import { UserAccessPartitionProductOrders } from '../../../../domain/model/accessMatrix';
import { ProductOrder } from '../../../../domain/model/order';
import { Nullable } from '../../../../domain/model/types';
import { EPanelActionPosition, PanelAction, PanelActions } from '../../../types';
import { ProductOrderLifeCycle } from '../lifecycle/types';
import { EProductOrderActionType, ProductOrderActionTableType } from '../types';

const getProductOrderActionName = (type: EProductOrderActionType): string => {
  switch (type) {
    case EProductOrderActionType.Cancel:
      return 'Отменен';
    case EProductOrderActionType.Give:
      return 'Получен';
    case EProductOrderActionType.Save:
      return 'Сохранить';
    case EProductOrderActionType.Renew:
      return 'Создан';
    case EProductOrderActionType.New:
      return 'Создать заказ';
    case EProductOrderActionType.Confirm:
      return 'Принят';
    case EProductOrderActionType.Pay:
      return 'Оплачен';
    case EProductOrderActionType.PartiallyReturn:
      return 'Возврат частичный';
    case EProductOrderActionType.Return:
      return 'Возврат полный';
    case EProductOrderActionType.Send:
      return 'Отправлен';
  }
};

type GetProductOrderActionsByPermissionsProps = UserAccessPartitionProductOrders;

export const getProductOrderActionsConfigByPermissions = (
  props: GetProductOrderActionsByPermissionsProps
): PanelActions<EProductOrderActionType> => {
  const { create, save, renew, confirm, pay, send, give, cancel, partiallyReturn, return: canReturn } = props;

  const actions: PanelActions<EProductOrderActionType> = [];

  let onCreate: Nullable<PanelAction<EProductOrderActionType>> = null;
  let onSave: Nullable<PanelAction<EProductOrderActionType>> = null;
  let onPay: Nullable<PanelAction<EProductOrderActionType>> = null;
  let onRenew: Nullable<PanelAction<EProductOrderActionType>> = null;
  let onConfirm: Nullable<PanelAction<EProductOrderActionType>> = null;
  let onSend: Nullable<PanelAction<EProductOrderActionType>> = null;
  let onGive: Nullable<PanelAction<EProductOrderActionType>> = null;
  let onCancel: Nullable<PanelAction<EProductOrderActionType>> = null;
  let onPartiallyReturn: Nullable<PanelAction<EProductOrderActionType>> = null;
  let onReturn: Nullable<PanelAction<EProductOrderActionType>> = null;

  if (give) {
    onGive = {
      type: EProductOrderActionType.Give,
      disabled: false,
      label: getProductOrderActionName(EProductOrderActionType.Give),

      position: [EPanelActionPosition.Default],
    };
  }
  if (create) {
    onCreate = {
      type: EProductOrderActionType.New,
      disabled: false,
      label: getProductOrderActionName(EProductOrderActionType.New),

      position: [EPanelActionPosition.Default],
    };
  }
  if (cancel) {
    onCancel = {
      type: EProductOrderActionType.Cancel,
      disabled: false,
      label: getProductOrderActionName(EProductOrderActionType.Cancel),

      position: [EPanelActionPosition.Default],
    };
  }
  if (pay) {
    onPay = {
      type: EProductOrderActionType.Pay,
      disabled: false,
      label: getProductOrderActionName(EProductOrderActionType.Pay),

      position: [EPanelActionPosition.Default],
    };
  }
  if (renew) {
    onRenew = {
      type: EProductOrderActionType.Renew,
      disabled: false,
      label: getProductOrderActionName(EProductOrderActionType.Renew),

      position: [EPanelActionPosition.Default],
    };
  }

  if (canReturn) {
    onReturn = {
      type: EProductOrderActionType.Return,
      disabled: false,
      label: getProductOrderActionName(EProductOrderActionType.Return),

      position: [EPanelActionPosition.Default],
    };
  }

  if (send) {
    onSend = {
      type: EProductOrderActionType.Send,
      disabled: false,
      label: getProductOrderActionName(EProductOrderActionType.Send),

      position: [EPanelActionPosition.Default],
    };
  }

  if (confirm) {
    onConfirm = {
      type: EProductOrderActionType.Confirm,
      disabled: false,
      label: getProductOrderActionName(EProductOrderActionType.Confirm),

      position: [EPanelActionPosition.Default],
    };
  }

  if (partiallyReturn) {
    onPartiallyReturn = {
      type: EProductOrderActionType.PartiallyReturn,
      disabled: false,
      label: getProductOrderActionName(EProductOrderActionType.PartiallyReturn),

      position: [EPanelActionPosition.Default],
    };
  }

  if (save) {
    onSave = {
      type: EProductOrderActionType.Save,
      disabled: false,
      label: getProductOrderActionName(EProductOrderActionType.Save),

      position: [EPanelActionPosition.Default],
    };
  }

  // Порядок важен!!!
  if (onCreate) actions.push(onCreate);
  if (onSave) actions.push(onSave);
  if (onRenew) actions.push(onRenew);
  if (onConfirm) actions.push(onConfirm);
  if (onPay) actions.push(onPay);
  if (onSend) actions.push(onSend);
  if (onGive) actions.push(onGive);
  if (onCancel) actions.push(onCancel);
  if (onPartiallyReturn) actions.push(onPartiallyReturn);
  if (onReturn) actions.push(onReturn);

  return actions;
};

export const getProductOrderTableActions = (
  lifecycle: ProductOrderLifeCycle,
  order: ProductOrder,
  allowedActions: PanelActions<ProductOrderActionTableType>
) => {
  return lifecycle.build({ obj: order, allowedActions }).nextActions as PanelActions<ProductOrderActionTableType>;
};
