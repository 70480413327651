import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import { EOfferHistoryTableTab } from '../../types';
import {
  offerHistoryTableSetFieldHistoryFilter,
  offerHistoryTableSetHistoryFilter,
  offerHistoryTableSetPage,
  offerHistoryTableSetPageSize,
  offerHistoryTableSetTab,
} from '../store/slice';
import { OfferFieldHistoryFilterEditStrategy } from '../utils/fieldHistoryFilter';
import { OfferHistoryFilterEditStrategy } from '../utils/historyFilter';

export type UseOfferHistoryTable = {
  readonly onSetTab: (tab: EOfferHistoryTableTab) => void;
  readonly onChangePage: (page: number) => void;
  readonly onChangePageSize: (pageSize: PaginationSize) => void;
  readonly onChangeHistoryFilter: (strategies: OfferHistoryFilterEditStrategy[]) => void;
  readonly onChangeFieldHistoryFilter: (strategies: OfferFieldHistoryFilterEditStrategy[]) => void;
};

export function useOfferHistoryTableHandlers(): UseOfferHistoryTable {
  const dispatch = useDispatch();

  const onSetTab = useCallback(
    (tab: EOfferHistoryTableTab) => {
      dispatch(offerHistoryTableSetTab(tab));
    },
    [dispatch]
  );

  const onChangePage = useCallback(
    (page: number) => {
      dispatch(offerHistoryTableSetPage(page));
    },
    [dispatch]
  );

  const onChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(offerHistoryTableSetPageSize(pageSize));
    },
    [dispatch]
  );

  const onChangeHistoryFilter = useCallback(
    (strategies: OfferHistoryFilterEditStrategy[]) => {
      dispatch(offerHistoryTableSetHistoryFilter(getDataFilterValuesByStrategies(strategies)));
    },
    [dispatch]
  );

  const onChangeFieldHistoryFilter = useCallback(
    (strategies: OfferFieldHistoryFilterEditStrategy[]) => {
      dispatch(offerHistoryTableSetFieldHistoryFilter(getDataFilterValuesByStrategies(strategies)));
    },
    [dispatch]
  );

  return {
    onSetTab,
    onChangePage,
    onChangePageSize,
    onChangeHistoryFilter,
    onChangeFieldHistoryFilter,
  };
}
