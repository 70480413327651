import { ChangeEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nullable } from '../../../../../domain/model/types';
import { useInputDebounce } from '../../../../hooks/useInputDebounce';
import { MPSearchInput } from '../../../../theme/ui-kit/input';
import { eventListNameSelector } from '../store/selectors';
import { eventListSetFilterName } from '../store/slice';
import { SearchInputWrapper } from './controls';

const EventListFilter = () => {
  const dispatch = useDispatch();

  const name = useSelector(eventListNameSelector);

  const onChangeName = useCallback((name: Nullable<string>) => dispatch(eventListSetFilterName(name)), [dispatch]);

  const [localName, setLocalName] = useInputDebounce({ initialValue: name, onChange: onChangeName });

  return (
    <div>
      <SearchInputWrapper>
        <MPSearchInput
          value={localName}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setLocalName(event.target.value)}
        />
      </SearchInputWrapper>
    </div>
  );
};

export default EventListFilter;
