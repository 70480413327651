import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';
import UserMpEditContainer from '../../features/user/userMp/edit/container';
import UserSportEditContainer from '../../features/user/userSport/edit/container';

const UserProfileEditScreen = () => {
  const dispatch = useDispatch();
  const { accessMatrix, userSpecific, refreshUserSpecific } = useCurrentUser();

  const onAfterSave = useCallback(() => {
    refreshUserSpecific();
  }, []);

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  if (!accessMatrix.isAdminSport) {
    return (
      <UserMpEditContainer
        id={userSpecific.id}
        onAfterSave={onAfterSave}
      />
    );
  } else {
    return (
      <UserSportEditContainer
        id={userSpecific.id}
        onAfterSave={onAfterSave}
      />
    );
  }
};

export default UserProfileEditScreen;
