import { FCC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useHistoryExtensions from '../../../../../hooks/useHistoryExtensions';
import { getConfigurationPrivilegeRoute } from '../../../../configuration/entry';
import { CorpOfferCategoriesEditContainerProps } from '../container';
import { CorpOfferCategoriesEditHandlersContext, CorpOfferCategoriesEditHandlersContextValue } from '../context';
import {
  corpOfferCategoriesEditCheckRelationShips,
  corpOfferCategoriesEditCreate,
  corpOfferCategoriesEditDelete,
  corpOfferCategoriesEditSetDialogState,
  corpOfferCategoriesEditUpdate,
} from '../store/slice';

export const CorpOfferCategoriesEditHandlersProvider: FCC<CorpOfferCategoriesEditContainerProps> = ({
  children,
  access,
  onClose: onCloseExternal,
}) => {
  const dispatch = useDispatch();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const onChangeDialogState = useCallback<CorpOfferCategoriesEditHandlersContextValue['onChangeDialogState']>(
    (name, data) => {
      dispatch(corpOfferCategoriesEditSetDialogState({ name, data }));
    },
    [dispatch]
  );

  const onCreate = useCallback<CorpOfferCategoriesEditHandlersContextValue['onCreate']>(
    async corpOfferCategory => {
      return await dispatch(corpOfferCategoriesEditCreate(corpOfferCategory)).unwrap();
    },
    [dispatch]
  );

  const onEdit = useCallback<CorpOfferCategoriesEditHandlersContextValue['onEdit']>(
    async corpOfferCategory => {
      return await dispatch(corpOfferCategoriesEditUpdate(corpOfferCategory)).unwrap();
    },
    [dispatch]
  );

  const onDelete = useCallback<CorpOfferCategoriesEditHandlersContextValue['onDelete']>(
    async corpOfferCategory => {
      await dispatch(corpOfferCategoriesEditDelete(corpOfferCategory)).unwrap();
    },
    [dispatch]
  );

  const onGetRelationShipsCount = useCallback<CorpOfferCategoriesEditHandlersContextValue['onGetRelationShipsCount']>(
    async corpOfferCategory => {
      return await dispatch(corpOfferCategoriesEditCheckRelationShips(corpOfferCategory)).unwrap();
    },
    [dispatch]
  );

  const onClose = useCallback<CorpOfferCategoriesEditHandlersContextValue['onClose']>(() => {
    if (onCloseExternal) {
      onCloseExternal();
    } else {
      gotoPrevIndependentLocation(getConfigurationPrivilegeRoute({}));
    }
  }, [gotoPrevIndependentLocation, onCloseExternal]);

  const value: CorpOfferCategoriesEditHandlersContextValue = {
    onChangeDialogState,
    onCreate,
    onEdit,
    onDelete,
    onGetRelationShipsCount,
    onClose,
  };

  return (
    <CorpOfferCategoriesEditHandlersContext.Provider value={value}>
      {children}
    </CorpOfferCategoriesEditHandlersContext.Provider>
  );
};
