import axios, { AxiosResponse } from 'axios';
import { v4 as uuid } from 'uuid';
import { ETeamMemberStatus, EUserType } from '../../domain/model/enums';
import { ActivityType } from '../../domain/model/event';
import { Team, TeamLogo, TeamMember, TeamMemberInvite } from '../../domain/model/team';
import { Nullable, UUID } from '../../domain/model/types';
import { createCancelToken } from './index';
import {
  ApiCancellable,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
  ApiResponseWithPageable,
  ApiSportResponseMaBeCounters,
} from './types';
import { getSportBaseEndpoint } from './utils';

export type AllProps<D extends ApiRequestListDiscriminator> = ApiRequestPageable &
  ApiCancellable & {
    readonly name?: Nullable<string>;
    readonly addressObjectId?: Nullable<UUID>;
    readonly activityTypes?: Nullable<ActivityType[]>;
    readonly userType?: Nullable<EUserType>;
    readonly userId?: Nullable<UUID>;
    readonly discriminator?: D;
  };

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type SaveProps = Team;

type MembersProps = ApiCancellable & {
  readonly id: UUID;
  readonly status: ETeamMemberStatus;
};

type LogosProps = ApiRequestPageable;

type InvitesProps = ApiRequestPageable &
  ApiCancellable & {
    readonly teamId: UUID;
  };

type RevokeInviteProps = {
  readonly id: UUID;
};

type RejectApplicationProps = {
  readonly id: UUID;
};

type ApproveApplicationProps = {
  readonly id: UUID;
};

type inviteMemberProps = {
  readonly teamId: UUID;
  readonly userId: UUID;
};

type TeamApi = {
  readonly all: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps<D>
  ) => Promise<AxiosResponse<ApiSportResponseMaBeCounters<D, ApiResponseWithPageable<Team>>>>;
  readonly one: (props: OneProps) => Promise<AxiosResponse<Team>>;
  readonly save: (props: SaveProps) => Promise<AxiosResponse<Team>>;
  readonly members: (props: MembersProps) => Promise<AxiosResponse<TeamMember[]>>;
  readonly logos: (props: LogosProps) => Promise<AxiosResponse<ApiResponseWithPageable<TeamLogo>>>;
  readonly invites: (props: InvitesProps) => Promise<AxiosResponse<ApiResponseWithPageable<TeamMemberInvite>>>;
  readonly revokeInvite: (props: RevokeInviteProps) => Promise<AxiosResponse<void>>;
  readonly rejectApplication: (props: RejectApplicationProps) => Promise<AxiosResponse<void>>;
  readonly approveApplication: (props: ApproveApplicationProps) => Promise<AxiosResponse<void>>;
  readonly inviteMember: (props: inviteMemberProps) => Promise<AxiosResponse<void>>;
};

/**
 * АПИ по работе с командами
 */
const team: TeamApi = {
  all: ({ page, pageSize, sort, name, addressObjectId, userType, activityTypes, userId, discriminator, signal }) => {
    const params = new URLSearchParams();

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    if (name) {
      params.append('name', name);
    }

    if (addressObjectId) {
      params.append('addressObjectId', addressObjectId);
    }

    if (userType) {
      params.append('userType', userType);
    }

    if (activityTypes) {
      activityTypes.forEach(item => params.append('activityTypes', item.id));
    }

    if (userId) {
      params.append('userId', userId);
    }

    if (discriminator) params.append('resultType', discriminator);

    return axios.get(`${getSportBaseEndpoint()}/teams`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  one: ({ id, signal }) => {
    return axios.get(`${getSportBaseEndpoint()}/teams/${id}`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  save: data => {
    const { id } = data;

    if (!id) {
      const newId = uuid();
      const newData = {
        ...data,
        id: newId,
      };

      return axios.post(`${getSportBaseEndpoint()}/admin/teams`, newData);
    } else {
      return axios.put(`${getSportBaseEndpoint()}/admin/teams`, data);
    }
  },

  members: ({ id, status, signal }) => {
    const params = new URLSearchParams();

    params.append('status', status);

    return axios.get(`${getSportBaseEndpoint()}/teams/${id}/members`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },

  logos: ({ page, pageSize, sort }) => {
    const params = new URLSearchParams();
    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }
    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    return axios.get(`${getSportBaseEndpoint()}/logo`, { params });
  },

  invites: ({ page, pageSize, sort, teamId, signal }) => {
    const params = new URLSearchParams();

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    params.append('teamId', teamId);

    return axios.get(`${getSportBaseEndpoint()}/team/member/invites`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },

  revokeInvite: ({ id }) => {
    return axios.post(`${getSportBaseEndpoint()}/team/member/invite/${id}/revoke-invite`);
  },

  rejectApplication: ({ id }) => {
    return axios.post(`${getSportBaseEndpoint()}/team/member/invite/${id}/reject-application`);
  },

  approveApplication: ({ id }) => {
    return axios.post(`${getSportBaseEndpoint()}/team/member/invite/${id}/approve-application`);
  },

  inviteMember: ({ teamId, userId }) => {
    const params = new URLSearchParams();
    params.append('teamId', teamId);
    params.append('userId', userId);

    return axios.post(`${getSportBaseEndpoint()}/team/member/invite/invite-member?${params}`);
  },
};

export default team;
