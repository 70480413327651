import { UserAccessPartitionCorpActivations } from '@/domain/model/accessMatrix';
import { Nullable, UUID } from '@/domain/model/types';
import { DataFilterAdapter } from '@/presentation/hooks/useDataFilterAdapter';
import { ECorpActivationsFilterItem } from '@features/corpActivation/utils/filter';
import TableCommonLayout from '../../../layouts/tableCommon/container';
import { CorpActivationLifeCycle } from '../lifecycle/types';
import { ECorpActivationTableColumn, ECorpActivationTableTab } from '../types';
import { getCorpActivationTableTabActions } from '../utils/table';
import { CorpActivationTableBehaviorAdapter } from './adapters/behavior';
import CorpActivationTableFilterAdapter from './adapters/filters';
import CorpActivationTableHeaderAdapter from './adapters/header';
import CorpActivationTableTableAdapter from './adapters/table';
import { CorpActivationTableTabsAdapter } from './adapters/tabs';
import { CorpActivationTableContext } from './context';
import CorpActivationTableFooterContainer from './footerContainer';
import { useCorpActivationTableHandlers } from './hooks/useCorpActivationTable';

export type CorpActivationTableContainerProps = {
  readonly guid: UUID;
  readonly partnerId: Nullable<UUID>;
  readonly customerId: Nullable<UUID>;
  readonly tab: ECorpActivationTableTab;
  readonly filterAdapter: DataFilterAdapter<ECorpActivationTableColumn, ECorpActivationsFilterItem>;
  readonly lifecycle: CorpActivationLifeCycle;
  readonly access: UserAccessPartitionCorpActivations;
};

const CorpActivationTableContainer = (props: CorpActivationTableContainerProps) => {
  const { guid, tab, partnerId, customerId, filterAdapter, lifecycle, access } = props;
  const handlers = useCorpActivationTableHandlers({ tab });

  const tableTabActions = getCorpActivationTableTabActions(access);

  return (
    <CorpActivationTableContext.Provider value={handlers}>
      <CorpActivationTableBehaviorAdapter
        guid={guid}
        tab={tab}
        partnerId={partnerId}
        customerId={customerId}
      />
      <TableCommonLayout
        header={
          <CorpActivationTableHeaderAdapter
            partnerId={partnerId}
            customerId={customerId}
          />
        }
        filter={<CorpActivationTableFilterAdapter filterAdapter={filterAdapter} />}
        tabs={<CorpActivationTableTabsAdapter tab={tab} />}
        table={
          <CorpActivationTableTableAdapter
            filterAdapter={filterAdapter}
            lifecycle={lifecycle}
            tableTabActions={tableTabActions}
          />
        }
        footer={CorpActivationTableFooterContainer}
      ></TableCommonLayout>
    </CorpActivationTableContext.Provider>
  );
};

export default CorpActivationTableContainer;
