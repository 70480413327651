import { Nullable, UUID } from '@/domain';
import { useContextConfig } from '@features/customer/table/hooks/useContextConfig';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  customersFilterSelector,
  customersNeedRefreshWatcherSelector,
  customersPageNumberSelector,
  customersSearchSelector,
} from '../store/selectors';
import {
  customersCountsFetch,
  customersFetch,
  CustomersFetchProps,
  customersNeedRefreshWatcherReset,
} from '../store/slice';
import { ECustomerTableTab, getCustomerStatusesByTableTab } from '../utils';

interface UseCustomerTableSearchProps {
  readonly tab: ECustomerTableTab;
  readonly partnerId: Nullable<UUID>;
}

const useCustomerTableSearch = (props: UseCustomerTableSearchProps): void => {
  const { tab, partnerId } = props;

  const dispatch = useDispatch();

  const { tabsForCounts } = useContextConfig();

  const [searchPromises, setSearchPromises] = useState<Nullable<any[]>>(null);

  const needRefreshWatcher = useSelector(customersNeedRefreshWatcherSelector);
  const pageNumber = useSelector(customersPageNumberSelector);
  const search = useSelector(customersSearchSelector);
  const filter = useSelector(customersFilterSelector);

  const currentStatuses = getCustomerStatusesByTableTab(tab);

  const queryParams = useMemo<CustomersFetchProps>(() => {
    return {
      filter,
      search: {
        ...search,
        statuses: currentStatuses,
        partnerId,
      },
      pageNumber,
    };
  }, [filter, search, currentStatuses, pageNumber]);

  const onSearch = useCallback(() => {
    setSearchPromises([
      dispatch(customersFetch(queryParams)),
      dispatch(customersCountsFetch({ ...queryParams, tabs: tabsForCounts })),
    ]);
  }, [dispatch, queryParams, tabsForCounts]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  // сброс вотчера после ухода
  useEffect(() => {
    return () => {
      dispatch(customersNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};

export default useCustomerTableSearch;
