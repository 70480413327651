import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataFilterComponent from '../../../../components/common/dataFilter';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import { EOfferHistoryTableTab } from '../../types';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  offerHistoryTableFieldFilterSelector,
  offerHistoryTableFilterSelector,
  offerHistoryTableTabSelector,
} from '../store/selectors';
import { getOfferFieldHistoryFilterStrategies } from '../utils/fieldHistoryFilter';
import { getOfferHistoryFilterStrategies } from '../utils/historyFilter';

const OfferHistoryTableFilterAdapter = () => {
  const handlers = useContextHandlers();

  const { offerStatuses, privilegeActorTypes: actorTypes } = useSelector(nsiDataSelector);

  const tab = useSelector(offerHistoryTableTabSelector);
  const historyFilter = useSelector(offerHistoryTableFilterSelector);
  const fieldHistoryFilter = useSelector(offerHistoryTableFieldFilterSelector);

  const historyFilterStrategies = getOfferHistoryFilterStrategies(historyFilter, offerStatuses, actorTypes);
  const fieldHistoryFilterStrategies = getOfferFieldHistoryFilterStrategies(
    fieldHistoryFilter,
    offerStatuses,
    actorTypes
  );

  return useMemo(
    () =>
      tab === EOfferHistoryTableTab.Status ? (
        <DataFilterComponent
          strategies={historyFilterStrategies}
          onChange={handlers.onChangeHistoryFilter}
        />
      ) : (
        <DataFilterComponent
          strategies={fieldHistoryFilterStrategies}
          onChange={handlers.onChangeFieldHistoryFilter}
        />
      ),
    [
      tab,
      historyFilterStrategies,
      fieldHistoryFilterStrategies,
      handlers.onChangeHistoryFilter,
      handlers.onChangeFieldHistoryFilter,
    ]
  );
};

export default OfferHistoryTableFilterAdapter;
