import { BookingOfferDetailsStepType, EBookingOfferStep } from '../types';

export const getBookingOfferDetailsStepText = (step: BookingOfferDetailsStepType): string => {
  switch (step) {
    case EBookingOfferStep.General:
      return 'Основные данные';
    case EBookingOfferStep.Contacts:
      return 'Контакты и как добраться';
    case EBookingOfferStep.Services:
      return 'Услуги';
  }
};

export const getBookingOfferDetailsSteps = (): BookingOfferDetailsStepType[] => {
  return [EBookingOfferStep.General, EBookingOfferStep.Contacts, EBookingOfferStep.Services];
};