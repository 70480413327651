import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import {
  Address,
  AddressPosition,
  CreateAddressRequest,
  CustomBuildingType,
  ManualAddressObjectType,
} from '../../../../../domain/model/address';
import { EAddressLevel } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { AddressHelper } from '../../../../utils/address';
import { ValidationItemResult, ValidationResult } from '../../../../utils/validation';
import addressServices from '../../service';
import { ManualAddress } from '../types';
import { emptyFormData } from '../utils';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';

export interface ManualAddressEditState extends Fetchable {
  readonly objectTypes: Nullable<ManualAddressObjectType[]>;
  readonly buildTypes: Nullable<CustomBuildingType[]>;
  readonly initialized: boolean;
  readonly isCreating: boolean;
  readonly isCreated: boolean;
  readonly isError: boolean;
  readonly data: ManualAddress;
  readonly validation: ValidationResult<ManualAddress>;
  readonly validationPosition: ValidationResult<AddressPosition>;
  readonly positionSelectDialog: Nullable<AddressPosition>;
}

type Reducer<T = undefined> = CaseReducer<ManualAddressEditState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ManualAddressEditState> {
  manualAddressEditChangeAttribute: Reducer<{ name: keyof ManualAddress; value: any }>;
  manualAddressEditChangePositionAttribute: Reducer<{ name: keyof AddressPosition; value: any }>;
  manualAddressEditResetState: Reducer;
  manualAddressEditClearAllValidation: Reducer;
  manualAddressEditSetValidation: Reducer<Nullable<ValidationResult<ManualAddress>>>;
  manualAddressEditSetAttributeValidation: Reducer<{
    name: keyof ManualAddress;
    results: Nullable<ValidationItemResult>;
  }>;
  manualAddressEditClearAttributeValidation: Reducer<keyof ManualAddress>;
  manualAddressEditSetPositionAttributeValidation: Reducer<{
    name: keyof AddressPosition;
    results: Nullable<ValidationItemResult>;
  }>;
  manualAddressEditClearPositionAttributeValidation: Reducer<keyof AddressPosition>;
  manualAddressEditSetPositionValidation: Reducer<Nullable<ValidationResult<AddressPosition>>>;
  manualAddressEditSetPositionSelectDialog: Reducer<Nullable<AddressPosition>>;
}

type ManualAddressEditFetchBySourceProps = {
  data: ManualAddress;
  objectTypes: ManualAddressObjectType[];
  buildTypes: ManualAddressObjectType[];
};

export const manualAddressEditFetchBySource = createAsyncThunk<
  ManualAddressEditFetchBySourceProps,
  Nullable<Address>,
  AppThunkAPIConfig
>('address/manual/init', async (address, { rejectWithValue, signal }) => {
  try {
    // Получаем справочник типов объекта
    const objectTypes = await addressServices.common.getObjectTypes({ signal });

    // Получаем справочник типов строения
    const buildTypes = await addressServices.common.getBuildTypes({ signal });

    const helperAddress = address ? new AddressHelper(address) : null;
    const regionId = helperAddress?.getLocalityIdByLevel(EAddressLevel.Region);
    const admRegionId = helperAddress?.getLocalityIdByLevel(EAddressLevel.AdministrativeRegion);
    const region = regionId ? await addressServices.common.addressByFiasId({ fiasId: regionId }) : null;
    const admRegion = admRegionId ? await addressServices.common.addressByFiasId({ fiasId: admRegionId }) : null;
    const buildObjects = helperAddress?.getLocalityValuesByLevel();

    return {
      objectTypes,
      buildTypes,
      data: {
        ...emptyFormData,
        region,
        admRegion,
        settlement: helperAddress?.getLocalityShortPath() ?? null,
        street: helperAddress?.getStreet() ?? null,
        postalCode: address?.postalCode ?? null,
        position: address?.position ?? { lat: null, lon: null },
        objectNumber: buildObjects?.[0]?.value ?? null,
        buildNumber: buildObjects?.[1]?.value ?? null,
        objectType:
          objectTypes?.find(item => item.name.toLowerCase() === buildObjects?.[0]?.typeName.toLowerCase()) ?? null,
        buildType:
          buildTypes?.find(item => item.name.toLowerCase() === buildObjects?.[1]?.typeName.toLowerCase()) ?? null,
      },
    };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const manualAddressEditCreateAddress = createAsyncThunk<Address, CreateAddressRequest, AppThunkAPIConfig>(
  'address/manual/createAddress',
  async (payload, { rejectWithValue }) => {
    try {
      return await addressServices.common.createFromManual(payload);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

const slice = createSlice<ManualAddressEditState, Reducers, 'manual'>({
  name: 'manual',
  initialState: {
    initialized: false,
    isCreated: false,
    isCreating: false,
    isError: false,
    validation: {},
    validationPosition: {},
    objectTypes: null,
    buildTypes: null,
    data: emptyFormData,
    positionSelectDialog: null,
    ...fetchableDefault,
  },
  reducers: {
    manualAddressEditResetState: state => {
      state.data = emptyFormData;
      state.initialized = false;
      state.validation = {};
      state.validationPosition = {};
      state.isCreating = false;
      state.isCreated = false;
      state.isError = false;
      state.positionSelectDialog = null;
    },
    manualAddressEditChangeAttribute: (state, { payload }) => {
      const { name, value } = payload;
      state.data[name] = value;
    },
    manualAddressEditChangePositionAttribute: (state, { payload }) => {
      const { name, value } = payload;
      state.data.position[name] = value;
    },
    manualAddressEditClearAllValidation: state => {
      state.validation = {};
      state.validationPosition = {};
    },
    manualAddressEditSetValidation: (state, { payload }) => {
      state.validation = payload ?? {};
    },
    manualAddressEditSetAttributeValidation: (state, { payload }) => {
      const { name, results } = payload;
      if (!results) {
        delete state.validation?.[name];
      } else {
        state.validation[name] = results;
      }
    },
    manualAddressEditClearAttributeValidation: (state, { payload }) => {
      delete state.validation[payload];
    },
    manualAddressEditSetPositionAttributeValidation: (state, { payload }) => {
      const { name, results } = payload;
      if (!results) {
        delete state.validationPosition?.[name];
      } else {
        state.validationPosition[name] = results;
      }
    },
    manualAddressEditClearPositionAttributeValidation: (state, { payload }) => {
      delete state.validationPosition[payload];
    },
    manualAddressEditSetPositionValidation: (state, { payload }) => {
      state.validationPosition = payload ?? {};
    },
    manualAddressEditSetPositionSelectDialog: (state, { payload }) => {
      state.positionSelectDialog = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(manualAddressEditCreateAddress.pending, state => {
        state.isCreating = true;
        state.isCreated = false;
        state.isError = false;
      })
      .addCase(manualAddressEditCreateAddress.fulfilled, state => {
        state.isCreating = false;
        state.isCreated = true;
        state.isError = false;
      })
      .addCase(manualAddressEditCreateAddress.rejected, state => {
        state.isCreating = false;
        state.isCreated = false;
        state.isError = true;
      })
      .addCase(manualAddressEditFetchBySource.pending, state => {
        state.data = emptyFormData;
        state.initialized = false;
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
      })
      .addCase(manualAddressEditFetchBySource.fulfilled, (state, { payload }) => {
        const { data, objectTypes, buildTypes } = payload;
        state.data = data;
        state.objectTypes = objectTypes;
        state.buildTypes = buildTypes;
        state.initialized = true;
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
      })
      .addCase(manualAddressEditFetchBySource.rejected, state => {
        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = true;
      });
  },
});

export const {
  manualAddressEditResetState,
  manualAddressEditChangeAttribute,
  manualAddressEditSetValidation,
  manualAddressEditSetAttributeValidation,
  manualAddressEditChangePositionAttribute,
  manualAddressEditSetPositionAttributeValidation,
  manualAddressEditClearPositionAttributeValidation,
  manualAddressEditSetPositionValidation,
  manualAddressEditClearAllValidation,
  manualAddressEditClearAttributeValidation,
  manualAddressEditSetPositionSelectDialog,
} = slice.actions;

export default slice.reducer;
