import styled from '@emotion/styled';
import { viewConfig } from '../../../theme/viewConfig';

export const Wrapper = styled.div`
  width: ${viewConfig.stepper.width};
  height: 100%;

  position: fixed;
  display: flex;
  flex-direction: column;

  padding: ${p => p.theme.spacing(3)};

  background: ${p => p.theme.palette.highlight};
  border-right: 1px solid ${p => p.theme.palette.secondary.main};

  z-index: 3;
`;
