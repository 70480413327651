import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import { CorpOfferTableCellPartnerTypeProps } from '../../components/tableCell/partnerType';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { corpOffersSearchCorpOfferByIndexSelector } from '../store/selectors';
import { CorpOffersSearchCellAdapterProps } from './cell';

type CorpOffersTableCellPartnerTypeAdapterProps = Omit<CorpOffersSearchCellAdapterProps, 'component'> & {
  readonly component: FC<CorpOfferTableCellPartnerTypeProps>;
};

const CorpOffersTableCellPartnerTypeAdapter = (props: CorpOffersTableCellPartnerTypeAdapterProps) => {
  const { index, component: Component } = props;

  const handlers = useContextHandlers();
  const corpOffer = useSelector(corpOffersSearchCorpOfferByIndexSelector(index));
  const isDisabled = corpOffer ? handlers.isCorpOfferNotModeratedByMe(corpOffer) : false;

  const { partnerTypes } = useSelector(nsiDataSelector);

  return useMemo(
    () =>
      corpOffer ? (
        <Component
          corpOffer={corpOffer}
          partnerTypes={partnerTypes}
          isDisabled={isDisabled}
        />
      ) : null,
    [Component, corpOffer, isDisabled, partnerTypes]
  );
};

export default CorpOffersTableCellPartnerTypeAdapter;
