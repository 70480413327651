import { EOfferActivationStatus } from '@/domain/model/enums';
import { ECorpActivationActionType } from '../types';
import { CorpActivationLifeCycleStatusScheme } from './types';

export const corpActivationAdminStatusScheme: CorpActivationLifeCycleStatusScheme = {
  description: 'Статусная схема админа активаций корпоративных предложений',
  statusScheme: {
    [EOfferActivationStatus.Pending]: [],
    [EOfferActivationStatus.Approved]: [ECorpActivationActionType.Give],
    [EOfferActivationStatus.Given]: [ECorpActivationActionType.UnGive],
    [EOfferActivationStatus.Rejected]: [],
  },
  actionsScheme: {} as any,
};
export const corpActivationPartnerStatusScheme = {
  ...corpActivationAdminStatusScheme,
  description: 'Статусная схема партнера активаций корпоративных предложений',
};
