import TabsPanelCounter from '../../../components/common/tabs/counter/counter';
import TabsPanelLabel from '../../../components/common/tabs/label/label';
import { AdCampaignTableTabsCounter, EAdCampaignTableTab } from '../types';
import { getAdCampaignTableTabName } from '@features/adCampaign/table/utils';

interface AdCampaignTableTabProps {
  readonly tab: EAdCampaignTableTab;
  readonly counts: AdCampaignTableTabsCounter;
}

export const AdCampaignTableTab = ({ tab, counts }: AdCampaignTableTabProps) => {
  const count = counts[tab];

  return (
    <TabsPanelLabel title={getAdCampaignTableTabName(tab)}>
      <TabsPanelCounter count={count} />
    </TabsPanelLabel>
  );
};
