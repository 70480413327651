import styled from '@emotion/styled';
import { css, Typography } from '@mui/material';
import { MPFormInput } from 'presentation/theme/ui-kit/input';

export const InfoText = styled(Typography)(
  ({ theme }) => css`
    background: ${theme.palette.secondary.main};
    padding: ${theme.spacing(1, 1.5)};
    border-radius: ${theme.shape.borderRadius}px;

    word-wrap: break-word;
  `
);

export const CodeInput = styled(MPFormInput)`
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
