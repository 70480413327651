import styled from '@emotion/styled';
import { Grid, List as MuiList, ListItem as MuiListItem } from '@mui/material';
import { ExtendButtonBase } from '@mui/material/ButtonBase';
import { ListItemTypeMap } from '@mui/material/ListItem/ListItem';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React, { forwardRef } from 'react';

export const Wrapper = styled.div`
  padding-bottom: ${p => p.theme.spacing(3)};
  display: flex;
`;

type ListWrapperProps = {
  readonly height: number;
  readonly children: any;
};

export const List = styled(MuiList)``;

export const ContentWrapper = styled.div`
  height: 100%;

  display: flex;
  flex-direction: row;
`;

export const ListWrapper = styled(
  forwardRef(({ height, ...others }: ListWrapperProps, ref: React.Ref<HTMLDivElement>) => (
    <div
      ref={ref}
      data-testid={'listWrapper'}
      {...others}
    />
  ))
)`
  width: 23rem;

  border-right: 1px solid ${p => p.theme.palette.secondary.main};
  padding-right: ${p => p.theme.spacing()};
  margin-left: -${p => p.theme.spacing(2)};

  &:not(:first-of-type) {
    margin-left: ${p => p.theme.spacing(2)};
  }

  & > * {
    height: ${p => p.height - parseInt(p.theme.spacing(4) as string, 10)}px;
  }
`;

type ListItemProps = OverridableComponent<
  ListItemTypeMap<
    {
      button?: false;
      $active?: boolean;
    },
    'li'
  >
> &
  ExtendButtonBase<
    ListItemTypeMap<
      {
        button: true;
        $active?: boolean;
      },
      'div'
    >
  >;

export const ListItem = styled<ListItemProps>(MuiListItem)`
  cursor: pointer;
  padding-right: 36px;

  &.MuiListItem-root {
    height: 2.3rem;

    padding: ${p => p.theme.spacing(0.5)} ${p => p.theme.spacing(1)};
    margin-right: ${p => p.theme.spacing(4)};

    border-radius: ${p => p.theme.shape.borderRadius}px;
  }

  & .MuiListItemIcon-root {
    margin-right: ${p => p.theme.spacing(0.5)};

    & .MuiIconButton-root {
      padding: ${p => p.theme.spacing(0.5)};
    }
  }

  ${p => p.$active && 'background-color: ' + p.theme.palette.secondary.light + ';'}
`;

export const ButtonsWrapper = styled(Grid)`
  padding-top: ${p => p.theme.spacing(2)};
  margin-top: ${p => p.theme.spacing(4)};
  border-top: 1px solid ${p => p.theme.palette.secondary.main};
`;
