import { useSelector } from 'react-redux';
import { MpUserShort } from '../../../../../../domain/model/user';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../../hooks/useDataFilterAdapter';
import { AdminMpActions, AdminMpActionTableType, EAdminMpActionType } from '../../types';
import AdminMpTable from '../component';
import { EAdminMpFilterItem } from '../filterUtils';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  adminMpsActionsSelector,
  adminMpsAdminsSelector,
  adminMpsFilterSelector,
  adminMpsGuidSelector,
  adminMpsSortColumnSelector,
  adminMpsSortDirectionSelector,
} from '../store/selectors';
import { AdminMpActionTableCall, AdminMpTableActionProcess, EAdminMpTableColumn } from '../utils';

interface AdminMpTableAdapterProps {
  readonly filterAdapter: DataFilterAdapter<EAdminMpTableColumn, EAdminMpFilterItem>;
  readonly getActions: (admin: MpUserShort) => AdminMpActions<AdminMpActionTableType>;
}

const AdminMpTableAdapter = (props: AdminMpTableAdapterProps) => {
  const { filterAdapter, getActions } = props;

  const handlers = useContextHandlers();
  const { onAdminClick } = useContextConfig();

  const guid = useSelector(adminMpsGuidSelector);
  const admins = useSelector(adminMpsAdminsSelector);
  const actionProcesses = useSelector(adminMpsActionsSelector);

  const sortColumn = useSelector(adminMpsSortColumnSelector);
  const sortDirection = useSelector(adminMpsSortDirectionSelector);
  const filter = useSelector(adminMpsFilterSelector);

  const onActionClick = (call: AdminMpActionTableCall) => {
    const { action, admin } = call;
    switch (action) {
      case EAdminMpActionType.Activate:
        handlers.onAdminActivate(admin);
        break;
      case EAdminMpActionType.Deactivate:
        handlers.onAdminDeactivate(admin);
        break;
    }
  };

  const getAdminMpActionProcesses = (admin: MpUserShort): AdminMpTableActionProcess[] =>
    actionProcesses.filter(process => process.id === admin.id) ?? [];

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return (
    <AdminMpTable
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      admins={admins}
      getActions={getActions}
      onAdminMpAction={onActionClick}
      onRequestSort={handlers.onChangeSort}
      onClick={onAdminClick}
      getActionProcesses={getAdminMpActionProcesses}
      onChangeMetadata={onChangeMetadata}
    />
  );
};

export default AdminMpTableAdapter;
