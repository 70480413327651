import { FieldsGroup } from '@components/fields';
import { AdCampaignCreateFieldAdapter, AdCampaignCreateFieldOffersAdapter } from '@features/adCampaign/create/adapters';
import AdCampaignCreateFieldBudget from '@features/adCampaign/create/fields/budget';
import AdCampaignCreateFieldKeywords from '@features/adCampaign/create/fields/keywords';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import FormItemAdapter from '../adapters/formItem';

const AdCampaignCreateStepKeywords = () => {
  return (
    <Grid
      container
      spacing={4}
    >
      <FormItemAdapter title={<Typography variant={'h3'}>Предложения в кампании</Typography>}>
        <AdCampaignCreateFieldAdapter
          name='offers'
          component={AdCampaignCreateFieldOffersAdapter}
        />
      </FormItemAdapter>

      <FormItemAdapter title={<Typography variant={'h3'}>Параметры продвижения</Typography>}>
        <FieldsGroup>
          <AdCampaignCreateFieldAdapter
            name='keywords'
            component={AdCampaignCreateFieldKeywords}
          />
          <AdCampaignCreateFieldAdapter
            name='budget'
            component={AdCampaignCreateFieldBudget}
          />
        </FieldsGroup>
      </FormItemAdapter>
    </Grid>
  );
};

export default AdCampaignCreateStepKeywords;
