import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import TablePagination from '../../../components/common/table/pagination';
import { useContextHandlers } from './hooks/useContextHandlers';
import {
  bookingOffersPageCountSelector,
  bookingOffersPageNumberSelector,
  bookingOffersSearchSelector,
  bookingOffersTotalCountSelector,
} from './store/selectors';

interface EventTableFooterContainerProps {}

const BookingTableFooterContainer = forwardRef((props: EventTableFooterContainerProps, ref: any) => {
  const handlers = useContextHandlers();

  const pageNumber = useSelector(bookingOffersPageNumberSelector);
  const search = useSelector(bookingOffersSearchSelector);
  const totalCount = useSelector(bookingOffersTotalCountSelector);
  const pageCount = useSelector(bookingOffersPageCountSelector);

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='объекты'
        onChangePageSize={handlers.onChangePageSize}
        onChangePage={handlers.onChangePage}
      />
    </div>
  );
});

export default BookingTableFooterContainer;
