import { EPartnerOwnershipType, EPartnerStatus } from '../../../../domain/model/enums';
import { Nullable } from '../../../../domain/model/types';
import { EPanelActionPosition, PanelActions } from '../../../types';
import { EPartnerActionType, PartnerActionDetailsType } from '../../general/partner/types';
import { getPartnerAddressLabelByOrgType } from '../../general/partner/utils/common';
import { getPartnerActionName } from '../utils';

export enum EPartnerDetailsTab {
  Legal = 'legal',
  Desk = 'desk',
  Notifications = 'notifications',
}

export enum EPartnerDetailsLegalTab {
  Common = 'common',
  Address = 'address',
  Requisites = 'requisites',
  Documents = 'documents',
  Permissions = 'permissions',
}

export interface PartnerDetailsLegalTab {
  readonly id: EPartnerDetailsLegalTab;
  readonly name: string;
}

export const getPartnerDetailsLegalTabs = (orgType: Nullable<EPartnerOwnershipType>): PartnerDetailsLegalTab[] => [
  {
    id: EPartnerDetailsLegalTab.Common,
    name: 'Общая информация',
  },
  {
    id: EPartnerDetailsLegalTab.Address,
    name: getPartnerAddressLabelByOrgType(orgType),
  },
  {
    id: EPartnerDetailsLegalTab.Requisites,
    name: 'Реквизиты',
  },
  {
    id: EPartnerDetailsLegalTab.Documents,
    name: 'Документы',
  },
  {
    id: EPartnerDetailsLegalTab.Permissions,
    name: 'Доступ',
  },
];

interface GetPartnerDetailsActionsProps {
  readonly status: EPartnerStatus;
  readonly tab: EPartnerDetailsTab;
  readonly canEdit?: boolean;
  readonly canEditDesk?: boolean;
  readonly canActivate?: boolean;
  readonly canDeactivate?: boolean;
}

export const getPartnerDetailsActions = ({
  status,
  tab,
  canEdit,
  canEditDesk,
  canActivate,
  canDeactivate,
}: GetPartnerDetailsActionsProps): PanelActions<PartnerActionDetailsType> => {
  const actions: PanelActions<PartnerActionDetailsType> = [];

  if (canActivate && status === EPartnerStatus.Disabled) {
    actions.push({
      type: EPartnerActionType.Activate,
      label: getPartnerActionName(EPartnerActionType.Activate),
      position: [EPanelActionPosition.Menu],
    });
  }

  if (canDeactivate && status === EPartnerStatus.Enabled) {
    actions.push({
      type: EPartnerActionType.Deactivate,
      label: getPartnerActionName(EPartnerActionType.Deactivate),
      position: [EPanelActionPosition.Menu],
    });
  }

  if (canEdit && tab === EPartnerDetailsTab.Legal) {
    actions.push({
      type: EPartnerActionType.Edit,
      label: getPartnerActionName(EPartnerActionType.Edit),
      position: [EPanelActionPosition.Menu],
    });
  }

  if (canEditDesk && tab === EPartnerDetailsTab.Desk) {
    actions.push({
      type: EPartnerActionType.EditDesk,
      label: getPartnerActionName(EPartnerActionType.EditDesk),
      position: [EPanelActionPosition.Menu],
    });
  }

  return actions;
};
