import TabsPanelCounter from '../../../components/common/tabs/counter/counter';
import TabsPanelLabel from '../../../components/common/tabs/label/label';
import { ETeamTableTab, getTeamTableTabName, TeamTableTabsCounter } from './utils';

interface TeamTableTabProps {
  readonly tab: ETeamTableTab;
  readonly counts: TeamTableTabsCounter;
}

const TeamTableTab = ({ tab, counts }: TeamTableTabProps) => {
  const count = counts[tab];

  return (
    <TabsPanelLabel title={getTeamTableTabName(tab)}>
      <TabsPanelCounter count={count} />
    </TabsPanelLabel>
  );
};

export default TeamTableTab;
