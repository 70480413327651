import { BookingOfferEditFieldServiceProps } from '../../../components/fieldsEdit/types';
import BookingOfferServiceManageFieldServicePriceItems from '../../fields/priceItems';

const fieldName = 'priceItems';

const BookingOfferServiceManageServicePriceItemsFieldAdapter = (
  props: BookingOfferEditFieldServiceProps<typeof fieldName>
) => {
  const { value } = props;

  return value ? <BookingOfferServiceManageFieldServicePriceItems {...props} /> : null;
};

export default BookingOfferServiceManageServicePriceItemsFieldAdapter;
