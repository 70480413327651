import { useMemo } from 'react';
import { Nullable, UUID } from '../../../../domain/model/types';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import { EPartnerTableColumn, EPartnerTableTab } from '../../general/partner/types';
import { getPartnersTableAdapterKey } from '../../general/partner/utils/table';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import { EPartnerTableFilterItem, getPartnerTableFilterStrategy, partnerTableFilterItems } from '../filterUtils';
import PartnerTableContainer from './container';
import { PartnersTableConfigContext } from './context';
import { usePartnersTableConfig } from './hooks/usePartnersTableConfig';
import { getPartnerTableColumns } from './utils';

interface PartnerTableEntryProps {
  readonly guid: UUID;
  readonly tab: Nullable<EPartnerTableTab>;
}

const PartnerTableEntry = ({ guid, tab }: PartnerTableEntryProps) => {
  const {
    accessMatrix: { partners },
  } = useCurrentUser();

  const config = usePartnersTableConfig();
  const { defaultTab } = config;

  const outTab = tab ?? defaultTab;

  // получаем максимально возможный состав колонок
  const sourceColumns = useMemo(() => getPartnerTableColumns(outTab), [outTab]);

  // готовим адаптер для хука
  const adapter = useMemo<DataFilterAdapter<EPartnerTableColumn, EPartnerTableFilterItem>>(
    () => ({
      ...getPartnersTableAdapterKey({ tab: outTab }),
      sourceColumns,
      filterItems: partnerTableFilterItems,
      requiredFilters: [EPartnerTableFilterItem.Query],
      getDataFilterStrategy: (filterItem, values) => getPartnerTableFilterStrategy(filterItem, values),
    }),
    [outTab, sourceColumns]
  );

  return (
    <PartnersTableConfigContext.Provider value={config}>
      <PartnerTableContainer
        guid={guid}
        tab={outTab}
        filterAdapter={adapter}
        canActivate={partners.activate}
        canDeactivate={partners.deactivate}
        canReturnToVerification={partners.returnToVerification}
        canDeleteDraft={partners.deleteDraft}
        canCreate={partners.create}
        canApprove={partners.approve}
        canEdit={partners.edit}
      />
    </PartnersTableConfigContext.Provider>
  );
};

export default PartnerTableEntry;
