import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import TablePagination from '../../../../components/common/table/pagination';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  offerHistoryTablePageCountSelector,
  offerHistoryTablePageNumberSelector,
  offerHistoryTablePageSizeSelector,
  offerHistoryTableTotalCountSelector,
} from '../store/selectors';

interface OfferHistoryTableFooterAdapterProps {}

const OfferHistoryTableFooterAdapter = forwardRef<OfferHistoryTableFooterAdapterProps>((props, ref: any) => {
  const handlers = useContextHandlers();

  const pageNumber = useSelector(offerHistoryTablePageNumberSelector);
  const pageSize = useSelector(offerHistoryTablePageSizeSelector);
  const totalCount = useSelector(offerHistoryTableTotalCountSelector);
  const pageCount = useSelector(offerHistoryTablePageCountSelector);

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='записей'
        onChangePageSize={handlers.onChangePageSize}
        onChangePage={handlers.onChangePage}
      />
    </div>
  );
});

export default OfferHistoryTableFooterAdapter;
