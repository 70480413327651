import DataTableCellTextFormat from '@components/common/table/cell/textFormat';

interface BookingOfferTableCellNameProps {
  readonly name: Nullable<string>;
}

const BookingOfferTableCellName = ({ name }: BookingOfferTableCellNameProps) => (
  <DataTableCellTextFormat>{name}</DataTableCellTextFormat>
);

export default BookingOfferTableCellName;
