import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { FileUploadObject, XLSXImportError, XLSXImportResult } from '../../../../domain/model';
import { Nullable } from '../../../../domain/model/types';
import { ButtonLink } from '../../../components/common/buttons/link';
import FileDownloadLink from '../../../components/common/files/download/link';
import AppSvgIcon from '../../../components/common/images/icon';
import ContentLoader from '../../../components/common/loader';
import Splitter from '../../../components/common/splitter';
import { IconDownload } from '../../../icons';
import { ValidationResult } from '../../../utils/validation';
import ProductUploadItems from '../components/upload';
import { productStocksImportAccept } from '../utils/common';
import ProductUploadStocksButtons from './buttons';

type ProductUploadStocksProps = {
  readonly templateDownloadUrl: string;
  readonly uploadFile: Nullable<{ file: File; error: Nullable<string> }>;
  readonly isUploading: boolean;
  readonly isUploaded: boolean;
  readonly isFailed: boolean;
  readonly uploadResult: Nullable<XLSXImportResult>;
  readonly validation: Nullable<ValidationResult<FileUploadObject>>;
  readonly isValid: boolean;
  readonly getSuccessImportedCountText: (count: number) => string;
  readonly onSetFile: (file: File, error?: string) => void;
  readonly onRemoveFile: () => void;
  readonly onDownloadImportError: (errors: XLSXImportError[]) => void;
  readonly onUploadFile: () => void;
  readonly onClose: () => void;
};

const ProductUploadStocks: FC<ProductUploadStocksProps> = props => {
  const {
    templateDownloadUrl,
    uploadFile,
    uploadResult,
    isUploading,
    isUploaded,
    isFailed,
    validation,
    isValid,
    getSuccessImportedCountText,
    onSetFile,
    onRemoveFile,
    onDownloadImportError,
    onUploadFile,
    onClose,
  } = props;

  const isUploadSuccess = isUploaded && (uploadResult?.successCount ?? 0) > 0;

  return (
    <>
      <Grid
        container
        spacing={2}
        wrap='nowrap'
        direction='column'
      >
        <Grid
          item
          xs={12}
        >
          <FileDownloadLink
            src={templateDownloadUrl}
            accept={productStocksImportAccept}
            fileName={'загрузка_остатков_товаров'}
            component={ButtonLink}
          >
            <AppSvgIcon
              icon={IconDownload}
              fontSize='small'
            />
            <Typography>Скачать таблицу</Typography>
          </FileDownloadLink>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Typography gutterBottom>
            Скачайте таблицу, с вашими SKU, и там где изменился остаток, укажите его количество. Сохраненный файл
            загрузите в поле ниже и нажмите кнопку «Обновить остатки». Товары с нулевым остатком, после обновления
            остатка, перейдут из статуса «Нет в наличии» в статус «Продаются»
          </Typography>
        </Grid>
        <Grid item>
          <ProductUploadItems
            uploadFile={uploadFile}
            importResult={uploadResult}
            isSaved={isUploaded}
            isFailed={isFailed}
            isValid={isValid}
            validation={validation}
            getSuccessImportedCountText={getSuccessImportedCountText}
            onSetFile={onSetFile}
            onRemoveFile={onRemoveFile}
            onDownloadImportError={onDownloadImportError}
          />
        </Grid>
        <Splitter size={3} />
        <Grid item>
          <ProductUploadStocksButtons
            isUploadSuccess={isUploadSuccess}
            onUploadFile={onUploadFile}
            onClose={onClose}
          />
        </Grid>
      </Grid>
      {isUploading && (
        <ContentLoader
          size={75}
          alpha
        />
      )}
    </>
  );
};

export default ProductUploadStocks;
