import { AdCampaignShort, UserAccessPartitionAdCampaigns } from '@/domain';
import { EPanelActionPosition } from '../../../types';
import { AdCampaignLifeCycle } from '../lifecycle/types';
import {
  AdCampaignActionCreateType,
  AdCampaignActionDetailsType,
  AdCampaignActions,
  AdCampaignActionTableType,
  EAdCampaignActionType,
  EAdCampaignTableTab,
} from '../types';

export const getAdCampaignActionName = (type: EAdCampaignActionType): string => {
  switch (type) {
    case EAdCampaignActionType.Pause:
      return 'Приостановить';
    case EAdCampaignActionType.Archive:
      return 'Отправить в архив';
    case EAdCampaignActionType.Resume:
      return 'Возобновить';
    case EAdCampaignActionType.Edit:
      return 'Редактировать';
  }
};

export type GetAdCampaignActionsByPermissionsProps = UserAccessPartitionAdCampaigns;

// получение разрешенных действий с РК с учетом разрешений
export const getAdCampaignActionsConfigByPermissions = (
  props: GetAdCampaignActionsByPermissionsProps
): AdCampaignActions => {
  const actions: AdCampaignActions = [];

  if (props.edit) {
    actions.push({
      type: EAdCampaignActionType.Edit,
      disabled: false,
      label: getAdCampaignActionName(EAdCampaignActionType.Edit),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.resume) {
    actions.push({
      type: EAdCampaignActionType.Resume,
      disabled: false,
      label: getAdCampaignActionName(EAdCampaignActionType.Resume),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.pause) {
    actions.push({
      type: EAdCampaignActionType.Pause,
      disabled: false,
      label: getAdCampaignActionName(EAdCampaignActionType.Pause),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.archive) {
    actions.push({
      type: EAdCampaignActionType.Archive,
      disabled: false,
      label: getAdCampaignActionName(EAdCampaignActionType.Archive),
      position: [EPanelActionPosition.Menu],
    });
  }

  return actions;
};

export const removeAdCampaignAction = (actions: AdCampaignActions, type: EAdCampaignActionType) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions.splice(index, 1);
  }
};

export const disableAdCampaignAction = (actions: AdCampaignActions, type: EAdCampaignActionType) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      disabled: true,
    };
  }
};

export const changeAdCampaignActionPosition = (
  actions: AdCampaignActions<any>,
  type: EAdCampaignActionType,
  position: EPanelActionPosition[]
) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      position,
    };
  }
};

export const renameAdCampaignAction = (actions: AdCampaignActions, type: EAdCampaignActionType, label: string) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      label,
    };
  }
};

export const makeAdCampaignActionPrimary = (actions: AdCampaignActions<any>, types: EAdCampaignActionType[]) => {
  return actions.map(action => ({
    ...action,
    primary: types.includes(action.type) ? true : action.primary,
  }));
};

export const isAdCampaignActionActionEnabled = (actions: AdCampaignActions<any>, type: EAdCampaignActionType) => {
  return actions.some(action => action.type === type && !action.disabled);
};

// фильтрация действий для создания РК по всем действиям
export const filterAdCampaignCreateActions = (
  actions: AdCampaignActions
): AdCampaignActions<AdCampaignActionCreateType> => {
  const createActions: AdCampaignActions<AdCampaignActionCreateType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EAdCampaignActionType.Edit:
        createActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          primary: action.primary,
          position: action.position,
        });
        break;
      case EAdCampaignActionType.Archive:
      case EAdCampaignActionType.Resume:
      case EAdCampaignActionType.Pause:
        break;
    }
  });

  return createActions;
};

/**
 * Фильтрация экшенов подходящих для мастера просмотра
 * @param actions
 */
export const filterAdCampaignDetailsActions = (
  actions: AdCampaignActions
): AdCampaignActions<AdCampaignActionDetailsType> => {
  const detailsActions: AdCampaignActions<AdCampaignActionDetailsType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EAdCampaignActionType.Edit:
      case EAdCampaignActionType.Resume:
      case EAdCampaignActionType.Archive:
      case EAdCampaignActionType.Pause:
        detailsActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          primary: action.primary,
          position: action.position,
        });
        break;
    }
  });

  return detailsActions;
};

// фильтрация действий для таблицы РК по всем действиям
export const filterAdCampaignTableActions = (
  actions: AdCampaignActions
): AdCampaignActions<AdCampaignActionTableType> => {
  const detailsActions: AdCampaignActions<AdCampaignActionTableType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EAdCampaignActionType.Edit:
      case EAdCampaignActionType.Resume:
      case EAdCampaignActionType.Archive:
      case EAdCampaignActionType.Pause:
        detailsActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          primary: action.primary,
          position: action.position,
        });
        break;
    }
  });

  return detailsActions;
};

/**
 * Получение экшенов для таблицы
 * @param lifecycle
 * @param offer
 * @param allowedActions
 */
export const getAdCampaignTableActions = (
  lifecycle: AdCampaignLifeCycle,
  offer: AdCampaignShort,
  allowedActions: AdCampaignActions<AdCampaignActionTableType>
) => {
  return lifecycle.build({ obj: offer, allowedActions }).nextActions as AdCampaignActions<AdCampaignActionTableType>;
};

/**
 * Получение экшенов с фильтрацией по табам
 * @param permissions
 * @param tab
 */
export const getAdCampaignTableTabActions = (
  permissions: GetAdCampaignActionsByPermissionsProps,
  tab: EAdCampaignTableTab
): AdCampaignActions<AdCampaignActionTableType> => {
  const allowedActions = getAdCampaignActionsConfigByPermissions(permissions);
  const actions = filterAdCampaignTableActions(allowedActions);

  return actions;
};
