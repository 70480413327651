import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SportsmanTableCellCommonProps } from '../../components/tableCell';
import { sportsmansByIndexSelector } from '../store/selectors';

export type SportsmanTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<SportsmanTableCellCommonProps>;
};
const SportsmanTableCellAdapter = (props: SportsmanTableCellAdapterProps) => {
  const { index, component: Component } = props;
  const sportsman = useSelector(sportsmansByIndexSelector(index));

  return useMemo(() => (sportsman ? <Component sportsman={sportsman} /> : null), [Component, sportsman]);
};

export default SportsmanTableCellAdapter;