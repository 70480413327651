import { ClientOrg, ContentTarget, EMultiSelectorValueType, Nullable } from '@/domain';
import { OrgStructureClientOrgMultipleSelectType } from '@components/orgStructure/clientOrg/multipleTypes/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import OrgStructureClientOrgMultipleTypesSelector from '../../../../../components/orgStructure/clientOrg/multipleTypes';
import { ContentTargetEditProps } from '../../types';

const attribute: keyof ContentTarget = 'clientOrgs';

interface ContentTargetEditClientOrgsProps extends ContentTargetEditProps {
  readonly label?: string | false;
}

const ContentTargetEditClientOrgs = (props: ContentTargetEditClientOrgsProps) => {
  const { target, label = 'Выберите компании', validation, onChangeAttribute } = props;

  const targetClientOrgs = target[attribute] ?? { select: EMultiSelectorValueType.In, in: null };

  const onChange = (newValue: Nullable<ClientOrg[]>) => {
    if (newValue?.length) {
      onChangeAttribute(attribute, { select: EMultiSelectorValueType.In, in: newValue });
    } else {
      onChangeAttribute(attribute, null);
    }
  };

  const onSelectAll = () => {
    onChangeAttribute(attribute, { select: EMultiSelectorValueType.All, in: null });
  };

  return (
    <>
      {label && (
        <Typography
          variant='body2'
          gutterBottom
        >
          {label}
        </Typography>
      )}
      <OrgStructureClientOrgMultipleTypesSelector
        label='Компании'
        color='primary'
        select={
          targetClientOrgs.select === EMultiSelectorValueType.All ? OrgStructureClientOrgMultipleSelectType.All : null
        }
        clientOrgs={targetClientOrgs.in ?? []}
        limitTags={2}
        helperText={validation?.[attribute]?.message}
        error={!!validation?.[attribute]?.hasError}
        popupIcon={<ExpandMoreIcon fontSize='small' />}
        onChange={onChange}
        onSelect={onSelectAll}
      />
    </>
  );
};

export default ContentTargetEditClientOrgs;
