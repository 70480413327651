import { CmsComponent, CmsContainer, CmsContainerRequest } from '../../../../domain/model/cms';
import { ECmsContainerType } from '../../../../domain/model/enums';
import { PartnerLink } from '../../../../domain/model/partner';
import { Target } from '../../../../domain/model/target';
import { MPStepperStep } from '../../../components/common/stepper';
import { PanelAction, PanelActions } from '../../../types';
import { CmsContainerView } from '../types';
import { CmsContainerActionTableCommonType } from './table/utils';

export type CmsContainerResizableBannerType = ECmsContainerType.Banner1Resizable | ECmsContainerType.Banner3Resizable;

export type ActionCmsContainerType = CmsContainer | CmsContainerRequest | CmsContainerView;

export type CmsFeatureContextPartner = {
  readonly partner: PartnerLink;
};

export type CmsFeatureContext = CmsFeatureContextPartner;

export type CmsContainerStep = MPStepperStep<number>;

export enum ECmsContainerActionType {
  Save = 'save',
  Publish = 'publish',
  Resume = 'resume',
  Pause = 'pause',
  Archive = 'archive',
  ChangeSortIndex = 'changeSortIndex',
  Edit = 'edit',
  Delete = 'delete',
  Duplicate = 'duplicate',
}

export enum ECmsTermTag {
  Title = 'title',
}

export type CmsContainerActionCreateType =
  | ECmsContainerActionType.Duplicate
  | ECmsContainerActionType.Save
  | ECmsContainerActionType.Publish
  | ECmsContainerActionType.Delete;

export type CmsContainerActionDetailsType =
  | ECmsContainerActionType.Edit
  | ECmsContainerActionType.Duplicate
  | ECmsContainerActionType.Pause
  | ECmsContainerActionType.Resume
  | ECmsContainerActionType.Archive
  | ECmsContainerActionType.Delete;

export type CmsContainerActionTableType =
  | ECmsContainerActionType.Resume
  | ECmsContainerActionType.Edit
  | ECmsContainerActionType.Pause
  | ECmsContainerActionType.Delete
  | ECmsContainerActionType.Archive
  | ECmsContainerActionType.ChangeSortIndex
  | ECmsContainerActionType.Duplicate;

export type CmsContainerAction<A extends string = ECmsContainerActionType> = PanelAction<A>;

export type CmsContainerActions<A extends string = ECmsContainerActionType> = PanelActions<A>;

export interface CmsFeatureContainerCommonProps {
  readonly cmsContext?: CmsFeatureContext;
}

export type CmsContentTarget = Target & Pick<CmsContainer, 'startDate' | 'endDate'>;

export interface GetCmsContainerActionsByPermissions {
  readonly canCreate?: boolean;
  readonly canEdit?: boolean;
  readonly canSave?: boolean;
  readonly canPublish?: boolean;
  readonly canDelete?: boolean;
  readonly canResume?: boolean;
  readonly canArchive?: boolean;
  readonly canPause?: boolean;
  readonly canChangeSortIndex?: boolean;
}

export type CmsContainerActionCall =
  | {
      readonly action: ECmsContainerActionType.ChangeSortIndex;
      readonly cmsContainer: CmsContainer;
      readonly sortIndex: number;
    }
  | {
      readonly action: CmsContainerActionTableCommonType;
      readonly cmsContainer: CmsContainer;
    };

export type CmsContainerViewResourceSize = {
  name: string;
  desktop: NonNullable<CmsContainerView['resourcesSize']>;
  mobile: NonNullable<CmsContainerView['resourcesSize']>;
};

export type CmsComponentPageSize = {
  name: string;
  value: NonNullable<CmsComponent['pageSize']>;
};

export enum ECmsContainerUrlParam {
  Tab = 'tab',
  Step = 'step',
  Type = 'type',
}
