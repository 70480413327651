import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../data/store/store';
import { ESortDirection } from '../../../../../domain/model/enums';
import { TradeOffer } from '../../../../../domain/model/tradeOffer';
import { Nullable } from '../../../../../domain/model/types';
import { ETradeOfferTableColumn } from '../../types';

export const tradeOffersSearchTradeOffersSelector = (store: RootState) => store.tradeOffer.search.data;
export const tradeOffersSearchIsFetchingSelector = (store: RootState) => store.tradeOffer.search.isFetching;
export const tradeOffersSearchGet = (store: RootState) => store.tradeOffer.search;
export const tradeOffersSearchGuidSelector = (store: RootState) => store.tradeOffer.search.guid;
export const tradeOffersSearchPageNumberSelector = (store: RootState) => store.tradeOffer.search.pageNumber;
export const tradeOffersSearchSearchSelector = (store: RootState) => store.tradeOffer.search.search;
export const tradeOffersSearchFilterSelector = (store: RootState) => store.tradeOffer.search.filter;
export const tradeOffersSearchSortColumnSelector = (store: RootState) =>
  (store.tradeOffer.search.search.sort?.split(',') as [ETradeOfferTableColumn, ESortDirection])?.[0] ?? '';
export const tradeOffersSearchSortDirectionSelector = (store: RootState) =>
  (store.tradeOffer.search.search.sort?.split(',') as [ETradeOfferTableColumn, ESortDirection])?.[1] ?? '';
export const tradeOffersSearchNeedRefreshWatcherSelector = (store: RootState) =>
  store.tradeOffer.search.needRefreshWatcher;
export const tradeOffersSearchTabsCounterSelector = (store: RootState) => store.tradeOffer.search.tabsCounter;
export const tradeOffersSearchSelectedSelector = (store: RootState) => store.tradeOffer.search.selected;

export const tradeOffersSearchTradeOfferIndexSelector = (store: RootState, index: number) => index;
const createTradeOffersSearchTradeOfferByIndexSelector = createSelector(
  tradeOffersSearchTradeOffersSelector,
  tradeOffersSearchTradeOfferIndexSelector,
  (tradeOffers, index): Nullable<TradeOffer> => tradeOffers?.[index] ?? null
);
export const tradeOffersSearchTradeOfferByIndexSelector = (index: number) => (store: RootState) =>
  createTradeOffersSearchTradeOfferByIndexSelector(store, index);
