import { AddressMultipleSelectType } from '@features/address/components/selector/multipleTypes/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Address } from 'domain/model/address';
import { EFilterMultiSelectorValueType } from 'domain/model/enums';
import { DataFilterStrategyAddressesSelector } from 'domain/model/filter';
import { Nullable } from 'domain/model/types';
import { AddressCityMultipleTypesSelector } from '../../../../../features/address/components/selector/multipleTypes';
import { DataFilterEditItemProps } from '../index';

type ValueType<T extends string> = DataFilterStrategyAddressesSelector<T>['value'];

interface DataFilterEditItemCityAddressMultipleTypesProps<T extends string>
  extends DataFilterEditItemProps<T, Nullable<ValueType<T>>> {
  readonly strategy: DataFilterStrategyAddressesSelector<T>;
}

function DataFilterEditItemCityAddressMultipleTypes<T extends string>({
  strategy,
  onChange,
}: DataFilterEditItemCityAddressMultipleTypesProps<T>) {
  const { label, value, readOnly, selectNone = true } = strategy;

  const targetLocalities = value ?? { select: EFilterMultiSelectorValueType.In, in: null };

  const select =
    targetLocalities.select === EFilterMultiSelectorValueType.All
      ? AddressMultipleSelectType.All
      : targetLocalities.select === EFilterMultiSelectorValueType.None
      ? AddressMultipleSelectType.None
      : null;

  const onChangeCities = (newValue: Nullable<Address[]>) => {
    if (newValue?.length) {
      onChange(strategy, { select: EFilterMultiSelectorValueType.In, in: newValue });
    } else {
      onChange(strategy, null);
    }
  };

  const onSelect = (type: AddressMultipleSelectType) => {
    switch (type) {
      case AddressMultipleSelectType.All:
        onChange(strategy, { select: EFilterMultiSelectorValueType.All, in: null });
        break;
      case AddressMultipleSelectType.None:
        onChange(strategy, { select: EFilterMultiSelectorValueType.None, in: null });
        break;
    }
  };

  return (
    <AddressCityMultipleTypesSelector
      selectNone={selectNone}
      label={label}
      color='primary'
      popupIcon={<ExpandMoreIcon fontSize='small' />}
      limitTags={2}
      disabled={readOnly}
      cities={targetLocalities.in ?? []}
      select={select}
      onSelect={onSelect}
      onChange={onChangeCities}
    />
  );
}

export default DataFilterEditItemCityAddressMultipleTypes;
