import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { EOrderPartition } from '../../../domain/model/enums';
import ForbiddenComponent from '../../components/common/forbidden';
import useBreadcrumbsActions from '../../features/general/breadcrumbs/useBreadcrumbsActions';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface OrderCreateScreenProps {
  readonly partition: EOrderPartition;
}

const OrderCreateScreen = ({ partition }: OrderCreateScreenProps) => {
  const dispatch = useDispatch();
  const { putBreadcrumbs } = useBreadcrumbsActions();

  const { accessMatrix } = useCurrentUser();
  const { orders } = accessMatrix;

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  useEffect(() => {
    putBreadcrumbs([{ label: 'Заказы' }]);
  }, []);

  if (!orders[partition].create) {
    return <ForbiddenComponent />;
  }

  switch (partition) {
    case EOrderPartition.BookingOffers:
    case EOrderPartition.ProductOffers:
      // TODO создать заглушку для букинга и товаров
      return null;
  }
};

export default OrderCreateScreen;
