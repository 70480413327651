import validator from 'validator';
import { MpUserData } from '../../../../domain/model/user';
import { EValidationMessages, EValidationType, ValidationRules } from '../../../utils/validation';
import { EPartnerEmployeeStep } from '../utils';

export const getPartnerEmployeeCreateStepText = (step: EPartnerEmployeeStep): string => {
  switch (step) {
    case EPartnerEmployeeStep.Profile:
      return 'Профиль';
    case EPartnerEmployeeStep.Grants:
      return 'Доступ';
    default:
      throw new Error(`Unknown EPartnerEmployeeStep: ${step}`);
  }
};

export const partnerEmployeeCreateProfileStepValidation: ValidationRules<MpUserData> = {
  lastName: {
    required: true,
  },
  firstName: {
    required: true,
  },
  middleName: {
    required: true,
  },
  email: {
    required: true,
    validator: (object, value) => {
      if (!value) return null;

      const isEmail = validator.isEmail(value);
      if (!isEmail) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EValidationMessages.EmailInvalid,
        };
      } else {
        return null;
      }
    },
  },
  phone: {
    required: true,
  },
};
