import { DataTableColumns } from '@components/common/table';
import { EOfferTableColumn } from '../../types';

export const offerCollectionDefaultColumns = [
  EOfferTableColumn.Code,
  EOfferTableColumn.SortIndex,
  EOfferTableColumn.Name,
];

const offerCollectionColumns: DataTableColumns<EOfferTableColumn> = {
  [EOfferTableColumn.Code]: {
    title: 'Код',
    width: '9rem',
  },
  [EOfferTableColumn.SortIndex]: {
    title: 'Место',
    width: '4rem',
  },
  [EOfferTableColumn.Name]: {
    title: 'Название',
    wrap: true,
  },
  [EOfferTableColumn.EndDate]: {
    title: 'Окончание',
    width: '8rem',
  },
};

export const offerCollectionTradeColumns: DataTableColumns<EOfferTableColumn> = {
  ...offerCollectionColumns,
  [EOfferTableColumn.Code]: {
    ...offerCollectionColumns[EOfferTableColumn.Code],
    title: 'Код ТП',
  },
};

export const offerCollectionCorpColumns: DataTableColumns<EOfferTableColumn> = {
  ...offerCollectionColumns,
  [EOfferTableColumn.Code]: {
    ...offerCollectionColumns[EOfferTableColumn.Code],
    title: 'Код КП',
  },
};

export const getOfferCollectionColumns = (
  columns: EOfferTableColumn[],
  tableColumns: DataTableColumns<EOfferTableColumn>
): DataTableColumns<EOfferTableColumn> => {
  const tableColumnsOut: DataTableColumns<EOfferTableColumn> = {};

  columns.forEach(
    column =>
      (tableColumnsOut[column] = {
        ...tableColumns[column],
      })
  );

  return tableColumnsOut;
};
