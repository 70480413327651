import { ESortDirection } from '@/domain/model/enums';
import { Nullable } from '@/domain/model/types';
import { Property } from 'csstype';
import React, { ChangeEvent, FC, MouseEvent, useMemo, useState } from 'react';
import { Body } from './body';
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableFooter,
  TableFooterCell,
  TableFooterRow,
  TableHead,
  TableHeadCell,
  TableHeadProgressBarCell,
  TableHeadRow,
  Wrapper,
} from './controls';
import { Head } from './head';
import DataTableMetadataDialog from './metadata/dialog';

export enum ETableColumnAlign {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}

export enum ETableAllSelectedMode {
  None = 'none',
  Any = 'any',
  All = 'all',
}

export type TableColumn = {
  title?: string | string[];
  readonly width?: string | number;
  readonly align?: ETableColumnAlign;
  readonly sortable?: any;
  readonly wrap?: any;
  readonly colspan?: number;
  readonly rowspan?: number;
  //не настройка, задаётся только в рантайме
  readonly hidden?: boolean;
  readonly slotRowName?: string;
  readonly metaData?: any;
};

export interface DataTableRowData {
  id: string | number;
}

export type MutableDataTableColumns<T extends string = string> = Partial<Record<T, TableColumn>>;

export type DataTableColumns<T extends string = string> = MutableDataTableColumns & Partial<Record<T, TableColumn>>;

export type DataTableMetadata<C extends string> = {
  readonly columns: MutableDataTableColumns<C>;
};

export type DataTableRowPropertyCollection<D extends DataTableRowData> = {
  main: DataTableRowProperty<D>;
  item: DataTableRowProperty<D>;
};
export type DataTableRowProperty<D extends DataTableRowData> =
  | React.ReactElement
  | FC<{ hoveredRow: boolean }>
  | DataTableRowPropertyCollection<D>
  | null
  | D
  | undefined
  | string
  | number
  | false;

export type DataTableRow<D extends DataTableRowData, R extends string = string> = {
  readonly [name in R]: DataTableRowProperty<D>;
} & {
  readonly data: D;
};

export type DataTableSort = {
  readonly column: any;
  readonly direction: ESortDirection;
};

export type ColumnActionsPosition = 'start' | 'end';

export const DataTableDefaultComponents = {
  Head: TableHead,
  Body: TableBody,
  Footer: TableFooter,
  HeadRow: TableHeadRow,
  BodyRow: TableBodyRow,
  FooterRow: TableFooterRow,
  HeadCell: TableHeadCell,
  BodyCell: TableBodyCell,
  FooterCell: TableFooterCell,
};

type DataTableSelectProps<D extends DataTableRowData, R extends string> =
  | {
      readonly selectable?: false;
      readonly singleSelect?: false;
      readonly selected?: undefined | false;
      readonly onRowSelect?: undefined | false;
      readonly onAllRowsSelect?: undefined | false;
    }
  | {
      readonly selectable?: boolean;
      readonly singleSelect?: boolean;
      readonly selected: Nullable<D[]>;
      readonly onRowSelect: (event: ChangeEvent, row: DataTableRow<D, R>, selected: boolean) => void;
      readonly onAllRowsSelect: (event: ChangeEvent, selected: boolean) => void;
    };

type DataTableRowActionsProps<D extends DataTableRowData> =
  | {
      readonly fixColumnActions?: undefined | false;
      readonly rowActions?: undefined | false;
      readonly getRowActions?: undefined | false;
      readonly columnActionsPosition?: ColumnActionsPosition;
    }
  | {
      /** зафиксировать колонку с экшонами */
      readonly fixColumnActions?: boolean;
      readonly rowActions: boolean;
      readonly columnActionsPosition?: ColumnActionsPosition;
      readonly getRowActions: Nullable<(data: D, index: number) => React.ReactNode>;
    };

type DataTableMetadataProps<C extends string> = {
  readonly metadata: DataTableMetadata<C>;
  readonly onChangeMetadata?: Nullable<(metadata: DataTableMetadata<C>) => void> | false;
};

export type DataTableProps<D extends DataTableRowData, R extends string, C extends string> = {
  readonly width?: string;
  readonly rows: DataTableRow<D, R>[];
  readonly footer?: any;
  readonly rowHeight?: string | number;
  readonly sort?: DataTableSort;
  readonly loader?: any;
  readonly overflowX?: Property.OverflowX;
  /** включение модуля наведения, в данном случае в рендер ячеек будет приходить соответствующий признак */
  readonly hoverModule?: boolean;
  readonly onSort?: (event: MouseEvent<HTMLSpanElement, MouseEvent>, column: string, direction: ESortDirection) => void;
  readonly onRowClick?: (event: Event, cell: string, row: DataTableRow<D, R>) => void;
  readonly onRowHover?: (event: Event, cell: string, row: DataTableRow<D, R>, hover: boolean) => void;
} & DataTableSelectProps<D, R> &
  DataTableRowActionsProps<D> &
  DataTableMetadataProps<C>;

function DataTable<D extends DataTableRowData, C extends string = string, R extends string = string>(
  props: DataTableProps<D, R, C>
) {
  const {
    metadata,
    width = '100%',
    rows,
    footer,
    rowHeight = '2.8rem',
    overflowX = 'auto',
    columnActionsPosition = 'start', // устанавливаем по умолчанию у всех таблиц позицию колонки с экшенами
    sort,
    onSort,
    selectable,
    singleSelect = false,
    selected,
    loader,
    hoverModule,
    fixColumnActions = true,
    rowActions,
    onRowClick,
    onRowHover,
    onRowSelect,
    onAllRowsSelect,
    onChangeMetadata,
    getRowActions,
  } = props;
  const [metadataDialogVisible, setMetadataDialogVisible] = useState<boolean>(false);
  const outColumns = metadata.columns;
  const visibleColumnsCount = useMemo(
    () => Object.entries<TableColumn>(outColumns as any).filter(([, column]) => !column?.hidden).length,
    [outColumns]
  );

  let allSelectedMode: ETableAllSelectedMode = ETableAllSelectedMode.None;
  if (selected && selected.length > 0 && rows?.length) {
    if (
      selected &&
      selected.length > 0 &&
      rows.some(row => !selected?.find(selectedItem => selectedItem.id === row.data.id))
    ) {
      allSelectedMode = ETableAllSelectedMode.Any;
    } else {
      allSelectedMode = ETableAllSelectedMode.All;
    }
  }

  return (
    <Wrapper overflowX={overflowX}>
      <Table width={width}>
        <Head
          columns={outColumns}
          sort={sort}
          selectable={selectable}
          selectedMode={allSelectedMode}
          fixColumnActions={fixColumnActions}
          columnActionsPosition={columnActionsPosition}
          rowActions={rowActions}
          onSort={onSort}
          onAllRowsSelect={
            selectable && !singleSelect && onAllRowsSelect
              ? event => onAllRowsSelect(event, allSelectedMode === ETableAllSelectedMode.None)
              : null
          }
          onChangeMetadata={onChangeMetadata ? () => setMetadataDialogVisible(true) : undefined}
        >
          {/* note: For safari supporting */}
          <DataTableDefaultComponents.HeadRow height='auto'>
            <TableHeadProgressBarCell colSpan={Object.keys(outColumns).length + Number(!!rowActions)}>
              {loader}
            </TableHeadProgressBarCell>
          </DataTableDefaultComponents.HeadRow>
        </Head>
        <Body
          columns={outColumns}
          rows={rows}
          rowHeight={rowHeight}
          selectable={selectable}
          singleSelect={singleSelect}
          selected={selected || null}
          fixColumnActions={fixColumnActions}
          columnActionsPosition={columnActionsPosition}
          rowActions={rowActions}
          canChangeMetadata={!!onChangeMetadata}
          hoverModule={hoverModule}
          onRowClick={onRowClick}
          onRowHover={onRowHover}
          onRowSelect={onRowSelect || null}
          getRowActions={getRowActions || null}
        />
        {footer && (
          <DataTableDefaultComponents.Footer>
            <DataTableDefaultComponents.FooterRow>
              <DataTableDefaultComponents.FooterCell colSpan={visibleColumnsCount}>
                {footer}
              </DataTableDefaultComponents.FooterCell>
            </DataTableDefaultComponents.FooterRow>
          </DataTableDefaultComponents.Footer>
        )}
      </Table>

      {metadata && onChangeMetadata && (
        <DataTableMetadataDialog
          open={metadataDialogVisible}
          metadata={metadata}
          onChange={onChangeMetadata}
          onClose={() => setMetadataDialogVisible(false)}
        />
      )}
    </Wrapper>
  );
}

export default DataTable;
