import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TabsPanel from '../../../../components/common/tabs/panel';
import { OfferTableTab } from '../../../general/offer/components/table/tab';
import { EOfferTableTab } from '../../../general/offer/types';
import { getOffersTableTabName } from '../../../general/offer/utils/table';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { tradeOffersTabsCounterSelector, tradeOffersTabsCounterUnwatchedSelector } from '../store/selectors';

interface TradeOffersTableTabsAdapterProps {
  readonly tab: EOfferTableTab;
}

export const TradeOffersTableTabsAdapter = (props: TradeOffersTableTabsAdapterProps) => {
  const { tab } = props;

  const { onChangeTab } = useContextHandlers();
  const { tabs } = useContextConfig();

  const counter = useSelector(tradeOffersTabsCounterSelector);
  const counterUnwatched = useSelector(tradeOffersTabsCounterUnwatchedSelector);

  return useMemo(
    () => (
      <TabsPanel
        tab={tab}
        tabs={tabs}
        labelAdapter={OfferTableTab}
        data={{
          counts: counter,
          unwatchedCounts: counterUnwatched,
          getTabName: getOffersTableTabName,
        }}
        onChangeTab={onChangeTab}
      />
    ),
    [tab, counter, counterUnwatched, onChangeTab]
  );
};
