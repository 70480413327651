import { Grid, Typography } from '@mui/material';

interface PartnerDetailsInfoOrgTypeCommonProps {
  readonly name: string;
}

const PartnerDetailsInfoOrgTypeCommon = (props: PartnerDetailsInfoOrgTypeCommonProps) => {
  const { name } = props;

  return (
    <Grid
      container
      spacing={3}
      direction='column'
    >
      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          Название компании, которое увидят покупатели
        </Typography>
        <Typography>{name ?? '-'}</Typography>
      </Grid>
    </Grid>
  );
};

export default PartnerDetailsInfoOrgTypeCommon;
