import { FCC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useHistoryExtensions from '../../../../../hooks/useHistoryExtensions';
import { getConfigurationPrivilegeRoute } from '../../../../configuration/entry';
import {
  BookingServiceCategoriesEditHandlersContext,
  BookingServiceCategoriesEditHandlersContextValue,
} from '../context';
import {
  bookingServiceCategoriesEditAddOrderByDateType,
  bookingServiceCategoriesEditCreate,
  bookingServiceCategoriesEditDelete,
  bookingServiceCategoriesEditRemoveOrderByDateType,
  bookingServiceCategoriesEditSetDialogState,
  bookingServiceCategoriesEditUpdate,
} from '../store/slice';
import { BookingServiceCategoriesEditCommonProps } from '../types';

export const BookingServiceCategoriesEditHandlersProvider: FCC<BookingServiceCategoriesEditCommonProps> = ({
  children,
  access,
}) => {
  const dispatch = useDispatch();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const onChangeDialogState = useCallback<BookingServiceCategoriesEditHandlersContextValue['onChangeDialogState']>(
    (name, data) => {
      dispatch(bookingServiceCategoriesEditSetDialogState({ name, data }));
    },
    [dispatch]
  );

  const onCreate = useCallback<BookingServiceCategoriesEditHandlersContextValue['onCreate']>(
    async bookingServiceCategory => {
      return await dispatch(bookingServiceCategoriesEditCreate(bookingServiceCategory)).unwrap();
    },
    [dispatch]
  );

  const onEdit = useCallback<BookingServiceCategoriesEditHandlersContextValue['onEdit']>(
    async bookingServiceCategory => {
      return await dispatch(bookingServiceCategoriesEditUpdate(bookingServiceCategory)).unwrap();
    },
    [dispatch]
  );

  const onAddOrderByDateType = useCallback<BookingServiceCategoriesEditHandlersContextValue['onAddOrderByDateType']>(
    async (bookingServiceCategory, orderByDateType) => {
      await dispatch(
        bookingServiceCategoriesEditAddOrderByDateType({
          bookingServiceCategory,
          orderByDateType,
        })
      );
    },
    [dispatch]
  );

  const onRemoveOrderByDateType = useCallback<
    BookingServiceCategoriesEditHandlersContextValue['onRemoveOrderByDateType']
  >(
    async (bookingServiceCategory, orderByDateType) => {
      await dispatch(
        bookingServiceCategoriesEditRemoveOrderByDateType({
          bookingServiceCategory,
          orderByDateType,
        })
      );
    },
    [dispatch]
  );

  const onDelete = useCallback<BookingServiceCategoriesEditHandlersContextValue['onDelete']>(
    async bookingServiceCategory => {
      await dispatch(bookingServiceCategoriesEditDelete(bookingServiceCategory)).unwrap();
    },
    [dispatch]
  );

  const onClose = useCallback<BookingServiceCategoriesEditHandlersContextValue['onClose']>(() => {
    gotoPrevIndependentLocation(getConfigurationPrivilegeRoute({}));
  }, [gotoPrevIndependentLocation]);

  const value: BookingServiceCategoriesEditHandlersContextValue = {
    onChangeDialogState,
    onCreate,
    onEdit,
    onAddOrderByDateType,
    onRemoveOrderByDateType,
    onDelete,
    onClose,
  };

  return (
    <BookingServiceCategoriesEditHandlersContext.Provider value={value}>
      {children}
    </BookingServiceCategoriesEditHandlersContext.Provider>
  );
};
