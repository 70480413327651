import { EDateFormat, EOfferType, ETradeOfferType, EUserGender, Nullable, SportOptionTyped, UUID } from '@/domain';
import { DataFilterAdapter } from '@/presentation/hooks/useDataFilterAdapter';
import { EOfferTableTab } from '@features/general/offer/types';
import { getOffersTableAdapterKey } from '@features/general/offer/utils/table';
import {
  ETradeOfferTableFilterItem,
  getTradeOfferTableFilterStrategy,
  TradeOfferTableFilterEditStrategy,
  tradeOfferTableFilterItems,
  TradeOfferTableFilterValues,
} from '@features/tradeOffer/filterUtils';
import { getTradeOfferAdminTableColumns, getTradeOfferPartnerTableColumns } from '@features/tradeOffer/table/utils';
import { ETradeOfferTableColumn } from '@features/tradeOffer/types';
import moment from 'moment';
import { useMemo } from 'react';

type UseCmsCollectionTradeOfferFilterAdapter = DataFilterAdapter<ETradeOfferTableColumn, ETradeOfferTableFilterItem>;

type UseCmsCollectionTradeOfferFilterAdapterProps = {
  readonly viewAsAdmin: boolean;
  readonly partnerId: Nullable<UUID>;
  readonly tab: EOfferTableTab;
  readonly values: TradeOfferTableFilterValues;
  readonly promotionTypes: SportOptionTyped<ETradeOfferType>[];
  readonly genderTypes: SportOptionTyped<EUserGender>[];
};

const useCmsCollectionTradeOfferFilterAdapter = (
  props: UseCmsCollectionTradeOfferFilterAdapterProps
): UseCmsCollectionTradeOfferFilterAdapter => {
  const { viewAsAdmin, partnerId, tab, values, promotionTypes, genderTypes } = props;

  const filterPreset = useMemo(
    () =>
      Object.keys(values)
        .map(key => {
          const strategy = getTradeOfferTableFilterStrategy(
            key as ETradeOfferTableFilterItem,
            values,
            promotionTypes,
            genderTypes
          );
          if (strategy) {
            const readonlyStrategy = { ...strategy, readOnly: true };
            switch (strategy.key) {
              case ETradeOfferTableFilterItem.TargetLocalities:
                return {
                  ...readonlyStrategy,
                  viewAlways: strategy.value === null,
                };
              case ETradeOfferTableFilterItem.StartDate:
                return {
                  ...readonlyStrategy,
                  querydsl: values[ETradeOfferTableFilterItem.StartDate]?.querydsl,
                  preview: `${moment(values[ETradeOfferTableFilterItem.EndDate]?.value).format(
                    EDateFormat.DisplayDefault
                  )} - ${moment(values[ETradeOfferTableFilterItem.StartDate]?.value).format(
                    EDateFormat.DisplayDefault
                  )}`,
                };
              case ETradeOfferTableFilterItem.EndDate:
                return {
                  ...readonlyStrategy,
                  querydsl: values[ETradeOfferTableFilterItem.EndDate]?.querydsl,
                  preview: null,
                };
              default:
                return { ...readonlyStrategy, viewAlways: undefined };
            }
          } else {
            return null;
          }
        })
        .filter(strategy => !!strategy) as TradeOfferTableFilterEditStrategy[],
    [values]
  );

  // TODO ASP - если ASP будет нужен в cms, завязать на фичу
  const sourceColumns = useMemo(
    () =>
      viewAsAdmin
        ? getTradeOfferAdminTableColumns(tab, partnerId, false)
        : getTradeOfferPartnerTableColumns(tab, false),
    [tab, partnerId, viewAsAdmin]
  );

  const { key, keyPartitions } = useMemo(
    () =>
      getOffersTableAdapterKey({
        offerType: EOfferType.Trade,
        isAdmin: viewAsAdmin ?? false,
        partnerId,
        tab,
      }),
    [tab, partnerId, viewAsAdmin]
  );

  const getTradeOfferTableFilterStrategyInternal = useMemo(
    () => (filterItem: ETradeOfferTableFilterItem, values: TradeOfferTableFilterValues) =>
      getTradeOfferTableFilterStrategy(filterItem, values, promotionTypes, genderTypes),
    [promotionTypes, genderTypes]
  );

  return useMemo<DataFilterAdapter<ETradeOfferTableColumn, ETradeOfferTableFilterItem>>(
    () => ({
      key,
      keyPartitions,
      sourceColumns: sourceColumns,
      filterItems: tradeOfferTableFilterItems,
      filterPreset,
      requiredFilters: [ETradeOfferTableFilterItem.Query],
      getDataFilterStrategy: getTradeOfferTableFilterStrategyInternal,
    }),
    [partnerId, key, keyPartitions, sourceColumns, filterPreset]
  );
};

export default useCmsCollectionTradeOfferFilterAdapter;
