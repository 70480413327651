import { EOrderStatus } from '../../../../domain/model/enums';
import { EBookingOrderActionType } from '../types';
import { BookingOrderLifeCycleStatusScheme } from './types';

export const bookingOrderAdminStatusScheme: BookingOrderLifeCycleStatusScheme = {
  description: 'Статусная схема админа заказов ДиО',
  statusScheme: {
    [EOrderStatus.New]: [EBookingOrderActionType.Confirm, EBookingOrderActionType.Give, EBookingOrderActionType.Cancel],
    [EOrderStatus.Confirmed]: [
      EBookingOrderActionType.Confirm,
      EBookingOrderActionType.Give,
      EBookingOrderActionType.Cancel,
    ],
    [EOrderStatus.Given]: [EBookingOrderActionType.Confirm, EBookingOrderActionType.Cancel],
    [EOrderStatus.Cancelled]: [EBookingOrderActionType.Confirm, EBookingOrderActionType.Give],
  },
  actionsScheme: {} as any,
};
export const bookingOrderPartnerStatusScheme = {
  ...bookingOrderAdminStatusScheme,
  description: 'Статусная схема партнера заказов ДиО',
};
