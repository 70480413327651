import order, { OrderService } from './order';

type ProductOrderServices = {
  readonly order: OrderService;
};

const productOrderServices: ProductOrderServices = {
  order,
};

export default productOrderServices;
