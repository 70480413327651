import { useSelector } from 'react-redux';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';
import BookingOfferServiceManageContainer from '../../serviceManage/container';
import { bookingOfferActionsDialogServiceManageSelector } from '../store/selectors';

const BookingOfferActionsDialogServiceManageAdapter = () => {
  const object = useSelector(bookingOfferActionsDialogServiceManageSelector);

  return (
    object && (
      <ConfirmDialog
        open
        title={'Управление услугами'}
      >
        <BookingOfferServiceManageContainer id={object.id} />
      </ConfirmDialog>
    )
  );
};

export default BookingOfferActionsDialogServiceManageAdapter;
