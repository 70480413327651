import styled from '@emotion/styled';
import { Grid, GridProps } from '@mui/material';
import React from 'react';
import { MPButton } from '../../../../theme/ui-kit/button';

interface DropzoneProps extends GridProps {
  readonly error?: boolean;
  readonly disabled?: boolean;
}

export const Dropzone: (props: DropzoneProps) => any = styled<any>(
  React.forwardRef(({ error, disabled, ...others }: DropzoneProps, ref: any) => (
    <Grid
      ref={ref}
      {...others}
    />
  ))
)`
  outline: none;
  cursor: pointer;

  border: 1px dashed ${p => (p.error ? p.theme.palette.error.main : p.theme.palette.secondary.main)};
  border-radius: ${p => p.theme.shape.borderRadius}px;

  padding: ${p => p.theme.spacing(2)} !important;

  :hover {
    border-color: ${p =>
      p.error
        ? p.theme.palette.error.dark
        : p.disabled
        ? p.theme.palette.secondary.main
        : p.theme.palette.primary.main};
  }
`;

export const Wrapper = styled(Grid)`
  position: relative;

  .MuiFormHelperText-root {
    margin-top: ${p => p.theme.spacing()};
  }

  > .MuiGrid-item {
    width: 100%;
  }
`;

export const FileButton = styled(MPButton)`
  &.MuiButton-containedSecondary {
    background-color: ${p => p.theme.palette.secondary.light};

    &:hover {
      background-color: ${p => p.theme.palette.secondary.main};
    }
  }
`;
