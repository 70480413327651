import ErrorHandler from '@/data/network/errorHandler';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { ClientOrg } from '@/domain';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import services from '../../services';

export const clientOrgDetailsByIdFetch = createAsyncThunk<ClientOrg, { id: UUID }, AppThunkAPIConfig>(
  'clientOrg/details/byId/fetch',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      return await services.clientOrg.one({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface ClientOrgDetailsState {
  readonly byId: Fetchable & {
    readonly clientOrg: Nullable<ClientOrg>;
  };
  readonly needRefreshWatcher: number;
}

type Reducer<T = undefined> = CaseReducer<ClientOrgDetailsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ClientOrgDetailsState> {
  clientOrgDetailsStateReset: Reducer;
  clientOrgDetailsNeedRefreshWatcherIncrement: Reducer;
}

const slice = createSlice<ClientOrgDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    byId: {
      ...fetchableDefault,
      clientOrg: null,
    },
    needRefreshWatcher: 0,
  },
  reducers: {
    clientOrgDetailsStateReset: state => {
      state.byId = {
        ...fetchableDefault,
        clientOrg: null,
      };
    },
    clientOrgDetailsNeedRefreshWatcherIncrement: state => {
      state.needRefreshWatcher++;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(clientOrgDetailsByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.clientOrg = null;
      })
      .addCase(clientOrgDetailsByIdFetch.fulfilled, (state, { payload }) => {
        const clientOrg = payload;
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.clientOrg = clientOrg;
      })
      .addCase(clientOrgDetailsByIdFetch.rejected, state => {
        state.byId.isFetching = false;
        state.byId.isFetched = false;
        state.byId.isFailed = true;
        state.byId.clientOrg = null;
      });
  },
});

export const { clientOrgDetailsStateReset, clientOrgDetailsSetDialogState } = slice.actions;

export default slice.reducer;
