import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterValueItem,
  EDataFilterType,
  Nullable,
} from '@/domain';
import { EReportSportPeriodType, ReportSportPeriodTypeItem } from '@features/report/types';

export enum EReportSportUsersFilterItem {
  DatePeriod = 'datePeriod',
  PeriodType = 'periodType',
}

export type ReportSportUsersFilterEditStrategy = DataFilterStrategyBase<EReportSportUsersFilterItem>;

export type ReportSportUsersFilterValues = {
  readonly [EReportSportUsersFilterItem.DatePeriod]?: DataFilterValueItem<
    Nullable<[Nullable<string>, Nullable<string>]>
  >;
  readonly [EReportSportUsersFilterItem.PeriodType]?: DataFilterValueItem<EReportSportPeriodType>;
};

export const getReportSportUsersFilterStrategies = (
  values: ReportSportUsersFilterValues,
  periodTypes: ReportSportPeriodTypeItem[]
): ReportSportUsersFilterEditStrategy[] => {
  const strategies: ReportSportUsersFilterEditStrategy[] = [];

  strategies.push(
    {
      type: EDataFilterType.DatePeriod,
      key: EReportSportUsersFilterItem.DatePeriod,
      label: '',
      labelPeriod: {
        from: 'Период с',
        to: 'по',
      },
      preview: null,
      buttonsPeriod: true,
      value: values[EReportSportUsersFilterItem.DatePeriod]?.value || null,
    },
    {
      type: EDataFilterType.ListSingle,
      key: EReportSportUsersFilterItem.PeriodType,
      label: '',
      items: periodTypes,
      preview: null,
      value: values[EReportSportUsersFilterItem.PeriodType]?.value || null,
      querydsl: {
        operator: DataFilterQueryDslOperator.Equals,
      },
    }
  );

  return strategies;
};
