import {
  DataFilterStrategyBase,
  DataFilterStrategyDateFrom,
  DataFilterStrategyDateTo,
  DataFilterStrategyEdit,
  DataFilterStrategyString,
  EDataFilterType,
} from '@/domain/model/filter';
import { Nullable } from '@/domain/model/types';
import DataFilterEditItemDatePeriod from '@/presentation/components/common/dataFilter/edit/itemDatePeriod';
import DataFilterEditItemClientOrgMultipleTypes from '@components/common/dataFilter/edit/itemClientOrg/multiple';
import DataFilterEditItemNumberPeriod from '@components/common/dataFilter/edit/itemNumberPeriod';
import DataFilterEditItemYear from '@components/common/dataFilter/edit/itemYear';
import { Grid, GridSize } from '@mui/material';
import React from 'react';
import DataFilterEditItemAddressSingle from './itemAddress/single';
import DataFilterEditItemAutocompleteMultiple from './itemAutocomplete/multiple';
import DataFilterEditItemAutocompleteSingle from './itemAutocomplete/single';
import DataFilterEditItemBooleanFlag from './itemBoolean/flag';
import DataFilterEditItemCityMultipleAddress from './itemCityAddress/multiple';
import DataFilterEditItemCityAddressMultipleTypes from './itemCityAddress/multipleTypes';
import DataFilterEditItemDate from './itemDate';
import DataFilterEditItemDateAsDateTimePeriod from './itemDateAsDateTimePeriod';
import DataFilterEditItemListMultiple from './itemList/multiple';
import DataFilterEditItemListMultipleAutocomplete from './itemList/multipleAutocomplete';
import DataFilterEditItemListMultipleTypes from './itemList/multipleTypes';
import DataFilterEditItemListSingle from './itemList/single';
import DataFilterEditItemListSingleAutocomplete from './itemList/singleAutocomplete';
import DataFilterEditItemAddressLocalityMultiple from './itemLocality/multiple';
import DataFilterEditItemAddressLocalitySingle from './itemLocality/single';
import DataFilterEditItemNumber from './itemNumber';
import DataFilterEditItemOrgUnitMultipleTypes from './itemOrgUnit/multiple';
import DataFilterEditItemRoadMultipleTypes from './itemRoad/multipleTypes';
import DataFilterEditItemString from './itemString';

interface DataFilterEditProps<T extends string> {
  readonly strategies: DataFilterStrategyEdit<T>[];
  readonly onChange: (strategies: DataFilterStrategyBase<T>[]) => void;
}

export interface DataFilterEditItemProps<T extends string, V> {
  readonly onChange: (strategy: DataFilterStrategyEdit<T, V>, value: V) => void;
}

function DataFilterEdit<T extends string>(props: DataFilterEditProps<T>) {
  const { strategies, onChange } = props;

  const onChangeItemValue = <V,>(strategy: DataFilterStrategyEdit<T, V>, value: V) => {
    const newStrategies = [...strategies];
    const newStrategy = { ...strategy, value };
    const strategyIndex = newStrategies.findIndex(ls => ls.key === strategy.key);
    newStrategies[strategyIndex] = newStrategy;
    onChange(newStrategies as DataFilterStrategyBase<T>[]);
  };

  const getItem = (item: DataFilterStrategyBase<T>) => {
    switch (item.type) {
      case EDataFilterType.String:
        // групповой фильтр - располагаем все элементы в строку
        if (item.group) {
          const currentItemIndex = strategies.findIndex(strategy => strategy === item);
          const firstCurrentGroupItemIndex = strategies.findIndex(
            strategy => (strategy as DataFilterStrategyString<T>).group === item.group
          );
          if (currentItemIndex === firstCurrentGroupItemIndex) {
            // если текущий элемент первый в группе то показываем всю группу сразу
            const groupItems = strategies.filter(
              strategy => (strategy as DataFilterStrategyString<T>).group === item.group
            );
            return (
              <React.Fragment key={item.key}>
                {groupItems.map(groupItem => (
                  <Grid
                    key={groupItem.key}
                    item
                    xs={Math.round(12 / groupItems.length) as GridSize}
                  >
                    <DataFilterEditItemString
                      strategy={groupItem as DataFilterStrategyString<T>}
                      onChange={onChangeItemValue}
                    />
                  </Grid>
                ))}
              </React.Fragment>
            );
          } else {
            // если текущий элемент не первый в группе то ничего не выводим, так как вся группа была выведена на первом элементе
            return null;
          }
        } else {
          return (
            <Grid
              key={item.key}
              item
              xs={12}
            >
              <DataFilterEditItemString
                strategy={item}
                onChange={onChangeItemValue}
              />
            </Grid>
          );
        }
      case EDataFilterType.Number:
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <DataFilterEditItemNumber
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );
      case EDataFilterType.Year:
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <DataFilterEditItemYear
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );
      case EDataFilterType.Date:
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <DataFilterEditItemDate
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );
      case EDataFilterType.DateAsTimePeriod:
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <DataFilterEditItemDateAsDateTimePeriod
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );
      case EDataFilterType.DatePeriod:
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <DataFilterEditItemDatePeriod
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );
      case EDataFilterType.ListSingle:
        if (item.autocomplete) {
          return (
            <Grid
              key={item.key}
              item
              xs={12}
            >
              <DataFilterEditItemListSingleAutocomplete
                strategy={item}
                onChange={onChangeItemValue}
              />
            </Grid>
          );
        } else {
          return (
            <Grid
              key={item.key}
              item
              xs={12}
            >
              <DataFilterEditItemListSingle
                strategy={item}
                onChange={onChangeItemValue}
              />
            </Grid>
          );
        }
      case EDataFilterType.ListMultiple: {
        if (item.autocomplete) {
          return (
            <Grid
              key={item.key}
              item
              xs={12}
            >
              <DataFilterEditItemListMultipleAutocomplete
                strategy={item}
                onChange={onChangeItemValue}
              />
            </Grid>
          );
        } else {
          return (
            <Grid
              key={item.key}
              item
              xs={12}
            >
              <DataFilterEditItemListMultiple
                strategy={item}
                onChange={onChangeItemValue}
              />
            </Grid>
          );
        }
      }
      case EDataFilterType.DateFrom: {
        const dateFromIndex = strategies.findIndex(strategy => strategy === item);
        const dateToIndex = strategies.findIndex(
          strategy =>
            (strategy as DataFilterStrategyDateTo<T>).group === item.group &&
            (strategy as DataFilterStrategyDateTo<T>).type === EDataFilterType.DateTo
        );

        if (dateFromIndex > dateToIndex && dateToIndex > -1) {
          // если текущий элемент в настройках дальше чем dateTo то вернем null, потому что логика по dateTo уже учла текущий элемент
          return null;
        }

        const dateToItem: Nullable<DataFilterStrategyDateTo<T>> =
          (strategies[dateToIndex] as DataFilterStrategyDateTo<T>) ?? null;
        if (dateToItem) {
          return (
            <React.Fragment key={item.key}>
              <Grid
                item
                xs={6}
              >
                <DataFilterEditItemDate
                  strategy={item}
                  onChange={onChangeItemValue}
                />
              </Grid>
              <Grid
                item
                xs={6}
              >
                <DataFilterEditItemDate
                  strategy={dateToItem}
                  onChange={onChangeItemValue}
                />
              </Grid>
            </React.Fragment>
          );
        } else {
          return (
            <Grid
              key={`${item.type}-${item.key}`}
              item
              xs={12}
            >
              <DataFilterEditItemDate
                strategy={item}
                onChange={onChangeItemValue}
              />
            </Grid>
          );
        }
      }
      case EDataFilterType.DateTo: {
        const dateToIndex = strategies.findIndex(strategy => strategy === item);
        const dateFromIndex = strategies.findIndex(
          strategy =>
            (strategy as DataFilterStrategyDateFrom<T>).group === item.group &&
            (strategy as DataFilterStrategyDateFrom<T>).type === EDataFilterType.DateFrom
        );

        if (dateToIndex > dateFromIndex && dateFromIndex > -1) {
          // если текущий элемент в настройках дальше чем dateFrom то вернем null, потому что логика по dateFrom уже учла текущий элемент
          return null;
        }

        const dateFromItem: Nullable<DataFilterStrategyDateFrom<T>> =
          (strategies[dateFromIndex] as DataFilterStrategyDateFrom<T>) ?? null;
        if (dateFromItem) {
          return (
            <React.Fragment key={item.key}>
              <Grid
                item
                xs={6}
              >
                <DataFilterEditItemDate
                  strategy={dateFromItem}
                  onChange={onChangeItemValue}
                />
              </Grid>
              <Grid
                item
                xs={6}
              >
                <DataFilterEditItemDate
                  strategy={item}
                  onChange={onChangeItemValue}
                />
              </Grid>
            </React.Fragment>
          );
        } else {
          return (
            <Grid
              key={`${item.type}-${item.key}`}
              item
              xs={12}
            >
              <DataFilterEditItemDate
                strategy={item}
                onChange={onChangeItemValue}
              />
            </Grid>
          );
        }
      }
      case EDataFilterType.CityMultipleAddress:
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <DataFilterEditItemCityMultipleAddress
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );
      case EDataFilterType.MultiSelectorPredicateOrgUnit:
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <DataFilterEditItemOrgUnitMultipleTypes
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );
      case EDataFilterType.MultiSelectorPredicateClientOrg:
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <DataFilterEditItemClientOrgMultipleTypes
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );
      case EDataFilterType.MultiSelectorPredicateAddress:
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <DataFilterEditItemCityAddressMultipleTypes
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );
      case EDataFilterType.MultiSelectorPredicateSportOption:
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <DataFilterEditItemListMultipleTypes
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );
      case EDataFilterType.MultiSelectorPredicateRoad:
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <DataFilterEditItemRoadMultipleTypes
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );
      case EDataFilterType.AddressLocalitySingle:
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <DataFilterEditItemAddressLocalitySingle
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );
      case EDataFilterType.AddressSingle:
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <DataFilterEditItemAddressSingle
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );
      case EDataFilterType.AddressLocalityMultiple:
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <DataFilterEditItemAddressLocalityMultiple
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );
      case EDataFilterType.AutocompleteSingle:
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <DataFilterEditItemAutocompleteSingle
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );
      case EDataFilterType.AutocompleteMultiple:
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <DataFilterEditItemAutocompleteMultiple
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );
      case EDataFilterType.BooleanFlag:
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <DataFilterEditItemBooleanFlag
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );
      case EDataFilterType.AdapterTreeMultiple:
        const Adapter = item.editAdapter;
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <Adapter
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );

      case EDataFilterType.NumberPeriod:
        return (
          <Grid
            key={item.key}
            item
            xs={12}
          >
            <DataFilterEditItemNumberPeriod
              strategy={item}
              onChange={onChangeItemValue}
            />
          </Grid>
        );
      case EDataFilterType.Target:
        console.error(`Filter item ${item} not supported`);
        return null;
    }
  };

  return (
    <Grid
      container
      spacing={2}
    >
      {strategies.map(item => getItem(item as DataFilterStrategyBase<T>))}
    </Grid>
  );
}

export default DataFilterEdit;
