import { Children, FCC } from 'react';
import { Divider, List, Wrapper } from './controls';

export interface StepperCommonHeaderProps {
  readonly bottomSplitter?: boolean;
}

export const StepperCommonHeader: FCC<StepperCommonHeaderProps> = props => {
  const { bottomSplitter = false, children } = props;
  const arrayChildren = Children.toArray(children);

  return (
    <Wrapper>
      <List>
        {Children.map(arrayChildren, (child, index) => (
          <div key={index}>{child}</div>
        ))}
      </List>
      {bottomSplitter && <Divider />}
    </Wrapper>
  );
};
