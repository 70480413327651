import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../data/store/types';
import { EOfferStatus, EPartnerStatus, EPartnerType } from '../../../../../domain/model/enums';
import { ProductDesk, ProductOffer, ProductOfferCreate } from '../../../../../domain/model/productOffer';
import { Target } from '../../../../../domain/model/target';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { GetFieldType, MultiModifier, setField } from '../../../../utils/modifier';
import offerServices from '../../../general/offer/services';
import productServices from '../../services';
import { EProductStep } from '../../types';
import { ProductOfferCreateValidation } from '../hooks/useValidProductOffer/useValidProductOffer';
import { getProductOfferCreateEmptyTarget } from '../utils';

export const productCreateStartSession = createAsyncThunk<
  ProductOfferCreate,
  { partnerId: Nullable<UUID> },
  AppThunkAPIConfig
>('product/create/start/session', async ({ partnerId }, { rejectWithValue }) => {
  try {
    // Новый товар
    const partner: ProductOfferCreate = {
      attributes: null, //  Значения характеристик товаров
      brand: null,
      category: null, //  Категория товара,
      categoryList: null, // Список всех категорий к которым относится товар
      createdAt: new Date().toISOString(), // Дата создания
      createdBy: null, // ИД автора
      currencyType: null, // Тип валюты
      deliveryConditions: null, // Условие доставки
      deliveryType: null, // Способы доставки
      description: null, // Описание товара в свободной форме
      variantName: null, // Название варианта товара для покупателя
      id: '',
      images: null,
      lastStatusAuthor: null,
      pausedReason: null, // Комментарий к последнему статусу
      lastStatusDate: null, // Дата выставления последнего статуса
      moderator: null,
      name: null, // Наименование товара
      newVersionFor: null, // 	Ссылка на предложение
      originalPrice: null, //  [multipleOf: 0.01] Цена без скидки
      otherAttributes: null, //  Описание прочих характеристик товара в свободной форме
      partner: {
        id: partnerId || '',
        name: '',
        code: 0,
        logo: null,
        status: EPartnerStatus.Enabled,
        type: EPartnerType.External,
      }, // Партнёр
      target: getProductOfferCreateEmptyTarget(),
      partnerSKU: null, // SKU в системе партнера
      productDesk: null,
      paymentConditions: null, // Условие оплаты
      paymentType: null, //  Способ оплаты
      price: null, //  [multipleOf: 0.01] Цена со скидкой
      producerSKU: null, // артикул производителя
      productCode: null, // Код карточки товара(для объединения вариантов товара)
      rejectionReasonType: null, // Тип причины отклонения предложения
      salePercent: null, // процент скидки
      status: EOfferStatus.Draft, // Статус
      stock: null, // Количество товара в наличии.
      updatedAt: null, // Дата обновления
      updatedBy: null, // ИД автора
      lastStatusComment: null,
    };

    return partner;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);

    return rejectWithValue(e.response.data);
  }
});

const productCreateViewed = createAsyncThunk<void, UUID, AppThunkAPIConfig>('product/action/viewed', async id => {
  try {
    const response = await offerServices.common.makeViewed({ id });
    return response;
  } catch (e: any) {
    console.error(`Error at call user event`, e);
  }
});

export const productCreateByIdFetch = createAsyncThunk<ProductOfferCreate, { id: UUID }, AppThunkAPIConfig>(
  'product/action/byId',
  async (prop, { rejectWithValue, dispatch }) => {
    try {
      const data = productServices.productOffer.byId(prop);
      dispatch(productCreateViewed(prop.id));
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export const productCreateDuplicate = createAsyncThunk<ProductOffer, { id: UUID }, AppThunkAPIConfig>(
  'product/action/duplicate',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productOffer.duplicate(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productDelete = createAsyncThunk<boolean, { id: UUID }, AppThunkAPIConfig>(
  'product/action/delete',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productOffer.delete(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export const productToModeration = createAsyncThunk<ProductOfferCreate, { id: UUID }, AppThunkAPIConfig>(
  'product/action/toModeration',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productOffer.toModeration(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export const productCreateStepSave = createAsyncThunk<Omit<ProductOffer, 'productDesk'>, void, AppThunkAPIConfig>(
  'product/create/step/save',
  async (prop, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const product = state.product.create.byId.product;

      const response = await productServices.productOffer.save({
        id: product!.id,
        product: product!,
      });
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productCreateArchive = createAsyncThunk<ProductOffer, { id: UUID }, AppThunkAPIConfig>(
  'product/action/archive',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productOffer.archive(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productCreateArchiveDesk = createAsyncThunk<ProductDesk, { id: UUID }, AppThunkAPIConfig>(
  'product/action/archiveDesk',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productDesk.archive(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productCreateResume = createAsyncThunk<ProductOffer, { id: UUID }, AppThunkAPIConfig>(
  'product/action/resume',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productOffer.resume(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export const productCreateResumeDesk = createAsyncThunk<ProductDesk, { id: UUID }, AppThunkAPIConfig>(
  'product/action/resumeDesk',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productDesk.resume(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productCreateUnPublish = createAsyncThunk<ProductOffer, { id: UUID }, AppThunkAPIConfig>(
  'product/action/unPublish',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productOffer.unPublish(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productCreateUnPublishDesk = createAsyncThunk<ProductDesk, { id: UUID }, AppThunkAPIConfig>(
  'product/action/unPublishDesk',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productDesk.unPublish(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productCreateReject = createAsyncThunk<
  ProductOffer,
  { id: UUID; comment: string; reasonId: UUID },
  AppThunkAPIConfig
>('product/action/reject', async (prop, { rejectWithValue }) => {
  try {
    const response = await productServices.productOffer.reject(prop);
    return response;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productCreatePause = createAsyncThunk<
  ProductOffer,
  { id: UUID; comment: Nullable<string> },
  AppThunkAPIConfig
>('product/action/pause', async (prop, { rejectWithValue }) => {
  try {
    const response = await productServices.productOffer.pause(prop);
    return response;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productCreatePauseDesk = createAsyncThunk<
  ProductDesk,
  { id: UUID; comment: Nullable<string> },
  AppThunkAPIConfig
>('product/action/pauseDesk', async (prop, { rejectWithValue }) => {
  try {
    const response = await productServices.productDesk.pause(prop);
    return response;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productCreateChangePrice = createAsyncThunk<
  ProductOffer,
  { id: UUID; price: number; originalPrice: ProductOffer['originalPrice'] },
  AppThunkAPIConfig
>('product/action/changePrice', async (prop, { rejectWithValue }) => {
  try {
    const response = await productServices.productOffer.changePrice(prop);
    return response;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productCreateChangeStock = createAsyncThunk<ProductOffer, { id: UUID; stock: number }, AppThunkAPIConfig>(
  'product/action/changeStock',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productOffer.changeStock(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface ProductCreateState {
  readonly byId: Fetchable & {
    readonly id: Nullable<UUID>;
    readonly product: Nullable<ProductOfferCreate>;
    readonly modified: boolean;
  };
  readonly actionFetchable: Fetchable;
  readonly stepSave: {
    readonly step: EProductStep;
  };
  readonly dialogs: {
    readonly changeModerator: boolean;
    readonly changePrice: boolean;
    readonly changeStock: boolean;
    readonly history: boolean;
    readonly feedback: boolean;
    readonly pause: boolean;
    readonly pauseDesk: boolean;
    readonly rejectProduct: boolean;
    readonly finally: boolean;
    readonly confirmClose: boolean;
    readonly archive: boolean;
    readonly archiveDesk: boolean;
    readonly resetCategory: boolean;
  };
  readonly validation: ProductOfferCreateValidation;
}

type Reducer<T = undefined> = CaseReducer<ProductCreateState, PayloadAction<T>>;

type FieldProductCreateState<T extends string = keyof ProductOffer | string> = {
  name: T;
  value?: GetFieldType<ProductOffer, T>;
};

type TargetFieldProductCreateState<T extends string = keyof Target | string> = {
  name: T;
  value?: GetFieldType<Target, T>;
};

type FieldProductCreateDialogsState = {
  name: keyof ProductCreateState['dialogs'];
  value: boolean;
};

interface Reducers extends SliceCaseReducers<ProductCreateState> {
  productCreateStateReset: Reducer;
  productCreateSetField: Reducer<FieldProductCreateState>;
  productCreateSetTargetField: Reducer<TargetFieldProductCreateState>;
  productCreateSetDialogs: Reducer<FieldProductCreateDialogsState>;
  productCreateSetFields: Reducer<Parameters<MultiModifier<ProductOfferCreate>>[0]>;
  productCreateSetValidation: Reducer<ProductOfferCreateValidation>;
}

const slice = createSlice<ProductCreateState, Reducers, 'product/create'>({
  name: 'product/create',
  initialState: {
    byId: {
      ...fetchableDefault,
      id: null,
      product: null,
      modified: false,
    },
    actionFetchable: {
      ...fetchableDefault,
    },
    stepSave: {
      step: EProductStep.General,
    },
    dialogs: {
      changeModerator: false,
      changePrice: false,
      changeStock: false,
      history: false,
      feedback: false,
      pause: false,
      pauseDesk: false,
      rejectProduct: false,
      finally: false,
      confirmClose: false,
      archive: false,
      archiveDesk: false,
      resetCategory: false,
    },
    validation: {},
  },
  reducers: {
    productCreateStateReset: state => {
      state.byId.product = null;
      state.actionFetchable = fetchableDefault;
      state.stepSave = { ...fetchableDefault, step: EProductStep.General };
    },
    productCreateSetField: (state, { payload }) => {
      const { name, value } = payload;
      state.byId.modified = true;

      if (state.byId.product) {
        setField(state.byId.product, name, value);
      }
    },
    productCreateSetTargetField: (state, { payload }) => {
      const { name, value } = payload;
      state.byId.modified = true;

      if (state.byId.product) {
        setField(state.byId.product.target, name, value);
      }
    },
    productCreateSetDialogs: (state, { payload }) => {
      const { name, value } = payload;
      setField(state.dialogs, name, value);
    },
    productCreateSetFields: (state, { payload }) => {
      state.byId.modified = true;

      if (state.byId.product) {
        Object.entries(payload).forEach(([key, value]) => {
          setField(state.byId.product, key, value);
        });
      }
    },
    productCreateSetValidation: (state, { payload }) => {
      state.validation = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(productCreateStartSession.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.modified = false;
      })
      .addCase(productCreateStartSession.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.id = payload.id;
        state.byId.product = payload;

        state.byId.modified = false;
      })
      .addCase(productCreateStartSession.rejected, state => {
        state.byId.isFetching = false;
        state.byId.isFetched = false;
        state.byId.isFailed = true;
        state.byId.modified = false;
      })

      .addCase(productCreateStepSave.pending, state => {
        state.actionFetchable = fetchableFetching;
      })
      .addCase(productCreateStepSave.fulfilled, (state, { payload }) => {
        state.actionFetchable = fetchableFetched;
        state.byId.modified = false;

        state.byId.id = payload.id;
        state.byId.product = {
          productDesk: state.byId.product?.productDesk || null,
          ...state.byId.product,
          ...payload,
        };
      })
      .addCase(productCreateStepSave.rejected, state => {
        state.actionFetchable = fetchableFailed;
      })

      .addCase(productCreateByIdFetch.pending, state => {
        state.actionFetchable = fetchableFetching;
      })
      .addCase(productCreateByIdFetch.fulfilled, (state, { payload }) => {
        state.actionFetchable = fetchableFetched;
        state.byId.modified = false;

        state.byId.id = payload.id;
        state.byId.product = {
          ...state.byId.product,
          ...payload,
        };
      })
      .addCase(productCreateByIdFetch.rejected, state => {
        state.actionFetchable = fetchableFailed;
      })

      .addCase(productToModeration.pending, state => {
        state.actionFetchable = fetchableFetching;
      })
      .addCase(productToModeration.fulfilled, (state, { payload }) => {
        state.actionFetchable = fetchableFetched;
        state.byId.modified = false;
        state.byId.id = payload.id;
        state.byId.product = {
          ...state.byId.product,
          ...payload,
        };
      })
      .addCase(productToModeration.rejected, state => {
        state.actionFetchable = fetchableFailed;
      })

      .addCase(productCreateDuplicate.pending, state => {
        state.actionFetchable = fetchableFetching;
      })
      .addCase(productCreateDuplicate.fulfilled, (state, { payload }) => {
        const { productDesk, ...data } = payload;
        state.actionFetchable = fetchableFetched;
        if (state.byId.product) {
          state.byId.product = {
            ...state.byId.product,
            ...data,
          };
        }
      })
      .addCase(productCreateDuplicate.rejected, state => {
        state.actionFetchable = fetchableFailed;
      })

      .addCase(productCreateArchive.pending, state => {
        state.actionFetchable = fetchableFetching;
      })
      .addCase(productCreateArchive.fulfilled, (state, { payload }) => {
        const { productDesk, ...data } = payload;
        state.actionFetchable = fetchableFetched;
        if (state.byId.product) {
          state.byId.product = {
            ...state.byId.product,
            ...data,
          };
        }
      })
      .addCase(productCreateArchive.rejected, state => {
        state.actionFetchable = fetchableFailed;
      })

      .addCase(productCreateArchiveDesk.pending, state => {
        state.actionFetchable = fetchableFetching;
      })
      .addCase(productCreateArchiveDesk.fulfilled, state => {
        state.actionFetchable = fetchableFetched;
      })
      .addCase(productCreateArchiveDesk.rejected, state => {
        state.actionFetchable = fetchableFailed;
      })

      .addCase(productCreateResume.pending, state => {
        state.actionFetchable = fetchableFetching;
      })
      .addCase(productCreateResume.fulfilled, (state, { payload }) => {
        const { productDesk, ...data } = payload;
        state.actionFetchable = fetchableFetched;
        if (state.byId.product) {
          state.byId.product = {
            ...state.byId.product,
            ...data,
          };
        }
      })
      .addCase(productCreateResume.rejected, state => {
        state.actionFetchable = fetchableFailed;
      })

      .addCase(productCreateResumeDesk.pending, state => {
        state.actionFetchable = fetchableFetching;
      })
      .addCase(productCreateResumeDesk.fulfilled, state => {
        state.actionFetchable = fetchableFetched;
      })
      .addCase(productCreateResumeDesk.rejected, state => {
        state.actionFetchable = fetchableFailed;
      })

      .addCase(productCreateUnPublishDesk.pending, state => {
        state.actionFetchable = fetchableFetching;
      })
      .addCase(productCreateUnPublishDesk.fulfilled, state => {
        state.actionFetchable = fetchableFetched;
      })
      .addCase(productCreateUnPublishDesk.rejected, state => {
        state.actionFetchable = fetchableFailed;
      })

      .addCase(productCreateUnPublish.pending, state => {
        state.actionFetchable = fetchableFetching;
      })
      .addCase(productCreateUnPublish.fulfilled, (state, { payload }) => {
        const { productDesk, ...data } = payload;
        state.actionFetchable = fetchableFetched;
        if (state.byId.product) {
          state.byId.product = {
            ...state.byId.product,
            ...data,
          };
        }
      })
      .addCase(productCreateUnPublish.rejected, state => {
        state.actionFetchable = fetchableFailed;
      })

      .addCase(productCreateReject.pending, state => {
        state.actionFetchable = fetchableFetching;
      })
      .addCase(productCreateReject.fulfilled, (state, { payload }) => {
        const { productDesk, ...data } = payload;
        state.actionFetchable = fetchableFetched;
        if (state.byId.product) {
          state.byId.product = {
            ...state.byId.product,
            ...data,
          };
        }
      })
      .addCase(productCreateReject.rejected, state => {
        state.actionFetchable = fetchableFailed;
      })

      .addCase(productCreatePause.pending, state => {
        state.actionFetchable = fetchableFetching;
      })
      .addCase(productCreatePause.fulfilled, (state, { payload }) => {
        const { productDesk, ...data } = payload;
        state.actionFetchable = fetchableFetched;
        if (state.byId.product) {
          state.byId.product = {
            ...state.byId.product,
            ...data,
          };
        }
      })
      .addCase(productCreatePause.rejected, state => {
        state.actionFetchable = fetchableFailed;
      })

      .addCase(productCreatePauseDesk.pending, state => {
        state.actionFetchable = fetchableFetching;
      })
      .addCase(productCreatePauseDesk.fulfilled, state => {
        state.actionFetchable = fetchableFetched;
      })
      .addCase(productCreatePauseDesk.rejected, state => {
        state.actionFetchable = fetchableFailed;
      })

      .addCase(productCreateChangePrice.pending, state => {
        state.actionFetchable = fetchableFetching;
      })
      .addCase(productCreateChangePrice.fulfilled, (state, { payload }) => {
        const { productDesk, ...data } = payload;
        state.actionFetchable = fetchableFetched;
        if (state.byId.product) {
          state.byId.product = {
            ...state.byId.product,
            ...data,
          };
        }
      })
      .addCase(productCreateChangePrice.rejected, state => {
        state.actionFetchable = fetchableFailed;
      })

      .addCase(productCreateChangeStock.pending, state => {
        state.actionFetchable = fetchableFetching;
      })
      .addCase(productCreateChangeStock.fulfilled, (state, { payload }) => {
        const { productDesk, ...data } = payload;
        state.actionFetchable = fetchableFetched;
        if (state.byId.product) {
          state.byId.product = {
            ...state.byId.product,
            ...data,
          };
        }
      })
      .addCase(productCreateChangeStock.rejected, state => {
        state.actionFetchable = fetchableFailed;
      });
  },
});

export const {
  productCreateSetValidation,
  productCreateStateReset,
  productCreateSetField,
  productCreateSetTargetField,
  productCreateSetFields,
  productCreateSetDialogs,
} = slice.actions;

export default slice.reducer;
