import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../data/store/store';
import { BookingOrderEditFieldCommonProps, BookingOrderView } from '../../types';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingOrderEditBookingOrderIsPresentSelector } from '../store/selectors';

interface BookingOrderEditFieldAdapterProps<Key extends keyof BookingOrderView> {
  readonly name: Key;
  readonly component: FC<BookingOrderEditFieldCommonProps<Key>>;
  readonly valueSelector: (store: RootState) => BookingOrderView[Key];
}

export const BookingOrderEditFieldAdapter = <Key extends keyof BookingOrderView>(
  props: BookingOrderEditFieldAdapterProps<Key>
) => {
  const { valueSelector, component: Component } = props;

  const handlers = useContextHandlers();

  const orderIsPresent = useSelector(bookingOrderEditBookingOrderIsPresentSelector);
  const value = useSelector(valueSelector);

  return orderIsPresent ? (
    <Component
      value={value}
      disabled={false}
      validation={null}
      onChangeAttribute={handlers.onChangeAttribute}
      onValidateAttribute={() => {}}
    />
  ) : null;
};
