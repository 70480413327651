import { FCC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { bookingServicePriceUnitsEditFetch, bookingServicePriceUnitsEditResetState } from './store/slice';
import { BookingServicePriceUnitsEditCommonProps } from './types';

type BookingServicePriceUnitsEditInitializerProps = BookingServicePriceUnitsEditCommonProps;

export const BookingServicePriceUnitsEditInitializer: FCC<BookingServicePriceUnitsEditInitializerProps> = ({
  children,
}) => {
  const dispatch = useDispatch();

  //загрузка основных данных
  useEffect(() => {
    const requests = [dispatch(bookingServicePriceUnitsEditFetch())];
    return () => {
      requests.forEach(request => request.abort());
    };
  }, [dispatch]);

  //очистка данных
  useEffect(() => {
    return () => {
      dispatch(bookingServicePriceUnitsEditResetState());
    };
  }, [dispatch]);

  return <>{children}</>;
};
