import { EDateFormat } from '@/domain';
import { MPDatePicker } from '@ui-kit/picker';
import moment from 'moment/moment';
import { AdCampaignCreateFieldCommonProps } from '../../types';

const fieldName = 'startDate';

const AdCampaignCreateFieldStartDate = (props: AdCampaignCreateFieldCommonProps<typeof fieldName>) => {
  const { value, validation, disabled, onChangeAttribute, onValidateAttribute } = props;

  const onChange = (date: Nullable<moment.Moment>) => {
    const d = date === null ? null : moment(date).format(EDateFormat.Server);
    onChangeAttribute(fieldName, d);
    onValidateAttribute(fieldName);
  };

  return (
    <MPDatePicker
      label='Начало'
      disabled={disabled}
      value={value}
      format={EDateFormat.Human}
      error={validation?.hasError}
      helperText={validation?.message}
      onChange={onChange}
    />
  );
};

export default AdCampaignCreateFieldStartDate;
