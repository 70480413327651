import { CmsBanner } from '../../../../../../domain/model/cms';
import { Nullable } from '../../../../../../domain/model/types';
import EntityMasterPreviewCarousel from '../../../../../components/common/entityMaster/preview/carousel';
import { EEntityPreviewMode } from '../../../../../types';
import CmsCarouselPreviewBlock from './block';

interface CmsCarouselPreviewProps {
  readonly mode?: EEntityPreviewMode;
  readonly banners: Nullable<CmsBanner>[];
  readonly activePreviewNumber?: Nullable<number | false>;
  readonly canActivateItem?: boolean;
}

const CmsCarouselPreview = (props: CmsCarouselPreviewProps) => {
  const { mode = EEntityPreviewMode.Desktop, activePreviewNumber, canActivateItem, banners } = props;

  const foregroundNumber = activePreviewNumber || 0;

  return (
    <EntityMasterPreviewCarousel>
      {banners.map((b, i) => (
        <CmsCarouselPreviewBlock
          key={b?.id ?? i}
          number={i + 1}
          image={mode === EEntityPreviewMode.Desktop ? b?.desktopImage : b?.mobileImage}
          isForeground={foregroundNumber === i}
          isActive={activePreviewNumber === i}
          showNumber={
            canActivateItem && mode === EEntityPreviewMode.Desktop && banners.length > 1 && i === foregroundNumber
          }
          canActivateItem={canActivateItem}
        />
      ))}
    </EntityMasterPreviewCarousel>
  );
};

export default CmsCarouselPreview;
