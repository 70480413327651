import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Partner } from '../../../domain/model/partner';
import { MpUser } from '../../../domain/model/user';
import ForbiddenComponent from '../../components/common/forbidden';
import CustomerDetailsContainer from '../../features/customer/details/container';
import { viewShowCustomerManagementByPartnerMenu } from '../../features/customer/management/byPartner/store/slice';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface CustomerManagementByPartnerScreenProps {
  readonly customer: MpUser;
  readonly partner: Partner;
}

const CustomerManagementByPartnerScreen = (props: CustomerManagementByPartnerScreenProps) => {
  const { customer } = props;

  const dispatch = useDispatch();
  const { customers } = useCurrentUser().accessMatrix;

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowCustomerManagementByPartnerMenu());
  }, [dispatch]);

  if (!customers?.view?.details) {
    return <ForbiddenComponent />;
  }

  return (
    <CustomerDetailsContainer
      id={customer.id}
      canChangeStatus={customers?.changeStatus}
    />
  );
};

export default CustomerManagementByPartnerScreen;
