import { CorpOffer } from '../../../../../domain/model/corpOffer';
import { AppUserSpecific } from '../../../../../domain/model/user';
import { CorpOfferLifeCycle } from '../../../general/offer/lifecycle/types';
import {
  EOfferActionType,
  EOfferTableTab,
  OfferActions,
  OfferActionTableCollectionType,
  OfferActionTableType,
} from '../../../general/offer/types';
import { renameOfferAction } from '../../../general/offer/utils/actions';

interface UseCorpOfferCollectionActionsProps {
  readonly tab: EOfferTableTab;
  readonly userSpecific: AppUserSpecific;
  readonly lifecycle: CorpOfferLifeCycle;
  readonly corpOffers: CorpOffer[];
  readonly allowedActions: OfferActions<OfferActionTableType>;
}

type UseCorpOfferCollectionActionsResult = OfferActions<OfferActionTableCollectionType>;

const intersectActions = (a: OfferActions<OfferActionTableType>, b: OfferActions<OfferActionTableType>) =>
  a
    // фильтруем для определения пересечений
    .filter(itemA => b.some(itemB => itemA.type === itemB.type))
    // ставим disabled если действие отключено в любом из массивов
    .map(itemA => ({
      ...itemA,
      disabled: itemA.disabled && b.find(itemB => itemA.type === itemB.type)?.disabled,
    }));

const useCorpOfferCollectionActions = (
  props: UseCorpOfferCollectionActionsProps
): UseCorpOfferCollectionActionsResult => {
  const { tab, lifecycle, corpOffers, allowedActions } = props;

  switch (tab) {
    case EOfferTableTab.Moderation: {
      const resultActions = corpOffers.reduce<OfferActions<OfferActionTableType>>((prev, offer) => {
        // получаем ограничения ЖЦ
        const restrictions = lifecycle.build({ obj: offer, allowedActions });
        const lifecycleActions = restrictions.nextActions as OfferActions<OfferActionTableType>;

        // фильтруем допустимые действия для массовых модификаций
        const collectionActions = lifecycleActions.filter(action =>
          [EOfferActionType.Approve, EOfferActionType.ChangeApprovalRegistry].includes(action.type)
        );

        // рассчитываем пересечения
        return intersectActions(prev.length ? prev : collectionActions, collectionActions);
      }, []) as OfferActions<OfferActionTableCollectionType>;

      // меняем label у действий
      renameOfferAction(resultActions, EOfferActionType.Approve, 'Опубликовать все КП с приказом');
      renameOfferAction(resultActions, EOfferActionType.ChangeApprovalRegistry, 'Присвоить приказ');

      return resultActions;
    }
    default: {
      return [];
    }
  }
};

export default useCorpOfferCollectionActions;
