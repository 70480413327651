import { DrawerProps, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Property } from 'csstype';
import { CloseButton, Content, Drawer, Footer, Header } from './controls';
import { ReactNode } from 'react';

export interface TopPushDialogProps extends DrawerProps {
  readonly leftOffset?: number | string;
  readonly title?: any;
  readonly footer?: ReactNode;
  readonly fullScreen?: boolean;
  readonly stickyHeader?: boolean;
  readonly backdropAdjust?: any;
  readonly contentOverflow?: Property.Overflow;
}

const TopPushDialog = (props: TopPushDialogProps) => {
  const { leftOffset, backdropAdjust, title, footer, stickyHeader, fullScreen, contentOverflow, children, ...others } =
    props;
  const { onClose } = others;

  return (
    <Drawer
      leftOffset={leftOffset ?? 0}
      fullScreen={fullScreen}
      backdropAdjust={backdropAdjust}
      elevation={0}
      {...others}
      anchor='top'
    >
      {(onClose || title) && (
        <Header
          container
          alignItems='center'
          spacing={0}
          sticky={stickyHeader}
        >
          {onClose && (
            <Grid item>
              <CloseButton
                size='large'
                onClick={event => onClose?.(event, 'backdropClick')}
              >
                <CloseIcon />
              </CloseButton>
            </Grid>
          )}
          {title && (
            <Grid
              item
              xs={12}
            >
              <Typography variant='subtitle2'>{title}</Typography>
            </Grid>
          )}
        </Header>
      )}
      <Content overflow={contentOverflow}>{children}</Content>
      {footer && <Footer>{footer}</Footer>}
    </Drawer>
  );
};

export default TopPushDialog;
