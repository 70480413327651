import TradeOfferTableCell, { TradeOfferTableCellCommonProps } from './index';

const TradeOfferTableCellTargetExternalUsers = (props: TradeOfferTableCellCommonProps) => {
  const {
    tradeOffer: { target },
  } = props;

  const value = target.targetExternalUsers ? 'Да' : '';

  return (
    <TradeOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default TradeOfferTableCellTargetExternalUsers;
