import { Fade, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Banner } from '../../../../domain/model/banner';
import { EBannerPartition, EBannerPlace, EBannerStatus } from '../../../../domain/model/enums';
import { UUID } from '../../../../domain/model/types';
import MasterActionsComponent from '../../../components/common/actions/master';
import DefaultHeader from '../../../components/common/header';
import ContentLoader from '../../../components/common/loader';
import { StepperCommonHeader } from '../../../components/common/stepper/common/header';
import { DefaultContentWrapper } from '../../../components/common/wrappers/content';
import { DefaultFooterWrapper } from '../../../components/common/wrappers/footer';
import useHistoryExtensions from '../../../hooks/useHistoryExtensions';
import { PanelAction, PanelActions } from '../../../types';
import { nsiDataSelector } from '../../general/nsi/store/selectors';
import StepperContainer from '../../general/stepper/container';
import { getBannerEditRoute, getBannersTableRoute } from '../entry';
import { BannerActionDetailsType, EBannerActionType } from '../types';
import { getBannerActionTitle, getBannerDetailsActions, getBannerPartitionByPlaces } from '../utils';
import BannerDetails from './component';
import { ContainerWrapper, ContentContainer, LoaderWrapper, TitleWrapper } from './controls';
import { bannerDetailsSelector } from './store/selectors';
import { bannerDetailsFetch, bannerDetailsReset } from './store/slice';

interface BannerDetailsContainerProps {
  readonly id: UUID;
  readonly canEdit?: boolean;
}

const BannerDetailsContainer = ({ id, canEdit = false }: BannerDetailsContainerProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const { bannerPlaces } = useSelector(nsiDataSelector);
  const { data: banner, isFetching } = useSelector(bannerDetailsSelector);

  const onEdit = () => {
    history.push(getBannerEditRoute({ id }));
  };

  const onClose = () => {
    gotoPrevIndependentLocation(getBannersTableRoute({ partition: EBannerPartition.TradeOffers }));
  };

  const onPanelAction = (action: PanelAction<BannerActionDetailsType>) => {
    const { type } = action;
    switch (type) {
      case EBannerActionType.Edit:
        onEdit();
        break;
    }
  };

  useEffect(() => {
    dispatch(bannerDetailsFetch(id));
    return () => {
      dispatch(bannerDetailsReset());
    };
  }, [dispatch]);

  const StepperHeader = ({ headerBanner }: { headerBanner: Banner }) => (
    <StepperCommonHeader>
      <Grid item>{headerBanner.code}</Grid>
    </StepperCommonHeader>
  );

  const canModify: boolean = canEdit && !!banner && banner.status !== EBannerStatus.Archived;
  const partition = getBannerPartitionByPlaces(banner?.places ?? [], EBannerPlace.TradeOffersListPageTop);

  const actions: PanelActions<BannerActionDetailsType> = getBannerDetailsActions(canModify);

  const actionsPanel = actions.length > 0 && (
    <MasterActionsComponent<BannerActionDetailsType>
      show={!!actions.length}
      actions={actions}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );

  return (
    <Fade in>
      <ContainerWrapper>
        {banner && (
          <>
            <StepperContainer header={<StepperHeader headerBanner={banner} />} />
            <ContentContainer>
              <DefaultContentWrapper
                type='details'
                stickyHeader
                fullHeight
                footer={actionsPanel}
              >
                <DefaultHeader
                  sticky
                  onClose={onClose}
                >
                  <TitleWrapper>
                    <Typography variant='h2'>{getBannerActionTitle(banner, partition)}</Typography>
                  </TitleWrapper>
                </DefaultHeader>
                <BannerDetails
                  banner={banner}
                  bannerPlaces={bannerPlaces}
                />
              </DefaultContentWrapper>
            </ContentContainer>
          </>
        )}
        {isFetching && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
      </ContainerWrapper>
    </Fade>
  );
};

export default BannerDetailsContainer;
