import { CommonLifeCycleInstance } from '../../../utils/lifecycle';
import { productOrderAdminConfiguration, productOrderPartnerConfiguration } from './configuration';
import { ProductOrderLifeCycle, ProductOrderLifeCycleFactoryProps, ProductOrderLifeCycleProps } from './types';

/**
 * жизненный цикл заказа КП
 */
const ProductOrderLifeCycleInstance = (props: ProductOrderLifeCycleProps): ProductOrderLifeCycle => {
  const { configuration } = props;

  const commonLifecycle = CommonLifeCycleInstance(props);

  return {
    configuration,
    build: ({ allowedActions, obj }) => {
      const commonLifecycleBuild = commonLifecycle.build({ allowedActions, obj });

      return {
        ...commonLifecycleBuild,
      };
    },
  };
};

/**
 * фабрика создания жизненнешго цикла заказа
 */
export const ProductOrderLifeCycleFactory = () => ({
  create: ({ isPartnerUser, userId, debug }: ProductOrderLifeCycleFactoryProps): ProductOrderLifeCycle => {
    if (isPartnerUser) {
      return ProductOrderLifeCycleInstance({ userId, configuration: productOrderPartnerConfiguration, debug });
    } else {
      return ProductOrderLifeCycleInstance({ userId, configuration: productOrderAdminConfiguration, debug });
    }
  },
});
