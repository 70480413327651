import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ForbiddenComponent from '../../../components/common/forbidden';
import { ConfigurationLocationState } from '../../../features/configuration/entry';
import ConfigurationPrivilegeContainer from '../../../features/configuration/privilege/container';
import LocationWithRequiredStateContainer from '../../../features/general/locationExtension/withRequiredState';
import { viewShowMainMenu } from '../../../features/main/container/store/slice';
import useCurrentUser from '../../../features/user/hooks/useCurrentUser';

const ConfigurationPrivilegeScreen = () => {
  const dispatch = useDispatch();
  const {
    configuration,
    corpCategories,
    tradeCategories,
    bookingOfferCategories,
    bookingOfferPriceUnits,
    bookingServiceCategories,
  } = useCurrentUser().accessMatrix;

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (!configuration?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<ConfigurationLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <ConfigurationPrivilegeContainer
          guid={state.guid}
          canEditConfiguration={configuration.edit}
          canEditCorpCategories={corpCategories.edit || corpCategories.delete || corpCategories.create}
          canEditTradeCategories={tradeCategories.edit || tradeCategories.delete || tradeCategories.create}
          canEditBookingOfferCategories={
            bookingOfferCategories.edit || bookingOfferCategories.delete || bookingOfferCategories.create
          }
          canEditBookingServiceCategories={
            bookingServiceCategories.edit || bookingServiceCategories.delete || bookingServiceCategories.create
          }
          canEditBookingOfferPriceUnits={
            bookingOfferPriceUnits.edit || bookingOfferPriceUnits.delete || bookingOfferPriceUnits.create
          }
        />
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default ConfigurationPrivilegeScreen;
