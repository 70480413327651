import { combineReducers } from '@reduxjs/toolkit';
import actions, { ProductOrderActionsState } from './actions/store/slice';
import edit, { ProductOrderEditState } from './edit/store/slice';
import list, { ProductOrderListState } from './table/store/slice';

interface ProductOrderState {
  edit: ProductOrderEditState;
  list: ProductOrderListState;
  actions: ProductOrderActionsState;
}

export default combineReducers<ProductOrderState>({
  edit,
  list,
  actions,
});
