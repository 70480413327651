import { getUserShortName } from '../../../../../../utils';
import OfferHistoryTableCell, { OfferHistoryTableCellCommonProps } from './index';

const OfferHistoryTableCellEnteredBy = (props: OfferHistoryTableCellCommonProps) => {
  const {
    history: { enteredBy },
  } = props;

  const value = enteredBy ? getUserShortName(enteredBy) : '-';

  return (
    <OfferHistoryTableCell
      {...props}
      value={value}
    />
  );
};

export default OfferHistoryTableCellEnteredBy;
