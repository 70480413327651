import React from 'react';
import { UseOfferHistoryTable } from './hooks/useOfferHistoryTable';
import { UseOfferHistoryTableConfig } from './hooks/useOfferHistoryTableConfig';

export type OfferHistoryTableContextValue = UseOfferHistoryTable;

export const OfferHistoryTableContext = React.createContext<OfferHistoryTableContextValue>(
  {} as OfferHistoryTableContextValue
);

export type OfferHistoryTableConfigContextValue = UseOfferHistoryTableConfig;

export const OfferHistoryTableConfigContext = React.createContext<OfferHistoryTableConfigContextValue>(
  {} as OfferHistoryTableConfigContextValue
);
