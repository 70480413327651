import styled from '@emotion/styled';

interface WrapperProps {
  readonly bottomAnchor?: HTMLElement;
  readonly children: any;
}

export const Wrapper = styled(({ bottomAnchor, ...others }: WrapperProps) => <div {...others} />)`
  ${p =>
    p.bottomAnchor &&
    `
    .os-scrollbar-horizontal {
      position: fixed !important;
      bottom: calc(100% - ${p.bottomAnchor.getBoundingClientRect().y}px) !important;
      left: ${p.bottomAnchor.getBoundingClientRect().x}px !important;
      right: ${p.theme.spacing()} !important;
    }
  `}
  & .os-scrollbar {
    pointer-events: auto;
    transition: none;

    &.os-scrollbar-horizontal {
      height: 1.5rem;

      box-sizing: border-box;
      padding: ${p => p.theme.spacing()};
    }

    &.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
      background: ${p => p.theme.scrollBar.background} !important;

      border-radius: 0;
    }
  }
`;
