import { ReactNode } from 'react';
import ClientOrgUserActionsContext, { ClientOrgUserActionsContextType } from './context';
import { useDispatch } from 'react-redux';
import { clientOrgUserActionsChangeDialogState, clientOrgUserActionsImport } from './store/slice';
import {
  getClientOrgUserActionsOfferIsProcessingSelector,
  getIsClientOrgUserAnyActionByTypeExecutedSelector,
  isClientOrgUserAnyChangedSelector,
} from './store/selectors';
import { ClientOrgUserActionsDialogsAdapter, ClientOrgUserActionsOptimizerAdapter } from './adapters';
import { showClientOrgActionNotification } from '@features/user/userClientOrg/actions/utils';
import { EClientOrgUserActionType } from '@features/user/userClientOrg/types';

interface ClientOrgActionsProviderProps {
  readonly children: ReactNode;
}

type ClientOrgActionsProviderType = (props: ClientOrgActionsProviderProps) => JSX.Element;

export const ClientOrgUserActionsProvider: ClientOrgActionsProviderType = ({ children }) => {
  const dispatch = useDispatch();

  const onChangeDialogState: ClientOrgUserActionsContextType['onChangeDialogState'] = (name, data) => {
    dispatch(clientOrgUserActionsChangeDialogState({ name, data }));
  };

  const onImport: ClientOrgUserActionsContextType['onImport'] = async ({ clientOrgId, file }) => {
    const response = await dispatch(clientOrgUserActionsImport({ id: clientOrgId, file })).unwrap();

    if (response) {
      showClientOrgActionNotification(EClientOrgUserActionType.Import);
    }

    return response;
  };

  const onTryImport: ClientOrgUserActionsContextType['onTryImport'] = clientOrg => {
    onChangeDialogState('import', clientOrg);
  };

  return (
    <ClientOrgUserActionsContext.Provider
      value={{
        onImport,

        onTryImport,

        onChangeDialogState,
        utils: {
          selectors: {
            getIsClientOrgUserExecutedActionsSelector: getIsClientOrgUserAnyActionByTypeExecutedSelector,
            getIsClientOrgUserProcessingSelector: getClientOrgUserActionsOfferIsProcessingSelector,
            isClientOrgUsersAnyChangedSelector: isClientOrgUserAnyChangedSelector,
          },
        },
      }}
    >
      {children}
      <ClientOrgUserActionsOptimizerAdapter />
      <ClientOrgUserActionsDialogsAdapter />
    </ClientOrgUserActionsContext.Provider>
  );
};
