import { FCC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useHistoryExtensions from '../../../../../hooks/useHistoryExtensions';
import { getConfigurationPrivilegeRoute } from '../../../../configuration/entry';
import { TradeOfferCategoriesEditHandlersContext, TradeOfferCategoriesEditHandlersContextValue } from '../context';
import {
  tradeOfferCategoriesEditCheckRelationShips,
  tradeOfferCategoriesEditCreate,
  tradeOfferCategoriesEditDelete,
  tradeOfferCategoriesEditSetDialogState,
  tradeOfferCategoriesEditUpdate,
} from '../store/slice';
import { TradeOfferCategoriesEditCommonProps } from '../types';

export const TradeOfferCategoriesEditHandlersProvider: FCC<TradeOfferCategoriesEditCommonProps> = ({
  children,
  access,
}) => {
  const dispatch = useDispatch();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const onChangeDialogState = useCallback<TradeOfferCategoriesEditHandlersContextValue['onChangeDialogState']>(
    (name, data) => {
      dispatch(tradeOfferCategoriesEditSetDialogState({ name, data }));
    },
    [dispatch]
  );

  const onCreate = useCallback<TradeOfferCategoriesEditHandlersContextValue['onCreate']>(
    async tradeOfferCategory => {
      return await dispatch(tradeOfferCategoriesEditCreate(tradeOfferCategory)).unwrap();
    },
    [dispatch]
  );

  const onEdit = useCallback<TradeOfferCategoriesEditHandlersContextValue['onEdit']>(
    async tradeOfferCategory => {
      return await dispatch(tradeOfferCategoriesEditUpdate(tradeOfferCategory)).unwrap();
    },
    [dispatch]
  );

  const onDelete = useCallback<TradeOfferCategoriesEditHandlersContextValue['onDelete']>(
    async tradeOfferCategory => {
      await dispatch(tradeOfferCategoriesEditDelete(tradeOfferCategory)).unwrap();
    },
    [dispatch]
  );

  const onGetRelationShipsCount = useCallback<TradeOfferCategoriesEditHandlersContextValue['onGetRelationShipsCount']>(
    async tradeOfferCategory => {
      return await dispatch(tradeOfferCategoriesEditCheckRelationShips(tradeOfferCategory)).unwrap();
    },
    [dispatch]
  );

  const onClose = useCallback<TradeOfferCategoriesEditHandlersContextValue['onClose']>(() => {
    gotoPrevIndependentLocation(getConfigurationPrivilegeRoute({}));
  }, [gotoPrevIndependentLocation]);

  const value: TradeOfferCategoriesEditHandlersContextValue = {
    onChangeDialogState,
    onCreate,
    onEdit,
    onDelete,
    onGetRelationShipsCount,
    onClose,
  };

  return (
    <TradeOfferCategoriesEditHandlersContext.Provider value={value}>
      {children}
    </TradeOfferCategoriesEditHandlersContext.Provider>
  );
};
