import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';
import { ENoticeStatus } from '../../../../../domain/model/enums';
import Notifier from '../../../../../system/notifier';

const downloadURL = (data: string, fileName: string) => {
  const a = document.createElement('a');
  a.href = data;
  a.download = fileName;
  a.style.display = 'none';

  document.body.appendChild(a);
  a.click();
  a.remove();
};

export type UseFileDownload = {
  readonly isDownloading: boolean;
  readonly download: () => void;
};

interface UseFileDownloadProps {
  readonly src: string;
  readonly accept?: string;
  readonly fileName?: string;
  readonly onDownload?: (success: boolean) => void;
}

const useFileDownload = (props: UseFileDownloadProps): UseFileDownload => {
  const { src, accept, fileName, onDownload } = props;
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const axiosInstance = axios;

  const download = () => setIsDownloading(true);

  useEffect(() => {
    if (!isDownloading) return;

    const cancelCallback: CancelTokenSource = axiosInstance.CancelToken.source();
    const config: AxiosRequestConfig = {
      responseType: 'blob',
      headers: {},
      cancelToken: cancelCallback.token,
    };

    if (accept) {
      config.headers!.accept = accept;
    }

    axiosInstance
      .get(src, config)
      .then(response => {
        const { headers, data } = response;

        if (accept) {
          const fileUrl = URL.createObjectURL(new Blob([data], { type: accept }));
          downloadURL(fileUrl, fileName || 'file');
        } else {
          const fName: string = decodeURI(
            headers['content-disposition']?.split('filename=')[1].replace(/\+/g, ' ')?.replace(/['"]/g, '')
          );
          const type: string = decodeURI(headers['content-type']);
          const fileUrl = URL.createObjectURL(new Blob([data], { type }));
          downloadURL(fileUrl, fileName || fName);
        }
        onDownload?.(true);
      })
      .catch(error => {
        console.error('Ошибка при загрузке файла', error);
        onDownload?.(false);
        Notifier.getInstance().addNotice(ENoticeStatus.Error, `Ошибка при загрузке файла`);
      })
      .finally(() => {
        setIsDownloading(false);
      });

    return () => cancelCallback.cancel();
  }, [isDownloading]);

  return {
    isDownloading,
    download,
  };
};

export default useFileDownload;
