import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Action, Location } from 'history';
import { Nullable } from '../../../../../domain/model/types';

export type InternalLocation = {
  readonly action: Action;
  readonly location: Location;
};

export type InternalLocationConnector = InternalLocation[];

export interface HistoryExtensionsState {
  readonly prevLocation: Nullable<InternalLocation>;
  readonly collector: InternalLocationConnector;
}

type Reducer<T = undefined> = CaseReducer<HistoryExtensionsState, PayloadAction<T>>;

type Reducers = {
  historyExtensionsInit: Reducer<InternalLocation>;
  historyExtensionsReset: Reducer;
  historyExtensionsCollect: Reducer<InternalLocation>;
};

const limit = 100;

const slice = createSlice<HistoryExtensionsState, Reducers, 'historyExtensions'>({
  name: 'historyExtensions',
  initialState: {
    prevLocation: null,
    collector: [],
  },
  reducers: {
    historyExtensionsInit: (state, { payload }) => {
      state.prevLocation = payload;
      state.collector = [payload];
    },
    historyExtensionsReset: (state, { payload }) => {
      state.prevLocation = null;
      state.collector = [];
    },
    historyExtensionsCollect: (state, { payload }) => {
      const { action, location } = payload;
      const internalLocation: InternalLocation = {
        action,
        location,
      };

      switch (action) {
        case 'POP': {
          // это возврат Назад, поэтому удаляем из истории то, через что перепрыгнули Назад
          const fromIndex = state.collector.findIndex(l => l.location.key === location.key);
          if (fromIndex !== -1) {
            state.collector.splice(fromIndex + 1);
          }
          if (state.collector.length > 1) {
            state.prevLocation = state.collector[state.collector.length - 2];
          } else {
            state.prevLocation = null;
          }
          break;
        }
        case 'REPLACE': {
          // это замена текущей локации, поэтому перезаписываем последнюю локацию
          state.collector.splice(state.collector.length - 1, 1);
          state.collector.push(internalLocation);
          break;
        }
        case 'PUSH': {
          // это переход, поэтому добавляем локацию
          state.prevLocation = state.collector[state.collector.length - 1];
          state.collector.push(internalLocation);
          break;
        }
      }

      if (state.collector.length > limit) {
        state.collector = state.collector.slice(1);
      }
    },
  },
});

export const { historyExtensionsInit, historyExtensionsReset, historyExtensionsCollect } = slice.actions;

export default slice.reducer;
