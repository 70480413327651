import React from 'react';
import { UseProductTable } from './hooks/useProductTable';
import { UseProductTableConfig } from './hooks/useProductTableConfig';

export type ProductTableContextValue = UseProductTable;

export const ProductTableContext = React.createContext<ProductTableContextValue>({} as ProductTableContextValue);

export type ProductTableConfigContextValue = UseProductTableConfig;

export const ProductTableConfigContext = React.createContext<ProductTableConfigContextValue>(
  {} as ProductTableConfigContextValue
);
