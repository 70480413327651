import Api from '../../../../data/api';
import { ApiCancellable } from '../../../../data/api/types';
import { pageSizeAll } from '../../../../domain/model/constants';
import { EOfferStatus, EProductDeskActionDiscriminator } from '../../../../domain/model/enums';
import { DataFilterQueryDslOperator } from '../../../../domain/model/filter';
import { ProductDesk, ProductOffer } from '../../../../domain/model/productOffer';
import { Nullable, UUID } from '../../../../domain/model/types';

type AloneIDProps = ApiCancellable & {
  readonly id: UUID;
};

type PauseProps = AloneIDProps & {
  readonly comment: Nullable<string>;
};

type OfferProps = AloneIDProps & {
  readonly statuses?: Nullable<EOfferStatus[]>;
  readonly sort?: Nullable<string>;
};

export type ProductDeskService = {
  readonly archive: (props: AloneIDProps) => Promise<ProductDesk>;
  readonly resume: (props: AloneIDProps) => Promise<ProductDesk>;
  readonly unPublish: (props: AloneIDProps) => Promise<ProductDesk>;
  readonly pause: (props: PauseProps) => Promise<ProductDesk>;
  readonly offers: (props: OfferProps) => Promise<ProductOffer[]>;
};

const service: ProductDeskService = {
  archive: async ({ id }) => {
    const { data } = await Api.product.productDesk.actions({
      id,
      discriminator: EProductDeskActionDiscriminator.ProductArchiveCommand,
    });
    return data;
  },
  resume: async ({ id }) => {
    const { data } = await Api.product.productDesk.actions({
      id,
      discriminator: EProductDeskActionDiscriminator.ProductRenewCommand,
    });
    return data;
  },
  unPublish: async ({ id }) => {
    const { data } = await Api.product.productDesk.actions({
      id,
      discriminator: EProductDeskActionDiscriminator.ProductBackToDraftCommand,
    });
    return data;
  },
  pause: async ({ id, comment }) => {
    const { data } = await Api.product.productDesk.actions({
      id,
      comment,
      discriminator: EProductDeskActionDiscriminator.ProductPauseCommand,
    });
    return data;
  },
  offers: async ({ id, statuses, sort, signal }) => {
    const querydsl = [{ value: [id], operator: DataFilterQueryDslOperator.Equals, field: 'productDesk.id' }];
    const { data } = await Api.productOffers.all({
      querydsl,
      signal,
      statuses,
      page: 1,
      pageSize: pageSizeAll,
      sort,
    });

    return data;
  },
};

export default service;
