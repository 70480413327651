import { ReactElement } from 'react';
import {
  BookingOffer,
  BookingOfferContact,
  BookingOfferResponsiblePerson,
  BookingOfferService,
  BookingOfferShort,
  FileDescription,
  Nullable,
  UUID,
} from '@/domain';
import { MPStepperStep } from '@components/common/stepper';
import { PanelActions } from '../../types';
import { BookingServicePriceItemView } from './components/fieldsEdit/types';

export enum EBookingOfferUrlParam {
  Step = 'step',
  Type = 'type',
}

export enum EBookingOfferCreateUrlParam {
  Id = 'id',
  Guid = 'guid',
}

export enum EBookingOfferStep {
  General = 'general',
  Contacts = 'contacts',
  Services = 'services',
}

export type BookingOfferCreateStepType =
  | EBookingOfferStep.General
  | EBookingOfferStep.Contacts
  | EBookingOfferStep.Services;

export type BookingOfferDetailsStepType =
  | EBookingOfferStep.General
  | EBookingOfferStep.Contacts
  | EBookingOfferStep.Services;

export type BookingOfferCreateStep = MPStepperStep<BookingOfferCreateStepType>;
export type BookingOfferDetailsStep = MPStepperStep<BookingOfferDetailsStepType>;

export type BookingOfferTableStubAdapterProps = {
  readonly children: (props: BookingOfferTableStubAdapterResult) => ReactElement<any, any>;
};

export type BookingOfferTableStubAdapterResult = {
  readonly canViewTable: boolean;
  readonly isCreateDisabled?: boolean;
  readonly stub?: Nullable<JSX.Element>;
  readonly createInfo?: Nullable<string>;
};

export type BookingOfferTableStubAdapterType = (props: BookingOfferTableStubAdapterProps) => ReactElement<any, any>;

export enum EBookingActionType {
  /** Отправить на проверку */
  Publish = 'publish',
  /** Снять с публикации */
  UnPublish = 'unPublish',
  /** Отозвать */
  Resume = 'resume',
  /** Возобновить */
  Approve = 'approve',
  /** Отклонить */
  Reject = 'reject',
  /** Приостановить */
  Pause = 'pause',
  /** Отправить в архив */
  Archive = 'archive',
  /** Удалить */
  Delete = 'delete',
  /** Редактировать */
  Edit = 'edit',
  /** Управление услугами */
  ManageServices = 'manageServices',
  /** Взять в работу */
  InWork = 'inWork',
  /** Копировать в черновик */
  Duplicate = 'duplicate',
  /** Вернуть на проверку */
  ReturnToVerification = 'returnToVerification',
  /** Вернуть в предыдущий статус */
  Retrieve = 'retrieve',
  /** Назначить модератора */
  ChangeModerator = 'changeModerator',
  /** Создать новую версию */
  SaveNewVersion = 'saveNewVersion',
}

export type BookingActionCreateType =
  | EBookingActionType.Publish
  | EBookingActionType.Resume
  | EBookingActionType.Edit
  | EBookingActionType.Delete
  | EBookingActionType.Archive
  | EBookingActionType.Duplicate
  | EBookingActionType.ReturnToVerification
  | EBookingActionType.SaveNewVersion
  | EBookingActionType.ChangeModerator;

export type BookingActionDetailsType =
  | EBookingActionType.Resume
  | EBookingActionType.Publish
  | EBookingActionType.Pause
  | EBookingActionType.Archive
  | EBookingActionType.Approve
  | EBookingActionType.Reject
  | EBookingActionType.Edit
  | EBookingActionType.ManageServices
  | EBookingActionType.UnPublish
  | EBookingActionType.InWork
  | EBookingActionType.Duplicate
  | EBookingActionType.Retrieve
  | EBookingActionType.ReturnToVerification
  | EBookingActionType.ChangeModerator;

export type BookingActionTableType =
  | EBookingActionType.Approve
  | EBookingActionType.InWork
  | EBookingActionType.ReturnToVerification
  | EBookingActionType.Duplicate
  | EBookingActionType.Delete
  | EBookingActionType.Archive
  | EBookingActionType.Reject
  | EBookingActionType.UnPublish
  | EBookingActionType.ChangeModerator
  | EBookingActionType.Edit
  | EBookingActionType.ManageServices
  | EBookingActionType.Pause
  | EBookingActionType.Resume
  | EBookingActionType.Publish
  | EBookingActionType.Retrieve;

export type BookingActionTableCommonType = BookingActionTableType;

export type BookingActionType = BookingActionCreateType | BookingActionDetailsType | BookingActionTableType;

export type ActionBookingType = BookingOfferShort | BookingOfferView;

export type BookingActions<A extends string = EBookingActionType> = PanelActions<A>;

export interface BookingServicePriceItemValidateView extends Omit<BookingServicePriceItemView, 'service'> {
  readonly service: BookingOfferServiceView;
}

export interface BookingOfferServiceView extends Omit<BookingOfferService, 'id' | 'priceItems'> {
  readonly id: Nullable<UUID>;
  readonly priceItems: BookingServicePriceItemView[];
}

export interface BookingOfferView
  extends Omit<
    BookingOffer,
    'id' | 'services' | 'displayPriceItem' | 'responsiblePerson' | 'contacts' | 'responsiblePersons'
  > {
  readonly id: Nullable<UUID>;
  readonly responsiblePersons: BookingOfferResponsiblePerson[];
  readonly contacts: BookingOfferContact[];
  readonly services: BookingOfferServiceView[];
  readonly displayPriceItem: Nullable<BookingServicePriceItemView>;
  readonly fileImages?: FileDescription[];
}

export enum EBookingOfferTableColumn {
  Image = 'image',
  Date = 'createdAt',
  Code = 'code',
  Name = 'name',
  Address = 'address',
  Partner = 'partner.name',
  PartnerType = 'partner.type',
  Category = 'category',
  ResponsibleFullName = 'responsiblePersons.fullName',
  ResponsiblePhone = 'responsiblePerson.phone',
  ResponsibleEmail = 'responsiblePerson.email',
  Moderator = 'moderator',
  RzdSocialPackage = 'rzdSocialPackage',
}
