import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PartnerEmployeeTableCellCommonProps } from '../../components/tableCell';
import { partnerEmployeesEmployeeByIndexSelector } from '../store/selectors';

export type PartnerEmployeeTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<PartnerEmployeeTableCellCommonProps>;
};

const PartnerEmployeeTableCellAdapter = (props: PartnerEmployeeTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const employee = useSelector(partnerEmployeesEmployeeByIndexSelector(index));

  return useMemo(() => (employee ? <Component employee={employee} /> : null), [Component, employee]);
};

export default PartnerEmployeeTableCellAdapter;
