import { Grid, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback, useMemo, useState } from 'react';
import Api from '../../../../../data/api';
import { ProductCategory } from '../../../../../domain/model/productOffer';
import { Nullable } from '../../../../../domain/model/types';
import { ButtonLink } from '../../../../components/common/buttons/link';
import AppSvgIcon from '../../../../components/common/images/icon';
import SelectCascade from '../../../../components/common/selectCascade/container';
import { CascadeItem } from '../../../../components/common/selectCascade/types';
import { AddOutlinedIcon } from '../../../../icons';
import { getStorageData, setStorageData } from '../../../../utils/localStorage';
import { CategoryChip, Content, Header, StyledDialog } from './controls';

interface ProductUploadOffersCategoryProps {
  readonly category: Nullable<ProductCategory>;
  readonly categoryList: Nullable<ProductCategory[]>;
  readonly onChangeCategory: (category: Nullable<ProductCategory>, categoryList: Nullable<ProductCategory[]>) => void;
}

const ProductUploadOffersCategory = (props: ProductUploadOffersCategoryProps) => {
  const { category, categoryList, onChangeCategory } = props;
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);

  const productCategory = useMemo(
    () =>
      categoryList?.map(category => ({
        id: category.id,
        name: category.name,
        parentId: category.parentId,
        attributes: null,
      })) || [],
    [category, categoryList]
  );

  const onSelectCategory = (
    item: CascadeItem<ProductCategory> | undefined,
    cascade: CascadeItem<ProductCategory>[]
  ) => {
    if (item) {
      setDialogVisible(false);
      onChangeCategory(item, cascade);
    }
  };

  const onClearCategory = () => {
    onChangeCategory(null, null);
  };

  const loadOptionsCategories = useCallback(async (): Promise<any> => {
    const dataFromStorage = getStorageData<ProductCategory[], true>('productCategories', true);

    if (dataFromStorage) {
      return dataFromStorage;
    }

    const response = await Api.category.product.all({});

    const expires = new Date();
    // Храним данные 1 день
    expires.setDate(expires.getDate() + 1);
    setStorageData('productCategories', response.data, expires.toISOString());
    return response.data;
  }, []);

  return (
    <>
      {dialogVisible && (
        <StyledDialog
          open
          disableStandardClose
          title={
            <Header
              container
              alignItems='center'
              spacing={0}
            >
              <Grid item>
                <IconButton onClick={() => setDialogVisible(false)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant='subtitle2'>Выбор категории</Typography>
              </Grid>
            </Header>
          }
          dialogProps={{
            open: true,
            fullWidth: true,
            maxWidth: 'lg',
            hideBackdrop: true,
            disableEscapeKeyDown: true,
            PaperProps: {
              elevation: 0,
            },
          }}
        >
          <Content>
            <SelectCascade<ProductCategory>
              loadOptionsMethod={loadOptionsCategories}
              value={productCategory}
              onSelect={onSelectCategory}
            />
          </Content>
        </StyledDialog>
      )}

      {!category && (
        <ButtonLink onClick={() => setDialogVisible(true)}>
          <AppSvgIcon
            icon={AddOutlinedIcon}
            fontSize='small'
          />
          <Typography>Выбрать категорию</Typography>
        </ButtonLink>
      )}
      {category && categoryList && (
        <CategoryChip
          label={categoryList.map(r => r.name).join(' > ')}
          color='primary'
          onDelete={onClearCategory}
        />
      )}
    </>
  );
};

export default ProductUploadOffersCategory;
