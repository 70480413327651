import { EAnalyticsQueriesTableTab } from '@features/analytics/query/table/utils';

export type UseAnalyticsQueriesTableConfig = {
  readonly tabs: EAnalyticsQueriesTableTab[];
  readonly defaultTab: EAnalyticsQueriesTableTab;
};

export function useAnalyticsQueriesTableConfig(): UseAnalyticsQueriesTableConfig {
  const tabs = [EAnalyticsQueriesTableTab.Queries, EAnalyticsQueriesTableTab.Geography];
  const defaultTab = EAnalyticsQueriesTableTab.Queries;

  return {
    tabs,
    defaultTab,
  };
}
