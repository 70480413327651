import { MPAutocompleteMultipleSelectItem } from '../../../../../theme/ui-kit/autocomplete/types';

export enum OrgStructureRoadMultipleItemType {
  All = 'all',
  Text = 'text',
  Road = 'road',
  None = 'none',
}

export enum OrgStructureRoadMultipleSelectType {
  All = 'all',
  None = 'none',
}

export enum OrgStructureRoadMultipleAllRoadsState {
  Indeterminate = 'indeterminate',
  Checked = 'checked',
  NotChecked = 'not-checked',
}

export type OrgStructureRoadMultipleTypesSelectorItem =
  | (MPAutocompleteMultipleSelectItem & { type: OrgStructureRoadMultipleItemType.Road })
  | (MPAutocompleteMultipleSelectItem & { type: OrgStructureRoadMultipleItemType.Text })
  | (MPAutocompleteMultipleSelectItem & {
      readonly type: OrgStructureRoadMultipleItemType.All;
      readonly state: OrgStructureRoadMultipleAllRoadsState;
    })
  | (MPAutocompleteMultipleSelectItem & {
      readonly type: OrgStructureRoadMultipleItemType.None;
      readonly state: OrgStructureRoadMultipleAllRoadsState;
    });

export enum MultipleSelectorSelectAllType {
  Flag = 'flag',
  List = 'list',
}
