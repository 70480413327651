import { Address } from '../../../../../../domain/model/address';
import { EAddressLevel } from '../../../../../../domain/model/enums';
import { AddressHelper } from '../../../../../utils/address';
import AddressMultipleSelector, { AddressMultipleSelectorProps } from './index';

export interface AddressLocalityMultipleSelectorProps
  extends Omit<
    AddressMultipleSelectorProps,
    'getOptionLabel' | 'onlyValidPostalCode' | 'level' | 'fromLevel' | 'toLevel'
  > {}

const getOptionLabel = (address: Address) => new AddressHelper(address).getLocalityFullPath() ?? '-';

const AddressLocalityMultipleSelector = (props: AddressLocalityMultipleSelectorProps) => {
  return (
    <AddressMultipleSelector
      {...props}
      fromLevel={EAddressLevel.City}
      toLevel={EAddressLevel.Settlement}
      getOptionLabel={getOptionLabel}
    />
  );
};

export default AddressLocalityMultipleSelector;
