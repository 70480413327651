import { SportOption } from '../../../../domain/model';
import {
  ENoticeStatus,
  EPresenceStatus,
  ESimpleSportEventType,
  ESportEventParticipationFormat,
  ESportEventStatus,
  ESportEventType,
  ESportsFestCombinedTeamStatus,
} from '../../../../domain/model/enums';
import {
  ActivityEvent,
  ActivityEventRequest,
  FriendlyMatch,
  SportsFest,
  SportsFestRequest,
  Tournament,
  TournamentIndividual,
  TournamentIndividualRequest,
  TournamentRequest,
} from '../../../../domain/model/event';
import { Nullable, UUID } from '../../../../domain/model/types';
import { SportUserProfile, SportUserProfileShort } from '../../../../domain/model/user';
import Notifier from '../../../../system/notifier';
import { getUserShortName } from '../../../utils';
import { EEventStep } from '../types';
import { eventParticipationFormats } from '../../general/nsi';

export const EVENT_DATETIME_FORMAT = 'D MMMM YYYY г в HH:mm';
export const EVENT_DATETIME_FORMAT_SHORT = 'D MMMM в HH:mm';
export const EVENT_TIME_HOURS_DEFAULT = '10';
export const EVENT_TIME_MINUTES_DEFAULT = '00';
export const EVENT_RESET_TIME = '00:00:00';

const statusesForModify: { [key: string]: ESportEventStatus[] } = {
  general: [ESportEventStatus.ApplicationsCollecting, ESportEventStatus.WaitingForStart],
  applications: [ESportEventStatus.ApplicationsCollecting, ESportEventStatus.WaitingForStart],
  results: [ESportEventStatus.InProgress, ESportEventStatus.Completed],
  chat: [],
};

export const statusesForCancel = [
  ESportEventStatus.ApplicationsCollecting,
  ESportEventStatus.InProgress,
  ESportEventStatus.SummingUp,
  ESportEventStatus.InProgress,
  ESportEventStatus.Completed,
  ESportEventStatus.WaitingForStart,
  ESportEventStatus.Summarizing,
];

export const ALL = 'ALL';
export const ASC = 'asc';

export const getEventDateTimeFormat = (date: Date): string =>
  date.getFullYear() === new Date().getFullYear() ? EVENT_DATETIME_FORMAT_SHORT : EVENT_DATETIME_FORMAT;

export const canEventCancel = (event: FriendlyMatch, user: SportUserProfile, ownOnly: boolean): boolean => {
  return (!ownOnly || isEventAdmin(event, user)) && statusesForCancel.some(status => status === event.status.code);
};

export const isEventAdmin = (event: FriendlyMatch, user: SportUserProfile): boolean => {
  return event.admin?.id === user.id;
};

export const canEventEdit = (event: FriendlyMatch, user: SportUserProfile, ownOnly: boolean): boolean => {
  return (
    (!ownOnly || isEventAdmin(event, user)) && statusesForModify.general.some(status => status === event.status.code)
  );
};

export const canEventCreate = (event: FriendlyMatch, user: SportUserProfile, ownOnly: boolean): boolean => {
  return (!ownOnly || isEventAdmin(event, user)) && event.status.code === ESportEventStatus.Draft;
};

export const canEventTabEdit = (
  event: FriendlyMatch,
  user: SportUserProfile,
  tab: EEventStep,
  ownOnly: boolean
): boolean => {
  return (!ownOnly || isEventAdmin(event, user)) && statusesForModify[tab].some(status => status === event.status.code);
};

export const canEventSetResults = (event: FriendlyMatch, user: SportUserProfile, ownOnly: boolean): boolean => {
  return (
    (!ownOnly || isEventAdmin(event, user)) && statusesForModify.results.some(status => status === event.status.code)
  );
};

export const eventObjectToDto = (object: any): any => {
  // TODO хуета, невозможно типизировать
  const dto = { ...object };

  if (ESportEventType.FriendlyMatch) {
    delete dto.status;
  }

  dto.invites = object.invites?.map((item: any) => ({ team: item.team }));
  return dto;
};

export const presenceStatusText = {
  presenceConfirmed: 'Подтвердил явку',
  presenceNotConfirmed: 'Не подтвердил явку',
  waitingConfirmation: 'Не подтвердил явку',
} as const;

export const getPresenceStatusText = (status: EPresenceStatus): string => {
  switch (status) {
    case EPresenceStatus.PresenceConfirmed:
      return presenceStatusText.presenceConfirmed;
    case EPresenceStatus.PresenceNotConfirmed:
      return presenceStatusText.presenceNotConfirmed;
    case EPresenceStatus.WaitingConfirmation:
      return presenceStatusText.waitingConfirmation;
    default:
      throw new Error(`Unknown PresenceStatus: ${status}`);
  }
};

export const getSportEventInstrumentalLabel = (eventType: ESportEventType): string => {
  switch (eventType) {
    case ESportEventType.Tournament:
      return 'турнира';
    case ESportEventType.TournamentIndividual:
      return 'индивидуального турнира';
    case ESportEventType.SportsFest:
      return 'спартакиады';
    case ESportEventType.FriendlyMatch:
      return 'товарищеского матча';
    case ESportEventType.ActivityEvent:
      return 'события';
  }
};

export const getSportsFestCombinedTeamStatusText = (status: ESportsFestCombinedTeamStatus): string => {
  switch (status) {
    case ESportsFestCombinedTeamStatus.New:
    case ESportsFestCombinedTeamStatus.Forming:
      return 'Не сформирована';
    case ESportsFestCombinedTeamStatus.Formed:
      return 'Сформирована';
  }
};

interface GetEventAdminNameProps {
  readonly currentUserId?: UUID;
  readonly admin: SportUserProfile;
}

export const getEventAdminName = ({ currentUserId, admin }: GetEventAdminNameProps) => {
  const name = getUserShortName(admin);
  return currentUserId === admin.id ? name + ' (Вы)' : name;
};

/**
 * показать нотификатор об успешном выполнении действия с мероприятием
 * @param event мероприятие
 * @param nextStatus если значение не передано, предполагается что статус не меняется относительно текущего, или пока статус не присвоен
 * */
export const showEventActionSuccessNotification = (
  event:
    | TournamentRequest
    | SportsFestRequest
    | ActivityEventRequest
    | Tournament
    | SportsFest
    | ActivityEvent
    | TournamentIndividual
    | TournamentIndividualRequest,
  nextStatus?: ESportEventStatus
) => {
  let text;

  if (!event.status && !nextStatus) {
    text = 'Черновик мероприятия сохранён';
  } else {
    switch (nextStatus) {
      case ESportEventStatus.Announced:
      case ESportEventStatus.ApplicationsCollecting:
      case ESportEventStatus.WaitingForStart:
        text = 'Мероприятие опубликовано';
        break;
      case ESportEventStatus.Cancelled:
        text = 'Мероприятие отменено';
        break;
      default:
        text = 'Изменения сохранены';
        break;
    }
  }

  Notifier.getInstance().addNotice(ENoticeStatus.Success, text);
};

/**
 * показать нотификатор об ошибке валидации во время выполнения действия с мероприятием
 * @param event мероприятие
 * @param nextStatus если значение не передано, предполагается что статус не меняется относительно текущего, или пока статус не присвоен
 * */
export const showEventInvalidNotification = (
  event: TournamentRequest | SportsFestRequest | ActivityEventRequest | Tournament | SportsFest | ActivityEvent,
  nextStatus?: ESportEventStatus
) => {
  let text;

  if (!event.status && !nextStatus) {
    text = 'Заполните корректно поля, чтобы сохранить черновик';
  } else {
    switch (nextStatus) {
      case ESportEventStatus.Announced:
        text = 'Заполните все обязательные поля, чтобы опубликовать анонс';
        break;
      case ESportEventStatus.ApplicationsCollecting:
        text = 'Заполните все обязательные поля, чтобы открыть сбор заявок';
        break;
      case ESportEventStatus.WaitingForStart:
        text = 'Заполните все обязательные поля, чтобы запустить формирование сборных';
        break;
      default:
        text = 'Заполните все обязательные поля, чтобы сохранить изменения';
        break;
    }
  }

  Notifier.getInstance().addNotice(ENoticeStatus.Warning, text);
};

export const convertSportUserProfileInSportOption = (user: Nullable<SportUserProfile>): Nullable<SportOption> => {
  if (!user) {
    return null;
  }

  return {
    id: user.id,
    name: [user.lastName, user.firstName].join(' '),
    code: user.gender,
    image: user.photo?.path ?? null,
  };
};

export const convertSportUserProfileInSportUserProfileShort = (
  user: Nullable<SportUserProfile>
): Nullable<SportUserProfileShort> => {
  if (!user) {
    return null;
  }

  return {
    id: user.id,
    gender: user.gender,
    firstName: user.firstName,
    lastName: user.lastName,
    photo: user.photo,
    birthDate: user.birthDate,
    locality: user.locality,
    middleName: user.middleName,
  };
};

export const getNameSportEventType = (type: ESimpleSportEventType) => {
  switch (type) {
    case ESimpleSportEventType.ActivityEvent:
      return 'Событие';
    case ESimpleSportEventType.SportsFest:
      return 'Спартакиада';
    case ESimpleSportEventType.FriendlyMatch:
      return 'Товарищеский матч';
    case ESimpleSportEventType.Tournament:
      return 'Турнир';
  }
};

export const getLabelSportEventParticipationFormat = (format: ESportEventParticipationFormat) => {
  return Object.values(eventParticipationFormats).find(f => f.id === format)?.name;
};
