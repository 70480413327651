import { Typography } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { Nullable } from '../../../../../../../domain/model/types';
import AppSvgIcon from '../../../../../../components/common/images/icon';
import { PreviewDesktopIcon, PreviewMobileIcon } from '../../../../../../icons';
import { EEntityPreviewMode } from '../../../../../../types';
import { ContentWrapper, Label, ModeButton, ModeToggler, Title, TitleDesktop, TitleWrapper, Wrapper } from './controls';

interface OfferPreviewWrapperProps {
  readonly title?: string;
  readonly label?: Nullable<string>;
  readonly onlyDesktop?: boolean;
  readonly children: (({ mode }: { mode: EEntityPreviewMode }) => React.ReactNode | JSX.Element) | ReactNode;
}

const OfferPreviewWrapper = (props: OfferPreviewWrapperProps) => {
  const { onlyDesktop, title, label, children } = props;

  const [mode, setMode] = useState<EEntityPreviewMode>(EEntityPreviewMode.Desktop);

  const onChangeMode = (event: React.MouseEvent<HTMLElement>, newMode: EEntityPreviewMode) => {
    if (newMode) {
      setMode(newMode);
    }
  };

  const content = (
    <>
      {mode === EEntityPreviewMode.Desktop && (
        <ContentWrapper>
          {title && (
            <TitleWrapper>
              <TitleDesktop>{title}</TitleDesktop>
            </TitleWrapper>
          )}
          {typeof children === 'function' ? children({ mode }) : children}
        </ContentWrapper>
      )}
      {mode === EEntityPreviewMode.Mobile && (
        <ContentWrapper>
          {title && (
            <TitleWrapper>
              <Title>{title}</Title>
            </TitleWrapper>
          )}
          {typeof children === 'function' ? children({ mode }) : children}
        </ContentWrapper>
      )}
    </>
  );

  return (
    <Wrapper>
      {label && (
        <Label>
          <Typography
            color='textSecondary'
            variant='body2'
          >
            {label}
          </Typography>
        </Label>
      )}
      {!onlyDesktop && (
        <ModeToggler
          exclusive
          value={mode}
          onChange={onChangeMode}
        >
          <ModeButton value={EEntityPreviewMode.Desktop}>
            <AppSvgIcon
              icon={PreviewDesktopIcon}
              viewBox='0 0 24 24'
            />
          </ModeButton>
          <ModeButton value={EEntityPreviewMode.Mobile}>
            <AppSvgIcon
              icon={PreviewMobileIcon}
              viewBox='0 0 24 24'
            />
          </ModeButton>
        </ModeToggler>
      )}
      {content}
    </Wrapper>
  );
};

export default OfferPreviewWrapper;
