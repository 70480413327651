import { LocationWithRequiredStateType } from './types';
import useLocationWithRequiredState from './useLocationWithRequiredState';

interface LocationWithRequiredStateContainerProps<T extends LocationWithRequiredStateType> {
  readonly defaultState: T;
  readonly children: (props: { state: T }) => JSX.Element;
}

const LocationWithRequiredStateContainer = <T extends LocationWithRequiredStateType>({
  defaultState,
  children,
}: LocationWithRequiredStateContainerProps<T>) => {
  const { ready, state } = useLocationWithRequiredState<T>(defaultState);

  if (!ready || !state) {
    return null;
  }

  return children({ state });
};

export default LocationWithRequiredStateContainer;
