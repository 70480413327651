import { combineReducers } from '@reduxjs/toolkit';
import list, { AnalyticsQueriesListState } from './table/store/slice';

interface AnalyticsQueriesState {
  list: AnalyticsQueriesListState;
}

export default combineReducers<AnalyticsQueriesState>({
  list,
});
