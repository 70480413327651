import { Action, Location } from 'history';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Nullable } from '../../domain/model/types';
import {
  historyExtensionsGetLocationCollector,
  historyExtensionsGetPrevIndependentLocationSelector,
} from '../features/general/historyExtension/store/selectors';
import { InternalLocationConnector } from '../features/general/historyExtension/store/slice';

interface UseHistoryExtensions {
  readonly locationCollector: InternalLocationConnector;
  readonly prevIndependentLocation: Nullable<Location>;
  readonly gotoPrevLocation: (
    prevLocation: Nullable<Location | string>,
    fallback: string | Location,
    fallbackAction?: Action
  ) => void;
  readonly gotoPrevIndependentLocation: (fallback: string | Location, fallbackAction?: Action) => void;
}

function useHistoryExtensions(): UseHistoryExtensions {
  const history = useHistory();

  const locationCollector = useSelector(historyExtensionsGetLocationCollector);
  const prevIndependentLocation = useSelector(historyExtensionsGetPrevIndependentLocationSelector);

  const gotoPrevLocation = useCallback(
    (prevLocation: Nullable<Location | string>, fallback: string | Location, fallbackAction: Action = 'REPLACE') => {
      if (!prevLocation) {
        switch (fallbackAction) {
          case 'PUSH':
            history.push(fallback);
            break;
          case 'POP':
          case 'REPLACE':
            history.replace(fallback);
            break;
          default:
            history.push(fallback);
            console.error('Fallback action must be value in [PUSH, REPLACE, POP]');
        }
        return;
      }

      let prevLocationIndex = -1;
      locationCollector.forEach((l, index) => {
        if (typeof prevLocation === 'string') {
          if (l.location.pathname === prevLocation) {
            prevLocationIndex = index;
          }
        } else {
          if (l.location.key === prevLocation.key && l.location.pathname === prevLocation.pathname) {
            prevLocationIndex = index;
          }
        }
      });

      if (prevLocationIndex !== -1) {
        const backSteps = prevLocationIndex - (locationCollector.length - 1);
        if (history.length > Math.abs(backSteps)) {
          history.go(backSteps);
        } else {
          history.replace(fallback);
        }
      } else {
        switch (fallbackAction) {
          case 'PUSH':
            history.push(fallback);
            break;
          case 'POP':
          case 'REPLACE':
            history.replace(fallback);
            break;
          default:
            history.push(fallback);
            console.error('Fallback action must be value in [PUSH, REPLACE, POP]');
        }
      }
    },
    [history, locationCollector]
  );

  const gotoPrevIndependentLocation = useCallback(
    (fallback: string | Location, fallbackAction: Action = 'REPLACE') => {
      gotoPrevLocation(prevIndependentLocation, fallback, fallbackAction);
    },
    [prevIndependentLocation, gotoPrevLocation]
  );

  return {
    locationCollector,
    prevIndependentLocation,
    gotoPrevLocation,
    gotoPrevIndependentLocation,
  };
}

export default useHistoryExtensions;
