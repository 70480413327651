import {
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterValueItem,
  EDataFilterType,
  Nullable,
} from '@/domain';

export enum EReportEventsFilterItem {
  DatePeriod = 'datePeriod',
  Partner = 'partner',
}

export type ReportEventsFilterEditStrategy = DataFilterStrategyBase<EReportEventsFilterItem>;

export type ReportEventsFilterValues = {
  readonly [EReportEventsFilterItem.DatePeriod]?: DataFilterValueItem<Nullable<[Nullable<string>, Nullable<string>]>>;
  readonly [EReportEventsFilterItem.Partner]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem>>;
};

export const getReportEventsFilterStrategies = (values: ReportEventsFilterValues): ReportEventsFilterEditStrategy[] => {
  const strategies: ReportEventsFilterEditStrategy[] = [];
  strategies.push({
    type: EDataFilterType.DatePeriod,
    key: EReportEventsFilterItem.DatePeriod,
    label: '',
    labelPeriod: {
      from: 'Период с',
      to: 'по',
    },
    buttonsPeriod: true,
    preview: null,
    value: values[EReportEventsFilterItem.DatePeriod]?.value || null,
  });

  return strategies;
};
