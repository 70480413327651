import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../../data/api';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../../data/store/types';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import { SportUserProfile } from '../../../../../../domain/model/user';
import { userSportEditUpdate } from '../../../userSport/edit/store/slice';

export const userCurrentDetailsSportByIdFetch = createAsyncThunk<SportUserProfile, { id: UUID }, AppThunkAPIConfig>(
  'user/current/details/sport/byId/fetch',
  async ({ id }, { rejectWithValue, signal }) => {
    try {
      const { data: user } = await Api.user.sport.one({ id, signal });
      return user;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export interface UserCurrentDetailsSportState {
  readonly needRefreshWatcher: number;
  readonly byId: Fetchable & {
    readonly user: Nullable<SportUserProfile>;
    readonly loadedUser: Nullable<SportUserProfile>;
  };
  readonly emailChanged: Nullable<string>;
}

type Reducer<T = undefined> = CaseReducer<UserCurrentDetailsSportState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<UserCurrentDetailsSportState> {
  userCurrentDetailsSportStateReset: Reducer;
  userCurrentDetailsSportEmailChangedReset: Reducer;
}

const slice = createSlice<UserCurrentDetailsSportState, Reducers, 'details/sport'>({
  name: 'details/sport',
  initialState: {
    needRefreshWatcher: 0,
    byId: {
      ...fetchableDefault,
      user: null,
      loadedUser: null,
    },
    emailChanged: null,
  },
  reducers: {
    userCurrentDetailsSportStateReset: state => {
      state.needRefreshWatcher = 0;
      state.byId = {
        ...fetchableDefault,
        user: null,
        loadedUser: null,
      };
      state.emailChanged = null;
    },
    userCurrentDetailsSportEmailChangedReset: state => {
      state.emailChanged = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(userCurrentDetailsSportByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.user = null;
      })
      .addCase(userCurrentDetailsSportByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.user = payload;
        state.byId.loadedUser = payload;
      })
      .addCase(userCurrentDetailsSportByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        }

        state.byId.user = null;
      })
      .addCase(userSportEditUpdate.pending, state => {
        state.emailChanged = null;
      })
      .addCase(userSportEditUpdate.fulfilled, (state, { payload }) => {
        state.emailChanged = payload.newEmail;
      })
      .addCase(userSportEditUpdate.rejected, state => {
        state.emailChanged = null;
      });
  },
});

export const { userCurrentDetailsSportStateReset, userCurrentDetailsSportEmailChangedReset } = slice.actions;

export default slice.reducer;
