import { useReportDownloads } from '../useDownloads';
import { EReportType } from '@features/report/types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { reportDownloadsReportsSelector } from '../store/selectors';
import { EventsDetailedReportContainer } from '../reports/eventsDetailed';

export const ReportDownloadsEventsDetailedAdapter = () => {
  const handlers = useReportDownloads();

  const { events_detailed: open } = useSelector(reportDownloadsReportsSelector);

  const onClose = useCallback(() => {
    handlers.onCloseDownloadDialog(EReportType.EventsDetailed);
  }, [handlers.onCloseDownloadDialog]);

  return open && <EventsDetailedReportContainer onClose={onClose} />;
};
