import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { CmsSitePage } from '../../../../../domain/model/cms';
import { DataFilterQueryDslOperator } from '../../../../../domain/model/filter';
import { Nullable, UUID } from '../../../../../domain/model/types';

export const partnerWindowFetch = createAsyncThunk<Nullable<CmsSitePage>, { partnerId: UUID }, AppThunkAPIConfig>(
  'partnerWindow/fetch',
  async ({ partnerId }, { rejectWithValue, signal }) => {
    try {
      const querydsl = [
        {
          field: 'partnerId',
          operator: DataFilterQueryDslOperator.Equals,
          value: [partnerId],
        },
      ];

      const { data } = await Api.cms.page.all({
        querydsl,
        signal,
      });
      return data?.[0] ?? null;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const partnerWindowChangeActive = createAsyncThunk<
  CmsSitePage,
  { cmsSitePage: CmsSitePage; active: boolean },
  AppThunkAPIConfig
>('partnerWindow/changeActive', async ({ cmsSitePage, active }, { rejectWithValue }) => {
  try {
    const { data } = await Api.cms.page.patch({
      id: cmsSitePage.id,
      oldData: cmsSitePage,
      newData: {
        ...cmsSitePage,
        enabled: active,
      },
    });
    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface PartnerWindowDetailsState extends Fetchable {
  readonly guid: Nullable<UUID>;
  readonly cmsSitePage: Fetchable & {
    readonly data: Nullable<CmsSitePage>;
  };
  readonly changeActive: Fetchable;
  readonly needRefreshWatcher: number;
}

type Reducer<T = undefined> = CaseReducer<PartnerWindowDetailsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PartnerWindowDetailsState> {
  partnerWindowDetailsStartSession: Reducer<{ guid: UUID }>;
  partnerWindowDetailsNeedRefreshWatcherReset: Reducer;
}

const slice = createSlice<PartnerWindowDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    ...fetchableDefault,
    guid: null,
    cmsSitePage: {
      ...fetchableDefault,
      data: null,
    },
    changeActive: {
      ...fetchableDefault,
    },
    needRefreshWatcher: 0,
  },
  reducers: {
    partnerWindowDetailsStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;
        state.cmsSitePage = {
          ...fetchableDefault,
          data: null,
        };
        state.changeActive = {
          ...fetchableDefault,
        };
        state.needRefreshWatcher = 0;
      }
    },
    partnerWindowDetailsNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(partnerWindowFetch.pending, state => {
        state.cmsSitePage.isFetching = true;
        state.cmsSitePage.isFetched = false;
        state.cmsSitePage.isFailed = false;
      })
      .addCase(partnerWindowFetch.fulfilled, (state, { payload }) => {
        state.cmsSitePage.isFetching = false;
        state.cmsSitePage.isFetched = true;
        state.cmsSitePage.isFailed = false;

        state.cmsSitePage.data = payload;
      })
      .addCase(partnerWindowFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.cmsSitePage.isFetching = false;
          state.cmsSitePage.isFetched = false;
          state.cmsSitePage.isFailed = true;
        }
      })
      .addCase(partnerWindowChangeActive.pending, state => {
        state.changeActive.isFetching = true;
        state.changeActive.isFetched = false;
        state.changeActive.isFailed = false;
      })
      .addCase(partnerWindowChangeActive.fulfilled, (state, { payload }) => {
        state.changeActive.isFetching = false;
        state.changeActive.isFetched = true;
        state.changeActive.isFailed = false;

        state.cmsSitePage.data = payload;
      })
      .addCase(partnerWindowChangeActive.rejected, state => {
        state.changeActive.isFetching = false;
        state.changeActive.isFetched = false;
        state.changeActive.isFailed = true;
      });
  },
});

export const { partnerWindowDetailsStartSession, partnerWindowDetailsNeedRefreshWatcherReset } = slice.actions;

export default slice.reducer;
