import { useMemo } from 'react';
import useCurrentUser from '../../../../user/hooks/useCurrentUser';
import { EMainMenuType } from '../../enums';
import MainMenuHeaderMp from './mp';
import MainMenuHeaderSport from './sport';

interface MainMenuHeaderAdapterProps {
  readonly menuType: EMainMenuType;
}

export const MainMenuHeaderAdapter = ({ menuType }: MainMenuHeaderAdapterProps) => {
  const { user } = useCurrentUser();

  return useMemo(() => {
    switch (menuType) {
      case EMainMenuType.Sport:
        return <MainMenuHeaderSport user={user} />;
      case EMainMenuType.Partner:
      case EMainMenuType.MpCommon:
        return <MainMenuHeaderMp user={user} />;
    }
  }, [menuType, user]);
};
