import { Nullable } from '../../../../../../../../domain/model/types';
import DataTableCellTextFormat from '../../../../../../../components/common/table/cell/textFormat';

interface PartnerTableCellNameProps {
  readonly name: Nullable<string>;
}

const PartnerTableCellName = ({ name }: PartnerTableCellNameProps) => (
  <DataTableCellTextFormat>{name}</DataTableCellTextFormat>
);

export default PartnerTableCellName;
