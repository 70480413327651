import { AdCampaign, AdCampaignCreate, AdCampaignCreateRequest, DataFilterQueryDslOperator } from '@/domain';
import { BookingOfferTableFilterValues, EBookingOfferTableFilterItem } from '@features/bookingOffer/filterUtils';
import { CorpOfferTableFilterValues, ECorpOfferTableFilterItem } from '@features/corpOffer/filterUtils';
import { ETradeOfferTableFilterItem, TradeOfferTableFilterValues } from '@features/tradeOffer/filterUtils';
import { pluralize } from '../../../utils/pluralize';
import { EValidationType, ValidationResult } from '../../../utils/validation';
import { AdCampaignCreateStepType, EAdCampaignStep } from '../types';

export const getAdCampaignCreateStepText = (step: AdCampaignCreateStepType): string => {
  switch (step) {
    case EAdCampaignStep.General:
      return 'Описание';
    case EAdCampaignStep.Keywords:
      return 'Ключевые слова';
  }
};

export const createEmptyAdCampaignCreate = (): AdCampaignCreate => {
  return {
    id: null,
    name: null,
    status: null,
    partner: null,
    startDate: null,
    endDate: null,
    type: null,
    offerType: null,
    offers: null,
    keywords: null,
    budget: null,
  };
};

export const convertAdCampaignCreateToCreateRequest = (adCampaignCreate: AdCampaignCreate): AdCampaignCreateRequest => {
  const { name, partner, offers, offerType, startDate, endDate, keywords, budget } = adCampaignCreate;

  return {
    name,
    partner: partner?.id ? { id: partner.id } : null,
    offers: offers?.map(offer => ({ id: offer.id })) ?? null,
    offerType,
    startDate,
    endDate,
    keywords,
    budget,
  };
};
export const convertAdCampaignToAdCampaignCreate = (adCampaign: AdCampaign): AdCampaignCreate => {
  return adCampaign;
};

export const convertAdCampaignCreateToUpdateRequest = convertAdCampaignCreateToCreateRequest;

const adCampaignGetValidationCommonError = (
  step: AdCampaignCreateStepType,
  count: number
): Nullable<ValidationResult<any>> => {
  if (!count) {
    return null;
  }

  return {
    common: {
      type: EValidationType.Error,
      hasError: true,
      message: `Не заполнено ${count} ${pluralize(count, ['поле', 'поля', 'полей'])}`,
    },
  };
};

export const adCampaignCreateInitValidationStepper = (validation: ValidationResult<AdCampaignCreate>) => {
  const generalErrorsCount = [
    validation.name,
    validation.type,
    validation.partner,
    validation.offerType,
    validation.startDate,
    validation.endDate,
  ].filter(item => !!item).length;

  const keywordsErrorsCount = [validation.offers, validation.keywords, validation.budget].filter(item => !!item).length;

  return {
    [EAdCampaignStep.General]: adCampaignGetValidationCommonError(EAdCampaignStep.General, generalErrorsCount),
    [EAdCampaignStep.Keywords]: adCampaignGetValidationCommonError(EAdCampaignStep.Keywords, keywordsErrorsCount),
  };
};

export const getAdCampaignCreateSteps = (): AdCampaignCreateStepType[] => {
  return [EAdCampaignStep.General, EAdCampaignStep.Keywords];
};

export const getAdCampaignSearchCorpOfferPresetValues = (
  adCampaign: Pick<AdCampaignCreate, 'startDate' | 'endDate' | 'partner'>
): CorpOfferTableFilterValues => {
  let filterValues: CorpOfferTableFilterValues = {};
  if (adCampaign.endDate) {
    filterValues = {
      ...filterValues,
      [ECorpOfferTableFilterItem.StartDate]: {
        value: adCampaign.endDate,
        querydsl: {
          operator: DataFilterQueryDslOperator.LessOrEquals,
        },
      },
    };
  }
  if (adCampaign.startDate) {
    filterValues = {
      ...filterValues,
      [ECorpOfferTableFilterItem.EndDate]: {
        value: adCampaign.startDate,
        querydsl: {
          operator: DataFilterQueryDslOperator.MoreOrEquals,
        },
      },
    };
  }

  if (adCampaign.partner) {
    filterValues = {
      ...filterValues,
      [ECorpOfferTableFilterItem.Partner]: {
        value: [adCampaign.partner],
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
        },
      },
    };
  }

  return filterValues;
};

export const getAdCampaignSearchTradeOfferPresetValues = (
  adCampaign: Pick<AdCampaignCreate, 'startDate' | 'endDate' | 'partner'>
): TradeOfferTableFilterValues => {
  let filterValues: TradeOfferTableFilterValues = {};
  if (adCampaign.endDate) {
    filterValues = {
      ...filterValues,
      [ETradeOfferTableFilterItem.StartDate]: {
        value: adCampaign.endDate,
        querydsl: {
          operator: DataFilterQueryDslOperator.LessOrEquals,
        },
      },
    };
  }
  if (adCampaign.startDate) {
    filterValues = {
      ...filterValues,
      [ETradeOfferTableFilterItem.EndDate]: {
        value: adCampaign.startDate,
        querydsl: {
          operator: DataFilterQueryDslOperator.MoreOrEquals,
        },
      },
    };
  }

  if (adCampaign.partner) {
    filterValues = {
      ...filterValues,
      [ETradeOfferTableFilterItem.Partner]: {
        value: [adCampaign.partner],
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
        },
      },
    };
  }

  return filterValues;
};

export const getAdCampaignSearchBookingOfferPresetValues = (
  adCampaign: Pick<AdCampaignCreate, 'partner'>
): CorpOfferTableFilterValues => {
  let filterValues: BookingOfferTableFilterValues = {};

  if (adCampaign.partner) {
    filterValues = {
      ...filterValues,
      [EBookingOfferTableFilterItem.Partner]: {
        value: [adCampaign.partner],
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
        },
      },
    };
  }

  return filterValues;
};
