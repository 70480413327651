import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  overflow: hidden;
`;

export const Content = styled.div`
  max-width: 22.125rem;
`;
