import {ECorpPersonalPromotionType, EOfferPausedReason, EOfferStatus, ETradeOfferType,} from '@/domain';
import moment from 'moment';
import {EOfferActionType} from '../../types';
import {OfferLifeCycleCondition} from '../types';

/*
 * односложные условия, если несколько условий то они всегда должны работать по принципу И
 */

export const isWaitingForModerationCondition: OfferLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Ожидает модерации'],
  call: ({ obj }) => {
    const { status } = obj;

    return status === EOfferStatus.WaitingForModeration;
  },
};

export const isNotWaitingForModerationCondition: OfferLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Не ожидает модерации'],
  call: props => !isWaitingForModerationCondition.call(props),
};

export const isModeratedByMeCondition: OfferLifeCycleCondition = {
  description: 'Проверка по статусу и модератору',
  algorithm: ['На модерации у текущего пользователя'],
  call: ({ userId, obj }) => {
    const { status, approvingAdmin } = obj;
    const isModerator = userId === approvingAdmin?.id;

    return status === EOfferStatus.OnModeration && isModerator;
  },
};

export const isNotOnModerationByMeCondition: OfferLifeCycleCondition = {
  description: 'Проверка по статусу и модератору',
  algorithm: ['На модерации у другого пользователя'],
  call: ({ userId, obj }) => {
    const { status, approvingAdmin } = obj;
    const isModerator = userId === approvingAdmin?.id;

    return status === EOfferStatus.OnModeration && !isModerator;
  },
};

export const isOnModerationCondition: OfferLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['На модерации'],
  call: ({ userId, obj }) => {
    const { status } = obj;

    return status === EOfferStatus.OnModeration;
  },
};

export const isNotArchivedCondition: OfferLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Не находится в архиве'],
  call: ({ obj }) => {
    const { status } = obj;
    return status !== EOfferStatus.Archived;
  },
};

export const isPersonalPromotionTypeCondition: OfferLifeCycleCondition = {
  description: 'Проверка по типам предложения',
  algorithm: ['Тип с кодами'],
  call: ({ obj }) => {
    const { promotionType } = obj;
    return (
      !!promotionType &&
      [
        ETradeOfferType.PromoCode,
        ETradeOfferType.AccessCode,
        ETradeOfferType.Voucher,
        ETradeOfferType.Asp,
        ECorpPersonalPromotionType.ExternalCertificate,
        ECorpPersonalPromotionType.Certificate,
      ].includes(promotionType)
    );
  },
};

export const isNotPersonalPromotionTypeCondition: OfferLifeCycleCondition = {
  description: 'Проверка по типам предложения',
  algorithm: ['Тип без кодов'],
  call: props => !isPersonalPromotionTypeCondition.call(props),
};

export const isArchivedCondition: OfferLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Архивный'],
  call: ({ obj }) => {
    const { status } = obj;

    return status === EOfferStatus.Archived;
  },
};

export const isDraftCondition: OfferLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Черновик'],
  call: ({ obj }) => {
    const { status } = obj;

    return status === EOfferStatus.Draft;
  },
};

export const isPausedCondition: OfferLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Приостановлен'],
  call: ({ obj }) => {
    const { status } = obj;

    return status === EOfferStatus.Paused;
  },
};

export const isRejectedCondition: OfferLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Отмененный'],
  call: ({ obj }) => {
    const { status } = obj;

    return status === EOfferStatus.Rejected;
  },
};

export const isUpcomingCondition: OfferLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Предстоящее'],
  call: ({ obj }) => {
    const { status } = obj;

    return status === EOfferStatus.Upcoming;
  },
};

export const isActiveCondition: OfferLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Активный'],
  call: ({ obj }) => {
    const { status } = obj;

    return status === EOfferStatus.Active;
  },
};

export const isPausedByAdminCondition: OfferLifeCycleCondition = {
  description: 'Проверка по причине приостановки',
  algorithm: ['Приостановлен админом'],
  call: ({ obj }) => {
    const { pausedReason } = obj;

    return pausedReason?.code === EOfferPausedReason.PausedByAdmin;
  },
};

export const isNotPausedByAdminCondition: OfferLifeCycleCondition = {
  description: 'Проверка по причине приостановки',
  algorithm: ['Приостановлен не админом'],
  call: props => {
    return !isPausedByAdminCondition.call(props);
  },
};

export const isPausedAfterCodesAddingCondition: OfferLifeCycleCondition = {
  description: 'Проверка по причине приостановки',
  algorithm: ['Приостановлен после добавления кодов'],
  call: ({ obj }) => {
    const { pausedReason } = obj;

    return pausedReason?.code === EOfferPausedReason.PausedAfterCodesAdding;
  },
};

export const isNotDraftCondition: OfferLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Не черновик'],
  call: props => !isDraftCondition.call(props),
};

export const canNotEditCodesCondition: OfferLifeCycleCondition = {
  description: 'Проверка по доступности действий',
  algorithm: ['Недоступность действий с кодами'],
  call: ({ nextActions }) =>
    nextActions.every(
      action => ![EOfferActionType.DeleteCodes, EOfferActionType.CreateCodes].includes(action.type) || action.disabled
    ),
};

export const hasNotOfferCountCondition: OfferLifeCycleCondition = {
  description: 'Проверка по количеству кодов',
  algorithm: ['Коды отсутствуют'],
  call: ({ obj }) => {
    const { offerCount } = obj;
    return !offerCount;
  },
};

export const hasNotFreeOfferCountCondition: OfferLifeCycleCondition = {
  description: 'Проверка по количеству кодов',
  algorithm: ['Отсутствуют свободные коды'],
  call: ({ obj }) => {
    const { notUsedOfferCount } = obj;
    return notUsedOfferCount === 0;
  },
};

export const isNotSavedOfferCondition: OfferLifeCycleCondition = {
  description: 'Проверка по ID',
  algorithm: ['Запись ещё не сохранена'],
  call: ({ obj }) => {
    const { id } = obj;
    return !id;
  },
};

export const isNotActiveByDateOfferCondition: OfferLifeCycleCondition = {
  description: 'Проверка по дате окончания действия',
  algorithm: ['Не действует по дате окончания'],
  call: ({ obj }) => {
    const { endDate } = obj;
    return !endDate || moment().isAfter(moment(endDate).endOf('day'));
  },
};
