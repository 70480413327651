import styled from '@emotion/styled';
import { viewConfig } from '../../../theme/viewConfig';

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;

  background: ${p => p.theme.palette.background.default};
`;

export const Wrapper = styled.div`
  height: 100%;

  display: flex;
  flex-direction: row;
`;

export const ColumnWrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  display: flex;
  flex-direction: column;
`;
