import { forwardRef, ReactNode, useState } from 'react';
import { UUID } from '../../../../../../domain/model/types';
import MenuButton from '../../../../../components/common/buttons/menu';
import ContentLoader from '../../../../../components/common/loader';
import { ActionCmsContainerType, CmsContainerActions } from '../../types';
import { CmsContainerActionTableCommonType, CmsContainerTableActionProcess } from '../utils';
import { ActionsWrapper } from './controls';
import CmsContainerTableActionItem from './item';

interface CmsContainerTableActionsProps<T extends ActionCmsContainerType, A extends string> {
  readonly id: UUID;
  readonly actions: CmsContainerActions<A>;
  readonly processes: CmsContainerTableActionProcess[];
  readonly onExecute: (action: A) => void;
}

type CmsContainerTableActionsType = <
  T extends ActionCmsContainerType,
  A extends string = CmsContainerActionTableCommonType
>(
  props: CmsContainerTableActionsProps<T, A>
) => ReactNode;

const CmsContainerTableActions: CmsContainerTableActionsType = forwardRef((props, ref: any) => {
  const { id, actions, onExecute, processes } = props;
  const [closeMarker, setCloseMarker] = useState<symbol>(Symbol());

  const onExecuteInternal: typeof onExecute = type => {
    setCloseMarker(Symbol());
    onExecute(type);
  };

  if (processes.some(process => process.isFetching)) {
    return (
      <ActionsWrapper>
        <ContentLoader size={20} />
      </ActionsWrapper>
    );
  }

  return actions.length > 0 ? (
    <ActionsWrapper>
      <MenuButton
        disablePropagation
        ref={ref}
        id={id}
        closeMarker={closeMarker}
      >
        {actions.map(({ type, disabled, label }) => (
          <CmsContainerTableActionItem
            key={type}
            disabled={disabled ?? false}
            onExecute={() => onExecuteInternal(type)}
          >
            {label}
          </CmsContainerTableActionItem>
        ))}
      </MenuButton>
    </ActionsWrapper>
  ) : null;
});

export default CmsContainerTableActions;
