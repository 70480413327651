import { AdCampaign, EAdCampaignType, EDateFormat, EOfferType, SportOptionTyped } from '@/domain';
import { Field } from '@components/fields';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import moment from 'moment/moment';

interface AdCampaignDetailsStepGeneralProps {
  readonly adCampaign: AdCampaign;
  readonly adCampaignTypes: SportOptionTyped<EAdCampaignType>[];
  readonly offerTypes: SportOptionTyped<EOfferType>[];
}

const AdCampaignDetailsStepGeneral = (props: AdCampaignDetailsStepGeneralProps) => {
  const { adCampaign, adCampaignTypes, offerTypes } = props;

  const adCampaignType: Nullable<SportOptionTyped<EAdCampaignType>> =
    adCampaignTypes.find(type => type.id === adCampaign.type) ?? null;
  const offerType: Nullable<SportOptionTyped<EOfferType>> =
    offerTypes.find(type => type.id === adCampaign.offerType) ?? null;

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid item>
        <Typography variant={'h3'}>Описание</Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field label='Название кампании'>{adCampaign.name}</Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field label='Тип рекламной кампании'>{adCampaignType?.name ?? '-'}</Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field label='Партнёр'>{adCampaign.partner.name}</Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field label='Тип предложения'>{offerType?.name ?? '-'}</Field>
      </Grid>

      <Grid
        item
        xs={6}
      >
        <Field label='Начало'>{moment(adCampaign.startDate).format(EDateFormat.DisplayDefault)}</Field>
      </Grid>

      <Grid
        item
        xs={6}
      >
        <Field label='Окончание'>{moment(adCampaign.endDate).format(EDateFormat.DisplayDefault)}</Field>
      </Grid>
    </Grid>
  );
};

export default AdCampaignDetailsStepGeneral;
