import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import TablePagination from '../../../components/common/table/pagination';
import { useContextHandlers } from './hooks/useContextHandlers';
import {
  corpActivationsPageCountSelector,
  corpActivationsPageNumberSelector,
  corpActivationsSearchSelector,
  corpActivationsTotalCountSelector,
} from './store/selectors';

interface CorpActivationTableFooterContainerProps {}

const CorpActivationTableFooterContainer = forwardRef((props: CorpActivationTableFooterContainerProps, ref: any) => {
  const handlers = useContextHandlers();
  const search = useSelector(corpActivationsSearchSelector);
  const pageNumber = useSelector(corpActivationsPageNumberSelector);
  const totalCount = useSelector(corpActivationsTotalCountSelector);
  const pageCount = useSelector(corpActivationsPageCountSelector);

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='активаций'
        onChangePageSize={handlers.onChangePageSize}
        onChangePage={handlers.onChangePage}
      />
    </div>
  );
});

export default CorpActivationTableFooterContainer;
