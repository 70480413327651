import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CorpOffer } from '../../../../../../domain/model/corpOffer';
import { ECmsBannerLinkObjectType } from '../../../../../../domain/model/enums';
import { TradeOffer } from '../../../../../../domain/model/tradeOffer';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import useDialogInHistory from '../../../../../hooks/useDialogInHistory';
import { EOfferTableTab } from '../../../../general/offer/types';
import { CmsLinkedObjectBannerCorpOfferType, CmsLinkedObjectBannerTradeOfferType } from '../../../types/banner';

import { CollectionContentType, ECollectionSearchDialogTag } from './types';

interface UseDialogInHistoryParams {
  readonly dialog_guid: UUID;
  readonly dialog_tab: EOfferTableTab;
}

type UseCollectionOfferSingle<T extends CollectionContentType> = {
  readonly isSearchDialogOpened: boolean;
  readonly searchDialogParams: UseDialogInHistoryParams;
  readonly onOpenSearchDialog: (tab?: EOfferTableTab) => void;
  readonly onCloseSearchDialog: () => void;
  readonly onChangeSearchDialogTab: (tab: EOfferTableTab) => void;
  readonly onSelect: (offer: Nullable<T>) => void;
  readonly onClear: () => void;
};

type UseCmsCollectionTradeOfferSingleProps = {
  readonly linkObjectType: ECmsBannerLinkObjectType.TradeOffer;
  readonly onChangeLinkedObject: (data: CmsLinkedObjectBannerTradeOfferType) => void;
};

type UseCmsCollectionCorpOfferSingleProps = {
  readonly linkObjectType: ECmsBannerLinkObjectType.CorpOffer;
  readonly onChangeLinkedObject: (data: CmsLinkedObjectBannerCorpOfferType) => void;
};

type UseCmsCollectionOfferSingleProps<T extends CollectionContentType> = T extends TradeOffer
  ? UseCmsCollectionTradeOfferSingleProps
  : UseCmsCollectionCorpOfferSingleProps;

const useCmsCollectionOfferSingle = <T extends CollectionContentType>(
  props: UseCmsCollectionOfferSingleProps<T>
): UseCollectionOfferSingle<T> => {
  const {
    open: isSearchDialogOpened,
    params: searchDialogParams,
    onOpen: onOpenSearchDialog,
    onClose: onCloseSearchDialog,
  } = useDialogInHistory<UseDialogInHistoryParams>({
    tag:
      props.linkObjectType === ECmsBannerLinkObjectType.CorpOffer
        ? ECollectionSearchDialogTag.CorpSingle
        : ECollectionSearchDialogTag.TradeSingle,
    params: { dialog_guid: uuidv4(), dialog_tab: EOfferTableTab.Active },
  });

  const onOpenSearchDialogInternal = useCallback(
    (tab?: EOfferTableTab) => onOpenSearchDialog({ dialog_guid: uuidv4(), dialog_tab: tab ?? EOfferTableTab.Active }),
    [onOpenSearchDialog]
  );

  const onChangeSearchDialogTab = useCallback(
    (tab: EOfferTableTab) => onOpenSearchDialog({ ...searchDialogParams, dialog_tab: tab }),
    [searchDialogParams]
  );

  const onChangeInternal = useCallback(
    (offer: Nullable<T>) => {
      switch (props.linkObjectType) {
        case ECmsBannerLinkObjectType.CorpOffer:
          props.onChangeLinkedObject({
            linkObjectType: ECmsBannerLinkObjectType.CorpOffer,
            linkedObject: offer as CorpOffer,
          });
          break;
        case ECmsBannerLinkObjectType.TradeOffer:
          props.onChangeLinkedObject({
            linkObjectType: ECmsBannerLinkObjectType.TradeOffer,
            linkedObject: offer as TradeOffer,
          });
          break;
      }
    },
    [props.linkObjectType, props.onChangeLinkedObject]
  );

  const onSelect = useCallback(
    (offer: Nullable<T>) => {
      onChangeInternal(offer);
      onCloseSearchDialog();
    },
    [onChangeInternal, onCloseSearchDialog]
  );

  const onClear = useCallback(() => {
    onChangeInternal(null);
  }, [onChangeInternal]);

  return {
    searchDialogParams,
    isSearchDialogOpened,
    onOpenSearchDialog: onOpenSearchDialogInternal,
    onCloseSearchDialog,
    onChangeSearchDialogTab,
    onSelect,
    onClear,
  };
};

export default useCmsCollectionOfferSingle;
