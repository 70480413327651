import { ContentTarget, EMultiSelectorValueType } from '@/domain';
import { ContentTargetViewComponent } from '@features/general/contentTarget/types';
import { Typography } from '@mui/material';

const attribute: keyof ContentTarget = 'clientOrgs';

const ContentTargetViewClientOrgsCard: ContentTargetViewComponent = props => {
  const { target } = props;

  const names: string[] = [];

  const targetClientOrgs = target[attribute];

  if (targetClientOrgs) {
    if (targetClientOrgs.select === EMultiSelectorValueType.All) {
      names.push('все компании');
    } else {
      if (targetClientOrgs.in?.length) {
        names.push(...targetClientOrgs.in.map(clientOrg => clientOrg.name));
      }
    }
  }

  return (
    <>
      <Typography color='textSecondary'>Компании</Typography>
      <Typography>{names.join(', ') || '-'}</Typography>
    </>
  );
};

export default ContentTargetViewClientOrgsCard;
