import moment from 'moment';
import validator from 'validator';
import {
  ECmsBannerLinkObjectType,
  ECmsCollectionLinkObjectType,
  ECmsContainerStatus,
  ECmsContainerType,
} from '../../../domain/model/enums';
import { Nullable } from '../../../domain/model/types';
import { EValidationType, ValidationItemResult, ValidationRules } from '../../utils/validation';
import { CmsContainerView } from './types';
import { CmsLinkedObjectBannerType } from './types/banner';
import { CmsLinkedObjectCollectionType } from './types/collection';

export const cmsContainerViewValidationRules: ValidationRules<CmsContainerView> = {
  name: {
    required: true,
  },
  title: {
    required: ({ showTitle }) => !!showTitle,
  },
  startDate: {
    required: true,
    validator: (object, value) => {
      if (
        object.status === ECmsContainerStatus.Draft &&
        moment(`${value} 00:00:00`).isBefore(moment().startOf('date'))
      ) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Дата начала меньше текущей даты',
        };
      } else {
        return null;
      }
    },
  },
  endDate: {
    required: true,
    validator: (object, value) => {
      if (!value || !object.startDate) return null;
      if (moment(`${value} 23:59:59`).isBefore(object.startDate)) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Дата окончания меньше даты начала',
        };
      } else {
        return null;
      }
    },
  },
  offerType: {
    required: ({ type }) =>
      type?.code !== ECmsContainerType.CompilationPartner && type?.code !== ECmsContainerType.Banner1LinkResizable,
  },
};

export const cmsContainerViewResourcesSizeValidationRules: ValidationRules<Pick<CmsContainerView, 'resourcesSize'>> = {
  resourcesSize: {
    required: true,
  },
};

export const cmsBannerValidationRules: ValidationRules<CmsLinkedObjectBannerType> = {
  desktopImage: {
    required: true,
  },
  mobileImage: {
    required: true,
  },
  linkObjectType: {
    required: true,
  },
  linkedObject: {
    required: true,
    requiredMessage: 'Необходимо настроить переход для баннера',
    validator: (object: CmsLinkedObjectBannerType): Nullable<ValidationItemResult> => {
      let isEmpty;
      switch (object.linkObjectType) {
        case ECmsBannerLinkObjectType.Collection:
          isEmpty = (object?.linkedObject?.linkedObject?.length ?? 0) === 0;
          break;
        default:
          isEmpty = object?.linkedObject === null || object?.linkedObject === undefined;
      }
      if (isEmpty) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Необходимо выбрать элементы для перехода',
        };
      }

      switch (object.linkObjectType) {
        case ECmsBannerLinkObjectType.Link:
          if (object?.linkedObject && !validator.isURL(object.linkedObject)) {
            return {
              type: EValidationType.Error,
              hasError: true,
              message: 'Необходимо ввести корректный адрес',
            };
          }
          break;
        default:
          break;
      }

      return null;
    },
  },
};

export const cmsCollectionValidationRules: ValidationRules<CmsLinkedObjectCollectionType> = {
  linkObjectType: {
    required: true,
  },
  linkedObject: {
    required: true,
    validator: (object: CmsLinkedObjectCollectionType, value: Nullable<[]>): Nullable<ValidationItemResult> => {
      const selectedCount = value?.length ?? 0;
      const requiredCount = object.pageSize ?? 0;

      let objectNames: [withCount: string, withoutCount: string] = ['', ''];
      switch (object?.linkObjectType) {
        case ECmsCollectionLinkObjectType.CorpOffer:
        case ECmsCollectionLinkObjectType.TradeOffer:
          objectNames = ['предложений', 'предложения'];
          break;
        case ECmsCollectionLinkObjectType.Partner:
          objectNames = ['партнёров', 'партнёров'];
          break;
        case null:
          break;
      }

      // TODO - по идее проверять не надо теперь на requiredCount? только на selectedCount === 0?
      if (requiredCount && selectedCount !== requiredCount) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: `Необходимо выбрать ${requiredCount} ${objectNames[0]}`,
        };
      } else if (selectedCount === 0) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: `Необходимо выбрать ${objectNames[1]}`,
        };
      } else {
        return null;
      }
    },
  },
};
