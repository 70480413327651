import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UUID } from '../../../../domain/model/types';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import { nsiDataSelector } from '../../general/nsi/store/selectors';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import { ETeamTableFilterItem, getTeamTableFilterStrategy, teamTableFilterItems } from '../filterUtils';
import TeamsTableContainer from './container';
import { TeamsTableConfigContext } from './context';
import { useTeamsTableConfig } from './hooks/useTeamsTableConfig';
import { ETeamTableColumn, getTeamsTableAdapterKey, getTeamTableColumns } from './utils';

interface TeamTableEntryProps {
  readonly guid: UUID;
}

const TeamTableEntry = ({ guid }: TeamTableEntryProps) => {
  const {
    accessMatrix: { teams },
  } = useCurrentUser();

  const { userTypes } = useSelector(nsiDataSelector);

  const config = useTeamsTableConfig();

  // получаем максимально возможный состав колонок
  const sourceColumns = getTeamTableColumns();

  // готовим адаптер для хука
  const adapter = useMemo<DataFilterAdapter<ETeamTableColumn, ETeamTableFilterItem>>(
    () => ({
      ...getTeamsTableAdapterKey(),
      sourceColumns,
      filterItems: teamTableFilterItems,
      getDataFilterStrategy: (filterItem, values) => getTeamTableFilterStrategy(filterItem, values, userTypes),
    }),
    [sourceColumns]
  );

  return (
    <TeamsTableConfigContext.Provider value={config}>
      <TeamsTableContainer
        guid={guid}
        filterAdapter={adapter}
        canCreate={teams.create}
        tab={config.defaultTab}
      />
    </TeamsTableConfigContext.Provider>
  );
};

export default TeamTableEntry;
