import { Grid, Typography } from '@mui/material';
import { SportUserProfile } from '../../../../../../domain/model/user';
import AppBreadcrumbs from '../../../../../components/common/breadcrumbs';
import Splitter from '../../../../../components/common/splitter';
import { getUserShortName } from '../../../../../utils';
import useBreadcrumbs from '../../../../general/breadcrumbs/useBreadcrumbs';
import { BreadcrumbsWrapper, TitleWrapper, Wrapper } from './controls';

interface UserCurrentDetailsSportHeaderProps {
  readonly user: SportUserProfile;
}

const UserCurrentDetailsSportHeader = (props: UserCurrentDetailsSportHeaderProps) => {
  const { user } = props;
  const breadcrumbs = useBreadcrumbs();

  return (
    <Wrapper
      container
      spacing={1}
      direction='row'
      justifyContent='space-between'
      alignItems='baseline'
    >
      <Grid
        item
        xs={12}
      >
        <BreadcrumbsWrapper>
          {breadcrumbs.length > 0 && (
            <>
              <AppBreadcrumbs />
              <Splitter
                variant='vertical'
                size={3}
              />
            </>
          )}
        </BreadcrumbsWrapper>
      </Grid>
      <Grid item>
        <TitleWrapper>
          <Typography
            noWrap
            variant='h2'
          >
            {getUserShortName(user)}
          </Typography>
        </TitleWrapper>
      </Grid>
    </Wrapper>
  );
};

export default UserCurrentDetailsSportHeader;
