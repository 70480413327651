import { RootState } from '../../../../../../data/store/store';

export const tradeOfferCategoriesEditDataSelector = (store: RootState) =>
  store.dictionary.tradeOfferCategory.edit.fetch.data;

export const tradeOfferCategoriesEditIsPreloadingSelector = (store: RootState) =>
  store.dictionary.tradeOfferCategory.edit.fetch.isFetching;

export const tradeOfferCategoriesEditIsLoadingSelector = (store: RootState) =>
  store.dictionary.tradeOfferCategory.edit.fetch.isFetching;

export const tradeOfferCategoriesEditAddDialogStateSelector = (store: RootState) =>
  store.dictionary.tradeOfferCategory.edit.dialogs.add;

export const tradeOfferCategoriesEditDeleteDialogStateSelector = (store: RootState) =>
  store.dictionary.tradeOfferCategory.edit.dialogs.delete;

export const tradeOfferCategoriesEditEditDialogStateSelector = (store: RootState) =>
  store.dictionary.tradeOfferCategory.edit.dialogs.modify;

export const tradeOfferCategoriesEditIsRelationShipsCheckingSelector = (store: RootState) =>
  store.dictionary.tradeOfferCategory.edit.checkRelationShips.isFetching;
