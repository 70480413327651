import { FormControlLabel, FormHelperText, Grid, Radio, RadioGroup } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { SportOptionTyped } from '../../../../../../../domain/model';
import { EPartnerOwnershipType, ETaxationSystemType } from '../../../../../../../domain/model/enums';
import { PartnerDataDraft, PartnerIndividualDataDraft } from '../../../../../../../domain/model/partner';
import { Nullable } from '../../../../../../../domain/model/types';
import SectionTitle from '../../../../../../components/common/sectionTitle';
import ValidationErrorPanel from '../../../../../../components/common/validation/errorPanel';
import { MPFormInput, MPMaskInput } from '../../../../../../theme/ui-kit/input';
import { OnChangeObjectAttribute } from '../../../../../../types';
import { ValidationResult } from '../../../../../../utils/validation';
import {
  getPartnerAccountMask,
  getPartnerBikMask,
  getPartnerOgrnMask,
} from '../../../../../general/partner/utils/common';
import { PartnerDetailsIndividual } from '../../../../../general/partner/validation';

interface PartnerApplicationEditIndividualDetailsProps {
  readonly bic: Nullable<string>;
  readonly checkingAccount: Nullable<string>;
  readonly ogrnip: Nullable<string>;
  readonly taxSystem: Nullable<ETaxationSystemType>;
  readonly taxationSystemTypes: SportOptionTyped<ETaxationSystemType>[];
  readonly validation: Nullable<ValidationResult<PartnerDetailsIndividual>>;
  readonly isValid: boolean;
  readonly onChangeAttribute: OnChangeObjectAttribute<PartnerDataDraft>;
  readonly onChangeIndividualAttribute: OnChangeObjectAttribute<PartnerIndividualDataDraft>;
}

const OgrnMaskInput = React.forwardRef<any, any>(({ ...props }, ref) => (
  <MPMaskInput
    mask={getPartnerOgrnMask(EPartnerOwnershipType.IndividualEntrepreneur)}
    ref={ref}
    {...props}
  />
));

const BikMaskInput = React.forwardRef<any, any>(({ ...props }, ref) => (
  <MPMaskInput
    mask={getPartnerBikMask(EPartnerOwnershipType.IndividualEntrepreneur)}
    ref={ref}
    {...props}
  />
));

const AccountMaskInput = React.forwardRef<any, any>(({ ...props }, ref) => (
  <MPMaskInput
    mask={getPartnerAccountMask(EPartnerOwnershipType.IndividualEntrepreneur)}
    ref={ref}
    {...props}
  />
));

const PartnerApplicationEditIndividualDetails = (props: PartnerApplicationEditIndividualDetailsProps) => {
  const {
    bic,
    checkingAccount,
    ogrnip,
    taxSystem,
    taxationSystemTypes,
    isValid,
    validation,
    onChangeAttribute,
    onChangeIndividualAttribute,
  } = props;

  return (
    <Grid
      container
      spacing={2}
      direction='column'
    >
      <Grid item>
        <Typography variant='h3'>Реквизиты</Typography>
      </Grid>

      <Grid item>
        <MPFormInput
          label='БИК'
          value={bic}
          error={!!validation?.bic?.hasError}
          helperText={validation?.bic?.message}
          onChange={(event: any) => onChangeAttribute('bic', event.target.value.replaceAll(' ', ''))}
          InputProps={{
            inputComponent: BikMaskInput,
          }}
        />
      </Grid>

      <Grid item>
        <MPFormInput
          label='Расчетный счет компании'
          value={checkingAccount}
          error={!!validation?.checkingAccount?.hasError}
          helperText={validation?.checkingAccount?.message}
          onChange={(event: any) => onChangeAttribute('checkingAccount', event.target.value.replaceAll(' ', ''))}
          InputProps={{
            inputComponent: AccountMaskInput,
          }}
        />
      </Grid>

      <Grid item>
        <MPFormInput
          label='ОГРНИП'
          value={ogrnip}
          error={!!validation?.ogrnip?.hasError}
          helperText={validation?.ogrnip?.message}
          onChange={(event: any) => onChangeIndividualAttribute('ogrnip', event.target.value.replaceAll(' ', ''))}
          InputProps={{
            inputComponent: OgrnMaskInput,
          }}
        />
      </Grid>

      <Grid item>
        <SectionTitle>Система налогообложения</SectionTitle>
        <RadioGroup
          aria-label='tax-system-type'
          name='taxSystem'
          value={taxSystem}
          onChange={(event: any) => onChangeAttribute('taxSystem', event.target.value)}
        >
          {taxationSystemTypes.map(item => (
            <FormControlLabel
              key={item.id}
              value={item.id}
              control={<Radio color='primary' />}
              label={item.name}
            />
          ))}
        </RadioGroup>
        {!!validation?.taxSystem?.hasError && <FormHelperText error>{validation?.taxSystem?.message}</FormHelperText>}
      </Grid>
      {!isValid && (
        <Grid
          item
          xs={12}
        >
          <ValidationErrorPanel />
        </Grid>
      )}
    </Grid>
  );
};

export default PartnerApplicationEditIndividualDetails;
