import { useDispatch } from 'react-redux';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import { EBookingActionType } from '../types';
import BookingOfferActionsDialogsAdapter from './adapters/dialogs';
import BookingOfferActionsOptimizerAdapter from './adapters/optimizer';
import BookingOfferActionsContext, { BookingOfferActionsContextType } from './context';
import {
  getBookingOfferActionsOfferIsProcessingSelector,
  getIsBookingOfferAnyActionByTypeExecutedSelector,
  isBookingOffersAnyChangedSelector,
} from './store/selectors';
import {
  bookingOfferActionsArchive,
  bookingOfferActionsChangeDialogState,
  bookingOfferActionsChangeModerator,
  bookingOfferActionsDelete,
  bookingOfferActionsDuplicate,
  bookingOfferActionsInWork,
  bookingOfferActionsManageServices,
  bookingOfferActionsNewVersion,
  bookingOfferActionsPublish,
  bookingOfferActionsReject,
  bookingOfferActionsReturnToVerification,
  bookingOfferActionsSave,
  bookingOfferActionsUnPublish,
} from './store/slice';
import { showBookingOfferActionNotification, showBookingOfferSaveErrorsNotification } from './utils';

interface BookingOfferActionsProviderProps {
  readonly children: JSX.Element;
}

type BookingOfferActionsProviderType = (props: BookingOfferActionsProviderProps) => JSX.Element;

const BookingOfferActionsProvider: BookingOfferActionsProviderType = ({ children }) => {
  const dispatch = useDispatch();

  const { id: userId } = useCurrentUser().userSpecific;

  const onSave: BookingOfferActionsContextType['onSave'] = async offer => {
    const response = await dispatch(bookingOfferActionsSave(offer)).unwrap();

    if (response) {
      showBookingOfferActionNotification(response.offer, EBookingActionType.Edit, !offer.id);

      if (response.errors.length) {
        showBookingOfferSaveErrorsNotification(response.offer, response.errors);
      }
    }

    return response;
  };

  const onSaveServices: BookingOfferActionsContextType['onSaveServices'] = async (offerId, services) => {
    const response = await dispatch(bookingOfferActionsManageServices({ offerId, services })).unwrap();
    if (response.errors?.length === 0) {
      showBookingOfferActionNotification(null, EBookingActionType.ManageServices, false);
    }
    return response;
  };

  const onPublish: BookingOfferActionsContextType['onPublish'] = async id => {
    const response = await dispatch(bookingOfferActionsPublish({ id })).unwrap();
    onChangeDialogState('publish', response);
    return response;
  };

  const onUnPublish: BookingOfferActionsContextType['onUnPublish'] = async id => {
    return await dispatch(bookingOfferActionsUnPublish({ id })).unwrap();
  };

  const onNewVersion: BookingOfferActionsContextType['onNewVersion'] = async props => {
    const response = await dispatch(bookingOfferActionsNewVersion(props)).unwrap();

    if (response) {
      showBookingOfferActionNotification(response.offer, EBookingActionType.SaveNewVersion, !props.id);
    }

    return response;
  };

  const onArchive: BookingOfferActionsContextType['onArchive'] = async id => {
    const response = await dispatch(bookingOfferActionsArchive({ id })).unwrap();
    if (response) {
      showBookingOfferActionNotification(response, EBookingActionType.Archive, false);
    }
    return response;
  };

  const onDuplicate: BookingOfferActionsContextType['onDuplicate'] = async id => {
    const response = await dispatch(bookingOfferActionsDuplicate({ id })).unwrap();
    if (response) {
      showBookingOfferActionNotification(response, EBookingActionType.Duplicate, false);
    }
    return response;
  };

  const onDelete: BookingOfferActionsContextType['onDelete'] = async id => {
    await dispatch(bookingOfferActionsDelete({ id })).unwrap();
    showBookingOfferActionNotification(null, EBookingActionType.Delete, false);
    return Promise.resolve(false);
  };

  const onReturnToVerification: BookingOfferActionsContextType['onReturnToVerification'] = async id => {
    const response = await dispatch(bookingOfferActionsReturnToVerification({ id, userId })).unwrap();
    showBookingOfferActionNotification(null, EBookingActionType.ReturnToVerification, false);
    return response;
  };

  const onReject: BookingOfferActionsContextType['onReject'] = async (offer, comment) => {
    const response = await dispatch(bookingOfferActionsReject({ id: offer.id, comment })).unwrap();
    if (response) {
      showBookingOfferActionNotification(response, EBookingActionType.Reject, false);
    }
    return response;
  };

  const onChangeModerator: BookingOfferActionsContextType['onChangeModerator'] = async (id, userId) => {
    const response = await dispatch(bookingOfferActionsChangeModerator({ id, userId })).unwrap();
    if (response) {
      showBookingOfferActionNotification(response, EBookingActionType.ChangeModerator, false);
    }
    return response;
  };

  const onTryReject: BookingOfferActionsContextType['onTryReject'] = offer => {
    onChangeDialogState('reject', offer);
  };

  const onTryDelete: BookingOfferActionsContextType['onTryDelete'] = offer => {
    onChangeDialogState('delete', offer);
  };

  const onTryArchive: BookingOfferActionsContextType['onTryArchive'] = offer => {
    onChangeDialogState('archive', offer);
  };

  const onTryPause: BookingOfferActionsContextType['onTryPause'] = offer => {
    onChangeDialogState('pause', offer);
  };

  const onTryChangeModerator: BookingOfferActionsContextType['onTryChangeModerator'] = offer => {
    onChangeDialogState('changeModerator', offer);
  };

  const onTryReturnToVerification: BookingOfferActionsContextType['onTryReturnToVerification'] = offer => {
    onChangeDialogState('returnToVerification', offer);
  };

  const onTryDuplicate: BookingOfferActionsContextType['onTryDuplicate'] = offer => {
    dispatch(bookingOfferActionsDuplicate({ id: offer.id }))
      .unwrap()
      .then(response => showBookingOfferActionNotification(response, EBookingActionType.Duplicate, false));
  };

  const onTryRetrieve: BookingOfferActionsContextType['onTryRetrieve'] = offer => {
    onChangeDialogState('retrieve', offer);
  };

  const onTryApprove: BookingOfferActionsContextType['onTryApprove'] = offer => {
    onChangeDialogState('approve', offer);
  };

  const onTryInWork: BookingOfferActionsContextType['onTryInWork'] = offer => {
    dispatch(bookingOfferActionsInWork({ id: offer.id, userId }))
      .unwrap()
      .then(response => showBookingOfferActionNotification(response, EBookingActionType.InWork, false));
  };

  const onTryUnPublish: BookingOfferActionsContextType['onTryUnPublish'] = offer => {
    onChangeDialogState('unPublish', offer);
  };

  const onTryPublish: BookingOfferActionsContextType['onTryPublish'] = offer => {
    onChangeDialogState('publish', offer);
  };

  const onTryResume: BookingOfferActionsContextType['onTryResume'] = offer => {
    onChangeDialogState('resume', offer);
  };

  const onTryServiceManage: BookingOfferActionsContextType['onTryServiceManage'] = offer => {
    onChangeDialogState('serviceManage', offer);
  };

  const onChangeDialogState: BookingOfferActionsContextType['onChangeDialogState'] = (name, data) => {
    dispatch(bookingOfferActionsChangeDialogState({ name, data }));
  };

  const value: BookingOfferActionsContextType = {
    onChangeDialogState,
    onSave,
    onSaveServices,
    onPublish,
    onArchive,
    onDuplicate,
    onDelete,
    onReturnToVerification,
    onReject,
    onChangeModerator,
    onUnPublish,
    onNewVersion,

    onTryReject,
    onTryPause,
    onTryArchive,
    onTryDelete,
    onTryChangeModerator,
    onTryReturnToVerification,
    onTryDuplicate,
    onTryRetrieve,
    onTryApprove,
    onTryInWork,
    onTryUnPublish,
    onTryPublish,
    onTryResume,
    onTryServiceManage,

    utils: {
      selectors: {
        getIsOfferProcessingSelector: getBookingOfferActionsOfferIsProcessingSelector,
        getIsOfferExecutedActionsSelector: getIsBookingOfferAnyActionByTypeExecutedSelector,
        isBookingOffersAnyChangedSelector,
      },
    },
  };

  return (
    <BookingOfferActionsContext.Provider value={value}>
      {children}
      <BookingOfferActionsOptimizerAdapter />
      <BookingOfferActionsDialogsAdapter />
    </BookingOfferActionsContext.Provider>
  );
};

export default BookingOfferActionsProvider;
