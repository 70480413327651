import { FCC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BookingOfferDetailsContainerProps } from './container';
import { bookingOfferDetailsByIdFetch, bookingOfferDetailsStateReset } from './store/slice';

type BookingOfferDetailsInitializerProps = BookingOfferDetailsContainerProps;

export const BookingOfferDetailsInitializer: FCC<BookingOfferDetailsInitializerProps> = ({ id, children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const promise = dispatch(bookingOfferDetailsByIdFetch({ id }));

    return () => {
      dispatch(bookingOfferDetailsStateReset());
      promise?.abort();
    };
  }, [dispatch, id]);

  return <>{children}</>;
};
