import { EPanelActionPosition } from '../../../../types';
import { BookingLifeCycleRules } from '../../lifecycle/types';
import { BookingActionDetailsType, BookingActions, EBookingActionType } from '../../types';
import {
  changeBookingActionPosition,
  filterBookingDetailsActions,
  makeBookingActionPrimary,
  renameBookingAction,
} from '../../utils/actions';

type UseBookingDetailsActions = BookingActions<BookingActionDetailsType>;

interface UseBookingDetailsActionsProps {
  readonly lifeCycleRules: BookingLifeCycleRules;
  readonly isLastStep: boolean;
}

const useBookingDetailsActions = (props: UseBookingDetailsActionsProps): UseBookingDetailsActions => {
  const { lifeCycleRules } = props;

  const { nextActions } = lifeCycleRules;
  let detailsActions = filterBookingDetailsActions(nextActions);

  //переименуем кнопку Редактирования/Сохранения
  renameBookingAction(detailsActions, EBookingActionType.Edit, 'Редактировать');
  //перемещаем кнопку Редактирования/Сохранения в меню
  changeBookingActionPosition(detailsActions, EBookingActionType.Edit, [EPanelActionPosition.Menu]);
  //перемещаем кнопку Дублирования в меню
  changeBookingActionPosition(detailsActions, EBookingActionType.Duplicate, [EPanelActionPosition.Menu]);
  //перемещаем кнопку Возврата из архива в меню
  changeBookingActionPosition(detailsActions, EBookingActionType.Retrieve, [EPanelActionPosition.Menu]);
  //перемещаем кнопку Назначения модератора в меню
  changeBookingActionPosition(detailsActions, EBookingActionType.ChangeModerator, [EPanelActionPosition.Menu]);

  // помечаем праймари экшоны
  detailsActions = makeBookingActionPrimary(detailsActions, [
    EBookingActionType.Resume,
    EBookingActionType.Pause,
    EBookingActionType.Approve,
    EBookingActionType.Reject,
    EBookingActionType.ReturnToVerification,
  ]);

  return detailsActions;
};

export default useBookingDetailsActions;
