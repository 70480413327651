import { useCallback, useEffect, useState } from 'react';
import { SportOptionTyped } from '../../../../../../domain/model';
import { Nullable } from '../../../../../../domain/model/types';
import { getSportOptionMultipleItemAllOptions, getSportOptionMultipleItemNoneOptions } from './item';
import {
  SportOptionMultipleAllOptionsState,
  SportOptionMultipleItemType,
  SportOptionMultipleSelectType,
  SportOptionMultipleTypesSelectorItem,
} from './types';

interface UseSportOptionMultipleTypesProps<T extends string> {
  readonly select?: Nullable<SportOptionMultipleSelectType>;
  readonly selectNone?: boolean;
  readonly selectAllLabel: string;
  readonly values: T[];
  readonly allValues: SportOptionTyped<T>[];
  readonly onSelectNone?: () => void;
  readonly onChange: (value: T[]) => void;
}

interface UseSportOptionMultipleTypes<T extends string> {
  readonly options: SportOptionMultipleTypesSelectorItem<T>[];
  readonly value: SportOptionMultipleTypesSelectorItem<T>[];
  readonly setSearchValue: (value: string) => void;
  readonly onChangeValue: (value: Nullable<SportOptionMultipleTypesSelectorItem<T>[]>) => void;
  readonly getOptionLabel: (option: SportOptionMultipleTypesSelectorItem<T>) => string;
}

const useSportOptionMultipleTypes = <T extends string>(
  props: UseSportOptionMultipleTypesProps<T>
): UseSportOptionMultipleTypes<T> => {
  const { select, selectNone, selectAllLabel, values, allValues, onChange, onSelectNone } = props;

  const [suggestions, setSuggestions] = useState<SportOptionTyped<T>[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    if (searchValue) {
      setSuggestions(
        allValues.filter(road => road.name.toLocaleUpperCase().startsWith(searchValue.toLocaleUpperCase()))
      );
    }
  }, [searchValue, allValues]);

  const onChangeValue = useCallback(
    (value: Nullable<SportOptionMultipleTypesSelectorItem<T>[]>) => {
      if (
        value?.some(
          item =>
            item.type === SportOptionMultipleItemType.All && item.state !== SportOptionMultipleAllOptionsState.Checked
        )
      ) {
        onChange(allValues.map(v => v.id));
      } else if (
        value?.some(
          item =>
            item.type === SportOptionMultipleItemType.None && item.state !== SportOptionMultipleAllOptionsState.Checked
        )
      ) {
        onSelectNone?.();
      } else {
        const selectedOptions = value?.filter(v => v.type === SportOptionMultipleItemType.Option).map(v => v.id) ?? [];
        const setUnselectedAll = select && selectedOptions.length === allValues.length;
        onChange(setUnselectedAll ? [] : selectedOptions);
      }
    },
    [onChange, allValues, select]
  );

  const getOptionLabel = useCallback((option: SportOptionMultipleTypesSelectorItem<T>) => {
    switch (option.type) {
      case SportOptionMultipleItemType.Option:
        return option.name;
      default:
        return '';
    }
  }, []);

  const options: SportOptionMultipleTypesSelectorItem<T>[] = [];

  if (selectNone) {
    options.push(
      getSportOptionMultipleItemNoneOptions(
        select === SportOptionMultipleSelectType.None
          ? SportOptionMultipleAllOptionsState.Checked
          : SportOptionMultipleAllOptionsState.NotChecked
      )
    );
  }
  //всегда добавляем первым "Все опции"
  options.push(
    getSportOptionMultipleItemAllOptions(
      selectAllLabel,
      select === SportOptionMultipleSelectType.All
        ? SportOptionMultipleAllOptionsState.Checked
        : values?.length
        ? SportOptionMultipleAllOptionsState.Indeterminate
        : SportOptionMultipleAllOptionsState.NotChecked
    )
  );

  //если не введен текст для поиска то добавляем все значения
  if (!searchValue && allValues?.length) {
    allValues.forEach(item =>
      options.push({
        type: SportOptionMultipleItemType.Option,
        id: item.id,
        name: item.name,
      })
    );
  } else {
    suggestions.forEach(item =>
      options.push({
        type: SportOptionMultipleItemType.Option,
        id: item.id,
        name: item.name,
      })
    );
  }

  let value: SportOptionMultipleTypesSelectorItem<T>[];

  if (select === SportOptionMultipleSelectType.All) {
    value = allValues.map(item => ({
      type: SportOptionMultipleItemType.Option,
      id: item.id,
      name: item.name,
    }));
    value.unshift(getSportOptionMultipleItemAllOptions(selectAllLabel, SportOptionMultipleAllOptionsState.Checked));
  } else if (select === SportOptionMultipleSelectType.None) {
    value = [getSportOptionMultipleItemNoneOptions(SportOptionMultipleAllOptionsState.Checked)];
  } else {
    value = allValues
      .filter(item => values.includes(item.id))
      .map(item => ({
        type: SportOptionMultipleItemType.Option,
        id: item.id,
        name: item.name,
      }));
  }

  return {
    options,
    value,
    setSearchValue,
    onChangeValue,
    getOptionLabel,
  };
};

export default useSportOptionMultipleTypes;
