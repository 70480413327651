import { ReactNode, useMemo } from 'react';
import { PartnerShortDraft } from '../../../../../domain/model/partner';
import DataTableCellTextFormat from '../../../../components/common/table/cell/textFormat';

export interface PartnerTableCellCommonProps {
  readonly partner: PartnerShortDraft;
  readonly isDisabled?: boolean;
}

interface PartnerTableCellProps extends PartnerTableCellCommonProps {
  readonly value: ReactNode;
}

const PartnerTableCell = ({ value, isDisabled }: PartnerTableCellProps) => {
  return useMemo(
    () => <DataTableCellTextFormat disabled={isDisabled}>{value}</DataTableCellTextFormat>,
    [isDisabled, value]
  );
};

export default PartnerTableCell;
