import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { EOfferStatus } from '@/domain/model/enums';
import { Nullable, UUID } from '@/domain/model/types';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import TableCommonLayout from '../../../layouts/tableCommon/container';
import { NumberRange } from '../../../types';
import { EOfferTableTab } from '../../general/offer/types';
import { EBookingOfferTableFilterItem } from '../filterUtils';
import { EBookingOfferTableColumn } from '../types';
import BookingOfferSearchFilterAdapter from '././adatpers/filter';
import BookingOfferSearchTableAdapter from './adatpers/table';
import { BookingOfferSearchTabsAdapter } from './adatpers/tabs';
import { BookingOfferSearchConfigContext, BookingOfferSearchContext } from './context';
import BookingOfferSearchFooterContainer from './footerContainer';
import useBookingOfferSearchSearch from './hooks/useSearch';
import useBookingOfferSearchSession from './hooks/useSession';
import { useBookingOfferSearchHandlers } from './hooks/useBookingOfferSearch';
import { useBookingOfferSearchConfig } from './hooks/useBookingOfferSearchConfig';
import { bookingOfferSearchGet, bookingOfferSearchGuidSelector } from './store/selectors';
import { BookingOfferShort } from '@/domain/model/booking';

interface BookingOfferSearchContainerSingleProps {
  readonly initialSelected: Nullable<BookingOfferShort>;
  readonly selectedMaxCount: 1;
  readonly onSelect: (offer: Nullable<BookingOfferShort>) => void;
}

interface BookingOfferSearchContainerMultipleProps {
  readonly initialSelected: Nullable<BookingOfferShort[]>;
  readonly selectedMaxCount?: NumberRange<2, 100> | false;
  readonly onSelect: (offer: BookingOfferShort[]) => void;
}

export type BookingOfferSearchContainerSelectedProps =
  | BookingOfferSearchContainerSingleProps
  | BookingOfferSearchContainerMultipleProps;

type BookingOfferSearchContainerProps = {
  readonly guid: UUID;
  readonly tab: Nullable<EOfferTableTab>;
  readonly partnerId: Nullable<UUID>;
  readonly statuses?: EOfferStatus[];
  readonly filterAdapter: DataFilterAdapter<EBookingOfferTableColumn, EBookingOfferTableFilterItem>;
  readonly onBookingOfferClick: (bookingOffer: BookingOfferShort) => void;
  readonly onChangeTableTab: (tab: EOfferTableTab) => void;
} & BookingOfferSearchContainerSelectedProps;

const BookingOfferSearchContainer = (props: BookingOfferSearchContainerProps) => {
  const { guid, tab, partnerId, statuses = [], filterAdapter, onBookingOfferClick, onChangeTableTab } = props;

  const currentGuid = useSelector(bookingOfferSearchGuidSelector);
  const { tab: tableTab } = useSelector(bookingOfferSearchGet);

  const currentTab = tab ?? tableTab;
  const isSingleSelect = props.selectedMaxCount === 1;

  const config = useBookingOfferSearchConfig({
    isSingleSelect,
    tab: currentTab,
    onBookingOfferClick,
    onChangeTableTab,
  });
  const handlers = useBookingOfferSearchHandlers(props);

  useBookingOfferSearchSession({
    ...props,
    tab: currentTab,
  });
  useBookingOfferSearchSearch({ guid, tab: currentTab, partnerId, statuses, tabsForCounts: config.tabsForCounts });

  if (guid !== currentGuid) {
    return null;
  }

  return (
    <BookingOfferSearchConfigContext.Provider value={config}>
      <BookingOfferSearchContext.Provider value={handlers}>
        <TableCommonLayout
          filter={<BookingOfferSearchFilterAdapter filterAdapter={filterAdapter} />}
          tabs={<BookingOfferSearchTabsAdapter tab={currentTab} />}
          table={<BookingOfferSearchTableAdapter filterAdapter={filterAdapter} />}
          footer={forwardRef((footerProps, ref: any) => (
            <BookingOfferSearchFooterContainer
              ref={ref}
              maxCount={props.selectedMaxCount}
              onSelect={handlers.onReturn}
            />
          ))}
        />
      </BookingOfferSearchContext.Provider>
    </BookingOfferSearchConfigContext.Provider>
  );
};

export default BookingOfferSearchContainer;
