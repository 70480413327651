import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../data/store/store';
import { ESortDirection } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { SportUserProfile } from '../../../../../domain/model/user';
import { ESportsmanTableColumn } from '../utils';

export const sportsmansSelector = (store: RootState) => store.sportsman.list.data;

export const sportsmansGetSearch = (store: RootState) => store.sportsman.list.search;
export const sportsmansGetPageNumber = (store: RootState) => store.sportsman.list.pageNumber;

export const sportsmansGetFilter = (store: RootState) => store.sportsman.list.filter;
export const sportsmansGetNeedRefreshWatcher = (store: RootState) => store.sportsman.list.needRefreshWatcher;
export const sportsmansGetTotalCount = (store: RootState) => store.sportsman.list.totalCount;
export const sportsmansGetisFetching = (store: RootState) => store.sportsman.list.isFetching;

export const sportsmansSortDirectionSelector = (store: RootState) =>
  (store.sportsman.list.search.sort?.split(',') as [ESportsmanTableColumn, ESortDirection])?.[1] ?? '';

export const sportsmansGetGuid = (store: RootState) => store.sportsman.list.guid;
export const sportsmansGetPageCount = (store: RootState) => store.sportsman.list.pageCount;

export const sportsmansSortColumnSelector = (store: RootState) =>
  (store.sportsman.list.search.sort?.split(',') as [ESportsmanTableColumn, ESortDirection])?.[0] ?? '';

export const sportsmansTabsCounterSelector = (store: RootState) => store.sportsman.list.tabsCounter;

export const sportsmansIndexSelector = (store: RootState, index: number) => index;

const createSportsmansTableSportsmanByIndexSelector = createSelector(
  sportsmansSelector,
  sportsmansIndexSelector,
  (sportsmen, index): Nullable<SportUserProfile> => sportsmen?.[index] ?? null
);

export const sportsmansByIndexSelector = (index: number) => (store: RootState) =>
  createSportsmansTableSportsmanByIndexSelector(store, index);
