import { EDateFormat } from '@/domain';
import moment from 'moment/moment';
import BannerTableCell, { BannerTableCellCommonProps } from './index';

const BannerTableCellEndDate = (props: BannerTableCellCommonProps) => {
  const {
    banner: { endDate },
  } = props;

  const value = endDate && moment(endDate).format(EDateFormat.DisplayDefault);

  return (
    <BannerTableCell
      {...props}
      value={value}
    />
  );
};

export default BannerTableCellEndDate;
