import ClientOrgUserTableCell, { ClientOrgUserTableCellCommonProps } from './index';
import { getUserShortName } from '../../../../../utils';

const ClientOrgUserTableCellName = (props: ClientOrgUserTableCellCommonProps) => {
  const { clientOrgUser } = props;

  const value = getUserShortName(clientOrgUser) ?? '-';

  return (
    <ClientOrgUserTableCell
      {...props}
      value={value}
    />
  );
};

export default ClientOrgUserTableCellName;
