import TeamTableCell, { TeamTableCellCommonProps } from './index';

const TeamTableCellName = (props: TeamTableCellCommonProps) => {
  const {
    team: { name: value },
  } = props;

  return (
    <TeamTableCell
      {...props}
      value={value}
    />
  );
};

export default TeamTableCellName;
