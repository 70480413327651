import { Grid, IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from 'react';
import { getPromotionsDownloadTemplateEndpoint } from '../../../../../data/api/utils';
import { CommonFileInfo } from '../../../../../domain/model';
import { Nullable } from '../../../../../domain/model/types';
import FileDownloadLink from '../../../../components/common/files/download/link';
import FileUploader from '../../../../components/common/files/uploader';
import Splitter from '../../../../components/common/splitter';
import { fileTypes } from '../../../../constants';
import OfferFileInfo from '../../../general/offer/components/fileInfo';
import { ErrorWrapper, GridItemContent, Link } from '../controls';

interface ErrorBlockProps {
  readonly text: string;
}

const ErrorBlock = (props: ErrorBlockProps) => {
  const { text } = props;

  return (
    <ErrorWrapper>
      <Grid
        container
        spacing={1}
        alignItems='center'
      >
        <Grid item>
          <IconButton
            disabled
            size='small'
          >
            <ClearIcon
              color='error'
              fontSize='small'
            />
          </IconButton>
        </Grid>
        <GridItemContent item>
          <Typography>{text}</Typography>
        </GridItemContent>
      </Grid>
    </ErrorWrapper>
  );
};

interface CodesUploadProps {
  readonly isExecuting: boolean;
  readonly error?: string;
  readonly onUpload: (file: File) => void;
}

const CodesUpload = (props: CodesUploadProps) => {
  const { isExecuting, error, onUpload } = props;

  const [fileError, setFileError] = useState<Nullable<{ fileInfo: CommonFileInfo; error: string }>>(null);

  const onSuccess = (files: File[]) => {
    const [file] = files;
    onUpload(file);
  };

  const onError = (file: File, msg: string) => {
    const { name: fileName, type, size } = file;
    const fileInfo = { name: fileName, type, size };
    setFileError({ fileInfo, error: msg });
  };

  if (fileError) {
    return (
      <>
        <OfferFileInfo
          error
          fileInfo={fileError.fileInfo}
          onRemove={() => setFileError(null)}
        />
        <ErrorBlock text={fileError.error} />
      </>
    );
  } else {
    return (
      <>
        <Typography gutterBottom>
          Необходимо загрузить по&nbsp;
          <FileDownloadLink
            src={getPromotionsDownloadTemplateEndpoint()}
            accept={fileTypes.xls.mimeType}
            fileName={'загрузка_кодов'}
            component={Link}
          >
            шаблону
          </FileDownloadLink>
          &nbsp;базу кодов
        </Typography>
        <Splitter
          variant='horizontal'
          size={1}
        />
        <FileUploader
          text='Загрузить базу'
          fileTypes={[fileTypes.xls.mimeType, fileTypes.xlsx.mimeType]}
          accept={[fileTypes.xls.ext, fileTypes.xlsx.ext]}
          error={!!error}
          helperText={error}
          isLoading={isExecuting}
          onSelect={onSuccess}
          onError={onError}
        />
      </>
    );
  }
};

export default CodesUpload;
