import moment from 'moment';
import {
  ObjectTarget,
  EOfferStatus,
  ETargetType,
  EUserRole,
  CorpOfferShort,
  EDateFormat,
  ApprovalOfferRegistryRequest,
  MpUser,
} from '@/domain';
import { getUserFullName } from '../../utils';
import { getUserCheckedRoles, getUserRoleShortName } from '../../utils/roles';
import { ContentTargetComponentsType } from '../general/contentTarget/types';

export const corpOfferTargets: ObjectTarget[] = [
  { id: ETargetType.Geo, name: 'По городам' },
  { id: ETargetType.Corp, name: 'По структуре ОАО «РЖД»' },
];

export const isCorpOfferApprovalRegistryRequired = (corpOffer: CorpOfferShort): boolean => {
  return [EOfferStatus.Active, EOfferStatus.Upcoming, EOfferStatus.Paused].includes(corpOffer.status);
};

export const isCorpOfferDraftSavable = (status: EOfferStatus): boolean => {
  return [EOfferStatus.Draft, EOfferStatus.Rejected].includes(status);
};

export const getApprovalRegistryPreview = (approvalRegistry: ApprovalOfferRegistryRequest): string =>
  `${approvalRegistry.number} от ${moment(approvalRegistry.date).format(EDateFormat.DisplayDefault)}`;

export const getCorpOfferModeratorPreview = (user: MpUser, checkedRoles: EUserRole[]): string => {
  return `${getUserFullName({ user })} (${getUserCheckedRoles(user.roles, checkedRoles)
    .map(role => getUserRoleShortName(role))
    .join()})`;
};

export const corpOfferTargetByLocalitiesAttributes: ContentTargetComponentsType = {
  localities: true,
  gender: true,
  tradeUnionMembersOnly: true,
  havingChildFamilyMemberOnly: true,
  familyMemberOnly: true,
};

export const corpOfferTargetByCorpStructureAttributes: ContentTargetComponentsType = {
  gender: true,
  roads: true,
  orgUnits: true,
  tradeUnionMembersOnly: true,
  havingChildFamilyMemberOnly: true,
  familyMemberOnly: true,
};
