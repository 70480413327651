import { UserAccessPartitionProductOffers } from '../../../../domain/model/accessMatrix';
import { ProductOffer } from '../../../../domain/model/productOffer';
import { EPanelActionPosition } from '../../../types';
import { ProductLifeCycle } from '../lifecycle/types';
import {
  EProductActionType,
  ProductActionDetailsType,
  ProductActionEditType,
  ProductActions,
  ProductActionTableType,
} from '../types';

export const getProductActionName = (type: EProductActionType): string => {
  switch (type) {
    case EProductActionType.Pause:
      return 'Приостановить вариант товара';
    case EProductActionType.PauseDesk:
      return 'Приостановить карточку товара';
    case EProductActionType.Archive:
      return 'Отправить в архив вариант товара';
    case EProductActionType.ArchiveDesk:
      return 'Отправить в архив карточку товара';
    case EProductActionType.Delete:
      return 'Удалить';
    case EProductActionType.Resume:
      return 'Возобновить вариант товара';
    case EProductActionType.ResumeDesk:
      return 'Возобновить карточку товара';
    case EProductActionType.Publish:
      return 'Отправить на проверку';
    case EProductActionType.Approve:
      return 'Опубликовать';
    case EProductActionType.Reject:
      return 'Отклонить';
    case EProductActionType.UnPublish:
      return 'Отозвать вариант товара';
    case EProductActionType.UnPublishDesk:
      return 'Отозвать карточку товара';
    case EProductActionType.ChangeModerator:
      return 'Назначить модератора';
    case EProductActionType.Edit:
      return 'Редактировать';
    case EProductActionType.UnPublishAndEdit:
      return 'Отозвать и редактировать';
    case EProductActionType.Duplicate:
      return 'Копировать в черновики';
    case EProductActionType.ChangeStock:
      return 'Обновить остаток';
    case EProductActionType.ChangePrice:
      return 'Изменить цену';
    case EProductActionType.InWork:
      return 'Взять в работу';
    case EProductActionType.ChangeCategory:
      return 'Изменить категорию';
  }
};

export type GetProductActionsByPermissionsProps = UserAccessPartitionProductOffers;

// получение разрешенных действий с товаром с учетом разрешений
export const getProductActionsConfigByPermissions = (props: GetProductActionsByPermissionsProps): ProductActions => {
  const actions: ProductActions = [];

  if (props.changePrice) {
    actions.push({
      type: EProductActionType.ChangePrice,
      disabled: false,
      label: getProductActionName(EProductActionType.ChangePrice),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.changeStock) {
    actions.push({
      type: EProductActionType.ChangeStock,
      disabled: false,
      label: getProductActionName(EProductActionType.ChangeStock),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.publish) {
    actions.push({
      type: EProductActionType.Publish,
      disabled: false,
      label: getProductActionName(EProductActionType.Publish),
      position: [EPanelActionPosition.Default],
    });
  }
  if (props.unPublish) {
    actions.push({
      type: EProductActionType.UnPublish,
      disabled: false,
      label: getProductActionName(EProductActionType.UnPublish),
      position: [EPanelActionPosition.Menu],
    });

    actions.push({
      type: EProductActionType.UnPublishDesk,
      disabled: false,
      label: getProductActionName(EProductActionType.UnPublishDesk),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.resume) {
    actions.push({
      type: EProductActionType.Resume,
      disabled: false,
      label: getProductActionName(EProductActionType.Resume),
      position: [EPanelActionPosition.Menu],
    });
    actions.push({
      type: EProductActionType.ResumeDesk,
      disabled: false,
      label: getProductActionName(EProductActionType.ResumeDesk),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.approve) {
    actions.push({
      type: EProductActionType.Approve,
      disabled: false,
      label: getProductActionName(EProductActionType.Approve),
      position: [EPanelActionPosition.Default],
    });
  }
  if (props.moderate) {
    actions.push({
      type: EProductActionType.ChangeModerator,
      disabled: false,
      label: getProductActionName(EProductActionType.ChangeModerator),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.reject) {
    actions.push({
      type: EProductActionType.Reject,
      disabled: false,
      label: getProductActionName(EProductActionType.Reject),
      position: [EPanelActionPosition.Default],
    });
  }
  if (props.pause) {
    actions.push({
      type: EProductActionType.Pause,
      disabled: false,
      label: getProductActionName(EProductActionType.Pause),
      position: [EPanelActionPosition.Menu],
    });
    actions.push({
      type: EProductActionType.PauseDesk,
      disabled: false,
      label: getProductActionName(EProductActionType.PauseDesk),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.archive) {
    actions.push({
      type: EProductActionType.Archive,
      disabled: false,
      label: getProductActionName(EProductActionType.Archive),
      position: [EPanelActionPosition.Menu],
    });
    actions.push({
      type: EProductActionType.ArchiveDesk,
      disabled: false,
      label: getProductActionName(EProductActionType.ArchiveDesk),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.delete) {
    actions.push({
      type: EProductActionType.Delete,
      disabled: false,
      label: getProductActionName(EProductActionType.Delete),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.edit) {
    actions.push({
      type: EProductActionType.Edit,
      disabled: false,
      label: getProductActionName(EProductActionType.Edit),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.inWork) {
    actions.push({
      type: EProductActionType.InWork,
      disabled: false,
      label: getProductActionName(EProductActionType.InWork),
      position: [EPanelActionPosition.Default],
    });
  }
  if (props.duplicate) {
    actions.push({
      type: EProductActionType.Duplicate,
      disabled: false,
      label: getProductActionName(EProductActionType.Duplicate),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.changeCategory) {
    actions.push({
      type: EProductActionType.ChangeCategory,
      disabled: false,
      label: getProductActionName(EProductActionType.ChangeCategory),
      position: [EPanelActionPosition.Content],
    });
  }

  return actions;
};

export const removeProductAction = (actions: ProductActions, type: EProductActionType) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions.splice(index, 1);
  }
};

export const disableProductAction = (actions: ProductActions, type: EProductActionType) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      disabled: true,
    };
  }
};

export const changeProductActionPosition = (
  actions: ProductActions<any>,
  type: EProductActionType,
  position: EPanelActionPosition[]
) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      position,
    };
  }
};

export const renameProductAction = (actions: ProductActions, type: EProductActionType, label: string) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      label,
    };
  }
};

export const makeProductActionPrimary = (actions: ProductActions<any>, types: EProductActionType[]) => {
  return actions.map(action => ({
    ...action,
    primary: types.includes(action.type) ? true : action.primary,
  }));
};

// фильтрация действий для редактирования товара по всем действиям
export const filterProductEditActions = (actions: ProductActions): ProductActions<ProductActionEditType> => {
  const createActions: ProductActions<ProductActionEditType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EProductActionType.Edit:
      case EProductActionType.Publish:
      case EProductActionType.Delete:
      case EProductActionType.Duplicate:
      case EProductActionType.ChangeStock:
      case EProductActionType.ChangePrice:
      case EProductActionType.Resume:
      case EProductActionType.ResumeDesk:
      case EProductActionType.Archive:
      case EProductActionType.ArchiveDesk:
      case EProductActionType.Pause:
      case EProductActionType.PauseDesk:
      case EProductActionType.UnPublish:
      case EProductActionType.UnPublishDesk:
      case EProductActionType.ChangeCategory:
        createActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          primary: action.primary,
          position: action.position,
        });
        break;
      case EProductActionType.Reject:
      case EProductActionType.Approve:
      case EProductActionType.ChangeModerator:
      case EProductActionType.UnPublishAndEdit:
      case EProductActionType.InWork:
        break;
    }
  });

  return createActions;
};

// фильтрация действий для просмотра офферов по всем действиям
export const filterProductDetailsActions = (actions: ProductActions): ProductActions<ProductActionDetailsType> => {
  const detailsActions: ProductActions<ProductActionDetailsType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EProductActionType.Approve:
      case EProductActionType.Archive:
      case EProductActionType.ArchiveDesk:
      case EProductActionType.ChangeModerator:
      case EProductActionType.ChangePrice:
      case EProductActionType.ChangeStock:
      case EProductActionType.Delete:
      case EProductActionType.Duplicate:
      case EProductActionType.Edit:
      case EProductActionType.InWork:
      case EProductActionType.Pause:
      case EProductActionType.PauseDesk:
      case EProductActionType.Reject:
      case EProductActionType.Resume:
      case EProductActionType.ResumeDesk:
      case EProductActionType.UnPublish:
      case EProductActionType.UnPublishAndEdit:
      case EProductActionType.UnPublishDesk:
      case EProductActionType.Publish:
        detailsActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          primary: action.primary,
          position: action.position,
        });
        break;
      case EProductActionType.ChangeCategory:
        break;
    }
  });

  return detailsActions;
};

// фильтрация действий для просмотра офферов по всем действиям
export const filterProductTableActions = (actions: ProductActions): ProductActions<ProductActionTableType> => {
  const tableActions: ProductActions<ProductActionTableType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EProductActionType.ChangePrice:
      case EProductActionType.Edit:
      case EProductActionType.Delete:
      case EProductActionType.Resume:
      case EProductActionType.ResumeDesk:
      case EProductActionType.Pause:
      case EProductActionType.PauseDesk:
      case EProductActionType.Reject:
      case EProductActionType.Archive:
      case EProductActionType.ArchiveDesk:
      case EProductActionType.ChangeModerator:
      case EProductActionType.Approve:
      case EProductActionType.UnPublish:
      case EProductActionType.UnPublishDesk:
      case EProductActionType.ChangeStock:
      case EProductActionType.Publish:
      case EProductActionType.Duplicate:
        tableActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          primary: action.primary,
          position: action.position,
        });
        break;
      case EProductActionType.UnPublishAndEdit:
      case EProductActionType.InWork:
      case EProductActionType.ChangeCategory:
        break;
    }
  });

  return tableActions;
};

export const getProductTableActions = (
  lifecycle: ProductLifeCycle,
  product: ProductOffer,
  allowedActions: ProductActions<ProductActionTableType>
) => {
  const actions = lifecycle.build({
    obj: product,
    allowedActions,
  }).nextActions as ProductActions<ProductActionTableType>;

  changeProductActionPosition(actions, EProductActionType.Publish, [EPanelActionPosition.Menu]);
  changeProductActionPosition(actions, EProductActionType.Approve, [EPanelActionPosition.Menu]);
  changeProductActionPosition(actions, EProductActionType.Reject, [EPanelActionPosition.Menu]);

  return actions;
};
