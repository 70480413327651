import { ContentTargetViewClientOrgsTableCell } from '@features/general/contentTarget/components/viewClientOrgs';
import TradeOfferTableCell, { TradeOfferTableCellCommonProps } from './index';

const TradeOfferTableCellTargetClientOrgs = (props: TradeOfferTableCellCommonProps) => {
  const {
    tradeOffer: { target },
  } = props;

  return (
    <TradeOfferTableCell
      {...props}
      value={<ContentTargetViewClientOrgsTableCell value={target?.targetClientOrgs ?? null} />}
    />
  );
};

export default TradeOfferTableCellTargetClientOrgs;
