import { FCC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CorpOfferCategoriesEditContainerProps } from './container';
import { corpOfferCategoriesEditFetch, corpOfferCategoriesEditResetState } from './store/slice';

type CorpOfferCategoriesEditInitializerProps = CorpOfferCategoriesEditContainerProps;

export const CorpOfferCategoriesEditInitializer: FCC<CorpOfferCategoriesEditInitializerProps> = ({ children }) => {
  const dispatch = useDispatch();

  //загрузка основных данных
  useEffect(() => {
    const requests = [dispatch(corpOfferCategoriesEditFetch())];
    return () => {
      requests.forEach(request => request.abort());
    };
  }, [dispatch]);

  //очистка данных
  useEffect(() => {
    return () => {
      dispatch(corpOfferCategoriesEditResetState());
    };
  }, [dispatch]);

  return <>{children}</>;
};
