import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../data/store/types';
import { SportOption } from '../../../../../domain/model';
import { Address } from '../../../../../domain/model/address';
import {
  EPartnerDiscriminator,
  EPartnerOwnershipType,
  EPartnerPermission,
  EPartnerStatus,
  EPartnerType,
} from '../../../../../domain/model/enums';
import {
  Partner,
  PartnerCompanyDataDraft,
  PartnerDraft,
  PartnerIndividualDataDraft,
} from '../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { createEmptyAddress } from '../../../../utils/address';

const emptyAddress = createEmptyAddress();

interface PartnerApplicationEditFetchProps {
  readonly partnerId: UUID;
  readonly data: PartnerDraft;
  readonly permissions: Nullable<EPartnerPermission[]>;
  readonly partnerType: Nullable<EPartnerType>;
  readonly extraData: {
    readonly status: Nullable<EPartnerStatus>;
    readonly rejectionReasonType: Nullable<SportOption>;
    readonly lastStatusComment: Nullable<string>;
  };
}

export const partnerApplicationEditFetch = createAsyncThunk<
  PartnerApplicationEditFetchProps,
  { partnerId: UUID },
  AppThunkAPIConfig
>('partner/application/edit/fetch', async ({ partnerId }, { rejectWithValue, signal }) => {
  try {
    const { data: partnerData } = await Api.partner.oneDraft({ id: partnerId, signal });
    const { status, rejectionReasonType, lastStatusComment } = partnerData;

    return {
      partnerId,
      data: partnerData,
      permissions: partnerData.permissions,
      partnerType: partnerData.type,
      extraData: {
        status,
        rejectionReasonType,
        lastStatusComment,
      },
    };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

interface PartnerApplicationEditSaveProps {
  readonly partnerId: UUID;
  readonly partnerApplication: PartnerDraft;
}

export const partnerApplicationEditSave = createAsyncThunk<
  PartnerDraft,
  PartnerApplicationEditSaveProps,
  AppThunkAPIConfig
>('partner/application/edit/save', async ({ partnerId, partnerApplication }, { rejectWithValue }) => {
  try {
    const { data } = await Api.partner.unverifiedUpdate({ partnerId, data: partnerApplication });
    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const partnerApplicationEditSendToVerification = createAsyncThunk<
  PartnerDraft,
  { partnerId: UUID; partnerApplication: PartnerDraft },
  AppThunkAPIConfig
>('partner/application/edit/verification', async ({ partnerId, partnerApplication }, { rejectWithValue }) => {
  try {
    await Api.partner.unverifiedUpdate({ partnerId, data: partnerApplication });
    const { data } = await Api.partner.sendToVerification(partnerId);
    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const partnerApplicationEditApprove = createAsyncThunk<
  Partner,
  {
    partnerId: UUID;
    partnerApplication: PartnerDraft;
    permissions: Nullable<EPartnerPermission[]>;
    type: EPartnerType;
  },
  AppThunkAPIConfig
>(
  'partner/application/edit/approve',
  async ({ partnerId, partnerApplication, permissions, type }, { rejectWithValue }) => {
    try {
      await Api.partner.unverifiedUpdate({ partnerId, data: partnerApplication });
      await Api.partner.updatePermissions({ id: partnerId, permissions: permissions ?? [] });
      await Api.partner.updateType({ id: partnerId, type });
      const { data } = await Api.partner.approve(partnerId);
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface PartnerApplicationEditState {
  readonly partnerId: Nullable<UUID>;
  readonly data: Nullable<PartnerDraft>;
  readonly loadedData: Nullable<PartnerDraft>;
  readonly partnerType: Nullable<EPartnerType>;
  readonly loadedPartnerType: Nullable<EPartnerType>;
  readonly permissions: Nullable<EPartnerPermission[]>;
  readonly loadedPermissions: Nullable<EPartnerPermission[]>;
  readonly extraData: Nullable<{
    readonly status: Nullable<EPartnerStatus>;
    readonly rejectionReasonType: Nullable<SportOption>;
    readonly lastStatusComment: Nullable<string>;
  }>;
  readonly modified: boolean;
  readonly address: Nullable<Address>;
  readonly fetch: Fetchable;
  readonly save: Fetchable;
  readonly sendToVerification: Fetchable;
  readonly approve: Fetchable & {
    readonly data: Nullable<Partner>;
  };
}

type Reducer<T = undefined> = CaseReducer<PartnerApplicationEditState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PartnerApplicationEditState> {
  partnerApplicationEditSetAttribute: Reducer<{ name: keyof PartnerDraft; value: any }>;
  partnerApplicationEditSetPermissions: Reducer<Nullable<EPartnerPermission[]>>;
  partnerApplicationEditSetPartnerType: Reducer<Nullable<EPartnerType>>;
  partnerApplicationEditSetOrgType: Reducer<EPartnerOwnershipType>;
  partnerApplicationEditSetCompanyAttribute: Reducer<{ name: keyof PartnerCompanyDataDraft; value: any }>;
  partnerApplicationEditSetIndividualAttribute: Reducer<{ name: keyof PartnerIndividualDataDraft; value: any }>;
  partnerApplicationEditSetAddress: Reducer<Address>;
  partnerApplicationEditRestore: Reducer<void>;
  partnerApplicationEditStateReset: Reducer<void>;
}

const slice = createSlice<PartnerApplicationEditState, Reducers, 'edit'>({
  name: 'edit',
  initialState: {
    partnerId: null,
    fetch: {
      ...fetchableDefault,
    },
    data: null,
    partnerType: null,
    loadedPartnerType: null,
    permissions: null,
    loadedPermissions: null,
    extraData: null,
    address: emptyAddress,
    loadedData: null,
    modified: false,
    save: {
      ...fetchableDefault,
    },
    sendToVerification: {
      ...fetchableDefault,
    },
    approve: {
      ...fetchableDefault,
      data: null,
    },
  },
  reducers: {
    partnerApplicationEditStateReset: state => {
      state.partnerId = null;
      state.fetch = fetchableDefault;
      state.data = null;
      state.partnerType = null;
      state.loadedPartnerType = null;
      state.permissions = null;
      state.loadedPermissions = null;
      state.extraData = null;
      state.address = emptyAddress;
      state.loadedData = null;
      state.modified = false;
      state.save = {
        ...fetchableDefault,
      };
      state.sendToVerification = fetchableDefault;
      state.approve = {
        ...fetchableDefault,
        data: null,
      };
    },
    partnerApplicationEditSetOrgType: (state, { payload }) => {
      if (state.data && state.data.regInfo?.orgType !== payload) {
        const prevType = state.data.regInfo?.orgType;
        switch (payload) {
          case EPartnerOwnershipType.IndividualEntrepreneur: {
            state.data.inn = null;
            state.data.regInfo = {
              orgType: payload,
              discriminator: EPartnerDiscriminator.PartnerIndividualData,
              ogrnip: null,
              ogrnipCert: null,
              passportCopy: null,
            };
            break;
          }
          case EPartnerOwnershipType.StockCompany:
          case EPartnerOwnershipType.PublicJoinStockCompany:
          case EPartnerOwnershipType.PK:
          case EPartnerOwnershipType.UP:
          case EPartnerOwnershipType.FGBNU:
          case EPartnerOwnershipType.NonProfitOrganization:
          case EPartnerOwnershipType.ClosedJoinStockCompany:
          case EPartnerOwnershipType.JoinStockCompany:
          case EPartnerOwnershipType.NAO:
          case EPartnerOwnershipType.AutonomousNonProfitOrganization:
          case EPartnerOwnershipType.LimitedLiabilityCompany: {
            if (!prevType || prevType === EPartnerOwnershipType.IndividualEntrepreneur || !state.data.regInfo) {
              state.data.inn = null;
              state.data.regInfo = {
                orgType: payload,
                discriminator: EPartnerDiscriminator.PartnerCompanyData,
                companyName: null,
                charterDoc: null,
                ceoAppointmentProtocol: null,
                kpp: null,
                ogrn: null,
                ogrnCert: null,
              };
            } else {
              state.data.regInfo = {
                ...state.data.regInfo,
                orgType: payload,
              };
            }
            break;
          }
        }
      }
    },
    partnerApplicationEditSetAttribute: (state, { payload }) => {
      const { name, value } = payload;
      (state.data![name] as keyof PartnerDraft) = value;
      if (name === 'taxSystem' && !value) {
        state.data!.usnDoc = null;
      }
      state.modified = true;
    },
    partnerApplicationEditSetPermissions: (state, { payload }) => {
      state.permissions = payload;
      state.modified = true;
    },
    partnerApplicationEditSetPartnerType: (state, { payload }) => {
      state.partnerType = payload;
      state.modified = true;
    },
    partnerApplicationEditSetCompanyAttribute: (state, { payload }) => {
      const { name, value } = payload;
      if (state.data?.regInfo?.orgType && state.data.regInfo.orgType !== EPartnerOwnershipType.IndividualEntrepreneur) {
        state.data.regInfo[name] = value;
        state.modified = true;
      }
    },
    partnerApplicationEditSetIndividualAttribute: (state, { payload }) => {
      const { name, value } = payload;
      if (state.data?.regInfo?.orgType === EPartnerOwnershipType.IndividualEntrepreneur) {
        state.data.regInfo[name] = value;
        state.modified = true;
      }
    },
    partnerApplicationEditSetAddress: (state, { payload }) => {
      state.address = payload;
      state.data!.address = payload;
      state.modified = true;
    },
    partnerApplicationEditRestore: state => {
      state.data = state.loadedData;
      state.permissions = state.loadedPermissions;
      state.partnerType = state.loadedPartnerType;
      state.address = state.loadedData?.address ?? emptyAddress;
      state.modified = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(partnerApplicationEditFetch.pending, state => {
        state.fetch = fetchableFetching;
        state.data = null;
        state.permissions = null;
        state.loadedPermissions = null;
        state.partnerType = null;
        state.loadedPartnerType = null;
        state.extraData = null;
        state.loadedData = null;
        state.modified = false;
        state.save = fetchableDefault;
        state.sendToVerification = fetchableDefault;
        state.approve = {
          ...fetchableDefault,
          data: null,
        };
        state.address = emptyAddress;
      })
      .addCase(partnerApplicationEditFetch.fulfilled, (state, { payload }) => {
        const { partnerId, data, permissions, partnerType, extraData } = payload;
        state.partnerId = partnerId;
        state.fetch = fetchableFetched;
        const address = data.address;
        state.data = { ...data, address };
        state.permissions = permissions;
        state.loadedPermissions = permissions;
        state.partnerType = partnerType;
        state.loadedPartnerType = partnerType;
        state.extraData = extraData;
        state.loadedData = { ...data, address };
        state.address = address ?? emptyAddress;
      })
      .addCase(partnerApplicationEditFetch.rejected, state => {
        state.fetch = fetchableFailed;
      })
      .addCase(partnerApplicationEditSave.pending, state => {
        state.save = fetchableFetching;
      })
      .addCase(partnerApplicationEditSave.fulfilled, (state, { payload }) => {
        const { status, rejectionReasonType, lastStatusComment } = payload;

        state.save.isFetching = false;
        state.save.isFetched = true;
        state.save.isFailed = false;

        state.partnerId = payload.id;
        const address = payload.address ?? emptyAddress;
        state.data = { ...payload, address };
        state.permissions = payload.permissions;
        state.loadedPermissions = payload.permissions;
        state.partnerType = payload.type;
        state.loadedPartnerType = payload.type;
        state.extraData = {
          status,
          rejectionReasonType,
          lastStatusComment,
        };
        state.loadedData = { ...payload, address };
        state.address = address;
        state.modified = false;
      })
      .addCase(partnerApplicationEditSave.rejected, state => {
        state.save = fetchableFailed;
      })
      .addCase(partnerApplicationEditSendToVerification.pending, state => {
        state.sendToVerification = fetchableFetching;
      })
      .addCase(partnerApplicationEditSendToVerification.fulfilled, (state, { payload }) => {
        const { status, rejectionReasonType, lastStatusComment } = payload;

        state.sendToVerification = fetchableFetched;

        state.partnerId = payload.id;
        const address = payload.address ?? emptyAddress;
        state.data = { ...payload, address };
        state.permissions = payload.permissions;
        state.loadedPermissions = payload.permissions;
        state.partnerType = payload.type;
        state.loadedPartnerType = payload.type;
        state.extraData = {
          status,
          rejectionReasonType,
          lastStatusComment,
        };
        state.loadedData = { ...payload, address };
        state.address = address;
        state.modified = false;
      })
      .addCase(partnerApplicationEditSendToVerification.rejected, state => {
        state.sendToVerification = fetchableFailed;
      })
      .addCase(partnerApplicationEditApprove.pending, state => {
        state.approve = {
          ...fetchableFetching,
          data: null,
        };
      })
      .addCase(partnerApplicationEditApprove.fulfilled, (state, { payload }) => {
        const { status, rejectionReasonType, lastStatusComment } = payload;

        state.approve = {
          ...fetchableFetched,
          data: payload,
        };

        state.partnerId = payload.id;
        const address = payload.address ?? emptyAddress;
        state.data = { ...payload, address };
        state.permissions = payload.permissions;
        state.loadedPermissions = payload.permissions;
        state.partnerType = payload.type;
        state.loadedPartnerType = payload.type;
        state.extraData = {
          status,
          rejectionReasonType,
          lastStatusComment,
        };
        state.loadedData = { ...payload, address };
        state.address = address;
        state.modified = false;
      })
      .addCase(partnerApplicationEditApprove.rejected, state => {
        state.approve = {
          ...fetchableFailed,
          data: null,
        };
      });
  },
});

export const {
  partnerApplicationEditSetAttribute,
  partnerApplicationEditSetPermissions,
  partnerApplicationEditSetPartnerType,
  partnerApplicationEditSetAddress,
  partnerApplicationEditRestore,
  partnerApplicationEditSetOrgType,
  partnerApplicationEditSetCompanyAttribute,
  partnerApplicationEditSetIndividualAttribute,
  partnerApplicationEditStateReset,
} = slice.actions;

export default slice.reducer;
