import { useReportDownloads } from '@features/report/downloads/useDownloads';
import { EReportType } from '@features/report/types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { reportDownloadsReportsSelector } from '../store/selectors';
import { SportUsersReportContainer } from '../reports/sportUsers';

export const ReportDownloadsSportUsersAdapter = () => {
  const handlers = useReportDownloads();

  const { sport_users: open } = useSelector(reportDownloadsReportsSelector);

  const onClose = useCallback(() => {
    handlers.onCloseDownloadDialog(EReportType.SportUsers);
  }, [handlers.onCloseDownloadDialog]);

  return open && <SportUsersReportContainer onClose={onClose} />;
};
