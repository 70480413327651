import axios, { AxiosResponse } from 'axios';
import { OfferCategory, OfferCategoryRequest } from '../../domain/model';
import { EOfferStatus } from '../../domain/model/enums';
import { Nullable, UUID } from '../../domain/model/types';
import { createCancelToken } from './index';
import { ApiCancellable, ApiQueryDsl } from './types';
import { appendQueryDslParams, getComServicesEndpoint } from './utils';

type CreteProps = OfferCategoryRequest;

type OneProps = ApiCancellable & {
  readonly categoryId: UUID;
};

type AllProps = ApiCancellable & {
  readonly parent?: UUID;
  readonly onlyRoot?: boolean;
  readonly querydsl?: Nullable<ApiQueryDsl>;
};

type AllUsedProps = ApiCancellable & {
  readonly partnerId?: UUID;
  readonly statuses?: EOfferStatus[];
  readonly onlyLeafCategories?: boolean;
};

type UpdateProps = {
  readonly categoryId: UUID;
  readonly data: OfferCategoryRequest;
};

type DeleteProps = {
  readonly categoryId: UUID;
};

export type CategoryCommonApi = {
  readonly create: (props: CreteProps) => Promise<AxiosResponse<OfferCategory>>;
  readonly all: (props: AllProps) => Promise<AxiosResponse<OfferCategory[]>>;
  readonly allUsed: (props: AllUsedProps) => Promise<AxiosResponse<OfferCategory[]>>;
  readonly one: (props: OneProps) => Promise<AxiosResponse<OfferCategory>>;
  readonly update: (props: UpdateProps) => Promise<AxiosResponse<OfferCategory>>;
  readonly delete: (props: DeleteProps) => Promise<AxiosResponse<void>>;
};

type CategoryApi = {
  readonly corpOffer: CategoryCommonApi;
  readonly tradeOffer: CategoryCommonApi;
  readonly product: CategoryCommonApi;
  readonly bookingOffer: CategoryCommonApi;
};

const category: CategoryApi = {
  corpOffer: {
    create: data => {
      return axios.post(`${getComServicesEndpoint()}/corp-offer-categories`, data);
    },
    all: ({ parent, onlyRoot, querydsl, signal }) => {
      const params = new URLSearchParams();

      if (parent) {
        params.append('parent', parent);
      }

      if (onlyRoot !== undefined) {
        params.append('onlyRoot', onlyRoot.toString());
      }

      if (querydsl) {
        appendQueryDslParams(params, querydsl);
      }

      return axios.get(`${getComServicesEndpoint()}/corp-offer-categories`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    allUsed: ({ partnerId, onlyLeafCategories, statuses, signal }) => {
      const params = new URLSearchParams();

      if (partnerId) {
        params.append('partnerId', partnerId);
      }

      if (onlyLeafCategories !== undefined) {
        params.append('onlyLeafCategories', onlyLeafCategories.toString());
      }

      statuses?.forEach((status: EOfferStatus) => params.append('status', status));

      return axios.get(`${getComServicesEndpoint()}/corp-offers/categories`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    one: ({ categoryId, signal }) => {
      return axios.get(`${getComServicesEndpoint()}/corp-offer-categories/${categoryId}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    update: ({ categoryId, data }) => {
      return axios.put(`${getComServicesEndpoint()}/corp-offer-categories/${categoryId}`, data);
    },
    delete: ({ categoryId }) => {
      return axios.delete(`${getComServicesEndpoint()}/corp-offer-categories/${categoryId}`);
    },
  },
  tradeOffer: {
    create: data => {
      return axios.post(`${getComServicesEndpoint()}/trade-offer-categories`, data);
    },
    all: ({ parent, onlyRoot, querydsl, signal }) => {
      const params = new URLSearchParams();

      if (parent) {
        params.append('parent', parent);
      }

      if (onlyRoot !== undefined) {
        params.append('onlyRoot', onlyRoot.toString());
      }

      if (querydsl) {
        appendQueryDslParams(params, querydsl);
      }

      return axios.get(`${getComServicesEndpoint()}/trade-offer-categories`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    allUsed: ({ partnerId, statuses, onlyLeafCategories, signal }) => {
      const params = new URLSearchParams();

      if (partnerId) {
        params.append('partnerId', partnerId);
      }

      if (onlyLeafCategories !== undefined) {
        params.append('onlyLeafCategories', onlyLeafCategories.toString());
      }

      statuses?.forEach((status: EOfferStatus) => params.append('status', status));

      return axios.get(`${getComServicesEndpoint()}/trade-offers/categories`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    one: ({ categoryId, signal }) => {
      return axios.get(`${getComServicesEndpoint()}/trade-offer-categories/${categoryId}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    update: ({ categoryId, data }) => {
      return axios.put(`${getComServicesEndpoint()}/trade-offer-categories/${categoryId}`, data);
    },
    delete: ({ categoryId }) => {
      return axios.delete(`${getComServicesEndpoint()}/trade-offer-categories/${categoryId}`);
    },
  },
  product: {
    create: data => {
      return axios.post(`${getComServicesEndpoint()}/product-categories`, data);
    },
    all: ({ parent, onlyRoot, querydsl, signal }) => {
      const params = new URLSearchParams();

      if (parent) {
        params.append('parent', parent);
      }

      if (onlyRoot !== undefined) {
        params.append('onlyRoot', onlyRoot.toString());
      }

      if (querydsl) {
        appendQueryDslParams(params, querydsl);
      }

      return axios.get(`${getComServicesEndpoint()}/product-categories`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    allUsed: ({ partnerId, statuses, onlyLeafCategories, signal }) => {
      const params = new URLSearchParams();

      if (partnerId) {
        params.append('partnerId', partnerId);
      }

      if (onlyLeafCategories !== undefined) {
        params.append('onlyLeafCategories', onlyLeafCategories.toString());
      }

      statuses?.forEach((status: EOfferStatus) => params.append('status', status));

      return axios.get(`${getComServicesEndpoint()}/product-offers/categories`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    one: ({ categoryId, signal }) => {
      return axios.get(`${getComServicesEndpoint()}/product-categories/${categoryId}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    update: ({ categoryId, data }) => {
      return axios.put(`${getComServicesEndpoint()}/product-categories/${categoryId}`, data);
    },
    delete: ({ categoryId }) => {
      return axios.delete(`${getComServicesEndpoint()}/product-categories/${categoryId}`);
    },
  },
  bookingOffer: {
    create: data => {
      return axios.post(`${getComServicesEndpoint()}/booking-offer-categories`, data);
    },
    all: ({ parent, onlyRoot, querydsl, signal }) => {
      const params = new URLSearchParams();

      if (parent) {
        params.append('parent', parent);
      }

      if (onlyRoot !== undefined) {
        params.append('onlyRoot', onlyRoot.toString());
      }

      if (querydsl) {
        appendQueryDslParams(params, querydsl);
      }

      return axios.get(`${getComServicesEndpoint()}/booking-offer-categories`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    allUsed: ({ partnerId, statuses, onlyLeafCategories, signal }) => {
      const params = new URLSearchParams();

      if (partnerId) {
        params.append('partnerId', partnerId);
      }

      if (onlyLeafCategories !== undefined) {
        params.append('onlyLeafCategories', onlyLeafCategories.toString());
      }

      statuses?.forEach((status: EOfferStatus) => params.append('status', status));

      return axios.get(`${getComServicesEndpoint()}/booking-offer-categories`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    one: ({ categoryId, signal }) => {
      return axios.get(`${getComServicesEndpoint()}/booking-offer-categories/${categoryId}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    update: ({ categoryId, data }) => {
      return axios.put(`${getComServicesEndpoint()}/booking-offer-categories/${categoryId}`, data);
    },
    delete: ({ categoryId }) => {
      return axios.delete(`${getComServicesEndpoint()}/booking-offer-categories/${categoryId}`);
    },
  },
};

export default category;
