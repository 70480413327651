import { useState } from 'react';
import DataTableCellCostEdit from '../../../../../components/common/table/cell/cost';
import { formatCost } from '../../../../../utils';

interface BookingOrderItemTableCellCostProps {
  readonly value: Nullable<number>;
  readonly onChange: Nullable<(value: Nullable<number>) => void>;
}

const BookingOrderItemTableCellCost = (props: BookingOrderItemTableCellCostProps) => {
  const { value, onChange } = props;
  const [editMode, setEditMode] = useState(false);

  if (!onChange) {
    return <>{formatCost(value)}</>;
  }

  return (
    <DataTableCellCostEdit
      editMode={editMode}
      value={value}
      max={999999}
      label={(min, max) => `${formatCost(min)} - ${formatCost(max)}`}
      onChange={onChange}
      hideEditIcon={editMode}
      isFetching={false}
      onChangeEditMode={(value, event) => {
        event.stopPropagation();
        setEditMode(value);
      }}
    />
  );
};

export default BookingOrderItemTableCellCost;
