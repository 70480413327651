import { service as common } from './common';
import { service as sportsFest } from './sportsFest';
import { service as tournament } from './tournament';
import { service as tournamentIndividual } from './tournamentIndividual';
import { service as users } from './users';
import { service as activity } from './activity';

const eventServices = {
  common,
  tournament,
  tournamentIndividual,
  sportsFest,
  activity,
  users,
};

export default eventServices;
