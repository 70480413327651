import { MutableDataTableColumns, TableColumn } from '../components/common/table';

type CalculateTableMetadataChangesResult<C> = {
  readonly removedColumns: C[];
  readonly addedColumns: C[];
};

export const calculateTableMetadataChanges = <C extends string>(
  prevMetadataColumns: MutableDataTableColumns<C>,
  currentMetadataColumns: MutableDataTableColumns<C>
): CalculateTableMetadataChangesResult<C> => {
  const currentColumns = Object.keys(currentMetadataColumns);
  const prevColumns = Object.keys(prevMetadataColumns);

  const removedColumns = prevColumns.reduce<C[]>((prev, current) => {
    if (!currentColumns.includes(current)) {
      return [...prev, current] as C[];
    } else {
      return prev;
    }
  }, []);

  Object.entries(currentMetadataColumns).forEach(item => {
    const key: C = item[0] as C;
    const value: TableColumn = item[1] as TableColumn;
    if (!!prevMetadataColumns[key] && !prevMetadataColumns[key]?.hidden && value.hidden) {
      removedColumns.push(key);
    }
  });

  const addedColumns: C[] = [];
  Object.entries(currentMetadataColumns).forEach(item => {
    const key: C = item[0] as C;
    const value: TableColumn = item[1] as TableColumn;
    if ((!prevMetadataColumns[key] || prevMetadataColumns[key]?.hidden) && !value.hidden) {
      addedColumns.push(key);
    }
  });

  return {
    removedColumns,
    addedColumns,
  };
};

export const getTableFilterItemsByTableColumns = <C extends string, F extends string>(
  filters: Record<C, F[]>,
  columns: C[]
): F[] => {
  const filterItems: F[] = [];
  columns.forEach(column => {
    if (filters[column]?.length) {
      filterItems.push(...filters[column]);
    }
  });
  return filterItems;
};
