import styled from '@emotion/styled';
import { Chip, ChipProps, css, Skeleton, Typography } from '@mui/material';
import { SkeletonProps } from '@mui/material/Skeleton/Skeleton';
import { viewConfig } from '@theme/viewConfig';
import { HTMLAttributes } from 'react';
import { EEntityPreviewMode } from '../../../../types';

export const Footer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Price = styled(Typography)(({ theme }) => css``);

export const AspTag = styled(({ children, ...props }: ChipProps) => <Chip {...props}>{children}</Chip>)(
  ({ theme }) => css`
    border-radius: ${Number(theme.shape.borderRadius) * 2.5}px;
    border: 1px solid #f4f5f6;

    padding: ${theme.spacing(0.25)};

    .MuiChip-icon {
      margin-left: 0;
      margin-right: 0;
    }

    .MuiChip-label {
      padding-left: ${theme.spacing(0.5)};
      padding-right: ${theme.spacing(0.5)};
    }
  `
);

export const AspLabel = styled(Typography)(
  () => css`
    font-weight: 500;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `
);

type HeaderProps = ChipProps & {
  readonly mode: EEntityPreviewMode;
};

export const Header = styled(({ mode, children, ...props }: HeaderProps) => <Chip {...props}>{children}</Chip>)(
  ({ mode, theme }) => css`
    background-color: #ff8d58;
    border-radius: ${Number(theme.shape.borderRadius) * (mode === EEntityPreviewMode.Mobile ? 2 : 1.5)}px;
  `
);

type HeaderSkeletonProps = SkeletonProps & {
  readonly mode: EEntityPreviewMode;
};
export const HeaderSkeleton = styled(({ mode, ...props }: HeaderSkeletonProps) => <Skeleton {...props} />)(
  ({ theme, mode }) => css`
    transform: none;
    border-radius: ${Number(theme.shape.borderRadius) * (mode === EEntityPreviewMode.Mobile ? 1.5 : 2)}px;
    background-color: #ff8d58;
  `
);

type TradeOfferPreviewWrapperProps = HTMLAttributes<HTMLDivElement> & {
  readonly mode: EEntityPreviewMode;
};

export const DescriptionSkeletonWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    gap: ${theme.spacing(0.5)};
  `
);

export const TradeOfferPreviewWrapper = styled(({ mode, children, ...props }: TradeOfferPreviewWrapperProps) => (
  <div {...props}>{children}</div>
))(
  ({ mode }) => css`
    width: ${mode === EEntityPreviewMode.Mobile
      ? viewConfig.tradeOffer.preview.mobileWidth
      : viewConfig.tradeOffer.preview.width};
  `
);
