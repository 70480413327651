export const viewConfig = {
  viewport: 1200,
  menuPanelWidth: '20rem',
  table: {
    headRowSize: '3rem',
    paginationSize: '3.5rem',
  },
  sidePanel: {
    contentWidth: '32rem',
    titleSize: '4.5rem',
    titleWithTabsSize: '7rem',
  },
  stepper: {
    width: '20rem',
    contentHeader: {
      height: '7rem',
    },
  },
  event: {
    preview: {
      width: '25.25rem',
    },
  },
  coverImage: {
    height: '14.25rem',
  },
  tradeOffer: {
    preview: {
      detailsWidth: '30.375rem',
      listItemWidth: '18.5rem',
      width: '18.5rem',
      mobileWidth: '10.125rem',
    },
  },
  corpOffer: {
    preview: {
      detailsWidth: '30.375rem',
      listItemWidth: '18.5rem',
      width: '18.5rem',
      mobileWidth: '9.75rem',
    },
    contentHeader: {
      height: '9rem',
    },
  },
  bookingOffer: {
    sidePanel: {
      width: '23.5rem',
    },
    preview: {
      width: '18.5rem',
      mobileWidth: '13.5rem',
    },
  },
  banner: {
    preview: {
      width: '30rem',
      mobileWidth: '25rem',
    },
  },
  support: {
    info: {
      width: '30.625rem',
    },
  },
  profile: {
    notifications: {
      contentWidth: '22rem',
    },
  },
  cms: {
    preview: {
      detailsWidth: '25.875rem',
      compilation: {
        desktop: {
          contentHeight: '5.5rem',
          imageHeight: '3.25rem',
        },
        mobile: {
          imageHeight: '4.5rem',
          imageWidth: '6.75rem',
        },
      },
    },
  },
  clientOrg: {
    preview: {
      width: '12rem',
    },
  },
};
