import { useDispatch } from 'react-redux';
import { EProductOrderActionType } from '../types';
import ProductOrderActionsDialogsAdapter from './adapters';
import ProductOrderActionsOptimizerAdapter from './adapters/optimizer';
import ProductOrderActionsContext, { ProductOrderActionsContextType } from './context';
import {
  getIsProductOrderAnyActionByTypeExecutedSelector,
  getProductOrderActionsOfferIsProcessingSelector,
  isProductOrdersAnyChangedSelector,
} from './store/selectors';
import {
  productOrderActionsCancel,
  productOrderActionsChangeDialogState,
  productOrderActionsConfirm,
  productOrderActionsGive,
  productOrderActionsPartiallyReturn,
  productOrderActionsPay,
  productOrderActionsRenew,
  productOrderActionsReturn,
  productOrderActionsSend,
} from './store/slice';
import { showProductOrderActionNotification } from './utils';

type ProductOrderActionsProviderProps = {
  readonly children: JSX.Element;
};

type ProductOrderActionsProviderType = (props: ProductOrderActionsProviderProps) => JSX.Element;

const ProductOrderActionsProvider: ProductOrderActionsProviderType = ({ children }) => {
  const dispatch = useDispatch();

  const onChangeDialogState: ProductOrderActionsContextType['onChangeDialogState'] = (name, data) => {
    dispatch(productOrderActionsChangeDialogState({ name, data }));
  };

  const onCancel: ProductOrderActionsContextType['onCancel'] = async (order, reasonId, comment) => {
    const response = await dispatch(
      productOrderActionsCancel({
        id: order.id,
        comment,
        reasonId,
      })
    ).unwrap();

    if (response) {
      showProductOrderActionNotification(response, EProductOrderActionType.Cancel);
    }
    return response;
  };

  const onPartiallyReturn: ProductOrderActionsContextType['onPartiallyReturn'] = async order => {
    const response = await dispatch(
      productOrderActionsPartiallyReturn({
        id: order.id,
      })
    ).unwrap();

    if (response) {
      showProductOrderActionNotification(response, EProductOrderActionType.PartiallyReturn);
    }
  };

  const onReturn: ProductOrderActionsContextType['onReturn'] = async order => {
    const response = await dispatch(
      productOrderActionsReturn({
        id: order.id,
      })
    ).unwrap();

    if (response) {
      showProductOrderActionNotification(response, EProductOrderActionType.Return);
    }
  };

  const onConfirm: ProductOrderActionsContextType['onConfirm'] = async order => {
    const response = await dispatch(
      productOrderActionsConfirm({
        id: order.id,
      })
    ).unwrap();

    if (response) {
      showProductOrderActionNotification(response, EProductOrderActionType.Confirm);
    }
  };

  const onRenew: ProductOrderActionsContextType['onRenew'] = async order => {
    const response = await dispatch(
      productOrderActionsRenew({
        id: order.id,
      })
    ).unwrap();

    if (response) {
      showProductOrderActionNotification(response, EProductOrderActionType.Renew);
    }
  };

  const onGive: ProductOrderActionsContextType['onGive'] = async order => {
    const response = await dispatch(
      productOrderActionsGive({
        id: order.id,
      })
    ).unwrap();

    if (response) {
      showProductOrderActionNotification(response, EProductOrderActionType.Give);
    }
  };

  const onSend: ProductOrderActionsContextType['onSend'] = async order => {
    const response = await dispatch(
      productOrderActionsSend({
        id: order.id,
      })
    ).unwrap();

    if (response) {
      showProductOrderActionNotification(response, EProductOrderActionType.Send);
    }
  };

  const onPay: ProductOrderActionsContextType['onPay'] = async order => {
    const response = await dispatch(
      productOrderActionsPay({
        id: order.id,
      })
    ).unwrap();

    if (response) {
      showProductOrderActionNotification(response, EProductOrderActionType.Pay);
    }
  };

  const onTryCancel: ProductOrderActionsContextType['onTryCancel'] = data => {
    onChangeDialogState('cancel', data);
  };

  const onTryConfirm: ProductOrderActionsContextType['onTryConfirm'] = data => {
    onChangeDialogState('confirm', data);
  };

  const value: ProductOrderActionsContextType = {
    onChangeDialogState,

    onCancel,
    onGive,
    onRenew,
    onConfirm,
    onPartiallyReturn,
    onReturn,
    onSend,
    onPay,
    onTryCancel,
    onTryConfirm,

    utils: {
      selectors: {
        getIsProductOrderExecutedActionsSelector: getIsProductOrderAnyActionByTypeExecutedSelector,
        getIsProductOrderProcessingSelector: getProductOrderActionsOfferIsProcessingSelector,
        isProductOrdersAnyChangedSelector,
      },
    },
  };

  return (
    <ProductOrderActionsContext.Provider value={value}>
      {children}
      <ProductOrderActionsDialogsAdapter />
      <ProductOrderActionsOptimizerAdapter />
    </ProductOrderActionsContext.Provider>
  );
};

export default ProductOrderActionsProvider;
