import { AdCampaignCreate, EAdCampaignType, Nullable } from '@/domain';
import moment from 'moment/moment';
import { EValidationMessages, EValidationType, ValidationItemResult, ValidationRules } from '../../../utils/validation';

const arrayIsNotEmptyValidation = (value: Nullable<any[]>, message?: string): Nullable<ValidationItemResult> => {
  if (!value?.length) {
    return {
      type: EValidationType.Error,
      hasError: true,
      message: message || EValidationMessages.Empty,
    };
  }
  return null;
};

//правила технической валидации
const adCampaignTechnicalValidationRules: ValidationRules<AdCampaignCreate> = {};

//правила бизнес валидации
export const adCampaignBusinessValidationRules: ValidationRules<AdCampaignCreate> = {
  ...adCampaignTechnicalValidationRules,
};

export const adCampaignValidationRulesSave: ValidationRules<AdCampaignCreate> = {
  name: {
    required: true,
    requiredMessage: 'Придумайте название кампании',
  },
  type: { required: true, requiredMessage: 'Выберите тип рекламной кампании' },
  partner: { required: true, requiredMessage: 'Выберите партнёра' },
  offerType: {
    required: true,
    requiredMessage: 'Выберите тип предложения',
  },
  startDate: {
    required: true,
    requiredMessage: 'Укажите начало кампании',
    validator: (object: AdCampaignCreate, value: AdCampaignCreate['startDate']) => {
      if (!value) return null;
      if (moment().isAfter(value, 'day')) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Дата начала меньше текущей даты',
        };
      } else {
        return null;
      }
    },
  },
  endDate: {
    required: true,
    requiredMessage: 'Укажите окончание кампании',
    validator: (object: AdCampaignCreate, value: AdCampaignCreate['endDate']) => {
      if (!value || !object.startDate) return null;
      if (moment(value).isBefore(object.startDate)) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Дата окончания меньше даты начала',
        };
      } else {
        return null;
      }
    },
  },

  budget: { required: true, requiredMessage: 'Укажите бюджет кампании в рублях' },
  offers: {
    required: true,
    requiredMessage: 'Выберите до 4 записей',
    validator: (object: AdCampaignCreate, value: AdCampaignCreate['offers']) =>
      arrayIsNotEmptyValidation(value, 'Выберите до 4 записей'),
  },
  keywords: {
    required: (object: AdCampaignCreate) => object.type === EAdCampaignType.Search,
    requiredMessage: 'Введите ключевые слова',
    validator: (object: AdCampaignCreate, value: AdCampaignCreate['keywords']) =>
      object.type === EAdCampaignType.Search ? arrayIsNotEmptyValidation(value, 'Введите ключевые слова') : null,
  },
};
