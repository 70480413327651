import { AdCampaignShort } from '@/domain';
import { ESortDirection } from '@/domain/model/enums';
import TableActionsContainer from '@components/common/table/actions/container';
import { DataTableLoader } from '@components/common/table/loader';
import AdCampaignTableCellTypeAdapter from '@features/adCampaign/table/adapters/cellAdType';
import AdCampaignTableCellOfferTypeAdapter from '@features/adCampaign/table/adapters/cellOfferType';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../components/common/table';
import { useAdCampaignActionsSelectors } from '../actions/useActions';
import {
  AdCampaignTableCellBudget,
  AdCampaignTableCellName,
  AdCampaignTableCellOfferCount,
  AdCampaignTableCellOfferType,
  AdCampaignTableCellPartner,
  AdCampaignTableCellPeriod,
  AdCampaignTableCellShowCount,
  AdCampaignTableCellType,
  AdCampaignTableCellViewCount,
} from '../components/tableCell';
import { AdCampaignActions, AdCampaignActionTableType, EAdCampaignTableColumn } from '../types';
import AdCampaignsTableCellAdapter from './adapters/cell';
import { adCampaignIsFetchingSelector } from './store/selectors';
import { AdCampaignActionCall } from './utils';

interface AdCampaignTableProps {
  readonly metadata: DataTableMetadata<EAdCampaignTableColumn>;
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly adCampaigns: AdCampaignShort[];
  readonly getActions: (adCampaign: AdCampaignShort) => AdCampaignActions<AdCampaignActionTableType>;
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onAdCampaignClick?: (adCampaign: AdCampaignShort) => void;
  readonly onChangeMetadata?: (metadata: DataTableMetadata<EAdCampaignTableColumn>) => void;
  readonly onAdCampaignAction: (call: AdCampaignActionCall) => void;
}

export const AdCampaignTable = (props: AdCampaignTableProps) => {
  const {
    adCampaigns,
    sort,
    onAdCampaignClick,
    metadata,
    onChangeMetadata,
    onRequestSort,
    getActions,
    onAdCampaignAction,
  } = props;

  const { getIsAdCampaignProcessingSelector } = useAdCampaignActionsSelectors();

  const rows: DataTableRow<AdCampaignShort, EAdCampaignTableColumn>[] = adCampaigns?.map((adCampaign, index) => {
    return {
      [EAdCampaignTableColumn.Name]: (
        <AdCampaignsTableCellAdapter
          index={index}
          component={AdCampaignTableCellName}
        />
      ),
      [EAdCampaignTableColumn.Period]: (
        <AdCampaignsTableCellAdapter
          index={index}
          component={AdCampaignTableCellPeriod}
        />
      ),
      [EAdCampaignTableColumn.Partner]: (
        <AdCampaignsTableCellAdapter
          index={index}
          component={AdCampaignTableCellPartner}
        />
      ),
      [EAdCampaignTableColumn.Type]: (
        <AdCampaignTableCellTypeAdapter
          index={index}
          component={AdCampaignTableCellType}
        />
      ),
      [EAdCampaignTableColumn.OfferType]: (
        <AdCampaignTableCellOfferTypeAdapter
          index={index}
          component={AdCampaignTableCellOfferType}
        />
      ),
      [EAdCampaignTableColumn.OfferCount]: (
        <AdCampaignsTableCellAdapter
          index={index}
          component={AdCampaignTableCellOfferCount}
        />
      ),
      [EAdCampaignTableColumn.Budget]: (
        <AdCampaignsTableCellAdapter
          index={index}
          component={AdCampaignTableCellBudget}
        />
      ),
      [EAdCampaignTableColumn.ShowCount]: (
        <AdCampaignsTableCellAdapter
          index={index}
          component={AdCampaignTableCellShowCount}
        />
      ),
      [EAdCampaignTableColumn.ViewCount]: (
        <AdCampaignsTableCellAdapter
          index={index}
          component={AdCampaignTableCellViewCount}
        />
      ),
      data: adCampaign,
    };
  });

  return (
    <DataTable<AdCampaignShort, EAdCampaignTableColumn>
      width='auto'
      overflowX='inherit'
      metadata={metadata}
      onChangeMetadata={onChangeMetadata}
      rowActions
      rows={rows}
      sort={{ column: sort.column, direction: sort.direction }}
      loader={<DataTableLoader selector={adCampaignIsFetchingSelector} />}
      onRowClick={(event, cell, { data }) => onAdCampaignClick?.(data)}
      onSort={(event, column, direction) => onRequestSort(column, direction)}
      getRowActions={adCampaign => (
        <TableActionsContainer<AdCampaignShort, AdCampaignActionTableType>
          entityLink={adCampaign}
          actions={getActions(adCampaign)}
          onExecute={(action, adCampaign) => onAdCampaignAction({ action, adCampaign })}
          isFetchingSelector={getIsAdCampaignProcessingSelector(adCampaign.id)}
        />
      )}
    />
  );
};
