import { useState } from 'react';
import { Nullable } from '../../../../../domain/model/types';
import { MPInputButton } from '../../../../theme/ui-kit/input/complex';

interface CodesGenerateProps {
  readonly isExecuting: boolean;
  readonly error?: string;
  readonly onGenerate: Nullable<(count: number) => void>;
}

const CodesGenerate = (props: CodesGenerateProps) => {
  const { error, isExecuting, onGenerate } = props;

  const [count, setCount] = useState<Nullable<number>>(null);

  const onGenerateClick = () => {
    if (onGenerate && count !== null) {
      onGenerate(count);
    }
  };

  return (
    <MPInputButton
      buttonProps={{
        disabled: isExecuting || !count,
      }}
      buttonText='Сгенерировать'
      disabled={isExecuting}
      error={!!error}
      helperText={error}
      label='Количество кодов'
      isFetching={isExecuting}
      naturalNumberInput
      value={count}
      onChange={(event, newValue) => setCount(newValue)}
      onExecute={onGenerateClick}
    />
  );
};

export default CodesGenerate;
