import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../data/store/types';
import { AddressPosition } from '../../../../../domain/model/address';
import { Team, TeamMember } from '../../../../../domain/model/team';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { SportUserProfile } from '../../../../../domain/model/user';
import { convertSportUserProfileInSportUserProfileShort } from '../../../event/utils/common';
import { teamLogoSelect } from '../../logos/store/slice';

const emptyTeamPosition: AddressPosition = {
  lat: 0,
  lon: 0,
};

export const teamEditFetch = createAsyncThunk<Team, { id: UUID }, AppThunkAPIConfig>(
  'team/edit/fetch',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await Api.team.one({ id });

      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export const teamEditSave = createAsyncThunk<Team, { team: Team }, AppThunkAPIConfig>(
  'team/edit/save',
  async ({ team }, { rejectWithValue }) => {
    try {
      const { data } = await Api.team.save(team);
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface TeamEditState {
  readonly edit: Fetchable & {
    readonly data: Nullable<Team>;
    readonly modified: boolean;
    readonly validationErrors: { [key: string]: string } | {};
  };
  readonly save: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<TeamEditState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<TeamEditState> {
  teamEditSetAttribute: Reducer<{ name: keyof Team; value: any }>;
  teamEditSetValidationErrors: Reducer<{ errors: { [field: string]: any } }>;
  teamsEditSaveReset: Reducer;
  teamCreateSetCapitan: Reducer<Nullable<SportUserProfile>>;
}

const slice = createSlice<TeamEditState, Reducers, 'team/edit'>({
  name: 'team/edit',
  initialState: {
    edit: {
      ...fetchableDefault,
      data: null,
      modified: false,
      validationErrors: {},
    },
    save: {
      ...fetchableDefault,
    },
  },
  reducers: {
    teamEditSetAttribute: (state, { payload }) => {
      const { name, value } = payload;
      if (state.edit.data) {
        (state.edit.data[name] as keyof Team) = value;
        state.edit.modified = true;

        if (name === 'locality') {
          const position = state.edit.data?.locality?.position;
          if (position) {
            state.edit.data.location = {
              lat: position.lat,
              lon: position.lon,
            };
          } else {
            state.edit.data.location = emptyTeamPosition;
          }
        }
      }
    },
    teamsEditSaveReset: state => {
      state.save.isFetching = false;
      state.save.isFetched = false;
      state.save.isFailed = false;
    },
    teamEditSetValidationErrors: (state, { payload }) => {
      const { errors } = payload;
      state.edit.validationErrors = errors;
    },
    teamCreateSetCapitan: (state, { payload }) => {
      if (payload && state.edit.data) {
        state.edit.data.captain = {
          id: uuid(),
          userProfile: convertSportUserProfileInSportUserProfileShort(payload),
        } as TeamMember;
        state.edit.modified = true;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(teamEditFetch.pending, state => {
        state.edit.isFetching = true;
        state.edit.isFetched = false;
        state.edit.isFailed = false;

        state.edit.data = null;
        state.edit.modified = false;
        state.edit.validationErrors = {};
      })
      .addCase(teamEditFetch.fulfilled, (state, { payload }) => {
        state.edit.isFetching = false;
        state.edit.isFetched = true;
        state.edit.isFailed = false;

        state.edit.data = payload;
        state.edit.modified = false;
        state.edit.validationErrors = {};
      })
      .addCase(teamEditFetch.rejected, state => {
        state.edit.isFetching = false;
        state.edit.isFetched = false;
        state.edit.isFailed = true;

        state.edit.data = null;
        state.edit.modified = false;
        state.edit.validationErrors = {};
      })
      .addCase(teamEditSave.pending, state => {
        state.save = fetchableFetching;
      })
      .addCase(teamEditSave.fulfilled, state => {
        state.save = fetchableFetched;
      })
      .addCase(teamEditSave.rejected, state => {
        state.save = fetchableFailed;
      })
      .addCase(teamLogoSelect, (state, { payload }) => {
        const { selected } = payload;
        if (state.edit.data) {
          state.edit.data.logo = selected;
          state.edit.modified = true;
        }
      });
  },
});

export const { teamEditSetAttribute, teamEditSetValidationErrors, teamsEditSaveReset, teamCreateSetCapitan } =
  slice.actions;

export default slice.reducer;
