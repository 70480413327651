import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  ClientOrgCreateFaviconFieldAdapter,
  ClientOrgCreateFieldAdapter,
  ClientOrgCreateLogoFieldAdapter,
  FormItemAdapter,
} from '../adapters';
import ClientOrgCreateFieldCode from '../fields/code';
import ClientOrgCreateFieldColorBrand from '../fields/colorBrand';
import ClientOrgCreateFieldColorPrimary from '../fields/colorPrimary';
import ClientOrgCreateFieldDomain from '../fields/domain';
import ClientOrgCreateFieldName from '../fields/name';

const ClientOrgCreateStepGeneral = () => {
  return (
    <Grid
      container
      spacing={4}
    >
      <Grid
        item
        xs={12}
      >
        <Typography variant={'h3'}>Настройка Витрины</Typography>
      </Grid>

      <FormItemAdapter title='Логотип'>
        <ClientOrgCreateFieldAdapter
          name='logo'
          component={ClientOrgCreateLogoFieldAdapter}
        />
      </FormItemAdapter>

      <FormItemAdapter title='Фавикон'>
        <ClientOrgCreateFieldAdapter
          name='favicon'
          component={ClientOrgCreateFaviconFieldAdapter}
        />
      </FormItemAdapter>

      <FormItemAdapter title='Название компании'>
        <ClientOrgCreateFieldAdapter
          name='name'
          component={ClientOrgCreateFieldName}
        />
      </FormItemAdapter>

      <FormItemAdapter title='Код компании в системе Витрина'>
        <ClientOrgCreateFieldAdapter
          name='code'
          component={ClientOrgCreateFieldCode}
        />
      </FormItemAdapter>

      <FormItemAdapter title='Цвета'>
        <ClientOrgCreateFieldAdapter
          name='colorBrand'
          component={ClientOrgCreateFieldColorBrand}
        />
        <ClientOrgCreateFieldAdapter
          name='colorPrimary'
          component={ClientOrgCreateFieldColorPrimary}
        />
      </FormItemAdapter>

      <FormItemAdapter title='Доменное имя'>
        <ClientOrgCreateFieldAdapter
          name='domain'
          component={ClientOrgCreateFieldDomain}
        />
      </FormItemAdapter>
    </Grid>
  );
};

export default ClientOrgCreateStepGeneral;
