import { ReactNode, useMemo } from 'react';
import { SearchQueryStat } from '../../../../../../domain/model/analytics';
import DataTableCellTextFormat from '../../../../../components/common/table/cell/textFormat';

export interface AnalyticsQueriesTableCellCommonProps {
  readonly query: SearchQueryStat;
  readonly isDisabled?: boolean;
  readonly isTextAccent?: boolean;
}

interface AnalyticsQueriesTableCellProps extends AnalyticsQueriesTableCellCommonProps {
  readonly value: ReactNode;
}

const AnalyticsQueriesTableCell = ({ value, isDisabled, isTextAccent }: AnalyticsQueriesTableCellProps) => {
  return useMemo(
    () => (
      <DataTableCellTextFormat
        disabled={isDisabled}
        bold={isTextAccent}
      >
        {value}
      </DataTableCellTextFormat>
    ),
    [isDisabled, isTextAccent, value]
  );
};

export default AnalyticsQueriesTableCell;
