import { ServerErrorResponse } from '@/data/network/types';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { Pageable, TradeOffer } from '@/domain';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { PaginationSize } from '../../../../types';
import { EOfferTableNotificationType } from '../../../general/offer/enums';
import offerServices from '../../../general/offer/services';
import { AllProps } from '../../../general/offer/services/tradeOffers';
import { EOfferActionType, EOfferTableTab } from '../../../general/offer/types';
import { OfferTableTabsCounter } from '../../../general/offer/utils/table';
import { TradeOfferTableFilterValues } from '../../filterUtils';
import { ETradeOfferTableColumn } from '../../types';
import {
  tradeOfferActionsApprove,
  tradeOfferActionsPublish,
  tradeOfferActionsReject,
  tradeOfferActionsResume,
  tradeOfferActionsSave,
} from '../../actions/store/slice';

const defaultSort = `${ETradeOfferTableColumn.Name},asc`;

export type TradeOffersFetchProps = Omit<AllProps, 'signal'>;

export type TradeOffersCountsFetchProps = TradeOffersFetchProps & {
  readonly tabs: EOfferTableTab[];
};

export const tradeOffersFetch = createAsyncThunk<Pageable<TradeOffer>, TradeOffersFetchProps, AppThunkAPIConfig>(
  'tradeOffer/list/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await offerServices.trade.all({ ...props, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tradeOffersCountFetch = createAsyncThunk<number, TradeOffersFetchProps, AppThunkAPIConfig>(
  'tradeOffer/list/count/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await offerServices.trade.count({ ...props, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tradeOffersUnwatchedFetch = createAsyncThunk<UUID[], TradeOffersFetchProps, AppThunkAPIConfig>(
  'tradeOffer/list/unwatched/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      const data = await offerServices.trade.unwatched({ ...props, signal });
      return data.map(({ id }) => id);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tradeOffersCountsFetch = createAsyncThunk<
  OfferTableTabsCounter,
  TradeOffersCountsFetchProps,
  AppThunkAPIConfig
>('tradeOffer/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await offerServices.trade.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      errors.map(e => {
        console.error(e);
      });
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tradeOffersCountsUnwatchedFetch = createAsyncThunk<
  OfferTableTabsCounter,
  TradeOffersCountsFetchProps,
  AppThunkAPIConfig
>('tradeOffer/list/counts/unwatched/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await offerServices.trade.countsByTabs({ ...props, viewed: false, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface TradeOfferListState extends Fetchable, Pageable<TradeOffer> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
    readonly partnerId: Nullable<UUID>;
  };
  readonly allTradeOffers: Fetchable & {
    readonly count: Nullable<number>;
    readonly needRefreshWatcher: number;
  };
  readonly tab: Nullable<EOfferTableTab>;
  readonly filter: TradeOfferTableFilterValues;
  readonly notifierInfo: Nullable<{
    type: EOfferTableNotificationType;
    tradeOffer: TradeOffer;
  }>;
  readonly actions: (Fetchable & {
    id: UUID;
    type: EOfferActionType;
    error: Nullable<ServerErrorResponse>;
  })[];
  readonly reject: Fetchable;
  readonly pause: Fetchable;
  readonly archive: Fetchable;
  readonly retrieve: Fetchable;
  readonly changeModerator: Fetchable;
  readonly tabsCounter: OfferTableTabsCounter;
  readonly tabsCounterUnwatched: OfferTableTabsCounter;
  readonly unwatchedIds: UUID[];
}

type Reducer<T = undefined> = CaseReducer<TradeOfferListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<TradeOfferListState> {
  tradeOffersStartSession: Reducer<{ guid: UUID; filter?: TradeOfferTableFilterValues }>;
  tradeOffersSetTab: Reducer<{ tab: EOfferTableTab }>;
  tradeOffersSortReset: Reducer;
  tradeOffersNeedRefreshWatcherIncrement: Reducer;
  tradeOffersSetSort: Reducer<{ sort: string }>;
  tradeOffersSetSearch: Reducer<{ sort: string; pageSize: PaginationSize }>;
  tradeOffersSetPage: Reducer<{ pageNumber: number }>;
  tradeOffersSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  tradeOffersSetFilter: Reducer<TradeOfferTableFilterValues>;
  tradeOffersClearNotifierInfo: Reducer;
  tradeOffersActionsReset: Reducer;
  tradeOffersDataReset: Reducer;
  tradeOffersNeedRefreshWatcherReset: Reducer;
}

const slice = createSlice<TradeOfferListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    ...fetchableDefault,
    guid: null,
    data: [],
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    needRefreshWatcher: 0,
    search: {
      sort: defaultSort,
      pageSize: 10,
      partnerId: null,
    },
    allTradeOffers: {
      ...fetchableDefault,
      count: null,
      needRefreshWatcher: 0,
    },
    tab: null,
    filter: {},
    notifierInfo: null,
    actions: [],
    reject: fetchableDefault,
    pause: fetchableDefault,
    archive: fetchableDefault,
    retrieve: fetchableDefault,
    changeModerator: fetchableDefault,
    tabsCounter: {},
    tabsCounterUnwatched: {},
    unwatchedIds: [],
  },
  reducers: {
    tradeOffersStartSession: (state, { payload }) => {
      const { guid, filter } = payload;
      if (guid !== state.guid) {
        state.guid = guid;

        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = false;

        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;
        state.needRefreshWatcher = 0;
        state.search = {
          sort: defaultSort,
          pageSize: 10,
          partnerId: null,
        };
        state.allTradeOffers = {
          ...fetchableDefault,
          count: null,
          needRefreshWatcher: 0,
        };
        state.tab = null;
        state.filter = filter ?? {};
        state.actions = [];
        state.reject = fetchableDefault;
        state.pause = fetchableDefault;
        state.archive = fetchableDefault;
        state.retrieve = fetchableDefault;
        state.tabsCounter = {};
        state.tabsCounterUnwatched = {};
        state.unwatchedIds = [];
      }
    },
    tradeOffersSetTab: (state, { payload }) => {
      const { tab } = payload;
      // сбрасываем пейджинг и сортировку, если поменялись статусы (закладки)
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    tradeOffersNeedRefreshWatcherIncrement: state => {
      state.needRefreshWatcher++;
    },
    tradeOffersSetSearch: (state, { payload }) => {
      const { sort, pageSize } = payload;
      state.search = {
        ...state.search,
        sort,
        pageSize,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    tradeOffersSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    tradeOffersSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    tradeOffersSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    tradeOffersSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    tradeOffersSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    tradeOffersClearNotifierInfo: state => {
      state.notifierInfo = null;
    },
    tradeOffersActionsReset: state => {
      state.actions = [];
    },
    tradeOffersDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
    },
    tradeOffersNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
      state.allTradeOffers.needRefreshWatcher = 0;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(tradeOffersFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;

        if (partnerId !== state.search.partnerId) {
          state.data = [];
        }
        state.search.partnerId = partnerId;
      })
      .addCase(tradeOffersFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(tradeOffersFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(tradeOffersUnwatchedFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        if (partnerId !== state.search.partnerId) {
          state.unwatchedIds = [];
        }
      })
      .addCase(tradeOffersUnwatchedFetch.fulfilled, (state, { payload }) => {
        state.unwatchedIds = payload;
      })
      .addCase(tradeOffersUnwatchedFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.unwatchedIds = [];
        }
      })
      .addCase(tradeOffersCountsFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        if (partnerId !== state.search.partnerId) {
          state.tabsCounter = {};
        }
      })
      .addCase(tradeOffersCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(tradeOffersCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      })
      .addCase(tradeOffersCountsUnwatchedFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        if (partnerId !== state.search.partnerId) {
          state.tabsCounterUnwatched = {};
        }
      })
      .addCase(tradeOffersCountsUnwatchedFetch.fulfilled, (state, { payload }) => {
        state.tabsCounterUnwatched = payload;
      })
      .addCase(tradeOffersCountsUnwatchedFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounterUnwatched = {};
        }
      })
      .addCase(tradeOffersCountFetch.pending, state => {
        state.allTradeOffers.isFetching = true;
        state.allTradeOffers.isFetched = false;
        state.allTradeOffers.isFailed = false;

        if (state.allTradeOffers.count === 0) {
          //сбрасываем в null в том случае если до этого ничего не было, чтобы не моргала таблица лишний раз
          state.allTradeOffers.count = null;
        }
      })
      .addCase(tradeOffersCountFetch.fulfilled, (state, { payload }) => {
        state.allTradeOffers.isFetching = false;
        state.allTradeOffers.isFetched = true;
        state.allTradeOffers.isFailed = false;

        state.allTradeOffers.count = payload;
      })
      .addCase(tradeOffersCountFetch.rejected, state => {
        state.allTradeOffers.isFetching = false;
        state.allTradeOffers.isFetched = false;
        state.allTradeOffers.isFailed = true;

        state.allTradeOffers.count = null;
      })
      .addCase(tradeOfferActionsApprove.fulfilled, (state, { payload }) => {
        state.needRefreshWatcher++;
        state.notifierInfo = {
          type: EOfferTableNotificationType.Approved,
          tradeOffer: payload,
        };
      })
      .addCase(tradeOfferActionsPublish.fulfilled, (state, { payload }) => {
        state.notifierInfo = {
          type: EOfferTableNotificationType.Published,
          tradeOffer: payload,
        };
        state.needRefreshWatcher++;
        state.allTradeOffers.needRefreshWatcher++;
      })
      .addCase(tradeOfferActionsResume.fulfilled, (state, { payload }) => {
        state.needRefreshWatcher++;
        state.notifierInfo = {
          type: EOfferTableNotificationType.Resumed,
          tradeOffer: payload,
        };
      })
      .addCase(tradeOfferActionsReject.fulfilled, (state, { payload }) => {
        state.needRefreshWatcher++;
        state.notifierInfo = {
          type: EOfferTableNotificationType.Rejected,
          tradeOffer: payload,
        };
      })
      .addCase(tradeOfferActionsSave.fulfilled, state => {
        state.allTradeOffers.needRefreshWatcher++;
      });
  },
});

export const {
  tradeOffersStartSession,
  tradeOffersNeedRefreshWatcherIncrement,
  tradeOffersSetTab,
  tradeOffersSortReset,
  tradeOffersSetSort,
  tradeOffersSetSearch,
  tradeOffersSetPage,
  tradeOffersSetFilter,
  tradeOffersSetPageSize,
  tradeOffersClearNotifierInfo,
  tradeOffersActionsReset,
  tradeOffersDataReset,
  tradeOffersNeedRefreshWatcherReset,
  tradeOffersSetDialogState,
} = slice.actions;

export default slice.reducer;
