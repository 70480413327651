import { Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';

export const Wrapper = styled(Grid)`
  overflow: hidden;
`;

export const NameBox = styled.div`
  height: 0.875rem;
  width: 0.875rem;

  background: ${p => p.theme.palette.disabled.background};
  border-radius: ${p => p.theme.shape.borderRadius}px;
`;

export const CompanyName = styled(Typography)`
  font-size: 0.625rem;
  font-weight: bold;
`;
