import { MPRubFormat } from '@/presentation/theme/ui-kit/format';
import { FC } from 'react';
import { EEntityPreviewMode } from '../../../../types';
import { OfferPreviewSkeleton } from '../../../general/offer/components/preview/controls';
import { Price } from './controls';

type TradeOfferPreviewPriceProps = {
  readonly mode: EEntityPreviewMode;
  readonly price: Nullable<number>;
};

const TradeOfferPreviewPrice: FC<TradeOfferPreviewPriceProps> = props => {
  const { mode, price } = props;

  return (
    <>
      {price !== null ? (
        <Price variant={mode === EEntityPreviewMode.Mobile ? 'subtitle1' : 'h3'}>
          <MPRubFormat
            showRub
            value={price}
          />
        </Price>
      ) : (
        <OfferPreviewSkeleton
          animation={false}
          variant='rectangular'
          width='47%'
          height={mode === EEntityPreviewMode.Mobile ? '1.5rem' : '1.625rem'}
        />
      )}
    </>
  );
};

export default TradeOfferPreviewPrice;
