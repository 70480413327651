import { combineReducers } from '@reduxjs/toolkit';
import adminMp, { AdminMpState } from './adminMp/reducers';
import current, { UserCurrentState } from './current/reducers';
import userMp, { UserMpState } from './userMp/reducers';
import userSport, { UserSportState } from './userSport/reducers';
import userClientOrg, { ClientOrgUserState } from './userClientOrg/reducers';

interface UserState {
  current: UserCurrentState;
  userMp: UserMpState;
  adminMp: AdminMpState;
  userSport: UserSportState;
  userClientOrg: ClientOrgUserState;
}

export default combineReducers<UserState>({
  current,
  userMp,
  adminMp,
  userSport,
  userClientOrg,
});
