import Axios, { AxiosRequestConfig } from 'axios';
import { omit, pick } from 'lodash';
import Api from '../../../../data/api';
import {
  ApiCancellable,
  ApiQueryDsl,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
} from '../../../../data/api/types';
import {
  appendQueryDslParams,
  getPageableFromResponseHeaders,
  getProductOffersDownloadEndpoint,
  getProductsDownloadEndpoint,
  getProductStocksDownloadEndpoint,
  getQueryDslByDataFilterValues,
} from '../../../../data/api/utils';
import { Pageable, XLSXImportResult } from '../../../../domain/model';
import { EOfferStatus, EProductOfferActionDiscriminator } from '../../../../domain/model/enums';
import { DataFilterQueryDslOperator } from '../../../../domain/model/filter';
import {
  AttributeValuesFilter,
  ProductCategory,
  ProductCreateRequest,
  ProductDesk,
  ProductOffer,
  ProductOfferCreate,
} from '../../../../domain/model/productOffer';
import { Nullable, UUID } from '../../../../domain/model/types';
import { PaginationSize } from '../../../types';
import { Writeable } from '../../../utils/types';
import { OfferCounterByStatus } from '../../general/offer/types';
import { EProductTableFilterItem, ProductTableFilterValues } from '../table/filter/filterUtils';
import { EProductTableTab, getProductTableStatusByTab, ProductTableTabsCounter } from '../table/utils';

enum EProductsDownloadUrlParam {
  Status = 'status',
  PartnerId = 'partner.id',
  CategoryId = 'category.id',
}

type AloneIDProps = ApiCancellable & {
  readonly id: UUID;
};

type SaveProps = ApiCancellable & {
  readonly id: UUID;
  readonly product: ProductOfferCreate;
};

type RejectProps = ApiCancellable & {
  readonly id: UUID;
  readonly comment?: string;
  readonly reasonId: UUID;
};

type PauseProps = ApiCancellable & {
  readonly id: UUID;
  readonly comment: Nullable<string>;
};

type ChangePriceProps = ApiCancellable & {
  id: UUID;
  price: number;
  originalPrice: ProductOffer['originalPrice'];
};

type ChangeStockProps = ApiCancellable & {
  id: UUID;
  stock: number;
};

type AssignModeratorProps = ApiCancellable & {
  id: UUID;
  userId: UUID;
};

type GetAttributeValuesFilterProps = {
  readonly category: Nullable<ProductCategory>;
  readonly filter: ProductTableFilterValues;
};

type GetAttributeValuesFilter = {
  readonly attributeValues: AttributeValuesFilter[];
  readonly filterValues: ProductTableFilterValues;
};

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string>;
    readonly statuses: EOfferStatus[];
    readonly partnerId: Nullable<UUID>;
    readonly categories: Nullable<UUID[]>;
  };
  readonly filter: ProductTableFilterValues;
  readonly pageNumber: number;
  readonly category: Nullable<ProductCategory>;
};

type BuildListQueryParamsReturn<D extends ApiRequestListDiscriminator> = ApiCancellable &
  ApiRequestPageable & {
    readonly partnerId: Nullable<UUID>;
    readonly statuses?: Nullable<EOfferStatus[]>;
    readonly categories?: Nullable<UUID[]>;
    readonly attributeValues?: Nullable<AttributeValuesFilter[]>;
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

export type CountProps = Omit<AllProps, 'pageNumber'> & {
  readonly viewed?: boolean;
};

type CountsByStatusesProps = Omit<AllProps, 'pageSize' | 'page' | 'sort'> & {
  readonly viewed?: boolean;
};

type CountsByTabsProps = Omit<CountsByStatusesProps, 'statuses'> & {
  readonly tabs: EProductTableTab[];
};

type UnwatchedProps = Omit<AllProps, 'pageNumber'>;

type ProductsDownloadUrlWithSkipPaginationProps = {
  readonly skipPageable: true;
} & Partial<ApiRequestPageable>;

type ProductsDownloadUrlWithoutSkipPaginationProps = {
  readonly skipPageable?: false;
} & ApiRequestPageable;

type MabeSkipPaginationProductsDownloadUrlProps =
  | ProductsDownloadUrlWithSkipPaginationProps
  | ProductsDownloadUrlWithoutSkipPaginationProps;

type ProductsDownloadUrlProps = {
  readonly partnerId?: Nullable<UUID>;
  readonly categoryId?: Nullable<UUID>;
  readonly states?: Nullable<EOfferStatus[]>;
  readonly queryDsl?: ApiQueryDsl;
} & MabeSkipPaginationProductsDownloadUrlProps;

type StocksDownloadUrlProps = {
  readonly partnerId: UUID;
  readonly statuses: Array<EOfferStatus>;
};

type OffersDownloadUrlProps = {
  readonly partnerId: UUID;
  readonly categoryId: UUID;
};

type ImportStocksProps = ApiCancellable & {
  readonly partnerId: UUID;
  readonly file: File;
  readonly config?: AxiosRequestConfig;
};

type ImportProps = ApiCancellable & {
  readonly partnerId: UUID;
  readonly categoryId: UUID;
  readonly file: File;
  readonly config?: AxiosRequestConfig;
};

export type ProductOfferService = {
  readonly byId: (props: AloneIDProps) => Promise<ProductOfferCreate>;
  readonly getAttributeValuesFilter: (props: GetAttributeValuesFilterProps) => GetAttributeValuesFilter;
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps
  ) => BuildListQueryParamsReturn<D>;
  readonly all: (props: AllProps) => Promise<Pageable<ProductOffer>>;
  readonly unwatched: (props: UnwatchedProps) => Promise<ProductOffer[]>;
  readonly countsByStatuses: (
    props: CountsByStatusesProps
  ) => Promise<{ counts: OfferCounterByStatus; errors: Array<string> }>;
  readonly countsByTabs: (
    props: CountsByTabsProps
  ) => Promise<{ counts: ProductTableTabsCounter; errors: Array<string> }>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly duplicate: (props: AloneIDProps) => Promise<ProductOffer>;
  readonly delete: (props: AloneIDProps) => Promise<boolean>;
  readonly approve: (props: AloneIDProps) => Promise<ProductOffer>;
  readonly toModeration: (props: AloneIDProps) => Promise<ProductOfferCreate>;
  readonly assignModerator: (props: AssignModeratorProps) => Promise<ProductOffer>;
  readonly save: (props: SaveProps) => Promise<Omit<ProductOffer, 'productDesk'>>;
  readonly archive: (props: AloneIDProps) => Promise<ProductOffer>;
  readonly resume: (props: AloneIDProps) => Promise<ProductOffer>;
  readonly unPublish: (props: AloneIDProps) => Promise<ProductOffer>;
  readonly reject: (props: RejectProps) => Promise<ProductOffer>;
  readonly pause: (props: PauseProps) => Promise<ProductOffer>;
  readonly changePrice: (props: ChangePriceProps) => Promise<ProductOffer>;
  readonly changeStock: (props: ChangeStockProps) => Promise<ProductOffer>;
  readonly productsDownloadUrl: (props: ProductsDownloadUrlProps) => string;
  readonly stocksDownloadUrl: (props: StocksDownloadUrlProps) => string;
  readonly offersDownloadUrl: (props: OffersDownloadUrlProps) => string;
  readonly importStocks: (props: ImportStocksProps) => Promise<XLSXImportResult>;
  readonly import: (props: ImportProps) => Promise<XLSXImportResult>;
};

const service: ProductOfferService = {
  byId: async ({ id, signal }) => {
    const response = await Api.productOffers.one({ id, signal });

    let productDesk: Nullable<ProductDesk> = null;

    if (response.data.productDesk?.id) {
      productDesk = (await Api.product.productDesk.one({ id: response.data.productDesk.id })).data;
    }

    return {
      ...response.data,
      productDesk: productDesk,
    };
  },
  getAttributeValuesFilter: ({ category, filter }) => {
    let attributeValues: AttributeValuesFilter[] = [];
    let filterValues = filter;

    if (category?.attributes) {
      const { attributes } = category;

      const keysAttributes = attributes.map(item => item.attribute.name);

      const attrFilters = pick(filter, keysAttributes);

      attributeValues = Object.entries(attrFilters).reduce<AttributeValuesFilter[]>((acc, value) => {
        const [key, item] = value;
        if (item?.value) {
          const attrId = attributes.find(attr => attr.attribute.name === key)?.attribute.id;
          if (attrId) {
            if (Array.isArray(item?.value)) {
              item.value.map(attrVal => {
                acc.push({
                  id: attrId,
                  value: {
                    dictionaryValue: {
                      id: attrVal.id,
                    },
                    value: attrVal.name,
                  },
                });
              });
            } else {
              acc.push({
                id: attrId,
                value: {
                  value: item.value,
                },
              });
            }
          }
        }
        return acc;
      }, []);

      filterValues = omit(filter, keysAttributes);
    }

    return {
      attributeValues,
      filterValues,
    };
  },
  buildListQueryParams: props => {
    const { search, filter, category, pageNumber, signal } = props;
    const { pageSize, sort, statuses, categories, partnerId } = search;

    const query = filter[EProductTableFilterItem.Query]?.value;

    const { attributeValues, filterValues } = service.getAttributeValuesFilter({ category, filter });
    const querydsl = getQueryDslByDataFilterValues(filterValues);

    return {
      query,
      sort,
      signal,
      pageSize,
      partnerId,
      statuses,
      categories,
      page: pageNumber,
      querydsl,
      attributeValues,
    };
  },

  all: async props => {
    const response = await Api.productOffers.all(service.buildListQueryParams(props));
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);

    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
  unwatched: async props => {
    const { data: productOffers } = await Api.productOffers.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      pageSize: 99999,
      viewed: false,
    });
    return productOffers;
  },

  count: async ({ viewed, ...props }) => {
    const { data: response } = await Api.productOffers.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      discriminator: ApiRequestListDiscriminator.Count,
      viewed,
    });

    return response[0].count;
  },
  countsByStatuses: async ({ viewed, signal, ...props }) => {
    const {
      search: { statuses },
    } = props;

    const errors: string[] = [];
    const counts: OfferCounterByStatus = {};

    const requests = statuses.map(status => {
      const params = {
        ...props,
        search: { ...props.search, statuses: [status] },
      };
      return service.count({ ...params, viewed, signal });
    });

    const responses = await Promise.allSettled(requests);
    const parseResponse = (response: typeof responses[0], status: EOfferStatus) => {
      if (response.status === 'fulfilled') {
        counts[status] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество товаров '${status}': ${response.reason}`);
        }
      }
    };
    statuses.forEach((status, index) => parseResponse(responses[index], status));

    return { counts, errors };
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: ProductTableTabsCounter = {};

    const requests = tabs.map(tab => {
      const statuses = getProductTableStatusByTab(tab);
      const params = {
        ...props,
        search: { ...props.search, statuses },
        statuses,
      };
      return service.countsByStatuses({ ...params, signal });
    });
    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: typeof responses[0], tab: EProductTableTab) => {
      if (response.status === 'fulfilled') {
        const statuses = getProductTableStatusByTab(tab);
        counts[tab] = statuses.reduce<number>((prev, current) => prev + (response.value.counts?.[current] ?? 0), 0);
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество корпоративных предложений '${tab}': ${response.reason}`);
        }
      }
    };
    tabs.forEach((tab, index) => parseResponse(responses[index], tab));

    return { counts, errors };
  },

  delete: async ({ id }) => {
    const response = await Api.productOffers.delete({ id });

    return response.status === 200;
  },
  approve: async ({ id }) => {
    const { data } = await Api.productOffers.actions({
      id,
      discriminator: EProductOfferActionDiscriminator.OfferApproveCommand,
    });
    return data;
  },
  duplicate: async ({ id }) => {
    const { data } = await Api.productOffers.duplicate({
      id,
    });

    return data;
  },
  assignModerator: async ({ id, userId }) => {
    const { data } = await Api.productOffers.actions({
      id,
      user: {
        id: userId,
      },
      discriminator: EProductOfferActionDiscriminator.OfferAssignModeratorCommand,
    });

    return data;
  },
  toModeration: async ({ id }) => {
    const response = await Api.productOffers.actions({
      id,
      discriminator: EProductOfferActionDiscriminator.OfferToModerationCommand,
    });

    let productDesk: Nullable<ProductDesk> = null;

    if (response.data.productDesk?.id) {
      productDesk = (await Api.product.productDesk.one({ id: response.data.productDesk.id })).data;
    }

    return {
      ...response.data,
      productDesk: productDesk,
    };
  },
  save: async ({ id, product }) => {
    const data: Omit<Writeable<ProductCreateRequest>, 'discriminator'> = {
      category: null,
      name: product.name,
      variantName: product.variantName,
      images: product.images,
      partner: product.partner,
      brand: product.brand
        ? {
            id: product.brand.id,
          }
        : null,
      partnerSKU: product.partnerSKU,
      producerSKU: product.producerSKU,
      productDesk: product.productDesk
        ? {
            id: product.productDesk.id,
          }
        : null,
      description: product.description,
      originalPrice: product.originalPrice,
      price: product.price,
      attributes: product.attributes,
      otherAttributes: product.otherAttributes,
      paymentType: product.paymentType,
      paymentConditions: product.paymentConditions,
      deliveryType: product.deliveryType,
      deliveryConditions: product.deliveryConditions,
      target: product.target,
    };

    let response;

    if (id) {
      if ([EOfferStatus.Draft, EOfferStatus.Rejected, EOfferStatus.WaitingForModeration].includes(product.status)) {
        response = await Api.productOffers.updateDraft({ id, data });
      } else {
        response = await Api.productOffers.update({ id, data });
      }
    } else {
      data.category = {
        id: product.category!.id,
      };
      response = await Api.productOffers.createDraft({ id, data });
    }

    const { productDesk, ...restData } = response.data;

    return restData;
  },
  archive: async ({ id }) => {
    const { data } = await Api.productOffers.actions({
      id,
      discriminator: EProductOfferActionDiscriminator.OfferArchiveCommand,
    });
    return data;
  },
  resume: async ({ id }) => {
    const { data } = await Api.productOffers.actions({
      id,
      discriminator: EProductOfferActionDiscriminator.OfferRenewCommand,
    });
    return data;
  },
  unPublish: async ({ id }) => {
    const { data } = await Api.productOffers.actions({
      id,
      discriminator: EProductOfferActionDiscriminator.OfferBackToDraftCommand,
    });
    return data;
  },
  reject: async ({ id, comment, reasonId }) => {
    const { data } = await Api.productOffers.actions({
      id,
      discriminator: EProductOfferActionDiscriminator.OfferRejectCommand,
      comment,
      rejectionType: {
        id: reasonId,
      },
    });
    return data;
  },
  pause: async ({ id, comment }) => {
    const { data } = await Api.productOffers.actions({
      id,
      discriminator: EProductOfferActionDiscriminator.OfferPauseCommand,
      comment,
    });
    return data;
  },
  changePrice: async ({ id, price, originalPrice }) => {
    const { data } = await Api.productOffers.actions({
      id,
      discriminator: EProductOfferActionDiscriminator.ProductOfferChangePriceCommand,
      price,
      originalPrice,
    });
    return data;
  },
  changeStock: async ({ id, stock }) => {
    const { data } = await Api.productOffers.actions({
      id,
      discriminator: EProductOfferActionDiscriminator.ProductOfferChangeStockCommand,
      stock,
    });
    return data;
  },
  productsDownloadUrl: ({ sort, page, pageSize, partnerId, categoryId, states, skipPageable, queryDsl = [] }) => {
    const searchParams = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        searchParams.append('sort', sort);
      } else {
        sort.forEach(item => searchParams.append('sort', item));
      }
    }

    if (skipPageable) {
      searchParams.append('skipPageable', skipPageable.toString());
    } else {
      page && searchParams.append('page', (page - 1).toString(10));
      pageSize && searchParams.append('size', pageSize.toString(10));
    }

    if (states) {
      queryDsl.push({
        field: EProductsDownloadUrlParam.Status,
        operator: DataFilterQueryDslOperator.In,
        value: states,
      });
    }

    if (partnerId) {
      queryDsl.push({
        field: EProductsDownloadUrlParam.PartnerId,
        operator: DataFilterQueryDslOperator.Equals,
        value: [partnerId],
      });
    }

    if (categoryId) {
      queryDsl.push({
        field: EProductsDownloadUrlParam.CategoryId,
        operator: DataFilterQueryDslOperator.Equals,
        value: [categoryId],
      });
    }

    appendQueryDslParams(searchParams, queryDsl);

    const search = searchParams.toString() ? `?${searchParams}` : '';

    return `${getProductsDownloadEndpoint()}${search}`;
  },
  stocksDownloadUrl: ({ partnerId, statuses }) => {
    const searchParams = new URLSearchParams();
    searchParams.append('partnerId', partnerId);
    statuses.forEach(status => searchParams.append('status', status));

    return `${getProductStocksDownloadEndpoint()}?${searchParams}`;
  },
  offersDownloadUrl: ({ partnerId, categoryId }) => {
    const searchParams = new URLSearchParams();
    searchParams.append('partnerId', partnerId);
    searchParams.append('categoryId', categoryId);

    return `${getProductOffersDownloadEndpoint()}?${searchParams}`;
  },
  import: async ({ partnerId, categoryId, file, config }) => {
    const { data } = await Api.productOffers.import({
      partnerId,
      categoryId,
      file,
      config,
    });
    return data;
  },
  importStocks: async ({ partnerId, file, config }) => {
    const { data } = await Api.productOffers.importStocks({
      partnerId,
      file,
      config,
    });
    return data;
  },
};

export default service;
