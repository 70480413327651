import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../data/store/store';
import { EPartnerStatus, ESortDirection } from '../../../../../domain/model/enums';
import { PartnerShortAny } from '../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { addSearchParamToLocation } from '../../../../../routing/globalRouting';
import { PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import { EPartnerTableTab } from '../../../general/partner/types';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { EPartnerUrlParam } from '../../entry';
import { EPartnerTableFilterItem, PartnerTableFilterEditStrategy, PartnerTableFilterValues } from '../../filterUtils';
import { partnersGuidSelector } from '../store/selectors';
import {
  partnersActivatePartner,
  partnersDataReset,
  partnersDeactivatePartner,
  partnersDeletePartner,
  partnersSendToVerificationPartner,
  partnersSetFilter,
  partnersSetPage,
  partnersSetPageSize,
  partnersSetSort,
  partnersSortReset,
} from '../store/slice';

export type UsePartnersTable = {
  readonly onChangeTab: (newTab: EPartnerTableTab) => void;
  readonly onChangeFilter: (strategies: PartnerTableFilterEditStrategy[]) => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
  readonly onResetSort: () => void;
  readonly onChangePageNumber: (page: number) => void;
  readonly onChangePageSize: (pageSize: PaginationSize) => void;

  readonly isPartnerNotModeratedByMe: (partner: PartnerShortAny) => boolean;

  readonly onPartnerDelete: (partner: PartnerShortAny) => Promise<boolean>;
  readonly onPartnerActivate: (partner: PartnerShortAny) => Promise<boolean>;
  readonly onPartnerDeactivate: (partner: PartnerShortAny) => Promise<boolean>;
  readonly onPartnerReturnToVerification: (partner: PartnerShortAny) => Promise<boolean>;
};

type UsePartnersTableHandlersProps = {
  readonly tab: EPartnerTableTab;
};
export function usePartnersTableHandlers({ tab }: UsePartnersTableHandlersProps): UsePartnersTable {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const {
    userSpecific: { id: userId },
    accessMatrix,
  } = useCurrentUser();

  const getPartnersGuidFromState = useCallback((): Nullable<UUID> => {
    const state = store.getState();
    return partnersGuidSelector(state);
  }, [store]);

  const onChangeTab = useCallback(
    (newTab: EPartnerTableTab) => {
      if (newTab !== tab) {
        const guid = getPartnersGuidFromState();
        dispatch(partnersDataReset())
        history.replace(
          addSearchParamToLocation({
            location,
            param: EPartnerUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [dispatch, history, location, tab, getPartnersGuidFromState]
  );

  const onChangeFilter = useCallback(
    (strategies: PartnerTableFilterEditStrategy[]) => {
      dispatch(
        partnersSetFilter(
          getDataFilterValuesByStrategies<EPartnerTableFilterItem, PartnerTableFilterValues>(strategies)
        )
      );
    },
    [dispatch]
  );

  const onChangeSort = useCallback(
    (name: string, direction: ESortDirection) => {
      dispatch(
        partnersSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onChangePageNumber = useCallback(
    (page: number) => {
      dispatch(partnersSetPage(page));
    },
    [dispatch]
  );

  const onChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(partnersSetPageSize(pageSize));
    },
    [dispatch]
  );

  const onResetSort = useCallback(() => dispatch(partnersSortReset()), [dispatch]);

  const isPartnerNotModeratedByMe = useCallback(
    (partner: PartnerShortAny) => {
      return (
        !!accessMatrix.partners.approve &&
        partner.status === EPartnerStatus.OnVerification &&
        !!partner?.partnerReviewer?.id &&
        partner.partnerReviewer.id !== userId
      );
    },
    [userId, accessMatrix.tradeOffers.moderate]
  );

  const onPartnerDelete = useCallback(
    async ({ id }: PartnerShortAny) => {
      await dispatch(partnersDeletePartner(id)).unwrap();
      return true;
    },
    [dispatch]
  );

  const onPartnerActivate = useCallback(
    async ({ id }: PartnerShortAny) => {
      await dispatch(partnersActivatePartner(id)).unwrap();
      return true;
    },
    [dispatch]
  );

  const onPartnerDeactivate = useCallback(
    async ({ id }: PartnerShortAny) => {
      await dispatch(partnersDeactivatePartner(id)).unwrap();
      return true;
    },
    [dispatch]
  );

  const onPartnerReturnToVerification = useCallback(
    async ({ id }: PartnerShortAny) => {
      await dispatch(partnersSendToVerificationPartner(id)).unwrap();
      return true;
    },
    [dispatch]
  );

  return {
    onChangeTab,
    onChangeFilter,
    onChangeSort,
    onResetSort,
    isPartnerNotModeratedByMe,
    onPartnerActivate,
    onPartnerDeactivate,
    onPartnerDelete,
    onPartnerReturnToVerification,
    onChangePageNumber,
    onChangePageSize,
  };
}
