import styled from '@emotion/styled';
import { viewConfig } from '../../../theme/viewConfig';

export const FieldsGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2)};
`;

export const ContentWrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  padding-top: ${p => p.theme.spacing(1)};
`;
