import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { UUID } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import PartnerDeskEditContainer from '../../features/partnerDesk/edit/container';
import { EPartnerDeskEditStep } from '../../features/partnerDesk/edit/utils';
import { EPartnerDeskUrlParam } from '../../features/partnerDesk/entry';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const PartnerDeskEditScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { accessMatrix } = useCurrentUser();
  const { partnerDesks } = accessMatrix;

  const { id } = useParams<{ id: UUID }>();
  const searchParams = new URLSearchParams(location.search);
  const step = (searchParams.get(EPartnerDeskUrlParam.Step) as EPartnerDeskEditStep) || undefined;

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  if (!partnerDesks?.edit) {
    return <ForbiddenComponent />;
  }

  return (
    <PartnerDeskEditContainer
      partnerId={id}
      step={step}
    />
  );
};

export default PartnerDeskEditScreen;
