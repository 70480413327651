import { UUID } from '@/domain';
import ForbiddenComponent from '@components/common/forbidden';
import ClientOrgCreateContainer from '@features/clientOrg/create/container';
import { EClientOrgUrlParam } from '@features/clientOrg/entry';
import { ClientOrgLifeCycleFactory } from '@features/clientOrg/lifecycle/lifecycle';
import { ClientOrgCreateStepType, EClientOrgStep } from '@features/clientOrg/types';
import useBreadcrumbsActions from '@features/general/breadcrumbs/useBreadcrumbsActions';
import { viewHideMainMenu } from '@features/main/container/store/slice';
import useCurrentUser from '@features/user/hooks/useCurrentUser';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';

const ClientOrgEditScreen = () => {
  const dispatch = useDispatch();
  const { putBreadcrumbs } = useBreadcrumbsActions();
  const location = useLocation();

  const { id } = useParams<{ id: UUID }>();
  const searchParams = new URLSearchParams(location.search);
  const step: ClientOrgCreateStepType =
    (searchParams.get(EClientOrgUrlParam.Step) as ClientOrgCreateStepType) ?? EClientOrgStep.General;

  const {
    accessMatrix: { clientOrgs, isPartnerUser },
    userSpecific,
  } = useCurrentUser();

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  useEffect(() => {
    putBreadcrumbs([{ label: 'Компании-клиенты' }]);
  }, []);

  if (!clientOrgs?.create) {
    return <ForbiddenComponent />;
  }

  return (
    <ClientOrgCreateContainer
      id={id}
      step={step}
      lifecycle={ClientOrgLifeCycleFactory().create({ isPartnerUser, userId: userSpecific.id })}
      access={clientOrgs}
    />
  );
};

export default ClientOrgEditScreen;
