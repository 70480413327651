import { Grid, Typography } from '@mui/material';
import { MpUser, NotificationOption, Nullable } from '@/domain';
import { ButtonLink } from '@components/common/buttons/link';
import Splitter from '../../../../components/common/splitter';
import { AdminIcon, MailIcon } from '@/presentation/icons';
import UserDetailsGeneral from '../../components/detailsGeneral';
import UserDetailsNotifications from '../../components/detailsNotifications';
import { ColumnWrapper, Wrapper } from './controls';

interface UserCurrentDetailsMpComponentProps {
  readonly user: MpUser;
  readonly notifications: Nullable<NotificationOption[]>;
  readonly onChangeNotification: (option: NotificationOption, enabled: boolean) => void;
  readonly onChangePassword: Nullable<() => void>;
  readonly onChangeEmail?: () => void;
}

const UserCurrentDetailsMpComponent = (props: UserCurrentDetailsMpComponentProps) => {
  const { user, notifications, onChangeNotification, onChangePassword, onChangeEmail } = props;

  return (
    <Wrapper>
      <ColumnWrapper>
        <Grid
          container
          spacing={4}
          direction='column'
        >
          <Grid item>
            <Typography variant='h3'>Профиль</Typography>
            <Splitter
              variant='horizontal'
              size={3}
            />
            <UserDetailsGeneral user={user} />
          </Grid>
          {onChangePassword && (
            <Grid item>
              <ButtonLink onClick={onChangePassword}>
                <AdminIcon />
                Сменить пароль
              </ButtonLink>
            </Grid>
          )}
          {onChangeEmail && (
            <Grid item>
              <ButtonLink onClick={onChangeEmail}>
                <MailIcon />
                Изменить почту
              </ButtonLink>
            </Grid>
          )}
          {notifications && notifications.length > 0 && (
            <Grid item>
              <UserDetailsNotifications
                notifications={notifications}
                notificationSettings={user.notificationSettings}
                onChange={onChangeNotification}
              />
            </Grid>
          )}
        </Grid>
      </ColumnWrapper>
    </Wrapper>
  );
};

export default UserCurrentDetailsMpComponent;
