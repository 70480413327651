import { DictionaryTree } from './common';
import { DictionaryTreeChangeType, DictionaryTreeProps } from './types';

type DictionaryTreeEditProps<T> = Omit<DictionaryTreeProps<T>, 'initialSelected' | 'changeType'>

export const DictionaryTreeEdit = <T extends object>(props: DictionaryTreeEditProps<T>) => {
  return (
    <DictionaryTree {...props} changeType={DictionaryTreeChangeType.click} />
  );
};
