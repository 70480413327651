import moment from 'moment';
import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterStrategyView,
  DataFilterValueItem,
  EDataFilterType,
} from '../../../../domain/model/filter';
import { EDateFormat } from '../../../../domain/model/formats';
import { Nullable, UUID } from '../../../../domain/model/types';
import { formatNumberRange } from '../../../utils';

export enum EProductOrdersFilterItem {
  /** Строка запроса */
  Query = 'query',
  /** Фильтр даты создания */
  CreateAt = 'createdAt',
  /** Фильтр номер заказа */
  Number = 'number',
  /** Фильтр фамилия сотрудника */
  CustomerName = 'offerActivation.customer.lastName',
  /** Фильтр Общей стоимость со скидкой в рублях*/
  Cost = 'cost',
}

export type ProductOrdersFilterEditStrategy = DataFilterStrategyBase<EProductOrdersFilterItem>;
export type ProductOrdersFilterViewStrategy = DataFilterStrategyView<EProductOrdersFilterItem>;
export type ProductOrdersFilterValues = {
  readonly [EProductOrdersFilterItem.Query]?: DataFilterValueItem<Nullable<string>>;
  readonly [EProductOrdersFilterItem.CreateAt]?: DataFilterValueItem<Nullable<[string, string]>>;
  readonly [EProductOrdersFilterItem.Number]?: DataFilterValueItem<Nullable<string>>;
  readonly [EProductOrdersFilterItem.CustomerName]?: DataFilterValueItem<Nullable<string>>;
  readonly [EProductOrdersFilterItem.Cost]?: DataFilterValueItem<Nullable<[Nullable<number>, Nullable<number>]>>;
};

const getCostPreview = (cost: ProductOrdersFilterValues[EProductOrdersFilterItem.Cost]): Nullable<string> => {
  const range = formatNumberRange(cost?.value?.[0] ?? null, cost?.value?.[1] ?? null);
  return range ? 'Сумма ' + range : null;
};

export const getProductOrdersFilterStrategies = (
  customerId: Nullable<UUID>,
  values: ProductOrdersFilterValues
): ProductOrdersFilterEditStrategy[] => {
  const strategies: ProductOrdersFilterEditStrategy[] = [];
  strategies.push(
    {
      type: EDataFilterType.String,
      key: EProductOrdersFilterItem.Query,
      label: 'Поиск по ключевому слову',
      preview: values[EProductOrdersFilterItem.Query]?.value || null,
      value: values[EProductOrdersFilterItem.Query]?.value || null,
    },
    {
      type: EDataFilterType.DateAsTimePeriod,
      key: EProductOrdersFilterItem.CreateAt,
      label: 'Дата',
      preview: moment(values[EProductOrdersFilterItem.CreateAt]?.value?.[0]).format(EDateFormat.DisplayDefault) || null,
      value: values[EProductOrdersFilterItem.CreateAt]?.value || null,
      querydsl: {
        operator: DataFilterQueryDslOperator.Between,
      },
    },
    {
      type: EDataFilterType.String,
      key: EProductOrdersFilterItem.Number,
      label: 'Номер заказа',
      preview: values[EProductOrdersFilterItem.Number]?.value || null,
      value: values[EProductOrdersFilterItem.Number]?.value || null,
      querydsl: {
        operator: DataFilterQueryDslOperator.Like,
      },
    }
  );
  strategies.push(
    {
      type: EDataFilterType.String,
      key: EProductOrdersFilterItem.CustomerName,
      label: 'Клиент',
      preview: values[EProductOrdersFilterItem.CustomerName]?.value || null,
      value: values[EProductOrdersFilterItem.CustomerName]?.value || null,
      querydsl: {
        operator: DataFilterQueryDslOperator.Like,
      },
    },
    {
      type: EDataFilterType.NumberPeriod,
      key: EProductOrdersFilterItem.Cost,
      label: '',
      labelPeriod: {
        from: 'Сумма от',
        to: 'до',
      },
      preview: getCostPreview(values[EProductOrdersFilterItem.Cost]),
      value: values[EProductOrdersFilterItem.Cost]?.value || null,
      querydsl: {
        operator: DataFilterQueryDslOperator.Between,
      },
    }
  );

  return strategies;
};
