import { CommonLifeCycleInstance } from '../../../utils/lifecycle';
import { EProductActionType } from '../types';
import { commonAdminConfiguration, commonPartnerConfiguration } from './configurations';
import { ProductLifeCycle, ProductLifeCycleFactoryProps, ProductLifeCycleProps, ProductType } from './types';

/**
 * жизненный цикл продукта
 */
const ProductLifeCycleInstance = (props: ProductLifeCycleProps<ProductType>): ProductLifeCycle => {
  const { configuration, isPartnerUser } = props;

  const commonLifecycle = CommonLifeCycleInstance(props);

  return {
    configuration,
    build: ({ allowedActions, obj }) => {
      const commonLifecycleBuild = commonLifecycle.build({ allowedActions, obj });
      const { can } = commonLifecycleBuild;

      const edit = can(EProductActionType.Edit);

      return {
        ...commonLifecycleBuild,
        canEdit: edit,
      };
    },
    isPartnerUser,
  };
};

/**
 * жизненный цикл продукта - основная модель
 */
export const ProductLifeCycleFactory = () => {
  return {
    create: ({ isPartnerUser, userId, debug }: ProductLifeCycleFactoryProps): ProductLifeCycle => {
      if (isPartnerUser) {
        return ProductLifeCycleInstance({ userId, configuration: commonPartnerConfiguration, debug, isPartnerUser });
      } else {
        return ProductLifeCycleInstance({ userId, configuration: commonAdminConfiguration, debug, isPartnerUser });
      }
    },
  };
};
