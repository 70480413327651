import axios, { AxiosResponse } from 'axios';
import { LastSortIndex } from '../../domain/model';
import { Banner, BannerRequest } from '../../domain/model/banner';
import { Nullable, UUID } from '../../domain/model/types';
import { createCancelToken } from './index';
import {
  ApiCancellable,
  ApiQueryDsl,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
  ApiResponseMaBeCounters,
} from './types';
import { appendQueryDslParams, getComServicesEndpoint } from './utils';

export type AllProps<D extends ApiRequestListDiscriminator> = ApiRequestPageable &
  ApiCancellable & {
    readonly startDate?: Nullable<string>;
    readonly endDate?: Nullable<string>;
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
    readonly discriminator?: D;
  };

type CreateProps = BannerRequest;

type UpdateProps = {
  readonly id: UUID;
  readonly request: BannerRequest;
};

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type BannerPublishProps = {
  readonly id: UUID;
};

type BannerResumedProps = {
  readonly id: UUID;
};

type BannerArchivedProps = {
  readonly id: UUID;
};

export type BannerPausedProps = {
  readonly id: UUID;
};

type ChangeSortIndexProps = {
  readonly id: UUID;
  readonly sortIndex: number;
};

export type BannerLockProps = {
  readonly id: UUID;
};

export type BannerUnlockProps = {
  readonly id: UUID;
};

type BannerApi = {
  readonly all: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps<D>
  ) => Promise<AxiosResponse<ApiResponseMaBeCounters<D, Banner[]>>>;
  readonly one: (props: OneProps) => Promise<AxiosResponse<Banner>>;
  readonly create: (props: CreateProps) => Promise<AxiosResponse<Banner>>;
  readonly update: (props: UpdateProps) => Promise<AxiosResponse<Banner>>;
  readonly publish: (props: BannerPublishProps) => Promise<AxiosResponse<Banner>>;
  readonly paused: (props: BannerPausedProps) => Promise<AxiosResponse<Banner>>;
  readonly resumed: (props: BannerResumedProps) => Promise<AxiosResponse<Banner>>;
  readonly archived: (props: BannerArchivedProps) => Promise<AxiosResponse<Banner>>;
  readonly changeSortIndex: (props: ChangeSortIndexProps) => Promise<AxiosResponse<void>>;
  readonly lastSortIndex: () => Promise<AxiosResponse<LastSortIndex>>;
  readonly lock: (props: BannerLockProps) => Promise<AxiosResponse<Banner>>;
  readonly unlock: (props: BannerUnlockProps) => Promise<AxiosResponse<Banner>>;
};

/**
 * АПИ по работе с торговыми предложениями
 */
const banner: BannerApi = {
  all: props => {
    const { page, pageSize, sort, startDate, discriminator, endDate, query, querydsl, signal } = props;

    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (endDate) {
      params.append('endDate', endDate);
    }

    if (startDate) {
      params.append('startDate', startDate);
    }

    if (query) {
      params.append('q', query);
    }

    if (querydsl) {
      appendQueryDslParams(params, querydsl);
    }

    if (discriminator) {
      params.append('resultType', discriminator);
    }

    return axios.get(`${getComServicesEndpoint()}/banners`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  one: ({ id, signal }) => {
    return axios.get(`${getComServicesEndpoint()}/banners/${id}`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  create: data => {
    return axios.post(`${getComServicesEndpoint()}/banners`, data);
  },
  update: ({ id, request }) => {
    return axios.put(`${getComServicesEndpoint()}/banners/${id}`, request);
  },
  paused: ({ id }) => {
    return axios.patch(`${getComServicesEndpoint()}/banners/${id}/paused`);
  },
  publish: ({ id }) => {
    return axios.patch(`${getComServicesEndpoint()}/banners/${id}/publish`);
  },
  archived: ({ id }) => {
    return axios.patch(`${getComServicesEndpoint()}/banners/${id}/archived`);
  },
  resumed: ({ id }) => {
    return axios.patch(`${getComServicesEndpoint()}/banners/${id}/resumed`);
  },
  lock: ({ id }) => {
    return axios.patch(`${getComServicesEndpoint()}/banners/${id}/lock-for-edit`, { lock: true });
  },
  unlock: ({ id }) => {
    return axios.patch(`${getComServicesEndpoint()}/banners/${id}/lock-for-edit`, { lock: false });
  },
  changeSortIndex: ({ id, sortIndex }) => {
    return axios.patch(`${getComServicesEndpoint()}/banners/${id}/sortIndex`, { sortIndex });
  },
  lastSortIndex: () => {
    return axios.get(`${getComServicesEndpoint()}/banners/sortIndex/last`);
  },
};

export default banner;
