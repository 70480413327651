import axios, { AxiosResponse } from 'axios';
import { EActivityTypeStatus } from '../../domain/model/enums';
import { ActivityType, ActivityTypeCreateRequest } from '../../domain/model/event';
import { UUID } from '../../domain/model/types';
import { createCancelToken } from './index';
import { ApiCancellable } from './types';
import { getSportBaseEndpoint } from './utils';

export enum ActivityTypeUpdateDiscriminator {
  UpdateActivityTypeCommand = 'UpdateActivityTypeCommand',
  ChangeActivityTypeStatusCommand = 'ChangeActivityTypeStatusCommand',
  SortChildrenActivityTypesCommand = 'SortChildrenActivityTypesCommand',
}

export enum ActivityTypeActionDiscriminator {
  SortRootActivityTypesCommand = 'SortRootActivityTypesCommand',
}

type CreateProps = ActivityTypeCreateRequest;

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type DeleteProps = {
  readonly id: UUID;
};

type AllProps = ApiCancellable & {
  readonly statuses?: EActivityTypeStatus[];
  readonly sport?: boolean;
};

type UpdateProps<D extends ActivityTypeUpdateDiscriminator> =
  | {
      readonly discriminator: ActivityTypeUpdateDiscriminator.UpdateActivityTypeCommand;
      readonly id: UUID;
      readonly data: ActivityType;
    }
  | {
      readonly discriminator: ActivityTypeUpdateDiscriminator.ChangeActivityTypeStatusCommand;
      readonly id: UUID;
      readonly data: ActivityType;
    }
  | {
      readonly discriminator: ActivityTypeUpdateDiscriminator.SortChildrenActivityTypesCommand;
      readonly id: UUID;
      readonly data: ActivityType[];
    };

type ActionProps<D extends ActivityTypeActionDiscriminator> = {
  readonly discriminator: ActivityTypeActionDiscriminator.SortRootActivityTypesCommand;
  readonly data: ActivityType[];
};

type ActivityTypeApi = {
  readonly create: (props: CreateProps) => Promise<AxiosResponse<ActivityType>>;
  readonly all: (props: AllProps) => Promise<AxiosResponse<ActivityType[]>>;
  readonly one: (props: OneProps) => Promise<AxiosResponse<ActivityType>>;
  readonly delete: (props: DeleteProps) => Promise<AxiosResponse<void>>;
  readonly action: <D extends ActivityTypeActionDiscriminator>(props: ActionProps<D>) => Promise<AxiosResponse<void>>;
  readonly update: <D extends ActivityTypeUpdateDiscriminator>(
    props: UpdateProps<D>
  ) => Promise<AxiosResponse<ActivityType>>;
};

const activityType: ActivityTypeApi = {
  create: data => {
    return axios.post(`${getSportBaseEndpoint()}/activity-types`, data);
  },
  all: ({ statuses, sport, signal }) => {
    const params = new URLSearchParams();

    statuses?.forEach(status => params.append('status', status));

    if (sport) {
      params.append('type', 'SPORT');
    } else {
      params.append('type', 'SPORT');
      params.append('type', 'OTHER');
    }

    return axios.get(`${getSportBaseEndpoint()}/activity-types`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  one: ({ id, signal }) => {
    return axios.get(`${getSportBaseEndpoint()}/activity-types/${id}`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  update: props => {
    switch (props.discriminator) {
      case ActivityTypeUpdateDiscriminator.UpdateActivityTypeCommand:
      case ActivityTypeUpdateDiscriminator.ChangeActivityTypeStatusCommand:
        return axios.post(`${getSportBaseEndpoint()}/activity-types/${props.id}`, {
          discriminator: props.discriminator,
          ...props.data,
        });
      case ActivityTypeUpdateDiscriminator.SortChildrenActivityTypesCommand:
        return axios.post(`${getSportBaseEndpoint()}/activity-types/${props.id}`, {
          discriminator: props.discriminator,
          children: props.data,
        });
    }
  },
  action: props => {
    switch (props.discriminator) {
      case ActivityTypeActionDiscriminator.SortRootActivityTypesCommand:
        return axios.post(`${getSportBaseEndpoint()}/activity-types/actions`, {
          discriminator: props.discriminator,
          array: props.data,
        });
    }
  },
  delete: ({ id }) => {
    return axios.delete(`${getSportBaseEndpoint()}/activity-types/${id}`);
  },
};

export default activityType;
