import { CommonLifeCycleInstance } from '../../../utils/lifecycle';
import { bookingOrderAdminConfiguration, bookingOrderPartnerConfiguration } from './configuration';
import { BookingOrderLifeCycle, BookingOrderLifeCycleFactoryProps, BookingOrderLifeCycleProps } from './types';

const BookingOrderLifeCycleInstance = (props: BookingOrderLifeCycleProps): BookingOrderLifeCycle => {
  const { configuration } = props;

  const commonLifecycle = CommonLifeCycleInstance(props);

  return {
    configuration,
    build: ({ allowedActions, obj }) => {
      const commonLifecycleBuild = commonLifecycle.build({ allowedActions, obj });

      return {
        ...commonLifecycleBuild,
      };
    },
  };
};

/**
 * фабрика создания жизненнешго цикла заказа
 */
export const BookingOrderLifeCycleFactory = () => ({
  create: ({ isPartnerUser, userId, debug }: BookingOrderLifeCycleFactoryProps): BookingOrderLifeCycle => {
    if (isPartnerUser) {
      return BookingOrderLifeCycleInstance({ userId, configuration: bookingOrderPartnerConfiguration, debug });
    } else {
      return BookingOrderLifeCycleInstance({ userId, configuration: bookingOrderAdminConfiguration, debug });
    }
  },
});