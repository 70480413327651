import React, { FC, FCC, useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop, XYCoord } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Nullable } from '../../../../../domain/model/types';
import { FileDescription } from '../../../../../domain/model';
import { BadgeRemoveIcon, ImageGridContainer, ImageItemWrapper, StyledImage } from './controls';
import type { Identifier } from 'dnd-core';
import CloseIcon from '@mui/icons-material/Close';
import { difference } from 'lodash';
import { ViewImage } from './ViewImage';

type ImageGridProps = {
  readonly readOnly?: boolean;
  readonly images: Nullable<FileDescription[]>;
  readonly onChange?: (newItems: FileDescription[], oldItems: Nullable<FileDescription[]>) => void;
};

interface DragItem {
  index: number;
  image: string;
  type: string;
}

type ImageItemProps = {
  readonly index: number;
  readonly image: string;
  onMoveCard(dragIndex: number, hoverIndex: number): void;
  onEndDrag(): void;
  onRemove(index: number): void;
  onTarget: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const ImageItem: FC<ImageItemProps> = props => {
  const { image, index, onMoveCard, onEndDrag, onRemove, onTarget } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'image',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // не заменяем items сами на себя
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Получаем среднее по ординате Y
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // положение мыши
      const clientOffset = monitor.getClientOffset();

      // вычисляем пиксели в верхней части
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Перетаскивание вниз
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Перетаскивание вверх
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onMoveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'image',
    item: () => {
      return { index, image };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      onEndDrag();
    },
  });

  const handleRemove = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      onRemove(index);
    },
    [index]
  );

  const handleDoubleClick = useCallback(() => {
    onTarget(image);
  }, [image]);

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <ImageItemWrapper
      ref={ref}
      data-handler-id={handlerId}
      style={{ opacity }}
      onDoubleClick={handleDoubleClick}
    >
      <BadgeRemoveIcon onClick={handleRemove}>
        <CloseIcon
          fontSize={'inherit'}
          color={'inherit'}
        />
      </BadgeRemoveIcon>
      <StyledImage
        src={image}
        alt=''
      />
    </ImageItemWrapper>
  );
};

const ImageItemContent: FC<Pick<ImageItemProps, 'image' | 'onTarget'>> = props => {
  const { image, onTarget } = props;

  const handleDoubleClick = useCallback(() => {
    onTarget(image);
  }, [image]);

  return (
    <ImageItemWrapper
      onClick={handleDoubleClick}
      style={{ cursor: 'pointer' }}
    >
      <StyledImage
        src={image}
        alt=''
      />
    </ImageItemWrapper>
  );
};

const Container: FCC<{ readOnly: boolean }> = ({ children, readOnly }) => {
  if (readOnly) {
    return <ImageGridContainer>{children}</ImageGridContainer>;
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <ImageGridContainer>{children}</ImageGridContainer>
    </DndProvider>
  );
};

const ImageGrid: FC<ImageGridProps> = props => {
  const { images, onChange, readOnly = false } = props;

  const [items, setItems] = useState<FileDescription[]>(images ?? []);
  const [isEndDrag, setIsEndDrag] = useState<boolean>(false);
  const [targetImage, setTargetImage] = useState<string>();

  useEffect(() => {
    if (images && difference(images, items).length > 0) {
      setItems(images);
    }
  }, [images]);

  useEffect(() => {
    if (isEndDrag) {
      onChange?.(items, images);
      setIsEndDrag(false);
    }
  }, [isEndDrag, items, images]);

  const handleEndDrag = useCallback(() => {
    // Items внутри этой функции будет всегда [], т.к. кэшируется в хуке useDrag
    // из-за этого вызов onChange сделан через второй useEffect
    setIsEndDrag(true);
  }, []);

  const handleMoveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setItems((prevImages: FileDescription[]) => {
      // создаём копию
      const imageItems = Array.from(prevImages);
      // получаем текущий
      const target = imageItems[dragIndex];

      // удаляем выбранный элемент
      imageItems.splice(dragIndex, 1);

      // вставляем выбранный элемент на позицию в которой находиться курсор
      imageItems.splice(hoverIndex, 0, target);

      return imageItems;
    });
  }, []);

  const handleRemove = useCallback((index: number) => {
    setItems((prevImages: FileDescription[]) => {
      const imageItems = Array.from(prevImages);
      // удаляем выбранный элемент
      imageItems.splice(index, 1);
      setIsEndDrag(true);
      return imageItems;
    });
  }, []);

  const handleCloseImageView = useCallback(() => {
    setTargetImage(undefined);
  }, []);

  if (!images?.length) {
    return null;
  }

  const renderImage = (image: FileDescription, index: number) =>
    readOnly ? (
      <ImageItemContent
        key={image.path}
        image={image.path}
        onTarget={setTargetImage}
      />
    ) : (
      <ImageItem
        key={image.path}
        index={index}
        image={image.path}
        onMoveCard={handleMoveCard}
        onEndDrag={handleEndDrag}
        onRemove={handleRemove}
        onTarget={setTargetImage}
      />
    );
  return (
    <>
      <Container readOnly={readOnly}>{items.map(renderImage)}</Container>

      <ViewImage
        open={!!targetImage}
        src={targetImage!}
        onClick={handleCloseImageView}
      />
    </>
  );
};

export default ImageGrid;
