import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Nullable } from '@/domain/model/types';
import ConfirmDialog from '@/presentation/components/common/dialogs/confirm';
import { MPButton } from '@/presentation/theme/ui-kit/button';
import { ClientOrg } from '@/domain/model/clientOrg';
import { ContentLimiter } from '@/presentation/components/common/contentLimit';
import { ContentLimiterHintLayout } from '@/presentation/components/common/contentLimit/layouts';
import { useState } from 'react';
import ContentLoader from '@/presentation/components/common/loader';
import { fileTypes } from '@/presentation/constants';
import FileInfo from '@/presentation/components/common/files/info';
import FileUploader, { ActiveUpload } from '@/presentation/components/common/files/uploader';
import { AppFile } from '@/domain/model';

export interface ClientOrgImportUsersDialogProps {
  readonly clientOrg: Nullable<ClientOrg>;
  readonly isFetching: boolean;
  readonly onImport: (clientOrg: ClientOrg, file: ClientOrgUserFileData) => void;
  readonly onClose: () => void;
}

export type ClientOrgUserFileData = { file: File; appFile: AppFile };

export const ClientOrgUserImportDialog = (props: ClientOrgImportUsersDialogProps) => {
  const { clientOrg, isFetching, onClose, onImport } = props;

  const [data, setData] = useState<Nullable<ClientOrgUserFileData>>(null);

  const onHandleUploadUpload = (appFile: AppFile, binFiles: ActiveUpload[]) => {
    if (binFiles.length !== 0) {
      setData({ file: binFiles[0].file, appFile });
    }
  };

  const onHandleImport = () => {
    if (clientOrg && data) {
      onImport(clientOrg, data);
    }
  };

  if (!clientOrg) {
    return null;
  }

  return (
    <ConfirmDialog
      open
      onClose={onClose}
      title={'Добавить пользователей'}
      dialogButtons={
        data && (
          <Grid
            container
            spacing={3}
          >
            <Grid item>
              <MPButton
                fullWidth={false}
                onClick={onHandleImport}
                disabled={isFetching}
              >
                Внести в систему
                {isFetching && <ContentLoader />}
              </MPButton>
            </Grid>
            <Grid item>
              <MPButton
                variant='outlined'
                fullWidth={false}
                onClick={onClose}
              >
                Отмена
              </MPButton>
            </Grid>
          </Grid>
        )
      }
    >
      <Grid
        container
        spacing={2}
        wrap='nowrap'
        direction='column'
      >
        <Grid item>
          {!data && (
            <ContentLimiter
              layout={ContentLimiterHintLayout}
              layoutProps={{
                collapsedTitle: <>Пользователей необходимо загрузить согласно требованиям</>,
                expandedTitle: <b>Пользователей необходимо загрузить согласно требованиям</b>,
              }}
            >
              <p>Загрузите файл сотрудников, соответствующий требованиям:</p>
              <ul>
                <li>формат: xls;</li>
                <li>размер: не более 1 МБ;</li>
                <li>Файл xls должен содержать 1 столбец с перечнем электронных адресов;</li>
                <li>
                  Необходимо загрузить всех новых и текущих пользователей. Доступы пользователей будут приостановлены,
                  если в файле будут отсутствовать их почтовые электронные адреса.
                </li>
              </ul>
              <p>В случае несоответствия требованиям, файл не будет загружен, появится сообщение об ошибке</p>
            </ContentLimiter>
          )}
        </Grid>
        {data ? (
          <Grid item>
            <FileInfo
              id={data.appFile.path}
              onRemove={() => setData(null)}
            />
          </Grid>
        ) : (
          <Grid item>
            <FileUploader
              text={'Загрузить'}
              onUpload={onHandleUploadUpload}
              fileTypes={[fileTypes.xls.mimeType, fileTypes.xlsx.mimeType]}
              accept={[fileTypes.xls.ext, fileTypes.xlsx.ext]}
            />
          </Grid>
        )}
        <Grid item>
          {data && (
            <Typography>
              <b>Внимание!</b> После изменения списка новые и удаленные пользователи получат рассылки об изменении
              доступа
            </Typography>
          )}
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
};
