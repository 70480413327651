import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { ProductOffer } from '../../../../../../domain/model/productOffer';
import ConfirmDialog from '../../../../../components/common/dialogs/confirm';
import ContentLoader from '../../../../../components/common/loader';
import { MPButton } from '../../../../../theme/ui-kit/button';
import { MPNumberInput } from '../../../../../theme/ui-kit/input';
import { formatCost } from '../../../../../utils';
import { locale } from '../../../../../../locale';

interface ProductChangePriceDialogProps {
  readonly productOffer: ProductOffer;
  readonly isExecuting: boolean;
  readonly onExecute: (price: number, originalPrice: ProductOffer['originalPrice']) => void;
  readonly onClose: () => void;
}

const ProductChangePriceDialog = (props: ProductChangePriceDialogProps) => {
  const { productOffer, isExecuting, onExecute, onClose } = props;

  const [localValue, setLocalValue] = useState<[ProductOffer['price'], ProductOffer['originalPrice']]>([null, null]);

  const onInternalChange = () => {
    if (localValue[0]) {
      onExecute(localValue[0], localValue[1]);
    }
  };

  return (
    <ConfirmDialog
      open
      onClose={onClose}
      title='Изменение цены'
      dialogButtons={
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              disabled={isExecuting}
              onClick={onInternalChange}
            >
              Сохранить
              {isExecuting && <ContentLoader />}
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              fullWidth={false}
              onClick={onClose}
            >
              Отмена
              {isExecuting && <ContentLoader />}
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        spacing={2}
        wrap='nowrap'
        direction='column'
      >
        <Grid item>
          <Typography>{productOffer.name}</Typography>
          <Typography>Текущая цена: {formatCost(productOffer.price ?? 0)}</Typography>
          <Typography>Текущая цена до скидки: {formatCost(productOffer.originalPrice ?? 0)}</Typography>
        </Grid>
        <Grid item>
          <MPNumberInput
            label={`Новая цена, ${locale.currency}`}
            value={localValue[0]}
            disabled={isExecuting}
            allowNegative={false}
            isNumericString
            decimalScale={0}
            onChange={(event, newValue) => setLocalValue(prevValue => [newValue, prevValue[1]])}
          />
        </Grid>
        <Grid item>
          <MPNumberInput
            label={`Новая цена до скидки, ${locale.currency}`}
            value={localValue[1]}
            disabled={isExecuting}
            allowNegative={false}
            isNumericString
            decimalScale={0}
            onChange={(event, newValue) => setLocalValue(prevValue => [prevValue[0], newValue])}
          />
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
};

export default ProductChangePriceDialog;
