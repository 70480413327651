import React from 'react';
import { UseProductOrderTable } from './hooks/useTable';
import { UseProductOrderTableConfig } from './hooks/useTableConfig';

export type ProductOrderTableContextValue = UseProductOrderTable;

export const ProductOrderTableContext = React.createContext<ProductOrderTableContextValue>(
  {} as ProductOrderTableContextValue
);

export type ProductOrderTableConfigContextValue = UseProductOrderTableConfig;

export const ProductOrderTableConfigContext = React.createContext<ProductOrderTableConfigContextValue>(
  {} as ProductOrderTableConfigContextValue
);
