import { BookingOffer, CorpOffer, CorpOfferShort, EOfferStatus, TradeOffer, TradeOfferShort } from '@/domain';
import { PanelAction, PanelActions, PanelActionsExecutable } from '../../../types';
import { EOfferInfoPanelType } from './enums';

export enum EOfferActionType {
  /** Опубликовать */
  Publish = 'publish',
  /** Снять с публикации */
  UnPublish = 'unPublish',
  /** Вернуть в предыдущий статус */
  Resume = 'resume',
  /** Утвердить */
  Approve = 'approve',
  /** Отклонить */
  Reject = 'reject',
  /** Приостановить */
  Pause = 'pause',
  /** Архивировать */
  Archive = 'archive',
  /** Удалить */
  Delete = 'delete',
  ChangeApprovalRegistry = 'changeApprovalRegistry',
  ChangeResponsiblePerson = 'changeResponsiblePerson',
  /** Назначить модератора */
  ChangeModerator = 'changeModerator',
  /** Редактировать */
  Edit = 'edit',
  /** Создать новую версию */
  SaveNewVersion = 'saveNewVersion',
  UnPublishAndEdit = 'unPublishAndEdit',
  CreateCodes = 'createCodes',
  DeleteCodes = 'deleteCodes',
  DownloadCodes = 'downloadCodes ',
  InWork = 'inWork',
  Duplicate = 'duplicate',
  Retrieve = 'retrieve',
  ReturnToVerification = 'returnToVerification',
}

export type OfferActionTableType =
  | EOfferActionType.Delete
  | EOfferActionType.Resume
  | EOfferActionType.Pause
  | EOfferActionType.Reject
  | EOfferActionType.Archive
  | EOfferActionType.ChangeApprovalRegistry
  | EOfferActionType.ChangeModerator
  | EOfferActionType.Retrieve
  | EOfferActionType.Duplicate
  | EOfferActionType.Approve
  | EOfferActionType.ReturnToVerification;

export type OfferActionTableCommonType = OfferActionTableType;

export type OfferActionTableCollectionType = Exclude<
  OfferActionTableType,
  | EOfferActionType.Delete
  | EOfferActionType.Resume
  | EOfferActionType.Pause
  | EOfferActionType.Reject
  | EOfferActionType.Archive
  | EOfferActionType.ChangeModerator
  | EOfferActionType.Retrieve
  | EOfferActionType.Duplicate
  | EOfferActionType.ReturnToVerification
>;

export type OfferActionCreateType =
  | EOfferActionType.Publish
  | EOfferActionType.Resume
  | EOfferActionType.Edit
  | EOfferActionType.SaveNewVersion
  | EOfferActionType.Delete
  | EOfferActionType.CreateCodes
  | EOfferActionType.DeleteCodes
  | EOfferActionType.DownloadCodes
  | EOfferActionType.ChangeModerator
  | EOfferActionType.ChangeResponsiblePerson;

export type OfferActionDetailsType =
  | EOfferActionType.Resume
  | EOfferActionType.Pause
  | EOfferActionType.Archive
  | EOfferActionType.Approve
  | EOfferActionType.Reject
  | EOfferActionType.UnPublish
  | EOfferActionType.CreateCodes
  | EOfferActionType.DeleteCodes
  | EOfferActionType.DownloadCodes
  | EOfferActionType.Edit
  | EOfferActionType.UnPublishAndEdit
  | EOfferActionType.InWork
  | EOfferActionType.Duplicate
  | EOfferActionType.ChangeResponsiblePerson
  | EOfferActionType.Retrieve
  | EOfferActionType.ReturnToVerification
  | EOfferActionType.ChangeModerator;

export type OfferActionPublishType = EOfferActionType.Reject | EOfferActionType.Approve;

export type OfferActionReasonRequiredType = EOfferActionType.Reject | EOfferActionType.Pause;

export type ActionOfferType = TradeOfferShort | CorpOfferShort;

export type OfferActionType =
  | OfferActionTableType
  | OfferActionCreateType
  | OfferActionDetailsType
  | OfferActionTableCollectionType;

export type OfferAction<A extends string = EOfferActionType, P = any> = PanelAction<A, P>;

export type OfferActions<A extends string = EOfferActionType> = PanelActions<A>;

export type OfferActionsExecutable<
  T extends ActionOfferType,
  A extends string = EOfferActionType
> = PanelActionsExecutable<T, A>[];

export enum EOfferTableTab {
  Upcoming = 'upcoming',
  Moderation = 'moderation',
  Active = 'active',
  Rejected = 'rejected',
  Paused = 'paused',
  Archived = 'archived',
  Draft = 'draft',
}

export interface OfferActionProcess<Type = EOfferActionType> {
  readonly isFetching: boolean;
  readonly type: Type;
}

export type InfoPanelOfferType = TradeOffer | CorpOffer | BookingOffer;

export type OfferInfoPanelCreateType =
  | EOfferInfoPanelType.PartnerInactive
  | EOfferInfoPanelType.TradeRestrictAccess
  | EOfferInfoPanelType.CorpRestrictAccess
  | EOfferInfoPanelType.BookingRestrictAccess
  | EOfferInfoPanelType.Rejected
  | EOfferInfoPanelType.Paused;

export type OfferInfoPanelDetailsType =
  | EOfferInfoPanelType.PartnerInactive
  | EOfferInfoPanelType.Rejected
  | EOfferInfoPanelType.Paused;

export enum EOfferTableColumn {
  Code = 'code',
  Name = 'name',
  SortIndex = 'sortIndex',
  EndDate = 'endDate',
}

export enum EOfferTableFilterItem {
  Code = 'code',
  Name = 'name',
  SortIndex = 'sortIndex',
}

export type OfferCounterByStatus = {
  [name in EOfferStatus]?: number;
};
