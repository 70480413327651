import { useSelector } from 'react-redux';
import useHistoryExtensions from '../../../../hooks/useHistoryExtensions';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import { EOfferTableTab } from '@features/general/offer/types';
import { getOfferTableTabForStatus } from '@features/general/offer/utils/table';
import { useBookingOfferActionsSelectors } from '../../actions/useActions';
import { getBookingOffersTableRoute } from '../../entry';
import { EBookingActionType } from '../../types';
import { useContextLifecycle } from '../hooks/useContextLifecycle';
import { useContextParams } from '../hooks/useContextParams';
import { bookingOfferDetailsBookingOfferSelector, bookingOfferDetailsIsPreloadingSelector } from '../store/selectors';
import { BookingMasterHeader } from '../../components/masterHeader';

const BookingOfferDetailsHeaderAdapter = () => {
  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const {
    id,
    access: { viewAsAdmin },
  } = useContextParams();
  const { actions, lifecycle, onAction } = useContextLifecycle();
  const { getIsOfferProcessingSelector } = useBookingOfferActionsSelectors();

  const { offerStatuses } = useSelector(nsiDataSelector);
  const bookingOffer = useSelector(bookingOfferDetailsBookingOfferSelector);
  const isLoading = useSelector(bookingOfferDetailsIsPreloadingSelector);
  const isUpdating = useSelector(getIsOfferProcessingSelector(id));

  const isFetching = isLoading || isUpdating;

  const isModerator = lifecycle.userId === bookingOffer?.approvingAdmin?.id;

  const offerStatusText = bookingOffer ? offerStatuses.find(s => s.id === bookingOffer.status)?.name : '';

  const onClose = () => {
    const defaultTab = viewAsAdmin ? EOfferTableTab.Moderation : EOfferTableTab.Draft;
    const tab = bookingOffer?.status ? getOfferTableTabForStatus(bookingOffer?.status) : defaultTab;
    gotoPrevIndependentLocation(getBookingOffersTableRoute({ tab }));
  };

  const listActions = actions.filter(action => [EBookingActionType.InWork].includes(action.type)) ?? null;

  return (
    <BookingMasterHeader
      offerStatusText={offerStatusText}
      isDisabledAll={isFetching}
      actions={listActions}
      bookingOffer={bookingOffer}
      onAction={onAction}
      onClose={onClose}
      isModerator={isModerator}
    />
  );
};

export default BookingOfferDetailsHeaderAdapter;
