import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClientOrg } from '../../../../../domain/model/clientOrg';
import { UUID } from '../../../../../domain/model/types';
import ContentLoader from '../../../../components/common/loader';
import useBreadcrumbsActions from '../../../general/breadcrumbs/useBreadcrumbsActions';
import ClientOrgManagementMenuPanel from '../menu';
import { ContentWrapper, Wrapper } from './controls';
import { clientOrgManagementContainerGetClientOrg, clientOrgManagementContainerIsMenuVisible } from './store/selectors';
import { clientOrgManagementClientOrgByIdFetch } from './store/slice';

interface ClientOrgManagementContainerProps {
  readonly id: UUID;
  readonly children: (clientOrg: ClientOrg) => React.ReactNode;
}

const ClientOrgManagementContainer = ({ id, children }: ClientOrgManagementContainerProps) => {
  const dispatch = useDispatch();
  const { putHeadBreadcrumbs } = useBreadcrumbsActions();

  const isMenuVisible = useSelector(clientOrgManagementContainerIsMenuVisible);
  const { data: clientOrg, isFetching, needRefreshWatcher } = useSelector(clientOrgManagementContainerGetClientOrg);

  useEffect(() => {
    const promise = dispatch(clientOrgManagementClientOrgByIdFetch(id));
    return () => {
      promise?.abort();
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (needRefreshWatcher > 0) {
      const promise = dispatch(clientOrgManagementClientOrgByIdFetch(id));
      return () => {
        promise?.abort();
      };
    }
  }, [dispatch, id, needRefreshWatcher]);

  useEffect(() => {
    if (clientOrg) {
      putHeadBreadcrumbs([
        {
          label: 'Компании-клиенты',
        },
        {
          label: clientOrg.name,
        },
      ]);
      return () => {
        putHeadBreadcrumbs([]);
      };
    }
  }, [clientOrg]);

  return (
    <Wrapper>
      {clientOrg && (
        <>
          <ClientOrgManagementMenuPanel
            visible={isMenuVisible}
            clientOrg={clientOrg}
          />
          <ContentWrapper fullscreen={false}>{children(clientOrg)}</ContentWrapper>
        </>
      )}
      {isFetching && <ContentLoader size={75} />}
    </Wrapper>
  );
};

export default ClientOrgManagementContainer;
