import { combineReducers } from '@reduxjs/toolkit';
import edit, { BookingServicePriceUnitsEditState } from './edit/store/slice';

interface BookingServicePriceUnitsState {
  edit: BookingServicePriceUnitsEditState;
}

export default combineReducers<BookingServicePriceUnitsState>({
  edit,
});
