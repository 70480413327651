import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EFilterMultiSelectorValueType } from 'domain/model/enums';
import { DataFilterStrategyRoadsSelector } from 'domain/model/filter';
import { RzhdRoad } from 'domain/model/orgStructure';
import { Nullable } from 'domain/model/types';
import OrgStructureRoadMultipleTypesSelector from '../../../../orgStructure/road/selector/multipleTypes';
import {
  MultipleSelectorSelectAllType,
  OrgStructureRoadMultipleSelectType,
} from '../../../../orgStructure/road/selector/multipleTypes/types';
import { DataFilterEditItemProps } from '../index';

type ValueType<T extends string> = DataFilterStrategyRoadsSelector<T>['value'];

interface DataFilterEditItemRoadMultipleTypesProps<T extends string>
  extends DataFilterEditItemProps<T, Nullable<ValueType<T>>> {
  readonly strategy: DataFilterStrategyRoadsSelector<T>;
}

function DataFilterEditItemRoadMultipleTypes<T extends string>({
  strategy,
  onChange,
}: DataFilterEditItemRoadMultipleTypesProps<T>) {
  const { label, value, readOnly, selectNone = true } = strategy;

  const targetRoads = value ?? { select: EFilterMultiSelectorValueType.In, in: null };

  const select =
    targetRoads.select === EFilterMultiSelectorValueType.All
      ? OrgStructureRoadMultipleSelectType.All
      : targetRoads.select === EFilterMultiSelectorValueType.None
      ? OrgStructureRoadMultipleSelectType.None
      : null;

  const onChangeRoads = (newValue: Nullable<RzhdRoad[]>) => {
    if (newValue?.length) {
      onChange(strategy, { select: EFilterMultiSelectorValueType.In, in: newValue });
    } else {
      onChange(strategy, null);
    }
  };

  const onSelect = (type: OrgStructureRoadMultipleSelectType) => {
    switch (type) {
      case OrgStructureRoadMultipleSelectType.All:
        onChange(strategy, { select: EFilterMultiSelectorValueType.All, in: null });
        break;
      case OrgStructureRoadMultipleSelectType.None:
        onChange(strategy, { select: EFilterMultiSelectorValueType.None, in: null });
        break;
    }
  };

  return (
    <OrgStructureRoadMultipleTypesSelector
      selectNone={selectNone}
      label={label}
      color='primary'
      selectAllType={MultipleSelectorSelectAllType.Flag}
      select={select}
      disabled={readOnly}
      roads={targetRoads.in ?? []}
      limitTags={2}
      popupIcon={<ExpandMoreIcon fontSize='small' />}
      onChange={onChangeRoads}
      onSelect={onSelect}
    />
  );
}

export default DataFilterEditItemRoadMultipleTypes;
