import { Divider as MuiDivider } from '@mui/material';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${p => p.theme.spacing()};
`;

export const Divider = styled(MuiDivider)`
  margin: ${p => p.theme.spacing(2)} 0;
`;
