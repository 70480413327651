import AdCampaignCreateFieldType from '@features/adCampaign/create/fields/type';
import { AdCampaignCreateFieldCommonProps } from '@features/adCampaign/types';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import { useSelector } from 'react-redux';

const fieldName = 'type';

export const AdCampaignCreateFieldTypeAdapter = (props: AdCampaignCreateFieldCommonProps<typeof fieldName>) => {
  const { adCampaignTypes } = useSelector(nsiDataSelector);

  return (
    <AdCampaignCreateFieldType
      {...props}
      types={adCampaignTypes}
    />
  );
};