import { ApiCancellable, ApiQueryDsl, ApiRequestListDiscriminator, ApiRequestPageable } from '@/data/api/types';
import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '@/data/api/utils';
import { Pageable } from '@/domain/model';
import { CorpOfferActivation } from '@/domain/model/activation';
import { EOfferActivationDiscriminator, EOfferActivationStatus } from '@/domain/model/enums';
import { DataFilterQueryDslOperator } from '@/domain/model/filter';
import { Nullable, UUID } from '@/domain/model/types';
import Axios from 'axios';
import Api from '../../../../data/api';
import { PaginationSize } from '../../../types';
import { CorpActivationCounterByStatus, ECorpActivationTableTab } from '../types';
import { CorpActivationsFilterValues, ECorpActivationsFilterItem } from '../utils/filter';
import { CorpActivationTableTabsCounter, getCorpActivationStatusesByTableTab } from '../utils/table';

const activationDiscriminator = EOfferActivationDiscriminator.CorpOffer;

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: string;
    readonly statuses: EOfferActivationStatus[];
    readonly partnerId: Nullable<UUID>;
    readonly customerId: Nullable<UUID>;
  };
  readonly filter: CorpActivationsFilterValues;
  readonly pageNumber: number;
};

type BuildListQueryParamsReturn<D extends ApiRequestListDiscriminator> = ApiCancellable &
  ApiRequestPageable & {
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

export type CountProps = Omit<AllProps, 'pageNumber'>;

type CountsByStatusesProps = CountProps;

type CountsByTabsProps = CountProps & {
  readonly tabs: ECorpActivationTableTab[];
};

export type CorpActivationActivationService = {
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps
  ) => BuildListQueryParamsReturn<D>;
  readonly all: (props: AllProps) => Promise<Pageable<CorpOfferActivation>>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly countsByStatuses: (props: CountsByStatusesProps) => Promise<{
    counts: CorpActivationCounterByStatus;
    errors: Array<string>;
  }>;
  readonly countsByTabs: (props: CountsByTabsProps) => Promise<{
    counts: CorpActivationTableTabsCounter;
    errors: Array<string>;
  }>;
  readonly give: (id: UUID) => Promise<CorpOfferActivation>;
  readonly unGive: (id: UUID) => Promise<CorpOfferActivation>;
};

const service: CorpActivationActivationService = {
  buildListQueryParams: props => {
    const { search, filter, pageNumber, signal } = props;
    const { pageSize, sort, statuses, partnerId, customerId } = search;

    const querydsl: ApiQueryDsl = [];
    const query = filter[ECorpActivationsFilterItem.Query]?.value;
    const filterDsl = getQueryDslByDataFilterValues(filter);

    if (filterDsl) {
      querydsl.push(...filterDsl);
    }

    if (partnerId) {
      querydsl.push({
        value: [partnerId],
        field: 'offer.partner.id',
        operator: DataFilterQueryDslOperator.In,
      });
    }

    if (customerId) {
      querydsl.push({
        value: [customerId],
        field: 'customer.id',
        operator: DataFilterQueryDslOperator.In,
      });
    }

    if (statuses.length > 0) {
      querydsl.push({
        value: statuses,
        field: 'status',
        operator: DataFilterQueryDslOperator.In,
      });
    }

    return {
      query,
      sort,
      signal,
      pageSize,
      page: pageNumber,
      querydsl,
    };
  },
  all: async props => {
    const response = await Api.activation.all({ ...service.buildListQueryParams(props), activationDiscriminator });
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);
    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
  count: async props => {
    const { data: response } = await Api.activation.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      activationDiscriminator,
      discriminator: ApiRequestListDiscriminator.Count,
    });

    return response[0].count;
  },
  countsByStatuses: async ({ signal, ...props }) => {
    const {
      search: { statuses },
    } = props;

    const errors: string[] = [];
    const counts: CorpActivationCounterByStatus = {};

    const requests = statuses.map(status => {
      const params = {
        ...props,
        search: { ...props.search, statuses: [status] },
      };
      return service.count({ ...params, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], status: EOfferActivationStatus) => {
      if (response.status === 'fulfilled') {
        counts[status] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество активаций '${status}': ${response.reason}`);
        }
      }
    };

    statuses.forEach((status, index) => parseResponse(responses[index], status));

    return { counts, errors };
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: CorpActivationTableTabsCounter = {};

    const requests = tabs.map(tab => {
      const statuses = getCorpActivationStatusesByTableTab(tab);
      const params = {
        ...props,
        search: { ...props.search, statuses },
      };
      return service.count({ ...params, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], tab: ECorpActivationTableTab) => {
      if (response.status === 'fulfilled') {
        counts[tab] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество активаций '${tab}': ${response.reason}`);
        }
      }
    };

    tabs.forEach((tab, index) => parseResponse(responses[index], tab));

    return { counts, errors };
  },
  give: async id => {
    return (await Api.activation.corp.give({ id })).data;
  },
  unGive: async id => {
    return (await Api.activation.corp.unGive({ id })).data;
  },
};

export default service;
