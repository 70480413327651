import AdCampaignCreateFieldOffers from '@features/adCampaign/create/fields/offers';
import {
  adCampaignCreateAdCampaignSelector,
  adCampaignCreateGuidSelector,
} from '@features/adCampaign/create/store/selectors';
import { AdCampaignCreateFieldCommonProps } from '@features/adCampaign/types';
import { useSelector } from 'react-redux';

const fieldName = 'offers';

export const AdCampaignCreateFieldOffersAdapter = (props: AdCampaignCreateFieldCommonProps<typeof fieldName>) => {
  const guid = useSelector(adCampaignCreateGuidSelector);
  const adCampaign = useSelector(adCampaignCreateAdCampaignSelector);

  return (
    <AdCampaignCreateFieldOffers
      {...props}
      guid={guid as any}
      adCampaign={adCampaign}
    />
  );
};