import { ContentTarget } from '@/domain';
import { Checkbox } from '@components/common/checkboxPanel/controls';
import { FormControlLabel, FormHelperText, Typography } from '@mui/material';
import { ContentTargetEditComponent } from '../../types';

const attribute: keyof ContentTarget = 'externalUsers';

const ContentTargetEditExternalUsers: ContentTargetEditComponent = props => {
  const { target, validation, onChangeAttribute } = props;

  const value = target[attribute] ?? false;

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            color='primary'
            checked={value}
            onChange={(event, checked) => onChangeAttribute(attribute, checked)}
          />
        }
        label={<Typography>Внешние пользователи</Typography>}
      />
      {validation?.[attribute]?.message && (
        <FormHelperText error={validation?.[attribute]?.hasError}>{validation?.[attribute]?.message}</FormHelperText>
      )}
    </>
  );
};

export default ContentTargetEditExternalUsers;
