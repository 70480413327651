import { common as commonRules, corp as corpRules } from './rules';
import * as schemas from './scheme';
import { CorpOfferLifeCycleRulesConfiguration, TradeOfferLifeCycleRulesConfiguration } from './types';

/**
 * конфигурация правил админа
 */
export const commonAdminConfiguration: TradeOfferLifeCycleRulesConfiguration = {
  statusScheme: schemas.trade.adminStatusScheme.statusScheme,
  actionsScheme: schemas.trade.adminStatusScheme.actionsScheme,
  actionRules: [
    commonRules.removeEditActionForModerationRule,
    // commonRules.removeEditActionForFreeOfferCountRule,
    commonRules.disableModerateActionsRule,
    commonRules.disableChangeModeratorActionRule,
    commonRules.removeEditCodesActionRule,
    commonRules.removeDownloadCodesActionRule,
    // commonRules.removeActivationActionsRule,
  ],
  changeCodeStatusRules: [],
};

/**
 * конфигурация правил партнёра
 */
export const commonPartnerConfiguration: TradeOfferLifeCycleRulesConfiguration = {
  statusScheme: schemas.trade.partnerStatusScheme.statusScheme,
  actionsScheme: schemas.trade.partnerStatusScheme.actionsScheme,
  actionRules: [
    commonRules.removeEditActionForModerationRule,
    //commonRules.removeEditActionForFreeOfferCountRule,
    commonRules.removeEditCodesActionRule,
    commonRules.removeDownloadCodesActionRule,
    commonRules.removeResumePartnerActionRule,
    commonRules.removeDeleteActionRule,
    // commonRules.removeActivationActionsRule,
    commonRules.disableRetrieveActionRule,
    commonRules.disableUnPublishActionRule,
  ],
  changeCodeStatusRules: [],
};

/**
 * конфигурация правил ТП партнёра
 */
export const tradePartnerConfiguration: TradeOfferLifeCycleRulesConfiguration = {
  ...commonPartnerConfiguration,
  statusScheme: schemas.trade.partnerStatusScheme.statusScheme,
  actionsScheme: schemas.trade.partnerStatusScheme.actionsScheme,
  actionRules: [...(commonPartnerConfiguration.actionRules as any), commonRules.disableResumePartnerActionRule],
};

/**
 * конфигурация правил КП админа
 */
export const corpAdminConfiguration: CorpOfferLifeCycleRulesConfiguration = {
  ...commonAdminConfiguration,
  statusScheme: schemas.corp.adminStatusScheme.statusScheme,
  actionsScheme: schemas.corp.adminStatusScheme.actionsScheme,
  actionRules: [
    ...(commonAdminConfiguration.actionRules as any),
    corpRules.disableActivationActionsRule,
    corpRules.renameChangeApprovalRegistryActionRule,
    corpRules.disableChangeApprovalRegistryActionRule,
    commonRules.disableRetrieveActionRule,
    commonRules.removeEditActionForFreeOfferCountRule,
    commonRules.removeActivationActionsRule,
  ],
  changeCodeStatusRules: [corpRules.adminChangeCodeStatusRule],
};

/**
 * конфигурация правил КП партнёра
 */
export const corpPartnerConfiguration: CorpOfferLifeCycleRulesConfiguration = {
  ...commonPartnerConfiguration,
  statusScheme: schemas.corp.partnerStatusScheme.statusScheme,
  actionsScheme: schemas.corp.partnerStatusScheme.actionsScheme,
  actionRules: [
    ...(commonPartnerConfiguration.actionRules as any),
    corpRules.disableActivationActionsRule,
    corpRules.renameChangeApprovalRegistryActionRule,
    corpRules.disableChangeApprovalRegistryActionRule,
    corpRules.removeResumeActionRule,
    commonRules.disableResumePartnerActionRule,
    commonRules.removeEditActionForFreeOfferCountRule,
    commonRules.removeActivationActionsRule,
  ],
  changeCodeStatusRules: [corpRules.partnerChangeCodeStatusRule],
};
