import { Grid, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Nullable } from '../../../../../../domain/model/types';
import AppBreadcrumbs from '../../../../../components/common/breadcrumbs';
import MenuButton from '../../../../../components/common/buttons/menu';
import FileDownloadLink from '../../../../../components/common/files/download/link';
import DefaultHeader from '../../../../../components/common/header';
import { analyticsQueriesFilterSelector, analyticsQueriesSortSelector } from '../store/selectors';
import {EAnalyticsQueriesTableTab, getQueryAnalyticsDownloadUrl} from '../utils';

interface AnalyticsQueriesTableHeaderAdapterProps {
  readonly tab: EAnalyticsQueriesTableTab;
  readonly canDownload?: boolean;
}

export const AnalyticsQueriesTableHeaderAdapter = (props: AnalyticsQueriesTableHeaderAdapterProps) => {
  const { tab, canDownload } = props;

  const [menuCloseMarker, setMenuCloseMarker] = useState<Nullable<symbol>>(null);
  const sort = useSelector(analyticsQueriesSortSelector);
  const filter = useSelector(analyticsQueriesFilterSelector);

  const showMenu = canDownload;

  const downloadUrl = getQueryAnalyticsDownloadUrl({
    sort,
    skipPageable: true,
    tab,
    filter,
  });

  return (
    <DefaultHeader
      headline={<AppBreadcrumbs />}
      actions={
        showMenu && (
          <Grid
            container
            spacing={1}
            alignItems='center'
          >
            {showMenu && (
              <Grid item>
                <MenuButton
                  id={uuidv4()}
                  closeMarker={menuCloseMarker}
                >
                  {canDownload && (
                    <MenuItem>
                      <FileDownloadLink
                        src={downloadUrl}
                        component={'div'}
                        onDownload={() => setMenuCloseMarker(Symbol())}
                      >
                        Скачать результат
                      </FileDownloadLink>
                    </MenuItem>
                  )}
                </MenuButton>
              </Grid>
            )}
          </Grid>
        )
      }
    >
      <Typography variant='h2'>Поисковые запросы</Typography>
    </DefaultHeader>
  );
};
