import styled from '@emotion/styled';

interface ActionsWrapperProps {
  readonly opacity?: number;
  readonly children?: any;
}

export const ActionsWrapper = styled(({ opacity, ...others }: ActionsWrapperProps) => <div {...others} />)`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  align-self: flex-end;

  padding: ${p => p.theme.spacing(2.5)};

  background-color: ${p => p.theme.palette.background.default};
  opacity: ${p => p.opacity};
`;
