import { XLSXImportError } from '../../domain/model';

export interface DownloadXLSXImportErrorsProps {
  readonly errors: XLSXImportError[];
  readonly fileName?: string;
  readonly separator?: string;
}

export const downloadXLSXImportErrors = (props: DownloadXLSXImportErrorsProps) => {
  const { errors, fileName = 'errorsLog.csv', separator = ';' } = props;

  const header = ['N строки', 'Наименование колонки', 'Описание'].join(separator);
  const csvArray = errors.map(error => [error.stringNumber, error.columnName, error.descr].join(separator));
  csvArray.unshift(header);

  const universalBOM = '\uFEFF';
  const csv = universalBOM + csvArray.join('\r\n');

  const blob = new Blob([csv], {
    type: 'text/csv;charset=utf-8',
  });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
