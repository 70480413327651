//правила технической валидации (формат ввода)
import validator from 'validator';
import { BookingOfferContact, BookingOfferResponsiblePerson } from '../../../../domain/model/booking';
import { EOfferServiceStatus, EOfferStatus } from '../../../../domain/model/enums';
import { Nullable } from '../../../../domain/model/types';
import {
  EValidationMessages,
  EValidationType,
  ValidationCollectionRules,
  ValidationItemResult,
  ValidationRules,
} from '../../../utils/validation';
import { BookingOfferValidationRules } from '../create/types';
import { BookingOfferServiceView, BookingOfferView, BookingServicePriceItemValidateView } from '../types';

const emailValidation = (value: Nullable<string>): Nullable<ValidationItemResult> => {
  if (!value) return null;

  const isEmail = validator.isEmail(value);
  if (!isEmail) {
    return {
      type: EValidationType.Error,
      hasError: true,
      message: EValidationMessages.EmailInvalid,
    };
  }

  return null;
};

const arrayIsNotEmptyValidation = (value: Nullable<any[]>, message?: string): Nullable<ValidationItemResult> => {
  if (!value?.length) {
    return {
      type: EValidationType.Error,
      hasError: true,
      message: message || EValidationMessages.Empty,
    };
  }
  return null;
};

const serviceValidation = (value: BookingOfferServiceView[]) => {
  // Проверяем есть ли хотя бы одна услуга
  if (!value?.length) {
    return {
      type: EValidationType.Error,
      hasError: true,
      message: 'Заполните услуги',
    };
  }

  // Проверяем что бы хотя бы одна услуга была включена
  for (let i = 0; i < value.length; i++) {
    if (value[i].status !== EOfferServiceStatus.Disabled) {
      return null;
    }
  }

  return {
    type: EValidationType.Error,
    hasError: true,
    message: 'Хотя бы одна услуга должна быть активна',
  };
};

//правила технической валидации
const bookingOfferTechnicalValidationRules: ValidationRules<BookingOfferView> = {};

//правила бизнес валидации
export const bookingOfferBusinessValidationRules: ValidationRules<BookingOfferView> = {
  ...bookingOfferTechnicalValidationRules,
};

export const bookingOfferValidationRulesDraft: ValidationRules<BookingOfferView> = {
  name: {
    ...bookingOfferBusinessValidationRules.name,
    required: true,
  },
};

export const bookingOfferValidationRulesPublish: ValidationRules<BookingOfferView> = {
  ...bookingOfferValidationRulesDraft,
  address: {
    required: true,
  },
  responsiblePersons: {
    ...bookingOfferBusinessValidationRules.responsiblePersons,
    required: true,
    validator: (object, value) => arrayIsNotEmptyValidation(value, 'Заполните ответственных'),
  },
  category: {
    ...bookingOfferBusinessValidationRules.category,
    required: true,
  },
  description: {
    ...bookingOfferBusinessValidationRules.description,
    required: true,
  },
  images: {
    ...bookingOfferBusinessValidationRules.images,
    required: true,
    validator: (object, value) => arrayIsNotEmptyValidation(value),
  },
  contacts: {
    ...bookingOfferBusinessValidationRules.contacts,
    required: true,
    validator: (object, value) => arrayIsNotEmptyValidation(value, 'Заполните контакты'),
  },
  routeDescription: {
    ...bookingOfferBusinessValidationRules.routeDescription,
    required: true,
  },
  services: {
    ...bookingOfferBusinessValidationRules.services,
    required: true,
    validator: (object, value) => serviceValidation(value),
  },
};

//правила технической валидации
const bookingOfferResponsiblePersonTechnicalValidationRules: ValidationRules<BookingOfferResponsiblePerson> = {
  email: {
    validator: (object, value: Nullable<string>) => emailValidation(value),
  },
};

//правила бизнес валидации
export const bookingOfferResponsiblePersonBusinessValidationRules: ValidationRules<BookingOfferResponsiblePerson> = {
  ...bookingOfferResponsiblePersonTechnicalValidationRules,
};

export const bookingOfferResponsiblePersonValidationRulesDraft: ValidationCollectionRules<BookingOfferResponsiblePerson> =
  {
    ...bookingOfferResponsiblePersonBusinessValidationRules,
    fullName: {
      ...bookingOfferResponsiblePersonBusinessValidationRules.fullName,
      required: true,
    },
  };

export const bookingOfferResponsiblePersonValidationRulesPublish: ValidationCollectionRules<BookingOfferResponsiblePerson> =
  {
    ...bookingOfferResponsiblePersonBusinessValidationRules,
    fullName: {
      ...bookingOfferResponsiblePersonBusinessValidationRules.fullName,
      required: true,
    },
    phone: {
      ...bookingOfferResponsiblePersonBusinessValidationRules.phone,
      required: true,
    },
    email: {
      ...bookingOfferResponsiblePersonBusinessValidationRules.email,
      required: true,
    },
  };

//правила технической валидации
const bookingOfferContactTechnicalValidationRules: ValidationRules<BookingOfferContact> = {
  email: {
    validator: (object, value: Nullable<string>) => emailValidation(value),
  },
};

//правила бизнес валидации
export const bookingOfferContactBusinessValidationRules: ValidationRules<BookingOfferContact> = {
  ...bookingOfferContactTechnicalValidationRules,
};

export const bookingOfferContactValidationRulesDraft: ValidationCollectionRules<BookingOfferContact> = {
  ...bookingOfferContactBusinessValidationRules,
  name: {
    ...bookingOfferContactBusinessValidationRules.name,
    required: true,
  },
};

export const bookingOfferContactValidationRulesPublish: ValidationCollectionRules<BookingOfferContact> = {
  ...bookingOfferContactBusinessValidationRules,
  name: {
    ...bookingOfferContactBusinessValidationRules.name,
    required: true,
  },
  phone: {
    ...bookingOfferContactBusinessValidationRules.phone,
    required: true,
  },
  email: {
    ...bookingOfferContactBusinessValidationRules.email,
    required: true,
  },
  address: {
    ...bookingOfferContactBusinessValidationRules.address,
    required: true,
  },
};

//правила технической валидации
const bookingOfferServiceTechnicalValidationRules: ValidationRules<BookingOfferServiceView> = {};

//правила бизнес валидации
export const bookingOfferServiceBusinessValidationRules: ValidationRules<BookingOfferServiceView> = {
  ...bookingOfferServiceTechnicalValidationRules,
};

export const bookingOfferServiceValidationRulesDraft: ValidationCollectionRules<BookingOfferServiceView> = {
  ...bookingOfferServiceBusinessValidationRules,
  name: {
    ...bookingOfferServiceBusinessValidationRules.name,
    required: true,
  },
};

export const bookingOfferServiceValidationRulesPublish: ValidationCollectionRules<BookingOfferServiceView> = {
  ...bookingOfferServiceBusinessValidationRules,
  name: {
    ...bookingOfferServiceBusinessValidationRules.name,
    required: true,
  },
  category: {
    ...bookingOfferServiceBusinessValidationRules.category,
    required: true,
  },
  description: {
    ...bookingOfferServiceBusinessValidationRules.description,
    required: true,
  },
  orderByDateType: {
    ...bookingOfferServiceBusinessValidationRules.orderByDateType,
    required: true,
  },
  priceItems: {
    ...bookingOfferServiceBusinessValidationRules.priceItems,
    required: true,
    validator: (object, value) => arrayIsNotEmptyValidation(value, 'Заполните тарифы'),
  },
};

//правила технической валидации
const bookingOfferPriceItemTechnicalValidationRules: ValidationRules<BookingServicePriceItemValidateView> = {};

//правила бизнес валидации
export const bookingOfferPriceItemBusinessValidationRules: ValidationRules<BookingServicePriceItemValidateView> = {
  ...bookingOfferPriceItemTechnicalValidationRules,
};

export const bookingOfferPriceItemValidationRulesDraft: ValidationCollectionRules<BookingServicePriceItemValidateView> =
  {
    ...bookingOfferPriceItemBusinessValidationRules,
    name: {
      ...bookingOfferPriceItemBusinessValidationRules.name,
      required: true,
    },
  };

export const bookingOfferPriceItemValidationRulesPublish: ValidationCollectionRules<BookingServicePriceItemValidateView> =
  {
    name: {
      ...bookingOfferPriceItemBusinessValidationRules.name,
      required: true,
    },
    price: {
      ...bookingOfferPriceItemBusinessValidationRules.price,
      required: true,
    },
    unit: {
      ...bookingOfferPriceItemBusinessValidationRules.unit,
      required: true,
    },
  };

export const getBookingOfferValidationRulesForSave = (offer: BookingOfferView): BookingOfferValidationRules => {
  const status = offer.status ?? null;
  switch (status) {
    case null:
    case EOfferStatus.Draft:
      return {
        offer: bookingOfferValidationRulesDraft,
        service: bookingOfferServiceValidationRulesDraft,
        responsiblePerson: bookingOfferResponsiblePersonValidationRulesDraft,
        contact: bookingOfferContactValidationRulesDraft,
        priceItem: bookingOfferPriceItemValidationRulesDraft,
      };
    default:
      return {
        offer: bookingOfferValidationRulesPublish,
        service: bookingOfferServiceValidationRulesPublish,
        responsiblePerson: bookingOfferResponsiblePersonValidationRulesPublish,
        contact: bookingOfferContactValidationRulesPublish,
        priceItem: bookingOfferPriceItemValidationRulesPublish,
      };
  }
};
