import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { UUID } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import { EPartnerStep } from '../../features/general/partner/types';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import PartnerEditContainer from '../../features/partner/edit/container';
import { EPartnerUrlParam } from '../../features/partner/entry';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const PartnerEditScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { partners } = useCurrentUser().accessMatrix;

  const { id } = useParams<{ id: UUID }>();
  const searchParams = new URLSearchParams(location.search);
  const step = (searchParams.get(EPartnerUrlParam.Step) as EPartnerStep) || undefined;

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  if (!partners?.edit || !partners?.viewAsAdmin) {
    return <ForbiddenComponent />;
  }

  return (
    <PartnerEditContainer
      id={id}
      step={step}
      canChangePermissions={partners.changePermissions}
    />
  );
};

export default PartnerEditScreen;
