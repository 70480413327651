import { Grid } from '@mui/material';
import { useState } from 'react';
import { ApprovalOfferRegistryRequest } from '../../../../../domain/model/offerApprovalRegistry';
import { Nullable } from '../../../../../domain/model/types';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';
import ContentLoader from '../../../../components/common/loader';
import useValidation from '../../../../hooks/validation/useValidation';
import { MPButton } from '../../../../theme/ui-kit/button';
import { OnChangeObjectAttribute } from '../../../../types';
import CorpOfferApprovalRegistryEdit from '../../components/approvalRegistry/edit';

interface CorpOfferApprovalRegistryDialogProps {
  readonly open: boolean;
  readonly approvalRegistry: Nullable<ApprovalOfferRegistryRequest>;
  readonly isValidation: boolean;
  readonly isExecuting: boolean;
  readonly description?: string;
  readonly onChangeAttribute: OnChangeObjectAttribute<ApprovalOfferRegistryRequest>;
  readonly onChange: () => void;
  readonly onClose: () => void;
}

const CorpOfferApprovalRegistryDialog = (props: CorpOfferApprovalRegistryDialogProps) => {
  const { open, approvalRegistry, isValidation, isExecuting, description, onChangeAttribute, onChange, onClose } =
    props;

  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);

  const { validationResult, validate } = useValidation<ApprovalOfferRegistryRequest>({
    object: approvalRegistry,
    validateOnChange,
    rules: isValidation
      ? {
          number: {
            required: true,
          },
          date: {
            required: true,
          },
        }
      : {},
  });

  const onInternalChange = () => {
    const isValidObject = validate();
    if (isValidObject) {
      onChange();
    } else {
      setValidateOnChange(true);
    }
  };

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title='Присвоить приказ?'
      text={description}
      dialogButtons={
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              disabled={isExecuting}
              onClick={onInternalChange}
            >
              Присвоить
              {isExecuting && <ContentLoader />}
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              disabled={isExecuting}
              fullWidth={false}
              onClick={onClose}
            >
              Отменить
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        spacing={2}
        wrap='nowrap'
        direction='column'
      >
        <Grid item>
          <CorpOfferApprovalRegistryEdit
            approvalRegistry={approvalRegistry}
            validation={validationResult}
            onChangeAttribute={onChangeAttribute}
          />
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
};

export default CorpOfferApprovalRegistryDialog;
