import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable } from '../../../../../domain/model';
import { EPlaygroundModerationStatus } from '../../../../../domain/model/enums';
import { Playground } from '../../../../../domain/model/playground';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { PaginationSize } from '../../../../types';
import { PlaygroundTableFilterValues } from '../../filterUtils';
import playgroundServices, { AllProps } from '../../services';
import { EPlaygroundTableColumn, EPlaygroundTableTab } from '../../table/utils';

const defaultSort = `${EPlaygroundTableColumn.Name},asc`;

export type PlaygroundsSearchFetchProps = AllProps;

export const playgroundsSearchFetch = createAsyncThunk<
  Pageable<Playground>,
  PlaygroundsSearchFetchProps,
  AppThunkAPIConfig
>('playground/search/fetch', async (payload, { rejectWithValue, signal }) => {
  try {
    return await playgroundServices.common.all({ ...payload, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);

    return rejectWithValue(e.response.data);
  }
});

export interface PlaygroundsSearchState extends Fetchable, Pageable<Playground> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
    readonly moderationStatuses: Nullable<EPlaygroundModerationStatus[]>;
  };
  readonly tab: EPlaygroundTableTab;
  readonly filter: PlaygroundTableFilterValues;
  readonly selected: Playground[];
}

type Reducer<T = undefined> = CaseReducer<PlaygroundsSearchState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PlaygroundsSearchState> {
  playgroundsSearchStartSession: Reducer<{ guid: UUID; selected: Playground[] }>;
  playgroundsSearchSetSearch: Reducer<{ sort: string; pageSize: PaginationSize }>;
  playgroundsSearchSetTab: Reducer<{ tab: EPlaygroundTableTab }>;
  playgroundsSearchSortReset: Reducer;
  playgroundsSearchSetPage: Reducer<{ pageNumber: number }>;
  playgroundsSearchSelect: Reducer<Playground>;
  playgroundsSearchAllSelect: Reducer<Nullable<number>>;
  playgroundsSearchAllUnselect: Reducer;
  playgroundsSearchDataReset: Reducer;
  playgroundsSearchSetFilter: Reducer<PlaygroundTableFilterValues>;
  playgroundsSearchSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  playgroundsSearchSetSort: Reducer<{ sort: string }>;
  playgroundsSearchUnselect: Reducer<Playground>;
  playgroundsSearchNeedRefreshWatcherReset: Reducer;
}

const slice = createSlice<PlaygroundsSearchState, Reducers, 'search'>({
  name: 'search',
  initialState: {
    ...fetchableDefault,
    guid: null,
    selected: [],
    data: [],
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    needRefreshWatcher: 0,
    tab: EPlaygroundTableTab.Active,
    search: {
      sort: defaultSort,
      pageSize: 15,
      moderationStatuses: [EPlaygroundModerationStatus.Active],
    },
    filter: {},
  },
  reducers: {
    playgroundsSearchStartSession: (state, { payload }) => {
      const { guid, selected } = payload;
      state.guid = guid;

      state.isFetching = false;
      state.isFetched = false;
      state.isFailed = false;

      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
      state.needRefreshWatcher = 0;
      state.search = {
        moderationStatuses: [EPlaygroundModerationStatus.Active],
        sort: defaultSort,
        pageSize: 10,
      };
      state.filter = {};
      state.selected = selected;
    },
    playgroundsSearchSetSearch: (state, { payload }) => {
      const { sort, pageSize } = payload;

      state.search = {
        ...state.search,
        sort,
        pageSize,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    playgroundsSearchSetTab: (state, { payload }) => {
      const { tab } = payload;

      if (state.tab !== tab) {
        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
        state.needRefreshWatcher++;
      }
    },
    playgroundsSearchSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    playgroundsSearchSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.selected = [];
      state.needRefreshWatcher++;
    },
    playgroundsSearchSelect: (state, { payload }) => {
      const selected = [...state.selected];
      const existedIndex = selected.findIndex(s => s.id === payload.id);

      if (existedIndex !== -1) {
        selected.splice(existedIndex, 1);
      }

      state.selected = [...selected, payload];
    },
    playgroundsSearchAllSelect: (state, { payload }) => {
      if (payload) {
        state.selected = [...state.data.slice(0, payload)];
      } else {
        state.selected = state.data;
      }
    },
    playgroundsSearchAllUnselect: state => {
      state.selected = [];
    },
    playgroundsSearchDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
      state.selected = [];
    },
    playgroundsSearchSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    playgroundsSearchSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;

      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    playgroundsSearchSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    playgroundsSearchUnselect: (state, { payload }) => {
      const existedIndex = state.selected.findIndex(s => s.id === payload.id);

      if (existedIndex !== -1) {
        state.selected.splice(existedIndex, 1);
      }
    },
    playgroundsSearchNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(playgroundsSearchFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
      })
      .addCase(playgroundsSearchFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(playgroundsSearchFetch.rejected, state => {
        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = true;
        state.data = [];
      });
  },
});

export const {
  playgroundsSearchSetSearch,
  playgroundsSearchSetPage,
  playgroundsSearchSelect,
  playgroundsSearchStartSession,
  playgroundsSearchAllSelect,
  playgroundsSearchAllUnselect,
  playgroundsSearchDataReset,
  playgroundsSearchSetFilter,
  playgroundsSearchSetPageSize,
  playgroundsSearchSetSort,
  playgroundsSearchSortReset,
  playgroundsSearchUnselect,
  playgroundsSearchNeedRefreshWatcherReset,
  playgroundsSearchSetTab,
} = slice.actions;

export default slice.reducer;
