import { Nullable, UUID } from '../../../../../domain/model/types';
import { EBookingOrderTableTab } from '../../types';

export type UseBookingOrderTableConfig = {
  readonly tabs: EBookingOrderTableTab[];
  readonly tabsForCounts: EBookingOrderTableTab[];
  readonly defaultTab: EBookingOrderTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly customerId: Nullable<UUID>;
};

type UseBookingOrderTableConfigProps = {
  readonly partnerId: Nullable<UUID>;
  readonly customerId: Nullable<UUID>;
};

export function useBookingOrderTableConfig({
  partnerId,
  customerId,
}: UseBookingOrderTableConfigProps): UseBookingOrderTableConfig {
  const tabs = [
    EBookingOrderTableTab.New,
    EBookingOrderTableTab.Confirmed,
    EBookingOrderTableTab.Given,
    EBookingOrderTableTab.Cancelled,
  ];
  const tabsForCounts = [
    EBookingOrderTableTab.New,
    EBookingOrderTableTab.Confirmed,
    EBookingOrderTableTab.Given,
    EBookingOrderTableTab.Cancelled,
  ];

  const defaultTab = EBookingOrderTableTab.Given;

  return {
    tabs,
    tabsForCounts,
    defaultTab,
    partnerId,
    customerId,
  };
}
