import { Typography } from '@mui/material';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../components/common/header';
import { MPButton } from '../../../../theme/ui-kit/button';
import { useContextHandlers } from '../hooks/useContextHandlers';

type ProductOrderTableHeaderAdapterProps = {
  readonly canCreate?: boolean;
};

const ProductOrderTableHeaderAdapter = (props: ProductOrderTableHeaderAdapterProps) => {
  const { canCreate } = props;

  const handlers = useContextHandlers();

  return (
    <DefaultHeader
      headline={<AppBreadcrumbs />}
      actions={
        canCreate ? (
          <MPButton
            onClick={handlers.onCreateOrder}
            variant='outlined'
            size='small'
          >
            Создать заказ
          </MPButton>
        ) : null
      }
    >
      <Typography variant='h2'>Заказы Товаров</Typography>
    </DefaultHeader>
  );
};

export default ProductOrderTableHeaderAdapter;
