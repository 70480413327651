import Api from '../../../../../data/api';
import { BookingServiceCategoryUpdateDiscriminator } from '../../../../../data/api/bookingDictionary';
import { ApiCancellable, ApiQueryDsl } from '../../../../../data/api/types';
import { BookingServiceCategory } from '../../../../../domain/model/booking';
import { EBookingServiceCategoryStatus } from '../../../../../domain/model/enums';
import { DataFilterQueryDslOperator } from '../../../../../domain/model/filter';
import { UUID } from '../../../../../domain/model/types';

type DataType = BookingServiceCategory;

type CreateProps = DataType;

type AllProps = ApiCancellable & {
  readonly onlyEnabled?: boolean;
  readonly withOrderByDateType?: boolean;
};

type UpdateProps = {
  readonly id: UUID;
  readonly data: DataType;
};

type DeleteProps = {
  readonly id: UUID;
};

type BookingServiceCategoriesService = {
  readonly create: (props: CreateProps) => Promise<DataType>;
  readonly all: (props: AllProps) => Promise<DataType[]>;
  readonly update: (props: UpdateProps) => Promise<DataType>;
  readonly delete: (props: DeleteProps) => Promise<void>;
};

const service: BookingServiceCategoriesService = {
  create: async data => {
    const { data: result } = await Api.bookingDictionary.serviceCategory.create(data);
    return result;
  },
  all: async ({ onlyEnabled, withOrderByDateType, signal }) => {
    const querydsl: ApiQueryDsl = [];
    if (onlyEnabled) {
      querydsl.push({
        field: 'status',
        operator: DataFilterQueryDslOperator.Equals,
        value: [EBookingServiceCategoryStatus.Enabled],
      });
    }
    const { data: result } = await Api.bookingDictionary.serviceCategory.all({ querydsl, signal });
    return result.filter(item => (withOrderByDateType ? !!item.orderByDateTypes?.length : true));
  },
  update: async ({ id, data }) => {
    const { data: result } = await Api.bookingDictionary.serviceCategory.update({
      discriminator: BookingServiceCategoryUpdateDiscriminator.UpdateServiceCategoryCommand,
      id,
      data,
    });
    return result;
  },
  delete: async ({ id }) => {
    await Api.bookingDictionary.serviceCategory.delete({ id });
  },
};

export default service;
