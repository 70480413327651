const fontWeightRegular = 400;
const fontWeightMedium = 500;

type TypographyType = {
  fontSize: string;
  lineHeight: string;
  fontWeight: number;
};

type Typography = {
  fontWeightRegular: number;
  fontWeightMedium: number;

  fontFamily: string;

  h1: TypographyType;
  h2: TypographyType;
  h3: TypographyType;

  subtitle1: TypographyType;

  body1: TypographyType;
  body2: TypographyType;
  p3: TypographyType;
  p2Medium: TypographyType;
  p3Medium: TypographyType;
  p4: TypographyType;
};

const brandBookTypography: Typography = {
  fontWeightRegular,
  fontWeightMedium,

  fontFamily: 'Rubik',

  h1: {
    fontSize: '30px',
    lineHeight: '36px',
    fontWeight: fontWeightMedium,
  },
  h2: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: fontWeightMedium,
  },
  h3: {
    fontSize: '20px',
    lineHeight: '26px',
    fontWeight: fontWeightMedium,
  },

  subtitle1: {
    fontSize: '17px',
    lineHeight: '24px',
    fontWeight: fontWeightMedium,
  },

  body1: {
    fontSize: '17px',
    lineHeight: '24px',
    fontWeight: fontWeightRegular,
  },
  body2: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: fontWeightRegular,
  },
  p3: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: fontWeightRegular,
  },
  p2Medium: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: fontWeightMedium,
  },
  p3Medium: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: fontWeightMedium,
  },
  p4: {
    fontSize: '10px',
    lineHeight: '16px',
    fontWeight: fontWeightRegular,
  },
};

export default brandBookTypography;
