import { EOrderDiscriminator, EOrderPartition } from '../../../domain/model/enums';

export enum EOrderStep {
  Promotion = 'promotion',
  Order = 'order',
}

export const getOrderPartitionByOrderDiscriminator = (discriminator: EOrderDiscriminator): EOrderPartition => {
  switch (discriminator) {
    case EOrderDiscriminator.ProductOrder:
      return EOrderPartition.ProductOffers;
    case EOrderDiscriminator.BookingOrder:
      return EOrderPartition.BookingOffers;
  }
};
