import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataFilterComponent from '../../../../components/common/dataFilter';
import { getBookingOrderFilterStrategies } from '../filterUtils';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingOrdersFilterSelector } from '../store/selectors';
import useTechConfig from '@/presentation/hooks/useTechConfig';
import { EAppFeature } from '@/presentation/types';

interface OrdersTableFilterAdapterProps {
  readonly partnerId: Nullable<UUID>;
}

const BookingOrderTableFilterAdapter = ({ partnerId }: OrdersTableFilterAdapterProps) => {
  const handlers = useContextHandlers();
  const filter = useSelector(bookingOrdersFilterSelector);
  const { hasFeature } = useTechConfig();
  const filterStrategies = getBookingOrderFilterStrategies(filter, partnerId, hasFeature(EAppFeature.Csp));

  return useMemo(
    () => (
      <DataFilterComponent
        strategies={filterStrategies}
        onChange={handlers.onChangeFilter}
      />
    ),
    [filterStrategies, handlers.onChangeFilter]
  );
};

export default BookingOrderTableFilterAdapter;
