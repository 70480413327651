import OfferFieldHistoryTableCell, { OfferFieldHistoryTableCellCommonProps } from './index';

const OfferFieldHistoryTableCellOldValue = (props: OfferFieldHistoryTableCellCommonProps) => {
  const {
    fieldHistory: { oldValue },
  } = props;

  const value = oldValue ?? undefined;

  return (
    <OfferFieldHistoryTableCell
      {...props}
      value={value}
    />
  );
};

export default OfferFieldHistoryTableCellOldValue;
