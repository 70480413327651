import { ESortDirection, MpCustomer } from '@/domain';
import { DataTableLoader } from '@components/common/table/loader';
import CustomerTableCellCreatedAt from '@features/customer/components/tableCell/createdAt';
import CustomerTableCellEmail from '@features/customer/components/tableCell/email';
import CustomerTableCellName from '@features/customer/components/tableCell/name';
import CustomerTableCellPernr from '@features/customer/components/tableCell/pernr';
import CustomerTableCellPhone from '@features/customer/components/tableCell/phone';
import { CustomerTableCellAdapter } from '@features/customer/table/adapters/cell';
import { customersIsFetchingSelector } from '@features/customer/table/store/selectors';
import { useCallback } from 'react';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../components/common/table';
import TableActions from '../../../components/common/table/actions';
import { CustomerActions, CustomerActionTableType } from '../types';
import { CustomerActionTableCall, CustomerTableActionProcess, ECustomerTableColumn } from './utils';

interface CustomersTableProps {
  readonly metadata: DataTableMetadata<ECustomerTableColumn>;
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly customers: MpCustomer[];
  readonly onChangeMetadata: (metadata: DataTableMetadata<ECustomerTableColumn>) => void;
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onCustomerClick: (customer: MpCustomer) => void;
  readonly onCustomerAction: (call: CustomerActionTableCall) => void;
  readonly getActions: (customer: MpCustomer) => CustomerActions<CustomerActionTableType>;
  readonly getActionProcesses: (customer: MpCustomer) => CustomerTableActionProcess[];
}

const CustomersTable = (props: CustomersTableProps) => {
  const {
    metadata,
    sort,
    customers,
    onChangeMetadata,
    onRequestSort,
    onCustomerClick,
    onCustomerAction,
    getActions,
    getActionProcesses,
  } = props;

  const rows: DataTableRow<MpCustomer, ECustomerTableColumn>[] = customers.map((customer, index) => {
    return {
      [ECustomerTableColumn.Date]: (
        <CustomerTableCellAdapter
          index={index}
          component={CustomerTableCellCreatedAt}
        />
      ),
      [ECustomerTableColumn.Name]: (
        <CustomerTableCellAdapter
          index={index}
          component={CustomerTableCellName}
        />
      ),
      [ECustomerTableColumn.PersonnelNumber]: (
        <CustomerTableCellAdapter
          index={index}
          component={CustomerTableCellPernr}
        />
      ),
      [ECustomerTableColumn.Phone]: (
        <CustomerTableCellAdapter
          index={index}
          component={CustomerTableCellPhone}
        />
      ),
      [ECustomerTableColumn.Email]: (
        <CustomerTableCellAdapter
          index={index}
          component={CustomerTableCellEmail}
        />
      ),
      data: customer,
    };
  });

  const onTableAction = useCallback(
    (action: CustomerActionTableType, customer: MpCustomer) => onCustomerAction({ action, customer }),
    [onCustomerAction]
  );

  return (
    <DataTable<MpCustomer, ECustomerTableColumn>
      width='auto'
      metadata={metadata}
      rowActions
      rows={rows}
      sort={{ column: sort.column, direction: sort.direction }}
      loader={<DataTableLoader selector={customersIsFetchingSelector} />}
      onSort={(event, column, direction) => onRequestSort(column, direction)}
      onRowClick={(event, cell, { data }) => onCustomerClick(data)}
      onChangeMetadata={onChangeMetadata}
      getRowActions={customer => (
        <TableActions<MpCustomer, CustomerActionTableType>
          entityLink={customer}
          actions={getActions(customer)}
          onExecute={onTableAction}
          isExecuting={getActionProcesses(customer).some(process => process.isFetching)}
        />
      )}
    />
  );
};

export default CustomersTable;
