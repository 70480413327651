import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { Partner } from '../../../domain/model/partner';
import { MpUser } from '../../../domain/model/user';
import ForbiddenComponent from '../../components/common/forbidden';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import { viewShowPartnerManagementMenu } from '../../features/partner/management/container/store/slice';
import { getPartnerManagementEmployeeRoute } from '../../features/partner/management/entry';
import { EPartnerEmployeeUrlParam, PartnerEmployeesLocationState } from '../../features/partnerEmployee/entry';
import PartnerEmployeeTableEntry from '../../features/partnerEmployee/table/entry';
import { EPartnerEmployeeTableTab } from '../../features/partnerEmployee/table/utils';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface PartnerManagementScreenEmployeesScreenProps {
  readonly partner: Partner;
}

const PartnerManagementEmployeesScreen = ({ partner }: PartnerManagementScreenEmployeesScreenProps) => {
  const dispatch = useDispatch();
  const location = useLocation<PartnerEmployeesLocationState>();
  const history = useHistory();
  const { accessMatrix } = useCurrentUser();
  const { partnerEmployees } = accessMatrix;

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(EPartnerEmployeeUrlParam.Tab) as EPartnerEmployeeTableTab;

  const owner = partner.owner;

  const onEmployeeOpen = (employee: MpUser) => {
    history.push(getPartnerManagementEmployeeRoute({ partnerId: partner.id, employeeId: employee.id }));
  };

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowPartnerManagementMenu());
  }, [dispatch]);

  if (!partnerEmployees?.view || !owner) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<PartnerEmployeesLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <PartnerEmployeeTableEntry
          guid={state.guid}
          tab={tab}
          partner={partner}
          onEmployeeOpen={onEmployeeOpen}
        />
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default PartnerManagementEmployeesScreen;
