import { ENoticeStatus } from '../../../../domain/model/enums';
import { BookingOrder } from '../../../../domain/model/order';
import Notifier from '../../../../system/notifier';
import { EBookingOrderActionType } from '../types';
import { getBookingOrderStatusText } from '../utils/common';

export const showBookingOrderActionNotification = (order: BookingOrder, action: EBookingOrderActionType) => {
  let text;
  switch (action) {
    case EBookingOrderActionType.Give:
      text = 'Изменения сохранены';
      break;
    case EBookingOrderActionType.Cancel:
      text = 'Заказ был отменен';
      break;
    case EBookingOrderActionType.New:
      text = 'Был создан новый заказ';
      break;
    case EBookingOrderActionType.Confirm:
      text = 'Заказ был принят';
      break;
    case EBookingOrderActionType.Save:
      text = `Заказ был сохранен в статусе ${getBookingOrderStatusText(order.status)}`;
      break;
    case EBookingOrderActionType.Renew:
      text = 'Заказ был пересоздан';
      break;
  }

  Notifier.getInstance().addNotice(ENoticeStatus.Success, text);
};
