import { useSelector } from 'react-redux';
import { cmsContainersLastSortIndexSelector } from '../store/selectors';

type UseCmsContainerTableSortIndex = {
  readonly max: number;
};

const useCmsContainerTableSortIndex = (): UseCmsContainerTableSortIndex => {
  const max = useSelector(cmsContainersLastSortIndexSelector);

  return {
    max,
  };
};

export default useCmsContainerTableSortIndex;
