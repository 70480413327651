import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nullable } from '../../../../../../domain/model/types';
import {
  adminMpsFilterSelector,
  adminMpsNeedRefreshWatcherSelector,
  adminMpsPageNumberSelector,
  adminMpsSearchSelector,
} from '../store/selectors';
import { AdminMpFetchProps, adminMpNeedRefreshWatcherReset, adminMpsCountsFetch, adminMpsFetch } from '../store/slice';
import { EAdminMpTableTab, getAdminMpStatusesByTableTab } from '../utils';
import { useContextConfig } from './useContextConfig';

interface UseAdminMpTableSearchProps {
  readonly tab: EAdminMpTableTab;
}

const useAdminMpTableSearch = (props: UseAdminMpTableSearchProps): void => {
  const { tab } = props;

  const dispatch = useDispatch();

  const { tabsForCounts } = useContextConfig();

  const [searchPromises, setSearchPromises] = useState<Nullable<any[]>>(null);

  const needRefreshWatcher = useSelector(adminMpsNeedRefreshWatcherSelector);
  const pageNumber = useSelector(adminMpsPageNumberSelector);
  const search = useSelector(adminMpsSearchSelector);
  const filter = useSelector(adminMpsFilterSelector);

  const currentStatuses = getAdminMpStatusesByTableTab(tab);

  const queryParams = useMemo<AdminMpFetchProps>(() => {
    return {
      filter,
      search: {
        ...search,
        statuses: currentStatuses,
      },
      pageNumber,
    };
  }, [filter, search, currentStatuses, pageNumber]);

  const onSearch = useCallback(() => {
    setSearchPromises([
      dispatch(adminMpsFetch(queryParams)),
      dispatch(adminMpsCountsFetch({ ...queryParams, tabs: tabsForCounts })),
    ]);
  }, [dispatch, queryParams, tabsForCounts]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  // сброс вотчера после ухода
  useEffect(() => {
    return () => {
      dispatch(adminMpNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};

export default useAdminMpTableSearch;
