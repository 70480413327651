import { AnyClientOrg, ClientOrgCreate, EClientOrgStatus, ENoticeStatus } from '@/domain';
import Notifier from '../../../../system/notifier';

export const clientOrgRzdCode = 'rzd' as const;

/**
 * показать нотификатор об успешном выполнении действия с компанией
 * @param adCampaign
 * @param nextStatus если значение не передано, предполагается что статус не меняется относительно текущего, или пока статус не присвоен
 * */
export const showClientOrgActionSuccessNotification = (
  adCampaign: AnyClientOrg | ClientOrgCreate,
  nextStatus?: EClientOrgStatus
) => {
  let text;

  if (!adCampaign.status && !nextStatus) {
    text = 'Компания сохранёна';
  } else {
    text = 'Изменения сохранены';
  }

  Notifier.getInstance().addNotice(ENoticeStatus.Success, text);
};

export const showClientOrgInvalidNotification = (
  adCampaign: AnyClientOrg | ClientOrgCreate,
  nextStatus?: EClientOrgStatus
) => {
  let text;

  if (!adCampaign.status && !nextStatus) {
    text = 'Заполните корректно поля, чтобы сохранить компанию';
  } else {
    switch (nextStatus) {
      case EClientOrgStatus.Active:
        text = 'Заполните все обязательные поля, чтобы опубликовать компанию';
        break;
      default:
        text = 'Заполните все обязательные поля, чтобы сохранить изменения';
        break;
    }
  }

  Notifier.getInstance().addNotice(ENoticeStatus.Warning, text);
};
