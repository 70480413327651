import { Location } from 'history';
import { v4 as uuidv4 } from 'uuid';
import { Nullable, UUID } from '@/domain';
import rootRouting from '../../../routing';
import { EBookingOrderTableTab } from './types';

const routing = {
  list: `${rootRouting.orders}/booking-offers`,
  create: `${rootRouting.orders}/booking-offers/create`,
  details: `${rootRouting.orders}/:id`,
  edit: `${rootRouting.orders}/:id/edit`,
};

export enum EBookingOrderUrlParam {
  Tab = 'tab',
}

export interface BookingOrdersLocationState {
  readonly guid: UUID;
}

interface GetBookingOrderEditRouteProps {
  readonly id: UUID;
}

export const getBookingOrderEditRoute = ({ id }: GetBookingOrderEditRouteProps) => {
  return `${routing.edit.replace(':id', id)}`;
};

export const getBookingOrderDetailsRoute = (id: UUID) => {
  return `${routing.details.replace(':id', id)}`;
};

interface GetBookingOrdersTableRouteProps {
  readonly tab?: Nullable<EBookingOrderTableTab>;
  readonly guid?: UUID;
}

export const getBookingOrdersTableRoute = (
  props: GetBookingOrdersTableRouteProps
): Location<BookingOrdersLocationState> => {
  const { tab, guid } = props;

  const searchParams = new URLSearchParams();
  if (tab) {
    searchParams.append(EBookingOrderUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return {
    pathname: routing.list,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};
