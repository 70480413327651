import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataFilterComponent from '../../../../components/common/dataFilter';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { ECorpOfferTableFilterItem } from '../../filterUtils';
import { ECorpOfferTableColumn } from '../../types';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  corpOffersSearchFilterSelector,
  corpOffersSearchGuidSelector,
  corpOffersSearchSortColumnSelector,
} from '../store/selectors';

interface CorpOffersSearchFilterAdapterProps {
  readonly filterAdapter: DataFilterAdapter<ECorpOfferTableColumn, ECorpOfferTableFilterItem>;
}

const CorpOffersSearchFilterAdapter = (props: CorpOffersSearchFilterAdapterProps) => {
  const { filterAdapter } = props;

  const handlers = useContextHandlers();

  const guid = useSelector(corpOffersSearchGuidSelector);
  const sortColumn = useSelector(corpOffersSearchSortColumnSelector);
  const filter = useSelector(corpOffersSearchFilterSelector);

  const { filterStrategies } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return useMemo(
    () => (
      <DataFilterComponent
        strategies={filterStrategies}
        onChange={handlers.onChangeFilter}
      />
    ),
    [filterStrategies, handlers.onChangeFilter]
  );
};

export default CorpOffersSearchFilterAdapter;
