import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../../data/store/types';
import { DataTreeItem, DataTreeItemMutable } from '../../../../../../domain/model';
import { BookingServicePriceUnit } from '../../../../../../domain/model/booking';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import service from '../../services';
import { buildBookingServicePriceUnitTree } from '../utils';

export const bookingServicePriceUnitsEditFetch = createAsyncThunk<BookingServicePriceUnit[], void, AppThunkAPIConfig>(
  'bookingServicePriceUnit/edit/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await service.all({ signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const bookingServicePriceUnitsEditCreate = createAsyncThunk<
  BookingServicePriceUnit,
  BookingServicePriceUnit,
  AppThunkAPIConfig
>('bookingServicePriceUnit/edit/create', async (data, { rejectWithValue }) => {
  try {
    return await service.create(data);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingServicePriceUnitsEditUpdate = createAsyncThunk<
  BookingServicePriceUnit,
  BookingServicePriceUnit,
  AppThunkAPIConfig
>('bookingServicePriceUnit/edit/update', async (data, { rejectWithValue }) => {
  try {
    return await service.update({ id: data.id, data });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingServicePriceUnitsEditDelete = createAsyncThunk<
  BookingServicePriceUnit,
  BookingServicePriceUnit,
  AppThunkAPIConfig
>('bookingServicePriceUnit/edit/delete', async (data, { rejectWithValue }) => {
  try {
    await service.delete({ id: data.id });
    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface BookingServicePriceUnitsEditState {
  readonly fetch: Fetchable & {
    readonly data: Nullable<DataTreeItem<BookingServicePriceUnit>[]>;
  };
  readonly create: Fetchable;
  readonly delete: Fetchable;
  readonly update: Fetchable;
  readonly dialogs: {
    readonly add: Nullable<{ parent: Nullable<DataTreeItem<BookingServicePriceUnit>> }>;
    readonly modify: Nullable<DataTreeItem<BookingServicePriceUnit>>;
    readonly delete: Nullable<DataTreeItem<BookingServicePriceUnit>>;
  };
}

type Reducer<T = undefined> = CaseReducer<BookingServicePriceUnitsEditState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<BookingServicePriceUnitsEditState> {
  readonly bookingServicePriceUnitsEditResetState: Reducer;
  readonly bookingServicePriceUnitsEditSetDialogState: Reducer<{
    name: keyof BookingServicePriceUnitsEditState['dialogs'];
    data: any;
  }>;
}

const slice = createSlice<BookingServicePriceUnitsEditState, Reducers, 'bookingServicePriceUnit/edit'>({
  name: 'bookingServicePriceUnit/edit',
  initialState: {
    fetch: {
      ...fetchableDefault,
      data: null,
    },
    create: {
      ...fetchableDefault,
    },
    delete: {
      ...fetchableDefault,
    },
    update: {
      ...fetchableDefault,
    },
    dialogs: {
      add: null,
      modify: null,
      delete: null,
    },
  },
  reducers: {
    bookingServicePriceUnitsEditResetState: state => {
      state.fetch = {
        ...fetchableDefault,
        data: null,
      };
      state.create = {
        ...fetchableDefault,
      };
      state.delete = {
        ...fetchableDefault,
      };
      state.update = {
        ...fetchableDefault,
      };
      state.dialogs = {
        add: null,
        modify: null,
        delete: null,
      };
    },
    bookingServicePriceUnitsEditSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bookingServicePriceUnitsEditFetch.pending, state => {
        state.fetch.isFetching = true;
        state.fetch.isFetched = false;
        state.fetch.isFailed = false;
        state.fetch.data = null;
      })
      .addCase(bookingServicePriceUnitsEditFetch.fulfilled, (state, { payload }) => {
        state.fetch.isFetching = false;
        state.fetch.isFetched = true;
        state.fetch.isFailed = false;
        state.fetch.data = buildBookingServicePriceUnitTree(payload);
      })
      .addCase(bookingServicePriceUnitsEditFetch.rejected, state => {
        state.fetch.isFetching = false;
        state.fetch.isFetched = false;
        state.fetch.isFailed = true;
        state.fetch.data = null;
      })
      .addCase(bookingServicePriceUnitsEditCreate.pending, state => {
        state.create.isFetching = true;
        state.create.isFetched = false;
        state.create.isFailed = false;
      })
      .addCase(bookingServicePriceUnitsEditCreate.fulfilled, (state, { payload }) => {
        state.create.isFetching = false;
        state.create.isFetched = true;
        state.create.isFailed = false;

        if (state.fetch.data) {
          const parent = findItem(state.fetch.data, payload.orderByDateType);
          parent?.children?.push({
            id: payload.id,
            label: payload.name,
            parentId: parent.id,
            data: payload,
            children: [],
          });
        }
      })
      .addCase(bookingServicePriceUnitsEditCreate.rejected, state => {
        state.create.isFetching = false;
        state.create.isFetched = false;
        state.create.isFailed = true;
      })
      .addCase(bookingServicePriceUnitsEditUpdate.pending, state => {
        state.update.isFetching = true;
        state.update.isFetched = false;
        state.update.isFailed = false;
      })
      .addCase(bookingServicePriceUnitsEditUpdate.fulfilled, (state, { payload }) => {
        state.update.isFetching = false;
        state.update.isFetched = true;
        state.update.isFailed = false;

        if (state.fetch.data) {
          const item = findItem(state.fetch.data, payload.id);
          if (item) {
            item.id = payload.id;
            item.label = payload.name;
            item.parentId = payload.orderByDateType;
            item.data = payload;
          }
        }
      })
      .addCase(bookingServicePriceUnitsEditUpdate.rejected, state => {
        state.update.isFetching = false;
        state.update.isFetched = false;
        state.update.isFailed = true;
      })
      .addCase(bookingServicePriceUnitsEditDelete.pending, state => {
        state.delete.isFetching = true;
        state.delete.isFetched = false;
        state.delete.isFailed = false;
      })
      .addCase(bookingServicePriceUnitsEditDelete.fulfilled, (state, { payload }) => {
        state.delete.isFetching = false;
        state.delete.isFetched = true;
        state.delete.isFailed = false;

        if (state.fetch.data) {
          const parent = findItem(state.fetch.data, payload.orderByDateType);
          if (parent?.children) {
            const index = parent.children.findIndex(item => item.id === payload.id);
            parent.children.splice(index, 1);
          }
        }
      })
      .addCase(bookingServicePriceUnitsEditDelete.rejected, state => {
        state.delete.isFetching = false;
        state.delete.isFetched = false;
        state.delete.isFailed = true;
      });
  },
});

export const { bookingServicePriceUnitsEditResetState, bookingServicePriceUnitsEditSetDialogState } = slice.actions;

const findChild = (
  item: DataTreeItem<BookingServicePriceUnit>,
  id: UUID
): Nullable<DataTreeItemMutable<BookingServicePriceUnit>> =>
  item.id === id
    ? item
    : item.children?.reduce<Nullable<DataTreeItem<BookingServicePriceUnit>>>(
        (result, n) => result || findChild(n, id),
        null
      ) ?? null;

const findItem = (
  tree: DataTreeItem<BookingServicePriceUnit>[],
  id: UUID
): Nullable<DataTreeItemMutable<BookingServicePriceUnit>> => {
  let result: Nullable<DataTreeItemMutable<BookingServicePriceUnit>> = null;
  for (let i = 0; i < tree.length; i++) {
    result = findChild(tree[i], id);
    if (result) {
      break;
    }
  }
  return result;
};

export default slice.reducer;
