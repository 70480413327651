import { Partner } from '@/domain';
import ForbiddenComponent from '@components/common/forbidden';
import LocationWithRequiredStateContainer from '@features/general/locationExtension/withRequiredState';
import { viewHideMainMenu } from '@features/main/container/store/slice';
import { viewShowPartnerManagementMenu } from '@features/partner/management/container/store/slice';
import ReportDownloadsProvider from '@features/report/downloads/provider';
import { ReportsLocationState } from '@features/report/entry';
import { ReportsTableEntry } from '@features/report/table/entry';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface PartnerManagementReportsScreenProps {
  readonly partner: Partner;
}

const PartnerManagementReportsScreen = ({ partner }: PartnerManagementReportsScreenProps) => {
  const dispatch = useDispatch();
  const { reports } = useCurrentUser().accessMatrix;

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowPartnerManagementMenu());
  }, [dispatch]);

  if (!reports?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<ReportsLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <ReportDownloadsProvider>
          <ReportsTableEntry
            guid={state.guid}
            partnerId={partner.id}
          />
        </ReportDownloadsProvider>
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default PartnerManagementReportsScreen;
