import { FCC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../data/store/store';
import ContentLoader, { ContentLoaderProps } from '../../loader';

interface TableActionsDecoratorProps extends ContentLoaderProps {
  readonly isFetchingSelector: (store: RootState) => boolean;
}

export const TableActionsUpdaterDecorator: FCC<TableActionsDecoratorProps> = ({
  isFetchingSelector,
  children,
  ...loaderProps
}) => {
  const isFetching = useSelector(isFetchingSelector);

  return (
    <>
      {children}
      {isFetching && (
        <ContentLoader
          size={20}
          {...loaderProps}
        />
      )}
    </>
  );
};
