import { FormControlLabel, FormHelperText, Typography } from '@mui/material';
import { ContentTarget } from '../../../../../../domain/model';
import { Checkbox } from '../../../../../components/common/checkboxPanel/controls';
import { ContentTargetEditComponent } from '../../types';

const attribute: keyof ContentTarget = 'havingChildFamilyMemberOnly';

const ContentTargetEditHavingChildFamilyMemberOnly: ContentTargetEditComponent = props => {
  const { target, validation, onChangeAttribute } = props;

  const value = target[attribute] ?? false;

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            color='primary'
            checked={value}
            onChange={(event, checked) => onChangeAttribute(attribute, checked)}
          />
        }
        label={<Typography>Только если есть член семьи ДО 18 лет</Typography>}
      />
      {validation?.[attribute]?.message && (
        <FormHelperText error={validation?.[attribute]?.hasError}>{validation?.[attribute]?.message}</FormHelperText>
      )}
    </>
  );
};

export default ContentTargetEditHavingChildFamilyMemberOnly;
