import TradeOfferTableCell, { TradeOfferTableCellCommonProps } from './index';

const empty = 0;

const TradeOfferTableCellOfferCounts = (props: TradeOfferTableCellCommonProps) => {
  const {
    tradeOffer: { offerCount, notUsedOfferCount },
  } = props;

  const value = `${notUsedOfferCount ?? empty}/${offerCount ?? empty}`;

  return (
    <TradeOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default TradeOfferTableCellOfferCounts;
