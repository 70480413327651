import styled from '@emotion/styled';
import { Card as MuiCard } from '@mui/material';
import AppImage from '../../../../components/common/images/common';
import { publicAppPalette } from '@theme/palette';
import { viewConfig } from '@theme/viewConfig';

export const Wrapper = styled.div`
  position: relative;

  padding: ${p => p.theme.spacing(5)};

  border: 1px solid ${p => p.theme.palette.secondary.main};
  border-radius: ${p => (p.theme.shape.borderRadius as number) * 3}px;
  background-color: ${p => p.theme.palette.background.default};

  &::before {
    content: '';
    position: absolute;
    inset: -1px;
    border: 10px dashed ${p => p.theme.palette.background.default};
    border-radius: ${p => (p.theme.shape.borderRadius as number) * 3}px;
    z-index: 0;
  }
`;

export const Content = styled('div')`
  position: relative;
  z-index: 1;
`;

export const Label = styled.div`
  position: absolute;
  top: calc(-${p => p.theme.typography.body2.lineHeight} / 2);
  left: ${p => p.theme.spacing(4.5)};

  padding: 0 ${p => p.theme.spacing(0.5)};
  background-color: ${p => p.theme.palette.background.default};

  & .MuiTypography-root {
    font-weight: ${p => p.theme.typography.fontWeightSemiBold};
  }
`;

export const Card = styled(MuiCard)`
  ${p =>
    (p.elevation ?? 0) > 0 &&
    `
    box-shadow: 0 ${p.theme.spacing(0.5)} ${p.theme.spacing(3.5)} ${publicAppPalette.shadow} !important;
  `}

  user-select: none;

  & .MuiCardContent-root:last-child {
    padding: ${p => p.theme.spacing(1.5, 0, 0)};
  }
`;

export const ImageWrapper = styled.div`
  width: ${viewConfig.clientOrg.preview.width};
  height: 3rem;

  border-radius: ${p => (p.theme.shape.borderRadius as number) * 2}px;

  position: relative;

  overflow: hidden;
`;

export const StyledImage = styled(AppImage)`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 3rem;

  object-fit: contain;
  object-position: center center;

  vertical-align: top;
`;
