import { PartnerShort } from '../../../../../domain/model/partner';
import { Nullable } from '../../../../../domain/model/types';
import { MpUser, MpUserShort } from '../../../../../domain/model/user';
import { EPartnerEmployeeTableTab } from '../utils';

interface UseEmployeeTableConfigProps {
  readonly partner: PartnerShort;
  readonly onEmployeeOpen: (employee: MpUser) => void;
}

export type UseEmployeeTableConfig = {
  readonly defaultTab: EPartnerEmployeeTableTab;
  readonly tabsForCounts: EPartnerEmployeeTableTab[];
  readonly partnerOwner: Nullable<MpUserShort>;
  readonly partner: PartnerShort;
  readonly onEmployeeOpen: (employee: MpUser) => void;
};

export function useEmployeeTableConfig(props: UseEmployeeTableConfigProps): UseEmployeeTableConfig {
  const { partner, onEmployeeOpen } = props;

  const defaultTab = EPartnerEmployeeTableTab.Active;
  const tabsForCounts = Object.values(EPartnerEmployeeTableTab);
  const partnerOwner = partner.owner;

  return {
    defaultTab,
    tabsForCounts,
    partnerOwner,
    partner,
    onEmployeeOpen,
  };
}
