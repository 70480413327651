import styled from '@emotion/styled';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 100%;

    display: flex;
    flex-direction: column;

    padding: ${theme.spacing(2)} 0;
    gap: ${theme.spacing(2.5)};
  `
);
