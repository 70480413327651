import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import { FCC } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './data/store/store';
import { locale } from './locale';
import AuthProvider from './presentation/features/auth/provider';
import theme from './presentation/theme';
import MPThemeProvider from './presentation/theme/common/provider';

const AppProviders: FCC = ({ children }) => {
  return (
    <MPThemeProvider theme={theme}>
      <LocalizationProvider
        dateLibInstance={moment}
        dateAdapter={AdapterMoment}
        adapterLocale={locale.date}
      >
        <StoreProvider store={store}>
          <AuthProvider>
            <BrowserRouter>{children}</BrowserRouter>
          </AuthProvider>
        </StoreProvider>
      </LocalizationProvider>
    </MPThemeProvider>
  );
};

export default AppProviders;
