import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from './index';

const CorpOfferTableCellInn = (props: CorpOfferTableCellCommonProps) => {
  const {
    corpOffer: {
      partner: { inn },
    },
  } = props;

  return (
    <CorpOfferTableCell
      {...props}
      value={inn}
    />
  );
};

export default CorpOfferTableCellInn;
