import { FCC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import store from '../../../../../../data/store/store';
import { OfferCategory } from '../../../../../../domain/model';
import useHistoryExtensions from '../../../../../hooks/useHistoryExtensions';
import { getConfigurationPrivilegeRoute } from '../../../../configuration/entry';
import { TradeOfferCategoriesSelectContainerProps } from '../container';
import { TradeOfferCategoriesSelectHandlersContext, TradeOfferCategoriesSelectHandlersContextValue } from '../context';
import { tradeOfferCategoriesSelectSelectedSelector } from '../store/selectors';
import {
  tradeOfferCategoriesSelectSelect,
  tradeOfferCategoriesSelectUnselect,
  tradeOfferCategoriesSelectUnselectAll,
} from '../store/slice';

export const TradeOfferCategoriesSelectHandlersProvider: FCC<TradeOfferCategoriesSelectContainerProps> = ({
  children,
  access,
  maxCount = 99999,
  onClose: onCloseExternal,
}) => {
  const dispatch = useDispatch();
  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const getSelectedFromState = useCallback((): Nullable<OfferCategory[]> => {
    const state = store.getState();
    return tradeOfferCategoriesSelectSelectedSelector(state);
  }, [store]);

  const onSelect = useCallback<TradeOfferCategoriesSelectHandlersContextValue['onSelect']>(
    tradeOfferCategory => {
      const selected = getSelectedFromState();
      if (maxCount === 1) {
        dispatch(tradeOfferCategoriesSelectUnselectAll());
        dispatch(tradeOfferCategoriesSelectSelect(tradeOfferCategory));
      } else if ((selected?.length ?? 0) < maxCount) {
        dispatch(tradeOfferCategoriesSelectSelect(tradeOfferCategory));
      }
    },
    [dispatch, maxCount, getSelectedFromState]
  );

  const onUnselect = useCallback<TradeOfferCategoriesSelectHandlersContextValue['onUnselect']>(
    tradeOfferCategory => {
      dispatch(tradeOfferCategoriesSelectUnselect(tradeOfferCategory));
    },
    [dispatch]
  );

  const onClose = useCallback<TradeOfferCategoriesSelectHandlersContextValue['onClose']>(() => {
    if (onCloseExternal) {
      const selected = getSelectedFromState();
      onCloseExternal(selected);
    } else {
      gotoPrevIndependentLocation(getConfigurationPrivilegeRoute({}));
    }
  }, [gotoPrevIndependentLocation, onCloseExternal, getSelectedFromState]);

  const value: TradeOfferCategoriesSelectHandlersContextValue = {
    onSelect,
    onUnselect,
    onClose,
  };

  return (
    <TradeOfferCategoriesSelectHandlersContext.Provider value={value}>
      {children}
    </TradeOfferCategoriesSelectHandlersContext.Provider>
  );
};
