import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EFilterMultiSelectorValueType } from 'domain/model/enums';
import { DataFilterStrategyOrgUnitsSelector } from 'domain/model/filter';
import { OrgUnit } from 'domain/model/orgStructure';
import { Nullable } from 'domain/model/types';
import OrgStructureOrgUnitMultipleTypesSelector from '../../../../orgStructure/orgUnit/selector/multipleTypes';
import { OrgStructureOrgUnitMultipleSelectType } from '../../../../orgStructure/orgUnit/selector/multipleTypes/types';
import { DataFilterEditItemProps } from '../index';

type ValueType<T extends string> = DataFilterStrategyOrgUnitsSelector<T>['value'];

interface DataFilterEditItemOrgUnitMultipleProps<T extends string>
  extends DataFilterEditItemProps<T, Nullable<ValueType<T>>> {
  readonly strategy: DataFilterStrategyOrgUnitsSelector<T>;
}

function DataFilterEditItemOrgUnitMultipleTypes<T extends string>({
  strategy,
  onChange,
}: DataFilterEditItemOrgUnitMultipleProps<T>) {
  const { label, value, readOnly, selectNone = true } = strategy;

  const targetOrgUnits = value ?? { select: EFilterMultiSelectorValueType.In, in: null };

  const select =
    targetOrgUnits.select === EFilterMultiSelectorValueType.All
      ? OrgStructureOrgUnitMultipleSelectType.All
      : targetOrgUnits.select === EFilterMultiSelectorValueType.None
      ? OrgStructureOrgUnitMultipleSelectType.None
      : null;

  const onChangeOrgUnits = (newValue: Nullable<OrgUnit[]>) => {
    if (newValue?.length) {
      onChange(strategy, { select: EFilterMultiSelectorValueType.In, in: newValue });
    } else {
      onChange(strategy, null);
    }
  };

  const onSelect = (type: OrgStructureOrgUnitMultipleSelectType) => {
    switch (type) {
      case OrgStructureOrgUnitMultipleSelectType.All:
        onChange(strategy, { select: EFilterMultiSelectorValueType.All, in: null });
        break;
      case OrgStructureOrgUnitMultipleSelectType.None:
        onChange(strategy, { select: EFilterMultiSelectorValueType.None, in: null });
        break;
    }
  };

  return (
    <OrgStructureOrgUnitMultipleTypesSelector
      selectNone={selectNone}
      label={label}
      color='primary'
      select={select}
      disabled={readOnly}
      orgUnits={targetOrgUnits.in ?? []}
      limitTags={2}
      popupIcon={<ExpandMoreIcon fontSize='small' />}
      onChange={onChangeOrgUnits}
      onSelect={onSelect}
    />
  );
}

export default DataFilterEditItemOrgUnitMultipleTypes;
