import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { ServerErrorResponse } from '../../../../../data/network/types';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { BookingOrder } from '../../../../../domain/model/order';
import { Nullable, UUID } from '../../../../../domain/model/types';
import bookingOrderServices from '../../services';
import { BookingOrderView, EBookingOrderActionType, EBookingOrderResponsible } from '../../types';
import { convertBookingOrderViewToRequest, GetBookingOrdersDownloadUrlProps } from '../../utils/common';

type BookingOrderEntity = BookingOrder | BookingOrderView;

export type BookingOrderActionsState = {
  readonly actions: (Fetchable & {
    id: UUID;
    type: EBookingOrderActionType;
    error: Nullable<ServerErrorResponse>;
  })[];
  readonly create: Fetchable;
  readonly dialogs: {
    readonly cancel: Nullable<BookingOrderEntity>;
    readonly download: Nullable<GetBookingOrdersDownloadUrlProps>;
  };
};

const getActionProcess = (state: BookingOrderActionsState, id: UUID, actionType: EBookingOrderActionType) => {
  let process = state.actions.find(change => change.id === id);
  if (process) return process;

  process = {
    ...fetchableDefault,
    id,
    type: actionType,
    error: null,
  };
  state.actions.push(process);

  return process;
};

type DownloadDataDialogs = {
  name: 'download';
  data: Nullable<GetBookingOrdersDownloadUrlProps>;
};

type AnyActionsDataDialogs = {
  name: Exclude<keyof BookingOrderActionsState['dialogs'], 'download'>;
  data: Nullable<BookingOrderEntity>;
};

export type ActionsChangeDialogStateType = AnyActionsDataDialogs | DownloadDataDialogs;

type Reducer<T = undefined> = CaseReducer<BookingOrderActionsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<BookingOrderActionsState> {
  bookingOrderActionsChangeDialogState: Reducer<ActionsChangeDialogStateType>;
  bookingOrderActionsOptimize: Reducer;
}

export const bookingOrderActionsSave = createAsyncThunk<
  BookingOrder,
  BookingOrderView,
  AppThunkAPIConfig<{
    error: ServerErrorResponse;
  }>
>('bookingOrder/actions/save', async (order, { rejectWithValue }) => {
  try {
    return await bookingOrderServices.order.save({ id: order.id, order: convertBookingOrderViewToRequest(order) });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingOrderActionsCancel = createAsyncThunk<
  BookingOrder,
  { id: UUID; reasonId: UUID; comment?: string; responsible: EBookingOrderResponsible },
  AppThunkAPIConfig<{
    error: ServerErrorResponse;
  }>
>('bookingOrder/actions/cancel', async ({ id, comment, reasonId, responsible }, { rejectWithValue }) => {
  try {
    switch (responsible) {
      case EBookingOrderResponsible.Manager:
        return await bookingOrderServices.order.cancel({ id, comment, reasonId });
      case EBookingOrderResponsible.Customer:
        return await bookingOrderServices.order.customerCancel({ id, comment, reasonId });
    }
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});
export const bookingOrderActionsGive = createAsyncThunk<
  undefined,
  {
    id: UUID;
  },
  AppThunkAPIConfig<ServerErrorResponse>
>('bookingOrder/actions/give', async ({ id }, { rejectWithValue }) => {
  try {
    await bookingOrderServices.order.given(id);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingOrderActionsConfirm = createAsyncThunk<
  undefined,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('bookingOrder/actions/confirm', async ({ id }, { rejectWithValue }) => {
  try {
    await bookingOrderServices.order.confirm(id);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingOrderActionsRenew = createAsyncThunk<
  undefined,
  {
    id: UUID;
  },
  AppThunkAPIConfig<ServerErrorResponse>
>('bookingOrder/actions/renew', async ({ id }, { rejectWithValue }) => {
  try {
    await bookingOrderServices.order.renew(id);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

const slice = createSlice<BookingOrderActionsState, Reducers, 'bookingOrders/actions'>({
  name: 'bookingOrders/actions',
  initialState: {
    actions: [],
    create: fetchableDefault,
    dialogs: {
      cancel: null,
      download: null,
    },
  },
  reducers: {
    bookingOrderActionsChangeDialogState: (state, { payload }) => {
      const { name, data } = payload;
      if (name === 'download') {
        state.dialogs.download = data;
      } else {
        state.dialogs[name] = data;
      }
    },
    bookingOrderActionsOptimize: state => {
      // Оставляем только выполняющиеся действия
      state.actions = state.actions.filter(action => action.isFetching);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bookingOrderActionsSave.pending, (state, { meta }) => {
        const { id } = meta.arg;
        const actionType = EBookingOrderActionType.Save;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOrderActionsSave.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingOrderActionType.Save;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOrderActionsSave.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingOrderActionType.Save;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload?.error ?? null;
      })
      .addCase(bookingOrderActionsCancel.pending, (state, { meta }) => {
        const { id } = meta.arg;
        const actionType = EBookingOrderActionType.Cancel;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOrderActionsCancel.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingOrderActionType.Cancel;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOrderActionsCancel.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingOrderActionType.Cancel;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload?.error ?? null;
      })
      .addCase(bookingOrderActionsGive.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingOrderActionType.Give;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOrderActionsGive.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingOrderActionType.Give;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOrderActionsGive.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingOrderActionType.Give;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(bookingOrderActionsConfirm.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingOrderActionType.Confirm;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOrderActionsConfirm.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingOrderActionType.Confirm;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOrderActionsConfirm.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingOrderActionType.Confirm;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(bookingOrderActionsRenew.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingOrderActionType.Renew;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOrderActionsRenew.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingOrderActionType.Renew;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOrderActionsRenew.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingOrderActionType.Renew;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      });
  },
});

export const { bookingOrderActionsChangeDialogState, bookingOrderActionsOptimize } = slice.actions;

export default slice.reducer;
