import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getTradeOfferActionsOfferIsProcessingSelector, tradeOfferActionsGetActionSelector } from '../store/selectors';
import { useTradeOfferActions } from '../useActions';
import { TradeOffer } from '@/domain';
import { TradeOfferActionsConfirmDialog } from '@features/tradeOffer/actions/components';
import { Grid, Typography } from '@mui/material';
import { TradeOfferStateKeyDialogs } from '../store/slice';

const nameDialog: TradeOfferStateKeyDialogs = 'delete';

const TradeOfferActionsDialogDeleteAdapter = () => {
  const handlers = useTradeOfferActions();
  const { data, notification } = useSelector(tradeOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getTradeOfferActionsOfferIsProcessingSelector(data?.id ?? null));

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onDelete = useCallback(
    (offer: TradeOffer) => {
      handlers.onDelete(offer.id, notification).then(() => {
        onCloseDialog();
      });
    },
    [handlers.onDelete, notification]
  );

  return (
    data && (
      <TradeOfferActionsConfirmDialog
        offer={data}
        title={`Удалить безвозвратно торговое предложение?`}
        actionText='Удалить'
        isFetching={isProcessing}
        onAction={onDelete}
        onClose={onCloseDialog}
      >
        <Grid item>
          <Typography>Партнер: {data?.partner?.name}</Typography>
        </Grid>
        <Grid item>
          <Typography>Предложение: {data?.name}</Typography>
        </Grid>
      </TradeOfferActionsConfirmDialog>
    )
  );
};

export default TradeOfferActionsDialogDeleteAdapter;
