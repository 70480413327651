import Axios from 'axios';
import Api from '@/data/api';
import { ClientOrgAllProps as ApiAllProps } from '@/data/api/clientOrg';
import { ApiCancellable, ApiQueryDsl, ApiRequestListDiscriminator } from '@/data/api/types';
import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '@/data/api/utils';
import {
  ClientOrg,
  ClientOrgCreate,
  DataFilterQueryDslOperator,
  EClientOrgActionDiscriminator,
  EClientOrgStatus,
  Pageable,
} from '@/domain';
import { ClientOrgTableFilterValues } from '../filterUtils';
import {
  ClientOrgCounterByStatus,
  ClientOrgTableTabsCounter,
  getStatesFilterForClientOrgTableTab,
} from '../table/utils';
import { EClientOrgTableTab } from '../types';
import { EBookingOfferTableFilterItem } from '@features/bookingOffer/filterUtils';
import { convertClientOrgCreateToCreateRequest } from '../create/utils';

type CommandProps = {
  readonly id: UUID;
};

export type OneProps = ApiCancellable & CommandProps;

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly sort: Nullable<string>;
    readonly statuses: EClientOrgStatus[];
    readonly pageSize: number;
  };
  readonly filter: ClientOrgTableFilterValues;
  readonly pageNumber: number;
};

export type CountProps = AllProps;

type CountsByStatusesProps = CountProps;

type CountsByTabsProps = CountProps & {
  readonly tabs: EClientOrgTableTab[];
};

type SaveProps = ClientOrgCreate;

export type ClientOrgService = {
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps
  ) => ApiAllProps<D>;
  readonly all: (props: AllProps) => Promise<Pageable<ClientOrg>>;
  readonly one: (props: OneProps) => Promise<ClientOrg>;
  readonly save: (props: SaveProps) => Promise<ClientOrg>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly countsByStatuses: (
    props: CountsByStatusesProps
  ) => Promise<{ counts: ClientOrgCounterByStatus; errors: Array<string> }>;
  readonly countsByTabs: (
    props: CountsByTabsProps
  ) => Promise<{ counts: ClientOrgTableTabsCounter; errors: Array<string> }>;
  readonly block: (props: CommandProps) => Promise<ClientOrg>;
  readonly unblock: (props: CommandProps) => Promise<ClientOrg>;
};

const service: ClientOrgService = {
  buildListQueryParams: ({ search, filter, signal, pageNumber }) => {
    const { sort, statuses, pageSize } = search;

    const querydsl: ApiQueryDsl = [];
    const query = filter[EBookingOfferTableFilterItem.Query]?.value;
    const filterDsl = getQueryDslByDataFilterValues(filter);

    if (filterDsl) {
      querydsl.push(...filterDsl);
    }

    if (statuses.length > 0) {
      querydsl.push({
        value: statuses,
        field: 'status',
        operator: DataFilterQueryDslOperator.In,
      });
    }

    return {
      query,
      querydsl,
      signal,
      statuses,
      page: pageNumber,
      pageSize,
      sort,
    };
  },
  all: async props => {
    const response = await Api.clientOrg.all(service.buildListQueryParams(props));
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);

    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
  one: async props => {
    const { data } = await Api.clientOrg.one(props);
    return data;
  },
  save: async clientOrg => {
    if (!clientOrg.id) {
      return (
        await Api.clientOrg.create({
          data: convertClientOrgCreateToCreateRequest(clientOrg),
        })
      ).data;
    } else {
      return (
        await Api.clientOrg.action({
          ...clientOrg,
          id: clientOrg.id,
          discriminator: EClientOrgActionDiscriminator.ClientOrgUpdateCommand,
        })
      ).data;
    }
  },
  count: async props => {
    const { data: response } = await Api.clientOrg.all({
      ...service.buildListQueryParams(props),
      discriminator: ApiRequestListDiscriminator.Count,
    });

    return response[0].count;
  },
  countsByStatuses: async ({ signal, ...props }) => {
    const {
      search: { statuses },
    } = props;

    const errors: string[] = [];
    const counts: ClientOrgCounterByStatus = {};

    const requests = statuses.map(status => {
      const params = {
        ...props,
        search: { ...props.search },
        querydsl: [
          {
            field: 'status',
            operator: DataFilterQueryDslOperator.Equals,
            value: [status],
          },
        ],
      };
      return service.count({ ...params, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], status: EClientOrgStatus) => {
      if (response.status === 'fulfilled') {
        counts[status] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество пользователей '${status}': ${response.reason}`);
        }
      }
    };

    statuses.forEach((status, index) => parseResponse(responses[index], status));

    return { counts, errors };
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: ClientOrgTableTabsCounter = {};

    const requests = tabs.map(tab => {
      const statuses = getStatesFilterForClientOrgTableTab(tab);
      const params = {
        ...props,
        search: {
          ...props.search,
          statuses,
        },
      };
      return service.count({ ...params, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], tab: EClientOrgTableTab) => {
      if (response.status === 'fulfilled') {
        counts[tab] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество пользователей '${tab}': ${response.reason}`);
        }
      }
    };

    tabs.forEach((tab, index) => parseResponse(responses[index], tab));

    return { counts, errors };
  },
  block: async ({ id }) => {
    return (
      await Api.clientOrg.action({
        id,
        discriminator: EClientOrgActionDiscriminator.ClientOrgBlockCommand,
      })
    ).data;
  },
  unblock: async ({ id }) => {
    return (
      await Api.clientOrg.action({
        id,
        discriminator: EClientOrgActionDiscriminator.ClientOrgUnblockCommand,
      })
    ).data;
  },
};

export default service;
