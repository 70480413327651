import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getTradeOfferActionsOfferIsProcessingSelector, tradeOfferActionsGetActionSelector } from '../store/selectors';
import { useTradeOfferActions } from '../useActions';
import { TradeOffer } from '@/domain';
import OfferArchiveDialog from '@features/general/offer/components/archiveDialog';
import { TradeOfferStateKeyDialogs } from '../store/slice';

const nameDialog: TradeOfferStateKeyDialogs = 'archive';

const TradeOfferActionsDialogArchiveAdapter = () => {
  const handlers = useTradeOfferActions();
  const { data, notification } = useSelector(tradeOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getTradeOfferActionsOfferIsProcessingSelector(data?.id ?? null));

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onArchive = useCallback(
    (offer: TradeOffer) => {
      handlers.onArchive(offer.id, notification).then(() => {
        onCloseDialog();
      });
    },
    [handlers.onArchive, notification]
  );

  return (
    data && (
      <OfferArchiveDialog
        offer={data}
        offerLabel='торговое предложение'
        isExecuting={isProcessing}
        onExecute={onArchive}
        onClose={onCloseDialog}
      />
    )
  );
};

export default TradeOfferActionsDialogArchiveAdapter;
