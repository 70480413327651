import banner, { CmsBannerService } from './cmsBanner';
import collection, { CmsCollectionService } from './cmsCollection';
import component, { CmsComponentService } from './cmsComponent';
import container, { CmsContainerService } from './cmsContainer';
import sitePage, { CmsSitePageService } from './cmsSitePage';
import term, { CmsTermService } from './cmsTerm';

type CmsServices = {
  readonly sitePage: CmsSitePageService;
  readonly container: CmsContainerService;
  readonly component: CmsComponentService;
  readonly banner: CmsBannerService;
  readonly collection: CmsCollectionService;
  readonly term: CmsTermService;
};

const cmsServices: CmsServices = {
  sitePage,
  container,
  component,
  banner,
  collection,
  term,
};

export default cmsServices;
