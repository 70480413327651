import { DataTreeItem } from '../../../../../domain/model';
import { BookingServiceCategory } from '../../../../../domain/model/booking';
import { EServiceOrderByDateType } from '../../../../../domain/model/enums';
import { serviceOrderByDateType } from '../../../general/nsi';

export const buildBookingServiceCategoryTree = (
  bookingServiceCategories: BookingServiceCategory[]
): DataTreeItem<BookingServiceCategory>[] => {
  return bookingServiceCategories.map(bookingServiceCategory =>
    buildBookingServiceCategoryTreeItem(bookingServiceCategory)
  );
};

export const buildBookingServiceCategoryTreeItem = (
  bookingServiceCategory: BookingServiceCategory
): DataTreeItem<BookingServiceCategory> => {
  return {
    id: bookingServiceCategory.id,
    label: bookingServiceCategory.name,
    parentId: null,
    data: bookingServiceCategory,
    children: [
      {
        id: EServiceOrderByDateType.Period,
        label: serviceOrderByDateType[EServiceOrderByDateType.Period].name,
        parentId: bookingServiceCategory.id,
        data: bookingServiceCategory,
        children: null,
      },
      {
        id: EServiceOrderByDateType.Days,
        label: serviceOrderByDateType[EServiceOrderByDateType.Days].name,
        parentId: bookingServiceCategory.id,
        data: bookingServiceCategory,
        children: null,
      },
      {
        id: EServiceOrderByDateType.None,
        label: serviceOrderByDateType[EServiceOrderByDateType.None].name,
        parentId: bookingServiceCategory.id,
        data: bookingServiceCategory,
        children: null,
      },
    ],
  };
};
