import { createAsyncThunk } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { ApprovalOfferRegistryShort } from '../../../../../domain/model/offerApprovalRegistry';
import { Nullable } from '../../../../../domain/model/types';
import approvalRegistryServices from '../../services';

export type OfferApprovalRegistrySearchFetchProps = {
  number: Nullable<string>;
};

export const offerApprovalRegistrySuggestionFetch = createAsyncThunk<
  ApprovalOfferRegistryShort[],
  OfferApprovalRegistrySearchFetchProps,
  AppThunkAPIConfig
>('offerApprovalRegistry/search/fetch', async ({ number: registryNumber }, { rejectWithValue, signal }) => {
  try {
    return approvalRegistryServices.offer.all({
      registryNumber,
      signal,
    });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});
