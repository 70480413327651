import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../data/store/types';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { MpUserData } from '../../../../../domain/model/user';

export const partnerEmployeeCreateSave = createAsyncThunk<
  void,
  { partnerId: UUID; partnerEmployee: MpUserData },
  AppThunkAPIConfig
>('partner/employee/create/save', async ({ partnerId, partnerEmployee }, { rejectWithValue }) => {
  try {
    await Api.partner.createManager({ partnerId, partnerEmployee });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface PartnerEmployeeCreateState {
  partnerId: Nullable<UUID>;
  data: Nullable<MpUserData>;
  save: Fetchable;
}

type Reducer<T> = CaseReducer<PartnerEmployeeCreateState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PartnerEmployeeCreateState> {
  partnerEmployeeCreateInit: Reducer<UUID>;
  partnerEmployeeCreateReset: Reducer<undefined>;
  partnerEmployeeCreateSetAttribute: Reducer<{ name: keyof MpUserData; value: any }>;
}

const slice = createSlice<PartnerEmployeeCreateState, Reducers, 'create'>({
  name: 'create',
  initialState: {
    partnerId: null,
    data: null,
    save: {
      ...fetchableDefault,
    },
  },
  reducers: {
    partnerEmployeeCreateReset: state => {
      state.partnerId = null;
      state.data = null;
      state.save = fetchableDefault;
    },
    partnerEmployeeCreateInit: (state, { payload }) => {
      state.partnerId = payload;
      state.data = {
        email: '',
        phone: null,
        lastName: '',
        firstName: '',
        middleName: null,
        photo: null,
        locality: null,
        birthDate: null,
        gender: null,
      };
      state.save = fetchableDefault;
    },
    partnerEmployeeCreateSetAttribute: (state, { payload }) => {
      const { name, value } = payload;
      if (state.data) {
        (state.data[name] as keyof MpUserData) = value;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(partnerEmployeeCreateSave.pending, state => {
        state.save = fetchableFetching;
      })
      .addCase(partnerEmployeeCreateSave.fulfilled, state => {
        state.save = fetchableFetched;
      })
      .addCase(partnerEmployeeCreateSave.rejected, state => {
        state.save = fetchableFailed;
      });
  },
});

export const { partnerEmployeeCreateSetAttribute, partnerEmployeeCreateInit, partnerEmployeeCreateReset } =
  slice.actions;

export default slice.reducer;
