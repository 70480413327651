import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import styled from '@emotion/styled';

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip
    {...props}
    classes={{ popper: className }}
  />
))`
  .MuiTooltip-tooltip {
    background-color: ${p => p.theme.palette.text.secondary};
    color: ${p => p.theme.palette.secondary.contrastText};

    margin-left: ${p => p.theme.spacing(4)};
    margin-top: ${p => p.theme.spacing()};

    border: 1px solid ${p => p.theme.palette.disabled.background};
    border-radius: ${p => Number(p.theme.shape.borderRadius) * 2}px;
  }
`;
