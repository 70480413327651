import { Link as MuiLink } from '@mui/material';
import styled from '@emotion/styled';

export const StyledFileLink = styled(MuiLink)`
  width: 100%;

  display: block;
  border-radius: ${p => p.theme.shape.borderRadius}px;

  &:hover {
    background-color: ${p => p.theme.palette.primary.alpha90};
  }
`;

export const StyledBlankFileLink = styled.div`
  display: block;
  border-radius: ${p => p.theme.shape.borderRadius}px;

  &:hover {
    background-color: ${p => p.theme.palette.primary.alpha90};
  }
`;
