import { ReportsTableCell, ReportsTableCellCommonProps } from './index';

export const ReportsTableCellName = (props: ReportsTableCellCommonProps) => {
  const {
    report: { name: value = '-' },
  } = props;

  return (
    <ReportsTableCell
      {...props}
      value={value}
    />
  );
};
