import { BreadcrumbsContext } from './prodiver';

// @ts-ignore
import React, { __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED as ReactInternals, Context, useEffect } from 'react';

// this works exactly the same as `useContext()` but doesn't log error
// @see https://github.com/facebook/react/issues/14815#issuecomment-462194923
const useContext = <T,>(context: Context<T>, observedBits: undefined | boolean) =>
  ReactInternals.ReactCurrentDispatcher.current.readContext<T>(context, observedBits);

const useBreadcrumbsActions = () => {
  const { putHead: putHeadBreadcrumbs, put: putBreadcrumbs } = useContext(BreadcrumbsContext, false)!;

  useEffect(() => () => putBreadcrumbs([]), []);

  return {
    putHeadBreadcrumbs,
    putBreadcrumbs,
  };
};

export default useBreadcrumbsActions;
