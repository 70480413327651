import { useCallback, useMemo } from 'react';
import { AppUserSpecific, MpUserShort } from '../../../../../../domain/model/user';
import { AdminMpActions, AdminMpActionTableType, GetAdminMpActionsByPermissions } from '../../types';
import { EAdminMpTableTab, getAdminMpTableTabActions } from '../utils';

type ActionsType = AdminMpActions<AdminMpActionTableType>;

type UseAdminMpTableActions = {
  readonly getAdminMpActions: (admin: MpUserShort) => ActionsType;
};

interface UseAdminMpTableActionsProps extends GetAdminMpActionsByPermissions {
  readonly tab: EAdminMpTableTab;
  readonly user: AppUserSpecific;
}

const useAdminMpTableActions = (props: UseAdminMpTableActionsProps): UseAdminMpTableActions => {
  const { canActivate, canDeactivate, canSave, canEdit, tab, user } = props;

  const tableTabActions = useMemo<ActionsType>(
    () =>
      getAdminMpTableTabActions(
        {
          canActivate,
          canDeactivate,
          canSave,
          canEdit,
        },
        tab
      ),
    [canActivate, canDeactivate, canSave, canEdit, tab]
  );

  const getAdminMpActions = useCallback(
    (admin: MpUserShort) => {
      const disabled = user.id === admin.id;
      return tableTabActions.map(action => ({ ...action, disabled }));
    },
    [tableTabActions, user]
  );

  return {
    getAdminMpActions,
  };
};

export default useAdminMpTableActions;
