import { IconButtonProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledIconButton } from './controls';

const CloseButton = (props: IconButtonProps) => {
  return (
    <StyledIconButton
      size='large'
      {...props}
    >
      <CloseIcon />
    </StyledIconButton>
  );
};

export default CloseButton;
