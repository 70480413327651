import { Moment } from 'moment';
import { DataFilterStrategyDate } from '../../../../../../domain/model/filter';
import { EDateFormat } from '../../../../../../domain/model/formats';
import { Nullable } from '../../../../../../domain/model/types';
import { MPDatePicker } from '../../../../../theme/ui-kit/picker';
import { DataFilterEditItemProps } from '../index';

interface DataFilterEditItemDateProps<T extends string> extends DataFilterEditItemProps<T, Nullable<string>> {
  readonly strategy: DataFilterStrategyDate<T>;
}

function DataFilterEditItemDate<T extends string>({ strategy, onChange }: DataFilterEditItemDateProps<T>) {
  const { label, value, readOnly } = strategy;

  const onSubmitValue = (newValue: Nullable<Moment>) => {
    if (!newValue) {
      onChange(strategy, null);
    } else {
      onChange(strategy, newValue.format(EDateFormat.Server));
    }
  };

  return (
    <MPDatePicker
      label={label}
      value={value}
      disabled={readOnly}
      onChange={date => onSubmitValue(date)}
    />
  );
}

export default DataFilterEditItemDate;
