import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Partner } from '../../../domain/model/partner';
import { UUID } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import useBreadcrumbsActions from '../../features/general/breadcrumbs/useBreadcrumbsActions';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import { getPartnerManagementEmployeesRoute } from '../../features/partner/management/entry';
import PartnerEmployeeEditContainer from '../../features/partnerEmployee/edit/container';
import { EPartnerEmployeeStep } from '../../features/partnerEmployee/utils';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';
import useHistoryExtensions from '../../hooks/useHistoryExtensions';

interface PartnerManagementEmployeeEditScreenProps {
  readonly partner: Partner;
}

const PartnerManagementEmployeeEditScreen = ({ partner }: PartnerManagementEmployeeEditScreenProps) => {
  const dispatch = useDispatch();
  const { putBreadcrumbs } = useBreadcrumbsActions();
  const { partnerEmployees } = useCurrentUser().accessMatrix;

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const { employeeId } = useParams<{ employeeId: UUID }>();

  const onClose = () => {
    gotoPrevIndependentLocation(getPartnerManagementEmployeesRoute({ id: partner.id }));
  };

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  useEffect(() => {
    putBreadcrumbs([{ label: 'Сотрудники' }]);
  }, []);

  if (!partnerEmployees?.edit) {
    return <ForbiddenComponent />;
  }

  return (
    <PartnerEmployeeEditContainer
      id={employeeId}
      step={EPartnerEmployeeStep.Profile}
      onClose={onClose}
    />
  );
};

export default PartnerManagementEmployeeEditScreen;
