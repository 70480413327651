import axios, { CancelTokenSource } from 'axios';
import debounce from 'lodash/debounce';
import { useEffect, useRef, useState } from 'react';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { Address, AddressId } from '../../../../../domain/model/address';
import { EAddressLevel } from '../../../../../domain/model/enums';
import addressServices from '../../service';

interface UseAddressSelectorProps {
  readonly count?: number;
  readonly level?: EAddressLevel;
  readonly fromLevel?: EAddressLevel;
  readonly toLevel?: EAddressLevel;
  readonly locations?: AddressId[];
  readonly onlyValidPostalCode?: boolean;
  //строгий поиск (только те которые есть в ГАР,только с координатами, нет районов города в ответе)
  readonly strict?: boolean;
  readonly filterBy?: (address: Address) => boolean;
  readonly sortBy?: (address1: Address, address2: Address) => number;
}

interface UseAddressSelectorResult {
  readonly suggestions: Address[];
  readonly isLoading: boolean;
  readonly onSearch: (value: string) => void;
}

const useAddressSelector = (props: UseAddressSelectorProps): UseAddressSelectorResult => {
  const {
    count,
    onlyValidPostalCode,
    level,
    fromLevel = EAddressLevel.Region,
    toLevel = EAddressLevel.Apartment,
    locations,
    strict,
    filterBy,
    sortBy,
  } = props;

  const [suggestions, setSuggestions] = useState<Address[]>([]);
  const [search, setSearch] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSearchInternal = useRef(debounce(newValue => setSearch(newValue), 500)).current;

  const onSearch = (newValue: string) => {
    if (newValue) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    onSearchInternal(newValue);
  };

  useEffect(() => {
    if (search) {
      const cancelCallback: CancelTokenSource = axios.CancelToken.source();
      const cancelToken = cancelCallback.token;

      addressServices.common
        .addressByQuery({ query: search, strict, count, level, fromLevel, toLevel, locations, cancelToken })
        .then(data => {
          let newSuggestions = data.filter(item => !onlyValidPostalCode || item.postalCode !== null).map(item => item);

          if (filterBy) {
            newSuggestions = newSuggestions.filter(filterBy);
          }
          if (sortBy) {
            newSuggestions = newSuggestions.sort(sortBy);
          }

          setSuggestions(newSuggestions);
        })
        .catch(e => {
          ErrorHandler.handleHttpError(e, e.response);
        })
        .finally(() => {
          setIsLoading(false);
        });

      return () => {
        cancelCallback.cancel();
      };
    }
  }, [search]);

  return {
    suggestions,
    isLoading,
    onSearch,
  };
};

export default useAddressSelector;
