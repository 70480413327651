import { FormControlLabel, Grid, Radio, Typography } from '@mui/material';
import Splitter from '../../../../../components/common/splitter';

interface PartnerDetailsInfoCompanyRequisitesProps {
  readonly bic: string;
  readonly checkingAccount: string;
  readonly kpp: string;
  readonly ogrn: string;
  readonly taxSystemName: string;
}

const PartnerDetailsInfoCompanyRequisites = (props: PartnerDetailsInfoCompanyRequisitesProps) => {
  const { bic, checkingAccount, kpp, ogrn, taxSystemName } = props;

  return (
    <Grid
      container
      spacing={3}
      direction='column'
    >
      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          БИК
        </Typography>
        <Typography>{bic ?? '-'}</Typography>
      </Grid>
      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          Расчётный счет компании
        </Typography>
        <Typography>{checkingAccount ?? '-'}</Typography>
      </Grid>
      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          ОГРН
        </Typography>
        <Typography>{ogrn ?? '-'}</Typography>
      </Grid>
      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          КПП
        </Typography>
        <Typography>{kpp ?? '-'}</Typography>
      </Grid>

      <Grid item>
        <Splitter variant='horizontal' />
        <Typography
          variant='subtitle2'
          gutterBottom
        >
          Система налогообложения
        </Typography>
        <FormControlLabel
          disabled
          checked
          control={<Radio />}
          label={<Typography color='textPrimary'>{taxSystemName}</Typography>}
        />
      </Grid>
    </Grid>
  );
};

export default PartnerDetailsInfoCompanyRequisites;
