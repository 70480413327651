import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { EOfferTableTab } from '../../../general/offer/types';
import { getStatesFilterForOfferTableTab } from '../../../general/offer/utils/table';
import {
  corpOffersFilterSelector,
  corpOffersNeedRefreshWatcherSelector,
  corpOffersPageNumberSelector,
  corpOffersSearchSelector,
} from '../store/selectors';
import {
  corpOffersCountsFetch,
  corpOffersCountsUnwatchedFetch,
  corpOffersFetch,
  CorpOffersFetchProps,
  corpOffersNeedRefreshWatcherReset,
  corpOffersUnwatchedFetch,
} from '../store/slice';
import { useCorpOffersTableConfig } from './useCorpOffersTableConfig';

interface UseCorpOffersTableSearchProps {
  readonly tab: EOfferTableTab;
  readonly partnerId: Nullable<UUID>;
}

const getStatusesByTab = (t: EOfferTableTab) => getStatesFilterForOfferTableTab(t, []);

const useCorpOffersTableSearch = (props: UseCorpOffersTableSearchProps): void => {
  const { tab, partnerId } = props;

  const dispatch = useDispatch();

  const { tabsForCounts, tabsForCountsUnwatched } = useCorpOffersTableConfig();

  const [searchPromises, setSearchPromises] = useState<Nullable<any[]>>(null);

  const needRefreshWatcher = useSelector(corpOffersNeedRefreshWatcherSelector);
  const pageNumber = useSelector(corpOffersPageNumberSelector);
  const search = useSelector(corpOffersSearchSelector);
  const filter = useSelector(corpOffersFilterSelector);

  const currentStatuses = getStatusesByTab(tab);

  const queryParams = useMemo<CorpOffersFetchProps>(() => {
    return {
      filter,
      search: {
        ...search,
        statuses: currentStatuses,
        partnerId,
        categories: null,
      },
      pageNumber,
    };
  }, [filter, search, currentStatuses, partnerId, pageNumber]);

  const onSearch = useCallback(() => {
    setSearchPromises([
      dispatch(corpOffersFetch(queryParams)),
      dispatch(corpOffersCountsFetch({ ...queryParams, tabs: tabsForCounts })),
      dispatch(corpOffersCountsUnwatchedFetch({ ...queryParams, tabs: tabsForCountsUnwatched })),
      dispatch(corpOffersUnwatchedFetch(queryParams)),
    ]);
  }, [dispatch, queryParams]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  // сброс вотчера после ухода
  useEffect(() => {
    return () => {
      dispatch(corpOffersNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};

export default useCorpOffersTableSearch;
