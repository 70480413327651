import { combineReducers } from '@reduxjs/toolkit';
import byId, { OrderByIdState } from './byId/store/slice';

interface OrderState {
  byId: OrderByIdState;
}

export default combineReducers<OrderState>({
  byId,
});
