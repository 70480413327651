import { isAdCampaignsAnyChangedSelector } from '@features/adCampaign/actions/store/selectors';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { adCampaignActionsOptimize } from '../store/slice';

const AdCampaignActionsOptimizerAdapter = () => {
  const dispatch = useDispatch();
  const needOptimize = useSelector(isAdCampaignsAnyChangedSelector);

  useEffect(() => {
    if (needOptimize > 0) {
      dispatch(adCampaignActionsOptimize());
    }
  }, [needOptimize]);

  return null;
};

export default AdCampaignActionsOptimizerAdapter;
