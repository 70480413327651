import { Grid, Typography } from '@mui/material';
import { OfferDataShort } from '../../../../../../domain/model';
import { CorpOfferShort } from '../../../../../../domain/model/corpOffer';
import { TradeOfferShort } from '../../../../../../domain/model/tradeOffer';
import { Nullable } from '../../../../../../domain/model/types';
import ConfirmDialog from '../../../../../components/common/dialogs/confirm';
import ContentLoader from '../../../../../components/common/loader';
import { MPButton } from '../../../../../theme/ui-kit/button';

interface OfferArchiveDialogProps<T> {
  readonly offer: Nullable<T>;
  readonly offerLabel: string;
  readonly isExecuting: boolean;
  readonly onExecute: (offer: T) => void;
  readonly onClose: () => void;
}

const OfferArchiveDialog = <T extends OfferDataShort | CorpOfferShort | TradeOfferShort>(
  props: OfferArchiveDialogProps<T>
) => {
  const { offer, isExecuting, offerLabel, onExecute, onClose } = props;

  return (
    <ConfirmDialog
      open={!!offer}
      onClose={onClose}
      title={`Отправить ${offerLabel.toLowerCase()} в архив?`}
      dialogButtons={
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              disabled={isExecuting}
              onClick={() => onExecute(offer!)}
            >
              Отправить в архив
              {isExecuting && <ContentLoader />}
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              disabled={isExecuting}
              fullWidth={false}
              onClick={onClose}
            >
              Отменить
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        spacing={2}
        wrap='nowrap'
        direction='column'
      >
        <Grid item>
          <Typography>Партнер: {offer?.partner?.name}</Typography>
        </Grid>
        <Grid item>
          <Typography>Предложение: {offer?.name}</Typography>
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
};

export default OfferArchiveDialog;
