import styled from '@emotion/styled';
import { ListItem as MuiListItem } from '@mui/material';
import { viewConfig } from '../../../theme/viewConfig';

export const ListItem = styled(MuiListItem)`
  padding-left: ${p => p.theme.spacing(3)};
  padding-right: ${p => p.theme.spacing(3)};

  & .MuiListItemText-primary {
    font-weight: 600;
    margin-bottom: ${p => p.theme.spacing(0.5)};
    margin-left: ${p => p.theme.spacing()};
  }

  & .MuiListItemText-secondary {
    margin-left: ${p => p.theme.spacing()};
  }
`;

export const LoaderWrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};
  height: 100%;

  z-index: 3;
  position: fixed;
  top: 0;
`;

export const FooterActionsWrapper = styled.div`
  border-top: 1px solid ${p => p.theme.palette.secondary.main};
  padding: ${p => p.theme.spacing(2)};
`;
