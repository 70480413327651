import { Popover, Typography } from '@mui/material';
import styled from '@emotion/styled';

export const MorePopover = styled(Popover)`
  .MuiPaper-root {
    padding: ${p => p.theme.spacing(1.5)};
  }
`;

export interface ItemsMoreProps {
  readonly offsetLeft: number;
}

export const ItemsMore = styled.div<ItemsMoreProps>`
  position: absolute;
  left: ${p => p.offsetLeft}px;
  top: 0;

  margin-left: ${p => p.theme.spacing(2)};

  .MuiChip-root {
    cursor: pointer;
    color: ${p => p.theme.palette.text.secondary};

    :hover {
      color: ${p => p.theme.palette.primary.main};
      background-color: ${p => p.theme.palette.secondary.main};
      box-shadow: none;

      svg {
        color: ${p => p.theme.palette.primary.main};
      }
    }
  }
`;

export const ChipLabel = styled(Typography)`
  max-width: 18rem;

  overflow: hidden;
  text-overflow: ellipsis;
`;
