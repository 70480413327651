import StepperValidationLabelAdapter from '@components/common/stepper/adapters/validationLabel';
import { FCC } from 'react';
import { useSelector } from 'react-redux';
import { ClientOrgCreateStep } from '../../types';
import { clientOrgCreateValidationStepperSelector } from '../store/selectors';

interface ClientOrgCreateStepperLabelAdapterProps {
  readonly step: ClientOrgCreateStep;
}

export const ClientOrgCreateStepperLabelAdapter: FCC<ClientOrgCreateStepperLabelAdapterProps> = props => {
  const { step, children } = props;

  const validation = useSelector(clientOrgCreateValidationStepperSelector);

  return <StepperValidationLabelAdapter validation={validation?.[step.key]}>{children}</StepperValidationLabelAdapter>;
};
