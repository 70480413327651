import { ButtonProps, Menu } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React, { forwardRef, useEffect } from 'react';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { Button } from './controls';
import { getMenuButtonTestId } from './testUtuls';

type MenuButtonProps = ButtonProps & {
  readonly id: UUID;
  readonly closeMarker?: Nullable<symbol>;
  readonly disablePropagation?: boolean;
  readonly children: any;
};

const MenuButton = forwardRef(
  ({ id, closeMarker, disablePropagation, children, ...others }: MenuButtonProps, ref: any) => {
    const [anchorEl, setAnchorEl] = React.useState<any>(null);

    const { size = 'small', variant = 'text', color = 'secondary', fullWidth = false } = others;

    const onOpen = (event: any) => {
      setAnchorEl(event.currentTarget);

      if (disablePropagation) {
        event.stopPropagation();
      }
    };

    const onClose = (event: any) => {
      event.stopPropagation();
      setAnchorEl(null);
    };

    const onClick = (event: any) => {
      event.stopPropagation();
      event.preventDefault();
    };

    useEffect(() => {
      if (closeMarker) {
        setAnchorEl(null);
      }
    }, [closeMarker]);

    const menuId = `menu-${id}`;
    return (
      <div data-testid={getMenuButtonTestId(id)}>
        <Button
          size={size}
          variant={variant}
          color={color}
          fullWidth={fullWidth}
          onClick={onOpen}
        >
          <MoreHorizIcon color={color} />
        </Button>

        <Menu
          ref={ref}
          id={menuId}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={onClose}
          onClick={onClick}
        >
          {children}
        </Menu>
      </div>
    );
  }
);

export default MenuButton;
