import {
  CmsComponent,
  CmsContainer,
  ContentTargetNsi,
  ECmsBannerLinkObjectType,
  ECmsCollectionLinkObjectType,
  ECmsContainerType,
  ECmsLinkObjectType,
  EMultiSelectorValueType,
  EOfferType,
  Nullable,
} from '@/domain';
import { clientOrgRzdCode } from '@features/clientOrg/utils';
import { groupBy } from 'lodash';
import { AddressHelper } from '../../../../utils/address';
import { capitalizeFirstLetter } from '../../../../utils/strings';
import { CmsContainerView, CmsLinkedObject } from '../../types';
import { CmsLinkedObjectBanner } from '../../types/banner';
import { CmsContainerStep, CmsContentTarget } from '../types';

export const getCmsContainerComponentCountByType = (type: ECmsContainerType): number => {
  switch (type) {
    case ECmsContainerType.Banner1:
    case ECmsContainerType.Banner1Resizable:
    case ECmsContainerType.Banner1LinkResizable:
      return 1;
    case ECmsContainerType.Banner2:
    case ECmsContainerType.Banner2MobileWide:
      return 2;
    case ECmsContainerType.Banner3Resizable:
    case ECmsContainerType.Banner3:
      return 3;
    case ECmsContainerType.Banner4High:
    case ECmsContainerType.Banner4:
    case ECmsContainerType.Category4Offer:
      return 4;
    case ECmsContainerType.Banner3Carousel:
      return 3;
    case ECmsContainerType.CompilationOffer:
    case ECmsContainerType.CompilationPartner:
      return 1;
  }
};

export const getCmsLinkObjectType = (containerType: ECmsContainerType): ECmsLinkObjectType => {
  switch (containerType) {
    case ECmsContainerType.Banner1:
    case ECmsContainerType.Banner2:
    case ECmsContainerType.Banner3:
    case ECmsContainerType.Banner4:
    case ECmsContainerType.Banner4High:
    case ECmsContainerType.Banner3Carousel:
    case ECmsContainerType.Banner1Resizable:
    case ECmsContainerType.Banner1LinkResizable:
    case ECmsContainerType.Banner3Resizable:
    case ECmsContainerType.Banner2MobileWide:
    case ECmsContainerType.Category4Offer:
      return ECmsLinkObjectType.Banner;
    case ECmsContainerType.CompilationOffer:
    case ECmsContainerType.CompilationPartner:
      return ECmsLinkObjectType.Collection;
  }
};

export const getCmsCollectionLinkObjectTypeByOfferType = (offerType: EOfferType): ECmsCollectionLinkObjectType => {
  switch (offerType) {
    case EOfferType.Trade:
      return ECmsCollectionLinkObjectType.TradeOffer;
    case EOfferType.Corp:
      return ECmsCollectionLinkObjectType.CorpOffer;
    // todo убрать или чтото придумать
    case EOfferType.Product:
    case EOfferType.Booking:
      return ECmsCollectionLinkObjectType.CorpOffer;
  }
};

export const getEmptyCmsLinkedObjectsByType = (type: ECmsContainerType): CmsLinkedObject[] => {
  const emptyContent = {} as any;
  switch (type) {
    case ECmsContainerType.Banner1:
    case ECmsContainerType.Banner1Resizable:
    case ECmsContainerType.Banner1LinkResizable:
      return [{ type: ECmsLinkObjectType.Banner, banner: emptyContent }];
    case ECmsContainerType.Banner2:
    case ECmsContainerType.Banner2MobileWide:
      return [
        { type: ECmsLinkObjectType.Banner, banner: emptyContent },
        { type: ECmsLinkObjectType.Banner, banner: emptyContent },
      ];
    case ECmsContainerType.Banner3:
    case ECmsContainerType.Banner3Carousel:
    case ECmsContainerType.Banner3Resizable:
      return [
        { type: ECmsLinkObjectType.Banner, banner: emptyContent },
        { type: ECmsLinkObjectType.Banner, banner: emptyContent },
        { type: ECmsLinkObjectType.Banner, banner: emptyContent },
      ];
    case ECmsContainerType.Banner4:
    case ECmsContainerType.Banner4High:
    case ECmsContainerType.Category4Offer:
      return [
        { type: ECmsLinkObjectType.Banner, banner: emptyContent },
        { type: ECmsLinkObjectType.Banner, banner: emptyContent },
        { type: ECmsLinkObjectType.Banner, banner: emptyContent },
        { type: ECmsLinkObjectType.Banner, banner: emptyContent },
      ];
    case ECmsContainerType.CompilationOffer:
      return [
        {
          type: ECmsLinkObjectType.Collection,
          collection: {
            id: null,
            linkObjectType: ECmsCollectionLinkObjectType.TradeOffer,
            linkedObject: [{} as any, {} as any, {} as any, {} as any],
            zone: null,
            pageSize: null,
          },
        },
      ];
    case ECmsContainerType.CompilationPartner:
      return [
        {
          type: ECmsLinkObjectType.Collection,
          collection: {
            id: null,
            linkObjectType: ECmsCollectionLinkObjectType.Partner,
            linkedObject: [],
            zone: null,
            pageSize: null,
          },
        },
      ];
  }
};

export const getCmsContainerViewTarget = (cmsContainer: CmsContainerView): CmsContentTarget => {
  return {
    targetGender: cmsContainer.targetGender,
    targetRoads: cmsContainer.targetRoads,
    targetLocalities: cmsContainer.targetLocalities,
    targetOrgUnits: cmsContainer.targetOrgUnits,
    targetType: cmsContainer.targetType,
    targetTradeUnionMembersOnly: cmsContainer.targetTradeUnionMembersOnly,
    targetFamilyMemberOnly: cmsContainer.targetFamilyMemberOnly,
    targetHavingChildFamilyMemberOnly: cmsContainer.targetHavingChildFamilyMemberOnly,
    targetExternalUsers: cmsContainer.targetExternalUsers,
    targetClientOrgs: cmsContainer.targetClientOrgs,
    targetHasRzdSocialPackage: cmsContainer.targetHasRzdSocialPackage,
    startDate: cmsContainer.startDate,
    endDate: cmsContainer.endDate,
  };
};

export const getCmsLinkedObjectsLinkToNames = (linkedObjects: CmsLinkedObject[]): string[] => {
  return linkedObjects
    .filter(linkedObject => !!linkedObject)
    .map(linkedObject => {
      switch (linkedObject.type) {
        case ECmsLinkObjectType.Banner:
          return getCmsLinkedObjectBannerLinkToName(linkedObject) ?? '';
        case ECmsLinkObjectType.Collection:
          return 'страницу подборки';
      }
    })
    .filter(name => !!name);
};

export const getCmsContainerTargetItems = (cmsContainer: CmsContainer, nsi: ContentTargetNsi): string[] => {
  const names: string[] = [];

  const { genderTypes } = nsi;
  const {
    targetLocalities,
    targetRoads,
    targetOrgUnits,
    targetGender,
    targetClientOrgs,
    targetExternalUsers,
    targetFamilyMemberOnly,
    targetTradeUnionMembersOnly,
    targetHavingChildFamilyMemberOnly,
    targetHasRzdSocialPackage,
  } = cmsContainer.target;

  if (targetRoads) {
    if (targetRoads.select === EMultiSelectorValueType.All) {
      names.push('все дороги');
    } else {
      if (targetRoads.in?.length) {
        names.push(...targetRoads.in.map(road => `${road.name} дорога`));
      }
    }
  }
  if (targetOrgUnits) {
    if (targetOrgUnits.select === EMultiSelectorValueType.All) {
      names.push('все филиалы');
    } else {
      if (targetOrgUnits.in?.length) {
        names.push(...targetOrgUnits.in.map(orgUnit => orgUnit.name));
      }
    }
  }
  if (targetGender) {
    if (targetGender.select === EMultiSelectorValueType.All) {
      names.push('для всех полов');
    } else {
      if (targetGender.in?.length) {
        const targetGenders = targetGender.in;
        names.push(
          ...genderTypes.filter(genderType => targetGenders.includes(genderType.id)).map(genderType => genderType.name)
        );
      }
    }
  }

  if (targetLocalities) {
    if (targetLocalities.select === EMultiSelectorValueType.All) {
      names.push('вся Россия');
    } else {
      if (targetLocalities.in?.length) {
        names.push(
          ...targetLocalities.in.map(
            locality => new AddressHelper(locality).getLastLocalityShortPath() ?? locality.name
          )
        );
      }
    }
  }
  if (targetExternalUsers) {
    names.push('внешние пользователи');
  }
  if (targetClientOrgs) {
    if (targetClientOrgs.select === EMultiSelectorValueType.All) {
      names.push('все компании');
    } else {
      if (targetClientOrgs.in?.length) {
        names.push(
          ...targetClientOrgs.in.sort((a, b) => (a.code === clientOrgRzdCode ? -1 : 1)).map(clientOrg => clientOrg.name)
        );
      }
    }
  }
  if (targetHasRzdSocialPackage) {
    names.push('КСП');
  }
  if (targetFamilyMemberOnly) {
    names.push('если есть член семьи ОТ 18 лет');
  }
  if (targetHavingChildFamilyMemberOnly) {
    names.push('если есть член семьи ДО 18 лет');
  }
  if (targetTradeUnionMembersOnly) {
    names.push('для членов профсоюза');
  }

  return names.filter(item => !!item);
};

export const getCmsLinkedObjectBannerLinkToName = (banner: CmsLinkedObjectBanner): Nullable<string> => {
  switch (banner.banner.linkObjectType) {
    case ECmsBannerLinkObjectType.CorpOffer:
    case ECmsBannerLinkObjectType.TradeOffer:
      return 'карточку';
    case ECmsBannerLinkObjectType.TradeOfferCategory:
    case ECmsBannerLinkObjectType.CorpOfferCategory:
      return 'категорию';
    case ECmsBannerLinkObjectType.Collection:
      return 'страницу подборки';
    case ECmsBannerLinkObjectType.Link:
      return banner.banner.url;
    case null:
      return null;
  }
  return null;
};

const getCmsContainerStepLabel = (
  linkObjectType: Nullable<ECmsLinkObjectType>,
  number: number,
  count: number
): string => {
  const withCounter = count > 1;
  let counter = '';
  if (withCounter) {
    switch (number) {
      case 0:
        counter = 'первый ';
        break;
      case 1:
        counter = 'второй ';
        break;
      case 2:
        counter = 'третий ';
        break;
      case 3:
        counter = 'четвертый ';
        break;
      default:
        console.error(`Step counter for value '${number}' not supported`);
    }
  }

  let label: string;
  switch (linkObjectType) {
    case ECmsLinkObjectType.Banner:
      label = 'Баннер';
      break;
    default:
      label = 'Состав блока';
  }

  return capitalizeFirstLetter(`${counter}${label}`.toLowerCase());
};

export const getCmsContainerSteps = (cmsComponents: CmsComponent[]): CmsContainerStep[] => {
  const steps: CmsContainerStep[] = [{ key: 0, label: 'Данные блока' }];
  Object.values<CmsComponent[]>(groupBy(cmsComponents, cmsComponent => cmsComponent.linkObjectType)).map(group =>
    group.forEach((item, index) =>
      steps.push({
        key: steps.length,
        label: getCmsContainerStepLabel(item.linkObjectType, index, group.length),
      })
    )
  );
  return steps;
};
