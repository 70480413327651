import { Typography } from '@mui/material';
import { SportOption } from '../../../../../../domain/model';
import { Nullable } from '../../../../../../domain/model/types';
import SectionTitle from '../../../../../components/common/sectionTitle';
import { Wrapper } from './controls';

interface PartnerRejectInfoPanelProps {
  readonly rejectReason: SportOption;
  readonly text?: Nullable<string>;
}

// TODO - сделать панель с причиной отклонения общую на весь проект (есть еще в ТР и КП)
const PartnerRejectInfoPanel = ({ rejectReason, text }: PartnerRejectInfoPanelProps) => {
  return (
    <Wrapper>
      <SectionTitle>Причина отклонения</SectionTitle>
      <Typography
        variant='body2'
        gutterBottom
      >
        {rejectReason.name}
      </Typography>
      <Typography variant='body2'>{text}</Typography>
    </Wrapper>
  );
};

export default PartnerRejectInfoPanel;
