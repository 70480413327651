import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nullable, UUID } from '../../../../../domain/model/types';
import {
  partnerEmployeesFilterSelector,
  partnerEmployeesNeedRefreshWatcherSelector,
  partnerEmployeesPageNumberSelector,
  partnerEmployeesSearchSelector,
} from '../store/selectors';
import {
  partnerEmployeeCountsFetch,
  partnerEmployeesFetch,
  PartnerEmployeesFetchProps,
  partnerEmployeesNeedRefreshWatcherReset,
} from '../store/slice';
import { EPartnerEmployeeTableTab, getPartnerEmployeeStatusesByTableTab } from '../utils';
import { useContextConfig } from './useContextConfig';

interface UseCustomerTableSearchProps {
  readonly tab: EPartnerEmployeeTableTab;
  readonly partnerId: UUID;
}

const usePartnerEmployeeTableSearch = (props: UseCustomerTableSearchProps): void => {
  const { tab, partnerId } = props;

  const dispatch = useDispatch();

  const { tabsForCounts } = useContextConfig();

  const [searchPromises, setSearchPromises] = useState<Nullable<any[]>>(null);

  const needRefreshWatcher = useSelector(partnerEmployeesNeedRefreshWatcherSelector);
  const pageNumber = useSelector(partnerEmployeesPageNumberSelector);
  const search = useSelector(partnerEmployeesSearchSelector);
  const filter = useSelector(partnerEmployeesFilterSelector);

  const currentStatuses = getPartnerEmployeeStatusesByTableTab(tab);

  const queryParams = useMemo<PartnerEmployeesFetchProps>(() => {
    return {
      filter,
      search: {
        ...search,
        statuses: currentStatuses,
        partnerId,
      },
      pageNumber,
    };
  }, [filter, search, currentStatuses, pageNumber]);

  const onSearch = useCallback(() => {
    setSearchPromises([
      dispatch(partnerEmployeesFetch(queryParams)),
      dispatch(partnerEmployeeCountsFetch({ ...queryParams, tabs: tabsForCounts })),
    ]);
  }, [dispatch, queryParams, tabsForCounts]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  // сброс вотчера после ухода
  useEffect(() => {
    return () => {
      dispatch(partnerEmployeesNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};

export default usePartnerEmployeeTableSearch;
