import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { UUID } from '../../../domain/model/types';
import rootRouting from '../../../routing';
import NotFoundScreen from '../../screens/notFound';
import PartnerDeskEditScreen from '../../screens/partnerDeskEdit';
import { EPartnerDeskEditStep } from './edit/utils';

const routing = {
  root: `${rootRouting.partnerDesks}`,
  edit: `${rootRouting.partnerDesks}/:id/desk/edit`,
};

export enum EPartnerDeskUrlParam {
  Step = 'step',
}

interface GetPartnerDeskEditRouteProps {
  readonly step?: EPartnerDeskEditStep;
  readonly partnerId: UUID;
}

export const getPartnerDeskEditRoute = (props: GetPartnerDeskEditRouteProps) => {
  const { partnerId, step } = props;

  const searchParams = new URLSearchParams();

  if (step) {
    searchParams.append(EPartnerDeskUrlParam.Step, step);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.edit.replace(':id', partnerId)}${search}`;
};

const PartnerDesksEntry = () => {
  return (
    <Switch>
      <Route
        path={routing.edit}
        component={PartnerDeskEditScreen}
      />

      <Route component={NotFoundScreen} />
    </Switch>
  );
};

export default PartnerDesksEntry;
