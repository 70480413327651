import { SportOptionTyped } from '../../../../../../../domain/model';
import { EPartnerOwnershipType } from '../../../../../../../domain/model/enums';
import { PartnerCompanyDataDraft, PartnerDataDraft } from '../../../../../../../domain/model/partner';
import { Nullable } from '../../../../../../../domain/model/types';
import { OnChangeObjectAttribute } from '../../../../../../types';
import { ValidationResult } from '../../../../../../utils/validation';
import { PartnerGeneralCompany, PartnerGeneralIndividual } from '../../../../../general/partner/validation';
import PartnerApplicationEditCommonGeneral from './common';
import PartnerApplicationEditCompanyGeneral from './company';
import PartnerApplicationEditIndividualGeneral from './individual';

interface PartnerApplicationEditStepGeneralProps {
  readonly partnerApplication: PartnerDataDraft;
  readonly ownerShipTypes: SportOptionTyped<EPartnerOwnershipType>[];
  readonly validationCompany: Nullable<ValidationResult<PartnerGeneralCompany>>;
  readonly validationIndividual: Nullable<ValidationResult<PartnerGeneralIndividual>>;
  readonly isValid: boolean;
  readonly onChangeOrgType: (value: EPartnerOwnershipType) => void;
  readonly onChangeAttribute: OnChangeObjectAttribute<PartnerDataDraft>;
  readonly onChangeCompanyAttribute: OnChangeObjectAttribute<PartnerCompanyDataDraft>;
}

const PartnerApplicationEditStepGeneral = (props: PartnerApplicationEditStepGeneralProps) => {
  const {
    partnerApplication,
    ownerShipTypes,
    isValid,
    validationCompany,
    validationIndividual,
    onChangeOrgType,
    onChangeAttribute,
    onChangeCompanyAttribute,
  } = props;

  const { inn, name, ownerFullName, email, website, phone } = partnerApplication;

  if (!partnerApplication.regInfo?.orgType) {
    return (
      <PartnerApplicationEditCommonGeneral
        ownerShipTypes={ownerShipTypes}
        onChangeOrgType={onChangeOrgType}
      />
    );
  }

  switch (partnerApplication.regInfo?.orgType) {
    case EPartnerOwnershipType.IndividualEntrepreneur:
      return (
        <PartnerApplicationEditIndividualGeneral
          inn={inn}
          name={name}
          ownerFullName={ownerFullName}
          email={email}
          website={website}
          phone={phone}
          ownerShipTypes={ownerShipTypes}
          validation={validationIndividual}
          isValid={isValid}
          onChangeOrgType={onChangeOrgType}
          onChangeAttribute={onChangeAttribute}
        />
      );
    case EPartnerOwnershipType.StockCompany:
    case EPartnerOwnershipType.PublicJoinStockCompany:
    case EPartnerOwnershipType.PK:
    case EPartnerOwnershipType.UP:
    case EPartnerOwnershipType.FGBNU:
    case EPartnerOwnershipType.NonProfitOrganization:
    case EPartnerOwnershipType.ClosedJoinStockCompany:
    case EPartnerOwnershipType.JoinStockCompany:
    case EPartnerOwnershipType.NAO:
    case EPartnerOwnershipType.AutonomousNonProfitOrganization:
    case EPartnerOwnershipType.LimitedLiabilityCompany: {
      return (
        <PartnerApplicationEditCompanyGeneral
          ownerShipType={partnerApplication.regInfo.orgType}
          inn={inn}
          name={name}
          companyName={partnerApplication.regInfo.companyName ?? null}
          ownerFullName={ownerFullName}
          email={email}
          website={website}
          phone={phone}
          ownerShipTypes={ownerShipTypes}
          validation={validationCompany}
          isValid={isValid}
          onChangeOrgType={onChangeOrgType}
          onChangeAttribute={onChangeAttribute}
          onChangeCompanyAttribute={onChangeCompanyAttribute}
        />
      );
    }
    default:
      return (
        <PartnerApplicationEditCommonGeneral
          ownerShipTypes={ownerShipTypes}
          onChangeOrgType={onChangeOrgType}
        />
      );
  }
};

export default PartnerApplicationEditStepGeneral;
