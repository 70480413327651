import { useReportDownloads } from '@features/report/downloads/useDownloads';
import { EReportType } from '@features/report/types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { reportDownloadsReportsSelector } from '../store/selectors';
import { UsersReportContainer } from '../reports/users';

export const ReportDownloadsUsersAdapter = () => {
  const handlers = useReportDownloads();

  const { users: open } = useSelector(reportDownloadsReportsSelector);

  const onClose = useCallback(() => {
    handlers.onCloseDownloadDialog(EReportType.Users);
  }, [handlers.onCloseDownloadDialog]);

  return open && <UsersReportContainer onClose={onClose} />;
};
