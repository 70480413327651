import styled from '@emotion/styled';
import { viewConfig } from '../../../../theme/viewConfig';

export const Wrapper = styled.div`
  height: 100%;
  max-width: 100%;

  display: flex;
  flex-direction: column;

  padding-bottom: ${viewConfig.table.paginationSize};
`;
