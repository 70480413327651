import { MutableRefObject } from 'react';
import { useSelector } from 'react-redux';
import { CmsContainer } from '../../../../../domain/model/cms';
import { UUID } from '../../../../../domain/model/types';
import { CmsContainerLifeCycle } from '../lifecycle/types';
import CmsContainersTableAdapter from './adapters/table';
import { CmsContainersTableTabsAdapter } from './adapters/tabs';
import { CmsContainersTableConfigContext, CmsContainersTableContext } from './context';
import { useCmsContainersTableHandlers } from './hooks/useCmsContainersTable';
import { useCmsContainersTableConfig } from './hooks/useCmsContainersTableConfig';
import useCmsContainerTableSearch from './hooks/useSearch';
import useCmsContainerTableSession from './hooks/useSession';
import CmsContainerTableLayout from './layout/container';
import { cmsContainersGuidSelector, cmsContainersTabSelector } from './store/selectors';
import { ECmsContainerTableTab, getCmsContainerTableTabActions } from './utils';

interface CmsContainerTableContainerProps {
  readonly guid: UUID;
  readonly sitePageId: UUID;
  readonly tab?: ECmsContainerTableTab;
  readonly scrollAnchorRef: MutableRefObject<HTMLElement | undefined>;
  readonly lifecycle: CmsContainerLifeCycle;
  readonly canEdit?: boolean;
  readonly canCreate?: boolean;
  readonly canPause?: boolean;
  readonly canResume?: boolean;
  readonly canArchive?: boolean;
  readonly canChangeSortIndex?: boolean;
  readonly canDelete?: boolean;
  readonly onCmsContainerOpenEdit: (cmsContainer: CmsContainer) => void;
  readonly onCmsContainerOpenView: (cmsContainer: CmsContainer) => void;
}

const CmsContainerTableContainer = (props: CmsContainerTableContainerProps) => {
  const {
    sitePageId,
    guid,
    tab,
    scrollAnchorRef,
    lifecycle,
    canEdit,
    canCreate,
    canPause,
    canResume,
    canArchive,
    canChangeSortIndex,
    canDelete,
    onCmsContainerOpenEdit,
    onCmsContainerOpenView,
  } = props;

  const handlers = useCmsContainersTableHandlers();
  const config = useCmsContainersTableConfig({ canCreate, onCmsContainerOpenView, onCmsContainerOpenEdit });

  const currentGuid = useSelector(cmsContainersGuidSelector);
  const tableTab = useSelector(cmsContainersTabSelector);

  const currentTab = tab ?? tableTab;

  const tabActions = getCmsContainerTableTabActions(
    {
      canCreate,
      canEdit,
      canResume,
      canPause,
      canArchive,
      canDelete,
      canChangeSortIndex,
    },
    currentTab
  );

  useCmsContainerTableSession({ guid, tab: currentTab });
  useCmsContainerTableSearch({ sitePageId, tab: currentTab });

  if (guid !== currentGuid) {
    return null;
  }

  return (
    <CmsContainersTableConfigContext.Provider value={config}>
      <CmsContainersTableContext.Provider value={handlers}>
        <CmsContainerTableLayout
          tabs={<CmsContainersTableTabsAdapter tab={currentTab} />}
          table={
            <CmsContainersTableAdapter
              tab={currentTab}
              lifecycle={lifecycle}
              sitePageId={sitePageId}
              tabActions={tabActions}
            />
          }
          tableScrollAnchorRef={scrollAnchorRef}
        />
      </CmsContainersTableContext.Provider>
    </CmsContainersTableConfigContext.Provider>
  );
};

export default CmsContainerTableContainer;
