import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../data/store/store';
import { EOrderStatus } from '../../../../../domain/model/enums';

export const bookingOrderDetailsIsPreloadingSelector = (store: RootState) => store.bookingOrder.details.byId.isFetching;
export const bookingOrderDetailsFetchIsFailedSelector = (store: RootState) => store.bookingOrder.details.byId.isFailed;
export const bookingOrderDetailsBookingOrderSelector = (store: RootState) =>
  store.bookingOrder.details.byId.bookingOrder;
export const bookingOrderDetailsBookingOrderObjectNameSelector = (store: RootState) =>
  store.bookingOrder.details.byId.bookingOrder?.offer.name ?? null;
export const bookingOrderDetailsBookingOrderCostSelector = (store: RootState) =>
  store.bookingOrder.details.byId.bookingOrder?.cost ?? null;
export const bookingOrderDetailsBookingOrderItemsSelector = (store: RootState) =>
  store.bookingOrder.details.byId.orderItems ?? [];
export const bookingOrderDetailsBookingOrderForLifeCycleSelector = createSelector(
  (store: RootState) => store.bookingOrder.details.byId.bookingOrder?.id ?? null,
  (store: RootState) => store.bookingOrder.details.byId.bookingOrder?.status ?? EOrderStatus.New,
  (store: RootState) => store.bookingOrder.details.byId.orderItems,
  (id, status, orderItems) => ({
    id,
    status,
    orderItems,
  })
);
export const bookingOrderDetailsDialogsSelector = (store: RootState) => store.bookingOrder.details.dialogs;
