import { Grid, Link, Typography } from '@mui/material';
import { Wrapper } from './controls';

interface DataFilterDialogHeaderProps {
  readonly text?: boolean;
  readonly onClear?: () => void;
}

function DataFilterDialogHeader(props: DataFilterDialogHeaderProps) {
  const { text = 'Поиск и фильтр', onClear } = props;

  return (
    <Wrapper>
      <Grid
        container
        spacing={2}
        justifyContent='space-between'
      >
        <Grid item>
          <Typography variant='subtitle2'>{text}</Typography>
        </Grid>
        {onClear && (
          <Grid item>
            <Typography
              component={Link}
              color='textSecondary'
              onClick={onClear}
            >
              Сбросить все
            </Typography>
          </Grid>
        )}
      </Grid>
    </Wrapper>
  );
}

export default DataFilterDialogHeader;
