import { useCallback, useState } from 'react';
import { Address } from '../../../../../../domain/model/address';
import { MPAutocompleteSingleSelect } from '../../../../../theme/ui-kit/autocomplete/single';
import { AddressHelper } from '../../../../../utils/address';
import { AddressManualInputActionElement } from '../../elements/manualInputAction';
import { AddressSelectorProps } from '../types';
import useAddressSelector from '../useAddressSelector';
import { AddressPaperComponent } from './paper';

export interface AddressSingleSelectorProps extends AddressSelectorProps<Address> {
  readonly onManualAddress?: (address: Nullable<Address>) => void;
}

const getOptionLabelDefault = (address: Address) => new AddressHelper(address).getFullPath();
const filterOptionsDefault = (options: Address[]) => options;

const AddressSingleSelector = (props: AddressSingleSelectorProps) => {
  const {
    error,
    disabled,
    helperText,
    count,
    onlyValidPostalCode,
    label,
    level,
    fromLevel,
    toLevel,
    value,
    locations,
    filterOptions = filterOptionsDefault,
    filterBy,
    sortBy,
    groupBy,
    onChange,
    onManualAddress,
    getOptionLabel = getOptionLabelDefault,
    ...others
  } = props;

  const [searchInput, setSearchInput] = useState<string>();

  const { suggestions, isLoading, onSearch } = useAddressSelector(props);

  const onSearchValue = (value: string) => {
    setSearchInput(value);
    onSearch(value);
  };

  const onManualAddressInternal = useCallback(() => {
    onManualAddress?.(suggestions?.[0] ?? null);
  }, [suggestions, onManualAddress]);

  return (
    <MPAutocompleteSingleSelect<Address>
      {...others}
      value={value}
      inputValue={searchInput}
      controlled
      options={suggestions}
      label={label}
      isLoading={isLoading}
      error={error}
      disabled={disabled}
      helperText={helperText}
      filterOptions={filterOptions}
      groupBy={groupBy?.(suggestions)}
      PaperComponent={props => (
        <AddressPaperComponent
          {...props}
          footer={
            onManualAddress &&
            ((searchInput && !isLoading) || !!suggestions?.length) && (
              <AddressManualInputActionElement onAction={onManualAddressInternal} />
            )
          }
        />
      )}
      getOptionLabel={getOptionLabel}
      onSearchValue={onSearchValue}
      onChangeValue={onChange}
    />
  );
};

export default AddressSingleSelector;
