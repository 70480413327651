import { ReactNode, useMemo } from 'react';
import { ClientOrgUser } from '../../../../../../domain/model/user';
import DataTableCellTextFormat from '../../../../../components/common/table/cell/textFormat';

export interface ClientOrgUserTableCellCommonProps {
  readonly clientOrgUser: ClientOrgUser;
  readonly isDisabled?: boolean;
  readonly isTextAccent?: boolean;
}

interface ClientOrgUserTableCellProps extends ClientOrgUserTableCellCommonProps {
  readonly value: ReactNode;
}

const ClientOrgUserTableCell = ({ value, isDisabled, isTextAccent }: ClientOrgUserTableCellProps) => {
  return useMemo(
    () => (
      <DataTableCellTextFormat
        disabled={isDisabled}
        bold={isTextAccent}
      >
        {value}
      </DataTableCellTextFormat>
    ),
    [isDisabled, isTextAccent, value]
  );
};

export default ClientOrgUserTableCell;
