import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { MpUser } from '../../../domain/model/user';
import ForbiddenComponent from '../../components/common/forbidden';
import { viewShowCustomerManagementCommonMenu } from '../../features/customer/management/common/store/slice';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import { TradeActivationsLocationState } from '../../features/tradeActivation/entry';
import TradeActivationTableEntry from '../../features/tradeActivation/table/entry';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface CustomerManagementTradeActivationsScreenProps {
  readonly customer: MpUser;
}

const CustomerManagementTradeActivationsScreen = ({ customer }: CustomerManagementTradeActivationsScreenProps) => {
  const dispatch = useDispatch();

  const {
    accessMatrix: { tradeActivations, isPartnerUser },
    mpPartnerUserProfile,
  } = useCurrentUser();

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowCustomerManagementCommonMenu());
  }, [dispatch]);

  if (!tradeActivations.view) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<TradeActivationsLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <TradeActivationTableEntry
          guid={state.guid}
          partnerId={isPartnerUser ? mpPartnerUserProfile.partner.id : null}
          customerId={customer.id}
        />
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default CustomerManagementTradeActivationsScreen;
