import {
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
  Typography as MuiTypography,
} from '@mui/material';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding-top: ${p => p.theme.spacing(3)};
`;

export const ListWrapper = styled.div`
  flex-grow: 1;
`;

export const SizedListItem = styled(MuiListItemButton)`
  width: auto;
  height: 6rem;
  padding: ${p => p.theme.spacing(1)};

  & .Mui-selected {
    background: ${p => p.theme.palette.secondary.main};
  }

  .MuiListItemAvatar-root {
    margin-right: ${p => p.theme.spacing(3)};
  }

  .MuiListItemText-root {
  }

  &:hover {
    & .MuiAvatar-root {
      background-color: ${p => p.theme.palette.secondary.light};
    }
  }
`;

export const SplitterListItem = styled(MuiListItem)`
  width: auto;
  height: 6rem;
  padding: ${p => p.theme.spacing()};
`;

export const LoaderListItem = styled(SizedListItem)``;

export const AvatarWrapper = styled.div`
  width: 2.625rem;
  height: 2.625rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background: ${p => p.theme.palette.avatar.background};
`;

const Typography = styled(MuiTypography)`
  word-break: break-word;
`;

export const OverflowedTypography = styled(MuiTypography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled(Typography)`
  font-weight: 600;
`;
