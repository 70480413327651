import { useContextHandlers } from '@features/customer/table/hooks/useContextHandlers';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import TablePagination from '../../../components/common/table/pagination';
import {
  customersPageCountSelector,
  customersPageNumberSelector,
  customersSearchSelector,
  customersTotalCountSelector,
} from './store/selectors';

interface CustomerTableFooterContainerProps {}

const CustomerTableFooterContainer = forwardRef((props: CustomerTableFooterContainerProps, ref: any) => {
  const handlers = useContextHandlers();

  const pageNumber = useSelector(customersPageNumberSelector);
  const search = useSelector(customersSearchSelector);
  const totalCount = useSelector(customersTotalCountSelector);
  const pageCount = useSelector(customersPageCountSelector);

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='покупателей'
        onChangePageSize={handlers.onChangePageSize}
        onChangePage={handlers.onChangePageNumber}
      />
    </div>
  );
});

export default CustomerTableFooterContainer;
