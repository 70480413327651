import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { ProductOffer } from '../../../../../../domain/model/productOffer';
import ConfirmDialog from '../../../../../components/common/dialogs/confirm';
import ContentLoader from '../../../../../components/common/loader';
import { MPButton } from '../../../../../theme/ui-kit/button';
import { MPNumberInput } from '../../../../../theme/ui-kit/input';

interface ProductChangeStockDialogProps {
  readonly productOffer: ProductOffer;
  readonly isExecuting: boolean;
  readonly onExecute: (stock: number) => void;
  readonly onClose: () => void;
}

const ProductChangeStockDialog = (props: ProductChangeStockDialogProps) => {
  const { productOffer, isExecuting, onExecute, onClose } = props;

  const [localValue, setLocalValue] = useState<ProductOffer['stock']>(null);

  const onInternalChange = () => {
    if (localValue !== null && localValue >= 0) {
      onExecute(localValue);
    }
  };

  return (
    <ConfirmDialog
      open
      onClose={onClose}
      title='Обновление остатка'
      dialogButtons={
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              disabled={isExecuting}
              onClick={onInternalChange}
            >
              Сохранить
              {isExecuting && <ContentLoader />}
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              fullWidth={false}
              onClick={onClose}
            >
              Отмена
              {isExecuting && <ContentLoader />}
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        spacing={2}
        wrap='nowrap'
        direction='column'
      >
        <Grid item>
          <Typography>{productOffer.name}</Typography>
          <Typography>Текущий остаток: {productOffer.stock ?? 0}</Typography>
        </Grid>
        <Grid item>
          <MPNumberInput
            label='Новый остаток'
            value={localValue}
            disabled={isExecuting}
            allowNegative={false}
            isNumericString
            decimalScale={0}
            onChange={(event, newValue) => setLocalValue(newValue)}
          />
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
};

export default ProductChangeStockDialog;
