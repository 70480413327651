import { Grid } from '@mui/material';
import React from 'react';
import { TradeOffer } from '@/domain';
import ConfirmDialog from '../../../../../components/common/dialogs/confirm';
import ContentLoader from '../../../../../components/common/loader';
import { MPButton } from '@ui-kit/button';
import Typography from '@mui/material/Typography';

interface TradeOfferActionsConfirmDialogProps {
  readonly offer: TradeOffer;
  readonly title: string;
  readonly actionText: string;
  readonly text?: React.ReactNode | string;
  readonly children?: React.ReactNode;
  readonly actionDisabled?: boolean;
  readonly isFetching: boolean;
  readonly onAction?: (offer: TradeOffer) => void;
  readonly onClose: () => void;
}

export const TradeOfferActionsConfirmDialog = <T extends object>(props: TradeOfferActionsConfirmDialogProps) => {
  const { offer, title, text, actionText, isFetching, actionDisabled = false, children, onAction, onClose } = props;

  return (
    <ConfirmDialog
      open
      onClose={onClose}
      title={title}
      dialogButtons={
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              disabled={actionDisabled || isFetching}
              onClick={() => onAction?.(offer)}
            >
              {actionText}
              {isFetching && <ContentLoader />}
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              disabled={isFetching}
              fullWidth={false}
              onClick={onClose}
            >
              Отменить
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        spacing={2}
        wrap='nowrap'
        direction='column'
      >
        {text && (
          <Grid item>
            <Typography>{text}</Typography>
          </Grid>
        )}
        {children && <Grid item>{children}</Grid>}
      </Grid>
    </ConfirmDialog>
  );
};
