import { UUID } from '@/domain';
import { ReportsTableConfigContext } from '@features/report/table/context';
import { useReportsTableConfig } from '@features/report/table/hooks/useReportsTableConfig';
import ReportsTableContainer from './container';

type ReportsTableEntryProps = {
  readonly guid: UUID;
  readonly partnerId: Nullable<UUID>;
};

export const ReportsTableEntry = (props: ReportsTableEntryProps) => {
  const { guid, partnerId } = props;

  const config = useReportsTableConfig({ partnerId });

  return (
    <ReportsTableConfigContext.Provider value={config}>
      <ReportsTableContainer guid={guid} />
    </ReportsTableConfigContext.Provider>
  );
};
