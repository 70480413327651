import { Grid } from '@mui/material';
import { FileDescription } from '../../../../../../domain/model';
import PartnerDetailsInfoDocument from './document';

interface PartnerDetailsInfoCompanyDocumentsProps {
  readonly ogrnCert: FileDescription;
  readonly innCert: FileDescription;
  readonly ceoAppointmentProtocol: FileDescription;
  readonly charterDoc: FileDescription;
}

const PartnerDetailsInfoCompanyDocuments = (props: PartnerDetailsInfoCompanyDocumentsProps) => {
  const { ogrnCert, innCert, ceoAppointmentProtocol, charterDoc } = props;

  return (
    <Grid
      container
      spacing={3}
      direction='column'
    >
      <Grid item>
        <PartnerDetailsInfoDocument
          label='Выписка из ЕГРЮЛ'
          guid={ogrnCert?.path}
        />
      </Grid>
      <Grid item>
        <PartnerDetailsInfoDocument
          label='Свидетельство ИНН'
          guid={innCert?.path}
        />
      </Grid>
      <Grid item>
        <PartnerDetailsInfoDocument
          label='Копия устава'
          description='Устав в полной редакции единым файлом, с отметкой о государственной регистрации'
          guid={charterDoc?.path}
        />
      </Grid>
      <Grid item>
        <PartnerDetailsInfoDocument
          label='Решение/протокол о назначении ген. директора'
          guid={ceoAppointmentProtocol?.path}
        />
      </Grid>
    </Grid>
  );
};

export default PartnerDetailsInfoCompanyDocuments;
