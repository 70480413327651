import { AdCampaignTableCell, AdCampaignTableCellCommonProps } from './index';
import moment from 'moment';
import { EDateFormat } from '@/domain';

const empty = 'не указано';

export const AdCampaignTableCellPeriod = (props: AdCampaignTableCellCommonProps) => {
  const {
    adCampaign: { startDate, endDate },
  } = props;

  const startDateValue = moment(startDate).format(EDateFormat.DisplayDefault);
  const endDateValue = moment(endDate).format(EDateFormat.DisplayDefault);
  const value = `${startDateValue ?? empty} - ${endDateValue ?? empty}`;

  return (
    <AdCampaignTableCell
      {...props}
      value={value}
    />
  );
};
