import { SportOptionTyped } from '../../../../../domain/model';
import { EPartnerType } from '../../../../../domain/model/enums';
import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from './index';

export type CorpOfferTableCellPartnerTypeProps = CorpOfferTableCellCommonProps & {
  readonly partnerTypes: SportOptionTyped<EPartnerType>[];
};

const CorpOfferTableCellPartnerType = (props: CorpOfferTableCellPartnerTypeProps) => {
  const {
    corpOffer: {
      partner: { type },
    },
    partnerTypes,
  } = props;

  const value = partnerTypes.find(item => item.id === type)?.name ?? '-';

  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellPartnerType;
