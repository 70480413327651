import { AdCampaignCreate, AdCampaignShort } from '@/domain';
import ConfirmDialog from '@components/common/dialogs/confirm';
import ContentLoader from '@components/common/loader';
import { Grid } from '@mui/material';
import { MPButton } from '@ui-kit/button';
import React from 'react';

type AdCampaignType = AdCampaignCreate | AdCampaignShort;

interface AdCampaignActionsConfirmDialogProps<T extends AdCampaignType = AdCampaignShort> {
  readonly campaign: T;
  readonly title: string;
  readonly actionText: string;
  readonly text?: React.ReactNode | string;
  readonly children?: React.ReactNode;
  readonly actionDisabled?: boolean;
  readonly isFetching: boolean;
  readonly onAction: (campaign: T) => void;
  readonly onClose: () => void;
}

const AdCampaignActionsConfirmDialog = <T extends AdCampaignType>(props: AdCampaignActionsConfirmDialogProps<T>) => {
  const { campaign, title, text, actionText, isFetching, actionDisabled = false, children, onAction, onClose } = props;

  return (
    <ConfirmDialog
      open
      onClose={onClose}
      title={title}
      dialogButtons={
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              disabled={actionDisabled || isFetching}
              onClick={() => onAction(campaign)}
            >
              {actionText}
              {isFetching && <ContentLoader />}
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              disabled={isFetching}
              fullWidth={false}
              onClick={onClose}
            >
              Отменить
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        spacing={2}
        wrap='nowrap'
        direction='column'
      >
        {text && <Grid item>{text}</Grid>}
        {children && <Grid item>{children}</Grid>}
      </Grid>
    </ConfirmDialog>
  );
};

export default AdCampaignActionsConfirmDialog;
