import React, { useEffect } from 'react';
import { SportOption } from '../../../domain/model';
import { ENoticeStatus } from '../../../domain/model/enums';
import { Nullable, UUID } from '../../../domain/model/types';
import Notifier from '../../../system/notifier';
import ContentLoader from '../../components/common/loader';
import useLockObject, { ELockObjectType, ELockStatus } from './useLockObject';

interface ObjectLockContainerProps {
  readonly id: UUID;
  readonly lockedBy: Nullable<SportOption>;
  readonly lockObjectType: ELockObjectType;
  readonly children: React.ReactNode;
  readonly onReject?: () => void;
}

const ObjectLockContainer = (props: ObjectLockContainerProps) => {
  const { id, lockedBy, lockObjectType, children, onReject } = props;

  const { status: lockStatus, message: lockErrorMessage } = useLockObject({
    id,
    lockedBy,
    type: lockObjectType,
  });

  useEffect(() => {
    if (lockErrorMessage) {
      Notifier.getInstance().addNotice(ENoticeStatus.Error, lockErrorMessage);
      onReject?.();
    }
  }, [lockErrorMessage]);

  return (
    <>
      {lockStatus === ELockStatus.Success && children}
      {lockStatus === ELockStatus.Fetching && (
        <ContentLoader
          size={75}
          alpha
        />
      )}
    </>
  );
};

export default ObjectLockContainer;
