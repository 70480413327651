import { RootState } from '@/data/store/store';
import { EBannerPartition, ESortDirection } from '@/domain';
import { EBannerTableColumn } from '@features/banner/table/utils';
import {
  createBannersActionsSelector,
  createBannersBannerByIndexSelector,
  createBannersBannersSelector,
  createBannersBannerToChangeSortIndexSelector,
  createBannersFilterSelector,
  createBannersGuidSelector,
  createBannersIsFetchingSelector,
  createBannersNeedRefreshWatcherSelector,
  createBannersPageCountSelector,
  createBannersPageNumberSelector,
  createBannersSearchSelector,
  createBannersSortIndexInfoSelector,
  createBannersSortSelector,
  createBannersTabsCounterSelector,
  createBannersTotalCountSelector,
} from './slice';

export const bannersBannersSelector = (partition: EBannerPartition) => (state: RootState) =>
  createBannersBannersSelector(state, partition);
export const bannersIsFetchingSelector = (partition: EBannerPartition) => (state: RootState) =>
  createBannersIsFetchingSelector(state, partition);
export const bannersGuidSelector = (partition: EBannerPartition) => (state: RootState) =>
  createBannersGuidSelector(state, partition);
export const bannersPageNumberSelector = (partition: EBannerPartition) => (state: RootState) =>
  createBannersPageNumberSelector(state, partition);
export const bannersTotalCountSelector = (partition: EBannerPartition) => (state: RootState) =>
  createBannersTotalCountSelector(state, partition);
export const bannersPageCountSelector = (partition: EBannerPartition) => (state: RootState) =>
  createBannersPageCountSelector(state, partition);
export const bannersSearchSelector = (partition: EBannerPartition) => (state: RootState) =>
  createBannersSearchSelector(state, partition);
export const bannersSortColumnSelector = (partition: EBannerPartition) => (state: RootState) =>
  (createBannersSortSelector(state, partition).split(',') as [EBannerTableColumn, ESortDirection])?.[0] ?? '';
export const bannersSortDirectionSelector = (partition: EBannerPartition) => (state: RootState) =>
  (createBannersSortSelector(state, partition).split(',') as [EBannerTableColumn, ESortDirection])?.[1] ?? '';
export const bannersFilterSelector = (partition: EBannerPartition) => (state: RootState) =>
  createBannersFilterSelector(state, partition);
export const bannersTabsCounterSelector = (partition: EBannerPartition) => (state: RootState) =>
  createBannersTabsCounterSelector(state, partition);
export const bannersActionsSelector = (partition: EBannerPartition) => (state: RootState) =>
  createBannersActionsSelector(state, partition);
export const bannersNeedRefreshWatcherSelector = (partition: EBannerPartition) => (state: RootState) =>
  createBannersNeedRefreshWatcherSelector(state, partition);
export const bannersSortIndexInfoSelector = (partition: EBannerPartition) => (state: RootState) =>
  createBannersSortIndexInfoSelector(state, partition);
export const bannersBannerToChangeSortIndexSelector = (partition: EBannerPartition) => (state: RootState) =>
  createBannersBannerToChangeSortIndexSelector(state, partition);
export const bannersBannerByIndexSelector = (partition: EBannerPartition, index: number) => (state: RootState) =>
  createBannersBannerByIndexSelector(state, partition, index);
