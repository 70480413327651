import TradeActivationTableCell, { TradeActivationTableCellCommonProps } from './index';

const TradeActivationTableCellOfferCode = (props: TradeActivationTableCellCommonProps) => {
  const {
    tradeActivation: {
      offer: { code },
    },
  } = props;

  return (
    <TradeActivationTableCell
      {...props}
      value={code}
    />
  );
};

export default TradeActivationTableCellOfferCode;
