import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SportsmanTableCellCommonProps } from '../../components/tableCell';
import { sportsmanSearchSportsmanByIndexSelector } from '../store/selectors';

export type SportsmansSearchCellAdapterProps = {
  readonly index: number;
  readonly component: FC<SportsmanTableCellCommonProps>;
};

const SportsmansSearchCellAdapter = (props: SportsmansSearchCellAdapterProps) => {
  const { index, component: Component } = props;

  const sportsman = useSelector(sportsmanSearchSportsmanByIndexSelector(index));

  return useMemo(() => (sportsman ? <Component sportsman={sportsman} /> : null), [Component, sportsman]);
};

export default SportsmansSearchCellAdapter;
