import { Fade, Grid, Switch, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SportEnumOption } from '../../../../domain/model';
import { CmsContainer } from '../../../../domain/model/cms';
import { ECmsContainerType } from '../../../../domain/model/enums';
import { UUID } from '../../../../domain/model/types';
import MasterActionsComponent from '../../../components/common/actions/master';
import ConfirmDialog from '../../../components/common/dialogs/confirm';
import DefaultHeader from '../../../components/common/header';
import ContentLoader from '../../../components/common/loader';
import Splitter from '../../../components/common/splitter';
import SwitchControl from '../../../components/common/switch';
import { DefaultContentWrapper } from '../../../components/common/wrappers/content';
import { PanelAction } from '../../../types';
import CmsAddContainerDialog from '../../cms/components/addDialog';
import { CmsContainerLifeCycleFactory } from '../../cms/container/lifecycle';
import { CmsContainerLifeCycle } from '../../cms/container/lifecycle/types';
import CmsContainerTableContainer from '../../cms/container/table/container';
import { ECmsContainerTableTab } from '../../cms/container/table/utils';
import { CmsFeatureContextPartner } from '../../cms/container/types';
import CmsPreviewTargetContainer from '../../cms/previewTarget/container';
import { nsiDataSelector } from '../../general/nsi/store/selectors';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import PartnerWindowStub from '../components/stub';
import { EPartnerWindowActionType } from '../types';
import { ContainerWrapper, LoaderWrapper } from './controls';
import {
  partnerWindowDetailsChangeActiveSelector,
  partnerWindowDetailsGetSelector,
  partnerWindowDetailsGuidSelector,
  partnerWindowDetailsNeedRefreshWatcherSelector,
} from './store/selectors';
import { partnerWindowChangeActive, partnerWindowDetailsStartSession, partnerWindowFetch } from './store/slice';
import usePartnerWindowActions from './useActions';

interface PartnerWindowContainerProps {
  readonly guid: UUID;
  readonly partnerId: UUID;
  readonly tab?: ECmsContainerTableTab;
  readonly canEdit?: boolean;
  readonly canCreate?: boolean;
  readonly canPause?: boolean;
  readonly canResume?: boolean;
  readonly canArchive?: boolean;
  readonly canChangeSortIndex?: boolean;
  readonly canChangePageVisible?: boolean;
  readonly canDelete?: boolean;
  readonly onAddCmsContainer: (type: ECmsContainerType) => void;
  readonly onEditCmsContainer: (cmsContainer: CmsContainer) => void;
  readonly onViewCmsContainer: (cmsContainer: CmsContainer) => void;
}

const PartnerWindowContainer = (props: PartnerWindowContainerProps) => {
  const {
    guid,
    partnerId,
    tab,
    canCreate,
    canEdit,
    canArchive,
    canChangeSortIndex,
    canChangePageVisible,
    canDelete,
    canPause,
    canResume,
    onAddCmsContainer,
    onEditCmsContainer,
    onViewCmsContainer,
  } = props;

  const dispatch = useDispatch();
  const { userSpecific } = useCurrentUser();

  const cmsContext = useMemo<CmsFeatureContextPartner>(() => ({ partner: { id: partnerId } }), [partnerId]);

  const lifecycle = useMemo<CmsContainerLifeCycle>(
    () => CmsContainerLifeCycleFactory().create({ userId: userSpecific.id }),
    [userSpecific]
  );

  const footerRef = useRef<HTMLElement>();

  const { cmsContainerTypes: nsiCmsContainerTypes } = useSelector(nsiDataSelector);
  const containerTypes = useMemo(
    () =>
      [
        ECmsContainerType.Banner1Resizable,
        ECmsContainerType.Banner2,
        ECmsContainerType.Banner3Resizable,
        ECmsContainerType.Banner4High,
        ECmsContainerType.CompilationOffer,
      ]
        .map(type => nsiCmsContainerTypes.find(nsiType => nsiType.code === type) ?? null)
        .filter(type => !!type) as SportEnumOption<ECmsContainerType>[],
    [nsiCmsContainerTypes]
  );

  const { data: cmsSitePage, isFetching } = useSelector(partnerWindowDetailsGetSelector);
  const { isFetching: changeActiveIsFetching } = useSelector(partnerWindowDetailsChangeActiveSelector);
  const currentGuid = useSelector(partnerWindowDetailsGuidSelector);
  const needRefreshWatcher = useSelector(partnerWindowDetailsNeedRefreshWatcherSelector);

  const hasContainers = !!cmsSitePage?.containers?.length;

  const [isAddBlockDialogVisible, setIsAddBlockDialogVisible] = useState<boolean>(false);
  const [isPreviewTargetDialogVisible, setIsPreviewTargetDialogVisible] = useState<boolean>(false);

  // начало сеанса
  useEffect(() => {
    if (guid !== currentGuid) {
      dispatch(partnerWindowDetailsStartSession({ guid }));
    }
  }, [dispatch, guid, currentGuid]);

  // начальная загрузка
  useEffect(() => {
    if (guid === currentGuid) {
      const promise = dispatch(partnerWindowFetch({ partnerId }));

      return () => {
        promise?.abort();
      };
    }
  }, [dispatch, guid, currentGuid, partnerId]);

  // обновление
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      const promise = dispatch(partnerWindowFetch({ partnerId }));

      return () => {
        promise?.abort();
      };
    }
  }, [dispatch, needRefreshWatcher]);

  const onChangeCmsSitePageActive = (active: boolean) => {
    if (cmsSitePage) {
      dispatch(partnerWindowChangeActive({ cmsSitePage, active }));
    }
  };

  const onPanelAction = (action: PanelAction<EPartnerWindowActionType>) => {
    switch (action.type) {
      case EPartnerWindowActionType.CreateCmsContainer: {
        setIsAddBlockDialogVisible(true);
        break;
      }
      case EPartnerWindowActionType.OpenPreview: {
        setIsPreviewTargetDialogVisible(true);
        break;
      }
    }
  };

  const actions = usePartnerWindowActions({
    canCreate,
    hasData: hasContainers,
  });

  const footer = (
    <MasterActionsComponent<EPartnerWindowActionType>
      actions={actions}
      show={!!actions.length}
      wrapperRef={footerRef}
      onAction={onPanelAction}
    />
  );

  const isLoading = isFetching || changeActiveIsFetching;
  const isEnabledSwitchVisible = hasContainers && canChangePageVisible;

  if (guid !== currentGuid) {
    return null;
  }

  return (
    <>
      {!isFetching && (
        <Fade in>
          <ContainerWrapper>
            <DefaultContentWrapper
              type='details'
              stickyHeader
              fullHeight
              footer={footer}
            >
              <DefaultHeader
                sticky
                actions={
                  isEnabledSwitchVisible && (
                    <Grid
                      container
                      spacing={4}
                      alignItems='center'
                    >
                      <Grid item>
                        <SwitchControl
                          control={<Switch color='primary' />}
                          label={
                            <Typography
                              color='textPrimary'
                              noWrap
                            >
                              Показывать страницу компании на витрине
                            </Typography>
                          }
                          labelPlacement='start'
                          checked={cmsSitePage?.enabled ?? false}
                          onChange={(event, checked) => onChangeCmsSitePageActive(checked)}
                        />
                      </Grid>
                    </Grid>
                  )
                }
              >
                <Typography variant='h2'>Страница компании</Typography>
              </DefaultHeader>
              <Splitter size={2} />
              {cmsSitePage && hasContainers ? (
                <CmsContainerTableContainer
                  sitePageId={cmsSitePage.id}
                  tab={tab}
                  guid={guid}
                  scrollAnchorRef={footerRef}
                  lifecycle={lifecycle}
                  canCreate={canCreate}
                  canEdit={canEdit}
                  canPause={canPause}
                  canArchive={canArchive}
                  canDelete={canDelete}
                  canResume={canResume}
                  canChangeSortIndex={canChangeSortIndex}
                  onCmsContainerOpenEdit={onEditCmsContainer}
                  onCmsContainerOpenView={onViewCmsContainer}
                />
              ) : (
                <PartnerWindowStub />
              )}
            </DefaultContentWrapper>
          </ContainerWrapper>
        </Fade>
      )}
      <CmsAddContainerDialog
        types={containerTypes}
        open={isAddBlockDialogVisible}
        onAdd={onAddCmsContainer}
        onClose={() => setIsAddBlockDialogVisible(false)}
      />
      <ConfirmDialog
        open={isPreviewTargetDialogVisible}
        onClose={() => setIsPreviewTargetDialogVisible(false)}
      >
        <CmsPreviewTargetContainer cmsContext={cmsContext} />
      </ConfirmDialog>
      {isLoading && (
        <LoaderWrapper>
          <ContentLoader
            size={75}
            alpha
          />
        </LoaderWrapper>
      )}
    </>
  );
};

export default PartnerWindowContainer;
