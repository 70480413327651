import BookingOfferServiceManageSectionServicePriceItem from '../fields/priceItem';

interface BookingOfferCreateServicePriceItemAdapterProps {
  readonly index: number;
  readonly serviceIndex: number;
}

const BookingOfferCreateServicePriceItemAdapter = (props: BookingOfferCreateServicePriceItemAdapterProps) => {
  return <BookingOfferServiceManageSectionServicePriceItem {...props} />;
};

export default BookingOfferCreateServicePriceItemAdapter;
