import { useSelector } from 'react-redux';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { EBookingOfferTableFilterItem } from '../../filterUtils';
import { EBookingOfferTableColumn } from '../../types';
import BookingOfferTable from '../component';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  bookingOfferSearchFilterSelector,
  bookingOfferSearchGuidSelector,
  bookingOfferSearchSelectedSelector,
  bookingOfferSearchSortColumnSelector,
  bookingOfferSearchSortDirectionSelector,
  bookingOfferSearchBookingOfferSelector,
} from '../store/selectors';

interface BookingOfferSearchTableAdapterProps {
  readonly filterAdapter: DataFilterAdapter<EBookingOfferTableColumn, EBookingOfferTableFilterItem>;
}

const BookingOfferSearchTableAdapter = (props: BookingOfferSearchTableAdapterProps) => {
  const { filterAdapter } = props;

  const handlers = useContextHandlers();
  const { isSingleSelect, onBookingOfferClick } = useContextConfig();

  const guid = useSelector(bookingOfferSearchGuidSelector);
  const bookingOffer = useSelector(bookingOfferSearchBookingOfferSelector);
  const sortColumn = useSelector(bookingOfferSearchSortColumnSelector);
  const sortDirection = useSelector(bookingOfferSearchSortDirectionSelector);
  const filter = useSelector(bookingOfferSearchFilterSelector);
  const selectedBookingOffer = useSelector(bookingOfferSearchSelectedSelector);
  const selectedBookingOfferCount = selectedBookingOffer?.length ?? 0;

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return (
    <BookingOfferTable
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      bookingOffer={bookingOffer}
      selectedBookingOffer={selectedBookingOffer}
      isSingleSelect={isSingleSelect}
      onBookingOfferSelect={(bookingOffer, selected) =>
        handlers.onBookingOfferSelect(bookingOffer, selectedBookingOfferCount, selected)
      }
      onAllBookingOfferSelect={handlers.onAllBookingOfferSelect}
      onRequestSort={handlers.onChangeSort}
      onBookingOfferClick={onBookingOfferClick}
      onChangeMetadata={onChangeMetadata}
    />
  );
};

export default BookingOfferSearchTableAdapter;
