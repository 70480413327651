import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BookingServicePriceUnit } from '../../../../../../domain/model/booking';
import BookingOfferEditFieldServicePriceItemUnit from '../../../components/fieldsEdit/priceItem/unit';
import { BookingOfferEditFieldServicePriceItemProps } from '../../../components/fieldsEdit/types';
import {
  bookingOfferServiceManageServiceByIndexSelector,
  bookingOfferServiceManageServicePriceUnitsSelector,
} from '../../store/selectros';

const fieldName = 'unit';

const BookingOfferServiceManagePriceItemFieldUnitAdapter = (
  props: BookingOfferEditFieldServicePriceItemProps<typeof fieldName>
) => {
  const { serviceIndex } = props;
  const service = useSelector(bookingOfferServiceManageServiceByIndexSelector(serviceIndex));

  const allPriceUnits = useSelector(bookingOfferServiceManageServicePriceUnitsSelector);

  const priceUnits = useMemo<BookingServicePriceUnit[]>(() => {
    const type = service?.orderByDateType;
    return type ? allPriceUnits.filter(item => item.orderByDateType === type) : [];
  }, [service, allPriceUnits]);

  return (
    <BookingOfferEditFieldServicePriceItemUnit
      {...props}
      priceUnits={priceUnits}
    />
  );
};

export default BookingOfferServiceManagePriceItemFieldUnitAdapter;
