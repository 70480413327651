import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UUID } from '../../../../../domain/model/types';
import { EOfferTableTab } from '../../../general/offer/types';
import { tradeOffersGuidSelector } from '../store/selectors';
import { tradeOffersSetTab, tradeOffersStartSession } from '../store/slice';

interface UseTradeOffersTableSessionProps {
  readonly guid: UUID;
  readonly tab: EOfferTableTab;
}

const useTradeOffersTableSession = (props: UseTradeOffersTableSessionProps): void => {
  const { guid, tab } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(tradeOffersGuidSelector);

  // инициализация сеанса
  useEffect(() => {
    dispatch(tradeOffersStartSession({ guid }));
  }, [dispatch, guid]);

  // начальная загрузка, изменение вкладки
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(tradeOffersSetTab({ tab }));
    }
  }, [dispatch, guid, currentGuid, tab]);
};

export default useTradeOffersTableSession;
