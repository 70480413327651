import { UUID } from '../../../../../../domain/model/types';
import useAdminMpTableSearch from '../hooks/useSearch';
import useAdminMpTableSession from '../hooks/useSession';
import { EAdminMpTableTab } from '../utils';

interface AdminMpTableBehaviorAdapterProps {
  readonly guid: UUID;
  readonly tab: EAdminMpTableTab;
}

export const AdminMpTableBehaviorAdapter = ({ guid, tab }: AdminMpTableBehaviorAdapterProps) => {
  useAdminMpTableSession({ guid, tab });
  useAdminMpTableSearch({ tab });
  
  return null;
};