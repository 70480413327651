import { BookingOrderStatus, EOrderStatus, Nullable, UserAccessPartitionBookingOrders } from '@/domain';
import { DataTableColumns, ETableColumnAlign, MutableDataTableColumns } from '@components/common/table';
import { EBookingOrderItemsTableColumn } from '@features/bookingOrder/utils/common';
import {
  BookingOrderActions,
  BookingOrderActionTableType,
  EBookingOrderActionType,
  EBookingOrderTableColumn,
  EBookingOrderTableTab,
} from '../types';
import { filterBookingOrderTableActions, getBookingOrderActionsConfigByPermissions } from '../utils/actions';

export type BookingOrderTableTabsCounter = {
  [name in EBookingOrderTableTab]?: number;
};

export const getBookingOrderTableVisibleColumns = (
  tab: EBookingOrderTableTab,
  partnerId: Nullable<UUID>,
  isCSP: boolean
): DataTableColumns<EBookingOrderTableColumn> => {
  const columns: EBookingOrderTableColumn[] = [];

  columns.push(
    EBookingOrderTableColumn.CreateAt,
    EBookingOrderTableColumn.ObjectCode,
    EBookingOrderTableColumn.ObjectName,
    EBookingOrderTableColumn.Number,
    EBookingOrderTableColumn.Cost,
    EBookingOrderTableColumn.CustomerName
  );

  if (tab === EBookingOrderTableTab.Cancelled) {
    columns.push(EBookingOrderTableColumn.CancelInitiator, EBookingOrderTableColumn.CancelReason);
  }

  columns.push(EBookingOrderTableColumn.ManagerComment);

  if (isCSP) {
    columns.push(EBookingOrderTableColumn.CSP);
  }

  if (!partnerId) {
    columns.push(EBookingOrderTableColumn.ObjectPartner);
  }

  const mutableTableColumns: MutableDataTableColumns<EBookingOrderTableColumn> = {};
  columns.forEach(column => (mutableTableColumns[column] = getBookingOrderTableColumns()[column]));
  return mutableTableColumns;
};

export const getBookingOrderStatusesByTableTab = (tab: EBookingOrderTableTab): BookingOrderStatus[] => {
  switch (tab) {
    case EBookingOrderTableTab.Given:
      return [EOrderStatus.Given];

    case EBookingOrderTableTab.New:
      return [EOrderStatus.New];

    case EBookingOrderTableTab.Cancelled:
      return [EOrderStatus.Cancelled];

    case EBookingOrderTableTab.Confirmed:
      return [EOrderStatus.Confirmed];
  }
};

export const getBookingOrderTableTabName = (tab: EBookingOrderTableTab) => {
  switch (tab) {
    case EBookingOrderTableTab.New:
      return 'Создан';
    case EBookingOrderTableTab.Confirmed:
      return 'Принят';
    case EBookingOrderTableTab.Given:
      return 'Выполнен';
    case EBookingOrderTableTab.Cancelled:
      return 'Отменен';
  }
};

const getBookingOrderTableColumns = (): DataTableColumns<EBookingOrderTableColumn> => {
  return {
    [EBookingOrderTableColumn.CreateAt]: {
      title: 'Дата',
      width: '7rem',
      sortable: true,
    },
    [EBookingOrderTableColumn.Number]: {
      title: 'Номер заказа',
      width: '7rem',
      sortable: true,
    },
    [EBookingOrderTableColumn.ObjectName]: {
      title: 'Объект',
      width: '15rem',
      wrap: true,
      sortable: true,
    },
    [EBookingOrderTableColumn.ObjectCode]: {
      title: 'Код объекта',
      width: '7rem',
      sortable: true,
    },
    [EBookingOrderTableColumn.ObjectPartner]: {
      title: 'Партнер',
      width: '15rem',
      wrap: true,
      sortable: true,
    },
    [EBookingOrderTableColumn.CustomerName]: {
      title: 'Клиент',
      width: '15rem',
      wrap: true,
      sortable: true,
    },
    [EBookingOrderTableColumn.Cost]: {
      title: 'Сумма',
      width: '7rem',
      sortable: true,
    },
    [EBookingOrderTableColumn.ManagerComment]: {
      title: 'Комментарий менеджера',
      width: '15rem',
      wrap: true,
      sortable: true,
    },
    [EBookingOrderTableColumn.CancelInitiator]: {
      title: 'Инициатор отмены',
      width: '7rem',
      wrap: true,
    },
    [EBookingOrderTableColumn.CancelReason]: {
      title: 'Причина отмены',
      width: '15rem',
      wrap: true,
    },
    [EBookingOrderTableColumn.CSP]: {
      title: 'КСП',
      width: '3rem',
      wrap: true,
    },
  };
};

interface GetOrdersTableTabActionsProps {
  readonly tab: EBookingOrderTableTab;
  readonly actions: BookingOrderActions<BookingOrderActionTableType>;
}

const getBookingOrderTableTabActionsConfig = ({
  tab,
  actions,
}: GetOrdersTableTabActionsProps): BookingOrderActions<BookingOrderActionTableType> => {
  switch (tab) {
    case EBookingOrderTableTab.New:
      return actions.map(item => ({ ...item, disabled: item.type === EBookingOrderActionType.Renew || item.disabled }));
    case EBookingOrderTableTab.Given:
      return actions.map(item => ({ ...item, disabled: item.type === EBookingOrderActionType.Give || item.disabled }));
    case EBookingOrderTableTab.Confirmed:
      return actions.map(item => ({
        ...item,
        disabled: item.type === EBookingOrderActionType.Confirm || item.disabled,
      }));
    case EBookingOrderTableTab.Cancelled:
      return actions.map(item => ({
        ...item,
        disabled: item.type === EBookingOrderActionType.Cancel || item.disabled,
      }));
  }
};

export const getBookingOrderTableTabActions = (
  permissions: UserAccessPartitionBookingOrders,
  tab: EBookingOrderTableTab
): BookingOrderActions<BookingOrderActionTableType> => {
  const allowedActions = getBookingOrderActionsConfigByPermissions(permissions);
  const actions = filterBookingOrderTableActions(allowedActions);
  return getBookingOrderTableTabActionsConfig({ tab, actions });
};

export const getBookingOrderTableTabForStatus = (status?: EOrderStatus): EBookingOrderTableTab => {
  switch (status) {
    case EOrderStatus.New:
      return EBookingOrderTableTab.New;
    case EOrderStatus.Given:
      return EBookingOrderTableTab.Given;
    case EOrderStatus.Active:
    case EOrderStatus.Confirmed:
      return EBookingOrderTableTab.Confirmed;
    case EOrderStatus.Returned:
    case EOrderStatus.Cancelled:
      return EBookingOrderTableTab.Cancelled;
    default:
      return EBookingOrderTableTab.Given;
  }
};

export const getBookingOrderItemsTableColumns = (): DataTableColumns<EBookingOrderItemsTableColumn> => ({
  [EBookingOrderItemsTableColumn.Category]: {
    title: 'Категория услуги',
    width: '7rem',
  },
  [EBookingOrderItemsTableColumn.Service]: {
    title: 'Наименование услуги',
    width: '15rem',
    wrap: true,
  },
  [EBookingOrderItemsTableColumn.PriceItemName]: {
    title: 'Тариф',
    width: '15rem',
    wrap: true,
  },
  [EBookingOrderItemsTableColumn.Cost]: {
    title: 'Цена',
    width: '7rem',
    align: ETableColumnAlign.Right,
  },
  [EBookingOrderItemsTableColumn.Unit]: {
    title: 'Единица',
    width: '5rem',
  },
  [EBookingOrderItemsTableColumn.BookingDatesAndQty]: {
    title: 'Даты бронирования и количество',
    width: '12rem',
  },
  [EBookingOrderItemsTableColumn.TotalCost]: {
    title: 'Сумма',
    width: '7rem',
    align: ETableColumnAlign.Right,
  },
  [EBookingOrderItemsTableColumn.Status]: {
    title: 'Статус',
    width: '7rem',
  },
});
