import { Nullable, UUID } from '@/domain/model/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  tradeActivationsFilterSelector,
  tradeActivationsNeedRefreshWatcherSelector,
  tradeActivationsPageNumberSelector,
  tradeActivationsSearchSelector,
} from '../store/selectors';
import {
  tradeActivationsFetch,
  TradeActivationsFetchProps,
  tradeActivationsNeedRefreshWatcherReset,
} from '../store/slice';

type useTradeActivationTableSearchProps = {
  readonly partnerId: Nullable<UUID>;
  readonly customerId: Nullable<UUID>;
};

export const useTradeActivationTableSearch = (props: useTradeActivationTableSearchProps) => {
  const { partnerId, customerId } = props;
  const dispatch = useDispatch();

  const [searchPromise, setSearchPromise] = useState<Nullable<any[]>>(null);

  const needRefreshWatcher = useSelector(tradeActivationsNeedRefreshWatcherSelector);
  const pageNumber = useSelector(tradeActivationsPageNumberSelector);
  const search = useSelector(tradeActivationsSearchSelector);
  const filter = useSelector(tradeActivationsFilterSelector);

  const queryParams = useMemo<TradeActivationsFetchProps>(
    () => ({
      search: {
        ...search,
        partnerId,
        customerId,
      },
      pageNumber,
      filter,
    }),
    [search, partnerId, customerId, pageNumber, filter]
  );

  const onSearch = useCallback(() => {
    setSearchPromise([dispatch(tradeActivationsFetch(queryParams))]);
  }, [queryParams]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromise?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromise?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  useEffect(() => {
    return () => {
      dispatch(tradeActivationsNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};
