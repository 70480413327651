import { Typography } from '@mui/material';
import { ContentTarget } from '../../../../../../domain/model';
import { EMultiSelectorValueType } from '../../../../../../domain/model/enums';
import { AddressHelper } from '../../../../../utils/address';
import { ContentTargetViewComponent } from '../../types';

const attribute: keyof ContentTarget = 'localities';

const ContentTargetViewLocalitiesCard: ContentTargetViewComponent = props => {
  const { target } = props;

  const names: string[] = [];

  const targetLocalities = target[attribute];

  if (targetLocalities) {
    if (targetLocalities.select === EMultiSelectorValueType.All) {
      names.push('вся Россия');
    } else {
      if (targetLocalities.in?.length) {
        names.push(
          ...targetLocalities.in.map(
            locality => new AddressHelper(locality).getLastLocalityShortPath() ?? locality.name
          )
        );
      }
    }
  }

  return (
    <>
      <Typography color='textSecondary'>Город</Typography>
      <Typography>{names.join(', ') || '-'}</Typography>
    </>
  );
};

export default ContentTargetViewLocalitiesCard;
