/*
 * сложные правила
 */

import { EProductActionType } from '../../types';
import { disableProductAction, removeProductAction } from '../../utils/actions';
import {
  hasNotProductDeskCondition,
  isNotOnModerationByMeCondition,
  isNotSavedProductCondition,
  isWaitingForModerationCondition,
} from '../conditions';
import { ProductLifeCycleActionsRule } from '../types';

export const removeDeskActionsRule: ProductLifeCycleActionsRule = {
  description: 'Удаление действий с карточкой товара',
  algorithm: [...hasNotProductDeskCondition.algorithm],
  call: props => {
    if (hasNotProductDeskCondition.call(props)) {
      removeProductAction(props.nextActions, EProductActionType.ResumeDesk);
      removeProductAction(props.nextActions, EProductActionType.PauseDesk);
      removeProductAction(props.nextActions, EProductActionType.ArchiveDesk);
      return true;
    }
    return false;
  },
};

export const disableModerateActionsRule: ProductLifeCycleActionsRule = {
  description: 'Отключение доступности модерации',
  algorithm: [...isWaitingForModerationCondition.algorithm, 'или', ...isNotOnModerationByMeCondition.algorithm],
  call: props => {
    if (isWaitingForModerationCondition.call(props) || isNotOnModerationByMeCondition.call(props)) {
      disableProductAction(props.nextActions, EProductActionType.Approve);
      disableProductAction(props.nextActions, EProductActionType.Reject);
      return true;
    }
    return false;
  },
};

export const removeEditActionRule: ProductLifeCycleActionsRule = {
  description: 'Удаление возможности редактирования',
  algorithm: [...isNotOnModerationByMeCondition.algorithm],
  call: props => {
    if (isNotOnModerationByMeCondition.call(props)) {
      removeProductAction(props.nextActions, EProductActionType.Edit);
      return true;
    }
    return false;
  },
};

export const disableChangeModeratorActionRule: ProductLifeCycleActionsRule = {
  description: 'Отключение доступности изменения модератора',
  algorithm: [...isWaitingForModerationCondition.algorithm],
  call: props => {
    if (isWaitingForModerationCondition.call(props)) {
      disableProductAction(props.nextActions, EProductActionType.ChangeModerator);
      return true;
    }
    return false;
  },
};

export const removeDeleteActionRule: ProductLifeCycleActionsRule = {
  description: 'Удаление доступности удаления',
  algorithm: [...isNotSavedProductCondition.algorithm],
  call: props => {
    if (isNotSavedProductCondition.call(props)) {
      removeProductAction(props.nextActions, EProductActionType.Delete);
      return true;
    }
    return false;
  },
};

export const removeDuplicateActionRule: ProductLifeCycleActionsRule = {
  description: 'Удаление доступности дублирования',
  algorithm: [...isNotSavedProductCondition.algorithm],
  call: props => {
    if (isNotSavedProductCondition.call(props)) {
      removeProductAction(props.nextActions, EProductActionType.Duplicate);
      return true;
    }
    return false;
  },
};
