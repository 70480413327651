import { Fade, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../data/store/store';
import { SportOptionTyped } from '../../../../domain/model';
import { EOrderPartition, EOrderStatus } from '../../../../domain/model/enums';
import { ProductOrder, ProductOrderItem, ProductOrderStatus } from '../../../../domain/model/order';
import { PartnerView } from '../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../domain/model/types';
import MasterActionsComponent from '../../../components/common/actions/master';
import AppBreadcrumbs from '../../../components/common/breadcrumbs';
import DefaultHeader from '../../../components/common/header';
import ContentLoader from '../../../components/common/loader';
import { DefaultContentWrapper } from '../../../components/common/wrappers/content';
import { DefaultFooterWrapper } from '../../../components/common/wrappers/footer';
import useHistoryExtensions from '../../../hooks/useHistoryExtensions';
import { PanelAction, PanelActions } from '../../../types';
import { Modifier, ModifierArray } from '../../../utils/modifier';
import { EValidationType } from '../../../utils/validation';
import useBreadcrumbsActions from '../../general/breadcrumbs/useBreadcrumbsActions';
import StepperContainer from '../../general/stepper/container';
import { getOrdersTableRoute } from '../../order/entry';
import { EProductOrderEditActionType } from '../types';
import ProductOrderEdit from './component';
import { ContainerWrapper, ContentContainer, LoaderWrapper, TitleWrapper } from './controls';
import { useValidProductOrder } from './hooks/useValidProductOffer';
import {
  productOrderEditIsFetchingSelector,
  productOrderEditItemsSelector,
  productOrderEditOrderSelector,
} from './store/selectors';
import {
  productOrderEditItemsSetField,
  productOrderEditReset,
  productOrderEditSetField,
  productOrderEditSetWarning,
  productOrderEditStartSession,
  productOrderReplaceItemField,
  productOrderSave,
  ProductOrderWarnings,
} from './store/slice';
import { getProductOrderEditActions } from './utils';

interface ProductOrderEditContainerProps {
  readonly id: UUID;
  readonly guid: UUID;
  readonly source: ProductOrder;
  readonly partner: PartnerView;
}

const ProductOrderEditContainer = (props: ProductOrderEditContainerProps) => {
  const { guid, source, partner } = props;

  const dispatch = useAppDispatch();
  const { putBreadcrumbs } = useBreadcrumbsActions();
  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const order = useSelector(productOrderEditOrderSelector);
  const orderItems = useSelector(productOrderEditItemsSelector);
  const isFetching = useSelector(productOrderEditIsFetchingSelector);
  const validation = useValidProductOrder();

  const orderStatusesToDisplay = useMemo<SportOptionTyped<ProductOrderStatus>[]>(
    () => [
      {
        id: EOrderStatus.New,
        name: 'Создан',
      },
      {
        id: EOrderStatus.Confirmed,
        name: 'Принят',
      },
      {
        id: EOrderStatus.Paid,
        name: 'Оплачен',
      },
      {
        id: EOrderStatus.Sent,
        name: 'Отправлен',
      },
      {
        id: EOrderStatus.Given,
        name: 'Получен',
      },
      {
        id: EOrderStatus.Cancelled,
        name: 'Отменен',
      },
      {
        id: EOrderStatus.PartiallyReturned,
        name: 'Возврат частичный',
      },
      {
        id: EOrderStatus.Returned,
        name: 'Возврат полный',
      },
    ],
    []
  );

  const onClose = () => {
    dispatch(productOrderEditReset());
    gotoPrevIndependentLocation(getOrdersTableRoute({ partition: EOrderPartition.ProductOffers }));
  };

  const onChangeField: Modifier<ProductOrder> = (name, value) => {
    dispatch(productOrderEditSetField({ name, value }));
  };
  const onChangeFieldItem: ModifierArray<ProductOrderItem> = (id, name, value) => {
    dispatch(productOrderEditItemsSetField({ id, name, value }));
  };
  const onReplaceFieldItem = (offerId: UUID, value: Nullable<ProductOrderItem>) => {
    dispatch(productOrderReplaceItemField({ offerId, value }));
  };
  const onSetWarning: Modifier<ProductOrderWarnings> = (name, value) => {
    dispatch(productOrderEditSetWarning({ name, value }));
  };

  const onPanelAction = (action: PanelAction<EProductOrderEditActionType>) => {
    const { type } = action;
    switch (type) {
      case EProductOrderEditActionType.Save:
        if (orderItems && order) {
          let isValid = true;

          if (order.status !== EOrderStatus.New && order?.status !== EOrderStatus.Cancelled) {
            const resultValidationOrder = validation.validateOrderDeliveryAddress(order.deliveryAddress);
            if (!resultValidationOrder.isValid) {
              isValid = false;
              onSetWarning('validateOrderDeliveryAddress', resultValidationOrder.results);
            } else {
              isValid = true;
            }

            if (!order.deliveryCost || order.deliveryCost < 0) {
              isValid = false;
              onSetWarning('validateOrderDeliveryCost', {
                hasError: true,
                type: EValidationType.Error,
              });
            }
          }

          const resultValidationItems = validation.validateProductOrderItems(orderItems);

          if (!resultValidationItems.isValid) {
            isValid = false;
            onSetWarning('validateItems', {
              message: 'Чтобы измененить статус заказа, сначала выберите статус для каждого товара',
            });
          } else {
            isValid = isValid && true;
          }

          if (isValid) {
            dispatch(productOrderSave()).unwrap().then(onClose);
          }
        }
        break;
    }
  };

  //меняем хлебные крошки
  useEffect(() => {
    putBreadcrumbs([{ label: 'Заказы товаров' }]);
  }, []);

  //инициализация сеанса
  useEffect(() => {
    dispatch(productOrderEditStartSession({ guid, order: source, partner }));
  }, [guid, source, partner]);

  const title = source ? `Заказ №${source?.number}` : 'Создание заказа';

  const actions: PanelActions<EProductOrderEditActionType> = useMemo(() => getProductOrderEditActions(), []);

  const actionsPanel = (
    <MasterActionsComponent<EProductOrderEditActionType>
      actions={actions}
      show={!!actions.length}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );

  return (
    <Fade in>
      <ContainerWrapper>
        {order && (
          <>
            <StepperContainer />
            <ContentContainer>
              <DefaultContentWrapper
                type='details'
                stickyHeader
                fullHeight
                footer={actionsPanel}
              >
                <DefaultHeader
                  sticky
                  headline={<AppBreadcrumbs />}
                  onClose={onClose}
                >
                  <TitleWrapper>
                    <Typography variant='h2'>{title}</Typography>
                  </TitleWrapper>
                </DefaultHeader>
                <ProductOrderEdit
                  order={order}
                  statuses={orderStatusesToDisplay}
                  onChangeField={onChangeField}
                  onChangeFieldItem={onChangeFieldItem}
                  onReplaceFieldItem={onReplaceFieldItem}
                  onSetWarning={onSetWarning}
                  onValidateDeliveryAddress={validation.validateOrderDeliveryAddress}
                />
              </DefaultContentWrapper>
            </ContentContainer>
          </>
        )}
        {isFetching && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
      </ContainerWrapper>
    </Fade>
  );
};

export default ProductOrderEditContainer;
