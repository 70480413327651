import { useState, SyntheticEvent } from 'react';
import { Nullable } from '../../../../../../domain/model/types';
import { MPNaturalNumberFormat } from '../../../../../theme/ui-kit/format';
import DataTableCellEditable from '../editable';
import { MPFormInputProps } from '../../../../../theme/ui-kit/input';

interface DataTableCellSortIndexProps {
  readonly value: Nullable<number>;
  readonly min?: number;
  readonly max: number;
  readonly editMode?: boolean;
  readonly hideEditIcon: boolean;
  readonly isFetching: boolean;
  readonly label: (min: number, max: number) => string;
  readonly onChange: (value: number) => void;
  readonly onChangeEditMode: (isEditMode: boolean, event: SyntheticEvent) => void;
  readonly textFieldProps?: MPFormInputProps;
}

const DataTableCellSortIndex = (props: DataTableCellSortIndexProps) => {
  const {
    value,
    min = 1,
    max,
    editMode = false,
    hideEditIcon,
    isFetching,
    label,
    onChange,
    onChangeEditMode,
    textFieldProps = {},
  } = props;

  const [helpText, setHelpText] = useState<Nullable<string>>(null);

  const onInternalChangeSortIndex = (oldValue: number, newValue: number) => {
    if (oldValue === newValue) {
      return true;
    }

    if (newValue < min || newValue > max) {
      setHelpText(`Допустимые значения с ${min} по ${max}`);
      return false;
    } else {
      setHelpText('');
      onChange(newValue);
      return true;
    }
  };

  return (
    <DataTableCellEditable
      editMode={editMode}
      textFieldProps={{
        ...textFieldProps,
        label: label(min, max),
        value,
        error: !!helpText,
        helperText: helpText,
        helperInTooltip: true,
        helperTooltipStateless: true,
        InputProps: {
          inputProps: {
            onFocus: event => event.target.select(),
          },
          inputComponent: MPNaturalNumberFormat,
        },
      }}
      isFetching={isFetching}
      hideEditIcon={hideEditIcon}
      onChangeEditMode={(isEditMode, event) => {
        setHelpText(!isEditMode ? null : '');
        onChangeEditMode(isEditMode, event);
      }}
      onChange={newValue => onInternalChangeSortIndex(value ?? 0, parseInt(newValue, 10))}
    >
      {value}
    </DataTableCellEditable>
  );
};

export default DataTableCellSortIndex;
