import BannerTableCell, { BannerTableCellCommonProps } from './index';

const BannerTableCellPlaces = (props: BannerTableCellCommonProps) => {
  const {
    banner: { places },
  } = props;

  const value = places && places?.map(place => place.name).join(',');

  return (
    <BannerTableCell
      {...props}
      value={value}
    />
  );
};

export default BannerTableCellPlaces;
