import { AdCampaignTableCell, AdCampaignTableCellCommonProps } from './index';

export const AdCampaignTableCellName = (props: AdCampaignTableCellCommonProps) => {
  const {
    adCampaign: { name: value = '-' },
  } = props;

  return (
    <AdCampaignTableCell
      {...props}
      value={value}
    />
  );
};
