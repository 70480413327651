import { combineReducers } from '@reduxjs/toolkit';
import list, { TradeActivationListState } from './table/store/slice';

interface TradeActivationState {
  list: TradeActivationListState;
}

export default combineReducers<TradeActivationState>({
  list,
});
