import React, { ReactNode } from 'react';

interface RenderDecoratorContainerProps {
  decorators: React.ReactNode[];
  children: ReactNode;
}

const RenderDecoratorContainer: React.FC<RenderDecoratorContainerProps> = ({decorators, children}) => {
  if (decorators.length === 0) {
    return <>{children}</>;
  }

  const wrapChildren = (currentDecorator: React.ReactNode, remainingDecorators: React.ReactNode[]): React.ReactNode => {
    // base case
    if (remainingDecorators.length === 0) {
      return React.cloneElement(currentDecorator as React.ReactElement, {}, children);
    }

    // recursive case
    const [nextDecorator, ...restDecorators] = remainingDecorators;
    const wrappedChildren = wrapChildren(nextDecorator, restDecorators);

    return React.cloneElement(currentDecorator as React.ReactElement, {}, wrappedChildren);
  };

  const [firstDecorator, ...restDecorators] = decorators;
  const wrappedChildren = wrapChildren(firstDecorator, restDecorators);

  return <>{wrappedChildren}</>;
};

export default RenderDecoratorContainer;

/*** usage
 *
 * const decoratorStyles: React.CSSProperties = {
 *   position: 'fixed',
 *   top: 0,
 *   padding: '2rem',
 *   bottom: 0,
 *   fontSize: '3rem',
 *   left: 0,
 *   right: 0,
 *   background: 'green',
 *   zIndex: 999999,
 * };
 *
 * const Decorator1: FCC = ({ children }) => {
 *   const [open, setOpen] = useState(false);
 *
 *   return (
 *     <div
 *       style={decoratorStyles}
 *       onClick={() => setOpen(true)}
 *     >
 *       {open ? children : 'open next (1 decorator)'}
 *     </div>
 *   );
 * };
 *
 * const Decorator2: FCC = ({ children }) => {
 *   const [open, setOpen] = useState(false);
 *
 *   return (
 *     <div
 *       style={decoratorStyles}
 *       onClick={() => setOpen(true)}
 *     >
 *       {open ? children : 'open next (2 decorator)'}
 *     </div>
 *   );
 * };
 *
 *
 * <RenderDecoratorContainer decorators={[<Decorator1 key={1} />, <Decorator2 key={2} />]}>
 *   children text
 * </RenderDecoratorContainer>
 *
 *
 *
 * ***/
