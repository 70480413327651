import { Fade, Grid } from '@mui/material';
import { MutableRefObject, ReactNode } from 'react';
import TableScrollbarOverlay from '../../../../../components/common/table/scrollbarOverlay';
import { ContainerWrapper } from './controls';

interface CmsContainerTableLayoutProps {
  readonly tabs: ReactNode;
  readonly table: ReactNode;
  readonly tableScrollAnchorRef: MutableRefObject<HTMLElement | undefined>;
}

const CmsContainerTableLayout = (props: CmsContainerTableLayoutProps) => {
  const { tabs, table, tableScrollAnchorRef } = props;
  console.log('tableScrollAnchorRef.current', tableScrollAnchorRef.current);
  return (
    <Fade in>
      <ContainerWrapper>
        <Grid
          container
          spacing={2}
          direction='column'
        >
          <Grid item>{tabs}</Grid>
        </Grid>
        <TableScrollbarOverlay bottomAnchor={tableScrollAnchorRef.current}>{table}</TableScrollbarOverlay>
      </ContainerWrapper>
    </Fade>
  );
};

export default CmsContainerTableLayout;
