import { DataTableColumns } from '../../../../../components/common/table';
import { EPartnerTableColumn } from '../../types';

export type PartnerCollectionColumn = EPartnerTableColumn.SortIndex | EPartnerTableColumn.Name;

export const partnerCollectionDefaultColumns: PartnerCollectionColumn[] = [
  EPartnerTableColumn.SortIndex,
  EPartnerTableColumn.Name,
];

export const partnerCollectionColumns: DataTableColumns<PartnerCollectionColumn> = {
  [EPartnerTableColumn.SortIndex]: {
    title: 'Место',
    width: '5rem',
  },
  [EPartnerTableColumn.Name]: {
    title: 'Компания',
    width: '20rem',
    wrap: true,
  },
};
