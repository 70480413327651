import { Grid } from '@mui/material';
import styled from '@emotion/styled';

export const navBackWidth = '3rem';

export const FixedWidthBackActionItemWrapper = styled.div`
  width: ${navBackWidth};
  height: 100%;

  .MuiButtonBase-root {
    min-width: ${navBackWidth};
    width: ${navBackWidth};
  }
`;

export const NextActionItemWrapper = styled.div`
  white-space: nowrap;
`;

export const NavGridItem = styled(Grid)`
  width: auto;
`;

export const NavAdapterWrapperGridItem = styled(Grid)`
  display: flex;
  margin-left: calc(${p => -parseInt(p.theme.spacing(2))}px - ${navBackWidth}); //  - 3 rem width - 8padding - 8padding
`;
