import { MPFormInput } from '../../../../../theme/ui-kit/input';
import { CmsContainerCreateComponentProps } from '../types';

type CmsContainerEditComponentNameProps = CmsContainerCreateComponentProps;

const CmsContainerEditComponentName = (props: CmsContainerEditComponentNameProps) => {
  const { cmsContainer, validation, onChange } = props;

  return (
    <MPFormInput
      value={cmsContainer.name}
      label='Название блока'
      error={validation?.name?.hasError}
      helperText={validation?.name?.message}
      onChange={event => onChange('name', event.target.value)}
    />
  );
};

export default CmsContainerEditComponentName;
