import TabsPanelCounter from '../../../components/common/tabs/counter/counter';
import TabsPanelLabel from '../../../components/common/tabs/label/label';
import { CustomerTableTabsCounter, ECustomerTableTab, getCustomerTableTabName } from './utils';

interface CustomersTableTabProps {
  readonly tab: ECustomerTableTab;
  readonly counts: CustomerTableTabsCounter;
}

const CustomersTableTab = ({ tab, counts }: CustomersTableTabProps) => {
  const count = counts[tab];

  return (
    <TabsPanelLabel title={getCustomerTableTabName(tab)}>
      <TabsPanelCounter count={count} />
    </TabsPanelLabel>
  );
};

export default CustomersTableTab;
