import { useContext } from 'react';
import ProductOrderActionsContext, { ProductOrderActionsContextType } from './context';

export const useProductOrderActions = (): ProductOrderActionsContextType => {
  return useContext<ProductOrderActionsContextType>(ProductOrderActionsContext);
};

export const useProductOrderActionsSelectors = (): ProductOrderActionsContextType['utils']['selectors'] => {
  return useProductOrderActions().utils.selectors;
};
