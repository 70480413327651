import { Grid } from '@mui/material';
import { SportOption } from '../../../../../domain/model';
import { Nullable } from '../../../../../domain/model/types';
import useBusinessConfig from '../../../../hooks/useBusinessConfig';
import { convertStringToArray } from '../../../../utils';
import PartnerRejectInfoPanel from '../../../general/partner/components/rejectInfoPanel';
import SupportInfoPanel from '../../../support/components/infoPanel';
import { Wrapper } from './controls';

interface PartnerApplicationDetailsInfoColumnProps {
  readonly rejectReasonType?: Nullable<SportOption>;
  readonly rejectReasonComment?: Nullable<string>;
}

const PartnerApplicationDetailsInfoColumn = (props: PartnerApplicationDetailsInfoColumnProps) => {
  const { rejectReasonType, rejectReasonComment } = props;

  const { supportInfo } = useBusinessConfig();
  const emails = convertStringToArray(supportInfo.mp?.emails ?? '');
  const phones = convertStringToArray(supportInfo.mp?.phones ?? '');

  return (
    <Wrapper>
      <Grid
        container
        spacing={3}
        direction={'column'}
      >
        {rejectReasonType && (
          <Grid item>
            <PartnerRejectInfoPanel
              rejectReason={rejectReasonType}
              text={rejectReasonComment}
            />
          </Grid>
        )}
        <Grid item>
          <SupportInfoPanel
            emails={emails}
            phones={phones}
          />
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default PartnerApplicationDetailsInfoColumn;
