import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { SportUserProfile } from '../../../../../domain/model/user';
import { getSportsmanDetailsRoute } from '../../entry';
import { ESportsmanTableTab } from '../utils';

export type UseSportsmansTableConfig = {
  readonly defaultTab: ESportsmanTableTab;
  readonly tabsForCounts: ESportsmanTableTab[];
  readonly onSportsmanClick: (sportsman: SportUserProfile) => void;
};

export function useSportsmansTableConfig(): UseSportsmansTableConfig {
  const history = useHistory();

  const onSportsmanClick = useCallback(
    (sportsman: SportUserProfile) => {
      history.push(getSportsmanDetailsRoute({ id: sportsman.id }));
    },
    [history]
  );

  const defaultTab = ESportsmanTableTab.All;
  const tabsForCounts = Object.values(ESportsmanTableTab);

  return {
    defaultTab,
    tabsForCounts,
    onSportsmanClick,
  };
}
