import { FilterMultiSelectorPredicate } from '@/domain/model';
import { DataFilterStrategyString, DataFilterStrategyView, EDataFilterType } from '@/domain/model/filter';
import { Nullable } from '@/domain/model/types';
import SearchIcon from '@mui/icons-material/Search';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { isMultipleSelectorPredicateType } from '../../../../utils/filtering';
import { ItemsWrapper, StyledButtonLink, Wrapper } from './controls';
import DataFilterViewCommonItem from './item/common';
import DataFilterExpandedItem from './item/expanded';
import DataFilterMoreItem, { DataFilterMoreProps } from './item/more';
import DataFilterPreviews from './item/previews';

interface DataFilterViewProps<T extends string> {
  readonly strategies: DataFilterStrategyView<T>[];
  readonly onClearStrategyValue: (strategy: DataFilterStrategyView<T>) => void;
  readonly onSetStrategyValue?: (strategy: DataFilterStrategyView<T>, value: any) => void;
  readonly onEditFilter: () => void;
}

function DataFilterView<T extends string>(props: DataFilterViewProps<T>) {
  const { strategies, onClearStrategyValue, onSetStrategyValue, onEditFilter } = props;

  const { width: windowWidth } = useWindowResize();
  const [showMoreExpanded, setShowMoreExpanded] = useState<boolean>(false);
  const [showMoreProps, setShowMoreProps] = useState<DataFilterMoreProps>({
    visible: false,
    offsetLeft: 0,
    elementsCount: null,
  });
  const itemsWrapperRef = useRef<HTMLDivElement>(null);

  const onClearStrategyValueByIndex = (strategy: DataFilterStrategyView<T, Nullable<string[]>>, index: number) => {
    if (strategy.value === undefined || strategy.value === null) {
      return;
    }

    const value = [...strategy.value];
    value.splice(index, 1);

    if (value.length > 0) {
      onSetStrategyValue?.(strategy, value);
    } else {
      onClearStrategyValue(strategy);
    }
  };

  const onClearStrategyMultipleSelectorValueByIndex = (
    strategy: DataFilterStrategyView<T, Nullable<FilterMultiSelectorPredicate<any>>>,
    index: number
  ) => {
    if (strategy.value?.in === undefined || strategy.value?.in === null) {
      return;
    }

    const valueIn = [...strategy.value.in];
    valueIn.splice(index, 1);

    if (valueIn.length > 0) {
      onSetStrategyValue?.(strategy, { ...strategy.value, in: valueIn });
    } else {
      onClearStrategyValue(strategy);
    }
  };

  useEffect(() => {
    const element = itemsWrapperRef.current;
    if (element) {
      const newShowMoreProps: DataFilterMoreProps = {
        visible: false,
        offsetLeft: 0,
        elementsCount: null,
      };

      for (let i = 1; i < element.children.length; i++) {
        const item: HTMLDivElement = element.children.item(i) as HTMLDivElement;
        if (item.offsetLeft === 0) {
          const prevItem: HTMLDivElement = element.children.item(i - 1) as HTMLDivElement;
          newShowMoreProps.visible = true;
          newShowMoreProps.offsetLeft = !prevItem ? 0 : prevItem.offsetLeft + prevItem.clientWidth;
          newShowMoreProps.elementsCount = strategies.length - i;
          break;
        }
      }

      setShowMoreProps(newShowMoreProps);
    }
  }, [itemsWrapperRef.current, windowWidth, strategies]);

  const invisibleElements = strategies.slice(
    showMoreProps.elementsCount ? strategies.length - showMoreProps.elementsCount : strategies.length
  );

  const ignoreStrategyPredicate = (strategy: DataFilterStrategyView<T>): boolean => {
    switch (strategy.type) {
      case EDataFilterType.String: {
        const stringStrategy = strategy as DataFilterStrategyString<T>;
        if (!stringStrategy.group) {
          return true;
        }

        const currentItemIndex = strategies.findIndex(s => s === stringStrategy);
        const firstCurrentGroupItemIndex = strategies.findIndex(
          s => (s as DataFilterStrategyString<T>).group === stringStrategy.group
        );
        // если текущий элемент первый в группе то показываем, иначе нет (так как показана вся группа на первом элементе)
        return currentItemIndex === firstCurrentGroupItemIndex;
      }
      default:
        return true;
    }
  };

  const ignoreEmptyLists = (strategy: DataFilterStrategyView<T>): boolean => {
    if (isMultipleFilter(strategy)) {
      return strategy.viewAlways || (Array.isArray(strategy.value) && strategy.value.length > 0);
    } else {
      return true;
    }
  };

  function isMultipleFilter(element: DataFilterStrategyView<T>) {
    return (
      element.type === EDataFilterType.ListMultiple ||
      element.type === EDataFilterType.AutocompleteMultiple ||
      element.type === EDataFilterType.AddressLocalityMultiple ||
      element.type === EDataFilterType.CityMultipleAddress ||
      element.type === EDataFilterType.AdapterTreeMultiple
    );
  }

  function isTargetFilter(element: DataFilterStrategyView<T>) {
    return element.type === EDataFilterType.Target;
  }

  return (
    <Wrapper
      container
      spacing={3}
      wrap='nowrap'
    >
      <Grid item>
        <StyledButtonLink onClick={onEditFilter}>
          <SearchIcon />
          <Typography>Поиск и фильтр</Typography>
        </StyledButtonLink>
      </Grid>
      <Grid item>
        <ItemsWrapper ref={itemsWrapperRef}>
          {strategies
            .filter(ignoreStrategyPredicate)
            .filter(ignoreEmptyLists)
            .filter(strategy => strategy.preview)
            .map(element => (
              <React.Fragment key={element.key.toString()}>
                {isMultipleSelectorPredicateType(element.type) && (
                  <DataFilterExpandedItem
                    label={element.preview!}
                    onDelete={
                      element.readOnly || element.value === null ? undefined : () => onClearStrategyValue(element)
                    }
                  >
                    {element.previews && (
                      <DataFilterPreviews
                        strategy={element}
                        onDeleteItem={index =>
                          onClearStrategyMultipleSelectorValueByIndex(
                            element as DataFilterStrategyView<T, Nullable<FilterMultiSelectorPredicate<any>>>,
                            index
                          )
                        }
                      />
                    )}
                  </DataFilterExpandedItem>
                )}
                {isTargetFilter(element) && (
                  <DataFilterExpandedItem label={element.preview!}>
                    {element.previews && <DataFilterPreviews strategy={element} />}
                  </DataFilterExpandedItem>
                )}
                {isMultipleFilter(element) && (
                  <DataFilterExpandedItem
                    label={element.preview!}
                    onDelete={
                      element.readOnly || element.value === null ? undefined : () => onClearStrategyValue(element)
                    }
                  >
                    {(element.previews || (element as DataFilterStrategyView<T, Nullable<string[]>>).value) && (
                      <DataFilterPreviews
                        strategy={element}
                        onDeleteItem={index =>
                          onClearStrategyValueByIndex(element as DataFilterStrategyView<T, Nullable<string[]>>, index)
                        }
                      />
                    )}
                  </DataFilterExpandedItem>
                )}
                {!isMultipleSelectorPredicateType(element.type) &&
                  !isMultipleFilter(element) &&
                  !isTargetFilter(element) && (
                    <DataFilterViewCommonItem
                      label={element.preview}
                      onDelete={
                        element.readOnly || element.value === null ? undefined : () => onClearStrategyValue(element)
                      }
                    />
                  )}
              </React.Fragment>
            ))}
          <DataFilterMoreItem
            {...showMoreProps}
            expanded={showMoreExpanded}
            onExpandOrCollapse={() => setShowMoreExpanded(!showMoreExpanded)}
          >
            <Grid
              container
              spacing={1}
              direction='column'
            >
              {invisibleElements.map(element => (
                <Grid
                  item
                  key={element.key.toString()}
                >
                  <DataFilterViewCommonItem
                    label={element.preview}
                    onDelete={element.readOnly ? undefined : () => onClearStrategyValue(element)}
                  />
                </Grid>
              ))}
            </Grid>
          </DataFilterMoreItem>
        </ItemsWrapper>
      </Grid>
    </Wrapper>
  );
}

export default DataFilterView;
