import PartnerTableCell, {PartnerTableCellCommonProps} from './index';

const PartnerTableCellEmail = (props: PartnerTableCellCommonProps) => {
  const {
    partner: { owner },
  } = props;

  const value = owner?.email ?? '';

  return (
    <PartnerTableCell
      {...props}
      value={value}
    />
  );
};

export default PartnerTableCellEmail;