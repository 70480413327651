import { useMemo } from 'react';
import MenuPanelList from '../../../../../components/common/menuPanel/list';
import { MenuPanelItem } from '../../../../../types';

interface MainMenuItemsAdapterProps {
  readonly items: MenuPanelItem[];
  readonly onItemClick: (item: MenuPanelItem) => void;
  readonly isSelectedItem: (item: MenuPanelItem) => boolean;
  readonly isOpenedSubMenu: (item: MenuPanelItem) => boolean;
}

export const MainMenuItemsAdapter = (props: MainMenuItemsAdapterProps) => {
  const { items, onItemClick, isSelectedItem, isOpenedSubMenu } = props;

  return useMemo(() => {
    return (
      <MenuPanelList
        items={items}
        onItemClick={onItemClick}
        isSelectedItem={isSelectedItem}
        isOpenedSubMenu={isOpenedSubMenu}
      />
    );
  }, [items, isSelectedItem, isOpenedSubMenu, onItemClick]);
};
