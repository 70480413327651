import { ButtonLink } from '@components/common/buttons/link';
import ChatBubbleIcon from '@mui/icons-material/ChatBubbleOutline';
import { useSelector } from 'react-redux';
import { StepperButtonWrapper, TextSemiBold } from '../controls';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { clientOrgCreateClientOrgSelector } from '../store/selectors';

export const ClientOrgCreateStepperFooterAdapter = () => {
  const clientOrg = useSelector(clientOrgCreateClientOrgSelector);

  const handlers = useContextHandlers();

  const onShowFeedback = () => {
    handlers.onChangeDialogState('feedback', clientOrg);
  };

  return (
    <StepperButtonWrapper>
      <ButtonLink
        variant='text'
        fullWidth={false}
        onClick={onShowFeedback}
      >
        <ChatBubbleIcon fontSize={'small'} />
        <TextSemiBold>Форма обратной связи</TextSemiBold>
      </ButtonLink>
    </StepperButtonWrapper>
  );
};
