import { FileDescription, Nullable } from '@/domain';
import { FCC, ReactNode } from 'react';
import AppImage from '../../../../../components/common/images/common';
import MPThemeProvider from '../../../../../theme/common/provider';
import privilegeTheme from '../../../../../theme/privilege';
import { EEntityPreviewMode } from '../../../../../types';
import {
  CardFavoriteWrapper,
  ImagePreviewWrapper,
  OfferPreviewCard,
  OfferPreviewCardDetails,
  OfferPreviewContent,
  OfferPreviewDescription,
  OfferPreviewFooter,
  OfferPreviewHeader,
  OfferPreviewImageSkeleton,
  OfferTagsWrapper,
} from './controls';

export type OfferPreviewProps = {
  readonly mode: EEntityPreviewMode;
  readonly image: Nullable<FileDescription>;
  readonly imageRatio: number;
  readonly header?: ReactNode;
  readonly description?: ReactNode;
  readonly imageThumb?: boolean;
  readonly favorite?: ReactNode;
  readonly tags?: ReactNode;
  readonly info?: ReactNode;
  readonly border?: boolean;
};

const OfferPreview: FCC<OfferPreviewProps> = props => {
  const { mode, image, imageRatio, favorite, tags, header, description, info, border, children } = props;

  return (
    <MPThemeProvider theme={privilegeTheme}>
      <OfferPreviewCard
        elevation={0}
        hasBorder={border}
      >
        <ImagePreviewWrapper ratio={imageRatio}>
          {image?.path ? (
            <AppImage src={image?.path} />
          ) : (
            <OfferPreviewImageSkeleton
              animation={false}
              height='100%'
              width='100%'
            />
          )}
          {favorite && <CardFavoriteWrapper>{favorite}</CardFavoriteWrapper>}
          {tags && <OfferTagsWrapper mode={mode}>{tags}</OfferTagsWrapper>}
        </ImagePreviewWrapper>
        <OfferPreviewCardDetails>
          <OfferPreviewContent mode={mode}>
            {header && <OfferPreviewHeader>{header}</OfferPreviewHeader>}
            {typeof description === 'string' ? (
              <OfferPreviewDescription
                mode={mode}
                variant='body2'
              >
                {description}
              </OfferPreviewDescription>
            ) : (
              <>{description}</>
            )}
            {children}
          </OfferPreviewContent>
          {info && <OfferPreviewFooter mode={mode}>{info}</OfferPreviewFooter>}
        </OfferPreviewCardDetails>
      </OfferPreviewCard>
    </MPThemeProvider>
  );
};

export default OfferPreview;
