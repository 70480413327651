import { Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BookingOfferShort } from '../../../../../domain/model/booking';
import Splitter from '../../../../components/common/splitter';
import { MPFormTextArea } from '../../../../theme/ui-kit/input';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { EBookingActionType } from '../../types';
import BookingActionsConfirmDialog from '../components/confirmDialog';
import {
  bookingOfferActionsDialogPauseSelector,
  getBookingOfferActionsOfferIsProcessingSelector,
} from '../store/selectors';
import { bookingOfferActionsPause } from '../store/slice';
import { useBookingOfferActions } from '../useActions';
import { showBookingOfferActionNotification } from '../utils';

const BookingOfferActionsDialogPauseAdapter = () => {
  const dispatch = useDispatch();

  const {
    accessMatrix: { isPartnerUser },
  } = useCurrentUser();

  const [commentText, setCommentText] = useState<undefined | string>(undefined);

  const handlers = useBookingOfferActions();

  const object = useSelector(bookingOfferActionsDialogPauseSelector);

  const isProcessing = useSelector(getBookingOfferActionsOfferIsProcessingSelector(object?.id ?? null));

  const onPause = useCallback(
    (offer: BookingOfferShort) => {
      dispatch(
        bookingOfferActionsPause({
          id: offer.id,
          comment: commentText,
        })
      )
        .unwrap()
        .then(response => {
          handlers.onChangeDialogState('pause', null);
          setCommentText(undefined);
          showBookingOfferActionNotification(response, EBookingActionType.Pause, false);
        });
    },
    [dispatch, handlers.onChangeDialogState, commentText]
  );

  const onCloseDialog = useCallback(() => {
    setCommentText(undefined);
    handlers.onChangeDialogState('pause', null);
  }, [handlers.onChangeDialogState]);

  return (
    object && (
      <BookingActionsConfirmDialog
        offer={object}
        title='Приостановить объект?'
        actionText='Приостановить'
        text={
          <>
            <Typography>Объект будет снят с публикации на Витрине</Typography>
            {!isPartnerUser && <Typography>Укажите причину приостановки</Typography>}
          </>
        }
        actionDisabled={!isPartnerUser && !commentText}
        onAction={offer => onPause(offer)}
        isFetching={isProcessing}
        onClose={onCloseDialog}
      >
        {!isPartnerUser && (
          <>
            <Typography>Укажите причину приостановки</Typography>
            <Splitter />
            <MPFormTextArea
              minRows={3}
              label='Комментарий'
              value={commentText}
              onChange={event => setCommentText(event.target.value)}
              inputProps={{ maxLength: 1000 }}
            />
          </>
        )}
      </BookingActionsConfirmDialog>
    )
  );
};

export default BookingOfferActionsDialogPauseAdapter;
