import { Grid } from '@mui/material';
import { forwardRef } from 'react';
import styled from '@emotion/styled';
import { viewConfig } from '../../../theme/viewConfig';

interface FooterWrapperProps {
  readonly children: any;
}

export const LoaderWrapper = styled.div`
  z-index: 3;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: ${viewConfig.stepper.width};
`;

export const ContainerWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

export const FooterWrapper = styled(
  forwardRef(({ ...others }: FooterWrapperProps, ref: any) => (
    <div
      ref={ref}
      {...others}
    />
  ))
)`
  border-top: 1px solid ${p => p.theme.palette.secondary.main};

  padding: ${p => p.theme.spacing(2)} ${p => p.theme.spacing(10)};
`;
