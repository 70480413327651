import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { ActivityType } from '../../../../../domain/model/event';
import { Nullable } from '../../../../../domain/model/types';
import service from '../../services';

export const activityTypeEditControlFetch = createAsyncThunk<
  ActivityType[],
  { sport?: boolean; onlyLeaf?: boolean },
  AppThunkAPIConfig
>('activityType/editControl/fetch', async ({ sport }, { rejectWithValue, signal }) => {
  try {
    if (sport) {
      return await service.allActiveSport({ signal });
    } else {
      return await service.allActive({ signal });
    }
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface ActivityTypeEditControlState extends Fetchable {
  readonly data: Nullable<ActivityType[]>;
}

type Reducer<T = undefined> = CaseReducer<ActivityTypeEditControlState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ActivityTypeEditControlState> {
  activityTypeEditControlStateReset: Reducer;
}

const slice = createSlice<ActivityTypeEditControlState, Reducers, 'editControl'>({
  name: 'editControl',
  initialState: {
    ...fetchableDefault,
    data: null,
  },
  reducers: {
    activityTypeEditControlStateReset: state => {
      state.isFetching = false;
      state.isFetched = false;
      state.isFailed = false;
      state.data = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(activityTypeEditControlFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
        state.data = null;
      })
      .addCase(activityTypeEditControlFetch.fulfilled, (state, { payload, meta }) => {
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        if (meta.arg.onlyLeaf) {
          state.data = payload.filter(item => !!item.parent);
        } else {
          //перекладываем коллекцию, чтобы после родителя всегда шли его дети
          state.data = buildTree(payload).flatMap(item => {
            const { children, ...itemData } = item;
            return [{ ...itemData }, ...(item.children ?? [])];
          });
        }
      })
      .addCase(activityTypeEditControlFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
        } else {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = false;
        }
      });
  },
});

export const { activityTypeEditControlStateReset } = slice.actions;

type ActivityTypeTreeItem = ActivityType & {
  readonly children?: Nullable<ActivityTypeTreeItem[]>;
};

export const buildTree = (activityTypes: ActivityType[]): ActivityTypeTreeItem[] => {
  return activityTypes.filter(item => !item.parent?.id).map(activityType => buildTreeItem(activityType, activityTypes));
};

const buildTreeItem = (activityType: ActivityType, activityTypes: ActivityTypeTreeItem[]): ActivityTypeTreeItem => {
  return {
    ...activityType,
    children:
      activityTypes.filter(c => c.parent?.id === activityType.id).map(c => buildTreeItem(c, activityTypes)) ?? [],
  };
};

export default slice.reducer;
