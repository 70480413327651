import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import {
  ESportEventClassificationType,
  ETournamentApplicationStatuses,
  SportUserProfile,
  TournamentIndividual,
  TournamentIndividualApplication,
  TournamentIndividualGenderResultsRequest,
  TournamentIndividualMemberListView,
  TournamentIndividualResultsRequest,
  TournamentResultsRequest,
} from '@/domain';
import {
  CaseReducer,
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ValidationItemResult, ValidationResult } from 'presentation/utils/validation';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { EventStatisticsProps } from '../../../components/statistics';
import {
  tournamentCreatePublish,
  tournamentCreatePublishAnnounce,
  tournamentCreateSave,
} from '../../../create/tournament/store/slice';
import eventServices from '../../../services';
import { setTournamentApplicationsFilter } from '../../../tournamentApplication/filter/store/slice';
import { ETournamentApplicationState } from '../../../tournamentApplication/types';
import {
  ETournamentIndividualViewApplicationsTabs,
  TournamentIndividualViewApplicationsTabsCounter,
} from '../../../tournamentIndividualApplication';
import { ApplicationState, EventApplicationsFilter, EventApplicationsFilterState } from '../../../types';
import { TournamentIndividualViewUiState } from '../types';
import {
  convertTournamentIndividualResultsToTournamentResultsRequest,
  tournamentIndividualViewGetStatusByTab,
} from '../utils';

const applicationsSortDefault = 'status';

const filterDefault = {
  sort: applicationsSortDefault,
  search: null,
  locality: null,
  status: null,
};

interface TournamentIndividualApplicationIdState {
  id: UUID;
  eventId: UUID;
}

export const tournamentIndividualViewByIdFetch = createAsyncThunk<TournamentIndividual, UUID, AppThunkAPIConfig>(
  'tournamentIndividual/view/byId/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      return await eventServices.tournamentIndividual.one({ id, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tournamentIndividualViewApplicationsFetch = createAsyncThunk<
  TournamentIndividualApplication[],
  { tournamentId: UUID; userId?: UUID; statuses?: ETournamentApplicationStatuses[] },
  AppThunkAPIConfig
>(
  'tournamentIndividual/view/applications/fetch',
  async ({ tournamentId, userId, statuses }, { rejectWithValue, signal }) => {
    try {
      const data = await eventServices.tournamentIndividual.applications({ tournamentId, userId, statuses });
      return data.content;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tournamentIndividualViewUsersFetch = createAsyncThunk<
  TournamentIndividualMemberListView[],
  UUID,
  AppThunkAPIConfig
>('tournamentIndividual/view/teams/fetch', async (id, { rejectWithValue, signal }) => {
  try {
    const data = await eventServices.tournamentIndividual.members({ id, signal });
    return data.content;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tournamentIndividualViewInviteUsers = createAsyncThunk<
  TournamentIndividual,
  { id: UUID; users: SportUserProfile[] },
  AppThunkAPIConfig
>('tournamentIndividual/view/teams/invite', async ({ id, users }, { rejectWithValue }) => {
  try {
    return await eventServices.tournamentIndividual.inviteUsers({ id, users });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tournamentIndividualViewCancel = createAsyncThunk<void, UUID, AppThunkAPIConfig>(
  'tournamentIndividual/view/cancel',
  async (id, { rejectWithValue }) => {
    try {
      await eventServices.tournamentIndividual.cancel({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tournamentIndividualViewSaveResults = createAsyncThunk<
  TournamentIndividual,
  { id: UUID; results: TournamentIndividualResultsRequest },
  AppThunkAPIConfig
>('tournamentIndividual/view/saveResults', async ({ id, results }, { rejectWithValue }) => {
  try {
    return await eventServices.tournamentIndividual.saveResult({ id, results });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tournamentIndividualViewPublishResults = createAsyncThunk<
  TournamentIndividual,
  { id: UUID },
  AppThunkAPIConfig
>('tournamentIndividual/view/publishResults', async ({ id }, { rejectWithValue }) => {
  try {
    return await eventServices.tournamentIndividual.publishResult({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tournamentIndividualViewApplicationAcceptFetch = createAsyncThunk<
  AxiosResponse<void, any>,
  TournamentIndividualApplicationIdState,
  AppThunkAPIConfig
>('events/tournamentIndividual/view/application/accept/fetch', async ({ id, eventId }, { rejectWithValue }) => {
  try {
    return await eventServices.tournamentIndividual.acceptApplication({ appId: id, tournamentId: eventId });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tournamentIndividualViewApplicationRejectFetch = createAsyncThunk<
  AxiosResponse<void, any>,
  TournamentIndividualApplicationIdState & { message: string },
  AppThunkAPIConfig
>(
  'events/tournamentIndividual/view/application/reject/fetch',
  async ({ id, eventId, message }, { rejectWithValue }) => {
    try {
      return await eventServices.tournamentIndividual.rejectApplication({
        appId: id,
        tournamentId: eventId,
        message,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface TournamentIndividualViewState {
  readonly guid: Nullable<UUID>;
  readonly byId: Fetchable & {
    readonly tournament: Nullable<TournamentIndividual>;
  };
  readonly dialogs: {
    readonly applicationReject: Nullable<TournamentIndividualApplication>;
    readonly applicationAccept: Nullable<TournamentIndividualApplication>;
    readonly cancel: Nullable<TournamentIndividual>;
  };
  readonly ui: Nullable<TournamentIndividualViewUiState>;
  readonly applications: Fetchable & {
    readonly filter: EventApplicationsFilter;
    readonly data: Nullable<TournamentIndividualApplication[]>;
    readonly tab: Nullable<ETournamentIndividualViewApplicationsTabs>;
    readonly tabs: Nullable<ETournamentIndividualViewApplicationsTabs[]>;
    readonly tabsCounter: Nullable<TournamentIndividualViewApplicationsTabsCounter>;
  };
  readonly cancel: Fetchable;
  readonly saveResults: Fetchable;
  readonly publishResults: Fetchable;

  readonly applicationsStates: ApplicationState[];
  readonly applicationAccept: Fetchable & {
    readonly data: Nullable<{ id: UUID; eventId: UUID }>;
  };
  readonly applicationReject: Fetchable & {
    readonly data: Nullable<{ id: UUID; eventId: UUID }>;
  };

  readonly users: Fetchable & {
    readonly data: Nullable<TournamentIndividualMemberListView[]>;
  };
  readonly teamsInvite: Fetchable;
  readonly results: {
    readonly data: TournamentIndividualResultsRequest;
    readonly classification: ESportEventClassificationType;
    readonly modified: boolean;
    readonly validation: ValidationResult<TournamentIndividualResultsRequest>;
  };
  readonly statistics: Nullable<EventStatisticsProps>;
}

type Reducer<T = undefined> = CaseReducer<TournamentIndividualViewState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<TournamentIndividualViewState> {
  tournamentIndividualViewStartSession: Reducer<{ guid: UUID }>;
  tournamentIndividualViewClearActionsState: Reducer;
  tournamentIndividualViewSetUiState: Reducer<{ name: keyof TournamentIndividualViewUiState; value: any }>;
  tournamentIndividualViewSetResultsAttribute: Reducer<{ field: keyof TournamentResultsRequest; value: any }>;
  tournamentIndividualViewSetResultsModified: Reducer<boolean>;
  tournamentIndividualViewClearResultsValidation: Reducer;
  tournamentIndividualViewSetResultsAttributeValidation: Reducer<{
    field: keyof TournamentResultsRequest;
    results: Nullable<ValidationItemResult>;
  }>;
  tournamentIndividualViewSetResultsClassificationAttribute: Reducer<{
    classification: ESportEventClassificationType;
    field: keyof TournamentIndividualGenderResultsRequest;
    value: any;
  }>;
  tournamentIndividualViewSetApplicationsFilterAttribute: Reducer<{
    field: keyof EventApplicationsFilterState;
    value: any;
  }>;
  tournamentIndividualViewSetApplicationState: Reducer<{ id: UUID; state: ETournamentApplicationState }>;
  tournamentIndividualViewChangeApplicationState: Reducer<{ id: UUID; state: ETournamentApplicationState }>;
  tournamentIndividualViewSetDialogState: Reducer<{
    name: keyof TournamentIndividualViewState['dialogs'];
    data: Nullable<any>;
  }>;
  tournamentIndividualViewSetStatistics: Reducer<Nullable<EventStatisticsProps>>;
  tournamentIndividualViewSetApplicationsTab: Reducer<ETournamentIndividualViewApplicationsTabs>;
}

const slice = createSlice<TournamentIndividualViewState, Reducers, 'tournament/create'>({
  name: 'tournament/create',
  initialState: {
    guid: null,
    byId: {
      ...fetchableDefault,
      tournament: null,
    },
    dialogs: {
      applicationReject: null,
      applicationAccept: null,
      cancel: null,
    },
    ui: null,
    applications: {
      ...fetchableDefault,
      data: null,
      tab: ETournamentIndividualViewApplicationsTabs.All,
      tabsCounter: {},
      tabs: Object.values(ETournamentIndividualViewApplicationsTabs),
      filter: filterDefault,
    },
    users: {
      ...fetchableDefault,
      data: null,
    },
    teamsInvite: {
      ...fetchableDefault,
    },
    cancel: {
      ...fetchableDefault,
    },
    saveResults: {
      ...fetchableDefault,
    },
    publishResults: {
      ...fetchableDefault,
    },
    results: {
      data: {
        maleResults: null,
        femaleResults: null,
        mixedResults: null,
        resultsFile: null,
      },
      classification: ESportEventClassificationType.Male,
      validation: {},
      modified: false,
    },
    applicationsStates: [],
    applicationAccept: {
      ...fetchableDefault,
      data: null,
    },
    applicationReject: {
      ...fetchableDefault,
      data: null,
    },
    statistics: null,
  },
  reducers: {
    tournamentIndividualViewStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;
        state.byId = {
          ...fetchableDefault,
          tournament: null,
        };
        state.applications = {
          ...fetchableDefault,
          data: null,
          tab: ETournamentIndividualViewApplicationsTabs.All,
          tabs: Object.values(ETournamentIndividualViewApplicationsTabs),
          tabsCounter: {},
          filter: {
            sort: applicationsSortDefault,
            search: null,
            locality: null,
            status: null,
          },
        };
        state.results = {
          data: {
            maleResults: null,
            femaleResults: null,
            mixedResults: null,
            resultsFile: null,
          },
          classification: ESportEventClassificationType.Male,
          modified: false,
          validation: {},
        };
        state.applicationsStates = [];
        state.dialogs = {
          applicationReject: null,
          applicationAccept: null,
          cancel: null,
        };
        state.ui = null;
      }
    },
    tournamentIndividualViewClearActionsState: state => {
      state.byId = { ...fetchableDefault, tournament: null };
      state.cancel = {
        ...fetchableDefault,
      };
      state.saveResults = {
        ...fetchableDefault,
      };
      state.publishResults = {
        ...fetchableDefault,
      };
      state.applicationAccept = {
        ...fetchableDefault,
        data: null,
      };
      state.applicationReject = {
        ...fetchableDefault,
        data: null,
      };
      state.applications = {
        ...fetchableDefault,
        ...state.applications,
        data: null,
        tab: null,
        tabsCounter: null,
        filter: filterDefault,
      };
      state.users = {
        ...fetchableDefault,
        data: null,
      };
      state.teamsInvite = {
        ...fetchableDefault,
      };
    },
    tournamentIndividualViewSetUiState: (state, { payload }) => {
      const { name, value } = payload;
      if (!state.ui) {
        state.ui = {
          steps: [],
          options: [],
        };
      }
      state.ui[name] = value;
    },
    tournamentIndividualViewSetStatistics: (state, { payload }) => {
      state.statistics = payload;
    },
    tournamentIndividualViewSetResultsAttributeValidation: (state, { payload }) => {
      const { field, results } = payload;
      if (!results) {
        delete state.results.validation?.[field];
      } else {
        state.results.validation[field] = results;
      }
    },
    tournamentIndividualViewClearResultsValidation: state => {
      state.results.validation = {};
    },
    tournamentIndividualViewSetResultsClassificationAttribute: (state, { payload }) => {
      const { classification, field, value } = payload;
      switch (classification) {
        case ESportEventClassificationType.Male:
          if (!state.results.data.maleResults) {
            state.results.data.maleResults = { results: null, nominations: null };
          }
          if (!value) {
            delete state.results.data.maleResults[field];
          } else {
            state.results.data.maleResults[field] = value;
          }
          break;
        case ESportEventClassificationType.Female:
          if (!state.results.data.femaleResults) {
            state.results.data.femaleResults = { results: null, nominations: null };
          }
          if (!value) {
            delete state.results.data.femaleResults[field];
          } else {
            state.results.data.femaleResults[field] = value;
          }
          break;
        case ESportEventClassificationType.Mixed:
          if (!state.results.data.mixedResults) {
            state.results.data.mixedResults = { results: null, nominations: null };
          }
          if (!value) {
            delete state.results.data.mixedResults[field];
          } else {
            state.results.data.mixedResults[field] = value;
          }
          break;
      }
    },
    tournamentIndividualViewSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
    tournamentIndividualViewSetResultsAttribute: (state, { payload }) => {
      const { field, value } = payload;
      state.results.data[field] = value;
    },
    tournamentIndividualViewSetResultsModified: (state, { payload }) => {
      state.results.modified = payload;
    },
    tournamentIndividualViewSetResultsClassification: (state, { payload }) => {
      state.results.classification = payload;
    },
    tournamentIndividualViewSetApplicationsFilterAttribute: (state, { payload }) => {
      const { field, value } = payload;
      state.applications.filter[field] = value;
    },
    tournamentIndividualViewChangeApplicationState: (state, { payload }) => {
      const { id, state: newState } = payload;
      const selectedIndex = state.applicationsStates.findIndex(item => item.id === id);

      state.applicationsStates.splice(selectedIndex, 1, { id, state: newState });
    },
    tournamentIndividualViewSetApplicationState: (state, { payload }) => {
      const { id, state: newState } = payload;
      const selectedIndex = state.applicationsStates.findIndex(item => item.id === id);

      state.applicationsStates.splice(selectedIndex, 1);

      if (selectedIndex === -1) {
        state.applicationsStates.push({ id, state: newState });
      }
    },
    tournamentIndividualViewSetApplicationsTab: (state, { payload }) => {
      state.applications.tab = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(tournamentIndividualViewByIdFetch.pending, (state, { meta }) => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        if (state.byId.tournament?.id !== meta.arg) {
          state.byId.tournament = null;
        }
      })
      .addCase(tournamentIndividualViewByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;
        state.byId.tournament = payload;

        state.results.data = payload.results
          ? convertTournamentIndividualResultsToTournamentResultsRequest(payload.results)
          : {
              maleResults: null,
              femaleResults: null,
              mixedResults: null,
              resultsFile: null,
            };
        state.results.modified = false;
      })
      .addCase(tournamentIndividualViewByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = false;
        } else {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        }
        state.byId.tournament = null;
      })
      .addCase(tournamentIndividualViewCancel.pending, state => {
        state.cancel.isFetching = true;
        state.cancel.isFetched = false;
        state.cancel.isFailed = false;
      })
      .addCase(tournamentIndividualViewCancel.fulfilled, state => {
        state.cancel.isFetching = false;
        state.cancel.isFetched = true;
        state.cancel.isFailed = false;
      })
      .addCase(tournamentIndividualViewCancel.rejected, state => {
        state.cancel.isFetching = false;
        state.cancel.isFetched = false;
        state.cancel.isFailed = true;
      })
      .addCase(tournamentIndividualViewSaveResults.pending, state => {
        state.saveResults.isFetching = true;
        state.saveResults.isFetched = false;
        state.saveResults.isFailed = false;
      })
      .addCase(tournamentIndividualViewSaveResults.fulfilled, (state, { payload }) => {
        state.saveResults.isFetching = false;
        state.saveResults.isFetched = true;
        state.saveResults.isFailed = false;

        state.byId.tournament = payload;
        state.results.data = payload.results
          ? convertTournamentIndividualResultsToTournamentResultsRequest(payload.results)
          : {
              maleResults: null,
              femaleResults: null,
              mixedResults: null,
              resultsFile: null,
            };
      })
      .addCase(tournamentIndividualViewSaveResults.rejected, state => {
        state.saveResults.isFetching = false;
        state.saveResults.isFetched = false;
        state.saveResults.isFailed = true;
      })
      .addCase(tournamentIndividualViewPublishResults.pending, state => {
        state.publishResults.isFetching = true;
        state.publishResults.isFetched = false;
        state.publishResults.isFailed = false;
      })
      .addCase(tournamentIndividualViewPublishResults.fulfilled, (state, { payload }) => {
        state.publishResults.isFetching = false;
        state.publishResults.isFetched = true;
        state.publishResults.isFailed = false;

        state.byId.tournament = payload;
        state.results.data = payload.results
          ? convertTournamentIndividualResultsToTournamentResultsRequest(payload.results)
          : {
              maleResults: null,
              femaleResults: null,
              mixedResults: null,
              resultsFile: null,
            };
      })
      .addCase(tournamentIndividualViewPublishResults.rejected, state => {
        state.publishResults.isFetching = false;
        state.publishResults.isFetched = false;
        state.publishResults.isFailed = true;
      })
      .addCase(tournamentIndividualViewApplicationsFetch.pending, state => {
        state.applications.isFetching = true;
        state.applications.isFetched = false;
        state.applications.isFailed = false;
      })
      .addCase(tournamentIndividualViewApplicationsFetch.fulfilled, (state, { payload }) => {
        state.applications.isFetching = false;
        state.applications.isFetched = true;
        state.applications.isFailed = false;

        state.applications.data = payload;

        const counter: TournamentIndividualViewApplicationsTabsCounter = {};
        state.applications.tabs?.map(tab => {
          if (tab === ETournamentIndividualViewApplicationsTabs.All) {
            counter[tab] = payload?.length ?? 0;
          } else {
            counter[tab] =
              payload?.filter(item => item.status === tournamentIndividualViewGetStatusByTab(tab))?.length ?? 0;
          }
        });
        state.applications.tabsCounter = counter;
      })
      .addCase(tournamentIndividualViewApplicationsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (aborted) {
          state.applications.isFetching = false;
          state.applications.isFetched = false;
          state.applications.isFailed = false;
          state.applications.data = null;
        } else {
          state.applications.isFetching = false;
          state.applications.isFetched = false;
          state.applications.isFailed = true;
        }
      })
      .addCase(tournamentIndividualViewUsersFetch.pending, state => {
        state.users.isFetching = true;
        state.users.isFetched = false;
        state.users.isFailed = false;
      })
      .addCase(tournamentIndividualViewUsersFetch.fulfilled, (state, { payload }) => {
        state.users.isFetching = false;
        state.users.isFetched = true;
        state.users.isFailed = false;

        state.users.data = payload;
      })
      .addCase(tournamentIndividualViewUsersFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (aborted) {
          state.users.isFetching = false;
          state.users.isFetched = false;
          state.users.isFailed = false;
          state.users.data = null;
        } else {
          state.users.isFetching = false;
          state.users.isFetched = false;
          state.users.isFailed = true;
        }
      })
      .addCase(tournamentIndividualViewApplicationAcceptFetch.pending, (state, { meta }) => {
        state.applicationAccept.isFetching = true;
        state.applicationAccept.isFetched = false;
        state.applicationAccept.isFailed = false;
        state.applicationAccept.data = meta.arg;
      })
      .addCase(tournamentIndividualViewApplicationAcceptFetch.fulfilled, state => {
        state.applicationAccept.isFetching = false;
        state.applicationAccept.isFetched = true;
        state.applicationAccept.isFailed = false;
        state.applicationAccept.data = null;
        state.applicationsStates = [];
      })
      .addCase(tournamentIndividualViewApplicationAcceptFetch.rejected, state => {
        state.applicationAccept.isFetching = false;
        state.applicationAccept.isFetched = false;
        state.applicationAccept.isFailed = true;
        state.applicationAccept.data = null;
      })

      .addCase(tournamentIndividualViewApplicationRejectFetch.pending, (state, { meta }) => {
        state.applicationReject.isFetching = true;
        state.applicationReject.isFetched = false;
        state.applicationReject.isFailed = false;
        state.applicationReject.data = meta.arg;
      })
      .addCase(tournamentIndividualViewApplicationRejectFetch.fulfilled, state => {
        state.applicationReject.isFetching = false;
        state.applicationReject.isFetched = true;
        state.applicationReject.isFailed = false;
        state.applicationReject.data = null;
        state.applicationsStates = [];
      })
      .addCase(tournamentIndividualViewApplicationRejectFetch.rejected, state => {
        state.applicationReject.isFetching = false;
        state.applicationReject.isFetched = false;
        state.applicationReject.isFailed = true;
        state.applicationReject.data = null;
      })

      .addCase(tournamentIndividualViewInviteUsers.pending, state => {
        state.teamsInvite.isFetching = true;
        state.teamsInvite.isFetched = false;
        state.teamsInvite.isFailed = false;
      })
      .addCase(tournamentIndividualViewInviteUsers.fulfilled, (state, { payload }) => {
        state.teamsInvite.isFetching = false;
        state.teamsInvite.isFetched = true;
        state.teamsInvite.isFailed = false;
        state.byId.tournament = payload;
      })
      .addCase(tournamentIndividualViewInviteUsers.rejected, state => {
        state.teamsInvite.isFetching = false;
        state.teamsInvite.isFetched = false;
        state.teamsInvite.isFailed = true;
      })

      .addCase(setTournamentApplicationsFilter, (state, { payload }) => {
        const { guid, filter } = payload;

        if (state.byId.tournament?.id === guid) {
          state.applications.filter = {
            ...state.applications.filter,
            ...filter,
          };
        }
      })
      .addMatcher(
        isAnyOf(
          tournamentCreateSave.fulfilled,
          tournamentCreatePublish.fulfilled,
          tournamentCreatePublishAnnounce.fulfilled
        ),
        state => {
          state.guid = null;
        }
      );
  },
});

export const {
  tournamentIndividualViewStartSession,
  tournamentIndividualViewClearActionsState,
  tournamentIndividualViewSetUiState,
  tournamentIndividualViewSetResultsAttribute,
  tournamentIndividualViewSetResultsModified,
  tournamentIndividualViewSetResultsAttributeValidation,
  tournamentIndividualViewClearResultsValidation,
  tournamentIndividualViewSetResultsClassificationAttribute,
  tournamentIndividualViewSetApplicationsFilterAttribute,
  tournamentIndividualViewChangeApplicationState,
  tournamentIndividualViewSetApplicationState,
  tournamentIndividualViewSetDialogState,
  tournamentIndividualViewSetStatistics,
  tournamentIndividualViewSetApplicationsTab,
} = slice.actions;

export default slice.reducer;
