import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '../../../../../data/api/utils';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable } from '../../../../../domain/model';
import { EPartnerStatus } from '../../../../../domain/model/enums';
import { DataFilterQueryDslOperator } from '../../../../../domain/model/filter';
import { PartnerShort } from '../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { PaginationSize } from '../../../../types';
import { EPartnerTableColumn } from '../../../general/partner/types';
import { PartnerTableFilterValues } from '../../filterUtils';

export interface PartnersSearchFetchProps {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string>;
    readonly statuses: EPartnerStatus[];
    readonly deskActive?: boolean;
  };
  readonly filter: PartnerTableFilterValues;
  readonly pageNumber: number;
}

const defaultSort = `${EPartnerTableColumn.Date},desc`;

export const partnersSearchFetch = createAsyncThunk<
  Pageable<PartnerShort>,
  PartnersSearchFetchProps,
  AppThunkAPIConfig
>('partner/list/fetch', async ({ search, pageNumber, filter }, { rejectWithValue, signal }) => {
  try {
    const { pageSize, sort, statuses, deskActive } = search;
    const { query } = filter;
    const querydsl = getQueryDslByDataFilterValues(filter) ?? [];
    if (deskActive !== undefined) {
      querydsl.push({
        field: 'partnerDesk.active',
        operator: DataFilterQueryDslOperator.Equals,
        value: [`${deskActive}`]
      });
    }

    const response = await Api.partner.all({
      sort,
      query: query?.value,
      querydsl,
      page: pageNumber,
      pageSize,
      statuses,
      signal,
    });

    const headers = getPageableFromResponseHeaders(response);
    const pageCount = headers.pageCount;
    const totalCount = headers.totalCount;
    const page = headers.page;

    return { data: response.data, totalCount, pageCount, pageNumber: page };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface PartnerSearchState extends PartnersSearchFetchProps, Fetchable, Pageable<PartnerShort> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly selected: PartnerShort[];
}

type Reducer<T = undefined> = CaseReducer<PartnerSearchState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PartnerSearchState> {
  partnersSearchStartSession: Reducer<{ guid: UUID; selected: PartnerShort[] }>;
  partnersSearchSortReset: Reducer;
  partnersSearchSetSearch: Reducer<{ sort: string; pageSize: PaginationSize; statuses: EPartnerStatus[] }>;
  partnersSearchSetPage: Reducer<{ pageNumber: number }>;
  partnersSearchSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  partnersSearchSetFilter: Reducer<PartnerTableFilterValues>;
  partnersSearchDataReset: Reducer;
  partnersSearchNeedRefreshWatcherReset: Reducer;
  partnersSearchSelect: Reducer<PartnerShort>;
  partnersSearchUnselect: Reducer<PartnerShort>;
  partnersSearchAllSelect: Reducer<Nullable<number>>;
  partnersSearchAllUnselect: Reducer;
}

const slice = createSlice<PartnerSearchState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    ...fetchableDefault,
    guid: null,
    needRefreshWatcher: 0,
    search: {
      pageSize: 10,
      sort: defaultSort,
      statuses: [EPartnerStatus.ToVerification],
    },
    data: [],
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    filter: {},
    selected: [],
  },
  reducers: {
    partnersSearchStartSession: (state, { payload }) => {
      const { guid, selected } = payload;
      if (guid !== state.guid) {
        state.guid = guid;

        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = false;

        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;

        state.needRefreshWatcher = 0;
        state.search = {
          pageSize: 10,
          sort: defaultSort,
          statuses: [EPartnerStatus.ToVerification],
        };

        state.filter = {};
        state.selected = selected;
      }
    },
    partnersSearchSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.needRefreshWatcher++;
    },
    partnersSearchSetSearch: (state, { payload }) => {
      const { sort, statuses, pageSize } = payload;
      state.search = {
        ...state.search,
        sort,
        statuses,
        pageSize,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    partnersSearchSetPage: (state, { payload }) => {
      const { pageNumber } = payload;

      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    partnersSearchSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;

      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    partnersSearchSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.needRefreshWatcher++;
    },
    partnersSearchDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
      state.selected = [];
    },
    partnersSearchNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
    partnersSearchSelect: (state, { payload }) => {
      const selected = [...state.selected];
      const existedIndex = selected.findIndex(s => s.id === payload.id);
      if (existedIndex !== -1) {
        selected.splice(existedIndex, 1);
      }
      state.selected = [...selected, payload];
    },
    partnersSearchUnselect: (state, { payload }) => {
      const existedIndex = state.selected.findIndex(s => s.id === payload.id);
      if (existedIndex !== -1) {
        state.selected.splice(existedIndex, 1);
      }
    },
    partnersSearchAllSelect: (state, { payload }) => {
      if (payload) {
        state.selected = [...state.data.slice(0, payload)];
      } else {
        state.selected = state.data;
      }
    },
    partnersSearchAllUnselect: state => {
      state.selected = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(partnersSearchFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
      })
      .addCase(partnersSearchFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(partnersSearchFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      });
  },
});

export const {
  partnersSearchStartSession,
  partnersSearchSortReset,
  partnersSearchSetSearch,
  partnersSearchSetFilter,
  partnersSearchDataReset,
  partnersSearchAllSelect,
  partnersSearchAllUnselect,
  partnersSearchSelect,
  partnersSearchSetPage,
  partnersSearchSetPageSize,
  partnersSearchUnselect,
  partnersSearchNeedRefreshWatcherReset,
} = slice.actions;

export default slice.reducer;
