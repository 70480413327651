import {
  CaseReducer,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  Selector,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { ServerErrorResponse } from '../../../../../../data/network/types';
import { AppThunkAPIConfig, RootState } from '../../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../../data/store/types';
import { CmsComponent, CmsContainer, CmsSitePage } from '../../../../../../domain/model/cms';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import cmsServices from '../../../services';
import { CmsContainerView, CmsLinkedObject } from '../../../types';
import { convertCmsContainerToCmsContainerView } from '../../create/utils';

interface CmsContainerDetailsFetchResult {
  readonly data: CmsContainerView;
  readonly components: CmsComponent[];
  readonly linkedObjects: CmsLinkedObject[];
}

export const cmsContainerDetailsFetch = createAsyncThunk<
  CmsContainerDetailsFetchResult,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('cmsContainer/details/fetch', async ({ id }, { rejectWithValue, signal }) => {
  try {
    const cmsContainer = await cmsServices.container.byId({ id, signal });
    const { components, linkedObjects } = await cmsServices.container.getLinkedObjects({ cmsContainer, signal });

    return {
      data: convertCmsContainerToCmsContainerView(cmsContainer, linkedObjects),
      components,
      linkedObjects,
    };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);

    return rejectWithValue(e.response.data);
  }
});

export const cmsContainerDetailsDelete = createAsyncThunk<void, { id: UUID }, AppThunkAPIConfig<ServerErrorResponse>>(
  'cmsContainer/details/delete',
  async ({ id }, { rejectWithValue }) => {
    try {
      await cmsServices.container.delete({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const cmsContainerDetailsDuplicate = createAsyncThunk<
  CmsContainer,
  { cmsSitePage: CmsSitePage; id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('cmsContainer/details/duplicate', async ({ cmsSitePage, id }, { rejectWithValue }) => {
  try {
    return await cmsServices.container.duplicate({ sitePageId: cmsSitePage.id, id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const cmsContainerDetailsPause = createAsyncThunk<
  CmsContainerDetailsFetchResult,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('cmsContainer/details/pause', async ({ id }, { rejectWithValue, signal }) => {
  try {
    const cmsContainer = await cmsServices.container.paused({ id });
    const { components, linkedObjects } = await cmsServices.container.getLinkedObjects({ cmsContainer, signal });

    return {
      data: convertCmsContainerToCmsContainerView(cmsContainer, linkedObjects),
      components,
      linkedObjects,
    };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const cmsContainerDetailsResume = createAsyncThunk<
  CmsContainerDetailsFetchResult,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('cmsContainer/details/resume', async ({ id }, { rejectWithValue, signal }) => {
  try {
    const cmsContainer = await cmsServices.container.resumed({ id });
    const { components, linkedObjects } = await cmsServices.container.getLinkedObjects({ cmsContainer, signal });

    return {
      data: convertCmsContainerToCmsContainerView(cmsContainer, linkedObjects),
      components,
      linkedObjects,
    };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const cmsContainerDetailsArchive = createAsyncThunk<
  CmsContainerDetailsFetchResult,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('cmsContainer/details/archive', async ({ id }, { rejectWithValue, signal }) => {
  try {
    const cmsContainer = await cmsServices.container.archived({ id });
    const { components, linkedObjects } = await cmsServices.container.getLinkedObjects({ cmsContainer, signal });

    return {
      data: convertCmsContainerToCmsContainerView(cmsContainer, linkedObjects),
      components,
      linkedObjects,
    };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface CmsContainerDetailsState {
  readonly guid: Nullable<UUID>;
  readonly byId: Fetchable & {
    readonly data: Nullable<CmsContainerView>;
  };
  readonly components: CmsComponent[];
  readonly linkedObjects: CmsLinkedObject[];
  readonly pause: Fetchable;
  readonly resume: Fetchable;
  readonly delete: Fetchable;
  readonly duplicate: Fetchable;
  readonly archive: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<CmsContainerDetailsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CmsContainerDetailsState> {
  cmsContainerDetailsReset: Reducer;
}

const slice = createSlice<CmsContainerDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    guid: null,
    byId: {
      ...fetchableDefault,
      data: null,
    },
    components: [],
    linkedObjects: [],
    pause: {
      ...fetchableDefault,
    },
    resume: {
      ...fetchableDefault,
    },
    delete: {
      ...fetchableDefault,
    },
    duplicate: {
      ...fetchableDefault,
    },
    archive: {
      ...fetchableDefault,
    },
  },
  reducers: {
    cmsContainerDetailsReset: state => {
      state.byId.isFetching = false;
      state.byId.isFetched = false;
      state.byId.isFailed = false;
      state.byId.data = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(cmsContainerDetailsFetch.pending, state => {
      state.byId.isFetching = true;
      state.byId.isFetched = false;
      state.byId.isFailed = false;
    });
    builder.addCase(cmsContainerDetailsFetch.fulfilled, (state, { payload }) => {
      const { data, components, linkedObjects } = payload;

      state.byId.isFetched = true;
      state.byId.isFetching = false;
      state.byId.isFailed = false;

      state.byId.data = data;

      state.components = components;
      state.linkedObjects = linkedObjects;
    });
    builder.addCase(cmsContainerDetailsFetch.rejected, state => {
      state.byId.isFailed = true;
      state.byId.isFetching = false;
      state.byId.isFetched = false;
    });
    builder.addCase(cmsContainerDetailsDelete.pending, state => {
      state.delete.isFetching = true;
      state.delete.isFetched = false;
      state.delete.isFailed = false;
    });
    builder.addCase(cmsContainerDetailsDelete.fulfilled, state => {
      state.delete.isFetched = true;
      state.delete.isFetching = false;
      state.delete.isFailed = false;
    });
    builder.addCase(cmsContainerDetailsDelete.rejected, state => {
      state.delete.isFailed = true;
      state.delete.isFetching = false;
      state.delete.isFetched = false;
    });
    builder.addCase(cmsContainerDetailsDuplicate.pending, state => {
      state.duplicate.isFetching = true;
      state.duplicate.isFetched = false;
      state.duplicate.isFailed = false;
    });
    builder.addCase(cmsContainerDetailsDuplicate.fulfilled, state => {
      state.duplicate.isFetched = true;
      state.duplicate.isFetching = false;
      state.duplicate.isFailed = false;
    });
    builder.addCase(cmsContainerDetailsDuplicate.rejected, state => {
      state.duplicate.isFailed = true;
      state.duplicate.isFetching = false;
      state.duplicate.isFetched = false;
    });
    builder.addCase(cmsContainerDetailsPause.pending, state => {
      state.pause.isFetching = true;
      state.pause.isFetched = false;
      state.pause.isFailed = false;
    });
    builder.addCase(cmsContainerDetailsPause.fulfilled, (state, { payload }) => {
      const { data, components, linkedObjects } = payload;

      state.pause.isFetched = true;
      state.pause.isFetching = false;
      state.pause.isFailed = false;

      state.byId.data = data;

      state.components = components;
      state.linkedObjects = linkedObjects;
    });
    builder.addCase(cmsContainerDetailsPause.rejected, state => {
      state.pause.isFailed = true;
      state.pause.isFetching = false;
      state.pause.isFetched = false;
    });
    builder.addCase(cmsContainerDetailsResume.pending, state => {
      state.resume.isFetching = true;
      state.resume.isFetched = false;
      state.resume.isFailed = false;
    });
    builder.addCase(cmsContainerDetailsResume.fulfilled, (state, { payload }) => {
      const { data, components, linkedObjects } = payload;

      state.resume.isFetched = true;
      state.resume.isFetching = false;
      state.resume.isFailed = false;

      state.byId.data = data;

      state.components = components;
      state.linkedObjects = linkedObjects;
    });
    builder.addCase(cmsContainerDetailsResume.rejected, state => {
      state.resume.isFailed = true;
      state.resume.isFetching = false;
      state.pause.isFetched = false;
    });
    builder.addCase(cmsContainerDetailsArchive.pending, state => {
      state.archive.isFetching = true;
      state.archive.isFetched = false;
      state.archive.isFailed = false;
    });
    builder.addCase(cmsContainerDetailsArchive.fulfilled, (state, { payload }) => {
      const { data, components, linkedObjects } = payload;

      state.archive.isFetched = true;
      state.archive.isFetching = false;
      state.archive.isFailed = false;

      state.byId.data = data;

      state.components = components;
      state.linkedObjects = linkedObjects;
    });
    builder.addCase(cmsContainerDetailsArchive.rejected, state => {
      state.archive.isFailed = true;
      state.archive.isFetching = false;
      state.archive.isFetched = false;
    });
  },
});

export const { cmsContainerDetailsReset } = slice.actions;

export default slice.reducer;

const linkedObjectsSelector: Selector<RootState, Nullable<CmsLinkedObject[]>> = state =>
  state.cms.container.details.linkedObjects;
const indexSelector: Selector<RootState, number, [number]> = (state, index) => index;

export const createCmsContainerDetailsCurrentLinkedObjectByIndexSelector = () =>
  createSelector(
    linkedObjectsSelector,
    indexSelector,
    (linkedObjects, index): Nullable<CmsLinkedObject> => linkedObjects?.[index] ?? null
  );
