import { useEffect, useState } from 'react';
import { Nullable } from '../../../domain/model/types';
import {
  ValidationItemResult,
  ValidationResult,
  ValidationResultSummary,
  ValidationRules,
  ValidationRuleSummary,
} from '../../utils/validation';
import validateObject, { validateObjectByRule } from './utils';

interface UseValidationCollectionProps<T> {
  readonly objects: Nullable<T[]>;
  readonly rules: ValidationRules<T> & ValidationRuleSummary<T[]>;
  readonly validateOnChange?: boolean;
}

export type UseValidationCollection<T> = {
  readonly isValid: boolean;
  readonly validationResult: Nullable<ValidationResult<Nullable<T>>[] & Nullable<ValidationResultSummary>>;
  readonly validate: () => boolean;
  readonly resetValidationResult: (index?: number, attribute?: keyof T) => void;
};

function useValidationCollection<T>(props: UseValidationCollectionProps<T>): UseValidationCollection<T> {
  const { objects, rules, validateOnChange = false } = props;

  const [result, setResult] =
    useState<Nullable<ValidationResult<Nullable<T>>[] & ValidationResult<ValidationResultSummary>>>(null);

  const resetValidationResult = (index?: number, attribute?: keyof T) => {
    if (!index) {
      setResult(null);
      return;
    }

    if (result !== null) {
      const resultCopy: typeof result = [...result];
      if (!attribute) {
        resultCopy.splice(index, 1);
      } else {
        delete resultCopy[index]?.[attribute];
      }
      delete resultCopy.validationSummary;
      setResult(resultCopy);
      return;
    }
  };

  const validate = (): boolean => {
    if (objects === null && !rules.validationSummary) {
      setResult(null);
      return true;
    }

    const results: typeof result = [];
    let hasErrors = false;

    if (rules.validationSummary) {
      results.validationSummary = validateObjectByRule<T[]>(objects, rules.validationSummary) ?? undefined;
      if (results.validationSummary?.hasError) {
        hasErrors = true;
      }
    }

    const { validationSummary, ...commonRules } = rules;
    objects?.forEach(object => {
      const validateObjectResult = validateObject<T>(object, { ...commonRules } as any as ValidationRules<T>);
      results.push(validateObjectResult.results);
      if (!validateObjectResult.isValid) {
        hasErrors = true;
      }
    });

    if (Object.keys(results).length === 0) {
      setResult(null);
      return true;
    }

    setResult(results);
    return !hasErrors;
  };

  useEffect(() => {
    if (validateOnChange) validate();
  }, [objects, validateOnChange]);

  const isNotValid =
    result?.validationSummary?.hasError ||
    result
      ?.filter(item => !!item)
      ?.some(item => Object.entries(item!).some(([, value]) => (value as ValidationItemResult).hasError));

  return {
    validationResult: result,
    isValid: !isNotValid,
    validate,
    resetValidationResult,
  };
}

export default useValidationCollection;
