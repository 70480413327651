import { Grid } from '@mui/material';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';
import { MPButton } from '../../../../theme/ui-kit/button';

interface PartnerEmployeeTableNotifierProps {
  readonly onRead: () => void;
}

const PartnerEmployeeTableNotifier = (props: PartnerEmployeeTableNotifierProps) => {
  const { onRead } = props;

  const notifierText = `Отключить доступ ответственному лицу нельзя. Пожалуйста, переназначьте ответственным лицом другого сотрудника`;
  return (
    <ConfirmDialog
      open
      title='Перед отключением назначьте ответственным лицом другого сотрудника'
      dialogButtons={
        <Grid container>
          <Grid item>
            <MPButton onClick={onRead}>Понятно</MPButton>
          </Grid>
        </Grid>
      }
      text={notifierText}
    ></ConfirmDialog>
  );
};

export default PartnerEmployeeTableNotifier;
