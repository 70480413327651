import { combineReducers } from '@reduxjs/toolkit';
import actions, { AdCampaignActionsState } from './actions/store/slice';
import create, { AdCampaignCreateState } from './create/store/slice';
import details, { AdCampaignDetailsState } from './details/store/slice';
import list, { AdCampaignListState } from './table/store/slice';

interface AdCampaignState {
  actions: AdCampaignActionsState;
  create: AdCampaignCreateState;
  details: AdCampaignDetailsState;
  list: AdCampaignListState;
}

export default combineReducers<AdCampaignState>({
  actions,
  create,
  details,
  list,
});
