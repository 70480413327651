import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import {
  ESportEventClassificationType,
  Team,
  Tournament,
  TournamentApplication,
  TournamentClassificationResultsRequest,
  TournamentResultsRequest,
  TournamentTeam,
} from '@/domain';
import {
  CaseReducer,
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { ValidationItemResult, ValidationResult } from 'presentation/utils/validation';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { EventStatisticsProps } from '../../../components/statistics';
import {
  tournamentCreatePublish,
  tournamentCreatePublishAnnounce,
  tournamentCreateSave,
} from '../../../create/tournament/store/slice';
import eventServices from '../../../services';
import { setTournamentApplicationsFilter } from '../../../tournamentApplication/filter/store/slice';
import { ETournamentApplicationState } from '../../../tournamentApplication/types';
import { ApplicationState, EventApplicationsFilter, EventApplicationsFilterState } from '../../../types';
import {
  ETournamentViewApplicationsTabs,
  TournamentViewApplicationsTabsCounter,
  TournamentViewUiState,
} from '../types';
import { convertTournamentResultsToTournamentResultsRequest, tournamentViewGetStatusByTab } from '../utils';

const applicationsSortDefault = 'status';

const filterDefault = {
  sort: applicationsSortDefault,
  search: null,
  locality: null,
  status: null,
};

interface TournamentApplicationIdState {
  id: UUID;
  eventId: UUID;
}

export const tournamentViewByIdFetch = createAsyncThunk<Tournament, UUID, AppThunkAPIConfig>(
  'tournament/view/byId/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      return await eventServices.tournament.one({ id, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tournamentViewApplicationsFetch = createAsyncThunk<TournamentApplication[], UUID, AppThunkAPIConfig>(
  'tournament/view/applications/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      const data = await eventServices.tournament.applications({ id, signal });
      return data.content;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tournamentViewTeamsFetch = createAsyncThunk<TournamentTeam[], UUID, AppThunkAPIConfig>(
  'tournament/view/teams/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      const data = await eventServices.tournament.teams({ id, signal });
      return data.content;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tournamentViewInviteTeams = createAsyncThunk<
  Tournament,
  { tournament: Tournament; teams: Team[] },
  AppThunkAPIConfig
>('tournament/view/teams/invite', async ({ tournament, teams }, { rejectWithValue }) => {
  try {
    return await eventServices.tournament.inviteTeams({ tournament, teams });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tournamentViewCancel = createAsyncThunk<void, UUID, AppThunkAPIConfig>(
  'tournament/view/cancel',
  async (id, { rejectWithValue }) => {
    try {
      await eventServices.tournament.cancel({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tournamentViewSaveResults = createAsyncThunk<
  Tournament,
  { id: UUID; results: TournamentResultsRequest },
  AppThunkAPIConfig
>('tournament/view/saveResults', async ({ id, results }, { rejectWithValue }) => {
  try {
    const { data } = await eventServices.tournament.saveResults({ id, results });

    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tournamentViewPublishResults = createAsyncThunk<
  Tournament,
  { id: UUID; results: TournamentResultsRequest },
  AppThunkAPIConfig
>('tournament/view/publishResults', async ({ id, results }, { rejectWithValue }) => {
  try {
    const { data } = await eventServices.tournament.publishResults({ id });
    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tournamentViewApplicationAcceptFetch = createAsyncThunk<
  TournamentApplicationIdState,
  TournamentApplicationIdState,
  AppThunkAPIConfig
>('events/tournament/view/application/accept/fetch', async ({ id, eventId }, { rejectWithValue }) => {
  try {
    const { data } = await eventServices.tournament.applicationAccept({ applicationId: id, tournamentId: eventId });
    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tournamentViewApplicationRejectFetch = createAsyncThunk<
  TournamentApplicationIdState,
  TournamentApplicationIdState & { message?: string },
  AppThunkAPIConfig
>('events/tournament/view/application/reject/fetch', async ({ id, eventId, message }, { rejectWithValue }) => {
  try {
    const { data } = await eventServices.tournament.applicationReject({
      applicationId: id,
      tournamentId: eventId,
      message,
    });
    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface TournamentViewState {
  readonly guid: Nullable<UUID>;
  readonly byId: Fetchable & {
    readonly tournament: Nullable<Tournament>;
  };
  readonly dialogs: {
    readonly applicationReject: Nullable<TournamentApplication>;
    readonly applicationAccept: Nullable<TournamentApplication>;
    readonly cancel: Nullable<Tournament>;
  };
  readonly ui: Nullable<TournamentViewUiState>;
  readonly applications: Fetchable & {
    readonly filter: EventApplicationsFilter;
    readonly data: Nullable<TournamentApplication[]>;
    readonly tab: Nullable<ETournamentViewApplicationsTabs>;
    readonly tabs: Nullable<ETournamentViewApplicationsTabs[]>;
    readonly tabsCounter: Nullable<TournamentViewApplicationsTabsCounter>;
  };
  readonly cancel: Fetchable;
  readonly saveResults: Fetchable;
  readonly publishResults: Fetchable;

  readonly applicationsStates: ApplicationState[];
  readonly applicationAccept: Fetchable & {
    readonly data: Nullable<{ id: UUID; eventId: UUID }>;
  };
  readonly applicationReject: Fetchable & {
    readonly data: Nullable<{ id: UUID; eventId: UUID }>;
  };

  readonly teams: Fetchable & {
    readonly data: Nullable<TournamentTeam[]>;
  };
  readonly teamsInvite: Fetchable;
  readonly results: {
    readonly data: TournamentResultsRequest;
    readonly classification: ESportEventClassificationType;
    readonly modified: boolean;
    readonly validation: ValidationResult<TournamentResultsRequest>;
  };
  readonly statistics: Nullable<EventStatisticsProps>;
}

type Reducer<T = undefined> = CaseReducer<TournamentViewState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<TournamentViewState> {
  tournamentViewStartSession: Reducer<{ guid: UUID }>;
  tournamentViewClearActionsState: Reducer;
  tournamentViewSetUiState: Reducer<{ name: keyof TournamentViewUiState; value: any }>;
  tournamentViewSetResultsAttribute: Reducer<{ field: keyof TournamentResultsRequest; value: any }>;
  tournamentViewSetResultsModified: Reducer<boolean>;
  tournamentViewClearResultsValidation: Reducer;
  tournamentViewSetResultsAttributeValidation: Reducer<{
    field: keyof TournamentResultsRequest;
    results: Nullable<ValidationItemResult>;
  }>;
  tournamentViewSetResultsClassificationAttribute: Reducer<{
    classification: ESportEventClassificationType;
    field: keyof TournamentClassificationResultsRequest;
    value: any;
  }>;
  tournamentViewSetApplicationsFilterAttribute: Reducer<{ field: keyof EventApplicationsFilterState; value: any }>;
  tournamentViewSetApplicationState: Reducer<{ id: UUID; state: ETournamentApplicationState }>;
  tournamentViewChangeApplicationState: Reducer<{ id: UUID; state: ETournamentApplicationState }>;
  tournamentViewSetDialogState: Reducer<{ name: keyof TournamentViewState['dialogs']; data: Nullable<any> }>;
  tournamentViewSetStatistics: Reducer<Nullable<EventStatisticsProps>>;
  tournamentViewSetApplicationsTab: Reducer<ETournamentViewApplicationsTabs>;
}

const slice = createSlice<TournamentViewState, Reducers, 'tournament/create'>({
  name: 'tournament/create',
  initialState: {
    guid: null,
    byId: {
      ...fetchableDefault,
      tournament: null,
    },
    dialogs: {
      applicationReject: null,
      applicationAccept: null,
      cancel: null,
    },
    ui: null,
    applications: {
      ...fetchableDefault,
      data: null,
      tab: ETournamentViewApplicationsTabs.All,
      tabsCounter: {},
      tabs: Object.values(ETournamentViewApplicationsTabs),
      filter: filterDefault,
    },
    teams: {
      ...fetchableDefault,
      data: null,
    },
    teamsInvite: {
      ...fetchableDefault,
    },
    cancel: {
      ...fetchableDefault,
    },
    saveResults: {
      ...fetchableDefault,
    },
    publishResults: {
      ...fetchableDefault,
    },
    results: {
      data: {
        maleResults: null,
        femaleResults: null,
        mixedResults: null,
        resultsFile: null,
      },
      classification: ESportEventClassificationType.Male,
      validation: {},
      modified: false,
    },
    applicationsStates: [],
    applicationAccept: {
      ...fetchableDefault,
      data: null,
    },
    applicationReject: {
      ...fetchableDefault,
      data: null,
    },
    statistics: null,
  },
  reducers: {
    tournamentViewStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;
        state.byId = {
          ...fetchableDefault,
          tournament: null,
        };
        state.applications = {
          ...fetchableDefault,
          data: null,
          tab: ETournamentViewApplicationsTabs.All,
          tabs: Object.values(ETournamentViewApplicationsTabs),
          tabsCounter: {},
          filter: {
            sort: applicationsSortDefault,
            search: null,
            locality: null,
            status: null,
          },
        };
        state.results = {
          data: {
            maleResults: null,
            femaleResults: null,
            mixedResults: null,
            resultsFile: null,
          },
          classification: ESportEventClassificationType.Male,
          modified: false,
          validation: {},
        };
        state.applicationsStates = [];
        state.dialogs = {
          applicationReject: null,
          applicationAccept: null,
          cancel: null,
        };
        state.ui = null;
      }
    },
    tournamentViewClearActionsState: state => {
      state.byId = { ...fetchableDefault, tournament: null };
      state.cancel = {
        ...fetchableDefault,
      };
      state.saveResults = {
        ...fetchableDefault,
      };
      state.publishResults = {
        ...fetchableDefault,
      };
      state.applicationAccept = {
        ...fetchableDefault,
        data: null,
      };
      state.applicationReject = {
        ...fetchableDefault,
        data: null,
      };
      state.applications = {
        ...fetchableDefault,
        ...state.applications,
        data: null,
        tab: null,
        tabsCounter: null,
        filter: filterDefault,
      };
      state.teams = {
        ...fetchableDefault,
        data: null,
      };
      state.teamsInvite = {
        ...fetchableDefault,
      };
    },
    tournamentViewSetUiState: (state, { payload }) => {
      const { name, value } = payload;
      if (!state.ui) {
        state.ui = {
          steps: [],
          options: [],
        };
      }
      state.ui[name] = value;
    },
    tournamentViewSetStatistics: (state, { payload }) => {
      state.statistics = payload;
    },
    tournamentViewSetResultsAttributeValidation: (state, { payload }) => {
      const { field, results } = payload;
      if (!results) {
        delete state.results.validation?.[field];
      } else {
        state.results.validation[field] = results;
      }
    },
    tournamentViewClearResultsValidation: state => {
      state.results.validation = {};
    },
    tournamentViewSetResultsClassificationAttribute: (state, { payload }) => {
      const { classification, field, value } = payload;
      switch (classification) {
        case ESportEventClassificationType.Male:
          if (!state.results.data.maleResults) {
            state.results.data.maleResults = { teamsResults: null, usersResults: null };
          }
          if (!value) {
            delete state.results.data.maleResults[field];
          } else {
            state.results.data.maleResults[field] = value;
          }
          break;
        case ESportEventClassificationType.Female:
          if (!state.results.data.femaleResults) {
            state.results.data.femaleResults = { teamsResults: null, usersResults: null };
          }
          if (!value) {
            delete state.results.data.femaleResults[field];
          } else {
            state.results.data.femaleResults[field] = value;
          }
          break;
        case ESportEventClassificationType.Mixed:
          if (!state.results.data.mixedResults) {
            state.results.data.mixedResults = { teamsResults: null, usersResults: null };
          }
          if (!value) {
            delete state.results.data.mixedResults[field];
          } else {
            state.results.data.mixedResults[field] = value;
          }
          break;
      }
    },
    tournamentViewSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
    tournamentViewSetResultsAttribute: (state, { payload }) => {
      const { field, value } = payload;
      state.results.data[field] = value;
    },
    tournamentViewSetResultsModified: (state, { payload }) => {
      state.results.modified = payload;
    },
    tournamentViewSetResultsClassification: (state, { payload }) => {
      state.results.classification = payload;
    },
    tournamentViewSetApplicationsFilterAttribute: (state, { payload }) => {
      const { field, value } = payload;
      state.applications.filter[field] = value;
    },
    tournamentViewChangeApplicationState: (state, { payload }) => {
      const { id, state: newState } = payload;
      const selectedIndex = state.applicationsStates.findIndex(item => item.id === id);

      state.applicationsStates.splice(selectedIndex, 1, { id, state: newState });
    },
    tournamentViewSetApplicationState: (state, { payload }) => {
      const { id, state: newState } = payload;
      const selectedIndex = state.applicationsStates.findIndex(item => item.id === id);

      state.applicationsStates.splice(selectedIndex, 1);

      if (selectedIndex === -1) {
        state.applicationsStates.push({ id, state: newState });
      }
    },
    tournamentViewSetApplicationsTab: (state, { payload }) => {
      state.applications.tab = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(tournamentViewByIdFetch.pending, (state, { meta }) => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        if (state.byId.tournament?.id !== meta.arg) {
          state.byId.tournament = null;
        }
      })
      .addCase(tournamentViewByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;
        state.byId.tournament = payload;

        state.results.data = payload.results
          ? convertTournamentResultsToTournamentResultsRequest(payload.results)
          : {
              maleResults: null,
              femaleResults: null,
              mixedResults: null,
              resultsFile: null,
            };
        state.results.modified = false;
      })
      .addCase(tournamentViewByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = false;
        } else {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        }
        state.byId.tournament = null;
      })
      .addCase(tournamentViewCancel.pending, state => {
        state.cancel.isFetching = true;
        state.cancel.isFetched = false;
        state.cancel.isFailed = false;
      })
      .addCase(tournamentViewCancel.fulfilled, state => {
        state.cancel.isFetching = false;
        state.cancel.isFetched = true;
        state.cancel.isFailed = false;
      })
      .addCase(tournamentViewCancel.rejected, state => {
        state.cancel.isFetching = false;
        state.cancel.isFetched = false;
        state.cancel.isFailed = true;
      })
      .addCase(tournamentViewSaveResults.pending, state => {
        state.saveResults.isFetching = true;
        state.saveResults.isFetched = false;
        state.saveResults.isFailed = false;
      })
      .addCase(tournamentViewSaveResults.fulfilled, (state, { payload }) => {
        state.saveResults.isFetching = false;
        state.saveResults.isFetched = true;
        state.saveResults.isFailed = false;

        state.byId.tournament = payload;
        state.results.data = payload.results
          ? convertTournamentResultsToTournamentResultsRequest(payload.results)
          : {
              maleResults: null,
              femaleResults: null,
              mixedResults: null,
              resultsFile: null,
            };
      })
      .addCase(tournamentViewSaveResults.rejected, state => {
        state.saveResults.isFetching = false;
        state.saveResults.isFetched = false;
        state.saveResults.isFailed = true;
      })
      .addCase(tournamentViewPublishResults.pending, state => {
        state.publishResults.isFetching = true;
        state.publishResults.isFetched = false;
        state.publishResults.isFailed = false;
      })
      .addCase(tournamentViewPublishResults.fulfilled, (state, { payload }) => {
        state.publishResults.isFetching = false;
        state.publishResults.isFetched = true;
        state.publishResults.isFailed = false;

        state.byId.tournament = payload;
        state.results.data = payload.results
          ? convertTournamentResultsToTournamentResultsRequest(payload.results)
          : {
              maleResults: null,
              femaleResults: null,
              mixedResults: null,
              resultsFile: null,
            };
      })
      .addCase(tournamentViewPublishResults.rejected, state => {
        state.publishResults.isFetching = false;
        state.publishResults.isFetched = false;
        state.publishResults.isFailed = true;
      })
      .addCase(tournamentViewApplicationsFetch.pending, state => {
        state.applications.isFetching = true;
        state.applications.isFetched = false;
        state.applications.isFailed = false;
      })
      .addCase(tournamentViewApplicationsFetch.fulfilled, (state, { payload }) => {
        state.applications.isFetching = false;
        state.applications.isFetched = true;
        state.applications.isFailed = false;

        state.applications.data = payload;

        const counter: TournamentViewApplicationsTabsCounter = {};
        state.applications.tabs?.map(tab => {
          if (tab === ETournamentViewApplicationsTabs.All) {
            counter[tab] = payload?.length ?? 0;
          } else {
            counter[tab] = payload?.filter(item => item.status === tournamentViewGetStatusByTab(tab))?.length ?? 0;
          }
        });
        state.applications.tabsCounter = counter;
      })
      .addCase(tournamentViewApplicationsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (aborted) {
          state.applications.isFetching = false;
          state.applications.isFetched = false;
          state.applications.isFailed = false;
          state.applications.data = null;
        } else {
          state.applications.isFetching = false;
          state.applications.isFetched = false;
          state.applications.isFailed = true;
        }
      })
      .addCase(tournamentViewTeamsFetch.pending, state => {
        state.teams.isFetching = true;
        state.teams.isFetched = false;
        state.teams.isFailed = false;
      })
      .addCase(tournamentViewTeamsFetch.fulfilled, (state, { payload }) => {
        state.teams.isFetching = false;
        state.teams.isFetched = true;
        state.teams.isFailed = false;

        state.teams.data = payload;
      })
      .addCase(tournamentViewTeamsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (aborted) {
          state.teams.isFetching = false;
          state.teams.isFetched = false;
          state.teams.isFailed = false;
          state.teams.data = null;
        } else {
          state.teams.isFetching = false;
          state.teams.isFetched = false;
          state.teams.isFailed = true;
        }
      })
      .addCase(tournamentViewApplicationAcceptFetch.pending, (state, { meta }) => {
        state.applicationAccept.isFetching = true;
        state.applicationAccept.isFetched = false;
        state.applicationAccept.isFailed = false;
        state.applicationAccept.data = meta.arg;
      })
      .addCase(tournamentViewApplicationAcceptFetch.fulfilled, state => {
        state.applicationAccept.isFetching = false;
        state.applicationAccept.isFetched = true;
        state.applicationAccept.isFailed = false;
        state.applicationAccept.data = null;
        state.applicationsStates = [];
      })
      .addCase(tournamentViewApplicationAcceptFetch.rejected, state => {
        state.applicationAccept.isFetching = false;
        state.applicationAccept.isFetched = false;
        state.applicationAccept.isFailed = true;
        state.applicationAccept.data = null;
      })

      .addCase(tournamentViewApplicationRejectFetch.pending, (state, { meta }) => {
        state.applicationReject.isFetching = true;
        state.applicationReject.isFetched = false;
        state.applicationReject.isFailed = false;
        state.applicationReject.data = meta.arg;
      })
      .addCase(tournamentViewApplicationRejectFetch.fulfilled, state => {
        state.applicationReject.isFetching = false;
        state.applicationReject.isFetched = true;
        state.applicationReject.isFailed = false;
        state.applicationReject.data = null;
        state.applicationsStates = [];
      })
      .addCase(tournamentViewApplicationRejectFetch.rejected, state => {
        state.applicationReject.isFetching = false;
        state.applicationReject.isFetched = false;
        state.applicationReject.isFailed = true;
        state.applicationReject.data = null;
      })

      .addCase(tournamentViewInviteTeams.pending, state => {
        state.teamsInvite.isFetching = true;
        state.teamsInvite.isFetched = false;
        state.teamsInvite.isFailed = false;
      })
      .addCase(tournamentViewInviteTeams.fulfilled, (state, { payload }) => {
        state.teamsInvite.isFetching = false;
        state.teamsInvite.isFetched = true;
        state.teamsInvite.isFailed = false;
        state.byId.tournament = payload;
      })
      .addCase(tournamentViewInviteTeams.rejected, state => {
        state.teamsInvite.isFetching = false;
        state.teamsInvite.isFetched = false;
        state.teamsInvite.isFailed = true;
      })

      .addCase(setTournamentApplicationsFilter, (state, { payload }) => {
        const { guid, filter } = payload;

        if (state.byId.tournament?.id === guid) {
          state.applications.filter = {
            ...state.applications.filter,
            ...filter,
          };
        }
      })
      .addMatcher(
        isAnyOf(
          tournamentCreateSave.fulfilled,
          tournamentCreatePublish.fulfilled,
          tournamentCreatePublishAnnounce.fulfilled
        ),
        state => {
          state.guid = null;
        }
      );
  },
});

export const {
  tournamentViewStartSession,
  tournamentViewClearActionsState,
  tournamentViewSetUiState,
  tournamentViewSetResultsAttribute,
  tournamentViewSetResultsModified,
  tournamentViewSetResultsAttributeValidation,
  tournamentViewClearResultsValidation,
  tournamentViewSetResultsClassificationAttribute,
  tournamentViewSetApplicationsFilterAttribute,
  tournamentViewChangeApplicationState,
  tournamentViewSetApplicationState,
  tournamentViewSetDialogState,
  tournamentViewSetStatistics,
  tournamentViewSetApplicationsTab,
} = slice.actions;

export default slice.reducer;
