import { combineReducers } from '@reduxjs/toolkit';
import edit, { BookingOfferCategoriesEditState } from './edit/store/slice';
import select, { BookingOfferCategoriesSelectState } from './select/store/slice';

interface BookingOfferCategoriesState {
  edit: BookingOfferCategoriesEditState;
  select: BookingOfferCategoriesSelectState;
}

export default combineReducers<BookingOfferCategoriesState>({
  edit,
  select,
});
