import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { ServerErrorResponse } from '../../../../../data/network/types';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Address } from '../../../../../domain/model/address';
import {
  PartnerUpdateProductOrderItemCommand,
  ProductOrder,
  ProductOrderItem,
} from '../../../../../domain/model/order';
import { PartnerShort, PartnerView } from '../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { GetFieldType, setField } from '../../../../utils/modifier';
import { ValidationItemResult, ValidationResult } from '../../../../utils/validation';
import productOrderServices from '../../services';

export const productOrderEditFetch = createAsyncThunk<
  { order: ProductOrder; partner: PartnerShort },
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('productOrder/edit/fetch', async ({ id }, { rejectWithValue, signal }) => {
  try {
    return productOrderServices.order.one({ id, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productOrderSave = createAsyncThunk<Nullable<ProductOrder>, void, AppThunkAPIConfig<ServerErrorResponse>>(
  'productOrder/save',
  async (prop, { rejectWithValue, getState }) => {
    try {
      const state = getState();

      if (state.productOrder.edit.order) {
        const { status, lastStatusComment, deliveryAddress, deliveryCost, id } = state.productOrder.edit.order;
        // PartnerUpdateProductOrderItemCommand
        const items =
          state.productOrder.edit.items?.map<PartnerUpdateProductOrderItemCommand>((item: ProductOrderItem) => {
            return {
              offer: {
                id: item.offer.id,
              },
              status: item.status!,
              cost: item.cost!,
              quantity: item.qty!,
            };
          }) ?? [];

        return productOrderServices.order.updateProductOrder({
          id,
          data: {
            status,
            comment: lastStatusComment,
            deliveryAddress,
            deliveryCost,
            items,
          },
        });
      }

      return null;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export const productOrderEditItemsFetch = createAsyncThunk<
  ProductOrderItem[],
  Nullable<ProductOrderItem[]>,
  AppThunkAPIConfig<ServerErrorResponse>
>('productOrder/edit/items/fetch', async (items, { rejectWithValue, signal }) => {
  try {
    return productOrderServices.order.items({ items, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);

    return rejectWithValue(e.response.data);
  }
});

export interface ProductOrderWarnings {
  excess: Nullable<{
    message: Nullable<string>;
    maxCount?: number;
    productId?: UUID;
  }>;
  validateItems: Nullable<{
    message: Nullable<string>;
  }>;
  validateOrderDeliveryAddress: Nullable<ValidationResult<Address>>;
  validateOrderDeliveryCost: Nullable<ValidationItemResult>;
}

export interface ProductOrderEditState {
  readonly guid: Nullable<UUID>;
  readonly order: Nullable<ProductOrder>;
  readonly partner: Nullable<PartnerView>;
  readonly items: Nullable<ProductOrderItem[]>;

  readonly modifiedItems: Record<string, Partial<ProductOrderItem>>;
  readonly warnings: ProductOrderWarnings;

  readonly fetchable: {
    main: Fetchable;
    items: Fetchable;
  };
}

type Reducer<T = undefined> = CaseReducer<ProductOrderEditState, PayloadAction<T>>;

type FieldProductOrderEdit<T extends string = keyof ProductOrder | string> = {
  name: T;
  value?: GetFieldType<ProductOrder, T>;
};

type FieldProductOrderEditItems<T extends string = keyof ProductOrderItem | string> = {
  id: UUID;
  name: T;
  value?: GetFieldType<ProductOrderItem, T>;
};

type FieldProductOrderEditWarning<T extends string = keyof ProductOrderWarnings | string> = {
  name: T;
  value?: GetFieldType<ProductOrderWarnings, T>;
};

interface Reducers extends SliceCaseReducers<ProductOrderEditState> {
  productOrderEditStartSession: Reducer<{ guid: UUID; order: ProductOrder; partner: PartnerView }>;
  productOrderEditReset: Reducer;
  productOrderEditSetWarning: Reducer<FieldProductOrderEditWarning>;
  productOrderEditSetField: Reducer<FieldProductOrderEdit>;
  productOrderEditItemsSetField: Reducer<FieldProductOrderEditItems>;
  productOrderReplaceItemField: Reducer<{ offerId: UUID; value: Nullable<ProductOrderItem> }>;
  productOrderAppendItem: Reducer<Nullable<ProductOrderItem>>;
}

const slice = createSlice<ProductOrderEditState, Reducers, 'edit'>({
  name: 'edit',
  initialState: {
    guid: null,
    fetchable: {
      main: fetchableDefault,
      items: fetchableDefault,
    },
    order: null,
    partner: null,
    items: null,
    modifiedItems: {},
    warnings: {
      excess: null,
      validateItems: null,
      validateOrderDeliveryAddress: null,
      validateOrderDeliveryCost: null,
    },
  },
  reducers: {
    productOrderEditStartSession: (state, { payload }) => {
      const { guid, order, partner } = payload;

      if (state.guid !== guid) {
        state.fetchable = {
          main: fetchableDefault,
          items: fetchableDefault,
        };
        state.guid = guid;
        state.order = order;
        state.partner = partner;
        state.items = null;
        state.modifiedItems = {};
      }
    },
    productOrderEditReset: state => {
      state.fetchable = {
        main: fetchableDefault,
        items: fetchableDefault,
      };
      state.order = null;
      state.partner = null;
      state.items = null;
    },
    productOrderEditSetWarning: (state, { payload }) => {
      const { name, value } = payload;
      setField(state.warnings, name, value);
    },
    productOrderEditSetField: (state, { payload }) => {
      const { name, value } = payload;
      if (state.order) {
        setField(state.order, name, value);
      }
    },
    productOrderEditItemsSetField: (state, { payload }) => {
      const { name, value, id } = payload;

      if (!state.modifiedItems[id]) {
        state.modifiedItems[id] = {};
      }
      setField(state.modifiedItems[id], name, value);

      if (state.items) {
        const itemIdx = state.items.findIndex(item => item.offer.id === id);
        if (itemIdx >= 0) {
          const item = { ...state.items[itemIdx] };
          setField(item, name, value);
          state.items[itemIdx] = item;
        }
      }
    },
    productOrderReplaceItemField: (state, { payload }) => {
      const { offerId, value } = payload;
      if (state.items) {
        const itemIdx = state.items.findIndex(item => item.offer.id === offerId);
        if (value) {
          if (itemIdx >= 0) {
            state.items[itemIdx] = value;

            state.modifiedItems[offerId] = value;
          }
        } else {
          state.items = state.items.filter(item => item.offer.id !== offerId);
          delete state.modifiedItems[offerId];
        }
      }
    },
    productOrderAppendItem: (state, { payload }) => {
      if (payload) {
        if (state.items) {
          const duplicateOffer = state.items.find(item => item.offer.id === payload.offer.id);

          if (duplicateOffer) {
            if (duplicateOffer.qty! + payload.qty! > payload.offer.stock!) {
              state.warnings.excess = {
                message: `По остаткам варианта товара: ${payload.offer.variantName} — доступно ${payload.offer.stock}`,
                maxCount: payload.offer.stock!,
                productId: payload.offer.id,
              };
            } else {
              duplicateOffer.qty!++;
              if (!state.modifiedItems[payload.offer.id]) {
                state.modifiedItems[payload.offer.id] = {};
              }
              state.modifiedItems[payload.offer.id].qty = duplicateOffer.qty;
            }
          } else {
            state.items.push(payload);
            state.modifiedItems[payload.offer.id] = payload;
          }
        } else {
          state.items = [payload];
          state.modifiedItems[payload.offer.id] = payload;
        }
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(productOrderEditFetch.pending, state => {
      state.fetchable.main.isFetching = true;
      state.fetchable.main.isFetched = false;
      state.fetchable.main.isFailed = false;
    });
    builder.addCase(productOrderEditFetch.fulfilled, (state, { payload }) => {
      const { order, partner } = payload;

      state.fetchable.main.isFetched = true;
      state.fetchable.main.isFetching = false;
      state.fetchable.main.isFailed = false;

      state.order = order;
      state.partner = partner;
    });
    builder.addCase(productOrderEditFetch.rejected, state => {
      state.fetchable.main.isFailed = true;
      state.fetchable.main.isFetching = false;
      state.fetchable.main.isFetched = false;
    });
    builder.addCase(productOrderEditItemsFetch.pending, state => {
      state.fetchable.items.isFetching = true;
      state.fetchable.items.isFetched = false;
      state.fetchable.items.isFailed = false;
      state.items = null;
    });
    builder.addCase(productOrderEditItemsFetch.fulfilled, (state, { payload }) => {
      state.items = payload;

      state.fetchable.items.isFetched = true;
      state.fetchable.items.isFetching = false;
      state.fetchable.items.isFailed = false;
    });
    builder.addCase(productOrderEditItemsFetch.rejected, state => {
      state.fetchable.items.isFailed = true;
      state.fetchable.items.isFetching = false;
      state.fetchable.items.isFetched = false;
    });
    builder.addCase(productOrderSave.pending, state => {
      state.fetchable.main.isFetching = true;
      state.fetchable.main.isFetched = false;
      state.fetchable.main.isFailed = false;
    });
    builder.addCase(productOrderSave.fulfilled, state => {
      state.fetchable.main.isFetched = true;
      state.fetchable.main.isFetching = false;
      state.fetchable.main.isFailed = false;
    });
    builder.addCase(productOrderSave.rejected, state => {
      state.fetchable.main.isFailed = true;
      state.fetchable.main.isFetching = false;
      state.fetchable.main.isFetched = false;
    });
  },
});

export const {
  productOrderEditStartSession,
  productOrderEditReset,
  productOrderEditSetField,
  productOrderEditItemsSetField,
  productOrderReplaceItemField,
  productOrderEditSetWarning,
  productOrderAppendItem,
} = slice.actions;

export default slice.reducer;
