import { ServerErrorResponse } from '@/data/network/types';
import { Fetchable, fetchableFetched } from '@/data/store/types';
import { Nullable } from '@/domain';
import { EUserActionsType } from '@features/user/utils';
import { CaseReducer, createSlice, Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

const handleActionProcess = (
  state: Draft<UserCurrentActionsState>,
  actionType: EUserActionsType,
  fetchable: Fetchable,
  error: Nullable<ServerErrorResponse | undefined> = null
) => {
  let process = state.actions.find(change => change.type === actionType);

  if (process) {
    process.type = actionType;
    process.error = error ?? null;
    process.isFetching = fetchable.isFetching;
    process.isFetched = fetchable.isFetched;
    process.isFailed = fetchable.isFailed;
  } else {
    process = {
      ...fetchable,
      type: actionType,
      error: null,
    };

    state.actions.push(process);
  }
};

export interface UserCurrentActionsState {
  readonly actions: (Fetchable & {
    type: EUserActionsType;
    error: Nullable<ServerErrorResponse>;
  })[];
  readonly dialogs: {
    changeEmail: boolean;
  };
}

type Reducer<T = undefined> = CaseReducer<UserCurrentActionsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<UserCurrentActionsState> {
  userActionsChangeDialogState: Reducer<{
    name: keyof UserCurrentActionsState['dialogs'];
    data: boolean;
  }>;
  userActionsSetActionFulfilled: Reducer<EUserActionsType>;
  userActionsOptimize: Reducer;
}

const initialState: UserCurrentActionsState = {
  actions: [],
  dialogs: {
    changeEmail: false,
  },
};

const slice = createSlice<UserCurrentActionsState, Reducers>({
  name: 'user/actions',
  initialState,
  reducers: {
    userActionsChangeDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
    userActionsSetActionFulfilled: (state, { payload }) => {
      handleActionProcess(state, payload, fetchableFetched);
    },
    userActionsOptimize: state => {
      // Оставляем только выполняющиеся действия
      state.actions = state.actions.filter(action => action.isFetching);
    },
  },
});

export const { userActionsChangeDialogState, userActionsOptimize, userActionsSetActionFulfilled } = slice.actions;
export default slice.reducer;
