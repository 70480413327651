import { Fade, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { CmsSitePage } from '../../../../../domain/model/cms';
import {
  ECmsContainerStatus,
  ECmsContainerType,
  ECmsLinkObjectType,
  EOfferType,
} from '../../../../../domain/model/enums';
import { Nullable, UUID } from '../../../../../domain/model/types';
import MasterActionsComponent from '../../../../components/common/actions/master';
import useNavAdapter from '../../../../components/common/actions/navAdapter/hooks';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import SaveWhenExitDialog from '../../../../components/common/dialogs/saveWhenExit';
import DefaultHeader from '../../../../components/common/header';
import ContentLoader from '../../../../components/common/loader';
import { EMPStepperTransitionMethod, MPStepperLabelStrategy } from '../../../../components/common/stepper';
import { DefaultContentWrapper } from '../../../../components/common/wrappers/content';
import { DefaultFooterWrapper } from '../../../../components/common/wrappers/footer';
import { OnChangeObjectAttribute, PanelAction } from '../../../../types';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import StepperContainer from '../../../general/stepper/container';
import { getPartnerCmsContainerEditRoute } from '../../../partnerWindow/entry';
import { CmsContainerView, CmsLinkedObjectDataTypes } from '../../types';
import useCmsContainerStepper from '../hooks/useStepper';
import { CmsContainerLifeCycle } from '../lifecycle/types';
import {
  CmsContainerActionCreateType,
  CmsContainerStep,
  CmsFeatureContainerCommonProps,
  ECmsContainerActionType,
} from '../types';
import { getCmsContainerActionsConfigByPermissions } from '../utils/actions';
import CmsContainerCreate from './component';
import { ContainerWrapper, ContentContainer, LoaderWrapper, TitleWrapper } from './controls';
import {
  cmsContainerCreateByIdSelector,
  cmsContainerCreateComponentsSelector,
  cmsContainerCreateCurrentLinkedObjectSelector,
  cmsContainerCreateDeleteSelector,
  cmsContainerCreateDuplicateSelector,
  cmsContainerCreateGuidSelector,
  cmsContainerCreateIdSelector,
  cmsContainerCreateLinkedObjectsSelector,
  cmsContainerCreatePublishSelector,
  cmsContainerCreateSaveSelector,
} from './store/selectors';
import {
  cmsContainerCreateByIdFetch,
  cmsContainerCreateChangeLinkedObject,
  cmsContainerCreateDelete,
  cmsContainerCreateDuplicate,
  cmsContainerCreatePublish,
  cmsContainerCreateSave,
  cmsContainerCreateSetAttribute,
  cmsContainerCreateStartSession,
} from './store/slice';
import { CmsContainerCreateComponentDictionaries, CmsContainerCreateConfiguration } from './types';
import useCmsContainerCreateActions from './useActions';
import useCmsContainerCreateValidation from './useValidation';

interface CmsContainerCreateContainerProps extends CmsFeatureContainerCommonProps {
  readonly cmsSitePage: CmsSitePage;
  readonly id: Nullable<UUID>;
  readonly guid: UUID;
  readonly step: number;
  readonly type: ECmsContainerType;
  readonly lifecycle: CmsContainerLifeCycle;
  readonly canCreate?: boolean;
  readonly canSave?: boolean;
  readonly canPublish?: boolean;
  readonly canDelete?: boolean;
  readonly canChangeOfferIconVisible?: boolean;
  readonly onClose: () => void;
}

const CmsContainerCreateContainer = (props: CmsContainerCreateContainerProps) => {
  const {
    cmsContext,
    cmsSitePage,
    id: sourceId,
    guid,
    step,
    type,
    lifecycle,
    onClose,
    canCreate,
    canSave,
    canPublish,
    canDelete,
    canChangeOfferIconVisible,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [closeMarker, setCloseMarker] = useState<Nullable<symbol>>(null);

  const {
    cmsContainerTypes,
    cmsContainerStatuses,
    cmsBannerLinkedObjectTypes,
    offerTypes,
    userGenders: genders,
  } = useSelector(nsiDataSelector);

  const configuration = useMemo<CmsContainerCreateConfiguration>(
    () => ({ canChangeOfferIconVisible }),
    [canChangeOfferIconVisible]
  );

  const dictionaries = useMemo<CmsContainerCreateComponentDictionaries>(
    () => ({
      offerTypes: offerTypes.filter(item => ![EOfferType.Product, EOfferType.Booking].some(ot => ot === item.id)),
      genders,
    }),
    [offerTypes, genders]
  );

  const cmsContainerType = cmsContainerTypes.find(item => item.code === type) ?? cmsContainerTypes?.[0];

  const currentLinkedObjectIndex = step - 1;

  const { isFetching: isSaving } = useSelector(cmsContainerCreateSaveSelector);
  const { isFetching: isPublishing } = useSelector(cmsContainerCreatePublishSelector);
  const { isFetching: isDeleting } = useSelector(cmsContainerCreateDeleteSelector);
  const { isFetching: isDuplicating } = useSelector(cmsContainerCreateDuplicateSelector);
  const { data: cmsContainer, isFetching, modified: isModified } = useSelector(cmsContainerCreateByIdSelector);
  const id: Nullable<UUID> = useSelector(cmsContainerCreateIdSelector(guid, sourceId));
  const currentGuid = useSelector(cmsContainerCreateGuidSelector);
  const cmsComponents = useSelector(cmsContainerCreateComponentsSelector);
  const cmsLinkedObjects = useSelector(cmsContainerCreateLinkedObjectsSelector);
  const cmsLinkedObject = useSelector(cmsContainerCreateCurrentLinkedObjectSelector(currentLinkedObjectIndex));

  const isDraft = cmsContainer.status === ECmsContainerStatus.Draft;

  const statusName: string = cmsContainerStatuses?.find(cs => cs.id === cmsContainer.status)?.name ?? '';
  const isLoading = isFetching || isSaving || isPublishing || isDeleting || isDuplicating;

  const cmsLinkedObjectIndex = cmsLinkedObject ? step - 1 : null;

  const {
    currentStepIndex,
    nextStepIndex,
    steps,
    currentStep,
    isLastStep,
    isNextStepDirectionForward,
    openStep,
    openPrevStep,
  } = useCmsContainerStepper({
    currentStepKey: step,
    cmsComponents,
  });

  const {
    isValidGeneral,
    validationResultGeneral,
    validationResultLinkedObject,
    validateDraft,
    validateCurrentStep,
    validateAll,
  } = useCmsContainerCreateValidation({
    currentStepIndex,
    cmsContainer,
    cmsLinkedObjects,
  });

  const onChangeAttribute = useCallback<OnChangeObjectAttribute<CmsContainerView>>(
    (name, value) => {
      dispatch(cmsContainerCreateSetAttribute({ name, value }));
    },
    [dispatch]
  );

  const onChangeLinkedObjectAttribute = useCallback(
    <T extends CmsLinkedObjectDataTypes>(type: ECmsLinkObjectType) =>
      (data: T) => {
        if (cmsLinkedObjectIndex !== null) {
          dispatch(
            cmsContainerCreateChangeLinkedObject({
              index: cmsLinkedObjectIndex,
              type,
              data: data as any,
            })
          );
        }
      },
    [dispatch, cmsLinkedObjectIndex]
  );

  const onChangeLinkedObjectAttributeByCurrentType = useCallback(
    <T extends CmsLinkedObjectDataTypes>(data: T) => {
      if (cmsLinkedObject) {
        onChangeLinkedObjectAttribute<T>(cmsLinkedObject.type)(data);
      }
    },
    [cmsLinkedObject]
  );

  const onChangeStep = (nextStep: CmsContainerStep) => {
    if (currentStep === nextStep) return;

    const isForwardDirection = isNextStepDirectionForward(nextStep);
    const isCurrentStepValid = isForwardDirection ? validateCurrentStep() : true;

    // меняем страницу без сохранения
    if (isCurrentStepValid) {
      openStep(nextStep);
    }
  };

  const onSave = useCallback(async () => {
    const response = await dispatch(
      cmsContainerCreateSave({
        id,
        cmsContext,
        cmsSitePage,
      })
    ).unwrap();
    return response.cmsContainer;
  }, [dispatch, id, cmsContext, cmsSitePage]);

  const onPublish = useCallback(() => {
    onSave().then(savedCmsContainer => {
      dispatch(
        cmsContainerCreatePublish({
          id: savedCmsContainer.id,
        })
      )
        .unwrap()
        .then(onClose);
    });
  }, [dispatch, id, onSave, onClose]);

  const onDelete = useCallback(() => {
    if (id) {
      dispatch(cmsContainerCreateDelete({ id })).unwrap().then(onClose);
    }
  }, [dispatch, id, onClose]);

  const onDuplicate = useCallback(() => {
    if (id) {
      dispatch(cmsContainerCreateDuplicate({ cmsSitePage, id }))
        .unwrap()
        .then(newContainer => {
          history.push(getPartnerCmsContainerEditRoute({ id: newContainer.id, location }));
        });
    }
  }, [dispatch, cmsSitePage, id, history, location]);

  const onValidateBeforeSave = useCallback(
    () => (isDraft ? validateDraft() : validateAll(openStep)),
    [isDraft, validateDraft, validateAll]
  );

  const onPanelAction = useCallback(
    (action: PanelAction<CmsContainerActionCreateType>) => {
      switch (action.type) {
        case ECmsContainerActionType.Save:
          if (onValidateBeforeSave()) {
            onSave().then(onClose);
          }
          break;
        case ECmsContainerActionType.Publish:
          if (validateAll(openStep)) onPublish();
          break;
        case ECmsContainerActionType.Delete:
          onDelete();
          break;
        case ECmsContainerActionType.Duplicate:
          onDuplicate();
          break;
      }
    },
    [id, onValidateBeforeSave, validateAll, onSave, onPublish, onDelete, onDuplicate]
  );

  const onTryClose = () => {
    if (isModified) {
      setCloseMarker(Symbol());
    } else {
      onClose();
    }
  };

  const getOnSaveWithNextAction = useCallback(
    (afterSaveAction: () => void) => () => {
      setCloseMarker(null);
      if (onValidateBeforeSave()) {
        onSave().then(afterSaveAction);
      }
    },
    [onSave, onValidateBeforeSave, setCloseMarker]
  );

  //старт сеанса
  useEffect(() => {
    if (guid !== currentGuid) {
      dispatch(cmsContainerCreateStartSession({ guid, type: cmsContainerType }));
      if (id) {
        dispatch(cmsContainerCreateByIdFetch({ id, type: cmsContainerType }));
      }
    }
  }, [dispatch, id, guid, currentGuid, cmsContainerType]);

  const allowedActions = useMemo(
    () =>
      getCmsContainerActionsConfigByPermissions({
        canCreate,
        canPublish,
        canSave,
        canDelete,
      }),
    [canPublish, canSave, canDelete]
  );

  const actions = useCmsContainerCreateActions({
    allowedActions,
    cmsContainer,
    lifecycle,
    isLastStep,
  });

  const { adapter: navAdapter, actions: navActions } = useNavAdapter({
    openPrevStep,
    currentStepIndex,
    openNextStep: () => onChangeStep(steps[nextStepIndex]),
    stepsCount: steps.length,
  });

  const actionsPanel = (actions.length > 0 || !isLastStep) && (
    <MasterActionsComponent<CmsContainerActionCreateType>
      actions={actions}
      show={!!actions.length || !!navActions.length}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
      navAdapter={navAdapter}
    />
  );

  if (guid !== currentGuid) {
    return null;
  }

  return (
    <Fade in>
      <ContainerWrapper>
        <SaveWhenExitDialog
          description='В блок были внесены изменения. Вы хотите сохранить изменения?'
          open={!!closeMarker}
          onCancel={() => setCloseMarker(null)}
          onExit={onClose}
          onExitAfterSave={getOnSaveWithNextAction(onClose)}
        />
        <StepperContainer<number>
          step={currentStep}
          steps={steps}
          forwardTransition={EMPStepperTransitionMethod.Neighbour}
          labelStrategy={MPStepperLabelStrategy.FromZero}
          onClick={onChangeStep}
        />
        <ContentContainer>
          <DefaultContentWrapper
            type='details'
            stickyHeader
            fullHeight
            footer={actionsPanel}
          >
            <DefaultHeader
              sticky
              headline={
                <AppBreadcrumbs>
                  <Typography color='textSecondary'>{statusName}</Typography>
                </AppBreadcrumbs>
              }
              onClose={onTryClose}
            >
              <TitleWrapper>
                <Typography variant='h2'>
                  {isDraft ? `Создание блока «${cmsContainerType.name}»` : `Блок «${cmsContainerType.name}»`}
                </Typography>
              </TitleWrapper>
            </DefaultHeader>
            <CmsContainerCreate
              configuration={configuration}
              guid={guid}
              cmsContainer={cmsContainer}
              cmsContext={cmsContext}
              cmsLinkedObject={cmsLinkedObject}
              cmsLinkedObjects={cmsLinkedObjects}
              isValidGeneral={isValidGeneral}
              validation={validationResultGeneral}
              validationCmsLinkedObject={validationResultLinkedObject}
              dictionaries={dictionaries}
              cmsBannerLinkedObjectTypes={cmsBannerLinkedObjectTypes}
              onChange={onChangeAttribute}
              onChangeLinkedObject={onChangeLinkedObjectAttributeByCurrentType}
            />
          </DefaultContentWrapper>
        </ContentContainer>
        {isLoading && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
      </ContainerWrapper>
    </Fade>
  );
};

export default CmsContainerCreateContainer;
