import { formatCurrency, formatNumber } from '../../../../utils';
import ProductTableCell, { ProductTableCellCommonProps } from './index';

const ProductTableCellPrice = (props: ProductTableCellCommonProps) => {
  const {
    productOffer: { price, currencyType },
  } = props;

  const value = currencyType ? formatCurrency(price, currencyType) : formatNumber(price);

  return (
    <ProductTableCell
      {...props}
      value={value ?? '-'}
    />
  );
};

export default ProductTableCellPrice;
