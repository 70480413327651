import { EOfferStatus } from '../../../../../../domain/model/enums';
import { EOfferActionType } from '../../types';
import { disableOfferAction, renameOfferAction } from '../../utils/actions';
import { common as commonCondition, corp as corpCondition } from '../conditions';
import { CorpOfferLifeCycleActionsRule } from '../types';

/*
 * сложные правила
 */

export const disableChangeApprovalRegistryActionRule: CorpOfferLifeCycleActionsRule = {
  description: 'Отключение доступности работы с приказом',
  algorithm: [
    ...commonCondition.isWaitingForModerationCondition.algorithm,
    'или',
    ...commonCondition.isNotOnModerationByMeCondition.algorithm,
  ],
  call: props => {
    if (
      commonCondition.isWaitingForModerationCondition.call(props) ||
      commonCondition.isNotOnModerationByMeCondition.call(props)
    ) {
      disableOfferAction(props.nextActions, EOfferActionType.ChangeApprovalRegistry);
      return true;
    }
    return false;
  },
};

export const disableActivationActionsRule: CorpOfferLifeCycleActionsRule = {
  description: 'Отключение доступности любой активации',
  algorithm: [
    ...commonCondition.isNotDraftCondition.algorithm,
    'и',
    ...corpCondition.hasNotApprovalRegistryCondition.algorithm,
  ],
  call: props => {
    if (commonCondition.isNotDraftCondition.call(props) && corpCondition.hasNotApprovalRegistryCondition.call(props)) {
      disableOfferAction(props.nextActions, EOfferActionType.Approve);
      disableOfferAction(props.nextActions, EOfferActionType.Resume);
      return true;
    }
    return false;
  },
};

export const removeResumeActionRule: CorpOfferLifeCycleActionsRule = {
  description: 'Удаление возобновления',
  algorithm: [
    ...commonCondition.hasNotFreeOfferCountCondition.algorithm,
    'и',
    ...commonCondition.canNotEditCodesCondition.algorithm,
  ],
  call: props => {
    if (
      commonCondition.hasNotFreeOfferCountCondition.call(props) &&
      commonCondition.canNotEditCodesCondition.call(props)
    ) {
      disableOfferAction(props.nextActions, EOfferActionType.Resume);
      return true;
    }
    return false;
  },
};

export const renameChangeApprovalRegistryActionRule: CorpOfferLifeCycleActionsRule = {
  description: 'Переименование действия с приказом',
  algorithm: [...corpCondition.hasApprovalRegistryCondition.algorithm],
  call: props => {
    if (corpCondition.hasApprovalRegistryCondition.call(props)) {
      renameOfferAction(props.nextActions, EOfferActionType.ChangeApprovalRegistry, 'Присвоить другой приказ');
    } else {
      renameOfferAction(props.nextActions, EOfferActionType.ChangeApprovalRegistry, 'Присвоить приказ');
    }
  },
};

export const adminChangeCodeStatusRule: CorpOfferLifeCycleActionsRule = {
  description: 'Возможность сменить статус кода админом',
  algorithm: [
    ...commonCondition.isModeratedByMeCondition.algorithm,
    'или статус в [Черновик, Отклонено, Приостановлено, Активно, Предстоящее]',
  ],
  call: props => {
    return (
      commonCondition.isModeratedByMeCondition.call(props) ||
      [
        EOfferStatus.Draft,
        EOfferStatus.Rejected,
        EOfferStatus.Active,
        EOfferStatus.Paused,
        EOfferStatus.Upcoming,
      ].includes(props.obj.status)
    );
  },
};

export const partnerChangeCodeStatusRule: CorpOfferLifeCycleActionsRule = {
  description: 'Возможность сменить статус кода партнером',
  algorithm: [
    ...commonCondition.isDraftCondition.algorithm,
    'или',
    ...commonCondition.isWaitingForModerationCondition.algorithm,
    'или',
    ...commonCondition.isRejectedCondition.algorithm,
  ],
  call: props => {
    return (
      commonCondition.isDraftCondition.call(props) ||
      commonCondition.isWaitingForModerationCondition.call(props) ||
      commonCondition.isRejectedCondition.call(props)
    );
  },
};
