import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { viewShowMainMenu } from '../../features/main/container/store/slice';
import SupportInfoMpContainer from '../../features/support/mp/container';
import SupportInfoSportContainer from '../../features/support/sport/container';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const SupportInfoDetailsScreen = () => {
  const dispatch = useDispatch();
  const { accessMatrix } = useCurrentUser();

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (accessMatrix.isAdminSport) {
    return <SupportInfoSportContainer />;
  } else {
    return <SupportInfoMpContainer />;
  }
};

export default SupportInfoDetailsScreen;
