import { AdCampaignCreate, CorpOffer, EOfferStatus, EOfferType } from '@/domain';
import { NumberRange } from '@/presentation/types';
import TopPushDialog from '@components/common/dialogs/topPush';
import AdCampaignsOfferCollectionComponent from '@features/adCampaign/components/offerCollection';
import useAdCampaignCollectionOffer from '@features/adCampaign/create/collection/useChangeOffers';
import { getCorpOfferDetailsRoute } from '@features/corpOffer/entry';
import CorpOfferSearchContainer from '@features/corpOffer/search/container';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import useCurrentUser from '@features/user/hooks/useCurrentUser';
import { viewConfig } from '@theme/viewConfig';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getAdCampaignSearchCorpOfferPresetValues } from '../../utils';
import useAdCampaignCollectionCorpOfferFilterAdapter from './useFilterAdapter';

interface AdCampaignCorpOfferCollectionContainerProps {
  readonly guid: UUID;
  readonly offers: CorpOffer[];
  readonly adCampaign: AdCampaignCreate;
  readonly selectedMaxCount?: NumberRange<2, 100> | false;
  readonly onChange: (offers: CorpOffer[]) => void;
}

const AdCampaignCorpOfferCollectionContainer = (props: AdCampaignCorpOfferCollectionContainerProps) => {
  const { guid, offers, adCampaign, selectedMaxCount, onChange } = props;

  const history = useHistory();
  const {
    accessMatrix: {
      corpOffers: { viewAsAdmin = false },
    },
  } = useCurrentUser();

  const { userGenders: genderTypes } = useSelector(nsiDataSelector);

  const filterPresetValues = useMemo(() => getAdCampaignSearchCorpOfferPresetValues(adCampaign), [adCampaign]);

  const {
    isSearchDialogOpened,
    searchDialogParams,
    onOpenSearchDialog,
    onCloseSearchDialog,
    onChangeSearchDialogTab,
    onSelect,
    onClear,
    onUnselect,
  } = useAdCampaignCollectionOffer<CorpOffer>({
    offerType: EOfferType.Corp,
    selected: offers,
    onChange,
  });

  const filterAdapter = useAdCampaignCollectionCorpOfferFilterAdapter({
    viewAsAdmin,
    tab: searchDialogParams.dialog_tab,
    partnerId: null,
    values: filterPresetValues,
    genderTypes,
  });

  const onOfferClick = useCallback(
    (id: UUID) => {
      history.push(getCorpOfferDetailsRoute({ id }));
    },
    [history]
  );

  return (
    <>
      <AdCampaignsOfferCollectionComponent
        offers={offers}
        offerType={EOfferType.Corp}
        onShowOfferSearch={onOpenSearchDialog}
        onClearOffers={onClear}
        onRemoveOffer={onUnselect}
      />
      <TopPushDialog
        fullScreen
        stickyHeader
        open={isSearchDialogOpened}
        title='Выбор корпоративных предложений'
        leftOffset={viewConfig.stepper.width}
        onClose={onCloseSearchDialog}
      >
        <CorpOfferSearchContainer
          guid={searchDialogParams?.dialog_guid ?? guid}
          partnerId={null}
          statuses={[EOfferStatus.Active]}
          filterAdapter={filterAdapter}
          initialSelected={offers}
          selectedMaxCount={selectedMaxCount}
          tab={searchDialogParams.dialog_tab}
          onCorpOfferClick={corpOffer => onOfferClick(corpOffer.id)}
          onSelect={onSelect}
          onChangeTableTab={onChangeSearchDialogTab}
        />
      </TopPushDialog>
    </>
  );
};

export default AdCampaignCorpOfferCollectionContainer;
