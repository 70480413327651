import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { Nullable, UUID } from '../../../domain/model/types';
import { viewShowMainMenu } from '../../features/main/container/store/slice';
import SportsmanDetailsContainer from '../../features/sportsman/details/container';
import { ESportsmanTab } from '../../features/sportsman/details/utils';
import { ESportsmanUrlParam } from '../../features/sportsman/entry';

const SportsmanScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const guid: Nullable<UUID> = (new URLSearchParams(location.search).get(ESportsmanUrlParam.Guid) as UUID) ?? uuidv4();
  const tab: ESportsmanTab =
    (new URLSearchParams(location.search).get(ESportsmanUrlParam.Tab) as ESportsmanTab) ?? ESportsmanTab.General;
  const { id } = useParams<{ id: UUID }>();

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  return (
    <SportsmanDetailsContainer
      guid={guid}
      id={id}
      tab={tab}
    />
  );
};

export default SportsmanScreen;
