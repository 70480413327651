import { removeActiveActionRule, removeEditActionRule, removePauseActionRule } from './rules';
import * as schemas from './scheme';
import { ClientOrgLifeCycleRulesConfiguration } from './types';

/**
 * конфигурация правил админа
 */
export const commonAdminConfiguration: ClientOrgLifeCycleRulesConfiguration = {
  statusScheme: schemas.adminStatusScheme.statusScheme,
  actionsScheme: schemas.adminStatusScheme.actionsScheme,
  actionRules: [removeActiveActionRule, removePauseActionRule, removeEditActionRule],
};

/**
 * конфигурация правил партнёра
 */
export const commonPartnerConfiguration: ClientOrgLifeCycleRulesConfiguration = {
  statusScheme: schemas.partnerStatusScheme.statusScheme,
  actionsScheme: schemas.partnerStatusScheme.actionsScheme,
  actionRules: [removeActiveActionRule, removePauseActionRule, removeEditActionRule],
};
