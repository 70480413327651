import { SportEventShort } from '../../../../domain/model/event';
import { Nullable } from '../../../../domain/model/types';

export interface EventListFilter {
  readonly name: Nullable<string>;
}

export enum EEventListElementType {
  Splitter,
  Event,
}

export type EventListItemSplitter = {
  readonly id: any;
  readonly type: EEventListElementType.Splitter;
  readonly data: string;
};

export type EventListItemEvent = {
  readonly id: any;
  readonly type: EEventListElementType.Event;
  readonly data: SportEventShort;
};

export type EventListElement = EventListItemSplitter | EventListItemEvent;
