import { EOfferType, SportOption } from '@/domain';
import { DataFilterAdapter } from '@/presentation/hooks/useDataFilterAdapter';
import {
  BookingOfferTableFilterEditStrategy,
  bookingOfferTableFilterItems,
  BookingOfferTableFilterValues,
  EBookingOfferTableFilterItem,
  getBookingOfferTableFilterStrategy,
} from '@features/bookingOffer/filterUtils';
import { getBookingOfferAdminTableColumns, getBookingOfferOwnerTableColumns } from '@features/bookingOffer/table/utils';
import { EBookingOfferTableColumn } from '@features/bookingOffer/types';
import { EOfferTableTab } from '@features/general/offer/types';
import { getOffersTableAdapterKey } from '@features/general/offer/utils/table';
import { useMemo } from 'react';
import useTechConfig from '@/presentation/hooks/useTechConfig';
import { EAppFeature } from '@/presentation/types';

type UseAdCampaignCollectionBookingOfferFilterAdapter = DataFilterAdapter<
  EBookingOfferTableColumn,
  EBookingOfferTableFilterItem
>;

type UseAdCampaignCollectionBookingOfferFilterAdapterProps = {
  readonly viewAsAdmin: boolean;
  readonly partnerId: Nullable<UUID>;
  readonly tab: EOfferTableTab;
  readonly values: BookingOfferTableFilterValues;
  readonly partnerTypes: SportOption[];
};

const useAdCampaignCollectionBookingOfferFilterAdapter = (
  props: UseAdCampaignCollectionBookingOfferFilterAdapterProps
): UseAdCampaignCollectionBookingOfferFilterAdapter => {
  const { viewAsAdmin, partnerId, tab, values, partnerTypes } = props;
  const { hasFeature } = useTechConfig();
  const isCSP = hasFeature(EAppFeature.Csp);

  const filterPreset = useMemo(
    () =>
      Object.keys(values)
        .map(key => {
          const strategy = getBookingOfferTableFilterStrategy(
            key as EBookingOfferTableFilterItem,
            values,
            partnerTypes
          );
          if (strategy) {
            const readonlyStrategy = { ...strategy, readOnly: true };
            return { ...readonlyStrategy, viewAlways: undefined };
          } else {
            return null;
          }
        })
        .filter(strategy => !!strategy) as BookingOfferTableFilterEditStrategy[],
    [values, partnerTypes]
  );

  const sourceColumns = useMemo(
    () =>
      viewAsAdmin ? getBookingOfferAdminTableColumns(tab, partnerId, isCSP) : getBookingOfferOwnerTableColumns(isCSP),
    [partnerId, viewAsAdmin]
  );

  const { key, keyPartitions } = useMemo(
    () =>
      getOffersTableAdapterKey({
        offerType: EOfferType.Booking,
        isAdmin: viewAsAdmin ?? false,
        partnerId,
        tab,
      }),
    [tab, partnerId, viewAsAdmin]
  );

  const getBookingOfferTableFilterStrategyInternal = useMemo(
    () => (filterItem: EBookingOfferTableFilterItem, values: BookingOfferTableFilterValues) =>
      getBookingOfferTableFilterStrategy(filterItem, values, partnerTypes),
    [partnerTypes]
  );

  return useMemo<DataFilterAdapter<EBookingOfferTableColumn, EBookingOfferTableFilterItem>>(
    () => ({
      key,
      keyPartitions,
      sourceColumns: sourceColumns,
      filterItems: bookingOfferTableFilterItems,
      filterPreset,
      requiredFilters: [EBookingOfferTableFilterItem.Query],
      getDataFilterStrategy: getBookingOfferTableFilterStrategyInternal,
    }),
    [partnerId, key, keyPartitions, sourceColumns, filterPreset]
  );
};

export default useAdCampaignCollectionBookingOfferFilterAdapter;
