import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UUID } from '../../../../../domain/model/types';
import { sportsmansGetGuid } from '../store/selectors';
import { sportsmanSetTab, sportsmanStartSession } from '../store/slice';
import { ESportsmanTableTab } from '../utils';

interface UseSportsmanTableSessionProps {
  readonly guid: UUID;
  readonly tab: ESportsmanTableTab;
}

const useSportsTableSession = (props: UseSportsmanTableSessionProps): void => {
  const { guid, tab } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(sportsmansGetGuid);

  // инициализация сеанса
  useEffect(() => {
    dispatch(sportsmanStartSession({ guid }));
  }, [dispatch, guid]);

  // начальная загрузка, изменение вкладки
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(sportsmanSetTab({ tab }));
    }
  }, [dispatch, guid, currentGuid, tab]);
};

export default useSportsTableSession;
