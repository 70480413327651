import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { reportsDataReset, reportsStartSession } from '../store/slice';
import { useContextConfig } from './useContextConfig';

type UseReportsTableSessionProps = {
  readonly guid: UUID;
};

export const useReportsTableSession = ({ guid }: UseReportsTableSessionProps) => {
  const dispatch = useDispatch();
  const { reports } = useContextConfig();

  // инициализация сеанса
  useEffect(() => {
    dispatch(reportsStartSession({ guid, reports: reports || [] }));
  }, [dispatch, guid]);

  // сброс вотчера после ухода
  useEffect(() => {
    return () => {
      dispatch(reportsDataReset());
    };
  }, [dispatch]);
};
