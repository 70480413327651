import { EDateTimeFormat } from '@/domain/model/formats';
import moment from 'moment/moment';
import CorpActivationTableCell, { CorpActivationTableCellCommonProps } from './index';

const CorpActivationTableCellGivenAt = (props: CorpActivationTableCellCommonProps) => {
  const {
    corpActivation: { givenAt },
  } = props;

  const value = givenAt ? moment(givenAt).format(EDateTimeFormat.DisplayDefault) : '-';
  return (
    <CorpActivationTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpActivationTableCellGivenAt;
