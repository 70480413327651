import React from 'react';
import { OfferCategory } from '../../../../../domain/model';
import { Nullable } from '../../../../../domain/model/types';
import { BookingOfferCategoriesEditState } from './store/slice';

export type BookingOfferCategoriesEditHandlersContextValue = {
  readonly onChangeDialogState: <Name extends keyof BookingOfferCategoriesEditState['dialogs']>(
    name: Name,
    data: Nullable<BookingOfferCategoriesEditState['dialogs'][Name]>
  ) => void;
  readonly onCreate: (bookingOfferCategory: OfferCategory) => Promise<OfferCategory>;
  readonly onEdit: (bookingOfferCategory: OfferCategory) => Promise<OfferCategory>;
  readonly onDelete: (bookingOfferCategory: OfferCategory) => Promise<void>;
  readonly onGetRelationShipsCount: (bookingOfferCategory: OfferCategory) => Promise<number>;
  readonly onClose: () => void;
};

export const BookingOfferCategoriesEditHandlersContext =
  React.createContext<BookingOfferCategoriesEditHandlersContextValue>(
    {} as BookingOfferCategoriesEditHandlersContextValue
  );
