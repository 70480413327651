import { EPartnerStatus } from '../../../../domain/model/enums';
import { EPanelActionPosition, PanelActions } from '../../../types';
import { EPartnerApplicationActionType, PartnerApplicationEditActionType } from '../types';
import { getPartnerApplicationActionName } from '../utils';

interface GetPartnerApplicationEditActionsProps {
  readonly partnerStatus: EPartnerStatus;
  readonly canApprove?: boolean;
  readonly canSendToVerification?: boolean;
  readonly canReturnToVerification?: boolean;
}

export const getPartnerApplicationEditActions = ({
  partnerStatus,
  canApprove,
  canSendToVerification,
  canReturnToVerification,
}: GetPartnerApplicationEditActionsProps): PanelActions<PartnerApplicationEditActionType> => {
  const actions: PanelActions<PartnerApplicationEditActionType> = [];

  if (canSendToVerification && partnerStatus === EPartnerStatus.Unverified) {
    actions.push({
      type: EPartnerApplicationActionType.SendToVerification,
      label: getPartnerApplicationActionName(EPartnerApplicationActionType.SendToVerification),
      primary: true,
      position: [EPanelActionPosition.Default],
    });
  }

  if (canReturnToVerification && partnerStatus === EPartnerStatus.Rejected) {
    actions.push({
      type: EPartnerApplicationActionType.ReturnToVerification,
      label: getPartnerApplicationActionName(EPartnerApplicationActionType.ReturnToVerification),
      primary: true,
      position: [EPanelActionPosition.Default],
    });
  }

  if (canApprove) {
    actions.push({
      type: EPartnerApplicationActionType.Approve,
      label: 'Активировать партнера',
      primary: true,
      position: [EPanelActionPosition.Default],
    });
  }

  if (canSendToVerification && partnerStatus === EPartnerStatus.ToVerification) {
    actions.push({
      type: EPartnerApplicationActionType.Save,
      label: getPartnerApplicationActionName(EPartnerApplicationActionType.Save),
      primary: true,
      position: [EPanelActionPosition.Default],
    });
  }

  return actions;
};
