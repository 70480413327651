import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TabsPanel from '../../../../components/common/tabs/panel';
import { EPartnerTableTab } from '../../../general/partner/types';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { partnersTabsCounterSelector } from '../store/selectors';
import PartnerTableTab from '../tab';

interface PartnersTableTabsAdapterProps {
  readonly tab: EPartnerTableTab;
}

export const PartnersTableTabsAdapter = (props: PartnersTableTabsAdapterProps) => {
  const { tab } = props;

  const { onChangeTab } = useContextHandlers();

  const counter = useSelector(partnersTabsCounterSelector);

  return useMemo(
    () => (
      <TabsPanel
        tab={tab}
        tabs={Object.values(EPartnerTableTab)}
        labelAdapter={PartnerTableTab}
        data={{
          counts: counter,
        }}
        onChangeTab={onChangeTab}
      />
    ),
    [tab, counter, onChangeTab]
  );
};
