import { ContentTarget, Target } from '@/domain';
import { ContentTargetComponentsType } from '@features/general/contentTarget/types';
import {
  convertContentTargetAttributeToTargetAttribute,
  convertTargetToContentTarget,
  convertTargetValidationToContentTargetValidation,
} from '@features/general/contentTarget/utils';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import { useSelector } from 'react-redux';
import { OnChangeObjectAttribute } from '../../../../../types';
import { ValidationResult } from '../../../../../utils/validation';
import ContentTargetEditContainer from '../../../../general/contentTarget/edit/container';

type CmsEditTargetAdapterProps<T extends Target> = ContentTargetComponentsType & {
  readonly target: T;
  readonly validation: Nullable<ValidationResult<T>>;
  readonly onChangeAttribute: OnChangeObjectAttribute<T>;
};

const CmsEditTargetAdapter = <T extends Target>(props: CmsEditTargetAdapterProps<T>) => {
  const { target, validation, onChangeAttribute, ...components } = props;

  const { userGenders } = useSelector(nsiDataSelector);

  const contentTarget: ContentTarget = convertTargetToContentTarget(target);
  const contentTargetValidation: Nullable<ValidationResult<ContentTarget>> =
    convertTargetValidationToContentTargetValidation(validation);

  const onChangeAttributeInternal: OnChangeObjectAttribute<ContentTarget> = (name, value) => {
    onChangeAttribute(convertContentTargetAttributeToTargetAttribute(name), value as any);
  };

  return (
    <ContentTargetEditContainer
      {...components}
      target={contentTarget}
      nsi={{ genderTypes: userGenders }}
      validation={contentTargetValidation}
      onChangeAttribute={onChangeAttributeInternal}
    />
  );
};

export default CmsEditTargetAdapter;
