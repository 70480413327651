import { ESortDirection, OfferState } from '@/domain';
import DataTable from '@components/common/table';
import { DataTableLoader } from '@components/common/table/loader';
import OfferHistoryTableCellAdapter from '../../adapters/cell';
import OfferHistoryTableCellActorTypeAdapter from '../../adapters/cellActorType';
import OfferHistoryTableCellEnteredByAdapter from '../../adapters/cellEnteredBy';
import OfferHistoryTableCellStatusAdapter from '../../adapters/cellStatus';
import { offerHistoryTableIsLoadingSelector } from '../../store/selectors';
import { EOfferHistoryTableColumn } from '../../utils/common';
import OfferHistoryTableCellActorType from '../tableCell/history/actorType';
import OfferHistoryTableCellEnteredAt from '../tableCell/history/enteredAt';
import OfferHistoryTableCellEnteredAtTime from '../tableCell/history/enteredAtTime';
import OfferHistoryTableCellEnteredBy from '../tableCell/history/enteredBy';
import OfferHistoryTableCellStatus from '../tableCell/history/status';
import OfferHistoryTableCellStatusComment from '../tableCell/history/statusComment';
import { useOfferHistoryTable } from '@features/offerHistory/table/hooks/useTableSettings';

interface OfferHistoryTableComponentProps {
  readonly offerStates: OfferState[];
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
}

const OfferHistoryTableComponent = (props: OfferHistoryTableComponentProps) => {
  const { offerStates, sort } = props;

  const rows = offerStates.map((offerState, index) => {
    return {
      [EOfferHistoryTableColumn.EnteredAt]: (
        <OfferHistoryTableCellAdapter
          index={index}
          component={OfferHistoryTableCellEnteredAt}
        />
      ),
      [EOfferHistoryTableColumn.EnteredAtTime]: (
        <OfferHistoryTableCellAdapter
          index={index}
          component={OfferHistoryTableCellEnteredAtTime}
        />
      ),
      [EOfferHistoryTableColumn.Status]: (
        <OfferHistoryTableCellStatusAdapter
          index={index}
          component={OfferHistoryTableCellStatus}
        />
      ),
      [EOfferHistoryTableColumn.EnteredBy]: (
        <OfferHistoryTableCellEnteredByAdapter
          index={index}
          component={OfferHistoryTableCellEnteredBy}
        />
      ),
      [EOfferHistoryTableColumn.StatusComment]: (
        <OfferHistoryTableCellAdapter
          index={index}
          component={OfferHistoryTableCellStatusComment}
        />
      ),
      [EOfferHistoryTableColumn.ActorType]: (
        <OfferHistoryTableCellActorTypeAdapter
          index={index}
          component={OfferHistoryTableCellActorType}
        />
      ),
      data: offerState,
    };
  });

  const { metadata } = useOfferHistoryTable();

  return (
    <DataTable<OfferState, EOfferHistoryTableColumn>
      width={'auto'}
      overflowX='inherit'
      metadata={metadata}
      rows={rows}
      sort={{ column: sort.column, direction: sort.direction }}
      loader={<DataTableLoader selector={offerHistoryTableIsLoadingSelector} />}
    />
  );
};

export default OfferHistoryTableComponent;
