import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable } from '../../../../../domain/model';
import { SportEventShort } from '../../../../../domain/model/event';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { EventListFilter } from '../types';
import { eventListFeatureEventsSelector, eventListNameSelector, eventListPastEventsSelector } from './selectors';

enum EEventListLoadedType {
  Feature = 'feature',
  Past = 'past',
}

const defaultPageSize = 15;
const defaultPastSort = 'scheduledDate,desc';
const defaultFeatureSort = 'scheduledDate,asc';

export const eventListFetch = createAsyncThunk<
  Pageable<SportEventShort> & {
    type: EEventListLoadedType;
    pageNumber: number;
    featureTotalCount: number;
    pastTotalCount: number;
  },
  { userId?: UUID; playgroundId?: UUID; teamId?: UUID; timestamp: string; refresh?: boolean },
  AppThunkAPIConfig
>(
  'event/list/fetch',
  async ({ userId, playgroundId, teamId, timestamp, refresh }, { getState, dispatch, rejectWithValue, signal }) => {
    try {
      if (refresh) {
        dispatch(eventListClearState());
      }

      const name = eventListNameSelector(getState());
      const eventsPast = eventListPastEventsSelector(getState());
      const eventsFeature = eventListFeatureEventsSelector(getState());

      let loadPast = false;
      let loadFeature = false;

      if (eventsPast.pageCount - 1 > eventsPast.pageNumber) {
        loadPast = true;
      } else {
        if (eventsFeature.pageCount - 1 > eventsFeature.pageNumber || eventsPast.totalCount === 0) {
          loadFeature = true;
        } else {
          loadPast = true;
        }
      }

      const loadingPast = async (pageSize: number = defaultPageSize) => {
        const { data: responsePast } = await Api.event.all({
          page: eventsPast.pageNumber + 1,
          pageSize,
          sort: defaultPastSort,
          scheduledDateTo: timestamp,
          name,
          userId,
          playgroundId,
          teamId,
          signal,
        });

        return {
          totalCount: responsePast.totalElements,
          pageCount: responsePast.totalPages,
          pageNumber: responsePast.number + 1,
          data: responsePast.content,
          type: EEventListLoadedType.Past,

          pastTotalCount: responsePast.totalElements,
          featureTotalCount: eventsFeature.totalCount,
        };
      };

      const loadingFeature = async (pastTotalCount: number) => {
        const { data: responseFeature } = await Api.event.all({
          page: eventsFeature.pageNumber + 1,
          pageSize: defaultPageSize,
          sort: defaultFeatureSort,
          scheduledDateFrom: timestamp,
          name,
          userId,
          playgroundId,
          teamId,
          signal,
        });

        return {
          totalCount: responseFeature.totalElements,
          pageCount: responseFeature.totalPages,
          pageNumber: responseFeature.number + 1,
          data: responseFeature.content,
          type: EEventListLoadedType.Feature,

          pastTotalCount,
          featureTotalCount: responseFeature.totalElements,
        };
      };

      if (loadPast) {
        return await loadingPast();
      }

      if (loadFeature) {
        const past = await loadingPast(0);
        const feature = await loadingFeature(past.totalCount);

        if (feature.totalCount === 0) {
          return await loadingPast();
        }

        return feature;
      }

      return rejectWithValue(null);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface EventListState {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly feature: Fetchable & Pageable<SportEventShort>;
  readonly past: Fetchable & Pageable<SportEventShort>;
  readonly name: Nullable<string>;
}

type Reducer<T = undefined> = CaseReducer<EventListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<EventListState> {
  eventListStartSession: Reducer<Nullable<UUID>>;
  eventListSetFilterName: Reducer<EventListFilter['name']>;
  eventListClearNeedRefreshWatcher: Reducer;
  eventListClearState: Reducer;
}

const slice = createSlice<EventListState, Reducers, 'event/list'>({
  name: 'event/list',
  initialState: {
    guid: null,
    needRefreshWatcher: 0,
    feature: {
      ...fetchableDefault,
      data: [],
      totalCount: 0,
      pageCount: 0,
      pageNumber: 0,
    },
    past: {
      ...fetchableDefault,
      data: [],
      totalCount: 0,
      pageCount: 0,
      pageNumber: 0,
    },
    name: null,
  },
  reducers: {
    eventListStartSession: (state, { payload }) => {
      if (state.guid !== payload) {
        state.feature = {
          ...fetchableDefault,
          data: [],
          totalCount: 0,
          pageCount: 0,
          pageNumber: 0,
        };
        state.past = {
          ...fetchableDefault,
          data: [],
          totalCount: 0,
          pageCount: 0,
          pageNumber: 0,
        };

        state.name = null;
      }
      state.needRefreshWatcher = 0;
      state.guid = payload;
    },
    eventListSetFilterName: (state, { payload }) => {
      state.name = payload;
      state.needRefreshWatcher++;
    },
    eventListClearNeedRefreshWatcher: state => {
      state.needRefreshWatcher = 0;
    },
    eventListClearState: state => {
      state.feature = {
        ...fetchableDefault,
        data: [],
        totalCount: 0,
        pageCount: 0,
        pageNumber: 0,
      };
      state.past = {
        ...fetchableDefault,
        data: [],
        totalCount: 0,
        pageCount: 0,
        pageNumber: 0,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(eventListFetch.pending, (state, { meta }) => {
        state.feature.isFetching = true;
        state.feature.isFetched = false;
        state.feature.isFailed = false;
        state.past.isFetching = true;
        state.past.isFetched = false;
        state.past.isFailed = false;
      })
      .addCase(eventListFetch.fulfilled, (state, { payload }) => {
        const { data, pageCount, pageNumber, type, pastTotalCount, featureTotalCount } = payload;
        state.feature.isFetching = false;
        state.feature.isFetched = true;
        state.feature.isFailed = false;

        state.past.isFetching = false;
        state.past.isFetched = true;
        state.past.isFailed = false;

        state.feature.totalCount = featureTotalCount;
        state.past.totalCount = pastTotalCount;

        if (type === EEventListLoadedType.Feature) {
          state.feature.pageNumber = pageNumber;
          state.feature.pageCount = pageCount;
          state.feature.data.push(...data);
        } else if (type === EEventListLoadedType.Past) {
          state.past.pageNumber = pageNumber;
          state.past.pageCount = pageCount;
          state.past.data.push(...data);
        }
      })
      .addCase(eventListFetch.rejected, state => {
        state.feature.isFetching = false;
        state.feature.isFetched = false;
        state.feature.isFailed = true;
        state.feature.data = [];
        state.feature.pageNumber = 0;
        state.feature.pageCount = 0;
        state.feature.totalCount = 0;

        state.past.isFetching = false;
        state.past.isFetched = false;
        state.past.isFailed = true;
        state.past.data = [];
        state.past.pageNumber = 0;
        state.past.pageCount = 0;
        state.past.totalCount = 0;
      });
  },
});

export const { eventListStartSession, eventListSetFilterName, eventListClearNeedRefreshWatcher, eventListClearState } =
  slice.actions;

export default slice.reducer;
