import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import { OfferHistoryTableCellStatusProps } from '../components/tableCell/history/status';
import { offerHistoryTableHistoryByIndexSelector } from '../store/selectors';
import { OfferHistoryTableCellAdapterProps } from './cell';

type OfferHistoryTableCellStatusAdapterProps = Omit<OfferHistoryTableCellAdapterProps, 'component'> & {
  readonly component: FC<OfferHistoryTableCellStatusProps>;
};

const OfferHistoryTableCellStatusAdapter = (props: OfferHistoryTableCellStatusAdapterProps) => {
  const { index, component: Component } = props;

  const history = useSelector(offerHistoryTableHistoryByIndexSelector(index));

  const { offerStatuses } = useSelector(nsiDataSelector);

  return useMemo(
    () =>
      history ? (
        <Component
          history={history}
          offerStatuses={offerStatuses}
        />
      ) : null,
    [Component, history, offerStatuses]
  );
};

export default OfferHistoryTableCellStatusAdapter;
