import { MultiSelectorPredicate, Nullable, Target, TargetRequest, UUID } from '@/domain';

interface ObjectLink {
  readonly id: UUID;
}

type TargetSelectorType =
  | Target['targetRoads']
  | Target['targetOrgUnits']
  | Target['targetLocalities']
  | Target['targetClientOrgs'];

const convertSelectorPredicateToSelectorPredicateRequest = (
  value: TargetSelectorType
): Nullable<MultiSelectorPredicate<ObjectLink>> => {
  return value ? { ...value, in: value.in?.map(item => ({ id: item.id })) ?? null } : null;
};

export const convertTargetToTargetRequest = (target: Target): TargetRequest => {
  return {
    ...target,
    targetLocalities: convertSelectorPredicateToSelectorPredicateRequest(target.targetLocalities),
    targetRoads: convertSelectorPredicateToSelectorPredicateRequest(target.targetRoads),
    targetOrgUnits: convertSelectorPredicateToSelectorPredicateRequest(target.targetOrgUnits),
    targetClientOrgs: convertSelectorPredicateToSelectorPredicateRequest(target.targetClientOrgs),
  };
};

export const isTargetEmpty = (target: Nullable<Target>): boolean => {
  if (!target) return true;
  for (const property in target) {
    if (property !== 'targetType' && !!target[property as keyof Target]) return false;
  }
  return true;
};
