import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import BookingServicePriceUnitsEditContainer from '../../features/dictionary/bookingServicePriceUnit/edit/container';

const ConfigurationPrivilegeBookingOfferPriceUnitsScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  return <BookingServicePriceUnitsEditContainer />;
};

export default ConfigurationPrivilegeBookingOfferPriceUnitsScreen;
