import { FC, useMemo, useState } from 'react';
import ErrorHandler from '../../../../data/network/errorHandler';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../data/store/types';
import { XLSXImportError, XLSXImportResult } from '../../../../domain/model';
import { ProductCategory } from '../../../../domain/model/productOffer';
import { Nullable, UUID } from '../../../../domain/model/types';
import useValidation from '../../../hooks/validation/useValidation';
import { pluralize } from '../../../utils/pluralize';
import { downloadXLSXImportErrors } from '../../../utils/xlsxImport';
import productServices from '../services';
import ProductUploadOffers from './component';
import { ProductOffersUploadObject, productOffersUploadValidation } from './utils';

type ProductUploadOffersContainerProps = {
  readonly partnerId: UUID;
  readonly onUpload: (count: number) => void;
  readonly onClose: () => void;
};

type XLSXUploadState = Fetchable & {
  readonly result: Nullable<XLSXImportResult>;
};

type SelectedCategoryState = {
  readonly category: Nullable<ProductCategory>;
  readonly categoryList: Nullable<ProductCategory[]>;
};

const ProductUploadOffersContainer: FC<ProductUploadOffersContainerProps> = props => {
  const { partnerId, onUpload, onClose } = props;

  const [selectedCategory, setSelectedCategory] = useState<SelectedCategoryState>({
    category: null,
    categoryList: null,
  });
  const [offersUpload, setOffersUpload] = useState<XLSXUploadState>({ ...fetchableDefault, result: null });
  const [localFile, setLocalFile] = useState<Nullable<{ file: File; error: Nullable<string> }>>(null);
  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);

  const downloadUrl = useMemo(
    () =>
      selectedCategory.category
        ? productServices.productOffer.offersDownloadUrl({
            partnerId,
            categoryId: selectedCategory.category.id,
          })
        : '',
    [partnerId, selectedCategory.category?.id]
  );

  const uploadObject = { file: localFile?.file ?? null, category: selectedCategory.category };

  const { validationResult, isValid, validate } = useValidation<ProductOffersUploadObject>({
    object: uploadObject,
    validateOnChange,
    rules: productOffersUploadValidation,
  });

  const onChangeCategory = (newCategory: Nullable<ProductCategory>, newCategoryList: Nullable<ProductCategory[]>) => {
    setSelectedCategory({ category: newCategory, categoryList: newCategoryList });
  };

  const onUploadFile = () => {
    const file = localFile?.file;
    const isValidObject = validate();

    if (!isValidObject) {
      setValidateOnChange(true);
    } else {
      if (file && selectedCategory.category && !localFile.error) {
        setOffersUpload({ ...fetchableFetching, result: null });
        productServices.productOffer
          .import({ partnerId, categoryId: selectedCategory.category.id, file })
          .then(response => {
            setOffersUpload({ ...fetchableFetched, result: response });
            onUpload(response.successCount);
          })
          .catch(e => {
            ErrorHandler.handleHttpError(e, e.response);
            setOffersUpload({ ...fetchableFailed, result: null });
          });
      }
    }
  };

  const onRemoveFile = () => {
    setOffersUpload({ ...fetchableDefault, result: null });
    setLocalFile(null);
  };

  const onSetFile = (file: File, error?: string) => {
    setOffersUpload({ ...fetchableDefault, result: null });
    setLocalFile({ file, error: error ?? null });
  };

  const onDownloadImportError = (errors: XLSXImportError[]) => {
    downloadXLSXImportErrors({ errors });
  };

  const getSuccessImportedCountText = (count: number): string =>
    `${count} ${pluralize(count, ['запись добавлена', 'записи добавлены', 'записей добавлено'])}`;

  return (
    <ProductUploadOffers
      category={selectedCategory.category}
      categoryList={selectedCategory.categoryList}
      uploadFile={localFile}
      offersDownloadUrl={downloadUrl}
      isUploading={offersUpload.isFetching}
      isUploaded={offersUpload.isFetched}
      isFailed={offersUpload.isFailed}
      uploadResult={offersUpload.result}
      isValid={isValid}
      validation={validationResult}
      getSuccessImportedCountText={getSuccessImportedCountText}
      onDownloadImportError={onDownloadImportError}
      onSetFile={onSetFile}
      onUploadFile={onUploadFile}
      onRemoveFile={onRemoveFile}
      onChangeCategory={onChangeCategory}
      onClose={onClose}
    />
  );
};

export default ProductUploadOffersContainer;
