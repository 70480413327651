import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { Pageable } from '@/domain/model';
import { TradeOfferActivation } from '@/domain/model/activation';
import { Nullable, UUID } from '@/domain/model/types';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { PaginationSize } from '../../../../types';
import tradeActivationServices from '../../services';
import { AllProps } from '../../services/activation';
import { ETradeActivationTableColumn } from '../../types';
import { TradeActivationsFilterValues } from '../../utils/filter';

const defaultSort = `${ETradeActivationTableColumn.CreatedAt},desc` as const;

interface TradeActivationSearchState {
  readonly sort: string;
  readonly partnerId: Nullable<UUID>;
  readonly pageSize: PaginationSize;
}

export interface TradeActivationListState {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly tradeActivations: Fetchable & Pageable<TradeOfferActivation>;
  readonly search: TradeActivationSearchState;
  readonly filter: TradeActivationsFilterValues;
}

type Reducer<T = undefined> = CaseReducer<TradeActivationListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<TradeActivationListState> {
  tradeActivationsStartSession: Reducer<{ guid: UUID }>;
  tradeActivationsSetSort: Reducer<{ sort: string }>;
  tradeActivationsSortReset: Reducer;
  tradeActivationsSetFilter: Reducer<{ filter: TradeActivationsFilterValues }>;
  tradeActivationsSetPage: Reducer<{ pageNumber: number }>;
  tradeActivationsSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  tradeActivationsNeedRefreshWatcherReset: Reducer;
  tradeActivationsNeedRefreshWatcherIncrement: Reducer;
}

export type TradeActivationsFetchProps = Omit<AllProps, 'signal'>;
export const tradeActivationsFetch = createAsyncThunk<
  Pageable<TradeOfferActivation>,
  TradeActivationsFetchProps,
  AppThunkAPIConfig
>('tradeActivations/list/fetch', async (payload, { rejectWithValue, signal }) => {
  try {
    return await tradeActivationServices.activation.all({ ...payload, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

const slice = createSlice<TradeActivationListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    guid: null,
    needRefreshWatcher: 0,
    tradeActivations: {
      ...fetchableDefault,
      data: [],
      totalCount: 0,
      pageCount: 0,
      pageNumber: 1,
    },
    search: {
      sort: defaultSort,
      pageSize: 10,
      partnerId: null,
    },
    filter: {},
  },
  reducers: {
    tradeActivationsStartSession: (state, { payload }) => {
      const { guid } = payload;
      if (guid !== state.guid) {
        state.guid = guid;

        state.tradeActivations.isFetching = false;
        state.tradeActivations.isFetched = false;
        state.tradeActivations.isFailed = false;

        state.tradeActivations.data = [];
        state.tradeActivations.totalCount = 0;
        state.tradeActivations.pageCount = 0;
        state.tradeActivations.pageNumber = 1;
        state.needRefreshWatcher = 0;
        state.search = {
          sort: defaultSort,
          pageSize: 10,
          partnerId: null,
        };
        state.filter = {};
      }
    },
    tradeActivationsSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.tradeActivations.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    tradeActivationsSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.needRefreshWatcher++;
    },
    tradeActivationsSetFilter: (state, { payload }) => {
      const { filter } = payload;
      state.filter = filter;
      state.tradeActivations.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    tradeActivationsSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.tradeActivations.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    tradeActivationsSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.tradeActivations.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    tradeActivationsNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
    tradeActivationsNeedRefreshWatcherIncrement: state => {
      state.needRefreshWatcher++;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(tradeActivationsFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        state.tradeActivations.isFetching = true;
        state.tradeActivations.isFetched = false;
        state.tradeActivations.isFailed = false;

        if (partnerId !== state.search.partnerId) {
          state.tradeActivations.data = [];
        }
        state.search.partnerId = partnerId;
      })
      .addCase(tradeActivationsFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.tradeActivations.isFetching = false;
        state.tradeActivations.isFetched = true;
        state.tradeActivations.isFailed = false;

        state.tradeActivations.data = data;
        state.tradeActivations.totalCount = totalCount;
        state.tradeActivations.pageCount = pageCount;
      })
      .addCase(tradeActivationsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tradeActivations.isFetching = false;
          state.tradeActivations.isFetched = false;
          state.tradeActivations.isFailed = true;
          state.tradeActivations.data = [];
        }
      });
  },
});

export const {
  tradeActivationsStartSession,
  tradeActivationsSetSort,
  tradeActivationsSortReset,
  tradeActivationsSetFilter,
  tradeActivationsSetPage,
  tradeActivationsSetPageSize,
  tradeActivationsNeedRefreshWatcherReset,
  tradeActivationsNeedRefreshWatcherIncrement,
} = slice.actions;

export default slice.reducer;
