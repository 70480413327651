import styled from '@emotion/styled';

interface WrapperProps {
  readonly width: number;
  readonly children?: any;
}

export const Wrapper = styled(({ width, ...others }: WrapperProps) => <div {...others} />)`
  width: 100%;
  min-height: 100%;

  display: grid;
  grid-template-rows: 1fr;
  align-items: center;
`;
