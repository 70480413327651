import { DataFilterQueryDslOperator } from '../../domain/model/filter';
import { Nullable } from '../../domain/model/types';

type ApiPageable = {
  /** @description begin from 1 */
  readonly page: number;
  readonly pageSize: number;
};

export type ApiResponseWithPageable<T> = {
  readonly content: T[];
  readonly totalElements: number;
  readonly totalPages: number;
  readonly number: number;
};

export type ApiRequestPageable = ApiPageable & {
  readonly sort?: Nullable<string | string[]>;
};

export type ApiResponsePageable = ApiPageable & {
  readonly pageCount: number;
  readonly totalCount: number;
};

export enum ApiRequestListDiscriminator {
  List = 'list',
  Count = 'count',
}

export type ApiResponseCounter = {
  readonly count: number;
};

export type ApiResponseMaBeCounters<
  D extends ApiRequestListDiscriminator,
  T
> = D extends ApiRequestListDiscriminator.Count ? [ApiResponseCounter] : T;

export type ApiSportResponseMaBeCounters<
  D extends ApiRequestListDiscriminator,
  T
> = D extends ApiRequestListDiscriminator.Count ? ApiResponseCounter : T;

export type ApiCancellable = {
  signal?: AbortSignal;
};

export type ApiQueryDslItem = {
  readonly field: string;
  readonly operator: DataFilterQueryDslOperator;
  readonly value: Nullable<string>[];
};

export type ApiQueryDsl = ApiQueryDslItem[];
