import { FilterMultiSelectorPredicate } from 'domain/model';
import { Address } from 'domain/model/address';
import { EFilterMultiSelectorValueType } from 'domain/model/enums';
import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterValueItem,
  EDataFilterType,
} from 'domain/model/filter';
import { EDateFormat } from 'domain/model/formats';
import { Nullable } from 'domain/model/types';
import moment from 'moment/moment';
import { AddressHelper } from 'presentation/utils/address';
import { EAnalyticsQueriesTableColumn } from './table/utils';

export enum EAnalyticsQueriesTableFilterItem {
  Query = 'query',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  Places = 'places',
}

export type AnalyticsQueriesTableFilterValues = {
  readonly [EAnalyticsQueriesTableFilterItem.Query]?: DataFilterValueItem<Nullable<string>>;
  readonly [EAnalyticsQueriesTableFilterItem.DateFrom]?: DataFilterValueItem<string>;
  readonly [EAnalyticsQueriesTableFilterItem.DateTo]?: DataFilterValueItem<string>;
  readonly [EAnalyticsQueriesTableFilterItem.Places]?: DataFilterValueItem<
    Nullable<FilterMultiSelectorPredicate<Address>>
  >;
};

export const analyticsQueriesTableFilterItems: Record<
  EAnalyticsQueriesTableColumn,
  EAnalyticsQueriesTableFilterItem[]
> = {
  [EAnalyticsQueriesTableColumn.Query]: [],
  [EAnalyticsQueriesTableColumn.Geo]: [],
  [EAnalyticsQueriesTableColumn.Count]: [],
};

export type AnalyticsQueriesTableFilterEditStrategy = DataFilterStrategyBase<EAnalyticsQueriesTableFilterItem>;

const getPlacesPreview = (places: Nullable<FilterMultiSelectorPredicate<Address>>) => {
  if (!places) return null;

  switch (places.select) {
    case EFilterMultiSelectorValueType.All:
      return 'Вся Россия';
    case EFilterMultiSelectorValueType.None:
      return 'Город не выбран';
    case EFilterMultiSelectorValueType.In:
      return `Город (${places.in?.length})` || null;
  }
};

export const getAnalyticsQueriesTableFilterStrategy = (
  filterItem: EAnalyticsQueriesTableFilterItem,
  values: AnalyticsQueriesTableFilterValues
): Nullable<AnalyticsQueriesTableFilterEditStrategy> => {
  try {
    switch (filterItem) {
      case EAnalyticsQueriesTableFilterItem.Query:
        return {
          type: EDataFilterType.String,
          key: EAnalyticsQueriesTableFilterItem.Query,
          label: 'Слово или словосочетание',
          preview: values[EAnalyticsQueriesTableFilterItem.Query]?.value || null,
          value: values[EAnalyticsQueriesTableFilterItem.Query]?.value || null,
        };
      case EAnalyticsQueriesTableFilterItem.DateFrom:
        return {
          type: EDataFilterType.DateFrom,
          key: EAnalyticsQueriesTableFilterItem.DateFrom,
          group: 'date',
          label: 'Период с',
          preview: values[EAnalyticsQueriesTableFilterItem.DateFrom]?.value
            ? `с ${moment(values[EAnalyticsQueriesTableFilterItem.DateFrom]?.value).format(EDateFormat.DisplayDefault)}`
            : null,
          value: values[EAnalyticsQueriesTableFilterItem.DateFrom]?.value ?? null,
        };
      case EAnalyticsQueriesTableFilterItem.DateTo:
        return {
          type: EDataFilterType.DateTo,
          key: EAnalyticsQueriesTableFilterItem.DateTo,
          group: 'date',
          label: 'до',
          preview: values[EAnalyticsQueriesTableFilterItem.DateTo]?.value
            ? `по ${moment(values[EAnalyticsQueriesTableFilterItem.DateTo]?.value).format(EDateFormat.DisplayDefault)}`
            : null,
          value: values[EAnalyticsQueriesTableFilterItem.DateTo]?.value ?? null,
        };
      case EAnalyticsQueriesTableFilterItem.Places:
        return {
          type: EDataFilterType.MultiSelectorPredicateAddress,
          key: EAnalyticsQueriesTableFilterItem.Places,
          label: 'Город',
          selectNone: false,
          value: values[EAnalyticsQueriesTableFilterItem.Places]?.value || null,
          preview: getPlacesPreview(values[EAnalyticsQueriesTableFilterItem.Places]?.value ?? null),
          previews:
            values[EAnalyticsQueriesTableFilterItem.Places]?.value?.in?.map(
              locality => new AddressHelper(locality).getLastLocalityShortPath() ?? locality.name
            ) ?? null,
          querydsl: {
            operator: DataFilterQueryDslOperator.In,
            valueAttribute: 'id',
          },
        };
    }
  } catch (e) {
    console.error(e);
    return null;
  }
};
