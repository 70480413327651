import React from 'react';
import { Image } from './controls';

interface DataTableCellImageProps {
  readonly src: string;
  readonly width?: string | number;
  readonly height?: string | number;
  readonly scaleType?: 'fit-center' | 'crop-center';
}

const DataTableCellImage = (props: DataTableCellImageProps) => {
  const { src, width, height, scaleType = 'fit-center' } = props;

  return (
    <Image
      src={src}
      width={width}
      height={height}
      scaleType={scaleType}
    />
  );
};

export default React.memo(DataTableCellImage);
