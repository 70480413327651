import { ReactNode, useMemo } from 'react';
import DataTableCellTextFormat from '../../../../components/common/table/cell/textFormat';
import { BookingOrder } from '../../../../../domain/model/order';

export interface BookingOrderTableCellCommonProps {
  readonly bookingOrder: BookingOrder;
  readonly isDisabled?: boolean;
  readonly isTextAccent?: boolean;
}

interface BookingOfferTableCellProps extends BookingOrderTableCellCommonProps {
  readonly value: ReactNode;
}

const BookingOrderTableCell = ({ value, isDisabled, isTextAccent }: BookingOfferTableCellProps) => {
  return useMemo(
    () => (
      <DataTableCellTextFormat
        disabled={isDisabled}
        bold={isTextAccent}
      >
        {value}
      </DataTableCellTextFormat>
    ),
    [isDisabled, isTextAccent, value]
  );
};

export default BookingOrderTableCell;
