import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { UUID } from '../../../../../../domain/model/types';

export interface ClientOrgManagementMenuState {
  readonly guid: UUID;
  readonly opened: {
    readonly [guid: string]: boolean;
  };
}

type Reducer<T> = CaseReducer<ClientOrgManagementMenuState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ClientOrgManagementMenuState> {
  clientOrgManagementMenuAddToOpened: Reducer<{ itemId: UUID }>;
}

const slice = createSlice<ClientOrgManagementMenuState, Reducers, 'clientOrg/management/menu'>({
  name: 'clientOrg/management/menu',
  initialState: {
    guid: uuidv4(),
    opened: {},
  },
  reducers: {
    clientOrgManagementMenuAddToOpened(state, { payload }) {
      const { itemId } = payload;
      state.opened[itemId] = !state.opened[itemId];
    },
  },
});

export const { clientOrgManagementMenuAddToOpened } = slice.actions;

export default slice.reducer;
