import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const LinkToObject = styled(Typography)`
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
` as typeof Typography;

export const Wrapper = styled.div`
  border-radius: ${p => p.theme.shape.borderRadius}px;

  padding: ${p => p.theme.spacing(1.5)};

  background-color: ${p => p.theme.palette.disabled};
`;
