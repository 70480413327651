import { CascadeItem, CascadeItemWithChildren } from './types';

export const transformListToTree = <T>(data: CascadeItemWithChildren<T>[]): CascadeItemWithChildren<T>[] => {
  return data.reduce<CascadeItemWithChildren<T>[]>((acc, value) => {
    if (!value.parentId) {
      acc.push(value);
    } else {
      const parentItem = data.find(r => r.id === value.parentId);
      if (parentItem) {
        if (Array.isArray(parentItem?.children)) {
          parentItem.children.push(value);
        } else {
          parentItem.children = [value];
        }
      }
    }

    return acc;
  }, []);
};

export function findParentAndPush<T>(id: string | null, acc: CascadeItem<T>[], source: CascadeItem<T>[]) {
  if (id) {
    const item = source.find(r => r.id === id);
    if (item) {
      acc.push(item);
      if (item.parentId) {
        findParentAndPush(item.parentId, acc, source);
      }
    }
  }
}

export function findInTree<T>(
  id: string | null,
  source: CascadeItemWithChildren<T>[]
): CascadeItemWithChildren<T> | null {
  if (id) {
    const item = source.find(r => r.id === id);
    if (item) {
      return item;
    } else {
      for (const sourceElement of source) {
        if (sourceElement.children) {
          const result = findInTree(id, sourceElement.children);
          if (result) {
            return result;
          }
        }
      }
    }
  }
  return null;
}
