import { Grid, Typography } from '@mui/material';
import { ECmsCollectionLinkObjectType, EOfferType } from '../../../../../../../domain/model/enums';
import Splitter from '../../../../../../components/common/splitter';
import CmsOfferMultipleCollectionComponent from '../../../../components/collection/offers/multiple';
import CmsPartnerMultipleCollectionComponent from '../../../../components/collection/partners/multiple';
import { CmsLinkedObjectCollectionType } from '../../../../types/collection';
import CmsContainerViewComponentPageSize from '../../attributes/pageSize';
import { ColumnWrapper, Content, Wrapper } from './controls';

interface CmsContainerDetailsStepCollectionContainerProps {
  readonly cmsCollection: CmsLinkedObjectCollectionType;
}

const CmsContainerDetailsStepCollection = (props: CmsContainerDetailsStepCollectionContainerProps) => {
  const { cmsCollection } = props;

  const { linkObjectType } = cmsCollection;

  return (
    <Wrapper>
      <ColumnWrapper>
        <Content>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Typography variant='h3'>Состав блока</Typography>
            </Grid>

            {linkObjectType === ECmsCollectionLinkObjectType.Partner && (
              <Grid item>
                <Splitter
                  variant='horizontal'
                  size={1}
                />
                <CmsContainerViewComponentPageSize
                  label='Количество партнёров'
                  cmsComponent={cmsCollection}
                />
              </Grid>
            )}

            {linkObjectType === ECmsCollectionLinkObjectType.CorpOffer && (
              <CmsOfferMultipleCollectionComponent
                offers={cmsCollection.linkedObject ?? []}
                offerType={EOfferType.Corp}
              />
            )}
            {linkObjectType === ECmsCollectionLinkObjectType.TradeOffer && (
              <CmsOfferMultipleCollectionComponent
                offers={cmsCollection.linkedObject ?? []}
                offerType={EOfferType.Trade}
              />
            )}
            {linkObjectType === ECmsCollectionLinkObjectType.Partner && (
              <CmsPartnerMultipleCollectionComponent partners={cmsCollection.linkedObject ?? []} />
            )}
          </Grid>
        </Content>
      </ColumnWrapper>
    </Wrapper>
  );
};

export default CmsContainerDetailsStepCollection;
