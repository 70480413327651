function stringToJSON(arg: string) {
  try {
    return JSON.parse(arg);
  } catch (e) {
    return null;
  }
}

type StorageItemData = {
  expires: string;
  data: string;
};

export function getStorageData<T, P extends boolean | undefined = undefined>(
  key: string,
  parse?: P
): (P extends true ? T : string) | null {
  try {
    const item = localStorage.getItem(key);
    if (item) {
      const value = stringToJSON(item) as StorageItemData | null;

      if (value) {
        const expiresDate = new Date(value.expires);

        if (expiresDate && Date.now() < expiresDate.getTime()) {
          return parse ? stringToJSON(value.data) : value;
        } else {
          localStorage.removeItem(key);
        }
      }
    }
  } catch (e) {
    return null;
  }
  return null;
}

const REG_DATE_TIME_ISO =
  /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;

/**
 * @name setStorageData
 * @description Функция записи в localStorage данных с временем жизни записи
 *  @param key - string - ключ
 *  @param value - T - значение
 *  @param expires - T - время жизни в ISO формате. date.toISOString()
 * */
export function setStorageData<T>(key: string, value: T, expires: string): boolean {
  try {
    if (REG_DATE_TIME_ISO.test(expires)) {
      localStorage.setItem(
        key,
        JSON.stringify({
          expires,
          data: JSON.stringify(value),
        })
      );
      return true;
    }

    throw new Error('Не верный формат expires, должен быть в ISO - 2022-11-11T11:20:48.152Z');
  } catch (e) {
    return false;
  }
}
