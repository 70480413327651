import { useState } from 'react';
import { BookingServiceCategory } from '../../../../../../domain/model/booking';
import { EBookingServiceCategoryStatus } from '../../../../../../domain/model/enums';
import { Nullable } from '../../../../../../domain/model/types';
import { DictionaryAddDialog } from '../../../../../components/common/dictionary/dialog/add';
import { MPFormInput } from '../../../../../theme/ui-kit/input';

interface BookingServiceCategoriesEditDialogAddAdapterProps {
  readonly isLoading: boolean;
  readonly onClose: () => void;
  readonly onAdd: (bookingServiceCategory: BookingServiceCategory) => void;
}

export const BookingServiceCategoriesEditDialogAddAdapter = (
  props: BookingServiceCategoriesEditDialogAddAdapterProps
) => {
  const { isLoading, onAdd, onClose } = props;

  const [error, setError] = useState<Nullable<string>>(null);

  const [value, setValue] = useState<BookingServiceCategory>({
    id: '',
    name: '',
    displayPrice: false,
    used: false,
    orderByDateTypes: [],
    status: EBookingServiceCategoryStatus.Enabled,
  });

  const onAddInternal = () => {
    if (!value.name) {
      setError('Введите название');
    } else {
      onAdd(value ?? '');
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setError(null);
    setValue(prev => ({ ...prev, name: event.target.value }));
  };

  return (
    <DictionaryAddDialog
      isLoading={isLoading}
      onClose={onClose}
      onSave={onAddInternal}
    >
      <MPFormInput
        disabled={isLoading}
        label='Название'
        value={value.name}
        error={error !== null}
        helperText={error}
        inputRef={input => input && input.focus()}
        onChange={onChange}
      />
    </DictionaryAddDialog>
  );
};
