import { ContentLimiter } from '../../../../../components/common/contentLimit';
import { ContentLimiterDialogLayout } from '../../../../../components/common/contentLimit/layouts';
import SectionTitle from '../../../../../components/common/sectionTitle';
import { EntityInfoPanel } from '../../../../../types';
import { OfferInfoPanelCreateType, OfferInfoPanelDetailsType } from '../../types';
import { Wrapper } from './controls';

type OfferInfoPanelItemProps = {
  readonly infoPanel: EntityInfoPanel<OfferInfoPanelDetailsType | OfferInfoPanelCreateType>;
};

const OfferInfoPanelItem = ({ infoPanel }: OfferInfoPanelItemProps) => {
  const { title, text } = infoPanel;

  if (text?.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <SectionTitle>{title}</SectionTitle>
      {text?.map((item, index) => (
        <ContentLimiter
          key={index}
          layout={ContentLimiterDialogLayout}
          layoutProps={{ title }}
        >
          {item}
        </ContentLimiter>
      ))}
    </Wrapper>
  );
};

export default OfferInfoPanelItem;
