import { Grid } from '@mui/material';
import React from 'react';
import ConfirmDialog from '../../../../../components/common/dialogs/confirm';
import ContentLoader from '../../../../../components/common/loader';
import { MPButton } from '../../../../../theme/ui-kit/button';
import { ClientOrg } from '@/domain/model/clientOrg';

interface ClientOrgActionsConfirmDialogProps {
  readonly clientOrg: ClientOrg;
  readonly title: string;
  readonly actionText: string;
  readonly text?: React.ReactNode | string;
  readonly children?: React.ReactNode;
  readonly actionDisabled?: boolean;
  readonly isFetching: boolean;
  readonly onAction: (clientOrg: ClientOrg) => void;
  readonly onClose: () => void;
}

const ClientOrgActionsConfirmDialog = <T extends object>(props: ClientOrgActionsConfirmDialogProps) => {
  const { clientOrg, title, text, actionText, isFetching, actionDisabled = false, children, onAction, onClose } = props;

  return (
    <ConfirmDialog
      open
      onClose={onClose}
      title={title}
      dialogButtons={
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              disabled={actionDisabled || isFetching}
              onClick={() => onAction(clientOrg)}
            >
              {actionText}
              {isFetching && <ContentLoader />}
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              disabled={isFetching}
              fullWidth={false}
              onClick={onClose}
            >
              Отменить
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        spacing={2}
        wrap='nowrap'
        direction='column'
      >
        {text && <Grid item>{text}</Grid>}
        {children && <Grid item>{children}</Grid>}
      </Grid>
    </ConfirmDialog>
  );
};

export default ClientOrgActionsConfirmDialog;
