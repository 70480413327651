import { useSelector } from 'react-redux';
import BookingOrderDetailsInfo from '../../components/detailsInfo';
import { bookingOrderEditOrderSelector } from '../store/selectors';
import FormItemAdapter from './formItem';

export const BookingOrderEditDetailsInfoAdapter = () => {
  const bookingOrder = useSelector(bookingOrderEditOrderSelector);

  if (!bookingOrder) {
    return null;
  }

  return (
    <FormItemAdapter
      gridSize={4}
      title='Сведения о заказе'
    >
      <BookingOrderDetailsInfo
        date={bookingOrder.createdAt}
        customer={bookingOrder.customer}
        comment={bookingOrder.customerComment}
      />
    </FormItemAdapter>
  );
};
