import { Grid, Switch, Typography } from '@mui/material';
import { NotificationOption, NotificationSetting } from '../../../../../domain/model/notification';
import { Nullable } from '../../../../../domain/model/types';
import SwitchControl from '../../../../components/common/switch';
import { Wrapper } from './controls';

interface UserMpDetailsNotificationsHeader {
  readonly notifications: NotificationOption[];
  readonly notificationSettings: NotificationSetting[];
  readonly onChange: Nullable<(option: NotificationOption, enabled: boolean) => void>;
}

const UserMpDetailsNotifications = (props: UserMpDetailsNotificationsHeader) => {
  const { notifications, notificationSettings, onChange } = props;

  const notificationsData = notifications.map(notification => {
    const enabled = notificationSettings.find(item => item.option.id === notification.id)?.enabled;

    return {
      option: notification,
      enabled,
    };
  });

  return (
    <Wrapper>
      <Grid
        container
        spacing={2}
        direction='column'
      >
        <Grid item>
          <Typography variant='h3'>Уведомления</Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={2}
          >
            {notificationsData.map(({ option, enabled }) => {
              const { id, name } = option;
              return (
                <Grid
                  key={id}
                  item
                  xs={12}
                >
                  <SwitchControl
                    control={<Switch color='primary' />}
                    label={<Typography>{name}</Typography>}
                    name={id}
                    labelPlacement='start'
                    checked={enabled}
                    disabled={!onChange}
                    onChange={onChange ? (event, checked) => onChange(option, checked) : () => null}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default UserMpDetailsNotifications;
