import { Grid } from '@mui/material';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../data/store/store';
import { CorpOffer } from '../../../../domain/model/corpOffer';
import TablePagination from '../../../components/common/table/pagination';
import { MPButton } from '../../../theme/ui-kit/button';
import { PaginationSize } from '../../../types';
import { FooterActionsWrapper } from './controls';
import { corpOffersSearchGet, corpOffersSearchSelectedSelector } from './store/selectors';
import { corpOffersSearchSetPage, corpOffersSearchSetPageSize } from './store/slice';

interface CorpOfferSearchFooterContainerProps {
  readonly maxCount?: number | false;
  readonly onSelect: (offers: CorpOffer[]) => void;
}

const CorpOfferSearchFooterContainer = forwardRef((props: CorpOfferSearchFooterContainerProps, ref: any) => {
  const { maxCount, onSelect } = props;

  const dispatch = useAppDispatch();

  const { totalCount, pageCount, pageNumber, search, selected: offers } = useSelector(corpOffersSearchGet);

  const selectedCorpOffers = useSelector(corpOffersSearchSelectedSelector);

  const onChangePage = (num: number) => {
    dispatch(corpOffersSearchSetPage({ pageNumber: num }));
  };

  const onChangePageSize = (size: PaginationSize) => {
    dispatch(corpOffersSearchSetPageSize({ pageSize: size }));
  };

  let buttonText = `Выбрать ${selectedCorpOffers.length}`;

  if (maxCount) {
    buttonText += ` из ${maxCount}`;
  }

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='корпоративных предложений'
        onChangePageSize={onChangePageSize}
        onChangePage={onChangePage}
      />
      <FooterActionsWrapper>
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <MPButton
              disabled={selectedCorpOffers.length === 0}
              onClick={() => onSelect(offers)}
            >
              {buttonText}
            </MPButton>
          </Grid>
        </Grid>
      </FooterActionsWrapper>
    </div>
  );
});

export default CorpOfferSearchFooterContainer;
