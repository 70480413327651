import React, { FCC, ReactNode, useMemo } from 'react';
import SectionTitle from '../../common/sectionTitle';
import { ContentWrapper, Wrapper } from './controls';

export interface FieldsGroupProps {
  readonly label?: ReactNode;
  readonly gap?: number;
  readonly direction?: 'row' | 'column';
}

export const FieldsGroup: FCC<FieldsGroupProps> = props => {
  const { label, gap = 1.5, direction = 'column', children } = props;

  const validChildren = useMemo(
    () => React.Children.map(children, child => (React.isValidElement(child) || !!child ? child : null)),
    [children]
  );

  if (!validChildren?.length) {
    return null;
  }

  return (
    <Wrapper>
      {label && (
        <SectionTitle
          color={'textPrimary'}
          gutterBottom={false}
        >
          {label}
        </SectionTitle>
      )}
      <ContentWrapper
        gap={gap}
        direction={direction}
      >
        {validChildren}
      </ContentWrapper>
    </Wrapper>
  );
};
