import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { SportOptionTyped } from '../../../../../../../domain/model';
import { EPartnerOwnershipType } from '../../../../../../../domain/model/enums';
import { MPMenuItem } from '../../../../../../theme/ui-kit/menu/item';
import { MPSelect } from '../../../../../../theme/ui-kit/select';

interface PartnerApplicationEditCommonGeneralProps {
  readonly ownerShipTypes: SportOptionTyped<EPartnerOwnershipType>[];
  readonly onChangeOrgType: (value: EPartnerOwnershipType) => void;
}

const PartnerApplicationEditCommonGeneral = (props: PartnerApplicationEditCommonGeneralProps) => {
  const { ownerShipTypes, onChangeOrgType } = props;

  return (
    <Grid
      container
      spacing={2}
      direction='column'
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          variant='h3'
          gutterBottom
        >
          Организационно-правовая форма
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <MPSelect<EPartnerOwnershipType>
          label='Организационно-правовая форма'
          value=''
          onChange={(value: EPartnerOwnershipType) => onChangeOrgType(value)}
        >
          {ownerShipTypes.map(item => (
            <MPMenuItem
              key={item.id}
              value={item.id}
            >
              <Typography variant='body1'>{item.name}</Typography>
            </MPMenuItem>
          ))}
        </MPSelect>
      </Grid>
    </Grid>
  );
};

export default PartnerApplicationEditCommonGeneral;
