import Axios from 'axios';
import Api from '../../../../data/api';
import { ApiCancellable, ApiQueryDsl, ApiRequestListDiscriminator, ApiRequestPageable } from '@/data/api/types';
import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '@/data/api/utils';
import { OfferCategory, Pageable } from '@/domain/model';
import { BookingOffer, BookingOfferShort } from '@/domain/model/booking';
import { EBookingOfferActionDiscriminator, EOfferStatus } from '@/domain/model/enums';
import { DataFilterQueryDslOperator } from '@/domain/model/filter';
import { Nullable, UUID } from '@/domain/model/types';
import { PaginationSize } from '../../../types';
import { EOfferTableTab, OfferCounterByStatus } from '../../general/offer/types';
import { getStatesFilterForOfferTableTab, OfferTableTabsCounter } from '../../general/offer/utils/table';
import { convertBookingOfferViewToCreateRequest, convertBookingOfferViewToUpdateRequest } from '../create/utils';
import { BookingOfferTableFilterValues, EBookingOfferTableFilterItem } from '../filterUtils';
import { BookingOfferView } from '../types';
import { isBookingOfferDraftSavable } from '../utils/common';
import bookingServiceService from './service';

type BuildListQueryParamsReturn<D extends ApiRequestListDiscriminator> = ApiCancellable &
  ApiRequestPageable & {
    readonly categories?: Nullable<UUID[]>;
    readonly query?: Nullable<string>;
    readonly addressObjectId?: Nullable<UUID>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

export type BookingAllProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string>;
    readonly statuses: EOfferStatus[];
    readonly partnerId: Nullable<UUID>;
    readonly categories: Nullable<UUID[]>;
  };
  readonly filter: BookingOfferTableFilterValues;
  readonly pageNumber: number;
};

type AloneIDProps = ApiCancellable & {
  readonly id: UUID;
};

type RejectProps = ApiCancellable & {
  readonly id: UUID;
  readonly comment: string;
};

type PauseProps = ApiCancellable & {
  readonly id: UUID;
  readonly comment?: Nullable<string>;
};

type AssignModeratorProps = ApiCancellable & {
  id: UUID;
  userId: UUID;
};

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type saveProps = BookingOfferView;

type NewVersionProps = {
  id: UUID;
  offer: BookingOfferView;
};

export type CountProps = Omit<BookingAllProps, 'pageNumber'> & {
  readonly viewed?: boolean;
};

type CommandProps = {
  readonly id: UUID;
};

type CategoriesProps = ApiCancellable & {
  readonly statuses?: Nullable<EOfferStatus[]>;
  readonly partnerId?: Nullable<UUID>;
  readonly query?: Nullable<string>;
  readonly querydsl?: Nullable<ApiQueryDsl>;
  readonly onlyLeafCategories?: boolean;
};

type UnwatchedProps = Omit<BookingAllProps, 'pageNumber'>;

type CountsByStatusesProps = Omit<BookingAllProps, 'pageNumber'> & {
  readonly viewed?: boolean;
};

type CountsByTabsProps = CountsByStatusesProps & {
  readonly tabs: EOfferTableTab[];
};

export type BookingOfferService = {
  readonly one: (props: OneProps) => Promise<BookingOffer>;
  readonly save: (props: saveProps) => Promise<{ offer: BookingOffer; errors: string[] }>;
  readonly all: (props: BookingAllProps) => Promise<Pageable<BookingOfferShort>>;
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: BookingAllProps
  ) => BuildListQueryParamsReturn<D>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly countsByStatuses: (
    props: CountsByStatusesProps
  ) => Promise<{ counts: OfferCounterByStatus; errors: Array<string> }>;
  readonly countsByTabs: (
    props: CountsByTabsProps
  ) => Promise<{ counts: OfferTableTabsCounter; errors: Array<string> }>;
  readonly delete: (props: CommandProps) => Promise<void>;
  readonly duplicate: (props: AloneIDProps) => Promise<BookingOffer>;
  readonly approve: (props: AloneIDProps) => Promise<BookingOffer>;
  readonly toModeration: (props: AloneIDProps) => Promise<BookingOffer>;
  readonly assignModerator: (props: AssignModeratorProps) => Promise<BookingOffer>;
  readonly retrieve: (props: AloneIDProps) => Promise<BookingOffer>;
  readonly archive: (props: AloneIDProps) => Promise<BookingOffer>;
  readonly resume: (props: AloneIDProps) => Promise<BookingOffer>;
  readonly unPublish: (props: AloneIDProps) => Promise<BookingOffer>;
  readonly reject: (props: RejectProps) => Promise<BookingOffer>;
  readonly pause: (props: PauseProps) => Promise<BookingOffer>;
  readonly unwatched: (props: UnwatchedProps) => Promise<BookingOfferShort[]>;
  readonly categories: (props: CategoriesProps) => Promise<OfferCategory[]>;
  readonly onSaveNewVersion: (props: NewVersionProps) => Promise<{ offer: BookingOffer; errors: string[] }>;
};

const service: BookingOfferService = {
  buildListQueryParams: props => {
    const { search, filter, pageNumber, signal } = props;
    const { pageSize, sort, statuses, categories, partnerId } = search;

    const querydsl: ApiQueryDsl = [];
    const query = filter[EBookingOfferTableFilterItem.Query]?.value;
    const addressObjectId = filter[EBookingOfferTableFilterItem.Address]?.value?.id;
    const filterDsl = getQueryDslByDataFilterValues(filter);

    if (filterDsl) {
      querydsl.push(...filterDsl);
    }

    if (partnerId) {
      querydsl.push({
        value: [partnerId],
        field: 'partner.id',
        operator: DataFilterQueryDslOperator.In,
      });
    }

    if (statuses.length > 0) {
      querydsl.push({
        value: statuses,
        field: 'status',
        operator: DataFilterQueryDslOperator.In,
      });
    }

    return {
      query,
      sort,
      signal,
      pageSize,
      categories,
      addressObjectId,
      page: pageNumber,
      querydsl,
    };
  },
  one: async ({ id, signal }) => {
    return (await Api.booking.offer.one({ id, signal })).data;
  },
  save: async offerView => {
    let offerId: UUID;

    if (offerView.id) {
      offerId = offerView.id;
    } else {
      // создаем без услуг
      const createdBookingOffer = (
        await Api.booking.offer.createDraft({ data: convertBookingOfferViewToCreateRequest(offerView) })
      ).data;
      offerId = createdBookingOffer.id;
    }

    // сохраняем услуги
    const { services, errors } = await bookingServiceService.saveOfferServices({
      offerId,
      services: offerView.services,
    });

    // update offer
    const offer = isBookingOfferDraftSavable(offerView.status)
      ? (
          await Api.booking.offer.updateDraft({
            id: offerId,
            data: convertBookingOfferViewToUpdateRequest({ ...offerView, services }),
          })
        ).data
      : (
          await Api.booking.offer.update({
            id: offerId,
            data: convertBookingOfferViewToUpdateRequest({ ...offerView, services }),
          })
        ).data;

    return { offer, errors };
  },
  all: async props => {
    const response = await Api.booking.offer.all(service.buildListQueryParams(props));
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);

    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
  count: async ({ viewed, ...props }) => {
    const { data: response } = await Api.booking.offer.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      discriminator: ApiRequestListDiscriminator.Count,
      viewed,
    });

    return response[0].count;
  },
  countsByStatuses: async ({ viewed, signal, ...props }) => {
    const {
      search: { statuses },
    } = props;

    const errors: string[] = [];
    const counts: OfferCounterByStatus = {};

    const requests = statuses.map(status => {
      const params = {
        ...props,
        search: { ...props.search, statuses: [status] },
      };
      return service.count({ ...params, viewed, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], status: EOfferStatus) => {
      if (response.status === 'fulfilled') {
        counts[status] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество корпоративных предложений '${status}': ${response.reason}`);
        }
      }
    };
    statuses.forEach((status, index) => parseResponse(responses[index], status));

    return { counts, errors };
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: OfferTableTabsCounter = {};

    const requests = tabs.map(tab => {
      const statuses = getStatesFilterForOfferTableTab(tab, []);
      const params = {
        ...props,
        search: { ...props.search, statuses },
      };
      return service.countsByStatuses({ ...params, signal });
    });
    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], tab: EOfferTableTab) => {
      if (response.status === 'fulfilled') {
        const statuses = getStatesFilterForOfferTableTab(tab, []);
        counts[tab] = statuses.reduce<number>((prev, current) => prev + (response.value.counts?.[current] ?? 0), 0);
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество объектов '${tab}': ${response.reason}`);
        }
      }
    };
    tabs.forEach((tab, index) => parseResponse(responses[index], tab));

    return { counts, errors };
  },
  unwatched: async props => {
    const { data: bookingOffers } = await Api.booking.offer.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      pageSize: 99999,
      viewed: false,
    });
    return bookingOffers;
  },
  delete: async ({ id }) => {
    await Api.booking.offer.delete({ id });
  },
  duplicate: async ({ id }) => {
    const { data } = await Api.booking.offer.duplicate({
      id,
    });
    return data;
  },
  approve: async ({ id }) => {
    const { data } = await Api.booking.offer.action({
      id,
      discriminator: EBookingOfferActionDiscriminator.OfferApproveCommand,
    });
    return data;
  },
  assignModerator: async ({ id, userId }) => {
    const { data } = await Api.booking.offer.action({
      id,
      user: {
        id: userId,
      },
      discriminator: EBookingOfferActionDiscriminator.OfferAssignModeratorCommand,
    });

    return data;
  },
  toModeration: async ({ id }) => {
    const { data } = await Api.booking.offer.action({
      id,
      discriminator: EBookingOfferActionDiscriminator.OfferToModerationCommand,
    });

    return data;
  },
  retrieve: async ({ id }) => {
    const { data } = await Api.booking.offer.action({
      id,
      discriminator: EBookingOfferActionDiscriminator.OfferBackFromArchiveCommand,
    });

    return data;
  },
  archive: async ({ id }) => {
    const { data } = await Api.booking.offer.action({
      id,
      discriminator: EBookingOfferActionDiscriminator.OfferArchiveCommand,
    });
    return data;
  },
  resume: async ({ id }) => {
    const { data } = await Api.booking.offer.action({
      id,
      discriminator: EBookingOfferActionDiscriminator.OfferRenewCommand,
    });
    return data;
  },
  unPublish: async ({ id }) => {
    const { data } = await Api.booking.offer.action({
      id,
      discriminator: EBookingOfferActionDiscriminator.OfferBackToDraftCommand,
    });
    return data;
  },
  onSaveNewVersion: async props => {
    const { id, offer } = props;

    // сохраняем услуги
    const { services, errors } = await bookingServiceService.saveOfferServices({
      offerId: id,
      services: offer.services,
    });

    const { data } = await Api.booking.offer.action({
      ...convertBookingOfferViewToUpdateRequest({ ...offer, services }),
      id,
      discriminator: EBookingOfferActionDiscriminator.NewVersionBookingOfferCommand,
    });

    return { offer: data, errors };
  },
  reject: async ({ id, comment }) => {
    const { data } = await Api.booking.offer.action({
      id,
      discriminator: EBookingOfferActionDiscriminator.OfferRejectCommand,
      comment,
    });
    return data;
  },
  pause: async ({ id, comment }) => {
    const { data } = await Api.booking.offer.action({
      id,
      discriminator: EBookingOfferActionDiscriminator.OfferPauseCommand,
      comment,
    });
    return data;
  },
  categories: async props => {
    const { data } = await Api.category.bookingOffer.all(props);
    return data;
  },
};

export default service;
