import { EAdCampaignUrlParam } from '@features/adCampaign/entry';
import { AdCampaignLifeCycleFactory } from '@features/adCampaign/lifecycle/lifecycle';
import { AdCampaignDetailsStepType, EAdCampaignStep } from '@features/adCampaign/types';
import { viewHideMainMenu } from '@features/main/container/store/slice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import ForbiddenComponent from '../../components/common/forbidden';
import AdCampaignDetailsContainer from '../../features/adCampaign/details/container';
import useBreadcrumbsActions from '../../features/general/breadcrumbs/useBreadcrumbsActions';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const AdCampaignDetailsScreen = () => {
  const dispatch = useDispatch();
  const { putBreadcrumbs } = useBreadcrumbsActions();
  const location = useLocation();
  const {
    accessMatrix: { adCampaigns, isPartnerUser },
    userSpecific,
  } = useCurrentUser();

  const { id } = useParams<{ id: UUID }>();
  const searchParams = new URLSearchParams(location.search);
  const step: AdCampaignDetailsStepType =
    (searchParams.get(EAdCampaignUrlParam.Step) as AdCampaignDetailsStepType) ?? EAdCampaignStep.General;

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  useEffect(() => {
    putBreadcrumbs([{ label: 'Рекламный кабинет' }]);
  }, []);

  if (!adCampaigns?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <AdCampaignDetailsContainer
      id={id}
      step={step}
      lifecycle={AdCampaignLifeCycleFactory().create({ isPartnerUser, userId: userSpecific.id })}
      access={adCampaigns}
    />
  );
};

export default AdCampaignDetailsScreen;
