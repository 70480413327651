import { Nullable } from '../../../domain/model/types';
import { EPanelActionPosition } from '../../types';
import { CustomerAction, CustomerActions, ECustomerActionType, GetCustomerActionsByPermissions } from './types';

export const getCustomerActionName = (type: ECustomerActionType): string => {
  switch (type) {
    case ECustomerActionType.Activate:
      return 'Включить';
    case ECustomerActionType.Deactivate:
      return 'Отключить';
  }
};

// получение разрешенных действий с покупателем с учетом разрешений
export const getCustomerActionsConfigByPermissions = (
  props: GetCustomerActionsByPermissions
): CustomerActions<ECustomerActionType> => {
  const { canActivate, canDeactivate } = props;

  const actions: CustomerActions<ECustomerActionType> = [];

  let onActivate: Nullable<CustomerAction<ECustomerActionType>> = null;
  let onDeactivate: Nullable<CustomerAction<ECustomerActionType>> = null;

  if (canActivate) {
    onActivate = {
      type: ECustomerActionType.Activate,
      disabled: false,
      label: getCustomerActionName(ECustomerActionType.Activate),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onActivate);
  }

  if (canDeactivate) {
    onDeactivate = {
      type: ECustomerActionType.Deactivate,
      disabled: false,
      label: getCustomerActionName(ECustomerActionType.Deactivate),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onDeactivate);
  }

  return actions;
};
