import { useAuthentication } from '@mp-npm/mp-auth-client';
import axios from 'axios';
import { FCC, Fragment, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENetworkErrorCode } from '../../../../data/network/types';
import { EUserRole } from '../../../../domain/model/enums';
import ContentLoader from '../../../components/common/loader';
import { isSportUser } from '../../user/securityUtils';
import SignAgreementsDialog from '../components/signAgreementsDialog';
import {
  agreementsListIsFetchingToSignSelector,
  agreementsListRefreshWatcherSelector,
  agreementsListStatusSelector,
  agreementsListToSignSelector,
  agreementsSignIsFetchingToSignSelector,
} from '../store/selectors';
import { userAgreementsFetch, userAgreementsSign } from '../store/slice';
import { BackdropWrapper } from './controls';

const LegalAgreementContainer: FCC = ({ children }) => {
  const { token, logOut } = useAuthentication();
  const dispatch = useDispatch();

  const isAgreementsListFetching = useSelector(agreementsListIsFetchingToSignSelector);
  const watcher = useSelector(agreementsListRefreshWatcherSelector);
  const isAgreementsListSigning = useSelector(agreementsSignIsFetchingToSignSelector);
  const agreementsStatus = useSelector(agreementsListStatusSelector);
  const agreementsToSign = useSelector(agreementsListToSignSelector);
  const open = !!agreementsToSign.length;

  useLayoutEffect(() => {
    if (!token) {
      return;
    }

    const interceptor = axios.interceptors.response.use(undefined, error => {
      if (error?.response?.status === 403 && error?.response?.data?.code === ENetworkErrorCode.MissingUserAgreement) {
        const isSportUserInternal = isSportUser((token?.realm_access?.roles as EUserRole[]) ?? []);
        dispatch(userAgreementsFetch(isSportUserInternal));
      }

      return Promise.reject(error);
    });

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [token, dispatch, watcher]);

  const onAccept = async () => {
    if (!token) {
      return;
    }

    const isSportUserInternal = isSportUser((token?.realm_access?.roles as EUserRole[]) ?? []);

    const docLinks = agreementsToSign.map(a => ({
      id: a.doc.id,
    }));

    try {
      await dispatch(userAgreementsSign({ isSportUser: isSportUserInternal, data: { docs: docLinks } }));
    } catch {
      console.error('User agreements sign is failed');
    }
  };

  const onReject = () => {
    logOut();
  };

  return (
    <Fragment key={watcher.toString()}>
      {children}
      {isAgreementsListFetching && (
        <BackdropWrapper>
          <ContentLoader />
        </BackdropWrapper>
      )}
      {open && (
        <SignAgreementsDialog
          agreements={agreementsToSign}
          agreementsStatus={agreementsStatus}
          open
          isAccepting={isAgreementsListSigning}
          onAccept={onAccept}
          onReject={onReject}
        />
      )}
    </Fragment>
  );
};

export default LegalAgreementContainer;
