import { ManualAddress } from './types';

export const emptyFormData: ManualAddress = {
  region: null,
  admRegion: null,
  settlement: null,
  street: null,
  postalCode: null,
  position: { lat: null, lon: null },
  buildType: null,
  objectType: null,
  buildNumber: null,
  objectNumber: null,
};
