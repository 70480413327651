import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  max-width: 100%;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const RemoveIcon = styled(CloseIcon)`
  cursor: pointer;
`;
