import styled from '@emotion/styled';
import { viewConfig } from '../../../theme/viewConfig';

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;

  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  padding: ${p => p.theme.spacing(3)} 0;
`;

export const ColumnWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};
`;

export const FormHelperText = styled.div`
  padding: ${p => p.theme.spacing()};
  margin: 0;

  background-color: ${p => p.theme.palette.error.alpha90};

  .MuiSvgIcon-root {
    cursor: pointer;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  padding-left: ${viewConfig.stepper.width};
`;

export const TitleWrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  padding-bottom: ${p => p.theme.spacing(1.5)};

  border-bottom: 1px solid ${p => p.theme.palette.disabled.background};
`;
