import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import PartnerRegistrationContainer from '../../features/partner/registration/container';

const PartnerRegistrationScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  return <PartnerRegistrationContainer />;
};

export default PartnerRegistrationScreen;
