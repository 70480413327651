import { EUserRole } from '../../domain/model/enums';
import { SupportInfoSportManualPaths } from '../../system/appConfigurator';

export const getUserRoleName = (roles: EUserRole[]): string => {
  const hasRole = (r: EUserRole) => roles.includes(r);
  const hasOneOfRole = (...r: EUserRole[]) => r.some(sr => roles.includes(sr));

  if (hasOneOfRole(EUserRole.AdminMp, EUserRole.AdminMpReadOnly)) return 'Администратор ЭО';
  if (hasRole(EUserRole.AdminMpCorp)) return 'Администратор ЦСР';
  if (hasOneOfRole(EUserRole.AdminPartner, EUserRole.ManagerPartner)) return 'Партнёр';
  if (hasRole(EUserRole.SystemAdmin)) return 'Главный администратор';
  if (hasRole(EUserRole.EventAdmin)) return 'Администратор мероприятий';
  if (hasRole(EUserRole.ComplaintAdmin)) return 'Администратор жалоб';

  return 'Пользователь';
};

export const getUserRoleManualPath = (roles: EUserRole[], sportManualPaths: SupportInfoSportManualPaths): string => {
  const hasRole = (r: EUserRole) => roles.includes(r);
  const hasOneOfRole = (...r: EUserRole[]) => r.some(sr => roles.includes(sr));

  if (hasOneOfRole(EUserRole.AdminMp, EUserRole.AdminMpReadOnly)) return '';
  if (hasRole(EUserRole.AdminMpCorp)) return '';
  if (hasOneOfRole(EUserRole.AdminPartner, EUserRole.ManagerPartner)) return '';

  if (hasRole(EUserRole.SystemAdmin)) return sportManualPaths.systemAdmin;
  if (hasRole(EUserRole.EventAdmin)) return sportManualPaths.eventAdmin;
  if (hasRole(EUserRole.ComplaintAdmin)) return sportManualPaths.complaintAdmin;

  return '';
};

export const getUserRoleShortName = (role: EUserRole): string => {
  switch (role) {
    case EUserRole.SystemAdmin:
      return 'Системный администратор';
    case EUserRole.EventAdmin:
      return 'Администратор мероприятий';
    case EUserRole.ComplaintAdmin:
      return 'Администратор жалоб';
    case EUserRole.CorpUser:
      return 'Корпоративный пользователь';
    case EUserRole.User:
      return 'Пользователь';
    case EUserRole.Customer:
      return 'Покупатель';
    case EUserRole.DefaultMp:
      return 'Пользователь маркетплейс';
    case EUserRole.AdminMp:
      return 'ЦС';
    case EUserRole.AdminMpReadOnly:
      return 'ЦС для чтения';
    case EUserRole.AdminMpCorp:
      return 'ЦСР';
    case EUserRole.AdminPartner:
      return 'Админ партнёра';
    case EUserRole.ManagerPartner:
      return 'Менеджер партнёра';
    default:
      return 'Неизвестная роль';
  }
};

export const getUserCheckedRoles = (roles: EUserRole[], checkedRoles: EUserRole[]): EUserRole[] => {
  return checkedRoles.filter(role => roles.includes(role));
};
