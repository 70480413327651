export enum EOfferTableNotificationType {
  Saved = 'saved',
  Published = 'published',
  Approved = 'approved',
  Resumed = 'resumed',
  Rejected = 'rejected',
}

export enum EOfferInfoPanelType {
  TradeRestrictAccess = 'tradeRestrictAccess',
  CorpRestrictAccess = 'corpRestrictAccess',
  BookingRestrictAccess = 'bookingRestrictAccess',
  PartnerInactive = 'partnerInactive',
  Paused = 'paused',
  Rejected = 'rejected',
}
