import { Divider, Grid, ListItemAvatar, ListItemText } from '@mui/material';
import moment from 'moment-timezone';
import React from 'react';
import { BonusTransaction } from '../../../../../../domain/model';
import { Nullable } from '../../../../../../domain/model/types';
import { EventAvatar, OptionAvatar } from '../../../../../components/common/avatars';
import InfiniteList from '../../../../../theme/ui-kit/list/infinite';
import { formatDateHuman } from '../../../../../utils/dates';
import { AvatarWrapper, BonusCount, ListItem, OperationName, Typography, Wrapper } from './controls';

interface BonusTransactionListItemProps {
  readonly bonusTransaction: BonusTransaction;
  readonly onBonusTransactionClick: (BonusTransaction: BonusTransaction) => void;
}

const BonusTransactionListItem = ({ bonusTransaction, onBonusTransactionClick }: BonusTransactionListItemProps) => {
  return (
    <>
      <ListItem
        alignItems='center'
        onClick={() => onBonusTransactionClick(bonusTransaction)}
      >
        <ListItemAvatar>
          <AvatarWrapper>
            {bonusTransaction.event && (
              <EventAvatar
                event={bonusTransaction.event}
                size='small'
              />
            )}
            {bonusTransaction.productOrder && (
              <OptionAvatar
                option={{
                  id: bonusTransaction.productOrder.product.id,
                  image: bonusTransaction.productOrder.product.photo,
                  name: bonusTransaction.productOrder.product.name,
                }}
              />
            )}
          </AvatarWrapper>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Grid
              container
              direction='row'
              spacing={1}
              wrap={'nowrap'}
            >
              <Grid
                item
                xs={10}
              >
                <Typography
                  color='textSecondary'
                  variant='body2'
                >
                  {formatDateHuman(moment.utc(bonusTransaction.date).local())}
                </Typography>
                <OperationName>
                  {bonusTransaction.cancelledDate ? 'Отмена зачисления' : 'Начисление баллов'}
                </OperationName>
                {bonusTransaction.event && <Typography>{bonusTransaction.event.name}</Typography>}
                {bonusTransaction.productOrder && (
                  <>
                    <Typography>{`Номер заказа: ${bonusTransaction.productOrder.number}`}</Typography>
                    <Typography color='textSecondary'>{bonusTransaction.productOrder.product?.name}</Typography>
                  </>
                )}
              </Grid>
              {!bonusTransaction.cancelledDate && (
                <Grid
                  item
                  xs={2}
                >
                  <BonusCount label={`+${bonusTransaction.count}`} />
                </Grid>
              )}
            </Grid>
          }
        />
      </ListItem>
      <Divider variant='inset' />
    </>
  );
};

interface SportsmanTabBonusesProps {
  readonly bonusTransactions: BonusTransaction[];
  readonly totalCount: number;
  readonly isFetching: boolean;
  readonly needRefresh: boolean;
  readonly loadNextPage: () => void;
  readonly onBonusTransactionClick: (bonusTransaction: BonusTransaction) => void;
}

const SportsmanTabBonuses = (props: SportsmanTabBonusesProps) => {
  const { bonusTransactions, totalCount, isFetching, loadNextPage, needRefresh, onBonusTransactionClick } = props;

  const onRowRender = (item: Nullable<BonusTransaction>) => (
    <React.Fragment key={item?.id}>
      {item && (
        <BonusTransactionListItem
          bonusTransaction={item}
          onBonusTransactionClick={onBonusTransactionClick}
        />
      )}

      {!item && (
        <ListItem
          divider
          key='loader'
        >
          <ListItemAvatar>
            <AvatarWrapper />
          </ListItemAvatar>
          <ListItemText primary={'Загрузка...'} />
        </ListItem>
      )}
    </React.Fragment>
  );

  return (
    <Wrapper>
      <InfiniteList<BonusTransaction>
        list={bonusTransactions}
        isLoading={isFetching || needRefresh}
        loadMore={loadNextPage}
        totalCount={totalCount}
        onRowRender={onRowRender}
      />
    </Wrapper>
  );
};

export default SportsmanTabBonuses;
