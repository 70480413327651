import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { Partner } from '../../../domain/model/partner';
import ForbiddenComponent from '../../components/common/forbidden';
import { CorpOffersLocationState, ECorpOfferUrlParam } from '../../features/corpOffer/entry';
import CorpOfferTableEntry from '../../features/corpOffer/table/entry';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { EOfferTableTab } from '../../features/general/offer/types';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import { viewShowPartnerManagementMenu } from '../../features/partner/management/container/store/slice';
import { TradeOffersLocationState } from '../../features/tradeOffer/entry';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface PartnerManagementCorpOffersScreenProps {
  readonly partner: Partner;
}

const PartnerManagementCorpOffersScreen = ({ partner }: PartnerManagementCorpOffersScreenProps) => {
  const dispatch = useDispatch();
  const location = useLocation<TradeOffersLocationState>();

  const {
    accessMatrix: { corpOffers },
  } = useCurrentUser();

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(ECorpOfferUrlParam.Tab) as EOfferTableTab;

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowPartnerManagementMenu());
  }, [dispatch]);

  if (!corpOffers?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<CorpOffersLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <CorpOfferTableEntry
          guid={state.guid}
          partnerId={partner.id}
          tab={tab}
        />
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default PartnerManagementCorpOffersScreen;
