import { EOfferHistoryTableTab } from '../../types';

export enum EOfferHistoryTableColumn {
  EnteredAt = 'enteredAt',
  EnteredAtTime = 'enteredAtTime',
  Status = 'status',
  EnteredBy = 'enteredBy.lastName',
  StatusComment = 'statusComment',
  ActorType = 'actorType',
}

export enum EOfferFieldHistoryTableColumn {
  UpdatedAt = 'updatedAt',
  UpdatedAtTime = 'updatedAtTime',
  Field = 'field.name',
  OldValue = 'oldValue',
  NewValue = 'newValue',
  ActorType = 'actorType',
  UpdatedBy = 'updatedBy.lastName',
}

export enum EVariantHistory {
  CorpOffer = 'corpOffer',
  TradeOffer = 'tradeOffer',
  ProductOffers = 'productOffers',
  BookingOffer = 'booking',
}

export const getOfferHistoryTableTabName = (tab: EOfferHistoryTableTab): string => {
  switch (tab) {
    case EOfferHistoryTableTab.Status:
      return 'События';
    case EOfferHistoryTableTab.Fields:
      return 'Значения полей';
  }
};
