import { Chip } from '@mui/material';
import _ from 'lodash';
import { ActivityType } from '../../../../../domain/model/event';
import { Nullable } from '../../../../../domain/model/types';
import { Field, FieldsGroup } from '../../../../components/fields';
import { ItemsWrapper } from './controls';

type ActivityTypesFieldViewProps = {
  readonly value: Nullable<ActivityType[]>;
};

const ActivityTypesFieldView = (props: ActivityTypesFieldViewProps) => {
  const { value } = props;

  const groups = value?.length ? _.groupBy(value, item => item.parent?.name ?? item.name) : null;

  if (!groups || Object.keys(groups).length === 0) {
    return null;
  }

  return (
    <FieldsGroup>
      {Object.keys(groups).map(group => (
        <Field
          key={group}
          label={group}
          gap={1}
        >
          <ItemsWrapper>
            {groups[group].map(item => (
              <Chip
                key={item.id}
                color='primary'
                label={item.name}
              />
            ))}
          </ItemsWrapper>
        </Field>
      ))}
    </FieldsGroup>
  );
};

export default ActivityTypesFieldView;
