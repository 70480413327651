import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CustomerTableCellCommonProps } from '../../components/tableCell';
import { customersCustomerByIndexSelector } from '../store/selectors';

export type CustomerTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<CustomerTableCellCommonProps>;
};

export const CustomerTableCellAdapter = (props: CustomerTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const customer = useSelector(customersCustomerByIndexSelector(index));

  return useMemo(() => (customer ? <Component customer={customer} /> : null), [Component, customer]);
};
