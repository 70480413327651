import React from 'react';
import styled from '@emotion/styled';

interface WrapperProps {
  readonly fullHeight?: boolean;
  readonly footer?: React.ReactNode;
  readonly children: any;
}

interface ContentProps {
  readonly type?: 'table' | 'details';
  readonly stickyHeader?: boolean;
  readonly children: any;
}

export const Wrapper = styled(
  React.forwardRef(({ fullHeight, footer, ...others }: WrapperProps, ref: any) => (
    <div
      ref={ref}
      {...others}
    />
  ))
)`
  ${p =>
    p.fullHeight &&
    `
    height: 100vh;
  
    padding-top: 0;
    padding-bottom: 0;
  `};

  ${p =>
    p.fullHeight &&
    p.footer &&
    `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `};

  position: relative;
`;

export const Content = styled(
  React.forwardRef(({ type, stickyHeader, ...others }: ContentProps, ref: any) => (
    <div
      ref={ref}
      {...others}
    />
  ))
)`
  flex: 1;
  //height: 100%;
  padding-left: ${p => p.theme.spacing(3.75)};

  ${p =>
    p.type === 'table' &&
    !p.stickyHeader &&
    `
    padding: ${p.theme.spacing(3, 3.75)};
  `};

  ${p =>
    p.type === 'table' &&
    p.stickyHeader &&
    `
    padding: ${p.theme.spacing(0, 3.75, 3, 3.75)};
  `};

  ${p =>
    p.type === 'details' &&
    !p.stickyHeader &&
    `
    padding: ${p.theme.spacing(3, 0)};
  `};

  ${p =>
    p.type === 'details' &&
    p.stickyHeader &&
    `
    padding: ${p.theme.spacing(0, 4, 4, 10)};
    display: flex;
    flex-direction: column;
  `};
`;

export const Footer = styled.footer`
  z-index: 4;
  position: sticky;
  bottom: 0;

  background-color: ${p => p.theme.palette.background.default};
`;
