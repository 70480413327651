import { Grid } from '@mui/material';
import { DataFilterStrategyView } from '../../../../../../domain/model/filter';
import { Nullable } from '../../../../../../domain/model/types';
import DataFilterViewCommonItem from './common';

export interface DataFilterPreviewsProps<T extends string> {
  readonly strategy: DataFilterStrategyView<T>;
  readonly onDeleteItem?: (index: number) => void;
}

const DataFilterPreviews = <T extends string>({ strategy, onDeleteItem }: DataFilterPreviewsProps<T>) => {
  return (
    <Grid
      container
      spacing={1}
      direction='column'
    >
      {(strategy.previews || (strategy as DataFilterStrategyView<T, Nullable<string[]>>).value)?.map((label, index) => (
        <Grid
          item
          key={index}
        >
          <DataFilterViewCommonItem
            label={label}
            onDelete={onDeleteItem && !strategy.readOnly ? () => onDeleteItem(index) : undefined}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default DataFilterPreviews;
