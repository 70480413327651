import { SportOptionTyped } from '../../../../../../domain/model';
import { EPartnerOwnershipType, ETaxationSystemType } from '../../../../../../domain/model/enums';
import { Partner } from '../../../../../../domain/model/partner';
import PartnerDetailsInfoCompanyRequisites from './company';
import PartnerDetailsInfoIndividualRequisites from './individual';

interface PartnerDetailsInfoRequisitesProps {
  readonly partner: Partner;
  readonly taxSystems: SportOptionTyped<ETaxationSystemType>[];
}

const PartnerDetailsInfoRequisites = ({ partner, taxSystems }: PartnerDetailsInfoRequisitesProps) => {
  const { bic, checkingAccount, taxSystem } = partner;

  const taxSystemName = taxSystems.find(ts => ts.id === taxSystem)?.name ?? 'не определена';

  switch (partner.regInfo?.orgType) {
    case EPartnerOwnershipType.IndividualEntrepreneur:
      return (
        <PartnerDetailsInfoIndividualRequisites
          bic={bic}
          ogrnip={partner.regInfo?.ogrnip}
          checkingAccount={checkingAccount}
          taxSystemName={taxSystemName}
        />
      );
    case EPartnerOwnershipType.StockCompany:
    case EPartnerOwnershipType.PublicJoinStockCompany:
    case EPartnerOwnershipType.PK:
    case EPartnerOwnershipType.UP:
    case EPartnerOwnershipType.FGBNU:
    case EPartnerOwnershipType.NonProfitOrganization:
    case EPartnerOwnershipType.ClosedJoinStockCompany:
    case EPartnerOwnershipType.JoinStockCompany:
    case EPartnerOwnershipType.NAO:
    case EPartnerOwnershipType.AutonomousNonProfitOrganization:
    case EPartnerOwnershipType.LimitedLiabilityCompany:
      return (
        <PartnerDetailsInfoCompanyRequisites
          bic={bic}
          kpp={partner.regInfo?.kpp}
          ogrn={partner.regInfo?.ogrn}
          checkingAccount={checkingAccount}
          taxSystemName={taxSystemName}
        />
      );
    default:
      return <div />;
  }
};

export default PartnerDetailsInfoRequisites;
