import {
  disableChangeModeratorActionRule,
  disableModerateActionsRule,
  removeDeleteActionRule,
  removeDeskActionsRule,
  removeDuplicateActionRule,
  removeEditActionRule,
} from './rules';
import * as schemas from './scheme';
import { ProductLifeCycleRulesConfiguration } from './types';

/**
 * конфигурация правил админа
 */
export const commonAdminConfiguration: ProductLifeCycleRulesConfiguration = {
  statusScheme: schemas.adminStatusScheme.statusScheme,
  actionsScheme: schemas.adminStatusScheme.actionsScheme,
  actionRules: [
    removeEditActionRule,
    removeDeskActionsRule,
    disableModerateActionsRule,
    disableChangeModeratorActionRule,
  ],
};

/**
 * конфигурация правил партнёра
 */
export const commonPartnerConfiguration: ProductLifeCycleRulesConfiguration = {
  statusScheme: schemas.partnerStatusScheme.statusScheme,
  actionsScheme: schemas.partnerStatusScheme.actionsScheme,
  actionRules: [removeDeskActionsRule, removeDeleteActionRule, removeDuplicateActionRule],
};
