import { EOfferType, Nullable, UUID } from '@/domain';
import useTechConfig from '@/presentation/hooks/useTechConfig';
import { EAppFeature } from '@/presentation/types';
import { isAspPromotionType } from '@features/tradeOffer/utils';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import { nsiDataSelector } from '../../general/nsi/store/selectors';
import { TradeOfferLifeCycleFactory } from '../../general/offer/lifecycle/lifecycle';
import { EOfferTableTab } from '../../general/offer/types';
import { getOffersTableAdapterKey } from '../../general/offer/utils/table';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import {
  ETradeOfferTableFilterItem,
  getTradeOfferTableFilterStrategy,
  tradeOfferTableFilterItems,
} from '../filterUtils';
import { ETradeOfferTableColumn, TradeOfferTableStubAdapterType } from '../types';
import TradeOffersStubAdapter from './adapters/stub';
import TradeOffersPartnerStubAdapter from './adapters/stubPartner';
import TradeOfferTableContainer from './container';
import { TradeOffersTableConfigContext } from './context';
import { useTradeOffersTableConfig } from './hooks/useTradeOffersTableConfig';
import { tradeOffersIsEmptySelector } from './store/selectors';
import { getTradeOfferAdminTableColumns, getTradeOfferPartnerTableColumns } from './utils';

interface TradeOfferTableEntryProps {
  readonly guid: UUID;
  readonly partnerId?: Nullable<UUID>;
  readonly tab: Nullable<EOfferTableTab>;
}

const TradeOfferTableEntry = ({ guid, tab, partnerId = null }: TradeOfferTableEntryProps) => {
  const {
    accessMatrix: { isPartnerUser, tradeOffers },
    mpPartnerUserProfile,
    userSpecific,
  } = useCurrentUser();
  const { viewAsAdmin: isAdmin } = tradeOffers;

  const { hasFeature } = useTechConfig();
  const isAspFeature = hasFeature(EAppFeature.Asp);

  const { promotionTypes: tradePromotionTypes, userGenders: genderTypes } = useSelector(nsiDataSelector);
  const promotionTypes = isAspFeature
    ? tradePromotionTypes
    : tradePromotionTypes.filter(promotionType => !isAspPromotionType(promotionType.id));

  // Создаем жизненный цикл
  const lifecycle = useMemo(
    () =>
      TradeOfferLifeCycleFactory().create({
        isPartnerUser,
        userId: userSpecific.id,
      }),
    [isPartnerUser, userSpecific.id]
  );

  const config = useTradeOffersTableConfig({ lifecycle, canCreate: !!tradeOffers.create });
  const { defaultTab } = config;

  const outTab = tab ?? defaultTab;

  // получаем максимально возможный состав колонок
  const sourceColumns = useMemo(
    () =>
      isPartnerUser
        ? getTradeOfferPartnerTableColumns(outTab, isAspFeature)
        : getTradeOfferAdminTableColumns(outTab, partnerId, isAspFeature),
    [outTab, partnerId, isPartnerUser, isAspFeature]
  );

  // готовим адаптер для хука
  const adapter = useMemo<DataFilterAdapter<ETradeOfferTableColumn, ETradeOfferTableFilterItem>>(
    () => ({
      ...getOffersTableAdapterKey({
        offerType: EOfferType.Trade,
        tab: outTab,
        isAdmin,
        partnerId,
      }),
      sourceColumns,
      filterItems: tradeOfferTableFilterItems,
      requiredFilters: [ETradeOfferTableFilterItem.Query],
      getDataFilterStrategy: (filterItem, values) =>
        getTradeOfferTableFilterStrategy(filterItem, values, promotionTypes, genderTypes),
    }),
    [partnerId, outTab, sourceColumns, isAdmin, promotionTypes, genderTypes]
  );

  const stubAdapter = useMemo<TradeOfferTableStubAdapterType>(
    () =>
      isPartnerUser
        ? props => (
            <TradeOffersPartnerStubAdapter
              isEmptySelector={tradeOffersIsEmptySelector}
              partnerId={mpPartnerUserProfile?.partner?.id}
            >
              {props.children}
            </TradeOffersPartnerStubAdapter>
          )
        : TradeOffersStubAdapter,
    [isPartnerUser, mpPartnerUserProfile?.partner?.id]
  );

  return (
    <TradeOffersTableConfigContext.Provider value={config}>
      <TradeOfferTableContainer
        guid={guid}
        partnerId={partnerId}
        tab={outTab}
        lifecycle={lifecycle}
        filterAdapter={adapter}
        stubAdapter={stubAdapter}
        canCreate={tradeOffers.create}
        canDelete={tradeOffers.delete}
        canApprove={tradeOffers.approve}
        canReject={tradeOffers.reject}
        canResume={tradeOffers.resume}
        canPause={tradeOffers.pause}
        canArchive={tradeOffers.archive}
        canDownload={tradeOffers.download}
        canUpload={tradeOffers.upload}
        canModerate={tradeOffers.moderate}
        canDuplicate={tradeOffers.duplicate}
        canRetrieve={tradeOffers.retrieve}
        canChangeModerator={tradeOffers.changeModerator}
        canReturnToVerification={tradeOffers.returnToVerification}
      />
    </TradeOffersTableConfigContext.Provider>
  );
};

export default TradeOfferTableEntry;
