import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Nullable } from '../../../domain/model/types';
import { MpUser } from '../../../domain/model/user';
import ForbiddenComponent from '../../components/common/forbidden';
import CorpActivationActionsProvider from '../../features/corpActivation/actions/provider';
import { CorpActivationsLocationState, ECorpActivationUrlParam } from '../../features/corpActivation/entry';
import CorpActivationTableEntry from '../../features/corpActivation/table/entry';
import { ECorpActivationTableTab } from '../../features/corpActivation/types';
import { viewShowCustomerManagementCommonMenu } from '../../features/customer/management/common/store/slice';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface CustomerManagementCorpActivationsScreenProps {
  readonly customer: MpUser;
}

const CustomerManagementCorpActivationsScreen = ({ customer }: CustomerManagementCorpActivationsScreenProps) => {
  const dispatch = useDispatch();
  const location = useLocation<Nullable<CorpActivationsLocationState>>();

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(ECorpActivationUrlParam.Tab) as ECorpActivationTableTab;

  const {
    accessMatrix: { corpActivations, isPartnerUser },
    mpPartnerUserProfile,
  } = useCurrentUser();

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowCustomerManagementCommonMenu());
  }, [dispatch]);

  if (!corpActivations.view) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<CorpActivationsLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <CorpActivationActionsProvider>
          <CorpActivationTableEntry
            guid={state.guid}
            tab={tab}
            partnerId={isPartnerUser ? mpPartnerUserProfile.partner.id : null}
            customerId={customer.id}
          />
        </CorpActivationActionsProvider>
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default CustomerManagementCorpActivationsScreen;
