import React, { ChangeEvent, useRef, useState } from 'react';
import { Nullable } from '@/domain/model/types';
import {
  ColumnActionsPosition,
  DataTableColumns,
  DataTableDefaultComponents,
  DataTableRow,
  DataTableRowData,
  DataTableRowProperty,
  ETableColumnAlign,
} from '../index';
import { TabelCellActions } from '../cell/actions';
import { TableCellCheckBox } from '@/presentation/components/common/table/cell/checkbox';

interface BodyProps<D extends DataTableRowData, R extends string = string> {
  readonly columns: DataTableColumns;
  readonly rows: DataTableRow<D, R>[];
  readonly rowHeight: any;
  readonly selectable?: boolean;
  readonly singleSelect: boolean;
  readonly selected?: Nullable<D[]>;
  readonly fixColumnActions?: boolean;
  readonly columnActionsPosition: ColumnActionsPosition;
  readonly rowActions?: boolean;
  readonly canChangeMetadata: boolean;
  readonly hoverModule?: boolean;
  readonly onRowClick?: (event: Event, cell: string, row: DataTableRow<D, R>) => void;
  readonly onRowHover?: (event: Event, cell: string, row: DataTableRow<D, R>, hover: boolean) => void;
  readonly onRowSelect?: Nullable<(event: ChangeEvent, row: DataTableRow<D, R>, selected: boolean) => void>;
  readonly getRowActions: Nullable<(data: D, index: number) => React.ReactNode>;
}

export function Body<D extends DataTableRowData, R extends string = string>(props: BodyProps<D, R>) {
  const {
    columns,
    rows,
    rowHeight,
    selectable,
    singleSelect,
    selected,
    fixColumnActions,
    columnActionsPosition,
    rowActions,
    canChangeMetadata,
    hoverModule,
    onRowClick,
    onRowHover,
    onRowSelect,
    getRowActions,
  } = props;
  const checkboxRef = useRef<HTMLTableDataCellElement>(null);
  const [hoveredId, setHoveredId] = useState<Nullable<string | number>>(null);

  const isRowSelected = (row: DataTableRow<D, R>) => selected?.some(s => s.id === row.data.id);

  const isShowActionCell = rowActions || canChangeMetadata;
  const isSelecting = selectable && !!onRowSelect;

  return (
    <DataTableDefaultComponents.Body>
      {rows.map((row, index) => (
        <DataTableDefaultComponents.BodyRow
          key={row.data.id}
          active={!!onRowClick}
        >
          {isSelecting && (
            <TableCellCheckBox
              row={row}
              ref={checkboxRef}
              single={singleSelect}
              fixed={fixColumnActions && columnActionsPosition === 'start'}
              isRowSelected={() => isRowSelected(row)}
              onRowSelect={(event, checked) => onRowSelect(event, row, checked)}
              position={'start'}
            />
          )}

          {columnActionsPosition === 'start' && isShowActionCell && (
            <TabelCellActions
              row={row}
              index={index}
              height={rowHeight}
              align={ETableColumnAlign.Center}
              fixed={fixColumnActions}
              position={columnActionsPosition}
              positionOffset={isSelecting ? checkboxRef.current?.clientWidth : 0}
              getRowActions={getRowActions}
              rowActions={rowActions}
            />
          )}

          {Object.keys(columns).map((key, columnIndex) => {
            const column = columns[key]!;

            const Property = (row as any)[column.slotRowName ?? key]! as DataTableRowProperty<D>;

            const Cell = DataTableDefaultComponents.BodyCell;

            return (
              column &&
              !column.hidden && (
                <Cell
                  key={`${row.data.id}-${columnIndex}`}
                  height={rowHeight}
                  wrap={column.wrap}
                  align={column.align}
                  colSpan={column.colspan}
                  onMouseEnter={(event: any) => {
                    onRowHover?.(event, key, row, true);
                    if (hoverModule) {
                      setHoveredId(row.data.id);
                    }
                  }}
                  onMouseLeave={(event: any) => {
                    onRowHover?.(event, key, row, false);
                    if (hoverModule) {
                      setHoveredId(null);
                    }
                  }}
                  onClick={
                    onRowClick &&
                    ((event: any) => {
                      onRowClick(event, key, row);
                    })
                  }
                >
                  {typeof Property === 'function' ? (
                    <Property hoveredRow={hoveredId === row.data.id} />
                  ) : React.isValidElement(Property) ? (
                    React.cloneElement(Property, { column } as any)
                  ) : (
                    Property
                  )}
                </Cell>
              )
            );
          })}

          {columnActionsPosition === 'end' && isShowActionCell && (
            <TabelCellActions
              row={row}
              index={index}
              height={rowHeight}
              align={ETableColumnAlign.Center}
              fixed={fixColumnActions}
              position={columnActionsPosition}
              positionOffset={0}
              getRowActions={getRowActions}
              rowActions={rowActions}
            />
          )}
        </DataTableDefaultComponents.BodyRow>
      ))}
    </DataTableDefaultComponents.Body>
  );
}
