import Api from '../../../../data/api';
import { ApiResponseWithPageable } from '../../../../data/api/types';
import { EUserRole, EUserStatus } from '../../../../domain/model/enums';
import { SportUserProfile } from '../../../../domain/model/user';

export type TournamentUsersService = {
  readonly eventAdmins: () => Promise<ApiResponseWithPageable<SportUserProfile>>;
};

export const service: TournamentUsersService = {
  // Метод для получения модераторов
  eventAdmins: async () => {
    const res = await Api.user.sport.all({
      page: 0,
      pageSize: 99999,
      sort: 'lastName,asc',
      roles: [EUserRole.EventAdmin],
      statuses: [EUserStatus.Enabled, EUserStatus.NotFilled],
    });

    return res.data;
  },
};
