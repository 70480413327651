import { Grid, Typography } from '@mui/material';
import { DataTreeItem } from '../../../../../../domain/model';
import { EActivityTypeStatus } from '../../../../../../domain/model/enums';
import { ActivityType } from '../../../../../../domain/model/event';
import { DictionaryItemElementArrow } from '../../../../../components/common/dictionary/tree/element';
import { DictionaryItemConstructor } from '../../../../../components/common/dictionary/tree/item';
import { ActionButton } from '../../../../../components/common/dictionary/tree/item/controls';
import { DictionaryItemContentProps } from '../../../../../components/common/dictionary/types';
import AppSvgIcon from '../../../../../components/common/images/icon';
import { BasketIcon, EditIcon, PasswordInvisibleIcon, PasswordVisibleIcon } from '../../../../../icons';
import { Image, InvisibleIconButton } from './controls';

type ActivityTypesEditListItemProps = Omit<DictionaryItemContentProps<ActivityType>, 'onClick'> & {
  readonly onEdit?: (item: DataTreeItem<ActivityType>) => void;
  readonly onHide?: (item: DataTreeItem<ActivityType>) => void;
  readonly onShow?: (item: DataTreeItem<ActivityType>) => void;
  readonly onDelete?: (item: DataTreeItem<ActivityType>) => void;
};

export const ActivityTypesEditListItem = (props: ActivityTypesEditListItemProps) => {
  const { data, selected, onEdit, onHide, onShow, onDelete } = props;
  return (
    <DictionaryItemConstructor
      {...props}
      startAdornment={data.data.image?.path && <Image src={data.data.image} />}
      content={
        <Typography color={data.data.status === EActivityTypeStatus.Disabled ? 'textSecondary' : 'textPrimary'}>
          {data.label}
        </Typography>
      }
      endAdornment={
        <Grid
          container
          spacing={1}
          wrap='nowrap'
          alignItems='center'
          justifyContent='flex-end'
        >
          {data.data.status === EActivityTypeStatus.Enabled && (
            <>
              {onEdit && (
                <Grid item>
                  <ActionButton
                    disableFocusRipple
                    size='small'
                    onClick={() => onEdit(data)}
                  >
                    <AppSvgIcon
                      color='primary'
                      icon={EditIcon}
                    />
                  </ActionButton>
                </Grid>
              )}
              {onHide && (
                <Grid item>
                  <ActionButton
                    disableFocusRipple
                    size='small'
                    onClick={() => onHide(data)}
                  >
                    <AppSvgIcon
                      color='primary'
                      icon={PasswordVisibleIcon}
                    />
                  </ActionButton>
                </Grid>
              )}
              {onDelete && (
                <Grid item>
                  <ActionButton
                    disableFocusRipple
                    size='small'
                    onClick={() => onDelete(data)}
                  >
                    <AppSvgIcon
                      color='primary'
                      icon={BasketIcon}
                    />
                  </ActionButton>
                </Grid>
              )}
            </>
          )}
          {data.data.status === EActivityTypeStatus.Disabled && onShow && (
            <Grid item>
              <InvisibleIconButton
                disableFocusRipple
                size='small'
                onClick={() => onShow(data)}
              >
                <AppSvgIcon
                  color='secondary'
                  icon={PasswordInvisibleIcon}
                />
              </InvisibleIconButton>
            </Grid>
          )}
          {Array.isArray(data.children) && data.children.length > 0 && (
            <Grid item>
              <DictionaryItemElementArrow active={selected} />
            </Grid>
          )}
        </Grid>
      }
    />
  );
};
