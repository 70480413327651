import { combineReducers } from '@reduxjs/toolkit';
import actions, { BookingOrderActionsState } from './actions/store/slice';
import details, { BookingOrderDetailsState } from './details/store/slice';
import edit, { BookingOrderEditState } from './edit/store/slice';
import list, { BookingOrderListState } from './table/store/slice';

interface BookingOrderState {
  edit: BookingOrderEditState;
  list: BookingOrderListState;
  actions: BookingOrderActionsState;
  details: BookingOrderDetailsState;
}

export default combineReducers<BookingOrderState>({
  edit,
  list,
  actions,
  details,
});
