import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable } from '../../../../../domain/model';
import { BookingOrder } from '../../../../../domain/model/order';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { PaginationSize } from '../../../../types';
import bookingOrderServices from '../../services';
import { AllProps } from '../../services/order';
import { EBookingOrderTableColumn, EBookingOrderTableTab } from '../../types';
import { BookingOrdersFilterValues } from '../filterUtils';
import { BookingOrderTableTabsCounter } from '../utils';

const defaultSort = `${EBookingOrderTableColumn.CreateAt},desc` as const;

interface BookingOrderSearchState {
  readonly sort: string;
  readonly pageSize: PaginationSize;
  readonly partnerId: Nullable<UUID>;
}

export interface BookingOrderListState {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly orders: Fetchable & Pageable<BookingOrder>;
  readonly search: BookingOrderSearchState;
  readonly tab: Nullable<EBookingOrderTableTab>;
  readonly filter: BookingOrdersFilterValues;
  readonly tabsCounter: BookingOrderTableTabsCounter;
}

type Reducer<T = undefined> = CaseReducer<BookingOrderListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<BookingOrderListState> {
  bookingOrdersStartSession: Reducer<{ guid: UUID }>;
  bookingOrdersSetSearch: Reducer<{ sort: string; pageSize: PaginationSize }>;
  bookingOrdersSetSort: Reducer<{ sort: string }>;
  bookingOrdersSortReset: Reducer;
  bookingOrdersSetTab: Reducer<{ tab: EBookingOrderTableTab }>;
  bookingOrdersSetFilter: Reducer<{ filter: BookingOrdersFilterValues }>;
  bookingOrdersSetPage: Reducer<{ pageNumber: number }>;
  bookingOrdersSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  bookingOrdersDataReset: Reducer;
  bookingOrdersNeedRefreshWatcherReset: Reducer;
  bookingOrdersNeedRefreshWatcherIncrement: Reducer;
}

export type BookingOrdersFetchProps = Omit<AllProps, 'signal'>;

export type OrdersFetchProps = Omit<AllProps, 'signal'>;
export const bookingOrdersFetch = createAsyncThunk<Pageable<BookingOrder>, OrdersFetchProps, AppThunkAPIConfig>(
  'bookingOrders/list/fetch',
  async (payload, { rejectWithValue, signal }) => {
    try {
      return await bookingOrderServices.order.all(payload);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export type BookingOrdersCountsFetchProps = BookingOrdersFetchProps & {
  readonly tabs: EBookingOrderTableTab[];
};

export const bookingOrdersCountsFetch = createAsyncThunk<
  BookingOrderTableTabsCounter,
  BookingOrdersCountsFetchProps,
  AppThunkAPIConfig
>('bookingOrder/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await bookingOrderServices.order.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

const slice = createSlice<BookingOrderListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    guid: null,
    needRefreshWatcher: 0,
    orders: {
      ...fetchableDefault,
      data: [],
      totalCount: 0,
      pageCount: 0,
      pageNumber: 1,
    },
    search: {
      sort: defaultSort,
      pageSize: 10,
      partnerId: null,
    },
    tab: null,
    filter: {},
    tabsCounter: {},
  },
  reducers: {
    bookingOrdersStartSession: (state, { payload }) => {
      const { guid } = payload;
      if (guid !== state.guid) {
        state.guid = guid;

        state.orders.isFetching = false;
        state.orders.isFetched = false;
        state.orders.isFailed = false;

        state.orders.data = [];
        state.orders.totalCount = 0;
        state.orders.pageCount = 0;
        state.orders.pageNumber = 1;
        state.needRefreshWatcher = 0;
        state.search = {
          sort: defaultSort,
          pageSize: 10,
          partnerId: null,
        };
        state.tab = null;
        state.filter = {};
        state.tabsCounter = {};
      }
    },
    bookingOrdersSetSearch: (state, { payload }) => {
      const { sort, pageSize } = payload;
      state.search = {
        ...state.search,
        sort,
        pageSize,
      };
      state.orders.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    bookingOrdersSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.orders.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    bookingOrdersSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.needRefreshWatcher++;
    },
    bookingOrdersSetTab: (state, { payload }) => {
      const { tab } = payload;
      if (state.tab !== tab) {
        state.orders.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    bookingOrdersSetFilter: (state, { payload }) => {
      const { filter } = payload;
      state.filter = filter;
      state.orders.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    bookingOrdersSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.orders.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    bookingOrdersSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.orders.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    bookingOrdersDataReset: (state, { payload }) => {
      state.orders.data = [];
      state.orders.totalCount = 0;
      state.orders.pageCount = 0;
      state.orders.pageNumber = 1;
    },
    bookingOrdersNeedRefreshWatcherReset: (state, { payload }) => {
      state.needRefreshWatcher = 0;
    },
    bookingOrdersNeedRefreshWatcherIncrement: state => {
      state.needRefreshWatcher++;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bookingOrdersFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        state.orders.isFetching = true;
        state.orders.isFetched = false;
        state.orders.isFailed = false;

        if (partnerId !== state.search.partnerId) {
          state.orders.data = [];
        }
        state.search.partnerId = partnerId;
      })
      .addCase(bookingOrdersFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.orders.isFetching = false;
        state.orders.isFetched = true;
        state.orders.isFailed = false;

        state.orders.data = data;
        state.orders.totalCount = totalCount;
        state.orders.pageCount = pageCount;
      })
      .addCase(bookingOrdersFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.orders.isFetching = false;
          state.orders.isFetched = false;
          state.orders.isFailed = true;
          state.orders.data = [];
        }
      })
      .addCase(bookingOrdersCountsFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        if (partnerId !== state.search.partnerId) {
          state.tabsCounter = {};
        }
      })
      .addCase(bookingOrdersCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(bookingOrdersCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      });
  },
});

export const {
  bookingOrdersStartSession,
  bookingOrdersSetSearch,
  bookingOrdersSetSort,
  bookingOrdersSortReset,
  bookingOrdersSetTab,
  bookingOrdersSetFilter,
  bookingOrdersSetPage,
  bookingOrdersSetPageSize,
  bookingOrdersDataReset,
  bookingOrdersNeedRefreshWatcherReset,
  bookingOrdersNeedRefreshWatcherIncrement,
} = slice.actions;

export default slice.reducer;
