import { PartnerView } from '@/domain';
import { MPAutocompleteSingleSelect } from '@ui-kit/autocomplete/single';
import { AdCampaignCreateFieldCommonProps } from '../../types';

const fieldName = 'partner';

interface AdCampaignCreateFieldPartnerProps extends AdCampaignCreateFieldCommonProps<typeof fieldName> {
  readonly partners: PartnerView[];
  readonly isSearching: boolean;
  readonly onSearch: (value: string) => void;
}

const AdCampaignCreateFieldPartner = (props: AdCampaignCreateFieldPartnerProps) => {
  const { value, partners, isSearching, validation, disabled, onChangeAttribute, onValidateAttribute, onSearch } =
    props;

  const onChangeValue = (newValue: Nullable<PartnerView>) => {
    onChangeAttribute(fieldName, newValue);
    onValidateAttribute(fieldName);
  };

  return (
    <MPAutocompleteSingleSelect<PartnerView>
      label='Партнер'
      disabled={disabled}
      value={value}
      error={validation?.hasError}
      helperText={validation?.message}
      isLoading={isSearching}
      options={partners}
      onSearchValue={onSearch}
      onChangeValue={onChangeValue}
    />
  );
};

export default AdCampaignCreateFieldPartner;
