import { Chip } from '@mui/material';
import React from 'react';
import { OrgUnit } from '../../../../../../domain/model/orgStructure';
import { Nullable } from '../../../../../../domain/model/types';
import { MPAutocompleteMultipleSelect } from '../../../../../theme/ui-kit/autocomplete/multiple';
import OrgStructureOrgUnitMultipleItem from './item';
import { OrgStructureOrgUnitMultipleSelectType, OrgStructureOrgUnitMultipleTypesSelectorItem } from './types';
import useOrgStructureOrgUnitMultipleTypes from './useMultipleTypes';

interface OrgStructureOrgUnitMultipleTypesSelectorProps {
  readonly select?: Nullable<OrgStructureOrgUnitMultipleSelectType>;
  readonly selectNone?: boolean;
  readonly orgUnits: OrgUnit[];
  readonly label: string;
  readonly helperText?: string;
  readonly error?: boolean;
  readonly limitTags?: number;
  readonly disabled?: boolean;
  readonly isLoading?: boolean;
  readonly color?: 'default' | 'primary' | 'secondary' | undefined;
  readonly popupIcon?: React.ReactNode;
  /* набор данных по умолчанию (в моменты когда нет поискового запроса) */
  readonly defaultSource?: Nullable<OrgUnit[]>;
  readonly onSelect: (type: OrgStructureOrgUnitMultipleSelectType) => void;
  readonly onChange: (orgUnits: OrgUnit[]) => void;
}

const OrgStructureOrgUnitMultipleTypesSelector = (props: OrgStructureOrgUnitMultipleTypesSelectorProps) => {
  const {
    select,
    selectNone,
    orgUnits,
    label,
    helperText,
    error,
    limitTags = -1,
    disabled = false,
    color = 'default',
    popupIcon,
    isLoading: isInitialLoading,
    defaultSource,
    onSelect,
    onChange,
  } = props;

  const {
    options,
    values,
    isLoading: isLoading,
    setSearchValue,
    onChangeValue,
    getOptionLabel,
  } = useOrgStructureOrgUnitMultipleTypes({
    select,
    selectNone,
    orgUnits,
    defaultSource,
    onSelect,
    onChange,
  });

  return (
    <MPAutocompleteMultipleSelect<OrgStructureOrgUnitMultipleTypesSelectorItem>
      value={values}
      label={label}
      helperText={helperText}
      error={error}
      options={options}
      limitTags={limitTags}
      isLoading={isLoading || isInitialLoading}
      disabled={disabled}
      disableCloseOnSelect
      onSearchValue={setSearchValue}
      getOptionLabel={getOptionLabel}
      onChangeValue={onChangeValue}
      renderOption={(elementProps, option, state) => (
        <OrgStructureOrgUnitMultipleItem
          key={option.id}
          elementProps={elementProps}
          option={option}
          state={state}
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            component={'div' as any}
            color={color}
            selected
            label={option.name}
            {...getTagProps({ index })}
            key={option.id}
          />
        ))
      }
      popupIcon={popupIcon}
    />
  );
};

export default OrgStructureOrgUnitMultipleTypesSelector;
