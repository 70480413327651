import { ESortDirection } from '@/domain/model/enums';
import { partition } from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import { ETradeActivationTableColumn } from '../../types';
import {
  ETradeActivationsFilterItem,
  TradeActivationsFilterEditStrategy,
  TradeActivationsFilterValues,
} from '../../utils/filter';
import {
  tradeActivationsNeedRefreshWatcherIncrement,
  tradeActivationsSetFilter,
  tradeActivationsSetPage,
  tradeActivationsSetPageSize,
  tradeActivationsSetSort,
  tradeActivationsSortReset,
} from '../store/slice';

export type UseTradeActivationTable = {
  readonly onRefresh: () => void;
  readonly onResetSort: () => void;

  readonly onChangeSort: (name: ETradeActivationTableColumn, direction: ESortDirection) => void;
  readonly onChangeFilter: (strategies: TradeActivationsFilterEditStrategy[]) => void;
  readonly onChangePage: (num: number) => void;
  readonly onChangePageSize: (size: PaginationSize) => void;
  readonly onSortReset: () => void;
  readonly onClearFilter: () => void;
};

export const useTradeActivationTableHandlers = (): UseTradeActivationTable => {
  const dispatch = useDispatch();

  const onRefresh = useCallback(() => dispatch(tradeActivationsNeedRefreshWatcherIncrement()), [dispatch]);
  const onResetSort = useCallback(() => dispatch(tradeActivationsSortReset()), [dispatch]);

  const onChangeFilter = useCallback(
    (strategies: TradeActivationsFilterEditStrategy[]) => {
      dispatch(
        tradeActivationsSetFilter({
          filter: getDataFilterValuesByStrategies<ETradeActivationsFilterItem, TradeActivationsFilterValues>(
            strategies
          ),
        })
      );
    },
    [partition]
  );

  const onChangePage = useCallback(
    (pageNumber: number) => {
      dispatch(tradeActivationsSetPage({ pageNumber }));
    },
    [dispatch]
  );

  const onChangeSort = useCallback(
    (name: ETradeActivationTableColumn, direction: ESortDirection) => {
      dispatch(
        tradeActivationsSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(tradeActivationsSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onSortReset = useCallback(() => {
    dispatch(tradeActivationsSortReset());
  }, [dispatch]);

  const onClearFilter = useCallback(() => {
    dispatch(tradeActivationsSetFilter({ filter: {} }));
  }, [partition]);

  return {
    onRefresh,
    onResetSort,
    onChangeSort,
    onChangeFilter,
    onChangePage,
    onChangePageSize,
    onSortReset,
    onClearFilter,
  };
};
