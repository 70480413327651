import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isClientOrgsAnyChangedSelector } from '../store/selectors';
import { clientOrgActionsOptimize } from '../store/slice';

export const ClientOrgActionsOptimizerAdapter = () => {
  const dispatch = useDispatch();
  const needOptimize = useSelector(isClientOrgsAnyChangedSelector());

  useEffect(() => {
    if (needOptimize > 0) {
      dispatch(clientOrgActionsOptimize());
    }
  }, [needOptimize]);

  return null;
};
