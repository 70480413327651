import { Grid, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import { EDateTimeFormat } from '../../../../../domain/model/formats';
import { Nullable } from '../../../../../domain/model/types';
import { MpUserShort } from '../../../../../domain/model/user';
import { EUserNameRule, getUserFullName } from '../../../../utils';
import { getCustomerDetailsRoute } from '../../../customer/entry';
import { LinkToObject, Wrapper } from './controls';

interface ProductOrderDetailsInfoProps {
  readonly date?: Nullable<string>;
  readonly customer: MpUserShort;
}

const ProductOrderDetailsInfo = (props: ProductOrderDetailsInfoProps) => {
  const { date, customer } = props;

  return (
    <Wrapper>
      <Grid
        container
        spacing={2}
        direction='column'
      >
        <Grid item>
          <Typography
            variant='body2'
            color='textSecondary'
          >
            Получатель
          </Typography>
          <Typography>
            <LinkToObject
              component={RouterLink}
              to={getCustomerDetailsRoute({ id: customer.id })}
              color='textPrimary'
            >
              {getUserFullName({ user: customer, rule: EUserNameRule.Reverse })}
            </LinkToObject>
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant='body2'
            color='textSecondary'
          >
            Телефон
          </Typography>
          {customer.phone ? (
            <Typography
              component={Link}
              href={`tel:${customer.phone}`}
              color='textPrimary'
            >
              {customer.phone}
            </Typography>
          ) : (
            <Typography variant='body2'>-</Typography>
          )}
        </Grid>
        <Grid item>
          <Typography
            variant='body2'
            color='textSecondary'
          >
            Электронная почта
          </Typography>
          <Typography
            component={Link}
            href={`mailto:${customer.email}`}
            color='textPrimary'
          >
            {customer.email}
          </Typography>
        </Grid>
        {date && (
          <>
            <Grid item>
              <Typography
                variant='body2'
                color='textSecondary'
              >
                Время
              </Typography>
              <Typography>{moment(date).format(EDateTimeFormat.DisplayDefault)}</Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Wrapper>
  );
};

export default ProductOrderDetailsInfo;
