import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { SportEvent } from '../../../../../domain/model/event';
import { Nullable, UUID } from '../../../../../domain/model/types';

export const eventByIdFetch = createAsyncThunk<SportEvent, UUID, AppThunkAPIConfig>(
  'event/byId/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      const { data } = await Api.event.one({ id, signal });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface EventByIdState extends Fetchable {
  readonly data: Nullable<SportEvent>;
}

type Reducer<T = undefined> = CaseReducer<EventByIdState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<EventByIdState> {
  eventByIdStateReset: Reducer;
}

const slice = createSlice<EventByIdState, Reducers, 'byId'>({
  name: 'byId',
  initialState: {
    ...fetchableDefault,
    data: null,
  },
  reducers: {
    eventByIdStateReset: state => {
      state.isFetching = false;
      state.isFetched = false;
      state.isFailed = false;
      state.data = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(eventByIdFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
        state.data = null;
      })
      .addCase(eventByIdFetch.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.data = payload;
      })
      .addCase(eventByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
        } else {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = false;
        }
      });
  },
});

export const { eventByIdStateReset } = slice.actions;

export default slice.reducer;
