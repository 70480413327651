import styled from '@emotion/styled';
import { viewConfig } from '../../../theme/viewConfig';

export const LoaderWrapper = styled.div`
  z-index: 3;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: ${viewConfig.stepper.width};
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;

  background: ${p => p.theme.palette.background.default};
`;

export const Wrapper = styled.div``;

export const FooterWrapper = styled.div`
  border-top: 1px solid ${p => p.theme.palette.secondary.main};

  padding: ${p => p.theme.spacing(2)} ${p => p.theme.spacing(4)};
`;

export const ColumnWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  padding-left: ${viewConfig.stepper.width};
`;

export const TitleWrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  padding-bottom: ${p => p.theme.spacing(1.5)};

  border-bottom: 1px solid ${p => p.theme.palette.disabled.background};
`;
