import { ApiRequestPageable } from '@/data/api/types';
import {
  appendQueryDslParams,
  getOfferActivationsDownloadEndpoint,
  getQueryDslByDataFilterValues,
} from '@/data/api/utils';
import { UserAccessPartitionCorpActivations } from '@/domain/model/accessMatrix';
import { EOfferActivationStatus, EOfferType } from '@/domain/model/enums';
import { DataFilterQueryDslOperator } from '@/domain/model/filter';
import { Nullable, UUID } from '@/domain/model/types';
import { DataTableColumns, MutableDataTableColumns } from '@/presentation/components/common/table';
import { CorpActivationsFilterValues } from '@features/corpActivation/utils/filter';
import { PanelAction, PanelActions } from '../../../types';
import {
  CorpActivationActionTableType,
  ECorpActivationActionType,
  ECorpActivationTableColumn,
  ECorpActivationTableTab,
} from '../types';
import { getCorpActivationActionsConfigByPermissions } from './actions';

export type CorpActivationTableTabsCounter = {
  [name in ECorpActivationTableTab]?: number;
};

const corpActivationTableColumns: DataTableColumns<ECorpActivationTableColumn> = {
  [ECorpActivationTableColumn.CreateAt]: {
    title: 'Дата',
    width: '10rem',
    sortable: true,
  },
  [ECorpActivationTableColumn.OfferCode]: {
    title: 'Код КП',
    width: '6rem',
    sortable: true,
  },
  [ECorpActivationTableColumn.OfferName]: {
    title: 'Название',
    width: '10rem',
    wrap: true,
    sortable: true,
  },
  [ECorpActivationTableColumn.Status]: {
    title: 'Статус выдачи',
    width: '10rem',
    sortable: true,
  },
  [ECorpActivationTableColumn.PromotionType]: {
    title: 'Способ получения',
    width: '10rem',
    sortable: true,
  },
  [ECorpActivationTableColumn.Partner]: {
    title: 'Партнер',
    width: '10rem',
    sortable: true,
  },
  [ECorpActivationTableColumn.PromotionCode]: {
    title: 'Присвоенный код',
    width: '10rem',
    sortable: true,
  },
  [ECorpActivationTableColumn.Price]: {
    title: 'Сумма в баллах',
    width: '10rem',
    sortable: true,
  },
  [ECorpActivationTableColumn.CustomerPernr]: {
    title: 'Табельный №',
    width: '10rem',
    sortable: true,
  },
  [ECorpActivationTableColumn.CustomerName]: {
    title: 'ФИО',
    width: '15rem',
    wrap: true,
    sortable: true,
  },
  [ECorpActivationTableColumn.CustomerEmail]: {
    title: 'Эл.почта',
    width: '10rem',
    sortable: true,
  },
  [ECorpActivationTableColumn.CustomerPhone]: {
    title: 'Телефон',
    width: '10rem',
    sortable: true,
  },
  [ECorpActivationTableColumn.GivenBy]: {
    title: 'Кем выдано',
    width: '15rem',
    wrap: true,
    sortable: true,
  },
  [ECorpActivationTableColumn.GivenAt]: {
    title: 'Дата выдачи',
    width: '10rem',
    sortable: true,
  },
};

export const getCorpActivationTableVisibleColumns = (
  partnerId: Nullable<UUID>,
  customerId: Nullable<UUID>,
  tab: ECorpActivationTableTab
): DataTableColumns<ECorpActivationTableColumn> => {
  const columns: ECorpActivationTableColumn[] = [];

  columns.push(
    ECorpActivationTableColumn.CreateAt,
    ECorpActivationTableColumn.OfferCode,
    ECorpActivationTableColumn.OfferName
  );

  if (tab === ECorpActivationTableTab.Approved) {
    columns.push(ECorpActivationTableColumn.Status);
  }

  columns.push(ECorpActivationTableColumn.PromotionType);

  if (!partnerId) {
    columns.push(ECorpActivationTableColumn.Partner);
  }

  columns.push(ECorpActivationTableColumn.PromotionCode, ECorpActivationTableColumn.Price);

  if (!customerId) {
    columns.push(
      ECorpActivationTableColumn.CustomerPernr,
      ECorpActivationTableColumn.CustomerName,
      ECorpActivationTableColumn.CustomerEmail,
      ECorpActivationTableColumn.CustomerPhone
    );
  }

  if (tab === ECorpActivationTableTab.Approved) {
    columns.push(ECorpActivationTableColumn.GivenBy, ECorpActivationTableColumn.GivenAt);
  }

  const mutableTableColumns: MutableDataTableColumns<ECorpActivationTableColumn> = {};
  columns.forEach(column => (mutableTableColumns[column] = corpActivationTableColumns[column]));
  return mutableTableColumns;
};

export const getCorpActivationStatusesByTableTab = (tab: ECorpActivationTableTab): EOfferActivationStatus[] => {
  switch (tab) {
    case ECorpActivationTableTab.Pending:
      return [EOfferActivationStatus.Pending];

    case ECorpActivationTableTab.Approved:
      return [EOfferActivationStatus.Approved, EOfferActivationStatus.Given];
  }
};

const filterCorpActivationTableActions = (
  actions: PanelActions<ECorpActivationActionType>
): PanelActions<CorpActivationActionTableType> => {
  const tableActions: PanelActions<CorpActivationActionTableType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case ECorpActivationActionType.Give:
      case ECorpActivationActionType.UnGive:
        tableActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          position: action.position,
        });
        break;
    }
  });

  return tableActions;
};

export const getCorpActivationTableTabActions = (
  permissions: UserAccessPartitionCorpActivations
): PanelAction<CorpActivationActionTableType>[] => {
  const allowedActions = getCorpActivationActionsConfigByPermissions(permissions);
  return filterCorpActivationTableActions(allowedActions);
};

export const getCorpActivationTableTabName = (tab: ECorpActivationTableTab) => {
  switch (tab) {
    case ECorpActivationTableTab.Pending:
      return 'В обработке';
    case ECorpActivationTableTab.Approved:
      return 'Выполненные';
  }
};

enum ECorpActivationsDownloadUrlParam {
  PartnerId = 'offer.partner.id',
  CustomerId = 'customer.id',
}

type GetCorpActivationsDownloadUrlWithSkipPaginationProps = {
  readonly skipPageable: true;
} & Partial<ApiRequestPageable>;

type GetCorpActivationsDownloadUrlWithoutSkipPaginationProps = {
  readonly skipPageable?: false;
} & ApiRequestPageable;

type MabeSkipPaginationGetCorprActivationsDownloadUrlProps =
  | GetCorpActivationsDownloadUrlWithSkipPaginationProps
  | GetCorpActivationsDownloadUrlWithoutSkipPaginationProps;

type GetCorpActivationsDownloadUrlProps = {
  readonly partnerId?: Nullable<UUID>;
  readonly customerId?: Nullable<UUID>;
  readonly filter: CorpActivationsFilterValues;
} & MabeSkipPaginationGetCorprActivationsDownloadUrlProps;

export const getCorpActivationsDownloadUrl = (props: GetCorpActivationsDownloadUrlProps) => {
  const { sort, page, pageSize, partnerId, customerId, filter, skipPageable } = props;

  const searchParams = new URLSearchParams();

  if (sort) {
    if (typeof sort === 'string') {
      searchParams.append('sort', sort);
    } else {
      sort.forEach(item => searchParams.append('sort', item));
    }
  }

  if (skipPageable) {
    searchParams.append('skipPageable', skipPageable.toString());
  } else {
    page && searchParams.append('page', (page - 1).toString(10));
    pageSize && searchParams.append('size', pageSize.toString(10));
  }

  searchParams.append('offerType', EOfferType.Corp);

  const queryDsl = getQueryDslByDataFilterValues(filter) ?? [];

  if (customerId) {
    queryDsl.push({
      field: ECorpActivationsDownloadUrlParam.CustomerId,
      operator: DataFilterQueryDslOperator.Equals,
      value: [customerId],
    });
  }

  if (partnerId) {
    queryDsl.push({
      field: ECorpActivationsDownloadUrlParam.PartnerId,
      operator: DataFilterQueryDslOperator.Equals,
      value: [partnerId],
    });
  }

  appendQueryDslParams(searchParams, queryDsl);

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return `${getOfferActivationsDownloadEndpoint()}${search}`;
};
