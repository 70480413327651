import { EPartnerOwnershipType } from '../../../../../../domain/model/enums';
import { Partner } from '../../../../../../domain/model/partner';
import PartnerDetailsInfoCompanyDocuments from './company';
import PartnerDetailsInfoIndividualDocuments from './individual';

interface PartnerDetailsInfoDocumentsProps {
  readonly partner: Partner;
}

const PartnerDetailsInfoDocuments = ({ partner }: PartnerDetailsInfoDocumentsProps) => {
  const { innCert, usnDoc } = partner;

  switch (partner.regInfo?.orgType) {
    case EPartnerOwnershipType.IndividualEntrepreneur:
      return (
        <PartnerDetailsInfoIndividualDocuments
          innCert={innCert}
          ogrnipCert={partner.regInfo?.ogrnipCert}
          passportCopy={partner.regInfo?.passportCopy}
          usnDoc={usnDoc}
        />
      );
    case EPartnerOwnershipType.StockCompany:
    case EPartnerOwnershipType.PublicJoinStockCompany:
    case EPartnerOwnershipType.PK:
    case EPartnerOwnershipType.UP:
    case EPartnerOwnershipType.FGBNU:
    case EPartnerOwnershipType.NonProfitOrganization:
    case EPartnerOwnershipType.ClosedJoinStockCompany:
    case EPartnerOwnershipType.JoinStockCompany:
    case EPartnerOwnershipType.NAO:
    case EPartnerOwnershipType.AutonomousNonProfitOrganization:
    case EPartnerOwnershipType.LimitedLiabilityCompany:
      return (
        <PartnerDetailsInfoCompanyDocuments
          innCert={innCert}
          ogrnCert={partner.regInfo?.ogrnCert}
          ceoAppointmentProtocol={partner.regInfo?.ceoAppointmentProtocol}
          charterDoc={partner.regInfo?.charterDoc}
        />
      );
    default:
      return <div />;
  }
};

export default PartnerDetailsInfoDocuments;
