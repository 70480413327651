import { Typography, TypographyProps } from '@mui/material';
import { TitleWrapper } from './controls';

type OfferMasterTitleProps = TypographyProps;

export const OfferMasterTitle = (props: OfferMasterTitleProps) => {
  const { children, ...other } = props;
  return (
    <TitleWrapper>
      <Typography
        variant='h2'
        {...other}
      >
        {children}
      </Typography>
    </TitleWrapper>
  );
};
