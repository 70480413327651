import { FCC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import store from '../../../../../data/store/store';
import { Nullable } from '../../../../../domain/model/types';
import validateObject from '../../../../hooks/validation/utils';
import { ValidationCollectionResult, ValidationRules } from '../../../../utils/validation';
import { useBookingOfferActions } from '../../actions/useActions';
import { BookingServicePriceItemView } from '../../components/fieldsEdit/types';
import {
  bookingOfferCreateSetAttributeValidation,
  bookingOfferCreateSetServiceAttribute,
} from '../../create/store/slice';
import { BookingOfferServiceView, BookingOfferView, BookingServicePriceItemValidateView } from '../../types';
import { showBookingOfferErrorNotification, showBookingOfferInvalidNotification } from '../../utils/common';
import {
  bookingOfferBusinessValidationRules,
  bookingOfferPriceItemBusinessValidationRules,
  bookingOfferServiceBusinessValidationRules,
  getBookingOfferValidationRulesForSave,
} from '../../utils/validation';
import { BookingOfferServiceManageContainerProps } from '../container';
import { BookingOfferServiceManageHandlersContext, BookingOfferServiceManageHandlersContextValue } from '../context';
import {
  bookingOfferServiceManageBookingOfferSelector,
  bookingOfferServiceManageValidationServicesResultsSelector,
} from '../store/selectros';
import {
  bookingOfferServiceManageClearAllValidations,
  bookingOfferServiceManageClearServiceAttributeValidation,
  bookingOfferServiceManageServicePriceItemSetAttributeValidation,
  bookingOfferServiceManageServiceSetAttributeValidation,
  bookingOfferServiceManageSetModified,
  bookingOfferServiceManageSetServiceAttribute,
  bookingOfferServiceManageSetServicePriceItemAttribute,
  bookingOfferServiceManageSetServicePriceItemsValidation,
  bookingOfferServiceManageSetServicesValidation,
} from '../store/slice';

export const BookingOfferServiceManageHandlersProvider: FCC<BookingOfferServiceManageContainerProps> = ({
  children,
}) => {
  const dispatch = useDispatch();

  const { onSaveServices, onChangeDialogState } = useBookingOfferActions();

  const getBookingOfferFromState = useCallback((): BookingOfferView => {
    const state = store.getState();
    return bookingOfferServiceManageBookingOfferSelector(state);
  }, [store]);

  const getBookingOfferServicesFromState = useCallback((): Nullable<BookingOfferServiceView[]> => {
    return getBookingOfferFromState().services ?? null;
  }, [store, getBookingOfferFromState]);

  const getBookingOfferServicePriceItemFromState = useCallback(
    (serviceIndex: number, index: number): Nullable<BookingServicePriceItemView> => {
      return getBookingOfferServicesFromState()?.[serviceIndex]?.priceItems?.[index] ?? null;
    },
    [store, getBookingOfferServicesFromState]
  );

  const getServicesValidationFromState = useCallback((): ValidationCollectionResult<BookingOfferServiceView> => {
    const state = store.getState();
    return bookingOfferServiceManageValidationServicesResultsSelector(state);
  }, [store]);

  const onChangeModified = useCallback(
    (modified: boolean) => {
      dispatch(bookingOfferServiceManageSetModified(modified));
    },
    [dispatch]
  );

  const onClose = useCallback(() => {
    onChangeDialogState('serviceManage', null);
  }, [onChangeDialogState]);

  // Сервисы - валидация элементов списка
  const onValidateServices = useCallback(
    (
      services: Nullable<BookingOfferServiceView[]>,
      rules: ValidationRules<BookingOfferServiceView>,
      priceItemsRules: ValidationRules<BookingServicePriceItemValidateView>,
      save: boolean
    ): boolean => {
      let hasErrors = false;
      const results =
        services?.map((service, serviceIndex) => {
          const validateObjectResult = validateObject(service, rules);
          if (!validateObjectResult.isValid) {
            hasErrors = true;
          }

          const priceItemsResults =
            service.priceItems?.map(object => {
              const validateObjectResult = validateObject({ ...object, service }, priceItemsRules);
              if (!validateObjectResult.isValid) {
                hasErrors = true;
              }
              return validateObjectResult.results;
            }) ?? [];

          if (save) {
            dispatch(
              bookingOfferServiceManageSetServicePriceItemsValidation({
                serviceIndex,
                results: priceItemsResults,
              })
            );
          }

          return validateObjectResult.results;
        }) ?? [];

      if (save) {
        dispatch(bookingOfferServiceManageSetServicesValidation(results));
      }

      return !hasErrors;
    },
    [dispatch]
  );

  const onSave = useCallback(async () => {
    const bookingOffer = getBookingOfferFromState();

    if (!bookingOffer) {
      return Promise.resolve(null);
    }

    dispatch(bookingOfferServiceManageClearAllValidations());

    const rules = getBookingOfferValidationRulesForSave(bookingOffer);

    const isValidServices = onValidateServices(bookingOffer.services, rules.service, rules.priceItem, true);

    if (isValidServices && bookingOffer.id) {
      const response = await onSaveServices(bookingOffer.id, bookingOffer.services);

      if (response) {
        if (!response?.errors.length) {
          onClose();
        } else {
          showBookingOfferErrorNotification(response.errors);
        }
      }
    } else {
      showBookingOfferInvalidNotification(bookingOffer);
    }
  }, [onSaveServices, onChangeDialogState]);

  const onAttributeValidate = useCallback<BookingOfferServiceManageHandlersContextValue['onAttributeValidate']>(
    name => {
      const bookingOffer = getBookingOfferFromState();

      //не проверяем пустые значения
      if (!bookingOffer?.[name]) {
        return;
      }

      const rules = { [name]: bookingOfferBusinessValidationRules[name] ?? {} };
      const results = validateObject(bookingOffer, rules).results?.[name] ?? null;
      dispatch(bookingOfferCreateSetAttributeValidation({ name, results }));
    },
    [dispatch, getBookingOfferFromState]
  );

  const onServicesRepeatValidate = useCallback(() => {
    const services = getBookingOfferServicesFromState();
    if (services) {
      const servicesValidationFromState = getServicesValidationFromState();

      const isServicesHasSavedError = servicesValidationFromState?.some(
        v => v && Object.values(v)?.some(v => v?.hasError)
      );
      if (isServicesHasSavedError) {
        onValidateServices(
          services,
          bookingOfferServiceBusinessValidationRules,
          bookingOfferPriceItemBusinessValidationRules,
          true
        );
        onAttributeValidate('services');
      }
    }
  }, [dispatch, getServicesValidationFromState, onAttributeValidate, getBookingOfferServicesFromState]);

  // price item
  const onChangeServicePriceItemAttribute = useCallback<
    BookingOfferServiceManageHandlersContextValue['onChangeServicePriceItemAttribute']
  >(
    (serviceIndex, index, name, value) => {
      const priceItem = getBookingOfferServicePriceItemFromState(serviceIndex, index);
      if (!priceItem) {
        return;
      }

      dispatch(bookingOfferServiceManageSetServicePriceItemAttribute({ serviceIndex, index, name, value }));
      onServicesRepeatValidate();
      onChangeModified(true);
    },
    [dispatch, onServicesRepeatValidate]
  );

  const onServicePriceItemAttributeValidate = useCallback<
    BookingOfferServiceManageHandlersContextValue['onServicePriceItemAttributeValidate']
  >(
    (serviceIndex, index, name) => {
      const priceItem = getBookingOfferServicePriceItemFromState(serviceIndex, index);
      if (priceItem) {
        //не проверяем пустые значения
        if (!priceItem?.[name]) {
          return;
        }

        const rules = { [name]: bookingOfferPriceItemBusinessValidationRules[name] ?? {} };
        const results = validateObject(priceItem, rules).results?.[name] ?? null;
        dispatch(
          bookingOfferServiceManageServicePriceItemSetAttributeValidation({
            serviceIndex,
            index,
            name,
            results,
          })
        );
      }
    },
    [dispatch, getBookingOfferServicePriceItemFromState]
  );

  // service
  const onServiceAttributeValidate = useCallback<
    BookingOfferServiceManageHandlersContextValue['onServiceAttributeValidate']
  >(
    (index, name) => {
      const service = getBookingOfferServicesFromState()?.[index];
      if (service) {
        //не проверяем пустые значения
        if (!service?.[name]) {
          return;
        }

        const rules = { [name]: bookingOfferServiceBusinessValidationRules[name] ?? {} };
        const results = validateObject(service, rules).results?.[name] ?? null;
        dispatch(bookingOfferServiceManageServiceSetAttributeValidation({ index, name, results }));
      }
    },
    [dispatch, getBookingOfferServicesFromState]
  );

  const onChangeServiceAttribute = useCallback<
    BookingOfferServiceManageHandlersContextValue['onChangeServiceAttribute']
  >(
    (index, name, value) => {
      let service = getBookingOfferServicesFromState()?.[index];
      if (!service) {
        return;
      }
      service = getBookingOfferServicesFromState()?.[index];
      if (service) {
        dispatch(bookingOfferServiceManageClearServiceAttributeValidation({ index, name }));
        dispatch(bookingOfferServiceManageSetServiceAttribute({ index, name, value }));
        switch (name) {
          case 'category':
            const category = value as BookingOfferServiceView['category'];
            const types = category?.orderByDateTypes ?? [];
            const orderByDateType = service.orderByDateType;
            if (!orderByDateType || !types.includes(orderByDateType)) {
              dispatch(
                bookingOfferServiceManageSetServiceAttribute({
                  index,
                  name: 'orderByDateType',
                  value: types.length === 1 ? types[0] : null,
                })
              );
              const priceItems = [...(service?.priceItems ?? [])].map(priceItem => ({
                ...priceItem,
                unit: null,
              }));
              if (priceItems.length) {
                dispatch(
                  bookingOfferServiceManageSetServiceAttribute({
                    index,
                    name: 'priceItems',
                    value: priceItems,
                  })
                );
              }
            }
            break;
          case 'orderByDateType':
            const priceItems = [...(service?.priceItems ?? [])].map(priceItem => ({
              ...priceItem,
              unit: null,
            }));
            if (priceItems.length) {
              dispatch(
                bookingOfferCreateSetServiceAttribute({
                  index,
                  name: 'priceItems',
                  value: priceItems,
                })
              );
            }
            break;
          case 'status':
          default:
            break;
        }
        onChangeModified(true);
      }
    },
    [dispatch, getBookingOfferServicesFromState]
  );

  const value: BookingOfferServiceManageHandlersContextValue = {
    onSave,
    onClose,
    onAttributeValidate,

    onChangeServicePriceItemAttribute,
    onServicePriceItemAttributeValidate,
    onChangeServiceAttribute,
    onServiceAttributeValidate,
  };

  return (
    <BookingOfferServiceManageHandlersContext.Provider value={value}>
      {children}
    </BookingOfferServiceManageHandlersContext.Provider>
  );
};
