import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from './index';

const CorpOfferTableCellLastStatusAuthor = (props: CorpOfferTableCellCommonProps) => {
  const {
    corpOffer: { lastStatusAuthor },
  } = props;

  const value = lastStatusAuthor?.name ?? '-';

  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellLastStatusAuthor;
