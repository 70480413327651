import { ClientOrg } from '@/domain/model/clientOrg';
import { UUID } from '@/domain/model/types';
import rootRouting from '@/routing';
import { ClientOrgUserActionsProvider } from '@features/user/userClientOrg/actions/provider';
import ClientOrgManagementUsersScreen from '@screens/clientOrgManagement/users';
import NotFoundScreen from '@screens/notFound';
import { Location } from 'history';
import { Redirect, Switch, useParams } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ClientOrgsLocationState, EClientOrgUrlParam } from '../entry';
import ClientOrgManagementContainer from './container';
import { EClientOrgUserTableTab } from '@features/user/userClientOrg/types';
import ClientOrgManagementDetailsScreen from '@screens/clientOrgManagement/details';

const rootPath = rootRouting.clientOrgs;

const routing = {
  root: `${rootPath}/:id/management`,
  users: `${rootPath}/:id/management/users`,
  details: `${rootPath}/:id/management/details`,
};

export interface ClientOrgUsersLocationState {
  readonly guid: UUID;
}

export interface ClientOrgDetailsLocationState {
  readonly guid: UUID;
}

interface GetClientOrgManagementUsersRouteProps {
  readonly id: UUID;
  readonly tab?: EClientOrgUserTableTab;
  readonly guid?: UUID;
}

interface GetClientOrgManagementDetailsRouteProps {
  readonly id: UUID;
  readonly guid?: UUID;
}

interface GetClientOrgManagementRootRouteProps {
  readonly id: UUID;
  readonly tab?: EClientOrgUserTableTab;
  readonly guid?: UUID;
}

export const getClientOrgManagementRootRouteDefinition = () => routing.root;

export const getClientOrgManagementRootRoute = (
  props: GetClientOrgManagementRootRouteProps
): Location<ClientOrgsLocationState> => {
  const { id, guid } = props;

  const searchParams = new URLSearchParams();

  const newGuid = guid ?? uuidv4();

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: `${routing.root.replace(':id', id)}`,
    search,
    state: {
      guid: newGuid,
    },
    hash: '',
    key: '',
  };
};

export const getClientOrgManagementDetailsRoute = (
  props: GetClientOrgManagementDetailsRouteProps
): Location<ClientOrgDetailsLocationState> => {
  const { id, guid } = props;

  const searchParams = new URLSearchParams();

  const newGuid = guid ?? uuidv4();

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: `${routing.details.replace(':id', id)}`,
    search,
    state: {
      guid: newGuid,
    },
    hash: '',
    key: '',
  };
};

export const getClientOrgManagementUsersRoute = (
  props: GetClientOrgManagementUsersRouteProps
): Location<ClientOrgUsersLocationState> => {
  const { id, tab, guid } = props;

  const searchParams = new URLSearchParams();
  if (tab) searchParams.append(EClientOrgUrlParam.Tab, tab);

  const newGuid = guid ?? uuidv4();

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: `${routing.users.replace(':id', id)}`,
    search,
    state: {
      guid: newGuid,
    },
    hash: '',
    key: '',
  };
};

interface ClientOrgManagementEntryProps {
  readonly clientOrg: ClientOrg;
}

const ClientOrgManagementEntryInternal = ({ clientOrg }: ClientOrgManagementEntryProps) => {
  return (
    <ClientOrgUserActionsProvider>
      <Switch>
        <Route
          exact
          path={routing.users}
          render={() => <ClientOrgManagementUsersScreen clientOrg={clientOrg} />}
        />
        <Route
          exact
          path={routing.details}
          render={() => <ClientOrgManagementDetailsScreen clientOrg={clientOrg} />}
        />
        <Redirect
          from={routing.root}
          to={routing.users}
        />
        <Route component={NotFoundScreen} />
      </Switch>
    </ClientOrgUserActionsProvider>
  );
};

const ClientOrgManagementEntry = () => {
  const { id } = useParams<{ id: UUID }>();

  return (
    <ClientOrgManagementContainer id={id}>
      {clientOrg => <ClientOrgManagementEntryInternal clientOrg={clientOrg} />}
    </ClientOrgManagementContainer>
  );
};

export default ClientOrgManagementEntry;
