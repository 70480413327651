import { CorpOfferCategoriesEditDialogAddAdapter } from './dialogAdd';
import { CorpOfferCategoriesEditDialogDeleteAdapter } from './dialogDelete';
import { CorpOfferCategoriesEditDialogEditAdapter } from './dialogEdit';

export const CorpOfferCategoriesEditDialogsAdapter = () => {
  return (
    <>
      <CorpOfferCategoriesEditDialogAddAdapter />
      <CorpOfferCategoriesEditDialogEditAdapter />
      <CorpOfferCategoriesEditDialogDeleteAdapter />
    </>
  );
};
