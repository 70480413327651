import { useSelector } from 'react-redux';
import ClientOrgDetailsContent from '../content';
import { ContentWrapper } from '../controls';
import { clientOrgDetailsClientOrgSelector } from '../store/selectors';

export const ClientOrgDetailsContentAdapter = () => {
  const clientOrg = useSelector(clientOrgDetailsClientOrgSelector);

  if (!clientOrg) {
    return null;
  }

  return (
    <ContentWrapper>
      <ClientOrgDetailsContent clientOrg={clientOrg} />
    </ContentWrapper>
  );
};
