import axios, { AxiosResponse } from 'axios';
import { OfferFieldAudit, OfferState } from '../../domain/model';
import {
  BookingOffer,
  BookingOfferCreateRequest,
  BookingOfferService,
  BookingOfferServiceCreateRequest,
  BookingOfferServiceUpdateRequest,
  BookingOfferShort,
  BookingOfferUpdateRequest,
  BookingServicePriceItem,
  BookingServicePriceItemCreateRequest,
  BookingServicePriceItemUpdateRequest,
} from '../../domain/model/booking';
import { EBookingOfferActionDiscriminator, EBookingOfferDiscriminator } from '../../domain/model/enums';
import { Nullable, UUID } from '../../domain/model/types';
import { createCancelToken } from './index';
import {
  ApiCancellable,
  ApiQueryDsl,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
  ApiResponseMaBeCounters,
} from './types';
import { appendQueryDslParams, getComServicesEndpoint } from './utils';

type AllOfferProps<D extends ApiRequestListDiscriminator> = ApiRequestPageable &
  ApiCancellable & {
    readonly discriminator?: D;
    readonly query?: Nullable<string>;
    readonly viewed?: Nullable<boolean>;
    readonly addressObjectId?: Nullable<UUID>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

type AloneIdProps = ApiCancellable & {
  readonly id: UUID;
};

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type DeleteProps = {
  readonly id: UUID;
};

type OfferCreateProps = {
  readonly data: BookingOfferCreateRequest;
};

type OfferSaveProps = {
  readonly id: UUID;
  readonly data: BookingOfferUpdateRequest;
};

type BookingServiceCreateProps = {
  readonly data: BookingOfferServiceCreateRequest;
};

type BookingServiceUpdateProps = {
  readonly id: UUID;
  readonly data: BookingOfferServiceUpdateRequest;
};

type ServicePriceItemCreateProps = {
  readonly data: BookingServicePriceItemCreateRequest;
};

type ServicePriceItemUpdateProps = {
  readonly id: UUID;
  readonly data: BookingServicePriceItemUpdateRequest;
};

export type BookingOfferPauseCommand = {
  readonly discriminator: EBookingOfferActionDiscriminator.OfferPauseCommand;
  readonly comment: Nullable<string>;
};

export type BookingOfferNewVersionCommand = BookingOfferUpdateRequest & {
  readonly discriminator: EBookingOfferActionDiscriminator.NewVersionBookingOfferCommand;
};

export type BookingOfferAssignModeratorCommand = {
  readonly discriminator: EBookingOfferActionDiscriminator.OfferAssignModeratorCommand;
  readonly user: {
    readonly id: UUID; // ID пользователя
  };
};

export type BookingOfferRejectCommand = {
  readonly discriminator: EBookingOfferActionDiscriminator.OfferRejectCommand;
  readonly rejectionType: {
    readonly id: UUID; // Id причины отклонения
  };
  readonly comment: string;
};

export type BookingOfferAction =
  | {
      discriminator: EBookingOfferActionDiscriminator;
    }
  | BookingOfferAssignModeratorCommand
  | BookingOfferRejectCommand
  | BookingOfferPauseCommand
  | BookingOfferNewVersionCommand;

type ActionProps = AloneIdProps & BookingOfferAction;

type HistoryProps = ApiRequestPageable &
  ApiCancellable & {
    readonly id: UUID;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

type BookingOfferApi = {
  readonly all: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllOfferProps<D>
  ) => Promise<AxiosResponse<ApiResponseMaBeCounters<D, BookingOfferShort[]>>>;
  readonly one: (props: OneProps) => Promise<AxiosResponse<BookingOffer>>;
  readonly createDraft: (props: OfferCreateProps) => Promise<AxiosResponse<BookingOffer>>;
  readonly updateDraft: (props: OfferSaveProps) => Promise<AxiosResponse<BookingOffer>>;
  readonly update: (props: OfferSaveProps) => Promise<AxiosResponse<BookingOffer>>;
  readonly action: (props: ActionProps) => Promise<AxiosResponse<BookingOffer>>;
  readonly duplicate: (props: AloneIdProps) => Promise<AxiosResponse<BookingOffer>>;
  readonly delete: (props: DeleteProps) => Promise<AxiosResponse>;
  readonly history: (props: HistoryProps) => Promise<AxiosResponse<OfferState[]>>;
  readonly fieldHistory: (props: HistoryProps) => Promise<AxiosResponse<OfferFieldAudit[]>>;
};

type BookingServiceApi = {
  readonly one: (props: OneProps) => Promise<AxiosResponse<BookingOfferService>>;
  readonly create: (props: BookingServiceCreateProps) => Promise<AxiosResponse<BookingOfferService>>;
  readonly update: (props: BookingServiceUpdateProps) => Promise<AxiosResponse<BookingOfferService>>;
  readonly delete: (props: DeleteProps) => Promise<AxiosResponse>;
};

type ServicePriceItemApi = {
  readonly one: (props: OneProps) => Promise<AxiosResponse<BookingServicePriceItem>>;
  readonly create: (props: ServicePriceItemCreateProps) => Promise<AxiosResponse<BookingServicePriceItem>>;
  readonly update: (props: ServicePriceItemUpdateProps) => Promise<AxiosResponse<BookingServicePriceItem>>;
  readonly delete: (props: DeleteProps) => Promise<AxiosResponse>;
};

type BookingApi = {
  readonly offer: BookingOfferApi;
  readonly service: BookingServiceApi;
  readonly priceItem: ServicePriceItemApi;
};

const booking: BookingApi = {
  offer: {
    all: ({ page, pageSize, sort, viewed, querydsl, query, addressObjectId, discriminator, signal }) => {
      const params = new URLSearchParams();

      if (sort) {
        if (typeof sort === 'string') {
          params.append('sort', sort);
        } else {
          sort.forEach(item => params.append('sort', item));
        }
      }

      params.append('page', (page - 1).toString(10));
      params.append('size', pageSize.toString(10));

      if (addressObjectId) {
        params.append('addressObjectId', addressObjectId);
      }

      if (viewed !== null && viewed !== undefined) {
        params.append('viewed', viewed.toString());
      }

      if (query) {
        params.append('q', query);
      }

      if (discriminator) {
        params.append('resultType', discriminator);
      }

      if (querydsl) {
        appendQueryDslParams(params, querydsl);
      }

      return axios.get(`${getComServicesEndpoint()}/booking-offers`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    one: ({ id, signal }) => {
      return axios.get(`${getComServicesEndpoint()}/booking-offers/${id}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    createDraft: ({ data }) => {
      return axios.post(`${getComServicesEndpoint()}/booking-offers`, {
        ...data,
        discriminator: EBookingOfferDiscriminator.NewBookingOfferRequest,
      });
    },
    updateDraft: ({ id, data }) => {
      return axios.put(`${getComServicesEndpoint()}/booking-offers/${id}`, {
        ...data,
        discriminator: EBookingOfferDiscriminator.BookingOfferDraftUpdateRequest,
      });
    },
    update: ({ id, data }) => {
      return axios.put(`${getComServicesEndpoint()}/booking-offers/${id}`, {
        ...data,
        discriminator: EBookingOfferDiscriminator.ValidBookingOfferRequest,
      });
    },
    action: ({ id, signal, ...data }) => {
      return axios.post(`${getComServicesEndpoint()}/booking-offers/${id}`, data, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    duplicate: ({ id }) => {
      return axios.post(`${getComServicesEndpoint()}/booking-offers`, {
        id,
        discriminator: EBookingOfferDiscriminator.BookingOfferCopyCommand,
      });
    },
    delete: ({ id }) => {
      return axios.delete(`${getComServicesEndpoint()}/booking-offers/${id}`);
    },
    history: ({ id, querydsl, page, pageSize, sort, signal }) => {
      const params = new URLSearchParams();

      if (sort) {
        if (typeof sort === 'string') {
          params.append('sort', sort);
        } else {
          sort.forEach(item => params.append('sort', item));
        }
      }

      params.append('page', (page - 1).toString(10));
      params.append('size', pageSize.toString(10));

      if (querydsl) {
        appendQueryDslParams(params, querydsl);
      }

      return axios.get(`${getComServicesEndpoint()}/booking-offers/${id}/history`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    fieldHistory: ({ id, querydsl, page, pageSize, sort, signal }) => {
      const params = new URLSearchParams();

      if (sort) {
        if (typeof sort === 'string') {
          params.append('sort', sort);
        } else {
          sort.forEach(item => params.append('sort', item));
        }
      }

      params.append('page', (page - 1).toString(10));
      params.append('size', pageSize.toString(10));

      if (querydsl) {
        appendQueryDslParams(params, querydsl);
      }

      return axios.get(`${getComServicesEndpoint()}/booking-offers/${id}/field-history`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
  },
  service: {
    one: ({ id, signal }) => {
      return axios.get(`${getComServicesEndpoint()}/booking-services/${id}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    create: ({ data }) => {
      return axios.post(`${getComServicesEndpoint()}/booking-services`, data);
    },
    update: ({ id, data }) => {
      return axios.put(`${getComServicesEndpoint()}/booking-services/${id}`, data);
    },
    delete: ({ id }) => {
      return axios.delete(`${getComServicesEndpoint()}/booking-services/${id}`);
    },
  },
  priceItem: {
    one: ({ id, signal }) => {
      return axios.get(`${getComServicesEndpoint()}/booking-price-items/${id}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    create: ({ data }) => {
      return axios.post(`${getComServicesEndpoint()}/booking-price-items`, data);
    },
    update: ({ id, data }) => {
      return axios.put(`${getComServicesEndpoint()}/booking-price-items/${id}`, data);
    },
    delete: ({ id }) => {
      return axios.delete(`${getComServicesEndpoint()}/booking-price-items/${id}`);
    },
  },
};

export default booking;
