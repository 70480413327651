import AnalyticsQueriesTableCell, { AnalyticsQueriesTableCellCommonProps } from './index';

const AnalyticsQueriesTableCellCount = (props: AnalyticsQueriesTableCellCommonProps) => {
  const {
    query: { count: value },
  } = props;

  return (
    <AnalyticsQueriesTableCell
      {...props}
      value={value}
    />
  );
};

export default AnalyticsQueriesTableCellCount;
