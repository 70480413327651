import { Card as MuiCard, Skeleton } from '@mui/material';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: fixed;

  padding: ${p => p.theme.spacing(4)} ${p => p.theme.spacing(5)};

  border: 1px dashed ${p => p.theme.palette.secondary.main};
  border-radius: ${p => (p.theme.shape.borderRadius as number) * 3}px;
`;

export const Card = styled(MuiCard)`
  width: 100%;

  user-select: none;

  &.MuiCard-root {
    overflow: visible;
  }

  & .MuiCardContent-root:last-child {
    padding: ${p => p.theme.spacing(1)} 0 0 0;
  }
`;

export const CardLabel = styled.div`
  position: absolute;
  top: calc(-${p => p.theme.typography.body2.lineHeight} / 2);
  left: ${p => p.theme.spacing(2.5)};

  padding: 0 ${p => p.theme.spacing(0.5)};
  background-color: ${p => p.theme.palette.background.default};

  & .MuiTypography-root {
    font-weight: ${p => p.theme.typography.fontWeightSemiBold};
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const LogoWrapper = styled.div`
  width: 3.2rem;
  height: 3rem;

  position: relative;

  overflow: hidden;

  img {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    object-fit: cover;
    object-position: center center;
  }
`;

export const LogoSkeleton = styled(Skeleton)`
  transform: none;
`;
