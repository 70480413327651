import { Typography } from '@mui/material';
import { FC } from 'react';
import { ButtonLink } from '../../../../../components/common/buttons/link';
import { Wrapper } from './controls';

interface AddressManualInputActionElementProps {
  readonly onAction: () => void;
}

export const AddressManualInputActionElement: FC<AddressManualInputActionElementProps> = props => {
  const { onAction } = props;

  return (
    <Wrapper onMouseDown={e => e.preventDefault()}>
      <Typography>Нет правильного варианта?</Typography>
      <ButtonLink
        onClick={e => {
          e.currentTarget.focus();
          onAction();
        }}
      >
        Указать вручную
      </ButtonLink>
    </Wrapper>
  );
};
