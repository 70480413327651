import { useMemo } from 'react';
import { TeamAvatar } from '../../../../components/common/avatars';
import { TeamTableCellCommonProps } from './index';

const TeamTableCellLogo = (props: TeamTableCellCommonProps) => {
  const { team: value } = props;

  return useMemo(
    () => (
      <TeamAvatar
        size='small'
        team={value}
      />
    ),
    [value]
  );
};

export default TeamTableCellLogo;
