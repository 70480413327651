import { Chip } from '@mui/material';
import { ChipLabel } from './controls';

export interface DataFilterViewItemProps {
  readonly elementRef?: any;
  readonly label: any;
  readonly icon?: any;
  readonly onClick?: () => void;
  readonly onDelete?: () => void;
}

const DataFilterViewCommonItem = ({ elementRef, label, icon, onDelete, onClick }: DataFilterViewItemProps) => {
  return (
    <Chip
      ref={elementRef}
      color='primary'
      label={<ChipLabel variant='body1'>{label}</ChipLabel>}
      deleteIcon={icon}
      onDelete={onDelete}
      onClick={onClick}
    />
  );
};

export default DataFilterViewCommonItem;
