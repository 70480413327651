import { useSelector } from 'react-redux';
import { EClientOrgStep } from '../../types';
import { StepWrapper } from '../controls';
import { useContextParams } from '../hooks/useContextParams';
import ClientOrgCreateStepGeneral from '../steps/general';
import { clientOrgCreateClientOrgIsPresentSelector } from '../store/selectors';

const currentStep = EClientOrgStep.General;

export const ClientOrgCreateStepGeneralAdapter = () => {
  const { step } = useContextParams();

  const clientOrgIsPresent = useSelector(clientOrgCreateClientOrgIsPresentSelector);

  if (!clientOrgIsPresent) {
    return null;
  }

  return step === currentStep ? (
    <StepWrapper>
      <ClientOrgCreateStepGeneral />
    </StepWrapper>
  ) : null;
};
