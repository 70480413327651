import { useEffect, useState } from 'react';
import { EPartnerOwnershipType } from '../../../../../domain/model/enums';
import { PartnerDataDraft } from '../../../../../domain/model/partner';
import { Nullable } from '../../../../../domain/model/types';
import useValidation from '../../../../hooks/validation/useValidation';
import { ValidationResult } from '../../../../utils/validation';
import {
  partnerEditGeneralCompanyValidation,
  partnerEditGeneralIndividualValidation,
  PartnerGeneralCompany,
  PartnerGeneralIndividual,
} from '../validation';

interface UsePartnerGeneralValidationProps {
  readonly object: Nullable<PartnerDataDraft>;
  readonly validateOnChange?: boolean;
}

type UsePartnerGeneralValidation = {
  readonly isValid: boolean;
  readonly validationResultCompany: Nullable<ValidationResult<PartnerGeneralCompany>>;
  readonly validationResultIndividual: Nullable<ValidationResult<PartnerGeneralIndividual>>;
  readonly validate: () => boolean;
};

const getPartnerGeneralCompanyByPartner = (partner: Nullable<PartnerDataDraft>): Nullable<PartnerGeneralCompany> =>
  partner?.regInfo?.orgType && partner?.regInfo.orgType !== EPartnerOwnershipType.IndividualEntrepreneur
    ? {
        ...partner,
        companyName: partner.regInfo.companyName,
      }
    : null;

const getPartnerGeneralIndividualByPartner = (
  partner: Nullable<PartnerDataDraft>
): Nullable<PartnerGeneralIndividual> =>
  partner?.regInfo?.orgType === EPartnerOwnershipType.IndividualEntrepreneur ? partner : null;

function usePartnerGeneralValidation(props: UsePartnerGeneralValidationProps): UsePartnerGeneralValidation {
  const { object, validateOnChange = false } = props;

  const [partnerGeneralCompany, setPartnerGeneralCompany] = useState<Nullable<PartnerGeneralCompany>>(null);
  const [partnerGeneralIndividual, setPartnerGeneralIndividual] = useState<Nullable<PartnerGeneralIndividual>>(null);

  const {
    validationResult: validationResultCompany,
    isValid: isValidGeneralCompany,
    validate: validateGeneralCompany,
  } = useValidation<PartnerGeneralCompany>({
    object: partnerGeneralCompany,
    validateOnChange,
    rules: partnerEditGeneralCompanyValidation,
  });

  const {
    validationResult: validationResultIndividual,
    isValid: isValidGeneralIndividual,
    validate: validateGeneralIndividual,
  } = useValidation<PartnerGeneralIndividual>({
    object: partnerGeneralIndividual,
    validateOnChange,
    rules: partnerEditGeneralIndividualValidation,
  });

  useEffect(() => setPartnerGeneralCompany(getPartnerGeneralCompanyByPartner(object)), [object]);
  useEffect(() => setPartnerGeneralIndividual(getPartnerGeneralIndividualByPartner(object)), [object]);

  return {
    validationResultCompany,
    validationResultIndividual,
    isValid: isValidGeneralCompany && isValidGeneralIndividual,
    validate: () => validateGeneralCompany() && validateGeneralIndividual(),
  };
}

export default usePartnerGeneralValidation;
