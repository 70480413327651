import DataFilterEdit from '@components/common/dataFilter/edit';
import ReportDialogLayout from '@layouts/reportDialog';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getDataFilterValuesByStrategies } from '@/presentation/utils/filtering';
import { defaultDatePeriod, reportDownloadURL, validateDatePeriod } from '@features/report/utils';
import reportServices from '@features/report/services';
import Notifier from '@/system/notifier';
import { ENoticeStatus } from '@/domain';
import {
  EReportPartnersFilterItem,
  getReportPartnersFilterStrategies,
  ReportPartnersFilterEditStrategy,
  ReportPartnersFilterValues,
} from './filters';

type PartnersReportProps = {
  readonly onClose: () => void;
};

const defaultFilterValue: ReportPartnersFilterValues = {
  [EReportPartnersFilterItem.DatePeriod]: {
    value: defaultDatePeriod,
  },
};

export const PartnersReportContainer = (props: PartnersReportProps) => {
  const { onClose } = props;
  const [error, setError] = useState<Nullable<string>>(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const [filter, setFilter] = useState<ReportPartnersFilterValues>(defaultFilterValue);

  const resetValidation = useCallback(() => {
    setError(null);
  }, []);

  const filterStrategies = useMemo(() => getReportPartnersFilterStrategies(filter), [filter]);

  const onChangeStrategies = useCallback(
    (strategies: ReportPartnersFilterEditStrategy[]) => {
      setFilter(getDataFilterValuesByStrategies(strategies));
    },
    [setFilter]
  );

  const onResetFilter = useCallback(() => {
    setFilter(defaultFilterValue);
  }, [setFilter]);

  const handleDownload = () => {
    resetValidation();
    // готовим параметры
    const startDate = filter[EReportPartnersFilterItem.DatePeriod]?.value?.[0];
    const endDate = filter[EReportPartnersFilterItem.DatePeriod]?.value?.[1];

    const isValid = validateDatePeriod(startDate, endDate);

    if (isValid) {
      setIsDownloading(true);
      reportServices.mp
        .partners({ startDate, endDate })
        .then(result => {
          reportDownloadURL(result);
          onClose();
        })
        .catch(error => Notifier.getInstance().addNotice(ENoticeStatus.Error, `${error.message}`))
        .finally(() => setIsDownloading(false));
    } else {
      setError('Заполните корректно поля даты');
    }
  };

  useEffect(() => {
    resetValidation();
  }, [filter]);

  return (
    <ReportDialogLayout
      title='Партнеры'
      filter={
        <DataFilterEdit
          strategies={filterStrategies}
          onChange={onChangeStrategies}
        />
      }
      error={error}
      isFetching={isDownloading}
      onFetch={handleDownload}
      onClearFilter={onResetFilter}
      onClose={onClose}
    />
  );
};
