import React from 'react';
import EntityMasterPreviewWrapper from '../../../../components/common/entityMaster/preview/wrapper';

interface BannerPreviewWrapperProps {
  readonly label: string;
  readonly children: React.ReactNode;
}

const BannerPreviewWrapper = (props: BannerPreviewWrapperProps) => {
  const { label, children } = props;

  return (
    <EntityMasterPreviewWrapper
      onlyDesktop
      label={label}
    >
      {({ mode }) => {
        return children;
      }}
    </EntityMasterPreviewWrapper>
  );
};

export default BannerPreviewWrapper;
