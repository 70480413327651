import { ServerErrorResponse } from '@/data/network/types';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { EUserStatus, MpCustomer, Nullable, Pageable, UUID } from '@/domain';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { PaginationSize } from '../../../../types';
import customerService from '../../services';
import { AllProps } from '../../services/common';
import { CustomerActionTableType, ECustomerActionType } from '../../types';
import { CustomersFilterValues } from '../filterUtils';
import { CustomerTableTabsCounter, ECustomerTableColumn, ECustomerTableTab } from '../utils';

export type CustomersFetchProps = Omit<AllProps, 'signal'>;

export type CustomersCountsFetchProps = CustomersFetchProps & {
  readonly tabs: ECustomerTableTab[];
};

const defaultSort = `${ECustomerTableColumn.Name},asc`;

const getActionProcess = (state: CustomerListState, id: UUID, type: CustomerActionTableType) => {
  let process = state.actions.find(process => process.id === id);
  if (process) return process;

  process = {
    ...fetchableDefault,
    id,
    type,
    error: null,
  };
  state.actions.push(process);

  return process;
};

export const customersFetch = createAsyncThunk<Pageable<MpCustomer>, CustomersFetchProps, AppThunkAPIConfig>(
  'customer/list/fetch',
  async (payload, { rejectWithValue, signal }) => {
    try {
      return await customerService.common.all({...payload, signal});
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const customersCountsFetch = createAsyncThunk<
  CustomerTableTabsCounter,
  CustomersCountsFetchProps,
  AppThunkAPIConfig
>('customer/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await customerService.common.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

export const customersActivateCustomer = createAsyncThunk<void, { id: UUID }, AppThunkAPIConfig<ServerErrorResponse>>(
  'customers/list/activateCustomer',
  async ({ id }, { rejectWithValue }) => {
    try {
      await Api.user.mp.changeStatus({ id, status: EUserStatus.Enabled });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const customersDeactivateCustomer = createAsyncThunk<void, { id: UUID }, AppThunkAPIConfig<ServerErrorResponse>>(
  'customers/list/deactivateCustomer',
  async ({ id }, { rejectWithValue }) => {
    try {
      await Api.user.mp.changeStatus({ id, status: EUserStatus.Disabled });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface CustomerListState extends Fetchable, Pageable<MpCustomer> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
  };
  readonly tab: ECustomerTableTab;
  readonly tabsCounter: CustomerTableTabsCounter;
  readonly filter: CustomersFilterValues;
  readonly actions: (Fetchable & {
    id: UUID;
    type: CustomerActionTableType;
    error: Nullable<ServerErrorResponse>;
  })[];
}

type Reducer<T = undefined> = CaseReducer<CustomerListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CustomerListState> {
  customersStartSession: Reducer<{ guid: UUID }>;
  customersSetTab: Reducer<{ tab: ECustomerTableTab }>;
  customersSortReset: Reducer;
  customersDataReset: Reducer;
  customersSetFilter: Reducer<CustomersFilterValues>;
  customersSetPage: Reducer<{ pageNumber: number }>;
  customersSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  customersSetSort: Reducer<{ sort: string }>;
  customersNeedRefreshWatcherReset: Reducer;
}

const slice = createSlice<CustomerListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    ...fetchableDefault,
    guid: null,
    data: [],
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    needRefreshWatcher: 0,
    search: {
      sort: defaultSort,
      pageSize: 10,
    },
    tab: ECustomerTableTab.Active,
    filter: {},
    tabsCounter: {},
    actions: [],
  },
  reducers: {
    customersStartSession: (state, { payload }) => {
      const { guid } = payload;
      if (state.guid !== guid) {
        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = false;
        state.guid = guid;
        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;
        state.needRefreshWatcher = 0;
        state.search = {
          sort: defaultSort,
          pageSize: 10,
        };
        state.tab = ECustomerTableTab.Active;
        state.filter = {};
        state.tabsCounter = {};
        state.actions = [];
      }
    },
    customersSetTab: (state, { payload }) => {
      const { tab } = payload;
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    customersSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    customersSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    customersSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    customersSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    customersSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    customersDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
    },
    customersNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(customersFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
      })
      .addCase(customersFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(customersFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(customersCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(customersCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      })
      .addCase(customersActivateCustomer.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECustomerActionType.Activate;

        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(customersActivateCustomer.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECustomerActionType.Activate;

        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(customersActivateCustomer.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = ECustomerActionType.Activate;

        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(customersDeactivateCustomer.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECustomerActionType.Deactivate;

        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(customersDeactivateCustomer.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECustomerActionType.Deactivate;

        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(customersDeactivateCustomer.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = ECustomerActionType.Deactivate;

        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      });
  },
});

export const {
  customersStartSession,
  customersSetTab,
  customersSetPage,
  customersSortReset,
  customersSetFilter,
  customersSetPageSize,
  customersSetSort,
  customersNeedRefreshWatcherReset,
  customersDataReset,
} = slice.actions;

export default slice.reducer;
