import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { ServerErrorResponse } from '../../../../../data/network/types';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable } from '../../../../../domain/model';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { MpUser } from '../../../../../domain/model/user';
import { PaginationSize } from '../../../../types';
import { partnerEmployeeService } from '../../services';
import { AllProps } from '../../services/common';
import { EPartnerEmployeeActionType, PartnerEmployeeActionTableType } from '../../types';
import { PartnerEmployeesFilterValues } from '../filterUtils';
import { EPartnerEmployeeTableColumn, EPartnerEmployeeTableTab, PartnerEmployeeTableTabsCounter } from '../utils';

const defaultSort = `${EPartnerEmployeeTableColumn.Name},asc`;

export type PartnerEmployeesAction = Fetchable & {
  id: UUID;
  type: PartnerEmployeeActionTableType;
  error: Nullable<ServerErrorResponse>;
};

const getActionProcess = (state: PartnerEmployeeListState, id: UUID, actionType: PartnerEmployeeActionTableType) => {
  let process = state.actions.find(change => change.id === id);
  if (process) return process;

  process = {
    ...fetchableDefault,
    id,
    type: actionType,
    error: null,
  };
  state.actions.push(process);

  return process;
};

export type PartnerEmployeesFetchProps = Omit<AllProps, 'signal'>;

export const partnerEmployeesFetch = createAsyncThunk<Pageable<MpUser>, PartnerEmployeesFetchProps, AppThunkAPIConfig>(
  'partner/employee/list/fetch',
  async (payload, { rejectWithValue, signal }) => {
    try {
      const response = await partnerEmployeeService.common.all(payload);
      const { pageCount, totalCount, pageNumber } = response;
      return { data: response.data, totalCount, pageCount, pageNumber };
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const partnerEmployeesChangePartnerOwner = createAsyncThunk<
  undefined,
  { partnerId: UUID; userId: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('partner/employee/list/changePartnerOwner', async ({ partnerId, userId }, { rejectWithValue }) => {
  try {
    await partnerEmployeeService.common.changePartnerOwner({ partnerId, userId });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const partnerEmployeesActivateEmployee = createAsyncThunk<
  undefined,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('partner/employee/list/employee/activate', async ({ id }, { rejectWithValue }) => {
  try {
    await partnerEmployeeService.common.enabled({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const partnerEmployeesDeactivateEmployee = createAsyncThunk<
  undefined,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('partner/employee/list/employee/deactivate', async ({ id }, { rejectWithValue }) => {
  try {
    await partnerEmployeeService.common.disabled({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export type PartnerEmployeeCountsFetchProps = PartnerEmployeesFetchProps & {
  readonly tabs: EPartnerEmployeeTableTab[];
};

export const partnerEmployeeCountsFetch = createAsyncThunk<
  PartnerEmployeeTableTabsCounter,
  PartnerEmployeeCountsFetchProps,
  AppThunkAPIConfig
>('partner/employee/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await partnerEmployeeService.common.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface PartnerEmployeeListState extends Fetchable, Pageable<MpUser> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
  };
  readonly tab: Nullable<EPartnerEmployeeTableTab>;
  readonly tabsCounter: PartnerEmployeeTableTabsCounter;
  readonly filter: PartnerEmployeesFilterValues;
  readonly actions: PartnerEmployeesAction[];
  readonly dialogs: {
    readonly changePartnerOwner: Nullable<MpUser>;
  };
}

type Reducer<T = undefined> = CaseReducer<PartnerEmployeeListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PartnerEmployeeListState> {
  partnerEmployeesStartSession: Reducer<{ guid: UUID }>;
  partnerEmployeesSetTab: Reducer<{ tab: EPartnerEmployeeTableTab }>;
  partnerEmployeesSetSearch: Reducer<{ sort: string; pageSize: PaginationSize }>;
  partnerEmployeesSetFilter: Reducer<PartnerEmployeesFilterValues>;
  partnerEmployeesSetPage: Reducer<{ pageNumber: number }>;
  partnerEmployeesSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  partnerEmployeesSetSort: Reducer<{ sort: string }>;
  partnerEmployeesSortReset: Reducer;
  partnerEmployeesActionsReset: Reducer;
  partnerEmployeesDataReset: Reducer;
  partnerEmployeesNeedRefreshWatcherReset: Reducer;
  partnerEmployeesSetDialogState: Reducer<{
    name: keyof PartnerEmployeeListState['dialogs'];
    employee: Nullable<MpUser>;
  }>;
}

const slice = createSlice<PartnerEmployeeListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    ...fetchableDefault,
    guid: null,
    data: [],
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    needRefreshWatcher: 0,
    search: {
      sort: defaultSort,
      pageSize: 10,
    },
    tab: null,
    filter: {},
    tabsCounter: {},
    actions: [],
    dialogs: {
      changePartnerOwner: null,
    },
  },
  reducers: {
    partnerEmployeesStartSession: (state, { payload }) => {
      const { guid } = payload;
      if (guid !== state.guid) {
        state.guid = guid;

        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = false;

        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;
        state.needRefreshWatcher = 0;
        state.search = {
          sort: defaultSort,
          pageSize: 10,
        };
        state.tab = null;
        state.filter = {};
        state.tabsCounter = {};
        state.actions = [];
        state.dialogs = {
          changePartnerOwner: null,
        };
      }
    },
    partnerEmployeesSetTab: (state, { payload }) => {
      const { tab } = payload;
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    partnerEmployeesSetSearch: (state, { payload }) => {
      const { sort, pageSize } = payload;
      state.search = {
        ...state.search,
        sort,
        pageSize,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    partnerEmployeesSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    partnerEmployeesSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    partnerEmployeesSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    partnerEmployeesSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    partnerEmployeesSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    partnerEmployeesActionsReset: state => {
      state.actions = [];
    },
    partnerEmployeesDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
    },
    partnerEmployeesNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
    partnerEmployeesSetDialogState: (state, { payload }) => {
      const { name, employee } = payload;
      state.dialogs[name] = employee;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(partnerEmployeesFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
      })
      .addCase(partnerEmployeesFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(partnerEmployeesFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(partnerEmployeesChangePartnerOwner.pending, (state, { meta }) => {
        const { userId } = meta.arg;

        const actionType = EPartnerEmployeeActionType.ChangeResponsible;
        const process = getActionProcess(state, userId, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = userId;
        process.type = actionType;
        process.error = null;
      })
      .addCase(partnerEmployeesChangePartnerOwner.fulfilled, (state, { meta }) => {
        const { userId } = meta.arg;

        const actionType = EPartnerEmployeeActionType.ChangeResponsible;
        const process = getActionProcess(state, userId, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = userId;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(partnerEmployeesChangePartnerOwner.rejected, (state, { payload, meta }) => {
        const { userId } = meta.arg;

        const actionType = EPartnerEmployeeActionType.ChangeResponsible;
        const process = getActionProcess(state, userId, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = userId;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(partnerEmployeesActivateEmployee.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EPartnerEmployeeActionType.Activate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(partnerEmployeesActivateEmployee.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EPartnerEmployeeActionType.Activate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(partnerEmployeesActivateEmployee.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EPartnerEmployeeActionType.Activate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(partnerEmployeesDeactivateEmployee.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EPartnerEmployeeActionType.Deactivate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(partnerEmployeesDeactivateEmployee.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EPartnerEmployeeActionType.Deactivate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(partnerEmployeesDeactivateEmployee.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EPartnerEmployeeActionType.Deactivate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(partnerEmployeeCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(partnerEmployeeCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      });
  },
});

export const {
  partnerEmployeesStartSession,
  partnerEmployeesSetTab,
  partnerEmployeesSetSearch,
  partnerEmployeesSetPage,
  partnerEmployeesSetFilter,
  partnerEmployeesSetPageSize,
  partnerEmployeesSetSort,
  partnerEmployeesSortReset,
  partnerEmployeesActionsReset,
  partnerEmployeesDataReset,
  partnerEmployeesNeedRefreshWatcherReset,
  partnerEmployeesSetDialogState,
} = slice.actions;

export default slice.reducer;
