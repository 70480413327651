import { getUserFullName } from '../../../../../utils';
import AdminMpTableCell, { AdminMpTableCellCommonProps } from './index';

const AdminMpTableCellName = (props: AdminMpTableCellCommonProps) => {
  const { admin } = props;

  const value = getUserFullName({ user: admin });

  return (
    <AdminMpTableCell
      {...props}
      value={value}
    />
  );
};

export default AdminMpTableCellName;
