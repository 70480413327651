import { PanelAction, PanelActions } from '../../../types';

export enum EAdminMpStep {
  Profile = 'profile',
  Grants = 'grants',
}

export enum EAdminMpActionType {
  Create = 'create',
  Save = 'save',
  Edit = 'edit',
  Activate = 'activate',
  Deactivate = 'deactivate',
}

export type AdminMpActionCreateType = EAdminMpActionType.Create;

export type AdminMpActionTableType = EAdminMpActionType.Activate | EAdminMpActionType.Deactivate;

export type AdminMpAction<A extends EAdminMpActionType, P = any> = PanelAction<A, P>;

export type AdminMpActions<A extends EAdminMpActionType> = PanelActions<A>;

export interface GetAdminMpActionsByPermissions {
  readonly canActivate?: boolean;
  readonly canDeactivate?: boolean;
  readonly canSave?: boolean;
  readonly canEdit?: boolean;
}
