import { Location } from 'history';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { UUID } from '../../../domain/model/types';
import rootRouting from '../../../routing';
import NotFoundScreen from '../../screens/notFound';
import { EOfferTableTab } from '../general/offer/types';
import { ETradeOfferStep } from './types';
import React, { Suspense } from 'react';
import { ContentLoaderSimple } from '../../components/common/loader';
import TradeOfferActionsProvider from '@features/tradeOffer/actions/provider';

const TradeOfferCreateScreen = React.lazy(() => import('../../screens/tradeOfferCreate'));
const TradeOfferDetailsScreen = React.lazy(() => import('../../screens/tradeOfferDetails'));
const TradeOfferEditScreen = React.lazy(() => import('../../screens/tradeOfferEdit'));
const TradeOffersScreen = React.lazy(() => import('../../screens/tradeOffers'));
const TradeOffersUploadScreen = React.lazy(() => import('../../screens/tradeOffersUpload'));

const routing = {
  root: `${rootRouting.tradeOffers}`,
  list: `${rootRouting.tradeOffers}`,
  create: `${rootRouting.tradeOffers}/create`,
  edit: `${rootRouting.tradeOffers}/:id/edit`,
  details: `${rootRouting.tradeOffers}/:id/details`,
  upload: `${rootRouting.tradeOffers}/upload`,
};

export enum ETradeOfferUrlParam {
  Tab = 'tab',
  Step = 'step',
}

export enum ETradeOfferTableUrlParam {
  Tab = 'tab',
}

export interface TradeOffersLocationState {
  readonly guid: UUID;
}

interface GetTradeOffersTableRouteProps {
  readonly tab?: EOfferTableTab;
  readonly guid?: UUID;
}

interface GetTradeOfferEditRouteProps {
  readonly id: UUID;
  readonly step?: ETradeOfferStep;
}

interface GetTradeOfferDetailsRouteProps {
  readonly id: UUID;
  readonly step?: ETradeOfferStep;
}

interface GetTradeOfferCreateRouteProps {
  readonly step?: ETradeOfferStep;
}

export const getTradeOfferCreateRoute = ({ step = ETradeOfferStep.General }: GetTradeOfferCreateRouteProps) => {
  const searchParams = new URLSearchParams();

  searchParams.append(ETradeOfferUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.create}${search}`;
};

export const getTradeOfferEditRoute = ({ id, step = ETradeOfferStep.General }: GetTradeOfferEditRouteProps) => {
  const searchParams = new URLSearchParams();
  searchParams.append(ETradeOfferUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.edit.replace(':id', id)}${search}`;
};

export const getTradeOfferDetailsRoute = ({ id, step = ETradeOfferStep.General }: GetTradeOfferDetailsRouteProps) => {
  const searchParams = new URLSearchParams();
  searchParams.append(ETradeOfferUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.details.replace(':id', id)}${search}`;
};

export const getTradeOffersTableRoute = ({
  tab,
  guid,
}: GetTradeOffersTableRouteProps): Location<TradeOffersLocationState> => {
  const searchParams = new URLSearchParams();
  if (tab) {
    searchParams.append(ETradeOfferTableUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return {
    pathname: routing.list,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

export const getTradeOffersUploadRoute = () => {
  return `${routing.upload}`;
};

const TradeOffersEntry = () => {
  return (
    <TradeOfferActionsProvider>
      <Suspense fallback={<ContentLoaderSimple />}>
        <Switch>
          <Route
            exact
            path={routing.list}
            component={TradeOffersScreen}
          />
          <Route
            exact
            path={routing.create}
            component={TradeOfferCreateScreen}
          />
          <Route
            exact
            path={routing.edit}
            component={TradeOfferEditScreen}
          />
          <Route
            exact
            path={routing.details}
            component={TradeOfferDetailsScreen}
          />
          <Route
            exact
            path={routing.upload}
            component={TradeOffersUploadScreen}
          />
          <Redirect
            exact
            from={routing.root}
            to={routing.list}
          />

          <Route component={NotFoundScreen} />
        </Switch>
      </Suspense>
    </TradeOfferActionsProvider>
  );
};

export default TradeOffersEntry;
