import CorpActivationTableCell, { CorpActivationTableCellCommonProps } from './index';

const CorpActivationTableCellPromotionCode = (props: CorpActivationTableCellCommonProps) => {
  const {
    corpActivation: {
      promotion: { code },
    },
  } = props;

  return (
    <CorpActivationTableCell
      {...props}
      value={code ?? ''}
    />
  );
};

export default CorpActivationTableCellPromotionCode;
