import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { XLSXImportResult } from '../../domain/model';
import { PersonalPromotion } from '../../domain/model/activation';
import { Nullable, UUID } from '../../domain/model/types';
import { createCancelToken } from './index';
import { ApiCancellable, ApiQueryDsl, ApiRequestPageable } from './types';
import { appendQueryDslParams, getComServicesEndpoint } from './utils';

type AllProps = ApiRequestPageable &
  ApiCancellable & {
    readonly offerId?: Nullable<UUID>;
    readonly customerId?: Nullable<UUID>;
    readonly partnerId?: Nullable<UUID>;
    readonly orderId?: Nullable<UUID>;
    readonly isActive?: boolean;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

type DeleteProps = {
  readonly id: UUID;
};

type EnabledProps = {
  readonly id: UUID;
};

type DisabledProps = {
  readonly id: UUID;
};

type NotUsedDeleteProps = {
  readonly offerId: UUID;
};

type DownloadCodesProps = {
  readonly offerId: UUID;
};

type CodesFileUploadProps = ApiCancellable & {
  readonly offerId: UUID;
  readonly file: File;
  readonly config?: AxiosRequestConfig;
};

type GenerateProps = {
  readonly offerId: UUID;
  readonly count: number;
};

type PersonalPromotionApi = {
  readonly all: (props: AllProps) => Promise<AxiosResponse<PersonalPromotion[]>>;
  readonly delete: (props: DeleteProps) => Promise<AxiosResponse>;
  readonly notUsedDelete: (props: NotUsedDeleteProps) => Promise<AxiosResponse>;
  readonly import: (props: CodesFileUploadProps) => Promise<AxiosResponse<XLSXImportResult>>;
  readonly generate: (props: GenerateProps) => Promise<AxiosResponse<string[]>>;
  readonly enabled: (props: EnabledProps) => Promise<AxiosResponse<PersonalPromotion>>;
  readonly disabled: (props: DisabledProps) => Promise<AxiosResponse<PersonalPromotion>>;
  readonly downloadCodes: (props: DownloadCodesProps) => Promise<AxiosResponse<string>>;
};

/**
 * АПИ по работе с персональными кодами
 */
const personalPromotion: PersonalPromotionApi = {
  all: ({ page, pageSize, sort, offerId, partnerId, customerId, orderId, querydsl, signal }) => {
    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (offerId) {
      params.append('offerId', offerId);
    }

    if (customerId) {
      params.append('customerId', customerId);
    }

    if (partnerId) {
      params.append('partnerId', partnerId);
    }

    if (orderId) {
      params.append('orderId', orderId);
    }

    if (querydsl) {
      appendQueryDslParams(params, querydsl);
    }

    return axios.get(`${getComServicesEndpoint()}/promotions/personal`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  delete: ({ id }) => {
    return axios.delete(`${getComServicesEndpoint()}/promotions/personal/${id}`);
  },
  notUsedDelete: ({ offerId }) => {
    const params = new URLSearchParams();
    params.append('offerId', offerId);

    return axios.delete(`${getComServicesEndpoint()}/promotions/personal`, { params });
  },
  import: ({ offerId, file, config = {} }) => {
    const formData = new FormData();
    formData.append('offerId', offerId);
    formData.append('file', file);
    const reqConfig: AxiosRequestConfig = {
      ...config,
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return axios.post(`${getComServicesEndpoint()}/promotions/personal/import`, formData, reqConfig);
  },
  generate: ({ offerId, count }) => {
    return axios.post(`${getComServicesEndpoint()}/promotions/personal/generate`, { offerId, countCode: count });
  },
  enabled: ({ id }) => {
    return axios.patch(`${getComServicesEndpoint()}/promotions/personal/${id}/enabled`);
  },
  disabled: ({ id }) => {
    return axios.patch(`${getComServicesEndpoint()}/promotions/personal/${id}/disabled`);
  },
  downloadCodes: ({ offerId }) => {
    const params = new URLSearchParams();

    params.append('offerId', offerId);
    params.append('skipPageable', 'true');

    return axios.get(`${getComServicesEndpoint()}/promotions/personal/export`, {
      params,
      responseType: 'blob',
    });
  },
};

export default personalPromotion;
