import { Grid } from '@mui/material';
import { SportOptionTyped } from '../../../../../domain/model';
import { EPartnerOwnershipType, EPartnerType, ETaxationSystemType } from '../../../../../domain/model/enums';
import { GlobalNotificationOption, NotificationOption } from '../../../../../domain/model/notification';
import { Partner, PartnerDesk } from '../../../../../domain/model/partner';
import { Nullable } from '../../../../../domain/model/types';
import ConfigurationNotifications from '../../../configuration/components/notifications';
import PartnerDetailsTabDesk from '../../components/detailsTab/desk';
import PartnerDetailsTabLegal from '../../components/detailsTab/legal';
import { ColumnWrapper, Wrapper } from '../controls';
import { EPartnerDetailsLegalTab, EPartnerDetailsTab, PartnerDetailsLegalTab } from '../utils';
import PartnerDetailsAdminDeskStub from './deskStub';

interface PartnerDetailsAdminComponentProps {
  readonly tab: EPartnerDetailsTab;
  readonly legalTab: EPartnerDetailsLegalTab;
  readonly legalTabs: PartnerDetailsLegalTab[];
  readonly partner: Partner;
  readonly partnerTypes: SportOptionTyped<EPartnerType>[];
  readonly ownerShipTypes: SportOptionTyped<EPartnerOwnershipType>[];
  readonly desk: Nullable<PartnerDesk>;
  readonly notifications: NotificationOption[];
  readonly taxSystems: SportOptionTyped<ETaxationSystemType>[];
  readonly onChangeLegalTab: (tab: PartnerDetailsLegalTab) => void;
}

const PartnerDetailsAdminComponent = (props: PartnerDetailsAdminComponentProps) => {
  const {
    tab,
    legalTab,
    legalTabs,
    partner,
    partnerTypes,
    desk,
    notifications,
    taxSystems,
    ownerShipTypes,
    onChangeLegalTab
  } = props;

  const notificationsData: GlobalNotificationOption[] = notifications.map(notification => {
    const enabled =
      partner.marketingDistributionSettings?.find(item => item.option.id === notification.id)?.enabled ?? false;

    return {
      ...notification,
      enabled
    };
  });

  return (
    <Wrapper container>
      <Grid
        item
        xs={6}
      >
        <ColumnWrapper>
          {tab === EPartnerDetailsTab.Legal && (
            <PartnerDetailsTabLegal
              partner={partner}
              partnerTypes={partnerTypes}
              tabs={legalTabs}
              tab={legalTab}
              taxSystems={taxSystems}
              ownerShipTypes={ownerShipTypes}
              onChangeTab={onChangeLegalTab}
            />
          )}
          {tab === EPartnerDetailsTab.Desk && !desk && <PartnerDetailsAdminDeskStub />}
          {tab === EPartnerDetailsTab.Desk && desk && <PartnerDetailsTabDesk desk={desk} />}
          {tab === EPartnerDetailsTab.Notifications && (
            <ConfigurationNotifications
              notifications={notificationsData}
              onChange={null}
            />
          )}
        </ColumnWrapper>
      </Grid>
    </Wrapper>
  );
};

export default PartnerDetailsAdminComponent;
