import styled from '@emotion/styled';

export const ProductOrderItemTableCellImageWrapper = styled.div`
  height: 4.75rem;
  width: 6.6875rem;
  margin-top: -${p => p.theme.spacing(0.7)};
  margin-bottom: -${p => p.theme.spacing(0.7)};
`;

export const ProductOrderItemTableCellAttributesWrapper = styled.div`
  display: grid;
  column-gap: ${p => p.theme.spacing(1)};
  grid-template-columns: repeat(2, auto 1fr);
`;
