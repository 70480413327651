import styled from '@emotion/styled';

export const FullScreenContentWrapper = styled.div`
  height: calc(100vh - 10rem);

  padding-top: ${p => p.theme.spacing(4)};
`;

export const TopPushContentWrapper = styled.div`
  height: calc(100vh - 9rem);
`;

export const TitleWrapper = styled.div`
  width: 40rem;

  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing()};

  padding-bottom: ${p => p.theme.spacing(1.5)};

  border-bottom: 1px solid ${p => p.theme.palette.disabled.background};
`;
