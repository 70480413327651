import { ReactNode, useMemo } from 'react';
import { BookingOfferShort } from '../../../../../domain/model/booking';
import DataTableCellTextFormat from '../../../../components/common/table/cell/textFormat';

export interface BookingOfferTableCellCommonProps {
  readonly bookingOffer: BookingOfferShort;
  readonly isDisabled?: boolean;
  readonly isTextAccent?: boolean;
}

interface BookingOfferTableCellProps extends BookingOfferTableCellCommonProps {
  readonly value: ReactNode;
}

const BookingOfferTableCell = ({ value, isDisabled, isTextAccent }: BookingOfferTableCellProps) => {
  return useMemo(
    () => (
      <DataTableCellTextFormat
        disabled={isDisabled}
        bold={isTextAccent}
      >
        {value}
      </DataTableCellTextFormat>
    ),
    [isDisabled, isTextAccent, value]
  );
};

export default BookingOfferTableCell;
