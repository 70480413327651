import { FCC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useHistoryExtensions from '../../../../../hooks/useHistoryExtensions';
import { getConfigurationPrivilegeRoute } from '../../../../configuration/entry';
import {
  BookingServicePriceUnitsEditHandlersContext,
  BookingServicePriceUnitsEditHandlersContextValue,
} from '../context';
import {
  bookingServicePriceUnitsEditCreate,
  bookingServicePriceUnitsEditDelete,
  bookingServicePriceUnitsEditSetDialogState,
  bookingServicePriceUnitsEditUpdate,
} from '../store/slice';
import { BookingServicePriceUnitsEditCommonProps } from '../types';

export const BookingServicePriceUnitsEditHandlersProvider: FCC<BookingServicePriceUnitsEditCommonProps> = ({
  children,
  access,
}) => {
  const dispatch = useDispatch();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const onChangeDialogState = useCallback<BookingServicePriceUnitsEditHandlersContextValue['onChangeDialogState']>(
    (name, data) => {
      dispatch(bookingServicePriceUnitsEditSetDialogState({ name, data }));
    },
    [dispatch]
  );

  const onCreate = useCallback<BookingServicePriceUnitsEditHandlersContextValue['onCreate']>(
    async bookingServicePriceUnit => {
      return await dispatch(bookingServicePriceUnitsEditCreate(bookingServicePriceUnit)).unwrap();
    },
    [dispatch]
  );

  const onEdit = useCallback<BookingServicePriceUnitsEditHandlersContextValue['onEdit']>(
    async bookingServicePriceUnit => {
      return await dispatch(bookingServicePriceUnitsEditUpdate(bookingServicePriceUnit)).unwrap();
    },
    [dispatch]
  );

  const onDelete = useCallback<BookingServicePriceUnitsEditHandlersContextValue['onDelete']>(
    async bookingServicePriceUnit => {
      await dispatch(bookingServicePriceUnitsEditDelete(bookingServicePriceUnit)).unwrap();
    },
    [dispatch]
  );

  const onClose = useCallback<BookingServicePriceUnitsEditHandlersContextValue['onClose']>(() => {
    gotoPrevIndependentLocation(getConfigurationPrivilegeRoute({}));
  }, [gotoPrevIndependentLocation]);

  const value: BookingServicePriceUnitsEditHandlersContextValue = {
    onChangeDialogState,
    onCreate,
    onEdit,
    onDelete,
    onClose,
  };

  return (
    <BookingServicePriceUnitsEditHandlersContext.Provider value={value}>
      {children}
    </BookingServicePriceUnitsEditHandlersContext.Provider>
  );
};
