import {
  CaseReducer,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  Selector,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import Api from '../../../../../data/api';
import { businessErrorCode } from '../../../../../data/network/constants';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig, RootState } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Chat, ChatMember, ChatMessage } from '../../../../../domain/model/chat';
import { Nullable, UUID } from '../../../../../domain/model/types';

const messagesPageSize = 20 as const;

export const chatFetch = createAsyncThunk<
  { chat: Nullable<Chat>; guid: UUID },
  { id: Nullable<UUID>; to?: UUID; guid: UUID },
  AppThunkAPIConfig
>('chat/details/fetch', async ({ id, to, guid }, { rejectWithValue, signal }) => {
  try {
    if (id) {
      const { data } = await Api.chat.one({ id });
      return { chat: data, guid };
    } else {
      const { data: chats } = await Api.chat.all({ page: 1, pageSize: 1, subjectId: to, signal });
      return { chat: chats.content.find(c => c.subject?.id === to)!, guid };
    }
  } catch (e: any) {
    if (e.response?.status === businessErrorCode) {
      return { chat: null, guid };
    } else {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
});

export const chatMessagesFetch = createAsyncThunk<
  { messages: ChatMessage[]; hasMore: boolean },
  { chat: Chat; guid: UUID; anchor: Nullable<ChatMessage> },
  AppThunkAPIConfig
>('chat/details/messages/fetch', async ({ chat, anchor }, { signal, rejectWithValue }) => {
  try {
    const { data: messages } = await Api.chat.messages({
      id: chat.id,
      limit: messagesPageSize,
      forward: false,
      messageId: anchor?.id,
      signal,
    });

    const hasMore = messages.length === messagesPageSize;

    return { messages, hasMore };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const chatMembersFetch = createAsyncThunk<ChatMember[], { guid: UUID; chatId: UUID }, AppThunkAPIConfig>(
  'chat/details/members/fetch',
  async ({ chatId }, { signal, rejectWithValue }) => {
    try {
      const { data } = await Api.chat.members({
        id: chatId,
        signal,
      });

      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

interface ByIdState extends Fetchable {
  readonly data: Nullable<Chat>;
}

interface MembersState extends Fetchable {
  readonly data: ChatMember[];
}

interface MessagesState extends Fetchable {
  readonly data: ChatMessage[];
  readonly selected: ChatMessage[];
  readonly hasMore: boolean;
  readonly scrollToEnd: Nullable<UUID>;
}

export interface ChatDetails {
  readonly guid: Nullable<UUID>;
  readonly byId: ByIdState;
  readonly messages: MessagesState;
  readonly members: MembersState;
}

export interface ChatsState {
  [index: string]: ChatDetails;
}

export interface ChatDetailsState {
  currentSession: Nullable<UUID>;
  chats: ChatsState;
}

type Reducer<T> = CaseReducer<ChatDetailsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ChatDetailsState> {
  chatStartSession: Reducer<UUID>;
  chatClearState: Reducer<UUID>;
  chatMessageSelect: Reducer<{ guid: UUID; chatMessage: ChatMessage }>;
  chatMessageClearSelected: Reducer<UUID>;
  chatMessageAppend: Reducer<{ guid: UUID; message: ChatMessage }>;
  chatMessageRemove: Reducer<{ guid: UUID; ids: UUID[] }>;
}

const slice = createSlice<ChatDetailsState, Reducers, 'chat/details'>({
  name: 'chat/details',
  initialState: {
    currentSession: null,
    chats: {},
  },
  reducers: {
    chatStartSession: (state, { payload }) => {
      if (!state.chats[payload]) {
        state.chats[payload] = {
          guid: payload,
          byId: {
            ...fetchableDefault,
            data: null,
          },
          messages: {
            ...fetchableDefault,
            data: [],
            hasMore: false,
            scrollToEnd: null,
            selected: [],
          },
          members: {
            ...fetchableDefault,
            data: [],
          },
        };
      }
      state.currentSession = payload;
    },
    chatClearState: (state, { payload }) => {
      state.chats[payload] = {
        guid: payload,
        byId: {
          ...fetchableDefault,
          data: null,
        },
        messages: {
          ...fetchableDefault,
          data: [],
          hasMore: false,
          scrollToEnd: null,
          selected: [],
        },
        members: {
          ...fetchableDefault,
          data: [],
        },
      };
    },
    chatMessageSelect: (state, { payload }) => {
      const { guid, chatMessage } = payload;
      const chat = state.chats[guid];
      if (chat) {
        const alreadySelectedIndex = chat.messages.selected.findIndex(selected => selected.id === chatMessage.id);
        if (alreadySelectedIndex !== -1) {
          chat.messages.selected.splice(alreadySelectedIndex, 1);
        } else {
          chat.messages.selected.push(chatMessage);
        }
      }
    },
    chatMessageClearSelected: (state, { payload }) => {
      const chat = state.chats[payload];
      if (chat) {
        chat.messages.selected = [];
      }
    },
    chatMessageAppend: (state, { payload }) => {
      const { guid, message } = payload;

      const chat = state.chats[guid];
      if (chat) {
        // ищем это сообщение в имеющемся наборе, оно может быть добавлено в момент отправки
        const index = chat.messages.data.findIndex(m => m.requestId === message.requestId);
        if (index !== -1) {
          chat.messages.data[index] = message;
        } else {
          chat.messages.data.push(message);
        }
        chat.messages.scrollToEnd = uuidv4();
      }
    },
    chatMessageRemove: (state, { payload }) => {
      const { guid, ids } = payload;

      const chat = state.chats[guid];
      if (chat) {
        const currentMessages = [...chat.messages.data];
        ids.forEach(id => {
          // ищем это сообщение в имеющемся наборе, оно может быть добавлено в момент отправки
          const index = currentMessages.findIndex(m => m.id === id);
          if (index !== -1) {
            currentMessages.splice(index, 1);
          }
          chat.messages.data = currentMessages;
        });
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(chatFetch.pending, (state, { meta }) => {
        const { guid } = meta.arg;
        const stateChat = state.chats[guid];

        if (stateChat) {
          stateChat.byId.isFetching = true;
          stateChat.byId.isFetched = false;
          stateChat.byId.isFailed = false;
          stateChat.byId.data = null;

          stateChat.messages.data = [];
        }
      })
      .addCase(chatFetch.fulfilled, (state, { payload }) => {
        const { guid, chat } = payload;
        const stateChat = state.chats[guid];

        if (stateChat) {
          stateChat.byId.isFetching = false;
          stateChat.byId.isFetched = true;
          stateChat.byId.isFailed = false;
          stateChat.byId.data = chat;
        }
      })
      .addCase(chatFetch.rejected, (state, { meta }) => {
        const { guid } = meta.arg;
        const stateChat = state.chats[guid];

        if (stateChat) {
          stateChat.byId.isFetching = false;
          stateChat.byId.isFetched = false;
          stateChat.byId.isFailed = true;
          stateChat.byId.data = null;
        }
      })

      .addCase(chatMessagesFetch.pending, (state, { meta }) => {
        const { guid } = meta.arg;
        const stateChat = state.chats[guid];

        if (stateChat) {
          stateChat.messages.isFetching = true;
          stateChat.messages.isFetched = false;
          stateChat.messages.isFailed = false;
        }
      })
      .addCase(chatMessagesFetch.fulfilled, (state, { payload, meta }) => {
        const { guid } = meta.arg;
        const { messages, hasMore } = payload;
        const stateChat = state.chats[guid];

        if (stateChat) {
          stateChat.messages.isFetching = false;
          stateChat.messages.isFetched = true;
          stateChat.messages.isFailed = false;
          stateChat.messages.hasMore = hasMore;

          // удаляем из полученных те что уже есть в списке
          const currentMessages = stateChat.messages.data;
          currentMessages.forEach(message => {
            const index = messages.findIndex(m => m.id === message.id);
            if (index !== -1) {
              messages.splice(index, 1);
            }
          });
          stateChat.messages.data = [...messages, ...currentMessages];
        }
      })
      .addCase(chatMessagesFetch.rejected, (state, { meta }) => {
        const { arg, aborted } = meta;
        const { guid } = arg;
        const stateChat = state.chats[guid];

        if (stateChat) {
          if (aborted) {
            stateChat.messages.isFetching = false;
            stateChat.messages.isFetched = false;
            stateChat.messages.isFailed = false;
          } else {
            stateChat.messages.isFetching = false;
            stateChat.messages.isFetched = false;
            stateChat.messages.isFailed = true;
          }
        }
      })

      .addCase(chatMembersFetch.pending, (state, { meta }) => {
        const { guid } = meta.arg;
        const stateChat = state.chats[guid];

        if (stateChat) {
          stateChat.members.isFetching = true;
          stateChat.members.isFetched = false;
          stateChat.members.isFailed = false;
        }
      })
      .addCase(chatMembersFetch.fulfilled, (state, { payload, meta }) => {
        const { guid } = meta.arg;
        const stateChat = state.chats[guid];

        if (stateChat) {
          stateChat.members.isFetching = false;
          stateChat.members.isFetched = true;
          stateChat.members.isFailed = false;
          stateChat.members.data = payload;
        }
      })
      .addCase(chatMembersFetch.rejected, (state, { meta }) => {
        const { aborted, arg } = meta;
        const { guid } = arg;
        const stateChat = state.chats[guid];

        if (stateChat) {
          if (aborted) {
            stateChat.members.isFetching = false;
            stateChat.members.isFetched = false;
            stateChat.members.isFailed = false;
          } else {
            stateChat.members.isFetching = false;
            stateChat.members.isFetched = false;
            stateChat.members.isFailed = true;
          }
        }
      });
  },
});

export const {
  chatStartSession,
  chatClearState,
  chatMessageSelect,
  chatMessageClearSelected,
  chatMessageAppend,
  chatMessageRemove,
} = slice.actions;

const chatSelector: Selector<RootState, ChatsState> = state => state.chat.details.chats;
const chatByIdSelector: Selector<RootState, UUID, [UUID]> = (state, id) => id;

export const createChatGetByIdSelector = createSelector(
  chatSelector,
  chatByIdSelector,
  (chats, guid) => chats[guid]?.byId ?? ({} as ByIdState)
);

export const createChatGetMessagesSelector = createSelector(
  chatSelector,
  chatByIdSelector,
  (chats, guid) =>
    chats[guid]?.messages ?? {
      ...fetchableDefault,
      data: [],
      hasMore: false,
      scrollToEnd: null,
      selected: [],
    }
);

export const createChatGetMembersSelector = createSelector(
  chatSelector,
  chatByIdSelector,
  (chats, guid) =>
    chats[guid]?.members ?? {
      ...fetchableDefault,
      data: [],
    }
);

export const createChatSelectedMessagesSelector = createSelector(
  chatSelector,
  chatByIdSelector,
  (chats, guid) => chats[guid]?.messages?.selected ?? []
);

export default slice.reducer;
