import React from 'react';
import { UseCorpOffersTable } from './hooks/useCorpOffersTable';
import { UseCorpOffersTableConfig } from './hooks/useCorpOffersTableConfig';

export type CorpOffersTableContextValue = UseCorpOffersTable;

export const CorpOffersTableContext = React.createContext<CorpOffersTableContextValue>(
  {} as CorpOffersTableContextValue
);

export type CorpOffersTableConfigContextValue = UseCorpOffersTableConfig;

export const CorpOffersTableConfigContext = React.createContext<CorpOffersTableConfigContextValue>(
  {} as CorpOffersTableConfigContextValue
);
