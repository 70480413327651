import { EOfferStatus, TradeOffer } from '@/domain';
import { useCallback, useMemo } from 'react';
import { EOfferTableTab } from '../../../general/offer/types';

type UseTradeOffersSearchConfigProps = {
  readonly isSingleSelect: boolean;
  readonly tab: EOfferTableTab;
  readonly statuses: EOfferStatus[];
  readonly onTradeOfferClick: (tradeOffer: TradeOffer) => void;
  readonly onChangeTableTab: (tab: EOfferTableTab) => void;
};

export type UseTradeOffersSearchConfig = {
  readonly isSingleSelect: boolean;
  readonly tabs: EOfferTableTab[];
  readonly tabsForCounts: EOfferTableTab[];
  readonly defaultTab: EOfferTableTab;
  readonly onTradeOfferClick: (tradeOffer: TradeOffer) => void;
  readonly onChangeTab: (tab: EOfferTableTab) => void;
};

export function useTradeOffersSearchConfig(props: UseTradeOffersSearchConfigProps): UseTradeOffersSearchConfig {
  const { isSingleSelect, tab, statuses, onTradeOfferClick, onChangeTableTab } = props;

  const tabs = useMemo(() => {
    const outTabs: EOfferTableTab[] = [];
    if (statuses.includes(EOfferStatus.Active)) {
      outTabs.push(EOfferTableTab.Active);
    }
    if (statuses.includes(EOfferStatus.Upcoming)) {
      outTabs.push(EOfferTableTab.Upcoming);
    }
    return outTabs;
  }, [statuses]);

  const defaultTab = EOfferTableTab.Active;

  const onChangeTab = useCallback(
    (newTab: EOfferTableTab) => {
      if (newTab !== tab) {
        onChangeTableTab(newTab);
      }
    },
    [tab, onChangeTableTab]
  );

  return {
    isSingleSelect,
    tabs,
    tabsForCounts: tabs,
    defaultTab,
    onTradeOfferClick,
    onChangeTab,
  };
}
