import { Grid, Typography } from '@mui/material';
import { GlobalNotificationOption } from '../../../../../../domain/model/notification';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import ConfigurationNotifications from '../../../components/notifications';
import { Wrapper } from './controls';

interface ConfigurationPrivilegeNotificationsProps {
  readonly notifications: GlobalNotificationOption[];
  readonly onChangeNotification: Nullable<(id: UUID, enabled: boolean) => void>;
}

const ConfigurationPrivilegeNotifications = (props: ConfigurationPrivilegeNotificationsProps) => {
  const { notifications, onChangeNotification } = props;

  return (
    <Wrapper>
      <Grid
        container
        spacing={3}
        direction='column'
      >
        <Grid item>
          <Typography variant='subtitle2'>Уведомления партнёрам</Typography>
        </Grid>
        <Grid item>
          <ConfigurationNotifications
            notifications={notifications}
            onChange={onChangeNotification}
          />
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default ConfigurationPrivilegeNotifications;
