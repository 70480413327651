import useDataTableFilterAdapter, { DataFilterAdapter } from '@/presentation/hooks/useDataFilterAdapter';
import { ETradeActivationTableColumn } from '@features/tradeActivation/types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataFilterComponent from '../../../../components/common/dataFilter';
import { ETradeActivationsFilterItem } from '../../utils/filter';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  tradeActivationsFilterSelector,
  tradeActivationsGuidSelector,
  tradeActivationsSortColumnSelector,
} from '../store/selectors';

interface TradeActivationTableFilterAdapterProps {
  readonly filterAdapter: DataFilterAdapter<ETradeActivationTableColumn, ETradeActivationsFilterItem>;
}

const TradeActivationTableFilterAdapter = ({ filterAdapter }: TradeActivationTableFilterAdapterProps) => {
  const handlers = useContextHandlers();

  const guid = useSelector(tradeActivationsGuidSelector);
  const sortColumn = useSelector(tradeActivationsSortColumnSelector);
  const filter = useSelector(tradeActivationsFilterSelector);

  const { filterStrategies } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return useMemo(
    () => (
      <DataFilterComponent
        strategies={filterStrategies}
        onChange={handlers.onChangeFilter}
      />
    ),
    [filterStrategies, handlers.onChangeFilter]
  );
};

export default TradeActivationTableFilterAdapter;
