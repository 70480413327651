import { combineReducers } from '@reduxjs/toolkit';
import menu, { UikitMenuState } from './menu/store/slice';

export interface UikitState {
  menu: UikitMenuState;
}

export default combineReducers<UikitState>({
  menu,
});
