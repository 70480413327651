import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { FormItem } from '../../../../components/forms/item';
import BookingOrderDetailsInfo from '../../components/detailsInfo';
import { bookingOrderDetailsBookingOrderSelector } from '../store/selectors';

export const BookingOrderDetailsDetailsInfoAdapter = () => {
  const bookingOrder = useSelector(bookingOrderDetailsBookingOrderSelector);

  if (!bookingOrder) {
    return null;
  }

  return (
    <Grid
      item
      xs={4}
    >
      <FormItem title='Сведения о заказе'>
        <BookingOrderDetailsInfo
          date={bookingOrder.createdAt}
          customer={bookingOrder.customer}
          comment={bookingOrder.customerComment}
        />
      </FormItem>
    </Grid>
  );
};
