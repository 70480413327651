import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { Pageable } from '@/domain/model';
import { CorpOfferActivation } from '@/domain/model/activation';
import { Nullable, UUID } from '@/domain/model/types';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { PaginationSize } from '../../../../types';
import corpActivationServices from '../../services';
import { AllProps } from '../../services/activation';
import { ECorpActivationTableColumn, ECorpActivationTableTab } from '../../types';
import { CorpActivationsFilterValues } from '../../utils/filter';
import { CorpActivationTableTabsCounter } from '../../utils/table';

const defaultSort = `${ECorpActivationTableColumn.CreateAt},desc` as const;

interface CorpActivationSearchState {
  readonly sort: string;
  readonly partnerId: Nullable<UUID>;
  readonly pageSize: PaginationSize;
}

export interface CorpActivationListState {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly corpActivations: Fetchable & Pageable<CorpOfferActivation>;
  readonly search: CorpActivationSearchState;
  readonly tab: Nullable<ECorpActivationTableTab>;
  readonly filter: CorpActivationsFilterValues;
  readonly tabsCounter: CorpActivationTableTabsCounter;
}

type Reducer<T = undefined> = CaseReducer<CorpActivationListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CorpActivationListState> {
  corpActivationsStartSession: Reducer<{ guid: UUID }>;
  corpActivationsSetSort: Reducer<{ sort: string }>;
  corpActivationsSortReset: Reducer;
  corpActivationsSetTab: Reducer<{ tab: ECorpActivationTableTab }>;
  corpActivationsSetFilter: Reducer<{ filter: CorpActivationsFilterValues }>;
  corpActivationsSetPage: Reducer<{ pageNumber: number }>;
  corpActivationsSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  corpActivationsDataReset: Reducer;
  corpActivationsNeedRefreshWatcherReset: Reducer;
  corpActivationsNeedRefreshWatcherIncrement: Reducer;
}

export type CorpActivationsFetchProps = Omit<AllProps, 'signal'>;
export const corpActivationsFetch = createAsyncThunk<
  Pageable<CorpOfferActivation>,
  CorpActivationsFetchProps,
  AppThunkAPIConfig
>('corpActivations/list/fetch', async (payload, { rejectWithValue, signal }) => {
  try {
    return await corpActivationServices.activation.all({ ...payload, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export type CorpActivationsCountsFetchProps = CorpActivationsFetchProps & {
  readonly tabs: ECorpActivationTableTab[];
};

export const corpActivationsCountsFetch = createAsyncThunk<
  CorpActivationTableTabsCounter,
  CorpActivationsCountsFetchProps,
  AppThunkAPIConfig
>('corpActivation/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await corpActivationServices.activation.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      errors.map(e => {
        console.error(e);
      });
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

const slice = createSlice<CorpActivationListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    guid: null,
    needRefreshWatcher: 0,
    corpActivations: {
      ...fetchableDefault,
      data: [],
      totalCount: 0,
      pageCount: 0,
      pageNumber: 1,
    },
    search: {
      sort: defaultSort,
      pageSize: 10,
      partnerId: null,
    },
    tab: null,
    filter: {},
    tabsCounter: {},
  },
  reducers: {
    corpActivationsStartSession: (state, { payload }) => {
      const { guid } = payload;
      if (guid !== state.guid) {
        state.guid = guid;

        state.corpActivations.isFetching = false;
        state.corpActivations.isFetched = false;
        state.corpActivations.isFailed = false;

        state.corpActivations.data = [];
        state.corpActivations.totalCount = 0;
        state.corpActivations.pageCount = 0;
        state.corpActivations.pageNumber = 1;
        state.needRefreshWatcher = 0;
        state.search = {
          sort: defaultSort,
          pageSize: 10,
          partnerId: null,
        };
        state.tab = null;
        state.filter = {};
        state.tabsCounter = {};
      }
    },
    corpActivationsSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.corpActivations.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    corpActivationsSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.needRefreshWatcher++;
    },
    corpActivationsSetTab: (state, { payload }) => {
      const { tab } = payload;
      if (state.tab !== tab) {
        state.corpActivations.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    corpActivationsSetFilter: (state, { payload }) => {
      const { filter } = payload;
      state.filter = filter;
      state.corpActivations.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    corpActivationsSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.corpActivations.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    corpActivationsSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.corpActivations.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    corpActivationsDataReset: state => {
      state.corpActivations.data = [];
      state.corpActivations.totalCount = 0;
      state.corpActivations.pageCount = 0;
      state.corpActivations.pageNumber = 1;
    },
    corpActivationsNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
    corpActivationsNeedRefreshWatcherIncrement: state => {
      state.needRefreshWatcher++;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(corpActivationsFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        state.corpActivations.isFetching = true;
        state.corpActivations.isFetched = false;
        state.corpActivations.isFailed = false;

        if (partnerId !== state.search.partnerId) {
          state.corpActivations.data = [];
        }
        state.search.partnerId = partnerId;
      })
      .addCase(corpActivationsFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.corpActivations.isFetching = false;
        state.corpActivations.isFetched = true;
        state.corpActivations.isFailed = false;

        state.corpActivations.data = data;
        state.corpActivations.totalCount = totalCount;
        state.corpActivations.pageCount = pageCount;
      })
      .addCase(corpActivationsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.corpActivations.isFetching = false;
          state.corpActivations.isFetched = false;
          state.corpActivations.isFailed = true;
          state.corpActivations.data = [];
        }
      })
      .addCase(corpActivationsCountsFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        if (partnerId !== state.search.partnerId) {
          state.tabsCounter = {};
        }
      })
      .addCase(corpActivationsCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(corpActivationsCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      });
  },
});

export const {
  corpActivationsStartSession,
  corpActivationsSetSort,
  corpActivationsSortReset,
  corpActivationsSetTab,
  corpActivationsSetFilter,
  corpActivationsSetPage,
  corpActivationsSetPageSize,
  corpActivationsDataReset,
  corpActivationsNeedRefreshWatcherReset,
  corpActivationsNeedRefreshWatcherIncrement,
} = slice.actions;

export default slice.reducer;
