import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Partner } from '../../../domain/model/partner';
import ForbiddenComponent from '../../components/common/forbidden';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import PartnerDetailsContainer from '../../features/partner/details/container';
import { EPartnerDetailsLegalTab, EPartnerDetailsTab } from '../../features/partner/details/utils';
import { EPartnerUrlParam } from '../../features/partner/entry';
import { viewShowPartnerManagementMenu } from '../../features/partner/management/container/store/slice';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface PartnerManagementScreenProps {
  readonly partner: Partner;
}

const PartnerManagementScreen = ({ partner }: PartnerManagementScreenProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { partners } = useCurrentUser().accessMatrix;

  const searchParams = new URLSearchParams(location.search);
  const tab = (searchParams.get(EPartnerUrlParam.Tab) as EPartnerDetailsTab) ?? undefined;
  const legalTab = (searchParams.get(EPartnerUrlParam.LegalTab) as EPartnerDetailsLegalTab) ?? undefined;

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowPartnerManagementMenu());
  }, [dispatch]);

  if (!partners?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <PartnerDetailsContainer
      id={partner.id}
      tab={tab}
      legalTab={legalTab}
    />
  );
};

export default PartnerManagementScreen;
