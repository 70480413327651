import { Grid, Typography } from '@mui/material';
import {
  getBookingOfferCategoriesDownloadEndpoint,
  getBookingOfferPriceUnitsDownloadEndpoint,
  getBookingServiceCategoriesDownloadEndpoint,
  getCorpOfferCategoriesDownloadEndpoint,
  getTradeOfferCategoriesDownloadEndpoint,
} from '../../../../../../data/api/utils';
import FileDownloadButton from '../../../../../components/common/files/download/button';
import { fileTypes } from '../../../../../constants';
import { IconDownload } from '../../../../../icons';
import { MPButton } from '../../../../../theme/ui-kit/button';
import { EConfigurationPrivilegeDictionaryType } from '../../../utils';
import { DictionaryTitle, Wrapper } from './controls';

interface ConfigurationPrivilegeDictionariesProps {
  readonly canEditCorpCategories?: boolean;
  readonly canEditTradeCategories?: boolean;
  readonly canEditBookingOfferCategories?: boolean;
  readonly canEditBookingServiceCategories?: boolean;
  readonly canEditBookingOfferPriceUnits?: boolean;
  readonly onEdit: (type: EConfigurationPrivilegeDictionaryType) => void;
}

export const ConfigurationPrivilegeDictionaries = (props: ConfigurationPrivilegeDictionariesProps) => {
  const {
    canEditCorpCategories,
    canEditTradeCategories,
    canEditBookingOfferCategories,
    canEditBookingServiceCategories,
    canEditBookingOfferPriceUnits,
    onEdit,
  } = props;

  if (!canEditCorpCategories && !canEditTradeCategories) {
    return null;
  }

  return (
    <Wrapper>
      <Grid
        container
        direction='column'
        spacing={3}
      >
        <Grid item>
          <Typography variant='subtitle2'>Справочники категорий</Typography>
        </Grid>
        {canEditTradeCategories && (
          <Grid
            item
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <DictionaryTitle>Справочник категорий ТП</DictionaryTitle>
            </Grid>
            <Grid
              item
              xs={7}
            >
              <MPButton onClick={() => onEdit(EConfigurationPrivilegeDictionaryType.TradeCategories)}>
                Редактировать
              </MPButton>
            </Grid>
            <Grid
              item
              xs={5}
            >
              <FileDownloadButton
                variant='outlined'
                startIcon={<IconDownload />}
                src={getTradeOfferCategoriesDownloadEndpoint()}
                accept={fileTypes.xls.mimeType}
                fileName={'категории_торговых_предложений'}
              >
                Скачать
              </FileDownloadButton>
            </Grid>
          </Grid>
        )}
        {canEditCorpCategories && (
          <Grid
            item
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <DictionaryTitle>Справочник категорий КП</DictionaryTitle>
            </Grid>
            <Grid
              item
              xs={7}
            >
              <MPButton onClick={() => onEdit(EConfigurationPrivilegeDictionaryType.CorpCategories)}>
                Редактировать
              </MPButton>
            </Grid>
            <Grid
              item
              xs={5}
            >
              <FileDownloadButton
                variant='outlined'
                startIcon={<IconDownload />}
                src={getCorpOfferCategoriesDownloadEndpoint()}
                accept={fileTypes.xls.mimeType}
                fileName={'категории_корпоративных_предложений'}
              >
                Скачать
              </FileDownloadButton>
            </Grid>
          </Grid>
        )}
        {canEditBookingOfferCategories && (
          <Grid
            item
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <DictionaryTitle>Категории объектов досуга и отдыха</DictionaryTitle>
            </Grid>
            <Grid
              item
              xs={7}
            >
              <MPButton onClick={() => onEdit(EConfigurationPrivilegeDictionaryType.BookingOfferCategories)}>
                Редактировать
              </MPButton>
            </Grid>
            <Grid
              item
              xs={5}
            >
              <FileDownloadButton
                variant='outlined'
                startIcon={<IconDownload />}
                src={getBookingOfferCategoriesDownloadEndpoint()}
                accept={fileTypes.xls.mimeType}
                fileName={'категории_дио'}
              >
                Скачать
              </FileDownloadButton>
            </Grid>
          </Grid>
        )}
        {canEditBookingOfferPriceUnits && (
          <Grid
            item
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <DictionaryTitle>Единицы измерения тарифов в объектах досуга и отдыха</DictionaryTitle>
            </Grid>
            <Grid
              item
              xs={7}
            >
              <MPButton onClick={() => onEdit(EConfigurationPrivilegeDictionaryType.BookingOfferPriceUnits)}>
                Редактировать
              </MPButton>
            </Grid>
            <Grid
              item
              xs={5}
            >
              <FileDownloadButton
                variant='outlined'
                startIcon={<IconDownload />}
                src={getBookingOfferPriceUnitsDownloadEndpoint()}
                accept={fileTypes.xls.mimeType}
                fileName={'категории_ед_изм_дио'}
              >
                Скачать
              </FileDownloadButton>
            </Grid>
          </Grid>
        )}
        {canEditBookingServiceCategories && (
          <Grid
            item
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <DictionaryTitle>Категории услуг в объектах досуга и отдыха</DictionaryTitle>
            </Grid>
            <Grid
              item
              xs={7}
            >
              <MPButton onClick={() => onEdit(EConfigurationPrivilegeDictionaryType.BookingServiceCategories)}>
                Редактировать
              </MPButton>
            </Grid>
            <Grid
              item
              xs={5}
            >
              <FileDownloadButton
                variant='outlined'
                startIcon={<IconDownload />}
                src={getBookingServiceCategoriesDownloadEndpoint()}
                accept={fileTypes.xls.mimeType}
                fileName={'категории_услуг_дио'}
              >
                Скачать
              </FileDownloadButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Wrapper>
  );
};
