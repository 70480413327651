import { ENoticeStatus } from '@/domain/model/enums';
import Notifier from '../../../../system/notifier';
import { ECorpActivationActionType } from '../types';

export const showCorpActivationActionNotification = (action: ECorpActivationActionType) => {
  let text;
  switch (action) {
    case ECorpActivationActionType.Give:
      text = 'Заказ был выдан';
      break;
    case ECorpActivationActionType.UnGive:
      text = 'Выдача заказа отменена';
      break;
  }

  Notifier.getInstance().addNotice(ENoticeStatus.Success, text);
};
