import { Banner } from '@/domain';
import { ReactNode, useMemo } from 'react';
import DataTableCellTextFormat from '../../../../components/common/table/cell/textFormat';

export interface BannerTableCellCommonProps {
  readonly banner: Banner;
  readonly isDisabled?: boolean;
}

interface BannerTableCellProps extends BannerTableCellCommonProps {
  readonly value: ReactNode;
}

const BannerTableCell = ({ value, isDisabled }: BannerTableCellProps) => {
  return useMemo(
    () => <DataTableCellTextFormat disabled={isDisabled}>{value}</DataTableCellTextFormat>,
    [isDisabled, value]
  );
};

export default BannerTableCell;
