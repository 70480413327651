import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { UUID } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import { viewShowMainMenu } from '../../features/main/container/store/slice';
import TeamEditContainer from '../../features/team/edit/container';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const TeamEditScreen = () => {
  const dispatch = useDispatch();

  const { id } = useParams<{ id: UUID }>();

  const { teams } = useCurrentUser().accessMatrix;

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (!teams?.edit) {
    return <ForbiddenComponent />;
  }

  return <TeamEditContainer id={id} />;
};

export default TeamEditScreen;
