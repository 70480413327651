import { Location } from 'history';
import { Route, Switch } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { ECmsContainerType } from '../../../domain/model/enums';
import { UUID } from '../../../domain/model/types';
import rootRouting from '../../../routing';
import NotFoundScreen from '../../screens/notFound';
import { ECmsContainerUrlParam } from '../cms/container/types';
import { ContentLoaderSimple } from '../../components/common/loader';
import React, { Suspense } from 'react';

const PartnerCmsContainerCreateScreen = React.lazy(() => import('../../screens/partnerCms/containerCreate'));
const PartnerCmsContainerDetailsScreen = React.lazy(() => import('../../screens/partnerCms/containerDetails'));
const PartnerCmsContainerEditScreen = React.lazy(() => import('../../screens/partnerCms/containerEdit'));
const PartnerWindowScreen = React.lazy(() => import('../../screens/partnerWindow'));

const routing = {
  root: `${rootRouting.partnerWindow}`,
  cmsContainerCreate: `${rootRouting.partnerWindow}/cms/containers/create`,
  cmsContainerEdit: `${rootRouting.partnerWindow}/cms/containers/:id/edit`,
  cmsContainerDetails: `${rootRouting.partnerWindow}/cms/containers/:id/details`,
};

export interface PartnerCmsContainerCreateLocationState {
  readonly guid: UUID;
}

export interface PartnerCmsContainerEditLocationState {
  readonly guid: UUID;
}

export interface PartnerCmsContainerDetailsLocationState {
  readonly guid: UUID;
}

export interface PartnerWindowLocationState {
  readonly guid: UUID;
}

export const getPartnerWindowRootRouteDefinition = () => routing.root;

interface GetPartnerCmsContainerCreateRouteProps {
  readonly location?: any;
  readonly guid?: UUID;
  readonly type: ECmsContainerType;
  readonly step?: number;
}

interface GetPartnerCmsContainerEditRouteProps {
  readonly location?: any;
  readonly guid?: UUID;
  readonly id: UUID;
  readonly step?: number;
}

interface GetPartnerCmsContainerDetailsRouteProps {
  readonly location?: any;
  readonly guid?: UUID;
  readonly id: UUID;
  readonly step?: number;
}

export const getPartnerCmsContainerCreateRoute = (
  props: GetPartnerCmsContainerCreateRouteProps
): Location<PartnerCmsContainerCreateLocationState> => {
  const { location, guid, step, type } = props;

  const searchParams = new URLSearchParams(location?.params);

  searchParams.set(ECmsContainerUrlParam.Type, type);

  if (step) {
    searchParams.set(ECmsContainerUrlParam.Step, `${step}`);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: routing.cmsContainerCreate,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

export const getPartnerCmsContainerEditRoute = (
  props: GetPartnerCmsContainerEditRouteProps
): Location<PartnerCmsContainerEditLocationState> => {
  const { id, location, guid, step } = props;

  const searchParams = new URLSearchParams(location?.params);

  if (step) {
    searchParams.set(ECmsContainerUrlParam.Step, `${step}`);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: routing.cmsContainerEdit.replace(':id', id),
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

export const getPartnerCmsContainerDetailsRoute = (
  props: GetPartnerCmsContainerDetailsRouteProps
): Location<PartnerCmsContainerDetailsLocationState> => {
  const { id, location, guid, step } = props;

  const searchParams = new URLSearchParams(location?.params);

  if (step) {
    searchParams.set(ECmsContainerUrlParam.Step, `${step}`);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: routing.cmsContainerDetails.replace(':id', id),
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

const PartnerWindowEntry = () => {
  return (
    <Suspense fallback={<ContentLoaderSimple />}>
      <Switch>
        <Route
          path={routing.cmsContainerCreate}
          component={PartnerCmsContainerCreateScreen}
        />
        <Route
          path={routing.cmsContainerEdit}
          component={PartnerCmsContainerEditScreen}
        />
        <Route
          path={routing.cmsContainerDetails}
          component={PartnerCmsContainerDetailsScreen}
        />
        <Route
          path={routing.root}
          component={PartnerWindowScreen}
        />

        <Route component={NotFoundScreen} />
      </Switch>
    </Suspense>
  );
};

export default PartnerWindowEntry;
