import Api from '../../../../data/api';
import { ApiCancellable, ApiQueryDsl } from '../../../../data/api/types';
import { DataFilterQueryDslOperator } from '../../../../domain/model/filter';
import { Nullable } from '../../../../domain/model/types';
import { ECmsTermTag } from '../container/types';
import { CmsTermValueSuggestion } from '../types';

type SearchProps = ApiCancellable & {
  readonly value: Nullable<string>;
  readonly tag?: ECmsTermTag;
};

export type CmsTermService = {
  readonly search: (props: SearchProps) => Promise<CmsTermValueSuggestion[]>;
};

const cmsTermService: CmsTermService = {
  search: async ({ value, tag, signal }) => {
    const querydsl: ApiQueryDsl = [];

    if (value) {
      querydsl.push({
        field: 'value',
        operator: DataFilterQueryDslOperator.Like,
        value: [`*${value}*`],
      });
    }

    if (tag) {
      querydsl.push({
        field: 'tags',
        operator: DataFilterQueryDslOperator.In,
        value: [tag],
      });
    }

    const { data } = await Api.cms.term.all({
      querydsl,
      signal,
    });

    const result: CmsTermValueSuggestion[] = data.map((d, i) => ({
      id: (i + 1).toString(),
      name: d.value,
    }));

    return result;
  },
};

export default cmsTermService;
