import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UUID } from '../../../../../domain/model/types';
import { EPartnerTableTab } from '../../../general/partner/types';
import { partnersGuidSelector } from '../store/selectors';
import { partnersSetTab, partnersStartSession } from '../store/slice';

interface UsePartnersTableSessionProps {
  readonly guid: UUID;
  readonly tab: EPartnerTableTab;
}

const usePartnersTableSession = (props: UsePartnersTableSessionProps): void => {
  const { guid, tab } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(partnersGuidSelector);

  // инициализация сеанса
  useEffect(() => {
      dispatch(partnersStartSession({ guid }));
  }, [dispatch, guid]);
  
  // начальная загрузка, изменение вкладки
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(partnersSetTab({ tab }));
    }
  }, [dispatch, guid, currentGuid, tab]);
};

export default usePartnersTableSession;
