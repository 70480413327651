import { BookingOfferShort, CorpOffer, EOfferType, TradeOffer } from '@/domain';
import useDialogInHistory from '@/presentation/hooks/useDialogInHistory';
import { EOfferTableTab } from '@features/general/offer/types';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AdCampaignCollectionContentType } from './types';

type UseAdCampaignCollectionOffer<T extends AdCampaignCollectionContentType> = {
  readonly isSearchDialogOpened: boolean;
  readonly searchDialogParams: UseDialogInHistoryParams;
  readonly onOpenSearchDialog: (tab?: EOfferTableTab) => void;
  readonly onCloseSearchDialog: () => void;
  readonly onChangeSearchDialogTab: (tab: EOfferTableTab) => void;
  readonly onSelect: (offers: T[]) => void;
  readonly onUnselect: (id: UUID) => void;
  readonly onClear: () => void;
};

type UseAdCampaignCollectionTradeOfferProps = {
  readonly offerType: EOfferType.Trade;
  readonly selected: TradeOffer[];
  readonly onChange: (offers: TradeOffer[]) => void;
};

type UseAdCampaignCollectionCorpOfferProps = {
  readonly offerType: EOfferType.Corp;
  readonly selected: CorpOffer[];
  readonly onChange: (offers: CorpOffer[]) => void;
};

type UseAdCampaignCollectionBookingOfferProps = {
  readonly offerType: EOfferType.Booking;
  readonly selected: BookingOfferShort[];
  readonly onChange: (offers: BookingOfferShort[]) => void;
};

type UseAdCampaignCollectionOfferProps<T extends AdCampaignCollectionContentType> = T extends TradeOffer
  ? UseAdCampaignCollectionTradeOfferProps
  : T extends CorpOffer
  ? UseAdCampaignCollectionCorpOfferProps
  : UseAdCampaignCollectionBookingOfferProps;

interface UseDialogInHistoryParams {
  readonly dialog_guid: UUID;
  readonly dialog_tab: EOfferTableTab;
}

const useAdCampaignCollectionOffer = <T extends AdCampaignCollectionContentType>(
  props: UseAdCampaignCollectionOfferProps<T>
): UseAdCampaignCollectionOffer<T> => {
  const { selected } = props;

  const {
    open: isSearchDialogOpened,
    params: searchDialogParams,
    onOpen: onOpenSearchDialog,
    onClose: onCloseSearchDialog,
  } = useDialogInHistory<UseDialogInHistoryParams>({
    tag: props.offerType,
    params: { dialog_guid: uuidv4(), dialog_tab: EOfferTableTab.Active },
  });

  const onOpenSearchDialogInternal = useCallback(
    (tab?: EOfferTableTab) => onOpenSearchDialog({ dialog_guid: uuidv4(), dialog_tab: tab ?? EOfferTableTab.Active }),
    [onOpenSearchDialog]
  );

  const onChangeSearchDialogTab = useCallback(
    (tab: EOfferTableTab) => onOpenSearchDialog({ ...searchDialogParams, dialog_tab: tab }),
    [searchDialogParams]
  );

  const onChangeInternal = useCallback(
    (newSelected: T[]) => {
      switch (props.offerType) {
        case EOfferType.Corp:
          props.onChange(newSelected as CorpOffer[]);
          break;
        case EOfferType.Trade:
          props.onChange(newSelected as TradeOffer[]);
          break;
        case EOfferType.Booking:
          props.onChange(newSelected as BookingOfferShort[]);
          break;
      }
    },
    [props.offerType, props.onChange]
  );

  const onSelect = useCallback(
    (newSelected: T[]) => {
      onChangeInternal(newSelected);
      onCloseSearchDialog();
    },
    [onChangeInternal, onCloseSearchDialog]
  );

  const onClear = useCallback(() => {
    onChangeInternal([]);
  }, [onChangeInternal]);

  const onUnselect = useCallback(
    (id: UUID) => {
      const arr: T[] = [...(selected as T[])];
      const index = selected.findIndex(o => o.id === id);

      if (index !== -1) {
        arr.splice(index, 1);
        onChangeInternal(arr);
      }
    },
    [selected, onChangeInternal]
  );

  return {
    searchDialogParams,
    isSearchDialogOpened,
    onOpenSearchDialog: onOpenSearchDialogInternal,
    onCloseSearchDialog,
    onChangeSearchDialogTab,
    onSelect,
    onClear,
    onUnselect,
  };
};

export default useAdCampaignCollectionOffer;
