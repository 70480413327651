export enum EUserMPActionType {
  Save = 'save',
  Edit = 'edit',
  Activate = 'activate',
  Deactivate = 'deactivate',
  Logout = 'logout',
}

export type UserMPEditActionType = EUserMPActionType.Save;
export type UserMPDetailsActionType = Exclude<EUserMPActionType, EUserMPActionType.Save>;
