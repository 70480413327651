import { Checkbox, Chip } from '@mui/material';
import { DataFilterStrategyListItem, DataFilterStrategyListMultiple } from '../../../../../../domain/model/filter';
import { Nullable } from '../../../../../../domain/model/types';
import { MPAutocompleteMultipleSelect } from '../../../../../theme/ui-kit/autocomplete/multiple';
import { DataFilterEditItemProps } from '../index';

interface DataFilterEditItemListMultipleProps<T extends string> extends DataFilterEditItemProps<T, Nullable<string[]>> {
  readonly strategy: DataFilterStrategyListMultiple<T>;
}

function DataFilterEditItemListMultiple<T extends string>(props: DataFilterEditItemListMultipleProps<T>) {
  const { strategy, onChange } = props;

  const { label, value, readOnly } = strategy;

  const items = strategy.items ?? [];

  const onSubmitValue = (newValue: Nullable<DataFilterStrategyListItem[]>) => {
    const newValueOut = newValue?.length ? newValue.map(v => v.id) : null;
    onChange(strategy, newValueOut);
  };

  const values = items.filter(item => value?.some(lv => lv === item.id));

  return (
    <MPAutocompleteMultipleSelect<DataFilterStrategyListItem>
      value={values}
      label={label}
      disabled={readOnly}
      options={items}
      disableCloseOnSelect
      onChangeValue={onSubmitValue}
      renderOption={(renderProps, option, { selected }) => (
        <li {...renderProps}>
          <Checkbox
            color='primary'
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            component={'div' as any}
            selected
            label={option.name}
            color='primary'
            {...getTagProps({ index })}
            key={option.id}
          />
        ))
      }
    />
  );
}

export default DataFilterEditItemListMultiple;
