import { useSelector } from 'react-redux';
import { EAdCampaignStep } from '../../types';
import { StepWrapper } from '../controls';
import { useContextParams } from '../hooks/useContextParams';
import AdCampaignCreateStepGeneral from '../steps/general';
import { adCampaignCreateAdCampaignIsPresentSelector } from '../store/selectors';

const currentStep = EAdCampaignStep.General;

export const AdCampaignCreateStepGeneralAdapter = () => {
  const { step } = useContextParams();

  const adCampaignIsPresent = useSelector(adCampaignCreateAdCampaignIsPresentSelector);

  if (!adCampaignIsPresent) {
    return null;
  }

  return step === currentStep ? (
    <StepWrapper>
      <AdCampaignCreateStepGeneral />
    </StepWrapper>
  ) : null;
};
