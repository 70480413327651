import { Switch, Typography } from '@mui/material';
import React from 'react';
import { EOfferServiceStatus } from '../../../../../../domain/model/enums';
import { BookingOfferEditFieldServiceProps } from '../types';
import { Tooltip } from './controls';

const fieldName = 'status';

export const BookingOfferEditFieldServiceStatus = (props: BookingOfferEditFieldServiceProps<typeof fieldName>) => {
  const { value, disabled, onChangeAttribute, onValidateAttribute } = props;

  const enabled = value === EOfferServiceStatus.Enabled;

  const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeAttribute(fieldName, event.target.checked ? EOfferServiceStatus.Enabled : EOfferServiceStatus.Disabled);
    onValidateAttribute(fieldName);
  };

  return (
    <Tooltip
      title={
        <Typography variant='body2'>
          Управление услугами осуществляется посредством включения и выключения переключателя. Когда переключатель
          находится в зеленом положении, тогда услуга для клиента будет видна в составе услуг объекта. Когда
          переключатель находится в сером положении, услуга скрывается со страницы объекта.
        </Typography>
      }
      placement={'bottom-start'}
    >
      <div>
        <Switch
          color='primary'
          checked={enabled}
          disabled={disabled}
          onChange={onChangeInternal}
        />
      </div>
    </Tooltip>
  );
};
