import { ClientOrgCreateUiFieldsState } from '../uiState/fields';
import { ClientOrgCreateUiStepsState } from '../uiState/steps';

export const ClientOrgCreateUiStateAdapter = () => {
  return (
    <>
      <ClientOrgCreateUiStepsState />
      <ClientOrgCreateUiFieldsState />
    </>
  );
};
