import { Chip, Grid } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Api from '../../../../../data/api';
import { ProductCategory } from '../../../../../domain/model/productOffer';
import { Nullable, UUID } from '../../../../../domain/model/types';
import TopPushDialog from '../../../../components/common/dialogs/topPush';
import SelectCascade from '../../../../components/common/selectCascade/container';
import { CascadeItem } from '../../../../components/common/selectCascade/types';
import { MPButton } from '../../../../theme/ui-kit/button';
import { viewConfig } from '../../../../theme/viewConfig';
import { getStorageData, setStorageData } from '../../../../utils/localStorage';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { productsCategoryListSelector, productsCategorySelector } from '../store/selectors';

type SS = {
  readonly partnerId: Nullable<UUID>;
};

const ProductTableToolbarAdapter = ({ partnerId }: SS) => {
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);

  const category = useSelector(productsCategorySelector);
  const categoryList = useSelector(productsCategoryListSelector);

  const handlers = useContextHandlers();

  const productCategory = useMemo(
    () =>
      categoryList?.map(category => ({
        id: category.id,
        name: category.name,
        parentId: category.parentId,
        attributes: null,
      })) || [],
    [categoryList]
  );

  const onSelectCategory = (
    item: CascadeItem<ProductCategory> | undefined,
    cascade: CascadeItem<ProductCategory>[]
  ) => {
    if (item) {
      handlers.onChangeCategory(item, cascade);
      setDialogVisible(false);
    }
  };

  const onClearCategory = () => {
    handlers.onChangeCategory(null, null);
  };

  const loadOptionsCategories = useCallback(async (): Promise<any> => {
    const dataFromStorage = getStorageData<ProductCategory[], true>(`productCategories-${partnerId}`, true);

    if (dataFromStorage) {
      return dataFromStorage;
    }

    const response = partnerId
      ? await Api.category.product.allUsed({
          partnerId,
          onlyLeafCategories: false,
        })
      : await Api.category.product.all({});

    const expires = new Date();
    // Храним данные 1 день
    expires.setDate(expires.getDate() + 1);
    setStorageData(`productCategories-${partnerId}`, response.data, expires.toISOString());
    return response.data;
  }, [partnerId]);

  return (
    <>
      <TopPushDialog
        fullScreen
        open={dialogVisible}
        title='Выбор категории'
        leftOffset={viewConfig.menuPanelWidth}
        onClose={() => setDialogVisible(false)}
      >
        <SelectCascade<ProductCategory>
          loadOptionsMethod={loadOptionsCategories}
          value={productCategory}
          onSelect={onSelectCategory}
        />
      </TopPushDialog>
      <Grid
        container
        spacing={2}
      >
        <Grid item>
          <MPButton
            size='small'
            onClick={() => setDialogVisible(true)}
          >
            Выбор категории
          </MPButton>
        </Grid>
        {category && categoryList && (
          <Grid item>
            <Chip
              label={categoryList.map(r => r.name).join(' > ')}
              color='primary'
              onDelete={onClearCategory}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ProductTableToolbarAdapter;
