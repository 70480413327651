import { forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CorpOffer } from '../../../../domain/model/corpOffer';
import { Nullable } from '../../../../domain/model/types';
import ActionsComponent from '../../../components/common/actions';
import TablePagination from '../../../components/common/table/pagination';
import { PanelAction } from '../../../types';
import { CorpOfferLifeCycle } from '../../general/offer/lifecycle/types';
import {
  EOfferActionType,
  EOfferTableTab,
  OfferActions,
  OfferActionTableCollectionType,
  OfferActionTableType,
} from '../../general/offer/types';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import CorpOffersTableChangeApprovalRegistry from './approvalRegistryChange/multiple';
import CorpOffersApproveDialog from './approveDialog/multiple';
import { FooterWrapper } from './controls';
import useCorpOfferCollectionActions from './hooks/useCollectionActions';
import { useContextHandlers } from './hooks/useContextHandlers';
import {
  corpOffersCollectionActionsSelector,
  corpOffersPageCountSelector,
  corpOffersPageNumberSelector,
  corpOffersSearchSelector,
  corpOffersSelectedSelector,
  corpOffersTotalCountSelector,
} from './store/selectors';
import { CorpOfferCollectionActionPayload } from './utils';

interface CorpOfferTableFooterContainerProps {
  readonly tab: EOfferTableTab;
  readonly allowedActions: OfferActions<OfferActionTableType>;
  readonly lifecycle: CorpOfferLifeCycle;
}

const CorpOfferTableFooterContainer = forwardRef((props: CorpOfferTableFooterContainerProps, ref: any) => {
  const { tab, lifecycle, allowedActions } = props;

  const { userSpecific } = useCurrentUser();

  const handlers = useContextHandlers();

  const [offersToSetApprovalRegistry, setOffersToSetApprovalRegistry] = useState<Nullable<CorpOffer[]>>(null);
  const [offersToApprove, setOffersToApprove] = useState<Nullable<CorpOffer[]>>(null);

  const pageNumber = useSelector(corpOffersPageNumberSelector);
  const search = useSelector(corpOffersSearchSelector);
  const totalCount = useSelector(corpOffersTotalCountSelector);
  const pageCount = useSelector(corpOffersPageCountSelector);

  const collectionActionProcesses = useSelector(corpOffersCollectionActionsSelector);
  const selectedCorpOffers = useSelector(corpOffersSelectedSelector);

  const onPanelAction = (action: PanelAction<OfferActionTableCollectionType, CorpOfferCollectionActionPayload>) => {
    const { type, payload } = action;
    const corpOffers = payload?.corpOffers ?? [];
    switch (type) {
      case EOfferActionType.Approve:
        const corpOffersToApprove = corpOffers.filter(offer =>
          lifecycle.build({ obj: offer, allowedActions }).can(EOfferActionType.Approve)
        );
        setOffersToApprove(corpOffersToApprove);
        break;
      case EOfferActionType.ChangeApprovalRegistry:
        const corpOffersToSetApprovalRegistry = corpOffers.filter(offer =>
          lifecycle.build({ obj: offer, allowedActions }).can(EOfferActionType.ChangeApprovalRegistry)
        );
        setOffersToSetApprovalRegistry(corpOffersToSetApprovalRegistry);
        break;
    }
  };

  const collectionActions = useCorpOfferCollectionActions({
    tab,
    userSpecific,
    corpOffers: selectedCorpOffers,
    lifecycle,
    allowedActions,
  });

  const actionsPanel = collectionActions.length > 0 && (
    <ActionsComponent<OfferActionTableCollectionType>
      actions={collectionActions.map(action => ({ ...action, payload: { corpOffers: selectedCorpOffers } }))}
      onAction={onPanelAction}
      navAdapter={null}
    />
  );

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='корпоративных предложений'
        onChangePageSize={handlers.onCorpOffersChangePageSize}
        onChangePage={handlers.onCorpOffersChangePage}
      />
      {actionsPanel && <FooterWrapper>{actionsPanel}</FooterWrapper>}
      {offersToSetApprovalRegistry?.length && (
        <CorpOffersTableChangeApprovalRegistry
          isExecuting={
            !!collectionActionProcesses.find(process => process.type === EOfferActionType.ChangeApprovalRegistry)
              ?.isFetching
          }
          corpOffersCount={offersToSetApprovalRegistry?.length}
          onChange={approvalRegistry =>
            handlers.onCorpOffersChangeApprovalRegistry(offersToSetApprovalRegistry, approvalRegistry)
          }
          onClose={() => setOffersToSetApprovalRegistry(null)}
        />
      )}
      {offersToApprove?.length && (
        <CorpOffersApproveDialog
          corpOffersCount={offersToApprove?.length}
          onExecute={() => handlers.onCorpOffersApprove(offersToApprove)}
          onClose={() => setOffersToApprove(null)}
        />
      )}
    </div>
  );
});

export default CorpOfferTableFooterContainer;
