import { RootState } from '../../../../../../data/store/store';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import { createCmsContainerCreateCurrentLinkedObjectByIndexSelector } from './slice';

export const cmsContainerCreateIdSelector = (guid: UUID, sourceId: Nullable<UUID>) => (store: RootState) => {
  if (sourceId) {
    return sourceId;
  }

  if (store.cms.container.create.guid !== guid) {
    return null;
  }

  return store.cms.container.create.byId.data?.id ?? null;
};
export const cmsContainerCreateGuidSelector = (store: RootState) => store.cms.container.create.guid;
export const cmsContainerCreateByIdSelector = (store: RootState) => store.cms.container.create.byId;
export const cmsContainerCreateComponentsSelector = (store: RootState) => store.cms.container.create.components;
export const cmsContainerCreateLinkedObjectsSelector = (store: RootState) => store.cms.container.create.linkedObjects;
export const cmsContainerCreateCurrentLinkedObjectSelector = (index: number) => (store: RootState) =>
  createCmsContainerCreateCurrentLinkedObjectByIndexSelector()(store, index);
export const cmsContainerCreateSaveSelector = (store: RootState) => store.cms.container.create.save;
export const cmsContainerCreatePublishSelector = (store: RootState) => store.cms.container.create.publish;
export const cmsContainerCreateDeleteSelector = (store: RootState) => store.cms.container.create.delete;
export const cmsContainerCreateDuplicateSelector = (store: RootState) => store.cms.container.create.duplicate;
