import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import { CorpActivationTableCellPromotionTypeProps } from '../../components/tableCell/promotionType';
import { corpActivationsCorpActivationByIndexSelector } from '../store/selectors';
import { CorpActivationTableCellAdapterProps } from './cell';

type CorpActivationTableCellPromotionTypeAdapterProps = Omit<CorpActivationTableCellAdapterProps, 'component'> & {
  readonly component: FC<CorpActivationTableCellPromotionTypeProps>;
};

const CorpActivationTableCellPromotionTypeAdapter = (props: CorpActivationTableCellPromotionTypeAdapterProps) => {
  const { index, component: Component } = props;

  const corpActivation = useSelector(corpActivationsCorpActivationByIndexSelector(index));

  const { corpPromotionTypes } = useSelector(nsiDataSelector);

  return useMemo(
    () =>
      corpActivation ? (
        <Component
          corpActivation={corpActivation}
          corpPromotionTypes={corpPromotionTypes}
        />
      ) : null,
    [Component, corpActivation, corpPromotionTypes]
  );
};

export default CorpActivationTableCellPromotionTypeAdapter;
