import { ReactNode, useMemo } from 'react';
import DataTableCellTextFormat from '../../../../components/common/table/cell/textFormat';
import { CmsContainerTableView } from '../../types';

export interface CmsContainerTableCellCommonProps {
  readonly cmsContainer: CmsContainerTableView;
  readonly isDisabled?: boolean;
  readonly isTextAccent?: boolean;
}

interface CmsContainerTableCellProps extends CmsContainerTableCellCommonProps {
  readonly value: ReactNode;
}

const CmsContainerTableCell = ({ value, isDisabled, isTextAccent }: CmsContainerTableCellProps) => {
  return useMemo(
    () => (
      <DataTableCellTextFormat
        disabled={isDisabled}
        bold={isTextAccent}
      >
        {value}
      </DataTableCellTextFormat>
    ),
    [isDisabled, isTextAccent, value]
  );
};

export default CmsContainerTableCell;
