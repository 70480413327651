import moment from 'moment';
import { EDateFormat } from '@/domain';
import CmsContainerTableCell, { CmsContainerTableCellCommonProps } from './index';

type CmsContainerTableCellStartDateProps = CmsContainerTableCellCommonProps;

const CmsContainerTableCellStartDate = (props: CmsContainerTableCellStartDateProps) => {
  const {
    cmsContainer: { startDate },
  } = props;

  const value = startDate && moment(startDate).format(EDateFormat.DisplayDefault);

  return (
    <CmsContainerTableCell
      {...props}
      value={value}
    />
  );
};

export default CmsContainerTableCellStartDate;
