import React, { FC, FCC } from 'react';
import TopPushDialog, { TopPushDialogProps } from '../../components/common/dialogs/topPush';
import RenderDecoratorContainer from '../../components/common/renderDecoratorContainer';
import { viewConfig } from '../../theme/viewConfig';
import { ContentWrapper, FooterWrapper, Wrapper } from './controls';

type MasterTopPushLayoutProps = Omit<TopPushDialogProps, 'footer' | 'onClose'> & {
  readonly footer?: FC;
  readonly decorators?: React.ReactNode[];
  readonly onClose?: () => void;
};

export const MasterTopPushLayout: FCC<MasterTopPushLayoutProps> = props => {
  const { footer: Footer, decorators = [], children, ...dialogProps } = props;

  return (
    <TopPushDialog
      fullScreen
      leftOffset={viewConfig.stepper.width}
      {...dialogProps}
    >
      <RenderDecoratorContainer decorators={decorators}>
        <Wrapper>
          <ContentWrapper>{children}</ContentWrapper>
          {Footer && (
            <FooterWrapper>
              <Footer />
            </FooterWrapper>
          )}
        </Wrapper>
      </RenderDecoratorContainer>
    </TopPushDialog>
  );
};
