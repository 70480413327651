import { Fade, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENoticeStatus } from '../../../../../domain/model/enums';
import { MpUserData } from '../../../../../domain/model/user';
import Notifier from '../../../../../system/notifier';
import MasterActionsComponent from '../../../../components/common/actions/master';
import useNavAdapter from '../../../../components/common/actions/navAdapter/hooks';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../components/common/header';
import ContentLoader from '../../../../components/common/loader';
import { DefaultContentWrapper } from '../../../../components/common/wrappers/content';
import { DefaultFooterWrapper } from '../../../../components/common/wrappers/footer';
import useHistoryExtensions from '../../../../hooks/useHistoryExtensions';
import useValidation from '../../../../hooks/validation/useValidation';
import { EPanelActionPosition, PanelAction, PanelActions } from '../../../../types';
import StepperContainer from '../../../general/stepper/container';
import { getAdminMpTableRoute } from '../entry';
import { EAdminMpTableTab } from '../table/utils';
import { AdminMpActionCreateType, EAdminMpActionType, EAdminMpStep } from '../types';
import { getAdminMpActionName } from '../utils';
import AdminMpCreate from './component';
import { ContainerWrapper, ContentContainer, LoaderWrapper, TitleWrapper } from './controls';
import { adminMpCreateSaveSelector, adminMpCreateSelector } from './store/selectors';
import { adminMpCreateInit, adminMpCreateReset, adminMpCreateSave, adminMpCreateSetAttribute } from './store/slice';
import { adminMpCreateProfileStepValidation, getAdminMpCreateStepText } from './utils';

interface AdminMpCreateContainerProps {
  readonly step: EAdminMpStep.Profile;
}

const validationRules = adminMpCreateProfileStepValidation;

const AdminMpCreateContainer = (props: AdminMpCreateContainerProps) => {
  const { step } = props;

  const dispatch = useDispatch();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const [validateOnChangeProfile, setValidateOnChangeProfile] = useState<boolean>(false);

  const { data: user } = useSelector(adminMpCreateSelector);
  const { isFetching: isSaving, isFetched: isSaved } = useSelector(adminMpCreateSaveSelector);

  const {
    validationResult: validationResultProfile,
    isValid: isValidProfile,
    validate: validateProfile,
  } = useValidation<MpUserData>({
    object: user,
    validateOnChange: validateOnChangeProfile,
    rules: validationRules,
  });

  const steps: [EAdminMpStep.Profile] = [EAdminMpStep.Profile];
  const actions: PanelActions<AdminMpActionCreateType> = [
    {
      type: EAdminMpActionType.Create,
      label: getAdminMpActionName(EAdminMpActionType.Create),
      primary: true,
      position: [EPanelActionPosition.Default],
    },
  ];

  const onAdminMpSave = () => {
    if (user) {
      const isValidObject = validateProfile();

      if (!isValidObject) {
        setValidateOnChangeProfile(true);
      } else {
        dispatch(adminMpCreateSave(user));
      }
    }
  };

  const onPanelAction = (action: PanelAction<AdminMpActionCreateType>) => {
    const { type } = action;
    switch (type) {
      case EAdminMpActionType.Create:
        onAdminMpSave();
        break;
    }
  };

  const onChangeAttribute = (name: keyof MpUserData, value: any) => {
    dispatch(adminMpCreateSetAttribute({ name, value }));
  };

  const onNextStep = () => {
    if (user) {
      const index = steps.indexOf(step);
      if (index < steps.length - 1) {
        switch (step) {
          case EAdminMpStep.Profile: {
            const isValidObject = validateProfile();
            if (!isValidObject) {
              setValidateOnChangeProfile(true);
              return;
            }
            break;
          }
          default: {
            console.error(`Unknown ETradeOfferStep ${step}`);
            return;
          }
        }
      }
    }
  };

  const onClose = useCallback(() => {
    gotoPrevIndependentLocation(getAdminMpTableRoute({ tab: EAdminMpTableTab.Active }));
  }, [gotoPrevIndependentLocation]);

  useEffect(() => {
    if (isSaved) {
      Notifier.getInstance().addNotice(ENoticeStatus.Success, `Сотрудник добавлен`);
      onClose();
    }
  }, [isSaved, onClose]);

  useEffect(() => {
    dispatch(adminMpCreateInit());
    return () => {
      dispatch(adminMpCreateReset());
    };
  }, [dispatch]);

  const activeStepIndex = steps.indexOf(step);

  const mpSteps = steps.map(item => ({
    key: item,
    label: getAdminMpCreateStepText(item),
  }));
  const mpStep = mpSteps.find(item => item.key === step) ?? mpSteps[0];

  const { adapter: navAdapter, actions: navActions } = useNavAdapter({
    openPrevStep: () => {},
    currentStepIndex: activeStepIndex,
    openNextStep: onNextStep,
    stepsCount: steps.length,
  });

  const actionsPanel = (
    <MasterActionsComponent<AdminMpActionCreateType>
      show={!!actions.length || !!navActions.length}
      navAdapter={navAdapter}
      actions={actions}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );

  return (
    <Fade in>
      <ContainerWrapper>
        <StepperContainer<EAdminMpStep.Profile>
          step={mpStep}
          steps={mpSteps}
        />
        <ContentContainer>
          <DefaultContentWrapper
            type='details'
            stickyHeader
            fullHeight
            footer={actionsPanel}
          >
            <DefaultHeader
              sticky
              headline={<AppBreadcrumbs />}
              onClose={onClose}
            >
              <TitleWrapper>
                <Typography variant='h2'>Добавление администратора</Typography>
              </TitleWrapper>
            </DefaultHeader>
            {user && (
              <AdminMpCreate
                step={step}
                user={user}
                isValidProfile={isValidProfile}
                validationProfile={validationResultProfile}
                onChangeAttribute={onChangeAttribute}
                onClose={onClose}
              />
            )}
          </DefaultContentWrapper>
        </ContentContainer>
        {isSaving && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
      </ContainerWrapper>
    </Fade>
  );
};

export default AdminMpCreateContainer;
