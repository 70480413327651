import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notice } from '../../../../domain/model';
import { Snackbar, Wrapper } from './controls';
import NoticeItem from './notice';
import { notifierGetNotices } from './store/selectors';
import { closeNotice } from './store/slice';

interface NotifierContainerProps {}

const NotifierContainer = forwardRef((props: NotifierContainerProps, ref) => {
  const dispatch = useDispatch();

  const notices = useSelector(notifierGetNotices);

  const onClose = (notice: Notice) => {
    dispatch(closeNotice({ ...notice }));
  };

  return (
    <Wrapper>
      {notices &&
        notices.map(notice => (
          <Snackbar
            ref={ref}
            key={Math.random()}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={!!notice}
            autoHideDuration={10000}
            onClose={() => onClose(notice)}
          >
            <NoticeItem
              variant={notice.status}
              title={notice.title}
              message={notice.text}
              onClose={() => onClose(notice)}
            />
          </Snackbar>
        ))}
    </Wrapper>
  );
});

export default NotifierContainer;
