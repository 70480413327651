import { AdCampaignTableCell, AdCampaignTableCellCommonProps } from './index';

export const AdCampaignTableCellPartner = (props: AdCampaignTableCellCommonProps) => {
  const { adCampaign } = props;

  const value = adCampaign.partner?.name || '-';

  return (
    <AdCampaignTableCell
      {...props}
      value={value}
    />
  );
};
