import { Nullable, UUID } from '@/domain/model/types';
import { ECorpActivationTableTab } from '../../types';
import { useCorpActivationTableSearch } from '../hooks/useSearch';
import { useCorpActivationTableSession } from '../hooks/useSession';

type CorpActivationTableBehaviorAdapterProps = {
  readonly guid: UUID;
  readonly tab: ECorpActivationTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly customerId: Nullable<UUID>;
};

export const CorpActivationTableBehaviorAdapter = ({
  guid,
  tab,
  partnerId,
  customerId,
}: CorpActivationTableBehaviorAdapterProps) => {
  useCorpActivationTableSession({ guid, tab });
  useCorpActivationTableSearch({ tab, partnerId, customerId });
  return null;
};
