import { SidePanelContentWrapper } from '@components/common/wrappers/sidePanel';
import { ClientOrgCreatePreviewAdapter } from './preview';

export const ClientOrgCreateSidePanelsAdapter = () => {
  return (
    <SidePanelContentWrapper>
      <ClientOrgCreatePreviewAdapter />
    </SidePanelContentWrapper>
  );
};
