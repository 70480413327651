import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { UUID } from '@/domain/model/types';
import { getComServicesEndpoint } from './utils';
import { fileTypes } from '@/presentation/constants';

type ByClientOrgIdProps = {
  readonly clientOrgId: UUID;
};

type ImportProps = ByClientOrgIdProps & {
  readonly file: File;
  readonly config?: AxiosRequestConfig;
};

type ClientOrgUserApi = {
  readonly import: (props: ImportProps) => Promise<AxiosResponse<string>>;
};

/**
 * АПИ по работе с пользователями клиентов
 */
const clientOrgUser: ClientOrgUserApi = {
  import: ({ clientOrgId, file, config }) => {
    const formData = new FormData();
    formData.append('file', file);
    const reqConfig: AxiosRequestConfig = {
      ...config,
      responseType: 'blob',
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    reqConfig.headers!.accept = fileTypes.xls.mimeType;

    return axios.post(`${getComServicesEndpoint()}/client-orgs/${clientOrgId}/users/import`, formData, reqConfig);
  },
};

export default clientOrgUser;
