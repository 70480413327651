import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getBookingOfferActionsOfferIsProcessingSelector } from '../../actions/store/selectors';
import { BookingOfferEditFieldServiceProps } from '../../components/fieldsEdit/types';
import { getEmptyOfferServiceView } from '../../create/utils';
import { BookingOfferServiceView } from '../../types';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { useContextParams } from '../hooks/useContextParams';
import {
  bookingOfferServiceManageServiceByIndexSelector,
  bookingOfferServiceManageValidationServicesResultByIndexSelector,
} from '../store/selectros';

interface BookingOfferServiceManageServiceFieldAdapterProps<Key extends keyof BookingOfferServiceView> {
  readonly index: number;
  readonly name: Key;
  readonly component: FC<BookingOfferEditFieldServiceProps<Key>>;
}

export const BookingOfferServiceManageServiceFieldAdapter = <Key extends keyof BookingOfferServiceView>(
  props: BookingOfferServiceManageServiceFieldAdapterProps<Key>
) => {
  const { index, name, component: Component } = props;

  const handlers = useContextHandlers();
  const { id } = useContextParams();
  const isSaving = useSelector(getBookingOfferActionsOfferIsProcessingSelector(id));

  const service = useSelector(bookingOfferServiceManageServiceByIndexSelector(index));
  const validation = useSelector(bookingOfferServiceManageValidationServicesResultByIndexSelector(index));

  const internalService = service ?? getEmptyOfferServiceView();

  return (
    <Component
      index={index}
      value={internalService[name]}
      validation={validation?.[name] ?? null}
      disabled={isSaving}
      onChangeAttribute={(field, value) => handlers.onChangeServiceAttribute(index, field, value)}
      onValidateAttribute={field => handlers.onServiceAttributeValidate(index, field)}
    />
  );
};
