import { Nullable, UUID } from '../../../../../domain/model/types';
import { EBookingOrderTableTab } from '../../types';
import { useBookingOrderTableSearch } from '../hooks/useSearch';
import { useBookingOrderTableSession } from '../hooks/useSession';

type BookingOrderTableBehaviorAdapterProps = {
  readonly guid: UUID;
  readonly tab: EBookingOrderTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly customerId: Nullable<UUID>;
};

export const BookingOrderTableBehaviorAdapter = ({
  guid,
  tab,
  partnerId,
  customerId,
}: BookingOrderTableBehaviorAdapterProps) => {
  useBookingOrderTableSession({ guid, tab });
  useBookingOrderTableSearch({ tab, partnerId, customerId });

  return null;
};
