import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { FormItem } from '@components/forms/item';
import { formatCost } from '../../../../utils';
import BookingOrderItemsTable from '../../components/itemsTable';
import {
  bookingOrderDetailsBookingOrderCostSelector,
  bookingOrderDetailsBookingOrderItemsSelector,
  bookingOrderDetailsBookingOrderObjectNameSelector,
} from '../store/selectors';

export const BookingOrderDetailsItemsTableAdapter = () => {
  const items = useSelector(bookingOrderDetailsBookingOrderItemsSelector);
  const objectName = useSelector(bookingOrderDetailsBookingOrderObjectNameSelector);
  const cost = useSelector(bookingOrderDetailsBookingOrderCostSelector);

  return (
    <Grid
      item
      xs={12}
    >
      <FormItem title={`Состав заказа по объекту «${objectName}»`}>
        <BookingOrderItemsTable items={items} />
        <Typography variant='h3'>Итого: {formatCost(cost)}</Typography>
      </FormItem>
    </Grid>
  );
};
