import {
  ContentTargetNsi,
  DataFilterStrategyBase,
  DataFilterStrategyString,
  DataFilterStrategyView,
  DataFilterValueItem,
  EDataFilterType,
  EMultiSelectorValueType,
  Nullable,
  Target,
} from '@/domain';
import { MutableDataTableColumns, TableColumn } from '@components/common/table';
import { AddressHelper } from './address';
import { getTableFilterItemsByTableColumns } from './tables';

export const getDataFilterValueByStrategy = <T extends string>(
  strategy: DataFilterStrategyBase<T>
): DataFilterValueItem<T> => {
  const { value, querydsl, type } = strategy;
  if (querydsl) {
    return {
      type,
      value: value as T,
      querydsl,
    };
  } else {
    return {
      type,
      value: value as T,
    };
  }
};

export const isMultipleSelectorPredicateType = (type: EDataFilterType) => {
  switch (type) {
    case EDataFilterType.MultiSelectorPredicateOrgUnit:
    case EDataFilterType.MultiSelectorPredicateClientOrg:
    case EDataFilterType.MultiSelectorPredicateAddress:
    case EDataFilterType.MultiSelectorPredicateSportOption:
    case EDataFilterType.MultiSelectorPredicateRoad:
      return true;
    case EDataFilterType.AddressLocalityMultiple:
    case EDataFilterType.AddressLocalitySingle:
    case EDataFilterType.AddressSingle:
    case EDataFilterType.AutocompleteMultiple:
    case EDataFilterType.AutocompleteSingle:
    case EDataFilterType.BooleanFlag:
    case EDataFilterType.CityMultipleAddress:
    case EDataFilterType.Date:
    case EDataFilterType.DateAsTimePeriod:
    case EDataFilterType.DateFrom:
    case EDataFilterType.DateTo:
    case EDataFilterType.ListMultiple:
    case EDataFilterType.ListSingle:
    case EDataFilterType.Number:
    case EDataFilterType.Year:
    case EDataFilterType.String:
    case EDataFilterType.Target:
    case EDataFilterType.AdapterTreeMultiple:
    case EDataFilterType.NumberPeriod:
    case EDataFilterType.DatePeriod:
      return false;
  }
};

export const getDataFilterValuesByStrategies = <T extends string, R>(strategies: DataFilterStrategyBase<T>[]): R => {
  return strategies.reduce<R>((previous, current) => {
    return {
      ...previous,
      [current.key]: getDataFilterValueByStrategy(current),
    };
  }, {} as R);
};

export const clearDataFilterStrategyValue = <T extends string>(
  strategies: DataFilterStrategyBase<T>[],
  strategy: DataFilterStrategyView<T>
) => {
  let temp = [...strategies];
  switch (strategy.type) {
    case EDataFilterType.String:
      {
        const stringStrategy = strategy as DataFilterStrategyString<T>;
        if (!stringStrategy.group) {
          temp = temp.map(t => (t.key !== strategy.key ? t : { ...t, value: null }));
        } else {
          temp = temp
            .filter(
              s =>
                s.type === EDataFilterType.String && (s as DataFilterStrategyString<T>).group === stringStrategy.group
            )
            .map(t => ({ ...t, value: null }));
        }
      }
      break;
    default:
      temp = temp.map(t => (t.key !== strategy.key ? t : { ...t, value: null }));
  }
  return temp;
};

// возвращает факт наличия заполненного значения фильтра среди указанных ключей (ключи это любой енам который бьется с ключами фильтров)
export const hasDataFilterAnyStrategyValue = <T extends string, C extends string>(props: {
  filterValues: Partial<Record<T, DataFilterValueItem<Nullable<any>>>>;
  keys: C[];
}): boolean => {
  return Object.entries(props.filterValues).some(item => item[1] !== null && props.keys.includes(item[0] as C));
};

export interface GetDataFilterStrategiesByColumnsProps<C extends string, F extends string> {
  columns: MutableDataTableColumns<C>;
  filterValues: Partial<Record<F, DataFilterValueItem<Nullable<any>>>>;
  filters: Record<C, F[]>;
  requiredFilters?: F[];
  getDataFilterStrategy: (
    item: F,
    values: Partial<Record<F, DataFilterValueItem<Nullable<any>>>>
  ) => Nullable<DataFilterStrategyBase<F>>;
}

// возвращает по колонкам только нужные фильтры
export const getDataFilterStrategiesByColumns = <C extends string, F extends string>(
  props: GetDataFilterStrategiesByColumnsProps<C, F>
): DataFilterStrategyBase<F>[] => {
  const { columns, filterValues, filters, requiredFilters, getDataFilterStrategy } = props;

  // определяем начальные стратегии, в них сразу входят обязательные
  const strategies: DataFilterStrategyBase<F>[] =
    (requiredFilters
      ?.map(filter => getDataFilterStrategy(filter, filterValues))
      ?.filter(strategy => !!strategy) as DataFilterStrategyBase<F>[]) ?? [];

  // идем по колонкам
  Object.entries(columns).forEach(item => {
    const tableColumn = item[0] as C;

    // получаем фильтры для этой колонки
    const filterItems: F[] = getTableFilterItemsByTableColumns(filters, [tableColumn]);

    // идем по фильтрам
    filterItems.forEach(filterItem => {
      // получаем стратегии фильтрации по фильтрам колонки
      const strategy = getDataFilterStrategy(filterItem, filterValues);

      // пихаем в результат, если есть стратегия и либо колонка не скрыта, либо readonly
      if (strategy && (!(item[1] as TableColumn).hidden || strategy.readOnly)) {
        strategies.push(strategy);
      }
    });
  });

  return strategies;
};

type CalculateDataFilterStrategiesChangesResult<F extends string> = {
  readonly removedStrategies: DataFilterStrategyBase<F>[];
  readonly addedStrategies: DataFilterStrategyBase<F>[];
};

export const calculateDataFilterStrategiesChanges = <F extends string>(
  prevStrategies: Nullable<DataFilterStrategyBase<F>[]>,
  currentStrategies: Nullable<DataFilterStrategyBase<F>[]>
): CalculateDataFilterStrategiesChangesResult<F> => {
  const addedStrategies: DataFilterStrategyBase<F>[] = [];
  const removedStrategies: DataFilterStrategyBase<F>[] = [];

  currentStrategies?.forEach(current => {
    const prevStrategy = prevStrategies?.find(prev => prev.key === current.key);
    if (!prevStrategy) {
      addedStrategies.push(current);
    }
  });

  prevStrategies?.forEach(prev => {
    const currentStrategy = currentStrategies?.find(current => current.key === prev.key);
    if (!currentStrategy) {
      removedStrategies.push(prev);
    }
  });

  return {
    removedStrategies,
    addedStrategies,
  };
};
export const getDataFilterTargetPreviews = (target: Nullable<Target>, nsi: ContentTargetNsi): Nullable<string[]> => {
  const names: string[] = [];

  if (!target) return names;
  const { genderTypes } = nsi;
  const {
    targetLocalities,
    targetRoads,
    targetOrgUnits,
    targetGender,
    targetClientOrgs,
    targetExternalUsers,
    targetFamilyMemberOnly,
    targetTradeUnionMembersOnly,
    targetHavingChildFamilyMemberOnly,
  } = target;

  if (targetRoads) {
    if (targetRoads.select === EMultiSelectorValueType.All) {
      names.push('Все дороги');
    } else {
      if (targetRoads.in?.length) {
        names.push(targetRoads.in.map(road => `${road.name} дорога`).join(', '));
      }
    }
  }
  if (targetOrgUnits) {
    if (targetOrgUnits.select === EMultiSelectorValueType.All) {
      names.push('Все филиалы');
    } else {
      if (targetOrgUnits.in?.length) {
        names.push(targetOrgUnits.in.map(orgUnit => orgUnit.name).join(', '));
      }
    }
  }

  if (targetLocalities) {
    if (targetLocalities.select === EMultiSelectorValueType.All) {
      names.push('Вся Россия');
    } else {
      if (targetLocalities.in?.length) {
        names.push(
          targetLocalities.in
            .map(locality => new AddressHelper(locality).getLastLocalityShortPath() ?? locality.name)
            .join(', ')
        );
      }
    }
  }

  if (targetGender) {
    if (targetGender.select === EMultiSelectorValueType.All) {
      names.push('Для всех полов');
    } else {
      if (targetGender.in?.length) {
        const targetGenders = targetGender.in;
        names.push(
          genderTypes
            .filter(genderType => targetGenders.includes(genderType.id))
            .map(genderType => genderType.name)
            .join(', ')
        );
      }
    }
  }

  if (targetExternalUsers) {
    names.push('Внешние пользователи');
  }

  if (targetClientOrgs) {
    if (targetClientOrgs.select === EMultiSelectorValueType.All) {
      names.push('Все компании');
    } else {
      if (targetClientOrgs.in?.length) {
        names.push(targetClientOrgs.in.map(orgUnit => orgUnit.name).join(', '));
      }
    }
  }

  if (targetFamilyMemberOnly) {
    names.push('Если есть член семьи ОТ 18 лет');
  }
  if (targetHavingChildFamilyMemberOnly) {
    names.push('Если есть член семьи ДО 18 лет');
  }
  if (targetTradeUnionMembersOnly) {
    names.push('Для членов профсоюза');
  }

  return names.filter(item => !!item);
};
