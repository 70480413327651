import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { SportOption } from '../../../../domain/model';
import { CorpOffer } from '../../../../domain/model/corpOffer';
import { ApprovalOfferRegistryRequest } from '../../../../domain/model/offerApprovalRegistry';
import { MpUser } from '../../../../domain/model/user';
import { nsiCustomOfferPausedReasonsSelector, nsiDataSelector } from '../../general/nsi/store/selectors';
import OfferArchiveDialog from '../../general/offer/components/archiveDialog';
import OfferReasonsDialog from '../../general/offer/components/reasonsDialog';
import { isCorpOfferApprovalRegistryRequired } from '../utils';
import CorpOfferTableChangeApprovalRegistry from './approvalRegistryChange/single';
import { useContextHandlers } from './hooks/useContextHandlers';
import { corpOffersDialogsStateSelector } from './store/selectors';
import { OfferChangeModeratorDialog } from '../../general/offer/components/changeModeratorDialog';
import { EOfferType } from '../../../../domain/model/enums';

const CorpOfferTableDialogs = () => {
  const handlers = useContextHandlers();

  const dialogs = useSelector(corpOffersDialogsStateSelector);
  const pauseReasons = useSelector(nsiCustomOfferPausedReasonsSelector);
  const { offerRejectReasons: rejectReasons } = useSelector(nsiDataSelector);

  const [isLoading, setIsLoading] = useState(false);

  const onReject = useCallback(
    async (corpOffer: CorpOffer, reason: SportOption, comment?: string) => {
      setIsLoading(true);
      await handlers.onCorpOfferReject(corpOffer, reason, comment).finally(() => {
        setIsLoading(false);
        handlers.onChangeDialogState('reject', null);
      });
    },
    [handlers.onChangeDialogState]
  );

  const onCloseReject = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('reject', null);
  }, [handlers.onChangeDialogState]);

  const onPause = useCallback(
    async (corpOffer: CorpOffer, reason: SportOption, comment?: string) => {
      setIsLoading(true);
      await handlers.onCorpOfferPause(corpOffer, reason, comment).finally(() => {
        setIsLoading(false);
        handlers.onChangeDialogState('pause', null);
      });
    },
    [handlers.onChangeDialogState]
  );

  const onClosePause = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('pause', null);
  }, [handlers.onChangeDialogState]);

  const onArchive = useCallback(
    async (corpOffer: CorpOffer) => {
      setIsLoading(true);
      await handlers.onCorpOfferArchive(corpOffer).finally(() => {
        setIsLoading(false);
        handlers.onChangeDialogState('archive', null);
      });
    },
    [handlers.onChangeDialogState]
  );

  const onCloseArchive = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('archive', null);
  }, [handlers.onChangeDialogState]);

  const onChangeModerator = useCallback(
    async (corpOffer: CorpOffer, user: MpUser) => {
      setIsLoading(true);
      await handlers.onCorpOfferChangeModerator(corpOffer, user).finally(() => {
        setIsLoading(false);
        handlers.onChangeDialogState('changeModerator', null);
      });
    },
    [handlers.onChangeDialogState]
  );

  const onCloseChangeModerator = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('changeModerator', null);
  }, [handlers.onChangeDialogState]);

  const onChangeApprovalRegistry = useCallback(
    async (corpOffer: CorpOffer, approvalRegistry: ApprovalOfferRegistryRequest) => {
      setIsLoading(true);
      await handlers.onCorpOfferChangeApprovalRegistry(corpOffer, approvalRegistry).finally(() => {
        setIsLoading(false);
        handlers.onChangeDialogState('approvalRegistry', null);
      });
    },
    [handlers.onChangeDialogState]
  );

  const onCloseChangeApprovalRegistry = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('approvalRegistry', null);
  }, [handlers.onChangeDialogState]);

  return (
    <>
      <OfferReasonsDialog
        offer={dialogs.reject}
        title='Отклонение корпоративного предложения'
        actionText='Отклонить'
        isFetching={isLoading}
        reasons={rejectReasons}
        onAction={onReject}
        onClose={onCloseReject}
      />
      <OfferReasonsDialog
        offer={dialogs.pause}
        title='Приостановка корпоративного предложения'
        actionText='Приостановить'
        isFetching={isLoading}
        reasons={pauseReasons}
        onAction={onPause}
        onClose={onClosePause}
      />
      <OfferArchiveDialog
        offer={dialogs.archive}
        offerLabel='корпоративное предложение'
        isExecuting={isLoading}
        onExecute={onArchive}
        onClose={onCloseArchive}
      />
      <OfferChangeModeratorDialog<CorpOffer>
        offer={dialogs.changeModerator}
        offerType={EOfferType.Corp}
        isExecuting={isLoading}
        onExecute={onChangeModerator}
        onClose={onCloseChangeModerator}
      />
      <CorpOfferTableChangeApprovalRegistry
        corpOffer={dialogs.approvalRegistry}
        isValidation={dialogs.approvalRegistry ? isCorpOfferApprovalRegistryRequired(dialogs.approvalRegistry) : false}
        isExecuting={isLoading}
        onChange={onChangeApprovalRegistry}
        onClose={onCloseChangeApprovalRegistry}
      />
    </>
  );
};

export default CorpOfferTableDialogs;
