import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TabsPanel from '../../../../components/common/tabs/panel';
import { EProductOrderTableTab } from '../../types';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { productOrdersTabsCounterSelector } from '../store/selectors';
import ProductOrderTableTab from '../tab';

interface ProductOrderTableTabsAdapterProps {
  readonly tab: EProductOrderTableTab;
}

export const ProductOrderTableTabsAdapter = (props: ProductOrderTableTabsAdapterProps) => {
  const { tab } = props;

  const { onChangeTab } = useContextHandlers();
  const { tabs } = useContextConfig();

  const counter = useSelector(productOrdersTabsCounterSelector);

  return useMemo(
    () => (
      <TabsPanel
        tab={tab}
        tabs={tabs}
        labelAdapter={ProductOrderTableTab}
        data={{
          counts: counter,
        }}
        onChangeTab={onChangeTab}
      />
    ),
    [tab, tabs, counter, onChangeTab]
  );
};
