import BookingOfferTableCell, { BookingOfferTableCellCommonProps } from './index';

const BookingOfferTableCellAddress = (props: BookingOfferTableCellCommonProps) => {
  const { bookingOffer } = props;
  const value = bookingOffer.address?.name || '-';

  return (
    <BookingOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default BookingOfferTableCellAddress;
