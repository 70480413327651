import { partition } from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../data/store/store';
import { EOrderPartition, ESortDirection } from '../../../../../domain/model/enums';
import { ProductOrder } from '../../../../../domain/model/order';
import { addSearchParamToLocation } from '../../../../../routing/globalRouting';
import { PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import { EOrderUrlParam, getOrderCreateRoute } from '../../../order/entry';
import { useProductOrderActions } from '../../actions/useAction';
import { EProductOrderTableColumn, EProductOrderTableTab } from '../../types';
import { EProductOrdersFilterItem, ProductOrdersFilterEditStrategy, ProductOrdersFilterValues } from '../filterUtils';
import { productOrdersGuidSelector } from '../store/selectors';
import {
  productOrdersDataReset,
  productOrdersNeedRefreshWatcherIncrement,
  productOrdersSetFilter,
  productOrdersSetPage,
  productOrdersSetPageSize,
  productOrdersSetSort,
  productOrdersSortReset,
} from '../store/slice';

export type UseProductOrderTable = {
  readonly onRefresh: () => void;
  readonly onResetSort: () => void;

  readonly onChangeTab: (newTab: EProductOrderTableTab) => void;
  readonly onChangeSort: (name: EProductOrderTableColumn, direction: ESortDirection) => void;
  readonly onChangeFilter: (strategies: ProductOrdersFilterEditStrategy[]) => void;
  readonly onChangePage: (num: number) => void;
  readonly onChangePageSize: (size: PaginationSize) => void;
  readonly onCreateOrder: () => void;
  readonly onSortReset: () => void;
  readonly onClearFilter: () => void;

  readonly onOrderTryCancel: (props: ProductOrder) => void;
  readonly onOrderSend: (props: ProductOrder) => void;
  readonly onOrderPay: (props: ProductOrder) => void;
  readonly onOrderPartiallyReturn: (props: ProductOrder) => void;
  readonly onOrderReturn: (props: ProductOrder) => void;
  readonly onOrderConfirm: (props: ProductOrder) => void;
  readonly onOrderRenew: (props: ProductOrder) => void;
  readonly onOrderGive: (props: ProductOrder) => void;
};

type UseProductOrderTableHandlersProps = {
  readonly tab: EProductOrderTableTab;
};

export const useProductOrderTableHandlers = ({tab}: UseProductOrderTableHandlersProps): UseProductOrderTable => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  
  const { onTryCancel, onSend, onPay, onPartiallyReturn, onReturn, onConfirm, onRenew, onGive } =
    useProductOrderActions();

  const getProductOrderGuidFromState = useCallback((): Nullable<UUID> => {
    const state = store.getState();
    return productOrdersGuidSelector(state);
  }, [store]);
  
  const onCreateOrder = useCallback(() => {
    history.push(getOrderCreateRoute({ partition: EOrderPartition.ProductOffers }));
  }, [history]);

  const onRefresh = useCallback(() => dispatch(productOrdersNeedRefreshWatcherIncrement()), [dispatch]);
  const onResetSort = useCallback(() => dispatch(productOrdersSortReset()), [dispatch]);

  const onChangeTab = useCallback(
    (newTab: EProductOrderTableTab) => {
      if (newTab !== tab) {
        const guid = getProductOrderGuidFromState();
        dispatch(productOrdersDataReset())
        history.replace(
          addSearchParamToLocation({
            location,
            param: EOrderUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [dispatch, history, location, tab, getProductOrderGuidFromState]
  );
  
  const onChangeFilter = useCallback(
    (strategies: ProductOrdersFilterEditStrategy[]) => {
      dispatch(
        productOrdersSetFilter({
          filter: getDataFilterValuesByStrategies<EProductOrdersFilterItem, ProductOrdersFilterValues>(strategies),
        })
      );
    },
    [partition]
  );

  const onChangePage = useCallback(
    (pageNumber: number) => {
      dispatch(productOrdersSetPage({ pageNumber }));
    },
    [dispatch]
  );

  const onChangeSort = useCallback(
    (name: EProductOrderTableColumn, direction: ESortDirection) => {
      dispatch(
        productOrdersSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(productOrdersSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onSortReset = useCallback(() => {
    dispatch(productOrdersSortReset());
  }, [dispatch]);

  const onClearFilter = useCallback(() => {
    dispatch(productOrdersSetFilter({ filter: {} }));
  }, [partition]);

  const onOrderTryCancel = useCallback<UseProductOrderTable['onOrderTryCancel']>(onTryCancel, [onTryCancel]);

  const onOrderSend = useCallback<UseProductOrderTable['onOrderSend']>(onSend, [onSend]);

  const onOrderPay = useCallback<UseProductOrderTable['onOrderPay']>(onPay, [onPay]);

  const onOrderPartiallyReturn = useCallback<UseProductOrderTable['onOrderPartiallyReturn']>(onPartiallyReturn, [
    onPartiallyReturn,
  ]);

  const onOrderReturn = useCallback<UseProductOrderTable['onOrderReturn']>(onReturn, [onReturn]);

  const onOrderConfirm = useCallback<UseProductOrderTable['onOrderConfirm']>(onConfirm, [onConfirm]);

  const onOrderRenew = useCallback<UseProductOrderTable['onOrderRenew']>(onRenew, [onRenew]);

  const onOrderGive = useCallback<UseProductOrderTable['onOrderGive']>(onGive, [onGive]);

  return {
    onRefresh,
    onResetSort,
    onChangeTab,
    onChangeSort,
    onChangeFilter,
    onChangePage,
    onChangePageSize,
    onSortReset,
    onClearFilter,
    onCreateOrder,
    onOrderTryCancel,
    onOrderSend,
    onOrderPay,
    onOrderPartiallyReturn,
    onOrderReturn,
    onOrderConfirm,
    onOrderRenew,
    onOrderGive,
  };
};
