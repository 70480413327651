import { ContentTarget } from '@/domain';
import CheckBoxContent from '../../../../../components/common/checkBoxContent';
import { ContentTargetViewComponent } from '../../types';

const attribute: keyof ContentTarget = 'externalUsers';

const ContentTargetViewExternalUsers: ContentTargetViewComponent = props => {
  const { target } = props;

  const value = target[attribute] ?? false;

  return (
    <CheckBoxContent
      label='Внешние пользователи'
      checked={value}
    />
  );
};

export default ContentTargetViewExternalUsers;
