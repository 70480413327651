import { ClientOrgCreate } from '@/domain';
import { ClientOrgCreateFieldCommonProps } from '@features/clientOrg/types';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  clientOrgCreateClientOrgFieldByNameSelector,
  clientOrgCreateClientOrgIsPresentSelector,
  clientOrgCreateIsFieldDisabledSelector,
  clientOrgCreateIsFieldVisibleSelector,
  clientOrgCreateValidationResultsByFieldSelector,
} from '../store/selectors';

interface ClientOrgCreateFieldAdapterProps<Key extends keyof ClientOrgCreate> {
  readonly name: Key;
  readonly component: FC<ClientOrgCreateFieldCommonProps<Key>>;
}

export const ClientOrgCreateFieldAdapter = <Key extends keyof ClientOrgCreate>(
  props: ClientOrgCreateFieldAdapterProps<Key>
) => {
  const { name, component: Component } = props;

  const handlers = useContextHandlers();

  const clientOrgIsPresent = useSelector(clientOrgCreateClientOrgIsPresentSelector);
  const value = useSelector(clientOrgCreateClientOrgFieldByNameSelector(name));
  const visible = useSelector(clientOrgCreateIsFieldVisibleSelector(name));
  const disabled = useSelector(clientOrgCreateIsFieldDisabledSelector(name));
  const validation = useSelector(clientOrgCreateValidationResultsByFieldSelector(name));

  return clientOrgIsPresent && visible ? (
    <Component
      value={value}
      disabled={disabled}
      validation={validation}
      onChangeAttribute={handlers.onChangeAttribute}
      onValidateAttribute={handlers.onAttributeValidate}
    />
  ) : null;
};
