import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Partner } from '../../../domain/model/partner';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import { viewShowPartnerManagementMenu } from '../../features/partner/management/container/store/slice';
import { TradeActivationsLocationState } from '../../features/tradeActivation/entry';
import TradeActivationTableEntry from '../../features/tradeActivation/table/entry';

interface PartnerManagementTradeActivationsScreenProps {
  readonly partner: Partner;
}

const PartnerManagementTradeActivationsScreen = ({ partner }: PartnerManagementTradeActivationsScreenProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowPartnerManagementMenu());
  }, [dispatch]);

  return (
    <LocationWithRequiredStateContainer<TradeActivationsLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <TradeActivationTableEntry
          guid={state.guid}
          partnerId={partner.id}
        />
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default PartnerManagementTradeActivationsScreen;
