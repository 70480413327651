import { CalendarProps } from 'react-multi-date-picker';
import { localeRu } from './locale';
import { ArrowLeftIcon, ArrowRightIcon } from '@mui/x-date-pickers';

export const permanentOptions: Partial<CalendarProps> = {
  weekStartDayIndex: 1,
  headerOrder: ['LEFT_BUTTON', 'MONTH_YEAR', 'RIGHT_BUTTON'],
  locale: localeRu,
  monthYearSeparator: ' ',
  renderButton: (direction: 'left' | 'right', handleClick: () => void) => {
    return (
      <>
        {direction === 'left' && (
          <ArrowLeftIcon
            fontSize='large'
            color='primary'
            onClick={handleClick}
          />
        )}
        {direction === 'right' && (
          <ArrowRightIcon
            fontSize='large'
            color='primary'
            onClick={handleClick}
          />
        )}
      </>
    );
  },
};

export const regularOptions: Partial<CalendarProps> = {
  showOtherDays: true,
  shadow: false,
  rangeHover: false,
};
