import { AnyOffer, CorpOffer, TradeOffer, UUID } from '@/domain';
import { DataTableColumns, DataTableMetadata } from '@components/common/table';
import { useMemo } from 'react';
import { EOfferTableColumn } from '../../types';
import OfferCollectionTable from './table';

interface OfferCollectionProps<T extends AnyOffer> {
  readonly offers: T[];
  readonly columns: DataTableColumns<EOfferTableColumn>;
  readonly onRemove?: (id: UUID) => void;
  readonly onChangeSortIndex?: ((offer: T, sortIndex: number) => void) | false;
}

const OfferCollection = <T extends CorpOffer | TradeOffer>(props: OfferCollectionProps<T>) => {
  const { offers, columns, onRemove, onChangeSortIndex } = props;

  const maxSortIndex = offers.length;

  const metadata = useMemo<DataTableMetadata<EOfferTableColumn>>(() => {
    return { columns };
  }, [columns]);

  return (
    <OfferCollectionTable<T>
      metadata={metadata}
      offers={offers}
      sortIndexInfo={{
        max: maxSortIndex,
        fetch: () => {},
        isFetching: false,
        isFetched: true,
        isFailed: false,
      }}
      onChangeSortIndex={onChangeSortIndex && ((offer, sortIndex) => onChangeSortIndex(offer, sortIndex))}
      onRemove={onRemove}
    />
  );
};

export default OfferCollection;
