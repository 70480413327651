import { useState } from 'react';
import { DataFilterStrategyBase, DataFilterStrategyView } from '../../../../domain/model/filter';
import { clearDataFilterStrategyValue } from '../../../utils/filtering';
import DataFilterDialog from './dialog';
import DataFilterView from './view';

interface DataFilterComponentProps<C extends string> {
  readonly strategies: DataFilterStrategyBase<C>[];
  readonly onChange: (strategies: DataFilterStrategyBase<C>[]) => void;
}

const DataFilterComponent = <C extends string>({ strategies, onChange }: DataFilterComponentProps<C>) => {
  const [editFilterVisible, setEditFilterVisible] = useState<boolean>(false);

  const onEditFilter = () => {
    setEditFilterVisible(true);
  };

  const onClear = () => {
    onChange(strategies.filter(strategy => strategy.readOnly));
  };

  const onClearStrategyValue = (strategy: DataFilterStrategyView<C>) => {
    const temp = clearDataFilterStrategyValue(strategies, strategy);
    onChange(temp);
  };

  const onSetStrategyValue = (strategy: DataFilterStrategyView<C>, value: any) => {
    const temp = [...strategies].map(t => (t.key !== strategy.key ? t : { ...t, value }));
    onChange(temp);
  };

  const onApplyAndClose = (newStrategies: DataFilterStrategyBase<C>[]) => {
    setEditFilterVisible(false);
    onChange(newStrategies);
  };

  return (
    <>
      <DataFilterDialog
        open={editFilterVisible}
        strategies={strategies}
        onApply={onApplyAndClose}
        onClear={onClear}
        onClose={() => setEditFilterVisible(!editFilterVisible)}
      />
      <DataFilterView
        strategies={strategies.filter(strategy => strategy.value !== null || strategy.viewAlways)}
        onClearStrategyValue={onClearStrategyValue}
        onSetStrategyValue={onSetStrategyValue}
        onEditFilter={onEditFilter}
      />
    </>
  );
};

export default DataFilterComponent;
