import { v4 as uuidv4 } from 'uuid';
import { ESportEventLevel, ESportEventParticipationFormat, EUserType } from '../../../../../domain/model/enums';
import {
  SportsFest,
  SportsFestCombinedTeamRequest,
  SportsFestContest,
  SportsFestContestRequest,
  SportsFestRequest,
} from '../../../../../domain/model/event';
import { Nullable } from '../../../../../domain/model/types';
import { CalendarIcon, ImageCoverIcon, ListItemsIcon, TextAlignLeftIcon, UserIcon } from '../../../../icons';
import { pluralize } from '../../../../utils/pluralize';
import { EValidationType, ValidationCollectionResult, ValidationResult } from '../../../../utils/validation';
import { EventHelpWidgetField } from '../../components/helpWidgets';
import { EEventStep, SportsFestCreateStepType, SportsFestEditStepType } from '../../types';

export const sportsFestDefaultLevel = ESportEventLevel.Road;
export const sportsFestDefaultUserType = EUserType.Any;

export const convertSportsFestToSportsFestRequest = (sportsFest: SportsFest): SportsFestRequest => {
  const {
    id,
    name,
    description,
    status,
    level,
    road,
    scheduledDate,
    endDate,
    playground,
    locationDescription,
    locationImage,
    participationFormat,
    onlineParticipationLink,
    onlineParticipationDescription,
    onlineParticipationInstruction,
    maxAge,
    minAge,
    minLengthOfWork,
    organizerEmail,
    organizerPhone,
    previewImage,
    programDescription,
    programFile,
    regulationFiles,
    contests,
    combinedTeams,
    admin,
    chatId,
    userType,
    activityTypes,
  } = sportsFest;

  return {
    id,
    name,
    description,
    status,
    scheduledDate,
    endDate,
    locationDescription,
    locationImage,
    participationFormat,
    onlineParticipationLink,
    onlineParticipationDescription,
    onlineParticipationInstruction,
    maxAge,
    minAge,
    minLengthOfWork,
    organizerEmail,
    organizerPhone,
    previewImage,
    programDescription,
    programFile,
    regulationFiles,
    contests: contests?.map(contest => convertSportsFestContestToSportsFestContestRequest(contest)) ?? null,
    playground,
    level,
    road,
    combinedTeams,
    admin,
    chatId,
    userType,
    activityTypes,
  };
};

export const createNewSportsFestRequest = (): SportsFestRequest => {
  return {
    id: uuidv4(),
    name: '',
    description: null,
    status: null,
    scheduledDate: null,
    endDate: null,
    locationDescription: null,
    locationImage: null,
    participationFormat: ESportEventParticipationFormat.Offline,
    onlineParticipationLink: null,
    onlineParticipationDescription: null,
    onlineParticipationInstruction: null,
    maxAge: null,
    minAge: null,
    minLengthOfWork: null,
    organizerEmail: null,
    organizerPhone: null,
    previewImage: null,
    programDescription: null,
    programFile: null,
    regulationFiles: null,
    contests: null,
    playground: null,
    level: null,
    road: null,
    combinedTeams: [],
    admin: null,
    chatId: null,
    userType: null,
    activityTypes: null,
  };
};

export const convertSportsFestContestToSportsFestContestRequest = (
  sportsFestContest: SportsFestContest
): SportsFestContestRequest => {
  const { id, activityType, maleMembersCount, femaleMembersCount, sexDependent, membersCount } = sportsFestContest;

  return {
    id,
    activityType: activityType,
    maleMembersCount,
    femaleMembersCount,
    sexDependent,
    membersCount,
  };
};

export const getSportsFestCreateStepText = (step: SportsFestCreateStepType | SportsFestEditStepType): string => {
  switch (step) {
    case EEventStep.General:
      return 'Основная информация';
    case EEventStep.Details:
      return 'Детали и контакты';
    case EEventStep.Contests:
      return 'Виды спорта';
    case EEventStep.Requirements:
      return 'Требования к участникам';
    case EEventStep.Members:
      return 'Сборные и ответственные лица';
  }
};

/**
 * Первоначальный массив полей, которые будет отображать виджет helper.
 * Адаптер берет эти значения как по умолчанию, но создает свое локальное состояние
 * @Warn порядок имеет значение для обработки полей виджетом
 */
export const sportsFestFieldsListForHelpWidget: Partial<keyof SportsFestRequest>[] = [
  'name',
  'description',
  'previewImage',
  'level',
  'road',
  'scheduledDate',
  'endDate',
  'participationFormat',
  'onlineParticipationLink',
  'playground',
  'organizerPhone',
  'organizerEmail',
  'activityTypes',
  'contests',
  'minAge',
  'maxAge',
  'combinedTeams',
];

export const sportsFestHelpWidgetGetFieldData = (
  field: Nullable<keyof SportsFestRequest>,
  combinedTeamValidation: Nullable<ValidationCollectionResult<SportsFestCombinedTeamRequest>>,
  contestsValidation: Nullable<ValidationCollectionResult<SportsFestContestRequest>>,
  contests: Nullable<SportsFestContestRequest[]>
): Nullable<EventHelpWidgetField> => {
  const defaultDataField: EventHelpWidgetField = {
    name: null,
    icon: TextAlignLeftIcon,
  };

  switch (field) {
    case 'name': {
      return {
        ...defaultDataField,
        name: 'Название',
      };
    }
    case 'description': {
      return {
        ...defaultDataField,
        name: 'Описание',
      };
    }
    case 'previewImage': {
      return {
        ...defaultDataField,
        name: 'Обложка',
        icon: ImageCoverIcon,
      };
    }
    case 'road': {
      return {
        ...defaultDataField,
        name: 'Железная дорога',
        icon: ListItemsIcon,
      };
    }
    case 'scheduledDate': {
      return {
        ...defaultDataField,
        name: 'Дата начала',
        icon: CalendarIcon,
      };
    }
    case 'endDate': {
      return {
        ...defaultDataField,
        name: 'Дата завершения',
        icon: CalendarIcon,
      };
    }
    case 'playground': {
      return {
        ...defaultDataField,
        name: 'Место проведения',
        icon: CalendarIcon,
      };
    }
    case 'organizerPhone': {
      return {
        ...defaultDataField,
        name: 'Телефон для связи',
      };
    }
    case 'organizerEmail': {
      return {
        ...defaultDataField,
        name: 'Почта для связи',
      };
    }
    case 'minAge': {
      return {
        ...defaultDataField,
        name: 'Возраст участников (От)',
      };
    }
    case 'maxAge': {
      return {
        ...defaultDataField,
        name: 'Возраст участников (До)',
      };
    }
    case 'minLengthOfWork': {
      return {
        ...defaultDataField,
        name: 'Минимальный стаж',
      };
    }
    case 'level': {
      return {
        ...defaultDataField,
        name: 'Уровень проведения',
      };
    }
    case 'activityTypes': {
      return {
        ...defaultDataField,
        name: 'Виды спорта',
        icon: CalendarIcon,
      };
    }
    case 'combinedTeams': {
      if (combinedTeamValidation) {
        const field = combinedTeamValidation?.find(item => item !== null);
        const numberField = combinedTeamValidation?.findIndex(item => item !== null);

        if (field?.name?.hasError || field?.responsibleUser?.hasError) {
          let name = null;
          let icon = defaultDataField.icon;

          if (field?.name?.hasError) {
            name = `Название ${numberField + 1} сборной`;
          } else if (field?.responsibleUser?.hasError) {
            name = `Ответственного лица ${numberField + 1} сборной`;
          }

          if (field?.name?.hasError) {
            icon = ListItemsIcon;
          } else if (field?.responsibleUser?.hasError) {
            icon = UserIcon;
          }

          return {
            ...defaultDataField,
            name,
            icon,
          };
        }
      }

      return {
        ...defaultDataField,
        name: 'Сборные и ответственные лица',
        icon: ListItemsIcon,
      };
    }
    case 'contests': {
      if (contestsValidation && contests) {
        const field = contestsValidation?.find(item => item !== null);
        const fieldIndex = contestsValidation?.findIndex(item => item !== null);

        if (field?.membersCount?.hasError || field?.maleMembersCount?.hasError || field?.femaleMembersCount?.hasError) {
          return {
            ...defaultDataField,
            name: `Количество участников (${contests[fieldIndex].activityType.name})`,
            icon: ListItemsIcon,
          };
        }
      }

      return {
        ...defaultDataField,
        name: 'Количество участников',
        icon: ListItemsIcon,
      };
    }
    case 'participationFormat': {
      return {
        ...defaultDataField,
        name: 'Формат участия',
      };
    }
    case 'onlineParticipationLink': {
      return {
        ...defaultDataField,
        name: 'Сайт или ссылка на трансляцию',
      };
    }
    case null:
    case 'status':
    case 'id':
    case 'regulationFiles':
    case 'chatId':
    case 'locationImage':
    case 'locationDescription':
    case 'onlineParticipationInstruction':
    case 'onlineParticipationDescription':
    case 'programFile':
    case 'programDescription':
    case 'admin':
    case 'userType': {
      return null;
    }
  }
};

export const getCommonErrorForValidationStepper = (
  step: SportsFestCreateStepType,
  count: number
): Nullable<ValidationResult<any>> => {
  if (!count) {
    return null;
  }

  switch (step) {
    case EEventStep.Members:
      return {
        common: {
          type: EValidationType.Error,
          hasError: true,
          message: 'Создайте сборные',
        },
      };
    default:
      return {
        common: {
          type: EValidationType.Error,
          hasError: true,
          message: `Не заполнено ${count} ${pluralize(count, ['поле', 'поля', 'полей'])}`,
        },
      };
  }
};

export const sportsFestCreateInitValidationStepper = (
  validation: ValidationResult<SportsFestRequest>,
  validationContests: ValidationCollectionResult<SportsFestContestRequest>,
  validationCombinedTeams: ValidationCollectionResult<SportsFestCombinedTeamRequest>
) => {
  const generalErrorsCount = [
    validation.name,
    validation.description,
    validation.previewImage,
    validation.level,
    validation.road,
    validation.userType,
    validation.scheduledDate,
    validation.endDate,
  ].filter(item => !!item).length;

  const detailsErrorsCount = [
    validation.playground,
    validation.participationFormat,
    validation.onlineParticipationLink,
    validation.onlineParticipationDescription,
    validation.onlineParticipationInstruction,
    validation.locationDescription,
    validation.locationImage,
    validation.programDescription,
    validation.programFile,
    validation.admin,
    validation.organizerPhone,
    validation.organizerEmail,
  ].filter(item => !!item).length;

  const contestsErrorsCount = [validation.activityTypes]
    .concat(
      validationContests.flatMap(vc => {
        return !vc ? [] : [vc.membersCount, vc.maleMembersCount, vc.femaleMembersCount];
      })
    )
    .filter(item => !!item).length;

  const requirementsErrorsCount = [
    validation.minAge,
    validation.maxAge,
    validation.minLengthOfWork,
    validation.regulationFiles,
  ].filter(item => !!item).length;

  const membersErrorsCount = [validation.combinedTeams]
    .concat(
      validationCombinedTeams.flatMap(vct => {
        if (!vct) {
          return [];
        }
        return [vct.name, vct.responsibleUser];
      })
    )
    .filter(item => !!item).length;

  return {
    [EEventStep.General]: getCommonErrorForValidationStepper(EEventStep.General, generalErrorsCount),
    [EEventStep.Details]: getCommonErrorForValidationStepper(EEventStep.Details, detailsErrorsCount),
    [EEventStep.Contests]: getCommonErrorForValidationStepper(EEventStep.Contests, contestsErrorsCount),
    [EEventStep.Requirements]: getCommonErrorForValidationStepper(EEventStep.Requirements, requirementsErrorsCount),
    [EEventStep.Members]: getCommonErrorForValidationStepper(EEventStep.Members, membersErrorsCount),
  };
};
