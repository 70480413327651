import { Grid, Typography } from '@mui/material';
import { MPButton } from '../../../theme/ui-kit/button';
import { getMapsConfig } from '../../../utils';
import { MapCoordinates } from '../types';
import YandexMapContainer from '../yandex/container';
import { StyledDialog } from './controls';

interface GeoPositionViewerProps {
  readonly coordinates: MapCoordinates;
  readonly onClose: () => void;
}

const GeoPositionViewer = ({ coordinates, onClose }: GeoPositionViewerProps) => {
  const { unavailableReason, yandexApiKey } = getMapsConfig();

  return (
    <StyledDialog
      open
      dialogProps={{ fullWidth: true, open: true, fullScreen: true }}
      dialogButtons={
        <Grid
          container
          justifyContent='center'
        >
          <Grid item>
            <MPButton
              onClick={onClose}
              fullWidth={false}
            >
              Закрыть
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      {!unavailableReason ? (
        <YandexMapContainer
          apiKey={yandexApiKey}
          centerCoordinates={coordinates}
          placemarkCoordinates={coordinates}
        />
      ) : (
        <Typography variant='body2'>{unavailableReason}</Typography>
      )}
    </StyledDialog>
  );
};

export default GeoPositionViewer;
