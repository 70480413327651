import { Banner, EBannerPartition, ESortDirection, Nullable, UUID } from '@/domain';
import { addSearchParamToLocation } from '@/routing/globalRouting';
import {
  BannerTableFilterEditStrategy,
  BannerTableFilterValues,
  EBannerTableFilterItem,
} from '@features/banner/table/filterUtils';
import { EBannerTableTab } from '@features/banner/table/utils';
import useCurrentUser from '@features/user/hooks/useCurrentUser';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../data/store/store';
import { PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import { EBannerUrlParam, getBannerCreateRoute } from '../../entry';
import { bannersGuidSelector } from '../store/selectors';
import {
  bannersArchiveBanner,
  bannersChangeBannerSortIndex,
  bannersDataReset,
  bannersGetLastSortIndex,
  bannersPauseBanner,
  bannersResumeBanner,
  bannersSetBannerToChangeSortIndex,
  bannersSetFilter,
  bannersSetPage,
  bannersSetPageSize,
  bannersSetSort,
  bannersSortReset,
} from '../store/slice';

export type UseBannersTable = {
  readonly onChangeTab: (newTab: EBannerTableTab) => void;
  readonly onChangeFilter: (strategies: BannerTableFilterEditStrategy[]) => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
  readonly onResetSort: () => void;
  readonly onChangePageNumber: (page: number) => void;
  readonly onChangePageSize: (pageSize: PaginationSize) => void;

  readonly isBannerLockedNotByMe: (banner: Banner) => boolean;

  readonly onFetchLastSortIndex: () => void;
  readonly onSetBannerToChangeSortIndex: (banner: Nullable<Banner>) => void;

  readonly onBannerCreate: () => void;
  readonly onBannerPause: (banner: Banner) => Promise<boolean>;
  readonly onBannerResume: (banner: Banner) => Promise<boolean>;
  readonly onBannerArchive: (banner: Banner) => Promise<boolean>;
  readonly onBannerChangeSortIndex: (banner: Banner, sortIndex: number) => Promise<boolean>;
};

type UseBannersTableHandlersProps = {
  readonly partition: EBannerPartition;
  readonly tab: EBannerTableTab;
};

export function useBannersTableHandlers({ partition, tab }: UseBannersTableHandlersProps): UseBannersTable {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const {
    userSpecific: { id: userId },
  } = useCurrentUser();

  const getBannersGuidFromState = useCallback((): Nullable<UUID> => {
    const state = store.getState();
    return bannersGuidSelector(partition)(state);
  }, [store]);

  const onChangeTab = useCallback(
    (newTab: EBannerTableTab) => {
      if (newTab !== tab) {
        const guid = getBannersGuidFromState();
        dispatch(bannersDataReset(partition));
        history.replace(
          addSearchParamToLocation({
            location,
            param: EBannerUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [dispatch, history, partition, tab, location, getBannersGuidFromState]
  );

  const onChangeFilter = useCallback(
    (strategies: BannerTableFilterEditStrategy[]) => {
      dispatch(
        bannersSetFilter({
          partition,
          filter: getDataFilterValuesByStrategies<EBannerTableFilterItem, BannerTableFilterValues>(strategies),
        })
      );
    },
    [dispatch]
  );

  const onChangeSort = useCallback(
    (name: string, direction: ESortDirection) => {
      dispatch(
        bannersSetSort({
          partition,
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch, partition]
  );

  const onChangePageNumber = useCallback(
    (pageNumber: number) => {
      dispatch(bannersSetPage({ partition, pageNumber }));
    },
    [dispatch, partition]
  );

  const onChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(bannersSetPageSize({ partition, pageSize }));
    },
    [dispatch, partition]
  );

  const onResetSort = useCallback(() => dispatch(bannersSortReset(partition)), [dispatch, partition]);

  const onBannerCreate = useCallback(() => history.push(getBannerCreateRoute({ partition })), [history, partition]);

  const isBannerLockedNotByMe = useCallback<UseBannersTable['isBannerLockedNotByMe']>(
    banner => !!banner.lock && userId !== banner.lock.lockedBy.id,
    [userId]
  );

  const onFetchLastSortIndex = useCallback(
    () => dispatch(bannersGetLastSortIndex({ partition })),
    [dispatch, partition]
  );

  const onSetBannerToChangeSortIndex = useCallback<UseBannersTable['onSetBannerToChangeSortIndex']>(
    banner => {
      dispatch(bannersSetBannerToChangeSortIndex({ banner, partition }));
      onFetchLastSortIndex();
    },
    [dispatch, partition, onFetchLastSortIndex]
  );

  const onBannerPause = useCallback<UseBannersTable['onBannerPause']>(
    async ({ id }) => {
      await dispatch(bannersPauseBanner({ id, partition })).unwrap();
      return true;
    },
    [dispatch, partition]
  );

  const onBannerResume = useCallback<UseBannersTable['onBannerResume']>(
    async ({ id }) => {
      await dispatch(bannersResumeBanner({ id, partition })).unwrap();
      return true;
    },
    [dispatch, partition]
  );

  const onBannerArchive = useCallback<UseBannersTable['onBannerArchive']>(
    async ({ id }) => {
      await dispatch(bannersArchiveBanner({ id, partition })).unwrap();
      return true;
    },
    [dispatch, partition]
  );

  const onBannerChangeSortIndex = useCallback<UseBannersTable['onBannerChangeSortIndex']>(
    async ({ id }, sortIndex) => {
      await dispatch(bannersChangeBannerSortIndex({ id, sortIndex, partition })).unwrap();
      return true;
    },
    [dispatch, partition]
  );

  return {
    onChangeTab,
    onChangeFilter,
    onChangeSort,
    onResetSort,
    onChangePageNumber,
    onChangePageSize,
    isBannerLockedNotByMe,
    onFetchLastSortIndex,
    onSetBannerToChangeSortIndex,
    onBannerCreate,
    onBannerPause,
    onBannerResume,
    onBannerArchive,
    onBannerChangeSortIndex,
  };
}
