import { Grid } from '@mui/material';
import ContentLoader from '../../../components/common/loader';
import { MPButton } from '../../../theme/ui-kit/button';

interface PartnerSelfRegistrationActionsProps {
  readonly isFetching: boolean;
  readonly onSave: () => void;
  readonly onSaveAndEdit: () => void;
}

const PartnerSelfRegistrationActions = (props: PartnerSelfRegistrationActionsProps) => {
  const { isFetching, onSave, onSaveAndEdit } = props;

  return (
    <Grid
      container
      spacing={2}
      direction='column'
    >
      <Grid item>
        <MPButton
          disabled={isFetching}
          onClick={onSave}
        >
          Пригласить партнера
        </MPButton>
      </Grid>
      <Grid item>
        <MPButton
          disabled={isFetching}
          variant='outlined'
          onClick={onSaveAndEdit}
        >
          Пригласить партнера и заполнить данные компании
        </MPButton>
      </Grid>
      {isFetching && <ContentLoader />}
    </Grid>
  );
};

export default PartnerSelfRegistrationActions;
