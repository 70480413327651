import { UserAccessPartitionAdCampaigns } from '@/domain';
import AdCampaignCreateFooterAdapter from '@features/adCampaign/create/adapters/footer';
import { CommonPreloaderDecorator } from '@features/general/decorators/preloader';
import { CommonUpdaterDecorator } from '@features/general/decorators/updater';
import MasterDetailsContainer from '@features/general/master/details/container';
import MasterContentGridLayout from '@layouts/masterContentGrid/container';
import { MasterDetailsLayout } from '@layouts/masterDetails/container';
import { useAdCampaignActionsSelectors } from '../actions/useActions';
import { AdCampaignLifeCycle } from '../lifecycle/types';
import { AdCampaignCreateStepType } from '../types';
import {
  AdCampaignCreateHeaderAdapter,
  AdCampaignCreateStepGeneralAdapter,
  AdCampaignCreateStepKeywordsAdapter,
  AdCampaignCreateStepperAdapter,
  AdCampaignCreateUiStateAdapter,
} from './adapters';
import { AdCampaignCreateInitializer } from './initializer';
import {
  AdCampaignCreateHandlersProvider,
  AdCampaignCreateLifecycleProvider,
  AdCampaignCreateParamsProvider,
} from './providers';
import { adCampaignCreateIsPreloadingSelector } from './store/selectors';

export interface AdCampaignCreateContainerProps {
  readonly guid: UUID;
  readonly id: Nullable<UUID>;
  readonly partnerId: Nullable<UUID>;
  readonly lifecycle: AdCampaignLifeCycle;
  readonly access: UserAccessPartitionAdCampaigns;
  readonly step: AdCampaignCreateStepType;
}

const AdCampaignCreateContainer = (props: AdCampaignCreateContainerProps) => {
  const { guid, id, partnerId, step, lifecycle, access } = props;

  const { getIsAdCampaignProcessingSelector } = useAdCampaignActionsSelectors();

  return (
    <MasterDetailsContainer
      layout={MasterDetailsLayout}
      layoutProps={{
        stepper: AdCampaignCreateStepperAdapter,
        header: AdCampaignCreateHeaderAdapter,
        footer: AdCampaignCreateFooterAdapter,
        decorators: [
          <CommonPreloaderDecorator
            key='preloader'
            isFetchingSelector={adCampaignCreateIsPreloadingSelector}
          />,
          <CommonUpdaterDecorator
            key='actions'
            isFetchingSelector={getIsAdCampaignProcessingSelector(id)}
          />,
        ],
      }}
      initializer={AdCampaignCreateInitializer}
      providers={[
        {
          name: 'params',
          factory: AdCampaignCreateParamsProvider,
        },
        {
          name: 'handlers',
          factory: AdCampaignCreateHandlersProvider,
        },
        {
          name: 'lifecycle',
          factory: AdCampaignCreateLifecycleProvider,
        },
      ]}
      commonProps={{
        guid,
        id,
        step,
        partnerId,
        lifecycle,
        access,
      }}
    >
      <AdCampaignCreateUiStateAdapter />

      <MasterContentGridLayout>
        <AdCampaignCreateStepGeneralAdapter />
        <AdCampaignCreateStepKeywordsAdapter />
      </MasterContentGridLayout>
    </MasterDetailsContainer>
  );
};

export default AdCampaignCreateContainer;
