import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../data/store/store';
import { Nullable, UUID } from '../../../../../domain/model/types';
import Splitter from '../../../../components/common/splitter';
import { ProductTableStubAdapterProps } from '../types';

export type ProductsStubAdapterProps = ProductTableStubAdapterProps & {
  readonly isEmptySelector: (state: RootState) => boolean;
  readonly partnerId?: Nullable<UUID>;
};

const ProductsStubAdapter = (props: ProductsStubAdapterProps) => {
  const { partnerId, isEmptySelector, children } = props;

  const isEmpty = useSelector(isEmptySelector);
  const stub = useMemo(() => {
    if (isEmpty) {
      return (
        <>
          <Splitter size={1} />
          {partnerId && <Typography>Пока партнёр не загрузил ни один товар.</Typography>}
          {!partnerId && <Typography>Пока партнёры не загрузили ни один товар.</Typography>}
        </>
      );
    }

    return null;
  }, [partnerId, isEmpty]);

  return (
    <Container>
      {() =>
        children({
          canViewTable: !isEmpty,
          stub,
        })
      }
    </Container>
  );
};

interface ContainerProps {
  readonly children: () => React.ReactNode;
}

const Container = ({ children }: ContainerProps) => {
  return <>{children()}</>;
};

export default ProductsStubAdapter;
