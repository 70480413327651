import { EOrderItemStatus } from '@/domain';
import DataTable, { DataTableRow } from '../../../../components/common/table';
import TableActions from '../../../../components/common/table/actions';
import { EPanelActionPosition, OnChangeObjectAttributeByIndex, PanelActions } from '../../../../types';
import { formatCost } from '../../../../utils';
import { ValidationCollectionResult } from '../../../../utils/validation';
import { BookingOrderItemView } from '../../types';
import BookingOrderItemTableCellCost from './cells/cost';
import BookingOrderItemTableCellQuantity from './cells/quantity';
import { BookingOrderItemTableCellStatus } from './cells/status';
import { EBookingOrderItemsTableColumn } from '@features/bookingOrder/utils/common';
import { useBookingOrderItemsTable } from '../../utils/useTableSettings';

type BookingOrderTableItem = Omit<BookingOrderItemView, 'id'> & {
  readonly id: UUID;
  readonly canDelete: boolean;
};

enum EBookingOrderItemActionType {
  Delete = 'delete',
  Cancel = 'cancel',
  Confirm = 'confirm',
}

type BookingOrderItemAction<A extends string = EBookingOrderItemActionType> = PanelActions<A>;

interface BookingOrderItemsTableProps {
  readonly items: BookingOrderItemView[];
  readonly validation?: ValidationCollectionResult<BookingOrderItemView>;
  readonly onChangeAttribute?: OnChangeObjectAttributeByIndex<BookingOrderItemView>;
  readonly onTryChangeQuantity?: (index: number) => void;
  readonly onDelete?: (index: number) => void;
}

const BookingOrderItemsTable = (props: BookingOrderItemsTableProps) => {
  const { items, validation, onChangeAttribute, onTryChangeQuantity, onDelete } = props;

  const rows: DataTableRow<BookingOrderTableItem, EBookingOrderItemsTableColumn>[] = items.map((item, index) => {
    const { internalId, priceItem, qty, slots, cost, totalCost, status } = item;

    return {
      [EBookingOrderItemsTableColumn.Category]: priceItem.service.category?.name,
      [EBookingOrderItemsTableColumn.Service]: priceItem.service.name,
      [EBookingOrderItemsTableColumn.PriceItemName]: priceItem.name,
      [EBookingOrderItemsTableColumn.Cost]: (
        <BookingOrderItemTableCellCost
          value={cost}
          onChange={onChangeAttribute ? value => onChangeAttribute(index, 'cost', value) : null}
        />
      ),
      [EBookingOrderItemsTableColumn.Unit]: priceItem.unit?.name,
      [EBookingOrderItemsTableColumn.BookingDatesAndQty]: priceItem.service.orderByDateType ? (
        <BookingOrderItemTableCellQuantity
          quantity={qty}
          orderByDateType={priceItem.service.orderByDateType}
          slots={slots ?? []}
          hasError={validation?.[index]?.qty?.hasError || validation?.[index]?.slots?.hasError}
          onClick={onTryChangeQuantity ? () => onTryChangeQuantity(index) : null}
        />
      ) : null,
      [EBookingOrderItemsTableColumn.TotalCost]: formatCost(totalCost),
      [EBookingOrderItemsTableColumn.Status]: (
        <BookingOrderItemTableCellStatus
          hasError={validation?.[index]?.status?.hasError}
          status={status}
        />
      ),
      data: {
        ...item,
        id: internalId,
        canDelete: !item.id,
      },
    };
  });

  const getOnAction = (index: number) => (action: EBookingOrderItemActionType, orderItem: BookingOrderTableItem) => {
    switch (action) {
      case EBookingOrderItemActionType.Confirm:
        onChangeAttribute?.(index, 'status', EOrderItemStatus.Confirmed);
        break;
      case EBookingOrderItemActionType.Cancel:
        onChangeAttribute?.(index, 'status', EOrderItemStatus.Cancelled);
        break;
      case EBookingOrderItemActionType.Delete:
        onDelete?.(index);
        break;
    }
  };

  const getActions = (orderItem: BookingOrderTableItem): BookingOrderItemAction => {
    return [
      {
        type: EBookingOrderItemActionType.Confirm,
        position: [EPanelActionPosition.Menu],
        disabled: false,
        label: 'Подтверждён',
        primary: false,
      },
      {
        type: EBookingOrderItemActionType.Cancel,
        position: [EPanelActionPosition.Menu],
        disabled: false,
        label: 'Отменён',
        primary: false,
      },
      {
        type: EBookingOrderItemActionType.Delete,
        position: [EPanelActionPosition.Menu],
        disabled: !orderItem.canDelete,
        label: 'Удалить',
        primary: false,
      },
    ];
  };

  const { metadata, onChangeMetadata } = useBookingOrderItemsTable();

  return (
    <DataTable<BookingOrderTableItem, EBookingOrderItemsTableColumn>
      width='auto'
      rows={rows}
      metadata={metadata}
      onChangeMetadata={onChangeMetadata}
      rowActions={!!onChangeAttribute}
      getRowActions={(orderItem, index) => (
        <TableActions<BookingOrderTableItem, EBookingOrderItemActionType>
          entityLink={orderItem}
          actions={getActions(orderItem)}
          onExecute={getOnAction(index)}
          isExecuting={false}
        />
      )}
    />
  );
};

export default BookingOrderItemsTable;
