import mp, { ReportMpService } from './mp';
import sport, { ReportSportService } from './sport';

type ReportServices = {
  readonly mp: ReportMpService;
  readonly sport: ReportSportService;
};

const reportServices: ReportServices = {
  mp,
  sport,
};

export default reportServices;
