import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable } from '../../../../../domain/model';
import { TeamLogo } from '../../../../../domain/model/team';
import { Nullable, UUID } from '../../../../../domain/model/types';
import teamServices from '../../services';

export type TeamLogosFetchProps = {
  readonly search: {
    readonly pageSize: number;
    readonly sort: Nullable<string>;
  };
  readonly pageNumber: number;
};

export const teamLogosFetch = createAsyncThunk<Pageable<TeamLogo>, TeamLogosFetchProps, AppThunkAPIConfig>(
  'team/logos/fetch',
  async ({ search, pageNumber }, { rejectWithValue }) => {
    try {
      const { pageSize, sort } = search;

      const {
        content: data,
        totalElements: totalCount,
        totalPages: pageCount,
        number: page,
      } = await teamServices.logos.list({ page: pageNumber, pageSize, sort });
      // const { content: data, totalElements: totalCount, totalPages: pageCount, number: page } = response;
      return { data, totalCount, pageCount, pageNumber: page };
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface TeamLogoSelectState extends Fetchable, Pageable<TeamLogo> {
  readonly search: {
    sort: string;
    pageSize: number;
  };
  readonly pageNumber: number;
  readonly needRefresh: boolean;
  readonly selected: Nullable<TeamLogo>;
}

type Reducer<T> = CaseReducer<TeamLogoSelectState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<TeamLogoSelectState> {
  teamLogoSelect: Reducer<{ parent: Nullable<UUID>; selected: Nullable<TeamLogo> }>;
}

const slice = createSlice<TeamLogoSelectState, Reducers, 'team/logos/select'>({
  name: 'team/logos/select',
  initialState: {
    ...fetchableDefault,
    data: [],
    search: {
      sort: 'id,asc',
      pageSize: 999,
    },
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    needRefresh: true,
    selected: null,
  },
  reducers: {
    teamLogoSelect: (state, { payload }) => {
      state.selected = payload.selected;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(teamLogosFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
        state.needRefresh = false;
      })
      .addCase(teamLogosFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(teamLogosFetch.rejected, state => {
        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = true;
        state.data = [];
      });
  },
});

export const { teamLogoSelect } = slice.actions;

export default slice.reducer;
