import { Fade, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENoticeStatus } from '../../../../domain/model/enums';
import { UUID } from '../../../../domain/model/types';
import { MpUserData } from '../../../../domain/model/user';
import Notifier from '../../../../system/notifier';
import MasterActionsComponent from '../../../components/common/actions/master';
import useNavAdapter from '../../../components/common/actions/navAdapter/hooks';
import AppBreadcrumbs from '../../../components/common/breadcrumbs';
import DefaultHeader from '../../../components/common/header';
import ContentLoader from '../../../components/common/loader';
import { DefaultContentWrapper } from '../../../components/common/wrappers/content';
import { DefaultFooterWrapper } from '../../../components/common/wrappers/footer';
import useValidation from '../../../hooks/validation/useValidation';
import { EPanelActionPosition, OnChangeObjectAttribute, PanelAction, PanelActions } from '../../../types';
import { getUserShortName } from '../../../utils';
import StepperContainer from '../../general/stepper/container';
import { partnerEmployeeCreateProfileStepValidation } from '../create/utils';
import { EPartnerEmployeeActionType, PartnerEmployeeActionEditType } from '../types';
import { EPartnerEmployeeStep, getPartnerEmployeeActionName } from '../utils';
import PartnerEmployeeEdit from './component';
import { ContainerWrapper, ContentContainer, LoaderWrapper, TitleWrapper } from './controls';
import { partnerEmployeeEditByIdSelector, partnerEmployeeEditSaveSelector } from './store/selectors';
import {
  partnerEmployeeEditByIdFetch,
  partnerEmployeeEditReset,
  partnerEmployeeEditSetAttribute,
  partnerEmployeeEditUpdate,
} from './store/slice';
import { getPartnerEmployeeEditStepText } from './utils';

interface PartnerEmployeeEditContainerProps {
  readonly id: UUID;
  readonly step: EPartnerEmployeeStep;
  readonly onClose: () => void;
}

const validationRulesProfile = partnerEmployeeCreateProfileStepValidation;

const PartnerEmployeeEditContainer = (props: PartnerEmployeeEditContainerProps) => {
  const { id, step, onClose } = props;

  const dispatch = useDispatch();

  const [validateOnChangeProfile, setValidateOnChangeProfile] = useState<boolean>(false);

  const { user: partnerEmployee, isFetching } = useSelector(partnerEmployeeEditByIdSelector);
  const { isFetching: isSaving, isFetched: isSaved } = useSelector(partnerEmployeeEditSaveSelector);

  const {
    validationResult: validationResultProfile,
    isValid: isValidProfile,
    validate: validateProfile,
  } = useValidation<MpUserData>({
    object: partnerEmployee,
    validateOnChange: validateOnChangeProfile,
    rules: validationRulesProfile,
  });

  const steps: EPartnerEmployeeStep[] = [EPartnerEmployeeStep.Profile];
  const mpSteps = steps.map(item => ({
    key: item,
    label: getPartnerEmployeeEditStepText(item),
  }));
  const mpStep = mpSteps.find(item => item.key === step) ?? {
    key: EPartnerEmployeeStep.Profile,
    label: getPartnerEmployeeEditStepText(EPartnerEmployeeStep.Profile),
  };
  const actions: PanelActions<PartnerEmployeeActionEditType> = [
    {
      type: EPartnerEmployeeActionType.Save,
      label: getPartnerEmployeeActionName(EPartnerEmployeeActionType.Save),
      primary: true,
      position: [EPanelActionPosition.Default],
    },
  ];

  const onPartnerEmployeeSave = () => {
    if (partnerEmployee) {
      const isValidObject = validateProfile();

      if (!isValidObject) {
        setValidateOnChangeProfile(true);
      } else {
        dispatch(partnerEmployeeEditUpdate({ id, partnerEmployee }));
      }
    }
  };

  const onChangeAttribute: OnChangeObjectAttribute<MpUserData> = (name, value) => {
    dispatch(partnerEmployeeEditSetAttribute({ name, value }));
  };

  const onPanelAction = (action: PanelAction<PartnerEmployeeActionEditType>) => {
    const { type } = action;
    switch (type) {
      case EPartnerEmployeeActionType.Save:
        onPartnerEmployeeSave();
        break;
    }
  };

  useEffect(() => {
    if (isSaved) {
      Notifier.getInstance().addNotice(ENoticeStatus.Success, `Сотрудник сохранен`);
      onClose();
    }
  }, [isSaved, onClose]);

  useEffect(() => {
    dispatch(partnerEmployeeEditByIdFetch({ id }));
    return () => {
      dispatch(partnerEmployeeEditReset());
    };
  }, [dispatch, id]);

  const activeStepIndex = steps.indexOf(step);
  const nextStepIndex = Math.min(activeStepIndex + 1, steps.length - 1);
  const isLastStep = activeStepIndex === nextStepIndex;

  const onNextStep = () => {
    if (isLastStep) {
      return;
    }

    if (partnerEmployee) {
      const index = steps.indexOf(step);
      if (index < steps.length - 1) {
        switch (step) {
          case EPartnerEmployeeStep.Profile: {
            const isValidObject = validateProfile();
            if (!isValidObject) {
              setValidateOnChangeProfile(true);
              return;
            }
            break;
          }
          default: {
            console.error(`Unknown ETradeOfferStep ${step}`);
            return;
          }
        }
      }
    }
  };

  const { adapter: navAdapter, actions: navActions } = useNavAdapter({
    openPrevStep: () => {},
    currentStepIndex: activeStepIndex,
    stepsCount: steps.length,
    openNextStep: onNextStep,
  });

  const actionsPanel = (
    <MasterActionsComponent<PartnerEmployeeActionEditType>
      actions={actions}
      show={!!actions.length || !!navActions.length}
      navAdapter={navAdapter}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );

  return (
    <Fade in>
      <ContainerWrapper>
        {partnerEmployee && (
          <>
            <StepperContainer<EPartnerEmployeeStep>
              step={mpStep}
              steps={mpSteps}
            />
            <ContentContainer>
              <DefaultContentWrapper
                type='details'
                stickyHeader
                fullHeight
                footer={actionsPanel}
              >
                <DefaultHeader
                  sticky
                  headline={<AppBreadcrumbs />}
                  onClose={onClose}
                >
                  <TitleWrapper>
                    <Typography variant='h2'>{getUserShortName(partnerEmployee)}</Typography>
                  </TitleWrapper>
                </DefaultHeader>
                <PartnerEmployeeEdit
                  step={step}
                  partnerEmployee={partnerEmployee}
                  isValidProfile={isValidProfile}
                  validationProfile={validationResultProfile}
                  onChangeAttribute={onChangeAttribute}
                />
              </DefaultContentWrapper>
            </ContentContainer>
          </>
        )}
        {(isFetching || isSaving) && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
      </ContainerWrapper>
    </Fade>
  );
};

export default PartnerEmployeeEditContainer;
