import { ListItemText, Typography } from '@mui/material';
import { SplitterListItem } from './controls';

interface EventListSplitterProps {
  readonly data: any;
}

const EventListSplitter = ({ data }: EventListSplitterProps) => {
  return (
    <SplitterListItem alignItems='center'>
      <ListItemText primary={<Typography variant='subtitle2'>{data}</Typography>} />
    </SplitterListItem>
  );
};

export default EventListSplitter;
