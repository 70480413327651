import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Banner } from '../../../domain/model/banner';
import { UUID } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import BannerByIdContainer from '../../features/banner/byId/container';
import BannerDetailsContainer from '../../features/banner/details/container';
import { getBannerPartitionByPlaces } from '../../features/banner/utils';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const BannerDetailsScreen = () => {
  const dispatch = useDispatch();
  const { banners } = useCurrentUser().accessMatrix;

  const { id } = useParams<{ id: UUID }>();

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  const canView = (banner: Banner) => {
    const partition = getBannerPartitionByPlaces(banner.places ?? []);
    return banners[partition].view;
  };

  const canEdit = (banner: Banner) => {
    const partition = getBannerPartitionByPlaces(banner.places ?? []);
    return banners[partition].edit;
  };

  return (
    <BannerByIdContainer id={id}>
      {({ banner }) => {
        if (!canView(banner)) {
          return <ForbiddenComponent />;
        }

        return (
          <BannerDetailsContainer
            id={id}
            canEdit={canEdit(banner)}
          />
        );
      }}
    </BannerByIdContainer>
  );
};

export default BannerDetailsScreen;
