import { UUID } from '../../../../domain/model/types';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import TableCommonLayout from '../../../layouts/tableCommon/container';
import { ESportsmansTableFilterItem } from '../filterUtils';
import { SportsmansBehaviorAdapter } from './adapters/behavior';
import SportsmanFilterAdapter from './adapters/filter';
import SportsmanFooterAdapter from './adapters/footer';
import SportsmanTableHeaderAdapter from './adapters/header';
import SportsmanTableAdapter from './adapters/table';
import { SportsmanTableTabsAdapter } from './adapters/tabs';
import { SportsmanTableContext } from './context';
import { useSportsTableHandlers } from './hooks/useSportsmansTable';
import { ESportsmanTableColumn, ESportsmanTableTab } from './utils';

interface SportsmanTableContainerProps {
  readonly guid: UUID;
  readonly filterAdapter: DataFilterAdapter<ESportsmanTableColumn, ESportsmansTableFilterItem>;
  readonly tab: ESportsmanTableTab;
}

const SportsmanTableContainer = ({ guid, filterAdapter, tab }: SportsmanTableContainerProps) => {
  const handlers = useSportsTableHandlers({ tab });

  return (
    <SportsmanTableContext.Provider value={handlers}>
      <SportsmansBehaviorAdapter
        guid={guid}
        tab={tab}
      />
      <TableCommonLayout
        header={<SportsmanTableHeaderAdapter />}
        filter={<SportsmanFilterAdapter filterAdapter={filterAdapter} />}
        tabs={<SportsmanTableTabsAdapter tab={tab} />}
        table={<SportsmanTableAdapter filterAdapter={filterAdapter} />}
        footer={SportsmanFooterAdapter}
      />
    </SportsmanTableContext.Provider>
  );
};

export default SportsmanTableContainer;
