import { combineReducers } from '@reduxjs/toolkit';
import manual, { ManualAddressEditState } from './customInput/store/slice';

interface AddressState {
  manual: ManualAddressEditState;
}

export default combineReducers<AddressState>({
  manual,
});
