import { Grid, Typography } from '@mui/material';
import { UUID } from '../../../../../../../domain/model/types';
import FileInfo from '../../../../../../components/common/files/info';

interface PartnerApplicationViewDocumentProps {
  readonly label: string;
  readonly description?: string;
  readonly guid?: UUID;
}

const PartnerApplicationViewDocument = (props: PartnerApplicationViewDocumentProps) => {
  const { label, description, guid } = props;

  return (
    <Grid
      item
      xs={12}
    >
      <Typography
        variant='subtitle2'
        gutterBottom
      >
        {label}
      </Typography>
      {description && <Typography gutterBottom>{description}</Typography>}
      {guid && <FileInfo id={guid} />}
      {!guid && <Typography color='error'>файл не загружен</Typography>}
    </Grid>
  );
};

export default PartnerApplicationViewDocument;
