import { SportsFest, SportsFestCombinedTeamResultsRequest } from '../../../../../domain/model/event';
import { Nullable } from '../../../../../domain/model/types';
import { EEventStep, SportsFestViewStepType } from '../../types';

export const sportsFestWinnersMaxCount = 3;

export const getSportsFestViewStepText = (step: SportsFestViewStepType): string => {
  switch (step) {
    case EEventStep.General:
      return 'Описание мероприятия';
    case EEventStep.Members:
      return 'Сборные';
    case EEventStep.Results:
      return 'Результаты';
    case EEventStep.Chat:
      return 'Чат';
  }
};

export const getSportsFestCombinedTeamResults = (
  sportsFest: SportsFest
): Nullable<SportsFestCombinedTeamResultsRequest[]> => {
  return (
    sportsFest.combinedTeams
      ?.filter(combinedTeam => !!combinedTeam.place)
      ?.map(({ id, place }) => ({
        combinedTeamId: id,
        place,
      })) ?? null
  );
};
