import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BookingOfferShort } from '../../../../../domain/model/booking';
import { EBookingActionType } from '../../types';
import BookingActionsConfirmDialog from '../components/confirmDialog';
import {
  bookingOfferActionsDialogPublishSelector,
  getBookingOfferActionsOfferIsProcessingSelector,
} from '../store/selectors';
import { bookingOfferActionsPublish } from '../store/slice';
import { useBookingOfferActions } from '../useActions';
import { showBookingOfferActionNotification } from '../utils';

const BookingOfferActionsDialogPublishAdapter = () => {
  const dispatch = useDispatch();

  const handlers = useBookingOfferActions();

  const object = useSelector(bookingOfferActionsDialogPublishSelector);

  const isProcessing = useSelector(getBookingOfferActionsOfferIsProcessingSelector(object?.id ?? null));

  const onPublish = useCallback(
    (offer: BookingOfferShort) => {
      dispatch(
        bookingOfferActionsPublish({
          id: offer.id,
        })
      )
        .unwrap()
        .then(response => {
          handlers.onChangeDialogState('publish', null);
          showBookingOfferActionNotification(response, EBookingActionType.Publish, false);
        });
    },
    [dispatch, handlers.onChangeDialogState]
  );

  const onCloseDialog = useCallback(
    () => handlers.onChangeDialogState('publish', null),
    [handlers.onChangeDialogState]
  );

  return (
    object && (
      <BookingActionsConfirmDialog
        offer={object}
        title='Опубликовать объект?'
        actionText='Опубликовать'
        text={<Typography>Карточка объекта будет отправлена на модерацию</Typography>}
        onAction={offer => onPublish(offer)}
        isFetching={isProcessing}
        onClose={onCloseDialog}
      />
    )
  );
};

export default BookingOfferActionsDialogPublishAdapter;
