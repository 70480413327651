import { useContext } from 'react';
import AdCampaignActionsContext, { AdCampaignActionsContextType } from './context';

export const useAdCampaignActions = (): AdCampaignActionsContextType => {
  return useContext<AdCampaignActionsContextType>(AdCampaignActionsContext);
};

export const useAdCampaignActionsSelectors = (): AdCampaignActionsContextType['utils']['selectors'] => {
  return useAdCampaignActions().utils.selectors;
};
