import { Fade, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EPartnerStatus, ESortDirection, Nullable, PartnerShort, UUID } from '@/domain';
import DataFilterComponent from '../../../components/common/dataFilter';
import Splitter from '../../../components/common/splitter';
import { DefaultContentWrapper } from '@components/common/wrappers/content';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import { NumberRange } from '../../../types';
import { getDataFilterValuesByStrategies } from '../../../utils/filtering';
import { customersNeedRefreshWatcherReset } from '../../customer/table/store/slice';
import { EPartnerTableColumn } from '../../general/partner/types';
import { EPartnerTableFilterItem, PartnerTableFilterEditStrategy, PartnerTableFilterValues } from '../filterUtils';
import PartnersSearch from './component';
import { ContainerWrapper } from './controls';
import PartnerSearchFooterContainer from './footerContainer';
import {
  partnersSearchFilterSelector,
  partnersSearchGetSelector,
  partnersSearchGuidSelector,
  partnersSearchNeedRefreshWatcherSelector,
  partnersSearchSearchSelector,
  partnersSearchSelectedSelector,
} from './store/selectors';
import {
  partnersSearchAllSelect,
  partnersSearchAllUnselect,
  partnersSearchFetch,
  PartnersSearchFetchProps,
  partnersSearchSelect,
  partnersSearchSetFilter,
  partnersSearchSetSearch,
  partnersSearchSortReset,
  partnersSearchStartSession,
  partnersSearchUnselect,
} from './store/slice';

interface PartnersSearchContainerSingleProps {
  readonly initialSelected: Nullable<PartnerShort>;
  readonly selectedMaxCount: 1;
  readonly onSelect: (partner: Nullable<PartnerShort>) => void;
}

interface PartnersSearchContainerMultipleProps {
  readonly initialSelected: Nullable<PartnerShort[]>;
  readonly selectedMaxCount: NumberRange<2, 100>;
  readonly onSelect: (partner: PartnerShort[]) => void;
}

type PartnersSearchContainerProps = {
  readonly guid: UUID;
  readonly statuses?: EPartnerStatus[];
  readonly deskActive?: boolean;
  readonly filterAdapter: DataFilterAdapter<EPartnerTableColumn, EPartnerTableFilterItem>;
  readonly onPartnerClick: (partner: PartnerShort) => void;
} & (PartnersSearchContainerSingleProps | PartnersSearchContainerMultipleProps);

const PartnersSearchContainer = (props: PartnersSearchContainerProps) => {
  const { guid, statuses = [], deskActive, filterAdapter, selectedMaxCount, onPartnerClick } = props;

  const dispatch = useDispatch();

  const [searchPromise, setSearchPromise] = useState<Nullable<any>>(null);

  const { data: partners, pageNumber } = useSelector(partnersSearchGetSelector);

  const currentGuid = useSelector(partnersSearchGuidSelector);

  const search = useSelector(partnersSearchSearchSelector);
  const filter = useSelector(partnersSearchFilterSelector);

  const needRefreshWatcher = useSelector(partnersSearchNeedRefreshWatcherSelector);

  const selectedPartners = useSelector(partnersSearchSelectedSelector);

  const { sort } = search;

  const [sortColumn, sortDirection] = sort?.split(',') as [EPartnerTableColumn, ESortDirection];

  const getQueryParams = (): PartnersSearchFetchProps => {
    return {
      search: {
        ...search,
        statuses,
        deskActive,
      },
      pageNumber,
      filter,
    };
  };

  const onSelectInternal = (partners: PartnerShort[]) => {
    switch (props.selectedMaxCount) {
      case 1:
        props.onSelect(partners?.[0] ?? null);
        break;
      default:
        props.onSelect(partners);
        break;
    }
  };

  const onSearch = () => {
    setSearchPromise(dispatch(partnersSearchFetch(getQueryParams())));
  };

  const onChangeSort = (name: string, direction: ESortDirection) => {
    dispatch(
      partnersSearchSetSearch({
        ...search,
        sort: `${name},${direction}`,
      })
    );
  };

  const onSortReset = () => {
    dispatch(partnersSearchSortReset());
  };

  const onChangeFilter = (strategies: PartnerTableFilterEditStrategy[]) => {
    dispatch(
      partnersSearchSetFilter(
        getDataFilterValuesByStrategies<EPartnerTableFilterItem, PartnerTableFilterValues>(strategies)
      )
    );
  };

  const onPartnerSelect = (partner: PartnerShort, selected: boolean) => {
    if (selectedMaxCount > 1) {
      if (selected && selectedPartners.length < selectedMaxCount) {
        dispatch(partnersSearchSelect(partner));
      } else {
        dispatch(partnersSearchUnselect(partner));
      }
    } else {
      if (selected) {
        dispatch(partnersSearchAllUnselect());
        dispatch(partnersSearchSelect(partner));
      } else {
        dispatch(partnersSearchUnselect(partner));
      }
    }
  };

  const onAllPartnersSelect = (selected: boolean) => {
    if (selected) {
      dispatch(partnersSearchAllSelect(selectedMaxCount ?? null));
    } else {
      dispatch(partnersSearchAllUnselect());
    }
  };

  // начало сеанса
  useEffect(() => {
    const selected: PartnerShort[] = [];
    if (props.initialSelected) {
      switch (props.selectedMaxCount) {
        case 1:
          selected.push(props.initialSelected);
          break;
        default:
          selected.push(...props.initialSelected);
          break;
      }
    }
    if (guid !== currentGuid) {
      dispatch(partnersSearchStartSession({ guid, selected }));
      onSearch();
    }
  }, [dispatch, guid, currentGuid, props.initialSelected, props.selectedMaxCount]);

  // начальная загрузка
  useEffect(() => {
    if (guid === currentGuid) {
      searchPromise?.abort();
      onSearch();

      return () => {
        searchPromise?.abort();
      };
    }
  }, [dispatch, guid, currentGuid]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromise?.abort();
      onSearch();

      return () => {
        searchPromise?.abort();
      };
    }
  }, [dispatch, needRefreshWatcher]);

  useEffect(() => {
    return () => {
      dispatch(customersNeedRefreshWatcherReset());
    };
  }, []);

  const { metadata, filterStrategies, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    filterValues: filter,
    sortColumn,
    onChangeFilter,
    onSortReset,
  });

  if (guid !== currentGuid) {
    return null;
  }

  return (
    <>
      <Fade in>
        <DefaultContentWrapper
          type='table'
          fullHeight
          stickyHeader
          footer={
            <PartnerSearchFooterContainer
              maxCount={selectedMaxCount}
              onSelect={onSelectInternal}
            />
          }
        >
          <ContainerWrapper>
            <Grid
              container
              spacing={2}
              direction='column'
            >
              <Grid item>
                <Splitter
                  variant='horizontal'
                  size={1}
                />
                <DataFilterComponent
                  strategies={filterStrategies}
                  onChange={onChangeFilter}
                />
              </Grid>
            </Grid>
            <PartnersSearch
              metadata={metadata}
              partners={partners}
              selectedPartners={selectedPartners}
              sort={{ column: sortColumn, direction: sortDirection }}
              onRequestSort={onChangeSort}
              onClick={onPartnerClick}
              onChangeMetadata={onChangeMetadata}
              isSingleSelect={selectedMaxCount === 1}
              onPartnerSelect={onPartnerSelect}
              onAllPartnersSelect={onAllPartnersSelect}
            />
          </ContainerWrapper>
        </DefaultContentWrapper>
      </Fade>
    </>
  );
};

export default PartnersSearchContainer;
