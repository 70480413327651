import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getTradeOfferActionsOfferIsProcessingSelector, tradeOfferActionsGetActionSelector } from '../store/selectors';
import { useTradeOfferActions } from '../useActions';
import { TradeOffer } from '@/domain';
import { TradeOfferActionsConfirmDialog } from '@features/tradeOffer/actions/components';
import { TradeOfferStateKeyDialogs } from '../store/slice';

const nameDialog: TradeOfferStateKeyDialogs = 'deleteCodes';

const TradeOfferActionsDialogDeleteCodesAdapter = () => {
  const handlers = useTradeOfferActions();
  const { data: object, notification } = useSelector(tradeOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getTradeOfferActionsOfferIsProcessingSelector(object?.id ?? null));

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onDelete = useCallback(
    (offer: TradeOffer) => {
      handlers.onDeleteCodes(offer.id, notification).then(() => {
        onCloseDialog();
      });
    },
    [handlers.onDeleteCodes, notification]
  );

  return (
    object && (
      <TradeOfferActionsConfirmDialog
        offer={object}
        title={`Удалить все неприсвоенные коды?`}
        text={'В случае удаления всех неприсвоенных кодов, торговое предложение будет приостановлено'}
        actionText='Удалить'
        isFetching={isProcessing}
        onAction={onDelete}
        onClose={onCloseDialog}
      />
    )
  );
};

export default TradeOfferActionsDialogDeleteCodesAdapter;
