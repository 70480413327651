import { Location } from 'history';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Nullable, UUID } from '../../../domain/model/types';
import rootRouting from '../../../routing';
import ConfigurationPrivilegeScreen from '../../screens/configuration/privilege';
import ConfigurationSportScreen from '../../screens/configuration/sport';
import ConfigurationSportActivityTypeScreen from '../../screens/dictionaryEdit/activityType';
import ConfigurationPrivilegeBookingOfferCategoriesScreen from '../../screens/dictionaryEdit/bookingOfferCategory';
import ConfigurationPrivilegeBookingOfferPriceUnitsScreen from '../../screens/dictionaryEdit/bookingOfferPriceUnits';
import ConfigurationPrivilegeBookingServiceCategoriesScreen from '../../screens/dictionaryEdit/bookingServiceCategory';
import ConfigurationPrivilegeCorpOfferCategoriesScreen from '../../screens/dictionaryEdit/corpOfferCategory';
import ConfigurationPrivilegeTradeOfferCategoriesScreen from '../../screens/dictionaryEdit/tradeOfferCategory';
import NotFoundScreen from '../../screens/notFound';
import { EConfigurationPrivilegeDictionaryType, EConfigurationSportDictionaryType } from './utils';

const routing = {
  root: `${rootRouting.configuration}`,
  privilege: `${rootRouting.configuration}/privilege`,
  sport: `${rootRouting.configuration}/sport`,
  dictionary: {
    privilege: {
      corpOfferCategories: `${rootRouting.configuration}/privilege/corp-offers/categories`,
      tradeOfferCategories: `${rootRouting.configuration}/privilege/trade-offers/categories`,
      bookingOfferCategories: `${rootRouting.configuration}/privilege/booking-offers/categories`,
      bookingServiceCategories: `${rootRouting.configuration}/privilege/booking-services/categories`,
      bookingOfferPriceUnits: `${rootRouting.configuration}/privilege/booking-offers/price-units`,
    },
    sport: {
      activityType: `${rootRouting.configuration}/sport/activity-type`,
    },
  },
};

export interface ConfigurationLocationState {
  readonly guid: UUID;
}

interface GetConfigurationPrivilegeRouteProps {
  readonly guid?: UUID;
}

export interface ConfigurationDictionaryLocationState {
  readonly guid: UUID;
}

interface GetConfigurationPrivilegeDictionaryEditRouteProps {
  readonly guid?: UUID;
  readonly type: EConfigurationPrivilegeDictionaryType;
}

interface GetConfigurationSportDictionaryEditRouteProps {
  readonly guid?: UUID;
  readonly type: EConfigurationSportDictionaryType;
}

export const getConfigurationPrivilegeRoute = ({
  guid,
}: GetConfigurationPrivilegeRouteProps): Location<ConfigurationLocationState> => {
  const searchParams = new URLSearchParams();

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: routing.privilege,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

export const getConfigurationSportRoute = ({
  guid,
}: GetConfigurationPrivilegeRouteProps): Location<ConfigurationLocationState> => {
  const searchParams = new URLSearchParams();

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: routing.sport,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

export const getConfigurationPrivilegeCategoriesRoute = (
  props: GetConfigurationPrivilegeDictionaryEditRouteProps
): Nullable<Location<ConfigurationDictionaryLocationState>> => {
  const { guid, type } = props;

  const searchParams = new URLSearchParams();

  const search = searchParams.toString() ? `?${searchParams}` : '';

  switch (type) {
    case EConfigurationPrivilegeDictionaryType.CorpCategories:
      return {
        pathname: routing.dictionary.privilege.corpOfferCategories,
        search,
        state: {
          guid: guid ?? uuidv4(),
        },
        hash: '',
        key: '',
      };
    case EConfigurationPrivilegeDictionaryType.TradeCategories:
      return {
        pathname: routing.dictionary.privilege.tradeOfferCategories,
        search,
        state: {
          guid: guid ?? uuidv4(),
        },
        hash: '',
        key: '',
      };
    case EConfigurationPrivilegeDictionaryType.BookingOfferCategories:
      return {
        pathname: routing.dictionary.privilege.bookingOfferCategories,
        search,
        state: {
          guid: guid ?? uuidv4(),
        },
        hash: '',
        key: '',
      };
    case EConfigurationPrivilegeDictionaryType.BookingServiceCategories:
      return {
        pathname: routing.dictionary.privilege.bookingServiceCategories,
        search,
        state: {
          guid: guid ?? uuidv4(),
        },
        hash: '',
        key: '',
      };
    case EConfigurationPrivilegeDictionaryType.BookingOfferPriceUnits:
      return {
        pathname: routing.dictionary.privilege.bookingOfferPriceUnits,
        search,
        state: {
          guid: guid ?? uuidv4(),
        },
        hash: '',
        key: '',
      };
    default:
      return null;
  }
};

export const getConfigurationSportDictionaryRoute = (
  props: GetConfigurationSportDictionaryEditRouteProps
): Nullable<Location<ConfigurationDictionaryLocationState>> => {
  const { guid, type } = props;

  const searchParams = new URLSearchParams();

  const search = searchParams.toString() ? `?${searchParams}` : '';

  switch (type) {
    case EConfigurationSportDictionaryType.ActivityType:
      return {
        pathname: routing.dictionary.sport.activityType,
        search,
        state: {
          guid: guid ?? uuidv4(),
        },
        hash: '',
        key: '',
      };
    default:
      return null;
  }
};

const ConfigurationEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.privilege}
        component={ConfigurationPrivilegeScreen}
      />
      <Route
        exact
        path={routing.sport}
        component={ConfigurationSportScreen}
      />
      <Route
        exact
        path={routing.dictionary.privilege.corpOfferCategories}
        component={ConfigurationPrivilegeCorpOfferCategoriesScreen}
      />
      <Route
        exact
        path={routing.dictionary.privilege.tradeOfferCategories}
        component={ConfigurationPrivilegeTradeOfferCategoriesScreen}
      />
      <Route
        exact
        path={routing.dictionary.privilege.bookingOfferCategories}
        component={ConfigurationPrivilegeBookingOfferCategoriesScreen}
      />
      <Route
        exact
        path={routing.dictionary.privilege.bookingOfferPriceUnits}
        component={ConfigurationPrivilegeBookingOfferPriceUnitsScreen}
      />
      <Route
        exact
        path={routing.dictionary.privilege.bookingServiceCategories}
        component={ConfigurationPrivilegeBookingServiceCategoriesScreen}
      />
      <Route
        exact
        path={routing.dictionary.sport.activityType}
        component={ConfigurationSportActivityTypeScreen}
      />
      <Redirect
        exact
        from={routing.root}
        to={routing.privilege}
      />
      <Route component={NotFoundScreen} />
    </Switch>
  );
};

export default ConfigurationEntry;
