import Api from '../../../../data/api';
import { ApiCancellable } from '../../../../data/api/types';
import { CmsCollection } from '../../../../domain/model/cms';
import { ECmsCollectionLinkObjectType } from '../../../../domain/model/enums';
import { UUID } from '../../../../domain/model/types';
import {
  CmsLinkedObjectCollectionCorpOfferType,
  CmsLinkedObjectCollectionPartnerType,
  CmsLinkedObjectCollectionTradeOfferType,
  CmsLinkedObjectCollectionTypes,
} from '../types/collection';
import cmsServices from './index';

type ByIdProps = ApiCancellable & {
  readonly id: UUID;
};

type GetByLinkedObjectIdProps = ApiCancellable & {
  readonly id: UUID;
  readonly ignoreData?: boolean;
};

export type CmsCollectionService = {
  readonly byId: (props: ByIdProps) => Promise<CmsCollection>;
  readonly getCollectionByLinkObjectId: (props: GetByLinkedObjectIdProps) => Promise<CmsLinkedObjectCollectionTypes>;
  readonly getCollectionCorpOfferByLinkObjectId: (
    props: GetByLinkedObjectIdProps
  ) => Promise<CmsLinkedObjectCollectionCorpOfferType>;
  readonly getCollectionTradeOfferByLinkObjectId: (
    props: GetByLinkedObjectIdProps
  ) => Promise<CmsLinkedObjectCollectionTradeOfferType>;
  readonly getCollectionPartnerByLinkObjectId: (
    props: Omit<GetByLinkedObjectIdProps, 'ignoreData'>
  ) => Promise<CmsLinkedObjectCollectionPartnerType>;
};

const cmsCollectionService: CmsCollectionService = {
  byId: async ({ id, signal }) => {
    const { data } = await Api.cms.collection.one({ id, signal });
    return data;
  },
  getCollectionByLinkObjectId: async ({ id, ignoreData, signal }) => {
    const { data: cmsCollection } = await Api.cms.collection.one({
      id,
      signal,
    });

    let cmsLinkedObjectCollection: CmsLinkedObjectCollectionTypes;
    switch (cmsCollection.linkObjectType) {
      case ECmsCollectionLinkObjectType.CorpOffer:
        cmsLinkedObjectCollection = await cmsServices.collection.getCollectionCorpOfferByLinkObjectId({
          id,
          ignoreData,
          signal,
        });
        break;
      case ECmsCollectionLinkObjectType.TradeOffer:
        cmsLinkedObjectCollection = await cmsServices.collection.getCollectionTradeOfferByLinkObjectId({
          id,
          ignoreData,
          signal,
        });
        break;
      case ECmsCollectionLinkObjectType.Partner:
        cmsLinkedObjectCollection = await cmsServices.collection.getCollectionPartnerByLinkObjectId({
          id,
          signal,
        });
        break;
    }

    return cmsLinkedObjectCollection;
  },
  getCollectionCorpOfferByLinkObjectId: async ({ id, ignoreData, signal }) => {
    const linkedObject = ignoreData ? null : (await Api.corpOffer.collection({ id, signal })).data;
    return {
      linkObjectType: ECmsCollectionLinkObjectType.CorpOffer,
      linkedObject,
    };
  },
  getCollectionTradeOfferByLinkObjectId: async ({ id, ignoreData, signal }) => {
    const linkedObject = ignoreData ? null : (await Api.tradeOffer.collection({ id, signal })).data;
    return {
      linkObjectType: ECmsCollectionLinkObjectType.TradeOffer,
      linkedObject,
    };
  },
  getCollectionPartnerByLinkObjectId: async ({ id, signal }) => {
    const linkedObject = (await Api.partner.collection({ id, signal })).data;
    return {
      linkObjectType: ECmsCollectionLinkObjectType.Partner,
      linkedObject,
    };
  },
};

export default cmsCollectionService;
