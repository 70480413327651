import { ContentTarget } from '../../../../../../domain/model';
import { EMultiSelectorValueType } from '../../../../../../domain/model/enums';
import { AddressHelper } from '../../../../../utils/address';

interface ContentTargetViewLocalitiesTableCellProps {
  readonly value: ContentTarget['localities'];
  readonly limit?: number;
}

const ContentTargetViewLocalitiesTableCell = (props: ContentTargetViewLocalitiesTableCellProps) => {
  const { value, limit = 999 } = props;

  const targetLocalities = value ?? { select: EMultiSelectorValueType.In, in: null };

  const displayValue =
    targetLocalities.select === EMultiSelectorValueType.All
      ? 'Вся Россия'
      : targetLocalities.in
          ?.slice(0, limit)
          ?.map(locality => new AddressHelper(locality).getLastLocalityShortPath())
          ?.join(', ') ?? '-';

  return <>{displayValue}</>;
};

export default ContentTargetViewLocalitiesTableCell;
