import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { UUID } from '../../../../../../domain/model/types';

export interface UikitMenuState {
  readonly opened: {
    readonly [guid: string]: boolean;
  };
}

type Reducer<T> = CaseReducer<UikitMenuState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<UikitMenuState> {
  addToOpened: Reducer<{ itemId: UUID }>;
}

const slice = createSlice<UikitMenuState, Reducers, 'uikit/menu'>({
  name: 'uikit/menu',
  initialState: {
    opened: {},
  },
  reducers: {
    addToOpened(state, { payload }) {
      const { itemId } = payload;
      state.opened[itemId] = !state.opened[itemId];
    },
  },
});

export const { addToOpened } = slice.actions;

export default slice.reducer;
