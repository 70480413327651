import { combineReducers } from '@reduxjs/toolkit';
import details, { SportsmanDetailsState } from './details/store/slice';
import search, { SportsmansSearchState } from './search/store/slice';
import list, { SportsmansState } from './table/store/slice';

interface SportsmanState {
  list: SportsmansState;
  details: SportsmanDetailsState;
  search: SportsmansSearchState;
}

export default combineReducers<SportsmanState>({
  list,
  details,
  search,
});
