import DataFilterEdit from '@components/common/dataFilter/edit';
import ReportDialogLayout from '@layouts/reportDialog';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getDataFilterValuesByStrategies } from '@/presentation/utils/filtering';
import {
  defaultDatePeriod,
  reportDownloadURL,
  reportSportPeriodTypes,
  validateDatePeriod,
} from '@features/report/utils';
import reportServices from '@features/report/services';
import Notifier from '@/system/notifier';
import { ENoticeStatus } from '@/domain';
import {
  EReportSportUsersFilterItem,
  getReportSportUsersFilterStrategies,
  ReportSportUsersFilterEditStrategy,
  ReportSportUsersFilterValues,
} from './filters';
import { EReportSportPeriodType } from '@features/report/types';

type SportUsersReportProps = {
  readonly onClose: () => void;
};

// Задаем значение фильтров по умолчанию
const defaultFilterValue: ReportSportUsersFilterValues = {
  [EReportSportUsersFilterItem.DatePeriod]: {
    value: defaultDatePeriod,
  },
  [EReportSportUsersFilterItem.PeriodType]: {
    value: EReportSportPeriodType.DateEvent,
  },
};

export const SportUsersReportContainer = (props: SportUsersReportProps) => {
  const { onClose } = props;
  const [error, setError] = useState<Nullable<string>>(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const [filter, setFilter] = useState<ReportSportUsersFilterValues>(defaultFilterValue);

  const resetValidation = useCallback(() => {
    setError(null);
  }, []);

  const filterStrategies = useMemo(() => getReportSportUsersFilterStrategies(filter, reportSportPeriodTypes), [filter]);

  const onChangeStrategies = useCallback(
    (strategies: ReportSportUsersFilterEditStrategy[]) => {
      setFilter(getDataFilterValuesByStrategies(strategies));
    },
    [setFilter]
  );

  const onResetFilter = useCallback(() => {
    setFilter(defaultFilterValue);
  }, [setFilter]);

  const handleDownload = () => {
    resetValidation();
    const startDate = filter[EReportSportUsersFilterItem.DatePeriod]?.value?.[0];
    const endDate = filter[EReportSportUsersFilterItem.DatePeriod]?.value?.[1];
    const periodType = filter[EReportSportUsersFilterItem.PeriodType]?.value;

    const isValid = validateDatePeriod(startDate, endDate);

    if (isValid) {
      setIsDownloading(true);
      reportServices.sport
        .users({ startDate, endDate, periodType })
        .then(result => {
          reportDownloadURL(result);
          onClose();
        })
        .catch(error => Notifier.getInstance().addNotice(ENoticeStatus.Error, `${error.message}`))
        .finally(() => setIsDownloading(false));
    } else {
      setError('Заполните корректно поля даты');
    }
  };

  useEffect(() => {
    resetValidation();
  }, [filter]);

  return (
    <ReportDialogLayout
      title='Пользователи'
      filter={
        <DataFilterEdit
          strategies={filterStrategies}
          onChange={onChangeStrategies}
        />
      }
      error={error}
      isFetching={isDownloading}
      onFetch={handleDownload}
      onClearFilter={onResetFilter}
      onClose={onClose}
    />
  );
};
