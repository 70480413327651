import { getUserFullName } from '../../../../utils';
import PartnerTableCell, { PartnerTableCellCommonProps } from './index';

const PartnerTableCellReviewer = (props: PartnerTableCellCommonProps) => {
  const {
    partner: { partnerReviewer },
  } = props;

  const value = partnerReviewer ? getUserFullName({ user: partnerReviewer }) : '-';

  return (
    <PartnerTableCell
      {...props}
      value={value}
    />
  );
};

export default PartnerTableCellReviewer;
