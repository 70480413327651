import { useDispatch } from 'react-redux';
import { EBookingOrderActionType } from '../types';
import BookingOrderActionsDialogsAdapter from './adapters';
import BookingOrderActionsOptimizerAdapter from './adapters/optimizer';
import BookingOrderActionsContext, { BookingOrderActionsContextType } from './context';
import {
  getBookingOrderActionsOfferIsProcessingSelector,
  getIsBookingOrderAnyActionByTypeExecutedSelector,
  isBookingOrdersAnyChangedSelector,
} from './store/selectors';
import {
  bookingOrderActionsChangeDialogState,
  bookingOrderActionsConfirm,
  bookingOrderActionsGive,
  bookingOrderActionsRenew,
  bookingOrderActionsSave,
} from './store/slice';
import { showBookingOrderActionNotification } from './utils';

type BookingOrderActionsProviderProps = {
  readonly children: JSX.Element;
};

type BookingOrderActionsProviderType = (props: BookingOrderActionsProviderProps) => JSX.Element;

const BookingOrderActionsProvider: BookingOrderActionsProviderType = ({ children }) => {
  const dispatch = useDispatch();

  const onChangeDialogState: BookingOrderActionsContextType['onChangeDialogState'] = data => {
    dispatch(bookingOrderActionsChangeDialogState(data));
  };

  const onSaveInStatus: BookingOrderActionsContextType['onSaveInStatus'] = async (status, order) => {
    const response = await dispatch(
      bookingOrderActionsSave({
        ...order,
        status,
      })
    ).unwrap();

    if (response) {
      showBookingOrderActionNotification(response, EBookingOrderActionType.Save);
    }
    return response;
  };

  const onConfirm: BookingOrderActionsContextType['onConfirm'] = async order => {
    const response = await dispatch(
      bookingOrderActionsConfirm({
        id: order.id,
      })
    ).unwrap();

    if (response) {
      showBookingOrderActionNotification(response, EBookingOrderActionType.Confirm);
    }
  };

  const onRenew: BookingOrderActionsContextType['onRenew'] = async order => {
    const response = await dispatch(
      bookingOrderActionsRenew({
        id: order.id,
      })
    ).unwrap();

    if (response) {
      showBookingOrderActionNotification(response, EBookingOrderActionType.Renew);
    }
  };

  const onGive: BookingOrderActionsContextType['onGive'] = async order => {
    const response = await dispatch(
      bookingOrderActionsGive({
        id: order.id,
      })
    ).unwrap();

    if (response) {
      showBookingOrderActionNotification(response, EBookingOrderActionType.Give);
    }
  };

  const onTryCancel: BookingOrderActionsContextType['onTryCancel'] = data => {
    onChangeDialogState({ name: 'cancel', data });
  };

  const onTryDownload: BookingOrderActionsContextType['onTryDownload'] = data => {
    onChangeDialogState({ name: 'download', data });
  };

  const value: BookingOrderActionsContextType = {
    onChangeDialogState,

    onSaveInStatus,
    onGive,
    onRenew,
    onConfirm,
    onTryCancel,
    onTryDownload,

    utils: {
      selectors: {
        getIsBookingOrderExecutedActionsSelector: getIsBookingOrderAnyActionByTypeExecutedSelector,
        getIsBookingOrderProcessingSelector: getBookingOrderActionsOfferIsProcessingSelector,
        isBookingOrdersAnyChangedSelector,
      },
    },
  };

  return (
    <BookingOrderActionsContext.Provider value={value}>
      {children}
      <BookingOrderActionsDialogsAdapter />
      <BookingOrderActionsOptimizerAdapter />
    </BookingOrderActionsContext.Provider>
  );
};

export default BookingOrderActionsProvider;
