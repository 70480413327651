import { Divider } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nullable, UUID } from '../../../../domain/model/types';
import ContentLoader from '../../../components/common/loader';
import InfiniteList from '../../../theme/ui-kit/list/infinite';
import { nsiDataSelector } from '../../general/nsi/store/selectors';
import { ListWrapper, Wrapper } from './controls';
import EventListFilter from './filter';
import useEventListHandlers from './hooks/useEventList';
import EventListItem from './item';
import EventListLoaderItem from './itemLoader';
import EventListSplitter from './splitter';
import {
  eventListFeatureEventsSelector,
  eventListGuidSelector,
  eventListNeedRefreshWatcherSelector,
  eventListPastEventsSelector,
} from './store/selectors';
import { EEventListElementType, EventListElement } from './types';

interface EventListContainerProps {
  readonly guid: UUID;
  readonly userId?: UUID;
  readonly playgroundId?: UUID;
  readonly teamId?: UUID;
}

const EventListContainer = ({ guid, userId, playgroundId, teamId }: EventListContainerProps) => {
  const dispatch = useDispatch();

  const { eventTypes } = useSelector(nsiDataSelector);
  const currentGuid = useSelector(eventListGuidSelector);
  const needRefreshWatcher = useSelector(eventListNeedRefreshWatcherSelector);
  const past = useSelector(eventListPastEventsSelector);
  const feature = useSelector(eventListFeatureEventsSelector);

  const handlers = useEventListHandlers({ guid, userId, playgroundId, teamId });

  let eventsTotalCount = past.totalCount + feature.totalCount;
  const eventItems: EventListElement[] = [];
  if (feature.data.length > 0) {
    eventItems.push({
      id: 'feature_title',
      data: 'Предстоящие',
      type: EEventListElementType.Splitter,
    });
    eventsTotalCount += 1;

    feature.data.forEach(event =>
      eventItems.push({
        id: event.id,
        data: event,
        type: EEventListElementType.Event,
      })
    );
  }
  if (past.data.length > 0) {
    eventItems.push({
      id: 'past_title',
      data: 'Прошедшие',
      type: EEventListElementType.Splitter,
    });
    eventsTotalCount += 1;

    past.data.forEach(event =>
      eventItems.push({
        id: event.id,
        data: event,
        type: EEventListElementType.Event,
      })
    );
  }

  const onRowRender = useCallback(
    (item: Nullable<EventListElement>) => (
      <React.Fragment key={item?.id}>
        {item && (
          <>
            {item.type === EEventListElementType.Splitter && <EventListSplitter data={item.data} />}
            {item.type === EEventListElementType.Event && (
              <>
                <EventListItem
                  event={item.data}
                  eventType={eventTypes.find(et => et.id === item.data.type.code)!.name}
                  onEventClick={handlers.onEventClick}
                />
                <Divider
                  key={`divider${item?.id || Math.random()}`}
                  variant='inset'
                />
              </>
            )}
          </>
        )}

        {!item && <EventListLoaderItem key='loader' />}
      </React.Fragment>
    ),
    [eventTypes, handlers.onEventClick]
  );

  // первоначальная загрузка
  useEffect(() => {
    if (guid !== currentGuid) {
      const request = handlers.onEventsFetch();
      return () => {
        request.abort();
      };
    }
  }, [dispatch, handlers.onEventsFetch]);

  // обновление данных
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      handlers.onEventsFetch(true);
    }
  }, [dispatch, needRefreshWatcher]);

  // чтобы загрузка была планомерная определяем totalCount с одним лишним элементом как признак наличия еще элементов
  const limitedEventsTotalCount = Math.min(eventItems.length + 1, eventsTotalCount);

  const isFetching = past.isFetching || feature.isFetching;

  if (guid !== currentGuid) {
    return null;
  }

  return (
    <Wrapper>
      <EventListFilter />
      <ListWrapper>
        <InfiniteList<EventListElement>
          list={eventItems}
          isLoading={isFetching}
          loadMore={handlers.onEventsLoadMore}
          totalCount={limitedEventsTotalCount}
          onRowRender={onRowRender}
        />
        {isFetching && (
          <ContentLoader
            position='absolute'
            horizontalPosition='flex-start'
          />
        )}
      </ListWrapper>
    </Wrapper>
  );
};

export default EventListContainer;
