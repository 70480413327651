import SupportInfoMpLayout from '../mp/layout';
import SupportInfoSportContactsAdapter from './adapters/contacts';
import SupportInfoSportDocumentsAdapter from './adapters/documents';
import SupportInfoSportHeaderAdapter from './adapters/header';

const SupportInfoSportContainer = () => {
  return (
    <SupportInfoMpLayout
      header={<SupportInfoSportHeaderAdapter />}
      contacts={<SupportInfoSportContactsAdapter />}
      documents={<SupportInfoSportDocumentsAdapter />}
    />
  );
};

export default SupportInfoSportContainer;
