import { combineReducers } from '@reduxjs/toolkit';
import menuPanel, { MenuPanelLayoutState } from './menuPanel/store/slice';

export interface LayoutState {
  menuPanel: MenuPanelLayoutState;
}

export default combineReducers<LayoutState>({
  menuPanel,
});
