import { FormControlProps, Typography } from '@mui/material';
import { FCC, ReactNode } from 'react';
import { Info, Main, Wrapper } from './controls';

export interface FormItemProps extends Omit<FormControlProps, 'title'> {
  readonly title?: ReactNode;
  readonly description?: ReactNode;
  readonly direction?: 'row' | 'column';
}

export const FormItem: FCC<FormItemProps> = props => {
  const { children, title, description, disabled = false, direction = 'column', ...other } = props;

  return (
    <Wrapper
      {...other}
      disabled={disabled}
    >
      {(title || description) && (
        <Info>
          {title && (
            <Typography
              component={'div'}
              variant={'subtitle2'}
            >
              {title}
            </Typography>
          )}
          {description && (
            <Typography
              component={'div'}
              variant={'body2'}
            >
              {description}
            </Typography>
          )}
        </Info>
      )}
      <Main direction={direction}>{children}</Main>
    </Wrapper>
  );
};
