import { combineReducers } from '@reduxjs/toolkit';
import byPartner, { CustomerManagementByPartnerState } from './byPartner/store/slice';
import common, { CustomerManagementCommonState } from './common/store/slice';
import menus, { CustomerManagementMenusState } from './menu/store/slice';

export interface CustomerManagementState {
  common: CustomerManagementCommonState;
  byPartner: CustomerManagementByPartnerState;
  menus: CustomerManagementMenusState;
}

export default combineReducers<CustomerManagementState>({
  common,
  byPartner,
  menus,
});
