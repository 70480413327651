import { Grid } from '@mui/material';
import { Nullable } from '../../../../../../domain/model/types';
import { MpUserData } from '../../../../../../domain/model/user';
import ValidationErrorPanel from '../../../../../components/common/validation/errorPanel';
import { OnChangeObjectAttribute } from '../../../../../types';
import { ValidationResult } from '../../../../../utils/validation';
import UserCommonEditProfile from '../../../../user/components/editProfile';
import { Content } from '../../controls';
import { ColumnWrapper, Wrapper } from './controls';

interface PartnerEmployeeEditStepProfileProps {
  readonly partnerEmployee: MpUserData;
  readonly validation: Nullable<ValidationResult<MpUserData>>;
  readonly isValid: boolean;
  readonly onChangeAttribute: OnChangeObjectAttribute<MpUserData>;
}

const PartnerEmployeeEditStepProfile = (props: PartnerEmployeeEditStepProfileProps) => {
  const { partnerEmployee, validation, isValid, onChangeAttribute } = props;

  return (
    <Wrapper>
      <ColumnWrapper>
        <Content>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <UserCommonEditProfile
                user={partnerEmployee}
                validation={validation}
                onChangeAttribute={onChangeAttribute}
              />
            </Grid>
            {!isValid && (
              <Grid
                item
                xs={12}
              >
                <ValidationErrorPanel text='Заполните все обязательные поля' />
              </Grid>
            )}
          </Grid>
        </Content>
      </ColumnWrapper>
    </Wrapper>
  );
};

export default PartnerEmployeeEditStepProfile;
