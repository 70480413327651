import { RootState } from '@/data/store/store';
import { CorpOfferActivation } from '@/domain/model/activation';
import { ESortDirection } from '@/domain/model/enums';
import { Nullable } from '@/domain/model/types';
import { createSelector } from '@reduxjs/toolkit';
import { ECorpActivationTableColumn } from '../../types';

export const corpActivationsGuidSelector = (store: RootState) => store.corpActivation.list.guid;

export const corpActivationsActivationsSelector = (store: RootState) => store.corpActivation.list.corpActivations.data;
export const corpActivationsIsFetchingSelector = (store: RootState) =>
  store.corpActivation.list.corpActivations.isFetching;

const corpActivationsCorpActivationIndexSelector = (store: RootState, index: number) => index;
const createCorpActivationTableCorpActivationByIndexSelector = createSelector(
  corpActivationsActivationsSelector,
  corpActivationsCorpActivationIndexSelector,
  (corpActivations, index): Nullable<CorpOfferActivation> => corpActivations?.[index] ?? null
);
export const corpActivationsCorpActivationByIndexSelector = (index: number) => (store: RootState) =>
  createCorpActivationTableCorpActivationByIndexSelector(store, index);

export const corpActivationsPageNumberSelector = (store: RootState) =>
  store.corpActivation.list.corpActivations.pageNumber;
export const corpActivationsTotalCountSelector = (store: RootState) =>
  store.corpActivation.list.corpActivations.totalCount;
export const corpActivationsPageCountSelector = (store: RootState) =>
  store.corpActivation.list.corpActivations.pageCount;

export const corpActivationsSearchSelector = (store: RootState) => store.corpActivation.list.search;
export const corpActivationsFilterSelector = (store: RootState) => store.corpActivation.list.filter;

export const corpActivationsSortSelector = (store: RootState) => store.corpActivation.list.search.sort;

export const corpActivationsSortColumnSelector = (store: RootState) =>
  (store.corpActivation.list.search.sort?.split(',') as [ECorpActivationTableColumn, ESortDirection])?.[0] ?? '';
export const corpActivationsSortDirectionSelector = (store: RootState) =>
  (store.corpActivation.list.search.sort?.split(',') as [ECorpActivationTableColumn, ESortDirection])?.[1] ?? '';

export const corpActivationsNeedRefreshWatcherSelector = (store: RootState) =>
  store.corpActivation.list.needRefreshWatcher;

export const corpActivationsTabsCounterSelector = (store: RootState) => store.corpActivation.list.tabsCounter;
