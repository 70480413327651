import { Divider, Paper } from '@mui/material';
import { PaperProps } from '@mui/material/Paper/Paper';
import { FCC, ReactNode } from 'react';

type AddressPaperComponentProps = PaperProps & {
  readonly footer?: ReactNode;
};
export const AddressPaperComponent: FCC<AddressPaperComponentProps> = props => {
  const { children, footer, ...paperProps } = props;
  return (
    <Paper {...paperProps}>
      {children}
      {footer && <Divider />}
      {footer}
    </Paper>
  );
};
