import { DrawerProps } from 'material-ui';
import { FCC, ReactNode } from 'react';
import { UUID } from '../../../domain/model/types';
import MenuPanelLayout from './container';
import { Drawer } from './controls';

interface MenuPanelDrawerLayoutProps extends Exclude<DrawerProps, 'open'> {
  readonly guid: UUID;
  readonly visible: boolean;
  readonly backgroundColor?: string;
  readonly header?: ReactNode;
  readonly footer?: ReactNode;
}

const MenuPanelDrawerLayout: FCC<MenuPanelDrawerLayoutProps> = props => {
  const { guid, backgroundColor, header, footer, visible, children, ...other } = props;

  return (
    <Drawer
      variant={visible ? 'permanent' : 'temporary'}
      open={visible}
      ModalProps={{ BackdropComponent: undefined }}
      backgroundColor={backgroundColor}
      {...other}
    >
      <MenuPanelLayout
        guid={guid}
        header={header}
        footer={footer}
      >
        {children}
      </MenuPanelLayout>
    </Drawer>
  );
};

export default MenuPanelDrawerLayout;
