import { Link, TypographyProps } from '@mui/material';
import { LinkProps } from '@mui/material/Link/Link';
import { FCC, forwardRef } from 'react';
import useBusinessConfig from '../../../hooks/useBusinessConfig';

export enum PersonalDataPolicyCase {
  Instrumental = 'instrumental',
}

type SupportPdpLinkContainerCase = LinkProps & {
  readonly textCase?: PersonalDataPolicyCase;
};

type SupportPdpLinkContainerProps = SupportPdpLinkContainerCase;

const SupportPdpLinkContainer = forwardRef<any, SupportPdpLinkContainerProps>(
  ({ children, textCase, ...others }, ref) => {
    const { supportInfo } = useBusinessConfig();

    let text = '';
    switch (textCase) {
      case PersonalDataPolicyCase.Instrumental:
        text = 'Политикой обработки персональных данных в ОАО «РЖД»';
        break;
      case undefined:
        text = 'Политика обработки персональных данных в ОАО «РЖД»';
        break;
    }

    return (
      <Link
        target='_blank'
        href={supportInfo.personalDataPolicyUrl}
        color='primary'
        {...others}
      >
        {children ?? text}
      </Link>
    );
  }
);

export const SupportPdpLinkInstrumental: FCC<TypographyProps> = props => (
  <SupportPdpLinkContainer
    textCase={PersonalDataPolicyCase.Instrumental}
    {...props}
  />
);

export default SupportPdpLinkContainer;
