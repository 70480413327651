import { Typography, TypographyProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { HintPopover, StyledTypography } from './controls';

export interface DataTableCellTextFormatProps extends TypographyProps {
  readonly bold?: boolean;
  readonly disabled?: boolean;
  readonly maxLines?: number;
  readonly hintWhenOverflow?: boolean;
}

const DataTableCellTextFormat = (props: DataTableCellTextFormatProps) => {
  const { bold, disabled, maxLines, hintWhenOverflow, children, ...others } = props;

  const ref = useRef<HTMLElement>(null);
  const [isOverflowed, setIsOverflowed] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const onOpen = () => setOpen(true);

  const onClose = () => setOpen(false);

  useEffect(() => {
    const el = ref.current;
    if (el) {
      const { scrollWidth, offsetWidth, scrollHeight, offsetHeight } = el;
      setIsOverflowed(scrollWidth > offsetWidth || scrollHeight > offsetHeight);
    }
  }, []);

  return (
    <>
      <StyledTypography
        ref={ref}
        bold={bold}
        disabled={disabled}
        component={'div'}
        maxLines={maxLines}
        hint={hintWhenOverflow && isOverflowed}
        onClick={hintWhenOverflow && isOverflowed ? onOpen : undefined}
        {...others}
      >
        {children}
      </StyledTypography>
      <HintPopover
        open={open}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={onClose}
      >
        <Typography component={'div'}>{children}</Typography>
      </HintPopover>
    </>
  );
};

export default DataTableCellTextFormat;
