import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TradeOfferCategoriesEditContainer from '../../features/dictionary/tradeOfferCategory/edit/container';
import { viewHideMainMenu } from '../../features/main/container/store/slice';

const ConfigurationPrivilegeTradeOfferCategoriesScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  return <TradeOfferCategoriesEditContainer />;
};

export default ConfigurationPrivilegeTradeOfferCategoriesScreen;
