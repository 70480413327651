import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SportOption } from '../../../../../domain/model';
import { ENoticeStatus } from '../../../../../domain/model/enums';
import { Nullable, UUID } from '../../../../../domain/model/types';
import Notifier from '../../../../../system/notifier';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';
import ContentLoader from '../../../../components/common/loader';
import { MPButton } from '../../../../theme/ui-kit/button';
import { MPFormTextArea } from '../../../../theme/ui-kit/input';
import { MPMenuItem } from '../../../../theme/ui-kit/menu/item';
import { MPSelect } from '../../../../theme/ui-kit/select';
import {
  nsiBookingCustomerOrderCancelReasonsSelector,
  nsiBookingOrderCancelReasonsSelector,
} from '../../../general/nsi/store/selectors';
import { EBookingOrderActionType, EBookingOrderResponsible } from '../../types';
import {
  bookingOrderActionsDialogByNameSelector,
  getBookingOrderActionsOfferIsProcessingSelector,
} from '../store/selectors';
import { bookingOrderActionsCancel } from '../store/slice';
import { useBookingOrderActions } from '../useAction';
import { showBookingOrderActionNotification } from '../utils';

type Initiator = {
  readonly id: EBookingOrderResponsible;
  readonly name: string;
};

const initiators: Initiator[] = [
  {
    id: EBookingOrderResponsible.Customer,
    name: 'Клиент',
  },
  {
    id: EBookingOrderResponsible.Manager,
    name: 'Менеджер',
  },
];

const OrdersActionsDialogCancelAdapter = () => {
  const dispatch = useDispatch();
  const handlers = useBookingOrderActions();

  // Получаем причины отмены
  const customerOrderCancelReasons = useSelector(nsiBookingCustomerOrderCancelReasonsSelector);
  const orderCancelReasons = useSelector(nsiBookingOrderCancelReasonsSelector);

  const [initiator, setInitiator] = useState<Nullable<Initiator>>(null);
  const [reason, setReason] = useState<Nullable<SportOption>>(null);
  const [comment, setComment] = useState<Nullable<string>>(null);

  const order = useSelector(bookingOrderActionsDialogByNameSelector('cancel'));
  const isProcessing = useSelector(getBookingOrderActionsOfferIsProcessingSelector(order?.id ?? null));

  const reasons = useMemo(() => {
    switch (initiator?.id) {
      case EBookingOrderResponsible.Manager:
        return orderCancelReasons;
      case EBookingOrderResponsible.Customer:
        return customerOrderCancelReasons;
      default:
        return null;
    }
  }, [initiator]);

  const onChangeInitiator = (newInitiator: Nullable<Initiator>) => {
    setInitiator(newInitiator);
    setReason(null);
    setComment(null);
  };

  const onChangeReasonId = useCallback(
    (id: UUID) => {
      setReason(reasons?.find(item => item.id === id) ?? null);
      setComment(null);
    },
    [reasons]
  );

  const cancelAction = useCallback(() => {
    if (order && reason && initiator) {
      dispatch(
        bookingOrderActionsCancel({
          id: order.id,
          comment: comment ?? undefined,
          reasonId: reason.id,
          responsible: initiator.id,
        })
      )
        .unwrap()
        .then(data => {
          showBookingOrderActionNotification(data, EBookingOrderActionType.Cancel);
          handlers.onChangeDialogState({ name: 'cancel', data: null });
          onChangeInitiator(null);
        });
    } else {
      Notifier.getInstance().addNotice(
        ENoticeStatus.Error,
        `Не удалось определить причину отмены. Справочник пуст или заполнен некорректно.`
      );
    }
  }, [dispatch, initiator, comment, order, reason, handlers.onChangeDialogState]);

  const onCloseDialog = useCallback(() => {
    handlers.onChangeDialogState({
      name: 'cancel',
      data: null,
    });
    onChangeInitiator(null);
  }, [handlers.onChangeDialogState]);

  // отменить можно в случае когда есть список причин и причина выбрана или нет причин и введен комментарий
  const canCancel = useMemo(
    () => reason && (reason.name.toLowerCase() !== 'другое' || comment?.length),
    [reason, comment]
  );

  if (!order) {
    return null;
  }

  return (
    order && (
      <ConfirmDialog
        open
        onClose={onCloseDialog}
        title='Причина отмены'
        dialogButtons={
          <Grid
            container
            spacing={2}
          >
            <Grid item>
              <MPButton
                fullWidth={false}
                disabled={!canCancel || isProcessing}
                onClick={cancelAction}
              >
                Выбрать
                {isProcessing && <ContentLoader />}
              </MPButton>
            </Grid>
            <Grid item>
              <MPButton
                fullWidth={false}
                variant='outlined'
                onClick={onCloseDialog}
              >
                Закрыть
              </MPButton>
            </Grid>
          </Grid>
        }
      >
        <Grid
          container
          spacing={2}
          wrap='nowrap'
          direction='column'
        >
          <Grid item>
            <MPSelect<Initiator>
              label={'Инициатор отмены'}
              value={initiator}
              onChange={onChangeInitiator}
            >
              {initiators.map(item => (
                <MPMenuItem
                  key={item.id}
                  value={item}
                >
                  <Typography variant='body1'>{item.name}</Typography>
                </MPMenuItem>
              ))}
            </MPSelect>
          </Grid>

          {reasons && (
            <Grid item>
              <RadioGroup
                value={reason?.id}
                onChange={event => onChangeReasonId(event.target.value)}
              >
                {reasons.map(item => (
                  <FormControlLabel
                    key={item.id}
                    id={item.id}
                    value={item.id}
                    control={<Radio color={'primary'} />}
                    label={item.name}
                  />
                ))}
              </RadioGroup>
            </Grid>
          )}

          {reason?.name.toLowerCase() === 'другое' && (
            <Grid item>
              <MPFormTextArea
                rows={3}
                label='Опишите проблему'
                value={comment}
                margin='none'
                disabled={isProcessing}
                onChange={event => setComment(event.target.value)}
              />
            </Grid>
          )}
        </Grid>
      </ConfirmDialog>
    )
  );
};

export default OrdersActionsDialogCancelAdapter;
