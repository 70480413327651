import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActivityTypesMultipleSelector, ActivityTypesMultipleSelectorProps } from '../components/treeSelector';
import { activityTypeEditControlDataSelector, activityTypeEditControlIsFetchingSelector } from './store/selectors';
import { activityTypeEditControlFetch, activityTypeEditControlStateReset } from './store/slice';

export type ActivityTypeEditControlTreeMultipleContainerProps = Omit<ActivityTypesMultipleSelectorProps, 'options'> & {
  //удаление значений, которых по факту нет в наборе, при этом вызывается onChange
  readonly filterNotValidValues?: boolean;
  //только спортивные интересы
  readonly sport?: boolean;
};

const ActivityTypeEditControlTreeMultipleContainer = (props: ActivityTypeEditControlTreeMultipleContainerProps) => {
  const { label, filterNotValidValues, excludeSelectBranchLevels = true, sport, limitTags = 6, ...others } = props;

  const dispatch = useDispatch();

  const activityTypes = useSelector(activityTypeEditControlDataSelector);
  const isFetching = useSelector(activityTypeEditControlIsFetchingSelector);

  const valueToAutoChange = useMemo(() => {
    if (filterNotValidValues && others?.value?.length && activityTypes?.length) {
      const notExistedValueItems = others.value.filter(item => !activityTypes.some(ac => ac.id === item.id));
      if (notExistedValueItems.length) {
        return others.value.filter(v => !notExistedValueItems.some(ne => ne.id === v.id));
      }
    }
  }, [filterNotValidValues, activityTypes]);

  useEffect(() => {
    if (valueToAutoChange) {
      others.onChange(valueToAutoChange);
    }
  }, [valueToAutoChange]);

  useEffect(() => {
    const promise = dispatch(activityTypeEditControlFetch({ sport }));
    return () => {
      promise?.abort();
      dispatch(activityTypeEditControlStateReset());
    };
  }, [dispatch, sport]);

  return (
    <ActivityTypesMultipleSelector
      label={label || 'Интересы'}
      options={activityTypes ?? []}
      isLoading={isFetching}
      excludeSelectBranchLevels={excludeSelectBranchLevels}
      limitTags={limitTags}
      {...others}
    />
  );
};

export default ActivityTypeEditControlTreeMultipleContainer;
