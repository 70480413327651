import { useSelector } from 'react-redux';
import { MpUser } from '../../../../../domain/model/user';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { EPartnerEmployeeActionType, PartnerEmployeeActions, PartnerEmployeeActionTableType } from '../../types';
import PartnerEmployeeTable from '../component';
import { EPartnerEmployeesFilterItem } from '../filterUtils';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  partnerEmployeesActionsSelector,
  partnerEmployeesColumnSelector,
  partnerEmployeesEmployeesSelector,
  partnerEmployeesFilterSelector,
  partnerEmployeesGuidSelector,
  partnerEmployeesSortDirectionSelector,
} from '../store/selectors';
import {
  EPartnerEmployeeTableColumn,
  PartnerEmployeeTableActionCall,
  PartnerEmployeeTableActionProcess,
} from '../utils';

interface PartnerEmployeeTableAdapterProps {
  readonly filterAdapter: DataFilterAdapter<EPartnerEmployeeTableColumn, EPartnerEmployeesFilterItem>;
  readonly getActions: (admin: MpUser) => PartnerEmployeeActions<PartnerEmployeeActionTableType>;
}

const PartnerEmployeeTableAdapter = (props: PartnerEmployeeTableAdapterProps) => {
  const { filterAdapter, getActions } = props;

  const handlers = useContextHandlers();
  const { partner, onEmployeeOpen, partnerOwner } = useContextConfig();

  const guid = useSelector(partnerEmployeesGuidSelector);
  const employees = useSelector(partnerEmployeesEmployeesSelector);
  const actionProcesses = useSelector(partnerEmployeesActionsSelector);

  const sortColumn = useSelector(partnerEmployeesColumnSelector);
  const sortDirection = useSelector(partnerEmployeesSortDirectionSelector);
  const filter = useSelector(partnerEmployeesFilterSelector);

  const onActionClick = (call: PartnerEmployeeTableActionCall) => {
    const { action, employee } = call;
    switch (action) {
      case EPartnerEmployeeActionType.Activate:
        handlers.onEmployeeActivate(employee);
        break;
      case EPartnerEmployeeActionType.Deactivate:
        if (employee.id === partnerOwner?.id) {
          handlers.onChangeDialogState('changePartnerOwner', employee);
        } else {
          handlers.onEmployeeDeactivate(employee);
        }
        break;
      case EPartnerEmployeeActionType.ChangeResponsible:
        handlers.onChangePartnerOwner(employee, partner);
        break;
    }
  };

  const getActionProcesses = (customer: MpUser): PartnerEmployeeTableActionProcess[] =>
    actionProcesses.filter(process => process.id === customer.id) ?? [];

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return (
    <PartnerEmployeeTable
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      employees={employees}
      getActions={getActions}
      onEmployeeAction={onActionClick}
      onRequestSort={handlers.onChangeSort}
      onEmployeeClick={onEmployeeOpen}
      getActionProcesses={getActionProcesses}
      onChangeMetadata={onChangeMetadata}
    />
  );
};

export default PartnerEmployeeTableAdapter;
