import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Banner } from '../../../../../domain/model/banner';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { bannerServices } from '../../services';

export const bannerByIdFetch = createAsyncThunk<Banner, UUID, AppThunkAPIConfig>(
  'banner/byId/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      return await bannerServices.common.one({ id, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface BannerByIdState extends Fetchable {
  readonly data: Nullable<Banner>;
}

type Reducer<T = undefined> = CaseReducer<BannerByIdState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<BannerByIdState> {
  bannerByIdStateReset: Reducer;
  bannerByIdStateRefresh: Reducer;
}

const slice = createSlice<BannerByIdState, Reducers, 'byId'>({
  name: 'byId',
  initialState: {
    ...fetchableDefault,
    data: null,
  },
  reducers: {
    bannerByIdStateReset: state => {
      state.isFetching = false;
      state.isFetched = false;
      state.isFailed = false;
      state.data = null;
    },
    bannerByIdStateRefresh: state => {
      if (state.data) {
        state.data = {
          ...state.data,
        };
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bannerByIdFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
        state.data = null;
      })
      .addCase(bannerByIdFetch.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.data = payload;
      })
      .addCase(bannerByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
        } else {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = false;
        }
      });
  },
});

export const { bannerByIdStateReset, bannerByIdStateRefresh } = slice.actions;

export default slice.reducer;
