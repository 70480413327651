import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import {
  EProductTableFilterItem,
  getProductTableFilterItems,
  getProductTableFilterStrategy,
  ProductTableFilterValues,
} from '../filter/filterUtils';
import { productsCategorySelector } from '../store/selectors';
import { EProductTableColumn, EProductTableTab, expandColumnsByCategory, getProductTableColumns } from '../utils';

interface UseTableProductProps {
  readonly tab: EProductTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly isPartnerUser: boolean;
}

type UseTableProductResult = {
  readonly filterAdapter: DataFilterAdapter<EProductTableColumn, EProductTableFilterItem>;
};

const useTableSettings = (props: UseTableProductProps): UseTableProductResult => {
  const { tab, partnerId, isPartnerUser } = props;

  const selectedCategory = useSelector(productsCategorySelector);

  // получаем максимально возможный состав колонок
  const sourceColumns = useMemo(() => getProductTableColumns(tab, partnerId), [tab, partnerId]);

  const keyPartitions = useMemo(() => {
    const keys = isPartnerUser ? ['partner', tab] : ['admin', partnerId ? 'byPartner' : 'common', tab];

    if (selectedCategory) {
      keys.push(selectedCategory.id);
    }

    return keys;
  }, [partnerId, isPartnerUser, tab, selectedCategory]);

  // готовим адаптер для хука
  const filterAdapter = useMemo<DataFilterAdapter<EProductTableColumn, EProductTableFilterItem>>(
    () => ({
      key: 'products',
      keyPartitions,
      sourceColumns: expandColumnsByCategory<EProductTableColumn>(sourceColumns, selectedCategory),
      filterItems: getProductTableFilterItems(selectedCategory),
      requiredFilters: [],
      getDataFilterStrategy: (filterItem: EProductTableFilterItem, values: ProductTableFilterValues) =>
        getProductTableFilterStrategy(filterItem, values, selectedCategory),
    }),
    [keyPartitions, sourceColumns, selectedCategory]
  );

  return {
    filterAdapter,
  };
};

export default useTableSettings;
