import { FormHelperText, Grid } from '@mui/material';
import { useMemo } from 'react';
import Splitter from '../../../../components/common/splitter';
import { BookingOfferEditFieldServiceProps } from '../../components/fieldsEdit/types';
import FormItemAdapter from '../adapters/formItem';
import BookingOfferCreateServicePriceItemAdapter from '../adapters/priceItem';

const fieldName = 'priceItems';

interface BookingOfferServiceManageServicesProps extends BookingOfferEditFieldServiceProps<typeof fieldName> {}

const BookingOfferServiceManageFieldServicePriceItems = (props: BookingOfferServiceManageServicesProps) => {
  const { index: serviceIndex, value, validation } = props;

  const servicesRender = useMemo(() => {
    return value.map((priceItem, index) => (
      <Grid
        key={index}
        item
      >
        <BookingOfferCreateServicePriceItemAdapter
          index={index}
          serviceIndex={serviceIndex}
        />
      </Grid>
    ));
  }, [value.length]);

  return (
    <FormItemAdapter>
      <Grid
        container
        spacing={4}
        direction='column'
      >
        {servicesRender}
        {validation?.hasError && (
          <>
            <Splitter size={1} />
            <FormHelperText error>{validation.message}</FormHelperText>
          </>
        )}
      </Grid>
    </FormItemAdapter>
  );
};

export default BookingOfferServiceManageFieldServicePriceItems;
