import { Grid } from '@mui/material';
import moment from 'moment';
import { EDateFormat } from '../../../../../../domain/model/formats';
import { Nullable } from '../../../../../../domain/model/types';
import SectionTitle from '../../../../../components/common/sectionTitle';
import { MPDatePicker } from '../../../../../theme/ui-kit/picker';
import { CmsContainerCreateComponentProps } from '../types';

type CmsContainerEditComponentPeriodProps = CmsContainerCreateComponentProps;

const CmsContainerEditComponentPeriod = (props: CmsContainerEditComponentPeriodProps) => {
  const { cmsContainer, validation, onChange } = props;

  const startDate: Nullable<string> =
    cmsContainer.startDate && moment(cmsContainer.startDate).format(EDateFormat.Server);
  const endDate: Nullable<string> = cmsContainer.endDate && moment(cmsContainer.endDate).format(EDateFormat.Server);

  return (
    <Grid
      container
      direction='column'
      spacing={1}
    >
      <Grid item>
        <SectionTitle>Срок публикации</SectionTitle>
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <MPDatePicker
              value={startDate || null}
              label='Начало'
              format={EDateFormat.Human}
              error={validation?.startDate?.hasError}
              helperText={validation?.startDate?.message}
              onChange={date => {
                const d = date === null ? null : moment(date).format(EDateFormat.Server);
                onChange('startDate', d);
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <MPDatePicker
              value={endDate || null}
              label='Окончание'
              format={EDateFormat.Human}
              error={validation?.endDate?.hasError}
              helperText={validation?.endDate?.message}
              onChange={date => {
                const d = date === null ? null : moment(date).format(EDateFormat.Server);
                onChange('endDate', d);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CmsContainerEditComponentPeriod;
