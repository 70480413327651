import styled from '@emotion/styled';
import { viewConfig } from '../../theme/viewConfig';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  background: ${p => p.theme.palette.background.default};
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;

  flex: 1;
`;

export const FooterWrapper = styled.div`
  position: fixed;
  left: ${viewConfig.stepper.width};
  bottom: 0;
  right: 0;
  z-index: 1;

  background: ${p => p.theme.palette.background.default};
`;
