import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Nullable } from '../../../../../domain/model/types';
import { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import {
  ESportsmansTableFilterItem,
  getSportsmanTableFilterStrategy,
  SportsmansTableFilterEditStrategy,
  sportsmansTableFilterItems,
  SportsmansTableFilterValues,
} from '../../filterUtils';
import { ESportsmanTableColumn, getSportsmansTableAdapterKey, getSportsmansTableColumns } from '../../table/utils';

type UseEventCollectionPlaygroundFilterAdapter = DataFilterAdapter<ESportsmanTableColumn, ESportsmansTableFilterItem>;

interface UseSportsmanSearchFilterAdapterProps {
  readonly values: SportsmansTableFilterValues;
}

const useSportsmanSearchFilterAdapter = (
  props: UseSportsmanSearchFilterAdapterProps
): UseEventCollectionPlaygroundFilterAdapter => {
  const { values } = props;
  const { userGenders, roads } = useSelector(nsiDataSelector);

  const sourceColumns = getSportsmansTableColumns();

  const filterPreset = useMemo(
    () =>
      Object.keys(values)
        .map<Nullable<SportsmansTableFilterEditStrategy>>(key => {
          const strategy = getSportsmanTableFilterStrategy(
            key as ESportsmansTableFilterItem,
            values,
            userGenders,
            roads
          );
          if (strategy) {
            return {
              ...strategy,
              readOnly: true,
            };
          } else {
            return null;
          }
        })
        .filter(strategy => !!strategy) as SportsmansTableFilterEditStrategy[],
    [values]
  );

  return useMemo<DataFilterAdapter<ESportsmanTableColumn, ESportsmansTableFilterItem>>(
    () => ({
      ...getSportsmansTableAdapterKey(),
      sourceColumns,
      filterItems: sportsmansTableFilterItems,
      filterPreset: filterPreset,
      getDataFilterStrategy: (filterItem, values) =>
        getSportsmanTableFilterStrategy(filterItem, values, userGenders, roads),
    }),
    [sourceColumns, filterPreset, userGenders]
  );
};

export default useSportsmanSearchFilterAdapter;
