import { FormHelperText, Grid, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useMemo } from 'react';
import { FileUploadObject, XLSXImportError, XLSXImportResult } from '../../../../../domain/model';
import { Nullable } from '../../../../../domain/model/types';
import FileUploader from '../../../../components/common/files/uploader';
import { fileTypes } from '../../../../constants';
import { ContentTypeDocumentIcon } from '../../../../icons';
import { pluralize } from '../../../../utils/pluralize';
import { ValidationResult } from '../../../../utils/validation';
import OfferFileInfo from '../../../general/offer/components/fileInfo';
import { ErrorWrapper, GridItemContent, Link, SuccessWrapper } from './controls';

interface ErrorSectionProps {
  readonly errorRowsCount: number;
  readonly errors: XLSXImportError[];
  readonly commonErrorText: Nullable<string>;
  readonly onDownloadImportError: (errors: XLSXImportError[]) => void;
}

const ErrorSection = (props: ErrorSectionProps) => {
  const { errorRowsCount, errors, commonErrorText, onDownloadImportError } = props;

  const errorText =
    errorRowsCount > 0
      ? `${errorRowsCount} ${pluralize(errorRowsCount, ['строка', 'строки', 'строк'])} с ошибками`
      : commonErrorText;

  return (
    <ErrorWrapper>
      <Grid
        container
        spacing={1}
        alignItems='center'
      >
        <Grid item>
          <IconButton
            disabled
            size='small'
          >
            <ClearIcon
              color='error'
              fontSize='small'
            />
          </IconButton>
        </Grid>
        <GridItemContent item>
          <Typography>{errorText}</Typography>
        </GridItemContent>
        {errors.length > 0 && (
          <Grid item>
            <Link onClick={() => onDownloadImportError(errors)}>Скачать лог</Link>
          </Grid>
        )}
      </Grid>
    </ErrorWrapper>
  );
};

interface SuccessSectionProps {
  readonly text: string;
}

const SuccessSection = (props: SuccessSectionProps) => {
  const { text } = props;

  return (
    <SuccessWrapper>
      <Grid
        container
        spacing={1}
        alignItems='center'
      >
        <Grid item>
          <IconButton
            disabled
            size='small'
          >
            <CheckIcon
              color='action'
              fontSize='small'
            />
          </IconButton>
        </Grid>
        <GridItemContent item>
          <Typography>{text}</Typography>
        </GridItemContent>
      </Grid>
    </SuccessWrapper>
  );
};

interface ProductUploadItemsProps {
  readonly uploadFile: Nullable<{ file: File; error: Nullable<string> }>;
  readonly isSaved: boolean;
  readonly isFailed: boolean;
  readonly importResult: Nullable<XLSXImportResult>;
  readonly validation: Nullable<ValidationResult<FileUploadObject>>;
  readonly isValid: boolean;
  readonly getSuccessImportedCountText: (count: number) => string;
  readonly onSetFile: (file: File, error?: string) => void;
  readonly onRemoveFile: () => void;
  readonly onDownloadImportError: (errors: XLSXImportError[]) => void;
}

const ProductUploadItems = (props: ProductUploadItemsProps) => {
  const {
    uploadFile,
    importResult,
    isSaved,
    isFailed,
    validation,
    getSuccessImportedCountText,
    onSetFile,
    onRemoveFile,
    onDownloadImportError,
  } = props;

  const errors = importResult?.errors ?? [];

  const isError: boolean = !!uploadFile?.error || isFailed || errors?.length > 0;

  const errorCount: number = importResult?.errorCount ?? 0;

  const successCount: number = importResult?.successCount ?? 0;

  const successImportedCountText = useMemo<string>(() => getSuccessImportedCountText(successCount), [successCount]);

  /*  const errorText: Nullable<string> =
      isFailed || (uploadResult && errorCount === 0 && errors.length > 0)
        ? 'Ошибка при загрузке файла'
        : uploadFile?.error ?? null;*/

  const errorText: Nullable<string> =
    isFailed || (importResult && errorCount === 0 && errors.length > 0) ? 'Ошибка при загрузке файла' : null;

  const fileInfo = uploadFile?.file
    ? {
        name: uploadFile.file.name,
        size: uploadFile.file.size,
        type: uploadFile.file.type,
      }
    : null;

  const onError = (file: File, msg: string) => {
    onSetFile(file, msg);
  };

  const onSelect = (files: File[]) => {
    const [file] = files;
    if (file) {
      onSetFile(file);
    }
  };

  return (
    <Grid
      container
      spacing={0}
      direction='column'
    >
      {fileInfo && (
        <Grid item>
          <OfferFileInfo
            icon={<ContentTypeDocumentIcon />}
            error={isError}
            success={isSaved && !isError}
            fileInfo={fileInfo}
            onRemove={onRemoveFile}
          />
        </Grid>
      )}
      {uploadFile?.error && <FormHelperText error>{uploadFile.error}</FormHelperText>}
      {(errorText || errorCount > 0) && (
        <Grid item>
          <ErrorSection
            errorRowsCount={errorCount}
            errors={errors}
            commonErrorText={errorText}
            onDownloadImportError={onDownloadImportError}
          />
        </Grid>
      )}
      {successCount > 0 && (
        <Grid item>
          <SuccessSection text={successImportedCountText} />
        </Grid>
      )}
      {!uploadFile && (
        <Grid item>
          <FileUploader
            text='Загрузить файл'
            fileTypes={[fileTypes.xls.mimeType, fileTypes.xlsx.mimeType]}
            accept={[fileTypes.xls.ext, fileTypes.xlsx.ext]}
            error={validation?.file?.hasError}
            fileTypeErrorText='Неверный формат файла'
            helperText={validation?.file?.message}
            onSelect={onSelect}
            onError={onError}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ProductUploadItems;
