import { useEffect, useState } from 'react';
import { EPanelActionPosition, PanelActions } from '../../../types';
import { EPartnerWindowActionType } from '../types';

type UsePartnerWindowActions = PanelActions<EPartnerWindowActionType>;

interface UsePartnerWindowActionsProps {
  readonly canCreate?: boolean;
  readonly hasData: boolean;
}

const usePartnerWindowActions = (props: UsePartnerWindowActionsProps): UsePartnerWindowActions => {
  const { canCreate, hasData } = props;

  const [actions, setActions] = useState<PanelActions<EPartnerWindowActionType>>([]);

  useEffect(() => {
    const temp: typeof actions = [];
    if (canCreate) {
      temp.push({
        type: EPartnerWindowActionType.CreateCmsContainer,
        label: 'Добавить блок',
        primary: true,
        position: [EPanelActionPosition.Default],
      });
    }
    if (hasData) {
      temp.push({
        type: EPartnerWindowActionType.OpenPreview,
        label: 'Предпросмотр',
        position: [EPanelActionPosition.Default],
      });
    }
    setActions(temp);
  }, [canCreate, hasData]);

  return actions;
};

export default usePartnerWindowActions;
