import { FC, useMemo } from 'react';
import { PartnersCollection } from '../../../../../../domain/model/partner';
import MPThemeProvider from '../../../../../theme/common/provider';
import privilegeTheme from '../../../../../theme/privilege';
import { EEntityPreviewMode } from '../../../../../types';
import CmsPartnersPreviewBlockPartner from './blockPartner';
import CmsPartnersPreviewBlockTemplate from './blockTemplate';
import { Wrapper } from './controls';

interface CmsPartnersPreviewProps {
  readonly mode?: EEntityPreviewMode;
  readonly isEmptyTemplated: boolean;
  readonly partners: PartnersCollection;
}

const CmsPartnersPreview: FC<CmsPartnersPreviewProps> = props => {
  const { partners, mode, isEmptyTemplated } = props;

  const rowLength = mode === EEntityPreviewMode.Desktop ? 5 : 2;

  const emptyTemplates = useMemo(() => Array.from({ length: rowLength }, () => null), [rowLength]);

  return (
    <MPThemeProvider theme={privilegeTheme}>
      <Wrapper maxRowElementsCount={rowLength}>
        {partners.map(partner => (
          <CmsPartnersPreviewBlockPartner
            key={partner.id}
            partner={partner}
            mode={mode}
          />
        ))}
        {!partners.length &&
          isEmptyTemplated &&
          emptyTemplates.map((ei, i) => <CmsPartnersPreviewBlockTemplate key={i} />)}
      </Wrapper>
    </MPThemeProvider>
  );
};

export default CmsPartnersPreview;
