import { RootState } from '@/data/store/store';
import { UserCurrentActionsState } from '@features/user/current/actions/store/slice';
import { EUserActionsType } from '@features/user/utils';
import { createSelector } from '@reduxjs/toolkit';

export const isUserCurrentEmailChangedSelector = (store: RootState) =>
  store.user.current.actions.actions.some(action => action.type === EUserActionsType.ChangeEmail && action.isFetched);

export const userCurrentActionsDialogsByNameSelector = (name: keyof UserCurrentActionsState['dialogs']) => {
  return createSelector(
    (store: RootState) => store.user.current.actions.dialogs,
    dialogs => dialogs[name]
  );
};

export const isUserCurrentAnyChangedSelector = () =>
  createSelector(
    (store: RootState) => store.user.current.actions.actions.filter(action => !action.isFetching).length,
    actions => {
      return actions;
    }
  );
