import { Fade, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useAppDispatch } from '../../../../../data/store/store';
import { Nullable, UUID } from '../../../../../domain/model/types';
import MasterActionsComponent from '../../../../components/common/actions/master';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';
import ContentLoader from '../../../../components/common/loader';
import Splitter from '../../../../components/common/splitter';
import { DefaultContentWrapper } from '../../../../components/common/wrappers/content';
import { DefaultFooterWrapper } from '../../../../components/common/wrappers/footer';
import { EPanelActionPosition, PanelAction, PanelActions } from '../../../../types';
import { getCurrentUserEditRoute } from '../../entry';
import { CurrentUserDetailsActionType, ECurrentUserActionType } from '../types';
import { getCurrentUserActionName } from '../utils';
import UserCurrentDetailsSportComponent from './component';
import { ContainerWrapper, HeaderWrapper, LoaderWrapper } from './controls';
import UserCurrentDetailsSportHeader from './header';
import {
  userCurrentDetailsSportByIdSelector,
  userCurrentDetailsSportEmailChangedSelector,
  userCurrentDetailsSportNeedRefreshWatcherSelector,
} from './store/selectors';
import {
  userCurrentDetailsSportByIdFetch,
  userCurrentDetailsSportEmailChangedReset,
  userCurrentDetailsSportStateReset,
} from './store/slice';

interface UserCurrentDetailsSportContainerProps {
  readonly id: UUID;
  readonly resetPassword: Nullable<() => void>;
  readonly logOut: () => void;
}

const UserCurrentDetailsSportContainer = (props: UserCurrentDetailsSportContainerProps) => {
  const { id, resetPassword, logOut } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();

  const { user, isFetching } = useSelector(userCurrentDetailsSportByIdSelector);
  const needRefreshWatcher = useSelector(userCurrentDetailsSportNeedRefreshWatcherSelector);
  const emailChanged = useSelector(userCurrentDetailsSportEmailChangedSelector);

  const onEdit = () => {
    history.push(getCurrentUserEditRoute());
  };

  const resetEmailChanged = () => {
    dispatch(userCurrentDetailsSportEmailChangedReset());
  };

  const onPanelAction = (action: PanelAction<CurrentUserDetailsActionType>) => {
    const { type } = action;
    switch (type) {
      case ECurrentUserActionType.Edit:
        onEdit();
        break;
      case ECurrentUserActionType.Logout:
        logOut();
        break;
    }
  };

  useEffect(() => {
    const promise = dispatch(userCurrentDetailsSportByIdFetch({ id }));
    return () => {
      dispatch(userCurrentDetailsSportStateReset());
      promise?.abort();
    };
  }, [dispatch, id, needRefreshWatcher]);

  const actions: PanelActions<CurrentUserDetailsActionType> = [];
  actions.push({
    type: ECurrentUserActionType.Logout,
    label: getCurrentUserActionName(ECurrentUserActionType.Logout),
    primary: true,
    position: [EPanelActionPosition.Default],
  });
  actions.push({
    type: ECurrentUserActionType.Edit,
    label: getCurrentUserActionName(ECurrentUserActionType.Edit),
    position: [EPanelActionPosition.Menu],
  });

  const actionsPanel = (
    <MasterActionsComponent<CurrentUserDetailsActionType>
      show={!!actions.length}
      actions={actions}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );

  return (
    <>
      <Fade in>
        <DefaultContentWrapper
          type='details'
          stickyHeader
          fullHeight
          footer={actionsPanel}
        >
          <ContainerWrapper>
            {user && (
              <HeaderWrapper>
                <UserCurrentDetailsSportHeader user={user} />
              </HeaderWrapper>
            )}

            <Splitter
              variant='horizontal'
              size={2}
            />

            {user && (
              <>
                <UserCurrentDetailsSportComponent
                  user={user}
                  onChangePassword={resetPassword}
                />
              </>
            )}

            {isFetching && (
              <LoaderWrapper>
                <ContentLoader
                  size={75}
                  alpha
                />
              </LoaderWrapper>
            )}
          </ContainerWrapper>
        </DefaultContentWrapper>
      </Fade>

      <ConfirmDialog
        open={!!emailChanged}
        onClose={resetEmailChanged}
        title='Подтвердите электронную почту'
      >
        <Grid
          container
          spacing={2}
          wrap='nowrap'
          direction='column'
        >
          <Grid item>
            <Typography>
              На почту <b>{emailChanged}</b> отправлено письмо. Нажмите на кнопку подтверждения в письме
            </Typography>
          </Grid>
        </Grid>
      </ConfirmDialog>
    </>
  );
};

export default UserCurrentDetailsSportContainer;
