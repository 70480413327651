import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { viewShowMainMenu } from '../../features/main/container/store/slice';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { v4 as uuidv4 } from 'uuid';
import { SportsmansLocationState } from '../../features/sportsman/entry';
import SportsmanTableEntry from '../../features/sportsman/table/entry';

const SportsmansScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  return (
    <LocationWithRequiredStateContainer<SportsmansLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => <SportsmanTableEntry guid={state.guid} />}
    </LocationWithRequiredStateContainer>
  );
};

export default SportsmansScreen;
