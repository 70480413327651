import styled from '@emotion/styled';
import { viewConfig } from '@theme/viewConfig';

export const TitleWrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  padding-bottom: ${p => p.theme.spacing(1.5)};

  border-bottom: 1px solid ${p => p.theme.palette.disabled.background};
`;

export const ContentWrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  padding-top: ${p => p.theme.spacing(4)};
`;

export const FaviconWrapper = styled.div`
  max-width: 32px;
  max-height: 32px;

  display: flex;
  margin-right: ${p => p.theme.spacing()};

  > * {
    max-width: 100%;
    max-height: 100%;
  }
`;
