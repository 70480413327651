import { FCC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { tradeOfferCategoriesEditFetch, tradeOfferCategoriesEditResetState } from './store/slice';
import { TradeOfferCategoriesEditCommonProps } from './types';

type TradeOfferCategoriesEditInitializerProps = TradeOfferCategoriesEditCommonProps;

export const TradeOfferCategoriesEditInitializer: FCC<TradeOfferCategoriesEditInitializerProps> = ({ children }) => {
  const dispatch = useDispatch();

  //загрузка основных данных
  useEffect(() => {
    const requests = [dispatch(tradeOfferCategoriesEditFetch())];
    return () => {
      requests.forEach(request => request.abort());
    };
  }, [dispatch]);

  //очистка данных
  useEffect(() => {
    return () => {
      dispatch(tradeOfferCategoriesEditResetState());
    };
  }, [dispatch]);

  return <>{children}</>;
};
