import { Checkbox } from '@mui/material';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import React from 'react';
import { OrgUnitOption, TextOption } from './controls';
import {
  OrgStructureOrgUnitMultipleAllOrgUnitsState,
  OrgStructureOrgUnitMultipleItemType,
  OrgStructureOrgUnitMultipleTypesSelectorItem,
} from './types';

interface OrgStructureOrgUnitMultipleItemProps {
  readonly elementProps: React.HTMLAttributes<HTMLLIElement>;
  readonly option: OrgStructureOrgUnitMultipleTypesSelectorItem;
  readonly state: AutocompleteRenderOptionState;
}

const OrgStructureOrgUnitMultipleItem = ({ elementProps, option, state }: OrgStructureOrgUnitMultipleItemProps) => {
  switch (option.type) {
    case OrgStructureOrgUnitMultipleItemType.Text:
      return (
        <TextOption
          color='textSecondary'
          variant='body2'
        >
          {option.name}
        </TextOption>
      );
    case OrgStructureOrgUnitMultipleItemType.All:
      return (
        <OrgUnitOption {...elementProps}>
          <Checkbox
            color='primary'
            checked={state.selected}
            indeterminate={option.state === OrgStructureOrgUnitMultipleAllOrgUnitsState.Indeterminate}
          />
          {option.name}
        </OrgUnitOption>
      );
    case OrgStructureOrgUnitMultipleItemType.None:
      return (
        <OrgUnitOption {...elementProps}>
          <Checkbox
            color='primary'
            checked={state.selected}
          />
          {option.name}
        </OrgUnitOption>
      );
    case OrgStructureOrgUnitMultipleItemType.OrgUnit:
      return (
        <OrgUnitOption {...elementProps}>
          <Checkbox
            color='primary'
            checked={state.selected}
          />
          {option.name}
        </OrgUnitOption>
      );
  }
};

export default OrgStructureOrgUnitMultipleItem;

export const getOrgStructureOrgUnitMultipleItemAllOrgUnits = (
  state: OrgStructureOrgUnitMultipleAllOrgUnitsState
): OrgStructureOrgUnitMultipleTypesSelectorItem => ({
  type: OrgStructureOrgUnitMultipleItemType.All,
  id: OrgStructureOrgUnitMultipleItemType.All,
  name: 'Все филиалы',
  state,
});

export const getOrgStructureOrgUnitMultipleItemNoneOrgUnits = (
  state: OrgStructureOrgUnitMultipleAllOrgUnitsState
): OrgStructureOrgUnitMultipleTypesSelectorItem => ({
  type: OrgStructureOrgUnitMultipleItemType.None,
  id: OrgStructureOrgUnitMultipleItemType.None,
  name: 'не выбрано',
  state,
});

export const getOrgStructureOrgUnitMultipleItemDefaultSourceHeader =
  (): OrgStructureOrgUnitMultipleTypesSelectorItem => ({
    type: OrgStructureOrgUnitMultipleItemType.Text,
    id: 'default_source_header',
    name: 'Самые часто используемые филиалы',
  });

export const getOrgStructureOrgUnitMultipleItemDefaultSourceFooter =
  (): OrgStructureOrgUnitMultipleTypesSelectorItem => ({
    type: OrgStructureOrgUnitMultipleItemType.Text,
    id: 'default_source_footer',
    name: 'Для выбора конкретного филиала начните вводить его название в поле поиска',
  });
