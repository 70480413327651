import { DataTableMetadata } from '@components/common/table';
import useDataTableSettings from '@/presentation/hooks/useDataTableSettings';
import { EOfferFieldHistoryTableColumn } from '@features/offerHistory/table/utils/common';
import { useMemo } from 'react';
import { getOfferFieldHistoryColumns } from '../utils/fieldHistoryFilter';

type ColumnsType = EOfferFieldHistoryTableColumn;

type UseOfferHistoryTableResult = {
  readonly metadata: DataTableMetadata<ColumnsType>;
  readonly onChangeMetadata: (metadata: DataTableMetadata<ColumnsType>) => void;
};

export const useOfferFieldHistoryTable = (): UseOfferHistoryTableResult => {
  const sourceColumns = useMemo(() => getOfferFieldHistoryColumns(), []);

  const { metadata, onChangeMetadata } = useDataTableSettings<ColumnsType>({
    name: 'offer-field-history',
    sourceColumns,
  });

  return {
    metadata,
    onChangeMetadata,
  };
};
