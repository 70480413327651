import { Fade, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../data/store/store';
import { EUserStatus } from '../../../../domain/model/enums';
import { UUID } from '../../../../domain/model/types';
import MasterActionsComponent from '../../../components/common/actions/master';
import AppBreadcrumbs from '../../../components/common/breadcrumbs';
import DefaultHeader from '../../../components/common/header';
import ContentLoader from '../../../components/common/loader';
import Splitter from '../../../components/common/splitter';
import { DefaultContentWrapper } from '../../../components/common/wrappers/content';
import { DefaultFooterWrapper } from '../../../components/common/wrappers/footer';
import { ETagColors, MPTag } from '../../../theme/ui-kit/tag';
import { EPanelActionPosition, PanelAction, PanelActions } from '../../../types';
import useBreadcrumbs from '../../general/breadcrumbs/useBreadcrumbs';
import { CustomerActionDetailsType, ECustomerActionType } from '../types';
import { getCustomerActionName } from '../utils';
import CustomerDetailsComponent from './component';
import { ContainerWrapper, LoaderWrapper, TitleWrapper } from './controls';
import {
  customerDetailsByIdSelector,
  customerDetailsChangeStatusSelector,
  customerDetailsNeedRefreshWatcherSelector,
} from './store/selectors';
import { customerDetailsByIdFetch, customerDetailsChangeUserStatus, customerDetailsStateReset } from './store/slice';

interface CustomerDetailsContainerProps {
  readonly id: UUID;
  readonly canChangeStatus?: boolean;
}

const CustomerDetailsContainer = ({ id, canChangeStatus }: CustomerDetailsContainerProps) => {
  const dispatch = useAppDispatch();

  const { user, isFetching } = useSelector(customerDetailsByIdSelector);
  const needRefreshWatcher = useSelector(customerDetailsNeedRefreshWatcherSelector);
  const { isFetching: changeStatusIsFetching } = useSelector(customerDetailsChangeStatusSelector);
  const breadcrumbs = useBreadcrumbs();

  const onChangeStatus = (status: EUserStatus) => {
    dispatch(customerDetailsChangeUserStatus({ id, status }));
  };

  const onPanelAction = (action: PanelAction<CustomerActionDetailsType>) => {
    const { type } = action;
    switch (type) {
      case ECustomerActionType.Activate:
        onChangeStatus(EUserStatus.Enabled);
        break;
      case ECustomerActionType.Deactivate:
        onChangeStatus(EUserStatus.Disabled);
        break;
    }
  };

  useEffect(() => {
    const promise = dispatch(customerDetailsByIdFetch(id));
    return () => {
      dispatch(customerDetailsStateReset());
      promise?.abort();
    };
  }, [dispatch, id, needRefreshWatcher]);

  const actions: PanelActions<CustomerActionDetailsType> = [];
  if (canChangeStatus && user?.status === EUserStatus.Enabled) {
    actions.push({
      type: ECustomerActionType.Deactivate,
      label: getCustomerActionName(ECustomerActionType.Deactivate),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (canChangeStatus && user?.status === EUserStatus.Disabled) {
    actions.push({
      type: ECustomerActionType.Activate,
      label: getCustomerActionName(ECustomerActionType.Activate),
      position: [EPanelActionPosition.Menu],
    });
  }
  const actionsPanel = (
    <MasterActionsComponent<CustomerActionDetailsType>
      show={!!actions.length}
      actions={actions}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );

  return (
    <Fade in>
      <DefaultContentWrapper
        type='details'
        stickyHeader
        fullHeight
        footer={actionsPanel}
      >
        <ContainerWrapper>
          {user && (
            <>
              <DefaultHeader
                sticky
                headline={
                  <>
                    {breadcrumbs.length > 0 && <AppBreadcrumbs />}
                    {user.status === EUserStatus.Disabled && (
                      <MPTag
                        bold
                        label={'Отключён'}
                        color={ETagColors.Warning}
                      />
                    )}
                  </>
                }
              >
                <TitleWrapper>
                  <Typography variant='h2'>Профиль покупателя</Typography>
                </TitleWrapper>
              </DefaultHeader>
              <Splitter
                variant='horizontal'
                size={2}
              />
              <CustomerDetailsComponent user={user} />
            </>
          )}

          {(isFetching || changeStatusIsFetching) && (
            <LoaderWrapper>
              <ContentLoader
                size={75}
                alpha
              />
            </LoaderWrapper>
          )}
        </ContainerWrapper>
      </DefaultContentWrapper>
    </Fade>
  );
};

export default CustomerDetailsContainer;
