// export * from './name';
// export * from './description';

import { MPReport } from '@/domain';
import { ReactNode, useMemo } from 'react';
import DataTableCellTextFormat from '@components/common/table/cell/textFormat';

export interface ReportsTableCellCommonProps {
  readonly report: MPReport;
  readonly isDisabled?: boolean;
  readonly isTextAccent?: boolean;
}

interface ReportsTableCellProps extends ReportsTableCellCommonProps {
  readonly value: ReactNode;
}

export const ReportsTableCell = ({ value, isDisabled, isTextAccent }: ReportsTableCellProps) => {
  return useMemo(
    () => (
      <DataTableCellTextFormat
        disabled={isDisabled}
        bold={isTextAccent}
      >
        {value}
      </DataTableCellTextFormat>
    ),
    [isDisabled, isTextAccent, value]
  );
};
