import CmsContainerTableCell, { CmsContainerTableCellCommonProps } from './index';

type CmsContainerTableCellStatusCommentProps = CmsContainerTableCellCommonProps;

const CmsContainerTableCellStatusComment = (props: CmsContainerTableCellStatusCommentProps) => {
  const {
    cmsContainer: { statusComment },
  } = props;

  const value = statusComment ?? '-';

  return (
    <CmsContainerTableCell
      {...props}
      value={value}
    />
  );
};

export default CmsContainerTableCellStatusComment;
