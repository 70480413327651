import TabsPanelCounter from '../../../../components/common/tabs/counter/counter';
import TabsPanelLabel from '../../../../components/common/tabs/label/label';
import { AdminMpTableTabsCounter, EAdminMpTableTab, getAdminMpTableTabName } from './utils';

interface AdminMpTableTabProps {
  readonly tab: EAdminMpTableTab;
  readonly counts: AdminMpTableTabsCounter;
}

const AdminMpTableTab = ({ tab, counts }: AdminMpTableTabProps) => {
  const count = counts[tab];

  return (
    <TabsPanelLabel title={getAdminMpTableTabName(tab)}>
      <TabsPanelCounter count={count} />
    </TabsPanelLabel>
  );
};

export default AdminMpTableTab;
