import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ClientOrg, UserAccessMatrix } from '@/domain';
import { commonMenu } from '../../../../menu';
import { MenuPanelItem, MenuPanelLinkLocationState } from '../../../../types';
import { getMenuItemLocation, menuPanelItemIsSelected } from '../../../general/menu/utils';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { getClientOrgManagementDetailsRoute, getClientOrgManagementUsersRoute } from '../entry';
import { clientOrgManagementMenuOpenedItemsSelector } from './store/selectors';
import { clientOrgManagementMenuAddToOpened } from './store/slice';

const getMenu = (clientOrg: ClientOrg, accessMatrix: UserAccessMatrix): MenuPanelItem[] => {
  const items: MenuPanelItem[] = [];
  const { clientOrgUsers, clientOrgs } = accessMatrix;

  if (clientOrgUsers?.view) {
    items.push(commonMenu.clientOrgUsers(getClientOrgManagementUsersRoute({ id: clientOrg.id })));
  }

  if (clientOrgs?.view) {
    items.push(commonMenu.clientOrgDetails(getClientOrgManagementDetailsRoute({ id: clientOrg.id })));
  }

  return items;
};

interface UseClientOrgManagementMenuItemsProps {
  readonly clientOrg: ClientOrg;
}

interface UseClientOrgManagementMenuItems {
  readonly items: MenuPanelItem[];
  readonly isSelectedItem: (item: MenuPanelItem) => boolean;
  readonly isOpenedSubMenu: (item: MenuPanelItem) => boolean;
  readonly onItemClick: (item: MenuPanelItem) => void;
}

const useClientOrgManagementMenuItems = ({
  clientOrg,
}: UseClientOrgManagementMenuItemsProps): UseClientOrgManagementMenuItems => {
  const location = useLocation();
  const history = useHistory<MenuPanelLinkLocationState>();
  const dispatch = useDispatch();

  const { accessMatrix } = useCurrentUser();

  const opened = useSelector(clientOrgManagementMenuOpenedItemsSelector);

  const isSelectedItem = useCallback((item: MenuPanelItem) => menuPanelItemIsSelected(item, location), [location]);

  const isOpenedSubMenu = useCallback(
    (item: MenuPanelItem): boolean => {
      return opened[item.id] || (item.subMenu?.some(subItem => isSelectedItem(subItem)) ?? false);
    },
    [isSelectedItem, opened]
  );

  const onItemClick = useCallback(
    (item: MenuPanelItem) => {
      if (item.subMenu) {
        dispatch(clientOrgManagementMenuAddToOpened({ itemId: item.id }));
      } else {
        history.replace(getMenuItemLocation(item));
      }
    },
    [dispatch, history]
  );

  const items = useMemo<MenuPanelItem[]>(() => getMenu(clientOrg, accessMatrix), [clientOrg, accessMatrix]);

  return { items, isSelectedItem, isOpenedSubMenu, onItemClick };
};

export default useClientOrgManagementMenuItems;
