import { FileDescription } from '../../../../../domain/model';
import { Nullable } from '../../../../../domain/model/types';
import { Image, ImageSkeleton, ImageWrapper } from './controls';

interface BannerPreviewProps {
  readonly src: Nullable<string | FileDescription>;
}

const BannerPreview = ({ src }: BannerPreviewProps) => {
  return <ImageWrapper>{src ? <Image src={src} /> : <ImageSkeleton animation={false} />}</ImageWrapper>;
};

export default BannerPreview;
