import { Location } from 'history';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Nullable, UUID } from '../../../../domain/model/types';
import NotFoundScreen from '../../../screens/notFound';
import AdminMpCreateScreen from '../../../screens/userCreate/adminMp';
import AdminMpDetailsScreen from '../../../screens/userDetails/adminMp';
import AdminsMpScreen from '../../../screens/users/adminsMp';
import { EAdminMpTableTab } from './table/utils';
import { EAdminMpStep } from './types';

const routing = {
  root: '/users/admins',
  list: '/users/admins',
  create: '/users/admins/create',
  details: '/users/admins/:id',
};

export enum EAdminMpUrlParam {
  Tab = 'tab',
  Step = 'step',
}

export interface AdminMpsLocationState {
  readonly guid: UUID;
}

interface GetAdminMpTableRouteProps {
  readonly tab?: Nullable<EAdminMpTableTab>;
  readonly guid?: UUID;
}

interface GetAdminMpCreateRouteProps {
  readonly step?: EAdminMpStep;
}

interface GetAdminMpDetailsRouteProps {
  readonly id: UUID;
  readonly step?: EAdminMpStep;
}

export const getAdminMpRootRouteDefinition = () => routing.root;

export const getAdminMpCreateRoute = ({ step = EAdminMpStep.Profile }: GetAdminMpCreateRouteProps) => {
  const searchParams = new URLSearchParams();

  searchParams.append(EAdminMpUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.create}${search}`;
};

export const getAdminMpDetailsRoute = ({ id, step = EAdminMpStep.Profile }: GetAdminMpDetailsRouteProps) => {
  const searchParams = new URLSearchParams();

  searchParams.append(EAdminMpUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.details.replace(':id', id)}${search}`;
};

export const getAdminMpTableRoute = ({ tab, guid }: GetAdminMpTableRouteProps): Location<AdminMpsLocationState> => {
  const searchParams = new URLSearchParams();
  if (tab) searchParams.append(EAdminMpUrlParam.Tab, tab);

  const newGuid = guid ?? uuidv4();

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: routing.list,
    search,
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

const AdminMpEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.list}
        component={AdminsMpScreen}
      />
      <Route
        exact
        path={routing.create}
        component={AdminMpCreateScreen}
      />
      <Route
        exact
        path={routing.details}
        component={AdminMpDetailsScreen}
      />
      <Redirect
        exact
        from={routing.root}
        to={routing.list}
      />

      <Route component={NotFoundScreen} />
    </Switch>
  );
};

export default AdminMpEntry;
