import { Chip, ListItemButton as MuiListItemButton, Typography as MuiTypography } from '@mui/material';
import styled from '@emotion/styled';
import { userHeaderSize } from '../../utils';

export const Wrapper = styled.div`
  min-height: calc(100vh - ${userHeaderSize});

  display: flex;
  flex-direction: column;
`;

export const ListItem = styled(MuiListItemButton)`
  &.MuiListItem-root {
    padding: ${p => p.theme.spacing(0.9)} 0 0 ${p => p.theme.spacing()};
  }

  .MuiGrid-root {
    padding-bottom: ${p => p.theme.spacing(0.75)};
  }

  & .MuiListItemAvatar-root {
    min-width: 2.2rem;
    margin-right: ${p => p.theme.spacing(3)};
  }
`;

export const Typography = styled(MuiTypography)``;

export const OperationName = styled(MuiTypography)`
  font-weight: ${p => p.theme.typography.fontWeightSemiBold};
`;

export const BonusCount = styled(Chip)`
  margin-top: ${p => p.theme.spacing(1.2)};
  border-radius: ${p => p.theme.shape.borderRadius}px;
  background: ${p => p.theme.palette.avatar.background};
`;

export const AvatarWrapper = styled.div`
  width: 2.625rem;
  height: 2.625rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background: ${p => p.theme.palette.avatar.background};
`;
