export const createHTMLImageElementByFile = (file: File): Promise<HTMLImageElement> => {
  const url = URL.createObjectURL(file);
  return createHTMLImageElementByUrl(url);
};

export const createHTMLImageElementByUrl = (url: string): Promise<HTMLImageElement> => {
  const image = new Image();

  image.src = url;

  return new Promise(resolve => {
    image.onload = () => {
      URL.revokeObjectURL(image.src);
      resolve(image);
    };
  });
};

/* подгонка размеров изображения под нужный размер по принципу cover */
export const imageSizeCoverTo = (
  imageWidth: number,
  imageHeight: number,
  toWidth: number,
  toHeight: number
): { newWidth: number; newHeight: number } => {
  const imageRatio = imageWidth / imageHeight;
  const toRatio = toWidth / toHeight;

  let scale;

  if (imageRatio < toRatio) {
    scale = imageWidth / toWidth;
  } else {
    scale = imageHeight / toHeight;
  }

  return {
    newWidth: imageWidth / scale,
    newHeight: imageHeight / scale,
  };
};

/* подгонка изображения под нужный размер по принципу cover */
export const imageCoverAuto = (image: HTMLImageElement, width: number, height: number): string => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (ctx) {
    const { newWidth, newHeight } = imageSizeCoverTo(image.width, image.height, width, height);
    ctx.canvas.width = newWidth;
    ctx.canvas.height = newHeight;
    ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, newWidth, newHeight);
    ctx.imageSmoothingQuality = 'medium';

    ctx.restore();
  } else {
    console.warn('Canvas context is undefined');
    return image.src;
  }
  const url = canvas.toDataURL();
  canvas.remove();
  return url;
};
