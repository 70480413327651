import TabsPanelCounter from '../../../../../components/common/tabs/counter/counter';
import TabsPanelLabel from '../../../../../components/common/tabs/label/label';
import { TabsCounter } from '../../../../../components/common/tabs/types';
import { EClientOrgUserTableTab } from '../../types';

interface GetCountsLabel {
  readonly count?: number;
}

const getCountsLabel = ({ count }: GetCountsLabel) => {
  return count !== undefined ? count.toString(10) : '';
};

interface ClientOrgUserTableTabProps<T extends EClientOrgUserTableTab> {
  readonly tab: T;
  readonly counts: TabsCounter<T>;
  readonly getTabName: (tab: T) => string;
}

export const ClientOrgUserTableTab = <T extends EClientOrgUserTableTab>({
  tab,
  counts,
  getTabName,
}: ClientOrgUserTableTabProps<T>) => {
  const count = counts[tab];

  return (
    <TabsPanelLabel title={getTabName(tab)}>
      <TabsPanelCounter count={getCountsLabel({ count })} />
    </TabsPanelLabel>
  );
};
