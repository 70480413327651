import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nullable, UUID } from '@/domain/model/types';
import { NumberRange } from '../../../../types';
import { EOfferTableTab } from '../../../general/offer/types';
import { bookingOfferSearchGet, bookingOfferSearchGuidSelector } from '../store/selectors';
import { bookingOfferSearchSetTab, bookingOfferSearchStartSession } from '../store/slice';
import { BookingOfferShort } from '@/domain/model/booking';

interface UseBookingOfferSearchSessionSingleProps {
  readonly initialSelected: Nullable<BookingOfferShort>;
  readonly selectedMaxCount: 1;
}

interface UseBookingOfferSearchSessionMultipleProps {
  readonly initialSelected: Nullable<BookingOfferShort[]>;
  readonly selectedMaxCount?: NumberRange<2, 100> | false;
}

type UseBookingOfferSearchSessionProps = {
  readonly guid: UUID;
  readonly tab: EOfferTableTab;
} & (UseBookingOfferSearchSessionSingleProps | UseBookingOfferSearchSessionMultipleProps);

const useBookingOfferSearchSession = (props: UseBookingOfferSearchSessionProps): void => {
  const { guid, tab } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(bookingOfferSearchGuidSelector);
  const { tab: tableTab } = useSelector(bookingOfferSearchGet);

  // инициализация сеанса
  useEffect(() => {
    const selected: BookingOfferShort[] = [];
    if (props.initialSelected) {
      switch (props.selectedMaxCount) {
        case 1:
          selected.push(props.initialSelected);
          break;
        default:
          selected.push(...props.initialSelected);
          break;
      }
    }
    if (guid !== currentGuid) {
      dispatch(bookingOfferSearchStartSession({ guid, selected }));
    }
  }, [dispatch, guid, currentGuid, props.initialSelected, props.selectedMaxCount]);

  // изменение вкладки
  useEffect(() => {
    if (tab && tab !== tableTab) {
      dispatch(bookingOfferSearchSetTab({ tab }));
    }
  }, [dispatch, tab, tableTab]);
};

export default useBookingOfferSearchSession;
