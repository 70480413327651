import { Nullable, UUID } from '@/domain/model/types';
import { DataFilterAdapter } from '@/presentation/hooks/useDataFilterAdapter';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import { ETradeActivationTableColumn } from '@features/tradeActivation/types';
import {
  ETradeActivationsFilterItem,
  getTradeActivationFilterStrategy,
  tradeActivationsFilterItems,
} from '@features/tradeActivation/utils/filter';
import { getTradeActivationTableVisibleColumns } from '@features/tradeActivation/utils/table';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import TradeActivationTableContainer from './container';

interface GetTableAdapterKeyParams {
  readonly isPartner?: boolean;
  readonly partnerId: Nullable<string>;
}

interface GetTableAdapterKeyResult {
  readonly key: string;
  readonly keyPartitions?: (string | number | null)[];
}

const getTableAdapterKey = (params: GetTableAdapterKeyParams): GetTableAdapterKeyResult => {
  const { isPartner, partnerId } = params;

  const key = 'tradeActivations';
  let keyPartitions;

  if (isPartner) {
    keyPartitions = ['partner', 'all'];
  } else {
    keyPartitions = ['admin', partnerId ? 'byPartner' : 'common', 'all'];
  }

  return {
    key,
    keyPartitions,
  };
};

interface TradeActivationTableEntryProps {
  readonly guid: UUID;
  readonly partnerId?: Nullable<UUID>;
  readonly customerId?: Nullable<UUID>;
}

const TradeActivationTableEntry = (props: TradeActivationTableEntryProps) => {
  const { guid, partnerId = null, customerId = null } = props;
  const { accessMatrix } = useCurrentUser();
  const { isPartnerUser, tradeActivations } = accessMatrix;

  const { promotionTypes } = useSelector(nsiDataSelector);

  // получаем максимально возможный состав колонок
  const sourceColumns = useMemo(
    () => getTradeActivationTableVisibleColumns(partnerId, customerId),
    [partnerId, customerId]
  );

  // готовим адаптер для хука
  const filterAdapter = useMemo<DataFilterAdapter<ETradeActivationTableColumn, ETradeActivationsFilterItem>>(
    () => ({
      ...getTableAdapterKey({
        isPartner: isPartnerUser,
        partnerId,
      }),
      sourceColumns,
      filterItems: tradeActivationsFilterItems,
      requiredFilters: [ETradeActivationsFilterItem.Query],
      getDataFilterStrategy: (filterItem, values) =>
        getTradeActivationFilterStrategy(filterItem, promotionTypes, values),
    }),
    [sourceColumns, partnerId, isPartnerUser, promotionTypes]
  );

  return (
    <TradeActivationTableContainer
      guid={guid}
      filterAdapter={filterAdapter}
      partnerId={partnerId}
      customerId={customerId}
      access={tradeActivations}
    />
  );
};

export default TradeActivationTableEntry;
