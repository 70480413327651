import order, { BookingOrderOrderService } from './order';

type BookingOrderServices = {
  readonly order: BookingOrderOrderService;
};

const bookingOrderServices: BookingOrderServices = {
  order,
};

export default bookingOrderServices;
