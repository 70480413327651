import { Typography } from '@mui/material';
import { FCC } from 'react';
import { Nullable } from '../../../../../domain/model/types';
import { ValidationItemResult, ValidationResult } from '../../../../utils/validation';
import { ValidationLabelWrapper } from './controls';

interface StepperValidationLabelAdapterProps {
  readonly validation?: Nullable<ValidationResult<object>>;
}

const StepperValidationLabelAdapter: FCC<StepperValidationLabelAdapterProps> = ({ validation, children }) => {
  const errors = validation
    ? Object.keys(validation).map(key => {
        const validationKey = key as any as keyof {};
        const validationItem = validation[validationKey] as ValidationItemResult;

        if (!validationItem) {
          return null;
        }

        return (
          <Typography
            key={key}
            variant='body2'
            color='error'
          >
            {validationItem.message}
          </Typography>
        );
      })
    : null;

  return (
    <ValidationLabelWrapper>
      {children}
      {errors}
    </ValidationLabelWrapper>
  );
};
export default StepperValidationLabelAdapter;
