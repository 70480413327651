import activation, { CorpActivationActivationService } from './activation';

type CorpActivationServices = {
  readonly activation: CorpActivationActivationService;
};

const corpActivationServices: CorpActivationServices = {
  activation,
};

export default corpActivationServices;
