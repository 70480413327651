import Axios from 'axios';
import Api from '../../../../data/api';
import { ApiCancellable, ApiRequestListDiscriminator } from '../../../../data/api/types';
import { MpAllProps as ApiAllProps } from '../../../../data/api/user';
import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '../../../../data/api/utils';
import { Pageable } from '../../../../domain/model';
import { EUserRole, EUserStatus } from '../../../../domain/model/enums';
import { UUID } from '../../../../domain/model/types';
import { MpUser } from '../../../../domain/model/user';
import { PaginationSize } from '../../../types';
import { AdminMpFilterValues } from '../adminMp/table/filterUtils';
import { AdminMpTableTabsCounter, EAdminMpTableTab, getAdminMpStatusesByTableTab } from '../adminMp/table/utils';
import { AdminMpCounterByStatus } from '../adminMp/utils';

type CommandProps = {
  readonly id: UUID;
};

export type AdminAllProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: string;
    readonly statuses: EUserStatus[];
  };
  readonly filter: AdminMpFilterValues;
  readonly pageNumber: number;
};

export type CountProps = Omit<AdminAllProps, 'pageNumber'>;

type CountsByStatusesProps = Omit<AdminAllProps, 'pageNumber'>;

type CountsByTabsProps = CountProps & {
  readonly tabs: EAdminMpTableTab[];
};

export type UserMpAdminService = {
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AdminAllProps
  ) => ApiAllProps<D>;
  readonly all: (props: AdminAllProps) => Promise<Pageable<MpUser>>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly countsByStatuses: (
    props: CountsByStatusesProps
  ) => Promise<{ counts: AdminMpCounterByStatus; errors: Array<string> }>;
  readonly countsByTabs: (
    props: CountsByTabsProps
  ) => Promise<{ counts: AdminMpTableTabsCounter; errors: Array<string> }>;
  readonly enabled: (props: CommandProps) => Promise<void>;
  readonly disabled: (props: CommandProps) => Promise<void>;
};

const service: UserMpAdminService = {
  buildListQueryParams: ({ search, filter, pageNumber, signal }) => {
    const { pageSize, sort, statuses } = search;
    const { query } = filter;
    const querydsl = getQueryDslByDataFilterValues(filter);
    return {
      page: pageNumber,
      pageSize,
      sort,
      query: query?.value,
      querydsl,
      roles: [EUserRole.AdminMp],
      statuses,
      signal,
    };
  },
  all: async props => {
    const response = await Api.user.mp.all(service.buildListQueryParams(props));
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);
    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
  count: async props => {
    const { data: response } = await Api.user.mp.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      discriminator: ApiRequestListDiscriminator.Count,
    });

    return response[0].count;
  },
  countsByStatuses: async ({ signal, ...props }) => {
    const {
      search: { statuses },
    } = props;

    const errors: string[] = [];
    const counts: AdminMpCounterByStatus = {};

    const requests = statuses.map(status => {
      const params = {
        ...props,
        search: { ...props.search, statuses: [status] },
      };
      return service.count({ ...params, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: typeof responses[0], status: EUserStatus) => {
      if (response.status === 'fulfilled') {
        counts[status] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество администраторов '${status}': ${response.reason}`);
        }
      }
    };

    statuses.forEach((status, index) => parseResponse(responses[index], status));

    return { counts, errors };
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: AdminMpTableTabsCounter = {};

    const requests = tabs.map(tab => {
      const statuses = getAdminMpStatusesByTableTab(tab);
      const params = {
        ...props,
        search: { ...props.search, statuses },
      };
      return service.count({ ...params, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: typeof responses[0], tab: EAdminMpTableTab) => {
      if (response.status === 'fulfilled') {
        counts[tab] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество администраторов '${tab}': ${response.reason}`);
        }
      }
    };

    tabs.forEach((tab, index) => parseResponse(responses[index], tab));

    return { counts, errors };
  },
  enabled: async ({ id }) => {
    await Api.user.mp.changeStatus({ id, status: EUserStatus.Enabled });
  },
  disabled: async ({ id }) => {
    await Api.user.mp.changeStatus({ id, status: EUserStatus.Disabled });
  },
};

export default service;
