import { Grid, Tabs as MuiTabs } from '@mui/material';
import styled from '@emotion/styled';

export const Tabs = styled(MuiTabs)`
  background: ${p => p.theme.palette.background.default};

  & .MuiTabs-flexContainer {
    border-top: 0;
  }
`;

export const Content = styled(Grid)`
  width: 100%;

  & .MuiGrid-item {
    max-width: 100%;
  }

  & .MuiTypography-root {
    max-width: 100%;
    word-break: break-all;
  }
`;
