import { ContentTarget } from '@/domain';
import { getContentTargetClientOrgsDisplayText } from '@features/general/contentTarget/utils';

interface ContentTargetViewClientOrgsTableCellProps {
  readonly value: ContentTarget['clientOrgs'];
}

const ContentTargetViewClientOrgsTableCell = (props: ContentTargetViewClientOrgsTableCellProps) => {
  const { value } = props;

  const displayValue = getContentTargetClientOrgsDisplayText(value,  'Все компании');

  return <>{displayValue || '-'}</>;
};

export default ContentTargetViewClientOrgsTableCell;