import React from 'react';
import { UseAdCampaignTable } from './hooks/useAdCampaignTable';
import { UseAdCampaignTableConfig } from './hooks/useAdCampaignTableConfig';

export type AdCampaignTableContextValue = UseAdCampaignTable;

export const AdCampaignTableContext = React.createContext<AdCampaignTableContextValue>(
  {} as AdCampaignTableContextValue
);

export type AdCampaignTableConfigContextValue = UseAdCampaignTableConfig;

export const AdCampaignTableConfigContext = React.createContext<AdCampaignTableConfigContextValue>(
  {} as AdCampaignTableConfigContextValue
);
