import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Partner } from '../../../domain/model/partner';
import { UUID } from '../../../domain/model/types';
import { MpUser } from '../../../domain/model/user';
import ForbiddenComponent from '../../components/common/forbidden';
import useBreadcrumbsActions from '../../features/general/breadcrumbs/useBreadcrumbsActions';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import { viewHidePartnerManagementMenu } from '../../features/partner/management/container/store/slice';
import {
  getPartnerManagementEmployeeEditRoute,
  getPartnerManagementEmployeesRoute,
} from '../../features/partner/management/entry';
import PartnerEmployeeDetailsContainer from '../../features/partnerEmployee/details/container';
import { EPartnerEmployeeStep } from '../../features/partnerEmployee/utils';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';
import useHistoryExtensions from '../../hooks/useHistoryExtensions';

interface PartnerManagementEmployeeScreenProps {
  readonly partner: Partner;
}

const PartnerManagementEmployeeScreen = ({ partner }: PartnerManagementEmployeeScreenProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { putBreadcrumbs } = useBreadcrumbsActions();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const { partnerEmployees } = useCurrentUser().accessMatrix;

  const { employeeId } = useParams<{ employeeId: UUID }>();

  const onEdit = (employee: MpUser) => {
    history.push(getPartnerManagementEmployeeEditRoute({ partnerId: partner.id, employeeId: employee.id }));
  };

  const onClose = () => {
    gotoPrevIndependentLocation(getPartnerManagementEmployeesRoute({ id: partner.id }));
  };

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewHidePartnerManagementMenu());
  }, [dispatch]);

  useEffect(() => {
    putBreadcrumbs([{ label: 'Сотрудники' }]);
  }, []);

  if (!partnerEmployees?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <PartnerEmployeeDetailsContainer
      id={employeeId}
      step={EPartnerEmployeeStep.Profile}
      canChangeStatus={partnerEmployees.changeStatus}
      onEdit={partnerEmployees.edit ? onEdit : null}
      onClose={onClose}
    />
  );
};

export default PartnerManagementEmployeeScreen;
