import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { EOfferHistoryTableTab } from '../../types';
import {
  offerHistoryTableFieldFilterSelector,
  offerHistoryTableFilterSelector,
  offerHistoryTableNeedRefreshWatcherSelector,
  offerHistoryTablePageNumberSelector,
  offerHistoryTableSearchSelector,
  offerHistoryTableTabSelector,
} from '../store/selectors';
import {
  OfferFieldHistoryFetchProps,
  offerFieldHistoryTableFetch,
  OfferHistoryFetchProps,
  offerHistoryTableFetch,
  offerHistoryTableSetTab,
  offerHistoryTableStateReset,
} from '../store/slice';
import { EVariantHistory } from '../utils/common';

interface UseOfferHistoryTableSearchProps {
  readonly offerId: UUID;
  readonly typeHistory: EVariantHistory;
  readonly defaultTab: EOfferHistoryTableTab;
}

const useOfferHistoryTableSearch = (props: UseOfferHistoryTableSearchProps): void => {
  const { offerId, typeHistory, defaultTab } = props;

  const dispatch = useDispatch();

  const [searchPromise, setSearchPromise] = useState<Nullable<any>>(null);

  const tab = useSelector(offerHistoryTableTabSelector);
  const needRefreshWatcher = useSelector(offerHistoryTableNeedRefreshWatcherSelector);
  const pageNumber = useSelector(offerHistoryTablePageNumberSelector);
  const search = useSelector(offerHistoryTableSearchSelector);

  const historyFilter = useSelector(offerHistoryTableFilterSelector);
  const fieldHistoryFilter = useSelector(offerHistoryTableFieldFilterSelector);

  const historyQueryParams = useMemo<OfferHistoryFetchProps>(() => {
    return {
      search,
      filter: historyFilter,
      pageNumber,
      offerId,
      typeHistory,
    };
  }, [search, historyFilter, pageNumber, offerId, typeHistory]);

  const fieldHistoryQueryParams = useMemo<OfferFieldHistoryFetchProps>(() => {
    return {
      search,
      filter: fieldHistoryFilter,
      pageNumber,
      offerId,
      typeHistory,
    };
  }, [search, fieldHistoryFilter, pageNumber, offerId, typeHistory]);

  const onSearch = useCallback(() => {
    if (tab === EOfferHistoryTableTab.Status) {
      setSearchPromise(dispatch(offerHistoryTableFetch(historyQueryParams)));
    } else {
      setSearchPromise(dispatch(offerFieldHistoryTableFetch(fieldHistoryQueryParams)));
    }
  }, [dispatch, tab, historyQueryParams, fieldHistoryQueryParams]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromise?.abort();
      onSearch();

      return () => {
        searchPromise?.abort();
      };
    }
  }, [dispatch, needRefreshWatcher]);

  /// при монтировке устанавливаем tab, при размонтировке очищаем
  useEffect(() => {
    dispatch(offerHistoryTableSetTab(defaultTab));

    return () => {
      dispatch(offerHistoryTableStateReset());
    };
  }, [dispatch, defaultTab]);
};

export default useOfferHistoryTableSearch;
