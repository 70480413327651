import { BookingOfferCategoriesEditDialogAddAdapter } from './dialogAdd';
import { BookingOfferCategoriesEditDialogDeleteAdapter } from './dialogDelete';
import { BookingOfferCategoriesEditDialogEditAdapter } from './dialogEdit';

export const BookingOfferCategoriesEditDialogsAdapter = () => {
  return (
    <>
      <BookingOfferCategoriesEditDialogAddAdapter />
      <BookingOfferCategoriesEditDialogEditAdapter />
      <BookingOfferCategoriesEditDialogDeleteAdapter />
    </>
  );
};
