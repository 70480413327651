import { Location } from 'history';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { EBannerPartition } from '../../../domain/model/enums';
import { UUID } from '../../../domain/model/types';
import rootRouting from '../../../routing';
import BannerCreateScreen from '../../screens/bannerCreate';
import BannerDetailsScreen from '../../screens/bannerDetails';
import BannerEditScreen from '../../screens/bannerEdit';
import BannersScreen from '../../screens/banners';
import NotFoundScreen from '../../screens/notFound';
import { EBannerTableTab } from './table/utils';

const partitionPathParam = ':partition' as const;

const routing = {
  root: `${rootRouting.banners}`,
  list: `${rootRouting.banners}/${partitionPathParam}`,
  create: `${rootRouting.banners}/${partitionPathParam}/create`,
  details: `${rootRouting.banners}/:id`,
  edit: `${rootRouting.banners}/:id/edit`,
};

export enum EBannerUrlParam {
  Tab = 'tab',
}

export interface BannersLocationState {
  readonly guid: UUID;
}

interface GetBannersTableRouteProps {
  readonly guid?: UUID;
  readonly partition: EBannerPartition;
  readonly tab?: EBannerTableTab;
}

interface GetBannerCreateRouteProps {
  readonly partition: EBannerPartition;
}

interface GetBannerEditRouteProps {
  readonly id: UUID;
}

interface GetBannerDetailsRouteProps {
  readonly id: UUID;
}

export const getBannerDetailsRoute = ({ id }: GetBannerDetailsRouteProps): string => {
  return routing.details.replace(':id', id);
};

export const getBannerCreateRoute = ({ partition }: GetBannerCreateRouteProps): string => {
  return routing.create.replace(partitionPathParam, partition);
};

export const getBannersTableRoute = (props: GetBannersTableRouteProps): Location<BannersLocationState> => {
  const { guid, partition, tab } = props;

  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.append(EBannerUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: routing.list.replace(partitionPathParam, partition),
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

export const getBannerEditRoute = ({ id }: GetBannerEditRouteProps): string => {
  return routing.edit.replace(':id', id);
};

const BannersEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.list.replace(partitionPathParam, EBannerPartition.TradeOffers)}
        render={() => <BannersScreen partition={EBannerPartition.TradeOffers} />}
      />
      <Route
        exact
        path={routing.list.replace(partitionPathParam, EBannerPartition.CorpOffers)}
        render={() => <BannersScreen partition={EBannerPartition.CorpOffers} />}
      />
      <Route
        exact
        path={routing.create}
        component={BannerCreateScreen}
      />
      <Route
        exact
        path={routing.edit}
        component={BannerEditScreen}
      />
      <Route
        exact
        path={routing.details}
        component={BannerDetailsScreen}
      />
      <Redirect
        exact
        from={routing.root}
        to={getBannersTableRoute({ partition: EBannerPartition.TradeOffers })}
      />

      <Route component={NotFoundScreen} />
    </Switch>
  );
};

export default BannersEntry;
