import { AnyAdCampaign } from '@/domain/model/ads';
import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EAdCampaignActionType } from '../../types';
import AdCampaignActionsConfirmDialog from '../components/confirmDialog';
import {
  adCampaignActionsDialogResumeSelector,
  getAdCampaignActionsAdCampaignIsProcessingSelector,
} from '../store/selectors';
import { adCampaignActionsResume } from '../store/slice';
import { useAdCampaignActions } from '../useActions';
import { showAdCampaignActionNotification } from '../utils';

const getResumeText = (text: Nullable<string>) => (
  <Typography>
    Рекламная кампания «<b>{text}</b>» будет возобновблена
  </Typography>
);

const AdCampaignActionsDialogResumeAdapter = () => {
  const dispatch = useDispatch();

  const handlers = useAdCampaignActions();

  const object = useSelector(adCampaignActionsDialogResumeSelector);

  const isProcessing = useSelector(getAdCampaignActionsAdCampaignIsProcessingSelector(object?.id ?? null));

  const onResume = useCallback(
    (campaign: AnyAdCampaign) => {
      if (campaign.id) {
        dispatch(
          adCampaignActionsResume({
            id: campaign.id,
          })
        )
          .unwrap()
          .then(response => showAdCampaignActionNotification(response, EAdCampaignActionType.Resume, false))
          .finally(onCloseDialog);
      }
    },
    [dispatch]
  );

  const onCloseDialog = useCallback(() => {
    handlers.onChangeDialogState({ name: 'resume', data: null });
  }, [handlers.onChangeDialogState]);

  return (
    object && (
      <AdCampaignActionsConfirmDialog
        campaign={object}
        title='Возобновить рекламную кампанию?'
        actionText='Возобновить'
        text={getResumeText(object.name)}
        onAction={campaign => onResume(campaign)}
        isFetching={isProcessing}
        onClose={onCloseDialog}
      />
    )
  );
};

export default AdCampaignActionsDialogResumeAdapter;
