import { useSelector } from 'react-redux';
import { BookingOfferEditFieldServiceProps } from '../../../components/fieldsEdit/types';
import { BookingOfferServiceManageFieldServiceStatus } from '../../fields/serviceStatus';
import { bookingOfferServiceManageServiceByIndexSelector } from '../../store/selectros';

const fieldName = 'status';

export const BookingOfferServiceManageStatusFieldAdapter = (
  props: BookingOfferEditFieldServiceProps<typeof fieldName>
) => {
  const { value, index } = props;

  const service = useSelector(bookingOfferServiceManageServiceByIndexSelector(index));

  return value ? (
    <BookingOfferServiceManageFieldServiceStatus
      {...props}
      nameService={service?.name}
    />
  ) : null;
};
