import { Grid } from '@mui/material';
import React from 'react';
import { BookingOffer, BookingOfferShort } from '../../../../../../domain/model/booking';
import ConfirmDialog from '../../../../../components/common/dialogs/confirm';
import ContentLoader from '../../../../../components/common/loader';
import { MPButton } from '../../../../../theme/ui-kit/button';
import { AddressHelper } from '../../../../../utils/address';
import BookingOfferPreview from '../../../components/preview';

interface BookingActionsConfirmDialogProps {
  readonly offer: BookingOffer | BookingOfferShort;
  readonly title: string;
  readonly actionText: string;
  readonly text?: React.ReactNode | string;
  readonly preview?: boolean;
  readonly children?: React.ReactNode;
  readonly actionDisabled?: boolean;
  readonly isFetching: boolean;
  readonly onAction: (offer: BookingOffer | BookingOfferShort) => void;
  readonly onClose: () => void;
}

const BookingActionsConfirmDialog = <T extends object>(props: BookingActionsConfirmDialogProps) => {
  const {
    offer,
    title,
    text,
    actionText,
    isFetching,
    actionDisabled = false,
    preview = true,
    children,
    onAction,
    onClose,
  } = props;

  return (
    <ConfirmDialog
      open
      onClose={onClose}
      title={title}
      dialogButtons={
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              disabled={actionDisabled || isFetching}
              onClick={() => onAction(offer)}
            >
              {actionText}
              {isFetching && <ContentLoader />}
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              disabled={isFetching}
              fullWidth={false}
              onClick={onClose}
            >
              Отменить
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        spacing={2}
        wrap='nowrap'
        direction='column'
      >
        {text && <Grid item>{text}</Grid>}
        {preview && (
          <Grid item>
            <BookingOfferPreview
              border
              name={offer.name}
              address={offer.address ? new AddressHelper(offer.address).getLastLocalityShortPath() : null}
              image={offer.images?.[0] ?? null}
              servicePrice={offer.displayPriceItem?.price ?? null}
              serviceUnit={offer.displayPriceItem?.unit?.name ?? null}
              logo={offer.partner?.logo ?? null}
            />
          </Grid>
        )}
        {children && <Grid item>{children}</Grid>}
      </Grid>
    </ConfirmDialog>
  );
};

export default BookingActionsConfirmDialog;
