import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { ServerErrorResponse } from '../../../../../data/network/types';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { ProductOrder } from '../../../../../domain/model/order';
import { Nullable, UUID } from '../../../../../domain/model/types';
import productOrderServices from '../../services';
import { EProductOrderActionType } from '../../types';

export type ProductOrderActionsState = {
  readonly actions: (Fetchable & {
    id: UUID;
    type: EProductOrderActionType;
    error: Nullable<ServerErrorResponse>;
  })[];
  readonly create: Fetchable;
  readonly dialogs: {
    readonly cancel: Nullable<ProductOrder>;
    readonly confirm: Nullable<ProductOrder>;
  };
};

const getActionProcess = (state: ProductOrderActionsState, id: UUID, actionType: EProductOrderActionType) => {
  let process = state.actions.find(change => change.id === id);
  if (process) return process;

  process = {
    ...fetchableDefault,
    id,
    type: actionType,
    error: null,
  };
  state.actions.push(process);

  return process;
};

type Reducer<T = undefined> = CaseReducer<ProductOrderActionsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ProductOrderActionsState> {
  productOrderActionsChangeDialogState: Reducer<{
    name: keyof ProductOrderActionsState['dialogs'];
    data: Nullable<ProductOrder>;
  }>;
  productOrderActionsOptimize: Reducer;
}

export const productOrderActionsCancel = createAsyncThunk<
  ProductOrder,
  { id: UUID; comment: string; reasonId: UUID },
  AppThunkAPIConfig<{
    error: ServerErrorResponse;
  }>
>('productOrder/actions/cancel', async ({ id, comment, reasonId }, { rejectWithValue }) => {
  try {
    return await productOrderServices.order.cancel({ id, comment, reasonId });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productOrderActionsReturn = createAsyncThunk<
  ProductOrder,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('productOrder/actions/return', async ({ id }, { rejectWithValue }) => {
  try {
    return await productOrderServices.order.return(id);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productOrderActionsPartiallyReturn = createAsyncThunk<
  ProductOrder,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('productOrder/actions/partiallyReturn', async ({ id }, { rejectWithValue }) => {
  try {
    return await productOrderServices.order.partiallyReturn(id);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productOrderActionsGive = createAsyncThunk<
  undefined,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('productOrder/actions/give', async ({ id }, { rejectWithValue }) => {
  try {
    await productOrderServices.order.given(id);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productOrderActionsSend = createAsyncThunk<
  undefined,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('productOrder/actions/send', async ({ id }, { rejectWithValue }) => {
  try {
    await productOrderServices.order.send(id);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productOrderActionsPay = createAsyncThunk<undefined, { id: UUID }, AppThunkAPIConfig<ServerErrorResponse>>(
  'productOrder/actions/pay',
  async ({ id }, { rejectWithValue }) => {
    try {
      await productOrderServices.order.pay(id);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productOrderActionsConfirm = createAsyncThunk<
  undefined,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('productOrder/actions/confirm', async ({ id }, { rejectWithValue }) => {
  try {
    await productOrderServices.order.confirm(id);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productOrderActionsRenew = createAsyncThunk<
  undefined,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('productOrder/actions/renew', async ({ id }, { rejectWithValue }) => {
  try {
    await productOrderServices.order.renew(id);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

const slice = createSlice<ProductOrderActionsState, Reducers, 'productOrders/actions'>({
  name: 'productOrders/actions',
  initialState: {
    actions: [],
    create: fetchableDefault,
    dialogs: {
      cancel: null,
      confirm: null,
    },
  },
  reducers: {
    productOrderActionsChangeDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
    productOrderActionsOptimize: state => {
      // Оставляем только выполняющиеся действия
      state.actions = state.actions.filter(action => action.isFetching);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(productOrderActionsCancel.pending, (state, { meta }) => {
        const { id } = meta.arg;
        const actionType = EProductOrderActionType.Cancel;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productOrderActionsCancel.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Cancel;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productOrderActionsCancel.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Cancel;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload?.error ?? null;
      })
      .addCase(productOrderActionsReturn.pending, (state, { meta }) => {
        const { id } = meta.arg;
        const actionType = EProductOrderActionType.Return;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productOrderActionsReturn.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Return;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productOrderActionsReturn.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Return;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productOrderActionsPartiallyReturn.pending, (state, { meta }) => {
        const { id } = meta.arg;
        const actionType = EProductOrderActionType.PartiallyReturn;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productOrderActionsPartiallyReturn.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.PartiallyReturn;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productOrderActionsPartiallyReturn.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.PartiallyReturn;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productOrderActionsGive.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Give;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productOrderActionsGive.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Give;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productOrderActionsGive.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Give;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productOrderActionsConfirm.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Confirm;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productOrderActionsConfirm.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Confirm;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productOrderActionsConfirm.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Confirm;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productOrderActionsSend.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Send;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productOrderActionsSend.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Send;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productOrderActionsSend.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Send;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productOrderActionsPay.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Pay;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productOrderActionsPay.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Pay;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productOrderActionsPay.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Pay;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productOrderActionsRenew.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Renew;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productOrderActionsRenew.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Renew;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productOrderActionsRenew.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductOrderActionType.Renew;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      });
  },
});

export const { productOrderActionsChangeDialogState, productOrderActionsOptimize } = slice.actions;

export default slice.reducer;
