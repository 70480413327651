import Axios from 'axios';
import Api from '../../../../data/api';
import { ApiCancellable } from '../../../../data/api/types';
import { businessErrorCode } from '../../../../data/network/constants';
import { BookingOfferService, BookingServicePriceItem } from '../../../../domain/model/booking';
import { UUID } from '../../../../domain/model/types';
import { BookingServicePriceItemView } from '../components/fieldsEdit/types';
import { BookingOfferServiceView } from '../types';

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type SavePriceItemProps = {
  readonly priceItem: BookingServicePriceItemView;
};

type SaveOfferServicesProps = {
  readonly offerId: UUID;
  readonly services: BookingOfferServiceView[];
};

type DeleteOfferServicesProps = {
  readonly services: UUID[];
};

type DeletePriceItemsProps = {
  readonly priceItems: UUID[];
};

type SaveServicePriceItemsProps = {
  readonly serviceId: UUID;
  readonly priceItems: BookingServicePriceItemView[];
};

type SaveProps = {
  readonly offerService: BookingOfferServiceView;
};

export type BookingOfferServiceService = {
  readonly one: (props: OneProps) => Promise<BookingOfferService>;
  readonly save: (props: SaveProps) => Promise<{ service: BookingOfferService; errors: string[] }>;
  readonly saveOfferServices: (
    props: SaveOfferServicesProps
  ) => Promise<{ services: BookingOfferService[]; errors: string[] }>;
  readonly deleteOfferServices: (props: DeleteOfferServicesProps) => Promise<{ services: UUID[]; errors: string[] }>;
  readonly saveServicePriceItems: (
    props: SaveServicePriceItemsProps
  ) => Promise<{ priceItems: BookingServicePriceItem[]; errors: string[] }>;
  readonly savePriceItem: (props: SavePriceItemProps) => Promise<BookingServicePriceItem>;
  readonly deletePriceItems: (props: DeletePriceItemsProps) => Promise<{ priceItems: UUID[]; errors: string[] }>;
};

const service: BookingOfferServiceService = {
  one: async ({ id, signal }) => {
    return (await Api.booking.service.one({ id, signal })).data;
  },
  save: async ({ offerService }) => {
    let serviceId: UUID;

    if (!offerService.id) {
      const { id, priceItems, ...createData } = offerService;
      const { data } = await Api.booking.service.create({ data: createData });
      serviceId = data.id;
    } else {
      serviceId = offerService.id;
    }

    const { priceItems, errors } = await service.saveServicePriceItems({
      serviceId,
      priceItems: offerService.priceItems,
    });

    const { id, ...updateData } = offerService;
    const updatedService = (await Api.booking.service.update({ id: serviceId, data: { ...updateData, priceItems } }))
      .data;
    return { service: updatedService, errors };
  },
  saveServicePriceItems: async ({ serviceId, priceItems }) => {
    const errors: string[] = [];
    const savedPriceItems: BookingServicePriceItem[] = [];

    const requests = priceItems.map(priceItem =>
      service.savePriceItem({
        priceItem: {
          ...priceItem,
          service: { id: serviceId },
        },
      })
    );

    const responses = await Promise.allSettled(requests);

    responses.forEach((response, index) => {
      if (response.status === 'fulfilled') {
        savedPriceItems.push(response.value);
      } else {
        // Если тариф был, но не проаптейтился - все равно егооставляем в списке
        // TODO - booking это странно, у нас все перемешано будет, часть сохранено, часть нет
        if (priceItems[index].id) {
          savedPriceItems.push(priceItems[index] as BookingServicePriceItem);
        }
        if (!(response.reason instanceof Axios.Cancel)) {
          const errorMessage: string =
            response.reason.response.status === businessErrorCode
              ? response.reason.response.data.message
              : response.reason;
          errors.push(`Не удалось сохранить тариф '${priceItems[index].name}': ${errorMessage}`);
        }
      }
    });

    return { priceItems: savedPriceItems, errors };
  },
  saveOfferServices: async ({ offerId, services }) => {
    const errors: string[] = [];
    const savedServices: BookingOfferService[] = [];

    // перед сохранением сервисов удаляем тарифы и сервисы, которые были в offer
    const offer = (await Api.booking.offer.one({ id: offerId })).data;

    const priceItemsIds = services.flatMap(service => service.priceItems ?? []).map(item => item.id);
    const deletedPriceItems = offer.services
      ?.flatMap(service => service.priceItems ?? [])
      .filter(priceItem => !priceItemsIds.includes(priceItem.id));
    if (deletedPriceItems?.length) {
      const { errors: deletePriceItemsErrors } = await service.deletePriceItems({
        priceItems: deletedPriceItems.map(item => item.id),
      });
      errors.push(...deletePriceItemsErrors);
    }

    const servicesIds = services.map(item => item.id);
    const deletedServices = offer.services?.filter(service => !servicesIds.includes(service.id));
    if (deletedServices?.length) {
      const { errors: deleteOfferServicesErrors } = await service.deleteOfferServices({
        services: deletedServices.map(item => item.id),
      });
      errors.push(...deleteOfferServicesErrors);
    }

    const requests = services.map(offerService =>
      service.save({
        offerService: {
          ...offerService,
          offer: { id: offerId },
        },
      })
    );

    const responses = await Promise.allSettled(requests);

    responses.forEach((response, index) => {
      if (response.status === 'fulfilled') {
        savedServices.push(response.value.service);
        errors.push(...response.value.errors);
      } else {
        // Если услуга была, но не сохранилась - все равно ее оставляем в списке услуг
        // TODO - booking это странно, у нас все перемешано будет, часть сохранено, часть нет
        if (services[index].id) {
          savedServices.push(services[index] as BookingOfferService);
        }
        if (!(response.reason instanceof Axios.Cancel)) {
          console.log('!!', response);
          const errorMessage: string =
            response.reason.response.status === businessErrorCode
              ? response.reason.response.data.message
              : response.reason;
          errors.push(`Не удалось сохранить услугу '${services[index].name}': ${errorMessage}`);
        }
      }
    });

    return { services: savedServices, errors };
  },
  deleteOfferServices: async ({ services }) => {
    const errors: string[] = [];
    const deletedServices: UUID[] = [];

    const requests = services.map(id =>
      Api.booking.service.delete({
        id,
      })
    );

    const responses = await Promise.allSettled(requests);

    responses.forEach((response, index) => {
      if (response.status === 'fulfilled') {
        deletedServices.push(services[index]);
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось удалить услугу '${services[index]}'`);
        }
      }
    });

    return { services: deletedServices, errors };
  },
  savePriceItem: async ({ priceItem }) => {
    const { id, ...data } = priceItem;

    let response;
    if (id) {
      const { service, ...updateData } = data;
      response = await Api.booking.priceItem.update({ id, data: updateData });
    } else {
      response = await Api.booking.priceItem.create({ data });
    }

    return response.data;
  },
  deletePriceItems: async ({ priceItems }) => {
    const errors: string[] = [];
    const deletedPriceItems: UUID[] = [];

    const requests = priceItems.map(id =>
      Api.booking.priceItem.delete({
        id,
      })
    );

    const responses = await Promise.allSettled(requests);

    responses.forEach((response, index) => {
      if (response.status === 'fulfilled') {
        deletedPriceItems.push(priceItems[index]);
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось удалить тариф '${priceItems[index]}'`);
        }
      }
    });

    return { priceItems: deletedPriceItems, errors };
  },
};
export default service;
