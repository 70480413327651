import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isProductOrdersAnyChangedSelector } from '../store/selectors';
import { productOrderActionsOptimize } from '../store/slice';

const ProductOrderActionsOptimizerAdapter = () => {
  const dispatch = useDispatch();
  const needOptimize = useSelector(isProductOrdersAnyChangedSelector);

  useEffect(() => {
    if (needOptimize > 0) {
      dispatch(productOrderActionsOptimize());
    }
  }, [needOptimize]);

  return null;
};

export default ProductOrderActionsOptimizerAdapter;
