import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { ECmsContainerType } from '../../../domain/model/enums';
import { Partner } from '../../../domain/model/partner';
import { CmsContainerLifeCycleFactory } from '../../features/cms/container/lifecycle';
import { ECmsContainerUrlParam } from '../../features/cms/container/types';
import RequiredGuidInLocationContainer from '../../features/general/locationGuid/container';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import { viewHidePartnerManagementMenu } from '../../features/partner/management/container/store/slice';
import { getPartnerManagementWindowRoute } from '../../features/partner/management/entry';
import PartnerWindowCmsContainerCreateContainer from '../../features/partnerWindow/cms/create';
import { PartnerCmsContainerCreateLocationState } from '../../features/partnerWindow/entry';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';
import useHistoryExtensions from '../../hooks/useHistoryExtensions';

interface PartnerManagementWindowContainerCreateScreenProps {
  readonly partner: Partner;
}

const PartnerManagementWindowContainerCreateScreen = ({
  partner,
}: PartnerManagementWindowContainerCreateScreenProps) => {
  const dispatch = useDispatch();

  const { userSpecific, accessMatrix } = useCurrentUser();
  const { partnerWindow } = accessMatrix;

  const location = useLocation<PartnerCmsContainerCreateLocationState>();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const searchParams = new URLSearchParams(location.search);
  const type = (searchParams.get(ECmsContainerUrlParam.Type) as ECmsContainerType) ?? ECmsContainerType.Banner1;
  const step = Number(searchParams.get(ECmsContainerUrlParam.Step)) ?? 0;

  const onClose = () => {
    gotoPrevIndependentLocation(getPartnerManagementWindowRoute({ id: partner.id }));
  };

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewHidePartnerManagementMenu());
  }, [dispatch]);

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <PartnerWindowCmsContainerCreateContainer
          partnerId={partner.id}
          guid={guid}
          step={step}
          type={type}
          lifecycle={CmsContainerLifeCycleFactory().create({ userId: userSpecific.id })}
          canCreate={partnerWindow.create}
          canSave={partnerWindow.edit}
          canPublish={partnerWindow.publish}
          canDelete={partnerWindow.delete}
          canChangeOfferIconVisible={partnerWindow.changeOfferIconVisible}
          onClose={onClose}
        />
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default PartnerManagementWindowContainerCreateScreen;
