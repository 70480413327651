import { useCallback } from 'react';
import { ECmsBannerLinkObjectType } from '../../../../../../domain/model/enums';
import { Nullable } from '../../../../../../domain/model/types';
import EntityMasterPreviewContentGrid from '../../../../../components/common/entityMaster/preview/contentGrid';
import { EEntityPreviewMode } from '../../../../../types';
import { CmsPreviewItemRatio } from '../../../types';
import { CmsLinkedObjectBannerType } from '../../../types/banner';
import CmsPreviewBlock from '../block';
import CmsPreviewBlockCaption from '../block/caption';

interface CmsCategoriesPreviewProps {
  readonly mode?: EEntityPreviewMode;
  readonly banners: Nullable<CmsLinkedObjectBannerType>[];
  readonly activePreviewNumber?: Nullable<number | false>;
  readonly canActivateItem?: boolean;
  readonly ratio?: CmsPreviewItemRatio;
  readonly rowElementsCount: number;
}

const CmsCategoriesPreview = (props: CmsCategoriesPreviewProps) => {
  const {
    mode = EEntityPreviewMode.Desktop,
    activePreviewNumber,
    canActivateItem,
    ratio,
    rowElementsCount,
    banners,
  } = props;

  const getBannerCaption = useCallback(
    (banner: Nullable<CmsLinkedObjectBannerType>, index: number) => {
      const defaultName = canActivateItem ? 'Название категории' : `Категория ${index + 1}`;
      if (banner) {
        switch (banner?.linkObjectType) {
          case ECmsBannerLinkObjectType.CorpOfferCategory:
          case ECmsBannerLinkObjectType.TradeOfferCategory:
            return banner?.linkedObject?.name ?? defaultName;
          default:
            return defaultName;
        }
      } else {
        return defaultName;
      }
    },
    [canActivateItem]
  );

  return (
    <EntityMasterPreviewContentGrid
      ratio={ratio}
      maxRowElementsCount={rowElementsCount}
      preserveAspectRatio={true}
    >
      {banners.map((b, i) => (
        <CmsPreviewBlock
          key={b?.id ?? i}
          width={b?.width}
          height={b?.height}
          number={i + 1}
          caption={<CmsPreviewBlockCaption text={getBannerCaption(b, i)} />}
          image={mode === EEntityPreviewMode.Desktop ? b?.desktopImage : b?.mobileImage}
          isActive={canActivateItem ? activePreviewNumber === i : true}
          showNumber={canActivateItem && mode === EEntityPreviewMode.Desktop && banners.length > 1}
        />
      ))}
    </EntityMasterPreviewContentGrid>
  );
};

export default CmsCategoriesPreview;
