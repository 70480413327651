import { PanelAction, PanelActions } from '../../types';

export enum EPartnerEmployeeActionType {
  Save = 'save',
  Edit = 'edit',
  Activate = 'activate',
  Deactivate = 'deactivate',
  ChangeResponsible = 'changeResponsible',
}

export type PartnerEmployeeActionDetailsType = Exclude<
  EPartnerEmployeeActionType,
  EPartnerEmployeeActionType.Save | EPartnerEmployeeActionType.ChangeResponsible
>;
export type PartnerEmployeeActionEditType = EPartnerEmployeeActionType.Save;
export type PartnerEmployeeActionTableType =
  | EPartnerEmployeeActionType.Activate
  | EPartnerEmployeeActionType.Deactivate
  | EPartnerEmployeeActionType.ChangeResponsible;

export type PartnerEmployeeAction<A extends EPartnerEmployeeActionType, P = any> = PanelAction<A, P>;

export type PartnerEmployeeActions<A extends EPartnerEmployeeActionType> = PanelActions<A>;

export interface GetPartnerEmployeeActionsByPermissions {
  readonly canCreate?: boolean;
  readonly canEdit?: boolean;
  readonly canSave?: boolean;
  readonly canActivate?: boolean;
  readonly canDeactivate?: boolean;
  readonly canChangeResponsible?: boolean;
}
