import React, { useRef, useState } from 'react';
import { Nullable } from '../../../../../../domain/model/types';
import DataFilterViewCommonItem from './common';
import { MorePopover } from './controls';

interface DataFilterExpandedItemProps {
  readonly label: string;
  readonly children?: Nullable<React.ReactElement>;
  readonly onDelete?: () => void;
}

const DataFilterExpandedItem = (props: DataFilterExpandedItemProps) => {
  const { label, onDelete, children } = props;
  const [expanded, setExpanded] = useState<boolean>(false);

  const anchorEl = useRef(null);

  const onExpandOrCollapse = () => setExpanded(!expanded);

  return (
    <>
      <DataFilterViewCommonItem
        elementRef={anchorEl}
        label={label}
        onClick={children ? onExpandOrCollapse : undefined}
        onDelete={onDelete}
      />
      <MorePopover
        open={expanded}
        anchorEl={anchorEl?.current}
        onClose={onExpandOrCollapse}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {children}
      </MorePopover>
    </>
  );
};

export default DataFilterExpandedItem;
