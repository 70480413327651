import { DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Grid } from '@mui/material';
import { UserAgreement } from '../../../../../domain/model/legalAgreement';
import FileInfo from '../../../../components/common/files/info';
import { FileInfoLinkAnyAdapter } from '../../../../components/common/files/info/linkAnyAdapter';
import ContentLoader from '../../../../components/common/loader';
import { ContentTypeDocumentIcon } from '../../../../icons';
import { MPButton } from '../../../../theme/ui-kit/button';
import { EAgreementsStatus } from '../../store/selectors';
import { StyledDialog } from './controls';
import { ArrowRightIcon } from '@mui/x-date-pickers';

interface SignAgreementsDialogProps {
  readonly agreements: UserAgreement[];
  readonly agreementsStatus: EAgreementsStatus;
  readonly open: boolean;
  readonly dialogProps?: DialogProps;
  readonly isAccepting?: boolean;
  readonly isRejecting?: boolean;
  readonly onAccept: () => void;
  readonly onReject: () => void;
}

const SignAgreementsDialog = (props: SignAgreementsDialogProps) => {
  const { agreements, agreementsStatus, open, dialogProps, isAccepting, isRejecting, onAccept, onReject } = props;

  const isAgreementsUpdated = agreementsStatus === EAgreementsStatus.Updated;

  const acceptText = 'Принять';
  const title = isAgreementsUpdated ? 'Мы обновили документы' : 'Необходимо ваше согласие с документами';

  return (
    <StyledDialog
      fullWidth
      keepMounted
      maxWidth='sm'
      {...dialogProps}
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Пожалуйста, ознакомьтесь с документами и дайте своё согласие, нажав кнопку «{acceptText}»
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          spacing={2}
          wrap='nowrap'
          direction='column'
        >
          {agreements.map(a => (
            <Grid
              item
              key={a.doc.id}
            >
              <FileInfo
                color={'secondary'}
                id={a.doc.url}
                icon={<ContentTypeDocumentIcon color='action' />}
                endIcon={<ArrowRightIcon color='action' />}
                adapter={FileInfoLinkAnyAdapter}
                originalName={a.doc.name}
                linkProps={{
                  underline: 'none',
                  target: '_blank',
                }}
              />
            </Grid>
          ))}
        </Grid>
      </DialogActions>
      <DialogActions>
        <Grid
          container
          spacing={2}
          direction='row'
        >
          <Grid item>
            <MPButton
              onClick={onAccept}
              disabled={isAccepting || isRejecting}
            >
              {acceptText}
              {isAccepting && <ContentLoader />}
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              disabled={isAccepting || isRejecting}
              onClick={onReject}
            >
              Отклонить
              {isRejecting && <ContentLoader />}
            </MPButton>
          </Grid>
        </Grid>
      </DialogActions>
    </StyledDialog>
  );
};

export default SignAgreementsDialog;
