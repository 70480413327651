import React, { useEffect, useState } from 'react';
import { getFileDownloadEndpoint } from '../../../../../data/api/utils';
import { FileDescription } from '../../../../../domain/model';
import { StyledImage } from './controls';

export interface AppImageProps extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'> {
  /** id/path файла или структура FileDescription */
  readonly src: string | FileDescription;
  /** прямая полная ссылка на файл */
  readonly stub?: string;
  readonly className?: string;
}

/*
  если true, то компонент создает через useState некий id, используемый для формирования ссылки на файлто есть
  каждый mount будет разная итоговая ссылка
 */
const stateOn = false as const;

const ImageInternal = React.forwardRef((props: AppImageProps, ref: any) => {
  const { src, stub, className, alt, ...otherProps } = props;
  const [state, setState] = useState<string>(new Date().getTime().toString());

  const id: string = typeof src === 'string' ? src : src.path;
  const href = getFileDownloadEndpoint({ id, state: stateOn ? state : null }) ?? stub ?? null;

  useEffect(() => {
    setState(new Date().getTime().toString());
  }, [src]);

  return (
    <StyledImage
      ref={ref}
      className={className}
      src={href}
      alt={alt ?? ''}
      {...otherProps}
    />
  );
});

const AppImage = React.forwardRef<typeof ImageInternal, any>((props: AppImageProps, ref: any) => (
  <ImageInternal
    ref={ref}
    {...props}
  />
)) as typeof ImageInternal;

export default AppImage;
