import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import rootRouting from '../../../routing';
import NotFoundScreen from '../../screens/notFound';
import SupportInfoDetailsScreen from '../../screens/supportInfoDetails';

const routing = {
  root: `${rootRouting.support}`,
  details: `${rootRouting.support}/details`,
};

export const getSupportInfoDetailsRoute = () => {
  return routing.details;
};

const SupportEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.details}
        component={SupportInfoDetailsScreen}
      />
      <Redirect
        exact
        from={routing.root}
        to={routing.details}
      />

      <Route component={NotFoundScreen} />
    </Switch>
  );
};

export default SupportEntry;
