import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { EOfferTableTab } from '../../../general/offer/types';
import {
  bookingOffersFilterSelector,
  bookingOffersNeedRefreshWatcherSelector,
  bookingOffersPageNumberSelector,
  bookingOffersSearchSelector,
} from '../store/selectors';
import {
  bookingOffersCountsFetch,
  bookingOffersCountsUnwatchedFetch,
  bookingOffersFetch,
  BookingOffersFetchProps,
  bookingOffersNeedRefreshWatcherReset,
  bookingOffersUnwatchedFetch,
} from '../store/slice';
import { getStatesFilterForBookingOfferTableTab } from '../utils';
import { useContextConfig } from './useContextConfig';

interface UseBookingOffersTableSearchProps {
  readonly tab: EOfferTableTab;
  readonly partnerId: Nullable<UUID>;
}

const getStatusesByTab = (t: EOfferTableTab) => getStatesFilterForBookingOfferTableTab(t, []);

const useBookingOffersTableSearch = (props: UseBookingOffersTableSearchProps): void => {
  const { tab, partnerId } = props;

  const dispatch = useDispatch();

  const { tabsForCounts, tabsForCountsUnwatched } = useContextConfig();

  const [searchPromises, setSearchPromises] = useState<Nullable<any[]>>(null);

  const needRefreshWatcher = useSelector(bookingOffersNeedRefreshWatcherSelector);
  const pageNumber = useSelector(bookingOffersPageNumberSelector);
  const search = useSelector(bookingOffersSearchSelector);
  const filter = useSelector(bookingOffersFilterSelector);

  const currentStatuses = getStatusesByTab(tab);

  const queryParams = useMemo<BookingOffersFetchProps>(() => {
    return {
      filter,
      search: {
        ...search,
        statuses: currentStatuses,
        partnerId,
        categories: null,
      },
      pageNumber,
    };
  }, [filter, search, currentStatuses, partnerId, pageNumber]);

  const onSearch = useCallback(() => {
    setSearchPromises([
      dispatch(bookingOffersFetch(queryParams)),
      dispatch(bookingOffersCountsFetch({ ...queryParams, tabs: tabsForCounts })),
      dispatch(bookingOffersCountsUnwatchedFetch({ ...queryParams, tabs: tabsForCountsUnwatched })),
      dispatch(bookingOffersUnwatchedFetch(queryParams)),
    ]);
  }, [dispatch, queryParams]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  // сброс вотчера после ухода
  useEffect(() => {
    return () => {
      dispatch(bookingOffersNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};

export default useBookingOffersTableSearch;
