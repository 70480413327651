import { MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Nullable } from '../../../../domain/model/types';
import { PanelAction, PanelActions } from '../../../types';
import MenuButton from '../buttons/menu';

interface ToolbarActionsProps<A extends string> {
  readonly actions: PanelActions<A>;
  readonly onAction: (action: PanelAction<A>) => void;
}

type ToolbarActionsComponentType = <A extends string>(props: ToolbarActionsProps<A>) => React.ReactElement | null;

const ToolbarActionsComponent: ToolbarActionsComponentType = props => {
  const { actions, onAction } = props;
  const [menuCloseMarker, setMenuCloseMarker] = useState<Nullable<symbol>>(null);

  return (
    <MenuButton
      id={uuidv4()}
      variant='outlined'
      size='medium'
      color='primary'
      closeMarker={menuCloseMarker}
    >
      {actions.map(action => (
        <MenuItem
          key={action.type}
          disabled={action.disabled}
          onClick={() => {
            onAction(action);
            setMenuCloseMarker(Symbol());
          }}
        >
          {action.label}
        </MenuItem>
      ))}
    </MenuButton>
  );
};

export default ToolbarActionsComponent;
