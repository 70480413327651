import { ENoticeStatus, EOfferType } from '../../../domain/model/enums';
import { Nullable } from '../../../domain/model/types';
import { capitalizeFirstLetter } from '../../utils/strings';
import { ENotificationMessageType, NotificationTypes } from './types';

interface SocketNotifierNotice {
  status: ENoticeStatus;
  text: string;
  title?: string;
}

export const getSocketNotifierByType = (
  msg: NotificationTypes,
  type: ENotificationMessageType
): SocketNotifierNotice => {
  switch (msg.type) {
    case ENotificationMessageType.OfferToModerationMessage:
    case ENotificationMessageType.OfferRejectedMessage:
    case ENotificationMessageType.OfferExpiredMessage:
    case ENotificationMessageType.OfferPromotionFreshOutMessage:
    case ENotificationMessageType.OfferPromotionsSellingOut:
    case ENotificationMessageType.OfferUpcomingMessage:
    case ENotificationMessageType.OfferPausedByAdminMessage:
    case ENotificationMessageType.OfferActivatedMessage: {
      const { offerId, offerName, offerType } = msg;

      return {
        status: getNotificationMessageStatus(type),
        title: getNotificationMessageTitle(type, offerType),
        text: `${offerId}\n${offerName}${getNotificationMessageExtraText(type)}`,
      };
    }
    case ENotificationMessageType.PartnerAddedMessage:
    case ENotificationMessageType.PartnerToVerificationMessage: {
      const { partnerId, partnerName } = msg;

      return {
        status: getNotificationMessageStatus(type),
        title: getNotificationMessageTitle(type, null),
        text: `${partnerId}\n${partnerName}`,
      };
    }
    default:
      const { title, description } = msg;

      return {
        status: getNotificationMessageStatus(type),
        title,
        text: description,
      };
  }
};

const getNotificationMessageExtraText = (type: ENotificationMessageType): string => {
  switch (type) {
    case ENotificationMessageType.OfferToModerationMessage:
    case ENotificationMessageType.OfferActivatedMessage:
    case ENotificationMessageType.OfferUpcomingMessage:
    case ENotificationMessageType.OfferPausedByAdminMessage:
    case ENotificationMessageType.OfferRejectedMessage:
    case ENotificationMessageType.PartnerAddedMessage:
    case ENotificationMessageType.PartnerToVerificationMessage:
      return ``;
    case ENotificationMessageType.OfferPromotionFreshOutMessage:
    case ENotificationMessageType.OfferPromotionsSellingOut:
      return `\nЕсли вы хотите пополнить базу кодов предложения, пожалуйста, перейдите в настройки.`;
    case ENotificationMessageType.OfferExpiredMessage:
      return `\nЕсли вы хотите продлить срок действия, перейдите в настройки`;
    default:
      return '';
  }
};

enum ELinguisticCase {
  Nominative = 'nominative',
  Genitive = 'genitive',
}

const corpOfferText = {
  [ELinguisticCase.Nominative]: 'корпоративное',
  [ELinguisticCase.Genitive]: 'корпоративного',
};

const tradeOfferText = {
  [ELinguisticCase.Nominative]: 'торговое',
  [ELinguisticCase.Genitive]: 'торгового',
};

const productOfferText = {
  [ELinguisticCase.Nominative]: 'товарное',
  [ELinguisticCase.Genitive]: 'товарного',
};

interface GetOfferNameText {
  readonly offerType: Nullable<EOfferType>;
  readonly linguisticCase?: ELinguisticCase;
  readonly firstUpper?: boolean;
}

const getOfferNameText = (props: GetOfferNameText): string => {
  const { offerType, linguisticCase = ELinguisticCase.Nominative, firstUpper = false } = props;

  let result;

  switch (offerType) {
    case EOfferType.Corp:
      result = corpOfferText[linguisticCase];
      break;
    case EOfferType.Trade:
      result = tradeOfferText[linguisticCase];
      break;
    case EOfferType.Product:
      result = productOfferText[linguisticCase];
      break;
    default:
      result = '';
  }
  return firstUpper ? capitalizeFirstLetter(result) : result;
};

const getNotificationMessageTitle = (type: ENotificationMessageType, offerType: Nullable<EOfferType>) => {
  switch (type) {
    case ENotificationMessageType.OfferToModerationMessage:
      return `Создано новое ${getOfferNameText({ offerType })} предложение и ожидает модерации.`;
    case ENotificationMessageType.OfferActivatedMessage:
      return `${getOfferNameText({ offerType, firstUpper: true })} предложение опубликовано.`;
    case ENotificationMessageType.OfferUpcomingMessage:
      return `${getOfferNameText({
        offerType,
        firstUpper: true,
      })} предложение прошло модерацию и будет опубликовано в срок.`;
    case ENotificationMessageType.OfferPromotionFreshOutMessage:
      return `У ${getOfferNameText({
        offerType,
        linguisticCase: ELinguisticCase.Genitive,
      })} предложения заканчились промокоды/коды ваучера.`;
    case ENotificationMessageType.OfferExpiredMessage:
      return `Срок действия ${getOfferNameText({
        offerType,
        linguisticCase: ELinguisticCase.Genitive,
      })} предложения истек.`;
    case ENotificationMessageType.OfferRejectedMessage:
      return `${getOfferNameText({ offerType, firstUpper: true })} предложение отклонено.`;
    case ENotificationMessageType.OfferPausedByAdminMessage:
      return `${getOfferNameText({ offerType, firstUpper: true })} приостановлено администратором.`;
    case ENotificationMessageType.OfferPromotionsSellingOut:
      return `У ${getOfferNameText({
        offerType,
        linguisticCase: ELinguisticCase.Genitive,
      })} предложения заканчиваются промокоды/коды ваучера.`;
    case ENotificationMessageType.PartnerAddedMessage:
      return 'Поступила новая заявка на регистрацию партнёра';
    case ENotificationMessageType.PartnerToVerificationMessage:
      return 'Создана новая заявка на регистрацию партнёра и ожидает модерации';
    default:
      return '';
  }
};

const getNotificationMessageStatus = (type: ENotificationMessageType): ENoticeStatus => {
  switch (type) {
    case ENotificationMessageType.OfferToModerationMessage:
    case ENotificationMessageType.OfferActivatedMessage:
    case ENotificationMessageType.OfferUpcomingMessage:
    case ENotificationMessageType.PartnerAddedMessage:
    case ENotificationMessageType.PartnerToVerificationMessage:
      return ENoticeStatus.Success;
    case ENotificationMessageType.OfferExpiredMessage:
      return ENoticeStatus.Info;
    case ENotificationMessageType.OfferRejectedMessage:
    case ENotificationMessageType.OfferPausedByAdminMessage:
    case ENotificationMessageType.OfferPromotionsSellingOut:
      return ENoticeStatus.Warning;
    case ENotificationMessageType.OfferPromotionFreshOutMessage:
      return ENoticeStatus.Error;
    default:
      return ENoticeStatus.Info;
  }
};
