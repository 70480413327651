import { Nullable } from '../../../../domain/model/types';
import { MpUserData } from '../../../../domain/model/user';
import { OnChangeObjectAttribute } from '../../../types';
import { ValidationResult } from '../../../utils/validation';
import { EPartnerEmployeeStep } from '../utils';
import { Wrapper } from './controls';
import PartnerEmployeeEditStepProfile from './steps/profile';

interface PartnerEmployeeEditProps {
  readonly step: EPartnerEmployeeStep;
  readonly partnerEmployee: MpUserData;
  readonly validationProfile: Nullable<ValidationResult<MpUserData>>;
  readonly isValidProfile: boolean;
  readonly onChangeAttribute: OnChangeObjectAttribute<MpUserData>;
}

const PartnerEmployeeEdit = (props: PartnerEmployeeEditProps) => {
  const { step, partnerEmployee, isValidProfile, validationProfile, onChangeAttribute } = props;

  return (
    <Wrapper>
      {step === EPartnerEmployeeStep.Profile && (
        <PartnerEmployeeEditStepProfile
          partnerEmployee={partnerEmployee}
          isValid={isValidProfile}
          validation={validationProfile}
          onChangeAttribute={onChangeAttribute}
        />
      )}
    </Wrapper>
  );
};

export default PartnerEmployeeEdit;
