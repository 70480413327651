import { forwardRef, ReactNode } from 'react';
import { RootState } from '../../../../../data/store/store';
import { EntityLink } from '../../../../../domain/model';
import { PanelActions } from '../../../../types';
import RenderDecoratorContainer from '../../renderDecoratorContainer';
import { TableActionsUpdaterDecorator } from './decorator';
import TableActions from './index';

interface TableActionsContainerProps<T extends EntityLink, A extends string> {
  readonly entityLink: T;
  readonly actions: PanelActions<A>;
  readonly isFetchingSelector: (store: RootState) => boolean;
  readonly onExecute: (action: A, entityLink: T) => void;
}

type TableActionsContainerType = <T extends EntityLink, A extends string>(
  props: TableActionsContainerProps<T, A>
) => ReactNode;

const TableActionsContainer: TableActionsContainerType = forwardRef((props, ref: any) => {
  const { entityLink, actions, isFetchingSelector, onExecute } = props;

  return (
    <RenderDecoratorContainer
      decorators={[
        <TableActionsUpdaterDecorator
          key={1}
          isFetchingSelector={isFetchingSelector}
        />,
      ]}
    >
      <TableActions
        entityLink={entityLink}
        actions={actions}
        onExecute={onExecute}
        isExecuting={false}
      />
    </RenderDecoratorContainer>
  );
});

export default TableActionsContainer;
