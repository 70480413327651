import { MpUser } from '../../../../domain/model/user';
import { EPartnerEmployeeStep } from '../utils';
import { ColumnWrapper, Wrapper } from './controls';
import PartnerEmployeeDetailsStepProfile from './steps/profile';

interface PartnerEmployeeDetailsProps {
  readonly step: EPartnerEmployeeStep;
  readonly partnerEmployee: MpUser;
}

const PartnerEmployeeDetails = (props: PartnerEmployeeDetailsProps) => {
  const { step, partnerEmployee } = props;

  return (
    <Wrapper>
      <ColumnWrapper>
        {step === EPartnerEmployeeStep.Profile && (
          <PartnerEmployeeDetailsStepProfile partnerEmployee={partnerEmployee} />
        )}
      </ColumnWrapper>
    </Wrapper>
  );
};

export default PartnerEmployeeDetails;
