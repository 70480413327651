import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { FormControlLabel, Typography } from '@mui/material';
import { LeafItemWrapper } from './controls';
import { ActivityTypeSelectorItemProps } from './types';

type ActivityTypeSelectorLeafItemProps = ActivityTypeSelectorItemProps;

export const ActivityTypeSelectorLeafItem = (props: ActivityTypeSelectorLeafItemProps) => {
  const { selected, disabled, children, onChange } = props;

  return (
    <LeafItemWrapper
      disabled={disabled}
      onClick={() => !disabled && onChange(!selected)}
    >
      <FormControlLabel
        control={selected ? <CheckBox color='primary' /> : <CheckBoxOutlineBlank color='secondary' />}
        label={<Typography>{children}</Typography>}
      />
    </LeafItemWrapper>
  );
};
