import { MasterDetailsLayout } from '../../../../layouts/masterDetails/container';
import { CommonPreloaderDecorator } from '../../../general/decorators/preloader';
import { CommonUpdaterDecorator } from '../../../general/decorators/updater';
import MasterDetailsContainer from '../../../general/master/details/container';
import {
  TradeOfferCategoriesEditDialogsAdapter,
  TradeOfferCategoriesEditDictionaryAdapter,
  TradeOfferCategoriesEditHeaderAdapter,
  TradeOfferCategoriesEditStepperAdapter,
} from './adapters';
import { TradeOfferCategoriesEditInitializer } from './initializer';
import { TradeOfferCategoriesEditHandlersProvider } from './providers';
import {
  tradeOfferCategoriesEditIsLoadingSelector,
  tradeOfferCategoriesEditIsPreloadingSelector,
} from './store/selectors';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import ForbiddenComponent from '../../../../components/common/forbidden';
import { TradeOfferCategoriesEditAccess } from './types';

const TradeOfferCategoriesEditContainer = () => {
  const { tradeCategories } = useCurrentUser().accessMatrix;
  const access: TradeOfferCategoriesEditAccess = {
    canCreate: tradeCategories.create,
    canEdit: tradeCategories.edit,
    canDelete: tradeCategories.delete,
  };

  if (!tradeCategories.view) {
    return <ForbiddenComponent />;
  }

  return (
    <MasterDetailsContainer
      layout={MasterDetailsLayout}
      layoutProps={{
        stepper: TradeOfferCategoriesEditStepperAdapter,
        header: TradeOfferCategoriesEditHeaderAdapter,
        decorators: [
          <CommonPreloaderDecorator
            key='preloader'
            isFetchingSelector={tradeOfferCategoriesEditIsPreloadingSelector}
          />,
          <CommonUpdaterDecorator
            key='updater'
            isFetchingSelector={tradeOfferCategoriesEditIsLoadingSelector}
          />,
        ],
      }}
      initializer={TradeOfferCategoriesEditInitializer}
      providers={[
        {
          name: 'handlers',
          factory: TradeOfferCategoriesEditHandlersProvider,
        },
      ]}
      commonProps={{
        access,
      }}
    >
      <TradeOfferCategoriesEditDictionaryAdapter access={access} />

      <TradeOfferCategoriesEditDialogsAdapter />
    </MasterDetailsContainer>
  );
};

export default TradeOfferCategoriesEditContainer;
