import { ListItemText, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { PlusIcon } from '../../../../../../icons';
import AppSvgIcon from '../../../../images/icon';
import { DictionaryLevelItemProps } from '../../../types';
import { ActionButton, EndAdornmentWrapper, ListItem } from '../controls';
import { ListItemAddContainer } from './controls';

type DictionaryAddItemProps<T> = DictionaryLevelItemProps<T> & {
  readonly fixed?: boolean;
  readonly icon?: ReactNode;
  readonly onClick: () => void;
  readonly children?: any;
};

export const DictionaryAddItem = <T extends object>(props: DictionaryAddItemProps<T>) => {
  const { children, icon, fixed, onClick } = props;
  return (
    <ListItemAddContainer //todo upgrade
      {...props}
      fixed={fixed}
    >
      <ListItem
        disableRipple
        onClick={onClick}
      >
        <ListItemText primary={<Typography color='primary'>{children}</Typography>} />
        <EndAdornmentWrapper>
          <ActionButton
            disableRipple
            disabled
            size='small'
            onClick={onClick}
          >
            {icon || (
              <AppSvgIcon
                icon={PlusIcon}
                color='primary'
              />
            )}
          </ActionButton>
        </EndAdornmentWrapper>
      </ListItem>
    </ListItemAddContainer>
  );
};
