import { StepWrapper } from '@features/adCampaign/details/controls';
import AdCampaignDetailsStepKeywords from '@features/adCampaign/details/steps/keywords';
import { useSelector } from 'react-redux';
import { EAdCampaignStep } from '../../types';
import { useContextParams } from '../hooks/useContextParams';
import { adCampaignDetailsAdCampaignSelector } from '../store/selectors';

const AdCampaignDetailsStepKeywordsAdapter = () => {
  const { step } = useContextParams();

  const adCampaign = useSelector(adCampaignDetailsAdCampaignSelector);

  if (!adCampaign) {
    return null;
  }

  return step === EAdCampaignStep.Keywords ? (
    <StepWrapper>
      <AdCampaignDetailsStepKeywords adCampaign={adCampaign} />
    </StepWrapper>
  ) : null;
};

export default AdCampaignDetailsStepKeywordsAdapter;
