import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getTradeOfferActionsOfferIsProcessingSelector, tradeOfferActionsGetActionSelector } from '../store/selectors';
import { useTradeOfferActions } from '../useActions';
import { SportOption, TradeOffer } from '@/domain';
import { nsiCustomOfferPausedReasonsSelector } from '@features/general/nsi/store/selectors';
import OfferReasonsDialog from '@features/general/offer/components/reasonsDialog';
import { TradeOfferStateKeyDialogs } from '../store/slice';

const nameDialog: TradeOfferStateKeyDialogs = 'pause';

const TradeOfferActionsDialogPauseAdapter = () => {
  const handlers = useTradeOfferActions();
  const { data: object, notification } = useSelector(tradeOfferActionsGetActionSelector(nameDialog));
  const pauseReasons = useSelector(nsiCustomOfferPausedReasonsSelector);
  const isProcessing = useSelector(getTradeOfferActionsOfferIsProcessingSelector(object?.id ?? null));

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onPause = useCallback(
    (offer: TradeOffer, reason: SportOption, comment?: string) => {
      handlers.onPause(offer, reason, comment, notification).then(() => {
        onCloseDialog();
      });
    },
    [handlers.onPause, notification]
  );

  return (
    object && (
      <OfferReasonsDialog
        offer={object}
        title='Приостановка торгового предложения'
        actionText='Приостановить'
        isFetching={isProcessing}
        reasons={pauseReasons}
        onAction={onPause}
        onClose={onCloseDialog}
      />
    )
  );
};

export default TradeOfferActionsDialogPauseAdapter;
