import { CaseReducer, createSelector, createSlice, PayloadAction, Selector, SliceCaseReducers } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../../../../../data/store/store';
import { UUID } from '../../../../../domain/model/types';
import { EMainMenuType } from '../enums';

interface MenuState {
  readonly guid: UUID;
  readonly opened: {
    readonly [guid: string]: boolean;
  };
}

export interface MainMenusState {
  readonly menus: {
    readonly [EMainMenuType.Sport]: MenuState;
    readonly [EMainMenuType.Partner]: MenuState;
    readonly [EMainMenuType.MpCommon]: MenuState;
  };
}

type Reducer<T = undefined> = CaseReducer<MainMenusState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<MainMenusState> {
  mainMenuAddToOpened: Reducer<{ menu: EMainMenuType; itemId: UUID }>;
}

const slice = createSlice<MainMenusState, Reducers, 'main/menus'>({
  name: 'main/menus',
  initialState: {
    menus: {
      [EMainMenuType.Sport]: { guid: uuidv4(), opened: {} },
      [EMainMenuType.Partner]: { guid: uuidv4(), opened: {} },
      [EMainMenuType.MpCommon]: { guid: uuidv4(), opened: {} },
    },
  },
  reducers: {
    mainMenuAddToOpened(state, { payload }) {
      const { menu, itemId } = payload;
      state.menus[menu].opened[itemId] = !state.menus[menu].opened[itemId];
    },
  },
});

export const { mainMenuAddToOpened } = slice.actions;

export default slice.reducer;

const menusSelector: Selector<RootState, MainMenusState> = state => state.mainView.menus;
const menuSelector: Selector<RootState, EMainMenuType, [EMainMenuType]> = (state, menuType) => menuType;

export const createMainMenuOpenedItemsSelector = createSelector(
  menusSelector,
  menuSelector,
  (mainMenus, menuType) => mainMenus.menus[menuType].opened
);

export const createMainMenuGuidSelector = createSelector(
  menusSelector,
  menuSelector,
  (mainMenus, menuType) => mainMenus.menus[menuType].guid
);
