import { Fade, Grid, IconButton } from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { EditIcon } from '../../../../../icons';
import { MPFormInputProps } from '../../../../../theme/ui-kit/input';
import AppSvgIcon from '../../../images/icon';
import ContentLoader from '../../../loader';
import { EditInput, ViewWrapper } from './controls';

interface DataTableCellEditableProps {
  /* если указывать снаружи ditMode, то получим контролируемый снаружи компонент, если нет - весь контроль внутри */
  readonly editMode?: boolean;
  readonly align?: 'left' | 'right';
  readonly textFieldProps?: MPFormInputProps;
  readonly hideEditIcon?: boolean;
  readonly isFetching?: boolean;
  readonly children?: React.ReactNode;
  readonly onChangeEditMode?: (isEditMode: boolean, event: SyntheticEvent) => void;
  readonly onChange: (value: string) => boolean;
}

interface DataTableCellEditableViewProps {
  readonly hideEditIcon?: boolean;
  readonly align?: 'left' | 'right';
  readonly isFetching?: boolean;
  readonly children?: React.ReactNode;
  readonly onShowEditMode: (event: React.SyntheticEvent) => void;
}

interface DataTableCellEditableEditProps {
  readonly textFieldProps?: MPFormInputProps;
  readonly onShowViewMode: (event: React.SyntheticEvent) => void;
  readonly onChange: (value: string) => boolean;
}

const DataTableCellEditableView = (props: DataTableCellEditableViewProps) => {
  const { hideEditIcon, align = 'left', isFetching, onShowEditMode, children } = props;

  const onClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onShowEditMode(event);
  };

  const justifyContent = align === 'right' ? 'end' : 'start';

  return (
    <ViewWrapper
      container
      justifyContent={justifyContent}
      alignItems='center'
    >
      <Grid item>{children}</Grid>
      {!hideEditIcon && !isFetching && (
        <Grid item>
          <IconButton
            size='small'
            onClick={onClick}
          >
            <AppSvgIcon
              color='primary'
              fontSize='small'
              icon={EditIcon}
            />
          </IconButton>
        </Grid>
      )}
      {isFetching && (
        <ContentLoader
          size={20}
          verticalPosition='flex-end'
        />
      )}
    </ViewWrapper>
  );
};

const DataTableCellEditableEdit = ({
  textFieldProps = {},
  onShowViewMode,
  onChange,
}: DataTableCellEditableEditProps) => {
  const [value, setValue] = useState<string>((textFieldProps.value as string) ?? '');

  const onClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      onShowViewMode(event);
    } else if (event.key === 'Enter') {
      if (onChange(value)) {
        onShowViewMode(event);
      }
    }
  };

  return (
    <Fade in>
      <div>
        <EditInput
          {...textFieldProps}
          InputProps={{
            ...textFieldProps?.InputProps,
            autoFocus: true,
          }}
          value={value}
          onChange={event => setValue(event.target.value)}
          onClick={onClick}
          onBlur={onShowViewMode}
          onKeyDown={onKeyDown}
        />
      </div>
    </Fade>
  );
};

const DataTableCellEditable = (props: DataTableCellEditableProps) => {
  const {
    editMode,
    align = 'left',
    textFieldProps,
    hideEditIcon,
    isFetching,
    children,
    onChangeEditMode,
    onChange,
  } = props;

  const [editModeInternal, setEditModeInternal] = useState<boolean>(editMode ?? false);

  const onChangeInternal = (value: string) => {
    return onChange(value);
  };

  useEffect(() => {
    if (editMode !== null && editMode !== undefined) {
      setEditModeInternal(editMode);
    }
  }, [editMode]);

  return (
    <>
      {editModeInternal ? (
        <DataTableCellEditableEdit
          textFieldProps={textFieldProps}
          onShowViewMode={event => (onChangeEditMode ? onChangeEditMode(false, event) : setEditModeInternal(false))}
          onChange={onChangeInternal}
        />
      ) : (
        <DataTableCellEditableView
          hideEditIcon={hideEditIcon}
          align={align}
          isFetching={isFetching}
          onShowEditMode={event => (onChangeEditMode ? onChangeEditMode(true, event) : setEditModeInternal(true))}
        >
          {children}
        </DataTableCellEditableView>
      )}
    </>
  );
};

export default DataTableCellEditable;
