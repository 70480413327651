import BookingOrderTableCell, { BookingOrderTableCellCommonProps } from './index';
import moment from 'moment';
import { EDateTimeFormat } from '../../../../../domain/model/formats';

const BookingOrderTableCellCreateAt = (props: BookingOrderTableCellCommonProps) => {
  const {
    bookingOrder: { createdAt },
  } = props;

  return (
    <BookingOrderTableCell
      {...props}
      value={moment(createdAt).format(EDateTimeFormat.DisplayDefault)}
    />
  );
};

export default BookingOrderTableCellCreateAt;
