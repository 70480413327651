import { AdCampaignCreate, BookingOfferShort, CorpOffer, EOfferType, TradeOffer } from '@/domain';
import AdCampaignBookingOfferCollectionContainer from '@features/adCampaign/create/collection/booking';
import AdCampaignCorpOfferCollectionContainer from '@features/adCampaign/create/collection/corp';
import AdCampaignTradeOfferCollectionContainer from '@features/adCampaign/create/collection/trade';
import { adCampaignOfferMaxCount } from '@features/adCampaign/utils/common';
import { FormHelperText, Grid } from '@mui/material';
import { AdCampaignCreateFieldCommonProps } from '../../types';

const fieldName = 'offers';

interface AdCampaignCreateFieldOffersProps extends AdCampaignCreateFieldCommonProps<typeof fieldName> {
  readonly guid: UUID;
  readonly adCampaign: AdCampaignCreate;
}

const AdCampaignCreateFieldOffers = (props: AdCampaignCreateFieldOffersProps) => {
  const { value, guid, adCampaign, validation, onChangeAttribute, onValidateAttribute } = props;

  const onChangeOffers = (newValue: AdCampaignCreate[typeof fieldName]) => {
    onChangeAttribute(fieldName, newValue);
    onValidateAttribute(fieldName);
  };

  return (
    <>
      {adCampaign.offerType === EOfferType.Corp && (
        <AdCampaignCorpOfferCollectionContainer
          guid={guid}
          offers={(adCampaign.offers ?? []) as CorpOffer[]}
          adCampaign={adCampaign}
          selectedMaxCount={adCampaignOfferMaxCount}
          onChange={onChangeOffers}
        />
      )}
      {adCampaign.offerType === EOfferType.Trade && (
        <AdCampaignTradeOfferCollectionContainer
          guid={guid}
          offers={(value ?? []) as TradeOffer[]}
          adCampaign={adCampaign}
          selectedMaxCount={adCampaignOfferMaxCount}
          onChange={onChangeOffers}
        />
      )}
      {adCampaign.offerType === EOfferType.Booking && (
        <AdCampaignBookingOfferCollectionContainer
          guid={guid}
          offers={(value ?? []) as BookingOfferShort[]}
          adCampaign={adCampaign}
          selectedMaxCount={adCampaignOfferMaxCount}
          onChange={onChangeOffers}
        />
      )}
      {validation?.hasError && (
        <Grid item>
          <FormHelperText error>{validation?.message}</FormHelperText>
        </Grid>
      )}
    </>
  );
};

export default AdCampaignCreateFieldOffers;
