import { Grid, IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { SyntheticEvent } from 'react';
import { CommonFileInfo } from '../../../../../../domain/model';
import { ContentTypeImageIcon } from '../../../../../icons';
import { convertBytesToUnitText } from '../../../../../utils/files';
import { Content, Wrapper } from './controls';

interface OfferFileInfoProps {
  readonly fileInfo: CommonFileInfo;
  readonly success?: boolean;
  readonly error?: boolean;
  readonly onRemove?: () => void;
  readonly icon?: JSX.Element;
}

const OfferFileInfo = (props: OfferFileInfoProps) => {
  const { fileInfo, success = false, error = false, onRemove, icon = <ContentTypeImageIcon /> } = props;

  const onRemoveInner = (event: SyntheticEvent) => {
    event.preventDefault();
    onRemove?.();
  };

  const name = fileInfo.name;
  const size = convertBytesToUnitText(fileInfo.size);

  return (
    <Wrapper
      error={error}
      success={success}
    >
      {icon}
      <Content
        container
        alignItems='stretch'
        direction='column'
      >
        <Grid
          item
          xs
          zeroMinWidth
        >
          <Typography
            variant='body1'
            noWrap
          >
            {name}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant='body2'
            color='textSecondary'
          >
            {size}
          </Typography>
        </Grid>
      </Content>
      {onRemove && (
        <IconButton onClick={onRemoveInner}>
          <ClearIcon
            color='primary'
            fontSize='small'
          />
        </IconButton>
      )}
    </Wrapper>
  );
};

export default OfferFileInfo;
