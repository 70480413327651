import styled from '@emotion/styled';

export const ContentWrapper = styled.div`
  margin-bottom: ${p => p.theme.spacing(2)};
`;

export const ServiceStatusWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
