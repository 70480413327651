import { FC } from 'react';
import { useSelector } from 'react-redux';
import { BookingOfferEditFieldCommonProps } from '../../components/fieldsEdit/types';
import { BookingOfferView } from '../../types';
import {
  bookingOfferServiceManageBookingOfferFieldByNameSelector,
  bookingOfferServiceManageBookingOfferSelector,
  bookingOfferServiceManageIsFieldDisabledSelector,
  bookingOfferServiceManageIsFieldVisibleSelector,
} from '../store/selectros';

interface BookingOfferCreateFieldAdapterProps<Key extends keyof BookingOfferView> {
  readonly name: Key;
  readonly component: FC<BookingOfferEditFieldCommonProps<Key>>;
}

export const BookingOfferServiceManageFieldAdapter = <Key extends keyof BookingOfferView>(
  props: BookingOfferCreateFieldAdapterProps<Key>
) => {
  const { name, component: Component } = props;

  const bookingOffer = useSelector(bookingOfferServiceManageBookingOfferSelector);
  const value = useSelector(bookingOfferServiceManageBookingOfferFieldByNameSelector(name));
  const visible = useSelector(bookingOfferServiceManageIsFieldVisibleSelector(name));
  const disabled = useSelector(bookingOfferServiceManageIsFieldDisabledSelector(name));

  return bookingOffer && visible ? (
    <Component
      value={value}
      disabled={disabled}
      onChangeAttribute={() => {}}
      onValidateAttribute={() => {}}
      validation={null}
    />
  ) : null;
};
