import BookingOrderTableCell, { BookingOrderTableCellCommonProps } from './index';

const BookingOrderTableCellObjectName = (props: BookingOrderTableCellCommonProps) => {
  const {
    bookingOrder: {
      offer: { name },
    },
  } = props;

  return (
    <BookingOrderTableCell
      {...props}
      value={name}
    />
  );
};

export default BookingOrderTableCellObjectName;
