import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Address } from '../../../../../../../domain/model/address';
import { EPartnerOwnershipType } from '../../../../../../../domain/model/enums';
import { Nullable } from '../../../../../../../domain/model/types';
import { AddressHelper } from '../../../../../../utils/address';
import { getPartnerAddressLabelByOrgType } from '../../../../../general/partner/utils/common';

interface PartnerApplicationViewStepAddressProps {
  readonly address: Nullable<Address>;
  readonly orgType: Nullable<EPartnerOwnershipType>;
}

const PartnerApplicationViewStepAddress = (props: PartnerApplicationViewStepAddressProps) => {
  const { address, orgType } = props;

  return (
    <Grid
      container
      spacing={3}
      direction='column'
    >
      <Grid item>
        <Typography variant='h3'>{getPartnerAddressLabelByOrgType(orgType)}</Typography>
      </Grid>

      {address && (
        <>
          {address.id && (
            <Grid item>
              <Typography
                variant='body2'
                color='textSecondary'
              >
                Область / Республика / Край
              </Typography>
              <Typography>{new AddressHelper(address).getLocalityRegion()}</Typography>
            </Grid>
          )}
          <Grid item>
            <Typography
              variant='body2'
              color='textSecondary'
            >
              Адрес
            </Typography>
            <Typography>{new AddressHelper(address).getFullPathWithPostalCode()}</Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PartnerApplicationViewStepAddress;
