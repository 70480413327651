import { ClientOrg } from '@/domain/model/clientOrg';
import { ESortDirection } from '@/domain/model/enums';
import { getDataFilterValuesByStrategies } from '@/presentation/utils/filtering';
import { addSearchParamToLocation } from '@/routing/globalRouting';
import ClientOrgUserActionsContext from '@features/user/userClientOrg/actions/context';
import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { PaginationSize } from '../../../../../types';
import { ClientOrgUserTableFilterEditStrategy } from '../../filterUtils';
import { EClientOrgUserTableTab } from '../../types';
import { EClientOrgUserUrlParam } from '../entry';
import {
  clientOrgUsersDataReset,
  clientOrgUsersNeedRefreshWatcherIncrement,
  clientOrgUsersSetFilter,
  clientOrgUsersSetPage,
  clientOrgUsersSetPageSize,
  clientOrgUsersSetSort,
  clientOrgUsersSortReset,
} from '../store/slice';

export type UseClientOrgUsersTable = {
  readonly onTryCreate: (clientOrg: ClientOrg) => void;
  readonly onRefresh: () => void;
  readonly onResetSort: () => void;
  readonly onChangePage: (page: number) => void;
  readonly onChangeTab: (newTab: EClientOrgUserTableTab) => void;
  readonly onChangePageSize: (pageSize: PaginationSize) => void;
  readonly onChangeFilter: (strategies: ClientOrgUserTableFilterEditStrategy[]) => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
};

type UseClientOrgUsersTableHandlersProps = {
  readonly guid: UUID;
  readonly tab: EClientOrgUserTableTab;
};

export const useClientOrgUsersTableHandlers = ({
  guid,
  tab,
}: UseClientOrgUsersTableHandlersProps): UseClientOrgUsersTable => {
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const { onTryImport } = useContext(ClientOrgUserActionsContext);

  const onTryCreate = useCallback<UseClientOrgUsersTable['onTryCreate']>(onTryImport, [onTryImport]);

  const onRefresh = useCallback<UseClientOrgUsersTable['onRefresh']>(
    () => dispatch(clientOrgUsersNeedRefreshWatcherIncrement()),
    [dispatch]
  );

  const onChangeFilter = useCallback<UseClientOrgUsersTable['onChangeFilter']>(
    (strategies: ClientOrgUserTableFilterEditStrategy[]) => {
      dispatch(clientOrgUsersSetFilter(getDataFilterValuesByStrategies(strategies)));
    },
    [dispatch]
  );

  const onResetSort = useCallback<UseClientOrgUsersTable['onResetSort']>(
    () => dispatch(clientOrgUsersSortReset()),
    [dispatch]
  );

  const onChangePage = useCallback<UseClientOrgUsersTable['onChangePage']>(
    (page: number) => {
      dispatch(clientOrgUsersSetPage({ pageNumber: page }));
    },
    [dispatch]
  );

  const onChangePageSize = useCallback<UseClientOrgUsersTable['onChangePageSize']>(
    (pageSize: PaginationSize) => {
      dispatch(clientOrgUsersSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onChangeTab = useCallback<UseClientOrgUsersTable['onChangeTab']>(
    (newTab: EClientOrgUserTableTab) => {
      if (newTab !== tab) {
        dispatch(clientOrgUsersDataReset());
        history.replace(
          addSearchParamToLocation({
            location,
            param: EClientOrgUserUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [dispatch, history, location, tab, guid]
  );

  const onChangeSort = useCallback<UseClientOrgUsersTable['onChangeSort']>(
    (name: string, direction: ESortDirection) => {
      dispatch(
        clientOrgUsersSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  return {
    onTryCreate,
    onResetSort,
    onChangeSort,
    onChangeFilter,
    onChangePage,
    onChangePageSize,
    onChangeTab,
    onRefresh,
  };
};
