import { ESortDirection, Nullable, ProductOffer } from '@/domain';
import { DataTableLoader } from '@components/common/table/loader';
import ProductTableCellTargetClientOrgs from '@features/products/table/cells/targetClientOrgs';
import ProductTableCellTargetExternalUsers from '@features/products/table/cells/targetExternalUsers';
import { FC, useCallback } from 'react';
import DataTable, { DataTableMetadata } from '../../../components/common/table';
import TableActions from '../../../components/common/table/actions';
import { ProductActionProcess, ProductActions, ProductActionTableCommonType } from '../types';
import ProductTableCellAdapter from './adapters/cell';
import ProductTableCellAttributeItem from './cells/attributeItem';
import ProductTableCellBrand from './cells/brand';
import ProductTableCellCategory from './cells/category';
import ProductTableCellCreatedAt from './cells/createsAt';
import ProductTableCellImages from './cells/images';
import ProductTableCellLastStatusAuthor from './cells/lastStatusAuthor';
import ProductTableCellModerator from './cells/moderator';
import ProductTableCellName from './cells/name';
import ProductTableCellOriginalPrice from './cells/originalPrice';
import ProductTableCellPartnerSKU from './cells/partnerSKU';
import ProductTableCellPausedReason from './cells/pausedReason';
import ProductTableCellPrice from './cells/price';
import ProductTableCellProducerSKU from './cells/producerSKU';
import ProductTableCellProductDesk from './cells/productDesk';
import ProductTableCellStock from './cells/stock';
import ProductTableCellTargetLocalities from './cells/targetLocalities';
import { Wrapper } from './controls';
import { productsIsFetchingSelector } from './store/selectors';
import { EProductTableColumn, ProductActionCall } from './utils';

type ProductTableProps = {
  readonly metadata: DataTableMetadata<EProductTableColumn>;
  readonly products: ProductOffer[];
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly selected: ProductOffer[];
  readonly getCommonActions: (product: ProductOffer) => ProductActions<ProductActionTableCommonType>;
  readonly getActionProcesses: (product: ProductOffer) => ProductActionProcess[];
  readonly onProductAction: (call: ProductActionCall) => void;
  readonly onClick: (item: ProductOffer) => void;
  readonly onChangeMetadata: (metadata: DataTableMetadata<EProductTableColumn>) => void;
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onProductSelect: Nullable<(product: ProductOffer, selected: boolean) => void>;
  readonly onAllProductsSelect: (selected: boolean) => void;
};

const ProductTable: FC<ProductTableProps> = props => {
  const {
    metadata,
    sort,
    selected,
    products,
    getCommonActions,
    getActionProcesses,
    onProductAction,
    onClick,
    onChangeMetadata,
    onRequestSort,
    onProductSelect,
    onAllProductsSelect,
  } = props;

  const rows = products.map((product, index) => {
    return {
      [EProductTableColumn.DeskNumber]: (
        <ProductTableCellAdapter
          index={index}
          component={ProductTableCellProductDesk}
        />
      ),
      [EProductTableColumn.Image]: <ProductTableCellImages productOffer={product} />,
      [EProductTableColumn.PartnerSKU]: (
        <ProductTableCellAdapter
          index={index}
          component={ProductTableCellPartnerSKU}
        />
      ),
      [EProductTableColumn.ProducerSKU]: (
        <ProductTableCellAdapter
          index={index}
          component={ProductTableCellProducerSKU}
        />
      ),
      [EProductTableColumn.Category]: (
        <ProductTableCellAdapter
          index={index}
          component={ProductTableCellCategory}
        />
      ),
      [EProductTableColumn.Name]: (
        <ProductTableCellAdapter
          index={index}
          component={ProductTableCellName}
        />
      ),
      [EProductTableColumn.Price]: (
        <ProductTableCellAdapter
          index={index}
          component={ProductTableCellPrice}
        />
      ),
      [EProductTableColumn.OriginalPrice]: (
        <ProductTableCellAdapter
          index={index}
          component={ProductTableCellOriginalPrice}
        />
      ),
      [EProductTableColumn.Stock]: (
        <ProductTableCellAdapter
          index={index}
          component={ProductTableCellStock}
        />
      ),
      [EProductTableColumn.CreatedAt]: (
        <ProductTableCellAdapter
          index={index}
          component={ProductTableCellCreatedAt}
        />
      ),
      [EProductTableColumn.Moderator]: (
        <ProductTableCellAdapter
          index={index}
          component={ProductTableCellModerator}
        />
      ),
      [EProductTableColumn.PausedReason]: (
        <ProductTableCellAdapter
          index={index}
          component={ProductTableCellPausedReason}
        />
      ),
      [EProductTableColumn.LastStatusAuthor]: (
        <ProductTableCellAdapter
          index={index}
          component={ProductTableCellLastStatusAuthor}
        />
      ),
      [EProductTableColumn.Brand]: (
        <ProductTableCellAdapter
          index={index}
          component={ProductTableCellBrand}
        />
      ),
      [EProductTableColumn.TargetLocalities]: (
        <ProductTableCellAdapter
          index={index}
          component={ProductTableCellTargetLocalities}
        />
      ),
      [EProductTableColumn.TargetExternalUsers]: (
        <ProductTableCellAdapter
          index={index}
          component={ProductTableCellTargetExternalUsers}
        />
      ),
      [EProductTableColumn.TargetClientOrgs]: (
        <ProductTableCellAdapter
          index={index}
          component={ProductTableCellTargetClientOrgs}
        />
      ),
      [EProductTableColumn.Attributes]: (
        <ProductTableCellAdapter
          index={index}
          component={ProductTableCellAttributeItem}
        />
      ),
      data: product,
    };
  });

  const onTableCommonAction = useCallback(
    (action: ProductActionTableCommonType, product: ProductOffer) => onProductAction({ action, product }),
    [onProductAction]
  );

  const selectable = !!onProductSelect;

  return (
    <Wrapper>
      <DataTable<ProductOffer, EProductTableColumn>
        width='auto'
        overflowX='inherit'
        metadata={metadata}
        rowActions
        selectable={selectable}
        selected={selected}
        rows={rows}
        sort={{ column: sort.column, direction: sort.direction }}
        loader={<DataTableLoader selector={productsIsFetchingSelector} />}
        onAllRowsSelect={(event, selected) => onAllProductsSelect(selected)}
        onRowSelect={(event, { data }, selected) => onProductSelect?.(data, selected)}
        getRowActions={product => (
          <TableActions<ProductOffer, ProductActionTableCommonType>
            entityLink={product}
            actions={getCommonActions(product)}
            onExecute={onTableCommonAction}
            isExecuting={getActionProcesses(product).some(process => process.isFetching)}
          />
        )}
        onChangeMetadata={onChangeMetadata}
        onRowClick={(event, cell, { data }) => onClick(data)}
        onSort={(event, column, direction) => onRequestSort(column, direction)}
      />
    </Wrapper>
  );
};

export default ProductTable;
