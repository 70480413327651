import { combineReducers } from '@reduxjs/toolkit';
import actions, { SocialPackageActionsState } from './actions/store/slice';
import list, { SocialPackageListState } from './table/store/slice';

interface SocialPackageState {
  actions: SocialPackageActionsState;
  list: SocialPackageListState;
}

export default combineReducers<SocialPackageState>({
  actions,
  list,
});
