import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import { viewConfig } from '../../../../theme/viewConfig';

export const Wrapper = styled(Grid)`
  margin-bottom: ${p => p.theme.spacing()};
`;

export const BreadcrumbsWrapper = styled.div`
  min-height: 2rem;

  display: flex;
  align-items: flex-end;
`;

export const TitleWrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  padding-bottom: ${p => p.theme.spacing(2)};

  border-bottom: 1px solid ${p => p.theme.palette.disabled.background};
`;
