import { EProductOrderTableTab } from '../../types';

export type UseProductOrderTableConfig = {
  readonly tabs: EProductOrderTableTab[];
  readonly tabsForCounts: EProductOrderTableTab[];
  readonly defaultTab: EProductOrderTableTab;
};

export function useProductOrderTableConfig(): UseProductOrderTableConfig {
  const tabs = [
    EProductOrderTableTab.New,
    EProductOrderTableTab.Confirmed,
    EProductOrderTableTab.Paid,
    EProductOrderTableTab.Sent,
    EProductOrderTableTab.Given,
    EProductOrderTableTab.Cancelled,
    EProductOrderTableTab.PartiallyReturned,
    EProductOrderTableTab.Returned,
  ];
  const tabsForCounts = [
    EProductOrderTableTab.New,
    EProductOrderTableTab.Confirmed,
    EProductOrderTableTab.Paid,
    EProductOrderTableTab.Sent,
    EProductOrderTableTab.Given,
    EProductOrderTableTab.Cancelled,
    EProductOrderTableTab.PartiallyReturned,
    EProductOrderTableTab.Returned,
  ];
  const defaultTab = EProductOrderTableTab.Given;

  return {
    tabs,
    tabsForCounts,
    defaultTab,
  };
}
