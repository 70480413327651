import {
  CmsComponent,
  CmsContainer,
  CmsContainerRequest,
  DataFilterQueryDslOperator,
  ECmsBannerLinkObjectType,
  ECmsCollectionLinkObjectType,
  ECmsContainerStatus,
  ECmsContainerType,
  ECmsLinkObjectType,
  EOfferType,
  ETargetType,
  Nullable,
  SportOptionTyped,
  Target,
} from '@/domain';
import { isTargetEmpty } from '../../../../utils/target';
import { CorpOfferTableFilterValues, ECorpOfferTableFilterItem } from '../../../corpOffer/filterUtils';
import { ETradeOfferTableFilterItem, TradeOfferTableFilterValues } from '../../../tradeOffer/filterUtils';
import { CmsContainerView, CmsLinkedObject } from '../../types';
import { CmsLinkedObjectBanner, CmsLinkedObjectBannerType } from '../../types/banner';
import { CmsLinkedObjectCollection } from '../../types/collection';
import { getCmsContainerComponentCountByType, getCmsLinkObjectType } from '../utils';

export const convertCmsContainerToCmsContainerView = (
  cmsContainer: CmsContainer,
  cmsLinkedObjects: CmsLinkedObject[]
): CmsContainerView => {
  const { target, filterSettings, ...withOutRuntimeAttributes } = cmsContainer;
  return {
    ...withOutRuntimeAttributes,
    ...target,
    resourcesSize: calculateCmsContainerResourcesSize(cmsLinkedObjects),
    offerType: filterSettings.offerType,
  };
};

export const calculateCmsContainerResourcesSize = (
  cmsLinkedObjects: CmsLinkedObject[]
): CmsContainerView['resourcesSize'] => {
  for (const cmsLinkedObject of cmsLinkedObjects) {
    if (cmsLinkedObject.type === ECmsLinkObjectType.Banner)
      return [cmsLinkedObject.banner.width ?? '', cmsLinkedObject.banner.height ?? ''];
  }
  return null;
};

export const convertCmsContainerViewToCmsContainerRequest = (
  cmsContainerView: CmsContainerView
): CmsContainerRequest => {
  const {
    targetGender,
    targetRoads,
    targetLocalities,
    targetOrgUnits,
    targetType,
    targetTradeUnionMembersOnly,
    targetFamilyMemberOnly,
    targetHavingChildFamilyMemberOnly,
    targetClientOrgs,
    targetExternalUsers,
    targetHasRzdSocialPackage,
    offerType,
    ...withOutRuntimeAttributes
  } = cmsContainerView;
  return {
    ...withOutRuntimeAttributes,
    target: {
      targetGender,
      targetRoads,
      targetLocalities,
      targetOrgUnits,
      targetType,
      targetTradeUnionMembersOnly,
      targetFamilyMemberOnly,
      targetHavingChildFamilyMemberOnly,
      targetClientOrgs,
      targetExternalUsers,
      targetHasRzdSocialPackage,
    },
    filterSettings: {
      offerType,
    },
  };
};

export const getEmptyCmsContainerView = (): CmsContainerView => ({
  id: null,
  name: '',
  title: null,
  showTitle: false,
  showIcon: false,
  startDate: '',
  endDate: '',
  type: null,
  targetClientOrgs: null,
  targetExternalUsers: null,
  targetFamilyMemberOnly: null,
  targetGender: null,
  targetHavingChildFamilyMemberOnly: null,
  targetLocalities: null,
  targetOrgUnits: null,
  targetRoads: null,
  targetTradeUnionMembersOnly: null,
  targetType: ETargetType.Geo,
  targetHasRzdSocialPackage: null,
  pageSize: 0,
  status: ECmsContainerStatus.Draft,
  components: null,
  resourcesSize: null,
  offerType: null,
});

const cmsLinkObjectDefaultType = ECmsLinkObjectType.Banner;

export const getEmptyCmsComponent = (): CmsComponent => ({
  id: null,
  linkObjectType: cmsLinkObjectDefaultType,
  linkObjectId: null,
  zone: null,
  height: null,
  width: null,
  pageSize: null,
  createdAt: '',
  createdBy: '',
  updatedAt: null,
  updatedBy: null,
});

export const getEmptyCmsLinkedObject = (
  containerType: ECmsContainerType,
  linkObjectType: ECmsLinkObjectType,
  offerType: Nullable<EOfferType>
): CmsLinkedObject => {
  switch (linkObjectType) {
    case ECmsLinkObjectType.Banner:
      switch (containerType) {
        case ECmsContainerType.Banner1LinkResizable:
          return getEmptyCmsLinkedObjectBanner(ECmsBannerLinkObjectType.Link);
        default:
          return getEmptyCmsLinkedObjectBanner();
      }
    case ECmsLinkObjectType.Collection:
      switch (containerType) {
        case ECmsContainerType.CompilationOffer:
          switch (offerType) {
            case EOfferType.Trade:
              return getEmptyCmsLinkedObjectCollection(ECmsCollectionLinkObjectType.TradeOffer);
            case EOfferType.Corp:
              return getEmptyCmsLinkedObjectCollection(ECmsCollectionLinkObjectType.CorpOffer);
            default:
              return getEmptyCmsLinkedObjectCollection();
          }
        case ECmsContainerType.CompilationPartner:
          return getEmptyCmsLinkedObjectCollection(ECmsCollectionLinkObjectType.Partner);
        case ECmsContainerType.Banner1LinkResizable:
        case ECmsContainerType.Banner1:
        case ECmsContainerType.Banner2:
        case ECmsContainerType.Banner3:
        case ECmsContainerType.Banner4:
        case ECmsContainerType.Banner1Resizable:
        case ECmsContainerType.Banner3Resizable:
        case ECmsContainerType.Banner3Carousel:
        case ECmsContainerType.Banner4High:
        case ECmsContainerType.Banner2MobileWide:
        case ECmsContainerType.Category4Offer:
          return {} as CmsLinkedObject;
      }
  }
};

export const getEmptyCmsLinkedObjectBanner = (
  bannerLinkObjectType?: Nullable<ECmsBannerLinkObjectType>
): CmsLinkedObjectBanner => {
  return {
    type: ECmsLinkObjectType.Banner,
    banner: {
      id: null,
      linkObjectType: bannerLinkObjectType ?? null,
      linkedObject: null,
      linkObjectId: null,
      height: null,
      width: null,
      url: null,
      desktopImage: null,
      mobileImage: null,
      createdAt: '',
      createdBy: '',
      updatedAt: null,
      updatedBy: null,
      pageSize: null,
      zone: null,
      target: null,
    },
  };
};

export const getEmptyCmsLinkedObjectCollection = (
  collectionLinkObjectType?: Nullable<ECmsCollectionLinkObjectType>
): CmsLinkedObjectCollection => {
  return {
    type: ECmsLinkObjectType.Collection,
    collection: {
      id: null,
      linkObjectType: collectionLinkObjectType ?? null,
      linkedObject: null,
      pageSize: null,
      zone: null,
    },
  };
};

export const generateEmptyCmsObjects = (
  cmsContainerType: ECmsContainerType,
  offerType: Nullable<EOfferType>
): { components: CmsComponent[]; linkedObjects: CmsLinkedObject[] } => {
  const componentsCount = getCmsContainerComponentCountByType(cmsContainerType);

  const components: CmsComponent[] = [];
  const linkedObjects: CmsLinkedObject[] = [];

  for (let i = 0; i < componentsCount; i++) {
    const component = {
      ...getEmptyCmsComponent(),
      linkObjectType: getCmsLinkObjectType(cmsContainerType),
    };
    components.push(component);
    linkedObjects.push(getEmptyCmsLinkedObject(cmsContainerType, component.linkObjectType, offerType));
  }
  return {
    components,
    linkedObjects,
  };
};

export const getCmsBannerLinkedObjectTypes = (
  allBannerLinkedObjectTypes: SportOptionTyped<ECmsBannerLinkObjectType>[],
  cmsContainerType: Nullable<ECmsContainerType>,
  cmsBanner: CmsLinkedObjectBannerType,
  offerType: Nullable<EOfferType>
): SportOptionTyped<ECmsBannerLinkObjectType>[] => {
  let linkedObjectTypes: SportOptionTyped<ECmsBannerLinkObjectType>[] = [];

  switch (offerType) {
    case EOfferType.Corp:
      const corpSource =
        cmsContainerType === ECmsContainerType.Category4Offer
          ? [ECmsBannerLinkObjectType.CorpOfferCategory]
          : [
              ECmsBannerLinkObjectType.Collection,
              ECmsBannerLinkObjectType.CorpOfferCategory,
              ECmsBannerLinkObjectType.CorpOffer,
            ];

      linkedObjectTypes = allBannerLinkedObjectTypes.filter(lot => corpSource.includes(lot.id));
      break;
    case EOfferType.Trade:
      const tradeSource =
        cmsContainerType === ECmsContainerType.Category4Offer
          ? [ECmsBannerLinkObjectType.TradeOfferCategory]
          : [
              ECmsBannerLinkObjectType.Collection,
              ECmsBannerLinkObjectType.TradeOfferCategory,
              ECmsBannerLinkObjectType.TradeOffer,
            ];

      linkedObjectTypes = allBannerLinkedObjectTypes.filter(lot => tradeSource.includes(lot.id));
      break;
    // todo потом не забыть Product
    case EOfferType.Product:
      break;
    case EOfferType.Booking:
      break;
    case null:
      break;
  }

  if (cmsContainerType === ECmsContainerType.Banner1LinkResizable) {
    linkedObjectTypes = [
      {
        id: ECmsBannerLinkObjectType.Link,
        name: cmsBanner.url ?? '',
      },
    ];
  }

  return linkedObjectTypes;
};

export const getCmsCollectionLinkedObjectTypeByContainer = (
  cmsContainerType: Nullable<ECmsContainerType>,
  offerType: Nullable<EOfferType>
): Nullable<ECmsCollectionLinkObjectType> => {
  switch (cmsContainerType) {
    case ECmsContainerType.CompilationOffer:
      switch (offerType) {
        case EOfferType.Trade:
          return ECmsCollectionLinkObjectType.TradeOffer;
        case EOfferType.Corp:
          return ECmsCollectionLinkObjectType.CorpOffer;
        case EOfferType.Product:
          return null;
        case EOfferType.Booking:
          return null;
        case null:
          return null;
      }
      break;
    case ECmsContainerType.Banner1:
    case ECmsContainerType.Banner2:
    case ECmsContainerType.Banner3:
    case ECmsContainerType.Banner4:
    case ECmsContainerType.Banner3Carousel:
    case ECmsContainerType.Banner4High:
    case ECmsContainerType.Banner1Resizable:
    case ECmsContainerType.Banner1LinkResizable:
    case ECmsContainerType.Banner3Resizable:
    case ECmsContainerType.Banner2MobileWide:
    case ECmsContainerType.Category4Offer:
    case ECmsContainerType.CompilationPartner:
      return null;
    case null:
      return null;
  }
};
export const getCmsSearchCorpOfferPresetValues = (
  cmsContainer: Pick<CmsContainerView, 'startDate' | 'endDate' | keyof Target>
): CorpOfferTableFilterValues => {
  let filterValues: CorpOfferTableFilterValues = {};
  if (cmsContainer.endDate) {
    filterValues = {
      ...filterValues,
      [ECorpOfferTableFilterItem.StartDate]: {
        value: cmsContainer.endDate,
        querydsl: {
          operator: DataFilterQueryDslOperator.LessOrEquals,
        },
      },
    };
  }
  if (cmsContainer.startDate) {
    filterValues = {
      ...filterValues,
      [ECorpOfferTableFilterItem.EndDate]: {
        value: cmsContainer.startDate,
        querydsl: {
          operator: DataFilterQueryDslOperator.MoreOrEquals,
        },
      },
    };
  }

  const {
    targetType,
    targetGender,
    targetClientOrgs,
    targetExternalUsers,
    targetRoads,
    targetLocalities,
    targetOrgUnits,
    targetFamilyMemberOnly,
    targetHavingChildFamilyMemberOnly,
    targetTradeUnionMembersOnly,
    targetHasRzdSocialPackage,
  } = cmsContainer;
  const target = {
    targetType,
    targetGender,
    targetClientOrgs,
    targetExternalUsers,
    targetRoads,
    targetLocalities,
    targetOrgUnits,
    targetFamilyMemberOnly,
    targetHavingChildFamilyMemberOnly,
    targetTradeUnionMembersOnly,
    targetHasRzdSocialPackage,
  };
  if (!isTargetEmpty(target)) {
    filterValues = { ...filterValues, [ECorpOfferTableFilterItem.Target]: { value: target } };
  }

  return filterValues;
};

export const getCmsSearchTradeOfferPresetValues = (
  cmsContainer: Pick<CmsContainerView, 'startDate' | 'endDate' | keyof Target>
): TradeOfferTableFilterValues => {
  let filterValues: TradeOfferTableFilterValues = {};
  if (cmsContainer.endDate) {
    filterValues = {
      ...filterValues,
      [ETradeOfferTableFilterItem.StartDate]: {
        value: cmsContainer.endDate,
        querydsl: {
          operator: DataFilterQueryDslOperator.LessOrEquals,
        },
      },
    };
  }
  if (cmsContainer.startDate) {
    filterValues = {
      ...filterValues,
      [ETradeOfferTableFilterItem.EndDate]: {
        value: cmsContainer.startDate,
        querydsl: {
          operator: DataFilterQueryDslOperator.MoreOrEquals,
        },
      },
    };
  }

  const {
    targetType,
    targetGender,
    targetClientOrgs,
    targetExternalUsers,
    targetRoads,
    targetLocalities,
    targetOrgUnits,
    targetFamilyMemberOnly,
    targetHavingChildFamilyMemberOnly,
    targetTradeUnionMembersOnly,
    targetHasRzdSocialPackage,
  } = cmsContainer;

  const target: Target = {
    targetType,
    targetGender,
    targetClientOrgs,
    targetExternalUsers,
    targetRoads,
    targetLocalities,
    targetOrgUnits,
    targetFamilyMemberOnly,
    targetHavingChildFamilyMemberOnly,
    targetTradeUnionMembersOnly,
    targetHasRzdSocialPackage,
  };

  if (!isTargetEmpty(target)) {
    filterValues = { ...filterValues, [ETradeOfferTableFilterItem.Target]: { value: target } };
  }

  return filterValues;
};
