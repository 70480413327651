import { RootState } from '@/data/store/store';
import ErrorComponent from '@components/common/error';
import { FCC } from 'react';
import { useSelector } from 'react-redux';

interface CommonFetchErrorDecoratorProps {
  readonly isFetchFailedSelector: (store: RootState) => boolean;
}

export const CommonFetchErrorDecorator: FCC<CommonFetchErrorDecoratorProps> = ({ isFetchFailedSelector, children }) => {
  const isFailed = useSelector(isFetchFailedSelector);

  return isFailed ? <ErrorComponent /> : <>{children}</>;
};
