import { ReactNode } from 'react';
import styled from '@emotion/styled';

interface WrapperProps {
  readonly children: ReactNode;
}

export const Wrapper = styled(({ ...others }: WrapperProps) => <div {...others} />)`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  transform-style: preserve-3d;

  & > *[aria-checked='false'] {
    height: 80%;
  }

  & > *[aria-checked='true'] {
    width: 90%;
    height: 100%;
    position: absolute;

    left: 0;
    right: 0;
    margin: auto;

    transition: transform 0.4s ease;
    transition-property: width, height;
  }
`;
