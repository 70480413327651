import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useContextHandlers } from './hooks/useContextHandlers';
import PartnerEmployeeTableNotifier from './notifier';
import { partnerEmployeesDialogsSelector } from './store/selectors';

const PartnerEmployeeTableDialogs = () => {
  const handlers = useContextHandlers();

  const dialogs = useSelector(partnerEmployeesDialogsSelector);

  const onReadChangePartnerOwner = useCallback(() => {
    handlers.onChangeDialogState('changePartnerOwner', null);
  }, [handlers.onChangeDialogState]);

  return <>{dialogs.changePartnerOwner && <PartnerEmployeeTableNotifier onRead={onReadChangePartnerOwner} />}</>;
};

export default PartnerEmployeeTableDialogs;
