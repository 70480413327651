import { ReactNode, useMemo } from 'react';
import { CorpOffer } from '../../../../../domain/model/corpOffer';
import DataTableCellTextFormat from '../../../../components/common/table/cell/textFormat';

export interface CorpOfferTableCellCommonProps {
  readonly corpOffer: CorpOffer;
  readonly isDisabled?: boolean;
  readonly isTextAccent?: boolean;
}

interface CorpOfferTableCellProps extends CorpOfferTableCellCommonProps {
  readonly value: ReactNode;
}

const CorpOfferTableCell = ({ value, isDisabled, isTextAccent }: CorpOfferTableCellProps) => {
  return useMemo(
    () => (
      <DataTableCellTextFormat
        disabled={isDisabled}
        bold={isTextAccent}
      >
        {value}
      </DataTableCellTextFormat>
    ),
    [isDisabled, isTextAccent, value]
  );
};

export default CorpOfferTableCell;
