import { Typography } from '@mui/material';
import { EValidationMessages } from '../../../../utils/validation';
import { Wrapper } from './controls';

interface CheckboxPanelProps {
  text?: string;
}

const ValidationErrorPanel = ({ text = EValidationMessages.Summary }: CheckboxPanelProps) => {
  return (
    <Wrapper>
      <Typography variant='body1'>{text}</Typography>
    </Wrapper>
  );
};
export default ValidationErrorPanel;
