import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import { OfferHistoryTableCellActorTypeProps } from '../components/tableCell/history/actorType';
import { offerHistoryTableHistoryByIndexSelector } from '../store/selectors';
import { OfferHistoryTableCellAdapterProps } from './cell';

type OfferHistoryTableCellActorTypeAdapterProps = Omit<OfferHistoryTableCellAdapterProps, 'component'> & {
  readonly component: FC<OfferHistoryTableCellActorTypeProps>;
};

const OfferHistoryTableCellActorTypeAdapter = (props: OfferHistoryTableCellActorTypeAdapterProps) => {
  const { index, component: Component } = props;

  const history = useSelector(offerHistoryTableHistoryByIndexSelector(index));

  const { privilegeActorTypes: actorTypes } = useSelector(nsiDataSelector);

  return useMemo(
    () =>
      history ? (
        <Component
          history={history}
          actorTypes={actorTypes}
        />
      ) : null,
    [Component, history, actorTypes]
  );
};

export default OfferHistoryTableCellActorTypeAdapter;