import { useEffect, useState } from 'react';
import { CorpOffer } from '../../../../../domain/model/corpOffer';
import { ApprovalOfferRegistryRequest } from '../../../../../domain/model/offerApprovalRegistry';
import { Nullable } from '../../../../../domain/model/types';
import { OnChangeObjectAttribute } from '../../../../types';
import CorpOfferApprovalRegistryDialog from '../approvalRegistryDialog';

interface CorpOfferTableChangeApprovalRegistryProps {
  readonly corpOffer: Nullable<CorpOffer>;
  readonly isValidation: boolean;
  readonly isExecuting: boolean;
  readonly onChange: (corpOffer: CorpOffer, approvalRegistry: ApprovalOfferRegistryRequest) => void;
  readonly onClose: () => void;
}

const CorpOfferTableChangeApprovalRegistry = (props: CorpOfferTableChangeApprovalRegistryProps) => {
  const { corpOffer, isValidation, isExecuting, onChange, onClose } = props;

  const [approvalRegistry, setApprovalRegistry] = useState<ApprovalOfferRegistryRequest>({ number: null, date: null });

  const onChangeApprovalRegistryAttribute: OnChangeObjectAttribute<ApprovalOfferRegistryRequest> = (name, value) => {
    setApprovalRegistry(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    setApprovalRegistry(
      corpOffer?.approvalRegistry
        ? { number: corpOffer.approvalRegistry.number, date: corpOffer.approvalRegistry.date }
        : { number: null, date: null }
    );
  }, [corpOffer]);

  return (
    <CorpOfferApprovalRegistryDialog
      open={!!corpOffer}
      approvalRegistry={approvalRegistry}
      isValidation={isValidation}
      isExecuting={isExecuting}
      onChangeAttribute={onChangeApprovalRegistryAttribute}
      onChange={() => {
        onChange(corpOffer!, approvalRegistry);
      }}
      onClose={onClose}
    />
  );
};

export default CorpOfferTableChangeApprovalRegistry;
