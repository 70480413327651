import { BookingOrderLifeCycleRules } from '../../lifecycle/types';
import { BookingOrderActionEditType, BookingOrderActions, EBookingOrderActionType } from '../../types';
import {
  filterBookingOrderEditActions,
  getBookingOrderActionName,
  makeBookingOrderActionPrimary,
  renameBookingOrderAction,
} from '../../utils/actions';

type UseBookingOrderEditActions = BookingOrderActions<BookingOrderActionEditType>;

interface UseBookingOrderEditActionsProps {
  readonly lifeCycleRules: BookingOrderLifeCycleRules;
}

const useBookingOrderEditActions = (props: UseBookingOrderEditActionsProps): UseBookingOrderEditActions => {
  const { lifeCycleRules } = props;

  const { nextActions } = lifeCycleRules;
  const editActions = filterBookingOrderEditActions(nextActions);

  renameBookingOrderAction(editActions, EBookingOrderActionType.Cancel, 'Отменить заказ');

  makeBookingOrderActionPrimary(editActions, EBookingOrderActionType.Confirm);
  makeBookingOrderActionPrimary(editActions, EBookingOrderActionType.Give);

  // Если действий меньше двух, объединяем label с групповым!
  if (
    editActions.filter(action => [EBookingOrderActionType.Confirm, EBookingOrderActionType.Give].includes(action.type))
      .length < 2
  ) {
    renameBookingOrderAction(
      editActions,
      EBookingOrderActionType.Confirm,
      `Сохранить и перевести в статус ${getBookingOrderActionName(EBookingOrderActionType.Confirm)}`
    );
    renameBookingOrderAction(
      editActions,
      EBookingOrderActionType.Give,
      `Сохранить и перевести в статус ${getBookingOrderActionName(EBookingOrderActionType.Give)}`
    );
  }
  return editActions;
};

export default useBookingOrderEditActions;
