import { AdCampaignShort } from '@/domain';
import { EAdCampaignTableTab } from '@features/adCampaign/types';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { getAdCampaignDetailsRoute, getAdCampaignEditRoute } from '../../entry';

export type UseAdCampaignTableConfig = {
  readonly tabs: EAdCampaignTableTab[];
  readonly tabsForCounts: EAdCampaignTableTab[];
  readonly defaultTab: EAdCampaignTableTab;
  readonly onAdCampaignClick: (adCampaign: AdCampaignShort) => void;
  readonly onAdCampaignOpenEdit: (adCampaign: AdCampaignShort) => void;
};

export function useAdCampaignTableConfig(): UseAdCampaignTableConfig {
  const history = useHistory();

  const onAdCampaignClick = useCallback(
    (adCampaign: AdCampaignShort) => {
      history.push(getAdCampaignDetailsRoute({ id: adCampaign.id }));
    },
    [history]
  );

  const onAdCampaignOpenEdit = useCallback(
    (adCampaign: AdCampaignShort) => {
      history.push(getAdCampaignEditRoute({ id: adCampaign.id }));
    },
    [history]
  );

  const tabs = [
    EAdCampaignTableTab.Active,
    EAdCampaignTableTab.Upcoming,
    EAdCampaignTableTab.Paused,
    EAdCampaignTableTab.Archived,
  ];
  const tabsForCounts = [
    EAdCampaignTableTab.Active,
    EAdCampaignTableTab.Upcoming,
    EAdCampaignTableTab.Paused,
    EAdCampaignTableTab.Archived,
  ];

  const defaultTab = EAdCampaignTableTab.Active;

  return {
    tabs,
    tabsForCounts,
    defaultTab,
    onAdCampaignClick,
    onAdCampaignOpenEdit,
  };
}
