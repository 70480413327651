import { ESportEventLevel, ESportEventParticipationFormat, EUserType } from '../../../../../domain/model/enums';
import { TournamentIndividual, TournamentIndividualRequest } from '../../../../../domain/model/event';
import { Nullable } from '../../../../../domain/model/types';
import { CalendarIcon, ImageCoverIcon, ListItemsIcon, TextAlignLeftIcon, UserIcon } from '../../../../icons';
import { pluralize } from '../../../../utils/pluralize';
import { EValidationType, ValidationResult } from '../../../../utils/validation';
import { EventHelpWidgetField } from '../../components/helpWidgets';
import { EEventStep, TournamentEditStepType, TournamentIndividualCreateStepType } from '../../types';

export const tournamentDefaultLevel = ESportEventLevel.Road;
export const tournamentDefaultUserType = EUserType.Any;

export const convertTournamentIndividualToTournamentIndividualRequest = (
  tournament: TournamentIndividual
): TournamentIndividualRequest => {
  const {
    id,
    name,
    description,
    status,
    level,
    road,
    scheduledDate,
    endDate,
    playground,
    locationDescription,
    locationImage,
    participationFormat,
    onlineParticipationLink,
    onlineParticipationDescription,
    onlineParticipationInstruction,
    maxAge,
    minAge,
    minLengthOfWork,
    organizerEmail,
    organizerPhone,
    previewImage,
    programDescription,
    programFile,
    regulationFiles,
    admin,
    invitedAddresses,
    invitedUsers,
    applicationTakingEndDate,
    activityType,
    userType,
    chatId,
    activityTypes,
  } = tournament;

  return {
    id,
    name,
    description,
    status,
    scheduledDate,
    endDate,
    locationDescription,
    locationImage,
    participationFormat,
    onlineParticipationLink,
    onlineParticipationDescription,
    onlineParticipationInstruction,
    maxAge,
    minAge,
    minLengthOfWork,
    organizerEmail,
    organizerPhone,
    previewImage,
    programDescription,
    programFile,
    regulationFiles,
    playground,
    level,
    road,
    admin,
    invitedAddresses,
    invitedUsers,
    applicationTakingEndDate,
    activityType: activityType,
    userType,
    chatId,
    activityTypes,
  };
};

export const createNewTournamentIndividualRequest = (): TournamentIndividualRequest => {
  return {
    id: '',
    name: '',
    description: null,
    status: null,
    scheduledDate: null,
    endDate: null,
    locationDescription: null,
    locationImage: null,
    participationFormat: ESportEventParticipationFormat.Offline,
    onlineParticipationLink: null,
    onlineParticipationDescription: null,
    onlineParticipationInstruction: null,
    maxAge: null,
    minAge: null,
    minLengthOfWork: null,
    organizerEmail: null,
    organizerPhone: null,
    previewImage: null,
    programDescription: null,
    programFile: null,
    regulationFiles: null,
    playground: null,
    level: null,
    road: null,
    admin: null,
    invitedUsers: null,
    invitedAddresses: null,
    applicationTakingEndDate: null,
    activityType: null,
    userType: null,
    chatId: null,
    activityTypes: null,
  };
};

export const getTournamentIndividualCreateStepText = (
  step: TournamentIndividualCreateStepType | TournamentEditStepType
): string => {
  switch (step) {
    case EEventStep.General:
      return 'Основная информация';
    case EEventStep.Details:
      return 'Детали и контакты';
    case EEventStep.Requirements:
      return 'Требования к участникам';
    case EEventStep.Invites:
      return 'Приглашение участников';
  }
};

/**
 * Первоначальный массив полей, которые будет отображать виджет.
 * Адаптер берет эти значения как по умолчанию, но создает свое локальное состояние
 */
export const tournamentIndividualFieldsListForHelpWidget: Partial<keyof TournamentIndividualRequest>[] = [
  'name',
  'description',
  'previewImage',
  'level',
  'road',
  'activityType',
  'scheduledDate',
  'endDate',
  'participationFormat',
  'onlineParticipationLink',
  'playground',
  'organizerPhone',
  'organizerEmail',
  'minAge',
  'maxAge',
  'applicationTakingEndDate',
];

export const tournamentIndividualHelpWidgetGetFieldData = (
  field: Nullable<keyof TournamentIndividualRequest>
): Nullable<EventHelpWidgetField> => {
  const defaultDataField: EventHelpWidgetField = {
    name: null,
    icon: TextAlignLeftIcon,
  };

  switch (field) {
    case 'name': {
      return {
        ...defaultDataField,
        name: 'Название',
      };
    }
    case 'description': {
      return {
        ...defaultDataField,
        name: 'Описание',
      };
    }
    case 'activityType': {
      return {
        ...defaultDataField,
        name: 'Вид спорта',
        icon: ListItemsIcon,
      };
    }
    case 'activityTypes': {
      return {
        ...defaultDataField,
        name: 'Виды спорта',
        icon: ListItemsIcon,
      };
    }
    case 'previewImage': {
      return {
        ...defaultDataField,
        name: 'Обложка',
        icon: ImageCoverIcon,
      };
    }
    case 'road': {
      return {
        ...defaultDataField,
        name: 'Железная дорога',
        icon: ListItemsIcon,
      };
    }
    case 'scheduledDate': {
      return {
        ...defaultDataField,
        name: 'Дата начала',
        icon: CalendarIcon,
      };
    }
    case 'endDate': {
      return {
        ...defaultDataField,
        name: 'Дата завершения',
        icon: CalendarIcon,
      };
    }
    case 'playground': {
      return {
        ...defaultDataField,
        name: 'Место проведения',
        icon: CalendarIcon,
      };
    }
    case 'organizerPhone': {
      return {
        ...defaultDataField,
        name: 'Телефон для связи',
      };
    }
    case 'organizerEmail': {
      return {
        ...defaultDataField,
        name: 'Почта для связи',
      };
    }
    case 'minAge': {
      return {
        ...defaultDataField,
        name: 'Возраст участников (От)',
      };
    }
    case 'maxAge': {
      return {
        ...defaultDataField,
        name: 'Возраст участников (До)',
      };
    }
    case 'minLengthOfWork': {
      return {
        ...defaultDataField,
        name: 'Минимальный стаж',
      };
    }
    case 'level': {
      return {
        ...defaultDataField,
        name: 'Уровень проведения',
      };
    }
    case 'applicationTakingEndDate': {
      return {
        ...defaultDataField,
        name: 'Дата окончания приема заявок',
        icon: CalendarIcon,
      };
    }
    case 'invitedAddresses':
    case 'invitedUsers': {
      return {
        ...defaultDataField,
        name: 'Пригласите участников',
        icon: UserIcon,
      };
    }
    case 'participationFormat': {
      return {
        ...defaultDataField,
        name: 'Формат участия',
      };
    }
    case 'onlineParticipationLink': {
      return {
        ...defaultDataField,
        name: 'Сайт или ссылка на трансляцию',
      };
    }
    case null:
    case 'status':
    case 'id':
    case 'regulationFiles':
    case 'chatId':
    case 'locationImage':
    case 'locationDescription':
    case 'onlineParticipationInstruction':
    case 'onlineParticipationDescription':
    case 'programFile':
    case 'programDescription':
    case 'admin':
    case 'userType': {
      return null;
    }
  }
};
const tournamentIndividualGetValidationCommonError = (
  step: TournamentIndividualCreateStepType,
  count: number
): Nullable<ValidationResult<any>> => {
  if (!count) {
    return null;
  }

  switch (step) {
    case EEventStep.Invites:
      return {
        common: {
          type: EValidationType.Error,
          hasError: true,
          message: 'Пригласите участников',
        },
      };
    default:
      return {
        common: {
          type: EValidationType.Error,
          hasError: true,
          message: `Не заполнено ${count} ${pluralize(count, ['поле', 'поля', 'полей'])}`,
        },
      };
  }
};

export const tournamentIndividualCreateInitValidationStepper = (
  validation: ValidationResult<TournamentIndividualRequest>
) => {
  const generalErrorsCount = [
    validation.name,
    validation.description,
    validation.previewImage,
    validation.level,
    validation.road,
    validation.userType,
    validation.activityType,
    validation.scheduledDate,
    validation.endDate,
  ].filter(item => !!item).length;

  const detailsErrorsCount = [
    validation.playground,
    validation.participationFormat,
    validation.onlineParticipationLink,
    validation.onlineParticipationDescription,
    validation.onlineParticipationInstruction,
    validation.locationDescription,
    validation.locationImage,
    validation.programDescription,
    validation.programFile,
    validation.admin,
    validation.organizerPhone,
    validation.organizerEmail,
  ].filter(item => !!item).length;

  const requirementsErrorsCount = [
    validation.minAge,
    validation.maxAge,
    validation.minLengthOfWork,
    validation.applicationTakingEndDate,
    validation.regulationFiles,
  ].filter(item => !!item).length;

  const invitesErrorsCount = [validation.invitedAddresses, validation.invitedUsers].filter(item => !!item).length;

  return {
    [EEventStep.General]: tournamentIndividualGetValidationCommonError(EEventStep.General, generalErrorsCount),
    [EEventStep.Details]: tournamentIndividualGetValidationCommonError(EEventStep.Details, detailsErrorsCount),
    [EEventStep.Requirements]: tournamentIndividualGetValidationCommonError(
      EEventStep.Requirements,
      requirementsErrorsCount
    ),
    [EEventStep.Invites]: tournamentIndividualGetValidationCommonError(EEventStep.Invites, invitesErrorsCount),
  };
};
