import { useContext } from 'react';
import TradeOfferActionsContext, { TradeOfferActionsContextType } from './context';

export const useTradeOfferActions = (): TradeOfferActionsContextType => {
  return useContext<TradeOfferActionsContextType>(TradeOfferActionsContext);
};

export const useTradeOfferActionsSelectors = (): TradeOfferActionsContextType['utils']['selectors'] => {
  return useTradeOfferActions().utils.selectors;
};
