import { UUID } from 'domain/model/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { analyticsQueriesGuidSelector } from '../store/selectors';
import { analyticsQueriesSetTab, analyticsQueriesStartSession } from '../store/slice';
import { EAnalyticsQueriesTableTab } from '../utils';

interface UseAnalyticsQueriesTableSessionProps {
  readonly guid: UUID;
  readonly tab: EAnalyticsQueriesTableTab;
}

const useAnalyticsQueriesTableSession = (props: UseAnalyticsQueriesTableSessionProps): void => {
  const { guid, tab } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(analyticsQueriesGuidSelector);

  // инициализация сеанса
  useEffect(() => {
    dispatch(analyticsQueriesStartSession({ guid }));
  }, [dispatch, guid]);

  // начальная загрузка
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(analyticsQueriesSetTab({ tab }));
    }
  }, [dispatch, guid, currentGuid, tab]);
};

export default useAnalyticsQueriesTableSession;
