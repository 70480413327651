import { Address } from '@/domain';
import { MPAutocompleteMultipleSelectItem } from '@ui-kit/autocomplete/types';

export enum AddressMultipleItemType {
  All = 'all',
  Text = 'text',
  Address = 'address',
  None = 'none',
}

export enum AddressMultipleAllItemsState {
  Indeterminate = 'indeterminate',
  Checked = 'checked',
  NotChecked = 'not-checked',
}

export enum AddressMultipleSelectType {
  All = 'all',
  None = 'none',
}

export type AddressMultipleTypesSelectorItem =
  | (Address & { type: AddressMultipleItemType.Address })
  | (MPAutocompleteMultipleSelectItem & { type: AddressMultipleItemType.Text })
  | (MPAutocompleteMultipleSelectItem & {
      readonly type: AddressMultipleItemType.All;
      readonly state: AddressMultipleAllItemsState;
    })
  | (MPAutocompleteMultipleSelectItem & {
      readonly type: AddressMultipleItemType.None;
      readonly state: AddressMultipleAllItemsState;
    });
