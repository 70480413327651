import { Nullable, UUID } from '../../../../domain/model/types';

export enum EPlaygroundTab {
  General = 'general',
  Events = 'events',
}

export enum EPlaygroundMode {
  View = 'view',
  Edit = 'edit',
}

export const playgroundMediaSize = '9.375rem';

interface GetPlaygroundDetailsTitleProps {
  readonly id?: Nullable<UUID>;
  readonly mode: EPlaygroundMode;
}

export const getPlaygroundDetailsTitle = ({ mode, id = null }: GetPlaygroundDetailsTitleProps) =>
  mode === EPlaygroundMode.View ? 'Площадка' : id ? 'Редактирование площадки' : 'Добавление площадки';

export const playgroundTitleMaxLength = 50 as const;
export const playgroundDescriptionMaxLength = 300 as const;
