import { CommonLifeCycleInstance } from '../../../utils/lifecycle';
import { EAdCampaignActionType } from '../types';
import { commonAdminConfiguration, commonPartnerConfiguration } from './configurations';
import {
  AdCampaignLifeCycle,
  AdCampaignLifeCycleFactoryProps,
  AdCampaignLifeCycleProps,
  AdCampaignLifeCycleType,
} from './types';

/**
 * жизненный цикл РК
 */
const AdCampaignLifeCycleInstance = (props: AdCampaignLifeCycleProps<AdCampaignLifeCycleType>): AdCampaignLifeCycle => {
  const { configuration, userId, isPartnerUser } = props;

  const commonLifecycle = CommonLifeCycleInstance(props);

  return {
    configuration,
    build: ({ allowedActions, obj }) => {
      const commonLifecycleBuild = commonLifecycle.build({ allowedActions, obj });
      const { can } = commonLifecycleBuild;

      const edit = can(EAdCampaignActionType.Edit);

      return {
        ...commonLifecycleBuild,
        canEdit: edit,
      };
    },
    isPartnerUser,
    userId,
  };
};

/**
 * жизненный цикл РК - основная модель
 */
export const AdCampaignLifeCycleFactory = () => {
  return {
    create: ({ isPartnerUser, userId, debug }: AdCampaignLifeCycleFactoryProps): AdCampaignLifeCycle => {
      if (isPartnerUser) {
        return AdCampaignLifeCycleInstance({ userId, configuration: commonPartnerConfiguration, debug, isPartnerUser });
      } else {
        return AdCampaignLifeCycleInstance({ userId, configuration: commonAdminConfiguration, debug, isPartnerUser });
      }
    },
  };
};
