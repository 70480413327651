import { FC } from 'react';
import { useSelector } from 'react-redux';
import { AdCampaignTableCellCommonProps } from '../../components/tableCell';
import { adCampaignAdCampaignByIndexSelector } from '../store/selectors';

export type AdCampaignTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<AdCampaignTableCellCommonProps>;
};

const AdCampaignTableCellAdapter = (props: AdCampaignTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const adCampaign = useSelector(adCampaignAdCampaignByIndexSelector(index));

  return adCampaign ? <Component adCampaign={adCampaign} /> : null;
};

export default AdCampaignTableCellAdapter;
