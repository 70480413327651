import { SportOption } from '../../../domain/model';
import { Address } from '../../../domain/model/address';
import { EPlaygroundModerationStatus } from '../../../domain/model/enums';
import { ActivityType } from '../../../domain/model/event';
import {
  DataFilterQueryDslOperator,
  DataFilterStrategyAdapterTreeMultipleEditAdapter,
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterValueItem,
  EDataFilterType,
} from '../../../domain/model/filter';
import { Nullable } from '../../../domain/model/types';
import { AddressHelper } from '../../utils/address';
import ActivityTypeFilterEditAdapter from '../activityType/adapters/filterEdit/container';
import playgroundServices from './services';
import { EPlaygroundTableColumn, EPlaygroundTableTab } from './table/utils';

export enum EPlaygroundTableFilterItem {
  Name = 'name',
  ActivityTypes = 'activityTypes',
  Address = 'addressObjectId',
  ModeratedBy = 'moderatedBy',
  CreatedBy = 'createdBy',
  TypePlayGround = 'type',
}

interface PlaygroundTableItems extends Record<EPlaygroundTableColumn, EPlaygroundTableFilterItem[]> {}

export const playgroundTableFilterItems: PlaygroundTableItems = {
  [EPlaygroundTableColumn.Name]: [EPlaygroundTableFilterItem.Name],
  [EPlaygroundTableColumn.CreatedBy]: [EPlaygroundTableFilterItem.CreatedBy],
  [EPlaygroundTableColumn.ModeratedBy]: [EPlaygroundTableFilterItem.ModeratedBy],
  [EPlaygroundTableColumn.CityName]: [EPlaygroundTableFilterItem.Address],
  [EPlaygroundTableColumn.ActivityTypes]: [EPlaygroundTableFilterItem.ActivityTypes],
  [EPlaygroundTableColumn.ActivitySubTypes]: [],
  [EPlaygroundTableColumn.TypePlayGround]: [EPlaygroundTableFilterItem.TypePlayGround],
  [EPlaygroundTableColumn.RejectingReason]: [],
};

export type PlaygroundTableFilterValues = {
  readonly [EPlaygroundTableFilterItem.Name]?: DataFilterValueItem<Nullable<string>>;
  readonly [EPlaygroundTableFilterItem.ActivityTypes]?: DataFilterValueItem<Nullable<ActivityType[]>>;
  readonly [EPlaygroundTableFilterItem.Address]?: DataFilterValueItem<Nullable<Address>>;
  readonly [EPlaygroundTableFilterItem.TypePlayGround]?: DataFilterValueItem<Nullable<string>>;
  readonly [EPlaygroundTableFilterItem.CreatedBy]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem>>;
  readonly [EPlaygroundTableFilterItem.ModeratedBy]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem>>;
};

export type PlaygroundTableFilterEditStrategy = DataFilterStrategyBase<EPlaygroundTableFilterItem>;

export const getPlaygroundTableFilterStrategy = (
  filterItem: EPlaygroundTableFilterItem,
  values: PlaygroundTableFilterValues,
  types: SportOption[]
): PlaygroundTableFilterEditStrategy => {
  const typeItems: DataFilterStrategyListItem[] = (types ?? []).map(({ id, name }) => ({ id, name }));

  switch (filterItem) {
    case EPlaygroundTableFilterItem.Name:
      return {
        type: EDataFilterType.String,
        key: EPlaygroundTableFilterItem.Name,
        label: 'Название',
        preview: values[EPlaygroundTableFilterItem.Name]?.value || null,
        value: values[EPlaygroundTableFilterItem.Name]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case EPlaygroundTableFilterItem.ActivityTypes:
      return {
        type: EDataFilterType.AdapterTreeMultiple,
        key: EPlaygroundTableFilterItem.ActivityTypes,
        label: 'Тематика',
        preview: `Тематика (${values[EPlaygroundTableFilterItem.ActivityTypes]?.value?.length})`,
        previews: values[EPlaygroundTableFilterItem.ActivityTypes]?.value?.map(t => t.name),
        value: values[EPlaygroundTableFilterItem.ActivityTypes]?.value || null,
        editAdapter:
          ActivityTypeFilterEditAdapter as DataFilterStrategyAdapterTreeMultipleEditAdapter<EPlaygroundTableFilterItem>,
      };
    case EPlaygroundTableFilterItem.Address:
      return {
        type: EDataFilterType.AddressLocalitySingle,
        key: EPlaygroundTableFilterItem.Address,
        label: 'Населённый пункт',
        preview: values[EPlaygroundTableFilterItem.Address]?.value
          ? new AddressHelper(values[EPlaygroundTableFilterItem.Address]!.value!).getLocalityFullPath()
          : null,
        value: values[EPlaygroundTableFilterItem.Address]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Equals,
        },
      };
    case EPlaygroundTableFilterItem.ModeratedBy:
      return {
        type: EDataFilterType.AutocompleteSingle,
        key: EPlaygroundTableFilterItem.ModeratedBy,
        label: 'Модератор',
        preview: `Модератор (${values[EPlaygroundTableFilterItem.ModeratedBy]?.value?.name})` || null,
        value: values[EPlaygroundTableFilterItem.ModeratedBy]?.value || null,
        source: (name: string) =>
          name
            ? playgroundServices.users.moderators({ name }).then(res =>
                res.content.map(item => ({
                  id: item.id,
                  name: `${item.firstName} ${item.lastName}`,
                }))
              )
            : Promise.resolve([]),
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
          valueAttribute: 'id',
        },
      };
    case EPlaygroundTableFilterItem.CreatedBy:
      return {
        type: EDataFilterType.AutocompleteSingle,
        key: EPlaygroundTableFilterItem.CreatedBy,
        label: 'Инициатор',
        value: values[EPlaygroundTableFilterItem.CreatedBy]?.value || null,
        preview: `Инициатор (${values[EPlaygroundTableFilterItem.CreatedBy]?.value?.name})` || null,
        source: (name: string) =>
          name
            ? playgroundServices.users.creators({ name }).then(res =>
                res.map(item => ({
                  id: item.id,
                  name: item.name,
                }))
              )
            : Promise.resolve([]),
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
          valueAttribute: 'id',
        },
      };
    case EPlaygroundTableFilterItem.TypePlayGround:
      return {
        type: EDataFilterType.ListSingle,
        key: EPlaygroundTableFilterItem.TypePlayGround,
        label: 'Комфорт',
        preview:
          `Комфорт (${typeItems.find(g => g.id === values[EPlaygroundTableFilterItem.TypePlayGround]?.value)?.name})` ||
          null,
        value: values[EPlaygroundTableFilterItem.TypePlayGround]?.value || null,
        items: typeItems,
        querydsl: {
          operator: DataFilterQueryDslOperator.Equals,
          valueAttribute: 'id',
        },
      };
  }
};

export const getStatesFilterForPlaygroundTableTab = (tab: EPlaygroundTableTab): EPlaygroundModerationStatus[] => {
  switch (tab) {
    case EPlaygroundTableTab.All:
      return [];

    case EPlaygroundTableTab.Active:
      return [EPlaygroundModerationStatus.Active];

    case EPlaygroundTableTab.Blocked:
      return [EPlaygroundModerationStatus.Blocked];
  }
  return [];
};
