import { ClientOrg } from '@/domain/model/clientOrg';
import { ENoticeStatus } from '@/domain/model/enums';
import Notifier from '@/system/notifier';
import { EClientOrgActionType } from '../types';

export const addSuccessNotice = (text: string) => {
  Notifier.getInstance().addNotice(ENoticeStatus.Success, text);
};

export const showClientOrgActionNotification = (clientOrg: ClientOrg, action: EClientOrgActionType) => {
  switch (action) {
    case EClientOrgActionType.Edit:
      addSuccessNotice(`Изменения сохранены`);
      break;
    case EClientOrgActionType.Block:
      addSuccessNotice(`Компания ${clientOrg.name} была приостановлена`);
      break;
    case EClientOrgActionType.UnBlock:
      addSuccessNotice(`Компания ${clientOrg.name} была активирована`);
      break;
  }
};
