import styled from '@emotion/styled';
import { MPButton } from '../../../../theme/ui-kit/button';

export const BackButtonWrapper = styled.div`
  padding-top: ${p => p.theme.spacing(2)};
`;

export const StyledButton = styled(MPButton)`
  .MuiSvgIcon-root {
    margin-right: ${p => p.theme.spacing(1)};
    color: ${p => p.theme.palette.secondary.dark};
  }
`;
