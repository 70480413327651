import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { ActivityType } from '../../../../../../domain/model/event';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { activityTypesEditDialogsStateSelector } from '../store/selectors';
import { ActivityTypesEditDialogAddAdapter } from './dialogAdd';
import { ActivityTypesEditDialogEditAdapter } from './dialogEdit';
import { ActivityTypesEditDialogHideAdapter } from './dialogHide';

export const ActivityTypesEditDialogsAdapter = () => {
  const handlers = useContextHandlers();

  const dialogs = useSelector(activityTypesEditDialogsStateSelector);

  const [isLoading, setIsLoading] = useState(false);

  const onAdd = useCallback(
    async (activityType: ActivityType) => {
      setIsLoading(true);
      await handlers
        .onCreate(activityType)
        .then(() => {
          handlers.onChangeDialogState('add', null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [handlers.onChangeDialogState]
  );

  const onCloseAdd = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('add', null);
  }, [handlers.onChangeDialogState]);

  const onHide = useCallback(
    async (activityType: ActivityType) => {
      setIsLoading(true);
      await handlers
        .onHide(activityType)
        .then(() => {
          handlers.onChangeDialogState('hide', null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [handlers.onChangeDialogState, handlers.onHide]
  );

  const onCloseHide = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('hide', null);
  }, [handlers.onChangeDialogState]);

  const onModify = useCallback(
    async (activityType: ActivityType) => {
      setIsLoading(true);
      await handlers
        .onEdit(activityType)
        .then(() => {
          handlers.onChangeDialogState('modify', null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [handlers.onChangeDialogState, handlers.onEdit]
  );

  const onCloseModify = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('modify', null);
  }, [handlers.onChangeDialogState]);

  return (
    <>
      {dialogs.add && (
        <ActivityTypesEditDialogAddAdapter
          isLoading={isLoading}
          parent={dialogs.add?.parent?.data ?? null}
          onAdd={onAdd}
          onClose={onCloseAdd}
        />
      )}
      {dialogs.hide && (
        <ActivityTypesEditDialogHideAdapter
          isLoading={isLoading}
          data={dialogs.hide.data}
          onHide={onHide}
          onClose={onCloseHide}
        />
      )}
      {dialogs.modify && (
        <ActivityTypesEditDialogEditAdapter
          isLoading={isLoading}
          data={dialogs.modify.data}
          onEdit={onModify}
          onClose={onCloseModify}
        />
      )}
    </>
  );
};
