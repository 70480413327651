import { EClientOrgStatus } from '@/domain/model/enums';
import { EClientOrgActionType } from '../../types';
import { ClientOrgLifeCycleStatusScheme } from '../types';

export const adminStatusScheme: ClientOrgLifeCycleStatusScheme = {
  description: 'Статусная схема главного админа',
  statusScheme: {
    null: [EClientOrgActionType.Edit],
    [EClientOrgStatus.Active]: [EClientOrgActionType.Edit, EClientOrgActionType.Block],
    [EClientOrgStatus.Blocked]: [EClientOrgActionType.Edit, EClientOrgActionType.UnBlock],
  },
  actionsScheme: {} as any,
};

export const partnerStatusScheme = adminStatusScheme;
