import { useMemo } from 'react';
import RenderDecoratorContainer from '../../../../components/common/renderDecoratorContainer';
import {
  MasterDetailsAnyComponentType,
  MasterDetailsCommonPropsType,
  MasterDetailsProps,
  MasterDetailsProviderType,
} from './types';

const MasterDetailsContainer = <
  TLayout extends MasterDetailsAnyComponentType,
  TProvider extends MasterDetailsProviderType<TCommonProps>,
  TCommonProps extends MasterDetailsCommonPropsType
>(
  props: MasterDetailsProps<TLayout, TProvider, TCommonProps>
) => {
  const { layout, layoutProps, children, providers, commonProps, initializer: Initializer } = props;

  const layoutComponent = useMemo(() => {
    const Layout = layout as MasterDetailsAnyComponentType;
    return <Layout {...layoutProps}>{children}</Layout>;
  }, [layout, layoutProps, commonProps, children]);

  return (
    <Initializer {...commonProps}>
      {providers?.length ? (
        <RenderDecoratorContainer
          decorators={providers.map(({ name, factory: Factory }) => {
            return (
              <Factory
                key={name}
                {...commonProps}
              />
            );
          })}
        >
          {layoutComponent}
        </RenderDecoratorContainer>
      ) : (
        layoutComponent
      )}
    </Initializer>
  );
};

export default MasterDetailsContainer;
