import { ReportsTable } from '@features/report/table/component';
import { useContextConfig } from '../hooks/useContextConfig';
import { useSelector } from 'react-redux';
import { reportsSelector } from '@features/report/table/store/selectors';

export const ReportsTableAdapter = () => {
  const { onReportClick } = useContextConfig();
  const reports = useSelector(reportsSelector);

  return (
    <ReportsTable
      reports={reports}
      onReportClick={onReportClick}
    />
  );
};
