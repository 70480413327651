import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Partner } from '../../../../../domain/model/partner';
import useHistoryExtensions from '../../../../hooks/useHistoryExtensions';
import MenuPanelDrawerLayout from '../../../../layouts/menuPanel';
import theme from '../../../../theme';
import { getPartnersTableRoute } from '../../entry';
import { PartnerManagementMenuHeaderAdapter } from './adapter/header';
import { PartnerManagementMenuItemsAdapter } from './adapter/items';
import { partnerManagementMenuGuidSelector } from './store/selectors';

interface PartnerManagementMenuPanelProps {
  readonly visible: boolean;
  readonly partner: Partner;
}

const PartnerManagementMenuPanel = ({ visible, partner }: PartnerManagementMenuPanelProps) => {
  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const guid = useSelector(partnerManagementMenuGuidSelector);

  const goBack = useCallback(
    () => gotoPrevIndependentLocation(getPartnersTableRoute({})),
    [gotoPrevIndependentLocation]
  );

  return (
    <MenuPanelDrawerLayout
      visible={visible}
      guid={guid}
      backgroundColor={theme.palette.error.alpha90}
      header={
        <PartnerManagementMenuHeaderAdapter
          partner={partner}
          goBack={goBack}
        />
      }
    >
      <PartnerManagementMenuItemsAdapter partner={partner} />
    </MenuPanelDrawerLayout>
  );
};

export default PartnerManagementMenuPanel;
