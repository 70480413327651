import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SportOption } from '../../../domain/model';
import { Nullable, UUID } from '../../../domain/model/types';
import useCurrentUser from '../user/hooks/useCurrentUser';
import { lockObjectGetByIdSelector } from './store/selectors';
import { bannerLock, bannerUnlock, setLockObjectError, setLockObjectSuccess } from './store/slice';

export enum ELockObjectType {
  Banner = 'Banner',
}

export enum ELockStatus {
  Fetching = 'Fetching',
  Error = 'Error',
  Success = 'Success',
}

interface UseLockObjectProps {
  readonly id: UUID;
  readonly lockedBy: Nullable<SportOption>;
  readonly type: ELockObjectType;
  readonly initialLock?: boolean;
}

interface UseLockObject {
  readonly status: Nullable<ELockStatus>;
  readonly message: Nullable<string>;
  readonly lock: () => void;
  readonly unlock: () => void;
}

const useLockObject = (props: UseLockObjectProps): UseLockObject => {
  const { id, lockedBy, type, initialLock = true } = props;

  const dispatch = useDispatch();
  const { status, message } = useSelector(lockObjectGetByIdSelector(id));

  const { userSpecific } = useCurrentUser();

  const lockInternal = (objectId: UUID, lockObjectType: ELockObjectType) => {
    switch (lockObjectType) {
      case ELockObjectType.Banner:
        dispatch(bannerLock({ id: objectId }));
        break;
    }
  };

  const lock = () => {
    switch (type) {
      case ELockObjectType.Banner:
        dispatch(bannerLock({ id }));
        break;
    }
  };

  const unlock = () => {
    switch (type) {
      case ELockObjectType.Banner:
        dispatch(bannerUnlock({ id }));
        break;
    }
  };

  useEffect(() => {
    if (!lockedBy) {
      if (initialLock) lockInternal(id, type);
    } else if (lockedBy.id === userSpecific.id) {
      dispatch(setLockObjectSuccess({ id }));
    } else {
      dispatch(setLockObjectError({ id, message: 'Объект заблокирован другим пользователем' }));
    }

    return () => unlock();
  }, [id, lockedBy, type, initialLock, userSpecific.id]);

  return {
    status,
    message,
    lock,
    unlock,
  };
};

export default useLockObject;
