import Api from '@/data/api';
import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterValueItem,
  EDataFilterType,
  EMPUserGroup,
  EOfferType,
  Nullable,
  pageSizeAll,
  SportOption,
  SportOptionTyped,
} from '@/domain';

export enum EReportOffersStatisticByPartnerFilterItem {
  DatePeriod = 'datePeriod',
  OfferType = 'offerType',
  Partner = 'partner',
  UserGroup = 'userGroup',
}

export type ReportOffersStatisticByPartnerFilterEditStrategy =
  DataFilterStrategyBase<EReportOffersStatisticByPartnerFilterItem>;

export type ReportOffersStatisticByPartnerFilterValues = {
  readonly [EReportOffersStatisticByPartnerFilterItem.DatePeriod]?: DataFilterValueItem<
    Nullable<[Nullable<string>, Nullable<string>]>
  >;
  readonly [EReportOffersStatisticByPartnerFilterItem.OfferType]?: DataFilterValueItem<Nullable<EOfferType[]>>;
  readonly [EReportOffersStatisticByPartnerFilterItem.Partner]?: DataFilterValueItem<
    Nullable<DataFilterStrategyListItem>
  >;
  readonly [EReportOffersStatisticByPartnerFilterItem.UserGroup]?: DataFilterValueItem<Nullable<EMPUserGroup>>;
};

export const getReportOffersStatisticByPartnerFilterStrategies = (
  values: ReportOffersStatisticByPartnerFilterValues,
  partnerId: Nullable<UUID>,
  offerTypes: SportOptionTyped<EOfferType>[],
  userGroups: SportOption[]
): ReportOffersStatisticByPartnerFilterEditStrategy[] => {
  const strategies: ReportOffersStatisticByPartnerFilterEditStrategy[] = [];
  const userGroupItems: DataFilterStrategyListItem[] = (userGroups ?? []).map(({ id, name }) => ({ id, name }));

  strategies.push(
    {
      type: EDataFilterType.DatePeriod,
      key: EReportOffersStatisticByPartnerFilterItem.DatePeriod,
      label: '',
      buttonsPeriod: true,
      labelPeriod: {
        from: 'Период с',
        to: 'по',
      },
      preview: null,
      value: values[EReportOffersStatisticByPartnerFilterItem.DatePeriod]?.value || null,
    },
    {
      type: EDataFilterType.ListMultiple,
      key: EReportOffersStatisticByPartnerFilterItem.OfferType,
      label: 'Тип предложения',
      preview: `Тип предложения (${values[EReportOffersStatisticByPartnerFilterItem.OfferType]?.value?.length})`,
      previews: offerTypes
        .filter(t => values[EReportOffersStatisticByPartnerFilterItem.OfferType]?.value?.some(v => v === t.id))
        .map(t => t.name),
      value: values[EReportOffersStatisticByPartnerFilterItem.OfferType]?.value || null,
      items: offerTypes.map(t => ({ id: t.id, name: t.name })),
      querydsl: {
        operator: DataFilterQueryDslOperator.In,
      },
    },
    {
      type: EDataFilterType.ListSingle,
      key: EReportOffersStatisticByPartnerFilterItem.UserGroup,
      label: 'Тип пользователя',
      preview:
        `Тип пользователя (${
          userGroupItems.find(g => g.id === values[EReportOffersStatisticByPartnerFilterItem.UserGroup]?.value)?.name
        })` || null,
      value: values[EReportOffersStatisticByPartnerFilterItem.UserGroup]?.value || null,
      items: userGroupItems,
      querydsl: {
        operator: DataFilterQueryDslOperator.Equals,
      },
    }
  );

  if (!partnerId) {
    strategies.push({
      type: EDataFilterType.AutocompleteSingle,
      key: EReportOffersStatisticByPartnerFilterItem.Partner,
      label: 'Партнёр',
      preview: `Партнер (${values[EReportOffersStatisticByPartnerFilterItem.Partner]?.value?.name})` || null,
      value: values[EReportOffersStatisticByPartnerFilterItem.Partner]?.value || null,
      source: (name: string) =>
        name
          ? Api.partner
              .all({
                query: name,
                sort: 'name,asc',
                page: 1,
                pageSize: pageSizeAll,
              })
              .then(response => response.data)
          : Promise.resolve([]),
    });
  }

  return strategies;
};
