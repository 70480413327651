import { Typography } from '@mui/material';
import { useMemo } from 'react';
import Splitter from '../../../../components/common/splitter';
import useBusinessConfig from '../../../../hooks/useBusinessConfig';
import { convertStringToArray } from '../../../../utils';
import SupportContacts from '../../components/contacts';

const SupportInfoSportContactsAdapter = () => {
  const {
    sport: { phones, emails },
  } = useBusinessConfig().supportInfo;

  const sportPhones = convertStringToArray(phones);
  const sportEmails = convertStringToArray(emails);

  return useMemo(() => {
    return sportPhones.length + sportEmails.length > 0 ? (
      <>
        <Typography variant='h3'>Контакты разработчика</Typography>
        <Splitter
          variant='horizontal'
          size={2}
        />
        <SupportContacts
          emails={sportEmails}
          phones={sportPhones}
        />
      </>
    ) : null;
  }, [sportPhones, sportEmails]);
};

export default SupportInfoSportContactsAdapter;
