import { SportOptionTyped } from '../../../../../../domain/model';
import { EPartnerOwnershipType } from '../../../../../../domain/model/enums';
import { PartnerCompanyDataDraft, PartnerData, PartnerDataDraft } from '../../../../../../domain/model/partner';
import { Nullable } from '../../../../../../domain/model/types';
import { OnChangeObjectAttribute } from '../../../../../types';
import { ValidationResult } from '../../../../../utils/validation';
import { PartnerGeneralCompany, PartnerGeneralIndividual } from '../../../../general/partner/validation';
import { Content } from '../../controls';
import PartnerApplicationEditCompanyGeneral from './company';
import PartnerApplicationEditIndividualGeneral from './individual';

interface PartnerEditStepGeneralProps {
  readonly partner: PartnerData;
  readonly ownerShipTypes: SportOptionTyped<EPartnerOwnershipType>[];
  readonly validationCompany: Nullable<ValidationResult<PartnerGeneralCompany>>;
  readonly validationIndividual: Nullable<ValidationResult<PartnerGeneralIndividual>>;
  readonly isValid: boolean;
  readonly onChangeAttribute: OnChangeObjectAttribute<PartnerDataDraft>;
  readonly onChangeCompanyAttribute: OnChangeObjectAttribute<PartnerCompanyDataDraft>;
}

const PartnerEditStepGeneral = (props: PartnerEditStepGeneralProps) => {
  const {
    partner,
    ownerShipTypes,
    isValid,
    validationCompany,
    validationIndividual,
    onChangeAttribute,
    onChangeCompanyAttribute,
  } = props;

  const { inn, name, ownerFullName, email, website, phone } = partner;

  switch (partner.regInfo.orgType) {
    case EPartnerOwnershipType.IndividualEntrepreneur:
      return (
        <Content>
          <PartnerApplicationEditIndividualGeneral
            inn={inn}
            name={name}
            ownerFullName={ownerFullName}
            email={email}
            website={website}
            phone={phone}
            ownerShipTypes={ownerShipTypes}
            validation={validationIndividual}
            isValid={isValid}
            onChangeAttribute={onChangeAttribute}
          />
        </Content>
      );
    case EPartnerOwnershipType.StockCompany:
    case EPartnerOwnershipType.PublicJoinStockCompany:
    case EPartnerOwnershipType.PK:
    case EPartnerOwnershipType.UP:
    case EPartnerOwnershipType.FGBNU:
    case EPartnerOwnershipType.NonProfitOrganization:
    case EPartnerOwnershipType.ClosedJoinStockCompany:
    case EPartnerOwnershipType.JoinStockCompany:
    case EPartnerOwnershipType.NAO:
    case EPartnerOwnershipType.AutonomousNonProfitOrganization:
    case EPartnerOwnershipType.LimitedLiabilityCompany: {
      return (
        <Content>
          <PartnerApplicationEditCompanyGeneral
            ownerShipType={partner.regInfo.orgType}
            inn={inn}
            name={name}
            companyName={partner.regInfo.companyName}
            ownerFullName={ownerFullName}
            email={email}
            website={website}
            phone={phone}
            ownerShipTypes={ownerShipTypes}
            validation={validationCompany}
            isValid={isValid}
            onChangeAttribute={onChangeAttribute}
            onChangeCompanyAttribute={onChangeCompanyAttribute}
          />
        </Content>
      );
    }
    default: {
      return <Content></Content>;
    }
  }
};

export default PartnerEditStepGeneral;
