import styled from '@emotion/styled';
import { dialogContentClasses, paperClasses } from '@mui/material';
import ConfirmDialog from '../../../components/common/dialogs/confirm';

export const StyledDialog = styled(ConfirmDialog)`
  .${paperClasses.root}, .${dialogContentClasses.root} {
    padding: 0;
  }

  .${paperClasses.root} {
    border-radius: 0;
  }

  .${dialogContentClasses.root} {
    border-bottom: 1px solid ${p => p.theme.palette.secondary.main};
  }
`;
