import { UUID } from 'domain/model/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { adCampaignGuidSelector } from '../store/selectors';
import { adCampaignSetTab, adCampaignStartSession } from '../store/slice';
import { EAdCampaignTableTab } from '@features/adCampaign/types';

interface UseAdCampaignTableSessionProps {
  readonly guid: UUID;
  readonly tab: EAdCampaignTableTab;
}

const useAdCampaignTableSession = (props: UseAdCampaignTableSessionProps): void => {
  const { guid, tab } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(adCampaignGuidSelector);

  // инициализация сеанса
  useEffect(() => {
    dispatch(adCampaignStartSession({ guid }));
  }, [dispatch, guid]);

  // начальная загрузка, изменение вкладки
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(adCampaignSetTab({ tab }));
    }
  }, [dispatch, guid, currentGuid, tab]);
};

export default useAdCampaignTableSession;
