import { createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { PartnerDesk } from '../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { partnerDeskEditSave } from '../../edit/store/slice';

export const partnerDeskByIdFetch = createAsyncThunk<Nullable<PartnerDesk>, UUID, AppThunkAPIConfig>(
  'partnerDesk/byId/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      return (await Api.partner.desk({ partnerId: id, signal })).data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

export interface PartnerDeskByIdState extends Fetchable {
  readonly id: Nullable<UUID>;
  readonly partnerDesk: Nullable<PartnerDesk>;
}

interface Reducers extends SliceCaseReducers<PartnerDeskByIdState> {}

const slice = createSlice<PartnerDeskByIdState, Reducers, 'byId'>({
  name: 'byId',
  initialState: {
    ...fetchableDefault,
    id: null,
    partnerDesk: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(partnerDeskByIdFetch.pending, (state, { meta }) => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
        state.id = meta.arg;
        state.partnerDesk = null;
      })
      .addCase(partnerDeskByIdFetch.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.partnerDesk = payload;
      })
      .addCase(partnerDeskByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
        } else {
          state.id = null;
          state.partnerDesk = null;
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = false;
        }
      })
      .addCase(partnerDeskEditSave.fulfilled, state => {
        state.id = null;
        state.partnerDesk = null;
        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = false;
      });
  },
});

export default slice.reducer;
