import { Tabs as MuiTabs } from '@mui/material';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
`;

export const Border = styled.div`
  width: 100%;
  height: 2px;

  position: absolute;
  bottom: -1px;

  border-top: 1px solid ${p => p.theme.palette.secondary.main};
`;

export const Tabs = styled(MuiTabs)`
  background: ${p => p.theme.palette.background.default};

  & .MuiTabs-flexContainer {
    border-top: 0;
    border-bottom: 0;
  }

  & .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }

  & .MuiTabs-indicator {
    z-index: 1;
  }
`;
