import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { ServerErrorResponse } from '../../../../../../data/network/types';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../../data/store/types';
import { Pageable } from '../../../../../../domain/model';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import { MpUserShort } from '../../../../../../domain/model/user';
import { PaginationSize } from '../../../../../types';
import userServices from '../../../services';
import { AdminAllProps } from '../../../services/mpAdmin';
import { AdminMpActionTableType, EAdminMpActionType } from '../../types';
import { AdminMpFilterValues } from '../filterUtils';
import { AdminMpTableTabsCounter, EAdminMpTableColumn, EAdminMpTableTab } from '../utils';

export type AdminMpFetchProps = Omit<AdminAllProps, 'signal'>;

export type AdminMpCountsFetchProps = AdminMpFetchProps & {
  readonly tabs: EAdminMpTableTab[];
};

const defaultSort = `${EAdminMpTableColumn.Name},asc`;

const getActionProcess = (state: AdminMpListState, id: UUID, type: AdminMpActionTableType) => {
  let process = state.actions.find(change => change.id === id);
  if (process) return process;

  process = {
    ...fetchableDefault,
    id,
    type,
    error: null,
  };
  state.actions.push(process);

  return process;
};

export const adminMpsFetch = createAsyncThunk<Pageable<MpUserShort>, AdminMpFetchProps, AppThunkAPIConfig>(
  'adminMp/list/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await userServices.mpAdmin.all({ ...props, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const adminMpsCountsFetch = createAsyncThunk<
  AdminMpTableTabsCounter,
  AdminMpCountsFetchProps,
  AppThunkAPIConfig
>('adminMp/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await userServices.mpAdmin.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const adminMpsActivateAdminMp = createAsyncThunk<void, { id: UUID }, AppThunkAPIConfig<ServerErrorResponse>>(
  'adminMp/list/activateAdminMp',
  async ({ id }, { rejectWithValue }) => {
    try {
      await userServices.mpAdmin.enabled({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const adminMpsDeactivateAdminMp = createAsyncThunk<void, { id: UUID }, AppThunkAPIConfig<ServerErrorResponse>>(
  'adminMp/list/deactivateAdminMp',
  async ({ id }, { rejectWithValue }) => {
    try {
      await userServices.mpAdmin.disabled({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface AdminMpListState extends Fetchable, Pageable<MpUserShort> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
  };
  readonly filter: AdminMpFilterValues;
  readonly tabsCounter: AdminMpTableTabsCounter;
  readonly tab: Nullable<EAdminMpTableTab>;
  readonly actions: (Fetchable & {
    id: UUID;
    type: AdminMpActionTableType;
    error: Nullable<ServerErrorResponse>;
  })[];
}

type Reducer<T = undefined> = CaseReducer<AdminMpListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<AdminMpListState> {
  adminMpStartSession: Reducer<{ guid: UUID; filter?: AdminMpFilterValues }>;
  adminMpSetTab: Reducer<{ tab: EAdminMpTableTab }>;

  adminMpSetSort: Reducer<{ sort: string }>;
  adminMpSortReset: Reducer;
  adminMpSetSearch: Reducer<{ sort: string; pageSize: PaginationSize }>;
  adminMpSetFilter: Reducer<AdminMpFilterValues>;
  adminMpSetPage: Reducer<{ pageNumber: number }>;
  adminMpSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  adminMpNeedRefreshWatcherReset: Reducer;

  adminMpChangeStatusReset: Reducer;
}

const slice = createSlice<AdminMpListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    ...fetchableDefault,
    guid: null,
    tab: null,
    data: [],
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    needRefreshWatcher: 0,
    search: {
      sort: defaultSort,
      pageSize: 10,
    },
    filter: {},
    tabsCounter: {},
    actions: [],
  },
  reducers: {
    adminMpStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;

        state.tab = null;
        state.needRefreshWatcher = 0;
        state.search.sort = defaultSort;
        state.filter = {};
        state.tabsCounter = {};
        state.actions = [];
      }
    },
    adminMpSetTab: (state, { payload }) => {
      const { tab } = payload;
      // сбрасываем пейджинг и сортировку, если поменялись статусы (закладки)
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    adminMpSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    adminMpSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    adminMpSetSearch: (state, { payload }) => {
      const { sort, pageSize } = payload;
      state.search = {
        ...state.search,
        sort,
        pageSize,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    adminMpSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    adminMpSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    adminMpSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    adminMpNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
    adminMpChangeStatusReset: state => {
      state.actions = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(adminMpsFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
      })
      .addCase(adminMpsFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(adminMpsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(adminMpsCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(adminMpsCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      })
      .addCase(adminMpsActivateAdminMp.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EAdminMpActionType.Activate;

        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(adminMpsActivateAdminMp.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EAdminMpActionType.Activate;

        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(adminMpsActivateAdminMp.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EAdminMpActionType.Activate;

        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(adminMpsDeactivateAdminMp.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EAdminMpActionType.Deactivate;

        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(adminMpsDeactivateAdminMp.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EAdminMpActionType.Deactivate;

        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(adminMpsDeactivateAdminMp.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EAdminMpActionType.Deactivate;

        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      });
  },
});

export const {
  adminMpStartSession,
  adminMpSetTab,
  adminMpSetSort,
  adminMpNeedRefreshWatcherReset,
  adminMpSortReset,
  adminMpSetSearch,
  adminMpSetPage,
  adminMpSetFilter,
  adminMpSetPageSize,
  adminMpChangeStatusReset,
} = slice.actions;

export default slice.reducer;
