import { ClientOrg } from '@/domain';
import { clientOrgRzdCode } from '@features/clientOrg/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import {
  getOrgStructureClientOrgMultipleItemAllClientOrgs,
  getOrgStructureClientOrgMultipleItemNoneClientOrgs,
} from './item';
import {
  OrgStructureClientOrgMultipleAllClientOrgsState,
  OrgStructureClientOrgMultipleItemType,
  OrgStructureClientOrgMultipleSelectType,
  OrgStructureClientOrgMultipleTypesSelectorItem,
} from './types';

interface UseOrgStructureClientOrgMultipleTypesProps {
  readonly select?: Nullable<OrgStructureClientOrgMultipleSelectType>;
  readonly selectNone?: boolean;
  readonly clientOrgs: ClientOrg[];
  readonly onSelect: (type: OrgStructureClientOrgMultipleSelectType) => void;
  readonly onChange: (clientOrgs: ClientOrg[]) => void;
}

interface UseOrgStructureClientOrgMultipleTypes {
  readonly options: OrgStructureClientOrgMultipleTypesSelectorItem[];
  readonly values: OrgStructureClientOrgMultipleTypesSelectorItem[];
  readonly isLoading: boolean;
  readonly setSearchValue: (value: string) => void;
  readonly onChangeValue: (value: Nullable<OrgStructureClientOrgMultipleTypesSelectorItem[]>) => void;
  readonly getOptionLabel: (option: OrgStructureClientOrgMultipleTypesSelectorItem) => string;
  readonly onFilterOptions: (
    options: OrgStructureClientOrgMultipleTypesSelectorItem[],
    value: string
  ) => OrgStructureClientOrgMultipleTypesSelectorItem[];
}

const useOrgStructureClientOrgMultipleTypes = (
  props: UseOrgStructureClientOrgMultipleTypesProps
): UseOrgStructureClientOrgMultipleTypes => {
  const { select, selectNone, clientOrgs, onSelect, onChange } = props;

  const [suggestions, setSuggestions] = useState<ClientOrg[]>([]);
  const [allClientOrgs, setAllClientOrgs] = useState<ClientOrg[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (searchValue) {
      setSuggestions(
        allClientOrgs.filter(clientOrg =>
          clientOrg.name.toLocaleUpperCase().startsWith(searchValue.toLocaleUpperCase())
        )
      );
    }
  }, [searchValue]);

  const selectAllInternal = useMemo<boolean>(() => select === OrgStructureClientOrgMultipleSelectType.All, [select]);

  const onChangeValue = useCallback(
    (value: Nullable<OrgStructureClientOrgMultipleTypesSelectorItem[]>) => {
      if (
        value?.some(
          item =>
            item.type === OrgStructureClientOrgMultipleItemType.All &&
            item.state !== OrgStructureClientOrgMultipleAllClientOrgsState.Checked
        ) ||
        (!selectAllInternal && value?.length === allClientOrgs.length)
      ) {
        onSelect(OrgStructureClientOrgMultipleSelectType.All);
      } else if (
        value?.some(
          item =>
            item.type === OrgStructureClientOrgMultipleItemType.None &&
            item.state !== OrgStructureClientOrgMultipleAllClientOrgsState.Checked
        )
      ) {
        onSelect(OrgStructureClientOrgMultipleSelectType.None);
      } else {
        const selectedClientOrgs: ClientOrg[] = [];
        value?.forEach(item => {
          if (item.type === OrgStructureClientOrgMultipleItemType.ClientOrg) {
            selectedClientOrgs.push({ ...item });
          }
        });
        const setUnselectedAll = selectAllInternal && selectedClientOrgs.length === allClientOrgs.length;
        onChange(setUnselectedAll ? [] : selectedClientOrgs);
      }
    },
    [onChange, onSelect, allClientOrgs, selectAllInternal]
  );

  const getOptionLabel = useCallback((option: OrgStructureClientOrgMultipleTypesSelectorItem) => {
    switch (option.type) {
      case OrgStructureClientOrgMultipleItemType.ClientOrg:
        return option.name;
      default:
        return '';
    }
  }, []);

  const onFilterOptions = useCallback((options: OrgStructureClientOrgMultipleTypesSelectorItem[], value: string) => {
    const newOptions: OrgStructureClientOrgMultipleTypesSelectorItem[] = [];
    options.forEach(element => {
      if (new RegExp(`.*${value}.*`, 'gi').test(element.name)) newOptions.push(element);
    });

    //удаляем им результатов "Все дороги" если начали поиск
    if (value) {
      const allIndex = newOptions.findIndex(element => element.type === OrgStructureClientOrgMultipleItemType.All);
      if (allIndex > -1) {
        newOptions.splice(allIndex, 1);
      }
    }
    return newOptions;
  }, []);

  const options = useMemo<OrgStructureClientOrgMultipleTypesSelectorItem[]>(() => {
    const result: OrgStructureClientOrgMultipleTypesSelectorItem[] = [];

    // Если в поисковой строке чтото есть, то выводим только поиск
    if (searchValue) {
      suggestions.forEach(item =>
        result.push({
          ...item,
          type: OrgStructureClientOrgMultipleItemType.ClientOrg,
        })
      );
    } else {
      if (selectNone) {
        result.push(
          getOrgStructureClientOrgMultipleItemNoneClientOrgs(
            select === OrgStructureClientOrgMultipleSelectType.None
              ? OrgStructureClientOrgMultipleAllClientOrgsState.Checked
              : OrgStructureClientOrgMultipleAllClientOrgsState.NotChecked
          )
        );
      }
      //всегда добавляем первым "Все компании", если нет поиска
      result.push(
        getOrgStructureClientOrgMultipleItemAllClientOrgs(
          selectAllInternal
            ? OrgStructureClientOrgMultipleAllClientOrgsState.Checked
            : clientOrgs?.length
            ? OrgStructureClientOrgMultipleAllClientOrgsState.Indeterminate
            : OrgStructureClientOrgMultipleAllClientOrgsState.NotChecked
        )
      );

      // Выводим справочник организаций корпорантов
      allClientOrgs.forEach(item =>
        result.push({
          ...item,
          type: OrgStructureClientOrgMultipleItemType.ClientOrg,
        })
      );
    }
    return result;
  }, [selectAllInternal, selectNone, select, searchValue, clientOrgs, allClientOrgs, suggestions]);

  const values = useMemo<OrgStructureClientOrgMultipleTypesSelectorItem[]>(() => {
    const result: OrgStructureClientOrgMultipleTypesSelectorItem[] = [];

    if (selectAllInternal) {
      result.push(
        getOrgStructureClientOrgMultipleItemAllClientOrgs(OrgStructureClientOrgMultipleAllClientOrgsState.Checked)
      );
      allClientOrgs.forEach(item =>
        result.push({
          ...item,
          type: OrgStructureClientOrgMultipleItemType.ClientOrg,
        })
      );
    } else if (select === OrgStructureClientOrgMultipleSelectType.None) {
      result.push(
        getOrgStructureClientOrgMultipleItemNoneClientOrgs(OrgStructureClientOrgMultipleAllClientOrgsState.Checked)
      );
    } else {
      clientOrgs?.forEach(item => {
        result.push({
          ...item,
          type: OrgStructureClientOrgMultipleItemType.ClientOrg,
        });
      });
    }

    return result;
  }, [selectAllInternal, allClientOrgs, clientOrgs, select]);

  useEffect(() => {
    setIsLoading(true);

    Api.clientOrg
      .all({
        page: 1,
        pageSize: 9999,
        sort: 'name',
      })
      .then(({ data }) => setAllClientOrgs(data.sort((a, b) => (a.code === clientOrgRzdCode ? -1 : 1))))
      .catch(e => {
        console.error(e);
        ErrorHandler.handleHttpError(e, e.response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return {
    options,
    values,
    isLoading,
    setSearchValue,
    onChangeValue,
    getOptionLabel,
    onFilterOptions,
  };
};

export default useOrgStructureClientOrgMultipleTypes;
