import { EOrderItemStatus } from '../../../../../../../domain/model/enums';
import { Nullable } from '../../../../../../../domain/model/types';
import DataTableCellTextFormat, {
  DataTableCellTextFormatProps,
} from '../../../../../../components/common/table/cell/textFormat';
import { getProductOrderItemStatusName, statusesProductOfferTableValue } from '../../../../utils';

export interface ProductOrderItemTableCellImageProps extends DataTableCellTextFormatProps {
  readonly status: Nullable<EOrderItemStatus>;
}

export const ProductOrderItemTableCellStatus = (props: ProductOrderItemTableCellImageProps) => {
  const { status } = props;
  return status && statusesProductOfferTableValue.includes(status) ? (
    <DataTableCellTextFormat>{getProductOrderItemStatusName(status)}</DataTableCellTextFormat>
  ) : null;
};
