import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { MpUserShort } from '../../../../../../domain/model/user';
import { getAdminMpDetailsRoute } from '../../entry';
import { EAdminMpTableTab } from '../utils';

export type UseAdminMpTableConfig = {
  readonly defaultTab: EAdminMpTableTab;
  readonly tabsForCounts: EAdminMpTableTab[];
  readonly onAdminClick: (admin: MpUserShort) => void;
};

export function useAdminMpTableConfig(): UseAdminMpTableConfig {
  const history = useHistory();

  const onAdminClick = useCallback(
    (admin: MpUserShort) => {
      history.push(getAdminMpDetailsRoute({ id: admin.id }));
    },
    [history]
  );

  const defaultTab = EAdminMpTableTab.Active;
  const tabsForCounts = Object.values(EAdminMpTableTab);

  return {
    defaultTab,
    tabsForCounts,
    onAdminClick,
  };
}