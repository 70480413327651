import NotFoundContainer from '../../features/notFound/container';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const NotFoundScreen = () => {
  const { isAdminMp, isPartnerUser } = useCurrentUser().accessMatrix;

  if (isAdminMp || isPartnerUser) {
    return <NotFoundContainer />;
  }

  return null;
};

export default NotFoundScreen;
