import { EPartnerDiscriminator } from '../../../../../domain/model/enums';
import PartnerTableCell, { PartnerTableCellCommonProps } from './index';

const PartnerTableCellKpp = (props: PartnerTableCellCommonProps) => {
  const {
    partner: { regInfo },
  } = props;

  const value = regInfo?.discriminator === EPartnerDiscriminator.PartnerCompanyData ? regInfo.kpp : '-';

  return (
    <PartnerTableCell
      {...props}
      value={value}
    />
  );
};

export default PartnerTableCellKpp;
