import { BookingOrderEditManagerCommentField } from '../fields/managerComment';
import { bookingOrderEditBookingOrderManagerCommentSelector } from '../store/selectors';
import { BookingOrderEditFieldAdapter } from './field';
import FormItemAdapter from './formItem';
import { ContentLimiterHintLayout } from '../../../../components/common/contentLimit/layouts';
import { ContentLimiter } from '../../../../components/common/contentLimit';

const descriptionText = 'Чтобы изменить статус заказа, сначала выберите статус для каждой услуги';

export const BookingOrderEditStatusSectionAdapter = () => {
  return (
    <FormItemAdapter
      gridSize={4}
      title='Комментарий менеджера'
    >
      <ContentLimiter
        layout={ContentLimiterHintLayout}
        layoutProps={{ collapsedTitle: descriptionText, expandedTitle: descriptionText }}
      >
        <BookingOrderEditFieldAdapter
          name={'managerComment'}
          component={BookingOrderEditManagerCommentField}
          valueSelector={bookingOrderEditBookingOrderManagerCommentSelector}
        />
      </ContentLimiter>
    </FormItemAdapter>
  );
};
