import { StepConnector, StepLabel, Stepper } from '@mui/material';
import { FCC, useCallback } from 'react';
import { EMPStepperTransitionMethod, MPStepperLabelStrategy, MPStepperStep } from '../types';
import { Step, StepperWrapper } from './controls';
import { StepIcon } from './stepicon';

interface DecoratorProps<T extends string | number> {
  readonly step: MPStepperStep<T>;
}

export interface MPStepperProps<T extends string | number> {
  readonly step: MPStepperStep<T>;
  readonly steps: MPStepperStep<T>[];
  readonly flow?: boolean;
  readonly forwardTransition?: EMPStepperTransitionMethod;
  readonly backwardTransition?: EMPStepperTransitionMethod;
  readonly labelStrategy?: MPStepperLabelStrategy;
  readonly decorators?: {
    readonly icon?: FCC<DecoratorProps<T>>;
    readonly labelOptional?: FCC<DecoratorProps<T>>;
  };
  readonly onClick?: (step: MPStepperStep<T>) => void;
}

const EmptyDecorator: FCC = props => <>{props.children}</>;

export const MPStepper = <T extends string | number>({
  step,
  steps,
  flow = true,
  forwardTransition = EMPStepperTransitionMethod.Free,
  backwardTransition = EMPStepperTransitionMethod.Free,
  labelStrategy = MPStepperLabelStrategy.Default,
  decorators,
  onClick,
}: MPStepperProps<T>) => {
  const IconDecorator = decorators?.icon ?? EmptyDecorator;
  const LabelOptionalDecorator = decorators?.labelOptional ?? EmptyDecorator;

  const activeStepIndex = steps?.findIndex(item => item.key === step?.key);

  const getLabelIndex = useCallback(
    (index: number) => {
      switch (labelStrategy) {
        case MPStepperLabelStrategy.FromZero:
          return index.toString(10);
        default:
          return (index + 1).toString(10);
      }
    },
    [labelStrategy]
  );

  const isStepEnabled = useCallback(
    (index: number): boolean => {
      if (activeStepIndex === undefined) {
        return EMPStepperTransitionMethod.Free && backwardTransition === EMPStepperTransitionMethod.Free;
      } else {
        // todo index === activeStep return false? чтобы не щелкать по текущему шагу
        const transition = index > activeStepIndex ? forwardTransition : backwardTransition;
        switch (transition) {
          case EMPStepperTransitionMethod.Free:
            return true;
          case EMPStepperTransitionMethod.None:
            return false;
          case EMPStepperTransitionMethod.Neighbour:
            return Math.abs(index - activeStepIndex) === 1;
        }
      }
    },
    [activeStepIndex]
  );

  return (
    <StepperWrapper flow={flow}>
      <Stepper
        activeStep={activeStepIndex}
        orientation='vertical'
        nonLinear={!flow}
        connector={<StepConnector />}
      >
        {steps.map((item, index) => (
          <Step
            key={index}
            completed={flow ? index < activeStepIndex : false}
            disabled={flow ? undefined : true}
            onClick={isStepEnabled(index) && onClick ? () => onClick(item) : undefined}
          >
            <StepLabel
              icon={
                <IconDecorator step={steps[index]}>
                  <StepIcon
                    completed={flow ? index < activeStepIndex : false}
                    flow={flow}
                    active={activeStepIndex === index}
                    placeholder={getLabelIndex(index)}
                  />
                </IconDecorator>
              }
              optional={<LabelOptionalDecorator step={steps[index]} />}
            >
              {item.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </StepperWrapper>
  );
};
