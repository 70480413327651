import { BadgeProps } from '@mui/material/Badge/Badge';
import { FCC } from 'react';
import { Wrapper } from './controls';

interface ValidationBadgeAdapterProps extends Omit<BadgeProps, 'invisible'> {
  readonly hasError?: boolean;
}

const ValidationBadgeAdapter: FCC<ValidationBadgeAdapterProps> = ({ hasError, ...others }) => {
  return (
    <Wrapper
      invisible={!hasError}
      variant='dot'
      color='error'
      {...others}
    />
  );
};

export default ValidationBadgeAdapter;
