import {
  Address,
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterStrategyView,
  DataFilterValueItem,
  EDataFilterType,
  EDateFormat,
  EFilterMultiSelectorValueType,
  EUserGender,
  EUserRole,
  FilterMultiSelectorPredicate,
  Nullable,
  OrgUnit,
  pageSizeAll,
  RzhdRoad,
  SportOptionTyped,
  Target,
} from '@/domain';
import moment from 'moment';
import Api from '../../../data/api';
import { getUserFullName } from '../../utils';
import { AddressHelper } from '../../utils/address';
import { getDataFilterTargetPreviews } from '../../utils/filtering';
import { ECorpOfferTableColumn } from './types';
import { getApprovalRegistryPreview } from './utils';

export enum ECorpOfferTableFilterItem {
  Query = 'query',
  Code = 'code',
  Name = 'name',
  Partner = 'partnerDesk.partner.id',
  Responsible = 'responsiblePerson.id',
  StoreResponsible = 'storeResponsiblePerson.fullName',
  ApprovalRegistry = 'approvalRegistry.id',
  StartDate = 'startDate',
  EndDate = 'endDate',
  NotUsedOfferCount = 'notUsedOfferCount',
  OfferCount = 'offerCount',
  Moderator = 'moderator.id',
  Places = 'places',
  TargetTradeUnionMembersOnly = 'targetTradeUnionMembersOnly',
  TargetHavingChildFamilyMemberOnly = 'targetHavingChildFamilyMemberOnly',
  TargetFamilyMemberOnly = 'targetFamilyMemberOnly',
  Road = 'roads',
  OrgUnit = 'targetOrgUnits',
  TargetGender = 'targetGender',
  Inn = 'partner.inn',
  Kpp = 'partner.kpp',
  Ogrn = 'partner.ogrn',
  Target = 'target',
}

export const corpOfferTableFilterItems: Record<ECorpOfferTableColumn, ECorpOfferTableFilterItem[]> = {
  [ECorpOfferTableColumn.Code]: [ECorpOfferTableFilterItem.Code],
  [ECorpOfferTableColumn.Name]: [ECorpOfferTableFilterItem.Name, ECorpOfferTableFilterItem.Target],
  [ECorpOfferTableColumn.Partner]: [ECorpOfferTableFilterItem.Partner],
  [ECorpOfferTableColumn.PartnerType]: [],
  [ECorpOfferTableColumn.Period]: [ECorpOfferTableFilterItem.StartDate, ECorpOfferTableFilterItem.EndDate],
  [ECorpOfferTableColumn.Price]: [],
  [ECorpOfferTableColumn.OfferCounts]: [
    ECorpOfferTableFilterItem.NotUsedOfferCount,
    ECorpOfferTableFilterItem.OfferCount,
  ],
  [ECorpOfferTableColumn.Ndfl]: [],
  [ECorpOfferTableColumn.PartnerResponsible]: [ECorpOfferTableFilterItem.Responsible],
  [ECorpOfferTableColumn.StoreResponsible]: [ECorpOfferTableFilterItem.StoreResponsible],
  [ECorpOfferTableColumn.Moderator]: [ECorpOfferTableFilterItem.Moderator],
  [ECorpOfferTableColumn.LastStatusDate]: [],
  [ECorpOfferTableColumn.ApprovalRegistry]: [ECorpOfferTableFilterItem.ApprovalRegistry],
  [ECorpOfferTableColumn.Categories]: [],
  [ECorpOfferTableColumn.PromotionType]: [],
  [ECorpOfferTableColumn.TargetRoads]: [ECorpOfferTableFilterItem.Road],
  [ECorpOfferTableColumn.TargetOrgUnits]: [ECorpOfferTableFilterItem.OrgUnit],
  [ECorpOfferTableColumn.TargetTradeUnionMembersOnly]: [ECorpOfferTableFilterItem.TargetTradeUnionMembersOnly],
  [ECorpOfferTableColumn.TargetHavingChildFamilyMemberOnly]: [
    ECorpOfferTableFilterItem.TargetHavingChildFamilyMemberOnly,
  ],
  [ECorpOfferTableColumn.TargetFamilyMemberOnly]: [ECorpOfferTableFilterItem.TargetFamilyMemberOnly],
  [ECorpOfferTableColumn.TargetGender]: [ECorpOfferTableFilterItem.TargetGender],
  [ECorpOfferTableColumn.Status]: [],
  [ECorpOfferTableColumn.LastStatusAuthor]: [],
  [ECorpOfferTableColumn.PausedReason]: [],
  [ECorpOfferTableColumn.TargetLocalities]: [ECorpOfferTableFilterItem.Places],

  [ECorpOfferTableColumn.Inn]: [ECorpOfferTableFilterItem.Inn],
  [ECorpOfferTableColumn.Kpp]: [ECorpOfferTableFilterItem.Kpp],
  [ECorpOfferTableColumn.Ogrn]: [ECorpOfferTableFilterItem.Ogrn],
};

export type CorpOfferTableFilterEditStrategy = DataFilterStrategyBase<ECorpOfferTableFilterItem>;
export type CorpOfferTableFilterViewStrategy = DataFilterStrategyView<ECorpOfferTableFilterItem>;
export type CorpOfferTableFilterValues = {
  readonly [ECorpOfferTableFilterItem.Query]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECorpOfferTableFilterItem.Code]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECorpOfferTableFilterItem.Name]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECorpOfferTableFilterItem.Partner]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
  readonly [ECorpOfferTableFilterItem.Responsible]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
  readonly [ECorpOfferTableFilterItem.StoreResponsible]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECorpOfferTableFilterItem.Road]?: DataFilterValueItem<Nullable<FilterMultiSelectorPredicate<OrgUnit>>>;
  readonly [ECorpOfferTableFilterItem.OrgUnit]?: DataFilterValueItem<Nullable<FilterMultiSelectorPredicate<OrgUnit>>>;
  readonly [ECorpOfferTableFilterItem.ApprovalRegistry]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
  readonly [ECorpOfferTableFilterItem.StartDate]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECorpOfferTableFilterItem.EndDate]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECorpOfferTableFilterItem.NotUsedOfferCount]?: DataFilterValueItem<Nullable<number>>;
  readonly [ECorpOfferTableFilterItem.OfferCount]?: DataFilterValueItem<Nullable<number>>;
  readonly [ECorpOfferTableFilterItem.Moderator]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
  readonly [ECorpOfferTableFilterItem.Places]?: DataFilterValueItem<Nullable<FilterMultiSelectorPredicate<Address>>>;
  readonly [ECorpOfferTableFilterItem.TargetTradeUnionMembersOnly]?: DataFilterValueItem<Nullable<true>>;
  readonly [ECorpOfferTableFilterItem.TargetHavingChildFamilyMemberOnly]?: DataFilterValueItem<Nullable<true>>;
  readonly [ECorpOfferTableFilterItem.TargetFamilyMemberOnly]?: DataFilterValueItem<Nullable<true>>;
  readonly [ECorpOfferTableFilterItem.TargetGender]?: DataFilterValueItem<
    Nullable<FilterMultiSelectorPredicate<EUserGender>>
  >;
  readonly [ECorpOfferTableFilterItem.Inn]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECorpOfferTableFilterItem.Kpp]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECorpOfferTableFilterItem.Ogrn]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECorpOfferTableFilterItem.Target]?: DataFilterValueItem<Nullable<Target>>;
};

const getOrgUnitsPreview = (orgUnits: Nullable<FilterMultiSelectorPredicate<OrgUnit>>) => {
  if (!orgUnits) return null;

  switch (orgUnits.select) {
    case EFilterMultiSelectorValueType.All:
      return 'Все филиалы';
    case EFilterMultiSelectorValueType.None:
      return 'Филиал не выбран';
    case EFilterMultiSelectorValueType.In:
      return `Филиал (${orgUnits.in?.length})` || null;
  }
};

const getRoadsPreview = (roads: Nullable<FilterMultiSelectorPredicate<RzhdRoad>>) => {
  if (!roads) return null;

  switch (roads.select) {
    case EFilterMultiSelectorValueType.All:
      return 'Все железные дороги';
    case EFilterMultiSelectorValueType.None:
      return 'Железная дорога не выбрана';
    case EFilterMultiSelectorValueType.In:
      return `Железная дорога (${roads.in?.length})` || null;
  }
};

const getPlacesPreview = (places: Nullable<FilterMultiSelectorPredicate<Address>>) => {
  if (!places) return null;

  switch (places.select) {
    case EFilterMultiSelectorValueType.All:
      return 'Вся Россия';
    case EFilterMultiSelectorValueType.None:
      return 'Город не выбран';
    case EFilterMultiSelectorValueType.In:
      return `Город (${places.in?.length})` || null;
  }
};

const getGenderPreview = (
  genders: Nullable<FilterMultiSelectorPredicate<EUserGender>>,
  genderTypes: SportOptionTyped<EUserGender>[]
) => {
  if (!genders) return null;

  switch (genders.select) {
    case EFilterMultiSelectorValueType.All:
      return 'Для всех полов';
    case EFilterMultiSelectorValueType.None:
      return 'Пол не выбран';
    case EFilterMultiSelectorValueType.In:
      return `Пол (${genders.in?.length})` || null;
  }
};

export const getCorpOfferTableFilterStrategy = (
  partnerId: Nullable<UUID>,
  filterItem: ECorpOfferTableFilterItem,
  values: CorpOfferTableFilterValues,
  genderTypes: SportOptionTyped<EUserGender>[]
): Nullable<CorpOfferTableFilterEditStrategy> => {
  try {
    switch (filterItem) {
      case ECorpOfferTableFilterItem.Target:
        return {
          type: EDataFilterType.Target,
          key: ECorpOfferTableFilterItem.Target,
          label: 'Доступность',
          preview: 'Доступность *',
          previews:
            getDataFilterTargetPreviews(values[ECorpOfferTableFilterItem.Target]?.value ?? null, {
              genderTypes,
            }) ?? [],
          value: values[ECorpOfferTableFilterItem.Target]?.value || null,
        };
      case ECorpOfferTableFilterItem.Code:
        return {
          type: EDataFilterType.String,
          key: ECorpOfferTableFilterItem.Code,
          label: 'Код КП',
          preview: values[ECorpOfferTableFilterItem.Code]?.value || null,
          value: values[ECorpOfferTableFilterItem.Code]?.value || null,
          querydsl: {
            operator: DataFilterQueryDslOperator.Like,
          },
        };
      case ECorpOfferTableFilterItem.Name:
        return {
          type: EDataFilterType.String,
          key: ECorpOfferTableFilterItem.Name,
          label: 'Название',
          preview: values[ECorpOfferTableFilterItem.Name]?.value || null,
          value: values[ECorpOfferTableFilterItem.Name]?.value || null,
          querydsl: {
            operator: DataFilterQueryDslOperator.Like,
          },
        };
      case ECorpOfferTableFilterItem.Partner:
        return {
          type: EDataFilterType.AutocompleteMultiple,
          key: ECorpOfferTableFilterItem.Partner,
          label: 'Партнёр',
          value: values[ECorpOfferTableFilterItem.Partner]?.value || null,
          preview: `Партнер (${values[ECorpOfferTableFilterItem.Partner]?.value?.length})` || null,
          previews: values[ECorpOfferTableFilterItem.Partner]?.value?.map(t => t.name) ?? [],
          source: (name: string) =>
            name
              ? Api.partner
                  .all({
                    query: name,
                    sort: 'name,asc',
                    page: 1,
                    pageSize: pageSizeAll,
                  })
                  .then(response => response.data)
              : Promise.resolve([]),
          querydsl: {
            operator: DataFilterQueryDslOperator.In,
            valueAttribute: 'id',
          },
        };
      case ECorpOfferTableFilterItem.StartDate:
        return {
          type: EDataFilterType.DateFrom,
          key: ECorpOfferTableFilterItem.StartDate,
          group: 'date',
          label: 'Начало',
          preview: values[ECorpOfferTableFilterItem.StartDate]?.value
            ? `с ${moment(values[ECorpOfferTableFilterItem.StartDate]?.value).format(EDateFormat.DisplayDefault)}`
            : null,
          value: values[ECorpOfferTableFilterItem.StartDate]?.value ?? null,
          querydsl: {
            operator: DataFilterQueryDslOperator.MoreOrEquals,
          },
        };
      case ECorpOfferTableFilterItem.EndDate:
        return {
          type: EDataFilterType.DateTo,
          key: ECorpOfferTableFilterItem.EndDate,
          group: 'date',
          label: 'Окончание',
          preview: values[ECorpOfferTableFilterItem.EndDate]?.value
            ? `по ${moment(values[ECorpOfferTableFilterItem.EndDate]?.value).format(EDateFormat.DisplayDefault)}`
            : null,
          value: values[ECorpOfferTableFilterItem.EndDate]?.value ?? null,
          querydsl: {
            operator: DataFilterQueryDslOperator.LessOrEquals,
          },
        };
      case ECorpOfferTableFilterItem.Road:
        return {
          type: EDataFilterType.MultiSelectorPredicateRoad,
          key: ECorpOfferTableFilterItem.Road,
          label: 'Железная дорога',
          value: values[ECorpOfferTableFilterItem.Road]?.value || null,
          preview: getRoadsPreview(values[ECorpOfferTableFilterItem.Road]?.value ?? null),
          previews: values[ECorpOfferTableFilterItem.Road]?.value?.in?.map(t => t.name) ?? [],
          querydsl: {
            operator: DataFilterQueryDslOperator.In,
            valueAttribute: 'id',
          },
        };
      case ECorpOfferTableFilterItem.Places:
        return {
          type: EDataFilterType.MultiSelectorPredicateAddress,
          key: ECorpOfferTableFilterItem.Places,
          label: 'Город',
          value: values[ECorpOfferTableFilterItem.Places]?.value || null,
          preview: getPlacesPreview(values[ECorpOfferTableFilterItem.Places]?.value ?? null),
          previews:
            values[ECorpOfferTableFilterItem.Places]?.value?.in?.map(
              locality => new AddressHelper(locality).getLastLocalityShortPath() ?? locality.name
            ) ?? null,
          querydsl: {
            operator: DataFilterQueryDslOperator.In,
            valueAttribute: 'id',
          },
        };
      case ECorpOfferTableFilterItem.OrgUnit:
        return {
          type: EDataFilterType.MultiSelectorPredicateOrgUnit,
          key: ECorpOfferTableFilterItem.OrgUnit,
          label: 'Филиал',
          value: values[ECorpOfferTableFilterItem.OrgUnit]?.value || null,
          preview: getOrgUnitsPreview(values[ECorpOfferTableFilterItem.OrgUnit]?.value ?? null),
          previews: values[ECorpOfferTableFilterItem.OrgUnit]?.value?.in?.map(t => t.name) ?? null,
          querydsl: {
            operator: DataFilterQueryDslOperator.In,
            valueAttribute: 'id',
          },
        };
      case ECorpOfferTableFilterItem.ApprovalRegistry:
        return {
          type: EDataFilterType.AutocompleteMultiple,
          key: ECorpOfferTableFilterItem.ApprovalRegistry,
          label: 'Приказ',
          value: values[ECorpOfferTableFilterItem.ApprovalRegistry]?.value || null,
          preview: `Приказ (${values[ECorpOfferTableFilterItem.ApprovalRegistry]?.value?.length})` || null,
          previews: values[ECorpOfferTableFilterItem.ApprovalRegistry]?.value?.map(t => t.name) || null,
          source: (name: string) =>
            name
              ? Api.offerApprovalRegistry
                  .all({
                    querydsl: [
                      {
                        field: 'number',
                        operator: DataFilterQueryDslOperator.Like,
                        value: [`%${name}%`],
                      },
                    ],
                    sort: 'number,asc',
                    page: 1,
                    pageSize: pageSizeAll,
                  })
                  .then(response =>
                    response.data.map(item => ({ id: item.id, name: getApprovalRegistryPreview(item) }))
                  )
              : Promise.resolve([]),
          querydsl: {
            operator: DataFilterQueryDslOperator.In,
            valueAttribute: 'id',
          },
        };
      case ECorpOfferTableFilterItem.Responsible:
        return {
          type: EDataFilterType.AutocompleteMultiple,
          key: ECorpOfferTableFilterItem.Responsible,
          label: 'Ответственный за формирование',
          value: values[ECorpOfferTableFilterItem.Responsible]?.value || null,
          preview:
            `Ответственный за формирование (${values[ECorpOfferTableFilterItem.Responsible]?.value?.length})` || null,
          previews: values[ECorpOfferTableFilterItem.Responsible]?.value?.map(t => t.name) ?? [],
          source: (name: string) =>
            name
              ? (partnerId
                  ? Api.partner.responsiblePerson.all({
                      query: name,
                      sort: 'fullName,asc',
                      page: 1,
                      partnerId,
                      pageSize: pageSizeAll,
                    })
                  : Api.partner.responsiblePersons({
                      query: name,
                      sort: 'fullName,asc',
                      page: 1,
                      pageSize: pageSizeAll,
                    })
                ).then(response => response.data.map(item => ({ ...item, name: item.fullName ?? '' })))
              : Promise.resolve([]),
          querydsl: {
            operator: DataFilterQueryDslOperator.In,
            valueAttribute: 'id',
          },
        };
      case ECorpOfferTableFilterItem.StoreResponsible:
        return {
          type: EDataFilterType.String,
          key: ECorpOfferTableFilterItem.StoreResponsible,
          label: 'Ответственный за выдачу',
          preview: values[ECorpOfferTableFilterItem.StoreResponsible]?.value || null,
          value: values[ECorpOfferTableFilterItem.StoreResponsible]?.value || null,
          querydsl: {
            operator: DataFilterQueryDslOperator.Like,
          },
        };
      case ECorpOfferTableFilterItem.NotUsedOfferCount:
        return {
          type: EDataFilterType.Number,
          key: ECorpOfferTableFilterItem.NotUsedOfferCount,
          label: 'Доступно кодов',
          preview: `Доступно кодов ${values[ECorpOfferTableFilterItem.NotUsedOfferCount]?.value?.toString()}`,
          value: values[ECorpOfferTableFilterItem.NotUsedOfferCount]?.value ?? null,
          querydsl: {
            operator: DataFilterQueryDslOperator.Equals,
          },
        };
      case ECorpOfferTableFilterItem.OfferCount:
        return {
          type: EDataFilterType.Number,
          key: ECorpOfferTableFilterItem.OfferCount,
          label: 'Всего кодов',
          preview: `Всего кодов ${values[ECorpOfferTableFilterItem.OfferCount]?.value?.toString()}`,
          value: values[ECorpOfferTableFilterItem.OfferCount]?.value ?? null,
          querydsl: {
            operator: DataFilterQueryDslOperator.Equals,
          },
        };
      case ECorpOfferTableFilterItem.Moderator:
        return {
          type: EDataFilterType.AutocompleteMultiple,
          key: ECorpOfferTableFilterItem.Moderator,
          label: 'Модератор',
          value: values[ECorpOfferTableFilterItem.Moderator]?.value || null,
          preview: `Модератор (${values[ECorpOfferTableFilterItem.Moderator]?.value?.length})` || null,
          previews: values[ECorpOfferTableFilterItem.Moderator]?.value?.map(t => t.name) ?? [],
          source: (name: string) =>
            name
              ? Api.user.mp
                  .all({
                    query: name,
                    page: 1,
                    pageSize: pageSizeAll,
                    sort: ['lastName,asc', 'firstName,asc', 'middleName,asc'],
                    roles: [EUserRole.AdminMp, EUserRole.AdminMpCorp],
                  })
                  .then(response =>
                    response.data.map(item => ({
                      ...item,
                      name: getUserFullName({ user: item }),
                    }))
                  )
              : Promise.resolve([]),
          querydsl: {
            operator: DataFilterQueryDslOperator.In,
            valueAttribute: 'id',
          },
        };
      case ECorpOfferTableFilterItem.TargetTradeUnionMembersOnly:
        return {
          type: EDataFilterType.BooleanFlag,
          key: ECorpOfferTableFilterItem.TargetTradeUnionMembersOnly,
          label: 'Только для членов профсоюза',
          preview: values[ECorpOfferTableFilterItem.TargetTradeUnionMembersOnly]?.value
            ? 'Только для членов профсоюза'
            : null,
          value: values[ECorpOfferTableFilterItem.TargetTradeUnionMembersOnly]?.value || null,
          querydsl: {
            operator: DataFilterQueryDslOperator.Equals,
          },
        };
      case ECorpOfferTableFilterItem.TargetHavingChildFamilyMemberOnly:
        return {
          type: EDataFilterType.BooleanFlag,
          key: ECorpOfferTableFilterItem.TargetHavingChildFamilyMemberOnly,
          label: 'Только если есть член семьи (ребенок до 18 лет)',
          preview: values[ECorpOfferTableFilterItem.TargetHavingChildFamilyMemberOnly]?.value
            ? 'Только если есть член семьи (ребенок до 18 лет)'
            : null,
          value: values[ECorpOfferTableFilterItem.TargetHavingChildFamilyMemberOnly]?.value || null,
          querydsl: {
            operator: DataFilterQueryDslOperator.Equals,
          },
        };
      case ECorpOfferTableFilterItem.TargetGender:
        return {
          type: EDataFilterType.MultiSelectorPredicateSportOption,
          key: ECorpOfferTableFilterItem.TargetGender,
          label: 'Пол',
          selectAllLabel: 'Для всех полов',
          items: genderTypes,
          value: values[ECorpOfferTableFilterItem.TargetGender]?.value || null,
          preview: getGenderPreview(values[ECorpOfferTableFilterItem.TargetGender]?.value ?? null, genderTypes),
          previews:
            values[ECorpOfferTableFilterItem.TargetGender]?.value?.in?.map(
              t => genderTypes.find(item => item.id === t)?.name ?? t
            ) ?? null,
          querydsl: {
            operator: DataFilterQueryDslOperator.In,
          },
        };
      case ECorpOfferTableFilterItem.TargetFamilyMemberOnly:
        return {
          type: EDataFilterType.BooleanFlag,
          key: ECorpOfferTableFilterItem.TargetFamilyMemberOnly,
          label: 'Только если есть член семьи от 18 лет',
          preview: values[ECorpOfferTableFilterItem.TargetFamilyMemberOnly]?.value
            ? 'Только если есть член семьи от 18 лет'
            : null,
          value: values[ECorpOfferTableFilterItem.TargetFamilyMemberOnly]?.value || null,
          querydsl: {
            operator: DataFilterQueryDslOperator.Equals,
          },
        };
      case ECorpOfferTableFilterItem.Query:
        return {
          type: EDataFilterType.String,
          key: ECorpOfferTableFilterItem.Query,
          label: 'Поиск по ключевому слову',
          preview: values[ECorpOfferTableFilterItem.Query]?.value || null,
          value: values[ECorpOfferTableFilterItem.Query]?.value || null,
        };

      case ECorpOfferTableFilterItem.Inn:
        return {
          type: EDataFilterType.String,
          key: ECorpOfferTableFilterItem.Inn,
          label: 'ИНН',
          value: values[ECorpOfferTableFilterItem.Inn]?.value || null,
          preview: values[ECorpOfferTableFilterItem.Inn]?.value || null,
          querydsl: {
            operator: DataFilterQueryDslOperator.In,
          },
        };
      case ECorpOfferTableFilterItem.Kpp:
        return {
          type: EDataFilterType.String,
          key: ECorpOfferTableFilterItem.Kpp,
          label: 'КПП',
          value: values[ECorpOfferTableFilterItem.Kpp]?.value || null,
          preview: values[ECorpOfferTableFilterItem.Kpp]?.value || null,
          querydsl: {
            operator: DataFilterQueryDslOperator.In,
          },
        };
      case ECorpOfferTableFilterItem.Ogrn:
        return {
          type: EDataFilterType.String,
          key: ECorpOfferTableFilterItem.Ogrn,
          label: 'ОГРН',
          value: values[ECorpOfferTableFilterItem.Ogrn]?.value || null,
          preview: values[ECorpOfferTableFilterItem.Ogrn]?.value || null,
          querydsl: {
            operator: DataFilterQueryDslOperator.In,
          },
        };
      default:
        return null;
    }
  } catch (e) {
    console.error(e);
    return null;
  }
};
