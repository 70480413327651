export enum ETradeActivationTableColumn {
  CreatedAt = 'createdAt',
  OfferCode = 'offer.code',
  OfferName = 'offer.name',
  PromotionType = 'promotionType',
  PromotionCode = 'promotion.code',
  Partner = 'offer.partner.name',
  CustomerName = 'customer.lastName',
  CustomerEmail = 'customer.email',
  CustomerPhone = 'customer.phone',
}
