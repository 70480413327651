import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../data/store/types';
import { XLSXImportResult } from '../../../../../domain/model';
import { Nullable, UUID } from '../../../../../domain/model/types';
import offerServices from '../../../general/offer/services';

export const tradeOffersUploadSave = createAsyncThunk<XLSXImportResult, { id: UUID; file: File }, AppThunkAPIConfig>(
  'tradeOffer/upload/save',
  async ({ id, file }, { rejectWithValue }) => {
    try {
      return await offerServices.trade.import({ id, file });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface TradeOffersUploadState {
  readonly save: Fetchable & {
    readonly data: Nullable<XLSXImportResult>;
  };
}

interface Reducers extends SliceCaseReducers<TradeOffersUploadState> {
  tradeOffersUploadStateReset: CaseReducer<TradeOffersUploadState, PayloadAction<undefined>>;
}

const slice = createSlice<TradeOffersUploadState, Reducers, 'upload'>({
  name: 'upload',
  initialState: {
    save: {
      ...fetchableDefault,
      data: null,
    },
  },
  reducers: {
    tradeOffersUploadStateReset: state => {
      state.save = {
        ...fetchableDefault,
        data: null,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(tradeOffersUploadSave.pending, state => {
        state.save = {
          ...fetchableFetching,
          data: null,
        };
      })
      .addCase(tradeOffersUploadSave.fulfilled, (state, { payload }) => {
        state.save = {
          ...fetchableFetched,
          data: payload,
        };
      })
      .addCase(tradeOffersUploadSave.rejected, state => {
        state.save = {
          ...fetchableFailed,
          data: null,
        };
      });
  },
});

export const { tradeOffersUploadStateReset } = slice.actions;

export default slice.reducer;
