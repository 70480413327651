import { RootState } from '@/data/store/store';
import { Nullable, UUID } from '@/domain/model/types';
import { createSelector } from '@reduxjs/toolkit';
import { EAdCampaignActionType } from '../../types';

export const adCampaignActionsActionsSelector = (store: RootState) => store.adCampaign.actions.actions;

export const adCampaignActionsIsCreatingSelector = (store: RootState) => store.adCampaign.actions.create.isFetching;

export const adCampaignActionsDialogsSelector = (store: RootState) => store.adCampaign.actions.dialogs;

export const adCampaignActionsDialogSaveSelector = (store: RootState) => store.adCampaign.actions.dialogs.save;

export const adCampaignActionsDialogPauseSelector = (store: RootState) => store.adCampaign.actions.dialogs.pause;

export const adCampaignActionsDialogArchiveSelector = (store: RootState) => store.adCampaign.actions.dialogs.archive;

export const adCampaignActionsDialogResumeSelector = (store: RootState) => store.adCampaign.actions.dialogs.resume;

export const getAdCampaignActionsAdCampaignIsProcessingSelector = (id: Nullable<UUID>) =>
  createSelector(
    (store: RootState) => store.adCampaign.actions.actions,
    (store: RootState) => store.adCampaign.actions.create.isFetching,
    (actions, isCreating) => {
      return id ? actions.some(action => action.id === id && action.isFetching) : isCreating;
    }
  );

export const isAdCampaignsAnyChangedSelector = (store: RootState) =>
  store.adCampaign.actions.actions.filter(action => !action.isFetching).length +
  +!store.adCampaign.actions.create.isFetching;

export const getIsAdCampaignAnyActionByTypeExecutedSelector = (
  id: Nullable<UUID>,
  ...actions: EAdCampaignActionType[]
) =>
  createSelector(
    () => id,
    () => actions,
    (store: RootState) => store.adCampaign.actions.actions,
    (store: RootState) => store.adCampaign.actions.create.isFetched,
    (id, argActions, storeActions, isCreated) => {
      return id
        ? storeActions.find(action => action.id === id && action.isFetched && argActions.some(a => a === action.type))
            ?.type ?? null
        : isCreated && argActions.some(a => a === EAdCampaignActionType.Edit)
        ? EAdCampaignActionType.Edit
        : null;
    }
  );
