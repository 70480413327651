import { FormControlLabel, Grid, Radio } from '@mui/material';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { BannerPlace, BannerRequest } from '../../../../../../domain/model/banner';
import { ETargetType } from '../../../../../../domain/model/enums';
import { EDateFormat } from '../../../../../../domain/model/formats';
import { Target } from '../../../../../../domain/model/target';
import { Nullable } from '../../../../../../domain/model/types';
import FileInfo from '../../../../../components/common/files/info';
import FileUploader from '../../../../../components/common/files/uploader';
import Splitter from '../../../../../components/common/splitter';
import { fileTypes } from '../../../../../constants';
import { MPFormInput } from '../../../../../theme/ui-kit/input';
import { MPDatePicker } from '../../../../../theme/ui-kit/picker';
import { MPRadioGroup } from '../../../../../theme/ui-kit/radio';
import { OnChangeObjectAttribute } from '../../../../../types';
import { ValidationResult } from '../../../../../utils/validation';
import { bannerTargetByCorpStructureAttributes, bannerTargetByLocalitiesAttributes } from '../../../utils';
import BannerEditTargetAdapter from '../../adapters/target';
import { FormWrapper } from './controls';

interface BannerEditStepGeneralProps {
  readonly banner: BannerRequest;
  readonly validation: Nullable<ValidationResult<BannerRequest>>;
  readonly validationTarget: Nullable<ValidationResult<Target>>;
  readonly places: BannerPlace[];
  readonly targets: { id: ETargetType; name: string }[];
  readonly desktopImageDescription: string;
  readonly mobileImageDescription: string;
  readonly onFilterDesktopImage: (file: File) => Promise<Nullable<File>>;
  readonly onFilterMobileImage: (file: File) => Promise<Nullable<File>>;
  readonly onChangeTarget: Nullable<(value: ETargetType) => void>;
  readonly onChangeAttribute: OnChangeObjectAttribute<BannerRequest>;
  readonly onChangeTargetAttribute: OnChangeObjectAttribute<Target>;
}

const BannerEditStepGeneral = (props: BannerEditStepGeneralProps) => {
  const {
    places,
    banner,
    targets,
    validation,
    validationTarget,
    desktopImageDescription,
    mobileImageDescription,
    onFilterDesktopImage,
    onFilterMobileImage,
    onChangeAttribute,
    onChangeTargetAttribute,
    onChangeTarget,
  } = props;

  const { placeCodes, target, desktopFormatImage, mobileFormatImage, name, url, startDate, endDate } = banner;

  // пока тут только один вариант возможен
  const place = places.find(p => placeCodes?.[0] === p.code)!;

  return (
    <div>
      <Typography
        variant='subtitle2'
        gutterBottom
      >
        Баннер
      </Typography>
      <Splitter
        variant='horizontal'
        size={1}
      />
      <FormWrapper
        container
        spacing={2}
        direction='column'
      >
        <Grid item>
          <MPFormInput
            label='Формат'
            value={place?.name}
            disabled
            error={!!validation?.placeCodes?.hasError}
            helperText={validation?.placeCodes?.message}
          />
        </Grid>

        <Grid item>
          {desktopFormatImage && (
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                xs={12}
              >
                <FileInfo
                  id={desktopFormatImage.path}
                  onRemove={() => onChangeAttribute('desktopFormatImage', null)}
                />
              </Grid>
            </Grid>
          )}
          {!desktopFormatImage && (
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                xs={12}
              >
                <FileUploader
                  text={
                    <div>
                      <Typography
                        color='primary'
                        align='center'
                      >
                        Загрузить изображение для десктопного формата
                      </Typography>
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        align='center'
                      >
                        {desktopImageDescription}
                      </Typography>
                    </div>
                  }
                  fileTypes={[fileTypes.png.mimeType, fileTypes.jpg.mimeType, fileTypes.jpeg.mimeType]}
                  accept={[fileTypes.png.ext, fileTypes.jpg.ext, fileTypes.jpeg.ext]}
                  error={!!validation?.desktopFormatImage?.hasError}
                  helperText={validation?.desktopFormatImage?.message}
                  onFilter={files => {
                    if (files.length > 0) {
                      return onFilterDesktopImage(files[0]).then(result => (result ? [result] : []));
                    }
                    return Promise.resolve(files);
                  }}
                  onUpload={file => onChangeAttribute('desktopFormatImage', { path: file.path })}
                />
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item>
          {mobileFormatImage && (
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                xs={12}
              >
                <FileInfo
                  id={mobileFormatImage.path}
                  onRemove={() => onChangeAttribute('mobileFormatImage', null)}
                />
              </Grid>
            </Grid>
          )}
          {!mobileFormatImage && (
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                xs={12}
              >
                <FileUploader
                  text={
                    <div>
                      <Typography
                        color='primary'
                        align='center'
                      >
                        Загрузить изображение для мобильного формата
                      </Typography>
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        align='center'
                      >
                        {mobileImageDescription}
                      </Typography>
                    </div>
                  }
                  fileTypes={[fileTypes.png.mimeType, fileTypes.jpg.mimeType, fileTypes.jpeg.mimeType]}
                  accept={[fileTypes.png.ext, fileTypes.jpg.ext, fileTypes.jpeg.ext]}
                  error={!!validation?.mobileFormatImage?.hasError}
                  helperText={validation?.mobileFormatImage?.message}
                  onFilter={files => {
                    if (files.length > 0) {
                      return onFilterMobileImage(files[0]).then(result => (result ? [result] : []));
                    }
                    return Promise.resolve(files);
                  }}
                  onUpload={file => onChangeAttribute('mobileFormatImage', { path: file.path })}
                />
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item>
          <MPFormInput
            label='Название'
            value={name ?? ''}
            error={!!validation?.name?.hasError}
            helperText={validation?.name?.message}
            onChange={event => onChangeAttribute('name', event.target.value)}
          />
        </Grid>

        <Grid item>
          <MPFormInput
            label='URL-ссылка'
            value={url ?? ''}
            error={!!validation?.url?.hasError}
            helperText={validation?.url?.message}
            onChange={event => onChangeAttribute('url', event.target.value)}
          />
        </Grid>
      </FormWrapper>

      <Splitter
        variant='horizontal'
        size={4}
      />
      <Typography
        variant='subtitle2'
        gutterBottom
      >
        Доступность
      </Typography>

      {onChangeTarget && (
        <>
          <Splitter
            variant='horizontal'
            size={1}
          />
          <MPRadioGroup<ETargetType>
            value={target.targetType}
            onChange={value => onChangeTarget(value)}
          >
            <Grid
              container
              spacing={3}
            >
              {targets.map(t => (
                <Grid
                  key={t.id}
                  item
                >
                  <FormControlLabel
                    value={t.id}
                    control={<Radio color='primary' />}
                    label={t.name}
                  />
                </Grid>
              ))}
            </Grid>
          </MPRadioGroup>
          <Splitter
            variant='horizontal'
            size={2}
          />
        </>
      )}

      <Grid
        container
        spacing={2}
        direction='column'
      >
        <Grid item>
          {target.targetType === ETargetType.Geo && (
            <BannerEditTargetAdapter
              {...bannerTargetByLocalitiesAttributes}
              target={target}
              validation={validationTarget}
              onChangeAttribute={onChangeTargetAttribute}
            />
          )}

          {target.targetType === ETargetType.Corp && (
            <BannerEditTargetAdapter
              {...bannerTargetByCorpStructureAttributes}
              target={target}
              validation={validationTarget}
              onChangeAttribute={onChangeTargetAttribute}
            />
          )}
        </Grid>
      </Grid>

      <Splitter
        variant='horizontal'
        size={3}
      />
      <Typography
        variant='subtitle2'
        gutterBottom
      >
        Срок размещения
      </Typography>
      <Splitter
        variant='horizontal'
        size={1}
      />
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={6}
        >
          <MPDatePicker
            value={startDate || null}
            label='Начало'
            format={EDateFormat.DisplayDefault}
            error={!!validation?.startDate?.hasError}
            helperText={validation?.startDate?.message}
            onChange={date => {
              const d = date === null ? null : moment(date).format(EDateFormat.Server);
              onChangeAttribute('startDate', d);
            }}
          />
        </Grid>

        <Grid
          item
          xs={6}
        >
          <MPDatePicker
            value={endDate || null}
            label='Окончание'
            format={EDateFormat.DisplayDefault}
            error={!!validation?.endDate?.hasError}
            helperText={validation?.endDate?.message}
            onChange={date => {
              const d = date === null ? null : moment(date).format(EDateFormat.Server);
              onChangeAttribute('endDate', d);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default BannerEditStepGeneral;
