import ProductTableCell, { ProductTableCellCommonProps } from './index';

const ProductTableCellBrand = (props: ProductTableCellCommonProps) => {
  const {
    productOffer: { brand },
  } = props;

  const value = brand?.name;

  return (
    <ProductTableCell
      {...props}
      value={value}
    />
  );
};

export default ProductTableCellBrand;
