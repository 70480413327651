import { EUserStatus } from '../../../../domain/model/enums';
import { MpUser } from '../../../../domain/model/user';
import { DataTableColumns, MutableDataTableColumns } from '../../../components/common/table';
import { TableActionProcess } from '../../../components/common/table/types';
import {
  EPartnerEmployeeActionType,
  GetPartnerEmployeeActionsByPermissions,
  PartnerEmployeeActions,
  PartnerEmployeeActionTableType,
} from '../types';
import { getPartnerEmployeeActionsConfigByPermissions } from '../utils';

export type PartnerEmployeeTableActionProcess = TableActionProcess<PartnerEmployeeActionTableType>;

export enum EPartnerEmployeeTableTab {
  Active = 'active',
  Deactivated = 'deactivated',
}

export enum EPartnerEmployeeTableColumn {
  Name = 'lastName',
  Phone = 'phone',
  Email = 'email',
  Owner = 'owner',
}

const partnerEmployeesTableColumns: DataTableColumns<EPartnerEmployeeTableColumn> = {
  [EPartnerEmployeeTableColumn.Name]: {
    title: 'Контактное лицо',
    width: '25rem',
    wrap: true,
    sortable: true,
  },
  [EPartnerEmployeeTableColumn.Phone]: {
    title: 'Телефон',
    width: '15rem',
    sortable: true,
  },
  [EPartnerEmployeeTableColumn.Email]: {
    title: 'Электронная почта',
    width: '15rem',
    wrap: true,
    sortable: true,
  },
  [EPartnerEmployeeTableColumn.Owner]: {
    title: 'Ответственное лицо',
    width: '10rem',
  },
};

const partnerEmployeeTableDefaultColumns = [
  EPartnerEmployeeTableColumn.Name,
  EPartnerEmployeeTableColumn.Phone,
  EPartnerEmployeeTableColumn.Email,
  EPartnerEmployeeTableColumn.Owner,
];

export const getPartnerEmployeeTableColumns = (
  tab: EPartnerEmployeeTableTab
): MutableDataTableColumns<EPartnerEmployeeTableColumn> => {
  const columns: MutableDataTableColumns<EPartnerEmployeeTableColumn> = {};

  const defaultColumns = partnerEmployeeTableDefaultColumns;
  defaultColumns.forEach(column => (columns[column] = partnerEmployeesTableColumns[column]));

  return columns;
};

type EmployeeActions = PartnerEmployeeActions<EPartnerEmployeeActionType>;
type EmployeeTableActions = PartnerEmployeeActions<PartnerEmployeeActionTableType>;

const filterPartnerEmployeeTableActions = (actions: EmployeeActions): EmployeeTableActions => {
  const tableActions: EmployeeTableActions = [];

  actions.forEach(action => {
    switch (action.type) {
      case EPartnerEmployeeActionType.Activate:
        tableActions.push({
          type: action.type,
          disabled: action.disabled,
          label: 'Включить',
          position: action.position,
        });
        break;
      case EPartnerEmployeeActionType.Deactivate:
        tableActions.push({
          type: action.type,
          disabled: action.disabled,
          label: 'Отключить',
          position: action.position,
        });
        break;
      case EPartnerEmployeeActionType.ChangeResponsible:
        tableActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          position: action.position,
        });
        break;
      case EPartnerEmployeeActionType.Edit:
      case EPartnerEmployeeActionType.Save:
        break;
    }
  });

  return tableActions;
};

const getPartnerEmployeeTableTabActionsConfig = (tab: EPartnerEmployeeTableTab, actions: EmployeeTableActions) => {
  switch (tab) {
    case EPartnerEmployeeTableTab.Active:
      return actions.filter(action =>
        [EPartnerEmployeeActionType.Deactivate, EPartnerEmployeeActionType.ChangeResponsible].includes(action.type)
      );
    case EPartnerEmployeeTableTab.Deactivated:
      return actions.filter(action => [EPartnerEmployeeActionType.Activate].includes(action.type));
    default:
      return [];
  }
};

export type PartnerEmployeeTableActionCall = {
  readonly action: PartnerEmployeeActionTableType;
  readonly employee: MpUser;
};

export const getPartnerEmployeeTableTabActions = (
  permissions: GetPartnerEmployeeActionsByPermissions,
  tab: EPartnerEmployeeTableTab
): EmployeeTableActions => {
  const allowedActions = getPartnerEmployeeActionsConfigByPermissions(permissions);
  const actions = filterPartnerEmployeeTableActions(allowedActions);
  return getPartnerEmployeeTableTabActionsConfig(tab, actions);
};

export type PartnerEmployeeTableTabsCounter = {
  [name in EPartnerEmployeeTableTab]?: number;
};

export const getPartnerEmployeeStatusesByTableTab = (tab: EPartnerEmployeeTableTab): EUserStatus[] => {
  switch (tab) {
    case EPartnerEmployeeTableTab.Active:
      return [EUserStatus.Enabled];
    case EPartnerEmployeeTableTab.Deactivated:
      return [EUserStatus.Disabled];
  }
};

export const getPartnerEmployeeTableTabName = (tab: EPartnerEmployeeTableTab): string => {
  switch (tab) {
    case EPartnerEmployeeTableTab.Active:
      return 'Активные';
    case EPartnerEmployeeTableTab.Deactivated:
      return 'Отключённые';
  }
};
