import moment from 'moment';
import { EDateFormat } from '../../../../../domain/model/formats';
import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from './index';

const CorpOfferTableCellLastStatusDate = (props: CorpOfferTableCellCommonProps) => {
  const {
    corpOffer: { lastStatusDate },
  } = props;

  const value = lastStatusDate ? moment(lastStatusDate).format(EDateFormat.DisplayDefault) : '-';

  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellLastStatusDate;
