import { getLabelPartnerType } from '../../table/utils';
import BookingOfferTableCell, { BookingOfferTableCellCommonProps } from './index';

const BookingOfferTableCellPartnerType = (props: BookingOfferTableCellCommonProps) => {
  const {
    bookingOffer: {
      partner: { type: value },
    },
  } = props;

  return (
    <BookingOfferTableCell
      {...props}
      value={getLabelPartnerType(value)}
    />
  );
};

export default BookingOfferTableCellPartnerType;
