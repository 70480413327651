import { Grid, Typography } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { PartnersCollection } from '../../../../../../domain/model/partner';
import { UUID } from '../../../../../../domain/model/types';
import { ButtonLink } from '../../../../../components/common/buttons/link';
import SectionTitle from '../../../../../components/common/sectionTitle';
import Splitter from '../../../../../components/common/splitter';
import { RefreshIcon } from '../../../../../icons';
import { ArrayElementType } from '../../../../../types';
import PartnerCollection from '../../../../general/partner/components/collection';
import { CollectionActionsContainer } from './controls';

type CmsPartnerMultipleCollectionComponentProps = {
  readonly partners: PartnersCollection;
  readonly onChangeSortIndex?: (partner: ArrayElementType<PartnersCollection>, sortIndex: number) => void;
  readonly onShowPartnerSearch?: () => void;
  readonly onClearPartners?: () => void;
  readonly onRemovePartner?: (id: UUID) => void;
};

const CmsPartnerMultipleCollectionComponent = (props: CmsPartnerMultipleCollectionComponentProps) => {
  const { partners, onShowPartnerSearch, onClearPartners, onRemovePartner } = props;

  return (
    <Grid
      item
      xs={12}
    >
      <Splitter
        variant='horizontal'
        size={1}
      />
      <SectionTitle>Партнёры</SectionTitle>
      {partners.length > 0 && (
        <PartnerCollection<ArrayElementType<PartnersCollection>>
          partners={props.partners}
          onRemove={onRemovePartner}
          onChangeSortIndex={props.onChangeSortIndex}
        />
      )}
      <CollectionActionsContainer>
        {onShowPartnerSearch && (
          <ButtonLink onClick={() => onShowPartnerSearch()}>
            <AddBoxOutlinedIcon />
            <Typography>Выбрать партнёров</Typography>
          </ButtonLink>
        )}

        {onClearPartners && partners.length > 0 && (
          <ButtonLink onClick={() => onClearPartners()}>
            <RefreshIcon />
            <Typography>Очистить список</Typography>
          </ButtonLink>
        )}
      </CollectionActionsContainer>
    </Grid>
  );
};

export default CmsPartnerMultipleCollectionComponent;
