import { ProductOffer } from '../../../domain/model/productOffer';
import { MpUser } from '../../../domain/model/user';
import { MPStepperStep } from '../../components/common/stepper';
import { PanelActions } from '../../types';

export type ProductOfferModeratorPreview = MpUser & {
  readonly name: string;
};

export enum EProductActionType {
  Publish = 'publish',
  ChangeModerator = 'changeModerator',
  Reject = 'reject',
  Approve = 'approve',
  UnPublish = 'unPublish',
  UnPublishDesk = 'unPublishDesk',
  Pause = 'pause',
  PauseDesk = 'pauseDesk',
  Resume = 'resume',
  ResumeDesk = 'resumeDesk',
  ChangeStock = 'changeStock',
  Archive = 'archive',
  ArchiveDesk = 'archiveDesk',
  Duplicate = 'duplicate',
  Delete = 'delete',
  ChangePrice = 'changePrice',
  Edit = 'edit',
  InWork = 'inWork',
  UnPublishAndEdit = 'unPublishAndEdit',
  ChangeCategory = 'сhangeCategory',
}

export type ProductActionTableType =
  | EProductActionType.Publish
  | EProductActionType.ChangePrice
  | EProductActionType.ChangeStock
  | EProductActionType.Edit
  | EProductActionType.UnPublish
  | EProductActionType.UnPublishDesk
  | EProductActionType.Duplicate
  | EProductActionType.Delete
  | EProductActionType.Resume
  | EProductActionType.ResumeDesk
  | EProductActionType.Pause
  | EProductActionType.PauseDesk
  | EProductActionType.Reject
  | EProductActionType.Archive
  | EProductActionType.ArchiveDesk
  | EProductActionType.ChangeModerator
  | EProductActionType.Approve;

export type ProductActionTableCommonType = ProductActionTableType;

export type ProductActionTableCollectionType = EProductActionType.Approve | EProductActionType.Resume;

export type ProductActionCreateType =
  | EProductActionType.Edit
  | EProductActionType.Publish
  | EProductActionType.Delete
  | EProductActionType.Duplicate
  | EProductActionType.ChangeStock
  | EProductActionType.ChangePrice
  | EProductActionType.Resume
  | EProductActionType.ResumeDesk
  | EProductActionType.Archive
  | EProductActionType.ArchiveDesk
  | EProductActionType.Pause
  | EProductActionType.PauseDesk
  | EProductActionType.Reject
  | EProductActionType.UnPublish
  | EProductActionType.ChangeCategory
  | EProductActionType.UnPublishDesk;

export type ProductActionEditType =
  | EProductActionType.Archive
  | EProductActionType.ArchiveDesk
  | EProductActionType.ChangePrice
  | EProductActionType.ChangeStock
  | EProductActionType.Delete
  | EProductActionType.Duplicate
  | EProductActionType.Pause
  | EProductActionType.PauseDesk
  | EProductActionType.Publish
  | EProductActionType.Reject
  | EProductActionType.Resume
  | EProductActionType.ResumeDesk
  | EProductActionType.Edit
  | EProductActionType.UnPublish
  | EProductActionType.ChangeCategory
  | EProductActionType.UnPublishDesk;

export type ProductActionDetailsType =
  | EProductActionType.Approve
  | EProductActionType.Archive
  | EProductActionType.ArchiveDesk
  | EProductActionType.ChangeModerator
  | EProductActionType.ChangePrice
  | EProductActionType.ChangeStock
  | EProductActionType.Delete
  | EProductActionType.Duplicate
  | EProductActionType.Edit
  | EProductActionType.InWork
  | EProductActionType.Pause
  | EProductActionType.PauseDesk
  | EProductActionType.Reject
  | EProductActionType.Resume
  | EProductActionType.ResumeDesk
  | EProductActionType.Publish
  | EProductActionType.UnPublish
  | EProductActionType.UnPublishAndEdit
  | EProductActionType.UnPublishDesk;

export type ActionProductType = ProductOffer;

export type ProductActions<A extends string = EProductActionType> = PanelActions<A>;

export interface ProductActionProcess<Type = EProductActionType> {
  readonly isFetching: boolean;
  readonly type: Type;
}

export enum EProductStep {
  General = 'general', // Карточка товара
  Variant = 'variant', // Вариант товара
  Specifications = 'specifications', // Общие характеристики
  Description = 'description', // Описание
  PaymentAndDelivery = 'paymentAndDelivery', // Оплата и доставка
}

export type ProductStep = MPStepperStep<EProductStep>;
