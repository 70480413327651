import { ContentTarget, Target } from '@/domain';
import { useSelector } from 'react-redux';
import { ContentTargetComponentsType } from '../../../general/contentTarget/types';
import { convertTargetToContentTarget } from '../../../general/contentTarget/utils';
import ContentTargetViewContainer from '../../../general/contentTarget/view/container';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';

type BannerViewTargetAdapterProps<T extends Target> = ContentTargetComponentsType & {
  readonly target: T;
};

const BannerViewTargetAdapter = <T extends Target>(props: BannerViewTargetAdapterProps<T>) => {
  const { target, ...components } = props;

  const { userGenders: genderTypes } = useSelector(nsiDataSelector);

  const contentTarget: ContentTarget = convertTargetToContentTarget(target);

  return (
    <ContentTargetViewContainer
      {...components}
      target={contentTarget}
      nsi={{ genderTypes }}
    />
  );
};

export default BannerViewTargetAdapter;
