import { FCC } from 'react';
import { Wrapper } from './controls';

interface EntityMasterPreviewCarouselProps {}

const EntityMasterPreviewCarousel: FCC<EntityMasterPreviewCarouselProps> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default EntityMasterPreviewCarousel;
