import { RootState } from '@/data/store/store';
import { CustomerOrderCancellationReasonType, EOfferType, OrderCancellationReasonType, SportOption } from '@/domain';
import { createSelector } from '@reduxjs/toolkit';

export const nsiSelector = (store: RootState) => store.nsi;
export const nsiDataSelector = (store: RootState) => store.nsi.data;
export const nsiCustomOfferPausedReasonsSelector = createSelector(
  (store: RootState) => store.nsi.data.offerPauseReasons,
  offerPauseReasons => offerPauseReasons.filter(reason => !reason.system)
);

type CancelReasonsFilterProps = {
  readonly reasons: CustomerOrderCancellationReasonType[] | OrderCancellationReasonType[];
  readonly offerType: EOfferType;
};

export const nsiOrderCancelReasonsSelector = (store: RootState) => store.nsi.data.orderCancelReasons;
export const nsiCustomerOrderCancelReasonsSelector = (store: RootState) => store.nsi.data.customerOrderCancelReasons;

export const nsiOrderCancelReasonsFilter = (
  reasons: CancelReasonsFilterProps['reasons'],
  offerType: CancelReasonsFilterProps['offerType']
): SportOption[] =>
  reasons
    // Фильтруем массив по определенному типу заказа
    .filter(r => r.offerType === offerType || r.offerType === null)
    // Сортируем массив, что бы объекты типа null оказались в конце
    .sort((a, b) => {
      if (a.offerType === null) return 1;
      if (b.offerType === null) return -1;
      return 0;
    })
    // Возвращаем новый вид массива приведенный к типу SportOption
    .map(item => ({
      id: item.id,
      name: item.name,
      code: item.offerType,
    }));

// Booking order cancel reasons
export const nsiBookingOrderCancelReasonsSelector = createSelector(nsiOrderCancelReasonsSelector, reasons =>
  nsiOrderCancelReasonsFilter(reasons, EOfferType.Booking)
);
export const nsiBookingCustomerOrderCancelReasonsSelector = createSelector(
  nsiCustomerOrderCancelReasonsSelector,
  reasons => nsiOrderCancelReasonsFilter(reasons, EOfferType.Booking)
);
