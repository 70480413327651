import { combineReducers } from '@reduxjs/toolkit';
import detailsAdmin, { PartnerDetailsAdminState } from './admin/store/slice';
import detailsOwner, { PartnerDetailsOwnerState } from './owner/store/slice';

export interface PartnerDetailsState {
  owner: PartnerDetailsOwnerState;
  admin: PartnerDetailsAdminState;
}

export default combineReducers<PartnerDetailsState>({
  owner: detailsOwner,
  admin: detailsAdmin,
});
