import Api from '../../../../data/api';
import { ActivityTypeActionDiscriminator, ActivityTypeUpdateDiscriminator } from '../../../../data/api/activityType';
import { ApiCancellable } from '../../../../data/api/types';
import { EActivityTypeStatus } from '../../../../domain/model/enums';
import { ActivityType } from '../../../../domain/model/event';
import { Nullable, UUID } from '../../../../domain/model/types';

type DataType = ActivityType;

type CreateProps = DataType;

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type AllProps = ApiCancellable & {
  readonly statuses?: EActivityTypeStatus[];
};

type AllActiveProps = ApiCancellable;

type AllActiveSportProps = ApiCancellable;

type UpdateProps = {
  readonly id: UUID;
  readonly data: DataType;
};

type SortProps = {
  readonly parentId: Nullable<UUID>;
  readonly data: DataType[];
};

type HideProps = DataType;

type ShowProps = DataType;

type DeleteProps = DataType;

type ActivityTypesService = {
  readonly create: (props: CreateProps) => Promise<DataType>;
  readonly all: (props: AllProps) => Promise<DataType[]>;
  readonly allActive: (props: AllActiveProps) => Promise<DataType[]>;
  readonly allActiveSport: (props: AllActiveSportProps) => Promise<DataType[]>;
  readonly one: (props: OneProps) => Promise<DataType>;
  readonly update: (props: UpdateProps) => Promise<DataType>;
  readonly delete: (props: DeleteProps) => Promise<void>;
  readonly hide: (props: HideProps) => Promise<DataType>;
  readonly show: (props: ShowProps) => Promise<DataType>;
  readonly sort: (props: SortProps) => Promise<void>;
};

const statusesAll = Object.values(EActivityTypeStatus);

const service: ActivityTypesService = {
  create: async data => {
    const { data: result } = await Api.activityType.create({
      name: data.name,
      parentId: data.parent?.id ?? null,
      image: data.image,
    });
    return result;
  },
  all: async ({ statuses = statusesAll, signal }) => {
    const { data: result } = await Api.activityType.all({ statuses, signal });
    return result;
  },
  allActive: async ({ signal }) => {
    const statuses: EActivityTypeStatus[] = [EActivityTypeStatus.Enabled];
    const { data: result } = await Api.activityType.all({ statuses, signal });
    return result;
  },
  allActiveSport: async ({ signal }) => {
    const statuses: EActivityTypeStatus[] = [EActivityTypeStatus.Enabled];
    const { data: result } = await Api.activityType.all({ statuses, sport: true, signal });
    return result;
  },
  one: async ({ id, signal }) => {
    const { data: result } = await Api.activityType.one({ id, signal });
    return result;
  },
  update: async ({ id, data }) => {
    const { data: result } = await Api.activityType.update({
      discriminator: ActivityTypeUpdateDiscriminator.UpdateActivityTypeCommand,
      id,
      data,
    });
    return result;
  },
  delete: async ({ id }) => {
    const { data: result } = await Api.activityType.delete({ id });
    return result;
  },
  hide: async data => {
    const newData: DataType = {
      ...data,
      status: EActivityTypeStatus.Disabled,
    };
    const { data: result } = await Api.activityType.update({
      discriminator: ActivityTypeUpdateDiscriminator.ChangeActivityTypeStatusCommand,
      id: data.id,
      data: newData,
    });
    return result;
  },
  show: async data => {
    const newData: DataType = {
      ...data,
      status: EActivityTypeStatus.Enabled,
    };
    const { data: result } = await Api.activityType.update({
      discriminator: ActivityTypeUpdateDiscriminator.ChangeActivityTypeStatusCommand,
      id: data.id,
      data: newData,
    });
    return result;
  },
  sort: async ({ parentId, data }) => {
    if (parentId) {
      await Api.activityType.update({
        discriminator: ActivityTypeUpdateDiscriminator.SortChildrenActivityTypesCommand,
        id: parentId,
        data,
      });
    } else {
      await Api.activityType.action({
        discriminator: ActivityTypeActionDiscriminator.SortRootActivityTypesCommand,
        data,
      });
    }
  },
};

export default service;
