import BookingOrderTableCell, { BookingOrderTableCellCommonProps } from './index';

const BookingOrderTableCellCancelInitiator = (props: BookingOrderTableCellCommonProps) => {
  const {
    bookingOrder: { cancelReason },
  } = props;

  const cancelInitiator = cancelReason ? 'Менеджер' : 'Клиент';

  return (
    <BookingOrderTableCell
      {...props}
      value={cancelInitiator}
    />
  );
};

export default BookingOrderTableCellCancelInitiator;
