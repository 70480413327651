import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../data/store/store';
import { SportOptionTyped } from '../../../../domain/model';
import { Address } from '../../../../domain/model/address';
import { EOrderItemStatus, EPaymentType } from '../../../../domain/model/enums';
import { ProductOrder, ProductOrderItem, ProductOrderStatus } from '../../../../domain/model/order';
import { ProductOffer } from '../../../../domain/model/productOffer';
import { Nullable, UUID } from '../../../../domain/model/types';
import Splitter from '../../../components/common/splitter';
import ValidationHintPanel from '../../../components/common/validation/hintPanel';
import { InternalValidateResult } from '../../../hooks/validation/utils';
import { MPFormTextArea } from '../../../theme/ui-kit/input';
import { MPInputButton } from '../../../theme/ui-kit/input/complex';
import { MPMenuItem } from '../../../theme/ui-kit/menu/item';
import { MPSelect } from '../../../theme/ui-kit/select';
import { formatCurrency } from '../../../utils';
import { createEmptyAddress, createEmptyDraftAddress } from '../../../utils/address';
import { Modifier, ModifierArray } from '../../../utils/modifier';
import AddressFullSingleSelector from '../../address/components/selector/single/full';
import { AutocompleteProductButton } from '../../products/components/autocompleteProductButton';
import { concatProductName } from '../../products/utils/helpers';
import ProductOrderDetailsInfo from '../components/detailsInfo';
import { ColumnWrapper, Content, Wrapper } from './controls';
import ProductOrderItemsContainer from './items/container';
import {
  productOrderEditDeliveryAddressSelector,
  productOrderEditItemsSelector,
  productOrderEditWarningsSelector,
} from './store/selectors';
import { productOrderAppendItem, ProductOrderWarnings } from './store/slice';

interface ProductOrderEditProps {
  readonly order: ProductOrder;
  readonly statuses: SportOptionTyped<ProductOrderStatus>[];
  readonly onChangeField: Modifier<ProductOrder>;
  readonly onChangeFieldItem: ModifierArray<ProductOrderItem>;
  readonly onReplaceFieldItem: (offerId: UUID, value: Nullable<ProductOrderItem>) => void;
  readonly onSetWarning: Modifier<ProductOrderWarnings>;

  onValidateDeliveryAddress(deliveryAddress: Nullable<Address>): InternalValidateResult<Address>;
}

const ProductOrderEdit = (props: ProductOrderEditProps) => {
  const {
    order,
    statuses,
    onChangeField,
    onChangeFieldItem,
    onReplaceFieldItem,
    onSetWarning,
    onValidateDeliveryAddress,
  } = props;
  const orderItems = useSelector(productOrderEditItemsSelector);
  const warnings = useSelector(productOrderEditWarningsSelector);
  const deliveryAddress = useSelector(productOrderEditDeliveryAddressSelector);

  const { customer, createdAt, status, lastStatusComment, partner } = order;
  const dispatch = useAppDispatch();
  const [deliveryCost, setDeliveryCost] = useState(order.deliveryCost);

  const totalCost =
    (orderItems?.reduce<number>(
      (acc, item) => acc + (item.status && item.status !== EOrderItemStatus.Cancelled && item.cost ? item.cost : 0),
      0
    ) ?? 0) + (order.deliveryCost || 0);

  const currency: EPaymentType = EPaymentType.Rub;

  const handleAppendItem = useCallback((item: ProductOffer) => {
    dispatch(
      productOrderAppendItem({
        cost: item.price,
        name: concatProductName(item.name, item.variantName),
        qty: 1,
        offer: item,
        discount: null,
        originalCost: item.originalPrice,
        isDelete: true,
        image: item.images?.[0] || null,
        status: EOrderItemStatus.Confirmed,
      })
    );
  }, []);

  const address = useMemo(() => {
    return deliveryAddress ? deliveryAddress : createEmptyAddress();
  }, [deliveryAddress]);

  return (
    <Wrapper>
      <ColumnWrapper>
        <Grid
          container
          spacing={2}
          direction='column'
        >
          <Grid item>
            <Typography variant={'h4'}>Сведения о заказе</Typography>
          </Grid>
          <Grid item>
            <Content>
              <ProductOrderDetailsInfo
                customer={customer}
                date={createdAt}
              />
            </Content>
          </Grid>

          <Grid item />
          <Grid item>
            <Typography variant={'h4'}>Состав заказа</Typography>
          </Grid>
          <Grid item>
            <ProductOrderItemsContainer
              items={order.items}
              onChangeFieldItem={onChangeFieldItem}
              onSetWarning={onSetWarning}
              onReplaceFieldItem={onReplaceFieldItem}
            />
          </Grid>
          <Grid item>
            <Content>
              <AutocompleteProductButton
                partnerId={partner.id}
                onExecute={handleAppendItem}
              />
            </Content>
          </Grid>

          <Grid item />
          <Grid item>
            <Typography variant={'h4'}>Доставка</Typography>
          </Grid>
          <Grid item>
            <Content>
              <AddressFullSingleSelector
                value={address}
                label='Доставка'
                onChange={address => {
                  onChangeField('deliveryAddress', address ?? createEmptyDraftAddress());
                }}
                error={
                  warnings.validateOrderDeliveryAddress?.hierarchy?.hasError ||
                  warnings.validateOrderDeliveryAddress?.postalCode?.hasError
                }
                helperText={
                  warnings.validateOrderDeliveryAddress?.hierarchy?.message ||
                  warnings.validateOrderDeliveryAddress?.postalCode?.message
                }
              />
              <Splitter />
              <MPInputButton
                helperText={
                  Object.keys(warnings.validateOrderDeliveryAddress || {}).length > 0 ||
                  warnings.validateOrderDeliveryCost?.hasError
                    ? 'Заполните обязательные поля'
                    : undefined
                }
                error={warnings.validateOrderDeliveryCost?.hasError}
                naturalNumberInput
                value={deliveryCost}
                InputProps={{
                  disabled: !!order.deliveryCost,
                }}
                buttonText={order.deliveryCost ? 'Удалить доставку' : 'Добавить доставку'}
                buttonProps={{
                  disabled: deliveryCost === null,
                }}
                label='Стоимость доставки'
                onChange={val => {
                  const cost = Number.parseFloat(val.target.value);

                  if (!Number.isNaN(cost)) {
                    setDeliveryCost(cost);
                  }
                }}
                onExecute={() => {
                  onSetWarning('validateOrderDeliveryCost', null);
                  if (order.deliveryCost) {
                    onChangeField('deliveryCost', null);
                    onChangeField('deliveryAddress', createEmptyDraftAddress());
                    setDeliveryCost(null);
                    onSetWarning('validateOrderDeliveryAddress', null);
                  } else {
                    const validOrder = onValidateDeliveryAddress(order.deliveryAddress);

                    if (validOrder.isValid) {
                      onChangeField('deliveryCost', deliveryCost);
                    } else {
                      onSetWarning('validateOrderDeliveryAddress', validOrder.results);
                    }
                  }
                }}
              />
            </Content>
          </Grid>

          <Grid item />
          <Grid item>
            <Typography variant={'h3'}>Итого: {formatCurrency(totalCost, currency)}</Typography>
          </Grid>

          <Grid item />
          <Grid item>
            <Typography variant={'h4'}>Статус заказа и комментарий менеджера</Typography>
          </Grid>

          {warnings.validateItems && (
            <Grid item>
              <ValidationHintPanel
                inline
                text={warnings.validateItems.message}
              />
            </Grid>
          )}

          <Grid item>
            <Content>
              <MPSelect<ProductOrderStatus>
                label='Статус заказа'
                value={status}
                onChange={newStatus => onChangeField('status', newStatus)}
                disabled={!!warnings.validateItems}
              >
                {statuses.map(item => (
                  <MPMenuItem
                    key={item.id}
                    value={item.id}
                  >
                    <Typography variant='body1'>{item.name}</Typography>
                  </MPMenuItem>
                ))}
              </MPSelect>
            </Content>
          </Grid>
          <Grid item>
            <Content>
              <MPFormTextArea
                label='Комментарий'
                value={lastStatusComment}
                rows={7}
                onChange={event => onChangeField('lastStatusComment', event.target.value)}
              />
            </Content>
          </Grid>
        </Grid>
      </ColumnWrapper>
    </Wrapper>
  );
};

export default ProductOrderEdit;
