import { EAnalyticsQueriesTableTab } from '@features/analytics/query/table/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  analyticsQueriesFilterSelector,
  analyticsQueriesNeedRefreshWatcherSelector,
  analyticsQueriesPageNumberSelector,
  analyticsQueriesSearchSelector,
} from '../store/selectors';
import {
  analyticsQueriesFetch,
  AnalyticsQueriesFetchProps,
  analyticsQueriesNeedRefreshWatcherReset,
} from '../store/slice';

interface UseAnalyticsQueriesTableSearchProps {
  readonly tab: EAnalyticsQueriesTableTab;
}

const useAnalyticsQueriesTableSearch = (props: UseAnalyticsQueriesTableSearchProps): void => {
  const { tab } = props;

  const dispatch = useDispatch();

  const [searchPromises, setSearchPromises] = useState<Nullable<any[]>>(null);

  const needRefreshWatcher = useSelector(analyticsQueriesNeedRefreshWatcherSelector);
  const pageNumber = useSelector(analyticsQueriesPageNumberSelector);
  const search = useSelector(analyticsQueriesSearchSelector);
  const filter = useSelector(analyticsQueriesFilterSelector);

  const queryParams = useMemo<AnalyticsQueriesFetchProps>(() => {
    return {
      filter,
      search,
      pageNumber,
      tab,
    };
  }, [filter, search, tab, pageNumber]);

  const onSearch = useCallback(() => {
    setSearchPromises([dispatch(analyticsQueriesFetch(queryParams))]);
  }, [dispatch, queryParams]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  // сброс вотчера после ухода
  useEffect(() => {
    return () => {
      dispatch(analyticsQueriesNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};

export default useAnalyticsQueriesTableSearch;
