import { useSelector } from 'react-redux';
import { AddressHelper } from '../../../../utils/address';
import OfferPreviewWrapper from '../../../general/offer/components/preview/wrapper';
import BookingOfferPreview from '../../components/preview';
import { bookingOfferDetailsBookingOfferSelector, bookingOfferDetailsPartnerSelector } from '../store/selectors';

export const BookingOfferDetailsPreviewAdapter = () => {
  const bookingOffer = useSelector(bookingOfferDetailsBookingOfferSelector);
  const partner = useSelector(bookingOfferDetailsPartnerSelector);

  return bookingOffer ? (
    <OfferPreviewWrapper>
      {({ mode }) => (
        <BookingOfferPreview
          mode={mode}
          name={bookingOffer.name}
          address={bookingOffer.address ? new AddressHelper(bookingOffer.address).getLastLocalityShortPath() : null}
          image={bookingOffer.images?.[0] ?? null}
          servicePrice={bookingOffer.displayPriceItem?.price ?? null}
          serviceUnit={bookingOffer.displayPriceItem?.unit?.name ?? null}
          logo={partner?.logo ?? null}
        />
      )}
    </OfferPreviewWrapper>
  ) : null;
};
