import { useDispatch } from 'react-redux';
import { EAdCampaignActionType } from '../types';
import AdCampaignActionsDialogsAdapter from './adapters/dialogs';
import AdCampaignActionsOptimizerAdapter from './adapters/optimizer';
import AdCampaignActionsContext, { AdCampaignActionsContextType } from './context';
import {
  getAdCampaignActionsAdCampaignIsProcessingSelector,
  getIsAdCampaignAnyActionByTypeExecutedSelector,
  isAdCampaignsAnyChangedSelector,
} from './store/selectors';
import { adCampaignActionsArchive, adCampaignActionsChangeDialogState } from './store/slice';
import { showAdCampaignActionNotification } from './utils';

interface AdCampaignActionsProviderProps {
  readonly children: JSX.Element;
}

type AdCampaignActionsProviderType = (props: AdCampaignActionsProviderProps) => JSX.Element;

const AdCampaignActionsProvider: AdCampaignActionsProviderType = ({ children }) => {
  const dispatch = useDispatch();

  const onArchive: AdCampaignActionsContextType['onArchive'] = async id => {
    const response = await dispatch(adCampaignActionsArchive({ id })).unwrap();
    if (response) {
      showAdCampaignActionNotification(response, EAdCampaignActionType.Archive, false);
    }
    return response;
  };

  const onTrySave: AdCampaignActionsContextType['onTrySave'] = data => {
    onChangeDialogState({ name: 'save', data });
  };

  const onTryArchive: AdCampaignActionsContextType['onTryArchive'] = data => {
    onChangeDialogState({ name: 'archive', data });
  };

  const onTryPause: AdCampaignActionsContextType['onTryPause'] = data => {
    onChangeDialogState({ name: 'pause', data });
  };

  const onTryResume: AdCampaignActionsContextType['onTryResume'] = data => {
    onChangeDialogState({ name: 'resume', data });
  };

  const onChangeDialogState: AdCampaignActionsContextType['onChangeDialogState'] = data => {
    dispatch(adCampaignActionsChangeDialogState(data));
  };

  const value: AdCampaignActionsContextType = {
    onChangeDialogState,
    onTrySave,
    onArchive,
    onTryPause,
    onTryArchive,
    onTryResume,

    utils: {
      selectors: {
        getIsAdCampaignProcessingSelector: getAdCampaignActionsAdCampaignIsProcessingSelector,
        getIsAdCampaignExecutedActionsSelector: getIsAdCampaignAnyActionByTypeExecutedSelector,
        isAdCampaignsAnyChangedSelector,
      },
    },
  };

  return (
    <AdCampaignActionsContext.Provider value={value}>
      {children}
      <AdCampaignActionsOptimizerAdapter />
      <AdCampaignActionsDialogsAdapter />
    </AdCampaignActionsContext.Provider>
  );
};

export default AdCampaignActionsProvider;
