import { FormControlLabel, Radio, RadioGroup, RadioGroupProps } from '@mui/material';
import React from 'react';
import { Nullable } from '../../../../domain/model/types';

interface MPRadioGroupItem<T extends string> {
  readonly id: T;
  readonly name: string;
}

interface MPRadioGroupCommonProps<T extends string> {
  readonly value: Nullable<T>;
  readonly color?: 'primary' | 'secondary' | 'default';
  readonly onChange: (value: T) => void;
}

interface MPRadioGroupItemsControllerProps<T extends string>
  extends MPRadioGroupCommonProps<T>,
    Omit<RadioGroupProps, 'color' | 'value' | 'onChange'> {
  readonly children: React.ReactNode;
}

interface MPRadioGroupItemsUnControllerProps<T extends string>
  extends MPRadioGroupCommonProps<T>,
    Omit<RadioGroupProps, 'color' | 'children' | 'value' | 'onChange'> {
  readonly items: MPRadioGroupItem<T>[];
}

function MPRadioGroupControlledInternal<T extends string>(
  props: MPRadioGroupItemsControllerProps<T> & { forwardedRef?: any }
) {
  const { onChange, forwardedRef, children, ...radioGroupProps } = props;

  return (
    <RadioGroup
      onChange={(event, value) => onChange(value as T)}
      {...radioGroupProps}
    >
      {children}
    </RadioGroup>
  );
}

function MPRadioGroupUnControlledInternal<T extends string>(
  props: MPRadioGroupItemsUnControllerProps<T> & { forwardedRef?: any }
) {
  const { items, color = 'primary', onChange, forwardedRef, ...radioGroupProps } = props;

  return (
    <RadioGroup
      onChange={(event, value) => onChange(value as T)}
      {...radioGroupProps}
    >
      {items?.map(({ id, name }) => (
        <FormControlLabel
          key={id}
          value={id}
          control={<Radio color={color} />}
          label={name}
        />
      ))}
    </RadioGroup>
  );
}

export const MPRadioGroup = React.forwardRef<typeof MPRadioGroupControlledInternal, any>(
  <T extends string>(props: MPRadioGroupItemsControllerProps<T>, ref: any) => (
    <MPRadioGroupControlledInternal<T>
      forwardedRef={ref}
      {...props}
    />
  )
) as typeof MPRadioGroupControlledInternal;

export const MPRadioGroupUnControlled = React.forwardRef<typeof MPRadioGroupUnControlledInternal, any>(
  <T extends string>(props: MPRadioGroupItemsUnControllerProps<T>, ref: any) => (
    <MPRadioGroupUnControlledInternal<T>
      forwardedRef={ref}
      {...props}
    />
  )
) as typeof MPRadioGroupUnControlledInternal;
