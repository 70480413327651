import { ReactNode, useMemo } from 'react';
import { Team } from '../../../../../domain/model/team';
import DataTableCellTextFormat from '../../../../components/common/table/cell/textFormat';

export interface TeamTableCellCommonProps {
  readonly team: Team;
}

interface TeamTableCellProps extends TeamTableCellCommonProps {
  readonly value: ReactNode;
}

const TeamTableCell = ({ value }: TeamTableCellProps) => {
  return useMemo(() => <DataTableCellTextFormat>{value}</DataTableCellTextFormat>, [value]);
};

export default TeamTableCell;
