import { useState } from 'react';
import { DataFilterStrategyString } from '../../../../../../domain/model/filter';
import { Nullable } from '../../../../../../domain/model/types';
import { MPFormInput } from '../../../../../theme/ui-kit/input';
import { DataFilterEditItemProps } from '../index';

interface DataFilterEditItemStringProps<T extends string> extends DataFilterEditItemProps<T, Nullable<string>> {
  readonly strategy: DataFilterStrategyString<T>;
}

function DataFilterEditItemString<T extends string>({ strategy, onChange }: DataFilterEditItemStringProps<T>) {
  const { label, value, readOnly } = strategy;

  const [localValue, setLocalValue] = useState<string>(value ?? '');

  const onSubmitValue = () => onChange(strategy, localValue);

  const onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      onSubmitValue();
      e.preventDefault();
    }
    return true;
  };

  return (
    <MPFormInput
      label={label}
      value={localValue}
      disabled={readOnly}
      onChange={event => setLocalValue(event.target.value)}
      onKeyPress={onKeyPress}
      onBlur={onSubmitValue}
    />
  );
}

export default DataFilterEditItemString;
