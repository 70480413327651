import styled from '@emotion/styled';
import ReactCrop from 'react-image-crop';

export const Image = styled.img`
  /*margin: auto;*/
`;

export const Wrapper = styled.div`
  width: max-content;
  height: auto;

  margin: auto;
`;

export const Preview = styled.canvas`
  display: none;
`;

export const Crop = styled(ReactCrop)``;
