import StepperValidationBadgeAdapter from '@components/common/stepper/adapters/validationBadge';
import { FCC } from 'react';
import { useSelector } from 'react-redux';
import { ClientOrgCreateStep } from '../../types';
import { clientOrgCreateValidationStepperSelector } from '../store/selectors';

interface ClientOrgCreateStepperIconAdapterProps {
  readonly step: ClientOrgCreateStep;
}

export const ClientOrgCreateStepperIconAdapter: FCC<ClientOrgCreateStepperIconAdapterProps> = props => {
  const { step, children } = props;

  const validation = useSelector(clientOrgCreateValidationStepperSelector);

  return <StepperValidationBadgeAdapter validation={validation?.[step.key]}>{children}</StepperValidationBadgeAdapter>;
};
