import { useCallback, useMemo } from 'react';
import { PartnerShortAny, PartnerShortDraft } from '../../../../../domain/model/partner';
import { UUID } from '../../../../../domain/model/types';
import {
  EPartnerTableTab,
  GetPartnerActionsByPermissions,
  PartnerActions,
  PartnerActionTableType,
} from '../../../general/partner/types';
import { getIsPartnerDisabled, getPartnerTableTabActions } from '../utils';

type ActionsType = PartnerActions<PartnerActionTableType>;

type UsePartnerTableActions = {
  readonly getPartnerActions: (partner: PartnerShortDraft) => ActionsType;
};

interface UsePartnerTableActionsProps extends GetPartnerActionsByPermissions {
  readonly tab: EPartnerTableTab;
  readonly userId: UUID;
}

const usePartnerTableActions = (props: UsePartnerTableActionsProps): UsePartnerTableActions => {
  const { canActivate, canDeactivate, canReturnToVerification, canDelete, tab, userId } = props;

  const tableTabActions = useMemo<ActionsType>(
    () =>
      getPartnerTableTabActions(
        {
          canActivate,
          canDeactivate,
          canReturnToVerification,
          canDelete,
        },
        tab
      ),
    [canActivate, canDeactivate, canReturnToVerification, canDelete, tab]
  );

  const getPartnerActions = useCallback(
    (partner: PartnerShortAny) => {
      const disabled = getIsPartnerDisabled(partner, userId);
      return tableTabActions.map(action => ({ ...action, disabled }));
    },
    [tableTabActions, userId]
  );

  return {
    getPartnerActions,
  };
};

export default usePartnerTableActions;
