import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from './index';

const CorpOfferTableCellStoreResponsible = (props: CorpOfferTableCellCommonProps) => {
  const {
    corpOffer: { storeResponsiblePerson, responsiblePerson },
  } = props;

  const value = storeResponsiblePerson?.responsiblePersonLink
    ? responsiblePerson?.fullName
    : storeResponsiblePerson?.fullName ?? '-';

  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellStoreResponsible;
