import BookingOfferServiceManageServicePriceItemsFieldAdapter from './fields/priceItem';
import { BookingOfferServiceManageStatusFieldAdapter } from './fields/status';
import FormItemAdapter from './formItem';
import { BookingOfferServiceManageServiceFieldAdapter } from './serviceField';

interface BookingOfferCreateServiceAdapterProps {
  readonly index: number;
}

const BookingOfferServiceManageServiceAdapter = (props: BookingOfferCreateServiceAdapterProps) => {
  const { index } = props;

  return (
    <FormItemAdapter
      title={
        <BookingOfferServiceManageServiceFieldAdapter
          index={index}
          name='status'
          component={BookingOfferServiceManageStatusFieldAdapter}
        />
      }
    >
      <BookingOfferServiceManageServiceFieldAdapter
        index={index}
        name='priceItems'
        component={BookingOfferServiceManageServicePriceItemsFieldAdapter}
      />
    </FormItemAdapter>
  );
};

export default BookingOfferServiceManageServiceAdapter;
