import DataTableCellImage from '../../../../components/common/table/cell/image';
import { DataTableCellImageWrapper } from './controls';
import BookingOfferTableCell, { BookingOfferTableCellCommonProps } from './index';

const BookingOfferTableCellImage = (props: BookingOfferTableCellCommonProps) => {
  const {
    bookingOffer: { images },
  } = props;

  const path = images && images.length > 0 ? images[0].path : null;

  if (path) {
    return (
      <DataTableCellImageWrapper>
        <DataTableCellImage
          src={path}
          height='100%'
          width='100%'
        />
      </DataTableCellImageWrapper>
    );
  }

  return (
    <BookingOfferTableCell
      {...props}
      value={'-'}
    />
  );
};

export default BookingOfferTableCellImage;
