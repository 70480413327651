import { EEntityPreviewMode } from '../../../../../types';
import { CmsContainerViewResourceSize } from '../../../container/types';
import { ImageStub, UnknownSizesStubWrapper } from './controls';

interface UnknownSizesStubProps {
  readonly mode: EEntityPreviewMode;
  readonly isActive: boolean;
  readonly sizes: CmsContainerViewResourceSize[];
}

const getCalculatedItemPercentHeights = (mode: EEntityPreviewMode, sizes: CmsContainerViewResourceSize[]) => {
  let originalHeights: number[];
  switch (mode) {
    case EEntityPreviewMode.Desktop:
      originalHeights = sizes.map(item => Number(item.desktop[1].replace('px', '')));
      break;
    case EEntityPreviewMode.Mobile:
      originalHeights = sizes.map(item => Number(item.mobile[1].replace('px', '')));
      break;
  }
  const itemsHeights = originalHeights
    .sort((a, b) => a - b)
    .reduce<number[]>((prev, cur, ind, arr) => {
      const val = ind === 0 ? cur : cur - arr[ind - 1];
      return [...prev, val];
    }, []);

  const heightsSum = itemsHeights.reduce((prev, cur) => cur + prev, 0);

  return itemsHeights.map(item => Math.round((100 * item) / heightsSum));
};

const UnknownSizesStub = ({ isActive, mode, sizes }: UnknownSizesStubProps) => {
  const itemsHeights: number[] = getCalculatedItemPercentHeights(mode, sizes);

  return (
    <UnknownSizesStubWrapper itemsHeights={itemsHeights}>
      {itemsHeights.map((item, index) => (
        <ImageStub
          key={index}
          isActive={isActive}
        />
      ))}
    </UnknownSizesStubWrapper>
  );
};

export default UnknownSizesStub;
