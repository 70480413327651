import { combineReducers } from '@reduxjs/toolkit';
import create, { PersonalPromotionCreateState } from './create/store/slice';
import table, { PersonalPromotionTableState } from './table/store/slice';

interface PersonalPromotionState {
  table: PersonalPromotionTableState;
  create: PersonalPromotionCreateState;
}

export default combineReducers<PersonalPromotionState>({
  table,
  create,
});
