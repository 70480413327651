import { FCC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { OfferCategory } from '../../../../../domain/model';
import { CorpOfferCategoriesSelectContainerProps } from './container';
import {
  corpOfferCategoriesSelectFetch,
  corpOfferCategoriesSelectResetState,
  corpOfferCategoriesSelectStartSession,
} from './store/slice';

type CorpOfferCategoriesSelectInitializerProps = CorpOfferCategoriesSelectContainerProps & {
  readonly open?: boolean;
  readonly selected: Nullable<OfferCategory[]>;
  readonly onClose: (selected?: Nullable<OfferCategory[]>) => void;
};

export const CorpOfferCategoriesSelectInitializer: FCC<CorpOfferCategoriesSelectInitializerProps> = ({
  onlyUsed,
  partnerId,
  statuses,
  open,
  selected,
  children,
}) => {
  const dispatch = useDispatch();

  //загрузка основных данных
  useEffect(() => {
    if (open) {
      const requests = [dispatch(corpOfferCategoriesSelectFetch({ onlyUsed, partnerId, statuses }))];
      return () => {
        requests.forEach(request => request.abort());
      };
    }
  }, [dispatch, open, onlyUsed, partnerId, statuses]);

  //очистка данных
  useEffect(() => {
    if (open) {
      dispatch(corpOfferCategoriesSelectStartSession({ selected }));
      return () => {
        dispatch(corpOfferCategoriesSelectResetState());
      };
    }
  }, [dispatch, selected, open]);

  return <>{children}</>;
};
