import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { BonusTransaction, Pageable } from '../../../../../domain/model';
import { pageSizeAll } from '../../../../../domain/model/constants';
import { Team } from '../../../../../domain/model/team';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { SportUserProfile, UserBonusesBalance } from '../../../../../domain/model/user';
import sportsmanServices from '../../services';

const bonusTransactionsPageSize = 15 as const;

export const sportsmanByIdFetch = createAsyncThunk<SportUserProfile, { id: UUID }, AppThunkAPIConfig>(
  'sportsman/details/byId/fetch',
  async ({ id }, { rejectWithValue, signal }) => {
    try {
      return await sportsmanServices.common.one({ id, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const sportsmanTeamsFetch = createAsyncThunk<{ data: Team[] }, { id: UUID }, AppThunkAPIConfig>(
  'sportsman/details/teams/fetch',
  async ({ id }, { rejectWithValue, signal }) => {
    try {
      const { data: response } = await Api.team.all({
        page: 1,
        pageSize: pageSizeAll,
        sort: 'name,desc',
        userId: id,
        signal,
      });

      const { content: data } = response;
      return { data };
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const sportsmanBonusFetch = createAsyncThunk<Nullable<UserBonusesBalance>, { id: UUID }, AppThunkAPIConfig>(
  'sportsman/details/bonus/fetch',
  async ({ id }, { rejectWithValue, signal }) => {
    try {
      const { data } = await Api.bonus.balance({ id, signal });
      return data;
    } catch (e: any) {
      if (e.response?.status === 404) {
        return null;
      }
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const sportsmanBonusTransactionsFetch = createAsyncThunk<
  Pageable<BonusTransaction>,
  { id: UUID; pageNumber: number },
  AppThunkAPIConfig
>('sportsman/details/bonusTransaction/fetch', async ({ id, pageNumber }, { rejectWithValue, signal }) => {
  try {
    const { data: response } = await Api.bonus.transactions({
      page: pageNumber,
      pageSize: bonusTransactionsPageSize,
      sort: 'date,desc',
      id,
      signal,
    });
    const { content: data, totalElements: totalCount, totalPages: pageCount, number: page } = response;
    return { data, totalCount, pageCount, pageNumber: page };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface SportsmanDetailsState {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly byId: Fetchable & {
    readonly data: Nullable<SportUserProfile>;
  };
  readonly teams: Fetchable & {
    readonly data: Team[];
  };
  readonly bonus: Fetchable & {
    readonly data: Nullable<UserBonusesBalance>;
  };
  readonly bonusTransactions: Fetchable &
    Pageable<BonusTransaction> & {
      readonly pageNumber: number;
      readonly pageSize: number;
      readonly needRefresh: boolean;
    };
}

type Reducer<T = undefined> = CaseReducer<SportsmanDetailsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<SportsmanDetailsState> {
  sportsmanStartSession: Reducer<Nullable<UUID>>;
  sportsmanBonusTransactionsSetPage: Reducer<{ pageNumber: number }>;
}

const slice = createSlice<SportsmanDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    guid: null,
    needRefreshWatcher: 0,
    byId: {
      ...fetchableDefault,
      data: null,
    },
    teams: {
      ...fetchableDefault,
      data: [],
    },
    bonus: {
      ...fetchableDefault,
      data: null,
    },
    bonusTransactions: {
      ...fetchableDefault,
      data: [],
      totalCount: 0,
      pageCount: 0,
      pageNumber: 1,
      pageSize: bonusTransactionsPageSize,
      needRefresh: false,
    },
  },
  reducers: {
    sportsmanStartSession: (state, { payload }) => {
      if (state.guid !== payload) {
        state.needRefreshWatcher = 0;
      }
      state.guid = payload;
    },
    sportsmanBonusTransactionsSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.bonusTransactions.pageNumber = pageNumber;
      state.bonusTransactions.needRefresh = true;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sportsmanByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;

        state.byId.data = null;
      })
      .addCase(sportsmanByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.data = payload;
      })
      .addCase(sportsmanByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        } else {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = false;
        }
        state.byId.data = null;
      })

      .addCase(sportsmanTeamsFetch.pending, state => {
        state.teams.isFetching = true;
        state.teams.isFetched = false;
        state.teams.isFailed = false;
        state.teams.data = [];
      })
      .addCase(sportsmanTeamsFetch.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.teams.isFetching = false;
        state.teams.isFetched = true;
        state.teams.isFailed = false;
        state.teams.data = data;
      })
      .addCase(sportsmanTeamsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.teams.isFetching = false;
          state.teams.isFetched = false;
          state.teams.isFailed = true;
        } else {
          state.teams.isFetching = false;
          state.teams.isFetched = false;
          state.teams.isFailed = false;
        }
        state.teams.data = [];
      })

      .addCase(sportsmanBonusFetch.pending, state => {
        state.bonus.isFetching = true;
        state.bonus.isFetched = false;
        state.bonus.isFailed = false;
        state.bonus.data = null;
      })
      .addCase(sportsmanBonusFetch.fulfilled, (state, { payload }) => {
        state.bonus.isFetching = false;
        state.bonus.isFetched = true;
        state.bonus.isFailed = false;
        state.bonus.data = payload;
      })
      .addCase(sportsmanBonusFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.bonus.isFetching = false;
          state.bonus.isFetched = false;
          state.bonus.isFailed = true;
        } else {
          state.bonus.isFetching = false;
          state.bonus.isFetched = false;
          state.bonus.isFailed = false;
        }
        state.bonus.data = null;
      })

      .addCase(sportsmanBonusTransactionsFetch.pending, state => {
        state.bonusTransactions.isFetching = true;
        state.bonusTransactions.isFetched = false;
        state.bonusTransactions.isFailed = false;
        state.bonusTransactions.needRefresh = false;
      })
      .addCase(sportsmanBonusTransactionsFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;
        state.bonusTransactions.isFetching = false;
        state.bonusTransactions.isFetched = true;
        state.bonusTransactions.isFailed = false;
        state.bonusTransactions.data =
          state.bonusTransactions.pageNumber === 1 ? data : [...state.bonusTransactions.data, ...data];
        state.bonusTransactions.totalCount = totalCount;
        state.bonusTransactions.pageCount = pageCount;
      })
      .addCase(sportsmanBonusTransactionsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.bonusTransactions.isFetching = false;
          state.bonusTransactions.isFetched = false;
          state.bonusTransactions.isFailed = true;
        } else {
          state.bonusTransactions.isFetching = false;
          state.bonusTransactions.isFetched = false;
          state.bonusTransactions.isFailed = false;
        }
        state.bonusTransactions.data = [];
      });
  },
});

export const { sportsmanBonusTransactionsSetPage, sportsmanStartSession } = slice.actions;

export default slice.reducer;
