import {
  CaseReducer,
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import Api from '../../../../data/api';
import { SignAgreementsRequest } from '../../../../data/api/legalAgreements';
import ErrorHandler from '../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../data/store/types';
import { EUserRole } from '../../../../domain/model/enums';
import { UserAgreement } from '../../../../domain/model/legalAgreement';
import { MpUserShort, SportUserProfile } from '../../../../domain/model/user';
import legalServices from '../service';

export interface AgreementsState {
  readonly agreementsList: {
    readonly agreementsToSign: UserAgreement[];
    readonly needRefreshWatcher: number;
  } & Fetchable;
  readonly agreementsSign: {} & Fetchable;
  readonly isCheckingRequestPassed: boolean;
  readonly isCheckingRequestFailed: boolean;
  readonly isCheckingRequestError: any;
}

type Reducer<T> = CaseReducer<AgreementsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<AgreementsState> {
  readonly resetAgreements: Reducer<void>;
}

export const checkUserCurrentMpAdminFetch = createAsyncThunk<MpUserShort, undefined, AppThunkAPIConfig>(
  'user/current/mpAdmin/checking/fetch',
  async (props, { signal, rejectWithValue }) => {
    try {
      const { data: user } = await Api.user.mp.current({ signal });
      return user;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const checkUserCurrentSportFetch = createAsyncThunk<SportUserProfile, { roles: EUserRole[] }, AppThunkAPIConfig>(
  'user/current/sport/checking/fetch',
  async ({ roles }, { signal, dispatch, rejectWithValue }) => {
    try {
      const { data: user } = await Api.user.sport.current({ signal });
      return {
        ...user,
        roles,
      };
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const userAgreementsFetch = createAsyncThunk<UserAgreement[], boolean, AppThunkAPIConfig>(
  'agreements/fetch',
  async (isAdminSport, { rejectWithValue, signal }) => {
    try {
      const agreementsToSign: UserAgreement[] = [];
      if (isAdminSport) {
        const agreements = await legalServices.sport.getAgreements({ signal });
        agreementsToSign.push(...agreements);
      } else {
        const agreements = await legalServices.mp.getAgreements({ signal });
        agreementsToSign.push(...agreements);
      }
      return agreementsToSign;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  },
  {
    condition: (isAdminSport, { getState, extra }) => {
      const { legalAgreements } = getState();
      const { legalAgreements: legalAgreementsData } = legalAgreements;
      if (legalAgreementsData.agreementsList.isFetching) {
        return false;
      }
    },
  }
);

export const userAgreementsSign = createAsyncThunk<
  void,
  { isSportUser: boolean; data: SignAgreementsRequest },
  AppThunkAPIConfig
>('agreements/sign', async ({ isSportUser, data }, { rejectWithValue }) => {
  try {
    if (isSportUser) {
      return await legalServices.sport.signAgreements({ data });
    } else {
      return await legalServices.mp.signAgreements({ data });
    }
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

const slice = createSlice<AgreementsState, Reducers, 'agreements/list'>({
  name: 'agreements/list',
  initialState: {
    agreementsList: {
      ...fetchableDefault,
      agreementsToSign: [],
      needRefreshWatcher: 0,
    },
    agreementsSign: {
      ...fetchableDefault,
    },
    isCheckingRequestPassed: false,
    isCheckingRequestFailed: false,
    isCheckingRequestError: undefined,
  },
  reducers: {
    resetAgreements(state) {
      state.agreementsList.agreementsToSign = [];
      state.agreementsList.isFailed = fetchableDefault.isFailed;
      state.agreementsList.isFetching = fetchableDefault.isFetching;
      state.agreementsList.isFetched = fetchableDefault.isFetched;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(userAgreementsFetch.pending, state => {
        state.agreementsList.isFetching = true;
        state.agreementsList.isFetched = false;
        state.agreementsList.isFailed = false;
      })
      .addCase(userAgreementsFetch.fulfilled, (state, { payload }) => {
        state.agreementsList.isFetching = false;
        state.agreementsList.isFetched = true;
        state.agreementsList.isFailed = false;
        state.agreementsList.agreementsToSign = payload;
      })
      .addCase(userAgreementsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.agreementsList.isFetching = false;
          state.agreementsList.isFetched = false;
          state.agreementsList.isFailed = true;
          state.agreementsList.agreementsToSign = [];
        }
      })
      .addCase(userAgreementsSign.pending, state => {
        state.agreementsSign.isFetching = true;
        state.agreementsSign.isFetched = false;
        state.agreementsSign.isFailed = false;
      })
      .addCase(userAgreementsSign.fulfilled, state => {
        state.agreementsSign.isFetching = false;
        state.agreementsSign.isFetched = true;
        state.agreementsSign.isFailed = false;
        state.agreementsList.agreementsToSign = [];
        state.agreementsList.needRefreshWatcher = state.agreementsList.needRefreshWatcher + 1;
      })
      .addCase(userAgreementsSign.rejected, state => {
        state.agreementsSign.isFetching = false;
        state.agreementsSign.isFetched = false;
        state.agreementsSign.isFailed = true;
      })
      .addMatcher(isAnyOf(checkUserCurrentSportFetch.fulfilled, checkUserCurrentMpAdminFetch.fulfilled), state => {
        state.isCheckingRequestPassed = true;
      })
      .addMatcher(
        isAnyOf(checkUserCurrentSportFetch.rejected, checkUserCurrentMpAdminFetch.rejected),
        (state, { payload }) => {
          state.isCheckingRequestFailed = true;
          state.isCheckingRequestError = payload;
        }
      );
  },
});

export const { resetAgreements } = slice.actions;

export default slice.reducer;
