import { AnyAdCampaign } from '@/domain';
import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EAdCampaignActionType } from '../../types';
import AdCampaignActionsConfirmDialog from '../components/confirmDialog';
import {
  adCampaignActionsDialogArchiveSelector,
  getAdCampaignActionsAdCampaignIsProcessingSelector,
} from '../store/selectors';
import { adCampaignActionsArchive } from '../store/slice';
import { useAdCampaignActions } from '../useActions';
import { showAdCampaignActionNotification } from '../utils';

const getArchiveText = (text: Nullable<string>) => (
  <Typography>
    Рекламная кампания «<b>{text}</b>» будет отправлена в архив
  </Typography>
);

const AdCampaignActionsDialogArchiveAdapter = () => {
  const dispatch = useDispatch();

  const handlers = useAdCampaignActions();

  const object = useSelector(adCampaignActionsDialogArchiveSelector);

  const isProcessing = useSelector(getAdCampaignActionsAdCampaignIsProcessingSelector(object?.id ?? null));

  const onArchive = useCallback(
    (campaign: AnyAdCampaign) => {
      if (campaign.id) {
        dispatch(
          adCampaignActionsArchive({
            id: campaign.id,
          })
        )
          .unwrap()
          .then(response => showAdCampaignActionNotification(response, EAdCampaignActionType.Archive, false))
          .finally(onCloseDialog);
      }
    },
    [dispatch]
  );

  const onCloseDialog = useCallback(() => {
    handlers.onChangeDialogState({ name: 'archive', data: null });
  }, [handlers.onChangeDialogState]);

  return (
    object && (
      <AdCampaignActionsConfirmDialog
        campaign={object}
        title='Отправить в архив рекламную кампанию?'
        actionText='Отправить в архив'
        text={getArchiveText(object.name)}
        onAction={campaign => onArchive(campaign)}
        isFetching={isProcessing}
        onClose={onCloseDialog}
      />
    )
  );
};

export default AdCampaignActionsDialogArchiveAdapter;
