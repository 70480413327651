import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { BonusTransaction } from '../../../../domain/model';
import { Team } from '../../../../domain/model/team';
import { UUID } from '../../../../domain/model/types';
import { getEventViewRoute } from '../../event/entry';
import { getTeamRoute } from '../../team/entry';
import { getSportsmanDetailsRoute } from '../entry';
import Sportsman from './component';
import {
  sportsmanGetBonusSelector,
  sportsmanGetBonusTransactionsSelector,
  sportsmanGetByIdSelector,
  sportsmanGetNeedRefreshWatcherSelector,
  sportsmanGetTeamsSelector,
  sportsmanGuidSelector,
} from './store/selectors';
import {
  sportsmanBonusFetch,
  sportsmanBonusTransactionsFetch,
  sportsmanBonusTransactionsSetPage,
  sportsmanByIdFetch,
  sportsmanStartSession,
  sportsmanTeamsFetch,
} from './store/slice';
import { ESportsmanTab } from './utils';

interface SportsmanContainerProps {
  readonly guid: UUID;
  readonly id: UUID;
  readonly tab: ESportsmanTab;
}

const SportsmanContainer = ({ guid, id, tab }: SportsmanContainerProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentGuid = useSelector(sportsmanGuidSelector);
  const needRefreshWatcher = useSelector(sportsmanGetNeedRefreshWatcherSelector);
  const { data: sportsman, isFetching: isFetchingSportsman } = useSelector(sportsmanGetByIdSelector);
  const { data: teams, isFetching: isFetchingTeams } = useSelector(sportsmanGetTeamsSelector);
  const { data: bonus, isFetching: isFetchingBonus } = useSelector(sportsmanGetBonusSelector);
  const {
    data: bonusTransactions,
    isFetching: btIsFetching,
    totalCount: btTotalCount,
    pageNumber: btPageNumber,
    needRefresh: btNeedRefresh,
  } = useSelector(sportsmanGetBonusTransactionsSelector);

  const onChangeTab = (newTab: ESportsmanTab) => {
    history.replace(getSportsmanDetailsRoute({ guid: currentGuid, id: sportsman!.id, tab: newTab }));
  };

  const onTeamClick = (team: Team) => {
    history.push(getTeamRoute({ id: team.id }));
  };

  const onBonusTransactionClick = (bonusTransaction: BonusTransaction) => {
    if (bonusTransaction.event) {
      history.push(getEventViewRoute({ id: bonusTransaction.event.id }));
    }
  };

  const btLoadNextPage = () => {
    dispatch(sportsmanBonusTransactionsSetPage({ pageNumber: btPageNumber + 1 }));
  };

  // сброс текущих данных
  useEffect(() => {
    dispatch(sportsmanStartSession(guid));
  }, [dispatch, guid]);

  // первоначальная загрузка
  useEffect(() => {
    if (guid !== currentGuid) {
      const requests: any = [];

      requests.push(
        dispatch(sportsmanByIdFetch({ id })),
        dispatch(sportsmanTeamsFetch({ id })),
        dispatch(sportsmanBonusFetch({ id })),
        dispatch(sportsmanBonusTransactionsFetch({ id, pageNumber: 1 }))
      );

      return () => {
        requests.forEach((request: any) => request.abort());
      };
    }
  }, [dispatch, id, guid]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      const requests: any = [];

      requests.push(
        dispatch(sportsmanByIdFetch({ id })),
        dispatch(sportsmanTeamsFetch({ id })),
        dispatch(sportsmanBonusFetch({ id })),
        dispatch(sportsmanBonusTransactionsFetch({ id, pageNumber: 1 }))
      );

      return () => {
        requests.forEach((request: any) => request.abort());
      };
    }
  }, [dispatch, id, needRefreshWatcher]);

  useEffect(() => {
    if (btNeedRefresh) {
      dispatch(sportsmanBonusTransactionsFetch({ id, pageNumber: btPageNumber }));
    }
  }, [dispatch, id, btPageNumber, btNeedRefresh]);

  const bonusEnabled = bonus !== null;

  if (!sportsman) {
    return null;
  }

  return (
    <Sportsman
      guid={guid}
      tab={tab}
      sportsman={sportsman}
      teams={teams}
      bonus={{
        count: bonus?.balance ?? null,
        isFetching: isFetchingBonus,
      }}
      bonusTransactions={{
        enabled: bonusEnabled,
        data: bonusTransactions,
        totalCount: btTotalCount,
        isFetching: btIsFetching,
        needRefresh: btNeedRefresh,
        loadNextPage: btLoadNextPage,
      }}
      onTeamClick={onTeamClick}
      onBonusTransactionClick={onBonusTransactionClick}
      isFetching={isFetchingSportsman || isFetchingTeams || btIsFetching}
      onChangeTab={onChangeTab}
    />
  );
};

export default SportsmanContainer;
