import { Grid } from '@mui/material';
import { groupBy } from 'lodash';
import { forwardRef, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Nullable } from '../../../../domain/model/types';
import { ArrayElementType, EPanelActionPosition, PanelAction, PanelActions } from '../../../types';
import { ActionsGridItem, ToolbarGridItem } from './controls';
import ActionGroup from './group';
import ActionItem from './item';
import ToolbarActionsComponent from './toolbar';

interface ActionsProps<A extends string> {
  readonly actions: PanelActions<A>;
  readonly onAction: (action: PanelAction<A>) => void;
  readonly renderDirection?: 'rtl' | 'ltr';
  readonly navAdapter: Nullable<ReactNode>;
}

type ActionsComponentType = <A extends string>(props: ActionsProps<A>) => ReactNode;

const ActionsComponent: ActionsComponentType = forwardRef((props, ref: any) => {
  const { actions, onAction, renderDirection = 'ltr', navAdapter } = props;
  const toolBarActions = actions.filter(action => action.position.includes(EPanelActionPosition.Menu));
  const isRevers = renderDirection === 'rtl';
  const isToolBar = toolBarActions.length > 0;

  const buttonActions = actions.filter(action => {
    return action.position.includes(EPanelActionPosition.Default);
  });

  const groupActions = groupBy(buttonActions, action => action.groupLabel);
  const mixedActions: (ArrayElementType<typeof actions> & { readonly actions?: typeof actions })[] = [];

  buttonActions.forEach(action => {
    if (!action.groupLabel || groupActions[action.groupLabel].length <= 1) {
      mixedActions.push(action);
    } else {
      if (!mixedActions.some(a => a.groupLabel === action.groupLabel)) {
        mixedActions.push({
          ...action,
          disabled: groupActions[action.groupLabel].every(a => a.disabled),
          actions: groupActions[action.groupLabel],
        });
      }
    }
  });

  return (
    <Grid
      container
      direction={'row'}
      wrap={'nowrap'}
      spacing={2}
    >
      {navAdapter}
      <ActionsGridItem
        ref={ref}
        item
      >
        <Grid
          container
          data-testid={'actions-component'}
          spacing={2}
          direction={isRevers ? 'row-reverse' : 'row'}
        >
          {mixedActions.map(action => (
            <Grid
              key={action.type}
              item
            >
              {!action.actions && (
                <ActionItem
                  dataTestId={`action-${action.type}`}
                  key={action.type}
                  disabled={action.disabled}
                  primary={action.primary}
                  onExecute={() => onAction(action)}
                >
                  {action.label}
                </ActionItem>
              )}
              {action.actions && (
                <ActionGroup
                  id={uuidv4()}
                  key={action.type}
                  primary={action.primary}
                  disabled={action.disabled}
                  actions={action.actions ?? []}
                  onAction={onAction}
                >
                  {action.groupLabel}
                </ActionGroup>
              )}
            </Grid>
          ))}
        </Grid>
      </ActionsGridItem>
      {isToolBar && (
        <ToolbarGridItem item>
          <ToolbarActionsComponent
            actions={toolBarActions}
            onAction={onAction}
          />
        </ToolbarGridItem>
      )}
    </Grid>
  );
});

export default ActionsComponent;
