import { combineReducers } from '@reduxjs/toolkit';
import byId, { BannerByIdState } from './byId/store/slice';
import details, { BannerDetailsState } from './details/store/slice';
import edit, { BannerEditState } from './edit/store/slice';
import list, { BannerListState } from './table/store/slice';

interface BannerState {
  byId: BannerByIdState;
  details: BannerDetailsState;
  list: BannerListState;
  edit: BannerEditState;
}

export default combineReducers<BannerState>({
  byId,
  details,
  list,
  edit,
});
