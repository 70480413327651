import { ESortDirection } from '@/domain/model/enums';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../components/common/table';
import { DataTableLoader } from '../../../components/common/table/loader';
import { EBookingOfferTableColumn } from '../types';
import { bookingOfferSearchIsFetchingSelector } from './store/selectors';
import { BookingOfferShort } from '@/domain/model/booking';
import BookingOfferSearchCellAdapter from './adatpers/cell';
import BookingOfferTableCellImage from '../components/tableCell/image';
import BookingOfferTableCellDate from '../components/tableCell/date';
import BookingOfferTableCellName from '../components/tableCell/name';
import BookingOfferTableCellCode from '../components/tableCell/code';
import BookingOfferTableCellPartner from '../components/tableCell/partner';
import BookingOfferTableCellAddress from '../components/tableCell/address';
import BookingOfferTableCellPartnerType from '../components/tableCell/partnerType';
import BookingOfferTableCellCategory from '../components/tableCell/category';
import BookingOfferTableCellResponsible from '../components/tableCell/responsible';
import BookingOfferTableCellResponsiblePhone from '../components/tableCell/responsiblePhone';
import BookingOfferTableCellResponsibleEmail from '../components/tableCell/responsibleEmail';
import BookingOfferTableCellModerator from '../components/tableCell/moderator';
import BookingOfferTableCelRzdSocialPackage from '@features/bookingOffer/components/tableCell/rzdSocialPackage';

interface BookingOfferSearchProps {
  readonly metadata: DataTableMetadata<EBookingOfferTableColumn>;
  readonly bookingOffer: BookingOfferShort[];
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly selectedBookingOffer: BookingOfferShort[];
  readonly isSingleSelect?: boolean;
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onBookingOfferClick: (bookingOffer: BookingOfferShort) => void;
  readonly onChangeMetadata: (metadata: DataTableMetadata<EBookingOfferTableColumn>) => void;
  readonly onBookingOfferSelect: (bookingOffer: BookingOfferShort, selected: boolean) => void;
  readonly onAllBookingOfferSelect: (selected: boolean) => void;
}

const BookingOfferSearch = (props: BookingOfferSearchProps) => {
  const {
    metadata,
    bookingOffer,
    sort,
    selectedBookingOffer,
    isSingleSelect,
    onRequestSort,
    onBookingOfferClick,
    onChangeMetadata,
    onBookingOfferSelect,
    onAllBookingOfferSelect,
  } = props;

  const rows: DataTableRow<BookingOfferShort, EBookingOfferTableColumn>[] = bookingOffer?.map((bookingOffer, index) => {
    return {
      [EBookingOfferTableColumn.Image]: (
        <BookingOfferSearchCellAdapter
          index={index}
          component={BookingOfferTableCellImage}
        />
      ),
      [EBookingOfferTableColumn.Date]: (
        <BookingOfferSearchCellAdapter
          index={index}
          component={BookingOfferTableCellDate}
        />
      ),
      [EBookingOfferTableColumn.Code]: (
        <BookingOfferSearchCellAdapter
          index={index}
          component={BookingOfferTableCellCode}
        />
      ),
      [EBookingOfferTableColumn.Name]: (
        <BookingOfferSearchCellAdapter
          index={index}
          component={BookingOfferTableCellName}
        />
      ),
      [EBookingOfferTableColumn.Address]: (
        <BookingOfferSearchCellAdapter
          index={index}
          component={BookingOfferTableCellAddress}
        />
      ),
      [EBookingOfferTableColumn.Partner]: (
        <BookingOfferSearchCellAdapter
          index={index}
          component={BookingOfferTableCellPartner}
        />
      ),
      [EBookingOfferTableColumn.PartnerType]: (
        <BookingOfferSearchCellAdapter
          index={index}
          component={BookingOfferTableCellPartnerType}
        />
      ),
      [EBookingOfferTableColumn.Category]: (
        <BookingOfferSearchCellAdapter
          index={index}
          component={BookingOfferTableCellCategory}
        />
      ),
      [EBookingOfferTableColumn.ResponsibleFullName]: (
        <BookingOfferSearchCellAdapter
          index={index}
          component={BookingOfferTableCellResponsible}
        />
      ),
      [EBookingOfferTableColumn.ResponsiblePhone]: (
        <BookingOfferSearchCellAdapter
          index={index}
          component={BookingOfferTableCellResponsiblePhone}
        />
      ),
      [EBookingOfferTableColumn.ResponsibleEmail]: (
        <BookingOfferSearchCellAdapter
          index={index}
          component={BookingOfferTableCellResponsibleEmail}
        />
      ),
      [EBookingOfferTableColumn.Moderator]: (
        <BookingOfferSearchCellAdapter
          index={index}
          component={BookingOfferTableCellModerator}
        />
      ),
      [EBookingOfferTableColumn.RzdSocialPackage]: (
        <BookingOfferSearchCellAdapter
          index={index}
          component={BookingOfferTableCelRzdSocialPackage}
        />
      ),
      data: bookingOffer,
    };
  });

  return (
    <DataTable<BookingOfferShort, EBookingOfferTableColumn>
      metadata={metadata}
      width='auto'
      overflowX='inherit'
      rows={rows}
      sort={{ column: sort.column, direction: sort.direction }}
      loader={<DataTableLoader selector={bookingOfferSearchIsFetchingSelector} />}
      selectable
      singleSelect={isSingleSelect}
      selected={selectedBookingOffer}
      onSort={(event, column, direction) => onRequestSort(column, direction)}
      onRowClick={(event, cell, { data }) => onBookingOfferClick(data)}
      onAllRowsSelect={(event, selected) => onAllBookingOfferSelect(selected)}
      onRowSelect={(event, { data }, selected) => onBookingOfferSelect(data, selected)}
      onChangeMetadata={onChangeMetadata}
    />
  );
};

export default BookingOfferSearch;
