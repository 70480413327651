import { combineReducers } from '@reduxjs/toolkit';
import details, { PlaygroundDetailsState } from './details/store/slice';
import search, { PlaygroundsSearchState } from './search/store/slice';
import list, { PlaygroundsListState } from './table/store/slice';

interface PlaygroundState {
  list: PlaygroundsListState;
  details: PlaygroundDetailsState;
  search: PlaygroundsSearchState;
}

export default combineReducers<PlaygroundState>({
  list,
  details,
  search,
});
