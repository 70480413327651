import React from 'react';
import { BookingOrder } from '../../../../domain/model/order';
import { Nullable } from '../../../../domain/model/types';
import { BookingOrderLifeCycle } from '../lifecycle/types';
import {
  BookingOrderAction,
  BookingOrderActionDetailsType,
  BookingOrderActions,
  EBookingOrderActionType,
} from '../types';
import { BookingOrderDetailsContainerProps } from './container';
import { BookingOrderDetailsState } from './store/slice';

export type BookingOrderDetailsParamsContextValue = BookingOrderDetailsContainerProps;

export const BookingOrderDetailsParamsContext = React.createContext<BookingOrderDetailsParamsContextValue>(
  {} as BookingOrderDetailsParamsContextValue
);

export type BookingOrderDetailsLifecycleContextValue = {
  readonly lifecycle: BookingOrderLifeCycle;
  readonly actions: BookingOrderActions<BookingOrderActionDetailsType>;
  readonly onAction: (action: BookingOrderAction<BookingOrderActionDetailsType>) => void;
};

export const BookingOrderDetailsLifecycleContext = React.createContext<BookingOrderDetailsLifecycleContextValue>(
  {} as BookingOrderDetailsLifecycleContextValue
);

export type BookingOrderDetailsHandlersContextValue = {
  readonly onRefresh: () => void;
  readonly onClose: () => void;
  readonly onChangeDialogState: (
    name: keyof BookingOrderDetailsState['dialogs'],
    bookingOrder: Nullable<BookingOrder>
  ) => void;

  readonly onBookingOrderOpenEdit: () => void;
  readonly onBookingOrderTryCancel: () => void;
  readonly onBookingOrderSwitchToEdit: (bookingOrder: BookingOrder) => void;
  readonly onBookingOrderClose: (action: Nullable<EBookingOrderActionType>) => void;
  readonly onBookingOrderRefetch: (bookingOrder: BookingOrder) => Promise<BookingOrder>;
};

export const BookingOrderDetailsHandlersContext = React.createContext<BookingOrderDetailsHandlersContextValue>(
  {} as BookingOrderDetailsHandlersContextValue
);
