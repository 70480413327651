import { Fade, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { UUID } from '../../../../domain/model/types';
import DefaultHeader from '../../../components/common/header';
import Splitter from '../../../components/common/splitter';
import { DefaultContentWrapper } from '../../../components/common/wrappers/content';
import { getConfigurationSportDictionaryRoute } from '../entry';
import { EConfigurationSportDictionaryType } from '../utils';
import { ConfigurationSportDictionaries } from './components/dictionaries';

interface ConfigurationSportContainerProps {
  readonly guid: UUID;
  readonly canEditActivityTypes?: boolean;
}

const ConfigurationSportContainer = (props: ConfigurationSportContainerProps) => {
  const { canEditActivityTypes } = props;

  const history = useHistory();

  const onEditDictionary = (type: EConfigurationSportDictionaryType) => {
    const editLocation = getConfigurationSportDictionaryRoute({ type });
    if (editLocation) {
      history.push(editLocation);
    } else {
      console.error(`Unknown location to edit dictionary ${type}`);
    }
  };

  return (
    <Fade in>
      <DefaultContentWrapper
        type='table'
        stickyHeader
      >
        <DefaultHeader>
          <Typography variant='h2'>Настройки сервиса</Typography>
        </DefaultHeader>
        <Splitter size={4} />
        <ConfigurationSportDictionaries
          canEditActivityTypes={canEditActivityTypes}
          onEdit={onEditDictionary}
        />
      </DefaultContentWrapper>
    </Fade>
  );
};

export default ConfigurationSportContainer;
