import { CorpOfferActivation } from '@/domain/model/activation';
import { ESortDirection } from '@/domain/model/enums';
import { DataTableLoader } from '@/presentation/components/common/table/loader';
import CorpActivationTableCellPartnerName from '@features/corpActivation/components/tableCell/partnerName';
import { corpActivationsIsFetchingSelector } from '@features/corpActivation/table/store/selectors';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../components/common/table';
import TableActionsContainer from '../../../components/common/table/actions/container';
import { useCorpActivationActionsSelectors } from '../actions/useAction';
import CorpActivationTableCellCreateAt from '../components/tableCell/createAt';
import CorpActivationTableCellCustomerEmail from '../components/tableCell/customerEmail';
import CorpActivationTableCellCustomerName from '../components/tableCell/customerName';
import CorpActivationTableCellCustomerPernr from '../components/tableCell/customerPernr';
import CorpActivationTableCellCustomerPhone from '../components/tableCell/customerPhone';
import CorpActivationTableCellGivenAt from '../components/tableCell/givenAt';
import CorpActivationTableCellGivenBy from '../components/tableCell/givenBy';
import CorpActivationTableCellOfferCode from '../components/tableCell/offerCode';
import CorpActivationTableCellOfferName from '../components/tableCell/offerName';
import CorpActivationTableCellPrice from '../components/tableCell/price';
import CorpActivationTableCellPromotionCode from '../components/tableCell/promotionCode';
import CorpActivationTableCellPromotionType from '../components/tableCell/promotionType';
import CorpActivationTableCellStatus from '../components/tableCell/status';
import { CorpActivationActions, CorpActivationActionTableType, ECorpActivationTableColumn } from '../types';
import CorpActivationTableCellAdapter from './adapters/cell';
import CorpActivationTableCellPromotionTypeAdapter from './adapters/cellPromotionType';

interface CorpActivationTableProps {
  readonly metadata: DataTableMetadata<ECorpActivationTableColumn>;
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly activations: CorpOfferActivation[];
  readonly getActions: (activation: CorpOfferActivation) => CorpActivationActions<CorpActivationActionTableType>;
  readonly onRequestSort: (column: ECorpActivationTableColumn, direction: ESortDirection) => void;
  readonly onAction: (action: CorpActivationActionTableType, activation: CorpOfferActivation) => void;
  readonly onChangeMetadata?: (metadata: DataTableMetadata<ECorpActivationTableColumn>) => void;
}

const CorpActivationTable = (props: CorpActivationTableProps) => {
  const { metadata, activations, sort, onRequestSort, onChangeMetadata, onAction, getActions } = props;

  const { getIsCorpActivationProcessingSelector } = useCorpActivationActionsSelectors();

  const rows: DataTableRow<CorpOfferActivation, ECorpActivationTableColumn>[] = activations?.map(
    (activation, index) => {
      return {
        [ECorpActivationTableColumn.CreateAt]: (
          <CorpActivationTableCellAdapter
            index={index}
            component={CorpActivationTableCellCreateAt}
          />
        ),
        [ECorpActivationTableColumn.OfferCode]: (
          <CorpActivationTableCellAdapter
            index={index}
            component={CorpActivationTableCellOfferCode}
          />
        ),
        [ECorpActivationTableColumn.OfferName]: (
          <CorpActivationTableCellAdapter
            index={index}
            component={CorpActivationTableCellOfferName}
          />
        ),
        [ECorpActivationTableColumn.PromotionType]: (
          <CorpActivationTableCellPromotionTypeAdapter
            index={index}
            component={CorpActivationTableCellPromotionType}
          />
        ),
        [ECorpActivationTableColumn.PromotionCode]: (
          <CorpActivationTableCellAdapter
            index={index}
            component={CorpActivationTableCellPromotionCode}
          />
        ),
        [ECorpActivationTableColumn.CustomerName]: (
          <CorpActivationTableCellAdapter
            index={index}
            component={CorpActivationTableCellCustomerName}
          />
        ),
        [ECorpActivationTableColumn.CustomerPernr]: (
          <CorpActivationTableCellAdapter
            index={index}
            component={CorpActivationTableCellCustomerPernr}
          />
        ),
        [ECorpActivationTableColumn.CustomerEmail]: (
          <CorpActivationTableCellAdapter
            index={index}
            component={CorpActivationTableCellCustomerEmail}
          />
        ),
        [ECorpActivationTableColumn.CustomerPhone]: (
          <CorpActivationTableCellAdapter
            index={index}
            component={CorpActivationTableCellCustomerPhone}
          />
        ),
        [ECorpActivationTableColumn.Price]: (
          <CorpActivationTableCellAdapter
            index={index}
            component={CorpActivationTableCellPrice}
          />
        ),
        [ECorpActivationTableColumn.Status]: (
          <CorpActivationTableCellAdapter
            index={index}
            component={CorpActivationTableCellStatus}
          />
        ),
        [ECorpActivationTableColumn.GivenAt]: (
          <CorpActivationTableCellAdapter
            index={index}
            component={CorpActivationTableCellGivenAt}
          />
        ),
        [ECorpActivationTableColumn.GivenBy]: (
          <CorpActivationTableCellAdapter
            index={index}
            component={CorpActivationTableCellGivenBy}
          />
        ),
        [ECorpActivationTableColumn.Partner]: (
          <CorpActivationTableCellAdapter
            index={index}
            component={CorpActivationTableCellPartnerName}
          />
        ),
        data: activation,
      };
    }
  );

  return (
    <DataTable<CorpOfferActivation, ECorpActivationTableColumn>
      width='auto'
      overflowX='inherit'
      metadata={metadata}
      rows={rows}
      rowActions
      sort={{ column: sort.column, direction: sort.direction }}
      loader={<DataTableLoader selector={corpActivationsIsFetchingSelector} />}
      onSort={(event, column, direction) => onRequestSort(column as ECorpActivationTableColumn, direction)}
      onChangeMetadata={onChangeMetadata}
      getRowActions={activation => {
        return (
          <TableActionsContainer<CorpOfferActivation, CorpActivationActionTableType>
            entityLink={activation}
            actions={getActions(activation)}
            onExecute={onAction}
            isFetchingSelector={getIsCorpActivationProcessingSelector(activation.id)}
          />
        );
      }}
    />
  );
};

export default CorpActivationTable;
