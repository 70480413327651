import { Link, Typography } from '@mui/material';
import Splitter from '../../components/common/splitter';
import { Content, Title, Wrapper } from './controls';

const NotFoundContainer = () => {
  return (
    <Wrapper>
      <Content>
        <Title variant='h2'>Ошибка 404</Title>
        <Splitter
          variant='horizontal'
          size={2.5}
        />
        <Typography variant='h3'>Такой страницы не существует</Typography>
        <Splitter
          variant='horizontal'
          size={2.5}
        />
        <Typography>
          Если вы администратор или партнер РЖД Витрины, вот <Link href='/'>ссылка в личный кабинет</Link>. Возможно,
          надо будет авторизоваться
        </Typography>
      </Content>
    </Wrapper>
  );
};

export default NotFoundContainer;
