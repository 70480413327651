import { observablePattern, observables, useAuthentication } from '@mp-npm/mp-auth-client';
import { useEffect } from 'react';
import { ENoticeStatus } from '../../../../domain/model/enums';
import Notifier from '../../../../system/notifier';
import CurrentUserProvider from '../../user/current/provider/provider';
import { getAllowedUserRoles } from '../../user/securityUtils';

interface MainSecurityProps {
  readonly children: JSX.Element;
}

const MainSecurity = ({ children }: MainSecurityProps) => {
  const { authService, token, refreshToken } = useAuthentication();
  const { logout } = authService;

  useEffect(() => {
    const observer = new observablePattern.Observer(() => {
      setTimeout(logOut, 5000);
      Notifier.getInstance().addNotice(ENoticeStatus.Error, 'Необходима авторизация. Сеанс будет завершен.');
    });

    observables.onAuthRefreshErrorObservableSubject.subscribe(observer);

    return () => {
      observables.onAuthRefreshErrorObservableSubject.unsubscribe(observer);
    };
  }, []);

  const logOut = () => {
    logout({ redirectUri: window.origin });
  };

  const resetPassword = () => {
    // window.location = `https://mp-dev.myservices.digital/auth/realms/mp/login-actions/reset-credentials`;
  };

  const roles = getAllowedUserRoles(token?.realm_access?.roles ?? []);
  console.debug('Keycloak user', token);

  if (!token) {
    return null;
  }

  return (
    <CurrentUserProvider
      user={token}
      roles={roles}
      logOut={logOut}
      refreshToken={refreshToken}
      resetPassword={resetPassword}
    >
      {children}
    </CurrentUserProvider>
  );
};

export default MainSecurity;
