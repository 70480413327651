import moment from 'moment';
import { EDateFormat } from '@/domain';
import TradeOfferTableCell, { TradeOfferTableCellCommonProps } from './index';

const TradeOfferTableCellLastStatusDate = (props: TradeOfferTableCellCommonProps) => {
  const {
    tradeOffer: { lastStatusDate },
  } = props;

  const value = lastStatusDate ? moment(lastStatusDate).format(EDateFormat.DisplayDefault) : '-';

  return (
    <TradeOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default TradeOfferTableCellLastStatusDate;
