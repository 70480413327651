import { DataFilterStrategyAdapterTreeMultiple } from '../../../../../domain/model/filter';
import { Nullable } from '../../../../../domain/model/types';
import { DataFilterEditItemProps } from '../../../../components/common/dataFilter/edit';
import ActivityTypeEditControlTreeMultipleContainer from '../../editControl/treeMultiple';
import { ActivityType } from '../../../../../domain/model/event';

type ValueType<T extends string> = DataFilterStrategyAdapterTreeMultiple<T, ActivityType[]>['value'];

interface ActivityTypeFilterEditAdapterProps<T extends string>
  extends DataFilterEditItemProps<T, Nullable<ValueType<T>>> {
  readonly strategy: DataFilterStrategyAdapterTreeMultiple<T, ActivityType[]>;
}

const ActivityTypeFilterEditAdapter = <T extends string>(props: ActivityTypeFilterEditAdapterProps<T>) => {
  const { strategy, onChange } = props;

  return (
    <ActivityTypeEditControlTreeMultipleContainer
      label={strategy.label}
      value={strategy.value ?? []}
      disabled={strategy.readOnly}
      onChange={value => onChange(strategy, value)}
    />
  );
};

export default ActivityTypeFilterEditAdapter;
