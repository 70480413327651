import { useCallback, useEffect, useMemo, useState } from 'react';
import { Nullable } from '../../domain/model/types';
import { DataTableMetadata, MutableDataTableColumns, TableColumn } from '../components/common/table';
import useUserSettings from '../features/user/hooks/useUserSettings';
import { UserDataTableSettings } from '../types';
import { calculateTableMetadataChanges } from '../utils/tables';

const primaryKey = 'table';
const keySeparator = '-';

interface UseDataTableSettingsProps<C extends string> {
  /* имя таблицы */
  readonly name: string;
  /* дополнение к имени таблицы - ['partner', 'on_moderation', ...] */
  readonly nameParts?: (string | number | null)[];
  /* исходные столбцы */
  readonly sourceColumns: MutableDataTableColumns<C>;
  readonly onColumnsRemoved?: (columns: C[]) => void;
}

type UseDataTableSettingsResult<C extends string> = {
  readonly metadata: DataTableMetadata<C>;
  readonly onChangeMetadata: (metadata: DataTableMetadata<C>) => void;
};

const changeColumnsVisible = <C extends string>(
  sourceColumns: MutableDataTableColumns<C>,
  outColumns: Nullable<C[]>
): MutableDataTableColumns<C> => {
  if (outColumns === null) {
    return sourceColumns;
  }

  const copyColumns = {} as MutableDataTableColumns<C>;
  Object.entries<TableColumn>(sourceColumns as Record<C, TableColumn>).forEach(([keyString, column]) => {
    const key = keyString as C;
    copyColumns[key] = {
      ...column,
      hidden: !outColumns.includes(key),
    };
  });

  return copyColumns;
};

const useDataTableSettings = <C extends string>(props: UseDataTableSettingsProps<C>): UseDataTableSettingsResult<C> => {
  const { name, nameParts, sourceColumns, onColumnsRemoved } = props;

  const finalParts = [primaryKey, name, ...(nameParts ?? []).map(part => part ?? 'null')];
  const key = finalParts.join(keySeparator);

  const defaultValue = useMemo<UserDataTableSettings<C>>(
    () => ({ columns: Object.keys(sourceColumns) as C[] }),
    [sourceColumns]
  );

  const { value: tableSettings, onChange } = useUserSettings<UserDataTableSettings<C>>({
    key,
    defaultValue,
  });

  const columns = useMemo<MutableDataTableColumns<C>>(
    () => changeColumnsVisible(sourceColumns, tableSettings?.columns ?? null),
    [sourceColumns, tableSettings?.columns]
  );

  const metadata = useMemo<DataTableMetadata<C>>(() => ({ columns }), [columns]);

  const [prevColumns, setPrevColumns] = useState<typeof metadata.columns>(metadata.columns);

  const onChangeMetadata = useCallback(
    (newMetadata: typeof metadata) => {
      onChange({
        columns: Object.entries<TableColumn>(newMetadata.columns as Record<C, TableColumn>)
          .filter(([, column]) => !column.hidden)
          .map(([columnKey]) => columnKey as C),
      });
    },
    [onChange]
  );

  useEffect(() => {
    if (!onColumnsRemoved) {
      return;
    }

    const { removedColumns } = calculateTableMetadataChanges(prevColumns, metadata.columns);

    if (removedColumns.length > 0) {
      onColumnsRemoved(removedColumns);
    }
  }, [prevColumns, metadata.columns, onColumnsRemoved]);

  useEffect(() => {
    setPrevColumns(metadata.columns);
  }, [metadata.columns]);

  return {
    metadata,
    onChangeMetadata,
  };
};

export default useDataTableSettings;
