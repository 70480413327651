import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Nullable, UUID } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import { viewShowMainMenu } from '../../features/main/container/store/slice';
import TeamCreateContainer from '../../features/team/create/container';
import { TeamCreateLocationState } from '../../features/team/entry';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const TeamCreateScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation<Nullable<TeamCreateLocationState>>();

  const { teams } = useCurrentUser().accessMatrix;

  const guid: UUID = location.state?.guid ?? uuidv4();

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (!teams?.create) {
    return <ForbiddenComponent />;
  }

  return <TeamCreateContainer guid={guid} />;
};

export default TeamCreateScreen;
