import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { ENoticeStatus } from '../../../../domain/model/enums';
import { Team } from '../../../../domain/model/team';
import { Nullable, UUID } from '../../../../domain/model/types';
import { SportUserProfileShort } from '../../../../domain/model/user';
import Notifier from '../../../../system/notifier';
import { OnChangeObjectAttribute } from '../../../types';
import { getTeamLogoSelectRoute, getTeamRoute } from '../entry';
import TeamCreate from './component';
import { teamCreateGuid, teamCreateSaveSelector, teamCreateSelector } from './store/selectors';
import {
  teamCreateSave,
  teamCreateSetAttribute,
  teamCreateSetCapitan,
  teamCreateSetValidationErrors,
  teamCreateStartSession,
} from './store/slice';
import { hasErrors, validateForm } from './validation';

interface TeamCreateContainerProps {
  readonly guid: UUID;
}

const TeamCreateContainer = ({ guid }: TeamCreateContainerProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { data: team, validationErrors } = useSelector(teamCreateSelector);
  const { isFetched: isSaved, isFetching: isSaving } = useSelector(teamCreateSaveSelector);
  const currentGuid = useSelector(teamCreateGuid);

  const onTeamCreateSave = () => {
    const isValid = validate();

    if (isValid) {
      dispatch(teamCreateSave({ team }));
    } else {
      Notifier.getInstance().addNotice(ENoticeStatus.Error, 'Ошибки при заполнении!');
    }
  };

  const onChangeAttribute: OnChangeObjectAttribute<Team> = (name, value) => {
    dispatch(teamCreateSetAttribute({ name, value }));
  };

  const onLogoChangeClick = () => {
    history.push(getTeamLogoSelectRoute({ location, selected: team.logo?.id }));
  };

  const onChangeCaptain = useCallback(
    (captain: Nullable<SportUserProfileShort>) => {
      dispatch(teamCreateSetCapitan(captain));
    },
    [dispatch]
  );

  const validate = () => {
    const errors = validateForm(team);
    dispatch(teamCreateSetValidationErrors({ errors }));

    return !hasErrors(errors);
  };

  useEffect(() => {
    if (guid !== currentGuid) {
      dispatch(teamCreateStartSession(guid));
    }
  }, [dispatch, guid, currentGuid]);

  useEffect(() => {
    if (isSaved && guid === currentGuid) {
      Notifier.getInstance().addNotice(ENoticeStatus.Success, 'Команда создана');
      history.replace(getTeamRoute({ id: team?.id }));
    }
  }, [dispatch, isSaved, guid, currentGuid]);

  return (
    <TeamCreate
      team={team}
      validationErrors={validationErrors}
      isSaving={isSaving}
      onChangeAttribute={onChangeAttribute}
      onLogoChangeClick={onLogoChangeClick}
      onChangeCaptain={onChangeCaptain}
      onSave={onTeamCreateSave}
    />
  );
};

export default TeamCreateContainer;
