import { EMainMenuType } from '@features/main/menu/enums';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuPanelItem } from '../../../../../types';
import { menuPanelItemIsSelected } from '../../../../general/menu/utils';
import { getSupportInfoDetailsRoute } from '../../../../support/entry';
import { getCurrentUserDetailsRoute } from '../../../../user/entry';
import useCurrentUser from '../../../../user/hooks/useCurrentUser';
import { MainMenuItemSupport } from './support';
import { MainMenuItemUser } from './user';

interface MainMenuFooterAdapterProps {
  readonly menuType: EMainMenuType;
  readonly onItemClick: (item: MenuPanelItem) => void;
}

export const MainMenuFooterAdapter = ({ menuType, onItemClick }: MainMenuFooterAdapterProps) => {
  const location = useLocation();
  const { userSpecific } = useCurrentUser();

  const userProfileItem: MenuPanelItem = {
    link: getCurrentUserDetailsRoute(),
    id: '',
    icon: '',
    caption: '',
  };

  const supportInfoItem: MenuPanelItem = {
    link: getSupportInfoDetailsRoute(),
    id: '',
    icon: '',
    caption: '',
  };

  const isUserProfileSelected = useMemo(() => menuPanelItemIsSelected(userProfileItem, location), [location]);
  const isSupportInfoSelected = useMemo(() => menuPanelItemIsSelected(supportInfoItem, location), [location]);

  const onSupportInfoItemClick = () => onItemClick(supportInfoItem);
  const onUserProfileItemClick = () => onItemClick(userProfileItem);

  return useMemo(() => {
    return (
      <>
        {menuType === EMainMenuType.Sport && (
          <MainMenuItemSupport
            selected={isSupportInfoSelected}
            onClick={onSupportInfoItemClick}
          />
        )}
        <MainMenuItemUser
          user={userSpecific}
          selected={isUserProfileSelected}
          onClick={onUserProfileItemClick}
        />
      </>
    );
  }, [isSupportInfoSelected, isUserProfileSelected, userSpecific, onUserProfileItemClick, onSupportInfoItemClick]);
};
