import { UserAccessPartitionBookingOrders } from '../../../../domain/model/accessMatrix';
import { BookingOrder } from '../../../../domain/model/order';
import { Nullable } from '../../../../domain/model/types';
import { EPanelActionPosition } from '../../../types';
import { BookingOrderLifeCycle } from '../lifecycle/types';
import {
  BookingOrderAction,
  BookingOrderActionDetailsType,
  BookingOrderActionEditType,
  BookingOrderActions,
  BookingOrderActionTableType,
  EBookingOrderActionType,
} from '../types';

export type GetBookingOrderActionsByPermissionsProps = UserAccessPartitionBookingOrders;

export const getBookingOrderActionName = (type: EBookingOrderActionType): string => {
  switch (type) {
    case EBookingOrderActionType.Cancel:
      return 'Отменен';
    case EBookingOrderActionType.Give:
      return 'Выполнен';
    case EBookingOrderActionType.Save:
      return 'Сохранить';
    case EBookingOrderActionType.Renew:
      return 'Создан';
    case EBookingOrderActionType.New:
      return 'Создать заказ';
    case EBookingOrderActionType.Confirm:
      return 'Принят';
  }
};

export const getBookingOrderActionsConfigByPermissions = (
  props: GetBookingOrderActionsByPermissionsProps
): BookingOrderActions => {
  const { create, save, renew, confirm, give, cancel } = props;

  const actions: BookingOrderActions = [];

  let onCreate: Nullable<BookingOrderAction> = null;
  let onSave: Nullable<BookingOrderAction> = null;
  let onRenew: Nullable<BookingOrderAction> = null;
  let onConfirm: Nullable<BookingOrderAction> = null;
  let onGive: Nullable<BookingOrderAction> = null;
  let onCancel: Nullable<BookingOrderAction> = null;

  if (create) {
    onCreate = {
      type: EBookingOrderActionType.New,
      disabled: false,
      label: getBookingOrderActionName(EBookingOrderActionType.New),

      position: [EPanelActionPosition.Default],
    };
  }
  if (confirm) {
    onConfirm = {
      type: EBookingOrderActionType.Confirm,
      disabled: false,
      groupLabel: 'Сохранить и перевести в статус',
      label: getBookingOrderActionName(EBookingOrderActionType.Confirm),
      primary: true,
      position: [EPanelActionPosition.Default],
    };
  }
  if (give) {
    onGive = {
      type: EBookingOrderActionType.Give,
      disabled: false,
      groupLabel: 'Сохранить и перевести в статус',
      label: getBookingOrderActionName(EBookingOrderActionType.Give),
      position: [EPanelActionPosition.Default],
    };
  }
  if (cancel) {
    onCancel = {
      type: EBookingOrderActionType.Cancel,
      disabled: false,
      label: getBookingOrderActionName(EBookingOrderActionType.Cancel),
      position: [EPanelActionPosition.Default],
    };
  }
  if (renew) {
    onRenew = {
      type: EBookingOrderActionType.Renew,
      disabled: false,
      label: getBookingOrderActionName(EBookingOrderActionType.Renew),

      position: [EPanelActionPosition.Default],
    };
  }
  if (save) {
    onSave = {
      type: EBookingOrderActionType.Save,
      disabled: false,
      label: getBookingOrderActionName(EBookingOrderActionType.Save),

      position: [EPanelActionPosition.Default],
    };
  }

  // Порядок важен!!!
  if (onCreate) actions.push(onCreate);
  if (onSave) actions.push(onSave);
  if (onRenew) actions.push(onRenew);
  if (onConfirm) actions.push(onConfirm);
  if (onGive) actions.push(onGive);
  if (onCancel) actions.push(onCancel);

  return actions;
};

export const changeBookingOrderActionPosition = (
  actions: BookingOrderActions,
  type: EBookingOrderActionType,
  position: EPanelActionPosition[]
) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      position,
    };
  }
};

export const renameBookingOrderAction = (
  actions: BookingOrderActions,
  type: EBookingOrderActionType,
  label: string
) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      label,
    };
  }
};

export const makeBookingOrderActionPrimary = (actions: BookingOrderActions, type: EBookingOrderActionType) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      primary: true,
    };
  }
};

export const getBookingOrderTableActions = (
  lifecycle: BookingOrderLifeCycle,
  order: BookingOrder,
  allowedActions: BookingOrderActions<BookingOrderActionTableType>
) => {
  return lifecycle.build({
    obj: order,
    allowedActions,
  }).nextActions as BookingOrderActions<BookingOrderActionTableType>;
};

export const filterBookingOrderDetailsActions = (
  actions: BookingOrderActions
): BookingOrderActions<BookingOrderActionDetailsType> => {
  const detailsActions: BookingOrderActions<BookingOrderActionDetailsType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EBookingOrderActionType.Save:
      case EBookingOrderActionType.Cancel:
        detailsActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          position: action.position,
        });
        break;
      case EBookingOrderActionType.Give:
      case EBookingOrderActionType.Confirm:
      case EBookingOrderActionType.Renew:
      case EBookingOrderActionType.New:
        break;
    }
  });

  return detailsActions;
};

export const filterBookingOrderEditActions = (
  actions: BookingOrderActions
): BookingOrderActions<BookingOrderActionEditType> => {
  const editActions: BookingOrderActions<BookingOrderActionEditType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EBookingOrderActionType.Save:
      case EBookingOrderActionType.Confirm:
      case EBookingOrderActionType.Give:
      case EBookingOrderActionType.Cancel:
        editActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          groupLabel: action.groupLabel,
          position: action.position,
        });
        break;
      case EBookingOrderActionType.Renew:
      case EBookingOrderActionType.New:
        break;
    }
  });

  return editActions;
};

export const filterBookingOrderTableActions = (
  actions: BookingOrderActions
): BookingOrderActions<BookingOrderActionTableType> => {
  const tableActions: BookingOrderActions<BookingOrderActionTableType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EBookingOrderActionType.Give:
      case EBookingOrderActionType.Confirm:
      case EBookingOrderActionType.Cancel:
        tableActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          position: action.position,
        });
        break;
      case EBookingOrderActionType.Renew:
      case EBookingOrderActionType.Save:
      case EBookingOrderActionType.New:
        break;
    }
  });

  return tableActions;
};
