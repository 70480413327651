import store from '@/data/store/store';
import { AdCampaignCreate, EAdCampaignStatus } from '@/domain';
import { addSearchParamToLocation } from '@/routing/globalRouting';
import { FCC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import useHistoryExtensions from '../../../../hooks/useHistoryExtensions';
import validateObject from '../../../../hooks/validation/utils';
import { ValidationResult, ValidationRules } from '../../../../utils/validation';
import { useAdCampaignActions } from '../../actions/useActions';
import { EAdCampaignUrlParam, getAdCampaignsTableRoute } from '../../entry';
import { AdCampaignCreateStep, AdCampaignForValidation } from '../../types';
import { showAdCampaignInvalidNotification } from '../../utils/common';
import { adCampaignBusinessValidationRules, adCampaignValidationRulesSave } from '../../utils/validation';
import { AdCampaignCreateContainerProps } from '../container';
import { AdCampaignCreateHandlersContext, AdCampaignCreateHandlersContextValue } from '../context';
import {
  adCampaignCreateAdCampaignSelector,
  adCampaignCreateUiStepsStateSelector,
  adCampaignCreateValidationResultsSelector,
  adCampaignCreateValidationStepperSelector,
} from '../store/selectors';
import {
  adCampaignCreateClearAllValidations,
  adCampaignCreateClearAttributeValidation,
  adCampaignCreateSetAttribute,
  adCampaignCreateSetAttributeValidation,
  adCampaignCreateSetModified,
  adCampaignCreateSetValidation,
  adCampaignCreateSetValidationStepper,
  AdCampaignCreateValidationStepper,
} from '../store/slice';
import { adCampaignCreateInitValidationStepper } from '../utils';

export const AdCampaignCreateHandlersProvider: FCC<AdCampaignCreateContainerProps> = ({ step, children }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const { onTrySave, onTryArchive, onTryResume, onTryPause } = useAdCampaignActions();

  const getAdCampaignFromState = useCallback((): AdCampaignCreate => {
    const state = store.getState();
    return adCampaignCreateAdCampaignSelector(state);
  }, [store]);

  const getValidationFromState = useCallback((): ValidationResult<AdCampaignCreate> => {
    const state = store.getState();
    return adCampaignCreateValidationResultsSelector(state);
  }, [store]);

  const getAdCampaignStepsFromState = useCallback((): Nullable<AdCampaignCreateStep[]> => {
    const state = store.getState();
    return adCampaignCreateUiStepsStateSelector(state);
  }, [store]);

  const getAdCampaignValidationStepperFromState = useCallback((): AdCampaignCreateValidationStepper => {
    const state = store.getState();
    return adCampaignCreateValidationStepperSelector(state);
  }, [store]);

  const onClose = useCallback(() => {
    gotoPrevIndependentLocation(getAdCampaignsTableRoute({}));
  }, [gotoPrevIndependentLocation]);

  const onChangeStep = useCallback<AdCampaignCreateHandlersContextValue['onChangeStep']>(
    newStep => {
      if (newStep.key !== step) {
        history.push(
          addSearchParamToLocation({
            location,
            param: EAdCampaignUrlParam.Step,
            value: newStep.key,
          })
        );
      }
    },
    [dispatch, step, history, location]
  );

  const onChangeAttribute = useCallback<AdCampaignCreateHandlersContextValue['onChangeAttribute']>(
    (name, value) => {
      dispatch(adCampaignCreateClearAttributeValidation(name));
      dispatch(adCampaignCreateSetAttribute({ name, value }));
      if (name === 'offerType' || name === 'partner' || name === 'startDate' || name === 'endDate') {
        dispatch(adCampaignCreateClearAttributeValidation('offers'));
        dispatch(adCampaignCreateSetAttribute({ name: 'offers', value: null }));
      }
      if (name === 'type') {
        dispatch(adCampaignCreateClearAttributeValidation('keywords'));
        dispatch(adCampaignCreateSetAttribute({ name: 'keywords', value: null }));
      }
      onChangeModified(true);
      onChangeValidationStepper();
    },
    [dispatch]
  );

  const onChangeModified = useCallback(
    (modified: boolean) => {
      dispatch(adCampaignCreateSetModified(modified));
    },
    [dispatch]
  );

  const onAttributeValidate = useCallback<AdCampaignCreateHandlersContextValue['onAttributeValidate']>(
    name => {
      const adCampaign = getAdCampaignFromState();

      //не проверяем пустые значения
      if (!adCampaign?.[name]) {
        return;
      }

      const rules = { [name]: adCampaignBusinessValidationRules[name] ?? {} };
      const results = validateObject(adCampaign, rules).results?.[name] ?? null;
      dispatch(adCampaignCreateSetAttributeValidation({ name, results }));

      onChangeValidationStepper();
    },
    [dispatch, getAdCampaignFromState]
  );

  // обновляем валидацию для степпера
  const onChangeValidationStepper = useCallback(() => {
    const validation = getValidationFromState();

    dispatch(adCampaignCreateSetValidationStepper(adCampaignCreateInitValidationStepper(validation)));
  }, [dispatch, getValidationFromState]);

  // РК - валидация без списков ответственных, контактов и услуг
  const onValidateAdCampaign = useCallback(
    (adCampaign: AdCampaignForValidation, rules: ValidationRules<AdCampaignForValidation>): boolean => {
      //запускаем валидацию бизнес правилам
      const byBusinessValidation = validateObject(adCampaign, adCampaignBusinessValidationRules);

      //запускаем валидацию по целевым правилам
      const byActionValidation = validateObject(adCampaign, rules);

      //сохраняем суммарные результаты валидации
      dispatch(adCampaignCreateSetValidation({ ...byBusinessValidation.results, ...byActionValidation.results }));

      // Обновляем валидацию степпера
      onChangeValidationStepper();

      //факт валидности берём суммарно
      return byBusinessValidation.isValid && byActionValidation.isValid;
    },
    [dispatch, validateObject]
  );

  const goToFirstIsNotValidStep = useCallback<AdCampaignCreateHandlersContextValue['goToFirstIsNotValidStep']>(() => {
    const steps = getAdCampaignStepsFromState();
    const stepperValid = getAdCampaignValidationStepperFromState();

    if (steps) {
      const firstStepIsNotValid = steps.find(step => stepperValid?.[step.key] !== null);

      if (firstStepIsNotValid) {
        onChangeStep(firstStepIsNotValid);
      }
    }
  }, [dispatch, history, location]);

  // публикация
  const onAdCampaignTrySave = useCallback<AdCampaignCreateHandlersContextValue['onAdCampaignTrySave']>(() => {
    const adCampaign = getAdCampaignFromState();

    dispatch(adCampaignCreateClearAllValidations());
    const isValidByActionRules = onValidateAdCampaign(adCampaign, adCampaignValidationRulesSave);

    // Обновляем валидацию степпера
    onChangeValidationStepper();

    if (isValidByActionRules) {
      onTrySave(adCampaign);
    } else {
      showAdCampaignInvalidNotification(adCampaign, EAdCampaignStatus.Active);
      goToFirstIsNotValidStep();
    }
  }, [dispatch, getAdCampaignFromState, onValidateAdCampaign, goToFirstIsNotValidStep]);

  // в архив
  const onAdCampaignTryArchive = useCallback<AdCampaignCreateHandlersContextValue['onAdCampaignTryArchive']>(() => {
    const adCampaign = getAdCampaignFromState();
    onTryArchive(adCampaign);
  }, [getAdCampaignFromState]);

  // возобновление
  const onAdCampaignTryResume = useCallback<AdCampaignCreateHandlersContextValue['onAdCampaignTryResume']>(() => {
    const adCampaign = getAdCampaignFromState();
    onTryResume(adCampaign);
  }, [getAdCampaignFromState]);

  // приостановка
  const onAdCampaignTryPause = useCallback<AdCampaignCreateHandlersContextValue['onAdCampaignTryPause']>(() => {
    const adCampaign = getAdCampaignFromState();
    onTryPause(adCampaign);
  }, [getAdCampaignFromState]);

  const value: AdCampaignCreateHandlersContextValue = {
    onClose,
    onChangeStep,
    onChangeAttribute,
    onAttributeValidate,

    onAdCampaignTrySave,
    onAdCampaignTryArchive,
    onAdCampaignTryResume,
    onAdCampaignTryPause,

    goToFirstIsNotValidStep,
  };

  return <AdCampaignCreateHandlersContext.Provider value={value}>{children}</AdCampaignCreateHandlersContext.Provider>;
};
