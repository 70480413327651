import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Address } from '../../../../../../../domain/model/address';
import { EPartnerOwnershipType } from '../../../../../../../domain/model/enums';
import { Nullable } from '../../../../../../../domain/model/types';
import ValidationErrorPanel from '../../../../../../components/common/validation/errorPanel';
import { ValidationResult } from '../../../../../../utils/validation';
import { getPartnerAddressLabelByOrgType } from '../../../../../general/partner/utils/common';
import AddressFullSingleSelector from '../../../../../address/components/selector/single/full';
import { createEmptyDraftAddress } from '../../../../../../utils/address';

interface PartnerApplicationEditStepAddressProps {
  readonly address: Nullable<Address>;
  readonly orgType: Nullable<EPartnerOwnershipType>;
  readonly isValid: boolean;
  readonly validation: Nullable<ValidationResult<Address>>;
  readonly onChangeAddress: (address: Address) => void;
}

const PartnerApplicationEditStepAddress = (props: PartnerApplicationEditStepAddressProps) => {
  const { address, orgType, isValid, validation, onChangeAddress } = props;

  const getAddressValidationResult = (): Nullable<ValidationResult<Address>> => {
    const result = validation;

    if (!result) return null;

    return {
      postalCode: result.postalCode,
      hierarchy: result.hierarchy,
    };
  };

  return (
    <Grid
      container
      spacing={2}
      direction='column'
    >
      <Grid item>
        <Typography variant='h3'>{getPartnerAddressLabelByOrgType(orgType)}</Typography>
      </Grid>

      <Grid
        item
        xs={12}
        spacing={2}
      >
        <AddressFullSingleSelector
          value={address}
          error={
            getAddressValidationResult()?.hierarchy?.hasError || getAddressValidationResult()?.postalCode?.hasError
          }
          helperText={
            getAddressValidationResult()?.hierarchy?.message || getAddressValidationResult()?.postalCode?.message
          }
          label='Адрес'
          onChange={address => {
            onChangeAddress(address ?? createEmptyDraftAddress());
          }}
        />
      </Grid>

      {!isValid && (
        <Grid item>
          <ValidationErrorPanel />
        </Grid>
      )}
    </Grid>
  );
};

export default PartnerApplicationEditStepAddress;
