import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../data/store/store';
import { ESortDirection } from '../../../../../../domain/model/enums';
import { Nullable } from '../../../../../../domain/model/types';
import { MpUserShort } from '../../../../../../domain/model/user';
import { EAdminMpTableColumn } from '../utils';

export const adminMpsGuidSelector = (store: RootState) => store.user.adminMp.list.guid;
export const adminMpsIsFetchingSelector = (store: RootState) => store.user.adminMp.list.isFetching;
export const adminMpsAdminsSelector = (store: RootState) => store.user.adminMp.list.data;
export const adminMpsSearchSelector = (store: RootState) => store.user.adminMp.list.search;
export const adminMpsFilterSelector = (store: RootState) => store.user.adminMp.list.filter;
export const adminMpsNeedRefreshWatcherSelector = (store: RootState) => store.user.adminMp.list.needRefreshWatcher;
export const adminMpsActionsSelector = (store: RootState) => store.user.adminMp.list.actions;

export const adminMpsPageNumberSelector = (store: RootState) => store.user.adminMp.list.pageNumber;
export const adminMpsPageCountSelector = (store: RootState) => store.user.adminMp.list.pageCount;
export const adminMpsTotalCountSelector = (store: RootState) => store.user.adminMp.list.totalCount;
export const adminMpsTabsCounterSelector = (store: RootState) => store.user.adminMp.list.tabsCounter;

export const adminMpsSortColumnSelector = (store: RootState) =>
  (store.user.adminMp.list.search.sort?.split(',') as [EAdminMpTableColumn, ESortDirection])?.[0] ?? '';
export const adminMpsSortDirectionSelector = (store: RootState) =>
  (store.user.adminMp.list.search.sort?.split(',') as [EAdminMpTableColumn, ESortDirection])?.[1] ?? '';

const adminMpsAdminIndexSelector = (store: RootState, index: number) => index;
const createAdminMpsAdminByIndexSelector = createSelector(
  adminMpsAdminsSelector,
  adminMpsAdminIndexSelector,
  (admins, index): Nullable<MpUserShort> => admins?.[index] ?? null
);

export const adminMpsAdminByIndexSelector = (index: number) => (store: RootState) =>
  createAdminMpsAdminByIndexSelector(store, index);
