import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EOfferHistoryTableTab } from '../../types';
import OfferFieldHistoryTableComponent from '../components/table/fieldHistory';
import OfferHistoryTableComponent from '../components/table/history';
import {
  offerHistoryTableFieldHistorySelector,
  offerHistoryTableHistorySelector,
  offerHistoryTableSortColumnSelector,
  offerHistoryTableSortDirectionSelector,
  offerHistoryTableTabSelector,
} from '../store/selectors';

const OfferHistoryTableAdapter = () => {
  const tab = useSelector(offerHistoryTableTabSelector);

  const offerStates = useSelector(offerHistoryTableHistorySelector);
  const fieldHistory = useSelector(offerHistoryTableFieldHistorySelector);
  const sortColumn = useSelector(offerHistoryTableSortColumnSelector);
  const sortDirection = useSelector(offerHistoryTableSortDirectionSelector);

  return useMemo(
    () =>
      tab === EOfferHistoryTableTab.Status ? (
        <OfferHistoryTableComponent
          offerStates={offerStates}
          sort={{ column: sortColumn, direction: sortDirection }}
        />
      ) : (
        <OfferFieldHistoryTableComponent
          fieldHistory={fieldHistory}
          sort={{ column: sortColumn, direction: sortDirection }}
        />
      ),
    [tab, offerStates, fieldHistory, sortColumn, sortDirection]
  );
};

export default OfferHistoryTableAdapter;
