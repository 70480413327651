import { BookingServicePriceUnit } from '../../../../../../domain/model/booking';
import { DictionaryDeleteDialog } from '../../../../../components/common/dictionary/dialog/delete';

interface BookingServicePriceUnitsEditDialogDeleteAdapterProps {
  readonly isLoading: boolean;
  readonly data: BookingServicePriceUnit;
  readonly onClose: () => void;
  readonly onDelete: (bookingServicePriceUnit: BookingServicePriceUnit) => void;
}

export const BookingServicePriceUnitsEditDialogDeleteAdapter = (
  props: BookingServicePriceUnitsEditDialogDeleteAdapterProps
) => {
  const { isLoading, data, onDelete, onClose } = props;

  return (
    <DictionaryDeleteDialog
      isLoading={isLoading}
      onClose={onClose}
      onDelete={() => onDelete(data)}
    >
      Вы уверены, что хотите удалить <b>«{data.name}»</b>?
    </DictionaryDeleteDialog>
  );
};
