import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nullable, PartnerDesk, UUID } from '@/domain';
import ContentLoader from '../../../components/common/loader';
import { ContainerWrapper } from './controls';
import { partnerDeskByIdSelector } from './store/selectors';
import { partnerDeskByIdFetch } from './store/slice';

interface PartnerDeskByIdContainerResult {
  readonly partnerDesk: Nullable<PartnerDesk>;
}

interface PartnerDeskByIdContainerProps {
  readonly id: UUID;
  readonly children: (result: PartnerDeskByIdContainerResult) => React.ReactNode;
}

const PartnerDeskByIdContainer = ({ id, children }: PartnerDeskByIdContainerProps) => {
  const dispatch = useDispatch();

  const { id: lastId, partnerDesk, isFetching } = useSelector(partnerDeskByIdSelector);
  const isNewPartnerId = lastId !== id;

  useEffect(() => {
    if (isNewPartnerId) {
      dispatch(partnerDeskByIdFetch(id));
    }
  }, [dispatch, id, isNewPartnerId]);

  if (isFetching || isNewPartnerId) {
    return (
      <ContainerWrapper>
        <ContentLoader />
      </ContainerWrapper>
    );
  }

  return <>{children({ partnerDesk })}</>;
};

export default PartnerDeskByIdContainer;
