import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from '.';

const empty = 0;

const CorpOfferTableCellOfferCounts = (props: CorpOfferTableCellCommonProps) => {
  const {
    corpOffer: { offerCount, notUsedOfferCount },
  } = props;

  const value = `${notUsedOfferCount ?? empty}/${offerCount ?? empty}`;

  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellOfferCounts;
