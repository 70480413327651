import { Nullable, UUID } from '@/domain/model/types';
import { DataFilterAdapter } from '@/presentation/hooks/useDataFilterAdapter';
import {
  corpActivationsFilterItems,
  ECorpActivationsFilterItem,
  getCorpActivationFilterStrategy,
} from '@features/corpActivation/utils/filter';
import { getCorpActivationTableVisibleColumns } from '@features/corpActivation/utils/table';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import { CorpActivationLifeCycleFactory } from '../lifecycle';
import { ECorpActivationTableColumn, ECorpActivationTableTab } from '../types';
import CorpActivationTableContainer from './container';
import { CorpActivationTableConfigContext } from './context';
import { useCorpActivationTableConfig } from './hooks/useTableConfig';

interface GetTableAdapterKeyParams {
  readonly isPartner?: boolean;
  readonly partnerId: Nullable<string>;
  readonly tab: ECorpActivationTableTab;
}

interface GetTableAdapterKeyResult {
  readonly key: string;
  readonly keyPartitions?: (string | number | null)[];
}

const getTableAdapterKey = (params: GetTableAdapterKeyParams): GetTableAdapterKeyResult => {
  const { isPartner, partnerId, tab } = params;

  const key = 'corpActivations';
  let keyPartitions;

  if (isPartner) {
    keyPartitions = ['partner', tab];
  } else {
    keyPartitions = ['admin', partnerId ? 'byPartner' : 'common', tab];
  }

  return {
    key,
    keyPartitions,
  };
};

interface CorpActivationTableEntryProps {
  readonly guid: UUID;
  readonly tab: Nullable<ECorpActivationTableTab>;
  readonly partnerId?: Nullable<UUID>;
  readonly customerId?: Nullable<UUID>;
}

const CorpActivationTableEntry = (props: CorpActivationTableEntryProps) => {
  const { guid, tab, partnerId = null, customerId = null } = props;
  const { accessMatrix, userSpecific } = useCurrentUser();
  const { isPartnerUser, corpActivations } = accessMatrix;

  const config = useCorpActivationTableConfig();

  const { defaultTab } = config;
  const outTab = tab ?? defaultTab;

  const { corpPromotionTypes } = useSelector(nsiDataSelector);

  const lifecycle = CorpActivationLifeCycleFactory().create({
    isPartnerUser,
    userId: userSpecific.id,
  });

  // получаем максимально возможный состав колонок
  const sourceColumns = useMemo(
    () => getCorpActivationTableVisibleColumns(partnerId, customerId, outTab),
    [partnerId, customerId, outTab]
  );

  // готовим адаптер для хука
  const filterAdapter = useMemo<DataFilterAdapter<ECorpActivationTableColumn, ECorpActivationsFilterItem>>(
    () => ({
      ...getTableAdapterKey({
        tab: outTab,
        isPartner: isPartnerUser,
        partnerId,
      }),
      sourceColumns,
      filterItems: corpActivationsFilterItems,
      requiredFilters: [ECorpActivationsFilterItem.Query],
      getDataFilterStrategy: (filterItem, values) =>
        getCorpActivationFilterStrategy(filterItem, values, corpPromotionTypes, partnerId),
    }),
    [sourceColumns, partnerId, isPartnerUser, outTab, corpPromotionTypes, partnerId]
  );

  return (
    <CorpActivationTableConfigContext.Provider value={config}>
      <CorpActivationTableContainer
        guid={guid}
        tab={outTab}
        filterAdapter={filterAdapter}
        lifecycle={lifecycle}
        partnerId={partnerId}
        customerId={customerId}
        access={corpActivations}
      />
    </CorpActivationTableConfigContext.Provider>
  );
};

export default CorpActivationTableEntry;
