import _ from 'lodash';
import SportsmanTableCell, { SportsmanTableCellCommonProps } from './index';

const SportsmanTableCellActivityTypes = (props: SportsmanTableCellCommonProps) => {
  const {
    sportsman: { activityTypes },
  } = props;

  const parentNames = activityTypes?.map(item => item.parent ?? item)?.map(item => item.name);
  const value = _.uniq(parentNames)?.join(', ') || '-';

  return (
    <SportsmanTableCell
      {...props}
      value={value}
    />
  );
};

export default SportsmanTableCellActivityTypes;
