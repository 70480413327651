import { AutocompleteInputChangeReason } from '@mui/material/useAutocomplete/useAutocomplete';
import debounce from 'lodash/debounce';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ProductOffer } from '../../../../../domain/model/productOffer';
import { Nullable } from '../../../../../domain/model/types';
import { MPAutocompleteSingleSelect } from '../../../../theme/ui-kit/autocomplete/single';
import { concatProductName } from '../../utils/helpers';

type ProductVariant = ProductOffer & { name: string };

type AutocompleteProductVariantProps = {
  readonly value: Nullable<ProductOffer>;
  readonly onChange: (value: Nullable<ProductOffer>) => void;
  readonly loadOptionsMethod: (search?: string) => Promise<ProductOffer[]>;
};

export const AutocompleteProductVariant: FC<AutocompleteProductVariantProps> = props => {
  const { value, onChange, loadOptionsMethod } = props;
  const [options, setOptions] = useState<ProductVariant[]>([]);
  const [search, setSearch] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    loadOptionsMethod(search)
      .then(response => {
        setOptions(response as ProductVariant[]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [search]);

  const handlerSearch = useCallback(
    debounce((value: string, reason?: AutocompleteInputChangeReason) => {
      if (reason === 'input') setSearch(value);
    }, 500),
    []
  );

  const val = useMemo<ProductVariant | null>(() => {
    if (value) {
      return {
        ...value,
        name: value?.name || '',
      };
    }
    return null;
  }, [value]);

  return (
    <MPAutocompleteSingleSelect<ProductVariant>
      value={val}
      isLoading={isLoading}
      label='Вариант товара'
      options={options}
      getOptionLabel={option => concatProductName(option?.name, option?.variantName)}
      onSearchValue={handlerSearch}
      onChangeValue={onChange}
    />
  );
};
