import { EBannerPartition, EOrderPartition } from 'domain/model/enums';
import React, { Suspense } from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import rootRouting from '../../../../../routing';
import { ContentLoaderSimple } from '../../../../components/common/loader';
import ProtectedRoute from '../../../../components/common/protectedRoute';
import { EAppFeature } from '../../../../types';
import ClientOrgEntry from '../../../clientOrg/entry';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import MainMenuContainer from '../../menu/container';
import { ContentWrapper } from './controls';

const PlaygroundEntry = React.lazy(() => import('../../../playground/entry'));
const EventsEntry = React.lazy(() => import('../../../event/entry'));
const TeamsEntry = React.lazy(() => import('../../../team/entry'));
const SportsmansEntry = React.lazy(() => import('../../../sportsman/entry'));
const ChatsEntry = React.lazy(() => import('../../../chat/entry'));
const TradeOffersEntry = React.lazy(() => import('../../../tradeOffer/entry'));
const CorpOffersEntry = React.lazy(() => import('../../../corpOffer/entry'));
const TradeActivationsEntry = React.lazy(() => import('../../../tradeActivation/entry'));
const CorpActivationsEntry = React.lazy(() => import('../../../corpActivation/entry'));
const BookingOffersEntry = React.lazy(() => import('../../../bookingOffer/entry'));
const ComplaintsEntry = React.lazy(() => import('../../../complaint/entry'));
const CustomersEntry = React.lazy(() => import('../../../customer/entry'));
const OrdersEntry = React.lazy(() => import('../../../order/entry'));
const PartnersEntry = React.lazy(() => import('../../../partner/entry'));
const ProductsEntry = React.lazy(() => import('../../../products/entry'));
const PartnerApplicationsEntry = React.lazy(() => import('../../../partnerApplication/entry'));
const PartnerEmployeeEntry = React.lazy(() => import('../../../partnerEmployee/entry'));
const UsersEntry = React.lazy(() => import('../../../user/entry'));
const SupportEntry = React.lazy(() => import('../../../support/entry'));
const AuthEntry = React.lazy(() => import('../../../auth/entry'));
const ReportsEntry = React.lazy(() => import('../../../report/entry'));
const QueryAnalyticsEntry = React.lazy(() => import('../../../analytics/entry'));
const BannersEntry = React.lazy(() => import('../../../banner/entry'));
const AdsEntry = React.lazy(() => import('../../../ads/entry'));
const ConfigurationEntry = React.lazy(() => import('../../../configuration/entry'));
const UiKitEntry = React.lazy(() => import('../../../general/uikit/entry'));
const PartnerDesksEntry = React.lazy(() => import('../../../partnerDesk/entry'));
const PartnerWindowEntry = React.lazy(() => import('../../../partnerWindow/entry'));
const LandingWindowEntry = React.lazy(() => import('../../../landingWindow/entry'));
const SocialPackagesEntry = React.lazy(() => import('../../../socialPackage/entry'));

const MainEntryCommon = () => {
  const { defaultRoute, accessMatrix } = useCurrentUser();
  const {
    playgrounds,
    events,
    teams,
    productOffers,
    complaints,
    chats,
    tradeOffers,
    corpOffers,
    bookingOffers,
    tradeActivations,
    corpActivations,
    customers,
    partners,
    partnerDesks,
    partnerEmployees,
    partnerWindow,
    landingWindow,
    orders,
    reports,
    banners,
    configuration,
    analyticsQueries,
    adCampaigns,
    clientOrgs,
    socialPackages,
  } = accessMatrix;

  return (
    <>
      <MainMenuContainer />
      <ContentWrapper>
        <Suspense fallback={<ContentLoaderSimple />}>
          <Switch>
            {/* TODO добавить все ограничения видимости по accessMatrix*/}
            <ProtectedRoute
              path={rootRouting.playgrounds}
              component={PlaygroundEntry}
              allowed={playgrounds?.view}
            />
            <ProtectedRoute
              path={rootRouting.events}
              component={EventsEntry}
              allowed={events?.view}
            />
            <ProtectedRoute
              path={rootRouting.teams}
              component={TeamsEntry}
              allowed={teams?.view}
            />
            <ProtectedRoute
              path={rootRouting.sportsmen}
              component={SportsmansEntry}
              allowed={events?.view}
            />
            <ProtectedRoute
              path={rootRouting.chats}
              component={ChatsEntry}
              allowed={chats?.view}
            />
            <ProtectedRoute
              path={rootRouting.complaints}
              component={ComplaintsEntry}
              allowed={complaints?.view}
            />
            <ProtectedRoute
              path={rootRouting.tradeOffers}
              component={TradeOffersEntry}
              allowed={tradeOffers?.view}
            />
            <ProtectedRoute
              path={rootRouting.corpOffers}
              component={CorpOffersEntry}
              allowed={corpOffers?.view}
            />
            <ProtectedRoute
              path={rootRouting.bookingOffers}
              component={BookingOffersEntry}
              allowed={bookingOffers?.view}
              feature={{
                name: EAppFeature.Booking,
                defaultRoute: '/',
              }}
            />
            <ProtectedRoute
              path={rootRouting.tradeActivations}
              component={TradeActivationsEntry}
              allowed={tradeActivations?.view}
            />
            <ProtectedRoute
              path={rootRouting.corpActivations}
              component={CorpActivationsEntry}
              allowed={corpActivations?.view}
            />
            <ProtectedRoute
              path={rootRouting.customers}
              component={CustomersEntry}
              allowed={customers?.view?.list || customers?.view?.details}
            />
            <ProtectedRoute
              path={rootRouting.partners}
              component={PartnersEntry}
              allowed={partners?.view}
            />
            <ProtectedRoute
              path={rootRouting.products}
              component={ProductsEntry}
              allowed={productOffers?.view}
              feature={{
                name: EAppFeature.ProductOffer,
                defaultRoute: '/',
              }}
            />
            <ProtectedRoute
              path={rootRouting.employees}
              component={PartnerEmployeeEntry}
              allowed={partnerEmployees?.view}
            />
            <ProtectedRoute
              path={rootRouting.orders}
              component={OrdersEntry}
              allowed={orders?.[EOrderPartition.ProductOffers]?.view || orders?.[EOrderPartition.BookingOffers]?.view}
            />
            <ProtectedRoute
              path={rootRouting.reports}
              component={ReportsEntry}
              allowed={reports?.view}
            />
            <ProtectedRoute
              path={rootRouting.analytics}
              component={QueryAnalyticsEntry}
              allowed={analyticsQueries?.view}
              feature={{
                name: EAppFeature.QueryAnalytics,
                defaultRoute: '/',
              }}
            />
            <ProtectedRoute
              path={rootRouting.ads}
              component={AdsEntry}
              allowed={adCampaigns?.view}
              feature={{
                name: EAppFeature.AdCampaign,
                defaultRoute: '/',
              }}
            />
            <ProtectedRoute
              path={rootRouting.clientOrgs}
              component={ClientOrgEntry}
              allowed={clientOrgs?.view}
              feature={{
                name: EAppFeature.Saas,
                defaultRoute: '/',
              }}
            />
            <ProtectedRoute
              path={rootRouting.socialPackage}
              component={SocialPackagesEntry}
              allowed={socialPackages?.view}
              feature={{
                name: EAppFeature.Csp,
                defaultRoute: '/',
              }}
            />
            <ProtectedRoute
              path={rootRouting.banners}
              component={BannersEntry}
              allowed={banners?.[EBannerPartition.TradeOffers]?.view || banners?.[EBannerPartition.CorpOffers]?.view}
            />
            <ProtectedRoute
              path={rootRouting.configuration}
              component={ConfigurationEntry}
              allowed={configuration?.view}
            />
            <Route
              path={rootRouting.users}
              component={UsersEntry}
            />
            <Route
              path={rootRouting.support}
              component={SupportEntry}
            />
            <Route
              path={rootRouting.auth}
              component={AuthEntry}
            />
            <Route
              path={rootRouting.uikit}
              component={UiKitEntry}
            />
            <Route
              path={rootRouting.partnerApplication}
              component={PartnerApplicationsEntry}
            />
            <ProtectedRoute
              path={rootRouting.partnerDesks}
              component={PartnerDesksEntry}
              allowed={partnerDesks?.view}
            />
            <ProtectedRoute
              path={rootRouting.partnerWindow}
              component={PartnerWindowEntry}
              allowed={partnerWindow?.view}
              feature={{
                name: EAppFeature.CmsPartner,
                defaultRoute: '/',
              }}
            />
            <ProtectedRoute
              path={rootRouting.landingWindow}
              component={LandingWindowEntry}
              allowed={landingWindow?.view}
              feature={{
                name: EAppFeature.CmsLanding,
                defaultRoute: '/',
              }}
            />
            <Redirect to={defaultRoute} />
          </Switch>
        </Suspense>
      </ContentWrapper>
    </>
  );
};

export default MainEntryCommon;
