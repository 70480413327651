import { Grid, Link, Typography } from '@mui/material';
import { SportOptionTyped } from '../../../../../../domain/model';
import { EPartnerOwnershipType } from '../../../../../../domain/model/enums';

interface PartnerDetailsInfoCompanyCommonProps {
  readonly inn: string;
  readonly publicName: string;
  readonly legalName: string;
  readonly ownerFullName: string;
  readonly website: string;
  readonly phone: string;
  readonly email: string;
  readonly ownerShipType: EPartnerOwnershipType;
  readonly ownerShipTypes: SportOptionTyped<EPartnerOwnershipType>[];
}

const PartnerDetailsInfoCompanyCommon = (props: PartnerDetailsInfoCompanyCommonProps) => {
  const { inn, publicName, legalName, ownerFullName, website, phone, email, ownerShipType, ownerShipTypes } = props;

  return (
    <Grid
      container
      spacing={3}
      direction='column'
    >
      <Grid
        item
      >
        <Typography
          variant='body2'
          color='textSecondary'
        >
          Организационно-правовая форма
        </Typography>
        <Typography>{ownerShipTypes.find(type => type.id === ownerShipType)?.name ?? '-'}</Typography>
      </Grid>
      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          ИНН
        </Typography>
        <Typography>{inn ?? '-'}</Typography>
      </Grid>
      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          Юридическое название компании
        </Typography>
        <Typography>{legalName ?? '-'}</Typography>
      </Grid>
      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          Название компании, которое увидят покупатели
        </Typography>
        <Typography>{publicName ?? '-'}</Typography>
      </Grid>
      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          ФИО генерального директора
        </Typography>
        <Typography>{ownerFullName ?? '-'}</Typography>
      </Grid>
      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          Электронная почта
        </Typography>
        {phone ? (
          <Typography
            component={Link}
            href={`mailto:${phone}`}
            color='textPrimary'
          >
            {email}
          </Typography>
        ) : (
          <Typography>-</Typography>
        )}
      </Grid>
      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          Сайт компании
        </Typography>
        {website ? (
          <Typography
            component={Link}
            href={website}
            color='textPrimary'
            target='_blank'
          >
            {website}
          </Typography>
        ) : (
          <Typography>-</Typography>
        )}
      </Grid>
      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          Контактный телефон
        </Typography>
        {phone ? (
          <Typography
            component={Link}
            href={`tel:${phone}`}
            color='textPrimary'
          >
            {phone}
          </Typography>
        ) : (
          <Typography>-</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default PartnerDetailsInfoCompanyCommon;
