import * as schemas from './scheme';
import { BookingOrderLifeCycleRulesConfiguration } from './types';

/**
 * конфигурация правил админа Объектов ДиО
 */
export const bookingOrderAdminConfiguration: BookingOrderLifeCycleRulesConfiguration = {
  statusScheme: schemas.bookingOrderAdminStatusScheme.statusScheme,
  actionsScheme: schemas.bookingOrderAdminStatusScheme.actionsScheme,
  actionRules: [],
};
/**
 * конфигурация правил партнера Объектов ДиО
 */
export const bookingOrderPartnerConfiguration: BookingOrderLifeCycleRulesConfiguration = {
  statusScheme: schemas.bookingOrderPartnerStatusScheme.statusScheme,
  actionsScheme: schemas.bookingOrderPartnerStatusScheme.actionsScheme,
  actionRules: [],
};
