import { useReportDownloads } from '@features/report/downloads/useDownloads';
import { EReportType } from '@features/report/types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { reportDownloadsReportsSelector } from '../store/selectors';
import { PartnersReportContainer } from '@features/report/downloads/reports/partners';

export const ReportDownloadsPartnersAdapter = () => {
  const handlers = useReportDownloads();

  const { partners: open } = useSelector(reportDownloadsReportsSelector);

  const onClose = useCallback(() => {
    handlers.onCloseDownloadDialog(EReportType.Partners);
  }, [handlers.onCloseDownloadDialog]);

  return open && <PartnersReportContainer onClose={onClose} />;
};
