import { Grid } from '@mui/material';
import { BookingOrder, UserAccessPartitionBookingOrders, UUID } from '@/domain';
import MasterContentGridLayout from '../../../layouts/masterContentGrid/container';
import { MasterDetailsLayout } from '@layouts/masterDetails/container';
import { CommonPreloaderDecorator } from '../../general/decorators/preloader';
import { CommonUpdaterDecorator } from '../../general/decorators/updater';
import MasterDetailsContainer from '../../general/master/details/container';
import { useBookingOrderActionsSelectors } from '../actions/useAction';
import { BookingOrderLifeCycle } from '../lifecycle/types';
import { BookingOrderEditDetailsInfoAdapter } from './adapters/detailsInfo';
import { BookingOrderEditDialogsAdapter } from './adapters/dialogs';
import { BookingOrderEditFooterAdapter } from './adapters/footer';
import { BookingOrderEditHeaderAdapter } from './adapters/header';
import { BookingOrderEditItemsTableAdapter } from './adapters/itemsTable';
import { BookingOrderEditStatusSectionAdapter } from './adapters/statusSection';
import { BookingOrderEditStepperAdapter } from './adapters/stepper';
import { BookingOrderEditInitializer } from './initializer';
import { BookingOrderEditHandlersProvider, BookingOrderEditLifecycleProvider } from './providers';
import { bookingOrderEditFetchingSelector } from './store/selectors';

export type BookingOrderEditContainerProps = {
  readonly id: UUID;
  readonly guid: UUID;
  readonly source: BookingOrder;
  readonly access: UserAccessPartitionBookingOrders;
  readonly lifecycle: BookingOrderLifeCycle;
};

const BookingOrderEditContainer = (props: BookingOrderEditContainerProps) => {
  const { id } = props;
  const { getIsBookingOrderProcessingSelector } = useBookingOrderActionsSelectors();

  return (
    <MasterDetailsContainer
      layout={MasterDetailsLayout}
      commonProps={props}
      layoutProps={{
        stepper: BookingOrderEditStepperAdapter,
        header: BookingOrderEditHeaderAdapter,
        footer: BookingOrderEditFooterAdapter,
        decorators: [
          <CommonPreloaderDecorator
            key='preloader'
            isFetchingSelector={bookingOrderEditFetchingSelector}
          />,
          <CommonUpdaterDecorator
            key='actions'
            isFetchingSelector={getIsBookingOrderProcessingSelector(id)}
          />,
        ],
      }}
      initializer={BookingOrderEditInitializer}
      providers={[
        {
          name: 'handlers',
          factory: BookingOrderEditHandlersProvider,
        },
        {
          name: 'lifecycle',
          factory: BookingOrderEditLifecycleProvider,
        },
      ]}
    >
      <MasterContentGridLayout>
        <Grid
          container
          spacing={4}
        >
          <BookingOrderEditDetailsInfoAdapter />
          <BookingOrderEditItemsTableAdapter />
          <BookingOrderEditStatusSectionAdapter />
        </Grid>
      </MasterContentGridLayout>

      <BookingOrderEditDialogsAdapter />
    </MasterDetailsContainer>
  );
};

export default BookingOrderEditContainer;
