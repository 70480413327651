import { SportOptionTyped } from '../../../../../../../domain/model';
import { EOfferStatus } from '../../../../../../../domain/model/enums';
import OfferHistoryTableCell, { OfferHistoryTableCellCommonProps } from './index';

export type OfferHistoryTableCellStatusProps = OfferHistoryTableCellCommonProps & {
  readonly offerStatuses: SportOptionTyped<EOfferStatus>[];
};

const OfferHistoryTableCellStatus = (props: OfferHistoryTableCellStatusProps) => {
  const {
    history: { status },
    offerStatuses,
  } = props;

  const value = status && offerStatuses.find(s => s.id === status)?.name;

  return (
    <OfferHistoryTableCell
      {...props}
      value={value}
    />
  );
};

export default OfferHistoryTableCellStatus;
