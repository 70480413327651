import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../../data/api';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { Fetchable } from '../../../../../../data/store/types';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import { MpUser } from '../../../../../../domain/model/user';

export const customerManagementCommonCustomerByIdFetch = createAsyncThunk<MpUser, UUID, AppThunkAPIConfig>(
  'customer/management/common/byId/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      const { data: customer } = await Api.user.mp.one({ id, signal });
      return customer;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export interface CustomerManagementCommonState {
  readonly menu: {
    readonly visible: boolean;
  };
  readonly data: Fetchable & {
    readonly customer: Nullable<MpUser>;
  };
}

type Reducer<T = undefined> = CaseReducer<CustomerManagementCommonState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CustomerManagementCommonState> {
  viewShowCustomerManagementCommonMenu: Reducer;
  viewHideCustomerManagementCommonMenu: Reducer;
}

const slice = createSlice<CustomerManagementCommonState, Reducers, 'customer/management/common'>({
  name: 'customer/management/common',
  initialState: {
    menu: {
      visible: true,
    },
    data: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      customer: null,
    },
  },
  reducers: {
    viewShowCustomerManagementCommonMenu(state) {
      state.menu.visible = true;
    },
    viewHideCustomerManagementCommonMenu(state) {
      state.menu.visible = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(customerManagementCommonCustomerByIdFetch.pending, state => {
        state.data.isFetching = true;
        state.data.isFetched = false;
        state.data.isFailed = false;
        state.data.customer = null;
      })
      .addCase(customerManagementCommonCustomerByIdFetch.fulfilled, (state, { payload }) => {
        state.data.isFetching = false;
        state.data.isFetched = true;
        state.data.isFailed = false;

        state.data.customer = payload;
      })
      .addCase(customerManagementCommonCustomerByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.data.isFetching = false;
          state.data.isFetched = false;
          state.data.isFailed = true;
        } else {
          state.data.isFetching = false;
          state.data.isFetched = false;
          state.data.isFailed = false;
        }

        state.data.customer = null;
      });
  },
});

export const { viewShowCustomerManagementCommonMenu, viewHideCustomerManagementCommonMenu } = slice.actions;

export default slice.reducer;
