import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../components/common/header';
import { getBookingOrderStatusText } from '../../utils/common';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingOrderDetailsBookingOrderSelector } from '../store/selectors';
import { OfferMasterTitle } from '@features/general/offer/components/title';

const BookingOrderDetailsHeaderAdapter = () => {
  const bookingOrder = useSelector(bookingOrderDetailsBookingOrderSelector);
  const handlers = useContextHandlers();
  const status = bookingOrder ? getBookingOrderStatusText(bookingOrder.status) : null;

  return bookingOrder ? (
    <DefaultHeader
      sticky
      headline={
        <AppBreadcrumbs>
          <Typography color='textSecondary'>Заказы досуга и отдыха / {status}</Typography>
        </AppBreadcrumbs>
      }
      onClose={handlers.onClose}
    >
      <OfferMasterTitle>Заказ № {bookingOrder.number}</OfferMasterTitle>
    </DefaultHeader>
  ) : null;
};

export default BookingOrderDetailsHeaderAdapter;
