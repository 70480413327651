import moment from 'moment';
import { EDateFormat } from '../../../../../domain/model/formats';
import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from './index';

const empty = 'не указано';

const CorpOfferTableCellPeriod = (props: CorpOfferTableCellCommonProps) => {
  const {
    corpOffer: { startDate, endDate },
  } = props;

  const startDateValue = startDate && moment(startDate).format(EDateFormat.DisplayDefault);
  const endDateValue = endDate && moment(endDate).format(EDateFormat.DisplayDefault);
  const value = `${startDateValue ?? empty} - ${endDateValue ?? empty}`;

  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellPeriod;
