import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EOfferStatus } from '../../../../../domain/model/enums';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { EOfferTableTab } from '../../../general/offer/types';
import { getStatesFilterForOfferTableTab } from '../../../general/offer/utils/table';
import {
  tradeOffersSearchFilterSelector,
  tradeOffersSearchGuidSelector,
  tradeOffersSearchNeedRefreshWatcherSelector,
  tradeOffersSearchPageNumberSelector,
  tradeOffersSearchSearchSelector,
} from '../store/selectors';
import {
  tradeOffersSearchCountsFetch,
  tradeOffersSearchFetch,
  TradeOffersSearchFetchProps,
  tradeOffersSearchNeedRefreshWatcherReset,
} from '../store/slice';

interface UseTradeOffersSearchSearchProps {
  readonly guid: UUID;
  readonly tab: EOfferTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly statuses: EOfferStatus[];
  readonly tabsForCounts: EOfferTableTab[];
}

const getStatusesByTab = (t: EOfferTableTab, statuses: EOfferStatus[]) => getStatesFilterForOfferTableTab(t, statuses);

const useTradeOffersSearchSearch = (props: UseTradeOffersSearchSearchProps): void => {
  const { guid, tab, partnerId, statuses, tabsForCounts } = props;

  const dispatch = useDispatch();

  const [searchPromises, setSearchPromises] = useState<Nullable<any[]>>(null);

  const needRefreshWatcher = useSelector(tradeOffersSearchNeedRefreshWatcherSelector);
  const pageNumber = useSelector(tradeOffersSearchPageNumberSelector);
  const currentGuid = useSelector(tradeOffersSearchGuidSelector);
  const search = useSelector(tradeOffersSearchSearchSelector);
  const filter = useSelector(tradeOffersSearchFilterSelector);

  const currentStatuses = getStatusesByTab(tab, statuses);

  const queryParams = useMemo<TradeOffersSearchFetchProps>(() => {
    return {
      filter,
      search: {
        ...search,
        statuses: currentStatuses,
        partnerId,
        categories: null,
      },
      pageNumber,
    };
  }, [filter, search, currentStatuses, partnerId, pageNumber]);

  const onSearch = useCallback(() => {
    setSearchPromises([
      dispatch(tradeOffersSearchFetch(queryParams)),
      dispatch(tradeOffersSearchCountsFetch({ ...queryParams, tabs: tabsForCounts })),
    ]);
  }, [dispatch, queryParams]);

  // начальная загрузка
  useEffect(() => {
    if (guid === currentGuid) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, guid, currentGuid]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  // сброс вотчера после ухода
  useEffect(() => {
    return () => {
      dispatch(tradeOffersSearchNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};

export default useTradeOffersSearchSearch;
