import { ProductOffer } from '../../../../../../../domain/model/productOffer';
import { ProductOrderItemTableCellCommonProps } from '../types';
import DataTableCellTextFormat from '../../../../../../components/common/table/cell/textFormat';
import AppSvgIcon from '../../../../../../components/common/images/icon';
import { EditIcon } from '../../../../../../icons';
import { IconButton } from '@mui/material';
import { ProductOrderItemTableCellAttributesWrapper } from './controls';
import { MouseEventHandler } from 'react';

export interface ProductOrderItemTableCellAttributesProps extends ProductOrderItemTableCellCommonProps {
  readonly value: ProductOffer['variantName'];
  readonly onOpenDialog: MouseEventHandler<HTMLButtonElement>;
}

const ProductOrderItemTableCellAttributes = (props: ProductOrderItemTableCellAttributesProps) => {
  const { value, onOpenDialog } = props;

  if (!value) {
    return <>-</>;
  }

  return (
    <ProductOrderItemTableCellAttributesWrapper>
      <DataTableCellTextFormat>{value}</DataTableCellTextFormat>

      <IconButton
        size='small'
        onClick={onOpenDialog}
      >
        <AppSvgIcon
          color='primary'
          fontSize='small'
          icon={EditIcon}
        />
      </IconButton>
    </ProductOrderItemTableCellAttributesWrapper>
  );
};

export default ProductOrderItemTableCellAttributes;
