import { combineReducers } from '@reduxjs/toolkit';
import details, { PartnerApplicationDetailsState } from './details/store/slice';
import edit, { PartnerApplicationEditState } from './edit/store/slice';
import verification, { PartnerApplicationVerificationState } from './verification/store/slice';

interface PartnerApplicationState {
  edit: PartnerApplicationEditState;
  details: PartnerApplicationDetailsState;
  verification: PartnerApplicationVerificationState;
}

export default combineReducers<PartnerApplicationState>({
  edit,
  details,
  verification,
});
