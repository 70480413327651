import { Grid, Typography } from '@mui/material';
import { SportOptionTyped } from '../../../../../../domain/model';
import { EOfferType } from '../../../../../../domain/model/enums';
import { Nullable } from '../../../../../../domain/model/types';
import SectionTitle from '../../../../../components/common/sectionTitle';
import { CmsContainerDetailsComponentProps } from '../types';
import CmsContainerViewComponentOfferIconVisible from './offerIconVisible';

type CmsContainerViewComponentOfferTypeProps = CmsContainerDetailsComponentProps;

const CmsContainerViewComponentOfferType = (props: CmsContainerViewComponentOfferTypeProps) => {
  const { cmsContainer, configuration, dictionaries } = props;

  const offerType: Nullable<SportOptionTyped<EOfferType>> =
    dictionaries.offerTypes.find(type => type.id === cmsContainer.offerType) ?? null;

  return (
    <Grid
      container
      spacing={1}
      direction='column'
    >
      <Grid
        item
        container
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item>
          <SectionTitle gutterBottom={false}>Вид предложения</SectionTitle>
        </Grid>
        {configuration.canChangeOfferIconVisible && (
          <Grid item>
            <CmsContainerViewComponentOfferIconVisible {...props} />
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Typography color='textSecondary'>Вид предложения</Typography>
        <Typography>{offerType?.name ?? '-'}</Typography>
      </Grid>
    </Grid>
  );
};

export default CmsContainerViewComponentOfferType;
