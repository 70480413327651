import { BadgeProps } from '@mui/material/Badge/Badge';
import { FCC } from 'react';
import { Nullable } from '../../../../../domain/model/types';
import { WarnFilledIcon } from '../../../../icons';
import { ValidationResult } from '../../../../utils/validation';
import AppSvgIcon from '../../images/icon';
import ValidationBadgeAdapter from '../../validation/badge';

interface StepperValidationBadgeAdapterProps extends Omit<BadgeProps, 'invisible' | 'color' | 'badgeContent'> {
  readonly validation?: Nullable<ValidationResult<object>>;
}

const StepperValidationBadgeAdapter: FCC<StepperValidationBadgeAdapterProps> = ({ validation, ...others }) => {
  const hasError = validation && Object.values(validation).filter(item => !!item).length;

  return (
    <ValidationBadgeAdapter
      hasError={!!hasError}
      variant={'standard'}
      color={'default'}
      badgeContent={
        <AppSvgIcon
          viewBox='0 0 17 17'
          color='error'
          fontSize='small'
          icon={WarnFilledIcon}
        />
      }
      {...others}
    />
  );
};

export default StepperValidationBadgeAdapter;
