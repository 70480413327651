import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../data/store/store';
import { UUID } from '../../../../../domain/model/types';
import { MpUser } from '../../../../../domain/model/user';
import ContentLoader from '../../../../components/common/loader';
import { EUserNameRule, getUserFullName } from '../../../../utils';
import useBreadcrumbsActions from '../../../general/breadcrumbs/useBreadcrumbsActions';
import CustomerManagementMenuContainer from '../menu/container';
import { ContentWrapper, Wrapper } from './controls';
import { customerManagementCommonGetData, customerManagementCommonIsMenuVisible } from './store/selectors';
import { customerManagementCommonCustomerByIdFetch } from './store/slice';

interface CustomerManagementCommonContainerProps {
  readonly id: UUID;
  readonly children: (customer: MpUser) => React.ReactNode;
}

const CustomerManagementCommonContainer = ({ id, children }: CustomerManagementCommonContainerProps) => {
  const dispatch = useAppDispatch();
  const { putHeadBreadcrumbs } = useBreadcrumbsActions();

  const isMenuVisible = useSelector(customerManagementCommonIsMenuVisible);
  const { customer, isFetching } = useSelector(customerManagementCommonGetData);

  useEffect(() => {
    const promise = dispatch(customerManagementCommonCustomerByIdFetch(id));
    return () => {
      promise?.abort();
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (customer) {
      putHeadBreadcrumbs([
        {
          label: 'Покупатели',
        },
        {
          label: getUserFullName({ user: customer, rule: EUserNameRule.Reverse }),
        },
      ]);
      return () => {
        putHeadBreadcrumbs([]);
      };
    }
  }, [customer]);

  return (
    <Wrapper>
      {customer && (
        <>
          <CustomerManagementMenuContainer
            visible={isMenuVisible}
            customer={customer}
          />
          <ContentWrapper fullscreen={false}>{children(customer)}</ContentWrapper>
        </>
      )}
      {isFetching && <ContentLoader size={75} />}
    </Wrapper>
  );
};

export default CustomerManagementCommonContainer;
