import TradeActivationTableCell, { TradeActivationTableCellCommonProps } from './index';

const TradeActivationTableCellCustomerEmail = (props: TradeActivationTableCellCommonProps) => {
  const {
    tradeActivation: {
      customer: { email },
    },
  } = props;

  return (
    <TradeActivationTableCell
      {...props}
      value={email ?? ''}
    />
  );
};

export default TradeActivationTableCellCustomerEmail;
