import TradeOfferTableCell, { TradeOfferTableCellCommonProps } from './index';

const TradeOfferTableCellPartner = (props: TradeOfferTableCellCommonProps) => {
  const {
    tradeOffer: {
      partner: { name: value = '-' },
    },
  } = props;

  return (
    <TradeOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default TradeOfferTableCellPartner;
