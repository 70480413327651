import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { pageSizeAll } from '../../../../../domain/model/constants';
import { ENoticeStatus, ETeamMemberInviteStatus, ETeamMemberStatus } from '../../../../../domain/model/enums';
import { Team, TeamMember, TeamMemberInvite } from '../../../../../domain/model/team';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { SportUserProfile } from '../../../../../domain/model/user';
import Notifier from '../../../../../system/notifier';
import teamServices from '../../services';

export const teamByIdFetch = createAsyncThunk<Team, { id: UUID }, AppThunkAPIConfig>(
  'team/details/byId/fetch',
  async ({ id }, { rejectWithValue, signal }) => {
    try {
      return await teamServices.common.one({ id, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const teamMembersFetch = createAsyncThunk<
  { data: TeamMember[] },
  { id: UUID; status: ETeamMemberStatus },
  AppThunkAPIConfig
>('team/details/members/fetch', async ({ id, status }, { rejectWithValue, signal }) => {
  try {
    const { data } = await Api.team.members({ id, status, signal });
    return { data };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const teamInvitesFetch = createAsyncThunk<{ data: TeamMemberInvite[] }, { id: UUID }, AppThunkAPIConfig>(
  'team/details/invites/fetch',
  async ({ id }, { rejectWithValue, signal }) => {
    try {
      const { data: response } = await Api.team.invites({
        page: 1,
        pageSize: pageSizeAll,
        teamId: id,
        signal,
      });
      const { content: data } = response;

      return { data };
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const revokeInvite = createAsyncThunk<void, { id: UUID }, AppThunkAPIConfig>(
  'team/details/invites/revokeInvite',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await Api.team.revokeInvite({ id });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const rejectApplication = createAsyncThunk<void, { id: UUID }, AppThunkAPIConfig>(
  'team/details/invites/rejectApplication',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await Api.team.rejectApplication({ id });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const approveApplication = createAsyncThunk<void, { id: UUID }, AppThunkAPIConfig>(
  'team/details/invites/approveApplication',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await Api.team.approveApplication({ id });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const inviteMembers = createAsyncThunk<
  void,
  { id: UUID; preInvited: SportUserProfile[]; members: TeamMember[]; invites: TeamMemberInvite[] },
  AppThunkAPIConfig
>('team/details/invites/inviteMember', async ({ id, preInvited, members, invites }, { rejectWithValue }) => {
  try {
    const invited = preInvited
      .filter(obj => !members.some(item => obj.id === item?.userProfile.id))
      .filter(
        obj =>
          !invites.some(
            item => obj.id === item?.userProfile.id && ![ETeamMemberInviteStatus.InviteRevoked].includes(item.status)
          )
      );

    const queue = invited.map(item => {
      return Api.team.inviteMember({ teamId: id, userId: item.id });
    });

    await Promise.allSettled(queue).then(results => {
      results
        .filter(({ status }) => status === 'rejected')
        .forEach(({ reason }: any) => {
          const { response } = reason;
          const errorUrl = response.config.url;
          const errorMessage = response.data.message;

          Notifier.getInstance().addNotice(ENoticeStatus.Error, `${errorUrl}\n${errorMessage}`);
        });
    });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface TeamDetailsState {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly byId: Fetchable & {
    readonly data: Nullable<Team>;
  };
  readonly members: Fetchable & {
    readonly data: TeamMember[];
  };
  readonly invites: Fetchable & {
    readonly data: TeamMemberInvite[];
    readonly preInvited: SportUserProfile[];
  };
  readonly revokeInvite: Fetchable & {};
  readonly rejectApplication: Fetchable & {};
  readonly approveApplication: Fetchable & {};
}

type Reducer<T = undefined> = CaseReducer<TeamDetailsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<TeamDetailsState> {
  teamStartSession: Reducer<Nullable<UUID>>;
  teamSetInvites: Reducer<Nullable<SportUserProfile[]>>;
}

const slice = createSlice<TeamDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    guid: null,
    needRefreshWatcher: 0,
    byId: {
      ...fetchableDefault,
      data: null,
    },
    members: {
      ...fetchableDefault,
      data: [],
    },
    invites: {
      ...fetchableDefault,
      data: [],
      preInvited: [],
    },
    revokeInvite: {
      ...fetchableDefault,
    },
    rejectApplication: {
      ...fetchableDefault,
    },
    approveApplication: {
      ...fetchableDefault,
    },
  },
  reducers: {
    teamStartSession: (state, { payload }) => {
      if (state.guid !== payload) {
        state.needRefreshWatcher = 0;
      }
      state.guid = payload;
    },
    teamSetInvites: (state, { payload }) => {
      // @ts-ignore
      state.invites.preInvited = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(teamByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;

        state.byId.data = null;
      })
      .addCase(teamByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.data = payload;
      })
      .addCase(teamByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        } else {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = false;
        }

        state.byId.data = null;
      })

      .addCase(teamMembersFetch.pending, state => {
        state.members.isFetching = true;
        state.members.isFetched = false;
        state.members.isFailed = false;

        state.members.data = [];
      })
      .addCase(teamMembersFetch.fulfilled, (state, { payload }) => {
        const { data } = payload;

        state.members.isFetching = false;
        state.members.isFetched = true;
        state.members.isFailed = false;

        state.members.data = data;
      })
      .addCase(teamMembersFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.members.isFetching = false;
          state.members.isFetched = false;
          state.members.isFailed = true;
        } else {
          state.members.isFetching = false;
          state.members.isFetched = false;
          state.members.isFailed = false;
        }

        state.members.data = [];
      })

      .addCase(teamInvitesFetch.pending, state => {
        state.invites.isFetching = true;
        state.invites.isFetched = false;
        state.invites.isFailed = false;

        state.invites.data = [];
      })
      .addCase(teamInvitesFetch.fulfilled, (state, { payload }) => {
        const { data } = payload;

        state.invites.isFetching = false;
        state.invites.isFetched = true;
        state.invites.isFailed = false;

        state.invites.data = data;
      })
      .addCase(teamInvitesFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.invites.isFetching = false;
          state.invites.isFetched = false;
          state.invites.isFailed = true;
        } else {
          state.invites.isFetching = false;
          state.invites.isFetched = false;
          state.invites.isFailed = false;
        }

        state.invites.data = [];
      })

      .addCase(revokeInvite.pending, state => {
        state.revokeInvite.isFetching = true;
        state.revokeInvite.isFetched = false;
        state.revokeInvite.isFailed = false;

        state.needRefreshWatcher++;
      })
      .addCase(revokeInvite.fulfilled, state => {
        state.revokeInvite.isFetching = false;
        state.revokeInvite.isFetched = true;
        state.revokeInvite.isFailed = false;

        state.needRefreshWatcher++;
      })
      .addCase(revokeInvite.rejected, state => {
        state.revokeInvite.isFetching = false;
        state.revokeInvite.isFetched = false;
        state.revokeInvite.isFailed = true;
      })

      .addCase(rejectApplication.pending, state => {
        state.rejectApplication.isFetching = true;
        state.rejectApplication.isFetched = false;
        state.rejectApplication.isFailed = false;
      })
      .addCase(rejectApplication.fulfilled, state => {
        state.rejectApplication.isFetching = false;
        state.rejectApplication.isFetched = true;
        state.rejectApplication.isFailed = false;

        state.needRefreshWatcher++;
      })
      .addCase(rejectApplication.rejected, state => {
        state.rejectApplication.isFetching = false;
        state.rejectApplication.isFetched = false;
        state.rejectApplication.isFailed = true;
      })

      .addCase(approveApplication.pending, state => {
        state.approveApplication.isFetching = true;
        state.approveApplication.isFetched = false;
        state.approveApplication.isFailed = false;
      })
      .addCase(approveApplication.fulfilled, state => {
        state.approveApplication.isFetching = false;
        state.approveApplication.isFetched = true;
        state.approveApplication.isFailed = false;

        state.needRefreshWatcher++;
      })
      .addCase(approveApplication.rejected, state => {
        state.approveApplication.isFetching = false;
        state.approveApplication.isFetched = false;
        state.approveApplication.isFailed = true;
      })

      .addCase(inviteMembers.pending, state => {
        state.invites.isFetching = true;
        state.invites.isFetched = false;
        state.invites.isFailed = false;
      })
      .addCase(inviteMembers.fulfilled, state => {
        state.invites.isFetching = false;
        state.invites.isFetched = true;
        state.invites.isFailed = false;

        state.needRefreshWatcher++;
        state.invites.preInvited = [];
      })
      .addCase(inviteMembers.rejected, state => {
        state.invites.isFetching = false;
        state.invites.isFetched = false;
        state.invites.isFailed = true;

        state.invites.preInvited = [];
      });
  },
});

export const { teamStartSession, teamEventsClear, teamSetInvites } = slice.actions;

export default slice.reducer;
