import { useMemo } from 'react';
import { AnyFileLink } from '../link';
import { FileInfoAdapterType, FileInfoState } from './types';

/**
 * адаптер для работы с файлом (или Html, заранее неизвестно) лежащим по url, без подгрузки информации
 * применяется в рамках FileInfo
 */
export const FileInfoLinkAnyAdapter: FileInfoAdapterType = props => {
  const { id, originalName, children, linkProps } = props;

  const state = useMemo<FileInfoState>(
    () => ({
      isLoading: false,
      name: originalName ?? id ?? '',
      size: null,
      error: null,
    }),
    [originalName, id]
  );

  return (
    <AnyFileLink
      href={id}
      target='_blank'
      underline='none'
      {...linkProps}
    >
      {children(state)}
    </AnyFileLink>
  );
};
