import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../../data/api';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { ServerErrorResponse } from '../../../../../../data/network/types';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../../data/store/types';
import { EPartnerStatus } from '../../../../../../domain/model/enums';
import { NotificationOption } from '../../../../../../domain/model/notification';
import { Partner, PartnerDesk } from '../../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../../domain/model/types';

export const partnerDetailsAdminByIdFetch = createAsyncThunk<
  { partner: Partner; notifications: NotificationOption[]; desk: Nullable<PartnerDesk> },
  { id: UUID },
  AppThunkAPIConfig
>('partner/details/admin/byId/fetch', async ({ id }, { rejectWithValue, signal }) => {
  let partner: Nullable<Partner> = null;
  let desk: Nullable<PartnerDesk> = null;
  let notifications: NotificationOption[] = [];

  try {
    const { data: partnerData } = await Api.partner.one({ id, signal });
    partner = partnerData;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);

    return rejectWithValue(e.response.data);
  }

  try {
    const { data: deskData } = await Api.partner.desk({ partnerId: id, signal });
    desk = deskData;
  } catch (e: any) {
    if (e.response.status !== 404) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }

  try {
    const { data: notificationsData } = await Api.notification.partnerOptions({ signal });
    notifications = notificationsData;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
  }

  return {
    partner,
    notifications,
    desk,
  };
});

export const partnerDetailsAdminChangePartnerStatus = createAsyncThunk<
  Partner,
  { id: UUID; status: EPartnerStatus },
  AppThunkAPIConfig<ServerErrorResponse>
>('partner/details/admin/changeStatus', async ({ id, status }, { signal, rejectWithValue }) => {
  try {
    if (status === EPartnerStatus.Disabled) await Api.partner.disabled(id);
    else await Api.partner.enabled(id);
    const { data: partnerData } = await Api.partner.one({ id, signal });
    return partnerData;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface PartnerDetailsAdminState {
  readonly byId: Fetchable & {
    readonly partner: Nullable<Partner>;
    readonly desk: Nullable<PartnerDesk>;
    readonly notifications: NotificationOption[];
  };
  readonly needRefreshWatcher: number;
  readonly changeStatus: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<PartnerDetailsAdminState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PartnerDetailsAdminState> {
  partnerDetailsAdminStateReset: Reducer;
}

const slice = createSlice<PartnerDetailsAdminState, Reducers, 'detailsAdmin'>({
  name: 'detailsAdmin',
  initialState: {
    byId: {
      ...fetchableDefault,
      partner: null,
      desk: null,
      notifications: [],
    },
    needRefreshWatcher: 0,
    changeStatus: {
      ...fetchableDefault,
    },
  },
  reducers: {
    partnerDetailsAdminStateReset: state => {
      state.byId = {
        ...fetchableDefault,
        partner: null,
        desk: null,
        notifications: [],
      };
      state.needRefreshWatcher = 0;
      state.changeStatus = {
        ...fetchableDefault,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(partnerDetailsAdminByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.partner = null;
        state.byId.desk = null;
        state.byId.notifications = [];
      })
      .addCase(partnerDetailsAdminByIdFetch.fulfilled, (state, { payload }) => {
        const { partner, desk, notifications } = payload;

        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.partner = partner;
        state.byId.desk = desk;
        state.byId.notifications = notifications;
      })
      .addCase(partnerDetailsAdminByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;

          state.byId.partner = null;
          state.byId.desk = null;
          state.byId.notifications = [];
        }
      })
      .addCase(partnerDetailsAdminChangePartnerStatus.pending, state => {
        state.changeStatus.isFetching = true;
        state.changeStatus.isFetched = false;
        state.changeStatus.isFailed = false;
      })
      .addCase(partnerDetailsAdminChangePartnerStatus.fulfilled, (state, { payload }) => {
        state.changeStatus.isFetching = false;
        state.changeStatus.isFetched = true;
        state.changeStatus.isFailed = false;
        state.byId.partner = payload;
      })
      .addCase(partnerDetailsAdminChangePartnerStatus.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.changeStatus.isFetching = false;
          state.changeStatus.isFetched = false;
          state.changeStatus.isFailed = true;

          state.byId.partner = null;
          state.byId.desk = null;
          state.byId.notifications = [];
        }
      });
  },
});

export const { partnerDetailsAdminStateReset } = slice.actions;

export default slice.reducer;
