import { Popover, Typography, TypographyProps } from '@mui/material';
import { forwardRef } from 'react';
import styled from '@emotion/styled';

interface StyledTypographyProps extends TypographyProps {
  readonly bold?: boolean;
  readonly disabled?: boolean;
  readonly maxLines?: number;
  readonly hint?: boolean;
}

export const StyledTypography = styled(
  forwardRef(({ bold, disabled, maxLines, hint, ...others }: StyledTypographyProps, ref: any) => (
    <Typography
      ref={ref}
      {...others}
    />
  ))
)`
  ${p =>
    p.bold &&
    `
    font-weight: ${p.theme.typography.fontWeightSemiBold};
    
    & * {
      font-weight: ${p.theme.typography.fontWeightSemiBold};
    }
  `}

  ${p =>
    p.disabled &&
    `
    color: ${p.theme.palette.text.disabled};
  
    & * {
      color: ${p.theme.palette.text.disabled};
    }
  `}

  ${p =>
    p.maxLines &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: ${p.maxLines};
    -webkit-box-orient: vertical;
  `}

  ${p =>
    p.hint &&
    `
    cursor: pointer;
  `}
`;

export const HintPopover = styled(Popover)`
  .MuiPaper-root {
    padding: ${p => p.theme.spacing(1.5)};
    max-width: 30rem;
  }
`;
