import { ENoticeStatus, Notice, Nullable } from '@/domain';
import SentryClient from 'integration/sentry/client';
import Notifier from 'system/notifier';
import { businessErrorCode, businessErrorCode2 } from './constants';
import { ENetworkErrorCode, ServerErrorResponse } from './types';
import { ScopeContext } from '@sentry/types/types/scope';
import { AppConfigurator } from '@/system/appConfigurator';

type ErrorParseFunction = (response: any, onForbidden?: () => void) => Nullable<Notice>;

export default class ErrorHandler {
  static handleHttpError(error: Error, response: any, parser: ErrorParseFunction = parseHttpErrorResponse) {
    if ((error as any).__CANCEL__) {
      // Axios Cancel
      return;
    }

    if (response) {
      const notice = parser(response);
      if (notice) {
        Notifier.getInstance().addNotice(notice.status, notice.text);

        if (AppConfigurator.getInstance().getOptions().sentry.xhrTracking) {
          const noticeForLogger = buildServerErrorResponseTextForLogger(notice.text, response);
          ErrorHandler.captureException(new Error(noticeForLogger));
        }
      }
    } else {
      console.error(error);
      ErrorHandler.captureException(error);
    }
  }

  static captureException(exception: string | Error, context?: Partial<ScopeContext>) {
    SentryClient.getInstance().captureException(exception, context);
  }
}

const parseHttpErrorResponse: ErrorParseFunction = (response, onForbidden = () => null) => {
  try {
    switch (response.status) {
      case 401:
        return {
          status: ENoticeStatus.Error,
          text: buildServerErrorResponseTextForClient('Отсутствуют полномочия на запрашиваемый ресурс', response),
        };
      case 403:
        if (response.data?.code === ENetworkErrorCode.MissingUserAgreement) {
          return null;
        }

        onForbidden();
        return {
          status: ENoticeStatus.Error,
          text: buildServerErrorResponseTextForClient(
            'У пользователя не хватает прав доступа к запрашиваемому ресурсу',
            response
          ),
        };
      case businessErrorCode:
      case businessErrorCode2:
        return processServerResponseData(response.data);
      case 500:
        const text = [
          'Сервер не смог обработать запрос из-за внутренней ошибки',
          response?.data?.message ? '.\n' : null,
          response?.data?.message?.slice(0, 500),
        ]
          .filter(t => !!t)
          .join('');
        return {
          status: ENoticeStatus.Error,
          text,
        };
      case 502:
      case 503:
        return {
          status: ENoticeStatus.Error,
          text: buildServerErrorResponseTextForClient('Сервер недоступен', response),
        };
      default:
        return {
          status: ENoticeStatus.Error,
          text: buildServerErrorResponseTextForClient('Неизвестная ошибка сервера', response),
        };
    }
  } catch (e: any) {
    console.error({ ...e, source: response });
    return { status: ENoticeStatus.Error, text: 'Ошибка обработки ответа от сервера' };
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const buildServerErrorResponseTextForClient = (errorText: string, response: any): string => {
  return errorText;
};

const buildServerErrorResponseTextForLogger = (errorText: string, response: any): string => {
  const text = response?.data?.message?.slice(0, 500) || errorText;
  return `[${response.status || 'None'}] ${text}`;
};

const processServerResponseData = (data: ServerErrorResponse): Notice => {
  const { message } = data;
  if (!message) return { status: ENoticeStatus.Warning, text: 'Не получен ожидаемый ответ от сервера' };
  return { status: ENoticeStatus.Error, text: data.message };
};
