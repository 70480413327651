import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { AppFile } from '../../../../../../domain/model';
import { EActivityTypeStatus } from '../../../../../../domain/model/enums';
import { ActivityType } from '../../../../../../domain/model/event';
import { Nullable } from '../../../../../../domain/model/types';
import { DictionaryAddDialog } from '../../../../../components/common/dictionary/dialog/add';
import FileInfo from '../../../../../components/common/files/info';
import FileUploader from '../../../../../components/common/files/uploader';
import { FileUploaderButtonAdapter } from '../../../../../components/common/files/uploader/buttonAdapter';
import { fileTypes } from '../../../../../constants';
import { MPFormInput } from '../../../../../theme/ui-kit/input';
import { convertBytesToUnitText } from '../../../../../utils/files';
import { activityTypeImageMaxSize } from '../utils';

interface ActivityTypesEditDialogAddAdapterProps {
  readonly isLoading: boolean;
  readonly parent: Nullable<ActivityType>;
  readonly onClose: () => void;
  readonly onAdd: (activityType: ActivityType) => void;
}

export const ActivityTypesEditDialogAddAdapter = (props: ActivityTypesEditDialogAddAdapterProps) => {
  const { isLoading, parent, onAdd, onClose } = props;

  const [error, setError] = useState<Nullable<string>>(null);

  const [value, setValue] = useState<ActivityType>({
    id: '',
    name: '',
    description: null,
    parent,
    status: EActivityTypeStatus.Enabled,
    image: null,
  });

  const onAddInternal = () => {
    if (!value.name) {
      setError('Введите название');
    } else {
      onAdd(value);
    }
  };

  const onChangeName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setError(null);
    setValue(prev => ({ ...prev, name: event.target.value }));
  };

  const onChangeImage = ({ path }: AppFile) => {
    setValue(prev => ({ ...prev, image: { path } }));
  };

  const onRemoveImage = () => {
    setValue(prev => ({ ...prev, image: null }));
  };

  return (
    <DictionaryAddDialog
      isLoading={isLoading}
      onClose={onClose}
      onSave={onAddInternal}
    >
      <Grid
        container
        spacing={1.5}
        direction='column'
      >
        <Grid item>
          <MPFormInput
            disabled={isLoading}
            label='Название'
            value={value.name}
            error={error !== null}
            helperText={error}
            inputRef={input => input && input.focus()}
            onChange={onChangeName}
          />
        </Grid>
        <Grid item>
          {!value.image && (
            <FileUploader
              adapter={FileUploaderButtonAdapter}
              text='Изображение интереса'
              description={
                <div>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    align='left'
                  >
                    Формат изображения png. Максимальный размер — {convertBytesToUnitText(activityTypeImageMaxSize)}.
                  </Typography>
                </div>
              }
              fileTypes={[fileTypes.png.mimeType]}
              accept={[fileTypes.png.ext]}
              fileMaxSize={activityTypeImageMaxSize}
              onUpload={onChangeImage}
            />
          )}
          {value.image && (
            <FileInfo
              id={value.image.path}
              onRemove={onRemoveImage}
            />
          )}
        </Grid>
      </Grid>
    </DictionaryAddDialog>
  );
};
