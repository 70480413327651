import axios, { AxiosResponse } from 'axios';
import { OfferLink } from '../../domain/model';
import { DataFilterQueryDslOperator } from '../../domain/model/filter';
import { ApprovalOfferRegistry, ApprovalOfferRegistryRequest } from '../../domain/model/offerApprovalRegistry';
import { Nullable, UUID } from '../../domain/model/types';
import { createCancelToken } from './index';
import { ApiCancellable, ApiQueryDsl, ApiRequestPageable } from './types';
import { appendQueryDslParams, getComServicesEndpoint } from './utils';

type AllProps = ApiCancellable &
  ApiRequestPageable & {
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type FindProps = ApiCancellable & {
  readonly num: Nullable<string>;
  readonly date: Nullable<string>;
};

type SetOffersProps = {
  readonly id: UUID;
  readonly offers: OfferLink[];
};

type CreateProps = ApprovalOfferRegistryRequest;

type OfferApprovalRegistryApi = {
  readonly all: (props: AllProps) => Promise<AxiosResponse<ApprovalOfferRegistry[]>>;
  readonly one: (props: OneProps) => Promise<AxiosResponse<ApprovalOfferRegistry>>;
  readonly find: (props: FindProps) => Promise<AxiosResponse<Nullable<ApprovalOfferRegistry>>>;
  readonly create: (props: CreateProps) => Promise<AxiosResponse<ApprovalOfferRegistry>>;
  readonly setOffers: (props: SetOffersProps) => Promise<AxiosResponse<ApprovalOfferRegistry>>;
};

/**
 * АПИ по работе с приказами для офферов
 */
const offerApprovalRegistry: OfferApprovalRegistryApi = {
  all: props => {
    const { sort, page, pageSize, querydsl, signal } = props;

    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }
    if (querydsl) appendQueryDslParams(params, querydsl);

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    return axios.get(`${getComServicesEndpoint()}/offers/approval-registries`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  one: ({ id, signal }) => {
    return axios.get(`${getComServicesEndpoint()}/offers/approval-registries/${id}`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  find: ({ num, date, signal }) => {
    const querydsl: ApiQueryDsl = [
      {
        field: 'number',
        operator: DataFilterQueryDslOperator.Equals,
        value: [num?.trim() ?? ''],
      },
      {
        field: 'date',
        operator: DataFilterQueryDslOperator.Equals,
        value: [date ?? ''],
      },
    ];

    const params = new URLSearchParams();
    appendQueryDslParams(params, querydsl);
    params.append('page', '0');
    params.append('size', '1');

    return axios
      .get(`${getComServicesEndpoint()}/offers/approval-registries`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      })
      .then(response => ({
        ...response,
        data: response.data?.[0] ?? null,
      }));
  },
  create: data => {
    return axios.post(`${getComServicesEndpoint()}/offers/approval-registries`, {
      number: data.number?.trim(),
      date: data.date,
    });
  },
  setOffers: ({ id, offers }) => {
    return axios.patch(`${getComServicesEndpoint()}/offers/approval-registries/${id}/offers`, offers);
  },
};

export default offerApprovalRegistry;
