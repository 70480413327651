import { SportOptionTyped } from '../../../../../../../domain/model';
import { EPartnerOwnershipType } from '../../../../../../../domain/model/enums';
import { PartnerDraft } from '../../../../../../../domain/model/partner';
import PartnerApplicationViewCompanyGeneral from './company';
import PartnerApplicationViewIndividualGeneral from './individual';

interface PartnerApplicationViewStepGeneralProps {
  readonly partnerApplication: PartnerDraft;
  readonly ownerShipTypes: SportOptionTyped<EPartnerOwnershipType>[];
}

const PartnerApplicationViewStepGeneral = (props: PartnerApplicationViewStepGeneralProps) => {
  const { partnerApplication, ownerShipTypes } = props;

  const { inn, name, ownerFullName, email, website, phone } = partnerApplication;

  if (!partnerApplication.regInfo?.orgType) {
    return <></>;
  }
  switch (partnerApplication.regInfo?.orgType) {
    case EPartnerOwnershipType.IndividualEntrepreneur:
      return (
        <PartnerApplicationViewIndividualGeneral
          inn={inn}
          name={name}
          ownerFullName={ownerFullName}
          email={email}
          website={website}
          phone={phone}
          ownerShipTypes={ownerShipTypes}
        />
      );
    case EPartnerOwnershipType.StockCompany:
    case EPartnerOwnershipType.PublicJoinStockCompany:
    case EPartnerOwnershipType.PK:
    case EPartnerOwnershipType.UP:
    case EPartnerOwnershipType.FGBNU:
    case EPartnerOwnershipType.NonProfitOrganization:
    case EPartnerOwnershipType.ClosedJoinStockCompany:
    case EPartnerOwnershipType.JoinStockCompany:
    case EPartnerOwnershipType.NAO:
    case EPartnerOwnershipType.AutonomousNonProfitOrganization:
    case EPartnerOwnershipType.LimitedLiabilityCompany: {
      return (
        <PartnerApplicationViewCompanyGeneral
          ownerShipType={partnerApplication.regInfo.orgType}
          inn={inn}
          name={name}
          companyName={partnerApplication.regInfo.companyName ?? null}
          ownerFullName={ownerFullName}
          email={email}
          website={website}
          phone={phone}
          ownerShipTypes={ownerShipTypes}
        />
      );
    }
    default:
      return <></>;
  }
};

export default PartnerApplicationViewStepGeneral;
