import { ESearchQueryStatDiscriminator } from '@/domain/model/enums';
import { AddressHelper } from '@/presentation/utils/address';
import AnalyticsQueriesTableCell, { AnalyticsQueriesTableCellCommonProps } from './index';

const AnalyticsQueriesTableCellGeo = (props: AnalyticsQueriesTableCellCommonProps) => {
  const { query } = props;

  const value =
    query.discriminator === ESearchQueryStatDiscriminator.Geo ? new AddressHelper(query.geo).getFullPath() : '';
  return (
    <AnalyticsQueriesTableCell
      {...props}
      value={value}
    />
  );
};

export default AnalyticsQueriesTableCellGeo;
