import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Api from '../../../../../../data/api';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { RzhdRoad } from '../../../../../../domain/model/orgStructure';
import { Nullable } from '../../../../../../domain/model/types';
import {
  getOrgStructureRoadMultipleItemAllRoads,
  getOrgStructureRoadMultipleItemDefaultSourceHeader,
  getOrgStructureRoadMultipleItemNoneRoads,
} from './item';
import {
  MultipleSelectorSelectAllType,
  OrgStructureRoadMultipleAllRoadsState,
  OrgStructureRoadMultipleItemType,
  OrgStructureRoadMultipleSelectType,
  OrgStructureRoadMultipleTypesSelectorItem,
} from './types';

interface UseOrgStructureRoadMultipleTypesProps {
  readonly selectAllType: MultipleSelectorSelectAllType;
  readonly select?: Nullable<OrgStructureRoadMultipleSelectType>;
  readonly selectNone?: boolean;
  readonly roads: RzhdRoad[];
  /* набор данных по умолчанию (в моменты когда нет поискового запроса) */
  readonly defaultSource?: Nullable<RzhdRoad[]>;
  readonly onSelect?: (type: OrgStructureRoadMultipleSelectType) => void;
  readonly onChange: (roads: RzhdRoad[]) => void;
}

interface UseOrgStructureRoadMultipleTypes {
  readonly options: OrgStructureRoadMultipleTypesSelectorItem[];
  readonly values: OrgStructureRoadMultipleTypesSelectorItem[];
  readonly isLoading: boolean;
  readonly setSearchValue: (value: string) => void;
  readonly onChangeValue: (value: Nullable<OrgStructureRoadMultipleTypesSelectorItem[]>) => void;
  readonly getOptionLabel: (option: OrgStructureRoadMultipleTypesSelectorItem) => string;
  readonly onFilterOptions: (
    options: OrgStructureRoadMultipleTypesSelectorItem[],
    value: string
  ) => OrgStructureRoadMultipleTypesSelectorItem[];
}

const useOrgStructureRoadMultipleTypes = (
  props: UseOrgStructureRoadMultipleTypesProps
): UseOrgStructureRoadMultipleTypes => {
  const { selectAllType, select, selectNone, roads, defaultSource, onSelect, onChange } = props;

  const [suggestions, setSuggestions] = useState<RzhdRoad[]>([]);
  const [allRoads, setAllRoads] = useState<RzhdRoad[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (searchValue) {
      setSuggestions(
        allRoads.filter(road => road.name.toLocaleUpperCase().startsWith(searchValue.toLocaleUpperCase()))
      );
    }
  }, [searchValue]);

  const selectAllInternal = useMemo<boolean>(() => {
    switch (selectAllType) {
      case MultipleSelectorSelectAllType.Flag:
        return select === OrgStructureRoadMultipleSelectType.All;
      case MultipleSelectorSelectAllType.List:
        return roads.length > 0 && roads.length === allRoads.length;
    }
  }, [selectAllType, select, allRoads, roads]);

  const onChangeValue = useCallback(
    (value: Nullable<OrgStructureRoadMultipleTypesSelectorItem[]>) => {
      if (
        value?.some(
          item =>
            item.type === OrgStructureRoadMultipleItemType.All &&
            item.state !== OrgStructureRoadMultipleAllRoadsState.Checked
        ) ||
        (!selectAllInternal && value?.length === allRoads.length)
      ) {
        onSelect ? onSelect(OrgStructureRoadMultipleSelectType.All) : onChange(allRoads);
      } else if (
        value?.some(
          item =>
            item.type === OrgStructureRoadMultipleItemType.None &&
            item.state !== OrgStructureRoadMultipleAllRoadsState.Checked
        )
      ) {
        onSelect?.(OrgStructureRoadMultipleSelectType.None);
      } else {
        const selectedRoads =
          value
            ?.filter(v => v.type === OrgStructureRoadMultipleItemType.Road)
            .map(v => ({
              id: v.id,
              name: v.name,
            })) ?? [];
        const setUnselectedAll = selectAllInternal && selectedRoads.length === allRoads.length;
        onChange(setUnselectedAll ? [] : selectedRoads);
      }
    },
    [onChange, onSelect, allRoads, selectAllInternal]
  );

  const getOptionLabel = useCallback((option: OrgStructureRoadMultipleTypesSelectorItem) => {
    switch (option.type) {
      case OrgStructureRoadMultipleItemType.Road:
        return option.name;
      default:
        return '';
    }
  }, []);

  const onFilterOptions = useCallback((options: OrgStructureRoadMultipleTypesSelectorItem[], value: string) => {
    const newOptions: OrgStructureRoadMultipleTypesSelectorItem[] = [];
    options.forEach(element => {
      if (new RegExp(`.*${value}.*`, 'gi').test(element.name)) newOptions.push(element);
    });

    //удаляем им результатов "Все дороги" если начали поиск
    if (value) {
      const allIndex = newOptions.findIndex(element => element.type === OrgStructureRoadMultipleItemType.All);
      if (allIndex > -1) {
        newOptions.splice(allIndex, 1);
      }
    }
    return newOptions;
  }, []);

  const options = useMemo<OrgStructureRoadMultipleTypesSelectorItem[]>(() => {
    const result: OrgStructureRoadMultipleTypesSelectorItem[] = [];

    // Если в поисковой строке чтото есть, то выводим только поиск
    if (searchValue) {
      suggestions.forEach(item =>
        result.push({
          type: OrgStructureRoadMultipleItemType.Road,
          id: item.id,
          name: item.name,
        })
      );
    } else {
      if (selectNone) {
        result.push(
          getOrgStructureRoadMultipleItemNoneRoads(
            select === OrgStructureRoadMultipleSelectType.None
              ? OrgStructureRoadMultipleAllRoadsState.Checked
              : OrgStructureRoadMultipleAllRoadsState.NotChecked
          )
        );
      }
      //всегда добавляем первым "Все дороги", если нет поиска
      result.push(
        getOrgStructureRoadMultipleItemAllRoads(
          selectAllInternal
            ? OrgStructureRoadMultipleAllRoadsState.Checked
            : roads?.length
            ? OrgStructureRoadMultipleAllRoadsState.Indeterminate
            : OrgStructureRoadMultipleAllRoadsState.NotChecked
        )
      );
      // выводим defaultSource, если есть
      if (defaultSource?.length) {
        result.push(getOrgStructureRoadMultipleItemDefaultSourceHeader());
        defaultSource.forEach(item =>
          result.push({
            type: OrgStructureRoadMultipleItemType.Road,
            id: item.id,
            name: item.name,
          })
        );
      } else {
        // если defaultSource нет, выводим все дороги, весь справочник
        allRoads.forEach(item =>
          result.push({
            type: OrgStructureRoadMultipleItemType.Road,
            id: item.id,
            name: item.name,
          })
        );
      }
    }
    return result;
  }, [selectAllInternal, selectNone, select, searchValue, defaultSource, roads, allRoads, suggestions]);

  const values = useMemo<OrgStructureRoadMultipleTypesSelectorItem[]>(() => {
    const result: OrgStructureRoadMultipleTypesSelectorItem[] = [];

    if (selectAllInternal) {
      result.push(getOrgStructureRoadMultipleItemAllRoads(OrgStructureRoadMultipleAllRoadsState.Checked));
      allRoads.forEach(item =>
        result.push({
          type: OrgStructureRoadMultipleItemType.Road,
          id: item.id,
          name: item.name,
        })
      );
    } else if (select === OrgStructureRoadMultipleSelectType.None) {
      result.push(getOrgStructureRoadMultipleItemNoneRoads(OrgStructureRoadMultipleAllRoadsState.Checked));
    } else {
      roads?.forEach(item => {
        result.push({
          type: OrgStructureRoadMultipleItemType.Road,
          id: item.id,
          name: item.name,
        });
      });
    }

    return result;
  }, [selectAllInternal, allRoads, roads, select]);

  useEffect(() => {
    const cancelCallback: CancelTokenSource = axios.CancelToken.source();
    const config: AxiosRequestConfig = {
      cancelToken: cancelCallback.token,
    };

    setIsLoading(true);

    Api.orgStructure.road
      .all({
        page: 1,
        pageSize: 9999,
        sort: 'name',
        config,
      })
      .then(({ data }) => setAllRoads(data))
      .catch(e => {
        console.error(e);
        ErrorHandler.handleHttpError(e, e.response);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      cancelCallback.cancel();
    };
  }, []);

  return {
    options,
    values,
    isLoading,
    setSearchValue,
    onChangeValue,
    getOptionLabel,
    onFilterOptions,
  };
};

export default useOrgStructureRoadMultipleTypes;
