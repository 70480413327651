import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { UUID } from '../../../domain/model/types';
import { MpUser } from '../../../domain/model/user';
import ForbiddenComponent from '../../components/common/forbidden';
import useBreadcrumbsActions from '../../features/general/breadcrumbs/useBreadcrumbsActions';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import PartnerEmployeeDetailsContainer from '../../features/partnerEmployee/details/container';
import { getPartnerEmployeeEditRoute, getPartnerEmployeesTableRoute } from '../../features/partnerEmployee/entry';
import { EPartnerEmployeeStep } from '../../features/partnerEmployee/utils';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';
import useHistoryExtensions from '../../hooks/useHistoryExtensions';

const PartnerEmployeeDetailsScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { putBreadcrumbs } = useBreadcrumbsActions();
  const { partnerEmployees } = useCurrentUser().accessMatrix;

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const { id } = useParams<{ id: UUID }>();

  const onEdit = (employee: MpUser) => {
    history.push(getPartnerEmployeeEditRoute({ id: employee.id }));
  };

  const onClose = () => {
    gotoPrevIndependentLocation(getPartnerEmployeesTableRoute({}));
  };

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  useEffect(() => {
    putBreadcrumbs([{ label: 'Сотрудники' }]);
  }, []);

  if (!partnerEmployees?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <PartnerEmployeeDetailsContainer
      id={id}
      step={EPartnerEmployeeStep.Profile}
      canChangeStatus={partnerEmployees.changeStatus}
      onEdit={partnerEmployees.edit ? onEdit : null}
      onClose={onClose}
    />
  );
};

export default PartnerEmployeeDetailsScreen;
