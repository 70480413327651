import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from '.';
import { EMultiSelectorValueType } from '../../../../../domain/model/enums';

const visibleCount = 3;

const CorpOfferTableCellTargetOrgUnits = (props: CorpOfferTableCellCommonProps) => {
  const {
    corpOffer: { target },
  } = props;

  const { targetOrgUnits } = target;

  let value: string;
  if (targetOrgUnits) {
    if (targetOrgUnits.select === EMultiSelectorValueType.All) {
      value = 'все филиалы';
    } else {
      const count = targetOrgUnits.in?.length ?? 0;
      const orgUnits =
        targetOrgUnits.in
          ?.map(targetOrgUnit => targetOrgUnit.name)
          ?.slice(0, visibleCount)
          ?.join(', ') ?? '';
      const hiddenLabel = count > visibleCount ? `+${count - visibleCount}` : null;

      value = [orgUnits, hiddenLabel].filter(item => !!item).join(', ') ?? '-';
    }
  } else {
    value = '-';
  }

  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellTargetOrgUnits;
