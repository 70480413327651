import { Grid } from '@mui/material';
import { CmsContainerCreateComponentProps } from '../types';
import CmsContainerEditComponentName from './name';
import CmsContainerEditComponentTitle from './title';

type CmsContainerEditComponentCommonProps = CmsContainerCreateComponentProps;

const CmsContainerEditComponentCommon = (props: CmsContainerEditComponentCommonProps) => {
  return (
    <Grid
      container
      direction='column'
      spacing={2}
    >
      <Grid item>
        <CmsContainerEditComponentName {...props} />
      </Grid>
      <Grid item>
        <CmsContainerEditComponentTitle {...props} />
      </Grid>
    </Grid>
  );
};

export default CmsContainerEditComponentCommon;
