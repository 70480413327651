import { BookingOfferShort } from '@/domain/model/booking';
import { useCallback, useMemo } from 'react';
import { EOfferTableTab } from '../../../general/offer/types';

type UseBookingOfferSearchConfigProps = {
  readonly isSingleSelect: boolean;
  readonly tab: EOfferTableTab;
  readonly onBookingOfferClick: (bookingOffer: BookingOfferShort) => void;
  readonly onChangeTableTab: (tab: EOfferTableTab) => void;
};

export type UseBookingOfferSearchConfig = {
  readonly isSingleSelect: boolean;
  readonly tabs: EOfferTableTab[];
  readonly tabsForCounts: EOfferTableTab[];
  readonly defaultTab: EOfferTableTab;
  readonly onBookingOfferClick: (bookingOffer: BookingOfferShort) => void;
  readonly onChangeTab: (tab: EOfferTableTab) => void;
};

export function useBookingOfferSearchConfig(props: UseBookingOfferSearchConfigProps): UseBookingOfferSearchConfig {
  const { isSingleSelect, tab, onBookingOfferClick, onChangeTableTab } = props;

  const tabs = useMemo(() => [EOfferTableTab.Active], []);
  const tabsForCounts = useMemo(() => [EOfferTableTab.Active], []);
  const defaultTab = EOfferTableTab.Active;

  const onChangeTab = useCallback(
    (newTab: EOfferTableTab) => {
      if (newTab !== tab) {
        onChangeTableTab(newTab);
      }
    },
    [tab, onChangeTableTab]
  );

  return {
    isSingleSelect,
    tabs,
    tabsForCounts,
    defaultTab,
    onBookingOfferClick,
    onChangeTab,
  };
}
