import TradeOfferActionsDialogPauseAdapter from './dialogPause';
import TradeOfferActionsDialogRejectAdapter from './dialogReject';
import TradeOfferActionsDialogArchiveAdapter from './dialogArhive';
import TradeOfferActionsDialogChangeModeratorAdapter from './dialogChangeModerator';
import TradeOfferActionsDialogRetrieveAdapter from './dialogRetrieve';
import TradeOfferActionsDialogApproveAdapter from './dialogApprove';
import TradeOfferActionsDialogDeleteAdapter from './dialogDelete';
import TradeOfferActionsDialogDeleteCodesAdapter from './dialogDeleteCodes';
import TradeOfferActionsDialogUnPublishAdapter from './dialogUnPublish';
import TradeOfferActionsDialogResumeAdapter from './dialogResume';
import TradeOfferActionsDialogCreateCodesAdapter from './dialogCreateCodes';

const TradeOfferActionsDialogsAdapter = () => {
  return (
    <>
      <TradeOfferActionsDialogPauseAdapter />
      <TradeOfferActionsDialogRejectAdapter />
      <TradeOfferActionsDialogArchiveAdapter />
      <TradeOfferActionsDialogChangeModeratorAdapter />
      <TradeOfferActionsDialogRetrieveAdapter />
      <TradeOfferActionsDialogApproveAdapter />
      <TradeOfferActionsDialogDeleteAdapter />
      <TradeOfferActionsDialogDeleteCodesAdapter />
      <TradeOfferActionsDialogUnPublishAdapter />
      <TradeOfferActionsDialogResumeAdapter />
      <TradeOfferActionsDialogCreateCodesAdapter />
    </>
  );
};

export default TradeOfferActionsDialogsAdapter;
