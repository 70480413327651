import KeywordsInput from '@components/common/keywordsInput';
import { adCampaignKeywordMaxCount } from '@features/adCampaign/utils/common';
import { AdCampaignCreateFieldCommonProps } from '../../types';

const fieldName = 'keywords';
const AdCampaignCreateFieldKeywords = (props: AdCampaignCreateFieldCommonProps<typeof fieldName>) => {
  const { value, validation, disabled, onChangeAttribute, onValidateAttribute } = props;

  const onChangeValue = (newValue: Nullable<string[]>) => {
    onChangeAttribute(fieldName, newValue);
    onValidateAttribute(fieldName);
  };

  return (
    <KeywordsInput
      label='Ключевые слова'
      disabled={disabled}
      maxCount={adCampaignKeywordMaxCount}
      value={value ?? []}
      error={validation?.hasError}
      helperText={validation?.message}
      onChange={onChangeValue}
    />
  );
};

export default AdCampaignCreateFieldKeywords;
