import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CmsContainerTableCellSortIndexProps } from '../../../components/containerTableCell/sortIndex';
import { CmsContainerTableView } from '../../../types';
import {
  CmsContainerActionCall,
  CmsContainerActions,
  CmsContainerActionTableType,
  ECmsContainerActionType,
} from '../../types';
import { useContextHandlers } from '../hooks/useContextHandlers';
import useCmsContainerTableSortIndex from '../hooks/useSortIndex';
import {
  cmsContainersCmsContainerByIndexSelector,
  cmsContainersCmsContainerToChangeSortIndexSelector,
} from '../store/selectors';
import { CmsContainerTableActionProcess } from '../utils';
import { CmsContainersTableCellAdapterProps } from './cell';

type CmsContainersTableCellSortIndexAdapterProps = Omit<CmsContainersTableCellAdapterProps, 'component'> & {
  readonly component: FC<CmsContainerTableCellSortIndexProps>;
  readonly hoveredRow?: boolean;
  readonly getActionProcesses: (cmsContainer: CmsContainerTableView) => CmsContainerTableActionProcess[];
  readonly getActions: (cmsContainer: CmsContainerTableView) => CmsContainerActions<CmsContainerActionTableType>;
  readonly onCmsContainerAction: (call: CmsContainerActionCall) => void;
};

const CmsContainersTableCellSortIndexAdapter = (props: CmsContainersTableCellSortIndexAdapterProps) => {
  const { index, hoveredRow, getActionProcesses, getActions, onCmsContainerAction, component: Component } = props;

  const handlers = useContextHandlers();
  const cmsContainer = useSelector(cmsContainersCmsContainerByIndexSelector(index));
  const editableCmsContainer = useSelector(cmsContainersCmsContainerToChangeSortIndexSelector);
  const isDisabled = cmsContainer ? handlers.isCmsContainerDisabled(cmsContainer) : false;

  const { max: maxSortIndex } = useCmsContainerTableSortIndex();

  const isEditable = cmsContainer ? editableCmsContainer?.id === cmsContainer.id : false;

  const sortIndexInfo = useMemo(
    () => ({
      max: maxSortIndex,
      isFetching: isEditable,
      isFetched: true,
      isFailed: false,
    }),
    [maxSortIndex, isEditable]
  );

  const canChangeSortIndex = cmsContainer
    ? getActions(cmsContainer).find(
        process => process.type === ECmsContainerActionType.ChangeSortIndex && !process.disabled
      ) ?? null
    : null;

  return cmsContainer ? (
    <Component
      cmsContainer={cmsContainer}
      activeCmsContainer={hoveredRow ? cmsContainer : null}
      changeSortIndexAction={canChangeSortIndex}
      isDisabled={isDisabled}
      editableCmsContainer={editableCmsContainer}
      sortIndexInfo={sortIndexInfo}
      setEditableCmsContainer={handlers.onSetCmsContainerToChangeSortIndex}
      getActionProcesses={getActionProcesses}
      onCmsContainerAction={onCmsContainerAction}
    />
  ) : null;
};

export default CmsContainersTableCellSortIndexAdapter;
