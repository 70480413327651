import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../data/store/store';
import { Partner } from '../../../../../domain/model/partner';
import { UUID } from '../../../../../domain/model/types';
import ContentLoader from '../../../../components/common/loader';
import useBreadcrumbsActions from '../../../general/breadcrumbs/useBreadcrumbsActions';
import PartnerManagementMenuPanel from '../menu';
import { ContentWrapper, Wrapper } from './controls';
import { partnerManagementContainerGetPartner, partnerManagementContainerIsMenuVisible } from './store/selectors';
import { partnerManagementPartnerByIdFetch } from './store/slice';

interface PartnerManagementContainerProps {
  readonly id: UUID;
  readonly children: (partner: Partner) => React.ReactNode;
}

const PartnerManagementContainer = ({ id, children }: PartnerManagementContainerProps) => {
  const dispatch = useAppDispatch();
  const { putHeadBreadcrumbs } = useBreadcrumbsActions();

  const isMenuVisible = useSelector(partnerManagementContainerIsMenuVisible);
  const { data: partner, isFetching, needRefreshWatcher } = useSelector(partnerManagementContainerGetPartner);

  useEffect(() => {
    const promise = dispatch(partnerManagementPartnerByIdFetch(id));
    return () => {
      promise?.abort();
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (needRefreshWatcher > 0) {
      const promise = dispatch(partnerManagementPartnerByIdFetch(id));
      return () => {
        promise?.abort();
      };
    }
  }, [dispatch, id, needRefreshWatcher]);

  useEffect(() => {
    if (partner) {
      putHeadBreadcrumbs([
        {
          label: 'Партнёры',
        },
        {
          label: partner.name,
        },
      ]);
      return () => {
        putHeadBreadcrumbs([]);
      };
    }
  }, [partner]);

  return (
    <Wrapper>
      {partner && (
        <>
          <PartnerManagementMenuPanel
            visible={isMenuVisible}
            partner={partner}
          />
          <ContentWrapper fullscreen={false}>{children(partner)}</ContentWrapper>
        </>
      )}
      {isFetching && <ContentLoader size={75} />}
    </Wrapper>
  );
};

export default PartnerManagementContainer;
