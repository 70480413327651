import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Nullable } from '../../../../domain/model/types';
import { ProgressWrapper, SimpleWrapper, Wrapper } from './controls';

export interface ContentLoaderWrapperProps {
  readonly alpha?: boolean;
  readonly position?: 'absolute' | 'relative' | 'fixed' | 'static';
  readonly verticalPosition?: 'baseline' | 'center' | 'flex-start' | 'flex-end';
  readonly horizontalPosition?: 'baseline' | 'center' | 'flex-start' | 'flex-end';
}

export interface ContentLoaderProps {
  readonly size?: number | string;
  readonly progress?: Nullable<number>;
  readonly color?: 'primary' | 'secondary' | 'inherit';
}

const ContentLoader = (props: ContentLoaderWrapperProps & ContentLoaderProps) => {
  const {
    size = '2rem',
    color = 'primary',
    alpha,
    position = 'absolute',
    verticalPosition = 'center',
    horizontalPosition = 'center',
    progress,
  } = props;

  return (
    <Wrapper
      alpha={alpha}
      position={position}
      verticalPosition={verticalPosition}
      horizontalPosition={horizontalPosition}
    >
      <CircularProgress
        size={size}
        color={color}
      />
      {(progress ?? 0) > 0 && (
        <ProgressWrapper>
          <Typography noWrap>{progress} %</Typography>
        </ProgressWrapper>
      )}
    </Wrapper>
  );
};

export const ContentLoaderSimple = ({ text = 'загрузка...' }) => <SimpleWrapper>{text}</SimpleWrapper>;

export default ContentLoader;
