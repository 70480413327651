type UseSportsmansSearchConfigProps = {
  readonly isSingleSelect: boolean;
};

export type UseSportsmansSearchConfig = {
  readonly isSingleSelect: boolean;
};

export function useSportsmansSearchConfig(props: UseSportsmansSearchConfigProps): UseSportsmansSearchConfig {
  const { isSingleSelect } = props;

  return {
    isSingleSelect,
  };
}
