import { Typography } from '@mui/material';
import { DataFilterStrategyListItem, DataFilterStrategyListSingle } from '../../../../../../domain/model/filter';
import { Nullable } from '../../../../../../domain/model/types';
import { MPMenuItem } from '../../../../../theme/ui-kit/menu/item';
import { MPSelect } from '../../../../../theme/ui-kit/select';
import { DataFilterEditItemProps } from '../index';

interface DataFilterEditItemListSingleProps<T extends string> extends DataFilterEditItemProps<T, Nullable<string>> {
  readonly strategy: DataFilterStrategyListSingle<T>;
}

function DataFilterEditItemListSingle<T extends string>({ strategy, onChange }: DataFilterEditItemListSingleProps<T>) {
  const { label, value, readOnly } = strategy;
  const items = strategy.items ?? [];

  const onSubmitValue = (newValue: DataFilterStrategyListItem) => {
    onChange(strategy, newValue.id);
  };

  return (
    <MPSelect<DataFilterStrategyListItem>
      value={items.find(item => item.id === value) ?? ''}
      label={label}
      disabled={readOnly}
      onChange={onSubmitValue}
    >
      {items.map(item => (
        <MPMenuItem
          key={item.id}
          value={item}
        >
          <Typography variant='body1'>{item.name}</Typography>
        </MPMenuItem>
      ))}
    </MPSelect>
  );
}

export default DataFilterEditItemListSingle;
