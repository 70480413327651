import { Grid } from '@mui/material';
import { FCC, ReactNode, useEffect, useRef, useState } from 'react';
import { MPButton } from '../../../../../theme/ui-kit/button';
import ConfirmDialog from '../../../dialogs/confirm';
import { Content, DialogContentWrapper, Link, Wrapper } from './controls';

type ContentLimiterDialogLayoutProps = {
  readonly rows?: number;
  readonly lineHeight?: number;
  readonly title?: ReactNode;
  readonly moreText?: ReactNode;
};

export const ContentLimiterDialogLayout: FCC<ContentLimiterDialogLayoutProps> = props => {
  const { children, title, rows = 5, lineHeight = 16, moreText } = props;

  const refContent = useRef<Nullable<HTMLDivElement>>(null);
  const [isExpandable, setIsExpandable] = useState<boolean>(false);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const minHeight = rows * lineHeight;

  const handleMoreClick = () => {
    setIsOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  // Инициализируем высоту блока и проверяем нужно ли его сворачивать
  useEffect(() => {
    if (refContent.current !== null) {
      if (refContent.current.getBoundingClientRect().height > minHeight) {
        setIsExpandable(true);
        refContent.current.style.display = '-webkit-box';
      }
    }
  }, [minHeight, refContent]);

  const MoreComponent = (
    <Link
      variant={'body1'}
      onClick={handleMoreClick}
    >
      {moreText ? moreText : 'Посмотреть полностью'}
    </Link>
  );

  return (
    <Wrapper>
      <Content
        rows={rows}
        ref={refContent}
        variant={'body2'}
      >
        {children}
      </Content>
      {isExpandable && moreText !== null && MoreComponent}
      {isOpenDialog && (
        <ConfirmDialog
          title={title}
          text={<DialogContentWrapper>{children}</DialogContentWrapper>}
          open
          dialogButtons={
            <Grid
              container
              spacing={2}
            >
              <Grid item>
                <MPButton onClick={handleCloseDialog}>Закрыть</MPButton>
              </Grid>
            </Grid>
          }
          onClose={handleCloseDialog}
        />
      )}
    </Wrapper>
  );
};
