import { AppConfigurator } from '../../../../../system/appConfigurator';
import BusinessConfigContext from '../contexts/businessConfigContext';

interface BusinessConfigProviderProps {
  readonly children: any;
}

const BusinessConfigProvider = ({ children }: BusinessConfigProviderProps) => {
  const supportInfo = AppConfigurator.getInstance().getSupportInfo();
  const trustedDomains = AppConfigurator.getInstance().getTrustedDomains();

  const state = {
    supportInfo,
    trustedDomains,
  };

  return <BusinessConfigContext.Provider value={state}>{children}</BusinessConfigContext.Provider>;
};

export default BusinessConfigProvider;
