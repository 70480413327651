import { Grid, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENoticeStatus } from '../../../../../domain/model/enums';
import Notifier from '../../../../../system/notifier';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';
import ContentLoader from '../../../../components/common/loader';
import { MPButton } from '../../../../theme/ui-kit/button';
import { MPFormTextArea } from '../../../../theme/ui-kit/input';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import { EProductOrderActionType } from '../../types';
import {
  getProductOrderActionsOfferIsProcessingSelector,
  productOrderActionsDialogByNameSelector,
} from '../store/selectors';
import { productOrderActionsCancel } from '../store/slice';
import { useProductOrderActions } from '../useAction';
import { showProductOrderActionNotification } from '../utils';

const ProductOrderActionsDialogCancelAdapter = () => {
  const dispatch = useDispatch();
  const handlers = useProductOrderActions();
  const { orderCancelReasons } = useSelector(nsiDataSelector);

  const [comment, setComment] = useState<string>('');

  const order = useSelector(productOrderActionsDialogByNameSelector('cancel'));

  const isProcessing = useSelector(getProductOrderActionsOfferIsProcessingSelector(order?.id ?? null));

  const onChangeComment = (reason: string) => {
    setComment(reason);
  };

  const cancelAction = useCallback(() => {
    const cancelReason =
      orderCancelReasons.find(r => r.name.toLowerCase() === 'другое')?.id ?? orderCancelReasons?.[0].id;
    if (cancelReason && order) {
      dispatch(
        productOrderActionsCancel({
          id: order?.id,
          comment,
          reasonId: cancelReason,
        })
      )
        .unwrap()
        .then(response => {
          handlers.onChangeDialogState('cancel', null);
          showProductOrderActionNotification(response, EProductOrderActionType.Cancel);
        });
    } else {
      Notifier.getInstance().addNotice(
        ENoticeStatus.Error,
        `Не удалось определить причину отмены. Справочник пуст или заполнен некорректно.`
      );
    }
  }, [dispatch, handlers.onChangeDialogState, comment, orderCancelReasons]);

  const onCloseDialog = useCallback(() => handlers.onChangeDialogState('cancel', null), [handlers.onChangeDialogState]);

  // отменить можно в случае когда есть список причин и причина выбрана или нет причин и введен комментарий
  const canCancel = comment.trim().length;

  return (
    order && (
      <ConfirmDialog
        open
        onClose={onCloseDialog}
        title='Отмена заказа'
        dialogButtons={
          <Grid
            container
            spacing={2}
          >
            <Grid item>
              <MPButton
                fullWidth={false}
                disabled={!canCancel || isProcessing}
                onClick={cancelAction}
              >
                Отменить заказ
                {isProcessing && <ContentLoader />}
              </MPButton>
            </Grid>
            <Grid item>
              <MPButton
                fullWidth={false}
                variant='outlined'
                onClick={onCloseDialog}
              >
                Вернуться
              </MPButton>
            </Grid>
          </Grid>
        }
      >
        <Grid
          container
          spacing={2}
          wrap='nowrap'
          direction='column'
        >
          <Grid item>
            <Typography gutterBottom={true}>Укажите причину отмены</Typography>
          </Grid>
          <Grid item>
            <MPFormTextArea
              rows={3}
              label='Комментарий'
              value={comment}
              margin='none'
              disabled={isProcessing}
              onChange={event => onChangeComment(event.target.value)}
            />
          </Grid>
        </Grid>
      </ConfirmDialog>
    )
  );
};

export default ProductOrderActionsDialogCancelAdapter;
