import { RootState } from '../../../../../data/store/store';

export const PartnerApplicationVerificationDataSelector = (store: RootState) =>
  store.partnerApplication.verification.data;
export const PartnerApplicationVerificationFetchSelector = (store: RootState) =>
  store.partnerApplication.verification.fetch;
export const PartnerApplicationVerificationAssignVerifierSelector = (store: RootState) =>
  store.partnerApplication.verification.assignVerifier;
export const PartnerApplicationVerificationApproveSelector = (store: RootState) =>
  store.partnerApplication.verification.approve;
export const PartnerApplicationVerificationRejectSelector = (store: RootState) =>
  store.partnerApplication.verification.reject;
