import React from 'react';
import { UserAccessMatrix } from '../../../../../domain/model/accessMatrix';
import {
  AppUser,
  AppUserSpecific,
  MpAdminUserProfile,
  MpPartnerUserProfile,
  SportUserProfile,
} from '../../../../../domain/model/user';

export interface CurrentUserContextType {
  readonly user: AppUser;
  readonly userSpecific: AppUserSpecific;
  readonly accessMatrix: UserAccessMatrix;

  readonly mpAdminUserProfile: MpAdminUserProfile;
  readonly mpPartnerUserProfile: MpPartnerUserProfile;
  readonly sportUserProfile: SportUserProfile;

  readonly defaultRoute: string;

  readonly logOut: () => void;
  readonly refreshToken: () => void;
  readonly resetPassword: () => void;
  readonly refreshUserSpecific: () => void;
}

const CurrentUserContext = React.createContext<CurrentUserContextType>({} as CurrentUserContextType);

export default CurrentUserContext;
