import { useMemo } from 'react';
import { DataTableMetadata } from '@components/common/table';
import useDataTableSettings from '../../../../../hooks/useDataTableSettings';
import { ECmsContainerTableColumn, ECmsContainerTableTab, getCmsContainerTableColumns } from '../utils';

type ColumnsType = ECmsContainerTableColumn;

interface UseCmsContainerTableProps {
  readonly tab: ECmsContainerTableTab;
}

type UseCmsContainerTableResult = {
  readonly metadata: DataTableMetadata<ColumnsType>;
  readonly onChangeMetadata: (metadata: DataTableMetadata<ColumnsType>) => void;
};

const useCmsContainerTable = (props: UseCmsContainerTableProps): UseCmsContainerTableResult => {
  const { tab } = props;

  const sourceColumns = useMemo(() => getCmsContainerTableColumns(tab), [tab]);

  const { metadata, onChangeMetadata } = useDataTableSettings<ColumnsType>({
    name: 'cms-containers',
    nameParts: [tab],
    sourceColumns,
  });

  return {
    metadata,
    onChangeMetadata,
  };
};

export default useCmsContainerTable;
