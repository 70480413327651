import { EClientOrgActionType } from '../../types';
import { removeClientOrgAction } from '../../utils';
import { isReadOnlyCondition } from '../conditions';
import { ClientOrgLifeCycleActionsRule } from '../types';

export const removeActiveActionRule: ClientOrgLifeCycleActionsRule = {
  description: 'Удаление возможности активировать',
  algorithm: [...isReadOnlyCondition.algorithm],
  call: props => {
    if (isReadOnlyCondition.call(props)) {
      removeClientOrgAction(props.nextActions, EClientOrgActionType.UnBlock);
      return true;
    }

    return false;
  },
};

export const removePauseActionRule: ClientOrgLifeCycleActionsRule = {
  description: 'Удаление возможности отключить',
  algorithm: [...isReadOnlyCondition.algorithm],
  call: props => {
    if (isReadOnlyCondition.call(props)) {
      removeClientOrgAction(props.nextActions, EClientOrgActionType.Block);
      return true;
    }
    return false;
  },
};

export const removeEditActionRule: ClientOrgLifeCycleActionsRule = {
  description: 'Удаление возможности редактирования',
  algorithm: [...isReadOnlyCondition.algorithm],
  call: props => {
    if (isReadOnlyCondition.call(props)) {
      removeClientOrgAction(props.nextActions, EClientOrgActionType.Edit);
      return true;
    }
    return false;
  },
};
