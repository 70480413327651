import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { publicAppPalette } from '../../../../theme/palette';

export const CardImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 300px;

  & img {
    width: 100%;
    object-fit: contain;
    object-position: center center;
  }
`;

export const CardContent = styled.div`
  margin-top: ${p => p.theme.spacing(1.5)};
  display: grid;
  grid-row: 1/3;
  row-gap: ${p => p.theme.spacing(1.5)};
}
`;

export const PriceContent = styled.div`
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  column-gap: ${p => p.theme.spacing(1)};
`;

export const PriceText = styled(Typography)`
  font-weight: 700;
`;

export const OldPriceText = styled(Typography)`
  text-decoration-line: line-through;
`;
export const SalePercent = styled(Typography)`
  color: ${publicAppPalette.primary};
`;

export const ButtonCart = styled.div`
  background-color: ${publicAppPalette.primary};
  color: white;
  border-radius: ${p => (p.theme.shape.borderRadius as number) * 2}px;
  width: 100px;
  height: 32px;

  place-content: center;
  display: grid;
}
`;
