import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import PartnerSelfRegistrationContainer from '../../features/partner/selfRegistration/container';

const UserCreateScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  return <PartnerSelfRegistrationContainer />;
};

export default UserCreateScreen;
