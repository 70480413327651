import { formatCostBenefit } from '../../../../utils';
import CorpActivationTableCell, { CorpActivationTableCellCommonProps } from './index';

const CorpActivationTableCellPrice = (props: CorpActivationTableCellCommonProps) => {
  const {
    corpActivation: { price },
  } = props;

  return (
    <CorpActivationTableCell
      {...props}
      value={formatCostBenefit(price) ?? ''}
    />
  );
};

export default CorpActivationTableCellPrice;
