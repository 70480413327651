import { Popper, Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material';
import styled from '@emotion/styled';

export const TooltipPopperDefault = styled(Popper)`
  .MuiTooltip-tooltip {
    padding: ${p => p.theme.spacing(1.25)} ${p => p.theme.spacing(1.5)};

    background-color: ${p => p.theme.status.default};

    border-radius: ${p => (p.theme.shape.borderRadius as number) / 2}px;
  }

  & .MuiTooltip-arrow {
    color: ${p => p.theme.status.default};
  }
`;

export const TooltipPopperError = styled(TooltipPopperDefault)`
  .MuiTooltip-tooltip {
    background-color: ${p => p.theme.palette.error.light};
  }

  & .MuiTooltip-arrow {
    color: ${p => p.theme.palette.error.light};
  }
`;

interface TooltipProps extends MuiTooltipProps {
  readonly variant?: 'default' | 'error';
}

export const FormControlTooltip = styled(({ variant, children, ...others }: TooltipProps) => (
  <MuiTooltip
    PopperComponent={variant === 'error' ? TooltipPopperError : TooltipPopperDefault}
    {...others}
  >
    {children}
  </MuiTooltip>
))``;
