import { Nullable } from '../../../../../domain/model/types';
import { MpUserShort } from '../../../../../domain/model/user';
import PartnerEmployeeTableCell, { PartnerEmployeeTableCellCommonProps } from './index';

export type PartnerEmployeeTableCellOwnerProps = PartnerEmployeeTableCellCommonProps & {
  readonly owner: Nullable<MpUserShort>;
};

const PartnerEmployeeTableCellOwner = (props: PartnerEmployeeTableCellOwnerProps) => {
  const { employee, owner } = props;

  const value = employee.id === owner?.id ? 'Да' : '';

  return (
    <PartnerEmployeeTableCell
      {...props}
      value={value}
    />
  );
};

export default PartnerEmployeeTableCellOwner;
