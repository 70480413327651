import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import TablePagination from '../../../components/common/table/pagination';
import { useContextHandlers } from './hooks/useContextHandlers';
import {
  bookingOrdersPageCountSelector,
  bookingOrdersPageNumberSelector,
  bookingOrdersSearchSelector,
  bookingOrdersTotalCountSelector,
} from './store/selectors';

interface BookingOrderTableFooterContainerProps {}

const BookingOrderTableFooterContainer = forwardRef((props: BookingOrderTableFooterContainerProps, ref: any) => {
  const handlers = useContextHandlers();
  const totalCount = useSelector(bookingOrdersTotalCountSelector);
  const pageCount = useSelector(bookingOrdersPageCountSelector);
  const pageNumber = useSelector(bookingOrdersPageNumberSelector);
  const search = useSelector(bookingOrdersSearchSelector);

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='заказов'
        onChangePageSize={handlers.onChangePageSize}
        onChangePage={handlers.onChangePage}
      />
    </div>
  );
});

export default BookingOrderTableFooterContainer;
