import { Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';

export const Wrapper = styled(Grid)`
  height: 4.375rem;
  width: 18.5rem;

  column-gap: ${p => p.theme.spacing(1)};

  margin-left: 0;
`;

export const CompilationBlockTitle = styled(Typography)`
  font-size: 0.5rem;
  font-weight: ${p => p.theme.typography.fontWeightBold};
`;

export const Line = styled(Grid)`
  margin-left: ${p => p.theme.spacing(1)};
`;
