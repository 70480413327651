import { TokenExpiredConfig } from '@mp-npm/mp-auth-client';
import { ENetworkErrorCode, ServerErrorResponse } from '../../data/network/types';

export const tokenExpiredConfig: TokenExpiredConfig = {
  secondsOnTokenExpired: -1,
  secondsToExpireToken: 20,
  msToCheckInterval: 5000,
};
export const checkSsoPath = `${window.location.origin}/check-sso.html`;

//факт того, что ошибка вызвана бизнес-логикой, для таких ситуаций обработка отдельная в нужных местах
export const isSecurityBusinessError = (data: ServerErrorResponse): boolean => {
  return data?.code === ENetworkErrorCode.MissingUserAgreement;
};
