import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { ServerErrorResponse } from '../../../../../data/network/types';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { EUserStatus } from '../../../../../domain/model/enums';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { MpUser } from '../../../../../domain/model/user';

export const customerDetailsByIdFetch = createAsyncThunk<MpUser, UUID, AppThunkAPIConfig>(
  'customer/details/byId/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      const { data: user } = await Api.user.mp.one({ id, signal });
      return user;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export const customerDetailsChangeUserStatus = createAsyncThunk<
  MpUser,
  { id: UUID; status: EUserStatus },
  AppThunkAPIConfig<ServerErrorResponse>
>('customer/details/changeStatus', async ({ id, status }, { signal, rejectWithValue }) => {
  try {
    await Api.user.mp.changeStatus({ id, status });
    const { data: userData } = await Api.user.mp.one({ id, signal });
    return userData;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface CustomerDetailsState {
  readonly needRefreshWatcher: number;
  readonly byId: Fetchable & {
    readonly user: Nullable<MpUser>;
    readonly loadedUser: Nullable<MpUser>;
  };
  readonly changeStatus: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<CustomerDetailsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CustomerDetailsState> {
  customerDetailsStateReset: Reducer;
}

const slice = createSlice<CustomerDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    needRefreshWatcher: 0,
    byId: {
      ...fetchableDefault,
      user: null,
      loadedUser: null,
    },
    changeStatus: {
      ...fetchableDefault,
    },
  },
  reducers: {
    customerDetailsStateReset: state => {
      state.needRefreshWatcher = 0;
      state.byId = {
        ...fetchableDefault,
        user: null,
        loadedUser: null,
      };
      state.changeStatus = {
        ...fetchableDefault,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(customerDetailsByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.user = null;
      })
      .addCase(customerDetailsByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.user = payload;
        state.byId.loadedUser = payload;
      })
      .addCase(customerDetailsByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        }

        state.byId.user = null;
      })
      .addCase(customerDetailsChangeUserStatus.pending, state => {
        state.changeStatus.isFetching = true;
        state.changeStatus.isFetched = false;
        state.changeStatus.isFailed = false;
      })
      .addCase(customerDetailsChangeUserStatus.fulfilled, (state, { payload }) => {
        state.changeStatus.isFetching = false;
        state.changeStatus.isFetched = true;
        state.changeStatus.isFailed = false;
        state.byId.user = payload;
      })
      .addCase(customerDetailsChangeUserStatus.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.changeStatus.isFetching = false;
          state.changeStatus.isFetched = false;
          state.changeStatus.isFailed = true;
        }
      });
  },
});

export const { customerDetailsStateReset } = slice.actions;

export default slice.reducer;
