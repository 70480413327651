import { AdCampaignCreateUiState } from '../types';

export const defaultUiFieldsState: AdCampaignCreateUiState['fields'] = {
  visible: ['name', 'type', 'partner', 'offerType', 'startDate', 'endDate', 'offers', 'budget'],
  disabled: [],
};

export const catalogUiFieldsState: AdCampaignCreateUiState['fields'] = defaultUiFieldsState;
export const searchUiFieldsState: AdCampaignCreateUiState['fields'] = {
  visible: ['name', 'type', 'partner', 'offerType', 'startDate', 'endDate', 'offers', 'keywords', 'budget'],
  disabled: [],
};
