import { GeolocationControl, Map, Placemark, YMaps, ZoomControl } from '@pbe/react-yandex-maps';
import { locale } from '../../../../locale';
import { MapCoordinates } from '../types';

interface YandexMapContainerProps {
  readonly apiKey: string;
  readonly centerCoordinates: Nullable<MapCoordinates>;
  readonly placemarkCoordinates: Nullable<MapCoordinates>;
  readonly onClickMap?: (e: any) => void;
}

const YandexMapContainer = (props: YandexMapContainerProps) => {
  const { onClickMap, placemarkCoordinates, apiKey } = props;

  return (
    <YMaps
      query={{
        lang: locale.map,
        apikey: apiKey,
      }}
    >
      <Map
        width='100%'
        height='100%'
        onClick={onClickMap}
        defaultState={{ center: placemarkCoordinates ?? [55.73, 37.9], zoom: 9 }}
      >
        {placemarkCoordinates && <Placemark geometry={placemarkCoordinates} />}
        <GeolocationControl options={{ float: 'right' }} />
        <ZoomControl
          options={{
            position: { top: 200, right: 10 },
            size: 'small',
          }}
        />
      </Map>
    </YMaps>
  );
};

export default YandexMapContainer;
