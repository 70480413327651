import styled from '@emotion/styled';

export const Wrapper = styled.div<{ active?: boolean; completed?: boolean }>`
  width: ${p => p.theme.spacing(4)};
  height: ${p => p.theme.spacing(4)};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: 2px solid ${p => (p.active || p.completed ? p.theme.palette.primary.main : p.theme.palette.disabled.text)};

  font-weight: ${p => p.theme.typography.fontWeightBold};
  color: ${p => (p.active || p.completed ? p.theme.palette.primary.main : p.theme.palette.disabled.text)};
`;
