import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../data/store/store';
import { Nullable } from '../../../../domain/model/types';
import { userCurrentSettingsByKeySelector } from '../current/store/selectors';
import { userSettingsChange, userSettingsFetch } from '../current/store/slice';

interface UseUserSettingsProps<T> {
  readonly key: string;
  readonly defaultValue: T;
}

interface UseUserSettingsResult<T> {
  readonly value: Nullable<T>;
  readonly onChange: (value: T) => void;
}

const useUserSettings = <T>(props: UseUserSettingsProps<T>): UseUserSettingsResult<T> => {
  const { key, defaultValue } = props;
  const dispatch = useAppDispatch();

  const valueString = useSelector(userCurrentSettingsByKeySelector(key));

  const value = useMemo(() => (valueString ? JSON.parse(valueString) : null), [valueString]);
  const defaultValueString = useMemo(() => JSON.stringify(defaultValue), [JSON.stringify(defaultValue)]);

  const onChange = useCallback(
    (newValue: T) => {
      dispatch(userSettingsChange({ key, value: JSON.stringify(newValue) }));
    },
    [key]
  );

  useEffect(() => {
    if (!valueString) {
      dispatch(userSettingsFetch({ key, defaultValue: defaultValueString }));
    }
  }, [valueString, defaultValueString]);

  return {
    value,
    onChange,
  };
};

export default useUserSettings;
