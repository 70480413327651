import { RootState } from '../../../../../data/store/store';

export const bookingOfferDetailsIsPreloadingSelector = (store: RootState) => store.bookingOffer.details.byId.isFetching;
export const bookingOfferDetailsFetchIsFailedSelector = (store: RootState) => store.bookingOffer.details.byId.isFailed;
export const bookingOfferDetailsBookingOfferSelector = (store: RootState) =>
  store.bookingOffer.details.byId.bookingOffer;
export const bookingOfferDetailsPartnerSelector = (store: RootState) => store.bookingOffer.details.byId.partner;
export const bookingOfferDetailsUiStepsStateSelector = (store: RootState) =>
  store.bookingOffer.details.ui?.steps ?? null;
export const bookingOfferDetailsDialogsSelector = (store: RootState) => store.bookingOffer.details.dialogs;
