import { IconButton, SnackbarContent, SnackbarProps } from '@mui/material';
import { forwardRef } from 'react';
import styled from '@emotion/styled';

export const DefaultNotice = styled(
  forwardRef((props: SnackbarProps, ref: any) => (
    <SnackbarContent
      {...props}
      ref={ref}
    />
  ))
)`
  width: 28rem;
  padding: ${p => p.theme.spacing(0.8)} ${props => props.theme.spacing(1.2)};

  .MuiSnackbarContent-message {
    width: 100%;
  }

  .MuiSnackbarContent-action {
    position: absolute;
    right: 0.7rem;
    top: 0.15rem;
  }
`;
export const ErrorNotice = styled(DefaultNotice)`
  background-color: ${p => p.theme.status.error};
`;
export const WarningNotice = styled(DefaultNotice)`
  background-color: ${p => p.theme.status.warning};
`;
export const SuccessNotice = styled(DefaultNotice)`
  background-color: ${p => p.theme.status.success};
`;
export const InfoNotice = styled(DefaultNotice)`
  background-color: ${p => p.theme.status.info};
`;
export const Message = styled.div`
  display: flex;
  align-items: center;

  padding-right: 2.2rem;
`;
export const IconWrapper = styled.div`
  display: flex;

  margin-right: ${p => p.theme.spacing()};

  .MuiSvgIcon-root {
    fill: ${p => p.theme.palette.text.onPrimary};
  }
`;
export const Text = styled.div`
  width: 100%;

  color: ${p => p.theme.palette.text.onPrimary};
  white-space: pre-line;
  word-break: break-word;
`;

export const TitleText = styled.div`
  width: 100%;
  color: ${p => p.theme.palette.background.default};
  white-space: pre-line;
  word-break: break-word;
  font-weight: 700;
  margin-bottom: ${p => p.theme.spacing()};
`;
export const CloseButton = styled(IconButton)`
  .MuiSvgIcon-root {
    fill: ${p => p.theme.palette.text.onPrimary};
  }
`;
