const isBankAccountValid = (account: string): boolean => {
  let sum = 0;

  // весовые коэффициенты
  const v = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];

  for (let i = 0; i <= 22; i++) {
    // вычисляем контрольную сумму
    sum = sum + ((Number(account.charAt(i)) * v[i]) % 10);
  }

  // сравниваем остаток от деления контрольной суммы на 10 с нулём
  return sum % 10 === 0;
};
/*
  Проверка правильности указания корреспондентского счёта:
  1. Для проверки контрольной суммы перед корреспондентским счётом добавляются "0" и два знака БИКа банка, начиная с пятого знака.
*/
export const isCorrespondentBankAccount = (account: string, bik: string): boolean => {
  try {
    return isBankAccountValid('0' + bik.substring(4, 6) + account);
  } catch (e) {
    console.error(e);
    return false;
  }
};
/*
  Проверка правильности указания расчётного счёта:
  1. Для проверки контрольной суммы перед расчётным счётом добавляются три последние цифры БИКа банка.
*/
export const isPaymentBankAccount = (account: string, bik: string): boolean => {
  try {
    return account.length === 20 && bik.length === 9 && isBankAccountValid(bik.substring(6, 9) + account);
  } catch (e) {
    console.error(e);
    return false;
  }
};
// Функция для проверки правильности ИНН
export const isInn = (inn: string): boolean => {
  try {
    if (inn.length === 10) {
      let sum = 0;
      const v = [2, 4, 10, 3, 5, 9, 4, 6, 8];

      // вычисляем контрольную сумму
      for (let i = 0; i <= 8; i++) sum = sum + Number(inn.charAt(i)) * v[i];

      return inn.charAt(9) === String((sum % 11) % 10);
    } else if (inn.length === 12) {
      let sum1 = 0;
      const v1 = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8];

      // вычисляем контрольную сумму
      for (let i = 0; i <= 9; i++) sum1 = sum1 + Number(inn.charAt(i)) * v1[i];

      let sum2 = 0;
      const v2 = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8];

      // вычисляем контрольную сумму
      for (let i = 0; i <= 10; i++) sum2 = sum2 + Number(inn.charAt(i)) * v2[i];

      return inn.charAt(10) === String((sum1 % 11) % 10) && inn.charAt(11) === String((sum2 % 11) % 10);
    }

    return false;
  } catch (e) {
    console.error(e);
    return false;
  }
};
export const isOgrn = (ogrn: string): boolean => {
  try {
    // дальше работаем со строкой
    ogrn += '';

    // для ОГРН в 13 знаков
    if (ogrn.length === 13) {
      const calcNumber: number = (Number(ogrn.slice(0, -1)) % 11) % 10;
      return ogrn.charAt(12) === String(calcNumber);
      // для ОГРН в 15 знаков
    } else if (ogrn.length === 15) {
      const calcNumber: number = (Number(ogrn.slice(0, -1)) % 13) % 10;
      return ogrn.charAt(14) === String(calcNumber);
    } else {
      return false;
    }
  } catch (e) {
    console.error(e);
    return false;
  }
};
