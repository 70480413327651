import useCurrentUser from '@features/user/hooks/useCurrentUser';
import { useEffect } from 'react';
import { ChangeEmailDialog } from '../dialog';
import { useUserProfileEmailEdit } from '../hooks/useEmailEdit';

type ChangeEmailAdapterProps = {
  readonly onNext: () => void;
  readonly onClose: () => void;
};

export const ChangeEmailAdapter = ({ onClose, onNext }: ChangeEmailAdapterProps) => {
  const { userSpecific } = useCurrentUser();
  const currentEmail = userSpecific?.email ?? '';

  const { email, validation, isFetching, onUpdate, setEmail } = useUserProfileEmailEdit();

  const updateEmailInternal = () => {
    onUpdate().then(result => {
      if (result) {
        onNext();
      }
    });
  };

  useEffect(() => {
    setEmail(currentEmail);
  }, [currentEmail]);

  return (
    <ChangeEmailDialog
      value={email}
      validation={validation?.email}
      label={'Новая электронная почта'}
      primaryText='Продолжить'
      isFetching={isFetching}
      onChange={setEmail}
      onClose={onClose}
      onSave={updateEmailInternal}
    />
  );
};
