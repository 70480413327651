import { FCC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PanelAction } from '../../../../types';
import { useClientOrgActions } from '../../actions/useActions';
import { ClientOrgActionDetailsType, EClientOrgActionType } from '../../types';
import { getClientOrgActionsConfigByPermissions } from '../../utils/actions';
import { ClientOrgDetailsContainerProps } from '../container';
import { ClientOrgDetailsLifecycleContext, ClientOrgDetailsLifecycleContextValue } from '../context';
import useClientOrgDetailsActions from '../hooks/useActions';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { clientOrgDetailsClientOrgSelector } from '../store/selectors';

export const ClientOrgDetailLifecycleProvider: FCC<ClientOrgDetailsContainerProps> = ({
  children,
  id,
  lifecycle,
  access,
}) => {
  const handlers = useContextHandlers();

  const {
    utils: {
      selectors: { getIsClientOrgExecutedActionsSelector },
    },
  } = useClientOrgActions();

  const clientOrg = useSelector(clientOrgDetailsClientOrgSelector);

  const isActionsForRefresh = useSelector(
    getIsClientOrgExecutedActionsSelector(id, EClientOrgActionType.Block, EClientOrgActionType.UnBlock)
  );

  const allowedActions = getClientOrgActionsConfigByPermissions({
    ...access,
  });

  const lifeCycleRules = lifecycle.build({ allowedActions, obj: clientOrg });

  const actions = useClientOrgDetailsActions({
    lifeCycleRules,
  });

  const onAction = (action: PanelAction<ClientOrgActionDetailsType>) => {
    if (!clientOrg) {
      return;
    }

    const { type } = action;
    switch (type) {
      case EClientOrgActionType.Block:
        handlers.onClientOrgTryBlock();
        break;
      case EClientOrgActionType.UnBlock:
        handlers.onClientOrgTryUnBlock();
        break;
      case EClientOrgActionType.Edit:
        handlers.onClientOrgOpenEdit(clientOrg);
        break;
    }
  };

  useEffect(() => {
    if (isActionsForRefresh) {
      handlers.onRefresh();
    }
  }, [isActionsForRefresh, handlers.onRefresh]);

  const value: ClientOrgDetailsLifecycleContextValue = {
    lifecycle,
    lifeCycleRules,
    actions,
    onAction,
  };

  return (
    <ClientOrgDetailsLifecycleContext.Provider value={value}>{children}</ClientOrgDetailsLifecycleContext.Provider>
  );
};
