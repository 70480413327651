import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ContentLoaderSimple } from '../../../../components/common/loader';
import UserCreateScreen from '../../../../screens/userCreate/common';
import { ContentWrapper } from './controls';

const MainEntryUserCreate = () => {
  return (
    <ContentWrapper>
      <Suspense fallback={<ContentLoaderSimple />}>
        <Switch>
          <Route component={UserCreateScreen} />
        </Switch>
      </Suspense>
    </ContentWrapper>
  );
};

export default MainEntryUserCreate;
