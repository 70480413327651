import { Grid, Typography } from '@mui/material';

const BookingOrderEditItemInfoQuantityItemsHeader = () => {
  return (
    <Grid container>
      <Grid
        item
        xs={8}
      >
        <Typography
          color='textSecondary'
          variant='body2'
        >
          Выбранные даты
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
      >
        <Typography
          color='textSecondary'
          variant='body2'
        >
          Гостей
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BookingOrderEditItemInfoQuantityItemsHeader;
