import { UserAccessPartitionBookingOffers, UUID } from '@/domain';
import { CommonFetchErrorDecorator } from '@features/general/decorators/error';
import { MasterDetailsLayout } from '@layouts/masterDetails/container';
import MasterContentGridLayout from '../../../layouts/masterContentGrid/container';
import { CommonPreloaderDecorator } from '../../general/decorators/preloader';
import { CommonUpdaterDecorator } from '../../general/decorators/updater';
import MasterDetailsContainer from '../../general/master/details/container';
import { useBookingOfferActionsSelectors } from '../actions/useActions';
import { BookingLifeCycle } from '../lifecycle/types';
import { EBookingOfferStep } from '../types';
import BookingOfferDetailsFooterAdapter from './adapters/footer';
import BookingOfferDetailsHeaderAdapter from './adapters/header';
import BookingOfferDetailsHistoryAdapter from './adapters/history';
import { BookingOfferDetailsSidePanelsAdapter } from './adapters/sidePanels';
import { BookingOfferDetailsStepContactsAdapter } from './adapters/stepContacts';
import { BookingOfferDetailsStepGeneralAdapter } from './adapters/stepGeneral';

import BookingOfferViewStepperAdapter from './adapters/stepper';
import { BookingOfferDetailsStepServicesAdapter } from './adapters/stepServices';
import { BookingOfferDetailsUiStateAdapter } from './adapters/uiState';
import { BookingOfferDetailsDialogsAdapter } from './dialogs';
import { BookingOfferDetailsInitializer } from './initializer';
import {
  BookingOfferDetailHandlersProvider,
  BookingOfferDetailLifecycleProvider,
  BookingOfferDetailParamsProvider,
} from './providers';
import { bookingOfferDetailsFetchIsFailedSelector, bookingOfferDetailsIsPreloadingSelector } from './store/selectors';

export interface BookingOfferDetailsContainerProps {
  readonly id: UUID;
  readonly step: EBookingOfferStep;
  readonly lifecycle: BookingLifeCycle;
  readonly access: UserAccessPartitionBookingOffers;
}

const BookingOfferDetailsContainer = (props: BookingOfferDetailsContainerProps) => {
  const { id, step, access, lifecycle } = props;

  const { getIsOfferProcessingSelector } = useBookingOfferActionsSelectors();

  return (
    <MasterDetailsContainer
      layout={MasterDetailsLayout}
      layoutProps={{
        stepper: BookingOfferViewStepperAdapter,
        header: BookingOfferDetailsHeaderAdapter,
        footer: BookingOfferDetailsFooterAdapter,
        decorators: [
          <CommonPreloaderDecorator
            key='preloader'
            isFetchingSelector={bookingOfferDetailsIsPreloadingSelector}
          />,
          <CommonUpdaterDecorator
            key='actions'
            isFetchingSelector={getIsOfferProcessingSelector(id)}
          />,
          <CommonFetchErrorDecorator
            key='fail'
            isFetchFailedSelector={bookingOfferDetailsFetchIsFailedSelector}
          />,
        ],
      }}
      initializer={BookingOfferDetailsInitializer}
      providers={[
        {
          name: 'params',
          factory: BookingOfferDetailParamsProvider,
        },
        {
          name: 'handlers',
          factory: BookingOfferDetailHandlersProvider,
        },
        {
          name: 'lifecycle',
          factory: BookingOfferDetailLifecycleProvider,
        },
      ]}
      commonProps={{
        id,
        step,
        lifecycle,
        access,
      }}
    >
      <BookingOfferDetailsUiStateAdapter />

      <MasterContentGridLayout sideContent={<BookingOfferDetailsSidePanelsAdapter />}>
        <BookingOfferDetailsStepGeneralAdapter />
        <BookingOfferDetailsStepContactsAdapter />
        <BookingOfferDetailsStepServicesAdapter />
        <BookingOfferDetailsDialogsAdapter />
      </MasterContentGridLayout>

      <BookingOfferDetailsHistoryAdapter />
    </MasterDetailsContainer>
  );
};

export default BookingOfferDetailsContainer;
