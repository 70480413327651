import Api from '@/data/api';
import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterValueItem,
  EDataFilterType,
  EDateFormat,
  Nullable,
  pageSizeAll,
} from '@/domain';
import moment from 'moment/moment';
import { formatCost, formatNumberRange } from '../../../utils';

export enum EBookingOrdersFilterItem {
  /** Строка запроса */
  Query = 'query',
  /** Фильтр даты создания */
  CreateAt = 'createdAt',
  /** Фильтр код офера */
  OfferCode = 'offer.code',
  /** Фильтр название офера */
  OfferName = 'offer.name',
  /** Фильтр номер заказа */
  Number = 'number',
  /** Фильтр фамилия сотрудника */
  CustomerName = 'customer.lastName',
  /** Фильтр Общей стоимость со скидкой в рублях*/
  Cost = 'cost',
  /** Фильтр Комментарий к последнему статусу */
  ManagerComment = 'managerComment',
  /** Фильтр партнер офера */
  OfferPartner = 'partner.id',
  /** Фильтр КСП */
  CSP = 'hasRzdSocialPackage',
}

export type BookingOrdersFilterEditStrategy = DataFilterStrategyBase<EBookingOrdersFilterItem>;

export type BookingOrdersFilterValues = {
  readonly [EBookingOrdersFilterItem.Query]?: DataFilterValueItem<Nullable<string>>;
  readonly [EBookingOrdersFilterItem.CreateAt]?: DataFilterValueItem<Nullable<[string, string]>>;
  readonly [EBookingOrdersFilterItem.Number]?: DataFilterValueItem<Nullable<string>>;
  readonly [EBookingOrdersFilterItem.CustomerName]?: DataFilterValueItem<Nullable<string>>;
  readonly [EBookingOrdersFilterItem.OfferName]?: DataFilterValueItem<Nullable<string>>;
  readonly [EBookingOrdersFilterItem.OfferCode]?: DataFilterValueItem<Nullable<string>>;
  readonly [EBookingOrdersFilterItem.OfferPartner]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
  readonly [EBookingOrdersFilterItem.ManagerComment]?: DataFilterValueItem<Nullable<string>>;
  readonly [EBookingOrdersFilterItem.Cost]?: DataFilterValueItem<Nullable<[Nullable<number>, Nullable<number>]>>;
  readonly [EBookingOrdersFilterItem.CSP]?: DataFilterValueItem<Nullable<boolean>>;
};

const getCostPreview = (cost: BookingOrdersFilterValues[EBookingOrdersFilterItem.Cost]): Nullable<string> => {
  const range = formatNumberRange(cost?.value?.[0] ?? null, cost?.value?.[1] ?? null, formatCost);
  return range ? 'Сумма ' + range : null;
};

export const getBookingOrderFilterStrategies = (
  values: BookingOrdersFilterValues,
  partnerId: Nullable<UUID>,
  isCSP: boolean
): BookingOrdersFilterEditStrategy[] => {
  const strategies: BookingOrdersFilterEditStrategy[] = [];
  strategies.push(
    {
      type: EDataFilterType.String,
      key: EBookingOrdersFilterItem.Query,
      label: 'Поиск по ключевому слову',
      preview: values[EBookingOrdersFilterItem.Query]?.value || null,
      value: values[EBookingOrdersFilterItem.Query]?.value || null,
    },
    {
      type: EDataFilterType.DateAsTimePeriod,
      key: EBookingOrdersFilterItem.CreateAt,
      label: 'Дата',
      preview: moment(values[EBookingOrdersFilterItem.CreateAt]?.value?.[0]).format(EDateFormat.DisplayDefault) || null,
      value: values[EBookingOrdersFilterItem.CreateAt]?.value || null,
      querydsl: {
        operator: DataFilterQueryDslOperator.Between,
      },
    },
    {
      type: EDataFilterType.String,
      key: EBookingOrdersFilterItem.Number,
      label: 'Номер заказа',
      preview: values[EBookingOrdersFilterItem.Number]?.value || null,
      value: values[EBookingOrdersFilterItem.Number]?.value || null,
      querydsl: {
        operator: DataFilterQueryDslOperator.Like,
      },
    },
    {
      type: EDataFilterType.String,
      key: EBookingOrdersFilterItem.OfferCode,
      label: 'Код объекта',
      preview: values[EBookingOrdersFilterItem.OfferCode]?.value || null,
      value: values[EBookingOrdersFilterItem.OfferCode]?.value || null,
      querydsl: {
        operator: DataFilterQueryDslOperator.Like,
      },
    },
    {
      type: EDataFilterType.String,
      key: EBookingOrdersFilterItem.OfferName,
      label: 'Объект',
      preview: values[EBookingOrdersFilterItem.OfferName]?.value || null,
      value: values[EBookingOrdersFilterItem.OfferName]?.value || null,
      querydsl: {
        operator: DataFilterQueryDslOperator.Like,
      },
    },
    {
      type: EDataFilterType.NumberPeriod,
      key: EBookingOrdersFilterItem.Cost,
      label: '',
      labelPeriod: {
        from: 'Сумма от',
        to: 'до',
      },
      preview: getCostPreview(values[EBookingOrdersFilterItem.Cost]),
      value: values[EBookingOrdersFilterItem.Cost]?.value || null,
      querydsl: {
        operator: DataFilterQueryDslOperator.Between,
      },
    },
    {
      type: EDataFilterType.String,
      key: EBookingOrdersFilterItem.CustomerName,
      label: 'Клиент',
      preview: values[EBookingOrdersFilterItem.CustomerName]?.value || null,
      value: values[EBookingOrdersFilterItem.CustomerName]?.value || null,
      querydsl: {
        operator: DataFilterQueryDslOperator.Like,
      },
    },
    {
      type: EDataFilterType.String,
      key: EBookingOrdersFilterItem.ManagerComment,
      label: 'Комментарий менеджера',
      preview: values[EBookingOrdersFilterItem.ManagerComment]?.value || null,
      value: values[EBookingOrdersFilterItem.ManagerComment]?.value || null,
      querydsl: {
        operator: DataFilterQueryDslOperator.Like,
      },
    }
  );

  if (!partnerId) {
    strategies.push({
      type: EDataFilterType.AutocompleteMultiple,
      key: EBookingOrdersFilterItem.OfferPartner,
      label: 'Партнёр',
      value: values[EBookingOrdersFilterItem.OfferPartner]?.value || null,
      preview: `Партнер (${values[EBookingOrdersFilterItem.OfferPartner]?.value?.length})` || null,
      previews: values[EBookingOrdersFilterItem.OfferPartner]?.value?.map(t => t.name) ?? [],
      source: (name: string) =>
        name
          ? Api.partner
              .all({
                query: name,
                sort: 'name,asc',
                page: 1,
                pageSize: pageSizeAll,
              })
              .then(response => response.data)
          : Promise.resolve([]),
      querydsl: {
        operator: DataFilterQueryDslOperator.In,
        valueAttribute: 'id',
      },
    });
  }

  if (isCSP) {
    strategies.push({
      type: EDataFilterType.BooleanFlag,
      key: EBookingOrdersFilterItem.CSP,
      label: 'Компенсация КСП',
      preview: values[EBookingOrdersFilterItem.CSP]?.value ? 'Компенсация КСП' : null,
      value: values[EBookingOrdersFilterItem.CSP]?.value || null,
      querydsl: {
        operator: DataFilterQueryDslOperator.Equals,
      },
    });
  }

  return strategies;
};
