import { ReactNode } from 'react';
import DataTableCellTextFormat from '../../../../components/common/table/cell/textFormat';
import { SportUserProfile } from '../../../../../domain/model/user';

export interface SportsmanTableCellCommonProps {
  readonly sportsman: SportUserProfile;
}

interface SportsmanTableCellProps extends SportsmanTableCellCommonProps {
  readonly value: ReactNode;
}

const SportsmanTableCell = ({ value }: SportsmanTableCellProps) => {
  return <DataTableCellTextFormat>{value}</DataTableCellTextFormat>;
};

export default SportsmanTableCell;
