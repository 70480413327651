import { AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import { EOfferType, EPeriod } from '../../../domain/model/enums';
import { Nullable } from '../../../domain/model/types';
import { getPastPeriodDates } from '../../utils/dates';
import { EReportSportPeriodType, ReportOfferTypeItem, ReportPeriodItem, ReportSportPeriodTypeItem } from './types';
import { EDateTimeFormat } from '@/domain';

export const reportPeriods: ReportPeriodItem[] = [
  { id: EPeriod.Week, name: 'Неделя' },
  { id: EPeriod.Month, name: 'Месяц' },
  { id: EPeriod.Quarter, name: 'Квартал' },
  { id: EPeriod.Year, name: 'Год' },
];

export const reportOfferTypes: ReportOfferTypeItem[] = [
  { id: EOfferType.Trade, name: 'Торговые предложения' },
  { id: EOfferType.Corp, name: 'Корпоративные предложения' },
  { id: EOfferType.Booking, name: 'Досуг и отдых' },
];

export const reportSportPeriodTypes: ReportSportPeriodTypeItem[] = [
  { id: EReportSportPeriodType.DateEvent, name: 'Срок завершения мероприятия' },
  { id: EReportSportPeriodType.DateUserRegistration, name: 'Срок регистрации пользователей' },
];

export const defaultDatePeriod: Nullable<[Nullable<string>, Nullable<string>]> = [
  moment().add(-1, 'month').add(1, 'day').startOf('day').format(EDateTimeFormat.Server),
  moment().endOf('day').format(EDateTimeFormat.Server),
];

export const getReportPeriodByDates = (
  periods: ReportPeriodItem[],
  from: Moment,
  to: Moment
): Nullable<ReportPeriodItem> => {
  let result: Nullable<ReportPeriodItem> = null;
  Object.values(EPeriod).forEach(period => {
    const periodDates = getPastPeriodDates(period);
    if (periodDates[0].valueOf() === from.valueOf() && periodDates[1].valueOf() === to.valueOf()) {
      result = periods.find(p => p.id === period) ?? null;
    }
  });
  return result;
};

export const reportDownloadURL = (res: AxiosResponse<string>) => {
  const contentDisposition = res.headers['content-disposition'] || 'filename=file';
  const contentType = res.headers['content-type'] || '';

  const fileName: string = decodeURI(contentDisposition.split('filename=')[1]).replace(/["]+/g, '');
  const url = window.URL.createObjectURL(new Blob([res.data], { type: contentType }));
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.style.display = 'none';

  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const validateDatePeriod = (from: Nullable<any>, to: Nullable<any>): boolean => {
  if (!from || !to) {
    return false;
  }

  const fromDate = moment(from);
  const toDate = moment(to);

  return fromDate.isSameOrBefore(toDate);
};
