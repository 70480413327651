import { useState } from 'react';
import { DataFilterStrategyNumber } from '../../../../../../domain/model/filter';
import { Nullable } from '../../../../../../domain/model/types';
import { MPCostInput, MPNumberInput } from '../../../../../theme/ui-kit/input';
import { DataFilterEditItemProps } from '../index';

interface DataFilterEditItemNumberProps<T extends string> extends DataFilterEditItemProps<T, Nullable<number>> {
  readonly strategy: DataFilterStrategyNumber<T>;
}

function DataFilterEditItemNumber<T extends string>({ strategy, onChange }: DataFilterEditItemNumberProps<T>) {
  const { label, value, decimal, readOnly } = strategy;

  const [localValue, setLocalValue] = useState<Nullable<number>>(value);

  const onSubmitValue = () => onChange(strategy, localValue);

  const onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      onSubmitValue();
      e.preventDefault();
    }
    return true;
  };

  return (
    <>
      {decimal ? (
        <MPCostInput
          label={label}
          value={localValue}
          disabled={readOnly}
          onChange={(event, newValue) => setLocalValue(newValue)}
          onKeyPress={onKeyPress}
          onBlur={onSubmitValue}
        />
      ) : (
        <MPNumberInput
          label={label}
          value={localValue}
          disabled={readOnly}
          allowNegative={false}
          isNumericString
          decimalScale={0}
          onChange={(event, newValue) => setLocalValue(newValue)}
          onKeyPress={onKeyPress}
          onBlur={onSubmitValue}
        />
      )}
    </>
  );
}

export default DataFilterEditItemNumber;
