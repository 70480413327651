import { List as MuiList } from '@mui/material';
import styled from '@emotion/styled';

export const Wrapper = styled.div``;

export const List = styled(MuiList)`


  
  & .MuiDivider-inset {
    margin-left: ${p => p.theme.spacing(8)};
    margin-right: 0;
  }
  
  & .MuiListItemSecondaryAction-root {
    right: ${p => p.theme.spacing(3.75)};
  }
  
  & .MuiListItemAvatar-root {
    min-width: ${p => p.theme.spacing(7)};
  }
  
  & .MuiListItem-root {
    padding: ${p => p.theme.spacing(1)}};
  }
  
  & .MuiListItemText-multiline {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
