import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '@/data/network/errorHandler';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { Pageable } from '@/domain/model';
import { BookingOfferShort } from '@/domain/model/booking';
import { Nullable, UUID } from '@/domain/model/types';
import { PaginationSize } from '../../../../types';
import { BookingAllProps } from '../../services/offer';
import { EOfferTableTab } from '@features/general/offer/types';
import { OfferTableTabsCounter } from '@features/general/offer/utils/table';
import { BookingOfferTableFilterValues } from '../../filterUtils';
import bookingServices from '../../services';
import { EBookingOfferTableColumn } from '../../types';

const defaultSort = `${EBookingOfferTableColumn.Name},asc`;

export interface BookingOfferListState extends Fetchable, Pageable<BookingOfferShort> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly totalCount: number;
  readonly pageCount: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
    readonly partnerId: Nullable<UUID>;
  };
  readonly tab: Nullable<EOfferTableTab>;
  readonly tabsCounter: OfferTableTabsCounter;
  readonly tabsCounterUnwatched: OfferTableTabsCounter;
  readonly unwatchedIds: UUID[];
  readonly filter: BookingOfferTableFilterValues;
  readonly allBookingOffers: Fetchable & {
    readonly count: Nullable<number>;
    readonly needRefreshWatcher: number;
  };
}

type Reducer<T = undefined> = CaseReducer<BookingOfferListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<BookingOfferListState> {
  bookingOffersDataReset: Reducer;
  bookingOffersSetTab: Reducer<{ tab: EOfferTableTab }>;
  bookingOffersStartSession: Reducer<{ guid: UUID }>;
  bookingOffersSetFilter: Reducer<BookingOfferTableFilterValues>;
  bookingOffersSortReset: Reducer;
  bookingOffersSetSort: Reducer<{ sort: string }>;
  bookingOffersNeedRefreshWatcherReset: Reducer;
  bookingOffersNeedRefreshWatcherIncrement: Reducer;
  bookingOffersSetPage: Reducer<{ pageNumber: number }>;
  bookingOffersSetPageSize: Reducer<{ pageSize: PaginationSize }>;
}

export type BookingOffersFetchProps = Omit<BookingAllProps, 'signal'>;
export type BookingOffersCountsFetchProps = BookingOffersFetchProps & {
  readonly tabs: EOfferTableTab[];
};

export const bookingOffersFetch = createAsyncThunk<
  Pageable<BookingOfferShort>,
  BookingOffersFetchProps,
  AppThunkAPIConfig
>('bookingOffer/list/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    return await bookingServices.offer.all({ ...props, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingOffersCountFetch = createAsyncThunk<number, BookingOffersFetchProps, AppThunkAPIConfig>(
  'bookingOffer/list/count/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await bookingServices.offer.count({ ...props, signal });
    } catch (e: any) {
      console.error(e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const bookingOffersCountsFetch = createAsyncThunk<
  OfferTableTabsCounter,
  BookingOffersCountsFetchProps,
  AppThunkAPIConfig
>('bookingOffer/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await bookingServices.offer.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      errors.map(e => {
        console.error(e);
      });
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingOffersUnwatchedFetch = createAsyncThunk<UUID[], BookingOffersFetchProps, AppThunkAPIConfig>(
  'bookingOffer/list/unwatched/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      const data = await bookingServices.offer.unwatched({ ...props, signal });
      return data.map(({ id }) => id);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const bookingOffersCountsUnwatchedFetch = createAsyncThunk<
  OfferTableTabsCounter,
  BookingOffersCountsFetchProps,
  AppThunkAPIConfig
>('bookingOffer/list/counts/unwatched/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await bookingServices.offer.countsByTabs({ ...props, viewed: false, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingOffersChangeModerator = createAsyncThunk<
  BookingOfferShort,
  {
    id: UUID;
    userId: UUID;
  },
  AppThunkAPIConfig
>('bookingOffer/list/changeModerator', async ({ id, userId }, { rejectWithValue }) => {
  try {
    return await bookingServices.offer.assignModerator({ id, userId });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

const slice = createSlice<BookingOfferListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    guid: null,
    needRefreshWatcher: 0,
    pageNumber: 1,
    totalCount: 0,
    pageCount: 0,
    tab: null,
    data: [],
    unwatchedIds: [],
    tabsCounter: {},
    filter: {},
    tabsCounterUnwatched: {},
    ...fetchableDefault,
    search: {
      sort: defaultSort,
      pageSize: 10,
      partnerId: null,
    },
    allBookingOffers: {
      ...fetchableDefault,
      count: null,
      needRefreshWatcher: 0,
    },
  },
  reducers: {
    bookingOffersStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;

        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = false;

        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;
        state.needRefreshWatcher = 0;

        state.search = {
          sort: defaultSort,
          pageSize: 10,
          partnerId: null,
        };

        state.allBookingOffers = {
          ...fetchableDefault,
          count: null,
          needRefreshWatcher: 0,
        };

        state.tab = null;
      }
    },
    bookingOffersSetTab: (state, { payload }) => {
      const { tab } = payload;
      // сбрасываем пейджинг и сортировку, если поменялись статусы (закладки)
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    bookingOffersSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    bookingOffersDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
    },
    bookingOffersSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    bookingOffersSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    bookingOffersNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
      state.allBookingOffers.needRefreshWatcher = 0;
    },
    bookingOffersNeedRefreshWatcherIncrement: state => {
      state.needRefreshWatcher++;
    },
    bookingOffersSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    bookingOffersSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bookingOffersFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;

        if (partnerId !== state.search.partnerId) {
          state.data = [];
        }
        state.search.partnerId = partnerId;
      })
      .addCase(bookingOffersFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(bookingOffersFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(bookingOffersUnwatchedFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        if (partnerId !== state.search.partnerId) {
          state.unwatchedIds = [];
        }
      })
      .addCase(bookingOffersUnwatchedFetch.fulfilled, (state, { payload }) => {
        state.unwatchedIds = payload;
      })
      .addCase(bookingOffersUnwatchedFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.unwatchedIds = [];
        }
      })
      .addCase(bookingOffersCountsFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        if (partnerId !== state.search.partnerId) {
          state.tabsCounter = {};
        }
      })
      .addCase(bookingOffersCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(bookingOffersCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      })
      .addCase(bookingOffersCountFetch.pending, state => {
        state.allBookingOffers.isFetching = true;
        state.allBookingOffers.isFetched = false;
        state.allBookingOffers.isFailed = false;

        if (state.allBookingOffers.count === 0) {
          //сбрасываем в null в том случае если до этого ничего не было, чтобы не моргала таблица лишний раз
          state.allBookingOffers.count = null;
        }
      })
      .addCase(bookingOffersCountFetch.fulfilled, (state, { payload }) => {
        state.allBookingOffers.isFetching = false;
        state.allBookingOffers.isFetched = true;
        state.allBookingOffers.isFailed = false;

        state.allBookingOffers.count = payload;
      })
      .addCase(bookingOffersCountFetch.rejected, state => {
        state.allBookingOffers.isFetching = false;
        state.allBookingOffers.isFetched = false;
        state.allBookingOffers.isFailed = true;

        state.allBookingOffers.count = null;
      });
  },
});
export const {
  bookingOffersStartSession,
  bookingOffersSetTab,
  bookingOffersSetFilter,
  bookingOffersDataReset,
  bookingOffersSetPage,
  bookingOffersNeedRefreshWatcherReset,
  bookingOffersSortReset,
  bookingOffersSetPageSize,
  bookingOffersSetSort,
  bookingOffersNeedRefreshWatcherIncrement,
} = slice.actions;

export default slice.reducer;
