import { Grid } from '@mui/material';
import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SportUserProfile } from '../../../../domain/model/user';
import TablePagination from '../../../components/common/table/pagination';
import { MPButton } from '../../../theme/ui-kit/button';
import { PaginationSize } from '../../../types';
import { FooterActionsWrapper } from '../../playground/search/controls';
import { sportsmanSearchSelectedSelector, sportsmanSearchSelector } from './store/selectors';
import { sportsmanSearchSetPage, sportsmanSearchSetPageSize } from './store/slice';

interface SportsmanSearchFooterContainerProps {
  readonly maxCount?: number | false;
  readonly onSelect: (sportUserProfiles: SportUserProfile[]) => void;
  readonly isSingleSelect: boolean;
}

const SportsmanSearchFooterContainer = forwardRef((props: SportsmanSearchFooterContainerProps, ref: any) => {
  const { maxCount, onSelect, isSingleSelect } = props;

  const dispatch = useDispatch();

  const {
    totalCount,
    pageCount,
    pageNumber,
    search,
    selected: sportUserProfiles,
  } = useSelector(sportsmanSearchSelector);

  const selectedSportsmen = useSelector(sportsmanSearchSelectedSelector);

  const onChangePage = (num: number) => {
    dispatch(sportsmanSearchSetPage({ pageNumber: num }));
  };

  const onChangePageSize = (size: PaginationSize) => {
    dispatch(sportsmanSearchSetPageSize({ pageSize: size }));
  };

  let buttonText;

  if (isSingleSelect) {
    buttonText = `Выбрать`;
  } else {
    buttonText = `Выбрать ${selectedSportsmen.length}`;

    if (maxCount) {
      buttonText = `Выбрать`;
      buttonText += ` из ${maxCount}`;
    }
  }

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='пользователей'
        onChangePageSize={onChangePageSize}
        onChangePage={onChangePage}
      />
      <FooterActionsWrapper>
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <MPButton
              disabled={selectedSportsmen.length === 0}
              onClick={() => onSelect(sportUserProfiles)}
            >
              {buttonText}
            </MPButton>
          </Grid>
        </Grid>
      </FooterActionsWrapper>
    </div>
  );
});

export default SportsmanSearchFooterContainer;
