import { Location } from 'history';
import { Redirect, Switch, useParams } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { EOrderPartition } from '../../../../../domain/model/enums';
import { UUID } from '../../../../../domain/model/types';
import { MpUser } from '../../../../../domain/model/user';
import ForbiddenComponent from '../../../../components/common/forbidden';
import CustomerManagementBookingOrdersScreen from '../../../../screens/customerManagement/bookingOrders';
import CustomerManagementCorpActivationsScreen from '../../../../screens/customerManagement/corpActivations';
import CustomerManagementScreen from '../../../../screens/customerManagement/customer';
import CustomerManagementOrderScreen from '../../../../screens/customerManagement/order';
import CustomerManagementProductOrdersScreen from '../../../../screens/customerManagement/productOrders';
import CustomerManagementTradeActivationsScreen from '../../../../screens/customerManagement/tradeActivations';
import NotFoundScreen from '../../../../screens/notFound';
import { CorpActivationsLocationState } from '../../../corpActivation/entry';
import { OrdersLocationState } from '../../../order/entry';
import { TradeActivationsLocationState } from '../../../tradeActivation/entry';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import CustomerManagementCommonContainer from './index';

const partitionPathParam = ':partition' as const;

const routing = {
  root: '/customers/:id/management',
  details: '/customers/:id/management/details',
  orders: `/customers/:id/management/orders/${partitionPathParam}`,
  order: '/customers/:customerId/management/orders/:orderId',
  tradeActivations: `/customers/:id/management/trade-activations`,
  corpActivations: `/customers/:id/management/corp-activations`,
};

interface GetCustomerManagementDetailsRouteProps {
  readonly id: UUID;
}

interface GetCustomerManagementOrdersRouteProps {
  readonly id: UUID;
  readonly partition: EOrderPartition;
  readonly guid?: UUID;
}

interface GetCustomerManagementTradeActivationsRouteProps {
  readonly id: UUID;
  readonly guid?: UUID;
}

export const getCustomerManagementTradeActivationsRoute = (
  props: GetCustomerManagementTradeActivationsRouteProps
): Location<TradeActivationsLocationState> => {
  const { id, guid } = props;

  return {
    pathname: `${routing.tradeActivations.replace(':id', id)}`,
    search: '',
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

interface GetCustomerManagementCorpActivationsRouteProps {
  readonly id: UUID;
  readonly guid?: UUID;
}

export const getCustomerManagementCorpActivationsRoute = (
  props: GetCustomerManagementCorpActivationsRouteProps
): Location<CorpActivationsLocationState> => {
  const { id, guid } = props;

  return {
    pathname: `${routing.corpActivations.replace(':id', id)}`,
    search: '',
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

interface GetCustomerManagementOrderRouteProps {
  readonly orderId: UUID;
  readonly customerId: UUID;
}

export const getCustomerManagementRootRouteDefinition = () => routing.root;

export const getCustomerManagementDetailsRoute = ({ id }: GetCustomerManagementDetailsRouteProps) => {
  return `${routing.details.replace(':id', id)}`;
};

export const getCustomerManagementOrdersRoute = (
  props: GetCustomerManagementOrdersRouteProps
): Location<OrdersLocationState> => {
  const { id, guid, partition } = props;

  return {
    pathname: partition
      ? `${routing.orders.replace(':id', id).replace(partitionPathParam, partition)}`
      : `${routing.orders.replace(':id', id)}`,
    search: '',
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

export const getCustomerManagementOrderRoute = ({ orderId, customerId }: GetCustomerManagementOrderRouteProps) => {
  return `${routing.order.replace(':customerId', customerId).replace(':orderId', orderId)}`;
};

interface CustomerManagementCommonEntryInternalProps {
  readonly customer: MpUser;
}

const CustomerManagementCommonEntryInternal = ({ customer }: CustomerManagementCommonEntryInternalProps) => {
  return (
    <Switch>
      <Route
        exact
        path={routing.orders.replace(partitionPathParam, EOrderPartition.ProductOffers)}
        render={() => <CustomerManagementProductOrdersScreen customer={customer} />}
      />
      <Route
        exact
        path={routing.orders.replace(partitionPathParam, EOrderPartition.BookingOffers)}
        render={() => <CustomerManagementBookingOrdersScreen customer={customer} />}
      />
      <Route
        exact
        path={routing.tradeActivations}
        render={() => <CustomerManagementTradeActivationsScreen customer={customer} />}
      />
      <Route
        exact
        path={routing.corpActivations}
        render={() => <CustomerManagementCorpActivationsScreen customer={customer} />}
      />
      <Route
        exact
        path={routing.details}
        render={() => <CustomerManagementScreen customer={customer} />}
      />
      <Route
        exact
        path={routing.order}
        render={() => <CustomerManagementOrderScreen customer={customer} />}
      />
      <Redirect
        from={routing.root}
        to={routing.details}
      />

      <Route component={NotFoundScreen} />
    </Switch>
  );
};

const CustomerManagementCommonEntry = () => {
  const { id } = useParams<{ id: UUID }>();
  const { customers } = useCurrentUser().accessMatrix;

  if (!customers?.view?.management) {
    return <ForbiddenComponent />;
  }

  return (
    <CustomerManagementCommonContainer id={id}>
      {customer => <CustomerManagementCommonEntryInternal customer={customer} />}
    </CustomerManagementCommonContainer>
  );
};

export default CustomerManagementCommonEntry;
