import { Fade, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENoticeStatus } from '../../../../domain/model/enums';
import { UUID } from '../../../../domain/model/types';
import { MpUserData } from '../../../../domain/model/user';
import Notifier from '../../../../system/notifier';
import MasterActionsComponent from '../../../components/common/actions/master';
import useNavAdapter from '../../../components/common/actions/navAdapter/hooks';
import AppBreadcrumbs from '../../../components/common/breadcrumbs';
import DefaultHeader from '../../../components/common/header';
import ContentLoader from '../../../components/common/loader';
import { DefaultContentWrapper } from '../../../components/common/wrappers/content';
import { DefaultFooterWrapper } from '../../../components/common/wrappers/footer';
import useHistoryExtensions from '../../../hooks/useHistoryExtensions';
import useValidation from '../../../hooks/validation/useValidation';
import { EPanelActionPosition, OnChangeObjectAttribute, PanelAction, PanelActions } from '../../../types';
import StepperContainer from '../../general/stepper/container';
import { getPartnerEmployeesTableRoute } from '../entry';
import { EPartnerEmployeeTableTab } from '../table/utils';
import { EPartnerEmployeeActionType, PartnerEmployeeActionEditType } from '../types';
import { EPartnerEmployeeStep } from '../utils';
import PartnerEmployeeCreate from './component';
import { ContainerWrapper, ContentContainer, LoaderWrapper, TitleWrapper } from './controls';
import { partnerEmployeeCreateSaveSelector, partnerEmployeeCreateSelector } from './store/selectors';
import {
  partnerEmployeeCreateInit,
  partnerEmployeeCreateReset,
  partnerEmployeeCreateSave,
  partnerEmployeeCreateSetAttribute,
} from './store/slice';
import { getPartnerEmployeeCreateStepText, partnerEmployeeCreateProfileStepValidation } from './utils';

interface PartnerEmployeeCreateContainerProps {
  readonly partnerId: UUID;
  readonly step: EPartnerEmployeeStep.Profile;
}

const validationRulesProfile = partnerEmployeeCreateProfileStepValidation;

const PartnerEmployeeCreateContainer = (props: PartnerEmployeeCreateContainerProps) => {
  const { partnerId, step } = props;

  const dispatch = useDispatch();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const [validateOnChangeProfile, setValidateOnChangeProfile] = useState<boolean>(false);

  const { data: partnerEmployee } = useSelector(partnerEmployeeCreateSelector);
  const { isFetching: isSaving, isFetched: isSaved } = useSelector(partnerEmployeeCreateSaveSelector);

  const {
    validationResult: validationResultProfile,
    isValid: isValidProfile,
    validate: validateProfile,
  } = useValidation<MpUserData>({
    object: partnerEmployee,
    validateOnChange: validateOnChangeProfile,
    rules: validationRulesProfile,
  });

  const steps: EPartnerEmployeeStep.Profile[] = [EPartnerEmployeeStep.Profile];
  const actions: PanelActions<PartnerEmployeeActionEditType> = [
    {
      type: EPartnerEmployeeActionType.Save,
      label: 'Добавить',
      primary: true,
      position: [EPanelActionPosition.Default],
    },
  ];

  const onPartnerEmployeeSave = () => {
    if (partnerEmployee) {
      const isValidObject = validateProfile();

      if (!isValidObject) {
        setValidateOnChangeProfile(true);
      } else {
        dispatch(partnerEmployeeCreateSave({ partnerId, partnerEmployee }));
      }
    }
  };

  const onChangeAttribute: OnChangeObjectAttribute<MpUserData> = (name, value) => {
    dispatch(partnerEmployeeCreateSetAttribute({ name, value }));
  };

  const onNextStep = () => {
    if (partnerEmployee) {
      const index = steps.indexOf(step);
      if (index < steps.length - 1) {
        switch (step) {
          case EPartnerEmployeeStep.Profile: {
            const isValidObject = validateProfile();
            if (!isValidObject) {
              setValidateOnChangeProfile(true);
              return;
            }
            break;
          }
          default: {
            console.error(`Unknown ETradeOfferStep ${step}`);
            return;
          }
        }
      }
    }
  };

  const onPanelAction = (action: PanelAction<PartnerEmployeeActionEditType>) => {
    const { type } = action;
    switch (type) {
      case EPartnerEmployeeActionType.Save:
        onPartnerEmployeeSave();
        break;
    }
  };

  const onClose = useCallback(() => {
    gotoPrevIndependentLocation(getPartnerEmployeesTableRoute({ tab: EPartnerEmployeeTableTab.Active }));
  }, [gotoPrevIndependentLocation]);

  useEffect(() => {
    if (isSaved) {
      Notifier.getInstance().addNotice(ENoticeStatus.Success, `Сотрудник добавлен`);
      onClose();
    }
  }, [isSaved, onClose]);

  useEffect(() => {
    dispatch(partnerEmployeeCreateInit(partnerId));
    return () => {
      dispatch(partnerEmployeeCreateReset());
    };
  }, [dispatch, partnerId]);

  const activeStepIndex = steps.indexOf(step);

  const mpSteps = steps.map(item => ({
    key: item,
    label: getPartnerEmployeeCreateStepText(item),
  }));
  const mpStep = mpSteps.find(item => item.key === step) ?? {
    key: EPartnerEmployeeStep.Profile,
    label: getPartnerEmployeeCreateStepText(EPartnerEmployeeStep.Profile),
  };

  const { adapter: navAdapter, actions: navActions } = useNavAdapter({
    openPrevStep: () => {},
    currentStepIndex: activeStepIndex,
    openNextStep: onNextStep,
    stepsCount: steps.length,
  });

  const actionsPanel = (
    <MasterActionsComponent<PartnerEmployeeActionEditType>
      actions={actions}
      navAdapter={navAdapter}
      wrapper={DefaultFooterWrapper}
      show={!!actions.length || !!navActions.length}
      onAction={onPanelAction}
    />
  );

  return (
    <Fade in>
      <ContainerWrapper>
        <StepperContainer<EPartnerEmployeeStep>
          step={mpStep}
          steps={mpSteps}
        />
        <ContentContainer>
          <DefaultContentWrapper
            type='details'
            stickyHeader
            fullHeight
            footer={actionsPanel}
          >
            <DefaultHeader
              sticky
              headline={<AppBreadcrumbs />}
              onClose={onClose}
            >
              <TitleWrapper>
                <Typography variant='h2'>Добавление сотрудника</Typography>
              </TitleWrapper>
            </DefaultHeader>
            {partnerEmployee && (
              <PartnerEmployeeCreate
                step={step}
                partnerEmployee={partnerEmployee}
                isValidProfile={isValidProfile}
                validationProfile={validationResultProfile}
                onChangeAttribute={onChangeAttribute}
              />
            )}
          </DefaultContentWrapper>
        </ContentContainer>
        {isSaving && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
      </ContainerWrapper>
    </Fade>
  );
};

export default PartnerEmployeeCreateContainer;
