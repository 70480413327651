import { combineReducers } from '@reduxjs/toolkit';
import legalAgreements, { AgreementsState as LegalAgreementsState } from './store/slice';

interface AgreementsState {
  legalAgreements: LegalAgreementsState;
}

export default combineReducers<AgreementsState>({
  legalAgreements,
});
