import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../data/store/types';
import { AddressPosition } from '../../../../../domain/model/address';
import { Team, TeamMember } from '../../../../../domain/model/team';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { SportUserProfileShort } from '../../../../../domain/model/user';
import { teamLogoSelect } from '../../logos/store/slice';

const emptyTeamPosition: AddressPosition = {
  lat: 0,
  lon: 0,
};

export const teamCreateSave = createAsyncThunk<Team, { team: Team }, AppThunkAPIConfig>(
  'team/create/save',
  async ({ team }, { rejectWithValue }) => {
    try {
      const { data } = await Api.team.save(team);
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface TeamCreateState {
  readonly guid: Nullable<UUID>;
  readonly create: {
    readonly data: Team;
    readonly validationErrors: { [key: string]: string } | {};
  };
  readonly save: Fetchable;
}

type Reducer<T> = CaseReducer<TeamCreateState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<TeamCreateState> {
  teamCreateStartSession: Reducer<Nullable<UUID>>;
  teamCreateSetAttribute: Reducer<{ name: keyof Team; value: any }>;
  teamCreateSetValidationErrors: Reducer<{ errors: { [field: string]: any } }>;
  teamCreateSetCapitan: Reducer<Nullable<SportUserProfileShort>>;
}

const slice = createSlice<TeamCreateState, Reducers, 'team/create'>({
  name: 'team/create',
  initialState: {
    guid: null,
    create: {
      data: {} as Team,
      validationErrors: {},
    },
    save: {
      ...fetchableDefault,
    },
  },
  reducers: {
    teamCreateStartSession: (state, { payload }) => {
      if (state.guid !== payload) {
        state.create.data = {} as Team;
        state.create.validationErrors = {};

        state.save = { ...fetchableDefault };
      }

      state.guid = payload;
    },
    teamCreateSetAttribute: (state, { payload }) => {
      const { name, value } = payload;

      (state.create.data[name] as keyof Team) = value;

      if (name === 'locality') {
        const position = state.create.data?.locality?.position;
        if (position) {
          state.create.data.location = {
            lat: position.lat,
            lon: position.lon,
          };
        } else {
          state.create.data.location = emptyTeamPosition;
        }
      }
    },
    teamCreateSetCapitan: (state, { payload }) => {
      if (payload) {
        const userProfile = payload;

        state.create.data.captain = {
          id: uuid(),
          userProfile,
        } as TeamMember;
      }
    },
    teamCreateSetValidationErrors: (state, { payload }) => {
      const { errors } = payload;

      state.create.validationErrors = errors;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(teamCreateSave.pending, state => {
        state.save = fetchableFetching;
      })
      .addCase(teamCreateSave.fulfilled, (state, { payload }) => {
        state.save = fetchableFetched;
        state.create.data = payload;
      })
      .addCase(teamCreateSave.rejected, state => {
        state.save = fetchableFailed;
      })
      .addCase(teamLogoSelect, (state, { payload }) => {
        const { selected } = payload;
        state.create.data.logo = selected;
      });
  },
});

export const { teamCreateStartSession, teamCreateSetAttribute, teamCreateSetValidationErrors, teamCreateSetCapitan } =
  slice.actions;

export default slice.reducer;
