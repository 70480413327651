import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Partner } from '../../../domain/model/partner';
import { MpUser } from '../../../domain/model/user';
import ForbiddenComponent from '../../components/common/forbidden';
import { viewShowCustomerManagementByPartnerMenu } from '../../features/customer/management/byPartner/store/slice';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import { TradeActivationsLocationState } from '../../features/tradeActivation/entry';
import TradeActivationTableEntry from '../../features/tradeActivation/table/entry';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface CustomerManagementTradeActivationsByPartnerScreenProps {
  readonly customer: MpUser;
  readonly partner: Partner;
}

const CustomerManagementTradeActivationsByPartnerScreen = ({
  customer,
  partner,
}: CustomerManagementTradeActivationsByPartnerScreenProps) => {
  const dispatch = useDispatch();
  const {
    accessMatrix: { tradeActivations },
  } = useCurrentUser();

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowCustomerManagementByPartnerMenu());
  }, [dispatch]);

  if (!tradeActivations.view) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<TradeActivationsLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <TradeActivationTableEntry
          guid={state.guid}
          partnerId={partner.id}
          customerId={customer.id}
        />
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default CustomerManagementTradeActivationsByPartnerScreen;
