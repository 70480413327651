import BookingOrderTableCell, { BookingOrderTableCellCommonProps } from './index';
import { ContentLimiter } from '../../../../components/common/contentLimit';
import { ContentLimiterTooltipLayout } from '../../../../components/common/contentLimit/layouts/showTooltipContent';

const BookingOrderTableCellManagerComment = (props: BookingOrderTableCellCommonProps) => {
  const {
    bookingOrder: { managerComment },
  } = props;

  return (
    <BookingOrderTableCell
      {...props}
      value={
        <ContentLimiter
          layout={ContentLimiterTooltipLayout}
          layoutProps={{ rows: 2 }}
        >
          {managerComment}
        </ContentLimiter>
      }
    />
  );
};

export default BookingOrderTableCellManagerComment;
