import styled from '@emotion/styled';
import { css, Typography } from '@mui/material';
import { HTMLAttributes } from 'react';
import { viewConfig } from '../../../../theme/viewConfig';
import { EEntityPreviewMode } from '../../../../types';

export const Footer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type CorpOfferPreviewWrapperProps = HTMLAttributes<HTMLDivElement> & {
  readonly mode: EEntityPreviewMode;
};

export const CorpOfferPreviewWrapper = styled(({ mode, children, ...props }: CorpOfferPreviewWrapperProps) => (
  <div {...props}>{children}</div>
))(
  ({ mode }) => css`
    width: ${mode === EEntityPreviewMode.Mobile
      ? viewConfig.corpOffer.preview.mobileWidth
      : viewConfig.corpOffer.preview.width};
  `
);

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 1.25rem;
  }
`;

export const Price = styled(Typography)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(0.5)};
  `
);

export const OriginalPrice = styled(Typography)(
  ({ theme }) => css`
    text-decoration: line-through;
    margin-left: ${theme.spacing()};
  `
);

export const DescriptionSkeletonWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    gap: ${theme.spacing(0.5)};
  `
);
