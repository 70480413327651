import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { EPartnerStatus } from '../../../../../domain/model/enums';
import { PartnerShortAny } from '../../../../../domain/model/partner';
import { EPartnerTableTab } from '../../../general/partner/types';
import {
  getPartnerApplicationDetailsRoute,
  getPartnerApplicationEditRoute,
  getPartnerApplicationVerificationRoute,
} from '../../../partnerApplication/entry';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { getPartnerManagementDetailsRoute } from '../../management/entry';

export type UsePartnersTableConfig = {
  readonly defaultTab: EPartnerTableTab;
  readonly tabsForCounts: EPartnerTableTab[];
  readonly onPartnerClick: (partner: PartnerShortAny) => void;
};

export function usePartnersTableConfig(): UsePartnersTableConfig {
  const history = useHistory();

  const {
    accessMatrix: {
      tradeOffers: { approve: canApprove, edit: canEdit },
    },
  } = useCurrentUser();

  const onPartnerClick = useCallback(
    (partner: PartnerShortAny) => {
      switch (partner.status) {
        case EPartnerStatus.OnVerification:
        case EPartnerStatus.ToVerification:
          if (canApprove) {
            history.push(getPartnerApplicationVerificationRoute({ id: partner.id }));
          } else {
            history.push(getPartnerApplicationDetailsRoute({ id: partner.id }));
          }
          break;
        // Черновики - только свои показываем
        case EPartnerStatus.Unverified:
          if (canEdit) {
            history.push(getPartnerApplicationEditRoute({ id: partner.id }));
          } else {
            history.push(getPartnerApplicationDetailsRoute({ id: partner.id }));
          }
          break;
        default:
          history.push(getPartnerManagementDetailsRoute({ id: partner.id }));
      }
    },
    [history]
  );

  const defaultTab = EPartnerTableTab.Verification;
  const tabsForCounts = Object.values(EPartnerTableTab);

  return {
    defaultTab,
    tabsForCounts,
    onPartnerClick,
  };
}
