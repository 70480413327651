import { CorpOffer, EDateFormat, TradeOffer } from '@/domain';
import { Grid } from '@mui/material';
import { MPButton } from '@ui-kit/button';
import { MPDatePicker } from '@ui-kit/picker';
import moment from 'moment-timezone';
import { useState } from 'react';
import ConfirmDialog from '../../../../../components/common/dialogs/confirm';
import ContentLoader from '../../../../../components/common/loader';

type CorpOrTradeOffer = TradeOffer | CorpOffer;

interface OfferRetrieveDialogProps<T extends CorpOrTradeOffer> {
  readonly offer: T;
  readonly title?: string;
  readonly actionText?: string;
  readonly isExecuting: boolean;
  readonly onExecute: (offer: T, endDate: string) => void;
  readonly onClose: () => void;
}

const getErrorMessage = (startDate: Nullable<string>, endDate: Nullable<string>): Nullable<string> => {
  if (endDate === null) {
    return 'Укажите дату';
  }
  if (moment(endDate).isBefore(startDate)) {
    return 'Дата окончания меньше даты начала';
  }
  if (moment(endDate).isBefore(moment(), 'day')) {
    return 'Укажите дату больше или равной текущей';
  }
  return null;
};

const OfferRetrieveDialog = <T extends CorpOrTradeOffer>(props: OfferRetrieveDialogProps<T>) => {
  const {
    offer,
    title = 'Дата окончания публикации',
    actionText = 'Восстановить',
    isExecuting,
    onExecute,
    onClose,
  } = props;

  const [localDate, setLocalDate] = useState<Nullable<string>>(
    offer.endDate && moment(offer.endDate).format(EDateFormat.Server)
  );
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(getErrorMessage(offer.startDate, offer.endDate));

  const onChangeDate = (newDate: Nullable<string>) => {
    setLocalDate(newDate);
    setErrorMessage(getErrorMessage(offer.startDate, newDate));
  };

  const onInternalAction = () => {
    if (localDate) {
      onExecute(offer, localDate);
    }
  };

  return (
    <ConfirmDialog
      open={true}
      onClose={onClose}
      title={title}
      dialogButtons={
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              disabled={!!errorMessage || isExecuting}
              onClick={onInternalAction}
            >
              {actionText}
              {isExecuting && <ContentLoader />}
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              disabled={isExecuting}
              fullWidth={false}
              onClick={onClose}
            >
              Отменить
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        spacing={2}
        wrap='nowrap'
        direction='column'
      >
        <Grid item>
          <MPDatePicker
            value={localDate}
            label='Окончание'
            format={EDateFormat.Human}
            error={!!errorMessage}
            helperText={errorMessage}
            onChange={date => {
              const d = date === null ? null : moment(date).format(EDateFormat.Server);
              onChangeDate(d);
            }}
          />
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
};

export default OfferRetrieveDialog;
