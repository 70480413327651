import { MpUser, Nullable, UUID } from '@/domain';
import { CustomersTableConfigContext } from '@features/customer/table/context';
import {
  customerTableFilterItems,
  ECustomersFilterItem,
  getCustomerTableFilterStrategy,
} from '@features/customer/table/filterUtils';
import { useCustomerTableConfig } from '@features/customer/table/hooks/useTableConfig';
import { useMemo } from 'react';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import CustomersTableContainer from './container';
import { ECustomerTableColumn, ECustomerTableTab, getCustomerTableColumns } from './utils';

interface CustomerTableEntryProps {
  readonly guid: UUID;
  readonly tab?: Nullable<ECustomerTableTab>;
  readonly partnerId?: Nullable<UUID>;
  readonly onCustomerClick: (customer: MpUser) => void;
}

const CustomerTableEntry = ({ guid, tab, onCustomerClick }: CustomerTableEntryProps) => {
  const {
    accessMatrix: { customers },
  } = useCurrentUser();

  const config = useCustomerTableConfig({});
  const { defaultTab } = config;

  const outTab = tab ?? defaultTab;

  // получаем максимально возможный состав колонок
  const sourceColumns = useMemo(() => getCustomerTableColumns(outTab), [outTab]);

  // готовим адаптер для хука
  const adapter = useMemo<DataFilterAdapter<ECustomerTableColumn, ECustomersFilterItem>>(
    () => ({
      key: 'customers',
      keyPartitions: [outTab],
      sourceColumns,
      filterItems: customerTableFilterItems,
      requiredFilters: [ECustomersFilterItem.Query],
      getDataFilterStrategy: (filterItem, values) => getCustomerTableFilterStrategy(filterItem, values),
    }),
    [outTab, sourceColumns]
  );

  return (
    <CustomersTableConfigContext.Provider value={config}>
      <CustomersTableContainer
        guid={guid}
        tab={outTab}
        filterAdapter={adapter}
        canChangeStatus={customers.changeStatus}
        onCustomerClick={onCustomerClick}
      />
    </CustomersTableConfigContext.Provider>
  );
};

export default CustomerTableEntry;
