import { UseBannersTable } from '@features/banner/table/hooks/useTable';
import { UseBannerTableConfig } from '@features/banner/table/hooks/useTableConfig';
import React from 'react';

export type BannersTableContextValue = UseBannersTable;

export const BannersTableContext = React.createContext<BannersTableContextValue>({} as BannersTableContextValue);

export type BannersTableConfigContextValue = UseBannerTableConfig;

export const BannersTableConfigContext = React.createContext<BannersTableConfigContextValue>(
  {} as BannersTableConfigContextValue
);
