import { Banner, EBannerPartition } from '@/domain';
import { EBannerTableFilterItem } from '@features/banner/table/filterUtils';
import { useContextConfig } from '@features/banner/table/hooks/useContextConfig';
import {
  BannerActions,
  BannerActionTableCommonType,
  BannerActionTableType,
  EBannerActionType,
} from '@features/banner/types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import BannerTable from '../component';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  bannersActionsSelector,
  bannersBannersSelector,
  bannersFilterSelector,
  bannersGuidSelector,
  bannersSortColumnSelector,
  bannersSortDirectionSelector,
} from '../store/selectors';
import { BannerActionCall, BannerTableActionProcess, EBannerTableColumn } from '../utils';

interface BannerTableAdapterProps {
  readonly partition: EBannerPartition;
  readonly filterAdapter: DataFilterAdapter<EBannerTableColumn, EBannerTableFilterItem>;
  readonly tabActions: BannerActions<BannerActionTableType>;
}

export const BannerTableAdapter = (props: BannerTableAdapterProps) => {
  const { partition, filterAdapter, tabActions } = props;

  const handlers = useContextHandlers();
  const { onBannerClick } = useContextConfig();

  const guid = useSelector(bannersGuidSelector(partition));
  const banners = useSelector(bannersBannersSelector(partition));
  const actionProcesses = useSelector(bannersActionsSelector(partition));

  const sortColumn = useSelector(bannersSortColumnSelector(partition));
  const sortDirection = useSelector(bannersSortDirectionSelector(partition));
  const filter = useSelector(bannersFilterSelector(partition));

  const onBannerAction = (call: BannerActionCall) => {
    const { action, banner } = call;
    switch (action) {
      case EBannerActionType.Pause:
        handlers.onBannerPause(banner);
        break;
      case EBannerActionType.Resume:
        handlers.onBannerResume(banner);
        break;
      case EBannerActionType.Archive:
        handlers.onBannerArchive(banner);
        break;
      case EBannerActionType.ChangeSortIndex:
        handlers.onBannerChangeSortIndex(banner, call.sortIndex);
        break;
    }
  };

  const getActionProcesses = (banner: Banner): BannerTableActionProcess[] => {
    return actionProcesses.filter(p => p.id === banner.id) ?? [];
  };

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  const getBannerActions = useCallback(
    (banner: Banner) => {
      const disabled = handlers.isBannerLockedNotByMe(banner);
      return tabActions.map(action => ({ ...action, disabled }));
    },
    [tabActions, handlers.isBannerLockedNotByMe]
  );

  const getBannerCommonActions = useCallback(
    (banner: Banner) => {
      return getBannerActions(banner).filter(
        action => action.type !== EBannerActionType.ChangeSortIndex
      ) as BannerActions<BannerActionTableCommonType>;
    },
    [getBannerActions]
  );

  return (
    <BannerTable
      partition={partition}
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      banners={banners}
      getActions={getBannerActions}
      onRequestSort={handlers.onChangeSort}
      getActionProcesses={getActionProcesses}
      onChangeMetadata={onChangeMetadata}
      onBannerAction={onBannerAction}
      getCommonActions={getBannerCommonActions}
      onBannerClick={onBannerClick}
    />
  );
};