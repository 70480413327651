import { Link } from '@mui/material';
import { LinkProps } from '@mui/material/Link/Link';
import { FCC } from 'react';
import useBusinessConfig from '../../../hooks/useBusinessConfig';

type SupportTermsOfUseLinkContainerProps = LinkProps;

const SupportTermsOfUseLinkContainer: FCC<SupportTermsOfUseLinkContainerProps> = ({ children, ...others }) => {
  const { supportInfo } = useBusinessConfig();
  return (
    <Link
      target='_blank'
      href={supportInfo.termsOfUseUrl}
      color='primary'
      {...others}
    >
      {children ?? 'Пользовательское соглашение'}
    </Link>
  );
};

export default SupportTermsOfUseLinkContainer;
