import { ServerErrorResponse } from '@/data/network/types';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { CorpOfferActivation } from '@/domain/model/activation';
import { Nullable, UUID } from '@/domain/model/types';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import corpActivationServices from '../../services';
import { ECorpActivationActionType } from '../../types';

export type CorpActivationActionsState = {
  readonly actions: (Fetchable & {
    id: UUID;
    type: ECorpActivationActionType;
    error: Nullable<ServerErrorResponse>;
  })[];
};

const getActionProcess = (state: CorpActivationActionsState, id: UUID, actionType: ECorpActivationActionType) => {
  let process = state.actions.find(change => change.id === id);
  if (process) return process;

  process = {
    ...fetchableDefault,
    id,
    type: actionType,
    error: null,
  };
  state.actions.push(process);

  return process;
};

type Reducer<T = undefined> = CaseReducer<CorpActivationActionsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CorpActivationActionsState> {
  corpActivationActionsOptimize: Reducer;
}

export const corpActivationActionsUnGive = createAsyncThunk<
  CorpOfferActivation,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('corpActivation/actions/unGive', async ({ id }, { rejectWithValue }) => {
  try {
    return await corpActivationServices.activation.unGive(id);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpActivationActionsGive = createAsyncThunk<
  CorpOfferActivation,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('corpActivation/actions/give', async ({ id }, { rejectWithValue }) => {
  try {
    return await corpActivationServices.activation.give(id);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

const slice = createSlice<CorpActivationActionsState, Reducers, 'corpActivations/actions'>({
  name: 'corpActivations/actions',
  initialState: {
    actions: [],
  },
  reducers: {
    corpActivationActionsOptimize: state => {
      // Оставляем только выполняющиеся действия
      state.actions = state.actions.filter(action => action.isFetching);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(corpActivationActionsUnGive.pending, (state, { meta }) => {
        const { id } = meta.arg;
        const actionType = ECorpActivationActionType.UnGive;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(corpActivationActionsUnGive.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECorpActivationActionType.UnGive;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(corpActivationActionsUnGive.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = ECorpActivationActionType.UnGive;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(corpActivationActionsGive.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECorpActivationActionType.Give;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(corpActivationActionsGive.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECorpActivationActionType.Give;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(corpActivationActionsGive.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = ECorpActivationActionType.Give;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      });
  },
});

export const { corpActivationActionsOptimize } = slice.actions;

export default slice.reducer;
