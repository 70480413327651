import { Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { SportOptionTyped } from '../../../../../../domain/model';
import { EOfferType } from '../../../../../../domain/model/enums';
import { Nullable } from '../../../../../../domain/model/types';
import SectionTitle from '../../../../../components/common/sectionTitle';
import ValidationErrorPanel from '../../../../../components/common/validation/errorPanel';
import { MPMenuItem } from '../../../../../theme/ui-kit/menu/item';
import { MPSelect } from '../../../../../theme/ui-kit/select';
import CmsContainerOfferTypeObserver from '../../../components/offerTypeObserver';
import { CmsContainerCreateComponentProps } from '../types';
import CmsContainerEditComponentOfferIconVisible from './offerIconVisible';

type CmsContainerEditComponentOfferTypeVariantProps = CmsContainerCreateComponentProps;

type CmsContainerEditComponentOfferTypeProps = CmsContainerEditComponentOfferTypeVariantProps;

const CmsContainerEditComponentOfferType = (props: CmsContainerEditComponentOfferTypeProps) => {
  const { configuration, cmsContainer, validation, dictionaries, onChange } = props;

  const [offerType, setOfferType] = useState<Nullable<SportOptionTyped<EOfferType>>>(null);

  useEffect(() => {
    const selectedOfferType = dictionaries.offerTypes.find(ot => ot.id === cmsContainer?.offerType);

    setOfferType(selectedOfferType ?? null);
  }, [cmsContainer.offerType]);

  const onChangeOfferType = useCallback(
    (newOfferType: Nullable<EOfferType>) => onChange('offerType', newOfferType),
    [onChange]
  );

  return (
    <>
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          xs
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <SectionTitle gutterBottom={false}>Вид предложения</SectionTitle>
          </Grid>
          {configuration.canChangeOfferIconVisible && (
            <Grid item>
              <CmsContainerEditComponentOfferIconVisible {...props} />
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
        >
          <MPSelect<SportOptionTyped<EOfferType>>
            value={offerType ?? ''}
            label='Вид предложения'
            error={validation?.offerType?.hasError}
            helperText={validation?.offerType?.message}
            onChange={ot => setOfferType(ot)}
          >
            {dictionaries.offerTypes.map(ot => (
              <MPMenuItem
                key={ot.id}
                value={ot}
              >
                <Typography variant='body1'>{ot.name}</Typography>
              </MPMenuItem>
            ))}
          </MPSelect>
        </Grid>
        {validation?.showIcon?.hasError && (
          <Grid item>
            <ValidationErrorPanel text={validation?.showIcon?.message} />
          </Grid>
        )}
      </Grid>
      <CmsContainerOfferTypeObserver
        displayValue={offerType}
        currentValue={cmsContainer?.offerType}
        onRestore={() => {
          setOfferType(dictionaries.offerTypes.find(ot => ot.id === cmsContainer?.offerType) ?? null);
        }}
        onChange={onChangeOfferType}
      />
    </>
  );
};

export default CmsContainerEditComponentOfferType;
