import { UUID } from 'domain/model/types';
import useAnalyticsQueriesTableSearch from '../hooks/useSearch';
import useAnalyticsQueriesTableSession from '../hooks/useSession';
import { EAnalyticsQueriesTableTab } from '../utils';

interface AnalyticsQueriesBehaviorAdapterProps {
  readonly guid: UUID;
  readonly tab: EAnalyticsQueriesTableTab;
}

export const AnalyticsQueriesBehaviorAdapter = ({ guid, tab }: AnalyticsQueriesBehaviorAdapterProps) => {
  useAnalyticsQueriesTableSession({ guid, tab });
  useAnalyticsQueriesTableSearch({ tab });

  return null;
};
