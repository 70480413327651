import { Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseButton from '../buttons/close';
import { BackIconButton, ContentWrapper, Title, TitleWrapper, Wrapper } from './controls';

interface SideContentWrapperProps {
  title?: any;
  children?: any;
  onClose: false | (() => void);
  onBack: () => void;
}

const SideContentWrapper = ({ children, onClose, onBack, title }: SideContentWrapperProps) => {
  const RenderHeaderComponent = () => <Title>{title}</Title>;

  return (
    <Wrapper>
      <TitleWrapper>
        {onBack && (
          <BackIconButton onClick={onBack}>
            <ArrowBackIcon />
          </BackIconButton>
        )}
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid
            item
            xs={10}
          >
            <RenderHeaderComponent />
          </Grid>
          <Grid item>{onClose && <CloseButton onClick={onClose} />}</Grid>
        </Grid>
      </TitleWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};

export default SideContentWrapper;
