import { AdCampaignCreate, BookingOfferShort, EOfferStatus, EOfferType } from '@/domain';
import { NumberRange } from '@/presentation/types';
import TopPushDialog from '@components/common/dialogs/topPush';
import AdCampaignsOfferCollectionComponent from '@features/adCampaign/components/offerCollection';
import useAdCampaignCollectionOffer from '@features/adCampaign/create/collection/useChangeOffers';
import { getBookingOfferDetailsRoute } from '@features/bookingOffer/entry';
import BookingOfferSearchContainer from '@features/bookingOffer/search/container';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import useCurrentUser from '@features/user/hooks/useCurrentUser';
import { viewConfig } from '@theme/viewConfig';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getAdCampaignSearchBookingOfferPresetValues } from '../../utils';
import useAdCampaignCollectionBookingOfferFilterAdapter from './useFilterAdapter';

interface AdCampaignBookingOfferCollectionContainerProps {
  readonly guid: UUID;
  readonly offers: BookingOfferShort[];
  readonly statuses?: EOfferStatus[];
  readonly adCampaign: AdCampaignCreate;
  readonly selectedMaxCount?: NumberRange<2, 100> | false;
  readonly onChange: (offers: BookingOfferShort[]) => void;
}

const AdCampaignBookingOfferCollectionContainer = (props: AdCampaignBookingOfferCollectionContainerProps) => {
  const { guid, offers, statuses, adCampaign, selectedMaxCount, onChange } = props;

  const history = useHistory();
  const {
    accessMatrix: {
      bookingOffers: { viewAsAdmin = false },
    },
  } = useCurrentUser();

  const { partnerTypes } = useSelector(nsiDataSelector);

  const filterPresetValues = useMemo(() => getAdCampaignSearchBookingOfferPresetValues(adCampaign), [adCampaign]);

  const {
    isSearchDialogOpened,
    searchDialogParams,
    onOpenSearchDialog,
    onCloseSearchDialog,
    onChangeSearchDialogTab,
    onSelect,
    onClear,
    onUnselect,
  } = useAdCampaignCollectionOffer<BookingOfferShort>({
    offerType: EOfferType.Booking,
    selected: offers,
    onChange,
  });

  const filterAdapter = useAdCampaignCollectionBookingOfferFilterAdapter({
    viewAsAdmin,
    tab: searchDialogParams.dialog_tab,
    partnerId: null,
    values: filterPresetValues,
    partnerTypes,
  });

  const onOfferClick = useCallback(
    (id: UUID) => {
      history.push(getBookingOfferDetailsRoute({ id }));
    },
    [history]
  );

  return (
    <>
      <AdCampaignsOfferCollectionComponent
        offers={offers}
        offerType={EOfferType.Booking}
        onShowOfferSearch={onOpenSearchDialog}
        onClearOffers={onClear}
        onRemoveOffer={onUnselect}
      />
      <TopPushDialog
        fullScreen
        stickyHeader
        open={isSearchDialogOpened}
        title='Выбор объектов досуга и отдыха'
        leftOffset={viewConfig.stepper.width}
        onClose={onCloseSearchDialog}
      >
        <BookingOfferSearchContainer
          guid={searchDialogParams?.dialog_guid ?? guid}
          partnerId={null}
          statuses={statuses}
          filterAdapter={filterAdapter}
          initialSelected={offers}
          selectedMaxCount={selectedMaxCount}
          tab={searchDialogParams.dialog_tab}
          onBookingOfferClick={bookingOffer => onOfferClick(bookingOffer.id)}
          onSelect={onSelect}
          onChangeTableTab={onChangeSearchDialogTab}
        />
      </TopPushDialog>
    </>
  );
};

export default AdCampaignBookingOfferCollectionContainer;
