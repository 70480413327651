import OfferFieldHistoryTableCell, { OfferFieldHistoryTableCellCommonProps } from './index';

const OfferFieldHistoryTableCellNewValue = (props: OfferFieldHistoryTableCellCommonProps) => {
  const {
    fieldHistory: { newValue },
  } = props;

  const value = newValue ?? undefined;

  return (
    <OfferFieldHistoryTableCell
      {...props}
      value={value}
    />
  );
};

export default OfferFieldHistoryTableCellNewValue;
