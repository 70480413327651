import { CorpOffer, EOfferStatus, Nullable, UUID } from '@/domain';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import TableCommonLayout from '../../../layouts/tableCommon/container';
import { NumberRange } from '../../../types';
import { EOfferTableTab } from '../../general/offer/types';
import { ECorpOfferTableFilterItem } from '../filterUtils';
import { ECorpOfferTableColumn } from '../types';
import CorpOffersSearchFilterAdapter from './adapters/filter';
import CorpOffersSearchTableAdapter from './adapters/table';
import { CorpOffersSearchTabsAdapter } from './adapters/tabs';
import { CorpOffersSearchConfigContext, CorpOffersSearchContext } from './context';
import CorpOfferSearchFooterContainer from './footerContainer';
import { useCorpOffersSearchHandlers } from './hooks/useCorpOffersSearch';
import { useCorpOffersSearchConfig } from './hooks/useCorpOffersSearchConfig';
import useCorpOffersSearchSearch from './hooks/useSearch';
import useCorpOffersSearchSession from './hooks/useSession';
import { corpOffersSearchGet, corpOffersSearchGuidSelector } from './store/selectors';

interface CorpOfferSearchContainerSingleProps {
  readonly initialSelected: Nullable<CorpOffer>;
  readonly selectedMaxCount: 1;
  readonly onSelect: (offer: CorpOffer) => void;
}

interface CorpOfferSearchContainerMultipleProps {
  readonly initialSelected: Nullable<CorpOffer[]>;
  readonly selectedMaxCount?: NumberRange<2, 100> | false;
  readonly onSelect: (offer: CorpOffer[]) => void;
}

type CorpOfferSearchContainerProps = {
  readonly guid: UUID;
  readonly tab: Nullable<EOfferTableTab>;
  readonly partnerId: Nullable<UUID>;
  readonly statuses?: EOfferStatus[];
  readonly filterAdapter: DataFilterAdapter<ECorpOfferTableColumn, ECorpOfferTableFilterItem>;
  readonly onCorpOfferClick: (corpOffer: CorpOffer) => void;
  readonly onChangeTableTab: (tab: EOfferTableTab) => void;
} & (CorpOfferSearchContainerMultipleProps | CorpOfferSearchContainerSingleProps);

const CorpOfferSearchContainer = (props: CorpOfferSearchContainerProps) => {
  const {
    guid,
    tab,
    partnerId,
    filterAdapter,
    statuses = [EOfferStatus.Active, EOfferStatus.Upcoming],
    onCorpOfferClick,
    onChangeTableTab,
  } = props;

  const currentGuid = useSelector(corpOffersSearchGuidSelector);
  const { tab: tableTab } = useSelector(corpOffersSearchGet);

  const currentTab = tab ?? tableTab;
  const isSingleSelect = props.selectedMaxCount === 1;
  const config = useCorpOffersSearchConfig({
    isSingleSelect,
    tab: currentTab,
    statuses,
    onCorpOfferClick,
    onChangeTableTab,
  });
  const handlers = useCorpOffersSearchHandlers(props);

  useCorpOffersSearchSession({
    ...props,
    tab: currentTab,
  });
  useCorpOffersSearchSearch({ guid, tab: currentTab, partnerId, statuses, tabsForCounts: config.tabsForCounts });

  if (guid !== currentGuid) {
    return null;
  }

  return (
    <CorpOffersSearchConfigContext.Provider value={config}>
      <CorpOffersSearchContext.Provider value={handlers}>
        <TableCommonLayout
          filter={<CorpOffersSearchFilterAdapter filterAdapter={filterAdapter} />}
          tabs={<CorpOffersSearchTabsAdapter tab={currentTab} />}
          table={<CorpOffersSearchTableAdapter filterAdapter={filterAdapter} />}
          footer={forwardRef((footerProps, ref: any) => (
            <CorpOfferSearchFooterContainer
              ref={ref}
              maxCount={props.selectedMaxCount}
              onSelect={handlers.onReturn}
            />
          ))}
        />
      </CorpOffersSearchContext.Provider>
    </CorpOffersSearchConfigContext.Provider>

    /*<Fade in>
      <DefaultContentWrapper
        type='table'
        fullHeight
        stickyHeader
        footer={
          <CorpOfferSearchFooter
            ref={footerRef}
            maxCount={selectedMaxCount}
            onSelect={onSelectInternal}
          />
        }
      >
        <ContainerWrapper>
          <Grid
            container
            spacing={2}
            direction='column'
          >
            <Grid item>
              <Splitter
                variant='horizontal'
                size={1}
              />
              <DataFilterComponent
                strategies={filterStrategies}
                onChange={onChangeFilter}
              />
            </Grid>
            <Grid item>
              <CorpOfferSearchTabs
                tab={currentTab}
                counter={tabsCounter}
                onChangeTab={onChangeTab}
              />
            </Grid>
          </Grid>
          <TableScrollbarOverlay bottomAnchor={footerRef.current}>
            <CorpOfferSearch
              metadata={metadata}
              sort={{ column: sortColumn, direction: sortDirection }}
              corpOffers={corpOffers}
              selectedCorpOffers={selectedCorpOffers}
              corpPromotionTypes={corpPromotionTypes}
              partnerTypes={partnerTypes}
              userGenders={userGenders}
              isFetching={isFetching}
              isSingleSelect={selectedMaxCount === 1}
              offerStatuses={offerStatuses}
              onRequestSort={onChangeSort}
              onCorpOfferClick={onCorpOfferClick}
              isCorpOfferDisabled={isCorpOfferDisabled}
              onCorpOfferSelect={onCorpOfferSelect}
              onChangeMetadata={onChangeMetadata}
              onAllCorpOffersSelect={onAllCorpOffersSelect}
            />
          </TableScrollbarOverlay>
        </ContainerWrapper>
      </DefaultContentWrapper>
    </Fade>*/
  );
};

export default CorpOfferSearchContainer;
