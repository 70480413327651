import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from './index';
import { EPartnerDiscriminator } from '../../../../../domain/model/enums';

const CorpOfferTableCellKpp = (props: CorpOfferTableCellCommonProps) => {
  const {
    corpOffer: {
      partner: { regInfo },
    },
  } = props;

  const value = regInfo.discriminator === EPartnerDiscriminator.PartnerCompanyData ? regInfo.kpp : null;

  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellKpp;
