import Axios from 'axios';
import Api from '../../../../data/api';
import { AllProps as ApiAllProps } from '../../../../data/api/event';
import { ApiCancellable, ApiRequestListDiscriminator } from '../../../../data/api/types';
import { Pageable } from '../../../../domain/model';
import { ESportEventParticipationFormat, ESportEventStatus } from '../../../../domain/model/enums';
import { AnySportEvent, SportEventShort } from '../../../../domain/model/event';
import { EEventTableFilterItem, EventTableFilterValues } from '../table/filterUtils';
import { ESportEventTableTab, getSportEventTableStatusesByTab, SportEventTableTabsCounter } from '../table/utils';
import { SportEventCounterByStatus } from '../types';

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly sort: string;
    readonly pageSize: number;
    readonly statuses: ESportEventStatus[];
  };
  readonly filter: EventTableFilterValues;
  readonly pageNumber: number;
};

export type CountProps = Omit<AllProps, 'pageNumber'>;

type CountsByStatusesProps = CountProps;

type CountsByTabsProps = CountProps & {
  readonly tabs: ESportEventTableTab[];
};

export type EventCommonService = {
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps
  ) => ApiAllProps<D>;
  readonly beforeSave: <T extends AnySportEvent>(props: T) => T;
  readonly all: (props: AllProps) => Promise<Pageable<SportEventShort>>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly countsByStatuses: (
    props: CountsByStatusesProps
  ) => Promise<{ counts: SportEventCounterByStatus; errors: Array<string> }>;
  readonly countsByTabs: (
    props: CountsByTabsProps
  ) => Promise<{ counts: SportEventTableTabsCounter; errors: Array<string> }>;
};

export const service: EventCommonService = {
  buildListQueryParams: props => {
    const { search, filter, signal, pageNumber } = props;
    const { pageSize, sort, statuses } = search;

    const name = filter[EEventTableFilterItem.Name]?.value;
    const activityTypes = filter[EEventTableFilterItem.ActivityTypes]?.value;
    const eventTypes = filter[EEventTableFilterItem.Type]?.value;
    const eventLevels = filter[EEventTableFilterItem.Level]?.value;
    const roads = filter[EEventTableFilterItem.Road]?.value;
    const addressObjectId = filter[EEventTableFilterItem.Locality]?.value?.id;
    const admin = filter[EEventTableFilterItem.Admin]?.value;
    const participationFormat = filter[EEventTableFilterItem.ParticipationFormat]?.value;

    return {
      page: pageNumber,
      pageSize,
      name,
      sort,
      statuses,
      activityTypes,
      eventTypes,
      eventLevels,
      roads,
      addressObjectId,
      admin,
      signal,
      participationFormat,
    };
  },
  beforeSave: event => {
    let result: typeof event = event;

    //корректируем заполненность атрибутов в зависимости от формата участия
    switch (result.participationFormat) {
      case null:
      case ESportEventParticipationFormat.Offline:
        result = {
          ...result,
          onlineParticipationLink: null,
          onlineParticipationDescription: null,
          onlineParticipationInstruction: null,
        };
        break;
      case ESportEventParticipationFormat.Online:
        result = {
          ...result,
          playground: null,
          locationDescription: null,
          locationImage: null,
        };
        break;
    }

    return result;
  },
  all: async props => {
    const { data: response } = await Api.event.all(service.buildListQueryParams(props));
    const { content: data, totalElements: totalCount, totalPages: pageCount, number: page } = response;

    return {
      data,
      totalCount,
      pageCount,
      pageNumber: page,
    };
  },
  count: async props => {
    const { data: response } = await Api.event.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      discriminator: ApiRequestListDiscriminator.Count,
    });

    return response.count;
  },
  countsByStatuses: async ({ signal, ...props }) => {
    const {
      search: { statuses },
    } = props;

    const errors: string[] = [];
    const counts: SportEventCounterByStatus = {};

    const requests = statuses.map(status => {
      const params = {
        ...props,
        search: { ...props.search, statuses: [status] },
      };
      return service.count({ ...params, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], status: ESportEventStatus) => {
      if (response.status === 'fulfilled') {
        counts[status] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество партнеров '${status}': ${response.reason}`);
        }
      }
    };

    statuses.forEach((status, index) => parseResponse(responses[index], status));

    return { counts, errors };
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: SportEventTableTabsCounter = {};

    const requests = tabs.map(tab => {
      const statuses = getSportEventTableStatusesByTab(tab);
      const params = {
        ...props,
        search: {
          ...props.search,
          statuses,
        },
      };
      return service.count({ ...params, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], tab: ESportEventTableTab) => {
      if (response.status === 'fulfilled') {
        counts[tab] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество мероприятий '${tab}': ${response.reason}`);
        }
      }
    };

    tabs.forEach((tab, index) => parseResponse(responses[index], tab));

    return { counts, errors };
  },
};
