import { AdCampaignsLocationState } from '@features/adCampaign/entry';
import { EAdCampaignTableTab } from '@features/adCampaign/types';
import PartnerManagementAdCampaignsScreen from '@screens/partnerManagement/adCampaigns';
import { ECmsContainerType, EOrderPartition } from 'domain/model/enums';
import { Partner } from 'domain/model/partner';
import { UUID } from 'domain/model/types';
import { Location } from 'history';
import { Redirect, Switch, useParams } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import NotFoundScreen from '../../../screens/notFound';
import PartnerManagementBookingOffersScreen from '../../../screens/partnerManagement/bookingOffers';
import PartnerManagementBookingOrdersScreen from '../../../screens/partnerManagement/bookingOrders';
import PartnerManagementWindowContainerCreateScreen from '../../../screens/partnerManagement/cmsContainerCreate';
import PartnerManagementWindowContainerDetailsScreen from '../../../screens/partnerManagement/cmsContainerDetails';
import PartnerManagementWindowContainerEditScreen from '../../../screens/partnerManagement/cmsContainerEdit';
import PartnerManagementCorpActivationsScreen from '../../../screens/partnerManagement/corpActivations';
import PartnerManagementCorpOffersScreen from '../../../screens/partnerManagement/corpOffers';
import PartnerManagementCustomersScreen from '../../../screens/partnerManagement/customers';
import PartnerManagementEmployeeScreen from '../../../screens/partnerManagement/employee';
import PartnerManagementEmployeeEditScreen from '../../../screens/partnerManagement/employeeEdit';
import PartnerManagementEmployeesScreen from '../../../screens/partnerManagement/employees';
import PartnerManagementOrderScreen from '../../../screens/partnerManagement/order';
import PartnerManagementScreen from '../../../screens/partnerManagement/partner';
import PartnerManagementProductOrdersScreen from '../../../screens/partnerManagement/productOrders';
import PartnerManagementProductsScreen from '../../../screens/partnerManagement/products';
import PartnerManagementQueryAnalyticsScreen from '../../../screens/partnerManagement/queryAnalytics';
import PartnerManagementReportsScreen from '../../../screens/partnerManagement/reports';
import PartnerManagementTradeActivationsScreen from '../../../screens/partnerManagement/tradeActivations';
import PartnerManagementTradeOffersScreen from '../../../screens/partnerManagement/tradeOffers';
import PartnerManagementWindowScreen from '../../../screens/partnerManagement/window';
import { QueryAnalyticsLocationState } from '../../analytics/query/entry';
import { BookingOffersLocationState } from '../../bookingOffer/entry';
import { ECmsContainerUrlParam } from '../../cms/container/types';
import { ECorpActivationTableTab } from '../../corpActivation/types';
import { CorpOffersLocationState } from '../../corpOffer/entry';
import { CustomersLocationState, ECustomerUrlParam } from '../../customer/entry';
import { ECustomerTableTab } from '../../customer/table/utils';
import { EOfferTableTab } from '../../general/offer/types';
import { EOrderUrlParam, OrdersLocationState } from '../../order/entry';
import { EOrderTableTab } from '../../order/types';
import { EOrderStep } from '../../order/utils';
import { EPartnerEmployeeUrlParam, PartnerEmployeesLocationState } from '../../partnerEmployee/entry';
import { EPartnerEmployeeTableTab } from '../../partnerEmployee/table/utils';
import { EPartnerEmployeeStep } from '../../partnerEmployee/utils';
import { ProductLocationState } from '../../products/entry';
import { EProductTableTab } from '../../products/table/utils';
import { TradeActivationsLocationState } from '../../tradeActivation/entry';
import { TradeOffersLocationState } from '../../tradeOffer/entry';
import { EPartnerDetailsLegalTab, EPartnerDetailsTab } from '../details/utils';
import { EPartnerUrlParam } from '../entry';
import PartnerManagementContainer from './container';

const partitionPathParam = ':partition' as const;

const routing = {
  root: `/partners/:id/management`,
  details: `/partners/:id/management/details`,
  products: `/partners/:id/management/products`,
  tradeOffers: `/partners/:id/management/trade-offers`,
  corpOffers: `/partners/:id/management/corp-offers`,
  tradeActivations: `/partners/:id/management/trade-activations`,
  corpActivations: `/partners/:id/management/corp-activations`,
  bookingOffers: `/partners/:id/management/booking-offers`,
  customers: `/partners/:id/management/customers`,
  employees: `/partners/:id/management/employees`,
  employee: `/partners/:partnerId/management/employees/:employeeId`,
  employeeEdit: `/partners/:partnerId/management/employees/:employeeId/edit`,
  orders: `/partners/:id/management/orders/${partitionPathParam}`,
  order: `/partners/:partnerId/management/orders/:orderId`,
  reports: `/partners/:id/management/reports`,
  queryAnalytics: `/partners/:id/management/query-analytics`,
  ads: {
    root: `/partners/:id/management/ads`,
    campaigns: `/partners/:id/management/ads/campaign`,
    campaign: `/partners/:id/management/ads/campaign/:campaignId`,
  },
  window: {
    root: `/partners/:id/management/window`,
    container: `/partners/:partnerId/management/window/container/:containerId`,
    containerCreate: `/partners/:partnerId/management/window/container/create`,
    containerEdit: `/partners/:partnerId/management/window/container/:containerId/edit`,
  },
};

interface GetPartnerDetailsRouteProps {
  readonly id: UUID;
  readonly tab?: EPartnerDetailsTab;
  readonly legalTab?: EPartnerDetailsLegalTab;
}

interface GetPartnerManagementProductsRouteProps {
  readonly id: UUID;
  readonly tab?: EProductTableTab;
  readonly guid?: UUID;
}

interface GetPartnerManagementTradeOffersRouteProps {
  readonly id: UUID;
  readonly tab?: EOfferTableTab;
  readonly guid?: UUID;
}

interface GetPartnerManagementCorpOffersRouteProps {
  readonly id: UUID;
  readonly tab?: EOfferTableTab;
  readonly guid?: UUID;
}

interface GetPartnerManagementBookingOffersRouteProps {
  readonly id: UUID;
  readonly tab?: EOfferTableTab;
  readonly guid?: UUID;
}

interface GetPartnerManagementOrdersRouteProps {
  readonly id: UUID;
  readonly partition: EOrderPartition;
  readonly tab?: EOrderTableTab;
  readonly guid?: UUID;
}

interface GetPartnerManagementTradeActivationsRouteProps {
  readonly id: UUID;
  readonly guid?: UUID;
}

interface GetPartnerManagementCorpActivationsRouteProps {
  readonly id: UUID;
  readonly tab?: ECorpActivationTableTab;
  readonly guid?: UUID;
}

interface GetPartnerManagementAdCampaignsRouteProps {
  readonly id: UUID;
  readonly tab?: EAdCampaignTableTab;
  readonly guid?: UUID;
}

interface GetPartnerManagementReportsRouteProps {
  readonly id: UUID;
  readonly guid?: UUID;
}

interface GetPartnerManagementQueryAnalyticsRouteProps {
  readonly id: UUID;
  readonly guid?: UUID;
}

interface GetPartnerManagementOrderRouteProps {
  readonly partnerId: UUID;
  readonly orderId: UUID;
  readonly step?: EOrderStep;
}

interface GetPartnerManagementCustomersRouteProps {
  readonly id: UUID;
  readonly tab?: ECustomerTableTab;
  readonly guid?: UUID;
}

interface GetPartnerManagementEmployeesRouteProps {
  readonly id: UUID;
  readonly tab?: EPartnerEmployeeTableTab;
  readonly guid?: UUID;
}

interface GetPartnerManagementEmployeeRouteProps {
  readonly partnerId: UUID;
  readonly employeeId: UUID;
  readonly step?: EPartnerEmployeeStep;
}

interface GetPartnerManagementEmployeeEditRouteProps {
  readonly partnerId: UUID;
  readonly employeeId: UUID;
  readonly step?: EPartnerEmployeeStep;
}

interface GetPartnerManagementWindowRouteProps {
  readonly id: UUID;
  readonly guid?: UUID;
}

interface GetPartnerManagementWindowContainerDetailsRouteProps {
  readonly partnerId: UUID;
  readonly containerId: UUID;
  readonly guid?: UUID;
}

interface GetPartnerManagementWindowContainerEditRouteProps {
  readonly partnerId: UUID;
  readonly containerId: UUID;
  readonly guid?: UUID;
}

interface GetPartnerManagementWindowContainerCreateRouteProps {
  readonly partnerId: UUID;
  readonly type: ECmsContainerType;
  readonly step?: number;
  readonly guid?: UUID;
}

export const getPartnerManagementRootRouteDefinition = () => routing.root;

export const getPartnerManagementDetailsRoute = ({ id, tab, legalTab }: GetPartnerDetailsRouteProps) => {
  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.append(EPartnerUrlParam.Tab, tab);
  }

  if (legalTab) {
    searchParams.append(EPartnerUrlParam.LegalTab, legalTab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.details.replace(':id', id)}${search}`;
};

export const getPartnerManagementTradeOffersRoute = (
  props: GetPartnerManagementTradeOffersRouteProps
): Location<TradeOffersLocationState> => {
  const { id, tab, guid } = props;

  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.append(EPartnerUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return {
    pathname: `${routing.tradeOffers.replace(':id', id)}`,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    key: '',
    hash: '',
  };
};

export const getPartnerManagementTradeActivationsRoute = (
  props: GetPartnerManagementTradeActivationsRouteProps
): Location<TradeActivationsLocationState> => {
  const { id, guid } = props;

  const searchParams = new URLSearchParams();

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return {
    pathname: `${routing.tradeActivations.replace(':id', id)}`,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    key: '',
    hash: '',
  };
};

export const getPartnerManagementAdCampaignsRoute = (
  props: GetPartnerManagementAdCampaignsRouteProps
): Location<AdCampaignsLocationState> => {
  const { id, guid, tab } = props;

  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.append(EPartnerUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return {
    pathname: `${routing.ads.campaigns.replace(':id', id)}`,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    key: '',
    hash: '',
  };
};

export const getPartnerManagementCorpActivationsRoute = (
  props: GetPartnerManagementCorpActivationsRouteProps
): Location<TradeActivationsLocationState> => {
  const { id, tab, guid } = props;

  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.append(EPartnerUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return {
    pathname: `${routing.corpActivations.replace(':id', id)}`,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    key: '',
    hash: '',
  };
};

export const getPartnerManagementProductsRoute = (
  props: GetPartnerManagementProductsRouteProps
): Location<ProductLocationState> => {
  const { id, tab, guid } = props;

  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.append(EPartnerUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return {
    pathname: `${routing.products.replace(':id', id)}`,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    key: '',
    hash: '',
  };
};

export const getPartnerManagementCorpOffersRoute = (
  props: GetPartnerManagementCorpOffersRouteProps
): Location<CorpOffersLocationState> => {
  const { id, tab, guid } = props;

  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.append(EPartnerUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return {
    pathname: `${routing.corpOffers.replace(':id', id)}`,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    key: '',
    hash: '',
  };
};

export const getPartnerManagementBookingOffersRoute = (
  props: GetPartnerManagementBookingOffersRouteProps
): Location<BookingOffersLocationState> => {
  const { id, tab, guid } = props;

  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.append(EPartnerUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return {
    pathname: `${routing.bookingOffers.replace(':id', id)}`,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    key: '',
    hash: '',
  };
};

export const getPartnerManagementCustomersRoute = (
  props: GetPartnerManagementCustomersRouteProps
): Location<CustomersLocationState> => {
  const { id, tab, guid } = props;

  const searchParams = new URLSearchParams();
  if (tab) searchParams.append(ECustomerUrlParam.Tab, tab);

  const newGuid = guid ?? uuidv4();

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: `${routing.customers.replace(':id', id)}`,
    search,
    state: {
      guid: newGuid,
    },
    hash: '',
    key: '',
  };
};

export const getPartnerManagementEmployeesRoute = (
  props: GetPartnerManagementEmployeesRouteProps
): Location<PartnerEmployeesLocationState> => {
  const { id, tab, guid } = props;

  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.append(EPartnerEmployeeUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return {
    pathname: `${routing.employees.replace(':id', id)}`,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    key: '',
    hash: '',
  };
};

export const getPartnerManagementEmployeeRoute = (props: GetPartnerManagementEmployeeRouteProps) => {
  const { partnerId, employeeId, step } = props;

  const searchParams = new URLSearchParams();

  if (step) {
    searchParams.append(EPartnerEmployeeUrlParam.Step, step);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.employee.replace(':partnerId', partnerId).replace(':employeeId', employeeId)}${search}`;
};

export const getPartnerManagementEmployeeEditRoute = (props: GetPartnerManagementEmployeeEditRouteProps) => {
  const { partnerId, employeeId, step } = props;

  const searchParams = new URLSearchParams();

  if (step) {
    searchParams.append(EPartnerEmployeeUrlParam.Step, step);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.employeeEdit.replace(':partnerId', partnerId).replace(':employeeId', employeeId)}${search}`;
};

export const getPartnerManagementReportsRoute = (
  props: GetPartnerManagementReportsRouteProps
): Location<OrdersLocationState> => {
  const { id, guid } = props;

  const searchParams = new URLSearchParams();
  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: `${routing.reports.replace(':id', id)}`,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

export const getPartnerManagementQueryAnalyticsRoute = (
  props: GetPartnerManagementQueryAnalyticsRouteProps
): Location<QueryAnalyticsLocationState> => {
  const { id, guid } = props;

  const searchParams = new URLSearchParams();
  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: `${routing.queryAnalytics.replace(':id', id)}`,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

export const getPartnerManagementOrdersRoute = (
  props: GetPartnerManagementOrdersRouteProps
): Location<OrdersLocationState> => {
  const { id, tab, guid, partition } = props;

  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.append(EOrderUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return {
    pathname: partition
      ? `${routing.orders.replace(':id', id).replace(partitionPathParam, partition)}`
      : `${routing.orders.replace(':id', id)}`,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

export const getPartnerManagementOrderRoute = ({ partnerId, orderId, step }: GetPartnerManagementOrderRouteProps) => {
  const searchParams = new URLSearchParams();

  if (step) {
    searchParams.append(EOrderUrlParam.Step, step);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.order.replace(':partnerId', partnerId).replace(':orderId', orderId)}${search}`;
};

export const getPartnerManagementWindowRoute = (
  props: GetPartnerManagementWindowRouteProps
): Location<OrdersLocationState> => {
  const { id, guid } = props;

  const searchParams = new URLSearchParams();
  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: `${routing.window.root.replace(':id', id)}`,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

export const getPartnerManagementWindowContainerDetailsRoute = (
  props: GetPartnerManagementWindowContainerDetailsRouteProps
): Location<OrdersLocationState> => {
  const { partnerId, containerId, guid } = props;

  const searchParams = new URLSearchParams();
  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: `${routing.window.container.replace(':partnerId', partnerId).replace(':containerId', containerId)}`,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

export const getPartnerManagementWindowContainerCreateRoute = (
  props: GetPartnerManagementWindowContainerCreateRouteProps
): Location<OrdersLocationState> => {
  const { partnerId, guid, step, type } = props;

  const searchParams = new URLSearchParams();
  searchParams.set(ECmsContainerUrlParam.Type, type);

  if (step) {
    searchParams.set(ECmsContainerUrlParam.Step, `${step}`);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: `${routing.window.containerCreate.replace(':partnerId', partnerId)}`,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

export const getPartnerManagementWindowContainerEditRoute = (
  props: GetPartnerManagementWindowContainerEditRouteProps
): Location<OrdersLocationState> => {
  const { partnerId, containerId, guid } = props;

  const searchParams = new URLSearchParams();
  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: `${routing.window.containerEdit.replace(':partnerId', partnerId).replace(':containerId', containerId)}`,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

interface PartnerManagementEntryProps {
  readonly partner: Partner;
}

const PartnerManagementEntryInternal = ({ partner }: PartnerManagementEntryProps) => {
  return (
    <Switch>
      <Route
        exact
        path={routing.details}
        render={() => <PartnerManagementScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.products}
        render={() => <PartnerManagementProductsScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.tradeOffers}
        render={() => <PartnerManagementTradeOffersScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.corpOffers}
        render={() => <PartnerManagementCorpOffersScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.bookingOffers}
        render={() => <PartnerManagementBookingOffersScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.tradeActivations}
        render={() => <PartnerManagementTradeActivationsScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.corpActivations}
        render={() => <PartnerManagementCorpActivationsScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.ads.campaigns}
        render={() => <PartnerManagementAdCampaignsScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.queryAnalytics}
        render={() => <PartnerManagementQueryAnalyticsScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.window.root}
        render={() => <PartnerManagementWindowScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.window.containerEdit}
        render={() => <PartnerManagementWindowContainerEditScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.window.containerCreate}
        render={() => <PartnerManagementWindowContainerCreateScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.window.container}
        render={() => <PartnerManagementWindowContainerDetailsScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.employees}
        render={() => <PartnerManagementEmployeesScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.employee}
        render={() => <PartnerManagementEmployeeScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.employeeEdit}
        render={() => <PartnerManagementEmployeeEditScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.customers}
        render={() => <PartnerManagementCustomersScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.orders.replace(partitionPathParam, EOrderPartition.ProductOffers)}
        render={() => <PartnerManagementProductOrdersScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.orders.replace(partitionPathParam, EOrderPartition.BookingOffers)}
        render={() => <PartnerManagementBookingOrdersScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.order}
        render={() => <PartnerManagementOrderScreen partner={partner} />}
      />
      <Route
        exact
        path={routing.reports}
        render={() => <PartnerManagementReportsScreen partner={partner} />}
      />
      <Redirect
        from={routing.root}
        to={routing.details}
      />

      <Route component={NotFoundScreen} />
    </Switch>
  );
};

const PartnerManagementEntry = () => {
  const { id } = useParams<{ id: UUID }>();

  return (
    <PartnerManagementContainer id={id}>
      {partner => <PartnerManagementEntryInternal partner={partner} />}
    </PartnerManagementContainer>
  );
};

export default PartnerManagementEntry;
