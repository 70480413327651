import { ESearchQueryStatDiscriminator } from '@/domain/model/enums';
import AnalyticsQueriesTableCell, { AnalyticsQueriesTableCellCommonProps } from './index';

const AnalyticsQueriesTableCellQuery = (props: AnalyticsQueriesTableCellCommonProps) => {
  const { query } = props;

  const value = query.discriminator === ESearchQueryStatDiscriminator.Keyword ? query.query : '';
  return (
    <AnalyticsQueriesTableCell
      {...props}
      value={value}
    />
  );
};

export default AnalyticsQueriesTableCellQuery;
