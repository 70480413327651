import CmsContainerTableCell, { CmsContainerTableCellCommonProps } from './index';

type CmsContainerTableCellLinkToProps = CmsContainerTableCellCommonProps;

const CmsContainerTableCellLinkTo = (props: CmsContainerTableCellLinkToProps) => {
  const {
    cmsContainer: { linkTo },
  } = props;

  const value = linkTo ?? '-';

  return (
    <CmsContainerTableCell
      {...props}
      value={value}
    />
  );
};

export default CmsContainerTableCellLinkTo;
