import { ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { AppUserSpecific } from '../../../../../../domain/model/user';
import { UserAvatar } from '../../../../../components/common/avatars';
import { EUserNameRule, getUserFullName } from '../../../../../utils';

interface MainMenuItemUserProps {
  readonly user: AppUserSpecific;
  readonly selected: boolean;
  readonly onClick: () => void;
}

export const MainMenuItemUser = ({ user, selected, onClick }: MainMenuItemUserProps) => {
  return (
    <ListItem
      button
      selected={selected}
      onClick={onClick}
    >
      <ListItemAvatar>
        <UserAvatar
          user={user}
          size='micro'
        />
      </ListItemAvatar>
      <ListItemText
        primary={getUserFullName({ user, rule: EUserNameRule.Reverse })}
        primaryTypographyProps={{ variant: 'body1' }}
      />
    </ListItem>
  );
};
