import { Partner } from '@/domain';
import { EOfferTableTab } from '@features/general/offer/types';
import { viewHideMainMenu } from '@features/main/container/store/slice';
import { viewShowPartnerManagementMenu } from '@features/partner/management/container/store/slice';
import TradeOfferActionsProvider from '@features/tradeOffer/actions/provider';
import { ETradeOfferTableUrlParam, TradeOffersLocationState } from '@features/tradeOffer/entry';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import TradeOfferTableEntry from '../../features/tradeOffer/table/entry';

interface PartnerManagementTradeOffersScreenProps {
  readonly partner: Partner;
}

const PartnerManagementTradeOffersScreen = ({ partner }: PartnerManagementTradeOffersScreenProps) => {
  const dispatch = useDispatch();
  const location = useLocation<TradeOffersLocationState>();

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(ETradeOfferTableUrlParam.Tab) as EOfferTableTab;

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowPartnerManagementMenu());
  }, [dispatch]);

  return (
    <LocationWithRequiredStateContainer<TradeOffersLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <TradeOfferActionsProvider>
          <TradeOfferTableEntry
            guid={state.guid}
            tab={tab}
            partnerId={partner.id}
          />
        </TradeOfferActionsProvider>
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default PartnerManagementTradeOffersScreen;
