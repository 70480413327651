import { MPAutocompleteMultipleSelectItem } from '../../../../../theme/ui-kit/autocomplete/types';

export enum OrgStructureOrgUnitMultipleItemType {
  All = 'all',
  Text = 'text',
  OrgUnit = 'orgUnit',
  None = 'none',
}

export enum OrgStructureOrgUnitMultipleSelectType {
  All = 'all',
  None = 'none',
}

export enum OrgStructureOrgUnitMultipleAllOrgUnitsState {
  Indeterminate = 'indeterminate',
  Checked = 'checked',
  NotChecked = 'not-checked',
}

export type OrgStructureOrgUnitMultipleTypesSelectorItem =
  | (MPAutocompleteMultipleSelectItem & { type: OrgStructureOrgUnitMultipleItemType.OrgUnit })
  | (MPAutocompleteMultipleSelectItem & { type: OrgStructureOrgUnitMultipleItemType.Text })
  | (MPAutocompleteMultipleSelectItem & {
      readonly type: OrgStructureOrgUnitMultipleItemType.All;
      readonly state: OrgStructureOrgUnitMultipleAllOrgUnitsState;
    })
  | (MPAutocompleteMultipleSelectItem & {
      readonly type: OrgStructureOrgUnitMultipleItemType.None;
      readonly state: OrgStructureOrgUnitMultipleAllOrgUnitsState;
    });
