import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../components/common/header';
import { TitleWrapper } from '../controls';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { adCampaignDetailsAdCampaignStatusSelector } from '../store/selectors';

export const AdCampaignDetailsHeaderAdapter = () => {
  const adCampaignStatus = useSelector(adCampaignDetailsAdCampaignStatusSelector);

  const { adCampaignStatuses } = useSelector(nsiDataSelector);
  const adCampaignStatusText = adCampaignStatuses.find(s => s.id === adCampaignStatus)?.name;
  const { onAdCampaignClose } = useContextHandlers();

  const onClose = useCallback(() => onAdCampaignClose(null), [onAdCampaignClose]);

  return (
    <DefaultHeader
      sticky
      headline={
        <AppBreadcrumbs>
          <Typography color='textSecondary'>{adCampaignStatusText}</Typography>
        </AppBreadcrumbs>
      }
      onClose={onClose}
    >
      <TitleWrapper>
        <Typography variant='h2'>Рекламная кампания</Typography>
      </TitleWrapper>
    </DefaultHeader>
  );
};
