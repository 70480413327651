import validator from 'validator';
import { MpUserData } from '../../../../domain/model/user';
import { EValidationMessages, EValidationType, ValidationRules } from '../../../utils/validation';

export const partnerRegistrationValidation: ValidationRules<MpUserData> = {
  email: {
    required: true,
    validator: (object, value) => {
      if (!value) return null;

      const isEmail = validator.isEmail(value);
      if (!isEmail) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EValidationMessages.EmailInvalid,
        };
      } else {
        return null;
      }
    },
  },
};
