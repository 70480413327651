import { RootState } from '../../../../../data/store/store';
import { ESortDirection } from '../../../../../domain/model/enums';
import { EBookingOrderTableColumn } from '../../types';
import { createSelector } from '@reduxjs/toolkit';
import { Nullable } from '../../../../../domain/model/types';
import { BookingOrder } from '../../../../../domain/model/order';

export const bookingOrdersListSelector = (store: RootState) => store.bookingOrder.list;
export const bookingOrdersGuidSelector = (store: RootState) => store.bookingOrder.list.guid;

export const bookingOrdersOrdersSelector = (store: RootState) => store.bookingOrder.list.orders.data;
export const bookingOrdersIsFetchingSelector = (store: RootState) => store.bookingOrder.list.orders.isFetching;

export const bookingOrdersBookingOfferIndexSelector = (store: RootState, index: number) => index;
const createBookingOrdersTableBookingOrderByIndexSelector = createSelector(
  bookingOrdersOrdersSelector,
  bookingOrdersBookingOfferIndexSelector,
  (bookingOrders, index): Nullable<BookingOrder> => bookingOrders?.[index] ?? null
);
export const bookingOrderBookingOfferByIndexSelector = (index: number) => (store: RootState) =>
  createBookingOrdersTableBookingOrderByIndexSelector(store, index);

export const bookingOrdersPageNumberSelector = (store: RootState) => store.bookingOrder.list.orders.pageNumber;
export const bookingOrdersTotalCountSelector = (store: RootState) => store.bookingOrder.list.orders.totalCount;
export const bookingOrdersPageCountSelector = (store: RootState) => store.bookingOrder.list.orders.pageCount;

export const bookingOrdersSearchSelector = (store: RootState) => store.bookingOrder.list.search;
export const bookingOrdersSortSelector = (store: RootState) => store.bookingOrder.list.search.sort;
export const bookingOrdersFilterSelector = (store: RootState) => store.bookingOrder.list.filter;

export const bookingOrdersSortColumnSelector = (store: RootState) =>
  (store.bookingOrder.list.search.sort?.split(',') as [EBookingOrderTableColumn, ESortDirection])?.[0] ?? '';
export const bookingOrdersSortDirectionSelector = (store: RootState) =>
  (store.bookingOrder.list.search.sort?.split(',') as [EBookingOrderTableColumn, ESortDirection])?.[1] ?? '';

export const bookingOrdersNeedRefreshWatcherSelector = (store: RootState) => store.bookingOrder.list.needRefreshWatcher;

export const bookingOrdersTabsCounterSelector = (store: RootState) => store.bookingOrder.list.tabsCounter;
