import { EUserActionsType } from '@features/user/utils';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { UserCurrentActionsContext } from './context';
import { UserCurrentActionsDialogsAdapter } from './dialogs';
import { isUserCurrentEmailChangedSelector } from './store/selectors';
import { userActionsChangeDialogState, userActionsSetActionFulfilled } from './store/slice';
import { UserCurrentActionsOptimizerAdapter } from '@features/user/current/actions/adapters/optimizer';

type UserCurrentActionsProviderProps = {
  readonly children: JSX.Element;
};

export const UserCurrentActionsProvider = (props: UserCurrentActionsProviderProps) => {
  const { children } = props;
  const dispatch = useDispatch();

  const onChangeDialogState = useCallback<UserCurrentActionsContext['onChangeDialogState']>(
    (name, value) => {
      dispatch(userActionsChangeDialogState({ name, data: value }));
    },
    [dispatch]
  );

  const onEmailChanged = useCallback<UserCurrentActionsContext['onEmailChanged']>(() => {
    dispatch(userActionsSetActionFulfilled(EUserActionsType.ChangeEmail));
  }, [dispatch]);

  const onTryChangeEmail = useCallback<UserCurrentActionsContext['onTryChangeEmail']>(() => {
    onChangeDialogState('changeEmail', true);
  }, [dispatch]);

  return (
    <UserCurrentActionsContext.Provider
      value={{
        onChangeDialogState,
        onEmailChanged,
        onTryChangeEmail,
        utils: {
          selectors: {
            isCurrentUserEmailChangedSelector: isUserCurrentEmailChangedSelector,
          },
        },
      }}
    >
      {children}
      <UserCurrentActionsOptimizerAdapter />
      <UserCurrentActionsDialogsAdapter />
    </UserCurrentActionsContext.Provider>
  );
};
