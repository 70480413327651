import { ReactNode, useMemo } from 'react';
import { ProductOffer } from '../../../../../domain/model/productOffer';
import DataTableCellTextFormat from '../../../../components/common/table/cell/textFormat';
import { TableColumn } from '../../../../components/common/table';

export interface ProductTableCellCommonProps {
  readonly productOffer: ProductOffer;
  readonly isDisabled?: boolean;
  readonly isTextAccent?: boolean;
  readonly column?: TableColumn;
}

interface ProductTableCellProps extends ProductTableCellCommonProps {
  readonly value: ReactNode;
}

const ProductTableCell = ({ value, isDisabled, isTextAccent }: ProductTableCellProps) => {
  return useMemo(
    () => (
      <DataTableCellTextFormat
        disabled={isDisabled}
        bold={isTextAccent}
      >
        {value}
      </DataTableCellTextFormat>
    ),
    [isDisabled, isTextAccent, value]
  );
};

export default ProductTableCell;
