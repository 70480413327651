import { ClientOrg, ClientOrgCreate, Nullable } from '@/domain';
import { ClientOrgCreateState } from '@features/clientOrg/create/store/slice';
import React from 'react';
import { OnChangeObjectAttribute, PanelAction } from '../../../types';
import { ClientOrgLifeCycle, ClientOrgLifeCycleRules } from '../lifecycle/types';
import { ClientOrgActionCreateType, ClientOrgActions, ClientOrgCreateStep } from '../types';
import { ClientOrgCreateContainerProps } from './container';

export type ClientOrgCreateParamsContextValue = ClientOrgCreateContainerProps;

export const ClientOrgCreateParamsContext = React.createContext<ClientOrgCreateParamsContextValue>(
  {} as ClientOrgCreateParamsContextValue
);

export type ClientOrgCreateLifecycleContextValue = {
  readonly lifecycle: ClientOrgLifeCycle;
  readonly lifeCycleRules: ClientOrgLifeCycleRules;
  readonly actions: ClientOrgActions<ClientOrgActionCreateType>;
  readonly onAction: (action: PanelAction<ClientOrgActionCreateType>) => void;
};

export const ClientOrgCreateLifecycleContext = React.createContext<ClientOrgCreateLifecycleContextValue>(
  {} as ClientOrgCreateLifecycleContextValue
);

export type ClientOrgCreateHandlersContextValue = {
  readonly onClose: () => void;
  readonly onCloseImmediate: () => void;
  readonly onChangeDialogState: (
    name: keyof ClientOrgCreateState['dialogs'],
    clientOrg: Nullable<ClientOrgCreate>
  ) => void;

  readonly goToFirstIsNotValidStep: () => void;
  readonly onClientOrgSave: () => Promise<Nullable<ClientOrg>>;

  readonly onChangeStep: (step: ClientOrgCreateStep) => void;

  readonly onChangeAttribute: OnChangeObjectAttribute<ClientOrgCreate>;
  readonly onAttributeValidate: (name: keyof ClientOrgCreate) => void;
};

export const ClientOrgCreateHandlersContext = React.createContext<ClientOrgCreateHandlersContextValue>(
  {} as ClientOrgCreateHandlersContextValue
);
