import { EDateTimeFormat } from '@/domain/model/formats';
import moment from 'moment';
import TradeActivationTableCell, { TradeActivationTableCellCommonProps } from './index';

const TradeActivationTableCellCreateAt = (props: TradeActivationTableCellCommonProps) => {
  const {
    tradeActivation: { createdAt },
  } = props;

  return (
    <TradeActivationTableCell
      {...props}
      value={moment(createdAt).format(EDateTimeFormat.DisplayDefault)}
    />
  );
};

export default TradeActivationTableCellCreateAt;
