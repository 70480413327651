import { FCC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { bookingOfferCategoriesEditFetch, bookingOfferCategoriesEditResetState } from './store/slice';
import { BookingOfferCategoriesEditCommonProps } from './types';

type BookingOfferCategoriesEditInitializerProps = BookingOfferCategoriesEditCommonProps;

export const BookingOfferCategoriesEditInitializer: FCC<BookingOfferCategoriesEditInitializerProps> = ({
  children,
}) => {
  const dispatch = useDispatch();

  //загрузка основных данных
  useEffect(() => {
    const requests = [dispatch(bookingOfferCategoriesEditFetch())];
    return () => {
      requests.forEach(request => request.abort());
    };
  }, [dispatch]);

  //очистка данных
  useEffect(() => {
    return () => {
      dispatch(bookingOfferCategoriesEditResetState());
    };
  }, [dispatch]);

  return <>{children}</>;
};
