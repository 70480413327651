import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getTradeOfferActionsOfferIsProcessingSelector, tradeOfferActionsGetActionSelector } from '../store/selectors';
import { useTradeOfferActions } from '../useActions';
import { TradeOffer } from '@/domain';
import OfferRetrieveDialog from '@features/general/offer/components/retrieveDialog';
import { TradeOfferStateKeyDialogs } from '../store/slice';

const nameDialog: TradeOfferStateKeyDialogs = 'retrieve';

const TradeOfferActionsDialogRetrieveAdapter = () => {
  const handlers = useTradeOfferActions();
  const { data, notification } = useSelector(tradeOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getTradeOfferActionsOfferIsProcessingSelector(data?.id ?? null));

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onRetrieve = useCallback(
    (offer: TradeOffer, endDate: string) => {
      handlers.onRetrieve(offer, endDate, notification).then(() => {
        onCloseDialog();
      });
    },
    [handlers.onRetrieve, notification]
  );

  return (
    data && (
      <OfferRetrieveDialog<TradeOffer>
        offer={data}
        isExecuting={isProcessing}
        onExecute={onRetrieve}
        onClose={onCloseDialog}
      />
    )
  );
};

export default TradeOfferActionsDialogRetrieveAdapter;
