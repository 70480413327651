import { MpUserData } from '../../../../domain/model/user';
import { ValidationRules } from '../../../utils/validation';

export const partnerSelfRegistrationValidation: ValidationRules<MpUserData> = {
  lastName: {
    required: true,
  },
  firstName: {
    required: true,
  },
  phone: {
    required: true,
  },
};
