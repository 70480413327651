import { useContext } from 'react';
import ClientOrgActionsContext, { ClientOrgActionsContextType } from './context';

export const useClientOrgActions = (): ClientOrgActionsContextType => {
  return useContext<ClientOrgActionsContextType>(ClientOrgActionsContext);
};

export const useClientOrgActionsSelectors = (): ClientOrgActionsContextType['utils']['selectors'] => {
  return useClientOrgActions().utils.selectors;
};
