import { AxiosResponse } from 'axios';
import Api from '../../../../data/api';
import { TournamentIndividualPresenceConfirmedProps } from '../../../../data/api/event';
import { ApiCancellable, ApiResponseWithPageable } from '../../../../data/api/types';
import {
  EPresenceStatus,
  ESportEventStatus,
  ETournamentApplicationStatuses,
  ETournamentIndividualActionDiscriminator,
  ETournamentIndividualApplicationActionDiscriminator,
} from '../../../../domain/model/enums';
import {
  TournamentIndividual,
  TournamentIndividualApplication,
  TournamentIndividualMemberListView,
  TournamentIndividualRequest,
  TournamentIndividualResultsRequest,
} from '../../../../domain/model/event';
import { UUID } from '../../../../domain/model/types';
import { UserProfileLink } from '../../../../domain/model/user';
import eventServices from './';

export type ByIdProps = ApiCancellable & {
  id: UUID;
};

export type ByAppIdAndTourIdProps = {
  readonly appId: UUID;
  readonly tournamentId: UUID;
};

export type PublishProps = {
  tournament: TournamentIndividualRequest;
};

export type SaveProps = {
  tournament: TournamentIndividualRequest;
};

export type ApplicationsProps = {
  readonly tournamentId: UUID;
  readonly userId?: UUID;
  readonly statuses?: ETournamentApplicationStatuses[];
};

export type InviteUsersProps = {
  id: UUID;
  users: UserProfileLink[];
};

export type SaveResultProps = {
  id: UUID;
  results: TournamentIndividualResultsRequest;
};

export type PresenceConfirmedProps = TournamentIndividualPresenceConfirmedProps;

export type RejectApplicationProps = ByAppIdAndTourIdProps & {
  readonly message: string;
};

export type MembersProps = ApiCancellable & {
  readonly id: UUID;
};

export type TournamentIndividualCancelProps = ApiCancellable & {
  readonly id: UUID;
};

export type EventTournamentIndividualService = {
  readonly one: (props: ByIdProps) => Promise<TournamentIndividual>;
  readonly save: (props: SaveProps) => Promise<TournamentIndividual>;
  readonly cancel: (props: TournamentIndividualCancelProps) => Promise<AxiosResponse<void>>;
  readonly members: (props: MembersProps) => Promise<ApiResponseWithPageable<TournamentIndividualMemberListView>>;
  readonly announce: (props: PublishProps) => Promise<TournamentIndividual>;
  readonly publish: (props: PublishProps) => Promise<TournamentIndividual>;
  readonly publishResult: (props: ByIdProps) => Promise<TournamentIndividual>;
  readonly saveResult: (props: SaveResultProps) => Promise<TournamentIndividual>;
  readonly inviteUsers: (props: InviteUsersProps) => Promise<TournamentIndividual>;
  readonly acceptApplication: (props: ByAppIdAndTourIdProps) => Promise<AxiosResponse<void>>;
  readonly rejectApplication: (props: RejectApplicationProps) => Promise<AxiosResponse<void>>;
  readonly createApplication: (props: ByIdProps) => Promise<TournamentIndividualApplication>;
  readonly cancelledApplication: (props: ByAppIdAndTourIdProps) => Promise<AxiosResponse>;
  readonly presenceConfirmed: (props: PresenceConfirmedProps) => Promise<EPresenceStatus>;
  readonly applications: (
    props: ApplicationsProps
  ) => Promise<ApiResponseWithPageable<TournamentIndividualApplication>>;
};

export const service: EventTournamentIndividualService = {
  one: async ({ id, signal }) => {
    const { data } = await Api.event.tournamentIndividual.one({ id, signal });
    return data;
  },
  save: async ({ tournament }) => {
    const tournamentToSave = eventServices.common.beforeSave(tournament);

    switch (tournamentToSave.status?.code ?? null) {
      case null:
        /** Создаем турнир */
        return (await Api.event.tournamentIndividual.createDraft({ data: tournamentToSave })).data;
      case ESportEventStatus.Announced:
        /** Обновляем аннонсированый туринр  */
        return (
          await Api.event.tournamentIndividual.action({
            discriminator: ETournamentIndividualActionDiscriminator.AnnouncedTournamentIndividualUpdateCommand,
            ...tournamentToSave,
          })
        ).data;
      case ESportEventStatus.WaitingForStart:
      case ESportEventStatus.ApplicationsCollecting:
        /** Обновляем опубликованый туринр  */
        return (
          await Api.event.tournamentIndividual.action({
            discriminator: ETournamentIndividualActionDiscriminator.PublishTournamentIndividualUpdateCommand,
            ...tournamentToSave,
          })
        ).data;
      default:
        /** Обновляем туринр как черновик */
        return (
          await Api.event.tournamentIndividual.action({
            discriminator: ETournamentIndividualActionDiscriminator.DraftTournamentIndividualUpdateCommand,
            ...tournamentToSave,
          })
        ).data;
    }
  },
  members: async payload => {
    const { id, signal } = payload;
    const { data } = await Api.event.tournamentIndividual.members({
      pageSize: 9999999,
      page: 1,
      signal,
      id,
    });
    return data;
  },
  announce: async ({ tournament }) => {
    const { data } = await Api.event.tournamentIndividual.action({
      id: tournament.id,
      discriminator: ETournamentIndividualActionDiscriminator.AnnounceTournamentIndividualCommand,
    });
    return data;
  },
  saveResult: async ({ id, results }) => {
    const { data } = await Api.event.tournamentIndividual.action({
      id,
      discriminator: ETournamentIndividualActionDiscriminator.TournamentIndividualResultsCommand,
      ...results,
    });
    return data;
  },
  publish: async ({ tournament }) => {
    const { data } = await Api.event.tournamentIndividual.action({
      id: tournament.id,
      discriminator: ETournamentIndividualActionDiscriminator.PublishTournamentIndividualCommand,
    });
    return data;
  },
  publishResult: async ({ id }) => {
    const { data } = await Api.event.tournamentIndividual.action({
      id,
      discriminator: ETournamentIndividualActionDiscriminator.PublishTournamentIndividualResultCommand,
    });
    return data;
  },
  inviteUsers: async ({ id, users }) => {
    const { data } = await Api.event.tournamentIndividual.action({
      id,
      users,
      discriminator: ETournamentIndividualActionDiscriminator.InviteUsersToTournamentIndividualCommand,
    });
    return data;
  },
  acceptApplication: async ({ appId, tournamentId }) => {
    return await Api.event.tournamentIndividual.applicationsAction({
      appId,
      tournamentId,
      discriminator: ETournamentIndividualApplicationActionDiscriminator.AcceptTournamentApplicationCommand,
    });
  },
  rejectApplication: async ({ appId, tournamentId, message }) => {
    return await Api.event.tournamentIndividual.applicationsAction({
      appId,
      tournamentId,
      message,
      discriminator: ETournamentIndividualApplicationActionDiscriminator.RejectTournamentApplicationCommand,
    });
  },
  createApplication: async ({ id }) => {
    return (await Api.event.tournamentIndividual.createApplication({ tournamentId: id })).data;
  },
  cancelledApplication: async payload => {
    return await Api.event.tournamentIndividual.cancelApplication(payload);
  },
  presenceConfirmed: async payload => {
    return (await Api.event.tournamentIndividual.presenceConfirmed(payload)).data;
  },
  applications: async props => {
    return (
      await Api.event.tournamentIndividual.applications({
        ...props,
        page: 1,
        pageSize: 999,
      })
    ).data;
  },
  cancel: async ({ id }) => {
    return await Api.event.cancel({ id });
  },
};
