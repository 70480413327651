import { Grid, Link as MuiLink } from '@mui/material';
import styled from '@emotion/styled';
import { MPToggleButtonsGroupSingle } from '../../../theme/ui-kit/button/toggle';

export const Wrapper = styled.div`
  overflow: hidden;
`;

export const ErrorWrapper = styled.div`
  background: ${p => p.theme.palette.error.light};
  padding: ${p => p.theme.spacing(1)};
`;

export const GridItemContent = styled(Grid)`
  flex-grow: 1;
`;

export const Link = styled(MuiLink)`
  :focus {
    outline: none;
  }
`;

export const ToggleButtons = styled(MPToggleButtonsGroupSingle)`
  &.MuiToggleButtonGroup-root {
    width: 100%;
  }

  & .MuiToggleButton-root {
    width: 100%;
  }
` as typeof MPToggleButtonsGroupSingle;
