import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ReportsTableCellCommonProps } from '../../components/tableCell';
import { reportsByIndexSelector } from '../store/selectors';

export type ReportsTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<ReportsTableCellCommonProps>;
};

export const ReportsTableCellAdapter = (props: ReportsTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const report = useSelector(reportsByIndexSelector(index));

  return report ? <Component report={report} /> : null;
};
