import { RootState } from '@/data/store/store';
import { Nullable, UUID } from '@/domain/model/types';
import { createSelector } from '@reduxjs/toolkit';
import { EClientOrgUserActionType } from '../../types';

export const clientOrgUserActionsDialogImportSelector = (store: RootState) =>
  store.user.userClientOrg.actions.dialogs.import;

export const getClientOrgUserActionsOfferIsProcessingSelector = (id: Nullable<UUID>) =>
  createSelector(
    (store: RootState) => store.user.userClientOrg.actions.actions,
    (store: RootState) => store.user.userClientOrg.actions.create.isFetching,
    (actions, isCreating) => {
      return id ? actions.some(action => action.id === id && action.isFetching) : isCreating;
    }
  );

export const isClientOrgUserAnyChangedSelector = (store: RootState) =>
  store.user.userClientOrg.actions.actions.filter(action => !action.isFetching).length;

export const getIsClientOrgUserAnyActionByTypeExecutedSelector = (
  id: Nullable<UUID>,
  ...actions: EClientOrgUserActionType[]
) =>
  createSelector(
    () => id,
    () => actions,
    (store: RootState) => store.user.userClientOrg.actions.actions,
    (id, argActions, storeActions) => {
      return id
        ? storeActions.find(action => action.id === id && action.isFetched && argActions.some(a => a === action.type))
            ?.type ?? null
        : null;
    }
  );
