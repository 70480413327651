import { AdCampaignTable } from '@features/adCampaign/table/component';
import { AdCampaignActionCall } from '@features/adCampaign/table/utils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { useAdCampaignActions } from '../../actions/useActions';
import { EAdCampaignTableFilterItem } from '../../filterUtils';
import { AdCampaignLifeCycle } from '../../lifecycle/types';
import {
  AdCampaignActions,
  AdCampaignActionTableType,
  EAdCampaignActionType,
  EAdCampaignTableColumn,
} from '../../types';
import { getAdCampaignTableActions } from '../../utils/actions';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  adCampaignAdCampaignSelector,
  adCampaignFilterSelector,
  adCampaignGuidSelector,
  adCampaignSortColumnSelector,
  adCampaignSortDirectionSelector,
} from '../store/selectors';

interface AdCampaignTableAdapterProps {
  readonly filterAdapter: DataFilterAdapter<EAdCampaignTableColumn, EAdCampaignTableFilterItem>;
  readonly lifecycle: AdCampaignLifeCycle;
  readonly tabActions: AdCampaignActions<AdCampaignActionTableType>;
}

export const AdCampaignTableAdapter = (props: AdCampaignTableAdapterProps) => {
  const { filterAdapter, lifecycle, tabActions } = props;

  const { onAdCampaignClick, onAdCampaignOpenEdit } = useContextConfig();
  const handlers = useContextHandlers();
  const guid = useSelector(adCampaignGuidSelector);
  const sortColumn = useSelector(adCampaignSortColumnSelector);
  const sortDirection = useSelector(adCampaignSortDirectionSelector);
  const adCampaign = useSelector(adCampaignAdCampaignSelector);
  const filter = useSelector(adCampaignFilterSelector);

  const {
    utils: {
      selectors: { isAdCampaignsAnyChangedSelector },
    },
  } = useAdCampaignActions();

  const needRefresh = useSelector(isAdCampaignsAnyChangedSelector);

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  const onActionClick = (call: AdCampaignActionCall) => {
    const { action, adCampaign } = call;

    switch (action) {
      case EAdCampaignActionType.Resume:
        handlers.onAdCampaignTryResume(adCampaign);
        break;
      case EAdCampaignActionType.Archive:
        handlers.onAdCampaignTryArchive(adCampaign);
        break;
      case EAdCampaignActionType.Pause:
        handlers.onAdCampaignTryPause(adCampaign);
        break;
      case EAdCampaignActionType.Edit:
        onAdCampaignOpenEdit(adCampaign);
        break;
    }
  };

  useEffect(() => {
    if (needRefresh > 0) {
      handlers.onRefresh();
    }
  }, [needRefresh, handlers.onRefresh]);

  return (
    <AdCampaignTable
      metadata={metadata}
      onChangeMetadata={onChangeMetadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      adCampaigns={adCampaign}
      onRequestSort={handlers.onChangeSort}
      onAdCampaignAction={onActionClick}
      onAdCampaignClick={onAdCampaignClick}
      getActions={adCampaign => getAdCampaignTableActions(lifecycle, adCampaign, tabActions)}
    />
  );
};
