import { EclipseLine } from './controls';
import BookingOfferTableCell, { BookingOfferTableCellCommonProps } from './index';

const BookingOfferTableCellResponsible = (props: BookingOfferTableCellCommonProps) => {
  const { bookingOffer: offer } = props;

  const responsiblePersons = offer.responsiblePersons;

  const value = (
    <>
      {responsiblePersons?.map((item, index) => (
        <EclipseLine key={index}>{item.fullName ?? '-'}</EclipseLine>
      ))}
    </>
  );

  return (
    <BookingOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default BookingOfferTableCellResponsible;
