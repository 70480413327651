import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../data/store/types';
import { EUserStatus } from '../../../../../domain/model/enums';
import { Partner } from '../../../../../domain/model/partner';
import { Nullable } from '../../../../../domain/model/types';
import { MpUser, MpUserData, MpUserShort } from '../../../../../domain/model/user';
import partnerServices from '../../../general/partner/services';
import { createEmptyAddress } from '../../../../utils/address';

export const partnerRegistrationCreate = createAsyncThunk<Partner, MpUserShort, AppThunkAPIConfig>(
  'partner/registration/create',
  async (user, { rejectWithValue }) => {
    try {
      return await partnerServices.common.create({ owner: user });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const partnerRegistrationInvite = createAsyncThunk<MpUser, MpUserData, AppThunkAPIConfig>(
  'partner/registration/invite',
  async (user, { rejectWithValue }) => {
    try {
      return await partnerServices.common.invite({ user, redirectUri: location.origin });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface PartnerRegistrationState {
  readonly user: MpUserShort;
  readonly create: Fetchable & {
    readonly data: Nullable<Partner>;
  };
  readonly invite: Fetchable & {
    readonly data: Nullable<MpUser>;
  };
}

type Reducer<T = undefined> = CaseReducer<PartnerRegistrationState, PayloadAction<T>>;

const initialUser: MpUserShort = {
  id: '',
  status: EUserStatus.NotFilled,
  email: '',
  firstName: '',
  lastName: '',
  middleName: null,
  photo: null,
  phone: '',
  locality: createEmptyAddress(),
  gender: null,
  birthDate: null,
};

interface Reducers extends SliceCaseReducers<PartnerRegistrationState> {
  partnerRegistrationInit: Reducer;
  partnerRegistrationReset: Reducer;
  partnerRegistrationSetAttribute: Reducer<{ name: keyof MpUserData; value: any }>;
}

const slice = createSlice<PartnerRegistrationState, Reducers, 'registration'>({
  name: 'registration',
  initialState: {
    user: initialUser,
    create: {
      data: null,
      ...fetchableDefault,
    },
    invite: {
      data: null,
      ...fetchableDefault,
    },
  },
  reducers: {
    partnerRegistrationReset: state => {
      state.user = initialUser;
      state.create = {
        ...fetchableDefault,
        data: null,
      };
      state.invite = {
        ...fetchableDefault,
        data: null,
      };
    },
    partnerRegistrationInit: state => {
      state.user = initialUser;
      state.create = {
        ...fetchableDefault,
        data: null,
      };
      state.invite = {
        ...fetchableDefault,
        data: null,
      };
    },
    partnerRegistrationSetAttribute: (state, { payload }) => {
      const { name, value } = payload;
      if (state.user) {
        state.user[name] = value;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(partnerRegistrationCreate.pending, state => {
        state.create = {
          ...fetchableFetching,
          data: null,
        };
      })
      .addCase(partnerRegistrationCreate.fulfilled, (state, { payload }) => {
        state.create = {
          ...fetchableFetched,
          data: payload,
        };
      })
      .addCase(partnerRegistrationCreate.rejected, state => {
        state.create = {
          ...fetchableFailed,
          data: null,
        };
      })
      .addCase(partnerRegistrationInvite.pending, state => {
        state.invite = {
          ...fetchableFetching,
          data: null,
        };
      })
      .addCase(partnerRegistrationInvite.fulfilled, (state, { payload }) => {
        state.invite = {
          ...fetchableFetched,
          data: payload,
        };
      })
      .addCase(partnerRegistrationInvite.rejected, state => {
        state.invite = {
          ...fetchableFailed,
          data: null,
        };
      });
  },
});

export const { partnerRegistrationSetAttribute, partnerRegistrationInit, partnerRegistrationReset } = slice.actions;

export default slice.reducer;
