import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EOfferStatus } from '@/domain/model/enums';
import { Nullable, UUID } from '@/domain/model/types';
import { EOfferTableTab } from '@features/general/offer/types';
import { getStatesFilterForOfferTableTab } from '@features/general/offer/utils/table';
import {
  bookingOfferSearchFilterSelector,
  bookingOfferSearchGuidSelector,
  bookingOfferSearchNeedRefreshWatcherSelector,
  bookingOfferSearchPageNumberSelector,
  bookingOfferSearchSearchSelector,
} from '../store/selectors';
import {
  bookingOfferSearchCountsFetch,
  bookingOfferSearchFetch,
  BookingOfferSearchFetchProps,
  bookingOfferSearchNeedRefreshWatcherReset,
} from '../store/slice';

interface UseBookingOfferSearchSearchProps {
  readonly guid: UUID;
  readonly tab: EOfferTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly statuses: EOfferStatus[];
  readonly tabsForCounts: EOfferTableTab[];
}

const getStatusesByTab = (t: EOfferTableTab, statuses: EOfferStatus[]) => getStatesFilterForOfferTableTab(t, statuses);

const useBookingOfferSearchSearch = (props: UseBookingOfferSearchSearchProps): void => {
  const { guid, tab, partnerId, statuses, tabsForCounts } = props;

  const dispatch = useDispatch();

  const [searchPromises, setSearchPromises] = useState<Nullable<any[]>>(null);

  const needRefreshWatcher = useSelector(bookingOfferSearchNeedRefreshWatcherSelector);
  const pageNumber = useSelector(bookingOfferSearchPageNumberSelector);
  const currentGuid = useSelector(bookingOfferSearchGuidSelector);
  const search = useSelector(bookingOfferSearchSearchSelector);
  const filter = useSelector(bookingOfferSearchFilterSelector);

  const currentStatuses = getStatusesByTab(tab, statuses);

  const queryParams = useMemo<BookingOfferSearchFetchProps>(() => {
    return {
      filter,
      search: {
        ...search,
        statuses: currentStatuses,
        partnerId,
        categories: null,
      },
      pageNumber,
    };
  }, [filter, search, currentStatuses, partnerId, pageNumber]);

  const onSearch = useCallback(() => {
    setSearchPromises([
      dispatch(bookingOfferSearchFetch(queryParams)),
      dispatch(bookingOfferSearchCountsFetch({ ...queryParams, tabs: tabsForCounts })),
    ]);
  }, [dispatch, queryParams]);

  // начальная загрузка
  useEffect(() => {
    if (guid === currentGuid) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, guid, currentGuid]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  // сброс вотчера после ухода
  useEffect(() => {
    return () => {
      dispatch(bookingOfferSearchNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};

export default useBookingOfferSearchSearch;
