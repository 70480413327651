import Api from '../../../../data/api';
import { ApiCancellable, ApiRequestPageable, ApiResponseWithPageable } from '../../../../data/api/types';
import { TeamLogo } from '../../../../domain/model/team';

type ListProps = ApiCancellable & ApiRequestPageable;

export type TeamLogosServices = {
  readonly list: (props: ListProps) => Promise<ApiResponseWithPageable<TeamLogo>>;
};

const service: TeamLogosServices = {
  list: async ({ page, pageSize, sort }) => {
    return (
      await Api.team.logos({
        page,
        pageSize,
        sort,
      })
    ).data;
  },
};

export default service;
