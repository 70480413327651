import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TradeOfferTableCellCommonProps } from '../../components/tableCell';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { tradeOffersSearchTradeOfferByIndexSelector } from '../store/selectors';

export type TradeOffersSearchCellAdapterProps = {
  readonly index: number;
  readonly component: FC<TradeOfferTableCellCommonProps>;
};

const TradeOffersSearchCellAdapter = (props: TradeOffersSearchCellAdapterProps) => {
  const { index, component: Component } = props;

  const handlers = useContextHandlers();
  const tradeOffer = useSelector(tradeOffersSearchTradeOfferByIndexSelector(index));
  const isDisabled = tradeOffer ? handlers.isTradeOfferNotModeratedByMe(tradeOffer) : false;

  return useMemo(
    () =>
      tradeOffer ? (
        <Component
          tradeOffer={tradeOffer}
          isDisabled={isDisabled}
        />
      ) : null,
    [Component, tradeOffer, isDisabled]
  );
};

export default TradeOffersSearchCellAdapter;
