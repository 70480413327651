import { BookingOfferShort, CorpOffer, EOfferType, ProductOffer, TradeOffer, UUID } from '@/domain';
import { RefreshIcon } from '@/presentation/icons';
import { ButtonLink } from '@components/common/buttons/link';
import { CollectionActionsContainer } from '@features/adCampaign/components/offerCollection/controls';
import BookingOfferCollection from '@features/bookingOffer/components/collection';
import { bookingOfferCollectionColumns } from '@features/bookingOffer/components/collection/utils';
import OfferCollection from '@features/general/offer/components/collection';
import {
  getOfferCollectionColumns,
  offerCollectionCorpColumns,
  offerCollectionTradeColumns,
} from '@features/general/offer/components/collection/utils';
import { EOfferTableColumn } from '@features/general/offer/types';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { Grid, Typography } from '@mui/material';

const adCampaignOfferCollectionColumns = [EOfferTableColumn.Code, EOfferTableColumn.Name, EOfferTableColumn.EndDate];

interface AdCampaignsOfferCollectionComponentTradeProps {
  readonly offerType: EOfferType.Trade;
  readonly offers: TradeOffer[];
}

interface AdCampaignsOfferCollectionComponentCorpProps {
  readonly offerType: EOfferType.Corp;
  readonly offers: CorpOffer[];
}

interface AdCampaignsOfferCollectionComponentBookingProps {
  readonly offerType: EOfferType.Booking;
  readonly offers: BookingOfferShort[];
}

interface AdCampaignsOfferCollectionComponentProductProps {
  readonly offerType: EOfferType.Product;
  readonly offers: ProductOffer[];
}

type AdCampaignsOfferCollectionComponentProps = {
  readonly onShowOfferSearch?: () => void;
  readonly onClearOffers?: () => void;
  readonly onRemoveOffer?: (id: UUID) => void;
} & (
  | AdCampaignsOfferCollectionComponentTradeProps
  | AdCampaignsOfferCollectionComponentCorpProps
  | AdCampaignsOfferCollectionComponentBookingProps
  | AdCampaignsOfferCollectionComponentProductProps
);

const AdCampaignsOfferCollectionComponent = (props: AdCampaignsOfferCollectionComponentProps) => {
  const { offers, offerType, onShowOfferSearch, onClearOffers, onRemoveOffer } = props;

  return (
    <Grid
      item
      xs={12}
    >
      {offers.length > 0 && (
        <>
          {offerType === EOfferType.Corp && (
            <OfferCollection<CorpOffer>
              offers={offers}
              columns={getOfferCollectionColumns(adCampaignOfferCollectionColumns, offerCollectionCorpColumns)}
              onRemove={onRemoveOffer}
            />
          )}
          {offerType === EOfferType.Trade && (
            <OfferCollection<TradeOffer>
              columns={getOfferCollectionColumns(adCampaignOfferCollectionColumns, offerCollectionTradeColumns)}
              offers={offers}
              onRemove={onRemoveOffer}
            />
          )}
          {offerType === EOfferType.Booking && (
            <BookingOfferCollection
              columns={bookingOfferCollectionColumns}
              offers={offers}
              onRemove={onRemoveOffer}
            />
          )}
        </>
      )}
      <CollectionActionsContainer>
        {onShowOfferSearch && (
          <ButtonLink onClick={() => onShowOfferSearch()}>
            <AddBoxOutlinedIcon />
            <Typography>Выбрать предложения</Typography>
          </ButtonLink>
        )}

        {onClearOffers && offers.length > 0 && (
          <ButtonLink onClick={() => onClearOffers()}>
            <RefreshIcon />
            <Typography>Очистить список</Typography>
          </ButtonLink>
        )}
      </CollectionActionsContainer>
    </Grid>
  );
};

export default AdCampaignsOfferCollectionComponent;
