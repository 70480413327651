import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { SportOptionTyped } from '../../../../../../../domain/model';
import { ETaxationSystemType } from '../../../../../../../domain/model/enums';
import { Nullable } from '../../../../../../../domain/model/types';

interface PartnerApplicationViewCompanyDetailsProps {
  readonly bic: Nullable<string>;
  readonly checkingAccount: Nullable<string>;
  readonly kpp: Nullable<string>;
  readonly ogrn: Nullable<string>;
  readonly taxSystem: Nullable<ETaxationSystemType>;
  readonly taxationSystemTypes: SportOptionTyped<ETaxationSystemType>[];
}

const PartnerApplicationViewCompanyDetails = (props: PartnerApplicationViewCompanyDetailsProps) => {
  const { bic, checkingAccount, ogrn, kpp, taxSystem, taxationSystemTypes } = props;

  return (
    <Grid
      container
      spacing={2}
      direction='column'
    >
      <Grid
        item
        xs={12}
      >
        <Typography variant='h3'>Реквизиты</Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography color='textSecondary'>БИК</Typography>
        <Typography>{bic ?? '-'}</Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography color='textSecondary'>Расчетный счет компании</Typography>
        <Typography>{checkingAccount ?? '-'}</Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography color='textSecondary'>ОГРН</Typography>
        <Typography>{ogrn ?? '-'}</Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography color='textSecondary'>КПП</Typography>
        <Typography>{kpp ?? '-'}</Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography color='textSecondary'>Система налогообложения</Typography>
        <Typography>{taxationSystemTypes.find(type => type.id === taxSystem)?.name ?? '-'}</Typography>
      </Grid>
    </Grid>
  );
};

export default PartnerApplicationViewCompanyDetails;
