import Api from '@/data/api';
import {
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterValueItem,
  EDataFilterType,
  Nullable,
  pageSizeAll,
} from '@/domain';

export enum EReportBookingOrdersStatisticByPartnerFilterItem {
  DatePeriod = 'datePeriod',
  Partner = 'partner',
}

export type ReportBookingOrdersStatisticByPartnerFilterEditStrategy =
  DataFilterStrategyBase<EReportBookingOrdersStatisticByPartnerFilterItem>;

export type ReportBookingOrdersStatisticByPartnerFilterValues = {
  readonly [EReportBookingOrdersStatisticByPartnerFilterItem.DatePeriod]?: DataFilterValueItem<
    Nullable<[Nullable<string>, Nullable<string>]>
  >;
  readonly [EReportBookingOrdersStatisticByPartnerFilterItem.Partner]?: DataFilterValueItem<
    Nullable<DataFilterStrategyListItem>
  >;
};

export const getReportOffersByBookingOrdersStatisticFilterStrategies = (
  values: ReportBookingOrdersStatisticByPartnerFilterValues,
  partnerId: Nullable<UUID>
): ReportBookingOrdersStatisticByPartnerFilterEditStrategy[] => {
  const strategies: ReportBookingOrdersStatisticByPartnerFilterEditStrategy[] = [];
  strategies.push({
    type: EDataFilterType.DatePeriod,
    key: EReportBookingOrdersStatisticByPartnerFilterItem.DatePeriod,
    label: '',
    labelPeriod: {
      from: 'Период с',
      to: 'по',
    },
    buttonsPeriod: true,
    preview: null,
    value: values[EReportBookingOrdersStatisticByPartnerFilterItem.DatePeriod]?.value || null,
  });

  if (!partnerId) {
    strategies.push({
      type: EDataFilterType.AutocompleteSingle,
      key: EReportBookingOrdersStatisticByPartnerFilterItem.Partner,
      label: 'Партнёр',
      preview: `Партнер (${values[EReportBookingOrdersStatisticByPartnerFilterItem.Partner]?.value?.name})` || null,
      value: values[EReportBookingOrdersStatisticByPartnerFilterItem.Partner]?.value || null,
      source: (name: string) =>
        name
          ? Api.partner
              .all({
                query: name,
                sort: 'name,asc',
                page: 1,
                pageSize: pageSizeAll,
              })
              .then(response => response.data)
          : Promise.resolve([]),
    });
  }

  return strategies;
};
