import { ECmsBannerLinkObjectType, EOfferStatus, EOfferType, Nullable, TradeOffer, UUID } from '@/domain';
import useTechConfig from '@/presentation/hooks/useTechConfig';
import { EAppFeature } from '@/presentation/types';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import { getTradeOfferDetailsRoute } from '@features/tradeOffer/entry';
import { isAspPromotionType } from '@features/tradeOffer/utils';
import { viewConfig } from '@theme/viewConfig';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import TopPushDialog from '../../../../../../components/common/dialogs/topPush';
import TradeOfferSearchContainer from '../../../../../tradeOffer/search/container';
import useCurrentUser from '../../../../../user/hooks/useCurrentUser';
import CmsOfferSingleCollectionComponent from '../../../../components/collection/offers/single';
import { CmsContainerView } from '../../../../types';
import { CmsLinkedObjectBannerTypes } from '../../../../types/banner';
import { CmsFeatureContainerCommonProps } from '../../../types';
import { getCmsSearchTradeOfferPresetValues } from '../../utils';
import useCmsCollectionOfferSingle from '../useChangeOffersSingle';
import useCmsCollectionTradeOfferFilterAdapter from './useFilterAdapter';

interface CmsTradeOfferSingleCollectionContainerProps extends CmsFeatureContainerCommonProps {
  readonly guid: UUID;
  readonly offer: Nullable<TradeOffer>;
  readonly statuses: EOfferStatus[];
  readonly cmsContainer: CmsContainerView;
  readonly onChangeLinkedObject: (banner: CmsLinkedObjectBannerTypes) => void;
}

const CmsTradeOfferSingleCollectionContainer = (props: CmsTradeOfferSingleCollectionContainerProps) => {
  const { guid, offer, statuses, cmsContainer, cmsContext, onChangeLinkedObject } = props;

  const history = useHistory();
  const { accessMatrix } = useCurrentUser();
  const { hasFeature } = useTechConfig();
  const isAspFeature = hasFeature(EAppFeature.Asp);

  const { promotionTypes: tradePromotionTypes, userGenders: genderTypes } = useSelector(nsiDataSelector);
  const promotionTypes = isAspFeature
    ? tradePromotionTypes
    : tradePromotionTypes.filter(promotionType => !isAspPromotionType(promotionType.id));

  const filterPresetValues = useMemo(() => getCmsSearchTradeOfferPresetValues(cmsContainer), [cmsContainer]);

  const {
    isSearchDialogOpened,
    searchDialogParams,
    onOpenSearchDialog,
    onCloseSearchDialog,
    onChangeSearchDialogTab,
    onSelect,
    onClear,
  } = useCmsCollectionOfferSingle<TradeOffer>({
    linkObjectType: ECmsBannerLinkObjectType.TradeOffer,
    onChangeLinkedObject,
  });

  const filterAdapter = useCmsCollectionTradeOfferFilterAdapter({
    viewAsAdmin: accessMatrix.isAdminMp,
    tab: searchDialogParams.dialog_tab,
    partnerId: cmsContext?.partner.id ?? null,
    values: filterPresetValues,
    promotionTypes,
    genderTypes,
  });

  const onOfferClick = useCallback(
    (id: UUID) => {
      history.push(getTradeOfferDetailsRoute({ id }));
    },
    [history]
  );

  return (
    <>
      <CmsOfferSingleCollectionComponent
        offer={offer}
        offerType={EOfferType.Trade}
        onShowOfferSearch={onOpenSearchDialog}
        onRemoveOffer={onClear}
      />
      <TopPushDialog
        fullScreen
        open={isSearchDialogOpened}
        title='Выбор торговых предложений'
        leftOffset={viewConfig.stepper.width}
        onClose={onCloseSearchDialog}
      >
        <TradeOfferSearchContainer
          guid={searchDialogParams?.dialog_guid ?? guid}
          partnerId={cmsContext?.partner.id ?? null}
          statuses={statuses}
          filterAdapter={filterAdapter}
          selectedMaxCount={1}
          initialSelected={offer}
          tab={searchDialogParams.dialog_tab}
          onTradeOfferClick={tradeOffer => onOfferClick(tradeOffer.id)}
          onSelect={onSelect}
          onChangeTableTab={onChangeSearchDialogTab}
        />
      </TopPushDialog>
    </>
  );
};

export default CmsTradeOfferSingleCollectionContainer;
