import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../../data/store/types';
import { DataTreeItem, DataTreeItemMutable, OfferCategory } from '../../../../../../domain/model';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import service from '../../services';
import { buildBookingOfferCategoryTree } from '../../utils';

export const bookingOfferCategoriesEditFetch = createAsyncThunk<OfferCategory[], void, AppThunkAPIConfig>(
  'bookingOfferCategory/edit/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return service.all({ signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const bookingOfferCategoriesEditCreate = createAsyncThunk<OfferCategory, OfferCategory, AppThunkAPIConfig>(
  'bookingOfferCategory/edit/create',
  async (data, { rejectWithValue }) => {
    try {
      return await service.create(data);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const bookingOfferCategoriesEditUpdate = createAsyncThunk<OfferCategory, OfferCategory, AppThunkAPIConfig>(
  'bookingOfferCategory/edit/update',
  async (data, { rejectWithValue }) => {
    try {
      return await service.update({ id: data.id, data });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const bookingOfferCategoriesEditDelete = createAsyncThunk<OfferCategory, OfferCategory, AppThunkAPIConfig>(
  'bookingOfferCategory/edit/delete',
  async (data, { rejectWithValue }) => {
    try {
      await service.delete({ id: data.id });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const bookingOfferCategoriesEditCheckRelationShips = createAsyncThunk<number, OfferCategory, AppThunkAPIConfig>(
  'bookingOfferCategory/edit/checkRelationShips',
  async (data, { rejectWithValue, signal }) => {
    try {
      return await service.getRelationShipsCount({ id: data.id, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface BookingOfferCategoriesEditState {
  readonly fetch: Fetchable & {
    readonly data: Nullable<DataTreeItem<OfferCategory>[]>;
  };
  readonly create: Fetchable;
  readonly delete: Fetchable;
  readonly update: Fetchable;
  readonly checkRelationShips: Fetchable;
  readonly dialogs: {
    readonly add: Nullable<{ parent: Nullable<DataTreeItem<OfferCategory>> }>;
    readonly modify: Nullable<DataTreeItem<OfferCategory>>;
    readonly delete: Nullable<DataTreeItem<OfferCategory>>;
  };
}

type Reducer<T = undefined> = CaseReducer<BookingOfferCategoriesEditState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<BookingOfferCategoriesEditState> {
  readonly bookingOfferCategoriesEditResetState: Reducer;
  readonly bookingOfferCategoriesEditSetDialogState: Reducer<{
    name: keyof BookingOfferCategoriesEditState['dialogs'];
    data: any;
  }>;
}

const slice = createSlice<BookingOfferCategoriesEditState, Reducers, 'bookingOfferCategory/edit'>({
  name: 'bookingOfferCategory/edit',
  initialState: {
    fetch: {
      ...fetchableDefault,
      data: null,
    },
    create: {
      ...fetchableDefault,
    },
    delete: {
      ...fetchableDefault,
    },
    update: {
      ...fetchableDefault,
    },
    checkRelationShips: {
      ...fetchableDefault,
    },
    dialogs: {
      add: null,
      modify: null,
      delete: null,
    },
  },
  reducers: {
    bookingOfferCategoriesEditResetState: state => {
      state.fetch = {
        ...fetchableDefault,
        data: null,
      };
      state.create = {
        ...fetchableDefault,
      };
      state.delete = {
        ...fetchableDefault,
      };
      state.update = {
        ...fetchableDefault,
      };
      state.checkRelationShips = {
        ...fetchableDefault,
      };
      state.dialogs = {
        add: null,
        modify: null,
        delete: null,
      };
    },
    bookingOfferCategoriesEditSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bookingOfferCategoriesEditFetch.pending, state => {
        state.fetch.isFetching = true;
        state.fetch.isFetched = false;
        state.fetch.isFailed = false;
        state.fetch.data = null;
      })
      .addCase(bookingOfferCategoriesEditFetch.fulfilled, (state, { payload }) => {
        state.fetch.isFetching = false;
        state.fetch.isFetched = true;
        state.fetch.isFailed = false;
        state.fetch.data = buildBookingOfferCategoryTree(payload);
      })
      .addCase(bookingOfferCategoriesEditFetch.rejected, state => {
        state.fetch.isFetching = false;
        state.fetch.isFetched = false;
        state.fetch.isFailed = true;
        state.fetch.data = null;
      })
      .addCase(bookingOfferCategoriesEditCreate.pending, state => {
        state.create.isFetching = true;
        state.create.isFetched = false;
        state.create.isFailed = false;
      })
      .addCase(bookingOfferCategoriesEditCreate.fulfilled, (state, { payload }) => {
        state.create.isFetching = false;
        state.create.isFetched = true;
        state.create.isFailed = false;

        if (state.fetch.data) {
          if (payload.parentId) {
            const parent = findItem(state.fetch.data, payload.parentId);
            parent?.children?.push({
              id: payload.id,
              label: payload.name,
              parentId: parent.id,
              data: payload,
              children: [],
            });
          } else {
            state.fetch.data.push({
              id: payload.id,
              label: payload.name,
              parentId: null,
              data: payload,
              children: [],
            });
          }
        }
      })
      .addCase(bookingOfferCategoriesEditCreate.rejected, state => {
        state.create.isFetching = false;
        state.create.isFetched = false;
        state.create.isFailed = true;
      })
      .addCase(bookingOfferCategoriesEditUpdate.pending, state => {
        state.update.isFetching = true;
        state.update.isFetched = false;
        state.update.isFailed = false;
      })
      .addCase(bookingOfferCategoriesEditUpdate.fulfilled, (state, { payload }) => {
        state.update.isFetching = false;
        state.update.isFetched = true;
        state.update.isFailed = false;

        if (state.fetch.data) {
          const item = findItem(state.fetch.data, payload.id);
          if (item) {
            item.id = payload.id;
            item.label = payload.name;
            item.parentId = payload.parentId ?? null;
            item.data = payload;
          }
        }
      })
      .addCase(bookingOfferCategoriesEditUpdate.rejected, state => {
        state.update.isFetching = false;
        state.update.isFetched = false;
        state.update.isFailed = true;
      })
      .addCase(bookingOfferCategoriesEditDelete.pending, state => {
        state.delete.isFetching = true;
        state.delete.isFetched = false;
        state.delete.isFailed = false;
      })
      .addCase(bookingOfferCategoriesEditDelete.fulfilled, (state, { payload }) => {
        state.delete.isFetching = false;
        state.delete.isFetched = true;
        state.delete.isFailed = false;

        if (state.fetch.data) {
          if (payload.parentId) {
            const parent = findItem(state.fetch.data, payload.parentId);
            if (parent?.children) {
              const index = parent.children.findIndex(item => item.id === payload.id);
              parent.children.splice(index, 1);
            }
          } else {
            const index = state.fetch.data.findIndex(item => item.id === payload.id);
            state.fetch.data.splice(index, 1);
          }
        }
      })
      .addCase(bookingOfferCategoriesEditDelete.rejected, state => {
        state.delete.isFetching = false;
        state.delete.isFetched = false;
        state.delete.isFailed = true;
      })
      .addCase(bookingOfferCategoriesEditCheckRelationShips.pending, state => {
        state.checkRelationShips.isFetching = true;
        state.checkRelationShips.isFetched = false;
        state.checkRelationShips.isFailed = false;
      })
      .addCase(bookingOfferCategoriesEditCheckRelationShips.fulfilled, (state, { payload }) => {
        state.checkRelationShips.isFetching = false;
        state.checkRelationShips.isFetched = true;
        state.checkRelationShips.isFailed = false;
      })
      .addCase(bookingOfferCategoriesEditCheckRelationShips.rejected, state => {
        state.checkRelationShips.isFetching = false;
        state.checkRelationShips.isFetched = false;
        state.checkRelationShips.isFailed = true;
      });
  },
});

export const { bookingOfferCategoriesEditResetState, bookingOfferCategoriesEditSetDialogState } = slice.actions;

const findChild = (item: DataTreeItem<OfferCategory>, id: UUID): Nullable<DataTreeItemMutable<OfferCategory>> =>
  item.id === id
    ? item
    : item.children?.reduce<Nullable<DataTreeItem<OfferCategory>>>((result, n) => result || findChild(n, id), null) ??
      null;

const findItem = (tree: DataTreeItem<OfferCategory>[], id: UUID): Nullable<DataTreeItemMutable<OfferCategory>> => {
  let result: Nullable<DataTreeItemMutable<OfferCategory>> = null;
  for (let i = 0; i < tree.length; i++) {
    result = findChild(tree[i], id);
    if (result) {
      break;
    }
  }
  return result;
};

export default slice.reducer;
