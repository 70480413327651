import { useSelector } from 'react-redux';
import { EBookingOfferStep } from '../../types';
import { StepWrapper } from '../controls';
import { useContextParams } from '../hooks/useContextParams';
import BookingOfferDetailsStepServices from '../steps/services';
import { bookingOfferDetailsBookingOfferSelector } from '../store/selectors';

const currentStep = EBookingOfferStep.Services;

export const BookingOfferDetailsStepServicesAdapter = () => {
  const { step } = useContextParams();

  const bookingOffer = useSelector(bookingOfferDetailsBookingOfferSelector);

  if (!bookingOffer) {
    return null;
  }

  return step === currentStep ? (
    <StepWrapper>
      <BookingOfferDetailsStepServices bookingOffer={bookingOffer} />
    </StepWrapper>
  ) : null;
};
