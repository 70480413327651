import { Grid, Typography } from '@mui/material';
import { SportUserProfile } from '../../../../../domain/model/user';
import { AddressLocalityFullPathView } from '../../../address';
import { UserAvatar } from '../../../../components/common/avatars';
import ContentLoader from '../../../../components/common/loader';
import { pluralize } from '../../../../utils/pluralize';
import { BonusInfo } from '../../types';
import { getSportsmanGender } from '../utils';
import { Bold } from './controls';

interface SportsmanDetailsHeaderProps {
  readonly sportsman: SportUserProfile;
  readonly bonus: BonusInfo;
}

const SportsmanDetailsHeader = ({ sportsman, bonus }: SportsmanDetailsHeaderProps) => {
  return (
    <Grid
      container
      spacing={2}
      wrap='nowrap'
      alignItems='center'
    >
      <Grid item>
        <UserAvatar
          size='largest'
          user={sportsman}
        />
      </Grid>
      <Grid
        item
        container
        spacing={1}
        direction='column'
      >
        <Grid item>
          <Bold>{[sportsman.lastName, sportsman.firstName].filter(item => !!item).join(' ')}</Bold>
        </Grid>
        <Grid
          item
          container
          spacing={1}
        >
          {sportsman.locality && (
            <Grid item>
              <AddressLocalityFullPathView address={sportsman.locality} />
            </Grid>
          )}
        </Grid>
        <Grid
          item
          container
          spacing={1}
        >
          {sportsman.gender && (
            <Grid item>
              <Typography>{getSportsmanGender(sportsman.gender)}</Typography>
            </Grid>
          )}
        </Grid>
        <Grid item>
          {bonus.isFetching ? (
            <ContentLoader
              size={18}
              position='relative'
              verticalPosition='flex-start'
            />
          ) : (
            <Bold>
              {bonus.count} {!bonus.count ? '' : pluralize(bonus.count, ['бонус', 'бонуса', 'бонусов'])}
            </Bold>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SportsmanDetailsHeader;
