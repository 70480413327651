import CmsContainerTitle from '../../../components/titleControl';
import { CmsContainerCreateComponentProps } from '../types';

type CmsContainerEditComponentTitleProps = CmsContainerCreateComponentProps;

const CmsContainerEditComponentTitle = (props: CmsContainerEditComponentTitleProps) => {
  const { cmsContainer, validation, onChange } = props;

  return (
    <CmsContainerTitle
      title={cmsContainer.title}
      showTitle={cmsContainer.showTitle ?? false}
      error={validation?.title?.hasError}
      helperText={validation?.title?.message}
      onChangeTitle={t => onChange('title', t)}
      onChangeShowTitle={s => onChange('showTitle', s)}
    />
  );
};

export default CmsContainerEditComponentTitle;
