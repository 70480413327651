import { getUserFullName } from '../../../../utils';
import PartnerEmployeeTableCell, { PartnerEmployeeTableCellCommonProps } from './index';

const PartnerEmployeeTableCellName = (props: PartnerEmployeeTableCellCommonProps) => {
  const { employee } = props;

  const value = getUserFullName({ user: employee });

  return (
    <PartnerEmployeeTableCell
      {...props}
      value={value}
    />
  );
};

export default PartnerEmployeeTableCellName;
