import { getOfferPausedReasonText } from '../../../general/offer/utils/common';
import TradeOfferTableCell, { TradeOfferTableCellCommonProps } from './index';

const TradeOfferTableCellPausedReason = (props: TradeOfferTableCellCommonProps) => {
  const {
    tradeOffer: { pausedReason },
  } = props;

  const value = pausedReason ? getOfferPausedReasonText(pausedReason) : '-';

  return (
    <TradeOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default TradeOfferTableCellPausedReason;
