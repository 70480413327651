import { Checkbox } from '@mui/material';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import React from 'react';
import { RoadOption, TextOption } from './controls';
import {
  OrgStructureRoadMultipleAllRoadsState,
  OrgStructureRoadMultipleItemType,
  OrgStructureRoadMultipleTypesSelectorItem,
} from './types';

interface OrgStructureRoadMultipleItemProps {
  readonly elementProps: React.HTMLAttributes<HTMLLIElement>;
  readonly option: OrgStructureRoadMultipleTypesSelectorItem;
  readonly state: AutocompleteRenderOptionState;
}

const OrgStructureRoadMultipleItem = ({ elementProps, option, state }: OrgStructureRoadMultipleItemProps) => {
  switch (option.type) {
    case OrgStructureRoadMultipleItemType.Text:
      return (
        <TextOption
          color='textSecondary'
          variant='body2'
        >
          {option.name}
        </TextOption>
      );
    case OrgStructureRoadMultipleItemType.All:
      return (
        <RoadOption {...elementProps}>
          <Checkbox
            color='primary'
            checked={state.selected}
            indeterminate={option.state === OrgStructureRoadMultipleAllRoadsState.Indeterminate}
          />
          {option.name}
        </RoadOption>
      );
    case OrgStructureRoadMultipleItemType.None:
      return (
        <RoadOption {...elementProps}>
          <Checkbox
            color='primary'
            checked={state.selected}
          />
          {option.name}
        </RoadOption>
      );
    case OrgStructureRoadMultipleItemType.Road:
      return (
        <RoadOption {...elementProps}>
          <Checkbox
            color='primary'
            checked={state.selected}
          />
          {option.name}
        </RoadOption>
      );
  }
};

export default OrgStructureRoadMultipleItem;

export const getOrgStructureRoadMultipleItemAllRoads = (
  state: OrgStructureRoadMultipleAllRoadsState
): OrgStructureRoadMultipleTypesSelectorItem => ({
  type: OrgStructureRoadMultipleItemType.All,
  id: OrgStructureRoadMultipleItemType.All,
  name: 'Все дороги',
  state,
});

export const getOrgStructureRoadMultipleItemNoneRoads = (
  state: OrgStructureRoadMultipleAllRoadsState
): OrgStructureRoadMultipleTypesSelectorItem => ({
  type: OrgStructureRoadMultipleItemType.None,
  id: OrgStructureRoadMultipleItemType.None,
  name: 'не выбрано',
  state,
});

export const getOrgStructureRoadMultipleItemDefaultSourceHeader = (): OrgStructureRoadMultipleTypesSelectorItem => ({
  type: OrgStructureRoadMultipleItemType.Text,
  id: 'default_source_header',
  name: 'Самые часто используемые дороги',
});
