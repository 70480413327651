import { EAdCampaignType } from '@/domain';
import {
  adCampaignCreateAdCampaignStatusSelector,
  adCampaignCreateAdCampaignTypeSelector,
} from '@features/adCampaign/create/store/selectors';
import { adCampaignCreateSetUiState } from '@features/adCampaign/create/store/slice';
import { AdCampaignCreateUiState } from '@features/adCampaign/create/types';
import {
  catalogUiFieldsState,
  defaultUiFieldsState,
  searchUiFieldsState,
} from '@features/adCampaign/create/uiState/constants';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const AdCampaignCreateUiFieldsState = () => {
  const dispatch = useDispatch();

  const status = useSelector(adCampaignCreateAdCampaignStatusSelector);
  const type = useSelector(adCampaignCreateAdCampaignTypeSelector);

  const value = useMemo<AdCampaignCreateUiState['fields']>(() => {
    let result: AdCampaignCreateUiState['fields'];

    switch (type) {
      case null:
        result = defaultUiFieldsState;
        break;
      case EAdCampaignType.Search:
        result = searchUiFieldsState;
        break;
      case EAdCampaignType.Catalog:
        result = catalogUiFieldsState;
        break;
    }
    if (status !== null) {
      result = { ...result, disabled: [...result.disabled, 'type'] };
    }

    return result;
  }, [status, type]);

  useEffect(() => {
    dispatch(adCampaignCreateSetUiState({ name: 'fields', value }));
  }, [dispatch, value]);

  return null;
};
