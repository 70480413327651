import { useMemo } from 'react';
import { ECmsContainerType } from '../../../../domain/model/enums';
import { UUID } from '../../../../domain/model/types';
import CmsContainerByIdContainer from '../../cms/container/byId/container';
import CmsContainerCreateContainer from '../../cms/container/create/container';
import { CmsContainerLifeCycle } from '../../cms/container/lifecycle/types';
import { CmsFeatureContextPartner } from '../../cms/container/types';
import CmsSitePageForPartnerContainer from '../../cms/sitePage/forPartner/container';

type PartnerWindowCmsContainerCreateContainerProps = {
  readonly partnerId: UUID;
  readonly guid: UUID;
  readonly step: number;
  readonly lifecycle: CmsContainerLifeCycle;
  readonly canCreate?: boolean;
  readonly canSave?: boolean;
  readonly canPublish?: boolean;
  readonly canDelete?: boolean;
  readonly canChangeOfferIconVisible?: boolean;
  readonly onClose: () => void;
} & (
  | {
      readonly id: UUID;
      readonly type?: undefined;
    }
  | {
      readonly id?: undefined;
      readonly type: ECmsContainerType;
    }
);

const PartnerWindowCmsContainerCreateContainer = (props: PartnerWindowCmsContainerCreateContainerProps) => {
  const {
    id,
    guid,
    step,
    type,
    lifecycle,
    partnerId,
    canDelete,
    canCreate,
    canPublish,
    canSave,
    canChangeOfferIconVisible,
    onClose,
  } = props;

  const cmsContext = useMemo<CmsFeatureContextPartner>(() => ({ partner: { id: partnerId } }), [partnerId]);

  if (id) {
    return (
      <CmsContainerByIdContainer id={id}>
        {({ cmsSitePage, cmsContainer }) => (
          <CmsContainerCreateContainer
            cmsContext={cmsContext}
            cmsSitePage={cmsSitePage}
            id={id}
            guid={guid}
            step={step}
            type={cmsContainer.type!.code}
            lifecycle={lifecycle}
            onClose={onClose}
            canCreate={canCreate}
            canSave={canSave}
            canPublish={canPublish}
            canDelete={canDelete}
            canChangeOfferIconVisible={canChangeOfferIconVisible}
          />
        )}
      </CmsContainerByIdContainer>
    );
  } else {
    return (
      <CmsSitePageForPartnerContainer partnerId={partnerId}>
        {({ cmsSitePage }) => (
          <CmsContainerCreateContainer
            cmsContext={cmsContext}
            cmsSitePage={cmsSitePage}
            id={null}
            guid={guid}
            step={step}
            type={type!}
            lifecycle={lifecycle}
            onClose={onClose}
            canCreate={canCreate}
            canSave={canSave}
            canPublish={canPublish}
            canDelete={canDelete}
            canChangeOfferIconVisible={canChangeOfferIconVisible}
          />
        )}
      </CmsSitePageForPartnerContainer>
    );
  }
};

export default PartnerWindowCmsContainerCreateContainer;
