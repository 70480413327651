import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { SportOptionTyped } from '../../../../../../../domain/model';
import { EPartnerOwnershipType } from '../../../../../../../domain/model/enums';
import { Nullable } from '../../../../../../../domain/model/types';

interface PartnerApplicationViewCompanyGeneralProps {
  readonly ownerShipType: Nullable<EPartnerOwnershipType>;
  readonly inn: Nullable<string>;
  readonly name: Nullable<string>;
  readonly companyName: Nullable<string>;
  readonly ownerFullName: Nullable<string>;
  readonly email: Nullable<string>;
  readonly website: Nullable<string>;
  readonly phone: Nullable<string>;
  readonly ownerShipTypes: SportOptionTyped<EPartnerOwnershipType>[];
}

const PartnerApplicationViewCompanyGeneral = (props: PartnerApplicationViewCompanyGeneralProps) => {
  const { ownerShipType, inn, name, companyName, ownerFullName, email, website, phone, ownerShipTypes } = props;

  return (
    <Grid
      container
      spacing={3}
      direction='column'
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          variant='h3'
          gutterBottom
        >
          Организационно-правовая форма
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography color='textSecondary'>Организационно-правовая форма</Typography>
        <Typography>{ownerShipTypes.find(type => type.id === ownerShipType)?.name ?? '-'}</Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography variant='h4'>Общая информация</Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography color='textSecondary'>ИНН</Typography>
        <Typography>{inn ?? '-'}</Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography color='textSecondary'>Юридическое название компании</Typography>
        <Typography>{companyName ?? '-'}</Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography color='textSecondary'>Название компании, которое увидят покупатели</Typography>
        <Typography>{name ?? '-'}</Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography color='textSecondary'>ФИО генерального директора</Typography>
        <Typography>{ownerFullName ?? '-'}</Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography color='textSecondary'>Электронная почта</Typography>
        <Typography>{email ?? '-'}</Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography color='textSecondary'>Сайт компании</Typography>
        <Typography>{website ?? '-'}</Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography color='textSecondary'>Контактный телефон</Typography>
        <Typography>{phone ?? '-'}</Typography>
      </Grid>
    </Grid>
  );
};

export default PartnerApplicationViewCompanyGeneral;
