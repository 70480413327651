import TradeActivationTableCell, { TradeActivationTableCellCommonProps } from './index';

const TradeActivationTableCellOfferName = (props: TradeActivationTableCellCommonProps) => {
  const {
    tradeActivation: {
      offer: { name },
    },
  } = props;

  return (
    <TradeActivationTableCell
      {...props}
      value={name}
    />
  );
};

export default TradeActivationTableCellOfferName;
