import DataTableCellTextFormat from '@components/common/table/cell/textFormat';

interface BookingOfferTableCellCodeProps {
  readonly code: Nullable<string>;
}

const BookingOfferTableCellCode = ({ code }: BookingOfferTableCellCodeProps) => (
  <DataTableCellTextFormat>{code ?? '-'}</DataTableCellTextFormat>
);

export default BookingOfferTableCellCode;
