import { useSelector } from 'react-redux';
import { OfferCategory } from '../../../../../../domain/model';
import { DictionaryTreeEdit } from '../../../../../components/common/dictionary/tree';
import { DictionaryEditItem } from '../../../../../components/common/dictionary/tree/item';
import { DictionaryAddItem } from '../../../../../components/common/dictionary/tree/item/add';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { corpOfferCategoriesEditDataSelector } from '../store/selectors';
import { CorpOfferCategoriesEditAccess } from '../types';

const levelsLimit = 2;

type CorpOfferCategoriesEditDictionaryAdapterProps = {
  readonly access: CorpOfferCategoriesEditAccess;
};

export const CorpOfferCategoriesEditDictionaryAdapter = (props: CorpOfferCategoriesEditDictionaryAdapterProps) => {
  const { access } = props;

  const data = useSelector(corpOfferCategoriesEditDataSelector);

  const handlers = useContextHandlers();

  return data ? (
    <DictionaryTreeEdit<OfferCategory>
      data={data}
      slots={{
        item: props => (
          <DictionaryEditItem
            {...props}
            hideTreeIcon
            onEdit={access.canEdit ? () => handlers.onChangeDialogState('modify', props.data) : undefined}
            onDelete={access.canDelete ? () => handlers.onChangeDialogState('delete', props.data) : undefined}
          />
        ),
        levelHeader: access.canCreate
          ? props => (
              <DictionaryAddItem
                {...props}
                fixed
                onClick={() => handlers.onChangeDialogState('add', { parent: props.parent })}
              >
                Добавить категорию
              </DictionaryAddItem>
            )
          : undefined,
      }}
      hasLevel={level => level + 1 <= levelsLimit}
    />
  ) : null;
};
