import { MasterDetailsLayout } from '../../../../layouts/masterDetails/container';
import { CommonPreloaderDecorator } from '../../../general/decorators/preloader';
import { CommonUpdaterDecorator } from '../../../general/decorators/updater';
import MasterDetailsContainer from '../../../general/master/details/container';
import {
  BookingServicePriceUnitsEditDialogsAdapter,
  BookingServicePriceUnitsEditDictionaryAdapter,
  BookingServicePriceUnitsEditHeaderAdapter,
  BookingServicePriceUnitsEditStepperAdapter,
} from './adapters';
import { BookingServicePriceUnitsEditInitializer } from './initializer';
import { BookingServicePriceUnitsEditHandlersProvider } from './providers';
import {
  bookingServicePriceUnitsEditIsLoadingSelector,
  bookingServicePriceUnitsEditIsPreloadingSelector,
} from './store/selectors';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import ForbiddenComponent from '../../../../components/common/forbidden';
import { BookingServicePriceUnitsEditAccess } from './types';

const BookingServicePriceUnitsEditContainer = () => {
  const { bookingOfferPriceUnits } = useCurrentUser().accessMatrix;
  const access: BookingServicePriceUnitsEditAccess = {
    canCreate: bookingOfferPriceUnits.create,
    canEdit: bookingOfferPriceUnits.edit,
    canDelete: bookingOfferPriceUnits.delete,
  };

  if (!bookingOfferPriceUnits.view) {
    return <ForbiddenComponent />;
  }

  return (
    <MasterDetailsContainer
      layout={MasterDetailsLayout}
      layoutProps={{
        stepper: BookingServicePriceUnitsEditStepperAdapter,
        header: BookingServicePriceUnitsEditHeaderAdapter,
        decorators: [
          <CommonPreloaderDecorator
            key='preloader'
            isFetchingSelector={bookingServicePriceUnitsEditIsPreloadingSelector}
          />,
          <CommonUpdaterDecorator
            key='updater'
            isFetchingSelector={bookingServicePriceUnitsEditIsLoadingSelector}
          />,
        ],
      }}
      initializer={BookingServicePriceUnitsEditInitializer}
      providers={[
        {
          name: 'handlers',
          factory: BookingServicePriceUnitsEditHandlersProvider,
        },
      ]}
      commonProps={{
        access,
      }}
    >
      <BookingServicePriceUnitsEditDictionaryAdapter access={access} />

      <BookingServicePriceUnitsEditDialogsAdapter />
    </MasterDetailsContainer>
  );
};

export default BookingServicePriceUnitsEditContainer;
