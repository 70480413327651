import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { UUID } from '../../../../domain/model/types';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import TableCommonLayout from '../../../layouts/tableCommon/container';
import { EPartnerTableColumn, EPartnerTableTab } from '../../general/partner/types';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import { EPartnerTableFilterItem } from '../filterUtils';
import PartnersTableFilterAdapter from './adapters/filter';
import PartnersTableHeaderAdapter from './adapters/header';
import PartnersTableAdapter from './adapters/table';
import { PartnersTableTabsAdapter } from './adapters/tabs';
import { PartnersTableContext } from './context';
import PartnersTableFooterContainer from './footerContainer';
import usePartnerTableActions from './hooks/useActions';
import { usePartnersTableHandlers } from './hooks/usePartnersTable';
import usePartnersTableSearch from './hooks/useSearch';
import usePartnersTableSession from './hooks/useSession';
import { partnersGuidSelector } from './store/selectors';

interface PartnerTableContainerProps {
  readonly guid: UUID;
  readonly tab: EPartnerTableTab;
  readonly filterAdapter: DataFilterAdapter<EPartnerTableColumn, EPartnerTableFilterItem>;
  readonly canCreate?: boolean;
  readonly canDeleteDraft?: boolean;
  readonly canActivate?: boolean;
  readonly canDeactivate?: boolean;
  readonly canReturnToVerification?: boolean;
  readonly canApprove?: boolean;
  readonly canEdit?: boolean;
}

const PartnerTableContainer = (props: PartnerTableContainerProps) => {
  const {
    guid,
    tab,
    filterAdapter,
    canCreate,
    canActivate,
    canDeactivate,
    canDeleteDraft,
    canReturnToVerification,
    //canApprove,
    //canEdit,
  } = props;

  const handlers = usePartnersTableHandlers({ tab });

  const { id: userId } = useCurrentUser().userSpecific;

  const currentGuid = useSelector(partnersGuidSelector);

  usePartnersTableSession({ guid, tab });
  usePartnersTableSearch({ tab, userId });

  const { getPartnerActions } = usePartnerTableActions({
    canActivate,
    canDeactivate,
    canReturnToVerification,
    canDelete: canDeleteDraft,
    tab,
    userId,
  });

  if (guid !== currentGuid) {
    return null;
  }

  return (
    <PartnersTableContext.Provider value={handlers}>
      <TableCommonLayout
        header={<PartnersTableHeaderAdapter canCreate={canCreate} />}
        filter={<PartnersTableFilterAdapter filterAdapter={filterAdapter} />}
        tabs={<PartnersTableTabsAdapter tab={tab} />}
        table={
          <PartnersTableAdapter
            filterAdapter={filterAdapter}
            getActions={getPartnerActions}
          />
        }
        footer={forwardRef((props, ref: any) => (
          <PartnersTableFooterContainer
            ref={ref}
            tab={tab}
          />
        ))}
      />
    </PartnersTableContext.Provider>
  );
};

export default PartnerTableContainer;
