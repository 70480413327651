import { Grid, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { ClipIcon } from '../../../../icons';
import { FileButton } from './controls';
import { FileUploaderAdapterType } from './types';

export const FileUploaderButtonAdapter: FileUploaderAdapterType = props => {
  const { text, description, accept, onUpload, disabled } = props;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: files => onUpload?.(files),
    maxFiles: 1,
  });

  return (
    <Grid
      container
      spacing={1}
      direction='column'
    >
      <Grid item>
        <FileButton
          {...getRootProps()}
          fullWidth={false}
          disabled={disabled}
          color={'secondary'}
          variant='contained'
          startIcon={<ClipIcon />}
        >
          {text}
          <input
            {...getInputProps()}
            multiple={false}
            disabled={disabled}
            accept={accept?.join(',')}
          />
        </FileButton>
      </Grid>
      <Grid item>
        {typeof description === 'string' ? (
          <Typography
            variant='body2'
            color='textSecondary'
          >
            {description}
          </Typography>
        ) : (
          <>{description}</>
        )}
      </Grid>
    </Grid>
  );
};
