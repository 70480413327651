import styled from '@emotion/styled';

interface StyledSplitterCommonProps {
  readonly size: number | string;
}

interface StyledSplitterProps extends StyledSplitterCommonProps {
  readonly variant: 'vertical' | 'horizontal';
}

const VerticalSplitter = styled.div<StyledSplitterCommonProps>`
  width: ${p => (typeof p.size === 'string' ? p.size : p.theme.spacing(p.size))};
  height: 100%;
`;

const HorizontalSplitter = styled.div<StyledSplitterCommonProps>`
  width: 100%;
  height: ${p => (typeof p.size === 'string' ? p.size : p.theme.spacing(p.size))};
`;

export const StyledSplitter = styled((props: StyledSplitterProps) =>
  props.variant === 'vertical' ? <VerticalSplitter size={props.size} /> : <HorizontalSplitter size={props.size} />
)``;
