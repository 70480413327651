import { RootState } from '@/data/store/store';
import { TradeOfferActivation } from '@/domain/model/activation';
import { ESortDirection } from '@/domain/model/enums';
import { Nullable } from '@/domain/model/types';
import { createSelector } from '@reduxjs/toolkit';
import { ETradeActivationTableColumn } from '../../types';

export const tradeActivationsGuidSelector = (store: RootState) => store.tradeActivation.list.guid;

export const tradeActivationsActivationsSelector = (store: RootState) =>
  store.tradeActivation.list.tradeActivations.data;
export const tradeActivationsIsFetchingSelector = (store: RootState) =>
  store.tradeActivation.list.tradeActivations.isFetching;

const tradeActivationsTradeActivationIndexSelector = (store: RootState, index: number) => index;
const createTradeActivationTableTradeActivationByIndexSelector = createSelector(
  tradeActivationsActivationsSelector,
  tradeActivationsTradeActivationIndexSelector,
  (tradeActivations, index): Nullable<TradeOfferActivation> => tradeActivations?.[index] ?? null
);
export const tradeActivationsTradeActivationByIndexSelector = (index: number) => (store: RootState) =>
  createTradeActivationTableTradeActivationByIndexSelector(store, index);

export const tradeActivationsPageNumberSelector = (store: RootState) =>
  store.tradeActivation.list.tradeActivations.pageNumber;
export const tradeActivationsTotalCountSelector = (store: RootState) =>
  store.tradeActivation.list.tradeActivations.totalCount;
export const tradeActivationsPageCountSelector = (store: RootState) =>
  store.tradeActivation.list.tradeActivations.pageCount;

export const tradeActivationsSearchSelector = (store: RootState) => store.tradeActivation.list.search;
export const tradeActivationsFilterSelector = (store: RootState) => store.tradeActivation.list.filter;

export const tradeActivationsSortSelector = (store: RootState) => store.tradeActivation.list.search.sort;

export const tradeActivationsSortColumnSelector = (store: RootState) =>
  (store.tradeActivation.list.search.sort?.split(',') as [ETradeActivationTableColumn, ESortDirection])?.[0] ?? '';
export const tradeActivationsSortDirectionSelector = (store: RootState) =>
  (store.tradeActivation.list.search.sort?.split(',') as [ETradeActivationTableColumn, ESortDirection])?.[1] ?? '';

export const tradeActivationsNeedRefreshWatcherSelector = (store: RootState) =>
  store.tradeActivation.list.needRefreshWatcher;
