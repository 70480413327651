import { FileDescription, Nullable, PartnerView, TradeOfferRequest } from '@/domain';
import TradeOfferPreviewAspTag from '@features/tradeOffer/components/preview/aspTag';
import TradeOfferPreviewPrice from '@features/tradeOffer/components/preview/price';
import TradeOfferPreviewTitle from '@features/tradeOffer/components/preview/title';
import { isAspPromotionType } from '@features/tradeOffer/utils';
import LikesIcon from '@mui/icons-material/Favorite';
import { EEntityPreviewMode } from '../../../../types';
import OfferPreview from '../../../general/offer/components/preview';
import { OfferPreviewSkeleton } from '../../../general/offer/components/preview/controls';
import { tradeOfferImageRestrictions } from '../../create/utils';
import { DescriptionSkeletonWrapper, TradeOfferPreviewWrapper } from './controls';
import TradeOfferPreviewFooter from './footer';

type TradeOfferPreviewProps = {
  readonly mode?: EEntityPreviewMode;
  readonly logo: Nullable<string | FileDescription>;
  readonly partner: PartnerView;
  readonly tradeOffer: TradeOfferRequest;
  readonly border?: boolean;
};

const TradeOfferPreview = ({
  mode = EEntityPreviewMode.Desktop,
  tradeOffer,
  partner,
  logo,
  border,
}: TradeOfferPreviewProps) => {
  const { title, name, images, promotionType, price } = tradeOffer;
  const imageRatio =
    tradeOfferImageRestrictions.recommendedWidthInPx / tradeOfferImageRestrictions.recommendedHeightInPx;

  const isAspPromotion = promotionType && isAspPromotionType(promotionType);

  return (
    <TradeOfferPreviewWrapper mode={mode}>
      <OfferPreview
        mode={mode}
        border={border}
        image={images?.[0] ?? null}
        imageRatio={imageRatio}
        description={
          name ? (
            name
          ) : (
            <DescriptionSkeletonWrapper>
              <OfferPreviewSkeleton
                animation={false}
                width='100%'
                variant='rectangular'
                height={mode === EEntityPreviewMode.Mobile ? '1rem' : '1.25rem'}
              />
              <OfferPreviewSkeleton
                animation={false}
                width={mode === EEntityPreviewMode.Mobile ? '100%' : '70%'}
                variant='rectangular'
                height={mode === EEntityPreviewMode.Mobile ? '1rem' : '1.25rem'}
              />
              {mode === EEntityPreviewMode.Mobile && (
                <OfferPreviewSkeleton
                  animation={false}
                  width='70%'
                  variant='rectangular'
                  height='1rem'
                />
              )}
            </DescriptionSkeletonWrapper>
          )
        }
        header={
          isAspPromotion ? (
            <TradeOfferPreviewPrice
              mode={mode}
              price={price}
            />
          ) : (
            <TradeOfferPreviewTitle
              mode={mode}
              title={title}
            />
          )
        }
        info={
          <TradeOfferPreviewFooter
            mode={mode}
            partnerName={partner.name}
            logo={logo}
          />
        }
        favorite={<LikesIcon color='disabled' />}
        tags={isAspPromotion ? <TradeOfferPreviewAspTag mode={mode} /> : undefined}
      />
    </TradeOfferPreviewWrapper>
  );
};

export default TradeOfferPreview;
