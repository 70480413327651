import { MenuItem } from '@mui/material';
import React, { forwardRef } from 'react';

interface TableActionItemProps {
  readonly disabled: boolean;
  readonly children: React.ReactNode;
  readonly onExecute: () => void;
}

const TableActionItem = forwardRef((props: TableActionItemProps, ref: any) => {
  const { disabled, children, onExecute } = props;

  return (
    <MenuItem
      ref={ref}
      disabled={disabled}
      onClick={onExecute}
    >
      {children}
    </MenuItem>
  );
});

export default TableActionItem;
