import ConfirmDialog from '@components/common/dialogs/confirm';
import ContentLoader from '@components/common/loader';
import { FormHelperText, Grid } from '@mui/material';
import { MPButton } from '@ui-kit/button';
import { FCC, ReactNode } from 'react';

interface ReportDialogLayoutProps {
  readonly title?: ReactNode;
  readonly filter?: ReactNode;
  readonly error?: ReactNode;
  readonly isFetching?: boolean;
  readonly onFetch?: () => void;
  readonly onClearFilter?: () => void;
  readonly onClose?: () => void;
}

const ReportDialogLayout: FCC<ReportDialogLayoutProps> = props => {
  const { title, filter, error, isFetching, onFetch, onClearFilter, onClose, children } = props;

  return (
    <ConfirmDialog
      open
      title={title}
      dialogButtons={
        <Grid
          container
          spacing={2}
        >
          {onFetch && (
            <Grid item>
              <MPButton
                disable={isFetching}
                onClick={onFetch}
              >
                Скачать отчет
              </MPButton>
            </Grid>
          )}
          {onClearFilter && (
            <Grid item>
              <MPButton
                variant='outlined'
                disable={isFetching}
                onClick={onClearFilter}
              >
                Сбросить
              </MPButton>
            </Grid>
          )}
        </Grid>
      }
      onClose={onClose}
    >
      {filter}
      {children}
      {error && <FormHelperText error>{error}</FormHelperText>}
      {isFetching && <ContentLoader alpha />}
    </ConfirmDialog>
  );
};

export default ReportDialogLayout;
