import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EOfferStatus } from '../../../../../domain/model/enums';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { EOfferTableTab } from '../../../general/offer/types';
import { getStatesFilterForOfferTableTab } from '../../../general/offer/utils/table';
import {
  corpOffersSearchFilterSelector,
  corpOffersSearchGuidSelector,
  corpOffersSearchNeedRefreshWatcherSelector,
  corpOffersSearchPageNumberSelector,
  corpOffersSearchSearchSelector,
} from '../store/selectors';
import {
  corpOffersSearchCountsFetch,
  corpOffersSearchFetch,
  CorpOffersSearchFetchProps,
  corpOffersSearchNeedRefreshWatcherReset,
} from '../store/slice';

interface UseCorpOffersSearchSearchProps {
  readonly guid: UUID;
  readonly tab: EOfferTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly statuses: EOfferStatus[];
  readonly tabsForCounts: EOfferTableTab[];
}

const getStatusesByTab = (t: EOfferTableTab, statuses: EOfferStatus[]) => getStatesFilterForOfferTableTab(t, statuses);

const useCorpOffersSearchSearch = (props: UseCorpOffersSearchSearchProps): void => {
  const { guid, tab, partnerId, statuses, tabsForCounts } = props;

  const dispatch = useDispatch();

  const [searchPromises, setSearchPromises] = useState<Nullable<any[]>>(null);

  const needRefreshWatcher = useSelector(corpOffersSearchNeedRefreshWatcherSelector);
  const pageNumber = useSelector(corpOffersSearchPageNumberSelector);
  const currentGuid = useSelector(corpOffersSearchGuidSelector);
  const search = useSelector(corpOffersSearchSearchSelector);
  const filter = useSelector(corpOffersSearchFilterSelector);

  const currentStatuses = getStatusesByTab(tab, statuses);

  const queryParams = useMemo<CorpOffersSearchFetchProps>(() => {
    return {
      filter,
      search: {
        ...search,
        statuses: currentStatuses,
        partnerId,
        categories: null,
      },
      pageNumber,
    };
  }, [filter, search, currentStatuses, partnerId, pageNumber]);

  const onSearch = useCallback(() => {
    setSearchPromises([
      dispatch(corpOffersSearchFetch(queryParams)),
      dispatch(corpOffersSearchCountsFetch({ ...queryParams, tabs: tabsForCounts })),
    ]);
  }, [dispatch, queryParams]);

  // начальная загрузка
  useEffect(() => {
    if (guid === currentGuid) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, guid, currentGuid]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  // сброс вотчера после ухода
  useEffect(() => {
    return () => {
      dispatch(corpOffersSearchNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};

export default useCorpOffersSearchSearch;
