import styled from '@emotion/styled';
import { Autocomplete as MuiAutocomplete } from '@mui/material';

export const Autocomplete = styled(MuiAutocomplete)`
  .MuiAutocomplete-endAdornment {
    display: flex;

    right: ${p => p.theme.spacing()};
  }

  .MuiSvgIcon-fontSizeSmall {
    font-size: 1.2rem;
  }

  .MuiAutocomplete-popupIndicator {
  }
` as typeof MuiAutocomplete;

export const LoaderWrapper = styled.div`
  min-width: 1.8rem;

  position: absolute;
  right: ${p => p.theme.spacing()};
`;
