import AdCampaignCreateContainer from '@features/adCampaign/create/container';
import { AdCampaignLifeCycleFactory } from '@features/adCampaign/lifecycle/lifecycle';
import { AdCampaignCreateStepType, EAdCampaignStep } from '@features/adCampaign/types';
import { EBookingOfferUrlParam } from '@features/bookingOffer/entry';
import useBreadcrumbsActions from '@features/general/breadcrumbs/useBreadcrumbsActions';
import RequiredGuidInLocationContainer from '@features/general/locationGuid/container';
import { viewHideMainMenu } from '@features/main/container/store/slice';
import useCurrentUser from '@features/user/hooks/useCurrentUser';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import ForbiddenComponent from '../../components/common/forbidden';

const AdCampaignCreateScreen = () => {
  const dispatch = useDispatch();
  const { putBreadcrumbs } = useBreadcrumbsActions();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const step: AdCampaignCreateStepType =
    (searchParams.get(EBookingOfferUrlParam.Step) as AdCampaignCreateStepType) ?? EAdCampaignStep.General;

  const {
    accessMatrix: { isPartnerUser, adCampaigns },
    userSpecific,
  } = useCurrentUser();

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  useEffect(() => {
    putBreadcrumbs([{ label: 'Рекламный кабинет' }]);
  }, []);

  if (!adCampaigns?.create) {
    return <ForbiddenComponent />;
  }

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <AdCampaignCreateContainer
          guid={guid}
          id={null}
          step={step}
          partnerId={userSpecific.mpPartner?.partner?.id ?? null}
          lifecycle={AdCampaignLifeCycleFactory().create({ isPartnerUser, userId: userSpecific.id })}
          access={adCampaigns}
        />
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default AdCampaignCreateScreen;
