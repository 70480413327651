import { Fade, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { AnyAddress } from '../../../../domain/model/address';
import ConfirmDialog from '../../../components/common/dialogs/confirm';
import { Field } from '../../../components/fields';
import { ManualMapIcon } from '../../../icons';
import { MPButton } from '../../../theme/ui-kit/button';
import { AddressHelper } from '../../../utils/address';
import GeoPositionViewer from '../../maps/geoPositionView';
import { MapCoordinates } from '../../maps/types';
import { covertAddressPositionToMapCoordinates } from '../../maps/utils';
import { Wrapper } from './contorls';

export type AddressDetailsProps = {
  readonly address: AnyAddress;
  readonly onClose: () => void;
};

export const AddressDetails = (props: AddressDetailsProps) => {
  const { address, onClose } = props;
  const [mapCoordinates, setMapCoordinates] = useState<Nullable<MapCoordinates>>(null);

  return (
    <ConfirmDialog
      open
      title={<Typography variant='h3'>Адрес</Typography>}
      dialogButtons={
        <Grid container>
          <Grid item>
            <MPButton onClick={onClose}>Закрыть</MPButton>
          </Grid>
        </Grid>
      }
    >
      <Fade in>
        <Wrapper>
          <Field
            gap={1}
            label='Область / Республика / Край'
          >
            {new AddressHelper(address).getLocalityRegion() ?? '-'}
          </Field>
          <Field
            gap={1}
            label='Район/Округ'
          >
            {new AddressHelper(address).getLocalityAdmRegion() ?? '-'}
          </Field>
          <Field
            gap={1}
            label='Населенный пункт'
          >
            {new AddressHelper(address).getLocalityShortPath() || '-'}
          </Field>
          <Field
            gap={1}
            label='Индекс'
          >
            {address.postalCode || '-'}
          </Field>
          <Field
            gap={1}
            label='Улица'
          >
            {new AddressHelper(address).getStreet() || '-'}
          </Field>

          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
            >
              <Field
                gap={1}
                label='Тип объекта'
              >
                {new AddressHelper(address).getLocalityValuesByLevel()?.[0]?.typeName || '-'}
              </Field>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Field
                gap={1}
                label='Номер'
              >
                {new AddressHelper(address).getLocalityValuesByLevel()?.[0]?.value || '-'}
              </Field>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
            >
              <Field
                gap={1}
                label='Тип строения'
              >
                {new AddressHelper(address).getLocalityValuesByLevel()?.[1]?.typeName || '-'}
              </Field>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Field
                gap={1}
                label='Номер'
              >
                {new AddressHelper(address).getLocalityValuesByLevel()?.[1]?.value || '-'}
              </Field>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
            >
              <Field
                gap={1}
                label='Координаты объекта'
              >
                {address.position ? `${address.position.lat} ; ${address.position.lon}` : '-'}
              </Field>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <MPButton
                variant='text'
                fullWidth={false}
                startIcon={<ManualMapIcon />}
                disabled={!address.position}
                onClick={() => {
                  if (address.position) {
                    setMapCoordinates(covertAddressPositionToMapCoordinates(address.position));
                  }
                }}
              >
                Посмотреть на карте
              </MPButton>
            </Grid>
          </Grid>
          {mapCoordinates && (
            <GeoPositionViewer
              coordinates={mapCoordinates}
              onClose={() => setMapCoordinates(null)}
            />
          )}
        </Wrapper>
      </Fade>
    </ConfirmDialog>
  );
};
