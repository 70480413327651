import { AdCampaignCreate } from '@/domain';
import React from 'react';
import { OnChangeObjectAttribute, PanelAction } from '../../../types';
import { AdCampaignLifeCycle, AdCampaignLifeCycleRules } from '../lifecycle/types';
import { AdCampaignActionCreateType, AdCampaignActions, AdCampaignCreateStep } from '../types';
import { AdCampaignCreateContainerProps } from './container';

export type AdCampaignCreateParamsContextValue = AdCampaignCreateContainerProps;

export const AdCampaignCreateParamsContext = React.createContext<AdCampaignCreateParamsContextValue>(
  {} as AdCampaignCreateParamsContextValue
);

export type AdCampaignCreateLifecycleContextValue = {
  readonly lifecycle: AdCampaignLifeCycle;
  readonly lifeCycleRules: AdCampaignLifeCycleRules;
  readonly actions: AdCampaignActions<AdCampaignActionCreateType>;
  readonly onAction: (action: PanelAction<AdCampaignActionCreateType>) => void;
};

export const AdCampaignCreateLifecycleContext = React.createContext<AdCampaignCreateLifecycleContextValue>(
  {} as AdCampaignCreateLifecycleContextValue
);

export type AdCampaignCreateHandlersContextValue = {
  readonly onClose: () => void;

  readonly goToFirstIsNotValidStep: () => void;

  readonly onAdCampaignTrySave: () => void;
  readonly onAdCampaignTryArchive: () => void;
  readonly onAdCampaignTryResume: () => void;
  readonly onAdCampaignTryPause: () => void;

  readonly onChangeStep: (step: AdCampaignCreateStep) => void;

  readonly onChangeAttribute: OnChangeObjectAttribute<AdCampaignCreate>;
  readonly onAttributeValidate: (name: keyof AdCampaignCreate) => void;
};

export const AdCampaignCreateHandlersContext = React.createContext<AdCampaignCreateHandlersContextValue>(
  {} as AdCampaignCreateHandlersContextValue
);
