import { Grid, Typography } from '@mui/material';
import Splitter from '../../../../components/common/splitter';
import compilationLine from '../../../../icons/compilationLine.png';
import CompilationBlock from '../compilationBlock';
import { CompilationBlockTitle, Line, Wrapper } from './controls';

const PartnerWindowCompilation = () => (
  <Grid
    alignItems='center'
    container
    item
    spacing={1}
  >
    <Grid item>
      <Grid
        container
        direction='column'
        item
        spacing={1}
      >
        <Grid item>
          <CompilationBlockTitle>Заголовок блока предложений</CompilationBlockTitle>
        </Grid>
        <Grid item>
          <Wrapper
            container
            spacing={1}
          >
            <CompilationBlock />
            <CompilationBlock />
            <CompilationBlock />
            <CompilationBlock />
          </Wrapper>
          <Splitter size={2} />
          <Wrapper
            container
            spacing={1}
          >
            <CompilationBlock />
            <CompilationBlock />
            <CompilationBlock />
            <CompilationBlock />
          </Wrapper>
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Line>
        <img src={compilationLine} />
      </Line>
    </Grid>
    <Grid item>
      <Typography>Подборка предложений</Typography>
    </Grid>
  </Grid>
);

export default PartnerWindowCompilation;
