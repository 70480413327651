import { AnalyticsAdapter, AnalyticsContainer, AnalyticsType, VisitHistory } from '@mp-npm/mp-analytics-client';
import Api from '../../../data/api';
import { UserAccessMatrix } from '../../../domain/model/accessMatrix';
import { AppConfigurator } from '../../../system/appConfigurator';
import { EAppFeature } from '../../types';
import { WebAnalyticsConfigurator } from './configurator/webAnalyticsConfigurator';
import { WebAnalytics } from './types';

const getYandexAdapter = (history: VisitHistory, accessMatrix: UserAccessMatrix): AnalyticsAdapter => {
  const configurator = WebAnalyticsConfigurator.getInstance();
  const config = configurator.getYandexConfig();
  const enabled =
    AppConfigurator.getInstance().hasFeature(EAppFeature.WebAnalytics) && config.enabled && !accessMatrix.isAdminSport;
  return {
    type: AnalyticsType.Yandex,
    id: config.id,
    debug: configurator.isDebugMode(),
    enabled: enabled && !!config.id,
    enabledHit: true,
    visitParams: {},
    goals: [],
    options: {
      clickmap: config.clickmap,
      webvisor: config.webvisor,
    },
    history,
  };
};

const getSelfHostedAdapter = (accessMatrix: UserAccessMatrix): AnalyticsAdapter => {
  const configurator = WebAnalyticsConfigurator.getInstance();
  const config = configurator.getSelfHostedConfig();
  const enabled =
    AppConfigurator.getInstance().hasFeature(EAppFeature.WebAnalytics) && config.enabled && !accessMatrix.isAdminSport;
  return {
    type: AnalyticsType.SelfHosted,
    debug: configurator.isDebugMode(),
    enabled,
    goals: Object.values(config.goals).map(v => v),
    buffer: {
      lifeTime: config.buffer.timeout,
      goals: config.buffer.goals,
      onEntityGoal: entities => {
        if (entities.length) {
          return Api.user.mp.callOfferEvent(entities.map(item => ({ id: item.entityId, typeCode: item.goal })));
        }
        return Promise.resolve();
      },
    },
    onEntityGoal: ({ id, params }) => {
      if (id && params?.entityId) {
        return Api.user.mp.callOfferEvent([{ typeCode: id, id: params.entityId }]);
      }
      return Promise.resolve();
    },
  };
};

export const webAnalytics: WebAnalytics = {
  offerView: offerId => {
    const id = WebAnalyticsConfigurator.getInstance().getGoals().offerViewId;
    if (id) {
      AnalyticsContainer.getInstance().entityGoal({ id, params: { entityId: offerId } });
    }
  },

  getAdapters: (history, accessMatrix) => {
    return [getYandexAdapter(history, accessMatrix), getSelfHostedAdapter(accessMatrix)];
  },
};
