import { UUID } from '@/domain/model/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tradeActivationsGuidSelector } from '../store/selectors';
import { tradeActivationsNeedRefreshWatcherIncrement, tradeActivationsStartSession } from '../store/slice';

type useTradeActivationSessionProps = {
  readonly guid: UUID;
};

export const useTradeActivationTableSession = (props: useTradeActivationSessionProps) => {
  const { guid } = props;

  const currentGuid = useSelector(tradeActivationsGuidSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(tradeActivationsStartSession({ guid }));
  }, [dispatch, guid]);

  // начальная загрузка
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(tradeActivationsNeedRefreshWatcherIncrement());
    }
  }, [dispatch, guid, currentGuid]);
};
