import { CorpOffer } from '../../../../../../domain/model/corpOffer';
import { PartnersCollection } from '../../../../../../domain/model/partner';
import { TradeOffer } from '../../../../../../domain/model/tradeOffer';
import { ArrayElementType } from '../../../../../types';

export enum ECollectionSearchDialogTag {
  CorpMultiple = 'corp_multiple',
  TradeMultiple = 'trade_multiple',
  CorpSingle = 'corp_single',
  TradeSingle = 'trade_single',
  Category = 'category',
  PartnerMultiple = 'partner_multiple',
}

export type CollectionContentType = TradeOffer | CorpOffer | ArrayElementType<PartnersCollection>;
