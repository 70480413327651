import { UserAccessMatrix } from 'domain/model/accessMatrix';
import { EOrderPartition } from 'domain/model/enums';
import { Partner } from 'domain/model/partner';
import { MpUser } from 'domain/model/user';
import { commonMenu } from '../../../../menu';
import { MenuPanelItem } from '../../../../types';
import {
  getCustomerManagementByPartnerCorpActivationsRoute,
  getCustomerManagementByPartnerDetailsRoute,
  getCustomerManagementByPartnerOrdersRoute,
  getCustomerManagementByPartnerTradeActivationsRoute,
} from '../byPartner/entry';
import {
  getCustomerManagementCorpActivationsRoute,
  getCustomerManagementDetailsRoute,
  getCustomerManagementOrdersRoute,
  getCustomerManagementTradeActivationsRoute,
} from '../common/entry';

export const getCustomerManagementCommonMenu = (customer: MpUser, accessMatrix: UserAccessMatrix): MenuPanelItem[] => {
  const { id } = customer;

  const items: MenuPanelItem[] = [];
  const { orders, corpActivations, tradeActivations } = accessMatrix;

  const ordersSubMenu: MenuPanelItem[] = [];

  if (orders?.[EOrderPartition.ProductOffers].view) {
    ordersSubMenu.push(
      commonMenu.productOfferOrders(getCustomerManagementOrdersRoute({ id, partition: EOrderPartition.ProductOffers }))
    );
  }
  if (orders?.[EOrderPartition.BookingOffers].view) {
    ordersSubMenu.push(
      commonMenu.bookingOfferOrders(getCustomerManagementOrdersRoute({ id, partition: EOrderPartition.BookingOffers }))
    );
  }
  if (ordersSubMenu.length > 0) {
    items.push({
      ...commonMenu.orders,
      subMenu: ordersSubMenu,
    });
  }

  const activationsSubMenu: MenuPanelItem[] = [];
  if (corpActivations.view) {
    activationsSubMenu.push(
      commonMenu.corpActivations(
        getCustomerManagementCorpActivationsRoute({
          id,
        })
      )
    );
  }
  if (tradeActivations.view) {
    activationsSubMenu.push(
      commonMenu.tradeActivations(
        getCustomerManagementTradeActivationsRoute({
          id,
        })
      )
    );
  }
  if (activationsSubMenu.length > 0) {
    items.push({
      ...commonMenu.activations,
      subMenu: activationsSubMenu,
    });
  }

  items.push(commonMenu.customerProfile(getCustomerManagementDetailsRoute({ id })));

  return items;
};

export const getCustomerManagementByPartnerMenu = (
  customer: MpUser,
  partner: Partner,
  accessMatrix: UserAccessMatrix
): MenuPanelItem[] => {
  const { id } = customer;

  const items: MenuPanelItem[] = [];
  const { orders, corpActivations, tradeActivations } = accessMatrix;

  const ordersSubMenu: MenuPanelItem[] = [];

  if (orders?.[EOrderPartition.ProductOffers].view) {
    ordersSubMenu.push(
      commonMenu.productOfferOrders(
        getCustomerManagementByPartnerOrdersRoute({
          customerId: id,
          partnerId: partner.id,
          partition: EOrderPartition.ProductOffers,
        })
      )
    );
  }
  if (orders?.[EOrderPartition.BookingOffers].view) {
    ordersSubMenu.push(
      commonMenu.bookingOfferOrders(
        getCustomerManagementByPartnerOrdersRoute({
          customerId: id,
          partnerId: partner.id,
          partition: EOrderPartition.BookingOffers,
        })
      )
    );
  }
  if (ordersSubMenu.length > 0) {
    items.push({
      ...commonMenu.orders,
      subMenu: ordersSubMenu,
    });
  }

  const activationsSubMenu: MenuPanelItem[] = [];
  if (corpActivations.view) {
    activationsSubMenu.push(
      commonMenu.corpActivations(
        getCustomerManagementByPartnerCorpActivationsRoute({
          customerId: id,
          partnerId: partner.id,
        })
      )
    );
  }
  if (tradeActivations.view) {
    activationsSubMenu.push(
      commonMenu.tradeActivations(
        getCustomerManagementByPartnerTradeActivationsRoute({
          customerId: id,
          partnerId: partner.id,
        })
      )
    );
  }
  if (activationsSubMenu.length > 0) {
    items.push({
      ...commonMenu.activations,
      subMenu: activationsSubMenu,
    });
  }

  items.push(
    commonMenu.customerProfile(
      getCustomerManagementByPartnerDetailsRoute({
        customerId: id,
        partnerId: partner.id,
      })
    )
  );

  return items;
};
