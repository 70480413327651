import { useMemo } from 'react';
import { PartnerShort } from '../../../../../../domain/model/partner';
import { UUID } from '../../../../../../domain/model/types';
import { DataTableMetadata, MutableDataTableColumns } from '../../../../../components/common/table';
import PartnerCollectionTable from './table';
import { PartnerCollectionColumn, partnerCollectionColumns, partnerCollectionDefaultColumns } from './utils';

interface PartnerCollectionProps<T extends PartnerShort> {
  readonly partners: T[];
  readonly onRemove?: (id: UUID) => void;
  readonly onChangeSortIndex?: ((partner: T, sortIndex: number) => void) | false;
}

const PartnerCollection = <T extends PartnerShort>(props: PartnerCollectionProps<T>) => {
  const { partners, onRemove, onChangeSortIndex } = props;

  const maxSortIndex = partners.length;

  const metadata = useMemo<DataTableMetadata<PartnerCollectionColumn>>(() => {
    const columns: MutableDataTableColumns<PartnerCollectionColumn> = {};

    partnerCollectionDefaultColumns.forEach(
      column =>
        (columns[column] = {
          ...partnerCollectionColumns[column],
        })
    );

    return { columns };
  }, []);

  return (
    <PartnerCollectionTable<T>
      metadata={metadata}
      partners={partners}
      sortIndexInfo={{
        max: maxSortIndex,
        fetch: () => {},
        isFetching: false,
        isFetched: true,
        isFailed: false,
      }}
      onPartnerAction={onChangeSortIndex && (call => onChangeSortIndex(call.partner, call.sortIndex))}
      onRemove={onRemove}
    />
  );
};

export default PartnerCollection;
