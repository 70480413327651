import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from './index';

const CorpOfferTableCellModerator = (props: CorpOfferTableCellCommonProps) => {
  const {
    corpOffer: { moderator },
  } = props;

  const value = moderator?.name ?? '-';

  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellModerator;
