import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../data/store/store';
import { EOrderStatus } from '../../../../../domain/model/enums';
import { BookingOrderView } from '../../types';

export const bookingOrderEditOrderSelector = (store: RootState) => store.bookingOrder.edit.byId.bookingOrder;
export const bookingOrderEditGuidSelector = (store: RootState) => store.bookingOrder.edit.guid;
export const bookingOrderEditFetchingSelector = (store: RootState) => store.bookingOrder.edit.byId.isFetching;
export const bookingOrderEditBookingOrderObjectNameSelector = (store: RootState) =>
  store.bookingOrder.edit.byId.bookingOrder?.offer.name ?? null;
export const bookingOrderEditBookingOrderManagerCommentSelector = (store: RootState) =>
  store.bookingOrder.edit.byId.bookingOrder?.managerComment ?? null;
export const bookingOrderEditBookingOrderCostSelector = (store: RootState) =>
  store.bookingOrder.edit.byId.bookingOrder?.cost ?? null;
export const bookingOrderEditBookingOrderItemsSelector = (store: RootState) =>
  store.bookingOrder.edit.byId.bookingOrder?.orderItems ?? [];
export const bookingOrderEditOfferSelector = (store: RootState) => store.bookingOrder.edit.byId.offer;
export const bookingOrderEditBookingOrderIsPresentSelector = (store: RootState) =>
  !!store.bookingOrder.edit.byId.bookingOrder;
export const bookingOrderEditLoadedOrderSelector = (store: RootState) => store.bookingOrder.edit.byId.loadedData;
export const bookingOrderEditBookingOrderForLifeCycleSelector = createSelector(
  (store: RootState) => store.bookingOrder.edit.byId.loadedData?.status ?? EOrderStatus.New,
  (store: RootState) => store.bookingOrder.edit.byId.bookingOrder?.orderItems ?? null,
  (status, orderItems) => ({
    status,
    orderItems,
  })
);

export const bookingOrderEditValidationResultsSelector = (store: RootState) => store.bookingOrder.edit.validation;

export const bookingOrderEditValidationResultsByFieldSelector = (field: keyof BookingOrderView) => (store: RootState) =>
  store.bookingOrder.edit.validation[field] ?? null;

// Селекторы валидаций списков
export const bookingOrderEditValidationOrderItemsResultsSelector = (store: RootState) =>
  store.bookingOrder.edit.validationOrderItems;

// Селекторы валидаций элементов списков
export const bookingOrderEditValidationOrderItemsResultByIndexSelector = (index: number) => (store: RootState) =>
  store.bookingOrder.edit.validationOrderItems[index] ?? null;

export const bookingOrderEditDialogsSelector = (store: RootState) => store.bookingOrder.edit.dialogs;
