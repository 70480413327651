import { useMemo } from 'react';
import { Nullable, UUID } from '../../../../domain/model/types';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import { ProductLifeCycleFactory } from '../lifecycle/lifecycle';
import { ProductLifeCycle } from '../lifecycle/types';
import ProductsStubAdapter from './adapters/stub';
import ProductsPartnerStubAdapter from './adapters/stubPartner';
import ProductTableContainer from './container';
import { ProductTableConfigContext } from './context';
import { useProductTableConfig } from './hooks/useProductTableConfig';
import { productsIsEmptySelector } from './store/selectors';
import { ProductTableStubAdapterType } from './types';
import { EProductTableTab } from './utils';

interface ProductTableEntryProps {
  readonly guid: UUID;
  readonly partnerId?: Nullable<UUID>;
  readonly tab: Nullable<EProductTableTab>;
}

const ProductTableEntry = ({ guid, tab, partnerId = null }: ProductTableEntryProps) => {
  const {
    accessMatrix: { isPartnerUser, productOffers },
    mpPartnerUserProfile,
    userSpecific,
  } = useCurrentUser();

  const lifecycle = useMemo<ProductLifeCycle>(
    () => ProductLifeCycleFactory().create({ isPartnerUser, userId: userSpecific.id }),
    [isPartnerUser, userSpecific.id]
  );

  const config = useProductTableConfig();

  const { defaultTab } = config;

  const outTab = tab ?? defaultTab;

  const stubAdapter = useMemo<ProductTableStubAdapterType>(
    () => props =>
      isPartnerUser ? (
        <ProductsPartnerStubAdapter
          isEmptySelector={productsIsEmptySelector}
          partnerId={mpPartnerUserProfile?.partner?.id}
        >
          {props.children}
        </ProductsPartnerStubAdapter>
      ) : (
        <ProductsStubAdapter
          isEmptySelector={productsIsEmptySelector}
          partnerId={partnerId}
        >
          {props.children}
        </ProductsStubAdapter>
      ),
    [isPartnerUser, partnerId, mpPartnerUserProfile?.partner?.id]
  );

  return (
    <ProductTableConfigContext.Provider value={config}>
      <ProductTableContainer
        guid={guid}
        partnerId={partnerId}
        tab={outTab}
        lifecycle={lifecycle}
        stubAdapter={stubAdapter}
        access={productOffers}
      />
    </ProductTableConfigContext.Provider>
  );
};

export default ProductTableEntry;
