import TabsPanelCounter from '../../../components/common/tabs/counter/counter';
import TabsPanelLabel from '../../../components/common/tabs/label/label';
import { EPartnerTableTab } from '../../general/partner/types';
import { getPartnerTableTabName, PartnerTableTabsCounter } from './utils';

interface PartnerTableTabProps {
  readonly tab: EPartnerTableTab;
  readonly counts: PartnerTableTabsCounter;
}

const PartnerTableTab = ({ tab, counts }: PartnerTableTabProps) => {
  const count = counts[tab];

  return (
    <TabsPanelLabel title={getPartnerTableTabName(tab)}>
      <TabsPanelCounter count={count} />
    </TabsPanelLabel>
  );
};

export default PartnerTableTab;
