import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Nullable, UUID } from '@/domain/model/types';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import { nsiDataSelector } from '../../general/nsi/store/selectors';
import { EOfferTableTab } from '../../general/offer/types';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import {
  bookingOfferTableFilterItems,
  EBookingOfferTableFilterItem,
  getBookingOfferTableFilterStrategy,
} from '../filterUtils';
import { BookingLifeCycleFactory } from '../lifecycle/lifecycle';
import { BookingOfferTableStubAdapterType, EBookingOfferTableColumn } from '../types';
import BookingOffersStubAdapter from './adapters/stub';
import BookingOffersPartnerStubAdapter from './adapters/stubPartner';
import BookingOfferTableContainer from './container';
import { BookingOffersTableConfigContext } from './context';
import { useBookingOffersTableConfig } from './hooks/useBookingOffersTableConfig';
import { bookingOffersIsEmptySelector } from './store/selectors';
import { getBookingOfferAdminTableColumns, getBookingOfferOwnerTableColumns } from './utils';
import useTechConfig from '@/presentation/hooks/useTechConfig';
import { EAppFeature } from '@/presentation/types';

interface BookingOfferTableEntryProps {
  readonly guid: UUID;
  readonly partnerId?: Nullable<UUID>;
  readonly tab: Nullable<EOfferTableTab>;
}

interface GetOffersTableAdapterKeyParams {
  readonly isAdmin?: boolean;
  readonly partnerId: Nullable<string>;
  readonly tab: EOfferTableTab;
}

interface GetOffersTableAdapterKeyResult {
  readonly key: string;
  readonly keyPartitions?: (string | number | null)[];
}

const BookingOfferTableEntry = ({ guid, tab, partnerId = null }: BookingOfferTableEntryProps) => {
  const {
    accessMatrix: { bookingOffers, isPartnerUser },
    mpPartnerUserProfile,
    userSpecific,
  } = useCurrentUser();
  const { hasFeature } = useTechConfig();
  const isCSP = hasFeature(EAppFeature.Csp);

  const { partnerTypes } = useSelector(nsiDataSelector);

  const config = useBookingOffersTableConfig({
    canCreate: !!bookingOffers.create,
  });
  const { defaultTab } = config;
  const outTab = tab ?? defaultTab;

  const { viewAsAdmin: isAdmin } = bookingOffers;

  const lifecycle = useMemo(
    () =>
      BookingLifeCycleFactory().create({
        isPartnerUser,
        userId: userSpecific.id,
      }),
    [isPartnerUser, userSpecific.id]
  );

  // получаем максимально возможный состав колонок
  const sourceColumns = useMemo(
    () =>
      isPartnerUser
        ? getBookingOfferOwnerTableColumns(isCSP)
        : getBookingOfferAdminTableColumns(outTab, partnerId, isCSP),
    [outTab, partnerId, isPartnerUser]
  );

  const stubAdapter = useMemo<BookingOfferTableStubAdapterType>(
    () =>
      isPartnerUser
        ? props => (
            <BookingOffersPartnerStubAdapter
              isEmptySelector={bookingOffersIsEmptySelector}
              partnerId={mpPartnerUserProfile?.partner?.id}
            >
              {props.children}
            </BookingOffersPartnerStubAdapter>
          )
        : BookingOffersStubAdapter,
    [isPartnerUser, mpPartnerUserProfile?.partner?.id]
  );

  const getOffersTableAdapterKey = (params: GetOffersTableAdapterKeyParams): GetOffersTableAdapterKeyResult => {
    const { isAdmin, partnerId, tab } = params;

    const key = 'bookingOffers';
    let keyPartitions;

    if (isAdmin) {
      keyPartitions = ['admin', partnerId ? 'byPartner' : 'common', tab];
    } else {
      keyPartitions = ['partner', tab];
    }

    return {
      key,
      keyPartitions,
    };
  };

  // готовим адаптер для хука
  const filterAdapter = useMemo<DataFilterAdapter<EBookingOfferTableColumn, EBookingOfferTableFilterItem>>(
    () => ({
      ...getOffersTableAdapterKey({
        tab: outTab,
        isAdmin,
        partnerId,
      }),
      sourceColumns: sourceColumns,
      filterItems: bookingOfferTableFilterItems,
      requiredFilters: [EBookingOfferTableFilterItem.Query],
      getDataFilterStrategy: (filterItem, values) =>
        getBookingOfferTableFilterStrategy(filterItem, values, partnerTypes),
    }),
    [sourceColumns, partnerId, isAdmin, outTab]
  );

  return (
    <BookingOffersTableConfigContext.Provider value={config}>
      <BookingOfferTableContainer
        guid={guid}
        partnerId={partnerId}
        tab={outTab}
        filterAdapter={filterAdapter}
        stubAdapter={stubAdapter}
        lifecycle={lifecycle}
        access={bookingOffers}
      />
    </BookingOffersTableConfigContext.Provider>
  );
};

export default BookingOfferTableEntry;
