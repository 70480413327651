import { FCC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import store from '../../../../../../data/store/store';
import { OfferCategory } from '../../../../../../domain/model';
import useHistoryExtensions from '../../../../../hooks/useHistoryExtensions';
import { getConfigurationPrivilegeRoute } from '../../../../configuration/entry';
import { CorpOfferCategoriesSelectContainerProps } from '../container';
import { CorpOfferCategoriesSelectHandlersContext, CorpOfferCategoriesSelectHandlersContextValue } from '../context';
import { corpOfferCategoriesSelectSelectedSelector } from '../store/selectors';
import {
  corpOfferCategoriesSelectSelect,
  corpOfferCategoriesSelectUnselect,
  corpOfferCategoriesSelectUnselectAll,
} from '../store/slice';

export const CorpOfferCategoriesSelectHandlersProvider: FCC<CorpOfferCategoriesSelectContainerProps> = ({
  children,
  access,
  maxCount = 99999,
  onClose: onCloseExternal,
  onEdit: onEditExternal,
}) => {
  const dispatch = useDispatch();
  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const getSelectedFromState = useCallback((): Nullable<OfferCategory[]> => {
    const state = store.getState();
    return corpOfferCategoriesSelectSelectedSelector(state);
  }, [store]);

  const onSelect = useCallback<CorpOfferCategoriesSelectHandlersContextValue['onSelect']>(
    corpOfferCategory => {
      const selected = getSelectedFromState();
      if (maxCount === 1) {
        dispatch(corpOfferCategoriesSelectUnselectAll());
        dispatch(corpOfferCategoriesSelectSelect(corpOfferCategory));
      } else if ((selected?.length ?? 0) < maxCount) {
        dispatch(corpOfferCategoriesSelectSelect(corpOfferCategory));
      }
    },
    [dispatch, maxCount]
  );

  const onUnselect = useCallback<CorpOfferCategoriesSelectHandlersContextValue['onUnselect']>(
    corpOfferCategory => {
      dispatch(corpOfferCategoriesSelectUnselect(corpOfferCategory));
    },
    [dispatch]
  );

  const onClose = useCallback<CorpOfferCategoriesSelectHandlersContextValue['onClose']>(() => {
    if (onCloseExternal) {
      const selected = getSelectedFromState();
      onCloseExternal(selected);
    } else {
      gotoPrevIndependentLocation(getConfigurationPrivilegeRoute({}));
    }
  }, [gotoPrevIndependentLocation, onCloseExternal, getSelectedFromState]);

  const onEdit: CorpOfferCategoriesSelectHandlersContextValue['onEdit'] =
    access.canEdit && onEditExternal ? onEditExternal : null;

  const value: CorpOfferCategoriesSelectHandlersContextValue = {
    onSelect,
    onUnselect,
    onClose,
    onEdit,
  };

  return (
    <CorpOfferCategoriesSelectHandlersContext.Provider value={value}>
      {children}
    </CorpOfferCategoriesSelectHandlersContext.Provider>
  );
};
