import styled from '@emotion/styled';
import { Card as MuiCard, CardProps as MuiCardProps, css, Skeleton, Typography } from '@mui/material';
import { HTMLAttributes, ReactNode } from 'react';
import { EEntityPreviewMode } from '../../../../../types';

const imageSizeMobile = '2.75rem';
const imageSizeDesktop = '1.5rem';
const imageSizeTable = '1rem';

const getImageSize = (mode: Nullable<EEntityPreviewMode>) => {
  switch (mode) {
    case EEntityPreviewMode.Desktop:
      return imageSizeDesktop;
    case EEntityPreviewMode.Mobile:
      return imageSizeMobile;
    case null:
      return imageSizeTable;
  }
};

type WrapperProps = HTMLAttributes<HTMLDivElement> & {
  readonly maxRowElementsCount: number;
};

export const Wrapper = styled(({ maxRowElementsCount, children, ...props }: WrapperProps) => (
  <div {...props}>{children}</div>
))(
  ({ maxRowElementsCount }) => css`
    width: 100%;

    display: grid;
    grid-template-columns: repeat(${maxRowElementsCount}, 1fr);
  `
);

type CardProps = MuiCardProps & {
  readonly mode: Nullable<EEntityPreviewMode>;
};

export const Card = styled(({ mode, children, ...props }: CardProps) => <MuiCard {...props}>{children}</MuiCard>)(
  ({ mode, theme }) => css`
    height: 100%;

    position: relative;
    overflow: inherit;

    padding: ${theme.spacing(
      mode === EEntityPreviewMode.Mobile ? 1 : 0.5,
      mode === EEntityPreviewMode.Mobile ? 1.5 : 0.75
    )};

    gap: 2px;

    display: flex;
    flex-direction: column;
    align-items: center;

    user-select: none;
    box-shadow: none;
    border-radius: 0;
    background: none;

    cursor: pointer;
  `
);

type WrapperLogoProps = HTMLAttributes<HTMLDivElement> & {
  readonly mode: Nullable<EEntityPreviewMode>;
};

export const WrapperLogo = styled(({ mode, children, ...props }: WrapperLogoProps) => <div {...props}>{children}</div>)(
  ({ mode }) => css`
    width: ${getImageSize(mode)};
    height: ${getImageSize(mode)};

    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;

    img {
      max-width: ${getImageSize(mode)};
      max-height: ${getImageSize(mode)};
      object-fit: contain;
      object-position: center center;
    }
  `
);

export const CardTitle = styled(Typography)`
  white-space: normal;

  height: 0.85rem;
  line-height: 0.4rem;

  font-size: 0.3rem;
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const CardTitleMobile = styled(Typography)(
  ({ theme }) => css`
    height: 2rem;
    overflow: hidden;

    text-overflow: ellipsis;
    text-align: center;
    word-break: break-word;

    margin-top: ${theme.spacing(0.5)};
    margin-bottom: ${theme.spacing(0.5)};

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `
);

const BlockWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  position: relative;

  gap: 5%;
  justify-content: center;
  align-items: center;
`;

export const TemplateBorder = styled.div(
  ({ theme }) => css`
    position: absolute;
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    left: 0;
    top: 0;

    border: 1px solid ${theme.palette.text.primary};
  `
);

interface TemplateWrapperProps {
  children: ReactNode;
}

export const TemplateWrapper = styled(({ ...others }: TemplateWrapperProps) => <BlockWrapper {...others} />)`
  background-color: ${p => p.theme.palette.disabled.background};
`;

export const LogoStub = styled(Skeleton)`
  margin-top: 10%;

  width: 1rem;
  height: 1rem;
`;
