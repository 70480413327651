import styled from '@emotion/styled';
import { Typography, TypographyProps } from '@mui/material';

export const Wrapper = styled.div`
  position: relative;

  padding: ${p => p.theme.spacing(5)};

  border: 1px solid ${p => p.theme.palette.secondary.main};
  border-radius: ${p => (p.theme.shape.borderRadius as number) * 3}px;
  background-color: ${p => p.theme.palette.background.default};
  word-break: break-word;

  &::before {
    content: '';
    position: absolute;
    inset: -1px;
    border: 7px dashed ${p => p.theme.palette.background.default};
    border-radius: ${p => (p.theme.shape.borderRadius as number) * 3}px;
    z-index: 0;
  }
`;

export const Title = styled((props: TypographyProps) => <Typography {...props} />)`
  word-break: break-word;
`;

export const Label = styled.div`
  position: absolute;
  top: calc(-${p => p.theme.typography.body2.lineHeight} / 2);
  left: ${p => p.theme.spacing(4.5)};

  padding: 0 ${p => p.theme.spacing(0.5)};
  background-color: ${p => p.theme.palette.background.default};

  & .MuiTypography-root {
    font-weight: ${p => p.theme.typography.fontWeightSemiBold};
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
`;
