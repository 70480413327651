import styled from '@emotion/styled';
import { viewConfig } from '../../../../../../theme/viewConfig';

export const Wrapper = styled.div`
  display: flex;
`;

export const ColumnWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  padding-top: ${p => p.theme.spacing(2)};
`;
