import { FCC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClientOrgCreateContainerProps } from './container';
import { clientOrgCreateGuidSelector } from './store/selectors';
import { clientOrgCreateByIdFetch, clientOrgCreateClearActionsState, clientOrgCreateStartSession } from './store/slice';

type ClientOrgCreateInitializerProps = ClientOrgCreateContainerProps;

export const ClientOrgCreateInitializer: FCC<ClientOrgCreateInitializerProps> = ({ id, children }) => {
  const dispatch = useDispatch();

  const currentGuid = useSelector(clientOrgCreateGuidSelector);

  useEffect(() => {
    dispatch(clientOrgCreateStartSession({ guid: id }));
  }, [dispatch, id]);

  //загрузка основных данных
  useEffect(() => {
    // При первой загрузке мы инициализируем данные по умолчанию
    if (currentGuid === id) {
      const request = dispatch(clientOrgCreateByIdFetch(id));

      return () => {
        request.abort();
      };
    }
  }, [dispatch, id, currentGuid]);

  useEffect(() => {
    return () => {
      dispatch(clientOrgCreateClearActionsState());
    };
  }, [dispatch]);

  if (id !== currentGuid) {
    return null;
  }

  return <>{children}</>;
};
