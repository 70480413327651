import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useRef } from 'react';
import { Nullable } from '../../../../../../domain/model/types';
import DataFilterViewCommonItem from './common';
import { ItemsMore, MorePopover } from './controls';

export interface DataFilterMoreProps {
  visible: boolean;
  offsetLeft: number;
  elementsCount: Nullable<number>;
}

interface DataFilterMoreItemProps extends DataFilterMoreProps {
  readonly expanded: boolean;
  readonly children: any;
  readonly onExpandOrCollapse: () => void;
}

const DataFilterMoreItem = (props: DataFilterMoreItemProps) => {
  const { visible, offsetLeft, expanded, onExpandOrCollapse, children } = props;
  const anchorEl = useRef(null);

  if (!visible) return null;

  return (
    <ItemsMore offsetLeft={offsetLeft}>
      <DataFilterViewCommonItem
        elementRef={anchorEl}
        label='Ещё'
        icon={expanded ? <ArrowUpIcon fontSize='small' /> : <ArrowDownIcon fontSize='small' />}
        onClick={onExpandOrCollapse}
        onDelete={onExpandOrCollapse}
      />
      <MorePopover
        open={expanded}
        anchorEl={anchorEl?.current}
        onClose={onExpandOrCollapse}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {children}
      </MorePopover>
    </ItemsMore>
  );
};

export default DataFilterMoreItem;
