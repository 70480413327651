import { Grid, Typography } from '@mui/material';
import { Nullable } from '../../../../../domain/model/types';
import { MpUserData } from '../../../../../domain/model/user';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';
import { MPButton } from '../../../../theme/ui-kit/button';

interface PartnerInviteSuccessDialogProps {
  readonly partnerAdmin: Nullable<MpUserData>;
  readonly onAction: () => void;
}

const PartnerInviteSuccessDialog = (props: PartnerInviteSuccessDialogProps) => {
  const { partnerAdmin, onAction } = props;

  return (
    <ConfirmDialog
      open={!!partnerAdmin}
      title='Партнёру предоставлена учетная запись'
      dialogButtons={
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              onClick={onAction}
            >
              Отлично
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Typography gutterBottom={true}>
        На почту {partnerAdmin?.email} отправлено уведомление для подтверждения адреса электронной почты и создания
        учетной записи на администрирование в системе Витрина
      </Typography>
    </ConfirmDialog>
  );
};

export default PartnerInviteSuccessDialog;
