import { MpUser } from '@/domain';
import { CustomersLocationState, ECustomerUrlParam } from '@features/customer/entry';
import { getCustomerManagementDetailsRoute } from '@features/customer/management/common/entry';
import CustomerTableEntry from '@features/customer/table/entry';
import { ECustomerTableTab } from '@features/customer/table/utils';
import { viewShowMainMenu } from '@features/main/container/store/slice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import ForbiddenComponent from '../../components/common/forbidden';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const CustomersScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation<CustomersLocationState>();
  const history = useHistory();

  const { accessMatrix, mpPartnerUserProfile } = useCurrentUser();
  const { customers, isPartnerUser } = accessMatrix;

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(ECustomerUrlParam.Tab) as ECustomerTableTab;

  const onCustomerClick = (customer: MpUser) => {
    history.push(getCustomerManagementDetailsRoute({ id: customer.id }));
  };

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (!customers?.view?.list) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<CustomersLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <CustomerTableEntry
          guid={state.guid}
          partnerId={isPartnerUser ? mpPartnerUserProfile.partner.id : null}
          tab={tab}
          onCustomerClick={onCustomerClick}
        />
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default CustomersScreen;
