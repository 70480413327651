import { Chip } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { ActivityType } from '../../../../../domain/model/event';
import { Nullable } from '../../../../../domain/model/types';
import {
  MPAutocompleteMultipleSelect,
  MPAutocompleteMultipleSelectProps,
} from '../../../../theme/ui-kit/autocomplete/multiple';
import { ActivityTypeSelectorLeafItem } from './leafItem';
import { ActivityTypeSelectorRootItem } from './rootItem';

export interface ActivityTypesMultipleSelectorProps
  extends Omit<MPAutocompleteMultipleSelectProps<ActivityType>, 'onChangeValue' | 'onChange'> {
  readonly options: ActivityType[];
  //отключить возможность выделять подуровни одной ветки (то есть выбор родителя и листьев будет исключать друг друга)
  readonly excludeSelectBranchLevels?: boolean;
  //развернуть всё по умолчанию
  readonly initialExpandAll?: boolean;
  readonly onChange: (values: ActivityType[]) => void;
}

export const ActivityTypesMultipleSelector = (props: ActivityTypesMultipleSelectorProps) => {
  const { options, excludeSelectBranchLevels, initialExpandAll, onChange, placeholder, ...others } = props;

  const treeValue = others.value;
  const [searchValue, setSearchValue] = useState<Nullable<string>>(() => null);
  const [expanded, setExpanded] = useState<ActivityType[]>(() =>
    initialExpandAll ? options.filter(option => !option.parent) : []
  );

  const getChildren = (item: ActivityType) => options.filter(o => o.parent?.id === item.id);

  const onChangeInternal = (v: ActivityType, checked: boolean) => {
    const prev = others.value ?? [];
    let next: ActivityType[];
    if (checked) {
      next = [...prev, v];
    } else {
      next = [...prev.filter(item => item.id !== v.id)];
    }

    if (checked) {
      if (excludeSelectBranchLevels) {
        //сбрасываем дочерние, если выделяется родитель
        const children = getChildren(v);
        if (children.length) {
          next = [...next.filter(item => !children.some(c => c.id === item.id))];
        }

        //сбрасываем родителя, если выделяется дочерний
        if (v.parent && isSelected(v.parent)) {
          next = [...next.filter(item => item.id !== v.parent!.id)];
        }
      }
    }

    onChange(next);
  };

  const onExpandInternal = (expandItem: ActivityType, expanded: boolean) => {
    if (expanded) {
      setExpanded(prev => [...(prev ?? []), expandItem]);
    } else {
      setExpanded(prev => [...(prev ?? []).filter(item => item.id !== expandItem.id)]);
    }
  };

  const onExpandAll = useCallback(() => {
    setExpanded(prev => {
      const allToExpand = options.filter(option => !option.parent);
      if (allToExpand.length !== prev?.length) {
        return allToExpand;
      } else {
        return prev;
      }
    });
  }, [options]);

  const onCollapseAll = useCallback(() => {
    setExpanded(prev => {
      if (prev.length) {
        return [];
      } else {
        return prev;
      }
    });
  }, [options]);

  const isSelected = (item: ActivityType) => treeValue?.some(v => v.id === item.id);

  const isSelectedChild = (item: ActivityType) => {
    const children = getChildren(item);
    if (children) {
      return children.some(c => isSelected(c));
    }
    return false;
  };

  const isExpanded = (item: ActivityType) => expanded?.some(v => v.id === item.id);

  const optionsToDisplay: ActivityType[] = [...options];
  options.forEach(option => {
    if (option.parent && !expanded?.some(item => item.id === option.parent?.id)) {
      const index = optionsToDisplay.findIndex(o => o.id === option.id);
      if (index !== -1) {
        optionsToDisplay.splice(index, 1);
      }
    }
  });

  useEffect(() => {
    if (searchValue) {
      onExpandAll();
    } else {
      if (!initialExpandAll) {
        onCollapseAll();
      }
    }
  }, [searchValue, initialExpandAll, onExpandAll, onCollapseAll]);

  return (
    <MPAutocompleteMultipleSelect<ActivityType>
      {...others}
      placeholder={placeholder ?? ''}
      noOptionsText='Не найдено'
      value={treeValue ?? []}
      options={optionsToDisplay}
      filterOptions={(options, state) =>
        options.filter(option => {
          const regexp = new RegExp(`.*${state.inputValue.toLowerCase()}.*`);
          return (
            regexp.test(option.name.toLowerCase()) || (option.parent && regexp.test(option.parent?.name.toLowerCase()))
          );
        })
      }
      renderOption={(props, option) => {
        return option.parent ? (
          <ActivityTypeSelectorLeafItem
            selected={isSelected(option)}
            onChange={checked => onChangeInternal(option, checked)}
          >
            {option.name}
          </ActivityTypeSelectorLeafItem>
        ) : (
          <ActivityTypeSelectorRootItem
            selected={isSelected(option)}
            indeterminate={excludeSelectBranchLevels && isSelectedChild(option)}
            expanded={isExpanded(option)}
            withChildren={!!getChildren(option).length}
            onExpand={expanded => onExpandInternal(option, expanded)}
            onChange={checked => onChangeInternal(option, checked)}
          >
            {option.name}
          </ActivityTypeSelectorRootItem>
        );
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            color='primary'
            label={option.name}
            {...getTagProps({ index })}
            key={option.id}
          />
        ))
      }
      onSearchValue={setSearchValue}
      onChangeValue={newValue => onChange(newValue ?? [])}
    />
  );
};
export default ActivityTypesMultipleSelector;
