import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TableColumn } from '../../../../components/common/table';
import { ProductTableCellCommonProps } from '../cells';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { productsProductOfferByIndexSelector, productsUnwatchedByIndexSelector } from '../store/selectors';

export type ProductTableCellAdapterProps = {
  readonly index: number;
  readonly column?: TableColumn;
  readonly component: FC<ProductTableCellCommonProps>;
};

const ProductTableCellAdapter = (props: ProductTableCellAdapterProps) => {
  const { index, column, component: Component } = props;
  const handlers = useContextHandlers();
  const productOffer = useSelector(productsProductOfferByIndexSelector(index));
  const isUnwatched = productOffer ? useSelector(productsUnwatchedByIndexSelector(index)) : false;
  const isDisabled = productOffer && !isUnwatched ? handlers.isProductNotModeratedByMe(productOffer) : false;

  return useMemo(
    () =>
      productOffer ? (
        <Component
          productOffer={productOffer}
          isDisabled={isDisabled}
          isTextAccent={isUnwatched}
          column={column}
        />
      ) : null,
    [Component, productOffer, isDisabled, isUnwatched]
  );
};

export default ProductTableCellAdapter;
