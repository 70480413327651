import { useSelector } from 'react-redux';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import { EAdCampaignStep } from '../../types';
import { StepWrapper } from '../controls';
import { useContextParams } from '../hooks/useContextParams';
import AdCampaignDetailsStepGeneral from '../steps/general';
import { adCampaignDetailsAdCampaignSelector } from '../store/selectors';

const AdCampaignDetailsStepGeneralAdapter = () => {
  const { step } = useContextParams();

  const { offerTypes, adCampaignTypes } = useSelector(nsiDataSelector);
  const adCampaign = useSelector(adCampaignDetailsAdCampaignSelector);

  if (!adCampaign) {
    return null;
  }

  return step === EAdCampaignStep.General ? (
    <StepWrapper>
      <AdCampaignDetailsStepGeneral
        adCampaign={adCampaign}
        adCampaignTypes={adCampaignTypes}
        offerTypes={offerTypes}
      />
    </StepWrapper>
  ) : null;
};

export default AdCampaignDetailsStepGeneralAdapter;
