import { Grid, Typography } from '@mui/material';
import PartnerWindowBanners from '../banners';
import PartnerWindowCompilation from '../compilation';
import { CompanyName, NameBox, Wrapper } from './controls';

const PartnerWindowStub = () => (
  <Wrapper>
    <Grid
      container
      spacing={10}
    >
      <Grid
        item
        xs={5}
      >
        <Typography paragraph>
          Здесь вы можете добавить баннеры и подборки предложений, которые будут отображаться на вашей странице
          компании.
        </Typography>
        <Typography paragraph>
          Для каждого баннера нужно загрузить два изображения — для веб и мобильной версии сайта, и указать ссылку на
          страницу подборки, категорию или предложение.
        </Typography>
        <Typography paragraph>
          Блок предложений позволяет отобразить заголовок и до 20 выбранных вами предложений.
        </Typography>
        <Typography paragraph>Не допускаются ссылки на сторонние ресурсы и номера телефонов.</Typography>
      </Grid>
      <Grid
        container
        direction='column'
        item
        spacing={1}
        xs={7}
      >
        <Grid item>
          <Typography>Страница компании может состоять</Typography>
          <Typography>из следующих блоков:</Typography>
        </Grid>
        <Grid
          container
          alignItems='center'
          item
          spacing={1}
        >
          <Grid item>
            <NameBox />
          </Grid>
          <Grid item>
            <CompanyName>Название компании</CompanyName>
          </Grid>
        </Grid>
        <PartnerWindowBanners count={1} />
        <PartnerWindowBanners count={2} />
        <PartnerWindowBanners count={3} />
        <PartnerWindowBanners count={4} />
        <PartnerWindowCompilation />
      </Grid>
    </Grid>
  </Wrapper>
);

export default PartnerWindowStub;
