import { Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../components/common/header';
import { MPButton } from '../../../../theme/ui-kit/button';
import { getPartnerCreateRoute } from '../../entry';

interface PartnersTableHeaderAdapterProps {
  readonly canCreate?: boolean;
}

const PartnersTableHeaderAdapter = (props: PartnersTableHeaderAdapterProps) => {
  const { canCreate } = props;

  const history = useHistory();

  const onCreate = () => history.push(getPartnerCreateRoute({}));

  return (
    <DefaultHeader
      headline={<AppBreadcrumbs />}
      actions={
        canCreate && (
          <Grid
            container
            spacing={1}
            alignItems='center'
          >
            <Grid item>
              <MPButton
                variant='outlined'
                size='small'
                onClick={onCreate}
              >
                Добавить партнёра
              </MPButton>
            </Grid>
          </Grid>
        )
      }
    >
      <Typography variant='h2'>Партнеры</Typography>
    </DefaultHeader>
  );
};

export default PartnersTableHeaderAdapter;
