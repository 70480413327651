import { Link, Typography } from '@mui/material';
import ContentLoader from 'presentation/components/common/loader';
import Splitter from 'presentation/components/common/splitter';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { ReactNode, useEffect, useState } from 'react';
import { ValidationItemResult } from '../../../../utils/validation';
import { CodeInput, InfoText } from './controls';
import ConfirmDialog from '@components/common/dialogs/confirm';
import { convertSecondsToHumanView } from '@/presentation/utils/dates';

export type CodeConfirmationDialogProps = {
  readonly open: boolean;
  readonly email: string;
  readonly value: string;
  readonly validation?: ValidationItemResult;
  readonly isFetching?: boolean;
  readonly codeTtl?: number;
  readonly codeRequestId?: string;
  readonly codeNextAttemptSeconds?: number;
  readonly back?: ReactNode;
  readonly onClose?: () => void;
  readonly onChange?: (value: string) => void;
  readonly onSendCode: (code: string) => void;
  readonly onGetNewCode?: () => void;
};

const CodeConfirmationDialog = (props: CodeConfirmationDialogProps) => {
  const {
    open,
    value,
    isFetching,
    email,
    validation,
    codeTtl,
    codeRequestId,
    codeNextAttemptSeconds,
    back,
    onGetNewCode,
    onChange,
    onClose,
    onSendCode,
  } = props;

  const [timer, setTimer] = useState(codeNextAttemptSeconds ?? 0);

  useEffect(() => {
    if (codeNextAttemptSeconds) {
      setTimer(codeNextAttemptSeconds);
    }
  }, [codeNextAttemptSeconds, codeRequestId]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimer(time => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [codeNextAttemptSeconds, codeRequestId]);

  return (
    <ConfirmDialog
      title='Подтвердите электронную почту'
      open={open}
      onClose={onClose}
      dialogButtons={
        <MPButton
          fullWidth
          disabled={isFetching || !value}
          onClick={() => onSendCode(value)}
        >
          Продолжить
          {isFetching && <ContentLoader />}
        </MPButton>
      }
    >
      <InfoText variant='body2'>
        На почту{' '}
        <Typography component='span'>
          <b>{email}</b>
        </Typography>{' '}
        отправлен код подтверждения. Если письмо не пришло, проверьте папку «Спам»
      </InfoText>
      <Splitter size={2} />

      <CodeInput
        label='Код подтверждения'
        value={value}
        type='number'
        error={!!validation?.hasError}
        helperText={validation?.message}
        disabled={isFetching}
        onChange={event => onChange?.(event.target.value)}
      />
      <Splitter size={2} />

      {codeTtl && (
        <Typography variant='body2'>Код действителен в течение {convertSecondsToHumanView(codeTtl)}</Typography>
      )}

      {timer && timer && timer >= 0 ? (
        <Typography variant='body2'>Получить код повторно можно через {timer} секунд</Typography>
      ) : (
        <>
          {onGetNewCode && (
            <Link
              color='primary'
              component={Typography}
              underline='hover'
              onClick={onGetNewCode}
            >
              Получить новый
            </Link>
          )}
        </>
      )}

      {back && (
        <>
          <Splitter size={2} />
          {back}
        </>
      )}
    </ConfirmDialog>
  );
};

export default CodeConfirmationDialog;
