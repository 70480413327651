import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { ApiCancellable } from '../../../../../data/api/types';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable } from '../../../../../domain/model';
import { Chat } from '../../../../../domain/model/chat';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { PaginationSize } from '../../../../types';
import chatServices from '../../services';
import { ChatTableFilterValues } from '../filterUtils';
import { EChatTableColumn } from '../utils';

const defaultSort = `${EChatTableColumn.Name},asc`;

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string>;
  };
  readonly filter: ChatTableFilterValues;
  readonly pageNumber: number;
};

export type ChatsFetchProps = Omit<AllProps, 'signal'>;

export const chatsFetch = createAsyncThunk<Pageable<Chat>, ChatsFetchProps, AppThunkAPIConfig>(
  'chats/list/fetch',
  async ({ search, pageNumber, filter }, { rejectWithValue, signal }) => {
    try {
      return await chatServices.common.all({ pageNumber, filter, search, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface ChatsListState extends Fetchable, Pageable<Chat> {
  readonly pageNumber: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
    readonly name: Nullable<string>;
  };
  readonly needRefreshWatcher: number;
  readonly filter: ChatTableFilterValues;
  readonly guid: Nullable<UUID>;
}

type Reducer<T = undefined> = CaseReducer<ChatsListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ChatsListState> {
  chatsSetPage: Reducer<number>;
  chatsSetPageSize: Reducer<PaginationSize>;
  chatsSetFilter: Reducer<ChatTableFilterValues>;
  chatsResetFilter: Reducer;
  chatsSetSearch: Reducer<{ sort: string; pageSize: PaginationSize; name: Nullable<string> }>;
  chatSortReset: Reducer;
  chatsSetSort: Reducer<{ sort: string }>;
  chatNeedRefreshWatcherReset: Reducer;
  chatsStartSession: Reducer<{ guid: UUID }>;
}

const slice = createSlice<ChatsListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    ...fetchableDefault,
    data: [],
    guid: null,
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    needRefreshWatcher: 0,
    search: {
      sort: '',
      pageSize: 15,
      name: null,
    },
    filter: {},
  },
  reducers: {
    chatsStartSession: (state, { payload }) => {
      const { guid } = payload;
      if (guid !== state.guid) {
        state.guid = guid;
        state.needRefreshWatcher = 0;
      }
    },
    chatsSetPage: (state, { payload }) => {
      state.pageNumber = payload;
      state.needRefreshWatcher++;
    },
    chatsSetPageSize: (state, { payload }) => {
      state.pageNumber = 1;
      state.search.pageSize = payload;
      state.needRefreshWatcher++;
    },
    chatsSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    chatsSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    chatsResetFilter: state => {
      state.filter = {};
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    chatsSetSearch: (state, { payload }) => {
      const { sort, pageSize, name } = payload;
      state.search = {
        sort,
        pageSize,
        name,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    chatSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    chatNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(chatsFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
      })
      .addCase(chatsFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(chatsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      });
  },
});

export const {
  chatsSetPage,
  chatsSetPageSize,
  chatsSetSearch,
  chatsSetFilter,
  chatsResetFilter,
  chatsSetSort,
  chatSortReset,
  chatNeedRefreshWatcherReset,
  chatsStartSession,
} = slice.actions;

export default slice.reducer;
