import MasterActionsComponent from '../../../../../components/common/actions/master';
import { EPanelActionPosition, PanelAction, PanelActions } from '../../../../../types';
import { useContextHandlers } from '../hooks/useContextHandlers';

enum EActionType {
  Close = 'close',
}

const actions: PanelActions<EActionType> = [
  {
    type: EActionType.Close,
    label: 'Завершить редактирование',
    primary: true,
    position: [EPanelActionPosition.Default],
  },
];

export const CorpOfferCategoriesEditFooterAdapter = () => {
  const handlers = useContextHandlers();

  const onAction = (action: PanelAction<EActionType>) => {
    switch (action.type) {
      case EActionType.Close:
        handlers.onClose();
        break;
    }
  };

  return (
    <MasterActionsComponent
      actions={actions}
      onAction={onAction}
    />
  );
};
