import { Skeleton } from '@mui/material';
import { FileDescription } from '@/domain';
import { Card, ImageWrapper, StyledImage } from './controls';

interface ClientOrgPreviewProps {
  readonly image: Nullable<FileDescription>;
  readonly shadow?: boolean;
}

const ClientOrgPreview = ({ image, shadow }: ClientOrgPreviewProps) => {
  return (
    <Card elevation={shadow ? 1 : 0}>
      <ImageWrapper>
        {image?.path ? (
          <StyledImage src={image?.path} />
        ) : (
          <Skeleton
            variant='rectangular'
            animation={false}
            width={'100%'}
            height={'100%'}
          />
        )}
      </ImageWrapper>
    </Card>
  );
};

export default ClientOrgPreview;
