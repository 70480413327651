import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable } from '../../../../../domain/model';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { SportUserProfile } from '../../../../../domain/model/user';
import { PaginationSize } from '../../../../types';
import { SportsmansTableFilterValues } from '../../filterUtils';
import sportsmanServices from '../../services';
import { AllProps } from '../../services/common';
import { ESportsmanTableColumn, ESportsmanTableTab, SportsmanTableTabsCounter } from '../utils';

export type SportsmansFetchProps = Omit<AllProps, 'signal'>;

type SportsmansCountsFetchProps = SportsmansFetchProps & {
  readonly tabs: ESportsmanTableTab[];
};

const defaultSort = `${ESportsmanTableColumn.LastName},asc`;

export interface SportsmansState extends Fetchable, Pageable<SportUserProfile> {
  readonly needRefresh: boolean;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly guid: UUID;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
  };
  readonly tab: Nullable<ESportsmanTableTab>;
  readonly filter: SportsmansTableFilterValues;
  readonly tabsCounter: SportsmanTableTabsCounter;
}

type Reducer<T = undefined> = CaseReducer<SportsmansState, PayloadAction<T>>;

export interface Reducers extends SliceCaseReducers<SportsmansState> {
  sportsmanStartSession: Reducer<{ guid: UUID }>;
  sportsmanSetSearch: CaseReducer<SportsmansState, PayloadAction<any>>;
  sportsmanSetFilter: CaseReducer<SportsmansState, PayloadAction<SportsmansTableFilterValues>>;
  sportsmanResetFilter: CaseReducer<SportsmansState, PayloadAction<undefined>>;
  sportsmanSortReset: CaseReducer<SportsmansState, PayloadAction<undefined>>;
  sportsmanSetPage: CaseReducer<SportsmansState, PayloadAction<{ pageNumber: number }>>;
  sportsmanSetPageSize: CaseReducer<SportsmansState, PayloadAction<{ pageSize: PaginationSize }>>;
  sportsmanNeedRefreshWatcherReset: Reducer;
  sportsmanSetSort: Reducer<{ sort: string }>;
  sportsmanDataReset: Reducer;
  sportsmanSetTab: Reducer<{ tab: ESportsmanTableTab }>;
}

const initialState: SportsmansState = {
  ...fetchableDefault,
  guid: uuidv4(),
  data: [],
  totalCount: 0,
  pageCount: 0,
  pageNumber: 1,
  needRefresh: true,
  needRefreshWatcher: 0,
  search: {
    sort: defaultSort,
    pageSize: 15,
  },
  tab: null,
  filter: {},
  tabsCounter: {},
};

export type SportsmanFetchProps = Omit<AllProps, 'signal'>;

export const sportsmansFetch = createAsyncThunk<Pageable<SportUserProfile>, SportsmanFetchProps, AppThunkAPIConfig>(
  'sportsman/list/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await sportsmanServices.common.all({ ...props, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const sportsmansCountsFetch = createAsyncThunk<
  SportsmanTableTabsCounter,
  SportsmansCountsFetchProps,
  AppThunkAPIConfig
>('sportsman/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await sportsmanServices.common.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

const slice = createSlice<SportsmansState, Reducers, 'list'>({
  name: 'list',
  initialState,
  reducers: {
    sportsmanStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;
        state.needRefreshWatcher = 0;
        state.tabsCounter = {};

        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;

        state.tab = null;
      }
    },
    sportsmanSetFilter: (state, { payload }) => {
      state.filter = { ...payload };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    sportsmanSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    sportsmanNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
    sportsmanSetSearch: (state, { payload }) => {
      const { sort, pageSize } = payload;
      state.search = {
        ...state.search,
        sort,
        pageSize,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    sportsmanResetFilter: state => {
      state.filter = {};
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    sportsmanDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
    },
    sportsmanSetTab: (state, { payload: { tab } }) => {
      // сбрасываем только пейджинг, если поменялся таб (сортировку смысла нет - набор полей одинаков)
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    sportsmanSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    sportsmanSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.search.pageSize = pageSize;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    sportsmanWatcherRefresh: state => {
      state.needRefreshWatcher = 0;
    },
    sportsmanSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sportsmansFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
        state.needRefresh = false;
      })
      .addCase(sportsmansFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(sportsmansFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(sportsmansCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(sportsmansCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      });
  },
});

export const {
  sportsmanSetSearch,
  sportsmanSetFilter,
  sportsmanSetPage,
  sportsmanSetPageSize,
  sportsmanResetFilter,
  sportsmanSortReset,
  sportsmanStartSession,
  sportsmanNeedRefreshWatcherReset,
  sportsmanSetSort,
  sportsmanSetTab,
  sportsmanDataReset,
} = slice.actions;

export default slice.reducer;
