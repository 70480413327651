import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { ProductOffer } from '../../../../../domain/model/productOffer';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { getProductDetailsRoute, getProductEditRoute } from '../../entry';
import {
  EProductTableTab,
  getProductTableTabs,
  getProductTableTabsForCounts,
  getProductTableTabsForCountsUnwatched,
} from '../utils';
import { EOfferStatus } from '../../../../../domain/model/enums';

export type UseProductTableConfig = {
  readonly tabs: EProductTableTab[];
  readonly tabsForCounts: EProductTableTab[];
  readonly tabsForCountsUnwatched: EProductTableTab[];
  readonly isPartnerUser: boolean;
  readonly defaultTab: EProductTableTab;
  readonly multiSelectAllowed: boolean;
  readonly onProductOfferClick: (productOffer: ProductOffer) => void;
  readonly onProductOfferOpenEdit: (productOffer: ProductOffer) => void;
};

export function useProductTableConfig(): UseProductTableConfig {
  const history = useHistory();

  const {
    accessMatrix: { isPartnerUser },
  } = useCurrentUser();

  const tabs = useMemo(() => getProductTableTabs(isPartnerUser), [isPartnerUser]);

  const defaultTab = isPartnerUser ? EProductTableTab.Active : EProductTableTab.Moderation;
  const tabsForCounts = useMemo(() => getProductTableTabsForCounts(isPartnerUser), [isPartnerUser]);
  const tabsForCountsUnwatched = useMemo(() => getProductTableTabsForCountsUnwatched(), []);

  const multiSelectAllowed = true;

  const onProductOfferClick = useCallback(
    (product: ProductOffer) => {
      const path =
        product.status === EOfferStatus.Draft
          ? getProductEditRoute({ id: product.id })
          : getProductDetailsRoute({ id: product.id });
      history.push(path);
    },
    [history]
  );

  const onProductOfferOpenEdit = useCallback(
    (product: ProductOffer) => {
      history.push(getProductEditRoute({ id: product.id }));
    },
    [history]
  );

  return {
    tabs,
    isPartnerUser,
    defaultTab,
    tabsForCounts,
    tabsForCountsUnwatched,
    onProductOfferClick,
    onProductOfferOpenEdit,
    multiSelectAllowed,
  };
}
