import { FileDescription } from 'domain/model';
import { paginationSizeVariant } from 'domain/model/constants';
import { Nullable, UUID } from 'domain/model/types';
import { Location } from 'history';

export type PaginationSize = (typeof paginationSizeVariant)[number];

export type OnChangeObjectAttribute<T> = <A extends keyof T>(name: A, value: T[A]) => void;

export type OnChangeObjectAttributeByIndex<T> = <A extends keyof T>(index: number, name: A, value: T[A]) => void;

export type MenuPanelLink = string | Location;

export type MenuPanelLinkLocationState = {
  readonly guid?: string;
};

export interface MenuPanelItem {
  readonly id: UUID;
  readonly caption: string;
  readonly icon: any;
  readonly link: MenuPanelLink;
  readonly subMenu?: MenuPanelItem[];
}

export enum EPanelActionPosition {
  Default = 'default',
  Menu = 'menu',
  Header = 'header',
  Content = 'content',
}

export type PanelAction<A extends string, P = any> = {
  readonly type: A;
  readonly label: string | JSX.Element;
  readonly groupLabel?: string;
  readonly disabled?: boolean;
  readonly primary?: boolean;
  readonly position: EPanelActionPosition[];
  readonly payload?: P;
};

export type PanelActions<A extends string> = PanelAction<A>[];

export type PanelActionExecutable<T, A extends string> = PanelAction<A> & {
  execute: (object?: T) => void;
};

export type PanelActionsExecutable<T, A extends string> = PanelActionExecutable<T, A>[];

export type EntityInfoPanel<T extends string> = {
  readonly type: T;
  readonly title: string;
  readonly text?: Nullable<string[]>;
};

export type EntityInfoPanels<T extends string> = EntityInfoPanel<T>[];

export enum EAppFeature {
  SportsDay = 'sportsDay',
  CmsPartner = 'cmsPartner',
  CmsLanding = 'cmsLanding',
  ProductOffer = 'productOffer',
  SportEventLike = 'sportEventLike',
  TermsOfUse = 'terms',
  PersonalDataAgreement = 'pda',
  PersonalDataPolicy = 'pdp',
  WebAnalytics = 'webAnalytics',
  Booking = 'booking',
  QueryAnalytics = 'queryAnalytics',
  Activations = 'activations',
  Saas = 'saas',
  AdCampaign = 'adCampaign',
  Asp = 'asp',
  Csp = 'csp',
}

export enum EFileSizeMeasure {
  GB = 'GB',
  MB = 'MB',
  KB = 'KB',
  Byte = 'Byte',
}

export interface UserDataTableSettings<C extends string = string> {
  readonly columns: C[];
}

export enum EEntityPreviewMode {
  Desktop = 'desktop',
  Mobile = 'mobile',
}

export interface EntityPreview {
  readonly id: Nullable<string>;
  readonly desktopImage: Nullable<FileDescription>;
  readonly mobileImage: Nullable<FileDescription>;
}

type _NumbersToNRec<Nr extends number, Counter extends any[], Accumulator extends number> = Counter['length'] extends Nr
  ? Accumulator
  : _NumbersToNRec<Nr, [any, ...Counter], Accumulator | Counter['length']>;
type NumbersToN<Nr extends number> = Nr extends Nr
  ? number extends Nr
    ? number
    : Nr extends 0
    ? never
    : _NumbersToNRec<Nr, [], 0>
  : never;
export type NumberRange<Start extends number, End extends number> = Exclude<NumbersToN<End>, NumbersToN<Start>>;

export type ArrayElementType<ArrayType> = ArrayType extends readonly (infer ElementType)[] ? ElementType : never;
