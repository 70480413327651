import { FileUploadObject } from '../../../../domain/model';
import { ProductCategory } from '../../../../domain/model/productOffer';
import { Nullable } from '../../../../domain/model/types';
import { EValidationType, ValidationRules } from '../../../utils/validation';

export interface ProductOffersUploadObject extends FileUploadObject {
  readonly category: Nullable<ProductCategory>;
}

export const productOffersUploadValidation: ValidationRules<ProductOffersUploadObject> = {
  file: {
    validator: (object, value) => {
      if (!value) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Загрузите файл',
        };
      } else {
        return null;
      }
    },
  },
  category: {
    validator: (object, value) => {
      if (!value) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Выберите категорию',
        };
      } else {
        return null;
      }
    },
  },
};
