import common, { SportsmanCommonService } from './common';

type SportsmanServices = {
  readonly common: SportsmanCommonService;
};

const sportsmanServices: SportsmanServices = {
  common,
};

export default sportsmanServices;
