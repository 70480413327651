import Api from '../../../../data/api';
import { ApiCancellable } from '../../../../data/api/types';
import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '../../../../data/api/utils';
import { OfferFieldAudit, OfferState, Pageable } from '../../../../domain/model';
import { UUID } from '../../../../domain/model/types';
import { PaginationSize } from '../../../types';
import { EVariantHistory } from '../table/utils/common';
import { OfferFieldHistoryFilterValues } from '../table/utils/fieldHistoryFilter';
import { OfferHistoryFilterValues } from '../table/utils/historyFilter';

interface CommonAllProps extends ApiCancellable {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: string;
  };
  readonly offerId: UUID;
  readonly pageNumber: number;
  readonly typeHistory: EVariantHistory;
}

export interface HistoryAllProps extends CommonAllProps {
  readonly filter: OfferHistoryFilterValues;
}

export interface FieldHistoryAllProps extends CommonAllProps {
  readonly filter: OfferFieldHistoryFilterValues;
}

export interface OfferHistoryCommonService {
  readonly history: (props: HistoryAllProps) => Promise<Pageable<OfferState>>;
  readonly fieldHistory: (props: FieldHistoryAllProps) => Promise<Pageable<OfferFieldAudit>>;
}

const service: OfferHistoryCommonService = {
  history: async ({ search, filter, pageNumber, offerId, typeHistory, signal }) => {
    const { pageSize, sort } = search;
    const querydsl = getQueryDslByDataFilterValues(filter);
    const params = {
      id: offerId,
      querydsl,
      page: pageNumber,
      pageSize,
      sort,
      signal,
    };

    let response;
    if (typeHistory === EVariantHistory.BookingOffer) {
      response = await Api.booking.offer.history(params);
    } else {
      response = await Api[typeHistory].history(params);
    }

    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);

    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
  fieldHistory: async ({ search, filter, pageNumber, offerId, typeHistory, signal }) => {
    const { pageSize, sort } = search;
    const querydsl = getQueryDslByDataFilterValues(filter);
    const params = {
      id: offerId,
      querydsl,
      page: pageNumber,
      pageSize,
      sort,
      signal,
    };

    let response;
    if (typeHistory === EVariantHistory.BookingOffer) {
      response = await Api.booking.offer.fieldHistory(params);
    } else {
      response = await Api[typeHistory].fieldHistory(params);
    }

    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);

    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
};

export default service;
