import { Typography, TypographyProps } from '@mui/material';
import styled from '@emotion/styled';
import { Nullable } from '../../../../../../../domain/model/types';
import AppImage from '../../../../../../components/common/images/common';
import { publicAppPalette } from '../../../../../../theme/palette';

interface ForegroundImageStubProps {
  readonly isActive: boolean;
}

interface BackgroundImageStubProps {
  readonly canActivateItem?: boolean;
}

interface BannerNumberProps extends TypographyProps {
  readonly isActive: boolean;
}

interface WrapperProps {
  readonly width?: Nullable<string>;
  readonly height?: Nullable<string>;
  readonly children?: any;
}

export const Wrapper = styled(({ width, height, ...others }: WrapperProps) => <div {...others} />)`
  width: 100%;

  ${p =>
    p.width &&
    p.height &&
    `
  aspect-ratio: ${parseFloat(p.width) / parseFloat(p.height)};
  `}
`;

export const ImageWrapper = styled.div`
  height: 100%;
`;

const ImageStub = styled.div`
  height: 100%;

  border-width: 0.5px;
  border-radius: ${p => (p.theme.shape.borderRadius as number) / 2}px;
`;

export const ForegroundImageStub = styled(({ isActive, ...others }: ForegroundImageStubProps) => (
  <ImageStub {...others} />
))`
  background-color: ${p => (p.isActive ? p.theme.palette.disabled.background : p.theme.palette.background.default)};
  border-color: ${p => (p.isActive ? p.theme.palette.text.primary : p.theme.palette.text.secondary)};

  border-style: ${p => (p.isActive ? 'solid' : 'dashed')};

  box-shadow: 0 ${p => p.theme.spacing(0.5)} ${p => p.theme.spacing(4.5)} ${publicAppPalette.shadow} !important;
`;

export const BackgroundImageStub = styled(({ canActivateItem, ...others }: BackgroundImageStubProps) => (
  <ImageStub {...others} />
))`
  background-color: ${p => (p.canActivateItem ? p.theme.palette.background.default : p.theme.palette.text.secondary)};
  border-color: ${p => (p.canActivateItem ? p.theme.palette.text.secondary : p.theme.palette.text.primary)};

  border-style: dashed;
`;

export const StyledImage = styled(AppImage)`
  height: 100%;
  width: 100%;

  object-fit: cover;
  object-position: center center;
  vertical-align: top;

  border-radius: ${p => (p.theme.shape.borderRadius as number) / 2}px;
`;

export const Number = styled(({ isActive, ...others }: BannerNumberProps) => <Typography {...others} />)`
  color: ${p => (p.isActive ? p.theme.palette.text.primary : p.theme.palette.disabled.text)};
`;

export const Caption = styled(Typography)`
  height: 1rem;
  line-height: 1rem;

  font-size: 0.4rem;
  text-align: center;
`;
