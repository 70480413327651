import { userRewardImageSize } from '../../utils';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding-top: ${p => p.theme.spacing(3)};
`;

export const SportsmanMediaWrapper = styled.div`
  height: ${userRewardImageSize};
  width: ${userRewardImageSize};

  position: relative;

  border-radius: ${p => p.theme.shape.borderRadius}px;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;

    object-fit: cover;
    object-position: center center;
  }
`;
