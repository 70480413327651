import { Typography } from '@mui/material';
import React, { FCC, ReactNode, useMemo } from 'react';
import { ContentWrapper, Wrapper } from './controls';

export interface FieldProps {
  readonly label?: ReactNode;
  readonly gap?: number;
}

export const Field: FCC<FieldProps> = props => {
  const { label, gap, children } = props;

  const validChildren = useMemo(
    () => React.Children.map(children, child => (React.isValidElement(child) || !!child ? child : null)),
    [children]
  );

  if (!validChildren?.length) {
    return null;
  }

  return (
    <Wrapper gap={gap}>
      {label && (
        <Typography
          variant={'body2'}
          component={'div'}
          color={'textSecondary'}
        >
          {label}
        </Typography>
      )}
      <ContentWrapper
        component={'div'}
        variant={'body1'}
      >
        {validChildren}
      </ContentWrapper>
    </Wrapper>
  );
};

