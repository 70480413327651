import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nullable, UUID } from '../../../../../domain/model/types';
import {
  teamsFilterSelector,
  teamsGuidSelector,
  teamsNeedRefreshWatcherSelector,
  teamsPageNumberSelector,
  teamsSearchSelector,
} from '../store/selectors';
import { teamsCountsFetch, teamsFetch, TeamsFetchProps, teamsNeedRefreshWatcherReset } from '../store/slice';
import { useContextConfig } from './useContextConfig';

interface UseTeamsTableSearchProps {
  readonly guid: UUID;
}

const useTeamsTableSearch = ({ guid }: UseTeamsTableSearchProps): void => {
  const dispatch = useDispatch();

  const [searchPromises, setSearchPromises] = useState<Nullable<any[]>>(null);

  const { tabsForCounts } = useContextConfig();

  const currentGuid = useSelector(teamsGuidSelector);
  const needRefreshWatcher = useSelector(teamsNeedRefreshWatcherSelector);
  const filter = useSelector(teamsFilterSelector);
  const pageNumber = useSelector(teamsPageNumberSelector);
  const search = useSelector(teamsSearchSelector);

  const queryParams = useMemo<TeamsFetchProps>(() => {
    return {
      search,
      filter,
      pageNumber,
    };
  }, [filter, search, pageNumber]);

  const onSearch = useCallback(() => {
    setSearchPromises([
      dispatch(teamsFetch(queryParams)),
      dispatch(
        teamsCountsFetch({
          ...queryParams,
          tabs: tabsForCounts,
        })
      ),
    ]);
  }, [dispatch, queryParams]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  // Первоначальная загрузка
  useEffect(() => {
    if (guid === currentGuid) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, guid, currentGuid]);

  // сброс вотчера после ухода
  useEffect(() => {
    return () => {
      dispatch(teamsNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};

export default useTeamsTableSearch;
