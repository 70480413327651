import { ApiRequestPageable } from '@/data/api/types';
import { appendQueryDslParams, getQueryDslByDataFilterValues, getTradeOffersDownloadEndpoint } from '@/data/api/utils';
import { DataFilterQueryDslOperator, EOfferStatus, Nullable, TradeOffer, UUID } from '@/domain';
import { DataTableColumns, ETableColumnAlign } from '@components/common/table';
import { EOfferActionType, EOfferTableTab, OfferActionTableCommonType } from '../../general/offer/types';
import { TradeOfferTableFilterValues } from '../filterUtils';
import { ETradeOfferTableColumn } from '../types';

enum ETradeOffersDownloadUrlParam {
  Status = 'status',
  PartnerId = 'partner.id',
}

type GetTradeOffersDownloadUrlSkipPaginationProps = {
  readonly skipPageable: true;
} & Partial<ApiRequestPageable>;

type GetTradeOffersDownloadUrlNoSkipPaginationProps = {
  readonly skipPageable?: false;
} & ApiRequestPageable;

type MabeSkipPaginationGetTradeOffersDownloadUrlProps =
  | GetTradeOffersDownloadUrlSkipPaginationProps
  | GetTradeOffersDownloadUrlNoSkipPaginationProps;

type GetTradeOffersDownloadUrlProps = {
  readonly partnerId?: Nullable<UUID>;
  readonly states?: Nullable<EOfferStatus[]>;
  readonly filter: TradeOfferTableFilterValues;
} & MabeSkipPaginationGetTradeOffersDownloadUrlProps;

export const getTradeOffersDownloadUrl = ({
  sort,
  page,
  pageSize,
  partnerId,
  states,
  filter,
  skipPageable,
}: GetTradeOffersDownloadUrlProps) => {
  const searchParams = new URLSearchParams();

  if (sort) {
    if (typeof sort === 'string') {
      searchParams.append('sort', sort);
    } else {
      sort.forEach(item => searchParams.append('sort', item));
    }
  }

  if (skipPageable) {
    searchParams.append('skipPageable', skipPageable.toString());
  } else {
    page && searchParams.append('page', (page - 1).toString(10));
    pageSize && searchParams.append('size', pageSize.toString(10));
  }

  const queryDsl = getQueryDslByDataFilterValues(filter) ?? [];

  if (states) {
    queryDsl.push({
      field: ETradeOffersDownloadUrlParam.Status,
      operator: DataFilterQueryDslOperator.In,
      value: states,
    });
  }

  if (partnerId) {
    queryDsl.push({
      field: ETradeOffersDownloadUrlParam.PartnerId,
      operator: DataFilterQueryDslOperator.Equals,
      value: [partnerId],
    });
  }

  appendQueryDslParams(searchParams, queryDsl);

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return `${getTradeOffersDownloadEndpoint()}${search}`;
};

export const tradeOffersTableColumns: DataTableColumns<ETradeOfferTableColumn> = {
  [ETradeOfferTableColumn.LastStatusDate]: {
    title: 'Дата',
    sortable: true,
    width: '5rem',
  },
  [ETradeOfferTableColumn.Code]: {
    title: 'Код ТП',
    sortable: true,
    width: '6rem',
  },
  [ETradeOfferTableColumn.Name]: {
    title: 'Название',
    width: '30rem',
    wrap: true,
    sortable: true,
  },
  [ETradeOfferTableColumn.PromotionType]: {
    title: 'Тип предложения',
    width: '15rem',
    sortable: true,
  },
  [ETradeOfferTableColumn.Partner]: {
    title: 'Партнёр',
    width: '18rem',
    wrap: true,
    sortable: true,
  },
  [ETradeOfferTableColumn.Price]: {
    title: 'Стоимость',
    width: '6rem',
    sortable: true,
  },
  [ETradeOfferTableColumn.Period]: {
    title: 'Начало - окончание',
    align: ETableColumnAlign.Center,
    sortable: true,
  },
  [ETradeOfferTableColumn.Status]: {
    title: 'Статус',
    sortable: true,
  },
  [ETradeOfferTableColumn.ApprovingAdmin]: {
    title: 'Ответственный',
    sortable: true,
  },
  [ETradeOfferTableColumn.LastStatusAuthor]: {
    title: 'Ответственный',
    sortable: true,
  },
  [ETradeOfferTableColumn.OfferCounts]: {
    title: 'Доступно/всего',
    width: '8rem',
    sortable: true,
  },
  [ETradeOfferTableColumn.PausedReason]: {
    title: 'Причина остановки',
    width: '10rem',
    wrap: true,
    sortable: true,
  },
  [ETradeOfferTableColumn.TargetLocalities]: {
    title: 'Город',
    width: '15rem',
    wrap: true,
  },
  [ETradeOfferTableColumn.TargetExternalUsers]: {
    title: 'Внешние пользователи',
    width: '4rem',
    sortable: true,
  },
  [ETradeOfferTableColumn.TargetClientOrgs]: {
    title: 'Компании',
    width: '10rem',
    wrap: true,
    sortable: true,
  },
  [ETradeOfferTableColumn.AggregatorIntegrationId]: {
    title: 'ID интеграции',
    width: '8rem',
    sortable: true,
  },
};

const tradeOfferAdminTableDefaultColumns = [
  ETradeOfferTableColumn.LastStatusDate,
  ETradeOfferTableColumn.Code,
  ETradeOfferTableColumn.Name,
  ETradeOfferTableColumn.PromotionType,
  ETradeOfferTableColumn.Partner,
  ETradeOfferTableColumn.Price,
  ETradeOfferTableColumn.Period,
  ETradeOfferTableColumn.OfferCounts,
  ETradeOfferTableColumn.ApprovingAdmin,
  ETradeOfferTableColumn.AggregatorIntegrationId,
];

const getTradeOfferAdminTableDefaultColumns = (tab: EOfferTableTab): ETradeOfferTableColumn[] => {
  let columns: ETradeOfferTableColumn[];
  switch (tab) {
    case EOfferTableTab.Active:
      columns = tradeOfferAdminTableDefaultColumns;
      break;
    case EOfferTableTab.Paused:
      columns = [
        ...tradeOfferAdminTableDefaultColumns,
        ETradeOfferTableColumn.PausedReason,
        ETradeOfferTableColumn.LastStatusAuthor,
      ];
      break;
    default:
      columns = tradeOfferAdminTableDefaultColumns;
  }

  return columns;
};

export const getTradeOfferAdminTableColumns = (
  tab: EOfferTableTab,
  partnerId: Nullable<UUID>,
  isAspFeature: boolean
): DataTableColumns<ETradeOfferTableColumn> => {
  const defaultColumns = getTradeOfferAdminTableDefaultColumns(tab);

  const allColumns: ETradeOfferTableColumn[] = [];

  switch (tab) {
    case EOfferTableTab.Rejected:
      allColumns.push(
        ETradeOfferTableColumn.LastStatusDate,
        ETradeOfferTableColumn.Code,
        ETradeOfferTableColumn.Name,
        ETradeOfferTableColumn.PromotionType,
        ETradeOfferTableColumn.Partner,
        ETradeOfferTableColumn.Price,
        ETradeOfferTableColumn.ApprovingAdmin,
        ETradeOfferTableColumn.TargetLocalities,
        ETradeOfferTableColumn.TargetExternalUsers,
        ETradeOfferTableColumn.TargetClientOrgs,
        ETradeOfferTableColumn.AggregatorIntegrationId
      );
      break;
    case EOfferTableTab.Moderation:
    case EOfferTableTab.Upcoming:
      allColumns.push(
        ETradeOfferTableColumn.LastStatusDate,
        ETradeOfferTableColumn.Code,
        ETradeOfferTableColumn.Name,
        ETradeOfferTableColumn.PromotionType,
        ETradeOfferTableColumn.Partner,
        ETradeOfferTableColumn.Price,
        ETradeOfferTableColumn.Period,
        ETradeOfferTableColumn.OfferCounts,
        ETradeOfferTableColumn.ApprovingAdmin,
        ETradeOfferTableColumn.TargetLocalities,
        ETradeOfferTableColumn.TargetExternalUsers,
        ETradeOfferTableColumn.TargetClientOrgs,
        ETradeOfferTableColumn.AggregatorIntegrationId
      );
      break;
    case EOfferTableTab.Active:
      allColumns.push(
        ETradeOfferTableColumn.Code,
        ETradeOfferTableColumn.Name,
        ETradeOfferTableColumn.PromotionType,
        ETradeOfferTableColumn.Partner,
        ETradeOfferTableColumn.Price,
        ETradeOfferTableColumn.Period,
        ETradeOfferTableColumn.OfferCounts,
        ETradeOfferTableColumn.ApprovingAdmin,
        ETradeOfferTableColumn.TargetLocalities,
        ETradeOfferTableColumn.TargetExternalUsers,
        ETradeOfferTableColumn.TargetClientOrgs,
        ETradeOfferTableColumn.AggregatorIntegrationId
      );
      break;
    case EOfferTableTab.Paused:
      allColumns.push(
        ETradeOfferTableColumn.LastStatusDate,
        ETradeOfferTableColumn.Code,
        ETradeOfferTableColumn.Name,
        ETradeOfferTableColumn.PromotionType,
        ETradeOfferTableColumn.Partner,
        ETradeOfferTableColumn.Price,
        ETradeOfferTableColumn.Period,
        ETradeOfferTableColumn.OfferCounts,
        ETradeOfferTableColumn.PausedReason,
        ETradeOfferTableColumn.LastStatusAuthor,
        ETradeOfferTableColumn.TargetLocalities,
        ETradeOfferTableColumn.TargetExternalUsers,
        ETradeOfferTableColumn.TargetClientOrgs,
        ETradeOfferTableColumn.AggregatorIntegrationId
      );
      break;
    default:
      allColumns.push(
        ETradeOfferTableColumn.LastStatusDate,
        ETradeOfferTableColumn.Code,
        ETradeOfferTableColumn.Name,
        ETradeOfferTableColumn.PromotionType,
        ETradeOfferTableColumn.Partner,
        ETradeOfferTableColumn.Price,
        ETradeOfferTableColumn.Period,
        ETradeOfferTableColumn.OfferCounts,
        ETradeOfferTableColumn.ApprovingAdmin,
        ETradeOfferTableColumn.TargetLocalities,
        ETradeOfferTableColumn.TargetExternalUsers,
        ETradeOfferTableColumn.TargetClientOrgs,
        ETradeOfferTableColumn.AggregatorIntegrationId
      );
      break;
  }

  if (partnerId) {
    allColumns.splice(allColumns.indexOf(ETradeOfferTableColumn.Partner), 1);
  }

  if (!isAspFeature) {
    allColumns.splice(allColumns.indexOf(ETradeOfferTableColumn.Price), 1);
    allColumns.splice(allColumns.indexOf(ETradeOfferTableColumn.AggregatorIntegrationId), 1);
  }

  return allColumns.reduce<DataTableColumns<ETradeOfferTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...tradeOffersTableColumns[column],
        hidden: !defaultColumns.includes(column),
      },
    };
  }, {});
};

const tradeOfferPartnerTableDefaultColumns = [
  ETradeOfferTableColumn.Code,
  ETradeOfferTableColumn.Name,
  ETradeOfferTableColumn.PromotionType,
  ETradeOfferTableColumn.Price,
  ETradeOfferTableColumn.Period,
  ETradeOfferTableColumn.OfferCounts,
  ETradeOfferTableColumn.AggregatorIntegrationId,
];

export const getTradeOfferPartnerTableColumns = (
  tab: EOfferTableTab,
  isAspFeature: boolean
): DataTableColumns<ETradeOfferTableColumn> => {
  const defaultColumns = tradeOfferPartnerTableDefaultColumns;

  const allColumns: ETradeOfferTableColumn[] = [];
  switch (tab) {
    case EOfferTableTab.Moderation:
      allColumns.push(
        ETradeOfferTableColumn.Code,
        ETradeOfferTableColumn.Name,
        ETradeOfferTableColumn.PromotionType,
        ETradeOfferTableColumn.Period,
        ETradeOfferTableColumn.Price,
        ETradeOfferTableColumn.Status,
        ETradeOfferTableColumn.OfferCounts,
        ETradeOfferTableColumn.TargetLocalities,
        ETradeOfferTableColumn.TargetExternalUsers,
        ETradeOfferTableColumn.TargetClientOrgs,
        ETradeOfferTableColumn.AggregatorIntegrationId
      );
      break;
    case EOfferTableTab.Paused:
      allColumns.push(
        ETradeOfferTableColumn.Code,
        ETradeOfferTableColumn.Name,
        ETradeOfferTableColumn.PromotionType,
        ETradeOfferTableColumn.Price,
        ETradeOfferTableColumn.Period,
        ETradeOfferTableColumn.OfferCounts,
        ETradeOfferTableColumn.PausedReason,
        ETradeOfferTableColumn.TargetLocalities,
        ETradeOfferTableColumn.TargetExternalUsers,
        ETradeOfferTableColumn.TargetClientOrgs,
        ETradeOfferTableColumn.AggregatorIntegrationId
      );
      break;
    default:
      allColumns.push(
        ETradeOfferTableColumn.Code,
        ETradeOfferTableColumn.Name,
        ETradeOfferTableColumn.PromotionType,
        ETradeOfferTableColumn.Price,
        ETradeOfferTableColumn.Period,
        ETradeOfferTableColumn.OfferCounts,
        ETradeOfferTableColumn.TargetLocalities,
        ETradeOfferTableColumn.TargetExternalUsers,
        ETradeOfferTableColumn.TargetClientOrgs,
        ETradeOfferTableColumn.AggregatorIntegrationId
      );
      break;
  }

  if (!isAspFeature) {
    allColumns.splice(allColumns.indexOf(ETradeOfferTableColumn.Price), 1);
    allColumns.splice(allColumns.indexOf(ETradeOfferTableColumn.AggregatorIntegrationId), 1);
  }

  return allColumns.reduce<DataTableColumns<ETradeOfferTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...tradeOffersTableColumns[column],
        hidden: !defaultColumns.includes(column),
      },
    };
  }, {});
};

export type TradeOfferActionCommonType = Exclude<OfferActionTableCommonType, EOfferActionType.ChangeApprovalRegistry>;

export type TradeOfferActionCall = {
  readonly action: TradeOfferActionCommonType;
  readonly tradeOffer: TradeOffer;
};
