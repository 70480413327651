import { ContentTarget, Target } from '@/domain';
import { useSelector } from 'react-redux';
import { OnChangeObjectAttribute } from '../../../../types';
import { ValidationResult } from '../../../../utils/validation';
import ContentTargetEditContainer from '../../../general/contentTarget/edit/container';
import { ContentTargetComponentsType } from '../../../general/contentTarget/types';
import {
  convertContentTargetAttributeToTargetAttribute,
  convertTargetToContentTarget,
  convertTargetValidationToContentTargetValidation,
} from '../../../general/contentTarget/utils';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';

type BannerEditTargetAdapterProps<T extends Target> = ContentTargetComponentsType & {
  readonly target: T;
  readonly validation: Nullable<ValidationResult<T>>;
  readonly onChangeAttribute: OnChangeObjectAttribute<T>;
};

const BannerEditTargetAdapter = <T extends Target>(props: BannerEditTargetAdapterProps<T>) => {
  const { target, validation, onChangeAttribute, ...components } = props;

  const { userGenders: genderTypes } = useSelector(nsiDataSelector);

  const contentTarget: ContentTarget = convertTargetToContentTarget(target);
  const contentTargetValidation: ValidationResult<ContentTarget> =
    convertTargetValidationToContentTargetValidation(validation);

  const onChangeAttributeInternal: OnChangeObjectAttribute<ContentTarget> = (name, value) => {
    onChangeAttribute(convertContentTargetAttributeToTargetAttribute(name), value as any);
  };

  return (
    <ContentTargetEditContainer
      {...components}
      target={contentTarget}
      nsi={{ genderTypes }}
      validation={contentTargetValidation}
      onChangeAttribute={onChangeAttributeInternal}
    />
  );
};

export default BannerEditTargetAdapter;
