import { MPAutocompleteMultipleSelectItem } from '../../../../../theme/ui-kit/autocomplete/types';

export enum SportOptionMultipleItemType {
  All = 'all',
  Option = 'option',
  None = 'none',
}

export enum SportOptionMultipleAllOptionsState {
  Indeterminate = 'indeterminate',
  Checked = 'checked',
  NotChecked = 'not-checked',
}

export enum SportOptionMultipleSelectType {
  All = 'all',
  None = 'none',
}

export type SportOptionMultipleTypesSelectorItem<T extends string> =
  | (MPAutocompleteMultipleSelectItem<T> & {
      readonly type: SportOptionMultipleItemType.Option;
    })
  | (MPAutocompleteMultipleSelectItem<T> & {
      readonly type: SportOptionMultipleItemType.All;
      readonly state: SportOptionMultipleAllOptionsState;
    })
  | (MPAutocompleteMultipleSelectItem<T> & {
      readonly type: SportOptionMultipleItemType.None;
      readonly state: SportOptionMultipleAllOptionsState;
    });
