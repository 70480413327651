import { FCC, ReactNode, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UUID } from '../../../domain/model/types';
import useYScroll from '../../hooks/useYScroll';
import { Footer, Wrapper } from './controls';
import { menuPanelLayoutScrollPositionSelector } from './store/selectors';
import { menuPanelLayoutSetScrollPosition } from './store/slice';

interface MenuPanelLayoutProps {
  readonly guid: UUID;
  readonly header?: ReactNode;
  readonly footer?: ReactNode;
}

const MenuPanelLayout: FCC<MenuPanelLayoutProps> = props => {
  const { guid, header, footer, children } = props;

  const dispatch = useDispatch();
  const scrollPosition = useSelector(menuPanelLayoutScrollPositionSelector(guid));

  const wrapperRef = useRef<HTMLDivElement>(null);

  const innerScrollPosition = useYScroll(wrapperRef, scrollPosition);

  useEffect(() => {
    dispatch(menuPanelLayoutSetScrollPosition({ guid, scrollPosition: innerScrollPosition }));
  }, [innerScrollPosition]);

  return (
    <Wrapper ref={wrapperRef}>
      {header}
      {children}
      {footer && <Footer>{footer}</Footer>}
    </Wrapper>
  );
};

export default MenuPanelLayout;
