import { Grid, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { UploadIcon } from '../../../../icons';
import { Dropzone } from './controls';
import { FileUploaderAdapterType } from './types';

export const FileUploaderAreaAdapter: FileUploaderAdapterType = props => {
  const { text, description, accept, error, disabled, onUpload } = props;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: files => onUpload?.(files),
    maxFiles: 1,
    disabled,
  });

  return (
    <Dropzone
      error={error}
      disabled={disabled}
      item
      {...getRootProps()}
    >
      <Grid
        container
        spacing={1}
        direction='column'
        alignItems='center'
      >
        <Grid item>
          <UploadIcon color={disabled ? 'disabled' : 'primary'} />
        </Grid>
        <Grid item>
          {typeof text === 'string' ? (
            <Typography
              variant='body1'
              color={disabled ? 'secondary' : 'primary'}
              align='center'
            >
              {text}
            </Typography>
          ) : (
            <>{text}</>
          )}

          {typeof description === 'string' ? (
            <Typography
              variant='body1'
              color='secondary'
              align='center'
            >
              {description}
            </Typography>
          ) : (
            <>{description}</>
          )}

          {isDragActive ? (
            <Typography
              variant='body2'
              color='textSecondary'
              align='center'
            >
              отпустите клавишу мыши
            </Typography>
          ) : (
            <Typography
              variant='body2'
              color='textSecondary'
              align='center'
            >
              Нажмите или перенесите файл в эту область
            </Typography>
          )}
        </Grid>
      </Grid>
      {!disabled && (
        <input
          {...getInputProps()}
          multiple={false}
          accept={accept?.join(',')}
        />
      )}
    </Dropzone>
  );
};
