import { EOfferStatus } from '@/domain';
import { EOfferActionType } from '../../types';
import { OfferLifeCycleStatusScheme } from '../types';

export const adminStatusScheme: OfferLifeCycleStatusScheme = {
  description: 'Статусная схема главного админа',
  statusScheme: {
    [EOfferStatus.Draft]: [
      EOfferActionType.Edit,
      EOfferActionType.Publish,
      EOfferActionType.CreateCodes,
      EOfferActionType.DeleteCodes,
      EOfferActionType.DownloadCodes,
    ],
    [EOfferStatus.Rejected]: [
      EOfferActionType.Publish,
      EOfferActionType.CreateCodes,
      EOfferActionType.DeleteCodes,
      EOfferActionType.DownloadCodes,
      EOfferActionType.ReturnToVerification,
    ],
    [EOfferStatus.Paused]: [
      EOfferActionType.Edit,
      EOfferActionType.Resume,
      EOfferActionType.Archive,
      EOfferActionType.ChangeApprovalRegistry,
      EOfferActionType.CreateCodes,
      EOfferActionType.DeleteCodes,
      EOfferActionType.DownloadCodes,
    ],
    [EOfferStatus.Upcoming]: [
      EOfferActionType.Edit,
      EOfferActionType.Pause,
      EOfferActionType.Archive,
      EOfferActionType.ChangeApprovalRegistry,
      EOfferActionType.CreateCodes,
      EOfferActionType.DeleteCodes,
      EOfferActionType.DownloadCodes,
    ],
    [EOfferStatus.WaitingForModeration]: [
      EOfferActionType.Approve,
      EOfferActionType.Reject,
      EOfferActionType.InWork,
      EOfferActionType.ChangeApprovalRegistry,
      EOfferActionType.ChangeModerator,
      EOfferActionType.DownloadCodes,
    ],
    [EOfferStatus.OnModeration]: [
      EOfferActionType.Edit,
      EOfferActionType.Approve,
      EOfferActionType.Reject,
      EOfferActionType.Publish,
      EOfferActionType.ChangeApprovalRegistry,
      EOfferActionType.ChangeModerator,
      EOfferActionType.CreateCodes,
      EOfferActionType.DeleteCodes,
      EOfferActionType.DownloadCodes,
    ],
    [EOfferStatus.Active]: [
      EOfferActionType.Edit,
      EOfferActionType.Pause,
      EOfferActionType.Archive,
      EOfferActionType.ChangeApprovalRegistry,
      EOfferActionType.CreateCodes,
      EOfferActionType.DeleteCodes,
      EOfferActionType.DownloadCodes,
    ],
    [EOfferStatus.Archived]: [EOfferActionType.Retrieve, EOfferActionType.DownloadCodes],
    [EOfferStatus.ReadyForSale]: [],
  },
  actionsScheme: {} as any,
};

export const partnerStatusScheme: OfferLifeCycleStatusScheme = {
  description: 'Статусная схема пользователя партнёра',
  statusScheme: {
    [EOfferStatus.Draft]: [
      EOfferActionType.Edit,
      EOfferActionType.Publish,
      EOfferActionType.Delete,
      EOfferActionType.CreateCodes,
      EOfferActionType.DeleteCodes,
      EOfferActionType.Duplicate,
    ],
    [EOfferStatus.Rejected]: [
      EOfferActionType.Edit,
      EOfferActionType.Publish,
      EOfferActionType.CreateCodes,
      EOfferActionType.Duplicate,
      EOfferActionType.DeleteCodes,
    ],
    [EOfferStatus.Paused]: [
      EOfferActionType.Resume,
      EOfferActionType.Archive,
      EOfferActionType.CreateCodes,
      EOfferActionType.DeleteCodes,
      EOfferActionType.Duplicate,
    ],
    [EOfferStatus.Upcoming]: [
      EOfferActionType.Pause,
      EOfferActionType.Archive,
      EOfferActionType.CreateCodes,
      EOfferActionType.DeleteCodes,
      EOfferActionType.Duplicate,
    ],
    [EOfferStatus.WaitingForModeration]: [
      EOfferActionType.UnPublish,
      EOfferActionType.UnPublishAndEdit,
      EOfferActionType.CreateCodes,
      EOfferActionType.DeleteCodes,
      EOfferActionType.Duplicate,
    ],
    [EOfferStatus.OnModeration]: [],
    [EOfferStatus.Active]: [
      EOfferActionType.Pause,
      EOfferActionType.Archive,
      EOfferActionType.CreateCodes,
      EOfferActionType.DeleteCodes,
      EOfferActionType.Duplicate,
    ],
    [EOfferStatus.Archived]: [EOfferActionType.Retrieve, EOfferActionType.Duplicate],
    [EOfferStatus.ReadyForSale]: [EOfferActionType.Duplicate],
  },
  actionsScheme: {} as any,
};
