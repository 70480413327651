import React from 'react';
import { BookingServiceCategory } from '../../../../../domain/model/booking';
import { EServiceOrderByDateType } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { BookingServiceCategoriesEditState } from './store/slice';

export type BookingServiceCategoriesEditHandlersContextValue = {
  readonly onChangeDialogState: <Name extends keyof BookingServiceCategoriesEditState['dialogs']>(
    name: Name,
    data: Nullable<BookingServiceCategoriesEditState['dialogs'][Name]>
  ) => void;
  readonly onCreate: (bookingServiceCategory: BookingServiceCategory) => Promise<BookingServiceCategory>;
  readonly onEdit: (bookingServiceCategory: BookingServiceCategory) => Promise<BookingServiceCategory>;
  readonly onAddOrderByDateType: (
    bookingServiceCategory: BookingServiceCategory,
    orderByDateType: EServiceOrderByDateType
  ) => Promise<void>;
  readonly onRemoveOrderByDateType: (
    bookingServiceCategory: BookingServiceCategory,
    orderByDateType: EServiceOrderByDateType
  ) => Promise<void>;
  readonly onDelete: (bookingServiceCategory: BookingServiceCategory) => Promise<void>;
  readonly onClose: () => void;
};

export const BookingServiceCategoriesEditHandlersContext =
  React.createContext<BookingServiceCategoriesEditHandlersContextValue>(
    {} as BookingServiceCategoriesEditHandlersContextValue
  );
