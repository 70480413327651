import { ENoticeStatus, PartnerContactData, PartnerDesk, UUID } from '@/domain';
import { DefaultContentWrapper } from '@components/common/wrappers/content';
import { DefaultFooterWrapper } from '@components/common/wrappers/footer';
import { Fade, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Notifier from '../../../../system/notifier';
import MasterActionsComponent from '../../../components/common/actions/master';
import DefaultHeader from '../../../components/common/header';
import ContentLoader from '../../../components/common/loader';
import useHistoryExtensions from '../../../hooks/useHistoryExtensions';
import useValidation from '../../../hooks/validation/useValidation';
import useValidationCollection from '../../../hooks/validation/useValidationCollection';
import {
  EPanelActionPosition,
  OnChangeObjectAttribute,
  OnChangeObjectAttributeByIndex,
  PanelAction,
  PanelActions,
} from '../../../types';
import StepperContainer from '../../general/stepper/container';
import { getPartnerDetailsRoute } from '../../partner/entry';
import { getPartnerDeskEditRoute } from '../entry';
import { EPartnerDeskActionType, PartnerDeskEditActionType } from '../types';
import PartnerDeskEdit from './component';
import { ContainerWrapper, ContentContainer, LoaderWrapper, TitleWrapper } from './controls';
import { partnerDeskEditByIdSelector, partnerDeskEditSaveSelector } from './store/selectors';
import {
  partnerDeskEditAddContact,
  partnerDeskEditByIdFetch,
  partnerDeskEditRemoveContact,
  partnerDeskEditSave,
  partnerDeskEditSetContactAttribute,
  partnerDeskEditSetDeskAttribute,
  partnerDeskEditStateReset,
} from './store/slice';
import {
  EPartnerDeskEditStep,
  getPartnerDeskEditValidation,
  partnerContactEditValidation,
  partnerDeskEditSteps,
} from './utils';

interface PartnerDeskEditContainerProps {
  readonly partnerId: UUID;
  readonly step?: EPartnerDeskEditStep;
}

const PartnerDeskEditContainer = (props: PartnerDeskEditContainerProps) => {
  const { partnerId, step = partnerDeskEditSteps[0].key } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const [validateOnChangeDesk, setValidateOnChangeDesk] = useState<boolean>(false);
  const [validateOnChangeContacts, setValidateOnChangeContacts] = useState<boolean>(false);

  const { desk, isFetching, isFetched } = useSelector(partnerDeskEditByIdSelector);
  const { isFetching: isSaving, isFetched: isSaved } = useSelector(partnerDeskEditSaveSelector);

  const {
    validationResult: validationResultDesk,
    isValid: isValidDesk,
    validate: validateDesk,
  } = useValidation<PartnerDesk>({
    object: desk ?? null,
    validateOnChange: validateOnChangeDesk,
    rules: getPartnerDeskEditValidation(step),
  });

  const {
    validationResult: validationResultContacts,
    isValid: isValidContacts,
    validate: validateContacts,
    resetValidationResult: resetValidationResultContacts,
  } = useValidationCollection<PartnerContactData>({
    objects: desk?.contacts ?? null,
    validateOnChange: validateOnChangeContacts,
    rules: partnerContactEditValidation,
  });

  const onChangeAttribute: OnChangeObjectAttribute<PartnerDesk> = (name, value) => {
    dispatch(partnerDeskEditSetDeskAttribute({ name, value }));
  };

  const onChangeContactAttribute: OnChangeObjectAttributeByIndex<PartnerContactData> = (index, name, value) => {
    dispatch(partnerDeskEditSetContactAttribute({ index, name, value }));
  };

  const onAddContact = () => {
    setValidateOnChangeContacts(false);
    resetValidationResultContacts();
    dispatch(partnerDeskEditAddContact());
  };

  const onRemoveContact = (index: number) => {
    setValidateOnChangeContacts(false);
    resetValidationResultContacts();
    dispatch(partnerDeskEditRemoveContact(index));
  };

  const onNextStep = () => {
    if (step === EPartnerDeskEditStep.Desk) {
      const isValidObject = validateDesk();
      if (!isValidObject) {
        setValidateOnChangeDesk(true);
        return;
      }
    }

    const index = partnerDeskEditSteps.findIndex(item => item.key === step);
    if (index < partnerDeskEditSteps.length - 1) {
      const nextStep = partnerDeskEditSteps[index + 1].key;
      history.push(getPartnerDeskEditRoute({ partnerId, step: nextStep }));

      if (step === EPartnerDeskEditStep.Desk) {
        setValidateOnChangeDesk(false);
      }
    }
  };

  const validate = (): boolean => {
    const isValidObject = validateDesk() && validateContacts();
    if (!isValidObject) {
      setValidateOnChangeDesk(true);
      setValidateOnChangeContacts(true);
      return false;
    }
    return true;
  };

  const onClose = () => {
    gotoPrevIndependentLocation(getPartnerDetailsRoute({ id: partnerId }));
  };

  const onSaveDesk = () => {
    if (!validate()) return;

    dispatch(partnerDeskEditSave({ partnerId, desk: desk! }));
  };

  const onCreateDesk = () => {
    if (!validate()) return;

    dispatch(partnerDeskEditSave({ partnerId, desk: desk! }));
  };

  const onPanelAction = (action: PanelAction<PartnerDeskEditActionType>) => {
    const { type } = action;
    switch (type) {
      case EPartnerDeskActionType.Save:
        onSaveDesk();
        break;
      case EPartnerDeskActionType.Create:
        onCreateDesk();
        break;
    }
  };

  useEffect(() => {
    if (isSaved) {
      Notifier.getInstance().addNotice(ENoticeStatus.Success, `Карточка компании сохранена`);
      onClose();
    }
  }, [isSaved]);

  useEffect(() => {
    dispatch(partnerDeskEditByIdFetch({ id: partnerId }));

    return () => {
      dispatch(partnerDeskEditStateReset());
    };
  }, [dispatch, partnerId]);

  const mpStep = partnerDeskEditSteps.find(item => item.key === step) ?? partnerDeskEditSteps[0];

  const actions: PanelActions<PartnerDeskEditActionType> = [
    {
      type: desk?.id ? EPartnerDeskActionType.Save : EPartnerDeskActionType.Create,
      label: desk?.id ? 'Сохранить и закрыть' : 'Создать',
      primary: true,
      position: [EPanelActionPosition.Default],
    },
  ];

  const actionsPanel = (
    <MasterActionsComponent<PartnerDeskEditActionType>
      show={!!actions.length}
      actions={actions}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );
  return (
    <Fade in>
      <ContainerWrapper>
        <StepperContainer<EPartnerDeskEditStep>
          step={mpStep}
          steps={partnerDeskEditSteps}
        />
        <ContentContainer>
          <DefaultContentWrapper
            type='details'
            stickyHeader
            fullHeight
            footer={actionsPanel}
          >
            {isFetched && desk && (
              <DefaultHeader
                sticky
                onClose={onClose}
              >
                <TitleWrapper>
                  <Typography variant='h2'>{desk.id ? 'Карточка компании' : 'Создание карточки компании'}</Typography>
                </TitleWrapper>
              </DefaultHeader>
            )}
            {desk && (
              <PartnerDeskEdit
                step={step}
                desk={desk}
                validationDesk={validationResultDesk}
                validationContacts={validationResultContacts}
                isValidDesk={isValidDesk}
                isValidContacts={isValidContacts}
                onChangeAttribute={onChangeAttribute}
                onChangeContactAttribute={onChangeContactAttribute}
                onNextStep={onNextStep}
                onAddContact={onAddContact}
                onRemoveContact={onRemoveContact}
              />
            )}
          </DefaultContentWrapper>
        </ContentContainer>
        {(isFetching || isSaving) && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
      </ContainerWrapper>
    </Fade>
  );
};

export default PartnerDeskEditContainer;
