import { Chip } from '@mui/material';
import React from 'react';
import { SportOptionTyped } from '../../../../../../domain/model';
import { Nullable } from '../../../../../../domain/model/types';
import { MPAutocompleteMultipleSelect } from '../../../../../theme/ui-kit/autocomplete/multiple';
import SportOptionMultipleItem from './item';
import {
  SportOptionMultipleItemType,
  SportOptionMultipleSelectType,
  SportOptionMultipleTypesSelectorItem,
} from './types';
import useSportOptionMultipleTypes from './useMultipleTypes';

interface SportOptionMultipleTypesSelectorProps<T extends string> {
  readonly select?: Nullable<SportOptionMultipleSelectType>;
  readonly selectNone?: boolean;
  readonly values: T[];
  readonly allValues: SportOptionTyped<T>[];
  readonly selectAllLabel: string;
  readonly label: string;
  readonly helperText?: string;
  readonly error?: boolean;
  readonly limitTags?: number;
  readonly disabled?: boolean;
  readonly isLoading?: boolean;
  readonly color?: 'default' | 'primary' | 'secondary' | undefined;
  readonly popupIcon?: React.ReactNode;
  readonly onSelectNone?: () => void;
  readonly onChange: (values: T[]) => void;
}

const SportOptionMultipleTypesSelector = <T extends string>(props: SportOptionMultipleTypesSelectorProps<T>) => {
  const {
    select,
    selectNone,
    values,
    allValues,
    label,
    selectAllLabel,
    helperText,
    error,
    limitTags = -1,
    disabled = false,
    color = 'default',
    popupIcon,
    isLoading: isInitialLoading,
    onChange,
    onSelectNone,
  } = props;

  const { options, value, setSearchValue, onChangeValue, getOptionLabel } = useSportOptionMultipleTypes<T>({
    select,
    selectNone,
    values,
    allValues,
    selectAllLabel,
    onChange,
    onSelectNone,
  });

  return (
    <MPAutocompleteMultipleSelect<SportOptionMultipleTypesSelectorItem<T>>
      value={value}
      label={label}
      helperText={helperText}
      error={error}
      options={options}
      limitTags={limitTags}
      isLoading={isInitialLoading}
      disabled={disabled}
      disableCloseOnSelect
      onSearchValue={setSearchValue}
      getOptionLabel={getOptionLabel}
      onChangeValue={onChangeValue}
      renderOption={(elementProps, option, state) => (
        <SportOptionMultipleItem
          key={option.id}
          elementProps={elementProps}
          option={option}
          state={state}
        />
      )}
      renderTags={(tagValue, getTagProps) => {
        const allValuesSelected = tagValue.find(option => option.type === SportOptionMultipleItemType.All);
        return tagValue
          .filter(option => !allValuesSelected || option.type === SportOptionMultipleItemType.All)
          .map((option, index) => (
            <Chip
              component={'div' as any}
              color={color}
              selected
              label={option.name}
              {...getTagProps({ index })}
              key={option.id}
            />
          ));
      }}
      popupIcon={popupIcon}
    />
  );
};

export default SportOptionMultipleTypesSelector;
