import { RootState } from '../../../../../../data/store/store';

export const corpOfferCategoriesEditDataSelector = (store: RootState) =>
  store.dictionary.corpOfferCategory.edit.fetch.data;

export const corpOfferCategoriesEditIsPreloadingSelector = (store: RootState) =>
  store.dictionary.corpOfferCategory.edit.fetch.isFetching;

export const corpOfferCategoriesEditIsLoadingSelector = (store: RootState) =>
  store.dictionary.corpOfferCategory.edit.fetch.isFetching;

export const corpOfferCategoriesEditAddDialogStateSelector = (store: RootState) =>
  store.dictionary.corpOfferCategory.edit.dialogs.add;

export const corpOfferCategoriesEditDeleteDialogStateSelector = (store: RootState) =>
  store.dictionary.corpOfferCategory.edit.dialogs.delete;

export const corpOfferCategoriesEditEditDialogStateSelector = (store: RootState) =>
  store.dictionary.corpOfferCategory.edit.dialogs.modify;

export const corpOfferCategoriesEditIsRelationShipsCheckingSelector = (store: RootState) =>
  store.dictionary.corpOfferCategory.edit.checkRelationShips.isFetching;
