import { RefObject, useEffect, useState } from 'react';

const useYScroll = (element: RefObject<HTMLDivElement>, initialScroll: number) => {
  const [position, setPosition] = useState(initialScroll);

  useEffect(() => {
    const current = element?.current;
    if (current) {
      const onScrollListener = () => setPosition(current.scrollTop);

      current.addEventListener('scroll', onScrollListener);
      current.scrollTo({
        behavior: 'auto',
        top: position,
      });

      return () => {
        current.removeEventListener('scroll', onScrollListener);
      };
    }
  }, [element?.current]);

  return position;
};

export default useYScroll;
