import { EDateTimeFormat } from '@/domain/model/formats';
import moment from 'moment';
import CorpActivationTableCell, { CorpActivationTableCellCommonProps } from './index';

const CorpActivationTableCellCreateAt = (props: CorpActivationTableCellCommonProps) => {
  const {
    corpActivation: { createdAt },
  } = props;

  return (
    <CorpActivationTableCell
      {...props}
      value={moment(createdAt).format(EDateTimeFormat.DisplayDefault)}
    />
  );
};

export default CorpActivationTableCellCreateAt;
