import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataFilterComponent from '../../../../components/common/dataFilter';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { EPartnerEmployeesFilterItem } from '../filterUtils';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  partnerEmployeesColumnSelector,
  partnerEmployeesFilterSelector,
  partnerEmployeesGuidSelector,
} from '../store/selectors';
import { EPartnerEmployeeTableColumn } from '../utils';

interface PartnerEmployeeTableFilterAdapterProps {
  readonly filterAdapter: DataFilterAdapter<EPartnerEmployeeTableColumn, EPartnerEmployeesFilterItem>;
}

const PartnerEmployeeTableFilterAdapter = (props: PartnerEmployeeTableFilterAdapterProps) => {
  const { filterAdapter } = props;

  const handlers = useContextHandlers();

  const guid = useSelector(partnerEmployeesGuidSelector);
  const sortColumn = useSelector(partnerEmployeesColumnSelector);
  const filter = useSelector(partnerEmployeesFilterSelector);

  const { filterStrategies } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return useMemo(
    () => (
      <DataFilterComponent
        strategies={filterStrategies}
        onChange={handlers.onChangeFilter}
      />
    ),
    [filterStrategies, handlers.onChangeFilter]
  );
};

export default PartnerEmployeeTableFilterAdapter;
