import { combineReducers } from '@reduxjs/toolkit';
import list, { LockObjectListState } from './store/slice';

interface LockObjectState {
  list: LockObjectListState;
}

export default combineReducers<LockObjectState>({
  list,
});
