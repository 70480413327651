import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ESortDirection } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { SportUserProfile } from '../../../../../domain/model/user';
import { NumberRange, PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import { SportsmansTableFilterEditStrategy } from '../../filterUtils';
import {
  sportsmanSearchAllSelect,
  sportsmanSearchAllUnselect,
  sportsmanSearchDataReset,
  sportsmanSearchSelect,
  sportsmanSearchSetFilter,
  sportsmanSearchSetPage,
  sportsmanSearchSetPageSize,
  sportsmanSearchSetSort,
  sportsmanSearchSortReset,
  sportsmanSearchUnselect,
} from '../store/slice';

interface UsesportsmansSearchSingleProps {
  readonly initialSelected: Nullable<SportUserProfile>;
  readonly selectedMaxCount: 1;
  readonly onSelect: (sportUserProfile: Nullable<SportUserProfile>) => void;
}

interface UseSportsmansSearchMultipleProps {
  readonly initialSelected: Nullable<SportUserProfile[]>;
  readonly selectedMaxCount?: NumberRange<2, 100> | false;
  readonly onSelect: (sportUserProfiles: SportUserProfile[]) => void;
}

export type UseSportsmansSearch = {
  readonly onResetState: () => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
  readonly onChangeFilter: (strategies: SportsmansTableFilterEditStrategy[]) => void;
  readonly onResetSort: () => void;
  readonly onChangePage: (page: number) => void;
  readonly onChangePageSize: (pageSize: PaginationSize) => void;
  readonly onSportsmanSelect: (sportsmans: SportUserProfile, selectedCurrentCount: number, selected: boolean) => void;
  readonly onReturn: (sportsmen: SportUserProfile[]) => void;
  readonly onAllSportsmenSelect: (selected: boolean) => void;
};

type UseSportsmansSearchProps = UsesportsmansSearchSingleProps | UseSportsmansSearchMultipleProps;

export function useSportsmansSearchHandlers(props: UseSportsmansSearchProps): UseSportsmansSearch {
  const { selectedMaxCount } = props;
  const dispatch = useDispatch();

  const onChangeFilter = useCallback<UseSportsmansSearch['onChangeFilter']>(
    strategies => {
      dispatch(sportsmanSearchSetFilter(getDataFilterValuesByStrategies(strategies)));
    },
    [dispatch]
  );

  const onChangeSort = useCallback<UseSportsmansSearch['onChangeSort']>(
    (name, direction) => {
      dispatch(
        sportsmanSearchSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onResetSort = useCallback<UseSportsmansSearch['onResetSort']>(
    () => dispatch(sportsmanSearchSortReset()),
    [dispatch]
  );

  const onResetState = useCallback<UseSportsmansSearch['onResetState']>(
    () => dispatch(sportsmanSearchDataReset()),
    [dispatch]
  );

  const onAllSportsmenSelect = useCallback<UseSportsmansSearch['onAllSportsmenSelect']>(
    (selected: boolean) => {
      if (selected) {
        dispatch(sportsmanSearchAllSelect(selectedMaxCount || null));
      } else {
        dispatch(sportsmanSearchAllUnselect());
      }
    },
    [selectedMaxCount]
  );

  const onSportsmanSelect = useCallback<UseSportsmansSearch['onSportsmanSelect']>(
    (sportsmans, selectedCurrentCount, selected) => {
      if (!selectedMaxCount || selectedMaxCount > 1) {
        if (selected && (!selectedMaxCount || selectedCurrentCount < selectedMaxCount)) {
          dispatch(sportsmanSearchSelect(sportsmans));
        } else {
          dispatch(sportsmanSearchUnselect(sportsmans));
        }
      } else {
        if (selected) {
          dispatch(sportsmanSearchAllUnselect());
          dispatch(sportsmanSearchSelect(sportsmans));
        } else {
          dispatch(sportsmanSearchUnselect(sportsmans));
        }
      }
    },
    [selectedMaxCount]
  );

  const onReturn = useCallback<UseSportsmansSearch['onReturn']>(
    sportsmen => {
      switch (props.selectedMaxCount) {
        case 1:
          props.onSelect(sportsmen?.[0] ?? null);
          break;
        default:
          props.onSelect(sportsmen);
          break;
      }
    },
    [props.selectedMaxCount]
  );

  const onChangePage = useCallback<UseSportsmansSearch['onChangePage']>(
    page => {
      dispatch(sportsmanSearchSetPage({ pageNumber: page }));
    },
    [dispatch]
  );

  const onChangePageSize = useCallback<UseSportsmansSearch['onChangePageSize']>(
    pageSize => {
      dispatch(sportsmanSearchSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  return {
    onChangeSort,
    onResetSort,
    onSportsmanSelect,
    onReturn,
    onResetState,
    onChangePage,
    onChangePageSize,
    onChangeFilter,
    onAllSportsmenSelect,
  };
}
