import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AdCampaignCreateStep } from '../../types';
import { adCampaignCreateSetUiState } from '../store/slice';
import { AdCampaignCreateUiState } from '../types';
import { getAdCampaignCreateSteps, getAdCampaignCreateStepText } from '../utils';

export const AdCampaignCreateUiStepsState = () => {
  const dispatch = useDispatch();

  const value = useMemo<AdCampaignCreateUiState['steps']>(() => {
    const steps: AdCampaignCreateStep[] = getAdCampaignCreateSteps().map(key => ({
      key,
      label: getAdCampaignCreateStepText(key),
    }));

    return steps;
  }, []);

  useEffect(() => {
    dispatch(adCampaignCreateSetUiState({ name: 'steps', value }));
  }, [dispatch, value]);

  return null;
};
