import { Grid } from '@mui/material';
import { MPButton } from '@ui-kit/button';
import { ReactNode } from 'react';
import ConfirmDialog from '../confirm';

interface DiscardCreateWhenExitDialogProps {
  readonly open: boolean;
  readonly title?: ReactNode;
  readonly description?: ReactNode;
  readonly onCancel: () => void;
  readonly onDiscard: () => void;
}

const DiscardCreateWhenExitDialog = (props: DiscardCreateWhenExitDialogProps) => {
  const {
    open,
    title = 'Удалить введенные данные?',
    description = 'Все введенные данные будут потеряны',
    onCancel,
    onDiscard,
  } = props;

  return (
    <ConfirmDialog
      open={open}
      title={title}
      text={description}
      onClose={onCancel}
      dialogButtons={
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <MPButton onClick={onDiscard}>Удалить</MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              onClick={onCancel}
            >
              Отмена
            </MPButton>
          </Grid>
        </Grid>
      }
    />
  );
};

export default DiscardCreateWhenExitDialog;
