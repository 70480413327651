import { RootState } from '@/data/store/store';
import { Nullable, UUID } from '@/domain/model/types';
import { createSelector } from '@reduxjs/toolkit';
import { EClientOrgActionType } from '../../types';

export const clientOrgActionsDialogUnBlockSelector = (store: RootState) => store.clientOrg.actions.dialogs.unBlock;
export const clientOrgActionsDialogBlockSelector = (store: RootState) => store.clientOrg.actions.dialogs.block;

export const clientOrgActionsIsCreatedSelector = (store: RootState) => store.clientOrg.actions.create.isFetched;

export const getClientOrgActionsOfferIsProcessingSelector = (id: Nullable<UUID>) =>
  createSelector(
    (store: RootState) => store.clientOrg.actions.actions,
    (store: RootState) => store.clientOrg.actions.create.isFetching,
    (actions, isCreating) => {
      return id ? actions.some(action => action.id === id && action.isFetching) : isCreating;
    }
  );

export const isClientOrgsAnyChangedSelector = () =>
  createSelector(
    (store: RootState) => store.clientOrg.actions.actions.filter(action => !action.isFetching).length,
    (store: RootState) => (!store.clientOrg.actions.create.isFetching ? 1 : 0),
    (actions, isCreating) => {
      return actions + isCreating;
    }
  );

export const getIsClientOrgAnyActionByTypeExecutedSelector = (id: Nullable<UUID>, ...actions: EClientOrgActionType[]) =>
  createSelector(
    () => id,
    () => actions,
    (store: RootState) => store.clientOrg.actions.actions,
    (id, argActions, storeActions) => {
      return id
        ? storeActions.find(action => action.id === id && action.isFetched && argActions.some(a => a === action.type))
            ?.type ?? null
        : null;
    }
  );
