import { Nullable, UUID } from '@/domain';
import { ECustomerTableTab } from '@features/customer/table/utils';
import useCustomerTableSearch from '../hooks/useSearch';
import useCustomerTableSession from '../hooks/useSession';

interface CustomerTableBehaviorAdapterProps {
  readonly guid: UUID;
  readonly tab: ECustomerTableTab;
  readonly partnerId: Nullable<UUID>;
}

export const CustomerTableBehaviorAdapter = ({ guid, tab, partnerId }: CustomerTableBehaviorAdapterProps) => {
  useCustomerTableSession({ tab, guid });
  useCustomerTableSearch({ tab, partnerId });

  return null;
};
