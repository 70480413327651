import { useState } from 'react';
import { BookingServicePriceUnit } from '../../../../../../domain/model/booking';
import { EBookingServicePriceUnitStatus, EServiceOrderByDateType } from '../../../../../../domain/model/enums';
import { Nullable } from '../../../../../../domain/model/types';
import { DictionaryAddDialog } from '../../../../../components/common/dictionary/dialog/add';
import { MPFormInput } from '../../../../../theme/ui-kit/input';

interface BookingServicePriceUnitsEditDialogAddAdapterProps {
  readonly isLoading: boolean;
  readonly parent: Nullable<BookingServicePriceUnit>;
  readonly onClose: () => void;
  readonly onAdd: (bookingServicePriceUnit: BookingServicePriceUnit) => void;
}

export const BookingServicePriceUnitsEditDialogAddAdapter = (
  props: BookingServicePriceUnitsEditDialogAddAdapterProps
) => {
  const { isLoading, parent, onAdd, onClose } = props;

  const [error, setError] = useState<Nullable<string>>(null);

  const [value, setValue] = useState<BookingServicePriceUnit>({
    id: '',
    name: '',
    orderByDateType: (parent?.id as EServiceOrderByDateType) ?? EServiceOrderByDateType.None,
    status: EBookingServicePriceUnitStatus.Enabled,
  });

  const onAddInternal = () => {
    if (!value.name) {
      setError('Введите название');
    } else {
      onAdd(value ?? '');
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setError(null);
    setValue(prev => ({ ...prev, name: event.target.value }));
  };

  return (
    <DictionaryAddDialog
      isLoading={isLoading}
      onClose={onClose}
      onSave={onAddInternal}
    >
      <MPFormInput
        disabled={isLoading}
        label='Название'
        value={value.name}
        error={error !== null}
        helperText={error}
        inputRef={input => input && input.focus()}
        onChange={onChange}
      />
    </DictionaryAddDialog>
  );
};
