import { AdCampaignShort, ESortDirection, Nullable } from '@/domain';
import { addSearchParamToLocation } from '@/routing/globalRouting';
import { EAdCampaignTableTab } from '@features/adCampaign/types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../data/store/store';
import { PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import { useAdCampaignActions } from '../../actions/useActions';
import { EAdCampaignUrlParam, getAdCampaignCreateRoute } from '../../entry';
import { AdCampaignTableFilterEditStrategy } from '../../filterUtils';
import { adCampaignGuidSelector } from '../store/selectors';
import {
  adCampaignDataReset,
  adCampaignNeedRefreshWatcherIncrement,
  adCampaignSetFilter,
  adCampaignSetPage,
  adCampaignSetPageSize,
  adCampaignSetSort,
  adCampaignSortReset,
} from '../store/slice';

export type UseAdCampaignTable = {
  readonly onCreate: () => void;
  readonly onRefresh: () => void;
  readonly onResetSort: () => void;
  readonly onChangePage: (page: number) => void;
  readonly onChangeTab: (newTab: EAdCampaignTableTab) => void;
  readonly onChangePageSize: (pageSize: PaginationSize) => void;
  readonly onChangeFilter: (strategies: AdCampaignTableFilterEditStrategy[]) => void;

  readonly onAdCampaignTryResume: (adCampaign: AdCampaignShort) => void;
  readonly onAdCampaignTryArchive: (adCampaign: AdCampaignShort) => void;
  readonly onAdCampaignTryPause: (adCampaign: AdCampaignShort) => void;

  readonly onAdCampaignChangePageSize: (pageSize: PaginationSize) => void;
  readonly onAdCampaignChangePage: (page: number) => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
};

type UseAdCampaignTableHandlersProps = {
  readonly tab: EAdCampaignTableTab;
};

export const useAdCampaignTableHandlers = ({ tab }: UseAdCampaignTableHandlersProps): UseAdCampaignTable => {
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const { onTryArchive, onTryPause, onTryResume } = useAdCampaignActions();

  const getAdCampaignGuidFromState = useCallback((): Nullable<UUID> => {
    const state = store.getState();
    return adCampaignGuidSelector(state);
  }, [store]);

  const onRefresh = useCallback(() => dispatch(adCampaignNeedRefreshWatcherIncrement()), [dispatch]);

  const onChangeFilter = useCallback(
    (strategies: AdCampaignTableFilterEditStrategy[]) => {
      dispatch(adCampaignSetFilter(getDataFilterValuesByStrategies(strategies)));
    },
    [dispatch]
  );

  const onAdCampaignTryArchive = useCallback<UseAdCampaignTable['onAdCampaignTryArchive']>(onTryArchive, [
    onTryArchive,
  ]);

  const onAdCampaignTryResume = useCallback<UseAdCampaignTable['onAdCampaignTryResume']>(onTryResume, [onTryResume]);

  const onAdCampaignTryPause = useCallback<UseAdCampaignTable['onAdCampaignTryPause']>(onTryPause, [onTryPause]);

  const onAdCampaignChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(adCampaignSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onAdCampaignChangePage = useCallback(
    (page: number) => {
      dispatch(adCampaignSetPage({ pageNumber: page }));
    },
    [dispatch]
  );

  const onResetSort = useCallback(() => dispatch(adCampaignSortReset()), [dispatch]);

  const onChangePage = useCallback(
    (page: number) => {
      dispatch(adCampaignSetPage({ pageNumber: page }));
    },
    [dispatch]
  );

  const onChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(adCampaignSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onChangeTab = useCallback(
    (newTab: EAdCampaignTableTab) => {
      if (newTab !== tab) {
        const guid = getAdCampaignGuidFromState();
        dispatch(adCampaignDataReset());
        history.replace(
          addSearchParamToLocation({
            location,
            param: EAdCampaignUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [dispatch, history, location, tab, getAdCampaignGuidFromState]
  );

  const onChangeSort = useCallback(
    (name: string, direction: ESortDirection) => {
      dispatch(
        adCampaignSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onCreate = useCallback(() => history.push(getAdCampaignCreateRoute({ location })), [history, location]);

  return {
    onCreate,
    onResetSort,
    onChangeSort,
    onChangeFilter,
    onChangePage,
    onChangePageSize,
    onChangeTab,

    onAdCampaignTryArchive,
    onAdCampaignTryPause,
    onAdCampaignTryResume,
    onAdCampaignChangePageSize,
    onAdCampaignChangePage,
    onRefresh,
  };
};
