import { EOrderPartition } from '../../../../domain/model/enums';
import { BookingOrder } from '../../../../domain/model/order';
import { UUID } from '../../../../domain/model/types';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import { BookingOrderLifeCycleFactory } from '../lifecycle';
import { EBookingOrderTableTab } from '../types';
import BookingOrderTableContainer from './container';
import { BookingOrderTableConfigContext } from './context';
import { useBookingOrderTableConfig } from './hooks/useTableConfig';

interface BookingOrderTableEntryProps {
  readonly guid: UUID;
  readonly tab: EBookingOrderTableTab;
  readonly partnerId?: Nullable<UUID>;
  readonly customerId?: Nullable<UUID>;
  readonly onOrderClick: (order: BookingOrder) => void;
}

const BookingOrderTableEntry = (props: BookingOrderTableEntryProps) => {
  const { guid, tab, partnerId = null, customerId = null, onOrderClick } = props;
  const { accessMatrix, userSpecific } = useCurrentUser();
  const { isPartnerUser, orders } = accessMatrix;

  const config = useBookingOrderTableConfig({ partnerId, customerId });

  const { defaultTab } = config;
  const outTab = tab ?? defaultTab;

  const lifecycle = BookingOrderLifeCycleFactory().create({
    isPartnerUser,
    userId: userSpecific.id,
  });

  return (
    <BookingOrderTableConfigContext.Provider value={config}>
      <BookingOrderTableContainer
        guid={guid}
        tab={outTab}
        lifecycle={lifecycle}
        partnerId={partnerId}
        onOrderClick={onOrderClick}
        customerId={customerId}
        access={orders[EOrderPartition.BookingOffers]}
      />
    </BookingOrderTableConfigContext.Provider>
  );
};

export default BookingOrderTableEntry;
