import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { KeyboardEvent, useEffect, useState } from 'react';
import ContentLoader from '../loader';
import { Input, Wrapper } from './controls';
import { filterValue } from './utils';

export type CountInputProps = {
  readonly stayOpened?: boolean;
  readonly isEmpty?: boolean;
  readonly isWarn?: boolean;
  readonly disabled?: boolean;
  readonly loading?: boolean;
  readonly value?: number;
  readonly min?: number;
  readonly max?: number;
  readonly onDecrement?: (value: number) => void;
  readonly onIncrement?: (value: number) => void;
  readonly onChangeCount?: (value: number) => void;
  readonly onBlur?: (value: number) => void;
  readonly onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
};

const CountInput = (props: CountInputProps) => {
  const {
    isEmpty = false,
    stayOpened = false,
    value,
    disabled = false,
    loading = false,
    min = Number.MIN_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER,
    onBlur,
    onKeyPress,
    onChangeCount,
    onDecrement,
    onIncrement,
  } = props;

  const [localValue, setLocalValue] = useState<number>(value || 0);

  useEffect(() => {
    setLocalValue(value || 0);
  }, [value]);

  const handleChangeValue = (newValue: string) => {
    const numValue = filterValue(newValue);
    onChangeCount?.(numValue);
    setLocalValue(numValue);
  };

  const handleDecrementValue = () => {
    const numValue = filterValue(localValue - 1, { min, max });
    onDecrement?.(numValue);
    setLocalValue(numValue);
  };

  const handleIncrementValue = () => {
    const numValue = filterValue(localValue + 1, { min, max });

    if (numValue !== value) {
      onIncrement?.(numValue);
      setLocalValue(numValue);
    }
  };

  const handleOnBlur = () => {
    const numValue = filterValue(localValue, { min, max });

    if (numValue !== value) {
      onChangeCount?.(numValue);
      setLocalValue(numValue);
    }

    onBlur?.(numValue);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    onKeyPress?.(e);
  };

  return (
    <>
      {(!isEmpty || stayOpened) && (
        <Wrapper>
          <Input
            variant='filled'
            disabled={disabled}
            value={localValue}
            onChange={e => handleChangeValue(e.target.value)}
            onBlur={handleOnBlur}
            onKeyUp={handleKeyPress}
            InputProps={{
              startAdornment: (
                <IconButton
                  color='primary'
                  disabled={disabled || localValue <= min}
                  onClick={handleDecrementValue}
                >
                  <RemoveIcon />
                </IconButton>
              ),
              endAdornment: (
                <IconButton
                  color='primary'
                  disabled={disabled || localValue >= max}
                  onClick={handleIncrementValue}
                >
                  <AddIcon />
                </IconButton>
              ),
            }}
          />
          {loading && <ContentLoader size='1.5rem' />}
        </Wrapper>
      )}
    </>
  );
};

export default CountInput;
