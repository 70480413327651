import styled from '@emotion/styled';
import { css, Theme } from '@mui/material';
import { isArray } from 'lodash';
import DatePicker, { Calendar, CalendarProps, HeaderItem } from 'react-multi-date-picker';

const dayCellSize = '30px';

const getIndexYear = (headerItems: HeaderItem[] | undefined) => {
  if (headerItems) {
    const index = headerItems.findIndex(el => el === 'MONTH_YEAR' || el === 'YEAR_MONTH');

    if (headerItems[index] === 'MONTH_YEAR') {
      return 3;
    }

    if (headerItems[index] === 'YEAR_MONTH') {
      return 1;
    }
  }
  return 0;
};

const CommonStyles = (
  theme: Theme,
  value: any,
  headerOrder: HeaderItem[] | undefined,
  isSelecting = false
) => css`
  /* wrapper */

  &.rmdp-wrapper,
  .rmdp-calendar,
  .rmdp-day-picker,
  .rmdp-day-picker > div {
    width: 100%;
    overflow: auto;
  }

  &.rmdp-wrapper {
    border: none;
  }

  /* header */

  & .rmdp-header {
    padding: 3rem 0 0 0;
    margin: 0;
    margin-bottom: ${theme.spacing(2)};

    .MuiSvgIcon-root {
      cursor: pointer;
    }

    .MuiSvgIcon-root:first-of-type {
      margin-right: 11px;
    }

    .MuiSvgIcon-root:last-of-type {
      margin-left: 11px;
    }

    .rmdp-header-values {
      display: flex;
      justify-content: center;
      flex: 1;

      text-align: left;
      font-size: ${theme.typography.body1.fontSize};
      font-weight: ${theme.typography.body1.fontWeight};
      line-height: ${theme.typography.body1.lineHeight};

      // скрываем сепаратор, иначе не получается

      & span:nth-child(2) {
        display: none;
      }

      // Вытаскиваем год из базового заголовка

      & span:nth-child(${getIndexYear(headerOrder)}) {
        position: absolute;
        display: block;
        top: -3rem;
        left: 0;

        font-size: ${theme.typography.subtitle1.fontSize};
        font-weight: ${theme.typography.subtitle1.fontWeight};
        line-height: ${theme.typography.subtitle1.lineHeight};
        color: ${theme.palette.text.secondary};
      }
    }
  }

  /* week */

  & .rmdp-week {
    position: relative;
    overflow: hidden;

    :not(:first-of-type) {
      margin-top: ${theme.spacing(2)};
    }
  }

  & .rmdp-month-picker,
  & .rmdp-year-picker {
    z-index: 2;
  }

  & .rmdp-week-day {
    width: ${dayCellSize};
    height: auto;

    font-size: ${theme.typography.overline.fontSize};
    font-weight: ${theme.typography.overline.fontWeight};
    line-height: ${theme.typography.overline.lineHeight};

    color: ${theme.palette.secondary.main};
    text-transform: uppercase;
  }

  /* day */

  & .rmdp-day {
    width: ${dayCellSize};
    height: ${dayCellSize};

    span {
      font-size: ${theme.typography.body1.fontSize};
      font-weight: ${theme.typography.body1.fontWeight};
      line-height: ${theme.typography.body1.lineHeight};

      transition: background-color 300ms, color 100ms ease-in;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &.rmdp-selected span:not(.highlight) {
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: none;
    }

    &:not(.rmdp-selected, .rmdp-disabled, .rmdp-day-hidden) span:hover {
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
    }

    &.rmdp-today span {
      background-color: transparent;
      color: ${theme.palette.primary.main};
    }

    &.rmdp-deactive,
    &.rmdp-disabled {
      color: ${theme.palette.secondary.main};
    }
  }

  /* range */

  & .rmdp-range {
    position: relative;
    box-shadow: none;
    background-color: ${theme.palette.primary.main};

    span {
      position: relative;
      height: ${dayCellSize};
      z-index: 1;
    }

    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      left: -60%;
      width: 100%;
      background: ${theme.palette.primary.main};
      z-index: 0;
    }

    &.rmdp-today span {
      color: ${theme.palette.primary.contrastText};
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      right: -60%;
      width: 100%;
      background: ${theme.palette.primary.main};
      z-index: 0;
    }

    ${isArray(value) &&
    value.length === 1 &&
    css`
      box-shadow: none !important;

      &::after,
      &::before {
        display: none;
      }
    `}
    ${isSelecting &&
    css`
      overflow: hidden;
    `}
    &.start {
      ::before {
        display: none;
      }
    }

    &.end {
      ::after {
        display: none;
      }
    }
  }
`;

type StyledCalendarProps = CalendarProps<any, any> & {
  readonly isSelecting?: boolean;
};

export const StyledCalendar = styled(({ isSelecting = false, ...props }: StyledCalendarProps) => (
  <Calendar {...props} />
))`
  ${p => CommonStyles(p.theme, p.value, p.headerOrder, p.isSelecting)}
`;

export const StyledPicker = styled(DatePicker)`
  ${p => CommonStyles(p.theme, p.value, p.headerOrder)}
  &.rmdp-wrapper {
    width: 280px;

    padding: ${p => p.theme.spacing(0.8)};
  }
`;

/** пробы для подсверки при hover
 * из коробки выглядит плохо, потому что календарь дефольно не растягивается больше своего контента,
 * и при hover дырки между датами
 * пока не нужно, поэтому не используем
 */

/*
.rmdp-range {
  + .rmdp-range-hover,
    + .rmdp-range-hover + .rmdp-range-hover,
    + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover,
    + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover,
    + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover,
    + .rmdp-range-hover
  + .rmdp-range-hover
  + .rmdp-range-hover
  + .rmdp-range-hover
  + .rmdp-range-hover
  + .rmdp-range-hover {
    box-shadow: -26px 0px 0px ${p => p.theme.palette.brand.A500},
      -${rangeSelectShadowSize} 0px 0px ${p => p.theme.palette.brand.A500};
  }
}

.rmdp-range-hover {
  background-color: ${p => p.theme.palette.brand.A500};
  box-shadow: -${rangeSelectShadowSize} 0px 0px ${p => p.theme.palette.brand.A500},
    ${rangeSelectShadowSize} 0px 0px ${p => p.theme.palette.brand.A500};

  &.start {
    box-shadow: ${rangeSelectShadowSize} 0px 0px ${p => p.theme.palette.brand.A500};
  }

  &.end:first-of-type:not(:nth-of-type(1)) {
    box-shadow: -${rangeSelectShadowSize} 0px 0px ${p => p.theme.palette.brand.A500};
  }

  &.end:not(:first-of-type) {
    box-shadow: -26px 0px 0px ${p => p.theme.palette.brand.A500},
      -${rangeSelectShadowSize} 0px 0px ${p => p.theme.palette.brand.A500};
  }

  + .rmdp-range-hover,
  + .rmdp-range-hover + .rmdp-range-hover,
  + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover,
  + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover,
  + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover + .rmdp-range-hover,
  + .rmdp-range-hover
    + .rmdp-range-hover
    + .rmdp-range-hover
    + .rmdp-range-hover
    + .rmdp-range-hover
    + .rmdp-range-hover {
    box-shadow: -26px 0px 0px ${p => p.theme.palette.brand.A500},
      -${rangeSelectShadowSize} 0px 0px ${p => p.theme.palette.brand.A500};
  }

  .rmdp-range-hover:not(.rmdp-range) + .rmdp-range {
    box-shadow: -${rangeSelectShadowSize} 0px 0px ${p => p.theme.palette.brand.A500};
  }
}*/
