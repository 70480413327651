import { Typography } from '@mui/material';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../components/common/header';

const SportsmanTableHeaderAdapter = () => {
  return (
    <DefaultHeader headline={<AppBreadcrumbs />}>
      <Typography variant='h2'>Пользователи</Typography>
    </DefaultHeader>
  );
};

export default SportsmanTableHeaderAdapter;
