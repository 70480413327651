import { Grid, IconButton } from '@mui/material';
import styled from '@emotion/styled';
import { viewConfig } from '../../../theme/viewConfig';

export const Wrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  position: relative;
  display: flex;
  flex-direction: column;
`;

export const BackIconButton = styled(IconButton)`
  margin-left: -${p => p.theme.spacing(2)};
  margin-right: ${p => p.theme.spacing(1)};
`;

export const TitleWrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  position: fixed;
  z-index: 3;

  display: flex;
  align-items: center;

  padding: ${p => p.theme.spacing(1.5)} ${p => p.theme.spacing(1.5)} ${p => p.theme.spacing(1.5)}
    ${p => p.theme.spacing(3.75)};

  background: ${p => p.theme.palette.background.default};
  border-bottom: 1px solid ${p => p.theme.palette.secondary.main};
`;

export const Title = styled(Grid)`
  font-size: ${p => p.theme.typography.h3.fontSize};
  font-weight: ${p => p.theme.typography.h3.fontWeight};
  flex: 1;
`;

export const ContentWrapper = styled.div`
  flex: 1;
`;
