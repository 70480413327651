import { DataFilterStrategyAddressSingle } from '../../../../../../domain/model/filter';
import { Nullable } from '../../../../../../domain/model/types';
import { AddressSingleSelector } from '../../../../../features/address';
import { DataFilterEditItemProps } from '../index';

type ValueType<T extends string> = DataFilterStrategyAddressSingle<T>['value'];

interface DataFilterEditItemAddressSingleProps<T extends string>
  extends DataFilterEditItemProps<T, Nullable<ValueType<T>>> {
  readonly strategy: DataFilterStrategyAddressSingle<T>;
}

function DataFilterEditItemAddressSingle<T extends string>({
  strategy,
  onChange,
}: DataFilterEditItemAddressSingleProps<T>) {
  const { label, value, readOnly } = strategy;

  return (
    <AddressSingleSelector
      label={label}
      disabled={readOnly}
      value={value}
      onChange={data => onChange(strategy, data)}
    />
  );
}

export default DataFilterEditItemAddressSingle;
