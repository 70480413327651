import { combineReducers } from '@reduxjs/toolkit';
import edit, { BookingServiceCategoriesEditState } from './edit/store/slice';

interface BookingServiceCategoriesState {
  edit: BookingServiceCategoriesEditState;
}

export default combineReducers<BookingServiceCategoriesState>({
  edit,
});
