import { Dialog } from '@mui/material';
import styled from '@emotion/styled';

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  gap: ${p => p.theme.spacing(2)};
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    padding: ${p => p.theme.spacing(1.2)} 0;
    border-bottom: 1px solid ${p => p.theme.palette.secondary.main};
  }
`;

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    border: 1px solid ${p => p.theme.palette.secondary.main};
    border-radius: ${p => (p.theme.shape.borderRadius as number) * 5}px;
  }

  .MuiDialogContent-root {
    padding: ${p => p.theme.spacing(2)};
  }
`;
