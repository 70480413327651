import moment from 'moment';
import { EDateTimeFormat } from '../../../../domain/model/formats';
import { DataTableColumns } from '../../../components/common/table';

export const normalizeDate = (date: string) => {
  const currentYear = new Date().getFullYear();
  const dateYear = new Date(date).getFullYear();
  return moment(date).format(currentYear === dateYear ? EDateTimeFormat.HumanShort : EDateTimeFormat.Human);
};

export enum EChatTableColumn {
  Logo = 'logo',
  Name = 'name',
  SendDateTime = 'lastMessage.sendDateTime',
  BodyText = 'lastMessage.bodyText',
  // ChatTypes = 'types',
}

export const chatTableColumns: DataTableColumns<EChatTableColumn> = {
  [EChatTableColumn.Logo]: {
    width: '2rem',
  },
  [EChatTableColumn.Name]: {
    title: 'Чаты',
    width: '25rem',
    wrap: true,
    sortable: false,
  },
  [EChatTableColumn.SendDateTime]: {
    title: 'Дата и время',
    width: '10rem',
    sortable: false,
    wrap: true,
  },
  [EChatTableColumn.BodyText]: {
    title: 'Сообщение',
    wrap: true,
    sortable: false,
  },
  // [EChatTableColumn.ChatTypes]: {
  //   title: 'Тип чата',
  //   sortable: false,
  //   hidden: true,
  //   wrap: true,
  //   width: '10rem',
  // },
};

export const getChatTableColumns = (): DataTableColumns<EChatTableColumn> => {
  return chatTableColumns;
};

interface GetChatTableAdapterKeyResult {
  key: string;
}

export const getChatTableAdapterKey = (): GetChatTableAdapterKeyResult => {
  return {
    key: 'chat',
  };
};
