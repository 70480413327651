import styled from '@emotion/styled';
import { forwardRef } from 'react';
import { css, Link as MuiLink, Typography, TypographyProps } from '@mui/material';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(1.25)};
`;

export const Link = styled(MuiLink)`
  word-break: break-all;
`;

type ContentProps = TypographyProps & {
  readonly rows: number;
};

export const Content = styled(
  forwardRef(({ ...props }: ContentProps, ref: any) => (
    <Typography
      ref={ref}
      {...props}
    />
  ))
)(
  ({ rows }) => css`
    display: block;
    -webkit-line-clamp: ${rows};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  `
);

export const DialogContentWrapper = styled.div`
  word-break: break-word;
`;
