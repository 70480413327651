/* вызов промисов по порядку с передачей параметров друг от друга (как в reduce) */
export const executePromisesSequentially = async <T>(props: T, tasks: ((props: T) => Promise<T>)[]) => {
  let result = props;
  if (tasks && tasks.length > 0) {
    result =
      (await tasks
        .shift()?.(props)
        ?.then(output => {
          return executePromisesSequentially(output, tasks).then(result => Promise.resolve(result));
        })) ?? props;
  }
  return Promise.resolve<T>(result);
};
