import DataFilterComponent from '@/presentation/components/common/dataFilter';
import useDataTableFilterAdapter, { DataFilterAdapter } from '@/presentation/hooks/useDataFilterAdapter';
import { ECorpActivationTableColumn } from '@features/corpActivation/types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ECorpActivationsFilterItem } from '../../utils/filter';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  corpActivationsFilterSelector,
  corpActivationsGuidSelector,
  corpActivationsSortColumnSelector,
} from '../store/selectors';

interface CorpActivationTableFilterAdapterProps {
  readonly filterAdapter: DataFilterAdapter<ECorpActivationTableColumn, ECorpActivationsFilterItem>;
}

const CorpActivationTableFilterAdapter = (props: CorpActivationTableFilterAdapterProps) => {
  const { filterAdapter } = props;

  const handlers = useContextHandlers();

  const guid = useSelector(corpActivationsGuidSelector);
  const sortColumn = useSelector(corpActivationsSortColumnSelector);
  const filter = useSelector(corpActivationsFilterSelector);

  const { filterStrategies } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return useMemo(
    () => (
      <DataFilterComponent
        strategies={filterStrategies}
        onChange={handlers.onChangeFilter}
      />
    ),
    [filterStrategies, handlers.onChangeFilter]
  );
};

export default CorpActivationTableFilterAdapter;
