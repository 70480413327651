import CorpActivationTableCell, { CorpActivationTableCellCommonProps } from './index';

const CorpActivationTableCellCustomerPernr = (props: CorpActivationTableCellCommonProps) => {
  const {
    corpActivation: {
      customer: { pernr },
    },
  } = props;

  return (
    <CorpActivationTableCell
      {...props}
      value={pernr ?? ''}
    />
  );
};

export default CorpActivationTableCellCustomerPernr;
