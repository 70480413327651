import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CmsContainer, CmsSitePage } from '../../../../../domain/model/cms';
import { UUID } from '../../../../../domain/model/types';
import ErrorComponent from '../../../../components/common/error';
import ContentLoader from '../../../../components/common/loader';
import { ContainerWrapper } from './controls';
import { cmsContainerByIdSelector } from './store/selectors';
import { cmsContainerByIdFetch, cmsContainerByIdStateReset } from './store/slice';

interface CmsContainerByIdContainerResult {
  readonly cmsSitePage: CmsSitePage;
  readonly cmsContainer: CmsContainer;
}

interface CmsContainerByIdContainerProps {
  readonly id: UUID;
  readonly children: (result: CmsContainerByIdContainerResult) => React.ReactNode;
}

const CmsContainerByIdContainer = ({ id, children }: CmsContainerByIdContainerProps) => {
  const dispatch = useDispatch();

  const { cmsContainer, cmsSitePage, isFetching, isFailed } = useSelector(cmsContainerByIdSelector);

  useEffect(() => {
    dispatch(cmsContainerByIdFetch(id));

    return () => {
      dispatch(cmsContainerByIdStateReset());
    };
  }, [dispatch, id]);

  if (isFailed) {
    return (
      <ContainerWrapper>
        <ErrorComponent />
      </ContainerWrapper>
    );
  }

  if (isFetching || !cmsContainer || !cmsSitePage) {
    return (
      <ContainerWrapper>
        <ContentLoader />
      </ContainerWrapper>
    );
  }

  return <>{children({ cmsSitePage, cmsContainer })}</>;
};

export default CmsContainerByIdContainer;
