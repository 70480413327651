import { ReportDownloadsOfferAdapter } from './offers';
import { ReportDownloadsOffersStatisticAdapter } from './offersStatistic';
import { ReportDownloadsPartnersAdapter } from './partners';
import { ReportDownloadsBookingOrdersStatisticAdapter } from './bookingOrdersStatistic';
import { ReportDownloadsUsersAdapter } from './users';
import { ReportDownloadsEventsDetailedAdapter } from './eventsDetailed';
import { ReportDownloadsSportUsersAdapter } from './sportUsers';
import { ReportDownloadsEventsAdapter } from './events';

const ReportDownloadsDialogsAdapter = () => {
  return (
    <>
      <ReportDownloadsOfferAdapter />
      <ReportDownloadsOffersStatisticAdapter />
      <ReportDownloadsPartnersAdapter />
      <ReportDownloadsBookingOrdersStatisticAdapter />
      <ReportDownloadsUsersAdapter />
      <ReportDownloadsEventsAdapter />
      <ReportDownloadsEventsDetailedAdapter />
      <ReportDownloadsSportUsersAdapter />
    </>
  );
};

export default ReportDownloadsDialogsAdapter;
