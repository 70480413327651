import { Nullable } from '../../../../../../../../domain/model/types';
import DataTableCellTextFormat from '../../../../../../../components/common/table/cell/textFormat';

interface OfferTableCellNameProps {
  readonly name: Nullable<string>;
}

const OfferTableCellName = ({ name }: OfferTableCellNameProps) => (
  <DataTableCellTextFormat>{name}</DataTableCellTextFormat>
);

export default OfferTableCellName;
