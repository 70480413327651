import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { CmsContainer } from '../../../domain/model/cms';
import { ECmsContainerType } from '../../../domain/model/enums';
import { Partner } from '../../../domain/model/partner';
import ForbiddenComponent from '../../components/common/forbidden';
import { ECmsContainerTableTab } from '../../features/cms/container/table/utils';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import { viewShowPartnerManagementMenu } from '../../features/partner/management/container/store/slice';
import {
  getPartnerManagementWindowContainerCreateRoute,
  getPartnerManagementWindowContainerDetailsRoute,
  getPartnerManagementWindowContainerEditRoute,
} from '../../features/partner/management/entry';
import PartnerWindowContainer from '../../features/partnerWindow/details/container';
import { PartnerWindowLocationState } from '../../features/partnerWindow/entry';
import { EPartnerWindowUrlParam } from '../../features/partnerWindow/utils';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface PartnerManagementCorpOffersScreenProps {
  readonly partner: Partner;
}

const PartnerManagementWindowScreen = ({ partner }: PartnerManagementCorpOffersScreenProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<PartnerWindowLocationState>();
  const { partnerWindow } = useCurrentUser().accessMatrix;

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(EPartnerWindowUrlParam.Tab) as ECmsContainerTableTab;

  const guid: UUID = location.state?.guid ?? uuidv4();

  const onEditCmsContainer = useCallback(
    (cmsContainer: CmsContainer) => {
      history.push(
        getPartnerManagementWindowContainerEditRoute({
          partnerId: partner.id,
          containerId: cmsContainer.id,
        })
      );
    },
    [history]
  );

  const onViewCmsContainer = useCallback(
    (cmsContainer: CmsContainer) => {
      history.push(
        getPartnerManagementWindowContainerDetailsRoute({
          partnerId: partner.id,
          containerId: cmsContainer.id,
        })
      );
    },
    [history, partner.id]
  );

  const onAddCmsContainer = useCallback(
    (type: ECmsContainerType) => {
      history.push(getPartnerManagementWindowContainerCreateRoute({ partnerId: partner.id, type }));
    },
    [history, partner.id]
  );

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowPartnerManagementMenu());
  }, [dispatch]);

  if (!partnerWindow.view) {
    return <ForbiddenComponent />;
  }

  return (
    <PartnerWindowContainer
      guid={guid}
      partnerId={partner.id}
      tab={tab}
      canEdit={partnerWindow.edit}
      canCreate={partnerWindow.create}
      canResume={partnerWindow.resume}
      canDelete={partnerWindow.delete}
      canArchive={partnerWindow.archive}
      canPause={partnerWindow.pause}
      canChangeSortIndex={partnerWindow.changeSortIndex}
      canChangePageVisible={partnerWindow.changePageVisible}
      onAddCmsContainer={onAddCmsContainer}
      onEditCmsContainer={onEditCmsContainer}
      onViewCmsContainer={onViewCmsContainer}
    />
  );
};

export default PartnerManagementWindowScreen;
