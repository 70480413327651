import { EBannerPartition } from '@/domain';
import { useContextConfig } from '@features/banner/table/hooks/useContextConfig';
import { useContextHandlers } from '@features/banner/table/hooks/useContextHandlers';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TabsPanel from '../../../../components/common/tabs/panel';
import { bannersTabsCounterSelector } from '../store/selectors';
import BannerTableTab from '../tab';
import { EBannerTableTab } from '../utils';

interface BannerTableTabsAdapterProps {
  readonly tab: EBannerTableTab;
  readonly partition: EBannerPartition;
}

export const BannerTableTabsAdapter = (props: BannerTableTabsAdapterProps) => {
  const { tab, partition } = props;

  const { tabsForCounts } = useContextConfig();
  const { onChangeTab } = useContextHandlers();

  const counter = useSelector(bannersTabsCounterSelector(partition));

  return useMemo(
    () => (
      <TabsPanel
        tab={tab}
        tabs={tabsForCounts}
        labelAdapter={BannerTableTab}
        data={{
          counts: counter,
        }}
        onChangeTab={onChangeTab}
      />
    ),
    [tab, counter, tabsForCounts, onChangeTab]
  );
};
