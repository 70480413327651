import { Typography, TypographyProps } from '@mui/material';
import { AnyAddress } from '../../../../../domain/model/address';
import { Nullable } from '../../../../../domain/model/types';
import { AddressHelper } from '../../../../utils/address';

interface AddressLocalityShortPathViewProps extends TypographyProps {
  readonly address?: Nullable<AnyAddress>;
}

const AddressLocalityShortPathView = ({ address, ...others }: AddressLocalityShortPathViewProps) => {
  const value = address ? new AddressHelper(address).getLocalityShortPath() : null;

  return <Typography {...others}>{value || '-'}</Typography>;
};

export default AddressLocalityShortPathView;
