import CorpActivationTableCell, { CorpActivationTableCellCommonProps } from './index';

const CorpActivationTableCellPartnerName = (props: CorpActivationTableCellCommonProps) => {
  const {
    corpActivation: {
      offer: { partner },
    },
  } = props;

  const value = partner ? partner.name : '-';

  return (
    <CorpActivationTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpActivationTableCellPartnerName;
