import { ECmsContainerStatus } from '../../../../../domain/model/enums';
import { CmsContainerActions, ECmsContainerActionType } from '../types';
import { getCmsContainerActionName, removeCmsContainerAction, renameCmsContainerAction } from '../utils/actions';
import {
  CmsContainerLifeCycle,
  CmsContainerLifeCycleFactoryProps,
  CmsContainerLifeCycleRules,
  StatusActions,
} from './types';
import { canCmsContainerLifeCycleEdit, getCmsContainerLifeCycleNextActions } from './utils';

/**
 * жизненный цикл контейнера cms - фабрика
 */
export const CmsContainerLifeCycleFactory = () => {
  return {
    create: (props: CmsContainerLifeCycleFactoryProps): CmsContainerLifeCycle => {
      return CmsContainerLifeCycleDefault({});
    },
  };
};

interface CmsContainerLifeCycleProps {}

export type CmsContainerLifeCycleType = (props: CmsContainerLifeCycleProps) => CmsContainerLifeCycle;

/**
 * жизненный цикл контейнера cms
 */
export const CmsContainerLifeCycleDefault: CmsContainerLifeCycleType = (props): CmsContainerLifeCycle => {
  /**
   * доступные действия по статусам
   */
  const statusActions: StatusActions = {
    [ECmsContainerStatus.Draft]: [
      ECmsContainerActionType.Publish,
      ECmsContainerActionType.Edit,
      ECmsContainerActionType.Save,
      ECmsContainerActionType.Delete,
      ECmsContainerActionType.Duplicate,
    ],
    [ECmsContainerStatus.Paused]: [
      ECmsContainerActionType.Edit,
      ECmsContainerActionType.Save,
      ECmsContainerActionType.Resume,
      ECmsContainerActionType.Archive,
      ECmsContainerActionType.Duplicate,
    ],
    [ECmsContainerStatus.Upcoming]: [
      ECmsContainerActionType.Edit,
      ECmsContainerActionType.Save,
      ECmsContainerActionType.Pause,
      ECmsContainerActionType.Archive,
      ECmsContainerActionType.Duplicate,
    ],
    [ECmsContainerStatus.Active]: [
      ECmsContainerActionType.Edit,
      ECmsContainerActionType.Save,
      ECmsContainerActionType.Pause,
      ECmsContainerActionType.Archive,
      ECmsContainerActionType.ChangeSortIndex,
      ECmsContainerActionType.Duplicate,
    ],
    [ECmsContainerStatus.Archived]: [ECmsContainerActionType.Duplicate],
  };

  return {
    build: ({ allowedActions, cmsContainer }): CmsContainerLifeCycleRules => {
      if (!cmsContainer) {
        return {
          nextActions: [],
          can: () => false,
          canEdit: false,
        };
      }

      const nextActions: CmsContainerActions = getCmsContainerLifeCycleNextActions({
        statusActions,
        cmsContainer,
        allowedActions,
      });

      const edit = canCmsContainerLifeCycleEdit({
        statusActions,
        cmsContainer,
        allowedActions,
      });

      if (!edit) {
        removeCmsContainerAction(nextActions, ECmsContainerActionType.Save);
        removeCmsContainerAction(nextActions, ECmsContainerActionType.Edit);
      }

      // кнопку дублирования в черновики показываем только для существующего объекта
      if (!cmsContainer.id) {
        removeCmsContainerAction(nextActions, ECmsContainerActionType.Duplicate);
      }

      // кнопку дублирования в черновики переименовываем если это и так черновик
      if (cmsContainer.status === ECmsContainerStatus.Draft) {
        renameCmsContainerAction(nextActions, ECmsContainerActionType.Duplicate, 'Дублировать');
      } else {
        renameCmsContainerAction(
          nextActions,
          ECmsContainerActionType.Duplicate,
          getCmsContainerActionName(ECmsContainerActionType.Duplicate)
        );
      }

      const can = (action: ECmsContainerActionType) => nextActions.some(a => a.type === action && !a.disabled);

      return {
        nextActions,
        can,
        canEdit: edit,
      };
    },
  };
};
