import styled from '@emotion/styled';
import { ListItemContainer } from '../controls';
import { ListItemButtonProps } from '@mui/material';

export type ListItemAddContainerProps = ListItemButtonProps & {
  readonly fixed?: boolean;
};

export const ListItemAddContainer = styled(ListItemContainer)<ListItemAddContainerProps>`
  width: calc(100% - ${p => p.theme.spacing(2)});

  cursor: pointer;
  z-index: 1;

  ${p => p.fixed && 'position: sticky; top: 0;'}
`;
