import { useCallback, useMemo } from 'react';
import { ENotificationMessageType, NotificationTypes } from './types';
import useNotificationSocket from './useNotificationSocket';
import Notifier from '../../../system/notifier';
import { getSocketNotifierByType } from './utils';
import ErrorHandler from '@/data/network/errorHandler';

type AppNotificationsReceiverCallbackProps = (msg: NotificationTypes, type: ENotificationMessageType) => void;

const AppNotificationsReceiver = () => {
  const notifierInstance = useMemo(() => {
    return Notifier.getInstance();
  }, []);

  const getMessage = (type: ENotificationMessageType, msg: NotificationTypes) => {
    switch (type) {
      case ENotificationMessageType.OfferToModerationMessage:
      case ENotificationMessageType.OfferActivatedMessage:
      case ENotificationMessageType.OfferRejectedMessage:
      case ENotificationMessageType.OfferExpiredMessage:
      case ENotificationMessageType.OfferPromotionFreshOutMessage:
      case ENotificationMessageType.OfferPromotionsSellingOut:
      case ENotificationMessageType.OfferUpcomingMessage:
      case ENotificationMessageType.OfferPausedByAdminMessage:
      case ENotificationMessageType.PartnerAddedMessage:
      case ENotificationMessageType.PartnerToVerificationMessage:
        return { ...msg };
      default:
        ErrorHandler.captureException(`Получен неизвестный тип сообщения ${type}`);
        return { ...msg };
    }
  };

  const onMessageReceive = useCallback<AppNotificationsReceiverCallbackProps>((msg, type) => {
    const message = getMessage(type, msg);
    const { text, status, title } = getSocketNotifierByType(message, type);
    return notifierInstance.addNotice(status, text, title);
  }, []);

  useNotificationSocket({ onMessageReceive });

  return null;
};

export default AppNotificationsReceiver;
