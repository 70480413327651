import { Nullable } from '../../../../../../../../domain/model/types';
import DataTableCellTextFormat from '../../../../../../../components/common/table/cell/textFormat';

interface OfferTableCellCodeProps {
  readonly code: Nullable<string>;
}

const OfferTableCellCode = ({ code }: OfferTableCellCodeProps) => (
  <DataTableCellTextFormat>{code ?? '-'}</DataTableCellTextFormat>
);

export default OfferTableCellCode;
