import { ActionConfirmWithCode } from '@/domain';
import useCurrentUser from '@features/user/hooks/useCurrentUser';

type UseCodeConfirm = {
  readonly source?: ActionConfirmWithCode;
  readonly onConfirmed: () => Promise<boolean>;
};

export const useCodeConfirm = (): UseCodeConfirm => {
  const { refreshToken, refreshUserSpecific } = useCurrentUser();

  const onRefreshToken = async () => {
    // обновление токена (для смены почты)
    await refreshToken();
  };

  const onConfirmed = async () => {
    await onRefreshToken();
    refreshUserSpecific();
    return true;
  };

  return {
    onConfirmed,
  };
};
