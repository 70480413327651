import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { ListItemText } from '@mui/material';
import { useMemo } from 'react';
import { ClientOrg } from '../../../../../../domain/model/clientOrg';
import { MenuPanelCommonHeader } from '../../../../../components/common/menuPanel/header';
import { HeaderItem, LogoIconWrapper, UserType } from '../controls';

interface ClientOrgManagementMenuHeaderAdapterProps {
  readonly clientOrg: ClientOrg;
  readonly goBack: () => void;
}

export const ClientOrgManagementMenuHeaderAdapter = (props: ClientOrgManagementMenuHeaderAdapterProps) => {
  const { clientOrg, goBack } = props;

  return useMemo(() => {
    return (
      <MenuPanelCommonHeader>
        <HeaderItem onClick={goBack}>
          <LogoIconWrapper>
            <ArrowBackIosOutlinedIcon fontSize='small' />
          </LogoIconWrapper>
          <ListItemText primary={<UserType variant='subtitle2'>{clientOrg.name}</UserType>} />
        </HeaderItem>
      </MenuPanelCommonHeader>
    );
  }, [clientOrg, goBack]);
};
