import { combineReducers } from '@reduxjs/toolkit';
import container, { PartnerManagementContainerState } from './container/store/slice';
import menu, { PartnerManagementMenuState } from './menu/store/slice';

export interface PartnerManagementState {
  container: PartnerManagementContainerState;
  menu: PartnerManagementMenuState;
}

export default combineReducers<PartnerManagementState>({
  container,
  menu,
});
