import { useSelector } from 'react-redux';
import { ActivityType } from '../../../../../../domain/model/event';
import { DictionaryTreeEdit } from '../../../../../components/common/dictionary/tree';
import { DictionaryAddItem } from '../../../../../components/common/dictionary/tree/item/add';
import { ActivityTypesEditListItem } from '../../components/listItem';
import { ContentWrapper } from '../controls';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { activityTypesEditDataSelector } from '../store/selectors';
import { ActivityTypesEditAccess } from '../types';

const levelsLimit = 2;

interface ActivityTypesEditDictionaryAdapterProps {
  readonly access: ActivityTypesEditAccess;
}

export const ActivityTypesEditDictionaryAdapter = (props: ActivityTypesEditDictionaryAdapterProps) => {
  const { access } = props;

  const data = useSelector(activityTypesEditDataSelector);

  const handlers = useContextHandlers();

  return (
    <ContentWrapper>
      {data && (
        <DictionaryTreeEdit<ActivityType>
          data={data}
          slots={{
            item: props => (
              <ActivityTypesEditListItem
                {...props}
                onEdit={access.canEdit ? () => handlers.onChangeDialogState('modify', props.data) : undefined}
                onHide={access.canChangeVisible ? () => handlers.onChangeDialogState('hide', props.data) : undefined}
                onShow={access.canChangeVisible ? () => handlers.onShow(props.data.data) : undefined}
                onDelete={access.canDelete ? () => handlers.onDelete(props.data.data) : undefined}
              />
            ),
            levelHeader: access.canCreate
              ? props => (
                  <DictionaryAddItem
                    {...props}
                    fixed
                    onClick={() => handlers.onChangeDialogState('add', { parent: props.parent })}
                  >
                    Добавить категорию
                  </DictionaryAddItem>
                )
              : undefined,
          }}
          hasLevel={level => level + 1 <= levelsLimit}
          onChangeSort={
            access.canSort
              ? (parent, array) =>
                  handlers.onSort(
                    parent?.data ?? null,
                    array.map(item => item.data)
                  )
              : undefined
          }
        />
      )}
    </ContentWrapper>
  );
};
