import { FCC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BookingOrderDetailsContainerProps } from './container';
import { bookingOrderDetailsByIdFetch, bookingOrderDetailsStateReset } from './store/slice';

type BookingOrderDetailsInitializerProps = BookingOrderDetailsContainerProps;

export const BookingOrderDetailsInitializer: FCC<BookingOrderDetailsInitializerProps> = ({ id, children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const promise = dispatch(bookingOrderDetailsByIdFetch({ id }));

    return () => {
      dispatch(bookingOrderDetailsStateReset());
      promise?.abort();
    };
  }, [dispatch, id]);

  return <>{children}</>;
};
