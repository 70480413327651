import { AdCampaignTableCellAdapterProps } from '@features/adCampaign/table/adapters/cell';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { AdCampaignTableCellTypeProps } from '../../components/tableCell';
import { adCampaignAdCampaignByIndexSelector } from '../store/selectors';

type AdCampaignTableCellTypeAdapterProps = Omit<AdCampaignTableCellAdapterProps, 'component'> & {
  readonly component: FC<AdCampaignTableCellTypeProps>;
};

const AdCampaignTableCellTypeAdapter = (props: AdCampaignTableCellTypeAdapterProps) => {
  const { index, component: Component } = props;

  const { adCampaignTypes: adTypes } = useSelector(nsiDataSelector);

  const adCampaign = useSelector(adCampaignAdCampaignByIndexSelector(index));

  return adCampaign ? (
    <Component
      adCampaign={adCampaign}
      adTypes={adTypes}
    />
  ) : null;
};

export default AdCampaignTableCellTypeAdapter;
