import { Grid } from '@mui/material';
import { FCC } from 'react';
import { Color } from './controls';

type ClientOrgColorBrandWrapperProps = {
  readonly color: Nullable<string>;
  readonly alignItems?: 'flex-end';
};

const ClientOrgColorBrandWrapper: FCC<ClientOrgColorBrandWrapperProps> = ({ color, alignItems, children }) => {
  return (
    <Grid
      container
      spacing={1}
      alignItems={alignItems ?? 'inherit'}
    >
      <Grid
        item
        xs={2.5}
      >
        <Color style={{ backgroundColor: color ?? '' }} />
      </Grid>
      {children && (
        <Grid
          item
          xs
        >
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default ClientOrgColorBrandWrapper;
