import { MpUser, Nullable, UUID } from '@/domain';
import { DataFilterAdapter } from '@/presentation/hooks/useDataFilterAdapter';
import {
  CustomerTableAdapter,
  CustomerTableBehaviorAdapter,
  CustomerTableFilterAdapter,
  CustomerTableHeaderAdapter,
} from '@features/customer/table/adapters';
import { CustomersTableContext } from '@features/customer/table/context';
import CustomerTableFooterContainer from '@features/customer/table/footerContainer';
import { useCustomersTableHandlers } from '@features/customer/table/hooks/useTable';
import TableCommonLayout from '@layouts/tableCommon/container';
import { useMemo } from 'react';
import { CustomerActions, CustomerActionTableType } from '../types';
import { CustomerTableTabsAdapter } from './adapters/tabs';
import { ECustomersFilterItem } from './filterUtils';
import { ECustomerTableColumn, ECustomerTableTab, getCustomerTableTabActions } from './utils';

interface CustomersTableContainerProps {
  readonly guid: UUID;
  readonly partnerId?: Nullable<UUID>;
  readonly tab: ECustomerTableTab;
  readonly filterAdapter: DataFilterAdapter<ECustomerTableColumn, ECustomersFilterItem>;
  readonly canChangeStatus?: boolean;
  readonly onCustomerClick: (customer: MpUser) => void;
}

const CustomersTableContainer = (props: CustomersTableContainerProps) => {
  const { guid, partnerId = null, tab, filterAdapter, canChangeStatus = false, onCustomerClick } = props;

  const handlers = useCustomersTableHandlers({ tab });

  const tableTabActions = useMemo<CustomerActions<CustomerActionTableType>>(
    () =>
      getCustomerTableTabActions(
        {
          canActivate: canChangeStatus,
          canDeactivate: canChangeStatus,
        },
        tab
      ),
    [canChangeStatus, tab]
  );

  return (
    <CustomersTableContext.Provider value={handlers}>
      <CustomerTableBehaviorAdapter
        guid={guid}
        tab={tab}
        partnerId={partnerId}
      />
      <TableCommonLayout
        filter={<CustomerTableFilterAdapter filterAdapter={filterAdapter} />}
        header={<CustomerTableHeaderAdapter />}
        tabs={<CustomerTableTabsAdapter tab={tab} />}
        table={
          <CustomerTableAdapter
            filterAdapter={filterAdapter}
            tabActions={tableTabActions}
            onCustomerClick={onCustomerClick}
          />
        }
        footer={CustomerTableFooterContainer}
      ></TableCommonLayout>
    </CustomersTableContext.Provider>
  );
};

export default CustomersTableContainer;
