import { Checkbox, Typography } from '@mui/material';
import { CmsContainerDetailsComponentProps } from '../../types';
import { CheckboxLabel } from './controls';

type CmsContainerViewComponentOfferIconVisibleProps = CmsContainerDetailsComponentProps;

const CmsContainerViewComponentOfferIconVisible = (props: CmsContainerViewComponentOfferIconVisibleProps) => {
  const { cmsContainer } = props;

  return (
    <CheckboxLabel
      control={
        <Checkbox
          disabled
          color='primary'
          checked={cmsContainer.showIcon ?? false}
        />
      }
      label={<Typography>Показать иконку вида предложения</Typography>}
    />
  );
};

export default CmsContainerViewComponentOfferIconVisible;
