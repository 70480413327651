import PartnerTableCell, { PartnerTableCellCommonProps } from './index';

const PartnerTableCellInn = (props: PartnerTableCellCommonProps) => {
  const {
    partner: { inn },
  } = props;

  const value = inn || '-';

  return (
    <PartnerTableCell
      {...props}
      value={value}
    />
  );
};

export default PartnerTableCellInn;
