import { ContentTargetViewLocalitiesTableCell } from '../../../general/contentTarget/components/viewLocalities';
import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from './index';

const CorpOfferTableCellTargetLocalities = (props: CorpOfferTableCellCommonProps) => {
  const {
    corpOffer: { target },
  } = props;

  return (
    <CorpOfferTableCell
      {...props}
      value={<ContentTargetViewLocalitiesTableCell value={target.targetLocalities ?? null} />}
    />
  );
};

export default CorpOfferTableCellTargetLocalities;
