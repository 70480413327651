import BookingOrderTableCell, { BookingOrderTableCellCommonProps } from './index';
import { formatCost } from '../../../../utils';

const BookingOrderTableCellCost = (props: BookingOrderTableCellCommonProps) => {
  const {
    bookingOrder: { cost },
  } = props;

  return (
    <BookingOrderTableCell
      {...props}
      value={formatCost(cost) ?? ''}
    />
  );
};

export default BookingOrderTableCellCost;
