import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../data/store/types';
import { XLSXImportResult } from '../../../../../domain/model';
import { Nullable, UUID } from '../../../../../domain/model/types';

export const personalPromotionCreateUpload = createAsyncThunk<
  XLSXImportResult,
  { offerId: UUID; file: File },
  AppThunkAPIConfig
>('personalPromotion/create/upload', async ({ offerId, file }, { rejectWithValue, signal }) => {
  try {
    const { data } = await Api.personalPromotion.import({ offerId, file, signal });
    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const personalPromotionCreateGenerate = createAsyncThunk<
  string[],
  { offerId: UUID; count: number },
  AppThunkAPIConfig
>('personalPromotion/create/generate', async ({ offerId, count }, { rejectWithValue }) => {
  try {
    const { data } = await Api.personalPromotion.generate({ offerId, count });
    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface PersonalPromotionCreateState {
  readonly upload: Fetchable & {
    readonly data: Nullable<XLSXImportResult>;
  };
  readonly generate: Fetchable & {
    readonly data: Nullable<string[]>;
  };
}

interface Reducers extends SliceCaseReducers<PersonalPromotionCreateState> {
  personalPromotionCreateStateReset: CaseReducer<PersonalPromotionCreateState, PayloadAction<undefined>>;
}

const slice = createSlice<PersonalPromotionCreateState, Reducers, 'create'>({
  name: 'create',
  initialState: {
    upload: {
      ...fetchableDefault,
      data: null,
    },
    generate: {
      ...fetchableDefault,
      data: null,
    },
  },
  reducers: {
    personalPromotionCreateStateReset: state => {
      state.upload = {
        ...fetchableDefault,
        data: null,
      };
      state.generate = {
        ...fetchableDefault,
        data: null,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(personalPromotionCreateUpload.pending, state => {
        state.upload = {
          ...fetchableFetching,
          data: null,
        };
      })
      .addCase(personalPromotionCreateUpload.fulfilled, (state, { payload }) => {
        state.upload = {
          ...fetchableFetched,
          data: payload,
        };
      })
      .addCase(personalPromotionCreateUpload.rejected, state => {
        state.upload = {
          ...fetchableFailed,
          data: null,
        };
      })
      .addCase(personalPromotionCreateGenerate.pending, state => {
        state.generate = {
          ...fetchableFetching,
          data: null,
        };
      })
      .addCase(personalPromotionCreateGenerate.fulfilled, (state, { payload }) => {
        state.generate = {
          ...fetchableFetched,
          data: payload,
        };
      })
      .addCase(personalPromotionCreateGenerate.rejected, state => {
        state.upload = {
          ...fetchableFailed,
          data: null,
        };
      });
  },
});

export const { personalPromotionCreateStateReset } = slice.actions;

export default slice.reducer;
