import { Divider, List, ListItemSecondaryAction, Tooltip } from '@mui/material';
import { isEqual, uniqWith } from 'lodash';
import React, { useState } from 'react';
import { Team } from '../../../../../../domain/model/team';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import { TeamAvatar } from '../../../../../components/common/avatars';
import { Captain as CaptainIcon } from '../../../../../icons';
import { MPToggleButtonsGroupSingle } from '../../../../../theme/ui-kit/button/toggle';
import { AddressLocalityShortPathView } from '../../../../address';
import { ButtonsGroupWrapper, ListItem, ListItemAvatar, StyledTextListItem, Wrapper } from './controls';
import { ActivityType } from '../../../../../../domain/model/event';

interface SportsmanTabTeamsProps {
  readonly sportsmanId: UUID;
  readonly teams: Team[];
  readonly onTeamClick: (team: Team) => void;
}

const SportsmanTabTeams = ({ sportsmanId, teams, onTeamClick }: SportsmanTabTeamsProps) => {
  const [activityType, setActivityType] = useState<Nullable<ActivityType>>(null);

  const activityTypes = uniqWith<ActivityType>(
    teams.flatMap(item => item.activityTypes ?? []),
    isEqual
  );

  return (
    <Wrapper>
      {activityTypes.length > 1 && (
        <ButtonsGroupWrapper>
          <MPToggleButtonsGroupSingle<ActivityType>
            items={activityTypes}
            value={activityType}
            onChange={setActivityType}
          />
        </ButtonsGroupWrapper>
      )}
      <List>
        {teams
          .filter(item => !activityType || item.activityTypes?.some(a => a.id === activityType.id))
          .map(team => (
            <React.Fragment key={team.id}>
              <ListItem
                alignItems='center'
                onClick={() => {
                  onTeamClick(team);
                }}
              >
                <ListItemAvatar>
                  <TeamAvatar team={team} />
                </ListItemAvatar>
                <StyledTextListItem
                  primary={team.name}
                  secondary={<AddressLocalityShortPathView address={team.locality} />}
                />
                {team.captain?.userProfile?.id === sportsmanId && (
                  <ListItemSecondaryAction>
                    <Tooltip
                      title='Капитан команды'
                      arrow
                    >
                      <CaptainIcon color='primary' />
                    </Tooltip>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <Divider
                key={`divider${team.id}`}
                variant='inset'
                component='li'
              />
            </React.Fragment>
          ))}
      </List>
    </Wrapper>
  );
};

export default SportsmanTabTeams;
