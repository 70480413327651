import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nullable, UUID } from '../../../../../domain/model/types';
import {
  sportsmansGetFilter,
  sportsmansGetNeedRefreshWatcher,
  sportsmansGetPageNumber,
  sportsmansGetSearch,
  sportsmansGetGuid,
} from '../store/selectors';
import {
  SportsmanFetchProps,
  sportsmanNeedRefreshWatcherReset,
  sportsmansCountsFetch,
  sportsmansFetch,
} from '../store/slice';
import { useContextConfig } from './useContextConfig';

interface UseSportsmanTableSearchProps {
  readonly guid: UUID;
}

const useSportsmanTableSearch = ({ guid }: UseSportsmanTableSearchProps): void => {
  const dispatch = useDispatch();
  const [searchPromises, setSearchPromises] = useState<Nullable<any[]>>(null);

  const { tabsForCounts } = useContextConfig();

  const currentGuid = useSelector(sportsmansGetGuid);
  const needRefreshWatcher = useSelector(sportsmansGetNeedRefreshWatcher);
  const filter = useSelector(sportsmansGetFilter);
  const pageNumber = useSelector(sportsmansGetPageNumber);
  const search = useSelector(sportsmansGetSearch);

  const queryParams = useMemo<SportsmanFetchProps>(() => {
    return {
      search,
      filter,
      pageNumber,
    };
  }, [filter, search, pageNumber]);

  const onSearch = useCallback(() => {
    setSearchPromises([
      dispatch(sportsmansFetch(queryParams)),
      dispatch(
        sportsmansCountsFetch({
          ...queryParams,
          tabs: tabsForCounts,
        })
      ),
    ]);
  }, [dispatch, queryParams, tabsForCounts]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  // Первоначальная загрузка
  useEffect(() => {
    if (guid === currentGuid) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, guid, currentGuid]);

  // сброс вотчера после ухода
  useEffect(() => {
    return () => {
      dispatch(sportsmanNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};

export default useSportsmanTableSearch;
