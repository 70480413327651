import { getProductPausedReasonText } from '../../utils/common';
import ProductTableCell, { ProductTableCellCommonProps } from './index';

const ProductTableCellPausedReason = (props: ProductTableCellCommonProps) => {
  const {
    productOffer: { pausedReason },
  } = props;

  const value = pausedReason ? getProductPausedReasonText(pausedReason) : '';

  return (
    <ProductTableCell
      {...props}
      value={value}
    />
  );
};

export default ProductTableCellPausedReason;
