import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChatMember } from '../../../../../domain/model/chat';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { EUserNameRule, getUserFullName } from '../../../../utils';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { chatGetMembersSelector } from '../store/selectors';
import { chatMembersFetch } from '../store/slice';

interface UseChatMembersProps {
  readonly guid: UUID;
  readonly chatId: Nullable<UUID>;
}

interface UseChatMembers {
  readonly members: ChatMember[];
  readonly isFetching: boolean;
  readonly isFetched: boolean;
  readonly refreshMembers: () => void;
  readonly getMemberById: (memberId: UUID) => Nullable<ChatMember>;
}

const useChatMembers = ({ guid, chatId }: UseChatMembersProps): UseChatMembers => {
  const dispatch = useDispatch();

  const { userSpecific } = useCurrentUser();
  const { data: members, isFetching, isFetched } = useSelector(chatGetMembersSelector(guid));

  const currentUserAsMember = useMemo<ChatMember>(() => {
    return {
      id: userSpecific.id,
      name: getUserFullName({ user: userSpecific, rule: EUserNameRule.Reverse }),
      image: userSpecific.photo?.path,
    };
  }, [userSpecific]);

  const refreshMembers = useCallback(() => {
    if (chatId) {
      dispatch(chatMembersFetch({ guid, chatId }));
    }
  }, [guid, chatId]);

  const getMemberById = useCallback(
    (memberId: UUID): Nullable<ChatMember> => {
      const member = members.find(member => member.id === memberId);
      if (member) {
        return member;
      }

      const currentUserMember = memberId === currentUserAsMember.id ? currentUserAsMember : null;
      return currentUserMember ?? null;
    },
    [members, currentUserAsMember]
  );

  useEffect(() => {
    if (chatId) {
      const promise = dispatch(chatMembersFetch({ guid, chatId }));
      return () => {
        promise.abort();
      };
    }
  }, [guid, chatId]);

  return {
    members,
    isFetching,
    isFetched,

    refreshMembers,
    getMemberById,
  };
};

export default useChatMembers;
