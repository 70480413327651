import { LinearProgress } from '@mui/material';
import styled from '@emotion/styled';

export const TableLoading = styled(LinearProgress)`
  width: 100%;

  position: absolute;
  bottom: 0;
  z-index: 3;
`;
