import { EOfferPausedReason, EOfferStatus } from '../../../../../domain/model/enums';
import { BookingLifeCycleCondition } from '../types';

export const isWaitingForModerationCondition: BookingLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Ожидает модерации'],
  call: ({ obj }) => {
    const { status } = obj;

    return status === EOfferStatus.WaitingForModeration;
  },
};

export const isNotWaitingForModerationCondition: BookingLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Не ожидает модерации'],
  call: props => !isWaitingForModerationCondition.call(props),
};

export const isModeratedByMeCondition: BookingLifeCycleCondition = {
  description: 'Проверка по статусу и модератору',
  algorithm: ['На модерации у текущего пользователя'],
  call: ({ userId, obj }) => {
    const { status, approvingAdmin } = obj;
    const isModerator = userId === approvingAdmin?.id;

    return status === EOfferStatus.OnModeration && isModerator;
  },
};

export const isNotOnModerationByMeCondition: BookingLifeCycleCondition = {
  description: 'Проверка по статусу и модератору',
  algorithm: ['На модерации у другого пользователя'],
  call: ({ userId, obj }) => {
    const { status, approvingAdmin } = obj;
    const isModerator = userId === approvingAdmin?.id;

    return status === EOfferStatus.OnModeration && !isModerator;
  },
};

export const isNotMeLastStatusAuthor: BookingLifeCycleCondition = {
  description: 'Проверка текущий пользователь не автор последнего статуса',
  algorithm: ['Текущий пользователь не автор последнего статуса'],
  call: ({ userId, obj }) => {
    const { lastStatusAuthor } = obj;

    return lastStatusAuthor?.id !== userId;
  },
};

export const isDraftCondition: BookingLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Черновик'],
  call: ({ obj }) => {
    const { status } = obj;

    return status === EOfferStatus.Draft;
  },
};

export const isNotDraftCondition: BookingLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Не черновик'],
  call: ({ obj }) => {
    const { status } = obj;

    return status !== EOfferStatus.Draft;
  },
};

export const isActiveCondition: BookingLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Опубликован'],
  call: ({ obj }) => {
    const { status } = obj;

    return status === EOfferStatus.Active;
  },
};

export const isOnModerationCondition: BookingLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['На модерации'],
  call: ({ userId, obj }) => {
    const { status } = obj;

    return status === EOfferStatus.OnModeration;
  },
};

export const isArchivedCondition: BookingLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Архивный'],
  call: ({ obj }) => {
    const { status } = obj;

    return status === EOfferStatus.Archived;
  },
};

export const isRejectedCondition: BookingLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Отклонен'],
  call: ({ obj }) => {
    const { status } = obj;

    return status === EOfferStatus.Rejected;
  },
};

export const isPausedCondition: BookingLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Приостановлен'],
  call: ({ obj }) => {
    const { status } = obj;

    return status === EOfferStatus.Paused;
  },
};

export const isPausedByNotPartnerCondition: BookingLifeCycleCondition = {
  description: 'Проверка по причине приостановки',
  algorithm: ['Приостановлен не партнером'],
  call: ({ obj }) => {
    const { pausedReason } = obj;

    return pausedReason?.code !== EOfferPausedReason.PausedByPartner;
  },
};

export const isPausedByPartnerCondition: BookingLifeCycleCondition = {
  description: 'Проверка по причине приостановки',
  algorithm: ['Приостановлен партнером'],
  call: props => {
    return !isPausedByNotPartnerCondition.call(props);
  },
};

export const isNotSavedCondition: BookingLifeCycleCondition = {
  description: 'Проверка по ID',
  algorithm: ['Запись ещё не сохранена'],
  call: ({ obj }) => {
    const { id } = obj;
    return !id;
  },
};

export const isNotCanDeleteCondition: BookingLifeCycleCondition = {
  description: 'Проверка по возможности удаления',
  algorithm: ['Запись не может быть удалена'],
  call: ({ obj }) => {
    const { canDelete } = obj;
    return !canDelete;
  },
};
