import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../../data/store/types';
import { DataTreeItem } from '../../../../../../domain/model';
import { ActivityType } from '../../../../../../domain/model/event';
import { Nullable } from '../../../../../../domain/model/types';
import service from '../../../../activityType/services';
import { buildActivityTypeTree } from '../utils';

export const activityTypesEditFetch = createAsyncThunk<ActivityType[], void, AppThunkAPIConfig>(
  'activityType/edit/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await service.all({ signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const activityTypesEditCreate = createAsyncThunk<ActivityType, ActivityType, AppThunkAPIConfig>(
  'activityType/edit/create',
  async (data, { rejectWithValue }) => {
    try {
      return await service.create(data);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const activityTypesEditUpdate = createAsyncThunk<ActivityType, ActivityType, AppThunkAPIConfig>(
  'activityType/edit/update',
  async (data, { rejectWithValue }) => {
    try {
      return await service.update({ id: data.id, data });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const activityTypesEditDelete = createAsyncThunk<void, ActivityType, AppThunkAPIConfig>(
  'activityType/edit/delete',
  async (data, { rejectWithValue }) => {
    try {
      return await service.delete(data);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const activityTypesEditHide = createAsyncThunk<ActivityType, ActivityType, AppThunkAPIConfig>(
  'activityType/edit/hide',
  async (data, { rejectWithValue }) => {
    try {
      return await service.hide(data);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const activityTypesEditShow = createAsyncThunk<ActivityType, ActivityType, AppThunkAPIConfig>(
  'activityType/edit/show',
  async (data, { rejectWithValue }) => {
    try {
      return await service.show(data);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const activityTypesEditSort = createAsyncThunk<
  void,
  { parent: Nullable<ActivityType>; activityTypes: ActivityType[] },
  AppThunkAPIConfig
>('activityType/edit/sort', async ({ parent, activityTypes }, { rejectWithValue }) => {
  try {
    return await service.sort({ parentId: parent?.id ?? null, data: activityTypes });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface ActivityTypesEditState {
  readonly fetch: Fetchable & {
    readonly data: Nullable<DataTreeItem<ActivityType>[]>;
  };
  readonly create: Fetchable;
  readonly update: Fetchable;
  readonly hide: Fetchable;
  readonly show: Fetchable;
  readonly sort: Fetchable;
  readonly delete: Fetchable;
  readonly dialogs: {
    readonly add: Nullable<{ parent: Nullable<DataTreeItem<ActivityType>> }>;
    readonly modify: Nullable<DataTreeItem<ActivityType>>;
    readonly hide: Nullable<DataTreeItem<ActivityType>>;
  };
}

type Reducer<T = undefined> = CaseReducer<ActivityTypesEditState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ActivityTypesEditState> {
  readonly activityTypesEditResetState: Reducer;
  readonly activityTypesEditSetDialogState: Reducer<{
    name: keyof ActivityTypesEditState['dialogs'];
    data: any;
  }>;
}

const slice = createSlice<ActivityTypesEditState, Reducers, 'activityType/edit'>({
  name: 'activityType/edit',
  initialState: {
    fetch: {
      ...fetchableDefault,
      data: null,
    },
    create: {
      ...fetchableDefault,
    },
    update: {
      ...fetchableDefault,
    },
    hide: {
      ...fetchableDefault,
    },
    show: {
      ...fetchableDefault,
    },
    sort: {
      ...fetchableDefault,
    },
    delete: {
      ...fetchableDefault,
    },
    dialogs: {
      add: null,
      modify: null,
      hide: null,
    },
  },
  reducers: {
    activityTypesEditResetState: state => {
      state.fetch = {
        ...fetchableDefault,
        data: null,
      };
      state.create = {
        ...fetchableDefault,
      };
      state.update = {
        ...fetchableDefault,
      };
      state.hide = {
        ...fetchableDefault,
      };
      state.show = {
        ...fetchableDefault,
      };
      state.sort = {
        ...fetchableDefault,
      };
      state.delete = {
        ...fetchableDefault,
      };
      state.dialogs = {
        add: null,
        modify: null,
        hide: null,
      };
    },
    activityTypesEditSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(activityTypesEditFetch.pending, state => {
        state.fetch.isFetching = true;
        state.fetch.isFetched = false;
        state.fetch.isFailed = false;
      })
      .addCase(activityTypesEditFetch.fulfilled, (state, { payload }) => {
        state.fetch.isFetching = false;
        state.fetch.isFetched = true;
        state.fetch.isFailed = false;
        state.fetch.data = buildActivityTypeTree(payload);
      })
      .addCase(activityTypesEditFetch.rejected, state => {
        state.fetch.isFetching = false;
        state.fetch.isFetched = false;
        state.fetch.isFailed = true;
        state.fetch.data = null;
      })
      .addCase(activityTypesEditCreate.pending, state => {
        state.create.isFetching = true;
        state.create.isFetched = false;
        state.create.isFailed = false;
      })
      .addCase(activityTypesEditCreate.fulfilled, (state, { payload }) => {
        state.create.isFetching = false;
        state.create.isFetched = true;
        state.create.isFailed = false;
      })
      .addCase(activityTypesEditCreate.rejected, state => {
        state.update.isFetching = false;
        state.update.isFetched = false;
        state.update.isFailed = true;
      })
      .addCase(activityTypesEditSort.pending, state => {
        state.sort.isFetching = true;
        state.sort.isFetched = false;
        state.sort.isFailed = false;
      })
      .addCase(activityTypesEditSort.fulfilled, (state, { payload }) => {
        state.sort.isFetching = false;
        state.sort.isFetched = true;
        state.sort.isFailed = false;
      })
      .addCase(activityTypesEditSort.rejected, state => {
        state.sort.isFetching = false;
        state.sort.isFetched = false;
        state.sort.isFailed = true;

        //откатываем изменения
        state.fetch.data = restructChilds(state.fetch.data ?? []);
      })
      .addCase(activityTypesEditUpdate.pending, state => {
        state.update.isFetching = true;
        state.update.isFetched = false;
        state.update.isFailed = false;
      })
      .addCase(activityTypesEditUpdate.fulfilled, (state, { payload }) => {
        state.update.isFetching = false;
        state.update.isFetched = true;
        state.update.isFailed = false;
      })
      .addCase(activityTypesEditUpdate.rejected, state => {
        state.update.isFetching = false;
        state.update.isFetched = false;
        state.update.isFailed = true;
      })
      .addCase(activityTypesEditHide.pending, state => {
        state.hide.isFetching = true;
        state.hide.isFetched = false;
        state.hide.isFailed = false;
      })
      .addCase(activityTypesEditHide.fulfilled, (state, { payload }) => {
        state.hide.isFetching = false;
        state.hide.isFetched = true;
        state.hide.isFailed = false;
      })
      .addCase(activityTypesEditHide.rejected, state => {
        state.hide.isFetching = false;
        state.hide.isFetched = false;
        state.hide.isFailed = true;
      })
      .addCase(activityTypesEditShow.pending, state => {
        state.show.isFetching = true;
        state.show.isFetched = false;
        state.show.isFailed = false;
      })
      .addCase(activityTypesEditShow.fulfilled, (state, { payload }) => {
        state.show.isFetching = false;
        state.show.isFetched = true;
        state.show.isFailed = false;
      })
      .addCase(activityTypesEditShow.rejected, state => {
        state.show.isFetching = false;
        state.show.isFetched = false;
        state.show.isFailed = true;
      })
      .addCase(activityTypesEditDelete.pending, state => {
        state.delete.isFetching = true;
        state.delete.isFetched = false;
        state.delete.isFailed = false;
      })
      .addCase(activityTypesEditDelete.fulfilled, (state, { meta }) => {
        state.delete.isFetching = false;
        state.delete.isFetched = true;
        state.delete.isFailed = false;
      })
      .addCase(activityTypesEditDelete.rejected, state => {
        state.delete.isFetching = false;
        state.delete.isFetched = false;
        state.delete.isFailed = true;
      });
  },
});

export const { activityTypesEditResetState, activityTypesEditSetDialogState } = slice.actions;

const restructChilds = (tree: DataTreeItem<ActivityType>[]): DataTreeItem<ActivityType>[] =>
  tree.map(item => ({ ...item, children: item.children ? restructChilds(item.children) : null }));

export default slice.reducer;
