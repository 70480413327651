import { Address } from '../../../../../../domain/model/address';
import { EAddressLevel } from '../../../../../../domain/model/enums';
import { AddressHelper } from '../../../../../utils/address';
import { AddressSelectorProps } from '../types';
import AddressSingleSelector, { AddressSingleSelectorProps } from './index';

export interface AddressLocalitySingleSelectorProps
  extends Omit<
    AddressSingleSelectorProps,
    'getOptionLabel' | 'onlyValidPostalCode' | 'level' | 'fromLevel' | 'toLevel'
  > {}

const getOptionLabel = (address: Address) => new AddressHelper(address).getLocalityFullPath() ?? '-';

const groupAttributeResolveCallback = (option: Address) =>
  option.hierarchy?.length ? option.hierarchy[option.hierarchy.length - 1].level.name : '';

const groupBy: AddressSelectorProps<Address>['groupBy'] = options => {
  const groupOptions = options.map(groupAttributeResolveCallback);
  if (new Set(groupOptions).size > 1) {
    return groupAttributeResolveCallback;
  } else {
    return () => '';
  }
};

const AddressLocalitySingleSelector = (props: AddressLocalitySingleSelectorProps) => {
  return (
    <AddressSingleSelector
      {...props}
      fromLevel={EAddressLevel.City}
      toLevel={EAddressLevel.Settlement}
      strict
      getOptionLabel={getOptionLabel}
      groupBy={groupBy}
    />
  );
};

export default AddressLocalitySingleSelector;
