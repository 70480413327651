import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import BookingServiceCategoriesEditContainer from '../../features/dictionary/bookingServiceCategory/edit/container';

const ConfigurationPrivilegeBookingServiceCategoriesScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  return <BookingServiceCategoriesEditContainer />;
};

export default ConfigurationPrivilegeBookingServiceCategoriesScreen;
