import { ProductOrderStatus } from '../../../domain/model/order';

export enum EProductOrderActionType {
  Save = 'save',
  New = 'new',
  Renew = 'renew',
  Confirm = 'confirm',
  Pay = 'pay',
  Send = 'send',
  Return = 'return',
  PartiallyReturn = 'partiallyReturn',
  Cancel = 'cancel',
  Give = 'give',
}

export type ProductOrderActionTableType = Exclude<EProductOrderActionType, EProductOrderActionType.Save | EProductOrderActionType.New>;

export enum EProductOrderEditActionType {
  Save = 'save',
}

export enum EProductOrderTableTab {
  New = 'NEW',
  Confirmed = 'CONFIRMED',
  Paid = 'PAID',
  Sent = 'SENT',
  Given = 'GIVEN',
  Cancelled = 'CANCELLED',
  PartiallyReturned = 'PARTIALLY_RETURNED',
  Returned = 'RETURNED',
}

export enum EProductOrderTableColumn {
  CreateAt = 'createdAt',
  Number = 'number',
  Cost = 'cost',
  CustomerName = 'customer.lastName',
  ManagerComment = 'managerComment',
  CancelReason = 'cancelReason',
  CustomerCancelReason = 'customerCancelReason',
  ItemsCount = 'itemsCount',
}

export type ProductOrderCounterByStatus = {
  [name in ProductOrderStatus]?: number;
};
