import type { AllProps } from './common';
import { PlaygroundCommonService, playgroundCommonService } from './common';
import { PlaygroundUsersService, playgroundUsersService } from './users';
import { playgroundActionService, PlaygroundActionService } from './action';

type PlaygroundService = {
  common: PlaygroundCommonService;
  users: PlaygroundUsersService;
  action: PlaygroundActionService;
};

export type { AllProps, PlaygroundService };

const playgroundService: PlaygroundService = {
  common: playgroundCommonService,
  users: playgroundUsersService,
  action: playgroundActionService,
};

export default playgroundService;