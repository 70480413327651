import { Grid } from '@mui/material';
import styled from '@emotion/styled';

interface WrapperProps {
  readonly success?: boolean;
  readonly error?: boolean;
}

export const Wrapper = styled(({ success = false, error = false, ...others }: WrapperProps & { children?: any }) => (
  <div {...others} />
))`
  display: flex;
  align-items: center;

  border: 1px solid
    ${p =>
      p.success ? p.theme.palette.success.main : p.error ? p.theme.palette.error.main : p.theme.palette.secondary.main};
  border-radius: ${p => p.theme.shape.borderRadius}px;
  overflow: hidden;

  padding: ${p => p.theme.spacing(0.5)} ${p => p.theme.spacing(1)};
  cursor: pointer;

  > svg {
    margin-right: ${p => p.theme.spacing(1)};
  }
`;

export const Content = styled(Grid)`
  flex: 1;
`;
