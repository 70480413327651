import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import TabsPanel from '../../../../components/common/tabs/panel';
import { OfferTableTab } from '../../../general/offer/components/table/tab';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { productsTabsCounterSelector, productsTabsCounterUnwatchedSelector } from '../store/selectors';
import { EProductTableTab, getProductTableTabName } from '../utils';

interface ProductTableTabsAdapterProps {
  readonly tab: EProductTableTab;
}

export const ProductTableTabsAdapter = (props: ProductTableTabsAdapterProps) => {
  const { tab } = props;

  const { onChangeTab } = useContextHandlers();
  const { tabs, isPartnerUser } = useContextConfig();

  const counter = useSelector(productsTabsCounterSelector);
  const counterUnwatched = useSelector(productsTabsCounterUnwatchedSelector);

  const getTabName = useCallback(
    (tab: EProductTableTab) => getProductTableTabName(tab, isPartnerUser),
    [isPartnerUser]
  );

  return useMemo(
    () => (
      <TabsPanel
        tab={tab}
        tabs={tabs}
        labelAdapter={OfferTableTab}
        data={{
          counts: counter,
          unwatchedCounts: counterUnwatched,
          getTabName,
        }}
        onChangeTab={onChangeTab}
      />
    ),
    [tab, counter, counterUnwatched, onChangeTab, getTabName]
  );
};
