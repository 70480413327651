import { combineReducers } from '@reduxjs/toolkit';
import actions, { CorpActivationActionsState } from './actions/store/slice';
import list, { CorpActivationListState } from './table/store/slice';

interface CorpActivationState {
  actions: CorpActivationActionsState;
  list: CorpActivationListState;
}

export default combineReducers<CorpActivationState>({
  actions,
  list,
});
