import { Banner } from '@/domain';
import { BannerActions, BannerActionTableType, EBannerActionType } from '@features/banner/types';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BannersTableCellSortIndexProps } from '../../components/tableCell/sortIndex';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  bannersBannerByIndexSelector,
  bannersBannerToChangeSortIndexSelector,
  bannersSortIndexInfoSelector,
} from '../store/selectors';
import { BannerActionCall, BannerTableActionProcess } from '../utils';
import { BannerTableCellAdapterProps } from './cell';

type BannerTableCellSortIndexAdapterProps = Omit<BannerTableCellAdapterProps, 'component'> & {
  readonly component: FC<BannersTableCellSortIndexProps>;
  readonly hoveredRow?: boolean;
  readonly getActionProcesses: (banner: Banner) => BannerTableActionProcess[];
  readonly getActions: (banner: Banner) => BannerActions<BannerActionTableType>;
  readonly onBannerAction: (call: BannerActionCall) => void;
};

export const BannerTableCellSortIndexAdapter = (props: BannerTableCellSortIndexAdapterProps) => {
  const { partition, index, hoveredRow, getActionProcesses, getActions, onBannerAction, component: Component } = props;

  const handlers = useContextHandlers();
  const banner = useSelector(bannersBannerByIndexSelector(partition, index));
  const editableBanner = useSelector(bannersBannerToChangeSortIndexSelector(partition));
  const isDisabled = banner ? handlers.isBannerLockedNotByMe(banner) : false;
  const sortIndex = useSelector(bannersSortIndexInfoSelector(partition));

  const isEditable = banner && editableBanner?.id === banner.id;

  const canChangeSortIndex = banner
    ? getActions(banner).find(process => process.type === EBannerActionType.ChangeSortIndex && !process.disabled)
    : null;

  const sortIndexInfo = useMemo(
    () => ({
      max: sortIndex.last + 1,
      fetch: handlers.onFetchLastSortIndex,
      isFetching: isEditable ? sortIndex.isFetching : false,
      isFetched: sortIndex.isFetched,
      isFailed: sortIndex.isFailed,
    }),
    [
      sortIndex.last,
      handlers.onFetchLastSortIndex,
      sortIndex.isFetching,
      sortIndex.isFetched,
      sortIndex.isFailed,
      isEditable,
    ]
  );

  return banner ? (
    <Component
      banner={banner}
      isDisabled={isDisabled}
      activeBanner={hoveredRow ? banner : null}
      editableBanner={editableBanner}
      canChangeSortIndex={canChangeSortIndex ?? null}
      getActionProcesses={getActionProcesses}
      onBannerAction={onBannerAction}
      setEditableBanner={handlers.onSetBannerToChangeSortIndex}
      sortIndexInfo={sortIndexInfo}
    />
  ) : null;
};