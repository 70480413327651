import { SportOptionTyped } from '../../../../../../../domain/model';
import { EPartnerOwnershipType, ETaxationSystemType } from '../../../../../../../domain/model/enums';
import {
  PartnerCompanyDataDraft,
  PartnerDataDraft,
  PartnerIndividualDataDraft,
} from '../../../../../../../domain/model/partner';
import { Nullable } from '../../../../../../../domain/model/types';
import { OnChangeObjectAttribute } from '../../../../../../types';
import { ValidationResult } from '../../../../../../utils/validation';
import { PartnerDetailsCompany, PartnerDetailsIndividual } from '../../../../../general/partner/validation';
import PartnerApplicationEditCompanyDetails from './company';
import PartnerApplicationEditIndividualDetails from './individual';

interface PartnerApplicationEditStepDetailsProps {
  readonly partnerApplication: PartnerDataDraft;
  readonly taxationSystemTypes: SportOptionTyped<ETaxationSystemType>[];
  readonly isValid: boolean;
  readonly validationCompany: Nullable<ValidationResult<PartnerDetailsCompany>>;
  readonly validationIndividual: Nullable<ValidationResult<PartnerDetailsIndividual>>;
  readonly onChangeAttribute: OnChangeObjectAttribute<PartnerDataDraft>;
  readonly onChangeCompanyAttribute: OnChangeObjectAttribute<PartnerCompanyDataDraft>;
  readonly onChangeIndividualAttribute: OnChangeObjectAttribute<PartnerIndividualDataDraft>;
}

const PartnerApplicationEditStepDetails = (props: PartnerApplicationEditStepDetailsProps) => {
  const {
    partnerApplication,
    taxationSystemTypes,
    isValid,
    validationCompany,
    validationIndividual,
    onChangeAttribute,
    onChangeCompanyAttribute,
    onChangeIndividualAttribute,
  } = props;

  const { bic, checkingAccount, taxSystem } = partnerApplication;

  if (!partnerApplication.regInfo?.orgType) {
    return <></>;
  }
  switch (partnerApplication.regInfo?.orgType) {
    case EPartnerOwnershipType.IndividualEntrepreneur:
      return (
        <PartnerApplicationEditIndividualDetails
          bic={bic}
          checkingAccount={checkingAccount}
          ogrnip={partnerApplication.regInfo?.ogrnip ?? null}
          taxSystem={taxSystem}
          taxationSystemTypes={taxationSystemTypes}
          validation={validationIndividual}
          isValid={isValid}
          onChangeAttribute={onChangeAttribute}
          onChangeIndividualAttribute={onChangeIndividualAttribute}
        />
      );
    case EPartnerOwnershipType.StockCompany:
    case EPartnerOwnershipType.PublicJoinStockCompany:
    case EPartnerOwnershipType.PK:
    case EPartnerOwnershipType.UP:
    case EPartnerOwnershipType.FGBNU:
    case EPartnerOwnershipType.NonProfitOrganization:
    case EPartnerOwnershipType.ClosedJoinStockCompany:
    case EPartnerOwnershipType.JoinStockCompany:
    case EPartnerOwnershipType.NAO:
    case EPartnerOwnershipType.AutonomousNonProfitOrganization:
    case EPartnerOwnershipType.LimitedLiabilityCompany: {
      return (
        <PartnerApplicationEditCompanyDetails
          bic={bic}
          checkingAccount={checkingAccount}
          kpp={partnerApplication.regInfo?.kpp ?? null}
          ogrn={partnerApplication.regInfo?.ogrn ?? null}
          taxSystem={taxSystem}
          taxationSystemTypes={taxationSystemTypes}
          validation={validationCompany}
          isValid={isValid}
          onChangeAttribute={onChangeAttribute}
          onChangeCompanyAttribute={onChangeCompanyAttribute}
        />
      );
    }
    default: {
      return <></>;
    }
  }
};

export default PartnerApplicationEditStepDetails;
