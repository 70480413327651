export enum ENetworkErrorCode {
  UserBlocked = 'USER_BLOCKED',
  MissingUserAgreement = 'MISSING_USER_AGREEMENT',
}

export interface ServerError {
  readonly code: string;
  readonly field: string;
  readonly message: string;
}

export interface ServerErrorResponse {
  readonly code: string;
  readonly message: string;
  readonly description: string;
  readonly errors: ServerError[];
}
