import styled from '@emotion/styled';
import { css, Typography, TypographyProps } from '@mui/material';
import { HTMLAttributes } from 'react';
import { viewConfig } from '../../../../theme/viewConfig';
import { EEntityPreviewMode } from '../../../../types';
import { OfferPreviewEllipsis } from '../../../general/offer/components/preview/controls';

export const Title = styled((props: TypographyProps) => <Typography {...props} />)`
  word-break: break-word;
`;
export const Footer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type BookingOfferPreviewWrapperProps = HTMLAttributes<HTMLDivElement> & {
  readonly mode: EEntityPreviewMode;
};

export const BookingOfferPreviewWrapper = styled(({ mode, children, ...props }: BookingOfferPreviewWrapperProps) => (
  <div {...props}>{children}</div>
))(
  ({ mode }) => css`
    width: ${mode === EEntityPreviewMode.Mobile
      ? viewConfig.bookingOffer.preview.mobileWidth
      : viewConfig.bookingOffer.preview.width};
  `
);

type PriceWrapperProps = TypographyProps & {
  readonly mode: EEntityPreviewMode;
};

export const PriceWrapper = styled(({ mode, children, ...props }: PriceWrapperProps) => (
  <OfferPreviewEllipsis {...props}>{children}</OfferPreviewEllipsis>
))(
  ({ mode, theme: { typography, spacing } }) =>
    css`
      display: flex;
      align-items: baseline;
      margin-right: ${spacing(0.5)};

      word-break: break-word;

      font-size: ${mode === EEntityPreviewMode.Mobile ? typography.caption.fontSize : typography.body1.fontSize};
    `
);

type PriceProps = HTMLAttributes<HTMLDivElement> & {
  readonly mode: EEntityPreviewMode;
};
export const Price = styled(({ mode, children, ...props }: PriceProps) => <div {...props}>{children}</div>)(
  ({ mode, theme: { typography, spacing, breakpoints } }) =>
    css`
      display: flex;

      font-size: ${mode === EEntityPreviewMode.Mobile ? typography.subtitle1.fontSize : typography.h3.fontSize};
      font-weight: ${typography.h2.fontWeight};

      margin-left: ${spacing(0.5)};
      margin-right: ${spacing(0.5)};
    `
);
