import { Skeleton, Typography } from '@mui/material';
import { FC } from 'react';
import { Nullable } from '../../../../../domain/model/types';
import AppImage from '../../../../components/common/images/common';
import { formatCost } from '../../../../utils';
import {
  ButtonCart,
  CardContent,
  CardImageContainer,
  OldPriceText,
  PriceContent,
  PriceText,
  SalePercent,
} from './controls';

type CardProductProps = {
  image?: string;
  name: Nullable<string>;
  price?: Nullable<number>;
  salePercent: Nullable<number>;
  originalPrice?: Nullable<number>;
};

export const CardProduct: FC<CardProductProps> = props => {
  const { image, name, originalPrice, price, salePercent } = props;
  return (
    <>
      <CardImageContainer>
        {image ? (
          <AppImage
            src={image}
            alt={name || undefined}
            height={200}
          />
        ) : (
          <Skeleton
            variant='rectangular'
            width={300}
            height={200}
          />
        )}
      </CardImageContainer>

      <CardContent>
        <Typography variant='subtitle2'>{name}</Typography>
        <PriceContent>
          {price ? (
            <PriceText variant={'h3'}>{formatCost(price)}</PriceText>
          ) : (
            <Skeleton
              variant='text'
              width={100}
              height={24}
            />
          )}
          {originalPrice ? (
            <OldPriceText color={'secondary'}>{formatCost(originalPrice)}</OldPriceText>
          ) : (
            <Skeleton
              variant='text'
              width={100}
              height={24}
            />
          )}
          {salePercent && <SalePercent color={'error'}>-{salePercent}%</SalePercent>}
        </PriceContent>

        <ButtonCart>В корзину</ButtonCart>
      </CardContent>
    </>
  );
};
