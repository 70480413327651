import { ESortDirection } from '../../../../domain/model/enums';
import { SportUserProfile } from '../../../../domain/model/user';
import DataTable, { DataTableMetadata } from '../../../components/common/table';
import { DataTableLoader } from '../../../components/common/table/loader';
import SportsmanTableCellActivitySubTypes from '../components/tableCell/activitySubTypes';
import SportsmanTableCellActivityTypes from '../components/tableCell/activityTypes';
import SportsmanTableCellAge from '../components/tableCell/age';
import SportsmanTableCellDepartmentName from '../components/tableCell/departmentName';
import SportsmanTableCellLogo from '../components/tableCell/logo';
import SportsmanTableCellName from '../components/tableCell/name';
import SportsmanTableCellRoad from '../components/tableCell/road';
import { ESportsmanTableColumn } from '../table/utils';
import SportsmansSearchCellAdapter from './adapters/cell';
import { sportsmanSearchIsFetchingSelector } from './store/selectors';

interface SportsmansSearchProps {
  readonly metadata: DataTableMetadata<ESportsmanTableColumn>;
  readonly sportsmen: SportUserProfile[];
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly selectedSportsman: SportUserProfile[];
  readonly isSingleSelect?: boolean;
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onSportsmanClick: (sportsman: SportUserProfile) => void;
  readonly onChangeMetadata: (metadata: DataTableMetadata<ESportsmanTableColumn>) => void;
  readonly onSportsmanSelect: (sportsman: SportUserProfile, selected: boolean) => void;
  readonly onAllSportsmenSelect: (selected: boolean) => void;
}

const SportsmansSearch = (props: SportsmansSearchProps) => {
  const {
    metadata,
    sportsmen,
    sort,
    selectedSportsman,
    isSingleSelect,
    onRequestSort,
    onSportsmanClick,
    onSportsmanSelect,
    onAllSportsmenSelect,
  } = props;

  const rows = sportsmen.map((sportsman, index) => {
    return {
      [ESportsmanTableColumn.Logo]: (
        <SportsmansSearchCellAdapter
          index={index}
          component={SportsmanTableCellLogo}
        />
      ),
      [ESportsmanTableColumn.LastName]: (
        <SportsmansSearchCellAdapter
          index={index}
          component={SportsmanTableCellName}
        />
      ),
      [ESportsmanTableColumn.Age]: (
        <SportsmansSearchCellAdapter
          index={index}
          component={SportsmanTableCellAge}
        />
      ),
      [ESportsmanTableColumn.ActivityTypes]: (
        <SportsmansSearchCellAdapter
          index={index}
          component={SportsmanTableCellActivityTypes}
        />
      ),
      [ESportsmanTableColumn.ActivitySubTypes]: (
        <SportsmansSearchCellAdapter
          index={index}
          component={SportsmanTableCellActivitySubTypes}
        />
      ),
      [ESportsmanTableColumn.DepartmentName]: (
        <SportsmansSearchCellAdapter
          index={index}
          component={SportsmanTableCellDepartmentName}
        />
      ),
      [ESportsmanTableColumn.Road]: (
        <SportsmansSearchCellAdapter
          index={index}
          component={SportsmanTableCellRoad}
        />
      ),
      data: sportsman,
    };
  });

  return (
    <DataTable<SportUserProfile, ESportsmanTableColumn>
      metadata={metadata}
      width='auto'
      overflowX='inherit'
      rows={rows}
      sort={{ column: sort.column, direction: sort.direction }}
      loader={<DataTableLoader selector={sportsmanSearchIsFetchingSelector} />}
      selectable
      singleSelect={isSingleSelect}
      selected={selectedSportsman}
      onSort={(event, column, direction) => onRequestSort(column, direction)}
      onRowClick={(event, cell, { data }) => onSportsmanClick(data)}
      onAllRowsSelect={(event, selected) => onAllSportsmenSelect(selected)}
      onRowSelect={(event, { data }, selected) => onSportsmanSelect(data, selected)}
    />
  );
};
export default SportsmansSearch;
