import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../data/store/types';
import { GlobalNotificationOption } from '../../../../../domain/model/notification';
import { Nullable, UUID } from '../../../../../domain/model/types';

export const configurationPrivilegeNotificationsFetch = createAsyncThunk<
  GlobalNotificationOption[],
  undefined,
  AppThunkAPIConfig
>('configuration/privilege/notifications/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { data } = await Api.notification.globalOptions({ signal });

    return data.sort((a, b) => a.name.localeCompare(b.name));
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);

    return rejectWithValue(e.response.data);
  }
});

export const configurationPrivilegeNotificationUpdate = createAsyncThunk<
  GlobalNotificationOption,
  { id: UUID; enabled: boolean },
  AppThunkAPIConfig
>('configuration/privilege/notifications/update', async ({ id, enabled }, { rejectWithValue }) => {
  try {
    const { data } = await Api.notification.updateGlobalOptions({ id, enabled });
    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface ConfigurationPrivilegeNotificationsState {
  readonly guid: Nullable<UUID>;
  readonly notifications: Fetchable & {
    readonly data: Nullable<GlobalNotificationOption[]>;
  };
  readonly update: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<ConfigurationPrivilegeNotificationsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ConfigurationPrivilegeNotificationsState> {
  configurationPrivilegeNotificationsStartSession: Reducer<{ guid: UUID }>;
}

const slice = createSlice<ConfigurationPrivilegeNotificationsState, Reducers, 'privilege'>({
  name: 'privilege',
  initialState: {
    guid: null,
    notifications: {
      ...fetchableDefault,
      data: null,
    },
    update: {
      ...fetchableDefault,
    },
  },
  reducers: {
    configurationPrivilegeNotificationsStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;

        state.notifications = {
          ...fetchableDefault,
          data: null,
        };

        state.update = {
          ...fetchableDefault,
        };
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(configurationPrivilegeNotificationsFetch.pending, state => {
        state.notifications.isFetching = true;
        state.notifications.isFetched = false;
        state.notifications.isFailed = false;

        state.notifications.data = null;
      })
      .addCase(configurationPrivilegeNotificationsFetch.fulfilled, (state, { payload }) => {
        state.notifications.isFetching = false;
        state.notifications.isFetched = true;
        state.notifications.isFailed = false;

        state.notifications.data = payload;
      })
      .addCase(configurationPrivilegeNotificationsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.notifications.isFetching = false;
          state.notifications.isFetched = false;
          state.notifications.isFailed = true;
        } else {
          state.notifications.isFetching = false;
          state.notifications.isFetched = false;
          state.notifications.isFailed = false;
        }

        state.notifications.data = null;
      })

      .addCase(configurationPrivilegeNotificationUpdate.pending, (state, { meta }) => {
        const { id, enabled } = meta.arg;

        const existedNotificationSetting = state.notifications.data!.find(item => item.id === id);

        if (existedNotificationSetting) {
          existedNotificationSetting.enabled = enabled;
        }

        state.update = fetchableFetching;
      })
      .addCase(configurationPrivilegeNotificationUpdate.fulfilled, state => {
        state.update = fetchableFetched;
      })
      .addCase(configurationPrivilegeNotificationUpdate.rejected, state => {
        state.update = fetchableFailed;
      });
  },
});

export const { configurationPrivilegeNotificationsStartSession } = slice.actions;

export default slice.reducer;
