import CmsContainerTableCell, { CmsContainerTableCellCommonProps } from './index';

type CmsContainerTableCellTitleProps = CmsContainerTableCellCommonProps;

const CmsContainerTableCellTitle = (props: CmsContainerTableCellTitleProps) => {
  const {
    cmsContainer: { title },
  } = props;

  const value = title ?? '-';

  return (
    <CmsContainerTableCell
      {...props}
      value={value}
    />
  );
};

export default CmsContainerTableCellTitle;
