import React from 'react';
import { UseCorpActivationTable } from './hooks/useCorpActivationTable';
import { UseCorpActivationTableConfig } from './hooks/useTableConfig';

/**
 * Контекст для обработчиков
 */
export type CorpActivationTableContextValue = UseCorpActivationTable;
export const CorpActivationTableContext = React.createContext<CorpActivationTableContextValue>(
  {} as CorpActivationTableContextValue
);

/**
 * Контекст для конфига
 */
export type CorpActivationTableConfigContextValue = UseCorpActivationTableConfig;
export const CorpActivationTableConfigContext = React.createContext<CorpActivationTableConfigContextValue>(
  {} as CorpActivationTableConfigContextValue
);
