import { Snackbar as MuiSnackbar } from '@mui/material';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  z-index: 99999;

  position: fixed;
  left: auto;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
`;
export const Snackbar = styled(MuiSnackbar)`
  position: relative;

  margin-top: ${p => p.theme.spacing(2)};
`;
