import { getUserFullName } from '../../../../utils';
import CorpActivationTableCell, { CorpActivationTableCellCommonProps } from './index';

const CorpActivationTableCellCustomerName = (props: CorpActivationTableCellCommonProps) => {
  const {
    corpActivation: { customer },
  } = props;

  return (
    <CorpActivationTableCell
      {...props}
      value={customer && getUserFullName({ user: customer })}
    />
  );
};

export default CorpActivationTableCellCustomerName;
