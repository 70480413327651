import { CheckBox, CheckBoxOutlineBlank, ChevronRight, IndeterminateCheckBox } from '@mui/icons-material';
import { FormControlLabel, Typography } from '@mui/material';
import { RootItemWrapper } from './controls';
import { ActivityTypeSelectorItemProps } from './types';

type ActivityTypeSelectorRootItemProps = ActivityTypeSelectorItemProps & {
  readonly expanded?: boolean;
  readonly withChildren?: boolean;
  readonly onExpand: (expanded: boolean) => void;
};

type ItemExpandProps = {
  readonly expanded?: boolean;
  readonly onExpand: (expanded: boolean) => void;
};

const ItemExpand = (props: ItemExpandProps) => {
  const { expanded, onExpand } = props;

  return (
    <ChevronRight
      color='secondary'
      fontSize='small'
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        onExpand(!expanded);
      }}
    />
  );
};

export const ActivityTypeSelectorRootItem = (props: ActivityTypeSelectorRootItemProps) => {
  const { selected, children, expanded, indeterminate, withChildren, onChange, onExpand } = props;

  let control;
  if (selected) {
    control = <CheckBox color='primary' />;
  } else {
    control = <CheckBoxOutlineBlank color='secondary' />;
  }
  if (indeterminate) {
    control = <IndeterminateCheckBox color='primary' />;
  }

  return (
    <RootItemWrapper
      expanded={expanded}
      onClick={() => onChange(!selected)}
    >
      {withChildren && (
        <ItemExpand
          expanded={expanded}
          onExpand={onExpand}
        />
      )}
      <FormControlLabel
        control={control}
        label={<Typography>{children}</Typography>}
      />
    </RootItemWrapper>
  );
};
