import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import ForbiddenComponent from '../../components/common/forbidden';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { viewShowMainMenu } from '../../features/main/container/store/slice';
import { AdminMpsLocationState, EAdminMpUrlParam } from '../../features/user/adminMp/entry';
import AdminMpTableEntry from '../../features/user/adminMp/table/entry';
import { EAdminMpTableTab } from '../../features/user/adminMp/table/utils';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const AdminsMpScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { adminsMp } = useCurrentUser().accessMatrix;

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(EAdminMpUrlParam.Tab) as EAdminMpTableTab;

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (!adminsMp?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<AdminMpsLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <AdminMpTableEntry
          guid={state.guid}
          tab={tab}
        />
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default AdminsMpScreen;
