import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UUID } from '../../../../../domain/model/types';
import { productsGuidSelector } from '../store/selectors';
import { productsSetTab, productsStartSession } from '../store/slice';
import { EProductTableTab } from '../utils';

interface UseProductTableSessionProps {
  readonly guid: UUID;
  readonly tab: EProductTableTab;
}

const useProductTableSession = (props: UseProductTableSessionProps): void => {
  const { guid, tab } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(productsGuidSelector);

  // начало сеанса
  useEffect(() => {
    if (guid !== currentGuid) {
      dispatch(productsStartSession({ guid }));
    }
  }, [dispatch, guid, currentGuid]);

  // начальная загрузка, изменение вкладки
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(productsSetTab({ tab }));
    }
  }, [dispatch, guid, currentGuid, tab]);
};

export default useProductTableSession;
