import { useContext } from 'react';
import CurrentUserContext, { CurrentUserContextType } from '../current/provider/context';

type UseCurrentUser = () => CurrentUserContextType;

const useCurrentUser: UseCurrentUser = () => {
  return useContext<CurrentUserContextType>(CurrentUserContext);
};

export default useCurrentUser;
