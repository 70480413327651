import {
  Address,
  ClientOrg,
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterStrategyView,
  DataFilterValueItem,
  EDataFilterType,
  EDateFormat,
  EFilterMultiSelectorValueType,
  ETradeOfferType,
  EUserGender,
  FilterMultiSelectorPredicate,
  Nullable,
  pageSizeAll,
  SportOptionTyped,
  Target,
} from '@/domain';
import { formatCost, formatNumberRange } from '@/presentation/utils';
import moment from 'moment';
import Api from '../../../data/api';
import { AddressHelper } from '../../utils/address';
import { getDataFilterTargetPreviews } from '../../utils/filtering';
import { ECorpOfferTableFilterItem } from '../corpOffer/filterUtils';
import { ETradeOfferTableColumn } from './types';

export enum ETradeOfferTableFilterItem {
  Query = 'query',
  Code = 'code',
  Name = 'name',
  LastStatusDate = 'lastStatusDate',
  Partner = 'partnerDesk.partner.id',
  PromotionType = 'promotionType',
  StartDate = 'startDate',
  EndDate = 'endDate',
  NotUsedOfferCount = 'notUsedOfferCount',
  OfferCount = 'offerCount',
  ApprovingAdmin = 'approvingAdmin.id',
  TargetLocalities = 'places',
  TargetExternalUsers = 'targetExternalUsers',
  TargetClientOrgs = 'targetClientOrgs',
  Target = 'target',
  AggregatorIntegrationId = 'aggregatorIntegrationId',
  Price = 'price',
}

export const tradeOfferTableFilterItems: Record<ETradeOfferTableColumn, ETradeOfferTableFilterItem[]> = {
  [ETradeOfferTableColumn.Code]: [ETradeOfferTableFilterItem.Code],
  [ETradeOfferTableColumn.Name]: [ETradeOfferTableFilterItem.Name, ETradeOfferTableFilterItem.Target],
  [ETradeOfferTableColumn.Partner]: [ETradeOfferTableFilterItem.Partner],
  [ETradeOfferTableColumn.Price]: [ETradeOfferTableFilterItem.Price],
  [ETradeOfferTableColumn.Period]: [ETradeOfferTableFilterItem.StartDate, ETradeOfferTableFilterItem.EndDate],
  [ETradeOfferTableColumn.OfferCounts]: [
    ETradeOfferTableFilterItem.NotUsedOfferCount,
    ETradeOfferTableFilterItem.OfferCount,
  ],
  [ETradeOfferTableColumn.ApprovingAdmin]: [ETradeOfferTableFilterItem.ApprovingAdmin],
  [ETradeOfferTableColumn.PromotionType]: [ETradeOfferTableFilterItem.PromotionType],
  [ETradeOfferTableColumn.Status]: [],
  [ETradeOfferTableColumn.LastStatusDate]: [],
  [ETradeOfferTableColumn.LastStatusAuthor]: [],
  [ETradeOfferTableColumn.PausedReason]: [],
  [ETradeOfferTableColumn.TargetLocalities]: [ETradeOfferTableFilterItem.TargetLocalities],
  [ETradeOfferTableColumn.TargetClientOrgs]: [ETradeOfferTableFilterItem.TargetClientOrgs],
  [ETradeOfferTableColumn.TargetExternalUsers]: [ETradeOfferTableFilterItem.TargetExternalUsers],
  [ETradeOfferTableColumn.AggregatorIntegrationId]: [ETradeOfferTableFilterItem.AggregatorIntegrationId],
};

export type TradeOfferTableFilterEditStrategy = DataFilterStrategyBase<ETradeOfferTableFilterItem>;
export type TradeOfferTableFilterViewStrategy = DataFilterStrategyView<ETradeOfferTableFilterItem>;
export type TradeOfferTableFilterValues = {
  readonly [ETradeOfferTableFilterItem.Query]?: DataFilterValueItem<Nullable<string>>;
  readonly [ETradeOfferTableFilterItem.Code]?: DataFilterValueItem<Nullable<string>>;
  readonly [ETradeOfferTableFilterItem.Name]?: DataFilterValueItem<Nullable<string>>;
  readonly [ETradeOfferTableFilterItem.LastStatusDate]?: DataFilterValueItem<Nullable<[string, string]>>;
  readonly [ETradeOfferTableFilterItem.Partner]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
  readonly [ETradeOfferTableFilterItem.PromotionType]?: DataFilterValueItem<Nullable<string[]>>;
  readonly [ETradeOfferTableFilterItem.StartDate]?: DataFilterValueItem<Nullable<string>>;
  readonly [ETradeOfferTableFilterItem.EndDate]?: DataFilterValueItem<Nullable<string>>;
  readonly [ETradeOfferTableFilterItem.NotUsedOfferCount]?: DataFilterValueItem<Nullable<number>>;
  readonly [ETradeOfferTableFilterItem.OfferCount]?: DataFilterValueItem<Nullable<number>>;
  readonly [ETradeOfferTableFilterItem.ApprovingAdmin]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
  readonly [ETradeOfferTableFilterItem.TargetLocalities]?: DataFilterValueItem<
    Nullable<FilterMultiSelectorPredicate<Address>>
  >;
  readonly [ETradeOfferTableFilterItem.TargetClientOrgs]?: DataFilterValueItem<
    Nullable<FilterMultiSelectorPredicate<ClientOrg>>
  >;
  readonly [ETradeOfferTableFilterItem.TargetExternalUsers]?: DataFilterValueItem<Nullable<true>>;
  readonly [ECorpOfferTableFilterItem.Target]?: DataFilterValueItem<Nullable<Target>>;
  readonly [ETradeOfferTableFilterItem.AggregatorIntegrationId]?: DataFilterValueItem<Nullable<string>>;
  readonly [ETradeOfferTableFilterItem.Price]?: DataFilterValueItem<Nullable<[Nullable<number>, Nullable<number>]>>;
};

const getPlacesPreview = (places: Nullable<FilterMultiSelectorPredicate<Address>>) => {
  if (!places) return null;

  switch (places.select) {
    case EFilterMultiSelectorValueType.All:
      return 'Вся Россия';
    case EFilterMultiSelectorValueType.None:
      return 'Город не выбран';
    case EFilterMultiSelectorValueType.In:
      return `Город (${places.in?.length})` || null;
  }
};

const getClientOrgsPreview = (clientOrgs: Nullable<FilterMultiSelectorPredicate<ClientOrg>>) => {
  if (!clientOrgs) return null;

  switch (clientOrgs.select) {
    case EFilterMultiSelectorValueType.All:
      return 'Все компании';
    case EFilterMultiSelectorValueType.None:
      return 'Компания не выбрана';
    case EFilterMultiSelectorValueType.In:
      return `Компания (${clientOrgs.in?.length})` || null;
  }
};

const getPricePreview = (price: TradeOfferTableFilterValues[ETradeOfferTableFilterItem.Price]): Nullable<string> => {
  const range = formatNumberRange(price?.value?.[0] ?? null, price?.value?.[1] ?? null, formatCost);
  return range ? 'Стоимость ' + range : null;
};

export const getTradeOfferTableFilterStrategy = (
  filterItem: ETradeOfferTableFilterItem,
  values: TradeOfferTableFilterValues,
  promotionTypes: SportOptionTyped<ETradeOfferType>[],
  genderTypes: SportOptionTyped<EUserGender>[]
): Nullable<TradeOfferTableFilterEditStrategy> => {
  switch (filterItem) {
    case ETradeOfferTableFilterItem.Target:
      return {
        type: EDataFilterType.Target,
        key: ETradeOfferTableFilterItem.Target,
        label: 'Доступность',
        preview: 'Доступность *',
        previews:
          getDataFilterTargetPreviews(values[ETradeOfferTableFilterItem.Target]?.value ?? null, {
            genderTypes,
          }) ?? [],
        value: values[ETradeOfferTableFilterItem.Target]?.value || null,
      };
    case ETradeOfferTableFilterItem.Query:
      return {
        type: EDataFilterType.String,
        key: ETradeOfferTableFilterItem.Query,
        label: 'Поиск по ключевому слову',
        preview: values[ETradeOfferTableFilterItem.Query]?.value || null,
        value: values[ETradeOfferTableFilterItem.Query]?.value || null,
      };
    case ETradeOfferTableFilterItem.LastStatusDate:
      return {
        type: EDataFilterType.DateAsTimePeriod,
        key: ETradeOfferTableFilterItem.LastStatusDate,
        label: 'Дата',
        preview:
          moment(values[ETradeOfferTableFilterItem.LastStatusDate]?.value?.[0]).format(EDateFormat.DisplayDefault) ||
          null,
        value: values[ETradeOfferTableFilterItem.LastStatusDate]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Between,
        },
      };
    case ETradeOfferTableFilterItem.Code:
      return {
        type: EDataFilterType.String,
        key: ETradeOfferTableFilterItem.Code,
        label: 'Код ТП',
        preview: values[ETradeOfferTableFilterItem.Code]?.value || null,
        value: values[ETradeOfferTableFilterItem.Code]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ETradeOfferTableFilterItem.Name:
      return {
        type: EDataFilterType.String,
        key: ETradeOfferTableFilterItem.Name,
        label: 'Название',
        preview: values[ETradeOfferTableFilterItem.Name]?.value || null,
        value: values[ETradeOfferTableFilterItem.Name]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ETradeOfferTableFilterItem.Partner:
      return {
        type: EDataFilterType.AutocompleteMultiple,
        key: ETradeOfferTableFilterItem.Partner,
        label: 'Партнёр',
        value: values[ETradeOfferTableFilterItem.Partner]?.value || null,
        preview: `Партнер (${values[ETradeOfferTableFilterItem.Partner]?.value?.length})` || null,
        previews: values[ETradeOfferTableFilterItem.Partner]?.value?.map(t => t.name) || null,
        source: (name: string) =>
          name
            ? Api.partner
                .all({
                  query: name,
                  sort: 'name,asc',
                  page: 1,
                  pageSize: pageSizeAll,
                })
                .then(response => response.data)
            : Promise.resolve([]),
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
          valueAttribute: 'id',
        },
      };
    case ETradeOfferTableFilterItem.PromotionType:
      return {
        type: EDataFilterType.ListMultiple,
        key: ETradeOfferTableFilterItem.PromotionType,
        label: 'Тип предложения',
        preview: `Тип предложения (${values[ETradeOfferTableFilterItem.PromotionType]?.value?.length})`,
        previews: promotionTypes
          .filter(t => values[ETradeOfferTableFilterItem.PromotionType]?.value?.some(v => v === t.id))
          .map(t => t.name),
        value: values[ETradeOfferTableFilterItem.PromotionType]?.value || null,
        items: promotionTypes.map(t => ({ id: t.id, name: t.name })),
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
        },
      };
    case ETradeOfferTableFilterItem.StartDate:
      return {
        type: EDataFilterType.DateFrom,
        key: ETradeOfferTableFilterItem.StartDate,
        group: 'date',
        label: 'Начало',
        preview: values[ETradeOfferTableFilterItem.StartDate]?.value
          ? `с ${moment(values[ETradeOfferTableFilterItem.StartDate]?.value).format(EDateFormat.DisplayDefault)}`
          : null,
        value: values[ETradeOfferTableFilterItem.StartDate]?.value ?? null,
        querydsl: {
          operator: DataFilterQueryDslOperator.MoreOrEquals,
        },
      };
    case ETradeOfferTableFilterItem.EndDate:
      return {
        type: EDataFilterType.DateTo,
        key: ETradeOfferTableFilterItem.EndDate,
        group: 'date',
        label: 'Окончание',
        preview: values[ETradeOfferTableFilterItem.EndDate]?.value
          ? `по ${moment(values[ETradeOfferTableFilterItem.EndDate]?.value).format(EDateFormat.DisplayDefault)}`
          : null,
        value: values[ETradeOfferTableFilterItem.EndDate]?.value ?? null,
        querydsl: {
          operator: DataFilterQueryDslOperator.LessOrEquals,
        },
      };
    case ETradeOfferTableFilterItem.NotUsedOfferCount:
      return {
        type: EDataFilterType.Number,
        key: ETradeOfferTableFilterItem.NotUsedOfferCount,
        label: 'Доступно кодов',
        preview: `Доступно кодов ${values[ETradeOfferTableFilterItem.NotUsedOfferCount]?.value?.toString()}`,
        value: values[ETradeOfferTableFilterItem.NotUsedOfferCount]?.value ?? null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Equals,
        },
      };
    case ETradeOfferTableFilterItem.OfferCount:
      return {
        type: EDataFilterType.Number,
        key: ETradeOfferTableFilterItem.OfferCount,
        label: 'Всего кодов',
        preview: `Всего кодов ${values[ETradeOfferTableFilterItem.OfferCount]?.value?.toString()}`,
        value: values[ETradeOfferTableFilterItem.OfferCount]?.value ?? null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Equals,
        },
      };
    case ETradeOfferTableFilterItem.TargetLocalities:
      return {
        type: EDataFilterType.MultiSelectorPredicateAddress,
        key: ETradeOfferTableFilterItem.TargetLocalities,
        label: 'Город',
        value: values[ETradeOfferTableFilterItem.TargetLocalities]?.value || null,
        preview: getPlacesPreview(values[ETradeOfferTableFilterItem.TargetLocalities]?.value ?? null),
        previews:
          values[ETradeOfferTableFilterItem.TargetLocalities]?.value?.in?.map(
            locality => new AddressHelper(locality).getLastLocalityShortPath() ?? locality.name
          ) ?? null,
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
          valueAttribute: 'id',
        },
      };
    case ETradeOfferTableFilterItem.TargetClientOrgs:
      return {
        type: EDataFilterType.MultiSelectorPredicateClientOrg,
        key: ETradeOfferTableFilterItem.TargetClientOrgs,
        label: 'Компания',
        value: values[ETradeOfferTableFilterItem.TargetClientOrgs]?.value || null,
        preview: getClientOrgsPreview(values[ETradeOfferTableFilterItem.TargetClientOrgs]?.value ?? null),
        previews:
          values[ETradeOfferTableFilterItem.TargetClientOrgs]?.value?.in?.map(clientOrg => clientOrg.name) ?? null,
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
          valueAttribute: 'id',
        },
      };
    case ETradeOfferTableFilterItem.TargetExternalUsers:
      return {
        type: EDataFilterType.BooleanFlag,
        key: ETradeOfferTableFilterItem.TargetExternalUsers,
        label: 'Для внешних пользователей',
        preview: values[ETradeOfferTableFilterItem.TargetExternalUsers]?.value ? 'Для внешних пользователей' : null,
        value: values[ETradeOfferTableFilterItem.TargetExternalUsers]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Equals,
        },
      };
    case ETradeOfferTableFilterItem.AggregatorIntegrationId:
      return {
        type: EDataFilterType.String,
        key: ETradeOfferTableFilterItem.AggregatorIntegrationId,
        label: 'ID интеграции',
        preview: values[ETradeOfferTableFilterItem.AggregatorIntegrationId]?.value || null,
        value: values[ETradeOfferTableFilterItem.AggregatorIntegrationId]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ETradeOfferTableFilterItem.Price:
      return {
        type: EDataFilterType.NumberPeriod,
        key: ETradeOfferTableFilterItem.Price,
        label: '',
        labelPeriod: {
          from: 'Стоимость от',
          to: 'до',
        },
        preview: getPricePreview(values[ETradeOfferTableFilterItem.Price]),
        value: values[ETradeOfferTableFilterItem.Price]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Between,
        },
      };
    default:
      return null;
  }
};
