import { Grid } from '@mui/material';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../data/store/store';
import { PartnerShort } from '../../../../domain/model/partner';
import TablePagination from '../../../components/common/table/pagination';
import { MPButton } from '../../../theme/ui-kit/button';
import { PaginationSize } from '../../../types';
import { FooterActionsWrapper } from './controls';
import { partnersSearchGetSelector, partnersSearchSelectedSelector } from './store/selectors';
import { partnersSearchSetPage, partnersSearchSetPageSize } from './store/slice';

interface PartnerSearchFooterContainerProps {
  readonly maxCount?: number;
  readonly onSelect: (offers: PartnerShort[]) => void;
}

const PartnerSearchFooterContainer = forwardRef((props: PartnerSearchFooterContainerProps, ref: any) => {
  const { maxCount, onSelect } = props;

  const dispatch = useAppDispatch();

  const { totalCount, pageCount, pageNumber, search, selected: partners } = useSelector(partnersSearchGetSelector);

  const selectedPartners = useSelector(partnersSearchSelectedSelector);

  const onChangePage = (num: number) => {
    dispatch(partnersSearchSetPage({ pageNumber: num }));
  };

  const onChangePageSize = (size: PaginationSize) => {
    dispatch(partnersSearchSetPageSize({ pageSize: size }));
  };

  let buttonText = `Выбрать ${selectedPartners.length}`;

  if (maxCount) {
    buttonText += ` из ${maxCount}`;
  }

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='партнёров предложений'
        onChangePageSize={onChangePageSize}
        onChangePage={onChangePage}
      />
      <FooterActionsWrapper>
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <MPButton
              disabled={selectedPartners.length === 0}
              onClick={() => onSelect(partners)}
            >
              {buttonText}
            </MPButton>
          </Grid>
        </Grid>
      </FooterActionsWrapper>
    </div>
  );
});

export default PartnerSearchFooterContainer;
