import SportsmanTableCell, { SportsmanTableCellCommonProps } from './index';

const SportsmanTableCellName = (props: SportsmanTableCellCommonProps) => {
  const { sportsman } = props;

  return (
    <SportsmanTableCell
      {...props}
      value={[sportsman.lastName, sportsman.firstName].filter(item => !!item).join(' ')}
    />
  );
};

export default SportsmanTableCellName;
