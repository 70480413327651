import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { PartnerView, TradeOffer } from '@/domain';
import { TradeOfferDetailsUiState } from '@features/tradeOffer/details/types';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import offerServices from '../../../general/offer/services';

export const tradeOfferDetailsViewed = createAsyncThunk<void, UUID, AppThunkAPIConfig>(
  'tradeOffer/details/viewed',
  async id => {
    try {
      await offerServices.common.makeViewed({ id });
    } catch (e: any) {
      console.error(`Error at call user event`, e);
    }
  }
);

export const tradeOfferDetailsByIdFetch = createAsyncThunk<
  { tradeOffer: TradeOffer; partner: PartnerView },
  { id: UUID },
  AppThunkAPIConfig
>('tradeOffer/details/byId/fetch', async ({ id }, { rejectWithValue, dispatch }) => {
  try {
    const tradeOffer = await offerServices.trade.one({ id });
    dispatch(tradeOfferDetailsViewed(id));
    return { tradeOffer, partner: tradeOffer.partner };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

// TODO одинаково с tradeOfferDetailsById кроме tradeOfferDetailsViewed
// partner все равно вытаскивается, подумать как оставить минимум, это и в handlers заходит тоже
export const tradeOfferDetailsPromotionsCountFetch = createAsyncThunk<TradeOffer, { id: UUID }, AppThunkAPIConfig>(
  'tradeOffer/details/promotionsCount/fetch',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await offerServices.trade.one({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface TradeOfferDetailsState {
  readonly byId: Fetchable & {
    readonly tradeOffer: Nullable<TradeOffer>;
    readonly partner: Nullable<PartnerView>;
  };
  readonly ui: Nullable<TradeOfferDetailsUiState>;
  readonly dialogs: {
    readonly history: Nullable<TradeOffer>;
  };
}

type Reducer<T = undefined> = CaseReducer<TradeOfferDetailsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<TradeOfferDetailsState> {
  tradeOfferDetailsStateReset: Reducer;
  tradeOfferDetailsSetUiState: Reducer<{
    name: keyof TradeOfferDetailsUiState;
    value: any;
  }>;
  tradeOfferDetailsSetDialogState: Reducer<{
    name: keyof TradeOfferDetailsState['dialogs'];
    data: Nullable<TradeOffer>;
  }>;
}

const slice = createSlice<TradeOfferDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    byId: {
      ...fetchableDefault,
      tradeOffer: null,
      partner: null,
    },
    ui: null,
    dialogs: { history: null },
  },
  reducers: {
    tradeOfferDetailsStateReset: state => {
      state.byId = {
        ...fetchableDefault,
        tradeOffer: null,
        partner: null,
      };
      state.ui = null;
      state.dialogs = { history: null };
    },
    tradeOfferDetailsSetUiState: (state, { payload }) => {
      const { name, value } = payload;
      if (!state.ui) {
        state.ui = {
          steps: [],
        };
      }
      state.ui[name] = value;
    },
    tradeOfferDetailsSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(tradeOfferDetailsByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.tradeOffer = null;
      })
      .addCase(tradeOfferDetailsByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.tradeOffer = payload.tradeOffer;
        state.byId.partner = payload.partner;
      })
      .addCase(tradeOfferDetailsByIdFetch.rejected, state => {
        state.byId.isFetching = false;
        state.byId.isFetched = false;
        state.byId.isFailed = true;
        state.byId.tradeOffer = null;
        state.byId.partner = null;
      })
      .addCase(tradeOfferDetailsPromotionsCountFetch.fulfilled, (state, { payload }) => {
        if (state.byId.tradeOffer) {
          state.byId.tradeOffer.offerCount = payload.offerCount;
          state.byId.tradeOffer.notUsedOfferCount = payload.notUsedOfferCount;
        }
      });
  },
});

export const {
  tradeOfferDetailsStateReset,
  tradeOfferDetailsSetUiState,
  tradeOfferDetailsSetDialogState,
} = slice.actions;

export default slice.reducer;
