import color from './color';
import typography from './typography';

const fontUrl = 'https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap';

export const brandbook = {
  typography,
  color,
  fontUrl
};
