import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PartnerEmployeeTableCellOwnerProps } from '../../components/tableCell/owner';
import { useContextConfig } from '../hooks/useContextConfig';
import { partnerEmployeesEmployeeByIndexSelector } from '../store/selectors';
import { PartnerEmployeeTableCellAdapterProps } from './cell';

type PartnerEmployeeTableCellOwnerAdapterProps = Omit<PartnerEmployeeTableCellAdapterProps, 'component'> & {
  readonly component: FC<PartnerEmployeeTableCellOwnerProps>;
};

const PartnerEmployeeTableCellOwnerAdapter = (props: PartnerEmployeeTableCellOwnerAdapterProps) => {
  const { index, component: Component } = props;

  const { partnerOwner } = useContextConfig();
  const employee = useSelector(partnerEmployeesEmployeeByIndexSelector(index));

  return useMemo(
    () =>
      employee ? (
        <Component
          employee={employee}
          owner={partnerOwner}
        />
      ) : null,
    [Component, employee, partnerOwner]
  );
};

export default PartnerEmployeeTableCellOwnerAdapter;
