import { FCC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdCampaignCreateContainerProps } from './container';
import { adCampaignCreateGuidSelector } from './store/selectors';
import {
  adCampaignCreateByIdFetch,
  adCampaignCreateClearActionsState,
  adCampaignCreateStartSession,
} from './store/slice';

type AdCampaignCreateInitializerProps = AdCampaignCreateContainerProps;

export const AdCampaignCreateInitializer: FCC<AdCampaignCreateInitializerProps> = ({ guid, id, children }) => {
  const dispatch = useDispatch();

  const currentGuid = useSelector(adCampaignCreateGuidSelector);

  useEffect(() => {
    // При первой загрузке мы инициализируем данные по умолчанию
    if (guid !== currentGuid) {
      dispatch(adCampaignCreateStartSession({ guid }));
    }
  }, [dispatch, guid, currentGuid]);

  //загрузка основных данных
  useEffect(() => {
    if (guid === currentGuid) {
      if (id) {
        const request = dispatch(adCampaignCreateByIdFetch(id));

        return () => {
          request.abort();
        };
      }
    }
  }, [dispatch, id, guid, currentGuid]);

  useEffect(() => {
    return () => {
      dispatch(adCampaignCreateClearActionsState());
    };
  }, [dispatch]);

  if (guid !== currentGuid) {
    return null;
  }

  return <>{children}</>;
};
