import { SearchQueryStat } from '@/domain/model/analytics';
import { ESearchQueryStatDiscriminator, ESortDirection } from '@/domain/model/enums';
import DataTableCellTextFormat from '@/presentation/components/common/table/cell/textFormat';
import { DataTableLoader } from '@components/common/table/loader';
import AnalyticsQueriesTableCellGeo from '@features/analytics/query/components/tableCell/geo';
import { v4 as uuidv4 } from 'uuid';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../../components/common/table';
import AnalyticsQueriesTableCellCount from '../components/tableCell/count';
import AnalyticsQueriesTableCellQuery from '../components/tableCell/query';
import { AnalyticsQueriesTableCellAdapter } from './adapters';
import { analyticsQueriesIsFetchingSelector } from './store/selectors';
import { EAnalyticsQueriesTableColumn } from './utils';

interface AnalyticsQueriesTableProps {
  readonly metadata: DataTableMetadata<EAnalyticsQueriesTableColumn>;
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly queries: SearchQueryStat[];
  readonly count: Nullable<number>;
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onChangeMetadata?: (metadata: DataTableMetadata<EAnalyticsQueriesTableColumn>) => void;
}

export const AnalyticsQueriesTable = (props: AnalyticsQueriesTableProps) => {
  const { queries, count, sort, metadata, onChangeMetadata, onRequestSort } = props;

  const countRow: Nullable<DataTableRow<SearchQueryStat, EAnalyticsQueriesTableColumn>> =
    count !== null
      ? {
          [EAnalyticsQueriesTableColumn.Query]: <DataTableCellTextFormat bold>Итого</DataTableCellTextFormat>,
          [EAnalyticsQueriesTableColumn.Geo]: <DataTableCellTextFormat bold>Итого</DataTableCellTextFormat>,
          [EAnalyticsQueriesTableColumn.Count]: <DataTableCellTextFormat bold>{count}</DataTableCellTextFormat>,
          data: {
            id: uuidv4(),
            discriminator: ESearchQueryStatDiscriminator.Keyword,
            query: '',
            count,
          },
        }
      : null;

  const dataRows: DataTableRow<SearchQueryStat, EAnalyticsQueriesTableColumn>[] = queries.map((query, index) => {
    return {
      [EAnalyticsQueriesTableColumn.Query]: (
        <AnalyticsQueriesTableCellAdapter
          index={index}
          component={AnalyticsQueriesTableCellQuery}
        />
      ),
      [EAnalyticsQueriesTableColumn.Geo]: (
        <AnalyticsQueriesTableCellAdapter
          index={index}
          component={AnalyticsQueriesTableCellGeo}
        />
      ),
      [EAnalyticsQueriesTableColumn.Count]: (
        <AnalyticsQueriesTableCellAdapter
          index={index}
          component={AnalyticsQueriesTableCellCount}
        />
      ),
      data: query,
    };
  });

  const rows: DataTableRow<SearchQueryStat, EAnalyticsQueriesTableColumn>[] =
    countRow === null ? dataRows : [countRow].concat(dataRows);

  return (
    <DataTable<SearchQueryStat, EAnalyticsQueriesTableColumn>
      width='auto'
      overflowX='inherit'
      metadata={metadata}
      onChangeMetadata={onChangeMetadata}
      rows={rows}
      sort={{ column: sort.column, direction: sort.direction }}
      loader={<DataTableLoader selector={analyticsQueriesIsFetchingSelector} />}
      onSort={(event, column, direction) => onRequestSort(column, direction)}
    />
  );
};
