import { ClientOrg } from '@/domain';
import React from 'react';
import { PanelAction } from '../../../types';
import { ClientOrgLifeCycle, ClientOrgLifeCycleRules } from '../lifecycle/types';
import { ClientOrgActionDetailsType, ClientOrgActions } from '../types';
import { ClientOrgDetailsContainerProps } from './container';

export type ClientOrgDetailsParamsContextValue = ClientOrgDetailsContainerProps;

export const ClientOrgDetailsParamsContext = React.createContext<ClientOrgDetailsParamsContextValue>(
  {} as ClientOrgDetailsParamsContextValue
);

export type ClientOrgDetailsLifecycleContextValue = {
  readonly lifecycle: ClientOrgLifeCycle;
  readonly lifeCycleRules: ClientOrgLifeCycleRules;
  readonly actions: ClientOrgActions<ClientOrgActionDetailsType>;
  readonly onAction: (action: PanelAction<ClientOrgActionDetailsType>) => void;
};

export const ClientOrgDetailsLifecycleContext = React.createContext<ClientOrgDetailsLifecycleContextValue>(
  {} as ClientOrgDetailsLifecycleContextValue
);

export type ClientOrgDetailsHandlersContextValue = {
  readonly onRefresh: () => void;

  readonly onClientOrgOpenEdit: (clientOrg: ClientOrg) => void;
  readonly onClientOrgRefetch: (clientOrg: ClientOrg) => Promise<ClientOrg>;

  readonly onClientOrgTryBlock: () => void;
  readonly onClientOrgTryUnBlock: () => void;
};

export const ClientOrgDetailsHandlersContext = React.createContext<ClientOrgDetailsHandlersContextValue>(
  {} as ClientOrgDetailsHandlersContextValue
);
