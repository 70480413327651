import { IconButton, SvgIconProps } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import styled from '@emotion/styled';
import { viewConfig } from '../../../../theme/viewConfig';
import AppImage from '../common';

export const ImageStubContent = styled.div`
  text-align: center;
  color: ${p => p.theme.palette.secondary.main};
  background-color: ${p => p.theme.palette.highlight};

  & .MuiSvgIcon-root {
    font-size: 4.625rem;
    margin-bottom: ${p => p.theme.spacing(1)};
  }
`;

export const Content = styled.div`
  height: 100%;

  text-align: center;

  padding-left: ${p => p.theme.spacing(6)};
  padding-right: ${p => p.theme.spacing(6)};
`;

export const ImageWrapper = styled.div`
  min-height: 11.25rem;
  width: 100%;

  position: relative;

  border: 1px solid ${p => p.theme.palette.secondary.main};
  border-radius: ${p => p.theme.shape.borderRadius}px;
  overflow: hidden;
`;

export const Image = styled(AppImage)`
  vertical-align: top;
`;

interface CoverImageProps {
  readonly border: boolean;
  readonly rounded: boolean;
  readonly error?: any;
  readonly disabled?: boolean;
}

export const CoverImage = styled.div<CoverImageProps>`
  width: 100%;

  position: relative;
  overflow: hidden;
  height: ${viewConfig.coverImage.height};
  border: 1px solid ${p => (p.error ? p.theme.palette.error.main : p.theme.palette.secondary.main)};

  ${p =>
    p.disabled &&
    `
    background-color: ${p.theme.palette.disabled.background};
 `}

  ${p =>
    !p.border &&
    `
    border: 0;
  `};

  ${p =>
    p.rounded &&
    `
    border-radius: ${p.theme.shape.borderRadius}px;
  `};

  ${ImageStubContent} {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: ${p => p.theme.spacing(2)};
  }

  ${Image} {
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;

    object-fit: cover;
    object-position: center center;
  }
`;

export const AddImageWrap = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  padding-left: ${p => p.theme.spacing(3)};
  padding-right: ${p => p.theme.spacing(3)};
  padding-bottom: ${p => p.theme.spacing(2)};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DeleteButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

interface AddImageIconProps extends SvgIconProps {
  readonly disabled?: boolean;
}

export const AddImageIcon = styled(AddIcon)<AddImageIconProps>`
  ${p =>
    p.disabled &&
    `
    cursor:not-allowed;
  `}
  width: 2rem;
  height: 2rem;
`;
