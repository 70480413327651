import DataTable, { DataTableRow } from '@components/common/table';
import { DataTableLoader } from '@components/common/table/loader';
import { EReportsTableColumn } from '@features/report/types';
import { ReportsTableCellAdapter } from '@features/report/table/adapters/cell';
import { MPReport } from '@/domain';
import { ReportsTableCellName } from '../components/tableCell/name';
import { reportsIsFetchingSelector } from '@features/report/table/store/selectors';
import { reportsTableColumns } from '@features/report/table/utils';
import { ReportsTableCellDescription } from '@features/report/components/tableCell/description';

type ReportsTableProps = {
  readonly reports: MPReport[];
  readonly onReportClick?: (report: MPReport) => void;
};

export const ReportsTable = (props: ReportsTableProps) => {
  const { reports, onReportClick } = props;

  const rows: DataTableRow<MPReport, EReportsTableColumn>[] = reports?.map((report, index) => {
    return {
      [EReportsTableColumn.Name]: (
        <ReportsTableCellAdapter
          index={index}
          component={ReportsTableCellName}
        />
      ),
      [EReportsTableColumn.Description]: (
        <ReportsTableCellAdapter
          index={index}
          component={ReportsTableCellDescription}
        />
      ),
      data: report,
    };
  });

  return (
    <DataTable<MPReport, EReportsTableColumn>
      width='60%'
      overflowX='inherit'
      rows={rows}
      loader={<DataTableLoader selector={reportsIsFetchingSelector} />}
      metadata={{
        columns: reportsTableColumns,
      }}
      onRowClick={(event, cell, { data }) => {
        onReportClick?.(data);
      }}
    />
  );
};
