import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataFilterComponent from '../../../../../components/common/dataFilter';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../../hooks/useDataFilterAdapter';
import { EAdminMpFilterItem } from '../filterUtils';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { adminMpsFilterSelector, adminMpsGuidSelector, adminMpsSortColumnSelector } from '../store/selectors';
import { EAdminMpTableColumn } from '../utils';

interface AdminMpTableFilterAdapterProps {
  readonly filterAdapter: DataFilterAdapter<EAdminMpTableColumn, EAdminMpFilterItem>;
}

const AdminMpTableFilterAdapter = (props: AdminMpTableFilterAdapterProps) => {
  const { filterAdapter } = props;

  const handlers = useContextHandlers();

  const guid = useSelector(adminMpsGuidSelector);
  const sortColumn = useSelector(adminMpsSortColumnSelector);
  const filter = useSelector(adminMpsFilterSelector);

  const { filterStrategies } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return useMemo(
    () => (
      <DataFilterComponent
        strategies={filterStrategies}
        onChange={handlers.onChangeFilter}
      />
    ),
    [filterStrategies, handlers.onChangeFilter]
  );
};

export default AdminMpTableFilterAdapter;
