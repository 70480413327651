import { UUID } from 'domain/model/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EOfferTableTab } from '../../../general/offer/types';
import { bookingOffersGuidSelector } from '../store/selectors';
import { bookingOffersSetTab, bookingOffersStartSession } from '../store/slice';

interface UseBookingOffersTableSessionProps {
  readonly guid: UUID;
  readonly tab: EOfferTableTab;
}

const useBookingOffersTableSession = (props: UseBookingOffersTableSessionProps): void => {
  const { guid, tab } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(bookingOffersGuidSelector);

  // инициализация сеанса
  useEffect(() => {
    dispatch(bookingOffersStartSession({ guid }));
  }, [dispatch, guid]);

  // начальная загрузка, изменение вкладки
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(bookingOffersSetTab({ tab }));
    }
  }, [dispatch, guid, currentGuid, tab]);
};

export default useBookingOffersTableSession;
