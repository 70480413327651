import { Grid } from '@mui/material';
import { FCC } from 'react';
import { MPButton } from '../../../../../theme/ui-kit/button';
import ConfirmDialog from '../../../dialogs/confirm';
import ContentLoader from '../../../loader';

interface DictionaryDeleteDialogProps {
  readonly title?: string;
  readonly isLoading: boolean;
  readonly onClose: () => void;
  readonly onDelete: () => void;
}

export const DictionaryDeleteDialog: FCC<DictionaryDeleteDialogProps> = props => {
  const { isLoading, title = 'Удаление', children, onClose, onDelete } = props;

  return (
    <ConfirmDialog
      open
      dialogButtons={
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <MPButton
              disabled={isLoading}
              onClick={onDelete}
            >
              Да, удалить
            </MPButton>
            {isLoading && <ContentLoader color='secondary' />}
          </Grid>
          <Grid item>
            <MPButton
              disabled={isLoading}
              variant='outlined'
              onClick={onClose}
            >
              Отмена
            </MPButton>
          </Grid>
        </Grid>
      }
      title={title}
    >
      {children}
    </ConfirmDialog>
  );
};
