import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../data/store/store';
import { ESortDirection } from '../../../../../domain/model/enums';
import { Team } from '../../../../../domain/model/team';
import { Nullable } from '../../../../../domain/model/types';
import { ETeamTableColumn } from '../utils';

export const teamsTeamsSelector = (store: RootState) => store.team.list.data;
export const teamsIsFetchingSelector = (store: RootState) => store.team.list.isFetching;
export const teamsGuidSelector = (store: RootState) => store.team.list.guid;
export const teamsPageNumberSelector = (store: RootState) => store.team.list.pageNumber;
export const teamsTotalCountSelector = (store: RootState) => store.team.list.totalCount;
export const teamsPageCountSelector = (store: RootState) => store.team.list.pageCount;
export const teamsSearchSelector = (store: RootState) => store.team.list.search;
export const teamsFilterSelector = (store: RootState) => store.team.list.filter;
export const teamsSortColumnSelector = (store: RootState) =>
  (store.team.list.search.sort?.split(',') as [ETeamTableColumn, ESortDirection])?.[0] ?? '';
export const teamsSortDirectionSelector = (store: RootState) =>
  (store.team.list.search.sort?.split(',') as [ETeamTableColumn, ESortDirection])?.[1] ?? '';
export const teamsNeedRefreshWatcherSelector = (store: RootState) => store.team.list.needRefreshWatcher;
export const teamsTabsCounterSelector = (store: RootState) => store.team.list.tabsCounter;

export const teamsTeamIndexSelector = (store: RootState, index: number) => index;

const createTeamsTableTeamByIndexSelector = createSelector(
  teamsTeamsSelector,
  teamsTeamIndexSelector,
  (teams, index): Nullable<Team> => teams?.[index] ?? null
);
export const teamsTeamByIndexSelector = (index: number) => (store: RootState) =>
  createTeamsTableTeamByIndexSelector(store, index);
