import {
  CaseReducer,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  Selector,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import Api from '../../../../data/api';
import { ServerErrorResponse } from '../../../../data/network/types';
import { AppThunkAPIConfig, RootState } from '../../../../data/store/store';
import { Nullable, UUID } from '../../../../domain/model/types';
import { ELockStatus } from '../useLockObject';

export const bannerLock = createAsyncThunk<void, { id: UUID }, AppThunkAPIConfig<ServerErrorResponse>>(
  'lock/banner/lock',
  async ({ id }, { rejectWithValue }) => {
    try {
      await Api.banner.lock({ id });
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const bannerUnlock = createAsyncThunk<void, { id: UUID }, AppThunkAPIConfig<ServerErrorResponse>>(
  'lock/banner/unlock',
  async ({ id }, { rejectWithValue }) => {
    try {
      await Api.banner.unlock({ id });
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

export interface LockObject {
  readonly status: Nullable<ELockStatus>;
  readonly message: Nullable<string>;
}

export interface LockObjectListState {
  [index: string]: LockObject;
}

type Reducer<T = undefined> = CaseReducer<LockObjectListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<LockObjectListState> {
  setLockObjectSuccess: Reducer<{ id: UUID }>;
  setLockObjectError: Reducer<{ id: UUID; message: string }>;
}

const slice = createSlice<LockObjectListState, Reducers, 'list'>({
  name: 'list',
  initialState: {},
  reducers: {
    setLockObjectSuccess: (state, { payload }) => {
      const { id } = payload;
      state[id] = {
        status: ELockStatus.Success,
        message: null,
      };
    },
    setLockObjectError: (state, { payload }) => {
      const { id, message } = payload;
      state[id] = {
        status: ELockStatus.Error,
        message,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bannerLock.pending, (state, { meta }) => {
        const { id } = meta.arg;
        state[id] = {
          status: ELockStatus.Fetching,
          message: null,
        };
      })
      .addCase(bannerLock.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        state[id] = {
          status: ELockStatus.Success,
          message: null,
        };
      })
      .addCase(bannerLock.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        state[id] = {
          status: ELockStatus.Error,
          message: payload?.message ?? null,
        };
      })
      .addCase(bannerUnlock.pending, (state, { meta }) => {
        const { id } = meta.arg;
        if (state[id]) {
          delete state[id];
        }
      });
  },
});

export const { setLockObjectSuccess, setLockObjectError } = slice.actions;

const lockSelector: Selector<RootState, LockObjectListState> = state => state.lock.list;
const lockObjectByIdSelector: Selector<RootState, UUID, [UUID]> = (state, id) => id;

export const createLockObjectGetByIdSelector = createSelector(
  lockSelector,
  lockObjectByIdSelector,
  (list, id): LockObject =>
    list[id] ?? {
      status: null,
      message: null,
    }
);

export default slice.reducer;
