import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/data/store/store';
import { EAdCampaignTableColumn } from '../../types';
import { AdCampaignShort, ESortDirection, Nullable } from '@/domain';

export const adCampaignIsEmptySelector = (store: RootState) =>
  store.adCampaign.list.isFetched && store.adCampaign.list.allAdCampaigns.count === 0;
export const adCampaignIsPreloadingSelector = (store: RootState) => store.adCampaign.list.allAdCampaigns.count === null;

export const adCampaignGuidSelector = (store: RootState) => store.adCampaign.list.guid;
export const adCampaignAdCampaignSelector = (store: RootState) => store.adCampaign.list.data;
export const adCampaignPageNumberSelector = (store: RootState) => store.adCampaign.list.pageNumber;
export const adCampaignTotalCountSelector = (store: RootState) => store.adCampaign.list.totalCount;
export const adCampaignPageCountSelector = (store: RootState) => store.adCampaign.list.pageCount;
export const adCampaignSearchSelector = (store: RootState) => store.adCampaign.list.search;
export const adCampaignSortSelector = (store: RootState) => store.adCampaign.list.search.sort;
export const adCampaignTabsCounterSelector = (store: RootState) => store.adCampaign.list.tabsCounter;
export const adCampaignNeedRefreshWatcherSelector = (store: RootState) => store.adCampaign.list.needRefreshWatcher;
export const adCampaignFilterSelector = (store: RootState) => store.adCampaign.list.filter;
export const adCampaignIsFetchingSelector = (store: RootState) => store.adCampaign.list.isFetching;

export const adCampaignCountNeedRefreshWatcherSelector = (store: RootState) =>
  store.adCampaign.list.allAdCampaigns.needRefreshWatcher;

export const adCampaignSortColumnSelector = (store: RootState) =>
  (store.adCampaign.list.search.sort?.split(',') as [EAdCampaignTableColumn, ESortDirection])?.[0] ?? '';
export const adCampaignSortDirectionSelector = (store: RootState) =>
  (store.adCampaign.list.search.sort?.split(',') as [EAdCampaignTableColumn, ESortDirection])?.[1] ?? '';

export const adCampaignAdCampaignIndexSelector = (store: RootState, index: number) => index;
const createAdCampaignTableAdCampaignByIndexSelector = createSelector(
  adCampaignAdCampaignSelector,
  adCampaignAdCampaignIndexSelector,
  (adCampaign, index): Nullable<AdCampaignShort> => adCampaign?.[index] ?? null
);

export const adCampaignAdCampaignByIndexSelector = (index: number) => (store: RootState) =>
  createAdCampaignTableAdCampaignByIndexSelector(store, index);
