import { ESortDirection, OfferFieldAudit } from '@/domain';
import DataTable, { DataTableRow } from '../../../../../components/common/table';
import { DataTableLoader } from '@components/common/table/loader';
import OfferFieldHistoryTableCellAdapter from '../../adapters/fieldCell';
import OfferFieldHistoryTableCellActorTypeAdapter from '../../adapters/fieldCellActorType';
import { offerHistoryTableIsLoadingSelector } from '../../store/selectors';
import { EOfferFieldHistoryTableColumn } from '../../utils/common';
import OfferFieldHistoryTableCellActorType from '../tableCell/fieldHistory/actorType';
import OfferFieldHistoryTableCellField from '../tableCell/fieldHistory/field';
import OfferFieldHistoryTableCellNewValue from '../tableCell/fieldHistory/newValue';
import OfferFieldHistoryTableCellOldValue from '../tableCell/fieldHistory/oldValue';
import OfferFieldHistoryTableCellUpdatedAt from '../tableCell/fieldHistory/updatedAt';
import OfferFieldHistoryTableCellUpdatedAtTime from '../tableCell/fieldHistory/updatedAtTime';
import OfferFieldHistoryTableCellUpdatedBy from '../tableCell/fieldHistory/updatedBy';
import { useOfferFieldHistoryTable } from '../../hooks/useTableFieldSettings';

interface OfferFieldHistoryTableComponentProps {
  readonly fieldHistory: OfferFieldAudit[];
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
}

const OfferFieldHistoryTableComponent = (props: OfferFieldHistoryTableComponentProps) => {
  const { fieldHistory, sort } = props;

  const rows: DataTableRow<OfferFieldAudit, EOfferFieldHistoryTableColumn>[] = fieldHistory.map((auditItem, index) => {
    return {
      [EOfferFieldHistoryTableColumn.UpdatedAt]: (
        <OfferFieldHistoryTableCellAdapter
          index={index}
          component={OfferFieldHistoryTableCellUpdatedAt}
        />
      ),
      [EOfferFieldHistoryTableColumn.UpdatedAtTime]: (
        <OfferFieldHistoryTableCellAdapter
          index={index}
          component={OfferFieldHistoryTableCellUpdatedAtTime}
        />
      ),
      [EOfferFieldHistoryTableColumn.Field]: (
        <OfferFieldHistoryTableCellAdapter
          index={index}
          component={OfferFieldHistoryTableCellField}
        />
      ),
      [EOfferFieldHistoryTableColumn.OldValue]: (
        <OfferFieldHistoryTableCellAdapter
          index={index}
          component={OfferFieldHistoryTableCellOldValue}
        />
      ),
      [EOfferFieldHistoryTableColumn.NewValue]: (
        <OfferFieldHistoryTableCellAdapter
          index={index}
          component={OfferFieldHistoryTableCellNewValue}
        />
      ),
      [EOfferFieldHistoryTableColumn.UpdatedBy]: (
        <OfferFieldHistoryTableCellAdapter
          index={index}
          component={OfferFieldHistoryTableCellUpdatedBy}
        />
      ),
      [EOfferFieldHistoryTableColumn.ActorType]: (
        <OfferFieldHistoryTableCellActorTypeAdapter
          index={index}
          component={OfferFieldHistoryTableCellActorType}
        />
      ),
      data: auditItem,
    };
  });

  const { metadata } = useOfferFieldHistoryTable();

  return (
    <DataTable<OfferFieldAudit, EOfferFieldHistoryTableColumn>
      width={'auto'}
      overflowX='inherit'
      metadata={metadata}
      rows={rows}
      sort={{ column: sort.column, direction: sort.direction }}
      loader={<DataTableLoader selector={offerHistoryTableIsLoadingSelector} />}
    />
  );
};

export default OfferFieldHistoryTableComponent;
