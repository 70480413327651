import { ClientOrgCreate } from '@/domain';
import { EPanelActionPosition } from '../../../../types';
import { ClientOrgLifeCycleRules } from '../../lifecycle/types';
import { ClientOrgActionCreateType, ClientOrgActions, EClientOrgActionType } from '../../types';
import {
  changeClientOrgActionPosition,
  filterClientOrgCreateActions,
  makeClientOrgActionPrimary,
  renameClientOrgAction,
} from '../../utils';

type UseClientOrgCreateActions = ClientOrgActions<ClientOrgActionCreateType>;

interface UseClientOrgCreateActionsProps {
  readonly clientOrg: Pick<ClientOrgCreate, 'id'>;
  readonly lifeCycleRules: ClientOrgLifeCycleRules;
}

const useClientOrgCreateActions = (props: UseClientOrgCreateActionsProps): UseClientOrgCreateActions => {
  const { lifeCycleRules, clientOrg } = props;

  const { nextActions } = lifeCycleRules;

  const actions = filterClientOrgCreateActions(nextActions);

  //переименуем кнопку Редактирования/Сохранения
  if (!clientOrg.id) {
    renameClientOrgAction(actions, EClientOrgActionType.Edit, 'Активировать');
  } else {
    renameClientOrgAction(actions, EClientOrgActionType.Edit, 'Сохранить и закрыть');
  }

  //перемещаем кнопку Редактирования/Сохранения на основную панель
  changeClientOrgActionPosition(actions, EClientOrgActionType.Edit, [EPanelActionPosition.Default]);

  // помечаем праймари экшоны
  return makeClientOrgActionPrimary(actions, [EClientOrgActionType.Edit]);
};

export default useClientOrgCreateActions;
