import { Chip, Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../data/store/store';
import { ProductOrderItem } from '../../../../../domain/model/order';
import { ProductOffer } from '../../../../../domain/model/productOffer';
import { Nullable, UUID } from '../../../../../domain/model/types';
import ContentLoader from '../../../../components/common/loader';
import { Modifier, ModifierArray } from '../../../../utils/modifier';
import {
  productOrderEditItemsIsFetchingSelector,
  productOrderEditItemsSelector,
  productOrderEditModifiedItemsSelector,
  productOrderEditWarningsSelector,
} from '../store/selectors';
import { productOrderEditItemsFetch, ProductOrderWarnings } from '../store/slice';
import { validateProductOrder } from '../utils';
import ProductOrderItemsTable from './table';

type CategoryItemsType = {
  category: ProductOffer['category'];
  categoryList: ProductOffer['categoryList'];
  items: ProductOrderItem[];
};

interface ProductOrderItemsContainerProps {
  readonly items: Nullable<ProductOrderItem[]>;
  readonly onChangeFieldItem: ModifierArray<ProductOrderItem>;
  readonly onReplaceFieldItem: (offerId: UUID, value: Nullable<ProductOrderItem>) => void;
  readonly onSetWarning: Modifier<ProductOrderWarnings>;
}

const ProductOrderItemsContainer = (props: ProductOrderItemsContainerProps) => {
  const { items, onChangeFieldItem, onReplaceFieldItem, onSetWarning } = props;
  const dispatch = useAppDispatch();

  const [itemsByCategory, setItemsByCategory] = useState<CategoryItemsType[]>([]);
  const warnings = useSelector(productOrderEditWarningsSelector);
  const orderItems = useSelector(productOrderEditItemsSelector);
  const modifiedOrderItems = useSelector(productOrderEditModifiedItemsSelector);

  const isFetchingItems = useSelector(productOrderEditItemsIsFetchingSelector);

  const applyModification = useCallback(
    (
      source: Nullable<ProductOrderItem[]>,
      modification: Record<string, Partial<ProductOrderItem>>
    ): ProductOrderItem[] => {
      let result: ProductOrderItem[] = [];

      if (source) {
        result = [...source];
        Object.entries(modification).forEach(([offerId, item]) => {
          const offerIndex = result.findIndex(r => r.offer.id === offerId);

          if (offerIndex != -1) {
            result[offerIndex] = { ...result[offerIndex], ...item };
          } else {
            result.push(item as ProductOrderItem);
          }
        });
      }
      return result;
    },
    []
  );

  useEffect(() => {
    const items: CategoryItemsType[] = [];
    applyModification(orderItems, modifiedOrderItems).forEach(item => {
      const ind = items.findIndex(categoryItem => categoryItem.category?.id === item.offer.category?.id);
      if (ind === -1) {
        items.push({ category: item.offer.category, categoryList: item.offer.categoryList, items: [item] });
      } else {
        items[ind] = { ...items[ind], items: [...items[ind].items, item] };
      }
    });
    setItemsByCategory(items);
  }, [orderItems, modifiedOrderItems]);

  useEffect(() => {
    if (warnings.validateItems) {
      const isValid = itemsByCategory.every(itemByCategory => validateProductOrder(itemByCategory.items));

      if (isValid) {
        onSetWarning('validateItems', null);
      }
    }
  }, [itemsByCategory, warnings]);
  useEffect(() => {
    dispatch(productOrderEditItemsFetch(items));
  }, [dispatch, items]);

  return (
    <>
      {isFetchingItems ? (
        <ContentLoader
          size={75}
          alpha
        />
      ) : (
        itemsByCategory.map((cat, index) => (
          <Grid
            key={index}
            container
            spacing={2}
            direction={'column'}
          >
            <Grid item>
              <Chip
                label={cat.categoryList?.map(item => item.name)?.join(' > ')}
                color={'primary'}
              />
            </Grid>
            <Grid item>
              <ProductOrderItemsTable
                items={cat.items}
                onChangeFieldItem={onChangeFieldItem}
                onReplaceFieldItem={onReplaceFieldItem}
                onSetWarning={onSetWarning}
              />
            </Grid>
          </Grid>
        ))
      )}
    </>
  );
};

export default ProductOrderItemsContainer;
