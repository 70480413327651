import { ServerErrorResponse } from '@/data/network/types';
import { AppThunkAPIConfig, RootState } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { Banner, EBannerPartition, Nullable, Pageable, paginationSizeVariant, UUID } from '@/domain';
import {
  CaseReducer,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  Selector,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { PaginationSize } from '../../../../types';
import { bannerServices } from '../../services';
import { AllProps } from '../../services/common';
import { BannerActionTableType, EBannerActionType } from '../../types';
import { BannerTableFilterValues } from '../filterUtils';
import { BannerTableTabsCounter, EBannerTableColumn, EBannerTableTab } from '../utils';

const defaultSort = `${EBannerTableColumn.Name},asc`;

const getActionProcess = (
  state: BannerListState,
  partition: EBannerPartition,
  id: UUID,
  actionType: BannerActionTableType
) => {
  let process = state.partitions[partition].actions.find(change => change.id === id);
  if (process) return process;

  process = {
    ...fetchableDefault,
    id,
    type: actionType,
    error: null,
  };
  state.partitions[partition].actions.push(process);

  return process;
};

export type BannersFetchProps = Omit<AllProps, 'signal'>;

export type BannersCountsFetchProps = BannersFetchProps & {
  readonly tabs: EBannerTableTab[];
};

export const bannersFetch = createAsyncThunk<Pageable<Banner>, BannersFetchProps, AppThunkAPIConfig>(
  'banner/list/fetch',
  async (payload, { rejectWithValue, signal }) => {
    try {
      return bannerServices.common.all({ ...payload, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const bannersPauseBanner = createAsyncThunk<
  undefined,
  {
    partition: EBannerPartition;
    id: UUID;
  },
  AppThunkAPIConfig<ServerErrorResponse>
>('banner/list/banner/pause', async ({ partition, id }, { rejectWithValue }) => {
  try {
    await bannerServices.common.paused({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bannersArchiveBanner = createAsyncThunk<
  undefined,
  {
    partition: EBannerPartition;
    id: UUID;
  },
  AppThunkAPIConfig<ServerErrorResponse>
>('banner/list/banner/archive', async ({ partition, id }, { rejectWithValue }) => {
  try {
    await bannerServices.common.archived({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bannersResumeBanner = createAsyncThunk<
  undefined,
  {
    partition: EBannerPartition;
    id: UUID;
  },
  AppThunkAPIConfig<ServerErrorResponse>
>('banner/list/banner/resume', async ({ partition, id }, { rejectWithValue }) => {
  try {
    await bannerServices.common.resumed({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bannersChangeBannerSortIndex = createAsyncThunk<
  void,
  {
    partition: EBannerPartition;
    id: UUID;
    sortIndex: number;
  },
  AppThunkAPIConfig<ServerErrorResponse>
>('banners/list/banner/changeSortIndex', async ({ partition, id, sortIndex }, { rejectWithValue }) => {
  try {
    await bannerServices.common.changeSortIndex({ id, sortIndex });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bannersGetLastSortIndex = createAsyncThunk<
  number,
  {
    partition: EBannerPartition;
  },
  AppThunkAPIConfig<ServerErrorResponse>
>('banners/list/getLastSortIndex', async ({ partition }, { rejectWithValue }) => {
  try {
    const { data } = await bannerServices.common.lastSortIndex();
    return data.sortIndex ?? 0;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bannersCountsFetch = createAsyncThunk<BannerTableTabsCounter, BannersCountsFetchProps, AppThunkAPIConfig>(
  'banners/list/counts/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      const { counts, errors } = await bannerServices.common.countsByTabs({ ...props, signal });

      if (errors.length > 0) {
        console.error(errors.join('\n'));
      }

      return counts;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

interface BannerListSearchState {
  readonly sort: string;
  readonly pageSize: PaginationSize;
}

interface BannerListActionsState extends Fetchable {
  id: UUID;
  type: BannerActionTableType;
  error: Nullable<ServerErrorResponse>;
}

interface BannerPartitionState {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly tab: EBannerTableTab;
  readonly banners: Fetchable & Pageable<Banner>;
  readonly search: BannerListSearchState;
  readonly filter: BannerTableFilterValues;
  readonly tabsCounter: BannerTableTabsCounter;
  readonly actions: BannerListActionsState[];
  readonly sortIndexInfo: Fetchable & {
    last: number;
  };
  readonly bannerToChangeSortIndex: Nullable<Banner>;
}

export interface BannerListState {
  readonly partitions: {
    readonly [EBannerPartition.TradeOffers]: BannerPartitionState;
    readonly [EBannerPartition.CorpOffers]: BannerPartitionState;
  };
}

type Reducer<T = undefined> = CaseReducer<BannerListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<BannerListState> {
  bannersStartSession: Reducer<{
    guid: UUID;
    partition: EBannerPartition;
  }>;
  bannersSortReset: Reducer<EBannerPartition>;
  bannersSetTab: Reducer<{
    tab: EBannerTableTab;
    partition: EBannerPartition;
  }>;
  bannersSetSort: Reducer<{
    sort: string;
    partition: EBannerPartition;
  }>;
  bannersSetFilter: Reducer<{
    filter: BannerTableFilterValues;
    partition: EBannerPartition;
  }>;
  bannersSetPage: Reducer<{
    pageNumber: number;
    partition: EBannerPartition;
  }>;
  bannersSetPageSize: Reducer<{
    pageSize: PaginationSize;
    partition: EBannerPartition;
  }>;
  bannersDataReset: Reducer<EBannerPartition>;
  bannersNeedRefreshWatcherReset: Reducer<EBannerPartition>;
  bannersSetBannerToChangeSortIndex: Reducer<{ banner: Nullable<Banner>; partition: EBannerPartition }>;
}

const slice = createSlice<BannerListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    partitions: {
      [EBannerPartition.TradeOffers]: {
        guid: null,
        needRefreshWatcher: 0,
        tab: EBannerTableTab.Active,
        banners: {
          ...fetchableDefault,
          data: [],
          pageCount: 0,
          pageNumber: 1,
          totalCount: 0,
        },
        filter: {},
        tabsCounter: {},
        search: {
          sort: '',
          pageSize: paginationSizeVariant[1],
        },
        actions: [],
        sortIndexInfo: {
          ...fetchableDefault,
          last: 0,
        },
        bannerToChangeSortIndex: null,
      },
      [EBannerPartition.CorpOffers]: {
        guid: null,
        needRefreshWatcher: 0,
        tab: EBannerTableTab.Active,
        banners: {
          ...fetchableDefault,
          data: [],
          pageCount: 0,
          pageNumber: 1,
          totalCount: 0,
        },
        filter: {},
        tabsCounter: {},
        search: {
          sort: '',
          pageSize: paginationSizeVariant[1],
        },
        actions: [],
        sortIndexInfo: {
          ...fetchableDefault,
          last: 0,
        },
        bannerToChangeSortIndex: null,
      },
    },
  },
  reducers: {
    bannersStartSession: (state, { payload }) => {
      const { guid, partition } = payload;
      if (guid !== state.partitions[partition].guid) {
        state.partitions[partition].guid = guid;

        state.partitions[partition].banners.isFetching = false;
        state.partitions[partition].banners.isFetched = false;
        state.partitions[partition].banners.isFailed = false;

        state.partitions[partition].tab = EBannerTableTab.Active;
        state.partitions[partition].banners.data = [];
        state.partitions[partition].banners.totalCount = 0;
        state.partitions[partition].banners.pageCount = 0;
        state.partitions[partition].banners.pageNumber = 1;

        state.partitions[partition].needRefreshWatcher = 0;
        state.partitions[partition].search = {
          sort: defaultSort,
          pageSize: paginationSizeVariant[1],
        };
        state.partitions[partition].filter = {};
        state.partitions[partition].tabsCounter = {};
        state.partitions[partition].actions = [];

        state.partitions[partition].sortIndexInfo.isFetching = false;
        state.partitions[partition].sortIndexInfo.isFetched = false;
        state.partitions[partition].sortIndexInfo.isFailed = false;
        state.partitions[partition].sortIndexInfo.last = 0;

        state.partitions[partition].bannerToChangeSortIndex = null;
      }
    },
    bannersSetTab: (state, { payload }) => {
      const { tab, partition } = payload;
      // сбрасываем пейджинг и сортировку, если поменялись статусы (закладки)
      if (state.partitions[partition].tab !== tab) {
        state.partitions[partition].banners.pageNumber = 1;
        state.partitions[partition].search.sort = defaultSort;
        state.partitions[partition].tab = tab;
      }
      state.partitions[partition].needRefreshWatcher++;
    },
    bannersSortReset: (state, { payload }) => {
      state.partitions[payload].search = {
        ...state.partitions[payload].search,
        sort: defaultSort,
      };
      state.partitions[payload].banners.pageNumber = 1;
      state.partitions[payload].needRefreshWatcher++;
    },
    bannersSetSort: (state, { payload }) => {
      const { partition, sort } = payload;

      state.partitions[partition].search.sort = sort;
      state.partitions[partition].banners.pageNumber = 1;
      state.partitions[partition].needRefreshWatcher++;
    },
    /*    bannersSetSearch: (state, { payload }) => {
      const { sort, statuses, partition } = payload;
      state.partitions[partition].search = {
        ...state.partitions[partition].search,
        sort,
        statuses,
      };
      state.partitions[partition].banners.pageNumber = 1;
      state.partitions[partition].needRefreshWatcher++;
    },*/
    bannersSetFilter: (state, { payload }) => {
      const { filter, partition } = payload;

      state.partitions[partition].filter = filter;
      state.partitions[partition].banners.pageNumber = 1;
      state.partitions[partition].needRefreshWatcher++;
    },
    bannersSetPage: (state, { payload }) => {
      const { pageNumber, partition } = payload;
      state.partitions[partition].banners.pageNumber = pageNumber;
      state.partitions[partition].needRefreshWatcher++;
    },
    bannersSetPageSize: (state, { payload }) => {
      const { pageSize, partition } = payload;
      state.partitions[partition].banners.pageNumber = 1;
      state.partitions[partition].search.pageSize = pageSize;
      state.partitions[partition].needRefreshWatcher++;
    },
    bannersDataReset: (state, { payload }) => {
      state.partitions[payload].banners.data = [];
      state.partitions[payload].banners.totalCount = 0;
      state.partitions[payload].banners.pageCount = 0;
      state.partitions[payload].banners.pageNumber = 1;
    },
    bannersNeedRefreshWatcherReset: (state, { payload }) => {
      state.partitions[payload].needRefreshWatcher = 0;
    },
    bannersSetBannerToChangeSortIndex: (state, { payload }) => {
      const { partition, banner } = payload;
      state.partitions[partition].bannerToChangeSortIndex = banner;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bannersFetch.pending, (state, { meta }) => {
        const { arg } = meta;
        const { partition } = arg;

        state.partitions[partition].banners.isFetching = true;
        state.partitions[partition].banners.isFetched = false;
        state.partitions[partition].banners.isFailed = false;
      })
      .addCase(bannersFetch.fulfilled, (state, { payload, meta }) => {
        const { data, totalCount, pageCount } = payload;
        const { arg } = meta;
        const { partition } = arg;

        state.partitions[partition].banners.isFetching = false;
        state.partitions[partition].banners.isFetched = true;
        state.partitions[partition].banners.isFailed = false;

        state.partitions[partition].banners.data = data;
        state.partitions[partition].banners.totalCount = totalCount;
        state.partitions[partition].banners.pageCount = pageCount;
      })
      .addCase(bannersFetch.rejected, (state, { meta }) => {
        const { aborted, arg } = meta;
        const { partition } = arg;

        if (!aborted) {
          state.partitions[partition].banners.isFetching = false;
          state.partitions[partition].banners.isFetched = false;
          state.partitions[partition].banners.isFailed = true;
          state.partitions[partition].banners.data = [];
        }
      })

      .addCase(bannersPauseBanner.pending, (state, { meta }) => {
        const { id, partition } = meta.arg;

        const actionType = EBannerActionType.Pause;
        const process = getActionProcess(state, partition, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bannersPauseBanner.fulfilled, (state, { meta }) => {
        const { id, partition } = meta.arg;

        const actionType = EBannerActionType.Pause;
        const process = getActionProcess(state, partition, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.partitions[partition].needRefreshWatcher++;
      })
      .addCase(bannersPauseBanner.rejected, (state, { payload, meta }) => {
        const { id, partition } = meta.arg;

        const actionType = EBannerActionType.Pause;
        const process = getActionProcess(state, partition, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })

      .addCase(bannersResumeBanner.pending, (state, { meta }) => {
        const { id, partition } = meta.arg;

        const actionType = EBannerActionType.Resume;
        const process = getActionProcess(state, partition, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bannersResumeBanner.fulfilled, (state, { meta }) => {
        const { id, partition } = meta.arg;

        const actionType = EBannerActionType.Resume;
        const process = getActionProcess(state, partition, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.partitions[partition].needRefreshWatcher++;
      })
      .addCase(bannersResumeBanner.rejected, (state, { payload, meta }) => {
        const { id, partition } = meta.arg;

        const actionType = EBannerActionType.Resume;
        const process = getActionProcess(state, partition, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })

      .addCase(bannersArchiveBanner.pending, (state, { meta }) => {
        const { id, partition } = meta.arg;

        const actionType = EBannerActionType.Archive;
        const process = getActionProcess(state, partition, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bannersArchiveBanner.fulfilled, (state, { meta }) => {
        const { id, partition } = meta.arg;

        const actionType = EBannerActionType.Archive;
        const process = getActionProcess(state, partition, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.partitions[partition].needRefreshWatcher++;
      })
      .addCase(bannersArchiveBanner.rejected, (state, { payload, meta }) => {
        const { id, partition } = meta.arg;

        const actionType = EBannerActionType.Archive;
        const process = getActionProcess(state, partition, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(bannersChangeBannerSortIndex.pending, (state, { meta }) => {
        const { id, partition } = meta.arg;

        const actionType = EBannerActionType.ChangeSortIndex;
        const process = getActionProcess(state, partition, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bannersChangeBannerSortIndex.fulfilled, (state, { meta }) => {
        const { id, partition } = meta.arg;

        const actionType = EBannerActionType.ChangeSortIndex;
        const process = getActionProcess(state, partition, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.partitions[partition].needRefreshWatcher++;
      })
      .addCase(bannersChangeBannerSortIndex.rejected, (state, { payload, meta }) => {
        const { id, partition } = meta.arg;

        const actionType = EBannerActionType.ChangeSortIndex;
        const process = getActionProcess(state, partition, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(bannersGetLastSortIndex.pending, (state, { meta }) => {
        const { partition } = meta.arg;
        state.partitions[partition].sortIndexInfo.isFetching = true;
        state.partitions[partition].sortIndexInfo.isFetched = false;
        state.partitions[partition].sortIndexInfo.isFailed = false;
        state.partitions[partition].sortIndexInfo.last = 0;
      })
      .addCase(bannersGetLastSortIndex.fulfilled, (state, { payload, meta }) => {
        const { partition } = meta.arg;
        state.partitions[partition].sortIndexInfo.isFetching = false;
        state.partitions[partition].sortIndexInfo.isFetched = true;
        state.partitions[partition].sortIndexInfo.isFailed = false;
        state.partitions[partition].sortIndexInfo.last = payload;
      })
      .addCase(bannersCountsFetch.fulfilled, (state, { payload, meta }) => {
        const { partition } = meta.arg;
        state.partitions[partition].tabsCounter = payload;
      })
      .addCase(bannersCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        const { partition } = meta.arg;

        if (!aborted) {
          state.partitions[partition].tabsCounter = {};
        }
      })
      .addCase(bannersGetLastSortIndex.rejected, (state, { meta }) => {
        const { aborted, arg } = meta;
        const { partition } = arg;
        if (!aborted) {
          state.partitions[partition].sortIndexInfo.isFetching = false;
          state.partitions[partition].sortIndexInfo.isFetched = false;
          state.partitions[partition].sortIndexInfo.isFailed = true;
        } else {
          state.partitions[partition].sortIndexInfo.isFetching = false;
          state.partitions[partition].sortIndexInfo.isFetched = false;
          state.partitions[partition].sortIndexInfo.isFailed = false;
        }
        state.partitions[partition].sortIndexInfo.last = 0;
      });
  },
});

export const {
  bannersStartSession,
  bannersSetTab,
  bannersSortReset,
  bannersSetSort,
  bannersSetFilter,
  bannersSetPage,
  bannersSetPageSize,
  bannersDataReset,
  bannersNeedRefreshWatcherReset,
  bannersSetBannerToChangeSortIndex,
} = slice.actions;

export default slice.reducer;

const bannersSelector: Selector<RootState, BannerListState> = state => state.banner.list;
const bannersPartitionSelector: Selector<RootState, EBannerPartition, [EBannerPartition]> = (state, partition) =>
  partition;

const bannersBannerIndexSelector: Selector<RootState, number, [EBannerPartition, number]> = (state, partition, index) =>
  index;

export const createBannersBannerByIndexSelector = createSelector(
  bannersSelector,
  bannersPartitionSelector,
  bannersBannerIndexSelector,
  (banners, partition, index): Nullable<Banner> => banners.partitions[partition].banners.data[index] ?? null
);

export const createBannersBannersSelector = createSelector(
  bannersSelector,
  bannersPartitionSelector,
  (banners, partition): Banner[] => banners.partitions[partition].banners.data
);

export const createBannersIsFetchingSelector = createSelector(
  bannersSelector,
  bannersPartitionSelector,
  (banners, partition): boolean => banners.partitions[partition].banners.isFetching
);

export const createBannersGuidSelector = createSelector(
  bannersSelector,
  bannersPartitionSelector,
  (banners, partition): Nullable<UUID> => banners.partitions[partition].guid
);

export const createBannersPageNumberSelector = createSelector(
  bannersSelector,
  bannersPartitionSelector,
  (banners, partition): number => banners.partitions[partition].banners.pageNumber
);

export const createBannersTotalCountSelector = createSelector(
  bannersSelector,
  bannersPartitionSelector,
  (banners, partition): number => banners.partitions[partition].banners.totalCount
);

export const createBannersPageCountSelector = createSelector(
  bannersSelector,
  bannersPartitionSelector,
  (banners, partition): number => banners.partitions[partition].banners.pageCount
);

export const createBannersFilterSelector = createSelector(
  bannersSelector,
  bannersPartitionSelector,
  (banners, partition) => banners.partitions[partition].filter
);

export const createBannersTabsCounterSelector = createSelector(
  bannersSelector,
  bannersPartitionSelector,
  (banners, partition) => banners.partitions[partition].tabsCounter
);

export const createBannersSearchSelector = createSelector(
  bannersSelector,
  bannersPartitionSelector,
  (banners, partition) => banners.partitions[partition].search
);

export const createBannersSortSelector = createSelector(
  bannersSelector,
  bannersPartitionSelector,
  (banners, partition) => banners.partitions[partition].search.sort
);

export const createBannersActionsSelector = createSelector(
  bannersSelector,
  bannersPartitionSelector,
  (banners, partition) => banners.partitions[partition].actions
);

export const createBannersNeedRefreshWatcherSelector = createSelector(
  bannersSelector,
  bannersPartitionSelector,
  (banners, partition) => banners.partitions[partition].needRefreshWatcher
);

export const createBannersSortIndexInfoSelector = createSelector(
  bannersSelector,
  bannersPartitionSelector,
  (banners, partition) => banners.partitions[partition].sortIndexInfo
);

export const createBannersBannerToChangeSortIndexSelector = createSelector(
  bannersSelector,
  bannersPartitionSelector,
  (banners, partition): Nullable<Banner> => banners.partitions[partition].bannerToChangeSortIndex
);
