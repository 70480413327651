import { AxiosResponse } from 'axios';
import jsonpatch, { Operation as JsonPatchOperation } from 'fast-json-patch';
import { FilterMultiSelectorPredicate } from '../../domain/model';
import { EFilterMultiSelectorValueType } from '../../domain/model/enums';
import { DataFilterQueryDslOperator, DataFilterValueItem } from '../../domain/model/filter';
import { Nullable } from '../../domain/model/types';
import { isMultipleSelectorPredicateType } from '../../presentation/utils/filtering';
import { AppConfigurator } from '../../system/appConfigurator';
import Api from './index';
import { ApiQueryDsl, ApiQueryDslItem, ApiResponsePageable } from './types';

export const getSportBaseEndpoint = (): string => AppConfigurator.getInstance().getApiSportBase();

export const getComServicesEndpoint = (): string => AppConfigurator.getInstance().getApiComBase();

export const getUserStorageServicesEndpoint = (): string => AppConfigurator.getInstance().getApiUserStorageBase();

export const getComWsServicesEndpoint = (): string => AppConfigurator.getInstance().getApiComWs();

export const getSportWsServicesEndpoint = (): string => AppConfigurator.getInstance().getApiSportWs();

export const getFilesEndpoint = (): string => AppConfigurator.getInstance().getApiFilesBase();

export const getAddressEndpoint = (): string => AppConfigurator.getInstance().getApiAddress();

export const getBonusesEndpoint = (): string => AppConfigurator.getInstance().getApiBonusesBase();

export const getFileDownloadEndpoint = (props: { id: string; state?: string | null }): string => {
  return Api.files.getUrl(props.id, props.state, true);
};
export const getTradeOffersDownloadTemplateEndpoint = (): string =>
  `${getComServicesEndpoint()}/trade-offers/unpublished/template`;

export const getCorpOffersDownloadTemplateEndpoint = (): string =>
  `${getComServicesEndpoint()}/corp-offers/unpublished/template`;

export const getPromotionsDownloadTemplateEndpoint = (): string =>
  `${getComServicesEndpoint()}/promotions/personal/import/template`;

export const getTradeOffersDownloadEndpoint = (): string => `${getComServicesEndpoint()}/trade-offers/export`;

export const getCorpOffersDownloadEndpoint = (): string => `${getComServicesEndpoint()}/corp-offers/export`;

export const getProductsDownloadEndpoint = (): string => `${getComServicesEndpoint()}/product-offers/export`;

export const getBookingOffersDownloadEndpoint = (): string => `${getComServicesEndpoint()}/booking-offers/export`;

export const getProductStocksDownloadEndpoint = (): string => `${getComServicesEndpoint()}/product-stocks/import`;

export const getProductOffersDownloadEndpoint = (): string => `${getComServicesEndpoint()}/product-offers/import`;

export const getOrdersDownloadEndpoint = (): string => `${getComServicesEndpoint()}/orders/export`;

export const getOfferActivationsDownloadEndpoint = (): string =>
  `${getComServicesEndpoint()}/offers/activations/export`;

export const getQueryAnalyticsDownloadEndpoint = (): string =>
  `${getComServicesEndpoint()}/analytics/search-queries/export`;

export const getCorpOfferCategoriesDownloadEndpoint = (): string =>
  `${getComServicesEndpoint()}/corp-offer-categories/export`;

export const getBookingOfferCategoriesDownloadEndpoint = (): string =>
  `${getComServicesEndpoint()}/booking-offer-categories/export`;

export const getBookingOfferPriceUnitsDownloadEndpoint = (): string =>
  `${getComServicesEndpoint()}/booking-services/price-units/export`;

export const getBookingServiceCategoriesDownloadEndpoint = (): string =>
  `${getComServicesEndpoint()}/booking-services/categories/export`;

export const getTradeOfferCategoriesDownloadEndpoint = (): string =>
  `${getComServicesEndpoint()}/trade-offer-categories/export`;

export const getPageableFromResponseHeaders = (response: AxiosResponse): ApiResponsePageable => {
  const { headers } = response;
  return {
    pageCount: parseInt(headers['x-paging-page-count'], 10),
    totalCount: parseInt(headers['x-paging-total-count'], 10),
    page: parseInt(headers['x-paging-page'], 10),
    pageSize: parseInt(headers['x-paging-page-size'], 10),
  };
};

const getQueryDslMultiSelectorValue = (value: FilterMultiSelectorPredicate<any>, attribute?: string) => {
  switch (value.select) {
    case EFilterMultiSelectorValueType.All:
      return EFilterMultiSelectorValueType.All;
    case EFilterMultiSelectorValueType.None:
      return EFilterMultiSelectorValueType.None;
    case EFilterMultiSelectorValueType.In:
      const defaultOutValue = value.in;
      return defaultOutValue != null && attribute
        ? getQueryDslAttributeValue(defaultOutValue, attribute)
        : defaultOutValue;
  }
};

const getQueryDslMultiSelectorField = (field: string, value: FilterMultiSelectorPredicate<any>, attribute?: string) => {
  switch (value.select) {
    case EFilterMultiSelectorValueType.All:
    case EFilterMultiSelectorValueType.None:
      return `${field}.select`;
    case EFilterMultiSelectorValueType.In:
      return `${field}.in${attribute ? '.' + attribute : ''}`;
  }
};

const getQueryDslAttributeValue = (value: any, attribute: string) => {
  return Array.isArray(value) ? value.map(item => item[attribute]) : value[attribute];
};

const getQueryDslValueByDataType = (value: any): ApiQueryDslItem['value'] => {
  let outValue: ApiQueryDslItem['value'] = [];
  if (Array.isArray(value)) {
    outValue = value;
  } else if (typeof value === 'number') {
    outValue = [`${value}`];
  } else if (typeof value === 'string') {
    outValue = [value];
  } else if (typeof value === 'boolean') {
    outValue = [`${value}`];
  }
  return outValue;
};

const getQueryDslCommonByDataFilterValue = (field: string, valueItem: DataFilterValueItem<any>): ApiQueryDslItem => {
  const defaultOutValue = valueItem.value;

  const value =
    defaultOutValue != null && valueItem.querydsl?.valueAttribute
      ? getQueryDslAttributeValue(defaultOutValue, valueItem.querydsl.valueAttribute)
      : defaultOutValue;

  return {
    field,
    operator: valueItem.querydsl!.operator,
    value: getQueryDslValueByDataType(value),
  };
};

const getQueryDslMultiSelectorByDataFilterValue = (
  field: string,
  valueItem: DataFilterValueItem<any>
): ApiQueryDslItem => {
  const defaultOutValue = valueItem.value;

  return {
    field:
      defaultOutValue != null
        ? getQueryDslMultiSelectorField(field, defaultOutValue, valueItem.querydsl?.valueAttribute)
        : field,
    operator: valueItem.querydsl!.operator,
    value: getQueryDslValueByDataType(
      defaultOutValue != null
        ? getQueryDslMultiSelectorValue(defaultOutValue, valueItem.querydsl?.valueAttribute)
        : defaultOutValue
    ),
  };
};

const getQueryDslByDataFilterValue = (field: string, valueItem: DataFilterValueItem<any>): ApiQueryDslItem =>
  valueItem.type && isMultipleSelectorPredicateType(valueItem.type)
    ? getQueryDslMultiSelectorByDataFilterValue(field, valueItem)
    : getQueryDslCommonByDataFilterValue(field, valueItem);

export const getQueryDslByDataFilterValues = (
  values: Partial<Record<string, DataFilterValueItem<any>>>
): Nullable<ApiQueryDsl> => {
  const result = Object.keys(values)
    .filter(value => !!values[value])
    .filter(value => {
      const valueItem = values[value]!;
      const outValue = valueItem.value;
      return (
        // убираем если нет настроек querydsl и нет значения в value
        !!valueItem.querydsl && outValue?.toString()?.length > 0
      );
    })
    .reduce<ApiQueryDsl>((previous, current) => {
      const valueItem = values[current]!;
      return [...previous, getQueryDslByDataFilterValue(current, valueItem)];
    }, []);
  return result.length === 0 ? null : result;
};

export const appendQueryDslParams = (params: URLSearchParams, querydsl: ApiQueryDsl): void => {
  if (querydsl.length === 0) {
    return;
  }

  const likeFrontSymbol = '*';
  const likeReplacementSymbol = '%';
  querydsl.forEach(item => {
    let newItem = item;
    if (newItem.operator === DataFilterQueryDslOperator.Like) {
      newItem = {
        ...newItem,
        value: newItem.value.map(value => value?.replaceAll(likeFrontSymbol, likeReplacementSymbol) ?? null),
      };
    }
    if (newItem.operator === DataFilterQueryDslOperator.Between) {
      if (newItem.value[0] !== null) {
        const valueFrom: ApiQueryDslItem = {
          ...newItem,
          operator: DataFilterQueryDslOperator.MoreOrEquals,
          value: [newItem.value[0]],
        };
        params.append('filter', JSON.stringify(valueFrom));
      }
      if (newItem.value[1] !== null) {
        const valueTo: ApiQueryDslItem = {
          ...newItem,
          operator: DataFilterQueryDslOperator.LessOrEquals,
          value: [newItem.value[1]],
        };
        params.append('filter', JSON.stringify(valueTo));
      }
    } else {
      params.append('filter', JSON.stringify(newItem));
    }
  });
};

export const withJsonPatch = <T extends object>(data: T, predicate: (data: T) => T): JsonPatchOperation[] => {
  const newData = predicate(data);
  return jsonpatch.compare(data, newData);
};
