import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { Partner } from '../../../domain/model/partner';
import ForbiddenComponent from '../../components/common/forbidden';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import { viewShowPartnerManagementMenu } from '../../features/partner/management/container/store/slice';
import { EProductUrlParam, ProductLocationState } from '../../features/products/entry';
import ProductTableEntry from '../../features/products/table/entry';
import { EProductTableTab } from '../../features/products/table/utils';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface PartnerManagementProductsScreenProps {
  readonly partner: Partner;
}

const PartnerManagementProductsScreen = ({ partner }: PartnerManagementProductsScreenProps) => {
  const dispatch = useDispatch();
  const location = useLocation<ProductLocationState>();

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(EProductUrlParam.Tab) as EProductTableTab;

  const {
    accessMatrix: { productOffers },
  } = useCurrentUser();

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowPartnerManagementMenu());
  }, [dispatch]);

  if (!productOffers?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<ProductLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <ProductTableEntry
          guid={state.guid}
          partnerId={partner.id}
          tab={tab}
        />
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default PartnerManagementProductsScreen;
