import React from 'react';
import { OfferCategory } from '../../../../../domain/model';

export type CorpOfferCategoriesSelectHandlersContextValue = {
  readonly onSelect: (corpOfferCategory: OfferCategory) => void;
  readonly onUnselect: (corpOfferCategory: OfferCategory) => void;
  readonly onClose: () => void;
  readonly onEdit: Nullable<() => void>;
};

export const CorpOfferCategoriesSelectHandlersContext =
  React.createContext<CorpOfferCategoriesSelectHandlersContextValue>(
    {} as CorpOfferCategoriesSelectHandlersContextValue
  );
