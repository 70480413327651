import { combineReducers } from '@reduxjs/toolkit';
import privilege, { ConfigurationPrivilegeNotificationsState } from './privilege/store/slice';

interface ConfigurationState {
  privilege: ConfigurationPrivilegeNotificationsState;
}

export default combineReducers<ConfigurationState>({
  privilege,
});
