import { BookingOfferShort, UserAccessPartitionBookingOffers } from '@/domain';
import { EPanelActionPosition } from '../../../types';
import { EOfferTableTab, OfferActions } from '../../general/offer/types';
import {
  GetOfferActionsByPermissionsProps,
  getOfferActionsConfigByPermissions,
} from '../../general/offer/utils/actions';
import { filterOfferTableActions, getOfferTableTabActionsConfig } from '../../general/offer/utils/table';
import { BookingLifeCycle } from '../lifecycle/types';
import {
  BookingActionCreateType,
  BookingActionDetailsType,
  BookingActions,
  BookingActionTableType,
  EBookingActionType,
} from '../types';

export const getBookingActionName = (type: EBookingActionType): string => {
  switch (type) {
    case EBookingActionType.Pause:
      return 'Приостановить';
    case EBookingActionType.Archive:
      return 'Отправить в архив';
    case EBookingActionType.Delete:
      return 'Удалить';
    case EBookingActionType.Resume:
      return 'Возобновить';
    case EBookingActionType.Publish:
      return 'Отправить на проверку';
    case EBookingActionType.Approve:
      return 'Опубликовать';
    case EBookingActionType.Reject:
      return 'Отклонить';
    case EBookingActionType.UnPublish:
      return 'Отозвать';
    case EBookingActionType.ChangeModerator:
      return 'Назначить модератора';
    case EBookingActionType.Edit:
      return 'Редактировать';
    case EBookingActionType.ManageServices:
      return 'Управлять услугами';
    case EBookingActionType.Duplicate:
      return 'Копировать в черновики';
    case EBookingActionType.InWork:
      return 'Взять в работу';
    case EBookingActionType.ReturnToVerification:
      return 'Вернуть на проверку';
    case EBookingActionType.Retrieve:
      return 'Вернуть в предыдущий статус';
    case EBookingActionType.SaveNewVersion: {
      return 'Сохранить';
    }
  }
};

export type GetBookingActionsByPermissionsProps = UserAccessPartitionBookingOffers;

// получение разрешенных действий с объектов ДиО с учетом разрешений
export const getBookingActionsConfigByPermissions = (props: GetBookingActionsByPermissionsProps): BookingActions => {
  const actions: BookingActions = [];

  // 1. управлять услугами всегда первое
  if (props.manageServices) {
    actions.push({
      type: EBookingActionType.ManageServices,
      disabled: false,
      label: getBookingActionName(EBookingActionType.ManageServices),
      position: [EPanelActionPosition.Menu],
    });
  }

  // 2. основные действия в середине всегда
  if (props.publish) {
    actions.push({
      type: EBookingActionType.Publish,
      disabled: false,
      label: getBookingActionName(EBookingActionType.Publish),
      position: [EPanelActionPosition.Default],
    });
  }
  if (props.unPublish) {
    actions.push({
      type: EBookingActionType.UnPublish,
      disabled: false,
      label: getBookingActionName(EBookingActionType.UnPublish),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.resume) {
    actions.push({
      type: EBookingActionType.Resume,
      disabled: false,
      label: getBookingActionName(EBookingActionType.Resume),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.approve) {
    actions.push({
      type: EBookingActionType.Approve,
      disabled: false,
      label: getBookingActionName(EBookingActionType.Approve),
      position: [EPanelActionPosition.Default],
    });
  }
  if (props.reject) {
    actions.push({
      type: EBookingActionType.Reject,
      disabled: false,
      label: getBookingActionName(EBookingActionType.Reject),
      position: [EPanelActionPosition.Default],
    });
  }
  if (props.pause) {
    actions.push({
      type: EBookingActionType.Pause,
      disabled: false,
      label: getBookingActionName(EBookingActionType.Pause),
      position: [EPanelActionPosition.Menu],
    });
  }

  if (props.moderate) {
    actions.push({
      type: EBookingActionType.InWork,
      disabled: false,
      label: getBookingActionName(EBookingActionType.InWork),
      position: [EPanelActionPosition.Header],
    });
    actions.push({
      type: EBookingActionType.ReturnToVerification,
      disabled: false,
      label: getBookingActionName(EBookingActionType.ReturnToVerification),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.changeModerator) {
    actions.push({
      type: EBookingActionType.ChangeModerator,
      disabled: false,
      label: getBookingActionName(EBookingActionType.ChangeModerator),
      position: [EPanelActionPosition.Menu],
    });
  }

  if (props.edit) {
    actions.push({
      type: EBookingActionType.SaveNewVersion,
      disabled: false,
      label: getBookingActionName(EBookingActionType.SaveNewVersion),
      position: [EPanelActionPosition.Default],
    });
    actions.push({
      type: EBookingActionType.Edit,
      disabled: false,
      label: getBookingActionName(EBookingActionType.Edit),
      position: [EPanelActionPosition.Menu],
    });
  }

  // второстепенные действия всегда в конце
  if (props.retrieve) {
    actions.push({
      type: EBookingActionType.Retrieve,
      disabled: false,
      label: getBookingActionName(EBookingActionType.Retrieve),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.duplicate) {
    actions.push({
      type: EBookingActionType.Duplicate,
      disabled: false,
      label: getBookingActionName(EBookingActionType.Duplicate),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.delete) {
    actions.push({
      type: EBookingActionType.Delete,
      disabled: false,
      label: getBookingActionName(EBookingActionType.Delete),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (props.archive) {
    actions.push({
      type: EBookingActionType.Archive,
      disabled: false,
      label: getBookingActionName(EBookingActionType.Archive),
      position: [EPanelActionPosition.Menu],
    });
  }

  return actions;
};

export const removeBookingAction = (actions: BookingActions, type: EBookingActionType) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions.splice(index, 1);
  }
};

export const disableBookingAction = (actions: BookingActions, type: EBookingActionType) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      disabled: true,
    };
  }
};

export const changeBookingActionPosition = (
  actions: BookingActions<any>,
  type: EBookingActionType,
  position: EPanelActionPosition[]
) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      position,
    };
  }
};

export const renameBookingAction = (actions: BookingActions, type: EBookingActionType, label: string) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      label,
    };
  }
};

export const makeBookingActionPrimary = (actions: BookingActions<any>, types: EBookingActionType[]) => {
  return actions.map(action => ({
    ...action,
    primary: types.includes(action.type) ? true : action.primary,
  }));
};

export const isBookingActionActionEnabled = (actions: BookingActions<any>, type: EBookingActionType) => {
  return actions.some(action => action.type === type && !action.disabled);
};

// фильтрация действий для создания объекта ДиО по всем действиям
export const filterBookingCreateActions = (actions: BookingActions): BookingActions<BookingActionCreateType> => {
  const createActions: BookingActions<BookingActionCreateType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EBookingActionType.Edit:
      case EBookingActionType.Publish:
      case EBookingActionType.Duplicate:
      case EBookingActionType.Archive:
      case EBookingActionType.Delete:
      case EBookingActionType.ReturnToVerification:
      case EBookingActionType.ChangeModerator:
      case EBookingActionType.SaveNewVersion:
        createActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          primary: action.primary,
          position: action.position,
        });
        break;
      case EBookingActionType.Resume:
      case EBookingActionType.Pause:
      case EBookingActionType.Reject:
      case EBookingActionType.UnPublish:
      case EBookingActionType.Approve:
      case EBookingActionType.ManageServices:
      case EBookingActionType.InWork:
      case EBookingActionType.Retrieve:
        break;
    }
  });

  return createActions;
};

/**
 * Фильтрация экшенов подходящих для мастера просмотра
 * @param actions
 */
export const filterBookingDetailsActions = (actions: BookingActions): BookingActions<BookingActionDetailsType> => {
  const detailsActions: BookingActions<BookingActionDetailsType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EBookingActionType.Edit:
      case EBookingActionType.Duplicate:
      case EBookingActionType.Resume:
      case EBookingActionType.Archive:
      case EBookingActionType.Pause:
      case EBookingActionType.Reject:
      case EBookingActionType.Approve:
      case EBookingActionType.UnPublish:
      case EBookingActionType.ManageServices:
      case EBookingActionType.Retrieve:
      case EBookingActionType.InWork:
      case EBookingActionType.Publish:
      case EBookingActionType.ChangeModerator:
      case EBookingActionType.ReturnToVerification:
        detailsActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          primary: action.primary,
          position: action.position,
        });
        break;
      case EBookingActionType.Delete:
      case EBookingActionType.SaveNewVersion:
        break;
    }
  });

  return detailsActions;
};

// фильтрация действий для таблицы объектов ДиО по всем действиям
export const filterBookingTableActions = (actions: BookingActions): BookingActions<BookingActionTableType> => {
  const detailsActions: BookingActions<BookingActionTableType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EBookingActionType.Edit:
      case EBookingActionType.Duplicate:
      case EBookingActionType.Resume:
      case EBookingActionType.Archive:
      case EBookingActionType.Pause:
      case EBookingActionType.Reject:
      case EBookingActionType.UnPublish:
      case EBookingActionType.Publish:
      case EBookingActionType.ManageServices:
      case EBookingActionType.Delete:
      case EBookingActionType.Retrieve:
      case EBookingActionType.InWork:
      case EBookingActionType.ChangeModerator:
      case EBookingActionType.ReturnToVerification:
      case EBookingActionType.Approve:
        detailsActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          primary: action.primary,
          position: action.position,
        });
        break;
      case EBookingActionType.SaveNewVersion:
        break;
    }
  });

  return detailsActions;
};

/**
 * Получение экшенов для таблицы
 * @param lifecycle
 * @param offer
 * @param allowedActions
 */
export const getBookingOfferTableActions = (
  lifecycle: BookingLifeCycle,
  offer: BookingOfferShort,
  allowedActions: OfferActions<BookingActionTableType>
) => {
  return lifecycle.build({ obj: offer, allowedActions }).nextActions as OfferActions<BookingActionTableType>;
};

/**
 * Получение экшенов с фильтрацией по табам
 * @param permissions
 * @param tab
 */
export const getBookingOfferTableTabActions = (
  permissions: GetOfferActionsByPermissionsProps,
  tab: EOfferTableTab
): OfferActions<BookingActionTableType> => {
  const allowedActions = getOfferActionsConfigByPermissions(permissions);
  const actions = filterOfferTableActions(allowedActions);

  return getOfferTableTabActionsConfig({
    tab,
    actions,
  }) as unknown as OfferActions<BookingActionTableType>;
};
