import { BookingOffer } from '../../../../domain/model/booking';
import { ENoticeStatus } from '../../../../domain/model/enums';
import { Nullable } from '../../../../domain/model/types';
import Notifier from '../../../../system/notifier';
import { EOfferTableTab } from '../../general/offer/types';
import { EBookingActionType } from '../types';

export const showBookingOfferSaveErrorsNotification = (offer: BookingOffer, errors: string[]) => {
  Notifier.getInstance().addNotice(ENoticeStatus.Warning, errors.join('\n'));
};

export const showBookingOfferActionNotification = (
  bookingOffer: Nullable<BookingOffer>,
  action: EBookingActionType,
  isCreate: boolean
) => {
  let text;

  if (isCreate) {
    text = 'Черновик объекта Досуга и Отдыха создан';
  } else {
    switch (action) {
      case EBookingActionType.Edit:
        text = 'Изменения сохранены';
        break;
      case EBookingActionType.Archive:
        text = 'Объект Досуга и Отдыха отправлен в архив';
        break;
      case EBookingActionType.Delete:
        text = 'Объект Досуга и Отдыха удален';
        break;
      case EBookingActionType.Duplicate:
        text = 'Создана копия объекта Досуга и Отдыха';
        break;
      case EBookingActionType.ReturnToVerification:
        text = 'Объект вернулся на проверку';
        break;
      case EBookingActionType.Reject:
        text = 'Объект отклонен';
        break;
      case EBookingActionType.Approve:
        text = 'Объект опубликован';
        break;
      case EBookingActionType.Pause:
        text = 'Объект приостановлен';
        break;
      case EBookingActionType.Resume:
        text = 'Объект опубликован';
        break;
      case EBookingActionType.Publish:
        text = 'Объект был отправлен на проверку';
        break;
      case EBookingActionType.UnPublish:
        text = 'Объект отозван';
        break;
      case EBookingActionType.Retrieve:
        text = 'Объект вернулся в предыдущий статус';
        break;
      case EBookingActionType.InWork:
        text = 'Объект взят в работу';
        break;
      case EBookingActionType.ChangeModerator:
        text = 'У объекта поменялся модератор';
        break;
      case EBookingActionType.ManageServices:
        text = 'Услуги сохранены';
        break;
      case EBookingActionType.SaveNewVersion:
        text = 'Объект сохранен как черновик';
        break;
    }
  }

  Notifier.getInstance().addNotice(ENoticeStatus.Success, text);
};

/**
 * Получаем таб таблицы на который нужно перейти после выполнения экшена
 */
export const getBookingOfferTableTabByNameAction = (action: Nullable<EBookingActionType>): Nullable<EOfferTableTab> => {
  if (action) {
    switch (action) {
      case EBookingActionType.Approve:
      case EBookingActionType.Resume:
      case EBookingActionType.Publish: {
        return EOfferTableTab.Active;
      }
      case EBookingActionType.Pause:
      case EBookingActionType.UnPublish: {
        return EOfferTableTab.Paused;
      }
      case EBookingActionType.Reject: {
        return EOfferTableTab.Rejected;
      }
      case EBookingActionType.Archive: {
        return EOfferTableTab.Archived;
      }
      case EBookingActionType.Duplicate: {
        return EOfferTableTab.Draft;
      }
      case EBookingActionType.InWork:
      case EBookingActionType.ReturnToVerification:
      case EBookingActionType.ChangeModerator:
      case EBookingActionType.Retrieve: {
        return EOfferTableTab.Moderation;
      }
      case EBookingActionType.ManageServices:
      case EBookingActionType.Delete:
      case EBookingActionType.SaveNewVersion:
      case EBookingActionType.Edit: {
        return null;
      }
    }
  }

  return null;
};
