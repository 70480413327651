import ReportDownloadsDialogsAdapter from '@features/report/downloads/adapters/dialogs';
import { EReportType } from '@features/report/types';
import { FCC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ReportDownloadsContext, { ReportDownloadsContextType } from './context';
import { reportDownloadsChangeDialogState } from './store/slice';

const ReportDownloadsProvider: FCC = ({ children }) => {
  const dispatch = useDispatch();

  const onOpenDownloadDialog: ReportDownloadsContextType['onOpenDownloadDialog'] = ({ type, partnerId }) => {
    onChangeDialogState(type, { partnerId });
  };

  const onCloseDownloadDialog: ReportDownloadsContextType['onCloseDownloadDialog'] = reportType => {
    onChangeDialogState(reportType, null);
  };

  const onChangeDialogState = useCallback(
    (name: EReportType, data: Nullable<{ partnerId: Nullable<UUID> }>) => {
      dispatch(reportDownloadsChangeDialogState({ name, data }));
    },
    [dispatch]
  );

  const value: ReportDownloadsContextType = {
    onOpenDownloadDialog,
    onCloseDownloadDialog,
  };

  return (
    <ReportDownloadsContext.Provider value={value}>
      {children}
      <ReportDownloadsDialogsAdapter />
    </ReportDownloadsContext.Provider>
  );
};

export default ReportDownloadsProvider;
