import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../data/store/store';
import { UUID } from '../../../../../domain/model/types';
import Splitter from '../../../../components/common/splitter';
import PartnerDeskByIdContainer from '../../../partnerDesk/byId/container';
import PartnerDeskRestrictionContainer from '../../../partnerDesk/restriction/container';
import { ProductTableStubAdapterProps } from '../types';

export type ProductsPartnerStubAdapterProps = ProductTableStubAdapterProps & {
  readonly isEmptySelector: (state: RootState) => boolean;
  readonly partnerId: UUID;
};

const ProductsPartnerStubAdapter = (props: ProductsPartnerStubAdapterProps) => {
  const { partnerId, isEmptySelector, children } = props;

  const isEmpty = useSelector(isEmptySelector);

  const getStub = useCallback(
    (hasPartnerDesk: boolean) => {
      if (!hasPartnerDesk) {
        return (
          <PartnerDeskRestrictionContainer
            partnerId={partnerId}
            text='Для того, чтобы добавить товар, необходимо заполнить карточку компании, которая будет отображаться на Витрине.'
            description='Карточка компании будет объединять все товары вашей компании.'
          />
        );
      }

      if (isEmpty) {
        return (
          <>
            <Splitter size={1} />
            <Typography>Здесь будут отображаться ваши товары.</Typography>
            <Typography>Начните добалять товары через кнопку «Добавить товар»</Typography>
          </>
        );
      }

      return null;
    },
    [partnerId, isEmpty]
  );

  return (
    <PartnerDeskByIdContainer id={partnerId}>
      {({ partnerDesk }) =>
        children({
          canViewTable: !!partnerDesk && !isEmpty,
          isCreateDisabled: !partnerDesk,
          createInfo: !partnerDesk ? 'Добавление товара будет доступно после заполнения карточки компании' : null,
          stub: getStub(!!partnerDesk),
          isEmpty,
        })
      }
    </PartnerDeskByIdContainer>
  );
};

export default ProductsPartnerStubAdapter;
