import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { ECmsCollectionLinkObjectType, EPartnerStatus } from '../../../../../../../domain/model/enums';
import { PartnersCollection, PartnerShort } from '../../../../../../../domain/model/partner';
import { UUID } from '../../../../../../../domain/model/types';
import TopPushDialog from '../../../../../../components/common/dialogs/topPush';
import { viewConfig } from '../../../../../../theme/viewConfig';
import { ArrayElementType, NumberRange } from '../../../../../../types';
import { getPartnerManagementDetailsRoute } from '../../../../../partner/management/entry';
import PartnersSearchContainer from '../../../../../partner/search/container';
import CmsPartnerMultipleCollectionComponent from '../../../../components/collection/partners/multiple';
import { CmsLinkedObjectCollectionTypes } from '../../../../types/collection';
import useCmsCollectionPartnerMultiple from '../useChangePartnersMultiple';
import useCmsCollectionPartnerFilterAdapter from './useFilterAdapter';

interface CmsPartnerMultipleCollectionContainerProps {
  readonly guid: UUID;
  readonly partners: PartnersCollection;
  readonly selectedMaxCount: number;
  readonly onChangeLinkedObject: (banner: CmsLinkedObjectCollectionTypes) => void;
}

const partnerStatuses = [EPartnerStatus.Enabled];
const partnerDeskActive = true;

const CmsPartnerMultipleCollectionContainer = (props: CmsPartnerMultipleCollectionContainerProps) => {
  const { guid, partners, selectedMaxCount, onChangeLinkedObject } = props;

  const history = useHistory();

  const {
    isSearchDialogOpened,
    searchDialogParams,
    onOpenSearchDialog,
    onCloseSearchDialog,
    onSelect,
    onClear,
    onUnselect,
    onChangeSortIndex,
  } = useCmsCollectionPartnerMultiple<ArrayElementType<PartnersCollection>>({
    linkObjectType: ECmsCollectionLinkObjectType.Partner,
    selected: partners,
    onChangeLinkedObject,
  });

  const filterAdapter = useCmsCollectionPartnerFilterAdapter();

  const onPartnerClick = useCallback(
    (partner: PartnerShort) => {
      history.push(getPartnerManagementDetailsRoute({ id: partner.id }));
    },
    [history]
  );

  return (
    <>
      <CmsPartnerMultipleCollectionComponent
        partners={partners}
        onShowPartnerSearch={onOpenSearchDialog}
        onClearPartners={onClear}
        onRemovePartner={onUnselect}
        onChangeSortIndex={onChangeSortIndex}
      />
      <TopPushDialog
        fullScreen
        stickyHeader
        open={isSearchDialogOpened}
        title='Выбор партнёров'
        leftOffset={viewConfig.stepper.width}
        onClose={onCloseSearchDialog}
      >
        <PartnersSearchContainer
          guid={searchDialogParams?.dialog_guid ?? guid}
          filterAdapter={filterAdapter}
          statuses={partnerStatuses}
          deskActive={partnerDeskActive}
          initialSelected={partners}
          selectedMaxCount={selectedMaxCount as NumberRange<2, 100>}
          onPartnerClick={onPartnerClick}
          onSelect={onSelect}
        />
      </TopPushDialog>
    </>
  );
};

export default CmsPartnerMultipleCollectionContainer;
