import { SportOptionTyped } from '../../../../../domain/model';
import { ETradeOfferType } from '../../../../../domain/model/enums';
import TradeOfferTableCell, { TradeOfferTableCellCommonProps } from './index';

export type TradeOfferTableCellPromotionTypeProps = TradeOfferTableCellCommonProps & {
  readonly tradePromotionTypes: SportOptionTyped<ETradeOfferType>[];
};

const TradeOfferTableCellPromotionType = (props: TradeOfferTableCellPromotionTypeProps) => {
  const {
    tradeOffer: { promotionType },
    tradePromotionTypes,
  } = props;

  const value = tradePromotionTypes.find(item => item.id === promotionType)?.name ?? '-';

  return (
    <TradeOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default TradeOfferTableCellPromotionType;
