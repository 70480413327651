import { useContext } from 'react';
import TechConfigContext, { TechConfigContextType } from '../features/general/config/contexts/techConfigContext';

type UseTechConfig = () => TechConfigContextType;

const useTechConfig: UseTechConfig = () => {
  return useContext<TechConfigContextType>(TechConfigContext);
};

export default useTechConfig;
