import { AppFile } from '@/domain';
import { convertBytesToUnitText } from '@/presentation/utils/files';
import { createHTMLImageElementByFile } from '@/presentation/utils/images';
import Notifier from '@/system/notifier';
import FileInfo from '@components/common/files/info';
import FileUploader from '@components/common/files/uploader';
import AppImage from '@components/common/images/common';
import { clientOrgFaviconRestrictions } from '@features/clientOrg/create/utils';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { ClientOrgCreateFieldCommonProps } from '../../../types';
import { FaviconWrapper } from './controls';

const fieldName = 'favicon';

export const ClientOrgCreateFaviconFieldAdapter = (props: ClientOrgCreateFieldCommonProps<typeof fieldName>) => {
  const { value, validation, onChangeAttribute, disabled } = props;

  const { recommendedWidthInPx, recommendedHeightInPx, fileType, fileAccept, maxFileSizeInBytes, whStrict } =
    clientOrgFaviconRestrictions;

  const onFilter = useCallback(
    (files: File[]): Promise<File[]> => {
      if (files.length > 0) {
        const file = files[0];
        return createHTMLImageElementByFile(file).then(({ width, height }) => {
          const isWidthIncorrect = width !== recommendedWidthInPx;
          const isHeightIncorrect = height !== recommendedHeightInPx;

          if (whStrict && isWidthIncorrect) {
            Notifier.getInstance().addErrorNotice(`Ширина изображения должна быть ${recommendedWidthInPx} пикселей`);
            return [];
          }
          if (whStrict && isHeightIncorrect) {
            Notifier.getInstance().addErrorNotice(`Высота изображения должна быть ${recommendedHeightInPx} пикселей`);
            return [];
          }
          return [file];
        });
      }
      return Promise.resolve(files);
    },
    [recommendedWidthInPx, recommendedHeightInPx, whStrict]
  );

  const onUpload = (file: AppFile) => {
    onChangeAttribute(fieldName, { path: file.path });
  };

  const onRemove = () => {
    onChangeAttribute(fieldName, null);
  };

  const fileTypes = fileAccept
    .map(ft => ft.replace('.', ''))
    .join(', ')
    .toUpperCase();
  const fileSize = convertBytesToUnitText(maxFileSizeInBytes);

  const description = `${fileTypes} не более ${fileSize} и размером ${recommendedWidthInPx}px на ${recommendedHeightInPx}px`;

  return value?.path ? (
    <FileInfo
      id={value.path}
      icon={
        <FaviconWrapper>
          <AppImage src={value} />
        </FaviconWrapper>
      }
      onRemove={onRemove}
    />
  ) : (
    <FileUploader
      disabled={disabled}
      error={validation?.hasError}
      helperText={validation?.message}
      fileTypes={fileType}
      accept={fileAccept}
      fileMaxSize={maxFileSizeInBytes}
      text={
        <div>
          <Typography
            variant='body1'
            color='primary'
            align='center'
          >
            Загрузить изображение
          </Typography>
          <Typography
            variant='body2'
            color='textSecondary'
            align='center'
          >
            {description}
          </Typography>
        </div>
      }
      onUpload={onUpload}
      onFilter={onFilter}
    />
  );
};
