import { CorpOfferActivation } from '@/domain/model/activation';
import { ESortDirection } from '@/domain/model/enums';
import { addSearchParamToLocation } from '@/routing/globalRouting';
import { partition } from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../data/store/store';
import { PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import { useCorpActivationActions } from '../../actions/useAction';
import { ECorpActivationUrlParam } from '../../entry';
import { ECorpActivationTableColumn, ECorpActivationTableTab } from '../../types';
import {
  CorpActivationsFilterEditStrategy,
  CorpActivationsFilterValues,
  ECorpActivationsFilterItem,
} from '../../utils/filter';
import { corpActivationsGuidSelector } from '../store/selectors';
import {
  corpActivationsDataReset,
  corpActivationsNeedRefreshWatcherIncrement,
  corpActivationsSetFilter,
  corpActivationsSetPage,
  corpActivationsSetPageSize,
  corpActivationsSetSort,
  corpActivationsSortReset,
} from '../store/slice';

export type UseCorpActivationTable = {
  readonly onRefresh: () => void;
  readonly onResetSort: () => void;

  readonly onChangeSort: (name: ECorpActivationTableColumn, direction: ESortDirection) => void;
  readonly onChangeFilter: (strategies: CorpActivationsFilterEditStrategy[]) => void;
  readonly onChangePage: (num: number) => void;
  readonly onChangePageSize: (size: PaginationSize) => void;
  readonly onSortReset: () => void;
  readonly onClearFilter: () => void;
  readonly onChangeTab: (newTab: ECorpActivationTableTab) => void;

  readonly onActivationGive: (props: CorpOfferActivation) => void;
  readonly onActivationUnGive: (props: CorpOfferActivation) => void;
};

type UseCorpActivationTableHandlersProps = {
  readonly tab: ECorpActivationTableTab;
};

export const useCorpActivationTableHandlers = ({
  tab,
}: UseCorpActivationTableHandlersProps): UseCorpActivationTable => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { onUnGive, onGive } = useCorpActivationActions();

  const getCorpActivationGuidFromState = useCallback((): Nullable<UUID> => {
    const state = store.getState();
    return corpActivationsGuidSelector(state);
  }, [store]);

  const onRefresh = useCallback(() => dispatch(corpActivationsNeedRefreshWatcherIncrement()), [dispatch]);
  const onResetSort = useCallback(() => dispatch(corpActivationsSortReset()), [dispatch]);

  const onChangeFilter = useCallback(
    (strategies: CorpActivationsFilterEditStrategy[]) => {
      dispatch(
        corpActivationsSetFilter({
          filter: getDataFilterValuesByStrategies<ECorpActivationsFilterItem, CorpActivationsFilterValues>(strategies),
        })
      );
    },
    [partition]
  );

  const onChangePage = useCallback(
    (pageNumber: number) => {
      dispatch(corpActivationsSetPage({ pageNumber }));
    },
    [dispatch]
  );

  const onChangeSort = useCallback(
    (name: ECorpActivationTableColumn, direction: ESortDirection) => {
      dispatch(
        corpActivationsSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(corpActivationsSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onSortReset = useCallback(() => {
    dispatch(corpActivationsSortReset());
  }, [dispatch]);

  const onClearFilter = useCallback(() => {
    dispatch(corpActivationsSetFilter({ filter: {} }));
  }, [partition]);

  const onChangeTab = useCallback(
    (newTab: ECorpActivationTableTab) => {
      if (newTab !== tab) {
        const guid = getCorpActivationGuidFromState();
        dispatch(corpActivationsDataReset());
        history.replace(
          addSearchParamToLocation({
            location,
            param: ECorpActivationUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [dispatch, history, location, tab, getCorpActivationGuidFromState]
  );

  const onActivationGive = useCallback<UseCorpActivationTable['onActivationGive']>(onGive, [onGive]);
  const onActivationUnGive = useCallback<UseCorpActivationTable['onActivationUnGive']>(onUnGive, [onUnGive]);

  return {
    onRefresh,
    onResetSort,
    onChangeSort,
    onChangeFilter,
    onChangePage,
    onChangePageSize,
    onSortReset,
    onClearFilter,
    onChangeTab,
    onActivationGive,
    onActivationUnGive,
  };
};
