import {PanelActions} from "../../../../../types";

export interface ProductOrderItemTableCellCommonProps {
  readonly disabled?: boolean;
}

export enum EProductOrderActionType {
  Delete = 'delete',
  Cancel = 'cancel',
  Confirm = 'confirm',
}

export type ProductOrderActionCommonType =
  | EProductOrderActionType.Cancel
  | EProductOrderActionType.Delete
  | EProductOrderActionType.Confirm;

export type ProductOrderAction<A extends string = EProductOrderActionType> = PanelActions<A>;
