import { Button, ButtonProps, ButtonTypeMap, ExtendButtonBaseTypeMap } from '@mui/material';
import { OverridableComponent, OverridableTypeMap, OverrideProps } from '@mui/material/OverridableComponent';
import { forwardRef } from 'react';

type MPButtonPropsType<M extends OverridableTypeMap> = { href: string } & OverrideProps<
  ExtendButtonBaseTypeMap<M>,
  'a'
>;

export type MPButtonType<M extends OverridableTypeMap> = ((props: MPButtonPropsType<M> & { ref: any }) => JSX.Element) &
  OverridableComponent<ExtendButtonBaseTypeMap<M>>;

export const MPButton: any = forwardRef<MPButtonType<ButtonTypeMap>>((props: ButtonProps, ref: any) => {
  const { onClick, variant, children, ...others } = props;
  return (
    <Button
      ref={ref}
      fullWidth
      color='primary'
      variant={variant || 'contained'}
      onClick={onClick}
      {...others}
    >
      {children}
    </Button>
  );
});
