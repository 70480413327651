import { combineReducers } from '@reduxjs/toolkit';
import container, { CmsContainerState } from './container/reducers';
import sitePage, { CmsSitePageState } from './sitePage/reducers';

interface CmsState {
  sitePage: CmsSitePageState;
  container: CmsContainerState;
}

export default combineReducers<CmsState>({
  sitePage,
  container,
});
