import { Nullable } from '../../../../../domain/model/types';
import { CmsContainerLifeCycle } from '../lifecycle/types';
import { ActionCmsContainerType, CmsContainerActionDetailsType, CmsContainerActions } from '../types';
import { filterCmsContainerDetailsActions } from '../utils/actions';

type CmsContainerType = ActionCmsContainerType;

type UseCmsContainerDetailsActions = CmsContainerActions<CmsContainerActionDetailsType>;

interface UseCmsContainerDetailsActionsProps {
  readonly allowedActions: CmsContainerActions;
  readonly lifecycle: CmsContainerLifeCycle;
  readonly cmsContainer: Nullable<CmsContainerType>;
  readonly isLastStep: boolean;
}

const useCmsContainerDetailsActions = (props: UseCmsContainerDetailsActionsProps): UseCmsContainerDetailsActions => {
  const { allowedActions, lifecycle, cmsContainer } = props;

  if (!cmsContainer) {
    return [];
  }

  const lifeCycleRules = lifecycle.build({
    allowedActions,
    cmsContainer,
  });

  const { nextActions } = lifeCycleRules;
  const detailsActions = filterCmsContainerDetailsActions(nextActions);

  return detailsActions;
};

export default useCmsContainerDetailsActions;
