import { useSelector } from 'react-redux';
import { OfferCategory } from '../../../../../../domain/model';
import { DictionaryTreeEdit } from '../../../../../components/common/dictionary/tree';
import { DictionaryEditItem } from '../../../../../components/common/dictionary/tree/item';
import { DictionaryAddItem } from '../../../../../components/common/dictionary/tree/item/add';
import { ContentWrapper } from '../controls';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingOfferCategoriesEditDataSelector } from '../store/selectors';
import { BookingOfferCategoriesEditAccess } from '../types';

const levelsLimit = 1;

type BookingOfferCategoriesEditDictionaryAdapterProps = {
  readonly access: BookingOfferCategoriesEditAccess;
};

export const BookingOfferCategoriesEditDictionaryAdapter = (
  props: BookingOfferCategoriesEditDictionaryAdapterProps
) => {
  const { access } = props;

  const data = useSelector(bookingOfferCategoriesEditDataSelector);

  const handlers = useContextHandlers();

  return (
    <ContentWrapper>
      {data && (
        <DictionaryTreeEdit<OfferCategory>
          data={data}
          slots={{
            item: props => (
              <DictionaryEditItem
                {...props}
                onEdit={access.canEdit ? () => handlers.onChangeDialogState('modify', props.data) : undefined}
                onDelete={access.canDelete ? () => handlers.onChangeDialogState('delete', props.data) : undefined}
              />
            ),
            levelHeader: access.canCreate
              ? props => (
                  <DictionaryAddItem
                    {...props}
                    fixed
                    onClick={() => handlers.onChangeDialogState('add', { parent: props.parent })}
                  >
                    Добавить категорию
                  </DictionaryAddItem>
                )
              : undefined,
          }}
          hasLevel={level => level + 1 <= levelsLimit}
        />
      )}
    </ContentWrapper>
  );
};
