import BookingOrderTableCell, { BookingOrderTableCellCommonProps } from './index';

const BookingOrderTableCellNumber = (props: BookingOrderTableCellCommonProps) => {
  const {
    bookingOrder: { number },
  } = props;

  return (
    <BookingOrderTableCell
      {...props}
      value={number}
    />
  );
};

export default BookingOrderTableCellNumber;
