import { AddressLocalityFullPathView } from '../../../address';
import { SportsmanTableCellCommonProps } from './index';

const SportsmanTableCellLocality = (props: SportsmanTableCellCommonProps) => {
  const {
    sportsman: { locality },
  } = props;

  return <AddressLocalityFullPathView address={locality} />;
};

export default SportsmanTableCellLocality;
