import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { CmsComponent } from '../../../../../domain/model/cms';
import { UUID } from '../../../../../domain/model/types';
import { addSearchParamToLocation } from '../../../../../routing/globalRouting';
import useStepper, { UseStepper } from '../../../general/stepper/useStepper';
import { CmsContainerStep, ECmsContainerUrlParam } from '../types';
import { getCmsContainerSteps } from '../utils';

type UseCmsContainerStepper = UseStepper<number, CmsContainerStep>;

interface UseCmsContainerStepperProps {
  readonly currentStepKey: CmsContainerStep['key'];
  readonly cmsComponents: CmsComponent[];
}

const useCmsContainerStepper = (props: UseCmsContainerStepperProps): UseCmsContainerStepper => {
  const { currentStepKey, cmsComponents } = props;

  const history = useHistory();
  const location = useLocation();

  const steps = useMemo(() => getCmsContainerSteps(cmsComponents), [cmsComponents]);

  const openStep = useCallback(
    (step: CmsContainerStep) => {
      history.push(
        addSearchParamToLocation<{ guid: UUID }>({
          location,
          param: ECmsContainerUrlParam.Step,
          value: step.key.toString(10),
          state: (location.state as any) ?? { guid: uuidv4() },
        })
      );
    },
    [history, location]
  );

  return useStepper<number, CmsContainerStep>({ currentStepKey, steps, openStep });
};

export default useCmsContainerStepper;
