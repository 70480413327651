import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable } from '../../../../../domain/model';
import { SportEventShort } from '../../../../../domain/model/event';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { PaginationSize } from '../../../../types';
import { friendlyMatchCreateSave } from '../../create/friendlyMatch/store/slice';
import { sportsFestCreateSave } from '../../create/sportsFest/store/slice';
import { tournamentCreateSave } from '../../create/tournament/store/slice';
import { eventEditSave } from '../../edit/legacy/store/slice';
import eventServices from '../../services';
import { AllProps } from '../../services/common';
import { EventTableFilterValues } from '../filterUtils';
import { ESportEventTableColumn, ESportEventTableTab, SportEventTableTabsCounter } from '../utils';

export type EventsFetchProps = Omit<AllProps, 'signal'>;

type SportEventsCountsFetchProps = EventsFetchProps & {
  readonly tabs: ESportEventTableTab[];
};

const defaultSort = `${ESportEventTableColumn.Name},asc`;

export const eventsFetch = createAsyncThunk<Pageable<SportEventShort>, EventsFetchProps, AppThunkAPIConfig>(
  'event/list/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await eventServices.common.all({ ...props, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const eventsCountsFetch = createAsyncThunk<
  SportEventTableTabsCounter,
  SportEventsCountsFetchProps,
  AppThunkAPIConfig
>('event/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await eventServices.common.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface EventTableState extends Fetchable, Pageable<SportEventShort> {
  readonly guid: Nullable<UUID>;
  readonly tab: Nullable<ESportEventTableTab>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
    readonly name: Nullable<string>;
  };
  readonly filter: EventTableFilterValues;
  readonly tabsCounter: SportEventTableTabsCounter;
  readonly dialogs: {
    readonly tournamentType: boolean;
  };
}

type Reducer<T = undefined> = CaseReducer<EventTableState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<EventTableState> {
  eventsStartSession: Reducer<{ guid: UUID }>;
  eventsSetTab: Reducer<{ tab: ESportEventTableTab }>;
  eventsSetFilter: Reducer<EventTableFilterValues>;
  eventsSetPage: Reducer<{ pageNumber: number }>;
  eventsSetSort: Reducer<{ sort: string }>;
  eventsSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  eventsDataReset: Reducer;
  eventsSortReset: Reducer;
  eventsNeedRefreshWatcherReset: Reducer;
  eventsSetDialog: Reducer<{ name: keyof EventTableState['dialogs']; value: boolean }>;
}

const slice = createSlice<EventTableState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    ...fetchableDefault,
    guid: null,
    tab: null,
    data: [],
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    needRefreshWatcher: 0,

    search: {
      sort: defaultSort,
      pageSize: 15,
      name: null,
    },
    filter: {},
    tabsCounter: {},
    dialogs: {
      tournamentType: false,
    },
  },
  reducers: {
    eventsStartSession: (state, { payload }) => {
      const { guid } = payload;
      if (guid !== state.guid) {
        state.guid = guid;
        state.needRefreshWatcher = 0;
        state.tabsCounter = {};

        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;

        state.tab = null;
      }
    },
    eventsSetTab: (state, { payload }) => {
      const { tab } = payload;
      // сбрасываем только пейджинг, если поменялся таб (сортировку смысла нет - набор полей одинаков)
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    eventsSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    eventsSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    eventsSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    eventsSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    eventsDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
      state.dialogs = {
        tournamentType: false,
      };
    },
    eventsSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    eventsNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
    eventsSetDialog: (state, { payload }) => {
      const { name, value } = payload;
      state.dialogs[name] = value;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(eventsFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
      })
      .addCase(eventsFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(eventsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(sportsFestCreateSave.fulfilled, state => {
        state.needRefreshWatcher++;
      })
      .addCase(tournamentCreateSave.fulfilled, state => {
        state.needRefreshWatcher++;
      })
      .addCase(friendlyMatchCreateSave.fulfilled, state => {
        state.needRefreshWatcher++;
      })
      .addCase(eventEditSave.fulfilled, state => {
        state.needRefreshWatcher++;
      })
      .addCase(eventsCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(eventsCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      });
  },
});

export const {
  eventsStartSession,
  eventsSetFilter,
  eventsSetPage,
  eventsSetPageSize,
  eventsDataReset,
  eventsSetSort,
  eventsSortReset,
  eventsNeedRefreshWatcherReset,
  eventsSetTab,
  eventsSetDialog,
} = slice.actions;

export default slice.reducer;
