import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useStepper, { UseStepper } from '../../../general/stepper/useStepper';
import { ClientOrgCreateStep, ClientOrgCreateStepType } from '../../types';
import { clientOrgCreateUiStepsStateSelector } from '../store/selectors';
import { useContextHandlers } from './useContextHandlers';
import { useContextParams } from './useContextParams';

const useClientOrgCreateStepper = (): UseStepper<ClientOrgCreateStepType, ClientOrgCreateStep> => {
  const { step } = useContextParams();
  const handlers = useContextHandlers();

  const steps = useSelector(clientOrgCreateUiStepsStateSelector) ?? [];

  useEffect(() => {
    if (step && steps?.length > 0 && !steps.find(s => s.key === step)) {
      handlers.onChangeStep(steps[0]);
    }
  }, [step, steps, handlers.onChangeStep]);

  return useStepper({ currentStepKey: step, steps, openStep: handlers.onChangeStep });
};

export default useClientOrgCreateStepper;
