import React from 'react';
import { OfferCategory } from '../../../../../domain/model';
import { Nullable } from '../../../../../domain/model/types';
import { CorpOfferCategoriesEditState } from './store/slice';

export type CorpOfferCategoriesEditHandlersContextValue = {
  readonly onChangeDialogState: <Name extends keyof CorpOfferCategoriesEditState['dialogs']>(
    name: Name,
    data: Nullable<CorpOfferCategoriesEditState['dialogs'][Name]>
  ) => void;
  readonly onCreate: (corpOfferCategory: OfferCategory) => Promise<OfferCategory>;
  readonly onEdit: (corpOfferCategory: OfferCategory) => Promise<OfferCategory>;
  readonly onDelete: (corpOfferCategory: OfferCategory) => Promise<void>;
  readonly onGetRelationShipsCount: (corpOfferCategory: OfferCategory) => Promise<number>;
  readonly onClose: () => void;
};

export const CorpOfferCategoriesEditHandlersContext = React.createContext<CorpOfferCategoriesEditHandlersContextValue>(
  {} as CorpOfferCategoriesEditHandlersContextValue
);
