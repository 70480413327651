import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UUID } from '../../../../../domain/model/types';
import { EOfferTableTab } from '../../../general/offer/types';
import { corpOffersGuidSelector } from '../store/selectors';
import { corpOffersSetTab, corpOffersStartSession } from '../store/slice';

interface UseCorpOffersTableSessionProps {
  readonly guid: UUID;
  readonly tab: EOfferTableTab;
}

const useCorpOffersTableSession = (props: UseCorpOffersTableSessionProps): void => {
  const { guid, tab } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(corpOffersGuidSelector);

  // инициализация сеанса
  useEffect(() => {
    dispatch(corpOffersStartSession({ guid }));
  }, [dispatch, guid]);

  // начальная загрузка, изменение вкладки
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(corpOffersSetTab({ tab }));
    }
  }, [dispatch, guid, currentGuid, tab]);
};

export default useCorpOffersTableSession;
