import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import jsonpatch from 'fast-json-patch';
import {
  CmsBanner,
  CmsBannerRequest,
  CmsCollection,
  CmsCollectionRequest,
  CmsComponent,
  CmsComponentRequest,
  CmsContainer,
  CmsContainerCommand,
  CmsContainerRequest,
  CmsSitePage,
  CmsSitePageRequest,
  CmsTerm,
  CmsTermRequest,
} from '../../domain/model/cms';
import { ECmsContainerDiscriminator } from '../../domain/model/enums';
import { Nullable, UUID } from '../../domain/model/types';
import { createCancelToken } from './index';
import { ApiCancellable, ApiQueryDsl } from './types';
import { appendQueryDslParams, getComServicesEndpoint } from './utils';

type AllProps = ApiCancellable & {
  readonly query?: string;
  readonly querydsl?: Nullable<ApiQueryDsl>;
};

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type DeleteProps = {
  readonly id: UUID;
};

type CmsPageSaveProps = {
  readonly id?: Nullable<UUID>;
  readonly data: CmsSitePageRequest;
};

type CmsPagePatchProps = {
  readonly id?: Nullable<UUID>;
  readonly oldData: CmsSitePage;
  readonly newData: CmsSitePage;
};

type CmsContainerCreateProps = {
  readonly sitePageId: UUID;
  readonly data: CmsContainerRequest;
};

type CmsContainerSaveProps = {
  readonly id: UUID;
  readonly data: CmsContainerRequest;
  readonly discriminator: ECmsContainerDiscriminator;
};

type CmsComponentSaveProps = {
  readonly cmsContainerId: UUID;
  readonly id?: Nullable<UUID>;
  readonly data: CmsComponentRequest;
};

type CmsContainerPatchProps = {
  readonly id: UUID;
  readonly oldData: CmsContainerRequest;
  readonly newData: CmsContainerRequest;
};

type CmsContainerPostCommandProps = {
  readonly id: UUID;
  readonly command: CmsContainerCommand;
};

type CmsContainerDuplicateProps = {
  readonly sitePageId: UUID;
  readonly id: UUID;
};

type CmsTermCreateProps = CmsTermRequest;

type CmsBannerSaveProps = {
  readonly id?: Nullable<UUID>;
  readonly data: CmsBannerRequest;
};

type CmsCollectionSaveProps = {
  readonly id?: Nullable<UUID>;
  readonly data: CmsCollectionRequest;
};

type CmsTermAllProps = ApiCancellable & {
  readonly querydsl?: Nullable<ApiQueryDsl>;
};

type CmsTermOneProps = ApiCancellable & {
  readonly termId: UUID;
};

type CmsTermDeleteProps = {
  readonly termId: UUID;
};

type CmsApi = {
  readonly page: {
    readonly all: (props: AllProps) => Promise<AxiosResponse<CmsSitePage[]>>;
    readonly one: (props: OneProps) => Promise<AxiosResponse<CmsSitePage>>;
    readonly save: (props: CmsPageSaveProps) => Promise<AxiosResponse<CmsSitePage>>;
    readonly patch: (props: CmsPagePatchProps) => Promise<AxiosResponse<CmsSitePage>>;
  };
  readonly container: {
    readonly all: (props: AllProps) => Promise<AxiosResponse<CmsContainer[]>>;
    readonly one: (props: OneProps) => Promise<AxiosResponse<CmsContainer>>;
    readonly create: (props: CmsContainerCreateProps) => Promise<AxiosResponse<CmsContainer>>;
    readonly save: (props: CmsContainerSaveProps) => Promise<AxiosResponse<CmsContainer>>;
    readonly patch: (props: CmsContainerPatchProps) => Promise<AxiosResponse<CmsContainer>>;
    readonly postCommand: (props: CmsContainerPostCommandProps) => Promise<AxiosResponse<CmsContainer>>;
    readonly delete: (props: DeleteProps) => Promise<AxiosResponse<void>>;
    readonly duplicate: (props: CmsContainerDuplicateProps) => Promise<AxiosResponse<CmsContainer>>;
  };
  readonly component: {
    readonly save: (props: CmsComponentSaveProps) => Promise<AxiosResponse<CmsComponent>>;
  };
  readonly banner: {
    readonly one: (props: OneProps) => Promise<AxiosResponse<CmsBanner>>;
    readonly save: (props: CmsBannerSaveProps) => Promise<AxiosResponse<CmsBanner>>;
  };
  readonly collection: {
    readonly one: (props: OneProps) => Promise<AxiosResponse<CmsCollection>>;
    readonly save: (props: CmsCollectionSaveProps) => Promise<AxiosResponse<CmsCollection>>;
  };
  readonly term: {
    readonly create: (props: CmsTermCreateProps) => Promise<AxiosResponse<CmsTerm>>;
    readonly all: (props: CmsTermAllProps) => Promise<AxiosResponse<CmsTerm[]>>;
    readonly one: (props: CmsTermOneProps) => Promise<AxiosResponse<CmsTerm>>;
    readonly delete: (props: CmsTermDeleteProps) => Promise<AxiosResponse<void>>;
  };
};

/**
 * АПИ по работе с cms
 */
const cms: CmsApi = {
  page: {
    all: props => {
      const { querydsl, signal } = props;

      const params = new URLSearchParams();

      params.append('page', '0');
      params.append('size', '10000000');
      if (querydsl) appendQueryDslParams(params, querydsl);

      return axios.get(`${getComServicesEndpoint()}/cms/pages`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    one: ({ id, signal }) => {
      return axios.get(`${getComServicesEndpoint()}/cms/pages/${id}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    save: ({ id, data }) => {
      if (id) {
        return axios.put(`${getComServicesEndpoint()}/cms/pages/${id}`, data);
      } else {
        return axios.post(`${getComServicesEndpoint()}/cms/pages`, data);
      }
    },
    patch: ({ id, oldData, newData }) => {
      const requestConfig: AxiosRequestConfig = {
        headers: {
          'content-type': 'application/json-patch+json',
        },
      };
      return axios.patch(
        `${getComServicesEndpoint()}/cms/pages/${id}`,
        jsonpatch.compare(oldData, newData),
        requestConfig
      );
    },
  },
  container: {
    all: props => {
      const { query, querydsl, signal } = props;

      const params = new URLSearchParams();

      params.append('page', '0');
      params.append('size', '10000000');
      if (query) params.append('query', query);
      if (querydsl) appendQueryDslParams(params, querydsl);

      return axios.get(`${getComServicesEndpoint()}/cms/containers`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    one: ({ id, signal }) => {
      return axios.get(`${getComServicesEndpoint()}/cms/containers/${id}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    create: ({ sitePageId, data }) => {
      return axios.post(`${getComServicesEndpoint()}/cms/pages/${sitePageId}/containers`, {
        discriminator: ECmsContainerDiscriminator.ContainerCreateRequestData,
        ...data,
      });
    },
    save: ({ id, data, discriminator }) => {
      return axios.put(`${getComServicesEndpoint()}/cms/containers/${id}`, {
        discriminator,
        ...data,
      });
    },
    patch: ({ id, oldData, newData }) => {
      const requestConfig: AxiosRequestConfig = {
        headers: {
          'content-type': 'application/json-patch+json',
        },
      };
      return axios.patch(
        `${getComServicesEndpoint()}/cms/containers/${id}`,
        jsonpatch.compare(oldData, newData),
        requestConfig
      );
    },
    postCommand: ({ id, command }) => {
      return axios.post(`${getComServicesEndpoint()}/cms/containers/${id}`, {
        discriminator: command,
      });
    },
    delete: ({ id }) => {
      return axios.delete(`${getComServicesEndpoint()}/cms/containers/${id}`);
    },
    duplicate: ({ sitePageId, id }) => {
      return axios.post(`${getComServicesEndpoint()}/cms/pages/${sitePageId}/containers`, {
        discriminator: ECmsContainerDiscriminator.ContainerCopyLink,
        id,
      });
    },
  },
  component: {
    save: ({ cmsContainerId, id, data }) => {
      if (id) {
        return axios.put(`${getComServicesEndpoint()}/cms/components/${id}`, data);
      } else {
        return axios.post(`${getComServicesEndpoint()}/cms/containers/${cmsContainerId}/components`, data);
      }
    },
  },
  collection: {
    one: ({ id, signal }) => {
      return axios.get(`${getComServicesEndpoint()}/cms/collections/${id}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    save: ({ id, data }) => {
      if (id) {
        return axios.put(`${getComServicesEndpoint()}/cms/collections/${id}`, data);
      } else {
        return axios.post(`${getComServicesEndpoint()}/cms/collections`, data);
      }
    },
  },
  banner: {
    one: ({ id, signal }) => {
      return axios.get(`${getComServicesEndpoint()}/cms/banners/${id}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    save: ({ id, data }) => {
      if (id) {
        return axios.put(`${getComServicesEndpoint()}/cms/banners/${id}`, data);
      } else {
        return axios.post(`${getComServicesEndpoint()}/cms/banners`, data);
      }
    },
  },
  term: {
    create: data => {
      return axios.post(`${getComServicesEndpoint()}/cms/terms`, data);
    },
    all: props => {
      const { querydsl, signal } = props;

      const params = new URLSearchParams();

      if (querydsl) {
        appendQueryDslParams(params, querydsl);
      }

      return axios.get(`${getComServicesEndpoint()}/cms/terms`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    one: ({ termId, signal }) => {
      return axios.get(`${getComServicesEndpoint()}/cms/terms/${termId}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    delete: ({ termId }) => {
      return axios.delete(`${getComServicesEndpoint()}/cms/terms/${termId}`);
    },
  },
};

export default cms;
