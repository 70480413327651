import { SyntheticEvent, useState } from 'react';
import { Nullable } from '../../../../../../domain/model/types';
import { MPCostFormat } from '../../../../../theme/ui-kit/format';
import { MPFormInputProps } from '../../../../../theme/ui-kit/input';
import { formatCost } from '../../../../../utils';
import DataTableCellEditable from '../editable';

interface DataTableCellCostEditProps {
  readonly value: Nullable<number>;
  readonly min?: number;
  readonly max: number;
  readonly decimalScale?: number;
  readonly editMode?: boolean;
  readonly align?: 'left' | 'right';
  readonly hideEditIcon: boolean;
  readonly isFetching: boolean;
  readonly label: (min: number, max: number) => string;
  readonly onChange: (value: number) => void;
  readonly onChangeEditMode: (isEditMode: boolean, event: SyntheticEvent) => void;
  readonly textFieldProps?: MPFormInputProps;
}

const DataTableCellCostEdit = (props: DataTableCellCostEditProps) => {
  const {
    value,
    min = 0,
    max,
    decimalScale = 0,
    editMode = false,
    align = 'right',
    hideEditIcon,
    isFetching,
    label,
    onChange,
    onChangeEditMode,
    textFieldProps = {},
  } = props;

  const [helpText, setHelpText] = useState<Nullable<string>>(null);

  const onInternalChangeCost = (oldValue: number, newValue: number) => {
    if (oldValue === newValue) {
      return true;
    }

    if (newValue < min || newValue > max) {
      setHelpText(`Допустимые значения с ${min} по ${formatCost(max)}`);
      return false;
    } else {
      setHelpText('');
      onChange(newValue);
      return true;
    }
  };

  return (
    <DataTableCellEditable
      editMode={editMode}
      align={align}
      textFieldProps={{
        ...textFieldProps,
        label: label(min, max),
        value,
        error: !!helpText,
        helperText: helpText,
        helperInTooltip: true,
        helperTooltipStateless: true,
        InputProps: {
          inputProps: {
            decimalScale,
            onFocus: event => event.target.select(),
          },
          inputComponent: MPCostFormat,
        },
      }}
      isFetching={isFetching}
      hideEditIcon={hideEditIcon}
      onChangeEditMode={(isEditMode, event) => {
        setHelpText(!isEditMode ? null : '');
        onChangeEditMode(isEditMode, event);
      }}
      onChange={newValue => onInternalChangeCost(value ?? 0, isNaN(parseFloat(newValue)) ? 0 : parseFloat(newValue))}
    >
      {formatCost(value)}
    </DataTableCellEditable>
  );
};

export default DataTableCellCostEdit;
