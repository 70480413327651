import {EOfferActionType} from '../../types';
import {disableOfferAction, removeOfferAction} from '../../utils/actions';
import {common as commonCondition} from '../conditions';
import {OfferLifeCycleActionsRule} from '../types';

/*
 * сложные правила
 */

export const removeEditActionForModerationRule: OfferLifeCycleActionsRule = {
  description: 'Удаление возможности редактирования при модерации',
  algorithm: [...commonCondition.isNotOnModerationByMeCondition.algorithm],
  call: props => {
    if (commonCondition.isNotOnModerationByMeCondition.call(props)) {
      removeOfferAction(props.nextActions, EOfferActionType.Edit);
      return true;
    }
    return false;
  },
};

export const removeEditActionForFreeOfferCountRule: OfferLifeCycleActionsRule = {
  description: 'Удаление возможности редактирования по наличию кодов',
  algorithm: [
    ...commonCondition.isPersonalPromotionTypeCondition.algorithm,
    'и',
    ...commonCondition.hasNotFreeOfferCountCondition.algorithm,
  ],
  call: props => {
    if (
      commonCondition.isPersonalPromotionTypeCondition.call(props) &&
      commonCondition.hasNotFreeOfferCountCondition.call(props)
    ) {
      removeOfferAction(props.nextActions, EOfferActionType.Edit);
      return true;
    }
    return false;
  },
};

export const disableModerateActionsRule: OfferLifeCycleActionsRule = {
  description: 'Отключение доступности модерации',
  algorithm: [
    ...commonCondition.isWaitingForModerationCondition.algorithm,
    'или',
    ...commonCondition.isNotOnModerationByMeCondition.algorithm,
  ],
  call: props => {
    if (
      commonCondition.isWaitingForModerationCondition.call(props) ||
      commonCondition.isNotOnModerationByMeCondition.call(props)
    ) {
      disableOfferAction(props.nextActions, EOfferActionType.Approve);
      disableOfferAction(props.nextActions, EOfferActionType.Reject);
      return true;
    }
    return false;
  },
};

export const disableChangeModeratorActionRule: OfferLifeCycleActionsRule = {
  description: 'Отключение доступности изменения модератора',
  algorithm: [...commonCondition.isWaitingForModerationCondition.algorithm],
  call: props => {
    if (commonCondition.isWaitingForModerationCondition.call(props)) {
      disableOfferAction(props.nextActions, EOfferActionType.ChangeModerator);
      return true;
    }
    return false;
  },
};

export const removeEditCodesActionRule: OfferLifeCycleActionsRule = {
  description: 'Удаление возможности управления кодами',
  algorithm: [
    ...commonCondition.isNotPersonalPromotionTypeCondition.algorithm,
    'или',
    ...commonCondition.isNotOnModerationByMeCondition.algorithm,
  ],
  call: props => {
    if (
      commonCondition.isNotPersonalPromotionTypeCondition.call(props) ||
      commonCondition.isNotOnModerationByMeCondition.call(props)
    ) {
      removeOfferAction(props.nextActions, EOfferActionType.CreateCodes);
      removeOfferAction(props.nextActions, EOfferActionType.DeleteCodes);
      return true;
    }
    return false;
  },
};

export const removeDownloadCodesActionRule: OfferLifeCycleActionsRule = {
  description: 'Удаление возможности скачивания кодов',
  algorithm: [...commonCondition.hasNotOfferCountCondition.algorithm],
  call: props => {
    if (commonCondition.hasNotOfferCountCondition.call(props)) {
      removeOfferAction(props.nextActions, EOfferActionType.DownloadCodes);
      return true;
    }
    return false;
  },
};

export const removeResumePartnerActionRule: OfferLifeCycleActionsRule = {
  description: 'Удаление доступности возобновления партнёром',
  algorithm: [
    ...commonCondition.isPausedCondition.algorithm,
    'и',
    ...commonCondition.isPausedByAdminCondition.algorithm,
  ],
  call: props => {
    if (commonCondition.isPausedCondition.call(props) && commonCondition.isPausedByAdminCondition.call(props)) {
      removeOfferAction(props.nextActions, EOfferActionType.Resume);
      return true;
    }
    return false;
  },
};

export const removeDeleteActionRule: OfferLifeCycleActionsRule = {
  description: 'Удаление доступности удаления',
  algorithm: [...commonCondition.isNotSavedOfferCondition.algorithm],
  call: props => {
    if (commonCondition.isNotSavedOfferCondition.call(props)) {
      removeOfferAction(props.nextActions, EOfferActionType.Delete);
      return true;
    }
    return false;
  },
};

export const disableResumePartnerActionRule: OfferLifeCycleActionsRule = {
  description: 'Отключение доступности возобновления партнёром',
  algorithm: [
    ...commonCondition.isPausedCondition.algorithm,
    'и',
    ...commonCondition.isPausedAfterCodesAddingCondition.algorithm,
  ],
  call: props => {
    if (
      commonCondition.isPausedCondition.call(props) &&
      commonCondition.isPausedAfterCodesAddingCondition.call(props)
    ) {
      disableOfferAction(props.nextActions, EOfferActionType.Resume);
      return true;
    }
    return false;
  },
};

export const removeActivationActionsRule: OfferLifeCycleActionsRule = {
  description: 'Удаление доступности любой активации',
  algorithm: [
    ...commonCondition.isPersonalPromotionTypeCondition.algorithm,
    'и',
    ...commonCondition.hasNotFreeOfferCountCondition.algorithm,
  ],
  call: props => {
    if (
      commonCondition.isPersonalPromotionTypeCondition.call(props) &&
      commonCondition.hasNotFreeOfferCountCondition.call(props)
    ) {
      removeOfferAction(props.nextActions, EOfferActionType.Approve);
      removeOfferAction(props.nextActions, EOfferActionType.Resume);
      removeOfferAction(props.nextActions, EOfferActionType.Publish);
      return true;
    }
    return false;
  },
};

export const disableRetrieveActionRule: OfferLifeCycleActionsRule = {
  description: 'Отключение доступности возврата из архива',
  algorithm: [
    ...commonCondition.isArchivedCondition.algorithm,
    'и',
    ...commonCondition.isNotActiveByDateOfferCondition.algorithm,
  ],
  call: props => {
    if (
      commonCondition.isArchivedCondition.call(props) &&
      commonCondition.isNotActiveByDateOfferCondition.call(props)
    ) {
      disableOfferAction(props.nextActions, EOfferActionType.Retrieve);
      return true;
    }
    return false;
  },
};

export const disableUnPublishActionRule: OfferLifeCycleActionsRule = {
  description: 'Отключение доступности отзыва',
  algorithm: [...commonCondition.isOnModerationCondition.algorithm],
  call: props => {
    if (commonCondition.isOnModerationCondition.call(props)) {
      disableOfferAction(props.nextActions, EOfferActionType.UnPublish);
      disableOfferAction(props.nextActions, EOfferActionType.UnPublishAndEdit);
      return true;
    }
    return false;
  },
};
