import PartnerTableCell, { PartnerTableCellCommonProps } from './index';

const PartnerTableCellContact = (props: PartnerTableCellCommonProps) => {
  const {
    partner: { owner },
  } = props;

  const value = owner ? [owner.lastName, owner.firstName, owner.middleName].filter(item => !!item).join(' ') : '';

  return (
    <PartnerTableCell
      {...props}
      value={value}
    />
  );
};

export default PartnerTableCellContact;
