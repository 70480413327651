import { ClientOrg } from '@/domain/model/clientOrg';
import { EClientOrgStatus } from '@/domain/model/enums';
import { Nullable } from '@/domain/model/types';
import AppBreadcrumbs from '@/presentation/components/common/breadcrumbs';
import MenuButton from '@/presentation/components/common/buttons/menu';
import FileDownloadLink from '@/presentation/components/common/files/download/link';
import DefaultHeader from '@/presentation/components/common/header';
import { MPButton } from '@/presentation/theme/ui-kit/button';
import { ETagColors, MPTag } from '@/presentation/theme/ui-kit/tag';
import userServices from '@features/user/services';
import { Grid, MenuItem, Typography } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { userClientOrgExportAccept } from '../../../utils';
import { useContextHandlers } from '../hooks/useContextHandlers';

interface ClientOrgUsersTableHeaderAdapterProps {
  readonly canDownload?: boolean;
  readonly canCreate?: boolean;
  readonly clientOrg: ClientOrg;
}

const ClientOrgUsersTableHeaderAdapter = (props: ClientOrgUsersTableHeaderAdapterProps) => {
  const { canDownload, canCreate, clientOrg } = props;

  const { onTryCreate } = useContextHandlers();
  const [menuCloseMarker, setMenuCloseMarker] = useState<Nullable<symbol>>(null);

  const showImport = canCreate && !clientOrg.readOnly && clientOrg.status !== EClientOrgStatus.Blocked;
  const showMenu = canDownload;
  const nowDate = moment().format('YYYY_MM_DD');
  const filename = `${clientOrg.name.replace(/\s/g, '_')}_${nowDate}`;
  const downloadUrl = userServices.clientOrgUser.downloadUrl({ clientOrgId: clientOrg.id });

  return (
    <DefaultHeader
      headline={
        <>
          <AppBreadcrumbs />
          {clientOrg.status === EClientOrgStatus.Blocked && (
            <MPTag
              label='Компания приостановлена'
              color={ETagColors.Warning}
              variant='filled'
              dense
            />
          )}
        </>
      }
      actions={
        <Grid
          container
          spacing={1}
          alignItems='center'
        >
          {showImport && (
            <Grid item>
              <MPButton
                variant='outlined'
                size='small'
                onClick={() => onTryCreate(clientOrg)}
              >
                Добавить пользователей
              </MPButton>
            </Grid>
          )}
          {showMenu && (
            <Grid item>
              <MenuButton
                variant='outlined'
                color='primary'
                id={uuidv4()}
                closeMarker={menuCloseMarker}
              >
                {canDownload && (
                  <MenuItem>
                    <FileDownloadLink
                      src={downloadUrl}
                      accept={userClientOrgExportAccept}
                      fileName={filename}
                      component={'div'}
                      onDownload={() => setMenuCloseMarker(Symbol())}
                    >
                      Скачать реестр пользователей
                    </FileDownloadLink>
                  </MenuItem>
                )}
              </MenuButton>
            </Grid>
          )}
        </Grid>
      }
    >
      <Typography variant='h2'>Пользователи</Typography>
    </DefaultHeader>
  );
};

export default ClientOrgUsersTableHeaderAdapter;
