import { ReactNode, useMemo } from 'react';
import { MpUser } from '../../../../../domain/model/user';
import DataTableCellTextFormat from '../../../../components/common/table/cell/textFormat';

export interface PartnerEmployeeTableCellCommonProps {
  readonly employee: MpUser;
}

interface PartnerEmployeeTableCellProps extends PartnerEmployeeTableCellCommonProps {
  readonly value: ReactNode;
}

const PartnerEmployeeTableCell = ({ value }: PartnerEmployeeTableCellProps) => {
  return useMemo(() => <DataTableCellTextFormat>{value}</DataTableCellTextFormat>, [value]);
};

export default PartnerEmployeeTableCell;
