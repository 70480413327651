import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ECmsCollectionLinkObjectType } from '../../../../../../domain/model/enums';
import { PartnersCollection } from '../../../../../../domain/model/partner';
import { UUID } from '../../../../../../domain/model/types';
import useDialogInHistory from '../../../../../hooks/useDialogInHistory';
import { ArrayElementType } from '../../../../../types';
import { CmsLinkedObjectCollectionPartnerType } from '../../../types/collection';

import { CollectionContentType, ECollectionSearchDialogTag } from './types';

interface UseDialogInHistoryParams {
  readonly dialog_guid: UUID;
}

type UseCollectionPartnerMultiple<T extends CollectionContentType> = {
  readonly isSearchDialogOpened: boolean;
  readonly searchDialogParams: UseDialogInHistoryParams;
  readonly onOpenSearchDialog: () => void;
  readonly onCloseSearchDialog: () => void;
  readonly onSelect: (partners: T[]) => void;
  readonly onUnselect: (id: UUID) => void;
  readonly onClear: () => void;
  readonly onChangeSortIndex: (partner: T, sortIndex: number) => void;
};

type UseCmsCollectionPartnerMultipleProps = {
  readonly selected: PartnersCollection;
  readonly linkObjectType: ECmsCollectionLinkObjectType.Partner;
  readonly onChangeLinkedObject: (data: CmsLinkedObjectCollectionPartnerType) => void;
};

const useCmsCollectionPartnerMultiple = <T extends ArrayElementType<PartnersCollection>>(
  props: UseCmsCollectionPartnerMultipleProps
): UseCollectionPartnerMultiple<T> => {
  const { selected } = props;

  const {
    open: isSearchDialogOpened,
    params: searchDialogParams,
    onOpen: onOpenSearchDialog,
    onClose: onCloseSearchDialog,
  } = useDialogInHistory<UseDialogInHistoryParams>({
    tag: ECollectionSearchDialogTag.PartnerMultiple,
    params: { dialog_guid: uuidv4() },
  });

  const onOpenSearchDialogInternal = useCallback(
    () => onOpenSearchDialog({ dialog_guid: uuidv4() }),
    [onOpenSearchDialog]
  );

  const onChangeInternal = useCallback(
    (partners: T[]) => {
      switch (props.linkObjectType) {
        case ECmsCollectionLinkObjectType.Partner:
          props.onChangeLinkedObject({
            linkObjectType: ECmsCollectionLinkObjectType.Partner,
            linkedObject: partners,
          });
          break;
      }
    },
    [props.linkObjectType, props.onChangeLinkedObject]
  );

  const onSelect = useCallback(
    (partners: T[]) => {
      onChangeInternal(partners);
      onCloseSearchDialog();
    },
    [onChangeInternal, onCloseSearchDialog]
  );

  const onClear = useCallback(() => {
    onChangeInternal([]);
  }, [onChangeInternal]);

  const onUnselect = useCallback(
    (id: UUID) => {
      const arr: T[] = [...(selected as T[])];
      const index = selected.findIndex(o => o.id === id);

      if (index !== -1) {
        arr.splice(index, 1);
        onChangeInternal(arr);
      }
    },
    [selected, onChangeInternal]
  );

  const onChangeSortIndex = useCallback(
    (item: T, sortIndex: number) => {
      const arr: T[] = [...(selected as T[])];
      const from = arr.findIndex(o => o.id === item.id);
      arr.splice(sortIndex - 1, 0, arr.splice(from, 1)[0]);
      onChangeInternal(arr);
    },
    [selected, onChangeInternal]
  );

  return {
    searchDialogParams,
    isSearchDialogOpened,
    onOpenSearchDialog: onOpenSearchDialogInternal,
    onCloseSearchDialog,
    onSelect,
    onClear,
    onUnselect,
    onChangeSortIndex,
  };
};

export default useCmsCollectionPartnerMultiple;
