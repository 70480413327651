import BookingOrderTableCell, { BookingOrderTableCellCommonProps } from './index';

const BookingOrderTableCellCSP = (props: BookingOrderTableCellCommonProps) => {
  const {
    bookingOrder: { hasRzdSocialPackage },
  } = props;

  const value = hasRzdSocialPackage ? 'Да' : 'Нет';

  return (
    <BookingOrderTableCell
      {...props}
      value={value}
    />
  );
};

export default BookingOrderTableCellCSP;
