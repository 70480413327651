import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Partner } from '../../../domain/model/partner';
import { Nullable } from '../../../domain/model/types';
import CorpActivationActionsProvider from '../../features/corpActivation/actions/provider';
import { CorpActivationsLocationState, ECorpActivationUrlParam } from '../../features/corpActivation/entry';
import CorpActivationTableEntry from '../../features/corpActivation/table/entry';
import { ECorpActivationTableTab } from '../../features/corpActivation/types';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import { viewShowPartnerManagementMenu } from '../../features/partner/management/container/store/slice';

interface PartnerManagementCorpActivationsScreenProps {
  readonly partner: Partner;
}

const PartnerManagementCorpActivationsScreen = ({ partner }: PartnerManagementCorpActivationsScreenProps) => {
  const dispatch = useDispatch();
  const location = useLocation<Nullable<CorpActivationsLocationState>>();

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(ECorpActivationUrlParam.Tab) as ECorpActivationTableTab;

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowPartnerManagementMenu());
  }, [dispatch]);

  return (
    <LocationWithRequiredStateContainer<CorpActivationsLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <CorpActivationActionsProvider>
          <CorpActivationTableEntry
            tab={tab}
            guid={state.guid}
            partnerId={partner.id}
          />
        </CorpActivationActionsProvider>
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default PartnerManagementCorpActivationsScreen;
