import { Nullable, UUID } from '@/domain/model/types';
import { useTradeActivationTableSearch } from '../hooks/useSearch';
import { useTradeActivationTableSession } from '../hooks/useSession';

type TradeActivationTableBehaviorAdapterProps = {
  readonly guid: UUID;
  readonly partnerId: Nullable<UUID>;
  readonly customerId: Nullable<UUID>;
};

export const TradeActivationTableBehaviorAdapter = ({
  guid,
  partnerId,
  customerId,
}: TradeActivationTableBehaviorAdapterProps) => {
  useTradeActivationTableSession({ guid });
  useTradeActivationTableSearch({ partnerId, customerId });
  return null;
};
