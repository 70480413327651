import { ENoticeStatus } from '@/domain';
import Notifier from '@/system/notifier';
import { EOfferActionType } from '@features/general/offer/types';

export const showTradeOfferSaveErrorsNotification = (errors: string[]) => {
  Notifier.getInstance().addNotice(ENoticeStatus.Warning, errors.join('\n'));
};

export const showTradeOfferActionNotification = (action: EOfferActionType, isCreate: boolean = false) => {
  let text = '';

  if (isCreate) {
    text = 'Черновик торгового предложение создан';
  } else {
    text = getNotificationAction(action);
  }

  Notifier.getInstance().addNotice(ENoticeStatus.Success, text);
};

export const getNotificationAction = (action: EOfferActionType) => {
  switch (action) {
    case EOfferActionType.Edit:
      return 'Изменения сохранены';
    case EOfferActionType.Archive:
      return 'Торговое предложение отправлено в архив';
    case EOfferActionType.Delete:
      return 'Торговое предложение удалено';
    case EOfferActionType.Duplicate:
      return 'Создана копия торгового предложение';
    case EOfferActionType.ReturnToVerification:
      return 'Торговое предложение вернулось на проверку';
    case EOfferActionType.Reject:
      return 'Торговое предложение отклонен';
    case EOfferActionType.Approve:
      return 'Торговое предложение опубликовано';
    case EOfferActionType.Pause:
      return 'Торговое предложение приостановлено';
    case EOfferActionType.Resume:
      return 'Торговое предложение опубликовано';
    case EOfferActionType.Publish:
      return 'Торговое предложение было отправлено на проверку';
    case EOfferActionType.UnPublish:
      return 'Торговое предложение отозвано';
    case EOfferActionType.Retrieve:
      return 'Торговое предложение вернулось в предыдущий статус';
    case EOfferActionType.InWork:
      return 'Торговое предложение взято в работу';
    case EOfferActionType.ChangeModerator:
      return 'Модератор успешно назначен';
    case EOfferActionType.SaveNewVersion:
      return 'Торговое предложение сохранено как черновик';
    case EOfferActionType.ChangeApprovalRegistry:
      return 'Приказ успешно присвоен';
    case EOfferActionType.ChangeResponsiblePerson:
      return 'Ответственный успешно изменен';
    case EOfferActionType.UnPublishAndEdit:
      return 'Торговое предложение отозвано для редактирования';
    case EOfferActionType.CreateCodes:
      return 'Новые коды успешно добавлены';
    case EOfferActionType.DeleteCodes:
      return 'Неприсвоенные коды удалены';
    case EOfferActionType.DownloadCodes:
      return 'База кодов загружена';
  }
};
