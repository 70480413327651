import { EDateFormat } from '../../../../../../../domain/model/formats';
import { utcToLocalTimeZone } from '../../../../../../utils/dates';
import OfferFieldHistoryTableCell, { OfferFieldHistoryTableCellCommonProps } from './index';

const OfferFieldHistoryTableCellUpdatedAt = (props: OfferFieldHistoryTableCellCommonProps) => {
  const {
    fieldHistory: { updatedAt },
  } = props;

  const value = updatedAt && utcToLocalTimeZone(updatedAt).format(EDateFormat.DisplayDefault);

  return (
    <OfferFieldHistoryTableCell
      {...props}
      value={value}
    />
  );
};

export default OfferFieldHistoryTableCellUpdatedAt;
