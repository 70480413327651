import { EUserStatus } from '../../../domain/model/enums';
import { PanelAction, PanelActions } from '../../types';

export enum ECustomerActionType {
  Activate = 'activate',
  Deactivate = 'deactivate',
}

export type CustomerActionDetailsType = ECustomerActionType;

export type CustomerActionTableType = ECustomerActionType;

export type CustomerAction<A extends ECustomerActionType, P = any> = PanelAction<A, P>;

export type CustomerActions<A extends ECustomerActionType> = PanelActions<A>;

export interface GetCustomerActionsByPermissions {
  readonly canActivate?: boolean;
  readonly canDeactivate?: boolean;
}

export type CustomerCounterByStatus = {
  [name in EUserStatus]?: number;
};
