import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { CmsPreviewItemRatio } from '../../../../../features/cms/types';

interface WrapperProps {
  children: ReactNode;
  ratio?: CmsPreviewItemRatio;
  maxRowElementsCount: number;
  preserveAspectRatio?: boolean;
  spacing?: number;
}

export const Wrapper = styled(
  ({ maxRowElementsCount, preserveAspectRatio, ratio, spacing, ...others }: WrapperProps) => <div {...others} />
)`
  --ratio: ${p => p.ratio ?? 16 / 9};
  --spacing: ${p => (p.spacing ? p.theme.spacing(p.spacing) : '0px')};
  --itemsCount: ${p => p.maxRowElementsCount};

  width: 100%;

  display: grid;
  grid-template-columns: repeat(
    ${p => p.maxRowElementsCount},
    ${p =>
      p.preserveAspectRatio ? 'calc((100% - var(--spacing) *  (var(--itemsCount) - 1)) / var(--itemsCount))' : '1fr'}
  );

  ${p =>
    p.spacing &&
    `
    column-gap: ${p.theme.spacing(p.spacing)};
    row-gap: ${p.theme.spacing(p.spacing)};
  `}
  & > * {
    width: 100%;
    min-height: ${p => (p.preserveAspectRatio ? 'auto' : '2rem')};

    aspect-ratio: ${p => (p.preserveAspectRatio ? 'var(--ratio)' : 'auto')};
  }
`;
