import { FCC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useBookingOrderActions } from '../../actions/useAction';
import { BookingOrderAction, BookingOrderActionEditType, EBookingOrderActionType } from '../../types';
import { getBookingOrderActionsConfigByPermissions } from '../../utils/actions';
import { BookingOrderEditContainerProps } from '../container';
import { BookingOrderEditLifecycleContext, BookingOrderEditLifecycleContextValue } from '../context';
import useBookingOrderEditActions from '../hooks/useActions';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingOrderEditBookingOrderForLifeCycleSelector } from '../store/selectors';

export const BookingOrderEditLifecycleProvider: FCC<BookingOrderEditContainerProps> = ({
  id,
  children,
  lifecycle,
  access,
}) => {
  const handlers = useContextHandlers();

  const order = useSelector(bookingOrderEditBookingOrderForLifeCycleSelector);

  const {
    utils: {
      selectors: { getIsBookingOrderExecutedActionsSelector },
    },
  } = useBookingOrderActions();

  const isActionsForClose = useSelector(
    getIsBookingOrderExecutedActionsSelector(id, EBookingOrderActionType.Save, EBookingOrderActionType.Cancel)
  );

  const allowedActions = getBookingOrderActionsConfigByPermissions({
    ...access,
  });

  const lifeCycleRules = lifecycle.build({
    allowedActions,
    obj: order,
  });

  const actions = useBookingOrderEditActions({
    lifeCycleRules,
  });

  const onAction = (action: BookingOrderAction<BookingOrderActionEditType>) => {
    const { type } = action;
    switch (type) {
      case EBookingOrderActionType.Confirm:
        handlers.onBookingOrderConfirm();
        break;
      case EBookingOrderActionType.Give:
        handlers.onBookingOrderGive();
        break;
      case EBookingOrderActionType.Cancel:
        handlers.onTryCancel();
        break;
      case EBookingOrderActionType.Save:
        break;
    }
  };

  const value: BookingOrderEditLifecycleContextValue = {
    actions,
    onAction,
  };

  useEffect(() => {
    if (isActionsForClose) {
      handlers.onClose();
    }
  }, [isActionsForClose, handlers.onClose]);

  return (
    <BookingOrderEditLifecycleContext.Provider value={value}>{children}</BookingOrderEditLifecycleContext.Provider>
  );
};
