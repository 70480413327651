import { FC } from 'react';
import { FileDescription } from '../../../../../../domain/model';
import { Nullable } from '../../../../../../domain/model/types';
import { EEntityPreviewMode } from '../../../../../types';
import { OfferPreviewLogoImage, OfferPreviewLogoSkeleton, OfferPreviewLogoWrapper } from './controls';

export type OfferPreviewLogoProps = {
  readonly mode?: EEntityPreviewMode;
  readonly image: Nullable<string | FileDescription>;
};

const OfferPreviewLogo: FC<OfferPreviewLogoProps> = props => {
  const { mode = EEntityPreviewMode.Desktop, image } = props;

  return (
    <OfferPreviewLogoWrapper mode={mode}>
      {image ? (
        <OfferPreviewLogoImage
          mode={mode}
          src={image}
          alt=''
        />
      ) : (
        <OfferPreviewLogoSkeleton
          animation={false}
          width={mode === EEntityPreviewMode.Mobile ? '1.5rem' : '2rem'}
          height={mode === EEntityPreviewMode.Mobile ? '1.5rem' : '2rem'}
        />
      )}
    </OfferPreviewLogoWrapper>
  );
};

export default OfferPreviewLogo;
