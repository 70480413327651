import styled from '@emotion/styled';

export const Content = styled.div`
  padding-bottom: ${p => p.theme.spacing(3)};
  flex-grow: 1;
`;

export const Footer = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 1;

  margin-left: -${p => p.theme.spacing(3.75)};
  margin-right: -${p => p.theme.spacing(3.75)};

  padding: ${p => p.theme.spacing(2)} ${p => p.theme.spacing(3.75)} ${p => p.theme.spacing(3)};

  background: ${p => p.theme.palette.background.default};
`;
