import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clientOrgCreateClientOrgIdSelector } from '../store/selectors';
import { clientOrgCreateSetUiState } from '../store/slice';
import { ClientOrgCreateUiState } from '../types';

export const ClientOrgCreateUiFieldsState = () => {
  const dispatch = useDispatch();

  const id = useSelector(clientOrgCreateClientOrgIdSelector);

  const value = useMemo<ClientOrgCreateUiState['fields']>(() => {
    const result: ClientOrgCreateUiState['fields'] = {
      disabled: [],
    };

    if (id) {
      result.disabled.push('code');
    }

    return result;
  }, [id]);

  useEffect(() => {
    dispatch(clientOrgCreateSetUiState({ name: 'fields', value }));
  }, [dispatch, value]);

  return null;
};
