import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../../data/store/types';
import { CmsSitePage } from '../../../../../../domain/model/cms';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import cmsServices from '../../../services';

export const cmsSitePageForPartnerFetch = createAsyncThunk<CmsSitePage, UUID, AppThunkAPIConfig>(
  'cmsSitePage/forPartner/fetch',
  async (partnerId, { rejectWithValue }) => {
    try {
      return await cmsServices.sitePage.getOrCreateForPartner({
        partnerId,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface CmsSitePageForPartnerState extends Fetchable {
  readonly data: Nullable<CmsSitePage>;
}

type Reducer<T = undefined> = CaseReducer<CmsSitePageForPartnerState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CmsSitePageForPartnerState> {
  cmsSitePageForPartnerStateReset: Reducer;
}

const slice = createSlice<CmsSitePageForPartnerState, Reducers, 'forPartner'>({
  name: 'forPartner',
  initialState: {
    ...fetchableDefault,
    data: null,
  },
  reducers: {
    cmsSitePageForPartnerStateReset: state => {
      state.isFetching = false;
      state.isFetched = false;
      state.isFailed = false;
      state.data = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(cmsSitePageForPartnerFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
        state.data = null;
      })
      .addCase(cmsSitePageForPartnerFetch.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.data = payload;
      })
      .addCase(cmsSitePageForPartnerFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
        } else {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = false;
        }
      });
  },
});

export const { cmsSitePageForPartnerStateReset } = slice.actions;

export default slice.reducer;
