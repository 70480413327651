export type DefaultOptionsType = {
  min: number;
  max: number;
};

const defaultOptions = {
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
};

export const filterValue = (inputString: string | number, options: DefaultOptionsType = defaultOptions): number => {
  let newValue;
  const { min, max } = options;

  typeof inputString === 'string' ? (newValue = +inputString.replace(/\D/g, '')) : (newValue = inputString);

  if (isNaN(newValue)) {
    return 0;
  }

  return Math.min(Math.max(newValue, min), max);
};
