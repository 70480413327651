import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterValueItem,
  EDataFilterType,
} from '@/domain/model/filter';
import { EDateFormat } from '@/domain/model/formats';
import { Nullable } from '@/domain/model/types';
import { formatDateRange } from '@/presentation/utils';
import moment from 'moment-timezone';
import { EClientOrgUserTableColumn } from './table/utils';

export enum EClientOrgUserTableFilterItem {
  Query = 'query',
  ActivatedDate = 'createdAt',
  Name = 'name',
  Email = 'email',
  Phone = 'phone',
}

export const clientOrgUserTableFilterItems: Record<EClientOrgUserTableColumn, EClientOrgUserTableFilterItem[]> = {
  [EClientOrgUserTableColumn.Date]: [],
  [EClientOrgUserTableColumn.Name]: [EClientOrgUserTableFilterItem.ActivatedDate, EClientOrgUserTableFilterItem.Name],
  [EClientOrgUserTableColumn.Email]: [EClientOrgUserTableFilterItem.Email],
  [EClientOrgUserTableColumn.Phone]: [EClientOrgUserTableFilterItem.Phone],
};

export type ClientOrgUserTableFilterEditStrategy = DataFilterStrategyBase<EClientOrgUserTableFilterItem>;

export type ClientOrgUserTableFilterValues = {
  readonly [EClientOrgUserTableFilterItem.Query]?: DataFilterValueItem<Nullable<string>>;
  readonly [EClientOrgUserTableFilterItem.ActivatedDate]?: DataFilterValueItem<
    Nullable<[Nullable<string>, Nullable<string>]>
  >;
  readonly [EClientOrgUserTableFilterItem.Name]?: DataFilterValueItem<Nullable<string>>;
  readonly [EClientOrgUserTableFilterItem.Phone]?: DataFilterValueItem<Nullable<string>>;
  readonly [EClientOrgUserTableFilterItem.Email]?: DataFilterValueItem<Nullable<string>>;
};

const getActivatedDatePreview = (
  date: ClientOrgUserTableFilterValues[EClientOrgUserTableFilterItem.ActivatedDate]
): Nullable<string> => {
  const range = formatDateRange(date?.value?.[0] ?? null, date?.value?.[1] ?? null, (v: Nullable<string>) =>
    moment(v).format(EDateFormat.DisplayDefault)
  );
  return range ? 'Подключен ' + range : null;
};

export const getClientOrgUserTableFilterStrategy = (
  filterItem: EClientOrgUserTableFilterItem,
  values: ClientOrgUserTableFilterValues
): Nullable<ClientOrgUserTableFilterEditStrategy> => {
  try {
    switch (filterItem) {
      case EClientOrgUserTableFilterItem.Query:
        return {
          type: EDataFilterType.String,
          key: EClientOrgUserTableFilterItem.Query,
          label: 'Поиск по ключевому слову',
          preview: values[EClientOrgUserTableFilterItem.Query]?.value || null,
          value: values[EClientOrgUserTableFilterItem.Query]?.value || null,
        };
      case EClientOrgUserTableFilterItem.ActivatedDate:
        return {
          type: EDataFilterType.DatePeriod,
          key: EClientOrgUserTableFilterItem.ActivatedDate,
          label: '',
          labelPeriod: {
            from: 'Подключен с',
            to: 'по',
          },
          preview: getActivatedDatePreview(values[EClientOrgUserTableFilterItem.ActivatedDate]),
          value: values[EClientOrgUserTableFilterItem.ActivatedDate]?.value || null,
          querydsl: {
            operator: DataFilterQueryDslOperator.Between,
          },
        };
      case EClientOrgUserTableFilterItem.Name:
        return {
          type: EDataFilterType.String,
          key: EClientOrgUserTableFilterItem.Name,
          label: 'ФИО',
          preview: values[EClientOrgUserTableFilterItem.Name]?.value || null,
          value: values[EClientOrgUserTableFilterItem.Name]?.value || null,
          querydsl: {
            operator: DataFilterQueryDslOperator.Like,
          },
        };
      case EClientOrgUserTableFilterItem.Phone:
        return {
          type: EDataFilterType.String,
          key: EClientOrgUserTableFilterItem.Phone,
          label: 'Телефон',
          preview: values[EClientOrgUserTableFilterItem.Phone]?.value || null,
          value: values[EClientOrgUserTableFilterItem.Phone]?.value || null,
          querydsl: {
            operator: DataFilterQueryDslOperator.Like,
          },
        };
      case EClientOrgUserTableFilterItem.Email:
        return {
          type: EDataFilterType.String,
          key: EClientOrgUserTableFilterItem.Email,
          label: 'Электронная почта',
          preview: values[EClientOrgUserTableFilterItem.Email]?.value || null,
          value: values[EClientOrgUserTableFilterItem.Email]?.value || null,
          querydsl: {
            operator: DataFilterQueryDslOperator.Like,
          },
        };
    }
  } catch (e) {
    console.error(e);
    return null;
  }
};
