import { FCC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../data/store/store';
import ContentLoader, { ContentLoaderProps } from '../../../components/common/loader';

interface CommonUpdaterDecoratorProps extends ContentLoaderProps {
  readonly isFetchingSelector: (store: RootState) => boolean;
}

export const CommonUpdaterDecorator: FCC<CommonUpdaterDecoratorProps> = ({
  isFetchingSelector,
  children,
  ...loaderProps
}) => {
  const isFetching = useSelector(isFetchingSelector);

  return (
    <>
      {children}
      {isFetching && (
        <ContentLoader
          size={75}
          position='fixed'
          alpha
          {...loaderProps}
        />
      )}
    </>
  );
};
