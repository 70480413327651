import { ENoticeStatus } from '../../../../domain/model/enums';
import { ProductOrder } from '../../../../domain/model/order';
import { Nullable } from '../../../../domain/model/types';
import Notifier from '../../../../system/notifier';
import { EProductOrderActionType } from '../types';

export const showProductOrderActionNotification = (order: Nullable<ProductOrder>, action: EProductOrderActionType) => {
  let text;

  switch (action) {
    case EProductOrderActionType.Give:
      text = 'Изменения сохранены';
      break;
    case EProductOrderActionType.Cancel:
      text = 'Заказ был отменен';
      break;
    case EProductOrderActionType.New:
      text = 'Был создан новый заказ';
      break;
    case EProductOrderActionType.Confirm:
      text = 'Заказ был подтвержден';
      break;
    case EProductOrderActionType.Send:
      text = 'Заказ был отправлен';
      break;
    case EProductOrderActionType.Pay:
      text = 'Заказ был оплачен';
      break;
    case EProductOrderActionType.Save:
      text = 'Заказ был сохранен';
      break;
    case EProductOrderActionType.Return:
      text = 'Заказ был возвращен';
      break;
    case EProductOrderActionType.PartiallyReturn:
      text = 'Заказ был возвращен частично';
      break;
    case EProductOrderActionType.Renew:
      text = 'Заказ был пересоздан';
      break;
  }

  Notifier.getInstance().addNotice(ENoticeStatus.Success, text);
};
