import { useCallback } from 'react';
import { ECmsCollectionLinkObjectType, EOfferType } from '../../../../../../../domain/model/enums';
import { OnChangeObjectAttribute } from '../../../../../../types';
import { CmsLinkedObjectCollectionType, CmsLinkedObjectCollectionTypes } from '../../../../types/collection';

type UseCmsContainerCreateCollectionChange = {
  readonly onChangeCollectionAttribute: OnChangeObjectAttribute<CmsLinkedObjectCollectionType>;
  readonly onChangeCollectionLinkedObject: (newCmsCollection: CmsLinkedObjectCollectionTypes) => void;
};

interface useCmsContainerCreateCollectionChangeProps {
  readonly offerType: EOfferType;
  readonly cmsCollection: CmsLinkedObjectCollectionType;
  readonly onChangeCollection: (data: CmsLinkedObjectCollectionType) => void;
}

const useCmsContainerCreateCollectionChange = (
  props: useCmsContainerCreateCollectionChangeProps
): UseCmsContainerCreateCollectionChange => {
  const { offerType, cmsCollection, onChangeCollection } = props;

  const onChangeCollectionAttribute: OnChangeObjectAttribute<CmsLinkedObjectCollectionType> = useCallback(
    (name, value) => {
      if (name === 'linkObjectType') {
        //если меняем тип контента за баннером, то формируем пустой вложенный объект нужного типа
        switch (value) {
          case ECmsCollectionLinkObjectType.TradeOffer:
            onChangeCollection({
              ...cmsCollection,
              linkObjectType: ECmsCollectionLinkObjectType.TradeOffer,
              linkedObject: null,
            });
            break;
          case ECmsCollectionLinkObjectType.CorpOffer:
            onChangeCollection({
              ...cmsCollection,
              linkObjectType: ECmsCollectionLinkObjectType.CorpOffer,
              linkedObject: null,
            });
            break;
          case ECmsCollectionLinkObjectType.Partner:
            onChangeCollection({
              ...cmsCollection,
              linkObjectType: ECmsCollectionLinkObjectType.Partner,
              linkedObject: null,
            });
            break;
          default:
            console.warn('Not implemented');
            break;
        }
      } else {
        onChangeCollection({ ...cmsCollection, [name]: value });
      }
    },
    [offerType, cmsCollection, onChangeCollection]
  );

  const onChangeCollectionLinkedObject = useCallback(
    (newCmsCollection: CmsLinkedObjectCollectionTypes) => {
      if (newCmsCollection.linkObjectType === cmsCollection.linkObjectType) {
        const temp: typeof cmsCollection = {
          ...cmsCollection,
          linkedObject: newCmsCollection.linkedObject as any,
        };
        onChangeCollection(temp);
      } else {
        console.error(
          `Inner cmsCollection linkObjectType '${cmsCollection.linkObjectType}' and '${newCmsCollection.linkObjectType}' not equal`
        );
      }
    },
    [cmsCollection]
  );

  return {
    onChangeCollectionAttribute,
    onChangeCollectionLinkedObject,
  };
};

export default useCmsContainerCreateCollectionChange;
