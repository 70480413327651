import validator from 'validator';
import { SportUserData } from '../../../../../domain/model/user';
import { MPStepperStep } from '../../../../components/common/stepper';
import { EValidationMessages, EValidationType, ValidationRules } from '../../../../utils/validation';

export enum EUserSportEditStep {
  Profile = 'profile',
}

export type UserSportStep = MPStepperStep<EUserSportEditStep>;

export const userSportEditSteps: UserSportStep[] = [
  {
    key: EUserSportEditStep.Profile,
    label: 'Профиль',
  },
];

export const userSportEditValidation: ValidationRules<SportUserData> = {
  lastName: {
    required: true,
  },
  firstName: {
    required: true,
  },
  email: {
    required: true,
    validator: (object, value) => {
      if (!value) return null;

      const isEmail = validator.isEmail(value);
      if (!isEmail) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EValidationMessages.EmailInvalid,
        };
      } else {
        return null;
      }
    },
  },
};
