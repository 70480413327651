import { ECustomersFilterItem } from '@features/customer/table/filterUtils';
import { ECustomerTableColumn } from '@features/customer/table/utils';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataFilterComponent from '../../../../components/common/dataFilter';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { customersFilterSelector, customersGuidSelector, customersSortColumnSelector } from '../store/selectors';

interface CustomerTableFilterAdapterProps {
  readonly filterAdapter: DataFilterAdapter<ECustomerTableColumn, ECustomersFilterItem>;
}

export const CustomerTableFilterAdapter = (props: CustomerTableFilterAdapterProps) => {
  const { filterAdapter } = props;

  const handlers = useContextHandlers();

  const guid = useSelector(customersGuidSelector);
  const sortColumn = useSelector(customersSortColumnSelector);
  const filter = useSelector(customersFilterSelector);

  const { filterStrategies } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return useMemo(
    () => (
      <DataFilterComponent
        strategies={filterStrategies}
        onChange={handlers.onChangeFilter}
      />
    ),
    [filterStrategies, handlers.onChangeFilter]
  );
};
