import { Grid, Typography } from '@mui/material';
import fourBannersLine from '../../../../icons/fourBannersLine.png';
import oneBannerLine from '../../../../icons/oneBannerLine.png';
import threeBannersLine from '../../../../icons/threeBannersLine.png';
import twoBannersLine from '../../../../icons/twoBannersLine.png';
import { Banner, Line, Wrapper } from './controls';

interface PatnerWindowsBannersProps {
  count: 1 | 2 | 3 | 4;
}

const PartnerWindowBanners = (props: PatnerWindowsBannersProps) => {
  const { count } = props;

  let imageSource = '';
  let caption = '';

  switch (count) {
    case 1:
      caption = 'Один баннер';
      imageSource = oneBannerLine;
      break;
    case 2:
      caption = 'Два баннера';
      imageSource = twoBannersLine;
      break;
    case 3:
      caption = 'Три баннера';
      imageSource = threeBannersLine;
      break;
    case 4:
      caption = 'Четыре баннера';
      imageSource = fourBannersLine;
      break;
  }

  return (
    <Grid
      item
      alignItems='center'
      container
      spacing={1}
    >
      <Grid item>
        <Wrapper
          container
          count={count}
          spacing={1}
        >
          {[...Array(count).keys()].map(k => (
            <Banner
              item
              key={k}
            />
          ))}
        </Wrapper>
      </Grid>
      <Line item>
        <img src={imageSource} />
      </Line>
      <Grid item>
        <Typography>{caption}</Typography>
      </Grid>
    </Grid>
  );
};

export default PartnerWindowBanners;
