import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { ENoticeStatus } from '../../../../domain/model/enums';
import { Team } from '../../../../domain/model/team';
import { Nullable, UUID } from '../../../../domain/model/types';
import { SportUserProfile } from '../../../../domain/model/user';
import Notifier from '../../../../system/notifier';
import { OnChangeObjectAttribute } from '../../../types';
import { getTeamLogoSelectRoute, getTeamRoute } from '../entry';
import TeamEdit from './component';
import { teamEditSaveSelector, teamEditSelector } from './store/selectors';
import {
  teamCreateSetCapitan,
  teamEditFetch,
  teamEditSave,
  teamEditSetAttribute,
  teamEditSetValidationErrors,
  teamsEditSaveReset,
} from './store/slice';
import { hasErrors, validateForm } from './validation';

interface TeamEditContainerProps {
  readonly id: UUID;
}

const TeamEditContainer = ({ id }: TeamEditContainerProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { data: team, modified, isFetching, validationErrors } = useSelector(teamEditSelector);
  const { isFetched: isSaved, isFetching: isSaving } = useSelector(teamEditSaveSelector);

  const onClose = () => history.goBack();

  const onTeamEditSave = () => {
    if (team) {
      const isValid = validate(team);

      if (isValid) {
        dispatch(teamEditSave({ team }));
      } else {
        Notifier.getInstance().addNotice(ENoticeStatus.Error, 'Ошибки при заполнении!');
      }
    }
  };

  const onChangeAttribute: OnChangeObjectAttribute<Team> = (name, value) => {
    dispatch(teamEditSetAttribute({ name, value }));
  };

  const onLogoChangeClick = () => {
    history.push(getTeamLogoSelectRoute({ location, selected: team?.logo?.id }));
  };

  const onChangeCaptain = useCallback(
    (captain: Nullable<SportUserProfile>) => {
      dispatch(teamCreateSetCapitan(captain));
    },
    [dispatch]
  );

  const validate = (validatedTeam: Team) => {
    const errors = validateForm(validatedTeam);
    dispatch(teamEditSetValidationErrors({ errors }));

    return !hasErrors(errors);
  };

  useEffect(() => {
    dispatch(teamEditFetch({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (isSaved) {
      Notifier.getInstance().addNotice(ENoticeStatus.Success, 'Команда сохранена');
      history.push(getTeamRoute({ id }));
    }
  }, [dispatch, isSaved]);

  useEffect(() => {
    return () => {
      dispatch(teamsEditSaveReset());
    };
  }, []);

  return (
    <>
      {team && (
        <TeamEdit
          team={team}
          validationErrors={validationErrors}
          modified={modified}
          isFetching={isFetching}
          isSaving={isSaving}
          onChangeAttribute={onChangeAttribute}
          onLogoChangeClick={onLogoChangeClick}
          onChangeCaptain={onChangeCaptain}
          onSave={onTeamEditSave}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default TeamEditContainer;
