import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../data/store/store';
import { UUID } from '../../../../../domain/model/types';
import Splitter from '../../../../components/common/splitter';
import PartnerDeskByIdContainer from '../../../partnerDesk/byId/container';
import PartnerDeskRestrictionContainer from '../../../partnerDesk/restriction/container';
import { CorpOfferTableStubAdapterProps } from '../../types';

export type CorpOffersPartnerStubAdapterProps = CorpOfferTableStubAdapterProps & {
  readonly isEmptySelector: (state: RootState) => boolean;
  readonly partnerId: UUID;
};

const CorpOffersPartnerStubAdapter = (props: CorpOffersPartnerStubAdapterProps) => {
  const { partnerId, isEmptySelector, children } = props;

  const isEmpty = useSelector(isEmptySelector);

  const getStub = useCallback(
    (hasPartnerDesk: boolean) => {
      if (!hasPartnerDesk) {
        return (
          <PartnerDeskRestrictionContainer
            partnerId={partnerId}
            text='Для того, чтобы создать корпоративное предложение, необходимо заполнить карточку компании, которая будет отображаться на Витрине.'
            description='Карточка компании будет объединять все корпоративные предложения вашей компании.'
          />
        );
      }

      if (isEmpty) {
        return (
          <>
            <Splitter size={1} />
            <Typography color='textSecondary'>Здесь будут ваши корпоративные предложения</Typography>
          </>
        );
      }

      return null;
    },
    [partnerId, isEmpty]
  );

  const getCreateInfo = useCallback(
    (hasPartnerDesk: boolean) => {
      if (!hasPartnerDesk) {
        return 'Создание корпоративного предложения будет доступно после заполнения карточки компании';
      }
      if (isEmpty) {
        return 'Здравствуйте! Чтобы создать первое корпоративное предложение нажмите на соответствующую кнопку и заполните форму';
      }

      return null;
    },
    [isEmpty]
  );

  return (
    <PartnerDeskByIdContainer id={partnerId}>
      {({ partnerDesk }) =>
        children({
          canViewTable: !!partnerDesk && !isEmpty,
          isCreateDisabled: !partnerDesk,
          createInfo: getCreateInfo(!!partnerDesk),
          stub: getStub(!!partnerDesk),
        })
      }
    </PartnerDeskByIdContainer>
  );
};

export default CorpOffersPartnerStubAdapter;
