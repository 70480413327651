import validator from 'validator';
import { Nullable } from '../../../domain/model/types';
import {
  EValidationMessages,
  EValidationType,
  ValidationItemResult,
  ValidationOptions,
  ValidationResult,
  ValidationRule,
  ValidationRules,
} from '../../utils/validation';

export interface InternalValidateResult<T> {
  readonly results: Nullable<ValidationResult<T>>;
  readonly isValid: boolean;
}

function validateObject<T>(
  object: T,
  rules: ValidationRules<T>,
  options?: ValidationOptions
): InternalValidateResult<T> {
  let results: Nullable<ValidationResult<T>> = {};
  let hasErrors = false;

  (Object.keys(rules) as [keyof T]).forEach(key => {
    const value: any = object[key];
    const { required, requiredMessage, validator: customValidator } = rules[key]!;

    let resultItem: Nullable<ValidationItemResult> = null;

    let isRequired = false;
    if (!options?.ignoreEmpty) {
      if (typeof required === 'function') {
        isRequired = required(object, value);
      } else {
        isRequired = required ?? false;
      }
    }

    if (
      isRequired &&
      (value === null || value === undefined || (typeof value === 'string' && validator.isEmpty(value)))
    ) {
      resultItem = {
        type: EValidationType.Error,
        hasError: true,
        message: requiredMessage ?? EValidationMessages.Empty,
      };
    } else {
      if (customValidator) {
        resultItem = customValidator(object, value);
      }
    }

    if (resultItem) {
      results![key] = resultItem;

      if (resultItem.hasError) hasErrors = true;
    }
  });

  if (Object.keys(results).length === 0) {
    results = null;
  }

  return {
    results,
    isValid: !hasErrors,
  };
}

export function validateObjectByRule<T>(
  object: Nullable<T>,
  rule: ValidationRule<Nullable<T>>
): Nullable<ValidationItemResult> {
  const { required, requiredMessage, validator: customValidator } = rule;

  let result: Nullable<ValidationItemResult> = null;

  let isRequired: boolean;
  if (typeof required === 'function') {
    isRequired = required(object, object);
  } else {
    isRequired = required ?? false;
  }

  if (
    isRequired &&
    (object === null || object === undefined || (typeof object === 'string' && validator.isEmpty(object)))
  ) {
    result = {
      type: EValidationType.Error,
      hasError: true,
      message: requiredMessage ?? EValidationMessages.Empty,
    };
  } else {
    if (customValidator) {
      result = customValidator(object, object);
    }
  }

  return result;
}

export default validateObject;
