import { AdCampaignDetailsStepType, EAdCampaignStep } from '@features/adCampaign/types';

export const getAdCampaignDetailsSteps = (): AdCampaignDetailsStepType[] => {
  return [EAdCampaignStep.General, EAdCampaignStep.Keywords];
};

export const getAdCampaignDetailsStepText = (step: AdCampaignDetailsStepType): string => {
  switch (step) {
    case EAdCampaignStep.General:
      return 'Описание';
    case EAdCampaignStep.Keywords:
      return 'Ключевые слова';
  }
};
