import { EOfferStatus } from '../../../../../../domain/model/enums';
import { EOfferActionType } from '../../types';
import {
  adminStatusScheme as commonAdminStatusScheme,
  partnerStatusScheme as commonPartnerStatusScheme,
} from './common';

export const adminStatusScheme = commonAdminStatusScheme;

export const partnerStatusScheme = {
  ...commonPartnerStatusScheme,
  statusScheme: {
    ...commonPartnerStatusScheme.statusScheme,
    [EOfferStatus.Draft]: [
      ...commonPartnerStatusScheme.statusScheme[EOfferStatus.Draft],
      EOfferActionType.ChangeResponsiblePerson,
      EOfferActionType.Duplicate,
    ],
    [EOfferStatus.Rejected]: [
      ...commonPartnerStatusScheme.statusScheme[EOfferStatus.Rejected],
      EOfferActionType.ChangeResponsiblePerson,
      EOfferActionType.Duplicate,
    ],
    [EOfferStatus.Paused]: [
      EOfferActionType.Resume,
      EOfferActionType.Archive,
      EOfferActionType.Publish,
      EOfferActionType.Duplicate,
      EOfferActionType.DownloadCodes,
    ],
    [EOfferStatus.Upcoming]: [
      EOfferActionType.Pause,
      EOfferActionType.Archive,
      EOfferActionType.Duplicate,
      EOfferActionType.DownloadCodes,
    ],
    [EOfferStatus.WaitingForModeration]: [
      EOfferActionType.UnPublish,
      EOfferActionType.UnPublishAndEdit,
      EOfferActionType.ChangeResponsiblePerson,
      EOfferActionType.Duplicate,
      EOfferActionType.DownloadCodes,
    ],
    [EOfferStatus.OnModeration]: [EOfferActionType.Duplicate, EOfferActionType.DownloadCodes],
    [EOfferStatus.Active]: [
      EOfferActionType.Pause,
      EOfferActionType.Archive,
      EOfferActionType.Duplicate,
      EOfferActionType.DownloadCodes,
    ],
    [EOfferStatus.Archived]: [EOfferActionType.Duplicate, EOfferActionType.DownloadCodes],
    [EOfferStatus.ReadyForSale]: [EOfferActionType.Duplicate],
  },
};
