import OrdersActionsDialogCancelAdapter from './dialogCancel';
import OrdersActionsDialogDownloadAdapter from './dialogDownload';

const OrdersActionsDialogsAdapter = () => {
  return (
    <>
      <OrdersActionsDialogCancelAdapter />
      <OrdersActionsDialogDownloadAdapter />
    </>
  );
};

export default OrdersActionsDialogsAdapter;
