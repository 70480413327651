import { Nullable } from '../../../../domain/model/types';
import { MpUserData } from '../../../../domain/model/user';
import { OnChangeObjectAttribute } from '../../../types';
import { ValidationResult } from '../../../utils/validation';
import { EPartnerEmployeeStep } from '../utils';
import { Wrapper } from './controls';
import PartnerEmployeeCreateStepProfile from './steps/profile';

interface PartnerEmployeeCreateProps {
  readonly step: EPartnerEmployeeStep.Profile;
  readonly partnerEmployee: MpUserData;
  readonly validationProfile: Nullable<ValidationResult<MpUserData>>;
  readonly isValidProfile: boolean;
  readonly onChangeAttribute: OnChangeObjectAttribute<MpUserData>;
}

const PartnerEmployeeCreate = (props: PartnerEmployeeCreateProps) => {
  const { step, partnerEmployee, isValidProfile, validationProfile, onChangeAttribute } = props;

  return (
    <Wrapper>
      {step === EPartnerEmployeeStep.Profile && (
        <PartnerEmployeeCreateStepProfile
          partnerEmployee={partnerEmployee}
          isValid={isValidProfile}
          validation={validationProfile}
          onChangeAttribute={onChangeAttribute}
        />
      )}
    </Wrapper>
  );
};

export default PartnerEmployeeCreate;
