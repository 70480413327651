import common, { ComplaintCommonService } from './common';

type ComplaintServices = {
  readonly common: ComplaintCommonService;
};

const complaintServices: ComplaintServices = {
  common,
};

export default complaintServices;
