import { EOfferStatus, EPartnerStatus } from '../../../../../domain/model/enums';
import { PartnerView } from '../../../../../domain/model/partner';
import { Nullable } from '../../../../../domain/model/types';
import { EntityInfoPanels } from '../../../../types';
import { EOfferInfoPanelType } from '../enums';
import { InfoPanelOfferType, OfferInfoPanelDetailsType } from '../types';

type OfferType = InfoPanelOfferType;

type UseOfferDetailsInfoPanels = Nullable<EntityInfoPanels<OfferInfoPanelDetailsType>>;

interface UseOfferDetailsInfoPanelsProps {
  readonly partner: Nullable<PartnerView>;
  readonly offer: Nullable<OfferType>;
}

const useOfferDetailsInfoPanels = (props: UseOfferDetailsInfoPanelsProps): UseOfferDetailsInfoPanels => {
  const { partner, offer } = props;

  const detailsInfoPanels: EntityInfoPanels<OfferInfoPanelDetailsType> = [];

  if (partner && partner.status !== EPartnerStatus.Enabled) {
    detailsInfoPanels.push({
      type: EOfferInfoPanelType.PartnerInactive,
      title: 'Аккаунт не активирован',
      text: ['Для доступа к публикации предложений, необходимо чтобы администратор дал соответствующие права'],
    });
  }

  const text: string[] = [];

  if (offer?.status === EOfferStatus.Paused) {
    const text: string[] = [];
    if (offer.pausedReason?.name) {
      text.push(offer.pausedReason.name);
    }
    if (offer.lastStatusComment) {
      text.push(offer.lastStatusComment);
    }
    detailsInfoPanels.push({
      type: EOfferInfoPanelType.Paused,
      title: 'Причина приостановки',
      text: text.length > 0 ? text : null,
    });
  } else {
    if (offer?.rejectionReasonType?.name) {
      text.push(offer.rejectionReasonType.name);
    }
    if (offer?.lastStatusComment) {
      text.push(offer.lastStatusComment);
    }
    if (text.length) {
      detailsInfoPanels.push({
        type: EOfferInfoPanelType.Rejected,
        title: 'Причина отклонения',
        text,
      });
    }
  }

  return detailsInfoPanels.length > 0 ? detailsInfoPanels : null;
};

export default useOfferDetailsInfoPanels;
