import Api from '@/data/api';
import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterValueItem,
  EDataFilterType,
  EOfferType,
  EPartnerStatus,
  Nullable,
  pageSizeAll,
  SportOptionTyped,
} from '@/domain';

export enum EReportOffersByPartnerFilterItem {
  DatePeriod = 'datePeriod',
  OfferType = 'offerType',
  Partner = 'partner',
}

export type ReportOffersByPartnerFilterEditStrategy = DataFilterStrategyBase<EReportOffersByPartnerFilterItem>;

export type ReportOffersByPartnerFilterValues = {
  readonly [EReportOffersByPartnerFilterItem.DatePeriod]?: DataFilterValueItem<
    Nullable<[Nullable<string>, Nullable<string>]>
  >;
  readonly [EReportOffersByPartnerFilterItem.OfferType]?: DataFilterValueItem<Nullable<EOfferType>>;
  readonly [EReportOffersByPartnerFilterItem.Partner]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem>>;
};

export const getReportOffersByPartnerFilterStrategies = (
  values: ReportOffersByPartnerFilterValues,
  partnerId: Nullable<UUID>,
  offerTypes: SportOptionTyped<EOfferType>[]
): ReportOffersByPartnerFilterEditStrategy[] => {
  const strategies: ReportOffersByPartnerFilterEditStrategy[] = [];
  strategies.push(
    {
      type: EDataFilterType.DatePeriod,
      key: EReportOffersByPartnerFilterItem.DatePeriod,
      label: '',
      labelPeriod: {
        from: 'Период с',
        to: 'по',
      },
      preview: null,
      buttonsPeriod: true,
      value: values[EReportOffersByPartnerFilterItem.DatePeriod]?.value || null,
    },
    {
      type: EDataFilterType.ListSingle,
      key: EReportOffersByPartnerFilterItem.OfferType,
      label: 'Тип предложения',
      preview: `Тип предложения (${values[EReportOffersByPartnerFilterItem.OfferType]?.value?.length})`,
      previews: offerTypes.map(t => t.name),
      value: values[EReportOffersByPartnerFilterItem.OfferType]?.value || null,
      items: offerTypes.map(t => ({ id: t.id, name: t.name })),
      querydsl: {
        operator: DataFilterQueryDslOperator.In,
      },
    }
  );

  if (!partnerId) {
    strategies.push({
      type: EDataFilterType.AutocompleteSingle,
      key: EReportOffersByPartnerFilterItem.Partner,
      label: 'Выбрать партнера',
      preview: `Партнер (${values[EReportOffersByPartnerFilterItem.Partner]?.value?.name})` || null,
      value: values[EReportOffersByPartnerFilterItem.Partner]?.value || null,
      source: (name: string) =>
        Api.partner
          .all({
            query: name,
            sort: 'name,asc',
            page: 1,
            statuses: [EPartnerStatus.Disabled, EPartnerStatus.Enabled],
            pageSize: pageSizeAll,
          })
          .then(response => response.data),
    });
  }

  return strategies;
};
