import { EUserStatus, MpCustomer } from '@/domain';
import { DataTableColumns } from '@components/common/table';
import { TableActionProcess } from '@components/common/table/types';
import {
  CustomerActions,
  CustomerActionTableType,
  ECustomerActionType,
  GetCustomerActionsByPermissions,
} from '../types';
import { getCustomerActionsConfigByPermissions } from '../utils';

export type CustomerTableActionProcess = TableActionProcess<CustomerActionTableType>;

export enum ECustomerTableTab {
  Active = 'active',
  Deactivated = 'deactivated',
}

export enum ECustomerTableColumn {
  Date = 'createdAt',
  PersonnelNumber = 'pernr',
  Name = 'lastName',
  Phone = 'phone',
  Email = 'email',
}

export const customerTableColumns: DataTableColumns<ECustomerTableColumn> = {
  [ECustomerTableColumn.Date]: {
    title: 'Дата',
    width: '10rem',
    sortable: true,
  },
  [ECustomerTableColumn.PersonnelNumber]: {
    title: 'Табельный №',
    width: '10rem',
    sortable: true,
  },
  [ECustomerTableColumn.Name]: {
    title: 'ФИО',
    width: '30rem',
    wrap: true,
    sortable: true,
  },
  [ECustomerTableColumn.Phone]: {
    title: 'Телефон',
    width: '15rem',
    sortable: true,
  },
  [ECustomerTableColumn.Email]: {
    title: 'Электронная почта',
    wrap: true,
    sortable: true,
  },
};

export const getCustomerTableColumns = (tab: ECustomerTableTab): DataTableColumns<ECustomerTableColumn> => {
  const defaultColumns = getCustomerTableDefaultColumns(tab);

  const allColumns: ECustomerTableColumn[] = [];

  allColumns.push(
    ECustomerTableColumn.Date,
    ECustomerTableColumn.PersonnelNumber,
    ECustomerTableColumn.Name,
    ECustomerTableColumn.Phone,
    ECustomerTableColumn.Email
  );

  return allColumns.reduce<DataTableColumns<ECustomerTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...customerTableColumns[column],
        hidden: !defaultColumns.includes(column),
      },
    };
  }, {});
};

const customerTableDefaultColumns = [
  ECustomerTableColumn.Date,
  ECustomerTableColumn.PersonnelNumber,
  ECustomerTableColumn.Name,
  ECustomerTableColumn.Phone,
  ECustomerTableColumn.Email,
];

const getCustomerTableDefaultColumns = (tab: ECustomerTableTab): ECustomerTableColumn[] => {
  return customerTableDefaultColumns;
};

// фильтрация действий для таблицы покупателей по всем действиям
const filterCustomerTableActions = (
  actions: CustomerActions<ECustomerActionType>
): CustomerActions<CustomerActionTableType> => {
  const tableActions: CustomerActions<CustomerActionTableType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case ECustomerActionType.Activate:
      case ECustomerActionType.Deactivate:
        tableActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          position: action.position,
        });
        break;
    }
  });

  return tableActions;
};

// получение возможных действий с покупателем для вкладки таблицы
const getCustomerTableTabActionsConfig = (
  tab: ECustomerTableTab,
  actions: CustomerActions<CustomerActionTableType>
): CustomerActions<CustomerActionTableType> => {
  switch (tab) {
    case ECustomerTableTab.Active:
      return actions.filter(item => [ECustomerActionType.Deactivate].includes(item.type));
    case ECustomerTableTab.Deactivated:
      return actions.filter(item => [ECustomerActionType.Activate].includes(item.type));
  }
};

export const getCustomerTableTabActions = (
  permissions: GetCustomerActionsByPermissions,
  tab: ECustomerTableTab
): CustomerActions<CustomerActionTableType> => {
  const allowedActions = getCustomerActionsConfigByPermissions(permissions);
  const actions = filterCustomerTableActions(allowedActions);

  return getCustomerTableTabActionsConfig(tab, actions);
};

export type CustomerActionTableCall = {
  readonly action: CustomerActionTableType;
  readonly customer: MpCustomer;
};

export const getCustomerTableTabName = (tab: ECustomerTableTab): string => {
  switch (tab) {
    case ECustomerTableTab.Active:
      return 'Активные';
    case ECustomerTableTab.Deactivated:
      return 'Отключённые';
  }
};

export type CustomerTableTabsCounter = {
  [name in ECustomerTableTab]?: number;
};

export const getCustomerStatusesByTableTab = (tab: ECustomerTableTab): EUserStatus[] => {
  switch (tab) {
    case ECustomerTableTab.Active:
      return [EUserStatus.Enabled];
    case ECustomerTableTab.Deactivated:
      return [EUserStatus.Disabled];
  }
};
