import MasterActionsComponent from '@components/common/actions/master';
import useNavAdapter from '@components/common/actions/navAdapter/hooks';
import { DefaultFooterWrapper } from '@components/common/wrappers/footer';
import { useSelector } from 'react-redux';
import { AdCampaignActionCreateType } from '../../types';
import { useContextLifecycle } from '../hooks/useContextLifecycle';
import useAdCampaignCreateStepper from '../hooks/useStepper';
import { adCampaignCreateAdCampaignSelector } from '../store/selectors';

type AdCampaignCreateFooterAdapterProps = {};

const AdCampaignCreateFooterAdapter = (props: AdCampaignCreateFooterAdapterProps) => {
  const { actions, onAction } = useContextLifecycle();

  const adCampaign = useSelector(adCampaignCreateAdCampaignSelector);

  const { openNextStep, currentStepIndex, openPrevStep, steps } = useAdCampaignCreateStepper();

  const onNextStep = () => {
    if (adCampaign) {
      openNextStep();
    }
  };

  const { adapter: navAdapter, actions: navActions } = useNavAdapter({
    openPrevStep,
    currentStepIndex,
    openNextStep: onNextStep,
    stepsCount: steps.length,
  });

  return (
    <MasterActionsComponent<AdCampaignActionCreateType>
      show={!!actions.length || !!navActions.length}
      actions={actions}
      navAdapter={navAdapter}
      onAction={onAction}
      wrapper={DefaultFooterWrapper}
    />
  );
};

export default AdCampaignCreateFooterAdapter;
