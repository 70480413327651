import { Route, Switch } from 'react-router-dom';
import rootRouting from '../../../routing';
import NotFoundScreen from '../../screens/notFound';
import ResetPasswordScreen from '../../screens/resetPassword';
import LoginContainer from './login/container';

const routing = {
  root: `${rootRouting.auth}`,
  resetPassword: `${rootRouting.auth}/resetPassword`,
};

const AuthEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.resetPassword}
        component={ResetPasswordScreen}
      />
      <Route component={LoginContainer} />

      <Route component={NotFoundScreen} />
    </Switch>
  );
};

export default AuthEntry;
