import { BookingOrder, BookingOrderItem, BookingOrderStatus, UUID } from '@/domain';
import { PanelAction, PanelActions } from '../../types';
import { ValidationRules } from '../../utils/validation';
import { BookingOrderFieldCommonProps } from './components/fieldsEdit/types';

export enum EBookingOrderResponsible {
  Manager = 'manager',
  Customer = 'customer',
}

export enum EBookingOrderActionType {
  /** Сохранить */
  Save = 'save',
  /** Создать новый */
  New = 'new',
  /** Получен клиентом  */
  Give = 'give',
  Renew = 'renew',
  /** Отменить */
  Cancel = 'cancel',
  /** Подтвердить */
  Confirm = 'confirm',
}

export type BookingOrderActionEditType = Exclude<
  EBookingOrderActionType,
  EBookingOrderActionType.New | EBookingOrderActionType.Renew
>;

export type BookingOrderActionDetailsType = Exclude<
  EBookingOrderActionType,
  | EBookingOrderActionType.New
  | EBookingOrderActionType.Renew
  | EBookingOrderActionType.Confirm
  | EBookingOrderActionType.Give
>;

export type BookingOrderActionTableType = Exclude<
  EBookingOrderActionType,
  EBookingOrderActionType.Save | EBookingOrderActionType.New
>;

export type ActionBookingOrderType = BookingOrder | BookingOrderView;

export type BookingOrderAction<A extends string = EBookingOrderActionType, P = any> = PanelAction<A, P>;
export type BookingOrderActions<A extends string = EBookingOrderActionType> = PanelActions<A>;

export enum EBookingOrderTableColumn {
  CreateAt = 'createdAt',
  ObjectName = 'offer.name',
  ObjectCode = 'offer.code',
  ObjectPartner = 'partner.name',
  Number = 'number',
  Cost = 'cost',
  CustomerName = 'customer.lastName',
  ManagerComment = 'managerComment',
  CancelInitiator = 'cancelInitiator',
  CancelReason = 'cancelReason',
  CSP = 'hasRzdSocialPackage',
}

export enum EBookingOrderTableTab {
  New = 'NEW',
  Confirmed = 'CONFIRMED',
  Given = 'GIVEN',
  Cancelled = 'CANCELLED',
}

export interface BookingOrderView extends Omit<BookingOrder, 'discriminator' | 'orderItems'> {
  readonly orderItems: BookingOrderItemView[];
}

export type BookingOrderCounterByStatus = {
  [name in BookingOrderStatus]?: number;
};

export type BookingOrderEditFieldCommonProps<Key extends keyof BookingOrderView> = BookingOrderFieldCommonProps<
  BookingOrderView,
  Key
>;

export type BookingOrderItemView = Omit<BookingOrderItem, 'id'> & {
  readonly id: Nullable<UUID>;
  // runtime атрибуты
  readonly internalId: UUID;
};

export type BookingOrderValidationRules = {
  readonly order: ValidationRules<BookingOrderView>;
  readonly orderItem: ValidationRules<BookingOrderItemView>;
};
