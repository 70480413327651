import { Typography } from '@mui/material';
import { ContentTarget } from '../../../../../../domain/model';
import { EMultiSelectorValueType } from '../../../../../../domain/model/enums';
import { ContentTargetViewComponent } from '../../types';

const attribute: keyof ContentTarget = 'gender';

const ContentTargetViewGender: ContentTargetViewComponent = props => {
  const {
    target,
    nsi: { genderTypes },
  } = props;

  const names: string[] = [];
  
  const targetGender = target[attribute];

  if (targetGender) {
    if (targetGender.select === EMultiSelectorValueType.All) {
      names.push('для всех полов');
    } else {
      if (targetGender.in?.length) {
        const targetGenders = targetGender.in;
        names.push(
          ...genderTypes.filter(genderType => targetGenders.includes(genderType.id)).map(genderType => genderType.name)
        );
      }
    }
  }
  
  return (
    <>
      <Typography color='textSecondary'>Пол</Typography>
      <Typography>{names.join(', ') || '-'}</Typography>
    </>
  );
};

export default ContentTargetViewGender;
