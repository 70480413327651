import { ButtonProps, Grid, GridSize, TextFieldProps } from '@mui/material';
import { ChangeEvent } from 'react';
import { MPFormInputProps, MPNaturalNumberInputProps } from '..';
import { Nullable } from '../../../../../domain/model/types';
import ContentLoader from '../../../../components/common/loader';
import { StyledButton, StyledInput, StyledNumberInput } from './controls';

type MPInputButtonOwnProps = {
  readonly buttonProps?: ButtonProps;
  readonly buttonText: string;
  readonly inputSize?: Omit<GridSize, 12>;
  readonly isFetching?: boolean;
  readonly onExecute: (value: Nullable<string>) => void;
};

type MPFormInputOwnProps = Omit<TextFieldProps & MPFormInputProps, 'value'> & {
  readonly naturalNumberInput?: false;
  readonly value: string;
};

type MPNaturalNumberInputOwnProps = Omit<MPNaturalNumberInputProps, 'value'> & {
  readonly naturalNumberInput: true;
  readonly value: Nullable<number>;
};

type MPInputButtonProps = MPInputButtonOwnProps & (MPFormInputOwnProps | MPNaturalNumberInputOwnProps);

export const MPInputButton = (props: MPInputButtonProps) => {
  const {
    buttonProps,
    buttonText,
    InputProps,
    inputSize = 8,
    isFetching,
    naturalNumberInput,
    value,
    onChange,
    onExecute,
    ...others
  } = props;

  const buttonSize = inputSize === 'auto' ? 'auto' : 12 - (inputSize as number);

  const changeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value?: Nullable<number>) => {
    onChange?.(event as ChangeEvent<HTMLInputElement>, value ?? null);
  };

  const inputElement = naturalNumberInput ? (
    <StyledNumberInput
      InputProps={{ ...InputProps }}
      value={value}
      onChange={changeHandler}
      {...others}
    />
  ) : (
    <StyledInput
      InputProps={{ ...InputProps }}
      value={value}
      onChange={changeHandler}
      {...others}
    />
  );

  return (
    <Grid container>
      <Grid
        item
        xs={inputSize as GridSize}
      >
        {inputElement}
      </Grid>
      <Grid
        item
        xs={buttonSize as GridSize}
      >
        <StyledButton
          variant='outlined'
          onClick={onExecute}
          {...buttonProps}
        >
          {buttonText}
          {isFetching && <ContentLoader />}
        </StyledButton>
      </Grid>
    </Grid>
  );
};
