import moment from 'moment';
import { EDateFormat } from '../../../../../domain/model/formats';
import CmsContainerTableCell, { CmsContainerTableCellCommonProps } from './index';

type CmsContainerTableCellEndDateProps = CmsContainerTableCellCommonProps;

const CmsContainerTableCellEndDate = (props: CmsContainerTableCellEndDateProps) => {
  const {
    cmsContainer: { endDate },
  } = props;

  const value = endDate && moment(endDate).format(EDateFormat.DisplayDefault);

  return (
    <CmsContainerTableCell
      {...props}
      value={value}
    />
  );
};

export default CmsContainerTableCellEndDate;
