import { FCC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import store from '../../../../../data/store/store';
import { BookingOrder } from '../../../../../domain/model/order';
import { Nullable } from '../../../../../domain/model/types';
import useHistoryExtensions from '../../../../hooks/useHistoryExtensions';
import { useBookingOrderActions } from '../../actions/useAction';
import { getBookingOrderEditRoute, getBookingOrdersTableRoute } from '../../entry';
import { getBookingOrderTableTabForStatus } from '../../table/utils';
import { EBookingOrderActionType, EBookingOrderTableTab } from '../../types';
import { BookingOrderDetailsContainerProps } from '../container';
import { BookingOrderDetailsHandlersContext, BookingOrderDetailsHandlersContextValue } from '../context';
import { bookingOrderDetailsBookingOrderSelector } from '../store/selectors';
import { bookingOrderDetailsByIdFetch, bookingOrderDetailsSetDialogState } from '../store/slice';

export const BookingOrderDetailHandlersProvider: FCC<BookingOrderDetailsContainerProps> = ({ children, id }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const { onTryCancel } = useBookingOrderActions();

  /**
   * Получение объекта букинга
   */
  const getBookingOrderFromState = useCallback((): Nullable<BookingOrder> => {
    const state = store.getState();
    return bookingOrderDetailsBookingOrderSelector(state);
  }, [store]);

  const onBookingOrderTryCancel = useCallback<
    BookingOrderDetailsHandlersContextValue['onBookingOrderTryCancel']
  >(() => {
    const bookingOrder = getBookingOrderFromState();

    if (bookingOrder) {
      onTryCancel(bookingOrder);
    }
  }, [getBookingOrderFromState]);

  /**
   * обновить данные объекта
   */
  const onRefresh = useCallback<BookingOrderDetailsHandlersContextValue['onRefresh']>(() => {
    dispatch(bookingOrderDetailsByIdFetch({ id }));
  }, [dispatch]);

  /**
   * Открываем или закрываем диалоговое окно
   */
  const onChangeDialogState = useCallback<BookingOrderDetailsHandlersContextValue['onChangeDialogState']>(
    (name, bookingOrder) => {
      dispatch(bookingOrderDetailsSetDialogState({ name, data: bookingOrder }));
    },
    [dispatch]
  );

  /**
   * Открыть объект для редактирования
   */
  const onBookingOrderOpenEdit = useCallback<BookingOrderDetailsHandlersContextValue['onBookingOrderOpenEdit']>(() => {
    const bookingOrder = getBookingOrderFromState();
    if (bookingOrder) {
      history.push(getBookingOrderEditRoute({ id: bookingOrder.id }));
    }
  }, [history, getBookingOrderFromState]);

  const onBookingOrderSwitchToEdit = useCallback<BookingOrderDetailsHandlersContextValue['onBookingOrderSwitchToEdit']>(
    bookingOrder => {
      history.replace(getBookingOrderEditRoute({ id: bookingOrder.id }));
    },
    [history]
  );

  /**
   * Закрыть мастер просмотра
   */
  const onBookingOrderClose = useCallback(
    (action: Nullable<EBookingOrderActionType>) => {
      gotoPrevIndependentLocation(getBookingOrdersTableRoute({ tab: EBookingOrderTableTab.Given }));
    },
    [gotoPrevIndependentLocation]
  );

  /**
   * Обновить данные объекта
   */
  const onBookingOrderRefetch = useCallback<BookingOrderDetailsHandlersContextValue['onBookingOrderRefetch']>(
    async bookingOrder => {
      return await dispatch(bookingOrderDetailsByIdFetch({ id: bookingOrder.id })).unwrap();
    },
    [dispatch]
  );

  /**
   * Закрываем details
   */
  const onClose = useCallback(() => {
    const defaultTab = EBookingOrderTableTab.Given;
    const bookingOrder = getBookingOrderFromState();
    const tab = bookingOrder?.status ? getBookingOrderTableTabForStatus(bookingOrder.status) : defaultTab;
    gotoPrevIndependentLocation(getBookingOrdersTableRoute({ tab }));
  }, []);

  const value: BookingOrderDetailsHandlersContextValue = {
    onRefresh,
    onClose,
    onChangeDialogState,

    onBookingOrderOpenEdit,
    onBookingOrderTryCancel,
    onBookingOrderSwitchToEdit,
    onBookingOrderClose,
    onBookingOrderRefetch,
  };

  return (
    <BookingOrderDetailsHandlersContext.Provider value={value}>{children}</BookingOrderDetailsHandlersContext.Provider>
  );
};
