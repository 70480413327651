import { ProductOffer } from '../../../../../domain/model/productOffer';
import { AppUserSpecific } from '../../../../../domain/model/user';
import { ProductLifeCycle } from '../../lifecycle/types';
import {
  EProductActionType,
  ProductActions,
  ProductActionTableCollectionType,
  ProductActionTableType,
} from '../../types';
import { EProductTableTab } from '../utils';
import { changeProductActionPosition, makeProductActionPrimary, renameProductAction } from '../../utils/actions';
import { EPanelActionPosition } from '../../../../types';

interface UseProductCollectionActionsProps {
  readonly tab: EProductTableTab;
  readonly userSpecific: AppUserSpecific;
  readonly lifecycle: ProductLifeCycle;
  readonly products: ProductOffer[];
  readonly allowedActions: ProductActions<ProductActionTableType>;
}

type UseProductCollectionActionsResult = ProductActions<ProductActionTableCollectionType>;

const intersectActions = (a: ProductActions<ProductActionTableType>, b: ProductActions<ProductActionTableType>) =>
  a
    // фильтруем для определения пересечений
    .filter(itemA => b.some(itemB => itemA.type === itemB.type))
    // ставим disabled если действие отключено в любом из массивов
    .map(itemA => ({
      ...itemA,
      disabled: itemA.disabled && b.find(itemB => itemA.type === itemB.type)?.disabled,
    }));

const useProductCollectionActions = (props: UseProductCollectionActionsProps): UseProductCollectionActionsResult => {
  const { tab, lifecycle, products, allowedActions } = props;

  switch (tab) {
    case EProductTableTab.Moderation:
    case EProductTableTab.Paused: {
      const resultActions = products.reduce<ProductActions<ProductActionTableType>>((prev, product) => {
        // получаем ограничения ЖЦ
        const restrictions = lifecycle.build({ obj: product, allowedActions });
        const lifecycleActions = restrictions.nextActions as ProductActions<ProductActionTableType>;

        // фильтруем допустимые действия для массовых модификаций
        const collectionActions = lifecycleActions
          .filter(action => [EProductActionType.Approve, EProductActionType.Resume].includes(action.type))
          .map(action => ({ ...action, toolbar: false }));

        // рассчитываем пересечения
        return intersectActions(prev.length ? prev : collectionActions, collectionActions);
      }, []) as ProductActions<ProductActionTableCollectionType>;

      renameProductAction(resultActions, EProductActionType.Resume, 'Возобновить');
      makeProductActionPrimary(resultActions, [EProductActionType.Resume]);
      changeProductActionPosition(resultActions, EProductActionType.Resume, [EPanelActionPosition.Default]);

      return resultActions;
    }
    default: {
      return [];
    }
  }
};

export default useProductCollectionActions;
