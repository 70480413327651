import axios from 'axios';
import { ENoticeStatus } from '../../domain/model/enums';
import { MPAuthenticationClient } from '../../presentation/features/auth/types';
import Notifier from '../../system/notifier';
import { ENetworkErrorCode } from './types';

export default class HttpClient {
  private static instance: HttpClient;

  public static getInstance(): HttpClient {
    if (!HttpClient.instance) {
      HttpClient.instance = new HttpClient();
    }

    return HttpClient.instance;
  }

  private authService: MPAuthenticationClient | null = null;

  private constructor() {}

  init() {
    this.initAxios();
  }

  private initAxios() {
    axios.defaults.headers.common['x-app-id'] = 'coms-admin';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common.Accept = 'application/json';
    this.initTokenInterceptor();
  }

  setAuthService(authService: MPAuthenticationClient) {
    this.authService = authService;
  }

  getAuthService(): MPAuthenticationClient {
    return this.authService!;
  }

  private initTokenInterceptor() {
    axios.interceptors.request.use(
      config => {
        config.headers.Authorization = `Bearer ${this.authService!.token}`;
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(undefined, error => {
      const logOut = () => {
        setTimeout(() => {
          this.authService!.logout({ redirectUri: window.location.origin });
        }, 5000);
      };

      if (error?.response?.status === 401) {
        this.authService!.updateToken(99999999999999)
          .then(success => {
            if (success) {
              console.debug('Token was updated');
            } else {
              Notifier.getInstance().addNotice(ENoticeStatus.Error, 'Необходима авторизация');
              logOut();
            }
          })
          .catch(updateTokenError => {
            Notifier.getInstance().addNotice(ENoticeStatus.Error, 'Необходима авторизация');
            console.warn(`Не удалось обновить токен безопасности: ${updateTokenError?.message}`);
            logOut();
          });
      } else if (error?.response?.status === 403 && error?.response?.data?.code === ENetworkErrorCode.UserBlocked) {
        Notifier.getInstance().addNotice(ENoticeStatus.Error, 'Текущий пользователь был заблокирован');
        logOut();
      }
      return Promise.reject(error);
    });
  }
}
