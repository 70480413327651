import { useState } from 'react';
import { ApprovalOfferRegistryRequest } from '../../../../../domain/model/offerApprovalRegistry';
import { OnChangeObjectAttribute } from '../../../../types';
import { pluralize } from '../../../../utils/pluralize';
import CorpOfferApprovalRegistryDialog from '../approvalRegistryDialog';

interface CorpOffersTableChangeApprovalRegistryProps {
  readonly corpOffersCount: number;
  readonly isExecuting: boolean;
  readonly onChange: (approvalRegistry: ApprovalOfferRegistryRequest) => void;
  readonly onClose: () => void;
}

const CorpOffersTableChangeApprovalRegistry = (props: CorpOffersTableChangeApprovalRegistryProps) => {
  const { corpOffersCount, isExecuting, onChange, onClose } = props;

  const [approvalRegistry, setApprovalRegistry] = useState<ApprovalOfferRegistryRequest>({ number: null, date: null });

  const onChangeApprovalRegistryAttribute: OnChangeObjectAttribute<ApprovalOfferRegistryRequest> = (name, value) => {
    setApprovalRegistry(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const description = `Введите реквизиты приказа к ${corpOffersCount}
  ${pluralize(corpOffersCount, [
    'корпоративному предложению',
    'корпоративным предложениям',
    'корпоративным предложениям',
  ])}`;

  return (
    <CorpOfferApprovalRegistryDialog
      open={corpOffersCount > 0}
      approvalRegistry={approvalRegistry}
      isValidation={true}
      isExecuting={isExecuting}
      description={description}
      onChangeAttribute={onChangeApprovalRegistryAttribute}
      onChange={() => {
        onChange(approvalRegistry);
        onClose();
      }}
      onClose={onClose}
    />
  );
};

export default CorpOffersTableChangeApprovalRegistry;
