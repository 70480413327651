import { ETimeFormat } from '../../../../../../../domain/model/formats';
import { utcToLocalTimeZone } from '../../../../../../utils/dates';
import OfferHistoryTableCell, { OfferHistoryTableCellCommonProps } from './index';

const OfferHistoryTableCellEnteredAtTime = (props: OfferHistoryTableCellCommonProps) => {
  const {
    history: { enteredAt },
  } = props;

  const value = enteredAt && utcToLocalTimeZone(enteredAt).format(ETimeFormat.Default);

  return (
    <OfferHistoryTableCell
      {...props}
      value={value}
    />
  );
};

export default OfferHistoryTableCellEnteredAtTime;
