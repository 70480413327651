import React from 'react';
import { MenuPanelItem } from '../../../../types';
import { CommonList } from '../controls';
import MenuPanelListItem from '../item';

interface MenuPanelListProps {
  readonly items: MenuPanelItem[];
  readonly onItemClick: (item: MenuPanelItem) => void;
  readonly isSelectedItem: (item: MenuPanelItem) => boolean;
  readonly isOpenedSubMenu: (item: MenuPanelItem) => boolean;
}

const MenuPanelList = (props: MenuPanelListProps) => {
  const { items, onItemClick, isSelectedItem, isOpenedSubMenu } = props;

  return (
    <CommonList>
      {items.map(item => (
        <React.Fragment key={item.id}>
          <MenuPanelListItem
            item={item}
            onItemClick={onItemClick}
            isSelected={isSelectedItem}
            isOpenedSubMenu={isOpenedSubMenu}
          />
        </React.Fragment>
      ))}
    </CommonList>
  );
};

export default MenuPanelList;
