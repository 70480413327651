import { ApprovalOfferRegistryShort, MpUser, Nullable } from '@/domain';
import { MPStepperStep } from '@components/common/stepper';
import { ReactElement } from 'react';

export enum ECorpOfferStep {
  General = 'general',
  Description = 'description',
  Conditions = 'conditions',
  PromoCodes = 'promo_codes',
}

export type CorpOfferStep = MPStepperStep<ECorpOfferStep>;

export type CorpOfferTableStubAdapterProps = {
  readonly children: (props: CorpOfferTableStubAdapterResult) => ReactElement<any, any>;
};

export type CorpOfferTableStubAdapterResult = {
  readonly canViewTable: boolean;
  readonly isCreateDisabled?: boolean;
  readonly stub?: Nullable<JSX.Element>;
  readonly createInfo?: Nullable<string>;
};

export type CorpOfferTableStubAdapterType = (props: CorpOfferTableStubAdapterProps) => ReactElement<any, any>;

export type ApprovalOfferRegistryPreview = ApprovalOfferRegistryShort & {
  readonly name: string;
};

export type CorpOfferModeratorPreview = MpUser & {
  readonly name: string;
};

export const corpOfferSteps: CorpOfferStep[] = [
  {
    key: ECorpOfferStep.General,
    label: 'Основные данные',
  },
  {
    key: ECorpOfferStep.Description,
    label: 'Описание',
  },
  {
    key: ECorpOfferStep.Conditions,
    label: 'Условия',
  },
  {
    key: ECorpOfferStep.PromoCodes,
    label: 'База кодов',
  },
];

export enum ECorpOfferTableColumn {
  Code = 'code',
  Name = 'name',
  Partner = 'partner.name',
  PartnerType = 'partner.type',
  Period = 'startDate',
  Price = 'price',
  OfferCounts = 'notUsedOfferCount',
  Ndfl = 'ndflDescription',
  PartnerResponsible = 'responsiblePerson.fullName',
  StoreResponsible = 'storeResponsiblePerson.fullName',
  Moderator = 'moderator',
  LastStatusDate = 'lastStatusDate',
  ApprovalRegistry = 'approvalRegistry.number',
  Categories = 'categories',
  TargetLocalities = 'targetLocalities',
  PromotionType = 'promotionType',
  TargetRoads = 'targetRoads',
  TargetOrgUnits = 'targetOrgUnits',
  TargetTradeUnionMembersOnly = 'targetTradeUnionMembersOnly',
  TargetHavingChildFamilyMemberOnly = 'targetHavingChildFamilyMemberOnly',
  TargetFamilyMemberOnly = 'targetFamilyMemberOnly',
  TargetGender = 'targetGender',
  Status = 'status',
  LastStatusAuthor = 'lastStatusAuthor',
  PausedReason = 'pausedReason',
  Inn = 'partner.inn',
  Kpp = 'partner.kpp',
  Ogrn = 'partner.ogrn',
}
