import { FCC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PanelAction } from '../../../../types';
import { useAdCampaignActions } from '../../actions/useActions';
import { AdCampaignActionDetailsType, EAdCampaignActionType } from '../../types';
import { getAdCampaignActionsConfigByPermissions } from '../../utils/actions';
import { AdCampaignDetailsContainerProps } from '../container';
import { AdCampaignDetailsLifecycleContext, AdCampaignDetailsLifecycleContextValue } from '../context';
import useAdCampaignDetailsActions from '../hooks/useActions';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { adCampaignDetailsAdCampaignSelector } from '../store/selectors';

export const AdCampaignDetailLifecycleProvider: FCC<AdCampaignDetailsContainerProps> = ({
  children,
  id,
  lifecycle,
  access,
}) => {
  const handlers = useContextHandlers();

  const {
    utils: {
      selectors: { getIsAdCampaignExecutedActionsSelector },
    },
  } = useAdCampaignActions();

  const adCampaign = useSelector(adCampaignDetailsAdCampaignSelector);

  const isActionsForClose = useSelector(
    getIsAdCampaignExecutedActionsSelector(
      id,
      EAdCampaignActionType.Archive,
      EAdCampaignActionType.Resume,
      EAdCampaignActionType.Pause
    )
  );

  const allowedActions = getAdCampaignActionsConfigByPermissions({
    ...access,
  });

  const lifeCycleRules = lifecycle.build({ allowedActions, obj: adCampaign });

  const actions = useAdCampaignDetailsActions({
    lifeCycleRules,
  });

  const onAction = (action: PanelAction<AdCampaignActionDetailsType>) => {
    if (!adCampaign) {
      return;
    }

    const { type } = action;
    switch (type) {
      case EAdCampaignActionType.Pause:
        handlers.onAdCampaignTryPause();
        break;
      case EAdCampaignActionType.Resume:
        handlers.onAdCampaignTryResume();
        break;
      case EAdCampaignActionType.Edit:
        handlers.onAdCampaignOpenEdit(adCampaign);
        break;
      case EAdCampaignActionType.Archive:
        handlers.onAdCampaignTryArchive();
        break;
    }
  };

  useEffect(() => {
    if (isActionsForClose) {
      handlers.onAdCampaignClose(isActionsForClose);
    }
  }, [isActionsForClose, handlers.onAdCampaignClose]);

  const value: AdCampaignDetailsLifecycleContextValue = {
    lifecycle,
    lifeCycleRules,
    actions,
    onAction,
  };

  return (
    <AdCampaignDetailsLifecycleContext.Provider value={value}>{children}</AdCampaignDetailsLifecycleContext.Provider>
  );
};
