import { EValidationType, ValidationResult, ValidationRules } from 'presentation/utils/validation';
import { ActionConfirmWithCode } from '@/domain';
import validateObject from '@/presentation/hooks/validation/utils';
import {
  confirmCodeCodeSelector,
  confirmCodeIsFetchingSelector,
  confirmCodeValidationSelector,
} from '../store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  confirmCodeClearValidation,
  confirmCodeSendCode,
  confirmCodeSetCode,
  confirmCodeSetValidation,
} from '@features/requiredAction/confirmCode/store/slice';

type CodeData = {
  code: string;
};

export const rules: ValidationRules<CodeData> = {
  code: {
    required: true,
    requiredMessage: 'Необходимо указать корректный код',
  },
};

type CodeValue = { code: string };

type UseCodeEdit = {
  readonly code: string;
  readonly codeTtl: number;
  readonly codeRequestId: string;
  readonly codeNextAttemptDate: string;
  readonly isFetching?: boolean;
  readonly validation: Nullable<ValidationResult<CodeData>>;
  readonly setCode: (code: string) => void;
  readonly onSendCode: (code: string) => Promise<boolean>;
};

type UseCodeEditProps = {
  readonly source: ActionConfirmWithCode;
  readonly onSuccess: () => void;
};

export const useCodeEdit = (props: UseCodeEditProps): UseCodeEdit => {
  const {
    source: { otpId, otpTtl, nextAttempt },
    onSuccess,
  } = props;

  const dispatch = useDispatch();
  const code = useSelector(confirmCodeCodeSelector);
  const isFetching = useSelector(confirmCodeIsFetchingSelector);
  const validation = useSelector(confirmCodeValidationSelector);

  const setValidation = (value: Nullable<ValidationResult<CodeValue>>) => {
    dispatch(confirmCodeSetValidation(value));
  };

  const clearValidation = () => {
    dispatch(confirmCodeClearValidation());
  };

  const setCode = (value: string) => {
    dispatch(confirmCodeSetCode(value));
    clearValidation();
  };

  const onSendCode = () => {
    const validation = validateObject<CodeValue>({ code }, rules);
    const isValid = validation.isValid;

    setValidation(validation.results);

    if (isValid && otpId) {
      return dispatch(confirmCodeSendCode({ code, otpId }))
        .unwrap()
        .then(res => {
          onSuccess();
          return true;
        })
        .catch(error => {
          setValidation({
            code: {
              hasError: true,
              type: EValidationType.Error,
              message: error?.message || 'Произошла неизвестная ошибка',
            },
          });

          return Promise.resolve(false);
        });
    }

    return Promise.resolve(false);
  };

  return {
    code,
    codeTtl: otpTtl,
    codeRequestId: otpId,
    codeNextAttemptDate: nextAttempt,
    isFetching,
    validation,
    setCode,
    onSendCode,
  };
};
