import { Grid } from '@mui/material';
import { useMemo, useState } from 'react';
import { BookingOfferService, BookingServicePriceItem } from '../../../../../domain/model/booking';
import { EOfferServiceStatus } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';
import { MPAutocompleteSingleSelect } from '../../../../theme/ui-kit/autocomplete/single';
import { MPButton } from '../../../../theme/ui-kit/button';
import { BookingOrderItemView } from '../../types';
import { getEmptyBookingOrderItemView } from '../../utils/common';

type BookingOrderAddItemDialogProps = {
  readonly services: BookingOfferService[];
  readonly onExecute: (orderItem: BookingOrderItemView) => void;
  readonly onClose: () => void;
};

type BookingOfferServiceValid = Omit<BookingOfferService, 'name'> & {
  readonly name: string;
};

type BookingOfferServicePriceItemValid = Omit<BookingServicePriceItem, 'name'> & {
  readonly name: string;
};

const BookingOrderAddItemDialog = (props: BookingOrderAddItemDialogProps) => {
  const { services, onExecute, onClose } = props;

  const [serviceSelect, setServiceSelect] = useState<Nullable<BookingOfferServiceValid>>(null);
  const [priceItemSelect, setPriceItemSelect] = useState<Nullable<BookingOfferServicePriceItemValid>>(null);

  // фильтруем не активные услуги
  const options: BookingOfferServiceValid[] = services
    .filter(service => service.status !== EOfferServiceStatus.Disabled)
    .map(service => ({ ...service, name: service.name ?? 'Без названия' }));

  const optionsPriceItems = useMemo<BookingOfferServicePriceItemValid[]>(() => {
    if (!serviceSelect) {
      return [];
    }

    const getServiceSelect = services.find(service => service.id === serviceSelect.id);
    if (!getServiceSelect) {
      return [];
    }

    return (
      getServiceSelect.priceItems?.map(item => ({
        ...item,
        name: item.name ?? 'Без названия',
      })) ?? []
    );
  }, [serviceSelect, services]);

  const onSubmitValue = () => {
    if (serviceSelect && priceItemSelect) {
      onExecute(getEmptyBookingOrderItemView(serviceSelect, priceItemSelect));
    }
  };

  return (
    <ConfirmDialog
      open={!!services}
      onClose={onClose}
      title={`Выбор услуги`}
      dialogButtons={
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              disabled={!(serviceSelect && priceItemSelect)}
              onClick={onSubmitValue}
            >
              Выбрать
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              fullWidth={false}
              onClick={onClose}
            >
              Закрыть
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        spacing={2}
        wrap='nowrap'
        direction='column'
      >
        <Grid item>
          <MPAutocompleteSingleSelect<BookingOfferServiceValid>
            value={serviceSelect}
            label={'Услуга'}
            options={options}
            onChangeValue={value => setServiceSelect(value)}
          />
        </Grid>
        <Grid item>
          <MPAutocompleteSingleSelect<BookingOfferServicePriceItemValid>
            value={priceItemSelect}
            label={'Тариф'}
            options={optionsPriceItems}
            disabled={!serviceSelect}
            onChangeValue={value => setPriceItemSelect(value)}
          />
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
};

export default BookingOrderAddItemDialog;
