import { EDateFormat, Nullable } from '@/domain';
import moment from 'moment/moment';
import { EReportSportPeriodType } from '@features/report/types';
import Api from '@/data/api';
import { AxiosResponse } from 'axios';

type WithPeriodProps = {
  readonly startDate?: Nullable<string>;
  readonly endDate?: Nullable<string>;
};

type ReportAllProps = WithPeriodProps & {
  readonly periodType?: EReportSportPeriodType;
};

type ReportUsersProps = WithPeriodProps & {
  readonly periodType?: EReportSportPeriodType;
};

type ReportEventsProps = WithPeriodProps;
type ReportEventsDetailedProps = WithPeriodProps;

export type ReportSportService = {
  readonly buildListQueryParams: (props: ReportAllProps) => ReportAllProps;
  readonly users: (props: ReportUsersProps) => Promise<AxiosResponse<string>>;
  readonly events: (props: ReportEventsProps) => Promise<AxiosResponse<string>>;
  readonly eventsDetailed: (props: ReportEventsDetailedProps) => Promise<AxiosResponse<string>>;
};

const service: ReportSportService = {
  buildListQueryParams: props => {
    const { startDate, endDate, periodType } = props;

    if (!periodType) {
      return {
        startDate: startDate && moment(startDate).format(EDateFormat.Server),
        endDate: endDate && moment(endDate).format(EDateFormat.Server),
      };
    }

    switch (periodType) {
      case EReportSportPeriodType.DateEvent:
        return {
          eventStartDate: startDate && moment(startDate).format(EDateFormat.Server),
          eventEndDate: endDate && moment(endDate).format(EDateFormat.Server),
        };
      case EReportSportPeriodType.DateUserRegistration:
        return {
          startDate: startDate && moment(startDate).format(EDateFormat.Server),
          endDate: endDate && moment(endDate).format(EDateFormat.Server),
        };
    }
  },
  users: async props => {
    const params = service.buildListQueryParams(props);

    return await Api.report.sport.users(params);
  },
  events: async props => {
    const params = service.buildListQueryParams(props);

    return await Api.report.sport.events(params);
  },
  eventsDetailed: async props => {
    const params = service.buildListQueryParams(props);

    return await Api.report.sport.eventsDetailed(params);
  },
};

export default service;
