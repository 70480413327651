import { IconButtonProps } from '@mui/material';
import { SettingsIcon } from '../../../../../icons';
import AppSvgIcon from '../../../images/icon';
import { Wrapper } from './controls';

interface DataTableCellSettingsProps extends IconButtonProps {}

const DataTableCellSettings = (props: DataTableCellSettingsProps) => {
  return props.onClick ? (
    <Wrapper
      size='small'
      {...props}
    >
      <AppSvgIcon
        fontSize='small'
        icon={SettingsIcon}
      />
    </Wrapper>
  ) : (
    <></>
  );
};

export default DataTableCellSettings;
