import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { UUID } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import { viewShowMainMenu } from '../../features/main/container/store/slice';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';
import UserMpDetailsContainer from '../../features/user/userMp/details/container';

const AdminMpDetailsScreen = () => {
  const dispatch = useDispatch();
  const { adminsMp } = useCurrentUser().accessMatrix;

  const { id } = useParams<{ id: UUID }>();

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (!adminsMp?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <UserMpDetailsContainer
      id={id}
      canEdit={adminsMp.edit}
      canChangeStatus={adminsMp.changeStatus}
    />
  );
};

export default AdminMpDetailsScreen;
