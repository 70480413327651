import React from 'react';
import { UseBookingOfferSearch } from './hooks/useBookingOfferSearch';
import { UseBookingOfferSearchConfig } from './hooks/useBookingOfferSearchConfig';

export type BookingOfferSearchContextValue = UseBookingOfferSearch;

export const BookingOfferSearchContext = React.createContext<BookingOfferSearchContextValue>(
  {} as BookingOfferSearchContextValue
);

export type BookingOfferSearchConfigContextValue = UseBookingOfferSearchConfig;

export const BookingOfferSearchConfigContext = React.createContext<BookingOfferSearchConfigContextValue>(
  {} as BookingOfferSearchConfigContextValue
);
