import { EPartnerDiscriminator } from '../../../../../domain/model/enums';
import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from './index';

const CorpOfferTableCellOgrn = (props: CorpOfferTableCellCommonProps) => {
  const {
    corpOffer: {
      partner: { regInfo },
    },
  } = props;
  const value = regInfo.discriminator === EPartnerDiscriminator.PartnerCompanyData ? regInfo.ogrn : regInfo.ogrnip;
  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellOgrn;
