import { RootState } from '@/data/store/store';
import { FormItem, FormItemProps } from '@components/forms/item';
import { Grid, GridSize } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';

interface FormItemAdapterProps extends FormItemProps {
  readonly disabledSelector?: (store: RootState) => boolean;
  readonly visibleSelector?: (store: RootState) => boolean;
  readonly gridSize?: GridSize;
}

export const FormItemAdapter: FC<FormItemAdapterProps> = ({
  children,
  disabledSelector = () => false,
  visibleSelector = () => true,
  disabled,
  gridSize = 12,
  ...props
}) => {
  const isDisabled = useSelector(disabledSelector);
  const isVisible = useSelector(visibleSelector);

  if (!isVisible) {
    return null;
  }

  return (
    <Grid
      item
      xs={gridSize}
    >
      <FormItem
        {...props}
        disabled={isDisabled || disabled}
      >
        {children}
      </FormItem>
    </Grid>
  );
};
