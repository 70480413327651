import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import Api from '../../../../../../data/api';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { ServerErrorResponse } from '../../../../../../data/network/types';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../../data/store/types';
import { EUserRole, EUserStatus } from '../../../../../../domain/model/enums';
import { NotificationOption, NotificationSetting } from '../../../../../../domain/model/notification';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import { MpUser } from '../../../../../../domain/model/user';

export const userMpDetailsByIdFetch = createAsyncThunk<
  { user: MpUser; notifications: NotificationOption[] },
  { id: UUID; roles: EUserRole[] },
  AppThunkAPIConfig
>('userMp/details/byId/fetch', async ({ id, roles }, { rejectWithValue, signal }) => {
  try {
    const responses = await Promise.allSettled([
      Api.user.mp.one({ id, signal }),
      Api.notification.byRoles({ signal, roles }),
    ]);

    if (responses[0].status === 'rejected') {
      return rejectWithValue('cancelled');
    }

    const user = (responses[0] as PromiseFulfilledResult<AxiosResponse<MpUser>>).value.data;
    const notifications = [];
    if (responses[1].status === 'fulfilled') {
      notifications.push(...responses[1].value.data);
    }

    return { user, notifications };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);

    return rejectWithValue(e.response.data);
  }
});

export const userMpDetailsChangeUserStatus = createAsyncThunk<
  MpUser,
  { id: UUID; status: EUserStatus },
  AppThunkAPIConfig<ServerErrorResponse>
>('userMp/details/changeStatus', async ({ id, status }, { signal, rejectWithValue }) => {
  try {
    await Api.user.mp.changeStatus({ id, status });
    const { data: userData } = await Api.user.mp.one({ id, signal });
    return userData;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const userMpDetailsNotificationsUpdate = createAsyncThunk<
  NotificationSetting,
  { id: UUID; option: NotificationOption; enabled: boolean },
  AppThunkAPIConfig
>('userMp/details/notifications/update', async ({ id, option, enabled }, { rejectWithValue }) => {
  try {
    const { data } = await Api.notification.update({ id, option, enabled });
    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface UserMpDetailsState {
  readonly needRefreshWatcher: number;
  readonly byId: Fetchable & {
    readonly user: Nullable<MpUser>;
    readonly notifications: Nullable<NotificationOption[]>;
    readonly loadedUser: Nullable<MpUser>;
  };
  readonly notificationsUpdate: Fetchable;
  readonly changeStatus: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<UserMpDetailsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<UserMpDetailsState> {
  userMpDetailsStateReset: Reducer;
}

const slice = createSlice<UserMpDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    needRefreshWatcher: 0,
    byId: {
      ...fetchableDefault,
      user: null,
      loadedUser: null,
      notifications: null,
    },
    notificationsUpdate: {
      ...fetchableDefault,
    },
    changeStatus: {
      ...fetchableDefault,
    },
  },
  reducers: {
    userMpDetailsStateReset: state => {
      state.needRefreshWatcher = 0;
      state.byId = {
        ...fetchableDefault,
        user: null,
        notifications: null,
        loadedUser: null,
      };
      state.notificationsUpdate = {
        ...fetchableDefault,
      };
      state.changeStatus = {
        ...fetchableDefault,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(userMpDetailsByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.user = null;
        state.byId.notifications = null;
      })
      .addCase(userMpDetailsByIdFetch.fulfilled, (state, { payload }) => {
        const { user, notifications } = payload;

        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.user = user;
        state.byId.loadedUser = user;
        state.byId.notifications = notifications;
      })
      .addCase(userMpDetailsByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        }

        state.byId.user = null;
        state.byId.notifications = null;
      })
      .addCase(userMpDetailsNotificationsUpdate.pending, (state, { meta }) => {
        const { option, enabled } = meta.arg;

        const existedNotificationSetting = state.byId.user!.notificationSettings.find(
          item => item.option.id === option.id
        );
        if (existedNotificationSetting) {
          existedNotificationSetting.enabled = enabled;
        } else {
          state.byId.user!.notificationSettings.push({
            option,
            enabled,
          });
        }
        state.notificationsUpdate = fetchableFetching;
      })
      .addCase(userMpDetailsNotificationsUpdate.fulfilled, state => {
        state.notificationsUpdate = fetchableFetched;
      })
      .addCase(userMpDetailsNotificationsUpdate.rejected, state => {
        state.notificationsUpdate = fetchableFailed;
        state.byId.user = state.byId.loadedUser;
      })
      .addCase(userMpDetailsChangeUserStatus.pending, state => {
        state.changeStatus.isFetching = true;
        state.changeStatus.isFetched = false;
        state.changeStatus.isFailed = false;
      })
      .addCase(userMpDetailsChangeUserStatus.fulfilled, (state, { payload }) => {
        state.changeStatus.isFetching = false;
        state.changeStatus.isFetched = true;
        state.changeStatus.isFailed = false;
        state.byId.user = payload;
      })
      .addCase(userMpDetailsChangeUserStatus.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.changeStatus.isFetching = false;
          state.changeStatus.isFetched = false;
          state.changeStatus.isFailed = true;
        }
      });
  },
});

export const { userMpDetailsStateReset } = slice.actions;

export default slice.reducer;
