import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { BookingOffer } from '../../../../../domain/model/booking';
import DangerouslyHtmlWrapper from '../../../../components/common/wrappers/dangerouslyHtml';
import { Field, FieldsGroup } from '../../../../components/fields';
import { AddressHelper } from '../../../../utils/address';

interface BookingOfferDetailsStepContactsProps {
  readonly bookingOffer: BookingOffer;
}

const BookingOfferDetailsStepContacts = (props: BookingOfferDetailsStepContactsProps) => {
  const { bookingOffer } = props;

  return (
    <Grid
      container
      spacing={4}
    >
      {/* Секция контактов*/}
      <Grid
        item
        container
        spacing={3}
        xs={12}
      >
        <Grid
          item
          xs={12}
        >
          <Typography variant={'h3'}>Контакты</Typography>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          xs={12}
        >
          {bookingOffer.contacts?.map((contact, index) => (
            <Grid
              item
              key={index}
              xs={12}
            >
              <FieldsGroup label={`${index + 1}. Контакт`}>
                <Field label={'Название контакта'}>{contact.name ?? '-'}</Field>
                <Field label={'Адрес'}>
                  {contact.address
                    ? new AddressHelper(contact.address).getFullPath() ?? contact.address.name
                    : '-'}
                </Field>
                <Field label={'Телефон'}>{contact.phone ?? '-'}</Field>
                <Field label={'Электронная почта'}>{contact.email ?? '-'}</Field>
              </FieldsGroup>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Секция Как добраться*/}
      <Grid
        item
        container
        spacing={3}
        xs={12}
      >
        <Grid
          item
          xs={12}
        >
          <Typography variant={'h3'}>Как добраться</Typography>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <FieldsGroup>
            <Field label={'Как добраться'}>
              {bookingOffer.routeDescription ? (
                <DangerouslyHtmlWrapper>
                  <Typography
                    variant='body1'
                    dangerouslySetInnerHTML={{ __html: bookingOffer.routeDescription }}
                  />
                </DangerouslyHtmlWrapper>
              ) : (
                '-'
              )}
            </Field>
          </FieldsGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BookingOfferDetailsStepContacts;
