import { Grid, GridProps } from '@mui/material';
import styled from '@emotion/styled';

interface WrapperProps extends GridProps {
  readonly count: 1 | 2 | 3 | 4;
}

export const Wrapper = styled(({ count: bannerCount, ...others }: WrapperProps) => <Grid {...others} />)`
  height: ${p => {
    switch (p.count) {
      case 1:
        return '5.5rem';
      case 2:
        return '4.625rem';
      case 3:
        return '4rem';
      case 4:
        return '3.375rem';
      default:
        return 0;
    }
  }};
  width: 18.5rem;

  column-gap: ${p => p.theme.spacing(1)};

  margin-left: 0;
`;

export const Banner = styled(Grid)`
  flex: 1;

  background-color: ${p => p.theme.palette.disabled.background};
  border: 0.5px solid ${p => p.theme.palette.text.primary};
  border-radius: ${p => (p.theme.shape.borderRadius as number) / 2}px;
`;

export const Line = styled(Grid)`
  margin-left: ${p => p.theme.spacing(1)};
`;
