import { AsyncThunkAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { SportEventShort } from '../../../../../domain/model/event';
import { EDateTimeFormat } from '../../../../../domain/model/formats';
import { UUID } from '../../../../../domain/model/types';
import { getEventViewRoute } from '../../entry';
import { eventListClearNeedRefreshWatcher, eventListFetch, eventListStartSession } from '../store/slice';

interface UseEventListHandlersProps {
  readonly guid: UUID;
  readonly userId?: UUID;
  readonly playgroundId?: UUID;
  readonly teamId?: UUID;
}

export interface UseEventList {
  readonly onEventClick: (event: SportEventShort) => void;
  readonly onEventsFetch: (refresh?: boolean) => ReturnType<AsyncThunkAction<any, any, any>>;
  readonly onEventsLoadMore: () => ReturnType<AsyncThunkAction<any, any, any>>;
}

const useEventListHandlers = (props: UseEventListHandlersProps): UseEventList => {
  const { guid, userId, playgroundId, teamId } = props;

  const timestamp = useMemo(() => moment().utc().format(EDateTimeFormat.Server), []);

  const dispatch = useDispatch();
  const history = useHistory();

  const onEventClick = useCallback(
    (event: SportEventShort) => {
      history.push(getEventViewRoute({ id: event.id }));
    },
    [history]
  );

  const onEventsFetch = useCallback(
    (refresh?: boolean) => {
      return dispatch(
        eventListFetch({
          userId,
          playgroundId,
          teamId,
          timestamp,
          refresh,
        })
      );
    },
    [userId, playgroundId, teamId, timestamp, useCallback]
  );

  const onEventsLoadMore = useCallback(() => {
    return onEventsFetch();
  }, [onEventsFetch]);

  // начало сеанса
  useEffect(() => {
    dispatch(eventListStartSession(guid));
  }, [dispatch, guid]);

  // очистка needRefreshWatcher когдла уходим с экрана, чтобы когда вернулись данные не фетчились
  useEffect(
    () => () => {
      dispatch(eventListClearNeedRefreshWatcher());
    },
    []
  );

  return {
    onEventClick,
    onEventsFetch,
    onEventsLoadMore,
  };
};

export default useEventListHandlers;
