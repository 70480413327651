import DataTableCellImage from '../../../../components/common/table/cell/image';
import { ProductTableCellImageWrapper } from './controls';
import { ProductTableCellCommonProps } from './index';

const ProductTableCellImages = (props: ProductTableCellCommonProps) => {
  const {
    productOffer: { images },
  } = props;

  const path = images && images.length > 0 ? images[0].path : null;

  if (path) {
    return (
      <ProductTableCellImageWrapper>
        <DataTableCellImage
          src={path}
          height='100%'
          width='100%'
        />
      </ProductTableCellImageWrapper>
    );
  } else return null;
};

export default ProductTableCellImages;
