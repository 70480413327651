import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import { OfferFieldHistoryTableCellActorTypeProps } from '../components/tableCell/fieldHistory/actorType';
import { offerHistoryTableFieldHistoryByIndexSelector } from '../store/selectors';
import { OfferFieldHistoryTableCellAdapterProps } from './fieldCell';

type OfferFieldHistoryTableCellActorTypeAdapterProps = Omit<OfferFieldHistoryTableCellAdapterProps, 'component'> & {
  readonly component: FC<OfferFieldHistoryTableCellActorTypeProps>;
};

const OfferFieldHistoryTableCellActorTypeAdapter = (props: OfferFieldHistoryTableCellActorTypeAdapterProps) => {
  const { index, component: Component } = props;

  const fieldHistory = useSelector(offerHistoryTableFieldHistoryByIndexSelector(index));

  const { privilegeActorTypes: actorTypes } = useSelector(nsiDataSelector);

  return useMemo(
    () =>
      fieldHistory ? (
        <Component
          fieldHistory={fieldHistory}
          actorTypes={actorTypes}
        />
      ) : null,
    [Component, fieldHistory, actorTypes]
  );
};

export default OfferFieldHistoryTableCellActorTypeAdapter;
