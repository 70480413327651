import { CommonLifeCycleInstance } from '../../../../utils/lifecycle';
import { ActionOfferType, EOfferActionType } from '../types';
import {
  commonAdminConfiguration,
  corpAdminConfiguration,
  corpPartnerConfiguration,
  tradePartnerConfiguration,
} from './configurations';
import {
  CorpOfferLifeCycle,
  OfferLifeCycle,
  OfferLifeCycleFactoryProps,
  OfferLifeCycleProps,
  TradeOfferLifeCycle,
} from './types';

/**
 * Создаем жизненный цикл офера.
 * Он содержит в себе статусные схемы управляющие экшенами офера на определенных статусах
 */
const OfferLifeCycleInstance = <T extends ActionOfferType>(props: OfferLifeCycleProps<T>): OfferLifeCycle<T> => {
  const { configuration, isPartnerUser, userId } = props;

  const commonLifecycle = CommonLifeCycleInstance(props);

  return {
    configuration,
    userId,
    isPartnerUser,
    build: ({ allowedActions, obj, initialObj }) => {
      const commonLifecycleBuild = commonLifecycle.build({ allowedActions, obj, initialObj });
      const { can } = commonLifecycleBuild;

      const edit = can(EOfferActionType.Edit);
      const editCodes = can(EOfferActionType.CreateCodes) || can(EOfferActionType.DeleteCodes);
      const changeCodeStatus =
        !!obj &&
        configuration.changeCodeStatusRules.length > 0 &&
        configuration.changeCodeStatusRules.every(rule =>
          rule.call({
            obj,
            userId,
            nextActions: [],
          })
        );

      return {
        ...commonLifecycleBuild,
        canEdit: edit,
        canEditCodes: editCodes,
        canChangeCodeStatus: changeCodeStatus,
      };
    },
  };
};

/**
 * фабрика создания жизненнjго цикла трейд офера
 */
export const TradeOfferLifeCycleFactory = () => {
  return {
    create: ({ isPartnerUser, userId, debug }: OfferLifeCycleFactoryProps): TradeOfferLifeCycle => {
      if (isPartnerUser) {
        return OfferLifeCycleInstance({
          userId,
          configuration: tradePartnerConfiguration,
          debug,
          isPartnerUser,
        });
      } else {
        return OfferLifeCycleInstance({
          userId,
          configuration: commonAdminConfiguration,
          debug,
          isPartnerUser,
        });
      }
    },
  };
};

/**
 * фабрика создания жизненного цикла корп офера
 */
export const CorpOfferLifeCycleFactory = () => {
  return {
    create: ({ isPartnerUser, userId, debug }: OfferLifeCycleFactoryProps): CorpOfferLifeCycle => {
      if (isPartnerUser) {
        return OfferLifeCycleInstance({
          userId,
          configuration: corpPartnerConfiguration,
          debug,
          isPartnerUser,
        });
      } else {
        return OfferLifeCycleInstance({
          userId,
          configuration: corpAdminConfiguration,
          debug,
          isPartnerUser,
        });
      }
    },
  };
};
