import { Nullable } from '../../../domain/model/types';
import { EPanelActionPosition } from '../../types';
import {
  EPartnerEmployeeActionType,
  GetPartnerEmployeeActionsByPermissions,
  PartnerEmployeeAction,
  PartnerEmployeeActions,
} from './types';

export enum EPartnerEmployeeStep {
  Profile = 'profile',
  Grants = 'grants',
}

export const getPartnerEmployeeActionName = (type: EPartnerEmployeeActionType): string => {
  switch (type) {
    case EPartnerEmployeeActionType.Save:
      return 'Сохранить';
    case EPartnerEmployeeActionType.Edit:
      return 'Редактировать';
    case EPartnerEmployeeActionType.Activate:
      return 'Включить сотрудника';
    case EPartnerEmployeeActionType.Deactivate:
      return 'Отключить сотрудника';
    case EPartnerEmployeeActionType.ChangeResponsible:
      return 'Назначить ответственным лицом';
  }
};

type EmployeeAction = PartnerEmployeeAction<EPartnerEmployeeActionType>;
type EmployeeActions = PartnerEmployeeActions<EPartnerEmployeeActionType>;

export const getPartnerEmployeeActionsConfigByPermissions = (
  props: GetPartnerEmployeeActionsByPermissions
): EmployeeActions => {
  const { canActivate, canDeactivate, canSave, canEdit, canChangeResponsible } = props;

  const actions: EmployeeActions = [];

  let onActivate: Nullable<EmployeeAction> = null;
  let onDeactivate: Nullable<EmployeeAction> = null;
  let onEdit: Nullable<EmployeeAction> = null;
  let onSave: Nullable<EmployeeAction> = null;
  let onChangeResponsible: Nullable<EmployeeAction> = null;

  if (canActivate) {
    onActivate = {
      type: EPartnerEmployeeActionType.Activate,
      disabled: false,
      label: getPartnerEmployeeActionName(EPartnerEmployeeActionType.Activate),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onActivate);
  }
  if (canDeactivate) {
    onDeactivate = {
      type: EPartnerEmployeeActionType.Deactivate,
      disabled: false,
      label: getPartnerEmployeeActionName(EPartnerEmployeeActionType.Deactivate),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onDeactivate);
  }
  if (canSave) {
    onSave = {
      type: EPartnerEmployeeActionType.Save,
      disabled: false,
      label: getPartnerEmployeeActionName(EPartnerEmployeeActionType.Save),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onSave);
  }
  if (canEdit) {
    onEdit = {
      type: EPartnerEmployeeActionType.Edit,
      disabled: false,
      label: getPartnerEmployeeActionName(EPartnerEmployeeActionType.Edit),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onEdit);
  }
  if (canChangeResponsible) {
    onChangeResponsible = {
      type: EPartnerEmployeeActionType.ChangeResponsible,
      disabled: false,
      label: getPartnerEmployeeActionName(EPartnerEmployeeActionType.ChangeResponsible),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onChangeResponsible);
  }

  return actions;
};
