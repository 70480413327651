import { combineReducers } from '@reduxjs/toolkit';
import activityType from './activityType/reducers';
import bookingOfferCategory from './bookingOfferCategory/reducers';
import bookingServiceCategory from './bookingServiceCategory/reducers';
import bookingServicePriceUnit from './bookingServicePriceUnit/reducers';
import corpOfferCategory from './corpOfferCategory/reducers';
import tradeOfferCategory from './tradeOfferCategory/reducers';

export default combineReducers({
  activityType,
  tradeOfferCategory,
  corpOfferCategory,
  bookingOfferCategory,
  bookingServiceCategory,
  bookingServicePriceUnit,
});
