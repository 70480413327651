import { getUserFullName } from '@/presentation/utils';
import CustomerTableCell, { CustomerTableCellCommonProps } from './index';

const CustomerTableCellName = (props: CustomerTableCellCommonProps) => {
  const { customer } = props;

  const value = getUserFullName({ user: customer });

  return (
    <CustomerTableCell
      {...props}
      value={value}
    />
  );
};

export default CustomerTableCellName;
