import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Partner } from '../../../../domain/model/partner';
import { MpUserData } from '../../../../domain/model/user';
import useValidation from '../../../hooks/validation/useValidation';
import { OnChangeObjectAttribute } from '../../../types';
import { getPartnerApplicationEditRoute } from '../../partnerApplication/entry';
import UserCreateDialog from '../../user/components/createDialog';
import PartnerInviteSuccessDialog from '../components/inviteSuccessDialog';
import PartnerRegistrationActions from './actions';
import {
  partnerRegistrationInviteSelector,
  partnerRegistrationIsFetchingSelector,
  partnerRegistrationUserSelector,
} from './store/selectors';
import {
  partnerRegistrationCreate,
  partnerRegistrationInit,
  partnerRegistrationInvite,
  partnerRegistrationReset,
  partnerRegistrationSetAttribute,
} from './store/slice';
import { partnerRegistrationValidation } from './utils';

const validationRules = partnerRegistrationValidation;

const PartnerRegistrationContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);

  const user = useSelector(partnerRegistrationUserSelector);
  const { data: newPartnerAdmin } = useSelector(partnerRegistrationInviteSelector);
  const isFetching = useSelector(partnerRegistrationIsFetchingSelector);

  const { validationResult, isValid, validate } = useValidation<MpUserData>({
    object: user,
    validateOnChange,
    rules: validationRules,
  });

  const onClose = () => {
    history.goBack();
  };

  const onEditPartnerApplication = (partner: Partner) => {
    history.replace(getPartnerApplicationEditRoute({ id: partner.id }));
  };

  const onRegister = () => {
    const isValidObject = validate();

    if (!isValidObject) {
      setValidateOnChange(true);
    } else {
      dispatch(partnerRegistrationInvite(user));
    }
  };

  const onRegisterAndEdit = () => {
    const isValidObject = validate();

    if (!isValidObject) {
      setValidateOnChange(true);
    } else {
      dispatch(partnerRegistrationCreate(user)).unwrap().then(onEditPartnerApplication);
    }
  };

  const onChangeAttribute: OnChangeObjectAttribute<MpUserData> = (name, value) => {
    dispatch(partnerRegistrationSetAttribute({ name, value }));
  };

  useEffect(() => {
    dispatch(partnerRegistrationInit());
    return () => {
      dispatch(partnerRegistrationReset());
    };
  }, [dispatch]);

  return (
    <>
      {!newPartnerAdmin && (
        <UserCreateDialog
          title='Добавление партнёра'
          user={user}
          validation={validationResult}
          validationRules={validationRules}
          isValid={isValid}
          canChangeEmail
          actions={
            <PartnerRegistrationActions
              isFetching={isFetching}
              onSave={onRegister}
              onSaveAndEdit={onRegisterAndEdit}
            />
          }
          onChangeAttribute={onChangeAttribute}
          onClose={onClose}
        />
      )}
      <PartnerInviteSuccessDialog
        partnerAdmin={newPartnerAdmin}
        onAction={onClose}
      />
    </>
  );
};

export default PartnerRegistrationContainer;
