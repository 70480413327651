import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isBookingOrdersAnyChangedSelector } from '../store/selectors';
import { bookingOrderActionsOptimize } from '../store/slice';

const OrdersActionsOptimizerAdapter = () => {
  const dispatch = useDispatch();
  const needOptimize = useSelector(isBookingOrdersAnyChangedSelector);

  useEffect(() => {
    if (needOptimize > 0) {
      dispatch(bookingOrderActionsOptimize());
    }
  }, [needOptimize]);

  return null;
};

export default OrdersActionsOptimizerAdapter;
