import styled from '@emotion/styled';
import { viewConfig } from '../../../../theme/viewConfig';

export const LoaderWrapper = styled.div`
  z-index: 3;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: ${viewConfig.stepper.width};
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;

  background: ${p => p.theme.palette.background.default};
`;

export const Wrapper = styled.div`
  height: 100%;

  display: flex;
  flex-direction: row;
`;

export const ColumnWrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  display: flex;
  flex-direction: column;
`;

export const PreviewWrapper = styled.div`
  position: fixed;
  right: ${p => p.theme.spacing(7.5)};

  margin-top: ${p => p.theme.spacing()};
`;

export const FooterWrapper = styled.div`
  border-top: 1px solid ${p => p.theme.palette.secondary.main};

  padding: ${p => p.theme.spacing(2)} ${p => p.theme.spacing(4)};
`;
