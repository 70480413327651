import { Content } from './controls';
import { FCC, useEffect, useRef, useState } from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

type ContentLimiterTooltipLayoutProps = {
  readonly rows?: number;
  readonly tooltipProps?: TooltipProps;
};

export const ContentLimiterTooltipLayout: FCC<ContentLimiterTooltipLayoutProps> = props => {
  const { children, rows = 1, tooltipProps } = props;
  const refContent = useRef<Nullable<HTMLDivElement>>(null);
  const [expandable, setExpandable] = useState(false);

  // Инициализируем высоту блока и проверяем нужно ли его сворачивать
  useEffect(() => {
    if (refContent.current !== null) {
      if (refContent.current.clientHeight < refContent.current.scrollHeight) {
        setExpandable(false);
      } else {
        setExpandable(true);
      }
    }
  }, [refContent, children]);

  return (
    <Tooltip
      title={children}
      placement={'right'}
      disableFocusListener={expandable}
      disableHoverListener={expandable}
      {...tooltipProps}
    >
      <Content
        rows={rows}
        ref={refContent}
        variant={'body2'}
      >
        {children}
      </Content>
    </Tooltip>
  );
};
