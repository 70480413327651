import { TradeOfferCategoriesEditDialogAddAdapter } from './dialogAdd';
import { TradeOfferCategoriesEditDialogDeleteAdapter } from './dialogDelete';
import { TradeOfferCategoriesEditDialogEditAdapter } from './dialogEdit';

export const TradeOfferCategoriesEditDialogsAdapter = () => {
  return (
    <>
      <TradeOfferCategoriesEditDialogAddAdapter />
      <TradeOfferCategoriesEditDialogEditAdapter />
      <TradeOfferCategoriesEditDialogDeleteAdapter />
    </>
  );
};
