import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import TablePagination from '../../../../components/common/table/pagination';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  sportsmansGetPageCount,
  sportsmansGetPageNumber,
  sportsmansGetSearch,
  sportsmansGetTotalCount,
} from '../store/selectors';

const SportsmanFooterAdapter = forwardRef((props, ref: any) => {
  const { onChangePageSize, onChangePage } = useContextHandlers();

  const search = useSelector(sportsmansGetSearch);
  const pageNumber = useSelector(sportsmansGetPageNumber);
  const totalCount = useSelector(sportsmansGetTotalCount);
  const pageCount = useSelector(sportsmansGetPageCount);

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='пользователей'
        onChangePageSize={onChangePageSize}
        onChangePage={onChangePage}
      />
    </div>
  );
});

export default SportsmanFooterAdapter;
