import moment from 'moment/moment';
import ClientOrgUserTableCell, { ClientOrgUserTableCellCommonProps } from './index';

const ClientOrgUserTableCellDate = (props: ClientOrgUserTableCellCommonProps) => {
  const {
    clientOrgUser: { createdAt },
  } = props;
  const value = createdAt ? moment(createdAt).format('DD.MM.YYYY') : '-';

  return (
    <ClientOrgUserTableCell
      {...props}
      value={value}
    />
  );
};

export default ClientOrgUserTableCellDate;
