import { ServerErrorResponse } from '@/data/network/types';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { CmsContainer, ECmsContainerStatus, EOfferType, EUserGender, SportOptionTyped } from '@/domain';
import { OfferTableTabsCounter } from '@features/general/offer/utils/table';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import cmsServices from '../../../services';
import { CmsContainerTableView } from '../../../types';
import { CmsContainerActionTableType, ECmsContainerActionType } from '../../types';
import { CmsContainerTableTabsCounter, ECmsContainerTableTab, getCmsContainerTableStatusesByTab } from '../utils';

const getActionProcess = (state: CmsContainerTableState, id: UUID, actionType: CmsContainerActionTableType) => {
  let process = state.actions.find(change => change.id === id);
  if (process) return process;

  process = {
    ...fetchableDefault,
    id,
    type: actionType,
    error: null,
  };
  state.actions.push(process);

  return process;
};

export interface CmsContainersFetchProps {
  readonly sitePageId: UUID;
  readonly search: {
    readonly statuses: ECmsContainerStatus[];
  };
  readonly offerTypes: SportOptionTyped<EOfferType>[];
  readonly genderTypes: SportOptionTyped<EUserGender>[];
}

export const cmsContainersFetch = createAsyncThunk<
  { tableViews: CmsContainerTableView[]; tabsCounter: OfferTableTabsCounter },
  CmsContainersFetchProps,
  AppThunkAPIConfig
>('cmsContainer/table/fetch', async ({ sitePageId, search, offerTypes, genderTypes }, { rejectWithValue, signal }) => {
  try {
    const { statuses } = search;

    const sitePage = await cmsServices.sitePage.byId({
      id: sitePageId,
      signal,
    });

    const tableViews: CmsContainerTableView[] = await Promise.all(
      sitePage.containers
        ?.filter(cmsContainer => statuses.includes(cmsContainer.status))
        ?.map((cmsContainer, index) =>
          cmsServices.container.getTableView({
            sortIndex: index + 1,
            cmsContainer,
            offerTypes,
            genderTypes,
            signal,
          })
        ) ?? []
    );

    const calculateCount = (tab: ECmsContainerTableTab) => {
      tabsCounter[tab] =
        sitePage.containers?.filter(cmsContainer =>
          getCmsContainerTableStatusesByTab(tab).includes(cmsContainer.status)
        ).length ?? 0;
    };

    const tabsCounter: CmsContainerTableTabsCounter = {};
    calculateCount(ECmsContainerTableTab.Active);
    calculateCount(ECmsContainerTableTab.Upcoming);
    calculateCount(ECmsContainerTableTab.Paused);
    calculateCount(ECmsContainerTableTab.Draft);
    calculateCount(ECmsContainerTableTab.Archived);

    return { tableViews, tabsCounter };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const cmsContainersPauseCmsContainer = createAsyncThunk<
  CmsContainer,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('cmsContainer/table/pause', async ({ id }, { rejectWithValue }) => {
  try {
    return await cmsServices.container.paused({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const cmsContainersArchiveCmsContainer = createAsyncThunk<
  CmsContainer,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('cmsContainer/table/archive', async ({ id }, { rejectWithValue }) => {
  try {
    return await cmsServices.container.archived({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const cmsContainersResumeCmsContainer = createAsyncThunk<
  CmsContainer,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('cmsContainer/table/resume', async ({ id }, { rejectWithValue }) => {
  try {
    return await cmsServices.container.resumed({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const cmsContainersDeleteCmsContainer = createAsyncThunk<
  void,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('cmsContainer/table/delete', async ({ id }, { rejectWithValue }) => {
  try {
    return await cmsServices.container.delete({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const cmsContainersChangeCmsContainerSortIndex = createAsyncThunk<
  void,
  { id: UUID; sortIndex: number },
  AppThunkAPIConfig<ServerErrorResponse>
>('cmsContainer/table/changeSortIndex', async ({ id, sortIndex }, { rejectWithValue }) => {
  try {
    return await cmsServices.container.changeSortIndex({ id, sortIndex });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const cmsContainersDuplicateCmsContainer = createAsyncThunk<
  CmsContainer,
  { sitePageId: UUID; id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('cmsContainer/table/duplicate', async ({ sitePageId, id }, { rejectWithValue }) => {
  try {
    return await cmsServices.container.duplicate({ sitePageId, id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

interface CmsContainerListSearchState {
  readonly statuses: ECmsContainerStatus[];
}

interface CmsContainerListActionsState extends Fetchable {
  id: UUID;
  type: CmsContainerActionTableType;
  error: Nullable<ServerErrorResponse>;
}

export interface CmsContainerTableState {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly tab: ECmsContainerTableTab;
  readonly cmsContainers: Fetchable & { data: CmsContainerTableView[] };
  readonly search: CmsContainerListSearchState;
  readonly actions: CmsContainerListActionsState[];
  readonly tabsCounter: CmsContainerTableTabsCounter;
  readonly dialogs: {};
  readonly containerToChangeSortIndex: Nullable<CmsContainerTableView>;
}

type Reducer<T = undefined> = CaseReducer<CmsContainerTableState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CmsContainerTableState> {
  cmsContainersStartSession: Reducer<{
    guid: UUID;
    statuses: ECmsContainerStatus[];
  }>;
  cmsContainersSortReset: Reducer;
  cmsContainersSetTab: Reducer<{ tab: ECmsContainerTableTab }>;
  cmsContainersSetSearch: Reducer<{ sort: string; statuses: ECmsContainerStatus[] }>;
  cmsContainersDataReset: Reducer;
  cmsContainersNeedRefreshWatcherReset: Reducer;
  cmsContainersSetDialogState: Reducer<{ name: keyof CmsContainerTableState['dialogs']; data: Nullable<CmsContainer> }>;
  cmsContainersSetContainerToChangeSortIndex: Reducer<{ cmsContainer: Nullable<CmsContainerTableView> }>;
}

const slice = createSlice<CmsContainerTableState, Reducers, 'table'>({
  name: 'table',
  initialState: {
    guid: null,
    needRefreshWatcher: 0,
    tab: ECmsContainerTableTab.Active,
    cmsContainers: {
      ...fetchableDefault,
      data: [],
    },
    search: {
      statuses: [],
    },
    actions: [],
    tabsCounter: {},
    dialogs: {},
    containerToChangeSortIndex: null,
  },
  reducers: {
    cmsContainersStartSession: (state, { payload }) => {
      const { guid, statuses } = payload;
      if (guid !== state.guid) {
        state.guid = guid;

        state.cmsContainers.isFetching = false;
        state.cmsContainers.isFetched = false;
        state.cmsContainers.isFailed = false;

        state.tab = ECmsContainerTableTab.Active;
        state.cmsContainers.data = [];

        state.needRefreshWatcher = 0;
        state.search = {
          statuses,
        };
        state.actions = [];
        state.tabsCounter = {};
        state.dialogs = {};
        state.containerToChangeSortIndex = null;
      }
    },
    cmsContainersSetTab: (state, { payload }) => {
      const { tab } = payload;
      // сбрасываем сортировку, если поменялись статусы (закладки)
      if (state.tab !== tab) {
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    cmsContainersSortReset: state => {
      state.needRefreshWatcher++;
    },
    cmsContainersSetSearch: (state, { payload }) => {
      const { statuses } = payload;
      state.search = {
        ...state.search,
        statuses,
      };
      state.needRefreshWatcher++;
    },
    cmsContainersDataReset: state => {
      state.cmsContainers.data = [];
      state.dialogs = {};
      state.containerToChangeSortIndex = null;
    },
    cmsContainersNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
    cmsContainersSetDialogState: (state, { payload }) => {
      /*
      пока не требуется 
      const { name, data } = payload;
      state.dialogs[name] = data;
      */
    },
    cmsContainersSetContainerToChangeSortIndex: (state, { payload }) => {
      const { cmsContainer } = payload;
      state.containerToChangeSortIndex = cmsContainer;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(cmsContainersFetch.pending, state => {
        state.cmsContainers.isFetching = true;
        state.cmsContainers.isFetched = false;
        state.cmsContainers.isFailed = false;
      })
      .addCase(cmsContainersFetch.fulfilled, (state, { payload }) => {
        const { tableViews, tabsCounter } = payload;
        state.cmsContainers.isFetching = false;
        state.cmsContainers.isFetched = true;
        state.cmsContainers.isFailed = false;

        state.cmsContainers.data = tableViews;
        state.tabsCounter = tabsCounter;
      })
      .addCase(cmsContainersFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.cmsContainers.isFetching = false;
          state.cmsContainers.isFetched = false;
          state.cmsContainers.isFailed = true;
          state.cmsContainers.data = [];
        }
      })

      .addCase(cmsContainersPauseCmsContainer.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECmsContainerActionType.Pause;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(cmsContainersPauseCmsContainer.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECmsContainerActionType.Pause;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(cmsContainersPauseCmsContainer.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = ECmsContainerActionType.Pause;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })

      .addCase(cmsContainersResumeCmsContainer.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECmsContainerActionType.Resume;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(cmsContainersResumeCmsContainer.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECmsContainerActionType.Resume;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(cmsContainersResumeCmsContainer.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = ECmsContainerActionType.Resume;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })

      .addCase(cmsContainersArchiveCmsContainer.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECmsContainerActionType.Archive;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(cmsContainersArchiveCmsContainer.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECmsContainerActionType.Archive;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(cmsContainersArchiveCmsContainer.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = ECmsContainerActionType.Archive;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(cmsContainersChangeCmsContainerSortIndex.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECmsContainerActionType.ChangeSortIndex;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(cmsContainersChangeCmsContainerSortIndex.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECmsContainerActionType.ChangeSortIndex;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(cmsContainersChangeCmsContainerSortIndex.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = ECmsContainerActionType.ChangeSortIndex;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(cmsContainersDeleteCmsContainer.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECmsContainerActionType.Delete;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(cmsContainersDeleteCmsContainer.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECmsContainerActionType.Delete;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(cmsContainersDeleteCmsContainer.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = ECmsContainerActionType.Delete;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(cmsContainersDuplicateCmsContainer.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECmsContainerActionType.Duplicate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(cmsContainersDuplicateCmsContainer.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = ECmsContainerActionType.Duplicate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(cmsContainersDuplicateCmsContainer.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = ECmsContainerActionType.Duplicate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      });
  },
});

export const {
  cmsContainersStartSession,
  cmsContainersSetTab,
  cmsContainersDataReset,
  cmsContainersNeedRefreshWatcherReset,
  cmsContainersSetContainerToChangeSortIndex,
} = slice.actions;

export default slice.reducer;
