import { Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ContentTarget } from '../../../../../../domain/model';
import { EMultiSelectorValueType } from '../../../../../../domain/model/enums';
import { RzhdRoad } from '../../../../../../domain/model/orgStructure';
import { Nullable } from '../../../../../../domain/model/types';
import OrgStructureRoadMultipleTypesSelector from '../../../../../components/orgStructure/road/selector/multipleTypes';
import {
  MultipleSelectorSelectAllType,
  OrgStructureRoadMultipleSelectType,
} from '../../../../../components/orgStructure/road/selector/multipleTypes/types';
import { ContentTargetEditProps } from '../../types';

const attribute: keyof ContentTarget = 'roads';

interface ContentTargetEditRoadsProps extends ContentTargetEditProps {
  readonly label?: string | false;
}

const ContentTargetEditRoads = (props: ContentTargetEditRoadsProps) => {
  const { target, label = 'Выберите железные дороги', topUsed, validation, onChangeAttribute } = props;

  const topUsedRoads = topUsed?.roads;

  const targetRoads = target[attribute] ?? { select: EMultiSelectorValueType.In, in: null };

  // значение roads может быть
  // 1) null
  // 2) { select: EMultiSelectorValueType.All, in: null }
  // 3) { select: EMultiSelectorValueType.In, in: newValue }, где newValue.length > 0
  const onChange = (newValue: Nullable<RzhdRoad[]>) => {
    if (newValue?.length) {
      onChangeAttribute(attribute, { select: EMultiSelectorValueType.In, in: newValue });
    } else {
      onChangeAttribute(attribute, null);
    }
  };

  const onSelectAll = () => {
    onChangeAttribute(attribute, { select: EMultiSelectorValueType.All, in: null });
  };

  return (
    <>
      {label && (
        <Typography
          variant='body2'
          gutterBottom
        >
          {label}
        </Typography>
      )}
      <OrgStructureRoadMultipleTypesSelector
        label='Железная дорога'
        color='primary'
        selectAllType={MultipleSelectorSelectAllType.Flag}
        select={targetRoads.select === EMultiSelectorValueType.All ? OrgStructureRoadMultipleSelectType.All : null}
        roads={targetRoads.in ?? []}
        defaultSource={topUsedRoads}
        limitTags={2}
        helperText={validation?.[attribute]?.message}
        error={!!validation?.[attribute]?.hasError}
        popupIcon={<ExpandMoreIcon fontSize='small' />}
        onChange={onChange}
        onSelect={onSelectAll}
      />
    </>
  );
};

export default ContentTargetEditRoads;
