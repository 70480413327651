import { Grid } from '@mui/material';
import { Team } from '../../../../../domain/model/team';
import { TeamAvatar } from '../../../../components/common/avatars';
import { AddressLocalityShortPathView } from '../../../address';
import { Bold } from './controls';

interface TeamDetailsHeaderProps {
  readonly team: Team;
}

const TeamDetailsHeader = ({ team }: TeamDetailsHeaderProps) => {
  return (
    <Grid
      container
      spacing={2}
      wrap='nowrap'
    >
      <Grid item>
        <TeamAvatar
          size='large'
          team={team}
        />
      </Grid>
      <Grid
        item
        xs
        container
        spacing={1}
        direction='column'
      >
        <Grid item>
          <Bold>{team.name}</Bold>
        </Grid>
        {team.locality && (
          <Grid item>
            <AddressLocalityShortPathView
              variant='body2'
              address={team.locality}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default TeamDetailsHeader;
