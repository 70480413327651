import { EOrderItemStatus, Nullable, ProductOrderItem } from '@/domain';
import { EPanelActionPosition, PanelActions } from '../../../types';
import { EProductOrderEditActionType } from '../types';
import { DataTableColumns } from '@components/common/table';

export enum EProductOrderItemsTableColumn {
  PartnerSKU = 'partnerSKU',
  Category = 'category',
  Image = 'image',
  Name = 'name',
  Qty = 'qty',
  Attributes = 'attributes',
  Price = 'price',
  OriginalPrice = 'productOriginalCost',
  Cost = 'cost',
  Status = 'status',
}

export const getProductOrderEditActions = (): PanelActions<EProductOrderEditActionType> => {
  return [
    {
      label: 'Сохранить и закрыть',
      type: EProductOrderEditActionType.Save,
      disabled: false,
      primary: true,
      position: [EPanelActionPosition.Default],
    },
  ];
};

export const validateProductOrder = (orderItems: Nullable<ProductOrderItem[]>): boolean => {
  if (!orderItems) {
    return false;
  }

  return orderItems.every(item => item.status != EOrderItemStatus.New);
};

export const getProductOrderItemsColumns = (): DataTableColumns<EProductOrderItemsTableColumn> => ({
  [EProductOrderItemsTableColumn.PartnerSKU]: {
    width: '7rem',
    title: 'SKU партнера',
    wrap: false,
  },
  [EProductOrderItemsTableColumn.Category]: {
    width: '7rem',
    title: 'Категория',
  },
  [EProductOrderItemsTableColumn.Image]: {
    width: '10rem',
    title: 'Изображение',
    wrap: false,
  },
  [EProductOrderItemsTableColumn.Name]: {
    width: '15rem',
    title: 'Наименование товара',
  },
  [EProductOrderItemsTableColumn.Attributes]: {
    title: 'Вариант товара',
    width: '8rem',
  },
  [EProductOrderItemsTableColumn.Qty]: {
    title: 'Количество',
    width: '5.875rem',
  },
  [EProductOrderItemsTableColumn.Price]: {
    title: 'Цена',
  },
  [EProductOrderItemsTableColumn.OriginalPrice]: {
    title: 'Цена до скидки',
  },
  [EProductOrderItemsTableColumn.Cost]: {
    title: 'Сумма',
    wrap: false,
    width: '5.875rem',
  },
  [EProductOrderItemsTableColumn.Status]: {
    title: 'Статус',
  },
});
