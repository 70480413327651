import { Grid, Typography } from '@mui/material';
import { FCC } from 'react';
import { MPButton } from '../../../../../theme/ui-kit/button';
import ConfirmDialog from '../../../dialogs/confirm';

interface DictionaryDeleteDialogProps {
  readonly title?: string;
  readonly onClose: () => void;
  readonly onConfirm: () => void;
}

export const DictionaryServiceChangeDialog: FCC<DictionaryDeleteDialogProps> = props => {
  const { children, title = 'Изменение справочника', onClose, onConfirm } = props;

  return (
    <ConfirmDialog
      open
      title={title}
      dialogButtons={
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <MPButton onClick={onConfirm}>Да, изменить</MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              onClick={onClose}
            >
              Отмена
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Typography
        component={'div'}
        variant={'body1'}
      >
        {children}
      </Typography>
    </ConfirmDialog>
  );
};
