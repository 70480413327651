import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ProductOrder } from '../../../../../domain/model/order';
import { PanelActions } from '../../../../types';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { useProductOrderActionsSelectors } from '../../actions/useAction';
import { ProductOrderLifeCycle } from '../../lifecycle/types';
import { EProductOrderActionType, EProductOrderTableTab, ProductOrderActionTableType } from '../../types';
import { getProductOrderTableActions } from '../../utils/actions';
import ProductOrderTable from '../component';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { useProductOrderTableMetadata } from '../hooks/useMetadata';
import {
  productOrdersOrdersSelector,
  productOrdersSortColumnSelector,
  productOrdersSortDirectionSelector,
} from '../store/selectors';

type ProductOrdersTableTableAdapterProps = {
  readonly tab: EProductOrderTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly lifecycle: ProductOrderLifeCycle;
  readonly tabActions: PanelActions<ProductOrderActionTableType>;
  readonly onOrderClick: (order: ProductOrder) => void;
};

const ProductOrdersTableTableAdapter = (props: ProductOrdersTableTableAdapterProps) => {
  const { tab, partnerId, lifecycle, tabActions, onOrderClick } = props;

  const {
    accessMatrix: { isPartnerUser },
  } = useCurrentUser();
  const handlers = useContextHandlers();

  const sortColumn = useSelector(productOrdersSortColumnSelector);
  const sortDirection = useSelector(productOrdersSortDirectionSelector);
  const orders = useSelector(productOrdersOrdersSelector);

  const { isProductOrdersAnyChangedSelector } = useProductOrderActionsSelectors();
  const needRefresh = useSelector(isProductOrdersAnyChangedSelector);

  const { metadata, onChangeMetadata } = useProductOrderTableMetadata({
    tab,
    partnerId,
    isPartnerUser,
    sortColumn,
    onSortReset: handlers.onResetSort,
  });

  const onExecuteAction = (action: ProductOrderActionTableType, order: ProductOrder) => {
    switch (action) {
      case EProductOrderActionType.Give:
        handlers.onOrderGive(order);
        break;
      case EProductOrderActionType.Renew:
        handlers.onOrderRenew(order);
        break;
      case EProductOrderActionType.Cancel:
        handlers.onOrderTryCancel(order);
        break;
      case EProductOrderActionType.Confirm:
        handlers.onOrderConfirm(order);
        break;
      case EProductOrderActionType.Send:
        handlers.onOrderSend(order);
        break;
      case EProductOrderActionType.Pay:
        handlers.onOrderPay(order);
        break;
      case EProductOrderActionType.PartiallyReturn:
        handlers.onOrderPartiallyReturn(order);
        break;
      case EProductOrderActionType.Return:
        handlers.onOrderReturn(order);
        break;
    }
  };

  useEffect(() => {
    if (needRefresh > 0) {
      handlers.onRefresh();
    }
  }, [needRefresh, handlers.onRefresh]);

  return (
    <ProductOrderTable
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      orders={orders}
      getActions={order => getProductOrderTableActions(lifecycle, order, tabActions)}
      onRequestSort={handlers.onChangeSort}
      onOrderClick={onOrderClick}
      onActionClick={onExecuteAction}
      onChangeMetadata={onChangeMetadata}
    />
  );
};

export default ProductOrdersTableTableAdapter;
