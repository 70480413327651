import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataFilterComponent from '../../../../components/common/dataFilter';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { EBookingOfferTableFilterItem } from '../../filterUtils';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  bookingOffersFilterSelector,
  bookingOffersGuidSelector,
  bookingOffersSortColumnSelector,
} from '../store/selectors';
import { EBookingOfferTableColumn } from '../../types';

interface BookingOffersTableFilterAdapterProps {
  readonly filterAdapter: DataFilterAdapter<EBookingOfferTableColumn, EBookingOfferTableFilterItem>;
}

const BookingOffersTableFilterAdapter = (props: BookingOffersTableFilterAdapterProps) => {
  const { filterAdapter } = props;

  const handlers = useContextHandlers();

  const guid = useSelector(bookingOffersGuidSelector);
  const sortColumn = useSelector(bookingOffersSortColumnSelector);
  const filter = useSelector(bookingOffersFilterSelector);

  const { filterStrategies } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return useMemo(
    () => (
      <DataFilterComponent
        strategies={filterStrategies}
        onChange={handlers.onChangeFilter}
      />
    ),
    [filterStrategies, handlers.onChangeFilter]
  );
};

export default BookingOffersTableFilterAdapter;
