import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../../data/api';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../../data/store/types';
import { SportEnumOption } from '../../../../../../domain/model';
import { ESportEventType } from '../../../../../../domain/model/enums';
import { FriendlyMatch, NewSportEvent, NewSportEventGeneralAttributes } from '../../../../../../domain/model/event';
import { Nullable, UUID } from '../../../../../../domain/model/types';

export const friendlyMatchCreateFetch = createAsyncThunk<
  Nullable<FriendlyMatch>,
  { id: Nullable<UUID> },
  AppThunkAPIConfig
>('friendlyMatch/create/fetch', async ({ id }, { rejectWithValue }) => {
  try {
    if (!id) {
      return null;
    }

    return (await Api.event.friendlyMatch.one({ id })).data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const friendlyMatchCreateSave = createAsyncThunk<
  { data: FriendlyMatch; draft: boolean },
  { object: FriendlyMatch; draft: boolean },
  AppThunkAPIConfig
>('friendlyMatch/create/save', async ({ object, draft }, { rejectWithValue }) => {
  try {
    const { data } = await Api.event.friendlyMatch.create({ ...object, draft });

    return { data, draft };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

const defaultEventType: SportEnumOption<ESportEventType> = { code: ESportEventType.FriendlyMatch, name: null };

export interface FriendlyMatchCreateState {
  guid: Nullable<UUID>;
  edit: Fetchable & {
    data: NewSportEvent;
    validationErrors: {
      [field: string]: any;
    };
    modified: boolean;
  };
  save: Fetchable & {
    draft: boolean;
  };
}

export type NewFriendlyMatchAttributes = Omit<NewSportEvent, keyof NewSportEventGeneralAttributes>;

interface Reducers extends SliceCaseReducers<FriendlyMatchCreateState> {
  friendlyMatchCreateStartSession: CaseReducer<FriendlyMatchCreateState, PayloadAction<{ guid: UUID }>>;
  friendlyMatchCreateSetGeneralAttributes: CaseReducer<
    FriendlyMatchCreateState,
    PayloadAction<{ generalAttributes: NewSportEventGeneralAttributes }>
  >;
  friendlyMatchCreateSetAttribute: CaseReducer<
    FriendlyMatchCreateState,
    PayloadAction<{ name: keyof NewFriendlyMatchAttributes; value: any }>
  >;
  friendlyMatchCreateSaveReset: CaseReducer<FriendlyMatchCreateState, PayloadAction<undefined>>;
  friendlyMatchCreateSetValidationErrors: CaseReducer<
    FriendlyMatchCreateState,
    PayloadAction<{ errors: { [field: string]: any } }>
  >;
}

const slice = createSlice<FriendlyMatchCreateState, Reducers, 'friendlyMatch/create'>({
  name: 'friendlyMatch/create',
  initialState: {
    guid: null,
    edit: {
      ...fetchableDefault,
      data: {
        type: defaultEventType,
        name: '',
        description: '',
        createdDate: null,
        road: null,
        scheduledDate: null,
        endDate: null,
        playground: null,
        previewImage: null,
        appearCheckingHours: null,
        status: null,
        candidates: [],
        organizerTeam: null,
        activityTypes: null,
        userType: null,
      },
      validationErrors: {},
      modified: false,
    },
    save: {
      ...fetchableDefault,
      draft: false,
    },
  },
  reducers: {
    friendlyMatchCreateStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (state.guid !== guid) {
        state.edit.data = {
          type: defaultEventType,
          name: '',
          description: '',
          createdDate: null,
          road: null,
          scheduledDate: '',
          endDate: null,
          playground: null,
          previewImage: null,
          appearCheckingHours: null,
          candidates: [],
          organizerTeam: null,
          status: null,
          activityTypes: null,
          userType: null,
        };
        state.edit.validationErrors = {};
        state.edit.modified = false;
        state.edit.isFetched = false;
      }

      state.guid = guid;
    },
    friendlyMatchCreateSetGeneralAttributes: (state, { payload }) => {
      const { generalAttributes } = payload;

      state.edit.data = { ...state.edit.data, ...generalAttributes };
      state.edit.modified = true;
    },
    friendlyMatchCreateSetAttribute: (state, { payload }) => {
      const { name, value } = payload;

      (state.edit.data[name] as keyof NewSportEvent) = value;
      state.edit.modified = true;
    },
    friendlyMatchCreateSaveReset: state => {
      state.save.isFetching = false;
      state.save.isFetched = false;
      state.save.isFailed = false;
    },
    friendlyMatchCreateSetValidationErrors: (state, { payload }) => {
      const { errors } = payload;
      state.edit.validationErrors = errors;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(friendlyMatchCreateFetch.pending, state => {
        state.edit.isFetching = true;
        state.edit.isFetched = false;
        state.edit.isFailed = false;
      })
      .addCase(friendlyMatchCreateFetch.fulfilled, (state, { payload }) => {
        state.edit.isFetching = false;
        state.edit.isFetched = true;
        state.edit.isFailed = false;
        state.edit.data = payload as NewSportEvent;
      })
      .addCase(friendlyMatchCreateFetch.rejected, state => {
        state.edit.isFetching = false;
        state.edit.isFetched = false;
        state.edit.isFailed = true;
      })
      .addCase(friendlyMatchCreateSave.pending, state => {
        state.save.isFetching = true;
        state.save.isFetched = false;
        state.save.isFailed = false;
      })
      .addCase(friendlyMatchCreateSave.fulfilled, (state, { payload }) => {
        const { data, draft } = payload;

        state.save.isFetching = false;
        state.save.isFetched = true;
        state.save.isFailed = false;
        state.save.draft = draft;

        state.edit.data = data;
        state.edit.modified = false;
        state.edit.validationErrors = {};
      })
      .addCase(friendlyMatchCreateSave.rejected, state => {
        state.save.isFetching = false;
        state.save.isFetched = false;
        state.save.isFailed = true;
      });
  },
});

export const {
  friendlyMatchCreateStartSession,
  friendlyMatchCreateSetGeneralAttributes,
  friendlyMatchCreateSetAttribute,
  friendlyMatchCreateSaveReset,
  friendlyMatchCreateSetValidationErrors,
} = slice.actions;

export default slice.reducer;
