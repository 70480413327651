export enum MPStepperLabelStrategy {
  Default,
  FromZero,
}

export enum EMPStepperTransitionMethod {
  None = 'none',
  Free = 'free',
  Neighbour = 'neighbour',
}

export interface MPStepperStep<T extends string | number> {
  readonly key: T;
  readonly label: string;
}
