import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { EOrderPartition, EOrderStatus } from '../../../domain/model/enums';
import { BookingOrder } from '../../../domain/model/order';
import { Nullable } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import BookingOrdersActionsProvider from '../../features/bookingOrder/actions/provider';
import BookingOrderTableEntry from '../../features/bookingOrder/table/entry';
import { EBookingOrderTableTab } from '../../features/bookingOrder/types';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { viewShowMainMenu } from '../../features/main/container/store/slice';
import {
  EOrderUrlParam,
  getOrderDetailsRoute,
  getOrderEditRoute,
  OrdersLocationState,
} from '../../features/order/entry';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const BookingOrdersScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation<Nullable<OrdersLocationState>>();
  const history = useHistory();
  const {
    accessMatrix: { orders, isPartnerUser },
    mpPartnerUserProfile,
  } = useCurrentUser();

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(EOrderUrlParam.Tab) as EBookingOrderTableTab;

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (!orders[EOrderPartition.BookingOffers].view) {
    return <ForbiddenComponent />;
  }

  const onOrderClick = (order: BookingOrder) => {
    if (
      [EOrderStatus.New, EOrderStatus.Confirmed].includes(order.status) &&
      orders[EOrderPartition.BookingOffers].edit
    ) {
      const editRoute = getOrderEditRoute({ id: order.id });
      history.push(editRoute);
    } else {
      history.push(
        getOrderDetailsRoute({
          id: order.id,
        })
      );
    }
  };

  return (
    <LocationWithRequiredStateContainer<OrdersLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <BookingOrdersActionsProvider>
          <BookingOrderTableEntry
            guid={state.guid}
            tab={tab}
            partnerId={isPartnerUser ? mpPartnerUserProfile.partner.id : null}
            onOrderClick={onOrderClick}
          />
        </BookingOrdersActionsProvider>
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default BookingOrdersScreen;
