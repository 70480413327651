import { Typography } from '@mui/material';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  max-width: 23.75rem;
`;

export const DictionaryTitle = styled(Typography)`
  font-weight: 600;
`;
