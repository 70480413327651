import offer, { BookingOfferService } from './offer';
import service, { BookingOfferServiceService } from './service';

type BookingServices = {
  readonly offer: BookingOfferService;
  readonly service: BookingOfferServiceService;
};

const bookingServices: BookingServices = {
  offer,
  service,
};

export default bookingServices;
