import { useSelector } from 'react-redux';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { ECorpOfferTableFilterItem } from '../../filterUtils';
import { ECorpOfferTableColumn } from '../../types';
import CorpOfferTable from '../component';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  corpOffersSearchCorpOffersSelector,
  corpOffersSearchFilterSelector,
  corpOffersSearchGuidSelector,
  corpOffersSearchSelectedSelector,
  corpOffersSearchSortColumnSelector,
  corpOffersSearchSortDirectionSelector,
} from '../store/selectors';

interface CorpOffersSearchTableAdapterProps {
  readonly filterAdapter: DataFilterAdapter<ECorpOfferTableColumn, ECorpOfferTableFilterItem>;
}

const CorpOffersSearchTableAdapter = (props: CorpOffersSearchTableAdapterProps) => {
  const { filterAdapter } = props;

  const handlers = useContextHandlers();
  const { isSingleSelect, onCorpOfferClick } = useContextConfig();

  const guid = useSelector(corpOffersSearchGuidSelector);
  const corpOffers = useSelector(corpOffersSearchCorpOffersSelector);
  const sortColumn = useSelector(corpOffersSearchSortColumnSelector);
  const sortDirection = useSelector(corpOffersSearchSortDirectionSelector);
  const filter = useSelector(corpOffersSearchFilterSelector);
  const selectedCorpOffers = useSelector(corpOffersSearchSelectedSelector);
  const selectedCorpOffersCount = selectedCorpOffers?.length ?? 0;

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return (
    <CorpOfferTable
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      corpOffers={corpOffers}
      selectedCorpOffers={selectedCorpOffers}
      isSingleSelect={isSingleSelect}
      onCorpOfferSelect={(corpOffer, selected) =>
        handlers.onCorpOfferSelect(corpOffer, selectedCorpOffersCount, selected)
      }
      onAllCorpOffersSelect={handlers.onAllCorpOffersSelect}
      onRequestSort={handlers.onChangeSort}
      onCorpOfferClick={onCorpOfferClick}
      onChangeMetadata={onChangeMetadata}
    />
  );
};

export default CorpOffersSearchTableAdapter;
