import { ApiCancellable, ApiQueryDsl, ApiRequestListDiscriminator, ApiRequestPageable } from '@/data/api/types';
import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '@/data/api/utils';
import { Pageable } from '@/domain/model';
import { TradeOfferActivation } from '@/domain/model/activation';
import { EOfferActivationDiscriminator } from '@/domain/model/enums';
import { DataFilterQueryDslOperator } from '@/domain/model/filter';
import { Nullable, UUID } from '@/domain/model/types';
import Api from '../../../../data/api';
import { PaginationSize } from '../../../types';
import { ETradeActivationsFilterItem, TradeActivationsFilterValues } from '../utils/filter';

const activationDiscriminator = EOfferActivationDiscriminator.TradeOffer;

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: string;
    readonly partnerId: Nullable<UUID>;
    readonly customerId: Nullable<UUID>;
  };
  readonly filter: TradeActivationsFilterValues;
  readonly pageNumber: number;
};

type BuildListQueryParamsReturn<D extends ApiRequestListDiscriminator> = ApiCancellable &
  ApiRequestPageable & {
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

export type CountProps = Omit<AllProps, 'pageNumber'>;

export type TradeActivationActivationService = {
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps
  ) => BuildListQueryParamsReturn<D>;
  readonly all: (props: AllProps) => Promise<Pageable<TradeOfferActivation>>;
  readonly count: (props: CountProps) => Promise<number>;
};

const service: TradeActivationActivationService = {
  buildListQueryParams: props => {
    const { search, filter, pageNumber, signal } = props;
    const { pageSize, sort, partnerId, customerId } = search;

    const querydsl: ApiQueryDsl = [];
    const query = filter[ETradeActivationsFilterItem.Query]?.value;
    const filterDsl = getQueryDslByDataFilterValues(filter);

    if (filterDsl) {
      querydsl.push(...filterDsl);
    }

    if (partnerId) {
      querydsl.push({
        value: [partnerId],
        field: 'offer.partner.id',
        operator: DataFilterQueryDslOperator.In,
      });
    }

    if (customerId) {
      querydsl.push({
        value: [customerId],
        field: 'customer.id',
        operator: DataFilterQueryDslOperator.In,
      });
    }

    return {
      query,
      sort,
      signal,
      pageSize,
      page: pageNumber,
      querydsl,
    };
  },
  all: async props => {
    const response = await Api.activation.all({ ...service.buildListQueryParams(props), activationDiscriminator });
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);
    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
  count: async props => {
    const { data: response } = await Api.activation.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      activationDiscriminator,
      discriminator: ApiRequestListDiscriminator.Count,
    });

    return response[0].count;
  },
};

export default service;
