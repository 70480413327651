import { UseAnalyticsQueriesTableConfig } from '@features/analytics/query/table/hooks/useTableConfig';
import React from 'react';
import { UseAnalyticsQueriesTable } from './hooks/useTable';

export type AnalyticsQueriesTableContextValue = UseAnalyticsQueriesTable;

export const AnalyticsQueriesTableContext = React.createContext<AnalyticsQueriesTableContextValue>(
  {} as AnalyticsQueriesTableContextValue
);

export type AnalyticsQueriesTableConfigContextValue = UseAnalyticsQueriesTableConfig;
export const AnalyticsQueriesTableConfigContext = React.createContext<AnalyticsQueriesTableConfigContextValue>(
  {} as AnalyticsQueriesTableConfigContextValue
);
