import { combineReducers } from '@reduxjs/toolkit';
import actions, { ClientOrgActionsState } from './actions/store/slice';
import create, { ClientOrgCreateState } from './create/store/slice';
import details, { ClientOrgDetailsState } from './details/store/slice';
import management, { ClientOrgManagementState } from './management/reducers';
import list, { ClientOrgListState } from './table/store/slice';

interface ClientOrgState {
  actions: ClientOrgActionsState;
  list: ClientOrgListState;
  create: ClientOrgCreateState;
  details: ClientOrgDetailsState;
  management: ClientOrgManagementState;
}

export default combineReducers<ClientOrgState>({
  actions,
  list,
  create,
  details,
  management,
});
