import { useSelector } from 'react-redux';
import { SidePanelContentWrapper } from '../../../../components/common/wrappers/sidePanel';
import OfferInfoPanelItem from '../../../general/offer/components/infoPanel';
import useOfferDetailsInfoPanels from '../../../general/offer/hooks/useDetailsInfoPanels';
import { bookingOfferDetailsBookingOfferSelector, bookingOfferDetailsPartnerSelector } from '../store/selectors';
import { BookingOfferDetailsPreviewAdapter } from './preview';

export const BookingOfferDetailsSidePanelsAdapter = () => {
  const bookingOffer = useSelector(bookingOfferDetailsBookingOfferSelector);
  const partner = useSelector(bookingOfferDetailsPartnerSelector);

  const infoPanels = useOfferDetailsInfoPanels({
    partner,
    offer: bookingOffer,
  });

  return (
    <SidePanelContentWrapper>
      {infoPanels?.map((infoPanel, index) => (
        <OfferInfoPanelItem
          key={index}
          infoPanel={infoPanel}
        />
      ))}
      <BookingOfferDetailsPreviewAdapter />
    </SidePanelContentWrapper>
  );
};
