import { MasterDetailsLayout } from '../../../../layouts/masterDetails/container';
import { CommonPreloaderDecorator } from '../../../general/decorators/preloader';
import { CommonUpdaterDecorator } from '../../../general/decorators/updater';
import MasterDetailsContainer from '../../../general/master/details/container';
import {
  BookingOfferCategoriesEditDialogsAdapter,
  BookingOfferCategoriesEditDictionaryAdapter,
  BookingOfferCategoriesEditHeaderAdapter,
  BookingOfferCategoriesEditStepperAdapter,
} from './adapters';
import { BookingOfferCategoriesEditInitializer } from './initializer';
import { BookingOfferCategoriesEditHandlersProvider } from './providers';
import {
  bookingOfferCategoriesEditIsLoadingSelector,
  bookingOfferCategoriesEditIsPreloadingSelector,
} from './store/selectors';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import ForbiddenComponent from '../../../../components/common/forbidden';
import { BookingOfferCategoriesEditAccess } from './types';

const BookingOfferCategoriesEditContainer = () => {
  const { bookingOfferCategories } = useCurrentUser().accessMatrix;
  const access: BookingOfferCategoriesEditAccess = {
    canCreate: bookingOfferCategories.create,
    canEdit: bookingOfferCategories.edit,
    canDelete: bookingOfferCategories.delete,
  };

  if (!bookingOfferCategories.view) {
    return <ForbiddenComponent />;
  }

  return (
    <MasterDetailsContainer
      layout={MasterDetailsLayout}
      layoutProps={{
        stepper: BookingOfferCategoriesEditStepperAdapter,
        header: BookingOfferCategoriesEditHeaderAdapter,
        decorators: [
          <CommonPreloaderDecorator
            key='preloader'
            isFetchingSelector={bookingOfferCategoriesEditIsPreloadingSelector}
          />,
          <CommonUpdaterDecorator
            key='updater'
            isFetchingSelector={bookingOfferCategoriesEditIsLoadingSelector}
          />,
        ],
      }}
      initializer={BookingOfferCategoriesEditInitializer}
      providers={[
        {
          name: 'handlers',
          factory: BookingOfferCategoriesEditHandlersProvider,
        },
      ]}
      commonProps={{
        access,
      }}
    >
      <BookingOfferCategoriesEditDictionaryAdapter access={access} />

      <BookingOfferCategoriesEditDialogsAdapter />
    </MasterDetailsContainer>
  );
};

export default BookingOfferCategoriesEditContainer;
