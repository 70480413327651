import React from 'react';
import { UsePartnersTable } from './hooks/usePartnersTable';
import { UsePartnersTableConfig } from './hooks/usePartnersTableConfig';

export type PartnersTableContextValue = UsePartnersTable;

export const PartnersTableContext = React.createContext<PartnersTableContextValue>({} as PartnersTableContextValue);

export type PartnersTableConfigContextValue = UsePartnersTableConfig;

export const PartnersTableConfigContext = React.createContext<PartnersTableConfigContextValue>(
  {} as PartnersTableConfigContextValue
);
