import { FormControlLabel } from '@mui/material';
import styled from '@emotion/styled';

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;

  .MuiCheckbox-root {
    padding: 0;
    margin-right: ${p => p.theme.spacing()};
  }
`;
