import React from 'react';
import { OfferCategory } from '../../../../../domain/model';
import { Nullable } from '../../../../../domain/model/types';
import { TradeOfferCategoriesEditState } from './store/slice';

export type TradeOfferCategoriesEditHandlersContextValue = {
  readonly onChangeDialogState: <Name extends keyof TradeOfferCategoriesEditState['dialogs']>(
    name: Name,
    data: Nullable<TradeOfferCategoriesEditState['dialogs'][Name]>
  ) => void;
  readonly onCreate: (tradeOfferCategory: OfferCategory) => Promise<OfferCategory>;
  readonly onEdit: (tradeOfferCategory: OfferCategory) => Promise<OfferCategory>;
  readonly onDelete: (tradeOfferCategory: OfferCategory) => Promise<void>;
  readonly onGetRelationShipsCount: (tradeOfferCategory: OfferCategory) => Promise<number>;
  readonly onClose: () => void;
};

export const TradeOfferCategoriesEditHandlersContext =
  React.createContext<TradeOfferCategoriesEditHandlersContextValue>({} as TradeOfferCategoriesEditHandlersContextValue);
