import {
  ApiCancellable,
  ApiQueryDsl,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
  ApiResponseMaBeCounters,
} from '@/data/api/types';
import { getComServicesEndpoint } from '@/data/api/utils';
import {
  ESocialPackageAdminAction,
  ESocialPackagesAdminAction,
  Nullable,
  RzdSocialPackage,
  SportOption,
} from '@/domain';
import axios, { AxiosResponse } from 'axios';

type AllCommandProps = ApiRequestPageable &
  ApiCancellable & {
    readonly querydsl?: Nullable<ApiQueryDsl>;
    readonly restLimitsExist?: boolean;
  };

type AloneCommandProps = {
  readonly id: UUID;
};

type ArchiveCommandProps = AloneCommandProps & {
  readonly reason: SportOption;
  readonly comment?: string;
};

type ToReadyCommandProps = AloneCommandProps;

type BackToAppointedCommandProps = AloneCommandProps;

type SetLimitCommandProps = AloneCommandProps & {
  readonly limitToSelfRest: number;
  readonly limitToFamilyRest: number;
};

type CollectionCommandProps = {
  readonly ids: UUID[];
};

type ArchiveCollectionCommandProps = CollectionCommandProps & {
  readonly reason: SportOption;
  readonly comment?: string;
};

type ToReadyCollectionCommandProps = CollectionCommandProps;

type BackToAppointedCollectionCommandProps = CollectionCommandProps;

type AllocateLimitCollectionCommandProps = CollectionCommandProps & {
  readonly limitToSelfRest: number;
  readonly limitToFamilyRest: number;
};

type SocialPackageApi = {
  readonly all: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllCommandProps
  ) => Promise<AxiosResponse<ApiResponseMaBeCounters<D, RzdSocialPackage[]>>>;
  readonly archive: (props: ArchiveCommandProps) => Promise<AxiosResponse<RzdSocialPackage>>;
  readonly toReady: (props: ToReadyCommandProps) => Promise<AxiosResponse<RzdSocialPackage>>;
  readonly backToAppointed: (props: BackToAppointedCommandProps) => Promise<AxiosResponse<RzdSocialPackage>>;
  readonly allocateLimit: (props: SetLimitCommandProps) => Promise<AxiosResponse<RzdSocialPackage>>;

  readonly archiveCollection: (props: ArchiveCollectionCommandProps) => Promise<AxiosResponse<void>>;
  readonly toReadyCollection: (props: ToReadyCollectionCommandProps) => Promise<AxiosResponse<void>>;
  readonly backToAppointedCollection: (props: BackToAppointedCollectionCommandProps) => Promise<AxiosResponse<void>>;
  readonly allocateLimitCollection: (props: AllocateLimitCollectionCommandProps) => Promise<AxiosResponse<void>>;
};

const socialPackage: SocialPackageApi = {
  all: props => {
    return axios.get(`${getComServicesEndpoint()}/social-packages`, props);
  },
  archive: ({ id, reason, comment }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages/${id}`, {
      discriminator: ESocialPackageAdminAction.SocialPackageArchiveCommand,
      reason,
      comment,
    });
  },
  toReady: ({ id }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages/${id}`, {
      discriminator: ESocialPackageAdminAction.SocialPackageToReadyCommand,
    });
  },
  backToAppointed: ({ id }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages/${id}`, {
      discriminator: ESocialPackageAdminAction.SocialPackageToAppointedCommand,
    });
  },
  allocateLimit: ({ id, limitToSelfRest, limitToFamilyRest }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages/${id}`, {
      discriminator: ESocialPackageAdminAction.SocialPackageAllocateLimitCommand,
      limitToSelfRest,
      limitToFamilyRest,
    });
  },
  archiveCollection: ({ ids, reason, comment }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages/actions`, {
      socialPackageIds: ids,
      discriminator: ESocialPackagesAdminAction.SocialPackagesArchiveCommand,
      reason,
      comment,
    });
  },
  toReadyCollection: ({ ids }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages/actions`, {
      socialPackageIds: ids,
      discriminator: ESocialPackagesAdminAction.SocialPackagesToReadyCommand,
    });
  },
  backToAppointedCollection: ({ ids }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages/actions`, {
      socialPackageIds: ids,
      discriminator: ESocialPackagesAdminAction.SocialPackagesToAppointedCommand,
    });
  },
  allocateLimitCollection: ({ ids, limitToSelfRest, limitToFamilyRest }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages/actions`, {
      socialPackageIds: ids,
      discriminator: ESocialPackagesAdminAction.SocialPackagesAllocateLimitCommand,
      limitToSelfRest,
      limitToFamilyRest,
    });
  },
};

export default socialPackage;
