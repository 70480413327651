import { EAdCampaignStatus } from '@/domain';
import { EAdCampaignActionType } from '../../types';
import { AdCampaignLifeCycleStatusScheme } from '../types';

export const adminStatusScheme: AdCampaignLifeCycleStatusScheme = {
  description: 'Статусная схема главного админа',
  statusScheme: {
    null: [EAdCampaignActionType.Edit],
    [EAdCampaignStatus.Active]: [EAdCampaignActionType.Pause, EAdCampaignActionType.Archive],
    [EAdCampaignStatus.Paused]: [
      EAdCampaignActionType.Edit,
      EAdCampaignActionType.Resume,
      EAdCampaignActionType.Archive,
    ],
    [EAdCampaignStatus.Archived]: [EAdCampaignActionType.Edit],
    [EAdCampaignStatus.Upcoming]: [
      EAdCampaignActionType.Edit,
      EAdCampaignActionType.Pause,
      EAdCampaignActionType.Archive,
    ],
  },
  actionsScheme: {} as any,
};

export const partnerStatusScheme: AdCampaignLifeCycleStatusScheme = adminStatusScheme;
