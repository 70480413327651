import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import TablePagination from '../../../components/common/table/pagination';
import { EPartnerTableTab } from '../../general/partner/types';
import { useContextHandlers } from './hooks/useContextHandlers';
import {
  partnersPageCountSelector,
  partnersPageNumberSelector,
  partnersSearchSelector,
  partnersTotalCountSelector,
} from './store/selectors';

interface PartnersTableFooterContainerProps {
  readonly tab: EPartnerTableTab;
}

const PartnersTableFooterContainer = forwardRef((props: PartnersTableFooterContainerProps, ref: any) => {
  const handlers = useContextHandlers();

  const pageNumber = useSelector(partnersPageNumberSelector);
  const search = useSelector(partnersSearchSelector);
  const totalCount = useSelector(partnersTotalCountSelector);
  const pageCount = useSelector(partnersPageCountSelector);

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='партнеров'
        onChangePageSize={handlers.onChangePageSize}
        onChangePage={handlers.onChangePageNumber}
      />
    </div>
  );
});

export default PartnersTableFooterContainer;
