import { forwardRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProductOffer } from '../../../../domain/model/productOffer';
import { Nullable } from '../../../../domain/model/types';
import ActionsComponent from '../../../components/common/actions';
import TablePagination from '../../../components/common/table/pagination';
import { PaginationSize, PanelAction } from '../../../types';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import { ProductLifeCycle } from '../lifecycle/types';
import { EProductActionType, ProductActions, ProductActionTableCollectionType, ProductActionTableType } from '../types';
import ProductsApproveProductCollectionDialog from './approveDialog/multiple';
import { FooterWrapper } from './controls';
import useProductCollectionActions from './hooks/useCollectionActions';
import ProductsResumeProductCollectionDialog from './resumeDialog/multiple';
import { productsCollectionActionsSelector, productsGetSelector, productsSelectedSelector } from './store/selectors';
import {
  productsApproveProductCollection,
  productsResumeProductCollection,
  productsSetPage,
  productsSetPageSize,
} from './store/slice';
import { EProductTableTab, ProductCollectionActionPayload } from './utils';

interface ProductTableFooterContainerProps {
  readonly tab: EProductTableTab;
  readonly allowedActions: ProductActions<ProductActionTableType>;
  readonly lifecycle: ProductLifeCycle;
}

const ProductTableFooterContainer = forwardRef((props: ProductTableFooterContainerProps, ref: any) => {
  const { tab, lifecycle, allowedActions } = props;

  const dispatch = useDispatch();
  const { userSpecific } = useCurrentUser();

  const [productsToApprove, setProductsToApprove] = useState<Nullable<ProductOffer[]>>(null);
  const [productsToResume, setProductsToResume] = useState<Nullable<ProductOffer[]>>(null);

  const { totalCount, pageCount, pageNumber, search } = useSelector(productsGetSelector);

  const collectionActionProcesses = useSelector(productsCollectionActionsSelector);
  const selectedProducts = useSelector(productsSelectedSelector);

  const onChangePage = (num: number) => {
    dispatch(productsSetPage({ pageNumber: num }));
  };

  const onChangePageSize = (size: PaginationSize) => {
    dispatch(productsSetPageSize({ pageSize: size }));
  };

  const onPanelAction = (action: PanelAction<ProductActionTableCollectionType, ProductCollectionActionPayload>) => {
    const { type, payload } = action;
    const products = payload?.products ?? [];
    switch (type) {
      case EProductActionType.Approve:
        const productsToApprove = products.filter(product =>
          lifecycle.build({ obj: product, allowedActions }).can(EProductActionType.Approve)
        );
        setProductsToApprove(productsToApprove);
        break;
      case EProductActionType.Resume:
        const productsToResume = products.filter(product =>
          lifecycle.build({ obj: product, allowedActions }).can(EProductActionType.Resume)
        );
        setProductsToResume(productsToResume);
        break;
    }
  };

  const onProductsApprove = (products: ProductOffer[]) => {
    dispatch(productsApproveProductCollection({ ids: products.map(product => product.id) }));
  };

  const onProductsResume = (products: ProductOffer[]) => {
    dispatch(productsResumeProductCollection({ ids: products.map(product => product.id) }));
  };

  const collectionActions = useProductCollectionActions({
    tab,
    userSpecific,
    products: selectedProducts,
    lifecycle,
    allowedActions,
  });

  const actionsPanel = collectionActions.length > 0 && (
    <ActionsComponent<ProductActionTableCollectionType>
      actions={collectionActions.map(action => ({ ...action, payload: { products: selectedProducts } }))}
      onAction={onPanelAction}
      navAdapter={null}
    />
  );

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='товаров'
        onChangePageSize={onChangePageSize}
        onChangePage={onChangePage}
      />
      {actionsPanel && <FooterWrapper>{actionsPanel}</FooterWrapper>}
      {productsToResume?.length && (
        <ProductsResumeProductCollectionDialog
          productsCount={productsToResume.length}
          isExecuting={
            !!collectionActionProcesses.find(process => process.type === EProductActionType.Resume)?.isFetching
          }
          onExecute={() => onProductsResume(productsToResume)}
          onClose={() => setProductsToResume(null)}
        />
      )}
      {productsToApprove?.length && (
        <ProductsApproveProductCollectionDialog
          productsCount={productsToApprove.length}
          isExecuting={
            !!collectionActionProcesses.find(process => process.type === EProductActionType.Approve)?.isFetching
          }
          onExecute={() => onProductsApprove(productsToApprove)}
          onClose={() => setProductsToApprove(null)}
        />
      )}
    </div>
  );
});

export default ProductTableFooterContainer;
