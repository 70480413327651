import { Fade, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { UUID } from '../../../../domain/model/types';
import DefaultHeader from '../../../components/common/header';
import ContentLoader from '../../../components/common/loader';
import Splitter from '../../../components/common/splitter';
import { DefaultContentWrapper } from '../../../components/common/wrappers/content';
import { ContainerWrapper, LoaderWrapper } from '../../user/current/detailsMp/controls';
import { getConfigurationPrivilegeCategoriesRoute } from '../entry';
import { EConfigurationPrivilegeDictionaryType } from '../utils';
import { ConfigurationPrivilegeDictionaries } from './components/dictionaries';
import ConfigurationPrivilegeNotifications from './components/notifications';
import { configurationPrivilegeGuidSelector, configurationPrivilegeNotificationsSelector } from './store/selectors';
import {
  configurationPrivilegeNotificationsFetch,
  configurationPrivilegeNotificationsStartSession,
  configurationPrivilegeNotificationUpdate,
} from './store/slice';

interface ConfigurationPrivilegeContainerProps {
  readonly guid: UUID;
  readonly canEditConfiguration?: boolean;
  readonly canEditCorpCategories?: boolean;
  readonly canEditTradeCategories?: boolean;
  readonly canEditBookingOfferCategories?: boolean;
  readonly canEditBookingServiceCategories?: boolean;
  readonly canEditBookingOfferPriceUnits?: boolean;
}

const ConfigurationPrivilegeContainer = (props: ConfigurationPrivilegeContainerProps) => {
  const {
    guid,
    canEditConfiguration,
    canEditCorpCategories,
    canEditTradeCategories,
    canEditBookingOfferCategories,
    canEditBookingServiceCategories,
    canEditBookingOfferPriceUnits,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const { data: notifications, isFetching } = useSelector(configurationPrivilegeNotificationsSelector);
  const currentGuid = useSelector(configurationPrivilegeGuidSelector);

  const onChangeNotification = (id: UUID, enabled: boolean) => {
    dispatch(configurationPrivilegeNotificationUpdate({ id, enabled }));
  };

  const onEditDictionary = (type: EConfigurationPrivilegeDictionaryType) => {
    const editLocation = getConfigurationPrivilegeCategoriesRoute({ type });
    if (editLocation) {
      history.push(editLocation);
    } else {
      console.error(`Unknown location to edit dictionary ${type}`);
    }
  };

  useEffect(() => {
    const promise = dispatch(configurationPrivilegeNotificationsFetch());
    return () => {
      promise?.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    if (guid !== currentGuid) {
      dispatch(configurationPrivilegeNotificationsStartSession({ guid }));
    }
  }, [dispatch, guid, currentGuid]);

  if (guid !== currentGuid) {
    return null;
  }

  return (
    <Fade in>
      <DefaultContentWrapper
        type='table'
        stickyHeader
      >
        <ContainerWrapper>
          <DefaultHeader>
            <Typography variant='h2'>Настройки сервиса</Typography>
          </DefaultHeader>
          <Splitter size={4} />
          {notifications && (
            <ConfigurationPrivilegeNotifications
              notifications={notifications}
              onChangeNotification={canEditConfiguration ? onChangeNotification : null}
            />
          )}
          <ConfigurationPrivilegeDictionaries
            canEditCorpCategories={canEditCorpCategories}
            canEditTradeCategories={canEditTradeCategories}
            canEditBookingOfferCategories={canEditBookingOfferCategories}
            canEditBookingServiceCategories={canEditBookingServiceCategories}
            canEditBookingOfferPriceUnits={canEditBookingOfferPriceUnits}
            onEdit={onEditDictionary}
          />
          {isFetching && (
            <LoaderWrapper>
              <ContentLoader
                size={75}
                alpha
              />
            </LoaderWrapper>
          )}
        </ContainerWrapper>
      </DefaultContentWrapper>
    </Fade>
  );
};

export default ConfigurationPrivilegeContainer;
