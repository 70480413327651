import BookingOrderDetailsStepperFooterAdapter from '../../details/adapters/stepperFooter';
import StepperContainer from '../../../general/stepper/container';
import { FilledContainer } from './controls';

export const BookingOrderEditStepperAdapter = () => {
  return (
    <StepperContainer
      header={<FilledContainer />}
      footer={<BookingOrderDetailsStepperFooterAdapter />}
    />
  );
};
