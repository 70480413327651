import { EOfferStatus, EProductAttributeType, ProductCategory, ProductOffer } from '@/domain';
import { DataTableColumns } from '@components/common/table';
import { ETradeOfferTableColumn } from '@features/tradeOffer/types';
import { ProductActionTableCommonType } from '../types';

export enum EProductTableTab {
  Active = 'active',
  ReadyForSale = 'readyForSale',
  Paused = 'paused',
  Moderation = 'moderation',
  Rejected = 'rejected',
  Archived = 'archived',
  Draft = 'draft',
}

export enum EProductTableColumn {
  DeskNumber = 'productDesk.code',
  Image = 'image',
  PartnerSKU = 'partnerSKU',
  ProducerSKU = 'producerSKU',
  Category = 'category',
  Name = 'name',
  Price = 'price',
  OriginalPrice = 'originalPrice',
  Stock = 'stock',
  Moderator = 'moderator',
  CreatedAt = 'createdAt',
  PausedReason = 'pausedReason',
  LastStatusAuthor = 'lastStatusAuthor',
  Brand = 'brand',
  TargetLocalities = 'targetLocalities.id',
  TargetClientOrgs = 'targetClientOrgs',
  TargetExternalUsers = 'targetExternalUsers',
  Attributes = 'attributes',
}

export type ProductTableTabsCounter = {
  [name in EProductTableTab]?: number;
};

export const getProductTableTabName = (tab: EProductTableTab, isPartnerUser: boolean): string => {
  switch (tab) {
    case EProductTableTab.Paused:
      return 'Приостановенные';
    case EProductTableTab.Rejected:
      return 'Отклонённые';
    case EProductTableTab.Archived:
      return 'Архив';
    case EProductTableTab.Active:
      return 'Продаются';
    case EProductTableTab.ReadyForSale:
      return 'Нет в наличии';
    case EProductTableTab.Moderation:
      return isPartnerUser ? 'На проверке' : 'На проверку';
    case EProductTableTab.Draft:
      return 'Черновики';
  }
};

export const getProductTableTabs = (isPartnerUser: boolean): EProductTableTab[] =>
  isPartnerUser
    ? [
        EProductTableTab.Active,
        EProductTableTab.ReadyForSale,
        EProductTableTab.Paused,
        EProductTableTab.Moderation,
        EProductTableTab.Rejected,
        EProductTableTab.Draft,
        EProductTableTab.Archived,
      ]
    : [
        EProductTableTab.Moderation,
        EProductTableTab.Active,
        EProductTableTab.ReadyForSale,
        EProductTableTab.Paused,
        EProductTableTab.Rejected,
        EProductTableTab.Archived,
      ];

export const getProductTableTabsForCounts = (isPartnerUser: boolean): EProductTableTab[] =>
  isPartnerUser
    ? [
        EProductTableTab.Active,
        EProductTableTab.ReadyForSale,
        EProductTableTab.Paused,
        EProductTableTab.Moderation,
        EProductTableTab.Rejected,
        EProductTableTab.Draft,
        EProductTableTab.Archived,
      ]
    : [
        EProductTableTab.Moderation,
        EProductTableTab.Active,
        EProductTableTab.ReadyForSale,
        EProductTableTab.Paused,
        EProductTableTab.Rejected,
        EProductTableTab.Archived,
      ];

export const getProductTableTabsForCountsUnwatched = (): EProductTableTab[] => [
  EProductTableTab.Active,
  EProductTableTab.ReadyForSale,
  EProductTableTab.Paused,
  EProductTableTab.Moderation,
  EProductTableTab.Archived,
];

export const getStatesFilterForProductTableTab = (
  tab: EProductTableTab,
  defaultStatuses: EOfferStatus[]
): EOfferStatus[] => {
  switch (tab) {
    case EProductTableTab.Active:
      return [EOfferStatus.Active];

    case EProductTableTab.Archived:
      return [EOfferStatus.Archived];

    case EProductTableTab.Moderation:
      return [EOfferStatus.WaitingForModeration, EOfferStatus.OnModeration];

    case EProductTableTab.ReadyForSale:
      return [EOfferStatus.ReadyForSale];

    case EProductTableTab.Draft:
      return [EOfferStatus.Draft];

    case EProductTableTab.Paused:
      return [EOfferStatus.Paused];

    case EProductTableTab.Rejected:
      return [EOfferStatus.Rejected];

    default: {
      return defaultStatuses;
    }
  }
};

export const getProductTableTabByStatus = (statuses: EOfferStatus[]): EProductTableTab => {
  if (statuses.includes(EOfferStatus.Draft)) return EProductTableTab.Draft;
  if (statuses.includes(EOfferStatus.OnModeration) || statuses.includes(EOfferStatus.WaitingForModeration))
    return EProductTableTab.Moderation;
  if (statuses.includes(EOfferStatus.Rejected)) return EProductTableTab.Rejected;
  if (statuses.includes(EOfferStatus.ReadyForSale)) return EProductTableTab.ReadyForSale;
  if (statuses.includes(EOfferStatus.Paused)) return EProductTableTab.Paused;
  if (statuses.includes(EOfferStatus.Archived)) return EProductTableTab.Archived;

  return EProductTableTab.Active;
};

export const getProductTableStatusByTab = (tab: EProductTableTab): EOfferStatus[] => {
  switch (tab) {
    case EProductTableTab.Active:
      return [EOfferStatus.Active];
    case EProductTableTab.ReadyForSale:
      return [EOfferStatus.ReadyForSale];
    case EProductTableTab.Paused:
      return [EOfferStatus.Paused];
    case EProductTableTab.Moderation:
      return [EOfferStatus.WaitingForModeration, EOfferStatus.OnModeration];
    case EProductTableTab.Rejected:
      return [EOfferStatus.Rejected];
    case EProductTableTab.Archived:
      return [EOfferStatus.Archived];
    case EProductTableTab.Draft:
      return [EOfferStatus.Draft];
  }
};

const productTableDefaultColumns = [
  EProductTableColumn.DeskNumber,
  EProductTableColumn.Image,
  EProductTableColumn.PartnerSKU,
  EProductTableColumn.Category,
  EProductTableColumn.Name,
  EProductTableColumn.Price,
  EProductTableColumn.CreatedAt,
];

export const getProductTableDefaultColumns = (
  tab: EProductTableTab,
  partnerId: Nullable<UUID>
): EProductTableColumn[] => {
  let columns: EProductTableColumn[];
  switch (tab) {
    case EProductTableTab.Moderation:
      columns = [...productTableDefaultColumns, EProductTableColumn.Moderator];
      break;
    case EProductTableTab.Active:
      columns = [...productTableDefaultColumns, EProductTableColumn.Stock];
      break;
    case EProductTableTab.Paused:
      columns = [
        ...productTableDefaultColumns,
        EProductTableColumn.Stock,
        EProductTableColumn.PausedReason,
        EProductTableColumn.LastStatusAuthor,
      ];
      break;
    case EProductTableTab.ReadyForSale:
      columns = [...productTableDefaultColumns, EProductTableColumn.Stock];
      break;
    default:
      columns = productTableDefaultColumns;
  }

  /*  if (partnerId) {
      columns.splice(columns.indexOf(EProductTableColumn.Partner), 1);
    }*/

  return columns;
};

const productTableColumns: DataTableColumns<EProductTableColumn> = {
  [EProductTableColumn.DeskNumber]: {
    title: '№ карточки',
    width: '6,7rem',
    sortable: true,
  },
  [EProductTableColumn.Image]: {
    title: 'Изображение',
    width: '6,5rem',
  },
  [EProductTableColumn.PartnerSKU]: {
    title: 'Ваш SKU',
    width: '7,5rem',
    sortable: true,
  },
  [EProductTableColumn.ProducerSKU]: {
    title: 'Артикул производителя',
    width: '7,25rem',
    sortable: true,
  },
  [EProductTableColumn.Category]: {
    title: 'Категория',
    width: '6,5rem',
    sortable: true,
    wrap: true,
  },
  [EProductTableColumn.Name]: {
    title: 'Наименование товара',
    width: '16rem',
    sortable: true,
    wrap: true,
  },
  [EProductTableColumn.Price]: {
    title: 'Цена',
    width: '7,25rem',
    sortable: true,
  },
  [EProductTableColumn.OriginalPrice]: {
    title: 'Цена до скидки',
    width: '7,25rem',
    sortable: true,
  },
  [EProductTableColumn.Stock]: {
    title: 'Остаток',
    width: '7,5rem',
    sortable: true,
  },
  [EProductTableColumn.Moderator]: {
    title: 'Ответственный',
    width: '8rem',
    sortable: true,
    wrap: true,
  },
  [EProductTableColumn.CreatedAt]: {
    title: 'Дата создания',
    width: '8rem',
    sortable: true,
  },
  [EProductTableColumn.PausedReason]: {
    title: 'Причина остановки',
    width: '10rem',
    sortable: true,
    wrap: true,
  },
  [EProductTableColumn.LastStatusAuthor]: {
    title: 'Ответственный',
    width: '8rem',
    sortable: true,
    wrap: true,
  },
  [EProductTableColumn.Brand]: {
    title: 'Бренд',
    width: '8rem',
    sortable: true,
    wrap: true,
  },
  [EProductTableColumn.TargetLocalities]: {
    title: 'Города',
    width: '8rem',
    sortable: true,
    wrap: true,
  },
  [ETradeOfferTableColumn.TargetExternalUsers]: {
    title: 'Внешние пользователи',
    width: '4rem',
    sortable: true,
  },
  [ETradeOfferTableColumn.TargetClientOrgs]: {
    title: 'Компании',
    width: '10rem',
    wrap: true,
    sortable: true,
  },
  [EProductTableColumn.Attributes]: {
    title: 'Характеристики',
    width: '20rem',
    wrap: true,
  },
};

export const getProductTableColumns = (
  tab: EProductTableTab,
  partnerId: Nullable<UUID>
): DataTableColumns<EProductTableColumn> => {
  const defaultColumns = getProductTableDefaultColumns(tab, partnerId);

  const allColumns: EProductTableColumn[] = [];
  switch (tab) {
    case EProductTableTab.Active:
      allColumns.push(
        EProductTableColumn.DeskNumber,
        EProductTableColumn.Image,
        EProductTableColumn.PartnerSKU,
        EProductTableColumn.ProducerSKU,
        EProductTableColumn.Category,
        EProductTableColumn.Name,
        EProductTableColumn.Price,
        EProductTableColumn.OriginalPrice,
        EProductTableColumn.Stock,
        EProductTableColumn.CreatedAt,

        EProductTableColumn.Attributes,

        EProductTableColumn.Brand,
        EProductTableColumn.TargetLocalities,
        EProductTableColumn.TargetExternalUsers,
        EProductTableColumn.TargetClientOrgs
      );
      break;
    case EProductTableTab.ReadyForSale:
      allColumns.push(
        EProductTableColumn.DeskNumber,
        EProductTableColumn.Image,
        EProductTableColumn.PartnerSKU,
        EProductTableColumn.ProducerSKU,
        EProductTableColumn.Category,
        EProductTableColumn.Name,
        EProductTableColumn.Price,
        EProductTableColumn.OriginalPrice,
        EProductTableColumn.Stock,
        EProductTableColumn.CreatedAt,

        EProductTableColumn.Attributes,

        EProductTableColumn.Brand,
        EProductTableColumn.TargetLocalities,
        EProductTableColumn.TargetExternalUsers,
        EProductTableColumn.TargetClientOrgs
      );
      break;
    case EProductTableTab.Paused:
      allColumns.push(
        EProductTableColumn.DeskNumber,
        EProductTableColumn.Image,
        EProductTableColumn.PartnerSKU,
        EProductTableColumn.ProducerSKU,
        EProductTableColumn.Category,
        EProductTableColumn.Name,
        EProductTableColumn.Price,
        EProductTableColumn.OriginalPrice,
        EProductTableColumn.Stock,
        EProductTableColumn.CreatedAt,
        EProductTableColumn.PausedReason,
        EProductTableColumn.LastStatusAuthor,

        EProductTableColumn.Attributes,

        EProductTableColumn.Brand,
        EProductTableColumn.TargetLocalities,
        EProductTableColumn.TargetExternalUsers,
        EProductTableColumn.TargetClientOrgs
      );
      break;
    case EProductTableTab.Moderation:
      allColumns.push(
        EProductTableColumn.DeskNumber,
        EProductTableColumn.Image,
        EProductTableColumn.PartnerSKU,
        EProductTableColumn.ProducerSKU,
        EProductTableColumn.Category,
        EProductTableColumn.Name,
        EProductTableColumn.Price,
        EProductTableColumn.OriginalPrice,
        EProductTableColumn.CreatedAt,
        EProductTableColumn.Moderator,

        EProductTableColumn.Attributes,

        EProductTableColumn.Brand,
        EProductTableColumn.TargetLocalities,
        EProductTableColumn.TargetExternalUsers,
        EProductTableColumn.TargetClientOrgs
      );
      break;
    default:
      allColumns.push(
        EProductTableColumn.DeskNumber,
        EProductTableColumn.Image,
        EProductTableColumn.PartnerSKU,
        EProductTableColumn.ProducerSKU,
        EProductTableColumn.Category,
        EProductTableColumn.Name,
        EProductTableColumn.Price,
        EProductTableColumn.OriginalPrice,
        EProductTableColumn.CreatedAt,

        EProductTableColumn.Attributes,

        EProductTableColumn.Brand,
        EProductTableColumn.TargetLocalities,
        EProductTableColumn.TargetExternalUsers,
        EProductTableColumn.TargetClientOrgs
      );
  }

  /*  if (partnerId) {
    allColumns.splice(columns.indexOf(EProductTableColumn.Partner), 1);
  }*/

  return allColumns.reduce<DataTableColumns<EProductTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...productTableColumns[column],
        hidden: !defaultColumns.includes(column),
      },
    };
  }, {});
};

export type ProductActionCall = {
  readonly action: ProductActionTableCommonType;
  readonly product: ProductOffer;
};

export type ProductCollectionActionPayload = {
  readonly products: ProductOffer[];
};

export const expandColumnsByCategory = <C extends string, T extends object = DataTableColumns<C>>(
  columns: T,
  category: Nullable<ProductCategory>
): T => {
  const newSourceColumns = { ...columns };

  if (category) {
    const newSourceColumnsArr = Object.entries(newSourceColumns);
    const idx = newSourceColumnsArr.findIndex(([key]) => key === EProductTableColumn.Attributes);
    category?.attributes
      ?.filter(attr => attr && attr.attribute.type !== EProductAttributeType.File)
      ?.forEach(item => {
        newSourceColumnsArr.splice(idx + 1, 0, [
          item.attribute.name,
          {
            title: item.attribute.name,
            width: '10rem',
            wrap: true,
            metaData: item,
            slotRowName: EProductTableColumn.Attributes,
          },
        ]);
      });

    newSourceColumnsArr.splice(idx, 1);
    return Object.fromEntries(newSourceColumnsArr) as T;
  } else {
    // @ts-ignore
    Reflect.deleteProperty(newSourceColumns, EProductTableColumn.Attributes);
    return newSourceColumns;
  }
};
