import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterStrategyView,
  DataFilterValueItem,
  EDataFilterType,
  EDateFormat,
  Nullable,
} from '@/domain';
import moment from 'moment';
import { EBannerTableColumn } from './utils';

export enum EBannerTableFilterItem {
  Query = 'query',
  Name = 'name',
  Code = 'code',
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export const bannerTableFilterItems: Record<EBannerTableColumn, EBannerTableFilterItem[]> = {
  [EBannerTableColumn.Code]: [EBannerTableFilterItem.Code],
  [EBannerTableColumn.StartDate]: [EBannerTableFilterItem.StartDate],
  [EBannerTableColumn.EndDate]: [EBannerTableFilterItem.EndDate],
  [EBannerTableColumn.Name]: [EBannerTableFilterItem.Name],
  [EBannerTableColumn.Image]: [],
  [EBannerTableColumn.Places]: [],
  [EBannerTableColumn.SortIndex]: [],
};

export type BannerTableFilterEditStrategy = DataFilterStrategyBase<EBannerTableFilterItem>;
export type BannerTableFilterViewStrategy = DataFilterStrategyView<EBannerTableFilterItem>;
export type BannerTableFilterValues = {
  readonly [EBannerTableFilterItem.Query]?: DataFilterValueItem<Nullable<string>>;
  readonly [EBannerTableFilterItem.Name]?: DataFilterValueItem<Nullable<string>>;
  readonly [EBannerTableFilterItem.Code]?: DataFilterValueItem<Nullable<string>>;
  readonly [EBannerTableFilterItem.StartDate]?: DataFilterValueItem<Nullable<string>>;
  readonly [EBannerTableFilterItem.EndDate]?: DataFilterValueItem<Nullable<string>>;
};

export const getBannerTableFilterStrategy = (
  filterItem: EBannerTableFilterItem,
  values: BannerTableFilterValues
): Nullable<BannerTableFilterEditStrategy> => {
  switch (filterItem) {
    case EBannerTableFilterItem.Query:
      return {
        type: EDataFilterType.String,
        key: EBannerTableFilterItem.Query,
        label: 'Поиск по ключевому слову',
        preview: values[EBannerTableFilterItem.Query]?.value || null,
        value: values[EBannerTableFilterItem.Query]?.value || null,
      };
    case EBannerTableFilterItem.Name:
      return {
        type: EDataFilterType.String,
        key: EBannerTableFilterItem.Name,
        label: 'Название',
        preview: values[EBannerTableFilterItem.Name]?.value || null,
        value: values[EBannerTableFilterItem.Name]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case EBannerTableFilterItem.Code:
      return {
        type: EDataFilterType.String,
        key: EBannerTableFilterItem.Code,
        label: 'Код',
        preview: values[EBannerTableFilterItem.Code]?.value || null,
        value: values[EBannerTableFilterItem.Code]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case EBannerTableFilterItem.StartDate:
      return {
        type: EDataFilterType.DateFrom,
        key: EBannerTableFilterItem.StartDate,
        group: 'date',
        label: 'Начало',
        preview: values[EBannerTableFilterItem.StartDate]?.value
          ? `с ${moment(values[EBannerTableFilterItem.StartDate]?.value).format(EDateFormat.DisplayDefault)}`
          : null,
        value: values[EBannerTableFilterItem.StartDate]?.value ?? null,
        querydsl: {
          operator: DataFilterQueryDslOperator.MoreOrEquals,
        },
      };
    case EBannerTableFilterItem.EndDate:
      return {
        type: EDataFilterType.DateTo,
        key: EBannerTableFilterItem.EndDate,
        group: 'date',
        label: 'Окончание',
        preview: values[EBannerTableFilterItem.EndDate]?.value
          ? `по ${moment(values[EBannerTableFilterItem.EndDate]?.value).format(EDateFormat.DisplayDefault)}`
          : null,
        value: values[EBannerTableFilterItem.EndDate]?.value ?? null,
        querydsl: {
          operator: DataFilterQueryDslOperator.LessOrEquals,
        },
      };
    default:
      return null;
  }
};
