import CmsContainerTitle from '../../../components/titleControl';
import { CmsContainerDetailsComponentProps } from '../types';

type CmsContainerViewComponentTitleProps = CmsContainerDetailsComponentProps;

const CmsContainerViewComponentTitle = (props: CmsContainerViewComponentTitleProps) => {
  const { cmsContainer } = props;

  return (
    <CmsContainerTitle
      disabled
      title={cmsContainer.title}
      showTitle={cmsContainer.showTitle ?? false}
      onChangeTitle={() => {
        /* empty*/
      }}
      onChangeShowTitle={() => {
        /* empty*/
      }}
    />
  );
};

export default CmsContainerViewComponentTitle;
