import { MpCustomer } from '@/domain';
import { ReactNode, useMemo } from 'react';
import DataTableCellTextFormat from '../../../../components/common/table/cell/textFormat';

export interface CustomerTableCellCommonProps {
  readonly customer: MpCustomer;
}

interface CustomerTableCellProps extends CustomerTableCellCommonProps {
  readonly value: ReactNode;
}

const CustomerTableCell = ({ value }: CustomerTableCellProps) => {
  return useMemo(() => <DataTableCellTextFormat>{value}</DataTableCellTextFormat>, [value]);
};

export default CustomerTableCell;
