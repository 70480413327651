import { Nullable } from '@/domain/model/types';
import AdCampaignActionsProvider from '@features/adCampaign/actions/provider';
import { AdCampaignsLocationState, EAdCampaignUrlParam } from '@features/adCampaign/entry';
import AdCampaignTableEntry from '@features/adCampaign/table/entry';
import { EAdCampaignTableTab } from '@features/adCampaign/types';
import { viewHideMainMenu } from '@features/main/container/store/slice';
import { viewShowPartnerManagementMenu } from '@features/partner/management/container/store/slice';
import { Partner } from 'domain/model/partner';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';

interface PartnerManagementAdCampaignsScreenProps {
  readonly partner: Partner;
}

const PartnerManagementAdCampaignsScreen = ({ partner }: PartnerManagementAdCampaignsScreenProps) => {
  const dispatch = useDispatch();
  const location = useLocation<Nullable<AdCampaignsLocationState>>();

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(EAdCampaignUrlParam.Tab) as EAdCampaignTableTab;

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowPartnerManagementMenu());
  }, [dispatch]);

  return (
    <LocationWithRequiredStateContainer<AdCampaignsLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <AdCampaignActionsProvider>
          <AdCampaignTableEntry
            guid={state.guid}
            partnerId={partner.id}
            tab={tab}
          />
        </AdCampaignActionsProvider>
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default PartnerManagementAdCampaignsScreen;
