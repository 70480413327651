import { Grid, Typography } from '@mui/material';
import { MPButton } from '../../../../../theme/ui-kit/button';

interface PartnerDetailsOwnerDeskStubProps {
  readonly onEdit?: () => void;
}

const PartnerDetailsOwnerDeskStub = ({ onEdit }: PartnerDetailsOwnerDeskStubProps) => {
  return (
    <Grid
      container
      direction='column'
      spacing={4}
    >
      <Grid
        item
        xs={12}
      >
        <Typography>
          Для того чтобы создать торговое предложение, необходимо заполнить карточку компании, которая будет
          отображаться на Витрине. Карточка компании будет содержать в себе все торговые предложения вашей компании,
          совсем как папка в проводнике Windows.
        </Typography>
      </Grid>
      {onEdit && (
        <Grid item>
          <MPButton
            onClick={onEdit}
            fullWidth={false}
          >
            Заполнить карточку компании
          </MPButton>
        </Grid>
      )}
    </Grid>
  );
};

export default PartnerDetailsOwnerDeskStub;
