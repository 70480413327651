import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '../../../../../data/api/utils';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { ServerErrorResponse } from '../../../../../data/network/types';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable } from '../../../../../domain/model';
import { PartnerDesk } from '../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { PaginationSize } from '../../../../types';
import { PartnerDeskTableFilterValues } from '../filterUtils';

export interface PartnerDesksFetchProps {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly pageNumber: number;
    readonly sort: string;
  };
  readonly filter: PartnerDeskTableFilterValues;
}

export const partnerDesksFetch = createAsyncThunk<Pageable<PartnerDesk>, PartnerDesksFetchProps, AppThunkAPIConfig>(
  'partnerDesk/list/fetch',
  async ({ search, filter }, { rejectWithValue, signal }) => {
    try {
      const { pageSize, sort, pageNumber } = search;
      const { query } = filter;
      const querydsl = getQueryDslByDataFilterValues(filter);
      const response = await Api.partner.desks({
        sort: 'sortIndex,asc' + (sort ? `|${sort}` : ''),
        page: pageNumber,
        query: query?.value,
        querydsl,
        pageSize,
        signal,
      });
      const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);
      return { data: response.data, totalCount, pageCount, pageNumber: page };
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const partnerDesksChangeSortIndex = createAsyncThunk<
  undefined,
  { id: UUID; sortIndex: number },
  AppThunkAPIConfig<ServerErrorResponse>
>('partnerDesk/list/desk/changeSortIndex', async ({ id, sortIndex }, { rejectWithValue }) => {
  try {
    await Api.partner.changeDeskSortIndex({ id, sortIndex });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface PartnerDeskListState extends PartnerDesksFetchProps {
  readonly desks: Fetchable & Pageable<PartnerDesk>;
  readonly changeSortIndex: Fetchable & {
    readonly id: Nullable<UUID>;
  };
  readonly needRefreshWatcher: number;
}

type Reducer<T = undefined> = CaseReducer<PartnerDeskListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PartnerDeskListState> {
  partnerDesksClearState: Reducer;
  partnerDesksSetSearch: Reducer<{ sort: string; pageSize: PaginationSize; pageNumber: number }>;
  partnerDesksSetFilter: Reducer<PartnerDeskTableFilterValues>;
}

const slice = createSlice<PartnerDeskListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    desks: {
      ...fetchableDefault,
      data: [],
      totalCount: 0,
      pageCount: 0,
      pageNumber: 1,
    },
    needRefreshWatcher: 0,
    search: {
      sort: '',
      pageSize: 10,
      pageNumber: 1,
    },
    filter: {},
    changeSortIndex: {
      ...fetchableDefault,
      id: null,
    },
  },
  reducers: {
    partnerDesksSetSearch: (state, { payload }) => {
      const { sort, pageSize, pageNumber } = payload;
      state.search = {
        ...state.search,
        sort,
        pageSize,
        pageNumber: pageSize !== state.search.pageSize ? 1 : pageNumber,
      };
      state.needRefreshWatcher++;
    },
    partnerDesksClearState: state => {
      state.needRefreshWatcher = 0;
      state.changeSortIndex = {
        ...fetchableDefault,
        id: null,
      };
    },
    partnerDesksSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.needRefreshWatcher++;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(partnerDesksFetch.pending, state => {
        state.desks.isFetching = true;
        state.desks.isFetched = false;
        state.desks.isFailed = false;
      })
      .addCase(partnerDesksFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.desks.isFetching = false;
        state.desks.isFetched = true;
        state.desks.isFailed = false;

        state.desks.data = data;
        state.desks.totalCount = totalCount;
        state.desks.pageCount = pageCount;
      })
      .addCase(partnerDesksFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.desks.isFetching = false;
          state.desks.isFetched = false;
          state.desks.isFailed = false;
        } else {
          state.desks.isFetching = false;
          state.desks.isFetched = false;
          state.desks.isFailed = true;
        }
        state.desks.data = [];
      })
      .addCase(partnerDesksChangeSortIndex.pending, (state, { meta }) => {
        const { id } = meta.arg;

        state.changeSortIndex.isFetching = true;
        state.changeSortIndex.isFetched = false;
        state.changeSortIndex.isFailed = false;
        state.changeSortIndex.id = id;
      })
      .addCase(partnerDesksChangeSortIndex.fulfilled, state => {
        state.changeSortIndex.isFetching = false;
        state.changeSortIndex.isFetched = true;
        state.changeSortIndex.isFailed = false;
        state.needRefreshWatcher++;
      })
      .addCase(partnerDesksChangeSortIndex.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.changeSortIndex.isFetching = false;
          state.changeSortIndex.isFetched = false;
          state.changeSortIndex.isFailed = false;
        } else {
          state.changeSortIndex.isFetching = false;
          state.changeSortIndex.isFetched = true;
          state.changeSortIndex.isFailed = true;
          state.changeSortIndex.id = null;
        }
      });
  },
});

export const { partnerDesksSetSearch, partnerDesksSetFilter, partnerDesksClearState } = slice.actions;

export default slice.reducer;
