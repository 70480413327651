import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { BookingOfferServiceManageFieldAdapter } from './adapters/field';
import BookingOfferServiceManageServicesFieldAdapter from './adapters/fields/services';
import FormItemAdapter from './adapters/formItem';
import { ContentWrapper } from './controls';
import { bookingOfferServiceManageBookingOfferSelector } from './store/selectros';

export const BookingOfferServiceManageComponent = () => {
  const bookingOffer = useSelector(bookingOfferServiceManageBookingOfferSelector);

  if (bookingOffer === null) {
    return null;
  }

  return (
    <ContentWrapper>
      <Grid
        container
        spacing={2}
        xs={12}
      >
        <FormItemAdapter>
          <BookingOfferServiceManageFieldAdapter
            name={'services'}
            component={BookingOfferServiceManageServicesFieldAdapter}
          />
        </FormItemAdapter>
      </Grid>
    </ContentWrapper>
  );
};
