import { Grid } from '@mui/material';
import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TradeOffer } from '../../../../domain/model/tradeOffer';
import TablePagination from '../../../components/common/table/pagination';
import { MPButton } from '../../../theme/ui-kit/button';
import { PaginationSize } from '../../../types';
import { FooterActionsWrapper } from './controls';
import { tradeOffersSearchGet, tradeOffersSearchSelectedSelector } from './store/selectors';
import { tradeOffersSearchSetPage, tradeOffersSearchSetPageSize } from './store/slice';

interface TradeOfferSearchFooterContainerProps {
  readonly maxCount?: number | false;
  readonly onSelect: (offers: TradeOffer[]) => void;
}

const TradeOfferSearchFooterContainer = forwardRef((props: TradeOfferSearchFooterContainerProps, ref: any) => {
  const { maxCount, onSelect } = props;

  const dispatch = useDispatch();

  const { totalCount, pageCount, pageNumber, search, selected: offers } = useSelector(tradeOffersSearchGet);

  const selectedTradeOffers = useSelector(tradeOffersSearchSelectedSelector);

  const onChangePage = (num: number) => {
    dispatch(tradeOffersSearchSetPage({ pageNumber: num }));
  };

  const onChangePageSize = (size: PaginationSize) => {
    dispatch(tradeOffersSearchSetPageSize({ pageSize: size }));
  };

  let buttonText = `Выбрать ${selectedTradeOffers.length}`;

  if (maxCount) {
    buttonText += ` из ${maxCount}`;
  }

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='торговых предложений'
        onChangePageSize={onChangePageSize}
        onChangePage={onChangePage}
      />
      <FooterActionsWrapper>
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <MPButton
              disabled={selectedTradeOffers.length === 0}
              onClick={() => onSelect(offers)}
            >
              {buttonText}
            </MPButton>
          </Grid>
        </Grid>
      </FooterActionsWrapper>
    </div>
  );
});

export default TradeOfferSearchFooterContainer;
