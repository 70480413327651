import axios, { AxiosResponse } from 'axios';
import { Chat, ChatMember, ChatMessage } from '../../domain/model/chat';
import { Nullable, UUID } from '../../domain/model/types';
import { createCancelToken } from './index';
import { ApiCancellable, ApiRequestPageable, ApiResponseWithPageable } from './types';
import { getSportBaseEndpoint } from './utils';

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type AllProps = ApiRequestPageable &
  ApiCancellable & {
    readonly types?: Nullable<UUID[]>;
    readonly name?: Nullable<string>;
    readonly subjectId?: Nullable<UUID>;
  };

type MessagesProps = ApiCancellable & {
  readonly id: UUID;
  /** Направление сообщений (true - вперед/"юг", false - назад/"север") относительно заданного messageId */
  readonly forward: boolean;
  readonly messageId?: UUID;
  readonly limit: number;
};

type MembersProps = ApiCancellable & {
  readonly id: UUID;
};

type MessageDeleteProps = {
  readonly id: UUID;
};

type ByEventProps = {
  readonly id: UUID;
};

type ChatApi = {
  readonly one: (props: OneProps) => Promise<AxiosResponse<Chat>>;
  readonly all: (props: AllProps) => Promise<AxiosResponse<ApiResponseWithPageable<Chat>>>;
  readonly messages: (props: MessagesProps) => Promise<AxiosResponse<ChatMessage[]>>;
  readonly members: (props: MembersProps) => Promise<AxiosResponse<ChatMember[]>>;
  readonly delete: (props: MessageDeleteProps) => Promise<AxiosResponse<void>>;
  readonly byEvent: (props: ByEventProps) => Promise<AxiosResponse<Chat>>;
};
/**
 * АПИ по работе с чатами
 */
const chat: ChatApi = {
  one: ({ id, signal }) => {
    return axios.get(`${getSportBaseEndpoint()}/chats/${id}`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },

  all: ({ page, pageSize, sort, signal, types, name, subjectId }) => {
    const params = new URLSearchParams();

    if (name) params.append('name', name);
    if (types) types.forEach(item => params.append('types', String(item)));
    if (subjectId) params.append('subjectId', subjectId);

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));
    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    return axios.get(`${getSportBaseEndpoint()}/admin/chats`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },

  messages: ({ id, limit, forward, messageId, signal }) => {
    const params = new URLSearchParams();

    params.append('limit', limit.toString(10));
    params.append('forward', forward.toString());

    if (messageId) params.append('messageId', messageId);

    return axios.get(`${getSportBaseEndpoint()}/chats/${id}/messages-chunk`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },

  members: ({ id, signal }) => {
    return axios.get(`${getSportBaseEndpoint()}/chats/${id}/members`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },

  delete: ({ id }) => {
    return axios.delete(`${getSportBaseEndpoint()}/messages/${id}`);
  },

  byEvent: ({ id }) => {
    return axios.get(`${getSportBaseEndpoint()}/events/${id}/chat`);
  },
};

export default chat;
