import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BookingOfferShort } from '../../../../../domain/model/booking';
import { MpUser } from '../../../../../domain/model/user';
import { EBookingActionType } from '../../types';
import { BookingOfferChangeModeratorDialog } from '../components/changeModeratorDialog';
import {
  bookingOfferActionsDialogChangeModeratorSelector,
  getBookingOfferActionsOfferIsProcessingSelector,
} from '../store/selectors';
import { bookingOfferActionsChangeModerator } from '../store/slice';
import { useBookingOfferActions } from '../useActions';
import { showBookingOfferActionNotification } from '../utils';

const BookingOfferActionsDialogChangeModeratorAdapter = () => {
  const dispatch = useDispatch();
  const handlers = useBookingOfferActions();

  const object = useSelector(bookingOfferActionsDialogChangeModeratorSelector);

  const isProcessing = useSelector(getBookingOfferActionsOfferIsProcessingSelector(object?.id ?? null));

  const onChangeModerator = useCallback(
    (offer: BookingOfferShort, user: MpUser) => {
      dispatch(
        bookingOfferActionsChangeModerator({
          id: offer.id,
          userId: user.id,
        })
      )
        .unwrap()
        .then(response => {
          handlers.onChangeDialogState('changeModerator', null);
          showBookingOfferActionNotification(response, EBookingActionType.ChangeModerator, false);
        });
    },
    [dispatch, handlers.onChangeDialogState]
  );

  const onCloseDialog = useCallback(
    () => handlers.onChangeDialogState('changeModerator', null),
    [handlers.onChangeDialogState]
  );

  return (
    object && (
      <BookingOfferChangeModeratorDialog
        offer={object}
        isExecuting={isProcessing}
        onExecute={onChangeModerator}
        onClose={onCloseDialog}
      />
    )
  );
};

export default BookingOfferActionsDialogChangeModeratorAdapter;
