import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TabsPanel from '../../../../components/common/tabs/panel';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { teamsTabsCounterSelector } from '../store/selectors';
import TeamTableTab from '../tab';
import { ETeamTableTab } from '../utils';

interface TeamTableTabsAdapterProps {
  readonly tab: ETeamTableTab;
}

export const TeamTableTabsAdapter = (props: TeamTableTabsAdapterProps) => {
  const { tab } = props;
  const { onChangeTab } = useContextHandlers();
  const counter = useSelector(teamsTabsCounterSelector);

  return useMemo(
    () => (
      <TabsPanel
        tab={tab}
        tabs={Object.values(ETeamTableTab)}
        labelAdapter={TeamTableTab}
        data={{
          counts: counter,
        }}
        onChangeTab={onChangeTab}
      />
    ),
    [tab, counter, onChangeTab]
  );
};
