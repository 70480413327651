import { Grid, Skeleton } from '@mui/material';
import { Bottom, Top, Wrapper } from './controls';

const CompilationBlock = () => (
  <Wrapper>
    <Top />
    <Bottom>
      <Skeleton
        animation={false}
        height={4}
        variant='text'
        width='100%'
      />
      <Skeleton
        animation={false}
        height={4}
        variant='text'
        width='100%'
      />
      <Skeleton
        animation={false}
        height={4}
        variant='text'
        width='75%'
      />
      <Grid
        container
        justifyContent='space-between'
      >
        <Grid
          item
          xs={3}
        >
          <Skeleton
            animation={false}
            height={6}
            variant='text'
            width='100%'
          />
        </Grid>
        <Grid item>
          <Skeleton
            animation={false}
            height={6}
            variant='circular'
            width={6}
          />
        </Grid>
      </Grid>
    </Bottom>
  </Wrapper>
);

export default CompilationBlock;
