import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import SectionTitle from '../../../../../components/common/sectionTitle';
import { CmsContainerDetailsComponentProps } from '../types';

type CmsContainerViewComponentBannerSizeProps = CmsContainerDetailsComponentProps;

const CmsContainerViewComponentBannerSize = (props: CmsContainerViewComponentBannerSizeProps) => {
  const { cmsContainer } = props;

  return (
    <Grid
      container
      spacing={1}
      direction='column'
    >
      <Grid item>
        <SectionTitle gutterBottom={false}>Размер баннера</SectionTitle>
      </Grid>
      <Grid item>
        <Typography color='textSecondary'>Размер баннера</Typography>
      </Grid>
      <Grid item>
        <Typography>{cmsContainer.resourcesSize?.join(' x ')?.replaceAll('px', '') ?? '-'}</Typography>
      </Grid>
    </Grid>
  );
};

export default CmsContainerViewComponentBannerSize;
