import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ForbiddenComponent from '../../components/common/forbidden';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { viewShowMainMenu } from '../../features/main/container/store/slice';
import { TeamsLocationState } from '../../features/team/entry';
import TeamTableEntry from '../../features/team/table/entry';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const TeamsScreen = () => {
  const dispatch = useDispatch();
  const { teams } = useCurrentUser().accessMatrix;

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (!teams?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<TeamsLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => <TeamTableEntry guid={state.guid} />}
    </LocationWithRequiredStateContainer>
  );
};

export default TeamsScreen;
