import { useEffect } from 'react';
import { SportOptionTyped } from '../../../../../domain/model';
import { EOfferType } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import CmsContainerChangeOfferTypeDialog from '../changeOfferTypeDialog';

interface CmsContainerOfferTypeObserverProps {
  readonly displayValue: Nullable<SportOptionTyped<EOfferType>>;
  readonly currentValue: Nullable<EOfferType>;
  readonly onRestore: () => void;
  readonly onChange: (offerType: Nullable<EOfferType>) => void;
}

const CmsContainerOfferTypeObserver = (props: CmsContainerOfferTypeObserverProps) => {
  const { displayValue, currentValue, onRestore, onChange } = props;

  useEffect(() => {
    if (currentValue === null && currentValue !== displayValue?.id) {
      onChange(displayValue?.id ?? null);
    }
  }, [displayValue, currentValue, onChange]);

  return (
    <CmsContainerChangeOfferTypeDialog
      open={currentValue !== null && currentValue !== displayValue?.id}
      onCancel={onRestore}
      onChange={() => onChange(displayValue?.id ?? null)}
    />
  );
};

export default CmsContainerOfferTypeObserver;
