import { AccordionDetails, AccordionSummary, Grid, Slider, Typography } from '@mui/material';
import { FCC } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { ExpandMoreIcon } from '../../../../../icons';
import { EFileSizeMeasure } from '../../../../../types';
import { convertBytesToUnitText } from '../../../../../utils/files';
import { Placeholder, QualityWrapper, Wrapper } from './controls';

interface MPImageCropPreviewProps {
  readonly size?: number;
  readonly quality?: number;
  readonly onChangeQuality?: (quality: number) => void;
}

const MPImageCropPreview: FCC<MPImageCropPreviewProps> = props => {
  return (
    <>
      <Wrapper elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='preview-content'
          id='preview-header'
        >
          <Typography>
            Предпросмотр {props.size && `(${convertBytesToUnitText(props.size, EFileSizeMeasure.KB)}`})
          </Typography>
        </AccordionSummary>
        {props.quality !== undefined && (
          <QualityWrapper>
            <Grid
              container
              spacing={2}
            >
              <Grid item>
                <Typography>Качество:</Typography>
              </Grid>
              <Grid
                item
                xs
              >
                <Slider
                  min={0.5}
                  max={1.0}
                  step={0.01}
                  defaultValue={props.quality}
                  marks
                  getAriaValueText={value => `${value}%`}
                  valueLabelDisplay='on'
                  onChange={(event, value) => props.onChangeQuality?.(value as number)}
                />
              </Grid>
            </Grid>
          </QualityWrapper>
        )}
        <AccordionDetails>{props.children}</AccordionDetails>
      </Wrapper>
      <Placeholder />
    </>
  );
};

export default MPImageCropPreview;
