export enum EConfigurationPrivilegeDictionaryType {
  CorpCategories = 'corpCategories',
  TradeCategories = 'tradeCategories',
  BookingOfferCategories = 'bookingOfferCategories',
  BookingServiceCategories = 'bookingServiceCategories',
  BookingOfferPriceUnits = 'bookingOfferPriceUnits',
}

export enum EConfigurationSportDictionaryType {
  ActivityType = 'activityType',
}
