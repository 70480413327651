import { Address } from '../../../../domain/model/address';
import { Nullable } from '../../../../domain/model/types';

export enum ETournamentApplicationState {
  ShowMembers = 'SHOW_MEMBERS',
}

export interface TournamentApplicationsFilter {
  readonly locality: Nullable<Address>;
  readonly sort: string;
}

export enum ETournamentIndividualViewApplicationsTabs {
  All = 'ALL',
  New = 'NEW',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
}

export type TournamentIndividualViewApplicationsTabsCounter = {
  [name in ETournamentIndividualViewApplicationsTabs]?: number;
};
