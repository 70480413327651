import { Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import { EDateTimeFormat } from '../../../../../domain/model/formats';
import { Nullable } from '../../../../../domain/model/types';
import { MpUserShort } from '../../../../../domain/model/user';
import { Field, FieldsGroup } from '../../../../components/fields';
import { EUserNameRule, getUserFullName } from '../../../../utils';
import { getCustomerDetailsRoute } from '../../../customer/entry';
import { LinkToObject, Wrapper } from './controls';

interface BookingOrderDetailsInfoProps {
  readonly date?: Nullable<string>;
  readonly customer: Nullable<MpUserShort>;
  readonly comment: Nullable<string>;
}

const BookingOrderDetailsInfo = (props: BookingOrderDetailsInfoProps) => {
  const { date, customer, comment } = props;

  if (!customer) {
    return null;
  }

  return (
    <Wrapper>
      <FieldsGroup>
        <Field label={'Клиент'}>
          <LinkToObject
            component={RouterLink}
            to={getCustomerDetailsRoute({ id: customer.id })}
            color='textPrimary'
          >
            {getUserFullName({ user: customer, rule: EUserNameRule.Reverse })}
          </LinkToObject>
        </Field>
        <Field label={'Телефон'}>
          {customer.phone ? (
            <Typography
              component={Link}
              href={`tel:${customer.phone}`}
              color='textPrimary'
            >
              {customer.phone}
            </Typography>
          ) : (
            '-'
          )}
        </Field>
        <Field label={'Электронная почта'}>
          <Typography
            component={Link}
            href={`mailto:${customer.email}`}
            color='textPrimary'
          >
            {customer.email}
          </Typography>
        </Field>
        <Field label={'Время'}>{date ? moment(date).format(EDateTimeFormat.DisplayDefault) : '-'}</Field>
        <Field label={'Комментарий клиента'}>{comment && comment?.length > 0 ? comment : '-'}</Field>
      </FieldsGroup>
    </Wrapper>
  );
};

export default BookingOrderDetailsInfo;
