import { MpChangeCurrentEmailResponse } from '@/data/api/user';
import validateObject from '@/presentation/hooks/validation/utils';
import { EValidationType, validationRegexp, ValidationResult, ValidationRules } from '@/presentation/utils/validation';
import {
  authChangeEmailEmailSelector,
  authChangeEmailIsFetchingSelector,
  authChangeEmailSourceSelector,
  authChangeEmailValidationSelector,
} from '@features/auth/changeEmail/store/selectors';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import {
  authChangeEmailClearValidation,
  authChangeEmailSetEmail,
  authChangeEmailSetValidation,
  authChangeEmailUpdate,
} from '../store/slice';

type EmailValue = { email: string };

type UseUserProfileEmailEdit = {
  readonly onUpdate: () => Promise<boolean>;
  readonly setEmail: (newEmail: string) => void;
  readonly email: string;
  readonly isFetching: boolean;
  readonly source: Nullable<MpChangeCurrentEmailResponse>;
  readonly validation: ValidationResult<EmailValue>;
  readonly setValidation: (result: Nullable<ValidationResult<EmailValue>>) => void;
};

export const emailValidation: ValidationRules<{ email: string }> = {
  email: {
    required: true,
    requiredMessage: 'Необходимо указать корректный адрес электронной почты',
    validator: (object, value: Nullable<string>) => {
      if (!value) return null;

      const isEmail = validator.isEmail(value);
      if (!isEmail || !validationRegexp.email.test(value)) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Необходимо указать корректный адрес электронной почты',
        };
      } else {
        return null;
      }
    },
  },
};

const rules = { email: emailValidation.email };

export const useUserProfileEmailEdit = (): UseUserProfileEmailEdit => {
  const dispatch = useDispatch();
  const email = useSelector(authChangeEmailEmailSelector);
  const source = useSelector(authChangeEmailSourceSelector);
  const validation = useSelector(authChangeEmailValidationSelector);
  const isFetching = useSelector(authChangeEmailIsFetchingSelector);

  const setValidation = (value: Nullable<ValidationResult<EmailValue>>) => {
    dispatch(authChangeEmailSetValidation(value));
  };

  const clearValidation = () => {
    dispatch(authChangeEmailClearValidation());
  };

  const setEmail = (value: string) => {
    clearValidation();
    dispatch(authChangeEmailSetEmail(value));
  };

  const onUpdate = useCallback(() => {
    const validation = validateObject<EmailValue>({ email }, rules);
    const isValid = validation.isValid;

    setValidation(validation.results);

    if (isValid) {
      return dispatch(authChangeEmailUpdate(email))
        .unwrap()
        .then(result => !!result)
        .catch(error => {
          dispatch(
            authChangeEmailSetValidation({
              email: {
                hasError: true,
                type: EValidationType.Error,
                message: error.message || 'Произошла неизвестная ошибка',
              },
            })
          );
          return Promise.resolve(false);
        });
    }
    return Promise.resolve(false);
  }, [email]);

  return {
    email,
    source,
    validation,
    isFetching,
    onUpdate,
    setEmail,
    setValidation,
  };
};
