import { Tooltip as MuiTooltip } from '@mui/material';
import styled from '@emotion/styled';

export const FooterWrapper = styled.div`
  border-top: 1px solid ${p => p.theme.palette.secondary.main};

  padding: ${p => p.theme.spacing(2)};
`;

export const TooltipTitleWrapper = styled.div`
  padding: ${p => p.theme.spacing(3)};
`;

export const Tooltip = styled(MuiTooltip)`
  z-index: ${p => p.theme.zIndex.modal};
`;
