import Axios from 'axios';
import Api from '../../../../data/api';
import {
  ApiCancellable,
  ApiQueryDsl,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
} from '../../../../data/api/types';
import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '../../../../data/api/utils';
import { Pageable } from '../../../../domain/model';
import { EUserStatus } from '../../../../domain/model/enums';
import { Nullable, UUID } from '../../../../domain/model/types';
import { MpUser } from '../../../../domain/model/user';
import { PaginationSize } from '../../../types';
import { EPartnerEmployeesFilterItem, PartnerEmployeesFilterValues } from '../table/filterUtils';
import {
  EPartnerEmployeeTableTab,
  getPartnerEmployeeStatusesByTableTab,
  PartnerEmployeeTableTabsCounter,
} from '../table/utils';

type CommandProps = {
  readonly id: UUID;
};

type ChangePartnerOwnerProps = {
  readonly partnerId: UUID;
  readonly userId: UUID;
};

export interface AllProps extends ApiCancellable {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: string;
    readonly statuses: EUserStatus[];
    readonly partnerId: Nullable<UUID>;
  };
  readonly filter: PartnerEmployeesFilterValues;
  readonly pageNumber: number;
}

export type BuildListQueryParamsReturn<D extends ApiRequestListDiscriminator> = ApiCancellable &
  ApiRequestPageable & {
    readonly partnerId: Nullable<UUID>;
    readonly statuses?: Nullable<EUserStatus[]>;
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

export type CountProps = Omit<AllProps, 'pageNumber'>;

type CountsByTabsProps = CountProps & {
  readonly tabs: EPartnerEmployeeTableTab[];
};

export interface PartnerEmployeeCommonService {
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps
  ) => BuildListQueryParamsReturn<D>;
  readonly all: (props: AllProps) => Promise<Pageable<MpUser>>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly countsByTabs: (props: CountsByTabsProps) => Promise<{
    counts: PartnerEmployeeTableTabsCounter;
    errors: Array<string>;
  }>;
  readonly enabled: (props: CommandProps) => Promise<void>;
  readonly disabled: (props: CommandProps) => Promise<void>;
  readonly changePartnerOwner: (props: ChangePartnerOwnerProps) => Promise<void>;
}

const service: PartnerEmployeeCommonService = {
  buildListQueryParams: props => {
    const { pageNumber, search, filter, signal } = props;
    const query = filter[EPartnerEmployeesFilterItem.Query]?.value;
    const querydsl = getQueryDslByDataFilterValues(filter);
    const { partnerId, pageSize, sort, statuses } = search;

    return {
      page: pageNumber,
      pageSize,
      sort,
      statuses,
      partnerId,
      query,
      querydsl,
      signal,
    };
  },
  all: async props => {
    const response = await Api.partner.employees(service.buildListQueryParams(props));
    const { data } = response;
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);

    return {
      data,
      pageCount,
      totalCount,
      pageNumber: page,
    };
  },
  count: async props => {
    const { data: response } = await Api.partner.employees({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      discriminator: ApiRequestListDiscriminator.Count,
    });

    return response[0].count;
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: PartnerEmployeeTableTabsCounter = {};

    const requests = tabs.map(tab => {
      const statuses = getPartnerEmployeeStatusesByTableTab(tab);

      const params = {
        ...props,
        search: { ...props.search, statuses },
      };
      return service.count({ ...params, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: typeof responses[0], tab: EPartnerEmployeeTableTab) => {
      if (response.status === 'fulfilled') {
        counts[tab] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество партнеров '${tab}': ${response.reason}`);
        }
      }
    };

    tabs.forEach((tab, index) => parseResponse(responses[index], tab));

    return { counts, errors };
  },
  enabled: async ({ id }) => {
    await Api.user.mp.changeStatus({ id, status: EUserStatus.Enabled });
  },
  disabled: async ({ id }) => {
    await Api.user.mp.changeStatus({ id, status: EUserStatus.Disabled });
  },
  changePartnerOwner: async ({ userId, partnerId }) => {
    await Api.partner.setOwner({ partnerId, userId });
  },
};

export default service;
