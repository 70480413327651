import axios from 'axios';
import { useEffect, useState } from 'react';
import Api from '../../../../../data/api';
import { Nullable } from '../../../../../domain/model/types';
import StorageFileLink from '../link';
import { FileInfoAdapterType, FileInfoState } from './types';
import { appendFileInfoExtension } from './utils';

/**
 * адаптер для работы с файлом лежащим на штатном файл-сервере
 * применяется в рамках FileInfo
 */
export const FileInfoStorageAdapter: FileInfoAdapterType = props => {
  const { id, originalName, loadingProgress, children, linkProps, fileExtension } = props;

  const [state, setState] = useState<FileInfoState>({
    isLoading: false,
    name: null,
    size: null,
    error: null,
  });

  useEffect(() => {
    setState({
      ...state,
      isLoading: !!loadingProgress,
    });
  }, [loadingProgress]);

  useEffect(() => {
    if (!id) return;

    const cancelCallback = axios.CancelToken.source();
    const config = {
      cancelToken: cancelCallback.token,
    };

    setState(currentState => ({
      ...currentState,
      isLoading: true,
    }));

    Api.files
      .getInfo(id, config)
      .then(({ data }) => {
        let name: Nullable<string> = '';

        if (fileExtension) {
          if (originalName) {
            name = appendFileInfoExtension(originalName, data.contentType);
          } else if (data.originFileName) {
            name = appendFileInfoExtension(data.originFileName, data.contentType);
          }
        } else {
          name = originalName || data.originFileName || data.name;
        }

        const size: number = data.size;

        setState({
          isLoading: false,
          name,
          size,
          error: null,
        });
      })
      .catch(error => {
        if (axios.isCancel(error)) return;

        console.error('Ошибка при получении информации о файле', error);
        setState({
          isLoading: false,
          name: null,
          size: null,
          error: 'данные файла не получены',
        });
      });

    return () => {
      cancelCallback.cancel();
    };
  }, [id, originalName, fileExtension]);

  return state.error ? (
    <>{children(state)}</>
  ) : (
    <StorageFileLink
      src={id ?? ''}
      target='_blank'
      underline='none'
      {...linkProps}
    >
      {children(state)}
    </StorageFileLink>
  );
};
