import { SportOption } from '../../../../domain/model';
import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterValueItem,
  EDataFilterType,
} from '../../../../domain/model/filter';
import { Nullable } from '../../../../domain/model/types';
import { EChatTableColumn } from './utils';

export enum EChatTableFilterItem {
  Name = 'name',
  ChatTypes = 'types',
}

export const chatTableFilterItems: Record<EChatTableColumn, EChatTableFilterItem[]> = {
  [EChatTableColumn.Logo]: [],
  [EChatTableColumn.Name]: [EChatTableFilterItem.Name, EChatTableFilterItem.ChatTypes],
  [EChatTableColumn.SendDateTime]: [],
  [EChatTableColumn.BodyText]: [],
};

export type ChatTableFilterEditStrategy = DataFilterStrategyBase<EChatTableFilterItem>;
export type ChatTableFilterValues = {
  readonly [EChatTableFilterItem.Name]?: DataFilterValueItem<Nullable<string>>;
  readonly [EChatTableFilterItem.ChatTypes]?: DataFilterValueItem<Nullable<string[]>>;
};

export const getChatTableFilterStrategy = (
  filterItem: EChatTableFilterItem,
  values: ChatTableFilterValues,
  chatTypes: SportOption[]
): Nullable<ChatTableFilterEditStrategy> => {
  switch (filterItem) {
    case EChatTableFilterItem.Name:
      return {
        type: EDataFilterType.String,
        key: EChatTableFilterItem.Name,
        label: 'Чаты',
        preview: values[EChatTableFilterItem.Name]?.value || null,
        value: values[EChatTableFilterItem.Name]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case EChatTableFilterItem.ChatTypes:
      return {
        type: EDataFilterType.ListMultiple,
        key: EChatTableFilterItem.ChatTypes,
        label: 'Тип чата',
        preview: `Тип чата (${values[EChatTableFilterItem.ChatTypes]?.value?.length})`,
        previews: chatTypes
          .filter(t => values[EChatTableFilterItem.ChatTypes]?.value?.some(v => v === t.id))
          .map(t => t.name),
        value: values[EChatTableFilterItem.ChatTypes]?.value || null,
        items: chatTypes.map(t => ({ id: t.id, name: t.name })),
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
        },
      };
  }
};
