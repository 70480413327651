import { SportOptionTyped } from '@/domain/model';
import { ECorpPersonalPromotionType } from '@/domain/model/enums';
import CorpActivationTableCell, { CorpActivationTableCellCommonProps } from './index';

export type CorpActivationTableCellPromotionTypeProps = CorpActivationTableCellCommonProps & {
  readonly corpPromotionTypes: SportOptionTyped<ECorpPersonalPromotionType>[];
};

const CorpActivationTableCellPromotionType = (props: CorpActivationTableCellPromotionTypeProps) => {
  const {
    corpActivation: {
      promotion: { type },
    },
    corpPromotionTypes,
  } = props;

  const value = corpPromotionTypes.find(item => item.id === type)?.name ?? '-';
  return (
    <CorpActivationTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpActivationTableCellPromotionType;
