import { FormHelperText, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import SectionTitle from '../../../../../components/common/sectionTitle';
import { MPMenuItem } from '../../../../../theme/ui-kit/menu/item';
import { MPSelect } from '../../../../../theme/ui-kit/select';
import { CmsContainerViewResourceSize } from '../../types';
import { cmsBannerSizes1, cmsBannerSizes2, cmsBannerSizes3 } from '../../utils/constants';
import { CmsContainerCreateComponentProps } from '../types';

type CmsContainerEditComponentBannerSizeVariantProps = CmsContainerCreateComponentProps;

type CmsContainerEditComponentBannerSizeProps = CmsContainerEditComponentBannerSizeVariantProps & {
  readonly sizes: CmsContainerViewResourceSize[];
};

const CmsContainerEditComponentBannerSize = (props: CmsContainerEditComponentBannerSizeProps) => {
  const { sizes, cmsContainer, validation, onChange } = props;

  const value = sizes.find(size => size.desktop.join() === cmsContainer.resourcesSize?.join()) ?? '';

  return (
    <Grid
      container
      spacing={1}
      direction='column'
    >
      <Grid item>
        <SectionTitle>Размер баннера</SectionTitle>
      </Grid>
      <Grid item>
        <MPSelect<CmsContainerViewResourceSize>
          label='Размер баннера'
          value={value}
          helperText={validation?.resourcesSize?.message}
          error={!!validation?.resourcesSize?.hasError}
          onChange={size => onChange('resourcesSize', size.desktop)}
        >
          {sizes.map(item => (
            <MPMenuItem
              key={item.name}
              value={item}
            >
              <Typography variant='body1'>{item.name}</Typography>
            </MPMenuItem>
          ))}
        </MPSelect>
        {cmsContainer.resourcesSize && !value && (
          <FormHelperText error>
            Ранее выбранный размер баннера не поддерживается, необходимо выбрать актуальный вариант
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  );
};

const CmsContainerEditComponentBannerSize3 = (props: CmsContainerEditComponentBannerSizeVariantProps) => {
  return (
    <CmsContainerEditComponentBannerSize
      sizes={cmsBannerSizes3}
      {...props}
    />
  );
};

const CmsContainerEditComponentBannerSize2 = (props: CmsContainerEditComponentBannerSizeVariantProps) => {
  return (
    <CmsContainerEditComponentBannerSize
      sizes={cmsBannerSizes2}
      {...props}
    />
  );
};

const CmsContainerEditComponentBannerSize1 = (props: CmsContainerEditComponentBannerSizeVariantProps) => {
  return (
    <CmsContainerEditComponentBannerSize
      sizes={cmsBannerSizes1}
      {...props}
    />
  );
};

export {
  CmsContainerEditComponentBannerSize1,
  CmsContainerEditComponentBannerSize2,
  CmsContainerEditComponentBannerSize3,
};
