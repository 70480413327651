import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import { TradeOfferTableCellStatusProps } from '../../components/tableCell/status';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { tradeOffersTradeOfferByIndexSelector, tradeOffersUnwatchedByIndexSelector } from '../store/selectors';
import { TradeOffersTableCellAdapterProps } from './cell';

type TradeOffersTableCellStatusAdapterProps = Omit<TradeOffersTableCellAdapterProps, 'component'> & {
  readonly component: FC<TradeOfferTableCellStatusProps>;
};

const TradeOffersTableCellStatusAdapter = (props: TradeOffersTableCellStatusAdapterProps) => {
  const { index, component: Component } = props;

  const handlers = useContextHandlers();
  const tradeOffer = useSelector(tradeOffersTradeOfferByIndexSelector(index));
  const isUnwatched = tradeOffer ? useSelector(tradeOffersUnwatchedByIndexSelector(index)) : false;
  const isDisabled = tradeOffer && !isUnwatched ? handlers.isTradeOfferNotModeratedByMe(tradeOffer) : false;

  const { offerStatuses } = useSelector(nsiDataSelector);

  return useMemo(
    () =>
      tradeOffer ? (
        <Component
          tradeOffer={tradeOffer}
          offerStatuses={offerStatuses}
          isDisabled={isDisabled}
          isTextAccent={isUnwatched}
        />
      ) : null,
    [Component, tradeOffer, isDisabled, isUnwatched, offerStatuses]
  );
};

export default TradeOffersTableCellStatusAdapter;
