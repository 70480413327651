import TradeActivationTableCell, { TradeActivationTableCellCommonProps } from './index';

const TradeActivationTableCellCustomerPhone = (props: TradeActivationTableCellCommonProps) => {
  const {
    tradeActivation: {
      customer: { phone },
    },
  } = props;

  return (
    <TradeActivationTableCell
      {...props}
      value={phone ?? ''}
    />
  );
};

export default TradeActivationTableCellCustomerPhone;
