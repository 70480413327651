import { ListItemIcon, ListItemText } from '@mui/material';
import { forwardRef, isValidElement, ReactNode } from 'react';
import { DictionaryItemContentProps } from '../../types';
import { EndAdornmentWrapper, ListItem, ListItemContainer } from './controls';

export * from './common';
export * from './singleSelect';
export * from './multipleSelect';
export * from './edit';

type DictionaryItemConstructorProps<T> = DictionaryItemContentProps<T> & {
  readonly startAdornment?: ReactNode;
  readonly content: ReactNode;
  readonly endAdornment?: ReactNode;
};

const DictionaryItemConstructorInternal = <T extends object>(props: DictionaryItemConstructorProps<T>, ref: any) => {
  const { data, onClick, selected, startAdornment, content, endAdornment } = props;
  return (
    <ListItemContainer
      ref={ref}
      active={!!selected}
    >
      <ListItem
        disableRipple
        data-id={data.id}
        onClick={onClick}
      >
        {startAdornment && isValidElement(startAdornment) && <ListItemIcon>{startAdornment}</ListItemIcon>}
        <ListItemText primary={content} />
        {endAdornment && isValidElement(endAdornment) && <EndAdornmentWrapper>{endAdornment}</EndAdornmentWrapper>}
      </ListItem>
    </ListItemContainer>
  );
};

export const DictionaryItemConstructor = forwardRef(DictionaryItemConstructorInternal);
