import { ruRU } from '@mui/x-date-pickers';
import { MPTheme } from '../types';

const key = Object.keys(ruRU.components)[0];
const localeText = ruRU.components.MuiLocalizationProvider.defaultProps.localeText;
type Props = typeof localeText;

const additionalLocaleText: Props = {
  clearButtonLabel: 'Очистить',
  cancelButtonLabel: 'Отменить',
  okButtonLabel: 'Применить',
  fieldDayPlaceholder: () => '',
  fieldMonthPlaceholder: () => '',
  fieldYearPlaceholder: () => '',
  fieldSecondsPlaceholder: () => '',
};

export function applyPickersOverrides(theme: MPTheme): MPTheme {
  return {
    ...theme,
    components: {
      ...theme.components,
      [key]: {
        defaultProps: {
          localeText: {
            ...localeText,
            ...additionalLocaleText,
          },
        },
      },
    },
  };
}
