import styled from '@emotion/styled';
import { viewConfig } from '../../../../theme/viewConfig';

export const TopPushContentWrapper = styled.div`
  height: calc(100vh - 9rem);
`;

export const TitleWrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing()};

  padding-bottom: ${p => p.theme.spacing(1.5)};

  border-bottom: 1px solid ${p => p.theme.palette.disabled.background};
`;
