import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { EPartnerOwnershipType } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { addSearchParamToLocation } from '../../../../../routing/globalRouting';
import { EPartnerUrlParam } from '../../../partner/entry';
import useStepper, { UseStepper } from '../../stepper/useStepper';
import { EPartnerStep, PartnerStep } from '../types';
import { getPartnerSteps } from '../utils/common';

type UsePartnerStepper = UseStepper<EPartnerStep, PartnerStep>;

interface UsePartnerStepperProps {
  readonly currentStepKey: EPartnerStep;
  readonly orgType?: Nullable<EPartnerOwnershipType>;
  readonly canChangePermissions?: boolean;
}

const usePartnerStepper = (props: UsePartnerStepperProps): UsePartnerStepper => {
  const { currentStepKey, orgType = null, canChangePermissions } = props;

  const history = useHistory();
  const location = useLocation();

  const steps: PartnerStep[] = useMemo(
    () => getPartnerSteps(orgType, canChangePermissions),
    [orgType, canChangePermissions]
  );

  const openStep = useCallback(
    (step: PartnerStep) => {
      history.push(
        addSearchParamToLocation({
          location,
          param: EPartnerUrlParam.Step,
          value: step.key,
        })
      );
    },
    [history, location]
  );

  return useStepper<EPartnerStep, PartnerStep>({ currentStepKey, steps, openStep });
};

export default usePartnerStepper;
