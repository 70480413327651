import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../data/store/store';
import { ESortDirection } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { MpUser } from '../../../../../domain/model/user';
import { EPartnerEmployeeTableColumn } from '../utils';

export const partnerEmployeesGuidSelector = (store: RootState) => store.employee.list.guid;
export const partnerEmployeesIsFetchingSelector = (store: RootState) => store.employee.list.isFetching;
export const partnerEmployeesEmployeesSelector = (store: RootState) => store.employee.list.data;
export const partnerEmployeesCounterSelector = (store: RootState) => store.employee.list.tabsCounter;
export const partnerEmployeesPageNumberSelector = (store: RootState) => store.employee.list.pageNumber;
export const partnerEmployeesPageCountSelector = (store: RootState) => store.employee.list.pageCount;
export const partnerEmployeesTotalCountSelector = (store: RootState) => store.employee.list.totalCount;

export const partnerEmployeesSearchSelector = (store: RootState) => store.employee.list.search;
export const partnerEmployeesFilterSelector = (store: RootState) => store.employee.list.filter;
export const partnerEmployeesNeedRefreshWatcherSelector = (store: RootState) => store.employee.list.needRefreshWatcher;
export const partnerEmployeesActionsSelector = (store: RootState) => store.employee.list.actions;
export const partnerEmployeesDialogsSelector = (store: RootState) => store.employee.list.dialogs;

export const partnerEmployeesColumnSelector = (store: RootState) =>
  (store.employee.list.search.sort?.split(',') as [EPartnerEmployeeTableColumn, ESortDirection])?.[0] ?? '';
export const partnerEmployeesSortDirectionSelector = (store: RootState) =>
  (store.employee.list.search.sort?.split(',') as [EPartnerEmployeeTableColumn, ESortDirection])?.[1] ?? '';

const partnerEmployeesEmployeeIndexSelector = (store: RootState, index: number) => index;
const createPartnerEmployeesEmployeeByIndexSelector = createSelector(
  partnerEmployeesEmployeesSelector,
  partnerEmployeesEmployeeIndexSelector,
  (employees, index): Nullable<MpUser> => employees?.[index] ?? null
);

export const partnerEmployeesEmployeeByIndexSelector = (index: number) => (store: RootState) =>
  createPartnerEmployeesEmployeeByIndexSelector(store, index);
