import Api from '@/data/api';
import { AllProps as ApiAllProps } from '@/data/api/customer';
import { ApiCancellable, ApiRequestListDiscriminator } from '@/data/api/types';
import {
  getComServicesEndpoint,
  getPageableFromResponseHeaders,
  getQueryDslByDataFilterValues,
} from '@/data/api/utils';
import { Pageable } from '@/domain/model';
import { EUserStatus } from '@/domain/model/enums';
import { DataFilterQueryDslOperator } from '@/domain/model/filter';
import { ClientOrgUser } from '@/domain/model/user';
import Axios from 'axios';
import { PaginationSize } from '../../../types';
import { ClientOrgUserTableFilterValues } from '../userClientOrg/filterUtils';
import { ClientOrgUserTableTabsCounter, getStatesFilterForClientOrgUserTableTab } from '../userClientOrg/table/utils';
import { EClientOrgUserTableTab } from '../userClientOrg/types';
import { fileTypes } from '@/presentation/constants';

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string>;
    readonly statuses: EUserStatus[];
    readonly onlyLoggedIn: Nullable<boolean>;
  };
  readonly filter: ClientOrgUserTableFilterValues;
  readonly pageNumber: number;
  readonly clientOrgId?: Nullable<UUID>;
};

export type CountProps = Omit<AllProps, 'pageNumber'>;

export type ImportProps = {
  clientOrgId: UUID;
  file: File;
};

export type DownloadProps = {
  clientOrgId: UUID;
};

type CountsByTabsProps = CountProps & {
  readonly tabs: EClientOrgUserTableTab[];
};

export type UserClientOrgService = {
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps
  ) => ApiAllProps<D>;
  readonly all: (props: AllProps) => Promise<Pageable<ClientOrgUser>>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly import: (props: ImportProps) => Promise<string>;
  readonly downloadUrl: (props: DownloadProps) => string;
  readonly countsByTabs: (
    props: CountsByTabsProps
  ) => Promise<{ counts: ClientOrgUserTableTabsCounter; errors: Array<string> }>;
};

const service: UserClientOrgService = {
  buildListQueryParams: ({ search, filter, pageNumber, signal, clientOrgId }) => {
    const { pageSize, sort, statuses, onlyLoggedIn } = search;
    const { query } = filter;
    const querydsl = getQueryDslByDataFilterValues(filter) ?? [];

    /**
     * если lastLoggedInAt
     * не null, значит прошел регистрацию
     * null, то приглашение отправлено, но юзер не прошел регистрацию сам
     */
    if (onlyLoggedIn === true) {
      querydsl.push({
        field: 'lastLoggedInAt',
        operator: DataFilterQueryDslOperator.NotEquals,
        value: [null],
      });
    } else if (onlyLoggedIn === false) {
      querydsl.push({
        field: 'lastLoggedInAt',
        operator: DataFilterQueryDslOperator.In,
        value: [null],
      });
    }

    return {
      page: pageNumber,
      pageSize,
      sort,
      query: query?.value,
      querydsl,
      statuses,
      signal,
      clientOrgId,
    };
  },
  all: async props => {
    const response = await Api.customer.all(service.buildListQueryParams(props));
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);
    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
  count: async props => {
    const { data: response } = await Api.customer.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      discriminator: ApiRequestListDiscriminator.Count,
    });

    return response[0].count;
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: ClientOrgUserTableTabsCounter = {};

    const requests = tabs.map(tab => {
      const statuses = getStatesFilterForClientOrgUserTableTab(tab);

      let onlyLoggedIn: Nullable<boolean> = null;
      switch (tab) {
        case EClientOrgUserTableTab.Enabled:
          onlyLoggedIn = true;
          break;
        case EClientOrgUserTableTab.Invited:
          onlyLoggedIn = false;
          break;
        case EClientOrgUserTableTab.Disabled:
          onlyLoggedIn = null;
          break;
      }

      const params: Omit<AllProps, 'pageNumber'> = {
        ...props,
        search: { ...props.search, statuses, onlyLoggedIn },
      };
      return service.count({ ...params, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], tab: EClientOrgUserTableTab) => {
      if (response.status === 'fulfilled') {
        counts[tab] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество администраторов '${tab}': ${response.reason}`);
        }
      }
    };

    tabs.forEach((tab, index) => parseResponse(responses[index], tab));

    return { counts, errors };
  },
  import: async ({ clientOrgId, file }) => {
    const { data } = await Api.clientOrgUser.import({ clientOrgId, file });

    return URL.createObjectURL(new Blob([data], { type: fileTypes.xls.mimeType }));
  },
  downloadUrl: ({ clientOrgId }) => {
    return `${getComServicesEndpoint()}/client-orgs/${clientOrgId}/users/export`;
  },
};

export default service;
