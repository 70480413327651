import { Tab } from '@mui/material';
import { EPartnerDetailsTab } from '../utils';
import { Tabs } from './controls';

interface PartnerDetailsTabsProps {
  readonly tab: EPartnerDetailsTab;
  readonly onChangeTab: (tab: EPartnerDetailsTab) => void;
}

const PartnerDetailsTabs = ({ tab, onChangeTab }: PartnerDetailsTabsProps) => {
  return (
    <>
      <Tabs
        onChange={(event, newTab) => onChangeTab(newTab)}
        value={tab}
        textColor='primary'
      >
        <Tab
          label='Юридическая информация'
          value={EPartnerDetailsTab.Legal}
        />
        <Tab
          label='Карточка компании'
          value={EPartnerDetailsTab.Desk}
        />
        <Tab
          label='Уведомления покупателям'
          value={EPartnerDetailsTab.Notifications}
        />
      </Tabs>
    </>
  );
};

export default PartnerDetailsTabs;
