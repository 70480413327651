import { UUID } from '../../../../domain/model/types';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import TableCommonLayout from '../../../layouts/tableCommon/container';
import { ETeamTableFilterItem } from '../filterUtils';
import { TeamsBehaviorAdapter } from './adapters/behavior';
import TeamsTableFilterAdapter from './adapters/filter';
import TeamsTableHeaderAdapter from './adapters/header';
import TeamsTableAdapter from './adapters/table';
import { TeamTableTabsAdapter } from './adapters/tabs';
import { TeamsTableContext } from './context';
import TeamTableFooterContainer from './footerContainer';
import { useTeamsTableHandlers } from './hooks/useTeamsTable';
import { ETeamTableColumn, ETeamTableTab } from './utils';

interface TeamsTableContainerProps {
  readonly guid: UUID;
  readonly filterAdapter: DataFilterAdapter<ETeamTableColumn, ETeamTableFilterItem>;
  readonly canCreate?: boolean;
  readonly tab: ETeamTableTab;
}

const TeamsTableContainer = ({ guid, filterAdapter, canCreate, tab }: TeamsTableContainerProps) => {
  const handlers = useTeamsTableHandlers({ tab });

  return (
    <TeamsTableContext.Provider value={handlers}>
      <TeamsBehaviorAdapter
        guid={guid}
        tab={tab}
      />
      <TableCommonLayout
        header={<TeamsTableHeaderAdapter canCreate={canCreate} />}
        filter={<TeamsTableFilterAdapter filterAdapter={filterAdapter} />}
        tabs={<TeamTableTabsAdapter tab={tab} />}
        table={<TeamsTableAdapter filterAdapter={filterAdapter} />}
        footer={TeamTableFooterContainer}
      />
    </TeamsTableContext.Provider>
  );
};

export default TeamsTableContainer;
