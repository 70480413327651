import { AxiosResponse } from 'axios';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { webAnalytics } from '../../../webAnalytics';

type OneProps = {
  readonly id: UUID;
};

export type CommonOfferService = {
  readonly makeViewed: (props: OneProps) => Promise<void>;
  readonly downloadCodes: (props: OneProps) => Promise<Nullable<AxiosResponse<string>>>;
};

const service: CommonOfferService = {
  makeViewed: async ({ id }) => {
    webAnalytics.offerView(id);
    return Promise.resolve();
  },
  downloadCodes: async ({ id }) => {
    return await Api.personalPromotion
      .downloadCodes({ offerId: id })
      .then(result => result)
      .catch(error => {
        ErrorHandler.handleHttpError(error, error.response);
        return null;
      });
  },
};

export default service;
