import { FileDescription } from '@/domain';
import DataTableCellImage from '@components/common/table/cell/image';
import { DataTableCellImageWrapper } from '@features/banner/components/tableCell/controls';
import { BannerTableCellCommonProps } from '.';

export type BannersTableCellImageProps = BannerTableCellCommonProps & {
  readonly image: FileDescription;
};

const BannerTableCellImage = (props: BannersTableCellImageProps) => {
  const { image } = props;

  return (
    <DataTableCellImageWrapper>
      <DataTableCellImage
        src={image.path}
        height='100%'
        width='100%'
      />
    </DataTableCellImageWrapper>
  );
};

export default BannerTableCellImage;
