import axios, { AxiosResponse } from 'axios';
import { BonusTransaction } from '../../domain/model';
import { UUID } from '../../domain/model/types';
import { UserBonusesBalance } from '../../domain/model/user';
import { createCancelToken } from './index';
import { ApiCancellable, ApiRequestPageable, ApiResponseWithPageable } from './types';
import { getBonusesEndpoint, getSportBaseEndpoint } from './utils';

type InfoProps = ApiCancellable & {
  readonly id: UUID;
  readonly cache?: boolean;
};

type TransactionsProps = ApiRequestPageable &
  ApiCancellable & {
    readonly id: UUID;
  };

type BonusApi = {
  readonly balance: (props: InfoProps) => Promise<AxiosResponse<UserBonusesBalance>>;
  readonly transactions: (
    props: TransactionsProps
  ) => Promise<AxiosResponse<ApiResponseWithPageable<BonusTransaction>>>;
};

/**
 * АПИ по работе с бонусами
 */
const bonus: BonusApi = {
  balance: ({ id, cache = true, signal }) => {
    const params = new URLSearchParams();
    params.append('cache', cache.toString());

    return axios.get(`${getBonusesEndpoint()}/users/${id}/balance`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },

  transactions: ({ id, page, pageSize, sort, signal }) => {
    const params = new URLSearchParams();
    params.append('userProfileId', id);

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    return axios.get(`${getSportBaseEndpoint()}/bonuses/transactions`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
};

export default bonus;
