import { MpUser, Nullable, Partner } from '@/domain';
import { CustomersLocationState, ECustomerUrlParam } from '@features/customer/entry';
import { getCustomerManagementByPartnerDetailsRoute } from '@features/customer/management/byPartner/entry';
import CustomerTableEntry from '@features/customer/table/entry';
import { ECustomerTableTab } from '@features/customer/table/utils';
import { viewHideMainMenu } from '@features/main/container/store/slice';
import { viewShowPartnerManagementMenu } from '@features/partner/management/container/store/slice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import ForbiddenComponent from '../../components/common/forbidden';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface PartnerManagementScreenCustomersScreenProps {
  readonly partner: Partner;
}

const PartnerManagementCustomersScreen = ({ partner }: PartnerManagementScreenCustomersScreenProps) => {
  const dispatch = useDispatch();
  const location = useLocation<Nullable<CustomersLocationState>>();
  const history = useHistory();
  const { customers } = useCurrentUser().accessMatrix;

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(ECustomerUrlParam.Tab) as ECustomerTableTab;

  const onCustomerClick = (customer: MpUser) => {
    history.push(
      getCustomerManagementByPartnerDetailsRoute({
        customerId: customer.id,
        partnerId: partner.id,
      })
    );
  };

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowPartnerManagementMenu());
  }, [dispatch]);

  if (!customers?.view?.list) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<CustomersLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <CustomerTableEntry
          guid={state.guid}
          partnerId={partner.id}
          tab={tab}
          onCustomerClick={onCustomerClick}
        />
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default PartnerManagementCustomersScreen;
