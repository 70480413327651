import { Grid } from '@mui/material';
import moment from 'moment-timezone';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { EDateFormat, EDateTimeFormat } from '../../../../../domain/model/formats';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';
import FileDownloadLink from '../../../../components/common/files/download/link';
import { MPButton } from '../../../../theme/ui-kit/button';
import { MPDatePicker } from '../../../../theme/ui-kit/picker';
import { offerExportAccept } from '../../../general/offer/constants';
import { getBookingOrdersDownloadUrl } from '../../utils/common';
import { bookingOrderActionsDialogByNameSelector } from '../store/selectors';
import { useBookingOrderActions } from '../useAction';

const OrdersActionsDialogDownloadAdapter = () => {
  const handlers = useBookingOrderActions();

  const defaultDateFrom = moment().startOf('year').format(EDateTimeFormat.Server);
  const defaultDateTo = moment().format(EDateTimeFormat.Server);

  const [date, setDate] = useState<[string, string]>([defaultDateFrom, defaultDateTo]);
  const params = useSelector(bookingOrderActionsDialogByNameSelector('download'));

  const onCloseDialog = useCallback(
    () => handlers.onChangeDialogState({ name: 'download', data: null }),
    [handlers.onChangeDialogState]
  );

  if (params === null) {
    return null;
  }

  const downLoadUrl = getBookingOrdersDownloadUrl({
    ...params,
    date,
  });

  return (
    params &&
    downLoadUrl && (
      <ConfirmDialog
        open
        onClose={onCloseDialog}
        title='Выгрузка заказов Досуга и отдыха'
        dialogButtons={
          <Grid
            container
            spacing={2}
          >
            <Grid item>
              <FileDownloadLink
                src={downLoadUrl}
                accept={offerExportAccept}
                fileName={`заказы_объектов_с_${moment(date[0]).format(EDateFormat.FileName)}_до_${moment(
                  date[1]
                ).format(EDateFormat.FileName)}`}
                component={'div'}
                onDownload={onCloseDialog}
              >
                <MPButton fullWidth={false}>Выгрузить</MPButton>
              </FileDownloadLink>
            </Grid>
            <Grid item>
              <MPButton
                fullWidth={false}
                variant='outlined'
                onClick={onCloseDialog}
              >
                Отмена
              </MPButton>
            </Grid>
          </Grid>
        }
      >
        <Grid
          container
          spacing={2}
          wrap='nowrap'
        >
          <Grid item>
            <MPDatePicker
              value={date[0]}
              label={'Начало'}
              disableFuture
              onChange={value => {
                const d = value === null ? defaultDateFrom : moment(value).format(EDateTimeFormat.Server);
                setDate([d, date[1]]);
              }}
            />
          </Grid>
          <Grid item>
            <MPDatePicker
              value={date[1]}
              label={'Окончание'}
              disableFuture
              onChange={value => {
                const d = value === null ? defaultDateTo : moment(value).format(EDateTimeFormat.Server);
                setDate([date[0], d]);
              }}
            />
          </Grid>
        </Grid>
      </ConfirmDialog>
    )
  );
};

export default OrdersActionsDialogDownloadAdapter;
