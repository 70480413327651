import {
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterValueItem,
  EDataFilterType,
  EOfferType,
  Nullable,
} from '@/domain';

export enum EReportPartnersFilterItem {
  DatePeriod = 'datePeriod',
  OfferType = 'offerType',
  Partner = 'partner',
}

export type ReportPartnersFilterEditStrategy = DataFilterStrategyBase<EReportPartnersFilterItem>;

export type ReportPartnersFilterValues = {
  readonly [EReportPartnersFilterItem.DatePeriod]?: DataFilterValueItem<Nullable<[Nullable<string>, Nullable<string>]>>;
  readonly [EReportPartnersFilterItem.OfferType]?: DataFilterValueItem<Nullable<EOfferType[]>>;
  readonly [EReportPartnersFilterItem.Partner]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem>>;
};

export const getReportPartnersFilterStrategies = (
  values: ReportPartnersFilterValues
): ReportPartnersFilterEditStrategy[] => {
  const strategies: ReportPartnersFilterEditStrategy[] = [];
  strategies.push({
    type: EDataFilterType.DatePeriod,
    key: EReportPartnersFilterItem.DatePeriod,
    label: '',
    labelPeriod: {
      from: 'Период с',
      to: 'по',
    },
    buttonsPeriod: true,
    preview: null,
    value: values[EReportPartnersFilterItem.DatePeriod]?.value || null,
  });

  return strategies;
};
