import LikesIcon from '@mui/icons-material/Favorite';
import { FileDescription } from '../../../../../domain/model';
import { Nullable } from '../../../../../domain/model/types';
import { EEntityPreviewMode } from '../../../../types';
import OfferPreview from '../../../general/offer/components/preview';
import { OfferPreviewSkeleton } from '../../../general/offer/components/preview/controls';
import { bookingOfferImageRestrictions } from '../../create/utils';
import { BookingOfferPreviewWrapper } from './controls';
import BookingOfferPreviewFooter from './footer';
import BookingOfferPreviewPrice from './price';

interface BookingOfferPreviewProps {
  readonly mode?: EEntityPreviewMode;
  readonly name: Nullable<string>;
  readonly address: Nullable<string>;
  readonly serviceUnit: Nullable<string>;
  readonly servicePrice: Nullable<number>;
  readonly image: Nullable<FileDescription>;
  readonly logo: Nullable<string | FileDescription>;
  readonly border?: boolean;
}

const BookingOfferPreview = ({
  mode = EEntityPreviewMode.Desktop,
  name,
  address,
  servicePrice,
  serviceUnit,
  image,
  logo,
  border,
}: BookingOfferPreviewProps) => {
  const imageRatio =
    bookingOfferImageRestrictions.recommendedWidthInPx / bookingOfferImageRestrictions.recommendedHeightInPx;

  return (
    <BookingOfferPreviewWrapper mode={mode}>
      <OfferPreview
        mode={mode}
        border={border}
        image={image}
        imageRatio={imageRatio}
        description={
          name ? (
            name
          ) : (
            <OfferPreviewSkeleton
              animation={false}
              variant='rectangular'
              width='100%'
              height='1.375rem'
            />
          )
        }
        header={
          <BookingOfferPreviewPrice
            mode={mode}
            price={servicePrice}
            unit={serviceUnit}
          />
        }
        info={
          <BookingOfferPreviewFooter
            mode={mode}
            address={address}
            logo={logo}
          />
        }
        favorite={<LikesIcon color='disabled' />}
      />
    </BookingOfferPreviewWrapper>
  );
};

export default BookingOfferPreview;
