import { Nullable, UUID } from '@/domain';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import TableCommonLayout from '../../../layouts/tableCommon/container';
import TableLoaderCommonLayout from '../../../layouts/tableLoaderCommon/container';
import { CorpOfferLifeCycle } from '../../general/offer/lifecycle/types';
import { EOfferTableTab } from '../../general/offer/types';
import { getOfferTableTabActions } from '../../general/offer/utils/table';
import { ECorpOfferTableFilterItem } from '../filterUtils';
import { CorpOfferTableStubAdapterType, ECorpOfferTableColumn } from '../types';
import CorpOffersTableFilterAdapter from './adapters/filter';
import CorpOffersTableHeaderAdapter from './adapters/header';
import { CorpOffersTableNotifierAdapter } from './adapters/notifier';
import CorpOffersTableAdapter from './adapters/table';
import { CorpOffersTableTabsAdapter } from './adapters/tabs';
import { CorpOffersTableContext } from './context';
import CorpOfferTableDialogs from './dialogs';
import CorpOfferTableFooterContainer from './footerContainer';
import { useCorpOffersTableHandlers } from './hooks/useCorpOffersTable';
import useCorpOffersTableCount from './hooks/useCount';
import useCorpOffersTableSearch from './hooks/useSearch';
import useCorpOffersTableSession from './hooks/useSession';
import { corpOffersGuidSelector, corpOffersIsPreloadingSelector } from './store/selectors';

interface CorpOfferTableContainerProps {
  readonly guid: UUID;
  readonly partnerId: Nullable<UUID>;
  readonly tab: EOfferTableTab;
  readonly lifecycle: CorpOfferLifeCycle;
  readonly filterAdapter: DataFilterAdapter<ECorpOfferTableColumn, ECorpOfferTableFilterItem>;
  readonly stubAdapter: CorpOfferTableStubAdapterType;
  readonly canCreate?: boolean;
  readonly canDelete?: boolean;
  readonly canApprove?: boolean;
  readonly canPause?: boolean;
  readonly canResume?: boolean;
  readonly canArchive?: boolean;
  readonly canReject?: boolean;
  readonly canViewApprovalRegistry?: boolean;
  readonly canChangeApprovalRegistry?: boolean;
  readonly canDownload?: boolean;
  readonly canUpload?: boolean;
  readonly canChangeModerator?: boolean;
  readonly canDuplicate?: boolean;
  readonly canRetrieve?: boolean;
}

const CorpOfferTableContainer = (props: CorpOfferTableContainerProps) => {
  const {
    guid,
    partnerId,
    tab,
    lifecycle,
    filterAdapter,
    stubAdapter: StubAdapter,
    canCreate,
    canDelete,
    canApprove,
    canResume,
    canPause,
    canArchive,
    canReject,
    canChangeApprovalRegistry,
    canDownload,
    canUpload,
    canChangeModerator,
    canDuplicate,
    canRetrieve,
  } = props;

  const handlers = useCorpOffersTableHandlers({ tab });

  const currentGuid = useSelector(corpOffersGuidSelector);

  useCorpOffersTableSession({ guid, tab });
  useCorpOffersTableSearch({ tab, partnerId });
  useCorpOffersTableCount({ partnerId });

  const tableTabActions = getOfferTableTabActions(
    {
      canDelete,
      canApprove,
      canReject,
      canResume,
      canPause,
      canArchive,
      canChangeApprovalRegistry,
      canChangeModerator,
      canDuplicate,
      canRetrieve,
    },
    tab
  );

  if (guid !== currentGuid) {
    return null;
  }

  return (
    <CorpOffersTableContext.Provider value={handlers}>
      <TableLoaderCommonLayout selector={corpOffersIsPreloadingSelector}>
        <StubAdapter>
          {({ canViewTable, isCreateDisabled, createInfo, stub }) => (
            <TableCommonLayout
              canViewTable={canViewTable}
              header={
                <CorpOffersTableHeaderAdapter
                  tab={tab}
                  partnerId={partnerId}
                  createInfo={createInfo}
                  isCreateDisabled={isCreateDisabled}
                  canCreate={canCreate}
                  canDownload={canDownload && canViewTable}
                  canUpload={canUpload && canViewTable}
                />
              }
              filter={<CorpOffersTableFilterAdapter filterAdapter={filterAdapter} />}
              tabs={<CorpOffersTableTabsAdapter tab={tab} />}
              table={
                <CorpOffersTableAdapter
                  lifecycle={lifecycle}
                  filterAdapter={filterAdapter}
                  tabActions={tableTabActions}
                />
              }
              stub={stub}
              footer={forwardRef((props, ref: any) => (
                <CorpOfferTableFooterContainer
                  ref={ref}
                  tab={tab}
                  allowedActions={tableTabActions}
                  lifecycle={lifecycle}
                />
              ))}
            >
              <CorpOffersTableNotifierAdapter />
              <CorpOfferTableDialogs />
            </TableCommonLayout>
          )}
        </StubAdapter>
      </TableLoaderCommonLayout>
    </CorpOffersTableContext.Provider>
  );
};

export default CorpOfferTableContainer;
