import Api from '../../../../data/api';
import { EUserRole, EUserStatus } from '../../../../domain/model/enums';
import { ApiCancellable, ApiResponseWithPageable } from '../../../../data/api/types';
import { SportUserProfile } from '../../../../domain/model/user';
import { SportOption } from '../../../../domain/model';

type CreatorsProps = ApiCancellable & {
  readonly name: string;
};

export type PlaygroundUsersService = {
  readonly moderators: (props: CreatorsProps) => Promise<ApiResponseWithPageable<SportUserProfile>>;
  readonly creators: (props: CreatorsProps) => Promise<SportOption[]>;
  readonly searchCreators: (search: string) => Promise<SportOption[]>;
};

export const playgroundUsersService: PlaygroundUsersService = {
  // Метод для получения модераторов
  moderators: async ({ name }) => {
    const res = await Api.user.sport.all({
      page: 0,
      pageSize: 99999,
      name,
      sort: 'lastName,asc',
      roles: [EUserRole.EventAdmin, EUserRole.SystemAdmin, EUserRole.ComplaintAdmin],
      statuses: [EUserStatus.Enabled, EUserStatus.NotFilled, EUserStatus.Disabled, EUserStatus.Deleted],
    });

    return res.data;
  },

  // Метод для получения инициаторов
  creators: async props => (await Api.playground.creators(props)).data,

  searchCreators: async (search: string) => (await Api.playground.creators({ name: search })).data,
};