import { Grid } from '@mui/material';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';
import { MPButton } from '../../../../theme/ui-kit/button';

interface CmsContainerChangeOfferTypeDialogProps {
  readonly open: boolean;
  readonly onCancel: () => void;
  readonly onChange: () => void;
}

const CmsContainerChangeOfferTypeDialog = (props: CmsContainerChangeOfferTypeDialogProps) => {
  const { open, onCancel, onChange } = props;

  return (
    <ConfirmDialog
      open={open}
      title='Уверены, что хотите изменить вид предложения?'
      text='При изменеии вида предложения все шаги сбросятся'
      onClose={onCancel}
      dialogButtons={
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              onClick={onChange}
            >
              Да, изменить
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              fullwidth={false}
              variant='outlined'
              onClick={onCancel}
            >
              Отменить
            </MPButton>
          </Grid>
        </Grid>
      }
    />
  );
};

export default CmsContainerChangeOfferTypeDialog;
