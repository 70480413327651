import { Checkbox, FormControlLabel, FormHelperText, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Nullable } from '../../../../../../domain/model/types';
import { MPButton } from '../../../../../theme/ui-kit/button';
import ConfirmDialog from '../../../dialogs/confirm';
import { DataTableMetadata, TableColumn } from '../../index';

interface DataTableMetadataDialogProps<C extends string> {
  readonly open: boolean;
  readonly metadata: DataTableMetadata<C>;
  readonly onChange: (metadata: DataTableMetadata<C>) => void;
  readonly onClose: () => void;
}

interface DataTableMetadataColumnVisibleProps {
  readonly column: TableColumn;
  readonly onChangeVisible: (visible: boolean) => void;
}

const DataTableMetadataColumnVisible = (props: DataTableMetadataColumnVisibleProps) => {
  const { column, onChangeVisible } = props;
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={!column.hidden}
          color='primary'
          onChange={(event, checked) => onChangeVisible(checked)}
        />
      }
      label={<Typography>{column.title}</Typography>}
    />
  );
};

const DataTableMetadataDialog = <C extends string>(props: DataTableMetadataDialogProps<C>) => {
  const { open, metadata, onChange, onClose } = props;

  const [localMetadata, setLocalMetadata] = useState<DataTableMetadata<C>>(metadata);
  const [error, setError] = useState<Nullable<string>>(null);

  const onChangeInternal = () => {
    const columns = Object.entries<TableColumn>(localMetadata.columns as Record<C, TableColumn>);
    const notVisibleColumnCount = columns.filter(([, column]) => column.hidden).length;

    if (notVisibleColumnCount === columns.length) {
      setError('Необходимо выбрать минимум 1 столбец');
    } else {
      onChange(localMetadata);
      onClose();
    }
  };

  const onChangeColumnVisible = (key: C, column: TableColumn, visible: boolean) => {
    const columns = { ...(localMetadata.columns as Record<C, TableColumn>) };
    columns[key] = {
      ...columns[key],
      hidden: !visible,
    };
    setLocalMetadata({
      ...localMetadata,
      columns,
    });
  };

  useEffect(() => {
    setLocalMetadata(metadata);
  }, [metadata]);

  useEffect(() => {
    if (open) {
      setLocalMetadata(metadata);
    }
  }, [open]);

  useEffect(() => {
    setError(null);
  }, [localMetadata]);

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title='Настройка состава таблицы'
      dialogButtons={
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              onClick={onChangeInternal}
            >
              Сохранить
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        direction='column'
      >
        {Object.entries(localMetadata.columns).map(([key, column]) =>
          column ? (
            <Grid
              item
              key={key}
            >
              <DataTableMetadataColumnVisible
                column={column as TableColumn}
                onChangeVisible={visible => onChangeColumnVisible(key as C, column as TableColumn, visible)}
              />
            </Grid>
          ) : null
        )}

        {error && (
          <>
            <Grid item />
            <Grid item>
              <FormHelperText error>{error}</FormHelperText>
            </Grid>
          </>
        )}
      </Grid>
    </ConfirmDialog>
  );
};

export default DataTableMetadataDialog;
