import { RootState } from '../../../../../../data/store/store';

export const corpOfferCategoriesSelectDataSelector = (store: RootState) =>
  store.dictionary.corpOfferCategory.select.fetch.data;

export const corpOfferCategoriesSelectIsPreloadingSelector = (store: RootState) =>
  store.dictionary.corpOfferCategory.select.fetch.isFetching;

export const corpOfferCategoriesSelectIsLoadingSelector = (store: RootState) =>
  store.dictionary.corpOfferCategory.select.fetch.isFetching;

export const corpOfferCategoriesSelectSelectedSelector = (store: RootState) =>
  store.dictionary.corpOfferCategory.select.selected;

export const corpOfferCategoriesSelectSelectedItemsSelector = (store: RootState) =>
  store.dictionary.corpOfferCategory.select.selectedItems;
