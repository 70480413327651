import { combineReducers } from '@reduxjs/toolkit';
import table, { OfferHistoryTableState } from './table/store/slice';

interface OfferHistoryState {
  table: OfferHistoryTableState;
}

export default combineReducers<OfferHistoryState>({
  table,
});
