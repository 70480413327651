import moment from 'moment';
import Api from '../../../../../data/api';
import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterStrategyView,
  DataFilterValueItem,
  EDataFilterType,
  EDateFormat,
  EOfferStatus,
  EPrivilegeActorType,
  EUserRole,
  Nullable,
  pageSizeAll,
  SportOptionTyped,
} from '@/domain';
import { getUserFullName } from '../../../../utils';
import { DataTableColumns } from '@components/common/table';
import { EOfferFieldHistoryTableColumn } from '@features/offerHistory/table/utils/common';

export enum EOfferFieldHistoryFilterItem {
  UpdatedAt = 'updatedAt',
  Field = 'field.name',
  OldValue = 'oldValue',
  NewValue = 'newValue',
  UpdatedBy = 'updatedBy.id',
  ActorType = 'actorType',
}

export type OfferFieldHistoryFilterEditStrategy = DataFilterStrategyBase<EOfferFieldHistoryFilterItem>;
export type OfferFieldHistoryFilterViewStrategy = DataFilterStrategyView<EOfferFieldHistoryFilterItem>;
export type OfferFieldHistoryFilterValues = {
  readonly [EOfferFieldHistoryFilterItem.UpdatedAt]?: DataFilterValueItem<Nullable<[string, string]>>;
  readonly [EOfferFieldHistoryFilterItem.Field]?: DataFilterValueItem<Nullable<string>>;
  readonly [EOfferFieldHistoryFilterItem.OldValue]?: DataFilterValueItem<Nullable<string>>;
  readonly [EOfferFieldHistoryFilterItem.NewValue]?: DataFilterValueItem<Nullable<string>>;
  readonly [EOfferFieldHistoryFilterItem.ActorType]?: DataFilterValueItem<Nullable<string[]>>;
  readonly [EOfferFieldHistoryFilterItem.UpdatedBy]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
};
export const getOfferFieldHistoryFilterStrategies = (
  values: OfferFieldHistoryFilterValues,
  offerStatuses: SportOptionTyped<EOfferStatus>[],
  actorTypes: SportOptionTyped<EPrivilegeActorType>[]
): OfferFieldHistoryFilterEditStrategy[] => {
  return [
    {
      type: EDataFilterType.DateAsTimePeriod,
      key: EOfferFieldHistoryFilterItem.UpdatedAt,
      label: 'Дата',
      preview:
        moment(values[EOfferFieldHistoryFilterItem.UpdatedAt]?.value?.[0]).format(EDateFormat.DisplayDefault) || null,
      value: values[EOfferFieldHistoryFilterItem.UpdatedAt]?.value || null,
      querydsl: {
        operator: DataFilterQueryDslOperator.Between,
      },
    },
    {
      type: EDataFilterType.String,
      key: EOfferFieldHistoryFilterItem.Field,
      label: 'Название поля',
      preview: values[EOfferFieldHistoryFilterItem.Field]?.value || null,
      value: values[EOfferFieldHistoryFilterItem.Field]?.value || null,
      querydsl: {
        operator: DataFilterQueryDslOperator.Like,
      },
    },
    {
      type: EDataFilterType.String,
      key: EOfferFieldHistoryFilterItem.OldValue,
      label: 'Старое значение',
      preview: values[EOfferFieldHistoryFilterItem.OldValue]?.value || null,
      value: values[EOfferFieldHistoryFilterItem.OldValue]?.value || null,
      querydsl: {
        operator: DataFilterQueryDslOperator.Like,
      },
    },
    {
      type: EDataFilterType.String,
      key: EOfferFieldHistoryFilterItem.NewValue,
      label: 'Новое значение',
      preview: values[EOfferFieldHistoryFilterItem.NewValue]?.value || null,
      value: values[EOfferFieldHistoryFilterItem.NewValue]?.value || null,
      querydsl: {
        operator: DataFilterQueryDslOperator.Like,
      },
    },
    {
      type: EDataFilterType.AutocompleteMultiple,
      key: EOfferFieldHistoryFilterItem.UpdatedBy,
      label: 'Пользователь',
      value: values[EOfferFieldHistoryFilterItem.UpdatedBy]?.value || null,
      preview: `Пользователь (${values[EOfferFieldHistoryFilterItem.UpdatedBy]?.value?.length})` || null,
      previews: values[EOfferFieldHistoryFilterItem.UpdatedBy]?.value?.map(t => t.name) || null,
      source: (name: string) =>
        name
          ? Api.user.mp
              .all({
                query: name,
                page: 1,
                pageSize: pageSizeAll,
                sort: ['lastName,asc', 'firstName,asc', 'middleName,asc'],
                roles: [EUserRole.AdminPartner, EUserRole.AdminMp],
              })
              .then(response => response.data.map(item => ({ ...item, name: getUserFullName({ user: item }) })))
          : Promise.resolve([]),
      querydsl: {
        operator: DataFilterQueryDslOperator.In,
        valueAttribute: 'id',
      },
    },
    {
      type: EDataFilterType.ListMultiple,
      key: EOfferFieldHistoryFilterItem.ActorType,
      label: 'Роль',
      preview: `Роль (${values[EOfferFieldHistoryFilterItem.ActorType]?.value?.length})`,
      previews: actorTypes
        .filter(t => values[EOfferFieldHistoryFilterItem.ActorType]?.value?.some(v => v === t.id))
        .map(t => t.name),
      value: values[EOfferFieldHistoryFilterItem.ActorType]?.value || null,
      items: actorTypes.map(t => ({ id: t.id, name: t.name })),
      querydsl: {
        operator: DataFilterQueryDslOperator.In,
      },
    },
  ];
};

export const getOfferFieldHistoryColumns = (): DataTableColumns<EOfferFieldHistoryTableColumn> => ({
  [EOfferFieldHistoryTableColumn.UpdatedAt]: {
    title: 'Дата',
    width: '5rem',
  },
  [EOfferFieldHistoryTableColumn.UpdatedAtTime]: {
    title: 'Время',
    width: '4rem',
  },
  [EOfferFieldHistoryTableColumn.Field]: {
    title: 'Название поля',
    width: '10rem',
    wrap: true,
  },
  [EOfferFieldHistoryTableColumn.OldValue]: {
    title: 'Старое значение поля',
    width: '15rem',
    wrap: true,
  },
  [EOfferFieldHistoryTableColumn.NewValue]: {
    title: 'Новое значение поля',
    width: '15rem',
    wrap: true,
  },
  [EOfferFieldHistoryTableColumn.ActorType]: {
    title: 'Роль',
    width: '10rem',
  },
  [EOfferFieldHistoryTableColumn.UpdatedBy]: {
    title: 'Пользователь',
    width: '10rem',
  },
});
