import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { ServerErrorResponse } from '../../../../../data/network/types';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { EUserStatus } from '../../../../../domain/model/enums';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { MpUser } from '../../../../../domain/model/user';

export const partnerEmployeeDetailsByIdFetch = createAsyncThunk<MpUser, { id: UUID }, AppThunkAPIConfig>(
  'partner/employee/details/byId/fetch',
  async ({ id }, { rejectWithValue, signal }) => {
    try {
      const { data } = await Api.user.mp.one({ id, signal });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export const partnerEmployeeDetailsChangeUserStatus = createAsyncThunk<
  MpUser,
  { id: UUID; status: EUserStatus },
  AppThunkAPIConfig<ServerErrorResponse>
>('partner/employee/details/changeStatus', async ({ id, status }, { signal, rejectWithValue }) => {
  try {
    await Api.user.mp.changeStatus({ id, status });
    const { data: userData } = await Api.user.mp.one({ id, signal });
    return userData;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface PartnerEmployeeDetailsState {
  readonly byId: Fetchable & {
    readonly user: Nullable<MpUser>;
  };
  readonly changeStatus: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<PartnerEmployeeDetailsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PartnerEmployeeDetailsState> {
  partnerEmployeeDetailsStateReset: Reducer;
}

const slice = createSlice<PartnerEmployeeDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    byId: {
      ...fetchableDefault,
      user: null,
    },
    changeStatus: {
      ...fetchableDefault,
    },
  },
  reducers: {
    partnerEmployeeDetailsStateReset: state => {
      state.byId = {
        ...fetchableDefault,
        user: null,
      };
      state.changeStatus = {
        ...fetchableDefault,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(partnerEmployeeDetailsByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.user = null;
      })
      .addCase(partnerEmployeeDetailsByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.user = payload;
      })
      .addCase(partnerEmployeeDetailsByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        }

        state.byId.user = null;
      })
      .addCase(partnerEmployeeDetailsChangeUserStatus.pending, state => {
        state.changeStatus.isFetching = true;
        state.changeStatus.isFetched = false;
        state.changeStatus.isFailed = false;
      })
      .addCase(partnerEmployeeDetailsChangeUserStatus.fulfilled, (state, { payload }) => {
        state.changeStatus.isFetching = false;
        state.changeStatus.isFetched = true;
        state.changeStatus.isFailed = false;
        state.byId.user = payload;
      })
      .addCase(partnerEmployeeDetailsChangeUserStatus.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.changeStatus.isFetching = false;
          state.changeStatus.isFetched = false;
          state.changeStatus.isFailed = true;
        }
      });
  },
});

export const { partnerEmployeeDetailsStateReset } = slice.actions;

export default slice.reducer;
