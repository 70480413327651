import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BookingOfferShort } from '../../../../../domain/model/booking';
import { EBookingActionType } from '../../types';
import BookingActionsConfirmDialog from '../components/confirmDialog';
import {
  bookingOfferActionsDialogRetrieveSelector,
  getBookingOfferActionsOfferIsProcessingSelector,
} from '../store/selectors';
import { bookingOfferActionsRetrieve } from '../store/slice';
import { useBookingOfferActions } from '../useActions';
import { showBookingOfferActionNotification } from '../utils';

const BookingOfferActionsDialogRetrieveAdapter = () => {
  const dispatch = useDispatch();

  const handlers = useBookingOfferActions();

  const object = useSelector(bookingOfferActionsDialogRetrieveSelector);

  const isProcessing = useSelector(getBookingOfferActionsOfferIsProcessingSelector(object?.id ?? null));

  const onRetrieve = useCallback(
    (offer: BookingOfferShort) => {
      dispatch(
        bookingOfferActionsRetrieve({
          id: offer.id,
        })
      )
        .unwrap()
        .then(response => {
          showBookingOfferActionNotification(response, EBookingActionType.Retrieve, false);
          handlers.onChangeDialogState('retrieve', null);
        });
    },
    [dispatch, handlers.onChangeDialogState]
  );

  const onCloseDialog = useCallback(() => {
    handlers.onChangeDialogState('retrieve', null);
  }, [handlers.onChangeDialogState]);

  return (
    object && (
      <BookingActionsConfirmDialog
        offer={object}
        title='Вернуть в предыдущий статус?'
        actionText='Да, вернуть'
        onAction={offer => onRetrieve(offer)}
        isFetching={isProcessing}
        onClose={onCloseDialog}
      />
    )
  );
};

export default BookingOfferActionsDialogRetrieveAdapter;
