import { Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ContentTarget } from '../../../../../../domain/model';
import { EMultiSelectorValueType } from '../../../../../../domain/model/enums';
import { OrgUnit } from '../../../../../../domain/model/orgStructure';
import { Nullable } from '../../../../../../domain/model/types';
import OrgStructureOrgUnitMultipleTypesSelector from '../../../../../components/orgStructure/orgUnit/selector/multipleTypes';
import { OrgStructureOrgUnitMultipleSelectType } from '../../../../../components/orgStructure/orgUnit/selector/multipleTypes/types';
import { ContentTargetEditProps } from '../../types';

const attribute: keyof ContentTarget = 'orgUnits';

interface ContentTargetEditOrgUnitsProps extends ContentTargetEditProps {
  readonly label?: string | false;
}

const ContentTargetEditOrgUnits = (props: ContentTargetEditOrgUnitsProps) => {
  const { target, label = 'Выберите филиалы', topUsed, validation, onChangeAttribute } = props;

  const topUsedOrgUnits = topUsed?.orgUnits;

  const targetOrgUnits = target[attribute] ?? { select: EMultiSelectorValueType.In, in: null };

  // значение orgUnits может быть
  // 1) null
  // 2) { select: EMultiSelectorValueType.All, in: null }
  // 3) { select: EMultiSelectorValueType.In, in: newValue }, где newValue.length > 0
  const onChange = (newValue: Nullable<OrgUnit[]>) => {
    if (newValue?.length) {
      onChangeAttribute(attribute, { select: EMultiSelectorValueType.In, in: newValue });
    } else {
      onChangeAttribute(attribute, null);
    }
  };

  const onSelectAll = () => {
    onChangeAttribute(attribute, { select: EMultiSelectorValueType.All, in: null });
  };

  return (
    <>
      {label && (
        <Typography
          variant='body2'
          gutterBottom
        >
          {label}
        </Typography>
      )}
      <OrgStructureOrgUnitMultipleTypesSelector
        label='Филиал ОАО «РЖД»'
        color='primary'
        select={
          targetOrgUnits.select === EMultiSelectorValueType.All ? OrgStructureOrgUnitMultipleSelectType.All : null
        }
        orgUnits={targetOrgUnits.in ?? []}
        defaultSource={topUsedOrgUnits}
        limitTags={2}
        helperText={validation?.[attribute]?.message}
        error={!!validation?.[attribute]?.hasError}
        popupIcon={<ExpandMoreIcon fontSize='small' />}
        onChange={onChange}
        onSelect={onSelectAll}
      />
    </>
  );
};

export default ContentTargetEditOrgUnits;
