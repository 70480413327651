import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Partner } from '../../../../../domain/model/partner';
import { MpUser } from '../../../../../domain/model/user';
import useHistoryExtensions from '../../../../hooks/useHistoryExtensions';
import MenuPanelDrawerLayout from '../../../../layouts/menuPanel';
import theme from '../../../../theme';
import { getPartnerManagementCustomersRoute } from '../../../partner/management/entry';
import { getCustomersTableRoute } from '../../entry';
import { CustomerManagementMenuHeaderAdapter } from './adapter/header';
import { CustomerManagementMenuItemsAdapter } from './adapter/items';
import { ECustomerManagementMenuType } from './enums';
import { customerManagementMenuGuidSelector } from './store/selectors';

interface CustomerManagementMenuContainerProps {
  readonly visible: boolean;
  readonly customer: MpUser;
  readonly partner?: Partner;
}

const CustomerManagementMenuContainer = (props: CustomerManagementMenuContainerProps) => {
  const { visible, customer, partner } = props;

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const guid = useSelector(
    customerManagementMenuGuidSelector(
      partner ? ECustomerManagementMenuType.ByPartner : ECustomerManagementMenuType.Common
    )
  );

  const onBack = useCallback(() => {
    if (partner?.id) {
      gotoPrevIndependentLocation(getPartnerManagementCustomersRoute({ id: partner.id }));
    } else {
      gotoPrevIndependentLocation(getCustomersTableRoute({}));
    }
  }, [gotoPrevIndependentLocation, partner?.id]);

  return (
    <MenuPanelDrawerLayout
      visible={visible}
      guid={guid}
      backgroundColor={theme.palette.success.alpha90}
      header={
        <CustomerManagementMenuHeaderAdapter
          customer={customer}
          onBack={onBack}
        />
      }
    >
      <CustomerManagementMenuItemsAdapter
        customer={customer}
        partner={partner}
      />
    </MenuPanelDrawerLayout>
  );
};

export default CustomerManagementMenuContainer;
