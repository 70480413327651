import { Typography } from '@mui/material';
import { useMemo } from 'react';
import Splitter from '../../../../components/common/splitter';
import useBusinessConfig from '../../../../hooks/useBusinessConfig';
import { convertStringToArray } from '../../../../utils';
import SupportContacts from '../../components/contacts';

const SupportInfoMpContactsAdapter = () => {
  const {
    mp: { phones, emails },
  } = useBusinessConfig().supportInfo;

  const mpPhones = convertStringToArray(phones);
  const mpEmails = convertStringToArray(emails);

  return useMemo(() => {
    return mpPhones.length + mpEmails.length > 0 ? (
      <>
        <Typography variant='h3'>Контакты</Typography>
        <Splitter
          variant='horizontal'
          size={2}
        />
        <SupportContacts
          emails={mpEmails}
          phones={mpPhones}
        />
      </>
    ) : null;
  }, [mpPhones, mpEmails]);
};

export default SupportInfoMpContactsAdapter;
