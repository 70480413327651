import { useReportDownloads } from '@features/report/downloads/useDownloads';
import { EReportType } from '@features/report/types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { OffersStatisticReportContainer } from '../reports/offersStatistic';
import { reportDownloadsReportsSelector } from '../store/selectors';

export const ReportDownloadsOffersStatisticAdapter = () => {
  const handlers = useReportDownloads();

  const { offer_statistic: data } = useSelector(reportDownloadsReportsSelector);

  const onClose = useCallback(() => {
    handlers.onCloseDownloadDialog(EReportType.OffersStatistic);
  }, [handlers.onCloseDownloadDialog]);

  return (
    data && (
      <OffersStatisticReportContainer
        partnerId={data.partnerId}
        onClose={onClose}
      />
    )
  );
};
