import Api from '../../../../../data/api';
import { ApiCancellable } from '../../../../../data/api/types';
import { getPageableFromResponseHeaders } from '../../../../../data/api/utils';
import { OfferCategory } from '../../../../../domain/model';
import { EOfferStatus } from '../../../../../domain/model/enums';
import { UUID } from '../../../../../domain/model/types';

type DataType = OfferCategory;

type CreateProps = DataType;

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type AllProps = ApiCancellable & {
  readonly onlyUsed?: boolean;
  readonly partnerId?: UUID;
  readonly statuses?: EOfferStatus[];
};

type UpdateProps = {
  readonly id: UUID;
  readonly data: DataType;
};

type DeleteProps = {
  readonly id: UUID;
};

type GetRelationShipsCountProps = ApiCancellable & {
  readonly id: UUID;
};

type TradeOfferCategoriesService = {
  readonly create: (props: CreateProps) => Promise<DataType>;
  readonly all: (props: AllProps) => Promise<DataType[]>;
  readonly one: (props: OneProps) => Promise<DataType>;
  readonly update: (props: UpdateProps) => Promise<DataType>;
  readonly delete: (props: DeleteProps) => Promise<void>;
  readonly getRelationShipsCount: (props: GetRelationShipsCountProps) => Promise<number>;
};

const service: TradeOfferCategoriesService = {
  create: async data => {
    const { data: result } = await Api.category.tradeOffer.create(data);
    return result;
  },
  all: async ({ onlyUsed, statuses, partnerId, signal }) => {
    if (onlyUsed) {
      const { data: result } = await Api.category.tradeOffer.allUsed({
        partnerId,
        statuses,
        onlyLeafCategories: false,
        signal,
      });
      return result;
    } else {
      const { data: result } = await Api.category.tradeOffer.all({ signal });
      return result;
    }
  },
  one: async ({ id, signal }) => {
    const { data: result } = await Api.category.tradeOffer.one({ categoryId: id, signal });
    return result;
  },
  update: async ({ id, data }) => {
    const { data: result } = await Api.category.tradeOffer.update({ categoryId: id, data });
    return result;
  },
  delete: async ({ id }) => {
    const { data: result } = await Api.category.tradeOffer.delete({ categoryId: id });
    return result;
  },
  getRelationShipsCount: async ({ id, signal }) => {
    const statuses: EOfferStatus[] = [
      EOfferStatus.WaitingForModeration,
      EOfferStatus.OnModeration,
      EOfferStatus.Upcoming,
      EOfferStatus.Active,
      EOfferStatus.Paused,
    ];
    const linkedOffersResponse = await Api.tradeOffer.all({
      page: 1,
      pageSize: 0,
      categories: [id],
      statuses,
      signal,
    });
    return getPageableFromResponseHeaders(linkedOffersResponse).totalCount;
  },
};

export default service;
