import { ReportsTableCell, ReportsTableCellCommonProps } from '@features/report/components/tableCell/index';

export const ReportsTableCellDescription = (props: ReportsTableCellCommonProps) => {
  const {
    report: { description: value = '-' },
  } = props;

  return (
    <ReportsTableCell
      {...props}
      value={value}
    />
  );
};
