import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '@/data/network/errorHandler';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { AdCampaignShort, Nullable, Pageable, UUID } from '@/domain';
import { PaginationSize } from '../../../../types';
import adCampaignServices, { AdCampaignAllProps } from '../../services';
import { AdCampaignTableFilterValues } from '../../filterUtils';
import { EAdCampaignTableColumn, EAdCampaignTableTab } from '../../types';
import { AdCampaignTableTabsCounter } from '@features/adCampaign/table/utils';

const defaultSort = `${EAdCampaignTableColumn.Name},asc`;

export interface AdCampaignListState extends Fetchable, Pageable<AdCampaignShort> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly totalCount: number;
  readonly pageCount: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
    readonly partnerId: Nullable<UUID>;
  };
  readonly tab: Nullable<EAdCampaignTableTab>;
  readonly tabsCounter: AdCampaignTableTabsCounter;
  readonly filter: AdCampaignTableFilterValues;
  readonly allAdCampaigns: Fetchable & {
    readonly count: Nullable<number>;
    readonly needRefreshWatcher: number;
  };
}

type Reducer<T = undefined> = CaseReducer<AdCampaignListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<AdCampaignListState> {
  adCampaignDataReset: Reducer;
  adCampaignSetTab: Reducer<{ tab: EAdCampaignTableTab }>;
  adCampaignStartSession: Reducer<{ guid: UUID }>;
  adCampaignSetFilter: Reducer<AdCampaignTableFilterValues>;
  adCampaignSortReset: Reducer;
  adCampaignSetSort: Reducer<{ sort: string }>;
  adCampaignNeedRefreshWatcherReset: Reducer;
  adCampaignNeedRefreshWatcherIncrement: Reducer;
  adCampaignSetPage: Reducer<{ pageNumber: number }>;
  adCampaignSetPageSize: Reducer<{ pageSize: PaginationSize }>;
}

export type AdCampaignsFetchProps = Omit<AdCampaignAllProps, 'signal'>;
export type AdCampaignsCountsFetchProps = AdCampaignsFetchProps & {
  readonly tabs: EAdCampaignTableTab[];
};

export const adCampaignFetch = createAsyncThunk<Pageable<AdCampaignShort>, AdCampaignsFetchProps, AppThunkAPIConfig>(
  'adCampaign/list/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await adCampaignServices.all({ ...props, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const adCampaignCountFetch = createAsyncThunk<number, AdCampaignsFetchProps, AppThunkAPIConfig>(
  'adCampaign/list/count/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await adCampaignServices.count({ ...props, signal });
    } catch (e: any) {
      console.error(e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const adCampaignCountsFetch = createAsyncThunk<
  AdCampaignTableTabsCounter,
  AdCampaignsCountsFetchProps,
  AppThunkAPIConfig
>('adCampaign/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await adCampaignServices.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      errors.map(e => {
        console.error(e);
      });
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

const slice = createSlice<AdCampaignListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    guid: null,
    needRefreshWatcher: 0,
    pageNumber: 1,
    totalCount: 0,
    pageCount: 0,
    tab: null,
    data: [],
    tabsCounter: {},
    filter: {},
    ...fetchableDefault,
    search: {
      sort: defaultSort,
      pageSize: 10,
      partnerId: null,
    },
    allAdCampaigns: {
      ...fetchableDefault,
      count: null,
      needRefreshWatcher: 0,
    },
  },
  reducers: {
    adCampaignStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;

        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = false;

        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;
        state.needRefreshWatcher = 0;

        state.search = {
          sort: defaultSort,
          pageSize: 10,
          partnerId: null,
        };

        state.allAdCampaigns = {
          ...fetchableDefault,
          count: null,
          needRefreshWatcher: 0,
        };

        state.tab = null;
      }
    },
    adCampaignSetTab: (state, { payload }) => {
      const { tab } = payload;
      // сбрасываем пейджинг и сортировку, если поменялись статусы (закладки)
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    adCampaignSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    adCampaignDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
    },
    adCampaignSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    adCampaignSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    adCampaignNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
      state.allAdCampaigns.needRefreshWatcher = 0;
    },
    adCampaignNeedRefreshWatcherIncrement: state => {
      state.needRefreshWatcher++;
    },
    adCampaignSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    adCampaignSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(adCampaignFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;

        if (partnerId !== state.search.partnerId) {
          state.data = [];
        }
        state.search.partnerId = partnerId;
      })
      .addCase(adCampaignFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(adCampaignFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(adCampaignCountsFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        if (partnerId !== state.search.partnerId) {
          state.tabsCounter = {};
        }
      })
      .addCase(adCampaignCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(adCampaignCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      })
      .addCase(adCampaignCountFetch.pending, state => {
        state.allAdCampaigns.isFetching = true;
        state.allAdCampaigns.isFetched = false;
        state.allAdCampaigns.isFailed = false;

        if (state.allAdCampaigns.count === 0) {
          //сбрасываем в null в том случае если до этого ничего не было, чтобы не моргала таблица лишний раз
          state.allAdCampaigns.count = null;
        }
      })
      .addCase(adCampaignCountFetch.fulfilled, (state, { payload }) => {
        state.allAdCampaigns.isFetching = false;
        state.allAdCampaigns.isFetched = true;
        state.allAdCampaigns.isFailed = false;

        state.allAdCampaigns.count = payload;
      })
      .addCase(adCampaignCountFetch.rejected, state => {
        state.allAdCampaigns.isFetching = false;
        state.allAdCampaigns.isFetched = false;
        state.allAdCampaigns.isFailed = true;

        state.allAdCampaigns.count = null;
      });
  },
});
export const {
  adCampaignStartSession,
  adCampaignSetTab,
  adCampaignSetFilter,
  adCampaignDataReset,
  adCampaignSetPage,
  adCampaignNeedRefreshWatcherReset,
  adCampaignSortReset,
  adCampaignSetPageSize,
  adCampaignSetSort,
  adCampaignNeedRefreshWatcherIncrement,
} = slice.actions;

export default slice.reducer;
