import { useAuthentication } from '@mp-npm/mp-auth-client';
import { StompClientProvider } from '@mp-npm/mp-stomp-client';
import { useCallback } from 'react';
import { getComWsServicesEndpoint, getSportWsServicesEndpoint } from '../../../../data/api/utils';
import { UserAccessMatrix } from '../../../../domain/model/accessMatrix';
import { ENoticeStatus, EPartnerStatus, EUserStatus } from '../../../../domain/model/enums';
import { AppUserSpecific, MpPartnerUserProfile } from '../../../../domain/model/user';
import { AppConfigurator } from '../../../../system/appConfigurator';
import Notifier from '../../../../system/notifier';
import SideContainer from '../../general/side/container';
import AppNotificationsReceiver from '../../notification';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import { WebAnalyticsInitializer } from '../../webAnalytics';
import { Wrapper } from './controls';
import MainEntryCommon from './entry/common';
import MainEntryPartnerApplication from './entry/partnerApplication';
import MainEntryUserCreate from './entry/userCreate';

interface MainEntryProps {
  readonly user: AppUserSpecific;
  readonly mpPartnerUserProfile?: MpPartnerUserProfile;
  readonly accessMatrix: UserAccessMatrix;
  readonly logOut: () => void;
}

const MainEntry = ({ user, accessMatrix, mpPartnerUserProfile, logOut }: MainEntryProps) => {
  const { isAdminPartner } = accessMatrix;

  if (user.status === EUserStatus.NotFilled) {
    return <MainEntryUserCreate />;
  }

  if (isAdminPartner) {
    const partner = mpPartnerUserProfile?.partner ?? null;
    if (!partner || [EPartnerStatus.Disabled, EPartnerStatus.Deleted].includes(partner.status)) {
      Notifier.getInstance().addNotice(
        ENoticeStatus.Error,
        'Не найдено действующего партнёра. Обратитесь в поддержку.'
      );
      setTimeout(logOut, 5000);

      return null;
    }
    if (partner.status === EPartnerStatus.Unverified) {
      return <MainEntryPartnerApplication partner={partner} />;
    }
  }

  return <MainEntryCommon />;
};

const MainContainer = () => {
  const { authService } = useAuthentication();
  const { userSpecific: user, mpPartnerUserProfile, accessMatrix, logOut } = useCurrentUser();

  const wsEndpoint = accessMatrix.isAdminSport ? getSportWsServicesEndpoint() : getComWsServicesEndpoint();

  const apiWs = AppConfigurator.getInstance().getApiWs();

  const onStompConnectionFailure = useCallback((message: Nullable<string>) => {
    Notifier.getInstance().addNotice(
      ENoticeStatus.Error,
      `Ошибка подключения websocket: ${message ?? 'неизвестная ошибка'}`
    );
  }, []);

  return (
    <Wrapper>
      <StompClientProvider
        endpoint={wsEndpoint}
        wsPath={apiWs?.path}
        sockjsPath={apiWs?.sockjsPath}
        debug={AppConfigurator.getInstance().isDebug()}
        getToken={() => authService.token!}
        onConnectionFailure={onStompConnectionFailure}
      >
        <WebAnalyticsInitializer />
        <AppNotificationsReceiver />
        <MainEntry
          user={user}
          mpPartnerUserProfile={mpPartnerUserProfile}
          accessMatrix={accessMatrix}
          logOut={logOut}
        />
        <SideContainer />
      </StompClientProvider>
    </Wrapper>
  );
};

export default MainContainer;
