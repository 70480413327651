import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const ClientOrgOption = styled.li`
  margin-right: ${p => p.theme.spacing()};
`;

export const TextOption = styled(Typography)`
  padding: ${p => p.theme.spacing(1, 2.5, 1, 2.5)};
`;
