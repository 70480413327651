import ErrorHandler from '@/data/network/errorHandler';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { ClientOrg, ClientOrgCreate, Nullable } from '@/domain';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { ValidationItemResult, ValidationResult } from '../../../../utils/validation';
import clientOrgServices from '../../services';
import { ClientOrgCreateStepType } from '../../types';
import { ClientOrgCreateUiState } from '../types';
import { convertClientOrgToClientOrgCreate, createEmptyClientOrgCreate } from '../utils';

export const clientOrgCreateByIdFetch = createAsyncThunk<Nullable<ClientOrg>, Nullable<UUID>, AppThunkAPIConfig>(
  'clientOrg/create/byId/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      if (id) {
        return await clientOrgServices.clientOrg.one({ id, signal });
      } else {
        return null;
      }
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export type ClientOrgCreateValidationStepper = Nullable<
  Record<ClientOrgCreateStepType, Nullable<ValidationResult<any>>>
>;

export interface ClientOrgCreateState {
  readonly guid: Nullable<UUID>;
  readonly byId: Fetchable & {
    readonly clientOrg: ClientOrgCreate;
    readonly loadedData: Nullable<ClientOrg>;
  };
  readonly modified: boolean;

  readonly validation: ValidationResult<ClientOrgCreate>;
  readonly validationStepper: ClientOrgCreateValidationStepper;

  readonly ui: Nullable<ClientOrgCreateUiState>;

  readonly dialogs: {
    readonly feedback: Nullable<ClientOrgCreate>;
    readonly close: Nullable<ClientOrgCreate>;
    readonly discard: Nullable<ClientOrgCreate>;
  };
}

type Reducer<T = undefined> = CaseReducer<ClientOrgCreateState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ClientOrgCreateState> {
  clientOrgCreateStartSession: Reducer<{
    guid: Nullable<UUID>;
  }>;
  clientOrgCreateSetModified: Reducer<boolean>;
  clientOrgCreateClearActionsState: Reducer;
  clientOrgCreateSetValidationStepper: Reducer<
    Nullable<Record<ClientOrgCreateStepType, Nullable<ValidationResult<any>>>>
  >;
  clientOrgCreateClearAllValidations: Reducer;

  clientOrgCreateSetAttribute: Reducer<{
    name: keyof ClientOrgCreate;
    value: any;
  }>;
  clientOrgCreateSetUiState: Reducer<{
    name: keyof ClientOrgCreateUiState;
    value: any;
  }>;
  clientOrgCreateClearAttributeValidation: Reducer<keyof ClientOrgCreate>;
  clientOrgCreateSetAttributeValidation: Reducer<{
    name: keyof ClientOrgCreate;
    results: Nullable<ValidationItemResult>;
  }>;
  clientOrgCreateSetValidation: Reducer<Nullable<ValidationResult<ClientOrgCreate>>>;

  clientOrgCreateApplySavedClientOrg: Reducer<ClientOrg>;

  clientOrgCreateSetDialogState: Reducer<{
    name: keyof ClientOrgCreateState['dialogs'];
    data: Nullable<ClientOrgCreate>;
  }>;
}

const slice = createSlice<ClientOrgCreateState, Reducers, 'clientOrg/create'>({
  name: 'clientOrg/create',
  initialState: {
    guid: null,
    byId: {
      ...fetchableDefault,
      clientOrg: createEmptyClientOrgCreate(),
      loadedData: null,
    },
    modified: false,
    validation: {},
    validationStepper: null,
    ui: null,
    dialogs: {
      feedback: null,
      close: null,
      discard: null,
    },
  },
  reducers: {
    clientOrgCreateStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;
        state.byId = {
          ...fetchableDefault,
          clientOrg: createEmptyClientOrgCreate(),
          loadedData: null,
        };
        state.modified = false;
        state.validation = {};
        state.ui = null;
        state.dialogs = {
          feedback: null,
          close: null,
          discard: null,
        };
      }
    },
    clientOrgCreateSetModified: (state, { payload }) => {
      state.modified = payload;
    },
    clientOrgCreateClearActionsState: state => {
      state.validation = {};
      state.validationStepper = null;
      state.ui = null;
      state.dialogs = {
        feedback: null,
        close: null,
        discard: null,
      };
    },
    clientOrgCreateSetUiState: (state, { payload }) => {
      const { name, value } = payload;
      if (!state.ui) {
        state.ui = {
          steps: [],
          fields: {
            disabled: [],
          },
        };
      }
      state.ui[name] = value;
    },

    clientOrgCreateSetValidationStepper: (state, { payload }) => {
      state.validationStepper = payload;
    },

    clientOrgCreateSetAttribute: (state, { payload }) => {
      const { name, value } = payload;

      if (state.byId.clientOrg) {
        (state.byId.clientOrg[name] as keyof ClientOrgCreate) = value;
      }
    },
    clientOrgCreateClearAllValidations: state => {
      state.validation = {};
    },
    clientOrgCreateClearAttributeValidation: (state, { payload }) => {
      delete state.validation?.[payload];
    },
    clientOrgCreateSetAttributeValidation: (state, { payload }) => {
      const { name, results } = payload;
      if (!results) {
        delete state.validation?.[name];
      } else {
        state.validation[name] = results;
      }
    },
    clientOrgCreateSetValidation: (state, { payload }) => {
      state.validation = payload ?? {};
    },
    clientOrgCreateApplySavedClientOrg: (state, { payload }) => {
      state.byId.clientOrg = convertClientOrgToClientOrgCreate(payload);
      state.byId.loadedData = payload;
      state.modified = false;
    },
    clientOrgCreateSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(clientOrgCreateByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
      })
      .addCase(clientOrgCreateByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.clientOrg = payload ? convertClientOrgToClientOrgCreate(payload) : createEmptyClientOrgCreate();
        state.byId.loadedData = payload;
      })
      .addCase(clientOrgCreateByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        } else {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = false;
        }
      });
  },
});

export const {
  clientOrgCreateStartSession,
  clientOrgCreateSetModified,
  clientOrgCreateClearActionsState,
  clientOrgCreateSetUiState,

  clientOrgCreateClearAllValidations,
  clientOrgCreateSetValidationStepper,

  clientOrgCreateSetAttribute,
  clientOrgCreateSetValidation,
  clientOrgCreateClearAttributeValidation,
  clientOrgCreateSetAttributeValidation,

  clientOrgCreateApplySavedClientOrg,
  clientOrgCreateSetDialogState,
} = slice.actions;

export default slice.reducer;
