import { Typography } from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { useHistory } from 'react-router';
import { BackButtonWrapper, StyledButton } from './controls';

interface BackButtonProps {
  readonly label?: string;
}

const BackButton = ({ label = 'Назад' }: BackButtonProps) => {
  const history = useHistory();

  return (
    <BackButtonWrapper>
      <StyledButton
        fullWidth={false}
        variant='text'
        startIcon={<ArrowBackIosOutlinedIcon />}
        onClick={() => history.goBack()}
      >
        <Typography
          component='span'
          color='textPrimary'
          variant='h3'
        >
          {label}
        </Typography>
      </StyledButton>
    </BackButtonWrapper>
  );
};

export default BackButton;
