import { UUID } from '../../../../../../domain/model/types';
import ConfirmDialog from '../../../../../components/common/dialogs/confirm';
import ProductUploadStocksContainer from '../../../uploadStocks/container';
import {EOfferStatus} from "../../../../../../domain/model/enums";

interface ProductTableUploadStocksDialogProps {
  readonly partnerId: UUID;
  readonly statuses: EOfferStatus[];
  readonly onUpload: (count: number) => void;
  readonly onClose: () => void;
}

const ProductTableUploadStocksDialog = (props: ProductTableUploadStocksDialogProps) => {
  const { partnerId, statuses, onUpload, onClose } = props;

  return (
    <ConfirmDialog
      open
      onClose={onClose}
      title='Обновление остатков'
    >
      <ProductUploadStocksContainer
        partnerId={partnerId}
        statuses={statuses}
        onUpload={onUpload}
        onClose={onClose}
      />
    </ConfirmDialog>
  );
};

export default ProductTableUploadStocksDialog;
