import { ReactElement } from 'react';

export enum EClientOrgUserTableTab {
  Enabled = 'enabled',
  Invited = 'invited',
  Disabled = 'disabled',
}

export type ClientOrgUserTableStubAdapterProps = {
  readonly children: (props: ClientOrgUserTableStubAdapterResult) => ReactElement<any, any>;
};

export type ClientOrgUserTableStubAdapterResult = {
  readonly canViewTable: boolean;
  readonly stub?: Nullable<JSX.Element>;
};

export type ClientOrgUserTableStubAdapterType = (props: ClientOrgUserTableStubAdapterProps) => ReactElement<any, any>;

export enum EClientOrgUserActionType {
  /** Импорт пользователей */
  Import = 'import',
}
