import MasterActionsComponent from '@components/common/actions/master';
import useNavAdapter from '@components/common/actions/navAdapter/hooks';
import { DefaultFooterWrapper } from '@components/common/wrappers/footer';
import { useSelector } from 'react-redux';
import { ClientOrgActionCreateType } from '../../types';
import { useContextLifecycle } from '../hooks/useContextLifecycle';
import useClientOrgCreateStepper from '../hooks/useStepper';
import { clientOrgCreateClientOrgSelector } from '../store/selectors';

type ClientOrgCreateFooterAdapterProps = {};

export const ClientOrgCreateFooterAdapter = (props: ClientOrgCreateFooterAdapterProps) => {
  const { actions, onAction } = useContextLifecycle();

  const clientOrg = useSelector(clientOrgCreateClientOrgSelector);

  const { openNextStep, currentStepIndex, openPrevStep, steps } = useClientOrgCreateStepper();

  const onNextStep = () => {
    if (clientOrg) {
      openNextStep();
    }
  };

  const { adapter: navAdapter, actions: navActions } = useNavAdapter({
    openPrevStep,
    currentStepIndex,
    openNextStep: onNextStep,
    stepsCount: steps.length,
  });

  return (
    <MasterActionsComponent<ClientOrgActionCreateType>
      show={!!actions.length || !!navActions.length}
      actions={actions}
      navAdapter={navAdapter}
      onAction={onAction}
      wrapper={DefaultFooterWrapper}
    />
  );
};

