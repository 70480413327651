import { Typography } from '@mui/material';
import { useMemo } from 'react';
import FileInfo from '../../../../components/common/files/info';
import { FileInfoBlankAdapter } from '../../../../components/common/files/info/blankAdapter';
import { FileInfoRemoteAnyAdapter } from '../../../../components/common/files/info/remoteAnyAdapter';
import Splitter from '../../../../components/common/splitter';
import useBusinessConfig from '../../../../hooks/useBusinessConfig';
import useTechConfig from '../../../../hooks/useTechConfig';
import { ContentTypeDocumentIcon } from '../../../../icons';
import { EAppFeature } from '../../../../types';
import { getUserRoleManualPath } from '../../../../utils/roles';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import SupportPdpLinkContainer from '../../pdpLink';
import SupportTermsOfUseLinkContainer from '../../termsLink';
import { ArrowRightIcon } from '@mui/x-date-pickers';

const SupportInfoSportDocumentsAdapter = () => {
  const {
    sportUserProfile: { roles },
  } = useCurrentUser();
  const {
    sport: { manualPaths },
  } = useBusinessConfig().supportInfo;
  const { hasFeature } = useTechConfig();

  const url = getUserRoleManualPath(roles, manualPaths);

  const hasTermsOfUseFeature = useMemo<boolean>(() => hasFeature(EAppFeature.TermsOfUse), [hasFeature]);
  const hasPersonalDataPolicyFeature = useMemo<boolean>(() => hasFeature(EAppFeature.PersonalDataPolicy), [hasFeature]);

  return useMemo(() => {
    return url.length > 0 || hasTermsOfUseFeature || hasPersonalDataPolicyFeature ? (
      <>
        <Typography
          variant='h3'
          gutterBottom
        >
          Документы
        </Typography>
        <Splitter
          variant='horizontal'
          size={2}
        />
        {url.length > 0 && (
          <>
            <Typography
              variant='subtitle2'
              gutterBottom
            >
              Руководство пользователя
            </Typography>
            <FileInfo
              id={url}
              icon={<ContentTypeDocumentIcon color='action' />}
              endIcon={<ArrowRightIcon color='action' />}
              adapter={FileInfoRemoteAnyAdapter}
              originalName='Руководство пользователя'
              fileExtension
              fileSize={false}
            />
            <Splitter
              variant='horizontal'
              size={3}
            />
          </>
        )}
        {(hasTermsOfUseFeature || hasPersonalDataPolicyFeature) && (
          <>
            <Typography
              variant='subtitle2'
              gutterBottom
            >
              Юридические документы
            </Typography>
            {hasTermsOfUseFeature && (
              <>
                <SupportTermsOfUseLinkContainer underline='none'>
                  <FileInfo
                    color='secondary'
                    icon={<ContentTypeDocumentIcon color='action' />}
                    endIcon={<ArrowRightIcon color='action' />}
                    adapter={FileInfoBlankAdapter}
                    originalName='Пользовательское соглашение'
                  />
                </SupportTermsOfUseLinkContainer>
                <Splitter
                  variant='horizontal'
                  size={2}
                />
              </>
            )}
            {hasPersonalDataPolicyFeature && (
              <SupportPdpLinkContainer underline='none'>
                <FileInfo
                  color={'secondary'}
                  icon={<ContentTypeDocumentIcon color='action' />}
                  endIcon={<ArrowRightIcon color='action' />}
                  adapter={FileInfoBlankAdapter}
                  originalName='Политика обработки персональных данных ОАО «РЖД»'
                />
              </SupportPdpLinkContainer>
            )}
          </>
        )}
      </>
    ) : null;
  }, [url, hasTermsOfUseFeature, hasPersonalDataPolicyFeature]);
};

export default SupportInfoSportDocumentsAdapter;
