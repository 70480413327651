import { FC, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { UserAccessPartitionProductOffers } from '../../../../domain/model/accessMatrix';
import { Nullable, UUID } from '../../../../domain/model/types';
import TableCommonLayout from '../../../layouts/tableCommon/container';
import TableLoaderCommonLayout from '../../../layouts/tableLoaderCommon/container';
import { ProductLifeCycle } from '../lifecycle/types';
import { getProductTableTabActions } from '../utils/table';
import ProductTableFilterAdapter from './adapters/filter';
import ProductTableHeaderAdapter from './adapters/header';
import ProductTableAdapter from './adapters/table';
import { ProductTableTabsAdapter } from './adapters/tabs';
import ProductTableToolbarAdapter from './adapters/toolbar';
import { ProductTableContext } from './context';
import ProductTableDialogs from './dialogs';
import ProductTableFooterContainer from './footerContainer';
import useProductTableCount from './hooks/useCount';
import { useProductTableHandlers } from './hooks/useProductTable';
import useProductTableSearch from './hooks/useSearch';
import useProductTableSession from './hooks/useSession';
import useTableSettings from './hooks/useTableSettings';
import { productsGuidSelector, productsIsPreloadingSelector } from './store/selectors';
import { ProductTableStubAdapterType } from './types';
import { EProductTableTab } from './utils';

type ProductTableContainerProps = {
  readonly guid: UUID;
  readonly tab: EProductTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly lifecycle: ProductLifeCycle;
  readonly stubAdapter: ProductTableStubAdapterType;
  readonly access: UserAccessPartitionProductOffers;
};

const ProductTableContainer: FC<ProductTableContainerProps> = props => {
  const {
    guid,
    tab,
    partnerId,
    lifecycle,
    lifecycle: { isPartnerUser },
    stubAdapter: StubAdapter,
    access,
  } = props;

  const handlers = useProductTableHandlers({tab});

  const currentGuid = useSelector(productsGuidSelector);

  // готовим адаптер для хука
  const { filterAdapter } = useTableSettings({ tab, partnerId, isPartnerUser });

  useProductTableSession({ guid, tab });
  useProductTableSearch({ tab, partnerId });
  useProductTableCount({ partnerId });

  const tableTabActions = getProductTableTabActions(access, tab);

  if (guid !== currentGuid) {
    return null;
  }

  return (
    <ProductTableContext.Provider value={handlers}>
      <TableLoaderCommonLayout selector={productsIsPreloadingSelector}>
        <StubAdapter>
          {({ canViewTable, isCreateDisabled, createInfo, stub, isEmpty }) => (
            <TableCommonLayout
              canViewTable={canViewTable}
              header={
                <ProductTableHeaderAdapter
                  tab={tab}
                  partnerId={partnerId}
                  createInfo={createInfo}
                  isCreateDisabled={isCreateDisabled}
                  canCreate={access.create}
                  canDownload={access.download && canViewTable}
                  canUpload={access.upload && canViewTable}
                  canChangeStocks={access.changeStock && canViewTable}
                />
              }
              filter={<ProductTableFilterAdapter filterAdapter={filterAdapter} />}
              tabs={<ProductTableTabsAdapter tab={tab} />}
              toolbar={<ProductTableToolbarAdapter partnerId={partnerId} />}
              table={
                <ProductTableAdapter
                  lifecycle={lifecycle}
                  filterAdapter={filterAdapter}
                  tabActions={tableTabActions}
                />
              }
              stub={stub}
              footer={forwardRef((props, ref: any) =>
                isEmpty ? null : (
                  <ProductTableFooterContainer
                    ref={ref}
                    tab={tab}
                    allowedActions={tableTabActions}
                    lifecycle={lifecycle}
                  />
                )
              )}
            >
              <ProductTableDialogs />
            </TableCommonLayout>
          )}
        </StubAdapter>
      </TableLoaderCommonLayout>
    </ProductTableContext.Provider>
  );
};

export default ProductTableContainer;
