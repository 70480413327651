import AppBreadcrumbs from '@components/common/breadcrumbs';
import { Typography } from '@mui/material';
import DefaultHeader from '@components/common/header';

export const ReportsHeaderAdapter = () => {
  return (
    <DefaultHeader headline={<AppBreadcrumbs />}>
      <Typography variant='h2'>Отчеты</Typography>
    </DefaultHeader>
  );
};
