import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../../data/api';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { Fetchable } from '../../../../../../data/store/types';
import { Partner } from '../../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import { MpUser } from '../../../../../../domain/model/user';

export const customerManagementByPartnerCustomerByIdFetch = createAsyncThunk<
  { customer: MpUser; partner: Partner },
  { partnerId: UUID; customerId: UUID },
  AppThunkAPIConfig
>('customer/management/byPartner/byId/fetch', async ({ partnerId, customerId }, { rejectWithValue, signal }) => {
  try {
    const [{ data: partner }, { data: customer }] = await Promise.all([
      Api.partner.one({ id: partnerId, signal }),
      Api.user.mp.one({ id: customerId, signal }),
    ]);
    return { customer, partner };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);

    return rejectWithValue(e.response.data);
  }
});

export interface CustomerManagementByPartnerState {
  readonly menu: {
    readonly visible: boolean;
  };
  readonly data: Fetchable & {
    readonly customer: Nullable<MpUser>;
    readonly partner: Nullable<Partner>;
  };
}

type Reducer<T = undefined> = CaseReducer<CustomerManagementByPartnerState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CustomerManagementByPartnerState> {
  viewShowCustomerManagementByPartnerMenu: Reducer;
  viewHideCustomerManagementByPartnerMenu: Reducer;
}

const slice = createSlice<CustomerManagementByPartnerState, Reducers, 'customer/management/byPartner'>({
  name: 'customer/management/byPartner',
  initialState: {
    menu: {
      visible: true,
    },
    data: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      customer: null,
      partner: null,
    },
  },
  reducers: {
    viewShowCustomerManagementByPartnerMenu(state) {
      state.menu.visible = true;
    },
    viewHideCustomerManagementByPartnerMenu(state) {
      state.menu.visible = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(customerManagementByPartnerCustomerByIdFetch.pending, state => {
        state.data.isFetching = true;
        state.data.isFetched = false;
        state.data.isFailed = false;
        state.data.customer = null;
        state.data.partner = null;
      })
      .addCase(customerManagementByPartnerCustomerByIdFetch.fulfilled, (state, { payload }) => {
        const { customer, partner } = payload;

        state.data.isFetching = false;
        state.data.isFetched = true;
        state.data.isFailed = false;

        state.data.customer = customer;
        state.data.partner = partner;
      })
      .addCase(customerManagementByPartnerCustomerByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.data.isFetching = false;
          state.data.isFetched = false;
          state.data.isFailed = true;
        } else {
          state.data.isFetching = false;
          state.data.isFetched = false;
          state.data.isFailed = false;
        }

        state.data.customer = null;
        state.data.partner = null;
      });
  },
});

export const { viewShowCustomerManagementByPartnerMenu, viewHideCustomerManagementByPartnerMenu } = slice.actions;

export default slice.reducer;
