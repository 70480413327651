import { Team } from '../../../../domain/model/team';
import { Nullable } from '../../../../domain/model/types';
import { SportUserProfileShort } from '../../../../domain/model/user';
import { DefaultContentWrapper } from '../../../components/common/wrappers/content';
import { OnChangeObjectAttribute } from '../../../types';
import TeamCreateContent from './content';
import { Content, Header, Wrapper } from './controls';
import TeamCreateHeader from './header';

interface TeamCreateProps {
  readonly team: Team;
  readonly validationErrors: { [key: string]: string | boolean };
  readonly isSaving: boolean;
  readonly onChangeAttribute: OnChangeObjectAttribute<Team>;
  readonly onChangeCaptain: (captain: Nullable<SportUserProfileShort>) => void;
  readonly onLogoChangeClick: () => void;
  readonly onSave: () => void;
}

const TeamCreate = ({
  team,
  validationErrors,
  isSaving,
  onChangeAttribute,
  onChangeCaptain,
  onLogoChangeClick,
  onSave,
}: TeamCreateProps) => {
  return (
    <DefaultContentWrapper>
      <Wrapper>
        <Header>
          <TeamCreateHeader
            team={team}
            isSaving={isSaving}
            onLogoChangeClick={onLogoChangeClick}
          />
        </Header>
        <Content>
          <TeamCreateContent
            team={team}
            errors={validationErrors}
            isSaving={isSaving}
            onChangeAttribute={onChangeAttribute}
            onChangeCaptain={onChangeCaptain}
            onSave={onSave}
          />
        </Content>
      </Wrapper>
    </DefaultContentWrapper>
  );
};

export default TeamCreate;
