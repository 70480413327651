import { UserAccessPartitionClientOrgUsers } from '@/domain/model/accessMatrix';
import { ClientOrg } from '@/domain/model/clientOrg';
import { UUID } from '@/domain/model/types';
import { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import TableCommonLayout from '../../../../layouts/tableCommon/container';
import TableLoaderCommonLayout from '../../../../layouts/tableLoaderCommon/container';
import { EClientOrgUserTableFilterItem } from '../filterUtils';
import { EClientOrgUserTableTab } from '../types';
import { ClientOrgUserBehaviorAdapter } from './adapters/behavior';
import ClientOrgUsersTableFilterAdapter from './adapters/filters';
import ClientOrgUsersTableHeaderAdapter from './adapters/header';
import { ClientOrgUsersTableAdapter } from './adapters/table';
import { ClientOrgUsersTableTabsAdapter } from './adapters/tabs';
import { ClientOrgUsersTableContext } from './context';
import BookingTableFooterContainer from './footerContainer';
import { useClientOrgUsersTableHandlers } from './hooks/useClientOrgUsersTable';
import { clientOrgUsersIsPreloadingSelector } from './store/selectors';
import { EClientOrgUserTableColumn } from './utils';

interface ClientOrgUserTableContainerProps {
  readonly guid: UUID;
  readonly clientOrg: ClientOrg;
  readonly tab: EClientOrgUserTableTab;
  readonly filterAdapter: DataFilterAdapter<EClientOrgUserTableColumn, EClientOrgUserTableFilterItem>;
  readonly access: UserAccessPartitionClientOrgUsers;
}

const ClientOrgUserTableContainer = (props: ClientOrgUserTableContainerProps) => {
  const { guid, tab, filterAdapter, access, clientOrg } = props;

  const handlers = useClientOrgUsersTableHandlers({ guid, tab });

  return (
    <ClientOrgUsersTableContext.Provider value={handlers}>
      <ClientOrgUserBehaviorAdapter
        guid={guid}
        clientOrg={clientOrg}
        tab={tab}
      />
      <TableLoaderCommonLayout selector={clientOrgUsersIsPreloadingSelector}>
        <TableCommonLayout
          filter={<ClientOrgUsersTableFilterAdapter filterAdapter={filterAdapter} />}
          header={
            <ClientOrgUsersTableHeaderAdapter
              canDownload={access.download}
              canCreate={access.create}
              clientOrg={clientOrg}
            />
          }
          tabs={<ClientOrgUsersTableTabsAdapter tab={tab} />}
          table={<ClientOrgUsersTableAdapter filterAdapter={filterAdapter} />}
          footer={BookingTableFooterContainer}
        ></TableCommonLayout>
      </TableLoaderCommonLayout>
    </ClientOrgUsersTableContext.Provider>
  );
};

export default ClientOrgUserTableContainer;
