import { ListItem, ListItemIcon, Typography } from '@mui/material';
import styled from '@emotion/styled';

export const HeaderListItem = styled(ListItem)`
  & .MuiTypography-root {
    font-weight: ${p => p.theme.typography.fontWeightBold};
  }
`;

export const LogoIconWrapper = styled(ListItemIcon)`
  margin-right: ${p => p.theme.spacing()};
  color: white;
`;

export const UserType = styled(Typography)`
  line-height: 1.5rem;
`;

export const PrivilegeIcon = styled.img`
  width: 5rem;
  height: auto;
`;
