import { DataFilterStrategyBase, DataFilterValueItem, EDataFilterType, Nullable } from '@/domain';

export enum EReportUsersFilterItem {
  DatePeriod = 'datePeriod',
}

export type ReportUsersFilterEditStrategy = DataFilterStrategyBase<EReportUsersFilterItem>;

export type ReportUsersFilterValues = {
  readonly [EReportUsersFilterItem.DatePeriod]?: DataFilterValueItem<Nullable<[Nullable<string>, Nullable<string>]>>;
};

export const getReportUsersFilterStrategies = (values: ReportUsersFilterValues): ReportUsersFilterEditStrategy[] => {
  const strategies: ReportUsersFilterEditStrategy[] = [];
  strategies.push({
    type: EDataFilterType.DatePeriod,
    key: EReportUsersFilterItem.DatePeriod,
    label: '',
    labelPeriod: {
      from: 'Период с',
      to: 'по',
    },
    preview: null,
    buttonsPeriod: true,
    value: values[EReportUsersFilterItem.DatePeriod]?.value || null,
  });

  return strategies;
};
