import Typography from '@mui/material/Typography';
import { formatCost } from '../../../../utils';
import { BookingOrderEditFieldCommonProps } from '../../types';

const fieldName = 'cost';

export const BookingOrderEditCostField = (props: BookingOrderEditFieldCommonProps<typeof fieldName>) => {
  const { value } = props;

  return <Typography variant='h3'>Итого: {formatCost(value)}</Typography>;
};
