import { RootState } from '../../../../../../data/store/store';
import { createCmsContainerDetailsCurrentLinkedObjectByIndexSelector } from './slice';

export const cmsContainerDetailsByIdSelector = (store: RootState) => store.cms.container.details.byId;
export const cmsContainerDetailsComponentsSelector = (store: RootState) => store.cms.container.details.components;
export const cmsContainerDetailsLinkedObjectsSelector = (store: RootState) => store.cms.container.details.linkedObjects;
export const cmsContainerDetailsCurrentLinkedObjectSelector = (index: number) => (store: RootState) =>
  createCmsContainerDetailsCurrentLinkedObjectByIndexSelector()(store, index);
export const cmsContainerDetailsPauseSelector = (store: RootState) => store.cms.container.details.pause;
export const cmsContainerDetailsResumeSelector = (store: RootState) => store.cms.container.details.resume;
export const cmsContainerDetailsDeleteSelector = (store: RootState) => store.cms.container.details.delete;
export const cmsContainerDetailsDuplicateSelector = (store: RootState) => store.cms.container.details.duplicate;
export const cmsContainerDetailsArchiveSelector = (store: RootState) => store.cms.container.details.archive;
