import { UUID } from '../../../../domain/model/types';
import { CommonPreloaderDecorator } from '../../general/decorators/preloader';
import MasterDetailsContainer from '../../general/master/details/container';
import { BookingOfferServiceManageFooterAdapter } from './adapters/footer';
import { BookingOfferServiceManageHeaderAdapter } from './adapters/header';
import { BookingOfferServiceManageComponent } from './component';
import { BookingOfferServiceManageInitializer } from './initializer';
import BookingOfferServiceManageContentLayout from './layout';
import { BookingOfferServiceManageHandlersProvider } from './providers/handlers';
import { BookingOfferServiceManageParamsProvider } from './providers/params';
import { bookingOfferServiceManageIsPreloadingSelector } from './store/selectros';

export type BookingOfferServiceManageContainerProps = {
  readonly id: UUID;
};

const BookingOfferServiceManageContainer = (props: BookingOfferServiceManageContainerProps) => {
  const { id } = props;

  return (
    <MasterDetailsContainer
      layout={BookingOfferServiceManageContentLayout}
      layoutProps={{
        header: BookingOfferServiceManageHeaderAdapter,
        footer: BookingOfferServiceManageFooterAdapter,
        decorators: [
          <CommonPreloaderDecorator
            key='preloader'
            isFetchingSelector={bookingOfferServiceManageIsPreloadingSelector}
          />,
        ],
      }}
      initializer={BookingOfferServiceManageInitializer}
      providers={[
        {
          name: 'params',
          factory: BookingOfferServiceManageParamsProvider,
        },
        {
          name: 'handlers',
          factory: BookingOfferServiceManageHandlersProvider,
        },
      ]}
      commonProps={{
        id,
      }}
    >
      <BookingOfferServiceManageComponent />
    </MasterDetailsContainer>
  );
};

export default BookingOfferServiceManageContainer;
