import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TabsPanel from '../../../../../components/common/tabs/panel';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { adminMpsTabsCounterSelector } from '../store/selectors';
import AdminMpTableTab from '../tab';
import { EAdminMpTableTab } from '../utils';

interface AdminMpTableTabsAdapterProps {
  readonly tab: EAdminMpTableTab;
}

export const AdminMpTableTabsAdapter = (props: AdminMpTableTabsAdapterProps) => {
  const { tab } = props;

  const { onChangeTab } = useContextHandlers();

  const counter = useSelector(adminMpsTabsCounterSelector);

  return useMemo(
    () => (
      <TabsPanel
        tab={tab}
        tabs={Object.values(EAdminMpTableTab)}
        labelAdapter={AdminMpTableTab}
        data={{
          counts: counter,
        }}
        onChangeTab={onChangeTab}
      />
    ),
    [tab, counter, onChangeTab]
  );
};
