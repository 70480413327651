import { EBannerPartition, UUID } from '@/domain';
import { BannersTableConfigContext } from '@features/banner/table/context';
import {
  bannerTableFilterItems,
  EBannerTableFilterItem,
  getBannerTableFilterStrategy,
} from '@features/banner/table/filterUtils';
import { useBannerTableConfig } from '@features/banner/table/hooks/useTableConfig';
import { useMemo } from 'react';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import BannerTableContainer from './container';
import { EBannerTableColumn, EBannerTableTab, getBannerTableColumns } from './utils';

interface BannerTableEntryProps {
  readonly guid: UUID;
  readonly partition: EBannerPartition;
  readonly tab?: Nullable<EBannerTableTab>;
}

const BannerTableEntry = ({ guid, partition, tab }: BannerTableEntryProps) => {
  const {
    accessMatrix: { banners },
  } = useCurrentUser();

  const config = useBannerTableConfig({ partition });
  const { defaultTab } = config;

  const outTab = tab ?? defaultTab;

  // получаем максимально возможный состав колонок
  const sourceColumns = useMemo(() => getBannerTableColumns(outTab), [outTab]);

  // готовим адаптер для хука
  const adapter = useMemo<DataFilterAdapter<EBannerTableColumn, EBannerTableFilterItem>>(
    () => ({
      key: 'banners',
      keyPartitions: [partition === EBannerPartition.CorpOffers ? 'corp' : 'trade', outTab],
      sourceColumns,
      filterItems: bannerTableFilterItems,
      requiredFilters: [EBannerTableFilterItem.Query],
      getDataFilterStrategy: (filterItem, values) => getBannerTableFilterStrategy(filterItem, values),
    }),
    [partition, outTab, sourceColumns]
  );

  return (
    <BannersTableConfigContext.Provider value={config}>
      <BannerTableContainer
        guid={guid}
        partition={partition}
        tab={outTab}
        filterAdapter={adapter}
        canCreate={banners?.[partition].create}
        canEdit={banners?.[partition].edit}
        canPause={banners?.[partition].pause}
        canResume={banners?.[partition].resume}
        canArchive={banners?.[partition].create}
        canChangeSortIndex={banners?.[partition].changeSortIndex}
      />
    </BannersTableConfigContext.Provider>
  );
};

export default BannerTableEntry;
