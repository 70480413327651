import Api from '@/data/api';
import ErrorHandler from '@/data/network/errorHandler';
import { ClientOrgMultiSelectorPredicate, EMultiSelectorValueType, Nullable } from '@/domain';
import { clientOrgRzdCode } from '@features/clientOrg/utils';
import {
  CmsPreviewTargetAudienceSelectorItem,
  CmsPreviewTargetAudienceSelectorItemType,
} from '@features/cms/previewTarget/types';
import { getCmsPreviewTargetAudienceSelectorItemExternalUsers } from '@features/cms/previewTarget/utils';
import { MPAutocompleteSingleSelect } from '@ui-kit/autocomplete/single';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface CmsPreviewTargetAudienceSelectorProps {
  readonly label: string;
  readonly externalUsers: Nullable<boolean>;
  readonly clientOrgs: Nullable<ClientOrgMultiSelectorPredicate>;
  readonly helperText?: string;
  readonly error?: boolean;
  readonly disabled?: boolean;
  readonly onChange: (externalUsers: Nullable<boolean>, clientOrgs: Nullable<ClientOrgMultiSelectorPredicate>) => void;
}

const CmsPreviewTargetAudienceSelector = (props: CmsPreviewTargetAudienceSelectorProps) => {
  const { label, externalUsers, clientOrgs, helperText, error, disabled, onChange } = props;

  const [options, setOptions] = useState<CmsPreviewTargetAudienceSelectorItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const value = useMemo<Nullable<CmsPreviewTargetAudienceSelectorItem>>(() => {
    if (externalUsers) {
      return getCmsPreviewTargetAudienceSelectorItemExternalUsers();
    } else {
      return clientOrgs?.in?.length
        ? {
            ...clientOrgs.in[0],
            type: CmsPreviewTargetAudienceSelectorItemType.ClientOrg,
          }
        : null;
    }
  }, [options, externalUsers, clientOrgs]);

  const internalOnChange = useCallback((item: Nullable<CmsPreviewTargetAudienceSelectorItem>) => {
    const newExternalUsers = item?.type === CmsPreviewTargetAudienceSelectorItemType.ExternalUsers;
    const newClientOrgs =
      item?.type === CmsPreviewTargetAudienceSelectorItemType.ClientOrg
        ? { select: EMultiSelectorValueType.In, in: [item] }
        : null;
    onChange(newExternalUsers, newClientOrgs);
  }, []);

  useEffect(() => {
    setIsLoading(true);

    Api.clientOrg
      .all({
        page: 1,
        pageSize: 9999,
        sort: 'name',
      })
      .then(({ data }) =>
        setOptions([
          { ...getCmsPreviewTargetAudienceSelectorItemExternalUsers() },
          ...data
            .sort((a, b) => (a.code === clientOrgRzdCode ? -1 : 1))
            .map(item => ({
              ...item,
              type: CmsPreviewTargetAudienceSelectorItemType.ClientOrg,
            })),
        ])
      )
      .catch(e => {
        console.error(e);
        ErrorHandler.handleHttpError(e, e.response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <MPAutocompleteSingleSelect<CmsPreviewTargetAudienceSelectorItem>
      value={value}
      label={label}
      helperText={helperText}
      error={error}
      isLoading={isLoading}
      options={options}
      disabled={disabled}
      onChangeValue={internalOnChange}
    />
  );
};

export default CmsPreviewTargetAudienceSelector;
