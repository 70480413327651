import { TypographyProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Wrapper } from './controls';
import { ETagColors } from './utils';

export interface MPTagProps extends Omit<TypographyProps, 'variant' | 'color'> {
  readonly color?: ETagColors;
  readonly variant?: 'outlined' | 'filled';
  readonly dense?: boolean;
  readonly bold?: boolean;
  readonly label: ReactNode;
}

export const MPTag: FC<MPTagProps> = props => {
  const { variant = 'outlined', label, color = ETagColors.Default, ...other } = props;

  return (
    <Wrapper
      {...other}
      component={'span'}
      variant={variant}
      color={color}
    >
      {label}
    </Wrapper>
  );
};
