import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../data/store/types';
import { OfferFieldAudit, OfferState, Pageable } from '../../../../../domain/model';
import { Nullable } from '../../../../../domain/model/types';
import { PaginationSize } from '../../../../types';
import { offerHistoryService } from '../../services';
import { FieldHistoryAllProps, HistoryAllProps } from '../../services/common';
import { EOfferHistoryTableTab } from '../../types';
import { OfferFieldHistoryFilterValues } from '../utils/fieldHistoryFilter';
import { OfferHistoryFilterValues } from '../utils/historyFilter';

const pageSizeDefault: PaginationSize = 10;
const historySortDefault = 'enteredAt,desc';
const fieldHistorySortDefault = 'updatedAt,desc';

export type OfferHistoryFetchProps = Omit<HistoryAllProps, 'signal'>;

export const offerHistoryTableFetch = createAsyncThunk<Pageable<OfferState>, OfferHistoryFetchProps, AppThunkAPIConfig>(
  'offerHistory/table/fetch',
  async (payload, { rejectWithValue, signal }) => {
    try {
      return await offerHistoryService.common.history({ ...payload, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export type OfferFieldHistoryFetchProps = Omit<FieldHistoryAllProps, 'signal'>;

export const offerFieldHistoryTableFetch = createAsyncThunk<
  Pageable<OfferFieldAudit>,
  OfferFieldHistoryFetchProps,
  AppThunkAPIConfig
>('offerHistory/table/fetchFieldHistory', async (payload, { rejectWithValue, signal }) => {
  try {
    return await offerHistoryService.common.fieldHistory({ ...payload, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);

    return rejectWithValue(e.response.data);
  }
});

export interface OfferHistoryTableState {
  readonly tab: Nullable<EOfferHistoryTableTab>;
  readonly needRefreshWatcher: number;
  readonly fetch: Fetchable;
  readonly paging: {
    readonly totalCount: number;
    readonly pageCount: number;
    readonly pageNumber: number;
  };
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
  };
  readonly history: OfferState[];
  readonly fieldHistory: OfferFieldAudit[];
  readonly filter: OfferHistoryFilterValues;
  readonly fieldFilter: OfferFieldHistoryFilterValues;
}

type Reducer<T = undefined> = CaseReducer<OfferHistoryTableState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<OfferHistoryTableState> {
  offerHistoryTableSetTab: Reducer<EOfferHistoryTableTab>;
  offerHistoryTableStateReset: Reducer;
  offerHistoryTableSetPage: Reducer<number>;
  offerHistoryTableSetPageSize: Reducer<PaginationSize>;
  offerHistoryTableSetHistoryFilter: Reducer<OfferHistoryFilterValues>;
  offerHistoryTableSetFieldHistoryFilter: Reducer<OfferFieldHistoryFilterValues>;
}

const slice = createSlice<OfferHistoryTableState, Reducers, 'table'>({
  name: 'table',
  initialState: {
    tab: null,
    needRefreshWatcher: 0,
    fetch: fetchableDefault,
    paging: {
      totalCount: 0,
      pageCount: 0,
      pageNumber: 1,
    },
    history: [],
    fieldHistory: [],
    search: {
      pageSize: pageSizeDefault,
      sort: historySortDefault,
    },
    filter: {},
    fieldFilter: {},
  },
  reducers: {
    offerHistoryTableSetTab: (state, { payload }) => {
      state.tab = payload;

      state.paging.totalCount = 0;
      state.paging.pageCount = 0;
      state.paging.pageNumber = 1;

      state.fetch = fetchableDefault;

      state.search = {
        ...state.search,
        sort: payload === EOfferHistoryTableTab.Status ? historySortDefault : fieldHistorySortDefault,
      };

      state.history = [];
      state.fieldHistory = [];

      state.filter = {};
      state.fieldFilter = {};

      state.needRefreshWatcher++;
    },
    offerHistoryTableStateReset: state => {
      state.tab = null;
      state.needRefreshWatcher = 0;

      state.paging.totalCount = 0;
      state.paging.pageCount = 0;
      state.paging.pageNumber = 1;

      state.fetch = fetchableDefault;

      state.search = {
        sort: historySortDefault,
        pageSize: pageSizeDefault,
      };

      state.history = [];
      state.fieldHistory = [];

      state.filter = {};
      state.fieldFilter = {};
    },
    offerHistoryTableSetPage: (state, { payload }) => {
      state.paging.pageNumber = payload;
      state.needRefreshWatcher++;
    },
    offerHistoryTableSetPageSize: (state, { payload }) => {
      state.paging.pageNumber = 1;
      state.search.pageSize = payload;
      state.needRefreshWatcher++;
    },
    offerHistoryTableSetHistoryFilter: (state, { payload }) => {
      state.filter = payload;
      state.paging.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    offerHistoryTableSetFieldHistoryFilter: (state, { payload }) => {
      state.fieldFilter = payload;
      state.paging.pageNumber = 1;
      state.needRefreshWatcher++;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(offerHistoryTableFetch.pending, state => {
        state.fetch = fetchableFetching;
      })
      .addCase(offerHistoryTableFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.fetch = fetchableFetched;

        state.history = data.map(d => ({ ...d, id: d.enteredAt ?? '' }));

        state.paging.totalCount = totalCount;
        state.paging.pageCount = pageCount;
      })
      .addCase(offerHistoryTableFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.fetch = fetchableFailed;
        } else {
          state.fetch = fetchableDefault;
        }

        state.history = [];
      })
      .addCase(offerFieldHistoryTableFetch.pending, state => {
        state.fetch = fetchableFetching;
      })
      .addCase(offerFieldHistoryTableFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.fetch = fetchableFetched;

        state.fieldHistory = data.map(d => ({ ...d, id: d.updatedAt ?? '' }));

        state.paging.totalCount = totalCount;
        state.paging.pageCount = pageCount;
      })
      .addCase(offerFieldHistoryTableFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.fetch = fetchableFailed;
        } else {
          state.fetch = fetchableDefault;
        }

        state.fieldHistory = [];
      });
  },
});

export const {
  offerHistoryTableStateReset,
  offerHistoryTableSetPage,
  offerHistoryTableSetPageSize,
  offerHistoryTableSetHistoryFilter,
  offerHistoryTableSetFieldHistoryFilter,
  offerHistoryTableSetTab,
} = slice.actions;

export default slice.reducer;
