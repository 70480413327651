import { FCC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { bookingServiceCategoriesEditFetch, bookingServiceCategoriesEditResetState } from './store/slice';
import { BookingServiceCategoriesEditCommonProps } from './types';

type BookingServiceCategoriesEditInitializerProps = BookingServiceCategoriesEditCommonProps;

export const BookingServiceCategoriesEditInitializer: FCC<BookingServiceCategoriesEditInitializerProps> = ({
  children,
}) => {
  const dispatch = useDispatch();

  //загрузка основных данных
  useEffect(() => {
    const requests = [dispatch(bookingServiceCategoriesEditFetch())];
    return () => {
      requests.forEach(request => request.abort());
    };
  }, [dispatch]);

  //очистка данных
  useEffect(() => {
    return () => {
      dispatch(bookingServiceCategoriesEditResetState());
    };
  }, [dispatch]);

  return <>{children}</>;
};
