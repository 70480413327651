import axios, { AxiosResponse } from 'axios';
import { SportOption } from '../../domain/model';
import { Complaint } from '../../domain/model/complaint';
import { EComplaintObjectType, EComplaintStatus } from '../../domain/model/enums';
import { Nullable, UUID } from '../../domain/model/types';
import { EComplaintObjectTypeInternal } from '../../presentation/features/complaint/types';
import { createCancelToken } from './index';
import {
  ApiCancellable,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
  ApiResponseWithPageable,
  ApiSportResponseMaBeCounters,
} from './types';
import { getSportBaseEndpoint } from './utils';

export type AllProps<D extends ApiRequestListDiscriminator> = ApiRequestPageable &
  ApiCancellable & {
    readonly statuses?: Nullable<EComplaintStatus[]>;
    readonly fromDate?: Nullable<string>;
    readonly toDate?: Nullable<string>;
    readonly objectTypes?: Nullable<EComplaintObjectTypeInternal[]>;
    readonly causeCodes?: Nullable<string[]>;
    readonly admin?: Nullable<SportOption>;
    readonly creatorId?: Nullable<UUID>;
    readonly name?: Nullable<string>;
    readonly discriminator?: D;
  };

type OneProps = ApiCancellable & {
  readonly id: UUID;
};
type ExecuteProps = ApiCancellable & {
  readonly id: UUID;
};
type RejectProps = ApiCancellable & {
  readonly id: UUID;
  readonly reason?: string;
};
type ComplaintApi = {
  readonly all: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps<D>
  ) => Promise<AxiosResponse<ApiSportResponseMaBeCounters<D, ApiResponseWithPageable<Complaint>>>>;
  readonly one: (props: OneProps) => Promise<AxiosResponse<Complaint>>;
  readonly execute: (props: ExecuteProps) => Promise<AxiosResponse<Complaint>>;
  readonly reject: (props: RejectProps) => Promise<AxiosResponse<Complaint>>;
  readonly approve: (props: OneProps) => Promise<AxiosResponse<Complaint>>;
};

const complaint: ComplaintApi = {
  all: ({ page, pageSize, sort, statuses, causeCodes, admin, signal, name, objectTypes, discriminator }) => {
    const params = new URLSearchParams();
    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }
    if (name) params.append('objectName', name);
    if (objectTypes) {
      objectTypes.forEach(item => {
        if (item === EComplaintObjectTypeInternal.Event) {
          params.append('objectTypes', EComplaintObjectType.SportsFest);
          params.append('objectTypes', EComplaintObjectType.Tournament);
          params.append('objectTypes', EComplaintObjectType.FriendlyMatch);
        } else {
          params.append('objectTypes', item);
        }
      });
    }
    if (causeCodes) causeCodes.forEach(code => params.append('causeCodes', code));
    if (admin) params.append('adminId', admin.id);
    if (statuses) {
      statuses.forEach(item => params.append('statuses', item));
    }
    if (discriminator) params.append('resultType', discriminator);

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));
    return axios.get(`${getSportBaseEndpoint()}/complaints`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  one: ({ id }) => {
    return axios.get(`${getSportBaseEndpoint()}/complaints/${id}`);
  },
  execute: ({ id }) => {
    return axios.post(`${getSportBaseEndpoint()}/admin/complaints/${id}/assign-executor/current-user`);
  },
  reject: ({ id, reason }) => {
    return axios.post(`${getSportBaseEndpoint()}/admin/complaints/${id}/reject`, { reason });
  },
  approve: ({ id }) => {
    return axios.post(`${getSportBaseEndpoint()}/admin/complaints/${id}/approve`);
  },
};

export default complaint;
