import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FileDescription } from '../../../../../../domain/model';
import { ETaxationSystemType } from '../../../../../../domain/model/enums';
import { PartnerDataDraft, PartnerIndividualDataDraft } from '../../../../../../domain/model/partner';
import { Nullable } from '../../../../../../domain/model/types';
import SectionTitle from '../../../../../components/common/sectionTitle';
import ValidationErrorPanel from '../../../../../components/common/validation/errorPanel';
import { OnChangeObjectAttribute } from '../../../../../types';
import { ValidationResult } from '../../../../../utils/validation';
import { PartnerDocumentsIndividual } from '../../../../general/partner/validation';
import { PartnerCommonFileSection, PartnerIndividualFileSection } from './fileSections';

interface PartnerEditIndividualDocumentsProps {
  readonly innCert: Nullable<FileDescription>;
  readonly taxSystem: Nullable<ETaxationSystemType>;
  readonly usnDoc: Nullable<FileDescription>;
  readonly ogrnipCert: Nullable<FileDescription>;
  readonly passportCopy: Nullable<FileDescription>;
  readonly isValid: boolean;
  readonly validation: Nullable<ValidationResult<PartnerDocumentsIndividual>>;
  readonly onChangeAttribute: OnChangeObjectAttribute<PartnerDataDraft>;
  readonly onChangeIndividualAttribute: OnChangeObjectAttribute<PartnerIndividualDataDraft>;
}

const PartnerEditIndividualDocuments = (props: PartnerEditIndividualDocumentsProps) => {
  const {
    innCert,
    taxSystem,
    usnDoc,
    ogrnipCert,
    passportCopy,
    isValid,
    validation,
    onChangeAttribute,
    onChangeIndividualAttribute,
  } = props;

  return (
    <Grid
      container
      spacing={2}
      direction='column'
    >
      <Grid item>
        <Typography
          variant='h3'
          gutterBottom
        >
          Документы
        </Typography>
      </Grid>

      <PartnerIndividualFileSection
        title={<SectionTitle>Свидетельство ОГРНИП</SectionTitle>}
        name='ogrnipCert'
        guid={ogrnipCert?.path}
        error={!!validation?.ogrnipCert?.hasError}
        helperText={validation?.ogrnipCert?.message}
        onChangeFile={onChangeIndividualAttribute}
      />

      <PartnerCommonFileSection
        title={<SectionTitle>Свидетельство ИНН</SectionTitle>}
        name='innCert'
        guid={innCert?.path}
        error={!!validation?.innCert?.hasError}
        helperText={validation?.innCert?.message}
        onChangeFile={onChangeAttribute}
      />

      <PartnerIndividualFileSection
        title={
          <>
            <SectionTitle gutterBottom={false}>Копия паспорта</SectionTitle>
            <Typography gutterBottom>Первая страница с фото и страница с регистрацией</Typography>
          </>
        }
        name='passportCopy'
        guid={passportCopy?.path}
        error={!!validation?.passportCopy?.hasError}
        helperText={validation?.passportCopy?.message}
        onChangeFile={onChangeIndividualAttribute}
      />

      {taxSystem === ETaxationSystemType.USN && (
        <PartnerCommonFileSection
          title={<SectionTitle>Основание УСН</SectionTitle>}
          name='usnDoc'
          guid={usnDoc?.path}
          error={!!validation?.usnDoc?.hasError}
          helperText={validation?.usnDoc?.message}
          onChangeFile={onChangeAttribute}
        />
      )}

      {!isValid && (
        <Grid
          item
          xs={12}
        >
          <ValidationErrorPanel />
        </Grid>
      )}
    </Grid>
  );
};

export default PartnerEditIndividualDocuments;
