import { AuthProvider as MPAuthProvider, OnAuthProviderEvent, OnResetPassword } from '@mp-npm/mp-auth-client';
import { onAuthRefreshErrorObservableSubject } from '@mp-npm/mp-auth-client/dist/esm/observables';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import SentryClient from '../../../../integration/sentry/client';
import { AppConfigurator } from '../../../../system/appConfigurator';
import { checkSsoPath, tokenExpiredConfig } from '../../../utils/auth';
import { userCurrentDetailsMpPasswordReset } from '../../user/current/detailsMp/store/slice';

interface AuthProviderProps {
  readonly children: ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const dispatch = useDispatch();

  const onAuthEvent: OnAuthProviderEvent = (eventType, error) => {
    /** use kc observers fire here **/
    switch (eventType) {
      // errors
      case 'onInitError':
        if (error) {
          alert('Авторизация невозможна, обратитесь к разработчикам');
          console.log('Init error', error);
          SentryClient.getInstance().captureException(error.error);
        }
        break;
      case 'onAuthError':
        if (error) {
          alert('Авторизация невозможна, обратитесь к разработчикам');
          console.log('Auth error', error);
          SentryClient.getInstance().captureException(error.error);
        }
        break;
      // refresh token
      case 'onTokenExpired':
        break;
      // events
      case 'onReady':
        break;
      case 'onAuthLogout':
        break;
      case 'onAuthRefreshSuccess':
        break;
      case 'onAuthSuccess':
        break;
      case 'onAuthRefreshError':
        onAuthRefreshErrorObservableSubject.fire();
        break;
    }
  };

  const resetPassword: OnResetPassword = userId => {
    return dispatch(userCurrentDetailsMpPasswordReset({ id: userId }))
      .unwrap()
      .then(() => {
        return true;
      })
      .catch((e: Error) => {
        console.error(e);
        return false;
      });
  };

  return (
    <MPAuthProvider
      onResetPassword={resetPassword}
      tokenExpiredConfig={tokenExpiredConfig}
      config={AppConfigurator.getInstance().getApiAuthService()}
      initOptions={{ silentCheckSsoRedirectUri: checkSsoPath }}
      onAuthEvent={(...args) => onAuthEvent(...args)}
    >
      {children}
    </MPAuthProvider>
  );
};

export default AuthProvider;
