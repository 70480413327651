import changeEmail, { AuthChangeEmailState } from './changeEmail/store/slice';
import { combineReducers } from '@reduxjs/toolkit';

interface AuthState {
  changeEmail: AuthChangeEmailState;
}

export default combineReducers<AuthState>({
  changeEmail,
});
