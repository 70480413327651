import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/data/store/store';
import { ESortDirection } from '@/domain/model/enums';
import { Nullable } from '@/domain/model/types';
import { EBookingOfferTableColumn } from '../../types';
import { BookingOfferShort } from '@/domain/model/booking';

export const bookingOfferSearchBookingOfferSelector = (store: RootState) => store.bookingOffer.search.data;
export const bookingOfferSearchIsFetchingSelector = (store: RootState) => store.bookingOffer.search.isFetching;
export const bookingOfferSearchGet = (store: RootState) => store.bookingOffer.search;
export const bookingOfferSearchGuidSelector = (store: RootState) => store.bookingOffer.search.guid;
export const bookingOfferSearchPageNumberSelector = (store: RootState) => store.bookingOffer.search.pageNumber;
export const bookingOfferSearchSearchSelector = (store: RootState) => store.bookingOffer.search.search;
export const bookingOfferSearchFilterSelector = (store: RootState) => store.bookingOffer.search.filter;
export const bookingOfferSearchSortColumnSelector = (store: RootState) =>
  (store.bookingOffer.search.search.sort?.split(',') as [EBookingOfferTableColumn, ESortDirection])?.[0] ?? '';
export const bookingOfferSearchSortDirectionSelector = (store: RootState) =>
  (store.bookingOffer.search.search.sort?.split(',') as [EBookingOfferTableColumn, ESortDirection])?.[1] ?? '';
export const bookingOfferSearchNeedRefreshWatcherSelector = (store: RootState) =>
  store.bookingOffer.search.needRefreshWatcher;
export const bookingOfferSearchTabsCounterSelector = (store: RootState) => store.bookingOffer.search.tabsCounter;
export const bookingOfferSearchSelectedSelector = (store: RootState) => store.bookingOffer.search.selected;

export const bookingOfferSearchBookingOfferIndexSelector = (store: RootState, index: number) => index;
const createBookingOfferSearchBookingOfferByIndexSelector = createSelector(
  bookingOfferSearchBookingOfferSelector,
  bookingOfferSearchBookingOfferIndexSelector,
  (bookingOffer, index): Nullable<BookingOfferShort> => bookingOffer?.[index] ?? null
);
export const bookingOfferSearchBookingOfferByIndexSelector = (index: number) => (store: RootState) =>
  createBookingOfferSearchBookingOfferByIndexSelector(store, index);
