import { Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { AppFile } from '../../../../../../domain/model';
import { CmsBanner } from '../../../../../../domain/model/cms';
import { ECmsContainerType } from '../../../../../../domain/model/enums';
import { Nullable } from '../../../../../../domain/model/types';
import Notifier from '../../../../../../system/notifier';
import FileUploader from '../../../../../components/common/files/uploader';
import { EEntityPreviewMode } from '../../../../../types';
import { convertBytesToUnitText } from '../../../../../utils/files';
import { createHTMLImageElementByFile } from '../../../../../utils/images';
import { ValidationItemResult } from '../../../../../utils/validation';
import ImagePixelCropContainer from '../../../../general/imageCrop/pixel';
import { CmsPreviewParams } from '../../../types';
import { getCmsPreviewParams } from '../../../utils';

interface CmsContainerEditBannerImageSelectorVariantProps {
  readonly cmsContainerType: ECmsContainerType;
  readonly cmsBanner: CmsBanner;
  readonly validation?: ValidationItemResult;
  readonly activateImageEditor?: boolean;
  readonly onUpload: (file: AppFile) => void;
}

interface CmsContainerEditBannerImageSelectorProps {
  readonly params: CmsPreviewParams;
  readonly validation?: ValidationItemResult;
  readonly activateImageEditor?: boolean;
  readonly onUpload: (file: AppFile) => void;
}

const CmsContainerEditBannerImageSelector = (props: CmsContainerEditBannerImageSelectorProps) => {
  const { validation, params, activateImageEditor, onUpload } = props;
  const {
    restrictions: { recommendedWidthInPx, recommendedHeightInPx, maxFileSizeInBytes, fileType, fileAccept },
  } = params;

  const [imageToEdit, setImageToEdit] = useState<Nullable<File>>(null);
  const [imageEdited, setImageEdited] = useState<Nullable<File>>(null);

  const onFilter = useCallback(
    (files: File[]): Promise<File[]> => {
      if (files.length > 0) {
        const file = files[0];
        return createHTMLImageElementByFile(file).then(({ width, height }) => {
          const isWidthIncorrect = width !== recommendedWidthInPx;
          const isHeightIncorrect = height !== recommendedHeightInPx;

          if (activateImageEditor) {
            if (isWidthIncorrect || isHeightIncorrect) {
              setImageToEdit(file);
              return [];
            }
          } else {
            if (isWidthIncorrect) {
              Notifier.getInstance().addErrorNotice(`Ширина изображения должна быть ${recommendedWidthInPx} пикселей`);
              return [];
            }
            if (isHeightIncorrect) {
              Notifier.getInstance().addErrorNotice(`Высота изображения должна быть ${recommendedHeightInPx} пикселей`);
              return [];
            }
          }
          return [file];
        });
      }
      return Promise.resolve(files);
    },
    [recommendedWidthInPx, recommendedHeightInPx]
  );

  const onChangeEditedImage = (file: Nullable<File>) => {
    setImageEdited(file);
    setImageToEdit(null);
  };

  const fileTypes = fileType
    .map(ft => ft.split('/')[1])
    .join(', ')
    .toUpperCase();
  const fileSize = convertBytesToUnitText(maxFileSizeInBytes);

  const description = `${fileTypes} не более ${fileSize} и размером не более ${recommendedWidthInPx}px на ${recommendedHeightInPx}px`;

  return (
    <>
      <ImagePixelCropContainer
        source={imageToEdit}
        widthInPx={recommendedWidthInPx}
        heightInPx={recommendedHeightInPx}
        onCancel={() => setImageToEdit(null)}
        onChange={onChangeEditedImage}
      />
      <FileUploader
        error={validation?.hasError}
        helperText={validation?.message}
        fileTypes={fileType}
        accept={fileAccept}
        fileMaxSize={maxFileSizeInBytes}
        text={
          <div>
            <Typography
              variant='body1'
              color='primary'
              align='center'
            >
              Загрузить изображение
            </Typography>
            <Typography
              variant='body2'
              color='textSecondary'
              align='center'
            >
              {description}
            </Typography>
          </div>
        }
        externalSource={imageEdited}
        onUpload={onUpload}
        onFilter={onFilter}
      />
    </>
  );
};

const CmsContainerEditBannerImageSelectorDesktop = (props: CmsContainerEditBannerImageSelectorVariantProps) => {
  const { cmsContainerType, cmsBanner } = props;

  const params = getCmsPreviewParams(cmsContainerType, EEntityPreviewMode.Desktop, cmsBanner);

  return (
    <CmsContainerEditBannerImageSelector
      params={params}
      {...props}
    />
  );
};

const CmsContainerEditBannerImageSelectorMobile = (props: CmsContainerEditBannerImageSelectorVariantProps) => {
  const { cmsContainerType, cmsBanner } = props;

  const params = getCmsPreviewParams(cmsContainerType, EEntityPreviewMode.Mobile, cmsBanner);

  return (
    <CmsContainerEditBannerImageSelector
      params={params}
      {...props}
    />
  );
};

export { CmsContainerEditBannerImageSelectorDesktop, CmsContainerEditBannerImageSelectorMobile };
