import { ECorpActivationTableTab } from '../../types';

export type UseCorpActivationTableConfig = {
  readonly tabs: ECorpActivationTableTab[];
  readonly tabsForCounts: ECorpActivationTableTab[];
  readonly defaultTab: ECorpActivationTableTab;
};

export function useCorpActivationTableConfig(): UseCorpActivationTableConfig {
  const tabs = [ECorpActivationTableTab.Approved, ECorpActivationTableTab.Pending];
  const tabsForCounts = [ECorpActivationTableTab.Approved, ECorpActivationTableTab.Pending];
  const defaultTab = ECorpActivationTableTab.Approved;

  return {
    tabs,
    tabsForCounts,
    defaultTab,
  };
}
