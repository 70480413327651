import { createSelector } from '@reduxjs/toolkit';
import { RootStateTyped } from '../../../../../../data/store/store';
import { SearchQueryStat } from '../../../../../../domain/model/analytics';
import { ESortDirection } from '../../../../../../domain/model/enums';
import { EAnalyticsQueriesTableColumn } from '../utils';

export const analyticsQueriesGuidSelector = (store: RootStateTyped) => store.analytics.queries.list.guid;
export const analyticsQueriesAnalyticsQueriesSelector = (store: RootStateTyped) => store.analytics.queries.list.data;
export const analyticsQueriesAnalyticsCountSelector = (store: RootStateTyped) => store.analytics.queries.list.count;
export const analyticsQueriesPageNumberSelector = (store: RootStateTyped) => store.analytics.queries.list.pageNumber;
export const analyticsQueriesTotalCountSelector = (store: RootStateTyped) => store.analytics.queries.list.totalCount;
export const analyticsQueriesPageCountSelector = (store: RootStateTyped) => store.analytics.queries.list.pageCount;
export const analyticsQueriesSearchSelector = (store: RootStateTyped) => store.analytics.queries.list.search;
export const analyticsQueriesSortSelector = (store: RootStateTyped) => store.analytics.queries.list.search.sort;
export const analyticsQueriesNeedRefreshWatcherSelector = (store: RootStateTyped) =>
  store.analytics.queries.list.needRefreshWatcher;
export const analyticsQueriesFilterSelector = (store: RootStateTyped) => store.analytics.queries.list.filter;

export const analyticsQueriesIsFetchingSelector = (store: RootStateTyped) => store.analytics.queries.list.isFetching;

export const analyticsQueriesSortColumnSelector = (store: RootStateTyped) =>
  (store.analytics.queries.list.search.sort?.split(',') as [EAnalyticsQueriesTableColumn, ESortDirection])?.[0] ?? '';
export const analyticsQueriesSortDirectionSelector = (store: RootStateTyped) =>
  (store.analytics.queries.list.search.sort?.split(',') as [EAnalyticsQueriesTableColumn, ESortDirection])?.[1] ?? '';

export const analyticsQueriesBookingOfferIndexSelector = (store: RootStateTyped, index: number) => index;
const createAnalyticsQueriesTableAnalyticsQueryByIndexSelector = createSelector(
  analyticsQueriesAnalyticsQueriesSelector,
  analyticsQueriesBookingOfferIndexSelector,
  (analyticsQueries, index): Nullable<SearchQueryStat> => analyticsQueries?.[index] ?? null
);

export const analyticsQueriesAnalyticsQueryByIndexSelector = (index: number) => (store: RootStateTyped) =>
  createAnalyticsQueriesTableAnalyticsQueryByIndexSelector(store, index);
