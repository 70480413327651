import { Grid, Typography } from '@mui/material';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';
import { MPButton } from '../../../../theme/ui-kit/button';
import { pluralize } from '../../../../utils/pluralize';

interface PartnerApplicationEditNotifierProps {
  readonly considerDuration: number;
  readonly onRead: () => void;
}

const PartnerApplicationEditNotifier = (props: PartnerApplicationEditNotifierProps) => {
  const { considerDuration, onRead } = props;

  const durationText = `${considerDuration} ${pluralize(considerDuration, [
    'рабочего дня',
    'рабочих дней',
    'рабочих дней',
  ])}`;
  const notifierText = `Администратор РЖД Витрины проверит данные вашей организации и ответит в течение ${durationText}`;

  return (
    <ConfirmDialog
      open
      title='Заявка отправлена'
      dialogButtons={
        <Grid container>
          <Grid item>
            <MPButton onClick={onRead}>Отлично</MPButton>
          </Grid>
        </Grid>
      }
      text={<Typography color='textPrimary'>{notifierText}</Typography>}
    ></ConfirmDialog>
  );
};
export default PartnerApplicationEditNotifier;
