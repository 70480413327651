import { EReportType } from '@features/report/types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { OffersReportContainer } from '../reports/offers';
import { reportDownloadsReportsSelector } from '../store/selectors';
import { useReportDownloads } from '../useDownloads';

export const ReportDownloadsOfferAdapter = () => {
  const handlers = useReportDownloads();

  const { offer: data } = useSelector(reportDownloadsReportsSelector);

  const onClose = useCallback(() => {
    handlers.onCloseDownloadDialog(EReportType.Offers);
  }, [handlers.onCloseDownloadDialog]);

  return (
    data && (
      <OffersReportContainer
        partnerId={data.partnerId}
        onClose={onClose}
      />
    )
  );
};
