import { RootState } from '@/data/store/store';
import { CorpOffer, ESortDirection, Nullable } from '@/domain';
import { createSelector } from '@reduxjs/toolkit';
import { ECorpOfferTableColumn } from '../../types';

export const corpOffersCorpOffersSelector = (store: RootState) => store.corpOffer.list.data;
export const corpOffersCountNeedRefreshWatcherSelector = (store: RootState) =>
  store.corpOffer.list.allCorpOffers.needRefreshWatcher;
export const corpOffersIsEmptySelector = (store: RootState) =>
  store.corpOffer.list.allCorpOffers.isFetched && store.corpOffer.list.allCorpOffers.count === 0;
export const corpOffersIsPreloadingSelector = (store: RootState) => store.corpOffer.list.allCorpOffers.count === null;
export const corpOffersPageNumberSelector = (store: RootState) => store.corpOffer.list.pageNumber;
export const corpOffersTotalCountSelector = (store: RootState) => store.corpOffer.list.totalCount;
export const corpOffersPageCountSelector = (store: RootState) => store.corpOffer.list.pageCount;
export const corpOffersSearchSelector = (store: RootState) => store.corpOffer.list.search;
export const corpOffersSortSelector = (store: RootState) => store.corpOffer.list.search.sort;
export const corpOffersSortColumnSelector = (store: RootState) =>
  (store.corpOffer.list.search.sort?.split(',') as [ECorpOfferTableColumn, ESortDirection])?.[0] ?? '';
export const corpOffersSortDirectionSelector = (store: RootState) =>
  (store.corpOffer.list.search.sort?.split(',') as [ECorpOfferTableColumn, ESortDirection])?.[1] ?? '';
export const corpOffersFilterSelector = (store: RootState) => store.corpOffer.list.filter;
export const corpOffersIsFetchingSelector = (store: RootState) =>
  store.corpOffer.list.isFetching || store.corpOffer.list.allCorpOffers.isFetching;

export const corpOffersGuidSelector = (store: RootState) => store.corpOffer.list.guid;
export const corpOffersNotifierInfoGet = (store: RootState) => store.corpOffer.list.notifierInfo;
export const corpOffersNeedRefreshWatcherSelector = (store: RootState) => store.corpOffer.list.needRefreshWatcher;
export const corpOffersActionsSelector = (store: RootState) => store.corpOffer.list.actions;
export const corpOffersCollectionActionsSelector = (store: RootState) => store.corpOffer.list.collectionActions;
export const corpOffersTabsCounterSelector = (store: RootState) => store.corpOffer.list.tabsCounter;
export const corpOffersTabsCounterUnwatchedSelector = (store: RootState) => store.corpOffer.list.tabsCounterUnwatched;
export const corpOffersSelectedSelector = (store: RootState) => store.corpOffer.list.selected;
export const corpOffersUnwatchedIdsSelector = (store: RootState) => store.corpOffer.list.unwatchedIds;
export const corpOffersDialogsStateSelector = (store: RootState) => store.corpOffer.list.dialogs;

export const corpOffersCorpOfferIndexSelector = (store: RootState, index: number) => index;
const createCorpOffersTableCorpOfferByIndexSelector = createSelector(
  corpOffersCorpOffersSelector,
  corpOffersCorpOfferIndexSelector,
  (corpOffers, index): Nullable<CorpOffer> => corpOffers?.[index] ?? null
);
const createCorpOffersTableUnwatchedByIndexSelector = createSelector(
  corpOffersCorpOffersSelector,
  corpOffersUnwatchedIdsSelector,
  corpOffersCorpOfferIndexSelector,
  (corpOffers, unwatchedIds, index): boolean => {
    const corpOffer = corpOffers?.[index] ?? null;
    if (!corpOffer) {
      return false;
    } else {
      return unwatchedIds?.includes(corpOffer.id) ?? false;
    }
  }
);
export const corpOffersCorpOfferByIndexSelector = (index: number) => (store: RootState) =>
  createCorpOffersTableCorpOfferByIndexSelector(store, index);
export const corpOffersUnwatchedByIndexSelector = (index: number) => (store: RootState) =>
  createCorpOffersTableUnwatchedByIndexSelector(store, index);
