import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Partner } from '../../../domain/model/partner';
import ForbiddenComponent from '../../components/common/forbidden';
import BookingOfferActionsProvider from '../../features/bookingOffer/actions/provider';
import { BookingOffersLocationState } from '../../features/bookingOffer/entry';
import BookingOfferTableEntry from '../../features/bookingOffer/table/entry';
import { ECorpOfferUrlParam } from '../../features/corpOffer/entry';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { EOfferTableTab } from '../../features/general/offer/types';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import { viewShowPartnerManagementMenu } from '../../features/partner/management/container/store/slice';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface PartnerManagementBookingOffersScreenProps {
  readonly partner: Partner;
}

const PartnerManagementBookingOffersScreen = ({ partner }: PartnerManagementBookingOffersScreenProps) => {
  const dispatch = useDispatch();

  const {
    accessMatrix: { bookingOffers },
  } = useCurrentUser();

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(ECorpOfferUrlParam.Tab) as EOfferTableTab;

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowPartnerManagementMenu());
  }, [dispatch]);

  if (!bookingOffers?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<BookingOffersLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <BookingOfferActionsProvider>
          <BookingOfferTableEntry
            guid={state.guid}
            tab={tab}
            partnerId={partner.id}
          />
        </BookingOfferActionsProvider>
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default PartnerManagementBookingOffersScreen;
