import {
  Banner,
  BannerPlace,
  BannerRequest,
  EBannerPartition,
  EBannerPlace,
  EMultiSelectorValueType,
  ETargetType,
  Nullable,
  ObjectTarget,
  Target,
} from '@/domain';
import validator from 'validator';
import { EPanelActionPosition, PanelActions } from '../../types';
import { EValidationMessages, EValidationType, ValidationRules } from '../../utils/validation';
import { ContentTargetComponentsType } from '../general/contentTarget/types';
import {
  BannerAction,
  BannerActionDetailsType,
  BannerActionEditType,
  BannerActions,
  EBannerActionType,
  GetBannerActionsByPermissions,
} from './types';

export const bannerImageSize = {
  desktop: {
    width: 1280,
    height: 300,
  },
  mobile: {
    width: 640,
    height: 240,
  },
};

export const bannerTargets: ObjectTarget[] = [
  { id: ETargetType.Geo, name: 'По городам' },
  { id: ETargetType.Corp, name: 'По структуре ОАО «РЖД»' },
];

export const bannerDesktopImageRestriction = `Файл должен быть jpg или png с размерами ${bannerImageSize.desktop.width}х${bannerImageSize.desktop.height}`;

export const bannerMobileImageRestriction = `Файл должен быть jpg или png с размерами ${bannerImageSize.mobile.width}х${bannerImageSize.mobile.height}`;

export const createEmptyBannerRequest = (partition: EBannerPartition): BannerRequest => ({
  url: null,
  name: '',
  placeCodes: null,
  mobileFormatImage: null,
  desktopFormatImage: null,
  startDate: null,
  endDate: null,
  target: {
    targetType: ETargetType.Geo,
    targetClientOrgs: null,
    targetExternalUsers: null,
    // TODO таргет - убрать, если таргет у банеров будет аналогичен кп/тп
    /*targetGender: partition === EBannerPartition.CorpOffers ? { select: EMultiSelectorValueType.All, in: null } : null,*/
    targetGender: null,
    targetLocalities: null,
    targetOrgUnits: null,
    targetRoads: null,
    targetHavingChildFamilyMemberOnly: null,
    targetTradeUnionMembersOnly: null,
    targetFamilyMemberOnly: null,
    targetHasRzdSocialPackage: null,
  },
});

export const createBannerRequestFromBanner = (banner: Banner, defaultPlace: BannerPlace): BannerRequest => ({
  url: banner.url,
  name: banner.name,
  mobileFormatImage: banner.mobileFormatImage,
  desktopFormatImage: banner.desktopFormatImage,
  startDate: banner.startDate,
  endDate: banner.endDate,
  placeCodes: [banner.places?.[0]?.code ?? defaultPlace.code],
  target: banner.target,
});

export const getBannerPlaceByPartition = (places: BannerPlace[], partition: EBannerPartition): BannerPlace => {
  switch (partition) {
    case EBannerPartition.TradeOffers:
      return places.find(place => place.code === EBannerPlace.TradeOffersListPageTop)!;
    case EBannerPartition.CorpOffers:
      return places.find(place => place.code === EBannerPlace.CorpOffersListPageTop)!;
  }
};

export const getBannerPartitionByPlaces = (
  places: BannerPlace[],
  defaultPlace: EBannerPlace = EBannerPlace.TradeOffersListPageTop
): EBannerPartition => {
  const place = places?.[0]?.code ?? defaultPlace;
  switch (place) {
    case EBannerPlace.TradeOffersListPageTop:
      return EBannerPartition.TradeOffers;
    case EBannerPlace.CorpOffersListPageTop:
      return EBannerPartition.CorpOffers;
  }
};

export const getBannerLabelByPartition = (partition: EBannerPartition): string => {
  switch (partition) {
    case EBannerPartition.TradeOffers:
      return 'ТП';
    case EBannerPartition.CorpOffers:
      return 'КП';
  }
};

export const getBannerActionTitle = (banner: Nullable<Banner>, partition: EBannerPartition): string => {
  const partitionLabel = getBannerLabelByPartition(partition);

  if (banner) {
    return `Рекламный баннер ${partitionLabel}`;
  } else {
    return `Создание баннера ${partitionLabel}`;
  }
};

export const getBannerPlacesByPartition = (places: BannerPlace[], partition: EBannerPartition): BannerPlace[] => {
  switch (partition) {
    case EBannerPartition.TradeOffers:
      return places.filter(place => place.code === EBannerPlace.TradeOffersListPageTop)!;
    case EBannerPartition.CorpOffers:
      return places.filter(place => place.code === EBannerPlace.CorpOffersListPageTop)!;
  }
};

export const getBannerActionName = (type: EBannerActionType): string => {
  switch (type) {
    case EBannerActionType.Pause:
      return 'Приостановить';
    case EBannerActionType.Archive:
      return 'В архив';
    case EBannerActionType.Resume:
      return 'Возобновить';
    case EBannerActionType.Activate:
      return 'Опубликовать';
    case EBannerActionType.Save:
      return 'Сохранить и закрыть';
    case EBannerActionType.Edit:
      return 'Редактировать';
    case EBannerActionType.ChangeSortIndex:
      return 'Изменить индекс сортировки';
  }
};

export const getBannerEditActions = (isDraft: boolean, isPaused: boolean): PanelActions<BannerActionEditType> => {
  const actions: PanelActions<BannerActionEditType> = [];

  if (isDraft) {
    actions.push({
      type: EBannerActionType.Activate,
      label: getBannerActionName(EBannerActionType.Activate),
      primary: true,
      position: [EPanelActionPosition.Default],
    });
  }

  if (isPaused) {
    actions.push({
      type: EBannerActionType.Resume,
      label: getBannerActionName(EBannerActionType.Resume),
      primary: true,
      position: [EPanelActionPosition.Default],
    });
  }

  actions.push({
    type: EBannerActionType.Save,
    label: getBannerActionName(EBannerActionType.Save),
    position: [EPanelActionPosition.Default],
  });

  return actions;
};

export const getBannerDetailsActions = (canModify: boolean): PanelActions<BannerActionDetailsType> => {
  const actions: PanelActions<BannerActionDetailsType> = [];

  if (canModify) {
    actions.push({
      type: EBannerActionType.Edit,
      label: getBannerActionName(EBannerActionType.Edit),
      position: [EPanelActionPosition.Menu],
    });
  }

  return actions;
};

export const bannerDraftValidationRules: ValidationRules<BannerRequest> = {
  placeCodes: {
    validator: (object, value) => {
      if (!value) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EValidationMessages.Empty,
        };
      }
      return null;
    },
  },
  name: {
    required: true,
  },
};

export const bannerValidationRules: ValidationRules<BannerRequest> = {
  ...bannerDraftValidationRules,
  desktopFormatImage: {
    required: true,
  },
  mobileFormatImage: {
    required: true,
  },
  startDate: {
    required: true,
  },
  endDate: {
    required: true,
  },
  url: {
    required: true,
    validator: (object, value) => {
      if (!value) return null;

      const isURL = validator.isURL(value);
      if (!isURL) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EValidationMessages.URLInvalid,
        };
      } else {
        return null;
      }
    },
  },
};

export const getBannerTargetValidationRules = (partition: EBannerPartition): ValidationRules<Target> => {
  return {
    targetType: {
      required: true,
    },
    targetGender: {
      required: object => partition === EBannerPartition.CorpOffers && object.targetType === ETargetType.Corp,
      validator: (object, value) => {
        if (object.targetType !== ETargetType.Corp || partition !== EBannerPartition.CorpOffers) return null;
        if (!value || (value.select === EMultiSelectorValueType.In && !value.in?.length)) {
          return {
            type: EValidationType.Error,
            hasError: true,
            message: 'Выберите пол или <<Для всех>>',
          };
        }

        return null;
      },
    },
    targetExternalUsers: {
      validator: (object, value) => {
        if (object.targetType !== ETargetType.Geo) return null;

        const clientOrgs = object.targetClientOrgs;
        return value || clientOrgs?.in?.length || clientOrgs?.select === EMultiSelectorValueType.All
          ? null
          : {
              type: EValidationType.Error,
              hasError: true,
              message: 'Выберите внешние пользователи или компании',
            };
      },
    },
    targetClientOrgs: {
      validator: (object, value) => {
        if (object.targetType !== ETargetType.Geo) return null;

        return object.targetExternalUsers || value?.in?.length || value?.select === EMultiSelectorValueType.All
          ? null
          : {
              type: EValidationType.Error,
              hasError: true,
              message: 'Выберите компании или внешние пользователи',
            };
      },
    },
    targetLocalities: {
      required: object => object.targetType === ETargetType.Geo,
      validator: (object, value) => {
        if (object.targetType !== ETargetType.Geo) return null;

        if (!value || (value.select === EMultiSelectorValueType.In && !value.in?.length)) {
          return {
            type: EValidationType.Error,
            hasError: true,
            message: 'Выберите города или <<Вся Россия>>',
          };
        }

        return null;
      },
    },
    targetRoads: {
      required: object => object.targetType === ETargetType.Corp,
      validator: (object, value) => {
        if (object.targetType !== ETargetType.Corp) return null;

        if (!value || (value.select === EMultiSelectorValueType.In && !value.in?.length)) {
          return {
            type: EValidationType.Error,
            hasError: true,
            message: 'Выберите железные дороги или <<Все дороги>>',
          };
        }

        return null;
      },
    },
    targetOrgUnits: {
      required: object => object.targetType === ETargetType.Corp,
      validator: (object, value) => {
        if (object.targetType !== ETargetType.Corp) return null;

        if (!value || (value.select === EMultiSelectorValueType.In && !value.in?.length)) {
          return {
            type: EValidationType.Error,
            hasError: true,
            message: 'Выберите филиалы или <<Все филиалы>>',
          };
        }

        return null;
      },
    },
  };
};

type BannerActionType = BannerAction<EBannerActionType>;
type BannerActionsType = BannerActions<EBannerActionType>;

// получение разрешенных действий с баннером с учетом разрешений
export const getBannerActionsConfigByPermissions = (props: GetBannerActionsByPermissions): BannerActionsType => {
  const { canActivate, canArchive, canResume, canPause, canSave, canEdit, canChangeSortIndex } = props;

  const actions: BannerActionsType = [];

  let onActivate: Nullable<BannerActionType> = null;
  let onArchive: Nullable<BannerActionType> = null;
  let onResume: Nullable<BannerActionType> = null;
  let onPause: Nullable<BannerActionType> = null;
  let onSave: Nullable<BannerActionType> = null;
  let onEdit: Nullable<BannerActionType> = null;
  let onChangeSortIndex: Nullable<BannerActionType> = null;

  if (canActivate) {
    onActivate = {
      type: EBannerActionType.Activate,
      disabled: false,
      label: getBannerActionName(EBannerActionType.Activate),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onActivate);
  }
  if (canResume) {
    onResume = {
      type: EBannerActionType.Resume,
      disabled: false,
      label: getBannerActionName(EBannerActionType.Resume),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onResume);
  }
  if (canSave) {
    onSave = {
      type: EBannerActionType.Save,
      disabled: false,
      label: getBannerActionName(EBannerActionType.Save),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onSave);
  }
  if (canPause) {
    onPause = {
      type: EBannerActionType.Pause,
      disabled: false,
      label: getBannerActionName(EBannerActionType.Pause),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onPause);
  }
  if (canArchive) {
    onArchive = {
      type: EBannerActionType.Archive,
      disabled: false,
      label: getBannerActionName(EBannerActionType.Archive),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onArchive);
  }
  if (canEdit) {
    onEdit = {
      type: EBannerActionType.Edit,
      disabled: false,
      label: getBannerActionName(EBannerActionType.Edit),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onEdit);
  }
  if (canChangeSortIndex) {
    onChangeSortIndex = {
      type: EBannerActionType.ChangeSortIndex,
      disabled: false,
      label: getBannerActionName(EBannerActionType.ChangeSortIndex),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onChangeSortIndex);
  }

  return actions;
};

export const bannerTargetByLocalitiesAttributes: ContentTargetComponentsType = {
  localities: true,
  clientOrgs: true,
  externalUsers: true,
};

export const bannerTargetByCorpStructureAttributes: ContentTargetComponentsType = {
  gender: true,
  roads: true,
  orgUnits: true,
  havingChildFamilyMemberOnly: true,
};
