import { Step as MuiStep } from '@mui/material';
import { memo } from 'react';
import styled from '@emotion/styled';

interface StepperWrapperProps {
  readonly flow?: boolean;
  readonly children: any;
}

export const StepperWrapper = styled(({ flow, ...others }: StepperWrapperProps) => <div {...others} />)`
  flex: 1;

  ${p =>
    !p.flow &&
    `
      .MuiStepLabel-root.Mui-disabled .MuiStepIcon-active text {
        fill: ${p.theme.palette.text.onPrimary};
      }
  `}
`;

export const Step = memo(styled(MuiStep)<{ active?: boolean }>`
  ${p =>
    p.onClick &&
    `
  .MuiStepLabel-iconContainer, .MuiStepLabel-labelContainer, .MuiStepLabel-completed {
    cursor: pointer !important;
  }
  `}
`);
