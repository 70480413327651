import { FileUploadObject } from '../../../../domain/model';
import { EValidationType, ValidationRules } from '../../../utils/validation';

export const productStocksUploadValidation: ValidationRules<FileUploadObject> = {
  file: {
    validator: (object, value) => {
      if (!value) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Загрузите файл',
        };
      } else {
        return null;
      }
    },
  },
};
