import { MenuItem } from '@mui/material';
import styled from '@emotion/styled';

const ItemWrapper = styled(MenuItem)`
  padding-left: ${p => p.theme.spacing(4)};

  > .MuiSvgIcon-root {
    position: absolute;
    left: ${p => p.theme.spacing(0.5)};
  }

  .MuiFormControlLabel-root .MuiSvgIcon-root {
    margin-right: ${p => p.theme.spacing()};
  }

  .MuiTypography-root {
    white-space: pre-wrap;
  }
`;

export interface RootItemWrapperProps {
  readonly expanded?: boolean;
}

export const RootItemWrapper = styled(ItemWrapper)<RootItemWrapperProps>`
  > .MuiSvgIcon-root {
    rotate: ${p => (p.expanded ? '90deg' : '0deg')};
    transition: rotate 200ms;
  }
`;

export const LeafItemWrapper = styled(ItemWrapper)`
  padding-left: ${p => p.theme.spacing(7)};
`;
