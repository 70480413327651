import AppBreadcrumbs from '@components/common/breadcrumbs';
import { Typography } from '@mui/material';
import DefaultHeader from '../../../../components/common/header';
import { TitleWrapper } from '../controls';

export const ClientOrgDetailsHeaderAdapter = () => {
  return (
    <DefaultHeader
      sticky
      headline={<AppBreadcrumbs />}
    >
      <TitleWrapper>
        <Typography variant='h2'>Настройка Витрины</Typography>
      </TitleWrapper>
    </DefaultHeader>
  );
};
