import { EPanelActionPosition } from '@/presentation/types';
import {
  ClientOrgAction,
  ClientOrgActionCreateType,
  ClientOrgActionDetailsType,
  ClientOrgActions,
  ClientOrgActionTableType,
  EClientOrgActionType,
  GetClientOrgActionsByPermissions,
} from '../types';

export const getClientOrgActionName = (type: EClientOrgActionType): string => {
  switch (type) {
    case EClientOrgActionType.Edit:
      return 'Редактировать';
    case EClientOrgActionType.UnBlock:
      return 'Активировать';
    case EClientOrgActionType.Block:
      return 'Приостановить';
  }
};

// получение разрешенных действий с партнером с учетом разрешений
export const getClientOrgActionsConfigByPermissions = (props: GetClientOrgActionsByPermissions): ClientOrgActions => {
  const { edit, activate, deactivate } = props;

  const actions: ClientOrgActions = [];

  let onSave: Nullable<ClientOrgAction<EClientOrgActionType>> = null;
  let onActivate: Nullable<ClientOrgAction<EClientOrgActionType>> = null;
  let onDeactivate: Nullable<ClientOrgAction<EClientOrgActionType>> = null;

  if (edit) {
    onSave = {
      type: EClientOrgActionType.Edit,
      disabled: false,
      label: getClientOrgActionName(EClientOrgActionType.Edit),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onSave);
  }

  if (activate) {
    onActivate = {
      type: EClientOrgActionType.UnBlock,
      disabled: false,
      label: getClientOrgActionName(EClientOrgActionType.UnBlock),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onActivate);
  }

  if (deactivate) {
    onDeactivate = {
      type: EClientOrgActionType.Block,
      disabled: false,
      label: getClientOrgActionName(EClientOrgActionType.Block),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onDeactivate);
  }

  return actions;
};

export const removeClientOrgAction = (actions: ClientOrgActions, type: EClientOrgActionType) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions.splice(index, 1);
  }
};

export const disableClientOrgAction = (actions: ClientOrgActions, type: EClientOrgActionType) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      disabled: true,
    };
  }
};

export const changeClientOrgActionPosition = (
  actions: ClientOrgActions<any>,
  type: EClientOrgActionType,
  position: EPanelActionPosition[]
) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      position,
    };
  }
};

export const renameClientOrgAction = (actions: ClientOrgActions, type: EClientOrgActionType, label: string) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      label,
    };
  }
};

export const makeClientOrgActionPrimary = (actions: ClientOrgActions<any>, types: EClientOrgActionType[]) => {
  return actions.map(action => ({
    ...action,
    primary: types.includes(action.type) ? true : action.primary,
  }));
};

export const isClientOrgActionActionEnabled = (actions: ClientOrgActions<any>, type: EClientOrgActionType) => {
  return actions.some(action => action.type === type && !action.disabled);
};

// фильтрация действий для создания компании по всем действиям
export const filterClientOrgCreateActions = (
  actions: ClientOrgActions
): ClientOrgActions<ClientOrgActionCreateType> => {
  const createActions: ClientOrgActions<ClientOrgActionCreateType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EClientOrgActionType.Edit:
        createActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          primary: action.primary,
          position: action.position,
        });
        break;
      case EClientOrgActionType.Block:
      case EClientOrgActionType.UnBlock:
        break;
    }
  });

  return createActions;
};

// фильтрация действий для просмотра компании по всем действиям
export const filterClientOrgDetailsActions = (
  actions: ClientOrgActions
): ClientOrgActions<ClientOrgActionDetailsType> => {
  const createActions: ClientOrgActions<ClientOrgActionDetailsType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EClientOrgActionType.Edit:
        createActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          primary: action.primary,
          position: action.position,
        });
        break;
      case EClientOrgActionType.UnBlock:
      case EClientOrgActionType.Block:
        break;
    }
  });

  return createActions;
};

// фильтрация действий для просмотра компании по всем действиям
export const filterClientOrgTableActions = (actions: ClientOrgActions): ClientOrgActions<ClientOrgActionTableType> => {
  const createActions: ClientOrgActions<ClientOrgActionTableType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EClientOrgActionType.UnBlock:
      case EClientOrgActionType.Block:
        createActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          primary: action.primary,
          position: action.position,
        });
        break;
      case EClientOrgActionType.Edit:
        break;
    }
  });

  return createActions;
};
