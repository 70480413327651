import { EUserStatus } from '../../../../domain/model/enums';
import { Nullable } from '../../../../domain/model/types';
import { EPanelActionPosition } from '../../../types';
import { AdminMpAction, AdminMpActions, EAdminMpActionType, GetAdminMpActionsByPermissions } from './types';

export const getAdminMpActionName = (type: EAdminMpActionType): string => {
  switch (type) {
    case EAdminMpActionType.Create:
      return 'Добавить';
    case EAdminMpActionType.Save:
      return 'Сохранить';
    case EAdminMpActionType.Edit:
      return 'Редактировать';
    case EAdminMpActionType.Activate:
      return 'Включить';
    case EAdminMpActionType.Deactivate:
      return 'Отключить';
  }
};

type AdminMpActionType = AdminMpAction<EAdminMpActionType>;
type AdminMpActionsType = AdminMpActions<EAdminMpActionType>;

// получение разрешенных действий с админом с учетом разрешений
export const getAdminMpActionsConfigByPermissions = (props: GetAdminMpActionsByPermissions): AdminMpActionsType => {
  const { canActivate, canDeactivate, canSave, canEdit } = props;

  const actions: AdminMpActionsType = [];

  let onActivate: Nullable<AdminMpActionType> = null;
  let onDeactivate: Nullable<AdminMpActionType> = null;
  let onSave: Nullable<AdminMpActionType> = null;
  let onEdit: Nullable<AdminMpActionType> = null;

  if (canActivate) {
    onActivate = {
      type: EAdminMpActionType.Activate,
      disabled: false,
      label: getAdminMpActionName(EAdminMpActionType.Activate),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onActivate);
  }
  if (canDeactivate) {
    onDeactivate = {
      type: EAdminMpActionType.Deactivate,
      disabled: false,
      label: getAdminMpActionName(EAdminMpActionType.Deactivate),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onDeactivate);
  }
  if (canSave) {
    onSave = {
      type: EAdminMpActionType.Save,
      disabled: false,
      label: getAdminMpActionName(EAdminMpActionType.Save),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onSave);
  }
  if (canEdit) {
    onEdit = {
      type: EAdminMpActionType.Edit,
      disabled: false,
      label: getAdminMpActionName(EAdminMpActionType.Edit),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onEdit);
  }

  return actions;
};

export type AdminMpCounterByStatus = {
  [name in EUserStatus]?: number;
};
