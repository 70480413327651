import React from 'react';
import { FileDescription } from '../../../../../../../domain/model';
import { Nullable } from '../../../../../../../domain/model/types';
import { BackgroundImageStub, ForegroundImageStub, ImageWrapper, Number, StyledImage, Wrapper } from './controls';

interface CmsCarouselPreviewBlockProps {
  readonly image?: Nullable<FileDescription | string>;
  readonly width?: Nullable<string>;
  readonly height?: Nullable<string>;
  readonly number: number;
  readonly caption?: Nullable<React.ReactNode>;
  readonly isActive: boolean;
  readonly isForeground: boolean;
  readonly canActivateItem?: boolean;
  readonly showNumber?: boolean;
}

interface ImageStubProps {
  readonly isActive: boolean;
  readonly isForeground: boolean;
  readonly canActivateItem?: boolean;
}

const ImageStub = ({ isActive, isForeground, canActivateItem }: ImageStubProps) =>
  isForeground ? (
    <ForegroundImageStub isActive={isActive || !canActivateItem} />
  ) : (
    <BackgroundImageStub canActivateItem={canActivateItem} />
  );

const CmsCarouselPreviewBlock = (props: CmsCarouselPreviewBlockProps) => {
  const { image, width, height, number, caption, isForeground, isActive, canActivateItem, showNumber } = props;

  return (
    <Wrapper
      aria-checked={isForeground}
      width={width}
      height={height}
    >
      <ImageWrapper>
        {image ? (
          <StyledImage src={image} />
        ) : (
          <ImageStub
            isForeground={isForeground}
            isActive={isActive}
            canActivateItem={canActivateItem}
          />
        )}
      </ImageWrapper>
      {caption}
      {showNumber && (
        <Number
          align='center'
          isActive={isActive}
          variant='h2'
        >
          {number}
        </Number>
      )}
    </Wrapper>
  );
};

export default CmsCarouselPreviewBlock;
