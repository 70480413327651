import { ListItemButton, ListItemIcon, Typography } from '@mui/material';
import styled from '@emotion/styled';

export const HeaderItem = styled(ListItemButton)`
  padding-top: ${p => p.theme.spacing(2)};
  padding-bottom: ${p => p.theme.spacing(2)};
`;

export const LogoIconWrapper = styled(ListItemIcon)`
  min-width: 1.2rem;
`;

export const UserType = styled(Typography)`
  line-height: 1.5rem;
`;
