import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { paginationSizeVariant } from '../../../../../domain/model/constants';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { bookingOffersCountNeedRefreshWatcherSelector, bookingOffersGuidSelector } from '../store/selectors';
import { bookingOffersCountFetch, BookingOffersFetchProps } from '../store/slice';

interface UseBookingOffersTableCountProps {
  readonly partnerId: Nullable<UUID>;
}

const useBookingOffersTableCount = (props: UseBookingOffersTableCountProps): void => {
  const { partnerId } = props;

  const dispatch = useDispatch();

  const guid = useSelector(bookingOffersGuidSelector);
  const needRefreshWatcher = useSelector(bookingOffersCountNeedRefreshWatcherSelector);

  const queryParams = useMemo<BookingOffersFetchProps>(() => {
    return {
      search: {
        partnerId,
        statuses: [],
        categories: null,
        sort: null,
        pageSize: paginationSizeVariant[0],
      },
      filter: {},
      pageNumber: 0,
    };
  }, [partnerId]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      const promise = dispatch(bookingOffersCountFetch(queryParams));
      return () => {
        promise?.abort();
      };
    }
  }, [dispatch, needRefreshWatcher, queryParams]);

  // начальный поиск и отмена запроса после ухода
  useEffect(() => {
    const promise = dispatch(bookingOffersCountFetch(queryParams));
    return () => {
      promise?.abort();
    };
  }, [dispatch, queryParams, guid]);
};

export default useBookingOffersTableCount;
