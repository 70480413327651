import { ECmsCollectionLinkObjectType, EOfferStatus, EOfferType, TradeOffer, UUID } from '@/domain';
import useTechConfig from '@/presentation/hooks/useTechConfig';
import { EAppFeature, NumberRange } from '@/presentation/types';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import { getTradeOfferDetailsRoute } from '@features/tradeOffer/entry';
import { isAspPromotionType } from '@features/tradeOffer/utils';
import { viewConfig } from '@theme/viewConfig';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import TopPushDialog from '../../../../../../components/common/dialogs/topPush';
import TradeOfferSearchContainer from '../../../../../tradeOffer/search/container';
import useCurrentUser from '../../../../../user/hooks/useCurrentUser';
import CmsOfferMultipleCollectionComponent from '../../../../components/collection/offers/multiple';
import { CmsContainerView } from '../../../../types';
import { CmsLinkedObjectCollectionTypes } from '../../../../types/collection';
import { CmsFeatureContainerCommonProps } from '../../../types';
import { getCmsSearchTradeOfferPresetValues } from '../../utils';
import useCmsCollectionOfferMultiple from '../useChangeOffersMultiple';
import useCmsCollectionTradeOfferFilterAdapter from './useFilterAdapter';

interface CmsTradeOfferMultipleCollectionContainerProps extends CmsFeatureContainerCommonProps {
  readonly guid: UUID;
  readonly offers: TradeOffer[];
  readonly statuses?: EOfferStatus[];
  readonly cmsContainer: CmsContainerView;
  readonly selectedMaxCount?: NumberRange<2, 100> | false;
  readonly onChangeLinkedObject: (banner: CmsLinkedObjectCollectionTypes) => void;
}

const CmsTradeOfferMultipleCollectionContainer = (props: CmsTradeOfferMultipleCollectionContainerProps) => {
  const { guid, offers, statuses, cmsContainer, cmsContext, selectedMaxCount, onChangeLinkedObject } = props;

  const history = useHistory();
  const { accessMatrix } = useCurrentUser();
  const { hasFeature } = useTechConfig();
  const isAspFeature = hasFeature(EAppFeature.Asp);

  const { promotionTypes: tradePromotionTypes, userGenders: genderTypes } = useSelector(nsiDataSelector);

  const promotionTypes = isAspFeature
    ? tradePromotionTypes
    : tradePromotionTypes.filter(promotionType => !isAspPromotionType(promotionType.id));

  const filterPresetValues = useMemo(() => getCmsSearchTradeOfferPresetValues(cmsContainer), [cmsContainer]);

  const {
    isSearchDialogOpened,
    searchDialogParams,
    onOpenSearchDialog,
    onCloseSearchDialog,
    onChangeSearchDialogTab,
    onSelect,
    onClear,
    onUnselect,
    onChangeSortIndex,
  } = useCmsCollectionOfferMultiple<TradeOffer>({
    linkObjectType: ECmsCollectionLinkObjectType.TradeOffer,
    selected: offers,
    onChangeLinkedObject,
  });

  const filterAdapter = useCmsCollectionTradeOfferFilterAdapter({
    viewAsAdmin: accessMatrix.isAdminMp,
    tab: searchDialogParams.dialog_tab,
    partnerId: cmsContext?.partner.id ?? null,
    values: filterPresetValues,
    promotionTypes,
    genderTypes,
  });

  const onOfferClick = useCallback(
    (id: UUID) => {
      history.push(getTradeOfferDetailsRoute({ id }));
    },
    [history]
  );

  return (
    <>
      <CmsOfferMultipleCollectionComponent
        offers={offers}
        offerType={EOfferType.Trade}
        onShowOfferSearch={onOpenSearchDialog}
        onClearOffers={onClear}
        onRemoveOffer={onUnselect}
        onChangeSortIndex={onChangeSortIndex}
      />
      <TopPushDialog
        fullScreen
        open={isSearchDialogOpened}
        title='Выбор торговых предложений'
        leftOffset={viewConfig.stepper.width}
        onClose={onCloseSearchDialog}
      >
        <TradeOfferSearchContainer
          guid={searchDialogParams?.dialog_guid ?? guid}
          partnerId={cmsContext?.partner.id ?? null}
          statuses={statuses}
          filterAdapter={filterAdapter}
          initialSelected={offers}
          selectedMaxCount={selectedMaxCount}
          tab={searchDialogParams.dialog_tab}
          onTradeOfferClick={tradeOffer => onOfferClick(tradeOffer.id)}
          onSelect={onSelect}
          onChangeTableTab={onChangeSearchDialogTab}
        />
      </TopPushDialog>
    </>
  );
};

export default CmsTradeOfferMultipleCollectionContainer;
