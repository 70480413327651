import styled from '@emotion/styled';
import { viewConfig } from '../../../theme/viewConfig';

export const ContainerWrapper = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  height: 100%;
  max-width: 100%;

  display: flex;
  flex-direction: column;

  padding-bottom: ${viewConfig.table.paginationSize};
`;

export const FooterActionsWrapper = styled.div`
  border-top: 1px solid ${p => p.theme.palette.secondary.main};

  padding: ${p => p.theme.spacing(2)};
`;
