import { EOfferType, SportOptionTyped } from '@/domain';
import { AdCampaignTableCell, AdCampaignTableCellCommonProps } from './index';

export type AdCampaignTableCellOfferTypeProps = AdCampaignTableCellCommonProps & {
  readonly offerTypes: SportOptionTyped<EOfferType>[];
};

export const AdCampaignTableCellOfferType = (props: AdCampaignTableCellOfferTypeProps) => {
  const { adCampaign, offerTypes } = props;

  const value = offerTypes.find(offerType => offerType.id === adCampaign.offerType)?.name;

  return (
    <AdCampaignTableCell
      {...props}
      value={value}
    />
  );
};
