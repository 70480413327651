import { FC } from 'react';
import { FileDescription } from '../../../../../domain/model';
import { Nullable } from '../../../../../domain/model/types';
import { EEntityPreviewMode } from '../../../../types';
import { OfferPreviewCaption, OfferPreviewSkeleton } from '../../../general/offer/components/preview/controls';
import OfferPreviewLogo from '../../../general/offer/components/preview/logo';
import { Footer } from './controls';

type BookingOfferPreviewFooterProps = {
  readonly mode: EEntityPreviewMode;
  readonly address: Nullable<string>;
  readonly logo: Nullable<string | FileDescription>;
};

const BookingOfferPreviewFooter: FC<BookingOfferPreviewFooterProps> = props => {
  const { address, logo } = props;
  return (
    <Footer>
      {address ? (
        <OfferPreviewCaption
          variant='body2'
          color='textSecondary'
        >
          {address}
        </OfferPreviewCaption>
      ) : (
        <OfferPreviewSkeleton
          animation={false}
          variant='rectangular'
          width='75%'
          height='1.375rem'
        />
      )}
      <OfferPreviewLogo image={logo} />
    </Footer>
  );
};

export default BookingOfferPreviewFooter;
