import { DictionaryItemContentProps } from '../../../types';
import { DictionaryItemConstructor } from '../index';
import { DictionaryItemElementArrow } from '../../element';
import { ReactNode } from 'react';

type DictionaryCommonItemProps<T> = DictionaryItemContentProps<T> & {
  readonly endAdornment?: ReactNode;
};

export const DictionaryCommonItem = <T extends object>(props: DictionaryCommonItemProps<T>) => {
  const { data, expanded, endAdornment } = props;

  let endAdornmentToDisplay = endAdornment;
  if (endAdornmentToDisplay === undefined) {
    endAdornmentToDisplay = Array.isArray(data.children) && data.children.length > 0 && (
      <DictionaryItemElementArrow active={expanded} />
    );
  }

  return (
    <DictionaryItemConstructor
      {...props}
      content={data.label}
      endAdornment={endAdornmentToDisplay}
    />
  );
};
