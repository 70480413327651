import { FileDescription } from '../../../domain/model';
import { PartnerView } from '../../../domain/model/partner';
import { Nullable } from '../../../domain/model/types';
import { EPanelActionPosition } from '../../types';
import {
  EPartnerActionType,
  GetPartnerActionsByPermissions,
  PartnerAction,
  PartnerActions,
} from '../general/partner/types';

export const getPartnerLogo = (partner: Pick<PartnerView, 'type' | 'logo'>): Nullable<string | FileDescription> =>
  partner.logo?.path ? partner.logo : null;

export const getPartnerActionName = (type: EPartnerActionType): string => {
  switch (type) {
    case EPartnerActionType.Save:
      return 'Сохранить и завершить редактирование';
    case EPartnerActionType.Edit:
      return 'Редактировать';
    case EPartnerActionType.EditDesk:
      return 'Редактировать';
    case EPartnerActionType.Activate:
      return 'Включить партнера';
    case EPartnerActionType.Deactivate:
      return 'Приостановить партнера';
    case EPartnerActionType.ChangeSortIndex:
      return 'Изменить место';
    case EPartnerActionType.ReturnToVerification:
      return 'Вернуть на проверку';
    case EPartnerActionType.Delete:
      return 'Удалить';
  }
};

// получение разрешенных действий с партнером с учетом разрешений
export const getPartnerActionsConfigByPermissions = (
  props: GetPartnerActionsByPermissions
): PartnerActions<EPartnerActionType> => {
  const { canActivate, canDeactivate, canSave, canEdit, canEditDesk, canDelete, canReturnToVerification } = props;

  const actions: PartnerActions<EPartnerActionType> = [];

  let onActivate: Nullable<PartnerAction<EPartnerActionType>> = null;
  let onDeactivate: Nullable<PartnerAction<EPartnerActionType>> = null;
  let onSave: Nullable<PartnerAction<EPartnerActionType>> = null;
  let onEdit: Nullable<PartnerAction<EPartnerActionType>> = null;
  let onEditDesk: Nullable<PartnerAction<EPartnerActionType>> = null;
  let onDelete: Nullable<PartnerAction<EPartnerActionType>> = null;
  let onReturnToVerification: Nullable<PartnerAction<EPartnerActionType>> = null;

  if (canActivate) {
    onActivate = {
      type: EPartnerActionType.Activate,
      disabled: false,
      label: getPartnerActionName(EPartnerActionType.Activate),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onActivate);
  }

  if (canDeactivate) {
    onDeactivate = {
      type: EPartnerActionType.Deactivate,
      disabled: false,
      label: getPartnerActionName(EPartnerActionType.Deactivate),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onDeactivate);
  }

  if (canSave) {
    onSave = {
      type: EPartnerActionType.Save,
      disabled: false,
      label: getPartnerActionName(EPartnerActionType.Save),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onSave);
  }

  if (canEdit) {
    onEdit = {
      type: EPartnerActionType.Edit,
      disabled: false,
      label: getPartnerActionName(EPartnerActionType.Edit),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onEdit);
  }

  if (canEditDesk) {
    onEditDesk = {
      type: EPartnerActionType.EditDesk,
      disabled: false,
      label: getPartnerActionName(EPartnerActionType.EditDesk),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onEditDesk);
  }

  if (canDelete) {
    onDelete = {
      type: EPartnerActionType.Delete,
      disabled: false,
      label: getPartnerActionName(EPartnerActionType.Delete),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onDelete);
  }

  if (canReturnToVerification) {
    onReturnToVerification = {
      type: EPartnerActionType.ReturnToVerification,
      disabled: false,
      label: getPartnerActionName(EPartnerActionType.ReturnToVerification),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onReturnToVerification);
  }

  return actions;
};
