import { Nullable } from '@/domain';
import { MPStepperStep } from '@components/common/stepper';
import { ReactElement } from 'react';

export enum ETradeOfferStep {
  General = 'general',
  Widget = 'widget',
  Description = 'description',
  Conditions = 'conditions',
  PromoCodes = 'promo_codes',
}

export type TradeOfferDetailsStepType = Exclude<ETradeOfferStep, ETradeOfferStep.Widget>;
export type TradeOfferDetailsStep = MPStepperStep<TradeOfferDetailsStepType>;

export type TradeOfferCreateStepType = ETradeOfferStep;
export type TradeOfferCreateStep = MPStepperStep<TradeOfferCreateStepType>;

export enum ETradeOfferTableColumn {
  LastStatusDate = 'lastStatusDate',
  Code = 'code',
  Name = 'name',
  PromotionType = 'promotionType',
  Partner = 'partner.name',
  Price = 'price',
  Period = 'startDate',
  Status = 'status',
  ApprovingAdmin = 'approvingAdmin',
  LastStatusAuthor = 'lastStatusAuthor',
  OfferCounts = 'notUsedOfferCount',
  PausedReason = 'pausedReason',
  TargetLocalities = 'targetLocalities',
  TargetClientOrgs = 'targetClientOrgs',
  TargetExternalUsers = 'targetExternalUsers',
  AggregatorIntegrationId = 'aggregatorIntegrationId',
}

export type TradeOfferTableStubAdapterProps = {
  readonly children: (props: TradeOfferTableStubAdapterResult) => ReactElement<any, any>;
};

export type TradeOfferTableStubAdapterResult = {
  readonly canViewTable: boolean;
  readonly isCreateDisabled?: boolean;
  readonly stub?: Nullable<JSX.Element>;
  readonly createInfo?: Nullable<string>;
};

export type TradeOfferTableStubAdapterType = (props: TradeOfferTableStubAdapterProps) => ReactElement<any, any>;

export enum TradeOfferActionType {}
