import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export interface MainViewStore {
  readonly mainMenu: {
    readonly visible: boolean;
  };
}

type Reducer<T = undefined> = CaseReducer<MainViewStore, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<MainViewStore> {
  viewShowMainMenu: Reducer;
  viewHideMainMenu: Reducer;
}

const slice = createSlice<MainViewStore, Reducers, 'mainView'>({
  name: 'mainView',
  initialState: {
    mainMenu: {
      visible: false,
    },
  },
  reducers: {
    viewShowMainMenu(state) {
      state.mainMenu.visible = true;
    },
    viewHideMainMenu(state) {
      state.mainMenu.visible = false;
    },
  },
});

export const { viewShowMainMenu, viewHideMainMenu } = slice.actions;

export default slice.reducer;
