import { Divider, Grid, IconButton, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddIcon from '@mui/icons-material/AddCircleOutlineRounded';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import React from 'react';
import { ETeamMemberInviteStatus } from '../../../../../../domain/model/enums';
import { TeamMemberInvite } from '../../../../../../domain/model/team';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import { SportUserProfile } from '../../../../../../domain/model/user';
import { OptionAvatar } from '../../../../../components/common/avatars';
import SectionTitle from '../../../../../components/common/sectionTitle';
import { MPButton } from '../../../../../theme/ui-kit/button';
import SportsmanEditControlContainer from '../../../../sportsman/editControls/dialogControl';
import { getFilteredInvites } from '../../utils';
import { ButtonWrap, List, ListItemSecondaryAction, Wrapper } from './controls';
import { ActivityType } from '../../../../../../domain/model/event';

interface TeamTabInvitesProps {
  readonly activityTypes: Nullable<ActivityType[]>;
  readonly invites: TeamMemberInvite[];
  readonly canChangeMembers: boolean;
  readonly onRevokeInvite: (id: UUID) => void;
  readonly onApproveApplication: (id: UUID) => void;
  readonly onRejectApplication: (id: UUID) => void;
  readonly onAddMember: (sportsmen: Nullable<SportUserProfile[]>) => void;
}

const TeamTabInvites = ({
  invites,
  canChangeMembers,
  onRevokeInvite,
  onApproveApplication,
  onRejectApplication,
  onAddMember,
}: TeamTabInvitesProps) => {
  const sentInvites = getFilteredInvites(invites, ETeamMemberInviteStatus.Invited);
  const inboxInvites = getFilteredInvites(invites, ETeamMemberInviteStatus.ApplicationAdded);

  return (
    <Wrapper>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <SectionTitle>Исходящие</SectionTitle>
          {canChangeMembers && (
            <ButtonWrap>
              <SportsmanEditControlContainer
                selectedMaxCount={false}
                headerDialog={'Выбор игроков'}
                onChange={sportsmen => onAddMember(sportsmen)}
                control={
                  <MPButton
                    fullWidth={false}
                    variant='text'
                    startIcon={<AddIcon color='primary' />}
                  >
                    Пригласить игрока
                  </MPButton>
                }
              />
            </ButtonWrap>
          )}
          {sentInvites.length > 0 && (
            <List>
              {sentInvites.map((invite, index, array) => (
                <React.Fragment key={invite.id}>
                  <ListItem alignItems='center'>
                    <ListItemAvatar>
                      <OptionAvatar option={invite.userProfile} />
                    </ListItemAvatar>
                    <ListItemText primary={invite.userProfile.name} />
                    {canChangeMembers && (
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => onRevokeInvite(invite.id)}>
                          <BlockOutlinedIcon color='error' />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                  {array.length - 1 !== index && (
                    <Divider
                      key={`divider${invite.id}`}
                      variant='inset'
                    />
                  )}
                </React.Fragment>
              ))}
            </List>
          )}
        </Grid>
        <Grid
          item
          xs={12}
        >
          {inboxInvites.length > 0 && (
            <>
              <SectionTitle>Входящие</SectionTitle>
              <List>
                {inboxInvites.map((invite, index, array) => (
                  <React.Fragment key={invite.id}>
                    <ListItem alignItems='center'>
                      <ListItemAvatar>
                        <OptionAvatar option={invite.userProfile} />
                      </ListItemAvatar>
                      <ListItemText primary={invite.userProfile.name} />
                      {canChangeMembers && (
                        <ListItemSecondaryAction>
                          <IconButton onClick={() => onApproveApplication(invite.id)}>
                            <AddCircleOutlineOutlinedIcon color='primary' />
                          </IconButton>
                          <IconButton onClick={() => onRejectApplication(invite.id)}>
                            <BlockOutlinedIcon color='error' />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                    {array.length - 1 !== index && (
                      <Divider
                        key={`divider${invite.id}`}
                        variant='inset'
                      />
                    )}
                  </React.Fragment>
                ))}
              </List>
            </>
          )}
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default TeamTabInvites;
