import { combineReducers } from '@reduxjs/toolkit';
import byId, { PartnerDeskByIdState } from './byId/store/slice';
import edit, { PartnerDeskEditState } from './edit/store/slice';
import list, { PartnerDeskListState } from './table/store/slice';

interface PartnerDeskState {
  list: PartnerDeskListState;
  edit: PartnerDeskEditState;
  byId: PartnerDeskByIdState;
}

export default combineReducers<PartnerDeskState>({
  list,
  edit,
  byId,
});
