import { Banner, EBannerPartition, ESortDirection } from '@/domain';
import { DataTableLoader } from '@components/common/table/loader';
import BannerTableCellCode from '@features/banner/components/tableCell/code';
import BannerTableCellEndDate from '@features/banner/components/tableCell/endDate';
import BannerTableCellImage from '@features/banner/components/tableCell/image';
import BannerTableCellName from '@features/banner/components/tableCell/name';
import BannerTableCellPlaces from '@features/banner/components/tableCell/places';
import BannerTableCellSortIndex from '@features/banner/components/tableCell/sortIndex';
import BannerTableCellStartDate from '@features/banner/components/tableCell/startDate';
import { bannersIsFetchingSelector } from '@features/banner/table/store/selectors';
import { useCallback } from 'react';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../components/common/table';
import TableActions from '../../../components/common/table/actions';
import { BannerActions, BannerActionTableCommonType, BannerActionTableType } from '../types';
import { BannerTableCellAdapter, BannerTableCellImageAdapter, BannerTableCellSortIndexAdapter } from './adapters';
import { BannerActionCall, BannerTableActionProcess, EBannerTableColumn } from './utils';

interface BannerTableProps {
  readonly metadata: DataTableMetadata<EBannerTableColumn>;
  readonly partition: EBannerPartition;
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly banners: Banner[];
  readonly onChangeMetadata: (metadata: DataTableMetadata<EBannerTableColumn>) => void;
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onBannerClick: (banner: Banner) => void;
  readonly onBannerAction: (call: BannerActionCall) => void;
  readonly getActions: (banner: Banner) => BannerActions<BannerActionTableType>;
  readonly getCommonActions: (banner: Banner) => BannerActions<BannerActionTableCommonType>;
  readonly getActionProcesses: (banner: Banner) => BannerTableActionProcess[];
}

const BannerTable = (props: BannerTableProps) => {
  const {
    metadata,
    sort,
    banners,
    partition,
    onChangeMetadata,
    onRequestSort,
    onBannerClick,
    onBannerAction,
    getActions,
    getCommonActions,
    getActionProcesses,
  } = props;

  const rows: DataTableRow<Banner, EBannerTableColumn>[] = banners?.map((banner, index) => {
    return {
      [EBannerTableColumn.Image]: (
        <BannerTableCellImageAdapter
          partition={partition}
          index={index}
          component={BannerTableCellImage}
        ></BannerTableCellImageAdapter>
      ),
      [EBannerTableColumn.Name]: (
        <BannerTableCellAdapter
          partition={partition}
          index={index}
          component={BannerTableCellName}
        />
      ),
      [EBannerTableColumn.SortIndex]: ({ hoveredRow }) => (
        <BannerTableCellSortIndexAdapter
          partition={partition}
          hoveredRow={hoveredRow}
          index={index}
          component={BannerTableCellSortIndex}
          getActionProcesses={getActionProcesses}
          getActions={getActions}
          onBannerAction={onBannerAction}
        />
      ),
      [EBannerTableColumn.Places]: (
        <BannerTableCellAdapter
          partition={partition}
          index={index}
          component={BannerTableCellPlaces}
        />
      ),
      [EBannerTableColumn.Code]: (
        <BannerTableCellAdapter
          partition={partition}
          index={index}
          component={BannerTableCellCode}
        />
      ),
      [EBannerTableColumn.StartDate]: (
        <BannerTableCellAdapter
          partition={partition}
          index={index}
          component={BannerTableCellStartDate}
        />
      ),
      [EBannerTableColumn.EndDate]: (
        <BannerTableCellAdapter
          partition={partition}
          index={index}
          component={BannerTableCellEndDate}
        />
      ),
      data: banner,
    };
  });

  const onTableCommonAction = useCallback(
    (action: BannerActionTableCommonType, banner: Banner) => onBannerAction({ action, banner }),
    [onBannerAction]
  );

  return (
    <DataTable<Banner, EBannerTableColumn>
      width='auto'
      metadata={metadata}
      rowActions
      hoverModule
      rows={rows}
      sort={{ column: sort.column, direction: sort.direction }}
      loader={<DataTableLoader selector={bannersIsFetchingSelector(partition)} />}
      onSort={(event, column, direction) => onRequestSort(column, direction)}
      onRowClick={(event, cell, { data }) => onBannerClick(data)}
      onChangeMetadata={onChangeMetadata}
      getRowActions={banner => (
        <TableActions<Banner, BannerActionTableCommonType>
          entityLink={banner}
          actions={getCommonActions(banner)}
          onExecute={onTableCommonAction}
          isExecuting={getActionProcesses(banner).some(process => process.isFetching)}
        />
      )}
    />
  );
};

export default BannerTable;
