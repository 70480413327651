import { FCC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AdCampaignDetailsContainerProps } from './container';
import { adCampaignDetailsByIdFetch, adCampaignDetailsStateReset } from './store/slice';

type AdCampaignDetailsInitializerProps = AdCampaignDetailsContainerProps;

export const AdCampaignDetailsInitializer: FCC<AdCampaignDetailsInitializerProps> = ({ id, children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const promise = dispatch(adCampaignDetailsByIdFetch(id));

    return () => {
      dispatch(adCampaignDetailsStateReset());
      promise?.abort();
    };
  }, [dispatch, id]);

  return <>{children}</>;
};
