import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PartnerShort } from '../../../../../domain/model/partner';
import { ContentLoaderSimple } from '../../../../components/common/loader';
import PartnerApplicationEntry from '../../../partnerApplication/entry';
import { ContentWrapper } from './controls';

interface MainEntryPartnerApplicationProps {
  readonly partner: PartnerShort;
}

const MainEntryPartnerApplication = ({ partner }: MainEntryPartnerApplicationProps) => {
  return (
    <ContentWrapper>
      <Suspense fallback={<ContentLoaderSimple />}>
        <Switch>
          <Route render={() => <PartnerApplicationEntry partner={partner} />} />
        </Switch>
      </Suspense>
    </ContentWrapper>
  );
};

export default MainEntryPartnerApplication;
