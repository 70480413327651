import { CmsContainerActions, ECmsContainerActionType } from '../types';
import { CanEditType, GetNextActionsType } from './types';

// получение доступных действий с учетом состояния контейнера
export const getCmsContainerLifeCycleNextActions: GetNextActionsType = (props): CmsContainerActions => {
  const { cmsContainer, allowedActions, statusActions } = props;

  const { status } = cmsContainer;

  // определяем доступные статусы после текущего
  const nextActions = statusActions[status];

  // фильтруем действия по доступным следующим статусам
  return allowedActions.filter(action => {
    return nextActions.some(a => action.type === a);
  });
};

export const canCmsContainerLifeCycleEdit: CanEditType = ({ cmsContainer, allowedActions, statusActions }): boolean => {
  if (!cmsContainer) {
    return false;
  }
  const { status } = cmsContainer;

  const editActions = [ECmsContainerActionType.Edit, ECmsContainerActionType.Save];

  // определяем доступность сохранения на текущем статусе
  const statusActionsIncludeEdit = statusActions[status].some(a => editActions.includes(a));
  const allowedActionsIncludeEdit = allowedActions.some(a => !a.disabled && editActions.includes(a.type));

  return statusActionsIncludeEdit && allowedActionsIncludeEdit;
};
