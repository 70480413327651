import { Nullable, UUID } from '@/domain';
import useAdCampaignsTableCount from '../hooks/useCount';
import useAdCampaignsTableSearch from '../hooks/useSearch';
import useAdCampaignsTableSession from '../hooks/useSession';
import { EAdCampaignTableTab } from '@features/adCampaign/types';

interface AdCampaignBehaviorAdapterProps {
  readonly guid: UUID;
  readonly tab: EAdCampaignTableTab;
  readonly partnerId: Nullable<UUID>;
}

export const AdCampaignBehaviorAdapter = ({ guid, tab, partnerId }: AdCampaignBehaviorAdapterProps) => {
  useAdCampaignsTableSession({ guid, tab });
  useAdCampaignsTableSearch({ tab, partnerId });
  useAdCampaignsTableCount({ partnerId });

  return null;
};
