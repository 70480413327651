import {
  AdCampaign,
  AdCampaignCreateRequest,
  AdCampaignShort,
  AdCampaignUpdateRequest,
  EAdCampaignCommandDiscriminator,
  EAdCampaignCreateDiscriminator,
} from '@/domain';
import axios, { AxiosResponse } from 'axios';
import { createCancelToken } from './index';
import {
  ApiCancellable,
  ApiQueryDsl,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
  ApiResponseMaBeCounters,
} from './types';
import { appendQueryDslParams, getComServicesEndpoint } from './utils';

type AllOfferProps<D extends ApiRequestListDiscriminator> = ApiRequestPageable &
  ApiCancellable & {
    readonly discriminator?: D;
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

type AloneIdProps = ApiCancellable & {
  readonly id: UUID;
};

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type CreateProps = {
  readonly data: AdCampaignCreateRequest;
};

type UpdateProps = {
  readonly id: UUID;
  readonly data: AdCampaignUpdateRequest;
};

export type AdCampaignAction = {
  discriminator:
    | EAdCampaignCommandDiscriminator.AdCampaignPauseCommand
    | EAdCampaignCommandDiscriminator.AdCampaignRenewCommand
    | EAdCampaignCommandDiscriminator.AdCampaignArchiveCommand;
};

type ActionProps = AloneIdProps & AdCampaignAction;

type AdCampaignApi = {
  readonly all: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllOfferProps<D>
  ) => Promise<AxiosResponse<ApiResponseMaBeCounters<D, AdCampaignShort[]>>>;
  readonly one: (props: OneProps) => Promise<AxiosResponse<AdCampaign>>;
  readonly createSearch: (props: CreateProps) => Promise<AxiosResponse<AdCampaign>>;
  readonly createCatalog: (props: CreateProps) => Promise<AxiosResponse<AdCampaign>>;
  readonly updateSearch: (props: UpdateProps) => Promise<AxiosResponse<AdCampaign>>;
  readonly updateCatalog: (props: UpdateProps) => Promise<AxiosResponse<AdCampaign>>;
  readonly action: (props: ActionProps) => Promise<AxiosResponse<AdCampaign>>;
};

const adCampaign: AdCampaignApi = {
  all: ({ page, pageSize, sort, querydsl, query, discriminator, signal }) => {
    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (query) {
      params.append('q', query);
    }

    if (discriminator) {
      params.append('resultType', discriminator);
    }

    if (querydsl) {
      appendQueryDslParams(params, querydsl);
    }

    return axios.get(`${getComServicesEndpoint()}/ad-campaigns`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  one: ({ id, signal }) => {
    return axios.get(`${getComServicesEndpoint()}/ad-campaigns/${id}`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  createSearch: ({ data }) => {
    return axios.post(`${getComServicesEndpoint()}/ad-campaigns`, {
      ...data,
      discriminator: EAdCampaignCreateDiscriminator.CreateAdCampaignSearchRequest,
    });
  },
  createCatalog: ({ data }) => {
    return axios.post(`${getComServicesEndpoint()}/ad-campaigns`, {
      ...data,
      discriminator: EAdCampaignCreateDiscriminator.CreateAdCampaignCatalogRequest,
    });
  },
  updateSearch: ({ id, data }) => {
    return axios.post(`${getComServicesEndpoint()}/ad-campaigns/${id}`, {
      ...data,
      discriminator: EAdCampaignCommandDiscriminator.AdSearchCampaignUpdateCommand,
    });
  },
  updateCatalog: ({ id, data }) => {
    return axios.post(`${getComServicesEndpoint()}/ad-campaigns/${id}`, {
      ...data,
      discriminator: EAdCampaignCommandDiscriminator.AdCatalogCampaignUpdateCommand,
    });
  },
  action: ({ id, signal, ...data }) => {
    return axios.post(`${getComServicesEndpoint()}/ad-campaigns/${id}`, data, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
};

export default adCampaign;
