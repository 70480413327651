import { AddressPosition } from '../../../domain/model/address';
import { MapCoordinates } from './types';

export const getDefaultMapCoordinates = (): MapCoordinates => [55.753544, 37.621202];

export const covertAddressPositionToMapCoordinates = (addressPosition: AddressPosition): Nullable<MapCoordinates> => {
  return addressPosition.lat !== null && addressPosition.lon !== null
    ? [addressPosition.lat, addressPosition.lon]
    : null;
};

export const covertMapCoordinatesToAddressPosition = (mapCoordinates: MapCoordinates): AddressPosition => {
  return { lat: mapCoordinates[0], lon: mapCoordinates[1] };
};
