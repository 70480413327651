import common, { PartnerCommonService } from './common';

type PartnerServices = {
  readonly common: PartnerCommonService;
};

const partnerServices: PartnerServices = {
  common,
};

export default partnerServices;
