import axios, { AxiosResponse } from 'axios';
import { ApiCancellable } from './types';
import { getComServicesEndpoint } from './utils';
import { createCancelToken } from './index';
import { DictionaryValue, FeedBackForm } from '../../domain/model/commons';

export type DictionariesApiProps = ApiCancellable & {
  dictionaryId: string;
};
export type FeedBackApiProps = ApiCancellable & FeedBackForm;

type DictionariesAPI = {
  readonly valueById: (props: DictionariesApiProps) => Promise<AxiosResponse<DictionaryValue[]>>;
};

type CommonApi = {
  readonly feedback: (props: FeedBackApiProps) => Promise<AxiosResponse<DictionaryValue[]>>;
  readonly dictionaries: DictionariesAPI;
};

const common: CommonApi = {
  feedback: (props: FeedBackApiProps) => {
    const { signal, ...data } = props;
    return axios.post(`${getComServicesEndpoint()}/feedback`, {
      ...data,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  dictionaries: {
    valueById: props => {
      const { signal, dictionaryId } = props;
      const params = new URLSearchParams();

      params.append('dictionaryId', dictionaryId);

      return axios.get(`${getComServicesEndpoint()}/dictionaries/${dictionaryId}/values`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
  },
};

export default common;
