import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import { MPButton } from '../../../../theme/ui-kit/button';
import ContentLoader from '../../loader';
import ConfirmDialog from '../confirm';

interface SaveWhenExitDialogProps {
  readonly open: boolean;
  readonly title?: ReactNode;
  readonly description?: ReactNode;
  readonly onCancel: () => void;
  readonly onExit: () => void;
  readonly onExitAfterSave: () => void;
  readonly isSaving?: boolean;
}

const SaveWhenExitDialog = (props: SaveWhenExitDialogProps) => {
  const {
    open,
    title = 'Сохранить изменения?',
    description = 'Данные были изменены. Вы хотите их сохранить?',
    isSaving = false,
    onCancel,
    onExitAfterSave,
    onExit,
  } = props;

  return (
    <ConfirmDialog
      open={open}
      title={title}
      text={description}
      onClose={onCancel}
      dialogButtons={
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <MPButton
              onClick={onExitAfterSave}
              disabled={isSaving}
            >
              Да, сохранить
              {isSaving && <ContentLoader />}
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              onClick={onExit}
              disabled={isSaving}
            >
              Нет, не сохранять
            </MPButton>
          </Grid>
        </Grid>
      }
    />
  );
};

export default SaveWhenExitDialog;
