import { RootState } from '@/data/store/store';
import { AdCampaign, AdCampaignCreate, AnyAdCampaign } from '@/domain';
import React from 'react';
import { EAdCampaignActionType } from '../types';
import { ActionsChangeDialogStateType } from './store/slice';

export interface AdCampaignActionsSelectors {
  readonly getIsAdCampaignProcessingSelector: (id: Nullable<UUID>) => (store: RootState) => boolean;
  readonly getIsAdCampaignExecutedActionsSelector: (
    id: Nullable<UUID>,
    ...actions: EAdCampaignActionType[]
  ) => (store: RootState) => Nullable<EAdCampaignActionType>;
  readonly isAdCampaignsAnyChangedSelector: (store: RootState) => number;
}

export interface AdCampaignActionsContextType {
  readonly onChangeDialogState: (props: ActionsChangeDialogStateType) => void;
  readonly onArchive: (id: UUID) => Promise<Nullable<AdCampaign>>;
  readonly onTrySave: (campaign: AdCampaignCreate) => void;
  readonly onTryArchive: (campaign: AnyAdCampaign) => void;
  readonly onTryPause: (campaign: AnyAdCampaign) => void;
  readonly onTryResume: (campaign: AnyAdCampaign) => void;

  readonly utils: {
    readonly selectors: AdCampaignActionsSelectors;
  };
}

const AdCampaignActionsContext = React.createContext<AdCampaignActionsContextType>({} as AdCampaignActionsContextType);

export default AdCampaignActionsContext;
