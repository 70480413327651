import axios, { AxiosResponse } from 'axios';
import { EUserRole } from '../../domain/model/enums';
import { GlobalNotificationOption, NotificationOption, NotificationSetting } from '../../domain/model/notification';
import { UUID } from '../../domain/model/types';
import { createCancelToken } from './index';
import { ApiCancellable } from './types';
import { getComServicesEndpoint } from './utils';

type ByRolesProps = ApiCancellable & {
  readonly roles: EUserRole[];
};

type UpdateProps = {
  readonly id: UUID;
  readonly option: NotificationOption;
  readonly enabled: boolean;
};

type GlobalOptionsProps = ApiCancellable;

type UpdateGlobalOptionsProps = {
  readonly id: UUID;
  readonly enabled: boolean;
};

type PartnerOptionsProps = ApiCancellable;

type UpdatePartnerOptionsProps = {
  readonly id: UUID;
  readonly optionId: UUID;
  readonly enabled: boolean;
};

type NotificationApi = {
  readonly byRoles: (props: ByRolesProps) => Promise<AxiosResponse<NotificationOption[]>>;
  readonly update: (props: UpdateProps) => Promise<AxiosResponse<NotificationSetting>>;
  readonly partnerOptions: (props: PartnerOptionsProps) => Promise<AxiosResponse<NotificationOption[]>>;
  readonly updatePartnerOptions: (props: UpdatePartnerOptionsProps) => Promise<AxiosResponse<NotificationSetting>>;
  readonly globalOptions: (props: GlobalOptionsProps) => Promise<AxiosResponse<GlobalNotificationOption[]>>;
  readonly updateGlobalOptions: (props: UpdateGlobalOptionsProps) => Promise<AxiosResponse<GlobalNotificationOption>>;
};

/**
 * АПИ по работе с уведомлениями
 */
const notification: NotificationApi = {
  byRoles: ({ signal, roles }) => {
    const params = new URLSearchParams();

    if (roles) {
      roles.forEach(role => params.append('role', role));
    }

    return axios.get(`${getComServicesEndpoint()}/notifications/options`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  update: ({ id, option, enabled }) => {
    return axios.patch(`${getComServicesEndpoint()}/notifications/users/${id}/settings`, {
      optionId: option.id,
      enabled,
    });
  },
  partnerOptions: ({ signal }) => {
    return axios.get(`${getComServicesEndpoint()}/notifications/partners/marketing-distribution`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  updatePartnerOptions: ({ id, optionId, enabled }) => {
    return axios.patch(`${getComServicesEndpoint()}/notifications/partners/${id}/marketing-distribution`, {
      optionId,
      enabled,
    });
  },
  globalOptions: ({ signal }) => {
    return axios.get(`${getComServicesEndpoint()}/notifications/global-options`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  updateGlobalOptions: ({ id, enabled }) => {
    return axios.patch(`${getComServicesEndpoint()}/notifications/global-options`, {
      optionId: id,
      enabled,
    });
  },
};

export default notification;
