import { Location } from 'history';
import React, { Suspense } from 'react';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Nullable, UUID } from '../../../domain/model/types';
import rootRouting from '../../../routing';
import { ContentLoaderSimple } from '../../components/common/loader';
import BookingOfferDetailsScreen from '../../screens/bookingOfferDetails';
import NotFoundScreen from '../../screens/notFound';
import { EOfferTableTab } from '../general/offer/types';
import BookingOfferActionsProvider from './actions/provider';
import { EBookingOfferCreateUrlParam, EBookingOfferStep } from './types';

const BookingOfferCreateScreen = React.lazy(() => import('../../screens/bookingOfferCreate'));
const BookingOfferEditScreen = React.lazy(() => import('../../screens/bookingOfferEdit'));
const BookingOffersScreen = React.lazy(() => import('../../screens/bookingOffers'));

const routing = {
  root: `${rootRouting.bookingOffers}`,
  list: `${rootRouting.bookingOffers}`,
  create: `${rootRouting.bookingOffers}/create`,
  details: `${rootRouting.bookingOffers}/:id/details`,
  edit: `${rootRouting.bookingOffers}/:id/edit`,
};

export enum EBookingOfferUrlParam {
  Tab = 'tab',
  Step = 'step',
}

export interface BookingOffersLocationState {
  readonly guid: UUID;
}

interface GetBookingOfferCreateRouteProps {
  readonly location: any;
  readonly id?: Nullable<UUID>;
  readonly guid?: UUID;
  readonly step?: EBookingOfferStep;
}

interface GetBookingOfferEditRouteProps {
  readonly id: UUID;
  readonly step?: EBookingOfferStep;
}

interface GetBookingOfferDetailsRouteProps {
  readonly id: UUID;
  readonly step?: EBookingOfferStep;
}

interface GetBookingOffersTableRouteProps {
  readonly tab?: Nullable<EOfferTableTab>;
  readonly guid?: UUID;
}

export const getBookingOfferCreateRoute = ({ id, step, location, guid }: GetBookingOfferCreateRouteProps) => {
  const searchParams = new URLSearchParams(location.search);
  const currentGuid = guid ?? uuidv4();

  if (id) {
    searchParams.set(EBookingOfferCreateUrlParam.Id, id);
  }
  if (step) {
    searchParams.set(EBookingOfferUrlParam.Step, step);
  }
  searchParams.set(EBookingOfferCreateUrlParam.Guid, currentGuid);

  return `${routing.create}?${searchParams.toString()}`;
};

export const getBookingOfferDetailsRoute = ({
  id,
  step = EBookingOfferStep.General,
}: GetBookingOfferDetailsRouteProps) => {
  const searchParams = new URLSearchParams();
  searchParams.append(EBookingOfferUrlParam.Step, step);

  return `${routing.details.replace(':id', id)}?${searchParams.toString()}`;
};

export const getBookingOfferEditRoute = ({ id, step = EBookingOfferStep.General }: GetBookingOfferEditRouteProps) => {
  const searchParams = new URLSearchParams();
  searchParams.append(EBookingOfferUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.edit.replace(':id', id)}${search}`;
};

export const getBookingOffersTableRoute = (
  props: GetBookingOffersTableRouteProps
): Location<BookingOffersLocationState> => {
  const { tab, guid } = props;

  const searchParams = new URLSearchParams();
  if (tab) {
    searchParams.append(EBookingOfferUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return {
    pathname: routing.list,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

const BookingOffersEntry = () => {
  return (
    <BookingOfferActionsProvider>
      <Suspense fallback={<ContentLoaderSimple />}>
        <Switch>
          <Route
            exact
            path={routing.list}
            component={BookingOffersScreen}
          />
          <Route
            exact
            path={routing.create}
            component={BookingOfferCreateScreen}
          />
          <Route
            exact
            path={routing.edit}
            component={BookingOfferEditScreen}
          />
          <Route
            exact
            path={routing.details}
            component={BookingOfferDetailsScreen}
          />
          <Redirect
            exact
            from={routing.root}
            to={routing.list}
          />
          <Route component={NotFoundScreen} />
        </Switch>
      </Suspense>
    </BookingOfferActionsProvider>
  );
};

export default BookingOffersEntry;
