import { AdCampaignLifeCycleRules } from '../../lifecycle/types';
import { AdCampaignActionDetailsType, AdCampaignActions } from '../../types';
import { filterAdCampaignDetailsActions } from '../../utils/actions';

type UseAdCampaignDetailsActions = AdCampaignActions<AdCampaignActionDetailsType>;

interface UseAdCampaignDetailsActionsProps {
  readonly lifeCycleRules: AdCampaignLifeCycleRules;
}

const useAdCampaignDetailsActions = (props: UseAdCampaignDetailsActionsProps): UseAdCampaignDetailsActions => {
  const { lifeCycleRules } = props;

  const { nextActions } = lifeCycleRules;

  return filterAdCampaignDetailsActions(nextActions);
};

export default useAdCampaignDetailsActions;
