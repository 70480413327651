import { Grid } from '@mui/material';
import {
  PartnerCompanyDataDraft,
  PartnerDataDraft,
  PartnerIndividualDataDraft,
} from '../../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import FileInfo from '../../../../../components/common/files/info';
import FileUploader from '../../../../../components/common/files/uploader';
import { fileTypes } from '../../../../../constants';
import { ContentTypeDocumentIcon } from '../../../../../icons';
import { OnChangeObjectAttribute } from '../../../../../types';

interface PartnerCommonFileSectionProps {
  readonly title?: JSX.Element;
  readonly name: keyof PartnerDataDraft;
  readonly guid?: Nullable<UUID>;
  readonly error?: boolean;
  readonly helperText?: string;
  readonly onChangeFile: OnChangeObjectAttribute<PartnerDataDraft>;
}

export const PartnerCommonFileSection = (props: PartnerCommonFileSectionProps) => {
  const { title, name, guid, error, helperText, onChangeFile } = props;

  return (
    <Grid
      item
      xs={12}
    >
      {title}
      {guid && (
        <FileInfo
          id={guid}
          icon={<ContentTypeDocumentIcon />}
          onRemove={() => onChangeFile(name, null)}
        />
      )}
      {!guid && (
        <FileUploader
          text='Загрузите документ'
          icon={<ContentTypeDocumentIcon />}
          error={error}
          helperText={helperText}
          fileTypes={[fileTypes.pdf.mimeType, fileTypes.doc.mimeType, fileTypes.docx.mimeType]}
          accept={[fileTypes.pdf.ext, fileTypes.docx.ext, fileTypes.docx.ext]}
          onUpload={file => onChangeFile(name, { path: file.path })}
        />
      )}
    </Grid>
  );
};

interface PartnerCompanyFileSectionProps {
  readonly title?: JSX.Element;
  readonly name: keyof PartnerCompanyDataDraft;
  readonly guid?: Nullable<UUID>;
  readonly error?: boolean;
  readonly helperText?: string;
  readonly onChangeFile: OnChangeObjectAttribute<PartnerCompanyDataDraft>;
}

export const PartnerCompanyFileSection = (props: PartnerCompanyFileSectionProps) => {
  const { title, name, guid, error, helperText, onChangeFile } = props;

  return (
    <Grid
      item
      xs={12}
    >
      {title}
      {guid && (
        <FileInfo
          id={guid}
          icon={<ContentTypeDocumentIcon />}
          onRemove={() => onChangeFile(name, null)}
        />
      )}
      {!guid && (
        <FileUploader
          text='Загрузите документ'
          icon={<ContentTypeDocumentIcon />}
          error={error}
          helperText={helperText}
          fileTypes={[fileTypes.pdf.mimeType, fileTypes.doc.mimeType, fileTypes.docx.mimeType]}
          accept={[fileTypes.pdf.ext, fileTypes.docx.ext, fileTypes.docx.ext]}
          onUpload={file => onChangeFile(name, file)}
        />
      )}
    </Grid>
  );
};

interface PartnerIndividualFileSectionProps {
  readonly title?: JSX.Element;
  readonly name: keyof PartnerIndividualDataDraft;
  readonly guid?: Nullable<UUID>;
  readonly error?: boolean;
  readonly helperText?: string;
  readonly onChangeFile: OnChangeObjectAttribute<PartnerIndividualDataDraft>;
}

export const PartnerIndividualFileSection = (props: PartnerIndividualFileSectionProps) => {
  const { title, name, guid, error, helperText, onChangeFile } = props;

  return (
    <Grid
      item
      xs={12}
    >
      {title}
      {guid && (
        <FileInfo
          id={guid}
          icon={<ContentTypeDocumentIcon />}
          onRemove={() => onChangeFile(name, null)}
        />
      )}
      {!guid && (
        <FileUploader
          text='Загрузите документ'
          icon={<ContentTypeDocumentIcon />}
          error={error}
          helperText={helperText}
          fileTypes={[fileTypes.pdf.mimeType, fileTypes.doc.mimeType, fileTypes.docx.mimeType]}
          accept={[fileTypes.pdf.ext, fileTypes.docx.ext, fileTypes.docx.ext]}
          onUpload={file => onChangeFile(name, file)}
        />
      )}
    </Grid>
  );
};
