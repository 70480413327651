import { alpha, createTheme, ThemeOptions } from '@mui/material';
import { amber } from '@mui/material/colors';
import { MPPaletteOptions, MPPaletteType, MPTheme, MPThemeAdvancedOptions, MPTypographyType } from '../types';
import { applyOverrides } from './overrides';
import { applyPickersOverrides } from './pickers';

function applyPalette(theme: MPTheme, palette: MPPaletteType): MPTheme {
  const mpThemePalette: MPPaletteOptions = {
    primary: {
      ...theme.palette.primary,
      main: palette.primary.main,
      light: palette.primary.light,
      dark: palette.primary.dark,
      alpha40: alpha(palette.primary.main, 0.6),
      alpha75: alpha(palette.primary.main, 0.25),
      alpha90: alpha(palette.primary.main, 0.1),
    },
    secondary: {
      ...theme.palette.secondary,
      main: palette.secondary.main,
      dark: palette.secondary.dark,
      light: palette.secondary.light,
      alpha10: alpha(palette.secondary.main, 0.9),
    },
    error: {
      ...palette.error,
      alpha90: alpha(palette.error.main, 0.1),
    },
    success: {
      ...theme.palette.success,
      main: palette.success,
      alpha90: alpha(palette.success, 0.1),
    },
    text: {
      primary: palette.text.primary,
      secondary: palette.text.secondary,
      disabled: palette.secondary.main,
      onPrimary: palette.text.onPrimary,
    },
    background: {
      default: palette.background,
      defaultAlpha50: alpha(palette.background, 0.5),
      defaultAlpha80: alpha(palette.background, 0.8),
      defaultAlpha100: alpha(palette.background, 0),
    },
    border: {
      input: palette.border,
    },
    avatar: {
      background: palette.secondary.light,
    },
    highlight: palette.secondary.light,
    disabled: palette.disabled,
  };

  return createTheme({
    ...theme,
    palette: mpThemePalette,
  } as ThemeOptions) as MPTheme;
}

function applyTypography(theme: MPTheme, typography: MPTypographyType): MPTheme {
  return createTheme({
    ...theme,
    typography,
  } as ThemeOptions) as MPTheme;
}

function applyOthers(theme: MPTheme): MPTheme {
  const mpThemeAdvanced: MPThemeAdvancedOptions = {
    status: {
      default: theme.palette.secondary.main,
      error: theme.palette.error.main,
      warning: amber[700],
      success: theme.palette.success.main,
      info: theme.palette.primary.main,
    },
    scrollBar: {
      background: theme.palette.secondary.alpha10,
      outline: theme.palette.secondary.main,
    },
  };

  return {
    ...theme,
    ...mpThemeAdvanced,
  };
}

export function createMPTheme(palette: MPPaletteType, typography: MPTypographyType): MPTheme {
  let theme = createTheme() as MPTheme;

  theme = applyPalette(theme, palette);
  theme = applyTypography(theme, typography);
  theme = applyOthers(theme);
  theme = applyOverrides(theme);
  theme = applyPickersOverrides(theme);

  return theme;
}
