import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { UUID } from '../../../../../../domain/model/types';

export interface PartnerManagementMenuState {
  readonly guid: UUID;
  readonly opened: {
    readonly [guid: string]: boolean;
  };
}

type Reducer<T> = CaseReducer<PartnerManagementMenuState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PartnerManagementMenuState> {
  partnerManagementMenuAddToOpened: Reducer<{ itemId: UUID }>;
}

const slice = createSlice<PartnerManagementMenuState, Reducers, 'partner/management/menu'>({
  name: 'partner/management/menu',
  initialState: {
    guid: uuidv4(),
    opened: {},
  },
  reducers: {
    partnerManagementMenuAddToOpened(state, { payload }) {
      const { itemId } = payload;
      state.opened[itemId] = !state.opened[itemId];
    },
  },
});

export const { partnerManagementMenuAddToOpened } = slice.actions;

export default slice.reducer;
