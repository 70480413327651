import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../data/store/store';
import { OfferFieldAudit, OfferState } from '../../../../../domain/model';
import { ESortDirection } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';

export const offerHistoryTableHistorySelector = (store: RootState) => store.offerHistory.table.history;
export const offerHistoryTableFieldHistorySelector = (store: RootState) => store.offerHistory.table.fieldHistory;

export const offerHistoryTableFilterSelector = (store: RootState) => store.offerHistory.table.filter;
export const offerHistoryTableFieldFilterSelector = (store: RootState) => store.offerHistory.table.fieldFilter;

export const offerHistoryTableIsLoadingSelector = (store: RootState) => store.offerHistory.table.fetch.isFetching;

export const offerHistoryTableSearchSelector = (store: RootState) => store.offerHistory.table.search;
export const offerHistoryTablePageSizeSelector = (store: RootState) => store.offerHistory.table.search.pageSize;

export const offerHistoryTableSortColumnSelector = (store: RootState) =>
  (store.offerHistory.table.search.sort?.split(',') as [string, ESortDirection])?.[0] ?? '';
export const offerHistoryTableSortDirectionSelector = (store: RootState) =>
  (store.offerHistory.table.search.sort?.split(',') as [string, ESortDirection])?.[1] ?? '';

export const offerHistoryTablePageNumberSelector = (store: RootState) => store.offerHistory.table.paging.pageNumber;
export const offerHistoryTableTotalCountSelector = (store: RootState) => store.offerHistory.table.paging.totalCount;
export const offerHistoryTablePageCountSelector = (store: RootState) => store.offerHistory.table.paging.pageCount;

export const offerHistoryTableTabSelector = (store: RootState) => store.offerHistory.table.tab;
export const offerHistoryTableNeedRefreshWatcherSelector = (store: RootState) =>
  store.offerHistory.table.needRefreshWatcher;

const offerHistoryTableHistoryIndexSelector = (store: RootState, index: number) => index;

const createOfferHistoryTableHistoryByIndexSelector = createSelector(
  offerHistoryTableHistorySelector,
  offerHistoryTableHistoryIndexSelector,
  (offerStates, index): Nullable<OfferState> => offerStates?.[index] ?? null
);
export const offerHistoryTableHistoryByIndexSelector = (index: number) => (store: RootState) =>
  createOfferHistoryTableHistoryByIndexSelector(store, index);

const offerHistoryTableFieldHistoryIndexSelector = (store: RootState, index: number) => index;

const createOfferHistoryTableFieldHistoryByIndexSelector = createSelector(
  offerHistoryTableFieldHistorySelector,
  offerHistoryTableFieldHistoryIndexSelector,
  (fieldAuditItems, index): Nullable<OfferFieldAudit> => fieldAuditItems?.[index] ?? null
);
export const offerHistoryTableFieldHistoryByIndexSelector = (index: number) => (store: RootState) =>
  createOfferHistoryTableFieldHistoryByIndexSelector(store, index);
