import styled from '@emotion/styled';
import { Link as MuiLink, Typography as MuiTypography } from '@mui/material';

export const Link = styled(MuiLink)`
  text-decoration: none;
  display: block;
  border-radius: ${p => p.theme.shape.borderRadius}px;

  &:hover {
    text-decoration: none;
  }
`;

export const ProgressWrapper = styled.div`
  width: 100%;

  position: absolute;
  left: 0;
  bottom: -2px;
`;

export const Wrapper = styled(MuiTypography)`
  position: relative;
` as typeof MuiTypography;
