import { MPFormInput } from '@ui-kit/input';
import { AdCampaignCreateFieldCommonProps } from '../../types';

const fieldName = 'name';
const AdCampaignCreateFieldName = (props: AdCampaignCreateFieldCommonProps<typeof fieldName>) => {
  const { value, validation, disabled, onChangeAttribute, onValidateAttribute } = props;

  return (
    <MPFormInput
      label='Название кампании'
      disabled={disabled}
      value={value}
      error={validation?.hasError}
      helperText={validation?.message}
      onChange={event => onChangeAttribute(fieldName, event.target.value)}
      onBlur={() => onValidateAttribute(fieldName)}
    />
  );
};

export default AdCampaignCreateFieldName;
