import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../../data/api';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../../data/store/types';
import { NotificationOption, NotificationSetting } from '../../../../../../domain/model/notification';
import { Partner, PartnerDesk } from '../../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../../domain/model/types';

export const partnerDetailsOwnerByIdFetch = createAsyncThunk<
  { partner: Partner; notifications: NotificationOption[]; desk: Nullable<PartnerDesk> },
  { id: UUID },
  AppThunkAPIConfig
>('partner/details/owner/byId/fetch', async ({ id }, { rejectWithValue, signal }) => {
  let partner: Nullable<Partner> = null;
  let desk: Nullable<PartnerDesk> = null;
  let notifications: NotificationOption[] = [];

  try {
    const { data: partnerData } = await Api.partner.one({ id, signal });
    partner = partnerData;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);

    return rejectWithValue(e.response.data);
  }

  try {
    const { data: deskData } = await Api.partner.desk({ partnerId: id, signal });
    desk = deskData;
  } catch (e: any) {
    if (e.response.status !== 404) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }

  try {
    const { data: notificationsData } = await Api.notification.partnerOptions({ signal });
    notifications = notificationsData;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);

    return rejectWithValue(e.response.data);
  }

  return {
    partner,
    desk,
    notifications,
  };
});

export const partnerDetailsOwnerNotificationsUpdate = createAsyncThunk<
  NotificationSetting,
  { id: UUID; option: NotificationOption; enabled: boolean },
  AppThunkAPIConfig
>('partner/details/owner/notifications/update', async ({ id, option, enabled }, { rejectWithValue }) => {
  try {
    const { data } = await Api.notification.updatePartnerOptions({ id, optionId: option.id, enabled });
    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface PartnerDetailsOwnerState {
  readonly byId: Fetchable & {
    readonly partner: Nullable<Partner>;
    readonly loadedPartner: Nullable<Partner>;
    readonly desk: Nullable<PartnerDesk>;
    readonly notifications: NotificationOption[];
  };
  readonly notificationsUpdate: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<PartnerDetailsOwnerState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PartnerDetailsOwnerState> {
  partnerDetailsOwnerStateReset: Reducer;
}

const slice = createSlice<PartnerDetailsOwnerState, Reducers, 'detailsOwner'>({
  name: 'detailsOwner',
  initialState: {
    byId: {
      ...fetchableDefault,
      partner: null,
      loadedPartner: null,
      desk: null,
      notifications: [],
    },
    notificationsUpdate: fetchableDefault,
  },
  reducers: {
    partnerDetailsOwnerStateReset: state => {
      state.byId = {
        ...fetchableDefault,
        partner: null,
        loadedPartner: null,
        desk: null,
        notifications: [],
      };
      state.notificationsUpdate = fetchableDefault;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(partnerDetailsOwnerByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.partner = null;
        state.byId.loadedPartner = null;
        state.byId.desk = null;
        state.byId.notifications = [];
      })
      .addCase(partnerDetailsOwnerByIdFetch.fulfilled, (state, { payload }) => {
        const { partner, desk, notifications } = payload;

        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.partner = partner;
        state.byId.loadedPartner = partner;
        state.byId.desk = desk;
        state.byId.notifications = notifications;
      })
      .addCase(partnerDetailsOwnerByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;

          state.byId.partner = null;
          state.byId.loadedPartner = null;
          state.byId.desk = null;
          state.byId.notifications = [];
        }
      })
      .addCase(partnerDetailsOwnerNotificationsUpdate.pending, (state, { meta }) => {
        const { option, enabled } = meta.arg;

        if (!state.byId.partner!.marketingDistributionSettings) {
          state.byId.partner!.marketingDistributionSettings = [];
        }

        const existedNotificationSetting = state.byId.partner!.marketingDistributionSettings.find(
          item => item.option.id === option.id
        );
        if (existedNotificationSetting) {
          existedNotificationSetting.enabled = enabled;
        } else {
          state.byId.partner!.marketingDistributionSettings.push({
            option,
            enabled,
          });
        }
        state.notificationsUpdate = fetchableFetching;
      })
      .addCase(partnerDetailsOwnerNotificationsUpdate.fulfilled, state => {
        state.notificationsUpdate = fetchableFetched;
      })
      .addCase(partnerDetailsOwnerNotificationsUpdate.rejected, state => {
        state.notificationsUpdate = fetchableFailed;
        state.byId.partner = state.byId.loadedPartner;
      });
  },
});

export const { partnerDetailsOwnerStateReset } = slice.actions;

export default slice.reducer;
