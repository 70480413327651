import { useContext } from 'react';
import BookingOrderActionsContext, { BookingOrderActionsContextType } from './context';

export const useBookingOrderActions = (): BookingOrderActionsContextType => {
  return useContext<BookingOrderActionsContextType>(BookingOrderActionsContext);
};

export const useBookingOrderActionsSelectors = (): BookingOrderActionsContextType['utils']['selectors'] => {
  return useBookingOrderActions().utils.selectors;
};
