import { RootState } from '@/data/store/store';
import { AdCampaignCreate } from '@/domain';
import { createSelector } from '@reduxjs/toolkit';

export const adCampaignCreateAdCampaignSelector = (store: RootState) => store.adCampaign.create.byId.adCampaign;

export const adCampaignCreateAdCampaignStatusSelector = (store: RootState) =>
  store.adCampaign.create.byId.adCampaign.status;

export const adCampaignCreateAdCampaignTypeSelector = (store: RootState) =>
  store.adCampaign.create.byId.adCampaign.type;

export const adCampaignCreateAdCampaignIsPresentSelector = (store: RootState) =>
  !!store.adCampaign.create.byId.adCampaign;

export const adCampaignCreateAdCampaignFirLifeCycleSelector = createSelector(
  (store: RootState) => store.adCampaign.create.byId.adCampaign.id,
  (store: RootState) => store.adCampaign.create.byId.adCampaign.status,
  (id, status) => ({
    id,
    status,
  })
);

export const adCampaignCreateAdCampaignFieldByNameSelector =
  <Field extends keyof AdCampaignCreate>(field: Field) =>
  (store: RootState) =>
    store.adCampaign.create.byId.adCampaign[field];

export const adCampaignCreateValidationStepperSelector = (store: RootState) =>
  store.adCampaign.create.validationStepper;

export const adCampaignCreateIsPreloadingSelector = (store: RootState) => store.adCampaign.create.byId.isFetching;

export const adCampaignCreateGuidSelector = (store: RootState) => store.adCampaign.create.guid;

export const adCampaignCreateUiStepsStateSelector = (store: RootState) => store.adCampaign.create.ui?.steps ?? null;

export const adCampaignCreateIsFieldVisibleSelector = (field: keyof AdCampaignCreate) => (store: RootState) =>
  adCampaignCreateIsFieldVisibleSelectorInternal(store, field);

const adCampaignCreateIsFieldVisibleSelectorInternal = createSelector(
  (store: RootState) => store.adCampaign.create.ui?.fields?.visible ?? null,
  (store: RootState, field: keyof AdCampaignCreate) => field,
  (fields, field) => (fields ? fields?.indexOf(field) > -1 : false)
);

export const adCampaignCreateIsFieldDisabledSelector = (field: keyof AdCampaignCreate) => (store: RootState) =>
  adCampaignCreateIsFieldDisabledSelectorInternal(store, field);

const adCampaignCreateIsFieldDisabledSelectorInternal = createSelector(
  (store: RootState) => store.adCampaign.create.ui?.fields?.disabled ?? null,
  (store: RootState, field: keyof AdCampaignCreate) => field,
  (fields, field) => (fields ? fields?.indexOf(field) > -1 : false)
);

export const adCampaignCreateValidationResultsSelector = (store: RootState) => store.adCampaign.create.validation;

export const adCampaignCreateValidationResultsByFieldSelector = (field: keyof AdCampaignCreate) => (store: RootState) =>
  store.adCampaign.create.validation[field] ?? null;
