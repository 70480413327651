import { ListItemAvatar, ListItemText } from '@mui/material';
import { AvatarWrapper, LoaderListItem } from './controls';

const EventListLoaderItem = () => {
  return (
    <LoaderListItem>
      <ListItemAvatar>
        <AvatarWrapper />
      </ListItemAvatar>

      <ListItemText primary='Загрузка...' />
    </LoaderListItem>
  );
};

export default EventListLoaderItem;
