import { Tabs as MuiTabs } from '@mui/material';
import styled from '@emotion/styled';
import { viewConfig } from '../../../theme/viewConfig';
import { ESportsmanTab } from './utils';

export const Wrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  background: ${p => p.theme.palette.background.default};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const TabsWrapper = styled.div`
  background: ${p => p.theme.palette.background.default};
`;

export const Tabs = styled(MuiTabs)`
  & .MuiTabs-flexContainer {
    border-top: 0;
  }
`;

interface TabWrapperProps {
  readonly tab: ESportsmanTab;
}

export const TabWrapper = styled.div<TabWrapperProps>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const SportsmanDetailsHeaderWrapper = styled.div`
  margin-bottom: ${p => p.theme.spacing(1)};
  margin-top: ${p => p.theme.spacing(1)};
`;

export const LoaderWrapper = styled.div`
  top: 0;
  height: 100%;
  left: ${viewConfig.menuPanelWidth};
  right: 0;

  position: fixed;
  z-index: 3;
`;
