import { FormHelperText, Grid } from '@mui/material';
import { useMemo } from 'react';
import Splitter from '../../../../components/common/splitter';
import { BookingOfferEditFieldCommonProps } from '../../components/fieldsEdit/types';
import FormItemAdapter from '../adapters/formItem';
import BookingOfferServiceManageServiceAdapter from '../adapters/service';

const fieldName = 'services';

interface BookingOfferServiceManageServicesProps extends BookingOfferEditFieldCommonProps<typeof fieldName> {}

const BookingOfferServiceManageServicesField = (props: BookingOfferServiceManageServicesProps) => {
  const { value, validation } = props;

  const servicesRender = useMemo(() => {
    return value.map((service, index) => (
      <Grid
        key={index}
        item
      >
        <BookingOfferServiceManageServiceAdapter index={index} />
      </Grid>
    ));
  }, [value.length]);

  return (
    <FormItemAdapter>
      <Grid
        container
        spacing={4}
        direction='column'
      >
        {validation?.hasError && (
          <>
            <Splitter size={1} />
            <FormHelperText error>{validation.message}</FormHelperText>
          </>
        )}
        {servicesRender}
      </Grid>
    </FormItemAdapter>
  );
};

export default BookingOfferServiceManageServicesField;
