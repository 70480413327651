import styled from '@emotion/styled';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';

export const StyledDialog = styled(ConfirmDialog)`
  .MuiDialog-paper {
    width: 25.75rem;
  }
`;

export const Block = styled.div`
  cursor: pointer;
  border-radius: ${p => p.theme.shape.borderRadius}px;

  padding: ${p => p.theme.spacing()};

  &:hover {
    background-color: ${p => p.theme.palette.highlight};
  }
`;
