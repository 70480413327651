import { SidePanelContentWrapper } from '@components/common/wrappers/sidePanel';
import { ClientOrgDetailsPreviewAdapter } from './preview';

export const ClientOrgDetailsSidePanelsAdapter = () => {
  return (
    <SidePanelContentWrapper>
      <ClientOrgDetailsPreviewAdapter />
    </SidePanelContentWrapper>
  );
};
