import { Typography } from '@mui/material';
import { UUID } from '../../../../../../domain/model/types';
import FileInfo from '../../../../../components/common/files/info';

interface PartnerDetailsInfoDocumentProps {
  readonly label: string;
  readonly description?: string;
  readonly guid?: UUID;
}

const PartnerDetailsInfoDocument = (props: PartnerDetailsInfoDocumentProps) => {
  const { label, description, guid } = props;

  return (
    <>
      <Typography
        variant='subtitle2'
        gutterBottom
      >
        {label}
      </Typography>
      {description && <Typography gutterBottom>{description}</Typography>}
      {guid && <FileInfo id={guid} />}
      {!guid && <Typography color='error'>файл не загружен</Typography>}
    </>
  );
};

export default PartnerDetailsInfoDocument;
