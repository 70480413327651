import ErrorHandler from '@/data/network/errorHandler';
import { useAuthentication } from '@mp-npm/mp-auth-client';
import { FCC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENoticeStatus, EUserRole } from '../../../../domain/model/enums';
import { isSecurityBusinessError } from '../../../../presentation/utils/auth';
import { getAllowedUserRoles, isSportUser } from '../../user/securityUtils';
import {
  agreementsCheckingRequestError,
  agreementsIsCheckingRequestFailed,
  agreementsIsCheckingRequestPassed,
  agreementsListRefreshWatcherSelector,
} from '../store/selectors';
import { checkUserCurrentMpAdminFetch, checkUserCurrentSportFetch } from '../store/slice';

const logOutTimeoutMs = 8000 as const;

const CheckSignsContainer: FCC = ({ children }) => {
  const { token, logOut } = useAuthentication();
  const dispatch = useDispatch();

  const isCheckingRequestPassed = useSelector(agreementsIsCheckingRequestPassed);

  const isCheckingRequestFailed = useSelector(agreementsIsCheckingRequestFailed);
  const checkingRequestError = useSelector(agreementsCheckingRequestError);
  const needRefresh = useSelector(agreementsListRefreshWatcherSelector);

  // ошибка загрузки текущего специфического юзера
  useEffect(() => {
    if (isCheckingRequestFailed && !isSecurityBusinessError(checkingRequestError)) {
      ErrorHandler.handleHttpError(checkingRequestError, () => ({
        status: ENoticeStatus.Error,
        text: 'При получении информации о текущем пользователе произошла ошибка',
      }));

      const logOutTimeout = setTimeout(logOut, logOutTimeoutMs);
      return () => {
        clearTimeout(logOutTimeout);
      };
    }
  }, [isCheckingRequestFailed, logOut, checkingRequestError]);

  useEffect(() => {
    if (!token || isCheckingRequestPassed) {
      return;
    }

    const isAdminSport = isSportUser((token?.realm_access?.roles as EUserRole[]) ?? []);
    const roles = getAllowedUserRoles(token?.realm_access?.roles ?? []);

    if (isAdminSport) {
      dispatch(checkUserCurrentSportFetch({ roles }));
    } else {
      dispatch(checkUserCurrentMpAdminFetch());
    }
  }, [token, needRefresh, isCheckingRequestPassed]);

  if (!isCheckingRequestPassed) {
    return null;
  }

  return <>{children}</>;
};

export default CheckSignsContainer;
