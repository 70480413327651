import { useMemo } from 'react';
import { Partner } from '../../../../../../domain/model/partner';
import MenuPanelList from '../../../../../components/common/menuPanel/list';
import usePartnerManagementMenuItems from '../useMenuItems';

interface PartnerManagementMenuItemsAdapterProps {
  readonly partner: Partner;
}

export const PartnerManagementMenuItemsAdapter = (props: PartnerManagementMenuItemsAdapterProps) => {
  const { partner } = props;

  const { items, isSelectedItem, onItemClick, isOpenedSubMenu } = usePartnerManagementMenuItems({ partner });

  return useMemo(() => {
    return (
      <MenuPanelList
        items={items}
        onItemClick={onItemClick}
        isSelectedItem={isSelectedItem}
        isOpenedSubMenu={isOpenedSubMenu}
      />
    );
  }, [items, isSelectedItem, isOpenedSubMenu, onItemClick]);
};
