import common, { TeamCommonService } from './common';
import logos, { TeamLogosServices } from './logos';

type TeamServices = {
  readonly common: TeamCommonService;
  readonly logos: TeamLogosServices;
};

const teamServices: TeamServices = {
  common,
  logos,
};

export default teamServices;
