import { DataTreeItem } from '../../../../../domain/model';
import { BookingServicePriceUnit } from '../../../../../domain/model/booking';
import { EServiceOrderByDateType } from '../../../../../domain/model/enums';
import { serviceOrderByDateType } from '../../../general/nsi';

export const buildBookingServicePriceUnitTree = (
  bookingServicePriceUnits: BookingServicePriceUnit[]
): DataTreeItem<BookingServicePriceUnit>[] => {
  return [
    getItemByOrder(EServiceOrderByDateType.Period, bookingServicePriceUnits),
    getItemByOrder(EServiceOrderByDateType.Days, bookingServicePriceUnits),
    getItemByOrder(EServiceOrderByDateType.None, bookingServicePriceUnits),
  ];
};

const getItemByOrder = (
  order: EServiceOrderByDateType,
  bookingServicePriceUnits: BookingServicePriceUnit[]
): DataTreeItem<BookingServicePriceUnit> => {
  return {
    id: order,
    label: serviceOrderByDateType[order].name,
    parentId: null,
    data: { id: order } as BookingServicePriceUnit,
    children: bookingServicePriceUnits
      .filter(item => item.orderByDateType === order)
      .map(item => ({
        id: item.id,
        label: item.name,
        parentId: order,
        data: item,
        children: null,
      })),
  };
};
