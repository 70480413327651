import { FC } from 'react';
import { EEntityPreviewMode } from '../../../../types';
import { OfferPreviewEllipsis } from '../../../general/offer/components/preview/controls';
import { Header, HeaderSkeleton } from './controls';

type TradeOfferPreviewTitleProps = {
  readonly mode: EEntityPreviewMode;
  readonly title: Nullable<string>;
};

const TradeOfferPreviewTitle: FC<TradeOfferPreviewTitleProps> = props => {
  const { mode, title } = props;

  return title ? (
    <Header
      mode={mode}
      label={
        <OfferPreviewEllipsis variant={mode === EEntityPreviewMode.Mobile ? 'caption' : 'body2'}>
          {title}
        </OfferPreviewEllipsis>
      }
      color='warning'
    />
  ) : (
    <HeaderSkeleton
      mode={mode}
      variant='rounded'
      animation={false}
      width='60%'
      height={mode === EEntityPreviewMode.Mobile ? '1.125rem' : '1.5rem'}
      color='warning'
    />
  );
};

export default TradeOfferPreviewTitle;
