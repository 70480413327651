import { EBannerPartition } from '@/domain';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BannerTableCellCommonProps } from '../../components/tableCell';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bannersBannerByIndexSelector } from '../store/selectors';

export type BannerTableCellAdapterProps = {
  readonly partition: EBannerPartition;
  readonly index: number;
  readonly component: FC<BannerTableCellCommonProps>;
};

export const BannerTableCellAdapter = (props: BannerTableCellAdapterProps) => {
  const { index, partition, component: Component } = props;

  const handlers = useContextHandlers();
  const banner = useSelector(bannersBannerByIndexSelector(partition, index));
  const isDisabled = banner ? handlers.isBannerLockedNotByMe(banner) : false;

  return useMemo(
    () =>
      banner ? (
        <Component
          banner={banner}
          isDisabled={isDisabled}
        />
      ) : null,
    [Component, banner, isDisabled]
  );
};