import { Fetchable } from '@/data/store/types';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { ValidationResult } from '@/presentation/utils/validation';
import { EmailValue } from '../types';
import { Nullable } from '@/domain';
import Api from '@/data/api';
import { AppThunkAPIConfig } from '@/data/store/store';
import { MpChangeCurrentEmailResponse } from '@/data/api/user';

export interface AuthChangeEmailState extends Fetchable {
  readonly email: string;
  readonly source: Nullable<MpChangeCurrentEmailResponse>;
  readonly validation: ValidationResult<EmailValue>;
}

type Reducer<T = undefined> = CaseReducer<AuthChangeEmailState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<AuthChangeEmailState> {
  readonly authChangeEmailSetEmail: Reducer<string>;
  readonly authChangeEmailResetState: Reducer<string>;
  readonly authChangeEmailSetValidation: Reducer<Nullable<ValidationResult<EmailValue>>>;
  readonly authChangeEmailClearValidation: Reducer;
}

export const authChangeEmailUpdate = createAsyncThunk<MpChangeCurrentEmailResponse, string, AppThunkAPIConfig>(
  'auth/changeEmail/onUpdate',
  async (email, { rejectWithValue }) => {
    try {
      return (await Api.user.mp.changeCurrentEmail({ email })).data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

const initialState: AuthChangeEmailState = {
  email: '',
  validation: {},
  source: null,
  isFailed: false,
  isFetched: false,
  isFetching: false,
};

const slice = createSlice<AuthChangeEmailState, Reducers>({
  name: 'changeEmail',
  initialState,
  reducers: {
    authChangeEmailResetState: () => {
      return initialState;
    },
    authChangeEmailSetEmail: (state, { payload }) => {
      state.email = payload;
    },
    authChangeEmailSetValidation: (state, { payload }) => {
      state.validation = payload ?? {};
    },
    authChangeEmailClearValidation: state => {
      state.validation = {};
    },
  },
  extraReducers: builder => {
    builder
      .addCase(authChangeEmailUpdate.pending, state => {
        state.isFetched = false;
        state.isFetching = true;
        state.isFailed = false;
      })
      .addCase(authChangeEmailUpdate.fulfilled, (state, { payload }) => {
        state.isFetched = true;
        state.isFetching = false;
        state.isFailed = false;

        state.source = payload;
      })
      .addCase(authChangeEmailUpdate.rejected, state => {
        state.isFetched = false;
        state.isFetching = false;
        state.isFailed = true;
      });
  },
});

export const { authChangeEmailSetEmail, authChangeEmailSetValidation, authChangeEmailClearValidation } = slice.actions;
export default slice.reducer;
