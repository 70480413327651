import styled from '@emotion/styled';
import { Accordion } from '@mui/material';

export const Wrapper = styled(Accordion)`
  width: 100%;

  position: fixed;
  top: 0;
  z-index: 1;

  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: ${p => p.theme.shape.borderRadius}px;
  border-bottom-right-radius: ${p => (p.theme.shape.borderRadius as number) * 2}px;

  .MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const QualityWrapper = styled.div`
  width: 400px;

  position: fixed;
  top: 2.75rem;
  left: 1rem;
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 3.2rem;
`;
