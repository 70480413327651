import { TableBodyCellDefaultProps, TableBodyCheckboxCell, TableSelectBox } from '../../controls';
import { ChangeEvent, forwardRef } from 'react';
import { DataTableRow, DataTableRowData } from '@/presentation/components/common/table';
import { Nullable } from '@/domain/model/types';

type TableCellCheckBoxProps<D extends DataTableRowData, R extends string = string> = TableBodyCellDefaultProps & {
  readonly single?: boolean;
  readonly row: DataTableRow<D, R>;
  readonly isRowSelected: (row: DataTableRow<D, R>) => boolean | undefined;
  readonly onRowSelect?: Nullable<(event: ChangeEvent, selected: boolean) => void>;
};

export const TableCellCheckBox = forwardRef(
  <D extends DataTableRowData, R extends string = string>(props: TableCellCheckBoxProps<D, R>, ref: any) => {
    const { single, row, isRowSelected, onRowSelect, ...other } = props;

    return (
      <TableBodyCheckboxCell
        ref={ref}
        {...other}
      >
        <TableSelectBox
          single={single}
          color='primary'
          checked={isRowSelected(row)}
          onChange={(event: ChangeEvent, checked: boolean) => onRowSelect?.(event, checked)}
        />
      </TableBodyCheckboxCell>
    );
  }
);
