import { SportOptionTyped } from '../../../../../../../domain/model';
import { EPrivilegeActorType } from '../../../../../../../domain/model/enums';
import OfferHistoryTableCell, { OfferHistoryTableCellCommonProps } from './index';

export type OfferHistoryTableCellActorTypeProps = OfferHistoryTableCellCommonProps & {
  readonly actorTypes: SportOptionTyped<EPrivilegeActorType>[];
};

const OfferHistoryTableCellActorType = (props: OfferHistoryTableCellActorTypeProps) => {
  const {
    history: { actorType },
    actorTypes,
  } = props;

  const value = actorTypes.find(at => at.id === actorType)?.name;

  return (
    <OfferHistoryTableCell
      {...props}
      value={value}
    />
  );
};

export default OfferHistoryTableCellActorType;
