import styled from '@emotion/styled';

interface WrapperProps {
  readonly sticky: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  background-color: ${p => p.theme.palette.background.default};

  padding-top: ${p => p.theme.spacing(1.5)};
  display: grid;
  grid-gap: ${p => p.theme.spacing(0.75)};

  ${p =>
    p.sticky &&
    `
    position: sticky;
    top: 0;
    z-index: 2;
  `};
`;

export const HeadlineWrapper = styled.div`
  min-height: ${p => p.theme.typography.body1.lineHeight};
  display: flex;
  gap: ${p => p.theme.spacing(3)};
`;

export const CloseButtonWrapper = styled.div`
  position: relative;
  top: -${p => p.theme.spacing(0.5)};
`;
