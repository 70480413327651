import BannerTableCell, { BannerTableCellCommonProps } from './index';

const BannerTableCellName = (props: BannerTableCellCommonProps) => {
  const {
    banner: { name },
  } = props;

  return (
    <BannerTableCell
      {...props}
      value={name}
    />
  );
};

export default BannerTableCellName;
