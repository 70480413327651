import { Grid, Typography } from '@mui/material';
import { useMemo } from 'react';
import { SportOptionTyped } from '../../../../../../../domain/model';
import {
  ECmsBannerLinkObjectType,
  ECmsCollectionLinkObjectType,
  EOfferType,
} from '../../../../../../../domain/model/enums';
import { UUID } from '../../../../../../../domain/model/types';
import FileInfo from '../../../../../../components/common/files/info';
import SectionTitle from '../../../../../../components/common/sectionTitle';
import Splitter from '../../../../../../components/common/splitter';
import CmsOfferMultipleCollectionComponent from '../../../../components/collection/offers/multiple';
import CmsOfferSingleCollectionComponent from '../../../../components/collection/offers/single';
import { CmsContainerView } from '../../../../types';
import { CmsLinkedObjectBannerType } from '../../../../types/banner';
import { getCmsBannerLinkedObjectTypes } from '../../../create/utils';
import { ColumnWrapper, Content, Wrapper } from './controls';

interface CmsContainerDetailsStepBannerProps {
  readonly guid: UUID;
  readonly cmsBanner: CmsLinkedObjectBannerType;
  readonly cmsContainer: CmsContainerView;
  readonly cmsBannerLinkedObjectTypes: SportOptionTyped<ECmsBannerLinkObjectType>[];
}

const CmsContainerDetailsStepBanner = (props: CmsContainerDetailsStepBannerProps) => {
  const { cmsBanner, cmsContainer, cmsBannerLinkedObjectTypes } = props;

  const { linkObjectType } = cmsBanner;

  const bannerLinkedObjectTypes = useMemo<SportOptionTyped<ECmsBannerLinkObjectType>[]>(
    () =>
      getCmsBannerLinkedObjectTypes(
        cmsBannerLinkedObjectTypes,
        cmsContainer.type?.code ?? null,
        cmsBanner,
        cmsContainer.offerType
      ),
    [cmsBannerLinkedObjectTypes, cmsContainer.type?.code, cmsContainer.offerType]
  );

  const bannerLinkedObjectType = bannerLinkedObjectTypes.find(lot => lot.id === linkObjectType) ?? null;

  return (
    <>
      <Wrapper>
        <ColumnWrapper>
          <Content>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <Typography variant='h3'>Баннер</Typography>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Splitter
                  variant='horizontal'
                  size={1}
                />
                <SectionTitle>Для сайта</SectionTitle>
                {cmsBanner.desktopImage?.path && <FileInfo id={cmsBanner.desktopImage.path} />}
                {!cmsBanner.desktopImage?.path && <Typography color='error'>файл не загружен</Typography>}
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Splitter
                  variant='horizontal'
                  size={1}
                />
                <SectionTitle>Для мобильной версии</SectionTitle>
                {cmsBanner.mobileImage?.path && <FileInfo id={cmsBanner.mobileImage.path} />}
                {!cmsBanner.mobileImage?.path && <Typography color='error'>файл не загружен</Typography>}
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Splitter
                  variant='horizontal'
                  size={1}
                />
                <SectionTitle>Переход по баннеру</SectionTitle>
                <Splitter
                  variant='horizontal'
                  size={1}
                />
                <Typography color='textSecondary'>Переход на</Typography>
                <Typography>{bannerLinkedObjectType?.name ?? '-'}</Typography>
              </Grid>
              {linkObjectType === ECmsBannerLinkObjectType.CorpOffer && (
                <CmsOfferSingleCollectionComponent
                  offer={cmsBanner.linkedObject}
                  offerType={EOfferType.Corp}
                />
              )}
              {linkObjectType === ECmsBannerLinkObjectType.TradeOffer && (
                <CmsOfferSingleCollectionComponent
                  offer={cmsBanner.linkedObject}
                  offerType={EOfferType.Trade}
                />
              )}
              {(linkObjectType === ECmsBannerLinkObjectType.CorpOfferCategory ||
                linkObjectType === ECmsBannerLinkObjectType.TradeOfferCategory) && (
                <Grid
                  item
                  xs={12}
                >
                  <Splitter
                    variant='horizontal'
                    size={1}
                  />
                  <SectionTitle>{`Категория ${
                    linkObjectType === ECmsBannerLinkObjectType.CorpOfferCategory ? 'корпоративного' : 'торгового'
                  } предложения`}</SectionTitle>
                  <Splitter
                    variant='horizontal'
                    size={1}
                  />
                  <Typography>{cmsBanner?.linkedObject?.name ?? '-'}</Typography>
                </Grid>
              )}
              {linkObjectType === ECmsBannerLinkObjectType.Collection &&
                cmsBanner.linkedObject?.linkObjectType === ECmsCollectionLinkObjectType.CorpOffer && (
                  <CmsOfferMultipleCollectionComponent
                    offers={cmsBanner.linkedObject.linkedObject ?? []}
                    offerType={EOfferType.Corp}
                  />
                )}
              {linkObjectType === ECmsBannerLinkObjectType.Collection &&
                cmsBanner.linkedObject?.linkObjectType === ECmsCollectionLinkObjectType.TradeOffer && (
                  <CmsOfferMultipleCollectionComponent
                    offers={cmsBanner.linkedObject.linkedObject ?? []}
                    offerType={EOfferType.Trade}
                  />
                )}
            </Grid>
          </Content>
        </ColumnWrapper>
      </Wrapper>
    </>
  );
};

export default CmsContainerDetailsStepBanner;
