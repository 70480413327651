import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { EOfferStatus, ESortDirection } from '@/domain/model/enums';
import { Nullable } from '@/domain/model/types';
import { NumberRange, PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { BookingOfferTableFilterEditStrategy } from '../../filterUtils';
import {
  bookingOfferSearchAllSelect,
  bookingOfferSearchAllUnselect,
  bookingOfferSearchDataReset,
  bookingOfferSearchSelect,
  bookingOfferSearchSetFilter,
  bookingOfferSearchSetPage,
  bookingOfferSearchSetPageSize,
  bookingOfferSearchSetSort,
  bookingOfferSearchSortReset,
  bookingOfferSearchUnselect,
} from '../store/slice';
import { BookingOfferShort } from '@/domain/model/booking';

interface UseBookingOfferSearchSingleProps {
  readonly initialSelected: Nullable<BookingOfferShort>;
  readonly selectedMaxCount: 1;
  readonly onSelect: (offer: Nullable<BookingOfferShort>) => void;
}

interface UseBookingOfferSearchMultipleProps {
  readonly initialSelected: Nullable<BookingOfferShort[]>;
  readonly selectedMaxCount?: NumberRange<2, 100> | false;
  readonly onSelect: (offer: BookingOfferShort[]) => void;
}

type UseBookingOfferSearchProps = UseBookingOfferSearchSingleProps | UseBookingOfferSearchMultipleProps;

export type UseBookingOfferSearch = {
  readonly onResetState: () => void;
  readonly onChangeFilter: (strategies: BookingOfferTableFilterEditStrategy[]) => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
  readonly onResetSort: () => void;
  readonly onBookingOfferChangePage: (page: number) => void;
  readonly onBookingOfferChangePageSize: (pageSize: PaginationSize) => void;
  readonly onBookingOfferSelect: (
    bookingOffer: BookingOfferShort,
    selectedCurrentCount: number,
    selected: boolean
  ) => void;
  readonly onReturn: (bookingOffer: BookingOfferShort[]) => void;
  readonly onAllBookingOfferSelect: (selected: boolean) => void;
  readonly isBookingOfferNotModeratedByMe: (bookingOffer: BookingOfferShort) => boolean;
};

export function useBookingOfferSearchHandlers(props: UseBookingOfferSearchProps): UseBookingOfferSearch {
  const { selectedMaxCount } = props;

  const dispatch = useDispatch();

  const {
    userSpecific: { id: userId },
    accessMatrix,
  } = useCurrentUser();

  const onChangeFilter = useCallback(
    (strategies: BookingOfferTableFilterEditStrategy[]) => {
      dispatch(bookingOfferSearchSetFilter(getDataFilterValuesByStrategies(strategies)));
    },
    [dispatch]
  );

  const onResetState = useCallback(() => dispatch(bookingOfferSearchDataReset()), [dispatch]);

  const onChangeSort = useCallback(
    (name: string, direction: ESortDirection) => {
      dispatch(
        bookingOfferSearchSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onResetSort = useCallback(() => dispatch(bookingOfferSearchSortReset()), [dispatch]);

  const onBookingOfferChangePage = useCallback(
    (page: number) => {
      dispatch(bookingOfferSearchSetPage({ pageNumber: page }));
    },
    [dispatch]
  );

  const onBookingOfferChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(bookingOfferSearchSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onBookingOfferSelect = useCallback(
    (bookingOffer: BookingOfferShort, selectedCurrentCount: number, selected: boolean) => {
      if (!selectedMaxCount || selectedMaxCount > 1) {
        if (selected && (!selectedMaxCount || selectedCurrentCount < selectedMaxCount)) {
          dispatch(bookingOfferSearchSelect(bookingOffer));
        } else {
          dispatch(bookingOfferSearchUnselect(bookingOffer));
        }
      } else {
        if (selected) {
          dispatch(bookingOfferSearchAllUnselect());
          dispatch(bookingOfferSearchSelect(bookingOffer));
        } else {
          dispatch(bookingOfferSearchUnselect(bookingOffer));
        }
      }
    },
    [selectedMaxCount]
  );

  const onAllBookingOfferSelect = useCallback(
    (selected: boolean) => {
      if (selected) {
        dispatch(bookingOfferSearchAllSelect(selectedMaxCount || null));
      } else {
        dispatch(bookingOfferSearchAllUnselect());
      }
    },
    [selectedMaxCount]
  );

  const onReturn = useCallback(
    (bookingOffer: BookingOfferShort[]) => {
      switch (props.selectedMaxCount) {
        case 1:
          props.onSelect(bookingOffer?.[0] ?? null);
          break;
        default:
          props.onSelect(bookingOffer);
          break;
      }
    },
    [props.selectedMaxCount]
  );

  const isBookingOfferNotModeratedByMe = useCallback(
    (bookingOffer: BookingOfferShort) => {
      return (
        !!accessMatrix.bookingOffers.moderate &&
        bookingOffer.status === EOfferStatus.OnModeration &&
        bookingOffer.approvingAdmin?.id !== userId
      );
    },
    [userId, accessMatrix.bookingOffers.moderate]
  );

  return {
    onChangeFilter,
    onResetState,
    onChangeSort,
    onResetSort,
    onBookingOfferChangePage,
    onBookingOfferChangePageSize,
    onBookingOfferSelect,
    onAllBookingOfferSelect,
    onReturn,
    isBookingOfferNotModeratedByMe,
  };
}
