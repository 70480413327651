import { HTMLAttributes } from 'react';
import styled from '@emotion/styled';

export const Wrapper = styled('div')<{ disabled?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: ${p => p.theme.spacing(2.5)};
  color: ${p => (p.disabled ? p.theme.palette.disabled.text : p.theme.palette.text.primary)};
`;

type MainProps = HTMLAttributes<HTMLDivElement> & {
  readonly direction: 'row' | 'column' | 'column-reverse' | 'row-reverse';
};

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(1)};
`;

export const Main = styled(({ direction, ...props }: MainProps) => <div {...props} />)`
  display: flex;
  flex-direction: ${p => p.direction};
  gap: ${p => p.theme.spacing(1.5)};
`;
