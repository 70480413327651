import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const TextFieldLimited = styled.div`
  width: 100%;

  position: relative;
`;

export const LimitLabel = styled(Typography)`
  position: absolute;
  transform: scale(0.75);

  top: calc(${p => p.theme.typography.body2.lineHeight} / 2 * -1);
  right: ${p => p.theme.spacing(2)};

  padding-left: ${p => p.theme.spacing(0.5)};
  padding-right: ${p => p.theme.spacing(0.5)};

  background: ${p => p.theme.palette.background.default};
`;
