import { Grid, Typography } from '@mui/material';
import moment from 'moment-timezone';
import { useState } from 'react';
import { EDateFormat } from '../../../../../domain/model/formats';
import Splitter from '../../../../components/common/splitter';
import { MPMultipleCalendarPicker } from '../../../../theme/ui-kit/picker';
import BookingOrderEditItemInfoActions from './actions';
import { ItemsWrapper } from './controls';
import BookingOrderEditItemInfoQuantityItem from './item';
import BookingOrderEditItemInfoQuantityItemsHeader from './itemsHeader';

type BookingInfoByDates = {
  readonly date: Date;
  readonly quantity: number;
};

interface BookingOrderEditItemInfoByDatesProps {
  readonly value: BookingInfoByDates[];
  readonly onApply: (value: BookingInfoByDates[]) => void;
  readonly onCancel: () => void;
}

const BookingOrderEditItemInfoByDates = (props: BookingOrderEditItemInfoByDatesProps) => {
  const { value, onApply, onCancel } = props;

  const [localValue, setLocalValue] = useState<BookingOrderEditItemInfoByDatesProps['value']>(value);

  const dates: Date[] = localValue.map(item => item.date);

  const onInternalApply = () => {
    onApply(localValue);
  };

  const onClear = () => {
    setLocalValue([]);
  };

  const onChangeDates = (newDates: Nullable<Date[]>) => {
    const newValue = (newDates ?? []).map(
      date =>
        localValue.find(
          val => moment(val.date).format(EDateFormat.Server) === moment(date).format(EDateFormat.Server)
        ) ?? {
          date,
          quantity: 1,
        }
    );
    setLocalValue(newValue);
  };

  const onChangeQuantity = (index: number, quantity: number) => {
    const newValue = localValue.map((item, ind) => ({
      ...item,
      quantity: index === ind ? quantity : item.quantity,
    }));
    setLocalValue(newValue);
  };

  const isEmpty = !localValue.length;

  return (
    <Grid
      container
      direction='column'
      spacing={3}
    >
      <Grid item>
        <MPMultipleCalendarPicker
          minDate={new Date()}
          value={dates}
          onChange={onChangeDates}
        />
      </Grid>

      <Grid item>
        <ItemsWrapper>
          <BookingOrderEditItemInfoQuantityItemsHeader />
          {localValue.map(({ date, quantity }, index) => (
            <BookingOrderEditItemInfoQuantityItem
              key={index}
              quantity={quantity}
              label={<Typography>{moment(date).format(EDateFormat.Human)}</Typography>}
              onChange={count => onChangeQuantity(index, count)}
            />
          ))}
        </ItemsWrapper>
        {isEmpty && (
          <>
            <Splitter />
            <Typography
              color='error'
              variant='body2'
            >
              Выберите нужные даты, используя календарь
            </Typography>
          </>
        )}
      </Grid>
      <Grid item>
        <BookingOrderEditItemInfoActions
          isExecuting={false}
          disabled={isEmpty}
          onExecute={onInternalApply}
          onClear={onClear}
          onCancel={onCancel}
        />
      </Grid>
    </Grid>
  );
};

export default BookingOrderEditItemInfoByDates;
