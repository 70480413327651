import { Grid, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { getQueryDslByDataFilterValues } from '../../../../../data/api/utils';
import { Nullable, UUID } from '../../../../../domain/model/types';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import MenuButton from '../../../../components/common/buttons/menu';
import FileDownloadLink from '../../../../components/common/files/download/link';
import DefaultHeader from '../../../../components/common/header';
import { MPButton } from '../../../../theme/ui-kit/button';
import { Tooltip, TooltipTitleWrapper } from '../../../corpOffer/table/controls';
import { getProductCreateRoute } from '../../entry';
import productServices from '../../services';
import { productExportAccept, productExportMaxCount } from '../../utils/common';
import ProductTableUploadOffersDialog from '../dialogs/uploadOffers';
import ProductTableUploadStocksDialog from '../dialogs/uploadStocks';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { productsCategorySelector, productsFilterSelector, productsSortSelector } from '../store/selectors';
import { EProductTableTab, getProductTableStatusByTab } from '../utils';

interface ProductTableHeaderAdapterProps {
  readonly tab: EProductTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly createInfo?: Nullable<string>;
  readonly isCreateDisabled?: boolean;
  readonly canCreate?: boolean;
  readonly canDownload?: boolean;
  readonly canUpload?: boolean;
  readonly canChangeStocks?: boolean;
}

const ProductTableHeaderAdapter = (props: ProductTableHeaderAdapterProps) => {
  const { tab, createInfo, isCreateDisabled, canCreate, canDownload, canUpload, canChangeStocks, partnerId } = props;

  const history = useHistory();
  const handlers = useContextHandlers();

  const [menuCloseMarker, setMenuCloseMarker] = useState<Nullable<symbol>>(null);
  const [uploadStocksDialogVisible, setUploadStocksDialogVisible] = useState<boolean>(false);
  const [uploadOffersDialogVisible, setUploadOffersDialogVisible] = useState<boolean>(false);

  const sort = useSelector(productsSortSelector);
  const filter = useSelector(productsFilterSelector);
  const category = useSelector(productsCategorySelector);

  const onProductsUpload = () => {
    setMenuCloseMarker(Symbol());
    setUploadOffersDialogVisible(true);
  };

  const onStocksUpload = () => {
    setMenuCloseMarker(Symbol());
    setUploadStocksDialogVisible(true);
  };

  const onCreate = () => history.push(getProductCreateRoute({}));

  const downloadUrl = productServices.productOffer.productsDownloadUrl({
    sort,
    page: 1,
    pageSize: productExportMaxCount,
    states: getProductTableStatusByTab(tab),
    partnerId,
    categoryId: category?.id,
    queryDsl: getQueryDslByDataFilterValues(filter) ?? [],
  });

  const canOffersUpload = canUpload && partnerId;
  const canStocksUpload =
    canChangeStocks &&
    partnerId &&
    tab !== EProductTableTab.Moderation &&
    tab !== EProductTableTab.Rejected &&
    tab !== EProductTableTab.Draft &&
    tab !== EProductTableTab.Archived;

  const showMenu = canDownload || canOffersUpload || canStocksUpload;
  const showCreateButton = canCreate;

  return (
    <DefaultHeader
      headline={<AppBreadcrumbs />}
      actions={
        (showMenu || showCreateButton) && (
          <Grid
            container
            spacing={1}
            alignItems='center'
          >
            {showCreateButton && (
              <Grid item>
                <Tooltip
                  arrow
                  placement='bottom-end'
                  open={!!createInfo}
                  title={
                    <TooltipTitleWrapper>
                      <Typography>{createInfo}</Typography>
                    </TooltipTitleWrapper>
                  }
                >
                  <MPButton
                    disabled={isCreateDisabled}
                    variant='outlined'
                    size='small'
                    onClick={onCreate}
                  >
                    Добавить товар
                  </MPButton>
                </Tooltip>
              </Grid>
            )}
            {showMenu && (
              <Grid item>
                <MenuButton
                  variant='outlined'
                  color='primary'
                  id={uuidv4()}
                  closeMarker={menuCloseMarker}
                >
                  {canStocksUpload && <MenuItem onClick={onStocksUpload}>Обновить остатки</MenuItem>}
                  {canOffersUpload && <MenuItem onClick={onProductsUpload}>Импорт товаров</MenuItem>}
                  {canDownload && (
                    <MenuItem>
                      <FileDownloadLink
                        src={downloadUrl}
                        accept={productExportAccept}
                        fileName={'товары'}
                        component={'div'}
                        onDownload={() => setMenuCloseMarker(Symbol())}
                      >
                        Скачать товары
                      </FileDownloadLink>
                    </MenuItem>
                  )}
                </MenuButton>
              </Grid>
            )}
          </Grid>
        )
      }
    >
      <Typography variant='h2'>Товары</Typography>
      {uploadStocksDialogVisible && partnerId && (
        <ProductTableUploadStocksDialog
          statuses={getProductTableStatusByTab(tab)}
          partnerId={partnerId}
          onUpload={handlers.onStocksUploaded}
          onClose={() => setUploadStocksDialogVisible(false)}
        />
      )}
      {uploadOffersDialogVisible && partnerId && (
        <ProductTableUploadOffersDialog
          partnerId={partnerId}
          onUpload={handlers.onProductsUploaded}
          onClose={() => setUploadOffersDialogVisible(false)}
        />
      )}
    </DefaultHeader>
  );
};

export default ProductTableHeaderAdapter;
