import { Grid } from '@mui/material';
import { FCC } from 'react';
import { DataTreeItem } from '../../../../../../../domain/model';
import { BasketIcon, EditIcon } from '../../../../../../icons';
import AppSvgIcon from '../../../../images/icon';
import { DictionaryItemContentProps } from '../../../types';
import { DictionaryItemElementArrow } from '../../element';
import { ActionButton } from '../controls';
import { DictionaryItemConstructor } from '../index';

type DictionaryEditItemProps<T> = DictionaryItemContentProps<T> & {
  readonly content?: FCC<DictionaryItemContentProps<T>>;
  readonly hideTreeIcon?: boolean;
  readonly onEdit?: (item: DataTreeItem<T>) => void;
  readonly onDelete?: (item: DataTreeItem<T>) => void;
};

export const DictionaryEditItem = <T extends object>(props: DictionaryEditItemProps<T>) => {
  const { data, expanded, content: Content, hideTreeIcon, onEdit, onDelete } = props;
  return (
    <DictionaryItemConstructor
      {...props}
      content={Content ? <Content {...props}>{data.label}</Content> : data.label}
      endAdornment={
        <Grid
          container
          spacing={1}
          wrap='nowrap'
          alignItems='center'
          justifyContent='flex-end'
        >
          {onEdit && (
            <Grid item>
              <ActionButton
                disableFocusRipple
                size='small'
                onClick={() => onEdit(data)}
              >
                <AppSvgIcon
                  color='primary'
                  icon={EditIcon}
                />
              </ActionButton>
            </Grid>
          )}
          {onDelete && (
            <Grid item>
              <ActionButton
                disableFocusRipple
                size='small'
                onClick={() => onDelete(data)}
              >
                <AppSvgIcon
                  color='primary'
                  icon={BasketIcon}
                />
              </ActionButton>
            </Grid>
          )}
          {!hideTreeIcon && Array.isArray(data.children) && data.children.length > 0 && (
            <Grid item>
              <DictionaryItemElementArrow active={expanded} />
            </Grid>
          )}
        </Grid>
      }
    />
  );
};
