import { ClientOrg } from '@/domain/model/clientOrg';
import ClientOrgDetailsContainer from '@features/clientOrg/details/container';
import { ClientOrgLifeCycleFactory } from '@features/clientOrg/lifecycle/lifecycle';
import { viewShowClientOrgManagementMenu } from '@features/clientOrg/management/container/store/slice';
import useBreadcrumbsActions from '@features/general/breadcrumbs/useBreadcrumbsActions';
import { viewHideMainMenu } from '@features/main/container/store/slice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ForbiddenComponent from '../../components/common/forbidden';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface ClientOrgManagementDetailsScreenProps {
  readonly clientOrg: ClientOrg;
}

const ClientOrgManagementDetailsScreen = ({ clientOrg }: ClientOrgManagementDetailsScreenProps) => {
  const dispatch = useDispatch();
  const {
    accessMatrix: { clientOrgs, isPartnerUser },
    userSpecific,
  } = useCurrentUser();

  const { putBreadcrumbs } = useBreadcrumbsActions();

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowClientOrgManagementMenu());
  }, [dispatch]);

  useEffect(() => {
    putBreadcrumbs([{ label: 'Настройка витрины' }]);
  }, []);

  if (!clientOrgs?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <ClientOrgDetailsContainer
      id={clientOrg.id}
      lifecycle={ClientOrgLifeCycleFactory().create({
        isPartnerUser,
        userId: userSpecific.id,
      })}
      access={clientOrgs}
    />
  );
};

export default ClientOrgManagementDetailsScreen;
