import styled from '@emotion/styled';
import {
  Checkbox,
  IconButton,
  ListItemButton as MuiListItemButton,
  ListItemButtonProps,
  ListItemSecondaryAction,
  Radio,
} from '@mui/material';

export type ListItemContainerProps = ListItemButtonProps & {
  readonly active?: boolean;
};

export const ListItem = styled(MuiListItemButton)`
  cursor: pointer;

  &.MuiListItemButton-root {
    background: none !important;
    padding: ${p => p.theme.spacing(0.5)} ${p => p.theme.spacing()};
  }

  & .MuiListItemText-root {
    flex: 1;
  }

  & .MuiListItemIcon-root {
    margin-right: ${p => p.theme.spacing()};

    & .MuiIconButton-root {
      padding: ${p => p.theme.spacing(0.5)};
    }
  }
`;

export const ListItemContainer = styled.div<ListItemContainerProps>`
  width: calc(100% - ${p => p.theme.spacing(2)});
  min-height: 2.5rem;

  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 ${p => p.theme.spacing()} 0 0;
  margin-left: ${p => p.theme.spacing(2)};

  border-radius: ${p => p.theme.shape.borderRadius}px;
  background-color: ${p => p.theme.palette.background.default};

  &:hover {
    background-color: ${p => p.theme.palette.secondary.light};
  }

  ${p => p.active && 'background-color: ' + p.theme.palette.secondary.light + ';'}
`;

export const EndAdornmentWrapper = styled(ListItemSecondaryAction)`
  position: static;
  top: unset;
  transform: unset;
`;

export const ActionButton = styled(IconButton)`
  .MuiSvgIcon-root {
    font-size: 1.25rem;
  }
`;

export const ListItemCheckbox = styled(Checkbox)`
  padding: ${p => p.theme.spacing(0.5)};
`;

export const ListItemRadio = styled(Radio)`
  padding: ${p => p.theme.spacing(0.5)};
`;
