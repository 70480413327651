import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { JSX } from 'react';
import { MPTheme } from '../types';

interface MPThemeProviderProps {
  theme: MPTheme;
  children: JSX.Element;
}

type MPThemeProviderType = (props: MPThemeProviderProps) => JSX.Element;

const cache = createCache({
  key: 'mui',
  prepend: true,
});

const MPThemeProvider: MPThemeProviderType = ({ theme, children }) => (
  <CacheProvider value={cache}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  </CacheProvider>
);

export default MPThemeProvider;
