import { Nullable } from '../../../../../domain/model/types';
import { MpUserData } from '../../../../../domain/model/user';
import { OnChangeObjectAttribute } from '../../../../types';
import { ValidationResult } from '../../../../utils/validation';
import { EAdminMpStep } from '../types';
import { Wrapper } from './controls';
import AdminMpCreateStepProfile from './steps/profile';

interface AdminMpCreateProps {
  readonly step: EAdminMpStep.Profile;
  readonly user: MpUserData;
  readonly validationProfile: Nullable<ValidationResult<MpUserData>>;
  readonly isValidProfile: boolean;
  readonly onChangeAttribute: OnChangeObjectAttribute<MpUserData>;
  readonly onClose: () => void;
}

const AdminMpCreate = (props: AdminMpCreateProps) => {
  const { step, user, isValidProfile, validationProfile, onChangeAttribute } = props;

  return (
    <Wrapper>
      {step === EAdminMpStep.Profile && (
        <AdminMpCreateStepProfile
          user={user}
          isValid={isValidProfile}
          validation={validationProfile}
          onChangeAttribute={onChangeAttribute}
        />
      )}
    </Wrapper>
  );
};

export default AdminMpCreate;
