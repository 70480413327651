import { combineReducers } from '@reduxjs/toolkit';
import details, { ComplaintDetailsState } from './details/store/slice';
import list, { ComplaintListState } from './table/store/slice';

interface ComplaintState {
  list: ComplaintListState;
  details: ComplaintDetailsState;
}

export default combineReducers<ComplaintState>({
  list,
  details,
});
