import { RootState } from '../../../../../data/store/store';
import { Nullable } from '../../../../../domain/model/types';
import { InternalLocation, InternalLocationConnector } from './slice';

export const historyExtensionsGetLocationCollector = (store: RootState): InternalLocationConnector =>
  store.historyExtensions.collector;

export const historyExtensionsGetPrevLocation = (store: RootState): Nullable<InternalLocation> => {
  const collector = store.historyExtensions.collector;
  if (collector.length === 0) return null;
  return collector[collector.length - 1];
};

export const historyExtensionsGetPrevIndependentLocationSelector = (
  store: RootState
): Nullable<InternalLocation['location']> => {
  const collector: InternalLocationConnector = store.historyExtensions.collector;
  if (collector.length === 0) return null;

  let index = collector.length - 1;
  const lastLocation: InternalLocation = collector[index];

  while (index >= 0) {
    if (collector[index].location.pathname !== lastLocation.location.pathname) return collector[index].location;
    index--;
  }

  return null;
};
