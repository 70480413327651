import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../data/store/store';
import { ESortDirection } from '../../../../../domain/model/enums';
import { PartnerShort } from '../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { MpUser } from '../../../../../domain/model/user';
import { addSearchParamToLocation } from '../../../../../routing/globalRouting';
import { PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import { EPartnerEmployeeUrlParam } from '../../entry';
import {
  EPartnerEmployeesFilterItem,
  PartnerEmployeesFilterEditStrategy,
  PartnerEmployeesFilterValues,
} from '../filterUtils';
import { partnerEmployeesGuidSelector } from '../store/selectors';
import {
  PartnerEmployeeListState,
  partnerEmployeesActivateEmployee,
  partnerEmployeesChangePartnerOwner, partnerEmployeesDataReset,
  partnerEmployeesDeactivateEmployee,
  partnerEmployeesSetDialogState,
  partnerEmployeesSetFilter,
  partnerEmployeesSetPage,
  partnerEmployeesSetPageSize,
  partnerEmployeesSetSort,
  partnerEmployeesSortReset,
} from '../store/slice';
import { EPartnerEmployeeTableTab } from '../utils';

export type UseEmployeeTable = {
  readonly onChangeTab: (newTab: EPartnerEmployeeTableTab) => void;
  readonly onChangeFilter: (strategies: PartnerEmployeesFilterEditStrategy[]) => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
  readonly onResetSort: () => void;

  readonly onChangePage: (page: number) => void;
  readonly onChangePageSize: (pageSize: PaginationSize) => void;

  readonly onEmployeeActivate: (employee: MpUser) => Promise<boolean>;
  readonly onEmployeeDeactivate: (employee: MpUser) => Promise<boolean>;
  readonly onChangePartnerOwner: (employee: MpUser, partner: PartnerShort) => Promise<boolean>;

  readonly onChangeDialogState: (dialog: keyof PartnerEmployeeListState['dialogs'], employee: Nullable<MpUser>) => void;
};

type UseEmployeeTableHandlersProps = {
  readonly tab: EPartnerEmployeeTableTab;
}
export function useEmployeeTableHandlers({tab}: UseEmployeeTableHandlersProps): UseEmployeeTable {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const getEmployeeTableGuidFromState = useCallback((): Nullable<UUID> => {
    const state = store.getState();
    return partnerEmployeesGuidSelector(state);
  }, [store]);
  
  const onChangeTab = useCallback(
    (newTab: EPartnerEmployeeTableTab) => {
      if (newTab !== tab) {
        const guid = getEmployeeTableGuidFromState();
        dispatch(partnerEmployeesDataReset());
        history.replace(
          addSearchParamToLocation({
            location,
            param: EPartnerEmployeeUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [dispatch, history, location, tab, getEmployeeTableGuidFromState]
  );
  
  const onChangeFilter = useCallback(
    (strategies: PartnerEmployeesFilterEditStrategy[]) => {
      dispatch(
        partnerEmployeesSetFilter(
          getDataFilterValuesByStrategies<EPartnerEmployeesFilterItem, PartnerEmployeesFilterValues>(strategies)
        )
      );
    },
    [dispatch]
  );

  const onChangeSort = useCallback(
    (name: string, direction: ESortDirection) => {
      dispatch(
        partnerEmployeesSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onResetSort = useCallback(() => dispatch(partnerEmployeesSortReset()), [dispatch]);

  const onChangePage = useCallback(
    (page: number) => {
      dispatch(partnerEmployeesSetPage({ pageNumber: page }));
    },
    [dispatch]
  );

  const onChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(partnerEmployeesSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onEmployeeActivate = useCallback(
    async ({ id }: MpUser) => {
      await dispatch(partnerEmployeesActivateEmployee({ id })).unwrap();
      return true;
    },
    [dispatch]
  );

  const onEmployeeDeactivate = useCallback(
    async ({ id }: MpUser) => {
      await dispatch(partnerEmployeesDeactivateEmployee({ id })).unwrap();
      return true;
    },
    [dispatch]
  );

  const onChangePartnerOwner = useCallback(
    async ({ id: userId }: MpUser, { id: partnerId }: PartnerShort) => {
      await dispatch(partnerEmployeesChangePartnerOwner({ partnerId, userId })).unwrap();
      return true;
    },
    [dispatch]
  );

  const onChangeDialogState = useCallback(
    (dialog: keyof PartnerEmployeeListState['dialogs'], employee: Nullable<MpUser>) => {
      dispatch(partnerEmployeesSetDialogState({ name: dialog, employee }));
    },
    [dispatch]
  );

  return {
    onChangeTab,
    onChangeFilter,
    onChangeSort,
    onResetSort,
    onChangePage,
    onChangePageSize,
    onEmployeeActivate,
    onEmployeeDeactivate,
    onChangePartnerOwner,
    onChangeDialogState,
  };
}
