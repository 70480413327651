import TradeOfferTableCell, { TradeOfferTableCellCommonProps } from './index';

const TradeOfferTableCellApprovingAdmin = (props: TradeOfferTableCellCommonProps) => {
  const {
    tradeOffer: { approvingAdmin },
  } = props;

  const value = approvingAdmin?.name ?? '-';

  return (
    <TradeOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default TradeOfferTableCellApprovingAdmin;
