import { FC } from 'react';
import { useSelector } from 'react-redux';
import { BookingOrderTableCellCommonProps } from '../../components/tableCell';
import { bookingOrderBookingOfferByIndexSelector } from '../store/selectors';

export type BookingOrderTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<BookingOrderTableCellCommonProps>;
};

const BookingOffersTableCellAdapter = (props: BookingOrderTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const bookingOrder = useSelector(bookingOrderBookingOfferByIndexSelector(index));

  return bookingOrder ? <Component bookingOrder={bookingOrder} /> : null;
};

export default BookingOffersTableCellAdapter;
