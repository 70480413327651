import {
  disableArchiveActionRule,
  disableChangeModeratorActionRule,
  disableModerateActionsRule,
  disableResumePartnerActionRule,
  disableRetrieveActionRule,
  removeDeleteActionRule,
  removeDuplicateActionRule,
  removeEditActionRule,
  renameDuplicateActionRule,
} from './rules';
import * as schemas from './scheme';
import { BookingLifeCycleRulesConfiguration } from './types';

/**
 * конфигурация правил админа
 */
export const commonAdminConfiguration: BookingLifeCycleRulesConfiguration = {
  statusScheme: schemas.adminStatusScheme.statusScheme,
  actionsScheme: schemas.adminStatusScheme.actionsScheme,
  actionRules: [
    removeEditActionRule,
    removeDeleteActionRule,
    disableModerateActionsRule,
    disableChangeModeratorActionRule,
    renameDuplicateActionRule,
  ],
};

/**
 * конфигурация правил партнёра
 */
export const commonPartnerConfiguration: BookingLifeCycleRulesConfiguration = {
  statusScheme: schemas.partnerStatusScheme.statusScheme,
  actionsScheme: schemas.partnerStatusScheme.actionsScheme,
  actionRules: [
    disableArchiveActionRule,
    disableRetrieveActionRule,
    disableResumePartnerActionRule,
    removeDeleteActionRule,
    removeDuplicateActionRule,
  ],
};
