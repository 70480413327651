import { ENoticeStatus, EOfferType } from '@/domain';
import { getDataFilterValuesByStrategies } from '@/presentation/utils/filtering';
import Notifier from '@/system/notifier';
import DataFilterEdit from '@components/common/dataFilter/edit';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import reportServices from '@features/report/services';
import { defaultDatePeriod, reportDownloadURL, validateDatePeriod } from '@features/report/utils';
import ReportDialogLayout from '@layouts/reportDialog';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  EReportOffersByPartnerFilterItem,
  getReportOffersByPartnerFilterStrategies,
  ReportOffersByPartnerFilterEditStrategy,
  ReportOffersByPartnerFilterValues,
} from './filters';

type OffersReportProps = {
  readonly partnerId: Nullable<UUID>;
  readonly onClose: () => void;
};

const defaultFilterValue: ReportOffersByPartnerFilterValues = {
  [EReportOffersByPartnerFilterItem.DatePeriod]: {
    value: defaultDatePeriod,
  },
  [EReportOffersByPartnerFilterItem.OfferType]: {
    value: EOfferType.Corp,
  },
};

export const OffersReportContainer = (props: OffersReportProps) => {
  const { partnerId, onClose } = props;
  const [error, setError] = useState<Nullable<string>>(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const { offerTypes } = useSelector(nsiDataSelector);

  const [filter, setFilter] = useState<ReportOffersByPartnerFilterValues>(defaultFilterValue);

  const resetValidation = useCallback(() => {
    setError(null);
  }, []);

  const filterStrategies = useMemo(
    () =>
      getReportOffersByPartnerFilterStrategies(
        filter,
        partnerId,
        offerTypes.filter(offerType => [EOfferType.Trade, EOfferType.Corp].includes(offerType.id))
      ),
    [filter, offerTypes, partnerId]
  );

  const onChangeStrategies = useCallback(
    (strategies: ReportOffersByPartnerFilterEditStrategy[]) => {
      setFilter(getDataFilterValuesByStrategies(strategies));
    },
    [setFilter]
  );

  const onResetFilter = useCallback(() => {
    setFilter(defaultFilterValue);
  }, []);

  const handleDownload = () => {
    resetValidation();
    // готовим параметры
    const offerType = filter[EReportOffersByPartnerFilterItem.OfferType]?.value;
    const startDate = filter[EReportOffersByPartnerFilterItem.DatePeriod]?.value?.[0];
    const endDate = filter[EReportOffersByPartnerFilterItem.DatePeriod]?.value?.[1];
    const partner = filter[EReportOffersByPartnerFilterItem.Partner]?.value;

    const isValid = validateDatePeriod(startDate, endDate);

    if (isValid) {
      setIsDownloading(true);
      reportServices.mp
        .offers({ startDate, endDate, offerType, partnerId: partnerId || partner?.id })
        .then(result => {
          reportDownloadURL(result);
          onClose();
        })
        .catch(error => Notifier.getInstance().addNotice(ENoticeStatus.Error, `${error.message}`))
        .finally(() => setIsDownloading(false));
    } else {
      setError('Заполните корректно поля даты');
    }
  };

  useEffect(() => {
    resetValidation();
  }, [filter]);

  return (
    <ReportDialogLayout
      title='Предложения'
      filter={
        <DataFilterEdit
          strategies={filterStrategies}
          onChange={onChangeStrategies}
        />
      }
      error={error}
      isFetching={isDownloading}
      onFetch={handleDownload}
      onClearFilter={onResetFilter}
      onClose={onClose}
    />
  );
};
