import { ServerErrorResponse } from '@/data/network/types';
import { AppThunkAPIConfig, RootState } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import {
  CmsComponent,
  CmsContainer,
  CmsContainerType,
  CmsSitePage,
  ECmsBannerLinkObjectType,
  ECmsCollectionLinkObjectType,
  ECmsContainerType,
  ECmsLinkObjectType,
  EMultiSelectorValueType,
  EOfferType,
  ETargetType,
} from '@/domain';
import { clientOrgRzdCode } from '@features/clientOrg/utils';
import {
  CaseReducer,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  Selector,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import cmsServices from '../../../services';
import { CmsContainerView, CmsLinkedObject } from '../../../types';
import { CmsLinkedObjectBanner, CmsLinkedObjectBannerType } from '../../../types/banner';
import { CmsLinkedObjectCollection, CmsLinkedObjectCollectionType } from '../../../types/collection';
import { CmsFeatureContext } from '../../types';
import { getCmsContainerComponentCountByType, getCmsContainerViewTarget } from '../../utils';
import {
  convertCmsContainerToCmsContainerView,
  convertCmsContainerViewToCmsContainerRequest,
  generateEmptyCmsObjects,
  getCmsCollectionLinkedObjectTypeByContainer,
  getEmptyCmsContainerView,
  getEmptyCmsLinkedObjectBanner,
  getEmptyCmsLinkedObjectCollection,
} from '../utils';
import {
  cmsContainerCreateByIdSelector,
  cmsContainerCreateComponentsSelector,
  cmsContainerCreateLinkedObjectsSelector,
} from './selectors';

type CmsContainerCreateChangeLinkedObjectPayload = {
  index: number;
} & (
  | { type: ECmsLinkObjectType.Banner; data: CmsLinkedObjectBannerType }
  | { type: ECmsLinkObjectType.Collection; data: CmsLinkedObjectCollectionType }
);

interface CmsContainerCreateByIdFetchResult {
  readonly data: CmsContainerView;
  readonly loadedData: Nullable<CmsContainer>;
  readonly components: CmsComponent[];
  readonly linkedObjects: CmsLinkedObject[];
}

export const cmsContainerCreateByIdFetch = createAsyncThunk<
  CmsContainerCreateByIdFetchResult,
  { id: UUID; type: CmsContainerType },
  AppThunkAPIConfig<ServerErrorResponse>
>('cmsContainer/create/byId/fetch', async ({ id, type }, { rejectWithValue, signal }) => {
  try {
    const cmsContainer = await cmsServices.container.byId({ id, signal });
    const { components, linkedObjects } = await cmsServices.container.getLinkedObjects({ cmsContainer, signal });

    return {
      data: convertCmsContainerToCmsContainerView(cmsContainer, linkedObjects),
      loadedData: cmsContainer,
      components,
      linkedObjects,
    };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);

    return rejectWithValue(e.response.data);
  }
});

export const cmsContainerCreateSave = createAsyncThunk<
  { cmsContainer: CmsContainer; cmsLinkedObjects: CmsLinkedObject[] },
  {
    cmsContext?: CmsFeatureContext;
    cmsSitePage: CmsSitePage;
    id: Nullable<UUID>;
  },
  AppThunkAPIConfig<{
    error: ServerErrorResponse;
    cmsContainer: Nullable<CmsContainer>;
    cmsLinkedObjects: Nullable<CmsLinkedObject[]>;
  }>
>('cmsContainer/create/save', async ({ cmsSitePage, id, cmsContext }, { getState, rejectWithValue }) => {
  const state = getState();
  const cmsContainer = cmsContainerCreateByIdSelector(state).data;
  const cmsComponents = cmsContainerCreateComponentsSelector(state);
  const cmsLinkedObjects = cmsContainerCreateLinkedObjectsSelector(state);

  // объявляем переменные на возврат, будем в них писать по ходу сохранения, чтобы при исключении вернуть хотя-бы сохранненые сущности
  let savedCmsContainer: Nullable<CmsContainer> = null;
  let savedCmsLinkedObjects: Nullable<CmsLinkedObject[]> = null;

  try {
    //сохраняем контейнер
    savedCmsContainer = await cmsServices.container.save({
      sitePageId: cmsSitePage.id,
      id,
      data: convertCmsContainerViewToCmsContainerRequest(cmsContainer),
    });

    //сохраняем ресурсы
    savedCmsLinkedObjects = await cmsServices.container.saveLinkedObjects({
      id: savedCmsContainer.id,
      cmsTarget: savedCmsContainer.target,
      cmsContext,
      cmsLinkedObjects,
      cmsComponents,
    });

    //получаем заново контейнер, чтобы в нем были компоненты
    savedCmsContainer = await cmsServices.container.byId({ id: savedCmsContainer.id });

    return { cmsContainer: savedCmsContainer, cmsLinkedObjects: savedCmsLinkedObjects };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue({
      error: e.response.data,
      cmsContainer: savedCmsContainer,
      cmsLinkedObjects: savedCmsLinkedObjects,
    });
  }
});

export const cmsContainerCreatePublish = createAsyncThunk<
  CmsContainer,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('cmsContainer/create/publish', async ({ id }, { rejectWithValue }) => {
  try {
    return await cmsServices.container.publish({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const cmsContainerCreateDelete = createAsyncThunk<void, { id: UUID }, AppThunkAPIConfig<ServerErrorResponse>>(
  'cmsContainer/create/delete',
  async ({ id }, { rejectWithValue }) => {
    try {
      await cmsServices.container.delete({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const cmsContainerCreateDuplicate = createAsyncThunk<
  CmsContainer,
  {
    cmsSitePage: CmsSitePage;
    id: UUID;
  },
  AppThunkAPIConfig<ServerErrorResponse>
>('cmsContainer/create/duplicate', async ({ cmsSitePage, id }, { rejectWithValue }) => {
  try {
    return await cmsServices.container.duplicate({ sitePageId: cmsSitePage.id, id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface CmsContainerCreateState {
  readonly guid: Nullable<UUID>;
  readonly byId: Fetchable & {
    readonly data: CmsContainerView;
    readonly loadedData: Nullable<CmsContainer>;
    readonly modified: boolean;
  };
  readonly components: CmsComponent[];
  readonly linkedObjects: CmsLinkedObject[];
  readonly save: Fetchable;
  readonly publish: Fetchable;
  readonly delete: Fetchable;
  readonly duplicate: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<CmsContainerCreateState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CmsContainerCreateState> {
  cmsContainerCreateStartSession: Reducer<{ guid: UUID; type: CmsContainerType }>;
  cmsContainerCreateSetAttribute: Reducer<{ name: keyof CmsContainerView; value: any }>;
  cmsContainerCreateChangeLinkedObject: Reducer<CmsContainerCreateChangeLinkedObjectPayload>;
}

const slice = createSlice<CmsContainerCreateState, Reducers, 'create'>({
  name: 'create',
  initialState: {
    guid: null,
    byId: {
      ...fetchableDefault,
      data: getEmptyCmsContainerView(),
      loadedData: null,
      modified: false,
    },
    components: [],
    linkedObjects: [],
    save: {
      ...fetchableDefault,
    },
    publish: {
      ...fetchableDefault,
    },
    delete: {
      ...fetchableDefault,
    },
    duplicate: {
      ...fetchableDefault,
    },
  },
  reducers: {
    cmsContainerCreateStartSession: (state, { payload }) => {
      const { guid, type } = payload;

      if (state.guid !== guid) {
        const componentsCount = getCmsContainerComponentCountByType(type.code);
        const cmsContainer = {
          ...getEmptyCmsContainerView(),
          type,
          pageSize: componentsCount,
        };

        const { components, linkedObjects } = generateEmptyCmsObjects(type.code, cmsContainer.offerType);

        state.byId.data = cmsContainer;
        state.byId.loadedData = null;
        state.byId.modified = false;
        state.components = components;
        state.linkedObjects = linkedObjects;

        state.save = {
          ...fetchableDefault,
        };
        state.publish = {
          ...fetchableDefault,
        };
        state.delete = {
          ...fetchableDefault,
        };
        state.duplicate = {
          ...fetchableDefault,
        };
      }
      state.guid = guid;
    },
    cmsContainerCreateSetAttribute: (state, { payload }) => {
      const { name, value } = payload;

      if (state.byId.data) {
        const currentTarget = getCmsContainerViewTarget({ ...state.byId.data });

        (state.byId.data[name] as keyof CmsContainer) = value;
        state.byId.modified = true;

        switch (name) {
          case 'resourcesSize':
            state.linkedObjects.forEach(linkedObject => {
              switch (linkedObject.type) {
                case ECmsLinkObjectType.Collection:
                  break;
                case ECmsLinkObjectType.Banner:
                  const size = value as CmsContainerView['resourcesSize'];
                  linkedObject.banner.width = size?.[0] ?? null;
                  linkedObject.banner.height = size?.[1] ?? null;
                  break;
              }
            });
            break;
          case 'offerType':
            state.byId.data.targetType = ETargetType.Geo;
            state.byId.data.targetLocalities = { select: EMultiSelectorValueType.All, in: null };
            state.byId.data.targetGender = null;
            state.byId.data.targetExternalUsers = null;
            state.byId.data.targetClientOrgs = null;
            state.byId.data.targetHasRzdSocialPackage = null;
            state.byId.data.targetRoads = null;
            state.byId.data.targetOrgUnits = null;
            state.byId.data.targetTradeUnionMembersOnly = null;
            state.byId.data.targetHavingChildFamilyMemberOnly = null;
            state.byId.data.targetFamilyMemberOnly = null;

            state.linkedObjects.forEach(linkedObject => {
              switch (linkedObject.type) {
                case ECmsLinkObjectType.Collection:
                  linkedObject.collection.linkedObject = null;
                  switch (state.byId.data.offerType) {
                    case EOfferType.Corp:
                      linkedObject.collection.linkObjectType = ECmsCollectionLinkObjectType.CorpOffer;
                      break;
                    case EOfferType.Trade:
                      linkedObject.collection.linkObjectType = ECmsCollectionLinkObjectType.TradeOffer;
                      break;
                    case EOfferType.Product:
                      linkedObject.collection.linkObjectType = null;
                      break;
                    case EOfferType.Booking:
                      linkedObject.collection.linkObjectType = null;
                      break;
                    case null:
                      linkedObject.collection.linkObjectType = null;
                      break;
                  }
                  break;
                case ECmsLinkObjectType.Banner:
                  if (state.byId.data.type?.code === ECmsContainerType.Category4Offer) {
                    switch (state.byId.data.offerType) {
                      case EOfferType.Corp:
                        linkedObject.banner.linkedObject = null;
                        linkedObject.banner.linkObjectType = ECmsBannerLinkObjectType.CorpOfferCategory;
                        linkedObject.banner.id = null;
                        break;
                      case EOfferType.Trade:
                        linkedObject.banner.linkedObject = null;
                        linkedObject.banner.linkObjectType = ECmsBannerLinkObjectType.TradeOfferCategory;
                        linkedObject.banner.id = null;
                        break;
                      case EOfferType.Product:
                        linkedObject.banner.linkedObject = null;
                        linkedObject.banner.linkObjectType = null;
                        linkedObject.banner.id = null;
                        break;
                      case EOfferType.Booking:
                        linkedObject.banner.linkedObject = null;
                        linkedObject.banner.linkObjectType = null;
                        linkedObject.banner.id = null;
                        break;
                      case null:
                        linkedObject.banner.linkedObject = null;
                        linkedObject.banner.linkObjectType = null;
                        linkedObject.banner.id = null;
                        break;
                    }
                    break;
                  } else if (state.byId.data.type?.code === ECmsContainerType.Banner1LinkResizable) {
                    linkedObject.banner.id = null;
                    break;
                  } else {
                    linkedObject.banner.linkedObject = null;
                    linkedObject.banner.linkObjectType = null;
                    linkedObject.banner.id = null;
                    break;
                  }
              }
            });
            break;
          case 'targetType':
            state.byId.data.targetLocalities = null;
            state.byId.data.targetRoads = null;
            state.byId.data.targetOrgUnits = null;
            state.byId.data.targetGender = null;
            state.byId.data.targetExternalUsers = null;
            state.byId.data.targetClientOrgs = null;
            state.byId.data.targetTradeUnionMembersOnly = null;
            state.byId.data.targetHavingChildFamilyMemberOnly = null;
            state.byId.data.targetFamilyMemberOnly = null;
            state.byId.data.targetHasRzdSocialPackage = null;
            break;
          case 'targetClientOrgs':
            const isRzdClientOrg = state.byId.data.targetClientOrgs?.in?.some(
              clientOrg => clientOrg.id === clientOrgRzdCode
            );
            if (!isRzdClientOrg) {
              state.byId.data.targetHasRzdSocialPackage = null;
            }
            break;
          default:
            break;
        }

        const newTarget = getCmsContainerViewTarget({ ...state.byId.data });
        //очищаем данные баннеров и коллекций если поменялся таргет
        if (JSON.stringify(currentTarget) !== JSON.stringify(newTarget)) {
          state.linkedObjects.map(linkedObject => {
            switch (linkedObject.type) {
              case ECmsLinkObjectType.Banner:
                linkedObject.banner.linkObjectId = null;
                if (linkedObject.banner.linkedObject) {
                  //для баннеров все сложно - у них есть свой вложенный тип, так что нужно его почистить
                  switch (linkedObject.banner.linkObjectType) {
                    case ECmsBannerLinkObjectType.Collection:
                      linkedObject.banner.linkedObject.linkedObject = [];
                      break;
                    case ECmsBannerLinkObjectType.CorpOffer:
                    case ECmsBannerLinkObjectType.TradeOffer:
                    case ECmsBannerLinkObjectType.CorpOfferCategory:
                    case ECmsBannerLinkObjectType.TradeOfferCategory:
                    case ECmsBannerLinkObjectType.Link:
                      linkedObject.banner.linkedObject = null;
                      break;
                  }
                }
                break;
              case ECmsLinkObjectType.Collection:
                linkedObject.collection.id = null;
                linkedObject.collection.linkedObject = null;
                break;
            }
          });

          state.byId.modified = true;
        }
      }
    },
    cmsContainerCreateChangeLinkedObject: (state, { payload }) => {
      const { index } = payload;

      const linkedObject = state.linkedObjects?.[index];

      switch (payload.type) {
        case ECmsLinkObjectType.Banner:
          if (linkedObject?.type === ECmsLinkObjectType.Banner) {
            if (linkedObject) {
              linkedObject.banner = payload.data;
            } else {
              const newLinkedObject: CmsLinkedObjectBanner = {
                ...getEmptyCmsLinkedObjectBanner(),
                banner: payload.data,
              };
              state.linkedObjects.splice(index, 1, newLinkedObject);
            }
          } else {
            console.error(`Type '${payload.type}' and linkedObject.type '${linkedObject?.type}' are not equals`);
          }
          break;
        case ECmsLinkObjectType.Collection:
          if (linkedObject?.type === ECmsLinkObjectType.Collection) {
            if (linkedObject) {
              linkedObject.collection = payload.data;
            } else {
              const linkedObject = getCmsCollectionLinkedObjectTypeByContainer(
                state.byId.data.type?.code ?? null,
                state.byId.data.offerType
              );
              const newLinkedObject: CmsLinkedObjectCollection = {
                ...getEmptyCmsLinkedObjectCollection(linkedObject),
                collection: payload.data,
              };
              state.linkedObjects.splice(index, 1, newLinkedObject);
            }
          } else {
            console.error(`Type '${payload.type}' and linkedObject.type '${linkedObject?.type}' are not equals`);
          }
          break;
      }

      state.byId.modified = true;
    },
  },
  extraReducers: builder => {
    builder.addCase(cmsContainerCreateByIdFetch.pending, state => {
      state.byId.isFetching = true;
      state.byId.isFetched = false;
      state.byId.isFailed = false;
    });
    builder.addCase(cmsContainerCreateByIdFetch.fulfilled, (state, { payload }) => {
      const { data, loadedData, components, linkedObjects } = payload;

      state.byId.isFetched = true;
      state.byId.isFetching = false;
      state.byId.isFailed = false;

      state.byId.data = data;
      state.byId.loadedData = loadedData;
      state.byId.modified = false;

      state.components = components;
      state.linkedObjects = linkedObjects;
    });
    builder.addCase(cmsContainerCreateByIdFetch.rejected, state => {
      state.byId.isFailed = true;
      state.byId.isFetching = false;
      state.byId.isFetched = false;
    });
    builder.addCase(cmsContainerCreateSave.pending, state => {
      state.save.isFetching = true;
      state.save.isFetched = false;
      state.save.isFailed = false;
    });
    builder.addCase(cmsContainerCreateSave.fulfilled, (state, { payload }) => {
      const { cmsContainer, cmsLinkedObjects } = payload;

      state.save.isFetched = true;
      state.save.isFetching = false;
      state.save.isFailed = false;

      state.byId.data = convertCmsContainerToCmsContainerView(payload.cmsContainer, cmsLinkedObjects);
      state.byId.loadedData = cmsContainer;
      state.byId.modified = false;

      state.linkedObjects = cmsLinkedObjects;
      state.components = cmsContainer.components ?? [];
    });
    builder.addCase(cmsContainerCreateSave.rejected, (state, { payload }) => {
      state.save.isFailed = true;
      state.save.isFetching = false;
      state.save.isFetched = false;

      if (payload?.cmsContainer) {
        state.byId.data = convertCmsContainerToCmsContainerView(payload.cmsContainer, payload.cmsLinkedObjects ?? []);
        state.byId.loadedData = payload.cmsContainer;
        state.components = payload.cmsContainer.components ?? [];
      }

      if (payload?.cmsContainer && payload?.cmsLinkedObjects) {
        state.linkedObjects = payload.cmsLinkedObjects;
      }
    });
    builder.addCase(cmsContainerCreateDelete.pending, state => {
      state.delete.isFetching = true;
      state.delete.isFetched = false;
      state.delete.isFailed = false;
    });
    builder.addCase(cmsContainerCreateDelete.fulfilled, state => {
      state.delete.isFetched = true;
      state.delete.isFetching = false;
      state.delete.isFailed = false;
    });
    builder.addCase(cmsContainerCreateDelete.rejected, state => {
      state.delete.isFailed = true;
      state.delete.isFetching = false;
      state.delete.isFetched = false;
    });
    builder.addCase(cmsContainerCreateDuplicate.pending, state => {
      state.duplicate.isFetching = true;
      state.duplicate.isFetched = false;
      state.duplicate.isFailed = false;
    });
    builder.addCase(cmsContainerCreateDuplicate.fulfilled, state => {
      state.duplicate.isFetched = true;
      state.duplicate.isFetching = false;
      state.duplicate.isFailed = false;
    });
    builder.addCase(cmsContainerCreateDuplicate.rejected, state => {
      state.duplicate.isFailed = true;
      state.duplicate.isFetching = false;
      state.duplicate.isFetched = false;
    });
    builder.addCase(cmsContainerCreatePublish.pending, state => {
      state.publish.isFetching = true;
      state.publish.isFetched = false;
      state.publish.isFailed = false;
    });
    builder.addCase(cmsContainerCreatePublish.fulfilled, state => {
      state.publish.isFetched = true;
      state.publish.isFetching = false;
      state.publish.isFailed = false;
    });
    builder.addCase(cmsContainerCreatePublish.rejected, state => {
      state.publish.isFailed = true;
      state.publish.isFetching = false;
      state.publish.isFetched = false;
    });
  },
});

export const { cmsContainerCreateStartSession, cmsContainerCreateSetAttribute, cmsContainerCreateChangeLinkedObject } =
  slice.actions;

export default slice.reducer;

const linkedObjectsSelector: Selector<RootState, Nullable<CmsLinkedObject[]>> = state =>
  state.cms.container.create.linkedObjects;
const indexSelector: Selector<RootState, number, [number]> = (state, index) => index;

export const createCmsContainerCreateCurrentLinkedObjectByIndexSelector = () =>
  createSelector(
    linkedObjectsSelector,
    indexSelector,
    (linkedObjects, index): Nullable<CmsLinkedObject> => linkedObjects?.[index] ?? null
  );
