import { ENoticeStatus } from '@/domain';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notifier from '../../../../system/notifier';
import { pluralize } from '../../../utils/pluralize';
import PersonalPromotionCreate from './component';
import { personalPromotionCreateGenerateSelector, personalPromotionCreateUploadSelector } from './store/selectors';
import {
  personalPromotionCreateGenerate,
  personalPromotionCreateStateReset,
  personalPromotionCreateUpload,
} from './store/slice';

interface PersonalPromotionCreateContainerProps {
  readonly offerId: UUID | (() => Promise<Nullable<UUID>>);
  readonly error?: string;
  readonly canGenerate: boolean;
  readonly onCreated: () => void;
  readonly beforeCreate?: () => Promise<boolean>;
}

const PersonalPromotionCreateContainer = (props: PersonalPromotionCreateContainerProps) => {
  const { offerId, error, canGenerate, beforeCreate = () => Promise.resolve(true), onCreated } = props;

  const dispatch = useDispatch();

  const {
    isFetching: isUploading,
    isFetched: isUploaded,
    data: uploadResult,
  } = useSelector(personalPromotionCreateUploadSelector);

  const {
    isFetching: isGenerating,
    isFetched: isGenerated,
    data: generatedCodes,
  } = useSelector(personalPromotionCreateGenerateSelector);

  const onUpload = (file: File) => {
    if (typeof offerId === 'string') {
      beforeCreate().then(result => {
        result && dispatch(personalPromotionCreateUpload({ offerId, file }));
      });
    } else {
      beforeCreate().then(result => {
        result &&
          offerId().then(id => {
            if (id) dispatch(personalPromotionCreateUpload({ offerId: id, file }));
          });
      });
    }
  };

  const onGenerate = (count: number) => {
    if (typeof offerId === 'string') {
      beforeCreate().then(result => {
        result && dispatch(personalPromotionCreateGenerate({ offerId, count }));
      });
    } else {
      beforeCreate().then(result => {
        result &&
          offerId().then(id => {
            if (id) dispatch(personalPromotionCreateGenerate({ offerId: id, count }));
          });
      });
    }
  };

  useEffect(() => {
    if (isUploaded && uploadResult) {
      const { successCount, errorCount } = uploadResult;
      dispatch(personalPromotionCreateStateReset());
      Notifier.getInstance().addNotice(
        ENoticeStatus.Success,
        `${pluralize(successCount, ['Добавлен', 'Добавлено', 'Добавлено'])} ${successCount} ${pluralize(successCount, [
          'код',
          'кода',
          'кодов',
        ])}`
      );
      if (errorCount > 0) {
        Notifier.getInstance().addNotice(
          ENoticeStatus.Error,
          `${errorCount} ${pluralize(errorCount, ['строка', 'строки', 'строк'])} с ошибкой`
        );
      }
      onCreated();
    }
  }, [dispatch, isUploaded, uploadResult, onCreated]);

  useEffect(() => {
    if (isGenerated && generatedCodes) {
      const generatedCount = generatedCodes.length;
      dispatch(personalPromotionCreateStateReset());
      Notifier.getInstance().addNotice(
        ENoticeStatus.Success,
        `${pluralize(generatedCount, ['Добавлен', 'Добавлено', 'Добавлено'])} ${generatedCount} ${pluralize(
          generatedCount,
          ['код', 'кода', 'кодов']
        )}`
      );
      onCreated();
    }
  }, [dispatch, isGenerated, generatedCodes, onCreated]);

  useEffect(() => {
    return () => {
      dispatch(personalPromotionCreateStateReset());
    };
  }, [dispatch]);

  return (
    <PersonalPromotionCreate
      isExecuting={isUploading || isGenerating}
      error={error}
      onUpload={onUpload}
      onGenerate={canGenerate ? onGenerate : null}
    />
  );
};

export default PersonalPromotionCreateContainer;
