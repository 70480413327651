import { FormHelperText, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Nullable } from '../../../../../domain/model/types';
import { ErrorToggleButton } from './controls';

type MPToggleButtonsGroupItem = {
  readonly id: string | number;
  readonly name: string;
  readonly error?: boolean;
};

interface MPToggleButtonsGroupSingleProps<T extends MPToggleButtonsGroupItem> {
  readonly items: T[];
  readonly value: Nullable<T>;
  readonly size?: 'small' | 'medium' | 'large';
  readonly error?: boolean;
  readonly helperText?: string;
  readonly disabled?: boolean;
  readonly fullWidth?: boolean;
  readonly className?: any;
  readonly onChange?: (value: Nullable<T>) => void;
}

export function MPToggleButtonsGroupSingle<T extends MPToggleButtonsGroupItem>(
  props: MPToggleButtonsGroupSingleProps<T>
) {
  const {
    items,
    value,
    size = 'small',
    className,
    disabled,
    error,
    helperText,
    fullWidth,
    onChange = () => null,
  } = props;

  return (
    <>
      <ToggleButtonGroup
        exclusive
        size={size}
        value={value ? items.find(item => item.id === value.id) : ''}
        className={className}
        disabled={disabled}
        fullWidth={fullWidth}
        onChange={(event, newValue: T) => onChange(newValue)}
      >
        {items.map(item =>
          item.error ? (
            <ErrorToggleButton
              key={item.id}
              value={item}
            >
              {item.name}
            </ErrorToggleButton>
          ) : (
            <ToggleButton
              key={item.id}
              value={item}
            >
              {item.name}
            </ToggleButton>
          )
        )}
      </ToggleButtonGroup>
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </>
  );
}
