import { ESortDirection } from '../../../../domain/model/enums';
import { SportUserProfile } from '../../../../domain/model/user';
import DataTable, { DataTableMetadata } from '../../../components/common/table';
import { DataTableLoader } from '../../../components/common/table/loader';
import SportsmanTableCellActivitySubTypes from '../components/tableCell/activitySubTypes';
import SportsmanTableCellActivityTypes from '../components/tableCell/activityTypes';
import SportsmanTableCellAge from '../components/tableCell/age';
import SportsmanTableCellDepartmentName from '../components/tableCell/departmentName';
import SportsmanTableCellLocality from '../components/tableCell/locality';
import SportsmanTableCellLogo from '../components/tableCell/logo';
import SportsmanTableCellName from '../components/tableCell/name';
import SportsmanTableCellRoad from '../components/tableCell/road';
import SportsmanTableCellAdapter from './adapters/cell';
import { Wrapper } from './controls';
import { sportsmansGetisFetching } from './store/selectors';
import { ESportsmanTableColumn } from './utils';

interface SportsmanTableProps {
  readonly metadata: DataTableMetadata<ESportsmanTableColumn>;
  readonly sportsmen: SportUserProfile[];
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onClick: (sportsman: SportUserProfile) => void;
}

const SportsmanTable = (props: SportsmanTableProps) => {
  const { metadata, sort, sportsmen, onRequestSort, onClick } = props;

  const rows = sportsmen.map((sportsman, index) => {
    return {
      [ESportsmanTableColumn.Logo]: (
        <SportsmanTableCellAdapter
          index={index}
          component={SportsmanTableCellLogo}
        />
      ),
      [ESportsmanTableColumn.LastName]: (
        <SportsmanTableCellAdapter
          index={index}
          component={SportsmanTableCellName}
        />
      ),
      [ESportsmanTableColumn.Age]: (
        <SportsmanTableCellAdapter
          index={index}
          component={SportsmanTableCellAge}
        />
      ),
      [ESportsmanTableColumn.ActivityTypes]: (
        <SportsmanTableCellAdapter
          index={index}
          component={SportsmanTableCellActivityTypes}
        />
      ),
      [ESportsmanTableColumn.ActivitySubTypes]: (
        <SportsmanTableCellAdapter
          index={index}
          component={SportsmanTableCellActivitySubTypes}
        />
      ),
      [ESportsmanTableColumn.DepartmentName]: (
        <SportsmanTableCellAdapter
          index={index}
          component={SportsmanTableCellDepartmentName}
        />
      ),
      [ESportsmanTableColumn.Road]: (
        <SportsmanTableCellAdapter
          index={index}
          component={SportsmanTableCellRoad}
        />
      ),
      [ESportsmanTableColumn.Locality]: (
        <SportsmanTableCellAdapter
          index={index}
          component={SportsmanTableCellLocality}
        />
      ),
      data: sportsman,
    };
  });

  return (
    <Wrapper>
      <DataTable<SportUserProfile, ESportsmanTableColumn>
        metadata={metadata}
        rows={rows}
        sort={{ column: sort.column, direction: sort.direction }}
        loader={<DataTableLoader selector={sportsmansGetisFetching} />}
        onRowClick={(event, cell, { data }) => onClick(data)}
        onSort={(event, column, direction) => onRequestSort(column, direction)}
      />
    </Wrapper>
  );
};

export default SportsmanTable;
