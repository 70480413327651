import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { ServerErrorResponse } from '../../../../../data/network/types';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable } from '../../../../../domain/model';
import { EPartnerStatus } from '../../../../../domain/model/enums';
import { PartnerShortDraft } from '../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { PaginationSize } from '../../../../types';
import partnerServices from '../../../general/partner/services';
import { AllProps } from '../../../general/partner/services/common';
import {
  EPartnerActionType,
  EPartnerTableColumn,
  EPartnerTableTab,
  PartnerActionTableType,
} from '../../../general/partner/types';
import { partnerEditAdminUpdate } from '../../edit/store/slice';
import { PartnerTableFilterValues } from '../../filterUtils';
import { PartnerTableTabsCounter } from '../utils';

const defaultSort = `${EPartnerTableColumn.Date},desc`;

const getActionProcess = (state: PartnerListState, id: UUID, type: PartnerActionTableType) => {
  let process = state.actions.find(change => change.id === id);
  if (process) return process;

  process = {
    ...fetchableDefault,
    id,
    type,
    error: null,
  };
  state.actions.push(process);

  return process;
};

export type PartnersFetchProps = Omit<AllProps, 'signal'>;

type PartnersCountsFetchProps = PartnersFetchProps & {
  readonly tabs: EPartnerTableTab[];
};

export const partnersFetch = createAsyncThunk<Pageable<PartnerShortDraft>, PartnersFetchProps, AppThunkAPIConfig>(
  'partner/list/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await partnerServices.common.all({ ...props, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const partnersCountsFetch = createAsyncThunk<
  PartnerTableTabsCounter,
  PartnersCountsFetchProps,
  AppThunkAPIConfig
>('partner/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await partnerServices.common.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

export const partnersActivatePartner = createAsyncThunk<void, UUID, AppThunkAPIConfig<ServerErrorResponse>>(
  'partner/list/partner/activate',
  async (id, { rejectWithValue }) => {
    try {
      await partnerServices.common.enabled({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const partnersDeactivatePartner = createAsyncThunk<void, UUID, AppThunkAPIConfig<ServerErrorResponse>>(
  'partner/list/partner/deactivate',
  async (id, { rejectWithValue }) => {
    try {
      await partnerServices.common.disabled({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const partnersSendToVerificationPartner = createAsyncThunk<void, UUID, AppThunkAPIConfig<ServerErrorResponse>>(
  'partner/list/partner/sendToVerification',
  async (id, { rejectWithValue }) => {
    try {
      await partnerServices.common.sendToVerification({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const partnersDeletePartner = createAsyncThunk<void, UUID, AppThunkAPIConfig<ServerErrorResponse>>(
  'partner/list/partner/delete',
  async (id, { rejectWithValue }) => {
    try {
      await partnerServices.common.delete({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface PartnerListState extends Fetchable, Pageable<PartnerShortDraft> {
  readonly guid: Nullable<UUID>;
  readonly tab: Nullable<EPartnerTableTab>;
  readonly needRefreshWatcher: number;
  readonly filter: PartnerTableFilterValues;
  readonly tabsCounter: PartnerTableTabsCounter;
  readonly pageNumber: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
    readonly createdById: Nullable<UUID>;
  };
  readonly changeStatus: (Fetchable & {
    id: UUID;
    status: EPartnerStatus;
    error: Nullable<ServerErrorResponse>;
  })[];
  readonly actions: (Fetchable & {
    id: UUID;
    type: PartnerActionTableType;
    error: Nullable<ServerErrorResponse>;
  })[];
}

type Reducer<T = undefined> = CaseReducer<PartnerListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PartnerListState> {
  partnersStartSession: Reducer<{ guid: UUID }>;
  partnersSetTab: Reducer<{ tab: EPartnerTableTab }>;
  partnersSortReset: Reducer;
  partnersSetSort: Reducer<{ sort: string }>;
  partnersSetPage: Reducer<number>;
  partnersSetPageSize: Reducer<PaginationSize>;
  partnersSetFilter: Reducer<PartnerTableFilterValues>;
  partnersActionsReset: Reducer;
  partnersDataReset: Reducer;
  partnersNeedRefreshWatcherReset: Reducer;
}

const slice = createSlice<PartnerListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    ...fetchableDefault,
    data: [],
    guid: null,
    tab: null,
    needRefreshWatcher: 0,
    filter: {},
    tabsCounter: {},
    changeStatus: [],
    actions: [],
    totalCount: 0,
    pageNumber: 1,
    pageCount: 1,
    search: {
      sort: defaultSort,
      pageSize: 10,
      createdById: null,
    },
  },
  reducers: {
    partnersStartSession: (state, { payload }) => {
      const { guid } = payload;
      if (guid !== state.guid) {
        state.guid = guid;
        state.data = [];
        state.tab = null;
        state.needRefreshWatcher = 0;
        state.filter = {};
        state.tabsCounter = {};
        state.changeStatus = [];
        state.actions = [];
        state.pageNumber = 1;
        state.search = {
          sort: defaultSort,
          pageSize: 10,
          createdById: null,
        };
      }
    },
    partnersSetTab: (state, { payload }) => {
      const { tab } = payload;
      // сбрасываем пейджинг и сортировку, если поменялись статусы (закладки)
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    partnersSortReset: state => {
      state.search.sort = defaultSort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    partnersSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    partnersSetPage: (state, { payload }) => {
      state.pageNumber = payload;
      state.needRefreshWatcher++;
    },
    partnersSetPageSize: (state, { payload }) => {
      state.pageNumber = 1;
      state.search.pageSize = payload;
      state.needRefreshWatcher++;
    },
    partnersSetFilter: (state, { payload }) => {
      state.pageNumber = 1;
      state.filter = payload;
      state.needRefreshWatcher++;
    },
    partnersChangePartnerStatusReset: state => {
      state.changeStatus = [];
    },
    partnersDataReset: state => {
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
      state.data = [];
    },
    partnersActionsReset: state => {
      state.actions = [];
    },
    partnersNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(partnersFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
      })
      .addCase(partnersFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(partnersFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(partnersActivatePartner.pending, (state, { meta }) => {
        const id = meta.arg;

        const actionType = EPartnerActionType.Activate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(partnersActivatePartner.fulfilled, (state, { meta }) => {
        const id = meta.arg;

        const actionType = EPartnerActionType.Activate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(partnersActivatePartner.rejected, (state, { payload, meta }) => {
        const id = meta.arg;

        const actionType = EPartnerActionType.Activate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })

      .addCase(partnersDeactivatePartner.pending, (state, { meta }) => {
        const id = meta.arg;

        const actionType = EPartnerActionType.Deactivate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(partnersDeactivatePartner.fulfilled, (state, { meta }) => {
        const id = meta.arg;

        const actionType = EPartnerActionType.Deactivate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(partnersDeactivatePartner.rejected, (state, { payload, meta }) => {
        const id = meta.arg;

        const actionType = EPartnerActionType.Deactivate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })

      .addCase(partnersSendToVerificationPartner.pending, (state, { meta }) => {
        const id = meta.arg;

        const actionType = EPartnerActionType.ReturnToVerification;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(partnersSendToVerificationPartner.fulfilled, (state, { meta }) => {
        const id = meta.arg;

        const actionType = EPartnerActionType.ReturnToVerification;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(partnersSendToVerificationPartner.rejected, (state, { payload, meta }) => {
        const id = meta.arg;

        const actionType = EPartnerActionType.ReturnToVerification;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })

      .addCase(partnersDeletePartner.pending, (state, { meta }) => {
        const id = meta.arg;

        const actionType = EPartnerActionType.Delete;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(partnersDeletePartner.fulfilled, (state, { meta }) => {
        const id = meta.arg;

        const actionType = EPartnerActionType.Delete;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(partnersDeletePartner.rejected, (state, { payload, meta }) => {
        const id = meta.arg;

        const actionType = EPartnerActionType.Delete;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })

      .addCase(partnerEditAdminUpdate.fulfilled, state => {
        state.needRefreshWatcher++;
      })
      .addCase(partnersCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(partnersCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      });
  },
});

export const {
  partnersStartSession,
  partnersSetTab,
  partnersSortReset,
  partnersSetSort,
  partnersSetFilter,
  partnersActionsReset,
  partnersDataReset,
  partnersNeedRefreshWatcherReset,
  partnersSetPage,
  partnersSetPageSize,
} = slice.actions;

export default slice.reducer;
