import { UUID } from '../../domain/model/types';

type GetIndexedField<T, K> = K extends keyof T
  ? T[K]
  : K extends `${number}`
  ? '0' extends keyof T
    ? undefined
    : number extends keyof T
    ? T[number]
    : undefined
  : undefined;

type FieldWithPossiblyUndefined<T, Key> =
  | GetFieldType<Exclude<T, undefined | null>, Key>
  | Extract<T, undefined | null>;

type IndexedFieldWithPossiblyUndefined<T, Key> =
  | GetIndexedField<Exclude<T, undefined | null>, Key>
  | Extract<T, undefined | null>;

export type GetFieldType<T, P> = P extends `${infer Left}.${infer Right}`
  ? Left extends keyof T
    ? FieldWithPossiblyUndefined<T[Left], Right>
    : Left extends `${infer FieldKey}[${infer IndexKey}]`
    ? FieldKey extends keyof T
      ? FieldWithPossiblyUndefined<IndexedFieldWithPossiblyUndefined<T[FieldKey], IndexKey>, Right>
      : undefined
    : undefined
  : P extends keyof T
  ? T[P]
  : P extends `${infer FieldKey}[${infer IndexKey}]`
  ? FieldKey extends keyof T
    ? IndexedFieldWithPossiblyUndefined<T[FieldKey], IndexKey>
    : undefined
  : undefined;

export type Modifier<TData> = <TPath extends string>(name: TPath, value: GetFieldType<TData, TPath>) => void;

export type ModifierArray<TData> = <TPath extends string>(
  id: UUID,
  name: TPath,
  value: GetFieldType<TData, TPath>
) => void;

export type ArgMultiModifier<TData, TPath extends string> = {
  [key in TPath]: GetFieldType<TData, TPath>;
};

export type MultiModifier<TData, TPath extends string = Extract<keyof TData, string>> = (
  fields: Partial<ArgMultiModifier<TData, TPath>>
) => void;

export function getField<TData, TPath extends string, TDefault = GetFieldType<TData, TPath>>(
  data: TData,
  path: TPath,
  defaultValue?: TDefault
): GetFieldType<TData, TPath> | TDefault {
  const value = path
    .split(/[.[\]]/)
    .filter(Boolean)
    .reduce<GetFieldType<TData, TPath>>((value, key) => (value as any)?.[key], data as any);

  return value !== undefined ? value : (defaultValue as TDefault);
}

export function setField<TData, TPath extends string, TValue = GetFieldType<TData, TPath>>(
  data: TData,
  path: TPath,
  value: TValue
): TData {
  const obj = path
    .split(/[.[\]]/)
    .filter(Boolean)
    .reduce<GetFieldType<TData, TPath>>((acc, key, index, source) => {
      if (index === source.length - 1) {
        (acc as any)[key] = value;
        return acc;
      }
      if ((acc as any)?.[key]) return (acc as any)?.[key];
      return ((acc as any)[key] = {});
    }, data as any);

  return obj as any;
}
