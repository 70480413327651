import { Address } from '../../../../../../domain/model/address';
import { EAddressLevel } from '../../../../../../domain/model/enums';
import { AddressHelper } from '../../../../../utils/address';
import AddressMultipleSelector, { AddressMultipleSelectorProps } from './index';

export interface AddressCityMultipleSelectorProps
  extends Omit<
    AddressMultipleSelectorProps,
    'getOptionLabel' | 'onlyValidPostalCode' | 'level' | 'fromLevel' | 'toLevel'
  > {}

const getOptionLabelDefault = (address: Address) => new AddressHelper(address).getLastLocalityShortPath() ?? '';
const getTagLabelDefault = (address: Address) => new AddressHelper(address).getLastLocalityShortPath() ?? '';

const AddressCityMultipleSelector = (props: AddressCityMultipleSelectorProps) => {
  return (
    <AddressMultipleSelector
      {...props}
      getTagLabel={getTagLabelDefault}
      getOptionLabel={getOptionLabelDefault}
      level={EAddressLevel.City}
    />
  );
};

export default AddressCityMultipleSelector;
