import { Grid } from '@mui/material';
import { SportOptionTyped } from '../../../../../domain/model';
import { EPartnerOwnershipType, EPartnerType, ETaxationSystemType } from '../../../../../domain/model/enums';
import { GlobalNotificationOption, NotificationOption } from '../../../../../domain/model/notification';
import { Partner, PartnerDesk } from '../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../domain/model/types';
import ConfigurationNotifications from '../../../configuration/components/notifications';
import PartnerDetailsTabDesk from '../../components/detailsTab/desk';
import PartnerDetailsTabLegal from '../../components/detailsTab/legal';
import { ColumnWrapper, Wrapper } from '../controls';
import { EPartnerDetailsLegalTab, EPartnerDetailsTab, PartnerDetailsLegalTab } from '../utils';
import PartnerDetailsOwnerDeskStub from './deskStub';

interface PartnerDetailsOwnerComponentProps {
  readonly tab: EPartnerDetailsTab;
  readonly legalTab: EPartnerDetailsLegalTab;
  readonly legalTabs: PartnerDetailsLegalTab[];
  readonly partner: Partner;
  readonly partnerTypes: SportOptionTyped<EPartnerType>[];
  readonly ownerShipTypes: SportOptionTyped<EPartnerOwnershipType>[];
  readonly notifications: NotificationOption[];
  readonly desk: Nullable<PartnerDesk>;
  readonly taxSystems: SportOptionTyped<ETaxationSystemType>[];
  readonly onEditDesk?: () => void;
  readonly onChangeLegalTab: (tab: PartnerDetailsLegalTab) => void;
  readonly onChangeNotificationOption: Nullable<(option: NotificationOption, enabled: boolean) => void>;
}

const PartnerDetailsOwnerComponent = (props: PartnerDetailsOwnerComponentProps) => {
  const {
    tab,
    partner,
    partnerTypes,
    notifications,
    legalTab,
    legalTabs,
    desk,
    taxSystems,
    ownerShipTypes,
    onEditDesk,
    onChangeLegalTab,
    onChangeNotificationOption,
  } = props;

  const onChangeNotification = (id: UUID, enabled: boolean) => {
    const option = notifications.find(item => item.id === id);
    if (option && onChangeNotificationOption) {
      onChangeNotificationOption(option, enabled);
    }
  };

  const notificationsData: GlobalNotificationOption[] = notifications.map(notification => {
    const enabled =
      partner.marketingDistributionSettings?.find(item => item.option.id === notification.id)?.enabled ?? false;

    return {
      ...notification,
      enabled,
    };
  });

  return (
    <Wrapper container>
      <Grid
        item
        xs={6}
      >
        <ColumnWrapper>
          {tab === EPartnerDetailsTab.Legal && (
            <PartnerDetailsTabLegal
              partner={partner}
              partnerTypes={partnerTypes}
              tabs={legalTabs}
              tab={legalTab}
              taxSystems={taxSystems}
              ownerShipTypes={ownerShipTypes}
              onChangeTab={onChangeLegalTab}
            />
          )}
          {tab === EPartnerDetailsTab.Desk && !desk && <PartnerDetailsOwnerDeskStub onEdit={onEditDesk} />}
          {tab === EPartnerDetailsTab.Desk && desk && <PartnerDetailsTabDesk desk={desk} />}
          {tab === EPartnerDetailsTab.Notifications && (
            <ConfigurationNotifications
              notifications={notificationsData}
              onChange={onChangeNotification}
            />
          )}
        </ColumnWrapper>
      </Grid>
    </Wrapper>
  );
};

export default PartnerDetailsOwnerComponent;
