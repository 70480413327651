import { getUserFullName } from '../../../../utils';
import CorpActivationTableCell, { CorpActivationTableCellCommonProps } from './index';

const CorpActivationTableCellGivenBy = (props: CorpActivationTableCellCommonProps) => {
  const {
    corpActivation: { givenBy },
  } = props;

  const value = givenBy ? getUserFullName({ user: givenBy }) : '-';

  return (
    <CorpActivationTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpActivationTableCellGivenBy;
