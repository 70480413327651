import { ContentTargetViewClientOrgsTableCell } from '@features/general/contentTarget/components/viewClientOrgs';
import ProductTableCell, { ProductTableCellCommonProps } from './index';

const ProductTableCellTargetClientOrgs = (props: ProductTableCellCommonProps) => {
  const {
    productOffer: { target },
  } = props;

  return (
    <ProductTableCell
      {...props}
      value={<ContentTargetViewClientOrgsTableCell value={target?.targetClientOrgs ?? null} />}
    />
  );
};

export default ProductTableCellTargetClientOrgs;
