import { Typography, TypographyProps } from '@mui/material';
import { AnyAddress } from '../../../../../domain/model/address';
import { Nullable } from '../../../../../domain/model/types';
import { AddressHelper } from '../../../../utils/address';

interface AddressLocalityFullPathViewProps extends TypographyProps {
  readonly address: Nullable<AnyAddress>;
}

const AddressLocalityFullPathView = ({ address, ...others }: AddressLocalityFullPathViewProps) => {
  const value = address ? new AddressHelper(address).getLocalityFullPath() : null;

  return <Typography {...others}>{value || '-'}</Typography>;
};

export default AddressLocalityFullPathView;
