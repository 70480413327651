import { SvgIconOwnProps } from '@mui/material';
import theme from '../../../../theme';

export interface AppSvgProps extends SvgIconOwnProps {
  readonly icon: any;
}

const AppSvgIcon = ({ icon, viewBox = '0 0 20 20', ...others }: AppSvgProps) => {
  const Component = icon;
  return (
    <Component
      viewBox={viewBox}
      {...others}
    />
  );
};

export default AppSvgIcon;

export const MenuSvgIcon = ({ htmlColor, ...others }: AppSvgProps) => {
  return (
    <AppSvgIcon
      htmlColor={htmlColor ?? theme.palette.text.primary}
      {...others}
    />
  );
};
