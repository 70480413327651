import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { ServerErrorResponse } from '../../../../../data/network/types';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault, fetchableFetched, fetchableFetching } from '../../../../../data/store/types';
import { BookingOffer, BookingOfferService, BookingOfferShort } from '../../../../../domain/model/booking';
import { Nullable, UUID } from '../../../../../domain/model/types';
import bookingServices from '../../services';
import { BookingOfferServiceView, BookingOfferView, EBookingActionType } from '../../types';

const getActionProcess = (state: BookingOfferActionsState, id: UUID, actionType: EBookingActionType) => {
  let process = state.actions.find(change => change.id === id);
  if (process) return process;

  process = {
    ...fetchableDefault,
    id,
    type: actionType,
    error: null,
  };
  state.actions.push(process);

  return process;
};

export const bookingOfferActionsSave = createAsyncThunk<
  { offer: BookingOffer; errors: string[] },
  BookingOfferView,
  AppThunkAPIConfig<{
    error: ServerErrorResponse;
  }>
>('bookingOffer/actions/save', async (bookingOffer, { getState, rejectWithValue }) => {
  try {
    return await bookingServices.offer.save(bookingOffer);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue({
      error: e.response.data,
    });
  }
});

export const bookingOfferActionsManageServices = createAsyncThunk<
  { services: BookingOfferService[]; errors: string[] },
  { offerId: UUID; services: BookingOfferServiceView[] },
  AppThunkAPIConfig<{
    error: ServerErrorResponse;
  }>
>('bookingOffer/actions/manageServices', async ({ offerId, services }, { getState, rejectWithValue }) => {
  try {
    return await bookingServices.service.saveOfferServices({ offerId, services });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue({
      error: e.response.data,
    });
  }
});

export const bookingOfferActionsPublish = createAsyncThunk<BookingOffer, { id: UUID }, AppThunkAPIConfig>(
  'bookingOffer/actions/toModeration',
  async (prop, { rejectWithValue }) => {
    try {
      return await bookingServices.offer.toModeration(prop);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export const bookingOfferActionsDuplicate = createAsyncThunk<BookingOffer, { id: UUID }, AppThunkAPIConfig>(
  'bookingOffer/actions/duplicate',
  async (prop, { rejectWithValue }) => {
    try {
      return await bookingServices.offer.duplicate(prop);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const bookingOfferActionsArchive = createAsyncThunk<
  BookingOffer,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('bookingOffer/actions/archive', async ({ id }, { rejectWithValue }) => {
  try {
    return await bookingServices.offer.archive({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingOfferActionsReject = createAsyncThunk<
  BookingOffer,
  { id: UUID; comment: string },
  AppThunkAPIConfig<ServerErrorResponse>
>('bookingOffer/actions/reject', async ({ id, comment }, { rejectWithValue }) => {
  try {
    return await bookingServices.offer.reject({ id, comment });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingOfferActionsReturnToVerification = createAsyncThunk<
  BookingOffer,
  { id: UUID; userId: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('bookingOffer/actions/returnToVerification', async ({ id, userId }, { rejectWithValue }) => {
  try {
    return await bookingServices.offer.assignModerator({ id, userId });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingOfferActionsDelete = createAsyncThunk<
  undefined,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('bookingOffer/actions/delete', async ({ id }, { rejectWithValue }) => {
  try {
    await bookingServices.offer.delete({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

//
export const bookingOfferActionsChangeModerator = createAsyncThunk<
  BookingOffer,
  { id: UUID; userId: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('bookingOffer/actions/changeModerator', async ({ id, userId }, { rejectWithValue }) => {
  try {
    return await bookingServices.offer.assignModerator({ id, userId });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingOfferActionsPause = createAsyncThunk<
  BookingOffer,
  { id: UUID; comment?: string },
  AppThunkAPIConfig<ServerErrorResponse>
>('bookingOffer/actions/pause', async ({ id, comment }, { rejectWithValue }) => {
  try {
    return await bookingServices.offer.pause({ id, comment });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingOfferActionsRetrieve = createAsyncThunk<
  BookingOffer,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('bookingOffer/actions/retrieve', async ({ id }, { rejectWithValue }) => {
  try {
    return await bookingServices.offer.retrieve({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingOfferActionsApprove = createAsyncThunk<
  BookingOffer,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('bookingOffer/actions/approve', async ({ id }, { rejectWithValue }) => {
  try {
    return await bookingServices.offer.approve({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingOfferActionsInWork = createAsyncThunk<
  BookingOffer,
  { id: UUID; userId: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('bookingOffer/actions/inWork', async ({ id, userId }, { rejectWithValue }) => {
  try {
    return await bookingServices.offer.assignModerator({ id, userId });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingOfferActionsUnPublish = createAsyncThunk<
  BookingOffer,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('bookingOffer/actions/unPublish', async ({ id }, { rejectWithValue }) => {
  try {
    return await bookingServices.offer.unPublish({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingOfferActionsResume = createAsyncThunk<
  BookingOffer,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('bookingOffer/actions/resume', async ({ id }, { rejectWithValue }) => {
  try {
    return await bookingServices.offer.resume({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingOfferActionsNewVersion = createAsyncThunk<
  { offer: BookingOffer; errors: string[] },
  { id: UUID; offer: BookingOfferView },
  AppThunkAPIConfig<{
    error: ServerErrorResponse;
  }>
>('bookingOffer/actions/newVersion', async (payload, { rejectWithValue }) => {
  try {
    return await bookingServices.offer.onSaveNewVersion(payload);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue({
      error: e.response.data,
    });
  }
});

export interface BookingOfferActionsState {
  readonly actions: (Fetchable & {
    id: UUID;
    type: EBookingActionType;
    error: Nullable<ServerErrorResponse>;
  })[];
  readonly create: Fetchable;
  readonly dialogs: {
    readonly publish: Nullable<BookingOffer | BookingOfferShort>;
    readonly reject: Nullable<BookingOffer | BookingOfferShort>;
    readonly pause: Nullable<BookingOffer | BookingOfferShort>;
    readonly changeModerator: Nullable<BookingOffer | BookingOfferShort>;
    readonly archive: Nullable<BookingOffer | BookingOfferShort>;
    readonly delete: Nullable<BookingOffer | BookingOfferShort>;
    readonly resume: Nullable<BookingOffer | BookingOfferShort>;
    readonly retrieve: Nullable<BookingOffer | BookingOfferShort>;
    readonly returnToVerification: Nullable<BookingOffer | BookingOfferShort>;
    readonly approve: Nullable<BookingOffer | BookingOfferShort>;
    readonly unPublish: Nullable<BookingOffer | BookingOfferShort>;
    readonly serviceManage: Nullable<BookingOffer | BookingOfferShort>;
  };
}

type Reducer<T = undefined> = CaseReducer<BookingOfferActionsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<BookingOfferActionsState> {
  bookingOfferActionsChangeDialogState: Reducer<{
    name: keyof BookingOfferActionsState['dialogs'];
    data: Nullable<BookingOffer | BookingOfferShort>;
  }>;
  bookingOfferActionsOptimize: Reducer;
}

const slice = createSlice<BookingOfferActionsState, Reducers, 'bookingOffer/actions'>({
  name: 'bookingOffer/actions',
  initialState: {
    actions: [],
    create: fetchableDefault,
    dialogs: {
      publish: null,
      reject: null,
      archive: null,
      changeModerator: null,
      pause: null,
      delete: null,
      resume: null,
      retrieve: null,
      returnToVerification: null,
      approve: null,
      unPublish: null,
      serviceManage: null,
    },
  },
  reducers: {
    bookingOfferActionsChangeDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
    bookingOfferActionsOptimize: state => {
      // Оставляем только выполняющиеся действия
      state.actions = state.actions.filter(action => action.isFetching);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bookingOfferActionsSave.pending, (state, { meta }) => {
        const { id } = meta.arg;

        if (id) {
          const actionType = EBookingActionType.Edit;
          const process = getActionProcess(state, id, actionType);

          process.isFetching = true;
          process.isFetched = false;
          process.isFailed = false;

          process.id = id;
          process.type = actionType;
          process.error = null;
        } else {
          state.create = fetchableFetching;
        }
      })
      .addCase(bookingOfferActionsSave.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        if (id) {
          const actionType = EBookingActionType.Edit;
          const process = getActionProcess(state, id, actionType);

          process.isFetching = false;
          process.isFetched = true;
          process.isFailed = false;

          process.id = id;
          process.type = actionType;
          process.error = null;
        } else {
          state.create = fetchableFetched;
        }
      })
      .addCase(bookingOfferActionsSave.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        if (id) {
          const actionType = EBookingActionType.Edit;
          const process = getActionProcess(state, id, actionType);
          process.isFetching = false;
          process.isFetched = false;
          process.isFailed = true;

          process.id = id;
          process.type = actionType;
          process.error = payload?.error ?? null;
        } else {
          state.create = fetchableFetched;
        }
      })
      .addCase(bookingOfferActionsNewVersion.pending, (state, { meta }) => {
        const { id } = meta.arg;

        if (id) {
          const actionType = EBookingActionType.SaveNewVersion;
          const process = getActionProcess(state, id, actionType);

          process.isFetching = true;
          process.isFetched = false;
          process.isFailed = false;

          process.id = id;
          process.type = actionType;
          process.error = null;
        } else {
          state.create = fetchableFetching;
        }
      })
      .addCase(bookingOfferActionsNewVersion.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        if (id) {
          const actionType = EBookingActionType.SaveNewVersion;
          const process = getActionProcess(state, id, actionType);

          process.isFetching = false;
          process.isFetched = true;
          process.isFailed = false;

          process.id = id;
          process.type = actionType;
          process.error = null;
        } else {
          state.create = fetchableFetched;
        }
      })
      .addCase(bookingOfferActionsNewVersion.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        if (id) {
          const actionType = EBookingActionType.SaveNewVersion;
          const process = getActionProcess(state, id, actionType);
          process.isFetching = false;
          process.isFetched = false;
          process.isFailed = true;

          process.id = id;
          process.type = actionType;
          process.error = payload?.error ?? null;
        } else {
          state.create = fetchableFetched;
        }
      })
      .addCase(bookingOfferActionsManageServices.pending, (state, { meta }) => {
        const { offerId } = meta.arg;

        const actionType = EBookingActionType.ManageServices;
        const process = getActionProcess(state, offerId, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = offerId;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsManageServices.fulfilled, (state, { meta }) => {
        const { offerId } = meta.arg;

        const actionType = EBookingActionType.ManageServices;
        const process = getActionProcess(state, offerId, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = offerId;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsManageServices.rejected, (state, { payload, meta }) => {
        const { offerId } = meta.arg;

        const actionType = EBookingActionType.ManageServices;
        const process = getActionProcess(state, offerId, actionType);
        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = offerId;
        process.type = actionType;
        process.error = payload?.error ?? null;
      })
      .addCase(bookingOfferActionsArchive.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Archive;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsArchive.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Archive;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsArchive.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Archive;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(bookingOfferActionsApprove.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Approve;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsApprove.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Approve;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsApprove.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Approve;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(bookingOfferActionsInWork.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.InWork;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsInWork.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.InWork;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsInWork.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.InWork;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(bookingOfferActionsUnPublish.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.UnPublish;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsUnPublish.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.UnPublish;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsUnPublish.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.UnPublish;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(bookingOfferActionsResume.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Resume;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsResume.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Resume;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsResume.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Resume;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(bookingOfferActionsPause.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Pause;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsPause.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Pause;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsPause.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Pause;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(bookingOfferActionsRetrieve.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Retrieve;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsRetrieve.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Retrieve;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsRetrieve.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Retrieve;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(bookingOfferActionsChangeModerator.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.ChangeModerator;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsChangeModerator.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.ChangeModerator;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsChangeModerator.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.ChangeModerator;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(bookingOfferActionsReturnToVerification.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.ReturnToVerification;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsReturnToVerification.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.ReturnToVerification;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsReturnToVerification.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.ReturnToVerification;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(bookingOfferActionsDelete.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Delete;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsDelete.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Delete;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsDelete.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Delete;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(bookingOfferActionsDuplicate.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Duplicate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsDuplicate.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Duplicate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsDuplicate.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Duplicate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(bookingOfferActionsReject.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Reject;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsReject.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Reject;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsReject.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Reject;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(bookingOfferActionsPublish.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Publish;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsPublish.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Publish;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(bookingOfferActionsPublish.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EBookingActionType.Publish;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      });
  },
});

export const { bookingOfferActionsChangeDialogState, bookingOfferActionsOptimize } = slice.actions;

export default slice.reducer;
