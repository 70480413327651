import { RootState } from '../../../../../../data/store/store';

export const bookingServiceCategoriesEditDataSelector = (store: RootState) =>
  store.dictionary.bookingServiceCategory.edit.fetch.data;

export const bookingServiceCategoriesEditIsPreloadingSelector = (store: RootState) =>
  store.dictionary.bookingServiceCategory.edit.fetch.isFetching;

export const bookingServiceCategoriesEditIsLoadingSelector = (store: RootState) =>
  store.dictionary.bookingServiceCategory.edit.fetch.isFetching ||
  store.dictionary.bookingServiceCategory.edit.addOrderByDateType.isFetching ||
  store.dictionary.bookingServiceCategory.edit.removeOrderByDateType.isFetching;

export const bookingServiceCategoriesEditDialogsStateSelector = (store: RootState) =>
  store.dictionary.bookingServiceCategory.edit.dialogs;
