import debounce from 'lodash/debounce';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { Nullable } from '../../../../domain/model/types';
import { MPFormInput, MPFormInputProps } from '../input';
import { Autocomplete, LoaderWrapper } from '../autocomplete/controls';
import ContentLoader from '../../../components/common/loader';
import { AutocompleteProps, TextFieldProps, Typography } from '@mui/material';
import { InputBaseProps } from '@mui/material/InputBase';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';
import styled from '@emotion/styled';

interface AsyncAutocompleteModifiers {
  inputChange: (value: string) => string;
}

interface AsyncAutocompleteProps<T>
  extends Omit<AutocompleteProps<T, false, false, false>, 'inputValue' | 'renderInput' | 'onChange' | 'options'> {
  readonly value: Nullable<T>;
  readonly label?: string;
  readonly required?: boolean;
  readonly error?: boolean;
  readonly helperText?: Nullable<string>;
  readonly inputProps?: InputBaseProps['inputProps'];
  readonly disabled?: boolean;
  readonly controlled?: boolean;
  readonly loadOptionsMethod: (search?: string) => Promise<T[]>;
  readonly onChange: (value: T) => void;
  readonly options?: T[];
  readonly noOutline?: boolean;
  readonly renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  readonly modifiers?: Partial<AsyncAutocompleteModifiers>;
}

type MPFormInputStyledProps = FC<TextFieldProps & MPFormInputProps & { readonly noOutline?: boolean }>;

const MPFormInputStyled = styled<MPFormInputStyledProps>(MPFormInput)`
  ${p =>
    p.noOutline
      ? `fieldset {
    border-color: transparent !important;
  }`
      : ''}
`;

export function AsyncAutocomplete<T extends object | null | undefined>(props: AsyncAutocompleteProps<T>) {
  const {
    onChange,
    label,
    loadOptionsMethod,
    error,
    helperText,
    required,
    inputProps,
    noOutline = false,
    modifiers,
    ...restProp
  } = props;

  const [options, setOptions] = useState<T[]>([]);
  const [search, setSearch] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(
    debounce(() => {
      if (!open || !search) {
        return undefined;
      }

      setIsLoading(true);
      loadOptionsMethod(search || '')
        .then(payload => {
          setOptions(payload);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 500),
    [search, open]
  );

  useEffect(() => {
    if (restProp.value === null) {
      setSearch('');
      setOptions([]);
      setOpen(false);
    }
  }, [restProp.value]);

  return (
    <Autocomplete
      clearOnEscape={false}
      freeSolo={true as any}
      clearOnBlur={false}
      openOnFocus={!search}
      inputValue={search}
      options={options}
      getOptionLabel={option => {
        if (option && 'name' in option) {
          return (option as any).name ?? '';
        }
      }}
      noOptionsText={<Typography variant={'body2'}>{isLoading ? 'Загрузка...' : 'Нет данных'}</Typography>}
      open={open}
      onOpen={e => {
        setOpen(true);
        restProp.onOpen?.(e);
      }}
      onClose={(e, reason) => {
        setOpen(false);
        restProp.onClose?.(e, reason);
      }}
      onChange={(event, newValue, reason) => {
        if (typeof newValue !== 'string' && newValue) {
          onChange(newValue);
          if (props.getOptionLabel) setSearch(props.getOptionLabel(newValue));
        } else {
          if (reason === 'createOption') {
            if (options.length > 0) {
              onChange(options[0]);
              if (props.getOptionLabel) setSearch(props.getOptionLabel(options[0]));
            }
          }
        }
      }}
      onInputChange={(event, newValue, reason) => {
        if (reason === 'input') {
          if (modifiers?.inputChange) {
            const modifiedValue = modifiers?.inputChange(newValue);
            setSearch(prev => modifiedValue || prev);
          } else setSearch(newValue);
        }

        if (reason === 'clear') {
          setSearch('');
        }
      }}
      renderInput={params => (
        <MPFormInputStyled
          {...params}
          noOutline={noOutline}
          label={label}
          error={error}
          helperText={helperText}
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: isLoading ? (
              <LoaderWrapper>
                <ContentLoader size={23} />
              </LoaderWrapper>
            ) : (
              <>{params.InputProps.endAdornment}</>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            ...inputProps,
            autoComplete: 'none',
            'aria-autocomplete': 'none',
          }}
        />
      )}
      {...restProp}
    />
  );
}
