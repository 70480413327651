import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { Address } from '../../../../../../domain/model/address';
import { Nullable, UUID } from '../../../../../../domain/model/types';

interface TournamentApplicationsFilterState {
  readonly locality: Nullable<Address>;
  readonly sort: string;
}

interface Reducers extends SliceCaseReducers<TournamentApplicationsFilterState> {
  setTournamentApplicationsFilter: CaseReducer<
    TournamentApplicationsFilterState,
    PayloadAction<{ filter: TournamentApplicationsFilterState; guid: UUID }>
  >;
}

const slice = createSlice<TournamentApplicationsFilterState, Reducers, 'filter'>({
  name: 'filter',
  initialState: {
    locality: null,
    sort: 'name,asc',
  },
  reducers: {
    setTournamentApplicationsFilter: (state, { payload }) => {
      const { locality, sort } = payload.filter;

      state.locality = locality;
      state.sort = sort;
    },
  },
});

export const { setTournamentApplicationsFilter } = slice.actions;

export default slice.reducer;
