import { UUID } from '../../../../../../domain/model/types';
import ConfirmDialog from '../../../../../components/common/dialogs/confirm';
import ProductUploadOffersContainer from '../../../uploadOffers/container';

interface ProductTableUploadOffersDialogProps {
  readonly partnerId: UUID;
  readonly onUpload: (count: number) => void;
  readonly onClose: () => void;
}

const ProductTableUploadOffersDialog = (props: ProductTableUploadOffersDialogProps) => {
  const { partnerId, onUpload, onClose } = props;

  return (
    <ConfirmDialog
      open
      onClose={onClose}
      title='Импорт товаров'
    >
      <ProductUploadOffersContainer
        partnerId={partnerId}
        onUpload={onUpload}
        onClose={onClose}
      />
    </ConfirmDialog>
  );
};

export default ProductTableUploadOffersDialog;
