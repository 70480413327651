import { EOfferStatus, ETradeOfferType } from '@/domain';
import { ContentTargetComponentsType } from '../general/contentTarget/types';
import { ETradeOfferStep } from './types';

export const tradeOfferWidgetParams = {
  width: '320px',
  height: '500px',
} as const;

export const getTradeOfferStepText = (step: ETradeOfferStep): string => {
  switch (step) {
    case ETradeOfferStep.General:
      return 'Основные данные';
    case ETradeOfferStep.Widget:
      return 'Виджет';
    case ETradeOfferStep.Description:
      return 'Описание';
    case ETradeOfferStep.Conditions:
      return 'Условия';
    case ETradeOfferStep.PromoCodes:
      return 'База кодов';
  }
};

export const isTradeOfferDraftSavable = (status: EOfferStatus): boolean => {
  return [EOfferStatus.Draft, EOfferStatus.Rejected].includes(status);
};

export const isTradeOfferOnModerationSavable = (status: EOfferStatus): boolean => {
  return [EOfferStatus.OnModeration].includes(status);
};

export const isAspPromotionType = (type: ETradeOfferType): boolean => {
  return [ETradeOfferType.Asp, ETradeOfferType.DiGift].includes(type);
};

export const tradeOfferTargetAttributes: ContentTargetComponentsType = {
  localities: true,
  clientOrgs: true,
  externalUsers: true,
};

export const getTradeOfferTypeHint = (offerType: ETradeOfferType): string[] => {
  switch (offerType) {
    case ETradeOfferType.ReferralLink:
      return [
        `Для спецпредложений без промокода`,
        `Любая ссылка для перехода к получению предложения на сайте партнера`,
      ];
    case ETradeOfferType.PromoCode:
      return [
        `Пользователю выдается новый промокод после нового запроса`,
        `Один пользователь может получить несколько таких промокодов`,
      ];
    case ETradeOfferType.PublicPromoCode:
      return [`Промокод выглядит одинаково для всех пользователей`];
    case ETradeOfferType.Voucher:
      return [
        `Документ с уникальным номером, распространяющийся на один или несколько заказов (необходимо указать в условиях получения)`,
        `Один пользователь может получить несколько ваучеров за период акции`,
        `Покупателю необходимо предъявить ваучер в распечатанном или электронном виде при покупке товара или услуги`,
      ];
    case ETradeOfferType.Widget:
      return [
        `HTML или Java-script код, который партнер размещает на Витрине в карточке торгового предложения в виде формы заявки или формы обратной связи для генерации лида (заявки на услугу).`,
        ``,
        `Пользователи вводят свои данные, виджет передает информацию в систему партнер`,
      ];
    case ETradeOfferType.AccessCode:
      return [`Пользователю выдается промокод только 1 раз в период спецпредложения`];
    case ETradeOfferType.Asp:
      return [`Пользователь с балансом АСП, покупая сертификат, получает код из предзагруженной базы`];
    case ETradeOfferType.DiGift:
      return [`Пользователь с балансом АСП, покупая сертификат, получает код через интеграцию c Digift`];
  }
};
