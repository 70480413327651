import { Fade, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SportOption } from '../../../../domain/model';
import { EPartnerStatus } from '../../../../domain/model/enums';
import { PartnerShort } from '../../../../domain/model/partner';
import { UUID } from '../../../../domain/model/types';
import Notifier from '../../../../system/notifier';
import MasterActionsComponent from '../../../components/common/actions/master';
import useNavAdapter from '../../../components/common/actions/navAdapter/hooks';
import DefaultHeader from '../../../components/common/header';
import ContentLoader from '../../../components/common/loader';
import { StepperCommonHeader } from '../../../components/common/stepper/common/header';
import { DefaultContentWrapper } from '../../../components/common/wrappers/content';
import { DefaultFooterWrapper } from '../../../components/common/wrappers/footer';
import useHistoryExtensions from '../../../hooks/useHistoryExtensions';
import useValidation from '../../../hooks/validation/useValidation';
import { MPButton } from '../../../theme/ui-kit/button';
import { EPanelActionPosition, OnChangeObjectAttribute, PanelAction, PanelActions } from '../../../types';
import { nsiDataSelector } from '../../general/nsi/store/selectors';
import PartnerBySelfRegistrationSuccessDialog from '../../general/partner/components/bySelfRegistrationSuccessDialog';
import PartnerRegistrationSuccessDialog from '../../general/partner/components/registrationSuccessDialog';
import usePartnerStepper from '../../general/partner/hooks/useStepper';
import { EPartnerStep, EPartnerTableTab, PartnerStep } from '../../general/partner/types';
import { getPartnerStatusText } from '../../general/partner/utils/common';
import { getPartnerEditPermissionsStepValidation, PartnerDataStepPermissions } from '../../general/partner/validation';
import StepperContainer from '../../general/stepper/container';
import { getPartnersTableRoute } from '../../partner/entry';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import RejectReasonDialog from '../components/reasonsDialog';
import {
  EPartnerApplicationActionType,
  PartnerApplicationVerificationActionType,
  PartnerPermissionsAttributes,
} from '../types';
import { getPartnerApplicationActionName } from '../utils';
import PartnerApplicationVerification from './component';
import { ContainerWrapper, ContentContainer, LoaderWrapper, TitleWrapper } from './controls';
import {
  PartnerApplicationVerificationApproveSelector,
  PartnerApplicationVerificationAssignVerifierSelector,
  PartnerApplicationVerificationDataSelector,
  PartnerApplicationVerificationFetchSelector,
  PartnerApplicationVerificationRejectSelector,
} from './store/selectors';
import {
  partnerApplicationVerificationApprove,
  partnerApplicationVerificationAssignVerifier,
  partnerApplicationVerificationFetch,
  partnerApplicationVerificationReject,
  partnerApplicationVerificationSetPermissionsAttribute,
  partnerApplicationVerificationStateReset,
} from './store/slice';
import { ETagColors, MPTag } from '@/presentation/theme/ui-kit/tag';

interface PartnerApplicationVerificationContainerProps {
  readonly partnerId: UUID;
  readonly step: EPartnerStep;
  readonly canChangePermissions?: boolean;
}

const PartnerApplicationVerificationContainer = (props: PartnerApplicationVerificationContainerProps) => {
  const { partnerId, step, canChangePermissions } = props;

  const dispatch = useDispatch();
  const { userSpecific } = useCurrentUser();
  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const [rejectOpen, setRejectOpen] = useState<boolean>(false);

  const {
    partnerOwnerShipTypes: ownerShipTypes,
    taxationSystemTypes,
    partnerRejectReasons,
    partnerTypes,
  } = useSelector(nsiDataSelector);
  const partnerApplication = useSelector(PartnerApplicationVerificationDataSelector);
  const { isFetching } = useSelector(PartnerApplicationVerificationFetchSelector);
  const { isFetching: isVerifierAssigning } = useSelector(PartnerApplicationVerificationAssignVerifierSelector);
  const { data: approvedPartner, isFetching: isApproving } = useSelector(PartnerApplicationVerificationApproveSelector);
  const { isFetching: isRejecting, isFetched: isRejected } = useSelector(PartnerApplicationVerificationRejectSelector);

  const [validateOnChangePermissions, setValidateOnChangePermissions] = useState<boolean>(false);

  const {
    validationResult: validationResultPermissions,
    isValid: isValidPermissions,
    validate: validatePermissions,
  } = useValidation<PartnerDataStepPermissions>({
    object: partnerApplication,
    validateOnChange: validateOnChangePermissions,
    rules: getPartnerEditPermissionsStepValidation({ canChangePermissions }),
  });

  const isVerifier = userSpecific.id === partnerApplication?.partnerReviewer?.id;
  const bySelfRegistration = partnerApplication?.owner && partnerApplication.owner.id === partnerApplication?.createdBy;

  const orgType = partnerApplication?.regInfo?.orgType;
  const { currentStep, steps, isLastStep, openStep, openNextStep, currentStepIndex, openPrevStep } = usePartnerStepper({
    currentStepKey: step,
    orgType,
    canChangePermissions,
  });

  const actions: PanelActions<PartnerApplicationVerificationActionType> =
    partnerApplication?.status && isLastStep
      ? [
          {
            type: EPartnerApplicationActionType.Approve,
            label: getPartnerApplicationActionName(EPartnerApplicationActionType.Approve),
            primary: true,
            disabled: !isVerifier || partnerApplication.status !== EPartnerStatus.OnVerification,
            position: [EPanelActionPosition.Default],
          },
          {
            type: EPartnerApplicationActionType.Reject,
            label: getPartnerApplicationActionName(EPartnerApplicationActionType.Reject),
            disabled: !isVerifier || partnerApplication.status !== EPartnerStatus.OnVerification,
            position: [EPanelActionPosition.Default],
          },
        ]
      : [];

  const onClose = useCallback(() => {
    gotoPrevIndependentLocation(getPartnersTableRoute({ tab: EPartnerTableTab.Verification }));
  }, [gotoPrevIndependentLocation]);

  const onChangeStep = (newStep: PartnerStep) => {
    if (newStep.key !== step) {
      openStep(newStep);
    }
  };

  const onApprove = () => {
    const isValidObject = validatePermissions();
    if (!isValidObject) {
      setValidateOnChangePermissions(true);
      return;
    }
    dispatch(
      partnerApplicationVerificationApprove({
        partnerId,
        data: partnerApplication!,
        permissions: partnerApplication!.permissions,
        type: partnerApplication!.type!,
      })
    );
  };

  const onAssignVerifier = () => {
    dispatch(partnerApplicationVerificationAssignVerifier({ partnerId, userId: userSpecific.id }));
  };

  const onReject = (reason: SportOption, comment?: string) => {
    dispatch(partnerApplicationVerificationReject({ partnerId, reason, comment }));
  };

  const onPanelAction = (action: PanelAction<PartnerApplicationVerificationActionType>) => {
    const { type } = action;
    switch (type) {
      case EPartnerApplicationActionType.Approve:
        onApprove();
        break;
      case EPartnerApplicationActionType.Reject:
        setRejectOpen(true);
        break;
    }
  };

  const onChangeAttribute: OnChangeObjectAttribute<PartnerPermissionsAttributes> = (attribute, value) => {
    dispatch(partnerApplicationVerificationSetPermissionsAttribute({ attribute, value }));
  };

  useEffect(() => {
    if (isRejected) {
      onClose();
    }
  }, [isRejected, onClose]);

  useEffect(() => {
    if (approvedPartner?.status && approvedPartner?.status !== EPartnerStatus.Enabled) {
      Notifier.getInstance().addErrorNotice('Не удалось активировать партнёра');
    }
  }, [approvedPartner?.status]);

  useEffect(() => {
    const promise = dispatch(partnerApplicationVerificationFetch({ partnerId }));
    return () => {
      promise?.abort();
      dispatch(partnerApplicationVerificationStateReset());
    };
  }, [dispatch]);

  const { adapter: navAdapter, actions: navActions } = useNavAdapter({
    openPrevStep,
    currentStepIndex,
    openNextStep,
    stepsCount: steps.length,
  });

  const actionsPanel = (
    <MasterActionsComponent<PartnerApplicationVerificationActionType>
      show={!!actions.length || !!navActions.length}
      actions={actions}
      navAdapter={navAdapter}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );

  const isLoading = isFetching || isApproving || isRejecting;

  const StepperHeader = ({ partner }: { partner: PartnerShort }) => (
    <StepperCommonHeader bottomSplitter>
      <Grid item>
        {partner.code} — {partner.name}
      </Grid>
    </StepperCommonHeader>
  );

  return (
    <Fade in>
      <ContainerWrapper>
        {partnerApplication && (
          <>
            <StepperContainer<EPartnerStep>
              flow={false}
              step={currentStep}
              steps={steps}
              onClick={onChangeStep}
              header={<StepperHeader partner={partnerApplication} />}
            />
            <ContentContainer>
              <DefaultContentWrapper
                type='details'
                stickyHeader
                fullHeight
                footer={actionsPanel}
              >
                <DefaultHeader
                  sticky
                  actions={
                    partnerApplication.status === EPartnerStatus.ToVerification ? (
                      <MPButton
                        variant='outlined'
                        size='small'
                        disabled={isFetching || isVerifierAssigning}
                        onClick={onAssignVerifier}
                      >
                        <Typography noWrap>Взять в работу</Typography>
                        {(isFetching || isVerifierAssigning) && <ContentLoader />}
                      </MPButton>
                    ) : (
                      <MPTag
                        noWrap
                        color={ETagColors.Success}
                        label={
                          isVerifier && partnerApplication.status === EPartnerStatus.OnVerification
                            ? 'В работе'
                            : getPartnerStatusText(partnerApplication.status)
                        }
                      />
                    )
                  }
                  onClose={onClose}
                >
                  <TitleWrapper>
                    <Typography variant='h2'>Заявка на партнёрство</Typography>
                  </TitleWrapper>
                </DefaultHeader>
                {rejectOpen && (
                  <RejectReasonDialog
                    title='Отклонение заявки'
                    actionText='Отклонить'
                    isFetching={isRejecting}
                    reasons={partnerRejectReasons}
                    onAction={onReject}
                    onClose={() => setRejectOpen(false)}
                  />
                )}
                <PartnerApplicationVerification
                  partnerApplication={partnerApplication}
                  partnerTypes={partnerTypes}
                  step={step}
                  isValidPermissions={isValidPermissions}
                  isVerifier={isVerifier}
                  ownerShipTypes={ownerShipTypes}
                  taxationSystemTypes={taxationSystemTypes}
                  validationPermissions={validationResultPermissions}
                  onChangeAttribute={onChangeAttribute}
                />
              </DefaultContentWrapper>
            </ContentContainer>
          </>
        )}
        {!bySelfRegistration && (
          <PartnerRegistrationSuccessDialog
            partner={approvedPartner}
            onAction={onClose}
          />
        )}
        {bySelfRegistration && (
          <PartnerBySelfRegistrationSuccessDialog
            partner={approvedPartner}
            onAction={onClose}
          />
        )}
        {isLoading && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
      </ContainerWrapper>
    </Fade>
  );
};

export default PartnerApplicationVerificationContainer;
