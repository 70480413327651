import { AnyOffer } from '../../../../../../domain/model';
import {
  ECmsCollectionLinkObjectType,
  ECmsContainerType,
  ECmsLinkObjectType,
} from '../../../../../../domain/model/enums';
import { PartnersCollection } from '../../../../../../domain/model/partner';
import { Nullable } from '../../../../../../domain/model/types';
import { EEntityPreviewMode } from '../../../../../types';
import { CmsContainerViewResourceSize } from '../../../container/types';
import { getEmptyCmsLinkedObjectsByType } from '../../../container/utils';
import { cmsBannerSizes1, cmsBannerSizes2, cmsBannerSizes3 } from '../../../container/utils/constants';
import { CmsLinkedObject } from '../../../types';
import { getCmsPreviewParams } from '../../../utils';
import CmsBannersPreview from '../banners';
import CmsCarouselPreview from '../carousel';
import CmsCategoriesPreview from '../categories';
import CmsCompilationPreview from '../compilation';
import CmsPartnersPreview from '../partners';
import CmsResizableBannersPreview from '../resizableBanners';
import { CarouselWrapper } from './controls';

type CmsPreviewByContainerTypeProps = {
  readonly mode?: EEntityPreviewMode;
  readonly type: ECmsContainerType;
  readonly canActivateItem?: boolean;
  readonly cmsLinkedObjects: CmsLinkedObject[];
  readonly activePreviewNumber?: Nullable<number | false>;
};

const CmsPreviewByContainerType = (props: CmsPreviewByContainerTypeProps) => {
  const { mode = EEntityPreviewMode.Desktop, type, canActivateItem, cmsLinkedObjects, activePreviewNumber } = props;

  const activePreviewIndex = typeof activePreviewNumber === 'number' ? activePreviewNumber : 0;
  let params;

  switch (type) {
    case ECmsContainerType.Banner1:
    case ECmsContainerType.Banner2:
    case ECmsContainerType.Banner4:
    case ECmsContainerType.Banner2MobileWide:
      const banners = cmsLinkedObjects
        .map(cmsLinkedObject => (cmsLinkedObject.type === ECmsLinkObjectType.Banner ? cmsLinkedObject.banner : null))
        .filter(obj => !!obj);
      params = getCmsPreviewParams(type, mode, banners?.[activePreviewIndex] ?? null);
      return (
        <CmsBannersPreview
          mode={mode}
          banners={banners}
          activePreviewNumber={activePreviewNumber}
          canActivateItem={canActivateItem}
          ratio={params.ratio}
          rowElementsCount={params.rowElementsCount}
        />
      );
    case ECmsContainerType.Banner1Resizable:
    case ECmsContainerType.Banner3Resizable:
    case ECmsContainerType.Banner1LinkResizable:
      const resizableBanners = cmsLinkedObjects
        .map(cmsLinkedObject => (cmsLinkedObject.type === ECmsLinkObjectType.Banner ? cmsLinkedObject.banner : null))
        .filter(obj => !!obj);
      params = getCmsPreviewParams(type, mode, resizableBanners?.[activePreviewIndex] ?? null);
      let sizes: CmsContainerViewResourceSize[] = [];
      switch (type) {
        case ECmsContainerType.Banner1Resizable:
          sizes = cmsBannerSizes2;
          break;
        case ECmsContainerType.Banner1LinkResizable:
          sizes = cmsBannerSizes3;
          break;
        default:
          sizes = cmsBannerSizes1;
          break;
      }
      return (
        <CmsResizableBannersPreview
          mode={mode}
          banners={resizableBanners}
          sizes={sizes}
          activePreviewNumber={activePreviewNumber}
          canActivateItem={canActivateItem}
          ratio={params.ratio}
          rowElementsCount={params.rowElementsCount}
        />
      );
    case ECmsContainerType.Banner3:
      const threeBanners = cmsLinkedObjects
        .map(cmsLinkedObject => (cmsLinkedObject.type === ECmsLinkObjectType.Banner ? cmsLinkedObject.banner : null))
        .filter(obj => !!obj);
      params = getCmsPreviewParams(type, mode, threeBanners?.[activePreviewIndex] ?? null);
      return (
        <CmsBannersPreview
          mode={mode}
          banners={threeBanners}
          activePreviewNumber={activePreviewNumber}
          canActivateItem={canActivateItem}
          ratio={params.ratio}
          rowElementsCount={params.rowElementsCount}
        />
      );

    case ECmsContainerType.Banner4High:
      const highBanners = cmsLinkedObjects
        .map(cmsLinkedObject => (cmsLinkedObject.type === ECmsLinkObjectType.Banner ? cmsLinkedObject.banner : null))
        .filter(obj => !!obj);
      params = getCmsPreviewParams(type, mode, highBanners?.[activePreviewIndex] ?? null);
      return (
        <CmsBannersPreview
          mode={mode}
          banners={highBanners}
          activePreviewNumber={activePreviewNumber}
          canActivateItem={canActivateItem}
          ratio={params.ratio}
          rowElementsCount={params.rowElementsCount}
        />
      );

    case ECmsContainerType.CompilationOffer:
      const getOffers = (cmsLinkedObject: CmsLinkedObject): Nullable<AnyOffer[]> => {
        if (
          cmsLinkedObject.type === ECmsLinkObjectType.Collection &&
          (cmsLinkedObject.collection.linkObjectType === ECmsCollectionLinkObjectType.CorpOffer ||
            cmsLinkedObject.collection.linkObjectType === ECmsCollectionLinkObjectType.TradeOffer)
        ) {
          return cmsLinkedObject.collection.linkedObject;
        }
        return null;
      };

      const offers = cmsLinkedObjects.flatMap(cmsLinkedObject => getOffers(cmsLinkedObject)).filter(obj => !!obj);
      if (!offers.length) {
        const emptyCmsLinkedObjects = getEmptyCmsLinkedObjectsByType(ECmsContainerType.CompilationOffer);
        offers.push(...emptyCmsLinkedObjects.flatMap(cmsLinkedObject => getOffers(cmsLinkedObject)));
      }
      return (
        <CmsCompilationPreview
          mode={mode}
          offers={offers}
        />
      );

    case ECmsContainerType.CompilationPartner:
      const getPartners = (cmsLinkedObject: CmsLinkedObject): PartnersCollection => {
        if (
          cmsLinkedObject.type === ECmsLinkObjectType.Collection &&
          cmsLinkedObject.collection.linkObjectType === ECmsCollectionLinkObjectType.Partner
        ) {
          return cmsLinkedObject.collection.linkedObject ?? [];
        }
        return [];
      };

      const partners = cmsLinkedObjects.flatMap(cmsLinkedObject => getPartners(cmsLinkedObject)).filter(obj => !!obj);

      return (
        <CmsPartnersPreview
          mode={mode}
          partners={partners}
          isEmptyTemplated={!canActivateItem}
        />
      );

    case ECmsContainerType.Banner3Carousel:
      const carouselBanners = cmsLinkedObjects
        .map(cmsLinkedObject => (cmsLinkedObject.type === ECmsLinkObjectType.Banner ? cmsLinkedObject.banner : null))
        .filter(obj => !!obj);
      return (
        <CarouselWrapper>
          <CmsCarouselPreview
            mode={mode}
            banners={carouselBanners}
            activePreviewNumber={activePreviewNumber}
            canActivateItem={canActivateItem}
          />
        </CarouselWrapper>
      );

    case ECmsContainerType.Category4Offer:
      const categoryBanners = cmsLinkedObjects
        .map(cmsLinkedObject => (cmsLinkedObject.type === ECmsLinkObjectType.Banner ? cmsLinkedObject.banner : null))
        .filter(obj => !!obj);
      params = getCmsPreviewParams(type, mode, categoryBanners?.[activePreviewIndex] ?? null);
      return (
        <CmsCategoriesPreview
          mode={mode}
          banners={categoryBanners}
          activePreviewNumber={activePreviewNumber}
          canActivateItem={canActivateItem}
          ratio={params.ratio}
          rowElementsCount={params.rowElementsCount}
        />
      );
  }

  console.warn(`Unknown container type ${type}`);
  return null;
};

export default CmsPreviewByContainerType;
