import { EOfferType } from '@/domain';
import AdCampaignCreateFieldOfferType from '@features/adCampaign/create/fields/offerType';
import { AdCampaignCreateFieldCommonProps } from '@features/adCampaign/types';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import { useSelector } from 'react-redux';

const fieldName = 'offerType';

export const AdCampaignCreateFieldOfferTypeAdapter = (props: AdCampaignCreateFieldCommonProps<typeof fieldName>) => {
  const { offerTypes } = useSelector(nsiDataSelector);

  const offerTypesForAdCampaign = offerTypes.filter(offerType => offerType.id !== EOfferType.Product);

  return (
    <AdCampaignCreateFieldOfferType
      {...props}
      offerTypes={offerTypesForAdCampaign}
    />
  );
};
