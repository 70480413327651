import { MPTypographyType } from './types';

export const appTypography: MPTypographyType = {
  htmlFontSize: 16,
  fontSize: 13,
  fontFamily: 'Open Sans, sans-serif',
  h2: {
    fontSize: '1.625rem',
    lineHeight: '2.25rem',
    fontWeight: 600,
  },
  h3: {
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    fontWeight: 600,
  },
  h4: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 600,
  },
  body1: {
    fontSize: '.8125rem',
    lineHeight: '1.375rem',
  },
  body2: {
    fontSize: '.6875rem',
    lineHeight: '1rem',
  },
  subtitle1: {
    fontSize: '1.625rem',
    lineHeight: '2.25rem',
    fontWeight: 600,
  },
  subtitle2: {
    fontSize: '0.9375rem',
    lineHeight: '1.25rem',
    fontWeight: 600,
  },
  fontWeightSemiBold: 600,
};
