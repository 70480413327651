import { RootState } from '../../../../../data/store/store';
import { BookingOfferView } from '../../types';

export const bookingOfferServiceManageBookingOfferSelector = (store: RootState) =>
  store.bookingOffer.serviceManage.byId.bookingOffer;
export const bookingOfferServiceManageBookingOfferLoadedDataSelector = (store: RootState) =>
  store.bookingOffer.serviceManage.byId.loadedData;

export const bookingOfferServiceManageBookingOfferFieldByNameSelector =
  <Field extends keyof BookingOfferView>(field: Field) =>
  (store: RootState) =>
    store.bookingOffer.serviceManage.byId.bookingOffer?.[field];

export const bookingOfferServiceManageServicePriceUnitsSelector = (store: RootState) =>
  store.bookingOffer.serviceManage.servicePriceUnits;

export const bookingOfferServiceManageIsPreloadingSelector = (store: RootState) =>
  store.bookingOffer.serviceManage.byId.isFetching;

export const bookingOfferServiceManageIsFieldVisibleSelector = (field: keyof BookingOfferView) => (store: RootState) =>
  true;

export const bookingOfferServiceManageIsFieldDisabledSelector = (field: keyof BookingOfferView) => (store: RootState) =>
  false;

export const bookingOfferServiceManageValidationServicesResultByIndexSelector = (index: number) => (store: RootState) =>
  store.bookingOffer.serviceManage.validationServices[index] ?? null;

export const bookingOfferServiceManageServiceByIndexSelector = (index: number) => (store: RootState) =>
  store.bookingOffer.serviceManage.byId.bookingOffer.services?.[index] ?? null;

export const bookingOfferServiceManageServicePriceItemByIndexSelector =
  (serviceIndex: number, index: number) => (store: RootState) =>
    store.bookingOffer.serviceManage.byId.bookingOffer.services?.[serviceIndex]?.priceItems?.[index] ?? null;

export const bookingOfferServiceManageValidationServicePriceItemsByIndexSelector =
  (serviceIndex: number, index: number) => (store: RootState) =>
    store.bookingOffer.serviceManage.validationPriceItems?.[serviceIndex]?.[index] ?? null;

// Селекторы валидаций списков
export const bookingOfferServiceManageValidationServicesResultsSelector = (store: RootState) =>
  store.bookingOffer.serviceManage.validationServices;
