import { Grid, Typography, TypographyProps } from '@mui/material';
import { useState } from 'react';
import { AnyAddress } from '../../../../../domain/model/address';
import { EAddressOption } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { MPButton } from '../../../../theme/ui-kit/button';
import { ETagColors, MPTag } from '../../../../theme/ui-kit/tag';
import { AddressHelper } from '../../../../utils/address';
import { AddressDetails } from '../../details';
import { Wrapper } from './controls';

interface AddressFullViewProps extends TypographyProps {
  readonly address?: Nullable<AnyAddress>;
  readonly options?: EAddressOption[];
  readonly checkManual?: boolean;
}

const AddressFullView = ({ address, options, checkManual, ...others }: AddressFullViewProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (checkManual) {
    return (
      <Wrapper>
        <Typography {...others}>{address ? new AddressHelper(address).getFullPath({ options }) : '-'}</Typography>
        {address?.manual && (
          <Grid
            container
            spacing={2}
            alignItems={'center'}
          >
            <Grid item>
              <MPTag
                label='Адрес введен вручную'
                variant='filled'
                color={ETagColors.Warning}
              />
            </Grid>
            <Grid item>
              <MPButton
                size='small'
                onClick={handleOpen}
              >
                Проверить
              </MPButton>
            </Grid>
          </Grid>
        )}
        {address && open && (
          <AddressDetails
            address={address}
            onClose={handleClose}
          />
        )}
      </Wrapper>
    );
  } else {
    return <Typography {...others}>{address ? new AddressHelper(address).getFullPath({ options }) : '-'}</Typography>;
  }
};

export default AddressFullView;
