import { Typography } from '@mui/material';
import styled from '@emotion/styled';

interface ImageStubProps {
  readonly isActive: boolean;
}

interface UnknownSizesStubWrapperProps {
  readonly itemsHeights: number[];
  readonly children?: any;
}

export const TitleWrapper = styled.div`
  height: 1.125rem;

  margin-bottom: ${p => p.theme.spacing(0.5)};
`;

export const Title = styled(Typography)`
  font-weight: ${p => p.theme.typography.fontWeightBold};
  line-height: 1.125rem;
`;

export const ImageStub = styled(({ isActive, ...others }: ImageStubProps) => <div {...others} />)`
  height: 100%;

  border-color: ${p => (p.isActive ? p.theme.palette.text.primary : p.theme.palette.text.secondary)};
  border-radius: ${p => (p.theme.shape.borderRadius as number) / 2}px;

  border-width: 0.5px;

  ${p =>
    !p.isActive &&
    `
    background-color: ${p.theme.palette.background.default} !important;
    border-style: dashed !important;
  `}
`;

export const UnknownSizesStubWrapper = styled(({ itemsHeights, ...others }: UnknownSizesStubWrapperProps) => (
  <div {...others} />
))`
  height: 100%;

  display: grid;
  grid-template-rows: ${p => p.itemsHeights.map(item => `${item}%`).join(' ')};

  & :nth-of-type(n + 2) {
    border-top: 0;
  }

  & :nth-last-of-type(1) {
    background-color: ${p => p.theme.palette.border};
    border-style: solid;
  }

  & :nth-last-of-type(2) {
    background-color: ${p => p.theme.palette.disabled.background};
    border-style: dashed;
  }

  & :nth-last-of-type(3) {
    background-color: ${p => p.theme.palette.disabled.text};
    border-style: dashed;
  }

  & :nth-last-of-type(n + 4) {
    background-color: ${p => p.theme.palette.text.secondary};
    border-style: dashed;
  }
`;
