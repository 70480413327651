import { EBannerPartition, Nullable } from '@/domain';
import { BannersLocationState } from '@features/banner/entry';
import BannerTableEntry from '@features/banner/table/entry';
import { EBannerTableTab, EBannerTableUrlParam } from '@features/banner/table/utils';
import { viewShowMainMenu } from '@features/main/container/store/slice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import ForbiddenComponent from '../../components/common/forbidden';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface BannersScreenProps {
  readonly partition: EBannerPartition;
}

const BannersScreen = ({ partition }: BannersScreenProps) => {
  const dispatch = useDispatch();
  const location = useLocation<Nullable<BannersLocationState>>();
  const { banners } = useCurrentUser().accessMatrix;

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(EBannerTableUrlParam.Tab) as EBannerTableTab;

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (!banners?.[partition].view) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<BannersLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <BannerTableEntry
          partition={partition}
          guid={state.guid}
          tab={tab}
        />
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default BannersScreen;
