import { UserAccessPartitionClientOrgs } from '@/domain';
import { CommonPreloaderDecorator } from '@features/general/decorators/preloader';
import { CommonUpdaterDecorator } from '@features/general/decorators/updater';
import MasterDetailsContainer from '@features/general/master/details/container';
import MasterContentGridLayout from '@layouts/masterContentGrid/container';
import { MasterDetailsLayout } from '@layouts/masterDetails/container';
import { useClientOrgActionsSelectors } from '../actions/useActions';
import { ClientOrgLifeCycle } from '../lifecycle/types';
import { ClientOrgCreateStepType } from '../types';
import {
  ClientOrgCreateDialogsAdapter,
  ClientOrgCreateFooterAdapter,
  ClientOrgCreateHeaderAdapter,
  ClientOrgCreateSidePanelsAdapter,
  ClientOrgCreateStepGeneralAdapter,
  ClientOrgCreateStepperAdapter,
  ClientOrgCreateUiStateAdapter,
} from './adapters';
import { ClientOrgCreateInitializer } from './initializer';
import {
  ClientOrgCreateHandlersProvider,
  ClientOrgCreateLifecycleProvider,
  ClientOrgCreateParamsProvider,
} from './providers';
import { clientOrgCreateIsPreloadingSelector } from './store/selectors';

export interface ClientOrgCreateContainerProps {
  readonly id: Nullable<UUID>;
  readonly lifecycle: ClientOrgLifeCycle;
  readonly access: UserAccessPartitionClientOrgs;
  readonly step: ClientOrgCreateStepType;
}

const ClientOrgCreateContainer = (props: ClientOrgCreateContainerProps) => {
  const { id, step, lifecycle, access } = props;

  const { getIsClientOrgProcessingSelector } = useClientOrgActionsSelectors();

  return (
    <MasterDetailsContainer
      layout={MasterDetailsLayout}
      layoutProps={{
        stepper: ClientOrgCreateStepperAdapter,
        header: ClientOrgCreateHeaderAdapter,
        footer: ClientOrgCreateFooterAdapter,
        decorators: [
          <CommonPreloaderDecorator
            key='preloader'
            isFetchingSelector={clientOrgCreateIsPreloadingSelector}
          />,
          <CommonUpdaterDecorator
            key='actions'
            isFetchingSelector={getIsClientOrgProcessingSelector(id)}
          />,
        ],
      }}
      initializer={ClientOrgCreateInitializer}
      providers={[
        {
          name: 'params',
          factory: ClientOrgCreateParamsProvider,
        },
        {
          name: 'handlers',
          factory: ClientOrgCreateHandlersProvider,
        },
        {
          name: 'lifecycle',
          factory: ClientOrgCreateLifecycleProvider,
        },
      ]}
      commonProps={{
        id,
        step,
        lifecycle,
        access,
      }}
    >
      <ClientOrgCreateUiStateAdapter />
      <ClientOrgCreateDialogsAdapter />

      <MasterContentGridLayout sideContent={<ClientOrgCreateSidePanelsAdapter />}>
        <ClientOrgCreateStepGeneralAdapter />
      </MasterContentGridLayout>
    </MasterDetailsContainer>
  );
};

export default ClientOrgCreateContainer;
