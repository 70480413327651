import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import { TradeActivationTableCellPromotionTypeProps } from '../../components/tableCell/promotionType';
import { tradeActivationsTradeActivationByIndexSelector } from '../store/selectors';
import { TradeActivationTableCellAdapterProps } from './cell';

type TradeActivationTableCellPromotionTypeAdapterProps = Omit<TradeActivationTableCellAdapterProps, 'component'> & {
  readonly component: FC<TradeActivationTableCellPromotionTypeProps>;
};

const TradeActivationTableCellPromotionTypeAdapter = (props: TradeActivationTableCellPromotionTypeAdapterProps) => {
  const { index, component: Component } = props;

  const tradeActivation = useSelector(tradeActivationsTradeActivationByIndexSelector(index));

  const { promotionTypes } = useSelector(nsiDataSelector);

  return useMemo(
    () =>
      tradeActivation ? (
        <Component
          tradeActivation={tradeActivation}
          tradePromotionTypes={promotionTypes}
        />
      ) : null,
    [Component, tradeActivation, promotionTypes]
  );
};

export default TradeActivationTableCellPromotionTypeAdapter;
