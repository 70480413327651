import { ChangeEmailContainer } from '@features/auth/changeEmail/container';
import { userCurrentActionsDialogsByNameSelector } from '@features/user/current/actions/store/selectors';
import { useUserCurrentActions } from '@features/user/current/actions/useActions';
import { useSelector } from 'react-redux';

const actionName = 'changeEmail';

export const UserCurrentChangeEmailDialogAdapter = () => {
  const { onChangeDialogState, onEmailChanged } = useUserCurrentActions();
  const isOpen = useSelector(userCurrentActionsDialogsByNameSelector(actionName));

  const onClose = () => {
    onChangeDialogState(actionName, false);
  };

  return (
    isOpen && (
      <ChangeEmailContainer
        onSuccess={onEmailChanged}
        onClose={onClose}
      />
    )
  );
};
