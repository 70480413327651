import React from 'react';
import MPStepper, { MPStepperProps } from '../../../components/common/stepper';
import { Wrapper } from './controls';

interface StepperContainerOwnProps {
  readonly header?: React.ReactNode;
  readonly footer?: React.ReactNode;
}

type StepperContainerEmptyProps = StepperContainerOwnProps & {
  readonly step?: undefined;
  readonly steps?: undefined;
  readonly flow?: undefined;
  readonly forwardTransition?: undefined;
  readonly backwardTransition?: undefined;
  readonly labelStrategy?: undefined;
  readonly onClick?: undefined;
};

type StepperContainerTypedProps<T extends string | number> = MPStepperProps<T> & StepperContainerOwnProps;

type StepperContainerProps<T extends string | number> = StepperContainerEmptyProps | StepperContainerTypedProps<T>;

const StepperContainer = <T extends string | number>({
  header,
  step,
  steps,
  flow,
  footer,
  forwardTransition,
  backwardTransition,
  labelStrategy,
  onClick,
  ...others
}: StepperContainerProps<T>) => {
  return (
    <Wrapper>
      {header}
      {!!steps && step && (
        <MPStepper
          step={step}
          steps={steps}
          flow={flow}
          forwardTransition={forwardTransition}
          backwardTransition={backwardTransition}
          labelStrategy={labelStrategy}
          onClick={onClick}
          {...others}
        />
      )}
      <div>{footer}</div>
    </Wrapper>
  );
};

export default StepperContainer;
