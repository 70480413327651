import useTechConfig from '@/presentation/hooks/useTechConfig';
import { EAppFeature } from '@/presentation/types';
import { ContentTargetViewClientOrgsCard } from '@features/general/contentTarget/components/viewClientOrgs';
import ContentTargetViewExternalUsers from '@features/general/contentTarget/components/viewExternalUsers';
import ContentTargetViewHasRzdSocialPackage from '@features/general/contentTarget/components/viewHasRzdSocialPackage';
import { Grid } from '@mui/material';
import ContentTargetViewFamilyMemberOnly from '../components/viewFamilyMemberOnly';
import ContentTargetViewGender from '../components/viewGender';
import ContentTargetViewHavingChildFamilyMemberOnly from '../components/viewHavingChildFamilyMemberOnly';
import { ContentTargetViewLocalitiesCard } from '../components/viewLocalities';
import ContentTargetViewOrgUnits from '../components/viewOrgUnits';
import ContentTargetViewRoads from '../components/viewRoads';
import ContentTargetViewTradeUnionMembersOnly from '../components/viewTradeUnionMembersOnly';
import { ContentTargetComponentsType, ContentTargetViewProps } from '../types';

type ContentTargetViewContainerProps = ContentTargetViewProps & ContentTargetComponentsType;

const ContentTargetViewContainer = (props: ContentTargetViewContainerProps) => {
  const { target, nsi, ...components } = props;
  const componentProps = {
    target,
    nsi,
  };
  const { hasFeature } = useTechConfig();

  return (
    <Grid
      container
      spacing={2}
      direction='column'
    >
      {components.localities && (
        <Grid item>
          <ContentTargetViewLocalitiesCard {...componentProps} />
        </Grid>
      )}
      {components.externalUsers && (
        <Grid item>
          <ContentTargetViewExternalUsers {...componentProps} />
        </Grid>
      )}
      {components.clientOrgs && (
        <Grid item>
          <ContentTargetViewClientOrgsCard {...componentProps} />
        </Grid>
      )}
      {hasFeature(EAppFeature.Csp) && components.hasRzdSocialPackage && (
        <Grid item>
          <ContentTargetViewHasRzdSocialPackage {...componentProps} />
        </Grid>
      )}
      {components.roads && (
        <Grid item>
          <ContentTargetViewRoads {...componentProps} />
        </Grid>
      )}
      {components.orgUnits && (
        <Grid item>
          <ContentTargetViewOrgUnits {...componentProps} />
        </Grid>
      )}
      {components.gender && (
        <Grid item>
          <ContentTargetViewGender {...componentProps} />
        </Grid>
      )}
      {(components.tradeUnionMembersOnly || components.havingChildFamilyMemberOnly || components.familyMemberOnly) && (
        <Grid item>
          {components.tradeUnionMembersOnly && <ContentTargetViewTradeUnionMembersOnly {...componentProps} />}
          {components.havingChildFamilyMemberOnly && (
            <ContentTargetViewHavingChildFamilyMemberOnly {...componentProps} />
          )}
          {components.familyMemberOnly && <ContentTargetViewFamilyMemberOnly {...componentProps} />}
        </Grid>
      )}
    </Grid>
  );
};
export default ContentTargetViewContainer;
