import { EBannerPartition, UUID } from '@/domain';
import { EBannerTableTab } from '@features/banner/table/utils';
import { useBannerTableSearch } from '../hooks/useSearch';
import { useBannerTableSession } from '../hooks/useSession';

interface BannerTableBehaviorAdapterProps {
  readonly partition: EBannerPartition;
  readonly guid: UUID;
  readonly tab: EBannerTableTab;
}

export const BannerTableBehaviorAdapter = ({ guid, tab, partition }: BannerTableBehaviorAdapterProps) => {
  useBannerTableSession({ tab, partition, guid });
  useBannerTableSearch({ tab, partition });

  return null;
};
