import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { ServerErrorResponse } from '../../../../../data/network/types';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable, SportOption } from '../../../../../domain/model';
import { ENoticeStatus } from '../../../../../domain/model/enums';
import { ProductCategory, ProductDesk, ProductOffer } from '../../../../../domain/model/productOffer';
import { Nullable, UUID } from '../../../../../domain/model/types';
import Notifier from '../../../../../system/notifier';
import { PaginationSize } from '../../../../types';
import { productCreateStepSave, productDelete } from '../../create/store/slice';
import productServices from '../../services';
import { AllProps } from '../../services/productOffers';
import { EProductActionType, ProductActionTableCollectionType } from '../../types';
import { ProductTableFilterValues } from '../filter/filterUtils';
import { EProductTableColumn, EProductTableTab, ProductTableTabsCounter } from '../utils';

const defaultSort = `${EProductTableColumn.CreatedAt},desc`;

const getActionProcess = (state: ProductListState, id: UUID, actionType: EProductActionType) => {
  let process = state.actions.find(change => change.id === id);
  if (process) return process;

  process = {
    ...fetchableDefault,
    id,
    type: actionType,
    error: null,
  };
  state.actions.push(process);

  return process;
};

const getCollectionActionProcess = (state: ProductListState, actionType: ProductActionTableCollectionType) => {
  let process = state.collectionActions.find(change => change.type === actionType);
  if (process) return process;

  process = {
    ...fetchableDefault,
    type: actionType,
    error: null,
  };
  state.collectionActions.push(process);

  return process;
};

export type ProductsFetchProps = Omit<AllProps, 'signal'>;

type ProductsCountsFetchProps = ProductsFetchProps & {
  readonly tabs: EProductTableTab[];
};

export const productsFetch = createAsyncThunk<Pageable<ProductOffer>, ProductsFetchProps, AppThunkAPIConfig>(
  'product/list/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await productServices.productOffer.all({ ...props, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productsCountFetch = createAsyncThunk<number, ProductsFetchProps, AppThunkAPIConfig>(
  'product/list/count/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await productServices.productOffer.count({ ...props, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productsCountsFetch = createAsyncThunk<
  ProductTableTabsCounter,
  ProductsCountsFetchProps,
  AppThunkAPIConfig
>('product/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await productServices.productOffer.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productsCountsUnwatchedFetch = createAsyncThunk<
  ProductTableTabsCounter,
  ProductsCountsFetchProps,
  AppThunkAPIConfig
>('product/list/counts/unwatched/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await productServices.productOffer.countsByTabs({ ...props, viewed: false, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productsUnwatchedFetch = createAsyncThunk<UUID[], ProductsFetchProps, AppThunkAPIConfig>(
  'product/list/unwatched/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      const data = await productServices.productOffer.unwatched({ ...props, signal });
      return data.map(({ id }) => id);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productsPartnerHasDeskFetch = createAsyncThunk<boolean, UUID, AppThunkAPIConfig>(
  'product/list/hasDesk/fetch',
  async (partnerId, { rejectWithValue, signal }) => {
    let hasDesk = false;
    try {
      await Api.partner.desk({ partnerId, signal });
      hasDesk = true;
    } catch (e: any) {
      if (e.response.status !== 404) {
        ErrorHandler.handleHttpError(e, e.response);
        return rejectWithValue(e.response.data);
      }
    }
    return hasDesk;
  }
);

export const productsApproveProductCollection = createAsyncThunk<
  UUID[],
  { ids: UUID[] },
  AppThunkAPIConfig<ServerErrorResponse>
>('product/list/approveCollection', async ({ ids }, { rejectWithValue }) => {
  try {
    const responses = await Promise.allSettled<Promise<ProductOffer>[]>(
      ids.map(id => productServices.productOffer.approve({ id }))
    );

    const errors: string[] = [];
    const fulfilled = responses.filter(({ status }) => status === 'fulfilled');

    responses
      .filter(({ status }) => status === 'rejected')
      .forEach(({ reason }: any) => {
        const { response } = reason;
        const errorMessage = response.data.message;
        errors.push(errorMessage);
      });

    if (errors.length > 0) {
      Notifier.getInstance().addNotice(ENoticeStatus.Error, errors.join('\n'));
    }
    return fulfilled.map(response => (response as PromiseFulfilledResult<ProductOffer>).value.id);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productsResumeProductCollection = createAsyncThunk<
  UUID[],
  { ids: UUID[] },
  AppThunkAPIConfig<ServerErrorResponse>
>('product/list/resumeCollection', async ({ ids }, { rejectWithValue }) => {
  try {
    const responses = await Promise.allSettled<Promise<ProductOffer>[]>(
      ids.map(id => productServices.productOffer.resume({ id }))
    );

    const errors: string[] = [];
    const fulfilled = responses.filter(({ status }) => status === 'fulfilled');

    responses
      .filter(({ status }) => status === 'rejected')
      .forEach(({ reason }: any) => {
        const { response } = reason;
        const errorMessage = response.data.message;
        errors.push(errorMessage);
      });
    if (errors.length > 0) {
      Notifier.getInstance().addNotice(ENoticeStatus.Error, errors.join('\n'));
    }
    return fulfilled.map(response => (response as PromiseFulfilledResult<ProductOffer>).value.id);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productsDelete = createAsyncThunk<void, { id: UUID }, AppThunkAPIConfig>(
  'product/list/delete',
  async ({ id }, { rejectWithValue }) => {
    try {
      await productServices.productOffer.delete({
        id,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productsDuplicate = createAsyncThunk<ProductOffer, { id: UUID }, AppThunkAPIConfig>(
  'product/list/duplicate',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await productServices.productOffer.duplicate({
        id,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productsChangePrice = createAsyncThunk<
  ProductOffer,
  { id: UUID; price: number; originalPrice: ProductOffer['originalPrice'] },
  AppThunkAPIConfig
>('product/list/changePrice', async ({ id, price, originalPrice }, { rejectWithValue }) => {
  try {
    return await productServices.productOffer.changePrice({
      id,
      price,
      originalPrice,
    });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productsChangeStock = createAsyncThunk<ProductOffer, { id: UUID; stock: number }, AppThunkAPIConfig>(
  'product/list/changeStock',
  async ({ id, stock }, { rejectWithValue }) => {
    try {
      return await productServices.productOffer.changeStock({
        id,
        stock,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productsChangeModerator = createAsyncThunk<ProductOffer, { id: UUID; userId: UUID }, AppThunkAPIConfig>(
  'product/list/changeModerator',
  async ({ id, userId }, { rejectWithValue }) => {
    try {
      return await productServices.productOffer.assignModerator({
        id,
        userId,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productsApprove = createAsyncThunk<ProductOffer, { id: UUID }, AppThunkAPIConfig>(
  'product/list/approve',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await productServices.productOffer.approve({
        id,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productsPublishProduct = createAsyncThunk<ProductOffer, { id: UUID }, AppThunkAPIConfig>(
  'product/list/product/publish',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await productServices.productOffer.toModeration({
        id,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productsUnPublish = createAsyncThunk<ProductOffer, { id: UUID }, AppThunkAPIConfig>(
  'product/list/unPublish',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await productServices.productOffer.unPublish({
        id,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);
export const productsUnPublishDesk = createAsyncThunk<ProductDesk, { id: UUID }, AppThunkAPIConfig>(
  'product/list/unPublish',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await productServices.productDesk.unPublish({
        id,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productsPauseProduct = createAsyncThunk<
  ProductOffer,
  { id: UUID; comment: Nullable<string> },
  AppThunkAPIConfig
>('product/list/productOffer/pause', async ({ id, comment }, { rejectWithValue }) => {
  try {
    return await productServices.productOffer.pause({
      id,
      comment,
    });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productsRejectProductOffer = createAsyncThunk<
  ProductOffer,
  { id: UUID; reason: SportOption; comment?: string },
  AppThunkAPIConfig
>('product/list/productOffer/reject', async ({ id, reason, comment }, { rejectWithValue }) => {
  try {
    return await productServices.productOffer.reject({
      id,
      reasonId: reason.id,
      comment,
    });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productsResume = createAsyncThunk<ProductOffer, { id: UUID }, AppThunkAPIConfig>(
  'product/list/resume',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await productServices.productOffer.resume({
        id,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productsArchive = createAsyncThunk<ProductOffer, { id: UUID }, AppThunkAPIConfig>(
  'product/list/archive',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await productServices.productOffer.archive({
        id,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productsPauseProductDesk = createAsyncThunk<
  ProductDesk,
  { id: UUID; comment: Nullable<string> },
  AppThunkAPIConfig
>('product/list/productDesk/pause', async ({ id, comment }, { rejectWithValue }) => {
  try {
    return await productServices.productDesk.pause({
      id,
      comment,
    });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productsArchiveProductDesk = createAsyncThunk<ProductDesk, { id: UUID }, AppThunkAPIConfig>(
  'product/list/productDesk/archive',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await productServices.productDesk.archive({
        id,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productsResumeDesk = createAsyncThunk<ProductDesk, { id: UUID }, AppThunkAPIConfig>(
  'product/list/resumeDesk',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await productServices.productDesk.resume({
        id,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface ProductListState extends Fetchable, Pageable<ProductOffer> {
  readonly guid: Nullable<UUID>;
  readonly tab: Nullable<EProductTableTab>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
    readonly partnerId: Nullable<UUID>;
  };
  readonly filter: ProductTableFilterValues;
  readonly tabsCounter: ProductTableTabsCounter;
  readonly tabsCounterUnwatched: ProductTableTabsCounter;
  readonly allProducts: Fetchable & {
    readonly count: Nullable<number>;
    readonly needRefreshWatcher: number;
  };
  readonly partner: Fetchable & {
    readonly hasDesk: Nullable<boolean>;
    readonly needRefresh: boolean;
  };
  readonly selected: ProductOffer[];
  readonly category: Nullable<ProductCategory>;
  readonly categoryList: Nullable<ProductCategory[]>;
  // readonly changeStatus: ProductListChangeStatusState[];
  readonly actions: (Fetchable & {
    id: UUID;
    type: EProductActionType;
    error: Nullable<ServerErrorResponse>;
  })[];
  readonly collectionActions: (Fetchable & {
    type: ProductActionTableCollectionType;
    error: Nullable<ServerErrorResponse>;
  })[];
  readonly unwatchedIds: UUID[];
  readonly dialogs: {
    readonly reject: Nullable<ProductOffer>;
    readonly pause: Nullable<ProductOffer>;
    readonly pauseDesk: Nullable<ProductOffer>;
    readonly archive: Nullable<ProductOffer>;
    readonly archiveDesk: Nullable<ProductOffer>;
    readonly approve: Nullable<ProductOffer>;
    readonly changePrice: Nullable<ProductOffer>;
    readonly changeStock: Nullable<ProductOffer>;
    readonly changeModerator: Nullable<ProductOffer>;
  };
}

type Reducer<T = undefined> = CaseReducer<ProductListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ProductListState> {
  productsStartSession: Reducer<{ guid: UUID; filter?: ProductTableFilterValues }>;
  productsSetSort: Reducer<{ sort: string }>;
  productsSetTab: Reducer<{ tab: EProductTableTab }>;
  productsSetCategory: Reducer<{ category: Nullable<ProductCategory>; categoryList: Nullable<ProductCategory[]> }>;
  productsSetFilter: Reducer<ProductTableFilterValues>;
  productsSetPage: Reducer<{ pageNumber: number }>;
  productsSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  productsDataReset: Reducer;
  productsSortReset: Reducer;
  productsNeedRefreshWatcherReset: Reducer;
  productsOneSelect: Reducer<ProductOffer>;
  productsOneUnselect: Reducer<ProductOffer>;
  productsAllSelect: Reducer;
  productsAllUnselect: Reducer;
  productsStocksUploaded: Reducer<number>;
  productsOffersUploaded: Reducer<number>;
  productsSetDialogState: Reducer<{ name: keyof ProductListState['dialogs']; data: Nullable<ProductOffer> }>;
}

const slice = createSlice<ProductListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    ...fetchableDefault,
    guid: null,
    data: [],
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    needRefreshWatcher: 0,
    search: {
      sort: defaultSort,
      pageSize: 10,
      partnerId: null,
    },
    tab: null,
    filter: {},
    tabsCounter: {},
    tabsCounterUnwatched: {},
    allProducts: {
      ...fetchableDefault,
      count: null,
      needRefreshWatcher: 0,
    },
    partner: {
      ...fetchableDefault,
      hasDesk: null,
      needRefresh: true,
    },
    category: null,
    categoryList: null,
    selected: [],
    actions: [],
    collectionActions: [],
    unwatchedIds: [],
    dialogs: {
      reject: null,
      pause: null,
      pauseDesk: null,
      archive: null,
      archiveDesk: null,
      approve: null,
      changePrice: null,
      changeStock: null,
      changeModerator: null,
    },
  },
  reducers: {
    productsStartSession: (state, { payload }) => {
      const { guid, filter } = payload;
      if (guid !== state.guid) {
        state.guid = guid;

        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;
        state.needRefreshWatcher = 0;
        state.search = {
          sort: defaultSort,
          pageSize: 10,
          partnerId: null,
        };
        state.tab = null;
        state.filter = filter ?? {};
        state.tabsCounter = {};
        state.allProducts = {
          ...fetchableDefault,
          count: null,
          needRefreshWatcher: 0,
        };
        state.partner = {
          ...fetchableDefault,
          hasDesk: null,
          needRefresh: true,
        };
        state.category = null;
        state.categoryList = null;
        state.selected = [];
        state.actions = [];
        state.collectionActions = [];
        state.unwatchedIds = [];
        state.dialogs = {
          reject: null,
          pause: null,
          pauseDesk: null,
          archive: null,
          archiveDesk: null,
          approve: null,
          changePrice: null,
          changeStock: null,
          changeModerator: null,
        };
      }
    },
    productsSetTab: (state, { payload }) => {
      const { tab } = payload;
      // сбрасываем пейджинг и сортировку, если поменялись статусы (закладки)
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
        state.selected = [];
      }
      state.needRefreshWatcher++;
    },
    productsSetSort: (state, { payload }) => {
      const { sort } = payload;
      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
      state.selected = [];
    },
    productsSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
      state.selected = [];
    },
    productsSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
      state.selected = [];
    },
    productsSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
      state.selected = [];
    },
    productsSetCategory: (state, { payload }) => {
      const { category, categoryList } = payload;
      state.pageNumber = 1;
      state.category = category;
      state.categoryList = categoryList;
      state.needRefreshWatcher++;
      state.selected = [];
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
    },
    productsDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
      state.selected = [];
    },
    productsSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.needRefreshWatcher++;
      state.selected = [];
    },
    productsNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
      state.allProducts.needRefreshWatcher = 0;
    },
    productsOneSelect: (state, { payload }) => {
      const selected = [...state.selected];
      const existedIndex = selected.findIndex(s => s.id === payload.id);
      if (existedIndex !== -1) {
        selected.splice(existedIndex, 1);
      }
      state.selected = [...selected, payload];
    },
    productsOneUnselect: (state, { payload }) => {
      const existedIndex = state.selected.findIndex(s => s.id === payload.id);
      if (existedIndex !== -1) {
        state.selected.splice(existedIndex, 1);
      }
    },
    productsAllSelect: state => {
      state.selected = [...state.data];
    },
    productsAllUnselect: state => {
      state.selected = [];
    },
    productsStocksUploaded: (state, { payload }) => {
      if (payload > 0) {
        state.needRefreshWatcher++;
      }
    },
    productsOffersUploaded: (state, { payload }) => {
      if (payload > 0) {
        state.needRefreshWatcher++;
      }
    },
    productsSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(productsFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;

        if (partnerId !== state.search.partnerId) {
          state.data = [];
        }
        state.search.partnerId = partnerId;
      })
      .addCase(productsFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(productsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(productsUnwatchedFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        if (partnerId !== state.search.partnerId) {
          state.unwatchedIds = [];
        }
      })
      .addCase(productsUnwatchedFetch.fulfilled, (state, { payload }) => {
        state.unwatchedIds = payload;
      })
      .addCase(productsUnwatchedFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.unwatchedIds = [];
        }
      })
      .addCase(productsCountFetch.pending, state => {
        state.allProducts.isFetching = true;
        state.allProducts.isFetched = false;
        state.allProducts.isFailed = false;

        if (state.allProducts.count === 0) {
          //сбрасываем в null в том случае если до этого ничего не было, чтобы не моргала таблица лишний раз
          state.allProducts.count = null;
        }
      })
      .addCase(productsCountFetch.fulfilled, (state, { payload }) => {
        state.allProducts.isFetching = false;
        state.allProducts.isFetched = true;
        state.allProducts.isFailed = false;

        state.allProducts.count = payload;
      })
      .addCase(productsCountFetch.rejected, state => {
        state.allProducts.isFetching = false;
        state.allProducts.isFetched = false;
        state.allProducts.isFailed = true;

        state.allProducts.count = null;
      })
      .addCase(productsCountsFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        if (partnerId !== state.search.partnerId) {
          state.tabsCounter = {};
        }
      })
      .addCase(productsCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(productsCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      })
      .addCase(productsCountsUnwatchedFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        if (partnerId !== state.search.partnerId) {
          state.tabsCounterUnwatched = {};
        }
      })
      .addCase(productsCountsUnwatchedFetch.fulfilled, (state, { payload }) => {
        state.tabsCounterUnwatched = payload;
      })
      .addCase(productsCountsUnwatchedFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounterUnwatched = {};
        }
      })
      .addCase(productsPartnerHasDeskFetch.pending, state => {
        state.partner.isFetching = true;
        state.partner.isFetched = false;
        state.partner.isFailed = false;

        state.partner.needRefresh = false;
      })
      .addCase(productsPartnerHasDeskFetch.fulfilled, (state, { payload }) => {
        state.partner.isFetching = false;
        state.partner.isFetched = true;
        state.partner.isFailed = false;

        state.partner.hasDesk = payload;
      })
      .addCase(productsPartnerHasDeskFetch.rejected, state => {
        state.partner.isFetching = false;
        state.partner.isFetched = false;
        state.partner.isFailed = true;

        state.partner.hasDesk = null;
      })
      .addCase(productsApproveProductCollection.pending, state => {
        const actionType = EProductActionType.Approve;
        const process = getCollectionActionProcess(state, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.type = actionType;
        process.error = null;
      })
      .addCase(productsApproveProductCollection.fulfilled, (state, { payload }) => {
        const actionType = EProductActionType.Approve;
        const process = getCollectionActionProcess(state, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.type = actionType;
        process.error = null;

        const selected = [...state.selected];
        state.selected = selected.filter(item => !payload.includes(item.id));

        state.needRefreshWatcher++;
      })
      .addCase(productsApproveProductCollection.rejected, (state, { payload }) => {
        const actionType = EProductActionType.Approve;
        const process = getCollectionActionProcess(state, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productsResumeProductCollection.pending, state => {
        const actionType = EProductActionType.Resume;
        const process = getCollectionActionProcess(state, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.type = actionType;
        process.error = null;
      })
      .addCase(productsResumeProductCollection.fulfilled, (state, { payload }) => {
        const actionType = EProductActionType.Resume;
        const process = getCollectionActionProcess(state, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.type = actionType;
        process.error = null;

        const selected = [...state.selected];
        state.selected = selected.filter(item => !payload.includes(item.id));

        state.needRefreshWatcher++;
      })
      .addCase(productsResumeProductCollection.rejected, (state, { payload }) => {
        const actionType = EProductActionType.Resume;
        const process = getCollectionActionProcess(state, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productsDuplicate.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Duplicate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productsDuplicate.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Duplicate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(productsDuplicate.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Duplicate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })

      .addCase(productsPublishProduct.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Publish;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productsPublishProduct.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Publish;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(productsPublishProduct.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Publish;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productsUnPublish.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.UnPublish;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productsUnPublish.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.UnPublish;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(productsUnPublish.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.UnPublish;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productsApprove.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Approve;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productsApprove.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Approve;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(productsApprove.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Approve;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productsRejectProductOffer.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Reject;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productsRejectProductOffer.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Reject;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(productsRejectProductOffer.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Reject;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productsPauseProduct.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Pause;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productsPauseProduct.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Pause;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(productsPauseProduct.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Pause;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productsPauseProductDesk.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.PauseDesk;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productsPauseProductDesk.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.PauseDesk;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(productsPauseProductDesk.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.PauseDesk;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productsResume.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Resume;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productsResume.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Resume;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(productsResume.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Resume;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productsResumeDesk.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.ResumeDesk;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productsResumeDesk.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.ResumeDesk;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(productsResumeDesk.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.ResumeDesk;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productsArchive.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Archive;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productsArchive.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Archive;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(productsArchive.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Archive;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productsArchiveProductDesk.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.ArchiveDesk;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productsArchiveProductDesk.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.ArchiveDesk;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(productsArchiveProductDesk.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.ArchiveDesk;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productsChangeStock.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.ChangeStock;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productsChangeStock.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.ChangeStock;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(productsChangeStock.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.ChangeStock;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productsChangeModerator.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.ChangeModerator;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productsChangeModerator.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.ChangeModerator;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(productsChangeModerator.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.ChangeModerator;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productsChangePrice.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.ChangePrice;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(productsChangePrice.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.ChangePrice;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productsDelete.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Delete;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(productsDelete.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Delete;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
        state.allProducts.needRefreshWatcher++;
      })
      .addCase(productsDelete.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EProductActionType.Delete;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(productDelete.fulfilled, state => {
        // todo - по моему лишнее
        state.allProducts.needRefreshWatcher++;
      })
      .addCase(productCreateStepSave.fulfilled, state => {
        // todo - по моему лишнее
        state.allProducts.needRefreshWatcher++;
      });
  },
});

export const {
  productsStartSession,
  productsSetTab,
  productsSetSort,
  productsSetPage,
  productsSetPageSize,
  productsSetFilter,
  productsSetCategory,
  productsSortReset,
  productsDataReset,
  productsNeedRefreshWatcherReset,
  productsOneSelect,
  productsOneUnselect,
  productsAllSelect,
  productsAllUnselect,
  productsStocksUploaded,
  productsOffersUploaded,
  productsSetDialogState,
} = slice.actions;

export default slice.reducer;
