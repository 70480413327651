import { Location } from 'history';
import { v4 as uuidv4 } from 'uuid';
import { UUID } from '../domain/model/types';
import { SIDE_PANEL_PARAM_NAME } from './constants';

interface CreateSidePanelRouteProps {
  readonly location: any;
  readonly type: string;
  readonly params?: any;
  readonly saveGuid?: boolean;
  readonly parent?: UUID;
  readonly parentSide?: UUID;
}

export const createSidePanelRoute = (props: CreateSidePanelRouteProps) => {
  const { location, type, params, saveGuid, parent, parentSide } = props;
  const searchParams = new URLSearchParams(location.search);
  const sideParams = JSON.parse(decodeURI(searchParams.get(SIDE_PANEL_PARAM_NAME)!));
  const guid = saveGuid ? sideParams.guid : uuidv4();

  searchParams.delete(SIDE_PANEL_PARAM_NAME);
  searchParams.append(
    SIDE_PANEL_PARAM_NAME,
    encodeURI(
      JSON.stringify({
        type,
        guid,
        parent,
        parentSide,
        ...params,
      })
    )
  );

  return {
    ...location,
    search: `?${searchParams.toString()}`,
  };
};

interface AddSearchParamToLocationProps<S> {
  readonly location: any;
  readonly param: string;
  readonly value: string;
  readonly state?: S;
}

export const addSearchParamToLocation = <S>(props: AddSearchParamToLocationProps<S>): Location => {
  const { location, param, value, state } = props;
  const { pathname, search, state: prevState, hash, key } = location;
  const searchParams = new URLSearchParams(search);
  searchParams.delete(param);
  searchParams.append(param, value);

  return {
    pathname,
    search: searchParams.toString(),
    state: state ?? prevState,
    hash,
    key,
  };
};

interface AddStateToLocationProps<S> {
  readonly location: Location;
  readonly state?: S;
}

export const addStateToLocation = <S>(props: AddStateToLocationProps<S>): Location => {
  const { location, state } = props;
  const { pathname, search, state: prevState, hash, key } = location;

  const newState = typeof prevState === 'object' ? { ...prevState, ...state } : { ...state };

  return {
    pathname,
    search,
    state: newState,
    hash,
    key,
  };
};
