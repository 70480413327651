import { EMPUserGroup, ENoticeStatus, EOfferType } from '@/domain';
import { getDataFilterValuesByStrategies } from '@/presentation/utils/filtering';
import Notifier from '@/system/notifier';
import DataFilterEdit from '@components/common/dataFilter/edit';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import reportServices from '@features/report/services';
import { defaultDatePeriod, reportDownloadURL, validateDatePeriod } from '@features/report/utils';
import ReportDialogLayout from '@layouts/reportDialog';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  EReportOffersStatisticByPartnerFilterItem,
  getReportOffersStatisticByPartnerFilterStrategies,
  ReportOffersStatisticByPartnerFilterEditStrategy,
  ReportOffersStatisticByPartnerFilterValues,
} from './filters';

type OffersStatisticReportProps = {
  readonly partnerId: Nullable<UUID>;
  readonly onClose: () => void;
};

const defaultFilterValue: ReportOffersStatisticByPartnerFilterValues = {
  [EReportOffersStatisticByPartnerFilterItem.DatePeriod]: {
    value: defaultDatePeriod,
  },
  [EReportOffersStatisticByPartnerFilterItem.OfferType]: {
    value: [EOfferType.Corp, EOfferType.Trade],
  },
  [EReportOffersStatisticByPartnerFilterItem.UserGroup]: {
    value: EMPUserGroup.All,
  },
};

export const OffersStatisticReportContainer = (props: OffersStatisticReportProps) => {
  const { partnerId, onClose } = props;
  const [error, setError] = useState<Nullable<string>>(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const { offerTypes, userMpGroups } = useSelector(nsiDataSelector);

  const [filter, setFilter] = useState<ReportOffersStatisticByPartnerFilterValues>(defaultFilterValue);

  const resetValidation = useCallback(() => {
    setError(null);
  }, []);

  const filterStrategies = useMemo(
    () =>
      getReportOffersStatisticByPartnerFilterStrategies(
        filter,
        partnerId,
        offerTypes.filter(offerType => [EOfferType.Trade, EOfferType.Corp].includes(offerType.id)),
        userMpGroups
      ),
    [filter, offerTypes, partnerId]
  );

  const onChangeStrategies = useCallback(
    (strategies: ReportOffersStatisticByPartnerFilterEditStrategy[]) => {
      setFilter(getDataFilterValuesByStrategies(strategies));
    },
    [setFilter]
  );

  const onResetFilter = useCallback(() => {
    setFilter(defaultFilterValue);
  }, [setFilter]);

  const handleDownload = () => {
    resetValidation();
    // готовим параметры
    const offerType = filter[EReportOffersStatisticByPartnerFilterItem.OfferType]?.value;
    const startDate = filter[EReportOffersStatisticByPartnerFilterItem.DatePeriod]?.value?.[0];
    const endDate = filter[EReportOffersStatisticByPartnerFilterItem.DatePeriod]?.value?.[1];
    const partner = filter[EReportOffersStatisticByPartnerFilterItem.Partner]?.value;
    const reportUserGroup = filter[EReportOffersStatisticByPartnerFilterItem.UserGroup]?.value;

    const isValid = validateDatePeriod(startDate, endDate);

    if (isValid) {
      setIsDownloading(true);
      reportServices.mp
        .offersStatistic({ startDate, endDate, offerType, partnerId: partnerId || partner?.id, reportUserGroup })
        .then(result => {
          reportDownloadURL(result);
          onClose();
        })
        .catch(error => Notifier.getInstance().addNotice(ENoticeStatus.Error, `${error.message}`))
        .finally(() => setIsDownloading(false));
    } else {
      setError('Заполните корректно поля даты');
    }
  };

  useEffect(() => {
    resetValidation();
  }, [filter]);

  return (
    <ReportDialogLayout
      title='Конверсия предложений'
      filter={
        <DataFilterEdit
          strategies={filterStrategies}
          onChange={onChangeStrategies}
        />
      }
      error={error}
      isFetching={isDownloading}
      onFetch={handleDownload}
      onClearFilter={onResetFilter}
      onClose={onClose}
    />
  );
};
