import { useCallback } from 'react';
import { ESortDirection } from '../../../../domain/model/enums';
import { MpUser } from '../../../../domain/model/user';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../components/common/table';
import TableActions from '../../../components/common/table/actions';
import { DataTableLoader } from '../../../components/common/table/loader';
import PartnerEmployeeTableCellEmail from '../components/tableCell/email';
import PartnerEmployeeTableCellName from '../components/tableCell/name';
import PartnerEmployeeTableCellOwner from '../components/tableCell/owner';
import PartnerEmployeeTableCellPhone from '../components/tableCell/phone';
import { PartnerEmployeeActions, PartnerEmployeeActionTableType } from '../types';
import PartnerEmployeeTableCellAdapter from './adapters/cell';
import PartnerEmployeeTableCellOwnerAdapter from './adapters/cellOwner';
import { Wrapper } from './controls';
import { partnerEmployeesIsFetchingSelector } from './store/selectors';
import {
  EPartnerEmployeeTableColumn,
  PartnerEmployeeTableActionCall,
  PartnerEmployeeTableActionProcess,
} from './utils';

interface PartnerEmployeeTableProps {
  readonly metadata: DataTableMetadata<EPartnerEmployeeTableColumn>;
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly employees: MpUser[];
  readonly onChangeMetadata: (metadata: DataTableMetadata<EPartnerEmployeeTableColumn>) => void;
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onEmployeeClick: (employee: MpUser) => void;
  readonly onEmployeeAction: (call: PartnerEmployeeTableActionCall) => void;
  readonly getActions: (employee: MpUser) => PartnerEmployeeActions<PartnerEmployeeActionTableType>;
  readonly getActionProcesses: (employee: MpUser) => PartnerEmployeeTableActionProcess[];
}

const PartnerEmployeeTable = (props: PartnerEmployeeTableProps) => {
  const {
    metadata,
    sort,
    employees,
    onChangeMetadata,
    onRequestSort,
    onEmployeeClick,
    onEmployeeAction,
    getActions,
    getActionProcesses,
  } = props;

  const rows: DataTableRow<MpUser, EPartnerEmployeeTableColumn>[] = employees?.map((employee, index) => {
    return {
      [EPartnerEmployeeTableColumn.Name]: (
        <PartnerEmployeeTableCellAdapter
          index={index}
          component={PartnerEmployeeTableCellName}
        />
      ),
      [EPartnerEmployeeTableColumn.Phone]: (
        <PartnerEmployeeTableCellAdapter
          index={index}
          component={PartnerEmployeeTableCellPhone}
        />
      ),
      [EPartnerEmployeeTableColumn.Email]: (
        <PartnerEmployeeTableCellAdapter
          index={index}
          component={PartnerEmployeeTableCellEmail}
        />
      ),
      [EPartnerEmployeeTableColumn.Owner]: (
        <PartnerEmployeeTableCellOwnerAdapter
          index={index}
          component={PartnerEmployeeTableCellOwner}
        />
      ),
      data: employee,
    };
  });

  const onTableAction = useCallback(
    (action: PartnerEmployeeActionTableType, employee: MpUser) => onEmployeeAction({ action, employee }),
    [onEmployeeAction]
  );

  return (
    <Wrapper>
      <DataTable<MpUser, EPartnerEmployeeTableColumn>
        metadata={metadata}
        width='auto'
        overflowX='inherit'
        rows={rows}
        sort={{ column: sort.column, direction: sort.direction }}
        loader={<DataTableLoader selector={partnerEmployeesIsFetchingSelector} />}
        rowActions
        hoverModule
        onSort={(event, column, direction) => onRequestSort(column, direction)}
        onRowClick={(event, cell, { data }) => onEmployeeClick(data)}
        onChangeMetadata={onChangeMetadata}
        getRowActions={employee => (
          <TableActions<MpUser, PartnerEmployeeActionTableType>
            entityLink={employee}
            actions={getActions(employee)}
            onExecute={onTableAction}
            isExecuting={getActionProcesses(employee).some(process => process.isFetching)}
          />
        )}
      />
    </Wrapper>
  );
};

export default PartnerEmployeeTable;
