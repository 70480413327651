import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { SportOptionTyped } from '../../../../../../../domain/model';
import { EPartnerOwnershipType } from '../../../../../../../domain/model/enums';
import { PartnerDataDraft } from '../../../../../../../domain/model/partner';
import { Nullable } from '../../../../../../../domain/model/types';
import ValidationErrorPanel from '../../../../../../components/common/validation/errorPanel';
import { MPEmailInput, MPFormInput, MPMaskInput, MPPhoneInput } from '../../../../../../theme/ui-kit/input';
import { MPMenuItem } from '../../../../../../theme/ui-kit/menu/item';
import { MPSelect } from '../../../../../../theme/ui-kit/select';
import { OnChangeObjectAttribute } from '../../../../../../types';
import { ValidationResult } from '../../../../../../utils/validation';
import { getPartnerInnMask, partnerPhonePrefix } from '../../../../../general/partner/utils/common';
import { PartnerGeneralIndividual } from '../../../../../general/partner/validation';

interface PartnerApplicationEditIndividualGeneralProps {
  readonly inn: Nullable<string>;
  readonly name: Nullable<string>;
  readonly ownerFullName: Nullable<string>;
  readonly email: Nullable<string>;
  readonly website: Nullable<string>;
  readonly phone: Nullable<string>;
  readonly validation: Nullable<ValidationResult<PartnerGeneralIndividual>>;
  readonly isValid: boolean;
  readonly onChangeOrgType: (value: EPartnerOwnershipType) => void;
  readonly ownerShipTypes: SportOptionTyped<EPartnerOwnershipType>[];
  readonly onChangeAttribute: OnChangeObjectAttribute<PartnerDataDraft>;
}

const InnMaskInput = React.forwardRef<any, any>(({ ...props }, ref) => (
  <MPMaskInput
    mask={getPartnerInnMask(EPartnerOwnershipType.IndividualEntrepreneur)}
    ref={ref}
    {...props}
  />
));

const PartnerApplicationEditIndividualGeneral = (props: PartnerApplicationEditIndividualGeneralProps) => {
  const {
    inn,
    name,
    ownerFullName,
    email,
    website,
    phone,
    ownerShipTypes,
    validation,
    isValid,
    onChangeOrgType,
    onChangeAttribute,
  } = props;

  return (
    <Grid
      container
      spacing={2}
      direction='column'
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          variant='h3'
          gutterBottom
        >
          Организационно-правовая форма
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <MPSelect<EPartnerOwnershipType>
          label='Организационно-правовая форма'
          value={EPartnerOwnershipType.IndividualEntrepreneur}
          onChange={(value: EPartnerOwnershipType) => onChangeOrgType(value)}
        >
          {ownerShipTypes.map(item => (
            <MPMenuItem
              key={item.id}
              value={item.id}
            >
              <Typography variant='body1'>{item.name}</Typography>
            </MPMenuItem>
          ))}
        </MPSelect>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography variant='h4'>Общая информация</Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <MPFormInput
          label='ИНН'
          value={inn}
          error={!!validation?.inn?.hasError}
          helperText={validation?.inn?.message}
          InputProps={{
            inputComponent: InnMaskInput,
          }}
          onChange={(event: any) => onChangeAttribute('inn', event.target.value)}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <MPFormInput
          label='Название компании, которое увидят покупатели'
          value={name}
          error={!!validation?.name?.hasError}
          helperText={validation?.name?.message}
          onChange={(event: any) => onChangeAttribute('name', event.target.value)}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <MPFormInput
          label='ФИО индивидуального предпринимателя'
          value={ownerFullName}
          error={!!validation?.ownerFullName?.hasError}
          helperText={validation?.ownerFullName?.message}
          onChange={event => onChangeAttribute('ownerFullName', event.target.value)}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <MPEmailInput
          label='Электронная почта'
          value={email}
          error={!!validation?.email?.hasError}
          helperText={validation?.email?.message}
          onChange={value => onChangeAttribute('email', value)}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <MPFormInput
          label='Сайт компании'
          value={website}
          error={!!validation?.website?.hasError}
          helperText={validation?.website?.message}
          onChange={event => onChangeAttribute('website', event.target.value)}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <MPPhoneInput
          prefix={partnerPhonePrefix}
          label='Контактный телефон'
          value={phone}
          error={!!validation?.phone?.hasError}
          helperText={validation?.phone?.message}
          onChange={event => onChangeAttribute('phone', event.target.value)}
        />
      </Grid>

      {!isValid && (
        <Grid
          item
          xs={12}
        >
          <ValidationErrorPanel />
        </Grid>
      )}
    </Grid>
  );
};

export default PartnerApplicationEditIndividualGeneral;
