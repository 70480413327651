import { Address, ContentTarget, EMultiSelectorValueType } from '@/domain';
import {
  AddressMultipleSelectType,
  AddressRegionAndCityMultipleTypesSelector,
} from '@features/address/components/selector/multipleTypes';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ContentTargetEditComponent } from '../../types';

const attribute: keyof ContentTarget = 'localities';

const ContentTargetEditLocalities: ContentTargetEditComponent = props => {
  const { target, topUsed, validation, onChangeAttribute } = props;

  const topUsedLocalities = topUsed?.localities;

  const targetLocalities = target[attribute] ?? { select: EMultiSelectorValueType.In, in: null };

  // значение localities может быть
  // 1) null
  // 2) { select: EMultiSelectorValueType.All, in: null }
  // 3) { select: EMultiSelectorValueType.In, in: newValue }, где newValue.length > 0
  const onChange = (newValue: Nullable<Address[]>) => {
    if (newValue?.length) {
      onChangeAttribute(attribute, { select: EMultiSelectorValueType.In, in: newValue });
    } else {
      onChangeAttribute(attribute, null);
    }
  };

  const onSelectAll = () => {
    onChangeAttribute(attribute, { select: EMultiSelectorValueType.All, in: null });
  };

  return (
    <AddressRegionAndCityMultipleTypesSelector
      label='Город'
      color='primary'
      popupIcon={<ExpandMoreIcon fontSize='small' />}
      limitTags={2}
      error={!!validation?.[attribute]?.hasError}
      helperText={validation?.[attribute]?.message}
      regionsAndCities={targetLocalities.in ?? []}
      select={targetLocalities.select === EMultiSelectorValueType.All ? AddressMultipleSelectType.All : null}
      defaultSource={topUsedLocalities}
      onSelect={onSelectAll}
      onChange={onChange}
    />
  );
};

export default ContentTargetEditLocalities;
