import { ObjectTarget } from '../../../../../domain/model';
import { ETargetType } from '../../../../../domain/model/enums';
import { CmsContainerViewResourceSize } from '../types';

export const cmsBannerSizes1: CmsContainerViewResourceSize[] = [
  { name: 'Маленький - 410 x 144', desktop: ['410px', '144px'], mobile: ['138px', '80px'] },
  { name: 'Большой - 410 x 240', desktop: ['410px', '240px'], mobile: ['138px', '112px'] },
];

export const cmsBannerSizes2: CmsContainerViewResourceSize[] = [
  { name: 'Средний - 1280 x 144', desktop: ['1280px', '144px'], mobile: ['448px', '60px'] },
  { name: 'Большой - 1280 x 240', desktop: ['1280px', '240px'], mobile: ['448px', '120px'] },
];

export const cmsBannerSizes3: CmsContainerViewResourceSize[] = [
  { name: 'Маленький - 1280 x 144', desktop: ['1280px', '144px'], mobile: ['448px', '60px'] },
  { name: 'Средний - 1280 x 240', desktop: ['1280px', '240px'], mobile: ['448px', '120px'] },
  { name: 'Большой - 1280 x 380', desktop: ['1280px', '380px'], mobile: ['448px', '180px'] },
];

export const cmsContainerOfferTargets: ObjectTarget[] = [
  { id: ETargetType.Geo, name: 'По городам' },
  { id: ETargetType.Corp, name: 'По структуре ОАО «РЖД»' },
];
