import CmsContainerTableCell, { CmsContainerTableCellCommonProps } from './index';

type CmsContainerTableCellOfferTypeProps = CmsContainerTableCellCommonProps;

const CmsContainerTableCellOfferType = (props: CmsContainerTableCellOfferTypeProps) => {
  const {
    cmsContainer: { offerTypeName },
  } = props;

  const value = offerTypeName ?? '-';

  return (
    <CmsContainerTableCell
      {...props}
      value={value}
    />
  );
};

export default CmsContainerTableCellOfferType;
