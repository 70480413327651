import { Collapse, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { ExpandLessIcon, ExpandMoreIcon } from '../../../../icons';
import theme from '../../../../theme';
import { MenuPanelItem } from '../../../../types';
import AppSvgIcon from '../../images/icon';
import { SubMenuList } from '../controls';

interface MenuPanelListItemProps {
  readonly item: MenuPanelItem;
  readonly onItemClick: (item: MenuPanelItem) => void;
  readonly isSelected: (item: MenuPanelItem) => boolean;
  readonly isOpenedSubMenu: (item: MenuPanelItem) => boolean;
}

const MenuPanelListItem = ({ item, onItemClick, isSelected, isOpenedSubMenu }: MenuPanelListItemProps) => {
  return (
    <React.Fragment>
      <ListItem
        button
        selected={isSelected(item)}
        key={item.id}
        onClick={() => onItemClick(item)}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText
          primary={item.caption}
          primaryTypographyProps={{ variant: 'body1' }}
        />
        {item.subMenu &&
          (isOpenedSubMenu(item) ? (
            <AppSvgIcon
              fontSize='small'
              icon={ExpandLessIcon}
            />
          ) : (
            <AppSvgIcon
              fontSize='small'
              htmlColor={theme.palette.text.secondary}
              icon={ExpandMoreIcon}
            />
          ))}
      </ListItem>
      {item.subMenu && (
        <Collapse
          in={isOpenedSubMenu(item)}
          timeout='auto'
          unmountOnExit
        >
          <SubMenuList disablePadding>
            {item.subMenu.map(subItem => (
              <React.Fragment key={subItem.id}>
                <MenuPanelListItem
                  item={subItem}
                  onItemClick={onItemClick}
                  isSelected={isSelected}
                  isOpenedSubMenu={isOpenedSubMenu}
                />
              </React.Fragment>
            ))}
          </SubMenuList>
        </Collapse>
      )}
    </React.Fragment>
  );
};

export default MenuPanelListItem;
