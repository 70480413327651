import { combineReducers } from '@reduxjs/toolkit';
import details, { ChatDetailsState } from './details/store/slice';
import list, { ChatsListState } from './table/store/slice';

interface ChatState {
  list: ChatsListState;
  details: ChatDetailsState;
}

export default combineReducers<ChatState>({
  list,
  details,
});
