import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../data/store/store';
import { BookingOfferShort } from '../../../../../domain/model/booking';
import { ESortDirection } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { addSearchParamToLocation } from '../../../../../routing/globalRouting';
import { PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import { EOfferTableTab } from '../../../general/offer/types';
import { useBookingOfferActions } from '../../actions/useActions';
import { EBookingOfferUrlParam, getBookingOfferCreateRoute } from '../../entry';
import { BookingOfferTableFilterEditStrategy } from '../../filterUtils';
import { bookingOffersGuidSelector } from '../store/selectors';
import {
  bookingOffersDataReset,
  bookingOffersNeedRefreshWatcherIncrement,
  bookingOffersSetFilter,
  bookingOffersSetPage,
  bookingOffersSetPageSize,
  bookingOffersSetSort,
  bookingOffersSortReset,
} from '../store/slice';

export type UseBookingOffersTable = {
  readonly onCreate: () => void;
  readonly onRefresh: () => void;
  readonly onResetSort: () => void;
  readonly onChangePage: (page: number) => void;
  readonly onChangeTab: (newTab: EOfferTableTab) => void;
  readonly onChangePageSize: (pageSize: PaginationSize) => void;
  readonly onChangeFilter: (strategies: BookingOfferTableFilterEditStrategy[]) => void;

  readonly onBookingOfferTryReject: (bookingOffer: BookingOfferShort) => void;
  readonly onBookingOfferTryDelete: (bookingOffer: BookingOfferShort) => void;
  readonly onBookingOfferTryResume: (bookingOffer: BookingOfferShort) => void;
  readonly onBookingOfferTryArchive: (bookingOffer: BookingOfferShort) => void;
  readonly onBookingOfferTryPause: (bookingOffer: BookingOfferShort) => void;
  readonly onBookingOfferTryInWork: (bookingOffer: BookingOfferShort) => void;
  readonly onBookingOfferTryChangeModerator: (bookingOffer: BookingOfferShort) => void;
  readonly onBookingOfferTryApprove: (bookingOffer: BookingOfferShort) => void;
  readonly onBookingOfferTryPublish: (bookingOffer: BookingOfferShort) => void;
  readonly onBookingOfferTryUnPublish: (bookingOffer: BookingOfferShort) => void;
  readonly onBookingOfferTryDuplicate: (bookingOffer: BookingOfferShort) => void;
  readonly onBookingOfferTryRetrieve: (bookingOffer: BookingOfferShort) => void;
  readonly onBookingOfferTryServiceManage: (bookingOffer: BookingOfferShort) => void;
  readonly onBookingOfferTryReturnToVerification: (bookingOffer: BookingOfferShort) => void;

  readonly onBookingOffersChangePageSize: (pageSize: PaginationSize) => void;
  readonly onBookingOffersChangePage: (page: number) => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
};

type UseBookingOffersTableHandlersProps = {
  readonly tab: EOfferTableTab;
};

export const useBookingOffersTableHandlers = ({ tab }: UseBookingOffersTableHandlersProps): UseBookingOffersTable => {
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const {
    onTryReject,
    onTryRetrieve,
    onTryApprove,
    onTryArchive,
    onTryChangeModerator,
    onTryDelete,
    onTryDuplicate,
    onTryInWork,
    onTryPause,
    onTryPublish,
    onTryUnPublish,
    onTryResume,
    onTryReturnToVerification,
    onTryServiceManage,
  } = useBookingOfferActions();

  const getBookingOffersGuidFromState = useCallback((): Nullable<UUID> => {
    const state = store.getState();
    return bookingOffersGuidSelector(state);
  }, [store]);

  const onRefresh = useCallback(() => dispatch(bookingOffersNeedRefreshWatcherIncrement()), [dispatch]);

  const onChangeFilter = useCallback(
    (strategies: BookingOfferTableFilterEditStrategy[]) => {
      dispatch(bookingOffersSetFilter(getDataFilterValuesByStrategies(strategies)));
    },
    [dispatch]
  );

  const onBookingOfferTryReject = useCallback<UseBookingOffersTable['onBookingOfferTryReject']>(onTryReject, [
    onTryReject,
  ]);

  const onBookingOfferTryDelete = useCallback<UseBookingOffersTable['onBookingOfferTryDelete']>(onTryDelete, [
    onTryDelete,
  ]);

  const onBookingOfferTryPublish = useCallback<UseBookingOffersTable['onBookingOfferTryPublish']>(onTryPublish, [
    onTryPublish,
  ]);

  const onBookingOfferTryUnPublish = useCallback<UseBookingOffersTable['onBookingOfferTryUnPublish']>(onTryUnPublish, [
    onTryUnPublish,
  ]);

  const onBookingOfferTryArchive = useCallback<UseBookingOffersTable['onBookingOfferTryArchive']>(onTryArchive, [
    onTryArchive,
  ]);

  const onBookingOfferTryDuplicate = useCallback<UseBookingOffersTable['onBookingOfferTryDuplicate']>(onTryDuplicate, [
    onTryDuplicate,
  ]);

  const onBookingOfferTryInWork = useCallback<UseBookingOffersTable['onBookingOfferTryInWork']>(onTryInWork, [
    onTryInWork,
  ]);

  const onBookingOfferTryChangeModerator = useCallback<UseBookingOffersTable['onBookingOfferTryChangeModerator']>(
    onTryChangeModerator,
    [onTryChangeModerator]
  );

  const onBookingOfferTryApprove = useCallback<UseBookingOffersTable['onBookingOfferTryApprove']>(onTryApprove, [
    onTryApprove,
  ]);

  const onBookingOfferTryRetrieve = useCallback<UseBookingOffersTable['onBookingOfferTryRetrieve']>(onTryRetrieve, [
    onTryRetrieve,
  ]);

  const onBookingOfferTryResume = useCallback<UseBookingOffersTable['onBookingOfferTryResume']>(onTryResume, [
    onTryResume,
  ]);

  const onBookingOfferTryServiceManage = useCallback<UseBookingOffersTable['onBookingOfferTryResume']>(
    onTryServiceManage,
    [onTryServiceManage]
  );

  const onBookingOfferTryReturnToVerification = useCallback<
    UseBookingOffersTable['onBookingOfferTryReturnToVerification']
  >(onTryReturnToVerification, [onTryReturnToVerification]);

  const onBookingOfferTryPause = useCallback<UseBookingOffersTable['onBookingOfferTryPause']>(onTryPause, [onTryPause]);

  const onBookingOffersChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(bookingOffersSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onBookingOffersChangePage = useCallback(
    (page: number) => {
      dispatch(bookingOffersSetPage({ pageNumber: page }));
    },
    [dispatch]
  );

  const onResetSort = useCallback(() => dispatch(bookingOffersSortReset()), [dispatch]);

  const onChangePage = useCallback(
    (page: number) => {
      dispatch(bookingOffersSetPage({ pageNumber: page }));
    },
    [dispatch]
  );

  const onChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(bookingOffersSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onChangeTab = useCallback(
    (newTab: EOfferTableTab) => {
      if (newTab !== tab) {
        const guid = getBookingOffersGuidFromState();
        dispatch(bookingOffersDataReset());
        history.replace(
          addSearchParamToLocation({
            location,
            param: EBookingOfferUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [dispatch, history, location, tab, getBookingOffersGuidFromState]
  );

  const onChangeSort = useCallback(
    (name: string, direction: ESortDirection) => {
      dispatch(
        bookingOffersSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onCreate = useCallback(() => history.push(getBookingOfferCreateRoute({ location })), [history, location]);

  return {
    onCreate,
    onResetSort,
    onChangeSort,
    onChangeFilter,
    onChangePage,
    onChangePageSize,
    onChangeTab,
    onBookingOfferTryApprove,
    onBookingOfferTryArchive,
    onBookingOfferTryChangeModerator,
    onBookingOfferTryDelete,
    onBookingOfferTryDuplicate,
    onBookingOfferTryInWork,
    onBookingOfferTryPause,
    onBookingOfferTryPublish,
    onBookingOfferTryUnPublish,
    onBookingOfferTryResume,
    onBookingOfferTryRetrieve,
    onBookingOfferTryReturnToVerification,
    onBookingOfferTryReject,
    onBookingOffersChangePageSize,
    onBookingOffersChangePage,
    onBookingOfferTryServiceManage,
    onRefresh,
  };
};
