import { List } from '@mui/material';
import styled from '@emotion/styled';
import { viewConfig } from '../../../theme/viewConfig';

export const Wrapper = styled.div`
  width: ${viewConfig.menuPanelWidth};
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const CommonList = styled(List)`
  flex: 1;

  & .MuiListItemText-primary {
    font-weight: ${p => p.theme.typography.fontWeightSemiBold};
  }

  & .MuiListItemIcon-root {
    margin-right: ${p => p.theme.spacing(2)};

    .MuiSvgIcon-root {
      font-size: 1.25rem;
    }
  }
`;

export const SubMenuList = styled(CommonList)`
  & .MuiListItemIcon-root {
    padding-left: ${p => p.theme.spacing(2)};
  }
`;

export const Footer = styled(List)`
  & .MuiListItemText-primary {
    font-weight: ${p => p.theme.typography.fontWeightSemiBold};
  }

  & .MuiListItemIcon-root {
    margin-right: ${p => p.theme.spacing(2)};

    .MuiSvgIcon-root {
      font-size: 1.25rem;
    }
  }
`;
