export enum EDateFormat {
  Server = 'YYYY-MM-DD',
  DisplayDefault = 'DD.MM.YYYY',
  DisplayShort = 'DD.MM.YY',
  Human = 'D MMMM YYYY',
  HumanShort = 'D MMMM',
  FileName = 'DD_MM_YY',
}

export enum EDateTimeFormat {
  Server = 'YYYY-MM-DDTHH:mm:ss',
  Human = 'D MMMM YYYY в HH:mm',
  HumanShort = 'D MMMM в HH:mm',
  DisplayDefault = 'DD.MM.YYYY - HH:mm',
  LongMs = 'YYYY-MM-DDTHH:mm:ss.SSS',
}

export enum ETimeFormat {
  Default = 'HH:mm',
}
