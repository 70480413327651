import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import TablePagination from '../../../components/common/table/pagination';
import { useContextHandlers } from './hooks/useContextHandlers';
import {
  teamsPageCountSelector,
  teamsPageNumberSelector,
  teamsSearchSelector,
  teamsTotalCountSelector,
} from './store/selectors';

interface TeamTableFooterContainerProps {}

const TeamTableFooterContainer = forwardRef((props: TeamTableFooterContainerProps, ref: any) => {
  const handlers = useContextHandlers();

  const pageNumber = useSelector(teamsPageNumberSelector);
  const search = useSelector(teamsSearchSelector);
  const totalCount = useSelector(teamsTotalCountSelector);
  const pageCount = useSelector(teamsPageCountSelector);

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='команд'
        onChangePageSize={handlers.onChangePageSize}
        onChangePage={handlers.onChangePage}
      />
    </div>
  );
});

export default TeamTableFooterContainer;
