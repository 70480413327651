import { ListItem, ListItemText } from '@mui/material';
import { EUserRole } from '../../../../../../domain/model/enums';
import { AppUser } from '../../../../../../domain/model/user';
import { SportLogoIcon } from '../../../../../icons';
import { getUserRoleName } from '../../../../../utils/roles';
import { LogoIconWrapper, UserType } from './controls';

interface MainMenuHeaderSportProps {
  readonly user: AppUser;
}

const MainMenuHeaderSport = ({ user }: MainMenuHeaderSportProps) => {
  return (
    <ListItem>
      <LogoIconWrapper>
        <SportLogoIcon />
      </LogoIconWrapper>
      <ListItemText
        primary={
          <UserType variant='subtitle2'>{getUserRoleName((user.realm_access?.roles ?? []) as EUserRole[])}</UserType>
        }
      />
    </ListItem>
  );
};

export default MainMenuHeaderSport;
