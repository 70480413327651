import React from 'react';
import { UseSportsmanTableHandlers } from './hooks/useSportsmansTable';
import { UseSportsmansTableConfig } from './hooks/useSportsmansTableConfig';

export type SportsmanTableContextValue = UseSportsmanTableHandlers;

export const SportsmanTableContext = React.createContext<SportsmanTableContextValue>({} as SportsmanTableContextValue);

export type SportsmansTableConfigContextValue = UseSportsmansTableConfig;

export const SportsmansTableConfigContext = React.createContext<SportsmansTableConfigContextValue>(
  {} as SportsmansTableConfigContextValue
);
