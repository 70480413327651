import { AdCampaign, AdCampaignShort, EAdCampaignStatus, Nullable, UUID } from '@/domain';
import { DataTableColumns } from '@components/common/table';
import { AdCampaignActionTableType, EAdCampaignTableColumn, EAdCampaignTableTab } from '../types';

export type AdCampaignActionCall = {
  readonly action: AdCampaignActionTableType;
  readonly adCampaign: AdCampaign | AdCampaignShort;
};

export type AdCampaignTableTabsCounter = {
  [name in EAdCampaignTableTab]?: number;
};

export const adCampaignTableColumns: DataTableColumns<EAdCampaignTableColumn> = {
  [EAdCampaignTableColumn.Name]: {
    title: 'Название кампании',
    width: '15rem',
    sortable: true,
    wrap: true,
  },
  [EAdCampaignTableColumn.Partner]: {
    title: 'Партнер',
    width: '15rem',
    sortable: true,
    wrap: true,
  },
  [EAdCampaignTableColumn.Period]: {
    title: 'Период',
    width: '10rem',
    sortable: true,
  },
  [EAdCampaignTableColumn.OfferCount]: {
    title: 'Количество предложений',
    width: '4rem',
    sortable: true,
  },
  [EAdCampaignTableColumn.Type]: {
    title: 'Тип РК',
    width: '6rem',
    sortable: true,
  },
  [EAdCampaignTableColumn.OfferType]: {
    title: 'Тип предложения',
    width: '6rem',
    sortable: true,
  },
  [EAdCampaignTableColumn.Budget]: {
    title: 'Бюджет кампании',
    width: '10rem',
    sortable: true,
  },
  [EAdCampaignTableColumn.ShowCount]: {
    title: 'Показов',
    width: '4rem',
    sortable: true,
  },
  [EAdCampaignTableColumn.ViewCount]: {
    title: 'Просмотров',
    width: '4rem',
    sortable: true,
  },
};

const adCampaignTableDefaultColumns = [
  EAdCampaignTableColumn.Name,
  EAdCampaignTableColumn.Period,
  EAdCampaignTableColumn.Partner,
  EAdCampaignTableColumn.Type,
  EAdCampaignTableColumn.OfferType,
  EAdCampaignTableColumn.OfferCount,
  EAdCampaignTableColumn.Budget,
  EAdCampaignTableColumn.ShowCount,
  EAdCampaignTableColumn.ViewCount,
];

const getAdCampaignTableDefaultColumns = (
  tab: EAdCampaignTableTab,
  partnerId: Nullable<UUID>
): EAdCampaignTableColumn[] => {
  const columns = adCampaignTableDefaultColumns;

  if (partnerId) {
    columns.splice(columns.indexOf(EAdCampaignTableColumn.Partner), 1);
  }

  return columns;
};

export const getAdCampaignAdminTableColumns = (
  tab: EAdCampaignTableTab,
  partnerId: Nullable<UUID>
): DataTableColumns<EAdCampaignTableColumn> => {
  const defaultColumns = getAdCampaignTableDefaultColumns(tab, partnerId);
  const allColumns: EAdCampaignTableColumn[] = [];

  allColumns.push(
    EAdCampaignTableColumn.Name,
    EAdCampaignTableColumn.Period,
    EAdCampaignTableColumn.Partner,
    EAdCampaignTableColumn.Type,
    EAdCampaignTableColumn.OfferType,
    EAdCampaignTableColumn.OfferCount,
    EAdCampaignTableColumn.Budget,
    EAdCampaignTableColumn.ShowCount,
    EAdCampaignTableColumn.ViewCount
  );

  if (partnerId) {
    allColumns.splice(allColumns.indexOf(EAdCampaignTableColumn.Partner), 1);
  }

  return allColumns.reduce<DataTableColumns<EAdCampaignTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...adCampaignTableColumns[column],
        hidden: !defaultColumns.includes(column),
      },
    };
  }, {});
};

const adCampaignPartnerTableDefaultColumns = [
  EAdCampaignTableColumn.Name,
  EAdCampaignTableColumn.Period,
  EAdCampaignTableColumn.Type,
  EAdCampaignTableColumn.OfferType,
  EAdCampaignTableColumn.OfferCount,
  EAdCampaignTableColumn.Budget,
  EAdCampaignTableColumn.ShowCount,
  EAdCampaignTableColumn.ViewCount,
];

const getAdCampaignPartnerTableDefaultColumns = (): EAdCampaignTableColumn[] => {
  return adCampaignPartnerTableDefaultColumns;
};

export const getAdCampaignPartnerTableColumns = (): DataTableColumns<EAdCampaignTableColumn> => {
  const defaultColumns = getAdCampaignPartnerTableDefaultColumns();

  const allColumns: EAdCampaignTableColumn[] = [];

  allColumns.push(
    EAdCampaignTableColumn.Name,
    EAdCampaignTableColumn.Period,
    EAdCampaignTableColumn.Type,
    EAdCampaignTableColumn.OfferType,
    EAdCampaignTableColumn.OfferCount,
    EAdCampaignTableColumn.Budget,
    EAdCampaignTableColumn.ShowCount,
    EAdCampaignTableColumn.ViewCount
  );

  return allColumns.reduce<DataTableColumns<EAdCampaignTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...adCampaignTableColumns[column],
        hidden: !defaultColumns.includes(column),
      },
    };
  }, {});
};

export const getStatesFilterForAdCampaignTableTab = (
  tab: EAdCampaignTableTab,
  defaultStatuses: EAdCampaignStatus[]
): EAdCampaignStatus[] => {
  switch (tab) {
    case EAdCampaignTableTab.Active:
      return [EAdCampaignStatus.Active];

    case EAdCampaignTableTab.Archived:
      return [EAdCampaignStatus.Archived];

    case EAdCampaignTableTab.Upcoming:
      return [EAdCampaignStatus.Upcoming];

    case EAdCampaignTableTab.Paused:
      return [EAdCampaignStatus.Paused];

    default: {
      return defaultStatuses;
    }
  }
};

export const getAdCampaignTableTabName = (tab: EAdCampaignTableTab) => {
  switch (tab) {
    case EAdCampaignTableTab.Active:
      return 'Активные';
    case EAdCampaignTableTab.Paused:
      return 'Приостановленные';
    case EAdCampaignTableTab.Upcoming:
      return 'Предстоящие';
    case EAdCampaignTableTab.Archived:
      return 'Архив';
  }
};
