import { FC } from 'react';
import { FileDescription } from '../../../../../domain/model';
import { Nullable } from '../../../../../domain/model/types';
import { EEntityPreviewMode } from '../../../../types';
import { OfferPreviewCaption, OfferPreviewSkeleton } from '../../../general/offer/components/preview/controls';
import OfferPreviewLogo from '../../../general/offer/components/preview/logo';
import { Footer } from './controls';

type TradeOfferPreviewFooterProps = {
  readonly mode: EEntityPreviewMode;
  readonly partnerName: Nullable<string>;
  readonly logo: Nullable<string | FileDescription>;
};

const TradeOfferPreviewFooter: FC<TradeOfferPreviewFooterProps> = props => {
  const { mode, partnerName, logo } = props;
  return (
    <Footer>
      {partnerName ? (
        <OfferPreviewCaption
          variant={mode === EEntityPreviewMode.Mobile ? 'caption' : 'body2'}
          color='textSecondary'
        >
          {partnerName}
        </OfferPreviewCaption>
      ) : (
        <OfferPreviewSkeleton
          animation={false}
          width='80%'
          height={mode === EEntityPreviewMode.Mobile ? '1rem' : '1.25rem'}
        />
      )}
      <OfferPreviewLogo
        mode={mode}
        image={logo}
      />
    </Footer>
  );
};

export default TradeOfferPreviewFooter;
