import { Grid, Typography } from '@mui/material';

const PartnerDetailsAdminDeskStub = () => {
  return (
    <Grid
      container
      direction='column'
      spacing={4}
    >
      <Grid
        item
        xs={12}
      >
        <Typography>Карточка компании не заполнена</Typography>
      </Grid>
    </Grid>
  );
};

export default PartnerDetailsAdminDeskStub;
