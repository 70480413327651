import { useCallback, useMemo } from 'react';
import { BackIcon } from '../../../../../icons';
import { EPanelActionPosition, PanelAction } from '../../../../../types';
import NavAdapter, { ENavStepAction } from '../index';

interface UseNavAdapterProps {
  readonly stepsCount: number;
  readonly currentStepIndex: number;
  readonly openPrevStep: () => void;
  readonly openNextStep?: (() => void) | false;
}

interface UseNavAdapterResult {
  readonly actions: PanelAction<ENavStepAction>[];
  readonly adapter: JSX.Element;
}

const getNextStepPanelAction = (disabled: boolean): PanelAction<ENavStepAction> => ({
  label: 'Следующий шаг',
  type: ENavStepAction.NextStep,
  position: [EPanelActionPosition.Default],
  disabled,
});

const getBackStepPanelAction = (disabled: boolean): PanelAction<ENavStepAction> => ({
  label: <BackIcon />,
  type: ENavStepAction.Back,
  position: [EPanelActionPosition.Default],
  disabled,
});

const useNavAdapter = (props: UseNavAdapterProps): UseNavAdapterResult => {
  const { stepsCount, currentStepIndex, openNextStep, openPrevStep } = props;
  const nextStepIndex = Math.min(currentStepIndex + 1, stepsCount - 1);
  const isLastStep = currentStepIndex === stepsCount - 1;
  const showNextNav = !isLastStep && nextStepIndex + 1;
  const showBackNav = currentStepIndex !== 0;

  const navActions: PanelAction<ENavStepAction>[] = useMemo(() => {
    const result = [];

    if (showNextNav) {
      result.unshift(getNextStepPanelAction(!openNextStep));
    }

    if (showBackNav) {
      result.unshift(getBackStepPanelAction(false));
    }

    return result;
  }, [showNextNav, showBackNav, openNextStep]);

  const onNavAction = useCallback(
    (action: PanelAction<ENavStepAction>) => {
      switch (action.type) {
        case ENavStepAction.NextStep:
          openNextStep && openNextStep();
          break;
        case ENavStepAction.Back:
          openPrevStep && openPrevStep();
      }
    },
    [openNextStep, openPrevStep]
  );

  const navAdapter = useMemo(
    () => (
      <NavAdapter
        actions={navActions}
        onNavAction={onNavAction}
      />
    ),
    [navActions, onNavAction]
  );

  return {
    actions: navActions,
    adapter: navAdapter,
  };
};

export default useNavAdapter;
