import React from 'react';
import { RootState } from '@/data/store/store';
import { Nullable, SportOption, TradeOffer, UUID } from '@/domain';
import { DialogData, TradeOffersActionsState } from './store/slice';
import { EOfferActionType } from '@features/general/offer/types';

type TradeOfferTypes = TradeOffer;

export interface TradeOfferActionsSelectors {
  readonly getIsOfferProcessingSelector: (id: Nullable<UUID>) => (store: RootState) => boolean;
  readonly getIsOfferExecutedActionsSelector: (
    id: Nullable<UUID>,
    ...actions: EOfferActionType[]
  ) => (store: RootState) => Nullable<EOfferActionType>;
  readonly isTradeOffersAnyChangedSelector: (store: RootState) => number;
}

export type OpenDialogProps = {
  data: TradeOfferTypes;
  notification?: boolean;
};

export interface TradeOfferActionsContextType {
  readonly onReject: (
    offer: TradeOffer,
    reason: SportOption,
    comment?: string,
    notification?: boolean
  ) => Promise<Nullable<TradeOffer>>;
  readonly onRetrieve: (offer: TradeOffer, endDate: string, notification?: boolean) => Promise<Nullable<TradeOffer>>;
  readonly onResume: (offer: TradeOffer, notification?: boolean) => Promise<Nullable<TradeOffer>>;
  readonly onInWork: (offer: TradeOffer, notification?: boolean) => Promise<TradeOffer>;
  readonly onPause: (
    offer: TradeOffer,
    reason?: SportOption,
    comment?: string,
    notification?: boolean
  ) => Promise<Nullable<TradeOffer>>;
  readonly onCloseDialog: (name: keyof TradeOffersActionsState['dialogs']) => void;
  readonly onChangeDialogState: (name: keyof TradeOffersActionsState['dialogs'], data: DialogData<TradeOffer>) => void;
  readonly onReturnToVerification: (offer: TradeOffer, notification?: boolean) => Promise<Nullable<TradeOffer>>;
  readonly onDuplicate: (offer: TradeOffer, notification?: boolean) => Promise<Nullable<TradeOffer>>;
  readonly onDelete: (id: UUID, notification?: boolean) => Promise<Nullable<boolean>>;
  readonly onDeleteCodes: (id: UUID, notification?: boolean) => Promise<TradeOffer>;
  readonly onSave: (offer: TradeOffer, notification?: boolean) => Promise<Nullable<TradeOffer>>;
  readonly onArchive: (id: UUID, notification?: boolean) => Promise<Nullable<TradeOffer>>;
  readonly onApprove: (id: UUID, notification?: boolean) => Promise<Nullable<TradeOffer>>;
  readonly onChangeModerator: (id: UUID, userId: UUID, notification?: boolean) => Promise<Nullable<TradeOffer>>;
  readonly onUnPublish: (id: UUID, notification?: boolean) => Promise<Nullable<TradeOffer>>;
  readonly onSaveNewVersion: (offer: TradeOffer, notification?: boolean) => Promise<Nullable<TradeOffer>>;
  readonly onPublish: (id: UUID, notification?: boolean) => Promise<Nullable<TradeOffer>>;


  readonly onTryReject: (props: OpenDialogProps) => void;
  readonly onTryApprove: (props: OpenDialogProps) => void;
  readonly onTryReturnToVerification: (props: OpenDialogProps) => void;
  readonly onTryDuplicate: (props: OpenDialogProps) => void;
  readonly onTryDelete: (props: OpenDialogProps) => void;
  readonly onTryDeleteCodes: (props: OpenDialogProps) => void;
  readonly onTryCreateCodes: (props: OpenDialogProps) => void;
  readonly onTryArchive: (props: OpenDialogProps) => void;
  readonly onTryUnPublish: (props: OpenDialogProps) => void;
  readonly onTryChangeModerator: (props: OpenDialogProps) => void;
  readonly onTryPause: (props: OpenDialogProps) => void;
  readonly onTryPublish: (props: OpenDialogProps) => void;
  readonly onTryRetrieve: (props: OpenDialogProps) => void;
  readonly onTryResume: (props: OpenDialogProps) => void;

  readonly utils: {
    readonly selectors: TradeOfferActionsSelectors;
  };
}

const TradeOfferActionsContext = React.createContext<TradeOfferActionsContextType>({} as TradeOfferActionsContextType);

export default TradeOfferActionsContext;
