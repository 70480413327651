import { ClickAwayListener, Grow, MenuItem, MenuList, Paper } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import { PanelAction, PanelActions } from '../../../types';
import { MenuPopper } from './controls';
import ActionItem from './item';

interface ActionGroupProps<A extends string> {
  readonly id: string;
  readonly primary?: boolean;
  readonly disabled?: boolean;
  readonly actions: PanelActions<A>;
  readonly children: React.ReactNode;
  readonly onAction: (action: PanelAction<A>) => void;
}

const ActionGroup = <A extends string>(props: ActionGroupProps<A>) => {
  const { id, primary, disabled, actions, children, onAction } = props;

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const onClickAway = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      setOpen(false);
    }
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
      anchorRef.current?.focus();
    }
  };

  const onSetSameSize = ({ state }: any) => {
    const sizeName = state.placement.startsWith('left') || state.placement.startsWith('right') ? 'height' : 'width';
    const popperSize = state.rects.popper[sizeName];
    const referenceSize = state.rects.reference[sizeName];
    if (popperSize >= referenceSize) return;

    state.styles.popper[sizeName] = `${referenceSize}px`;
  };
  return (
    <>
      <ActionItem
        ref={anchorRef}
        primary={primary}
        disabled={disabled}
        endIcon={<KeyboardArrowDownIcon />}
        onExecute={handleToggle}
      >
        {children}
      </ActionItem>
      <MenuPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={'bottom-start'}
        transition
        disablePortal
        modifiers={[
          {
            name: 'offset',
            phase: 'main',
            options: {
              offset: [0, 4], // [skidding, distance]
            },
          },
          {
            name: 'sameWidth',
            enabled: true,
            phase: 'beforeWrite',
            requires: ['computeStyles'],
            fn: onSetSameSize,
          },
        ]}
        onKeyDown={onKeyDown}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper
              variant={'outlined'}
              onKeyDown={onKeyDown}
            >
              <ClickAwayListener onClickAway={onClickAway}>
                <MenuList
                  id={id}
                  autoFocusItem
                  onKeyDown={onKeyDown}
                >
                  {actions.map(action => (
                    <MenuItem
                      key={action.type}
                      disabled={action.disabled}
                      onClick={() => {
                        onAction(action);
                        setOpen(false);
                      }}
                    >
                      {action.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </MenuPopper>
    </>
  );
};
export default ActionGroup;
