import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CmsContainerTableCellCommonProps } from '../../../components/containerTableCell';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { cmsContainersCmsContainerByIndexSelector } from '../store/selectors';

export type CmsContainersTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<CmsContainerTableCellCommonProps>;
};

const CmsContainersTableCellAdapter = (props: CmsContainersTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const handlers = useContextHandlers();
  const cmsContainer = useSelector(cmsContainersCmsContainerByIndexSelector(index));
  const isDisabled = cmsContainer ? handlers.isCmsContainerDisabled(cmsContainer) : false;

  return useMemo(
    () =>
      cmsContainer ? (
        <Component
          cmsContainer={cmsContainer}
          isDisabled={isDisabled}
        />
      ) : null,
    [Component, cmsContainer, isDisabled]
  );
};

export default CmsContainersTableCellAdapter;
