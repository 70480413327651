import { Breadcrumbs as MuiBreadcrumbs, BreadcrumbsProps } from '@mui/material';
import styled from '@emotion/styled';

interface StyledBreadcrumbsProps extends BreadcrumbsProps {
  readonly dense?: boolean;
}

export const StyledBreadcrumbs = styled(({ dense, ...others }: StyledBreadcrumbsProps) => (
  <MuiBreadcrumbs {...others} />
))<StyledBreadcrumbsProps>`
  padding-top: ${p => p.theme.spacing(p.dense ? 0 : 1)};
`;
