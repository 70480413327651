import styled from '@emotion/styled';
import { viewConfig } from '../../../../../theme/viewConfig';

export const Wrapper = styled.div`
  position: relative;

  width: ${viewConfig.cms.preview.detailsWidth};

  padding: ${p => p.theme.spacing(5)};

  border: 1px dashed ${p => p.theme.palette.secondary.main};
  border-radius: ${p => (p.theme.shape.borderRadius as number) * 3}px;
  background-color: ${p => p.theme.palette.background.default};

  display: flex;
  justify-content: center;
  align-items: center;

  pointer-events: none;
`;

export const CarouselWrapper = styled.div`
  width: 90%;

  aspect-ratio: 2.2;

  margin: 0 auto;
`;

export const Label = styled.div`
  position: absolute;
  top: calc(-${p => p.theme.typography.body2.lineHeight} / 2);
  left: ${p => p.theme.spacing(4.5)};

  padding: 0 ${p => p.theme.spacing(0.5)};
  background-color: ${p => p.theme.palette.background.default};

  & .MuiTypography-root {
    font-weight: ${p => p.theme.typography.fontWeightSemiBold};
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;
