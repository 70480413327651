import { Grid, Typography } from '@mui/material';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import Splitter from '../../../../components/common/splitter';
import { Breadcrumbs } from '../../../general/breadcrumbs/types';
import { BreadcrumbsWrapper, TitleWrapper, Wrapper } from './controls';

interface SupportInfoDetailsSportHeaderProps {
  readonly breadcrumbs: Breadcrumbs;
}

const SupportInfoDetailsSportHeader = ({ breadcrumbs }: SupportInfoDetailsSportHeaderProps) => {
  return (
    <Wrapper
      container
      spacing={1}
      direction='row'
      justifyContent='space-between'
      alignItems='baseline'
    >
      <Grid
        item
        xs={12}
      >
        <BreadcrumbsWrapper>
          {breadcrumbs.length > 0 && (
            <>
              <AppBreadcrumbs />
              <Splitter
                variant='vertical'
                size={3}
              />
            </>
          )}
        </BreadcrumbsWrapper>
      </Grid>
      <Grid item>
        <TitleWrapper>
          <Typography
            noWrap
            variant='h2'
          >
            Служба поддержки
          </Typography>
        </TitleWrapper>
      </Grid>
    </Wrapper>
  );
};

export default SupportInfoDetailsSportHeader;
