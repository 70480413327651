import { useCallback, useMemo } from 'react';
import { UUID } from '../../../../../domain/model/types';
import { MpUser } from '../../../../../domain/model/user';
import {
  EPartnerEmployeeActionType,
  GetPartnerEmployeeActionsByPermissions,
  PartnerEmployeeActions,
  PartnerEmployeeActionTableType,
} from '../../types';
import { EPartnerEmployeeTableTab, getPartnerEmployeeTableTabActions } from '../utils';

type ActionsType = PartnerEmployeeActions<PartnerEmployeeActionTableType>;

type UsePartnerEmployeeTableActions = {
  readonly getPartnerEmployeeActions: (partnerEmployee: MpUser) => ActionsType;
};

interface UsePartnerEmployeeTableActionsProps extends GetPartnerEmployeeActionsByPermissions {
  readonly tab: EPartnerEmployeeTableTab;
  readonly responsibleId: UUID;
}

const usePartnerEmployeeTableActions = (props: UsePartnerEmployeeTableActionsProps): UsePartnerEmployeeTableActions => {
  const { canCreate, canEdit, canSave, canActivate, canDeactivate, canChangeResponsible, tab, responsibleId } = props;

  const tableTabActions = useMemo<ActionsType>(
    () =>
      getPartnerEmployeeTableTabActions(
        {
          canCreate,
          canEdit,
          canSave,
          canActivate,
          canDeactivate,
          canChangeResponsible,
        },
        tab
      ),
    [canCreate, canEdit, canSave, canActivate, canDeactivate, canChangeResponsible, tab]
  );

  const getPartnerEmployeeActions = useCallback(
    (partnerEmployee: MpUser) => {
      return tableTabActions.map(action => ({
        ...action,
        disabled: action.type === EPartnerEmployeeActionType.ChangeResponsible && partnerEmployee.id === responsibleId,
      }));
    },
    [tableTabActions, responsibleId]
  );

  return {
    getPartnerEmployeeActions,
  };
};

export default usePartnerEmployeeTableActions;
