import TabsPanelLabel from '@/presentation/components/common/tabs/label/label';
import { EAnalyticsQueriesTableTab, getAnalyticsQueriesTableTabName } from '@features/analytics/query/table/utils';

interface AnalyticsQueriesTableTabProps {
  readonly tab: EAnalyticsQueriesTableTab;
}

const AnalyticsQueriesTableTab = ({ tab }: AnalyticsQueriesTableTabProps) => {
  return <TabsPanelLabel title={getAnalyticsQueriesTableTabName(tab)}></TabsPanelLabel>;
};

export default AnalyticsQueriesTableTab;
