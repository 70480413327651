import { Fade, Grid } from '@mui/material';
import { ReactNode } from 'react';
import Splitter from '../../../components/common/splitter';
import { DefaultContentWrapper } from '../../../components/common/wrappers/content';
import { ColumnWrapper, ContainerWrapper, HeaderWrapper, Wrapper } from './controls';

interface SupportInfoMpLayoutProps {
  readonly header: ReactNode;
  readonly contacts: ReactNode;
  readonly documents: ReactNode;
}

const SupportInfoMpLayout = (props: SupportInfoMpLayoutProps) => {
  const { header, contacts, documents } = props;
  return (
    <Fade in>
      <DefaultContentWrapper
        type='details'
        stickyHeader
      >
        <ContainerWrapper>
          <HeaderWrapper>{header}</HeaderWrapper>
          <Splitter
            variant='horizontal'
            size={2}
          />
          <Wrapper>
            <ColumnWrapper>
              <Grid
                container
                spacing={5}
                direction='column'
              >
                <Grid item>{contacts}</Grid>
                <Grid item>{documents}</Grid>
              </Grid>
            </ColumnWrapper>
          </Wrapper>
        </ContainerWrapper>
      </DefaultContentWrapper>
    </Fade>
  );
};

export default SupportInfoMpLayout;
