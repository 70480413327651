import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FCC, useEffect, useState } from 'react';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { Wrapper } from './controls';

interface TableScrollbarOverlayProps {
  readonly bottomAnchor?: HTMLElement;
}

const TableScrollbarOverlay: FCC<TableScrollbarOverlayProps> = props => {
  const { bottomAnchor, children } = props;

  const [, setAnchorDimensionsChanged] = useState<symbol>(Symbol());

  const windowParams = useWindowResize();

  useEffect(() => {
    if (bottomAnchor) {
      const observer = new ResizeObserver(() => {
        setAnchorDimensionsChanged(Symbol());
      });
      observer.observe(bottomAnchor);
      return () => bottomAnchor && observer.unobserve(bottomAnchor);
    }
  }, [bottomAnchor]);

  useEffect(() => {
    setAnchorDimensionsChanged(Symbol());
  }, [windowParams]);

  return (
    <Wrapper bottomAnchor={bottomAnchor}>
      <OverlayScrollbarsComponent
        options={{
          overflowBehavior: { x: 'scroll', y: 'hidden' },
          scrollbars: {
            clickScrolling: true,
            dragScrolling: true,
            touchSupport: true,
          },
        }}
      >
        {children}
      </OverlayScrollbarsComponent>
    </Wrapper>
  );
};

export default TableScrollbarOverlay;
