import { Grid } from '@mui/material';
import styled from '@emotion/styled';

export const ContainerWrapper = styled.div`
  height: 100%;
`;

export const TabsWrapper = styled(Grid)`
  width: 100%;
`;

export const TableWrapper = styled.div`
  height: 100%;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;
