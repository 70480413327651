import { CorpOfferActivation } from '@/domain/model/activation';
import { EOfferActivationStatus } from '@/domain/model/enums';
import { PanelActions } from '../../types';

export enum ECorpActivationTableColumn {
  CreateAt = 'createdAt',
  OfferCode = 'offer.code',
  OfferName = 'offer.name',
  Status = 'status',
  PromotionType = 'promotionType',
  Partner = 'offer.partner.name',
  PromotionCode = 'promotion.code',
  Price = 'price',
  CustomerPernr = 'customer.pernr',
  CustomerName = 'customer.fullName',
  CustomerEmail = 'customer.email',
  CustomerPhone = 'customer.phone',
  GivenBy = 'givenBy.fullName',
  GivenAt = 'givenAt',
}

export enum ECorpActivationTableTab {
  Pending = 'PENDING',
  Approved = 'APPROVED',
}

export enum ECorpActivationActionType {
  /** Выдан клиенту  */
  Give = 'give',
  /** Отменена выдача (перевод в статус approved) */
  UnGive = 'unGive',
}

export type CorpActivationActionTableType = ECorpActivationActionType;

export type CorpActivationCounterByStatus = {
  [name in EOfferActivationStatus]?: number;
};

export type ActionCorpActivationType = CorpOfferActivation;

export type CorpActivationActions<A extends string = ECorpActivationActionType> = PanelActions<A>;
