import CmsContainerTableCell, { CmsContainerTableCellCommonProps } from './index';

type CmsContainerTableCellNameProps = CmsContainerTableCellCommonProps;

const CmsContainerTableCellName = (props: CmsContainerTableCellNameProps) => {
  const {
    cmsContainer: { name },
  } = props;

  const value = name ?? '-';

  return (
    <CmsContainerTableCell
      {...props}
      value={value}
    />
  );
};

export default CmsContainerTableCellName;
