import { Grid, Typography } from '@mui/material';
import { Team } from '../../../../../domain/model/team';
import { TeamAvatar } from '../../../../components/common/avatars';
import BackButton from '../../../../components/common/buttons/back';
import { MPButton } from '../../../../theme/ui-kit/button';

interface TeamCreateHeaderProps {
  readonly team: Team;
  readonly isSaving: boolean;
  readonly onLogoChangeClick: () => void;
}

const TeamCreateHeader = ({ team, isSaving, onLogoChangeClick }: TeamCreateHeaderProps) => {
  return (
    <Grid
      container
      spacing={2}
      direction='column'
    >
      <Grid item>
        <BackButton label='Добавление команды' />
      </Grid>
      <Grid
        item
        container
        spacing={2}
        wrap='nowrap'
        alignItems='center'
      >
        <Grid item>
          <TeamAvatar
            size='largest'
            team={team}
          />
        </Grid>
        <Grid item>
          <MPButton
            fullWidth={false}
            variant='text'
            disabled={isSaving}
            onClick={onLogoChangeClick}
          >
            <Typography
              noWrap
              color='primary'
              variant='subtitle2'
            >
              Выбрать эмблему
            </Typography>
          </MPButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TeamCreateHeader;
