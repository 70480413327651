import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { BookingOfferDetailsStep } from '../../types';
import { bookingOfferDetailsSetUiState } from '../store/slice';
import { BookingOfferDetailsUiState } from '../types';
import { getBookingOfferDetailsSteps, getBookingOfferDetailsStepText } from '../utils';

export const BookingOfferDetailsUiStepsState = () => {
  const dispatch = useDispatch();

  const value = useMemo<BookingOfferDetailsUiState['steps']>(() => {
    const steps: BookingOfferDetailsStep[] = getBookingOfferDetailsSteps().map(key => ({
      key,
      label: getBookingOfferDetailsStepText(key),
    }));

    return steps;
  }, []);

  useEffect(() => {
    dispatch(bookingOfferDetailsSetUiState({ name: 'steps', value }));
  }, [dispatch, value]);

  return null;
};
