import { useReportDownloads } from '../useDownloads';
import { EReportType } from '@features/report/types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { reportDownloadsReportsSelector } from '../store/selectors';
import { EventsReportContainer } from '../reports/events';

export const ReportDownloadsEventsAdapter = () => {
  const handlers = useReportDownloads();

  const { events: open } = useSelector(reportDownloadsReportsSelector);

  const onClose = useCallback(() => {
    handlers.onCloseDownloadDialog(EReportType.Events);
  }, [handlers.onCloseDownloadDialog]);

  return open && <EventsReportContainer onClose={onClose} />;
};
