import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isCorpActivationsAnyChangedSelector } from '../store/selectors';
import { corpActivationActionsOptimize } from '../store/slice';

const CorpActivationActionsOptimizerAdapter = () => {
  const dispatch = useDispatch();
  const needOptimize = useSelector(isCorpActivationsAnyChangedSelector);

  useEffect(() => {
    if (needOptimize > 0) {
      dispatch(corpActivationActionsOptimize());
    }
  }, [needOptimize]);

  return null;
};

export default CorpActivationActionsOptimizerAdapter;
