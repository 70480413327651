import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { OfferCategory } from '../../../../../../domain/model';
import { DictionaryDeleteDialog } from '../../../../../components/common/dictionary/dialog/delete';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { tradeOfferCategoriesEditDeleteDialogStateSelector } from '../store/selectors';

interface TradeOfferCategoriesEditDialogDeleteAdapterInternalProps {
  readonly data: OfferCategory;
}

const TradeOfferCategoriesEditDialogDeleteAdapterInternal = (
  props: TradeOfferCategoriesEditDialogDeleteAdapterInternalProps
) => {
  const { data } = props;

  const handlers = useContextHandlers();

  const [isLoading, setIsLoading] = useState(false);

  const onDelete = async (tradeOfferCategory: OfferCategory) => {
    setIsLoading(true);
    await handlers
      .onDelete(tradeOfferCategory)
      .then(() => {
        handlers.onChangeDialogState('delete', null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClose = () => {
    setIsLoading(false);
    handlers.onChangeDialogState('delete', null);
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return (
    <DictionaryDeleteDialog
      isLoading={isLoading}
      onClose={onClose}
      onDelete={() => onDelete(data)}
    >
      Вы уверены, что хотите удалить <b>«{data.name}»</b>?
    </DictionaryDeleteDialog>
  );
};

export const TradeOfferCategoriesEditDialogDeleteAdapter = () => {
  const data = useSelector(tradeOfferCategoriesEditDeleteDialogStateSelector);

  if (!data) {
    return null;
  }

  return <TradeOfferCategoriesEditDialogDeleteAdapterInternal data={data.data} />;
};
