import { SportOptionTyped } from '../../../../../../../domain/model';
import { EPartnerOwnershipType, ETaxationSystemType } from '../../../../../../../domain/model/enums';
import { PartnerDraft } from '../../../../../../../domain/model/partner';
import PartnerApplicationViewCompanyDetails from './company';
import PartnerApplicationViewIndividualDetails from './individual';

interface PartnerApplicationViewStepDetailsProps {
  readonly partnerApplication: PartnerDraft;
  readonly taxationSystemTypes: SportOptionTyped<ETaxationSystemType>[];
}

const PartnerApplicationViewStepDetails = (props: PartnerApplicationViewStepDetailsProps) => {
  const { partnerApplication, taxationSystemTypes } = props;

  const { bic, checkingAccount, taxSystem } = partnerApplication;

  if (!partnerApplication.regInfo?.orgType) {
    return <></>;
  }
  switch (partnerApplication.regInfo?.orgType) {
    case EPartnerOwnershipType.IndividualEntrepreneur:
      return (
        <PartnerApplicationViewIndividualDetails
          bic={bic}
          checkingAccount={checkingAccount}
          ogrnip={partnerApplication.regInfo?.ogrnip ?? null}
          taxSystem={taxSystem}
          taxationSystemTypes={taxationSystemTypes}
        />
      );
    case EPartnerOwnershipType.StockCompany:
    case EPartnerOwnershipType.PublicJoinStockCompany:
    case EPartnerOwnershipType.PK:
    case EPartnerOwnershipType.UP:
    case EPartnerOwnershipType.FGBNU:
    case EPartnerOwnershipType.NonProfitOrganization:
    case EPartnerOwnershipType.ClosedJoinStockCompany:
    case EPartnerOwnershipType.JoinStockCompany:
    case EPartnerOwnershipType.NAO:
    case EPartnerOwnershipType.AutonomousNonProfitOrganization:
    case EPartnerOwnershipType.LimitedLiabilityCompany: {
      return (
        <PartnerApplicationViewCompanyDetails
          bic={bic}
          checkingAccount={checkingAccount}
          kpp={partnerApplication.regInfo?.kpp ?? null}
          ogrn={partnerApplication.regInfo?.ogrn ?? null}
          taxSystem={taxSystem}
          taxationSystemTypes={taxationSystemTypes}
        />
      );
    }
    default: {
      return <></>;
    }
  }
};
export default PartnerApplicationViewStepDetails;
