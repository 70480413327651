import api from '@/data/api';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable } from '@/data/store/types';
import { Nullable } from '@/domain';
import { ValidationResult } from '@/presentation/utils/validation';
import { CodeValue } from '@features/requiredAction/confirmCode/types';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export interface RequiredActionConfirmCodeState extends Fetchable {
  readonly code: string;
  readonly validation: ValidationResult<CodeValue>;
}

type Reducer<T = undefined> = CaseReducer<RequiredActionConfirmCodeState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<RequiredActionConfirmCodeState> {
  readonly confirmCodeResetData: Reducer;
  readonly confirmCodeSetCode: Reducer<string>;
  readonly confirmCodeSetValidation: Reducer<Nullable<ValidationResult<CodeValue>>>;
  readonly confirmCodeClearValidation: Reducer;
}

export const confirmCodeSendCode = createAsyncThunk<any, { code: string; otpId: UUID }, AppThunkAPIConfig>(
  'requiredAction/confirmCode/send',
  async ({ code, otpId }, { rejectWithValue }) => {
    try {
      return (await api.user.mp.sendCode({ code, otpId })).data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

const initialState: RequiredActionConfirmCodeState = {
  code: '',
  validation: {},
  isFailed: false,
  isFetching: false,
  isFetched: false,
};

const slice = createSlice<RequiredActionConfirmCodeState, Reducers>({
  name: 'confirmCode',
  initialState,
  reducers: {
    confirmCodeResetData: (state, { payload }) => {
      return initialState;
    },
    confirmCodeSetCode: (state, { payload }) => {
      state.code = payload;
    },
    confirmCodeSetValidation: (state, { payload }) => {
      state.validation = payload ?? {};
    },
    confirmCodeClearValidation: state => {
      state.validation = {};
    },
  },
  extraReducers: builder => {
    builder
      .addCase(confirmCodeSendCode.pending, state => {
        state.isFetched = false;
        state.isFetching = true;
        state.isFailed = false;
      })
      .addCase(confirmCodeSendCode.fulfilled, state => {
        state.isFetched = true;
        state.isFetching = false;
        state.isFailed = false;
      })
      .addCase(confirmCodeSendCode.rejected, state => {
        state.isFetched = false;
        state.isFetching = false;
        state.isFailed = true;
      });
  },
});

export const { confirmCodeResetData, confirmCodeSetCode, confirmCodeSetValidation, confirmCodeClearValidation } =
  slice.actions;
export default slice.reducer;
