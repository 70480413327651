import { Grid } from '@mui/material';
import { FileDescription } from '../../../../../domain/model';
import { EDateFormat } from '../../../../../domain/model/formats';
import { TradeOffer } from '../../../../../domain/model/tradeOffer';
import { Nullable } from '../../../../../domain/model/types';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';
import { MPButton } from '../../../../theme/ui-kit/button';
import { utcToLocalTimeZone } from '../../../../utils/dates';
import { EOfferTableNotificationType } from '../../../general/offer/enums';
import TradeOfferPreview from '../../components/preview';

interface TradeOfferTableNotifierProps {
  readonly tradeOffer: TradeOffer;
  readonly partnerLogo: Nullable<string | FileDescription>;
  readonly type: Nullable<EOfferTableNotificationType>;
  readonly onRead: () => void;
}

const TradeOfferTableNotifier = (props: TradeOfferTableNotifierProps) => {
  const { tradeOffer, partnerLogo, type, onRead } = props;

  switch (type) {
    case EOfferTableNotificationType.Published:
      const publishedText = `Вы успешно создали торговое предложение. Сейчас оно ушло на модерацию, это займет не более суток. 
      Мы оповестим вас по почте как только все проверим.`;
      return (
        <ConfirmDialog
          open
          title='Торговое предложение отправлено на проверку'
          dialogButtons={
            <Grid container>
              <Grid item>
                <MPButton onClick={onRead}>Отлично</MPButton>
              </Grid>
            </Grid>
          }
          text={publishedText}
        >
          <TradeOfferPreview
            border
            logo={partnerLogo}
            tradeOffer={tradeOffer}
            partner={tradeOffer.partner}
          />
        </ConfirmDialog>
      );
    case EOfferTableNotificationType.Approved:
    case EOfferTableNotificationType.Resumed:
      const endDate = tradeOffer.endDate && utcToLocalTimeZone(tradeOffer.endDate).format(EDateFormat.DisplayDefault);
      return (
        <ConfirmDialog
          open
          title='Торговое предложение опубликовано'
          dialogButtons={
            <Grid container>
              <Grid item>
                <MPButton onClick={onRead}>Отлично</MPButton>
              </Grid>
            </Grid>
          }
          text={`Торговое предложение размещено в витрине до ${endDate}`}
        >
          <TradeOfferPreview
            border
            logo={partnerLogo}
            tradeOffer={tradeOffer}
            partner={tradeOffer.partner}
          />
        </ConfirmDialog>
      );
    case EOfferTableNotificationType.Rejected:
      return (
        <ConfirmDialog
          open
          title='Торговое предложение отклонено'
          dialogButtons={
            <Grid container>
              <Grid item>
                <MPButton onClick={onRead}>Отлично</MPButton>
              </Grid>
            </Grid>
          }
          text={`Торговое предложение ${tradeOffer.id} ${tradeOffer.name} отклонено.`}
        />
      );
    default:
      return null;
  }
};

export default TradeOfferTableNotifier;
