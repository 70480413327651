import { FCC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PanelAction } from '../../../../types';
import { useAdCampaignActions } from '../../actions/useActions';
import { AdCampaignActionCreateType, EAdCampaignActionType } from '../../types';
import { getAdCampaignActionsConfigByPermissions } from '../../utils/actions';
import { AdCampaignCreateContainerProps } from '../container';
import { AdCampaignCreateLifecycleContext, AdCampaignCreateLifecycleContextValue } from '../context';
import { useContextHandlers } from '../hooks/useContextHandlers';
import useAdCampaignCreateActions from '../hooks/useCreateActions';
import { adCampaignCreateAdCampaignFirLifeCycleSelector } from '../store/selectors';

export const AdCampaignCreateLifecycleProvider: FCC<AdCampaignCreateContainerProps> = ({
  children,
  step,
  lifecycle,
  access,
}) => {
  const handlers = useContextHandlers();

  const {
    utils: {
      selectors: { getIsAdCampaignExecutedActionsSelector },
    },
  } = useAdCampaignActions();

  const adCampaign = useSelector(adCampaignCreateAdCampaignFirLifeCycleSelector);

  const isActionsForClose = useSelector(
    getIsAdCampaignExecutedActionsSelector(
      adCampaign.id,
      EAdCampaignActionType.Edit,
      EAdCampaignActionType.Resume,
      EAdCampaignActionType.Archive,
      EAdCampaignActionType.Pause
    )
  );

  const allowedActions = getAdCampaignActionsConfigByPermissions({
    ...access,
  });

  const lifeCycleRules = lifecycle.build({ allowedActions, obj: adCampaign });

  const actions = useAdCampaignCreateActions({
    lifeCycleRules,
  });

  const onAction = async (action: PanelAction<AdCampaignActionCreateType>) => {
    const { type } = action;
    switch (type) {
      case EAdCampaignActionType.Edit:
        handlers.onAdCampaignTrySave();
        break;

      case EAdCampaignActionType.Archive:
        handlers.onAdCampaignTryArchive();
        break;

      case EAdCampaignActionType.Resume:
        handlers.onAdCampaignTryResume();
        break;
      case EAdCampaignActionType.Pause:
        handlers.onAdCampaignTryPause();
        break;
    }
  };

  useEffect(() => {
    if (isActionsForClose) {
      handlers.onClose();
    }
  }, [isActionsForClose, handlers.onClose]);

  const value: AdCampaignCreateLifecycleContextValue = {
    lifecycle,
    lifeCycleRules,
    actions,
    onAction,
  };

  return (
    <AdCampaignCreateLifecycleContext.Provider value={value}>{children}</AdCampaignCreateLifecycleContext.Provider>
  );
};
