import { DataTreeItem, OfferCategory } from '../../../../domain/model';

export const buildTradeOfferCategoryTree = (tradeOfferCategories: OfferCategory[]): DataTreeItem<OfferCategory>[] => {
  return tradeOfferCategories
    .filter(item => !item.parentId)
    .map(tradeOfferCategory => buildTreeItem(tradeOfferCategory, tradeOfferCategories));
};

const buildTreeItem = (
  tradeOfferCategory: OfferCategory,
  tradeOfferCategories: OfferCategory[]
): DataTreeItem<OfferCategory> => {
  return {
    id: tradeOfferCategory.id,
    label: tradeOfferCategory.name,
    parentId: tradeOfferCategory.parentId,
    data: tradeOfferCategory,
    children:
      tradeOfferCategories
        .filter(c => c.parentId === tradeOfferCategory.id)
        .map(c => buildTreeItem(c, tradeOfferCategories)) ?? [],
  };
};
