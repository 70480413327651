import { ClientOrg, ClientOrgCreate } from '@/domain';
import { ClientOrgActionsState } from './store/slice';
import { RootState } from '@/data/store/store';
import { EClientOrgActionType } from '../types';
import { createContext } from 'react';

type ClientOrgTypes = ClientOrg;

export interface ClientOrgActionsSelectors {
  readonly getIsClientOrgProcessingSelector: (id: Nullable<UUID>) => (store: RootState) => boolean;
  readonly getIsClientOrgExecutedActionsSelector: (
    id: Nullable<UUID>,
    ...actions: EClientOrgActionType[]
  ) => (store: RootState) => Nullable<EClientOrgActionType>;
  readonly isClientOrgsAnyChangedSelector: (store: RootState) => number;
  readonly isClientOrgCreatedActionSelector: (store: RootState) => boolean;
}

export type ClientOrgActionsContextType = {
  readonly onChangeDialogState: (name: keyof ClientOrgActionsState['dialogs'], data: Nullable<ClientOrg>) => void;
  readonly onSave: (clientOrg: ClientOrgCreate) => Promise<Nullable<ClientOrg>>;
  readonly onUnBlock: (id: UUID) => Promise<Nullable<ClientOrg>>;
  readonly onBlock: (id: UUID) => Promise<Nullable<ClientOrg>>;

  readonly onTryUnBlock: (clientOrg: ClientOrgTypes) => void;
  readonly onTryBlock: (clientOrg: ClientOrgTypes) => void;

  readonly utils: {
    readonly selectors: ClientOrgActionsSelectors;
  };
};

const ClientOrgActionsContext = createContext<ClientOrgActionsContextType>({} as ClientOrgActionsContextType);

export default ClientOrgActionsContext;
