import { Checkbox } from '@mui/material';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import React from 'react';
import { Option } from './controls';
import {
  SportOptionMultipleAllOptionsState,
  SportOptionMultipleItemType,
  SportOptionMultipleTypesSelectorItem,
} from './types';

interface SportOptionMultipleItemProps<T extends string> {
  readonly elementProps: React.HTMLAttributes<HTMLLIElement>;
  readonly option: SportOptionMultipleTypesSelectorItem<T>;
  readonly state: AutocompleteRenderOptionState;
}

const SportOptionMultipleItem = <T extends string>({
  elementProps,
  option,
  state,
}: SportOptionMultipleItemProps<T>) => {
  switch (option.type) {
    case SportOptionMultipleItemType.All:
      return (
        <Option {...elementProps}>
          <Checkbox
            color='primary'
            checked={state.selected}
            indeterminate={option.state === SportOptionMultipleAllOptionsState.Indeterminate}
          />
          {option.name}
        </Option>
      );
    case SportOptionMultipleItemType.None:
      return (
        <Option {...elementProps}>
          <Checkbox
            color='primary'
            checked={state.selected}
          />
          {option.name}
        </Option>
      );
    case SportOptionMultipleItemType.Option:
      return (
        <Option {...elementProps}>
          <Checkbox
            color='primary'
            checked={state.selected}
          />
          {option.name}
        </Option>
      );
  }
};

export default SportOptionMultipleItem;

export const getSportOptionMultipleItemAllOptions = (
  name: string,
  state: SportOptionMultipleAllOptionsState
): SportOptionMultipleTypesSelectorItem<any> => ({
  type: SportOptionMultipleItemType.All,
  id: SportOptionMultipleItemType.All,
  name,
  state,
});

export const getSportOptionMultipleItemNoneOptions = (
  state: SportOptionMultipleAllOptionsState
): SportOptionMultipleTypesSelectorItem<any> => ({
  type: SportOptionMultipleItemType.None,
  id: SportOptionMultipleItemType.None,
  name: 'не выбрано',
  state,
});
