import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EClientOrgUserTableTab } from '../../types';
import {
  clientOrgUsersClientOrgSelector,
  clientOrgUsersFilterSelector,
  clientOrgUsersNeedRefreshWatcherSelector,
  clientOrgUsersPageNumberSelector,
  clientOrgUsersSearchSelector,
} from '../store/selectors';
import {
  clientOrgUsersCountsFetch,
  clientOrgUsersFetch,
  ClientOrgUsersFetchProps,
  clientOrgUsersNeedRefreshWatcherReset,
} from '../store/slice';
import { getStatesFilterForClientOrgUserTableTab } from '../utils';
import { useContextConfig } from './useContextConfig';

interface UseClientOrgUsersTableSearchProps {
  readonly tab: EClientOrgUserTableTab;
}

const getStatusesByTab = (t: EClientOrgUserTableTab) => getStatesFilterForClientOrgUserTableTab(t);

const useClientOrgUsersTableSearch = (props: UseClientOrgUsersTableSearchProps): void => {
  const { tab } = props;

  const dispatch = useDispatch();

  const { tabsForCounts } = useContextConfig();

  const [searchPromises, setSearchPromises] = useState<Nullable<any[]>>(null);

  const needRefreshWatcher = useSelector(clientOrgUsersNeedRefreshWatcherSelector);
  const pageNumber = useSelector(clientOrgUsersPageNumberSelector);
  const search = useSelector(clientOrgUsersSearchSelector);
  const filter = useSelector(clientOrgUsersFilterSelector);
  const clientOrg = useSelector(clientOrgUsersClientOrgSelector);

  const currentStatuses = getStatusesByTab(tab);

  let onlyLoggedIn: Nullable<boolean> = null;
  switch (tab) {
    case EClientOrgUserTableTab.Enabled:
      onlyLoggedIn = true;
      break;
    case EClientOrgUserTableTab.Invited:
      onlyLoggedIn = false;
      break;
    case EClientOrgUserTableTab.Disabled:
      onlyLoggedIn = null;
      break;
  }

  const queryParams = useMemo<ClientOrgUsersFetchProps>(() => {
    return {
      filter,
      search: {
        ...search,
        statuses: currentStatuses,
        onlyLoggedIn,
      },
      clientOrgId: clientOrg?.id,
      pageNumber,
    };
  }, [filter, search, currentStatuses, pageNumber]);

  const onSearch = useCallback(() => {
    setSearchPromises([
      dispatch(clientOrgUsersFetch(queryParams)),
      dispatch(clientOrgUsersCountsFetch({ ...queryParams, tabs: tabsForCounts })),
    ]);
  }, [dispatch, queryParams]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  // сброс вотчера после ухода
  useEffect(() => {
    return () => {
      dispatch(clientOrgUsersNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};

export default useClientOrgUsersTableSearch;
