import { Location } from 'history';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { UUID } from '../../../domain/model/types';
import rootRouting from '../../../routing';
import NotFoundScreen from '../../screens/notFound';
import { EOfferTableTab } from '../general/offer/types';
import { ECorpOfferStep } from './types';
import { ContentLoaderSimple } from '../../components/common/loader';
import React, { Suspense } from 'react';

const CorpOfferCreateScreen = React.lazy(() => import('../../screens/corpOfferCreate'));
const CorpOfferDetailsScreen = React.lazy(() => import('../../screens/corpOfferDetails'));
const CorpOfferEditScreen = React.lazy(() => import('../../screens/corpOfferEdit'));
const CorpOffersScreen = React.lazy(() => import('../../screens/corpOffers'));
const CorpOffersUploadScreen = React.lazy(() => import('../../screens/corpOffersUpload'));

const routing = {
  root: `${rootRouting.corpOffers}`,
  list: `${rootRouting.corpOffers}`,
  create: `${rootRouting.corpOffers}/create`,
  edit: `${rootRouting.corpOffers}/:id/edit`,
  details: `${rootRouting.corpOffers}/:id/details`,
  upload: `${rootRouting.corpOffers}/upload`,
};

export enum ECorpOfferUrlParam {
  Tab = 'tab',
  Step = 'step',
}

export interface CorpOffersLocationState {
  readonly guid: UUID;
}

interface GetCorpOffersTableRouteProps {
  readonly tab?: EOfferTableTab;
  readonly guid?: UUID;
}

interface GetCorpOfferEditRouteProps {
  readonly id: UUID;
  readonly step?: ECorpOfferStep;
}

interface GetCorpOfferDetailsRouteProps {
  readonly id: UUID;
  readonly step?: ECorpOfferStep;
}

interface GetCorpOfferCreateRouteProps {
  readonly step?: ECorpOfferStep;
}

export const getCorpOfferCreateRoute = ({ step = ECorpOfferStep.General }: GetCorpOfferCreateRouteProps) => {
  const searchParams = new URLSearchParams();

  searchParams.append(ECorpOfferUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.create}${search}`;
};

export const getCorpOfferEditRoute = ({ id, step = ECorpOfferStep.General }: GetCorpOfferEditRouteProps) => {
  const searchParams = new URLSearchParams();
  searchParams.append(ECorpOfferUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.edit.replace(':id', id)}${search}`;
};

export const getCorpOfferDetailsRoute = ({ id, step = ECorpOfferStep.General }: GetCorpOfferDetailsRouteProps) => {
  const searchParams = new URLSearchParams();
  searchParams.append(ECorpOfferUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.details.replace(':id', id)}${search}`;
};

export const getCorpOffersTableRoute = (props: GetCorpOffersTableRouteProps): Location<CorpOffersLocationState> => {
  const { tab, guid } = props;

  const searchParams = new URLSearchParams();
  if (tab) {
    searchParams.append(ECorpOfferUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return {
    pathname: routing.list,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

export const getCorpOffersUploadRoute = () => {
  return `${routing.upload}`;
};

const CorpOffersEntry = () => {
  return (
    <Suspense fallback={<ContentLoaderSimple />}>
      <Switch>
        <Route
          exact
          path={routing.list}
          component={CorpOffersScreen}
        />
        <Route
          exact
          path={routing.details}
          component={CorpOfferDetailsScreen}
        />
        <Route
          exact
          path={routing.create}
          component={CorpOfferCreateScreen}
        />
        <Route
          exact
          path={routing.edit}
          component={CorpOfferEditScreen}
        />
        <Route
          exact
          path={routing.upload}
          component={CorpOffersUploadScreen}
        />
        <Redirect
          exact
          from={routing.root}
          to={routing.list}
        />
        <Route component={NotFoundScreen} />
      </Switch>
    </Suspense>
  );
};

export default CorpOffersEntry;
