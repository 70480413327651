import BannerTableCell, { BannerTableCellCommonProps } from './index';

const BannerTableCellCode = (props: BannerTableCellCommonProps) => {
  const {
    banner: { code },
  } = props;

  return (
    <BannerTableCell
      {...props}
      value={code}
    />
  );
};

export default BannerTableCellCode;
