import { configureStore, Middleware } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { loggerMiddleware } from '../../system/middleware';
import { rootReducer } from './reducers';

const isProductionMode = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test';
const projectMiddlewares: Middleware[] = !isProductionMode ? [loggerMiddleware] : [];

// для получения отдельных сторов (полезно для тестов)
export const setupStore = (preloadedState: any) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: gDM => gDM().concat(projectMiddlewares),
    devTools: !isProductionMode,
  });
};

const store = setupStore({});

export type RootState = ReturnType<typeof store.getState>;
export type RootStateTyped = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
/**
 * @deprecated Use `useDispatch()`
 */
export const useAppDispatch: () => AppDispatch = useDispatch;

export type AppThunkAPIConfig<R = any> = {
  state: RootState;
  rejectValue: R;
};

export default store;
