import { Wrapper } from './controls';

interface TabsPanelCounterProps {
  readonly count?: number | string;
}

const TabsPanelCounter = (props: TabsPanelCounterProps) => {
  const { count } = props;

  return count !== undefined ? <Wrapper color='textSecondary'>{count}</Wrapper> : null;
};

export default TabsPanelCounter;
