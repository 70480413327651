import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
`;

export const ColumnWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;
