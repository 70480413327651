import styled from '@emotion/styled';

export const OuterBorder = styled.div`
  height: 36.625rem;
  width: 20.875rem;

  position: relative;

  background-color: ${p => p.theme.palette.background.default};
  border: 1px solid ${p => p.theme.palette.secondary.main};
  border-radius: ${p => (p.theme.shape.borderRadius as number) * 4.5}px;
`;

export const InnerBorder = styled.div`
  height: 34.625rem;
  width: 18.9375rem;

  overflow: hidden;

  position: absolute;
  top: 0.9375rem;
  left: 0.9375rem;

  padding: ${p => p.theme.spacing(5, 1, 2, 1)};

  border: 1px solid ${p => p.theme.palette.secondary.main};
  border-radius: ${p => (p.theme.shape.borderRadius as number) * 4.5}px;
`;

export const TopBlock = styled.div`
  height: 0.3125rem;
  width: 2.8125rem;

  position: absolute;
  top: 0.6875rem;
  left: 9rem;

  border: 1px solid ${p => p.theme.palette.secondary.main};
  border-radius: ${p => (p.theme.shape.borderRadius as number) * 2.5}px
    ${p => (p.theme.shape.borderRadius as number) * 2.5}px 0 0;
`;

export const RightBlock = styled.div`
  height: 3.875rem;
  width: 0.25rem;

  position: absolute;
  top: 7.4375rem;
  right: -0.1875rem;

  background-color: ${p => p.theme.palette.background.default};
  border: 1px solid ${p => p.theme.palette.secondary.main};
  border-radius: 0 ${p => (p.theme.shape.borderRadius as number) / 2}px
    ${p => (p.theme.shape.borderRadius as number) / 2}px 0;
`;

export const LeftBlock = styled.div`
  height: 2.75rem;
  width: 0.25rem;

  position: absolute;
  left: -0.25rem;

  border: 1px solid ${p => p.theme.palette.secondary.main};
  border-radius: ${p => (p.theme.shape.borderRadius as number) / 2}px 0 0
    ${p => (p.theme.shape.borderRadius as number) / 2}px;
`;

export const LeftTopBlock = styled(LeftBlock)`
  top: 8rem;
`;

export const LeftBottomBlock = styled(LeftBlock)`
  top: 10.875rem;
`;
