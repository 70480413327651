import { FormControlLabel } from '@mui/material';
import styled from '@emotion/styled';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleLabel = styled(FormControlLabel)`
  margin-right: 0;
`;
