import { FCC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BookingOfferServiceManageContainerProps } from './container';
import { bookingOfferServiceManageByIdFetch, bookingOfferServiceManageStateReset } from './store/slice';

type BookingOfferServiceManageInitializerProps = BookingOfferServiceManageContainerProps;

export const BookingOfferServiceManageInitializer: FCC<BookingOfferServiceManageInitializerProps> = ({
  id,
  children,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const promise = dispatch(bookingOfferServiceManageByIdFetch({ id }));

    return () => {
      dispatch(bookingOfferServiceManageStateReset());
      promise?.abort();
    };
  }, [dispatch, id]);

  return <>{children}</>;
};
