import { Checkbox, Typography } from '@mui/material';
import { CmsContainerCreateComponentProps } from '../../types';
import { CheckboxLabel } from './controls';

type CmsContainerEditComponentOfferIconVisibleProps = CmsContainerCreateComponentProps;

const CmsContainerEditComponentOfferIconVisible = (props: CmsContainerEditComponentOfferIconVisibleProps) => {
  const { cmsContainer, onChange } = props;

  return (
    <CheckboxLabel
      control={
        <Checkbox
          color='primary'
          checked={cmsContainer.showIcon ?? false}
          onChange={(event, checked) => onChange('showIcon', checked)}
        />
      }
      label={<Typography>Показать иконку вида предложения</Typography>}
    />
  );
};

export default CmsContainerEditComponentOfferIconVisible;
