import { UUID } from '@/domain';
import { ContentLoaderSimple } from '@components/common/loader';
import AdCampaignCreateScreen from '@screens/adCampaignCreate';
import AdCampaignDetailsScreen from '@screens/adCampaignDetails';
import AdCampaignEditScreen from '@screens/adCampaignEdit';
import AdCampaignsScreen from '@screens/adCampaigns';
import NotFoundScreen from '@screens/notFound';
import { Location } from 'history';
import { Suspense } from 'react';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import rootRouting from '../../../routing';
import AdCampaignActionsProvider from './actions/provider';
import { EAdCampaignStep, EAdCampaignTableTab } from './types';

export const adsCampaignRootSubPath = '/campaign';

const routing = {
  root: `${rootRouting.ads}${adsCampaignRootSubPath}`,
  list: `${rootRouting.ads}${adsCampaignRootSubPath}`,
  create: `${rootRouting.ads}${adsCampaignRootSubPath}/create`,
  details: `${rootRouting.ads}${adsCampaignRootSubPath}/:id/details`,
  edit: `${rootRouting.ads}${adsCampaignRootSubPath}/:id/edit`,
};

export enum EAdCampaignUrlParam {
  Tab = 'tab',
  Step = 'step',
}

interface GetAdCampaignTableRouteProps {
  readonly guid?: UUID;
  readonly tab?: EAdCampaignTableTab;
}

export interface AdCampaignsLocationState {
  readonly guid: UUID;
}

export const getAdCampaignsTableRoute = (props: GetAdCampaignTableRouteProps): Location<AdCampaignsLocationState> => {
  const { guid, tab } = props;

  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.append(EAdCampaignUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: routing.list,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

interface GetAdCampaignCreateRouteProps {
  readonly location: any;
  readonly guid?: UUID;
  readonly step?: EAdCampaignStep;
}

export const getAdCampaignCreateRoute = ({ step, location, guid }: GetAdCampaignCreateRouteProps) => {
  const searchParams = new URLSearchParams(location.search);

  if (step) {
    searchParams.set(EAdCampaignUrlParam.Step, step);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: routing.create,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

interface GetAdCampaignDetailsRouteProps {
  readonly id: UUID;
  readonly step?: EAdCampaignStep;
}

export const getAdCampaignDetailsRoute = ({ id, step = EAdCampaignStep.General }: GetAdCampaignDetailsRouteProps) => {
  const searchParams = new URLSearchParams();
  searchParams.append(EAdCampaignUrlParam.Step, step);

  return `${routing.details.replace(':id', id)}?${searchParams.toString()}`;
};

interface GetAdCampaignEditRouteProps {
  readonly id: UUID;
  readonly location?: any;
  readonly guid?: UUID;
  readonly step?: EAdCampaignStep;
}

export const getAdCampaignEditRoute = ({ id, step, guid, location }: GetAdCampaignEditRouteProps) => {
  const searchParams = new URLSearchParams(location?.params);
  if (step) {
    searchParams.append(EAdCampaignUrlParam.Step, step);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: routing.edit.replace(':id', id),
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

const AdCampaignsEntry = () => {
  return (
    <AdCampaignActionsProvider>
      <Suspense fallback={<ContentLoaderSimple />}>
        <Switch>
          <Route
            exact
            path={routing.list}
            component={AdCampaignsScreen}
          />
          <Redirect
            exact
            from={routing.root}
            to={routing.list}
          />
          <Route
            exact
            path={routing.create}
            component={AdCampaignCreateScreen}
          />
          <Route
            exact
            path={routing.edit}
            component={AdCampaignEditScreen}
          />
          <Route
            exact
            path={routing.details}
            component={AdCampaignDetailsScreen}
          />
          <Route component={NotFoundScreen} />
        </Switch>
      </Suspense>
    </AdCampaignActionsProvider>
  );
};

export default AdCampaignsEntry;
