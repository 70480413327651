import { FormControlLabel } from '@mui/material';
import styled from '@emotion/styled';

export const Switch = styled(FormControlLabel)`
  width: 100%;

  align-items: start;

  position: relative;

  &.MuiFormControlLabel-labelPlacementStart {
    flex-direction: row;

    margin-left: 0;
    padding-right: ${p => p.theme.spacing(7.5)};
  }

  & > .MuiSwitch-root {
    position: absolute;
    right: 0;

    transform: translateY(-${p => p.theme.spacing(0.5)});
  }
`;
