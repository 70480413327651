import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { OrderFull } from '../../../../../domain/model/order';
import { Nullable, UUID } from '../../../../../domain/model/types';

export const orderByIdFetch = createAsyncThunk<OrderFull, UUID, AppThunkAPIConfig>(
  'order/byId/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      return (await Api.order.mp.byId({ id, signal })).data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export type OrderByIdState = Fetchable & {
  readonly order: Nullable<OrderFull>;
};

type Reducer<T = undefined> = CaseReducer<OrderByIdState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<OrderByIdState> {
  orderByIdStateReset: Reducer;
  orderByIdStateRefresh: Reducer;
}

const slice = createSlice<OrderByIdState, Reducers, 'byId'>({
  name: 'byId',
  initialState: {
    ...fetchableDefault,
    order: null,
  },
  reducers: {
    orderByIdStateReset: state => {
      state.isFetching = false;
      state.isFetched = false;
      state.isFailed = false;
      state.order = null;
    },
    orderByIdStateRefresh: state => {
      if (state.order) {
        state.order = {
          ...state.order,
        };
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(orderByIdFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
        state.order = null;
      })
      .addCase(orderByIdFetch.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.order = payload;
      })
      .addCase(orderByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
        } else {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = false;
        }
      });
  },
});

export const { orderByIdStateReset, orderByIdStateRefresh } = slice.actions;

export default slice.reducer;
