import { ApiRequestPageable } from '@/data/api/types';
import { getQueryAnalyticsDownloadEndpoint } from '@/data/api/utils';
import { ESearchQueryTypeDiscriminator } from '@/domain/model/enums';
import { DataTableColumns } from '@components/common/table';
import { AnalyticsQueriesTableFilterValues } from '../filterUtils';

export enum EAnalyticsQueriesTableColumn {
  Query = 'query',
  Geo = 'geo',
  Count = 'count',
}

export enum EAnalyticsQueriesTableTab {
  Queries = 'queries',
  Geography = 'geography',
}

export const analyticsQueriesTableColumns: DataTableColumns<EAnalyticsQueriesTableColumn> = {
  [EAnalyticsQueriesTableColumn.Query]: {
    title: 'Слово или словосочетание',
    width: '30rem',
    wrap: true,
  },
  [EAnalyticsQueriesTableColumn.Geo]: {
    title: 'География охвата',
    width: '30rem',
    wrap: true,
  },
  [EAnalyticsQueriesTableColumn.Count]: {
    title: 'Число запросов',
    width: '12rem',
  },
};

export const getAnalyticsQueriesTableTabName = (tab: EAnalyticsQueriesTableTab) => {
  switch (tab) {
    case EAnalyticsQueriesTableTab.Queries:
      return 'Запросы';
    case EAnalyticsQueriesTableTab.Geography:
      return 'География';
  }
};

export const getAnalyticsQueriesTableColumns = (
  tab: EAnalyticsQueriesTableTab
): DataTableColumns<EAnalyticsQueriesTableColumn> => {
  const columns: EAnalyticsQueriesTableColumn[] = [];

  switch (tab) {
    case EAnalyticsQueriesTableTab.Queries:
      columns.push(EAnalyticsQueriesTableColumn.Query);
      break;
    case EAnalyticsQueriesTableTab.Geography:
      columns.push(EAnalyticsQueriesTableColumn.Geo);
      break;
  }
  columns.push(EAnalyticsQueriesTableColumn.Count);

  return columns.reduce<DataTableColumns<EAnalyticsQueriesTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...analyticsQueriesTableColumns[column],
      },
    };
  }, {});
};

type SearchQueryListType = ESearchQueryTypeDiscriminator.Query | ESearchQueryTypeDiscriminator.Geo;
const geSearchQueryListTypeByTableTab = (tab: EAnalyticsQueriesTableTab): SearchQueryListType => {
  switch (tab) {
    case EAnalyticsQueriesTableTab.Queries:
      return ESearchQueryTypeDiscriminator.Query;
    case EAnalyticsQueriesTableTab.Geography:
      return ESearchQueryTypeDiscriminator.Geo;
  }
};

type GetQueryAnalyticsDownloadUrlSkipPaginationProps = {
  readonly skipPageable: true;
} & Partial<ApiRequestPageable>;

type GetQueryAnalyticsDownloadUrlNoSkipPaginationProps = {
  readonly skipPageable?: false;
} & ApiRequestPageable;

type MabeSkipPaginationGetQueryAnalyticsDownloadUrlProps =
  | GetQueryAnalyticsDownloadUrlSkipPaginationProps
  | GetQueryAnalyticsDownloadUrlNoSkipPaginationProps;

type GetQueryAnalyticsDownloadUrlProps = {
  readonly tab: EAnalyticsQueriesTableTab;
  readonly filter: AnalyticsQueriesTableFilterValues;
} & MabeSkipPaginationGetQueryAnalyticsDownloadUrlProps;

export const getQueryAnalyticsDownloadUrl = ({
  sort,
  page,
  pageSize,
  tab,
  filter,
  skipPageable,
}: GetQueryAnalyticsDownloadUrlProps) => {
  const { query, dateFrom, dateTo, places } = filter;
  const searchParams = new URLSearchParams();

  searchParams.append('resultType', geSearchQueryListTypeByTableTab(tab));

  if (sort) {
    if (typeof sort === 'string') {
      searchParams.append('sort', sort);
    } else {
      sort.forEach(item => searchParams.append('sort', item));
    }
  }

  if (skipPageable) {
    searchParams.append('skipPageable', skipPageable.toString());
  } else {
    page && searchParams.append('page', (page - 1).toString(10));
    pageSize && searchParams.append('size', pageSize.toString(10));
  }

  if (dateFrom?.value) searchParams.append('dateFrom', dateFrom.value);
  if (dateTo?.value) searchParams.append('dateTo', dateTo.value);

  if (query?.value) {
    searchParams.append('q', query.value);
  }

  if (places?.value) {
    searchParams.append(
      'localityPredicate',
      JSON.stringify({
        ...places.value,
        in: places.value.in?.map(item => ({ id: item.id })) ?? null,
      })
    );
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return `${getQueryAnalyticsDownloadEndpoint()}${search}`;
};
