import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENoticeStatus } from '../../../../domain/model/enums';
import Notifier from '../../../../system/notifier';
import ContentLoader from '../../../components/common/loader';
import { nsiSelector } from '../nsi/store/selectors';
import { nsiFetch } from '../nsi/store/slice';
import { Wrapper } from './controls';

const MetadataLoaderContainer = ({ children }: { children: any }) => {
  const dispatch = useDispatch();
  const { isFetched, isFailed } = useSelector(nsiSelector);

  useEffect(() => {
    dispatch(nsiFetch());
  }, []);

  useEffect(() => {
    if (isFailed) Notifier.getInstance().addNotice(ENoticeStatus.Error, 'Не удалось загрузить справочную информацию');
  }, [isFailed]);

  if (!isFetched) {
    return (
      <Wrapper>
        <ContentLoader />
      </Wrapper>
    );
  }

  return <>{children}</>;
};

export default MetadataLoaderContainer;
