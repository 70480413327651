import { Grid } from '@mui/material';
import { CmsContainerDetailsComponentProps } from '../types';
import CmsContainerViewComponentName from './name';
import CmsContainerViewComponentTitle from './title';

type CmsContainerViewComponentCommonProps = CmsContainerDetailsComponentProps;

const CmsContainerViewComponentCommon = (props: CmsContainerViewComponentCommonProps) => {
  return (
    <Grid
      container
      direction='column'
      spacing={2}
    >
      <Grid item>
        <CmsContainerViewComponentName {...props} />
      </Grid>
      <Grid item>
        <CmsContainerViewComponentTitle {...props} />
      </Grid>
    </Grid>
  );
};

export default CmsContainerViewComponentCommon;
