import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { OfferHistoryTableCellCommonProps } from '../components/tableCell/history';
import { offerHistoryTableHistoryByIndexSelector } from '../store/selectors';

export type OfferHistoryTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<OfferHistoryTableCellCommonProps>;
};

const OfferHistoryTableCellAdapter = (props: OfferHistoryTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const history = useSelector(offerHistoryTableHistoryByIndexSelector(index));

  return useMemo(() => (history ? <Component history={history} /> : null), [Component, history]);
};

export default OfferHistoryTableCellAdapter;
