import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Banner } from '../../../../../domain/model/banner';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { bannerServices } from '../../services';

export const bannerDetailsFetch = createAsyncThunk<Banner, UUID, AppThunkAPIConfig>(
  'banner/details/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      return await bannerServices.common.one({ id, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface BannerDetailsState extends Fetchable {
  readonly data: Nullable<Banner>;
}

type Reducer<T = undefined> = CaseReducer<BannerDetailsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<BannerDetailsState> {
  bannerDetailsReset: Reducer;
}

const slice = createSlice<BannerDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    ...fetchableDefault,
    data: null,
  },
  reducers: {
    bannerDetailsReset: state => {
      state.isFetching = false;
      state.isFetched = false;
      state.isFailed = false;
      state.data = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bannerDetailsFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
        state.data = null;
      })
      .addCase(bannerDetailsFetch.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.data = payload;
      })
      .addCase(bannerDetailsFetch.rejected, state => {
        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = true;
        state.data = null;
      });
  },
});

export const { bannerDetailsReset } = slice.actions;

export default slice.reducer;
