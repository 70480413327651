import Api from '../../../../data/api';
import { ApiCancellable, ApiRequestPageable, ApiResponseWithPageable } from '../../../../data/api/types';
import { ESportEventStatus } from '../../../../domain/model/enums';
import {
  SportsFest,
  SportsFestCombinedTeam,
  SportsFestRequest,
  SportsFestResultsRequest,
} from '../../../../domain/model/event';
import { UUID } from '../../../../domain/model/types';
import eventServices from './';

export type ByIdProps = ApiCancellable & {
  id: UUID;
};

export type CombinedTeamsProps = ApiCancellable &
  Partial<ApiRequestPageable> & {
    sportsFestId: UUID;
  };

export type SaveProps = {
  sportsFest: SportsFestRequest;
};

export type PublishProps = {
  sportsFest: SportsFestRequest;
};

export type SaveResultsProps = {
  id: UUID;
  results: SportsFestResultsRequest;
};

export type PublishResultsProps = {
  id: UUID;
};

export type CancelProps = {
  id: UUID;
};

export type EventSportsFestService = {
  readonly one: (props: ByIdProps) => Promise<SportsFest>;
  readonly save: (props: SaveProps) => Promise<SportsFest>;
  readonly combinedTeams: (props: CombinedTeamsProps) => Promise<ApiResponseWithPageable<SportsFestCombinedTeam>>;
  readonly publish: (props: PublishProps) => Promise<SportsFest>;
  readonly publishAnnounce: (props: PublishProps) => Promise<SportsFest>;
  readonly saveResults: (props: SaveResultsProps) => Promise<SportsFest>;
  readonly publishResults: (props: PublishResultsProps) => Promise<SportsFest>;
  readonly cancel: (props: CancelProps) => Promise<void>;
};

export const service: EventSportsFestService = {
  one: async ({ id, signal }) => {
    return (await Api.event.sportsFest.one({ id, signal })).data;
  },
  combinedTeams: async payload => {
    const { sportsFestId, pageSize = 9999999, signal, page = 1 } = payload;
    const { data } = await Api.event.sportsFest.combinedTeams({
      id: sportsFestId,
      page,
      pageSize,
      signal,
    });

    return data;
  },
  save: async ({ sportsFest }) => {
    const sportsFestToSave = eventServices.common.beforeSave(sportsFest);

    const id = sportsFestToSave.id;
    switch (sportsFestToSave.status?.code ?? null) {
      case null:
        return (await Api.event.sportsFest.create({ data: sportsFestToSave })).data;
      case ESportEventStatus.Draft:
        return (await Api.event.sportsFest.saveUnpublished({ data: sportsFestToSave })).data;
      case ESportEventStatus.Announced:
        return (await Api.event.sportsFest.saveAnnounced({ id, data: sportsFestToSave })).data;
      default:
        return (await Api.event.sportsFest.save({ id, data: sportsFestToSave })).data;
    }
  },
  publish: async ({ sportsFest }) => {
    const id = sportsFest.id;
    return (await Api.event.sportsFest.publish({ id })).data;
  },
  publishAnnounce: async ({ sportsFest }) => {
    const id = sportsFest.id;
    return (await Api.event.sportsFest.publishAnnounce({ id })).data;
  },
  saveResults: async ({ id, results }) => {
    return (await Api.event.sportsFest.saveResults({ id, results })).data;
  },
  publishResults: async ({ id }) => {
    return (await Api.event.sportsFest.publishResults({ id })).data;
  },
  cancel: async ({ id }) => {
    await Api.event.cancel({ id });
  },
};
