import { forwardRef, ReactNode, useState } from 'react';
import { EntityLink } from '../../../../../domain/model';
import { PanelActions } from '../../../../types';
import TableMenuButton from '../../buttons/menu';
import ContentLoader from '../../loader';
import { ActionsWrapper } from './controls';
import TableActionItem from './item';

interface TableActionsProps<T extends EntityLink, A extends string> {
  readonly entityLink: T;
  readonly actions: PanelActions<A>;
  readonly isExecuting: boolean;
  readonly onExecute: (action: A, entityLink: T) => void;
}

type TableActionsType = <T extends EntityLink, A extends string>(props: TableActionsProps<T, A>) => ReactNode;

const TableActions: TableActionsType = forwardRef((props, ref: any) => {
  const { entityLink, actions, isExecuting, onExecute } = props;
  const [closeMarker, setCloseMarker] = useState<symbol>(Symbol());

  const onExecuteInternal: typeof onExecute = (type, entity) => {
    setCloseMarker(Symbol());
    onExecute(type, entity);
  };

  if (isExecuting) {
    return (
      <ActionsWrapper>
        <ContentLoader size={20} />
      </ActionsWrapper>
    );
  }

  return actions.length > 0 ? (
    <ActionsWrapper>
      <TableMenuButton
        disablePropagation
        ref={ref}
        id={entityLink.id}
        closeMarker={closeMarker}
      >
        {actions.map(({ type, disabled, label }) => (
          <TableActionItem
            key={type}
            disabled={disabled ?? false}
            onExecute={() => onExecuteInternal(type, entityLink)}
          >
            {label}
          </TableActionItem>
        ))}
      </TableMenuButton>
    </ActionsWrapper>
  ) : null;
});

export default TableActions;
