import { Location } from 'history';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { EOfferType, EOrderPartition } from '../../../domain/model/enums';
import { UUID } from '../../../domain/model/types';
import rootRouting from '../../../routing';
import BookingOrdersScreen from '../../screens/bookingOrders';
import NotFoundScreen from '../../screens/notFound';
import OrderCreateScreen from '../../screens/orderCreate';
import OrderDetailsScreen from '../../screens/orderDetails';
import OrderEditScreen from '../../screens/orderEdit';
import ProductOrdersScreen from '../../screens/productOrders';
import { getBookingOfferDetailsRoute } from '../bookingOffer/entry';
import { getCorpOfferDetailsRoute } from '../corpOffer/entry';
import { getProductDetailsRoute } from '../products/entry';
import { getTradeOfferDetailsRoute } from '../tradeOffer/entry';
import { EOrderTableTab } from './types';
import { EOrderStep } from './utils';

const partitionPathParam = ':partition' as const;

const routing = {
  root: `${rootRouting.orders}`,
  list: `${rootRouting.orders}/${partitionPathParam}`,
  create: `${rootRouting.orders}/${partitionPathParam}/create`,
  details: `${rootRouting.orders}/:id`,
  edit: `${rootRouting.orders}/:id/edit`,
};

export enum EOrderUrlParam {
  Tab = 'tab',
  Step = 'step',
}

export interface OrdersLocationState {
  readonly guid: UUID;
}

interface GetOrderCreateRouteProps {
  readonly partition: EOrderPartition;
  readonly step?: EOrderStep;
}

interface GetOrderDetailsRouteProps {
  readonly id: UUID;
  readonly step?: EOrderStep;
}

interface GetOrderEditRouteProps {
  readonly id: UUID;
}

interface GetOrdersTableRouteProps {
  readonly partition: EOrderPartition;
  readonly tab?: EOrderTableTab;
  readonly guid?: UUID;
}

export const getOrderCreateRoute = ({ partition, step = EOrderStep.Promotion }: GetOrderCreateRouteProps) => {
  const searchParams = new URLSearchParams();

  searchParams.append(EOrderUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.create.replace(partitionPathParam, partition)}${search}`;
};

export const getOrderEditRoute = ({ id }: GetOrderEditRouteProps) => {
  return `${routing.edit.replace(':id', id)}`;
};

export const getOrderDetailsRoute = ({ id, step = EOrderStep.Order }: GetOrderDetailsRouteProps) => {
  const searchParams = new URLSearchParams();

  searchParams.append(EOrderUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.details.replace(':id', id)}${search}`;
};

export const getOrdersTableRoute = (props: GetOrdersTableRouteProps): Location<OrdersLocationState> => {
  const { guid, tab, partition } = props;

  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.append(EOrderUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: routing.list.replace(partitionPathParam, partition),
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

export const getOrderOfferDetailsRoute = (id: string, type: EOfferType): string | Location => {
  switch (type) {
    case EOfferType.Product:
      return getProductDetailsRoute({ id });
    case EOfferType.Trade:
      return getTradeOfferDetailsRoute({ id });
    case EOfferType.Corp:
      return getCorpOfferDetailsRoute({ id });
    case EOfferType.Booking:
      return getBookingOfferDetailsRoute({ id });
  }
};

const OrdersEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.list.replace(partitionPathParam, EOrderPartition.ProductOffers)}
        render={() => <ProductOrdersScreen />}
      />
      <Route
        exact
        path={routing.list.replace(partitionPathParam, EOrderPartition.BookingOffers)}
        render={() => <BookingOrdersScreen />}
      />
      <Route
        exact
        path={routing.create.replace(partitionPathParam, EOrderPartition.ProductOffers)}
        render={() => <OrderCreateScreen partition={EOrderPartition.ProductOffers} />}
      />
      <Route
        exact
        path={routing.edit}
        component={OrderEditScreen}
      />
      <Route
        exact
        path={routing.details}
        component={OrderDetailsScreen}
      />

      <Route component={NotFoundScreen} />
    </Switch>
  );
};

export default OrdersEntry;
