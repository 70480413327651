import SearchIcon from '@mui/icons-material/Search';
import {
  FormHelperTextProps as MuiFormHelperTextProps,
  InputAdornment,
  InputLabelProps as MuiInputLabelProps,
  InputProps as MuiInputProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import React, { forwardRef, useState } from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { Nullable } from '@/domain';
import { FormControlTooltip } from '../common';
import { MPCostFormat, MPNaturalNumberFormat, MPNumberFormat } from '../format';
import { LimitLabel, TextFieldLimited } from './controls';

export type MPFormInputProps = TextFieldProps & {
  /** @deprecated можно задавать все на верхнем уровне свойств */
  readonly textFieldProps?: {
    readonly InputProps?: MuiInputProps;
    readonly InputLabelProps?: MuiInputLabelProps;
    readonly FormHelperTextProps?: MuiFormHelperTextProps;
  };
  readonly helperInTooltip?: boolean;
  readonly helperTooltipStateless?: boolean;
};

export const MPFormInputInternal = forwardRef((props: TextFieldProps & MPFormInputProps, ref: any) => {
  const {
    name,
    label,
    value,
    textFieldProps,
    InputProps,
    helperInTooltip,
    helperTooltipStateless,
    helperText,
    error,
    ...others
  } = props;

  const control = (
    <TextField
      inputRef={ref}
      fullWidth
      name={name}
      variant='outlined'
      margin='dense'
      label={label}
      error={error}
      helperText={helperInTooltip ? null : helperText}
      value={value || ''}
      {...textFieldProps}
      InputLabelProps={{
        ...(textFieldProps && textFieldProps.InputLabelProps),
      }}
      FormHelperTextProps={{
        ...(textFieldProps && textFieldProps.FormHelperTextProps),
      }}
      InputProps={{
        ...(textFieldProps && textFieldProps.InputProps),
        ...InputProps,
      }}
      {...others}
    />
  );

  const tooltipInvisible = !helperText || !helperInTooltip;

  return (
    <FormControlTooltip
      variant={error ? 'error' : 'default'}
      placement='right-start'
      arrow
      open={helperTooltipStateless && !tooltipInvisible}
      disableFocusListener={tooltipInvisible}
      disableHoverListener={tooltipInvisible}
      disableTouchListener={tooltipInvisible}
      title={helperText ?? ''}
    >
      {control}
    </FormControlTooltip>
  );
});

export const MPFormInput = (props: TextFieldProps & MPFormInputProps) => {
  const { value, onChange, inputProps } = props;

  const [symbolsCount, setSymbolsCount] = useState<number>(((value as string) ?? '').length);

  const onChangeInternal = (event: any) => {
    setSymbolsCount(event.target.value.length ?? 0);
    onChange?.(event);
  };

  if (inputProps?.maxLength > 0) {
    return (
      <TextFieldLimited>
        <MPFormInputInternal
          {...props}
          onChange={onChangeInternal}
        />
        <LimitLabel
          color='textSecondary'
          variant='body2'
        >
          {symbolsCount} из {inputProps?.maxLength}
        </LimitLabel>
      </TextFieldLimited>
    );
  }

  return (
    <MPFormInputInternal
      {...props}
      onChange={onChangeInternal}
    />
  );
};

export const MPSearchInput = (props: TextFieldProps) => {
  const { placeholder, InputProps, ...others } = props;

  return (
    <MPFormInput
      type='search'
      placeholder={placeholder || 'Поиск...'}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon color='secondary' />
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...others}
    />
  );
};

const MPMaskedPhone = (props: TextFieldProps) => {
  const { InputProps, ...others } = props;

  return (
    <MPFormInput
      InputProps={{
        inputComponent: MPPhoneMaskInput,
        ...InputProps,
      }}
      {...others}
    />
  );
};

const MPPrefixedPhone = (props: TextFieldProps & { value: Nullable<string>; prefix: string }) => {
  const { InputProps, prefix, value, onChange, ...others } = props;
  const regExp = new RegExp(`\\${prefix}`);
  const localValue = value?.replace(regExp, '');

  const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.target.value;
    onChange?.({ ...event, target: { ...event.target, value: targetValue ? prefix + targetValue : targetValue } });
  };

  return (
    <MPFormInput
      value={localValue}
      InputProps={{
        startAdornment: <InputAdornment position='start'>{prefix}</InputAdornment>,
        ...InputProps,
      }}
      {...others}
      onChange={onChangeInternal}
    />
  );
};

export const MPPhoneInput = (props: TextFieldProps & { value: Nullable<string>; prefix?: string }) => {
  const { prefix = '', ...others } = props;

  return prefix.length === 0 ? (
    <MPMaskedPhone {...others} />
  ) : (
    <MPPrefixedPhone
      prefix={prefix}
      {...others}
    />
  );
};

export type MPNaturalNumberInputProps = Omit<TextFieldProps, 'onChange'> & {
  readonly onChange: (event: React.ChangeEvent<HTMLInputElement>, value: Nullable<number>) => void;
};

export const MPNaturalNumberInput = (props: MPNaturalNumberInputProps) => {
  const { InputProps, onChange, ...others } = props;

  const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parseInt(event.target.value, 10);
    const newValue: Nullable<number> = isNaN(parsedValue) || parsedValue === 0 ? null : parsedValue;
    onChange?.(event, newValue);
  };

  const onKeyDownInternal = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === '0' && !event.currentTarget.value) {
      event.preventDefault();
    }
  };

  const onPasteInternal = (event: React.ClipboardEvent<HTMLInputElement>) => {
    try {
      const value = event.clipboardData.getData('Text');
      if (value === '0' && !(event.target as HTMLInputElement).value) {
        event.preventDefault();
      }
    } catch (e: any) {
      event.preventDefault();
      console.error(e);
    }
  };

  return (
    <MPFormInput
      InputProps={{
        inputComponent: MPNaturalNumberFormat,
        value: props.value,
        ...InputProps,
        onKeyDown: onKeyDownInternal,
        onPaste: onPasteInternal,
      }}
      InputLabelProps={{
        shrink: !!props.value,
      }}
      onChange={onChangeInternal}
      {...others}
    />
  );
};

type MPNumberInputProps = Omit<MPFormInputProps, 'value' | 'onChange'> & {
  readonly value: Nullable<number>;
  readonly onChange: (event: React.ChangeEvent<HTMLInputElement>, value: Nullable<number>) => void;

  readonly thousandSeparator?: boolean | string;
  readonly decimalSeparator?: boolean | string;
  readonly decimalScale?: number;
  readonly fixedDecimalScale?: boolean;
  readonly isNumericString?: boolean;
  readonly allowNegative?: boolean;
  readonly allowLeadingZeros?: boolean;
};

export const MPNumberInput = (props: MPNumberInputProps) => {
  const {
    InputProps,
    InputLabelProps,
    inputProps,
    thousandSeparator,
    decimalSeparator,
    decimalScale,
    fixedDecimalScale,
    isNumericString,
    allowNegative,
    allowLeadingZeros,
    onChange,
    ...others
  } = props;

  const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue =
      props.decimalScale && props.decimalScale > 0 ? parseFloat(event.target.value) : parseInt(event.target.value, 10);
    const newValue: Nullable<number> = isNaN(parsedValue) ? null : parsedValue;

    onChange?.(event, newValue);
  };

  return (
    <MPFormInput
      InputLabelProps={{ shrink: props.value !== null, ...InputLabelProps }}
      inputProps={{
        thousandSeparator,
        decimalSeparator,
        decimalScale,
        fixedDecimalScale,
        isNumericString,
        allowNegative,
        allowLeadingZeros,
        ...inputProps,
      }}
      InputProps={{
        inputComponent: MPNumberFormat,
        value: props.value,
        ...InputProps,
      }}
      onChange={onChangeInternal}
      {...others}
    />
  );
};

type MPCostInputProps = Omit<MPFormInputProps, 'value' | 'onChange'> & {
  readonly value: Nullable<number>;
  readonly onChange: (event: React.ChangeEvent<HTMLInputElement>, value: Nullable<number>) => void;
};

export const MPCostInput = (props: MPCostInputProps) => {
  const { InputProps, onChange, ...others } = props;

  const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parseFloat(event.target.value);
    const newValue: Nullable<number> = isNaN(parsedValue) ? null : parsedValue;

    onChange?.(event, newValue);
  };

  return (
    <MPFormInput
      InputProps={{
        inputComponent: MPCostFormat,
        value: props.value,
        ...InputProps,
      }}
      onChange={onChangeInternal}
      {...others}
    />
  );
};

export const MPFormTextArea = (props: TextFieldProps & MPFormInputProps) => {
  return (
    <MPFormInput
      {...props}
      multiline
    />
  );
};

const MPPhoneMaskInput = React.forwardRef<any, InputBaseComponentProps & MaskedInputProps>(
  (props, forwardedRef: any) => {
    return (
      <MaskedInput
        {...props}
        ref={(ref: any) => {
          forwardedRef(ref ? ref.inputElement : null);
        }}
        mask={['8', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }
);

export const MPMaskInput = React.forwardRef<any, InputBaseComponentProps & MaskedInputProps>(
  (props, forwardedRef: any) => {
    return (
      <MaskedInput
        ref={(ref: any) => {
          forwardedRef(ref ? ref.inputElement : null);
        }}
        placeholderChar={'\u2000'}
        {...props}
      />
    );
  }
);

type MPEmailInputProps = Omit<MPFormInputProps, 'onChange'> & {
  readonly onChange?: (value: string) => void;
};

export const MPEmailInput = (props: MPEmailInputProps) => {
  const { onChange, label = 'E-mail', ...other } = props;

  const handleOnChange = (e: any) => {
    const el = e.target;
    const start = el.selectionStart;
    const end = el.selectionEnd;
    el.value = el.value.toLowerCase();
    el.setSelectionRange(start, end);
    onChange?.(el.value);
  };

  return (
    <MPFormInputInternal
      {...other}
      label={label}
      onChange={handleOnChange}
    />
  );
};
