import { RootState } from '@/data/store/store';
import { Nullable, UUID } from '@/domain';
import { createSelector } from '@reduxjs/toolkit';
import { EOfferActionType } from '@features/general/offer/types';
import { TradeOffersActionsState } from '@features/tradeOffer/actions/store/slice';

export const tradeOfferActionsDialogsSelector = (store: RootState) => store.tradeOffer.actions.dialogs;

export const getTradeOfferActionsOfferIsProcessingSelector = (id: Nullable<UUID>) =>
  createSelector(
    (store: RootState) => store.tradeOffer.actions.actions,
    (store: RootState) => store.tradeOffer.actions.create.isFetching,
    (actions, isCreating) => {
      return id ? actions.some(action => action.id === id && action.isFetching) : isCreating;
    }
  );

export const isTradeOffersAnyChangedSelector = (store: RootState) =>
  store.tradeOffer.actions.actions.filter(action => !action.isFetching).length;
export const tradeOfferActionsGetActionSelector = (action: keyof TradeOffersActionsState['dialogs']) =>
  createSelector(
    () => action,
    tradeOfferActionsDialogsSelector,
    (action, dialogs) => dialogs[action]
  );

export const getIsTradeOfferAnyActionByTypeExecutedSelector = (id: Nullable<UUID>, ...actions: EOfferActionType[]) =>
  createSelector(
    () => id,
    () => actions,
    (store: RootState) => store.tradeOffer.actions.actions,
    (id, argActions, storeActions) => {
      return id
        ? storeActions.find(action => action.id === id && action.isFetched && argActions.some(a => a === action.type))
            ?.type ?? null
        : null;
    }
  );
