import { AdCampaignCreate } from '@/domain/model/ads';
import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EAdCampaignActionType } from '../../types';
import AdCampaignActionsConfirmDialog from '../components/confirmDialog';
import {
  adCampaignActionsDialogSaveSelector,
  getAdCampaignActionsAdCampaignIsProcessingSelector,
} from '../store/selectors';
import { adCampaignActionsSave } from '../store/slice';
import { useAdCampaignActions } from '../useActions';
import { showAdCampaignActionNotification } from '../utils';

const getSaveText = (text: Nullable<string>) => (
  <Typography>
    Рекламная кампания «<b>{text}</b>» будет активирована
  </Typography>
);

const AdCampaignActionsDialogSaveAdapter = () => {
  const dispatch = useDispatch();

  const handlers = useAdCampaignActions();

  const object = useSelector(adCampaignActionsDialogSaveSelector);

  const isProcessing = useSelector(getAdCampaignActionsAdCampaignIsProcessingSelector(object?.id ?? null));

  const onSave = useCallback(
    (campaign: AdCampaignCreate) => {
      dispatch(adCampaignActionsSave(campaign))
        .unwrap()
        .then(response => showAdCampaignActionNotification(response, EAdCampaignActionType.Edit, !campaign?.id))
        .finally(onCloseDialog);
    },
    [dispatch]
  );

  const onCloseDialog = useCallback(() => {
    handlers.onChangeDialogState({ name: 'save', data: null });
  }, [handlers.onChangeDialogState]);

  return (
    object && (
      <AdCampaignActionsConfirmDialog
        campaign={object}
        title='Активировать рекламную кампанию?'
        actionText='Активировать'
        text={getSaveText(object.name)}
        onAction={campaign => onSave(campaign)}
        isFetching={isProcessing}
        onClose={onCloseDialog}
      />
    )
  );
};

export default AdCampaignActionsDialogSaveAdapter;
