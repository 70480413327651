import { Checkbox } from '@mui/material';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import React from 'react';
import { ClientOrgOption, TextOption } from './controls';
import {
  OrgStructureClientOrgMultipleAllClientOrgsState,
  OrgStructureClientOrgMultipleItemType,
  OrgStructureClientOrgMultipleTypesSelectorItem,
} from './types';

interface OrgStructureClientOrgMultipleItemProps {
  readonly elementProps: React.HTMLAttributes<HTMLLIElement>;
  readonly option: OrgStructureClientOrgMultipleTypesSelectorItem;
  readonly state: AutocompleteRenderOptionState;
}

const OrgStructureClientOrgMultipleItem = ({ elementProps, option, state }: OrgStructureClientOrgMultipleItemProps) => {
  switch (option.type) {
    case OrgStructureClientOrgMultipleItemType.Text:
      return (
        <TextOption
          color='textSecondary'
          variant='body2'
        >
          {option.name}
        </TextOption>
      );
    case OrgStructureClientOrgMultipleItemType.All:
      return (
        <ClientOrgOption {...elementProps}>
          <Checkbox
            color='primary'
            checked={state.selected}
            indeterminate={option.state === OrgStructureClientOrgMultipleAllClientOrgsState.Indeterminate}
          />
          {option.name}
        </ClientOrgOption>
      );
    case OrgStructureClientOrgMultipleItemType.None:
      return (
        <ClientOrgOption {...elementProps}>
          <Checkbox
            color='primary'
            checked={state.selected}
          />
          {option.name}
        </ClientOrgOption>
      );
    case OrgStructureClientOrgMultipleItemType.ClientOrg:
      return (
        <ClientOrgOption {...elementProps}>
          <Checkbox
            color='primary'
            checked={state.selected}
          />
          {option.name}
        </ClientOrgOption>
      );
  }
};

export default OrgStructureClientOrgMultipleItem;

export const getOrgStructureClientOrgMultipleItemAllClientOrgs = (
  state: OrgStructureClientOrgMultipleAllClientOrgsState
): OrgStructureClientOrgMultipleTypesSelectorItem => ({
  type: OrgStructureClientOrgMultipleItemType.All,
  id: OrgStructureClientOrgMultipleItemType.All,
  name: 'Все компании',
  state,
});

export const getOrgStructureClientOrgMultipleItemNoneClientOrgs = (
  state: OrgStructureClientOrgMultipleAllClientOrgsState
): OrgStructureClientOrgMultipleTypesSelectorItem => ({
  type: OrgStructureClientOrgMultipleItemType.None,
  id: OrgStructureClientOrgMultipleItemType.None,
  name: 'не выбрано',
  state,
});
