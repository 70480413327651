import { Divider } from '@mui/material';
import { FCC } from 'react';
import { Wrapper } from './controls';

export interface MenuPanelCommonHeaderProps {}

export const MenuPanelCommonHeader: FCC<MenuPanelCommonHeaderProps> = props => {
  const { children } = props;

  return (
    <Wrapper>
      {children}
      <Divider />
    </Wrapper>
  );
};
