import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../../data/store/store';
import { CmsContainer } from '../../../../../../domain/model/cms';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import { addSearchParamToLocation } from '../../../../../../routing/globalRouting';
import useCurrentUser from '../../../../user/hooks/useCurrentUser';
import { CmsContainerTableView } from '../../../types';
import { ECmsContainerUrlParam } from '../../types';
import { cmsContainersGuidSelector } from '../store/selectors';
import {
  cmsContainersArchiveCmsContainer,
  cmsContainersChangeCmsContainerSortIndex,
  cmsContainersDataReset,
  cmsContainersDeleteCmsContainer,
  cmsContainersDuplicateCmsContainer,
  cmsContainersPauseCmsContainer,
  cmsContainersResumeCmsContainer,
  cmsContainersSetContainerToChangeSortIndex,
} from '../store/slice';
import { ECmsContainerTableTab } from '../utils';

export type UseCmsContainersTable = {
  readonly onResetState: () => void;
  readonly onChangeTab: (tab: ECmsContainerTableTab, newTab: ECmsContainerTableTab) => void;
  readonly isCmsContainerDisabled: (cmsContainer: CmsContainer) => boolean;
  readonly onCmsContainerPause: (cmsContainer: CmsContainer) => Promise<boolean>;
  readonly onCmsContainerArchive: (cmsContainer: CmsContainer) => Promise<boolean>;
  readonly onCmsContainerDelete: (cmsContainer: CmsContainer) => Promise<boolean>;
  readonly onCmsContainerResume: (cmsContainer: CmsContainer) => Promise<boolean>;
  readonly onCmsContainerDuplicate: (sitePageId: UUID, cmsContainer: CmsContainer) => Promise<CmsContainer>;
  readonly onSetCmsContainerToChangeSortIndex: (cmsContainer: Nullable<CmsContainerTableView>) => void;
  readonly onCmsContainerChangeSortIndex: (cmsContainer: CmsContainer, sortIndex: number) => void;
};

export function useCmsContainersTableHandlers(): UseCmsContainersTable {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  
  const {
    userSpecific: { id: userId },
  } = useCurrentUser();

  const getCmsContainersGuidFromState = useCallback((): Nullable<UUID> => {
    const state = store.getState();
    return cmsContainersGuidSelector(state);
  }, [store]);
  
  const onResetState = useCallback(() => dispatch(cmsContainersDataReset()), [dispatch]);

  const isCmsContainerDisabled = useCallback(
    (cmsContainer: CmsContainer) => {
      return !!cmsContainer.lock && cmsContainer.lock.lockedBy.id !== userId;
    },
    [userId]
  );

  const onChangeTab = useCallback(
    (tab: ECmsContainerTableTab, newTab: ECmsContainerTableTab) => {
      if (newTab !== tab) {
        const guid = getCmsContainersGuidFromState();
        onResetState();
        history.replace(
          addSearchParamToLocation({
            location,
            param: ECmsContainerUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [history, location, getCmsContainersGuidFromState, onResetState]
  );
  
  const onCmsContainerPause = useCallback(
    async ({ id }: CmsContainer) => {
      const response = await dispatch(cmsContainersPauseCmsContainer({ id })).unwrap();
      return !!response?.id;
    },
    [dispatch]
  );

  const onCmsContainerResume = useCallback(
    async ({ id }: CmsContainer) => {
      const response = await dispatch(cmsContainersResumeCmsContainer({ id })).unwrap();
      return !!response?.id;
    },
    [dispatch]
  );

  const onCmsContainerArchive = useCallback(
    async ({ id }: CmsContainer) => {
      const response = await dispatch(cmsContainersArchiveCmsContainer({ id })).unwrap();
      return !!response?.id;
    },
    [dispatch]
  );

  const onCmsContainerDelete = useCallback(
    async ({ id }: CmsContainer) => {
      await dispatch(cmsContainersDeleteCmsContainer({ id })).unwrap();
      return true;
    },
    [dispatch]
  );

  const onCmsContainerDuplicate = useCallback(
    async (sitePageId: UUID, { id }: CmsContainer) => {
      return await dispatch(cmsContainersDuplicateCmsContainer({ sitePageId, id })).unwrap();
    },
    [dispatch]
  );

  const onCmsContainerChangeSortIndex = useCallback(
    (cmsContainer: CmsContainer, sortIndex: number) =>
      dispatch(
        cmsContainersChangeCmsContainerSortIndex({
          id: cmsContainer.id,
          sortIndex,
        })
      ),
    [dispatch]
  );

  const onSetCmsContainerToChangeSortIndex = useCallback(
    (cmsContainer: Nullable<CmsContainerTableView>) => {
      dispatch(cmsContainersSetContainerToChangeSortIndex({ cmsContainer }));
    },
    [dispatch]
  );

  return {
    onResetState,
    onChangeTab,
    isCmsContainerDisabled,
    onCmsContainerPause,
    onCmsContainerArchive,
    onCmsContainerChangeSortIndex,
    onSetCmsContainerToChangeSortIndex,
    onCmsContainerDelete,
    onCmsContainerResume,
    onCmsContainerDuplicate,
  };
}
