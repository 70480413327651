import {
  ECorpPersonalPromotionType,
  EOfferPausedReason,
  EOfferStatus,
  EOfferType,
  ETradeOfferType,
  SportOption,
} from '@/domain';

export const getOfferPausedReasonText = (reason: SportOption): string => {
  return !reason.code || reason.code === EOfferPausedReason.PausedByAdmin ? 'Остановлена Администратором' : reason.name;
};

export const isOfferCanChangePromotionType = (status: EOfferStatus, offerType: EOfferType): boolean => {
  switch (offerType) {
    case EOfferType.Trade:
      return [EOfferStatus.Draft, EOfferStatus.Rejected, EOfferStatus.OnModeration].includes(status);
    case EOfferType.Corp:
      return [
        EOfferStatus.Draft,
        EOfferStatus.Rejected,
        EOfferStatus.OnModeration,
        EOfferStatus.Active,
        EOfferStatus.Upcoming,
        EOfferStatus.Paused,
      ].includes(status);
    case EOfferType.Product:
      return false;
    case EOfferType.Booking:
      return false;
  }
};

export const isOfferTypeWithCodes = (type: ETradeOfferType | ECorpPersonalPromotionType): boolean => {
  switch (type) {
    case ETradeOfferType.Voucher:
    case ETradeOfferType.PromoCode:
    case ETradeOfferType.AccessCode:
    case ETradeOfferType.Asp:
    case ECorpPersonalPromotionType.Certificate:
    case ECorpPersonalPromotionType.ExternalCertificate:
      return true;
    default:
      return false;
  }
};
