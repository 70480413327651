import Notifier from '@/system/notifier';
import { ENoticeStatus } from '@/domain/model/enums';
import { EClientOrgUserActionType } from '@features/user/userClientOrg/types';

export const addSuccessNotice = (text: string) => {
  Notifier.getInstance().addNotice(ENoticeStatus.Success, text);
};

export const showClientOrgActionNotification = (action: EClientOrgUserActionType) => {
  switch (action) {
    case EClientOrgUserActionType.Import: {
      addSuccessNotice(`Пользователи успешно добавлены`);
      break;
    }
  }
};
