import { Grid } from '@mui/material';
import React from 'react';
import { SportOption } from '../../../../../domain/model';
import { Nullable } from '../../../../../domain/model/types';
import PartnerRejectInfoPanel from '../../../general/partner/components/rejectInfoPanel';
import { Wrapper } from './controls';

interface PartnerApplicationEditInfoColumnProps {
  readonly rejectReasonType?: Nullable<SportOption>;
  readonly rejectReasonComment?: Nullable<string>;
  readonly children?: React.ReactNode;
}

const PartnerApplicationEditInfoColumn = (props: PartnerApplicationEditInfoColumnProps) => {
  const { rejectReasonType, rejectReasonComment, children } = props;

  return (
    <Wrapper>
      <Grid
        container
        spacing={3}
        direction={'column'}
      >
        {rejectReasonType && (
          <Grid item>
            <PartnerRejectInfoPanel
              rejectReason={rejectReasonType}
              text={rejectReasonComment}
            />
          </Grid>
        )}
        {children}
      </Grid>
    </Wrapper>
  );
};

export default PartnerApplicationEditInfoColumn;
