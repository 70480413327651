import { EDateFormat } from '@/domain';
import moment from 'moment/moment';
import BannerTableCell, { BannerTableCellCommonProps } from './index';

const BannerTableCellStartDate = (props: BannerTableCellCommonProps) => {
  const {
    banner: { startDate },
  } = props;

  const value = startDate && moment(startDate).format(EDateFormat.DisplayDefault);

  return (
    <BannerTableCell
      {...props}
      value={value}
    />
  );
};

export default BannerTableCellStartDate;
