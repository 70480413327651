import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Feedback } from '../../feedback';
import { useFeedback } from '../../feedback/useFeedback';
import { useContextHandlers } from './hooks/useContextHandlers';
import { bookingOrderDetailsBookingOrderSelector, bookingOrderDetailsDialogsSelector } from './store/selectors';

export const BookingOrderDetailsDialogsAdapter = () => {
  const handlers = useContextHandlers();
  const { onSubmitFeedback } = useFeedback();

  const dialogs = useSelector(bookingOrderDetailsDialogsSelector);
  const offer = useSelector(bookingOrderDetailsBookingOrderSelector);

  const onHideFeedback = useCallback(() => {
    handlers.onChangeDialogState('feedback', null);
  }, [handlers.onChangeDialogState]);

  if (!offer) {
    return null;
  }

  return (
    <>
      {dialogs.feedback && (
        <Feedback
          open
          onClose={onHideFeedback}
          onSubmit={onSubmitFeedback}
        />
      )}
    </>
  );
};
