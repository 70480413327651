import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable, SportOption } from '../../../../../domain/model';
import { pageSizeAll } from '../../../../../domain/model/constants';
import { EPlaygroundModerationStatus, EUserRole, EUserStatus } from '../../../../../domain/model/enums';
import { Playground } from '../../../../../domain/model/playground';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { SportUserProfile } from '../../../../../domain/model/user';
import { PaginationSize } from '../../../../types';
import { PlaygroundTableFilterValues } from '../../filterUtils';
import playgroundServices, { AllProps } from '../../services';
import { EPlaygroundTableColumn, EPlaygroundTableTab, PlaygroundTableTabsCounter } from '../utils';

const defaultSort = `${EPlaygroundTableColumn.Name},asc`;

export type PlaygroundsFetchProps = Omit<AllProps, 'signal'>;

type PlaygroundsCountsFetchProps = PlaygroundsFetchProps & {
  readonly tabs: EPlaygroundTableTab[];
};

export const playgroundsFetch = createAsyncThunk<Pageable<Playground>, PlaygroundsFetchProps, AppThunkAPIConfig>(
  'playground/list/fetch',
  async (props, { rejectWithValue }) => {
    try {
      return await playgroundServices.common.all(props);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

interface PlaygroundsAdminsFetchProps {
  readonly name: string;
}

export const playgroundModeratorsFetch = createAsyncThunk<any, PlaygroundsAdminsFetchProps, AppThunkAPIConfig>(
  'playground/list/moderators/fetch',
  async ({ name }, { rejectWithValue }) => {
    try {
      const { data: response } = await Api.user.sport.all({
        page: 1,
        pageSize: pageSizeAll,
        name,
        sort: 'lastName,asc',
        roles: [EUserRole.EventAdmin, EUserRole.SystemAdmin, EUserRole.ComplaintAdmin],
        statuses: [EUserStatus.Enabled, EUserStatus.NotFilled, EUserStatus.Disabled, EUserStatus.Deleted],
      });
      const { content: data } = response;

      return { data };
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export const playgroundsCountsFetch = createAsyncThunk<
  PlaygroundTableTabsCounter,
  PlaygroundsCountsFetchProps,
  AppThunkAPIConfig
>('playground/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await playgroundServices.common.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

export const playgroundCreatorsFetch = createAsyncThunk<SportOption[], PlaygroundsAdminsFetchProps, AppThunkAPIConfig>(
  'playground/list/creators/fetch',
  async ({ name }, { rejectWithValue }) => {
    try {
      return await playgroundServices.users.creators({ name });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export interface PlaygroundsListState extends Fetchable, Pageable<Playground> {
  readonly guid: UUID;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
  };
  readonly tab: Nullable<EPlaygroundTableTab>;
  readonly filter: PlaygroundTableFilterValues;
  readonly searchedModerators: Fetchable & {
    readonly data: SportUserProfile[];
  };
  readonly searchedCreators: Fetchable & {
    readonly data: SportOption[];
  };
  readonly tabsCounter: PlaygroundTableTabsCounter;
}

type Reducer<T = undefined> = CaseReducer<PlaygroundsListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PlaygroundsListState> {
  playgroundsStartSession: Reducer<{ guid: UUID }>;
  playgroundsSetSearch: Reducer<{
    sort: string;
    pageSize: PaginationSize;
    moderationStatus: Nullable<EPlaygroundModerationStatus>;
  }>;
  playgroundsSetPage: Reducer<{ pageNumber: number }>;
  playgroundsSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  playgroundsSetFilter: Reducer<PlaygroundTableFilterValues>;
  playgroundsResetFilter: Reducer;
  playgroundsResetNeedRefreshWatcher: Reducer;
  playgroundsModeratorsClear: Reducer;
  playgroundsCreatorsClear: Reducer;
  playgroundsSortReset: Reducer;
  playgroundsDataReset: Reducer;
  playgroundsSetTab: Reducer<{ tab: EPlaygroundTableTab }>;
  playgroundSetSort: Reducer<{ sort: string }>;
}

const slice = createSlice<PlaygroundsListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    ...fetchableDefault,
    guid: uuidv4(),
    data: [],
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    needRefreshWatcher: 0,
    search: {
      sort: defaultSort,
      pageSize: 15,
    },
    tab: null,
    filter: {},
    searchedCreators: {
      ...fetchableDefault,
      data: [],
    },
    searchedModerators: {
      ...fetchableDefault,
      data: [],
    },
    tabsCounter: {},
  },
  reducers: {
    playgroundsStartSession: (state, { payload }) => {
      const { guid } = payload;
      if (guid !== state.guid) {
        state.guid = guid;
        state.tabsCounter = {};
        state.needRefreshWatcher = 0;

        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;

        state.tab = null;
      }
    },
    playgroundsResetNeedRefreshWatcher: state => {
      state.needRefreshWatcher = 0;
    },
    playgroundsSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    playgroundSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    playgroundsSetSearch: (state, { payload }) => {
      state.search = {
        ...state.search,
        ...payload,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    playgroundsSetFilter: (state, { payload }) => {
      state.filter = { ...payload };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    playgroundsResetFilter: state => {
      state.filter = {};
      state.searchedModerators = {
        ...fetchableDefault,
        data: [],
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    playgroundsSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    playgroundsSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    playgroundsModeratorsClear: state => {
      state.searchedModerators = {
        ...fetchableDefault,
        data: [],
      };
    },
    playgroundsDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
    },
    playgroundsSetTab: (state, { payload: { tab } }) => {
      // сбрасываем только пейджинг, если поменялся таб (сортировку смысла нет - набор полей одинаков)
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    playgroundsCreatorsClear: state => {
      state.searchedCreators = {
        ...fetchableDefault,
        data: [],
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(playgroundsFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
      })
      .addCase(playgroundsFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(playgroundsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(playgroundModeratorsFetch.pending, state => {
        state.searchedModerators.isFetching = true;
        state.searchedModerators.isFetched = false;
        state.searchedModerators.isFailed = false;
      })
      .addCase(playgroundModeratorsFetch.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.searchedModerators.isFetching = false;
        state.searchedModerators.isFetched = true;
        state.searchedModerators.isFailed = false;
        state.searchedModerators.data = data;
      })
      .addCase(playgroundModeratorsFetch.rejected, state => {
        state.searchedModerators.isFetching = false;
        state.searchedModerators.isFetched = false;
        state.searchedModerators.isFailed = true;
        state.searchedModerators.data = [];
      })
      .addCase(playgroundCreatorsFetch.pending, state => {
        state.searchedCreators.isFetching = true;
        state.searchedCreators.isFetched = false;
        state.searchedCreators.isFailed = false;
      })
      .addCase(playgroundCreatorsFetch.fulfilled, (state, { payload }) => {
        state.searchedCreators.isFetching = false;
        state.searchedCreators.isFetched = true;
        state.searchedCreators.isFailed = false;
        state.searchedCreators.data = payload;
      })
      .addCase(playgroundCreatorsFetch.rejected, state => {
        state.searchedCreators.isFetching = false;
        state.searchedCreators.isFetched = false;
        state.searchedCreators.isFailed = true;
        state.searchedCreators.data = [];
      })
      .addCase(playgroundsCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(playgroundsCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      });
  },
});

export const {
  playgroundsSetSearch,
  playgroundsSetFilter,
  playgroundsSetPage,
  playgroundsSetPageSize,
  playgroundsResetFilter,
  playgroundsModeratorsClear,
  playgroundsCreatorsClear,
  playgroundsStartSession,
  playgroundsResetNeedRefreshWatcher,
  playgroundsSortReset,
  playgroundSetSort,
  playgroundsDataReset,
  playgroundsSetTab,
} = slice.actions;

export default slice.reducer;
