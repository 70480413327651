/*
 * односложные условия, если несколько условий то они всегда должны работать по принципу И
 */

import { EOfferStatus } from '../../../../../domain/model/enums';
import { ProductLifeCycleCondition } from '../types';

export const hasNotProductDeskCondition: ProductLifeCycleCondition = {
  description: 'Отсутствие карточки',
  algorithm: ['Отсутствует карточка товара'],
  call: ({ obj }) => {
    const { productDesk } = obj;
    return !productDesk;
  },
};

export const isWaitingForModerationCondition: ProductLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Ожидает модерации'],
  call: ({ obj }) => {
    const { status } = obj;

    return status === EOfferStatus.WaitingForModeration;
  },
};

export const isNotWaitingForModerationCondition: ProductLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Не ожидает модерации'],
  call: props => !isWaitingForModerationCondition.call(props),
};

export const isModeratedByMeCondition: ProductLifeCycleCondition = {
  description: 'Проверка по статусу и модератору',
  algorithm: ['На модерации у текущего пользователя'],
  call: ({ userId, obj }) => {
    const { status, moderator } = obj;
    const isModerator = userId === moderator?.id;

    return status === EOfferStatus.OnModeration && isModerator;
  },
};

export const isNotOnModerationByMeCondition: ProductLifeCycleCondition = {
  description: 'Проверка по статусу и модератору',
  algorithm: ['На модерации у другого пользователя'],
  call: ({ userId, obj }) => {
    const { status, moderator } = obj;
    const isModerator = userId === moderator?.id;

    return status === EOfferStatus.OnModeration && !isModerator;
  },
};

export const isOnModerationCondition: ProductLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['На модерации'],
  call: ({ userId, obj }) => {
    const { status } = obj;

    return status === EOfferStatus.OnModeration;
  },
};

export const isRejectedCondition: ProductLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Отклонен'],
  call: ({ obj }) => {
    const { status } = obj;

    return status === EOfferStatus.Rejected;
  },
};

export const isPausedCondition: ProductLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Приостановлен'],
  call: ({ obj }) => {
    const { status } = obj;

    return status === EOfferStatus.Paused;
  },
};

export const isNotSavedProductCondition: ProductLifeCycleCondition = {
  description: 'Проверка по ID',
  algorithm: ['Запись ещё не сохранена'],
  call: ({ obj }) => {
    const { id } = obj;
    return !id;
  },
};
