import styled from '@emotion/styled';
import { forwardRef } from 'react';
import { css, Typography, TypographyProps } from '@mui/material';

type ContentProps = TypographyProps & {
  readonly rows: number;
};

export const Content = styled(
  forwardRef(({ lineHeight, rows, ...props }: ContentProps, ref: any) => (
    <Typography
      ref={ref}
      {...props}
    />
  ))
)(
  ({ rows }) => css`
    display: -webkit-box;
    -webkit-line-clamp: ${rows};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  `
);
