import { useCallback, useEffect, useState } from 'react';

type UseWindowResize = () => {
  width: number;
  height: number;
};

export const useWindowResize: UseWindowResize = () => {
  const [params, setParams] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const onResize = useCallback((event: any) => {
    setParams({
      width: event.currentTarget.innerWidth,
      height: event.currentTarget.innerHeight,
    });
  }, []);

  useEffect(() => {
    setParams({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return params;
};
