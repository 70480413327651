import { ClientOrgWidget } from '../../components/widget';
import { useSelector } from 'react-redux';
import { clientOrgCreateClientOrgSelector } from '../store/selectors';
import ClientOrgPreview from '../../components/preview';

export const ClientOrgCreatePreviewAdapter = () => {
  const { logo } = useSelector(clientOrgCreateClientOrgSelector);

  return (
    <ClientOrgWidget label={'Предпросмотр'}>
      <ClientOrgPreview image={logo} />
    </ClientOrgWidget>
  );
};
