import { Banner, EBannerPartition, EBannerStatus } from '@/domain';
import { getBannerDetailsRoute, getBannerEditRoute } from '@features/banner/entry';
import { EBannerTableTab } from '@features/banner/table/utils';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import useCurrentUser from '../../../user/hooks/useCurrentUser';

export type UseBannerTableConfig = {
  readonly partition: EBannerPartition;
  readonly defaultTab: EBannerTableTab;
  readonly tabsForCounts: EBannerTableTab[];
  readonly onBannerClick: (banner: Banner) => void;
};

export type UseBannerTableConfigProps = {
  readonly partition: EBannerPartition;
};

export function useBannerTableConfig({ partition }: UseBannerTableConfigProps): UseBannerTableConfig {
  const history = useHistory();

  const {
    accessMatrix: { banners },
  } = useCurrentUser();

  const access = banners[partition];

  const onBannerClick = useCallback(
    (banner: Banner) => {
      const { status } = banner;
      switch (status) {
        case EBannerStatus.Paused:
          if (access.edit) {
            history.push(getBannerEditRoute({ id: banner.id }));
          } else {
            history.push(getBannerDetailsRoute({ id: banner.id }));
          }
          break;
        case EBannerStatus.Draft:
          if (access.create) {
            history.push(getBannerEditRoute({ id: banner.id }));
          } else {
            history.push(getBannerDetailsRoute({ id: banner.id }));
          }
          break;
        case EBannerStatus.Active:
        case EBannerStatus.Upcoming:
        case EBannerStatus.Archived:
          history.push(getBannerDetailsRoute({ id: banner.id }));
          break;
        default:
          history.push(getBannerDetailsRoute({ id: banner.id }));
      }
    },
    [history, access]
  );

  const defaultTab = EBannerTableTab.Active;

  const tabsForCounts = [
    EBannerTableTab.Active,
    EBannerTableTab.Upcoming,
    EBannerTableTab.Paused,
    EBannerTableTab.Draft,
    EBannerTableTab.Archived,
  ];

  return {
    partition,
    defaultTab,
    tabsForCounts,
    onBannerClick,
  };
}
