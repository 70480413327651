import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2.5)};
`;

interface ContentWrapperProps {
  readonly gap: number;
  readonly direction: 'row' | 'column';
  readonly children?: any;
}

export const ContentWrapper = styled(({ gap, direction, ...props }: ContentWrapperProps) => <div {...props} />)`
  display: flex;
  flex-direction: ${p => p.direction};
  gap: ${p => p.theme.spacing(p.gap)};
`;
