import ErrorHandler from '@/data/network/errorHandler';
import { Address, AddressId } from '@/domain/model/address';
import { EAddressLevel } from '@/domain/model/enums';
import { Nullable } from '@/domain/model/types';
import { AddressHelper } from '@/presentation/utils/address';
import axios, { CancelTokenSource } from 'axios';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useRef, useState } from 'react';
import addressServices from '../../../service';
import {
  getAddressMultipleItemAll,
  getAddressMultipleItemDefaultSourceFooter,
  getAddressMultipleItemDefaultSourceHeader,
  getAddressMultipleItemNone,
} from './item';
import {
  AddressMultipleAllItemsState,
  AddressMultipleItemType,
  AddressMultipleSelectType,
  AddressMultipleTypesSelectorItem,
} from './types';

interface useAddressMultipleTypesProps {
  readonly select?: Nullable<AddressMultipleSelectType>;
  readonly selectNone?: boolean;
  readonly addresses: Address[];
  readonly locations?: AddressId[];
  readonly fromLevel: EAddressLevel;
  readonly toLevel: EAddressLevel;
  /* набор данных по умолчанию (в моменты когда нет поискового запроса) */
  readonly defaultSource?: Nullable<Address[]>;
  readonly onSelect: (type: AddressMultipleSelectType) => void;
  readonly onChange: (cities: Address[]) => void;
}

interface UseAddressCityMultipleTypes {
  readonly options: AddressMultipleTypesSelectorItem[];
  readonly values: AddressMultipleTypesSelectorItem[];
  readonly isLoading: boolean;
  readonly setSearchValue: (value: string) => void;
  readonly onChangeValue: (value: Nullable<AddressMultipleTypesSelectorItem[]>) => void;
  readonly getOptionLabel: (option: AddressMultipleTypesSelectorItem) => string;
}

const useAddressMultipleTypes = (props: useAddressMultipleTypesProps): UseAddressCityMultipleTypes => {
  const { select, selectNone, addresses, fromLevel, toLevel, locations, defaultSource, onSelect, onChange } = props;

  const [suggestions, setSuggestions] = useState<AddressMultipleTypesSelectorItem[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const onSearchInternal = useRef(debounce(newValue => setSearchValue(newValue), 500)).current;

  useEffect(() => {
    const cancelCallback: CancelTokenSource = axios.CancelToken.source();
    const cancelToken = cancelCallback.token;

    if (!searchValue) {
      return;
    }

    setIsLoading(true);
    addressServices.common
      .addressByQuery({ query: searchValue, fromLevel, toLevel, locations, cancelToken })
      .then(data => {
        const newAddresses: AddressMultipleTypesSelectorItem[] = data
          .filter(item => item.level.id === fromLevel || item.level.id === toLevel)
          .sort((item1, item2) => item1.shortName.localeCompare(item2.shortName))
          .map(item => ({
            ...item,
            type: AddressMultipleItemType.Address,
          }));
        setSuggestions(newAddresses);
      })
      .catch(e => {
        ErrorHandler.handleHttpError(e, e.response);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      cancelCallback?.cancel();
    };
  }, [searchValue, locations]);

  const onChangeValue = useCallback(
    (value: Nullable<AddressMultipleTypesSelectorItem[]>) => {
      if (
        value?.some(
          item => item.type === AddressMultipleItemType.All && item.state !== AddressMultipleAllItemsState.Checked
        )
      ) {
        onSelect(AddressMultipleSelectType.All);
      } else if (
        value?.some(
          item => item.type === AddressMultipleItemType.None && item.state !== AddressMultipleAllItemsState.Checked
        )
      ) {
        onSelect(AddressMultipleSelectType.None);
      } else {
        const selectedCities = (value?.filter(v => v.type === AddressMultipleItemType.Address) as Address[]) ?? [];
        onChange(selectedCities);
      }
    },
    [onChange, onSelect, select]
  );

  const getOptionLabel = useCallback((option: AddressMultipleTypesSelectorItem) => {
    switch (option.type) {
      case AddressMultipleItemType.Address:
        return new AddressHelper(option).getLastLocalityShortPath() ?? '';
      default:
        return option.name;
    }
  }, []);

  const options: AddressMultipleTypesSelectorItem[] = [];

  if (selectNone) {
    options.push(
      getAddressMultipleItemNone(
        select === AddressMultipleSelectType.None
          ? AddressMultipleAllItemsState.Checked
          : AddressMultipleAllItemsState.NotChecked
      )
    );
  }

  //всегда добавляем первым "Вся Россия"
  options.push(
    getAddressMultipleItemAll(
      select === AddressMultipleSelectType.All
        ? AddressMultipleAllItemsState.Checked
        : addresses?.length
        ? AddressMultipleAllItemsState.Indeterminate
        : AddressMultipleAllItemsState.NotChecked
    )
  );

  //если не введен текст для поиска то добавляем дефолтный список
  if (!searchValue && defaultSource?.length) {
    options.push(getAddressMultipleItemDefaultSourceHeader());
    defaultSource.forEach(item =>
      options.push({
        ...item,
        type: AddressMultipleItemType.Address,
      })
    );
    options.push(getAddressMultipleItemDefaultSourceFooter());
  } else {
    suggestions.forEach(item =>
      options.push({
        ...item,
      })
    );
  }

  const values: AddressMultipleTypesSelectorItem[] = addresses.map(item => ({
    ...item,
    type: AddressMultipleItemType.Address,
  }));

  if (select === AddressMultipleSelectType.All) {
    values.push(getAddressMultipleItemAll(AddressMultipleAllItemsState.Checked));
  }

  if (select === AddressMultipleSelectType.None) {
    values.push(getAddressMultipleItemNone(AddressMultipleAllItemsState.Checked));
  }

  return {
    options,
    values,
    isLoading,
    setSearchValue: onSearchInternal,
    onChangeValue,
    getOptionLabel,
  };
};

export default useAddressMultipleTypes;
