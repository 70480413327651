import { AdCampaignsLocationState, EAdCampaignUrlParam } from '@features/adCampaign/entry';
import AdCampaignTableEntry from '@features/adCampaign/table/entry';
import { EAdCampaignTableTab } from '@features/adCampaign/types';
import { viewShowMainMenu } from '@features/main/container/store/slice';
import { Nullable } from 'domain/model/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ForbiddenComponent from '../../components/common/forbidden';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const AdCampaignsScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation<Nullable<AdCampaignsLocationState>>();

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(EAdCampaignUrlParam.Tab) as EAdCampaignTableTab;

  const {
    accessMatrix: { adCampaigns, isPartnerUser },
    mpPartnerUserProfile,
  } = useCurrentUser();

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (!adCampaigns?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<AdCampaignsLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <AdCampaignTableEntry
          guid={state.guid}
          tab={tab}
          partnerId={isPartnerUser ? mpPartnerUserProfile.partner.id : null}
        />
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default AdCampaignsScreen;
