import { Fetchable } from '@/data/store/types';
import { AnyOffer, Nullable, UUID } from '@/domain';
import { PaginationSize } from '@/presentation/types';
import OfferTableCellEndDate from '@features/general/offer/components/collection/table/cell/endDate';
import { useMemo, useState } from 'react';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../../../../components/common/table';
import DataTableCellTextFormat from '../../../../../../components/common/table/cell/textFormat';
import TablePagination from '../../../../../../components/common/table/pagination';
import { EOfferTableColumn } from '../../../types';
import OfferTableCellCode from './cell/code';
import OfferTableCellName from './cell/name';
import OfferTableCellSortIndex from './cell/sortIndex';
import { RemoveIcon, Wrapper } from './controls';

interface OfferCollectionTableProps<T extends AnyOffer> {
  readonly metadata: DataTableMetadata<EOfferTableColumn>;
  readonly sortIndexInfo: Fetchable & {
    readonly fetch: () => void;
    readonly max: number;
  };
  readonly offers: T[];
  readonly onChangeSortIndex?: ((offer: T, sortIndex: number) => void) | false;
  readonly onRemove?: (id: UUID) => void;
}

const minPaginationSize: Extract<PaginationSize, 5> = 5;

const OfferCollectionTable = <T extends AnyOffer>(props: OfferCollectionTableProps<T>) => {
  const { metadata, sortIndexInfo, offers, onChangeSortIndex, onRemove } = props;

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<PaginationSize>(10);

  const [activeOffer, setActiveOffer] = useState<Nullable<T>>(null);
  const [editableOffer, setEditableOffer] = useState<Nullable<T>>(null);

  const pageCount = useMemo<number>(() => Math.ceil(offers.length / pageSize), [offers, pageSize]);

  const offersDisplay = useMemo<T[]>(
    () => offers.slice((page - 1) * pageSize, page * pageSize),
    [offers, page, pageSize]
  );

  const rows: DataTableRow<T, EOfferTableColumn>[] = offersDisplay?.map((offer, i) => ({
    [EOfferTableColumn.Code]: <OfferTableCellCode code={offer.code} />,
    [EOfferTableColumn.SortIndex]: onChangeSortIndex ? (
      <OfferTableCellSortIndex<T>
        activeOffer={activeOffer}
        editableOffer={editableOffer}
        onChangeSortIndex={onChangeSortIndex}
        setEditableOffer={setEditableOffer}
        sortIndexInfo={sortIndexInfo}
        sortIndex={(page - 1) * pageSize + i + 1}
        offer={offer}
      />
    ) : (
      <DataTableCellTextFormat>{(page - 1) * pageSize + i + 1}</DataTableCellTextFormat>
    ),
    [EOfferTableColumn.Name]: <OfferTableCellName name={offer.name} />,
    [EOfferTableColumn.EndDate]: <OfferTableCellEndDate endDate={offer.endDate} />,
    data: offer,
  }));

  return (
    <Wrapper>
      <DataTable<T, EOfferTableColumn>
        metadata={metadata}
        rows={rows}
        rowActions={!!onRemove}
        onRowHover={(event, cell, { data }, hover) => setActiveOffer(hover ? data : null)}
        getRowActions={data => (
          <RemoveIcon
            color='disabled'
            fontSize='small'
            onClick={() => onRemove?.(data.id)}
          />
        )}
      />
      {offers.length > minPaginationSize && (
        <TablePagination
          dense
          page={page}
          pageSize={pageSize}
          pageCount={pageCount}
          objectsCount={offers.length}
          objectsLabel=''
          onChangePageSize={setPageSize}
          onChangePage={setPage}
        />
      )}
    </Wrapper>
  );
};

export default OfferCollectionTable;
