import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterValueItem,
  EDataFilterType,
} from '../../../../../domain/model/filter';
import { Nullable } from '../../../../../domain/model/types';
import { EAdminMpTableColumn } from './utils';

export enum EAdminMpFilterItem {
  Query = 'query',
  LastName = 'lastName',
  FirstName = 'firstName',
  MiddleName = 'middleName',
  Phone = 'phone',
  Email = 'email',
}

export const adminMpFilterItems: Record<EAdminMpTableColumn, EAdminMpFilterItem[]> = {
  [EAdminMpTableColumn.Name]: [
    EAdminMpFilterItem.LastName,
    EAdminMpFilterItem.FirstName,
    EAdminMpFilterItem.MiddleName,
  ],
  [EAdminMpTableColumn.Phone]: [EAdminMpFilterItem.Phone],
  [EAdminMpTableColumn.Email]: [EAdminMpFilterItem.Email],
};

export type AdminMpFilterEditStrategy = DataFilterStrategyBase<EAdminMpFilterItem>;
export type AdminMpFilterValues = {
  readonly [EAdminMpFilterItem.Query]?: DataFilterValueItem<Nullable<string>>;
  readonly [EAdminMpFilterItem.FirstName]?: DataFilterValueItem<Nullable<string>>;
  readonly [EAdminMpFilterItem.LastName]?: DataFilterValueItem<Nullable<string>>;
  readonly [EAdminMpFilterItem.MiddleName]?: DataFilterValueItem<Nullable<string>>;
  readonly [EAdminMpFilterItem.Phone]?: DataFilterValueItem<Nullable<string>>;
  readonly [EAdminMpFilterItem.Email]?: DataFilterValueItem<Nullable<string>>;
};

export const getAdminMpFilterStrategy = (
  filterItem: EAdminMpFilterItem,
  values: AdminMpFilterValues
): Nullable<AdminMpFilterEditStrategy> => {
  switch (filterItem) {
    case EAdminMpFilterItem.Query:
      return {
        type: EDataFilterType.String,
        key: EAdminMpFilterItem.Query,
        label: 'Поиск по ключевому слову',
        preview: values[EAdminMpFilterItem.Query]?.value || null,
        value: values[EAdminMpFilterItem.Query]?.value || null,
      };
    case EAdminMpFilterItem.FirstName:
      return {
        type: EDataFilterType.String,
        key: EAdminMpFilterItem.FirstName,
        label: 'Имя',
        preview:
          [
            values[EAdminMpFilterItem.FirstName]?.value,
            values[EAdminMpFilterItem.LastName]?.value,
            values[EAdminMpFilterItem.MiddleName]?.value,
          ]
            .filter(v => !!v)
            .join(' ')
            .trim() || null,
        value: values[EAdminMpFilterItem.FirstName]?.value || null,
        group: 'fio',
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case EAdminMpFilterItem.LastName:
      return {
        type: EDataFilterType.String,
        key: EAdminMpFilterItem.LastName,
        label: 'Фамилия',
        preview:
          [
            values[EAdminMpFilterItem.FirstName]?.value,
            values[EAdminMpFilterItem.LastName]?.value,
            values[EAdminMpFilterItem.MiddleName]?.value,
          ]
            .filter(v => !!v)
            .join(' ')
            .trim() || null,
        value: values[EAdminMpFilterItem.LastName]?.value || null,
        group: 'fio',
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case EAdminMpFilterItem.MiddleName:
      return {
        type: EDataFilterType.String,
        key: EAdminMpFilterItem.MiddleName,
        label: 'Отчество',
        preview:
          [
            values[EAdminMpFilterItem.FirstName]?.value,
            values[EAdminMpFilterItem.LastName]?.value,
            values[EAdminMpFilterItem.MiddleName]?.value,
          ]
            .filter(v => !!v)
            .join(' ')
            .trim() || null,
        value: values[EAdminMpFilterItem.MiddleName]?.value || null,
        group: 'fio',
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case EAdminMpFilterItem.Phone:
      return {
        type: EDataFilterType.String,
        key: EAdminMpFilterItem.Phone,
        label: 'Телефон',
        preview: values[EAdminMpFilterItem.Phone]?.value || null,
        value: values[EAdminMpFilterItem.Phone]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case EAdminMpFilterItem.Email:
      return {
        type: EDataFilterType.String,
        key: EAdminMpFilterItem.Email,
        label: 'Email',
        preview: values[EAdminMpFilterItem.Email]?.value || null,
        value: values[EAdminMpFilterItem.Email]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    default:
      return null;
  }
};
