import { FC } from 'react';
import { useSelector } from 'react-redux';
import { CorpActivationTableCellCommonProps } from '../../components/tableCell';
import { corpActivationsCorpActivationByIndexSelector } from '../store/selectors';

export type CorpActivationTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<CorpActivationTableCellCommonProps>;
};

const CorpActivationTableCellAdapter = (props: CorpActivationTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const corpActivation = useSelector(corpActivationsCorpActivationByIndexSelector(index));

  return corpActivation ? <Component corpActivation={corpActivation} /> : null;
};

export default CorpActivationTableCellAdapter;
