import React, { FCC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { ButtonsWrapper, ContentWrapper, List, ListItem, ListWrapper } from './controls';
import { CascadeItemWithChildren, ChangeType } from './types';
import { Grid, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { MPButton } from '../../../theme/ui-kit/button';
import { findInTree } from './utils';

type TreeSelectComponentProps<T> = {
  readonly items: CascadeItemWithChildren<T>[];
  readonly value?: CascadeItemWithChildren<T>[];
  readonly onClick: (id: string) => void;
  readonly onSubmit: () => void;
  readonly changeType: ChangeType;
};

type ColumnListProps = {
  height: number;
  windowHeight: number;
  scrollToElementId?: string;
};

const ColumnList: FCC<ColumnListProps> = props => {
  const { height = 0, children, scrollToElementId } = props;
  const [contentWrapperHeight, setContentWrapperHeight] = useState<number>(0);
  const scrollRef = useRef<OverlayScrollbarsComponent>(null);
  const listRef = useRef<any>(null);

  useEffect(() => {
    setContentWrapperHeight(height);
  }, []);

  useEffect(
    () => {
      if (scrollToElementId && scrollRef.current) {
        const osInstance = scrollRef.current?.osInstance();
        if (osInstance) {
          const viewport = osInstance.getElements('viewport');
          const el = viewport.querySelector('[data-id="' + scrollToElementId + '"]');

          if (el) {
            osInstance.scroll(el, 300);
          }
        }
      }
    } /*, [scrollToElementId, height, listRef.current, scrollRef.current]*/
  );

  return (
    <ListWrapper height={contentWrapperHeight - 100}>
      <OverlayScrollbarsComponent
        ref={scrollRef}
        options={{ scrollbars: { autoHide: 'leave' } }}
      >
        <List ref={listRef}>{children}</List>
      </OverlayScrollbarsComponent>
    </ListWrapper>
  );
};
let lvl = 0;

function SelectCascadeComponent<T>(props: TreeSelectComponentProps<T>) {
  const { items, value, onClick, onSubmit, changeType } = props;
  const { height: windowHeight } = useWindowResize();
  const contentRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<HTMLDivElement>(null);

  const onClickItem = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget.dataset.id) {
      onClick(e.currentTarget.dataset.id);
    }
  };

  const renderListItem = (data: CascadeItemWithChildren<T>[]) => {
    return data.map(item => {
      const selected = value?.find(r => r.id === item.id);
      return (
        <ListItem
          $active={!!selected}
          key={item.id}
          data-id={item.id}
          button
          onClick={onClickItem}
        >
          <ListItemIcon>
            {!item.children &&
              (selected ? (
                <RadioButtonCheckedIcon color={'primary'} />
              ) : (
                <RadioButtonUncheckedIcon color={'secondary'} />
              ))}
          </ListItemIcon>
          <ListItemText primary={item.name} />
          {Array.isArray(item.children) && item.children.length > 0 && (
            <ListItemSecondaryAction>
              <KeyboardArrowRightIcon
                color={selected ? 'primary' : 'secondary'}
                fontSize='small'
              />
            </ListItemSecondaryAction>
          )}
        </ListItem>
      );
    });
  };

  const getNext = (): CascadeItemWithChildren<T>[] => {
    if (Array.isArray(value) && value.length > 0) {
      const categoryTarget = value[lvl];
      if (categoryTarget) {
        const category = findInTree(categoryTarget.id, items);
        return category?.children || [];
      }
    }
    return [];
  };

  const getTargetId = (): string | undefined => {
    if (Array.isArray(value) && value.length > 0) {
      const item = value[lvl];
      return item?.id;
    }
    return undefined;
  };

  const renderList = (items: CascadeItemWithChildren<T>[]): any => {
    if (items.length === 0) {
      return null;
    }
    const nextLvlArr = getNext();
    const targetId = changeType === ChangeType.search ? getTargetId() : undefined;

    if (nextLvlArr.length > 0) lvl++;
    else lvl = 0;

    return (
      <>
        <ColumnList
          windowHeight={windowHeight}
          height={columnHeight}
          scrollToElementId={targetId}
        >
          {renderListItem(items)}
        </ColumnList>
        {nextLvlArr.length > 0 && renderList(nextLvlArr)}
      </>
    );
  };

  const columnHeight = useMemo(() => {
    return (contentRef.current?.parentElement?.offsetHeight ?? 0) - (buttonsRef.current?.offsetHeight || 0);
  }, [contentRef.current, buttonsRef.current, windowHeight]);

  return (
    <>
      <ContentWrapper ref={contentRef}>{renderList(items)}</ContentWrapper>

      <ButtonsWrapper
        container
        spacing={1}
        ref={buttonsRef}
        alignItems='center'
        justifyContent='space-between'
        wrap='nowrap'
      >
        <Grid item>
          <MPButton
            fullWidth={false}
            onClick={onSubmit}
          >
            Выбрать
          </MPButton>
        </Grid>
      </ButtonsWrapper>
    </>
  );
}

export default memo(SelectCascadeComponent);
