import { ContentTarget, Target } from '@/domain';
import { ContentTargetComponentsType } from '@features/general/contentTarget/types';
import { convertTargetToContentTarget } from '@features/general/contentTarget/utils';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import { useSelector } from 'react-redux';
import ContentTargetViewContainer from '../../../../general/contentTarget/view/container';

type CmsViewTargetAdapterProps<T extends Target> = ContentTargetComponentsType & {
  readonly target: T;
};

const CmsViewTargetAdapter = <T extends Target>(props: CmsViewTargetAdapterProps<T>) => {
  const { target, ...components } = props;

  const { userGenders } = useSelector(nsiDataSelector);

  const contentTarget: ContentTarget = convertTargetToContentTarget(target);

  return (
    <ContentTargetViewContainer
      {...components}
      target={contentTarget}
      nsi={{ genderTypes: userGenders }}
    />
  );
};

export default CmsViewTargetAdapter;
