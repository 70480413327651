import { Dispatch, SetStateAction } from 'react';
import { Fetchable } from '../../../../../../../../data/store/types';
import { PartnerShort } from '../../../../../../../../domain/model/partner';
import { Nullable } from '../../../../../../../../domain/model/types';
import DataTableCellSortIndex from '../../../../../../../components/common/table/cell/sortIndex';
import { EPartnerActionType, PartnerActionCall, PartnerActionProcess } from '../../../../types';

interface PartnerTableCellSortIndexProps<T extends PartnerShort> {
  readonly partner: T;
  readonly activePartner: Nullable<T>;
  readonly editablePartner: Nullable<T>;
  readonly sortIndexInfo: Fetchable & {
    readonly fetch: () => void;
    readonly max: number;
  };
  readonly sortIndex: number;
  readonly getActionProcesses?: (partner: T) => PartnerActionProcess[];
  readonly onPartnerAction: (call: PartnerActionCall<T>) => void;
  readonly setEditablePartner: Dispatch<SetStateAction<Nullable<T>>>;
}

const PartnerTableCellSortIndex = <T extends PartnerShort>(props: PartnerTableCellSortIndexProps<T>) => {
  const {
    partner,
    activePartner,
    editablePartner,
    sortIndexInfo,
    sortIndex,
    getActionProcesses,
    onPartnerAction,
    setEditablePartner,
  } = props;
  const { id } = partner;

  const changeSortIndexProcess = getActionProcesses?.(partner)?.find(
    process => process.type === EPartnerActionType.ChangeSortIndex
  );
  const currentEditablePartner = editablePartner?.id === id ? editablePartner : null;
  const isSortIndexFetching = changeSortIndexProcess?.isFetching || sortIndexInfo.isFetching;

  return (
    <>
      <DataTableCellSortIndex
        editMode={editablePartner?.id === id && sortIndexInfo.isFetched}
        value={sortIndex}
        max={sortIndexInfo.max}
        label={(min, max) => (sortIndexInfo.isFetched ? `${min}-${max}` : '')}
        hideEditIcon={activePartner?.id !== id && editablePartner?.id !== id}
        isFetching={(!editablePartner || !!currentEditablePartner) && isSortIndexFetching}
        onChangeEditMode={isEditMode => setEditablePartner(!isEditMode ? null : partner)}
        onChange={newValue => {
          setEditablePartner({ ...partner, sortIndex: newValue });
          onPartnerAction({
            action: EPartnerActionType.ChangeSortIndex,
            partner,
            sortIndex: newValue,
          });
        }}
      />
    </>
  );
};

export default PartnerTableCellSortIndex;
