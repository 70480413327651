import { useSelector } from 'react-redux';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { ETradeOfferTableFilterItem } from '../../filterUtils';
import { ETradeOfferTableColumn } from '../../types';
import TradeOfferTable from '../component';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  tradeOffersSearchFilterSelector,
  tradeOffersSearchGuidSelector,
  tradeOffersSearchSelectedSelector,
  tradeOffersSearchSortColumnSelector,
  tradeOffersSearchSortDirectionSelector,
  tradeOffersSearchTradeOffersSelector,
} from '../store/selectors';

interface TradeOffersSearchTableAdapterProps {
  readonly filterAdapter: DataFilterAdapter<ETradeOfferTableColumn, ETradeOfferTableFilterItem>;
}

const TradeOffersSearchTableAdapter = (props: TradeOffersSearchTableAdapterProps) => {
  const { filterAdapter } = props;

  const handlers = useContextHandlers();
  const { isSingleSelect, onTradeOfferClick } = useContextConfig();

  const guid = useSelector(tradeOffersSearchGuidSelector);
  const tradeOffers = useSelector(tradeOffersSearchTradeOffersSelector);
  const sortColumn = useSelector(tradeOffersSearchSortColumnSelector);
  const sortDirection = useSelector(tradeOffersSearchSortDirectionSelector);
  const filter = useSelector(tradeOffersSearchFilterSelector);
  const selectedTradeOffers = useSelector(tradeOffersSearchSelectedSelector);
  const selectedTradeOffersCount = selectedTradeOffers?.length ?? 0;

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return (
    <TradeOfferTable
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      tradeOffers={tradeOffers}
      selectedTradeOffers={selectedTradeOffers}
      isSingleSelect={isSingleSelect}
      onTradeOfferSelect={(tradeOffer, selected) =>
        handlers.onTradeOfferSelect(tradeOffer, selectedTradeOffersCount, selected)
      }
      onAllTradeOffersSelect={handlers.onAllTradeOffersSelect}
      onRequestSort={handlers.onChangeSort}
      onTradeOfferClick={onTradeOfferClick}
      onChangeMetadata={onChangeMetadata}
    />
  );
};

export default TradeOffersSearchTableAdapter;
