import { Grid } from '@mui/material';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';
import { MPButton } from '../../../../theme/ui-kit/button';
import { pluralize } from '../../../../utils/pluralize';
import ContentLoader from '../../../../components/common/loader';

interface ProductsApproveProductCollectionDialogProps {
  readonly productsCount: number;
  readonly isExecuting: boolean;
  readonly onExecute: () => void;
  readonly onClose: () => void;
}

const ProductsApproveProductCollectionDialog = (props: ProductsApproveProductCollectionDialogProps) => {
  const { productsCount, isExecuting, onExecute, onClose } = props;

  const description = `Будет опубликовано ${productsCount}
  ${pluralize(productsCount, [
    'торговое предложение',
    'торговых предложения',
    'торговых предложений',
  ])}`;

  return (
    <ConfirmDialog
      open
      onClose={onClose}
      title='Публикация торговых предложений'
      text={description}
      dialogButtons={
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              onClick={() => {
                onExecute();
                onClose();
              }}
            >
              Опубликовать
              {isExecuting && <ContentLoader />}
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              fullWidth={false}
              onClick={onClose}
            >
              Отмена
              {isExecuting && <ContentLoader />}
            </MPButton>
          </Grid>
        </Grid>
      }
    />
  );
};

export default ProductsApproveProductCollectionDialog;
