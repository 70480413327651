import { Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BookingOfferShort } from '../../../../../domain/model/booking';
import Splitter from '../../../../components/common/splitter';
import { MPFormTextArea } from '../../../../theme/ui-kit/input';
import { EBookingActionType } from '../../types';
import BookingActionsConfirmDialog from '../components/confirmDialog';
import {
  bookingOfferActionsDialogRejectSelector,
  getBookingOfferActionsOfferIsProcessingSelector,
} from '../store/selectors';
import { bookingOfferActionsReject } from '../store/slice';
import { useBookingOfferActions } from '../useActions';
import { showBookingOfferActionNotification } from '../utils';

const BookingOfferActionsDialogRejectAdapter = () => {
  const dispatch = useDispatch();
  const handlers = useBookingOfferActions();

  const [comment, setComment] = useState<string>('');

  const object = useSelector(bookingOfferActionsDialogRejectSelector);

  const isProcessing = useSelector(getBookingOfferActionsOfferIsProcessingSelector(object?.id ?? null));

  const onChangeComment = (reason: string) => {
    setComment(reason);
  };

  const reasonAction = useCallback(
    (offer: BookingOfferShort) => {
      dispatch(
        bookingOfferActionsReject({
          id: offer.id,
          comment,
        })
      )
        .unwrap()
        .then(response => {
          handlers.onChangeDialogState('reject', null);
          onChangeComment('');
          showBookingOfferActionNotification(response, EBookingActionType.Reject, false);
        });
    },
    [dispatch, handlers.onChangeDialogState, comment]
  );

  const onCloseDialog = useCallback(() => {
    handlers.onChangeDialogState('reject', null);
    onChangeComment('');
  }, [handlers.onChangeDialogState]);

  return (
    object && (
      <BookingActionsConfirmDialog
        offer={object}
        title='Отклонить объект?'
        actionText='Отклонить'
        isFetching={isProcessing}
        actionDisabled={!comment}
        onAction={reasonAction}
        onClose={onCloseDialog}
      >
        <>
          <Typography gutterBottom={true}>Укажите причину отклонения</Typography>
          <Splitter />
          <MPFormTextArea
            rows={3}
            label='Комментарий'
            value={comment}
            inputProps={{ maxLength: 1000 }}
            onChange={event => onChangeComment(event.target.value)}
          />
        </>
      </BookingActionsConfirmDialog>
    )
  );
};

export default BookingOfferActionsDialogRejectAdapter;
