import { EOfferStatus, Nullable, TradeOffer, UUID } from '@/domain';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import TableCommonLayout from '../../../layouts/tableCommon/container';
import { NumberRange } from '../../../types';
import { EOfferTableTab } from '../../general/offer/types';
import { ETradeOfferTableFilterItem } from '../filterUtils';
import { ETradeOfferTableColumn } from '../types';
import TradeOffersSearchFilterAdapter from './adapters/filter';
import TradeOffersSearchTableAdapter from './adapters/table';
import { TradeOffersSearchTabsAdapter } from './adapters/tabs';
import { TradeOffersSearchConfigContext, TradeOffersSearchContext } from './context';
import TradeOfferSearchFooterContainer from './footerContainer';
import useTradeOffersSearchSearch from './hooks/useSearch';
import useTradeOffersSearchSession from './hooks/useSession';
import { useTradeOffersSearchHandlers } from './hooks/useTradeOffersSearch';
import { useTradeOffersSearchConfig } from './hooks/useTradeOffersSearchConfig';
import { tradeOffersSearchGet, tradeOffersSearchGuidSelector } from './store/selectors';

interface TradeOfferSearchContainerSingleProps {
  readonly initialSelected: Nullable<TradeOffer>;
  readonly selectedMaxCount: 1;
  readonly onSelect: (offer: Nullable<TradeOffer>) => void;
}

interface TradeOfferSearchContainerMultipleProps {
  readonly initialSelected: Nullable<TradeOffer[]>;
  readonly selectedMaxCount?: NumberRange<2, 100> | false;
  readonly onSelect: (offer: TradeOffer[]) => void;
}

export type TradeOfferSearchContainerSelectedProps =
  | TradeOfferSearchContainerSingleProps
  | TradeOfferSearchContainerMultipleProps;

type TradeOfferSearchContainerProps = {
  readonly guid: UUID;
  readonly tab: Nullable<EOfferTableTab>;
  readonly partnerId: Nullable<UUID>;
  readonly statuses?: EOfferStatus[];
  readonly filterAdapter: DataFilterAdapter<ETradeOfferTableColumn, ETradeOfferTableFilterItem>;
  readonly onTradeOfferClick: (tradeOffer: TradeOffer) => void;
  readonly onChangeTableTab: (tab: EOfferTableTab) => void;
} & TradeOfferSearchContainerSelectedProps;

const TradeOfferSearchContainer = (props: TradeOfferSearchContainerProps) => {
  const {
    guid,
    tab,
    partnerId,
    statuses = [EOfferStatus.Active, EOfferStatus.Upcoming],
    filterAdapter,
    onTradeOfferClick,
    onChangeTableTab,
  } = props;

  const currentGuid = useSelector(tradeOffersSearchGuidSelector);
  const { tab: tableTab } = useSelector(tradeOffersSearchGet);

  const currentTab = tab ?? tableTab;
  const isSingleSelect = props.selectedMaxCount === 1;

  const config = useTradeOffersSearchConfig({
    isSingleSelect,
    tab: currentTab,
    statuses,
    onTradeOfferClick,
    onChangeTableTab,
  });
  const handlers = useTradeOffersSearchHandlers(props);

  useTradeOffersSearchSession({
    ...props,
    tab: currentTab,
  });
  useTradeOffersSearchSearch({ guid, tab: currentTab, partnerId, statuses, tabsForCounts: config.tabsForCounts });

  if (guid !== currentGuid) {
    return null;
  }

  return (
    <TradeOffersSearchConfigContext.Provider value={config}>
      <TradeOffersSearchContext.Provider value={handlers}>
        <TableCommonLayout
          filter={<TradeOffersSearchFilterAdapter filterAdapter={filterAdapter} />}
          tabs={<TradeOffersSearchTabsAdapter tab={currentTab} />}
          table={<TradeOffersSearchTableAdapter filterAdapter={filterAdapter} />}
          footer={forwardRef((footerProps, ref: any) => (
            <TradeOfferSearchFooterContainer
              ref={ref}
              maxCount={props.selectedMaxCount}
              onSelect={handlers.onReturn}
            />
          ))}
        />
      </TradeOffersSearchContext.Provider>
    </TradeOffersSearchConfigContext.Provider>
  );
};

export default TradeOfferSearchContainer;
