import { formatCurrency, formatNumber } from '../../../../utils';
import ProductTableCell, { ProductTableCellCommonProps } from './index';

const ProductTableCellOriginalPrice = (props: ProductTableCellCommonProps) => {
  const {
    productOffer: { originalPrice, currencyType },
  } = props;

  const value = currencyType ? formatCurrency(originalPrice, currencyType) : formatNumber(originalPrice);

  return (
    <ProductTableCell
      {...props}
      value={value ?? '-'}
    />
  );
};

export default ProductTableCellOriginalPrice;
