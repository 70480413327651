import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CmsComponentData } from '../../../../../../domain/model/cms';
import SectionTitle from '../../../../../components/common/sectionTitle';

type DataType = Pick<CmsComponentData, 'pageSize'>;

type CmsContainerViewComponentPageSizeVariantProps<T extends DataType> = {
  readonly cmsComponent: T;
};

type CmsContainerViewComponentPageSizeProps<T extends DataType> = CmsContainerViewComponentPageSizeVariantProps<T> & {
  readonly label: string;
};

const CmsContainerViewComponentPageSize = <T extends DataType>(props: CmsContainerViewComponentPageSizeProps<T>) => {
  const { label, cmsComponent } = props;
  const { pageSize: value } = cmsComponent;

  return (
    <Grid
      container
      spacing={1}
      direction='column'
    >
      <Grid item>
        <SectionTitle>{label}</SectionTitle>
      </Grid>
      <Grid item>
        <Typography color='textSecondary'>{label}</Typography>
      </Grid>
      <Grid item>
        <Typography>{value ?? '-'}</Typography>
      </Grid>
    </Grid>
  );
};

export default CmsContainerViewComponentPageSize;
