import { Checkbox, Typography } from '@mui/material';
import { useCallback } from 'react';
import { Nullable } from '../../../../../domain/model/types';
import SectionTitle from '../../../../components/common/sectionTitle';
import { MPAutocompleteSingleSelect } from '../../../../theme/ui-kit/autocomplete/single';
import { ECmsTermTag } from '../../container/types';
import CmsTermSearchContainer from '../../term/search/container';
import { CmsTermValueSuggestion } from '../../types';
import { Header, TitleLabel } from './controls';

interface CmsContainerTitleProps {
  readonly title: Nullable<string>;
  readonly showTitle: boolean;
  readonly disabled?: boolean;
  readonly error?: boolean;
  readonly helperText?: string;
  readonly onChangeTitle: (value: Nullable<string>) => void;
  readonly onChangeShowTitle: (value: boolean) => void;
}

const CmsContainerTitle = (props: CmsContainerTitleProps) => {
  const { title, showTitle, disabled, error, helperText, onChangeTitle, onChangeShowTitle } = props;

  const onChangeTitleInternal = useCallback(
    (newTitle: Nullable<string>) => {
      if (newTitle?.toString() !== title?.toString()) {
        onChangeTitle(newTitle);
      }
    },
    [title, onChangeTitle]
  );

  return (
    <>
      <Header>
        <SectionTitle>Заголовок</SectionTitle>
        <TitleLabel
          disabled={disabled}
          control={
            <Checkbox
              disabled={disabled}
              color='primary'
              checked={showTitle}
              onChange={(event, checked) => onChangeShowTitle(checked)}
            />
          }
          label={<Typography>Отображать на странице</Typography>}
        />
      </Header>
      <CmsTermSearchContainer
        tag={ECmsTermTag.Title}
        initialValue={title}
      >
        {({ onSearch, values, isFetching }) => (
          <MPAutocompleteSingleSelect<CmsTermValueSuggestion>
            label='Заголовок'
            disabled={disabled || !showTitle}
            isLoading={isFetching}
            value={
              title
                ? {
                    id: '',
                    name: title,
                  }
                : null
            }
            error={error}
            helperText={helperText}
            options={values ?? []}
            onSearchValue={value => {
              onSearch(value);
              onChangeTitleInternal(value ?? null);
            }}
            onChangeValue={value => {
              onChangeTitleInternal(value?.name ?? null);
            }}
          />
        )}
      </CmsTermSearchContainer>
    </>
  );
};

export default CmsContainerTitle;
