import { AddressObjectLink } from '@/domain/model/address';
import { ESearchQueryTypeDiscriminator } from '@/domain/model/enums';
import { Nullable } from '@/domain/model/types';
import { ApiCancellable, ApiRequestPageable } from 'data/api/types';
import { getPageableFromResponseHeaders } from 'data/api/utils';
import { FilterMultiSelectorPredicate, Pageable } from 'domain/model';
import { SearchQueryGeoStat, SearchQueryKeywordStat } from 'domain/model/analytics';
import { v4 as uuidv4 } from 'uuid';
import Api from '../../../../data/api';
import { PaginationSize } from '../../../types';
import { AnalyticsQueriesTableFilterValues, EAnalyticsQueriesTableFilterItem } from '../query/filterUtils';

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
  };
  readonly filter: AnalyticsQueriesTableFilterValues;
  readonly pageNumber: number;
};

export type CountProps = Omit<AllProps, 'pageNumber'>;

type BuildListQueryParamsReturn = ApiCancellable &
  ApiRequestPageable & {
    readonly query?: Nullable<string>;
    readonly dateFrom?: Nullable<string>;
    readonly dateTo?: Nullable<string>;
    readonly places?: Nullable<FilterMultiSelectorPredicate<AddressObjectLink>>;
  };

export interface QueryAnalyticsService {
  readonly buildListQueryParams: (props: AllProps) => BuildListQueryParamsReturn;
  readonly allByKeywords: (props: AllProps) => Promise<Pageable<SearchQueryKeywordStat>>;
  readonly allByGeo: (props: AllProps) => Promise<Pageable<SearchQueryGeoStat>>;
  readonly count: (props: CountProps) => Promise<number>;
}

const service: QueryAnalyticsService = {
  buildListQueryParams: props => {
    const { search, filter, pageNumber, signal } = props;
    const { pageSize, sort } = search;

    const places = filter[EAnalyticsQueriesTableFilterItem.Places]?.value;
    return {
      page: pageNumber,
      pageSize,
      sort,
      query: filter[EAnalyticsQueriesTableFilterItem.Query]?.value,
      dateFrom: filter[EAnalyticsQueriesTableFilterItem.DateFrom]?.value,
      dateTo: filter[EAnalyticsQueriesTableFilterItem.DateTo]?.value,
      places: places ? { ...places, in: places.in?.map(item => ({ id: item.id })) ?? null } : null,
      signal,
    };
  },
  allByKeywords: async props => {
    const response = await Api.analytics.all({
      ...service.buildListQueryParams(props),
      discriminator: ESearchQueryTypeDiscriminator.Query,
    });
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);
    const data = response.data.map(query => ({ ...query, id: uuidv4() }));

    return { data, totalCount, pageCount, pageNumber: page };
  },
  allByGeo: async props => {
    const response = await Api.analytics.all({
      ...service.buildListQueryParams(props),
      discriminator: ESearchQueryTypeDiscriminator.Geo,
    });
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);
    const data = response.data.map(query => ({ ...query, id: uuidv4() }));

    return { data, totalCount, pageCount, pageNumber: page };
  },
  count: async props => {
    const { data: response } = await Api.analytics.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      discriminator: ESearchQueryTypeDiscriminator.Counter,
    });

    return response[0]!.count;
  },
};

export default service;
