import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { EOrderDiscriminator, EOrderPartition } from '../../../domain/model/enums';
import { UUID } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import BookingOrderActionsProvider from '../../features/bookingOrder/actions/provider';
import BookingOrderEditContainer from '../../features/bookingOrder/edit/container';
import { BookingOrderLifeCycleFactory } from '../../features/bookingOrder/lifecycle';
import useBreadcrumbsActions from '../../features/general/breadcrumbs/useBreadcrumbsActions';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import OrderByIdContainer from '../../features/order/byId/container';
import { getOrderPartitionByOrderDiscriminator } from '../../features/order/utils';
import ProductOrderEditContainer from '../../features/productOrder/edit/container';
import { TradeOffersLocationState } from '../../features/tradeOffer/entry';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const OrderEditScreen = () => {
  const dispatch = useDispatch();
  const { putBreadcrumbs } = useBreadcrumbsActions();

  const { accessMatrix, userSpecific } = useCurrentUser();
  const { orders, isPartnerUser } = accessMatrix;

  const { id } = useParams<{ id: UUID }>();

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  useEffect(() => {
    putBreadcrumbs([{ label: 'Заказы' }]);
  }, []);

  const canEdit = (partition: EOrderPartition) => {
    return orders?.[partition].edit;
  };

  return (
    <LocationWithRequiredStateContainer<TradeOffersLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <OrderByIdContainer id={id}>
          {({ order }) => {
            const partition = getOrderPartitionByOrderDiscriminator(order.discriminator);

            if (!canEdit(partition)) {
              return <ForbiddenComponent />;
            }

            switch (order.discriminator) {
              case EOrderDiscriminator.BookingOrder:
                return (
                  <BookingOrderActionsProvider>
                    <BookingOrderEditContainer
                      id={id}
                      guid={state.guid}
                      source={order}
                      access={orders[partition]}
                      lifecycle={BookingOrderLifeCycleFactory().create({
                        isPartnerUser,
                        userId: userSpecific.id,
                      })}
                    />
                  </BookingOrderActionsProvider>
                );
              case EOrderDiscriminator.ProductOrder:
                return (
                  <ProductOrderEditContainer
                    id={id}
                    guid={state.guid}
                    source={order}
                    partner={order.partner}
                  />
                );
            }
          }}
        </OrderByIdContainer>
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default OrderEditScreen;
