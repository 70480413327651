import { Nullable, UUID } from '../../../../../domain/model/types';
import { EProductOrderTableTab } from '../../types';
import { useProductOrderTableSearch } from '../hooks/useSearch';
import { useProductOrderTableSession } from '../hooks/useSession';

type ProductOrderTableBehaviorAdapterProps = {
  readonly guid: UUID;
  readonly tab: EProductOrderTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly customerId: Nullable<UUID>;
};

export const ProductOrderTableBehaviorAdapter = ({
  guid,
  tab,
  partnerId,
  customerId,
}: ProductOrderTableBehaviorAdapterProps) => {
  useProductOrderTableSession({ guid, tab });
  useProductOrderTableSearch({ tab, partnerId, customerId });

  return null;
};
