import { Grid, SvgIcon } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { forwardRef } from 'react';
import { ENoticeStatus } from '../../../../../domain/model/enums';
import {
  CloseButton,
  DefaultNotice,
  ErrorNotice,
  IconWrapper,
  InfoNotice,
  Message,
  SuccessNotice,
  Text,
  TitleText,
  WarningNotice,
} from './controls';

const variantIcon: { [variant: string]: typeof SvgIcon } = {
  variant_success: CheckCircleIcon,
  variant_warning: WarningIcon,
  variant_error: ErrorIcon,
  variant_info: InfoIcon,
};

const variantNotice: { [variant: string]: typeof DefaultNotice } = {
  variant_success: SuccessNotice,
  variant_warning: WarningNotice,
  variant_error: ErrorNotice,
  variant_info: InfoNotice,
};

interface NoticeItemProps {
  readonly variant: ENoticeStatus;
  readonly message: string;
  readonly title?: string;
  readonly description?: string;
  readonly onClose?: () => void;
}

const NoticeItem = forwardRef(({ variant, message, title, onClose }: NoticeItemProps, ref: any) => {
  const Icon = variantIcon[`variant_${variant.toLowerCase()}`];
  const Notice = variantNotice[`variant_${variant.toLowerCase()}`];
  return (
    <Notice
      ref={ref}
      message={
        <Message>
          <IconWrapper>
            <Icon />
          </IconWrapper>
          <Grid
            container
            direction='column'
          >
            {title && <TitleText>{title}</TitleText>}
            <Text>{message}</Text>
          </Grid>
        </Message>
      }
      action={
        onClose && (
          <CloseButton
            onClick={onClose}
            size='small'
          >
            <CloseIcon />
          </CloseButton>
        )
      }
    />
  );
});

export default NoticeItem;
