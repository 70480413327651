import styled from '@emotion/styled';
import { viewConfig } from '../../../../theme/viewConfig';

export const Wrapper = styled.div`
  width: ${viewConfig.support.info.width};

  display: flex;
  flex-direction: column;

  padding: ${p => p.theme.spacing(4)};
  border: solid 1px ${p => p.theme.palette.secondary.light};
  border-radius: ${p => (p.theme.shape.borderRadius as number) * 2}px;
`;
