import { FC, useMemo, useState } from 'react';
import ErrorHandler from '../../../../data/network/errorHandler';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../data/store/types';
import { FileUploadObject, XLSXImportError, XLSXImportResult } from '../../../../domain/model';
import { EOfferStatus } from '../../../../domain/model/enums';
import { Nullable, UUID } from '../../../../domain/model/types';
import useValidation from '../../../hooks/validation/useValidation';
import { pluralize } from '../../../utils/pluralize';
import { downloadXLSXImportErrors } from '../../../utils/xlsxImport';
import productServices from '../services';
import ProductUploadStocks from './component';
import { productStocksUploadValidation } from './utils';

type ProductUploadStocksContainerProps = {
  readonly partnerId: UUID;
  readonly statuses: EOfferStatus[];
  readonly onUpload: (count: number) => void;
  readonly onClose: () => void;
};

export type XLSXUploadState = Fetchable & {
  readonly result: Nullable<XLSXImportResult>;
};

const ProductUploadStocksContainer: FC<ProductUploadStocksContainerProps> = props => {
  const { partnerId, onUpload, onClose, statuses } = props;

  const [stocksUpload, setStocksUpload] = useState<XLSXUploadState>({ ...fetchableDefault, result: null });
  const [localFile, setLocalFile] = useState<Nullable<{ file: File; error: Nullable<string> }>>(null);
  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);

  const templateDownloadUrl = useMemo(
    () => productServices.productOffer.stocksDownloadUrl({ partnerId, statuses }),
    [partnerId]
  );

  const uploadObject = { file: localFile?.file ?? null };

  const { validationResult, isValid, validate } = useValidation<FileUploadObject>({
    object: uploadObject,
    validateOnChange,
    rules: productStocksUploadValidation,
  });

  const onUploadFile = () => {
    const file = localFile?.file;
    const isValidObject = validate();

    if (!isValidObject) {
      setValidateOnChange(false);
    } else {
      if (file && !localFile.error) {
        setStocksUpload({ ...fetchableFetching, result: null });
        productServices.productOffer
          .importStocks({ partnerId: partnerId, file })
          .then(response => {
            setStocksUpload({ ...fetchableFetched, result: response });
            onUpload(response.successCount);
          })
          .catch(e => {
            ErrorHandler.handleHttpError(e, e.response);
            setStocksUpload({ ...fetchableFailed, result: null });
          });
      }
    }
  };

  const onRemoveFile = () => {
    setStocksUpload({ ...fetchableDefault, result: null });
    setLocalFile(null);
  };

  const onSetFile = (file: File, error?: string) => {
    setStocksUpload({ ...fetchableDefault, result: null });
    setLocalFile({ file, error: error ?? null });
  };

  const onDownloadImportError = (errors: XLSXImportError[]) => {
    downloadXLSXImportErrors({ errors });
  };

  const getSuccessImportedCountText = (count: number): string =>
    `${count} ${pluralize(count, ['запись обновлена', 'записи обновлены', 'записей обновлено'])}`;

  return (
    <ProductUploadStocks
      uploadFile={localFile}
      templateDownloadUrl={templateDownloadUrl}
      isUploading={stocksUpload.isFetching}
      isUploaded={stocksUpload.isFetched}
      isFailed={stocksUpload.isFailed}
      uploadResult={stocksUpload.result}
      isValid={isValid}
      validation={validationResult}
      getSuccessImportedCountText={getSuccessImportedCountText}
      onDownloadImportError={onDownloadImportError}
      onSetFile={onSetFile}
      onUploadFile={onUploadFile}
      onRemoveFile={onRemoveFile}
      onClose={onClose}
    />
  );
};

export default ProductUploadStocksContainer;
