import styled from '@emotion/styled';
import AppImage from '../common';
import { Backdrop } from '@mui/material';

export const ImageGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: ${p => p.theme.spacing(1)};
  box-sizing: border-box;
`;

export const BadgeRemoveIcon = styled.div`
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 0 6px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  font-size: 0.625rem;
  top: 0;
  right: 0;
  transform: scale(1) translate(-50%, 70%);
  transform-origin: 100% 0;
  background-color: #406aff;
  color: white;
  cursor: pointer;
`;

export const ImageItemWrapper = styled.div`
  height: 4rem;
  padding: ${p => p.theme.spacing(0.5)};
  position: relative;
  cursor: move;

  &:hover ${BadgeRemoveIcon} {
    visibility: visible;
  }
`;

export const StyledImage = styled(AppImage)`
  width: 100%;
  height: 100%;

  object-fit: contain;
  object-position: center center;

  vertical-align: top;

  border: 1px solid #f2f2f2;
`;

export const StyledBackdrop = styled(Backdrop)`
  color: #fff;
  z-index: 1201;
`;
