import { EDateFormat, EOfferType, EUserGender, Nullable, SportOptionTyped, UUID } from '@/domain';
import { DataFilterAdapter } from '@/presentation/hooks/useDataFilterAdapter';
import {
  CorpOfferTableFilterEditStrategy,
  corpOfferTableFilterItems,
  CorpOfferTableFilterValues,
  ECorpOfferTableFilterItem,
  getCorpOfferTableFilterStrategy,
} from '@features/corpOffer/filterUtils';
import { getCorpOfferAdminTableColumns, getCorpOfferOwnerTableColumns } from '@features/corpOffer/table/utils';
import { ECorpOfferTableColumn } from '@features/corpOffer/types';
import { EOfferTableTab } from '@features/general/offer/types';
import { getOffersTableAdapterKey } from '@features/general/offer/utils/table';
import moment from 'moment';
import { useMemo } from 'react';

type UseCmsCollectionCorpOfferFilterAdapter = DataFilterAdapter<ECorpOfferTableColumn, ECorpOfferTableFilterItem>;

type UseCmsCollectionCorpOfferFilterAdapterProps = {
  readonly viewAsAdmin: boolean;
  readonly partnerId: Nullable<UUID>;
  readonly tab: EOfferTableTab;
  readonly values: CorpOfferTableFilterValues;
  readonly genderTypes: SportOptionTyped<EUserGender>[];
};

const useCmsCollectionCorpOfferFilterAdapter = (
  props: UseCmsCollectionCorpOfferFilterAdapterProps
): UseCmsCollectionCorpOfferFilterAdapter => {
  const { viewAsAdmin, partnerId, tab, values, genderTypes } = props;

  const filterPreset = useMemo(
    () =>
      Object.keys(values)
        .map(key => {
          const strategy = getCorpOfferTableFilterStrategy(
            partnerId,
            key as ECorpOfferTableFilterItem,
            values,
            genderTypes
          );
          if (strategy) {
            const readonlyStrategy = { ...strategy, readOnly: true };
            switch (strategy.key) {
              case ECorpOfferTableFilterItem.Places:
                return {
                  ...readonlyStrategy,
                  viewAlways: strategy.value === null,
                };
              case ECorpOfferTableFilterItem.StartDate:
                return {
                  ...readonlyStrategy,
                  querydsl: values[ECorpOfferTableFilterItem.StartDate]?.querydsl,
                  preview: `${moment(values[ECorpOfferTableFilterItem.EndDate]?.value).format(
                    EDateFormat.DisplayDefault
                  )} - ${moment(values[ECorpOfferTableFilterItem.StartDate]?.value).format(
                    EDateFormat.DisplayDefault
                  )}`,
                };
              case ECorpOfferTableFilterItem.EndDate:
                return {
                  ...readonlyStrategy,
                  querydsl: values[ECorpOfferTableFilterItem.EndDate]?.querydsl,
                  preview: null,
                };
              default:
                return { ...readonlyStrategy, viewAlways: undefined };
            }
          } else {
            return null;
          }
        })
        .filter(strategy => !!strategy) as CorpOfferTableFilterEditStrategy[],
    [values, genderTypes, partnerId]
  );

  const sourceColumns = useMemo(
    () => (viewAsAdmin ? getCorpOfferAdminTableColumns(tab, partnerId, false) : getCorpOfferOwnerTableColumns(tab)),
    [tab, partnerId, viewAsAdmin]
  );

  const { key, keyPartitions } = useMemo(
    () =>
      getOffersTableAdapterKey({
        offerType: EOfferType.Corp,
        isAdmin: viewAsAdmin ?? false,
        partnerId,
        tab,
      }),
    [tab, partnerId, viewAsAdmin]
  );

  return useMemo<DataFilterAdapter<ECorpOfferTableColumn, ECorpOfferTableFilterItem>>(
    () => ({
      key,
      keyPartitions,
      sourceColumns: sourceColumns,
      filterItems: corpOfferTableFilterItems,
      filterPreset,
      requiredFilters: [ECorpOfferTableFilterItem.Query],
      getDataFilterStrategy: (filterItem, values) =>
        getCorpOfferTableFilterStrategy(partnerId, filterItem, values, genderTypes),
    }),
    [partnerId, key, keyPartitions, sourceColumns, filterPreset, genderTypes]
  );
};

export default useCmsCollectionCorpOfferFilterAdapter;
