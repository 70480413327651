import { useSelector } from 'react-redux';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../../hooks/useDataFilterAdapter';
import { EAnalyticsQueriesTableFilterItem } from '../../filterUtils';
import { AnalyticsQueriesTable } from '../component';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  analyticsQueriesAnalyticsCountSelector,
  analyticsQueriesAnalyticsQueriesSelector,
  analyticsQueriesFilterSelector,
  analyticsQueriesGuidSelector,
  analyticsQueriesSortColumnSelector,
  analyticsQueriesSortDirectionSelector,
} from '../store/selectors';
import { EAnalyticsQueriesTableColumn } from '../utils';

interface AnalyticsQueriesTableAdapterProps {
  readonly filterAdapter: DataFilterAdapter<EAnalyticsQueriesTableColumn, EAnalyticsQueriesTableFilterItem>;
}

export const AnalyticsQueriesTableAdapter = (props: AnalyticsQueriesTableAdapterProps) => {
  const { filterAdapter } = props;

  const handlers = useContextHandlers();
  const guid = useSelector(analyticsQueriesGuidSelector);
  const sortColumn = useSelector(analyticsQueriesSortColumnSelector);
  const sortDirection = useSelector(analyticsQueriesSortDirectionSelector);
  const queries = useSelector(analyticsQueriesAnalyticsQueriesSelector);
  const count = useSelector(analyticsQueriesAnalyticsCountSelector);
  const filter = useSelector(analyticsQueriesFilterSelector);

  const { metadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return (
    <AnalyticsQueriesTable
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      queries={queries}
      count={count}
      onRequestSort={handlers.onChangeSort}
    />
  );
};
