import { FCC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { OfferCategory } from '../../../../../domain/model';
import { TradeOfferCategoriesSelectContainerProps } from './container';
import {
  tradeOfferCategoriesSelectFetch,
  tradeOfferCategoriesSelectResetState,
  tradeOfferCategoriesSelectStartSession,
} from './store/slice';

type TradeOfferCategoriesSelectInitializerProps = TradeOfferCategoriesSelectContainerProps & {
  readonly open?: boolean;
  readonly selected: Nullable<OfferCategory[]>;
  readonly onClose: (selected?: Nullable<OfferCategory[]>) => void;
};

export const TradeOfferCategoriesSelectInitializer: FCC<TradeOfferCategoriesSelectInitializerProps> = ({
  onlyUsed,
  partnerId,
  statuses,
  open,
  selected,
  children,
}) => {
  const dispatch = useDispatch();

  //загрузка основных данных
  useEffect(() => {
    if (open) {
      const requests = [dispatch(tradeOfferCategoriesSelectFetch({ onlyUsed, partnerId, statuses }))];
      return () => {
        requests.forEach(request => request.abort());
      };
    }
  }, [dispatch, open, onlyUsed, partnerId, statuses]);

  //очистка данных
  useEffect(() => {
    if (open) {
      dispatch(tradeOfferCategoriesSelectStartSession({ selected }));
      return () => {
        dispatch(tradeOfferCategoriesSelectResetState());
      };
    }
  }, [dispatch, selected, open]);

  return <>{children}</>;
};
