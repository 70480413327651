import { DataTreeItem, OfferCategory } from '../../../../domain/model';

export const buildCorpOfferCategoryTree = (corpOfferCategories: OfferCategory[]): DataTreeItem<OfferCategory>[] => {
  return corpOfferCategories
    .filter(item => !item.parentId)
    .map(corpOfferCategory => buildTreeItem(corpOfferCategory, corpOfferCategories));
};
 
const buildTreeItem = (
  corpOfferCategory: OfferCategory,
  corpOfferCategories: OfferCategory[]
): DataTreeItem<OfferCategory> => {
  return {
    id: corpOfferCategory.id,
    label: corpOfferCategory.name,
    parentId: corpOfferCategory.parentId,
    data: corpOfferCategory,
    children:
      corpOfferCategories
        .filter(c => c.parentId === corpOfferCategory.id)
        .map(c => buildTreeItem(c, corpOfferCategories)) ?? [],
  };
};
