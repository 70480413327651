import styled from '@emotion/styled';
import { Dialog, IconButton } from '@mui/material';

export const Wrapper = styled.div`
  position: relative;
`;

export const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: ${p => p.theme.spacing(2)};
  right: ${p => p.theme.spacing(2)};
  border: none;
  padding: ${p => p.theme.spacing(0.3)};

  z-index: ${p => p.theme.zIndex.modal - 1};

  svg {
    width: ${p => p.theme.spacing(3)};
    height: ${p => p.theme.spacing(3)};
  }
`;

export const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    border: 1px solid ${p => p.theme.palette.secondary?.main};
    border-radius: ${p => (p.theme.shape.borderRadius as number) * 5}px;
    padding: ${p => p.theme.spacing(7.5)};
  }

  & .MuiDialogContent-root {
    padding: ${p => p.theme.spacing(2)};
  }

  & .MuiDialogTitle-root + .MuiDialogContent-root {
    padding-top: ${p => p.theme.spacing(2)};
  }

  & .MuiDialogTitle-root {
    &.MuiTypography-root {
      font-weight: ${p => p.theme.typography.fontWeightBold};
      font-size: ${p => p.theme.typography.h3.fontSize};
    }

    padding: 0 ${p => p.theme.spacing(2)};
    margin-bottom: ${p => p.theme.spacing()};
  }
`;
