import { Typography } from '@mui/material';
import { useMemo } from 'react';
import FileInfo from '../../../../components/common/files/info';
import { FileInfoBlankAdapter } from '../../../../components/common/files/info/blankAdapter';
import Splitter from '../../../../components/common/splitter';
import useTechConfig from '../../../../hooks/useTechConfig';
import { ContentTypeDocumentIcon } from '../../../../icons';
import { EAppFeature } from '../../../../types';
import SupportPdpLinkContainer from '../../pdpLink';
import SupportTermsOfUseLinkContainer from '../../termsLink';
import { ArrowRightIcon } from '@mui/x-date-pickers';

const SupportInfoMpDocumentsAdapter = () => {
  const { hasFeature } = useTechConfig();

  const hasTermsOfUseFeature = useMemo<boolean>(() => hasFeature(EAppFeature.TermsOfUse), [hasFeature]);
  const hasPersonalDataPolicyFeature = useMemo<boolean>(() => hasFeature(EAppFeature.PersonalDataPolicy), [hasFeature]);

  return useMemo(() => {
    return hasTermsOfUseFeature || hasPersonalDataPolicyFeature ? (
      <>
        <Typography variant='h3'>Документы</Typography>
        <Splitter
          variant='horizontal'
          size={3}
        />
        {hasTermsOfUseFeature && (
          <>
            <SupportTermsOfUseLinkContainer underline='none'>
              <FileInfo
                color={'secondary'}
                icon={<ContentTypeDocumentIcon color='action' />}
                endIcon={<ArrowRightIcon color='action' />}
                adapter={FileInfoBlankAdapter}
                originalName='Пользовательское соглашение'
              />
            </SupportTermsOfUseLinkContainer>
            <Splitter
              variant='horizontal'
              size={2}
            />
          </>
        )}
        {hasPersonalDataPolicyFeature && (
          <SupportPdpLinkContainer underline='none'>
            <FileInfo
              color={'secondary'}
              icon={<ContentTypeDocumentIcon color='action' />}
              endIcon={<ArrowRightIcon color='action' />}
              adapter={FileInfoBlankAdapter}
              originalName='Политика обработки персональных данных ОАО «РЖД»'
            />
          </SupportPdpLinkContainer>
        )}
      </>
    ) : null;
  }, [hasTermsOfUseFeature, hasPersonalDataPolicyFeature]);
};

export default SupportInfoMpDocumentsAdapter;
