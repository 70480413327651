import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ClientOrg } from 'domain/model/clientOrg';
import { EFilterMultiSelectorValueType } from 'domain/model/enums';
import { DataFilterStrategyClientOrgsSelector } from 'domain/model/filter';
import { Nullable } from 'domain/model/types';
import OrgStructureClientOrgMultipleTypesSelector from '../../../../orgStructure/clientOrg/multipleTypes';
import { OrgStructureClientOrgMultipleSelectType } from '../../../../orgStructure/clientOrg/multipleTypes/types';
import { DataFilterEditItemProps } from '../index';

type ValueType<T extends string> = DataFilterStrategyClientOrgsSelector<T>['value'];

interface DataFilterEditItemClientOrgMultipleProps<T extends string>
  extends DataFilterEditItemProps<T, Nullable<ValueType<T>>> {
  readonly strategy: DataFilterStrategyClientOrgsSelector<T>;
}

function DataFilterEditItemClientOrgMultipleTypes<T extends string>({
  strategy,
  onChange,
}: DataFilterEditItemClientOrgMultipleProps<T>) {
  const { label, value, readOnly, selectNone = true } = strategy;

  const targetClientOrgs = value ?? { select: EFilterMultiSelectorValueType.In, in: null };

  const select =
    targetClientOrgs.select === EFilterMultiSelectorValueType.All
      ? OrgStructureClientOrgMultipleSelectType.All
      : targetClientOrgs.select === EFilterMultiSelectorValueType.None
      ? OrgStructureClientOrgMultipleSelectType.None
      : null;

  const onChangeClientOrgs = (newValue: Nullable<ClientOrg[]>) => {
    if (newValue?.length) {
      onChange(strategy, { select: EFilterMultiSelectorValueType.In, in: newValue });
    } else {
      onChange(strategy, null);
    }
  };

  const onSelect = (type: OrgStructureClientOrgMultipleSelectType) => {
    switch (type) {
      case OrgStructureClientOrgMultipleSelectType.All:
        onChange(strategy, { select: EFilterMultiSelectorValueType.All, in: null });
        break;
      case OrgStructureClientOrgMultipleSelectType.None:
        onChange(strategy, { select: EFilterMultiSelectorValueType.None, in: null });
        break;
    }
  };

  return (
    <OrgStructureClientOrgMultipleTypesSelector
      selectNone={selectNone}
      label={label}
      color='primary'
      select={select}
      disabled={readOnly}
      clientOrgs={targetClientOrgs.in ?? []}
      limitTags={2}
      popupIcon={<ExpandMoreIcon fontSize='small' />}
      onChange={onChangeClientOrgs}
      onSelect={onSelect}
    />
  );
}

export default DataFilterEditItemClientOrgMultipleTypes;
