import { FCC } from 'react';
import { CmsPreviewItemRatio } from '../../../../../features/cms/types';
import { Wrapper } from './controls';

interface EntityMasterPreviewContentGridProps {
  ratio?: CmsPreviewItemRatio;
  maxRowElementsCount: number;
  preserveAspectRatio?: boolean;
  spacing?: number;
}

const EntityMasterPreviewContentGrid: FCC<EntityMasterPreviewContentGridProps> = ({
  children,
  ratio,
  maxRowElementsCount,
  preserveAspectRatio,
  spacing = 1,
}) => (
  <Wrapper
    ratio={ratio}
    maxRowElementsCount={maxRowElementsCount}
    preserveAspectRatio={preserveAspectRatio}
    spacing={spacing}
  >
    {children}
  </Wrapper>
);

export default EntityMasterPreviewContentGrid;
