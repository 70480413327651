import { Grid } from '@mui/material';
import { Partner } from '../../../../../../domain/model/partner';
import { Nullable } from '../../../../../../domain/model/types';
import ConfirmDialog from '../../../../../components/common/dialogs/confirm';
import { MPButton } from '../../../../../theme/ui-kit/button';

interface PartnerBySelfRegistrationSuccessDialogProps {
  readonly partner: Nullable<Partner>;
  readonly onAction: () => void;
}

const PartnerBySelfRegistrationSuccessDialog = (props: PartnerBySelfRegistrationSuccessDialogProps) => {
  const { partner, onAction } = props;

  const isOpen = !!partner;

  return (
    <ConfirmDialog
      open={isOpen}
      title='Партнёр успешно добавлен'
      dialogButtons={
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              onClick={onAction}
            >
              Отлично
            </MPButton>
          </Grid>
        </Grid>
      }
    />
  );
};

export default PartnerBySelfRegistrationSuccessDialog;
