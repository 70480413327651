import { useMemo } from 'react';
import { ClientOrg } from '@/domain/model/clientOrg';
import MenuPanelList from '../../../../../components/common/menuPanel/list';
import useClientOrgManagementMenuItems from '../useMenuItems';

interface ClientOrgManagementMenuItemsAdapterProps {
  readonly clientOrg: ClientOrg;
}

export const ClientOrgManagementMenuItemsAdapter = (props: ClientOrgManagementMenuItemsAdapterProps) => {
  const { clientOrg } = props;

  const { items, isSelectedItem, onItemClick, isOpenedSubMenu } = useClientOrgManagementMenuItems({ clientOrg });

  return useMemo(() => {
    return (
      <MenuPanelList
        items={items}
        onItemClick={onItemClick}
        isSelectedItem={isSelectedItem}
        isOpenedSubMenu={isOpenedSubMenu}
      />
    );
  }, [items, isSelectedItem, isOpenedSubMenu, onItemClick]);
};
