import { Wrapper } from './controls';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import DoneIcon from '@mui/icons-material/Done';

type DictionaryItemElementArrowProps = SvgIconProps;

export const DictionaryItemElementDone = (props: DictionaryItemElementArrowProps) => {
  const { color = 'success' } = props;

  return (
    <Wrapper>
      <DoneIcon
        fontSize='small'
        color={color}
        {...props}
      />
    </Wrapper>
  );
};
