import { FCC, Fragment } from 'react';
import { DataTreeItem } from '../../../../../../../domain/model';
import { DictionaryDndLayer } from '../../../dnd';
import { DictionaryItemContent, DictionaryItemContentProps } from '../../../types';

type DictionaryTreeListItemProps<T> = Omit<DictionaryItemContentProps<T>, 'onClick'> & {
  readonly level: number;
  readonly component: DictionaryItemContent<T>;
  readonly onClick?: (item: DataTreeItem<T>) => void;
  readonly onMove?: (dragIndex: number, hoverIndex: number) => void;
  readonly onChangeSort?: () => void;
};

const EmptyWrapper: FCC = props => <Fragment>{props.children}</Fragment>;

export const DictionaryTreeListItem = <T extends object>(props: DictionaryTreeListItemProps<T>) => {
  const { selected, expanded, data, level, index, component: Component, onClick, inSelectedPath, onMove, onChangeSort } = props;

  const Wrapper = onMove ? DictionaryDndLayer : EmptyWrapper;

  return (
    <Wrapper
      index={index}
      onMove={onMove}
      onChangeSort={onChangeSort}
    >
      <Component
        data={data}
        index={index}
        level={level}
        selected={selected}
        expanded={expanded}
        inSelectedPath={inSelectedPath}
        onClick={() => onClick?.(data)}
      />
    </Wrapper>
  );
};
