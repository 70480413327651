import { EOfferType } from '@/domain';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import { nsiDataSelector } from '../../general/nsi/store/selectors';
import { CorpOfferLifeCycleFactory } from '../../general/offer/lifecycle/lifecycle';
import { EOfferTableTab } from '../../general/offer/types';
import { getOffersTableAdapterKey } from '../../general/offer/utils/table';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import { corpOfferTableFilterItems, ECorpOfferTableFilterItem, getCorpOfferTableFilterStrategy } from '../filterUtils';
import { CorpOfferTableStubAdapterType, ECorpOfferTableColumn } from '../types';
import CorpOffersStubAdapter from './adapters/stub';
import CorpOffersPartnerStubAdapter from './adapters/stubPartner';
import CorpOfferTableContainer from './container';
import { CorpOffersTableConfigContext } from './context';
import { useCorpOffersTableConfig } from './hooks/useCorpOffersTableConfig';
import { corpOffersIsEmptySelector } from './store/selectors';
import { getCorpOfferAdminTableColumns, getCorpOfferOwnerTableColumns } from './utils';

interface CorpOfferTableEntryProps {
  readonly guid: UUID;
  readonly partnerId?: Nullable<UUID>;
  readonly tab: Nullable<EOfferTableTab>;
}

const CorpOfferTableEntry = ({ guid, tab, partnerId = null }: CorpOfferTableEntryProps) => {
  const {
    accessMatrix: { isPartnerUser, corpOffers },
    mpPartnerUserProfile,
    userSpecific,
  } = useCurrentUser();
  const { canEditApprovalRegistry, viewAsAdmin: isAdmin } = corpOffers;

  const { userGenders: genderTypes } = useSelector(nsiDataSelector);

  const lifecycle = useMemo(
    () =>
      CorpOfferLifeCycleFactory().create({
        isPartnerUser,
        userId: userSpecific.id,
      }),
    [isPartnerUser, userSpecific.id]
  );

  const config = useCorpOffersTableConfig();
  const { defaultTab } = config;

  const outTab = tab ?? defaultTab;

  // получаем максимально возможный состав колонок
  const sourceColumns = useMemo(
    () =>
      isPartnerUser
        ? getCorpOfferOwnerTableColumns(outTab)
        : getCorpOfferAdminTableColumns(outTab, partnerId, canEditApprovalRegistry ?? false),
    [outTab, partnerId, isPartnerUser, canEditApprovalRegistry]
  );

  // готовим адаптер для хука
  const adapter = useMemo<DataFilterAdapter<ECorpOfferTableColumn, ECorpOfferTableFilterItem>>(
    () => ({
      ...getOffersTableAdapterKey({
        offerType: EOfferType.Corp,
        tab: outTab,
        isAdmin,
        partnerId,
      }),
      sourceColumns,
      filterItems: corpOfferTableFilterItems,
      requiredFilters: [ECorpOfferTableFilterItem.Query],
      getDataFilterStrategy: (filterItem, values) =>
        getCorpOfferTableFilterStrategy(partnerId, filterItem, values, genderTypes),
    }),
    [partnerId, outTab, sourceColumns, isAdmin, genderTypes]
  );

  const stubAdapter = useMemo<CorpOfferTableStubAdapterType>(
    () =>
      isPartnerUser
        ? props => (
            <CorpOffersPartnerStubAdapter
              isEmptySelector={corpOffersIsEmptySelector}
              partnerId={mpPartnerUserProfile?.partner?.id}
            >
              {props.children}
            </CorpOffersPartnerStubAdapter>
          )
        : CorpOffersStubAdapter,
    [isPartnerUser, mpPartnerUserProfile?.partner?.id]
  );

  return (
    <CorpOffersTableConfigContext.Provider value={config}>
      <CorpOfferTableContainer
        guid={guid}
        partnerId={partnerId}
        tab={outTab}
        lifecycle={lifecycle}
        filterAdapter={adapter}
        stubAdapter={stubAdapter}
        canCreate={corpOffers.create}
        canDelete={corpOffers.delete}
        canApprove={corpOffers.approve}
        canReject={corpOffers.reject}
        canResume={corpOffers.resume}
        canPause={corpOffers.pause}
        canArchive={corpOffers.archive}
        canDownload={corpOffers.download}
        canUpload={corpOffers.upload}
        canViewApprovalRegistry={corpOffers.canViewApprovalRegistry}
        canChangeApprovalRegistry={corpOffers.canEditApprovalRegistry}
        canChangeModerator={corpOffers.changeModerator}
        canDuplicate={corpOffers.duplicate}
        canRetrieve={corpOffers.retrieve}
      />
    </CorpOffersTableConfigContext.Provider>
  );
};

export default CorpOfferTableEntry;
