import productOffer, { ProductOfferService } from './productOffers';
import productDesk, { ProductDeskService } from './productDesk';

type ProductServices = {
  readonly productOffer: ProductOfferService;
  readonly productDesk: ProductDeskService;
};

const productServices: ProductServices = {
  productOffer,
  productDesk,
};

export default productServices;
