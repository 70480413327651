import { ButtonProps } from '@mui/material';
import React from 'react';
import { StyledButtonLink } from './controls';

export const ButtonLink = React.forwardRef((props: ButtonProps, ref: any) => {
  return (
    <StyledButtonLink
      ref={ref}
      fullWidth={false}
      color='primary'
      {...props}
      disableRipple
      disableFocusRipple
      disableTouchRipple
      variant='text'
    />
  );
});
