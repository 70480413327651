import { Nullable } from '../../../../../domain/model/types';
import { MpUserData } from '../../../../../domain/model/user';
import { OnChangeObjectAttribute } from '../../../../types';
import { ValidationResult, ValidationRules } from '../../../../utils/validation';
import { ColumnWrapper, StepWrapper, Wrapper } from './controls';
import UserMpStepProfile from './steps/profile';
import { EUserMpEditStep } from './utils';

interface UserMpEditProps {
  readonly step: EUserMpEditStep;
  readonly user: MpUserData;
  readonly validationProfile: Nullable<ValidationResult<MpUserData>>;
  readonly validationRulesProfile?: ValidationRules<MpUserData>;
  readonly isValidProfile: boolean;
  readonly onChangeProfileAttribute: OnChangeObjectAttribute<MpUserData>;
}

const UserMpEdit = (props: UserMpEditProps) => {
  const { step, user, validationProfile, validationRulesProfile, isValidProfile, onChangeProfileAttribute } = props;

  return (
    <Wrapper>
      <StepWrapper>
        <ColumnWrapper>
          {step === EUserMpEditStep.Profile && (
            <UserMpStepProfile
              user={user}
              validation={validationProfile}
              validationRules={validationRulesProfile}
              isValid={isValidProfile}
              onChangeAttribute={onChangeProfileAttribute}
            />
          )}
        </ColumnWrapper>
      </StepWrapper>
    </Wrapper>
  );
};

export default UserMpEdit;
