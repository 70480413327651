import { Grid } from '@mui/material';
import { ServicesManageHint } from '../../components/helpers/serviceManage';
import { ContentWrapper } from '../controls';

export const BookingOfferServiceManageHeaderAdapter = () => {
  return (
    <ContentWrapper>
      <Grid
        container
        spacing={2}
        xs={12}
      >
        <Grid
          item
          xs={12}
        >
          <ServicesManageHint />
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};
