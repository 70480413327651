import { EDateFormat } from '@/domain';
import moment from 'moment/moment';
import CustomerTableCell, { CustomerTableCellCommonProps } from './index';

const CustomerTableCellCreatedAt = (props: CustomerTableCellCommonProps) => {
  const {
    customer: { createdAt },
  } = props;

  const value = moment(createdAt).format(EDateFormat.DisplayDefault);

  return (
    <CustomerTableCell
      {...props}
      value={value}
    />
  );
};

export default CustomerTableCellCreatedAt;
