import { useCallback } from 'react';
import { ESortDirection } from '../../../../domain/model/enums';
import { PartnerShortDraft } from '../../../../domain/model/partner';
import DataTable, { DataTableMetadata } from '../../../components/common/table';
import TableActions from '../../../components/common/table/actions';
import { DataTableLoader } from '../../../components/common/table/loader';
import { EPartnerTableColumn, PartnerActions, PartnerActionTableType } from '../../general/partner/types';
import PartnerTableCellContact from '../components/tableCell/contact';
import PartnerTableCellEmail from '../components/tableCell/email';
import PartnerTableCellInn from '../components/tableCell/inn';
import PartnerTableCellKpp from '../components/tableCell/kpp';
import PartnerTableCellLastStatusDate from '../components/tableCell/lastStatusDate';
import PartnerTableCellName from '../components/tableCell/name';
import PartnerTableCellOgrn from '../components/tableCell/ogrn';
import PartnerTableCellPhone from '../components/tableCell/phone';
import PartnerTableCellReviewer from '../components/tableCell/reviewer';
import PartnersTableCellAdapter from './adapters/cell';
import { Wrapper } from './controls';
import { partnersIsFetchingSelector } from './store/selectors';
import { PartnerActionTableCall, PartnerTableActionProcess } from './utils';

interface PartnerTableProps {
  readonly metadata: DataTableMetadata<EPartnerTableColumn>;
  readonly partners: PartnerShortDraft[];
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onChangeMetadata: (metadata: DataTableMetadata<EPartnerTableColumn>) => void;
  readonly onClick: (partner: PartnerShortDraft) => void;
  readonly onPartnerAction: (call: PartnerActionTableCall) => void;
  readonly getActions: (partner: PartnerShortDraft) => PartnerActions<PartnerActionTableType>;
  readonly getActionProcesses: (partner: PartnerShortDraft) => PartnerTableActionProcess[];
}

const PartnerTable = (props: PartnerTableProps) => {
  const {
    sort,
    metadata,
    partners,
    onRequestSort,
    onPartnerAction,
    onClick,
    onChangeMetadata,
    getActions,
    getActionProcesses,
  } = props;

  const rows = partners.map((partner, index) => {
    return {
      [EPartnerTableColumn.Date]: (
        <PartnersTableCellAdapter
          index={index}
          component={PartnerTableCellLastStatusDate}
        />
      ),
      [EPartnerTableColumn.Name]: (
        <PartnersTableCellAdapter
          index={index}
          component={PartnerTableCellName}
        />
      ),
      [EPartnerTableColumn.OwnerName]: (
        <PartnersTableCellAdapter
          index={index}
          component={PartnerTableCellContact}
        />
      ),
      [EPartnerTableColumn.OwnerPhone]: (
        <PartnersTableCellAdapter
          index={index}
          component={PartnerTableCellPhone}
        />
      ),
      [EPartnerTableColumn.OwnerEmail]: (
        <PartnersTableCellAdapter
          index={index}
          component={PartnerTableCellEmail}
        />
      ),
      [EPartnerTableColumn.Reviewer]: (
        <PartnersTableCellAdapter
          index={index}
          component={PartnerTableCellReviewer}
        />
      ),
      [EPartnerTableColumn.Inn]: (
        <PartnersTableCellAdapter
          index={index}
          component={PartnerTableCellInn}
        />
      ),
      [EPartnerTableColumn.Kpp]: (
        <PartnersTableCellAdapter
          index={index}
          component={PartnerTableCellKpp}
        />
      ),
      [EPartnerTableColumn.Ogrn]: (
        <PartnersTableCellAdapter
          index={index}
          component={PartnerTableCellOgrn}
        />
      ),
      [EPartnerTableColumn.Actions]: null,
      data: partner,
    };
  });

  const onTableAction = useCallback(
    (action: PartnerActionTableType, partner: PartnerShortDraft) => onPartnerAction({ action, partner }),
    [onPartnerAction]
  );

  return (
    <Wrapper>
      <DataTable<PartnerShortDraft, EPartnerTableColumn>
        width='auto'
        metadata={metadata}
        overflowX='inherit'
        rowActions
        rows={rows}
        sort={{ column: sort.column, direction: sort.direction }}
        loader={<DataTableLoader selector={partnersIsFetchingSelector} />}
        getRowActions={partner => (
          <TableActions<PartnerShortDraft, PartnerActionTableType>
            entityLink={partner}
            actions={getActions(partner)}
            onExecute={onTableAction}
            isExecuting={getActionProcesses(partner).some(process => process.isFetching)}
          />
        )}
        onChangeMetadata={onChangeMetadata}
        onRowClick={(event, cell, { data }) => onClick(data)}
        onSort={(event, column, direction) => onRequestSort(column, direction)}
      />
    </Wrapper>
  );
};

export default PartnerTable;
