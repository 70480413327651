import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../components/common/header';
import { getBookingOrderStatusText } from '../../utils/common';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingOrderEditLoadedOrderSelector } from '../store/selectors';
import { OfferMasterTitle } from '@features/general/offer/components/title';

export const BookingOrderEditHeaderAdapter = () => {
  const handlers = useContextHandlers();
  const bookingOrder = useSelector(bookingOrderEditLoadedOrderSelector);

  return bookingOrder ? (
    <DefaultHeader
      sticky
      headline={
        <AppBreadcrumbs>
          <Typography color='textSecondary'>
            Заказы досуга и отдыха / {getBookingOrderStatusText(bookingOrder.status)}
          </Typography>
        </AppBreadcrumbs>
      }
      onClose={handlers.onClose}
    >
      <OfferMasterTitle>Заказ № {bookingOrder.number}</OfferMasterTitle>
    </DefaultHeader>
  ) : null;
};
