import { Grid, Typography } from '@mui/material';
import { ECmsContainerType } from '../../../../../../../domain/model/enums';
import { TargetRequest } from '../../../../../../../domain/model/target';
import { Nullable } from '../../../../../../../domain/model/types';
import ValidationErrorPanel from '../../../../../../components/common/validation/errorPanel';
import { OnChangeObjectAttribute } from '../../../../../../types';
import { ValidationResult } from '../../../../../../utils/validation';
import { CmsContainerView } from '../../../../types';
import {
  CmsContainerEditComponentBannerSize1,
  CmsContainerEditComponentBannerSize2,
  CmsContainerEditComponentBannerSize3,
} from '../../attributes/bannerSize';
import CmsContainerEditComponentCommon from '../../attributes/common';
import CmsContainerEditComponentOfferType from '../../attributes/offerType';
import CmsContainerEditComponentPeriod from '../../attributes/period';
import CmsContainerEditComponentTarget from '../../attributes/target';
import { CmsContainerCreateComponentProps, CmsContainerCreateConfiguration } from '../../types';
import { ColumnWrapper, Content, Wrapper } from './controls';

interface CmsContainerCreateStepGeneralProps extends CmsContainerCreateComponentProps {
  readonly configuration: CmsContainerCreateConfiguration;
  readonly isValid: boolean;
  readonly validation: Nullable<ValidationResult<CmsContainerView & TargetRequest>>;
  readonly onChange: OnChangeObjectAttribute<CmsContainerView>;
}

const CmsContainerCreateStepGeneral = (props: CmsContainerCreateStepGeneralProps) => {
  const { cmsContainer, isValid } = props;

  const containerType = cmsContainer.type!.code;

  return (
    <Wrapper>
      <ColumnWrapper>
        <Content>
          <Grid
            container
            spacing={3}
            direction='column'
          >
            <Grid item>
              <Typography variant='h3'>Данные блока</Typography>
            </Grid>

            <Grid item>
              <CmsContainerEditComponentCommon {...props} />
            </Grid>
            {containerType === ECmsContainerType.Banner1Resizable && (
              <Grid item>
                <CmsContainerEditComponentBannerSize2 {...props} />
              </Grid>
            )}
            {containerType === ECmsContainerType.Banner3Resizable && (
              <Grid item>
                <CmsContainerEditComponentBannerSize1 {...props} />
              </Grid>
            )}
            {containerType === ECmsContainerType.Banner1LinkResizable && (
              <Grid item>
                <CmsContainerEditComponentBannerSize3 {...props} />
              </Grid>
            )}
            <Grid item>
              <CmsContainerEditComponentPeriod {...props} />
            </Grid>
            {![ECmsContainerType.CompilationPartner, ECmsContainerType.Banner1LinkResizable].includes(
              containerType
            ) && (
              <Grid item>
                <CmsContainerEditComponentOfferType {...props} />
              </Grid>
            )}
            <Grid item>
              <CmsContainerEditComponentTarget
                {...props}
                isNotRequiredOfferType={containerType === ECmsContainerType.Banner1LinkResizable}
              />
            </Grid>

            {!isValid && (
              <Grid item>
                <ValidationErrorPanel text='Заполните все обязательные поля, чтобы перейти к следующему шагу' />
              </Grid>
            )}
          </Grid>
        </Content>
      </ColumnWrapper>
    </Wrapper>
  );
};

export default CmsContainerCreateStepGeneral;
