import { EBannerPartition, UUID } from '@/domain';
import { DataFilterAdapter } from '@/presentation/hooks/useDataFilterAdapter';
import { BannersTableContext } from '@features/banner/table/context';
import BannerTableFooterContainer from '@features/banner/table/footerContainer';
import { useBannersTableHandlers } from '@features/banner/table/hooks/useTable';
import TableCommonLayout from '@layouts/tableCommon/container';
import { useMemo } from 'react';
import { BannerActions, BannerActionTableType } from '../types';
import {
  BannerTableAdapter,
  BannerTableBehaviorAdapter,
  BannerTableFilterAdapter,
  BannerTableHeaderAdapter,
  BannerTableTabsAdapter,
} from './adapters';
import { EBannerTableFilterItem } from './filterUtils';
import { EBannerTableColumn, EBannerTableTab, getBannerTableTabActions } from './utils';

interface BannerTableContainerProps {
  readonly guid: UUID;
  readonly partition: EBannerPartition;
  readonly tab: EBannerTableTab;
  readonly filterAdapter: DataFilterAdapter<EBannerTableColumn, EBannerTableFilterItem>;
  readonly canEdit?: boolean;
  readonly canCreate?: boolean;
  readonly canPause?: boolean;
  readonly canResume?: boolean;
  readonly canArchive?: boolean;
  readonly canChangeSortIndex?: boolean;
}

const BannerTableContainer = (props: BannerTableContainerProps) => {
  const {
    guid,
    partition,
    tab,
    filterAdapter,
    canEdit,
    canCreate,
    canPause,
    canResume,
    canArchive,
    canChangeSortIndex,
  } = props;

  const handlers = useBannersTableHandlers({ partition, tab });

  const tableTabActions = useMemo<BannerActions<BannerActionTableType>>(
    () =>
      getBannerTableTabActions(
        {
          canArchive,
          canResume,
          canPause,
          canSave: canEdit,
          canEdit,
          canChangeSortIndex,
        },
        tab
      ),
    [canArchive, canResume, canPause, canEdit, canChangeSortIndex, tab]
  );

  return (
    <BannersTableContext.Provider value={handlers}>
      <BannerTableBehaviorAdapter
        partition={partition}
        guid={guid}
        tab={tab}
      />
      <TableCommonLayout
        filter={
          <BannerTableFilterAdapter
            partition={partition}
            filterAdapter={filterAdapter}
          />
        }
        header={
          <BannerTableHeaderAdapter
            partition={partition}
            canCreate={canCreate}
          />
        }
        tabs={
          <BannerTableTabsAdapter
            partition={partition}
            tab={tab}
          />
        }
        table={
          <BannerTableAdapter
            partition={partition}
            filterAdapter={filterAdapter}
            tabActions={tableTabActions}
          />
        }
        footer={BannerTableFooterContainer}
      ></TableCommonLayout>
    </BannersTableContext.Provider>
  );
};

export default BannerTableContainer;
