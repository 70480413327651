import { EPartnerStatus } from '../../../domain/model/enums';
import { EPartnerApplicationActionType } from './types';

export const partnerApplicationConsiderDays = 10;

export const isPartnerApplicationEditable = (status: EPartnerStatus, isAdminMp: boolean): boolean => {
  if (isAdminMp) {
    return [EPartnerStatus.OnVerification].includes(status);
  } else {
    return [EPartnerStatus.Unverified, EPartnerStatus.ToVerification, EPartnerStatus.Rejected].includes(status);
  }
};

export const getPartnerApplicationActionName = (type: EPartnerApplicationActionType): string => {
  switch (type) {
    case EPartnerApplicationActionType.Edit:
      return 'Редактировать';
    case EPartnerApplicationActionType.Approve:
      return 'Одобрить заявку';
    case EPartnerApplicationActionType.Reject:
      return 'Отклонить';
    case EPartnerApplicationActionType.SendToVerification:
      return 'Отправить на проверку';
    case EPartnerApplicationActionType.ReturnToVerification:
      return 'Вернуть на проверку';
    case EPartnerApplicationActionType.Save:
      return 'Сохранить и завершить редактирование';
  }
};
