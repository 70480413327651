import axios, { AxiosResponse } from 'axios';
import { UserAgreement, UserAgreementDocLink } from '../../domain/model/legalAgreement';
import { createCancelToken } from './index';
import { ApiCancellable } from './types';
import { getComServicesEndpoint, getSportBaseEndpoint } from './utils';

export interface SignAgreementsRequest {
  readonly docs: UserAgreementDocLink[];
}

export type GetAgreementsProps = ApiCancellable;

export type SignAgreementsProps = {
  readonly data: SignAgreementsRequest;
};

type LegalAgreementsApiType = {
  readonly sport: {
    readonly getAgreements: (props: GetAgreementsProps) => Promise<AxiosResponse<UserAgreement[]>>;
    readonly signAgreements: (props: SignAgreementsProps) => Promise<AxiosResponse<void>>;
  };
  readonly mp: {
    readonly getAgreements: (props: GetAgreementsProps) => Promise<AxiosResponse<UserAgreement[]>>;
    readonly signAgreements: (props: SignAgreementsProps) => Promise<AxiosResponse<void>>;
  };
};

const LegalAgreementsApi: LegalAgreementsApiType = {
  sport: {
    getAgreements: ({ signal }) => {
      return axios.get(`${getSportBaseEndpoint()}/user/current/agreements`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    signAgreements: ({ data }) => {
      return axios.post(`${getSportBaseEndpoint()}/user/current/agreements`, data);
    },
  },
  mp: {
    getAgreements: ({ signal }) => {
      return axios.get(`${getComServicesEndpoint()}/users/current/agreements`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    signAgreements: ({ data }) => {
      return axios.post(`${getComServicesEndpoint()}/users/current/agreements`, data);
    },
  },
};

export default LegalAgreementsApi;
