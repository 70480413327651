import { UUID } from '../../../../../domain/model/types';
import { useContextConfig } from '../hooks/useContextConfig';
import useOfferHistoryTableSearch from '../hooks/useSearch';
import { EVariantHistory } from '../utils/common';

interface OfferHistoryTableBehaviorAdapterProps {
  readonly offerId: UUID;
  readonly typeHistory: EVariantHistory;
}

export const OfferHistoryTableBehaviorAdapter = (props: OfferHistoryTableBehaviorAdapterProps) => {
  const { defaultTab } = useContextConfig();
  useOfferHistoryTableSearch({ ...props, defaultTab });

  return null;
};
