import React from 'react';
import { Content, Footer, Wrapper } from './controls';

interface DefaultWrapperProps {
  readonly type?: 'table' | 'details';
  readonly stickyHeader?: boolean;
  readonly fullHeight?: boolean;
  readonly header?: React.ReactNode;
  readonly footer?: React.ReactNode;
  readonly children: any;
}

export const DefaultContentWrapper = React.forwardRef((props: DefaultWrapperProps, ref: any) => {
  const { type, stickyHeader, fullHeight, header, footer, children } = props;

  return (
    <Wrapper
      ref={ref}
      fullHeight={fullHeight}
      footer={footer}
    >
      <Content
        type={type}
        stickyHeader={stickyHeader}
      >
        {header}
        {children}
      </Content>
      {footer && <Footer>{footer}</Footer>}
    </Wrapper>
  );
});
