import { EReportType } from '@features/report/types';
import React from 'react';

interface ReportDownloadProps {
  readonly type: EReportType;
  readonly partnerId: Nullable<UUID>;
}

export interface ReportDownloadsContextType {
  readonly onOpenDownloadDialog: (props: ReportDownloadProps) => void;
  readonly onCloseDownloadDialog: (reportType: EReportType) => void;
}

const ReportDownloadsContext = React.createContext<ReportDownloadsContextType>({} as ReportDownloadsContextType);

export default ReportDownloadsContext;
