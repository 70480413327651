import { DataTableMetadata } from '@components/common/table';
import useDataTableSettings from '@/presentation/hooks/useDataTableSettings';
import { EBookingOrderItemsTableColumn } from './common';
import { getBookingOrderItemsTableColumns } from '../table/utils';
import { useMemo } from 'react';

type ColumnsType = EBookingOrderItemsTableColumn;

type UseBookingOrderTableResult = {
  readonly metadata: DataTableMetadata<ColumnsType>;
  readonly onChangeMetadata: (metadata: DataTableMetadata<ColumnsType>) => void;
};

export const useBookingOrderItemsTable = (): UseBookingOrderTableResult => {
  const sourceColumns = useMemo(() => getBookingOrderItemsTableColumns(), []);

  const { metadata, onChangeMetadata } = useDataTableSettings<ColumnsType>({
    name: 'booking-order-items',
    sourceColumns,
  });

  return {
    metadata,
    onChangeMetadata,
  };
};
