import { FCC, ReactNode } from 'react';
import { Nullable } from '../../../../../domain/model/types';
import { PanelAction, PanelActions } from '../../../../types';
import { DefaultFooterWrapper } from '../../wrappers/footer';
import ActionsComponent from '../index';

interface EntityMasterActionsProps<A extends string> {
  readonly actions: PanelActions<A>;
  readonly show?: boolean;
  readonly renderDirection?: 'rtl' | 'ltr';
  readonly navAdapter?: Nullable<ReactNode>;
  readonly wrapper?: FCC;
  readonly wrapperRef?: any;
  readonly onAction: (action: PanelAction<A>) => void;
}

const MasterActionsComponent = <A extends string>(props: EntityMasterActionsProps<A>) => {
  const {
    actions,
    onAction,
    renderDirection,
    navAdapter,
    wrapper: Wrapper = DefaultFooterWrapper,
    wrapperRef,
    show = true,
  } = props;

  if (!show) {
    return null;
  }

  return (
    <Wrapper ref={wrapperRef}>
      <ActionsComponent<A>
        actions={actions}
        onAction={onAction}
        renderDirection={renderDirection}
        navAdapter={navAdapter}
      />
    </Wrapper>
  );
};

export default MasterActionsComponent;
