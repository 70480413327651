import { FC } from 'react';
import { useSelector } from 'react-redux';
import { BookingOfferTableCellCommonProps } from '../../components/tableCell';
import { bookingOffersBookingOfferByIndexSelector } from '../store/selectors';

export type BookingOffersTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<BookingOfferTableCellCommonProps>;
};

const BookingOffersTableCellAdapter = (props: BookingOffersTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const bookingOffer = useSelector(bookingOffersBookingOfferByIndexSelector(index));

  return bookingOffer ? <Component bookingOffer={bookingOffer} /> : null;
};

export default BookingOffersTableCellAdapter;
