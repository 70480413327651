import { ClientOrg } from '@/domain/model/clientOrg';
import { EClientOrgUserTableTab } from '../../types';
import useClientOrgUsersTableCount from '../hooks/useCount';
import useClientOrgUsersTableSearch from '../hooks/useSearch';
import useClientOrgUsersTableSession from '../hooks/useSession';

interface ClientOrgUserBehaviorAdapterProps {
  readonly guid: UUID;
  readonly tab: EClientOrgUserTableTab;
  readonly clientOrg: ClientOrg;
}

export const ClientOrgUserBehaviorAdapter = ({ guid, tab, clientOrg }: ClientOrgUserBehaviorAdapterProps) => {
  useClientOrgUsersTableSession({ guid, tab, clientOrg });
  useClientOrgUsersTableSearch({ tab });
  useClientOrgUsersTableCount({ tab });

  return null;
};
