import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../../data/api';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../../data/store/types';
import { ESportEventType } from '../../../../../../domain/model/enums';
import { FriendlyMatch, SportEvent } from '../../../../../../domain/model/event';
import { Nullable, UUID } from '../../../../../../domain/model/types';

export const eventEditFetch = createAsyncThunk<
  Nullable<SportEvent>,
  { id: UUID; eventType: ESportEventType },
  AppThunkAPIConfig
>('events/edit/fetch', async ({ id, eventType }, { rejectWithValue }) => {
  try {
    switch (eventType) {
      case ESportEventType.FriendlyMatch:
        return (await Api.event.friendlyMatch.one({ id })).data as any as SportEvent;
      default:
        console.error(`Unknown type ${eventType}`);
    }
    return null;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const eventEditSave = createAsyncThunk<
  Nullable<{ data: SportEvent; draft: boolean }>,
  { object: SportEvent; draft?: boolean },
  AppThunkAPIConfig
>('event/edit/save', async ({ object, draft }, { rejectWithValue }) => {
  try {
    switch (object.type.code) {
      case ESportEventType.FriendlyMatch: {
        const data = (await Api.event.friendlyMatch.create(object as any as FriendlyMatch)).data as any as SportEvent;
        return { data, draft: draft ?? false };
      }
      default:
        console.error(`Unknown type ${object.type}`);
    }
    return null;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface EventEditState {
  guid: Nullable<UUID>;
  edit: Fetchable & {
    data: Nullable<SportEvent>;
    validationErrors: {
      [field: string]: any;
    };
    modified: boolean;
  };
  save: Fetchable & {
    draft: boolean;
  };
}

interface Reducers extends SliceCaseReducers<EventEditState> {
  eventEditStartSession: CaseReducer<EventEditState, PayloadAction<{ guid: UUID }>>;
  eventEditSetAttribute: CaseReducer<EventEditState, PayloadAction<{ name: keyof SportEvent; value: any }>>;
  eventEditSaveReset: CaseReducer<EventEditState, PayloadAction<undefined>>;
  eventEditSetValidationErrors: CaseReducer<EventEditState, PayloadAction<{ errors: { [field: string]: any } }>>;
}

const slice = createSlice<EventEditState, Reducers, 'event/edit'>({
  name: 'event/edit',
  initialState: {
    guid: null,
    edit: {
      ...fetchableDefault,
      data: null,
      validationErrors: {},
      modified: false,
    },
    save: {
      ...fetchableDefault,
      draft: false,
    },
  },
  reducers: {
    eventEditStartSession: (state, { payload }) => {
      const { guid } = payload;
      if (state.guid !== guid) {
        state.edit.data = null;
        state.edit.validationErrors = {};
        state.edit.modified = false;
        state.edit.isFetched = false;
      }
      state.guid = guid;
    },
    eventEditSetAttribute: (state, { payload }) => {
      const { name, value } = payload;
      if (state.edit.data) (state.edit.data[name] as keyof SportEvent) = value;
      state.edit.modified = true;
    },
    eventEditSaveReset: state => {
      state.save.isFetching = false;
      state.save.isFetched = false;
      state.save.isFailed = false;
    },
    eventEditSetValidationErrors: (state, { payload }) => {
      const { errors } = payload;
      state.edit.validationErrors = errors;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(eventEditFetch.pending, state => {
        state.edit.isFetching = true;
        state.edit.isFetched = false;
        state.edit.isFailed = false;
      })
      .addCase(eventEditFetch.fulfilled, (state, { payload }) => {
        state.edit.isFetching = false;
        state.edit.isFetched = true;
        state.edit.isFailed = false;
        state.edit.data = payload;
      })
      .addCase(eventEditFetch.rejected, state => {
        state.edit.isFetching = false;
        state.edit.isFetched = false;
        state.edit.isFailed = true;
      })
      .addCase(eventEditSave.pending, state => {
        state.save.isFetching = true;
        state.save.isFetched = false;
        state.save.isFailed = false;
      })
      .addCase(eventEditSave.fulfilled, (state, { payload }) => {
        const data = payload?.data ?? null;
        state.save.isFetching = false;
        state.save.isFetched = true;
        state.save.isFailed = false;
        state.edit.data = data;
        state.edit.modified = false;
        state.edit.validationErrors = {};
      })
      .addCase(eventEditSave.rejected, state => {
        state.save.isFetching = false;
        state.save.isFetched = false;
        state.save.isFailed = true;
      });
  },
});

export const { eventEditStartSession, eventEditSetAttribute, eventEditSaveReset, eventEditSetValidationErrors } =
  slice.actions;

export default slice.reducer;
