import { forwardRef } from 'react';
import { Nullable, UUID } from '../../../../domain/model/types';
import { SportUserProfile } from '../../../../domain/model/user';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import TableCommonLayout from '../../../layouts/tableCommon/container';
import { NumberRange } from '../../../types';
import { ESportsmansTableFilterItem } from '../filterUtils';
import { ESportsmanTableColumn } from '../table/utils';
import { SportsmansSearchBehaviorAdapter } from './adapters/behavior';
import SportsmanSearchFilterAdapter from './adapters/filter';
import SportsmanSearchTableAdapter from './adapters/table';
import { SportsmansSearchConfigContext, SportsmansSearchContext } from './context';
import SportsmanSearchFooterContainer from './footerContainer';
import { useSportsmansSearchHandlers } from './hooks/useSportsmanSearch';
import { useSportsmansSearchConfig } from './hooks/useSportsmanSearchConfig';

interface SportsmanSearchContainerSingleProps {
  readonly initialSelected: Nullable<SportUserProfile>;
  readonly selectedMaxCount: 1;
  readonly onSelect: (sportsman: Nullable<SportUserProfile>) => void;
}

interface SportsmanSearchContainerMultipleProps {
  readonly initialSelected: Nullable<SportUserProfile[]>;
  readonly selectedMaxCount: NumberRange<2, 100> | false;
  readonly onSelect: (sportsmen: SportUserProfile[]) => void;
}

export type SportsmanSearchContainerSelectedProps =
  | SportsmanSearchContainerSingleProps
  | SportsmanSearchContainerMultipleProps;

type SportsmansSearchContainerProps = {
  readonly guid: UUID;
  readonly filterAdapter: DataFilterAdapter<ESportsmanTableColumn, ESportsmansTableFilterItem>;
  readonly onSportsmanClick?: (sportUserProfile: SportUserProfile) => void;
} & SportsmanSearchContainerSelectedProps;

export const SportsmansSearchContainer = (props: SportsmansSearchContainerProps) => {
  const { filterAdapter, selectedMaxCount } = props;

  const isSingleSelect = selectedMaxCount === 1;
  const handlers = useSportsmansSearchHandlers(props);

  const config = useSportsmansSearchConfig({
    isSingleSelect,
  });

  return (
    <SportsmansSearchConfigContext.Provider value={config}>
      <SportsmansSearchContext.Provider value={handlers}>
        <SportsmansSearchBehaviorAdapter {...props} />
        <TableCommonLayout
          filter={<SportsmanSearchFilterAdapter filterAdapter={filterAdapter} />}
          table={<SportsmanSearchTableAdapter filterAdapter={filterAdapter} />}
          footer={forwardRef((footerProps, ref: any) => (
            <SportsmanSearchFooterContainer
              ref={ref}
              maxCount={selectedMaxCount}
              onSelect={handlers.onReturn}
              isSingleSelect={isSingleSelect}
            />
          ))}
        />
      </SportsmansSearchContext.Provider>
    </SportsmansSearchConfigContext.Provider>
  );
};
