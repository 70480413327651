import { FCC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useHistoryExtensions from '../../../../../hooks/useHistoryExtensions';
import { getConfigurationPrivilegeRoute } from '../../../../configuration/entry';
import { BookingOfferCategoriesEditHandlersContext, BookingOfferCategoriesEditHandlersContextValue } from '../context';
import {
  bookingOfferCategoriesEditCheckRelationShips,
  bookingOfferCategoriesEditCreate,
  bookingOfferCategoriesEditDelete,
  bookingOfferCategoriesEditSetDialogState,
  bookingOfferCategoriesEditUpdate,
} from '../store/slice';
import { BookingOfferCategoriesEditCommonProps } from '../types';

export const BookingOfferCategoriesEditHandlersProvider: FCC<BookingOfferCategoriesEditCommonProps> = ({
  children,
  access,
}) => {
  const dispatch = useDispatch();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const onChangeDialogState = useCallback<BookingOfferCategoriesEditHandlersContextValue['onChangeDialogState']>(
    (name, data) => {
      dispatch(bookingOfferCategoriesEditSetDialogState({ name, data }));
    },
    [dispatch]
  );

  const onCreate = useCallback<BookingOfferCategoriesEditHandlersContextValue['onCreate']>(
    async bookingOfferCategory => {
      return await dispatch(bookingOfferCategoriesEditCreate(bookingOfferCategory)).unwrap();
    },
    [dispatch]
  );

  const onEdit = useCallback<BookingOfferCategoriesEditHandlersContextValue['onEdit']>(
    async bookingOfferCategory => {
      return await dispatch(bookingOfferCategoriesEditUpdate(bookingOfferCategory)).unwrap();
    },
    [dispatch]
  );

  const onDelete = useCallback<BookingOfferCategoriesEditHandlersContextValue['onDelete']>(
    async bookingOfferCategory => {
      await dispatch(bookingOfferCategoriesEditDelete(bookingOfferCategory)).unwrap();
    },
    [dispatch]
  );

  const onGetRelationShipsCount = useCallback<
    BookingOfferCategoriesEditHandlersContextValue['onGetRelationShipsCount']
  >(
    async bookingOfferCategory => {
      return await dispatch(bookingOfferCategoriesEditCheckRelationShips(bookingOfferCategory)).unwrap();
    },
    [dispatch]
  );

  const onClose = useCallback<BookingOfferCategoriesEditHandlersContextValue['onClose']>(() => {
    gotoPrevIndependentLocation(getConfigurationPrivilegeRoute({}));
  }, [gotoPrevIndependentLocation]);

  const value: BookingOfferCategoriesEditHandlersContextValue = {
    onChangeDialogState,
    onCreate,
    onEdit,
    onDelete,
    onGetRelationShipsCount,
    onClose,
  };

  return (
    <BookingOfferCategoriesEditHandlersContext.Provider value={value}>
      {children}
    </BookingOfferCategoriesEditHandlersContext.Provider>
  );
};
