import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../../data/store/types';
import { CmsContainer, CmsSitePage } from '../../../../../../domain/model/cms';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import cmsServices from '../../../services';

export const cmsContainerByIdFetch = createAsyncThunk<
  { cmsSitePage: CmsSitePage; cmsContainer: CmsContainer },
  UUID,
  AppThunkAPIConfig
>('cmsContainer/byId/fetch', async (id, { rejectWithValue, signal }) => {
  try {
    const cmsSitePage = await cmsServices.sitePage.byContainerId({ containerId: id, signal });
    if (!cmsSitePage) {
      throw new Error(`Не найдена страница конструктора для блока с id=${id}`);
    }

    const cmsContainer = await cmsServices.container.byId({ id, signal });

    return { cmsContainer, cmsSitePage };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface CmsContainerByIdState extends Fetchable {
  readonly cmsSitePage: Nullable<CmsSitePage>;
  readonly cmsContainer: Nullable<CmsContainer>;
}

type Reducer<T = undefined> = CaseReducer<CmsContainerByIdState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CmsContainerByIdState> {
  cmsContainerByIdStateReset: Reducer;
}

const slice = createSlice<CmsContainerByIdState, Reducers, 'byId'>({
  name: 'byId',
  initialState: {
    ...fetchableDefault,
    cmsSitePage: null,
    cmsContainer: null,
  },
  reducers: {
    cmsContainerByIdStateReset: state => {
      state.isFetching = false;
      state.isFetched = false;
      state.isFailed = false;
      state.cmsSitePage = null;
      state.cmsContainer = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(cmsContainerByIdFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
        state.cmsSitePage = null;
        state.cmsContainer = null;
      })
      .addCase(cmsContainerByIdFetch.fulfilled, (state, { payload }) => {
        const { cmsSitePage, cmsContainer } = payload;
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.cmsSitePage = cmsSitePage;
        state.cmsContainer = cmsContainer;
      })
      .addCase(cmsContainerByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
        } else {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = false;
        }
      });
  },
});

export const { cmsContainerByIdStateReset } = slice.actions;

export default slice.reducer;
