import DataTableCellSortIndex from '../../../../../../components/common/table/cell/sortIndex';
import { useMemo, useState } from 'react';
import { Nullable } from '../../../../../../../domain/model/types';

type ProductOrderItemTableCellQtyProps = {
  value: Nullable<number>;
  max: number;
  onChange(value: number): void;
};

const ProductOrderItemTableCellQty = (props: ProductOrderItemTableCellQtyProps) => {
  const { value, max, onChange } = props;
  const [editMode, setEditMode] = useState(false);

  if (!value) {
    return <>-</>;
  }

  const textFieldProps = useMemo(
    () => ({
      fullWidth: false,
      style: {
        maxWidth: '5rem',
      },
    }),
    []
  );

  return (
    <DataTableCellSortIndex
      textFieldProps={textFieldProps}
      editMode={editMode}
      value={value}
      max={max}
      label={(min, max) => `${min}-${max}`}
      onChange={onChange}
      hideEditIcon={editMode}
      isFetching={false}
      onChangeEditMode={setEditMode}
    />
  );
};

export default ProductOrderItemTableCellQty;
