import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { UUID } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import useBreadcrumbsActions from '../../features/general/breadcrumbs/useBreadcrumbsActions';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import PartnerEmployeeEditContainer from '../../features/partnerEmployee/edit/container';
import { getPartnerEmployeesTableRoute } from '../../features/partnerEmployee/entry';
import { EPartnerEmployeeStep } from '../../features/partnerEmployee/utils';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';
import useHistoryExtensions from '../../hooks/useHistoryExtensions';

const PartnerEmployeeEditScreen = () => {
  const dispatch = useDispatch();
  const { putBreadcrumbs } = useBreadcrumbsActions();
  const { partnerEmployees } = useCurrentUser().accessMatrix;

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const { id } = useParams<{ id: UUID }>();

  const onClose = () => {
    gotoPrevIndependentLocation(getPartnerEmployeesTableRoute({}));
  };

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  useEffect(() => {
    putBreadcrumbs([{ label: 'Сотрудники' }]);
  }, []);

  if (!partnerEmployees?.edit) {
    return <ForbiddenComponent />;
  }

  return (
    <PartnerEmployeeEditContainer
      id={id}
      step={EPartnerEmployeeStep.Profile}
      onClose={onClose}
    />
  );
};

export default PartnerEmployeeEditScreen;
