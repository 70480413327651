import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault, fetchableFailed, fetchableFetched, fetchableFetching } from '@/data/store/types';
import {
  ESportEventLevel,
  EUserType,
  SportEnumOption,
  SportUserProfile,
  TournamentIndividual,
  TournamentIndividualRequest,
} from '@/domain';
import {
  CaseReducer,
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { ValidationItemResult, ValidationResult } from '../../../../../utils/validation';
import { EventStatisticsProps } from '../../../components/statistics';
import eventServices from '../../../services';
import { TournamentIndividualCreateStepType } from '../../../types';
import { TournamentIndividualCreateUiState } from '../types';
import {
  convertTournamentIndividualToTournamentIndividualRequest,
  createNewTournamentIndividualRequest,
} from '../utils';

export const tournamentIndividualCreateByIdFetch = createAsyncThunk<
  Nullable<TournamentIndividual>,
  {
    id: Nullable<UUID>;
    level: Nullable<SportEnumOption<ESportEventLevel>>;
    userType: Nullable<EUserType>;
    admin: Nullable<SportUserProfile>;
  },
  AppThunkAPIConfig
>('tournamentIndividual/create/byId/fetch', async ({ id }, { rejectWithValue, signal }) => {
  try {
    if (!id) return null;
    return await eventServices.tournamentIndividual.one({ id, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tournamentIndividualCreateEventAdmins = createAsyncThunk<SportUserProfile[], {}, AppThunkAPIConfig>(
  'tournamentIndividual/create/eventAdmins',
  async (data, { rejectWithValue }) => {
    try {
      const response = await eventServices.users.eventAdmins();
      return response.content;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tournamentIndividualCreateSave = createAsyncThunk<
  TournamentIndividual,
  TournamentIndividualRequest,
  AppThunkAPIConfig
>('tournamentIndividual/create/save', async (tournament, { rejectWithValue }) => {
  try {
    return await eventServices.tournamentIndividual.save({ tournament });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tournamentIndividualCreatePublish = createAsyncThunk<
  TournamentIndividual,
  TournamentIndividualRequest,
  AppThunkAPIConfig
>('tournamentIndividual/create/publish', async (tournament, { rejectWithValue }) => {
  try {
    return await eventServices.tournamentIndividual.publish({ tournament });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tournamentIndividualCreatePublishAnnounce = createAsyncThunk<
  TournamentIndividual,
  TournamentIndividualRequest,
  AppThunkAPIConfig
>('tournamentIndividual/create/publishAnnounce', async (tournament, { rejectWithValue }) => {
  try {
    return await eventServices.tournamentIndividual.announce({ tournament });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export type TournamentIndividualCreateValidationStepper = Nullable<
  Record<TournamentIndividualCreateStepType, Nullable<ValidationResult<any>>>
>;

export interface TournamentIndividualCreateState {
  readonly guid: Nullable<UUID>;
  readonly modified: boolean;
  readonly byId: Fetchable & {
    readonly id: Nullable<UUID>;
    readonly tournament: TournamentIndividualRequest;
    readonly loadedData: Nullable<TournamentIndividual>;
  };
  readonly eventAdmins: Fetchable & {
    readonly data: SportUserProfile[];
  };
  readonly publish: Fetchable;
  readonly save: Fetchable;
  readonly dialogs: {
    readonly close: Nullable<TournamentIndividualRequest>;
  };
  readonly validation: ValidationResult<TournamentIndividualRequest>;
  readonly validationStepper: TournamentIndividualCreateValidationStepper;
  readonly ui: Nullable<TournamentIndividualCreateUiState>;
  readonly statistics: Nullable<EventStatisticsProps>;
}

type Reducer<T = undefined> = CaseReducer<TournamentIndividualCreateState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<TournamentIndividualCreateState> {
  tournamentIndividualCreateStartSession: Reducer<{
    guid: Nullable<UUID>;
  }>;
  tournamentIndividualCreateSetAttribute: Reducer<{
    name: keyof TournamentIndividualRequest;
    value: any;
  }>;
  tournamentIndividualCreateSetModified: Reducer<boolean>;
  tournamentIndividualCreateSetUiState: Reducer<{
    name: keyof TournamentIndividualCreateUiState;
    value: any;
  }>;
  tournamentIndividualCreateClearAttributeValidation: Reducer<keyof TournamentIndividualRequest>;
  tournamentIndividualCreateSetAttributeValidation: Reducer<{
    name: keyof TournamentIndividualRequest;
    results: Nullable<ValidationItemResult>;
  }>;
  tournamentIndividualCreateSetValidation: Reducer<Nullable<ValidationResult<TournamentIndividualRequest>>>;
  tournamentIndividualCreateSetValidationStepper: Reducer<
    Nullable<Record<TournamentIndividualCreateStepType, Nullable<ValidationResult<any>>>>
  >;
  tournamentIndividualCreateClearAllValidations: Reducer;
  tournamentIndividualCreateClearActionsState: Reducer;
  tournamentIndividualCreateSetDialogState: Reducer<{
    name: keyof TournamentIndividualCreateState['dialogs'];
    data: Nullable<TournamentIndividualRequest>;
  }>;
}

const slice = createSlice<TournamentIndividualCreateState, Reducers, 'tournamentIndividual/create'>({
  name: 'tournamentIndividual/create',
  initialState: {
    guid: null,
    modified: false,
    byId: {
      ...fetchableDefault,
      id: null,
      tournament: createNewTournamentIndividualRequest(),
      loadedData: null,
    },
    eventAdmins: {
      ...fetchableDefault,
      data: [],
    },
    publish: {
      ...fetchableDefault,
    },
    save: {
      ...fetchableDefault,
    },
    dialogs: {
      close: null,
    },
    validation: {},
    validationStepper: null,
    ui: null,
    statistics: null,
  },
  reducers: {
    tournamentIndividualCreateStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;
        state.byId = {
          ...fetchableDefault,
          id: null,
          tournament: createNewTournamentIndividualRequest(),
          loadedData: null,
        };
        state.modified = false;
        state.validation = {};
        state.ui = null;
      }
    },
    tournamentIndividualCreateSetModified: (state, { payload }) => {
      state.modified = payload;
    },
    tournamentIndividualCreateSetStatistics: (state, { payload }) => {
      state.statistics = payload;
    },
    tournamentIndividualCreateClearActionsState: state => {
      state.byId.isFetching = false;
      state.byId.isFetched = false;
      state.byId.isFailed = false;
      
      state.publish = fetchableDefault;
      state.save = fetchableDefault;
      state.validation = {};
      state.validationStepper = null;
      state.ui = null;
      state.dialogs = {
        close: null,
      };
    },
    tournamentIndividualCreateSetAttribute: (state, { payload }) => {
      const { name, value } = payload;

      if (state.byId.tournament) {
        (state.byId.tournament[name] as keyof TournamentIndividualRequest) = value;
      }
    },
    tournamentIndividualCreateSetUiState: (state, { payload }) => {
      const { name, value } = payload;
      if (!state.ui) {
        state.ui = {
          steps: [],
          options: [],
          fields: {
            visible: [],
            disabled: [],
          },
        };
      }
      state.ui[name] = value;
    },
    tournamentIndividualCreateClearAttributeValidation: (state, { payload }) => {
      delete state.validation?.[payload];
    },
    tournamentIndividualCreateSetAttributeValidation: (state, { payload }) => {
      const { name, results } = payload;
      if (!results) {
        delete state.validation?.[name];
      } else {
        state.validation[name] = results;
      }
    },
    tournamentIndividualCreateSetValidation: (state, { payload }) => {
      state.validation = payload ?? {};
    },
    tournamentIndividualCreateSetValidationStepper: (state, { payload }) => {
      state.validationStepper = payload;
    },
    tournamentIndividualCreateClearAllValidations: state => {
      state.validation = {};
    },
    tournamentIndividualCreateSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(tournamentIndividualCreateByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
      })
      .addCase(tournamentIndividualCreateByIdFetch.fulfilled, (state, { meta, payload }) => {
        const { level, userType, admin } = meta.arg;
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;
        state.byId.tournament = payload
          ? convertTournamentIndividualToTournamentIndividualRequest(payload)
          : {
              ...createNewTournamentIndividualRequest(),
              level,
              userType,
              admin,
            };
        state.byId.loadedData = payload;
      })
      .addCase(tournamentIndividualCreateByIdFetch.rejected, state => {
        state.byId.isFetching = false;
        state.byId.isFetched = false;
        state.byId.isFailed = true;
      })
      .addCase(tournamentIndividualCreateSave.pending, state => {
        state.save = fetchableFetching;
      })
      .addCase(tournamentIndividualCreateSave.fulfilled, (state, { payload }) => {
        state.save = fetchableFetched;

        state.byId.tournament = convertTournamentIndividualToTournamentIndividualRequest(payload);
        state.byId.loadedData = payload;
      })
      .addCase(tournamentIndividualCreateSave.rejected, state => {
        state.save = fetchableFailed;
      })
      .addCase(tournamentIndividualCreateEventAdmins.pending, state => {
        state.eventAdmins = { data: state.eventAdmins.data, ...fetchableFetching };
      })
      .addCase(tournamentIndividualCreateEventAdmins.fulfilled, (state, { payload }) => {
        state.eventAdmins = { data: payload, ...fetchableFetched };
      })
      .addCase(tournamentIndividualCreateEventAdmins.rejected, state => {
        state.eventAdmins = { data: state.eventAdmins.data, ...fetchableFailed };
      })
      .addMatcher(
        isAnyOf(tournamentIndividualCreatePublish.pending, tournamentIndividualCreatePublishAnnounce.pending),
        state => {
          state.publish = fetchableFetching;
        }
      )
      .addMatcher(
        isAnyOf(tournamentIndividualCreatePublish.fulfilled, tournamentIndividualCreatePublishAnnounce.fulfilled),
        (state, { payload }) => {
          state.publish = fetchableFetched;

          state.byId.tournament = convertTournamentIndividualToTournamentIndividualRequest(payload);
          state.byId.loadedData = payload;
        }
      )
      .addMatcher(
        isAnyOf(tournamentIndividualCreatePublish.rejected, tournamentIndividualCreatePublishAnnounce.rejected),
        state => {
          state.publish = fetchableFailed;
        }
      );
  },
});

export const {
  tournamentIndividualCreateSetAttribute,
  tournamentIndividualCreateSetModified,
  tournamentIndividualCreateClearActionsState,
  tournamentIndividualCreateStartSession,
  tournamentIndividualCreateSetUiState,
  tournamentIndividualCreateClearAttributeValidation,
  tournamentIndividualCreateSetAttributeValidation,
  tournamentIndividualCreateSetValidation,
  tournamentIndividualCreateClearAllValidations,
  tournamentIndividualCreateSetDialogState,
  tournamentIndividualCreateSetStatistics,
  tournamentIndividualCreateSetValidationStepper,
} = slice.actions;

export default slice.reducer;
