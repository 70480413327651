import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault, fetchableFailed, fetchableFetched, fetchableFetching } from '@/data/store/types';
import { Nullable, PartnerContactData, PartnerDesk, UUID } from '@/domain';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';

const emptyContact: PartnerContactData = {
  name: '',
  phone: null,
  email: null,
  feedbackLink: null,
  address: null,
};

const emptyPartnerDesk: PartnerDesk = {
  id: '',
  aboutCompany: '',
  image: null,
  contacts: [emptyContact],
  active: false,
  partnerId: '',
  partner: null,
  sortIndex: null,
};

export const partnerDeskEditByIdFetch = createAsyncThunk<PartnerDesk, { id: UUID }, AppThunkAPIConfig>(
  'partner/desk/edit/byId/fetch',
  async ({ id }, { rejectWithValue, signal }) => {
    let desk: PartnerDesk = {
      ...emptyPartnerDesk,
      partnerId: id,
    };

    try {
      const { data: deskData } = await Api.partner.desk({ partnerId: id, signal });
      desk = deskData;
    } catch (e: any) {
      if (e.response.status !== 404) {
        ErrorHandler.handleHttpError(e, e.response);

        return rejectWithValue(e.response.data);
      }
    }

    return desk;
  }
);

export const partnerDeskEditSave = createAsyncThunk<
  PartnerDesk,
  { partnerId: UUID; desk: PartnerDesk },
  AppThunkAPIConfig
>('partner/desk/edit/save', async ({ partnerId, desk }, { rejectWithValue }) => {
  try {
    const { data: savedDesk } = desk.id
      ? await Api.partner.updateDesk({ id: partnerId, data: { ...desk, partnerId } })
      : await Api.partner.createDesk({ ...desk, partnerId });
    return savedDesk;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface PartnerDeskEditState {
  readonly byId: Fetchable & {
    readonly desk: Nullable<PartnerDesk>;
    readonly loadedDesk: Nullable<PartnerDesk>;
    readonly modified: boolean;
  };
  readonly save: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<PartnerDeskEditState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PartnerDeskEditState> {
  partnerDeskEditStateReset: Reducer;
  partnerDeskEditSetDeskAttribute: Reducer<{ name: keyof PartnerDesk; value: any }>;
  partnerDeskEditSetContactAttribute: Reducer<{ index: number; name: keyof PartnerContactData; value: any }>;
  partnerDeskEditAddContact: Reducer;
  partnerDeskEditRemoveContact: Reducer<number>;
}

const slice = createSlice<PartnerDeskEditState, Reducers, 'editOwner'>({
  name: 'editOwner',
  initialState: {
    byId: {
      ...fetchableDefault,
      desk: null,
      loadedDesk: null,
      modified: false,
    },
    save: {
      ...fetchableDefault,
    },
  },
  reducers: {
    partnerDeskEditStateReset: state => {
      state.byId = {
        ...fetchableDefault,
        desk: null,
        loadedDesk: null,
        modified: false,
      };
      state.save = fetchableDefault;
    },
    partnerDeskEditSetDeskAttribute: (state, { payload }) => {
      const { name, value } = payload;

      (state.byId.desk! as any)[name] = value;
      state.byId.modified = true;
    },
    partnerDeskEditSetContactAttribute: (state, { payload }) => {
      const { index, name, value } = payload;

      const contact = state.byId.desk!.contacts![index];
      contact[name] = value;
      state.byId.modified = true;
    },
    partnerDeskEditAddContact: state => {
      if (!state.byId.desk!.contacts) state.byId.desk!.contacts = [];

      state.byId.desk!.contacts.push(emptyContact);

      state.byId.modified = true;
    },
    partnerDeskEditRemoveContact: (state, { payload }) => {
      state.byId.desk!.contacts!.splice(payload, 1);
      state.byId.modified = true;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(partnerDeskEditByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.desk = emptyPartnerDesk;
        state.byId.loadedDesk = null;
        state.byId.modified = false;
      })
      .addCase(partnerDeskEditByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.desk = payload;
        state.byId.loadedDesk = payload;
        state.byId.modified = false;
      })
      .addCase(partnerDeskEditByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;

          state.byId.desk = emptyPartnerDesk;
          state.byId.loadedDesk = null;
          state.byId.modified = false;
        }
      })
      .addCase(partnerDeskEditSave.pending, state => {
        state.save = fetchableFetching;
      })
      .addCase(partnerDeskEditSave.fulfilled, (state, { payload }) => {
        state.save = fetchableFetched;
        state.byId.desk = payload;
        state.byId.loadedDesk = payload;
        state.byId.modified = false;
      })
      .addCase(partnerDeskEditSave.rejected, state => {
        state.save = fetchableFailed;
      });
  },
});

export const {
  partnerDeskEditSetDeskAttribute,
  partnerDeskEditSetContactAttribute,
  partnerDeskEditAddContact,
  partnerDeskEditRemoveContact,
  partnerDeskEditStateReset,
} = slice.actions;

export default slice.reducer;
