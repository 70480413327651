import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/data/store/store';
import { BookingOfferShort } from '@/domain/model/booking';
import { ESortDirection } from '@/domain/model/enums';
import { Nullable } from '@/domain/model/types';
import { EBookingOfferTableColumn } from '../../types';

export const bookingOffersIsEmptySelector = (store: RootState) =>
  store.bookingOffer.list.allBookingOffers.isFetched && store.bookingOffer.list.allBookingOffers.count === 0;
export const bookingOffersIsPreloadingSelector = (store: RootState) =>
  store.bookingOffer.list.allBookingOffers.count === null;

export const bookingOffersGuidSelector = (store: RootState) => store.bookingOffer.list.guid;
export const bookingOffersBookingOffersSelector = (store: RootState) => store.bookingOffer.list.data;
export const bookingOffersPageNumberSelector = (store: RootState) => store.bookingOffer.list.pageNumber;
export const bookingOffersTotalCountSelector = (store: RootState) => store.bookingOffer.list.totalCount;
export const bookingOffersPageCountSelector = (store: RootState) => store.bookingOffer.list.pageCount;
export const bookingOffersSearchSelector = (store: RootState) => store.bookingOffer.list.search;
export const bookingOffersSortSelector = (store: RootState) => store.bookingOffer.list.search.sort;
export const bookingOffersTabsCounterSelector = (store: RootState) => store.bookingOffer.list.tabsCounter;
export const bookingOffersNeedRefreshWatcherSelector = (store: RootState) => store.bookingOffer.list.needRefreshWatcher;
export const bookingOffersUnwatchedIdsSelector = (store: RootState) => store.bookingOffer.list.unwatchedIds;
export const bookingOffersFilterSelector = (store: RootState) => store.bookingOffer.list.filter;
export const bookingIsFetchingSelector = (store: RootState) => store.bookingOffer.list.isFetching;
export const bookingOffersTabsCounterUnwatchedSelector = (store: RootState) =>
  store.bookingOffer.list.tabsCounterUnwatched;

export const bookingOffersCountNeedRefreshWatcherSelector = (store: RootState) =>
  store.bookingOffer.list.allBookingOffers.needRefreshWatcher;

export const bookingOffersSortColumnSelector = (store: RootState) =>
  (store.bookingOffer.list.search.sort?.split(',') as [EBookingOfferTableColumn, ESortDirection])?.[0] ?? '';
export const bookingOffersSortDirectionSelector = (store: RootState) =>
  (store.bookingOffer.list.search.sort?.split(',') as [EBookingOfferTableColumn, ESortDirection])?.[1] ?? '';

export const bookingOffersBookingOfferIndexSelector = (store: RootState, index: number) => index;
const createBookingOffersTableBookingOfferByIndexSelector = createSelector(
  bookingOffersBookingOffersSelector,
  bookingOffersBookingOfferIndexSelector,
  (bookingOffers, index): Nullable<BookingOfferShort> => bookingOffers?.[index] ?? null
);

const createBookingOffersTableUnwatchedByIndexSelector = createSelector(
  bookingOffersBookingOffersSelector,
  bookingOffersUnwatchedIdsSelector,
  bookingOffersBookingOfferIndexSelector,
  (bookingOffers, unwatchedIds, index): boolean => {
    const bookingOffer = bookingOffers?.[index] ?? null;
    if (!bookingOffer) {
      return false;
    } else {
      return unwatchedIds?.includes(bookingOffer.id) ?? false;
    }
  }
);

export const bookingOffersBookingOfferByIndexSelector = (index: number) => (store: RootState) =>
  createBookingOffersTableBookingOfferByIndexSelector(store, index);
export const bookingOffersUnwatchedByIndexSelector = (index: number) => (store: RootState) =>
  createBookingOffersTableUnwatchedByIndexSelector(store, index);
