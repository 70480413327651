import { Alert, Box, Grid, Typography } from '@mui/material';
import { sortBy } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import Api from '../../../../../data/api';
import { EOfferStatus } from '../../../../../domain/model/enums';
import { DataFilterQueryDslOperator } from '../../../../../domain/model/filter';
import { ProductOffer } from '../../../../../domain/model/productOffer';
import { locale } from '../../../../../locale';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';
import ImageGrid from '../../../../components/common/images/grid-dnd';
import Splitter from '../../../../components/common/splitter';
import { MPButton } from '../../../../theme/ui-kit/button';
import { AutocompleteProductVariant } from './AutocompleteProductVariant';

type DialogProductVariantTemplateString = Partial<{
  title: string;
  submitButton: string;
  closeButton: string;
}>;

type DialogProductVariantProps = {
  readonly open: boolean;
  readonly productDeskId: string;
  readonly onClose: () => void;
  readonly onSelect?: (product: ProductOffer) => void;
  readonly readonly?: boolean;
  readonly templateString?: DialogProductVariantTemplateString;
  readonly warning?: string;
  readonly closeOnSelect?: boolean;
};

const defaultTemplateString: DialogProductVariantTemplateString = {
  title: 'Созданные варианты товара',
  submitButton: 'Скопировать вариант товара',
  closeButton: 'Закрыть',
};

export const DialogProductVariant: FC<DialogProductVariantProps> = props => {
  const { open, productDeskId, readonly = false, onClose, onSelect, warning, closeOnSelect = true } = props;

  const templateString = Object.assign(defaultTemplateString, props.templateString);

  const [variant, setVariant] = useState<ProductOffer | null>(null);

  const loadOptionsProductVariant = useCallback(
    async (search?: string): Promise<ProductOffer[]> => {
      const querydsl = [
        {
          value: [productDeskId],
          operator: DataFilterQueryDslOperator.In,
          field: 'productDesk.id',
        },
        {
          value: [
            EOfferStatus.WaitingForModeration,
            EOfferStatus.OnModeration,
            EOfferStatus.Rejected,
            EOfferStatus.ReadyForSale,
            EOfferStatus.Active,
            EOfferStatus.Paused,
          ],
          operator: DataFilterQueryDslOperator.In,
          field: 'status',
        },
      ];

      if (search) {
        querydsl.push({
          value: [`%${search}%`],
          operator: DataFilterQueryDslOperator.Like,
          field: 'displayName',
        });
      }

      const response = await Api.productOffers.all({
        page: 1,
        pageSize: 20,
        querydsl,
      });

      if (readonly) {
        if (Array.isArray(response.data) && response.data.length > 0) {
          setVariant(response.data[0]);
        }
      }

      return sortBy(response.data, ['attributes[0].values[0].value']);
    },
    [productDeskId]
  );

  useEffect(() => () => setVariant(null), []);

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      dialogButtons={
        <>
          {readonly || (
            <MPButton
              fullWidth
              disabled={!variant}
              onClick={() => {
                onSelect?.(variant!);
                if (closeOnSelect) onClose();
              }}
            >
              {templateString.submitButton}
            </MPButton>
          )}
          <MPButton
            variant={'outlined'}
            fullWidth={false}
            onClick={onClose}
          >
            {templateString.closeButton}
          </MPButton>
        </>
      }
    >
      <Typography
        variant='h3'
        gutterBottom
      >
        {templateString.title}
      </Typography>

      <Splitter
        variant='horizontal'
        size={2}
      />

      <AutocompleteProductVariant
        onChange={setVariant}
        loadOptionsMethod={loadOptionsProductVariant}
        value={variant}
      />

      <Splitter
        variant='horizontal'
        size={2}
      />

      {variant && (
        <Grid container>
          <Grid
            item
            xs={6}
          >
            <Typography
              variant='body2'
              gutterBottom
              color={'secondary'}
            >
              SKU в вашей системе*
            </Typography>
            <Typography
              variant='body2'
              gutterBottom
            >
              {variant.producerSKU}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Typography
              variant='body2'
              gutterBottom
              color={'secondary'}
            >
              Артикул производителя
            </Typography>
            <Typography
              variant='body2'
              gutterBottom
            >
              {variant.partnerSKU || '—'}
            </Typography>
          </Grid>
          {variant.category?.attributes
            ?.filter(r => r.attribute.type !== 'file')
            .map(categoryAttr => {
              const valueAttr = variant?.attributes?.find(attr => attr.attribute.id === categoryAttr.attribute.id);
              return (
                <Grid
                  key={categoryAttr.attribute.id}
                  item
                  xs={6}
                >
                  <Typography
                    variant='body2'
                    gutterBottom
                    color={'secondary'}
                  >
                    {categoryAttr.attribute.name}
                  </Typography>
                  <Typography
                    variant='body2'
                    gutterBottom
                  >
                    {valueAttr?.values?.[0].value || '—'}
                  </Typography>
                </Grid>
              );
            })}

          <Grid
            item
            xs={6}
          >
            <Typography
              variant='body2'
              gutterBottom
              color={'secondary'}
            >
              Цена, {locale.currency}*
            </Typography>
            <Typography
              variant='body2'
              gutterBottom
            >
              {variant.price}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Typography
              variant='body2'
              gutterBottom
              color={'secondary'}
            >
              Цена до скидки, {locale.currency}
            </Typography>
            <Typography
              variant='body2'
              gutterBottom
            >
              {variant.originalPrice || '—'}
            </Typography>
          </Grid>

          <Grid>
            <ImageGrid
              images={variant.images}
              readOnly
            />
          </Grid>
        </Grid>
      )}

      {warning && (
        <Box sx={{ mt: 2 }}>
          <Alert
            icon={false}
            severity='warning'
          >
            {warning}
          </Alert>
        </Box>
      )}
    </ConfirmDialog>
  );
};
