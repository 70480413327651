import axios, { AxiosResponse, CancelToken } from 'axios';
import {
  Address,
  AddressId,
  CreateAddressRequest,
  CustomBuildingType,
  ManualAddressObjectType,
} from '../../domain/model/address';
import { EAddressLevel } from '../../domain/model/enums';
import { Nullable, UUID } from '../../domain/model/types';
import { createCancelToken } from './index';
import { ApiCancellable } from './types';
import { getAddressEndpoint } from './utils';

const countDefault = 20 as const;

export type AddressByQueryProps = ApiCancellable & {
  readonly query: string;
  readonly count?: number;
  readonly level?: EAddressLevel;
  readonly fromLevel?: EAddressLevel;
  readonly toLevel?: EAddressLevel;
  readonly locations?: AddressId[];
  //строгий поиск (только те которые есть в ГАР,только с координатами, нет районов города в ответе)
  readonly strict?: boolean;
  readonly cancelToken?: CancelToken;
};

export type AddressByFiasIdProps = ApiCancellable & {
  readonly fiasId: UUID;
};

export type AddressCreateProps = {
  readonly data: CreateAddressRequest;
};

export type ObjectTypesProps = ApiCancellable;
export type BuildingTypesProps = ApiCancellable;

type AddressApiType = {
  readonly addressByQuery: (props: AddressByQueryProps) => Promise<AxiosResponse<Address[]>>;
  readonly addressByFiasId: (props: AddressByFiasIdProps) => Promise<AxiosResponse<Nullable<Address>>>;
  readonly create: (props: AddressCreateProps) => Promise<AxiosResponse<Address>>;
  readonly objectTypes: (props: ObjectTypesProps) => Promise<AxiosResponse<ManualAddressObjectType[]>>;
  readonly buildingTypes: (props: BuildingTypesProps) => Promise<AxiosResponse<CustomBuildingType[]>>;
};

const AddressApi: AddressApiType = {
  addressByQuery: ({
    query,
    strict,
    count = countDefault,
    level,
    fromLevel,
    toLevel,
    locations,
    signal,
    cancelToken,
  }) => {
    const params = new URLSearchParams();
    if (query) {
      params.append('q', query);
    }
    if (strict !== undefined) {
      params.append('strict', strict.toString());
    }
    params.append('maxCount', count.toString(10));
    locations?.forEach(l => params.append('locations', l));

    if (level) {
      if (level === EAddressLevel.Area) {
        params.append('fromLevel', EAddressLevel.City.toString(10));
        params.append('toLevel', EAddressLevel.Area.toString(10));
      } else {
        params.append('fromLevel', level.toString(10));
        params.append('toLevel', level.toString(10));
      }
    } else if (fromLevel || toLevel) {
      if (fromLevel) {
        params.append('fromLevel', fromLevel.toString(10));
      }
      if (toLevel) {
        params.append('toLevel', toLevel.toString(10));
      }
    }

    return axios.get(`${getAddressEndpoint()}/addresses/suggest`, {
      params,
      cancelToken: cancelToken ?? (signal && createCancelToken(axios, signal)),
    });
  },
  addressByFiasId: ({ fiasId, signal }) => {
    return axios.get(`${getAddressEndpoint()}/addresses/${fiasId}`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  create: ({ data }) => {
    return axios.post(`${getAddressEndpoint()}/addresses`, {
      ...data,
    });
  },
  objectTypes: ({ signal }) => {
    return axios.get(`${getAddressEndpoint()}/custom-address-object-types`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  buildingTypes: ({ signal }) => {
    return axios.get(`${getAddressEndpoint()}/custom-building-types`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
};

export default AddressApi;
