import { AdCampaign, EAdCampaignType, EOfferType } from '@/domain';
import { Field, FieldsGroup } from '@components/fields';
import AdCampaignsOfferCollectionComponent from '@features/adCampaign/components/offerCollection';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { MPRubFormat } from '@ui-kit/format';

interface AdCampaignDetailsStepKeywordsProps {
  readonly adCampaign: AdCampaign;
}

const AdCampaignDetailsStepKeywords = (props: AdCampaignDetailsStepKeywordsProps) => {
  const { adCampaign } = props;

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid item>
        <Typography variant={'h3'}>Предложения в кампании</Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field>
          {adCampaign.offerType === EOfferType.Trade && (
            <AdCampaignsOfferCollectionComponent
              offers={adCampaign.offers}
              offerType={adCampaign.offerType}
            />
          )}
          {adCampaign.offerType === EOfferType.Corp && (
            <AdCampaignsOfferCollectionComponent
              offers={adCampaign.offers}
              offerType={adCampaign.offerType}
            />
          )}
          {adCampaign.offerType === EOfferType.Booking && (
            <AdCampaignsOfferCollectionComponent
              offers={adCampaign.offers}
              offerType={adCampaign.offerType}
            />
          )}
          {adCampaign.offerType === EOfferType.Product && (
            <AdCampaignsOfferCollectionComponent
              offers={adCampaign.offers}
              offerType={adCampaign.offerType}
            />
          )}
        </Field>
      </Grid>

      <Grid item>
        <FieldsGroup label={<Typography variant='h3'>Параметры продвижения</Typography>}>
          {adCampaign.type === EAdCampaignType.Search && (
            <Field label='Ключевые слова'>{adCampaign.keywords?.join(', ')}</Field>
          )}
          <Field label='Бюджет на месяц'>
            <MPRubFormat
              showRub
              value={adCampaign.budget}
            />
          </Field>
        </FieldsGroup>
      </Grid>
    </Grid>
  );
};

export default AdCampaignDetailsStepKeywords;
