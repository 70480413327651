import { useSelector } from 'react-redux';
import { OfferCategory } from '../../../../../../domain/model';
import { DictionaryTreeSelect } from '../../../../../components/common/dictionary/tree';
import { DictionaryCommonItem } from '../../../../../components/common/dictionary/tree/item';
import { DictionaryCheckboxItem } from '../../../../../components/common/dictionary/tree/item/checkbox';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  tradeOfferCategoriesSelectDataSelector,
  tradeOfferCategoriesSelectSelectedItemsSelector,
} from '../store/selectors';
import { TradeOfferCategoriesSelectAccess } from '../types';
import { DictionaryItemElementDone } from '../../../../../components/common/dictionary/tree/element/done';
import { DictionaryRadioItem } from '../../../../../components/common/dictionary/tree/item/radio';

type TradeOfferCategoriesSelectDictionaryAdapterProps = {
  readonly access: TradeOfferCategoriesSelectAccess;
  readonly maxCount?: number;
};

const levelForSelect = 1;

export const TradeOfferCategoriesSelectDictionaryAdapter = (
  props: TradeOfferCategoriesSelectDictionaryAdapterProps
) => {
  const { maxCount } = props;

  const data = useSelector(tradeOfferCategoriesSelectDataSelector);
  const selected = useSelector(tradeOfferCategoriesSelectSelectedItemsSelector);

  const handlers = useContextHandlers();

  return data ? (
    <DictionaryTreeSelect<OfferCategory>
      selected={selected}
      data={data}
      slots={{
        item: props => {
          if (props.level !== levelForSelect) {
            return (
              <DictionaryCommonItem
                {...props}
                endAdornment={props.inSelectedPath ? <DictionaryItemElementDone /> : undefined}
              />
            );
          }

          const Component = maxCount === 1 ? DictionaryRadioItem : DictionaryCheckboxItem;
          return (
            <Component
              {...props}
              selected={false}
              checked={!!props.selected}
              onChange={(item, isSelected) =>
                isSelected ? handlers.onSelect(item.data) : handlers.onUnselect(item.data)
              }
            />
          );
        },
      }}
    />
  ) : null;
};
