import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { BookingOfferShort } from '../../../../../domain/model/booking';
import { EOfferStatus } from '../../../../../domain/model/enums';
import { EOfferTableTab } from '../../../general/offer/types';
import {
  getOffersTableTabs,
  getOffersTableTabsForCounts,
  getOffersTableTabsForCountsUnwatched,
} from '../../../general/offer/utils/table';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { getBookingOfferDetailsRoute, getBookingOfferEditRoute } from '../../entry';

export type UseBookingOffersTableConfigProps = {
  readonly canCreate: boolean;
};

export type UseBookingOffersTableConfig = {
  readonly tabs: EOfferTableTab[];
  readonly tabsForCounts: EOfferTableTab[];
  readonly tabsForCountsUnwatched: EOfferTableTab[];
  readonly defaultTab: EOfferTableTab;
  readonly multiSelectAllowed: boolean;
  readonly onBookingOfferClick: (bookingOffer: BookingOfferShort) => void;
  readonly onBookingOfferOpenEdit: (bookingOffer: BookingOfferShort) => void;
};

export function useBookingOffersTableConfig(props: UseBookingOffersTableConfigProps): UseBookingOffersTableConfig {
  const { canCreate } = props;
  const history = useHistory();

  const {
    accessMatrix: { isPartnerUser },
  } = useCurrentUser();

  const onBookingOfferClick = useCallback(
    (bookingOffer: BookingOfferShort) => {
      if ([EOfferStatus.Draft, EOfferStatus.Rejected].includes(bookingOffer.status) && canCreate) {
        history.push(getBookingOfferEditRoute({ id: bookingOffer.id }));
      } else {
        history.push(getBookingOfferDetailsRoute({ id: bookingOffer.id }));
      }
    },
    [history]
  );

  const onBookingOfferOpenEdit = useCallback(
    (bookingOffer: BookingOfferShort) => {
      history.push(getBookingOfferEditRoute({ id: bookingOffer.id }));
    },
    [history]
  );

  // В букинге не может быть Upcoming
  const tabs = useMemo(
    () => getOffersTableTabs(isPartnerUser).filter(tab => tab !== EOfferTableTab.Upcoming),
    [isPartnerUser]
  );
  const tabsForCounts = useMemo(
    () => getOffersTableTabsForCounts(isPartnerUser).filter(tab => tab !== EOfferTableTab.Upcoming),
    [isPartnerUser]
  );
  const tabsForCountsUnwatched = useMemo(
    () => getOffersTableTabsForCountsUnwatched().filter(tab => tab !== EOfferTableTab.Upcoming),
    []
  );
  const multiSelectAllowed = !isPartnerUser;
  const defaultTab = isPartnerUser ? EOfferTableTab.Active : EOfferTableTab.Moderation;

  return {
    tabs,
    tabsForCounts,
    tabsForCountsUnwatched,
    multiSelectAllowed,
    defaultTab,
    onBookingOfferClick,
    onBookingOfferOpenEdit,
  };
}
