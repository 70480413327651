import { Grid } from '@mui/material';
import { SportOptionTyped } from '../../../../../../domain/model';
import { EPartnerOwnershipType, EPartnerType, ETaxationSystemType } from '../../../../../../domain/model/enums';
import { Partner } from '../../../../../../domain/model/partner';
import { MPToggleButtonsGroupSingle } from '../../../../../theme/ui-kit/button/toggle';
import { EPartnerDetailsLegalTab, PartnerDetailsLegalTab } from '../../../details/utils';
import PartnerDetailsInfoAddress from '../../detailsInfo/address';
import PartnerDetailsInfoCommon from '../../detailsInfo/common';
import PartnerDetailsInfoDocuments from '../../detailsInfo/documents';
import PartnerDetailsInfoPermissions from '../../detailsInfo/permissions';
import PartnerDetailsInfoRequisites from '../../detailsInfo/requisites';
import { Content } from './controls';

interface PartnerDetailsTabLegalProps {
  readonly partner: Partner;
  readonly partnerTypes: SportOptionTyped<EPartnerType>[];
  readonly ownerShipTypes: SportOptionTyped<EPartnerOwnershipType>[];
  readonly tab: EPartnerDetailsLegalTab;
  readonly tabs: PartnerDetailsLegalTab[];
  readonly taxSystems: SportOptionTyped<ETaxationSystemType>[];
  readonly onChangeTab: (tab: PartnerDetailsLegalTab) => void;
}

const PartnerDetailsTabLegal = (props: PartnerDetailsTabLegalProps) => {
  const { partner, partnerTypes, tab, tabs, taxSystems, ownerShipTypes, onChangeTab } = props;

  return (
    <Grid
      container
      spacing={3}
      direction='column'
    >
      <Grid item>
        <MPToggleButtonsGroupSingle
          items={tabs}
          value={tabs.find(t => t.id === tab) ?? tabs[0]}
          onChange={newTab => onChangeTab(newTab!)}
        />
      </Grid>

      <Content item>
        {tab === EPartnerDetailsLegalTab.Common &&
          <PartnerDetailsInfoCommon partner={partner} ownerShipTypes={ownerShipTypes} />}
        {tab === EPartnerDetailsLegalTab.Address && <PartnerDetailsInfoAddress partner={partner} />}
        {tab === EPartnerDetailsLegalTab.Requisites && (
          <PartnerDetailsInfoRequisites
            partner={partner}
            taxSystems={taxSystems}
          />
        )}
        {tab === EPartnerDetailsLegalTab.Documents && <PartnerDetailsInfoDocuments partner={partner} />}
        {tab === EPartnerDetailsLegalTab.Permissions && (
          <PartnerDetailsInfoPermissions
            partner={partner}
            partnerTypes={partnerTypes}
          />
        )}
      </Content>
    </Grid>
  );
};

export default PartnerDetailsTabLegal;
