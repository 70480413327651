import { BookingOfferShort } from '@/domain';
import { PaginationSize } from '@/presentation/types';
import DataTable, { DataTableMetadata, DataTableRow } from '@components/common/table';
import TablePagination from '@components/common/table/pagination';
import { EBookingOfferCollectionTableColumn } from '@features/bookingOffer/components/collection/types';
import { useMemo, useState } from 'react';
import BookingOfferTableCellCode from './cell/code';
import BookingOfferTableCellName from './cell/name';
import { RemoveIcon, Wrapper } from './controls';

interface BookingOfferCollectionTableProps {
  readonly metadata: DataTableMetadata<EBookingOfferCollectionTableColumn>;
  readonly offers: BookingOfferShort[];
  readonly onRemove?: (id: UUID) => void;
}

const minPaginationSize: Extract<PaginationSize, 5> = 5;

const BookingOfferCollectionTable = (props: BookingOfferCollectionTableProps) => {
  const { metadata, offers, onRemove } = props;

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<PaginationSize>(10);

  const pageCount = useMemo<number>(() => Math.ceil(offers.length / pageSize), [offers, pageSize]);

  const offersDisplay = useMemo<BookingOfferShort[]>(
    () => offers.slice((page - 1) * pageSize, page * pageSize),
    [offers, page, pageSize]
  );

  const rows: DataTableRow<BookingOfferShort, EBookingOfferCollectionTableColumn>[] = offersDisplay.map(offer => ({
    [EBookingOfferCollectionTableColumn.Code]: <BookingOfferTableCellCode code={offer.code} />,
    [EBookingOfferCollectionTableColumn.Name]: <BookingOfferTableCellName name={offer.name} />,
    data: offer,
  }));

  return (
    <Wrapper>
      <DataTable<BookingOfferShort, EBookingOfferCollectionTableColumn>
        metadata={metadata}
        rows={rows}
        rowActions={!!onRemove}
        getRowActions={data => (
          <RemoveIcon
            color='disabled'
            fontSize='small'
            onClick={() => onRemove?.(data.id)}
          />
        )}
      />
      {offers.length > minPaginationSize && (
        <TablePagination
          dense
          page={page}
          pageSize={pageSize}
          pageCount={pageCount}
          objectsCount={offers.length}
          objectsLabel=''
          onChangePageSize={setPageSize}
          onChangePage={setPage}
        />
      )}
    </Wrapper>
  );
};

export default BookingOfferCollectionTable;
