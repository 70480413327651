import { Typography } from '@mui/material';
import { ContentTarget } from '../../../../../../domain/model';
import { EMultiSelectorValueType } from '../../../../../../domain/model/enums';
import { ContentTargetViewComponent } from '../../types';

const attribute: keyof ContentTarget = 'roads';

const ContentTargetViewRoads: ContentTargetViewComponent = props => {
  const { target } = props;

  const names: string[] = [];

  const targetRoads = target[attribute];

  if (targetRoads) {
    if (targetRoads.select === EMultiSelectorValueType.All) {
      names.push('все дороги');
    } else {
      if (targetRoads.in?.length) {
        names.push(...targetRoads.in.map(road => `${road.name} дорога`));
      }
    }
  }

  return (
    <>
      <Typography color='textSecondary'>Железная дорога</Typography>
      <Typography>{names.join(', ') || '-'}</Typography>
    </>
  );
};

export default ContentTargetViewRoads;
