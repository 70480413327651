const rootRouting = {
  uikit: '/uikit',
  auth: '/auth',
  playgrounds: '/playgrounds',
  teams: '/teams',
  events: '/events',
  sportsmen: '/sportsmen',
  products: '/products',
  chats: '/chats',
  complaints: '/complaints',

  tradeOffers: '/trade-offers',
  corpOffers: '/corp-offers',
  bookingOffers: '/booking-offers',
  customers: '/customers',
  partners: '/partners',
  employees: '/employees',
  orders: '/orders',
  tradeActivations: '/trade-activations',
  corpActivations: '/corp-activations',
  reports: '/reports',
  banners: '/banners',
  configuration: '/configuration',
  users: '/users',
  partnerApplication: '/partner-application',
  partnerDesks: '/partner-desks',
  partnerWindow: '/partner-window',
  landingWindow: '/landing-window',
  cmsContainers: '/cms-containers',
  support: '/support',
  analytics: '/analytics',
  ads: '/ads',
  clientOrgs: '/client-orgs',
  socialPackage: '/social-packages',
};

export default rootRouting;
