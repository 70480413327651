import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { publicAppPalette } from '../../theme/palette';

export const Wrapper = styled.div`
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Content = styled.div`
  max-width: 22.125rem;
`;

export const Title = styled(Typography)`
  color: ${publicAppPalette.primary};
`;
