import { LinearProgress } from '@mui/material';
import useFileDownload from '../useFileDownload';
import { Link, ProgressWrapper, Wrapper } from './controls';

interface FileDownloadLinkProps {
  readonly src: string;
  readonly accept?: string;
  readonly fileName?: string;
  readonly component?: any;
  readonly children: any;
  readonly onDownload?: (success: boolean) => void;
}

const FileDownloadLink = ({ src, accept, fileName, component = Link, children, onDownload }: FileDownloadLinkProps) => {
  const { isDownloading, download } = useFileDownload({ src, fileName, accept, onDownload });

  return (
    <Wrapper
      component={component}
      onClick={download}
    >
      {children}
      {isDownloading && (
        <ProgressWrapper>
          <LinearProgress variant='indeterminate' />
        </ProgressWrapper>
      )}
    </Wrapper>
  );
};

export default FileDownloadLink;
