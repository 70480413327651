import TabsPanel from '@/presentation/components/common/tabs/panel';
import { useMemo } from 'react';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import AnalyticsQueriesTableTab from '../tab';
import { EAnalyticsQueriesTableTab } from '../utils';

interface AnalyticsQueriesTableTabsAdapterProps {
  readonly tab: EAnalyticsQueriesTableTab;
}

export const AnalyticsQueriesTableTabsAdapter = (props: AnalyticsQueriesTableTabsAdapterProps) => {
  const { tab } = props;

  const { tabs } = useContextConfig();
  const { onChangeTab } = useContextHandlers();

  return useMemo(
    () => (
      <TabsPanel
        tab={tab}
        tabs={tabs}
        labelAdapter={AnalyticsQueriesTableTab}
        onChangeTab={onChangeTab}
      />
    ),
    [tab, tabs, onChangeTab]
  );
};
