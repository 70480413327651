import React from 'react';
import { UseClientOrgUsersTable } from './hooks/useClientOrgUsersTable';
import { UseClientOrgUsersTableConfig } from './hooks/useClientOrgUsersTableConfig';

export type ClientOrgUsersTableContextValue = UseClientOrgUsersTable;

export const ClientOrgUsersTableContext = React.createContext<ClientOrgUsersTableContextValue>(
  {} as ClientOrgUsersTableContextValue
);

export type ClientOrgUsersTableConfigContextValue = UseClientOrgUsersTableConfig;

export const ClientOrgUsersTableConfigContext = React.createContext<ClientOrgUsersTableConfigContextValue>(
  {} as ClientOrgUsersTableConfigContextValue
);
