import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { BookingServicePriceUnit } from '../../../../../../domain/model/booking';
import { EBookingServicePriceUnitStatus } from '../../../../../../domain/model/enums';
import { DictionaryTreeEdit } from '../../../../../components/common/dictionary/tree';
import { DictionaryCommonItem, DictionaryEditItem } from '../../../../../components/common/dictionary/tree/item';
import { DictionaryAddItem } from '../../../../../components/common/dictionary/tree/item/add';
import { ContentWrapper } from '../controls';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingServicePriceUnitsEditDataSelector } from '../store/selectors';
import { BookingServicePriceUnitsEditAccess } from '../types';

const levelsLimit = 2;

type BookingServicePriceUnitsEditDictionaryAdapterProps = {
  readonly access: BookingServicePriceUnitsEditAccess;
};

export const BookingServicePriceUnitsEditDictionaryAdapter = (
  props: BookingServicePriceUnitsEditDictionaryAdapterProps
) => {
  const { access } = props;

  const data = useSelector(bookingServicePriceUnitsEditDataSelector);

  const handlers = useContextHandlers();

  return (
    <ContentWrapper>
      {data && (
        <DictionaryTreeEdit<BookingServicePriceUnit>
          data={data}
          slots={{
            item: props =>
              props.level === 0 ? (
                <DictionaryCommonItem {...props} />
              ) : (
                <DictionaryEditItem
                  {...props}
                  content={contentProps => (
                    <Typography
                      color={
                        contentProps.data.data.status === EBookingServicePriceUnitStatus.Enabled
                          ? 'textPrimary'
                          : 'textSecondary'
                      }
                    >
                      {contentProps.children}
                    </Typography>
                  )}
                  onEdit={access.canEdit ? () => handlers.onChangeDialogState('modify', props.data) : undefined}
                  onDelete={access.canDelete ? () => handlers.onChangeDialogState('delete', props.data) : undefined}
                />
              ),
            levelHeader: access.canCreate
              ? props =>
                  props.level === 1 ? (
                    <DictionaryAddItem
                      {...props}
                      fixed
                      onClick={() => handlers.onChangeDialogState('add', { parent: props.parent })}
                    >
                      Добавить единицу измерения
                    </DictionaryAddItem>
                  ) : null
              : undefined,
          }}
          hasLevel={level => level + 1 <= levelsLimit}
        />
      )}
    </ContentWrapper>
  );
};
