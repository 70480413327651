import { useMemo } from 'react';
import { Partner } from '../../../../../../domain/model/partner';
import { MpUser } from '../../../../../../domain/model/user';
import MenuPanelList from '../../../../../components/common/menuPanel/list';
import useCustomerManagementMenuItems from '../useMenuItems';

interface CustomerManagementMenuItemsAdapterProps {
  readonly customer: MpUser;
  readonly partner?: Partner;
}

export const CustomerManagementMenuItemsAdapter = ({ customer, partner }: CustomerManagementMenuItemsAdapterProps) => {
  const { items, isSelectedItem, onItemClick, isOpenedSubMenu } = useCustomerManagementMenuItems({
    customer,
    partner,
  });

  return useMemo(() => {
    return (
      <MenuPanelList
        items={items}
        onItemClick={onItemClick}
        isSelectedItem={isSelectedItem}
        isOpenedSubMenu={isOpenedSubMenu}
      />
    );
  }, [items, isSelectedItem, isOpenedSubMenu, onItemClick]);
};
