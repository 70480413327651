import { useEffect, useMemo, useState } from 'react';
import { DataFilterStrategyBase, DataFilterValueItem } from '../../domain/model/filter';
import { Nullable } from '../../domain/model/types';
import { DataTableColumns, DataTableMetadata } from '../components/common/table';
import {
  calculateDataFilterStrategiesChanges,
  getDataFilterStrategiesByColumns,
  GetDataFilterStrategiesByColumnsProps,
  hasDataFilterAnyStrategyValue,
} from '../utils/filtering';
import useDataTableSettings from './useDataTableSettings';

export interface DataFilterAdapter<C extends string = string, F extends string = string> {
  // ключ для хранения настроек
  readonly key: string;
  // дополнения ключа для хранения настроек
  readonly keyPartitions?: (string | number | null)[];
  // максимально возможный состав колонок
  readonly sourceColumns: DataTableColumns<C>;
  // элементы фильтра
  readonly filterItems: Record<C, F[]>;
  // предустановленные элементы фильтра
  readonly filterPreset?: DataFilterStrategyBase<F>[];
  // элементы фильтра видимые по умолчанию
  readonly requiredFilters?: F[];
  // функция получения стратегий
  readonly getDataFilterStrategy: GetDataFilterStrategiesByColumnsProps<C, F>['getDataFilterStrategy'];
}

export interface UseDataFilterAdapterProps<C extends string, F extends string> {
  readonly guid?: Nullable<string>;
  readonly adapter: DataFilterAdapter<C, F>;
  readonly filterValues: Partial<Record<F, DataFilterValueItem<Nullable<any>>>>;
  readonly sortColumn: Nullable<C>;
  readonly onChangeFilter?: (strategies: DataFilterStrategyBase<F>[]) => void;
  readonly onSortReset?: () => void;
}

interface UseDataFilterAdapterResult<C extends string, F extends string> {
  readonly metadata: DataTableMetadata<C>;
  readonly filterStrategies: DataFilterStrategyBase<F>[];
  readonly onChangeMetadata: (metadata: DataTableMetadata<C>) => void;
}

function useDataTableFilterAdapter<C extends string, F extends string>(
  props: UseDataFilterAdapterProps<C, F>
): UseDataFilterAdapterResult<C, F> {
  const { guid, adapter, sortColumn, filterValues, onChangeFilter, onSortReset } = props;

  const { key, sourceColumns, filterItems, filterPreset, requiredFilters, keyPartitions, getDataFilterStrategy } =
    adapter;

  const [filterStrategies, setFilterStrategies] = useState<Nullable<DataFilterStrategyBase<F>[]>>(null);
  const [prevFilterStrategies, setPrevFilterStrategies] =
    useState<Nullable<DataFilterStrategyBase<F>[]>>(filterStrategies);

  // очистка сортировки по исчезнувшей с экрана колонке
  const onColumnsRemoved = useMemo(
    () => (columns: C[]) => {
      if (onSortReset) {
        const sortChanged = sortColumn && columns.includes(sortColumn);
        if (sortChanged) {
          onSortReset();
        }
      }
    },
    [sortColumn, onSortReset]
  );

  // натягиваем readOnly свойство из пресета поверх основного getDataFilterStrategy
  const getDataFilterStrategyInternal = useMemo(
    () =>
      filterPreset?.length
        ? (
            item: F,
            values: Partial<Record<F, DataFilterValueItem<Nullable<any>>>>
          ): Nullable<DataFilterStrategyBase<F>> => {
            // получаем оригинальную стратегию
            const originalStrategy = getDataFilterStrategy(item, values);
            // получаем стратегию из пресета
            const presetStrategy = filterPreset?.find(preset => preset.key === originalStrategy?.key) ?? null;
            if (!originalStrategy) {
              return presetStrategy;
            } else {
              return {
                ...originalStrategy,
                readOnly: presetStrategy?.readOnly ?? originalStrategy.readOnly,
                viewAlways: presetStrategy?.viewAlways ?? originalStrategy.viewAlways,
                querydsl: presetStrategy?.querydsl ?? originalStrategy.querydsl,
                preview: presetStrategy ? presetStrategy.preview : originalStrategy.preview,
              };
            }
          }
        : getDataFilterStrategy,
    [filterPreset, getDataFilterStrategy]
  );

  // накатываем пресеты фильтров если они есть, тут важно учесть guid, чтобы хук умел работать с сессиями контейнеров
  useEffect(() => {
    if (guid && filterPreset?.length && filterStrategies?.length) {
      const temp = [...filterStrategies];
      let changed = false;

      filterPreset.forEach(preset => {
        const originalIndex = temp.findIndex(strategy => strategy.key === preset.key);
        if (originalIndex !== -1) {
          if (JSON.stringify(preset) !== JSON.stringify(temp[originalIndex])) {
            temp.splice(originalIndex, 1, preset);
            changed = true;
          }
        } else {
          temp.push(preset);
          changed = true;
        }
      });

      if (changed) {
        setFilterStrategies(temp);
        onChangeFilter?.(temp);
      }
    }
  }, [guid, filterStrategies]);

  // получаем метаданные на основе максимально возможного состава колонок и сохраненного для пользователя состава
  const { metadata, onChangeMetadata } = useDataTableSettings<C>({
    name: key,
    nameParts: keyPartitions,
    sourceColumns,
    onColumnsRemoved,
  });

  // применяем новые стратегии на базе метаданных
  useEffect(() => {
    setFilterStrategies(
      getDataFilterStrategiesByColumns<C, F>({
        columns: metadata.columns,
        filterValues,
        filters: filterItems,
        requiredFilters,
        getDataFilterStrategy: getDataFilterStrategyInternal,
      })
    );
  }, [metadata.columns, filterValues, filterItems, requiredFilters, getDataFilterStrategyInternal]);

  // меняем фильтр при изменении состава стратегий
  useEffect(() => {
    const { removedStrategies } = calculateDataFilterStrategiesChanges(prevFilterStrategies, filterStrategies);
    const removedFilterKeys = removedStrategies.map(strategy => strategy.key);

    // меняем значения фильтров
    if (removedStrategies.length > 0) {
      if (onChangeFilter) {
        const filterChanged = hasDataFilterAnyStrategyValue({
          filterValues,
          keys: removedFilterKeys,
        });
        if (filterChanged) {
          onChangeFilter(filterStrategies ?? []);
        }
      }
    }
  }, [prevFilterStrategies, filterStrategies]);

  // кэшируем текущие стратегии для дальнейшего сравнения
  useEffect(() => {
    setPrevFilterStrategies(filterStrategies);
  }, [filterStrategies]);

  return {
    metadata,
    filterStrategies: filterStrategies ?? [],
    onChangeMetadata,
  };
}

export default useDataTableFilterAdapter;
