import { Grid, Switch, Typography } from '@mui/material';
import { GlobalNotificationOption } from '../../../../../domain/model/notification';
import { Nullable, UUID } from '../../../../../domain/model/types';
import SwitchControl from '../../../../components/common/switch';

interface ConfigurationNotificationsProps {
  readonly notifications: GlobalNotificationOption[];
  readonly onChange: Nullable<(id: UUID, enabled: boolean) => void>;
}

const ConfigurationNotifications = (props: ConfigurationNotificationsProps) => {
  const { notifications, onChange } = props;

  return (
    <Grid
      container
      spacing={2}
    >
      {notifications.length > 0 ? (
        <>
          {notifications.map(notification => {
            const { id, name, enabled } = notification;

            return (
              <Grid
                key={id}
                item
                xs={12}
              >
                <SwitchControl
                  control={<Switch color='primary' />}
                  label={<Typography color='textPrimary'>{name}</Typography>}
                  name={id}
                  labelPlacement='start'
                  disabled={!onChange}
                  checked={enabled}
                  onChange={onChange ? (event, checked) => onChange(id, checked) : () => null}
                />
              </Grid>
            );
          })}
        </>
      ) : (
        <Grid
          item
          xs={12}
        >
          <Typography>Настройки уведомлений отсутствуют</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ConfigurationNotifications;
