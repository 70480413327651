import { EPartnerStatus } from '../../../../domain/model/enums';
import { PartnerShort, PartnerShortAny, PartnerShortDraft } from '../../../../domain/model/partner';
import { UUID } from '../../../../domain/model/types';
import { DataTableColumns, ETableColumnAlign } from '../../../components/common/table';
import { TableActionProcess } from '../../../components/common/table/types';
import {
  EPartnerActionType,
  EPartnerTableColumn,
  EPartnerTableTab,
  GetPartnerActionsByPermissions,
  PartnerActions,
  PartnerActionTableType,
} from '../../general/partner/types';
import { getPartnerActionsConfigByPermissions } from '../utils';

export type PartnerTableActionProcess = TableActionProcess<PartnerActionTableType>;

export enum EPartnersTableUrlParam {
  Tab = 'tab',
}

export type PartnerTableTabsCounter = {
  [name in EPartnerTableTab]?: number;
};

export const partnersTableColumns: DataTableColumns<EPartnerTableColumn> = {
  [EPartnerTableColumn.Date]: {
    title: 'Дата',
    width: '10rem',
    sortable: true,
  },
  [EPartnerTableColumn.Name]: {
    title: 'Партнёр',
    width: '20rem',
    wrap: true,
    sortable: true,
  },
  [EPartnerTableColumn.OwnerName]: {
    title: 'Ответственное лицо',
    width: '16rem',
    wrap: true,
    sortable: true,
  },
  [EPartnerTableColumn.OwnerPhone]: {
    title: 'Телефон',
    width: '8rem',
    wrap: true,
  },
  [EPartnerTableColumn.OwnerEmail]: {
    title: 'Электронная почта',
    width: '12rem',
    wrap: true,
    sortable: true,
  },
  [EPartnerTableColumn.Reviewer]: {
    title: 'Ответственный',
    width: '12rem',
    wrap: true,
  },
  [EPartnerTableColumn.Actions]: {
    align: ETableColumnAlign.Center,
    width: '3rem',
  },
  [EPartnerTableColumn.Inn]: {
    title: 'ИНН',
    width: '6rem',
    sortable: true,
  },
  [EPartnerTableColumn.Kpp]: {
    title: 'КПП',
    width: '6rem',
    sortable: true,
  },
  [EPartnerTableColumn.Ogrn]: {
    title: 'ОГРН',
    width: '8rem',
    sortable: true,
  },
};

export const getPartnerTableColumns = (tab: EPartnerTableTab): DataTableColumns<EPartnerTableColumn> => {
  const defaultColumns = getPartnerTableDefaultColumns(tab);

  const allColumns: EPartnerTableColumn[] = [];
  allColumns.push(
    EPartnerTableColumn.Date,
    EPartnerTableColumn.Name,
    EPartnerTableColumn.OwnerName,
    EPartnerTableColumn.OwnerPhone,
    EPartnerTableColumn.OwnerEmail,
    EPartnerTableColumn.Reviewer,
    EPartnerTableColumn.Inn,
    EPartnerTableColumn.Kpp,
    EPartnerTableColumn.Ogrn
  );

  return allColumns.reduce<DataTableColumns<EPartnerTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...partnersTableColumns[column],
        hidden: !defaultColumns.includes(column),
      },
    };
  }, {});
};

export const getPartnerTableStatusesByTab = (t: EPartnerTableTab): EPartnerStatus[] => {
  switch (t) {
    case EPartnerTableTab.Verification:
      return [EPartnerStatus.OnVerification, EPartnerStatus.ToVerification];
    case EPartnerTableTab.Active:
      return [EPartnerStatus.Enabled];
    case EPartnerTableTab.Deactivated:
      return [EPartnerStatus.Disabled];
    case EPartnerTableTab.Rejected:
      return [EPartnerStatus.Rejected];
    case EPartnerTableTab.Unverified:
      return [EPartnerStatus.Unverified];
  }
};

export const getIsPartnerDisabled = (partner: PartnerShortAny, userId: UUID): boolean =>
  (partner.status === EPartnerStatus.OnVerification &&
    !!partner?.partnerReviewer?.id &&
    partner.partnerReviewer.id !== userId) ??
  false;

const partnerTableDefaultColumns = [
  EPartnerTableColumn.Date,
  EPartnerTableColumn.Name,
  EPartnerTableColumn.OwnerName,
  EPartnerTableColumn.OwnerPhone,
  EPartnerTableColumn.OwnerEmail,
  EPartnerTableColumn.Reviewer,
  EPartnerTableColumn.Inn,
  EPartnerTableColumn.Kpp,
  EPartnerTableColumn.Ogrn,
];

const getPartnerTableDefaultColumns = (tab: EPartnerTableTab): EPartnerTableColumn[] => {
  return partnerTableDefaultColumns;
};

// фильтрация действий для таблицы партнеров по всем действиям
const filterPartnerTableActions = (
  actions: PartnerActions<EPartnerActionType>
): PartnerActions<PartnerActionTableType> => {
  const tableActions: PartnerActions<PartnerActionTableType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EPartnerActionType.Activate:
        tableActions.push({
          type: action.type,
          disabled: action.disabled,
          label: 'Активировать',
          position: action.position,
        });
        break;
      case EPartnerActionType.Deactivate:
        tableActions.push({
          type: action.type,
          disabled: action.disabled,
          label: 'Приостановить',
          position: action.position,
        });
        break;
      case EPartnerActionType.Delete:
      case EPartnerActionType.ReturnToVerification:
        tableActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          position: action.position,
        });
        break;
      case EPartnerActionType.Edit:
      case EPartnerActionType.EditDesk:
      case EPartnerActionType.Save:
      case EPartnerActionType.ChangeSortIndex:
        break;
    }
  });

  return tableActions;
};

// получение возможных действий с покупателем для вкладки таблицы
const getPartnerTableTabActionsConfig = (
  tab: EPartnerTableTab,
  actions: PartnerActions<PartnerActionTableType>
): PartnerActions<PartnerActionTableType> => {
  switch (tab) {
    case EPartnerTableTab.Active:
      return actions.filter(item => [EPartnerActionType.Deactivate].includes(item.type));
    case EPartnerTableTab.Deactivated:
      return actions.filter(item => [EPartnerActionType.Activate].includes(item.type));
    case EPartnerTableTab.Rejected:
      return actions.filter(item => [EPartnerActionType.ReturnToVerification].includes(item.type));
    case EPartnerTableTab.Unverified:
      return actions.filter(item => [EPartnerActionType.Delete].includes(item.type));
    case EPartnerTableTab.Verification:
      return [];
  }
};

export const getPartnerTableTabActions = (
  permissions: GetPartnerActionsByPermissions,
  tab: EPartnerTableTab
): PartnerActions<PartnerActionTableType> => {
  const allowedActions = getPartnerActionsConfigByPermissions(permissions);
  const actions = filterPartnerTableActions(allowedActions);

  return getPartnerTableTabActionsConfig(tab, actions);
};

export type PartnerActionTableCall = {
  readonly action: PartnerActionTableType;
  readonly partner: PartnerShort | PartnerShortDraft;
};

export const getPartnerTableTabName = (tab: EPartnerTableTab): string => {
  switch (tab) {
    case EPartnerTableTab.Verification:
      return 'На проверку';
    case EPartnerTableTab.Active:
      return 'Активные';
    case EPartnerTableTab.Deactivated:
      return 'Приостановленные';
    case EPartnerTableTab.Rejected:
      return 'Отклонённые';
    case EPartnerTableTab.Unverified:
      return 'Черновики';
  }
};
