import { ETeamMemberInviteStatus } from '../../../../domain/model/enums';
import { TeamMemberInvite } from '../../../../domain/model/team';

export enum ETeamTab {
  General = 'general',
  Events = 'events',
  Members = 'members',
  Invites = 'invites',
}

export const teamMediaSize = '9.375rem';
export const getTeamDetailsTitle = () => 'Команда';

export const getFilteredInvites = (invites: TeamMemberInvite[], status: ETeamMemberInviteStatus) => {
  return invites.filter(invite => invite.status === status);
};
