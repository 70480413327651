import BookingOfferTableCell, { BookingOfferTableCellCommonProps } from './index';

const BookingOfferTableCellCategory = (props: BookingOfferTableCellCommonProps) => {
  const { bookingOffer: offer } = props;
  const value = offer.category?.name || '-';

  return (
    <BookingOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default BookingOfferTableCellCategory;
