import { FormControlLabel, Typography } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox } from './controls';

type CheckBoxContentProps = {
  readonly label: string;
  readonly checked: boolean;
};

const CheckBoxContent = (props: CheckBoxContentProps) => {
  const { label, checked } = props;
  return (
    <FormControlLabel
      control={
        <Checkbox
          color='primary'
          disabled
          icon={checked ? <CheckBoxIcon color={'primary'} /> : <CheckBoxOutlineBlankIcon color={'primary'} />}
        />
      }
      label={<Typography color='textPrimary'>{label}</Typography>}
    />
  );
};

export default CheckBoxContent;
