import { DataFilterStrategyCityMultipleAddress } from '../../../../../../domain/model/filter';
import { Nullable } from '../../../../../../domain/model/types';
import { DataFilterEditItemProps } from '../index';
import { AddressCityMultipleSelector } from '../../../../../features/address';

type ValueType<T extends string> = DataFilterStrategyCityMultipleAddress<T>['value'];

interface DataFilterEditItemCityMultipleAddressProps<T extends string>
  extends DataFilterEditItemProps<T, Nullable<ValueType<T>>> {
  readonly strategy: DataFilterStrategyCityMultipleAddress<T>;
}

function DataFilterEditItemCityMultipleAddress<T extends string>({
  strategy,
  onChange,
}: DataFilterEditItemCityMultipleAddressProps<T>) {
  const { label, value, readOnly } = strategy;

  return (
    <AddressCityMultipleSelector
      value={value}
      label={label}
      color='primary'
      disabled={readOnly}
      onChange={addresses => onChange(strategy, addresses)}
    />
  );
}

export default DataFilterEditItemCityMultipleAddress;
