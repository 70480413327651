import { Link, Typography } from '@mui/material';
import SectionTitle from '../../../../components/common/sectionTitle';
import Splitter from '../../../../components/common/splitter';
import { Wrapper } from './controls';

interface SupportInfoPanelProps {
  readonly emails: string[];
  readonly phones: string[];
}

const SupportInfoPanel = ({ emails, phones }: SupportInfoPanelProps) => {
  return (
    <Wrapper>
      <SectionTitle>Поддержка</SectionTitle>
      <Typography
        variant='body2'
        gutterBottom
      >
        Если у Вас возникли вопросы, обращайтесь в службу поддержки:
      </Typography>
      {phones.map(item => (
        <Typography
          key={item}
          variant='body2'
        >
          {item}
        </Typography>
      ))}
      {emails.length > 0 && (
        <Splitter
          variant='horizontal'
          size={1}
        />
      )}
      {emails.map(item => (
        <Typography
          key={item}
          component={Link}
          href={`mailto:${item}`}
          variant='body2'
          color='primary'
        >
          {item}
        </Typography>
      ))}
    </Wrapper>
  );
};

export default SupportInfoPanel;
