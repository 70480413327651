import { CardTitle, LogoStub, TemplateBorder, TemplateWrapper } from './controls';

const CmsPartnersPreviewBlockTemplate = () => {
  return (
    <TemplateWrapper>
      <TemplateBorder />
      <LogoStub
        animation={false}
        variant='circular'
      />
      <CardTitle>Партнёр</CardTitle>
    </TemplateWrapper>
  );
};

export default CmsPartnersPreviewBlockTemplate;
