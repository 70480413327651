import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getTradeOfferActionsOfferIsProcessingSelector, tradeOfferActionsGetActionSelector } from '../store/selectors';
import { useTradeOfferActions } from '../useActions';
import { SportOption, TradeOffer } from '@/domain';
import OfferReasonsDialog from '@features/general/offer/components/reasonsDialog';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import { TradeOfferStateKeyDialogs } from '../store/slice';

const nameDialog: TradeOfferStateKeyDialogs = 'reject';

const TradeOfferActionsDialogRejectAdapter = () => {
  const handlers = useTradeOfferActions();
  const { data, notification } = useSelector(tradeOfferActionsGetActionSelector(nameDialog));
  const { offerRejectReasons: rejectReasons } = useSelector(nsiDataSelector);
  const isProcessing = useSelector(getTradeOfferActionsOfferIsProcessingSelector(data?.id ?? null));

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onReject = useCallback(
    (offer: TradeOffer, reason: SportOption, comment?: string) => {
      handlers.onReject(offer, reason, comment, notification).then(() => {
        onCloseDialog();
      });
    },
    [handlers.onReject, notification]
  );

  return (
    data && (
      <OfferReasonsDialog
        offer={data}
        title='Отклонение торгового предложения'
        actionText='Отклонить'
        isFetching={isProcessing}
        reasons={rejectReasons}
        onAction={onReject}
        onClose={onCloseDialog}
      />
    )
  );
};

export default TradeOfferActionsDialogRejectAdapter;
