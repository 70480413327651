import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Feedback } from '../../../feedback';
import { useFeedback } from '../../../feedback/useFeedback';
import BookingOrderAddItemDialog from '../../components/addItemDIalog';
import { BookingOrderItemView } from '../../types';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  bookingOrderEditDialogsSelector,
  bookingOrderEditOfferSelector,
  bookingOrderEditOrderSelector,
} from '../store/selectors';

export const BookingOrderEditDialogsAdapter = () => {
  const handlers = useContextHandlers();
  const { onSubmitFeedback } = useFeedback();

  const dialogs = useSelector(bookingOrderEditDialogsSelector);
  const order = useSelector(bookingOrderEditOrderSelector);
  const offer = useSelector(bookingOrderEditOfferSelector);

  const onHideFeedback = useCallback(() => {
    handlers.onChangeDialogState('feedback', null);
  }, [handlers.onChangeDialogState]);

  const onHideAddService = useCallback(() => {
    handlers.onChangeDialogState('addItem', null);
  }, [handlers.onChangeDialogState]);

  const onAddOrderItem = useCallback(
    (orderItem: BookingOrderItemView) => {
      handlers.onAddOrderItem(orderItem);
      handlers.onChangeDialogState('addItem', null);
    },
    [handlers.onChangeDialogState, handlers.onAddOrderItem]
  );

  if (!order) {
    return null;
  }

  return (
    <>
      {dialogs.feedback && (
        <Feedback
          open
          onClose={onHideFeedback}
          onSubmit={onSubmitFeedback}
        />
      )}
      {dialogs.addItem && (
        <BookingOrderAddItemDialog
          services={offer?.services ?? []}
          onExecute={onAddOrderItem}
          onClose={onHideAddService}
        />
      )}
    </>
  );
};
