import { EAdCampaignType, SportOptionTyped } from '@/domain';
import { Typography } from '@mui/material';
import { MPMenuItem } from '@ui-kit/menu/item';
import { MPSelect } from '@ui-kit/select';
import { AdCampaignCreateFieldCommonProps } from '../../types';

const fieldName = 'type';

interface AdCampaignCreateFieldTypeProps extends AdCampaignCreateFieldCommonProps<typeof fieldName> {
  readonly types: SportOptionTyped<EAdCampaignType>[];
}

const AdCampaignCreateFieldType = (props: AdCampaignCreateFieldTypeProps) => {
  const { value, types, validation, disabled, onChangeAttribute, onValidateAttribute } = props;

  const onChange = (newValue: SportOptionTyped<EAdCampaignType>) => {
    onChangeAttribute(fieldName, newValue.id);
    onValidateAttribute(fieldName);
  };

  return (
    <MPSelect<SportOptionTyped<EAdCampaignType>>
      label='Тип рекламной кампании'
      disabled={disabled}
      value={types.find(item => item.id === value)}
      error={validation?.hasError}
      helperText={validation?.message}
      onChange={onChange}
    >
      {types.map(item => (
        <MPMenuItem
          key={item.id}
          value={item}
        >
          <Typography variant='body1'>{item.name}</Typography>
        </MPMenuItem>
      ))}
    </MPSelect>
  );
};

export default AdCampaignCreateFieldType;
