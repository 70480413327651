import { useCallback } from 'react';
import { BookingOfferShort } from '@/domain/model/booking';
import { ESortDirection } from '@/domain/model/enums';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../components/common/table';
import TableActionsContainer from '../../../components/common/table/actions/container';
import { DataTableLoader } from '../../../components/common/table/loader';
import { OfferActions } from '../../general/offer/types';
import { useBookingOfferActionsSelectors } from '../actions/useActions';
import BookingOfferTableCellAddress from '../components/tableCell/address';
import BookingOfferTableCellCategory from '../components/tableCell/category';
import BookingOfferTableCellCode from '../components/tableCell/code';
import BookingOfferTableCellDate from '../components/tableCell/date';
import BookingOfferTableCellImage from '../components/tableCell/image';
import BookingOfferTableCellModerator from '../components/tableCell/moderator';
import BookingOfferTableCellName from '../components/tableCell/name';
import BookingOfferTableCellPartner from '../components/tableCell/partner';
import BookingOfferTableCellPartnerType from '../components/tableCell/partnerType';
import BookingOfferTableCellResponsible from '../components/tableCell/responsible';
import BookingOfferTableCellResponsibleEmail from '../components/tableCell/responsibleEmail';
import BookingOfferTableCellResponsiblePhone from '../components/tableCell/responsiblePhone';
import { BookingActionTableType, EBookingOfferTableColumn } from '../types';
import BookingOffersTableCellAdapter from './adapters/cell';
import { bookingIsFetchingSelector } from './store/selectors';
import { BookingOfferActionCall } from './utils';
import BookingOfferTableCelRzdSocialPackage from '../components/tableCell/rzdSocialPackage';

interface BookingOfferTableProps {
  readonly metadata: DataTableMetadata<EBookingOfferTableColumn>;
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly bookingOffers: BookingOfferShort[];
  readonly getActions: (bookingOffer: BookingOfferShort) => OfferActions<BookingActionTableType>;
  readonly getCommonActions: (bookingOffer: BookingOfferShort) => OfferActions<BookingActionTableType>;
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onBookingOfferClick?: (bookingOffer: BookingOfferShort) => void;
  readonly onChangeMetadata?: (metadata: DataTableMetadata<EBookingOfferTableColumn>) => void;
  readonly onBookingOfferAction: (call: BookingOfferActionCall) => void;
}

export const BookingOfferTable = (props: BookingOfferTableProps) => {
  const {
    bookingOffers,
    sort,
    onBookingOfferClick,
    metadata,
    onChangeMetadata,
    onRequestSort,
    getCommonActions,
    onBookingOfferAction,
  } = props;

  const { getIsOfferProcessingSelector } = useBookingOfferActionsSelectors();

  const rows: DataTableRow<BookingOfferShort, EBookingOfferTableColumn>[] = bookingOffers?.map(
    (bookingOffer, index) => {
      return {
        [EBookingOfferTableColumn.Image]: (
          <BookingOffersTableCellAdapter
            index={index}
            component={BookingOfferTableCellImage}
          />
        ),
        [EBookingOfferTableColumn.Date]: (
          <BookingOffersTableCellAdapter
            index={index}
            component={BookingOfferTableCellDate}
          />
        ),
        [EBookingOfferTableColumn.Code]: (
          <BookingOffersTableCellAdapter
            index={index}
            component={BookingOfferTableCellCode}
          />
        ),
        [EBookingOfferTableColumn.Name]: (
          <BookingOffersTableCellAdapter
            index={index}
            component={BookingOfferTableCellName}
          />
        ),
        [EBookingOfferTableColumn.Address]: (
          <BookingOffersTableCellAdapter
            index={index}
            component={BookingOfferTableCellAddress}
          />
        ),
        [EBookingOfferTableColumn.Partner]: (
          <BookingOffersTableCellAdapter
            index={index}
            component={BookingOfferTableCellPartner}
          />
        ),
        [EBookingOfferTableColumn.PartnerType]: (
          <BookingOffersTableCellAdapter
            index={index}
            component={BookingOfferTableCellPartnerType}
          />
        ),
        [EBookingOfferTableColumn.Category]: (
          <BookingOffersTableCellAdapter
            index={index}
            component={BookingOfferTableCellCategory}
          />
        ),
        [EBookingOfferTableColumn.ResponsibleFullName]: (
          <BookingOffersTableCellAdapter
            index={index}
            component={BookingOfferTableCellResponsible}
          />
        ),
        [EBookingOfferTableColumn.ResponsiblePhone]: (
          <BookingOffersTableCellAdapter
            index={index}
            component={BookingOfferTableCellResponsiblePhone}
          />
        ),
        [EBookingOfferTableColumn.ResponsibleEmail]: (
          <BookingOffersTableCellAdapter
            index={index}
            component={BookingOfferTableCellResponsibleEmail}
          />
        ),
        [EBookingOfferTableColumn.RzdSocialPackage]: (
          <BookingOffersTableCellAdapter
            index={index}
            component={BookingOfferTableCelRzdSocialPackage}
          />
        ),
        [EBookingOfferTableColumn.Moderator]: (
          <BookingOffersTableCellAdapter
            index={index}
            component={BookingOfferTableCellModerator}
          />
        ),
        data: bookingOffer,
      };
    }
  );

  const onTableCommonAction = useCallback(
    (action: BookingActionTableType, bookingOffer: BookingOfferShort) =>
      onBookingOfferAction({
        action,
        bookingOffer,
      }),
    [onBookingOfferAction]
  );

  return (
    <DataTable<BookingOfferShort, EBookingOfferTableColumn>
      width='auto'
      overflowX='inherit'
      metadata={metadata}
      onChangeMetadata={onChangeMetadata}
      rowActions
      rows={rows}
      sort={{ column: sort.column, direction: sort.direction }}
      loader={<DataTableLoader selector={bookingIsFetchingSelector} />}
      onRowClick={(event, cell, { data }) => onBookingOfferClick?.(data)}
      onSort={(event, column, direction) => onRequestSort(column, direction)}
      getRowActions={bookingOffer => (
        <TableActionsContainer<BookingOfferShort, BookingActionTableType>
          entityLink={bookingOffer}
          actions={getCommonActions(bookingOffer)}
          onExecute={onTableCommonAction}
          isFetchingSelector={getIsOfferProcessingSelector(bookingOffer.id)}
        />
      )}
    />
  );
};
