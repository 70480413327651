import moment from 'moment';
import { EDateFormat } from '../../../../../domain/model/formats';
import PartnerTableCell, { PartnerTableCellCommonProps } from './index';

const PartnerTableCellLastStatusDate = (props: PartnerTableCellCommonProps) => {
  const {
    partner: { lastStatusDate },
  } = props;

  const value = lastStatusDate ? moment(lastStatusDate).format(EDateFormat.DisplayDefault) : '-';

  return (
    <PartnerTableCell
      {...props}
      value={value}
    />
  );
};

export default PartnerTableCellLastStatusDate;