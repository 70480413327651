import { ReactNode, useCallback, useEffect, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { Nullable } from '../../../../../../domain/model/types';
import { createHTMLImageElementByFile } from '../../../../../utils/images';
import { executePromisesSequentially } from '../../../../../utils/promise';
import MPImageCropPreview from '../preview';
import { Crop, Image, Preview, Wrapper } from './controls';
import { MPImagePixelCropEnhancer } from './types';
import useImagePixelCrop from './usePixelCrop';

interface MPImagePixelCropProps {
  readonly source: File;
  readonly minWidth?: number;
  readonly maxWidth?: number;
  readonly minHeight?: number;
  readonly maxHeight?: number;
  readonly outFileName?: string;
  readonly quality?: number;
  readonly enhancers?: MPImagePixelCropEnhancer[];
  readonly children?: ((cropped: boolean, size: Nullable<number>, onApplyCrop: () => void) => ReactNode) | ReactNode;
  readonly onChange: (url: Nullable<File>) => void;
}

interface EnhancerParams {
  readonly image: HTMLImageElement;
  readonly url: string;
}

const MPImagePixelCrop = (props: MPImagePixelCropProps) => {
  const {
    source,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
    outFileName,
    quality = 1.0,
    children,
    enhancers,
    onChange
  } = props;

  const [imageSrc, setImageSrc] = useState<Nullable<string>>(null);
  const [qualityInternal, setQualityInternal] = useState<number>(() => quality);

  const width = maxWidth ?? minWidth ?? 0;
  const height = maxHeight ?? minHeight ?? 0;
  const { previewRef, imgRef, crop, cropped, resultFile, setCrop, onApply } = useImagePixelCrop({
    source,
    quality: qualityInternal,
    width,
    height
  });

  const onApplyInternal = useCallback(() => onApply(onChange, outFileName), [outFileName, onApply, onChange]);

  useEffect(() => {
    if (enhancers?.length) {
      createHTMLImageElementByFile(source).then(async image => {
        const result = await executePromisesSequentially<EnhancerParams>(
          { image, url: image.src },
          enhancers.map(
            enhancer => prev =>
              new Promise(resolve => {
                const url = enhancer({ file: source, element: prev.image, width, height });
                resolve({ image, url });
              })
          )
        );
        setImageSrc(result.url);
      });
    }
  }, [source, width, height, enhancers]);

  if (!imageSrc) {
    return null;
  }

  return (
    <>
      {resultFile && (
        <MPImageCropPreview size={resultFile?.size} quality={qualityInternal} onChangeQuality={setQualityInternal}>
          <img src={resultFile ? URL.createObjectURL(resultFile) : ''} />
        </MPImageCropPreview>
      )}
      <Wrapper>
        <Preview ref={previewRef} />
        <Crop
          crop={crop}
          keepSelection={false}
          locked
          minWidth={minWidth}
          maxWidth={maxWidth}
          minHeight={minHeight}
          maxHeight={maxHeight}
          onChange={setCrop}
        >
          <Image
            ref={imgRef}
            src={imageSrc}
            alt={''}
          />
        </Crop>
      </Wrapper>
      {typeof children === 'function' ? children?.(cropped, resultFile?.size ?? null, onApplyInternal) : children}
    </>
  );
};

export default MPImagePixelCrop;
