import { AdCampaign, ENoticeStatus } from '@/domain';
import Notifier from '../../../../system/notifier';
import { EAdCampaignActionType, EAdCampaignTableTab } from '../types';

export const showAdCampaignSaveErrorsNotification = (offer: AdCampaign, errors: string[]) => {
  Notifier.getInstance().addNotice(ENoticeStatus.Warning, errors.join('\n'));
};

export const showAdCampaignActionNotification = (
  campaign: Nullable<AdCampaign>,
  action: EAdCampaignActionType,
  isCreate: boolean
) => {
  let text;

  if (isCreate) {
    text = 'Рекламная кампания создана и опубликована';
  } else {
    switch (action) {
      case EAdCampaignActionType.Edit:
        text = 'Рекламная кампания была опубликована';
        break;
      case EAdCampaignActionType.Archive:
        text = 'Рекламная кампания отправлена в архив';
        break;
      case EAdCampaignActionType.Pause:
        text = 'Рекламная кампания приостановлена';
        break;
      case EAdCampaignActionType.Resume:
        text = 'Рекламная кампания опубликована';
        break;
    }
  }

  Notifier.getInstance().addNotice(ENoticeStatus.Success, text);
};

/**
 * Получаем таб таблицы на который нужно перейти после выполнения экшена
 */
export const getAdCampaignTableTabByNameAction = (
  action: Nullable<EAdCampaignActionType>
): Nullable<EAdCampaignTableTab> => {
  if (action) {
    switch (action) {
      case EAdCampaignActionType.Resume:
      case EAdCampaignActionType.Edit:
        return EAdCampaignTableTab.Active;
      case EAdCampaignActionType.Pause:
        return EAdCampaignTableTab.Paused;
      case EAdCampaignActionType.Archive:
        return EAdCampaignTableTab.Archived;
    }
  }
  return null;
};
