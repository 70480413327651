import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Banner } from '../../../domain/model/banner';
import { EBannerPlace, EBannerStatus } from '../../../domain/model/enums';
import { UUID } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import BannerByIdContainer from '../../features/banner/byId/container';
import BannerEditContainer from '../../features/banner/edit/container';
import { getBannerDetailsRoute } from '../../features/banner/entry';
import { getBannerPartitionByPlaces } from '../../features/banner/utils';
import ObjectLockContainer from '../../features/lock/container';
import { ELockObjectType } from '../../features/lock/useLockObject';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';
import useHistoryExtensions from '../../hooks/useHistoryExtensions';

const BannerEditScreen = () => {
  const dispatch = useDispatch();
  const { gotoPrevIndependentLocation } = useHistoryExtensions();
  const { banners } = useCurrentUser().accessMatrix;

  const { id } = useParams<{ id: UUID }>();

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  const canEdit = (banner: Banner) => {
    const partition = getBannerPartitionByPlaces(banner.places ?? []);
    return banners[partition].edit;
  };

  const onReject = () => {
    gotoPrevIndependentLocation(getBannerDetailsRoute({ id }));
  };

  return (
    <BannerByIdContainer id={id}>
      {({ banner, onRestore }) => {
        if (!canEdit(banner)) {
          return <ForbiddenComponent />;
        }

        const component = (
          <BannerEditContainer
            partition={getBannerPartitionByPlaces(banner.places ?? [], EBannerPlace.TradeOffersListPageTop)}
            source={banner}
            onRestore={onRestore}
          />
        );

        if (banner.status === EBannerStatus.Draft) {
          return component;
        }

        return (
          <ObjectLockContainer
            id={id}
            lockedBy={banner.lock?.lockedBy ?? null}
            lockObjectType={ELockObjectType.Banner}
            onReject={onReject}
          >
            {component}
          </ObjectLockContainer>
        );
      }}
    </BannerByIdContainer>
  );
};

export default BannerEditScreen;
