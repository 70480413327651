import { BookingOrder, Nullable, UserAccessPartitionBookingOrders, UUID } from '@/domain';
import TableCommonLayout from '../../../layouts/tableCommon/container';
import { BookingOrderLifeCycle } from '../lifecycle/types';
import { EBookingOrderTableTab } from '../types';
import { BookingOrderTableBehaviorAdapter } from './adapters/behavior';
import BookingOrderTableFilterAdapter from './adapters/filters';
import BookingOrderTableHeaderAdapter from './adapters/header';
import BookingOrderTableTableAdapter from './adapters/table';
import { BookingOrderTableTabsAdapter } from './adapters/tabs';
import { BookingOrderTableContext } from './context';
import BookingOrderTableFooterContainer from './footerContainer';
import { useBookingOrderTableHandlers } from './hooks/useTable';
import { getBookingOrderTableTabActions } from './utils';

export type BookingOrderTableContainerProps = {
  readonly guid: UUID;
  readonly partnerId: Nullable<UUID>;
  readonly customerId: Nullable<UUID>;
  readonly tab: EBookingOrderTableTab;
  readonly lifecycle: BookingOrderLifeCycle;
  readonly onOrderClick: (order: BookingOrder) => void;
  readonly access: UserAccessPartitionBookingOrders;
};

const BookingOrderTableContainer = (props: BookingOrderTableContainerProps) => {
  const { guid, tab, partnerId, lifecycle, access, customerId, onOrderClick } = props;
  const handlers = useBookingOrderTableHandlers({ tab });

  const tableTabActions = getBookingOrderTableTabActions(access, tab);

  return (
    <BookingOrderTableContext.Provider value={handlers}>
      <BookingOrderTableBehaviorAdapter
        guid={guid}
        tab={tab}
        partnerId={partnerId}
        customerId={customerId}
      />
      <TableCommonLayout
        header={<BookingOrderTableHeaderAdapter canDownload={access.download} />}
        filter={<BookingOrderTableFilterAdapter partnerId={partnerId} />}
        tabs={<BookingOrderTableTabsAdapter tab={tab} />}
        table={
          <BookingOrderTableTableAdapter
            tab={tab}
            lifecycle={lifecycle}
            onOrderClick={onOrderClick}
            partnerId={partnerId}
            tableTabActions={tableTabActions}
          />
        }
        footer={BookingOrderTableFooterContainer}
      ></TableCommonLayout>
    </BookingOrderTableContext.Provider>
  );
};

export default BookingOrderTableContainer;
