import { RootState } from '../../../../../data/store/store';

export const teamGuidSelector = (store: RootState) => store.team.details.guid;
export const teamGetNeedRefreshWatcherSelector = (store: RootState) => store.team.details.needRefreshWatcher;
export const teamGetByIdSelector = (store: RootState) => store.team.details.byId;
export const teamGetMembersSelector = (store: RootState) => store.team.details.members;
export const teamGetInvitesSelector = (store: RootState) => store.team.details.invites;
export const teamGetRevokeInviteSelector = (store: RootState) => store.team.details.revokeInvite;
export const teamGetRejectApplicationSelector = (store: RootState) => store.team.details.rejectApplication;
export const teamGetApproveApplicationSelector = (store: RootState) => store.team.details.approveApplication;
