import { useEffect, useState } from 'react';
import { EPartnerOwnershipType } from '../../../../../domain/model/enums';
import { PartnerDataDraft } from '../../../../../domain/model/partner';
import { Nullable } from '../../../../../domain/model/types';
import useValidation from '../../../../hooks/validation/useValidation';
import { ValidationResult } from '../../../../utils/validation';
import {
  PartnerDetailsCompany,
  PartnerDetailsIndividual,
  partnerEditDetailsCompanyValidation,
  partnerEditDetailsIndividualValidation,
} from '../validation';

interface UsePartnerDetailsValidationProps {
  readonly object: Nullable<PartnerDataDraft>;
  readonly validateOnChange?: boolean;
}

type UsePartnerDetailsValidation = {
  readonly isValid: boolean;
  readonly validationResultCompany: Nullable<ValidationResult<PartnerDetailsCompany>>;
  readonly validationResultIndividual: Nullable<ValidationResult<PartnerDetailsIndividual>>;
  readonly validate: () => boolean;
};

const getPartnerDetailsCompanyByPartner = (partner: Nullable<PartnerDataDraft>): Nullable<PartnerDetailsCompany> =>
  partner?.regInfo?.orgType && partner.regInfo.orgType !== EPartnerOwnershipType.IndividualEntrepreneur
    ? {
        ...partner,
        ogrn: partner.regInfo.ogrn,
        kpp: partner.regInfo.kpp,
      }
    : null;

const getPartnerDetailsIndividualByPartner = (
  partner: Nullable<PartnerDataDraft>
): Nullable<PartnerDetailsIndividual> =>
  partner?.regInfo?.orgType === EPartnerOwnershipType.IndividualEntrepreneur
    ? {
        ...partner,
        ogrnip: partner.regInfo.ogrnip,
      }
    : null;

function usePartnerDetailsValidation(props: UsePartnerDetailsValidationProps): UsePartnerDetailsValidation {
  const { object, validateOnChange = false } = props;

  const [partnerDetailsCompany, setPartnerDetailsCompany] = useState<Nullable<PartnerDetailsCompany>>(null);
  const [partnerDetailsIndividual, setPartnerDetailsIndividual] = useState<Nullable<PartnerDetailsIndividual>>(null);

  const {
    validationResult: validationResultCompany,
    isValid: isValidDetailsCompany,
    validate: validateDetailsCompany,
  } = useValidation<PartnerDetailsCompany>({
    object: partnerDetailsCompany,
    validateOnChange,
    rules: partnerEditDetailsCompanyValidation,
  });

  const {
    validationResult: validationResultIndividual,
    isValid: isValidDetailsIndividual,
    validate: validateDetailsIndividual,
  } = useValidation<PartnerDetailsIndividual>({
    object: partnerDetailsIndividual,
    validateOnChange,
    rules: partnerEditDetailsIndividualValidation,
  });

  useEffect(() => setPartnerDetailsCompany(getPartnerDetailsCompanyByPartner(object)), [object]);
  useEffect(() => setPartnerDetailsIndividual(getPartnerDetailsIndividualByPartner(object)), [object]);

  return {
    validationResultCompany,
    validationResultIndividual,
    isValid: isValidDetailsCompany && isValidDetailsIndividual,
    validate: () => validateDetailsCompany() && validateDetailsIndividual(),
  };
}

export default usePartnerDetailsValidation;
