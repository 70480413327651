import { MPReport } from '@/domain';
import { useReportDownloads } from '@features/report/downloads/useDownloads';
import { getReportsByUserRole } from '@features/report/table/utils';
import useCurrentUser from '@features/user/hooks/useCurrentUser';
import { useCallback, useMemo } from 'react';

type UseReportsTableConfigProps = {
  readonly partnerId: Nullable<UUID>;
};

export type UseReportsTableConfig = {
  readonly reports: MPReport[];
  readonly onReportClick: (report: MPReport) => void;
};

export const useReportsTableConfig = ({ partnerId }: UseReportsTableConfigProps): UseReportsTableConfig => {
  const {
    accessMatrix: { isSportUser, isPartnerUser, isMpUser },
  } = useCurrentUser();

  const reports = useMemo<MPReport[]>(
    () =>
      getReportsByUserRole(
        partnerId
          ? { isPartnerUser: true }
          : {
              isSportUser,
              isPartnerUser,
              isMpUser,
            }
      ) ?? [],
    [partnerId]
  );

  const { onOpenDownloadDialog } = useReportDownloads();

  const onReportClick = useCallback<UseReportsTableConfig['onReportClick']>(
    report => {
      onOpenDownloadDialog({ type: report.type, partnerId });
    },
    [onOpenDownloadDialog]
  );

  return {
    reports,
    onReportClick,
  };
};
