import { useContextConfig } from '@features/banner/table/hooks/useContextConfig';
import { useContextHandlers } from '@features/banner/table/hooks/useContextHandlers';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import TablePagination from '../../../components/common/table/pagination';
import {
  bannersPageCountSelector,
  bannersPageNumberSelector,
  bannersSearchSelector,
  bannersTotalCountSelector,
} from './store/selectors';

interface BannerTableFooterContainerProps {}

const BannerTableFooterContainer = forwardRef((props: BannerTableFooterContainerProps, ref: any) => {
  const { partition } = useContextConfig();
  const handlers = useContextHandlers();

  const pageNumber = useSelector(bannersPageNumberSelector(partition));
  const search = useSelector(bannersSearchSelector(partition));
  const totalCount = useSelector(bannersTotalCountSelector(partition));
  const pageCount = useSelector(bannersPageCountSelector(partition));

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='баннеров'
        onChangePageSize={handlers.onChangePageSize}
        onChangePage={handlers.onChangePageNumber}
      />
    </div>
  );
});

export default BannerTableFooterContainer;
