import { Grid, Link, Typography } from '@mui/material';
import { CodeWrapper, StyledButton, Wrapper } from './controls';

interface ErrorBoundaryContentProps {
  readonly message?: any;
  readonly stacktrace?: any;
  readonly onLogout?: (() => void) | false;
}

const ErrorBoundaryContent = ({ message, stacktrace, onLogout }: ErrorBoundaryContentProps) => {
  return (
    <Wrapper maxWidth='md'>
      <Grid
        container
        spacing={2}
        direction='column'
      >
        <Grid item>
          <Typography
            variant='h5'
            gutterBottom
          >
            Произошла ошибка!
          </Typography>
        </Grid>
        <Grid
          item
          container
          spacing={2}
        >
          <Grid item>
            <StyledButton
              component={Link}
              href='/'
            >
              Перейти на главную
            </StyledButton>
          </Grid>
          {onLogout && (
            <Grid item>
              <StyledButton onClick={onLogout}>Выйти</StyledButton>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Typography
            variant='overline'
            gutterBottom
          >
            {message}
          </Typography>
        </Grid>
        {stacktrace && (
          <Grid item>
            <CodeWrapper>
              {stacktrace.split('\n').map((item: any, index: number) => (
                <div key={index}>{item}</div>
              ))}
            </CodeWrapper>
          </Grid>
        )}
      </Grid>
    </Wrapper>
  );
};

export default ErrorBoundaryContent;
