import SportsmanTableCell, { SportsmanTableCellCommonProps } from './index';
import { pluralize } from '../../../../utils/pluralize';

const SportsmanTableCellAge = (props: SportsmanTableCellCommonProps) => {
  const { sportsman } = props;

  return (
    <SportsmanTableCell
      sportsman={sportsman}
      value={sportsman.age > 0 ? `${sportsman.age} ${pluralize(sportsman.age, ['год', 'года', 'лет'])}` : '-'}
    />
  );
};

export default SportsmanTableCellAge;
