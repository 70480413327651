export const fileTypes = {
  csv: {
    ext: '.csv',
    mimeType: 'text/csv',
  },
  pdf: {
    ext: '.pdf',
    mimeType: 'application/pdf',
  },
  doc: {
    ext: '.doc',
    mimeType: 'application/msword',
  },
  docx: {
    ext: '.docx',
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  xls: {
    ext: '.xls',
    mimeType: 'application/vnd.ms-excel',
  },
  xlsx: {
    ext: '.xlsx',
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  jpg: {
    ext: '.jpg',
    mimeType: 'image/jpg',
  },
  jpeg: {
    ext: '.jpeg',
    mimeType: 'image/jpeg',
  },
  png: {
    ext: '.png',
    mimeType: 'image/png',
  },
  svg: {
    ext: '.svg',
    mimeType: 'image/svg+xml',
  },
  ico: {
    ext: '.ico',
    mimeType: 'image/x-icon',
  },
} as const;

export const fileSizeInBytes = {
  _1MB: 1 * 1024 * 1024,
  _500kB: 0.5 * 1024 * 1024,
  _300kB: 0.3 * 1024 * 1024,
  _100kB: 0.0976 * 1024 * 1024,
};

export const systemStartYear = 2018;
