import { Grid, ImageList, ImageListItem } from '@mui/material';
import { Team } from '../../../../../../domain/model/team';
import { Nullable } from '../../../../../../domain/model/types';
import AppImage from '../../../../../components/common/images/common';
import { Field, FieldsGroup } from '../../../../../components/fields';
import { MPButton } from '../../../../../theme/ui-kit/button';
import ActivityTypesFieldView from '../../../../activityType/components/fieldView';
import { Content, Footer, TeamMediaWrapper } from './controls';

interface TeamTabGeneralProps {
  readonly team: Team;
  readonly onTeamEdit: Nullable<() => void>;
}

const TeamTabGeneral = ({ team, onTeamEdit }: TeamTabGeneralProps) => {
  return (
    <>
      <Content>
        <FieldsGroup>
          <Field>
            {team.activityTypes && team.activityTypes.length > 0 && (
              <ActivityTypesFieldView value={team.activityTypes} />
            )}
          </Field>

          <Field label='Описание'>{team.description}</Field>

          <Field label='Медиа'>
            {team.media?.length > 0 && (
              <ImageList
                cols={3}
                gap={6}
              >
                {team.media.map(media => (
                  <ImageListItem key={media.path}>
                    <TeamMediaWrapper>
                      <AppImage src={media} />
                    </TeamMediaWrapper>
                  </ImageListItem>
                ))}
              </ImageList>
            )}
          </Field>
        </FieldsGroup>
      </Content>
      <Footer>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <MPButton
              disabled={!onTeamEdit}
              onClick={onTeamEdit}
            >
              Редактировать
            </MPButton>
          </Grid>
        </Grid>
      </Footer>
    </>
  );
};

export default TeamTabGeneral;
