import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../data/store/store';
import { ESortDirection } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { SportUserProfile } from '../../../../../domain/model/user';
import { ESportsmanTableColumn } from '../../table/utils';

export const sportsmanSearchSelector = (store: RootState) => store.sportsman.search;

export const sportsmanSearchGuidSelector = (store: RootState) => store.sportsman.search.guid;
export const sportsmanSearchSportsmenSelector = (store: RootState) => store.sportsman.search.data;
export const sportsmanSearchSearchSelector = (store: RootState) => store.sportsman.search.search;
export const sportsmanSearchPageNumberSelector = (store: RootState) => store.sportsman.search.pageNumber;
export const sportsmanSearchFilterSelector = (store: RootState) => store.sportsman.search.filter;
export const sportsmanSearchSelectedSelector = (store: RootState) => store.sportsman.search.selected;
export const sportsmanSearchIsFetchingSelector = (store: RootState) => store.sportsman.search.isFetching;
export const sportsmanSearchNeedRefreshWatcherSelector = (store: RootState) =>
  store.sportsman.search.needRefreshWatcher;

export const sportsmanSearchSportsmanIndexSelector = (store: RootState, index: number) => index;

export const sportsmanSearchSortColumnSelector = (store: RootState) =>
  (store.sportsman.search.search.sort?.split(',') as [ESportsmanTableColumn, ESortDirection])?.[0] ?? '';

export const sportsmanSearchSortDirectionSelector = (store: RootState) =>
  (store.sportsman.search.search.sort?.split(',') as [ESportsmanTableColumn, ESortDirection])?.[1] ?? '';

const createSportsmanSearchSportsmanByIndexSelector = createSelector(
  sportsmanSearchSportsmenSelector,
  sportsmanSearchSportsmanIndexSelector,
  (sportsmans, index): Nullable<SportUserProfile> => sportsmans?.[index] ?? null
);

export const sportsmanSearchSportsmanByIndexSelector = (index: number) => (store: RootState) =>
  createSportsmanSearchSportsmanByIndexSelector(store, index);
