import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UUID } from '../../../../../domain/model/types';
import { EBookingOrderTableTab } from '../../types';
import { bookingOrdersGuidSelector } from '../store/selectors';
import { bookingOrdersSetTab, bookingOrdersStartSession } from '../store/slice';

type UseBookingOrderTableSessionProps = {
  readonly guid: UUID;
  readonly tab: EBookingOrderTableTab;
};

export const useBookingOrderTableSession = (props: UseBookingOrderTableSessionProps) => {
  const { guid, tab } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(bookingOrdersGuidSelector);

  // инициализация сеанса
  useEffect(() => {
    dispatch(bookingOrdersStartSession({ guid }));
  }, [dispatch, guid]);

  // начальная загрузка, изменение вкладки
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(bookingOrdersSetTab({ tab }));
    }
  }, [dispatch, guid, currentGuid, tab]);
};
