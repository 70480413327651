import TradeOfferTableCell, { TradeOfferTableCellCommonProps } from './index';

const TradeOfferTableCellCode = (props: TradeOfferTableCellCommonProps) => {
  const {
    tradeOffer: { code: value = '-' },
  } = props;

  return (
    <TradeOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default TradeOfferTableCellCode;
