import { EOfferType, SportOptionTyped } from '@/domain';
import { Typography } from '@mui/material';
import { MPMenuItem } from '@ui-kit/menu/item';
import { MPSelect } from '@ui-kit/select';
import { AdCampaignCreateFieldCommonProps } from '../../types';

const fieldName = 'offerType';

interface AdCampaignCreateFieldOfferTypeProps extends AdCampaignCreateFieldCommonProps<typeof fieldName> {
  readonly offerTypes: SportOptionTyped<EOfferType>[];
}

const AdCampaignCreateFieldOfferType = (props: AdCampaignCreateFieldOfferTypeProps) => {
  const { value, offerTypes, validation, disabled, onChangeAttribute, onValidateAttribute } = props;

  const onChange = (newValue: SportOptionTyped<EOfferType>) => {
    onChangeAttribute(fieldName, newValue.id);
    onValidateAttribute(fieldName);
  };

  return (
    <MPSelect<SportOptionTyped<EOfferType>>
      label='Тип предложения'
      disabled={disabled}
      value={offerTypes.find(item => item.id === value)}
      error={validation?.hasError}
      helperText={validation?.message}
      onChange={onChange}
    >
      {offerTypes.map(item => (
        <MPMenuItem
          key={item.id}
          value={item}
        >
          <Typography variant='body1'>{item.name}</Typography>
        </MPMenuItem>
      ))}
    </MPSelect>
  );
};

export default AdCampaignCreateFieldOfferType;
