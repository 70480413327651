import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { Partner } from '../../../domain/model/partner';
import { UUID } from '../../../domain/model/types';
import CmsContainerByIdContainer from '../../features/cms/container/byId/container';
import CmsContainerDetailsContainer from '../../features/cms/container/details/container';
import { CmsContainerLifeCycleFactory } from '../../features/cms/container/lifecycle';
import { CmsFeatureContextPartner, ECmsContainerUrlParam } from '../../features/cms/container/types';
import RequiredGuidInLocationContainer from '../../features/general/locationGuid/container';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import { viewHidePartnerManagementMenu } from '../../features/partner/management/container/store/slice';
import {
  getPartnerManagementWindowContainerEditRoute,
  getPartnerManagementWindowRoute,
} from '../../features/partner/management/entry';
import { PartnerCmsContainerCreateLocationState } from '../../features/partnerWindow/entry';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';
import useHistoryExtensions from '../../hooks/useHistoryExtensions';

interface PartnerManagementWindowContainerDetailsScreenProps {
  readonly partner: Partner;
}

const PartnerManagementWindowContainerDetailsScreen = ({
  partner,
}: PartnerManagementWindowContainerDetailsScreenProps) => {
  const dispatch = useDispatch();

  const { userSpecific, accessMatrix } = useCurrentUser();
  const { partnerWindow } = accessMatrix;

  const location = useLocation<PartnerCmsContainerCreateLocationState>();
  const history = useHistory();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const { containerId } = useParams<{ containerId: UUID }>();
  const searchParams = new URLSearchParams(location.search);
  const step = Number(searchParams.get(ECmsContainerUrlParam.Step)) ?? 0;

  const cmsContext = useMemo<CmsFeatureContextPartner>(() => ({ partner: { id: partner.id } }), [partner.id]);

  const onClose = () => {
    gotoPrevIndependentLocation(getPartnerManagementWindowRoute({ id: partner.id }));
  };

  const onCmsContainerEdit = () => {
    history.replace(
      getPartnerManagementWindowContainerEditRoute({
        partnerId: partner.id,
        containerId: containerId,
      })
    );
  };

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewHidePartnerManagementMenu());
  }, [dispatch]);

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <CmsContainerByIdContainer id={containerId}>
          {({ cmsSitePage, cmsContainer }) => (
            <CmsContainerDetailsContainer
              cmsContext={cmsContext}
              cmsSitePage={cmsSitePage}
              id={containerId}
              guid={guid}
              step={step}
              type={cmsContainer.type!.code}
              lifecycle={CmsContainerLifeCycleFactory().create({ userId: userSpecific.id })}
              onClose={onClose}
              onCmsContainerEdit={onCmsContainerEdit}
              canCreate={partnerWindow.create}
              canEdit={partnerWindow.edit}
              canPause={partnerWindow.pause}
              canResume={partnerWindow.resume}
              canArchive={partnerWindow.archive}
              canDelete={partnerWindow.delete}
              canChangeOfferIconVisible={partnerWindow.changeOfferIconVisible}
            />
          )}
        </CmsContainerByIdContainer>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default PartnerManagementWindowContainerDetailsScreen;
