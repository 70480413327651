import { EUserStatus } from '../../../../domain/model/enums';
import { EPanelActionPosition, PanelActions } from '../../../types';
import { EPartnerEmployeeActionType, PartnerEmployeeActionDetailsType } from '../types';
import { EPartnerEmployeeStep, getPartnerEmployeeActionName } from '../utils';

export const getPartnerEmployeeDetailsStepText = (step: EPartnerEmployeeStep): string => {
  switch (step) {
    case EPartnerEmployeeStep.Profile:
      return 'Профиль';
    case EPartnerEmployeeStep.Grants:
      return 'Доступ';
    default:
      throw new Error(`Unknown EPartnerEmployeeStep: ${step}`);
  }
};

export const getPartnerEmployeeDetailsActionsByStatus = (
  status: EUserStatus,
  canEdit: boolean,
  canChangeStatus: boolean
): PanelActions<PartnerEmployeeActionDetailsType> => {
  const actions: PanelActions<PartnerEmployeeActionDetailsType> = [];
  if (canChangeStatus) {
    switch (status) {
      case EUserStatus.Disabled:
        actions.push({
          type: EPartnerEmployeeActionType.Activate,
          label: getPartnerEmployeeActionName(EPartnerEmployeeActionType.Activate),
          position: [EPanelActionPosition.Menu],
        });
        break;
      case EUserStatus.Enabled:
        actions.push({
          type: EPartnerEmployeeActionType.Deactivate,
          label: getPartnerEmployeeActionName(EPartnerEmployeeActionType.Deactivate),
          position: [EPanelActionPosition.Menu],
        });
        break;
      default:
        break;
    }
  }

  if (canEdit) {
    actions.push({
      type: EPartnerEmployeeActionType.Edit,
      label: getPartnerEmployeeActionName(EPartnerEmployeeActionType.Edit),
      position: [EPanelActionPosition.Menu],
    });
  }

  return actions;
};
