import { AdCampaignTableCellAdapterProps } from '@features/adCampaign/table/adapters/cell';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { AdCampaignTableCellOfferTypeProps } from '../../components/tableCell';
import { adCampaignAdCampaignByIndexSelector } from '../store/selectors';

type AdCampaignTableCellOfferTypeAdapterProps = Omit<AdCampaignTableCellAdapterProps, 'component'> & {
  readonly component: FC<AdCampaignTableCellOfferTypeProps>;
};

const AdCampaignTableCellOfferTypeAdapter = (props: AdCampaignTableCellOfferTypeAdapterProps) => {
  const { index, component: Component } = props;

  const { offerTypes } = useSelector(nsiDataSelector);

  const adCampaign = useSelector(adCampaignAdCampaignByIndexSelector(index));

  return adCampaign ? (
    <Component
      adCampaign={adCampaign}
      offerTypes={offerTypes}
    />
  ) : null;
};

export default AdCampaignTableCellOfferTypeAdapter;
