import {
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterValueItem,
  EDataFilterType,
  Nullable,
} from '@/domain';

export enum EReportEventsDetailedFilterItem {
  DatePeriod = 'datePeriod',
  Partner = 'partner',
}

export type ReportEventsDetailedFilterEditStrategy = DataFilterStrategyBase<EReportEventsDetailedFilterItem>;

export type ReportEventsDetailedFilterValues = {
  readonly [EReportEventsDetailedFilterItem.DatePeriod]?: DataFilterValueItem<
    Nullable<[Nullable<string>, Nullable<string>]>
  >;
  readonly [EReportEventsDetailedFilterItem.Partner]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem>>;
};

export const getReportEventsDetailedFilterStrategies = (
  values: ReportEventsDetailedFilterValues
): ReportEventsDetailedFilterEditStrategy[] => {
  const strategies: ReportEventsDetailedFilterEditStrategy[] = [];
  strategies.push({
    type: EDataFilterType.DatePeriod,
    key: EReportEventsDetailedFilterItem.DatePeriod,
    label: '',
    labelPeriod: {
      from: 'Период с',
      to: 'по',
    },
    buttonsPeriod: true,
    preview: null,
    value: values[EReportEventsDetailedFilterItem.DatePeriod]?.value || null,
  });

  return strategies;
};
