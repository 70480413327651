import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import { CorpOfferCategoriesEditFullScreenContainer } from '../../features/dictionary/corpOfferCategory/edit/containerFullScreen';

const ConfigurationPrivilegeCorpOfferCategoriesScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  return <CorpOfferCategoriesEditFullScreenContainer />;
};

export default ConfigurationPrivilegeCorpOfferCategoriesScreen;
