import Axios from 'axios';
import Api from '../../../../../data/api';
import { PartnerAllProps as ApiAllProps } from '../../../../../data/api/partner';
import { ApiCancellable, ApiRequestListDiscriminator } from '../../../../../data/api/types';
import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '../../../../../data/api/utils';
import { Pageable } from '../../../../../domain/model';
import { EPartnerStatus } from '../../../../../domain/model/enums';
import { DataFilterQueryDslOperator } from '../../../../../domain/model/filter';
import {
  Partner,
  PartnerAdminRequest,
  PartnerDraft,
  PartnerShort,
  PartnerShortDraft,
} from '../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { MpUser, MpUserData, MpUserShort } from '../../../../../domain/model/user';
import { PartnerTableFilterValues } from '../../../partner/filterUtils';
import { getPartnerTableStatusesByTab, PartnerTableTabsCounter } from '../../../partner/table/utils';
import { EPartnerTableTab, PartnerCounterByStatus } from '../types';

type CommandProps = {
  readonly id: UUID;
};

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly sort: Nullable<string>;
    readonly statuses: EPartnerStatus[];
    readonly createdById: Nullable<UUID>;
    readonly pageSize: number;
  };
  readonly filter: PartnerTableFilterValues;
  readonly pageNumber: number;
};

export type CountProps = AllProps;

type CountsByStatusesProps = CountProps;

type CountsByTabsProps = CountProps & {
  readonly tabs: EPartnerTableTab[];
};

type CreateProps = PartnerAdminRequest;

type InviteProps = {
  readonly redirectUri?: string;
  readonly user: MpUserData;
};

type CreateForUserProps = {
  readonly user: MpUserShort;
  readonly partner: Nullable<PartnerShort>;
};

export type PartnerCommonService = {
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps
  ) => ApiAllProps<D>;
  readonly all: (props: AllProps) => Promise<Pageable<PartnerShortDraft>>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly countsByStatuses: (
    props: CountsByStatusesProps
  ) => Promise<{ counts: PartnerCounterByStatus; errors: Array<string> }>;
  readonly countsByTabs: (
    props: CountsByTabsProps
  ) => Promise<{ counts: PartnerTableTabsCounter; errors: Array<string> }>;
  readonly enabled: (props: CommandProps) => Promise<Partner>;
  readonly disabled: (props: CommandProps) => Promise<Partner>;
  readonly sendToVerification: (props: CommandProps) => Promise<Partner>;
  readonly delete: (props: CommandProps) => Promise<void>;
  readonly create: (props: CreateProps) => Promise<Partner>;
  readonly createForUser: (props: CreateForUserProps) => Promise<PartnerDraft>;
  readonly invite: (props: InviteProps) => Promise<MpUser>;
};

const service: PartnerCommonService = {
  buildListQueryParams: ({ search, filter, signal, pageNumber }) => {
    const { sort, statuses, createdById, pageSize } = search;
    const { query } = filter;

    let querydsl = getQueryDslByDataFilterValues(filter);
    if (createdById) {
      querydsl = [
        ...(getQueryDslByDataFilterValues(filter) ?? []),
        {
          field: 'createdBy',
          operator: DataFilterQueryDslOperator.Equals,
          value: [createdById],
        },
      ];
    }

    return {
      query: query?.value,
      querydsl,
      signal,
      statuses,
      page: pageNumber,
      pageSize,
      sort,
    };
  },
  all: async props => {
    const response = await Api.partner.all(service.buildListQueryParams(props));
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);

    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
  count: async props => {
    const { data: response } = await Api.partner.all({
      ...service.buildListQueryParams(props),
      discriminator: ApiRequestListDiscriminator.Count,
    });

    return response[0].count;
  },
  countsByStatuses: async ({ signal, ...props }) => {
    const {
      search: { statuses },
    } = props;

    const errors: string[] = [];
    const counts: PartnerCounterByStatus = {};

    const requests = statuses.map(status => {
      const params = {
        ...props,
        search: { ...props.search, statuses: [status] },
      };
      return service.count({ ...params, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], status: EPartnerStatus) => {
      if (response.status === 'fulfilled') {
        counts[status] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество партнеров '${status}': ${response.reason}`);
        }
      }
    };

    statuses.forEach((status, index) => parseResponse(responses[index], status));

    return { counts, errors };
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: PartnerTableTabsCounter = {};

    const requests = tabs.map(tab => {
      const statuses = getPartnerTableStatusesByTab(tab);
      const params = {
        ...props,
        search: {
          ...props.search,
          statuses,
          createdById: tab === EPartnerTableTab.Unverified ? props.search.createdById : null,
        },
      };
      return service.count({ ...params, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], tab: EPartnerTableTab) => {
      if (response.status === 'fulfilled') {
        counts[tab] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество партнеров '${tab}': ${response.reason}`);
        }
      }
    };

    tabs.forEach((tab, index) => parseResponse(responses[index], tab));

    return { counts, errors };
  },
  enabled: async ({ id }) => {
    return (await Api.partner.enabled(id)).data;
  },
  disabled: async ({ id }) => {
    return (await Api.partner.disabled(id)).data;
  },
  sendToVerification: async ({ id }) => {
    return (await Api.partner.sendToVerification(id)).data;
  },
  delete: async ({ id }) => {
    await Api.partner.delete(id);
  },
  create: async data => {
    return (await Api.partner.create(data)).data;
  },
  createForUser: async ({ user, partner }) => {
    await Api.user.mp.update({ id: user.id, data: user });
    if (!partner) {
      return (await Api.partner.createForUser()).data;
    } else {
      return (await Api.partner.one({ id: partner.id })).data;
    }
  },
  invite: async ({ user, redirectUri }) => {
    return (await Api.partner.invite({ user, redirectUri })).data;
  },
};

export default service;
