import { FCC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import store from '../../../../../data/store/store';
import { EOrderStatus } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import useHistoryExtensions from '../../../../hooks/useHistoryExtensions';
import validateObject from '../../../../hooks/validation/utils';
import { ValidationCollectionResult, ValidationItemResult, ValidationRules } from '../../../../utils/validation';
import { useBookingOrderActions } from '../../actions/useAction';
import { getBookingOrdersTableRoute } from '../../entry';
import { getBookingOrderTableTabForStatus } from '../../table/utils';
import { BookingOrderItemView, BookingOrderView, EBookingOrderTableTab } from '../../types';
import {
  getBookingOrderCost,
  getBookingOrderItemTotalCost,
  showBookingOrderInvalidNotification,
} from '../../utils/common';
import {
  bookingOrderBusinessValidationRules,
  bookingOrderOrderItemBusinessValidationRules,
  bookingOrderOrderItemValidationRulesConfirm,
  bookingOrderOrderItemValidationRulesGive,
  bookingOrderValidationRulesConfirm,
  bookingOrderValidationRulesGive,
} from '../../utils/validation';
import { BookingOrderEditContainerProps } from '../container';
import { BookingOrderEditHandlersContext, BookingOrderEditHandlersContextValue } from '../context';
import {
  bookingOrderEditOrderSelector,
  bookingOrderEditValidationOrderItemsResultsSelector,
  bookingOrderEditValidationResultsByFieldSelector,
} from '../store/selectors';
import {
  bookingOrderEditAddOrderItem,
  bookingOrderEditClearAllValidations,
  bookingOrderEditClearOrderItemAttributeValidation,
  bookingOrderEditClearOrderItemValidation,
  bookingOrderEditClearState,
  bookingOrderEditDeleteOrderItem,
  bookingOrderEditOrderItemSetAttributeValidation,
  bookingOrderEditSetAttribute,
  bookingOrderEditSetAttributeValidation,
  bookingOrderEditSetDialogState,
  bookingOrderEditSetModified,
  bookingOrderEditSetOrderItemAttribute,
  bookingOrderEditSetOrderItemsValidation,
  bookingOrderEditSetValidation,
} from '../store/slice';

export const BookingOrderEditHandlersProvider: FCC<BookingOrderEditContainerProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { gotoPrevIndependentLocation } = useHistoryExtensions();
  const { onSaveInStatus, onTryCancel: onOrderTryCancel } = useBookingOrderActions();
  /**
   * Получение заказа
   */
  const getBookingOrderFromState = useCallback((): Nullable<BookingOrderView> => {
    const state = store.getState();
    return bookingOrderEditOrderSelector(state);
  }, [store]);

  const getBookingOrderItemsFromState = useCallback((): Nullable<BookingOrderItemView[]> => {
    return getBookingOrderFromState()?.orderItems ?? null;
  }, [store, getBookingOrderFromState]);

  const getAttributeValidationFromState = useCallback(
    (name: keyof BookingOrderView): Nullable<ValidationItemResult> => {
      const state = store.getState();
      return bookingOrderEditValidationResultsByFieldSelector(name)(state);
    },
    [store]
  );

  const getOrderItemsValidationFromState = useCallback((): ValidationCollectionResult<BookingOrderItemView> => {
    const state = store.getState();
    return bookingOrderEditValidationOrderItemsResultsSelector(state);
  }, [store]);

  // Подтверждение
  const onBookingOrderConfirm = useCallback<BookingOrderEditHandlersContextValue['onBookingOrderConfirm']>(() => {
    const order = getBookingOrderFromState();
    if (order) {
      dispatch(bookingOrderEditClearAllValidations());
      const isValidByActionRules = onValidateBookingOrder(order, bookingOrderValidationRulesConfirm);
      const isValidOrderItems = onValidateOrderItems(
        order.orderItems,
        bookingOrderOrderItemValidationRulesConfirm,
        true
      );

      if (isValidByActionRules && isValidOrderItems) {
        onSaveInStatus(EOrderStatus.Confirmed, order);
      } else {
        showBookingOrderInvalidNotification(order, EOrderStatus.Confirmed);
      }
    }
  }, [dispatch, onSaveInStatus]);

  // Подтверждение
  const onBookingOrderGive = useCallback<BookingOrderEditHandlersContextValue['onBookingOrderGive']>(() => {
    const order = getBookingOrderFromState();
    if (order) {
      dispatch(bookingOrderEditClearAllValidations());
      const isValidByActionRules = onValidateBookingOrder(order, bookingOrderValidationRulesGive);
      const isValidOrderItems = onValidateOrderItems(order.orderItems, bookingOrderOrderItemValidationRulesGive, true);

      if (isValidByActionRules && isValidOrderItems) {
        onSaveInStatus(EOrderStatus.Given, order);
      } else {
        showBookingOrderInvalidNotification(order, EOrderStatus.Given);
      }
    }
  }, [dispatch, onSaveInStatus]);

  // Отмена
  const onTryCancel = useCallback<BookingOrderEditHandlersContextValue['onTryCancel']>(() => {
    const order = getBookingOrderFromState();

    if (order) {
      onOrderTryCancel(order);
    }
  }, [getBookingOrderFromState, onOrderTryCancel]);

  const onAttributeValidate = useCallback<BookingOrderEditHandlersContextValue['onAttributeValidate']>(
    name => {
      const bookingOrder = getBookingOrderFromState();

      //не проверяем пустые значения
      if (!bookingOrder?.[name]) {
        return;
      }

      const rules = { [name]: bookingOrderBusinessValidationRules[name] ?? {} };
      const results = validateObject(bookingOrder, rules).results?.[name] ?? null;
      dispatch(bookingOrderEditSetAttributeValidation({ name, results }));
    },
    [dispatch, getBookingOrderFromState]
  );

  // Заказ ДиО - валидация без списка строк
  const onValidateBookingOrder = useCallback(
    (bookingOrder: BookingOrderView, rules: ValidationRules<BookingOrderView>): boolean => {
      //запускаем валидацию бизнес правилам
      const byBusinessValidation = validateObject(bookingOrder, bookingOrderBusinessValidationRules);

      //запускаем валидацию по целевым правилам
      const byActionValidation = validateObject(bookingOrder, rules);

      //сохраняем суммарные результаты валидации
      dispatch(bookingOrderEditSetValidation({ ...byBusinessValidation.results, ...byActionValidation.results }));

      //факт валидности берём суммарно
      return byBusinessValidation.isValid && byActionValidation.isValid;
    },
    [dispatch, validateObject]
  );

  // Позиции заказа - валидация элементов списка
  const onValidateOrderItems = useCallback(
    (
      orderItems: Nullable<BookingOrderItemView[]>,
      rules: ValidationRules<BookingOrderItemView>,
      save: boolean
    ): boolean => {
      let hasErrors = false;

      // элементы списка
      const results: ValidationCollectionResult<BookingOrderItemView> =
        orderItems?.map(object => {
          const validateObjectResult = validateObject(object, rules);
          if (!validateObjectResult.isValid) {
            hasErrors = true;
          }
          return validateObjectResult.results;
        }) ?? [];

      if (save) {
        dispatch(bookingOrderEditSetOrderItemsValidation(results));
      }

      return !hasErrors;
    },
    [dispatch]
  );

  const onOrderItemAttributeValidate = useCallback<
    BookingOrderEditHandlersContextValue['onOrderItemAttributeValidate']
  >(
    (index, name) => {
      const orderItem = getBookingOrderItemsFromState()?.[index];
      if (orderItem) {
        //не проверяем пустые значения
        if (!orderItem?.[name]) {
          return;
        }

        const rules = { [name]: bookingOrderOrderItemBusinessValidationRules[name] ?? {} };
        const results = validateObject(orderItem, rules).results?.[name] ?? null;
        dispatch(bookingOrderEditOrderItemSetAttributeValidation({ index, name, results }));
      }
    },
    [dispatch, getBookingOrderItemsFromState]
  );

  const onClose = () => {
    dispatch(bookingOrderEditClearState());
    const defaultTab = EBookingOrderTableTab.Given;
    const bookingOrder = getBookingOrderFromState();
    const tab = bookingOrder?.status ? getBookingOrderTableTabForStatus(bookingOrder.status) : defaultTab;
    gotoPrevIndependentLocation(getBookingOrdersTableRoute({ tab }));
  };

  /**
   * Открываем или закрываем диалоговое окно
   */
  const onChangeDialogState = useCallback<BookingOrderEditHandlersContextValue['onChangeDialogState']>(
    (name, bookingOrder) => {
      dispatch(bookingOrderEditSetDialogState({ name, data: bookingOrder }));
    },
    [dispatch]
  );

  const onChangeModified = useCallback(
    (modified: boolean) => {
      dispatch(bookingOrderEditSetModified(modified));
    },
    [dispatch]
  );

  const onChangeAttribute = useCallback<BookingOrderEditHandlersContextValue['onChangeAttribute']>(
    (name, value) => {
      dispatch(bookingOrderEditSetAttribute({ name, value }));
      onChangeModified(true);
    },
    [dispatch]
  );

  const onTryAddService = useCallback<BookingOrderEditHandlersContextValue['onTryAddService']>(() => {
    const order = getBookingOrderFromState();
    dispatch(bookingOrderEditSetDialogState({ name: 'addItem', data: order }));
    onChangeModified(true);
  }, [dispatch, getBookingOrderFromState]);

  const onCalculateOrderItemTotalCost = useCallback(
    (index: number) => {
      const orderItem = getBookingOrderItemsFromState()?.[index];
      if (orderItem) {
        const value = getBookingOrderItemTotalCost(orderItem);
        dispatch(bookingOrderEditSetOrderItemAttribute({ index, name: 'totalCost', value }));
        // пересчитываем цену заказа
        onCalculateOrderCost();
      }
    },
    [dispatch, getBookingOrderItemsFromState]
  );

  const onCalculateOrderCost = useCallback(() => {
    const order = getBookingOrderFromState();
    if (order) {
      const value = getBookingOrderCost(order);
      onChangeAttribute('cost', value);
    }
  }, [getBookingOrderFromState]);

  const onChangeOrderItemAttribute = useCallback<BookingOrderEditHandlersContextValue['onChangeOrderItemAttribute']>(
    (index, name, value) => {
      const orderItem = getBookingOrderItemsFromState()?.[index];
      if (orderItem) {
        dispatch(bookingOrderEditClearOrderItemAttributeValidation({ index, name }));
        dispatch(bookingOrderEditSetOrderItemAttribute({ index, name, value }));
        // пересчитываем стоимость позиции заказа
        onCalculateOrderItemTotalCost(index);
        onChangeModified(true);
      }
    },
    [dispatch, getBookingOrderItemsFromState]
  );

  const onAddOrderItem = useCallback<BookingOrderEditHandlersContextValue['onAddOrderItem']>(
    orderItem => {
      dispatch(bookingOrderEditAddOrderItem(orderItem));
      onOrderItemsRepeatValidate();
      onChangeModified(true);
    },
    [dispatch, onChangeModified]
  );

  const onDeleteOrderItem = useCallback<BookingOrderEditHandlersContextValue['onDeleteOrderItem']>(
    index => {
      dispatch(bookingOrderEditClearOrderItemValidation(index));
      dispatch(bookingOrderEditDeleteOrderItem(index));
      onOrderItemsRepeatValidate();
      onChangeModified(true);
    },
    [dispatch]
  );

  const onOrderItemsRepeatValidate = useCallback(() => {
    const orderItems = getBookingOrderItemsFromState();
    if (orderItems) {
      const fieldOrderItemsValidationFromState = getAttributeValidationFromState('orderItems');
      const orderItemsValidationFromState = getOrderItemsValidationFromState();

      const isBookingOrderOrderItemsHasSavedError = fieldOrderItemsValidationFromState?.hasError;
      const isOrderItemsHasSavedError = orderItemsValidationFromState?.some(
        v => v && Object.values(v)?.some(v => v?.hasError)
      );
      if (isOrderItemsHasSavedError || isBookingOrderOrderItemsHasSavedError) {
        onValidateOrderItems(orderItems, bookingOrderOrderItemBusinessValidationRules, true);
        onAttributeValidate('orderItems');
      }
    }
  }, [
    dispatch,
    getAttributeValidationFromState,
    getOrderItemsValidationFromState,
    onAttributeValidate,
    getBookingOrderItemsFromState,
  ]);

  const value: BookingOrderEditHandlersContextValue = {
    onClose,
    onBookingOrderConfirm,
    onBookingOrderGive,
    onTryCancel,
    onChangeDialogState,
    onChangeAttribute,
    onAttributeValidate,
    onTryAddService,
    onAddOrderItem,
    onDeleteOrderItem,
    onChangeOrderItemAttribute,
    onOrderItemAttributeValidate,
  };

  return <BookingOrderEditHandlersContext.Provider value={value}>{children}</BookingOrderEditHandlersContext.Provider>;
};
