import { CorpOfferActivation } from '@/domain/model/activation';
import useDataTableFilterAdapter, { DataFilterAdapter } from '@/presentation/hooks/useDataFilterAdapter';
import { ECorpActivationsFilterItem } from '@features/corpActivation/utils/filter';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PanelAction } from '../../../../types';
import { useCorpActivationActions } from '../../actions/useAction';
import { CorpActivationLifeCycle } from '../../lifecycle/types';
import { CorpActivationActionTableType, ECorpActivationActionType, ECorpActivationTableColumn } from '../../types';
import { getCorpActivationTableActions } from '../../utils/actions';
import CorpActivationTable from '../component';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  corpActivationsActivationsSelector,
  corpActivationsFilterSelector,
  corpActivationsGuidSelector,
  corpActivationsSortColumnSelector,
  corpActivationsSortDirectionSelector,
} from '../store/selectors';

type CorpActivationTableTableAdapterProps = {
  readonly lifecycle: CorpActivationLifeCycle;
  readonly filterAdapter: DataFilterAdapter<ECorpActivationTableColumn, ECorpActivationsFilterItem>;
  readonly tableTabActions: PanelAction<CorpActivationActionTableType, any>[];
};

const CorpActivationTableTableAdapter = (props: CorpActivationTableTableAdapterProps) => {
  const { lifecycle, filterAdapter, tableTabActions } = props;
  const handlers = useContextHandlers();

  const {
    utils: {
      selectors: { isCorpActivationsAnyChangedSelector },
    },
  } = useCorpActivationActions();

  const guid = useSelector(corpActivationsGuidSelector);
  const filter = useSelector(corpActivationsFilterSelector);
  const sortColumn = useSelector(corpActivationsSortColumnSelector);
  const sortDirection = useSelector(corpActivationsSortDirectionSelector);
  const needRefresh = useSelector(isCorpActivationsAnyChangedSelector);
  const activations = useSelector(corpActivationsActivationsSelector);

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  const onExecuteAction = useCallback((action: CorpActivationActionTableType, activation: CorpOfferActivation) => {
    switch (action) {
      case ECorpActivationActionType.Give:
        handlers.onActivationGive(activation);
        break;
      case ECorpActivationActionType.UnGive:
        handlers.onActivationUnGive(activation);
        break;
    }
  }, []);

  useEffect(() => {
    if (needRefresh > 0) {
      handlers.onRefresh();
    }
  }, [needRefresh, handlers.onRefresh]);

  return (
    <CorpActivationTable
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      activations={activations}
      getActions={activation => getCorpActivationTableActions(lifecycle, activation, tableTabActions)}
      onRequestSort={handlers.onChangeSort}
      onAction={onExecuteAction}
      onChangeMetadata={onChangeMetadata}
    />
  );
};

export default CorpActivationTableTableAdapter;
