import { Typography } from '@mui/material';
import { ContentTarget } from '../../../../../../domain/model';
import { EMultiSelectorValueType } from '../../../../../../domain/model/enums';
import { ContentTargetViewComponent } from '../../types';

const attribute: keyof ContentTarget = 'orgUnits';

const ContentTargetViewOrgUnits: ContentTargetViewComponent = props => {
  const { target } = props;

  const names: string[] = [];

  const targetOrgUnits = target[attribute];

  if (targetOrgUnits) {
    if (targetOrgUnits.select === EMultiSelectorValueType.All) {
      names.push('все филиалы');
    } else {
      if (targetOrgUnits.in?.length) {
        names.push(...targetOrgUnits.in.map(orgUnit => orgUnit.name));
      }
    }
  }

  return (
    <>
      <Typography color='textSecondary'>Филиалы ОАО «РЖД»</Typography>
      <Typography>{names.join(', ') || '-'}</Typography>
    </>
  );
};

export default ContentTargetViewOrgUnits;
