import { Tabs as MuiTabs } from '@mui/material';
import styled from '@emotion/styled';

export const Tabs = styled(MuiTabs)`
  background: ${p => p.theme.palette.background.default};

  & .MuiTabs-flexContainer {
    border-top: 0;
  }
`;
