import { partition } from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../data/store/store';
import { EOrderPartition, ESortDirection } from '../../../../../domain/model/enums';
import { BookingOrder } from '../../../../../domain/model/order';
import { addSearchParamToLocation } from '../../../../../routing/globalRouting';
import { PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import { EOrderUrlParam, getOrderCreateRoute } from '../../../order/entry';
import { useBookingOrderActions } from '../../actions/useAction';
import { EBookingOrderTableColumn, EBookingOrderTableTab } from '../../types';
import { BookingOrdersFilterEditStrategy, BookingOrdersFilterValues, EBookingOrdersFilterItem } from '../filterUtils';
import { bookingOrdersFilterSelector, bookingOrdersGuidSelector, bookingOrdersSortSelector } from '../store/selectors';
import {
  bookingOrdersDataReset,
  bookingOrdersNeedRefreshWatcherIncrement,
  bookingOrdersSetFilter,
  bookingOrdersSetPage,
  bookingOrdersSetPageSize,
  bookingOrdersSetSort,
  bookingOrdersSortReset,
} from '../store/slice';
import { getBookingOrderStatusesByTableTab } from '../utils';
import { useContextConfig } from './useContextConfig';

export type UseBookingOrderTable = {
  readonly onRefresh: () => void;
  readonly onResetSort: () => void;

  readonly onChangeTab: (newTab: EBookingOrderTableTab) => void;
  readonly onChangeSort: (name: EBookingOrderTableColumn, direction: ESortDirection) => void;
  readonly onChangeFilter: (strategies: BookingOrdersFilterEditStrategy[]) => void;
  readonly onChangePage: (num: number) => void;
  readonly onChangePageSize: (size: PaginationSize) => void;
  readonly onCreateOrder: () => void;
  readonly onSortReset: () => void;
  readonly onClearFilter: () => void;

  readonly onOrderTryCancel: (props: BookingOrder) => void;
  readonly onOrderConfirm: (props: BookingOrder) => void;
  readonly onOrderRenew: (props: BookingOrder) => void;
  readonly onOrderGive: (props: BookingOrder) => void;
  readonly onOrdersTryDownload: () => void;
};

type UseBookingOrderTableHandlersProps = {
  readonly tab: EBookingOrderTableTab;
};

export const useBookingOrderTableHandlers = ({ tab }: UseBookingOrderTableHandlersProps): UseBookingOrderTable => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { partnerId, customerId } = useContextConfig();

  const { onTryCancel, onConfirm, onRenew, onGive, onTryDownload } = useBookingOrderActions();

  /**
   * Получение объекта filter из стора
   */
  const getBookingOrderFilterFromState = useCallback((): BookingOrdersFilterValues => {
    const state = store.getState();
    return bookingOrdersFilterSelector(state);
  }, [store]);

  /**
   * Получение объекта sort из стора
   */
  const getBookingOrderSortFromState = useCallback((): string => {
    const state = store.getState();
    return bookingOrdersSortSelector(state);
  }, [store]);

  const getBookingOrderGuidFromState = useCallback((): Nullable<UUID> => {
    const state = store.getState();
    return bookingOrdersGuidSelector(state);
  }, [store]);

  const onCreateOrder = useCallback(() => {
    history.push(getOrderCreateRoute({ partition: EOrderPartition.BookingOffers }));
  }, [history]);

  const onRefresh = useCallback(() => dispatch(bookingOrdersNeedRefreshWatcherIncrement()), [dispatch]);
  const onResetSort = useCallback(() => dispatch(bookingOrdersSortReset()), [dispatch]);

  const onChangeFilter = useCallback(
    (strategies: BookingOrdersFilterEditStrategy[]) => {
      dispatch(
        bookingOrdersSetFilter({
          filter: getDataFilterValuesByStrategies<EBookingOrdersFilterItem, BookingOrdersFilterValues>(strategies),
        })
      );
    },
    [partition]
  );

  const onChangePage = useCallback(
    (pageNumber: number) => {
      dispatch(bookingOrdersSetPage({ pageNumber }));
    },
    [dispatch]
  );

  const onChangeSort = useCallback(
    (name: EBookingOrderTableColumn, direction: ESortDirection) => {
      dispatch(
        bookingOrdersSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(bookingOrdersSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onSortReset = useCallback(() => {
    dispatch(bookingOrdersSortReset());
  }, [dispatch]);

  const onClearFilter = useCallback(() => {
    dispatch(bookingOrdersSetFilter({ filter: {} }));
  }, [partition]);

  const onChangeTab = useCallback(
    (newTab: EBookingOrderTableTab) => {
      if (newTab !== tab) {
        const guid = getBookingOrderGuidFromState();
        dispatch(bookingOrdersDataReset());
        history.replace(
          addSearchParamToLocation({
            location,
            param: EOrderUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [dispatch, history, tab, location, getBookingOrderGuidFromState]
  );
  const onOrderTryCancel = useCallback<UseBookingOrderTable['onOrderTryCancel']>(onTryCancel, [onTryCancel]);

  const onOrderConfirm = useCallback<UseBookingOrderTable['onOrderConfirm']>(onConfirm, [onConfirm]);

  const onOrderRenew = useCallback<UseBookingOrderTable['onOrderRenew']>(onRenew, [onRenew]);

  const onOrderGive = useCallback<UseBookingOrderTable['onOrderGive']>(onGive, [onGive]);

  const onOrdersTryDownload = useCallback<UseBookingOrderTable['onOrdersTryDownload']>(() => {
    const filter = getBookingOrderFilterFromState();
    const sort = getBookingOrderSortFromState();
    const states = getBookingOrderStatusesByTableTab(tab);

    onTryDownload({
      id: null,
      skipPageable: true,
      sort,
      filter,
      states,
      partnerId,
      customerId,
    });
  }, [tab, onTryDownload]);

  return {
    onRefresh,
    onResetSort,
    onChangeTab,
    onChangeSort,
    onChangeFilter,
    onChangePage,
    onChangePageSize,
    onSortReset,
    onClearFilter,
    onCreateOrder,
    onOrderTryCancel,
    onOrderConfirm,
    onOrderRenew,
    onOrderGive,
    onOrdersTryDownload,
  };
};
