import {
  alpha,
  autocompleteClasses,
  backdropClasses,
  buttonBaseClasses,
  buttonClasses,
  checkboxClasses,
  inputLabelClasses,
  outlinedInputClasses,
  paginationItemClasses,
  stepLabelClasses,
  switchClasses,
  tabClasses,
  tabsClasses,
  toggleButtonGroupClasses,
  touchRippleClasses,
  typographyClasses,
} from '@mui/material';
import { MPTheme } from '../types';
import { viewConfig } from '../viewConfig';
import { pseudoClasses } from '../constants';

export function applyOverrides(theme: MPTheme): MPTheme {
  return {
    ...theme,
    components: {
      ...theme.components,
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            minWidth: viewConfig.viewport,
            fontSize: theme.typography.body2.fontSize,
            fontWeight: theme.typography.body2.fontWeight,
            lineHeight: theme.typography.body2.lineHeight,
          },
          img: {
            outline: 'none',
            border: 'none',
          },
          '::-webkit-scrollbar': {
            width: '.5rem',
            height: '.5rem',
          },
          '::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.3)',
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: `${theme.scrollBar.background}`,
            outline: 'none',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          gutterBottom: {
            marginBottom: '.75rem',
          },
          subtitle2: {
            [`&.${typographyClasses.gutterBottom}`]: {
              marginBottom: '.5rem',
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            border: `1px solid ${theme.palette.secondary.main}`,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            width: 'auto',
          },
          paper: {
            width: 'auto',
          },
          modal: {
            [`& .${backdropClasses.root}`]: {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            [`& .${tabsClasses.flexContainer}`]: {
              borderBottom: `1px solid ${theme.palette.secondary.main}`,
            },
          },
          indicator: {
            height: 1,
            backgroundColor: theme.palette.primary.main,
          },
          vertical: {
            width: 'auto',
            fontWeight: theme.typography.fontWeightRegular,

            [`& .${tabsClasses.flexContainer}`]: {
              paddingLeft: theme.spacing(1.5),
              borderBottom: 'none',
            },
            [`& .${tabClasses.root}`]: {
              width: 'auto',
              maxWidth: 'unset',
              minHeight: '2rem',

              margin: theme.spacing(0.5, 0),
              padding: theme.spacing(0.75, 0),

              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              fontWeight: theme.typography.fontWeightRegular,

              opacity: 1,
              '&:hover': {
                opacity: 0.7,
              },
            },
            [`& .${tabClasses.textColorPrimary}`]: {
              color: theme.palette.primary.main,

              [`&.${pseudoClasses.selected}`]: {
                color: theme.palette.text.primary,
              },
            },
            [`& .${tabsClasses.indicator}`]: {
              width: '3px',
              borderRadius: '4px',
              left: 0,
              right: 'auto',
            },
          },
        },
      },
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            paddingLeft: 0,
            paddingRight: 0,
            lineHeight: 1.35,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: theme.typography.fontWeightSemiBold,
            textTransform: 'none',

            [`&.${tabClasses.root}`]: {
              minWidth: 'unset',
              marginRight: theme.spacing(2),

              '&:last-child': {
                marginRight: 0,
              },
              '&:hover': {
                color: theme.palette.primary.main,
              },
            },
            [`&.${tabClasses.textColorPrimary}`]: {
              color: theme.palette.text.primary,

              [`&.${pseudoClasses.selected}`]: {
                color: theme.palette.primary.main,
              },
            },
          },
          textColorPrimary: {
            [`&.${pseudoClasses.disabled}`]: {
              color: theme.palette.disabled.text,
            },
            [`& .${touchRippleClasses.rippleVisible}`]: {
              color: theme.palette.primary.alpha40,
            },
          },
          fullWidth: {
            flexGrow: 0,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontSize: theme.typography.body1.fontSize,
            height: theme.spacing(3.25),
          },
          colorPrimary: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.highlight,
          },
          deleteIconColorPrimary: {
            fontSize: theme.spacing(2.2),
            color: theme.palette.secondary.main,
            '&:hover': {
              color: theme.palette.primary.main,
            },
          },
          deleteIconColorSecondary: {
            fontSize: theme.spacing(2.2),
          },
          clickable: {
            '&:hover': {
              backgroundColor: alpha(theme.palette.highlight, 0.9),
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            height: '3.125rem',
            textTransform: 'none',
            fontSize: theme.typography.body1.fontSize,
            fontWeight: theme.typography.fontWeightSemiBold,

            paddingLeft: theme.spacing(2.5),
            paddingRight: theme.spacing(2.5),
          },

          contained: {
            boxShadow: 'none',

            '&:hover': {
              boxShadow: 'none',
            },
            '&:focus': {
              boxShadow: 'none',
            },

            [`&.${buttonClasses.containedSecondary}`]: {
              color: `${theme.palette.text.primary}`,

              [`&.${pseudoClasses.disabled}`]: {
                color: theme.palette.disabled.text,
              },
            },
          },
          outlined: {
            [`&.${buttonClasses.outlinedPrimary}`]: {
              '&:hover': {
                backgroundColor: theme.palette.highlight,
              },
            },

            [`&.${buttonClasses.outlinedSecondary}`]: {
              border: `1px solid ${theme.palette.secondary}`,
              color: `${theme.palette.text.primary}`,

              [`&.${pseudoClasses.disabled}`]: {
                color: theme.palette.disabled.text,
              },
            },
          },
          text: {
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
          },
          textSizeSmall: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
          },
          sizeSmall: {
            height: '1.5rem',
            fontSize: theme.typography.body2.fontSize,

            padding: theme.spacing(0.375, 1.125),
          },
          sizeLarge: {
            height: '3.875rem',
            fontSize: theme.typography.body1.fontSize,
          },
          textSecondary: {
            color: theme.palette.text.primary,
            '&:hover': {
              backgroundColor: theme.palette.secondary.light,
            },
          },
          outlinedSizeSmall: {
            padding: theme.spacing(0.375, 1.125),
          },
          outlinedSizeMedium: {
            padding: theme.spacing(0.625, 1.875),
          },
          containedSizeSmall: {
            padding: theme.spacing(0.5, 1.25),
          },
          startIcon: {
            marginLeft: 0,
            marginRight: theme.spacing(0.5),
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: theme.typography.body1.fontSize,
          },
          head: {
            color: theme.palette.text.disabled,
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            fontWeight: theme.typography.fontWeightSemiBold,
            color: theme.palette.disabled.text,
          },
          alternativeLabel: {
            fontWeight: 'inherit',
          },
          labelContainer: {
            fontWeight: theme.typography.fontWeightSemiBold,
            cursor: 'inherit',
          },
          active: {
            color: theme.palette.text.primary,
            fontWeight: 'inherit',
          },
          vertical: {
            padding: 0,
          },
          iconContainer: {
            cursor: 'text',
          },
          completed: {
            cursor: 'pointer',
            color: theme.palette.text.primary,
          },
        },
      },
      MuiFilledInput: {
        defaultProps: {
          disableUnderline: true,
        },
        styleOverrides: {
          input: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'filled',
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: theme.typography.body1.fontSize,
          },

          outlined: {
            transform: `translate(.8rem, 0.95rem) scale(1)`,

            [`&.${inputLabelClasses.shrink}`]: {
              transform: `translate(.8rem, -.45rem) scale(.8)`,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            [`&:not(.${pseudoClasses.disabled}):hover .${outlinedInputClasses.notchedOutline}`]: {
              border: `1px solid ${theme.palette.primary.main}`,
            },

            [`&.${pseudoClasses.error}:not(.${pseudoClasses.disabled}):hover .${outlinedInputClasses.notchedOutline}`]:
              {
                borderColor: theme.palette.error.dark,
              },

            [`&.${pseudoClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
              borderWidth: 1,
            },

            [`&.${pseudoClasses.disabled} .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.secondary.main,
            },

            [`&.${pseudoClasses.disabled}`]: {
              textFillColor: theme.palette.disabled.text,

              backgroundColor: theme.palette.disabled.background,
            },
          },
          input: {
            paddingTop: 0,
            paddingBottom: 0,
          },
          multiline: {
            paddingTop: theme.spacing(1.3125),
            paddingBottom: theme.spacing(1.3125),
          },
          notchedOutline: {
            borderColor: theme.palette.secondary.main,
            transition: 'border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '1.5rem',
          },
          fontSizeSmall: {
            fontSize: '1rem',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          elevation1: {
            boxShadow: '3px 3px 3px #bbb',
            borderRadius: 0,
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: theme.spacing(1.5),
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: theme.spacing(1.5),
            '&:last-child': {
              paddingBottom: theme.spacing(1.5),
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          padding: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          button: {
            '&:hover': {
              backgroundColor: theme.palette.highlight,
            },
            [`&.${pseudoClasses.selected}`]: {
              backgroundColor: theme.palette.highlight,

              '&:hover': {
                backgroundColor: theme.palette.highlight,
              },
            },

            [`& .${touchRippleClasses.rippleVisible}`]: {
              color: theme.palette.primary.alpha40,
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 'unset',
            marginRight: theme.spacing(1.5),
          },
        },
      },
      MuiListItemAvatar: {
        styleOverrides: {
          root: {
            minWidth: '2.2rem',
          },
        },
      },
      MuiListItemSecondaryAction: {
        styleOverrides: {
          root: {
            display: 'flex',
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          ul: {
            [`& .${buttonBaseClasses.root}`]: {
              minWidth: '1.5rem',
              height: '1.5rem',
              fontSize: theme.typography.body2.fontSize,
            },
            [`& .${paginationItemClasses.textSecondary}`]: {
              color: theme.palette.secondary.main,
            },
            [`& .${paginationItemClasses.textSecondary}.${pseudoClasses.selected}`]: {
              color: theme.palette.primary.main,
              background: 'none',
            },

            [`& .${paginationItemClasses.textPrimary}`]: {
              color: theme.palette.text.primary,
              border: `1px solid ${theme.palette.secondary.alpha10}`,

              '&:hover': {
                backgroundColor: theme.palette.highlight,
              },

              [`& .${touchRippleClasses.rippleVisible}`]: {
                color: theme.palette.primary.alpha40,
              },

              [`&.${paginationItemClasses.ellipsis}`]: {
                background: 'none',
                border: 'none',
              },
            },
            [`& .${paginationItemClasses.textPrimary}.${pseudoClasses.selected}`]: {
              color: theme.palette.text.primary,
              background: theme.palette.primary.alpha75,
              border: 'none',
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            height: '1.875rem',
            lineHeight: '1rem',

            textTransform: 'none',
            fontSize: theme.typography.body1.fontSize,

            [`& .${touchRippleClasses.rippleVisible}`]: {
              color: theme.palette.primary.alpha40,
            },

            [`&.${toggleButtonGroupClasses.groupedHorizontal}:not(:first-of-type)`]: {
              '&:hover': {
                borderColor: theme.palette.primary.main,
              },
            },

            color: theme.palette.text.primary,
            borderColor: theme.palette.secondary.main,
            transition:
              'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

            '&:hover': {
              backgroundColor: theme.palette.highlight,
              borderColor: theme.palette.primary.main,
            },

            [`&.${pseudoClasses.selected}`]: {
              color: theme.palette.text.onPrimary,
              backgroundColor: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,

              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              },

              [`&.${pseudoClasses.disabled}`]: {
                color: theme.palette.text.onPrimary,
                backgroundColor: theme.palette.primary.alpha40,
                borderColor: 'transparent',
              },
            },
          },
          sizeSmall: {
            height: '1.5rem',

            fontSize: theme.typography.body2.fontSize,
          },
          sizeLarge: {
            height: '3.125rem',

            fontSize: theme.typography.body1.fontSize,
          },
        },
      },

      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
      },

      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '0.8125rem',
            whiteSpace: 'pre-line',
            marginTop: `${theme.spacing(1)}`,
          },
          contained: {
            marginLeft: 0,
            marginRight: 0,
          },
        },
      },

      MuiAvatar: {
        styleOverrides: {
          root: {
            transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            backgroundColor: theme.palette.secondary.main,
          },
          colorDefault: {
            color: theme.palette.secondary.dark,
            backgroundColor: theme.palette.secondary.main,
          },
        },
      },

      MuiSwitch: {
        styleOverrides: {
          root: {
            width: '52px',
            height: '34px',

            padding: theme.spacing(),
          },
          thumb: {
            width: '12px',
            height: '12px',

            boxShadow: 'unset',
          },
          track: {
            borderRadius: (theme.shape.borderRadius as number) * 3,
          },
          switchBase: {
            top: 2,
            left: 2,

            [`&.${pseudoClasses.checked}`]: {
              top: 2,
              left: 2,
              transform: 'translateX(17px)',
            },
            [`&.${pseudoClasses.checked} + .${switchClasses.track}`]: {
              opacity: 1,
            },
          },
          colorPrimary: {
            [`&.${pseudoClasses.checked}`]: {
              color: theme.palette.background.default,
            },
            [`&.${pseudoClasses.checked} + .${switchClasses.track}`]: {
              backgroundColor: theme.palette.success.main,
            },
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            minHeight: '3.125rem',
          },
          input: {
            [`&.${pseudoClasses.disabled}`]: {
              cursor: 'not-allowed',
            },
          },
        },
      },

      MuiFormControl: {
        styleOverrides: {
          marginDense: {
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },

      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            marginLeft: '-.5rem',
          },

          labelPlacementStart: {
            marginRight: '-.5rem',
          },
        },
      },

      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: `${theme.spacing(2)}`,
          },
        },
      },

      MuiSelect: {
        styleOverrides: {
          select: {
            '&:focus': {
              backgroundColor: theme.palette.background.default,
            },
          },
        },
      },

      MuiCheckbox: {
        styleOverrides: {
          colorPrimary: {
            color: theme.palette.secondary.main,
          },
          colorSecondary: {
            color: theme.palette.secondary.main,
          },
        },
      },

      MuiRadio: {
        styleOverrides: {
          root: {
            padding: '.375rem',
          },

          colorPrimary: {
            color: theme.palette.secondary.main,
          },
          colorSecondary: {
            color: theme.palette.secondary.main,
          },
        },
      },

      MuiStep: {
        styleOverrides: {
          vertical: {
            [`& .${stepLabelClasses.label}.${pseudoClasses.active}`]: {
              fontWeight: theme.typography.fontWeightSemiBold,
              cursor: 'pointer',
            },
            [`& .${stepLabelClasses.label}.${pseudoClasses.completed}`]: {
              fontWeight: theme.typography.fontWeightSemiBold,
              color: theme.palette.primary.main,
              cursor: 'pointer',
            },
          },
        },
      },

      MuiStepper: {
        styleOverrides: {
          vertical: {
            display: 'flex',
            flex: 1,
            gap: theme.spacing(1),
          },
        },
      },

      MuiStepConnector: {
        styleOverrides: {
          line: {
            borderColor: 'inherit',
            borderLeftWidth: '2px',
          },
          vertical: {
            [`&.${pseudoClasses.active}`]: {
              borderColor: theme.palette.disabled.text,
            },
            [`&.${pseudoClasses.disabled}`]: {
              borderColor: theme.palette.disabled.text,
            },
          },
          lineVertical: {
            minHeight: theme.spacing(2),
            borderLeftWidth: '2px',
            borderColor: 'inherit',
            marginLeft: '3px',
          },
          active: {
            borderColor: theme.palette.primary.main,
          },
          completed: {
            borderColor: theme.palette.primary.main,
          },
        },
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: theme.palette.highlight,
            color: theme.palette.common.black,
          },
          arrow: {
            color: theme.palette.highlight,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            [`&.${pseudoClasses.focused}`]: {
              [`& .${autocompleteClasses.inputRoot} .${autocompleteClasses.input}`]: {
                width: '100%',
              },
            },
            [`.${outlinedInputClasses.root} .${autocompleteClasses.input}`]: {
              padding: theme.spacing(0.3125, 0.5),
            },
          },
          option: {
            [`&:hover, &:active, &.${pseudoClasses.focused}, &[data-focus="true"]`]: {
              backgroundColor: theme.palette.highlight,
            },
            [`& .${checkboxClasses.root}`]: {
              padding: theme.spacing(0.3),
              marginRight: theme.spacing(0.5),
            },
          },
          listbox: {
            padding: 0,
          },
          popupIndicator: {
            padding: theme.spacing(0.5),
          },
        },
      },
    },
  };
}
