import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TradeOffer } from '../../../../../domain/model/tradeOffer';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { NumberRange } from '../../../../types';
import { EOfferTableTab } from '../../../general/offer/types';
import { tradeOffersSearchGet, tradeOffersSearchGuidSelector } from '../store/selectors';
import { tradeOffersSearchSetTab, tradeOffersSearchStartSession } from '../store/slice';

interface UseTradeOffersSearchSessionSingleProps {
  readonly initialSelected: Nullable<TradeOffer>;
  readonly selectedMaxCount: 1;
}

interface UseTradeOffersSearchSessionMultipleProps {
  readonly initialSelected: Nullable<TradeOffer[]>;
  readonly selectedMaxCount?: NumberRange<2, 100> | false;
}

type UseTradeOffersSearchSessionProps = {
  readonly guid: UUID;
  readonly tab: EOfferTableTab;
} & (UseTradeOffersSearchSessionSingleProps | UseTradeOffersSearchSessionMultipleProps);

const useTradeOffersSearchSession = (props: UseTradeOffersSearchSessionProps): void => {
  const { guid, tab } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(tradeOffersSearchGuidSelector);
  const { tab: tableTab } = useSelector(tradeOffersSearchGet);

  // инициализация сеанса
  useEffect(() => {
    const selected: TradeOffer[] = [];
    if (props.initialSelected) {
      switch (props.selectedMaxCount) {
        case 1:
          selected.push(props.initialSelected);
          break;
        default:
          selected.push(...props.initialSelected);
          break;
      }
    }
    if (guid !== currentGuid) {
      dispatch(tradeOffersSearchStartSession({ guid, selected }));
    }
  }, [dispatch, guid, currentGuid, props.initialSelected, props.selectedMaxCount]);

  // изменение вкладки
  useEffect(() => {
    if (tab && tab !== tableTab) {
      dispatch(tradeOffersSearchSetTab({ tab }));
    }
  }, [dispatch, tab, tableTab]);
};

export default useTradeOffersSearchSession;
