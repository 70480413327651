import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Nullable } from '../../../../../domain/model/types';
import { BookingOfferEditFieldServiceStatus } from '../../components/fieldsEdit/service/status';
import { BookingOfferEditFieldServiceProps } from '../../components/fieldsEdit/types';
import { ServiceStatusWrapper } from '../controls';

const fieldName = 'status';

type BookingOfferServiceManageFieldServiceStatusProps = BookingOfferEditFieldServiceProps<typeof fieldName> & {
  readonly nameService: Nullable<string>;
};

export const BookingOfferServiceManageFieldServiceStatus = (
  props: BookingOfferServiceManageFieldServiceStatusProps
) => {
  const { index, onChangeAttribute, onValidateAttribute, value, validation, nameService, disabled } = props;

  return (
    <Grid
      container
      alignItems={'center'}
    >
      <Grid
        item
        xs={7}
      >
        <Typography variant={'subtitle2'}>
          {index + 1}. {nameService}
        </Typography>
      </Grid>
      <Grid
        item
        xs={5}
      >
        <ServiceStatusWrapper>
          <Typography>Отображать услугу</Typography>
          <BookingOfferEditFieldServiceStatus
            index={index}
            value={value}
            disabled={disabled}
            onChangeAttribute={onChangeAttribute}
            onValidateAttribute={onValidateAttribute}
            validation={validation}
          />
        </ServiceStatusWrapper>
      </Grid>
    </Grid>
  );
};
