import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../../data/api';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../../data/store/types';
import { SportOption } from '../../../../../../domain/model';
import { FriendlyMatch, FriendlyMatchResults, FriendlyMatchTeamResults } from '../../../../../../domain/model/event';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import { eventEditSave } from '../../../edit/legacy/store/slice';

export const friendlyMatchViewFetch = createAsyncThunk<FriendlyMatch, { id: UUID }, AppThunkAPIConfig>(
  'events/friendlyMatch/view/fetch',
  async ({ id }, { rejectWithValue, signal }) => {
    try {
      const { data } = await Api.event.friendlyMatch.one({ id, signal });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const friendlyMatchViewSetResults = createAsyncThunk<
  void,
  { id: UUID; teamResults: FriendlyMatchTeamResults[] },
  AppThunkAPIConfig
>('events/friendlyMatch/view/setResults', async ({ id, teamResults }, { rejectWithValue }) => {
  try {
    await Api.event.friendlyMatch.setResults({ id, friendlyMatchResults: { teamResults } });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});
export const friendlyMatchViewCancelEvent = createAsyncThunk<void, { id: UUID }, AppThunkAPIConfig>(
  'events/friendlyMatch/view/cancel',
  async ({ id }, { rejectWithValue }) => {
    try {
      await Api.event.cancel({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface FriendlyMatchViewState {
  readonly byId: Fetchable & {
    readonly data: Nullable<FriendlyMatch>;
    readonly needRefresh: boolean;
    readonly validationErrors: {
      [field: string]: any;
    };
  };
  readonly setResults: Fetchable;
  readonly cancel: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<FriendlyMatchViewState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<FriendlyMatchViewState> {
  friendlyMatchViewResetState: Reducer;
  friendlyMatchViewSetTeamScore: Reducer<{ team: SportOption; score: Nullable<number> }>;
  friendlyMatchViewSetValidationErrors: Reducer<{ errors: { [field: string]: any } }>;
}

const slice = createSlice<FriendlyMatchViewState, Reducers, 'friendlyMatch/view'>({
  name: 'friendlyMatch/view',
  initialState: {
    byId: {
      ...fetchableDefault,
      data: null,
      needRefresh: false,
      validationErrors: {},
    },
    setResults: {
      ...fetchableDefault,
    },
    cancel: {
      ...fetchableDefault,
    },
  },
  reducers: {
    friendlyMatchViewResetState: state => {
      state.byId = {
        ...fetchableDefault,
        data: null,
        needRefresh: false,
        validationErrors: {},
      };
      state.setResults = {
        ...fetchableDefault,
      };
    },
    friendlyMatchViewSetTeamScore: (state, { payload }) => {
      const { team, score } = payload;
      if (state.byId.data) {
        const { id, name, logo } = state.byId.data.organizerTeam!;
        const organizerTeam: SportOption = { id, name, image: logo?.image?.path };
        const rivalTeam: SportOption = state.byId.data.candidates[0].team;

        const [
          suggestedResult = {
            suggestingTeam: organizerTeam,
            teamResults: [
              { team: organizerTeam, score: null },
              { team: rivalTeam, score: null },
            ],
          },
        ] = state.byId.data.suggestedResults ?? [];
        const newSuggestedResult: FriendlyMatchResults = {
          ...suggestedResult,
          teamResults: suggestedResult.teamResults.map(teamResult =>
            teamResult.team.id !== team.id
              ? teamResult
              : {
                  ...teamResult,
                  score,
                }
          ),
        };
        state.byId.data.suggestedResults = [newSuggestedResult];
      }
    },
    friendlyMatchViewSetValidationErrors: (state, { payload }) => {
      const { errors } = payload;
      state.byId.validationErrors = errors;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(friendlyMatchViewFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.data = null;
        state.byId.validationErrors = {};
        state.setResults = fetchableDefault;
      })
      .addCase(friendlyMatchViewFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;
        state.byId.data = payload;
        state.byId.needRefresh = false;
      })
      .addCase(friendlyMatchViewFetch.rejected, state => {
        state.byId.isFetching = false;
        state.byId.isFetched = false;
        state.byId.isFailed = true;
        state.byId.data = null;
      })
      .addCase(friendlyMatchViewSetResults.pending, state => {
        state.setResults.isFetching = true;
        state.setResults.isFetched = false;
        state.setResults.isFailed = false;
      })
      .addCase(friendlyMatchViewSetResults.fulfilled, state => {
        state.setResults.isFetching = false;
        state.setResults.isFetched = true;
        state.setResults.isFailed = false;
        state.byId.needRefresh = true;
      })
      .addCase(friendlyMatchViewSetResults.rejected, state => {
        state.setResults.isFetching = false;
        state.setResults.isFetched = false;
        state.setResults.isFailed = true;
      })
      .addCase(friendlyMatchViewCancelEvent.pending, state => {
        state.cancel.isFetching = true;
        state.cancel.isFetched = false;
        state.cancel.isFailed = false;
      })
      .addCase(friendlyMatchViewCancelEvent.fulfilled, state => {
        state.cancel.isFetching = false;
        state.cancel.isFetched = true;
        state.cancel.isFailed = false;
        state.byId.needRefresh = true;
      })
      .addCase(friendlyMatchViewCancelEvent.rejected, state => {
        state.cancel.isFetching = false;
        state.cancel.isFetched = false;
        state.cancel.isFailed = true;
      })
      .addCase(eventEditSave.fulfilled, (state, { payload }) => {
        if (state.byId.data?.id && payload?.data.id === state.byId.data?.id) {
          state.byId.needRefresh = true;
        }
      });
  },
});

export const { friendlyMatchViewResetState, friendlyMatchViewSetTeamScore, friendlyMatchViewSetValidationErrors } =
  slice.actions;

export default slice.reducer;
