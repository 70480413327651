import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;

  padding: ${p => p.theme.spacing(5)};

  width: 100%;
  height: 100%;

  border: 1px solid ${p => p.theme.palette.secondary.main};
  border-radius: ${p => (p.theme.shape.borderRadius as number) * 3}px;
  background-color: ${p => p.theme.palette.background.default};

  display: flex;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    inset: -1px;
    border: 9px dashed ${p => p.theme.palette.background.default};
    border-radius: ${p => (p.theme.shape.borderRadius as number) * 3}px;
    z-index: 0;
  }
`;

export const Label = styled.div`
  position: absolute;
  top: calc(-${p => p.theme.typography.body2.lineHeight} / 2);
  left: ${p => p.theme.spacing(4.5)};

  padding: 0 ${p => p.theme.spacing(0.5)};
  background-color: ${p => p.theme.palette.background.default};

  & .MuiTypography-root {
    font-weight: ${p => p.theme.typography.fontWeightSemiBold};
  }
`;

export const ModeToggler = styled(ToggleButtonGroup)`
  position: absolute;
  top: calc(-${p => p.theme.spacing(2.75)});
  left: calc(100% / 2 - ${p => p.theme.spacing(4.5)});

  background-color: ${p => p.theme.palette.background.default};
  pointer-events: all;
`;

export const ModeButton = styled(ToggleButton)`
  height: 2.25rem;
  width: 2.25rem;

  border-color: ${p => p.theme.palette.secondary.main};

  &:hover {
    border-color: ${p => p.theme.palette.secondary.main} !important;
  }

  &.Mui-selected {
    background-color: ${p => p.theme.palette.secondary.light};
    border-color: ${p => p.theme.palette.secondary.main};

    svg {
      color: ${p => p.theme.palette.primary.main};
      stroke: ${p => p.theme.palette.primary.main};
    }

    &:hover {
      background-color: ${p => p.theme.palette.secondary.light};
    }
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  height: 1.125rem;

  margin-bottom: ${p => p.theme.spacing(0.5)};
`;

export const Title = styled(Typography)`
  font-weight: ${p => p.theme.typography.fontWeightBold};
  line-height: 1.125rem;
`;

export const TitleDesktop = styled(Title)`
  font-size: 0.5625rem;
`;
