import Api from '@/data/api';
import { ApiCancellable, ApiQueryDsl, ApiRequestListDiscriminator, ApiRequestPageable } from '@/data/api/types';
import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '@/data/api/utils';
import {
  AdCampaign,
  AdCampaignCreate,
  AdCampaignShort,
  DataFilterQueryDslOperator,
  EAdCampaignCommandDiscriminator,
  EAdCampaignStatus,
  EAdCampaignType,
  Pageable,
} from '@/domain';
import { PaginationSize } from '@/presentation/types';
import {
  convertAdCampaignCreateToCreateRequest,
  convertAdCampaignCreateToUpdateRequest,
} from '@features/adCampaign/create/utils';
import Axios from 'axios';
import {
  AdCampaignTableFilterValues,
  EAdCampaignTableFilterItem,
  getStatesFilterForAdCampaignTableTab,
} from '../filterUtils';
import { AdCampaignCounterByStatus, AdCampaignTableTabsCounter, EAdCampaignTableTab } from '../types';

type BuildListQueryParamsReturn<D extends ApiRequestListDiscriminator> = ApiCancellable &
  ApiRequestPageable & {
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

export type AdCampaignAllProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string>;
    readonly statuses: EAdCampaignStatus[];
    readonly partnerId: Nullable<UUID>;
  };
  readonly filter: AdCampaignTableFilterValues;
  readonly pageNumber: number;
};

type AloneIDProps = ApiCancellable & {
  readonly id: UUID;
};

type CommandProps = {
  readonly id: UUID;
};

type PauseProps = CommandProps;

type OneProps = AloneIDProps;

type SaveProps = AdCampaignCreate;

export type CountProps = Omit<AdCampaignAllProps, 'pageNumber'>;

type CountsByStatusesProps = Omit<AdCampaignAllProps, 'pageNumber'>;

type CountsByTabsProps = CountsByStatusesProps & {
  readonly tabs: EAdCampaignTableTab[];
};

type AdCampaignServices = {
  readonly one: (props: OneProps) => Promise<AdCampaign>;
  readonly save: (props: SaveProps) => Promise<AdCampaign>;
  readonly all: (props: AdCampaignAllProps) => Promise<Pageable<AdCampaignShort>>;
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AdCampaignAllProps
  ) => BuildListQueryParamsReturn<D>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly countsByStatuses: (
    props: CountsByStatusesProps
  ) => Promise<{ counts: AdCampaignCounterByStatus; errors: Array<string> }>;
  readonly countsByTabs: (
    props: CountsByTabsProps
  ) => Promise<{ counts: AdCampaignTableTabsCounter; errors: Array<string> }>;
  readonly archive: (props: AloneIDProps) => Promise<AdCampaign>;
  readonly resume: (props: AloneIDProps) => Promise<AdCampaign>;
  readonly pause: (props: PauseProps) => Promise<AdCampaign>;
};

const adCampaignServices: AdCampaignServices = {
  buildListQueryParams: props => {
    const { search, filter, pageNumber, signal } = props;
    const { pageSize, sort, statuses, partnerId } = search;

    const querydsl: ApiQueryDsl = [];
    const query = filter[EAdCampaignTableFilterItem.Query]?.value;
    const filterDsl = getQueryDslByDataFilterValues(filter);

    if (filterDsl) {
      querydsl.push(...filterDsl);
    }

    if (partnerId) {
      querydsl.push({
        value: [partnerId],
        field: 'partner.id',
        operator: DataFilterQueryDslOperator.In,
      });
    }

    if (statuses.length > 0) {
      querydsl.push({
        value: statuses,
        field: 'status',
        operator: DataFilterQueryDslOperator.In,
      });
    }

    return {
      query,
      sort,
      signal,
      pageSize,
      page: pageNumber,
      querydsl,
    };
  },
  one: async ({ id, signal }) => {
    return (await Api.adCampaign.one({ id, signal })).data;
  },
  save: async adCampaign => {
    if (!adCampaign.id) {
      const data = convertAdCampaignCreateToCreateRequest(adCampaign);
      return (
        await (adCampaign.type === EAdCampaignType.Catalog
          ? Api.adCampaign.createCatalog({ data })
          : Api.adCampaign.createSearch({ data }))
      ).data;
    } else {
      const data = convertAdCampaignCreateToUpdateRequest(adCampaign);
      return (
        await (adCampaign.type === EAdCampaignType.Catalog
          ? Api.adCampaign.updateCatalog({ id: adCampaign.id, data })
          : Api.adCampaign.updateSearch({ id: adCampaign.id, data }))
      ).data;
    }
  },
  all: async props => {
    const response = await Api.adCampaign.all(adCampaignServices.buildListQueryParams(props));
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);

    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
  count: async ({ ...props }) => {
    const { data: response } = await Api.adCampaign.all({
      ...adCampaignServices.buildListQueryParams({ ...props, pageNumber: 1 }),
      discriminator: ApiRequestListDiscriminator.Count,
    });

    return response[0].count;
  },
  countsByStatuses: async ({ signal, ...props }) => {
    const {
      search: { statuses },
    } = props;

    const errors: string[] = [];
    const counts: AdCampaignCounterByStatus = {};

    const requests = statuses.map(status => {
      const params = {
        ...props,
        search: { ...props.search, statuses: [status] },
      };
      return adCampaignServices.count({ ...params, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], status: EAdCampaignStatus) => {
      if (response.status === 'fulfilled') {
        counts[status] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество рекламных кампаний '${status}': ${response.reason}`);
        }
      }
    };
    statuses.forEach((status, index) => parseResponse(responses[index], status));

    return { counts, errors };
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: AdCampaignTableTabsCounter = {};

    const requests = tabs.map(tab => {
      const statuses = getStatesFilterForAdCampaignTableTab(tab, []);
      const params = {
        ...props,
        search: { ...props.search, statuses },
      };
      return adCampaignServices.countsByStatuses({ ...params, signal });
    });
    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], tab: EAdCampaignTableTab) => {
      if (response.status === 'fulfilled') {
        const statuses = getStatesFilterForAdCampaignTableTab(tab, []);
        counts[tab] = statuses.reduce<number>((prev, current) => prev + (response.value.counts?.[current] ?? 0), 0);
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество рекламных кампаний '${tab}': ${response.reason}`);
        }
      }
    };
    tabs.forEach((tab, index) => parseResponse(responses[index], tab));

    return { counts, errors };
  },
  archive: async ({ id }) => {
    const { data } = await Api.adCampaign.action({
      id,
      discriminator: EAdCampaignCommandDiscriminator.AdCampaignArchiveCommand,
    });
    return data;
  },
  resume: async ({ id }) => {
    const { data } = await Api.adCampaign.action({
      id,
      discriminator: EAdCampaignCommandDiscriminator.AdCampaignRenewCommand,
    });
    return data;
  },
  pause: async ({ id }) => {
    const { data } = await Api.adCampaign.action({
      id,
      discriminator: EAdCampaignCommandDiscriminator.AdCampaignPauseCommand,
    });
    return data;
  },
};

export default adCampaignServices;
