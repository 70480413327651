import { SportOptionTyped } from '@/domain/model';
import { ETradeOfferType } from '@/domain/model/enums';
import TradeActivationTableCell, { TradeActivationTableCellCommonProps } from './index';

export type TradeActivationTableCellPromotionTypeProps = TradeActivationTableCellCommonProps & {
  readonly tradePromotionTypes: SportOptionTyped<ETradeOfferType>[];
};

const TradeActivationTableCellPromotionType = (props: TradeActivationTableCellPromotionTypeProps) => {
  const { tradeActivation, tradePromotionTypes } = props;

  const value = tradePromotionTypes.find(item => item.id === tradeActivation.promotion.type)?.name ?? '-';
  return (
    <TradeActivationTableCell
      {...props}
      value={value}
    />
  );
};

export default TradeActivationTableCellPromotionType;
