import { StyledSplitter } from './controls';

interface SplitterProps {
  /**
   * number - на базе spacing
   * string - произвольный
   */
  readonly size?: number | string;
  readonly variant?: 'vertical' | 'horizontal';
}

/** Сплиттер контента */
const Splitter: (props: SplitterProps) => JSX.Element = ({ size = 2, variant = 'horizontal' }) => (
  <StyledSplitter
    size={size}
    variant={variant}
  />
);

export default Splitter;
