import { EPartnerDiscriminator } from '../../../../../domain/model/enums';
import PartnerTableCell, { PartnerTableCellCommonProps } from './index';

const PartnerTableCellOgrn = (props: PartnerTableCellCommonProps) => {
  const {
    partner: { regInfo },
  } = props;

  const value =
    (regInfo?.discriminator === EPartnerDiscriminator.PartnerCompanyData ? regInfo.ogrn : regInfo?.ogrnip) ?? '-';

  return (
    <PartnerTableCell
      {...props}
      value={value}
    />
  );
};

export default PartnerTableCellOgrn;
