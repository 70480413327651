import { Location } from 'history';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { UUID } from '../../../domain/model/types';
import rootRouting from '../../../routing';
import NotFoundScreen from '../../screens/notFound';
import PartnerScreen from '../../screens/partner';
import PartnerEditScreen from '../../screens/partnerEdit';
import PartnerRegistrationScreen from '../../screens/partnerRegistration';
import PartnersScreen from '../../screens/partners';
import { EPartnerStep, EPartnerTableTab } from '../general/partner/types';
import PartnerWindowEntry, { getPartnerWindowRootRouteDefinition } from '../partnerWindow/entry';
import { EPartnerDetailsLegalTab, EPartnerDetailsTab } from './details/utils';
import PartnerManagementEntry, { getPartnerManagementRootRouteDefinition } from './management/entry';
import { EPartnersTableUrlParam } from './table/utils';

const routing = {
  root: `${rootRouting.partners}`,
  list: `${rootRouting.partners}`,
  create: `${rootRouting.partners}/create`,
  details: `${rootRouting.partners}/:id`,
  edit: `${rootRouting.partners}/:id/edit`,
};

export enum EPartnerUrlParam {
  Tab = 'tab',
  LegalTab = 'legalTab',
  Step = 'step',
}

export interface PartnersLocationState {
  readonly guid: UUID;
}

interface GetPartnersTableRouteProps {
  readonly tab?: EPartnerTableTab;
  readonly guid?: UUID;
}

interface GetPartnerEditRouteProps {
  readonly id: UUID;
  readonly step?: EPartnerStep;
}

interface GetPartnerCreateRouteProps {
  readonly step?: EPartnerStep;
}

interface GetPartnerDetailsRouteProps {
  readonly id: UUID;
  readonly tab?: EPartnerDetailsTab;
  readonly legalTab?: EPartnerDetailsLegalTab;
}

export const getPartnerDetailsRoute = (props: GetPartnerDetailsRouteProps) => {
  const { id, tab, legalTab } = props;

  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.append(EPartnerUrlParam.Tab, tab);
  }

  if (legalTab) {
    searchParams.append(EPartnerUrlParam.LegalTab, legalTab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.details.replace(':id', id)}${search}`;
};

export const getPartnerCreateRoute = ({ step = EPartnerStep.General }: GetPartnerCreateRouteProps) => {
  const searchParams = new URLSearchParams();

  searchParams.append(EPartnerUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.create}${search}`;
};

export const getPartnerEditRoute = ({ id, step = EPartnerStep.General }: GetPartnerEditRouteProps) => {
  const searchParams = new URLSearchParams();
  searchParams.append(EPartnerUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.edit.replace(':id', id)}${search}`;
};

export const getPartnersTableRoute = ({ tab, guid }: GetPartnersTableRouteProps): Location<PartnersLocationState> => {
  const searchParams = new URLSearchParams();
  if (tab) searchParams.append(EPartnersTableUrlParam.Tab, tab);

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: routing.list,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

const PartnersEntry = () => {
  return (
    <Switch>
      <Route
        path={getPartnerManagementRootRouteDefinition()}
        component={PartnerManagementEntry}
      />
      <Route
        path={getPartnerWindowRootRouteDefinition()}
        component={PartnerWindowEntry}
      />
      <Route
        exact
        path={routing.list}
        component={PartnersScreen}
      />
      <Route
        exact
        path={routing.create}
        component={PartnerRegistrationScreen}
      />
      <Route
        exact
        path={routing.edit}
        component={PartnerEditScreen}
      />
      <Route
        exact
        path={routing.details}
        component={PartnerScreen}
      />
      <Redirect
        exact
        from={routing.root}
        to={routing.list}
      />

      <Route component={NotFoundScreen} />
    </Switch>
  );
};

export default PartnersEntry;
