import { ApiCancellable } from '@/data/api/types';
import {
  CmsBannerRequest,
  CmsCollectionRequest,
  CmsComponent,
  CmsComponentData,
  CmsComponentLinked,
  CmsContainer,
  CmsContainerRequest,
  ECmsBannerLinkObjectType,
  ECmsContainerCommandDiscriminator,
  ECmsContainerDiscriminator,
  ECmsContainerStatus,
  ECmsContainerType,
  ECmsLinkObjectType,
  EOfferType,
  EUserGender,
  SportOptionTyped,
} from '@/domain';
import Api from '../../../../data/api';
import {
  generateEmptyCmsObjects,
  getEmptyCmsLinkedObject,
  getEmptyCmsLinkedObjectBanner,
} from '../container/create/utils';
import { CmsFeatureContext } from '../container/types';
import { getCmsContainerTargetItems, getCmsLinkedObjectsLinkToNames } from '../container/utils';
import { CmsContainerTableView, CmsLinkedObject } from '../types';
import { CmsLinkedObjectCollection } from '../types/collection';
import cmsServices from './index';

type ByIdProps = ApiCancellable & {
  readonly id: UUID;
};

type CommandProps = {
  readonly id: UUID;
};

type DuplicateProps = {
  readonly sitePageId: UUID;
  readonly id: UUID;
};

type ChangeSortIndexProps = {
  readonly id: UUID;
  readonly sortIndex: number;
};

type GetLinkedObjectsProps = ApiCancellable & {
  readonly cmsContainer: CmsContainer;
  //игнорировать прогрузку глубоких объектов
  readonly ignoreDeepObjects?: boolean;
};

type GetTableViewProps = ApiCancellable & {
  readonly sortIndex: number;
  readonly cmsContainer: CmsContainer;
  readonly offerTypes: SportOptionTyped<EOfferType>[];
  readonly genderTypes: SportOptionTyped<EUserGender>[];
};

type SaveProps = {
  readonly sitePageId: UUID;
  readonly id?: Nullable<UUID>;
  readonly data: CmsContainerRequest;
};

type DeleteProps = {
  readonly id: UUID;
};

type SaveLinkedObjectsProps = {
  readonly cmsContext?: CmsFeatureContext;
  readonly id: UUID;
  readonly cmsTarget: CmsContainer['target'];
  readonly cmsComponents: CmsComponent[];
  readonly cmsLinkedObjects: CmsLinkedObject[];
};

export type CmsContainerService = {
  readonly byId: (props: ByIdProps) => Promise<CmsContainer>;
  readonly getLinkedObjects: (props: GetLinkedObjectsProps) => Promise<{
    readonly components: CmsComponent[];
    readonly linkedObjects: CmsLinkedObject[];
  }>;
  readonly getTableView: (props: GetTableViewProps) => Promise<CmsContainerTableView>;
  readonly save: (props: SaveProps) => Promise<CmsContainer>;
  readonly saveLinkedObjects: (props: SaveLinkedObjectsProps) => Promise<CmsLinkedObject[]>;
  readonly delete: (props: DeleteProps) => Promise<void>;
  readonly publish: (props: CommandProps) => Promise<CmsContainer>;
  readonly paused: (props: CommandProps) => Promise<CmsContainer>;
  readonly archived: (props: CommandProps) => Promise<CmsContainer>;
  readonly resumed: (props: CommandProps) => Promise<CmsContainer>;
  readonly duplicate: (props: DuplicateProps) => Promise<CmsContainer>;
  readonly changeSortIndex: (props: ChangeSortIndexProps) => Promise<void>;
};

const service: CmsContainerService = {
  byId: async ({ id, signal }) => {
    const { data } = await Api.cms.container.one({ id, signal });
    return data;
  },
  getLinkedObjects: async ({ cmsContainer, ignoreDeepObjects, signal }) => {
    const containerType: ECmsContainerType = cmsContainer.type!.code;

    // компоненты берем из контейнера или формируем пустые
    const components: CmsComponent[] = cmsContainer.components?.length
      ? cmsContainer.components
      : generateEmptyCmsObjects(containerType, cmsContainer.filterSettings.offerType).components;
    const linkedObjects: CmsLinkedObject[] = [];

    // формируем linkedObjects для экранной логики
    for (const cmsComponent of components ?? []) {
      if (!cmsComponent.linkObjectId) {
        linkedObjects.push(
          getEmptyCmsLinkedObject(containerType, cmsComponent.linkObjectType, cmsContainer.filterSettings.offerType)
        );
      } else {
        const cmsComponentLinked = cmsComponent as CmsComponentLinked;
        const cmsComponentData: CmsComponentData = { zone: cmsComponent.zone, pageSize: cmsComponent.pageSize };

        switch (cmsComponent.linkObjectType) {
          case ECmsLinkObjectType.Banner:
            try {
              linkedObjects.push(
                await cmsServices.banner.getLinkedObject({
                  cmsComponent: cmsComponentLinked,
                  ignoreDeepObjects,
                  signal,
                })
              );
            } catch (e: any) {
              console.error('Error at request cms banner info', e);
              linkedObjects.push(getEmptyCmsLinkedObjectBanner());
            }
            break;
          case ECmsLinkObjectType.Collection:
            const cmsLinkedObjectCollectionTyped = await cmsServices.collection.getCollectionByLinkObjectId({
              id: cmsComponent.linkObjectId,
              signal,
            });
            const cmsLinkedObjectCollection: CmsLinkedObjectCollection = {
              type: ECmsLinkObjectType.Collection,
              collection: {
                id: cmsComponent.linkObjectId,
                ...cmsLinkedObjectCollectionTyped,
                ...cmsComponentData,
              },
            };
            linkedObjects.push(cmsLinkedObjectCollection);
            break;
        }
      }
    }

    return { components, linkedObjects };
  },
  getTableView: async ({ sortIndex, cmsContainer, offerTypes, genderTypes, signal }) => {
    const typeName = cmsContainer.type!.name;
    const offerTypeName = offerTypes.find(type => type.id === cmsContainer.filterSettings.offerType)?.name ?? null;

    const { linkedObjects } = await cmsServices.container.getLinkedObjects({
      cmsContainer,
      ignoreDeepObjects: true,
      signal,
    });

    const linkTo = getCmsLinkedObjectsLinkToNames(linkedObjects).join(', ');
    const targetItems = getCmsContainerTargetItems(cmsContainer, { genderTypes });

    return {
      ...cmsContainer,
      linkedObjects,
      sortIndex,
      typeName,
      offerTypeName,
      linkTo,
      targetItems,
    };
  },
  save: async ({ id, data, sitePageId }) => {
    let savedCmsContainer: CmsContainer;
    if (!id) {
      savedCmsContainer = (
        await Api.cms.container.create({
          sitePageId,
          data,
        })
      ).data;
    } else {
      const discriminator =
        data.status === ECmsContainerStatus.Draft
          ? ECmsContainerDiscriminator.ContainerDraftUpdateRequest
          : ECmsContainerDiscriminator.ValidContainerUpdateRequest;
      savedCmsContainer = (
        await Api.cms.container.save({
          id,
          data,
          discriminator,
        })
      ).data;
    }
    return savedCmsContainer;
  },
  saveLinkedObjects: async ({ id, cmsContext, cmsTarget, cmsComponents, cmsLinkedObjects }) => {
    if (cmsComponents.length !== cmsLinkedObjects.length) {
      throw new Error('Обнаружено техническое несоответствие размерности данных cmsComponents и cmsLinkedObjects');
    }

    //сохраняем ресурсы
    const savedCmsLinkedObjects: CmsLinkedObject[] = [...cmsLinkedObjects];
    let index = -1;
    for (const cmsLinkedObject of cmsLinkedObjects) {
      index++;
      switch (cmsLinkedObject.type) {
        case ECmsLinkObjectType.Banner: {
          const cmsComponentData: CmsComponentData = {
            zone: cmsLinkedObject.banner.zone,
            pageSize: cmsLinkedObject.banner.pageSize,
          };

          let bannerToSave: CmsBannerRequest;

          switch (cmsLinkedObject.banner.linkObjectType) {
            case ECmsBannerLinkObjectType.Link: {
              const { linkedObject, ...bannerDataToSave } = cmsLinkedObject.banner;

              bannerToSave = {
                ...bannerDataToSave,
                linkObjectId: null,
                url: linkedObject,
                partner: cmsContext?.partner ? cmsContext.partner : null,
                target: cmsTarget,
              };
              break;
            }
            case ECmsBannerLinkObjectType.TradeOffer:
            case ECmsBannerLinkObjectType.CorpOffer:
            case ECmsBannerLinkObjectType.TradeOfferCategory:
            case ECmsBannerLinkObjectType.CorpOfferCategory:
            case null: {
              const { linkedObject, ...bannerDataToSave } = cmsLinkedObject.banner;

              bannerToSave = {
                ...bannerDataToSave,
                linkObjectId: linkedObject?.id ?? null,
                partner: cmsContext?.partner ? cmsContext.partner : null,
              };
              break;
            }
            case ECmsBannerLinkObjectType.Collection: {
              const { linkedObject, ...bannerDataToSave } = cmsLinkedObject.banner;

              let collectionId = null;

              //сохраняем коллекцию
              if (linkedObject?.linkObjectType) {
                const collectionToSave: CmsCollectionRequest = {
                  linkObjectType: linkedObject.linkObjectType,
                  linkObjectIds: linkedObject.linkedObject?.map(item => item.id) ?? [],
                  partner: cmsContext?.partner ? cmsContext.partner : null,
                };

                const { data: savedCollection } = await Api.cms.collection.save({
                  id: cmsLinkedObject.banner.linkObjectId,
                  data: collectionToSave,
                });
                collectionId = savedCollection.id;
              }

              bannerToSave = {
                ...bannerDataToSave,
                linkObjectId: collectionId,
                partner: cmsContext?.partner ? cmsContext.partner : null,
              };
              break;
            }
          }

          //сохраняем баннер
          const { data: savedBanner } = await Api.cms.banner.save({
            id: cmsLinkedObject.banner.id,
            data: bannerToSave,
          });

          //сохраняем компонент
          await Api.cms.component.save({
            cmsContainerId: id,
            id: cmsComponents[index].id,
            data: {
              ...cmsComponents[index],
              ...cmsComponentData,
              linkObjectId: savedBanner.id,
            },
          });

          //linkObjectType экранируем, потому что его нельзя присваивать, да и смысла нет
          const { linkObjectType, ...savedBannerProps } = savedBanner;
          const newBanner: typeof cmsLinkedObject = {
            ...cmsLinkedObject,
            banner: {
              ...cmsLinkedObject.banner,
              ...savedBannerProps,
            },
          };
          savedCmsLinkedObjects.splice(index, 1, newBanner);
          break;
        }
        case ECmsLinkObjectType.Collection: {
          const { collection } = cmsLinkedObject;
          const cmsComponentData: CmsComponentData = {
            zone: cmsLinkedObject.collection.zone,
            pageSize: cmsLinkedObject.collection.pageSize,
          };

          //сохраняем коллекцию
          if (collection.linkObjectType) {
            const collectionToSave: CmsCollectionRequest = {
              linkObjectType: collection.linkObjectType,
              linkObjectIds: collection.linkedObject?.map(item => item.id) ?? [],
              partner: cmsContext?.partner ? cmsContext.partner : null,
            };

            const { data: savedCollection } = await Api.cms.collection.save({
              id: collection.id,
              data: collectionToSave,
            });

            //сохраняем компонент
            await Api.cms.component.save({
              cmsContainerId: id,
              id: cmsComponents[index].id,
              data: {
                ...cmsComponents[index],
                ...cmsComponentData,
                linkObjectId: savedCollection.id,
              },
            });

            //linkObjectType экранируем, потому что его нельзя присваивать, да и смысла нет
            const { linkObjectType, ...savedCollectionProps } = savedCollection;
            const newCollection: typeof cmsLinkedObject = {
              ...cmsLinkedObject,
              collection: {
                ...cmsLinkedObject.collection,
                ...savedCollectionProps,
              },
            };
            savedCmsLinkedObjects.splice(index, 1, newCollection);
          }
          break;
        }
      }
    }

    return savedCmsLinkedObjects;
  },
  publish: async ({ id }) => {
    const { data } = await Api.cms.container.postCommand({
      id,
      command: ECmsContainerCommandDiscriminator.ContainerPublishCommand,
    });
    return data;
  },
  delete: async ({ id }) => {
    await Api.cms.container.delete({ id });
  },
  paused: async ({ id }) => {
    const { data } = await Api.cms.container.postCommand({
      id,
      command: ECmsContainerCommandDiscriminator.ContainerPauseCommand,
    });
    return data;
  },
  archived: async ({ id }) => {
    const { data } = await Api.cms.container.postCommand({
      id,
      command: ECmsContainerCommandDiscriminator.ContainerArchiveCommand,
    });
    return data;
  },
  resumed: async ({ id }) => {
    const { data } = await Api.cms.container.postCommand({
      id,
      command: ECmsContainerCommandDiscriminator.ContainerPublishCommand,
    });
    return data;
  },
  duplicate: async ({ sitePageId, id }) => {
    const { data } = await Api.cms.container.duplicate({
      sitePageId,
      id,
    });
    return data;
  },
  changeSortIndex: async ({ id, sortIndex }) => {
    //ищем sitePage, перемещаем container в коллекции и сохраняем
    const sitePage = await cmsServices.sitePage.byContainerId({ containerId: id });

    if (!sitePage) {
      throw new Error(`Не найдена страница с блоком id=${id}`);
    }

    if (sitePage.containers) {
      /**
       * в странице лежат все контейнеры во всех статусах, поэтому реальное старое и новое место не такое как sortIndex, потому что коллекция никак не отсортирована
       * поэтому мы сначала формируем коллекцию соответствия места на экране и реальную позицию в виде [index: realIndex]
       * далее берем в этой коллекции элемент с индексом sortIndex и на место соответствующее значению realIndex сдвигаем искомый контейнер, учитывая при этом статус
       *
       * Пример 1:
       * задача: нужно сдвинуть элемент с позиции 2 (currentSortIndex) на 3 (sortIndex)
       * данные:
       * 0 - ACTIVE
       * 1 - PAUSED
       * 2 - ACTIVE
       * 3 - ACTIVE
       * 4 - DRAFT
       * массив индексов по статусу movedContainerStatus=ACTIVE следующий: realIndices=[0,2,3]
       * итоговое действие: нужно сдвинуть элемент с позиции 2 (currentSortIndex) на 3 (realIndices[sortIndex - 1])
       */

      const currentSortIndex = sitePage?.containers?.findIndex(c => c.id === id) ?? -1;
      const movedContainerStatus = sitePage.containers.find(c => c.id === id)!.status;

      const realIndices = sitePage.containers.reduce<number[]>((prev, container, index) => {
        if (container.status === movedContainerStatus) {
          return [...prev, index];
        } else {
          return prev;
        }
      }, []);

      const newSortIndex = realIndices[sortIndex - 1];

      if (sitePage.containers) {
        sitePage.containers.splice(newSortIndex, 0, sitePage.containers.splice(currentSortIndex, 1)[0]);
        await cmsServices.sitePage.save({ id: sitePage.id, data: sitePage });
      }
    }
  },
};

export default service;
