import { EOfferHistoryTableTab } from '../../types';

export type UseOfferHistoryTableConfig = {
  readonly tabs: EOfferHistoryTableTab[];
  readonly defaultTab: EOfferHistoryTableTab;
};

export function useOfferHistoryTableConfig(): UseOfferHistoryTableConfig {
  const tabs = [EOfferHistoryTableTab.Status, EOfferHistoryTableTab.Fields];
  const defaultTab = EOfferHistoryTableTab.Status;

  return {
    tabs,
    defaultTab,
  };
}
