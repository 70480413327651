import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import ContentLoader from '../../../../components/common/loader';
import { MPButton } from '../../../../theme/ui-kit/button';
import { getBookingOfferActionsOfferIsProcessingSelector } from '../../actions/store/selectors';
import { ContentWrapper } from '../controls';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { useContextParams } from '../hooks/useContextParams';

export const BookingOfferServiceManageFooterAdapter = () => {
  const { id } = useContextParams();
  const isSaving = useSelector(getBookingOfferActionsOfferIsProcessingSelector(id));
  const handlers = useContextHandlers();

  return (
    <ContentWrapper>
      <Grid
        container
        spacing={3}
      >
        <Grid item>
          <MPButton
            fullWidth={false}
            disabled={isSaving}
            onClick={handlers.onSave}
          >
            Сохранить
            {isSaving && <ContentLoader />}
          </MPButton>
        </Grid>
        <Grid item>
          <MPButton
            variant='outlined'
            fullWidth={false}
            disabled={isSaving}
            onClick={handlers.onClose}
          >
            Отмена
          </MPButton>
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};
