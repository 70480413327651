import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Team } from '../../../../../domain/model/team';
import { getTeamRoute } from '../../entry';
import { ETeamTableTab } from '../utils';

export type UseTeamsTableConfig = {
  readonly defaultTab: ETeamTableTab;
  readonly tabsForCounts: ETeamTableTab[];
  readonly onTeamClick: (team: Team) => void;
};

export function useTeamsTableConfig(): UseTeamsTableConfig {
  const history = useHistory();

  const onTeamClick = useCallback(
    (team: Team) => {
      history.push(getTeamRoute({ id: team.id }));
    },
    [history]
  );

  const defaultTab = ETeamTableTab.All;
  const tabsForCounts = Object.values(ETeamTableTab);

  return {
    defaultTab,
    tabsForCounts,
    onTeamClick,
  };
}
