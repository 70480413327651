import { Typography } from '@mui/material';
import { Wrapper } from './controls';
import { Nullable } from '../../../../../domain/model/types';

interface ValidationHintPanelProps {
  text?: Nullable<string>;
  inline?: boolean;
}

const ValidationHintPanel = (props: ValidationHintPanelProps) => {
  const { text, inline } = props;

  if (!text) return null;

  return (
    <Wrapper $inline={inline}>
      <Typography variant='body2'>{text}</Typography>
    </Wrapper>
  );
};
export default ValidationHintPanel;
