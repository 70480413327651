import { EOfferStatus, EOfferType, Nullable } from '@/domain';
import { EOfferActionType, EOfferTableTab, OfferActions, OfferActionTableType } from '../types';
import { GetOfferActionsByPermissionsProps, getOfferActionsConfigByPermissions } from './actions';

type OfferActionTypes = OfferActionTableType;

interface GetOffersTableTabActionsProps {
  readonly tab: EOfferTableTab;
  readonly actions: OfferActions<OfferActionTypes>;
}

// получение возможных действий с оффером для вкладки таблицы
export const getOfferTableTabActionsConfig = (props: GetOffersTableTabActionsProps): OfferActions<OfferActionTypes> => {
  const { actions, tab } = props;

  switch (tab) {
    case EOfferTableTab.Active:
      return actions.filter(item =>
        [
          EOfferActionType.Pause,
          EOfferActionType.Archive,
          EOfferActionType.ChangeApprovalRegistry,
          EOfferActionType.Duplicate,
        ].includes(item.type)
      );
    case EOfferTableTab.Upcoming:
      return actions.filter(item =>
        [
          EOfferActionType.Pause,
          EOfferActionType.Archive,
          EOfferActionType.ChangeApprovalRegistry,
          EOfferActionType.Duplicate,
        ].includes(item.type)
      );
    case EOfferTableTab.Draft:
      return actions.filter(item => [EOfferActionType.Delete, EOfferActionType.Duplicate].includes(item.type));
    case EOfferTableTab.Paused:
      return actions.filter(item =>
        [
          EOfferActionType.Resume,
          EOfferActionType.Archive,
          EOfferActionType.ChangeApprovalRegistry,
          EOfferActionType.Duplicate,
        ].includes(item.type)
      );
    case EOfferTableTab.Moderation:
      return actions.filter(item =>
        [
          EOfferActionType.Approve,
          EOfferActionType.Reject,
          EOfferActionType.ChangeApprovalRegistry,
          EOfferActionType.ChangeModerator,
          EOfferActionType.Duplicate,
        ].includes(item.type)
      );
    case EOfferTableTab.Archived:
      return actions.filter(item => [EOfferActionType.Retrieve, EOfferActionType.Duplicate].includes(item.type));
    case EOfferTableTab.Rejected:
      return actions.filter(item =>
        [EOfferActionType.Duplicate, EOfferActionType.ReturnToVerification].includes(item.type)
      );
  }
};

// фильтрация действий для таблицы офферов по всем действиям
export const filterOfferTableActions = (actions: OfferActions): OfferActions<OfferActionTypes> => {
  const tableActions: OfferActions<OfferActionTypes> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EOfferActionType.Archive:
      case EOfferActionType.Delete:
      case EOfferActionType.Pause:
      case EOfferActionType.Resume:
      case EOfferActionType.Reject:
      case EOfferActionType.ChangeApprovalRegistry:
      case EOfferActionType.Approve:
      case EOfferActionType.ChangeModerator:
      case EOfferActionType.Duplicate:
      case EOfferActionType.ReturnToVerification:
      case EOfferActionType.Retrieve:
        tableActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          position: action.position,
        });
        break;
      case EOfferActionType.Publish:
      case EOfferActionType.UnPublish:
      case EOfferActionType.CreateCodes:
      case EOfferActionType.DeleteCodes:
      case EOfferActionType.DownloadCodes:
      case EOfferActionType.Edit:
      case EOfferActionType.SaveNewVersion:
      case EOfferActionType.UnPublishAndEdit:
      case EOfferActionType.ChangeResponsiblePerson:
      case EOfferActionType.InWork:
        break;
    }
  });

  return tableActions;
};

export type OfferTableTabsCounter = {
  [name in EOfferTableTab]?: number;
};

export const getStatesFilterForOfferTableTab = (
  tab: EOfferTableTab,
  defaultStatuses: EOfferStatus[]
): EOfferStatus[] => {
  switch (tab) {
    case EOfferTableTab.Active:
      return [EOfferStatus.Active];

    case EOfferTableTab.Archived:
      return [EOfferStatus.Archived];

    case EOfferTableTab.Moderation:
      return [EOfferStatus.WaitingForModeration, EOfferStatus.OnModeration];

    case EOfferTableTab.Upcoming:
      return [EOfferStatus.Upcoming];

    case EOfferTableTab.Draft:
      return [EOfferStatus.Draft];

    case EOfferTableTab.Paused:
      return [EOfferStatus.Paused];

    case EOfferTableTab.Rejected:
      return [EOfferStatus.Rejected];

    default: {
      return defaultStatuses;
    }
  }
};

export const getOfferTableTabForStatus = (status: EOfferStatus): EOfferTableTab => {
  switch (status) {
    case EOfferStatus.Active:
      return EOfferTableTab.Active;

    case EOfferStatus.Archived:
      return EOfferTableTab.Archived;

    case EOfferStatus.WaitingForModeration:
    case EOfferStatus.OnModeration:
      return EOfferTableTab.Moderation;

    case EOfferStatus.Upcoming:
      return EOfferTableTab.Upcoming;

    case EOfferStatus.Draft:
      return EOfferTableTab.Draft;

    case EOfferStatus.Rejected:
      return EOfferTableTab.Rejected;

    case EOfferStatus.Paused:
      return EOfferTableTab.Paused;
    case EOfferStatus.ReadyForSale:
      throw new Error('???');
  }
};

export const getOfferTableTabActions = (
  permissions: GetOfferActionsByPermissionsProps,
  tab: EOfferTableTab
): OfferActions<OfferActionTypes> => {
  const allowedActions = getOfferActionsConfigByPermissions(permissions);
  const actions = filterOfferTableActions(allowedActions);
  return getOfferTableTabActionsConfig({ tab, actions });
};

interface GetOffersTableAdapterKeyParams {
  readonly offerType: EOfferType;
  readonly isAdmin?: boolean;
  readonly partnerId: Nullable<string>;
  readonly tab: EOfferTableTab;
}

interface GetOffersTableAdapterKeyResult {
  readonly key: string;
  readonly keyPartitions?: (string | number | null)[];
}

const getOfferTypeAdapterKey = (offerType: EOfferType): string => {
  switch (offerType) {
    case EOfferType.Trade:
      return 'tradeOffers';
    case EOfferType.Corp:
      return 'corpOffers';
    case EOfferType.Booking:
      return 'bookingOffers';
    case EOfferType.Product:
      return 'productOffers';
  }
};
export const getOffersTableAdapterKey = (params: GetOffersTableAdapterKeyParams): GetOffersTableAdapterKeyResult => {
  const { offerType, isAdmin, partnerId, tab } = params;

  const key = getOfferTypeAdapterKey(offerType);
  let keyPartitions;

  if (isAdmin) {
    keyPartitions = ['admin', partnerId ? 'byPartner' : 'common', tab];
  } else {
    keyPartitions = ['partner', tab];
  }

  return {
    key,
    keyPartitions,
  };
};

export const getOffersTableTabName = (tab: EOfferTableTab): string => {
  switch (tab) {
    case EOfferTableTab.Paused:
      return 'Приостановленные';
    case EOfferTableTab.Rejected:
      return 'Отклонённые';
    case EOfferTableTab.Archived:
      return 'Архив';
    case EOfferTableTab.Active:
      return 'Опубликованные';
    case EOfferTableTab.Upcoming:
      return 'Предстоящие';
    case EOfferTableTab.Moderation:
      return 'На проверке';
    case EOfferTableTab.Draft:
      return 'Черновики';
  }
};

export const getOffersTableTabs = (isPartnerUser: boolean): EOfferTableTab[] =>
  isPartnerUser
    ? [
        EOfferTableTab.Active,
        EOfferTableTab.Upcoming,
        EOfferTableTab.Paused,
        EOfferTableTab.Moderation,
        EOfferTableTab.Rejected,
        EOfferTableTab.Draft,
        EOfferTableTab.Archived,
      ]
    : [
        EOfferTableTab.Moderation,
        EOfferTableTab.Active,
        EOfferTableTab.Upcoming,
        EOfferTableTab.Paused,
        EOfferTableTab.Rejected,
        EOfferTableTab.Archived,
      ];

export const getOffersTableTabsForCounts = (isPartnerUser: boolean): EOfferTableTab[] =>
  isPartnerUser
    ? [
        EOfferTableTab.Active,
        EOfferTableTab.Upcoming,
        EOfferTableTab.Paused,
        EOfferTableTab.Moderation,
        EOfferTableTab.Rejected,
        EOfferTableTab.Archived,
        EOfferTableTab.Draft,
      ]
    : [
        EOfferTableTab.Moderation,
        EOfferTableTab.Active,
        EOfferTableTab.Upcoming,
        EOfferTableTab.Paused,
        EOfferTableTab.Rejected,
        EOfferTableTab.Archived,
      ];

export const getOffersTableTabsForCountsUnwatched = (): EOfferTableTab[] => [
  EOfferTableTab.Active,
  EOfferTableTab.Upcoming,
  EOfferTableTab.Paused,
  EOfferTableTab.Moderation,
  EOfferTableTab.Archived,
];
