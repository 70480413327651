import SupportInfoMpContactsAdapter from './adapters/contacts';
import SupportInfoMpDocumentsAdapter from './adapters/documents';
import SupportInfoMpHeaderAdapter from './adapters/header';
import SupportInfoMpLayout from './layout';

const SupportInfoMpContainer = () => {
  return (
    <SupportInfoMpLayout
      header={<SupportInfoMpHeaderAdapter />}
      contacts={<SupportInfoMpContactsAdapter />}
      documents={<SupportInfoMpDocumentsAdapter />}
    />
  );
};

export default SupportInfoMpContainer;
