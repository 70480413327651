import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from './index';

const CorpOfferTableCellName = (props: CorpOfferTableCellCommonProps) => {
  const {
    corpOffer: { name: value = '-' },
  } = props;

  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellName;
