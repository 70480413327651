import React from 'react';
import { UseTradeActivationTable } from './hooks/useActivationTable';

/**
 * Контекст для обработчиков
 */
export type TradeActivationTableContextValue = UseTradeActivationTable;
export const TradeActivationTableContext = React.createContext<TradeActivationTableContextValue>(
  {} as TradeActivationTableContextValue
);
