import { List as MuiList, ListItemSecondaryAction as MuiListItemSecondaryAction } from '@mui/material';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding-top: ${p => p.theme.spacing(3.75)};
  padding-bottom: ${p => p.theme.spacing(3.75)};
`;

export const ButtonWrap = styled.div`
  margin-left: -${p => p.theme.spacing(1.5)};
`;

export const List = styled(MuiList)`
  & .MuiDivider-inset {
    margin-left: ${p => p.theme.spacing(10.75)};
    margin-right: ${p => p.theme.spacing(3.75)};
  }

  & .MuiListItemAvatar-root {
    min-width: ${p => p.theme.spacing(7)};
  }

  & .MuiListItem-root {
    padding-left: 0;
    padding-right: 0;
  }

  & .MuiDivider-inset {
    margin-left: ${p => p.theme.spacing(7)};
    margin-right: 0;
  }

  & .MuiListItemText-root {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const ListItemSecondaryAction = styled(MuiListItemSecondaryAction)`
  right: 0;

  & .MuiIconButton-root {
    padding: ${p => p.theme.spacing(1)};
  }

  & .MuiSvgIcon-root {
    font-size: 1.25rem;
  }
`;
