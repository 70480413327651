import { FCC } from 'react';
import { Label, Title, Wrapper } from './controls';

export interface ClientOrgWidgetProps {
  label: string;
}

export const ClientOrgWidget: FCC<ClientOrgWidgetProps> = props => {
  const { children, label } = props;

  if (!children) {
    return null;
  }

  return (
    <Wrapper>
      <Label>
        <Title
          color='textSecondary'
          variant='body2'
        >
          {label}
        </Title>
      </Label>
      {children}
    </Wrapper>
  );
};
