import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { UUID } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import { viewShowMainMenu } from '../../features/main/container/store/slice';
import PartnerDetailsContainer from '../../features/partner/details/container';
import { EPartnerDetailsLegalTab, EPartnerDetailsTab } from '../../features/partner/details/utils';
import { EPartnerUrlParam } from '../../features/partner/entry';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const PartnerScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { partners } = useCurrentUser().accessMatrix;

  const { id } = useParams<{ id: UUID }>();
  const searchParams = new URLSearchParams(location.search);
  const tab = (searchParams.get(EPartnerUrlParam.Tab) as EPartnerDetailsTab) ?? undefined;
  const legalTab = (searchParams.get(EPartnerUrlParam.LegalTab) as EPartnerDetailsLegalTab) ?? undefined;

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (!partners?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <PartnerDetailsContainer
      id={id}
      tab={tab}
      legalTab={legalTab}
    />
  );
};

export default PartnerScreen;
