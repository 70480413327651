import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import {
  BookingOffer,
  BookingOfferContact,
  BookingOfferResponsiblePerson,
  BookingOfferService,
  BookingServiceCategory,
  BookingServicePriceItem,
  BookingServicePriceUnit,
} from '../../../../../domain/model/booking';
import { PartnerDesk, PartnerShort } from '../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { ValidationCollectionResult, ValidationItemResult, ValidationResult } from '../../../../utils/validation';
import serviceCategoryServices from '../../../dictionary/bookingServiceCategory/services';
import servicePriceUnitServices from '../../../dictionary/bookingServicePriceUnit/services';
import offerServices from '../../../general/offer/services';
import bookingServices from '../../services';
import { BookingOfferCreateStepType, BookingOfferServiceView, BookingOfferView } from '../../types';
import { BookingOfferCreateUiState } from '../types';
import {
  convertBookingOfferToBookingOfferView,
  createEmptyBookingOfferView,
  getEmptyBookingOfferContact,
  getEmptyBookingOfferResponsiblePerson,
  getEmptyOfferServiceView,
  getEmptyServicePriceItemView,
} from '../utils';

interface BookingOfferCreateByIdFetchProps {
  readonly partner: Nullable<PartnerShort>;
  readonly data: Nullable<BookingOffer>;
  readonly serviceCategories: BookingServiceCategory[];
  readonly servicePriceUnits: BookingServicePriceUnit[];
}

export const bookingOfferCreateByIdFetch = createAsyncThunk<
  BookingOfferCreateByIdFetchProps,
  { id: Nullable<UUID>; partnerId: Nullable<UUID> },
  AppThunkAPIConfig
>('bookingOffer/create/byId/fetch', async ({ id, partnerId }, { rejectWithValue, signal, dispatch }) => {
  try {
    let partner: Nullable<PartnerShort> = null;

    if (partnerId) {
      partner = (await Api.partner.one({ id: partnerId, signal })).data;
    }

    const serviceCategories: BookingServiceCategory[] = await serviceCategoryServices.all({
      onlyEnabled: true,
      withOrderByDateType: true,
      signal,
    });
    const servicePriceUnits: BookingServicePriceUnit[] = await servicePriceUnitServices.all({
      onlyEnabled: true,
      signal,
    });

    if (id) {
      const data = await bookingServices.offer.one({ id, signal });
      dispatch(bookingOfferCreateViewed(id));
      partner = data.partner;

      return {
        data,
        partner,
        serviceCategories,
        servicePriceUnits,
      };
    } else {
      return {
        data: null,
        partner,
        serviceCategories,
        servicePriceUnits,
      };
    }
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);

    return rejectWithValue(e.response.data);
  }
});

export const bookingOfferCreateViewed = createAsyncThunk<void, UUID, AppThunkAPIConfig>(
  'bookingOffer/create/viewed',
  async id => {
    try {
      await offerServices.common.makeViewed({ id });
    } catch (e: any) {
      console.error(`Error at call user event`, e);
    }
  }
);

export type BookingOfferCreateValidationStepper = Nullable<
  Record<BookingOfferCreateStepType, Nullable<ValidationResult<any>>>
>;

export interface BookingOfferCreateState {
  readonly guid: Nullable<UUID>;
  readonly byId: Fetchable & {
    readonly bookingOffer: BookingOfferView;
    readonly loadedData: Nullable<BookingOffer>;
  };
  readonly partner: {
    readonly data: Nullable<PartnerShort>;
    readonly desk: Nullable<PartnerDesk>;
  };
  readonly modified: boolean;

  readonly serviceCategories: BookingServiceCategory[];
  readonly servicePriceUnits: BookingServicePriceUnit[];

  readonly validation: ValidationResult<BookingOfferView>;
  readonly validationStepper: BookingOfferCreateValidationStepper;
  readonly validationResponsiblePersons: ValidationCollectionResult<BookingOfferResponsiblePerson>;
  readonly validationContacts: ValidationCollectionResult<BookingOfferContact>;
  readonly validationServices: ValidationCollectionResult<BookingOfferServiceView>;
  readonly validationPriceItems: ValidationCollectionResult<BookingServicePriceItem>[];

  readonly ui: Nullable<BookingOfferCreateUiState>;
}

type Reducer<T = undefined> = CaseReducer<BookingOfferCreateState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<BookingOfferCreateState> {
  bookingOfferCreateStartSession: Reducer<{
    guid: Nullable<UUID>;
  }>;
  bookingOfferCreateSetModified: Reducer<boolean>;
  bookingOfferCreateApplySavedOffer: Reducer<BookingOffer>;
  bookingOfferCreateClearActionsState: Reducer;
  bookingOfferCreateSetValidationStepper: Reducer<
    Nullable<Record<BookingOfferCreateStepType, Nullable<ValidationResult<any>>>>
  >;
  bookingOfferCreateClearAllValidations: Reducer;

  //offer
  bookingOfferCreateSetAttribute: Reducer<{
    name: keyof BookingOfferView;
    value: any;
  }>;
  bookingOfferCreateSetUiState: Reducer<{
    name: keyof BookingOfferCreateUiState;
    value: any;
  }>;
  bookingOfferCreateClearAttributeValidation: Reducer<keyof BookingOfferView>;
  bookingOfferCreateSetAttributeValidation: Reducer<{
    name: keyof BookingOfferView;
    results: Nullable<ValidationItemResult>;
  }>;
  bookingOfferCreateSetValidation: Reducer<Nullable<ValidationResult<BookingOfferView>>>;

  // contact
  bookingOfferCreateSetContactAttribute: Reducer<{
    index: number;
    name: keyof BookingOfferContact;
    value: any;
  }>;
  bookingOfferCreateAddContact: Reducer;
  bookingOfferCreateRemoveContact: Reducer<number>;
  bookingOfferCreateContactSetAttributeValidation: Reducer<{
    index: number;
    name: keyof BookingOfferContact;
    results: Nullable<ValidationItemResult>;
  }>;
  bookingOfferCreateSetContactsValidation: Reducer<Nullable<ValidationCollectionResult<BookingOfferContact>>>;
  bookingOfferCreateClearContactAttributeValidation: Reducer<{
    index: number;
    name: keyof BookingOfferContact;
  }>;
  bookingOfferCreateClearContactValidation: Reducer<number>;

  //service
  bookingOfferCreateSetServiceAttribute: Reducer<{
    index: number;
    name: keyof BookingOfferService;
    value: any;
  }>;
  bookingOfferCreateAddService: Reducer;
  bookingOfferCreateRemoveService: Reducer<number>;
  bookingOfferCreateSetServicesValidation: Reducer<Nullable<ValidationCollectionResult<BookingOfferServiceView>>>;
  bookingOfferCreateClearServiceAttributeValidation: Reducer<{
    index: number;
    name: keyof BookingOfferServiceView;
  }>;
  bookingOfferCreateClearServiceValidation: Reducer<number>;
  bookingOfferCreateServiceSetAttributeValidation: Reducer<{
    index: number;
    name: keyof BookingOfferServiceView;
    results: Nullable<ValidationItemResult>;
  }>;

  //price item

  bookingOfferCreateSetServicePriceItemAttribute: Reducer<{
    serviceIndex: number;
    index: number;
    name: keyof BookingServicePriceItem;
    value: any;
  }>;
  bookingOfferCreateAddServicePriceItem: Reducer<number>;
  bookingOfferCreateRemoveServicePriceItem: Reducer<{
    serviceIndex: number;
    index: number;
  }>;
  bookingOfferCreateSetServicePriceItemsValidation: Reducer<{
    serviceIndex: number;
    results: Nullable<ValidationCollectionResult<BookingServicePriceItem>>;
  }>;
  bookingOfferCreateClearServicePriceItemAttributeValidation: Reducer<{
    serviceIndex: number;
    index: number;
    name: keyof BookingServicePriceItem;
  }>;
  bookingOfferCreateClearServicePriceItemValidation: Reducer<{
    serviceIndex: number;
    index: number;
  }>;
  bookingOfferCreateServicePriceItemSetAttributeValidation: Reducer<{
    serviceIndex: number;
    index: number;
    name: keyof BookingServicePriceItem;
    results: Nullable<ValidationItemResult>;
  }>;

  //responsible person
  bookingOfferCreateSetResponsiblePersonAttribute: Reducer<{
    index: number;
    name: keyof BookingOfferResponsiblePerson;
    value: any;
  }>;
  bookingOfferCreateAddResponsiblePerson: Reducer;
  bookingOfferCreateRemoveResponsiblePerson: Reducer<number>;
  bookingOfferCreateSetResponsiblePersonsValidation: Reducer<
    Nullable<ValidationCollectionResult<BookingOfferResponsiblePerson>>
  >;
  bookingOfferCreateClearResponsiblePersonAttributeValidation: Reducer<{
    index: number;
    name: keyof BookingOfferResponsiblePerson;
  }>;
  bookingOfferCreateResponsiblePersonSetAttributeValidation: Reducer<{
    index: number;
    name: keyof BookingOfferResponsiblePerson;
    results: Nullable<ValidationItemResult>;
  }>;
  bookingOfferCreateClearResponsiblePersonValidation: Reducer<number>;
}

const slice = createSlice<BookingOfferCreateState, Reducers, 'bookingOffer/create'>({
  name: 'bookingOffer/create',
  initialState: {
    guid: null,
    byId: {
      ...fetchableDefault,
      bookingOffer: createEmptyBookingOfferView(null),
      loadedData: null,
    },
    partner: {
      data: null,
      desk: null,
    },
    modified: false,
    serviceCategories: [],
    servicePriceUnits: [],
    validation: {},
    validationStepper: null,
    validationResponsiblePersons: [],
    validationContacts: [],
    validationServices: [],
    validationPriceItems: [],
    ui: null,
  },
  reducers: {
    bookingOfferCreateStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;
        state.byId = {
          ...fetchableDefault,
          bookingOffer: createEmptyBookingOfferView(null),
          loadedData: null,
        };
        state.modified = false;
        state.validation = {};
        state.validationResponsiblePersons = [];
        state.validationContacts = [];
        state.validationServices = [];
        state.validationPriceItems = [];
        state.ui = null;
      }
    },
    bookingOfferCreateSetModified: (state, { payload }) => {
      state.modified = payload;
    },
    bookingOfferCreateApplySavedOffer: (state, { payload }) => {
      state.byId.bookingOffer = convertBookingOfferToBookingOfferView(payload);
      state.byId.loadedData = payload;
      state.modified = false;
    },
    bookingOfferCreateClearActionsState: state => {
      state.validation = {};
      state.validationStepper = null;
      state.validationResponsiblePersons = [];
      state.validationContacts = [];
      state.validationServices = [];
      state.validationPriceItems = [];
      state.ui = null;
    },
    bookingOfferCreateSetUiState: (state, { payload }) => {
      const { name, value } = payload;
      if (!state.ui) {
        state.ui = {
          steps: [],
        };
      }
      state.ui[name] = value;
    },

    bookingOfferCreateSetValidationStepper: (state, { payload }) => {
      state.validationStepper = payload;
    },

    //offer
    bookingOfferCreateSetAttribute: (state, { payload }) => {
      const { name, value } = payload;

      if (state.byId.bookingOffer) {
        (state.byId.bookingOffer[name] as keyof BookingOfferView) = value;
      }
    },
    bookingOfferCreateClearAllValidations: state => {
      state.validation = {};
      state.validationResponsiblePersons = [];
      state.validationContacts = [];
      state.validationServices = [];
      state.validationPriceItems = [];
    },
    bookingOfferCreateClearAttributeValidation: (state, { payload }) => {
      delete state.validation?.[payload];
    },
    bookingOfferCreateSetAttributeValidation: (state, { payload }) => {
      const { name, results } = payload;
      if (!results) {
        delete state.validation?.[name];
      } else {
        state.validation[name] = results;
      }
    },
    bookingOfferCreateSetValidation: (state, { payload }) => {
      state.validation = payload ?? {};
    },

    //contact
    bookingOfferCreateSetContactAttribute: (state, { payload }) => {
      const { index, name, value } = payload;

      if (state.byId.bookingOffer?.contacts?.[index]) {
        state.byId.bookingOffer.contacts[index][name] = value;
      }
    },
    bookingOfferCreateAddContact: state => {
      if (state.byId.bookingOffer) {
        if (!state.byId.bookingOffer.contacts) {
          state.byId.bookingOffer.contacts = [];
        }

        state.byId.bookingOffer.contacts.push(getEmptyBookingOfferContact());
      }
    },
    bookingOfferCreateRemoveContact: (state, { payload }) => {
      if (state.byId.bookingOffer) {
        const currentContacts = [...(state.byId.bookingOffer.contacts ?? [])];
        if (payload < currentContacts.length && payload > -1) {
          currentContacts.splice(payload, 1);
          state.byId.bookingOffer.contacts = currentContacts;
        }
      }
    },
    bookingOfferCreateSetContactsValidation: (state, { payload }) => {
      state.validationContacts = payload ?? [];
    },
    bookingOfferCreateClearContactAttributeValidation: (state, { payload }) => {
      const { index, name } = payload;
      if (index >= 0) {
        const itemValidation = state.validationContacts[index];
        if (itemValidation) {
          delete itemValidation[name];
        }
      }
    },
    bookingOfferCreateClearContactValidation: (state, { payload }) => {
      const index = payload;
      if (index >= 0) {
        const validationContacts = state.validationContacts;
        validationContacts.splice(index, 1);
      }
    },
    bookingOfferCreateContactSetAttributeValidation: (state, { payload }) => {
      const { index, name, results } = payload;
      if (index >= 0) {
        const itemValidation = state.validationContacts[index];
        if (itemValidation) {
          if (!results) {
            delete itemValidation[name];
          } else {
            itemValidation[name] = results;
          }
        } else {
          state.validationContacts[index] = {
            [name]: results,
          };
        }
      }
    },

    // responsible person
    bookingOfferCreateSetResponsiblePersonAttribute: (state, { payload }) => {
      const { index, name, value } = payload;

      if (state.byId.bookingOffer.responsiblePersons?.[index]) {
        state.byId.bookingOffer.responsiblePersons[index][name] = value;
      }
    },
    bookingOfferCreateAddResponsiblePerson: state => {
      if (state.byId.bookingOffer) {
        if (!state.byId.bookingOffer.responsiblePersons) {
          state.byId.bookingOffer.responsiblePersons = [];
        }

        state.byId.bookingOffer.responsiblePersons.push(getEmptyBookingOfferResponsiblePerson());
      }
    },
    bookingOfferCreateRemoveResponsiblePerson: (state, { payload }) => {
      if (state.byId.bookingOffer) {
        const responsiblePersons = [...(state.byId.bookingOffer.responsiblePersons ?? [])];
        if (payload < responsiblePersons.length && payload > -1) {
          responsiblePersons.splice(payload, 1);
          state.byId.bookingOffer.responsiblePersons = responsiblePersons;
        }
      }
    },
    bookingOfferCreateSetResponsiblePersonsValidation: (state, { payload }) => {
      state.validationResponsiblePersons = payload ?? [];
    },
    bookingOfferCreateClearResponsiblePersonAttributeValidation: (state, { payload }) => {
      const { index, name } = payload;
      if (index >= 0) {
        const itemValidation = state.validationResponsiblePersons[index];
        if (itemValidation) {
          delete itemValidation[name];
        }
      }
    },
    bookingOfferCreateResponsiblePersonSetAttributeValidation: (state, { payload }) => {
      const { index, name, results } = payload;
      if (index >= 0) {
        const itemValidation = state.validationResponsiblePersons[index];
        if (itemValidation) {
          if (!results) {
            delete itemValidation[name];
          } else {
            itemValidation[name] = results;
          }
        } else {
          state.validationResponsiblePersons[index] = {
            [name]: results,
          };
        }
      }
    },
    bookingOfferCreateClearResponsiblePersonValidation: (state, { payload }) => {
      const index = payload;
      if (index >= 0) {
        const validationResponsiblePersons = state.validationResponsiblePersons;
        validationResponsiblePersons.splice(index, 1);
      }
    },

    //service
    bookingOfferCreateSetServiceAttribute: (state, { payload }) => {
      const { index, name, value } = payload;

      if (state.byId.bookingOffer?.services[index]) {
        (state.byId.bookingOffer.services[index][name] as BookingOfferService) = value;
      }
    },
    bookingOfferCreateAddService: state => {
      if (state.byId.bookingOffer) {
        state.byId.bookingOffer.services.push(getEmptyOfferServiceView());
      }
    },
    bookingOfferCreateRemoveService: (state, { payload }) => {
      if (state.byId.bookingOffer) {
        const currentServices = [...state.byId.bookingOffer.services];
        if (payload < currentServices.length && payload > -1) {
          currentServices.splice(payload, 1);
          state.byId.bookingOffer.services = currentServices;
        }
      }
    },
    bookingOfferCreateServiceSetAttributeValidation: (state, { payload }) => {
      const { index, name, results } = payload;
      if (index >= 0) {
        const itemValidation = state.validationServices[index];
        if (itemValidation) {
          if (!results) {
            delete itemValidation[name];
          } else {
            itemValidation[name] = results;
          }
        } else {
          state.validationServices[index] = {
            [name]: results,
          };
        }
      }
    },
    bookingOfferCreateSetServicesValidation: (state, { payload }) => {
      state.validationServices = payload ?? [];
    },
    bookingOfferCreateClearServiceAttributeValidation: (state, { payload }) => {
      const { index, name } = payload;
      if (index >= 0) {
        const itemValidation = state.validationServices[index];
        if (itemValidation) {
          delete itemValidation[name];
        }
      }
    },
    bookingOfferCreateClearServiceValidation: (state, { payload }) => {
      const index = payload;
      if (index >= 0) {
        const validationServices = state.validationServices;
        validationServices.splice(index, 1);
      }
    },

    //price item
    bookingOfferCreateSetServicePriceItemAttribute: (state, { payload }) => {
      const { serviceIndex, index, name, value } = payload;
      const priceItem = state.byId.bookingOffer.services?.[serviceIndex]?.priceItems?.[index];
      if (priceItem) {
        priceItem[name] = value;
      }
    },
    bookingOfferCreateServicePriceItemSetAttributeValidation: (state, { payload }) => {
      const { serviceIndex, index, name, results } = payload;
      const itemValidation = state.validationPriceItems[serviceIndex]?.[index];
      if (itemValidation) {
        if (!results) {
          delete itemValidation[name];
        } else {
          itemValidation[name] = results;
        }
      } else {
        if (!state.validationPriceItems[serviceIndex]) {
          state.validationPriceItems[serviceIndex] = [];
        }
        state.validationPriceItems[serviceIndex][index] = {
          [name]: results,
        };
      }
    },
    bookingOfferCreateClearServicePriceItemValidation: (state, { payload }) => {
      const { serviceIndex, index } = payload;
      if (index >= 0) {
        if (!state.validationPriceItems[serviceIndex]) {
          state.validationPriceItems[serviceIndex] = [];
        }
        const byServiceValidations = state.validationPriceItems[serviceIndex];
        byServiceValidations.splice(index, 1);
      }
    },
    bookingOfferCreateClearServicePriceItemAttributeValidation: (state, { payload }) => {
      const { serviceIndex, index, name } = payload;
      if (!state.validationPriceItems[serviceIndex]) {
        state.validationPriceItems[serviceIndex] = [];
      }
      const itemValidation = state.validationPriceItems[serviceIndex]?.[index];
      if (itemValidation) {
        delete itemValidation[name];
      }
    },
    bookingOfferCreateSetServicePriceItemsValidation: (state, { payload }) => {
      const { serviceIndex, results } = payload;
      state.validationPriceItems[serviceIndex] = results ?? [];
    },
    bookingOfferCreateAddServicePriceItem: (state, { payload }) => {
      if (state.byId.bookingOffer.services?.[payload]?.priceItems) {
        state.byId.bookingOffer.services?.[payload]?.priceItems?.push(getEmptyServicePriceItemView());
      }
    },
    bookingOfferCreateRemoveServicePriceItem: (state, { payload }) => {
      const { serviceIndex, index } = payload;
      const service = state.byId.bookingOffer.services?.[serviceIndex];
      const priceItems = [...(service?.priceItems ?? [])];
      if (index < priceItems.length && index > -1) {
        priceItems.splice(index, 1);
      }
      if (service) {
        service.priceItems = priceItems;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bookingOfferCreateByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
      })
      .addCase(bookingOfferCreateByIdFetch.fulfilled, (state, { payload }) => {
        const { data, partner, serviceCategories, servicePriceUnits } = payload;
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.bookingOffer = data
          ? convertBookingOfferToBookingOfferView(data)
          : createEmptyBookingOfferView(partner);
        state.byId.loadedData = data;

        state.partner.data = partner;
        state.serviceCategories = serviceCategories;
        state.servicePriceUnits = servicePriceUnits;
      })
      .addCase(bookingOfferCreateByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        } else {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = false;
        }
      });
  },
});

export const {
  bookingOfferCreateStartSession,
  bookingOfferCreateSetModified,
  bookingOfferCreateApplySavedOffer,
  bookingOfferCreateClearActionsState,
  bookingOfferCreateSetUiState,

  bookingOfferCreateClearAllValidations,
  bookingOfferCreateSetValidationStepper,

  bookingOfferCreateSetAttribute,
  bookingOfferCreateSetValidation,
  bookingOfferCreateClearAttributeValidation,
  bookingOfferCreateSetAttributeValidation,

  bookingOfferCreateSetServiceAttribute,
  bookingOfferCreateAddService,
  bookingOfferCreateRemoveService,
  bookingOfferCreateClearServiceAttributeValidation,
  bookingOfferCreateServiceSetAttributeValidation,
  bookingOfferCreateClearServiceValidation,
  bookingOfferCreateSetServicesValidation,

  bookingOfferCreateSetServicePriceItemAttribute,
  bookingOfferCreateAddServicePriceItem,
  bookingOfferCreateClearServicePriceItemValidation,
  bookingOfferCreateServicePriceItemSetAttributeValidation,
  bookingOfferCreateSetServicePriceItemsValidation,
  bookingOfferCreateClearServicePriceItemAttributeValidation,
  bookingOfferCreateRemoveServicePriceItem,

  bookingOfferCreateClearContactAttributeValidation,
  bookingOfferCreateClearContactValidation,
  bookingOfferCreateSetContactsValidation,
  bookingOfferCreateContactSetAttributeValidation,
  bookingOfferCreateSetContactAttribute,
  bookingOfferCreateAddContact,
  bookingOfferCreateRemoveContact,

  bookingOfferCreateResponsiblePersonSetAttributeValidation,
  bookingOfferCreateClearResponsiblePersonAttributeValidation,
  bookingOfferCreateSetResponsiblePersonAttribute,
  bookingOfferCreateSetResponsiblePersonsValidation,
  bookingOfferCreateClearResponsiblePersonValidation,
  bookingOfferCreateAddResponsiblePerson,
  bookingOfferCreateRemoveResponsiblePerson,
} = slice.actions;

export default slice.reducer;
