import styled from '@emotion/styled';
import { viewConfig } from '../../../../theme/viewConfig';

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  padding-left: ${viewConfig.stepper.width};
`;

export const ColumnWrapper = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;

  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
`;

export const TitleWrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  padding-bottom: ${p => p.theme.spacing(1.5)};

  border-bottom: 1px solid ${p => p.theme.palette.disabled.background};
`;

export const PreviewWrapper = styled.div`
  width: ${viewConfig.cms.preview.detailsWidth};

  position: fixed;

  margin-top: ${p => p.theme.spacing(3)};
`;
