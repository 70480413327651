import { UUID } from '../../../../domain/model/types';
import ForbiddenComponent from '../../../components/common/forbidden';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import PartnerDetailsAdminContainer from './admin/container';
import PartnerDetailsOwnerContainer from './owner/container';
import { EPartnerDetailsLegalTab, EPartnerDetailsTab } from './utils';

interface PartnerDetailsContainerProps {
  readonly id: UUID;
  readonly tab?: EPartnerDetailsTab;
  readonly legalTab?: EPartnerDetailsLegalTab;
}

const PartnerDetailsContainer = ({ id, tab, legalTab }: PartnerDetailsContainerProps) => {
  const { partners, partnerDesks, partnerNotifications } = useCurrentUser().accessMatrix;

  if (!partners?.view) {
    return <ForbiddenComponent />;
  }

  if (partners.viewAsAdmin) {
    return (
      <PartnerDetailsAdminContainer
        id={id}
        tab={tab}
        legalTab={legalTab}
        canEditDesk={partnerDesks.edit}
        canEdit={partners.edit}
        canActivate={partners.activate}
        canDeactivate={partners.deactivate}
      />
    );
  } else {
    return (
      <PartnerDetailsOwnerContainer
        id={id}
        tab={tab}
        legalTab={legalTab}
        canEditDesk={partnerDesks.edit}
        canEdit={partners.edit}
        canEditNotifications={partnerNotifications.edit}
      />
    );
  }
};

export default PartnerDetailsContainer;
