import styled from '@emotion/styled';

export const Wrapper = styled.div`
  flex: 1;

  border: 0.5px solid ${p => p.theme.palette.text.primary};
  border-radius: ${p => (p.theme.shape.borderRadius as number) / 2}px;
`;

export const Top = styled.div`
  height: 60%;

  background-color: ${p => p.theme.palette.disabled.background};
  border-bottom: 0.5px solid ${p => p.theme.palette.text.primary};
`;

export const Bottom = styled.div`
  padding: ${p => p.theme.spacing(0.5)};
`;
