import LikesIcon from '@mui/icons-material/Favorite';
import { FileDescription } from '../../../../../domain/model';
import { CorpOfferRequest } from '../../../../../domain/model/corpOffer';
import { PartnerShort } from '../../../../../domain/model/partner';
import { Nullable } from '../../../../../domain/model/types';
import { EEntityPreviewMode } from '../../../../types';
import OfferPreview from '../../../general/offer/components/preview';
import { OfferPreviewSkeleton } from '../../../general/offer/components/preview/controls';
import { corpOfferImageRestrictions } from '../../create/utils';
import { CorpOfferPreviewWrapper, DescriptionSkeletonWrapper } from './controls';
import CorpOfferPreviewFooter from './footer';
import CorpOfferPreviewPrice from './price';

type CorpOfferPreviewProps = {
  readonly mode?: EEntityPreviewMode;
  readonly logo: Nullable<string | FileDescription>;
  readonly partner: PartnerShort;
  readonly corpOffer: CorpOfferRequest;
  readonly border?: boolean;
};

const CorpOfferPreview = ({
  mode = EEntityPreviewMode.Desktop,
  corpOffer,
  partner,
  logo,
  border,
}: CorpOfferPreviewProps) => {
  const { name, price, originalPrice, images } = corpOffer;
  const imageRatio = corpOfferImageRestrictions.recommendedWidthInPx / corpOfferImageRestrictions.recommendedHeightInPx;

  return (
    <CorpOfferPreviewWrapper mode={mode}>
      <OfferPreview
        mode={mode}
        border={border}
        image={images?.[0] ?? null}
        imageRatio={imageRatio}
        description={
          name ? (
            name
          ) : (
            <DescriptionSkeletonWrapper>
              <OfferPreviewSkeleton
                animation={false}
                width='100%'
                variant='rectangular'
                height={mode === EEntityPreviewMode.Mobile ? '1rem' : '1.25rem'}
              />
              <OfferPreviewSkeleton
                animation={false}
                width={mode === EEntityPreviewMode.Mobile ? '100%' : '70%'}
                variant='rectangular'
                height={mode === EEntityPreviewMode.Mobile ? '1rem' : '1.25rem'}
              />
              {mode === EEntityPreviewMode.Mobile && (
                <OfferPreviewSkeleton
                  animation={false}
                  width='70%'
                  variant='rectangular'
                  height='1rem'
                />
              )}
            </DescriptionSkeletonWrapper>
          )
        }
        header={
          <CorpOfferPreviewPrice
            mode={mode}
            price={price}
            originalPrice={originalPrice}
          />
        }
        info={
          <CorpOfferPreviewFooter
            mode={mode}
            partnerName={partner.name}
            logo={logo}
          />
        }
        favorite={<LikesIcon color='disabled' />}
      />
    </CorpOfferPreviewWrapper>
  );
};

export default CorpOfferPreview;
