import { EBannerPartition, Nullable } from '@/domain';
import { useContextConfig } from '@features/banner/table/hooks/useContextConfig';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import {
  bannersFilterSelector,
  bannersNeedRefreshWatcherSelector,
  bannersPageNumberSelector,
  bannersSearchSelector,
} from '../store/selectors';
import { bannersCountsFetch, bannersFetch, BannersFetchProps, bannersNeedRefreshWatcherReset } from '../store/slice';
import { EBannerTableTab, getBannerTableStatusesByTab } from '../utils';

interface UseBannerTableSearch {
  readonly tab: EBannerTableTab;
  readonly partition: EBannerPartition;
}

export const useBannerTableSearch = (props: UseBannerTableSearch) => {
  const { partition, tab } = props;
  const dispatch = useDispatch();
  const { userSpecific } = useCurrentUser();

  const needRefreshWatcher = useSelector(bannersNeedRefreshWatcherSelector(partition));
  const pageNumber = useSelector(bannersPageNumberSelector(partition));
  const search = useSelector(bannersSearchSelector(partition));
  const filter = useSelector(bannersFilterSelector(partition));
  const { bannerPlaces: places } = useSelector(nsiDataSelector);

  const { tabsForCounts: tabs } = useContextConfig();

  const [searchPromises, setSearchPromises] = useState<Nullable<any[]>>(null);

  const currentStatuses = getBannerTableStatusesByTab(tab);

  const queryParams = useMemo<BannersFetchProps>(() => {
    return {
      userId: tab === EBannerTableTab.Draft ? userSpecific.id : null,
      places,
      partition,
      pageNumber,
      filter,
      search: {
        ...search,
        statuses: currentStatuses,
      },
    };
  }, [filter, search, currentStatuses, pageNumber, tab, partition, userSpecific.id]);

  const onSearch = useCallback(() => {
    setSearchPromises([
      dispatch(bannersFetch(queryParams)),
      dispatch(bannersCountsFetch({ ...queryParams, userId: userSpecific.id, tabs })),
    ]);
  }, [dispatch, queryParams, tabs, userSpecific.id]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  useEffect(() => {
    return () => {
      dispatch(bannersNeedRefreshWatcherReset(partition));
    };
  }, [dispatch, partition]);
};
