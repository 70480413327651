import { FCC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PanelAction } from '../../../../types';
import { useBookingOfferActions } from '../../actions/useActions';
import { BookingActionDetailsType, EBookingActionType } from '../../types';
import { getBookingActionsConfigByPermissions } from '../../utils/actions';
import { BookingOfferDetailsContainerProps } from '../container';
import { BookingOfferDetailsLifecycleContext, BookingOfferDetailsLifecycleContextValue } from '../context';
import useBookingDetailsActions from '../hooks/useActions';
import { useContextHandlers } from '../hooks/useContextHandlers';
import useBookingOfferDetailsStepper from '../hooks/useStepper';
import { bookingOfferDetailsBookingOfferSelector } from '../store/selectors';

export const BookingOfferDetailLifecycleProvider: FCC<BookingOfferDetailsContainerProps> = ({
  children,
  id,
  lifecycle,
  access,
}) => {
  const handlers = useContextHandlers();
  const { isLastStep } = useBookingOfferDetailsStepper();

  const {
    utils: {
      selectors: { getIsOfferExecutedActionsSelector },
    },
  } = useBookingOfferActions();

  const bookingOffer = useSelector(bookingOfferDetailsBookingOfferSelector);

  const isActionsForClose = useSelector(
    getIsOfferExecutedActionsSelector(
      id,
      EBookingActionType.Publish,
      EBookingActionType.Approve,
      EBookingActionType.Archive,
      EBookingActionType.Delete,
      EBookingActionType.Duplicate,
      EBookingActionType.Retrieve,
      EBookingActionType.Reject,
      EBookingActionType.Resume,
      EBookingActionType.Pause
    )
  );

  const isActionsForRefresh = useSelector(
    getIsOfferExecutedActionsSelector(
      id,
      EBookingActionType.ReturnToVerification,
      EBookingActionType.InWork,
      EBookingActionType.ChangeModerator
    )
  );

  const allowedActions = getBookingActionsConfigByPermissions({
    ...access,
  });

  const lifeCycleRules = lifecycle.build({ allowedActions, obj: bookingOffer });

  const actions = useBookingDetailsActions({
    lifeCycleRules,
    isLastStep,
  });

  const onAction = (action: PanelAction<BookingActionDetailsType>) => {
    if (!bookingOffer) {
      return;
    }

    const { type } = action;
    switch (type) {
      case EBookingActionType.ManageServices:
        handlers.onBookingOfferTryServiceManage();
        break;
      case EBookingActionType.Pause:
        handlers.onBookingOfferTryPause();
        break;
      case EBookingActionType.Reject:
        handlers.onBookingOfferTryReject();
        break;
      case EBookingActionType.Resume:
        handlers.onBookingOfferTryResume();
        break;
      case EBookingActionType.Archive:
        handlers.onBookingOfferTryArchive();
        break;
      case EBookingActionType.Approve:
        handlers.onBookingOfferTryApprove();
        break;
      case EBookingActionType.UnPublish:
        handlers.onBookingOfferTryUnPublish();
        break;
      case EBookingActionType.Edit:
        handlers.onBookingOfferOpenEdit(bookingOffer);
        break;
      case EBookingActionType.Publish:
        handlers.onBookingOfferTryPublish();
        break;
      case EBookingActionType.InWork:
        handlers.onBookingOfferTryInWork();
        break;
      case EBookingActionType.Duplicate:
        handlers.onBookingOfferTryDuplicate();
        break;
      case EBookingActionType.ChangeModerator: {
        handlers.onBookingOfferTryChangeModerator();
        break;
      }
      case EBookingActionType.Retrieve:
        handlers.onBookingOfferTryRetrieve();
        break;
      case EBookingActionType.ReturnToVerification:
        handlers.onBookingOfferTryReturnToVerification();
        break;
    }
  };

  useEffect(() => {
    if (isActionsForRefresh) {
      handlers.onRefresh();
    }

    if (isActionsForClose) {
      handlers.onBookingOfferClose(isActionsForClose);
    }
  }, [isActionsForClose, isActionsForRefresh, handlers.onRefresh]);

  const value: BookingOfferDetailsLifecycleContextValue = {
    lifecycle,
    lifeCycleRules,
    actions,
    onAction,
  };

  return (
    <BookingOfferDetailsLifecycleContext.Provider value={value}>
      {children}
    </BookingOfferDetailsLifecycleContext.Provider>
  );
};
