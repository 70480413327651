import { Container, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { MPButton } from '../../../theme/ui-kit/button';
import { Wrapper } from './controls';

interface ErrorMessageProps {
  readonly onBack: () => void;
  readonly onOpenMainPage: () => void;
}

interface ErrorComponentProps {
  readonly children?: any;
}

const ErrorMessage = ({ onBack, onOpenMainPage }: ErrorMessageProps) => {
  return (
    <Container maxWidth='xs'>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='subtitle2'
            color='error'
          >
            Произошла ошибка
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Typography>Операция не может быть выполнена</Typography>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <MPButton
            fullWidth={false}
            onClick={onBack}
          >
            Вернуться назад
          </MPButton>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <MPButton
            fullWidth={false}
            onClick={onOpenMainPage}
          >
            Перейти на главную
          </MPButton>
        </Grid>
      </Grid>
    </Container>
  );
};

const ErrorComponent = (props: ErrorComponentProps) => {
  const history = useHistory();

  const onOpenMainPage = () => (location.pathname = '/');

  const {
    children = (
      <ErrorMessage
        onBack={history.goBack}
        onOpenMainPage={onOpenMainPage}
      />
    ),
  } = props;

  return <Wrapper>{children}</Wrapper>;
};

export default ErrorComponent;
