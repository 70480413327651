import { EOrderStatus } from '../../../../domain/model/enums';
import { EProductOrderActionType } from '../types';
import { ProductOrderLifeCycleStatusScheme } from './types';

export const productOrderAdminStatusScheme: ProductOrderLifeCycleStatusScheme = {
  description: 'Статусная схема товаров админа',
  statusScheme: {
    [EOrderStatus.New]: [
      EProductOrderActionType.Save,
      EProductOrderActionType.New,
      EProductOrderActionType.Renew,
      EProductOrderActionType.Confirm,
      EProductOrderActionType.Give,
      EProductOrderActionType.Pay,
      EProductOrderActionType.Send,
      EProductOrderActionType.Cancel,
      EProductOrderActionType.PartiallyReturn,
      EProductOrderActionType.Return,
    ],
    [EOrderStatus.Given]: [
      EProductOrderActionType.Save,
      EProductOrderActionType.New,
      EProductOrderActionType.Renew,
      EProductOrderActionType.Confirm,
      EProductOrderActionType.Give,
      EProductOrderActionType.Pay,
      EProductOrderActionType.Send,
      EProductOrderActionType.Cancel,
      EProductOrderActionType.PartiallyReturn,
      EProductOrderActionType.Return,
    ],
    [EOrderStatus.Paid]: [
      EProductOrderActionType.Save,
      EProductOrderActionType.New,
      EProductOrderActionType.Renew,
      EProductOrderActionType.Confirm,
      EProductOrderActionType.Give,
      EProductOrderActionType.Pay,
      EProductOrderActionType.Send,
      EProductOrderActionType.Cancel,
      EProductOrderActionType.PartiallyReturn,
      EProductOrderActionType.Return,
    ],
    [EOrderStatus.Sent]: [
      EProductOrderActionType.Save,
      EProductOrderActionType.New,
      EProductOrderActionType.Renew,
      EProductOrderActionType.Confirm,
      EProductOrderActionType.Give,
      EProductOrderActionType.Pay,
      EProductOrderActionType.Send,
      EProductOrderActionType.Cancel,
      EProductOrderActionType.PartiallyReturn,
      EProductOrderActionType.Return,
    ],
    [EOrderStatus.Cancelled]: [
      EProductOrderActionType.Save,
      EProductOrderActionType.New,
      EProductOrderActionType.Renew,
      EProductOrderActionType.Confirm,
      EProductOrderActionType.Give,
      EProductOrderActionType.Pay,
      EProductOrderActionType.Send,
      EProductOrderActionType.Cancel,
      EProductOrderActionType.PartiallyReturn,
      EProductOrderActionType.Return,
    ],
    [EOrderStatus.Returned]: [
      EProductOrderActionType.Save,
      EProductOrderActionType.New,
      EProductOrderActionType.Renew,
      EProductOrderActionType.Confirm,
      EProductOrderActionType.Give,
      EProductOrderActionType.Pay,
      EProductOrderActionType.Send,
      EProductOrderActionType.Cancel,
      EProductOrderActionType.PartiallyReturn,
      EProductOrderActionType.Return,
    ],
    [EOrderStatus.Confirmed]: [
      EProductOrderActionType.Save,
      EProductOrderActionType.New,
      EProductOrderActionType.Renew,
      EProductOrderActionType.Confirm,
      EProductOrderActionType.Give,
      EProductOrderActionType.Pay,
      EProductOrderActionType.Send,
      EProductOrderActionType.Cancel,
      EProductOrderActionType.PartiallyReturn,
      EProductOrderActionType.Return,
    ],
    [EOrderStatus.PartiallyReturned]: [
      EProductOrderActionType.Save,
      EProductOrderActionType.New,
      EProductOrderActionType.Renew,
      EProductOrderActionType.Confirm,
      EProductOrderActionType.Give,
      EProductOrderActionType.Pay,
      EProductOrderActionType.Send,
      EProductOrderActionType.Cancel,
      EProductOrderActionType.PartiallyReturn,
      EProductOrderActionType.Return,
    ],
  },
  actionsScheme: {} as any,
};

export const productOrderPartnerStatusScheme: ProductOrderLifeCycleStatusScheme = {
  ...productOrderAdminStatusScheme,
  description: 'Статусная схема КП партнёра',
};
