import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { paginationSizeVariant } from '../../../../../domain/model/constants';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { productsCountNeedRefreshWatcherSelector, productsGuidSelector } from '../store/selectors';
import { productsCountFetch, ProductsFetchProps } from '../store/slice';

interface UseProductTableCountProps {
  readonly partnerId: Nullable<UUID>;
}

const useProductTableCount = (props: UseProductTableCountProps): void => {
  const { partnerId } = props;

  const dispatch = useDispatch();

  const guid = useSelector(productsGuidSelector);
  const needRefreshWatcher = useSelector(productsCountNeedRefreshWatcherSelector);

  const queryParams = useMemo<ProductsFetchProps>(() => {
    return {
      search: {
        partnerId,
        statuses: [],
        categories: null,
        sort: null,
        pageSize: paginationSizeVariant[0],
      },
      category: null,
      filter: {},
      pageNumber: 0,
    };
  }, [partnerId]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      const promise = dispatch(productsCountFetch(queryParams));
      return () => {
        promise?.abort();
      };
    }
  }, [dispatch, needRefreshWatcher, queryParams]);

  // начальный поиск и отмена запроса после ухода
  useEffect(() => {
    const promise = dispatch(productsCountFetch(queryParams));
    return () => {
      promise?.abort();
    };
  }, [dispatch, queryParams, guid]);
};

export default useProductTableCount;
