import { Location } from 'history';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { UUID } from '../../../domain/model/types';
import rootRouting from '../../../routing';
import NotFoundScreen from '../../screens/notFound';
import { EProductTableTab } from './table/utils';
import { EProductStep } from './types';
import { ContentLoaderSimple } from '../../components/common/loader';
import React, { Suspense } from 'react';

const ProductCreateScreen = React.lazy(() => import('../../screens/productCreate'));
const ProductDetailsScreen = React.lazy(() => import('../../screens/productDetails'));
const ProductEditScreen = React.lazy(() => import('../../screens/productEdit'));
const ProductScreen = React.lazy(() => import('../../screens/products'));

interface GetProductCreateRouteProps {
  readonly step?: EProductStep;
}

interface GetProductTableRouteProps {
  readonly tab?: EProductTableTab;
  readonly guid?: UUID;
}

interface GetProductEditRouteProps {
  readonly id: UUID;
  readonly step?: EProductStep;
}

interface GetProductDetailsRouteProps {
  readonly id: UUID;
  readonly step?: EProductStep;
}

export interface ProductLocationState {
  readonly guid: UUID;
}

export enum EProductUrlParam {
  Tab = 'tab',
  Step = 'step',
}

const routing = {
  root: `${rootRouting.products}`,
  list: `${rootRouting.products}`,
  create: `${rootRouting.products}/create`,
  details: `${rootRouting.products}/:id`,
  edit: `${rootRouting.products}/:id/edit`,
};

export const getProductCreateRoute = ({ step = EProductStep.General }: GetProductCreateRouteProps) => {
  const searchParams = new URLSearchParams();

  searchParams.append(EProductUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.create}${search}`;
};

export const getProductEditRoute = ({ id, step = EProductStep.General }: GetProductEditRouteProps) => {
  const searchParams = new URLSearchParams();
  searchParams.append(EProductUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.edit.replace(':id', id)}${search}`;
};

export const getProductDetailsRoute = ({ id, step = EProductStep.General }: GetProductDetailsRouteProps) => {
  const searchParams = new URLSearchParams();
  searchParams.append(EProductUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.details.replace(':id', id)}${search}`;
};

export const getProductTableRoute = ({ tab, guid }: GetProductTableRouteProps): Location<ProductLocationState> => {
  const searchParams = new URLSearchParams();
  if (tab) {
    searchParams.append(EProductUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return {
    pathname: routing.list,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

const ProductEntry = () => {
  return (
    <Suspense fallback={<ContentLoaderSimple />}>
      <Switch>
        <Route
          exact
          path={routing.list}
          component={ProductScreen}
        />
        <Route
          exact
          path={routing.create}
          component={ProductCreateScreen}
        />
        <Route
          exact
          path={routing.edit}
          component={ProductEditScreen}
        />

        <Route
          exact
          path={routing.details}
          component={ProductDetailsScreen}
        />
        <Redirect
          exact
          from={routing.root}
          to={routing.list}
        />

        <Route component={NotFoundScreen} />
      </Switch>
    </Suspense>
  );
};

export default ProductEntry;
