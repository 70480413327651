import { PanelAction, PanelActions } from '../../types';

export enum EBannerActionType {
  Activate = 'activate',
  Resume = 'resume',
  Pause = 'pause',
  Archive = 'archive',
  Save = 'save',
  Edit = 'edit',
  ChangeSortIndex = 'changeSortIndex',
}

export type BannerActionEditType = EBannerActionType.Activate | EBannerActionType.Resume | EBannerActionType.Save;

export type BannerActionDetailsType = EBannerActionType.Edit;

export type BannerActionTableType =
  | EBannerActionType.Resume
  | EBannerActionType.Pause
  | EBannerActionType.Archive
  | EBannerActionType.ChangeSortIndex;

export type BannerActionTableCommonType = Exclude<BannerActionTableType, EBannerActionType.ChangeSortIndex>;

export type BannerAction<A extends EBannerActionType, P = any> = PanelAction<A, P>;

export type BannerActions<A extends EBannerActionType> = PanelActions<A>;

export interface GetBannerActionsByPermissions {
  readonly canActivate?: boolean;
  readonly canArchive?: boolean;
  readonly canResume?: boolean;
  readonly canPause?: boolean;
  readonly canSave?: boolean;
  readonly canEdit?: boolean;
  readonly canChangeSortIndex?: boolean;
}
