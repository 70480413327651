import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { BookingOffer } from '../../../../../domain/model/booking';
import { BookingOrder } from '../../../../../domain/model/order';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { ValidationCollectionResult, ValidationItemResult, ValidationResult } from '../../../../utils/validation';
import bookingServices from '../../../bookingOffer/services';
import bookingOrderServices from '../../services';
import { BookingOrderItemView, BookingOrderView } from '../../types';
import { convertBookingOrderToBookingOrderView } from '../../utils/common';

export interface BookingOrderEditState {
  readonly guid: Nullable<UUID>;
  readonly modified: boolean;
  readonly byId: Fetchable & {
    readonly bookingOrder: Nullable<BookingOrderView>;
    readonly loadedData: Nullable<BookingOrder>;
    readonly offer: Nullable<BookingOffer>;
  };
  readonly validation: ValidationResult<BookingOrderView>;
  readonly validationOrderItems: ValidationCollectionResult<BookingOrderItemView>;
  readonly dialogs: {
    readonly feedback: Nullable<BookingOrder | BookingOrderView>;
    readonly addItem: Nullable<BookingOrder | BookingOrderView>;
  };
}

type Reducer<T = undefined> = CaseReducer<BookingOrderEditState, PayloadAction<T>>;

export const bookingOrderEditByIdFetch = createAsyncThunk<
  { order: BookingOrder; offer: BookingOffer },
  { id: UUID },
  AppThunkAPIConfig
>('bookingOrder/edit/fetch', async ({ id }, { rejectWithValue, signal }) => {
  try {
    const order = await bookingOrderServices.order.one({ id, signal });
    const offer = await bookingServices.offer.one({ id: order.offer.id, signal });
    return { order, offer };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

interface Reducers extends SliceCaseReducers<BookingOrderEditState> {
  bookingOrderEditStartSession: Reducer<{
    guid: Nullable<UUID>;
  }>;
  bookingOrderEditClearState: Reducer;
  bookingOrderEditSetModified: Reducer<boolean>;
  bookingOrderEditSetDialogState: Reducer<{
    name: keyof BookingOrderEditState['dialogs'];
    data: Nullable<BookingOrder | BookingOrderView>;
  }>;
  bookingOrderEditSetAttribute: Reducer<{
    name: keyof BookingOrderView;
    value: any;
  }>;
  bookingOrderEditAddOrderItem: Reducer<BookingOrderItemView>;
  bookingOrderEditSetOrderItemAttribute: Reducer<{
    index: number;
    name: keyof BookingOrderItemView;
    value: any;
  }>;
  bookingOrderEditDeleteOrderItem: Reducer<number>;
  bookingOrderEditClearAllValidations: Reducer;
  bookingOrderEditClearAttributeValidation: Reducer<keyof BookingOrderView>;
  bookingOrderEditSetAttributeValidation: Reducer<{
    name: keyof BookingOrderView;
    results: Nullable<ValidationItemResult>;
  }>;
  bookingOrderEditSetValidation: Reducer<Nullable<ValidationResult<BookingOrderView>>>;
  bookingOrderEditSetOrderItemsValidation: Reducer<Nullable<ValidationCollectionResult<BookingOrderItemView>>>;
  bookingOrderEditClearOrderItemAttributeValidation: Reducer<{
    index: number;
    name: keyof BookingOrderItemView;
  }>;
  bookingOrderEditOrderItemSetAttributeValidation: Reducer<{
    index: number;
    name: keyof BookingOrderItemView;
    results: Nullable<ValidationItemResult>;
  }>;
  bookingOrderEditClearOrderItemValidation: Reducer<number>;
}

const slice = createSlice<BookingOrderEditState, Reducers, 'edit'>({
  name: 'edit',
  initialState: {
    guid: null,
    modified: false,
    byId: {
      ...fetchableDefault,
      bookingOrder: null,
      loadedData: null,
      offer: null,
    },
    validation: {},
    validationOrderItems: [],
    dialogs: {
      feedback: null,
      addItem: null,
    },
  },
  reducers: {
    bookingOrderEditStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;
      }
    },
    bookingOrderEditClearState: state => {
      state.guid = null;
      state.modified = false;
      state.byId = {
        ...fetchableDefault,
        bookingOrder: null,
        loadedData: null,
        offer: null,
      };
      state.validation = {};
      state.validationOrderItems = [];
      state.dialogs = {
        feedback: null,
        addItem: null,
      };
    },
    bookingOrderEditSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
    bookingOrderEditSetAttribute: (state, { payload }) => {
      const { name, value } = payload;

      if (state.byId.bookingOrder) {
        (state.byId.bookingOrder[name] as keyof BookingOrderView) = value;
      }
    },
    bookingOrderEditAddOrderItem: (state, { payload }) => {
      if (state.byId.bookingOrder) {
        const orderItems = state.byId.bookingOrder.orderItems;
        orderItems.push(payload);
        state.byId.bookingOrder.orderItems = orderItems;
      }
    },
    bookingOrderEditSetOrderItemAttribute: (state, { payload }) => {
      const { index, name, value } = payload;

      if (state.byId.bookingOrder?.orderItems?.[index]) {
        (state.byId.bookingOrder.orderItems[index][name] as keyof BookingOrderItemView) = value;
      }
    },
    bookingOrderEditDeleteOrderItem: (state, { payload }) => {
      if (state.byId.bookingOrder) {
        const orderItems = [...(state.byId.bookingOrder?.orderItems ?? [])];

        if (payload < orderItems.length && payload > -1) {
          orderItems.splice(payload, 1);
          state.byId.bookingOrder.orderItems = orderItems;
        }
      }
    },
    bookingOrderEditSetModified: (state, { payload }) => {
      state.modified = payload;
    },
    bookingOrderEditClearAllValidations: state => {
      state.validation = {};
      state.validationOrderItems = [];
    },
    bookingOrderEditClearAttributeValidation: (state, { payload }) => {
      delete state.validation?.[payload];
    },
    bookingOrderEditSetAttributeValidation: (state, { payload }) => {
      const { name, results } = payload;
      if (!results) {
        delete state.validation?.[name];
      } else {
        state.validation[name] = results;
      }
    },
    bookingOrderEditSetValidation: (state, { payload }) => {
      state.validation = payload ?? {};
    },
    bookingOrderEditSetOrderItemsValidation: (state, { payload }) => {
      state.validationOrderItems = payload ?? [];
    },
    bookingOrderEditClearOrderItemAttributeValidation: (state, { payload }) => {
      const { index, name } = payload;
      if (index >= 0) {
        const itemValidation = state.validationOrderItems[index];
        if (itemValidation) {
          delete itemValidation[name];
        }
      }
    },
    bookingOrderEditOrderItemSetAttributeValidation: (state, { payload }) => {
      const { index, name, results } = payload;
      if (index >= 0) {
        const itemValidation = state.validationOrderItems[index];
        if (itemValidation) {
          if (!results) {
            delete itemValidation[name];
          } else {
            itemValidation[name] = results;
          }
        } else {
          state.validationOrderItems[index] = {
            [name]: results,
          };
        }
      }
    },
    bookingOrderEditClearOrderItemValidation: (state, { payload }) => {
      const index = payload;
      if (index >= 0) {
        const validationOrderItems = state.validationOrderItems;
        validationOrderItems.splice(index, 1);
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bookingOrderEditByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
      })
      .addCase(bookingOrderEditByIdFetch.fulfilled, (state, { payload }) => {
        const { order, offer } = payload;
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.bookingOrder = convertBookingOrderToBookingOrderView(order);
        state.byId.loadedData = order;
        state.byId.offer = offer;
      })
      .addCase(bookingOrderEditByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        } else {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = false;
        }
      });
  },
});

export const {
  bookingOrderEditStartSession,
  bookingOrderEditClearState,
  bookingOrderEditSetDialogState,
  bookingOrderEditSetAttribute,
  bookingOrderEditSetModified,
  bookingOrderEditAddOrderItem,
  bookingOrderEditSetOrderItemAttribute,
  bookingOrderEditDeleteOrderItem,

  bookingOrderEditClearAllValidations,

  bookingOrderEditSetValidation,
  bookingOrderEditClearAttributeValidation,
  bookingOrderEditSetAttributeValidation,

  bookingOrderEditClearOrderItemAttributeValidation,
  bookingOrderEditSetOrderItemsValidation,
  bookingOrderEditOrderItemSetAttributeValidation,
  bookingOrderEditClearOrderItemValidation,
} = slice.actions;

export default slice.reducer;
