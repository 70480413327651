import { combineReducers } from '@reduxjs/toolkit';
import details, { PartnerDetailsState } from './details/reducers';
import edit, { PartnerEditState } from './edit/store/slice';
import management, { PartnerManagementState } from './management/reducers';
import registration, { PartnerRegistrationState } from './registration/store/slice';
import search, { PartnerSearchState } from './search/store/slice';
import selfRegistration, { PartnerSelfRegistrationState } from './selfRegistration/store/slice';
import list, { PartnerListState } from './table/store/slice';

interface PartnerState {
  list: PartnerListState;
  search: PartnerSearchState;
  details: PartnerDetailsState;
  edit: PartnerEditState;
  management: PartnerManagementState;
  registration: PartnerRegistrationState;
  selfRegistration: PartnerSelfRegistrationState;
}

export default combineReducers<PartnerState>({
  list,
  search,
  details,
  edit,
  management,
  registration,
  selfRegistration,
});
