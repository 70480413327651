import Api from '../../../../data/api';
import { SportOption, SportOptionTyped } from '../../../../domain/model';
import { pageSizeAll } from '../../../../domain/model/constants';
import { EComplaintStatus, EUserRole, EUserStatus } from '../../../../domain/model/enums';
import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterStrategyView,
  DataFilterValueItem,
  EDataFilterType,
} from '../../../../domain/model/filter';
import { Nullable } from '../../../../domain/model/types';
import { getUserFullName } from '../../../utils';
import { EComplaintObjectTypeInternal } from '../types';
import { EComplaintTableColumn, EComplaintTableTab } from './utils';

export enum EComplaintTableFilterItem {
  ObjectName = 'objectName',
  ObjectType = 'objectType',
  Cause = 'cause',
  ExecutedBy = 'executedBy',
}

export const complaintFilterItems: Record<EComplaintTableColumn, EComplaintTableFilterItem[]> = {
  [EComplaintTableColumn.Logo]: [],
  [EComplaintTableColumn.CreatedBy]: [],
  [EComplaintTableColumn.CreatedDate]: [],
  [EComplaintTableColumn.DisplayId]: [],
  [EComplaintTableColumn.ObjectName]: [EComplaintTableFilterItem.ObjectName],
  [EComplaintTableColumn.Causes]: [EComplaintTableFilterItem.Cause],
  [EComplaintTableColumn.ExecutedBy]: [EComplaintTableFilterItem.ExecutedBy],
};

export type ComplaintTableFilterEditStrategy = DataFilterStrategyBase<EComplaintTableFilterItem>;
export type ComplaintTableFilterViewStrategy = DataFilterStrategyView<EComplaintTableFilterItem>;
export type ComplaintTableFilterValues = {
  readonly [EComplaintTableFilterItem.ObjectName]?: DataFilterValueItem<Nullable<string>>;
  readonly [EComplaintTableFilterItem.ObjectType]?: DataFilterValueItem<Nullable<EComplaintObjectTypeInternal[]>>;
  readonly [EComplaintTableFilterItem.Cause]?: DataFilterValueItem<Nullable<string[]>>;
  readonly [EComplaintTableFilterItem.ExecutedBy]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem>>;
};

export const getComplaintTableFilterStrategy = (
  filterItem: EComplaintTableFilterItem,
  values: ComplaintTableFilterValues,
  objectTypes: Nullable<SportOptionTyped<EComplaintObjectTypeInternal>[]>,
  causeTypes: Nullable<SportOption[]>
): Nullable<ComplaintTableFilterEditStrategy> => {
  const objectTypeItems: DataFilterStrategyListItem[] = (objectTypes ?? []).map(({ id, name }) => ({ id, name }));
  const causeTypeItems: DataFilterStrategyListItem[] = (causeTypes ?? []).map(({ id, name }) => ({ id, name }));
  switch (filterItem) {
    case EComplaintTableFilterItem.ObjectName:
      return {
        type: EDataFilterType.String,
        key: EComplaintTableFilterItem.ObjectName,
        label: 'Название',
        preview: values[EComplaintTableFilterItem.ObjectName]?.value || null,
        value: values[EComplaintTableFilterItem.ObjectName]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case EComplaintTableFilterItem.ObjectType:
      return {
        type: EDataFilterType.ListMultiple,
        key: EComplaintTableFilterItem.ObjectType,
        label: 'Тип жалобы',
        preview: `Тип жалобы (${values[EComplaintTableFilterItem.ObjectType]?.value?.length})`,
        previews: objectTypeItems
          .filter(t => values[EComplaintTableFilterItem.ObjectType]?.value?.some(v => v === t.id))
          .map(t => t.name),
        value: values[EComplaintTableFilterItem.ObjectType]?.value || null,
        items: objectTypeItems,
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
        },
      };
    case EComplaintTableFilterItem.Cause:
      return {
        type: EDataFilterType.ListMultiple,
        key: EComplaintTableFilterItem.Cause,
        label: 'Причина',
        preview: `Причина (${values[EComplaintTableFilterItem.Cause]?.value?.length})`,
        previews: causeTypeItems
          .filter(t => values[EComplaintTableFilterItem.Cause]?.value?.some(v => v === t.id))
          .map(t => t.name),
        value: values[EComplaintTableFilterItem.Cause]?.value || null,
        items: causeTypeItems,
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
        },
      };
    case EComplaintTableFilterItem.ExecutedBy:
      return {
        type: EDataFilterType.AutocompleteSingle,
        key: EComplaintTableFilterItem.ExecutedBy,
        label: 'Администратор',
        value: values[EComplaintTableFilterItem.ExecutedBy]?.value || null,
        preview: values[EComplaintTableFilterItem.ExecutedBy]?.value?.name || null,
        source: (name: string) =>
          name
            ? Api.user.sport
                .all({
                  name,
                  page: 1,
                  pageSize: pageSizeAll,
                  sort: ['lastName,asc', 'firstName,asc'],
                  roles: [EUserRole.EventAdmin, EUserRole.SystemAdmin, EUserRole.ComplaintAdmin],
                  statuses: [EUserStatus.Enabled, EUserStatus.NotFilled, EUserStatus.Disabled, EUserStatus.Deleted],
                })
                .then(response =>
                  response.data.content.map(item => ({ ...item, name: getUserFullName({ user: item }) }))
                )
            : Promise.resolve([]),
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
          valueAttribute: 'id',
        },
      };
  }
};

export const getStatesFilterForComplaintTableTab = (tab: EComplaintTableTab): EComplaintStatus[] => {
  switch (tab) {
    case EComplaintTableTab.All:
      return [];

    case EComplaintTableTab.UnderConsideration:
      return [EComplaintStatus.UnderConsideration, EComplaintStatus.New];

    case EComplaintTableTab.Accepted:
      return [EComplaintStatus.Accepted];

    case EComplaintTableTab.Rejected:
      return [EComplaintStatus.Rejected];
  }
};
