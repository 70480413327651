import { TextFieldProps } from '@mui/material';
import { DatePickerProps, TimePickerProps } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment-timezone';
import { EDateFormat, ETimeFormat } from '../../../../../domain/model/formats';
import { MPFormInput } from '../../input';
import { StyledDatePicker, StyledTimeInput } from './controls';

type CommonDatePickerProps = Omit<DatePickerProps<Moment | string | Date>, 'onChange' | 'views'>;
type CommonTextFieldProps = Pick<TextFieldProps, 'error' | 'helperText' | 'placeholder' | 'InputProps'>;
type MPDatePickerAdditionalProps = {
  readonly clearable?: boolean;
  readonly onChange?: (value: Nullable<Moment>) => void;
};

type MPDatePickerProps = CommonDatePickerProps & CommonTextFieldProps & MPDatePickerAdditionalProps;

export const MPDatePicker = (props: MPDatePickerProps) => {
  const {
    format = EDateFormat.DisplayDefault,
    error,
    clearable,
    helperText,
    placeholder,
    value,
    InputProps,
    onChange,
    ...others
  } = props;

  const localValue: Nullable<Moment> = value ? moment(value) : null;

  return (
    <StyledDatePicker
      {...others}
      value={localValue}
      format={format}
      localeText={{
        toolbarTitle: '',
      }}
      slots={{
        field: props => (
          //переопределяем филд, чтобы не было вообще ввода руками, и подтягивался нужный формат
          <MPFormInput
            {...props}
            error={error}
            helperText={helperText}
            placeholder={placeholder}
            value={props.value ? moment(props.value).format(format) : ''}
            onChange={undefined}
            onError={undefined}
          />
        ),
      }}
      slotProps={{
        field: { clearable, shouldRespectLeadingZeros: true },
        textField: {
          error,
          helperText,
          placeholder,
          variant: 'outlined',
          margin: 'dense',
          InputProps,
        },
        actionBar: { actions: ['clear', 'cancel', 'accept'] },
      }}
      onAccept={onChange as any}
    />
  );
};

type CommonTimePickerProps = Omit<TimePickerProps<Moment | string | Date>, 'onChange' | 'views'>;
type MPTimeInputAdditionalProps = {
  readonly onBlur?: () => void;
  readonly onChange?: (value: Nullable<Moment>) => void;
};

type MPTimeInputProps = CommonTimePickerProps & CommonTextFieldProps & MPTimeInputAdditionalProps;

export const MPTimeInput = (props: MPTimeInputProps) => {
  const {
    format = ETimeFormat.Default,
    error,
    helperText,
    placeholder,
    value,
    InputProps,
    onBlur,
    onChange,
    ...others
  } = props;

  const localValue: Nullable<Moment> = value ? moment(value) : null;

  return (
    <StyledTimeInput
      {...others}
      value={localValue}
      format={format}
      ampm={false}
      localeText={{
        toolbarTitle: '',
      }}
      slotProps={{
        textField: {
          error,
          helperText,
          placeholder,
          variant: 'outlined',
          margin: 'dense',
          InputProps,
          InputLabelProps: { shrink: !!value },
          onBlur,
        },
        actionBar: { actions: ['accept'] },
      }}
      onChange={onChange as any}
    />
  );
};
