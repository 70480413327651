import { useMemo } from 'react';
import { DataFilterAdapter } from '../../../../../../hooks/useDataFilterAdapter';
import { EPartnerTableTab, EPartnerTableColumn } from '../../../../../general/partner/types';
import { getPartnersTableAdapterKey } from '../../../../../general/partner/utils/table';
import {
  EPartnerTableFilterItem,
  getPartnerTableFilterStrategy,
  partnerTableFilterItems,
} from '../../../../../partner/filterUtils';
import { getPartnerTableColumns } from '../../../../../partner/table/utils';

type UseCmsCollectionPartnerFilterAdapter = DataFilterAdapter<EPartnerTableColumn, EPartnerTableFilterItem>;

const tab = EPartnerTableTab.Active;

const useCmsCollectionPartnerFilterAdapter = (): UseCmsCollectionPartnerFilterAdapter => {
  const sourceColumns = useMemo(() => getPartnerTableColumns(tab), []);

  const { key, keyPartitions } = useMemo(() => getPartnersTableAdapterKey({ tab }), []);

  return useMemo<DataFilterAdapter<EPartnerTableColumn, EPartnerTableFilterItem>>(
    () => ({
      key,
      keyPartitions,
      sourceColumns: sourceColumns,
      filterItems: partnerTableFilterItems,
      requiredFilters: [EPartnerTableFilterItem.Query],
      getDataFilterStrategy: getPartnerTableFilterStrategy,
    }),
    [key, keyPartitions, sourceColumns]
  );
};

export default useCmsCollectionPartnerFilterAdapter;
