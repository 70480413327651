import common, { CustomerCommonService } from './common';

type CustomerServices = {
  common: CustomerCommonService;
};

const customerService: CustomerServices = {
  common,
};

export default customerService;
