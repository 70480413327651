import { Fade, Grid } from '@mui/material';
import { FCC, ForwardRefExoticComponent, ReactNode, useState } from 'react';
import Splitter from '../../components/common/splitter';
import TableScrollbarOverlay from '../../components/common/table/scrollbarOverlay';
import { DefaultContentWrapper } from '../../components/common/wrappers/content';
import { ContainerWrapper, TableWrapper, TabsWrapper } from './controls';

interface TableCommonLayoutProps {
  readonly alwaysViewFilter?: boolean;
  readonly canViewTable?: boolean;
  readonly header?: ReactNode;
  readonly filter?: ReactNode;
  readonly tabs?: ReactNode;
  readonly toolbar?: ReactNode;
  readonly table?: ReactNode;
  readonly footer?: ForwardRefExoticComponent<any>;
  readonly stub?: ReactNode;
}

const TableCommonLayout: FCC<TableCommonLayoutProps> = props => {
  const {
    canViewTable = true,
    alwaysViewFilter = false,
    header,
    filter,
    tabs,
    toolbar,
    table,
    footer: Footer,
    stub,
    children,
  } = props;

  const [footerRef, setFooterRef] = useState<HTMLElement | undefined>();

  return (
    <Fade in>
      <DefaultContentWrapper
        type='table'
        fullHeight
        stickyHeader
        footer={Footer && <Footer ref={(ref: any) => setFooterRef(ref)} />}
      >
        <ContainerWrapper>
          <Grid
            container
            spacing={2}
            direction='column'
          >
            {header && <Grid item>{header}</Grid>}
            {((canViewTable && filter) || (alwaysViewFilter && filter)) && (
              <Grid item>
                <Splitter
                  variant='horizontal'
                  size={1}
                />
                {filter}
              </Grid>
            )}
            {canViewTable && tabs && <TabsWrapper item>{tabs}</TabsWrapper>}
            {canViewTable && toolbar && <Grid item>{toolbar}</Grid>}
          </Grid>
          {canViewTable && table && (
            <TableScrollbarOverlay bottomAnchor={footerRef}>
              <TableWrapper>{table}</TableWrapper>
            </TableScrollbarOverlay>
          )}
          {stub}
        </ContainerWrapper>
        {children}
      </DefaultContentWrapper>
    </Fade>
  );
};

export default TableCommonLayout;
