import { useContext } from 'react';
import BookingOfferActionsContext, { BookingOfferActionsContextType } from './context';

export const useBookingOfferActions = (): BookingOfferActionsContextType => {
  return useContext<BookingOfferActionsContextType>(BookingOfferActionsContext);
};

export const useBookingOfferActionsSelectors = (): BookingOfferActionsContextType['utils']['selectors'] => {
  return useBookingOfferActions().utils.selectors;
};

