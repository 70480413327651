import { Nullable, UUID } from '../../../../../domain/model/types';
import { EOfferTableTab } from '../../../general/offer/types';
import useBookingOffersTableCount from '../hooks/useCount';
import useBookingOffersTableSearch from '../hooks/useSearch';
import useBookingOffersTableSession from '../hooks/useSession';

interface BookingOfferBehaviorAdapterProps {
  readonly guid: UUID;
  readonly tab: EOfferTableTab;
  readonly partnerId: Nullable<UUID>;
}

export const BookingOfferBehaviorAdapter = ({ guid, tab, partnerId }: BookingOfferBehaviorAdapterProps) => {
  useBookingOffersTableSession({ guid, tab });
  useBookingOffersTableSearch({ tab, partnerId });
  useBookingOffersTableCount({ partnerId });

  return null;
};
