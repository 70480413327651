import {
  Address,
  ClientOrg,
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterStrategyView,
  DataFilterValueItem,
  EDataFilterType,
  EDateFormat,
  EFilterMultiSelectorValueType,
  FilterMultiSelectorPredicate,
  pageSizeAll,
  ProductCategory,
  ProductCategoryAttribute,
} from '@/domain';
import moment from 'moment';
import Api from '../../../../../data/api';
import { AddressHelper } from '../../../../utils/address';
import { EProductTableColumn } from '../utils';

export enum EProductTableFilterItem {
  Query = 'query',
  CreatedAt = 'createdAt',
  DeskNumber = 'productDesk.code',
  Brand = 'brand.id',
  PartnerSKU = 'partnerSKU',
  ProducerSKU = 'producerSKU',
  Name = 'name',
  TargetLocalities = 'places',
  TargetExternalUsers = 'targetExternalUsers',
  TargetClientOrgs = 'targetClientOrgs',
  Price = 'price',
  OriginalPrice = 'originalPrice',
}

export type ProductTableFilterEditStrategy = DataFilterStrategyBase<EProductTableFilterItem>;
export type ProductTableFilterViewStrategy = DataFilterStrategyView<EProductTableFilterItem>;

type ProductTableFilterDynamicValues = Partial<Record<string, DataFilterValueItem<any>>>;

export type ProductTableFilterValues = ProductTableFilterDynamicValues & {
  readonly [EProductTableFilterItem.Query]?: DataFilterValueItem<Nullable<string>>;
  readonly [EProductTableFilterItem.CreatedAt]?: DataFilterValueItem<Nullable<[string, string]>>;
  readonly [EProductTableFilterItem.DeskNumber]?: DataFilterValueItem<Nullable<string>>;
  readonly [EProductTableFilterItem.PartnerSKU]?: DataFilterValueItem<Nullable<string>>;
  readonly [EProductTableFilterItem.ProducerSKU]?: DataFilterValueItem<Nullable<string>>;
  readonly [EProductTableFilterItem.Name]?: DataFilterValueItem<Nullable<string>>;
  readonly [EProductTableFilterItem.Brand]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
  readonly [EProductTableFilterItem.TargetLocalities]?: DataFilterValueItem<
    Nullable<FilterMultiSelectorPredicate<Address>>
  >;
  readonly [EProductTableFilterItem.TargetClientOrgs]?: DataFilterValueItem<
    Nullable<FilterMultiSelectorPredicate<ClientOrg>>
  >;
  readonly [EProductTableFilterItem.TargetExternalUsers]?: DataFilterValueItem<Nullable<true>>;
  readonly [EProductTableFilterItem.Price]?: DataFilterValueItem<Nullable<number>>;
  readonly [EProductTableFilterItem.OriginalPrice]?: DataFilterValueItem<Nullable<number>>;
};

export const getProductTableFilterItems = (
  category: Nullable<ProductCategory>
): Record<EProductTableColumn, EProductTableFilterItem[]> => {
  return {
    [EProductTableColumn.Name]: [EProductTableFilterItem.Name],
    [EProductTableColumn.CreatedAt]: [EProductTableFilterItem.CreatedAt],
    [EProductTableColumn.DeskNumber]: [EProductTableFilterItem.DeskNumber],
    [EProductTableColumn.Brand]: [EProductTableFilterItem.Brand],
    [EProductTableColumn.TargetLocalities]: [EProductTableFilterItem.TargetLocalities],
    [EProductTableColumn.TargetClientOrgs]: [EProductTableFilterItem.TargetClientOrgs],
    [EProductTableColumn.TargetExternalUsers]: [EProductTableFilterItem.TargetExternalUsers],
    [EProductTableColumn.Image]: [],
    [EProductTableColumn.Category]: [],
    [EProductTableColumn.PartnerSKU]: [EProductTableFilterItem.PartnerSKU],
    [EProductTableColumn.ProducerSKU]: [EProductTableFilterItem.ProducerSKU],
    [EProductTableColumn.Price]: [EProductTableFilterItem.Price],
    [EProductTableColumn.OriginalPrice]: [EProductTableFilterItem.OriginalPrice],
    [EProductTableColumn.Attributes]: [],
    [EProductTableColumn.Stock]: [],
    [EProductTableColumn.Moderator]: [],
    [EProductTableColumn.PausedReason]: [],
    [EProductTableColumn.LastStatusAuthor]: [],
    ...expandProductTableFilterItems(category),
  };
};

const getPlacesPreview = (places: Nullable<FilterMultiSelectorPredicate<Address>>) => {
  if (!places) return null;

  switch (places.select) {
    case EFilterMultiSelectorValueType.All:
      return 'Вся Россия';
    case EFilterMultiSelectorValueType.None:
      return 'Город не выбран';
    case EFilterMultiSelectorValueType.In:
      return `Город (${places.in?.length})` || null;
  }
};

const getClientOrgsPreview = (clientOrgs: Nullable<FilterMultiSelectorPredicate<ClientOrg>>) => {
  if (!clientOrgs) return null;

  switch (clientOrgs.select) {
    case EFilterMultiSelectorValueType.All:
      return 'Все компании';
    case EFilterMultiSelectorValueType.None:
      return 'Компания не выбрана';
    case EFilterMultiSelectorValueType.In:
      return `Компания (${clientOrgs.in?.length})` || null;
  }
};

export const getProductTableFilterStrategy = (
  filterItem: EProductTableFilterItem,
  values: ProductTableFilterValues,
  category: Nullable<ProductCategory>
): Nullable<ProductTableFilterEditStrategy> => {
  //с характеристиками категорий фильтры динамические, поэтому тут костыли
  const categoryAttribute = category?.attributes?.find(a => a?.attribute?.name === filterItem);
  if (categoryAttribute) {
    return getProductTableCategoryAttributeFilterStrategy(filterItem, values, categoryAttribute);
  }

  switch (filterItem) {
    case EProductTableFilterItem.Query:
      return {
        type: EDataFilterType.String,
        key: EProductTableFilterItem.Query,
        label: 'Поиск по ключевому слову',
        preview: values[EProductTableFilterItem.Query]?.value || null,
        value: values[EProductTableFilterItem.Query]?.value || null,
      };
    case EProductTableFilterItem.DeskNumber:
      const deskNumber = values[EProductTableFilterItem.DeskNumber]?.value;
      return {
        type: EDataFilterType.String,
        key: EProductTableFilterItem.DeskNumber,
        label: '№ карточки',
        preview: deskNumber ? `№ карточки: ${deskNumber}` : null,
        value: deskNumber || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Equals,
        },
      };
    case EProductTableFilterItem.Name:
      return {
        type: EDataFilterType.String,
        key: EProductTableFilterItem.Name,
        label: 'Наименование товара',
        preview: values[EProductTableFilterItem.Name]?.value || null,
        value: values[EProductTableFilterItem.Name]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case EProductTableFilterItem.PartnerSKU:
      const partnerSKU = values[EProductTableFilterItem.PartnerSKU]?.value;
      return {
        type: EDataFilterType.String,
        key: EProductTableFilterItem.PartnerSKU,
        label: 'Ваш SKU',
        preview: partnerSKU ? `Ваш SKU: ${partnerSKU}` : null,
        value: partnerSKU || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Equals,
        },
      };
    case EProductTableFilterItem.ProducerSKU:
      const producerSKU = values[EProductTableFilterItem.ProducerSKU]?.value;
      return {
        type: EDataFilterType.String,
        key: EProductTableFilterItem.ProducerSKU,
        label: 'Артикул производителя',
        preview: producerSKU ? `Артикул производителя: ${producerSKU}` : null,
        value: producerSKU || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Equals,
        },
      };
    case EProductTableFilterItem.Price:
      const price = values[EProductTableFilterItem.Price]?.value;
      return {
        decimal: false,
        type: EDataFilterType.Number,
        key: EProductTableFilterItem.Price,
        label: 'Цена',
        preview: price ? `Цена: ${price.toString()}` : null,
        value: price || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Equals,
        },
      };
    case EProductTableFilterItem.OriginalPrice:
      const originalPrice = values[EProductTableFilterItem.OriginalPrice]?.value;
      return {
        decimal: false,
        type: EDataFilterType.Number,
        key: EProductTableFilterItem.OriginalPrice,
        label: 'Цена до скидки',
        preview: originalPrice ? `Цена до скидки: ${originalPrice.toString()}` : null,
        value: originalPrice || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Equals,
        },
      };
    case EProductTableFilterItem.CreatedAt:
      return {
        type: EDataFilterType.DateAsTimePeriod,
        key: EProductTableFilterItem.CreatedAt,
        label: 'Дата создания',
        preview:
          moment(values[EProductTableFilterItem.CreatedAt]?.value?.[0]).format(EDateFormat.DisplayDefault) || null,
        value: values[EProductTableFilterItem.CreatedAt]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Between,
        },
      };
    case EProductTableFilterItem.TargetLocalities:
      return {
        type: EDataFilterType.MultiSelectorPredicateAddress,
        key: EProductTableFilterItem.TargetLocalities,
        label: 'Города',
        value: values[EProductTableFilterItem.TargetLocalities]?.value || null,
        preview: getPlacesPreview(values[EProductTableFilterItem.TargetLocalities]?.value ?? null),
        previews:
          values[EProductTableFilterItem.TargetLocalities]?.value?.in?.map(
            locality => new AddressHelper(locality).getLastLocalityShortPath() ?? locality.name
          ) ?? null,
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
          valueAttribute: 'id',
        },
      };
    case EProductTableFilterItem.TargetClientOrgs:
      return {
        type: EDataFilterType.MultiSelectorPredicateClientOrg,
        key: EProductTableFilterItem.TargetClientOrgs,
        label: 'Компания',
        value: values[EProductTableFilterItem.TargetClientOrgs]?.value || null,
        preview: getClientOrgsPreview(values[EProductTableFilterItem.TargetClientOrgs]?.value ?? null),
        previews: values[EProductTableFilterItem.TargetClientOrgs]?.value?.in?.map(clientOrg => clientOrg.name) ?? null,
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
          valueAttribute: 'id',
        },
      };
    case EProductTableFilterItem.TargetExternalUsers:
      return {
        type: EDataFilterType.BooleanFlag,
        key: EProductTableFilterItem.TargetExternalUsers,
        label: 'Для внешних пользователей',
        preview: values[EProductTableFilterItem.TargetExternalUsers]?.value ? 'Для внешних пользователей' : null,
        value: values[EProductTableFilterItem.TargetExternalUsers]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Equals,
        },
      };
    case EProductTableFilterItem.Brand:
      return {
        type: EDataFilterType.AutocompleteMultiple,
        key: EProductTableFilterItem.Brand,
        label: 'Бренд',
        value: values[EProductTableFilterItem.Brand]?.value || null,
        preview: `Бренд (${values[EProductTableFilterItem.Brand]?.value?.length})` || null,
        previews: values[EProductTableFilterItem.Brand]?.value?.map(t => t.name) || null,
        source: (name: string) =>
          name
            ? Api.product.brand
                .all({
                  querydsl: [
                    {
                      field: 'name',
                      operator: DataFilterQueryDslOperator.Like,
                      value: [`${name}*`],
                    },
                  ],
                  sort: 'name,asc',
                  page: 1,
                  pageSize: pageSizeAll,
                })
                .then(response => response.data)
            : Promise.resolve([]),
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
          valueAttribute: 'id',
        },
      };
    default:
      return null;
  }
};

const getProductTableCategoryAttributeFilterStrategy = (
  filterItem: EProductTableFilterItem,
  values: ProductTableFilterValues,
  categoryAttribute: ProductCategoryAttribute
): Nullable<ProductTableFilterEditStrategy> => {
  const attribute = categoryAttribute?.attribute;
  if (attribute) {
    if (attribute.dictionary) {
      return {
        type: EDataFilterType.AutocompleteMultiple,
        key: attribute.name as any,
        label: attribute.name,
        preview: `${attribute.name}: ${values[attribute.name]?.value?.length || null}`,
        previews: values[attribute.name]?.value?.map((t: { name: string }) => t.name),
        value: values[attribute.name]?.value || null,
        source: async () => {
          return Api.common.dictionaries
            .valueById({
              dictionaryId: attribute.dictionary!.id,
            })
            .then(response => response.data.map(item => ({ id: item.id, name: item.value }))) as any;
        },
        loadOnOpening: true,
      };
    } else
      return {
        type: EDataFilterType.String,
        key: attribute.name as any,
        label: attribute.name,
        preview: `${attribute.name}: ${values[attribute.name]?.value || null}`,
        value: values[attribute.name]?.value || null,
      };
  }
  return null;
};

const expandProductTableFilterItems = (category: Nullable<ProductCategory>): Partial<Record<string, string[]>> => {
  const items: Partial<Record<string, string[]>> = {};
  if (category) {
    category.attributes?.forEach(attribute => {
      if (attribute?.attribute) {
        items[attribute.attribute.name] = [attribute.attribute.name];
      }
    });
  }
  return items;
};
