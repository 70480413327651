import { createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Complaint } from '../../../../../domain/model/complaint';
import { Nullable, UUID } from '../../../../../domain/model/types';

export const complaintByIdFetch = createAsyncThunk<Complaint, { id: UUID }, AppThunkAPIConfig>(
  'complaint/details/fetch',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await Api.complaint.one({ id });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const executeComplaint = createAsyncThunk<Complaint, { id: UUID }, AppThunkAPIConfig>(
  'complaint/details/execute',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await Api.complaint.execute({ id });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const approveComplaint = createAsyncThunk<Complaint, { id: UUID }, AppThunkAPIConfig>(
  'complaint/details/approve',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await Api.complaint.approve({ id });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const rejectComplaint = createAsyncThunk<Complaint, { id: UUID; reason?: string }, AppThunkAPIConfig>(
  'complaint/details/reject',
  async ({ id, reason }, { rejectWithValue }) => {
    try {
      const { data } = await Api.complaint.reject({ id, reason });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface ComplaintDetailsState {
  byId: Fetchable & {
    data: Nullable<Complaint>;
  };
}

const slice = createSlice<ComplaintDetailsState, SliceCaseReducers<ComplaintDetailsState>, 'details'>({
  name: 'details',
  initialState: {
    byId: {
      ...fetchableDefault,
      data: null,
    },
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(complaintByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.data = null;
      })
      .addCase(complaintByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;
        state.byId.data = payload;
      })
      .addCase(complaintByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
          state.byId.data = null;
        }
      })
      .addCase(executeComplaint.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
      })
      .addCase(executeComplaint.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;
        state.byId.data = payload;
      })
      .addCase(executeComplaint.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        }
      })
      .addCase(rejectComplaint.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
      })
      .addCase(rejectComplaint.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;
        state.byId.data = payload;
      })
      .addCase(rejectComplaint.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        }
      })
      .addCase(approveComplaint.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
      })
      .addCase(approveComplaint.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;
        state.byId.data = payload;
      })
      .addCase(approveComplaint.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        }
      });
  },
});

export default slice.reducer;
