import {
  ApprovalOfferRegistryRequest,
  CorpOfferRequest,
  CorpOfferShort,
  CorpOfferStoreResponsiblePerson,
  ECorpPersonalPromotionType,
  EMultiSelectorValueType,
  EPartnerType,
  ETargetType,
  ImageRestrictions,
  OfferCountInfo,
  PartnerResponsiblePersonRequest,
  Target,
  TargetRequest,
} from '@/domain';
import moment from 'moment';
import validator from 'validator';
import { fileTypes } from '../../../constants';
import { EValidationMessages, EValidationType, ValidationRules } from '../../../utils/validation';
import { isCorpOfferApprovalRegistryRequired } from '../utils';

export enum ECorpOfferCreateDialogState {
  FileUpload = 'fileUpload',
  CodesDelete = 'codesDelete',
  History = 'history',
}

export const corpOfferImagesMaxCount = 20;
export const corpOfferNameMaxLength = 120 as const;

export const corpOfferImageRestrictions: ImageRestrictions = {
  recommendedWidthInPx: 964,
  recommendedHeightInPx: 660,
  maxFileSizeInBytes: 1 * 1024 * 1024,
  whStrict: true,
  fileAccept: [fileTypes.jpg.ext, fileTypes.jpeg.ext, fileTypes.png.ext],
  fileType: [fileTypes.jpg.mimeType, fileTypes.jpeg.mimeType, fileTypes.png.mimeType],
};

export const getDefaultActivationExpiryDays = (partnerType: EPartnerType): number => {
  switch (partnerType) {
    case EPartnerType.Inner:
      return 90;
    case EPartnerType.External:
      return 0;
  }
};

export enum ECorpOfferValidationMessages {
  PersonalCodesNotLoaded = 'Необходимо загрузить базу данных кодов',
  TypeNumberOrClearDate = 'Укажите номер или удалите дату',
  TypeDateOrClearNumber = 'Укажите дату или удалите номер',
}

export const corpOfferCreateGeneralStepValidation: ValidationRules<CorpOfferRequest> = {
  name: {
    required: true,
  },
  categories: {
    required: true,
    validator: (object, value) => {
      if (!value?.length || value.length > 1) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: `Выберите категорию`,
        };
      } else {
        return null;
      }
    },
  },
  images: {
    validator: (object, value) => {
      if (!value || value.length === 0) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Загрузите как минимум 1 изображение',
        };
      }

      return null;
    },
    required: true,
  },
  price: {
    required: true,
  },
  originalPrice: {
    required: false,
    validator: (object, value) => {
      if (value === null) return null;

      if (object.price !== null && value < object.price) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Значение должно быть не меньше стоимости в бонусных баллах',
        };
      } else {
        return null;
      }
    },
  },
  ndflDescription: {
    required: object => !!object.hasNdfl,
  },
  startDate: {
    required: true,
  },
  endDate: {
    required: true,
    validator: (object, value) => {
      if (!value || !object.startDate) return null;
      if (moment(value).isBefore(object.startDate)) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Дата окончания меньше даты начала',
        };
      } else {
        return null;
      }
    },
  },
  activationExpiryDays: {
    required: object => object.promotionType === ECorpPersonalPromotionType.Certificate,
  },
  promotionType: {
    required: true,
  },
  externalCorpOfferType: {
    required: object => object.promotionType === ECorpPersonalPromotionType.ExternalCertificate,
  },
  externalActivationLink: {
    required: object => object.promotionType === ECorpPersonalPromotionType.ExternalCertificate,
    validator: (object, value) => {
      if (value === null || validator.isEmpty(value)) return null;

      const isURL = validator.isURL(value);
      if (!isURL) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EValidationMessages.URLInvalid,
        };
      } else {
        return null;
      }
    },
  },
};

export const corpOfferCreateDescriptionStepValidation: ValidationRules<CorpOfferRequest> = {
  description: {
    required: true,
  },
};

export const corpOfferCreateConditionsStepValidation: ValidationRules<CorpOfferRequest> = {
  conditions: {
    required: true,
  },
};

export const corpOfferCreatePersonalPromotionStepValidation: ValidationRules<OfferCountInfo> = {
  offerCount: {
    required: true,
    validator: (object, value) => {
      if (value === null) return null;

      if (value === 0) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: ECorpOfferValidationMessages.PersonalCodesNotLoaded,
        };
      } else {
        return null;
      }
    },
  },
};

export const getCorpOfferCreateApprovalRegistryValidation = (
  corpOffer: CorpOfferShort
): ValidationRules<ApprovalOfferRegistryRequest> => {
  if (isCorpOfferApprovalRegistryRequired(corpOffer)) return corpOfferCreateApprovalRegistryRequiredValidation;
  else return corpOfferCreateApprovalRegistryNotRequiredValidation;
};

const corpOfferCreateApprovalRegistryRequiredValidation: ValidationRules<ApprovalOfferRegistryRequest> = {
  number: {
    required: true,
  },
  date: {
    required: true,
  },
};

export const corpOfferCreateResponsiblePersonValidation: ValidationRules<PartnerResponsiblePersonRequest> = {
  fullName: {
    required: true,
  },
  phone: {
    required: true,
  },
};

export const corpOfferCreateStoreResponsiblePersonValidation: ValidationRules<CorpOfferStoreResponsiblePerson> = {
  fullName: {
    required: object => !object.responsiblePersonLink,
  },
  phone: {
    required: object => !object.responsiblePersonLink,
  },
  schedule: {
    required: true,
  },
};

const corpOfferCreateApprovalRegistryNotRequiredValidation: ValidationRules<ApprovalOfferRegistryRequest> = {
  number: {
    validator: (object, value) => {
      if (!value && object.date) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: ECorpOfferValidationMessages.TypeNumberOrClearDate,
        };
      }
      return null;
    },
  },
  date: {
    validator: (object, value) => {
      if (!value && object.number) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: ECorpOfferValidationMessages.TypeDateOrClearNumber,
        };
      }
      return null;
    },
  },
};

export const corpOfferCreateTargetValidation: ValidationRules<TargetRequest> = {
  targetGender: {
    required: true,
    validator: (object, value) => {
      if (!value || (value.select === EMultiSelectorValueType.In && !value.in?.length)) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Выберите пол или <<Для всех>>',
        };
      }

      return null;
    },
  },
  targetLocalities: {
    required: object => object.targetType === ETargetType.Geo,
    validator: (object, value) => {
      if (object.targetType !== ETargetType.Geo) return null;

      if (!value || (value.select === EMultiSelectorValueType.In && !value.in?.length)) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Выберите города или <<Вся Россия>>',
        };
      }

      return null;
    },
  },
  targetRoads: {
    validator: (object, value) => {
      if (object.targetType !== ETargetType.Corp) return null;

      if (
        (!value || (value.select === EMultiSelectorValueType.In && !value.in?.length)) &&
        (!object.targetOrgUnits ||
          (object.targetOrgUnits.select === EMultiSelectorValueType.In && !object.targetOrgUnits.in?.length))
      ) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message:
            'Обязательно для заполнения значение хотя бы одного из полей: "Железные дороги" или "Филиал ОАО "РЖД"',
        };
      }

      return null;
    },
  },
  targetOrgUnits: {
    validator: (object, value) => {
      if (object.targetType !== ETargetType.Corp) return null;

      if (
        (!value || (value.select === EMultiSelectorValueType.In && !value.in?.length)) &&
        (!object.targetRoads ||
          (object.targetRoads.select === EMultiSelectorValueType.In && !object.targetRoads.in?.length))
      ) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message:
            'Обязательно для заполнения значение хотя бы одного из полей: "Железные дороги" или "Филиал ОАО "РЖД"',
        };
      }

      return null;
    },
  },
};

export const getCorpOfferCreateEmptyTarget = (): Target => ({
  targetType: ETargetType.Geo,
  targetRoads: null,
  targetLocalities: null,
  targetOrgUnits: null,
  targetGender: { select: EMultiSelectorValueType.All, in: null },
  targetClientOrgs: null,
  targetExternalUsers: null,
  targetHavingChildFamilyMemberOnly: null,
  targetTradeUnionMembersOnly: null,
  targetFamilyMemberOnly: null,
  targetHasRzdSocialPackage: null,
});
