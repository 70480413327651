import { Team } from '../../../../domain/model/team';

const empty = (value: any, text = 'Не заполнено') => (value == null || value.length === 0) && text;

export const validateForm = (team: Team) => {
  const errors: { [key: string]: string | boolean } = {};

  if (!errors.activityTypes) {
    errors.activityTypes = empty(team.activityTypes, 'Выберите интересы');
  }

  if (!errors.name) {
    errors.name = empty(team.name, 'Введите название команды');
  }

  if (!errors.city) {
    errors.city = empty(team.locality, 'Введите название населенного пункта');
  }

  if (!errors.nickname) {
    errors.nickname = empty(team.nickname, 'Введите никнейм команды');
  }

  return errors;
};

export const hasErrors = (errors: any): boolean => {
  if (errors) {
    if (typeof errors === 'number' || (typeof errors === 'string' && errors.length > 0)) {
      return true;
    }

    if (typeof errors === 'object') {
      return Object.values(errors).filter(hasErrors).length > 0;
    }

    return true;
  }

  return false;
};
