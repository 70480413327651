import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Address } from '../../../../../../domain/model/address';
import { EPartnerOwnershipType } from '../../../../../../domain/model/enums';
import { Nullable } from '../../../../../../domain/model/types';
import ValidationErrorPanel from '../../../../../components/common/validation/errorPanel';
import { createEmptyDraftAddress } from '../../../../../utils/address';
import { ValidationResult } from '../../../../../utils/validation';
import AddressFullSingleSelector from '../../../../address/components/selector/single/full';
import { getPartnerAddressLabelByOrgType } from '../../../../general/partner/utils/common';
import { Content } from '../../controls';

interface PartnerEditStepAddressProps {
  readonly orgType: Nullable<EPartnerOwnershipType>;
  readonly address: Address;
  readonly isValid: boolean;
  readonly validation: Nullable<ValidationResult<Address>>;
  readonly onChangeAddress: (address: Address) => void;
}

const PartnerEditStepAddress = (props: PartnerEditStepAddressProps) => {
  const { address, orgType, isValid, onChangeAddress, validation } = props;

  const getAddressValidationResult = (): Nullable<ValidationResult<Address>> => {
    const result = validation;

    if (!result) return null;

    return {
      hierarchy: result.hierarchy,
    };
  };

  return (
    <Content>
      <Grid
        container
        spacing={2}
        direction='column'
      >
        <Grid item>
          <Typography variant='h3'>{getPartnerAddressLabelByOrgType(orgType)}</Typography>
        </Grid>

        <Grid item>
          <AddressFullSingleSelector
            value={address}
            error={getAddressValidationResult()?.hierarchy?.hasError}
            helperText={getAddressValidationResult()?.hierarchy?.message}
            label={'Адрес'}
            onChange={address => {
              onChangeAddress(address ?? createEmptyDraftAddress());
            }}
          />
        </Grid>

        {!isValid && (
          <Grid item>
            <ValidationErrorPanel />
          </Grid>
        )}
      </Grid>
    </Content>
  );
};

export default PartnerEditStepAddress;
