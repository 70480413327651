import { Grid, Typography } from '@mui/material';
import { Partner } from '../../../../../../domain/model/partner';
import { AddressHelper } from '../../../../../utils/address';

interface PartnerDetailsInfoAddressProps {
  readonly partner: Partner;
}

const PartnerDetailsInfoAddress = ({ partner }: PartnerDetailsInfoAddressProps) => {
  const { id } = partner.address;

  return (
    <Grid
      container
      spacing={3}
      direction='column'
    >
      {id && (
        <Grid item>
          <Typography
            variant='body2'
            color='textSecondary'
          >
            Область / Республика / Край
          </Typography>
          <Typography>{new AddressHelper(partner.address).getLocalityRegion()}</Typography>
        </Grid>
      )}
      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          Адрес
        </Typography>
        <Typography>{new AddressHelper(partner.address).getFullPathWithPostalCode()}</Typography>
      </Grid>
    </Grid>
  );
};

export default PartnerDetailsInfoAddress;
