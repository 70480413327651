import { SportOptionTyped } from '../../../../../domain/model';
import { EOfferStatus } from '../../../../../domain/model/enums';
import TradeOfferTableCell, { TradeOfferTableCellCommonProps } from './index';

export type TradeOfferTableCellStatusProps = TradeOfferTableCellCommonProps & {
  readonly offerStatuses: SportOptionTyped<EOfferStatus>[];
};

const TradeOfferTableCellStatus = (props: TradeOfferTableCellStatusProps) => {
  const {
    tradeOffer: { status },
    offerStatuses,
  } = props;

  const value = offerStatuses.find(s => s.id === status)?.name ?? '-';

  return (
    <TradeOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default TradeOfferTableCellStatus;
