import { DataTableMetadata } from '@components/common/table';
import { useCallback, useMemo } from 'react';
import useDataTableSettings from '../../../../hooks/useDataTableSettings';
import { EBookingOrderTableColumn, EBookingOrderTableTab } from '../../types';
import { getBookingOrderTableVisibleColumns } from '../utils';
import useTechConfig from '@/presentation/hooks/useTechConfig';
import { EAppFeature } from '@/presentation/types';

type UseBookingOrderTableMetadataProps = {
  readonly tab: EBookingOrderTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly isPartnerUser: boolean;
  readonly sortColumn: Nullable<EBookingOrderTableColumn>;
  readonly onSortReset: () => void;
};

type UseBookingOrderTableMetadata = {
  readonly metadata: DataTableMetadata<EBookingOrderTableColumn>;
  readonly onChangeMetadata: (metadata: DataTableMetadata<EBookingOrderTableColumn>) => void;
};

export const useBookingOrderTableMetadata = (
  props: UseBookingOrderTableMetadataProps
): UseBookingOrderTableMetadata => {
  const { tab, partnerId, isPartnerUser, sortColumn, onSortReset } = props;
  const { hasFeature } = useTechConfig();

  const columns = useMemo(
    () => getBookingOrderTableVisibleColumns(tab, partnerId, hasFeature(EAppFeature.Csp)),
    [tab, partnerId]
  );

  const keyPartitions = useMemo(
    () => (isPartnerUser ? ['partner', tab] : ['admin', partnerId ? 'byPartner' : 'common', tab]),
    [partnerId, isPartnerUser, tab]
  );

  const onColumnsRemoved = useCallback(
    (columns: EBookingOrderTableColumn[]) => {
      if (sortColumn && columns.includes(sortColumn)) {
        onSortReset();
      }
    },
    [sortColumn, columns, onSortReset]
  );

  const { metadata, onChangeMetadata } = useDataTableSettings<EBookingOrderTableColumn>({
    name: 'order-booking',
    nameParts: keyPartitions,
    sourceColumns: columns,
    onColumnsRemoved,
  });

  return {
    metadata,
    onChangeMetadata,
  };
};
