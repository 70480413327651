import { FCC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ActivityTypesEditContainerProps } from './container';
import { activityTypesEditFetch, activityTypesEditResetState } from './store/slice';

type ActivityTypesEditInitializerProps = ActivityTypesEditContainerProps;

export const ActivityTypesEditInitializer: FCC<ActivityTypesEditInitializerProps> = ({ children }) => {
  const dispatch = useDispatch();

  //загрузка основных данных
  useEffect(() => {
    const requests = [dispatch(activityTypesEditFetch())];
    return () => {
      requests.forEach(request => request.abort());
    };
  }, [dispatch]);

  //очистка данных
  useEffect(() => {
    return () => {
      dispatch(activityTypesEditResetState());
    };
  }, [dispatch]);

  return <>{children}</>;
};
