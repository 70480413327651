import { DataFilterStrategyYear, Nullable } from '@/domain';
import { systemStartYear } from '@/presentation/constants';
import { DataFilterEditItemProps } from '@components/common/dataFilter/edit';
import { MPAutocompleteSingleSelect } from '@ui-kit/autocomplete/single';
import { useEffect, useMemo, useState } from 'react';

type YearOption = {
  readonly id: number;
  readonly name: string;
};

interface DataFilterEditItemYearProps<T extends string> extends DataFilterEditItemProps<T, Nullable<number>> {
  readonly strategy: DataFilterStrategyYear<T>;
}

function DataFilterEditItemYear<T extends string>(props: DataFilterEditItemYearProps<T>) {
  const { strategy, onChange } = props;

  const { label, value, readOnly, min = systemStartYear, max = new Date().getFullYear(), years } = strategy;

  const [searchValue, setSearchValue] = useState<Nullable<string>>(null);

  const onSubmitValue = (newValue: Nullable<YearOption>) => {
    onChange(strategy, newValue?.id ?? null);
  };

  useEffect(() => {
    setSearchValue(null);
  }, [strategy]);

  const allItems = useMemo<YearOption[]>(
    () =>
      years?.length
        ? years.map(year => ({
            id: year,
            name: year.toFixed(),
          }))
        : max >= min
        ? Array.from(new Array(max - min + 1), (val, index) => ({
            id: min + index,
            name: (min + index).toFixed(),
          }))
        : [],
    [years, min, max]
  );

  const items = allItems.filter(item => item.name.toLowerCase().includes(searchValue?.toLowerCase() ?? '')) ?? [];

  return (
    <MPAutocompleteSingleSelect<YearOption, number>
      value={value ? items.find(item => item.id === value)! : null}
      label={label}
      disabled={readOnly}
      options={items}
      onSearchValue={newValue => setSearchValue(newValue)}
      onChangeValue={onSubmitValue}
    />
  );
}

export default DataFilterEditItemYear;
