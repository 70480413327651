import CmsContainerTableCell, { CmsContainerTableCellCommonProps } from './index';

type CmsContainerTableCellTargetProps = CmsContainerTableCellCommonProps;

const visibleCount = 3;

const CmsContainerTableCellTarget = (props: CmsContainerTableCellTargetProps) => {
  const {
    cmsContainer: { targetItems },
  } = props;

  const count = targetItems?.length ?? 0;

  const items = targetItems?.slice(0, visibleCount)?.join(', ') ?? '';
  const hiddenLabel = count > visibleCount ? `+${count - visibleCount}` : null;

  const value = [items, hiddenLabel].filter(item => !!item).join(', ');

  return (
    <CmsContainerTableCell
      {...props}
      value={value}
    />
  );
};

export default CmsContainerTableCellTarget;
