import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../../data/store/types';
import { DataTreeItem, DataTreeItemMutable, OfferCategory } from '../../../../../../domain/model';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import service from '../../services';
import { buildTradeOfferCategoryTree } from '../../utils';
import { EOfferStatus } from '../../../../../../domain/model/enums';

export const tradeOfferCategoriesSelectFetch = createAsyncThunk<
  OfferCategory[],
  {
    onlyUsed?: boolean;
    partnerId?: UUID;
    statuses?: EOfferStatus[];
  },
  AppThunkAPIConfig
>('tradeOfferCategory/edit/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    return await service.all({ ...props, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface TradeOfferCategoriesSelectState {
  readonly fetch: Fetchable & {
    readonly data: Nullable<DataTreeItem<OfferCategory>[]>;
  };
  readonly selected: Nullable<OfferCategory[]>;
  readonly selectedItems: Nullable<DataTreeItem<OfferCategory>[]>;
}

type Reducer<T = undefined> = CaseReducer<TradeOfferCategoriesSelectState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<TradeOfferCategoriesSelectState> {
  readonly tradeOfferCategoriesSelectStartSession: Reducer<{ selected: Nullable<OfferCategory[]> }>;
  readonly tradeOfferCategoriesSelectSelect: Reducer<OfferCategory>;
  readonly tradeOfferCategoriesSelectUnselect: Reducer<OfferCategory>;
  readonly tradeOfferCategoriesSelectUnselectAll: Reducer;
  readonly tradeOfferCategoriesSelectResetState: Reducer;
}

const slice = createSlice<TradeOfferCategoriesSelectState, Reducers, 'tradeOfferCategory/edit'>({
  name: 'tradeOfferCategory/edit',
  initialState: {
    fetch: {
      ...fetchableDefault,
      data: null,
    },
    selected: null,
    selectedItems: null,
  },
  reducers: {
    tradeOfferCategoriesSelectStartSession: (state, { payload }) => {
      state.selected = payload.selected;
    },
    tradeOfferCategoriesSelectSelect: (state, { payload }) => {
      if (!state.selected) {
        state.selected = [];
      }
      if (!state.selectedItems) {
        state.selectedItems = [];
      }
      const category = findItem(state.fetch.data ?? [], payload.id);
      if (category) {
        state.selected.push(payload);
        state.selectedItems.push(category);
      }
    },
    tradeOfferCategoriesSelectUnselect: (state, { payload }) => {
      if (!state.selected) {
        state.selected = [];
      }
      if (!state.selectedItems) {
        state.selectedItems = [];
      }

      const selectedIndex = state.selected.findIndex(s => s.id === payload.id);
      if (selectedIndex !== -1) {
        state.selected.splice(selectedIndex, 1);
      }

      const selectedItemIndex = state.selectedItems.findIndex(s => s.id === payload.id);
      if (selectedItemIndex !== -1) {
        state.selectedItems.splice(selectedItemIndex, 1);
      }
    },
    tradeOfferCategoriesSelectUnselectAll: state => {
      state.selected = [];
      state.selectedItems = [];
    },
    tradeOfferCategoriesSelectResetState: state => {
      state.fetch = {
        ...fetchableDefault,
        data: null,
      };
      state.selected = null;
      state.selectedItems = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(tradeOfferCategoriesSelectFetch.pending, state => {
        state.fetch.isFetching = true;
        state.fetch.isFetched = false;
        state.fetch.isFailed = false;
        state.fetch.data = null;
      })
      .addCase(tradeOfferCategoriesSelectFetch.fulfilled, (state, { payload }) => {
        state.fetch.isFetching = false;
        state.fetch.isFetched = true;
        state.fetch.isFailed = false;
        state.fetch.data = buildTradeOfferCategoryTree(payload);
        state.selectedItems =
          (state.selected
            ?.map(s => findItem(state.fetch.data ?? [], s.id))
            ?.filter(item => !!item) as DataTreeItem<OfferCategory>[]) ?? null;
      })
      .addCase(tradeOfferCategoriesSelectFetch.rejected, state => {
        state.fetch.isFetching = false;
        state.fetch.isFetched = false;
        state.fetch.isFailed = true;
        state.fetch.data = null;
      });
  },
});

export const {
  tradeOfferCategoriesSelectStartSession,
  tradeOfferCategoriesSelectResetState,
  tradeOfferCategoriesSelectUnselect,
  tradeOfferCategoriesSelectSelect,
  tradeOfferCategoriesSelectUnselectAll,
} = slice.actions;

const findChild = (item: DataTreeItem<OfferCategory>, id: UUID): Nullable<DataTreeItemMutable<OfferCategory>> =>
  item.id === id
    ? item
    : item.children?.reduce<Nullable<DataTreeItem<OfferCategory>>>((result, n) => result || findChild(n, id), null) ??
      null;

const findItem = (tree: DataTreeItem<OfferCategory>[], id: UUID): Nullable<DataTreeItemMutable<OfferCategory>> => {
  let result: Nullable<DataTreeItemMutable<OfferCategory>> = null;
  for (let i = 0; i < tree.length; i++) {
    result = findChild(tree[i], id);
    if (result) {
      break;
    }
  }
  return result;
};

export default slice.reducer;
