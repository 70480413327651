import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CorpOffer } from '../../../../../domain/model/corpOffer';
import { EOfferStatus, ESortDirection } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { NumberRange, PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { CorpOfferTableFilterEditStrategy } from '../../filterUtils';
import {
  corpOffersSearchAllSelect,
  corpOffersSearchAllUnselect,
  corpOffersSearchDataReset,
  corpOffersSearchSelect,
  corpOffersSearchSetFilter,
  corpOffersSearchSetPage,
  corpOffersSearchSetPageSize,
  corpOffersSearchSetSort,
  corpOffersSearchSortReset,
  corpOffersSearchUnselect,
} from '../store/slice';

interface UseCorpOffersSearchSingleProps {
  readonly initialSelected: Nullable<CorpOffer>;
  readonly selectedMaxCount: 1;
  readonly onSelect: (offer: CorpOffer) => void;
}

interface UseCorpOffersSearchMultipleProps {
  readonly initialSelected: Nullable<CorpOffer[]>;
  readonly selectedMaxCount?: NumberRange<2, 100> | false;
  readonly onSelect: (offer: CorpOffer[]) => void;
}

type UseCorpOffersSearchProps = UseCorpOffersSearchSingleProps | UseCorpOffersSearchMultipleProps;

export type UseCorpOffersSearch = {
  readonly onResetState: () => void;
  readonly onChangeFilter: (strategies: CorpOfferTableFilterEditStrategy[]) => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
  readonly onResetSort: () => void;
  readonly onCorpOffersChangePage: (page: number) => void;
  readonly onCorpOffersChangePageSize: (pageSize: PaginationSize) => void;
  readonly onCorpOfferSelect: (corpOffer: CorpOffer, selectedCurrentCount: number, selected: boolean) => void;
  readonly onReturn: (corpOffer: CorpOffer[]) => void;
  readonly onAllCorpOffersSelect: (selected: boolean) => void;
  readonly isCorpOfferNotModeratedByMe: (corpOffer: CorpOffer) => boolean;
};

export function useCorpOffersSearchHandlers(props: UseCorpOffersSearchProps): UseCorpOffersSearch {
  const { selectedMaxCount } = props;

  const dispatch = useDispatch();

  const {
    userSpecific: { id: userId },
    accessMatrix,
  } = useCurrentUser();

  const onChangeFilter = useCallback(
    (strategies: CorpOfferTableFilterEditStrategy[]) => {
      dispatch(corpOffersSearchSetFilter(getDataFilterValuesByStrategies(strategies)));
    },
    [dispatch]
  );

  const onResetState = useCallback(() => dispatch(corpOffersSearchDataReset()), [dispatch]);

  const onChangeSort = useCallback(
    (name: string, direction: ESortDirection) => {
      dispatch(
        corpOffersSearchSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onResetSort = useCallback(() => dispatch(corpOffersSearchSortReset()), [dispatch]);

  const onCorpOffersChangePage = useCallback(
    (page: number) => {
      dispatch(corpOffersSearchSetPage({ pageNumber: page }));
    },
    [dispatch]
  );

  const onCorpOffersChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(corpOffersSearchSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onCorpOfferSelect = useCallback(
    (corpOffer: CorpOffer, selectedCurrentCount: number, selected: boolean) => {
      if (!selectedMaxCount || selectedMaxCount > 1) {
        if (selected && (!selectedMaxCount || selectedCurrentCount < selectedMaxCount)) {
          dispatch(corpOffersSearchSelect(corpOffer));
        } else {
          dispatch(corpOffersSearchUnselect(corpOffer));
        }
      } else {
        if (selected) {
          dispatch(corpOffersSearchAllUnselect());
          dispatch(corpOffersSearchSelect(corpOffer));
        } else {
          dispatch(corpOffersSearchUnselect(corpOffer));
        }
      }
    },
    [selectedMaxCount]
  );

  const onAllCorpOffersSelect = useCallback(
    (selected: boolean) => {
      if (selected) {
        dispatch(corpOffersSearchAllSelect(selectedMaxCount || null));
      } else {
        dispatch(corpOffersSearchAllUnselect());
      }
    },
    [selectedMaxCount]
  );

  const onReturn = useCallback(
    (corpOffers: CorpOffer[]) => {
      switch (props.selectedMaxCount) {
        case 1:
          props.onSelect(corpOffers?.[0] ?? null);
          break;
        default:
          props.onSelect(corpOffers);
          break;
      }
    },
    [props.selectedMaxCount]
  );

  const isCorpOfferNotModeratedByMe = useCallback(
    (corpOffer: CorpOffer) => {
      return (
        !!accessMatrix.corpOffers.moderate &&
        corpOffer.status === EOfferStatus.OnModeration &&
        corpOffer.approvingAdmin?.id !== userId
      );
    },
    [userId, accessMatrix.corpOffers.moderate]
  );

  return {
    onChangeFilter,
    onResetState,
    onChangeSort,
    onResetSort,
    onCorpOffersChangePage,
    onCorpOffersChangePageSize,
    onCorpOfferSelect,
    onAllCorpOffersSelect,
    onReturn,
    isCorpOfferNotModeratedByMe,
  };
}
