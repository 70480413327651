import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PartnerTableCellCommonProps } from '../../components/tableCell';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { partnersPartnerByIndexSelector } from '../store/selectors';

export type PartnersTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<PartnerTableCellCommonProps>;
};

const PartnersTableCellAdapter = (props: PartnersTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const handlers = useContextHandlers();
  const partner = useSelector(partnersPartnerByIndexSelector(index));
  const isDisabled = partner ? handlers.isPartnerNotModeratedByMe(partner) : false;

  return useMemo(
    () =>
      partner ? (
        <Component
          partner={partner}
          isDisabled={isDisabled}
        />
      ) : null,
    [Component, partner, isDisabled]
  );
};

export default PartnersTableCellAdapter;
