import { LinkProps } from '@mui/material/Link/Link';
import React from 'react';
import { getFileDownloadEndpoint } from '../../../../../data/api/utils';
import { FileDescription } from '../../../../../domain/model';
import { StyledBlankFileLink, StyledFileLink } from './controls';

interface StorageFileLinkProps extends Omit<LinkProps, 'href'> {
  /** id/path файла или структура FileDescription */
  readonly src: string | FileDescription;
  readonly children?: React.ReactNode;
}

interface AnyFileLinkProps extends LinkProps {
  readonly children?: React.ReactNode;
}

interface BlankFileLinkProps {
  readonly children?: React.ReactNode;
}

const FileLinkInternal = React.forwardRef((props: StorageFileLinkProps, ref: any) => {
  const { src, target = '_blank', ...otherProps } = props;

  const onClick = (event: React.SyntheticEvent) => {
    const id: string = typeof src === 'string' ? src : src.path;

    const currentTarget: HTMLLinkElement = event.currentTarget as HTMLLinkElement;
    currentTarget.href = getFileDownloadEndpoint({ id });
  };

  return (
    <StyledFileLink
      ref={ref}
      target={target}
      underline='none'
      {...otherProps}
      onClick={onClick}
    />
  );
});

const StorageFileLink = React.forwardRef<typeof FileLinkInternal, any>((props: StorageFileLinkProps, ref: any) => (
  <FileLinkInternal
    ref={ref}
    {...props}
  />
)) as typeof FileLinkInternal;

export const AnyFileLink = React.forwardRef((props: AnyFileLinkProps, ref: any) => {
  return (
    <StyledFileLink
      ref={ref}
      {...props}
    />
  );
});

export const BlankFileLink = React.forwardRef((props: BlankFileLinkProps, ref: any) => {
  return (
    <StyledBlankFileLink
      ref={ref}
      {...props}
    />
  );
});

export default StorageFileLink;
