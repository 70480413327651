import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ClientOrg } from '@/domain/model/clientOrg';
import ForbiddenComponent from '../../components/common/forbidden';
import { viewShowClientOrgManagementMenu } from '@features/clientOrg/management/container/store/slice';
import LocationWithRequiredStateContainer from '@features/general/locationExtension/withRequiredState';
import { viewHideMainMenu } from '@features/main/container/store/slice';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';
import ClientOrgUserTableEntry, { EClientOrgUserUrlParam } from '../../features/user/userClientOrg/table/entry';
import { useLocation } from 'react-router';
import { EClientOrgUserTableTab } from '@features/user/userClientOrg/types';
import useBreadcrumbsActions from '@features/general/breadcrumbs/useBreadcrumbsActions';

interface ClientOrgManagementScreenUsersScreenProps {
  readonly clientOrg: ClientOrg;
}

const ClientOrgManagementUsersScreen = ({ clientOrg }: ClientOrgManagementScreenUsersScreenProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { clientOrgUsers } = useCurrentUser().accessMatrix;

  const { putBreadcrumbs } = useBreadcrumbsActions();

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(EClientOrgUserUrlParam.Tab) as EClientOrgUserTableTab;

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowClientOrgManagementMenu());
  }, [dispatch]);

  useEffect(() => {
    putBreadcrumbs([{ label: 'Пользователи' }]);
  }, []);

  if (!clientOrgUsers?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <ClientOrgUserTableEntry
          guid={state.guid}
          clientOrg={clientOrg}
          tab={tab}
        />
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default ClientOrgManagementUsersScreen;
