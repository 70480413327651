import styled from '@emotion/styled';
import { viewConfig } from '../../../theme/viewConfig';

export const Wrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};
  min-height: 100vh;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;

  padding-bottom: ${p => p.theme.spacing(2)};

  background: ${p => p.theme.palette.background.default};
`;

export const HeaderWrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  position: fixed;
  z-index: 3;
  background: ${p => p.theme.palette.background.default};

  top: ${viewConfig.sidePanel.titleSize};
`;

export const Content = styled.div`
  flex-grow: 1;
`;

export const LoaderWrapper = styled.div`
  top: 0;
  height: 100%;
  left: ${viewConfig.menuPanelWidth};
  right: 0;

  position: fixed;
  z-index: 3;
`;
