import { useMemo } from 'react';
import { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import useCurrentUser from '../../hooks/useCurrentUser';
import {
  clientOrgUserTableFilterItems,
  EClientOrgUserTableFilterItem,
  getClientOrgUserTableFilterStrategy,
} from '../filterUtils';
import { EClientOrgUserTableTab } from '../types';
import ClientOrgUserTableContainer from './container';
import { ClientOrgUsersTableConfigContext } from './context';
import { useClientOrgUsersTableConfig } from './hooks/useClientOrgUsersTableConfig';
import { EClientOrgUserTableColumn, getClientOrgUserTableDefaultColumns } from './utils';
import { ClientOrg } from '@/domain/model/clientOrg';

export enum EClientOrgUserUrlParam {
  Tab = 'tab',
}

interface ClientOrgUserTableEntryProps {
  readonly guid: UUID;
  readonly clientOrg: ClientOrg;
  readonly tab: Nullable<EClientOrgUserTableTab>;
}

interface GetTableAdapterKeyParams {
  readonly tab: EClientOrgUserTableTab;
}

interface GetOffersTableAdapterKeyResult {
  readonly key: string;
  readonly keyPartitions?: (string | number | null)[];
}

const ClientOrgUserTableEntry = ({ guid, tab, clientOrg }: ClientOrgUserTableEntryProps) => {
  const {
    accessMatrix: { clientOrgUsers },
  } = useCurrentUser();

  const config = useClientOrgUsersTableConfig({
    canCreate: !!clientOrgUsers.create,
  });
  const { defaultTab } = config;
  const outTab = tab ?? defaultTab;

  // получаем максимально возможный состав колонок
  const sourceColumns = getClientOrgUserTableDefaultColumns();

  const getTableAdapterKey = (params: GetTableAdapterKeyParams): GetOffersTableAdapterKeyResult => {
    const { tab } = params;

    const key = 'clientOrgUsers';
    const keyPartitions = [tab];

    return {
      key,
      keyPartitions,
    };
  };

  // готовим адаптер для хука
  const filterAdapter = useMemo<DataFilterAdapter<EClientOrgUserTableColumn, EClientOrgUserTableFilterItem>>(
    () => ({
      ...getTableAdapterKey({
        tab: outTab,
      }),
      sourceColumns,
      filterItems: clientOrgUserTableFilterItems,
      requiredFilters: [EClientOrgUserTableFilterItem.Query],
      getDataFilterStrategy: (filterItem, values) => getClientOrgUserTableFilterStrategy(filterItem, values),
    }),
    [sourceColumns, outTab]
  );

  return (
    <ClientOrgUsersTableConfigContext.Provider value={config}>
      <ClientOrgUserTableContainer
        guid={guid}
        clientOrg={clientOrg}
        tab={outTab}
        filterAdapter={filterAdapter}
        access={clientOrgUsers}
      />
    </ClientOrgUsersTableConfigContext.Provider>
  );
};

export default ClientOrgUserTableEntry;
