import { combineReducers } from '@reduxjs/toolkit';
import create, { PartnerEmployeeCreateState } from './create/store/slice';
import details, { PartnerEmployeeDetailsState } from './details/store/slice';
import edit, { PartnerEmployeeEditState } from './edit/store/slice';
import list, { PartnerEmployeeListState } from './table/store/slice';

interface PartnerEmployeeState {
  list: PartnerEmployeeListState;
  create: PartnerEmployeeCreateState;
  details: PartnerEmployeeDetailsState;
  edit: PartnerEmployeeEditState;
}

export default combineReducers<PartnerEmployeeState>({
  list,
  create,
  details,
  edit,
});
