import React from 'react';
import { UseTradeOffersSearch } from './hooks/useTradeOffersSearch';
import { UseTradeOffersSearchConfig } from './hooks/useTradeOffersSearchConfig';

export type TradeOffersSearchContextValue = UseTradeOffersSearch;

export const TradeOffersSearchContext = React.createContext<TradeOffersSearchContextValue>(
  {} as TradeOffersSearchContextValue
);

export type TradeOffersSearchConfigContextValue = UseTradeOffersSearchConfig;

export const TradeOffersSearchConfigContext = React.createContext<TradeOffersSearchConfigContextValue>(
  {} as TradeOffersSearchConfigContextValue
);
