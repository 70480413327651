import { EOrderItemStatus } from '../../../domain/model/enums';

export const getProductOrderItemStatusName = (status: EOrderItemStatus): string => {
  switch (status) {
    case EOrderItemStatus.New:
      return 'Новый';
    case EOrderItemStatus.Confirmed:
      return 'Подтвержден';
    case EOrderItemStatus.Returned:
      return 'Возвращен полностью';
    case EOrderItemStatus.Cancelled:
      return 'Отменен';
    case EOrderItemStatus.Given:
      return 'Получен клиентом';
    case EOrderItemStatus.Changed:
      return 'Состав позиции изменен';
    case EOrderItemStatus.PartiallyReceived:
      return 'Частично получен клиентом';
  }
};

export const statusesProductOfferTableValue = [
  EOrderItemStatus.New,
  EOrderItemStatus.Confirmed,
  EOrderItemStatus.Cancelled,
];
