import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../../data/api';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../../data/store/types';
import { TradeOfferRequest } from '../../../../../../domain/model/tradeOffer';
import { Nullable } from '../../../../../../domain/model/types';
import { MpUser, MpUserData } from '../../../../../../domain/model/user';

export const adminMpCreateSave = createAsyncThunk<MpUser, MpUserData, AppThunkAPIConfig>(
  'adminMp/create/save',
  async (user, { rejectWithValue }) => {
    try {
      const { data } = await Api.user.mp.createAdmin({ user, redirectUri: location.origin });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface AdminMpCreateState {
  data: Nullable<MpUserData>;
  save: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<AdminMpCreateState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<AdminMpCreateState> {
  adminMpCreateInit: Reducer;
  adminMpCreateReset: Reducer;
  adminMpCreateSetAttribute: Reducer<{ name: keyof MpUserData; value: any }>;
}

const slice = createSlice<AdminMpCreateState, Reducers, 'create'>({
  name: 'create',
  initialState: {
    data: null,
    save: {
      ...fetchableDefault,
    },
  },
  reducers: {
    adminMpCreateReset: state => {
      state.data = null;
      state.save = fetchableDefault;
    },
    adminMpCreateInit: state => {
      state.data = {
        email: '',
        phone: null,
        lastName: '',
        firstName: '',
        middleName: null,
        photo: null,
        locality: null,
        birthDate: null,
        gender: null,
      };
      state.save = fetchableDefault;
    },
    adminMpCreateSetAttribute: (state, { payload }) => {
      const { name, value } = payload;
      if (state.data) {
        (state.data[name] as keyof TradeOfferRequest) = value;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(adminMpCreateSave.pending, state => {
        state.save = fetchableFetching;
      })
      .addCase(adminMpCreateSave.fulfilled, state => {
        state.save = fetchableFetched;
      })
      .addCase(adminMpCreateSave.rejected, state => {
        state.save = fetchableFailed;
      });
  },
});

export const { adminMpCreateSetAttribute, adminMpCreateInit, adminMpCreateReset } = slice.actions;

export default slice.reducer;
