import { Tab } from '@mui/material';
import { Team, TeamMember } from '../../../../domain/model/team';
import { Nullable, UUID } from '../../../../domain/model/types';
import { SportUserProfile } from '../../../../domain/model/user';
import BackButton from '../../../components/common/buttons/back';
import ContentLoader from '../../../components/common/loader';
import { DefaultContentWrapper } from '../../../components/common/wrappers/content';
import { Content, Header, LoaderWrapper, Tabs, TabWrapper, TeamDetailsHeaderWrapper, Wrapper } from './controls';
import TeamDetailsHeader from './header';
import TeamTabEvents from './tabs/events';
import TeamTabGeneral from './tabs/general';
import TeamTabInvitesContainer from './tabs/invites/container';
import TeamTabMembers from './tabs/members';
import { ETeamTab } from './utils';

interface TeamDetailsComponentProps {
  readonly guid: UUID;
  readonly tab: ETeamTab;
  readonly team: Team;
  readonly members: Nullable<TeamMember[]>;
  readonly canChangeMembers: boolean;
  readonly isFetching: boolean;
  readonly onChangeTab: (tab: ETeamTab) => void;
  readonly onMemberClick: (member: TeamMember) => void;
  readonly onAddMember: (sportsmen: Nullable<SportUserProfile[]>) => void;
  readonly onTeamEdit: Nullable<() => void>;
}

const TeamDetails = (props: TeamDetailsComponentProps) => {
  const {
    guid,
    tab,
    team,
    members,
    isFetching,
    canChangeMembers,
    onChangeTab,
    onTeamEdit,
    onMemberClick,
    onAddMember,
  } = props;

  return (
    <DefaultContentWrapper>
      <Wrapper>
        <Header>
          <BackButton label='Команда' />
          <TeamDetailsHeaderWrapper>
            <TeamDetailsHeader team={team} />
          </TeamDetailsHeaderWrapper>
          <Tabs
            onChange={(event, newTab) => onChangeTab(newTab)}
            value={tab}
            textColor='primary'
          >
            <Tab
              label='Профиль'
              value={ETeamTab.General}
            />
            <Tab
              label='Мероприятия'
              value={ETeamTab.Events}
            />
            <Tab
              label='Участники'
              value={ETeamTab.Members}
            />
            <Tab
              label={
                <>
                  Заявки на
                  <br />
                  вступление
                </>
              }
              value={ETeamTab.Invites}
            />
          </Tabs>
        </Header>
        <Content>
          {tab === ETeamTab.General && (
            <TabWrapper tab={tab}>
              <TeamTabGeneral
                team={team}
                onTeamEdit={onTeamEdit}
              />
            </TabWrapper>
          )}
          {tab === ETeamTab.Events && (
            <TabWrapper tab={tab}>
              <TeamTabEvents
                guid={guid}
                teamId={team.id}
              />
            </TabWrapper>
          )}
          {tab === ETeamTab.Members && (
            <TabWrapper tab={tab}>
              {members && (
                <TeamTabMembers
                  members={members}
                  onMemberClick={onMemberClick}
                />
              )}
            </TabWrapper>
          )}
          {tab === ETeamTab.Invites && (
            <TabWrapper tab={tab}>
              <TeamTabInvitesContainer
                canChangeMembers={canChangeMembers}
                onAddMember={onAddMember}
              />
            </TabWrapper>
          )}
        </Content>
      </Wrapper>
      {isFetching && (
        <LoaderWrapper>
          <ContentLoader
            size={75}
            alpha
          />
        </LoaderWrapper>
      )}
    </DefaultContentWrapper>
  );
};

export default TeamDetails;
