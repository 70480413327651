import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import { TradeOfferTableCellStatusProps } from '../../components/tableCell/status';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { tradeOffersSearchTradeOfferByIndexSelector } from '../store/selectors';
import { TradeOffersSearchCellAdapterProps } from './cell';

type TradeOffersSearchCellStatusAdapterProps = Omit<TradeOffersSearchCellAdapterProps, 'component'> & {
  readonly component: FC<TradeOfferTableCellStatusProps>;
};

const TradeOffersSearchCellStatusAdapter = (props: TradeOffersSearchCellStatusAdapterProps) => {
  const { index, component: Component } = props;

  const handlers = useContextHandlers();
  const tradeOffer = useSelector(tradeOffersSearchTradeOfferByIndexSelector(index));
  const isDisabled = tradeOffer ? handlers.isTradeOfferNotModeratedByMe(tradeOffer) : false;

  const { offerStatuses } = useSelector(nsiDataSelector);

  return useMemo(
    () =>
      tradeOffer ? (
        <Component
          tradeOffer={tradeOffer}
          offerStatuses={offerStatuses}
          isDisabled={isDisabled}
        />
      ) : null,
    [Component, tradeOffer, isDisabled, offerStatuses]
  );
};

export default TradeOffersSearchCellStatusAdapter;
