import Axios from 'axios';
import Api from '../../../../data/api';
import { AllProps as ApiAllProps } from '../../../../data/api/team';
import { ApiCancellable, ApiRequestListDiscriminator } from '../../../../data/api/types';
import { Pageable } from '../../../../domain/model';
import { Team } from '../../../../domain/model/team';
import { Nullable, UUID } from '../../../../domain/model/types';
import { PaginationSize } from '../../../types';
import { ETeamTableFilterItem, TeamTableFilterValues } from '../filterUtils';
import { ETeamTableTab, TeamTableTabsCounter } from '../table/utils';

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string>;
  };
  readonly filter: TeamTableFilterValues;
  readonly pageNumber: number;
};

export type OneProps = ApiCancellable & {
  readonly id: UUID;
};

export type CountProps = Omit<AllProps, 'pageNumber'>;

type CountsByTabsProps = CountProps & {
  readonly tabs: ETeamTableTab[];
};

export type TeamCommonService = {
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps
  ) => ApiAllProps<D>;
  readonly all: (props: AllProps) => Promise<Pageable<Team>>;
  readonly one: (props: OneProps) => Promise<Team>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly countsByTabs: (props: CountsByTabsProps) => Promise<{
    counts: TeamTableTabsCounter;
    errors: Array<string>;
  }>;
};

const service: TeamCommonService = {
  buildListQueryParams: ({ search, filter, pageNumber, signal }) => {
    const { pageSize, sort } = search;

    const name = filter[ETeamTableFilterItem.Name]?.value;
    const activityTypes = filter[ETeamTableFilterItem.ActivityTypes]?.value;
    const userType = filter[ETeamTableFilterItem.UserType]?.value;
    const addressObjectId = filter[ETeamTableFilterItem.Locality]?.value?.id;

    return {
      page: pageNumber,
      pageSize,
      name,
      sort,
      addressObjectId,
      activityTypes,
      userType,
      signal,
    };
  },
  all: async props => {
    const { data: response } = await Api.team.all(service.buildListQueryParams(props));
    const { content: data, totalElements: totalCount, totalPages: pageCount, number: page } = response;

    return { data, totalCount, pageCount, pageNumber: page };
  },
  one: async props => {
    const { data } = await Api.team.one(props);
    return data;
  },
  count: async props => {
    const { data: response } = await Api.team.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      discriminator: ApiRequestListDiscriminator.Count,
    });

    return response.count;
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: TeamTableTabsCounter = {};

    //табы для которых нет статусов
    const requests = tabs.map(() => {
      return service.count({ ...props, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: typeof responses[0], tab: ETeamTableTab) => {
      if (response.status === 'fulfilled') {
        counts[tab] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество команд '${tab}': ${response.reason}`);
        }
      }
    };

    tabs.forEach((tab, index) => parseResponse(responses[index], tab));

    return { counts, errors };
  },
};

export default service;
