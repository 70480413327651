import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import { TradeOfferTableCellPromotionTypeProps } from '../../components/tableCell/promotionType';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { tradeOffersSearchTradeOfferByIndexSelector } from '../store/selectors';
import { TradeOffersSearchCellAdapterProps } from './cell';

type TradeOffersTableCellPromotionTypeAdapterProps = Omit<TradeOffersSearchCellAdapterProps, 'component'> & {
  readonly component: FC<TradeOfferTableCellPromotionTypeProps>;
};

const TradeOffersTableCellPromotionTypeAdapter = (props: TradeOffersTableCellPromotionTypeAdapterProps) => {
  const { index, component: Component } = props;

  const handlers = useContextHandlers();
  const tradeOffer = useSelector(tradeOffersSearchTradeOfferByIndexSelector(index));
  const isDisabled = tradeOffer ? handlers.isTradeOfferNotModeratedByMe(tradeOffer) : false;

  const { promotionTypes } = useSelector(nsiDataSelector);

  return useMemo(
    () =>
      tradeOffer ? (
        <Component
          tradeOffer={tradeOffer}
          tradePromotionTypes={promotionTypes}
          isDisabled={isDisabled}
        />
      ) : null,
    [Component, tradeOffer, isDisabled, promotionTypes]
  );
};

export default TradeOffersTableCellPromotionTypeAdapter;
