import { ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { SportEventShort } from '../../../../domain/model/event';
import { formatDateTimeHuman, utcToLocalTimeZone } from '../../../utils/dates';
import { AvatarWrapper, OverflowedTypography, SizedListItem, Title } from './controls';
import { EventAvatar } from '../../../components/common/avatars';

interface EventListItemProps {
  readonly event: SportEventShort;
  readonly eventType: string;
  readonly onEventClick: (event: SportEventShort) => void;
}

const EventListItem = ({ event, eventType, onEventClick }: EventListItemProps) => {
  return (
    <SizedListItem
      alignItems='center'
      onClick={() => onEventClick(event)}
    >
      <ListItemAvatar>
        <AvatarWrapper>
          <EventAvatar event={event} />
        </AvatarWrapper>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography color='textSecondary'>
              {formatDateTimeHuman(utcToLocalTimeZone(event.scheduledDate))}
            </Typography>
            <Title>{eventType}</Title>
            <OverflowedTypography>{event.name}</OverflowedTypography>
          </>
        }
      />
    </SizedListItem>
  );
};

export default EventListItem;
