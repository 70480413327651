import { EPartnerOwnershipType, EPartnerStatus } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { ETagColors } from '../../../../theme/ui-kit/tag';
import { EPartnerStep, PartnerStep } from '../types';

export const partnerPhonePrefix = '+7';

export const getPartnerStatusText = (status: EPartnerStatus): string => {
  switch (status) {
    case EPartnerStatus.Unverified:
      return 'Черновик';
    case EPartnerStatus.ToVerification:
      return 'На проверке';
    case EPartnerStatus.OnVerification:
      return 'Проверяется';
    case EPartnerStatus.Rejected:
      return 'Отклонена';
    case EPartnerStatus.Enabled:
      return 'Одобрена';
    case EPartnerStatus.Deleted:
      return 'Удален';
    case EPartnerStatus.Disabled:
      return 'Выключен';
  }
};

export const getPartnerStatusType = (status: EPartnerStatus): Nullable<ETagColors> => {
  switch (status) {
    case EPartnerStatus.Enabled:
      return null;
    case EPartnerStatus.Disabled:
    case EPartnerStatus.Unverified:
      return ETagColors.Disabled;
    case EPartnerStatus.ToVerification:
    case EPartnerStatus.OnVerification:
    case EPartnerStatus.Rejected:
      return ETagColors.Warning;
    case EPartnerStatus.Deleted:
      return ETagColors.Error;
  }
};

export const getPartnerAddressLabelByOrgType = (orgType: Nullable<EPartnerOwnershipType>) =>
  orgType === EPartnerOwnershipType.IndividualEntrepreneur ? 'Адрес регистрации ИП' : 'Юридический адрес';

export const getPartnerSteps = (
  orgType: Nullable<EPartnerOwnershipType>,
  includePermissions?: boolean
): PartnerStep[] => {
  const steps = [
    {
      key: EPartnerStep.General,
      label: 'Общая информация',
    },
    {
      key: EPartnerStep.Address,
      label: getPartnerAddressLabelByOrgType(orgType),
    },
    {
      key: EPartnerStep.Details,
      label: 'Реквизиты',
    },
    {
      key: EPartnerStep.Documents,
      label: 'Документы',
    },
  ];

  if (includePermissions) {
    steps.push({
      key: EPartnerStep.Permissions,
      label: 'Доступ',
    });
  }

  return steps;
};

export const getPartnerInnMask = (ownershipType: EPartnerOwnershipType) => {
  switch (ownershipType) {
    case EPartnerOwnershipType.IndividualEntrepreneur:
      return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    case EPartnerOwnershipType.LimitedLiabilityCompany:
    case EPartnerOwnershipType.StockCompany:
    case EPartnerOwnershipType.PublicJoinStockCompany:
    case EPartnerOwnershipType.PK:
    case EPartnerOwnershipType.UP:
    case EPartnerOwnershipType.FGBNU:
    case EPartnerOwnershipType.NonProfitOrganization:
    case EPartnerOwnershipType.ClosedJoinStockCompany:
    case EPartnerOwnershipType.JoinStockCompany:
    case EPartnerOwnershipType.NAO:
    case EPartnerOwnershipType.AutonomousNonProfitOrganization:
      return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  }
};

export const getPartnerOgrnMask = (ownershipType: EPartnerOwnershipType) => {
  switch (ownershipType) {
    case EPartnerOwnershipType.IndividualEntrepreneur:
      return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    case EPartnerOwnershipType.LimitedLiabilityCompany:
    case EPartnerOwnershipType.StockCompany:
    case EPartnerOwnershipType.PublicJoinStockCompany:
    case EPartnerOwnershipType.PK:
    case EPartnerOwnershipType.UP:
    case EPartnerOwnershipType.FGBNU:
    case EPartnerOwnershipType.NonProfitOrganization:
    case EPartnerOwnershipType.ClosedJoinStockCompany:
    case EPartnerOwnershipType.JoinStockCompany:
    case EPartnerOwnershipType.NAO:
    case EPartnerOwnershipType.AutonomousNonProfitOrganization:
      return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  }
};

export const getPartnerKppMask = (ownershipType: EPartnerOwnershipType) => {
  return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
};

export const getPartnerBikMask = (ownershipType: EPartnerOwnershipType) => {
  return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
};

export const getPartnerAccountMask = (ownershipType: EPartnerOwnershipType) => {
  return [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
};
