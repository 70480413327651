import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { EOfferStatus, ESortDirection } from '../../../../../domain/model/enums';
import { TradeOffer } from '../../../../../domain/model/tradeOffer';
import { Nullable } from '../../../../../domain/model/types';
import { NumberRange, PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { TradeOfferTableFilterEditStrategy } from '../../filterUtils';
import {
  tradeOffersSearchAllSelect,
  tradeOffersSearchAllUnselect,
  tradeOffersSearchDataReset,
  tradeOffersSearchSelect,
  tradeOffersSearchSetFilter,
  tradeOffersSearchSetPage,
  tradeOffersSearchSetPageSize,
  tradeOffersSearchSetSort,
  tradeOffersSearchSortReset,
  tradeOffersSearchUnselect,
} from '../store/slice';

interface UseTradeOffersSearchSingleProps {
  readonly initialSelected: Nullable<TradeOffer>;
  readonly selectedMaxCount: 1;
  readonly onSelect: (offer: Nullable<TradeOffer>) => void;
}

interface UseTradeOffersSearchMultipleProps {
  readonly initialSelected: Nullable<TradeOffer[]>;
  readonly selectedMaxCount?: NumberRange<2, 100> | false;
  readonly onSelect: (offer: TradeOffer[]) => void;
}

type UseTradeOffersSearchProps = UseTradeOffersSearchSingleProps | UseTradeOffersSearchMultipleProps;

export type UseTradeOffersSearch = {
  readonly onResetState: () => void;
  readonly onChangeFilter: (strategies: TradeOfferTableFilterEditStrategy[]) => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
  readonly onResetSort: () => void;
  readonly onTradeOffersChangePage: (page: number) => void;
  readonly onTradeOffersChangePageSize: (pageSize: PaginationSize) => void;
  readonly onTradeOfferSelect: (tradeOffer: TradeOffer, selectedCurrentCount: number, selected: boolean) => void;
  readonly onReturn: (tradeOffer: TradeOffer[]) => void;
  readonly onAllTradeOffersSelect: (selected: boolean) => void;
  readonly isTradeOfferNotModeratedByMe: (tradeOffer: TradeOffer) => boolean;
};

export function useTradeOffersSearchHandlers(props: UseTradeOffersSearchProps): UseTradeOffersSearch {
  const { selectedMaxCount } = props;

  const dispatch = useDispatch();

  const {
    userSpecific: { id: userId },
    accessMatrix,
  } = useCurrentUser();

  const onChangeFilter = useCallback(
    (strategies: TradeOfferTableFilterEditStrategy[]) => {
      dispatch(tradeOffersSearchSetFilter(getDataFilterValuesByStrategies(strategies)));
    },
    [dispatch]
  );

  const onResetState = useCallback(() => dispatch(tradeOffersSearchDataReset()), [dispatch]);

  const onChangeSort = useCallback(
    (name: string, direction: ESortDirection) => {
      dispatch(
        tradeOffersSearchSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onResetSort = useCallback(() => dispatch(tradeOffersSearchSortReset()), [dispatch]);

  const onTradeOffersChangePage = useCallback(
    (page: number) => {
      dispatch(tradeOffersSearchSetPage({ pageNumber: page }));
    },
    [dispatch]
  );

  const onTradeOffersChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(tradeOffersSearchSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onTradeOfferSelect = useCallback(
    (tradeOffer: TradeOffer, selectedCurrentCount: number, selected: boolean) => {
      if (!selectedMaxCount || selectedMaxCount > 1) {
        if (selected && (!selectedMaxCount || selectedCurrentCount < selectedMaxCount)) {
          dispatch(tradeOffersSearchSelect(tradeOffer));
        } else {
          dispatch(tradeOffersSearchUnselect(tradeOffer));
        }
      } else {
        if (selected) {
          dispatch(tradeOffersSearchAllUnselect());
          dispatch(tradeOffersSearchSelect(tradeOffer));
        } else {
          dispatch(tradeOffersSearchUnselect(tradeOffer));
        }
      }
    },
    [selectedMaxCount]
  );

  const onAllTradeOffersSelect = useCallback(
    (selected: boolean) => {
      if (selected) {
        dispatch(tradeOffersSearchAllSelect(selectedMaxCount || null));
      } else {
        dispatch(tradeOffersSearchAllUnselect());
      }
    },
    [selectedMaxCount]
  );

  const onReturn = useCallback(
    (tradeOffers: TradeOffer[]) => {
      switch (props.selectedMaxCount) {
        case 1:
          props.onSelect(tradeOffers?.[0] ?? null);
          break;
        default:
          props.onSelect(tradeOffers);
          break;
      }
    },
    [props.selectedMaxCount]
  );

  const isTradeOfferNotModeratedByMe = useCallback(
    (tradeOffer: TradeOffer) => {
      return (
        !!accessMatrix.tradeOffers.moderate &&
        tradeOffer.status === EOfferStatus.OnModeration &&
        tradeOffer.approvingAdmin?.id !== userId
      );
    },
    [userId, accessMatrix.tradeOffers.moderate]
  );

  return {
    onChangeFilter,
    onResetState,
    onChangeSort,
    onResetSort,
    onTradeOffersChangePage,
    onTradeOffersChangePageSize,
    onTradeOfferSelect,
    onAllTradeOffersSelect,
    onReturn,
    isTradeOfferNotModeratedByMe,
  };
}
