import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CorpOfferTableCellCommonProps } from '../../components/tableCell';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { corpOffersSearchCorpOfferByIndexSelector } from '../store/selectors';

export type CorpOffersSearchCellAdapterProps = {
  readonly index: number;
  readonly component: FC<CorpOfferTableCellCommonProps>;
};

const CorpOffersSearchCellAdapter = (props: CorpOffersSearchCellAdapterProps) => {
  const { index, component: Component } = props;

  const handlers = useContextHandlers();
  const corpOffer = useSelector(corpOffersSearchCorpOfferByIndexSelector(index));
  const isDisabled = corpOffer ? handlers.isCorpOfferNotModeratedByMe(corpOffer) : false;

  return useMemo(
    () =>
      corpOffer ? (
        <Component
          corpOffer={corpOffer}
          isDisabled={isDisabled}
        />
      ) : null,
    [Component, corpOffer, isDisabled]
  );
};

export default CorpOffersSearchCellAdapter;
