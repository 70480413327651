import ProductTableCell, { ProductTableCellCommonProps } from './index';

const ProductTableCellName = (props: ProductTableCellCommonProps) => {
  const {
    productOffer: { name: value = '-' },
  } = props;

  return (
    <ProductTableCell
      {...props}
      value={value}
    />
  );
};

export default ProductTableCellName;
