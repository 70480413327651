import { RootState } from '../../../../../data/store/store';
import { ESortDirection } from '../../../../../domain/model/enums';
import { EProductOrderTableColumn } from '../../types';

export const productOrdersGuidSelector = (store: RootState) => store.productOrder.list.guid;

export const productOrdersOrdersSelector = (store: RootState) => store.productOrder.list.orders.data;
export const productOrdersIsFetchingSelector = (store: RootState) => store.productOrder.list.orders.isFetching;

export const productOrdersPageNumberSelector = (store: RootState) => store.productOrder.list.orders.pageNumber;
export const productOrdersTotalCountSelector = (store: RootState) => store.productOrder.list.orders.totalCount;
export const productOrdersPageCountSelector = (store: RootState) => store.productOrder.list.orders.pageCount;

export const productOrdersSearchSelector = (store: RootState) => store.productOrder.list.search;
export const productOrdersFilterSelector = (store: RootState) => store.productOrder.list.filter;

export const productOrdersSortColumnSelector = (store: RootState) =>
  (store.productOrder.list.search.sort?.split(',') as [EProductOrderTableColumn, ESortDirection])?.[0] ?? '';
export const productOrdersSortDirectionSelector = (store: RootState) =>
  (store.productOrder.list.search.sort?.split(',') as [EProductOrderTableColumn, ESortDirection])?.[1] ?? '';

export const productOrdersNeedRefreshWatcherSelector = (store: RootState) => store.productOrder.list.needRefreshWatcher;

export const productOrdersTabsCounterSelector = (store: RootState) => store.productOrder.list.tabsCounter;
