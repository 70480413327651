import { EAddressLevel } from '@/domain';
import { Address, AddressId } from '@/domain/model/address';
import { Nullable } from '@/domain/model/types';
import { Chip } from '@mui/material';
import { MPAutocompleteMultipleSelect } from '@ui-kit/autocomplete/multiple';
import React from 'react';
import AddressMultipleItem from './item';
import { AddressMultipleSelectType, AddressMultipleTypesSelectorItem } from './types';
import useAddressMultipleTypes from './useMultipleTypes';

interface AddressCityMultipleTypesSelectorProps {
  readonly select?: Nullable<AddressMultipleSelectType>;
  readonly selectNone?: boolean;
  readonly cities: Address[];
  readonly locations?: AddressId[];
  readonly label: string;
  readonly helperText?: string;
  readonly error?: boolean;
  readonly limitTags?: number;
  readonly disabled?: boolean;
  readonly isLoading?: boolean;
  readonly color?: 'default' | 'primary' | 'secondary' | undefined;
  readonly popupIcon?: React.ReactNode;
  /* набор данных по умолчанию (в моменты когда нет поискового запроса) */
  readonly defaultSource?: Nullable<Address[]>;
  readonly onSelect: (type: AddressMultipleSelectType) => void;
  readonly onChange: (cities: Address[]) => void;
}

/**
 * *
 * Компонент мультисеклектора городов
 * Возможность выбрать 'Вся Россия' и наличие списка городов по умолчанию
 *
 *
 * @example
 *       <AddressCityMultipleTypesSelector
 *         label='Города 2'
 *         color={'primary'}
 *         popupIcon={<ExpandMoreIcon fontSize='small' />}
 *         limitTags={2}
 *         error={!!validation?.[attribute]?.hasError}
 *         helperText={validation?.[attribute]?.message}
 *         cities={value ?? []}
 *         selectAll={valueAll}
 *         defaultSource={[
 *           {
 *             id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
 *             name: 'Москва',
 *           },
 *           { id: 'c2deb16a-0330-4f05-821f-1d09c93331e6', name: 'Санкт-Петербург' },
 *           { id: '2763c110-cb8b-416a-9dac-ad28a55b4402', name: 'Екатеринбург' },
 *         ]}
 *         onChangeAllSelect={onChangeAll}
 *         onChange={onChange}
 *       />
 */

export const AddressCityMultipleTypesSelector = (props: AddressCityMultipleTypesSelectorProps) => {
  const {
    select,
    selectNone,
    cities,
    locations,
    label,
    helperText,
    error,
    limitTags = -1,
    disabled = false,
    color = 'default',
    popupIcon,
    isLoading: isInitialLoading,
    defaultSource,
    onSelect,
    onChange,
  } = props;

  const {
    options,
    values,
    isLoading: isLoading,
    setSearchValue,
    onChangeValue,
    getOptionLabel,
  } = useAddressMultipleTypes({
    select,
    selectNone,
    addresses: cities,
    fromLevel: EAddressLevel.City,
    toLevel: EAddressLevel.City,
    locations,
    defaultSource,
    onSelect,
    onChange: onChange,
  });

  return (
    <MPAutocompleteMultipleSelect<AddressMultipleTypesSelectorItem>
      value={values}
      label={label}
      helperText={helperText}
      error={error}
      options={options}
      limitTags={limitTags}
      isLoading={isLoading || isInitialLoading}
      disabled={disabled}
      disableCloseOnSelect
      onSearchValue={setSearchValue}
      getOptionLabel={getOptionLabel}
      onChangeValue={onChangeValue}
      renderOption={(elementProps, option, state) => (
        <AddressMultipleItem
          key={option.id}
          elementProps={elementProps}
          option={option}
          state={state}
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            component={'div' as any}
            color={color}
            selected
            label={getOptionLabel(option)}
            {...getTagProps({ index })}
            key={option.id}
          />
        ))
      }
      popupIcon={popupIcon}
    />
  );
};
