import { v4 as uuidv4 } from 'uuid';
import { ESportEventLevel, ESportEventParticipationFormat, EUserType } from '../../../../../domain/model/enums';
import { Tournament, TournamentRequest } from '../../../../../domain/model/event';
import { Nullable } from '../../../../../domain/model/types';
import { CalendarIcon, ImageCoverIcon, ListItemsIcon, TextAlignLeftIcon, UserIcon } from '../../../../icons';
import { pluralize } from '../../../../utils/pluralize';
import { EValidationType, ValidationResult } from '../../../../utils/validation';
import { EventHelpWidgetField } from '../../components/helpWidgets';
import { EEventStep, TournamentCreateStepType, TournamentEditStepType } from '../../types';

export const tournamentDefaultLevel = ESportEventLevel.Road;
export const tournamentDefaultUserType = EUserType.Any;

export const convertTournamentToTournamentRequest = (tournament: Tournament): TournamentRequest => {
  const {
    id,
    name,
    description,
    status,
    level,
    road,
    scheduledDate,
    endDate,
    playground,
    locationDescription,
    locationImage,
    participationFormat,
    onlineParticipationLink,
    onlineParticipationDescription,
    onlineParticipationInstruction,
    maxAge,
    minAge,
    minLengthOfWork,
    organizerEmail,
    organizerPhone,
    previewImage,
    programDescription,
    programFile,
    regulationFiles,
    admin,
    invitedAddresses,
    invitedTeams,
    applicationTakingEndDate,
    activityType,
    userType,
    chatId,
    activityTypes,
  } = tournament;

  return {
    id,
    name,
    description,
    status,
    scheduledDate,
    endDate,
    locationDescription,
    locationImage,
    participationFormat,
    onlineParticipationLink,
    onlineParticipationDescription,
    onlineParticipationInstruction,
    maxAge,
    minAge,
    minLengthOfWork,
    organizerEmail,
    organizerPhone,
    previewImage,
    programDescription,
    programFile,
    regulationFiles,
    playground,
    level,
    road,
    admin,
    invitedAddresses,
    invitedTeams,
    applicationTakingEndDate,
    activityType: activityType,
    userType,
    chatId,
    activityTypes,
  };
};

export const createNewTournamentRequest = (): TournamentRequest => {
  return {
    id: uuidv4(),
    name: '',
    description: null,
    status: null,
    scheduledDate: null,
    endDate: null,
    locationDescription: null,
    locationImage: null,
    participationFormat: ESportEventParticipationFormat.Offline,
    onlineParticipationLink: null,
    onlineParticipationDescription: null,
    onlineParticipationInstruction: null,
    maxAge: null,
    minAge: null,
    minLengthOfWork: null,
    organizerEmail: null,
    organizerPhone: null,
    previewImage: null,
    programDescription: null,
    programFile: null,
    regulationFiles: null,
    playground: null,
    level: null,
    road: null,
    admin: null,
    invitedTeams: null,
    invitedAddresses: null,
    applicationTakingEndDate: null,
    activityType: null,
    userType: null,
    chatId: null,
    activityTypes: null,
  };
};

export const getTournamentCreateStepText = (step: TournamentCreateStepType | TournamentEditStepType): string => {
  switch (step) {
    case EEventStep.General:
      return 'Основная информация';
    case EEventStep.Details:
      return 'Детали и контакты';
    case EEventStep.Requirements:
      return 'Требования к участникам';
    case EEventStep.Invites:
      return 'Приглашение участников';
  }
};

/**
 * Первоначальный массив полей, которые будет отображать виджет.
 * Адаптер берет эти значения как по умолчанию, но создает свое локальное состояние
 */
export const tournamentFieldsListForHelpWidget: Partial<keyof TournamentRequest>[] = [
  'name',
  'description',
  'previewImage',
  'level',
  'road',
  'activityType',
  'scheduledDate',
  'endDate',
  'participationFormat',
  'onlineParticipationLink',
  'playground',
  'organizerPhone',
  'organizerEmail',
  'minAge',
  'maxAge',
  'applicationTakingEndDate',
];

export const tournamentHelpWidgetGetFieldData = (
  field: Nullable<keyof TournamentRequest>
): Nullable<EventHelpWidgetField> => {
  const defaultDataField: EventHelpWidgetField = {
    name: null,
    icon: TextAlignLeftIcon,
  };

  switch (field) {
    case 'name': {
      return {
        ...defaultDataField,
        name: 'Название',
      };
    }
    case 'description': {
      return {
        ...defaultDataField,
        name: 'Описание',
      };
    }
    case 'activityType': {
      return {
        ...defaultDataField,
        name: 'Вид спорта',
        icon: ListItemsIcon,
      };
    }
    case 'activityTypes': {
      return {
        ...defaultDataField,
        name: 'Виды спорта',
        icon: ListItemsIcon,
      };
    }
    case 'previewImage': {
      return {
        ...defaultDataField,
        name: 'Обложка',
        icon: ImageCoverIcon,
      };
    }
    case 'road': {
      return {
        ...defaultDataField,
        name: 'Железная дорога',
        icon: ListItemsIcon,
      };
    }
    case 'scheduledDate': {
      return {
        ...defaultDataField,
        name: 'Дата начала',
        icon: CalendarIcon,
      };
    }
    case 'endDate': {
      return {
        ...defaultDataField,
        name: 'Дата завершения',
        icon: CalendarIcon,
      };
    }
    case 'playground': {
      return {
        ...defaultDataField,
        name: 'Место проведения',
        icon: CalendarIcon,
      };
    }
    case 'organizerPhone': {
      return {
        ...defaultDataField,
        name: 'Телефон для связи',
      };
    }
    case 'organizerEmail': {
      return {
        ...defaultDataField,
        name: 'Почта для связи',
      };
    }
    case 'minAge': {
      return {
        ...defaultDataField,
        name: 'Возраст участников (От)',
      };
    }
    case 'maxAge': {
      return {
        ...defaultDataField,
        name: 'Возраст участников (До)',
      };
    }
    case 'minLengthOfWork': {
      return {
        ...defaultDataField,
        name: 'Минимальный стаж',
      };
    }
    case 'level': {
      return {
        ...defaultDataField,
        name: 'Уровень проведения',
      };
    }
    case 'applicationTakingEndDate': {
      return {
        ...defaultDataField,
        name: 'Дата окончания приема заявок',
        icon: CalendarIcon,
      };
    }
    case 'invitedAddresses':
    case 'invitedTeams': {
      return {
        ...defaultDataField,
        name: 'Пригласите участников',
        icon: UserIcon,
      };
    }
    case 'participationFormat': {
      return {
        ...defaultDataField,
        name: 'Формат участия',
      };
    }
    case 'onlineParticipationLink': {
      return {
        ...defaultDataField,
        name: 'Сайт или ссылка на трансляцию',
      };
    }
    case null:
    case 'status':
    case 'id':
    case 'regulationFiles':
    case 'chatId':
    case 'locationImage':
    case 'locationDescription':
    case 'onlineParticipationInstruction':
    case 'onlineParticipationDescription':
    case 'programFile':
    case 'programDescription':
    case 'admin':
    case 'userType': {
      return null;
    }
  }
};
const tournamentGetValidationCommonError = (
  step: TournamentCreateStepType,
  count: number
): Nullable<ValidationResult<any>> => {
  if (!count) {
    return null;
  }

  switch (step) {
    case EEventStep.Invites:
      return {
        common: {
          type: EValidationType.Error,
          hasError: true,
          message: 'Пригласите участников',
        },
      };
    default:
      return {
        common: {
          type: EValidationType.Error,
          hasError: true,
          message: `Не заполнено ${count} ${pluralize(count, ['поле', 'поля', 'полей'])}`,
        },
      };
  }
};

export const tournamentCreateInitValidationStepper = (validation: ValidationResult<TournamentRequest>) => {
  const generalErrorsCount = [
    validation.name,
    validation.description,
    validation.previewImage,
    validation.level,
    validation.road,
    validation.userType,
    validation.activityType,
    validation.scheduledDate,
    validation.endDate,
  ].filter(item => !!item).length;

  const detailsErrorsCount = [
    validation.playground,
    validation.participationFormat,
    validation.onlineParticipationLink,
    validation.onlineParticipationDescription,
    validation.onlineParticipationInstruction,
    validation.locationDescription,
    validation.locationImage,
    validation.programDescription,
    validation.programFile,
    validation.admin,
    validation.organizerPhone,
    validation.organizerEmail,
  ].filter(item => !!item).length;

  const requirementsErrorsCount = [
    validation.minAge,
    validation.maxAge,
    validation.minLengthOfWork,
    validation.applicationTakingEndDate,
    validation.regulationFiles,
  ].filter(item => !!item).length;

  const invitesErrorsCount = [validation.invitedAddresses, validation.invitedTeams].filter(item => !!item).length;

  return {
    [EEventStep.General]: tournamentGetValidationCommonError(EEventStep.General, generalErrorsCount),
    [EEventStep.Details]: tournamentGetValidationCommonError(EEventStep.Details, detailsErrorsCount),
    [EEventStep.Requirements]: tournamentGetValidationCommonError(EEventStep.Requirements, requirementsErrorsCount),
    [EEventStep.Invites]: tournamentGetValidationCommonError(EEventStep.Invites, invitesErrorsCount),
  };
};
