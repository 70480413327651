import { EPartnerOwnershipType } from '../../../../../../../domain/model/enums';
import { PartnerDraft } from '../../../../../../../domain/model/partner';
import PartnerApplicationEditCompanyDocuments from './company';
import PartnerApplicationEditIndividualDocuments from './individual';

interface PartnerApplicationViewStepDocumentsProps {
  readonly partnerApplication: PartnerDraft;
}

const PartnerApplicationViewStepDocuments = (props: PartnerApplicationViewStepDocumentsProps) => {
  const { partnerApplication } = props;

  const { innCert, taxSystem, usnDoc } = partnerApplication;

  if (!partnerApplication.regInfo?.orgType) {
    return <></>;
  }
  switch (partnerApplication.regInfo?.orgType) {
    case EPartnerOwnershipType.IndividualEntrepreneur:
      return (
        <PartnerApplicationEditIndividualDocuments
          innCert={innCert}
          taxSystem={taxSystem}
          usnDoc={usnDoc}
          ogrnipCert={partnerApplication.regInfo?.ogrnipCert ?? null}
          passportCopy={partnerApplication.regInfo?.passportCopy ?? null}
        />
      );
    case EPartnerOwnershipType.StockCompany:
    case EPartnerOwnershipType.PublicJoinStockCompany:
    case EPartnerOwnershipType.PK:
    case EPartnerOwnershipType.UP:
    case EPartnerOwnershipType.FGBNU:
    case EPartnerOwnershipType.NonProfitOrganization:
    case EPartnerOwnershipType.ClosedJoinStockCompany:
    case EPartnerOwnershipType.JoinStockCompany:
    case EPartnerOwnershipType.NAO:
    case EPartnerOwnershipType.AutonomousNonProfitOrganization:
    case EPartnerOwnershipType.LimitedLiabilityCompany: {
      return (
        <PartnerApplicationEditCompanyDocuments
          innCert={innCert}
          taxSystem={taxSystem}
          usnDoc={usnDoc}
          ogrnCert={partnerApplication.regInfo?.ogrnCert ?? null}
          charterDoc={partnerApplication.regInfo?.charterDoc ?? null}
          ceoAppointmentProtocol={partnerApplication.regInfo?.ceoAppointmentProtocol ?? null}
        />
      );
    }
    default:
      return <></>;
  }
};

export default PartnerApplicationViewStepDocuments;
