import { Grid, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { Banner, BannerPlace } from '../../../../../../domain/model/banner';
import { ETargetType } from '../../../../../../domain/model/enums';
import { EDateFormat } from '../../../../../../domain/model/formats';
import FileInfo from '../../../../../components/common/files/info';
import Splitter from '../../../../../components/common/splitter';
import { normalizedLink } from '../../../../../utils';
import { bannerTargetByCorpStructureAttributes, bannerTargetByLocalitiesAttributes } from '../../../utils';
import BannerViewTargetAdapter from '../../adapters/target';
import { WordBreaker } from './controls';

interface BannerViewStepGeneralProps {
  readonly banner: Banner;
  readonly bannerPlaces: BannerPlace[];
}

const BannerViewStepGeneral = (props: BannerViewStepGeneralProps) => {
  const { banner, bannerPlaces } = props;

  const { places, desktopFormatImage, mobileFormatImage, target, name, url, startDate, endDate } = banner;

  // пока тут только один вариант возможен
  const place = bannerPlaces.find(p => places?.[0].code === p.code)!;

  return (
    <>
      <Typography variant='subtitle2'>Баннер</Typography>
      <Splitter
        variant='horizontal'
        size={1.5}
      />
      <Grid
        container
        spacing={3}
        direction='column'
      >
        <Grid item>
          <Typography
            variant='body2'
            color='textSecondary'
          >
            Формат
          </Typography>
          <Typography>{place.name}</Typography>
        </Grid>
        {(desktopFormatImage || mobileFormatImage) && (
          <Grid
            item
            container
            spacing={2}
          >
            {desktopFormatImage && (
              <Grid
                item
                xs={12}
              >
                <FileInfo id={desktopFormatImage.path} />
              </Grid>
            )}
            {mobileFormatImage && (
              <Grid
                item
                xs={12}
              >
                <FileInfo id={mobileFormatImage.path} />
              </Grid>
            )}
          </Grid>
        )}
        <Grid item>
          <Typography
            variant='body2'
            color='textSecondary'
          >
            Название
          </Typography>
          <Typography>{name}</Typography>
        </Grid>
        <Grid item>
          <Typography
            variant='body2'
            color='textSecondary'
          >
            URL-ссылка
          </Typography>
          <WordBreaker>
            <Typography>
              {url ? (
                <Link
                  href={normalizedLink(url)}
                  target='_blank'
                >
                  {url}
                </Link>
              ) : (
                '-'
              )}
            </Typography>
          </WordBreaker>
        </Grid>
      </Grid>
      <Splitter
        variant='horizontal'
        size={4}
      />
      <Typography variant='subtitle2'>Доступность</Typography>
      <Splitter
        variant='horizontal'
        size={1.5}
      />

      {target.targetType === ETargetType.Geo && (
        <BannerViewTargetAdapter
          {...bannerTargetByLocalitiesAttributes}
          target={target}
        />
      )}

      {target.targetType === ETargetType.Corp && (
        <BannerViewTargetAdapter
          {...bannerTargetByCorpStructureAttributes}
          target={target}
        />
      )}

      <Splitter
        variant='horizontal'
        size={4}
      />
      <Typography variant='subtitle2'>Срок размещения</Typography>
      <Splitter
        variant='horizontal'
        size={1.5}
      />
      <Grid
        container
        spacing={2}
        direction='column'
      >
        <Grid item>
          <Typography
            variant='body2'
            color='textSecondary'
          >
            Начало
          </Typography>
          <Typography>{moment(startDate).format(EDateFormat.DisplayDefault)}</Typography>
        </Grid>
        <Grid item>
          <Typography
            variant='body2'
            color='textSecondary'
          >
            Окончание
          </Typography>
          <Typography>{moment(endDate).format(EDateFormat.DisplayDefault)}</Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default BannerViewStepGeneral;
