import React from 'react';
import { UseCmsContainersTable } from './hooks/useCmsContainersTable';
import { UseCmsContainersTableConfig } from './hooks/useCmsContainersTableConfig';

export type CmsContainersTableContextValue = UseCmsContainersTable;

export const CmsContainersTableContext = React.createContext<CmsContainersTableContextValue>(
  {} as CmsContainersTableContextValue
);

export type CmsContainersTableConfigContextValue = UseCmsContainersTableConfig;

export const CmsContainersTableConfigContext = React.createContext<CmsContainersTableConfigContextValue>(
  {} as CmsContainersTableConfigContextValue
);
