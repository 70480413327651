import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { EOrderPartition, EOrderStatus } from '../../../domain/model/enums';
import { BookingOrder } from '../../../domain/model/order';
import { Partner } from '../../../domain/model/partner';
import ForbiddenComponent from '../../components/common/forbidden';
import BookingOrderActionsProvider from '../../features/bookingOrder/actions/provider';
import BookingOrderTableEntry from '../../features/bookingOrder/table/entry';
import { EBookingOrderTableTab } from '../../features/bookingOrder/types';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import { EOrderUrlParam, getOrderEditRoute, OrdersLocationState } from '../../features/order/entry';
import { viewShowPartnerManagementMenu } from '../../features/partner/management/container/store/slice';
import { getPartnerManagementOrderRoute } from '../../features/partner/management/entry';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface PartnerManagementBookingOrdersScreenProps {
  readonly partner: Partner;
}

const PartnerManagementBookingOrdersScreen = ({ partner }: PartnerManagementBookingOrdersScreenProps) => {
  const dispatch = useDispatch();
  const location = useLocation<OrdersLocationState>();
  const history = useHistory();
  const {
    accessMatrix: { orders },
  } = useCurrentUser();

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(EOrderUrlParam.Tab) as EBookingOrderTableTab;

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowPartnerManagementMenu());
  }, [dispatch]);

  if (!orders[EOrderPartition.BookingOffers].view) {
    return <ForbiddenComponent />;
  }

  const onOrderClick = (order: BookingOrder) => {
    if (order.status === EOrderStatus.New && orders[EOrderPartition.BookingOffers].edit) {
      const editRoute = getOrderEditRoute({ id: order.id });
      history.push(editRoute);
    } else {
      history.push(
        getPartnerManagementOrderRoute({
          orderId: order.id,
          partnerId: partner.id,
        })
      );
    }
  };

  return (
    <LocationWithRequiredStateContainer<OrdersLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <BookingOrderActionsProvider>
          <BookingOrderTableEntry
            tab={tab}
            guid={state.guid}
            partnerId={partner.id}
            onOrderClick={onOrderClick}
          />
        </BookingOrderActionsProvider>
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default PartnerManagementBookingOrdersScreen;
