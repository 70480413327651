import ClientOrgActionsConfirmDialog from '../components/confirmDialog';
import { useDispatch, useSelector } from 'react-redux';
import { useClientOrgActions } from '../useActions';
import {
  clientOrgActionsDialogUnBlockSelector,
  getClientOrgActionsOfferIsProcessingSelector,
} from '../store/selectors';
import { useCallback } from 'react';
import { ClientOrg } from '@/domain/model/clientOrg';
import { clientOrgActionsUnBlock } from '@features/clientOrg/actions/store/slice';
import { showClientOrgActionNotification } from '@features/clientOrg/actions/utils';
import { EClientOrgActionType } from '@features/clientOrg/types';
import { Typography } from '@mui/material';

export const ClientOrgActionsDialogUnBlockAdapter = () => {
  const dispatch = useDispatch();
  const handlers = useClientOrgActions();

  const object = useSelector(clientOrgActionsDialogUnBlockSelector);

  const isProcessing = useSelector(getClientOrgActionsOfferIsProcessingSelector(object?.id ?? null));

  const onCloseDialog = useCallback(
    () => handlers.onChangeDialogState('unBlock', null),
    [handlers.onChangeDialogState]
  );

  const onUnBlock = useCallback(
    (clientOrg: ClientOrg) => {
      dispatch(
        clientOrgActionsUnBlock({
          id: clientOrg.id,
        })
      )
        .unwrap()
        .then(response => {
          handlers.onChangeDialogState('unBlock', null);
          showClientOrgActionNotification(response, EClientOrgActionType.UnBlock);
        });
    },
    [dispatch, handlers.onChangeDialogState]
  );

  return (
    object && (
      <ClientOrgActionsConfirmDialog
        clientOrg={object}
        title={'Возобновить компанию?'}
        text={<Typography>Вы уверены что хотите возобновить компанию «{object.name}»?</Typography>}
        actionText={'Возобновить'}
        isFetching={isProcessing}
        onClose={onCloseDialog}
        onAction={onUnBlock}
      />
    )
  );
};
