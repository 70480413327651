import { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { Feature } from '../feature';
import { EAppFeature } from '../../../types';

interface RouteByFeatureProps extends RouteProps {
  readonly feature: EAppFeature;
  readonly defaultRoute?: string;
}

const RouteByFeature: FC<RouteByFeatureProps> = ({ feature, defaultRoute = '/', ...routeProps }) => {
  return (
    <Feature
      feature={feature}
      stubComponent={<Redirect to={defaultRoute} />}
    >
      <Route {...routeProps} />
    </Feature>
  );
};

export default RouteByFeature;
