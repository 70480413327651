import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AutocompleteProps, Typography } from '@mui/material';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';
import { InputBaseProps } from '@mui/material/InputBase';
import { AutocompleteInputChangeReason } from '@mui/material/useAutocomplete/useAutocomplete';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Nullable } from '../../../../domain/model/types';
import ContentLoader from '../../../components/common/loader';
import { MPFormInput } from '../input';
import { Autocomplete, LoaderWrapper } from './controls';
import { MPAutocompleteSingleSelectItem } from './types';

export interface MPAutocompleteSingleSelectProps<T>
  extends Omit<AutocompleteProps<T, false, false, false>, 'inputValue' | 'renderInput' | 'onChange'> {
  readonly value: Nullable<T>;
  readonly options: T[];
  readonly label: string;
  readonly required?: boolean;
  readonly error?: boolean;
  readonly helperText?: Nullable<string>;
  readonly disabled?: boolean;
  readonly isLoading?: boolean;
  readonly controlled?: boolean;
  readonly inputValue?: Nullable<string>;
  readonly inputProps?: InputBaseProps['inputProps'];
  readonly groupBy?: (option: T) => string;
  readonly renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  readonly onSearchValue?: (value: string, reason?: AutocompleteInputChangeReason) => void;
  readonly onChangeValue: (value: Nullable<T>) => void;
}

export function MPAutocompleteSingleSelect<
  T extends MPAutocompleteSingleSelectItem<IDT>,
  IDT extends string | number = string
>(props: MPAutocompleteSingleSelectProps<T>): JSX.Element {
  const {
    options,
    value,
    onChangeValue,
    onSearchValue,
    label,
    error,
    helperText,
    disabled,
    isLoading,
    groupBy,
    inputValue,
    inputProps,
    controlled,
    required,
    getOptionLabel,
    ...others
  } = props;

  const [internalInputValue, setInputValue] = useState<Nullable<string>>(inputValue ?? null);

  useEffect(() => {
    if (controlled && inputValue) {
      setInputValue(inputValue);
    }
  }, [controlled, inputValue]);

  return (
    <Autocomplete
      freeSolo={true as any}
      clearOnBlur
      openOnFocus
      disabled={disabled}
      value={value}
      inputValue={internalInputValue || ''}
      options={options}
      popupIcon={<ExpandMoreIcon fontSize='small' />}
      getOptionLabel={getOptionLabel ?? (option => (option as T).name ?? '')}
      getOptionDisabled={option => !!option.disabled}
      noOptionsText={<Typography variant={'body2'}>{isLoading ? 'загрузка...' : 'нет вариантов'}</Typography>}
      groupBy={groupBy}
      onChange={(event, newValue, reason) => {
        if (typeof newValue !== 'string') {
          onChangeValue(newValue);
        } else {
          if (reason === 'createOption') {
            if (options.length > 0) {
              onChangeValue(options[0]);
            } else {
              setInputValue(null);
              onChangeValue(null);
            }
          }
        }
      }}
      onInputChange={(event, newValue, reason) => {
        setInputValue(newValue);
        onSearchValue?.(newValue, reason);
      }}
      renderInput={
        others.renderInput ??
        (params => (
          <MPFormInput
            {...params}
            label={label}
            error={error}
            helperText={helperText}
            required={required}
            multiline
            InputProps={{
              ...params.InputProps,
              endAdornment: isLoading ? (
                <LoaderWrapper>
                  <ContentLoader size={23} />
                </LoaderWrapper>
              ) : (
                <>{params.InputProps.endAdornment}</>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              ...inputProps,
              autoComplete: 'none',
              'aria-autocomplete': 'none',
            }}
          />
        ))
      }
      {...others}
    />
  );
}
