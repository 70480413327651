import { EUserStatus } from '../../../../domain/model/enums';
import { EPanelActionPosition, PanelActions } from '../../../types';
import { EUserMPActionType, UserMPDetailsActionType } from './types';

export const getUserMPActionName = (type: EUserMPActionType) => {
  switch (type) {
    case EUserMPActionType.Activate:
      return 'Включить';
    case EUserMPActionType.Deactivate:
      return 'Отключить';
    case EUserMPActionType.Save:
      return 'Сохранить';
    case EUserMPActionType.Edit:
      return 'Редактировать';
    case EUserMPActionType.Logout:
      return 'Выйти из системы';
  }
};

interface GetUserMPDetailsActionsProps {
  readonly status: EUserStatus;
  readonly canEdit?: boolean;
  readonly canChangeStatus?: boolean;
  readonly canLogout?: boolean;
}

export const getUserMPDetailsActions = ({
  status,
  canEdit,
  canChangeStatus,
  canLogout,
}: GetUserMPDetailsActionsProps): PanelActions<UserMPDetailsActionType> => {
  const actions: PanelActions<UserMPDetailsActionType> = [];
  if (canLogout) {
    actions.push({
      type: EUserMPActionType.Logout,
      label: getUserMPActionName(EUserMPActionType.Logout),
      position: [EPanelActionPosition.Menu],
    });
  }
  if (canChangeStatus) {
    switch (status) {
      case EUserStatus.Enabled:
        actions.push({
          type: EUserMPActionType.Deactivate,
          label: getUserMPActionName(EUserMPActionType.Deactivate),
          position: [EPanelActionPosition.Menu],
        });
        break;
      case EUserStatus.Disabled:
        actions.push({
          type: EUserMPActionType.Activate,
          label: getUserMPActionName(EUserMPActionType.Activate),
          position: [EPanelActionPosition.Menu],
        });
        break;
      default:
        break;
    }
  }

  if (canEdit) {
    actions.push({
      type: EUserMPActionType.Edit,
      label: getUserMPActionName(EUserMPActionType.Edit),
      position: [EPanelActionPosition.Menu],
    });
  }

  return actions;
};
