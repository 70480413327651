import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { EOrderItemStatus, Nullable, ProductOrderItem, UUID } from '@/domain';
import ConfirmDialog from '../../../../../components/common/dialogs/confirm';
import DataTable, { DataTableRow } from '../../../../../components/common/table';
import TableActions from '../../../../../components/common/table/actions';
import { MPButton } from '@ui-kit/button';
import { EPanelActionPosition } from '../../../../../types';
import { formatCost } from '../../../../../utils';
import { Modifier, ModifierArray } from '../../../../../utils/modifier';
import { DialogProductVariant } from '@features/products/components/dialogProductVariant';
import { concatProductName } from '@features/products/utils/helpers';
import { productOrderEditWarningsSelector } from '../../store/selectors';
import { ProductOrderWarnings } from '../../store/slice';
import ProductOrderItemTableCellAttributes from './cells/attributes';
import ProductOrderItemTableCellCost from './cells/cost';
import ProductOrderItemTableCellImages from './cells/images';
import ProductOrderItemTableCellQty from './cells/qty';
import { ProductOrderItemTableCellStatus } from './cells/status';
import { EProductOrderActionType, ProductOrderAction, ProductOrderActionCommonType } from './types';
import { useProductOrderItemsTable } from '../../hooks/useTableSettings';
import { EProductOrderItemsTableColumn } from '../../utils';

interface ProductOrderItemsTableProps {
  readonly items: ProductOrderItem[];
  readonly onChangeFieldItem: ModifierArray<ProductOrderItem>;
  readonly onReplaceFieldItem: (offerId: UUID, value: Nullable<ProductOrderItem>) => void;
  readonly onSetWarning: Modifier<ProductOrderWarnings>;
}

interface OrderTableItem extends ProductOrderItem {
  id: UUID;
}

const ProductOrderItemsTable = (props: ProductOrderItemsTableProps) => {
  const { push } = useHistory();
  const { items, onChangeFieldItem, onReplaceFieldItem, onSetWarning } = props;
  const warnings = useSelector(productOrderEditWarningsSelector);
  const [selectVariant, setSelectVariant] = useState<{
    productDeskId: UUID;
    offerId: UUID;
    warning?: string;
  }>();

  const handleCloseDialogProductVariant = useCallback(() => {
    setSelectVariant(undefined);
  }, []);

  const handleCloseDialogWarning = useCallback(() => {
    onSetWarning('excess', null);
  }, []);

  const { metadata } = useProductOrderItemsTable();

  const rows: DataTableRow<OrderTableItem, EProductOrderItemsTableColumn>[] = items.map(item => {
    const { name, cost, qty, status, offer } = item;

    return {
      [EProductOrderItemsTableColumn.Name]: name ?? '-',
      [EProductOrderItemsTableColumn.Qty]: (
        <ProductOrderItemTableCellQty
          value={qty}
          max={offer.stock || 0}
          onChange={value => {
            onChangeFieldItem(offer.id, 'qty', value);
            if (cost) onChangeFieldItem(offer.id, 'cost', value * (offer.price ?? 1));
          }}
        />
      ),
      [EProductOrderItemsTableColumn.Price]: formatCost(offer.price) ?? '-',
      [EProductOrderItemsTableColumn.OriginalPrice]: formatCost(offer.originalPrice) ?? '-',
      [EProductOrderItemsTableColumn.Cost]: (
        <ProductOrderItemTableCellCost
          value={cost}
          onChange={value => {
            onChangeFieldItem(offer.id, 'cost', value);
          }}
        />
      ),
      [EProductOrderItemsTableColumn.Status]: <ProductOrderItemTableCellStatus status={status} />,
      [EProductOrderItemsTableColumn.Image]: (
        <ProductOrderItemTableCellImages
          disabled={false}
          images={offer.images}
        />
      ),
      [EProductOrderItemsTableColumn.Attributes]: (
        <ProductOrderItemTableCellAttributes
          value={offer.variantName}
          onOpenDialog={event => {
            event.stopPropagation();
            if (offer.productDesk?.id)
              setSelectVariant({
                productDeskId: offer.productDesk.id,
                offerId: offer.id,
              });
          }}
        />
      ),
      [EProductOrderItemsTableColumn.Category]: offer.category?.name,
      [EProductOrderItemsTableColumn.PartnerSKU]: offer.partnerSKU,
      data: {
        id: offer.id,
        ...item,
      },
    };
  });

  const handleClickRow = useCallback((event: Event, cell: string, row: DataTableRow<OrderTableItem, string>) => {
    push(`/products/${row.data.id}`);
  }, []);

  const onTableCommonAction = useCallback((action: EProductOrderActionType, orderItem: OrderTableItem) => {
    switch (action) {
      case EProductOrderActionType.Confirm:
        onChangeFieldItem(orderItem.id, 'status', EOrderItemStatus.Confirmed);
        break;
      case EProductOrderActionType.Cancel:
        onChangeFieldItem(orderItem.id, 'status', EOrderItemStatus.Cancelled);
        break;
      case EProductOrderActionType.Delete:
        // Удаление! null - значит удалить такую запись
        onReplaceFieldItem(orderItem.id, null);
        break;
    }
  }, []);

  const getCommonAction = (orderItem: OrderTableItem): ProductOrderAction<ProductOrderActionCommonType> => {
    return [
      {
        type: EProductOrderActionType.Confirm,
        position: [EPanelActionPosition.Menu],
        disabled: false,
        label: 'Подтверждён',
        primary: false,
      },
      {
        type: EProductOrderActionType.Cancel,
        position: [EPanelActionPosition.Menu],
        disabled: false,
        label: 'Отменён',
        primary: false,
      },
      {
        type: EProductOrderActionType.Delete,
        position: [EPanelActionPosition.Menu],
        disabled: !orderItem.isDelete,
        label: 'Удалить',
        primary: false,
      },
    ];
  };

  return (
    <>
      <DataTable<OrderTableItem, EProductOrderItemsTableColumn>
        width='auto'
        metadata={metadata}
        rowActions
        rows={rows}
        onRowClick={handleClickRow}
        getRowActions={orderItem => (
          <TableActions<OrderTableItem, ProductOrderActionCommonType>
            entityLink={orderItem}
            actions={getCommonAction(orderItem)}
            onExecute={onTableCommonAction}
            isExecuting={false}
          />
        )}
      />

      {warnings.excess && (
        <ConfirmDialog
          open
          title={'Недостаточно по остаткам'}
          text={warnings.excess.message}
          onClose={handleCloseDialogWarning}
          dialogButtons={
            <>
              <MPButton
                fullWidth
                onClick={() => {
                  onChangeFieldItem(warnings.excess!.productId!, 'qty', warnings.excess!.maxCount!);

                  handleCloseDialogWarning();
                }}
              >
                Выбрать доступное количество
              </MPButton>
              <MPButton
                variant={'outlined'}
                fullWidth={false}
                onClick={handleCloseDialogWarning}
              >
                Закрыть
              </MPButton>
            </>
          }
        />
      )}
      {!!selectVariant && (
        <DialogProductVariant
          warning={selectVariant.warning}
          open={true}
          templateString={{
            submitButton: 'Выбрать',
            title: 'Выбор варианта товара',
          }}
          closeOnSelect={false}
          onClose={handleCloseDialogProductVariant}
          productDeskId={selectVariant.productDeskId}
          onSelect={productVariant => {
            const duplicateOffer = items.find(r => r.offer.id === productVariant.id);
            const currentOffer = items.find(r => r.offer.id === selectVariant.offerId);

            // Если выбранный вариант уже есть в списке заказов
            if (duplicateOffer) {
              if (duplicateOffer.qty && currentOffer?.qty && productVariant.stock) {
                // если кол-во при схлопывании превышает остаток, выводи варнинг
                if (duplicateOffer.qty + currentOffer.qty > productVariant.stock) {
                  setSelectVariant({
                    ...selectVariant,
                    warning: 'Вариант товара недоступен, так как недостаточное количество по остаткам',
                  });
                } else {
                  // если не превышает
                  // охлопываем остатки
                  onChangeFieldItem(duplicateOffer.offer.id, 'qty', duplicateOffer!.qty + currentOffer.qty);
                  // Удаление! null - значит удалить такую запись
                  onReplaceFieldItem(currentOffer.offer.id, null);
                  // закрываем диалог
                  handleCloseDialogProductVariant();
                }
              }
            } else {
              // создаём OrderItem
              onReplaceFieldItem(selectVariant.offerId, {
                cost: productVariant.price,
                name: concatProductName(productVariant.name, productVariant.variantName),
                qty: currentOffer!.qty,
                offer: productVariant,
                discount: null,
                originalCost: productVariant.originalPrice,

                image: productVariant.images?.[0] || null,
                status: currentOffer!.status,
              });

              handleCloseDialogProductVariant();
            }
          }}
        />
      )}
    </>
  );
};

export default ProductOrderItemsTable;
