import AdCampaignActionsDialogArchiveAdapter from './dialogArchive';
import AdCampaignActionsDialogPauseAdapter from './dialogPause';
import AdCampaignActionsDialogResumeAdapter from './dialogResume';
import AdCampaignActionsDialogSaveAdapter from './dialogSave';

const AdCampaignActionsDialogsAdapter = () => {
  return (
    <>
      <AdCampaignActionsDialogSaveAdapter />
      <AdCampaignActionsDialogPauseAdapter />
      <AdCampaignActionsDialogArchiveAdapter />
      <AdCampaignActionsDialogResumeAdapter />
    </>
  );
};

export default AdCampaignActionsDialogsAdapter;
