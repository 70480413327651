import { useDispatch } from 'react-redux';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import TradeOfferActionsDialogsAdapter from './adapters/dialogs';
import TradeOfferActionsOptimizerAdapter from './adapters/optimizer';
import TradeOfferActionsContext, { TradeOfferActionsContextType } from './context';
import {
  getIsTradeOfferAnyActionByTypeExecutedSelector,
  getTradeOfferActionsOfferIsProcessingSelector,
  isTradeOffersAnyChangedSelector,
} from './store/selectors';
import {
  tradeOfferActionsApprove,
  tradeOfferActionsArchive,
  tradeOfferActionsChangeDialogState,
  tradeOfferActionsChangeModerator,
  tradeOfferActionsDelete,
  tradeOfferActionsDuplicate,
  tradeOfferActionsInWork,
  tradeOfferActionsNotUsedCodesDelete,
  tradeOfferActionsPause,
  tradeOfferActionsPublish,
  tradeOfferActionsReject,
  tradeOfferActionsResume,
  tradeOfferActionsRetrieve,
  tradeOfferActionsReturnToVerification,
  tradeOfferActionsSave,
  tradeOfferActionsSaveNewVersion,
  tradeOfferActionsUnPublish,
} from './store/slice';
import { showTradeOfferActionNotification } from './utils';
import { EOfferActionType } from '@features/general/offer/types';

interface TradeOfferActionsProviderProps {
  readonly children: JSX.Element;
}

const TradeOfferActionsProvider = ({ children }: TradeOfferActionsProviderProps) => {
  const dispatch = useDispatch();

  const { id: userId } = useCurrentUser().userSpecific;

  const onSave: TradeOfferActionsContextType['onSave'] = async (offer, notification = true) => {
    const response = await dispatch(tradeOfferActionsSave(offer)).unwrap();
    if (response && notification) {
      showTradeOfferActionNotification(EOfferActionType.Edit, !offer.id);
    }
    return response;
  };

  const onSaveNewVersion: TradeOfferActionsContextType['onSaveNewVersion'] = async (offer, notification = true) => {
    const response = await dispatch(tradeOfferActionsSaveNewVersion(offer)).unwrap();
    if (response && notification) {
      showTradeOfferActionNotification(EOfferActionType.SaveNewVersion, !offer.id);
    }
    return response;
  };

  const onUnPublish: TradeOfferActionsContextType['onUnPublish'] = async (id, notification = true) => {
    const response = await dispatch(tradeOfferActionsUnPublish({ id })).unwrap();

    if (response && notification) {
      showTradeOfferActionNotification(EOfferActionType.UnPublish);
    }
    return response;
  };

  const onArchive: TradeOfferActionsContextType['onArchive'] = async (id, notification = true) => {
    const response = await dispatch(tradeOfferActionsArchive({ id })).unwrap();
    if (response && notification) {
      showTradeOfferActionNotification(EOfferActionType.Archive);
    }
    return response;
  };

  const onPublish: TradeOfferActionsContextType['onPublish'] = async (id, notification) => {
    const response =  await dispatch(tradeOfferActionsPublish({ id })).unwrap();

    if (response && notification) {
      showTradeOfferActionNotification(EOfferActionType.Publish);
    }
    return response;
  };

  const onApprove: TradeOfferActionsContextType['onApprove'] = async (id, notification = true) => {
    const response = await dispatch(tradeOfferActionsApprove({ id })).unwrap();
    if (response && notification) {
      showTradeOfferActionNotification(EOfferActionType.Approve);
    }
    return response;
  };

  const onDuplicate: TradeOfferActionsContextType['onDuplicate'] = async (offer, notification = true) => {
    const response = await dispatch(tradeOfferActionsDuplicate({ id: offer.id })).unwrap();
    if (response && notification) {
      showTradeOfferActionNotification(EOfferActionType.Duplicate);
    }
    return response;
  };

  const onDelete: TradeOfferActionsContextType['onDelete'] = async (id, notification = true) => {
    await dispatch(tradeOfferActionsDelete({ id })).unwrap();
    if (notification) {
      showTradeOfferActionNotification(EOfferActionType.Delete);
    }
    return Promise.resolve(false);
  };

  const onDeleteCodes: TradeOfferActionsContextType['onDeleteCodes'] = async (id, notification = true) => {
    const response = await dispatch(tradeOfferActionsNotUsedCodesDelete({ id })).unwrap();
    if (response && notification) {
      showTradeOfferActionNotification(EOfferActionType.DeleteCodes);
    }
    return response;
  };

  const onReturnToVerification: TradeOfferActionsContextType['onReturnToVerification'] = async (
    offer,
    notification = true
  ) => {
    const response = await dispatch(tradeOfferActionsReturnToVerification({ id: offer.id, userId })).unwrap();
    if (response && notification) {
      showTradeOfferActionNotification(EOfferActionType.ReturnToVerification);
    }
    return response;
  };

  const onReject: TradeOfferActionsContextType['onReject'] = async (offer, reason, comment, notification = true) => {
    const response = await dispatch(tradeOfferActionsReject({ id: offer.id, reason, comment })).unwrap();

    if (response && notification) {
      showTradeOfferActionNotification(EOfferActionType.Reject);
    }
    return response;
  };

  const onResume: TradeOfferActionsContextType['onResume'] = async (offer, notification = true) => {
    const response = await dispatch(tradeOfferActionsResume({ id: offer.id })).unwrap();
    if (response && notification) {
      showTradeOfferActionNotification(EOfferActionType.Resume);
    }
    return response;
  };

  const onRetrieve: TradeOfferActionsContextType['onRetrieve'] = async (offer, endDate, notification = true) => {
    const response = await dispatch(tradeOfferActionsRetrieve({ id: offer.id, endDate })).unwrap();

    if (response && notification) {
      showTradeOfferActionNotification(EOfferActionType.Retrieve);
    }

    return response;
  };

  const onPause: TradeOfferActionsContextType['onPause'] = async (offer, reason, comment, notification = true) => {
    const response = await dispatch(tradeOfferActionsPause({ id: offer.id, reason, comment })).unwrap();

    if (response && notification) {
      showTradeOfferActionNotification(EOfferActionType.Pause);
    }
    return response;
  };

  const onInWork: TradeOfferActionsContextType['onInWork'] = async (offer, notification = true) => {
    const response = await dispatch(tradeOfferActionsInWork({ id: offer.id, userId })).unwrap();
    if (response && notification) {
      showTradeOfferActionNotification(EOfferActionType.InWork);
    }
    return response;
  };

  const onChangeModerator: TradeOfferActionsContextType['onChangeModerator'] = async (
    id,
    userId,
    notification = true
  ) => {
    const response = await dispatch(tradeOfferActionsChangeModerator({ id, userId })).unwrap();
    if (response && notification) {
      showTradeOfferActionNotification(EOfferActionType.ChangeModerator);
    }
    return response;
  };

  const onTryReject: TradeOfferActionsContextType['onTryReject'] = props => {
    onChangeDialogState('reject', props);
  };

  const onTryDelete: TradeOfferActionsContextType['onTryDelete'] = props => {
    onChangeDialogState('delete', props);
  };

  const onTryDeleteCodes: TradeOfferActionsContextType['onTryDeleteCodes'] = props => {
    onChangeDialogState('deleteCodes', props);
  };

  const onTryCreateCodes: TradeOfferActionsContextType['onTryCreateCodes'] = props => {
    onChangeDialogState('createCodes', props);
  };

  const onTryArchive: TradeOfferActionsContextType['onTryArchive'] = props => {
    onChangeDialogState('archive', props);
  };

  const onTryPause: TradeOfferActionsContextType['onTryPause'] = props => {
    onChangeDialogState('pause', props);
  };

  const onTryChangeModerator: TradeOfferActionsContextType['onTryChangeModerator'] = props => {
    onChangeDialogState('changeModerator', props);
  };

  const onTryReturnToVerification: TradeOfferActionsContextType['onTryReturnToVerification'] = props => {
    onChangeDialogState('returnToVerification', props);
  };

  const onTryDuplicate: TradeOfferActionsContextType['onTryDuplicate'] = props => {
    dispatch(tradeOfferActionsDuplicate({ id: props.data.id }))
      .unwrap()
      .then(() => showTradeOfferActionNotification(EOfferActionType.Duplicate));
  };

  const onTryRetrieve: TradeOfferActionsContextType['onTryRetrieve'] = props => {
    onChangeDialogState('retrieve', props);
  };

  const onTryApprove: TradeOfferActionsContextType['onTryApprove'] = props => {
    onChangeDialogState('approve', props);
  };

  const onTryUnPublish: TradeOfferActionsContextType['onTryUnPublish'] = props => {
    onChangeDialogState('unPublish', props);
  };

  const onTryPublish: TradeOfferActionsContextType['onTryPublish'] = props => {
    onChangeDialogState('publish', props);
  };

  const onTryResume: TradeOfferActionsContextType['onTryResume'] = props => {
    onChangeDialogState('resume', props);
  };

  const onCloseDialog: TradeOfferActionsContextType['onCloseDialog'] = name => {
    onChangeDialogState(name, { data: null });
  };

  const onChangeDialogState: TradeOfferActionsContextType['onChangeDialogState'] = (name, data) => {
    dispatch(tradeOfferActionsChangeDialogState({ name, data }));
  };

  const value: TradeOfferActionsContextType = {
    onSave,
    onCloseDialog,
    onSaveNewVersion,
    onChangeDialogState,
    onArchive,
    onApprove,
    onDuplicate,
    onDelete,
    onDeleteCodes,
    onReturnToVerification,
    onReject,
    onResume,
    onRetrieve,
    onPause,
    onInWork,
    onChangeModerator,
    onUnPublish,
    onPublish,

    onTryReject,
    onTryPause,
    onTryArchive,
    onTryDelete,
    onTryDeleteCodes,
    onTryCreateCodes,
    onTryChangeModerator,
    onTryReturnToVerification,
    onTryDuplicate,
    onTryRetrieve,
    onTryApprove,
    onTryUnPublish,
    onTryPublish,
    onTryResume,

    utils: {
      selectors: {
        getIsOfferProcessingSelector: getTradeOfferActionsOfferIsProcessingSelector,
        getIsOfferExecutedActionsSelector: getIsTradeOfferAnyActionByTypeExecutedSelector,
        isTradeOffersAnyChangedSelector,
      },
    },
  };

  return (
    <TradeOfferActionsContext.Provider value={value}>
      {children}
      <TradeOfferActionsOptimizerAdapter />
      <TradeOfferActionsDialogsAdapter />
    </TradeOfferActionsContext.Provider>
  );
};

export default TradeOfferActionsProvider;
