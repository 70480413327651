import { EPartnerTableTab } from '../types';

interface GetPartnersTableAdapterKeyParams {
  tab: EPartnerTableTab;
}

interface GetPartnersTableAdapterKeyResult {
  key: string;
  keyPartitions?: (string | number | null)[];
}

export const getPartnersTableAdapterKey = (
  params: GetPartnersTableAdapterKeyParams
): GetPartnersTableAdapterKeyResult => {
  const { tab } = params;

  return {
    key: 'partners',
    keyPartitions: [tab],
  };
};
