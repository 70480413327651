import ErrorHandler from '@/data/network/errorHandler';
import { ServerErrorResponse } from '@/data/network/types';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault, fetchableFailed, fetchableFetched, fetchableFetching } from '@/data/store/types';
import { AdCampaign, AdCampaignCreate, AnyAdCampaign, Nullable } from '@/domain';
import { EAdCampaignActionType } from '@features/adCampaign/types';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import adCampaignServices from '../../services';

const getActionProcess = (state: AdCampaignActionsState, id: UUID, actionType: EAdCampaignActionType) => {
  let process = state.actions.find(change => change.id === id);
  if (process) return process;

  process = {
    ...fetchableDefault,
    id,
    type: actionType,
    error: null,
  };
  state.actions.push(process);

  return process;
};

export const adCampaignActionsSave = createAsyncThunk<
  AdCampaign,
  AdCampaignCreate,
  AppThunkAPIConfig<ServerErrorResponse>
>('adCampaign/actions/save', async (adCampaign, { rejectWithValue }) => {
  try {
    return await adCampaignServices.save(adCampaign);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const adCampaignActionsArchive = createAsyncThunk<
  AdCampaign,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('adCampaign/actions/archive', async ({ id }, { rejectWithValue }) => {
  try {
    return await adCampaignServices.archive({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const adCampaignActionsPause = createAsyncThunk<
  AdCampaign,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('adCampaign/actions/pause', async ({ id }, { rejectWithValue }) => {
  try {
    return await adCampaignServices.pause({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const adCampaignActionsResume = createAsyncThunk<
  AdCampaign,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('adCampaign/actions/resume', async ({ id }, { rejectWithValue }) => {
  try {
    return await adCampaignServices.resume({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

type SaveActionDataDialogs = {
  name: 'save';
  data: Nullable<AdCampaignCreate>;
};

type CommandActionsDataDialogs = {
  name: Exclude<keyof AdCampaignActionsState['dialogs'], 'save'>;
  data: Nullable<AnyAdCampaign>;
};

export type ActionsChangeDialogStateType = CommandActionsDataDialogs | SaveActionDataDialogs;

export interface AdCampaignActionsState {
  readonly actions: (Fetchable & {
    id: UUID;
    type: EAdCampaignActionType;
    error: Nullable<ServerErrorResponse>;
  })[];
  readonly create: Fetchable;
  readonly dialogs: {
    readonly save: Nullable<AdCampaignCreate>;
    readonly pause: Nullable<AnyAdCampaign>;
    readonly archive: Nullable<AnyAdCampaign>;
    readonly resume: Nullable<AnyAdCampaign>;
  };
}

type Reducer<T = undefined> = CaseReducer<AdCampaignActionsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<AdCampaignActionsState> {
  adCampaignActionsChangeDialogState: Reducer<ActionsChangeDialogStateType>;
  adCampaignActionsOptimize: Reducer;
}

const slice = createSlice<AdCampaignActionsState, Reducers, 'adCampaign/actions'>({
  name: 'adCampaign/actions',
  initialState: {
    actions: [],
    create: fetchableDefault,
    dialogs: {
      save: null,
      archive: null,
      pause: null,
      resume: null,
    },
  },
  reducers: {
    adCampaignActionsChangeDialogState: (state, { payload }) => {
      const { name, data } = payload;
      if (name === 'save') {
        state.dialogs.save = data;
      } else {
        state.dialogs[name] = data;
      }
    },
    adCampaignActionsOptimize: state => {
      // Оставляем только выполняющиеся действия
      state.actions = state.actions.filter(action => action.isFetching);
      if (!state.create.isFetching) {
        state.create = fetchableDefault;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(adCampaignActionsSave.pending, (state, { meta }) => {
        const { id } = meta.arg;

        if (id) {
          const actionType = EAdCampaignActionType.Edit;
          const process = getActionProcess(state, id, actionType);

          process.isFetching = true;
          process.isFetched = false;
          process.isFailed = false;

          process.id = id;
          process.type = actionType;
          process.error = null;
        } else {
          state.create = fetchableFetching;
        }
      })
      .addCase(adCampaignActionsSave.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        if (id) {
          const actionType = EAdCampaignActionType.Edit;
          const process = getActionProcess(state, id, actionType);

          process.isFetching = false;
          process.isFetched = true;
          process.isFailed = false;

          process.id = id;
          process.type = actionType;
          process.error = null;
        } else {
          state.create = fetchableFetched;
        }
      })
      .addCase(adCampaignActionsSave.rejected, (state, { meta }) => {
        const { id } = meta.arg;

        if (id) {
          const actionType = EAdCampaignActionType.Edit;
          const process = getActionProcess(state, id, actionType);
          process.isFetching = false;
          process.isFetched = false;
          process.isFailed = true;

          process.id = id;
          process.type = actionType;
        } else {
          state.create = fetchableFailed;
        }
      })
      .addCase(adCampaignActionsArchive.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EAdCampaignActionType.Archive;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(adCampaignActionsArchive.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EAdCampaignActionType.Archive;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(adCampaignActionsArchive.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EAdCampaignActionType.Archive;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(adCampaignActionsResume.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EAdCampaignActionType.Resume;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(adCampaignActionsResume.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EAdCampaignActionType.Resume;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(adCampaignActionsResume.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EAdCampaignActionType.Resume;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(adCampaignActionsPause.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EAdCampaignActionType.Pause;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(adCampaignActionsPause.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EAdCampaignActionType.Pause;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(adCampaignActionsPause.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EAdCampaignActionType.Pause;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      });
  },
});

export const { adCampaignActionsChangeDialogState, adCampaignActionsOptimize } = slice.actions;

export default slice.reducer;
