import { combineReducers } from '@reduxjs/toolkit';
import forLanding, { CmsSitePageForLandingState } from './forLanding/store/slice';
import forPartner, { CmsSitePageForPartnerState } from './forPartner/store/slice';

export interface CmsSitePageState {
  forPartner: CmsSitePageForPartnerState;
  forLanding: CmsSitePageForLandingState;
}

export default combineReducers<CmsSitePageState>({
  forPartner,
  forLanding,
});
