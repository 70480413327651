import { Address, OrgUnit, RzhdRoad } from '@/domain';
import { DataFilterEditItemProps } from 'presentation/components/common/dataFilter/edit';
import { FC } from 'react';
import { ClientOrg, FilterMultiSelectorPredicate, SportOptionTyped } from './index';
import { Target } from './target';
import { Nullable } from './types';

export enum EDataFilterType {
  String = 'string',
  Number = 'number',
  Date = 'date',
  DateAsTimePeriod = 'date_as_datetime_period',
  DateFrom = 'date_from',
  DateTo = 'date_to',
  Year = 'year',
  ListSingle = 'list_single',
  ListMultiple = 'list_multiple',
  // города
  CityMultipleAddress = 'city_multiple_address',
  // населенный пункт по внутреннему сервису адресов
  AddressLocalitySingle = 'address_locality_single',
  // населенные пункты по внутреннему сервису адресов
  AddressLocalityMultiple = 'address_locality_multiple',
  AddressSingle = 'address_single',
  AutocompleteSingle = 'autocomplete_single',
  AutocompleteMultiple = 'autocomplete_multiple',
  BooleanFlag = 'boolean_flag',
  Target = 'target',
  MultiSelectorPredicateOrgUnit = 'multi_selector_predicate_org_unit',
  MultiSelectorPredicateClientOrg = 'multi_selector_predicate_client_org',
  MultiSelectorPredicateAddress = 'multi_selector_predicate_address',
  MultiSelectorPredicateSportOption = 'multi_selector_predicate_sport_option',
  MultiSelectorPredicateRoad = 'multi_selector_predicate_road',
  NumberPeriod = 'number_period',
  DatePeriod = 'date_period',
  // любой адаптер деревьев мультизначений, удовлетворяющий контракту
  AdapterTreeMultiple = 'adapter_tree_multiple',
}

export enum DataFilterQueryDslOperator {
  Equals = '=',
  NotEquals = '!=',
  Less = '<',
  LessOrEquals = '<=',
  More = '>',
  MoreOrEquals = '>=',
  In = 'in',
  Like = 'like',
  Between = 'between', // only client
}

export type DataFilterQueryDslItem = {
  /** оператор сравнения */
  readonly operator: DataFilterQueryDslOperator;
  /** ссылка на ключевой атрибут для технического значения, конвертируемого в параметры запроса */
  readonly valueAttribute?: string;
};

export type DataFilterStrategyListItem = {
  readonly id: string;
  readonly name: string;
};

export type DataFilterStrategyTreeItem = {
  readonly id: string;
  readonly name: string;
  readonly parent: Nullable<DataFilterStrategyTreeItem>;
};

export type DataFilterStrategyBase<T extends string> = { readonly key: T } & (
  | DataFilterStrategyString<T>
  | DataFilterStrategyNumber<T>
  | DataFilterStrategyYear<T>
  | DataFilterStrategyDateSimple<T>
  | DataFilterStrategyDateAsDateTimePeriod<T>
  | DataFilterStrategyDateFrom<T>
  | DataFilterStrategyDateTo<T>
  | DataFilterStrategyListSingle<T>
  | DataFilterStrategyListMultiple<T>
  | DataFilterStrategyLocalitySingle<T>
  | DataFilterStrategyLocalityMultiple<T>
  | DataFilterStrategyAddressSingle<T>
  | DataFilterStrategyAutocompleteSingle<T>
  | DataFilterStrategyAutocompleteMultiple<T>
  | DataFilterStrategyBooleanFlag<T>
  | DataFilterStrategyCityMultipleAddress<T>
  | DataFilterStrategyTarget<T>
  | DataFilterStrategyOrgUnitsSelector<T>
  | DataFilterStrategyClientOrgsSelector<T>
  | DataFilterStrategyAddressesSelector<T>
  | DataFilterStrategySportOptionsSelector<T>
  | DataFilterStrategyRoadsSelector<T>
  | DataFilterStrategyAdapterTreeMultiple<T>
  | DataFilterStrategyNumberPeriod<T>
  | DataFilterStrategyDatePeriod<T>
);

export type DataFilterStrategyMayBePreviews<V> = V extends any[]
  ? { readonly previews: Nullable<string[]> }
  : { readonly previews?: Nullable<string[]> };

export type DataFilterStrategyView<T extends string, V = unknown> = DataFilterStrategyMayBePreviews<V> & {
  /** ключ, может быть любой если не используется в сортировке, если используется - должен ссылаться на правильный атрибут в модели */
  readonly key: T;
  /** тип */
  readonly type: EDataFilterType;
  /** представление в текстовом виде для режима просмотра */
  readonly preview: Nullable<string>;
  /** значение */
  readonly value: V;
  /** только чтение */
  readonly readOnly?: true;
  /** отображать всегда когда есть preview */
  readonly viewAlways?: boolean;
};

export type DataFilterStrategyEdit<T extends string, V = unknown> = DataFilterStrategyMayBePreviews<V> & {
  /** ключ, может быть любой если не используется в сортировке, если используется - должен ссылаться на правильный атрибут в модели */
  readonly key: T;
  /** тип */
  readonly type: EDataFilterType;
  /** представление в текстовом виде для режима просмотра */
  readonly preview: Nullable<string>;
  /** значение */
  readonly value: V;
  /** название, выводится при редактировании фильтра */
  readonly label: string;
  /** только чтение */
  readonly readOnly?: true;
  /** отображать всегда когда есть preview */
  readonly viewAlways?: boolean;
  /** настройки для querydsl */
  readonly querydsl?: DataFilterQueryDslItem;
};

export type DataFilterStrategyString<T extends string> = DataFilterStrategyEdit<T, Nullable<string>> & {
  readonly type: EDataFilterType.String;
  /** настройки группировки в одну строку как связанных фильтров */
  readonly group?: string;
};

export type DataFilterStrategyNumber<T extends string> = DataFilterStrategyEdit<T, Nullable<number>> & {
  readonly type: EDataFilterType.Number;
  readonly decimal?: boolean;
  /** настройки группировки в одну строку как связанных фильтров */
  readonly group?: string;
};

export type DataFilterStrategyYear<T extends string> = DataFilterStrategyEdit<T, Nullable<number>> & {
  readonly type: EDataFilterType.Year;
  readonly min?: number;
  readonly max?: number;
  readonly years?: number[];
};

export type DataFilterStrategyDate<T extends string> = DataFilterStrategyEdit<T, Nullable<string>> & {};

export type DataFilterStrategyDateSimple<T extends string> = DataFilterStrategyDate<T> & {
  readonly type: EDataFilterType.Date;
};

export type DataFilterStrategyDateAsDateTimePeriod<T extends string> = DataFilterStrategyEdit<
  T,
  Nullable<[string, string]>
> & {
  readonly type: EDataFilterType.DateAsTimePeriod;
};

export type DataFilterStrategyNumberPeriod<T extends string> = DataFilterStrategyEdit<
  T,
  Nullable<[Nullable<number>, Nullable<number>]>
> & {
  readonly type: EDataFilterType.NumberPeriod;
  readonly labelPeriod: { from: string; to: string };
};

export type DataFilterStrategyDatePeriod<T extends string> = DataFilterStrategyEdit<
  T,
  Nullable<[Nullable<string>, Nullable<string>]>
> & {
  readonly buttonsPeriod?: boolean;
  readonly type: EDataFilterType.DatePeriod;
  readonly labelPeriod: { from: string; to: string };
};

export type DataFilterStrategyDateFrom<T extends string> = DataFilterStrategyDate<T> & {
  readonly type: EDataFilterType.DateFrom;
  /** настройки группировки в одну строку как связанных фильтров */
  readonly group: string;
};

export type DataFilterStrategyDateTo<T extends string> = DataFilterStrategyDate<T> & {
  readonly type: EDataFilterType.DateTo;
  /** настройки группировки в одну строку как связанных фильтров */
  readonly group: string;
};

export type DataFilterStrategyListSingle<
  T extends string,
  V extends string = string,
  I extends DataFilterStrategyListItem = DataFilterStrategyListItem
> = DataFilterStrategyEdit<T, Nullable<V>> & {
  readonly items: Nullable<I[]>;
  readonly type: EDataFilterType.ListSingle;
  readonly autocomplete?: boolean;
};

export type DataFilterStrategyListMultiple<
  T extends string,
  V extends string[] = string[],
  I extends DataFilterStrategyListItem = DataFilterStrategyListItem
> = Omit<DataFilterStrategyEdit<T, Nullable<V>>, 'value'> & {
  readonly value: Nullable<V>;
  readonly items: Nullable<I[]>;
  readonly type: EDataFilterType.ListMultiple;
  readonly autocomplete?: boolean;
};

export type DataFilterStrategyCityMultipleAddress<
  T extends string,
  V extends DataFilterStrategyListItem[] = Address[]
> = Omit<DataFilterStrategyEdit<T, Nullable<V>>, 'value'> & {
  readonly value: Nullable<V>;
  readonly type: EDataFilterType.CityMultipleAddress;
};

type DataFilterStrategyMultiSelector<T extends string, P> = Omit<
  DataFilterStrategyEdit<T, Nullable<FilterMultiSelectorPredicate<P>>>,
  'value'
> & {
  readonly value: Nullable<FilterMultiSelectorPredicate<P>>;
  readonly selectNone?: boolean;
};

export type DataFilterStrategyOrgUnitsSelector<T extends string> = DataFilterStrategyMultiSelector<T, OrgUnit> & {
  readonly type: EDataFilterType.MultiSelectorPredicateOrgUnit;
};

export type DataFilterStrategyClientOrgsSelector<T extends string> = DataFilterStrategyMultiSelector<T, ClientOrg> & {
  readonly type: EDataFilterType.MultiSelectorPredicateClientOrg;
};

export type DataFilterStrategyAddressesSelector<T extends string> = DataFilterStrategyMultiSelector<T, Address> & {
  readonly type: EDataFilterType.MultiSelectorPredicateAddress;
};

export type DataFilterStrategyRoadsSelector<T extends string> = DataFilterStrategyMultiSelector<T, RzhdRoad> & {
  readonly type: EDataFilterType.MultiSelectorPredicateRoad;
};

export type DataFilterStrategySportOptionsSelector<
  T extends string,
  S extends string = string
> = DataFilterStrategyMultiSelector<T, S> & {
  readonly selectAllLabel: string;
  readonly items: SportOptionTyped<S>[];
  readonly type: EDataFilterType.MultiSelectorPredicateSportOption;
};

export type DataFilterStrategyLocalitySingle<T extends string, V extends DataFilterStrategyListItem = Address> = Omit<
  DataFilterStrategyEdit<T, Nullable<V>>,
  'value'
> & {
  readonly value: Nullable<V>;
  readonly type: EDataFilterType.AddressLocalitySingle;
};

export type DataFilterStrategyAddressSingle<T extends string, V extends DataFilterStrategyListItem = Address> = Omit<
  DataFilterStrategyEdit<T, Nullable<V>>,
  'value'
> & {
  readonly value: Nullable<V>;
  readonly type: EDataFilterType.AddressSingle;
};

export type DataFilterStrategyTarget<T extends string> = Omit<DataFilterStrategyEdit<T, Nullable<Target>>, 'value'> & {
  readonly value: Nullable<Target>;
  readonly type: EDataFilterType.Target;
};

export type DataFilterStrategyLocalityMultiple<
  T extends string,
  V extends DataFilterStrategyListItem[] = Address[]
> = Omit<DataFilterStrategyEdit<T, Nullable<V>>, 'value'> & {
  readonly value: Nullable<V>;
  readonly type: EDataFilterType.AddressLocalityMultiple;
  readonly regionId?: Nullable<string>;
};

export type DataFilterStrategyAutocompleteSingle<
  T extends string,
  V extends DataFilterStrategyListItem = DataFilterStrategyListItem,
  I extends DataFilterStrategyListItem = DataFilterStrategyListItem
> = Omit<DataFilterStrategyEdit<T, Nullable<V>>, 'value'> & {
  readonly value: Nullable<V>;
  readonly source: (search: string) => Promise<I[]>;
  readonly type: EDataFilterType.AutocompleteSingle;
};

export type DataFilterStrategyAutocompleteMultiple<
  T extends string,
  V extends DataFilterStrategyListItem[] = DataFilterStrategyListItem[],
  I extends DataFilterStrategyListItem = DataFilterStrategyListItem
> = Omit<DataFilterStrategyEdit<T, Nullable<V>>, 'value'> & {
  readonly value: Nullable<V>;
  readonly source: (search: string) => Promise<I[]>;
  readonly type: EDataFilterType.AutocompleteMultiple;
  readonly loadOnOpening?: boolean;
};

export type DataFilterStrategyBooleanFlag<T extends string> = DataFilterStrategyEdit<T, Nullable<true>> & {
  readonly type: EDataFilterType.BooleanFlag;
};

export type DataFilterStrategyAdapterTreeMultipleEditAdapter<
  T extends string,
  V extends DataFilterStrategyTreeItem[] = DataFilterStrategyTreeItem[]
> = FC<
  DataFilterEditItemProps<T, Nullable<V>> & {
    readonly strategy: DataFilterStrategyAdapterTreeMultiple<T, V>;
  }
>;

export type DataFilterStrategyAdapterTreeMultiple<
  T extends string,
  V extends DataFilterStrategyTreeItem[] = DataFilterStrategyTreeItem[]
> = Omit<DataFilterStrategyEdit<T, Nullable<V>>, 'value'> & {
  readonly value: Nullable<V>;
  readonly type: EDataFilterType.AdapterTreeMultiple;
  readonly editAdapter: DataFilterStrategyAdapterTreeMultipleEditAdapter<T, V>;
};

export type DataFilterValueItem<T> = {
  /* тип фильтра */
  readonly type?: EDataFilterType;
  /* полное значение фильтра */
  readonly value: T;
  /* настройки querydsl */
  readonly querydsl?: DataFilterQueryDslItem;
};
