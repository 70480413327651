import { useSelector } from 'react-redux';
import ClientOrgPreview from '../../components/preview';
import { ClientOrgWidget } from '../../components/widget';
import { clientOrgDetailsClientOrgSelector } from '../store/selectors';

export const ClientOrgDetailsPreviewAdapter = () => {
  const clientOrg = useSelector(clientOrgDetailsClientOrgSelector);

  if (!clientOrg) {
    return null;
  }

  return (
    <ClientOrgWidget label={'Предпросмотр'}>
      <ClientOrgPreview image={clientOrg.logo} />
    </ClientOrgWidget>
  );
};
