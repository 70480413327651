import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataFilterComponent from '../../../../../components/common/dataFilter';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../../hooks/useDataFilterAdapter';
import { EClientOrgUserTableFilterItem } from '../../filterUtils';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  clientOrgUsersFilterSelector,
  clientOrgUsersGuidSelector,
  clientOrgUsersSortColumnSelector,
} from '../store/selectors';
import { EClientOrgUserTableColumn } from '../utils';

interface ClientOrgUsersTableFilterAdapterProps {
  readonly filterAdapter: DataFilterAdapter<EClientOrgUserTableColumn, EClientOrgUserTableFilterItem>;
}

const ClientOrgUsersTableFilterAdapter = (props: ClientOrgUsersTableFilterAdapterProps) => {
  const { filterAdapter } = props;

  const handlers = useContextHandlers();

  const guid = useSelector(clientOrgUsersGuidSelector);
  const sortColumn = useSelector(clientOrgUsersSortColumnSelector);
  const filter = useSelector(clientOrgUsersFilterSelector);

  const { filterStrategies } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return useMemo(
    () => (
      <DataFilterComponent
        strategies={filterStrategies}
        onChange={handlers.onChangeFilter}
      />
    ),
    [filterStrategies, handlers.onChangeFilter]
  );
};

export default ClientOrgUsersTableFilterAdapter;
