import { UserAccessPartitionAnalyticsQueries } from '@/domain/model/accessMatrix';
import { UUID } from '@/domain/model/types';
import { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import TableCommonLayout from '../../../../layouts/tableCommon/container';
import { EAnalyticsQueriesTableFilterItem } from '../filterUtils';
import {
  AnalyticsQueriesBehaviorAdapter,
  AnalyticsQueriesTableAdapter,
  AnalyticsQueriesTableFilterAdapter,
  AnalyticsQueriesTableHeaderAdapter,
  AnalyticsQueriesTableTabsAdapter,
} from './adapters';
import { AnalyticsQueriesTableContext } from './context';
import AnalyticsQueriesTableFooterContainer from './footerContainer';
import { useAnalyticsQueriesTableHandlers } from './hooks/useTable';
import { EAnalyticsQueriesTableColumn, EAnalyticsQueriesTableTab } from './utils';

interface AnalyticsQueriesTableContainerProps {
  readonly guid: UUID;
  readonly tab: EAnalyticsQueriesTableTab;
  readonly filterAdapter: DataFilterAdapter<EAnalyticsQueriesTableColumn, EAnalyticsQueriesTableFilterItem>;
  readonly access: UserAccessPartitionAnalyticsQueries;
}

const AnalyticsQueriesTableContainer = (props: AnalyticsQueriesTableContainerProps) => {
  const { guid, tab, filterAdapter, access } = props;

  const handlers = useAnalyticsQueriesTableHandlers({ tab });

  return (
    <AnalyticsQueriesTableContext.Provider value={handlers}>
      <AnalyticsQueriesBehaviorAdapter
        guid={guid}
        tab={tab}
      />
      <TableCommonLayout
        filter={<AnalyticsQueriesTableFilterAdapter filterAdapter={filterAdapter} />}
        tabs={<AnalyticsQueriesTableTabsAdapter tab={tab} />}
        header={
          <AnalyticsQueriesTableHeaderAdapter
            tab={tab}
            canDownload={access.download}
          />
        }
        table={<AnalyticsQueriesTableAdapter filterAdapter={filterAdapter} />}
        footer={AnalyticsQueriesTableFooterContainer}
      ></TableCommonLayout>
    </AnalyticsQueriesTableContext.Provider>
  );
};

export default AnalyticsQueriesTableContainer;
