import { Fade, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { EUserStatus } from '../../../../domain/model/enums';
import { Nullable, UUID } from '../../../../domain/model/types';
import { MpUser } from '../../../../domain/model/user';
import { addSearchParamToLocation } from '../../../../routing/globalRouting';
import MasterActionsComponent from '../../../components/common/actions/master';
import AppBreadcrumbs from '../../../components/common/breadcrumbs';
import DefaultHeader from '../../../components/common/header';
import ContentLoader from '../../../components/common/loader';
import Splitter from '../../../components/common/splitter';
import { MPStepperStep } from '../../../components/common/stepper';
import { DefaultContentWrapper } from '../../../components/common/wrappers/content';
import { DefaultFooterWrapper } from '../../../components/common/wrappers/footer';
import { ETagColors, MPTag } from '../../../theme/ui-kit/tag';
import { PanelAction, PanelActions } from '../../../types';
import { getUserFullName } from '../../../utils';
import useBreadcrumbs from '../../general/breadcrumbs/useBreadcrumbs';
import StepperContainer from '../../general/stepper/container';
import { EPartnerEmployeeUrlParam } from '../entry';
import { EPartnerEmployeeActionType, PartnerEmployeeActionDetailsType } from '../types';
import { EPartnerEmployeeStep } from '../utils';
import PartnerEmployeeDetails from './component';
import { ContainerWrapper, ContentContainer, HeaderWrapper, LoaderWrapper, TitleWrapper } from './controls';
import { partnerEmployeeDetailsByIdSelector } from './store/selectors';
import {
  partnerEmployeeDetailsByIdFetch,
  partnerEmployeeDetailsChangeUserStatus,
  partnerEmployeeDetailsStateReset,
} from './store/slice';
import { getPartnerEmployeeDetailsActionsByStatus, getPartnerEmployeeDetailsStepText } from './utils';

interface PartnerEmployeeDetailsContainerProps {
  readonly id: UUID;
  readonly step: EPartnerEmployeeStep;
  readonly canChangeStatus?: boolean;
  readonly onEdit: Nullable<(employee: MpUser) => void>;
  readonly onClose: () => void;
}

const PartnerEmployeeDetailsContainer = (props: PartnerEmployeeDetailsContainerProps) => {
  const { id, step, canChangeStatus, onEdit, onClose } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { user: partnerEmployee, isFetching } = useSelector(partnerEmployeeDetailsByIdSelector);

  const breadcrumbs = useBreadcrumbs();

  const steps: [EPartnerEmployeeStep.Profile] = [EPartnerEmployeeStep.Profile];
  const actions: PanelActions<PartnerEmployeeActionDetailsType> = partnerEmployee?.status
    ? getPartnerEmployeeDetailsActionsByStatus(partnerEmployee.status, !!onEdit, !!canChangeStatus)
    : [];

  const onChangeStatus = (status: EUserStatus) => {
    dispatch(partnerEmployeeDetailsChangeUserStatus({ id, status }));
  };

  const onChangeStep = (newStep: MPStepperStep<EPartnerEmployeeStep>) => {
    history.push(addSearchParamToLocation({ location, param: EPartnerEmployeeUrlParam.Step, value: newStep.key }));
  };

  const onPanelAction = (action: PanelAction<PartnerEmployeeActionDetailsType>) => {
    const { type } = action;
    switch (type) {
      case EPartnerEmployeeActionType.Edit:
        if (partnerEmployee && onEdit) {
          onEdit(partnerEmployee);
        }
        break;
      case EPartnerEmployeeActionType.Activate:
        onChangeStatus(EUserStatus.Enabled);
        break;
      case EPartnerEmployeeActionType.Deactivate:
        onChangeStatus(EUserStatus.Disabled);
        break;
    }
  };

  useEffect(() => {
    const promise = dispatch(partnerEmployeeDetailsByIdFetch({ id }));
    return () => {
      dispatch(partnerEmployeeDetailsStateReset());
      promise?.abort();
    };
  }, [dispatch, id]);

  const mpSteps = steps.map(item => ({
    key: item,
    label: getPartnerEmployeeDetailsStepText(item),
  }));

  const mpStep = mpSteps.find(item => item.key === step) ?? {
    key: EPartnerEmployeeStep.Profile,
    label: getPartnerEmployeeDetailsStepText(EPartnerEmployeeStep.Profile),
  };

  const actionsPanel = (
    <MasterActionsComponent<PartnerEmployeeActionDetailsType>
      actions={actions}
      show={!!actions.length}
      wrapper={DefaultFooterWrapper}
      onAction={onPanelAction}
    />
  );

  return (
    <Fade in>
      <ContainerWrapper>
        {partnerEmployee && (
          <>
            <StepperContainer<EPartnerEmployeeStep>
              flow={false}
              step={mpStep}
              steps={mpSteps}
              onClick={onChangeStep}
            />
            <ContentContainer>
              <DefaultContentWrapper
                type='details'
                stickyHeader
                fullHeight
                footer={actionsPanel}
              >
                <HeaderWrapper>
                  <DefaultHeader
                    sticky
                    headline={
                      <>
                        {breadcrumbs.length > 0 && <AppBreadcrumbs />}
                        {partnerEmployee.status === EUserStatus.Disabled && (
                          <MPTag
                            bold
                            label={'Отключён'}
                            color={ETagColors.Warning}
                          />
                        )}
                      </>
                    }
                    onClose={onClose}
                  >
                    <TitleWrapper>
                      <Typography variant='h2'>{getUserFullName({ user: partnerEmployee })}</Typography>
                    </TitleWrapper>
                  </DefaultHeader>
                </HeaderWrapper>
                <Splitter size={4} />
                <PartnerEmployeeDetails
                  step={step}
                  partnerEmployee={partnerEmployee}
                />
              </DefaultContentWrapper>
            </ContentContainer>
          </>
        )}
        {isFetching && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
      </ContainerWrapper>
    </Fade>
  );
};

export default PartnerEmployeeDetailsContainer;
