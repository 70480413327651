import { EOfferStatus } from '../../../../../domain/model/enums';
import { EProductActionType } from '../../types';
import { ProductLifeCycleStatusScheme } from '../types';

export const adminStatusScheme: ProductLifeCycleStatusScheme = {
  description: 'Статусная схема главного админа',
  statusScheme: {
    [EOfferStatus.Draft]: [],
    [EOfferStatus.Rejected]: [EProductActionType.Publish],
    [EOfferStatus.Paused]: [
      EProductActionType.Edit,
      EProductActionType.Resume,
      EProductActionType.ResumeDesk,
      EProductActionType.Archive,
      EProductActionType.ArchiveDesk,
    ],
    [EOfferStatus.ReadyForSale]: [
      EProductActionType.Edit,
      EProductActionType.Pause,
      EProductActionType.PauseDesk,
      EProductActionType.Archive,
      EProductActionType.ArchiveDesk,
    ],
    [EOfferStatus.WaitingForModeration]: [
      EProductActionType.Approve,
      EProductActionType.Reject,
      EProductActionType.ChangeModerator,
      EProductActionType.InWork,
    ],
    [EOfferStatus.OnModeration]: [
      EProductActionType.Edit,
      EProductActionType.Approve,
      EProductActionType.Reject,
      EProductActionType.ChangeModerator,
    ],
    [EOfferStatus.Active]: [
      EProductActionType.Edit,
      EProductActionType.Pause,
      EProductActionType.PauseDesk,
      EProductActionType.Archive,
      EProductActionType.ArchiveDesk,
    ],
    [EOfferStatus.Archived]: [],
    [EOfferStatus.Upcoming]: [],
  },
  actionsScheme: {} as any,
};

export const partnerStatusScheme: ProductLifeCycleStatusScheme = {
  description: 'Статусная схема пользователя партнёра',
  statusScheme: {
    [EOfferStatus.Draft]: [
      EProductActionType.Edit,
      EProductActionType.Publish,
      EProductActionType.Duplicate,
      EProductActionType.Delete,
      EProductActionType.Publish,
      EProductActionType.ChangeCategory,
    ],
    [EOfferStatus.Rejected]: [
      EProductActionType.Edit,
      EProductActionType.Duplicate,
      EProductActionType.Archive,
      EProductActionType.ArchiveDesk,
      EProductActionType.Publish,
    ],
    [EOfferStatus.Paused]: [
      EProductActionType.Resume,
      EProductActionType.ResumeDesk,
      EProductActionType.ChangePrice,
      EProductActionType.ChangeStock,
      EProductActionType.Edit,
      EProductActionType.Duplicate,
      EProductActionType.Archive,
      EProductActionType.ArchiveDesk,
    ],
    [EOfferStatus.ReadyForSale]: [
      EProductActionType.ChangePrice,
      EProductActionType.ChangeStock,
      EProductActionType.Pause,
      EProductActionType.PauseDesk,
      EProductActionType.Edit,
      EProductActionType.Duplicate,
      EProductActionType.Archive,
      EProductActionType.ArchiveDesk,
    ],
    [EOfferStatus.WaitingForModeration]: [
      EProductActionType.Edit,
      EProductActionType.Duplicate,
      EProductActionType.UnPublish,
      EProductActionType.UnPublishDesk,
      EProductActionType.UnPublishAndEdit,
    ],
    [EOfferStatus.OnModeration]: [EProductActionType.Duplicate],
    [EOfferStatus.Upcoming]: [],
    [EOfferStatus.Active]: [
      EProductActionType.ChangePrice,
      EProductActionType.ChangeStock,
      EProductActionType.Edit,
      EProductActionType.Pause,
      EProductActionType.PauseDesk,
      EProductActionType.Duplicate,
      EProductActionType.Archive,
      EProductActionType.ArchiveDesk,
    ],
    [EOfferStatus.Archived]: [EProductActionType.Duplicate],
  },
  actionsScheme: {} as any,
};
