import styled from '@emotion/styled';
import { viewConfig } from '../../../theme/viewConfig';

export const Wrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  background: ${p => p.theme.palette.background.default};
  padding-bottom: ${p => p.theme.spacing(2)};
`;

export const Content = styled.div`
  flex-grow: 1;
`;
