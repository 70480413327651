import { Nullable, UUID } from '../../../../../domain/model/types';
import { SportUserProfile } from '../../../../../domain/model/user';
import { NumberRange } from '../../../../types';
import useSportsmansSearchSearch from '../hooks/useSearch';
import useSportsmansSearchSession from '../hooks/useSession';

interface SportsmansSearchBehaviorAdapterSingleProps {
  readonly initialSelected: Nullable<SportUserProfile>;
  readonly selectedMaxCount: 1;
  readonly onSelect: (sportsman: Nullable<SportUserProfile>) => void;
}

interface SportsmansSearchBehaviorAdapterMultipleProps {
  readonly initialSelected: Nullable<SportUserProfile[]>;
  readonly selectedMaxCount: NumberRange<2, 100> | false;
  readonly onSelect: (sportsmen: SportUserProfile[]) => void;
}

export type SportsmansSearchBehaviorAdapterSelectedProps =
  | SportsmansSearchBehaviorAdapterSingleProps
  | SportsmansSearchBehaviorAdapterMultipleProps;

type SportsmansSearchBehaviorAdapterProps = {
  readonly guid: UUID;
} & SportsmansSearchBehaviorAdapterSelectedProps;

export const SportsmansSearchBehaviorAdapter = (props: SportsmansSearchBehaviorAdapterProps) => {
  useSportsmansSearchSession({ ...props });
  useSportsmansSearchSearch({ guid: props.guid });

  return null;
};
