import { EBannerPartition } from '@/domain';
import { useContextHandlers } from '@features/banner/table/hooks/useContextHandlers';
import { getBannerTableHeaderText } from '@features/banner/table/utils';
import { Grid, Typography } from '@mui/material';
import { MPButton } from '@ui-kit/button';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../components/common/header';

interface BannerTableHeaderAdapterProps {
  readonly partition: EBannerPartition;
  readonly canCreate?: boolean;
}

export const BannerTableHeaderAdapter = (props: BannerTableHeaderAdapterProps) => {
  const { partition, canCreate } = props;

  const { onBannerCreate } = useContextHandlers();

  return (
    <DefaultHeader
      headline={<AppBreadcrumbs />}
      actions={
        canCreate && (
          <Grid
            container
            spacing={1}
            alignItems='center'
          >
            <Grid item>
              <MPButton
                variant='outlined'
                size='small'
                onClick={onBannerCreate}
              >
                Создать баннер
              </MPButton>
            </Grid>
          </Grid>
        )
      }
    >
      <Typography variant='h2'>{getBannerTableHeaderText(partition)}</Typography>
    </DefaultHeader>
  );
};