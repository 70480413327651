import { EPartnerOwnershipType } from '../../../../../../../domain/model/enums';
import {
  PartnerCompanyDataDraft,
  PartnerDataDraft,
  PartnerIndividualDataDraft,
} from '../../../../../../../domain/model/partner';
import { Nullable } from '../../../../../../../domain/model/types';
import { OnChangeObjectAttribute } from '../../../../../../types';
import { ValidationResult } from '../../../../../../utils/validation';
import { PartnerDocumentsCompany, PartnerDocumentsIndividual } from '../../../../../general/partner/validation';
import PartnerApplicationEditCompanyDocuments from './company';
import PartnerApplicationEditIndividualDocuments from './individual';

interface PartnerApplicationEditStepDocumentsProps {
  readonly partnerApplication: PartnerDataDraft;
  readonly isValid: boolean;
  readonly validationCompany: Nullable<ValidationResult<PartnerDocumentsCompany>>;
  readonly validationIndividual: Nullable<ValidationResult<PartnerDocumentsIndividual>>;
  readonly onChangeAttribute: OnChangeObjectAttribute<PartnerDataDraft>;
  readonly onChangeCompanyAttribute: OnChangeObjectAttribute<PartnerCompanyDataDraft>;
  readonly onChangeIndividualAttribute: OnChangeObjectAttribute<PartnerIndividualDataDraft>;
}

const PartnerApplicationEditStepDocuments = (props: PartnerApplicationEditStepDocumentsProps) => {
  const {
    partnerApplication,
    isValid,
    validationCompany,
    validationIndividual,
    onChangeAttribute,
    onChangeCompanyAttribute,
    onChangeIndividualAttribute,
  } = props;

  const { innCert, taxSystem, usnDoc } = partnerApplication;

  if (!partnerApplication.regInfo?.orgType) {
    return <></>;
  }

  switch (partnerApplication.regInfo?.orgType) {
    case EPartnerOwnershipType.IndividualEntrepreneur:
      return (
        <PartnerApplicationEditIndividualDocuments
          innCert={innCert}
          taxSystem={taxSystem}
          usnDoc={usnDoc}
          ogrnipCert={partnerApplication.regInfo?.ogrnipCert ?? null}
          passportCopy={partnerApplication.regInfo?.passportCopy ?? null}
          validation={validationIndividual}
          isValid={isValid}
          onChangeAttribute={onChangeAttribute}
          onChangeIndividualAttribute={onChangeIndividualAttribute}
        />
      );
    case EPartnerOwnershipType.StockCompany:
    case EPartnerOwnershipType.PublicJoinStockCompany:
    case EPartnerOwnershipType.PK:
    case EPartnerOwnershipType.UP:
    case EPartnerOwnershipType.FGBNU:
    case EPartnerOwnershipType.NonProfitOrganization:
    case EPartnerOwnershipType.ClosedJoinStockCompany:
    case EPartnerOwnershipType.JoinStockCompany:
    case EPartnerOwnershipType.NAO:
    case EPartnerOwnershipType.AutonomousNonProfitOrganization:
    case EPartnerOwnershipType.LimitedLiabilityCompany: {
      return (
        <PartnerApplicationEditCompanyDocuments
          innCert={innCert}
          taxSystem={taxSystem}
          usnDoc={usnDoc}
          ogrnCert={partnerApplication.regInfo?.ogrnCert ?? null}
          charterDoc={partnerApplication.regInfo?.charterDoc ?? null}
          ceoAppointmentProtocol={partnerApplication.regInfo?.ceoAppointmentProtocol ?? null}
          validation={validationCompany}
          isValid={isValid}
          onChangeAttribute={onChangeAttribute}
          onChangeCompanyAttribute={onChangeCompanyAttribute}
        />
      );
    }
    default:
      return <></>;
  }
};

export default PartnerApplicationEditStepDocuments;
