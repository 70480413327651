import { useMemo } from 'react';
import { AddressLocalityFullPathView } from '../../../address';
import { TeamTableCellCommonProps } from './index';

const TeamTableCellLocality = (props: TeamTableCellCommonProps) => {
  const {
    team: { locality },
  } = props;

  return useMemo(() => <AddressLocalityFullPathView address={locality} />, [locality]);
};

export default TeamTableCellLocality;
