import { Divider, ListItemText, Typography } from '@mui/material';
import { EUserRole } from '../../../../../../domain/model/enums';
import { AppUser } from '../../../../../../domain/model/user';
import privilegeIcon from '../../../../../icons/privilegeIcon.png';
import { getUserRoleName } from '../../../../../utils/roles';
import { HeaderListItem, PrivilegeIcon } from './controls';

interface MainMenuHeaderMpProps {
  readonly user: AppUser;
}

const MainMenuHeaderMp = ({ user }: MainMenuHeaderMpProps) => {
  return (
    <>
      <HeaderListItem>
        <ListItemText
          primary={<PrivilegeIcon src={privilegeIcon} />}
          secondary={<Typography>{getUserRoleName((user.realm_access?.roles ?? []) as EUserRole[])}</Typography>}
        />
      </HeaderListItem>
      <Divider />
    </>
  );
};

export default MainMenuHeaderMp;
