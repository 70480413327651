import { useCallback, useMemo } from 'react';
import { Nullable } from '../../../../../domain/model/types';
import { DataTableMetadata } from '../../../../components/common/table';
import useDataTableSettings from '../../../../hooks/useDataTableSettings';
import { EProductOrderTableColumn, EProductOrderTableTab } from '../../types';
import { getProductOrderTableColumns } from '../utils';

type UseProductOrderTableMetadataProps = {
  readonly tab: EProductOrderTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly isPartnerUser: boolean;
  readonly sortColumn: Nullable<EProductOrderTableColumn>;
  readonly onSortReset: () => void;
};

type UseProductOrderTableMetadata = {
  readonly metadata: DataTableMetadata<EProductOrderTableColumn>;
  readonly onChangeMetadata: (metadata: DataTableMetadata<EProductOrderTableColumn>) => void;
};

export const useProductOrderTableMetadata = (
  props: UseProductOrderTableMetadataProps
): UseProductOrderTableMetadata => {
  const { tab, partnerId, isPartnerUser, sortColumn, onSortReset } = props;

  const columns = useMemo(() => getProductOrderTableColumns(tab), [tab]);

  const keyPartitions = useMemo(
    () => (isPartnerUser ? ['partner', tab] : ['admin', partnerId ? 'byPartner' : 'common', tab]),
    [partnerId, isPartnerUser, tab]
  );

  const onColumnsRemoved = useCallback(
    (columns: EProductOrderTableColumn[]) => {
      if (sortColumn && columns.includes(sortColumn)) {
        onSortReset();
      }
    },
    [sortColumn, columns, onSortReset]
  );

  const { metadata, onChangeMetadata } = useDataTableSettings<EProductOrderTableColumn>({
    name: 'order-product',
    nameParts: keyPartitions,
    sourceColumns: columns,
    onColumnsRemoved,
  });

  return {
    metadata,
    onChangeMetadata,
  };
};
