import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ForbiddenComponent from '../../components/common/forbidden';
import useBreadcrumbsActions from '../../features/general/breadcrumbs/useBreadcrumbsActions';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import PartnerEmployeeCreateContainer from '../../features/partnerEmployee/create/container';
import { EPartnerEmployeeStep } from '../../features/partnerEmployee/utils';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const PartnerEmployeeCreateScreen = () => {
  const dispatch = useDispatch();
  const { putBreadcrumbs } = useBreadcrumbsActions();
  const { accessMatrix, mpPartnerUserProfile } = useCurrentUser();

  const { partnerEmployees } = accessMatrix;

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  useEffect(() => {
    putBreadcrumbs([{ label: 'Сотрудники' }]);
  }, []);

  if (!partnerEmployees?.create) {
    return <ForbiddenComponent />;
  }

  return (
    <PartnerEmployeeCreateContainer
      partnerId={mpPartnerUserProfile.partner.id}
      step={EPartnerEmployeeStep.Profile}
    />
  );
};

export default PartnerEmployeeCreateScreen;
