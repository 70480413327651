import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import TablePagination from '../../../../components/common/table/pagination';
import { useContextHandlers } from './hooks/useContextHandlers';
import {
  clientOrgUsersPageCountSelector,
  clientOrgUsersPageNumberSelector,
  clientOrgUsersSearchSelector,
  clientOrgUsersTotalCountSelector,
} from './store/selectors';

interface EventTableFooterContainerProps {}

const BookingTableFooterContainer = forwardRef((props: EventTableFooterContainerProps, ref: any) => {
  const handlers = useContextHandlers();

  const pageNumber = useSelector(clientOrgUsersPageNumberSelector);
  const search = useSelector(clientOrgUsersSearchSelector);
  const totalCount = useSelector(clientOrgUsersTotalCountSelector);
  const pageCount = useSelector(clientOrgUsersPageCountSelector);

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='пользователей'
        onChangePageSize={handlers.onChangePageSize}
        onChangePage={handlers.onChangePage}
      />
    </div>
  );
});

export default BookingTableFooterContainer;
