import { CommonLifeCycleInstance } from '../../../utils/lifecycle';
import { corpActivationAdminConfiguration, corpActivationPartnerConfiguration } from './configuration';
import { CorpActivationLifeCycle, CorpActivationLifeCycleFactoryProps, CorpActivationLifeCycleProps } from './types';

const CorpActivationLifeCycleInstance = (props: CorpActivationLifeCycleProps): CorpActivationLifeCycle => {
  const { configuration } = props;

  const commonLifecycle = CommonLifeCycleInstance(props);

  return {
    configuration,
    build: ({ allowedActions, obj }) => {
      const commonLifecycleBuild = commonLifecycle.build({ allowedActions, obj });

      return {
        ...commonLifecycleBuild,
      };
    },
  };
};

/**
 * фабрика создания жизненнешго цикла активациии корпоративного предложения
 */
export const CorpActivationLifeCycleFactory = () => ({
  create: ({ isPartnerUser, userId, debug }: CorpActivationLifeCycleFactoryProps): CorpActivationLifeCycle => {
    if (isPartnerUser) {
      return CorpActivationLifeCycleInstance({ userId, configuration: corpActivationPartnerConfiguration, debug });
    } else {
      return CorpActivationLifeCycleInstance({ userId, configuration: corpActivationAdminConfiguration, debug });
    }
  },
});