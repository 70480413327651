import React from 'react';
import { UseTradeOffersTable } from './hooks/useTradeOffersTable';
import { UseTradeOffersTableConfig } from './hooks/useTradeOffersTableConfig';

export type TradeOffersTableContextValue = UseTradeOffersTable;

export const TradeOffersTableContext = React.createContext<TradeOffersTableContextValue>(
  {} as TradeOffersTableContextValue
);

export type TradeOffersTableConfigContextValue = UseTradeOffersTableConfig;

export const TradeOffersTableConfigContext = React.createContext<TradeOffersTableConfigContextValue>(
  {} as TradeOffersTableConfigContextValue
);
