import { CmsContainer } from '../../../../../domain/model/cms';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../../components/common/table';
import { DataTableLoader } from '../../../../components/common/table/loader';
import CmsContainerTableCellEndDate from '../../components/containerTableCell/endDate';
import CmsContainerTableCellLinkTo from '../../components/containerTableCell/linkTo';
import CmsContainerTableCellName from '../../components/containerTableCell/name';
import CmsContainerTableCellOfferType from '../../components/containerTableCell/offerType';
import CmsContainerTableCellPreview from '../../components/containerTableCell/preview';
import CmsContainerTableCellSortIndex from '../../components/containerTableCell/sortIndex';
import CmsContainerTableCellStartDate from '../../components/containerTableCell/startDate';
import CmsContainerTableCellStatusComment from '../../components/containerTableCell/statusComment';
import CmsContainerTableCellTarget from '../../components/containerTableCell/target';
import CmsContainerTableCellTitle from '../../components/containerTableCell/title';
import CmsContainerTableCellType from '../../components/containerTableCell/type';
import CmsContainerTableCellUpdatedAt from '../../components/containerTableCell/updatedAt';
import { CmsContainerTableView } from '../../types';
import { CmsContainerActionCall, CmsContainerActions, CmsContainerActionTableType } from '../types';
import CmsContainerTableActions from './actions';
import CmsContainersTableCellAdapter from './adapters/cell';
import CmsContainersTableCellSortIndexAdapter from './adapters/cellSortIndex';
import { cmsContainersIsFetchingSelector } from './store/selectors';
import { CmsContainerActionTableCommonType, CmsContainerTableActionProcess, ECmsContainerTableColumn } from './utils';

interface CmsContainerTableProps {
  readonly metadata: DataTableMetadata<ECmsContainerTableColumn>;
  readonly cmsContainers: CmsContainerTableView[];
  readonly getActions: (cmsContainer: CmsContainer) => CmsContainerActions<CmsContainerActionTableType>;
  readonly getCommonActions: (cmsContainer: CmsContainer) => CmsContainerActions<CmsContainerActionTableCommonType>;
  readonly onChangeMetadata: (metadata: DataTableMetadata<ECmsContainerTableColumn>) => void;
  readonly onClick: (cmsContainer: CmsContainerTableView) => void;
  readonly onAction: (call: CmsContainerActionCall) => void;
  readonly getActionProcesses: (cmsContainer: CmsContainerTableView) => CmsContainerTableActionProcess[];
}

const CmsContainerTable = (props: CmsContainerTableProps) => {
  const {
    metadata,
    cmsContainers,
    getActions,
    getCommonActions,
    onChangeMetadata,
    onClick,
    onAction,
    getActionProcesses,
  } = props;

  const rows: DataTableRow<CmsContainerTableView, ECmsContainerTableColumn>[] = cmsContainers?.map(
    (cmsContainer, index) => {
      return {
        [ECmsContainerTableColumn.UpdatedAt]: (
          <CmsContainersTableCellAdapter
            index={index}
            component={CmsContainerTableCellUpdatedAt}
          />
        ),
        [ECmsContainerTableColumn.Preview]: (
          <CmsContainersTableCellAdapter
            index={index}
            component={CmsContainerTableCellPreview}
          />
        ),
        [ECmsContainerTableColumn.Name]: (
          <CmsContainersTableCellAdapter
            index={index}
            component={CmsContainerTableCellName}
          />
        ),
        [ECmsContainerTableColumn.Title]: (
          <CmsContainersTableCellAdapter
            index={index}
            component={CmsContainerTableCellTitle}
          />
        ),
        [ECmsContainerTableColumn.SortIndex]: ({ hoveredRow }) => (
          <CmsContainersTableCellSortIndexAdapter
            hoveredRow={hoveredRow}
            index={index}
            component={CmsContainerTableCellSortIndex}
            getActionProcesses={getActionProcesses}
            getActions={getActions}
            onCmsContainerAction={onAction}
          />
        ),
        [ECmsContainerTableColumn.StartDate]: (
          <CmsContainersTableCellAdapter
            index={index}
            component={CmsContainerTableCellStartDate}
          />
        ),
        [ECmsContainerTableColumn.EndDate]: (
          <CmsContainersTableCellAdapter
            index={index}
            component={CmsContainerTableCellEndDate}
          />
        ),
        [ECmsContainerTableColumn.Type]: (
          <CmsContainersTableCellAdapter
            index={index}
            component={CmsContainerTableCellType}
          />
        ),
        [ECmsContainerTableColumn.OfferType]: (
          <CmsContainersTableCellAdapter
            index={index}
            component={CmsContainerTableCellOfferType}
          />
        ),
        [ECmsContainerTableColumn.LinkTo]: (
          <CmsContainersTableCellAdapter
            index={index}
            component={CmsContainerTableCellLinkTo}
          />
        ),
        [ECmsContainerTableColumn.Target]: (
          <CmsContainersTableCellAdapter
            index={index}
            component={CmsContainerTableCellTarget}
          />
        ),
        [ECmsContainerTableColumn.StatusComment]: (
          <CmsContainersTableCellAdapter
            index={index}
            component={CmsContainerTableCellStatusComment}
          />
        ),
        data: cmsContainer,
      };
    }
  );

  return (
    <DataTable<CmsContainerTableView, ECmsContainerTableColumn>
      metadata={metadata}
      width='auto'
      overflowX='inherit'
      rows={rows}
      rowActions
      hoverModule
      loader={<DataTableLoader selector={cmsContainersIsFetchingSelector} />}
      onRowClick={(event, cell, { data }) => onClick(data)}
      onChangeMetadata={onChangeMetadata}
      getRowActions={cmsContainer => (
        <CmsContainerTableActions<CmsContainer>
          id={cmsContainer.id}
          actions={getCommonActions(cmsContainer)}
          onExecute={action =>
            onAction({
              action,
              cmsContainer,
            })
          }
          processes={getActionProcesses(cmsContainer)}
        />
      )}
    />
  );
};

export default CmsContainerTable;
