import offer, { ApprovalRegistryOfferServices } from './offers';

type ApprovalRegistryServices = {
  readonly offer: ApprovalRegistryOfferServices;
};

const approvalRegistryServices: ApprovalRegistryServices = {
  offer,
};

export default approvalRegistryServices;
