import { FCC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useBookingOrderActions } from '../../actions/useAction';
import { BookingOrderAction, BookingOrderActionDetailsType, EBookingOrderActionType } from '../../types';
import { getBookingOrderActionsConfigByPermissions } from '../../utils/actions';
import { BookingOrderDetailsContainerProps } from '../container';
import { BookingOrderDetailsLifecycleContext, BookingOrderDetailsLifecycleContextValue } from '../context';
import useBookingOrderDetailsActions from '../hooks/useActions';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingOrderDetailsBookingOrderForLifeCycleSelector } from '../store/selectors';

export const BookingOrderDetailsLifecycleProvider: FCC<BookingOrderDetailsContainerProps> = ({
  id,
  children,
  lifecycle,
  access,
}) => {
  const handlers = useContextHandlers();

  const bookingOrder = useSelector(bookingOrderDetailsBookingOrderForLifeCycleSelector);

  const {
    utils: {
      selectors: { getIsBookingOrderExecutedActionsSelector },
    },
  } = useBookingOrderActions();

  const isActionsForClose = useSelector(getIsBookingOrderExecutedActionsSelector(id, EBookingOrderActionType.Cancel));

  const allowedActions = getBookingOrderActionsConfigByPermissions({
    ...access,
  });

  const lifeCycleRules = lifecycle.build({
    allowedActions,
    obj: bookingOrder,
  });

  const actions = useBookingOrderDetailsActions({
    lifeCycleRules,
  });

  const onAction = (action: BookingOrderAction<BookingOrderActionDetailsType>) => {
    if (!bookingOrder) {
      return;
    }

    const { type } = action;
    switch (type) {
      case EBookingOrderActionType.Save:
        handlers.onBookingOrderOpenEdit();
        break;
      case EBookingOrderActionType.Cancel:
        handlers.onBookingOrderTryCancel();
        break;
    }
  };

  const value: BookingOrderDetailsLifecycleContextValue = {
    lifecycle,
    actions,
    onAction,
  };

  useEffect(() => {
    if (isActionsForClose) {
      handlers.onBookingOrderClose(isActionsForClose);
    }
  }, [isActionsForClose, handlers.onBookingOrderClose]);

  return (
    <BookingOrderDetailsLifecycleContext.Provider value={value}>
      {children}
    </BookingOrderDetailsLifecycleContext.Provider>
  );
};
