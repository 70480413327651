import { CorpOfferCategoriesEditDictionaryAdapter } from './adapters';
import { CorpOfferCategoriesEditCommonProps } from './types';

export type CorpOfferCategoriesEditContainerProps = CorpOfferCategoriesEditCommonProps & {
  readonly onClose?: () => void;
};

const CorpOfferCategoriesEditContainer = (props: CorpOfferCategoriesEditContainerProps) => {
  const { access } = props;

  return <CorpOfferCategoriesEditDictionaryAdapter access={access} />;
};

export default CorpOfferCategoriesEditContainer;
