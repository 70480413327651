import { combineReducers } from '@reduxjs/toolkit';
import create, { TradeOfferCreateState } from './create/store/slice';
import details, { TradeOfferDetailsState } from './details/store/slice';
import search, { TradeOfferSearchState } from './search/store/slice';
import list, { TradeOfferListState } from './table/store/slice';
import upload, { TradeOffersUploadState } from './upload/store/slice';
import actions, { TradeOffersActionsState } from './actions/store/slice';

interface TradeOfferState {
  list: TradeOfferListState;
  create: TradeOfferCreateState;
  upload: TradeOffersUploadState;
  details: TradeOfferDetailsState;
  search: TradeOfferSearchState;
  actions: TradeOffersActionsState;
}

export default combineReducers<TradeOfferState>({
  list,
  create,
  upload,
  details,
  search,
  actions,
});
