import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../../data/api';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { Fetchable } from '../../../../../../data/store/types';
import { Partner } from '../../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import { partnerEmployeesChangePartnerOwner } from '../../../../partnerEmployee/table/store/slice';

export const partnerManagementPartnerByIdFetch = createAsyncThunk<Partner, UUID, AppThunkAPIConfig>(
  'partner/management/container/byId/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      const { data: partner } = await Api.partner.one({ id, signal });
      return partner;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export interface PartnerManagementContainerState {
  readonly menu: {
    readonly visible: boolean;
  };
  readonly partner: Fetchable & {
    readonly data: Nullable<Partner>;
    readonly needRefreshWatcher: number;
  };
}

type Reducer<T = undefined> = CaseReducer<PartnerManagementContainerState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PartnerManagementContainerState> {
  viewShowPartnerManagementMenu: Reducer;
  viewHidePartnerManagementMenu: Reducer;
}

const slice = createSlice<PartnerManagementContainerState, Reducers, 'partner/management/container'>({
  name: 'partner/management/container',
  initialState: {
    menu: {
      visible: true,
    },
    partner: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      data: null,
      needRefreshWatcher: 0,
    },
  },
  reducers: {
    viewShowPartnerManagementMenu(state) {
      state.menu.visible = true;
    },
    viewHidePartnerManagementMenu(state) {
      state.menu.visible = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(partnerManagementPartnerByIdFetch.pending, state => {
        state.partner.isFetching = true;
        state.partner.isFetched = false;
        state.partner.isFailed = false;
        state.partner.data = null;
      })
      .addCase(partnerManagementPartnerByIdFetch.fulfilled, (state, { payload }) => {
        state.partner.isFetching = false;
        state.partner.isFetched = true;
        state.partner.isFailed = false;

        state.partner.data = payload;
      })
      .addCase(partnerManagementPartnerByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.partner.isFetching = false;
          state.partner.isFetched = false;
          state.partner.isFailed = true;
        } else {
          state.partner.isFetching = false;
          state.partner.isFetched = false;
          state.partner.isFailed = false;
        }

        state.partner.data = null;
      })
      .addCase(partnerEmployeesChangePartnerOwner.fulfilled, (state, { meta }) => {
        const { partnerId } = meta.arg;
        if (partnerId === state.partner.data?.id) {
          state.partner.needRefreshWatcher++;
        }
      });
  },
});

export const { viewShowPartnerManagementMenu, viewHidePartnerManagementMenu } = slice.actions;

export default slice.reducer;
