import { ClientOrgLifeCycleRules } from '../../lifecycle/types';
import { ClientOrgActionDetailsType, ClientOrgActions, EClientOrgActionType } from '../../types';
import {
  changeClientOrgActionPosition,
  filterClientOrgDetailsActions,
  renameClientOrgAction,
} from '../../utils/actions';
import { EPanelActionPosition } from '@/presentation/types';

type UseClientOrgDetailsActions = ClientOrgActions<ClientOrgActionDetailsType>;

interface UseClientOrgDetailsActionsProps {
  readonly lifeCycleRules: ClientOrgLifeCycleRules;
}

const useClientOrgDetailsActions = (props: UseClientOrgDetailsActionsProps): UseClientOrgDetailsActions => {
  const { lifeCycleRules } = props;

  const { nextActions } = lifeCycleRules;
  const detailsActions = filterClientOrgDetailsActions(nextActions);

  //переименуем кнопку Редактирования/Сохранения
  renameClientOrgAction(detailsActions, EClientOrgActionType.Edit, 'Редактировать');

  //Переносим экшен Редактирования/Сохранения в три точки
  changeClientOrgActionPosition(detailsActions, EClientOrgActionType.Edit, [EPanelActionPosition.Menu]);

  return detailsActions;
};

export default useClientOrgDetailsActions;
