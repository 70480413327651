// получение действий с оффером с учетом состояния оффера в таблице
import { EProductTableTab } from '../table/utils';
import { EProductActionType, ProductActions, ProductActionTableType } from '../types';
import {
  filterProductTableActions,
  GetProductActionsByPermissionsProps,
  getProductActionsConfigByPermissions,
} from './actions';

type ProductActionTypes = ProductActionTableType;

interface GetProductTableTabActionsProps {
  readonly tab: EProductTableTab;
  readonly actions: ProductActions<ProductActionTypes>;
}

// получение возможных действий с товаром для вкладки таблицы
export const getProductTableTabActionsConfig = (
  props: GetProductTableTabActionsProps
): ProductActions<ProductActionTypes> => {
  const { actions, tab } = props;

  switch (tab) {
    case EProductTableTab.Active:
      return actions.filter(item =>
        [
          EProductActionType.ChangePrice,
          EProductActionType.ChangeStock,
          EProductActionType.Pause,
          EProductActionType.PauseDesk,
          EProductActionType.Edit,
          EProductActionType.Duplicate,
          EProductActionType.Archive,
          EProductActionType.ArchiveDesk,
        ].includes(item.type)
      );
    case EProductTableTab.ReadyForSale:
      return actions.filter(item =>
        [
          EProductActionType.ChangePrice,
          EProductActionType.ChangeStock,
          EProductActionType.Pause,
          EProductActionType.PauseDesk,
          EProductActionType.Edit,
          EProductActionType.Duplicate,
          EProductActionType.Archive,
          EProductActionType.ArchiveDesk,
        ].includes(item.type)
      );
    case EProductTableTab.Draft:
      return actions.filter(item =>
        [
          EProductActionType.Duplicate,
          EProductActionType.Delete,
          EProductActionType.Publish,
          EProductActionType.Edit,
        ].includes(item.type)
      );
    case EProductTableTab.Paused:
      return actions.filter(item =>
        [
          EProductActionType.ChangePrice,
          EProductActionType.ChangeStock,
          EProductActionType.Resume,
          EProductActionType.ResumeDesk,
          EProductActionType.Edit,
          EProductActionType.Duplicate,
          EProductActionType.Archive,
          EProductActionType.ArchiveDesk,
        ].includes(item.type)
      );
    case EProductTableTab.Moderation:
      return actions.filter(item =>
        [
          EProductActionType.Edit,
          EProductActionType.Duplicate,
          EProductActionType.UnPublish,
          EProductActionType.UnPublishDesk,
          EProductActionType.Approve,
          EProductActionType.Reject,
          EProductActionType.ChangeModerator,
        ].includes(item.type)
      );
    case EProductTableTab.Archived:
      return actions.filter(item => [EProductActionType.Duplicate].includes(item.type));
    case EProductTableTab.Rejected:
      return actions.filter(item =>
        [
          EProductActionType.Edit,
          EProductActionType.Publish,
          EProductActionType.Duplicate,
          EProductActionType.Archive,
          EProductActionType.ArchiveDesk,
        ].includes(item.type)
      );
  }
};

export const getProductTableTabActions = (
  permissions: GetProductActionsByPermissionsProps,
  tab: EProductTableTab
): ProductActions<ProductActionTypes> => {
  const allowedActions = getProductActionsConfigByPermissions(permissions);
  const actions = filterProductTableActions(allowedActions);
  return getProductTableTabActionsConfig({ tab, actions });
};
