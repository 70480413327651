import Axios from 'axios';
import Api from '../../../../data/api';
import {
  ApiCancellable,
  ApiQueryDsl,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
} from '../../../../data/api/types';
import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '../../../../data/api/utils';
import { Pageable } from '../../../../domain/model';
import { EOrderDiscriminator, EOrderStatus } from '../../../../domain/model/enums';
import { BookingOrder, BookingOrderRequest, BookingOrderStatus } from '../../../../domain/model/order';
import { Nullable, UUID } from '../../../../domain/model/types';
import { PaginationSize } from '../../../types';
import { BookingOrdersFilterValues, EBookingOrdersFilterItem } from '../table/filterUtils';
import { BookingOrderTableTabsCounter, getBookingOrderStatusesByTableTab } from '../table/utils';
import { BookingOrderCounterByStatus, EBookingOrderTableTab } from '../types';

const orderDiscriminator = EOrderDiscriminator.BookingOrder;

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: string;
    readonly statuses: EOrderStatus[];
    readonly partnerId: Nullable<UUID>;
    readonly customerId: Nullable<UUID>;
  };
  readonly filter: BookingOrdersFilterValues;
  readonly pageNumber: number;
};

type BuildListQueryParamsReturn<D extends ApiRequestListDiscriminator> = ApiCancellable &
  ApiRequestPageable & {
    readonly partnerId: Nullable<UUID>;
    readonly customerId: Nullable<UUID>;
    readonly statuses?: EOrderStatus[];
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

export type CountProps = Omit<AllProps, 'pageNumber'>;

type CountsByStatusesProps = CountProps;

type CountsByTabsProps = CountProps & {
  readonly tabs: EBookingOrderTableTab[];
};

type CancelProps = ApiCancellable & {
  readonly id: UUID;
  readonly reasonId: UUID;
  readonly comment?: string;
};

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type SaveProps = {
  readonly id: UUID;
  readonly order: BookingOrderRequest;
};

export type BookingOrderOrderService = {
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps
  ) => BuildListQueryParamsReturn<D>;
  readonly all: (props: AllProps) => Promise<Pageable<BookingOrder>>;
  readonly one: (props: OneProps) => Promise<BookingOrder>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly countsByStatuses: (props: CountsByStatusesProps) => Promise<{
    counts: BookingOrderCounterByStatus;
    errors: Array<string>;
  }>;
  readonly countsByTabs: (props: CountsByTabsProps) => Promise<{
    counts: BookingOrderTableTabsCounter;
    errors: Array<string>;
  }>;
  readonly cancel: (props: CancelProps) => Promise<BookingOrder>;
  readonly customerCancel: (props: CancelProps) => Promise<BookingOrder>;
  readonly confirm: (id: UUID) => Promise<BookingOrder>;
  readonly renew: (id: UUID) => Promise<BookingOrder>;
  readonly given: (id: UUID) => Promise<BookingOrder>;

  readonly save: (data: SaveProps) => Promise<BookingOrder>;
};

const service: BookingOrderOrderService = {
  buildListQueryParams: props => {
    const { search, filter, pageNumber, signal } = props;
    const { pageSize, sort, statuses, partnerId, customerId } = search;

    const query = filter[EBookingOrdersFilterItem.Query]?.value;
    const querydsl = getQueryDslByDataFilterValues(filter);

    return {
      query,
      sort,
      signal,
      pageSize,
      partnerId,
      customerId,
      statuses,
      page: pageNumber,
      querydsl,
    };
  },
  one: async ({ id, signal }) => {
    return (await Api.order.mp.one({ id, orderDiscriminator, signal })).data;
  },
  all: async props => {
    const response = await Api.order.mp.all({
      ...service.buildListQueryParams(props),
      orderDiscriminator,
    });
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);
    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
  count: async props => {
    const { data: response } = await Api.order.mp.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      orderDiscriminator,
      discriminator: ApiRequestListDiscriminator.Count,
    });

    return response[0].count;
  },
  countsByStatuses: async ({ signal, ...props }) => {
    const {
      search: { statuses },
    } = props;

    const errors: string[] = [];
    const counts: BookingOrderCounterByStatus = {};

    const requests = statuses.map(status => {
      const params = {
        ...props,
        search: { ...props.search, statuses: [status] },
      };
      return service.count({ ...params, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], status: BookingOrderStatus) => {
      if (response.status === 'fulfilled') {
        counts[status] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество заказов '${status}': ${response.reason}`);
        }
      }
    };

    // TODO - апи может разделить, чтобы as не использовать?
    statuses.forEach((status, index) => parseResponse(responses[index], status as BookingOrderStatus));

    return { counts, errors };
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: BookingOrderTableTabsCounter = {};

    const requests = tabs.map(tab => {
      const statuses = getBookingOrderStatusesByTableTab(tab);
      const params = {
        ...props,
        search: { ...props.search, statuses },
      };
      return service.count({ ...params, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], tab: EBookingOrderTableTab) => {
      if (response.status === 'fulfilled') {
        counts[tab] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество заказов '${tab}': ${response.reason}`);
        }
      }
    };

    tabs.forEach((tab, index) => parseResponse(responses[index], tab));

    return { counts, errors };
  },
  cancel: async ({ id, comment, reasonId }) => {
    return (await Api.order.mp.cancel({ id, orderDiscriminator, comment, reasonId })).data;
  },
  customerCancel: async ({ id, comment, reasonId }) => {
    return (await Api.order.mp.customerCancel({ id, orderDiscriminator, comment, reasonId })).data;
  },
  confirm: async id => {
    return (await Api.order.mp.confirm({ id, orderDiscriminator })).data;
  },
  renew: async id => {
    return (await Api.order.mp.renew({ id, orderDiscriminator })).data;
  },
  given: async id => {
    return (await Api.order.mp.given({ id, orderDiscriminator })).data;
  },
  save: async ({ id, order }) => {
    return (await Api.order.mp.updateBookingOrder({ id, data: order })).data;
  },
};

export default service;
