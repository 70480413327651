import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { viewShowMainMenu } from '@features/main/container/store/slice';
import UserCurrentDetailsMpContainer from '@features/user/current/detailsMp/container';
import UserCurrentDetailsSportContainer from '@features/user/current/detailsSport/container';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';
import { UserCurrentActionsProvider } from '@features/user/current/actions/provider';

const UserProfileScreen = () => {
  const dispatch = useDispatch();
  const { userSpecific, accessMatrix, logOut } = useCurrentUser();

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (accessMatrix.isAdminSport) {
    return (
      <UserCurrentDetailsSportContainer
        id={userSpecific.id}
        resetPassword={null}
        logOut={logOut}
      />
    );
  } else {
    return (
      <UserCurrentActionsProvider>
        <UserCurrentDetailsMpContainer
          id={userSpecific.id}
          roles={accessMatrix.roles}
          logOut={logOut}
        />
      </UserCurrentActionsProvider>
    );
  }
};

export default UserProfileScreen;
