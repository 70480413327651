import { combineReducers } from '@reduxjs/toolkit';
import edit, { CorpOfferCategoriesEditState } from './edit/store/slice';
import select, { CorpOfferCategoriesSelectState } from './select/store/slice';

interface CorpOfferCategoriesState {
  edit: CorpOfferCategoriesEditState;
  select: CorpOfferCategoriesSelectState;
}

export default combineReducers<CorpOfferCategoriesState>({
  edit,
  select,
});
