import { Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import ConfirmDialog from '@components/common/dialogs/confirm';

export type UserSuccessEmailUpdateDialogProps = {
  readonly open: boolean;
  readonly email: string;
  readonly onClose: () => void;
};

const ChangeEmailSuccessDialog = (props: UserSuccessEmailUpdateDialogProps) => {
  const { email, open, onClose } = props;

  return (
    <ConfirmDialog
      title='Электронная почта изменена'
      open={open}
      onClose={onClose}
      dialogButtons={
        <MPButton
          fullWidth
          onClick={onClose}
        >
          Продолжить
        </MPButton>
      }
    >
      Электронная почта вашего аккаунта была успешно изменена на{' '}
      <Typography component='span'>
        <b>{email}</b>
      </Typography>
    </ConfirmDialog>
  );
};

export default ChangeEmailSuccessDialog;
