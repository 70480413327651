import React from 'react';
import { BookingOffer } from '../../../../domain/model/booking';
import { Nullable } from '../../../../domain/model/types';
import { PanelAction } from '../../../types';
import { BookingLifeCycle, BookingLifeCycleRules } from '../lifecycle/types';
import { BookingActionDetailsType, BookingActions, BookingOfferDetailsStep, EBookingActionType } from '../types';
import { BookingOfferDetailsContainerProps } from './container';
import { BookingOfferDetailsState } from './store/slice';

export type BookingOfferDetailsParamsContextValue = BookingOfferDetailsContainerProps;

export const BookingOfferDetailsParamsContext = React.createContext<BookingOfferDetailsParamsContextValue>(
  {} as BookingOfferDetailsParamsContextValue
);

export type BookingOfferDetailsLifecycleContextValue = {
  readonly lifecycle: BookingLifeCycle;
  readonly lifeCycleRules: BookingLifeCycleRules;
  readonly actions: BookingActions<BookingActionDetailsType>;
  readonly onAction: (action: PanelAction<BookingActionDetailsType>) => void;
};

export const BookingOfferDetailsLifecycleContext = React.createContext<BookingOfferDetailsLifecycleContextValue>(
  {} as BookingOfferDetailsLifecycleContextValue
);

export type BookingOfferDetailsHandlersContextValue = {
  readonly onRefresh: () => void;
  readonly onChangeDialogState: (
    name: keyof BookingOfferDetailsState['dialogs'],
    bookingOffer: Nullable<BookingOffer>
  ) => void;

  readonly onChangeStep: (step: BookingOfferDetailsStep) => void;
  readonly onBookingOfferOpenEdit: (bookingOffer: BookingOffer) => void;
  readonly onBookingOfferSwitchToEdit: (bookingOffer: BookingOffer) => void;
  readonly onBookingOfferClose: (action: Nullable<EBookingActionType>) => void;
  readonly onBookingOfferRefetch: (bookingOffer: BookingOffer) => Promise<BookingOffer>;

  readonly onBookingOfferTryPublish: () => void;
  readonly onBookingOfferTryArchive: () => void;
  readonly onBookingOfferTryDuplicate: () => void;
  readonly onBookingOfferTryReturnToVerification: () => void;
  readonly onBookingOfferTryReject: () => void;
  readonly onBookingOfferTryPause: () => void;
  readonly onBookingOfferTryResume: () => void;
  readonly onBookingOfferTryInWork: () => void;
  readonly onBookingOfferTryUnPublish: () => void;
  readonly onBookingOfferTryChangeModerator: () => void;
  readonly onBookingOfferTryApprove: () => void;
  readonly onBookingOfferTryRetrieve: () => void;
  readonly onBookingOfferTryServiceManage: () => void;
};

export const BookingOfferDetailsHandlersContext = React.createContext<BookingOfferDetailsHandlersContextValue>(
  {} as BookingOfferDetailsHandlersContextValue
);
