import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { ServerErrorResponse } from '../../../../../data/network/types';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../data/store/types';
import { ProductDesk, ProductOffer, ProductOfferView } from '../../../../../domain/model/productOffer';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { setField } from '../../../../utils/modifier';
import offerServices from '../../../general/offer/services';
import productServices from '../../services';

const productOfferDetailsViewed = createAsyncThunk<void, UUID, AppThunkAPIConfig>(
  'product/details/viewed',
  async id => {
    try {
      const response = await offerServices.common.makeViewed({ id });
      return response;
    } catch (e: any) {
      console.error(`Error at call user event`, e);
    }
  }
);

export const productOfferDetailsByIdFetch = createAsyncThunk<
  ProductOffer,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('product/details/fetch', async (prop, { rejectWithValue, dispatch }) => {
  try {
    const data = productServices.productOffer.byId(prop);
    dispatch(productOfferDetailsViewed(prop.id));
    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productOfferDetailsAssignModerator = createAsyncThunk<
  ProductOffer,
  { id: UUID; userId: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('product/details/assignModerator', async (prop, { rejectWithValue }) => {
  try {
    const response = await productServices.productOffer.assignModerator(prop);
    return response;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);

    return rejectWithValue(e.response.data);
  }
});

export const productOfferDetailsApprove = createAsyncThunk<ProductOffer, { id: UUID }, AppThunkAPIConfig>(
  'product/details/approve',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productOffer.approve(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productOfferDetailsDuplicate = createAsyncThunk<ProductOffer, { id: UUID }, AppThunkAPIConfig>(
  'product/details/duplicate',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productOffer.duplicate(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productOfferDetailsReject = createAsyncThunk<
  ProductOffer,
  { id: UUID; comment: string; reasonId: UUID },
  AppThunkAPIConfig
>('product/details/reject', async (prop, { rejectWithValue }) => {
  try {
    const response = await productServices.productOffer.reject(prop);
    return response;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productOfferDetailsArchive = createAsyncThunk<ProductOffer, { id: UUID }, AppThunkAPIConfig>(
  'product/details/archive',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productOffer.archive(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productOfferDetailsToModeration = createAsyncThunk<ProductOffer, { id: UUID }, AppThunkAPIConfig>(
  'product/details/toModeration',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productOffer.toModeration(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export const productOfferDetailsResume = createAsyncThunk<ProductOffer, { id: UUID }, AppThunkAPIConfig>(
  'product/details/resume',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productOffer.resume(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export const productOfferDetailsResumeDesk = createAsyncThunk<ProductDesk, { id: UUID }, AppThunkAPIConfig>(
  'product/details/resumeDesk',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productDesk.resume(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productOfferDetailsPause = createAsyncThunk<
  ProductOffer,
  { id: UUID; comment: Nullable<string> },
  AppThunkAPIConfig
>('product/details/pause', async (prop, { rejectWithValue }) => {
  try {
    const response = await productServices.productOffer.pause(prop);
    return response;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productOfferDetailsUnPublish = createAsyncThunk<ProductOffer, { id: UUID }, AppThunkAPIConfig>(
  'product/details/unPublish',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productOffer.unPublish(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productOfferDetailsUnPublishDesk = createAsyncThunk<ProductDesk, { id: UUID }, AppThunkAPIConfig>(
  'product/details/unPublishDesk',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productDesk.unPublish(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productOfferDetailsArchiveDesk = createAsyncThunk<ProductDesk, { id: UUID }, AppThunkAPIConfig>(
  'product/details/archiveDesk',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productDesk.archive(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const productOfferDetailsPauseDesk = createAsyncThunk<
  ProductDesk,
  { id: UUID; comment: Nullable<string> },
  AppThunkAPIConfig
>('product/details/pauseDesk', async (prop, { rejectWithValue }) => {
  try {
    const response = await productServices.productDesk.pause(prop);
    return response;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productOfferDetailsChangePrice = createAsyncThunk<
  ProductOffer,
  { id: UUID; price: number; originalPrice: ProductOffer['originalPrice'] },
  AppThunkAPIConfig
>('product/details/changePrice', async (prop, { rejectWithValue }) => {
  try {
    const response = await productServices.productOffer.changePrice(prop);
    return response;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productOfferDetailsChangeStock = createAsyncThunk<
  ProductOffer,
  { id: UUID; stock: number },
  AppThunkAPIConfig
>('product/details/changeStock', async (prop, { rejectWithValue }) => {
  try {
    const response = await productServices.productOffer.changeStock(prop);
    return response;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productOfferDetailsDelete = createAsyncThunk<boolean, { id: UUID }, AppThunkAPIConfig>(
  'product/details/delete',
  async (prop, { rejectWithValue }) => {
    try {
      const response = await productServices.productOffer.delete(prop);
      return response;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface ProductOfferDetailsState {
  readonly guid: Nullable<UUID>;
  readonly byId: Fetchable & {
    readonly data: Nullable<ProductOfferView>;
  };
  readonly approve: Fetchable;
  readonly resume: Fetchable;
  readonly assignModerator: Fetchable;
  readonly duplicate: Fetchable;
  readonly archive: Fetchable;
  readonly archiveDesk: Fetchable;
  readonly unPublish: Fetchable;
  readonly toModeration: Fetchable;
  readonly reject: Fetchable;
  readonly pause: Fetchable;
  readonly changePrice: Fetchable;
  readonly changeStock: Fetchable;
  readonly delete: Fetchable;
  readonly dialogs: {
    readonly changeModerator: boolean;
    readonly changeStock: boolean;
    readonly changePrice: boolean;
    readonly history: boolean;
    readonly feedback: boolean;
    readonly rejectProduct: boolean;
    readonly pause: boolean;
    readonly pauseDesk: boolean;
    readonly archive: boolean;
    readonly archiveDesk: boolean;
    readonly approved: boolean;
  };
}

type Reducer<T = undefined> = CaseReducer<ProductOfferDetailsState, PayloadAction<T>>;

type FieldProductCreateDialogsState = {
  name: keyof ProductOfferDetailsState['dialogs'];
  value: boolean;
};

interface Reducers extends SliceCaseReducers<ProductOfferDetailsState> {
  productOfferDetailsReset: Reducer;
  productOfferDetailsDialogs: Reducer<FieldProductCreateDialogsState>;
}

const slice = createSlice<ProductOfferDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    guid: null,
    byId: {
      ...fetchableDefault,
      data: null,
    },
    approve: {
      ...fetchableDefault,
    },
    resume: {
      ...fetchableDefault,
    },
    assignModerator: {
      ...fetchableDefault,
    },
    duplicate: {
      ...fetchableDefault,
    },
    archive: {
      ...fetchableDefault,
    },
    archiveDesk: {
      ...fetchableDefault,
    },
    reject: {
      ...fetchableDefault,
    },
    unPublish: {
      ...fetchableDefault,
    },
    toModeration: {
      ...fetchableDefault,
    },
    pause: {
      ...fetchableDefault,
    },
    changePrice: {
      ...fetchableDefault,
    },
    changeStock: {
      ...fetchableDefault,
    },
    delete: {
      ...fetchableDefault,
    },
    dialogs: {
      changeModerator: false,
      changeStock: false,
      changePrice: false,
      history: false,
      feedback: false,
      rejectProduct: false,
      pause: false,
      pauseDesk: false,
      archive: false,
      archiveDesk: false,
      approved: false,
    },
  },
  reducers: {
    productOfferDetailsReset: state => {
      state.byId.isFetching = false;
      state.byId.isFetched = false;
      state.byId.isFailed = false;
      state.byId.data = null;
    },
    productOfferDetailsDialogs: (state, { payload }) => {
      const { name, value } = payload;
      setField(state.dialogs, name, value);
    },
  },
  extraReducers: builder => {
    builder.addCase(productOfferDetailsByIdFetch.pending, state => {
      state.byId.isFetching = true;
      state.byId.isFetched = false;
      state.byId.isFailed = false;
    });
    builder.addCase(productOfferDetailsByIdFetch.fulfilled, (state, { payload }) => {
      state.byId.isFetched = true;
      state.byId.isFetching = false;
      state.byId.isFailed = false;

      state.byId.data = payload;
    });
    builder.addCase(productOfferDetailsByIdFetch.rejected, state => {
      state.byId.isFailed = true;
      state.byId.isFetching = false;
      state.byId.isFetched = false;
    });

    builder.addCase(productOfferDetailsAssignModerator.pending, state => {
      state.assignModerator = fetchableFetching;
    });
    builder.addCase(productOfferDetailsAssignModerator.fulfilled, (state, { payload }) => {
      state.assignModerator = fetchableFetched;
      state.byId.data = payload;
    });
    builder.addCase(productOfferDetailsAssignModerator.rejected, state => {
      state.assignModerator = fetchableFailed;
    });

    builder.addCase(productOfferDetailsApprove.pending, state => {
      state.approve = fetchableFetching;
    });
    builder.addCase(productOfferDetailsApprove.fulfilled, (state, { payload }) => {
      state.approve = fetchableFetched;
      state.byId.data = payload;
    });
    builder.addCase(productOfferDetailsApprove.rejected, state => {
      state.approve = fetchableFailed;
    });

    builder.addCase(productOfferDetailsReject.pending, state => {
      state.reject = fetchableFetching;
    });
    builder.addCase(productOfferDetailsReject.fulfilled, (state, { payload }) => {
      state.reject = fetchableFetched;
      state.byId.data = payload;
    });
    builder.addCase(productOfferDetailsReject.rejected, state => {
      state.duplicate = fetchableFailed;
    });

    builder.addCase(productOfferDetailsDuplicate.pending, state => {
      state.duplicate = fetchableFetching;
    });
    builder.addCase(productOfferDetailsDuplicate.fulfilled, state => {
      state.duplicate = fetchableFetched;
    });
    builder.addCase(productOfferDetailsDuplicate.rejected, state => {
      state.duplicate = fetchableFailed;
    });

    builder.addCase(productOfferDetailsArchive.pending, state => {
      state.archive = fetchableFetching;
    });
    builder.addCase(productOfferDetailsArchive.fulfilled, (state, { payload }) => {
      state.archive = fetchableFetched;
      state.byId.data = payload;
    });
    builder.addCase(productOfferDetailsArchive.rejected, state => {
      state.archive = fetchableFailed;
    });

    builder.addCase(productOfferDetailsArchiveDesk.pending, state => {
      state.archiveDesk = fetchableFetching;
    });
    builder.addCase(productOfferDetailsArchiveDesk.fulfilled, state => {
      state.archiveDesk = fetchableFetched;
    });
    builder.addCase(productOfferDetailsArchiveDesk.rejected, state => {
      state.archiveDesk = fetchableFailed;
    });

    builder.addCase(productOfferDetailsUnPublishDesk.pending, state => {
      state.unPublish = fetchableFetching;
    });
    builder.addCase(productOfferDetailsUnPublishDesk.fulfilled, state => {
      state.unPublish = fetchableFetched;
    });
    builder.addCase(productOfferDetailsUnPublishDesk.rejected, state => {
      state.unPublish = fetchableFailed;
    });

    builder.addCase(productOfferDetailsUnPublish.pending, state => {
      state.unPublish = fetchableFetching;
    });
    builder.addCase(productOfferDetailsUnPublish.fulfilled, (state, { payload }) => {
      state.unPublish = fetchableFetched;
      state.byId.data = payload;
    });
    builder.addCase(productOfferDetailsUnPublish.rejected, state => {
      state.unPublish = fetchableFailed;
    });

    builder.addCase(productOfferDetailsToModeration.pending, state => {
      state.toModeration = fetchableFetching;
    });
    builder.addCase(productOfferDetailsToModeration.fulfilled, (state, { payload }) => {
      state.toModeration = fetchableFetched;
      state.byId.data = payload;
    });
    builder.addCase(productOfferDetailsToModeration.rejected, state => {
      state.toModeration = fetchableFailed;
    });

    builder.addCase(productOfferDetailsResume.pending, state => {
      state.resume = fetchableFetching;
    });
    builder.addCase(productOfferDetailsResume.fulfilled, (state, { payload }) => {
      state.resume = fetchableFetched;
      state.byId.data = payload;
    });
    builder.addCase(productOfferDetailsResume.rejected, state => {
      state.resume = fetchableFailed;
    });

    builder.addCase(productOfferDetailsResumeDesk.pending, state => {
      state.resume = fetchableFetching;
    });
    builder.addCase(productOfferDetailsResumeDesk.fulfilled, state => {
      state.resume = fetchableFetched;
    });
    builder.addCase(productOfferDetailsResumeDesk.rejected, state => {
      state.resume = fetchableFailed;
    });

    builder.addCase(productOfferDetailsPause.pending, state => {
      state.pause = fetchableFetching;
    });
    builder.addCase(productOfferDetailsPause.fulfilled, (state, { payload }) => {
      state.pause = fetchableFetched;
      state.byId.data = payload;
    });
    builder.addCase(productOfferDetailsPause.rejected, state => {
      state.pause = fetchableFailed;
    });

    builder.addCase(productOfferDetailsPauseDesk.pending, state => {
      state.pause = fetchableFetching;
    });
    builder.addCase(productOfferDetailsPauseDesk.fulfilled, state => {
      state.pause = fetchableFetched;
    });
    builder.addCase(productOfferDetailsPauseDesk.rejected, state => {
      state.pause = fetchableFailed;
    });

    builder.addCase(productOfferDetailsChangePrice.pending, state => {
      state.changePrice = fetchableFetching;
    });
    builder.addCase(productOfferDetailsChangePrice.fulfilled, (state, { payload }) => {
      state.changePrice = fetchableFetched;
      state.byId.data = payload;
    });
    builder.addCase(productOfferDetailsChangePrice.rejected, state => {
      state.changePrice = fetchableFailed;
    });

    builder.addCase(productOfferDetailsChangeStock.pending, state => {
      state.changeStock = fetchableFetching;
    });
    builder.addCase(productOfferDetailsChangeStock.fulfilled, (state, { payload }) => {
      state.changeStock = fetchableFetched;
      state.byId.data = payload;
    });
    builder.addCase(productOfferDetailsChangeStock.rejected, state => {
      state.changeStock = fetchableFailed;
    });

    builder.addCase(productOfferDetailsDelete.pending, state => {
      state.delete = fetchableFetching;
    });
    builder.addCase(productOfferDetailsDelete.fulfilled, state => {
      state.delete = fetchableFetched;
    });
    builder.addCase(productOfferDetailsDelete.rejected, state => {
      state.delete = fetchableFailed;
    });
  },
});

export const { productOfferDetailsReset, productOfferDetailsDialogs } = slice.actions;

export default slice.reducer;
