import { Grid, Link, Typography } from '@mui/material';
import { Wrapper } from './controls';

interface SupportContactsProps {
  readonly emails: string[];
  readonly phones: string[];
}

const SupportContacts = ({ emails, phones }: SupportContactsProps) => {
  return (
    <Grid
      container
      spacing={3}
      direction='column'
    >
      {emails.length > 0 && (
        <Grid item>
          <Typography
            variant='body2'
            color='textSecondary'
          >
            Электронная почта
          </Typography>
          <Wrapper>
            {emails.map(item => (
              <Typography
                key={item}
                component={Link}
                href={`mailto:${item}`}
                color='textPrimary'
              >
                {item}
              </Typography>
            ))}
          </Wrapper>
        </Grid>
      )}
      {phones.length > 0 && (
        <Grid item>
          <Typography
            variant='body2'
            color='textSecondary'
          >
            Телефон
          </Typography>
          <Wrapper>
            {phones.map(item => (
              <Typography
                key={item}
                component={Link}
                href={`tel:${item}`}
                color='textPrimary'
              >
                {item}
              </Typography>
            ))}
          </Wrapper>
        </Grid>
      )}
    </Grid>
  );
};

export default SupportContacts;
