import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import TablePagination from '../../../../components/common/table/pagination';
import { useContextHandlers } from './hooks/useContextHandlers';
import {
  adminMpsPageCountSelector,
  adminMpsPageNumberSelector,
  adminMpsSearchSelector,
  adminMpsTotalCountSelector,
} from './store/selectors';

interface AdminMpTableFooterContainerProps {}

const AdminMpTableFooterContainer = forwardRef((props: AdminMpTableFooterContainerProps, ref: any) => {
  const handlers = useContextHandlers();

  const pageNumber = useSelector(adminMpsPageNumberSelector);
  const search = useSelector(adminMpsSearchSelector);
  const totalCount = useSelector(adminMpsTotalCountSelector);
  const pageCount = useSelector(adminMpsPageCountSelector);

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='сотрудников'
        onChangePageSize={handlers.onChangePageSize}
        onChangePage={handlers.onChangePage}
      />
    </div>
  );
});

export default AdminMpTableFooterContainer;
