import { combineReducers } from '@reduxjs/toolkit';
import main, { MainViewStore } from './container/store/slice';
import menus, { MainMenusState } from './menu/store/slice';

interface MainContainerState {
  main: MainViewStore;
  menus: MainMenusState;
}

export default combineReducers<MainContainerState>({
  main,
  menus,
});
