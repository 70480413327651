import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { CmsContainer } from '../../../../../../domain/model/cms';
import { UUID } from '../../../../../../domain/model/types';
import { CmsContainerLifeCycle } from '../../lifecycle/types';
import {
  ActionCmsContainerType,
  CmsContainerActionCall,
  CmsContainerActions,
  CmsContainerActionTableType,
  ECmsContainerActionType,
} from '../../types';
import CmsContainerTable from '../component';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import useCmsContainerTable from '../hooks/useTableSettings';
import { cmsContainersActionsSelector, cmsContainersCmsContainersSelector } from '../store/selectors';
import { CmsContainerActionTableCommonType, CmsContainerTableActionProcess, ECmsContainerTableTab } from '../utils';

interface CmsContainersTableAdapterProps {
  readonly tab: ECmsContainerTableTab;
  readonly sitePageId: UUID;
  readonly lifecycle: CmsContainerLifeCycle;
  readonly tabActions: CmsContainerActions<CmsContainerActionTableType>;
}

const CmsContainersTableAdapter = (props: CmsContainersTableAdapterProps) => {
  const { tab, sitePageId, lifecycle, tabActions } = props;

  const handlers = useContextHandlers();
  const { onCmsContainerClick, onCmsContainerOpenEdit } = useContextConfig();

  const cmsContainers = useSelector(cmsContainersCmsContainersSelector);
  const actionProcesses = useSelector(cmsContainersActionsSelector);

  const onActionClick = useCallback(
    (call: CmsContainerActionCall) => {
      const { cmsContainer } = call;
      switch (call.action) {
        case ECmsContainerActionType.Edit:
          onCmsContainerOpenEdit(cmsContainer);
          break;
        case ECmsContainerActionType.Pause:
          handlers.onCmsContainerPause(cmsContainer);
          break;
        case ECmsContainerActionType.Resume:
          handlers.onCmsContainerResume(cmsContainer);
          break;
        case ECmsContainerActionType.Archive:
          handlers.onCmsContainerArchive(cmsContainer);
          break;
        case ECmsContainerActionType.Delete:
          handlers.onCmsContainerDelete(cmsContainer);
          break;
        case ECmsContainerActionType.ChangeSortIndex:
          handlers.onCmsContainerChangeSortIndex(cmsContainer, call.sortIndex);
          break;
        case ECmsContainerActionType.Duplicate:
          handlers
            .onCmsContainerDuplicate(sitePageId, cmsContainer)
            .then(newContainer => onCmsContainerOpenEdit(newContainer));
          break;
      }
    },
    [sitePageId, onCmsContainerOpenEdit]
  );

  const getCmsContainerActions = useCallback(
    (cmsContainer: ActionCmsContainerType) => {
      return lifecycle.build({
        cmsContainer,
        allowedActions: tabActions,
      }).nextActions as CmsContainerActions<CmsContainerActionTableType>;
    },
    [lifecycle, tabActions]
  );

  const getCmsContainerCommonActions = useCallback(
    (cmsContainer: ActionCmsContainerType) => {
      return getCmsContainerActions(cmsContainer).filter(
        action => action.type !== ECmsContainerActionType.ChangeSortIndex
      ) as CmsContainerActions<CmsContainerActionTableCommonType>;
    },
    [getCmsContainerActions]
  );

  const getActionProcesses = useCallback(
    (cmsContainer: CmsContainer): CmsContainerTableActionProcess[] => {
      return actionProcesses.filter(p => p.id === cmsContainer.id) ?? [];
    },
    [actionProcesses]
  );

  const { metadata, onChangeMetadata } = useCmsContainerTable({
    tab,
  });

  return (
    <CmsContainerTable
      metadata={metadata}
      cmsContainers={cmsContainers}
      getActions={getCmsContainerActions}
      getCommonActions={getCmsContainerCommonActions}
      getActionProcesses={getActionProcesses}
      onChangeMetadata={onChangeMetadata}
      onClick={onCmsContainerClick}
      onAction={onActionClick}
    />
  );
};

export default CmsContainersTableAdapter;
