import { AnalyticsInitializer } from '@mp-npm/mp-analytics-client';
import { useMemo, useRef } from 'react';
import { useHistory } from 'react-router';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import { useWebAnalytics } from '../hooks/useWebAnalytics';

export const WebAnalyticsInitializer = () => {
  const { webAnalytics } = useWebAnalytics();
  const history = useHistory();
  const { accessMatrix } = useCurrentUser();

  const adapters = useRef(webAnalytics.getAdapters(history, accessMatrix));

  return useMemo(() => <AnalyticsInitializer adapters={adapters.current} />, []);
};
