import { Grid } from '@mui/material';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';
import { MPButton } from '../../../../theme/ui-kit/button';
import { pluralize } from '../../../../utils/pluralize';

interface CorpOffersApproveDialogProps {
  readonly corpOffersCount: number;
  readonly onExecute: () => void;
  readonly onClose: () => void;
}

const CorpOffersApproveDialog = (props: CorpOffersApproveDialogProps) => {
  const { corpOffersCount, onExecute, onClose } = props;

  const description = `Будет опубликовано ${corpOffersCount}
  ${pluralize(corpOffersCount, [
    'корпоративное предложение',
    'корпоративных предложения',
    'корпоративных предложений',
  ])}`;

  return (
    <ConfirmDialog
      open
      onClose={onClose}
      title='Публикация корпоративных предложений'
      text={description}
      dialogButtons={
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              onClick={() => {
                onExecute();
                onClose();
              }}
            >
              Опубликовать
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              fullWidth={false}
              onClick={onClose}
            >
              Отмена
            </MPButton>
          </Grid>
        </Grid>
      }
    />
  );
};

export default CorpOffersApproveDialog;
