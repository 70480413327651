import { Fetchable, fetchableDefault } from '@/data/store/types';
import { MPReport, Nullable, UUID } from '@/domain';
import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export interface ReportListState extends Fetchable {
  readonly guid: Nullable<UUID>;
  readonly data: MPReport[];
}

type Reducer<T = undefined> = CaseReducer<ReportListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ReportListState> {
  reportsDataReset: Reducer;
  reportsStartSession: Reducer<{ guid: UUID; reports: MPReport[] }>;
}

const initialState: ReportListState = {
  guid: null,
  data: [],
  ...fetchableDefault,
};

const slice = createSlice<ReportListState, Reducers>({
  name: 'list',
  initialState,
  reducers: {
    reportsDataReset: () => {
      return initialState;
    },
    reportsStartSession: (state, { payload }) => {
      const { guid, reports } = payload;

      if (guid !== state.guid) {
        state.guid = guid;

        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = false;

        state.data = reports;
      }
    },
  },
  extraReducers: {},
});

export const { reportsDataReset, reportsStartSession } = slice.actions;
export default slice.reducer;
