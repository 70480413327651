import { Nullable, UUID } from '@/domain';
import { useSelector } from 'react-redux';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import TableCommonLayout from '../../../layouts/tableCommon/container';
import TableLoaderCommonLayout from '../../../layouts/tableLoaderCommon/container';
import { TradeOfferLifeCycle } from '../../general/offer/lifecycle/types';
import { EOfferTableTab } from '../../general/offer/types';
import { getOfferTableTabActions } from '../../general/offer/utils/table';
import { ETradeOfferTableFilterItem } from '../filterUtils';
import { ETradeOfferTableColumn, TradeOfferTableStubAdapterType } from '../types';
import TradeOffersTableFilterAdapter from './adapters/filter';
import TradeOffersTableHeaderAdapter from './adapters/header';
import { TradeOffersTableNotifierAdapter } from './adapters/notifier';
import TradeOffersTableAdapter from './adapters/table';
import { TradeOffersTableTabsAdapter } from './adapters/tabs';
import { TradeOffersTableContext } from './context';
import TradeOfferTableFooterContainer from './footerContainer';
import useTradeOffersTableCount from './hooks/useCount';
import useTradeOffersTableSearch from './hooks/useSearch';
import useTradeOffersTableSession from './hooks/useSession';
import { useTradeOffersTableHandlers } from './hooks/useTradeOffersTable';
import { tradeOffersGuidSelector, tradeOffersIsPreloadingSelector } from './store/selectors';

interface TradeOfferTableContainerProps {
  readonly guid: UUID;
  readonly partnerId: Nullable<UUID>;
  readonly tab: EOfferTableTab;
  readonly lifecycle: TradeOfferLifeCycle;
  readonly filterAdapter: DataFilterAdapter<ETradeOfferTableColumn, ETradeOfferTableFilterItem>;
  readonly stubAdapter: TradeOfferTableStubAdapterType;
  readonly canCreate?: boolean;
  readonly canPause?: boolean;
  readonly canResume?: boolean;
  readonly canArchive?: boolean;
  readonly canApprove?: boolean;
  readonly canReject?: boolean;
  readonly canDelete?: boolean;
  readonly canModerate?: boolean;
  readonly canDownload?: boolean;
  readonly canUpload?: boolean;
  readonly canDuplicate?: boolean;
  readonly canRetrieve?: boolean;
  readonly canChangeModerator?: boolean;
  readonly canReturnToVerification?: boolean;
}

const TradeOfferTableContainer = (props: TradeOfferTableContainerProps) => {
  const {
    guid,
    partnerId,
    tab,
    lifecycle,
    filterAdapter,
    stubAdapter: StubAdapter,
    canCreate,
    canResume,
    canPause,
    canArchive,
    canApprove,
    canReject,
    canDownload,
    canUpload,
    canDelete,
    canModerate,
    canDuplicate,
    canRetrieve,
    canChangeModerator,
    canReturnToVerification,
  } = props;
  const handlers = useTradeOffersTableHandlers({ tab, lifecycle, canModerate });

  const currentGuid = useSelector(tradeOffersGuidSelector);

  useTradeOffersTableSession({ guid, tab });
  useTradeOffersTableSearch({ tab, partnerId });
  useTradeOffersTableCount({ partnerId });

  const tableTabActions = getOfferTableTabActions(
    {
      canDelete,
      canApprove,
      canReject,
      canResume,
      canPause,
      canArchive,
      canDuplicate,
      canRetrieve,
      canChangeModerator,
      canReturnToVerification,
    },
    tab
  );

  if (guid !== currentGuid) {
    return null;
  }

  return (
    <TradeOffersTableContext.Provider value={handlers}>
      <TableLoaderCommonLayout selector={tradeOffersIsPreloadingSelector}>
        <StubAdapter>
          {({ canViewTable, isCreateDisabled, createInfo, stub }) => (
            <TableCommonLayout
              canViewTable={canViewTable}
              header={
                <TradeOffersTableHeaderAdapter
                  tab={tab}
                  partnerId={partnerId}
                  createInfo={createInfo}
                  isCreateDisabled={isCreateDisabled}
                  canCreate={canCreate}
                  canDownload={canDownload && canViewTable}
                  canUpload={canUpload && canViewTable}
                />
              }
              filter={<TradeOffersTableFilterAdapter filterAdapter={filterAdapter} />}
              tabs={<TradeOffersTableTabsAdapter tab={tab} />}
              table={
                <TradeOffersTableAdapter
                  lifecycle={lifecycle}
                  filterAdapter={filterAdapter}
                  tabActions={tableTabActions}
                />
              }
              stub={stub}
              footer={TradeOfferTableFooterContainer}
            >
              <TradeOffersTableNotifierAdapter />
            </TableCommonLayout>
          )}
        </StubAdapter>
      </TableLoaderCommonLayout>
    </TradeOffersTableContext.Provider>
  );
};

export default TradeOfferTableContainer;
