import imgServiceToggles from './imgs/service_manage_toggle.png';
import { ContentLimiterHintLayout } from '../../../../components/common/contentLimit/layouts';
import { ContentLimiter } from '../../../../components/common/contentLimit';

export const ServicesManageHint = () => {
  return (
    <ContentLimiter
      layout={ContentLimiterHintLayout}
      layoutProps={{
        collapsedTitle: 'Как управлять услугами',
        expandedTitle: <b>Как управлять услугами</b>,
      }}
    >
      <p>
        Управление услугами осуществляется посредством включения и выключения переключателя. Когда переключатель
        находится в зеленом положении, тогда услуга для клиента будет видна в составе услуг объекта. Когда переключатель
        находится в сером положении, услуга скрывается со страницы объекта.
      </p>
      <img
        src={imgServiceToggles}
        alt={''}
      />
    </ContentLimiter>
  );
};
