import { ClientOrg, ClientOrgCreate, ClientOrgCreateRequest, ImageRestrictions } from '@/domain';
import { fileSizeInBytes, fileTypes } from '@/presentation/constants';
import { pluralize } from '../../../utils/pluralize';
import { EValidationType, ValidationResult } from '../../../utils/validation';
import { ClientOrgCreateStepType, EClientOrgStep } from '../types';

export const clientOrgLogoRestrictions: ImageRestrictions = {
  recommendedWidthInPx: 200,
  recommendedHeightInPx: 48,
  maxFileSizeInBytes: fileSizeInBytes._1MB,
  whStrict: true,
  fileAccept: [fileTypes.jpg.ext, fileTypes.jpeg.ext, fileTypes.png.ext, fileTypes.svg.ext],
  fileType: [fileTypes.jpg.mimeType, fileTypes.jpeg.mimeType, fileTypes.png.mimeType, fileTypes.svg.mimeType],
};

export const clientOrgFaviconRestrictions: ImageRestrictions = {
  recommendedWidthInPx: 256,
  recommendedHeightInPx: 256,
  maxFileSizeInBytes: fileSizeInBytes._300kB,
  whStrict: true,
  fileAccept: [fileTypes.ico.ext],
  fileType: [fileTypes.ico.mimeType],
};

export const getClientOrgCreateStepText = (step: ClientOrgCreateStepType): string => {
  switch (step) {
    case EClientOrgStep.General:
      return 'Настройка Витрины';
  }
};

export const createEmptyClientOrgCreate = (): ClientOrgCreate => {
  return {
    id: null,
    name: null,
    status: null,
    code: null,
    readOnly: null,
    domain: null,
    logo: null,
    favicon: null,
    colorBrand: null,
    colorPrimary: null,
  };
};

export const convertClientOrgCreateToCreateRequest = (clientOrgCreate: ClientOrgCreate): ClientOrgCreateRequest => {
  return {
    ...clientOrgCreate,
    name: clientOrgCreate.name ?? '',
  };
};
export const convertClientOrgToClientOrgCreate = (clientOrg: ClientOrg): ClientOrgCreate => {
  return clientOrg;
};

export const convertClientOrgCreateToUpdateRequest = convertClientOrgCreateToCreateRequest;

const clientOrgGetValidationCommonError = (
  step: ClientOrgCreateStepType,
  count: number
): Nullable<ValidationResult<any>> => {
  if (!count) {
    return null;
  }

  return {
    common: {
      type: EValidationType.Error,
      hasError: true,
      message: `Не заполнено ${count} ${pluralize(count, ['поле', 'поля', 'полей'])}`,
    },
  };
};

export const clientOrgCreateInitValidationStepper = (validation: ValidationResult<ClientOrgCreate>) => {
  const generalErrorsCount = [
    validation.logo,
    validation.favicon,
    validation.name,
    validation.code,
    validation.colorBrand,
    validation.colorPrimary,
    validation.domain,
  ].filter(item => !!item).length;

  return {
    [EClientOrgStep.General]: clientOrgGetValidationCommonError(EClientOrgStep.General, generalErrorsCount),
  };
};

export const getClientOrgCreateSteps = (): ClientOrgCreateStepType[] => {
  return [EClientOrgStep.General];
};
