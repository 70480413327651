import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Nullable } from '../../../../../domain/model/types';
import { CommonUserData } from '../../../../../domain/model/user';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';
import Splitter from '../../../../components/common/splitter';
import { OnChangeObjectAttribute } from '../../../../types';
import { ValidationResult, ValidationRules } from '../../../../utils/validation';
import UserCommonEditProfile from '../editProfile';
import { Title } from './controls';

interface UserCreateDialogProps {
  readonly title?: string;
  readonly user: CommonUserData;
  readonly canChangeEmail?: boolean;
  readonly validation: Nullable<ValidationResult<CommonUserData>>;
  readonly validationRules?: ValidationRules<CommonUserData>;
  readonly isValid: boolean;
  readonly actions: React.ReactNode;
  readonly onChangeAttribute: OnChangeObjectAttribute<CommonUserData>;
  readonly onClose?: () => void;
}

const UserCreateDialog = (props: UserCreateDialogProps) => {
  const { user, validation, validationRules, canChangeEmail, title, actions, onChangeAttribute, onClose } = props;

  return (
    <ConfirmDialog
      open
      disableStandardClose
      title={title && <Title>{title}</Title>}
      dialogProps={{
        open: true,
        hideBackdrop: true,
        disableEscapeKeyDown: true,
        maxWidth: 'sm',
        PaperProps: {
          elevation: 0,
        },
      }}
      dialogButtons={actions}
      onClose={onClose}
    >
      <Grid
        container
        spacing={2}
        direction='column'
      >
        <Grid item>
          <Typography variant='h3'>Данные пользователя</Typography>
          <Splitter
            variant='horizontal'
            size={1}
          />
        </Grid>
        <Grid item>
          <UserCommonEditProfile
            user={user}
            titleHide
            emailReadonly={!canChangeEmail}
            validation={validation}
            validationRules={validationRules}
            onChangeAttribute={onChangeAttribute}
          />
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
};

export default UserCreateDialog;
