import { TradeOfferActivation } from '@/domain/model/activation';
import { ReactNode, useMemo } from 'react';
import DataTableCellTextFormat from '../../../../components/common/table/cell/textFormat';

export interface TradeActivationTableCellCommonProps {
  readonly tradeActivation: TradeOfferActivation;
  readonly isDisabled?: boolean;
  readonly isTextAccent?: boolean;
}

interface BookingOfferTableCellProps extends TradeActivationTableCellCommonProps {
  readonly value: ReactNode;
}

const TradeActivationTableCell = ({ value, isDisabled, isTextAccent }: BookingOfferTableCellProps) => {
  return useMemo(
    () => (
      <DataTableCellTextFormat
        disabled={isDisabled}
        bold={isTextAccent}
      >
        {value}
      </DataTableCellTextFormat>
    ),
    [isDisabled, isTextAccent, value]
  );
};

export default TradeActivationTableCell;
