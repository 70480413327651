import { AdCampaignCreate } from '@/domain';
import { AdCampaignCreateFieldCommonProps } from '@features/adCampaign/types';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  adCampaignCreateAdCampaignFieldByNameSelector,
  adCampaignCreateAdCampaignIsPresentSelector,
  adCampaignCreateIsFieldDisabledSelector,
  adCampaignCreateIsFieldVisibleSelector,
  adCampaignCreateValidationResultsByFieldSelector,
} from '../store/selectors';

interface AdCampaignCreateFieldAdapterProps<Key extends keyof AdCampaignCreate> {
  readonly name: Key;
  readonly component: FC<AdCampaignCreateFieldCommonProps<Key>>;
}

export const AdCampaignCreateFieldAdapter = <Key extends keyof AdCampaignCreate>(
  props: AdCampaignCreateFieldAdapterProps<Key>
) => {
  const { name, component: Component } = props;

  const handlers = useContextHandlers();

  const adCampaignIsPresent = useSelector(adCampaignCreateAdCampaignIsPresentSelector);
  const value = useSelector(adCampaignCreateAdCampaignFieldByNameSelector(name));
  const visible = useSelector(adCampaignCreateIsFieldVisibleSelector(name));
  const disabled = useSelector(adCampaignCreateIsFieldDisabledSelector(name));
  const validation = useSelector(adCampaignCreateValidationResultsByFieldSelector(name));

  return adCampaignIsPresent && visible ? (
    <Component
      value={value}
      disabled={disabled}
      validation={validation}
      onChangeAttribute={handlers.onChangeAttribute}
      onValidateAttribute={handlers.onAttributeValidate}
    />
  ) : null;
};
