import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import CountInput from '../../../../components/common/countInput';
import BookingOrderEditItemInfoActions from './actions';

interface BookingOrderEditItemInfoByNoneProps {
  readonly value: number;
  readonly onApply: (value: number) => void;
  readonly onCancel: () => void;
}

const BookingOrderEditItemInfoByNone = (props: BookingOrderEditItemInfoByNoneProps) => {
  const { value, onApply, onCancel } = props;

  const [localValue, setLocalValue] = useState<number>(value);

  const onInternalApply = () => {
    onApply(localValue);
  };

  const onClear = () => {
    setLocalValue(0);
  };

  const onChangeLocalValue = (newLocalValue: number) => {
    setLocalValue(newLocalValue);
  };

  const isDisabled = !localValue;

  return (
    <Grid
      container
      direction='column'
      spacing={3}
    >
      <Grid item>
        <Typography
          gutterBottom
          variant='body2'
          color='textSecondary'
        >
          Количество
        </Typography>
        <CountInput
          min={1}
          value={localValue}
          onChangeCount={count => onChangeLocalValue(count)}
          onIncrement={() => onChangeLocalValue(localValue + 1)}
          onDecrement={() => onChangeLocalValue(localValue - 1)}
        />
      </Grid>
      <Grid item>
        <BookingOrderEditItemInfoActions
          isExecuting={false}
          disabled={isDisabled}
          onExecute={onInternalApply}
          onClear={onClear}
          onCancel={onCancel}
        />
      </Grid>
    </Grid>
  );
};

export default BookingOrderEditItemInfoByNone;
