import { FormControlLabel } from '@mui/material';
import styled from '@emotion/styled';

export const CheckboxLabel = styled(FormControlLabel)`
  margin-right: 0;

  .PrivateSwitchBase-root-5 {
    padding: 0;
    margin-right: ${p => p.theme.spacing()};
  }
`;
