import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nullable, UUID } from '@/domain';
import {
  adCampaignCountsFetch,
  adCampaignFetch,
  adCampaignNeedRefreshWatcherReset,
  AdCampaignsFetchProps,
} from '../store/slice';
import { getStatesFilterForAdCampaignTableTab } from '../utils';
import { useContextConfig } from './useContextConfig';
import {
  adCampaignFilterSelector,
  adCampaignNeedRefreshWatcherSelector,
  adCampaignPageNumberSelector,
  adCampaignSearchSelector,
} from '../store/selectors';
import { EAdCampaignTableTab } from '@features/adCampaign/types';

interface UseAdCampaignTableSearchProps {
  readonly tab: EAdCampaignTableTab;
  readonly partnerId: Nullable<UUID>;
}

const getStatusesByTab = (t: EAdCampaignTableTab) => getStatesFilterForAdCampaignTableTab(t, []);

const useAdCampaignTableSearch = (props: UseAdCampaignTableSearchProps): void => {
  const { tab, partnerId } = props;

  const dispatch = useDispatch();

  const { tabsForCounts } = useContextConfig();

  const [searchPromises, setSearchPromises] = useState<Nullable<any[]>>(null);

  const needRefreshWatcher = useSelector(adCampaignNeedRefreshWatcherSelector);
  const pageNumber = useSelector(adCampaignPageNumberSelector);
  const search = useSelector(adCampaignSearchSelector);
  const filter = useSelector(adCampaignFilterSelector);

  const currentStatuses = getStatusesByTab(tab);

  const queryParams = useMemo<AdCampaignsFetchProps>(() => {
    return {
      filter,
      search: {
        ...search,
        statuses: currentStatuses,
        partnerId,
        categories: null,
      },
      pageNumber,
    };
  }, [filter, search, currentStatuses, partnerId, pageNumber]);

  const onSearch = useCallback(() => {
    setSearchPromises([
      dispatch(adCampaignFetch(queryParams)),
      dispatch(adCampaignCountsFetch({ ...queryParams, tabs: tabsForCounts })),
    ]);
  }, [dispatch, queryParams]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  // сброс вотчера после ухода
  useEffect(() => {
    return () => {
      dispatch(adCampaignNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};

export default useAdCampaignTableSearch;
