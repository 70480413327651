import { EPartnerOwnershipType } from '../../../../../../domain/model/enums';
import {
  PartnerCompanyDataDraft,
  PartnerData,
  PartnerDataDraft,
  PartnerIndividualDataDraft,
} from '../../../../../../domain/model/partner';
import { Nullable } from '../../../../../../domain/model/types';
import { OnChangeObjectAttribute } from '../../../../../types';
import { ValidationResult } from '../../../../../utils/validation';
import { PartnerDocumentsCompany, PartnerDocumentsIndividual } from '../../../../general/partner/validation';
import { Content } from '../../controls';
import PartnerApplicationEditCompanyDocuments from './company';
import PartnerApplicationEditIndividualDocuments from './individual';

interface PartnerEditStepDocumentsProps {
  readonly partner: PartnerData;
  readonly isValid: boolean;
  readonly validationCompany: Nullable<ValidationResult<PartnerDocumentsCompany>>;
  readonly validationIndividual: Nullable<ValidationResult<PartnerDocumentsIndividual>>;
  readonly onChangeAttribute: OnChangeObjectAttribute<PartnerDataDraft>;
  readonly onChangeCompanyAttribute: OnChangeObjectAttribute<PartnerCompanyDataDraft>;
  readonly onChangeIndividualAttribute: OnChangeObjectAttribute<PartnerIndividualDataDraft>;
}

const PartnerEditStepDocuments = (props: PartnerEditStepDocumentsProps) => {
  const {
    partner,
    isValid,
    validationCompany,
    validationIndividual,
    onChangeAttribute,
    onChangeCompanyAttribute,
    onChangeIndividualAttribute,
  } = props;

  const { innCert, taxSystem, usnDoc } = partner;

  switch (partner.regInfo.orgType) {
    case EPartnerOwnershipType.IndividualEntrepreneur:
      return (
        <Content>
          <PartnerApplicationEditIndividualDocuments
            innCert={innCert}
            taxSystem={taxSystem}
            usnDoc={usnDoc}
            ogrnipCert={partner.regInfo.ogrnipCert}
            passportCopy={partner.regInfo.passportCopy}
            validation={validationIndividual}
            isValid={isValid}
            onChangeAttribute={onChangeAttribute}
            onChangeIndividualAttribute={onChangeIndividualAttribute}
          />
        </Content>
      );
    case EPartnerOwnershipType.StockCompany:
    case EPartnerOwnershipType.PublicJoinStockCompany:
    case EPartnerOwnershipType.PK:
    case EPartnerOwnershipType.UP:
    case EPartnerOwnershipType.FGBNU:
    case EPartnerOwnershipType.NonProfitOrganization:
    case EPartnerOwnershipType.ClosedJoinStockCompany:
    case EPartnerOwnershipType.JoinStockCompany:
    case EPartnerOwnershipType.NAO:
    case EPartnerOwnershipType.AutonomousNonProfitOrganization:
    case EPartnerOwnershipType.LimitedLiabilityCompany: {
      return (
        <Content>
          <PartnerApplicationEditCompanyDocuments
            innCert={innCert}
            taxSystem={taxSystem}
            usnDoc={usnDoc}
            ogrnCert={partner.regInfo.ogrnCert}
            charterDoc={partner.regInfo.charterDoc}
            ceoAppointmentProtocol={partner.regInfo.ceoAppointmentProtocol}
            validation={validationCompany}
            isValid={isValid}
            onChangeAttribute={onChangeAttribute}
            onChangeCompanyAttribute={onChangeCompanyAttribute}
          />
        </Content>
      );
    }
    default: {
      return <Content></Content>;
    }
  }
};

export default PartnerEditStepDocuments;
