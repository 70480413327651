import React from 'react';
import { BookingServicePriceUnit } from '../../../../../domain/model/booking';
import { Nullable } from '../../../../../domain/model/types';
import { BookingServicePriceUnitsEditState } from './store/slice';

export type BookingServicePriceUnitsEditHandlersContextValue = {
  readonly onChangeDialogState: <Name extends keyof BookingServicePriceUnitsEditState['dialogs']>(
    name: Name,
    data: Nullable<BookingServicePriceUnitsEditState['dialogs'][Name]>
  ) => void;
  readonly onCreate: (bookingServicePriceUnit: BookingServicePriceUnit) => Promise<BookingServicePriceUnit>;
  readonly onEdit: (bookingServicePriceUnit: BookingServicePriceUnit) => Promise<BookingServicePriceUnit>;
  readonly onDelete: (bookingServicePriceUnit: BookingServicePriceUnit) => Promise<void>;
  readonly onClose: () => void;
};

export const BookingServicePriceUnitsEditHandlersContext =
  React.createContext<BookingServicePriceUnitsEditHandlersContextValue>(
    {} as BookingServicePriceUnitsEditHandlersContextValue
  );
