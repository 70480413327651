import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TabsPanel from '../../../../components/common/tabs/panel';
import { ECorpActivationTableTab } from '../../types';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { corpActivationsTabsCounterSelector } from '../store/selectors';
import CorpActivationTableTab from '../tab';

interface CorpActivationTableTabsAdapterProps {
  readonly tab: ECorpActivationTableTab;
}

export const CorpActivationTableTabsAdapter = (props: CorpActivationTableTabsAdapterProps) => {
  const { tab } = props;

  const { onChangeTab } = useContextHandlers();

  const counter = useSelector(corpActivationsTabsCounterSelector);
  const { tabs } = useContextConfig();

  return useMemo(
    () => (
      <TabsPanel
        tab={tab}
        tabs={tabs}
        labelAdapter={CorpActivationTableTab}
        data={{
          counts: counter,
        }}
        onChangeTab={onChangeTab}
      />
    ),
    [tab, tabs, counter, onChangeTab]
  );
};
