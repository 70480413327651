import { UserAccessPartitionTradeActivations } from '@/domain/model/accessMatrix';
import { Nullable, UUID } from '@/domain/model/types';
import { DataFilterAdapter } from '@/presentation/hooks/useDataFilterAdapter';
import { ETradeActivationTableColumn } from '@features/tradeActivation/types';
import { ETradeActivationsFilterItem } from '@features/tradeActivation/utils/filter';
import TableCommonLayout from '../../../layouts/tableCommon/container';
import { TradeActivationTableBehaviorAdapter } from './adapters/behavior';
import TradeActivationTableFilterAdapter from './adapters/filters';
import TradeActivationTableHeaderAdapter from './adapters/header';
import TradeActivationTableTableAdapter from './adapters/table';
import { TradeActivationTableContext } from './context';
import TradeActivationTableFooterContainer from './footerContainer';
import { useTradeActivationTableHandlers } from './hooks/useActivationTable';

export type TradeActivationTableContainerProps = {
  readonly guid: UUID;
  readonly partnerId: Nullable<UUID>;
  readonly customerId: Nullable<UUID>;
  readonly filterAdapter: DataFilterAdapter<ETradeActivationTableColumn, ETradeActivationsFilterItem>;
  readonly access: UserAccessPartitionTradeActivations;
};

const TradeActivationTableContainer = (props: TradeActivationTableContainerProps) => {
  const { guid, partnerId, customerId, filterAdapter } = props;
  const handlers = useTradeActivationTableHandlers();

  return (
    <TradeActivationTableContext.Provider value={handlers}>
      <TradeActivationTableBehaviorAdapter
        guid={guid}
        partnerId={partnerId}
        customerId={customerId}
      />
      <TableCommonLayout
        header={
          <TradeActivationTableHeaderAdapter
            customerId={customerId}
            partnerId={partnerId}
          />
        }
        filter={<TradeActivationTableFilterAdapter filterAdapter={filterAdapter} />}
        table={<TradeActivationTableTableAdapter filterAdapter={filterAdapter} />}
        footer={TradeActivationTableFooterContainer}
      ></TableCommonLayout>
    </TradeActivationTableContext.Provider>
  );
};

export default TradeActivationTableContainer;
