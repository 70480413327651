import { createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../data/store/types';
import { PartnerDraft } from '../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../domain/model/types';

export const partnerApplicationDetailsFetch = createAsyncThunk<PartnerDraft, { partnerId: UUID }, AppThunkAPIConfig>(
  'partner/application/details/fetch',
  async ({ partnerId }, { rejectWithValue, signal }) => {
    try {
      const { data } = await Api.partner.oneDraft({ id: partnerId, signal });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface PartnerApplicationDetailsState {
  readonly data: Nullable<PartnerDraft>;
  readonly fetch: Fetchable;
}

interface Reducers extends SliceCaseReducers<PartnerApplicationDetailsState> {}

const slice = createSlice<PartnerApplicationDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    fetch: {
      ...fetchableDefault,
    },
    data: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(partnerApplicationDetailsFetch.pending, state => {
        state.fetch = fetchableFetching;
        state.data = null;
      })
      .addCase(partnerApplicationDetailsFetch.fulfilled, (state, { payload }) => {
        state.fetch = fetchableFetched;
        state.data = payload;
      })
      .addCase(partnerApplicationDetailsFetch.rejected, state => {
        state.fetch = fetchableFailed;
      });
  },
});

export default slice.reducer;
