import Api from '@/data/api';
import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterValueItem,
  EAdCampaignStatus,
  EDataFilterType,
  EDateFormat,
  EOfferType,
  Nullable,
  pageSizeAll,
  SportOptionTyped,
} from '@/domain';
import moment from 'moment/moment';
import { EAdCampaignTableColumn, EAdCampaignTableTab } from './types';

export enum EAdCampaignTableFilterItem {
  Query = 'query',
  Name = 'name',
  Partner = 'partner.id',
  OfferType = 'offerType',
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export const adCampaignTableFilterItems: Record<EAdCampaignTableColumn, EAdCampaignTableFilterItem[]> = {
  [EAdCampaignTableColumn.Name]: [EAdCampaignTableFilterItem.Name],
  [EAdCampaignTableColumn.Partner]: [EAdCampaignTableFilterItem.Partner],
  [EAdCampaignTableColumn.Period]: [EAdCampaignTableFilterItem.StartDate, EAdCampaignTableFilterItem.EndDate],
  [EAdCampaignTableColumn.Type]: [],
  [EAdCampaignTableColumn.OfferType]: [EAdCampaignTableFilterItem.OfferType],
  [EAdCampaignTableColumn.OfferCount]: [],
  [EAdCampaignTableColumn.Budget]: [],
  [EAdCampaignTableColumn.ShowCount]: [],
  [EAdCampaignTableColumn.ViewCount]: [],
};

export type AdCampaignTableFilterEditStrategy = DataFilterStrategyBase<EAdCampaignTableFilterItem>;

export type AdCampaignTableFilterValues = {
  readonly [EAdCampaignTableFilterItem.Query]?: DataFilterValueItem<Nullable<string>>;
  readonly [EAdCampaignTableFilterItem.Name]?: DataFilterValueItem<Nullable<string>>;
  readonly [EAdCampaignTableFilterItem.Partner]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
  readonly [EAdCampaignTableFilterItem.OfferType]?: DataFilterValueItem<Nullable<string[]>>;
  readonly [EAdCampaignTableFilterItem.StartDate]?: DataFilterValueItem<Nullable<string>>;
  readonly [EAdCampaignTableFilterItem.EndDate]?: DataFilterValueItem<Nullable<string>>;
};

export const getAdCampaignTableFilterStrategy = (
  filterItem: EAdCampaignTableFilterItem,
  values: AdCampaignTableFilterValues,
  offerTypes: SportOptionTyped<EOfferType>[]
): Nullable<AdCampaignTableFilterEditStrategy> => {
  try {
    switch (filterItem) {
      case EAdCampaignTableFilterItem.Query:
        return {
          type: EDataFilterType.String,
          key: EAdCampaignTableFilterItem.Query,
          label: 'Поиск по ключевому слову',
          preview: values[EAdCampaignTableFilterItem.Query]?.value || null,
          value: values[EAdCampaignTableFilterItem.Query]?.value || null,
        };
      case EAdCampaignTableFilterItem.Name:
        return {
          type: EDataFilterType.String,
          key: EAdCampaignTableFilterItem.Name,
          label: 'Наименование',
          preview: values[EAdCampaignTableFilterItem.Name]?.value || null,
          value: values[EAdCampaignTableFilterItem.Name]?.value || null,
          querydsl: {
            operator: DataFilterQueryDslOperator.Like,
          },
        };
      case EAdCampaignTableFilterItem.Partner:
        return {
          type: EDataFilterType.AutocompleteMultiple,
          key: EAdCampaignTableFilterItem.Partner,
          label: 'Партнёр',
          value: values[EAdCampaignTableFilterItem.Partner]?.value || null,
          preview: `Партнер (${values[EAdCampaignTableFilterItem.Partner]?.value?.length})` || null,
          previews: values[EAdCampaignTableFilterItem.Partner]?.value?.map(t => t.name) ?? [],
          source: (name: string) =>
            name
              ? Api.partner
                  .all({
                    query: name,
                    sort: 'name,asc',
                    page: 1,
                    pageSize: pageSizeAll,
                  })
                  .then(response => response.data)
              : Promise.resolve([]),
          querydsl: {
            operator: DataFilterQueryDslOperator.In,
            valueAttribute: 'id',
          },
        };
      case EAdCampaignTableFilterItem.OfferType:
        return {
          type: EDataFilterType.ListMultiple,
          key: EAdCampaignTableFilterItem.OfferType,
          label: 'Тип предложения',
          preview: `Тип предложения (${values[EAdCampaignTableFilterItem.OfferType]?.value?.length})`,
          previews: offerTypes
            .filter(t => values[EAdCampaignTableFilterItem.OfferType]?.value?.some(v => v === t.id))
            .map(t => t.name),
          value: values[EAdCampaignTableFilterItem.OfferType]?.value || null,
          items: offerTypes.map(t => ({ id: t.id, name: t.name })),
          querydsl: {
            operator: DataFilterQueryDslOperator.In,
          },
        };
      case EAdCampaignTableFilterItem.StartDate:
        return {
          type: EDataFilterType.DateFrom,
          key: EAdCampaignTableFilterItem.StartDate,
          group: 'date',
          label: 'Начало',
          preview: values[EAdCampaignTableFilterItem.StartDate]?.value
            ? `с ${moment(values[EAdCampaignTableFilterItem.StartDate]?.value).format(EDateFormat.DisplayShort)}`
            : null,
          value: values[EAdCampaignTableFilterItem.StartDate]?.value ?? null,
          querydsl: {
            operator: DataFilterQueryDslOperator.MoreOrEquals,
          },
        };
      case EAdCampaignTableFilterItem.EndDate:
        return {
          type: EDataFilterType.DateTo,
          key: EAdCampaignTableFilterItem.EndDate,
          group: 'date',
          label: 'Окончание',
          preview: values[EAdCampaignTableFilterItem.EndDate]?.value
            ? `по ${moment(values[EAdCampaignTableFilterItem.EndDate]?.value).format(EDateFormat.DisplayShort)}`
            : null,
          value: values[EAdCampaignTableFilterItem.EndDate]?.value ?? null,
          querydsl: {
            operator: DataFilterQueryDslOperator.LessOrEquals,
          },
        };
      default:
        return null;
    }
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getStatesFilterForAdCampaignTableTab = (
  tab: EAdCampaignTableTab,
  defaultStatuses: EAdCampaignStatus[]
): EAdCampaignStatus[] => {
  switch (tab) {
    case EAdCampaignTableTab.Active:
      return [EAdCampaignStatus.Active];

    case EAdCampaignTableTab.Archived:
      return [EAdCampaignStatus.Archived];

    case EAdCampaignTableTab.Upcoming:
      return [EAdCampaignStatus.Upcoming];

    case EAdCampaignTableTab.Paused:
      return [EAdCampaignStatus.Paused];

    default: {
      return defaultStatuses;
    }
  }
};
