import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getTradeOfferActionsOfferIsProcessingSelector, tradeOfferActionsGetActionSelector } from '../store/selectors';
import { useTradeOfferActions } from '../useActions';
import { TradeOffer } from '@/domain';
import { TradeOfferActionsConfirmDialog } from '@features/tradeOffer/actions/components';
import { TradeOfferStateKeyDialogs } from '../store/slice';

const nameDialog: TradeOfferStateKeyDialogs = 'unPublish';

const TradeOfferActionsDialogUnPublishAdapter = () => {
  const handlers = useTradeOfferActions();
  const { data, notification } = useSelector(tradeOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getTradeOfferActionsOfferIsProcessingSelector(data?.id ?? null));

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onPause = useCallback(
    (offer: TradeOffer) => {
      handlers.onUnPublish(offer.id, notification).then(() => {
        onCloseDialog();
      });
    },
    [handlers.onUnPublish, notification]
  );

  return (
    data && (
      <TradeOfferActionsConfirmDialog
        offer={data}
        title='Отозвать торговое предложение?'
        text={`Торговое предложение ${data.id} '${data.name}' будет отозвано и сохранено в черновиках.`}
        actionText='Отозвать'
        isFetching={isProcessing}
        onAction={onPause}
        onClose={onCloseDialog}
      />
    )
  );
};

export default TradeOfferActionsDialogUnPublishAdapter;
