import { ListItemAvatar as MuiListItemAvatar, ListItemButton as MuiListItemButton, ListItemText } from '@mui/material';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding-top: ${p => p.theme.spacing(3)};
`;

export const ButtonsGroupWrapper = styled.div`
  margin-bottom: ${p => p.theme.spacing(1)};
`;

export const ListItem = styled(MuiListItemButton)`
  &.MuiListItem-root {
    height: 5rem;
    padding: ${p => p.theme.spacing(1.5)} ${p => p.theme.spacing(6)} ${p => p.theme.spacing(1.5)}
      ${p => p.theme.spacing(1)};
  }

  & .MuiListItemAvatar-root {
    min-width: 4rem;
  }
`;

export const ListItemAvatar = styled(MuiListItemAvatar)``;

export const StyledTextListItem = styled(ListItemText)`
  .MuiTypography-root {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
