import { AdCampaignCreateUiFieldsState } from '@features/adCampaign/create/uiState/fields';
import { AdCampaignCreateUiStepsState } from '../uiState/steps';

export const AdCampaignCreateUiStateAdapter = () => {
  return (
    <>
      <AdCampaignCreateUiStepsState />
      <AdCampaignCreateUiFieldsState />
    </>
  );
};
