import { FCC } from 'react';
import { BookingOfferServiceManageContainerProps } from '../container';
import { BookingOfferServiceManageParamsContext } from '../context';

export const BookingOfferServiceManageParamsProvider: FCC<BookingOfferServiceManageContainerProps> = ({
  children,
  ...value
}) => {
  return (
    <BookingOfferServiceManageParamsContext.Provider value={value}>
      {children}
    </BookingOfferServiceManageParamsContext.Provider>
  );
};
