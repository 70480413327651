import { UserAccessPartitionClientOrgs } from '@/domain';
import ClientOrgDetailsFooterAdapter from '@features/clientOrg/details/adapters/footer';
import { CommonFetchErrorDecorator } from '@features/general/decorators/error';
import MasterContentGridLayout from '@layouts/masterContentGrid/container';
import { MasterDetailsLayout } from '@layouts/masterDetails/container';
import { CommonPreloaderDecorator } from '../../general/decorators/preloader';
import { CommonUpdaterDecorator } from '../../general/decorators/updater';
import MasterDetailsContainer from '../../general/master/details/container';
import { useClientOrgActionsSelectors } from '../actions/useActions';
import { ClientOrgLifeCycle } from '../lifecycle/types';
import {
  ClientOrgDetailsContentAdapter,
  ClientOrgDetailsHeaderAdapter,
  ClientOrgDetailsSidePanelsAdapter,
} from './adapters';
import { ClientOrgDetailsInitializer } from './initializer';
import {
  ClientOrgDetailHandlersProvider,
  ClientOrgDetailLifecycleProvider,
  ClientOrgDetailParamsProvider,
} from './providers';
import { clientOrgDetailsFetchIsFailedSelector, clientOrgDetailsIsPreloadingSelector } from './store/selectors';

export interface ClientOrgDetailsContainerProps {
  readonly id: UUID;
  readonly lifecycle: ClientOrgLifeCycle;
  readonly access: UserAccessPartitionClientOrgs;
}

const ClientOrgDetailsContainer = (props: ClientOrgDetailsContainerProps) => {
  const { id, access, lifecycle } = props;

  const { getIsClientOrgProcessingSelector } = useClientOrgActionsSelectors();

  return (
    <MasterDetailsContainer
      layout={MasterDetailsLayout}
      layoutProps={{
        header: ClientOrgDetailsHeaderAdapter,
        footer: ClientOrgDetailsFooterAdapter,
        decorators: [
          <CommonPreloaderDecorator
            key='preloader'
            isFetchingSelector={clientOrgDetailsIsPreloadingSelector}
          />,
          <CommonUpdaterDecorator
            key='actions'
            isFetchingSelector={getIsClientOrgProcessingSelector(id)}
          />,
          <CommonFetchErrorDecorator
            key='fail'
            isFetchFailedSelector={clientOrgDetailsFetchIsFailedSelector}
          />,
        ],
      }}
      initializer={ClientOrgDetailsInitializer}
      providers={[
        {
          name: 'params',
          factory: ClientOrgDetailParamsProvider,
        },
        {
          name: 'handlers',
          factory: ClientOrgDetailHandlersProvider,
        },
        {
          name: 'lifecycle',
          factory: ClientOrgDetailLifecycleProvider,
        },
      ]}
      commonProps={{
        id,
        lifecycle,
        access,
      }}
    >
      <MasterContentGridLayout sideContent={<ClientOrgDetailsSidePanelsAdapter />}>
        <ClientOrgDetailsContentAdapter />
      </MasterContentGridLayout>
    </MasterDetailsContainer>
  );
};

export default ClientOrgDetailsContainer;
