import React from 'react';
import { BookingOrder } from '../../../../domain/model/order';
import { Nullable } from '../../../../domain/model/types';
import { OnChangeObjectAttribute, OnChangeObjectAttributeByIndex } from '../../../types';
import {
  BookingOrderAction,
  BookingOrderActionEditType,
  BookingOrderActions,
  BookingOrderItemView,
  BookingOrderView,
} from '../types';
import { BookingOrderEditState } from './store/slice';

export type BookingOrderEditHandlersContextValue = {
  readonly onBookingOrderConfirm: () => void;
  readonly onBookingOrderGive: () => void;
  readonly onClose: () => void;

  readonly onTryAddService: () => void;
  readonly onTryCancel: () => void;

  readonly onChangeAttribute: OnChangeObjectAttribute<BookingOrderView>;
  readonly onAttributeValidate: (name: keyof BookingOrderView) => void;

  readonly onAddOrderItem: (orderItem: BookingOrderItemView) => void;
  readonly onDeleteOrderItem: (index: number) => void;
  readonly onChangeOrderItemAttribute: OnChangeObjectAttributeByIndex<BookingOrderItemView>;
  readonly onOrderItemAttributeValidate: (index: number, name: keyof BookingOrderItemView) => void;

  readonly onChangeDialogState: (
    name: keyof BookingOrderEditState['dialogs'],
    bookingOrder: Nullable<BookingOrder | BookingOrderView>
  ) => void;
};

export const BookingOrderEditHandlersContext = React.createContext<BookingOrderEditHandlersContextValue>(
  {} as BookingOrderEditHandlersContextValue
);

export type BookingOrderEditLifecycleContextValue = {
  readonly actions: BookingOrderActions<BookingOrderActionEditType>;
  readonly onAction: (action: BookingOrderAction<BookingOrderActionEditType>) => void;
};

export const BookingOrderEditLifecycleContext = React.createContext<BookingOrderEditLifecycleContextValue>(
  {} as BookingOrderEditLifecycleContextValue
);
