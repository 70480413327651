import { useCallback, useEffect, useState } from 'react';
import { MPAutocompleteSingleSelect } from '../../../theme/ui-kit/autocomplete/single';
import { viewConfig } from '../../../theme/viewConfig';
import { CascadeItem } from './types';
import { findParentAndPush } from './utils';

type SearchProps<T> = {
  value?: CascadeItem<T>;
  source: CascadeItem<T>[];
  onChange?: (item: CascadeItem<T> | undefined, all: CascadeItem<T>[]) => void;
};

function SearchCascade<T>(props: SearchProps<T>) {
  const { source, onChange, value } = props;
  const [searchInput, setSearchInput] = useState<string>();
  const [searchVariants, setSearchVariants] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<any>(value);

  useEffect(() => {
    if (value) {
      setSearchValue(value);
      setSearchInput(value.name);
    }
  }, [value]);

  const onSearch = useCallback(
    (value: string) => {
      if (value) {
        const includes = source.filter(r => r.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()));

        const variants = includes.map(item => {
          const d = { id: item.id, name: '' };
          const cascade: any = [item];
          findParentAndPush(item.parentId, cascade, source);

          d.name = cascade
            .reverse()
            .map((r: any) => r.name)
            .join(' > ');
          return d;
        });

        setSearchVariants(variants);
        setSearchInput(value);
      } else {
        setSearchVariants([]);
        setSearchInput('');
      }
    },
    [source]
  );

  const onChangeValue = useCallback(
    (value: CascadeItem<T> | null) => {
      if (value) {
        const target = source.find(item => item.id === value.id);
        if (target) {
          const cascade: any = [target];
          findParentAndPush(target.parentId, cascade, source);
          onChange?.(target, cascade.reverse());
          setSearchValue({
            id: target.id,
            name: target.name,
          });
        }
      }
    },
    [source, onChange]
  );

  return (
    <div style={{ width: Number.parseInt(viewConfig.menuPanelWidth, 10) * 3 + 'rem', paddingBottom: '20px' }}>
      <MPAutocompleteSingleSelect
        controlled
        data-testid={'select-cascade_search'}
        label='Поиск категории'
        value={searchValue}
        inputValue={searchInput}
        options={searchVariants}
        onSearchValue={onSearch}
        onChangeValue={onChangeValue}
      />
    </div>
  );
}

export default SearchCascade;
