import styled from '@emotion/styled';

export const FaviconWrapper = styled.div`
  max-width: 32px;
  max-height: 32px;

  display: flex;
  margin-right: ${p => p.theme.spacing()};

  > * {
    max-width: 100%;
    max-height: 100%;
  }
`;
