import { DataTableColumns } from '@components/common/table';
import { EBookingOfferCollectionTableColumn } from '@features/bookingOffer/components/collection/types';

export const bookingOfferCollectionDefaultColumns = [
  EBookingOfferCollectionTableColumn.Code,
  EBookingOfferCollectionTableColumn.Name,
];

export const bookingOfferCollectionColumns: DataTableColumns<EBookingOfferCollectionTableColumn> = {
  [EBookingOfferCollectionTableColumn.Code]: {
    title: 'Код',
    width: '9rem',
  },
  [EBookingOfferCollectionTableColumn.Name]: {
    title: 'Название',
    wrap: true,
  },
};
