import { RootState } from '@/data/store/store';
import { UUID } from '@/domain/model/types';
import { createSelector } from '@reduxjs/toolkit';
import { ECorpActivationActionType } from '../../types';

export const getCorpActivationActionsOfferIsProcessingSelector = (id: Nullable<UUID>) =>
  createSelector(
    (store: RootState) => store.corpActivation.actions.actions,
    actions => {
      return actions.some(action => action.id === id && action.isFetching);
    }
  );

export const isCorpActivationsAnyChangedSelector = (store: RootState) =>
  store.corpActivation.actions.actions.filter(action => !action.isFetching).length;

export const getIsCorpActivationAnyActionByTypeExecutedSelector = (
  id: Nullable<UUID>,
  ...actions: ECorpActivationActionType[]
) =>
  createSelector(
    () => id,
    () => actions,
    (store: RootState) => store.corpActivation.actions.actions,
    (id, argActions, storeActions) => {
      return id
        ? storeActions.find(action => action.id === id && action.isFetched && argActions.some(a => a === action.type))
            ?.type ?? null
        : null;
    }
  );
