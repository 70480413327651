import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import TablePagination from '../../../components/common/table/pagination';
import { useContextHandlers } from './hooks/useContextHandlers';
import {
  tradeOffersPageCountSelector,
  tradeOffersPageNumberSelector,
  tradeOffersSearchSelector,
  tradeOffersTotalCountSelector,
} from './store/selectors';

interface TradeOfferTableFooterContainerProps {}

const TradeOfferTableFooterContainer = forwardRef((props: TradeOfferTableFooterContainerProps, ref: any) => {
  const handlers = useContextHandlers();

  const pageNumber = useSelector(tradeOffersPageNumberSelector);
  const search = useSelector(tradeOffersSearchSelector);
  const totalCount = useSelector(tradeOffersTotalCountSelector);
  const pageCount = useSelector(tradeOffersPageCountSelector);

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='торговых предложений'
        onChangePageSize={handlers.onTradeOffersChangePageSize}
        onChangePage={handlers.onTradeOffersChangePage}
      />
    </div>
  );
});

export default TradeOfferTableFooterContainer;
