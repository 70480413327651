import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import TablePagination from '../../../components/common/table/pagination';
import { useContextHandlers } from './hooks/useContextHandlers';
import {
  adCampaignPageCountSelector,
  adCampaignPageNumberSelector,
  adCampaignSearchSelector,
  adCampaignTotalCountSelector,
} from './store/selectors';

interface EventTableFooterContainerProps {}

const AdCampaignTableFooterContainer = forwardRef((props: EventTableFooterContainerProps, ref: any) => {
  const handlers = useContextHandlers();

  const pageNumber = useSelector(adCampaignPageNumberSelector);
  const search = useSelector(adCampaignSearchSelector);
  const totalCount = useSelector(adCampaignTotalCountSelector);
  const pageCount = useSelector(adCampaignPageCountSelector);

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='рекламных кабинетов'
        onChangePageSize={handlers.onChangePageSize}
        onChangePage={handlers.onChangePage}
      />
    </div>
  );
});

export default AdCampaignTableFooterContainer;
