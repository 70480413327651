import { MPBenefitFormat, MPCostFormat } from '@/presentation/theme/ui-kit/format';
import { FC } from 'react';
import AppSvgIcon from '../../../../components/common/images/icon';
import { PrivilegeUnitsIcon } from '../../../../icons';
import { EEntityPreviewMode } from '../../../../types';
import { OfferPreviewSkeleton } from '../../../general/offer/components/preview/controls';
import { OriginalPrice, Price, PriceWrapper } from './controls';

type CorpOfferPreviewPriceProps = {
  readonly mode: EEntityPreviewMode;
  readonly price: Nullable<number>;
  readonly originalPrice: Nullable<number>;
};

const CorpOfferPreviewPrice: FC<CorpOfferPreviewPriceProps> = props => {
  const { mode, price, originalPrice } = props;

  return (
    <>
      {price !== null ? (
        <PriceWrapper>
          <AppSvgIcon icon={PrivilegeUnitsIcon} />
          <Price variant={mode === EEntityPreviewMode.Mobile ? 'subtitle2' : 'h3'}>
            <MPBenefitFormat value={price} />
          </Price>
          {originalPrice !== null && originalPrice > price && (
            <OriginalPrice
              variant={mode === EEntityPreviewMode.Mobile ? 'overline' : 'body2'}
              color='textSecondary'
            >
              <MPCostFormat
                displayType='text'
                thousandSeparator=' '
                value={originalPrice}
              />
            </OriginalPrice>
          )}
        </PriceWrapper>
      ) : (
        <OfferPreviewSkeleton
          animation={false}
          width='60%'
          variant='rectangular'
          height={mode === EEntityPreviewMode.Mobile ? '1.25rem' : '1.5rem'}
        />
      )}
    </>
  );
};

export default CorpOfferPreviewPrice;
