import styled from '@emotion/styled';

export const Wrapper = styled.div`
  min-height: 100vh;

  display: flex;
`;

interface ContentWrapperProps {
  readonly fullscreen: boolean;
  readonly children: any;
}

export const ContentWrapper = styled(({ fullscreen, ...others }: ContentWrapperProps) => <div {...others} />)`
  width: calc(100% - 24.25rem);

  flex-grow: 1;
  position: relative;
`;
