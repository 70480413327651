import { CorpOffer, ESortDirection } from '@/domain';
import { DataTableLoader } from '@components/common/table/loader';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../components/common/table';
import CorpOfferTableCellApprovalRegistry from '../components/tableCell/approvalRegistry';
import CorpOfferTableCellCategories from '../components/tableCell/categories';
import CorpOfferTableCellCode from '../components/tableCell/code';
import CorpOfferTableCellInn from '../components/tableCell/inn';
import CorpOfferTableCellKpp from '../components/tableCell/kpp';
import CorpOfferTableCellLastStatusAuthor from '../components/tableCell/lastStatusAuthor';
import CorpOfferTableCellLastStatusDate from '../components/tableCell/lastStatusDate';
import CorpOfferTableCellModerator from '../components/tableCell/moderator';
import CorpOfferTableCellName from '../components/tableCell/name';
import CorpOfferTableCellNdfl from '../components/tableCell/ndfl';
import CorpOfferTableCellOfferCounts from '../components/tableCell/offerCounts';
import CorpOfferTableCellOgrn from '../components/tableCell/ogrn';
import CorpOfferTableCellPartner from '../components/tableCell/partner';
import CorpOfferTableCellPartnerResponsible from '../components/tableCell/partnerResponsible';
import CorpOfferTableCellPartnerType from '../components/tableCell/partnerType';
import CorpOfferTableCellPausedReason from '../components/tableCell/pausedReason';
import CorpOfferTableCellPeriod from '../components/tableCell/period';
import CorpOfferTableCellPrice from '../components/tableCell/price';
import CorpOfferTableCellPromotionType from '../components/tableCell/promotionType';
import CorpOfferTableCellStatus from '../components/tableCell/status';
import CorpOfferTableCellStoreResponsible from '../components/tableCell/storerResponsible';
import CorpOfferTableCellTargetFamilyMemberOnly from '../components/tableCell/targetFamilyMemberOnly';
import CorpOfferTableCellTargetHavingChildFamilyMemberOnly from '../components/tableCell/targetHavingChildFamilyMemberOnly';
import CorpOfferTableCellTargetLocalities from '../components/tableCell/targetLocalities';
import CorpOfferTableCellTargetOrgUnits from '../components/tableCell/targetOrgUnits';
import CorpOfferTableCellRoads from '../components/tableCell/targetRoads';
import CorpOfferTableCellTargetTradeUnionMembersOnly from '../components/tableCell/targetTradeUnionMembersOnly';
import CorpOfferTableCellUserGender from '../components/tableCell/userGender';
import { ECorpOfferTableColumn } from '../types';
import CorpOffersTableCellAdapter from './adapters/cell';
import CorpOffersTableCellPartnerTypeAdapter from './adapters/cellPartnerType';
import CorpOffersTableCellPromotionTypeAdapter from './adapters/cellPromotionType';
import CorpOffersTableCellStatusAdapter from './adapters/cellStatus';
import CorpOffersTableCellUserGenderAdapter from './adapters/cellTargetGender';
import { corpOffersSearchIsFetchingSelector } from './store/selectors';

interface CorpOfferSearchProps {
  readonly metadata: DataTableMetadata<ECorpOfferTableColumn>;
  readonly corpOffers: CorpOffer[];
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly selectedCorpOffers: CorpOffer[];
  readonly isSingleSelect?: boolean;
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onCorpOfferClick: (corpOffer: CorpOffer) => void;
  readonly onChangeMetadata: (metadata: DataTableMetadata<ECorpOfferTableColumn>) => void;
  readonly onCorpOfferSelect: (corpOffer: CorpOffer, selected: boolean) => void;
  readonly onAllCorpOffersSelect: (selected: boolean) => void;
}

const CorpOfferSearch = (props: CorpOfferSearchProps) => {
  const {
    metadata,
    corpOffers,
    sort,
    selectedCorpOffers,
    isSingleSelect,
    onRequestSort,
    onCorpOfferClick,
    onChangeMetadata,
    onCorpOfferSelect,
    onAllCorpOffersSelect,
  } = props;

  const rows: DataTableRow<CorpOffer, ECorpOfferTableColumn>[] = corpOffers?.map((corpOffer, index) => {
    return {
      [ECorpOfferTableColumn.LastStatusDate]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellLastStatusDate}
        />
      ),
      [ECorpOfferTableColumn.Code]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellCode}
        />
      ),
      [ECorpOfferTableColumn.Name]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellName}
        />
      ),
      [ECorpOfferTableColumn.Partner]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellPartner}
        />
      ),
      [ECorpOfferTableColumn.PartnerType]: (
        <CorpOffersTableCellPartnerTypeAdapter
          index={index}
          component={CorpOfferTableCellPartnerType}
        />
      ),
      [ECorpOfferTableColumn.Period]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellPeriod}
        />
      ),
      [ECorpOfferTableColumn.Ndfl]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellNdfl}
        />
      ),
      [ECorpOfferTableColumn.Status]: (
        <CorpOffersTableCellStatusAdapter
          index={index}
          component={CorpOfferTableCellStatus}
        />
      ),
      [ECorpOfferTableColumn.Moderator]: (
        <CorpOffersTableCellStatusAdapter
          index={index}
          component={CorpOfferTableCellModerator}
        />
      ),
      [ECorpOfferTableColumn.LastStatusAuthor]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellLastStatusAuthor}
        />
      ),
      [ECorpOfferTableColumn.OfferCounts]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellOfferCounts}
        />
      ),
      [ECorpOfferTableColumn.Price]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellPrice}
        />
      ),
      [ECorpOfferTableColumn.PausedReason]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellPausedReason}
        />
      ),
      [ECorpOfferTableColumn.ApprovalRegistry]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellApprovalRegistry}
        />
      ),
      [ECorpOfferTableColumn.PartnerResponsible]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellPartnerResponsible}
        />
      ),
      [ECorpOfferTableColumn.StoreResponsible]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellStoreResponsible}
        />
      ),
      [ECorpOfferTableColumn.Categories]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellCategories}
        />
      ),
      [ECorpOfferTableColumn.TargetLocalities]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellTargetLocalities}
        />
      ),
      [ECorpOfferTableColumn.PromotionType]: (
        <CorpOffersTableCellPromotionTypeAdapter
          index={index}
          component={CorpOfferTableCellPromotionType}
        />
      ),
      [ECorpOfferTableColumn.TargetGender]: (
        <CorpOffersTableCellUserGenderAdapter
          index={index}
          component={CorpOfferTableCellUserGender}
        />
      ),
      [ECorpOfferTableColumn.TargetRoads]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellRoads}
        />
      ),
      [ECorpOfferTableColumn.TargetOrgUnits]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellTargetOrgUnits}
        />
      ),
      [ECorpOfferTableColumn.TargetTradeUnionMembersOnly]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellTargetTradeUnionMembersOnly}
        />
      ),
      [ECorpOfferTableColumn.TargetHavingChildFamilyMemberOnly]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellTargetHavingChildFamilyMemberOnly}
        />
      ),
      [ECorpOfferTableColumn.TargetFamilyMemberOnly]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellTargetFamilyMemberOnly}
        />
      ),
      [ECorpOfferTableColumn.Inn]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellInn}
        />
      ),
      [ECorpOfferTableColumn.Kpp]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellKpp}
        />
      ),
      [ECorpOfferTableColumn.Ogrn]: (
        <CorpOffersTableCellAdapter
          index={index}
          component={CorpOfferTableCellOgrn}
        />
      ),
      data: corpOffer,
    };
  });

  return (
    <DataTable<CorpOffer, ECorpOfferTableColumn>
      metadata={metadata}
      width='auto'
      overflowX='inherit'
      rows={rows}
      sort={{ column: sort.column, direction: sort.direction }}
      loader={<DataTableLoader selector={corpOffersSearchIsFetchingSelector} />}
      selectable
      singleSelect={isSingleSelect}
      selected={selectedCorpOffers}
      onSort={(event, column, direction) => onRequestSort(column, direction)}
      onRowClick={(event, cell, { data }) => onCorpOfferClick(data)}
      onAllRowsSelect={(event, selected) => onAllCorpOffersSelect(selected)}
      onRowSelect={(event, { data }, selected) => onCorpOfferSelect(data, selected)}
      onChangeMetadata={onChangeMetadata}
    />
  );
};

export default CorpOfferSearch;
