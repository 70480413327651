import { DataFilterStrategyBase, DataFilterStrategyEdit } from '@/domain';
import { Grid } from '@mui/material';
import { viewConfig } from '@theme/viewConfig';
import { MPButton } from '@ui-kit/button';
import { useEffect, useState } from 'react';
import TopPushDialog from '../../dialogs/topPush';
import DataFilterEdit from '../edit';
import { Wrapper } from './controls';
import DataFilterDialogHeader from './header';

interface DataFilterDialogProps<T extends string> {
  readonly open: boolean;
  readonly strategies: DataFilterStrategyBase<T>[];
  readonly onApply: (strategies: DataFilterStrategyBase<T>[]) => void;
  readonly onClear: () => void;
  readonly onClose: () => void;
}

function DataFilterDialog<T extends string>(props: DataFilterDialogProps<T>) {
  const { open, strategies, onApply, onClear, onClose } = props;

  const [localStrategies, setLocalStrategies] = useState<DataFilterStrategyEdit<T>[]>(strategies);

  const onClearAndClose = () => {
    onClear();
    onClose();
  };

  const onChange = (newStrategies: DataFilterStrategyEdit<T>[]) => {
    setLocalStrategies(newStrategies);
  };

  const title = <DataFilterDialogHeader onClear={onClearAndClose} />;

  const footer = (
    <Grid
      container
      spacing={2}
    >
      <Grid item>
        <MPButton onClick={() => onApply(localStrategies as DataFilterStrategyBase<T>[])}>Применить</MPButton>
      </Grid>
    </Grid>
  );

  useEffect(() => {
    setLocalStrategies(strategies);
  }, [strategies]);

  return (
    <TopPushDialog
      title={title}
      footer={footer}
      stickyHeader
      contentOverflow='auto'
      fullScreen
      leftOffset={viewConfig.menuPanelWidth}
      backdropAdjust
      open={open}
      onClose={onClose}
    >
      <Wrapper>
        <DataFilterEdit<T>
          strategies={localStrategies}
          onChange={onChange}
        />
      </Wrapper>
    </TopPushDialog>
  );
}

export default DataFilterDialog;
