import { Link as MuiLink } from '@mui/material';
import styled from '@emotion/styled';
import { viewConfig } from '../../../theme/viewConfig';

export const LoaderWrapper = styled.div`
  z-index: 3;
  position: fixed;
  top: 0;
  left: ${viewConfig.stepper.width};
  right: 0;
  bottom: 0;
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  padding-left: ${viewConfig.stepper.width};
`;

export const Wrapper = styled.div``;

export const StepWrapper = styled.div`
  display: flex;
`;

export const ButtonsWrapper = styled.div`
  margin-left: ${viewConfig.stepper.width};
`;

export const ColumnWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  padding-top: ${p => p.theme.spacing(2.25)};
`;

export const Content = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  margin-bottom: ${p => p.theme.spacing(5)};
`;

export const PreviewWrapper = styled.div`
  position: fixed;
`;

export const DesktopPreviewWrapper = styled.div`
  width: ${viewConfig.banner.preview.width};
`;

export const MobilePreviewWrapper = styled.div`
  width: ${viewConfig.banner.preview.mobileWidth};
`;

export const Link = styled(MuiLink)`
  :focus {
    outline: none;
  }
`;

export const TitleWrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  padding-bottom: ${p => p.theme.spacing(1.5)};

  border-bottom: 1px solid ${p => p.theme.palette.disabled.background};
`;
