import { DoubleArrowOutlined } from '@mui/icons-material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Pagination } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Variant } from '@mui/material/styles/createTypography';
import Typography from '@mui/material/Typography';
import { forwardRef, useState } from 'react';
import { paginationSizeVariant } from '../../../../../domain/model/constants';
import { Nullable } from '../../../../../domain/model/types';
import { MPMenuItem } from '../../../../theme/ui-kit/menu/item';
import { PaginationSize } from '../../../../types';
import {
  GoToPageButton,
  NavButtonLeft,
  NavButtonRight,
  PageNumberInput,
  PagesAmountSelect,
  PaginationWrapper,
  Wrapper,
} from './controls';

interface TablePaginationProps {
  readonly dense?: boolean;
  readonly page: number;
  readonly pageSize: PaginationSize;
  readonly pageCount: number;
  readonly objectsCount: number;
  readonly objectsLabel: string;
  readonly disabled?: boolean;
  readonly children?: any;
  readonly onChangePageSize?: (page: PaginationSize) => void;
  readonly onChangePage: (page: number) => void;
}

const textVariant: Variant = 'body2';

const TablePagination = forwardRef((props: TablePaginationProps, ref: any) => {
  const {
    dense,
    page,
    pageSize = paginationSizeVariant[1],
    pageCount,
    objectsLabel,
    disabled,
    children,
    onChangePageSize,
    onChangePage,
  } = props;

  const [inputPageNumber, setInputPageNumber] = useState<Nullable<number>>(page);

  const onChangePageInternal = (pageNumber: Nullable<number>) => {
    if (pageNumber && pageNumber !== page && pageNumber >= 1 && pageNumber <= pageCount) {
      onChangePage(pageNumber);
    }
  };

  const onInputPageNumberKeyUp = (event: KeyboardEvent) => {
    if (event.keyCode === 13) {
      if (inputPageNumber) onChangePageInternal(inputPageNumber);
    }
  };

  const PreviousButton = () => (
    <NavButtonLeft
      variant='text'
      size='small'
      disabled={page === 1}
      onClick={() => onChangePageInternal(page - 1)}
    >
      <KeyboardArrowLeftIcon fontSize='small' />
      <Typography variant={textVariant}>Пред.</Typography>
    </NavButtonLeft>
  );

  const NextButton = () => (
    <NavButtonRight
      variant='text'
      size='small'
      disabled={page === pageCount}
      onClick={() => onChangePageInternal(page + 1)}
    >
      <Typography variant={textVariant}>Вперед</Typography>
      <KeyboardArrowRightIcon fontSize='small' />
    </NavButtonRight>
  );

  return (
    <Wrapper
      ref={ref}
      dense={dense}
    >
      <PaginationWrapper dense={dense}>
        <Grid
          container
          alignItems='center'
          justifyContent={dense ? 'space-between' : 'flex-start'}
        >
          <Grid
            container
            alignItems='center'
            spacing={1}
            wrap='nowrap'
            item
            xs={3}
          >
            {onChangePageSize && (
              <>
                <Grid item>Показать:</Grid>
                <Grid item>
                  <PagesAmountSelect<PaginationSize>
                    variant='filled'
                    disabled={disabled}
                    value={pageSize}
                    onChange={onChangePageSize}
                  >
                    {paginationSizeVariant.map(variant => (
                      <MPMenuItem<number>
                        key={variant}
                        value={variant}
                      >
                        <Typography variant='body1'>{variant}</Typography>
                      </MPMenuItem>
                    ))}
                  </PagesAmountSelect>
                </Grid>
                <Grid item>
                  <Typography
                    noWrap
                    variant='body2'
                  >
                    {objectsLabel}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            container
            justifyContent={dense ? 'flex-end' : 'center'}
            alignItems='center'
            spacing={1}
            item
            xs={6}
          >
            {pageCount > 1 && (
              <>
                <Grid item>
                  <PreviousButton />
                </Grid>
                <Grid item>
                  <Pagination
                    disabled={disabled}
                    color='primary'
                    shape='rounded'
                    size={dense ? 'small' : 'medium'}
                    count={pageCount}
                    page={page}
                    hideNextButton
                    hidePrevButton
                    onChange={(event, newPage) => onChangePageInternal(newPage)}
                  />
                </Grid>
                <Grid item>
                  <NextButton />
                </Grid>
              </>
            )}
          </Grid>
          {!dense && (
            <Grid
              container
              justifyContent='flex-end'
              alignItems='center'
              spacing={1}
              wrap='nowrap'
              item
              xs={3}
            >
              {pageCount > 5 && (
                <>
                  <Grid item>
                    <Typography variant={textVariant}>Перейти на:</Typography>
                  </Grid>
                  <Grid item>
                    <PageNumberInput
                      variant='filled'
                      value={inputPageNumber}
                      color='secondary'
                      onKeyUp={onInputPageNumberKeyUp as any}
                      onChange={(event, newValue) => setInputPageNumber(newValue)}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant={textVariant}>страницу</Typography>
                  </Grid>
                  <Grid item>
                    <GoToPageButton
                      size='small'
                      variant='outlined'
                      onClick={() => onChangePageInternal(inputPageNumber)}
                    >
                      <DoubleArrowOutlined fontSize='small' />
                    </GoToPageButton>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </PaginationWrapper>
      {children}
    </Wrapper>
  );
});

export default TablePagination;
