import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Nullable, UUID } from '../../../domain/model/types';
import rootRouting from '../../../routing';
import { createSidePanelRoute } from '../../../routing/globalRouting';
import NotFoundScreen from '../../screens/notFound';
import TeamScreen from '../../screens/team';
import TeamCreateScreen from '../../screens/teamCreate';
import TeamEditScreen from '../../screens/teamEdit';
import TeamsScreen from '../../screens/teams';
import { ESidePanelDataType } from '../general/side/enums';
import { ETeamTab } from './details/utils';

const routing = {
  root: `${rootRouting.teams}`,
  list: `${rootRouting.teams}`,
  details: `${rootRouting.teams}/:id`,
  edit: `${rootRouting.teams}/:id/edit`,
  create: `${rootRouting.teams}/create`,
};

export enum ETeamUrlParam {
  Guid = 'guid',
  Tab = 'tab',
}

interface GetTeamRouteProps {
  readonly guid?: Nullable<UUID>;
  readonly id: UUID;
  readonly tab?: ETeamTab;
}

export interface TeamCreateLocationState {
  readonly guid: UUID;
}

export interface TeamsLocationState {
  readonly guid: UUID;
}

export const getTeamRoute = ({ guid, id, tab }: GetTeamRouteProps) => {
  const searchParams = new URLSearchParams();
  searchParams.append(ETeamUrlParam.Guid, guid || uuidv4());

  if (tab) {
    searchParams.append(ETeamUrlParam.Tab, tab);
  }

  return `${routing.details.replace(':id', id)}?${searchParams.toString()}`;
};

interface GetTeamLogoSelectRouteProps {
  readonly location: any;
  readonly id?: UUID;
  readonly selected?: Nullable<UUID>;
  readonly parent?: UUID;
}

export const getTeamLogoSelectRoute = (props: GetTeamLogoSelectRouteProps) => {
  const { location, id, selected, parent } = props;

  return createSidePanelRoute({
    location,
    type: ESidePanelDataType.TeamLogoSelect,
    params: {
      id,
      selected,
    },
    parent,
  });
};
export const getTeamCreateRoute = () => {
  const guid = uuidv4();

  const searchParams = new URLSearchParams();

  return {
    pathname: routing.create,
    search: `?${searchParams.toString()}`,
    state: {
      guid,
    },
    hash: '',
  };
};

interface GetTeamEditRouteProps {
  readonly id: UUID;
}

export const getTeamEditRoute = ({ id }: GetTeamEditRouteProps) => {
  return routing.edit.replace(':id', id);
};

const TeamsEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.list}
        component={TeamsScreen}
      />
      <Route
        exact
        path={routing.create}
        component={TeamCreateScreen}
      />
      <Route
        exact
        path={routing.edit}
        component={TeamEditScreen}
      />
      <Route
        exact
        path={routing.details}
        component={TeamScreen}
      />
      <Redirect
        exact
        from={routing.root}
        to={routing.list}
      />

      <Route component={NotFoundScreen} />
    </Switch>
  );
};

export default TeamsEntry;
