import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { OrgUnit, RzhdRoad } from '../../domain/model/orgStructure';
import { Nullable } from '../../domain/model/types';
import { createCancelToken } from './index';
import { ApiCancellable, ApiQueryDsl, ApiRequestPageable } from './types';
import { appendQueryDslParams, getComServicesEndpoint } from './utils';

type OrgUnitAllProps = ApiCancellable &
  ApiRequestPageable & {
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
    readonly config?: AxiosRequestConfig;
  };

type RoadAllProps = ApiCancellable &
  ApiRequestPageable & {
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
    readonly config?: AxiosRequestConfig;
  };

type orgStructureApi = {
  readonly orgUnit: {
    readonly all: (props: OrgUnitAllProps) => Promise<AxiosResponse<OrgUnit[]>>;
  };
  readonly road: {
    readonly all: (props: RoadAllProps) => Promise<AxiosResponse<RzhdRoad[]>>;
  };
};

/**
 * АПИ по работе с корп структурой
 */
const orgStructure: orgStructureApi = {
  orgUnit: {
    all: props => {
      const { query, querydsl, sort, page, pageSize, signal, config } = props;

      const params = new URLSearchParams();

      if (sort) {
        if (typeof sort === 'string') {
          params.append('sort', sort);
        } else {
          sort.forEach(item => params.append('sort', item));
        }
      }

      params.append('page', (page - 1).toString(10));
      params.append('size', pageSize.toString(10));

      if (query) {
        params.append('q', query);
      }

      if (querydsl) {
        appendQueryDslParams(params, querydsl);
      }

      return axios.get(`${getComServicesEndpoint()}/org-units`, {
        cancelToken: signal && createCancelToken(axios, signal),
        ...config,
        params,
      });
    },
  },
  road: {
    all: props => {
      const { query, querydsl, sort, page, pageSize, signal, config } = props;

      const params = new URLSearchParams();

      if (sort) {
        if (typeof sort === 'string') {
          params.append('sort', sort);
        } else {
          sort.forEach(item => params.append('sort', item));
        }
      }

      params.append('page', (page - 1).toString(10));
      params.append('size', pageSize.toString(10));

      if (query) {
        params.append('q', query);
      }

      if (querydsl) {
        appendQueryDslParams(params, querydsl);
      }

      return axios.get(`${getComServicesEndpoint()}/roads`, {
        cancelToken: signal && createCancelToken(axios, signal),
        ...config,
        params,
      });
    },
  },
};

export default orgStructure;
