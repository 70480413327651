import { ReactNode, useMemo } from 'react';
import { OfferState } from '../../../../../../../domain/model';
import DataTableCellTextFormat from '../../../../../../components/common/table/cell/textFormat';

export interface OfferHistoryTableCellCommonProps {
  readonly history: OfferState;
}

interface OfferHistoryTableCellProps extends OfferHistoryTableCellCommonProps {
  readonly value: ReactNode;
}

const OfferHistoryTableCell = ({ value }: OfferHistoryTableCellProps) => {
  return useMemo(() => <DataTableCellTextFormat>{value}</DataTableCellTextFormat>, [value]);
};

export default OfferHistoryTableCell;
