import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { viewConfig } from '../../../theme/viewConfig';

export const StepWrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  padding-top: ${p => p.theme.spacing(4)};
`;

export const HistoryLink = styled(Typography)`
  font-weight: ${p => p.theme.typography.fontWeightSemiBold};
`;

export const StepperHeaderText = styled(Typography)`
  word-break: break-word;
`;
