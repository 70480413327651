import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import Api from '../../../../../../data/api';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { OrgUnit } from '../../../../../../domain/model/orgStructure';
import { Nullable } from '../../../../../../domain/model/types';
import {
  getOrgStructureOrgUnitMultipleItemAllOrgUnits,
  getOrgStructureOrgUnitMultipleItemDefaultSourceFooter,
  getOrgStructureOrgUnitMultipleItemDefaultSourceHeader,
  getOrgStructureOrgUnitMultipleItemNoneOrgUnits,
} from './item';
import {
  OrgStructureOrgUnitMultipleAllOrgUnitsState,
  OrgStructureOrgUnitMultipleItemType,
  OrgStructureOrgUnitMultipleSelectType,
  OrgStructureOrgUnitMultipleTypesSelectorItem,
} from './types';

interface UseOrgStructureOrgUnitMultipleTypesProps {
  readonly select?: Nullable<OrgStructureOrgUnitMultipleSelectType>;
  readonly selectNone?: boolean;
  readonly orgUnits: OrgUnit[];
  /* набор данных по умолчанию (в моменты когда нет поискового запроса) */
  readonly defaultSource?: Nullable<OrgUnit[]>;
  readonly onSelect: (type: OrgStructureOrgUnitMultipleSelectType) => void;
  readonly onChange: (orgUnits: OrgUnit[]) => void;
}

interface UseOrgStructureOrgUnitMultipleTypes {
  readonly options: OrgStructureOrgUnitMultipleTypesSelectorItem[];
  readonly values: OrgStructureOrgUnitMultipleTypesSelectorItem[];
  readonly isLoading: boolean;
  readonly setSearchValue: (value: string) => void;
  readonly onChangeValue: (value: Nullable<OrgStructureOrgUnitMultipleTypesSelectorItem[]>) => void;
  readonly getOptionLabel: (option: OrgStructureOrgUnitMultipleTypesSelectorItem) => string;
  readonly onFilterOptions: (
    options: OrgStructureOrgUnitMultipleTypesSelectorItem[],
    value: string
  ) => OrgStructureOrgUnitMultipleTypesSelectorItem[];
}

const useOrgStructureOrgUnitMultipleTypes = (
  props: UseOrgStructureOrgUnitMultipleTypesProps
): UseOrgStructureOrgUnitMultipleTypes => {
  const { selectNone, select, orgUnits, defaultSource, onSelect, onChange } = props;

  const [suggestions, setSuggestions] = useState<OrgUnit[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    debounce(() => {
      if (!searchValue) {
        return;
      }

      const cancelCallback: CancelTokenSource = axios.CancelToken.source();
      const config: AxiosRequestConfig = {
        cancelToken: cancelCallback.token,
      };

      setIsLoading(true);
      Api.orgStructure.orgUnit
        .all({
          page: 1,
          pageSize: 25,
          sort: 'name',
          query: searchValue,
          config,
        })
        .then(({ data }) => setSuggestions(data))
        .catch(e => {
          console.error(e);
          ErrorHandler.handleHttpError(e, e.response);
        })
        .finally(() => {
          setIsLoading(false);
        });

      return () => {
        cancelCallback.cancel();
      };
    }, 500),
    [searchValue]
  );

  const onChangeValue = useCallback(
    (value: Nullable<OrgStructureOrgUnitMultipleTypesSelectorItem[]>) => {
      if (
        value?.some(
          item =>
            item.type === OrgStructureOrgUnitMultipleItemType.All &&
            item.state !== OrgStructureOrgUnitMultipleAllOrgUnitsState.Checked
        )
      ) {
        onSelect(OrgStructureOrgUnitMultipleSelectType.All);
      } else if (
        value?.some(
          item =>
            item.type === OrgStructureOrgUnitMultipleItemType.None &&
            item.state !== OrgStructureOrgUnitMultipleAllOrgUnitsState.Checked
        )
      ) {
        onSelect(OrgStructureOrgUnitMultipleSelectType.None);
      } else {
        const selectedOrgUnits = value?.filter(v => v.type === OrgStructureOrgUnitMultipleItemType.OrgUnit) ?? [];
        onChange(selectedOrgUnits);
      }
    },
    [onChange, onSelect]
  );

  const getOptionLabel = useCallback((option: OrgStructureOrgUnitMultipleTypesSelectorItem) => {
    switch (option.type) {
      case OrgStructureOrgUnitMultipleItemType.OrgUnit:
        return option.name;
      default:
        return '';
    }
  }, []);

  const onFilterOptions = useCallback((options: OrgStructureOrgUnitMultipleTypesSelectorItem[], value: string) => {
    const newOptions: OrgStructureOrgUnitMultipleTypesSelectorItem[] = [];
    options.forEach(element => {
      if (new RegExp(`.*${value}.*`, 'gi').test(element.name)) newOptions.push(element);
    });

    //удаляем им результатов "Все филиалы" если начали поиск
    if (value) {
      const allIndex = newOptions.findIndex(element => element.type === OrgStructureOrgUnitMultipleItemType.All);
      if (allIndex > -1) {
        newOptions.splice(allIndex, 1);
      }
    }
    return newOptions;
  }, []);

  const options: OrgStructureOrgUnitMultipleTypesSelectorItem[] = [];

  if (selectNone) {
    options.push(
      getOrgStructureOrgUnitMultipleItemNoneOrgUnits(
        select === OrgStructureOrgUnitMultipleSelectType.None
          ? OrgStructureOrgUnitMultipleAllOrgUnitsState.Checked
          : OrgStructureOrgUnitMultipleAllOrgUnitsState.NotChecked
      )
    );
  }

  //всегда добавляем первым "Все филиалы"
  options.push(
    getOrgStructureOrgUnitMultipleItemAllOrgUnits(
      select === OrgStructureOrgUnitMultipleSelectType.All
        ? OrgStructureOrgUnitMultipleAllOrgUnitsState.Checked
        : orgUnits?.length
        ? OrgStructureOrgUnitMultipleAllOrgUnitsState.Indeterminate
        : OrgStructureOrgUnitMultipleAllOrgUnitsState.NotChecked
    )
  );

  //если не введен текст для поиска то добавляем дефолтный список
  if (!searchValue && defaultSource?.length) {
    options.push(getOrgStructureOrgUnitMultipleItemDefaultSourceHeader());
    defaultSource.forEach(item =>
      options.push({
        type: OrgStructureOrgUnitMultipleItemType.OrgUnit,
        id: item.id,
        name: item.name,
      })
    );
    options.push(getOrgStructureOrgUnitMultipleItemDefaultSourceFooter());
  } else {
    suggestions.forEach(item =>
      options.push({
        type: OrgStructureOrgUnitMultipleItemType.OrgUnit,
        id: item.id,
        name: item.name,
      })
    );
  }

  const values: OrgStructureOrgUnitMultipleTypesSelectorItem[] = orgUnits.map(item => ({
    type: OrgStructureOrgUnitMultipleItemType.OrgUnit,
    id: item.id,
    name: item.name,
  }));

  if (select === OrgStructureOrgUnitMultipleSelectType.All) {
    values.push(getOrgStructureOrgUnitMultipleItemAllOrgUnits(OrgStructureOrgUnitMultipleAllOrgUnitsState.Checked));
  }

  if (select === OrgStructureOrgUnitMultipleSelectType.None) {
    values.push(getOrgStructureOrgUnitMultipleItemNoneOrgUnits(OrgStructureOrgUnitMultipleAllOrgUnitsState.Checked));
  }

  return {
    options,
    values,
    isLoading,
    setSearchValue,
    onChangeValue,
    getOptionLabel,
    onFilterOptions,
  };
};

export default useOrgStructureOrgUnitMultipleTypes;
