import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import { CorpOfferTableCellUserGenderProps } from '../../components/tableCell/userGender';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { corpOffersSearchCorpOfferByIndexSelector } from '../store/selectors';
import { CorpOffersSearchCellAdapterProps } from './cell';

type CorpOffersTableCellUserGenderAdapterProps = Omit<CorpOffersSearchCellAdapterProps, 'component'> & {
  readonly component: FC<CorpOfferTableCellUserGenderProps>;
};

const CorpOffersTableCellUserGenderAdapter = (props: CorpOffersTableCellUserGenderAdapterProps) => {
  const { index, component: Component } = props;

  const handlers = useContextHandlers();
  const corpOffer = useSelector(corpOffersSearchCorpOfferByIndexSelector(index));
  const isDisabled = corpOffer ? handlers.isCorpOfferNotModeratedByMe(corpOffer) : false;

  const { userGenders } = useSelector(nsiDataSelector);

  return useMemo(
    () =>
      corpOffer ? (
        <Component
          corpOffer={corpOffer}
          userGenders={userGenders}
          isDisabled={isDisabled}
        />
      ) : null,
    [Component, corpOffer, isDisabled, userGenders]
  );
};

export default CorpOffersTableCellUserGenderAdapter;
