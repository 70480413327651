import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import { CorpOfferTableCellStatusProps } from '../../components/tableCell/status';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { corpOffersCorpOfferByIndexSelector, corpOffersUnwatchedByIndexSelector } from '../store/selectors';
import { CorpOffersTableCellAdapterProps } from './cell';

type CorpOffersTableCellStatusAdapterProps = Omit<CorpOffersTableCellAdapterProps, 'component'> & {
  readonly component: FC<CorpOfferTableCellStatusProps>;
};

const CorpOffersTableCellStatusAdapter = (props: CorpOffersTableCellStatusAdapterProps) => {
  const { index, component: Component } = props;

  const handlers = useContextHandlers();
  const corpOffer = useSelector(corpOffersCorpOfferByIndexSelector(index));
  const isUnwatched = corpOffer ? useSelector(corpOffersUnwatchedByIndexSelector(index)) : false;
  const isDisabled = corpOffer && !isUnwatched ? handlers.isCorpOfferNotModeratedByMe(corpOffer) : false;

  const { offerStatuses } = useSelector(nsiDataSelector);

  return useMemo(
    () =>
      corpOffer ? (
        <Component
          corpOffer={corpOffer}
          offerStatuses={offerStatuses}
          isDisabled={isDisabled}
          isTextAccent={isUnwatched}
        />
      ) : null,
    [Component, corpOffer, isDisabled, isUnwatched, offerStatuses]
  );
};

export default CorpOffersTableCellStatusAdapter;
