import { Fade, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ENoticeStatus } from '../../../../../domain/model/enums';
import { UUID } from '../../../../../domain/model/types';
import { MpUserData } from '../../../../../domain/model/user';
import Notifier from '../../../../../system/notifier';
import MasterActionsComponent from '../../../../components/common/actions/master';
import useNavAdapter from '../../../../components/common/actions/navAdapter/hooks';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../components/common/header';
import ContentLoader from '../../../../components/common/loader';
import { DefaultContentWrapper } from '../../../../components/common/wrappers/content';
import { DefaultFooterWrapper } from '../../../../components/common/wrappers/footer';
import useValidation from '../../../../hooks/validation/useValidation';
import { EPanelActionPosition, PanelAction, PanelActions } from '../../../../types';
import StepperContainer from '../../../general/stepper/container';
import { EUserMPActionType, UserMPEditActionType } from '../types';
import { getUserMPActionName } from '../utils';
import UserMpEdit from './component';
import { ContainerWrapper, ContentContainer, LoaderWrapper, TitleWrapper } from './controls';
import { userMpEditByIdSelector, userMpEditUpdateSelector } from './store/selectors';
import { userMpEditByIdFetch, userMpEditSetAttribute, userMpEditStateReset, userMpEditUpdate } from './store/slice';
import { EUserMpEditStep, userMpEditSteps, userMpEditValidation } from './utils';

interface UserMpEditContainerProps {
  readonly id: UUID;
  readonly step?: EUserMpEditStep;
  readonly onAfterSave?: () => void;
}

const validationRulesProfile = userMpEditValidation;

const UserMpEditContainer = (props: UserMpEditContainerProps) => {
  const { id, step = userMpEditSteps[0].key, onAfterSave } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const [validateOnChangeProfile, setValidateOnChangeProfile] = useState<boolean>(false);

  const { data: user, isFetching } = useSelector(userMpEditByIdSelector);
  const { isFetching: isUpdating, isFetched: isUpdated } = useSelector(userMpEditUpdateSelector);

  const {
    validationResult: validationResultProfile,
    isValid: isValidProfile,
    validate: validateProfile,
  } = useValidation<MpUserData>({
    object: user,
    validateOnChange: validateOnChangeProfile,
    rules: validationRulesProfile,
  });

  const onChangeProfileAttribute = (name: keyof MpUserData, value: any) => {
    dispatch(userMpEditSetAttribute({ name, value }));
  };

  const onNextStep = () => {
    if (step === EUserMpEditStep.Profile) {
      const isValidObject = validateProfile();
      if (!isValidObject) {
        setValidateOnChangeProfile(true);
        return;
      }
    }
  };

  const onClose = () => {
    history.goBack();
  };

  const onSave = () => {
    const isValidObject = validateProfile();
    if (!isValidObject) {
      setValidateOnChangeProfile(true);
      return;
    }

    dispatch(userMpEditUpdate(user!));
  };

  const onPanelAction = (action: PanelAction<UserMPEditActionType>) => {
    const { type } = action;
    switch (type) {
      case EUserMPActionType.Save:
        onSave();
        break;
    }
  };

  useEffect(() => {
    if (isUpdated) {
      Notifier.getInstance().addNotice(ENoticeStatus.Success, `Данные профиля успешно сохранены`);
      onAfterSave?.();
      onClose();
    }
  }, [isUpdated]);

  useEffect(() => {
    dispatch(userMpEditByIdFetch(id));

    return () => {
      dispatch(userMpEditStateReset());
    };
  }, [dispatch, id]);

  const activeStep = userMpEditSteps.findIndex(item => item.key === step);
  const mpStep = userMpEditSteps.find(item => item.key === step) ?? userMpEditSteps[0];

  const nextStepIndex = Math.min(activeStep + 1, userMpEditSteps.length - 1);
  const isLastStep = activeStep === nextStepIndex;

  const actions: PanelActions<UserMPEditActionType> = isLastStep
    ? [
        {
          type: EUserMPActionType.Save,
          label: getUserMPActionName(EUserMPActionType.Save),
          primary: true,
          position: [EPanelActionPosition.Default],
        },
      ]
    : [];

  const { adapter: navAdapter, actions: navActions } = useNavAdapter({
    openPrevStep: () => {},
    currentStepIndex: activeStep,
    openNextStep: onNextStep,
    stepsCount: userMpEditSteps.length,
  });

  const actionsPanel = (
    <MasterActionsComponent<UserMPEditActionType>
      actions={actions}
      show={!!actions.length || !!navActions.length}
      navAdapter={navAdapter}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );

  return (
    <Fade in>
      <ContainerWrapper>
        <StepperContainer<EUserMpEditStep>
          step={mpStep}
          steps={userMpEditSteps}
        />
        <ContentContainer>
          <DefaultContentWrapper
            type='details'
            stickyHeader
            fullHeight
            footer={actionsPanel}
          >
            <DefaultHeader
              sticky
              headline={<AppBreadcrumbs />}
              onClose={onClose}
            >
              <TitleWrapper>
                <Typography variant='h2'>Редактирование профиля</Typography>
              </TitleWrapper>
            </DefaultHeader>
            {user && (
              <UserMpEdit
                step={step}
                user={user}
                validationProfile={validationResultProfile}
                validationRulesProfile={validationRulesProfile}
                isValidProfile={isValidProfile}
                onChangeProfileAttribute={onChangeProfileAttribute}
              />
            )}
          </DefaultContentWrapper>
        </ContentContainer>
        {(isFetching || isUpdating) && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
      </ContainerWrapper>
    </Fade>
  );
};

export default UserMpEditContainer;
