import { ECmsContainerType, ECmsLinkObjectType } from '../../../../../domain/model/enums';
import { ValidationRules } from '../../../../utils/validation';
import { CmsContainerView, CmsLinkedObject, CmsLinkedObjectsTypes } from '../../types';
import {
  cmsBannerValidationRules,
  cmsCollectionValidationRules,
  cmsContainerViewResourcesSizeValidationRules,
  cmsContainerViewValidationRules,
} from '../../validation';

export const getCmsLinkedObjectValidationRules = (
  linkedObject: CmsLinkedObject
): ValidationRules<CmsLinkedObjectsTypes> => {
  switch (linkedObject.type) {
    case ECmsLinkObjectType.Banner:
      return cmsBannerValidationRules;
    case ECmsLinkObjectType.Collection:
      return cmsCollectionValidationRules;
  }
};
export const getCmsContainerViewValidationRules = (
  cmsContainer: CmsContainerView
): ValidationRules<CmsContainerView> => {
  switch (cmsContainer.type?.code) {
    case ECmsContainerType.Banner1Resizable:
    case ECmsContainerType.Banner3Resizable:
    case ECmsContainerType.Banner1LinkResizable:
      return { ...cmsContainerViewValidationRules, ...cmsContainerViewResourcesSizeValidationRules };
    default:
      return cmsContainerViewValidationRules;
  }
};
