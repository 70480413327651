import { Typography } from '@mui/material';
import styled from '@emotion/styled';

export const Wrapper = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;

  gap: ${p => (p.gap ? p.theme.spacing(p.gap) : 0)};
`;

export const ContentWrapper = styled(Typography)`
  display: flex;
  flex-direction: column;
  align-items: baseline;

  gap: ${p => p.theme.spacing()};
` as typeof Typography;
