import styled from '@emotion/styled';

export const ContainerWrapper = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  height: 100%;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  margin-bottom: ${p => p.theme.spacing(2)};
`;

export const FooterActionsWrapper = styled.div`
  border-top: 1px solid ${p => p.theme.palette.secondary.main};

  padding: ${p => p.theme.spacing(2)};
`;
