import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable } from '../../../../../domain/model';
import { CorpOffer } from '../../../../../domain/model/corpOffer';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { PaginationSize } from '../../../../types';
import offerServices from '../../../general/offer/services';
import { AllProps } from '../../../general/offer/services/corpOffers';
import { EOfferTableTab } from '../../../general/offer/types';
import { OfferTableTabsCounter } from '../../../general/offer/utils/table';
import { CorpOfferTableFilterValues } from '../../filterUtils';
import { ECorpOfferTableColumn } from '../../types';

const defaultSort = `${ECorpOfferTableColumn.Name},asc`;

export type CorpOffersSearchFetchProps = Omit<AllProps, 'signal'>;

export type CorpOffersSearchCountsFetchProps = CorpOffersSearchFetchProps & {
  readonly tabs: EOfferTableTab[];
};

export const corpOffersSearchCountsFetch = createAsyncThunk<
  OfferTableTabsCounter,
  CorpOffersSearchCountsFetchProps,
  AppThunkAPIConfig
>('corpOffer/search/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await offerServices.corp.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOffersSearchFetch = createAsyncThunk<
  Pageable<CorpOffer>,
  CorpOffersSearchFetchProps,
  AppThunkAPIConfig
>('corpOffer/search/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    return await offerServices.corp.all({ ...props, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface CorpOfferSearchState extends Fetchable, Pageable<CorpOffer> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
    readonly partnerId: Nullable<UUID>;
  };
  readonly tab: EOfferTableTab;
  readonly filter: CorpOfferTableFilterValues;
  readonly tabsCounter: OfferTableTabsCounter;
  readonly selected: CorpOffer[];
}

type Reducer<T = undefined> = CaseReducer<CorpOfferSearchState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CorpOfferSearchState> {
  corpOffersSearchStartSession: Reducer<{ guid: UUID; selected: CorpOffer[] }>;
  corpOffersSearchSetTab: Reducer<{ tab: EOfferTableTab }>;
  corpOffersSearchSetSearch: Reducer<{ sort: string; pageSize: PaginationSize }>;
  corpOffersSearchSortReset: Reducer;
  corpOffersSearchSetSort: Reducer<{ sort: string }>;
  corpOffersSearchSetPage: Reducer<{ pageNumber: number }>;
  corpOffersSearchSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  corpOffersSearchSetFilter: Reducer<CorpOfferTableFilterValues>;
  corpOffersSearchDataReset: Reducer;
  corpOffersSearchNeedRefreshWatcherReset: Reducer;
  corpOffersSearchSelect: Reducer<CorpOffer>;
  corpOffersSearchUnselect: Reducer<CorpOffer>;
  corpOffersSearchAllSelect: Reducer<Nullable<number>>;
  corpOffersSearchAllUnselect: Reducer;
}

const slice = createSlice<CorpOfferSearchState, Reducers, 'search'>({
  name: 'search',
  initialState: {
    ...fetchableDefault,
    guid: null,
    data: [],
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    needRefreshWatcher: 0,
    search: {
      sort: defaultSort,
      pageSize: 10,
      partnerId: null,
    },
    tab: EOfferTableTab.Active,
    filter: {},
    tabsCounter: {},
    selected: [],
  },
  reducers: {
    corpOffersSearchStartSession: (state, { payload }) => {
      const { guid, selected } = payload;
      if (guid !== state.guid) {
        state.guid = guid;

        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = false;

        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;
        state.needRefreshWatcher = 0;
        state.search = {
          sort: defaultSort,
          pageSize: 10,
          partnerId: null,
        };
        state.tab = EOfferTableTab.Active;
        state.filter = {};
        state.tabsCounter = {};
        state.selected = selected;
      }
    },
    corpOffersSearchSetTab: (state, { payload }) => {
      const { tab } = payload;

      if (state.tab !== tab) {
        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
      }

      state.needRefreshWatcher++;
    },
    corpOffersSearchSetSearch: (state, { payload }) => {
      const { sort, pageSize } = payload;

      state.search = {
        ...state.search,
        sort,
        pageSize,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    corpOffersSearchSortReset: (state, { payload }) => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    corpOffersSearchSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    corpOffersSearchSetPage: (state, { payload }) => {
      const { pageNumber } = payload;

      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    corpOffersSearchSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;

      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    corpOffersSearchSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    corpOffersSearchDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
      state.selected = [];
    },
    corpOffersSearchNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
    corpOffersSearchSelect: (state, { payload }) => {
      const selected = [...state.selected];
      const existedIndex = selected.findIndex(s => s.id === payload.id);
      if (existedIndex !== -1) {
        selected.splice(existedIndex, 1);
      }
      state.selected = [...selected, payload];
    },
    corpOffersSearchUnselect: (state, { payload }) => {
      const existedIndex = state.selected.findIndex(s => s.id === payload.id);
      if (existedIndex !== -1) {
        state.selected.splice(existedIndex, 1);
      }
    },
    corpOffersSearchAllSelect: (state, { payload }) => {
      if (payload) {
        state.selected = [...state.data.slice(0, payload)];
      } else {
        state.selected = state.data;
      }
    },
    corpOffersSearchAllUnselect: state => {
      state.selected = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(corpOffersSearchFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;

        if (partnerId !== state.search.partnerId) {
          state.data = [];
          state.tabsCounter = {};
        }
        state.search.partnerId = partnerId;
      })
      .addCase(corpOffersSearchFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(corpOffersSearchFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(corpOffersSearchCountsFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        if (partnerId !== state.search.partnerId) {
          state.tabsCounter = {};
        }
      })
      .addCase(corpOffersSearchCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(corpOffersSearchCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      });
  },
});

export const {
  corpOffersSearchStartSession,
  corpOffersSearchSetTab,
  corpOffersSearchSetSearch,
  corpOffersSearchSortReset,
  corpOffersSearchSetSort,
  corpOffersSearchSetPage,
  corpOffersSearchSetFilter,
  corpOffersSearchSetPageSize,
  corpOffersSearchDataReset,
  corpOffersSearchNeedRefreshWatcherReset,
  corpOffersSearchSelect,
  corpOffersSearchUnselect,
  corpOffersSearchAllSelect,
  corpOffersSearchAllUnselect,
} = slice.actions;

export default slice.reducer;
