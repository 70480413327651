import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable } from '../../../../../domain/model';
import { Team } from '../../../../../domain/model/team';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { PaginationSize } from '../../../../types';
import { TeamTableFilterValues } from '../../filterUtils';
import teamServices from '../../services';
import { AllProps } from '../../services/common';
import { ETeamTableColumn, ETeamTableTab, TeamTableTabsCounter } from '../utils';

const defaultSort = `${ETeamTableColumn.Name},asc`;

export type TeamsFetchProps = Omit<AllProps, 'signal'>;

type TeamsCountsFetchProps = TeamsFetchProps & {
  readonly tabs: ETeamTableTab[];
};

export const teamsFetch = createAsyncThunk<Pageable<Team>, TeamsFetchProps, AppThunkAPIConfig>(
  'team/list/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await teamServices.common.all({ ...props, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const teamsCountsFetch = createAsyncThunk<TeamTableTabsCounter, TeamsCountsFetchProps, AppThunkAPIConfig>(
  'team/list/counts/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      const { counts, errors } = await teamServices.common.countsByTabs({ ...props, signal });

      if (errors.length > 0) {
        console.error(errors.join('\n'));
      }

      return counts;
    } catch (e: any) {
      console.error(e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface TeamListState extends Fetchable, Pageable<Team> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  search: {
    sort: string;
    pageSize: PaginationSize;
  };
  readonly tab: Nullable<ETeamTableTab>;
  readonly filter: TeamTableFilterValues;
  readonly tabsCounter: TeamTableTabsCounter;
}

type Reducer<T = undefined> = CaseReducer<TeamListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<TeamListState> {
  teamsStartSession: Reducer<{ guid: UUID }>;
  teamsSetTab: Reducer<{ tab: ETeamTableTab }>;
  teamsSetFilter: Reducer<TeamTableFilterValues>;
  teamsSetPage: Reducer<{ pageNumber: number }>;
  teamsSetSort: Reducer<{ sort: string }>;
  teamsSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  teamsDataReset: Reducer;
  teamsSortReset: Reducer;
  teamsNeedRefreshWatcherReset: Reducer;
}

const slice = createSlice<TeamListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    ...fetchableDefault,
    guid: null,
    data: [],
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    needRefreshWatcher: 0,
    search: {
      sort: defaultSort,
      pageSize: 15,
    },
    tab: null,
    filter: {},
    tabsCounter: {},
  },
  reducers: {
    teamsStartSession: (state, { payload }) => {
      const { guid } = payload;
      if (guid !== state.guid) {
        state.guid = guid;
        state.needRefreshWatcher = 0;
        state.tabsCounter = {};

        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;

        state.tab = null;
      }
    },
    teamsSetTab: (state, { payload: { tab } }) => {
      // сбрасываем только пейджинг, если поменялся таб (сортировку смысла нет - набор полей одинаков)
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    teamsSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    teamsSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    teamsDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
    },
    teamsSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    teamsSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    teamsSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    teamsNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(teamsFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
      })
      .addCase(teamsFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(teamsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(teamsCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(teamsCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      });
  },
});

export const {
  teamsStartSession,
  teamsSetTab,
  teamsSetFilter,
  teamsSetPage,
  teamsSetPageSize,
  teamsSetSort,
  teamsDataReset,
  teamsSortReset,
  teamsNeedRefreshWatcherReset,
} = slice.actions;

export default slice.reducer;
