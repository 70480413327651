import Api from '../../../../data/api';
import { ApiCancellable } from '../../../../data/api/types';
import { OfferLink } from '../../../../domain/model';
import { pageSizeAll } from '../../../../domain/model/constants';
import { DataFilterQueryDslOperator } from '../../../../domain/model/filter';
import { ApprovalOfferRegistry, ApprovalOfferRegistryRequest } from '../../../../domain/model/offerApprovalRegistry';
import { Nullable, UUID } from '../../../../domain/model/types';

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type AllProps = ApiCancellable & {
  readonly registryNumber?: Nullable<string>;
};

type GetOrCreate = {
  readonly data: ApprovalOfferRegistryRequest;
};

type SetOffersProps = {
  readonly id: UUID;
  readonly offers: OfferLink[];
};

export type ApprovalRegistryOfferServices = {
  readonly all: (props: AllProps) => Promise<ApprovalOfferRegistry[]>;
  readonly one: (props: OneProps) => Promise<ApprovalOfferRegistry>;
  readonly getOrCreate: (props: GetOrCreate) => Promise<ApprovalOfferRegistry>;
  readonly setOffers: (props: SetOffersProps) => Promise<ApprovalOfferRegistry>;
};

const service: ApprovalRegistryOfferServices = {
  all: async props => {
    const { registryNumber, signal } = props;

    const querydsl = [
      {
        field: 'number',
        operator: DataFilterQueryDslOperator.Like,
        value: [`*${registryNumber}*`],
      },
    ];

    if (registryNumber) {
      const { data } = await Api.offerApprovalRegistry.all({
        sort: 'number,asc',
        pageSize: pageSizeAll,
        page: 1,
        querydsl,
        signal,
      });

      return data;
    } else {
      return [];
    }
  },
  one: async ({ id, signal }) => {
    return (await Api.offerApprovalRegistry.one({ id, signal })).data;
  },
  getOrCreate: async ({ data }) => {
    const existedApprovalRegistry = (
      await Api.offerApprovalRegistry.find({
        num: data.number,
        date: data.date,
      })
    ).data;
    if (existedApprovalRegistry) {
      return existedApprovalRegistry;
    }
    return (await Api.offerApprovalRegistry.create(data)).data;
  },
  setOffers: async ({ id, offers }) => {
    return (await Api.offerApprovalRegistry.setOffers({ id, offers })).data;
  },
};

export default service;
