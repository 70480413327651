export enum ESidePanelDataType {
  Playground = 'PLAYGROUND',
  Team = 'TEAM',
  EventApplicationFilter = 'EVENT_APPLICATION_FILTER',
  EventEdit = 'EVENT_EDIT',
  TeamLogoSelect = 'TEAM_LOGO_SELECT',
  TeamCreate = 'TEAM_CREATE',
  TeamEdit = 'TEAM_EDIT',
  SportsmanDetails = 'SPORTSMAN_DETAILS',
  ChatDetails = 'CHAT_DETAILS',
}
