import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../data/store/store';
import { EUserRole, EUserStatus } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { MpUser } from '../../../../../domain/model/user';
import { userMpSuggestionFetch } from './store/slice';

interface UserMpSuggestionResultParams {
  readonly onSearch: (name: Nullable<string>) => void;
  readonly isFetching: boolean;
  readonly users: Nullable<MpUser[]>;
}

interface UserMpSuggestionContainerProps {
  readonly roles?: EUserRole[];
  readonly statuses?: EUserStatus[];
  readonly children: (params: UserMpSuggestionResultParams) => React.ReactNode;
}

const UserMpSuggestionContainer = ({ roles, statuses, children }: UserMpSuggestionContainerProps) => {
  const dispatch = useAppDispatch();

  const [mpUsers, setMpUsers] = useState<Nullable<MpUser[]>>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [searchName, setSearchName] = useState<Nullable<string>>(null);

  const onSearch = (name: Nullable<string>) => {
    setSearchName(name);
  };

  useEffect(() => {
    setIsFetching(true);
    const promise = dispatch(userMpSuggestionFetch({ roles, statuses, name: searchName }));

    promise
      .unwrap()
      .then((result: MpUser[]) => {
        setIsFetching(false);
        setMpUsers(result);
      })
      .catch((e: any) => {
        if (e.message !== 'Aborted') {
          setIsFetching(false);
        }
        console.error(e);
      });

    return () => {
      promise?.abort();
    };
  }, [dispatch, roles, statuses, searchName]);

  return (
    <>
      {children({
        onSearch,
        isFetching,
        users: mpUsers,
      })}
    </>
  );
};

export default UserMpSuggestionContainer;
