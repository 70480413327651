import { useSelector } from 'react-redux';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../../hooks/useDataFilterAdapter';
import { EClientOrgUserTableFilterItem } from '../../filterUtils';
import { ClientOrgUserTable } from '../component';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  clientOrgUsersClientOrgUsersSelector,
  clientOrgUsersFilterSelector,
  clientOrgUsersGuidSelector,
  clientOrgUsersSortColumnSelector,
  clientOrgUsersSortDirectionSelector,
} from '../store/selectors';
import { EClientOrgUserTableColumn } from '../utils';
import { useClientOrgUserActions } from '@features/user/userClientOrg/actions/useActions';
import { useEffect } from 'react';

interface ClientOrgUsersTableAdapterProps {
  readonly filterAdapter: DataFilterAdapter<EClientOrgUserTableColumn, EClientOrgUserTableFilterItem>;
}

export const ClientOrgUsersTableAdapter = (props: ClientOrgUsersTableAdapterProps) => {
  const { filterAdapter } = props;

  const handlers = useContextHandlers();
  const guid = useSelector(clientOrgUsersGuidSelector);
  const sortColumn = useSelector(clientOrgUsersSortColumnSelector);
  const sortDirection = useSelector(clientOrgUsersSortDirectionSelector);
  const clientOrgUsers = useSelector(clientOrgUsersClientOrgUsersSelector);
  const filter = useSelector(clientOrgUsersFilterSelector);

  const {
    utils: {
      selectors: { isClientOrgUsersAnyChangedSelector },
    },
  } = useClientOrgUserActions();

  const needRefresh = useSelector(isClientOrgUsersAnyChangedSelector);

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  useEffect(() => {
    if (needRefresh > 0) {
      handlers.onRefresh();
    }
  }, [needRefresh, handlers.onRefresh]);

  return (
    <ClientOrgUserTable
      metadata={metadata}
      onChangeMetadata={onChangeMetadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      clientOrgUsers={clientOrgUsers}
      onRequestSort={handlers.onChangeSort}
    />
  );
};
