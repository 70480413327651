import { SportOptionTyped } from '../../../../../domain/model';
import { ECorpPersonalPromotionType } from '../../../../../domain/model/enums';
import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from './index';

export type CorpOfferTableCellPromotionTypeProps = CorpOfferTableCellCommonProps & {
  readonly corpPromotionTypes: SportOptionTyped<ECorpPersonalPromotionType>[];
};

const CorpOfferTableCellPromotionType = (props: CorpOfferTableCellPromotionTypeProps) => {
  const {
    corpOffer: { promotionType },
    corpPromotionTypes,
  } = props;

  const value = corpPromotionTypes.find(item => item.id === promotionType)?.name ?? '-';

  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellPromotionType;
