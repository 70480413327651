import { EUserStatus } from '../../../../../domain/model/enums';
import { MpUserShort } from '../../../../../domain/model/user';
import { DataTableColumns } from '../../../../components/common/table';
import { TableActionProcess } from '../../../../components/common/table/types';
import { AdminMpActions, AdminMpActionTableType, EAdminMpActionType, GetAdminMpActionsByPermissions } from '../types';
import { getAdminMpActionsConfigByPermissions } from '../utils';

export type AdminMpTableActionProcess = TableActionProcess<AdminMpActionTableType>;

export enum EAdminMpTableTab {
  Active = 'active',
  Deactivated = 'deactivated',
}

export type AdminMpTableTabsCounter = {
  [name in EAdminMpTableTab]?: number;
};

export enum EAdminMpTableColumn {
  Name = 'lastName',
  Phone = 'phone',
  Email = 'email',
}

export const adminMpTableColumns: DataTableColumns<EAdminMpTableColumn> = {
  [EAdminMpTableColumn.Name]: {
    title: 'Администратор',
    width: '25rem',
    wrap: true,
    sortable: true,
  },
  [EAdminMpTableColumn.Phone]: {
    title: 'Телефон',
    width: '15rem',
    sortable: true,
  },
  [EAdminMpTableColumn.Email]: {
    title: 'Электронная почта',
    width: '15rem',
    wrap: true,
    sortable: true,
  },
};

export const getAdminMpTableColumns = (tab: EAdminMpTableTab): DataTableColumns<EAdminMpTableColumn> => {
  const defaultColumns = getAdminMpTableDefaultColumns(tab);

  const allColumns: EAdminMpTableColumn[] = [];
  allColumns.push(EAdminMpTableColumn.Name, EAdminMpTableColumn.Phone, EAdminMpTableColumn.Email);

  return allColumns.reduce<DataTableColumns<EAdminMpTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...adminMpTableColumns[column],
        hidden: !defaultColumns.includes(column),
      },
    };
  }, {});
};

const adminMpTableDefaultColumns = [EAdminMpTableColumn.Name, EAdminMpTableColumn.Phone, EAdminMpTableColumn.Email];

const getAdminMpTableDefaultColumns = (tab: EAdminMpTableTab): EAdminMpTableColumn[] => {
  return adminMpTableDefaultColumns;
};

// фильтрация действий для таблицы админов по всем действиям
const filterAdminMpTableActions = (
  actions: AdminMpActions<EAdminMpActionType>
): AdminMpActions<AdminMpActionTableType> => {
  const tableActions: AdminMpActions<AdminMpActionTableType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EAdminMpActionType.Activate:
      case EAdminMpActionType.Deactivate:
        tableActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          position: action.position,
        });
        break;
      case EAdminMpActionType.Create:
      case EAdminMpActionType.Save:
      case EAdminMpActionType.Edit:
        break;
    }
  });

  return tableActions;
};

// получение возможных действий с админом для вкладки таблицы
const getAdminMpTableTabActionsConfig = (
  tab: EAdminMpTableTab,
  actions: AdminMpActions<AdminMpActionTableType>
): AdminMpActions<AdminMpActionTableType> => {
  switch (tab) {
    case EAdminMpTableTab.Active:
      return actions.filter(item => [EAdminMpActionType.Deactivate].includes(item.type));
    case EAdminMpTableTab.Deactivated:
      return actions.filter(item => [EAdminMpActionType.Activate].includes(item.type));
  }
};

export const getAdminMpTableTabActions = (
  permissions: GetAdminMpActionsByPermissions,
  tab: EAdminMpTableTab
): AdminMpActions<AdminMpActionTableType> => {
  const allowedActions = getAdminMpActionsConfigByPermissions(permissions);
  const actions = filterAdminMpTableActions(allowedActions);

  return getAdminMpTableTabActionsConfig(tab, actions);
};

export type AdminMpActionTableCall = {
  readonly action: AdminMpActionTableType;
  readonly admin: MpUserShort;
};

export const getAdminMpStatusesByTableTab = (tab: EAdminMpTableTab): EUserStatus[] => {
  return tab === EAdminMpTableTab.Deactivated ? [EUserStatus.Disabled] : [EUserStatus.Enabled];
};

export const getAdminMpTableTabName = (tab: EAdminMpTableTab): string => {
  switch (tab) {
    case EAdminMpTableTab.Active:
      return 'Активные';
    case EAdminMpTableTab.Deactivated:
      return 'Отключённые';
  }
};
