import { Fetchable, fetchableDefault, fetchableFetched, fetchableFetching } from '@/data/store/types';
import { ServerErrorResponse } from '@/data/network/types';
import { EClientOrgActionType } from '../../types';
import { ClientOrg, ClientOrgCreate } from '@/domain';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { AppThunkAPIConfig } from '@/data/store/store';
import ErrorHandler from '@/data/network/errorHandler';
import clientOrgService from '../../services';

const getActionProcess = (state: ClientOrgActionsState, id: UUID, actionType: EClientOrgActionType) => {
  let process = state.actions.find(change => change.id === id);
  if (process) return process;

  process = {
    ...fetchableDefault,
    id,
    type: actionType,
    error: null,
  };
  state.actions.push(process);

  return process;
};

export interface ClientOrgActionsState {
  readonly actions: (Fetchable & {
    id: UUID;
    type: EClientOrgActionType;
    error: Nullable<ServerErrorResponse>;
  })[];
  readonly create: Fetchable;
  readonly dialogs: {
    readonly block: Nullable<ClientOrg>;
    readonly unBlock: Nullable<ClientOrg>;
  };
}

type Reducer<T = undefined> = CaseReducer<ClientOrgActionsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ClientOrgActionsState> {
  clientOrgActionsChangeDialogState: Reducer<{
    name: keyof ClientOrgActionsState['dialogs'];
    data: Nullable<ClientOrg>;
  }>;
  clientOrgActionsOptimize: Reducer;
}

export const clientOrgActionsBlock = createAsyncThunk<ClientOrg, { id: UUID }, AppThunkAPIConfig>(
  'clientOrg/actions/block',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await clientOrgService.clientOrg.block({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue({
        error: e.response.data,
      });
    }
  }
);

export const clientOrgActionsUnBlock = createAsyncThunk<ClientOrg, { id: UUID }, AppThunkAPIConfig>(
  'clientOrg/actions/unBlock',
  async ({ id }, { getState, rejectWithValue }) => {
    try {
      return await clientOrgService.clientOrg.unblock({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue({
        error: e.response.data,
      });
    }
  }
);

export const clientOrgActionsSave = createAsyncThunk<ClientOrg, ClientOrgCreate, AppThunkAPIConfig>(
  'clientOrg/actions/save',
  async (clientOrg, { getState, rejectWithValue }) => {
    try {
      return await clientOrgService.clientOrg.save(clientOrg);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue({
        error: e.response.data,
      });
    }
  }
);

const slice = createSlice<ClientOrgActionsState, Reducers>({
  name: 'clientOrg/actions',
  initialState: {
    actions: [],
    create: fetchableDefault,
    dialogs: {
      block: null,
      unBlock: null,
    },
  },
  reducers: {
    clientOrgActionsChangeDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
    clientOrgActionsOptimize: state => {
      // Оставляем только выполняющиеся действия
      state.actions = state.actions.filter(action => action.isFetching);
      if (!state.create.isFetching) {
        state.create = fetchableDefault;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(clientOrgActionsSave.pending, (state, { meta }) => {
        const { id } = meta.arg;

        if (id) {
          const actionType = EClientOrgActionType.Edit;
          const process = getActionProcess(state, id, actionType);

          process.isFetching = true;
          process.isFetched = false;
          process.isFailed = false;

          process.id = id;
          process.type = actionType;
          process.error = null;
        } else {
          state.create = fetchableFetching;
        }
      })
      .addCase(clientOrgActionsSave.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        if (id) {
          const actionType = EClientOrgActionType.Edit;
          const process = getActionProcess(state, id, actionType);

          process.isFetching = false;
          process.isFetched = true;
          process.isFailed = false;

          process.id = id;
          process.type = actionType;
          process.error = null;
        } else {
          state.create = fetchableFetched;
        }
      })
      .addCase(clientOrgActionsSave.rejected, (state, { meta }) => {
        const { id } = meta.arg;

        if (id) {
          const actionType = EClientOrgActionType.Edit;
          const process = getActionProcess(state, id, actionType);
          process.isFetching = false;
          process.isFetched = false;
          process.isFailed = true;

          process.id = id;
          process.type = actionType;
        } else {
          state.create = fetchableFetched;
        }
      })
      .addCase(clientOrgActionsUnBlock.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EClientOrgActionType.UnBlock;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(clientOrgActionsUnBlock.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EClientOrgActionType.UnBlock;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(clientOrgActionsUnBlock.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EClientOrgActionType.UnBlock;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(clientOrgActionsBlock.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EClientOrgActionType.Block;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(clientOrgActionsBlock.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EClientOrgActionType.Block;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(clientOrgActionsBlock.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EClientOrgActionType.Block;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      });
  },
});

export const { clientOrgActionsChangeDialogState, clientOrgActionsOptimize } = slice.actions;

export default slice.reducer;
