import { UUID } from '../../../../../domain/model/types';
import { useContextConfig } from '../hooks/useContextConfig';
import usePartnerEmployeeTableSearch from '../hooks/useSearch';
import usePartnerEmployeeTableSession from '../hooks/useSession';
import { EPartnerEmployeeTableTab } from '../utils';

interface PartnerEmployeeTableBehaviorAdapterProps {
  readonly guid: UUID;
  readonly tab: EPartnerEmployeeTableTab;
}

export const PartnerEmployeeTableBehaviorAdapter = ({ guid, tab }: PartnerEmployeeTableBehaviorAdapterProps) => {
  const { partner } = useContextConfig();

  usePartnerEmployeeTableSession({ guid, tab });
  usePartnerEmployeeTableSearch({ tab, partnerId: partner.id });

  return null;
};
