import { useMemo } from 'react';
import { PartnerShort } from '../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../domain/model/types';
import { MpUser } from '../../../../domain/model/user';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import PartnerEmployeeTableContainer from './container';
import { EmployeeTableConfigContext } from './context';
import {
  EPartnerEmployeesFilterItem,
  getPartnerEmployeesFilterStrategy,
  partnerEmployeeFilterItems,
} from './filterUtils';
import { useEmployeeTableConfig } from './hooks/useEmployeeTableConfig';
import { EPartnerEmployeeTableColumn, EPartnerEmployeeTableTab, getPartnerEmployeeTableColumns } from './utils';

interface PartnerEmployeeTableEntryProps {
  readonly guid: UUID;
  readonly tab: Nullable<EPartnerEmployeeTableTab>;
  readonly partner: PartnerShort;
  readonly onEmployeeOpen: (employee: MpUser) => void;
}

const PartnerEmployeeTableEntry = ({ guid, tab, partner, onEmployeeOpen }: PartnerEmployeeTableEntryProps) => {
  const {
    accessMatrix: { partnerEmployees },
  } = useCurrentUser();

  const config = useEmployeeTableConfig({ partner, onEmployeeOpen });
  const { defaultTab } = config;

  const outTab = tab ?? defaultTab;

  // получаем максимально возможный состав колонок
  const sourceColumns = useMemo(() => getPartnerEmployeeTableColumns(outTab), [outTab]);

  // готовим адаптер для хука
  const adapter = useMemo<DataFilterAdapter<EPartnerEmployeeTableColumn, EPartnerEmployeesFilterItem>>(
    () => ({
      key: 'partnerEmployee',
      keyPartitions: [outTab],
      sourceColumns,
      filterItems: partnerEmployeeFilterItems,
      requiredFilters: [EPartnerEmployeesFilterItem.Query],
      getDataFilterStrategy: getPartnerEmployeesFilterStrategy,
    }),
    [outTab, sourceColumns]
  );

  return (
    <EmployeeTableConfigContext.Provider value={config}>
      <PartnerEmployeeTableContainer
        guid={guid}
        tab={outTab}
        filterAdapter={adapter}
        canCreate={partnerEmployees.create}
        canChangeStatus={partnerEmployees.changeStatus}
        canChangePartnerOwner={partnerEmployees.changeResponsible}
      />
    </EmployeeTableConfigContext.Provider>
  );
};

export default PartnerEmployeeTableEntry;
