import React, { Suspense } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { SIDE_PANEL_PARAM_NAME } from '../../../../routing/constants';
import { ContentLoaderSimple } from '../../../components/common/loader';
import SideContentWrapper from '../../../components/common/side';
import { getChatDetailsTitle } from '../../chat/details/utils';
import { getEventEditTitle } from '../../event/edit/legacy/utils';
import { getTournamentApplicationFilterTitle } from '../../event/tournamentApplication/filter/utils';
import { getPlaygroundDetailsTitle } from '../../playground/details/utils';
import { getSportsmanDetailsTitle } from '../../sportsman/details/utils';
import { getTeamCreateTitle } from '../../team/create/utils';
import { getTeamDetailsTitle } from '../../team/details/utils';
import { getTeamEditTitle } from '../../team/edit/utils';
import { getTeamLogoSelectTitle } from '../../team/logos/utils';
import { Drawer } from './controls';
import { ESidePanelDataType } from './enums';

const PlaygroundDetailsContainer = React.lazy(() => import('../../playground/details/container'));

const TeamDetailsContainer = React.lazy(() => import('../../team/details/container'));
const TeamLogoSelectContainer = React.lazy(() => import('../../team/logos/container'));
const TeamCreateContainer = React.lazy(() => import('../../team/create/container'));
const TeamEditContainer = React.lazy(() => import('../../team/edit/container'));

const EventApplicationFilterContainer = React.lazy(() => import('../../event/tournamentApplication/filter/container'));
const EventEditContainer = React.lazy(() => import('../../event/edit/legacy/container'));

const SportsmanDetailsContainer = React.lazy(() => import('../../sportsman/details/container'));

const ChatDetailsContainer = React.lazy(() => import('../../chat/details/container'));

const SideContainer = () => {
  const location = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);
  const side = searchParams.get(SIDE_PANEL_PARAM_NAME);
  const sidePanelParams = side ? JSON.parse(decodeURI(side)) : null;

  const open = !!sidePanelParams;
  const { type, guid, parent, parentSide, ...params } = sidePanelParams || {};

  if (!type) return null;

  let Component = null;
  let title = null;

  // TODO придумать как разнести по фичам эту партянку

  switch (type) {
    case ESidePanelDataType.Playground:
      Component = PlaygroundDetailsContainer;
      title = getPlaygroundDetailsTitle(params);
      break;

    case ESidePanelDataType.Team:
      Component = TeamDetailsContainer;
      title = getTeamDetailsTitle();
      break;

    case ESidePanelDataType.TeamLogoSelect:
      Component = TeamLogoSelectContainer;
      title = getTeamLogoSelectTitle();
      break;

    case ESidePanelDataType.TeamCreate:
      Component = TeamCreateContainer;
      title = getTeamCreateTitle();
      break;

    case ESidePanelDataType.TeamEdit:
      Component = TeamEditContainer;
      title = getTeamEditTitle();
      break;

    case ESidePanelDataType.EventApplicationFilter:
      Component = EventApplicationFilterContainer;
      title = getTournamentApplicationFilterTitle();
      break;

    case ESidePanelDataType.EventEdit:
      Component = EventEditContainer;
      title = getEventEditTitle();
      break;

    case ESidePanelDataType.SportsmanDetails:
      Component = SportsmanDetailsContainer;
      title = getSportsmanDetailsTitle();
      break;

    case ESidePanelDataType.ChatDetails:
      Component = ChatDetailsContainer;
      title = getChatDetailsTitle({ guid });
      break;

    default:
      throw new Error(`Unknown type ${type}`);
  }

  const onBack = () => {
    // TODO если заши по ссылке то goBack приведет в пустоту, надо prevlocation отслеживать, причем сейчас prevlocation сравнивается по pathname, в случае сайд-панели не обойтись без доделки, потому что она встраивается в url
    history.goBack();
  };

  return (
    <Drawer
      open={open}
      anchor='right'
      ModalProps={{
        onBackdropClick: onBack,
        onClose: (event, reason) => {
          if (reason === 'escapeKeyDown') onBack();
        },
      }}
    >
      <SideContentWrapper
        onClose={!parentSide && onBack}
        onBack={parentSide && onBack}
        title={title}
      >
        <Suspense fallback={<ContentLoaderSimple />}>
          <Component
            parent={parent}
            guid={guid}
            onClose={onBack}
            {...params}
          />
        </Suspense>
      </SideContentWrapper>
    </Drawer>
  );
};

export default SideContainer;
