import { ApiCancellable, ApiQueryDsl, ApiRequestListDiscriminator, ApiRequestPageable } from '@/data/api/types';
import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '@/data/api/utils';
import { EOfferStatus, Pageable, SportOption, TradeOffer, XLSXImportResult } from '@/domain';
import Axios from 'axios';
import Api from '../../../../../data/api';
import { PaginationSize } from '../../../../types';
import { convertTargetToTargetRequest } from '../../../../utils/target';
import { ETradeOfferTableFilterItem, TradeOfferTableFilterValues } from '../../../tradeOffer/filterUtils';
import { isTradeOfferDraftSavable } from '../../../tradeOffer/utils';
import { EOfferTableTab, OfferCounterByStatus } from '../types';
import { getStatesFilterForOfferTableTab, OfferTableTabsCounter } from '../utils/table';

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string>;
    readonly statuses: EOfferStatus[];
    readonly partnerId: Nullable<UUID>;
    readonly categories: Nullable<UUID[]>;
  };
  readonly filter: TradeOfferTableFilterValues;
  readonly pageNumber: number;
};

type BuildListQueryParamsReturn<D extends ApiRequestListDiscriminator> = ApiCancellable &
  ApiRequestPageable & {
    readonly partnerId: Nullable<UUID>;
    readonly statuses?: Nullable<EOfferStatus[]>;
    readonly categories?: Nullable<UUID[]>;
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

type SaveProps = TradeOffer;

type UnwatchedProps = Omit<AllProps, 'pageNumber'>;

type CommandProps = {
  readonly id: UUID;
};

type InWorkProps = CommandProps & {
  readonly userId: UUID;
};

type RetrieveProps = CommandProps & {
  readonly endDate: string;
};

type RejectProps = CommandProps & {
  readonly reason: SportOption;
  readonly comment?: string;
};

type ImportProps = CommandProps & {
  readonly file: File;
};

type PauseProps = {
  readonly id: UUID;
  readonly reason?: SportOption;
  readonly comment?: string;
};

type DuplicateProps = {
  readonly id: UUID;
};

type ReturnToVerificationProps = {
  readonly id: UUID;
  readonly userId: UUID;
};

type CountsByStatusesProps = Omit<AllProps, 'pageNumber'> & {
  readonly viewed?: boolean;
};

export type CountProps = Omit<AllProps, 'pageNumber'> & {
  readonly viewed?: boolean;
};

type CountsByTabsProps = CountsByStatusesProps & {
  readonly tabs: EOfferTableTab[];
};

export type TradeOfferService = {
  readonly one: (props: OneProps) => Promise<TradeOffer>;
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps
  ) => BuildListQueryParamsReturn<D>;
  readonly all: (props: AllProps) => Promise<Pageable<TradeOffer>>;
  readonly unwatched: (props: UnwatchedProps) => Promise<TradeOffer[]>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly countsByStatuses: (
    props: CountsByStatusesProps
  ) => Promise<{ counts: OfferCounterByStatus; errors: Array<string> }>;
  readonly countsByTabs: (
    props: CountsByTabsProps
  ) => Promise<{ counts: OfferTableTabsCounter; errors: Array<string> }>;
  readonly delete: (props: CommandProps) => Promise<void>;
  readonly approve: (props: CommandProps) => Promise<TradeOffer>;
  readonly resume: (props: CommandProps) => Promise<TradeOffer>;
  readonly pause: (props: PauseProps) => Promise<TradeOffer>;
  readonly archive: (props: CommandProps) => Promise<TradeOffer>;
  readonly inWork: (props: InWorkProps) => Promise<TradeOffer>;
  readonly reject: (props: RejectProps) => Promise<TradeOffer>;
  readonly publish: (props: CommandProps) => Promise<TradeOffer>;
  readonly unPublish: (props: CommandProps) => Promise<TradeOffer>;
  readonly retrieve: (props: RetrieveProps) => Promise<TradeOffer>;
  readonly save: (props: SaveProps) => Promise<TradeOffer>;
  readonly saveNewVersion: (props: SaveProps) => Promise<TradeOffer>;
  readonly import: (props: ImportProps) => Promise<XLSXImportResult>;
  readonly duplicate: (props: DuplicateProps) => Promise<TradeOffer>;
  readonly returnToVerification: (props: ReturnToVerificationProps) => Promise<TradeOffer>;
};

const service: TradeOfferService = {
  one: async ({ id, signal }) => {
    return (await Api.tradeOffer.one({ id, signal })).data;
  },
  buildListQueryParams: props => {
    const { search, filter, pageNumber, signal } = props;
    const { pageSize, sort, statuses, categories, partnerId } = search;

    const query = filter[ETradeOfferTableFilterItem.Query]?.value;
    const querydsl = getQueryDslByDataFilterValues(filter);
    const target = filter[ETradeOfferTableFilterItem.Target]?.value;

    return {
      query,
      sort,
      signal,
      pageSize,
      partnerId,
      statuses,
      categories,
      page: pageNumber,
      querydsl,
      target: target && convertTargetToTargetRequest(target),
    };
  },
  all: async props => {
    const response = await Api.tradeOffer.all(service.buildListQueryParams(props));
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);

    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
  unwatched: async props => {
    const { data: tradeOffers } = await Api.tradeOffer.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      pageSize: 99999,
      viewed: false,
    });
    return tradeOffers;
  },
  count: async ({ viewed, ...props }) => {
    const { data: response } = await Api.tradeOffer.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      discriminator: ApiRequestListDiscriminator.Count,
      viewed,
    });

    return response[0].count;
  },
  countsByStatuses: async ({ viewed, signal, ...props }) => {
    const {
      search: { statuses },
    } = props;

    const errors: string[] = [];
    const counts: OfferCounterByStatus = {};

    const requests = statuses.map(status => {
      const params = {
        ...props,
        search: { ...props.search, statuses: [status] },
      };
      return service.count({ ...params, viewed, signal });
    });

    const responses = await Promise.allSettled(requests);
    const parseResponse = (response: (typeof responses)[0], status: EOfferStatus) => {
      if (response.status === 'fulfilled') {
        counts[status] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество торговых предложений '${status}': ${response.reason}`);
        }
      }
    };
    statuses.forEach((status, index) => parseResponse(responses[index], status));

    return { counts, errors };
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: OfferTableTabsCounter = {};

    const requests = tabs.map(tab => {
      const statuses = getStatesFilterForOfferTableTab(tab, []);
      const params = {
        ...props,
        search: { ...props.search, statuses },
      };
      return service.countsByStatuses({ ...params, signal });
    });
    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], tab: EOfferTableTab) => {
      if (response.status === 'fulfilled') {
        const statuses = getStatesFilterForOfferTableTab(tab, []);
        counts[tab] = statuses.reduce<number>((prev, current) => prev + (response.value.counts?.[current] ?? 0), 0);
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество торговых предложений '${tab}': ${response.reason}`);
        }
      }
    };
    tabs.forEach((tab, index) => parseResponse(responses[index], tab));

    return { counts, errors };
  },
  delete: async ({ id }) => {
    await Api.tradeOffer.delete({ id });
  },
  resume: async ({ id }) => {
    return (await Api.tradeOffer.resume({ id })).data;
  },
  approve: async ({ id }) => {
    return (await Api.tradeOffer.approve({ id })).data;
  },
  pause: async ({ id, comment, reason }) => {
    return (await Api.tradeOffer.pause({ id, type: reason, comment })).data;
  },
  archive: async ({ id }) => {
    return (await Api.tradeOffer.archive({ id })).data;
  },
  inWork: async ({ id, userId }) => {
    return (await Api.tradeOffer.inWork({ id, userId })).data;
  },
  reject: async ({ id, reason, comment }) => {
    return (await Api.tradeOffer.reject({ id, rejectId: reason.id, comment })).data;
  },
  publish: async ({ id }) => {
    return (await Api.tradeOffer.publish({ id })).data;
  },
  unPublish: async ({ id }) => {
    return (await Api.tradeOffer.unPublish({ id })).data;
  },
  retrieve: async ({ id, endDate }) => {
    return (await Api.tradeOffer.retrieve({ id, endDate })).data;
  },
  save: async data => {
    const { id, status } = data;

    if (id) {
      if (isTradeOfferDraftSavable(status)) {
        return (await Api.tradeOffer.saveUnpublished({ id, data })).data;
      } else {
        return (await Api.tradeOffer.save({ id, data })).data;
      }
    } else {
      return (await Api.tradeOffer.create({ data })).data;
    }
  },
  saveNewVersion: async data => {
    const { id } = data;
    return (await Api.tradeOffer.saveNewVersion({ id, data })).data;
  },
  duplicate: async ({ id }) => {
    return (await Api.tradeOffer.duplicate({ id })).data;
  },
  import: async ({ id, file }) => {
    return (await Api.tradeOffer.import({ id, file })).data;
  },
  returnToVerification: async ({ id, userId }) => {
    return (await Api.tradeOffer.inWork({ id, userId })).data;
  },
};

export default service;
