import { useSelector } from 'react-redux';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { ESportsmansTableFilterItem } from '../../filterUtils';
import { ESportsmanTableColumn } from '../../table/utils';
import SportsmansSearch from '../component';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  sportsmanSearchFilterSelector,
  sportsmanSearchGuidSelector,
  sportsmanSearchSelectedSelector,
  sportsmanSearchSortColumnSelector,
  sportsmanSearchSortDirectionSelector,
  sportsmanSearchSportsmenSelector,
} from '../store/selectors';

interface SportsmansSearchTableAdapterProps {
  readonly filterAdapter: DataFilterAdapter<ESportsmanTableColumn, ESportsmansTableFilterItem>;
}

const SportsmanSearchTableAdapter = (props: SportsmansSearchTableAdapterProps) => {
  const { filterAdapter } = props;

  const handlers = useContextHandlers();
  const { isSingleSelect } = useContextConfig();

  const guid = useSelector(sportsmanSearchGuidSelector);
  const sportsmen = useSelector(sportsmanSearchSportsmenSelector);
  const sortColumn = useSelector(sportsmanSearchSortColumnSelector);
  const sortDirection = useSelector(sportsmanSearchSortDirectionSelector);
  const filter = useSelector(sportsmanSearchFilterSelector);
  const selectedSportsman = useSelector(sportsmanSearchSelectedSelector);
  const selectedSportsmanCount = selectedSportsman?.length ?? 0;

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return (
    <SportsmansSearch
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      sportsmen={sportsmen}
      selectedSportsman={selectedSportsman}
      isSingleSelect={isSingleSelect}
      onSportsmanSelect={(sportsman, selected) =>
        handlers.onSportsmanSelect(sportsman, selectedSportsmanCount, selected)
      }
      onRequestSort={handlers.onChangeSort}
      onSportsmanClick={sportsman => {
        handlers.onSportsmanSelect(sportsman, selectedSportsmanCount, true);
      }}
      onChangeMetadata={onChangeMetadata}
      onAllSportsmenSelect={handlers.onAllSportsmenSelect}
    />
  );
};

export default SportsmanSearchTableAdapter;
