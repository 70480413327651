import { EClientOrgStatus } from '@/domain/model/enums';
import { ClientOrgLifeCycleCondition } from '@features/clientOrg/lifecycle/types';

export const isActiveCondition: ClientOrgLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Находиться в активном состоянии'],
  call: ({ obj }) => {
    const { status } = obj;

    return status === EClientOrgStatus.Active;
  },
};

export const isPauseCondition: ClientOrgLifeCycleCondition = {
  description: 'Проверка по статусу',
  algorithm: ['Находиться в отключенном состоянии'],
  call: ({ obj }) => {
    const { status } = obj;

    return status === EClientOrgStatus.Blocked;
  },
};

export const isReadOnlyCondition: ClientOrgLifeCycleCondition = {
  description: 'Проверка по возможности',
  algorithm: ['Можно ли редактировать'],
  call: ({ obj }) => {
    const { readOnly } = obj;

    return !!readOnly;
  },
};
