import {
  CorpOffer,
  CorpOfferRequest,
  ECorpOfferDiscriminator,
  EOfferStatus,
  Nullable,
  OfferFieldAudit,
  OfferState,
  SportOption,
  TargetRequest,
  UUID,
  XLSXImportResult,
} from '@/domain';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { createCancelToken } from './index';
import {
  ApiCancellable,
  ApiQueryDsl,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
  ApiResponseMaBeCounters,
} from './types';
import { appendQueryDslParams, getComServicesEndpoint } from './utils';

type AllProps<D extends ApiRequestListDiscriminator> = ApiRequestPageable &
  ApiCancellable & {
    readonly statuses?: Nullable<EOfferStatus[]>;
    readonly startDate?: Nullable<string>;
    readonly endDate?: Nullable<string>;
    readonly partnerId?: Nullable<UUID>;
    readonly categories?: Nullable<UUID[]>;
    readonly viewed?: Nullable<boolean>;
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
    readonly target?: Nullable<TargetRequest>;
    readonly discriminator?: D;
  };

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type CreateProps = {
  readonly data: CorpOfferRequest;
};

type DuplicateProps = {
  readonly id: UUID;
};

type SaveProps = {
  readonly id: UUID;
  readonly data: CorpOfferRequest;
};

type SaveUnpublishedProps = {
  readonly id: UUID;
  readonly data: CorpOfferRequest;
};

type DeleteProps = {
  readonly id: UUID;
};

type PublishProps = {
  readonly id: UUID;
};

type UnPublishProps = {
  readonly id: UUID;
};

type ApproveProps = {
  readonly id: UUID;
};

type inWork = {
  readonly id: UUID;
  readonly userId: UUID;
};

type RejectProps = {
  readonly id: UUID;
  readonly rejectId: UUID;
  readonly comment?: string;
};

type PausedProps = {
  readonly id: UUID;
  readonly type?: SportOption;
  readonly comment?: string;
};

type ArchivedProps = {
  readonly id: UUID;
};

type ResumedProps = {
  readonly id: UUID;
};

type RetrieveProps = {
  readonly id: UUID;
};

type ImportProps = ApiCancellable & {
  readonly id: UUID;
  readonly file: File;
  readonly config?: AxiosRequestConfig;
};

type HistoryProps = ApiRequestPageable &
  ApiCancellable & {
    readonly id: UUID;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

type CorpOfferApi = {
  readonly all: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps<D>
  ) => Promise<AxiosResponse<ApiResponseMaBeCounters<D, CorpOffer[]>>>;
  readonly collection: (props: OneProps) => Promise<AxiosResponse<CorpOffer[]>>;
  readonly one: (props: OneProps) => Promise<AxiosResponse<CorpOffer>>;
  readonly create: (props: CreateProps) => Promise<AxiosResponse<CorpOffer>>;
  readonly duplicate: (props: DuplicateProps) => Promise<AxiosResponse<CorpOffer>>;
  readonly save: (props: SaveProps) => Promise<AxiosResponse<CorpOffer>>;
  readonly saveUnpublished: (props: SaveUnpublishedProps) => Promise<AxiosResponse<CorpOffer>>;
  readonly delete: (props: DeleteProps) => Promise<AxiosResponse>;
  readonly publish: (props: PublishProps) => Promise<AxiosResponse<CorpOffer>>;
  readonly unPublish: (props: UnPublishProps) => Promise<AxiosResponse<CorpOffer>>;
  readonly approve: (props: ApproveProps) => Promise<AxiosResponse<CorpOffer>>;
  readonly reject: (props: RejectProps) => Promise<AxiosResponse<CorpOffer>>;
  readonly inWork: (props: inWork) => Promise<AxiosResponse<CorpOffer>>;
  readonly pause: (props: PausedProps) => Promise<AxiosResponse<CorpOffer>>;
  readonly archive: (props: ArchivedProps) => Promise<AxiosResponse<CorpOffer>>;
  readonly resume: (props: ResumedProps) => Promise<AxiosResponse<CorpOffer>>;
  readonly retrieve: (props: RetrieveProps) => Promise<AxiosResponse<CorpOffer>>;
  readonly import: (props: ImportProps) => Promise<AxiosResponse<XLSXImportResult>>;
  readonly history: (props: HistoryProps) => Promise<AxiosResponse<OfferState[]>>;
  readonly fieldHistory: (props: HistoryProps) => Promise<AxiosResponse<OfferFieldAudit[]>>;
};

/**
 * АПИ по работе с торговыми предложениями
 */
const corpOffer: CorpOfferApi = {
  all: ({
    page,
    pageSize,
    sort,
    statuses,
    startDate,
    endDate,
    partnerId,
    categories,
    viewed,
    querydsl,
    query,
    target,
    discriminator,
    signal,
  }) => {
    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    statuses?.forEach((status: EOfferStatus) => params.append('status', status));
    categories?.forEach((category: UUID) => params.append('category', category));

    if (startDate) {
      params.append('startDate', startDate);
    }

    if (endDate) {
      params.append('endDate', endDate);
    }

    if (partnerId) {
      params.append('partnerId', partnerId);
    }

    if (viewed !== null && viewed !== undefined) {
      params.append('viewed', viewed.toString());
    }

    if (query) {
      params.append('q', query);
    }

    if (discriminator) {
      params.append('resultType', discriminator);
    }

    if (querydsl) {
      appendQueryDslParams(params, querydsl);
    }

    if (target) {
      params.append('targetFilter', JSON.stringify(target));
    }

    return axios.get(`${getComServicesEndpoint()}/corp-offers`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  collection: ({ id, signal }) => {
    return axios.get(`${getComServicesEndpoint()}/corp-offers/collections/${id}`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  one: ({ id }) => {
    return axios.get(`${getComServicesEndpoint()}/corp-offers/${id}`);
  },
  save: ({ id, data }) => {
    return axios.put(`${getComServicesEndpoint()}/corp-offers/${id}`, data);
  },
  create: ({ data }) => {
    return axios.post(`${getComServicesEndpoint()}/corp-offers/unpublished`, {
      ...data,
      discriminator: ECorpOfferDiscriminator.CorpOfferDataRequest,
    });
  },
  duplicate: ({ id }) => {
    return axios.post(`${getComServicesEndpoint()}/corp-offers/unpublished`, {
      id,
      discriminator: ECorpOfferDiscriminator.CreateDraftCorpOfferCommand,
    });
  },
  saveUnpublished: ({ id, data }) => {
    return axios.put(`${getComServicesEndpoint()}/corp-offers/unpublished/${id}`, data);
  },
  delete: ({ id }) => {
    return axios.delete(`${getComServicesEndpoint()}/corp-offers/unpublished/${id}`);
  },
  publish: ({ id }) => {
    return axios.patch(`${getComServicesEndpoint()}/corp-offers/${id}/publish`);
  },
  unPublish: ({ id }) => {
    return axios.patch(`${getComServicesEndpoint()}/corp-offers/${id}/unpublished`);
  },
  approve: ({ id }) => {
    return axios.patch(`${getComServicesEndpoint()}/corp-offers/${id}/approved`);
  },
  inWork: ({ id, userId }) => {
    const data = { userId };
    return axios.patch(`${getComServicesEndpoint()}/corp-offers/${id}/in_work`, data);
  },
  reject: data => {
    const { id, rejectId, comment } = data;
    return axios.patch(`${getComServicesEndpoint()}/corp-offers/${id}/rejected`, { rejectId, comment });
  },
  pause: ({ id, type, comment }) => {
    const data = {
      reasonId: type?.id,
      comment,
    };
    return axios.patch(`${getComServicesEndpoint()}/corp-offers/${id}/paused`, data);
  },
  archive: ({ id }) => {
    return axios.patch(`${getComServicesEndpoint()}/corp-offers/${id}/archived`);
  },
  resume: ({ id }) => {
    return axios.patch(`${getComServicesEndpoint()}/corp-offers/${id}/renewed`);
  },
  retrieve: ({ id }) => {
    return axios.patch(`${getComServicesEndpoint()}/corp-offers/${id}/retrieve`);
  },
  import: ({ id, file, config = {} }) => {
    const formData = new FormData();
    formData.append('partnerId', id);
    formData.append('file', file);
    const reqConfig: AxiosRequestConfig = {
      ...config,
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return axios.post(`${getComServicesEndpoint()}/corp-offers/unpublished/import`, formData, reqConfig);
  },
  history: ({ id, querydsl, page, pageSize, sort, signal }) => {
    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (querydsl) {
      appendQueryDslParams(params, querydsl);
    }

    return axios.get(`${getComServicesEndpoint()}/corp-offers/${id}/history`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  fieldHistory: ({ id, querydsl, page, pageSize, sort, signal }) => {
    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (querydsl) {
      appendQueryDslParams(params, querydsl);
    }

    return axios.get(`${getComServicesEndpoint()}/corp-offers/${id}/field-history`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
};

export default corpOffer;
