import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { Nullable } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { EPartnerTableTab } from '../../features/general/partner/types';
import { viewShowMainMenu } from '../../features/main/container/store/slice';
import { PartnersLocationState } from '../../features/partner/entry';
import PartnerTableEntry from '../../features/partner/table/entry';
import { EPartnersTableUrlParam } from '../../features/partner/table/utils';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const PartnersScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation<Nullable<PartnersLocationState>>();
  const { partners } = useCurrentUser().accessMatrix;

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(EPartnersTableUrlParam.Tab) as EPartnerTableTab;

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (!partners?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<PartnersLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <PartnerTableEntry
          guid={state.guid}
          tab={tab}
        />
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default PartnersScreen;
