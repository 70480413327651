import { Nullable, UUID } from '@/domain/model/types';
import { ContentLoaderSimple } from '@/presentation/components/common/loader';
import rootRouting from '@/routing';
import ClientOrgCreateScreen from '@screens/clientOrgCreate';
import ClientOrgEditScreen from '@screens/clientOrgEdit';
import NotFoundScreen from '@screens/notFound';
import { Location } from 'history';
import React, { Suspense } from 'react';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ClientOrgActionsProvider } from './actions/provider';
import ClientOrgManagementEntry, { getClientOrgManagementRootRouteDefinition } from './management/entry';
import { EClientOrgCreateUrlParam, EClientOrgStep, EClientOrgTableTab } from './types';

const ClientOrgsScreen = React.lazy(() => import('@screens/clientOrgs'));

const routing = {
  root: `${rootRouting.clientOrgs}`,
  list: `${rootRouting.clientOrgs}`,
  create: `${rootRouting.clientOrgs}/create`,
  details: `${rootRouting.clientOrgs}/details`,
  edit: `${rootRouting.clientOrgs}/:${EClientOrgCreateUrlParam.Id}/edit`,
};

export enum EClientOrgUrlParam {
  Tab = 'tab',
  Step = 'step',
}

export interface ClientOrgsLocationState {
  readonly guid: UUID;
}

interface GetClientOrgsTableRouteProps {
  readonly tab?: Nullable<EClientOrgTableTab>;
  readonly guid?: UUID;
}

export const getClientOrgsTableRoute = (props: GetClientOrgsTableRouteProps): Location<ClientOrgsLocationState> => {
  const { tab, guid } = props;

  const searchParams = new URLSearchParams();
  if (tab) {
    searchParams.append(EClientOrgUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return {
    pathname: routing.list,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

interface GetClientOrgCreateRouteProps {
  readonly location: any;
  readonly guid?: UUID;
  readonly step?: EClientOrgStep;
}

export const getClientOrgCreateRoute = ({ step, location, guid }: GetClientOrgCreateRouteProps) => {
  const searchParams = new URLSearchParams(location.search);
  const currentGuid = guid ?? uuidv4();

  if (step) {
    searchParams.set(EClientOrgUrlParam.Step, step);
  }
  searchParams.set(EClientOrgCreateUrlParam.Guid, currentGuid);

  return `${routing.create}?${searchParams.toString()}`;
};

interface GetClientOrgEditRouteProps {
  readonly id: UUID;
  readonly step?: EClientOrgStep;
}

export const getClientOrgEditRoute = ({ id, step = EClientOrgStep.General }: GetClientOrgEditRouteProps) => {
  const searchParams = new URLSearchParams();
  searchParams.append(EClientOrgUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.edit.replace(`:${EClientOrgCreateUrlParam.Id}`, id)}${search}`;
};

const ClientOrgEntry = () => {
  return (
    <Suspense fallback={<ContentLoaderSimple />}>
      <ClientOrgActionsProvider>
        <Switch>
          <Route
            path={getClientOrgManagementRootRouteDefinition()}
            component={ClientOrgManagementEntry}
          />
          <Route
            exact
            path={routing.list}
            component={ClientOrgsScreen}
          />
          <Route
            exact
            path={routing.create}
            component={ClientOrgCreateScreen}
          />
          <Route
            exact
            path={routing.edit}
            component={ClientOrgEditScreen}
          />
          <Redirect
            exact
            from={routing.root}
            to={routing.list}
          />
          <Route component={NotFoundScreen} />
        </Switch>
      </ClientOrgActionsProvider>
    </Suspense>
  );
};

export default ClientOrgEntry;
