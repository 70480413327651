import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { MpUser } from '../../../../../../domain/model/user';
import UserMpDetailsGeneral from '../../../../user/components/detailsGeneral';

interface PartnerEmployeeDetailsStepProfileProps {
  readonly partnerEmployee: MpUser;
}

const PartnerEmployeeDetailsStepProfile = (props: PartnerEmployeeDetailsStepProfileProps) => {
  const { partnerEmployee } = props;

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <Typography variant={'h3'}>Профиль</Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <UserMpDetailsGeneral user={partnerEmployee} />
      </Grid>
    </Grid>
  );
};

export default PartnerEmployeeDetailsStepProfile;
