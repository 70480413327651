import Api from '@/data/api';
import { ApiCancellable, ApiQueryDsl, ApiRequestPageable } from '@/data/api/types';
import { getQueryDslByDataFilterValues } from '@/data/api/utils';
import {
  DataFilterQueryDslOperator,
  ESocialPackageStatus,
  EUserGender,
  EUserStatus,
  Nullable,
  Pageable,
  RzdSocialPackage,
  SportOption,
  UUID,
} from '@/domain';
import { PaginationSize } from '@/presentation/types';
import { SocialPackageTableFilterValues } from '@features/socialPackage/filterUtils';
import { v4 as uuidv4 } from 'uuid';

const socialPackage: RzdSocialPackage = {
  id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  createdAt: '2024-12-02T14:26:19.685Z',
  createdBy: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  updatedAt: null,
  updatedBy: null,
  status: ESocialPackageStatus.Appointed,
  lastStatusDate: '2024-12-02T14:26:19.685Z',
  lastStatusComment: null,
  rejectionReason: null,
  employeeAgreement: null,
  employeeAgreementDate: null,
  employeeNotificationDate: null,
  employeeCategory: {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    code: '023',
    name: 'Отдых в профилактории',
  },
  maxLimit: 14000,
  startDate: '2024-06-02',
  year: 2024,
  endDate: '2024-12-02',
  limitToSelfRest: null,
  limitToFamilyRest: null,
  hasRestLimit: false,
  employee: {
    createdAt: '2024-12-02T14:26:19.685Z',
    createdBy: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    updatedAt: '2024-12-02T14:26:19.685Z',
    updatedBy: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    phone: 'string',
    lastName: 'string',
    firstName: 'string',
    middleName: 'string',
    photo: {
      path: 'string',
    },
    birthDate: '2024-12-02',
    gender: EUserGender.Male,
    locality: {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      name: 'string',
      shortName: 'string',
      level: {
        id: 1,
        name: 'string',
      },
      postalCode: 'string',
      position: {
        lat: 90,
        lon: 180,
      },
      external: false,
      hierarchy: [
        {
          id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          level: {
            id: 1,
            name: 'string',
          },
          name: 'string',
          shortName: 'string',
          values: [
            {
              value: 'string',
              typeName: 'string',
              shortTypeName: 'string',
            },
          ],
          //manual: false,
        },
      ],
      manual: false,
    },
    email: 'user@example.com',
    status: EUserStatus.Enabled,
    pernr: 'string',
    //emailVerified: true,
    road: {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      name: 'string',
    },
    hasBenefit: false,
    hasRzdSocialPackage: false,
    roles: [],
    notificationSettings: [
      {
        option: {
          id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          name: 'string',
          description: 'string',
        },
        enabled: true,
      },
    ],
    lastLoggedInAt: '2024-12-02T14:26:19.685Z',
  },
};

export type AllCommandProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string>;
    readonly statuses: ESocialPackageStatus[];
  };
  readonly filters: SocialPackageTableFilterValues;
  /** Фильтр на то что лимиты по отдыху соц. пакета распределены */
  readonly restLimitsExist?: boolean;
  readonly pageNumber: number;
};

type BuildListQueryParamsReturn = ApiCancellable &
  ApiRequestPageable & {
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

type ArchiveCommandProps = {
  readonly ids: UUID[];
  readonly reason: SportOption;
  readonly comment?: string;
};

type ToReadyCommandProps = {
  readonly ids: UUID[];
};

type BackToAppointedCommandProps = {
  readonly ids: UUID[];
};

type AllocateLimitCommandProps = {
  readonly ids: UUID[];
  readonly limitToSelfRest: number;
  readonly limitToFamilyRest: number;
};

type SocialPackageServices = {
  readonly all: (props: AllCommandProps) => Promise<Pageable<RzdSocialPackage>>;
  readonly buildListQueryParams: (props: AllCommandProps) => BuildListQueryParamsReturn;
  readonly archive: (props: ArchiveCommandProps) => Promise<void>;
  readonly toReady: (props: ToReadyCommandProps) => Promise<void>;
  readonly backToAppointed: (props: BackToAppointedCommandProps) => Promise<void>;
  readonly allocateLimit: (props: AllocateLimitCommandProps) => Promise<void>;
};

const socialPackageServices: SocialPackageServices = {
  buildListQueryParams: props => {
    const { search, filters, pageNumber, signal, restLimitsExist } = props;
    const { pageSize, sort, statuses } = search;
    const filterDsl = getQueryDslByDataFilterValues(filters);

    const querydsl: ApiQueryDsl = [];

    if (filterDsl) {
      querydsl.push(...filterDsl);
    }

    if (statuses.length > 0) {
      querydsl.push({
        value: statuses,
        field: 'status',
        operator: DataFilterQueryDslOperator.In,
      });
    }

    return {
      sort,
      signal,
      pageSize,
      restLimitsExist,
      page: pageNumber,
      querydsl,
    };
  },
  all: async props => {
    //    const response = await Api.socialPackage.all(socialPackageServices.buildListQueryParams(props));
    //    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);

    // return { data: response.data, totalCount, pageCount, pageNumber: page };

    const {
      search: { statuses },
    } = props;

    console.log('statuses', statuses);

    const data = [
      { ...socialPackage, id: uuidv4(), status: statuses[0] },
      { ...socialPackage, id: uuidv4(), status: statuses[1]! ?? statuses[0], hasRestLimit: true },
      { ...socialPackage, id: uuidv4(), status: statuses[2]! ?? statuses[0], maxLimit: 5000 },
    ];
    return { data, totalCount: 2, pageCount: 1, pageNumber: 0 };
  },
  archive: async ({ ids, reason, comment }) => {
    await (ids.length === 1
      ? Api.socialPackage.archive({
          id: ids[0],
          reason,
          comment,
        })
      : Api.socialPackage.archiveCollection({ ids, reason, comment }));
  },
  toReady: async ({ ids }) => {
    await (ids.length === 1 ? Api.socialPackage.toReady({ id: ids[0] }) : Api.socialPackage.toReadyCollection({ ids }));
  },
  backToAppointed: async ({ ids }) => {
    await (ids.length === 1
      ? Api.socialPackage.backToAppointed({ id: ids[0] })
      : Api.socialPackage.backToAppointedCollection({ ids }));
  },
  allocateLimit: async ({ ids, limitToFamilyRest, limitToSelfRest }) => {
    await (ids.length === 1
      ? Api.socialPackage.allocateLimit({
          id: ids[0],
          limitToFamilyRest,
          limitToSelfRest,
        })
      : Api.socialPackage.allocateLimitCollection({ ids, limitToFamilyRest, limitToSelfRest }));
  },
};

export default socialPackageServices;
