import { formatCost } from '@/presentation/utils';
import { AdCampaignTableCell, AdCampaignTableCellCommonProps } from './index';

export const AdCampaignTableCellBudget = (props: AdCampaignTableCellCommonProps) => {
  const {
    adCampaign: { budget },
  } = props;

  const value = formatCost(budget);

  return (
    <AdCampaignTableCell
      {...props}
      value={value}
    />
  );
};
