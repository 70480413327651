import { Team } from '../../../../domain/model/team';
import { Nullable } from '../../../../domain/model/types';
import { SportUserProfile } from '../../../../domain/model/user';
import ContentLoader from '../../../components/common/loader';
import { DefaultContentWrapper } from '../../../components/common/wrappers/content';
import { OnChangeObjectAttribute } from '../../../types';
import TeamEditContent from './content';
import { Content, Header, LoaderWrapper, Wrapper } from './controls';
import TeamEditHeader from './header';

interface TeamEditProps {
  readonly team: Team;
  readonly validationErrors: { [key: string]: string | boolean };
  readonly modified: boolean;
  readonly isFetching: boolean;
  readonly isSaving: boolean;
  readonly onChangeAttribute: OnChangeObjectAttribute<Team>;
  readonly onLogoChangeClick: () => void;
  readonly onChangeCaptain: (captain: Nullable<SportUserProfile>) => void;
  readonly onSave: () => void;
  readonly onClose: () => void;
}

const TeamEdit = (props: TeamEditProps) => {
  const {
    team,
    validationErrors,
    modified,
    isFetching,
    isSaving,
    onChangeAttribute,
    onLogoChangeClick,
    onChangeCaptain,
    onSave,
    onClose,
  } = props;

  return (
    <DefaultContentWrapper>
      <Wrapper>
        <Header>
          <TeamEditHeader
            team={team}
            isSaving={isSaving}
            onLogoChangeClick={onLogoChangeClick}
          />
        </Header>
        <Content>
          <TeamEditContent
            team={team}
            errors={validationErrors}
            modified={modified}
            isSaving={isSaving}
            onChangeAttribute={onChangeAttribute}
            onChangeCaptain={onChangeCaptain}
            onSave={onSave}
            onClose={onClose}
          />
        </Content>
      </Wrapper>
      {isFetching && (
        <LoaderWrapper>
          <ContentLoader
            size={75}
            alpha
          />
        </LoaderWrapper>
      )}
    </DefaultContentWrapper>
  );
};

export default TeamEdit;
