import { Grid, Tab, Typography } from '@mui/material';
import { CmsContainerTableTab, CmsContainerTableTabsCounter, ECmsContainerTableTab } from '../utils';
import { Tabs } from './controls';

interface CmsContainerTableTabsProps {
  readonly tab: ECmsContainerTableTab;
  readonly tabs: CmsContainerTableTab[];
  readonly counter: CmsContainerTableTabsCounter;
  readonly onChangeTab: (tab: ECmsContainerTableTab) => void;
}

interface CmsContainerTabLabelProps {
  readonly title: string;
  readonly count?: number;
}

const CmsContainerTabLabel = (props: CmsContainerTabLabelProps) => {
  const { title, count } = props;

  return (
    <Grid
      container
      spacing={1}
      alignItems='center'
    >
      <Grid item>{title}</Grid>
      {count !== null && count !== undefined && (
        <Grid item>
          <Typography color='textSecondary'>{count}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

const CmsContainerTableTabs = ({ tab, tabs, counter, onChangeTab }: CmsContainerTableTabsProps) => {
  return (
    <>
      <Tabs
        onChange={(event, newTab) => onChangeTab(newTab)}
        value={tab}
        textColor='primary'
      >
        {tabs.map(item => (
          <Tab
            key={item.id}
            label={
              <CmsContainerTabLabel
                title={item.name}
                count={counter[item.id]}
              />
            }
            value={item.id}
          />
        ))}
      </Tabs>
    </>
  );
};

export default CmsContainerTableTabs;
