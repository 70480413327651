import * as schemas from './scheme';
import { ProductOrderLifeCycleRulesConfiguration } from './types';

/**
 * конфигурация правил товаров для админа
 */
export const productOrderAdminConfiguration: ProductOrderLifeCycleRulesConfiguration = {
  statusScheme: schemas.productOrderAdminStatusScheme.statusScheme,
  actionsScheme: schemas.productOrderAdminStatusScheme.actionsScheme,
  actionRules: [],
};
/**
 * конфигурация правил товаров для партнера
 */
export const productOrderPartnerConfiguration: ProductOrderLifeCycleRulesConfiguration = {
  statusScheme: schemas.productOrderPartnerStatusScheme.statusScheme,
  actionsScheme: schemas.productOrderPartnerStatusScheme.actionsScheme,
  actionRules: [],
};
