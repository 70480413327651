import { Grid, Typography } from '@mui/material';
import { Partner } from '../../../../../../domain/model/partner';
import { Nullable } from '../../../../../../domain/model/types';
import ConfirmDialog from '../../../../../components/common/dialogs/confirm';
import { MPButton } from '../../../../../theme/ui-kit/button';

interface PartnerRegistrationSuccessDialogProps {
  readonly partner: Nullable<Partner>;
  readonly onAction: () => void;
}

const PartnerRegistrationSuccessDialog = (props: PartnerRegistrationSuccessDialogProps) => {
  const { partner, onAction } = props;

  const isOpen = !!partner;
  const partnerAdminEmail = partner?.owner?.email;
  const partnerName = partner?.name;

  return (
    <ConfirmDialog
      open={isOpen}
      title='Партнёр успешно добавлен'
      dialogButtons={
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              onClick={onAction}
              data-testid={'dialog-button'}
            >
              Отлично
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Typography
        gutterBottom={true}
        data-testid={'dialog-text'}
      >
        На почту <b>{partnerAdminEmail}</b> отправлена инструкция по созданию учетной записи на администрирование
        компании <b>{partnerName}</b> в системе Витрина
      </Typography>
    </ConfirmDialog>
  );
};

export default PartnerRegistrationSuccessDialog;
