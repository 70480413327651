import { SportOptionTyped } from '../../../../../domain/model';
import { EOfferStatus } from '../../../../../domain/model/enums';
import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from './index';

export type CorpOfferTableCellStatusProps = CorpOfferTableCellCommonProps & {
  readonly offerStatuses: SportOptionTyped<EOfferStatus>[];
};

const CorpOfferTableCellStatus = (props: CorpOfferTableCellStatusProps) => {
  const {
    corpOffer: { status },
    offerStatuses,
  } = props;

  const value = offerStatuses.find(s => s.id === status)?.name ?? '-';

  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellStatus;
