import { Fade } from '@mui/material';
import React, { FC, FCC } from 'react';
import RenderDecoratorContainer from '../../components/common/renderDecoratorContainer';
import { DefaultContentWrapper } from '../../components/common/wrappers/content';
import { ContentWrapper, StepperWrapper, Wrapper } from './controls';

type MasterDetailsLayoutProps = {
  readonly stepper?: FC;
  readonly header: FC;
  readonly footer?: FC;
  readonly decorators?: React.ReactNode[];
};

export const MasterDetailsLayout: FCC<MasterDetailsLayoutProps> = props => {
  const { stepper: Stepper, header: Header, footer: Footer, decorators = [], children } = props;

  return (
    <Fade in>
      <Wrapper>
        <RenderDecoratorContainer decorators={decorators}>
          {Stepper && (
            <StepperWrapper
              variant='permanent'
              anchor='left'
            >
              <Stepper />
            </StepperWrapper>
          )}
          <ContentWrapper>
            <DefaultContentWrapper
              type='details'
              stickyHeader
              fullHeight
              header={Header && <Header />}
              footer={Footer && <Footer />}
            >
              {children}
            </DefaultContentWrapper>
          </ContentWrapper>
        </RenderDecoratorContainer>
      </Wrapper>
    </Fade>
  );
};
