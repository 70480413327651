import { EOfferActivationTypeDiscriminator } from '@/domain/model/enums';
import TradeActivationTableCell, { TradeActivationTableCellCommonProps } from './index';

const TradeActivationTableCellPromotionCode = (props: TradeActivationTableCellCommonProps) => {
  const { tradeActivation } = props;

  const value =
    tradeActivation.discriminator === EOfferActivationTypeDiscriminator.PersonalPromotionActivation
      ? tradeActivation.promotion.code
      : '-';

  return (
    <TradeActivationTableCell
      {...props}
      value={value}
    />
  );
};

export default TradeActivationTableCellPromotionCode;
