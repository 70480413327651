import { MobileDatePicker as MuiDatePicker, TimePicker } from '@mui/x-date-pickers';
import styled from '@emotion/styled';

export const StyledDatePicker: typeof MuiDatePicker = styled(MuiDatePicker)`
  &.MuiFormControl-root {
    width: 100%;
  }
` as typeof MuiDatePicker;

export const StyledTimeInput: typeof TimePicker = styled(TimePicker)`
  &.MuiFormControl-root {
    width: 100%;
  }

  & .MuiInputAdornment-positionEnd > * {
    opacity: 0.6;
  }
` as typeof TimePicker;
