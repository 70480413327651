import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../data/store/store';
import { Nullable } from '../../../../../../domain/model/types';
import { CmsContainerTableView } from '../../../types';

export const cmsContainersCmsContainersSelector = (state: RootState) => state.cms.container.table.cmsContainers.data;
export const cmsContainersIsFetchingSelector = (state: RootState) => state.cms.container.table.cmsContainers.isFetching;
export const cmsContainersTabSelector = (state: RootState) => state.cms.container.table.tab;
export const cmsContainersGuidSelector = (state: RootState) => state.cms.container.table.guid;
export const cmsContainersSearchSelector = (state: RootState) => state.cms.container.table.search;
export const cmsContainersActionsSelector = (state: RootState) => state.cms.container.table.actions;
export const cmsContainersNeedRefreshWatcherSelector = (state: RootState) =>
  state.cms.container.table.needRefreshWatcher;
export const cmsContainersLastSortIndexSelector = (state: RootState) =>
  state.cms.container.table.cmsContainers.data.length ?? 0;
export const cmsContainersTabsCounterSelector = (store: RootState) => store.cms.container.table.tabsCounter;
export const cmsContainersCmsContainerToChangeSortIndexSelector = (store: RootState) =>
  store.cms.container.table.containerToChangeSortIndex;

export const cmsContainersCmsContainerIndexSelector = (store: RootState, index: number) => index;
const createCmsContainersTableCmsContainerByIndexSelector = createSelector(
  cmsContainersCmsContainersSelector,
  cmsContainersCmsContainerIndexSelector,
  (cmsContainers, index): Nullable<CmsContainerTableView> => cmsContainers?.[index] ?? null
);

export const cmsContainersCmsContainerByIndexSelector = (index: number) => (store: RootState) =>
  createCmsContainersTableCmsContainerByIndexSelector(store, index);
