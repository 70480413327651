import { Nullable, PartnerContactData, PartnerDesk } from '@/domain';
import { ButtonLink } from '@components/common/buttons/link';
import { partnerPhonePrefix } from '@features/general/partner/utils/common';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { FormHelperText, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { MPEmailInput, MPFormInput, MPPhoneInput } from '@ui-kit/input';
import { MPRichTextEditor } from '@ui-kit/rte';
import React from 'react';
import FileInfo from '../../../../../components/common/files/info';
import FileUploader from '../../../../../components/common/files/uploader';
import SectionTitle from '../../../../../components/common/sectionTitle';
import Splitter from '../../../../../components/common/splitter';
import ValidationErrorPanel from '../../../../../components/common/validation/errorPanel';
import { fileTypes } from '../../../../../constants';
import { OnChangeObjectAttribute, OnChangeObjectAttributeByIndex } from '../../../../../types';
import { ValidationResult, ValidationResultSummary } from '../../../../../utils/validation';
import AddressFullSingleSelector from '../../../../address/components/selector/single/full';
import PartnerLogoPreview from '../../../../partner/components/preview/logo';
import { ColumnWrapper, Content, PreviewWrapper, StepWrapper } from '../../controls';

interface PartnerDeskEditStepDeskProps {
  readonly desk: PartnerDesk;
  readonly validation: Nullable<ValidationResult<PartnerDesk>>;
  readonly validationContacts: Nullable<
    ValidationResult<Nullable<PartnerContactData>>[] & Nullable<ValidationResultSummary>
  >;
  readonly isValidDesk: boolean;
  readonly isValidContacts: boolean;
  readonly onChangeAttribute: OnChangeObjectAttribute<PartnerDesk>;
  readonly onChangeContactAttribute: OnChangeObjectAttributeByIndex<PartnerContactData>;
  readonly onAddContact: () => void;
  readonly onRemoveContact: (index: number) => void;
}

const PartnerDeskEditStepDesk = (props: PartnerDeskEditStepDeskProps) => {
  const {
    isValidDesk,
    isValidContacts,
    desk,
    validation,
    validationContacts,
    onChangeAttribute,
    onChangeContactAttribute,
    onAddContact,
    onRemoveContact,
  } = props;

  const { aboutCompany, image } = desk;

  const contacts = desk.contacts!;

  const getAddressValidationResult = (contactIndex: number): Nullable<ValidationResult<PartnerContactData>> => {
    const result = validationContacts?.[contactIndex];
    if (!result) return null;

    return {
      address: result.address,
    };
  };

  return (
    <StepWrapper>
      <ColumnWrapper>
        <Content>
          <Grid
            container
            spacing={2}
            direction='column'
          >
            <Grid
              item
              xs={12}
            >
              <SectionTitle gutterBottom>Логотип</SectionTitle>
              <Typography
                variant='body2'
                gutterBottom
              >
                Загрузите файл с расширением png и минимальным размером 120х120 px
              </Typography>

              {image && (
                <FileInfo
                  id={image.path}
                  onRemove={() => onChangeAttribute('image', null)}
                />
              )}
              {!image && (
                <FileUploader
                  fileTypes={[fileTypes.png.mimeType, fileTypes.jpg.mimeType, fileTypes.jpeg.mimeType]}
                  accept={[fileTypes.png.ext, fileTypes.jpg.ext, fileTypes.jpeg.ext]}
                  error={validation?.image?.hasError}
                  helperText={validation?.image?.message}
                  onUpload={file => onChangeAttribute('image', { path: file.path })}
                />
              )}
            </Grid>

            <Grid item>
              <Splitter variant='horizontal' />
              <SectionTitle gutterBottom>О компании</SectionTitle>

              <MPRichTextEditor
                value={aboutCompany ?? ''}
                error={!!validation?.aboutCompany?.hasError}
                helperText={validation?.aboutCompany?.message}
                onEditorChange={value => {
                  onChangeAttribute('aboutCompany', value);
                }}
              />
            </Grid>

            <Grid
              item
              container
              spacing={2}
              justifyContent='space-between'
            >
              {contacts.map((contact, index) => (
                <React.Fragment key={index}>
                  <Splitter variant='horizontal' />

                  <Grid item>
                    <SectionTitle gutterBottom={false}>Контакт {contacts.length === 1 ? '' : index + 1}</SectionTitle>
                  </Grid>

                  {contacts.length > 1 && (
                    <Grid item>
                      <ButtonLink onClick={() => onRemoveContact(index)}>
                        <DeleteOutlinedIcon />
                        <Typography>Удалить контакт</Typography>
                      </ButtonLink>
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                  >
                    <Typography variant='body2'>
                      По этому контакту покупатель будет обращаться по товару или услуге
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <MPFormInput
                      label='Название контакта'
                      value={contact.name}
                      error={validationContacts?.[index]?.name?.hasError}
                      helperText={validationContacts?.[index]?.name?.message}
                      onChange={event => onChangeContactAttribute(index, 'name', event.target.value)}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <AddressFullSingleSelector
                      value={contact.address}
                      onChange={address => onChangeContactAttribute(index, 'address', address)}
                      error={getAddressValidationResult(index)?.address?.hasError}
                      helperText={getAddressValidationResult(index)?.address?.message}
                      label='Адрес'
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <MPPhoneInput
                      prefix={partnerPhonePrefix}
                      label='Телефон'
                      value={contact.phone}
                      error={validationContacts?.[index]?.phone?.hasError}
                      helperText={validationContacts?.[index]?.phone?.message}
                      onChange={event => onChangeContactAttribute(index, 'phone', event.target.value)}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <MPEmailInput
                      label='Электронная почта'
                      value={contact.email}
                      error={validationContacts?.[index]?.email?.hasError}
                      helperText={validationContacts?.[index]?.email?.message}
                      onChange={value => onChangeContactAttribute(index, 'email', value)}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <MPFormInput
                      label='Ссылка на форму обратной связи'
                      value={contact.feedbackLink}
                      error={validationContacts?.[index]?.feedbackLink?.hasError}
                      helperText={validationContacts?.[index]?.feedbackLink?.message}
                      onChange={event => onChangeContactAttribute(index, 'feedbackLink', event.target.value)}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>

            <Grid item />

            <Grid
              item
              xs={12}
            >
              <ButtonLink onClick={onAddContact}>
                <AddBoxOutlinedIcon />
                <Typography>Добавить контакт</Typography>
              </ButtonLink>
              {validationContacts?.validationSummary?.hasError && (
                <FormHelperText error>{validationContacts?.validationSummary?.message}</FormHelperText>
              )}
            </Grid>

            {(!isValidDesk || !isValidContacts) && (
              <Grid
                item
                xs={12}
              >
                <ValidationErrorPanel />
              </Grid>
            )}
          </Grid>
        </Content>
      </ColumnWrapper>
      <ColumnWrapper>
        <PreviewWrapper>
          <PartnerLogoPreview id={desk?.image?.path ?? null} />
        </PreviewWrapper>
      </ColumnWrapper>
    </StepWrapper>
  );
};

export default PartnerDeskEditStepDesk;
