import { Grid, Typography } from '@mui/material';
import { MPButton } from '../../../../theme/ui-kit/button';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';
import { ProductOffer } from '../../../../../domain/model/productOffer';
import { useMemo } from 'react';
import { concatProductName } from '../../utils/helpers';
import { TypographyBold } from './controls';

type DialogToArchiveProps = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onSubmit: () => void;
  readonly product: ProductOffer;
  readonly isProductVariant?: boolean;
};

export const ProductArchiveDialog = (props: DialogToArchiveProps) => {
  const { open, onClose, onSubmit, product, isProductVariant = false } = props;

  const nameProduct = useMemo(() => {
    return isProductVariant
      ? concatProductName(product.name, product.variantName)
      : concatProductName(product.productDesk?.code, product.name, ' - ');
  }, [product, isProductVariant]);

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title={`Отправить в архив ${isProductVariant ? 'вариант' : 'карточку'} товара?`}
      dialogButtons={
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              onClick={onSubmit}
            >
              Отправить в архив
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant={'outlined'}
              fullWidth={false}
              onClick={onClose}
            >
              Отмена
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Typography
        variant='body1'
        gutterBottom
      >
        Вы действительно хотите отправить в архив {isProductVariant ? 'вариант' : 'карточку'} товара
      </Typography>
      <TypographyBold>{nameProduct}?</TypographyBold>
    </ConfirmDialog>
  );
};
