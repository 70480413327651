import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from './index';

const CorpOfferTableCellCategories = (props: CorpOfferTableCellCommonProps) => {
  const {
    corpOffer: { categories },
  } = props;

  const value = categories?.map(category => category.name).join(', ') ?? '-';

  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellCategories;
