import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { OfferFieldAudit, OfferState, XLSXImportResult } from '../../domain/model';
import { EOfferStatus, EProductOfferDiscriminator } from '../../domain/model/enums';
import {
  AttributeValuesFilter,
  ProductCategory,
  ProductCreateRequest,
  ProductOffer,
  ProductOfferAction,
  UpdateCreateRequest,
} from '../../domain/model/productOffer';
import { Nullable, UUID } from '../../domain/model/types';
import { createCancelToken } from './index';
import {
  ApiCancellable,
  ApiQueryDsl,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
  ApiResponseMaBeCounters,
} from './types';
import { appendQueryDslParams, getComServicesEndpoint } from './utils';

type AllProps<D extends ApiRequestListDiscriminator> = ApiCancellable &
  ApiRequestPageable & {
    readonly statuses?: Nullable<EOfferStatus[]>;
    readonly partnerId?: Nullable<UUID>;
    readonly categories?: Nullable<UUID[]>;
    readonly viewed?: Nullable<boolean>;
    readonly attributeValues?: Nullable<AttributeValuesFilter[]>;
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
    readonly discriminator?: D;
  };

type AloneIDProps = ApiCancellable & {
  readonly id: UUID;
};

type SaveDraftProps<T> = {
  readonly id?: Nullable<UUID>;
  readonly data: T;
};

type HistoryProps = ApiRequestPageable &
  ApiCancellable & {
    readonly id: UUID;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

type ActionProps = AloneIDProps & ProductOfferAction;

export type CategoriesRequestProps = ApiCancellable & {
  query?: Nullable<string>;
  /**onlyLeafCategories:
   * true - только категории у которых есть предложения
   * */
  onlyUsedCategories?: boolean;
  /** onlyLeafCategories:
   *  true - только листовые категории,
   *  false - структура категорий в виде списка с родительскими категориями
   *  */
  onlyLeafCategories?: boolean;
  querydsl?: Nullable<ApiQueryDsl>;
};

type ImportProps = ApiCancellable & {
  readonly partnerId: UUID;
  readonly categoryId: UUID;
  readonly file: File;
  readonly config?: AxiosRequestConfig;
};

type ImportStocksProps = ApiCancellable & {
  readonly partnerId: UUID;
  readonly file: File;
  readonly config?: AxiosRequestConfig;
};

type ProductOffersApi = {
  readonly all: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps<D>
  ) => Promise<AxiosResponse<ApiResponseMaBeCounters<D, ProductOffer[]>>>;
  readonly one: (props: AloneIDProps) => Promise<AxiosResponse<ProductOffer>>;
  readonly delete: (props: AloneIDProps) => Promise<AxiosResponse>;
  readonly createDraft: (props: SaveDraftProps<ProductCreateRequest>) => Promise<AxiosResponse<ProductOffer>>;
  readonly updateDraft: (props: SaveDraftProps<UpdateCreateRequest>) => Promise<AxiosResponse<ProductOffer>>;
  readonly update: (props: SaveDraftProps<UpdateCreateRequest>) => Promise<AxiosResponse<ProductOffer>>;
  readonly duplicate: (props: AloneIDProps) => Promise<AxiosResponse<ProductOffer>>;
  readonly actions: (props: ActionProps) => Promise<AxiosResponse<ProductOffer>>;
  readonly categories: (props: CategoriesRequestProps) => Promise<AxiosResponse<ProductCategory[]>>;
  readonly history: (props: HistoryProps) => Promise<AxiosResponse<OfferState[]>>;
  readonly fieldHistory: (props: HistoryProps) => Promise<AxiosResponse<OfferFieldAudit[]>>;
  readonly import: (props: ImportProps) => Promise<AxiosResponse<XLSXImportResult>>;
  readonly importStocks: (props: ImportStocksProps) => Promise<AxiosResponse<XLSXImportResult>>;
};

/**
 * АПИ по работе с товарами
 */
const productOffers: ProductOffersApi = {
  all: props => {
    const {
      sort,
      page,
      pageSize,
      statuses,
      categories,
      partnerId,
      query,
      querydsl,
      viewed,
      attributeValues,
      discriminator,
      signal,
    } = props;

    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    statuses?.forEach((status: EOfferStatus) => params.append('status', status));
    categories?.forEach((category: UUID) => params.append('category', category));

    if (partnerId) {
      params.append('partnerId', partnerId);
    }

    if (viewed !== null && viewed !== undefined) {
      params.append('viewed', viewed.toString());
    }

    if (attributeValues) {
      attributeValues.forEach(item => {
        params.append('attributeValues', JSON.stringify(item));
      });
    }

    if (query) params.append('q', query);

    if (discriminator) {
      params.append('resultType', discriminator);
    }

    if (querydsl) appendQueryDslParams(params, querydsl);

    return axios.get(`${getComServicesEndpoint()}/product-offers`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  one: ({ id, signal }) => {
    return axios.get(`${getComServicesEndpoint()}/product-offers/${id}`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  delete: ({ id }) => {
    return axios.delete(`${getComServicesEndpoint()}/product-offers/${id}`);
  },
  duplicate: ({ id }) => {
    return axios.post(`${getComServicesEndpoint()}/product-offers`, {
      id,
      discriminator: EProductOfferDiscriminator.ProductOfferCopyCommand,
    });
  },
  createDraft: ({ data }) => {
    return axios.post(`${getComServicesEndpoint()}/product-offers`, {
      ...data,
      discriminator: EProductOfferDiscriminator.ProductOfferDataRequest,
    });
  },
  updateDraft: ({ id, data }) => {
    return axios.put(`${getComServicesEndpoint()}/product-offers/${id}`, {
      ...data,
      discriminator: EProductOfferDiscriminator.ProductOfferDraftUpdateRequest,
    });
  },
  update: ({ id, data }) => {
    return axios.put(`${getComServicesEndpoint()}/product-offers/${id}`, {
      ...data,
      discriminator: EProductOfferDiscriminator.ValidProductOfferRequest,
    });
  },
  actions: ({ id, signal, ...data }) => {
    return axios.post(`${getComServicesEndpoint()}/product-offers/${id}`, data, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  categories: props => {
    const { query, onlyLeafCategories = true, onlyUsedCategories = true, querydsl, signal } = props;

    const params = new URLSearchParams();
    if (query) params.append('q', query);
    if (querydsl) appendQueryDslParams(params, querydsl);

    params.append('onlyLeafCategories', onlyLeafCategories.toString());

    params.append('onlyUsedCategories', onlyUsedCategories.toString());

    return axios.get(`${getComServicesEndpoint()}/product-offers/categories`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  history: props => {
    const { id, querydsl, page, pageSize, sort, signal } = props;
    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (querydsl) {
      appendQueryDslParams(params, querydsl);
    }

    return axios.get(`${getComServicesEndpoint()}/product-offers/${id}/history`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  fieldHistory: props => {
    const { id, querydsl, page, pageSize, sort, signal } = props;
    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (querydsl) {
      appendQueryDslParams(params, querydsl);
    }

    return axios.get(`${getComServicesEndpoint()}/product-offers/${id}/field-history`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  import: ({ partnerId, categoryId, file, config = {} }) => {
    const formData = new FormData();
    formData.append('partnerId', partnerId);
    formData.append('categoryId', categoryId);
    formData.append('file', file);
    const reqConfig: AxiosRequestConfig = {
      ...config,
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return axios.post(`${getComServicesEndpoint()}/product-offers/import`, formData, reqConfig);
  },
  importStocks: ({ partnerId, file, config = {} }) => {
    const formData = new FormData();
    formData.append('partnerId', partnerId);
    formData.append('file', file);
    const reqConfig: AxiosRequestConfig = {
      ...config,
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return axios.post(`${getComServicesEndpoint()}/product-stocks/import`, formData, reqConfig);
  },
};

export default productOffers;
