import useTechConfig from '../../../hooks/useTechConfig';
import { EAppFeature } from '../../../types';

interface FeatureProps {
  readonly feature: EAppFeature;
  readonly stubComponent?: JSX.Element;
  readonly children: JSX.Element;
}

export const Feature = ({ feature, children, stubComponent }: FeatureProps) => {
  const { hasFeature } = useTechConfig();

  return hasFeature(feature) ? children : stubComponent ? stubComponent : null;
};
