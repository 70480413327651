import BookingOfferTableCell, { BookingOfferTableCellCommonProps } from './index';

const BookingOfferTableCellModerator = (props: BookingOfferTableCellCommonProps) => {
  // TODO модератора сейчас нет в модели апишки
  const {
    bookingOffer: { moderator },
  } = props;

  const value = moderator?.name ?? '-';

  return (
    <BookingOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default BookingOfferTableCellModerator;
