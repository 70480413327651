import moment from 'moment';
import { EDateFormat } from '../../../../../domain/model/formats';
import CmsContainerTableCell, { CmsContainerTableCellCommonProps } from './index';

type CmsContainerTableCellUpdatedAtProps = CmsContainerTableCellCommonProps;

const CmsContainerTableCellUpdatedAt = (props: CmsContainerTableCellUpdatedAtProps) => {
  const {
    cmsContainer: { updatedAt },
  } = props;

  const value = updatedAt ? moment(updatedAt).format(EDateFormat.DisplayDefault) : '-';

  return (
    <CmsContainerTableCell
      {...props}
      value={value}
    />
  );
};

export default CmsContainerTableCellUpdatedAt;
