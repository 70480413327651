import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../../data/store/store';
import { ESortDirection } from '../../../../../../domain/model/enums';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import { MpUserShort } from '../../../../../../domain/model/user';
import { addSearchParamToLocation } from '../../../../../../routing/globalRouting';
import { PaginationSize } from '../../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../../utils/filtering';
import { EAdminMpUrlParam } from '../../entry';
import { AdminMpFilterEditStrategy, AdminMpFilterValues, EAdminMpFilterItem } from '../filterUtils';
import { adminMpsGuidSelector } from '../store/selectors';
import {
  adminMpsActivateAdminMp,
  adminMpsDeactivateAdminMp,
  adminMpSetFilter,
  adminMpSetPage,
  adminMpSetPageSize,
  adminMpSetSort,
  adminMpSortReset,
} from '../store/slice';
import { EAdminMpTableTab } from '../utils';

export type UseAdminMpTable = {
  readonly onChangeTab: (newTab: EAdminMpTableTab) => void;
  readonly onChangeFilter: (strategies: AdminMpFilterEditStrategy[]) => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
  readonly onResetSort: () => void;

  readonly onChangePage: (page: number) => void;
  readonly onChangePageSize: (pageSize: PaginationSize) => void;

  readonly onAdminActivate: (admin: MpUserShort) => Promise<boolean>;
  readonly onAdminDeactivate: (admin: MpUserShort) => Promise<boolean>;
};

type UseAdminMpTableHandlersProps = {
  readonly tab: EAdminMpTableTab;
}
export function useAdminMpTableHandlers({tab}: UseAdminMpTableHandlersProps): UseAdminMpTable {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const getAdminMpsGuidFromState = useCallback((): Nullable<UUID> => {
    const state = store.getState();
    return adminMpsGuidSelector(state);
  }, [store]);
  
  const onChangeFilter = useCallback(
    (strategies: AdminMpFilterEditStrategy[]) => {
      dispatch(adminMpSetFilter(getDataFilterValuesByStrategies<EAdminMpFilterItem, AdminMpFilterValues>(strategies)));
    },
    [dispatch]
  );

  const onChangeSort = useCallback(
    (name: string, direction: ESortDirection) => {
      dispatch(
        adminMpSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onResetSort = useCallback(() => dispatch(adminMpSortReset()), [dispatch]);

  const onChangeTab = useCallback(
    (newTab: EAdminMpTableTab) => {
      if (newTab !== tab) {
        const guid = getAdminMpsGuidFromState();
        history.replace(
          addSearchParamToLocation({
            location,
            param: EAdminMpUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [history, tab, location, getAdminMpsGuidFromState]
  );
  
  const onChangePage = useCallback(
    (page: number) => {
      dispatch(adminMpSetPage({ pageNumber: page }));
    },
    [dispatch]
  );

  const onChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(adminMpSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onAdminActivate = useCallback(
    async ({ id }: MpUserShort) => {
      await dispatch(adminMpsActivateAdminMp({ id })).unwrap();
      return true;
    },
    [dispatch]
  );

  const onAdminDeactivate = useCallback(
    async ({ id }: MpUserShort) => {
      await dispatch(adminMpsDeactivateAdminMp({ id })).unwrap();
      return true;
    },
    [dispatch]
  );

  return {
    onChangeTab,
    onChangeFilter,
    onChangeSort,
    onResetSort,
    onChangePage,
    onChangePageSize,
    onAdminActivate,
    onAdminDeactivate,
  };
}
