import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ESimpleSportEventType, ESportEventType } from '../../../domain/model/enums';
import { FriendlyMatch } from '../../../domain/model/event';
import { Nullable, UUID } from '../../../domain/model/types';
import rootRouting from '../../../routing';
import { createSidePanelRoute } from '../../../routing/globalRouting';
import NotFoundScreen from '../../screens/notFound';
import { ESidePanelDataType } from '../general/side/enums';
import { ESportEventTableTab } from './table/utils';
import { TournamentApplicationsFilter } from './tournamentApplication/types';
import { EEventCreateUrlParam, EEventStep, EEventUrlParam } from './types';
import React from 'react';

const EventScreen = React.lazy(() => import('../../screens/event'));
const EventCreateScreen = React.lazy(() => import('../../screens/eventCreate'));
const EventEditScreen = React.lazy(() => import('../../screens/eventEdit'));
const EventsScreen = React.lazy(() => import('../../screens/events'));

const routing = {
  root: `${rootRouting.events}`,
  list: `${rootRouting.events}`,
  details: `${rootRouting.events}/:id`,
  create: `${rootRouting.events}/create`,
  edit: `${rootRouting.events}/:id/edit`,
};

interface GetEventApplicationFilterRouteProps {
  readonly location: any;
  readonly parent?: UUID;
  readonly filter: TournamentApplicationsFilter;
}

export const getEventApplicationFilterRoute = (props: GetEventApplicationFilterRouteProps) => {
  const { location, parent, filter } = props;

  return createSidePanelRoute({
    location,
    type: ESidePanelDataType.EventApplicationFilter,
    parent,
    params: {
      filter,
    },
  });
};

interface GetEventEditLegacyRouteProps {
  readonly location: any;
  readonly event: FriendlyMatch;
}

export const getEventEditLegacyRoute = ({ location, event }: GetEventEditLegacyRouteProps) => {
  const { id, type } = event;
  return createSidePanelRoute({
    location,
    type: ESidePanelDataType.EventEdit,
    params: {
      id,
      eventType: type.code,
    },
  });
};

interface GetEventListTabRouteProps {
  tab?: ESportEventTableTab;
}

interface GetEventEditRouteProps {
  readonly id: UUID;
  readonly step?: EEventStep;
}

export const getEventEditRoute = ({ id, step = EEventStep.General }: GetEventEditRouteProps) => {
  const searchParams = new URLSearchParams();
  if (step) {
    searchParams.set(EEventUrlParam.Step, step);
  }

  return `${routing.edit.replace(':id', id)}?${searchParams.toString()}`;
};

interface GetEventCreateRouteProps {
  readonly location: any;
  readonly id?: Nullable<UUID>;
  readonly guid?: UUID;
  readonly eventType: ESportEventType | ESimpleSportEventType;
  readonly step?: EEventStep;
  readonly saveGuid?: boolean;
}

export interface EventsLocationState {
  readonly guid: UUID;
}

export const getEventCreateRoute = ({ id, step, eventType, location, guid }: GetEventCreateRouteProps) => {
  const searchParams = new URLSearchParams(location.search);
  const currentGuid = guid ?? uuidv4();

  if (id) {
    searchParams.set(EEventCreateUrlParam.Id, id);
  }
  if (step) {
    searchParams.set(EEventUrlParam.Step, step);
  }
  if (eventType) {
    searchParams.set(EEventUrlParam.Type, eventType);
  }
  searchParams.set(EEventCreateUrlParam.Guid, currentGuid);

  return `${routing.create}?${searchParams.toString()}`;
};

interface GetEventViewRouteProps {
  readonly id: UUID;
  readonly step?: EEventStep;
}

export const getEventViewRoute = ({ id, step }: GetEventViewRouteProps) => {
  const searchParams = new URLSearchParams();

  if (step) {
    searchParams.append(EEventUrlParam.Step, step);
  }

  return `${routing.details.replace(':id', id)}?${searchParams.toString()}`;
};
export const getEventsTableRoute = ({ tab }: GetEventListTabRouteProps) => {
  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.append('tab', tab);
  }
  return `${routing.list}${searchParams.getAll('tab').length ? `?${searchParams.toString()}` : ''}`;
};

const EventsEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.list}
        component={EventsScreen}
      />
      <Route
        exact
        path={routing.create}
        component={EventCreateScreen}
      />
      <Route
        exact
        path={routing.edit}
        component={EventEditScreen}
      />
      <Route
        path={routing.details}
        component={EventScreen}
      />
      <Redirect
        exact
        from={routing.root}
        to={routing.list}
      />
      <Route component={NotFoundScreen} />
    </Switch>
  );
};

export default EventsEntry;
