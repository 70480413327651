import { EBannerPartition } from '@/domain';
import { EBannerTableFilterItem } from '@features/banner/table/filterUtils';
import { EBannerTableColumn } from '@features/banner/table/utils';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataFilterComponent from '../../../../components/common/dataFilter';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bannersFilterSelector, bannersGuidSelector, bannersSortColumnSelector } from '../store/selectors';

interface BannerTableFilterAdapterProps {
  readonly partition: EBannerPartition;
  readonly filterAdapter: DataFilterAdapter<EBannerTableColumn, EBannerTableFilterItem>;
}

export const BannerTableFilterAdapter = (props: BannerTableFilterAdapterProps) => {
  const { partition, filterAdapter } = props;

  const handlers = useContextHandlers();

  const guid = useSelector(bannersGuidSelector(partition));
  const sortColumn = useSelector(bannersSortColumnSelector(partition));
  const filter = useSelector(bannersFilterSelector(partition));

  const { filterStrategies } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return useMemo(
    () => (
      <DataFilterComponent
        strategies={filterStrategies}
        onChange={handlers.onChangeFilter}
      />
    ),
    [filterStrategies, handlers.onChangeFilter]
  );
};