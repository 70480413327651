import { EPlaygroundModerationStatus } from '../../../../domain/model/enums';
import { Nullable } from '../../../../domain/model/types';
import { DataTableColumns } from '../../../components/common/table';

export enum EPlaygroundTableTab {
  All = 'all',
  Active = 'active',
  Blocked = 'blocked',
}

export type PlaygroundTableTabsCounter = {
  [name in EPlaygroundTableTab]?: number;
};

export type PlaygroundCounterByModerationStatus = {
  [name in EPlaygroundModerationStatus]?: number;
};

export enum EPlaygroundTableColumn {
  Name = 'name',
  ActivityTypes = 'activityTypes',
  ActivitySubTypes = 'activitySubTypes',
  CityName = 'locality',
  ModeratedBy = 'moderatedBy',
  CreatedBy = 'createdBy',
  TypePlayGround = 'type',
  RejectingReason = 'rejectingReason',
}

export const getPlaygroundTableColumns = (tab: EPlaygroundTableTab) => {
  const columns: DataTableColumns<EPlaygroundTableColumn> = {
    [EPlaygroundTableColumn.Name]: {
      title: 'Название',
      sortable: true,
      width: '15rem',
      wrap: true,
    },
    [EPlaygroundTableColumn.ActivityTypes]: {
      title: 'Тематика',
      width: '16rem',
      sortable: true,
      wrap: true,
    },
    [EPlaygroundTableColumn.ActivitySubTypes]: {
      title: 'Жанр',
      width: '22rem',
      wrap: true,
    },
    [EPlaygroundTableColumn.CityName]: {
      title: 'Населенный пункт',
      width: '15rem',
      sortable: true,
    },
    [EPlaygroundTableColumn.TypePlayGround]: {
      title: 'Комфорт',
      width: '6rem',
      sortable: false,
    },
    [EPlaygroundTableColumn.ModeratedBy]: {
      title: 'Модератор',
      width: '15rem',
      sortable: true,
    },
  };

  const columnsWithRejects = {
    ...columns,
    [EPlaygroundTableColumn.RejectingReason]: {
      title: 'Причина блокировки',
      width: '15rem',
      sortable: true,
    },
  };

  return tab !== EPlaygroundTableTab.Blocked ? columns : columnsWithRejects;
};

export const getPlaygroundTableTabByModerationStatus = (
  status: Nullable<EPlaygroundModerationStatus>
): EPlaygroundTableTab => {
  switch (status) {
    case EPlaygroundModerationStatus.Active:
      return EPlaygroundTableTab.Active;
    case EPlaygroundModerationStatus.Blocked:
      return EPlaygroundTableTab.Blocked;
    case EPlaygroundModerationStatus.Draft:
    case null:
      return EPlaygroundTableTab.All;
  }
  return EPlaygroundTableTab.All;
};

export const getPlaygroundTableTabName = (tab: EPlaygroundTableTab): string => {
  switch (tab) {
    case EPlaygroundTableTab.All:
      return 'Все площадки';
    case EPlaygroundTableTab.Active:
      return 'Опубликованные';
    case EPlaygroundTableTab.Blocked:
      return 'Заблокированные';
  }
};

interface GetPlaygroundTableAdapterKeyResult {
  key: string;
}

export const getPlaygroundTableAdapterKey = (tab: EPlaygroundTableTab): GetPlaygroundTableAdapterKeyResult => {
  return {
    key: `playgrounds-${tab}`,
  };
};
