import styled from '@emotion/styled';
import { css, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

export const Wrapper = styled.div`
  position: relative;

  padding: ${p => p.theme.spacing(5)};

  width: 23.875rem;
  height: 100%;

  border-radius: ${p => (p.theme.shape.borderRadius as number) * 3}px;
  background-color: #f4f5f6;

  display: flex;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    inset: -1px;
    border-radius: ${p => (p.theme.shape.borderRadius as number) * 3}px;
    z-index: 0;
  }
`;

export const Label = styled.div`
  position: absolute;
  top: calc(-${p => p.theme.typography.body2.lineHeight} / 2);
  left: ${p => p.theme.spacing(5)};

  padding: 0 ${p => p.theme.spacing(0.5)};
  background-color: ${p => p.theme.palette.background.default};

  & .MuiTypography-root {
    font-weight: ${p => p.theme.typography.fontWeightSemiBold};
  }
`;

export const ModeToggler = styled(ToggleButtonGroup)(
  ({ theme }) => css`
    position: absolute;
    top: calc(-${theme.spacing(2.5)});
    left: calc(100% / 2 - ${theme.spacing(5)});

    background-color: #f4f5f6;
    pointer-events: all;

    border: 1px solid ${theme.palette.text.primary};
    border-radius: ${Number(theme.shape.borderRadius) * 2}px;
  `
);

export const ModeButton = styled(ToggleButton)(
  ({ theme: { palette, shape } }) => css`
    height: 2.5rem;
    width: 2.5rem;

    border: 0 !important;
    border-radius: ${Number(shape.borderRadius) * 2}px !important;
    margin-left: 0 !important;

    &.Mui-selected {
      background-color: ${palette.text.primary} !important;

      svg {
        color: ${palette.text.onPrimary};
        stroke: ${palette.text.onPrimary};
      }

      &:hover {
        background-color: ${palette.text.primary};
      }
    }
  `
);

export const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  height: 1.125rem;

  margin-bottom: ${p => p.theme.spacing(0.5)};
`;

export const Title = styled(Typography)`
  font-weight: ${p => p.theme.typography.fontWeightBold};
  line-height: 1.125rem;
`;

export const TitleDesktop = styled(Title)`
  font-size: 0.5625rem;
`;
