import { useRef } from 'react';
import { useDrag, useDrop, XYCoord } from 'react-dnd';
import { Identifier } from 'dnd-core';
import { Wrapper } from './controls';
import { dictionaryDndElementType } from './utils';

type DragItem = {
  index: number;
  type: string;
};

type DictionaryDndLayerProps = {
  readonly index: number;
  readonly children: any;
  readonly onMove?: (dragIndex: number, hoverIndex: number) => void;
  readonly onChangeSort?: () => void;
};

export const DictionaryDndLayer = (props: DictionaryDndLayerProps) => {
  const { index, children, onMove, onChangeSort } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: dictionaryDndElementType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // не заменяем items сами на себя
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Получаем среднее по ординате Y
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // положение мыши
      const clientOffset = monitor.getClientOffset();

      // вычисляем пиксели в верхней части
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Перетаскивание вниз
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Перетаскивание вверх
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onMove?.(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ opacity }, drag] = useDrag({
    type: dictionaryDndElementType,
    item: () => {
      return { index };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0 : 1,
    }),
    end: () => {
      onChangeSort?.();
    },
  });

  drag(drop(ref));

  return (
    <Wrapper
      ref={ref}
      data-handler-id={handlerId}
      style={{
        opacity,
      }}
    >
      {children}
    </Wrapper>
  );
};
