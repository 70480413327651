import PartnerTableCell, { PartnerTableCellCommonProps } from './index';

const PartnerTableCellName = (props: PartnerTableCellCommonProps) => {
  const {
    partner: { name },
  } = props;

  const value = name || '-';

  return (
    <PartnerTableCell
      {...props}
      value={value}
    />
  );
};

export default PartnerTableCellName;
