import { createContext } from 'react';
import { RootState } from '../../../../data/store/store';
import { ProductOrder } from '../../../../domain/model/order';
import { Nullable, UUID } from '../../../../domain/model/types';
import { EProductOrderActionType } from '../types';
import { ProductOrderActionsState } from './store/slice';

export interface ProductOrderActionsSelectors {
  readonly getIsProductOrderProcessingSelector: (id: Nullable<UUID>) => (store: RootState) => boolean;
  readonly getIsProductOrderExecutedActionsSelector: (
    id: Nullable<UUID>,
    ...actions: EProductOrderActionType[]
  ) => (store: RootState) => Nullable<EProductOrderActionType>;
  readonly isProductOrdersAnyChangedSelector: (store: RootState) => number;
}

export type ProductOrderActionsContextType = {
  readonly onChangeDialogState: (name: keyof ProductOrderActionsState['dialogs'], data: Nullable<ProductOrder>) => void;

  readonly onCancel: (order: ProductOrder, reasonId: UUID, comment: string) => Promise<Nullable<ProductOrder>>;
  readonly onReturn: (props: ProductOrder) => void;
  readonly onPartiallyReturn: (props: ProductOrder) => void;
  readonly onConfirm: (props: ProductOrder) => void;
  readonly onRenew: (props: ProductOrder) => void;
  readonly onGive: (props: ProductOrder) => void;
  readonly onPay: (props: ProductOrder) => void;
  readonly onSend: (props: ProductOrder) => void;
  readonly onTryCancel: (data: ProductOrder) => void;
  readonly onTryConfirm: (data: ProductOrder) => void;

  readonly utils: {
    readonly selectors: ProductOrderActionsSelectors;
  };
};

const ProductOrderActionsContext = createContext<ProductOrderActionsContextType>({} as ProductOrderActionsContextType);

export default ProductOrderActionsContext;
