import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FileDescription } from '../../../../../../../domain/model';
import { ETaxationSystemType } from '../../../../../../../domain/model/enums';
import { Nullable } from '../../../../../../../domain/model/types';
import PartnerApplicationViewDocument from './document';

interface PartnerApplicationViewIndividualDocumentsProps {
  readonly innCert: Nullable<FileDescription>;
  readonly taxSystem: Nullable<ETaxationSystemType>;
  readonly usnDoc: Nullable<FileDescription>;
  readonly ogrnipCert: Nullable<FileDescription>;
  readonly passportCopy: Nullable<FileDescription>;
}

const PartnerApplicationViewIndividualDocuments = (props: PartnerApplicationViewIndividualDocumentsProps) => {
  const { innCert, taxSystem, usnDoc, ogrnipCert, passportCopy } = props;

  return (
    <Grid
      container
      spacing={2}
      direction='column'
    >
      <Grid item>
        <Typography
          variant='h3'
          gutterBottom
        >
          Документы
        </Typography>
      </Grid>

      <PartnerApplicationViewDocument
        label='Свидетельство ОГРНИП'
        guid={ogrnipCert?.path}
      />

      <PartnerApplicationViewDocument
        label='Свидетельство ИНН'
        guid={innCert?.path}
      />

      <PartnerApplicationViewDocument
        label='Копия паспорта'
        description='Первая страница с фото и страница с регистрацией'
        guid={passportCopy?.path}
      />

      {taxSystem === ETaxationSystemType.USN && (
        <PartnerApplicationViewDocument
          label='Основание УСН'
          guid={usnDoc?.path}
        />
      )}
    </Grid>
  );
};

export default PartnerApplicationViewIndividualDocuments;
