import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import BookingOfferCategoriesEditContainer from '../../features/dictionary/bookingOfferCategory/edit/container';
import { viewHideMainMenu } from '../../features/main/container/store/slice';

const ConfigurationPrivilegeBookingOfferCategoriesScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  return <BookingOfferCategoriesEditContainer />;
};

export default ConfigurationPrivilegeBookingOfferCategoriesScreen;
