import { BookingOfferShort } from '@/domain';
import { DataTableColumns, DataTableMetadata, MutableDataTableColumns } from '@components/common/table';
import { EBookingOfferCollectionTableColumn } from '@features/bookingOffer/components/collection/types';
import { useMemo } from 'react';
import BookingOfferCollectionTable from './table';
import { bookingOfferCollectionDefaultColumns } from './utils';

interface BookingOfferCollectionProps {
  readonly offers: BookingOfferShort[];
  readonly columns: DataTableColumns<EBookingOfferCollectionTableColumn>;
  readonly onRemove?: (id: UUID) => void;
}

const BookingOfferCollection = (props: BookingOfferCollectionProps) => {
  const { offers, columns, onRemove } = props;

  const metadata = useMemo<DataTableMetadata<EBookingOfferCollectionTableColumn>>(() => {
    const columnsOut: MutableDataTableColumns<EBookingOfferCollectionTableColumn> = {};

    bookingOfferCollectionDefaultColumns.forEach(
      column =>
        (columnsOut[column] = {
          ...columns[column],
        })
    );

    return { columns: columnsOut };
  }, [columns]);

  return (
    <BookingOfferCollectionTable
      metadata={metadata}
      offers={offers}
      onRemove={onRemove}
    />
  );
};

export default BookingOfferCollection;
