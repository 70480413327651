import { Skeleton } from '@mui/material';
import styled from '@emotion/styled';
import AppImage from '../../../../../../components/common/images/common';

export const Wrapper = styled.div`
  width: 100%;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  position: relative;

  border-radius: ${p => (p.theme.shape.borderRadius as number) / 2}px;
  border: 0.5px solid ${p => p.theme.palette.text.primary};
`;

export const ImageWrapper = styled.div`
  height: 60%;
`;

export const ImageStub = styled.div`
  height: 100%;

  background-color: ${p => p.theme.palette.disabled.background};
`;

export const StyledImage = styled(AppImage)`
  height: 100%;
  width: 100%;

  object-fit: cover;
  object-position: center center;
  vertical-align: top;
`;

export const SkeletonWrapper = styled.div`
  padding: ${p => p.theme.spacing(0.5)};

  border-top: 0.5px solid ${p => p.theme.palette.text.primary};
`;

export const PartnerIcon = styled(Skeleton)`
  position: absolute;
  right: ${p => p.theme.spacing(0.3)};
  bottom: ${p => p.theme.spacing(0.3)};
`;
