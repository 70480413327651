import { AppConfigurator } from '@/system/appConfigurator';
import TechConfigContext, { TechConfigContextType } from '../contexts/techConfigContext';

interface TechConfigProviderProps {
  readonly children: any;
}

const TechConfigProvider = ({ children }: TechConfigProviderProps) => {
  const isDebug = AppConfigurator.getInstance().isDebug();

  const hasFeature: TechConfigContextType['hasFeature'] = feature => {
    return AppConfigurator.getInstance().hasFeature(feature);
  };

  const getFeatureOptions: TechConfigContextType['getFeatureOptions'] = feature => {
    return AppConfigurator.getInstance().getFeaturesOptions(feature);
  };

  const state = {
    isDebug,
    hasFeature,
    getFeatureOptions,
  };

  return <TechConfigContext.Provider value={state}>{children}</TechConfigContext.Provider>;
};

export default TechConfigProvider;
