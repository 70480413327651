import TabsPanelCounter from '../../../components/common/tabs/counter/counter';
import TabsPanelLabel from '../../../components/common/tabs/label/label';
import { EBookingOrderTableTab } from '../types';
import { BookingOrderTableTabsCounter, getBookingOrderTableTabName } from './utils';

interface BookingOrderTableTabProps {
  readonly tab: EBookingOrderTableTab;
  readonly counts: BookingOrderTableTabsCounter;
}

const BookingOrderTableTab = ({ tab, counts }: BookingOrderTableTabProps) => {
  const count = counts[tab];

  return (
    <TabsPanelLabel title={getBookingOrderTableTabName(tab)}>
      <TabsPanelCounter count={count} />
    </TabsPanelLabel>
  );
};

export default BookingOrderTableTab;
