import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Nullable } from '../../../../../../domain/model/types';

type CropType = PixelCrop;

interface UseImagePixelCropProps {
  readonly source: File;
  readonly quality: number;
  readonly width?: number;
  readonly height?: number;
  readonly generateResult?: boolean;
}

interface UseImageCropResult {
  readonly previewRef: MutableRefObject<Nullable<HTMLCanvasElement>>;
  readonly imgRef: MutableRefObject<Nullable<HTMLImageElement>>;
  readonly crop: CropType;
  readonly cropped: boolean;
  readonly resultFile: Nullable<File>;
  readonly setCrop: (crop: CropType) => void;
  readonly onApply: (callback: (file: Nullable<File>) => void, fileName?: string) => void;
  readonly canvasToBlob: (canvas: HTMLCanvasElement, fileName?: string) => Promise<Nullable<File>>;
}

const useImagePixelCrop = ({
  source,
  width,
  height,
  quality,
  generateResult,
}: UseImagePixelCropProps): UseImageCropResult => {
  const previewRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const [resultFile, setResultFile] = useState<Nullable<File>>(null);

  const initialCrop = useMemo<CropType>(
    () => ({
      unit: 'px',
      x: 0,
      y: 0,
      width: width ?? 0,
      height: height ?? 0,
    }),
    [width, height]
  );
  const [crop, setCrop] = useState<CropType>(initialCrop);

  const canvasToBlob = useCallback<UseImageCropResult['canvasToBlob']>(
    (canvas, fileName) => {
      return new Promise<Nullable<File>>(resolve => {
        return canvas?.toBlob(
          blob => {
            if (blob) {
              const file = new File([blob], fileName || source.name, { type: source.type });
              return resolve(file);
            } else {
              return null;
            }
          },
          source.type,
          quality
        );
      });
    },
    [previewRef.current, source.name, source.type, quality]
  );

  const onApply = useCallback<UseImageCropResult['onApply']>(
    (callback, fileName) => {
      if (previewRef.current) {
        canvasToBlob(previewRef.current, fileName).then(callback);
      }
    },
    [previewRef.current, canvasToBlob]
  );

  useEffect(() => {
    setCrop(initialCrop);
  }, [source, initialCrop]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (crop && imgRef.current && previewRef.current) {
        const image = imgRef.current;
        const canvas = previewRef.current;
        const ctx = canvas.getContext('2d');
        if (canvas && ctx) {
          const scaleX = image.width / image.width;
          const scaleY = image.height / image.height;

          const pixelRatio = 1;
          canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
          canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
          ctx.scale(pixelRatio, pixelRatio);
          ctx.imageSmoothingQuality = 'high';

          const cropX = crop.x * scaleX;
          const cropY = crop.y * scaleY;

          const centerX = image.width / 2;
          const centerY = image.width / 2;

          ctx.save();

          ctx.translate(-cropX, -cropY);
          ctx.translate(centerX, centerY);
          ctx.translate(-centerX, -centerY);

          ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, image.width, image.height);
          ctx.restore();

          if (generateResult) {
            canvasToBlob(previewRef.current, 'temp.png').then(setResultFile);
          }
        }
      }
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [crop, imgRef.current, previewRef.current, quality, canvasToBlob, generateResult]);

  const cropped = !!crop?.width && !!crop?.height;

  return {
    previewRef,
    imgRef,
    crop,
    cropped,
    setCrop,
    canvasToBlob,
    resultFile,
    onApply,
  };
};
export default useImagePixelCrop;
