import Axios from 'axios';
import Api from '../../../../data/api';
import {
  ApiCancellable,
  ApiQueryDsl,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
} from '../../../../data/api/types';
import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '../../../../data/api/utils';
import { Pageable } from '../../../../domain/model';
import { EUserStatus } from '../../../../domain/model/enums';
import { Nullable, UUID } from '../../../../domain/model/types';
import { MpCustomer } from '../../../../domain/model/user';
import { PaginationSize } from '../../../types';
import { CustomersFilterValues, ECustomersFilterItem } from '../table/filterUtils';
import { CustomerTableTabsCounter, ECustomerTableTab, getCustomerStatusesByTableTab } from '../table/utils';
import { CustomerCounterByStatus } from '../types';

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: string;
    readonly statuses: EUserStatus[];
    readonly partnerId: Nullable<UUID>;
  };
  readonly filter: CustomersFilterValues;
  readonly pageNumber: number;
};

export type BuildListQueryParamsReturn<D extends ApiRequestListDiscriminator> = ApiCancellable &
  ApiRequestPageable & {
    readonly partnerId: Nullable<UUID>;
    readonly statuses?: Nullable<EUserStatus[]>;
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

export type CountProps = Omit<AllProps, 'pageNumber'>;

type CountsByStatusesProps = CountProps;

type CountsByTabsProps = CountProps & {
  readonly tabs: ECustomerTableTab[];
};

export interface CustomerCommonService {
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps
  ) => BuildListQueryParamsReturn<D>;
  readonly all: (props: AllProps) => Promise<Pageable<MpCustomer>>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly countsByStatuses: (props: CountsByStatusesProps) => Promise<{
    counts: CustomerCounterByStatus;
    errors: Array<string>;
  }>;
  readonly countsByTabs: (props: CountsByTabsProps) => Promise<{
    counts: CustomerTableTabsCounter;
    errors: Array<string>;
  }>;
}

const service: CustomerCommonService = {
  buildListQueryParams: props => {
    const { search, filter, pageNumber, signal } = props;
    const { pageSize, sort, statuses, partnerId } = search;

    const query = filter[ECustomersFilterItem.Query]?.value;
    const querydsl = getQueryDslByDataFilterValues(filter);

    return {
      query,
      sort,
      signal,
      pageSize,
      partnerId,
      statuses,
      page: pageNumber,
      querydsl,
    };
  },
  all: async props => {
    const response = await Api.customer.all(service.buildListQueryParams(props));
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);
    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
  count: async props => {
    const { data: response } = await Api.customer.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      discriminator: ApiRequestListDiscriminator.Count,
    });

    return response[0].count;
  },
  countsByStatuses: async ({ signal, ...props }) => {
    const {
      search: { statuses },
    } = props;

    const errors: string[] = [];
    const counts: CustomerCounterByStatus = {};

    const requests = statuses.map(status => {
      const params = {
        ...props,
        search: { ...props.search, statuses: [status] },
      };
      return service.count({ ...params, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], status: EUserStatus) => {
      if (response.status === 'fulfilled') {
        counts[status] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество покупателей '${status}': ${response.reason}`);
        }
      }
    };

    statuses.forEach((status, index) => parseResponse(responses[index], status));

    return { counts, errors };
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: CustomerTableTabsCounter = {};

    const requests = tabs.map(tab => {
      const statuses = getCustomerStatusesByTableTab(tab);
      const params = {
        ...props,
        search: { ...props.search, statuses },
      };
      return service.count({ ...params, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], tab: ECustomerTableTab) => {
      if (response.status === 'fulfilled') {
        counts[tab] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество покупателей '${tab}': ${response.reason}`);
        }
      }
    };

    tabs.forEach((tab, index) => parseResponse(responses[index], tab));

    return { counts, errors };
  },
};

export default service;
