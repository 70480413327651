import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from '.';
import { EMultiSelectorValueType } from '../../../../../domain/model/enums';

const visibleCount = 3;

const CorpOfferTableCellRoads = (props: CorpOfferTableCellCommonProps) => {
  const {
    corpOffer: { target },
  } = props;

  const { targetRoads } = target;

  let value: string;
  if (targetRoads) {
    if (targetRoads.select === EMultiSelectorValueType.All) {
      value = 'все дороги';
    } else {
      const count = targetRoads.in?.length ?? 0;
      const roads =
        targetRoads.in
          ?.map(targetRoad => targetRoad.name)
          ?.slice(0, visibleCount)
          ?.join(', ') ?? '';
      const hiddenLabel = count > visibleCount ? `+${count - visibleCount}` : null;

      value = [roads, hiddenLabel].filter(item => !!item).join(', ') ?? '-';
    }
  } else {
    value = '-';
  }

  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellRoads;
