import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useStepper, { UseStepper } from '../../../general/stepper/useStepper';
import { AdCampaignDetailsStep, AdCampaignDetailsStepType } from '../../types';
import { adCampaignDetailsUiStepsStateSelector } from '../store/selectors';
import { useContextHandlers } from './useContextHandlers';
import { useContextParams } from './useContextParams';

const useAdCampaignDetailsStepper = (): UseStepper<AdCampaignDetailsStepType, AdCampaignDetailsStep> => {
  const { step } = useContextParams();
  const handlers = useContextHandlers();

  const steps = useSelector(adCampaignDetailsUiStepsStateSelector) ?? [];

  useEffect(() => {
    if (step && steps?.length > 0 && !steps.find(s => s.key === step)) {
      handlers.onChangeStep(steps[0]);
    }
  }, [step, steps, handlers.onChangeStep]);

  return useStepper({ currentStepKey: step, steps, openStep: handlers.onChangeStep });
};

export default useAdCampaignDetailsStepper;
