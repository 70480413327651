import { Button, FormHelperText, Grid, IconButton, MobileStepper, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Delete';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import React from 'react';
import Api from '../../../../../data/api';
import { AppFile, SportOption } from '../../../../../domain/model';
import { ENoticeStatus } from '../../../../../domain/model/enums';
import { Nullable, UUID } from '../../../../../domain/model/types';
import Notifier from '../../../../../system/notifier';
import { NoImageIcon } from '../../../../icons';
import { FileContentTypes } from '../../../../utils/constants';
import { convertBytesToUnitText } from '../../../../utils/files';
import { imageFileMaxSize } from '../utils';
import {
  AddImageIcon,
  AddImageWrap,
  Content,
  CoverImage,
  DeleteButton,
  Image,
  ImageStubContent,
  ImageWrapper,
} from './controls';

interface CoverImageEditProps {
  readonly value: Nullable<SportOption | SportOption[]>;
  readonly text: string;
  readonly maxSize: number;
  readonly error?: any;
  readonly multiple?: boolean;
  readonly onAdd: (image: AppFile) => void;
  readonly onDelete?: (image: SportOption) => void;
  readonly disabled?: boolean;
}

export const CoverImageEdit = (props: CoverImageEditProps) => {
  const { value, text, maxSize, multiple = false, error, disabled, onAdd, onDelete } = props;

  if (!multiple && Array.isArray(value)) {
    throw Error("Collection can't be passed for single upload");
  }

  const handleCapture = (files: any) => {
    if (files.length > 0) {
      const file = files[0];

      if (!FileContentTypes.Image.includes(file.type)) {
        Notifier.getInstance().addNotice(
          ENoticeStatus.Error,
          `Недопустимый тип файла. Разрешены к загрузке ${FileContentTypes.Image.join(', ')}.`
        );
        return;
      }

      if (file.size > imageFileMaxSize) {
        Notifier.getInstance().addNotice(
          ENoticeStatus.Error,
          `Максимальный размер файла ${convertBytesToUnitText(maxSize)}`
        );
        return;
      }

      Api.files
        .upload(file)
        .then(({ data }) => {
          onAdd(data);
        })
        .catch(e => {
          Notifier.getInstance().addNotice(ENoticeStatus.Error, `Произошла ошибка при загрузке файла: ${e.message}`);
        });
    }
  };

  const sentences = text.split('.');

  return (
    <>
      {multiple ? (
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={4}
          >
            <CoverImage
              error={error}
              disabled={disabled}
              border
              rounded
            >
              <Content>
                <AddImageWrap>
                  <IconButton
                    component='label'
                    color='secondary'
                  >
                    <AddImageIcon disabled={disabled} />
                    <input
                      disabled={disabled}
                      accept={FileContentTypes.Image.join(', ')}
                      type='file'
                      style={{ display: 'none' }}
                      onChange={event => (event.target.files ? handleCapture(event.target.files) : null)}
                    />
                  </IconButton>
                  <div>
                    {sentences.map((item, index) => (
                      <Typography
                        gutterBottom
                        key={index}
                        color='textSecondary'
                        variant='body2'
                      >
                        {item}.
                      </Typography>
                    ))}
                  </div>
                </AddImageWrap>
              </Content>
            </CoverImage>

            {error && (
              <FormHelperText
                error
                filled
                margin='dense'
                variant='outlined'
              >
                {error}
              </FormHelperText>
            )}
          </Grid>
          {(value as SportOption[]).map(item => (
            <Grid
              item
              xs={4}
              key={item.id}
            >
              <CoverImage
                border
                rounded
              >
                <Content>
                  <Image src={item.image ?? ''} />
                  <DeleteButton onClick={() => onDelete?.(item)}>
                    <CloseIcon color='primary' />
                  </DeleteButton>
                </Content>
              </CoverImage>
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          <CoverImage
            error={error}
            border
            rounded
          >
            <Content>
              {value && (
                <>
                  <Image src={(value as SportOption).id ?? ''} />
                  <DeleteButton onClick={() => onDelete?.(value as SportOption)}>
                    <CloseIcon color='primary' />
                  </DeleteButton>
                </>
              )}
              {!value && (
                <AddImageWrap>
                  <IconButton
                    component='label'
                    color='secondary'
                  >
                    <AddImageIcon />
                    <input
                      accept={FileContentTypes.Image.join(', ')}
                      type='file'
                      style={{ display: 'none' }}
                      onChange={event => handleCapture(event.target.files)}
                    />
                  </IconButton>
                  <div>
                    {sentences.map((item, index) => (
                      <Typography
                        gutterBottom
                        key={index}
                        color='textSecondary'
                        variant='body2'
                      >
                        {item}.
                      </Typography>
                    ))}
                  </div>
                </AddImageWrap>
              )}
            </Content>
          </CoverImage>

          {error && (
            <FormHelperText
              error
              filled
              margin='dense'
              variant='outlined'
            >
              {error}
            </FormHelperText>
          )}
        </>
      )}
    </>
  );
};

interface ImageSliderProps {
  readonly images: any;
  readonly props: any;
}

export const ImageSlider = ({ images, ...props }: ImageSliderProps) => {
  const { position, variant, buttonSize }: any = props;
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <>
      {images && images.length > 0 && (
        <>
          <ImageView uuid={images[activeStep].image} />
          <MobileStepper
            steps={images.length}
            position={position || 'static'}
            variant={variant || 'text'}
            activeStep={activeStep}
            nextButton={
              <Button
                size={buttonSize || 'small'}
                onClick={handleNext}
                disabled={activeStep === images.length - 1}
              >
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size={buttonSize || 'small'}
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeft />
              </Button>
            }
          />
        </>
      )}
    </>
  );
};

interface ImageViewProps {
  readonly uuid: Nullable<UUID>;
  readonly children?: JSX.Element;
}

const ImageView = ({ uuid, children }: ImageViewProps) => {
  return (
    <ImageWrapper>
      <Image src={uuid ?? ''} />
      {children}
    </ImageWrapper>
  );
};

interface CoverImageViewProps {
  readonly id: Nullable<string>;
  readonly border?: boolean;
  readonly rounded?: boolean;
  readonly children?: any;
}

export const CoverImageView = ({ id, border = true, rounded = true, children }: CoverImageViewProps) => {
  return (
    <CoverImage
      border={border}
      rounded={rounded}
    >
      {id ? (
        <Image src={id} />
      ) : (
        <ImageStubContent>
          <NoImageIcon />
          <Typography>Фотография временно отсутствует</Typography>
        </ImageStubContent>
      )}

      {children}
    </CoverImage>
  );
};
