import useDataTableFilterAdapter, { DataFilterAdapter } from '@/presentation/hooks/useDataFilterAdapter';
import { ETradeActivationTableColumn } from '@features/tradeActivation/types';
import { ETradeActivationsFilterItem } from '@features/tradeActivation/utils/filter';
import { useSelector } from 'react-redux';
import TradeActivationTable from '../component';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  tradeActivationsActivationsSelector,
  tradeActivationsFilterSelector,
  tradeActivationsGuidSelector,
  tradeActivationsSortColumnSelector,
  tradeActivationsSortDirectionSelector,
} from '../store/selectors';

type TradeActivationTableTableAdapterProps = {
  readonly filterAdapter: DataFilterAdapter<ETradeActivationTableColumn, ETradeActivationsFilterItem>;
};

const TradeActivationTableTableAdapter = ({ filterAdapter }: TradeActivationTableTableAdapterProps) => {
  const handlers = useContextHandlers();

  const guid = useSelector(tradeActivationsGuidSelector);
  const filter = useSelector(tradeActivationsFilterSelector);
  const sortColumn = useSelector(tradeActivationsSortColumnSelector);
  const sortDirection = useSelector(tradeActivationsSortDirectionSelector);
  const activations = useSelector(tradeActivationsActivationsSelector);

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return (
    <TradeActivationTable
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      activations={activations}
      onRequestSort={handlers.onChangeSort}
      onChangeMetadata={onChangeMetadata}
    />
  );
};

export default TradeActivationTableTableAdapter;
