import { Typography } from '@mui/material';
import { CmsContainerDetailsComponentProps } from '../types';

type CmsContainerViewComponentNameProps = CmsContainerDetailsComponentProps;

const CmsContainerViewComponentName = (props: CmsContainerViewComponentNameProps) => {
  const { cmsContainer } = props;

  return (
    <>
      <Typography color='textSecondary'>Название блока</Typography>
      <Typography>{cmsContainer.name ?? '-'}</Typography>
    </>
  );
};

export default CmsContainerViewComponentName;
