import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../components/common/header';
import { TitleWrapper } from '../controls';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { adCampaignCreateAdCampaignStatusSelector } from '../store/selectors';

export const AdCampaignCreateHeaderAdapter = () => {
  const adCampaignStatus = useSelector(adCampaignCreateAdCampaignStatusSelector);

  const { adCampaignStatuses } = useSelector(nsiDataSelector);
  const adCampaignStatusText = adCampaignStatuses.find(s => s.id === adCampaignStatus)?.name;
  const handlers = useContextHandlers();

  return (
    <DefaultHeader
      sticky
      headline={
        <AppBreadcrumbs>
          <Typography color='textSecondary'>{adCampaignStatusText}</Typography>
        </AppBreadcrumbs>
      }
      onClose={handlers.onClose}
    >
      <TitleWrapper>
        <Typography variant='h2'>{!adCampaignStatus ? 'Создание рекламной кампании' : 'Рекламная кампания'}</Typography>
      </TitleWrapper>
    </DefaultHeader>
  );
};
