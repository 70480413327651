import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataFilterComponent from '../../../../components/common/dataFilter';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { ETeamTableFilterItem } from '../../filterUtils';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { teamsFilterSelector, teamsGuidSelector, teamsSortColumnSelector } from '../store/selectors';
import { ETeamTableColumn } from '../utils';

interface TeamsTableFilterAdapterProps {
  readonly filterAdapter: DataFilterAdapter<ETeamTableColumn, ETeamTableFilterItem>;
}

const TeamsTableFilterAdapter = (props: TeamsTableFilterAdapterProps) => {
  const { filterAdapter } = props;

  const handlers = useContextHandlers();

  const guid = useSelector(teamsGuidSelector);
  const sortColumn = useSelector(teamsSortColumnSelector);
  const filter = useSelector(teamsFilterSelector);

  const { filterStrategies } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return useMemo(
    () => (
      <DataFilterComponent
        strategies={filterStrategies}
        onChange={handlers.onChangeFilter}
      />
    ),
    [filterStrategies, handlers.onChangeFilter]
  );
};

export default TeamsTableFilterAdapter;
