import { EUserSportActionType } from './types';

export const getUserSportActionName = (type: EUserSportActionType) => {
  switch (type) {
    case EUserSportActionType.Save:
      return 'Сохранить';
    case EUserSportActionType.Edit:
      return 'Редактировать';
    case EUserSportActionType.Logout:
      return 'Выйти из системы';
  }
};
