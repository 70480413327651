import { ClientOrgActionsDialogBlockAdapter } from './dialogBlock';
import { ClientOrgActionsDialogUnBlockAdapter } from '@features/clientOrg/actions/adapters/dialogUnBlock';

export const ClientOrgActionsDialogsAdapter = () => {
  return (
    <>
      <ClientOrgActionsDialogBlockAdapter />
      <ClientOrgActionsDialogUnBlockAdapter />
    </>
  );
};
