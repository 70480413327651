import { ApiRequestPageable } from '@/data/api/types';
import {
  appendQueryDslParams,
  getBookingOffersDownloadEndpoint,
  getQueryDslByDataFilterValues,
} from '@/data/api/utils';
import { BookingOffer, BookingOfferShort } from '@/domain/model/booking';
import { EOfferStatus, EPartnerType } from '@/domain/model/enums';
import { DataFilterQueryDslOperator } from '@/domain/model/filter';
import { Nullable, UUID } from '@/domain/model/types';
import { DataTableColumns } from '@components/common/table';
import { EOfferTableTab } from '../../general/offer/types';
import { BookingOfferTableFilterValues } from '../filterUtils';
import { BookingActionTableType, EBookingOfferTableColumn } from '../types';

export type BookingOfferActionCall = {
  readonly action: BookingActionTableType;
  readonly bookingOffer: BookingOffer | BookingOfferShort;
};

export type BookingTableTabsCounter = {
  [name in EOfferTableTab]?: number;
};

export const bookingOffersTableColumns: DataTableColumns<EBookingOfferTableColumn> = {
  [EBookingOfferTableColumn.Image]: {
    title: 'Фото',
    width: '5rem',
    wrap: true,
  },
  [EBookingOfferTableColumn.Date]: {
    title: 'Дата',
    width: '3rem',
    sortable: true,
  },
  [EBookingOfferTableColumn.Code]: {
    title: 'Код объекта',
    width: '8rem',
    sortable: true,
    wrap: true,
  },
  [EBookingOfferTableColumn.Name]: {
    title: 'Объект',
    width: '10rem',
    sortable: true,
  },
  [EBookingOfferTableColumn.Address]: {
    title: 'Адрес',
    width: '12rem',
    wrap: true,
    sortable: true,
  },
  [EBookingOfferTableColumn.Partner]: {
    title: 'Партнер',
    width: '10rem',
    sortable: true,
    wrap: true,
  },
  [EBookingOfferTableColumn.PartnerType]: {
    title: 'Вид партнера',
    width: '10rem',
    sortable: true,
    wrap: true,
  },
  [EBookingOfferTableColumn.Category]: {
    title: 'Категория',
    width: '10rem',
    sortable: false,
  },
  [EBookingOfferTableColumn.ResponsibleFullName]: {
    title: 'Ответственный за объект',
    width: '10rem',
    sortable: false,
  },
  [EBookingOfferTableColumn.ResponsiblePhone]: {
    title: 'Телефон ответственного',
    width: '10rem',
    sortable: false,
  },
  [EBookingOfferTableColumn.ResponsibleEmail]: {
    title: 'Эл. почта ответственного',
    width: '10rem',
    sortable: false,
  },
  [EBookingOfferTableColumn.Moderator]: {
    title: 'Модератор',
    width: '10rem',
    sortable: false,
  },
  [EBookingOfferTableColumn.RzdSocialPackage]: {
    title: 'КСП',
    width: '2rem',
    sortable: true,
  },
};

const bookingOfferTableDefaultColumns = [
  EBookingOfferTableColumn.Image,
  EBookingOfferTableColumn.Date,
  EBookingOfferTableColumn.Code,
  EBookingOfferTableColumn.Name,
  EBookingOfferTableColumn.Address,
  EBookingOfferTableColumn.Category,
  EBookingOfferTableColumn.ResponsibleFullName,
  EBookingOfferTableColumn.ResponsiblePhone,
  EBookingOfferTableColumn.ResponsibleEmail,
];

const bookingOfferOwnerTableDefaultColumns = [
  EBookingOfferTableColumn.Image,
  EBookingOfferTableColumn.Date,
  EBookingOfferTableColumn.Code,
  EBookingOfferTableColumn.Name,
  EBookingOfferTableColumn.Address,
  EBookingOfferTableColumn.Partner,
  EBookingOfferTableColumn.PartnerType,
  EBookingOfferTableColumn.Category,
  EBookingOfferTableColumn.ResponsibleFullName,
  EBookingOfferTableColumn.ResponsiblePhone,
  EBookingOfferTableColumn.ResponsibleEmail,
  EBookingOfferTableColumn.Moderator,
];

const getBookingOfferTableDefaultColumns = (
  tab: EOfferTableTab,
  partnerId: Nullable<UUID>
): EBookingOfferTableColumn[] => {
  const columns = bookingOfferTableDefaultColumns;

  if (partnerId) {
    columns.splice(columns.indexOf(EBookingOfferTableColumn.Partner), 1);
  }

  return columns;
};

export const getBookingOfferAdminTableColumns = (
  tab: EOfferTableTab,
  partnerId: Nullable<UUID>,
  isCSP: boolean
): DataTableColumns<EBookingOfferTableColumn> => {
  const defaultColumns = getBookingOfferTableDefaultColumns(tab, partnerId);
  const allColumns: EBookingOfferTableColumn[] = [];

  allColumns.push(
    EBookingOfferTableColumn.Image,
    EBookingOfferTableColumn.Date,
    EBookingOfferTableColumn.Code,
    EBookingOfferTableColumn.Name,
    EBookingOfferTableColumn.Address,
    EBookingOfferTableColumn.Partner,
    EBookingOfferTableColumn.PartnerType,
    EBookingOfferTableColumn.Category,
    EBookingOfferTableColumn.ResponsibleFullName,
    EBookingOfferTableColumn.ResponsiblePhone,
    EBookingOfferTableColumn.ResponsibleEmail,
    EBookingOfferTableColumn.Moderator
  );

  if (isCSP) {
    allColumns.push(EBookingOfferTableColumn.RzdSocialPackage);
  }

  if (partnerId) {
    allColumns.splice(allColumns.indexOf(EBookingOfferTableColumn.Partner), 1);
  }

  return allColumns.reduce<DataTableColumns<EBookingOfferTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...bookingOffersTableColumns[column],
        hidden: !defaultColumns.includes(column),
      },
    };
  }, {});
};

const getBookingOfferOwnerTableDefaultColumns = (): EBookingOfferTableColumn[] => {
  return bookingOfferOwnerTableDefaultColumns;
};

export const getBookingOfferOwnerTableColumns = (isCSP: boolean): DataTableColumns<EBookingOfferTableColumn> => {
  const defaultColumns = getBookingOfferOwnerTableDefaultColumns();

  const allColumns: EBookingOfferTableColumn[] = [];

  allColumns.push(
    EBookingOfferTableColumn.Image,
    EBookingOfferTableColumn.Date,
    EBookingOfferTableColumn.Code,
    EBookingOfferTableColumn.Name,
    EBookingOfferTableColumn.Address,
    EBookingOfferTableColumn.Category,
    EBookingOfferTableColumn.ResponsibleFullName,
    EBookingOfferTableColumn.ResponsiblePhone,
    EBookingOfferTableColumn.ResponsibleEmail
  );

  if (isCSP) {
    allColumns.push(EBookingOfferTableColumn.RzdSocialPackage);
  }

  return allColumns.reduce<DataTableColumns<EBookingOfferTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...bookingOffersTableColumns[column],
        hidden: !defaultColumns.includes(column),
      },
    };
  }, {});
};

export const getLabelPartnerType = (partnerType: EPartnerType): string => {
  switch (partnerType) {
    case EPartnerType.Inner:
      return 'Внутренний';
    case EPartnerType.External:
      return 'Внешний';
  }
};

export const getStatesFilterForBookingOfferTableTab = (
  tab: EOfferTableTab,
  defaultStatuses: EOfferStatus[]
): EOfferStatus[] => {
  switch (tab) {
    case EOfferTableTab.Active:
      return [EOfferStatus.Active];

    case EOfferTableTab.Archived:
      return [EOfferStatus.Archived];

    case EOfferTableTab.Moderation:
      return [EOfferStatus.WaitingForModeration, EOfferStatus.OnModeration];

    case EOfferTableTab.Upcoming:
      return [EOfferStatus.Upcoming];

    case EOfferTableTab.Draft:
      return [EOfferStatus.Draft];

    case EOfferTableTab.Paused:
      return [EOfferStatus.Paused];

    case EOfferTableTab.Rejected:
      return [EOfferStatus.Rejected];

    default: {
      return defaultStatuses;
    }
  }
};

enum EBookingOffersDownloadUrlParam {
  Status = 'status',
  PartnerId = 'partner.id',
}

type GetBookingOffersDownloadUrlWithSkipPaginationProps = {
  readonly skipPageable: true;
} & Partial<ApiRequestPageable>;

type GetBookingOffersDownloadUrlWithoutSkipPaginationProps = {
  readonly skipPageable?: false;
} & ApiRequestPageable;

type MabeSkipPaginationGetBookingOffersDownloadUrlProps =
  | GetBookingOffersDownloadUrlWithSkipPaginationProps
  | GetBookingOffersDownloadUrlWithoutSkipPaginationProps;

type GetBookingOffersDownloadUrlProps = {
  readonly id?: Nullable<UUID[]>;
  readonly partnerId?: Nullable<UUID>;
  readonly states?: Nullable<EOfferStatus[]>;
  readonly filter: BookingOfferTableFilterValues;
} & MabeSkipPaginationGetBookingOffersDownloadUrlProps;

export const getBookingOffersDownloadUrl = (props: GetBookingOffersDownloadUrlProps) => {
  const { sort, page, pageSize, id, partnerId, states, filter, skipPageable } = props;

  const searchParams = new URLSearchParams();

  if (sort) {
    if (typeof sort === 'string') {
      searchParams.append('sort', sort);
    } else {
      sort.forEach(item => searchParams.append('sort', item));
    }
  }

  if (skipPageable) {
    searchParams.append('skipPageable', skipPageable.toString());
  } else {
    page && searchParams.append('page', (page - 1).toString(10));
    pageSize && searchParams.append('size', pageSize.toString(10));
  }

  if (id && id.length > 0) {
    id.forEach(item => searchParams.append('id', item));
  } else {
    const queryDsl = getQueryDslByDataFilterValues(filter) ?? [];

    if (states) {
      queryDsl.push({
        field: EBookingOffersDownloadUrlParam.Status,
        operator: DataFilterQueryDslOperator.In,
        value: states,
      });
    }

    if (partnerId) {
      queryDsl.push({
        field: EBookingOffersDownloadUrlParam.PartnerId,
        operator: DataFilterQueryDslOperator.Equals,
        value: [partnerId],
      });
    }

    appendQueryDslParams(searchParams, queryDsl);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return `${getBookingOffersDownloadEndpoint()}${search}`;
};
