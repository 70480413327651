import { DataTreeItem, OfferCategory } from '../../../../domain/model';

export const buildBookingOfferCategoryTree = (
  bookingOfferCategories: OfferCategory[]
): DataTreeItem<OfferCategory>[] => {
  return bookingOfferCategories
    .filter(item => !item.parentId)
    .map(bookingOfferCategory => buildTreeItem(bookingOfferCategory, bookingOfferCategories));
};

const buildTreeItem = (
  bookingOfferCategory: OfferCategory,
  bookingOfferCategories: OfferCategory[]
): DataTreeItem<OfferCategory> => {
  return {
    id: bookingOfferCategory.id,
    label: bookingOfferCategory.name,
    parentId: bookingOfferCategory.parentId,
    data: bookingOfferCategory,
    children:
      bookingOfferCategories
        .filter(c => c.parentId === bookingOfferCategory.id)
        .map(c => buildTreeItem(c, bookingOfferCategories)) ?? [],
  };
};
