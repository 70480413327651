import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../data/store/store';
import { ESortDirection } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { addSearchParamToLocation } from '../../../../../routing/globalRouting';
import { PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import { ETeamUrlParam } from '../../entry';
import { ETeamTableFilterItem, TeamTableFilterEditStrategy, TeamTableFilterValues } from '../../filterUtils';
import { teamsGuidSelector } from '../store/selectors';
import {
  teamsDataReset,
  teamsSetFilter,
  teamsSetPage,
  teamsSetPageSize,
  teamsSetSort,
  teamsSortReset,
} from '../store/slice';
import { ETeamTableTab } from '../utils';

export type UseTeamsTable = {
  readonly onChangeTab: (newTab: ETeamTableTab) => void;
  readonly onChangeFilter: (strategies: TeamTableFilterEditStrategy[]) => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
  readonly onResetSort: () => void;
  readonly onChangePage: (page: number) => void;
  readonly onChangePageSize: (pageSize: PaginationSize) => void;
};

type UseTeamsTableHandlersProps = {
  readonly tab: ETeamTableTab;
};

export function useTeamsTableHandlers({ tab }: UseTeamsTableHandlersProps): UseTeamsTable {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const getTeamsTableGuidFromState = useCallback((): Nullable<UUID> => {
    const state = store.getState();
    return teamsGuidSelector(state);
  }, [store]);

  const onChangeTab = useCallback(
    (newTab: ETeamTableTab) => {
      if (newTab !== tab) {
        const guid = getTeamsTableGuidFromState();
        dispatch(teamsDataReset());
        history.replace(
          addSearchParamToLocation({
            location,
            param: ETeamUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [dispatch, history, location, tab, getTeamsTableGuidFromState]
  );

  const onChangeFilter = useCallback(
    (strategies: TeamTableFilterEditStrategy[]) => {
      dispatch(
        teamsSetFilter(getDataFilterValuesByStrategies<ETeamTableFilterItem, TeamTableFilterValues>(strategies))
      );
    },
    [dispatch]
  );

  const onChangeSort = useCallback(
    (name: string, direction: ESortDirection) => {
      dispatch(
        teamsSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onResetSort = useCallback(() => dispatch(teamsSortReset()), [dispatch]);

  const onChangePage = useCallback(
    (page: number) => {
      dispatch(teamsSetPage({ pageNumber: page }));
    },
    [dispatch]
  );

  const onChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(teamsSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  return {
    onChangeTab,
    onChangeFilter,
    onChangeSort,
    onResetSort,
    onChangePage,
    onChangePageSize,
  };
}
