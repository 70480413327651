import { Nullable } from '../../../../../domain/model/types';
import { SportUserData } from '../../../../../domain/model/user';
import { OnChangeObjectAttribute } from '../../../../types';
import { ValidationResult } from '../../../../utils/validation';
import { ColumnWrapper, StepWrapper, Wrapper } from './controls';
import UserMpStepProfile from './steps/profile';
import { EUserSportEditStep } from './utils';

interface UserSportEditProps {
  readonly step: EUserSportEditStep;
  readonly user: SportUserData;
  readonly validationProfile: Nullable<ValidationResult<SportUserData>>;
  readonly isValidProfile: boolean;
  readonly onChangeProfileAttribute: OnChangeObjectAttribute<SportUserData>;
}

const UserSportEdit = (props: UserSportEditProps) => {
  const { step, user, validationProfile, isValidProfile, onChangeProfileAttribute } = props;

  return (
    <Wrapper>
      <StepWrapper>
        <ColumnWrapper>
          {step === EUserSportEditStep.Profile && (
            <UserMpStepProfile
              user={user}
              validation={validationProfile}
              isValid={isValidProfile}
              onChangeAttribute={onChangeProfileAttribute}
            />
          )}
        </ColumnWrapper>
      </StepWrapper>
    </Wrapper>
  );
};

export default UserSportEdit;
