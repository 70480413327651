import { EOfferStatus } from '../../../../../domain/model/enums';
import { EBookingActionType } from '../../types';
import { BookingLifeCycleStatusScheme } from '../types';

export const adminStatusScheme: BookingLifeCycleStatusScheme = {
  description: 'Статусная схема главного админа',
  statusScheme: {
    [EOfferStatus.Draft]: [EBookingActionType.Delete],
    [EOfferStatus.WaitingForModeration]: [
      EBookingActionType.ChangeModerator,
      EBookingActionType.InWork,
      EBookingActionType.Delete,
    ],
    [EOfferStatus.OnModeration]: [
      EBookingActionType.Approve,
      EBookingActionType.Reject,
      EBookingActionType.Edit,
      EBookingActionType.ChangeModerator,
      EBookingActionType.Delete,
    ],
    [EOfferStatus.Active]: [
      EBookingActionType.Edit,
      EBookingActionType.Pause,
      EBookingActionType.Archive,
      EBookingActionType.Delete,
    ],
    [EOfferStatus.Paused]: [
      EBookingActionType.Edit,
      EBookingActionType.Resume,
      EBookingActionType.Archive,
      EBookingActionType.Delete,
    ],
    [EOfferStatus.Rejected]: [EBookingActionType.ReturnToVerification, EBookingActionType.Delete],
    [EOfferStatus.Archived]: [EBookingActionType.Retrieve, EBookingActionType.Delete],

    [EOfferStatus.Upcoming]: [],
    [EOfferStatus.ReadyForSale]: [],
  },
  actionsScheme: {} as any,
};

export const partnerStatusScheme: BookingLifeCycleStatusScheme = {
  description: 'Статусная схема пользователя партнёра',
  statusScheme: {
    [EOfferStatus.Draft]: [
      EBookingActionType.Edit,
      EBookingActionType.Publish,
      EBookingActionType.Duplicate,
      EBookingActionType.Delete,
      EBookingActionType.Archive,
    ],
    [EOfferStatus.WaitingForModeration]: [
      EBookingActionType.Duplicate,
      EBookingActionType.UnPublish,
      EBookingActionType.Delete,
    ],
    [EOfferStatus.OnModeration]: [EBookingActionType.Duplicate, EBookingActionType.Delete],
    [EOfferStatus.Active]: [
      EBookingActionType.Edit,
      EBookingActionType.Pause,
      EBookingActionType.Duplicate,
      EBookingActionType.Archive,
      EBookingActionType.ManageServices,
      EBookingActionType.SaveNewVersion,
      EBookingActionType.Delete,
    ],
    [EOfferStatus.Paused]: [
      EBookingActionType.Edit,
      EBookingActionType.Resume,
      EBookingActionType.Duplicate,
      EBookingActionType.Archive,
      EBookingActionType.ManageServices,
      EBookingActionType.SaveNewVersion,
      EBookingActionType.Delete,
    ],
    [EOfferStatus.Rejected]: [
      EBookingActionType.Edit,
      EBookingActionType.Duplicate,
      EBookingActionType.Archive,
      EBookingActionType.Publish,
      EBookingActionType.Delete,
    ],
    [EOfferStatus.Archived]: [EBookingActionType.Duplicate, EBookingActionType.Retrieve, EBookingActionType.Delete],

    [EOfferStatus.ReadyForSale]: [],
    [EOfferStatus.Upcoming]: [],
  },
  actionsScheme: {} as any,
};
