import { AppUserSpecific, Banner, EBannerPartition, EBannerStatus } from '@/domain';
import { DataTableColumns } from '@components/common/table';
import { TableActionProcess } from '@components/common/table/types';
import {
  BannerActions,
  BannerActionTableCommonType,
  BannerActionTableType,
  EBannerActionType,
  GetBannerActionsByPermissions,
} from '../types';
import { getBannerActionsConfigByPermissions } from '../utils';

export type BannerTableActionProcess = TableActionProcess<BannerActionTableType>;

export enum EBannerTableTab {
  Active = 'active',
  Upcoming = 'upcoming',
  Paused = 'paused',
  Draft = 'draft',
  Archived = 'archived',
}

export type BannerTableTabsCounter = {
  [name in EBannerTableTab]?: number;
};

export enum EBannerTableUrlParam {
  Tab = 'tab',
}

export enum EBannerTableColumn {
  Image = 'image',
  Name = 'name',
  SortIndex = 'sortIndex',
  Code = 'orderNumber',
  Places = 'places',
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export const bannerTableColumns: DataTableColumns<EBannerTableColumn> = {
  [EBannerTableColumn.Name]: {
    title: 'Название',
    width: '25rem',
    wrap: true,
    sortable: true,
  },
  [EBannerTableColumn.Places]: {
    title: 'Формат',
    width: '15rem',
    wrap: true,
  },
  [EBannerTableColumn.SortIndex]: {
    title: 'Место',
    width: '3rem',
    sortable: true,
  },
  [EBannerTableColumn.Code]: {
    title: 'Код',
    width: '6rem',
    sortable: true,
  },
  [EBannerTableColumn.Image]: {
    title: 'Изображение',
    width: '4rem',
  },
  [EBannerTableColumn.StartDate]: {
    title: 'Начало',
    sortable: true,
  },
  [EBannerTableColumn.EndDate]: {
    title: 'Окончание',
    sortable: true,
  },
};

export const getBannerTableColumns = (tab: EBannerTableTab): DataTableColumns<EBannerTableColumn> => {
  const defaultColumns = getBannerTableDefaultColumns(tab);

  const allColumns: EBannerTableColumn[] = [];
  switch (tab) {
    case EBannerTableTab.Active:
      allColumns.push(
        EBannerTableColumn.Image,
        EBannerTableColumn.Code,
        EBannerTableColumn.SortIndex,
        EBannerTableColumn.Places,
        EBannerTableColumn.Name,
        EBannerTableColumn.StartDate,
        EBannerTableColumn.EndDate
      );
      break;
    default:
      allColumns.push(
        EBannerTableColumn.Image,
        EBannerTableColumn.Code,
        EBannerTableColumn.Places,
        EBannerTableColumn.Name,
        EBannerTableColumn.StartDate,
        EBannerTableColumn.EndDate
      );
  }

  return allColumns.reduce<DataTableColumns<EBannerTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...bannerTableColumns[column],
        hidden: !defaultColumns.includes(column),
      },
    };
  }, {});
};

export const getBannerTableStatusesByTab = (t: EBannerTableTab): EBannerStatus[] => {
  switch (t) {
    case EBannerTableTab.Active:
      return [EBannerStatus.Active];
    case EBannerTableTab.Upcoming:
      return [EBannerStatus.Upcoming];
    case EBannerTableTab.Paused:
      return [EBannerStatus.Paused];
    case EBannerTableTab.Draft:
      return [EBannerStatus.Draft];
    case EBannerTableTab.Archived:
      return [EBannerStatus.Archived];
  }
};

export const getBannerTableTabName = (tab: EBannerTableTab): string => {
  switch (tab) {
    case EBannerTableTab.Active:
      return 'Опубликованные';
    case EBannerTableTab.Upcoming:
      return 'Предстоящие';
    case EBannerTableTab.Draft:
      return 'Черновики';
    case EBannerTableTab.Paused:
      return 'Приостановленные';
    case EBannerTableTab.Archived:
      return 'Архив';
  }
};

// фильтрация действий для таблицы баннеров по всем действиям
const filterBannerTableActions = (actions: BannerActions<EBannerActionType>): BannerActions<BannerActionTableType> => {
  const tableActions: BannerActions<BannerActionTableType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EBannerActionType.Archive:
      case EBannerActionType.Pause:
      case EBannerActionType.Resume:
      case EBannerActionType.ChangeSortIndex:
        tableActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          position: action.position,
        });
        break;
      case EBannerActionType.Activate:
      case EBannerActionType.Save:
      case EBannerActionType.Edit:
        break;
    }
  });

  return tableActions;
};

// получение возможных действий с быннером для вкладки таблицы
const getBannerTableTabActionsConfig = (
  tab: EBannerTableTab,
  actions: BannerActions<BannerActionTableType>
): BannerActions<BannerActionTableType> => {
  switch (tab) {
    case EBannerTableTab.Active:
      return actions.filter(item =>
        [EBannerActionType.Pause, EBannerActionType.Archive, EBannerActionType.ChangeSortIndex].includes(item.type)
      );
    case EBannerTableTab.Upcoming:
      return actions.filter(item => [EBannerActionType.Pause, EBannerActionType.Archive].includes(item.type));
    case EBannerTableTab.Paused:
      return actions.filter(item => [EBannerActionType.Resume].includes(item.type));
    case EBannerTableTab.Draft:
    case EBannerTableTab.Archived:
      return [];
  }
};

export const getBannerTableTabActions = (
  permissions: GetBannerActionsByPermissions,
  tab: EBannerTableTab
): BannerActions<BannerActionTableType> => {
  const allowedActions = getBannerActionsConfigByPermissions(permissions);
  const actions = filterBannerTableActions(allowedActions);

  return getBannerTableTabActionsConfig(tab, actions);
};

export const getIsBannerDisabled = (banner: Banner, user: AppUserSpecific): boolean =>
  !!banner.lock && user.id !== banner.lock.lockedBy.id;

export const getBannerTableHeaderText = (partition: EBannerPartition) => {
  switch (partition) {
    case EBannerPartition.TradeOffers:
      return 'Рекламные баннеры ТП';
    case EBannerPartition.CorpOffers:
      return 'Рекламные баннеры КП';
  }
};

export type BannerActionCall =
  | {
      readonly action: EBannerActionType.ChangeSortIndex;
      readonly banner: Banner;
      readonly sortIndex: number;
    }
  | {
      readonly action: BannerActionTableCommonType;
      readonly banner: Banner;
    };

const bannerTableDefaultColumns = [
  EBannerTableColumn.Image,
  EBannerTableColumn.Code,
  EBannerTableColumn.SortIndex,
  EBannerTableColumn.Places,
  EBannerTableColumn.Name,
  EBannerTableColumn.StartDate,
  EBannerTableColumn.EndDate,
];

const getBannerTableDefaultColumns = (tab: EBannerTableTab): EBannerTableColumn[] => {
  return bannerTableDefaultColumns;
};
