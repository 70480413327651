import sport, { LegalAgreementSportService } from './sport';
import coms, { LegalAgreementMpService } from './coms';

type LegalServices = {
  sport: LegalAgreementSportService;
  mp: LegalAgreementMpService;
};

const legalServices: LegalServices = {
  sport,
  mp: coms,
};

export default legalServices;
