import { ClientOrgMultiSelectorPredicate } from '@/domain';
import { useCallback, useState } from 'react';
import useValidation from '../../../hooks/validation/useValidation';
import { OnChangeObjectAttribute } from '../../../types';
import { getPrivilegeTestCmsPath } from '../../../utils';
import { ValidationResult } from '../../../utils/validation';
import { CmsFeatureContext } from '../container/types';
import { CmsPreviewTarget, CmsPreviewTargetExportData, ECmsPreviewTargetUrlParam } from './types';
import { convertCmsPreviewTargetToExportTarget, getEmptyCmsPreviewTarget, openCmsPreviewWindow } from './utils';
import { cmsPreviewTargetValidation } from './validation';

interface UsePreviewTargetData {
  readonly target: CmsPreviewTarget;
  readonly isTargetFilled: boolean;
  readonly validation: Nullable<ValidationResult<CmsPreviewTarget>>;
  readonly onChangeAttribute: OnChangeObjectAttribute<CmsPreviewTarget>;
  readonly onAudienceChange: (
    externalUsers: Nullable<boolean>,
    clientOrgs: Nullable<ClientOrgMultiSelectorPredicate>
  ) => void;
  readonly onPreview: () => void;
}

interface UsePreviewTargetDataProps {
  readonly cmsContext?: CmsFeatureContext;
}

const usePreviewTargetData = (props: UsePreviewTargetDataProps): UsePreviewTargetData => {
  const { cmsContext } = props;
  const [cmsTarget, setCmsTarget] = useState<CmsPreviewTarget>(getEmptyCmsPreviewTarget());

  const { validationResult: validation, isValid: isTargetFilled } = useValidation<CmsPreviewTarget>({
    object: cmsTarget,
    validateOnChange: true,
    rules: cmsPreviewTargetValidation,
  });

  const onAudienceChange: UsePreviewTargetData['onAudienceChange'] = (externalUsers, clientOrgs) => {
    setCmsTarget({ ...getEmptyCmsPreviewTarget(), externalUsers, clientOrgs });
  };

  const onChangeAttribute: OnChangeObjectAttribute<CmsPreviewTarget> = (name, value) => {
    setCmsTarget(prevTarget => {
      const newTarget = { ...prevTarget };
      newTarget[name] = value;
      return newTarget;
    });
  };

  const onPreview = useCallback(() => {
    const path = getPrivilegeTestCmsPath();
    const cmsTargetExport: CmsPreviewTargetExportData = convertCmsPreviewTargetToExportTarget(cmsTarget);

    const searchParams = new URLSearchParams();

    searchParams.append(ECmsPreviewTargetUrlParam.Target, JSON.stringify(cmsTargetExport));
    if (cmsContext) {
      searchParams.append(ECmsPreviewTargetUrlParam.Context, JSON.stringify(cmsContext));
    }
    const search = searchParams.toString();

    openCmsPreviewWindow(`${path}?${search}`);
  }, [cmsTarget, cmsContext]);

  return {
    target: cmsTarget,
    isTargetFilled,
    validation,
    onChangeAttribute,
    onAudienceChange,
    onPreview,
  };
};

export default usePreviewTargetData;
