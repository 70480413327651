import {
  ApprovalOfferRegistryRequest,
  CorpOffer,
  EOfferStatus,
  ESortDirection,
  MpUser,
  Nullable,
  SportOption,
} from '@/domain';
import { addSearchParamToLocation } from '@/routing/globalRouting';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../data/store/store';
import { PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import { EOfferTableTab } from '../../../general/offer/types';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { ECorpOfferUrlParam } from '../../entry';
import { CorpOfferTableFilterEditStrategy } from '../../filterUtils';
import { corpOffersGuidSelector } from '../store/selectors';
import {
  CorpOfferListState,
  corpOffersAllSelect,
  corpOffersAllUnselect,
  corpOffersApproveCorpOffer,
  corpOffersApproveCorpOfferCollection,
  corpOffersArchiveCorpOffer,
  corpOffersChangeCorpOfferApprovalRegistry,
  corpOffersChangeCorpOfferCollectionApprovalRegistry,
  corpOffersChangeModerator,
  corpOffersClearNotifierInfo,
  corpOffersDataReset,
  corpOffersDeleteCorpOffer,
  corpOffersDuplicate,
  corpOffersPauseCorpOffer,
  corpOffersPublishReject,
  corpOffersResumeCorpOffer,
  corpOffersRetrieveCorpOffer,
  corpOffersSelect,
  corpOffersSetDialogState,
  corpOffersSetFilter,
  corpOffersSetPage,
  corpOffersSetPageSize,
  corpOffersSetSort,
  corpOffersSortReset,
  corpOffersUnselect,
} from '../store/slice';

export type UseCorpOffersTable = {
  readonly onCorpOfferSelect: (corpOffer: CorpOffer, selected: boolean) => void;
  readonly onAllCorpOffersSelect: (selected: boolean) => void;
  readonly onChangeTab: (newTab: EOfferTableTab) => void;
  readonly onChangeFilter: (strategies: CorpOfferTableFilterEditStrategy[]) => void;
  readonly onNotificationRead: () => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
  readonly onResetSort: () => void;
  readonly isCorpOfferNotModeratedByMe: (corpOffer: CorpOffer) => boolean;
  readonly onChangeDialogState: (dialog: keyof CorpOfferListState['dialogs'], data: Nullable<CorpOffer>) => void;
  readonly onCorpOfferReject: (corpOffer: CorpOffer, reason: SportOption, comment?: string) => Promise<boolean>;
  readonly onCorpOfferPause: (corpOffer: CorpOffer, reason: SportOption, comment?: string) => Promise<boolean>;
  readonly onCorpOfferArchive: (corpOffer: CorpOffer) => Promise<boolean>;
  readonly onCorpOfferRetrieve: (corpOffer: CorpOffer) => Promise<boolean>;
  readonly onCorpOfferDelete: (corpOffer: CorpOffer) => Promise<boolean>;
  readonly onCorpOfferResume: (corpOffer: CorpOffer) => Promise<boolean>;
  readonly onCorpOfferApprove: (corpOffer: CorpOffer) => Promise<boolean>;
  readonly onCorpOffersApprove: (corpOffers: CorpOffer[]) => Promise<boolean>;
  readonly onCorpOfferChangeModerator: (corpOffer: CorpOffer, user: MpUser) => Promise<boolean>;
  readonly onCorpOfferChangeApprovalRegistry: (
    corpOffer: CorpOffer,
    approvalRegistry: ApprovalOfferRegistryRequest
  ) => Promise<boolean>;
  readonly onCorpOffersChangeApprovalRegistry: (
    corpOffers: CorpOffer[],
    approvalRegistry: ApprovalOfferRegistryRequest
  ) => Promise<boolean>;
  readonly onCorpOffersChangePage: (page: number) => void;
  readonly onCorpOffersChangePageSize: (pageSize: PaginationSize) => void;
  readonly onCorpOfferDuplicate: (corpOffer: CorpOffer) => Promise<Nullable<CorpOffer>>;
};

type UseCorpOffersTableHandlersProps = {
  readonly tab: EOfferTableTab;
};

export function useCorpOffersTableHandlers({ tab }: UseCorpOffersTableHandlersProps): UseCorpOffersTable {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const {
    userSpecific: { id: userId },
    accessMatrix,
  } = useCurrentUser();

  const getCorpOffersGuidFromState = useCallback((): Nullable<UUID> => {
    const state = store.getState();
    return corpOffersGuidSelector(state);
  }, [store]);

  const onCorpOfferSelect = useCallback(
    (corpOffer: CorpOffer, selected: boolean) => {
      if (selected) {
        dispatch(corpOffersSelect(corpOffer));
      } else {
        dispatch(corpOffersUnselect(corpOffer));
      }
    },
    [dispatch]
  );

  const onAllCorpOffersSelect = useCallback(
    (selected: boolean) => {
      if (selected) {
        dispatch(corpOffersAllSelect());
      } else {
        dispatch(corpOffersAllUnselect());
      }
    },
    [dispatch]
  );

  const onChangeFilter = useCallback(
    (strategies: CorpOfferTableFilterEditStrategy[]) => {
      dispatch(corpOffersSetFilter(getDataFilterValuesByStrategies(strategies)));
    },
    [dispatch]
  );

  const onNotificationRead = useCallback(() => {
    dispatch(corpOffersClearNotifierInfo());
  }, [dispatch]);

  const onCorpOfferChangeApprovalRegistry = useCallback(
    async ({ id }: CorpOffer, approvalRegistry: ApprovalOfferRegistryRequest) => {
      await dispatch(corpOffersChangeCorpOfferApprovalRegistry({ id, approvalRegistry })).unwrap();
      return true;
    },
    [dispatch]
  );

  const onCorpOffersChangeApprovalRegistry = useCallback(
    async (offers: CorpOffer[], approvalRegistry: ApprovalOfferRegistryRequest) => {
      await dispatch(
        corpOffersChangeCorpOfferCollectionApprovalRegistry({
          ids: offers.map(offer => offer.id),
          approvalRegistry,
        })
      ).unwrap();
      return true;
    },
    [dispatch]
  );

  const onChangeSort = useCallback(
    (name: string, direction: ESortDirection) => {
      dispatch(
        corpOffersSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onResetSort = useCallback(() => dispatch(corpOffersSortReset()), [dispatch]);

  const isCorpOfferNotModeratedByMe = useCallback(
    (corpOffer: CorpOffer) => {
      return (
        !!accessMatrix.corpOffers.moderate &&
        corpOffer.status === EOfferStatus.OnModeration &&
        corpOffer.approvingAdmin?.id !== userId
      );
    },
    [userId, accessMatrix.corpOffers.moderate]
  );

  const onChangeDialogState = useCallback(
    (dialog: keyof CorpOfferListState['dialogs'], data: Nullable<CorpOffer>) => {
      dispatch(corpOffersSetDialogState({ name: dialog, data }));
    },
    [dispatch]
  );

  const onCorpOfferReject = useCallback(
    async ({ id }: CorpOffer, reason: SportOption, comment?: string) => {
      const response = await dispatch(
        corpOffersPublishReject({
          id,
          reason,
          comment,
        })
      ).unwrap();
      return !!response?.id;
    },
    [dispatch]
  );

  const onCorpOfferPause = useCallback(
    async ({ id }: CorpOffer, reason: SportOption, comment?: string) => {
      const response = await dispatch(
        corpOffersPauseCorpOffer({
          id,
          reason,
          comment,
        })
      ).unwrap();
      return !!response?.id;
    },
    [dispatch]
  );

  const onCorpOfferArchive = useCallback(
    async ({ id }: CorpOffer) => {
      const response = await dispatch(corpOffersArchiveCorpOffer({ id })).unwrap();
      return !!response?.id;
    },
    [dispatch]
  );

  const onCorpOfferRetrieve = useCallback(
    async ({ id }: CorpOffer) => {
      const response = await dispatch(corpOffersRetrieveCorpOffer({ id })).unwrap();
      return !!response?.id;
    },
    [dispatch]
  );

  const onCorpOfferDelete = useCallback(
    async ({ id }: CorpOffer) => {
      await dispatch(corpOffersDeleteCorpOffer({ id })).unwrap();
      return true;
    },
    [dispatch]
  );

  const onCorpOfferResume = useCallback(
    async ({ id }: CorpOffer) => {
      const response = await dispatch(corpOffersResumeCorpOffer({ id })).unwrap();
      return !!response?.id;
    },
    [dispatch]
  );

  const onCorpOfferApprove = useCallback(
    async ({ id }: CorpOffer) => {
      const response = await dispatch(corpOffersApproveCorpOffer({ id })).unwrap();
      return !!response?.id;
    },
    [dispatch]
  );

  const onCorpOffersApprove = useCallback(
    async (corpOffers: CorpOffer[]) => {
      await dispatch(corpOffersApproveCorpOfferCollection({ ids: corpOffers.map(offer => offer.id) })).unwrap();
      return true;
    },
    [dispatch]
  );

  const onCorpOfferChangeModerator = useCallback(
    async ({ id }: CorpOffer, { id: userId }: MpUser) => {
      const response = await dispatch(corpOffersChangeModerator({ id, userId })).unwrap();
      return !!response?.id;
    },
    [dispatch]
  );

  const onCorpOffersChangePage = useCallback(
    (page: number) => {
      dispatch(corpOffersSetPage({ pageNumber: page }));
    },
    [dispatch]
  );

  const onCorpOffersChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(corpOffersSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onChangeTab = useCallback(
    (newTab: EOfferTableTab) => {
      if (newTab !== tab) {
        const guid = getCorpOffersGuidFromState();
        dispatch(corpOffersDataReset());
        history.replace(
          addSearchParamToLocation({
            location,
            param: ECorpOfferUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [dispatch, history, location, tab, getCorpOffersGuidFromState]
  );

  const onCorpOfferDuplicate = useCallback(
    async (corpOffer: CorpOffer) => {
      const response = await dispatch(corpOffersDuplicate({ id: corpOffer.id })).unwrap();

      return response ?? null;
    },
    [dispatch]
  );

  return {
    onCorpOfferSelect,
    onAllCorpOffersSelect,
    onChangeFilter,
    onNotificationRead,
    onCorpOfferChangeApprovalRegistry,
    onCorpOffersChangeApprovalRegistry,
    onChangeTab,
    onChangeSort,
    onResetSort,
    isCorpOfferNotModeratedByMe,
    onChangeDialogState,
    onCorpOfferReject,
    onCorpOfferPause,
    onCorpOfferArchive,
    onCorpOfferRetrieve,
    onCorpOfferChangeModerator,
    onCorpOfferDelete,
    onCorpOfferResume,
    onCorpOfferApprove,
    onCorpOffersApprove,
    onCorpOffersChangePage,
    onCorpOffersChangePageSize,
    onCorpOfferDuplicate,
  };
}
