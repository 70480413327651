import { Grid } from '@mui/material';
import React from 'react';
import { Nullable } from '../../../../domain/model/types';
import CloseButton from '../buttons/close';
import { CloseButtonWrapper, HeadlineWrapper, Wrapper } from './controls';

interface DefaultHeaderProps {
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly headline?: Nullable<React.ReactNode>;
  readonly actions?: Nullable<React.ReactNode>;
  readonly sticky?: boolean;
  readonly onClose?: () => void;
}

const DefaultHeader = React.forwardRef((props: DefaultHeaderProps, ref: any) => {
  const { children, className, headline, actions, sticky = false, onClose } = props;

  return (
    <Wrapper
      className={className}
      sticky={sticky}
      ref={ref}
    >
      <HeadlineWrapper>{headline}</HeadlineWrapper>
      <Grid
        container
        alignItems='flex-start'
        justifyContent='space-between'
      >
        <Grid item>{children}</Grid>
        {(onClose || actions) && (
          <Grid item>
            <Grid
              container
              spacing={4}
              alignItems='center'
            >
              {actions && <Grid item>{actions}</Grid>}
              {onClose && (
                <Grid item>
                  <CloseButtonWrapper>
                    <CloseButton onClick={onClose} />
                  </CloseButtonWrapper>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Wrapper>
  );
});

export default DefaultHeader;
