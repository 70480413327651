import { CommonLifeCycleInstance } from '../../../utils/lifecycle';
import { EBookingActionType } from '../types';
import { commonAdminConfiguration, commonPartnerConfiguration } from './configurations';
import { BookingLifeCycle, BookingLifeCycleFactoryProps, BookingLifeCycleProps, BookingLifeCycleType } from './types';

/**
 * жизненный цикл объекта ДиО
 */
const BookingLifeCycleInstance = (props: BookingLifeCycleProps<BookingLifeCycleType>): BookingLifeCycle => {
  const { configuration, userId, isPartnerUser } = props;

  const commonLifecycle = CommonLifeCycleInstance(props);

  return {
    configuration,
    build: ({ allowedActions, obj }) => {
      const commonLifecycleBuild = commonLifecycle.build({ allowedActions, obj });
      const { can } = commonLifecycleBuild;

      const edit = can(EBookingActionType.Edit);

      return {
        ...commonLifecycleBuild,
        canEdit: edit,
      };
    },
    isPartnerUser,
    userId,
  };
};

/**
 * жизненный цикл объекта ДиО - основная модель
 */
export const BookingLifeCycleFactory = () => {
  return {
    create: ({ isPartnerUser, userId, debug }: BookingLifeCycleFactoryProps): BookingLifeCycle => {
      if (isPartnerUser) {
        return BookingLifeCycleInstance({ userId, configuration: commonPartnerConfiguration, debug, isPartnerUser });
      } else {
        return BookingLifeCycleInstance({ userId, configuration: commonAdminConfiguration, debug, isPartnerUser });
      }
    },
  };
};
