import moment from 'moment';
import { EDateFormat, ESortDirection, PartnerShort } from '@/domain';
import DataTable, { DataTableMetadata } from '../../../components/common/table';
import DataTableCellTextFormat from '../../../components/common/table/cell/textFormat';
import { getUserFullName } from '../../../utils';
import { EPartnerTableColumn } from '../../general/partner/types';
import { Wrapper } from './controls';
import { DataTableLoader } from '@components/common/table/loader';
import { partnersSearchIsFetchingSelector } from '@features/partner/search/store/selectors';

interface PartnersSearchProps {
  readonly metadata: DataTableMetadata<EPartnerTableColumn>;
  readonly partners: PartnerShort[];
  readonly selectedPartners: PartnerShort[];
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onChangeMetadata: (metadata: DataTableMetadata<EPartnerTableColumn>) => void;
  readonly onClick: (partner: PartnerShort) => void;
  readonly isSingleSelect?: boolean;
  readonly onPartnerSelect: (partner: PartnerShort, selected: boolean) => void;
  readonly onAllPartnersSelect: (selected: boolean) => void;
}

const PartnersSearch = (props: PartnersSearchProps) => {
  const {
    sort,
    metadata,
    partners,
    selectedPartners,
    isSingleSelect,
    onRequestSort,
    onClick,
    onChangeMetadata,
    onPartnerSelect,
    onAllPartnersSelect,
  } = props;

  const rows = partners.map(partner => {
    const { name, owner, partnerReviewer, lastStatusDate } = partner;

    const contact = owner ? [owner.lastName, owner.firstName, owner.middleName].filter(item => !!item).join(' ') : '';

    return {
      [EPartnerTableColumn.Date]: (
        <DataTableCellTextFormat>
          {lastStatusDate ? moment(lastStatusDate).format(EDateFormat.DisplayDefault) : '-'}
        </DataTableCellTextFormat>
      ),
      [EPartnerTableColumn.Name]: <DataTableCellTextFormat>{name}</DataTableCellTextFormat>,
      [EPartnerTableColumn.OwnerName]: <DataTableCellTextFormat>{contact}</DataTableCellTextFormat>,
      [EPartnerTableColumn.OwnerPhone]: <DataTableCellTextFormat>{owner?.phone ?? ''}</DataTableCellTextFormat>,
      [EPartnerTableColumn.OwnerEmail]: <DataTableCellTextFormat>{owner?.email ?? ''}</DataTableCellTextFormat>,
      [EPartnerTableColumn.Reviewer]: (
        <DataTableCellTextFormat>
          {partnerReviewer && getUserFullName({ user: partnerReviewer })}
        </DataTableCellTextFormat>
      ),
      [EPartnerTableColumn.Actions]: null,
      data: partner,
    };
  });

  return (
    <Wrapper>
      <DataTable<PartnerShort, EPartnerTableColumn>
        width='auto'
        metadata={metadata}
        rows={rows}
        sort={{ column: sort.column, direction: sort.direction }}
        loader={<DataTableLoader selector={partnersSearchIsFetchingSelector} />}
        selectable
        singleSelect={isSingleSelect}
        selected={selectedPartners}
        onChangeMetadata={onChangeMetadata}
        onRowClick={(event, cell, { data }) => onClick(data)}
        onSort={(event, column, direction) => onRequestSort(column, direction)}
        onAllRowsSelect={(event, selected) => onAllPartnersSelect(selected)}
        onRowSelect={(event, { data }, selected) => onPartnerSelect(data, selected)}
      />
    </Wrapper>
  );
};

export default PartnersSearch;
