import React from 'react';
import { UseSportsmansSearch } from './hooks/useSportsmanSearch';
import { UseSportsmansSearchConfig } from './hooks/useSportsmanSearchConfig';

export type SportsmansSearchContextValue = UseSportsmansSearch;

export const SportsmansSearchContext = React.createContext<SportsmansSearchContextValue>(
  {} as SportsmansSearchContextValue
);

export type SportsmansSearchConfigContextValue = UseSportsmansSearchConfig;

export const SportsmansSearchConfigContext = React.createContext<SportsmansSearchConfigContextValue>(
  {} as SportsmansSearchConfigContextValue
);
