import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CmsSitePage } from '../../../../../domain/model/cms';
import { UUID } from '../../../../../domain/model/types';
import ErrorComponent from '../../../../components/common/error';
import ContentLoader from '../../../../components/common/loader';
import { ContainerWrapper } from './controls';
import { cmsSitePageForPartnerSelector } from './store/selectors';
import { cmsSitePageForPartnerFetch, cmsSitePageForPartnerStateReset } from './store/slice';

interface CmsSitePageForPartnerContainerResult {
  readonly cmsSitePage: CmsSitePage;
}

interface CmsSitePageForPartnerContainerProps {
  readonly partnerId: UUID;
  readonly children: (result: CmsSitePageForPartnerContainerResult) => React.ReactNode;
}

const CmsSitePageForPartnerContainer = ({ partnerId, children }: CmsSitePageForPartnerContainerProps) => {
  const dispatch = useDispatch();

  const { data: cmsSitePage, isFetching, isFailed } = useSelector(cmsSitePageForPartnerSelector);

  useEffect(() => {
    dispatch(cmsSitePageForPartnerFetch(partnerId));

    return () => {
      dispatch(cmsSitePageForPartnerStateReset());
    };
  }, [dispatch, partnerId]);

  if (isFailed) {
    return (
      <ContainerWrapper>
        <ErrorComponent />
      </ContainerWrapper>
    );
  }

  if (isFetching || !cmsSitePage) {
    return (
      <ContainerWrapper>
        <ContentLoader />
      </ContainerWrapper>
    );
  }

  return <>{children({ cmsSitePage })}</>;
};

export default CmsSitePageForPartnerContainer;
