import { FC } from 'react';
import { useSelector } from 'react-redux';
import { BookingServicePriceItem } from '../../../../../domain/model/booking';
import { getBookingOfferActionsOfferIsProcessingSelector } from '../../actions/store/selectors';
import { BookingOfferEditFieldServicePriceItemProps } from '../../components/fieldsEdit/types';
import { getEmptyServicePriceItemView } from '../../create/utils';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { useContextParams } from '../hooks/useContextParams';
import {
  bookingOfferServiceManageServicePriceItemByIndexSelector,
  bookingOfferServiceManageValidationServicePriceItemsByIndexSelector,
} from '../store/selectros';

interface BookingOfferCreateServicePriceItemFieldAdapterProps<Key extends keyof BookingServicePriceItem> {
  readonly index: number;
  readonly serviceIndex: number;
  readonly disabled?: boolean;
  readonly name: Key;
  readonly component: FC<BookingOfferEditFieldServicePriceItemProps<Key>>;
}

export const BookingOfferServiceManageServicePriceItemFieldAdapter = <Key extends keyof BookingServicePriceItem>(
  props: BookingOfferCreateServicePriceItemFieldAdapterProps<Key>
) => {
  const { index, serviceIndex, name, component: Component, disabled = false } = props;
  const handlers = useContextHandlers();
  const { id } = useContextParams();
  const isSaving = useSelector(getBookingOfferActionsOfferIsProcessingSelector(id));

  const servicePriceItem = useSelector(bookingOfferServiceManageServicePriceItemByIndexSelector(serviceIndex, index));
  const validation = useSelector(
    bookingOfferServiceManageValidationServicePriceItemsByIndexSelector(serviceIndex, index)
  );

  const internalPriceItem = servicePriceItem ?? getEmptyServicePriceItemView();

  return (
    <Component
      index={index}
      serviceIndex={serviceIndex}
      value={internalPriceItem[name]}
      disabled={disabled || isSaving}
      validation={validation?.[name] ?? null}
      onChangeAttribute={(field, value) =>
        handlers.onChangeServicePriceItemAttribute(serviceIndex, index, field, value)
      }
      onValidateAttribute={field => handlers.onServicePriceItemAttributeValidate(serviceIndex, index, field)}
    />
  );
};
