import { SportOptionTyped } from '../../../../../../../domain/model';
import { EPrivilegeActorType } from '../../../../../../../domain/model/enums';
import OfferFieldHistoryTableCell, { OfferFieldHistoryTableCellCommonProps } from './index';

export type OfferFieldHistoryTableCellActorTypeProps = OfferFieldHistoryTableCellCommonProps & {
  readonly actorTypes: SportOptionTyped<EPrivilegeActorType>[];
};

const OfferFieldHistoryTableCellActorType = (props: OfferFieldHistoryTableCellActorTypeProps) => {
  const {
    fieldHistory: { actorType },
    actorTypes,
  } = props;

  const value = actorTypes.find(at => at.id === actorType)?.name;

  return (
    <OfferFieldHistoryTableCell
      {...props}
      value={value}
    />
  );
};

export default OfferFieldHistoryTableCellActorType;
