import { DataTableDefaultComponents, DataTableRow, DataTableRowData, ETableColumnAlign } from '../../index';
import { TableBodyCellDefaultProps } from '../../controls';
import { Nullable } from '@/domain/model/types';
import React from 'react';

type TabelCellActionsProps<D extends DataTableRowData, R extends string = string> = TableBodyCellDefaultProps & {
  readonly row: DataTableRow<D, R>;
  readonly getRowActions: Nullable<(data: D, index: number) => React.ReactNode>;
  readonly index: number;
  readonly rowActions?: boolean;
  readonly onRowClick?: (event: Event, cell: string, row: DataTableRow<D, R>) => void;
  readonly onRowHover?: (event: Event, cell: string, row: DataTableRow<D, R>, hover: boolean) => void;
};

export const TabelCellActions = <D extends DataTableRowData, R extends string = string>(
  props: TabelCellActionsProps<D, R>
) => {
  const { getRowActions, row, index, rowActions, onRowClick, onRowHover, ...other } = props;

  return (
    <DataTableDefaultComponents.BodyCell
      {...other}
      align={ETableColumnAlign.Center}
      data-table-cell-type={'actions'}
      onMouseEnter={
        onRowHover &&
        ((event: any) => {
          onRowHover(event, 'actions', row, true);
        })
      }
      onMouseLeave={
        onRowHover &&
        ((event: any) => {
          onRowHover(event, 'actions', row, false);
        })
      }
      onClick={
        onRowClick &&
        ((event: any) => {
          onRowClick(event, 'actions', row);
        })
      }
    >
      {rowActions && getRowActions?.(row.data, index)}
    </DataTableDefaultComponents.BodyCell>
  );
};
