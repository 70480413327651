import React from 'react';
import { UseBookingOrderTable } from './hooks/useTable';
import { UseBookingOrderTableConfig } from './hooks/useTableConfig';

export type BookingOrderTableContextValue = UseBookingOrderTable;

export const BookingOrderTableContext = React.createContext<BookingOrderTableContextValue>(
  {} as BookingOrderTableContextValue
);

export type BookingOrderTableConfigContextValue = UseBookingOrderTableConfig;

export const BookingOrderTableConfigContext = React.createContext<BookingOrderTableConfigContextValue>(
  {} as BookingOrderTableConfigContextValue
);
