import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CorpOffer } from '../../../../../../domain/model/corpOffer';
import { ECmsCollectionLinkObjectType } from '../../../../../../domain/model/enums';
import { TradeOffer } from '../../../../../../domain/model/tradeOffer';
import { UUID } from '../../../../../../domain/model/types';
import useDialogInHistory from '../../../../../hooks/useDialogInHistory';
import { EOfferTableTab } from '../../../../general/offer/types';
import {
  CmsLinkedObjectCollectionCorpOfferType,
  CmsLinkedObjectCollectionTradeOfferType,
} from '../../../types/collection';
import { CollectionContentType, ECollectionSearchDialogTag } from './types';

type UseCollectionOfferMultiple<T extends CollectionContentType> = {
  readonly isSearchDialogOpened: boolean;
  readonly searchDialogParams: UseDialogInHistoryParams;
  readonly onOpenSearchDialog: (tab?: EOfferTableTab) => void;
  readonly onCloseSearchDialog: () => void;
  readonly onChangeSearchDialogTab: (tab: EOfferTableTab) => void;
  readonly onSelect: (offers: T[]) => void;
  readonly onUnselect: (id: UUID) => void;
  readonly onClear: () => void;
  readonly onChangeSortIndex: (offer: T, sortIndex: number) => void;
};

type UseCmsCollectionTradeOfferMultipleProps = {
  readonly linkObjectType: ECmsCollectionLinkObjectType.TradeOffer;
  readonly selected: TradeOffer[];
  readonly onChangeLinkedObject: (data: CmsLinkedObjectCollectionTradeOfferType) => void;
};

type UseCmsCollectionCorpOfferMultipleProps = {
  readonly linkObjectType: ECmsCollectionLinkObjectType.CorpOffer;
  readonly selected: CorpOffer[];
  readonly onChangeLinkedObject: (data: CmsLinkedObjectCollectionCorpOfferType) => void;
};

type UseCmsCollectionOfferMultipleProps<T extends CollectionContentType> = T extends TradeOffer
  ? UseCmsCollectionTradeOfferMultipleProps
  : UseCmsCollectionCorpOfferMultipleProps;

interface UseDialogInHistoryParams {
  readonly dialog_guid: UUID;
  readonly dialog_tab: EOfferTableTab;
}

const useCmsCollectionOfferMultiple = <T extends CollectionContentType>(
  props: UseCmsCollectionOfferMultipleProps<T>
): UseCollectionOfferMultiple<T> => {
  const { selected } = props;

  const {
    open: isSearchDialogOpened,
    params: searchDialogParams,
    onOpen: onOpenSearchDialog,
    onClose: onCloseSearchDialog,
  } = useDialogInHistory<UseDialogInHistoryParams>({
    tag:
      props.linkObjectType === ECmsCollectionLinkObjectType.CorpOffer
        ? ECollectionSearchDialogTag.CorpMultiple
        : ECollectionSearchDialogTag.TradeMultiple,
    params: { dialog_guid: uuidv4(), dialog_tab: EOfferTableTab.Active },
  });

  const onOpenSearchDialogInternal = useCallback(
    (tab?: EOfferTableTab) => onOpenSearchDialog({ dialog_guid: uuidv4(), dialog_tab: tab ?? EOfferTableTab.Active }),
    [onOpenSearchDialog]
  );

  const onChangeSearchDialogTab = useCallback(
    (tab: EOfferTableTab) => onOpenSearchDialog({ ...searchDialogParams, dialog_tab: tab }),
    [searchDialogParams]
  );

  const onChangeInternal = useCallback(
    (newSelected: T[]) => {
      switch (props.linkObjectType) {
        case ECmsCollectionLinkObjectType.CorpOffer:
          props.onChangeLinkedObject({
            linkObjectType: ECmsCollectionLinkObjectType.CorpOffer,
            linkedObject: newSelected as CorpOffer[],
          });
          break;
        case ECmsCollectionLinkObjectType.TradeOffer:
          props.onChangeLinkedObject({
            linkObjectType: ECmsCollectionLinkObjectType.TradeOffer,
            linkedObject: newSelected as TradeOffer[],
          });
          break;
      }
    },
    [props.linkObjectType, props.onChangeLinkedObject]
  );

  const onSelect = useCallback(
    (newSelected: T[]) => {
      onChangeInternal(newSelected);
      onCloseSearchDialog();
    },
    [onChangeInternal, onCloseSearchDialog]
  );

  const onClear = useCallback(() => {
    onChangeInternal([]);
  }, [onChangeInternal]);

  const onUnselect = useCallback(
    (id: UUID) => {
      const arr: T[] = [...(selected as T[])];
      const index = selected.findIndex(o => o.id === id);

      if (index !== -1) {
        arr.splice(index, 1);
        onChangeInternal(arr);
      }
    },
    [selected, onChangeInternal]
  );

  const onChangeSortIndex = useCallback(
    (item: T, sortIndex: number) => {
      const arr: T[] = [...(selected as T[])];
      const from = arr.findIndex(o => o.id === item.id);
      arr.splice(sortIndex - 1, 0, arr.splice(from, 1)[0]);
      onChangeInternal(arr);
    },
    [selected, onChangeInternal]
  );

  return {
    searchDialogParams,
    isSearchDialogOpened,
    onOpenSearchDialog: onOpenSearchDialogInternal,
    onCloseSearchDialog,
    onChangeSearchDialogTab,
    onSelect,
    onClear,
    onUnselect,
    onChangeSortIndex,
  };
};

export default useCmsCollectionOfferMultiple;
