import { RootState } from '../../../../../data/store/store';
import { UUID } from '../../../../../domain/model/types';
import {
  createChatGetByIdSelector,
  createChatGetMembersSelector,
  createChatGetMessagesSelector,
  createChatSelectedMessagesSelector,
} from './slice';

export const chatGuidSelector = (store: RootState) => store.chat.details.currentSession;
export const chatGetByIdSelector = (guid: UUID) => (state: RootState) => createChatGetByIdSelector(state, guid);
export const chatGetMessagesSelector = (guid: UUID) => (state: RootState) => createChatGetMessagesSelector(state, guid);
export const chatGetMembersSelector = (guid: UUID) => (state: RootState) => createChatGetMembersSelector(state, guid);
export const chatSelectedMessagesSelector = (guid: UUID) => (state: RootState) =>
  createChatSelectedMessagesSelector(state, guid);
