import OfferFieldHistoryTableCell, { OfferFieldHistoryTableCellCommonProps } from './index';

const OfferFieldHistoryTableCellField = (props: OfferFieldHistoryTableCellCommonProps) => {
  const {
    fieldHistory: { field },
  } = props;

  const value = field.name ?? field.path;

  return (
    <OfferFieldHistoryTableCell
      {...props}
      value={value}
    />
  );
};

export default OfferFieldHistoryTableCellField;
