import { AdCampaignCreate, AnyAdCampaign, EAdCampaignStatus, ENoticeStatus } from '@/domain';
import Notifier from '../../../../system/notifier';

export const adCampaignKeywordMaxCount = 20;
export const adCampaignOfferMaxCount = 4;
/**
 * показать нотификатор об успешном выполнении действия с РК
 * @param adCampaign
 * @param nextStatus если значение не передано, предполагается что статус не меняется относительно текущего, или пока статус не присвоен
 * */
export const showAdCampaignActionSuccessNotification = (
  adCampaign: AnyAdCampaign | AdCampaignCreate,
  nextStatus?: EAdCampaignStatus
) => {
  let text;

  if (!adCampaign.status && !nextStatus) {
    text = 'Черновик рекламной кампании сохранён';
  } else {
    text = 'Изменения сохранены';
  }

  Notifier.getInstance().addNotice(ENoticeStatus.Success, text);
};

export const showAdCampaignInvalidNotification = (
  adCampaign: AnyAdCampaign | AdCampaignCreate,
  nextStatus?: EAdCampaignStatus
) => {
  let text;

  if (!adCampaign.status && !nextStatus) {
    text = 'Заполните корректно поля, чтобы сохранить рекламную кампанию';
  } else {
    switch (nextStatus) {
      case EAdCampaignStatus.Active:
        text = 'Заполните все обязательные поля, чтобы опубликовать рекламную кампанию';
        break;
      default:
        text = 'Заполните все обязательные поля, чтобы сохранить изменения';
        break;
    }
  }

  Notifier.getInstance().addNotice(ENoticeStatus.Warning, text);
};
