import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../data/store/types';
import { PartnerDraft, PartnerShort } from '../../../../../domain/model/partner';
import { Nullable } from '../../../../../domain/model/types';
import { MpUserData, MpUserShort } from '../../../../../domain/model/user';
import partnerServices from '../../../general/partner/services';

export const partnerSelfRegistrationCreate = createAsyncThunk<
  PartnerDraft,
  { user: MpUserShort; partner: Nullable<PartnerShort> },
  AppThunkAPIConfig
>('partner/selfRegistration/create', async ({ user, partner }, { rejectWithValue }) => {
  try {
    return await partnerServices.common.createForUser({ user, partner });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface PartnerSelfRegistrationState {
  readonly user: Nullable<MpUserShort>;
  readonly save: Fetchable & {
    readonly data: Nullable<PartnerDraft>;
  };
}

type Reducer<T = undefined> = CaseReducer<PartnerSelfRegistrationState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PartnerSelfRegistrationState> {
  partnerSelfRegistrationInit: Reducer<MpUserShort>;
  partnerSelfRegistrationReset: Reducer;
  partnerSelfRegistrationSetAttribute: Reducer<{ name: keyof MpUserData; value: any }>;
}

const slice = createSlice<PartnerSelfRegistrationState, Reducers, 'selfRegistration'>({
  name: 'selfRegistration',
  initialState: {
    user: null,
    save: {
      data: null,
      ...fetchableDefault,
    },
  },
  reducers: {
    partnerSelfRegistrationReset: state => {
      state.user = null;
      state.save = {
        ...fetchableDefault,
        data: null,
      };
    },
    partnerSelfRegistrationInit: (state, { payload }) => {
      state.user = payload;
      state.save = {
        ...fetchableDefault,
        data: null,
      };
    },
    partnerSelfRegistrationSetAttribute: (state, { payload }) => {
      const { name, value } = payload;
      if (state.user) {
        state.user[name] = value;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(partnerSelfRegistrationCreate.pending, state => {
        state.save = {
          ...fetchableFetching,
          data: null,
        };
      })
      .addCase(partnerSelfRegistrationCreate.fulfilled, (state, { payload }) => {
        state.save = {
          ...fetchableFetched,
          data: payload,
        };
      })
      .addCase(partnerSelfRegistrationCreate.rejected, state => {
        state.save = {
          ...fetchableFailed,
          data: null,
        };
      });
  },
});

export const { partnerSelfRegistrationSetAttribute, partnerSelfRegistrationInit, partnerSelfRegistrationReset } =
  slice.actions;

export default slice.reducer;
