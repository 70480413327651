import { CorpOfferShort } from '../../../../../../domain/model/corpOffer';
import { OfferLifeCycleCondition } from '../types';

/*
 * односложные условия, если несколько условий то они всегда должны работать по принципу И
 */

export const hasApprovalRegistryCondition: OfferLifeCycleCondition<CorpOfferShort> = {
  description: 'Наличие приказа',
  algorithm: ['Присвоен приказ'],
  call: ({ obj }) => {
    return !!obj.approvalRegistry?.number && !!obj.approvalRegistry?.date;
  },
};

export const hasNotApprovalRegistryCondition: OfferLifeCycleCondition<CorpOfferShort> = {
  description: 'Отсутствие приказа',
  algorithm: ['Не присвоен приказ'],
  call: ({ obj }) => {
    return !obj.approvalRegistry || !obj.approvalRegistry?.number || !obj.approvalRegistry?.date;
  },
};
