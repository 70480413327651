import StepperContainer from '../../../general/stepper/container';
import { FilledContainer } from './controls';
import BookingOrderDetailsStepperFooterAdapter from './stepperFooter';

const BookingOrderDetailsStepperAdapter = () => {
  return (
    <StepperContainer
      header={<FilledContainer />}
      footer={<BookingOrderDetailsStepperFooterAdapter />}
    />
  );
};

export default BookingOrderDetailsStepperAdapter;
