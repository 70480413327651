import styled from '@emotion/styled';

export const Content = styled.div`
  padding-bottom: ${p => p.theme.spacing(3)};
  flex-grow: 1;
`;

export const Footer = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 1;
  padding: ${p => p.theme.spacing(3, 0)};
  background: ${p => p.theme.palette.background.default};
`;
