import { ButtonProps, LinearProgress } from '@mui/material';
import { MPButton } from '../../../../../theme/ui-kit/button';
import useFileDownload from '../useFileDownload';
import { ProgressWrapper } from './controls';

type FileDownloadButtonProps = Omit<ButtonProps, 'onClick'> & {
  readonly src: string;
  readonly accept?: string;
  readonly fileName?: string;
  readonly onDownload?: (success: boolean) => void;
};

const FileDownloadButton = (props: FileDownloadButtonProps) => {
  const { src, accept, fileName, children, onDownload, ...rest } = props;
  const { isDownloading, download } = useFileDownload({ src, fileName, accept, onDownload });

  return (
    <MPButton
      {...rest}
      onClick={download}
    >
      {children}
      {isDownloading && (
        <ProgressWrapper>
          <LinearProgress variant='indeterminate' />
        </ProgressWrapper>
      )}
    </MPButton>
  );
};

export default FileDownloadButton;
