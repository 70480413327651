import { FilterMultiSelectorPredicate } from '@/domain/model';
import { AddressObjectLink } from '@/domain/model/address';
import { ESearchQueryTypeDiscriminator } from '@/domain/model/enums';
import axios, { AxiosResponse } from 'axios';
import { SearchQueryGeoStat, SearchQueryKeywordStat } from 'domain/model/analytics';
import { Nullable } from 'domain/model/types';
import { createCancelToken } from './index';
import { ApiCancellable, ApiRequestPageable } from './types';
import { getComServicesEndpoint } from './utils';

type AllQueryProps<D extends ESearchQueryTypeDiscriminator> = ApiRequestPageable &
  ApiCancellable & {
    readonly dateFrom?: Nullable<string>;
    readonly dateTo?: Nullable<string>;
    readonly query?: Nullable<string>;
    readonly places?: Nullable<FilterMultiSelectorPredicate<AddressObjectLink>>;
    readonly discriminator?: D;
  };

type ResponseCounter = {
  readonly count: number;
};

type KeywordSearchQueryResponse<D extends ESearchQueryTypeDiscriminator> = D extends ESearchQueryTypeDiscriminator.Query
  ? SearchQueryKeywordStat[]
  : never;
type GeographySearchQueryResponse<D extends ESearchQueryTypeDiscriminator> = D extends ESearchQueryTypeDiscriminator.Geo
  ? SearchQueryGeoStat[]
  : never;
type CounterSearchQueryResponse<D extends ESearchQueryTypeDiscriminator> =
  D extends ESearchQueryTypeDiscriminator.Counter ? ResponseCounter[] : never;

type SearchQueryResponse<D extends ESearchQueryTypeDiscriminator> =
  | KeywordSearchQueryResponse<D>
  | GeographySearchQueryResponse<D>
  | CounterSearchQueryResponse<D>;

type QueryAnalyticsApi = {
  readonly all: <D extends ESearchQueryTypeDiscriminator>(
    props: AllQueryProps<D>
  ) => Promise<AxiosResponse<SearchQueryResponse<D>>>;
};

/**
 * АПИ по работе с бонусами
 */
const queryAnalytics: QueryAnalyticsApi = {
  all: ({ page, pageSize, sort, discriminator, query, dateFrom, dateTo, places, signal }) => {
    const params = new URLSearchParams();

    if (discriminator) {
      params.append('resultType', discriminator);
    }

    if (dateFrom) params.append('dateFrom', dateFrom);
    if (dateTo) params.append('dateTo', dateTo);

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (query) {
      params.append('q', query);
    }

    if (places) {
      params.append('localityPredicate', JSON.stringify(places));
    }

    return axios.get(`${getComServicesEndpoint()}/analytics/search-queries`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
};

export default queryAnalytics;
