import styled from '@emotion/styled';
import { Link } from '@mui/material';

export const ExpandingHintContainer = styled.div`
  border-radius: ${p => Number(p.theme.shape.borderRadius) * 2}px;
  padding: ${p => p.theme.spacing(1.25)};
  background: ${p => p.theme.palette.disabled.background};
  position: relative;
  transition: all 2.3s;
`;

type HintWrapperProps = {
  $open: boolean;
};

export const HintWrapper = styled.div<HintWrapperProps>`
  max-height: ${({ $open }) => ($open ? '500px' : '16px')};
  overflow-x: hidden;
  overflow-y: ${({ $open }) => ($open ? 'auto' : 'hidden')};
  padding-right: ${p => p.theme.spacing(4)};
  transition: max-height 0.3s ${({ $open }) => ($open ? 'ease-in' : 'ease-out')};
`;

export const HintIcons = styled.div`
  position: absolute;
  right: ${p => p.theme.spacing(1)};
  top: ${p => p.theme.spacing(0.75)};
  cursor: pointer;
`;

export const HintLink = styled(Link)`
  font-size: ${p => p.theme.typography.body2.fontSize};
  line-height: 1rem;
  margin-top: -${p => p.theme.spacing(0.25)};
`;

export const HintContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing()};

  ul {
    padding-left: ${p => p.theme.spacing()};
    list-style-position: inside;
  }
`;

export const HintCollapseTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
