import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { EDateFormat } from '../../../../../../domain/model/formats';
import { Nullable } from '../../../../../../domain/model/types';
import SectionTitle from '../../../../../components/common/sectionTitle';
import { CmsContainerDetailsComponentProps } from '../types';

type CmsContainerViewComponentPeriodProps = CmsContainerDetailsComponentProps;

const CmsContainerViewComponentPeriod = (props: CmsContainerViewComponentPeriodProps) => {
  const { cmsContainer } = props;

  const startDate: Nullable<string> =
    cmsContainer.startDate && moment(cmsContainer.startDate).format(EDateFormat.DisplayDefault);
  const endDate: Nullable<string> =
    cmsContainer.endDate && moment(cmsContainer.endDate).format(EDateFormat.DisplayDefault);

  return (
    <Grid
      container
      direction='column'
      spacing={1}
    >
      <Grid item>
        <SectionTitle gutterBottom={false}>Срок публикации</SectionTitle>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography color='textSecondary'>Начало</Typography>
        <Typography>{startDate}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography color='textSecondary'>Окончание</Typography>
        <Typography>{endDate}</Typography>
      </Grid>
    </Grid>
  );
};

export default CmsContainerViewComponentPeriod;
