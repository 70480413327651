import { ButtonLink } from '@components/common/buttons/link';
import { FeedbackText } from '@features/adCampaign/details/controls';
import ChatBubbleIcon from '@mui/icons-material/ChatBubbleOutline';
import { useSelector } from 'react-redux';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { adCampaignDetailsAdCampaignSelector } from '../store/selectors';

const AdCampaignDetailsStepperButtonsAdapter = () => {
  const adCampaign = useSelector(adCampaignDetailsAdCampaignSelector);

  const handlers = useContextHandlers();

  const onShowFeedback = () => {
    handlers.onChangeDialogState('feedback', adCampaign);
  };

  return (
    <ButtonLink
      variant='text'
      fullWidth={false}
      onClick={onShowFeedback}
    >
      <ChatBubbleIcon fontSize={'small'} />
      <FeedbackText>Форма обратной связи</FeedbackText>
    </ButtonLink>
  );
};

export default AdCampaignDetailsStepperButtonsAdapter;
