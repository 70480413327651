import React from 'react';
import { ActivityType } from '../../../../../domain/model/event';
import { Nullable } from '../../../../../domain/model/types';
import { ActivityTypesEditContainerProps } from './container';
import { ActivityTypesEditState } from './store/slice';

export type ActivityTypesEditParamsContextValue = ActivityTypesEditContainerProps;

export const ActivityTypesEditParamsContext = React.createContext<ActivityTypesEditParamsContextValue>(
  {} as ActivityTypesEditParamsContextValue
);

export type ActivityTypesEditLifecycleContextValue = {};

export const ActivityTypesEditLifecycleContext = React.createContext<ActivityTypesEditLifecycleContextValue>(
  {} as ActivityTypesEditLifecycleContextValue
);

export type ActivityTypesEditHandlersContextValue = {
  readonly onChangeDialogState: <Name extends keyof ActivityTypesEditState['dialogs']>(
    name: Name,
    data: Nullable<ActivityTypesEditState['dialogs'][Name]>
  ) => void;
  readonly onCreate: (activityType: ActivityType) => Promise<void>;
  readonly onEdit: (activityType: ActivityType) => Promise<void>;
  readonly onShow: (activityType: ActivityType) => Promise<void>;
  readonly onSort: (parent: Nullable<ActivityType>, activityTypes: ActivityType[]) => Promise<void>;
  readonly onDelete: (activityType: ActivityType) => Promise<void>;
  readonly onHide: (activityType: ActivityType) => Promise<void>;
  readonly onClose: () => void;
};

export const ActivityTypesEditHandlersContext = React.createContext<ActivityTypesEditHandlersContextValue>(
  {} as ActivityTypesEditHandlersContextValue
);
