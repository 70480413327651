import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Banner } from '../../../../domain/model/banner';
import { UUID } from '../../../../domain/model/types';
import ContentLoader from '../../../components/common/loader';
import { ContainerWrapper } from './controls';
import { bannerByIdSelector } from './store/selectors';
import { bannerByIdFetch, bannerByIdStateRefresh, bannerByIdStateReset } from './store/slice';

interface BannerByIdContainerResult {
  readonly banner: Banner;
  readonly onRestore: () => void;
}

interface BannerByIdContainerProps {
  readonly id: UUID;
  readonly children: (result: BannerByIdContainerResult) => React.ReactNode;
}

const BannerByIdContainer = ({ id, children }: BannerByIdContainerProps) => {
  const dispatch = useDispatch();

  const { data: banner, isFetching } = useSelector(bannerByIdSelector);

  const onRestore = () => dispatch(bannerByIdStateRefresh());

  useEffect(() => {
    dispatch(bannerByIdFetch(id));

    return () => {
      dispatch(bannerByIdStateReset());
    };
  }, [dispatch, id]);

  if (isFetching || !banner) {
    return (
      <ContainerWrapper>
        <ContentLoader />
      </ContainerWrapper>
    );
  }

  return <>{children({ banner, onRestore })}</>;
};

export default BannerByIdContainer;
