import { AdCampaign, AdCampaignCreate, AnyAdCampaign, EAdCampaignStatus } from '@/domain';
import { AdCampaignShort } from '@/domain/model/ads';
import { Nullable } from '@/domain/model/types';
import { MPStepperStep } from '@/presentation/components/common/stepper';
import { OnChangeObjectAttribute, PanelActions } from '@/presentation/types';
import { ValidationItemResult, ValidationRules } from '@/presentation/utils/validation';
import { ReactElement } from 'react';

export enum EAdCampaignStep {
  General = 'general',
  Keywords = 'keywords',
}

export enum EAdCampaignCreateUrlParam {
  Id = 'id',
  Guid = 'guid',
}

export type AdCampaignTableTabsCounter = {
  [name in EAdCampaignTableTab]?: number;
};

export type AdCampaignCounterByStatus = {
  [name in EAdCampaignStatus]?: number;
};

export type AdCampaignCreateStepType = EAdCampaignStep.General | EAdCampaignStep.Keywords;

export type AdCampaignDetailsStepType = EAdCampaignStep.General | EAdCampaignStep.Keywords;

export type AdCampaignCreateStep = MPStepperStep<AdCampaignCreateStepType>;
export type AdCampaignDetailsStep = MPStepperStep<AdCampaignDetailsStepType>;

export enum EAdCampaignActionType {
  /** Возобновить */
  Resume = 'resume',
  /** Приостановить */
  Pause = 'pause',
  /** Отправить в архив */
  Archive = 'archive',
  /** Редактировать */
  Edit = 'edit',
}

export type AdCampaignActionCreateType =
  | EAdCampaignActionType.Pause
  | EAdCampaignActionType.Resume
  | EAdCampaignActionType.Edit
  | EAdCampaignActionType.Archive;

export type AdCampaignActionDetailsType = EAdCampaignActionType;

export type AdCampaignActionTableType = EAdCampaignActionType;

export type AdCampaignActionType = AdCampaignActionCreateType | AdCampaignActionDetailsType | AdCampaignActionTableType;

export type AdCampaignActionTableCommonType = EAdCampaignActionType;

export type ActionAdCampaignType = AdCampaign | AdCampaignShort | AdCampaignCreate;

export type AdCampaignActions<A extends string = EAdCampaignActionType> = PanelActions<A>;

export enum EAdCampaignTableTab {
  Active = 'active',
  Upcoming = 'upcoming',
  Paused = 'paused',
  Archived = 'archived',
}

export enum EAdCampaignTableColumn {
  Name = 'name',
  Period = 'startDate',
  Partner = 'partner.name',
  Type = 'type',
  OfferType = 'offerType',
  OfferCount = 'offersCount',
  Budget = 'budget',
  ShowCount = 'showCount',
  ViewCount = 'viewCount',
}

export type AdCampaignValidationRules = ValidationRules<AdCampaignForValidation>;

type AdCampaignTypesForField = AdCampaignCreate | AnyAdCampaign;

export type AdCampaignForValidation = AdCampaignCreate | AdCampaign;

export interface AdCampaignFieldCommonProps<T extends AdCampaignTypesForField, Key extends keyof T = any> {
  readonly value: T[Key];
  readonly disabled?: boolean;
  readonly validation: Nullable<ValidationItemResult>;
  readonly onChangeAttribute: OnChangeObjectAttribute<T>;
  readonly onValidateAttribute: (name: keyof T) => void;
}

export interface AdCampaignFieldViewProps<T extends AdCampaignTypesForField, Key extends keyof T = any> {
  readonly value: T[Key];
}

export type AdCampaignViewFieldCommonProps<Key extends keyof AdCampaignCreate> = AdCampaignFieldViewProps<
  AdCampaignCreate,
  Key
>;

export type AdCampaignCreateFieldCommonProps<Key extends keyof AdCampaignCreate> = AdCampaignFieldCommonProps<
  AdCampaignCreate,
  Key
>;

export type AdCampaignTableStubAdapterResult = {
  readonly canViewTable: boolean;
  readonly isCreateDisabled?: boolean;
  readonly stub?: Nullable<JSX.Element>;
  readonly createInfo?: Nullable<string>;
};

export type AdCampaignTableStubAdapterProps = {
  readonly children: (props: AdCampaignTableStubAdapterResult) => ReactElement<any, any>;
};

export type AdCampaignTableStubAdapterType = (props: AdCampaignTableStubAdapterProps) => ReactElement<any, any>;
