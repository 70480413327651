import { ESortDirection } from '@/domain/model/enums';
import { ClientOrgUser } from '@/domain/model/user';
import ClientOrgUserTableCellEmail from '@features/user/userClientOrg/components/tableCell/email';
import ClientOrgUserTableCellPhone from '@features/user/userClientOrg/components/tableCell/phone';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../../components/common/table';
import { DataTableLoader } from '../../../../components/common/table/loader';
import ClientOrgUserTableCellDate from '../components/tableCell/date';
import ClientOrgUserTableCellName from '../components/tableCell/name';
import ClientOrgUsersTableCellAdapter from './adapters/cell';
import { clientOrgUsersIsFetchingSelector } from './store/selectors';
import { EClientOrgUserTableColumn } from './utils';

interface ClientOrgUserTableProps {
  readonly metadata: DataTableMetadata<EClientOrgUserTableColumn>;
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly clientOrgUsers: ClientOrgUser[];
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onClientOrgUserClick?: (clientOrgUser: ClientOrgUser) => void;
  readonly onChangeMetadata?: (metadata: DataTableMetadata<EClientOrgUserTableColumn>) => void;
}

export const ClientOrgUserTable = (props: ClientOrgUserTableProps) => {
  const { clientOrgUsers, sort, metadata, onChangeMetadata, onRequestSort } = props;

  const rows: DataTableRow<ClientOrgUser, EClientOrgUserTableColumn>[] = clientOrgUsers?.map((clientOrgUser, index) => {
    return {
      [EClientOrgUserTableColumn.Date]: (
        <ClientOrgUsersTableCellAdapter
          index={index}
          component={ClientOrgUserTableCellDate}
        />
      ),
      [EClientOrgUserTableColumn.Name]: (
        <ClientOrgUsersTableCellAdapter
          index={index}
          component={ClientOrgUserTableCellName}
        />
      ),
      [EClientOrgUserTableColumn.Phone]: (
        <ClientOrgUsersTableCellAdapter
          index={index}
          component={ClientOrgUserTableCellPhone}
        />
      ),
      [EClientOrgUserTableColumn.Email]: (
        <ClientOrgUsersTableCellAdapter
          index={index}
          component={ClientOrgUserTableCellEmail}
        />
      ),
      data: clientOrgUser,
    };
  });

  return (
    <DataTable<ClientOrgUser, EClientOrgUserTableColumn>
      width='auto'
      overflowX='inherit'
      metadata={metadata}
      onChangeMetadata={onChangeMetadata}
      rows={rows}
      sort={{ column: sort.column, direction: sort.direction }}
      loader={<DataTableLoader selector={clientOrgUsersIsFetchingSelector} />}
      onSort={(event, column, direction) => onRequestSort(column, direction)}
    />
  );
};
