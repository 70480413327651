import axios, { AxiosResponse } from 'axios';
import { BookingServiceCategory, BookingServicePriceUnit } from '../../domain/model/booking';
import { Nullable, UUID } from '../../domain/model/types';
import { createCancelToken } from './index';
import { ApiCancellable, ApiQueryDsl } from './types';
import { appendQueryDslParams, getComServicesEndpoint } from './utils';

export enum BookingPriceUnitUpdateDiscriminator {
  UpdatePriceUnitCommand = 'UpdatePriceUnitCommand',
}

export enum BookingServiceCategoryUpdateDiscriminator {
  UpdateServiceCategoryCommand = 'UpdateServiceCategoryCommand',
}

type ServiceCategoryAllProps = ApiCancellable & {
  readonly query?: Nullable<string>;
  readonly querydsl?: Nullable<ApiQueryDsl>;
};

type PriceUnitAllProps = ApiCancellable & {
  readonly querydsl?: Nullable<ApiQueryDsl>;
};

type PriceUnitUpdateProps = {
  readonly discriminator: BookingPriceUnitUpdateDiscriminator;
  readonly id: UUID;
  readonly data: BookingServicePriceUnit;
};

type PriceUnitCreateProps = BookingServicePriceUnit;

type ServiceCategoryCreteProps = BookingServiceCategory;

type ServiceCategoryUpdateProps = {
  readonly discriminator: BookingServiceCategoryUpdateDiscriminator;
  readonly id: UUID;
  readonly data: BookingServiceCategory;
};

type DeleteProps = {
  readonly id: UUID;
};

type BookingDictionaryApi = {
  readonly serviceCategory: {
    readonly all: (props: ServiceCategoryAllProps) => Promise<AxiosResponse<BookingServiceCategory[]>>;
    readonly create: (props: ServiceCategoryCreteProps) => Promise<AxiosResponse<BookingServiceCategory>>;
    readonly update: (props: ServiceCategoryUpdateProps) => Promise<AxiosResponse<BookingServiceCategory>>;
    readonly delete: (props: DeleteProps) => Promise<AxiosResponse<void>>;
  };
  readonly priceUnit: {
    readonly all: (props: PriceUnitAllProps) => Promise<AxiosResponse<BookingServicePriceUnit[]>>;
    readonly create: (props: PriceUnitCreateProps) => Promise<AxiosResponse<BookingServicePriceUnit>>;
    readonly update: (props: PriceUnitUpdateProps) => Promise<AxiosResponse<BookingServicePriceUnit>>;
    readonly delete: (props: DeleteProps) => Promise<AxiosResponse<void>>;
  };
};

const bookingDictionary: BookingDictionaryApi = {
  serviceCategory: {
    all: ({ querydsl, query, signal }) => {
      const params = new URLSearchParams();

      if (query) {
        params.append('q', query);
      }

      if (querydsl) {
        appendQueryDslParams(params, querydsl);
      }

      return axios.get(`${getComServicesEndpoint()}/booking-services/categories`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    create: data => {
      return axios.post(`${getComServicesEndpoint()}/booking-services/categories`, data);
    },
    update: ({ id, data, discriminator }) => {
      return axios.post(`${getComServicesEndpoint()}/booking-services/categories/${id}`, {
        discriminator,
        ...data,
      });
    },
    delete: ({ id }) => {
      return axios.delete(`${getComServicesEndpoint()}/booking-services/categories/${id}`);
    },
  },
  priceUnit: {
    all: ({ querydsl, signal }) => {
      const params = new URLSearchParams();

      if (querydsl) {
        appendQueryDslParams(params, querydsl);
      }

      return axios.get(`${getComServicesEndpoint()}/booking-services/price-units`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    create: data => {
      return axios.post(`${getComServicesEndpoint()}/booking-services/price-units`, data);
    },
    update: ({ id, data, discriminator }) => {
      return axios.post(`${getComServicesEndpoint()}/booking-services/price-units/${id}`, {
        discriminator,
        ...data,
      });
    },
    delete: ({ id }) => {
      return axios.delete(`${getComServicesEndpoint()}/booking-services/price-units/${id}`);
    },
  },
};
export default bookingDictionary;
