import React from 'react';
import { UseEmployeeTable } from './hooks/useEmployeeTable';
import { UseEmployeeTableConfig } from './hooks/useEmployeeTableConfig';

export type EmployeeTableContextValue = UseEmployeeTable;

export const EmployeeTableContext = React.createContext<EmployeeTableContextValue>({} as EmployeeTableContextValue);

export type EmployeeTableConfigContextValue = UseEmployeeTableConfig;

export const EmployeeTableConfigContext = React.createContext<EmployeeTableConfigContextValue>(
  {} as EmployeeTableConfigContextValue
);
