import { EOfferStatus } from '../../../../../../domain/model/enums';
import { EOfferActionType } from '../../types';
import {
  adminStatusScheme as commonAdminStatusScheme,
  partnerStatusScheme as commonPartnerStatusScheme,
} from './common';

export const adminStatusScheme = commonAdminStatusScheme;

export const partnerStatusScheme = {
  ...commonPartnerStatusScheme,
  statusScheme: {
    ...commonPartnerStatusScheme.statusScheme,
    [EOfferStatus.Active]: [
      ...commonPartnerStatusScheme.statusScheme[EOfferStatus.Active],
      EOfferActionType.Edit,
      EOfferActionType.SaveNewVersion,
    ],
    [EOfferStatus.Paused]: [
      ...commonPartnerStatusScheme.statusScheme[EOfferStatus.Paused],
      EOfferActionType.Edit,
      EOfferActionType.SaveNewVersion,
    ],
    [EOfferStatus.Upcoming]: [
      ...commonPartnerStatusScheme.statusScheme[EOfferStatus.Upcoming],
      EOfferActionType.Edit,
      EOfferActionType.SaveNewVersion,
    ],
    [EOfferStatus.Rejected]: [
      ...commonPartnerStatusScheme.statusScheme[EOfferStatus.Rejected],
      EOfferActionType.Archive,
    ],
    [EOfferStatus.OnModeration]: [
      ...commonPartnerStatusScheme.statusScheme[EOfferStatus.OnModeration],
      EOfferActionType.UnPublish,
    ],
  },
};
