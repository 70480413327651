import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from './index';

const CorpOfferTableCellPartnerResponsible = (props: CorpOfferTableCellCommonProps) => {
  const {
    corpOffer: { responsiblePerson },
  } = props;

  const value = responsiblePerson?.fullName ?? '-';

  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellPartnerResponsible;
