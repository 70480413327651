import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataFilterComponent from '../../../../components/common/dataFilter';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { ESportsmansTableFilterItem } from '../../filterUtils';
import { ESportsmanTableColumn } from '../../table/utils';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  sportsmanSearchFilterSelector,
  sportsmanSearchGuidSelector,
  sportsmanSearchSortColumnSelector,
} from '../store/selectors';

interface SportsmanSearchFilterAdapterProps {
  readonly filterAdapter: DataFilterAdapter<ESportsmanTableColumn, ESportsmansTableFilterItem>;
}

const SportsmanSearchFilterAdapter = (props: SportsmanSearchFilterAdapterProps) => {
  const { filterAdapter } = props;

  const handlers = useContextHandlers();

  const guid = useSelector(sportsmanSearchGuidSelector);
  const sortColumn = useSelector(sportsmanSearchSortColumnSelector);
  const filter = useSelector(sportsmanSearchFilterSelector);

  const { filterStrategies } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return useMemo(
    () => (
      <DataFilterComponent
        strategies={filterStrategies}
        onChange={handlers.onChangeFilter}
      />
    ),
    [filterStrategies]
  );
};

export default SportsmanSearchFilterAdapter;
