import { Nullable } from '../../../../../domain/model/types';
import { EPanelActionPosition } from '../../../../types';
import {
  CmsContainerAction,
  CmsContainerActionCreateType,
  CmsContainerActionDetailsType,
  CmsContainerActions,
  CmsContainerActionTableType,
  ECmsContainerActionType,
  GetCmsContainerActionsByPermissions,
} from '../types';

export const getCmsContainerActionName = (type: ECmsContainerActionType): string => {
  switch (type) {
    case ECmsContainerActionType.Publish:
      return 'Опубликовать';
    case ECmsContainerActionType.Save:
      return 'Сохранить и закрыть';
    case ECmsContainerActionType.Pause:
      return 'Приостановить';
    case ECmsContainerActionType.Edit:
      return 'Редактировать';
    case ECmsContainerActionType.ChangeSortIndex:
      return 'Изменить индекс сортировки';
    case ECmsContainerActionType.Archive:
      return 'Отправить в архив';
    case ECmsContainerActionType.Resume:
      return 'Возобновить';
    case ECmsContainerActionType.Delete:
      return 'Удалить';
    case ECmsContainerActionType.Duplicate:
      return 'Копировать в черновики';
  }
};

// получение разрешенных действий с контейнеров с учетом разрешений
export const getCmsContainerActionsConfigByPermissions = (
  props: GetCmsContainerActionsByPermissions
): CmsContainerActions => {
  const { canCreate, canEdit, canSave, canPublish, canDelete, canArchive, canResume, canPause, canChangeSortIndex } =
    props;

  const actions: CmsContainerActions = [];

  let onSave: Nullable<CmsContainerAction> = null;
  let onPublish: Nullable<CmsContainerAction> = null;
  let onDelete: Nullable<CmsContainerAction> = null;
  let onArchive: Nullable<CmsContainerAction> = null;
  let onResume: Nullable<CmsContainerAction> = null;
  let onPause: Nullable<CmsContainerAction> = null;
  let onDuplicate: Nullable<CmsContainerAction> = null;
  let onChangeSortIndex: Nullable<CmsContainerAction> = null;
  let onEdit: Nullable<CmsContainerAction> = null;

  if (canPublish) {
    onPublish = {
      type: ECmsContainerActionType.Publish,
      disabled: false,
      label: getCmsContainerActionName(ECmsContainerActionType.Publish),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onPublish);
  }
  if (canResume) {
    onResume = {
      type: ECmsContainerActionType.Resume,
      disabled: false,
      label: getCmsContainerActionName(ECmsContainerActionType.Resume),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onResume);
  }
  if (canPause) {
    onPause = {
      type: ECmsContainerActionType.Pause,
      disabled: false,
      label: getCmsContainerActionName(ECmsContainerActionType.Pause),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onPause);
  }
  if (canChangeSortIndex) {
    onChangeSortIndex = {
      type: ECmsContainerActionType.ChangeSortIndex,
      disabled: false,
      label: getCmsContainerActionName(ECmsContainerActionType.ChangeSortIndex),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onChangeSortIndex);
  }
  if (canSave) {
    onSave = {
      type: ECmsContainerActionType.Save,
      disabled: false,
      label: getCmsContainerActionName(ECmsContainerActionType.Save),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onSave);
  }
  if (canEdit) {
    onEdit = {
      type: ECmsContainerActionType.Edit,
      disabled: false,
      label: getCmsContainerActionName(ECmsContainerActionType.Edit),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onEdit);
  }
  if (canArchive) {
    onArchive = {
      type: ECmsContainerActionType.Archive,
      disabled: false,
      label: getCmsContainerActionName(ECmsContainerActionType.Archive),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onArchive);
  }
  if (canCreate) {
    onDuplicate = {
      type: ECmsContainerActionType.Duplicate,
      disabled: false,
      label: getCmsContainerActionName(ECmsContainerActionType.Duplicate),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onDuplicate);
  }
  if (canDelete) {
    onDelete = {
      type: ECmsContainerActionType.Delete,
      disabled: false,
      label: getCmsContainerActionName(ECmsContainerActionType.Delete),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onDelete);
  }
  return actions;
};

export const removeCmsContainerAction = (actions: CmsContainerActions, type: ECmsContainerActionType) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions.splice(index, 1);
  }
};

export const disableCmsContainerAction = (actions: CmsContainerActions, type: ECmsContainerActionType) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      disabled: true,
    };
  }
};

export const renameCmsContainerAction = (
  actions: CmsContainerActions,
  type: ECmsContainerActionType,
  label: string
) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      label,
    };
  }
};

export const makeCmsContainerActionPrimary = (actions: CmsContainerActions<any>, types: ECmsContainerActionType[]) => {
  return actions.map(action => ({
    ...action,
    primary: types.includes(action.type) ? true : action.primary,
  }));
};

// фильтрация действий для создания контейнеров по всем действиям
export const filterCmsContainerCreateActions = (
  actions: CmsContainerActions
): CmsContainerActions<CmsContainerActionCreateType> => {
  const createActions: CmsContainerActions<CmsContainerActionCreateType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case ECmsContainerActionType.Save:
      case ECmsContainerActionType.Publish:
        createActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          primary: action.primary,
          position: action.position,
        });
        break;
      case ECmsContainerActionType.Delete:
      case ECmsContainerActionType.Edit:
      case ECmsContainerActionType.Pause:
      case ECmsContainerActionType.Archive:
      case ECmsContainerActionType.Resume:
      case ECmsContainerActionType.ChangeSortIndex:
      case ECmsContainerActionType.Duplicate:
        break;
    }
  });

  return createActions;
};

// фильтрация действий для просмотра контейнеров по всем действиям
export const filterCmsContainerDetailsActions = (
  actions: CmsContainerActions
): CmsContainerActions<CmsContainerActionDetailsType> => {
  const detailsActions: CmsContainerActions<CmsContainerActionDetailsType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case ECmsContainerActionType.Delete:
      case ECmsContainerActionType.Edit:
      case ECmsContainerActionType.Pause:
      case ECmsContainerActionType.Archive:
      case ECmsContainerActionType.Resume:
      case ECmsContainerActionType.Duplicate:
        detailsActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          primary: action.primary,
          position: action.position,
        });
        break;
      case ECmsContainerActionType.ChangeSortIndex:
      case ECmsContainerActionType.Save:
      case ECmsContainerActionType.Publish:
        break;
    }
  });

  return detailsActions;
};

// фильтрация действий для создания контейнеров по всем действиям
export const filterCmsContainerTableActions = (
  actions: CmsContainerActions
): CmsContainerActions<CmsContainerActionTableType> => {
  const createActions: CmsContainerActions<CmsContainerActionTableType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case ECmsContainerActionType.Pause:
      case ECmsContainerActionType.Archive:
      case ECmsContainerActionType.Resume:
      case ECmsContainerActionType.Edit:
      case ECmsContainerActionType.ChangeSortIndex:
      case ECmsContainerActionType.Delete:
      case ECmsContainerActionType.Duplicate:
        createActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          primary: action.primary,
          position: action.position,
        });
        break;
      case ECmsContainerActionType.Save:
      case ECmsContainerActionType.Publish:
        break;
    }
  });

  return createActions;
};
