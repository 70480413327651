import OfferHistoryTableCell, { OfferHistoryTableCellCommonProps } from './index';

const OfferHistoryTableCellStatusComment = (props: OfferHistoryTableCellCommonProps) => {
  const {
    history: { statusComment },
  } = props;

  return (
    <OfferHistoryTableCell
      {...props}
      value={statusComment}
    />
  );
};

export default OfferHistoryTableCellStatusComment;