import { Typography } from '@mui/material';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../components/common/header';

export const CustomerTableHeaderAdapter = () => {
  return (
    <DefaultHeader headline={<AppBreadcrumbs />}>
      <Typography variant='h2'>Покупатели</Typography>
    </DefaultHeader>
  );
};
