import TradeActivationTableCell, { TradeActivationTableCellCommonProps } from './index';

const TradeActivationTableCellPartnerName = (props: TradeActivationTableCellCommonProps) => {
  const {
    tradeActivation: {
      offer: { partner },
    },
  } = props;

  const value = partner ? partner.name : '-';

  return (
    <TradeActivationTableCell
      {...props}
      value={value}
    />
  );
};

export default TradeActivationTableCellPartnerName;
