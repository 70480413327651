import Api from '../../../../../data/api';
import { ApiCancellable, ApiQueryDsl, ApiRequestListDiscriminator } from '../../../../../data/api/types';
import { OfferCategory } from '../../../../../domain/model';
import { DataFilterQueryDslOperator } from '../../../../../domain/model/filter';
import { UUID } from '../../../../../domain/model/types';

type DataType = OfferCategory;

type CreateProps = DataType;

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type AllProps = ApiCancellable;

type UpdateProps = {
  readonly id: UUID;
  readonly data: DataType;
};

type DeleteProps = {
  readonly id: UUID;
};

type GetRelationShipsCountProps = ApiCancellable & {
  readonly id: UUID;
};

type BookingOfferCategoriesService = {
  readonly create: (props: CreateProps) => Promise<DataType>;
  readonly all: (props: AllProps) => Promise<DataType[]>;
  readonly one: (props: OneProps) => Promise<DataType>;
  readonly update: (props: UpdateProps) => Promise<DataType>;
  readonly delete: (props: DeleteProps) => Promise<void>;
  readonly getRelationShipsCount: (props: GetRelationShipsCountProps) => Promise<number>;
};

const service: BookingOfferCategoriesService = {
  create: async data => {
    const { data: result } = await Api.category.bookingOffer.create(data);
    return result;
  },
  all: async ({ signal }) => {
    const { data: result } = await Api.category.bookingOffer.all({ signal });
    return result;
  },
  one: async ({ id, signal }) => {
    const { data: result } = await Api.category.bookingOffer.one({ categoryId: id, signal });
    return result;
  },
  update: async ({ id, data }) => {
    const { data: result } = await Api.category.bookingOffer.update({ categoryId: id, data });
    return result;
  },
  delete: async ({ id }) => {
    const { data: result } = await Api.category.bookingOffer.delete({ categoryId: id });
    return result;
  },
  getRelationShipsCount: async ({ id, signal }) => {
    const querydsl: ApiQueryDsl = [
      {
        field: 'category',
        operator: DataFilterQueryDslOperator.Equals,
        value: [id],
      },
    ];
    const { data } = await Api.booking.offer.all({
      discriminator: ApiRequestListDiscriminator.Count,
      page: 1,
      pageSize: 1,
      querydsl,
      signal,
    });
    return data[0].count;
  },
};

export default service;
