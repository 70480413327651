import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { EOrderDiscriminator, EOrderPartition } from '../../../domain/model/enums';
import { UUID } from '../../../domain/model/types';
import { MpUser } from '../../../domain/model/user';
import ForbiddenComponent from '../../components/common/forbidden';
import BookingOrderActionsProvider from '../../features/bookingOrder/actions/provider';
import BookingOrderDetailsContainer from '../../features/bookingOrder/details/container';
import { BookingOrderLifeCycleFactory } from '../../features/bookingOrder/lifecycle';
import { getCustomerManagementOrdersRoute } from '../../features/customer/management/common/entry';
import { viewHideCustomerManagementCommonMenu } from '../../features/customer/management/common/store/slice';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import OrderByIdContainer from '../../features/order/byId/container';
import { getOrderPartitionByOrderDiscriminator } from '../../features/order/utils';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';
import useHistoryExtensions from '../../hooks/useHistoryExtensions';

interface CustomerManagementOrderScreenProps {
  readonly customer: MpUser;
}

const CustomerManagementOrderScreen = ({ customer }: CustomerManagementOrderScreenProps) => {
  const dispatch = useDispatch();
  const { accessMatrix, userSpecific } = useCurrentUser();
  const { isPartnerUser, orders } = accessMatrix;

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const { orderId } = useParams<{ orderId: UUID }>();

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewHideCustomerManagementCommonMenu());
  }, [dispatch]);

  const canView = (partition: EOrderPartition) => {
    return orders?.[partition].view;
  };

  return (
    <OrderByIdContainer id={orderId}>
      {({ order }) => {
        const partition = getOrderPartitionByOrderDiscriminator(order.discriminator);

        if (!canView(partition)) {
          return <ForbiddenComponent />;
        }

        switch (order.discriminator) {
          case EOrderDiscriminator.BookingOrder:
            return (
              <BookingOrderActionsProvider>
                <BookingOrderDetailsContainer
                  id={orderId}
                  lifecycle={BookingOrderLifeCycleFactory().create({
                    isPartnerUser,
                    userId: userSpecific.id,
                  })}
                  access={orders[EOrderPartition.BookingOffers]}
                  onClose={() =>
                    gotoPrevIndependentLocation(
                      getCustomerManagementOrdersRoute({
                        id: customer.id,
                        partition,
                      })
                    )
                  }
                />
              </BookingOrderActionsProvider>
            );
          case EOrderDiscriminator.ProductOrder:
            // TODO - booking details
            return null;
        }
      }}
    </OrderByIdContainer>
  );
};

export default CustomerManagementOrderScreen;
