import { Nullable, UUID } from '@/domain/model/types';
import MenuButton from '@/presentation/components/common/buttons/menu';
import FileDownloadLink from '@/presentation/components/common/files/download/link';
import {
  corpActivationsFilterSelector,
  corpActivationsSortSelector,
} from '@features/corpActivation/table/store/selectors';
import { getCorpActivationsDownloadUrl } from '@features/corpActivation/utils/table';
import { offerExportAccept } from '@features/general/offer/constants';
import { Grid, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../components/common/header';

interface CorpActivationTableHeaderAdapterProps {
  readonly partnerId: Nullable<UUID>;
  readonly customerId: Nullable<UUID>;
}

const CorpActivationTableHeaderAdapter = ({ partnerId, customerId }: CorpActivationTableHeaderAdapterProps) => {
  const sort = useSelector(corpActivationsSortSelector);
  const filter = useSelector(corpActivationsFilterSelector);

  const [menuCloseMarker, setMenuCloseMarker] = useState<Nullable<symbol>>(null);

  const downloadUrl = getCorpActivationsDownloadUrl({
    sort,
    skipPageable: true,
    partnerId,
    customerId,
    filter,
  });

  return (
    <DefaultHeader
      headline={<AppBreadcrumbs />}
      actions={
        <Grid
          container
          spacing={1}
          alignItems='center'
        >
          <Grid item>
            <MenuButton
              id={uuidv4()}
              closeMarker={menuCloseMarker}
            >
              <MenuItem>
                <FileDownloadLink
                  src={downloadUrl}
                  accept={offerExportAccept}
                  fileName={'активации_корпоративных_предложений'}
                  component={'div'}
                  onDownload={() => setMenuCloseMarker(Symbol())}
                >
                  Выгрузить реестр
                </FileDownloadLink>
              </MenuItem>
            </MenuButton>
          </Grid>
        </Grid>
      }
    >
      <Typography variant='h2'>Активации корпоративных предложений</Typography>
    </DefaultHeader>
  );
};

export default CorpActivationTableHeaderAdapter;
