import ProductOrderActionsDialogCancelAdapter from './dialogCancel';

const ProductOrderActionsDialogsAdapter = () => {
  return (
    <>
      <ProductOrderActionsDialogCancelAdapter />
    </>
  );
};

export default ProductOrderActionsDialogsAdapter;
