import { Fade, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ENoticeStatus } from '../../../../../domain/model/enums';
import { UUID } from '../../../../../domain/model/types';
import { MpUserData, SportUserData } from '../../../../../domain/model/user';
import Notifier from '../../../../../system/notifier';
import MasterActionsComponent from '../../../../components/common/actions/master';
import useNavAdapter from '../../../../components/common/actions/navAdapter/hooks';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../components/common/header';
import ContentLoader from '../../../../components/common/loader';
import { DefaultContentWrapper } from '../../../../components/common/wrappers/content';
import { DefaultFooterWrapper } from '../../../../components/common/wrappers/footer';
import useValidation from '../../../../hooks/validation/useValidation';
import { EPanelActionPosition, PanelAction, PanelActions } from '../../../../types';
import StepperContainer from '../../../general/stepper/container';
import { EUserSportActionType, UserSportEditActionType } from '../types';
import { getUserSportActionName } from '../utils';
import UserSportEdit from './component';
import { ContainerWrapper, ContentContainer, LoaderWrapper, TitleWrapper } from './controls';
import { userSportEditByIdSelector, userSportEditUpdateSelector } from './store/selectors';
import {
  userSportEditByIdFetch,
  userSportEditSetAttribute,
  userSportEditStateReset,
  userSportEditUpdate,
} from './store/slice';
import { EUserSportEditStep, userSportEditSteps, userSportEditValidation } from './utils';

interface UserSportEditContainerProps {
  readonly id: UUID;
  readonly step?: EUserSportEditStep;
  readonly onAfterSave?: () => void;
}

const validationRulesProfile = userSportEditValidation;

const UserSportEditContainer = (props: UserSportEditContainerProps) => {
  const { id, step = userSportEditSteps[0].key, onAfterSave } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const [validateOnChangeProfile, setValidateOnChangeProfile] = useState<boolean>(false);

  const { data: user, isFetching } = useSelector(userSportEditByIdSelector);
  const { isFetching: isUpdating, isFetched: isUpdated } = useSelector(userSportEditUpdateSelector);

  const {
    validationResult: validationResultProfile,
    isValid: isValidProfile,
    validate: validateProfile,
  } = useValidation<SportUserData>({
    object: user,
    validateOnChange: validateOnChangeProfile,
    rules: validationRulesProfile,
  });

  const onChangeProfileAttribute = (name: keyof MpUserData, value: any) => {
    dispatch(userSportEditSetAttribute({ name, value }));
  };

  const onNextStep = () => {
    if (step === EUserSportEditStep.Profile) {
      const isValidObject = validateProfile();
      if (!isValidObject) {
        setValidateOnChangeProfile(true);
        return;
      }
    }
  };

  const onClose = () => {
    history.goBack();
  };

  const onSave = () => {
    const isValidObject = validateProfile();
    if (!isValidObject) {
      setValidateOnChangeProfile(true);
      return;
    }

    dispatch(userSportEditUpdate(user!));
  };

  const onPanelAction = (action: PanelAction<UserSportEditActionType>) => {
    const { type } = action;
    switch (type) {
      case EUserSportActionType.Save:
        onSave();
        break;
    }
  };

  useEffect(() => {
    if (isUpdated) {
      Notifier.getInstance().addNotice(ENoticeStatus.Success, `Данные профиля успешно сохранены`);
      onAfterSave?.();
      onClose();
    }
  }, [isUpdated]);

  useEffect(() => {
    dispatch(userSportEditByIdFetch(id));

    return () => {
      dispatch(userSportEditStateReset());
    };
  }, [dispatch, id]);

  const activeStep = userSportEditSteps.findIndex(item => item.key === step);
  const mpStep = userSportEditSteps.find(item => item.key === step) ?? userSportEditSteps[0];

  const nextStepIndex = Math.min(activeStep + 1, userSportEditSteps.length - 1);
  const isLastStep = activeStep === nextStepIndex;

  const actions: PanelActions<UserSportEditActionType> = isLastStep
    ? [
        {
          type: EUserSportActionType.Save,
          label: getUserSportActionName(EUserSportActionType.Save),
          primary: true,
          position: [EPanelActionPosition.Default],
        },
      ]
    : [];

  const { adapter: navAdapter, actions: navActions } = useNavAdapter({
    openPrevStep: () => {},
    currentStepIndex: activeStep,
    openNextStep: onNextStep,
    stepsCount: userSportEditSteps.length,
  });

  const actionsPanel = (
    <MasterActionsComponent<UserSportEditActionType>
      actions={actions}
      navAdapter={navAdapter}
      show={!!actions.length || !!navActions.length}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );

  return (
    <Fade in>
      <ContainerWrapper>
        <StepperContainer<EUserSportEditStep>
          step={mpStep}
          steps={userSportEditSteps}
        />
        <ContentContainer>
          <DefaultContentWrapper
            type='details'
            stickyHeader
            fullHeight
            footer={actionsPanel}
          >
            <DefaultHeader
              sticky
              headline={<AppBreadcrumbs />}
              onClose={onClose}
            >
              <TitleWrapper>
                <Typography variant='h2'>Редактирование профиля</Typography>
              </TitleWrapper>
            </DefaultHeader>
            {user && (
              <UserSportEdit
                step={step}
                user={user}
                validationProfile={validationResultProfile}
                isValidProfile={isValidProfile}
                onChangeProfileAttribute={onChangeProfileAttribute}
              />
            )}
          </DefaultContentWrapper>
        </ContentContainer>
        {(isFetching || isUpdating) && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
      </ContainerWrapper>
    </Fade>
  );
};

export default UserSportEditContainer;
