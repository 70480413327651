import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { paginationSizeVariant } from '../../../../../domain/model/constants';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { tradeOffersCountNeedRefreshWatcherSelector, tradeOffersGuidSelector } from '../store/selectors';
import { tradeOffersCountFetch, TradeOffersFetchProps } from '../store/slice';

interface UseTradeOffersTableCountProps {
  readonly partnerId: Nullable<UUID>;
}

const useTradeOffersTableCount = (props: UseTradeOffersTableCountProps): void => {
  const { partnerId } = props;

  const dispatch = useDispatch();

  const guid = useSelector(tradeOffersGuidSelector);
  const needRefreshWatcher = useSelector(tradeOffersCountNeedRefreshWatcherSelector);

  const queryParams = useMemo<TradeOffersFetchProps>(() => {
    return {
      search: {
        partnerId,
        statuses: [],
        categories: null,
        sort: null,
        pageSize: paginationSizeVariant[0],
      },
      filter: {},
      pageNumber: 0,
    };
  }, [partnerId]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      const promise = dispatch(tradeOffersCountFetch(queryParams));
      return () => {
        promise?.abort();
      };
    }
  }, [dispatch, needRefreshWatcher, queryParams]);

  // начальный поиск и отмена запроса после ухода
  useEffect(() => {
    const promise = dispatch(tradeOffersCountFetch(queryParams));
    return () => {
      promise?.abort();
    };
  }, [dispatch, queryParams, guid]);
};

export default useTradeOffersTableCount;
