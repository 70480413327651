import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../data/store/store';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { EBookingOrderActionType } from '../../types';
import { BookingOrderActionsState } from './slice';

export const getBookingOrderActionsOfferIsProcessingSelector = (id: Nullable<UUID>) =>
  createSelector(
    (store: RootState) => store.bookingOrder.actions.actions,
    (store: RootState) => store.bookingOrder.actions.create.isFetching,
    (actions, isCreating) => {
      return id ? actions.some(action => action.id === id && action.isFetching) : isCreating;
    }
  );

export const bookingOrderActionsDialogByNameSelector =
  <Key extends keyof BookingOrderActionsState['dialogs']>(name: Key) =>
  (store: RootState) =>
    store.bookingOrder.actions.dialogs[name];

export const isBookingOrdersAnyChangedSelector = (store: RootState) =>
  store.bookingOrder.actions.actions.filter(action => !action.isFetching).length;

export const getIsBookingOrderAnyActionByTypeExecutedSelector = (
  id: Nullable<UUID>,
  ...actions: EBookingOrderActionType[]
) =>
  createSelector(
    () => id,
    () => actions,
    (store: RootState) => store.bookingOrder.actions.actions,
    (id, argActions, storeActions) => {
      return id
        ? storeActions.find(action => action.id === id && action.isFetched && argActions.some(a => a === action.type))
            ?.type ?? null
        : null;
    }
  );
