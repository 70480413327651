import styled from '@emotion/styled';

export const Color = styled.div`
  width: 100%;
  height: 100%;
  min-height: 3rem;

  border: 1px solid ${p => p.theme.palette.secondary.main};
  border-radius: ${p => p.theme.shape.borderRadius * 2}px;
`;
