import DataTableCellSortIndex from '../../../../../../components/common/table/cell/sortIndex';
import { useState } from 'react';
import { Nullable } from '../../../../../../../domain/model/types';

type ProductOrderItemTableCellCostProps = {
  value: Nullable<number>;
  max?: number;
  onChange(value: number): void;
};

const ProductOrderItemTableCellCost = (props: ProductOrderItemTableCellCostProps) => {
  const { value, max = 999999, onChange } = props;
  const [editMode, setEditMode] = useState(false);

  if (!value) {
    return <>-</>;
  }

  return (
    <DataTableCellSortIndex
      editMode={editMode}
      value={value}
      max={max}
      label={(min, max) => `${min}-${max}`}
      onChange={onChange}
      hideEditIcon={editMode}
      isFetching={false}
      onChangeEditMode={(value, event) => {
        event.stopPropagation();
        setEditMode(value);
      }}
    />
  );
};

export default ProductOrderItemTableCellCost;
