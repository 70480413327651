import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AnyFileMetadata, AppFile } from '../../domain/model';
import HttpClient from '../network/http';
import { getFilesEndpoint } from './utils';

type FilesApi = {
  upload: (file: File, config?: object) => Promise<AxiosResponse<AppFile>>;
  downloadBySrc: (src: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<string>>;
  getUrl: (id: string, state?: string | null, cache?: boolean) => string;
  getInfo: (id: string, config: object) => Promise<AxiosResponse<AppFile>>;
  getInfoByUrl: (url: string, config: object) => Promise<AnyFileMetadata>;
};

const cacheControlOn = 'private, max-age=2592000, must-revalidate';
const cacheControlOff = 'private, no-cache, no-store, max-age=0, must-revalidate, proxy-revalidate, s-maxage=0';

/**
 * АПИ по работе с файлами
 */
const files: FilesApi = {
  upload: (file, config = {}) => {
    const formData = new FormData();
    formData.append('file', file);
    const reqConfig = {
      ...config,
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return axios.post(`${getFilesEndpoint()}/preload`, formData, reqConfig);
  },
  downloadBySrc: (src, config) => {
    return axios.get(src, {
      ...config,
      responseType: 'arraybuffer',
    });
  },
  getUrl: (id, state, cache = true) => {
    const token = HttpClient.getInstance().getAuthService().token;
    const params = new URLSearchParams();

    params.append('path', id);
    if (token) params.append('access_token', token);
    if (state) params.append('state', state);
    if (!cache) {
      params.append('cache-control', cacheControlOff);
    } else {
      params.append('cache-control', cacheControlOn);
    }

    return `${getFilesEndpoint()}/files?${params}`;
  },
  getInfo: (id, config) => {
    const params = new URLSearchParams();

    params.append('path', id);

    return axios.get(`${getFilesEndpoint()}/metadata`, {
      ...config,
      params,
    });
  },
  getInfoByUrl: (url, config) => {
    return axios.head(url, config).then<AnyFileMetadata>(response => {
      const name = url.split('/').pop() ?? url;
      const size = parseInt(response.headers['content-length']) || null;
      const type = response.headers['content-type'] || null;
      return {
        name,
        size,
        type,
      };
    });
  },
};

export default files;
