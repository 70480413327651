import { ReactNode, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Nullable, UUID } from '../../../../domain/model/types';
import { SportUserProfile } from '../../../../domain/model/user';
import TopPushDialog from '../../../components/common/dialogs/topPush';
import useDialogInHistory from '../../../hooks/useDialogInHistory';
import { viewConfig } from '../../../theme/viewConfig';
import { NumberRange } from '../../../types';
import { SportsmansSearchContainer } from '../search/container';
import { getSportsmanSearchSportsmanPresetValues } from '../table/utils';
import { ECollectionSearchDialogTag } from '../types';
import useSportsmanSearchFilterAdapter from './hooks/useFilterAdapter';
import { ActivityType } from '../../../../domain/model/event';

interface SportsmanEditControlCSingleProps {
  readonly onChange?: (sportsman: Nullable<SportUserProfile>) => void;
  readonly selectedMaxCount: 1;
}

interface SportsmanEditControlCMultipleProps {
  readonly onChange?: (sportsmans: SportUserProfile[]) => void;
  readonly selectedMaxCount: NumberRange<2, 100> | false;
}

export type SportsmanEditControlCSelectedProps = SportsmanEditControlCSingleProps | SportsmanEditControlCMultipleProps;

type SportsmanEditControlContainerProps = {
  readonly activityTypes?: Nullable<ActivityType[]>;
  readonly headerDialog?: string;
  readonly control: ReactNode;
} & SportsmanEditControlCSelectedProps;

interface UseDialogInHistoryParams {
  readonly dialog_guid: UUID;
}

const SportsmanEditControlContainer = (props: SportsmanEditControlContainerProps) => {
  const { onChange, activityTypes = null, control = null, headerDialog, selectedMaxCount } = props;

  const filterPresetValues = useMemo(() => getSportsmanSearchSportsmanPresetValues(activityTypes), [activityTypes]);

  const sportsmenFilterAdapter = useSportsmanSearchFilterAdapter({
    values: filterPresetValues,
  });

  const {
    open: isSearchDialogOpened,
    onOpen: onOpenSearchDialog,
    onClose: onCloseSearchDialog,
    params: paramDialog,
  } = useDialogInHistory<UseDialogInHistoryParams>({
    tag: ECollectionSearchDialogTag.SportsmanMultiple,
    params: { dialog_guid: uuidv4() },
  });

  const onChangeInternal = useCallback(
    (newValue: any) => {
      onChange?.(newValue);
      onCloseSearchDialog();
    },
    [onChange]
  );

  const handleClick = () => {
    onOpenSearchDialog({ dialog_guid: uuidv4() });
  };

  return (
    <>
      <div onClick={handleClick}>{control}</div>
      <TopPushDialog
        fullScreen
        open={isSearchDialogOpened}
        title={headerDialog ?? 'Выбор спортсменов'}
        leftOffset={viewConfig.stepper.width}
        onClose={onCloseSearchDialog}
      >
        <SportsmansSearchContainer
          guid={paramDialog.dialog_guid}
          selectedMaxCount={selectedMaxCount as NumberRange<2, 100>}
          filterAdapter={sportsmenFilterAdapter}
          initialSelected={null}
          onSelect={(value: any) => onChangeInternal(value)}
        />
      </TopPushDialog>
    </>
  );
};

export default SportsmanEditControlContainer;
