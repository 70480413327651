import { ENoticeStatus } from '@/domain';
import Notifier from '@/system/notifier';
import SaveWhenExitDialog from '@components/common/dialogs/saveWhenExit';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { clientOrgCreateDialogCloseSelector } from '../store/selectors';

export const ClientOrgCreateDialogCloseAdapter = () => {
  const handlers = useContextHandlers();

  const clientOrg = useSelector(clientOrgCreateDialogCloseSelector);
  const [isLoading, setIsLoading] = useState(false);

  const onClose = useCallback(() => {
    handlers.onChangeDialogState('close', null);
  }, [handlers.onChangeDialogState]);

  const onSave = useCallback(async () => {
    setIsLoading(true);
    try {
      await handlers.onClientOrgSave();
    } catch {
      Notifier.getInstance().addNotice(ENoticeStatus.Error, 'Возникла ошибка при сохранении компании');
    }

    onClose();
    setIsLoading(false);
  }, [handlers.onClientOrgSave, onClose]);

  if (!clientOrg) {
    return null;
  }

  return (
    <SaveWhenExitDialog
      open
      isSaving={isLoading}
      onCancel={onClose}
      onExit={handlers.onCloseImmediate}
      onExitAfterSave={onSave}
    />
  );
};
