import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { UUID } from '../../../../domain/model/types';
import { addStateToLocation } from '../../../../routing/globalRouting';

interface RequiredGuidInLocationContainerProps {
  readonly children: (guid: UUID) => React.ReactElement;
}

export interface DataLocationState {
  readonly guid?: UUID;
}

const RequiredGuidInLocationContainer = ({ children }: RequiredGuidInLocationContainerProps) => {
  const history = useHistory();
  const location = useLocation<{ guid: UUID }>();

  const guid = location.state?.guid;

  useEffect(() => {
    if (!guid) {
      history.replace(
        addStateToLocation<DataLocationState>({
          location,
          state: { guid: uuidv4() },
        })
      );
    }
  }, [guid, history, location]);

  if (!guid) {
    return null;
  }

  return children(guid);
};

export default RequiredGuidInLocationContainer;
