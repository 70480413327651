import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import CountInput from '../../../../components/common/countInput';

interface BookingOrderEditItemInfoQuantityItemProps {
  readonly label: ReactNode;
  readonly quantity: number;
  readonly onChange: (quantity: number) => void;
}

const BookingOrderEditItemInfoQuantityItem = (props: BookingOrderEditItemInfoQuantityItemProps) => {
  const { label, quantity, onChange } = props;

  return (
    <Grid
      container
      alignItems='center'
    >
      <Grid
        item
        xs={8}
      >
        {label}
      </Grid>
      <Grid
        item
        xs={4}
      >
        <CountInput
          min={1}
          value={quantity}
          onChangeCount={onChange}
          onIncrement={() => onChange(quantity + 1)}
          onDecrement={() => onChange(quantity - 1)}
        />
      </Grid>
    </Grid>
  );
};

export default BookingOrderEditItemInfoQuantityItem;
