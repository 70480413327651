import { useCallback, useState } from 'react';
import Api from '../../../../../data/api';
import { ApiQueryDsl } from '../../../../../data/api/types';
import { EOfferStatus } from '../../../../../domain/model/enums';
import { DataFilterQueryDslOperator } from '../../../../../domain/model/filter';
import { ProductOffer } from '../../../../../domain/model/productOffer';
import { Nullable } from '../../../../../domain/model/types';
import { AsyncAutocomplete } from '../../../../theme/ui-kit/asyncAutocomplete';
import { MPButton } from '../../../../theme/ui-kit/button';
import { concatProductName } from '../../utils/helpers';
import { Container } from './controls';

type AutocompleteProductButtonProps = {
  readonly onExecute: (product: ProductOffer) => void;

  readonly partnerId: string;
};

export const AutocompleteProductButton = (props: AutocompleteProductButtonProps) => {
  const { onExecute, partnerId } = props;
  const [value, setValue] = useState<Nullable<ProductOffer>>();

  const handleLoadOptions = useCallback(async (search?: string): Promise<ProductOffer[]> => {
    const querydsl: ApiQueryDsl = [
      {
        value: [EOfferStatus.Active],
        operator: DataFilterQueryDslOperator.In,
        field: 'status',
      },
    ];

    if (search) {
      querydsl.push({
        value: [`%${search}%`],
        operator: DataFilterQueryDslOperator.Like,
        field: 'partnerSKU',
      });
    }

    const response = await Api.productOffers.all({
      page: 1,
      pageSize: 20,
      querydsl,
      partnerId,
      sort: 'variantName,asc',
    });

    return response.data;
  }, []);

  const handleExecute = useCallback(() => {
    if (value) {
      onExecute(value);
    }

    setValue(null);
  }, [value]);

  return (
    <Container>
      <AsyncAutocomplete<Nullable<ProductOffer> | undefined>
        value={value}
        onChange={setValue}
        loadOptionsMethod={handleLoadOptions}
        filterOptions={options => options}
        label={'SKU в вашей системе'}
        getOptionLabel={option => concatProductName(option?.name || null, option?.variantName)}
      />
      <MPButton
        disabled={value == null}
        onClick={handleExecute}
        variant={'outlined'}
      >
        Добавить товар
      </MPButton>
    </Container>
  );
};
