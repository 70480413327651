import { EOrderItemStatus } from '../../../../../../domain/model/enums';
import DataTableCellTextFormat, {
  DataTableCellTextFormatProps,
} from '../../../../../components/common/table/cell/textFormat';
import { bookingOrderItemTableStatuses, getBookingOrderItemStatusName } from '../../../utils/common';

export interface BookingOrderItemTableCellImageProps extends DataTableCellTextFormatProps {
  readonly status: EOrderItemStatus;
  readonly hasError?: boolean;
}

export const BookingOrderItemTableCellStatus = (props: BookingOrderItemTableCellImageProps) => {
  const { status, hasError } = props;
  return status && bookingOrderItemTableStatuses.includes(status) ? (
    <DataTableCellTextFormat color={hasError ? 'error' : 'inherit'}>
      {getBookingOrderItemStatusName(status)}
    </DataTableCellTextFormat>
  ) : null;
};
