import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import {
  BookingOffer,
  BookingOfferService,
  BookingServicePriceItem,
  BookingServicePriceUnit,
} from '../../../../../domain/model/booking';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { ValidationCollectionResult, ValidationItemResult } from '../../../../utils/validation';
import { convertBookingOfferToBookingOfferView, createEmptyBookingOfferView } from '../../create/utils';
import bookingServices from '../../services';
import { BookingOfferServiceView, BookingOfferView } from '../../types';

export interface BookingOfferServiceManageState {
  readonly byId: Fetchable & {
    readonly bookingOffer: BookingOfferView;
    readonly loadedData: Nullable<BookingOffer>;
  };
  readonly servicePriceUnits: BookingServicePriceUnit[];
  readonly modified: boolean;

  readonly validationServices: ValidationCollectionResult<BookingOfferServiceView>;
  readonly validationPriceItems: ValidationCollectionResult<BookingServicePriceItem>[];
}

type Reducer<T = undefined> = CaseReducer<BookingOfferServiceManageState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<BookingOfferServiceManageState> {
  bookingOfferServiceManageStateReset: Reducer;
  bookingOfferServiceManageSetModified: Reducer<boolean>;

  bookingOfferServiceManageClearAllValidations: Reducer;
  bookingOfferServiceManageSetServicesValidation: Reducer<
    Nullable<ValidationCollectionResult<BookingOfferServiceView>>
  >;
  bookingOfferServiceManageSetServicePriceItemsValidation: Reducer<{
    serviceIndex: number;
    results: Nullable<ValidationCollectionResult<BookingServicePriceItem>>;
  }>;
  //price item
  bookingOfferServiceManageSetServicePriceItemAttribute: Reducer<{
    serviceIndex: number;
    index: number;
    name: keyof BookingServicePriceItem;
    value: any;
  }>;
  bookingOfferServiceManageServicePriceItemSetAttributeValidation: Reducer<{
    serviceIndex: number;
    index: number;
    name: keyof BookingServicePriceItem;
    results: Nullable<ValidationItemResult>;
  }>;
  bookingOfferServiceManageServiceSetAttributeValidation: Reducer<{
    index: number;
    name: keyof BookingOfferServiceView;
    results: Nullable<ValidationItemResult>;
  }>;
  bookingOfferServiceManageClearServiceAttributeValidation: Reducer<{
    index: number;
    name: keyof BookingOfferServiceView;
  }>;
  bookingOfferServiceManageSetServiceAttribute: Reducer<{
    index: number;
    name: keyof BookingOfferService;
    value: any;
  }>;
}

type bookingOfferServiceManageByIdFetchProps = {
  readonly bookingOffer: Nullable<BookingOffer>;
  readonly servicePriceUnits: BookingServicePriceUnit[];
};

export const bookingOfferServiceManageByIdFetch = createAsyncThunk<
  bookingOfferServiceManageByIdFetchProps,
  {
    id: UUID;
  },
  AppThunkAPIConfig
>('bookingOffer/serviceManage/byId/fetch', async ({ id }, { rejectWithValue, signal }) => {
  try {
    const servicePriceUnits: BookingServicePriceUnit[] = (
      await Api.bookingDictionary.priceUnit.all({
        signal,
      })
    ).data;

    const bookingOffer = await bookingServices.offer.one({ id });
    return { bookingOffer, servicePriceUnits };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

const slice = createSlice<BookingOfferServiceManageState, Reducers, 'serviceManage'>({
  name: 'serviceManage',
  initialState: {
    byId: {
      ...fetchableDefault,
      bookingOffer: createEmptyBookingOfferView(null),
      loadedData: null,
    },
    servicePriceUnits: [],
    modified: false,
    validationServices: [],
    validationPriceItems: [],
  },
  reducers: {
    bookingOfferServiceManageStateReset: state => {
      state.byId = {
        ...fetchableDefault,
        bookingOffer: createEmptyBookingOfferView(null),
        loadedData: null,
      };
      state.modified = false;
      state.validationServices = [];
      state.validationPriceItems = [];
      state.servicePriceUnits = [];
    },
    bookingOfferServiceManageSetModified: (state, { payload }) => {
      state.modified = payload;
    },
    bookingOfferServiceManageClearAllValidations: state => {
      state.validationServices = [];
      state.validationPriceItems = [];
    },
    bookingOfferServiceManageSetServicePriceItemsValidation: (state, { payload }) => {
      const { serviceIndex, results } = payload;
      state.validationPriceItems[serviceIndex] = results ?? [];
    },
    bookingOfferServiceManageSetServicesValidation: (state, { payload }) => {
      state.validationServices = payload ?? [];
    },

    //price item
    bookingOfferServiceManageSetServicePriceItemAttribute: (state, { payload }) => {
      const { serviceIndex, index, name, value } = payload;
      const priceItem = state.byId.bookingOffer.services?.[serviceIndex]?.priceItems?.[index];
      if (priceItem) {
        priceItem[name] = value;
      }
    },

    bookingOfferServiceManageServicePriceItemSetAttributeValidation: (state, { payload }) => {
      const { serviceIndex, index, name, results } = payload;
      const itemValidation = state.validationPriceItems[serviceIndex]?.[index];
      if (itemValidation) {
        if (!results) {
          delete itemValidation[name];
        } else {
          itemValidation[name] = results;
        }
      } else {
        if (!state.validationPriceItems[serviceIndex]) {
          state.validationPriceItems[serviceIndex] = [];
        }
        state.validationPriceItems[serviceIndex][index] = {
          [name]: results,
        };
      }
    },

    bookingOfferServiceManageServiceSetAttributeValidation: (state, { payload }) => {
      const { index, name, results } = payload;
      if (index >= 0) {
        const itemValidation = state.validationServices[index];
        if (itemValidation) {
          if (!results) {
            delete itemValidation[name];
          } else {
            itemValidation[name] = results;
          }
        } else {
          state.validationServices[index] = {
            [name]: results,
          };
        }
      }
    },
    bookingOfferServiceManageClearServiceAttributeValidation: (state, { payload }) => {
      const { index, name } = payload;
      if (index >= 0) {
        const itemValidation = state.validationServices[index];
        if (itemValidation) {
          delete itemValidation[name];
        }
      }
    },
    bookingOfferServiceManageSetServiceAttribute: (state, { payload }) => {
      const { index, name, value } = payload;

      if (state.byId.bookingOffer?.services[index]) {
        (state.byId.bookingOffer.services[index][name] as BookingOfferService) = value;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bookingOfferServiceManageByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.loadedData = null;
      })
      .addCase(bookingOfferServiceManageByIdFetch.fulfilled, (state, { payload }) => {
        const { bookingOffer, servicePriceUnits } = payload;
        state.byId.isFetching = false;
        state.byId.isFetched = false;
        state.byId.isFailed = true;

        state.byId.bookingOffer = bookingOffer
          ? convertBookingOfferToBookingOfferView(bookingOffer)
          : createEmptyBookingOfferView(null);
        state.byId.loadedData = bookingOffer;
        state.servicePriceUnits = servicePriceUnits;
      })
      .addCase(bookingOfferServiceManageByIdFetch.rejected, state => {
        state.byId.isFetching = false;
        state.byId.isFetched = false;
        state.byId.isFailed = true;
        state.byId.loadedData = null;
      });
  },
});

export const {
  bookingOfferServiceManageStateReset,
  bookingOfferServiceManageSetModified,
  bookingOfferServiceManageClearAllValidations,
  bookingOfferServiceManageSetServicePriceItemsValidation,
  bookingOfferServiceManageSetServicesValidation,
  bookingOfferServiceManageSetServicePriceItemAttribute,
  bookingOfferServiceManageServicePriceItemSetAttributeValidation,
  bookingOfferServiceManageServiceSetAttributeValidation,
  bookingOfferServiceManageClearServiceAttributeValidation,
  bookingOfferServiceManageSetServiceAttribute,
} = slice.actions;

export default slice.reducer;
