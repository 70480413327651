import { isArray } from 'lodash';
import { useState } from 'react';
import { CalendarProps, DateObject } from 'react-multi-date-picker';
import { Nullable } from '../../../../../domain/model/types';
import { permanentOptions, regularOptions, StyledCalendar } from '../configuration';

export type DatesRange = {
  readonly startDate: Nullable<Date>;
  readonly endDate: Nullable<Date>;
};

export type MPRangeCalendarPickerProps = Omit<CalendarProps<false, true>, 'onChange' | 'multiple' | 'radge'> & {
  readonly onChange?: (range: Nullable<DatesRange>) => false | void;
};

export const MPRangeCalendarPicker = (props: MPRangeCalendarPickerProps) => {
  const [isSelectingRange, setIsSelectingRange] = useState<boolean>(false);

  const onChangeInternal = (selectedDates: Nullable<DateObject | DateObject[]>): false | void => {
    if (selectedDates === null) {
      setIsSelectingRange(false);
      return props.onChange?.(null);
    }

    const result: Nullable<DatesRange> = !isArray(selectedDates)
      ? { startDate: selectedDates.toDate(), endDate: null }
      : { startDate: selectedDates?.[0]?.toDate() ?? null, endDate: selectedDates?.[1]?.toDate() ?? null };

    if (result?.startDate !== null && result?.endDate === null) {
      setIsSelectingRange(true);
    } else {
      setIsSelectingRange(false);
    }

    return props.onChange?.(result);
  };

  return (
    <StyledCalendar
      {...regularOptions}
      {...props}
      isSelecting={isSelectingRange}
      onChange={onChangeInternal}
      multiple={false}
      range
      {...permanentOptions}
    />
  );
};
