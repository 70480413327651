import { CommonLifeCycleInstance } from '@/presentation/utils/lifecycle';
import { commonAdminConfiguration, commonPartnerConfiguration } from './configurations';
import {
  ClientOrgLifeCycle,
  ClientOrgLifeCycleFactoryProps,
  ClientOrgLifeCycleProps,
  ClientOrgLifeCycleType,
} from './types';

/**
 * жизненный цикл объекта Корпоративных пользователей
 */
const ClientOrgLifeCycleInstance = (props: ClientOrgLifeCycleProps<ClientOrgLifeCycleType>): ClientOrgLifeCycle => {
  const { configuration, userId, isPartnerUser } = props;

  const commonLifecycle = CommonLifeCycleInstance(props);

  return {
    configuration,
    build: ({ allowedActions, obj }) => {
      return commonLifecycle.build({ allowedActions, obj });
    },
    isPartnerUser,
    userId,
  };
};

/**
 * жизненный цикл объекта ДиО - основная модель
 */
export const ClientOrgLifeCycleFactory = () => {
  return {
    create: ({ isPartnerUser, userId, debug }: ClientOrgLifeCycleFactoryProps): ClientOrgLifeCycle => {
      if (isPartnerUser) {
        return ClientOrgLifeCycleInstance({ userId, configuration: commonPartnerConfiguration, debug, isPartnerUser });
      } else {
        return ClientOrgLifeCycleInstance({ userId, configuration: commonAdminConfiguration, debug, isPartnerUser });
      }
    },
  };
};
