import { FC, FCC, ReactNode } from 'react';
import RenderDecoratorContainer from '../../../../components/common/renderDecoratorContainer';
import { ContentWrapper, Wrapper } from './controls';

interface BookingOfferServiceManageContentLayoutProps {
  readonly header?: FC;
  readonly footer?: FC;
  readonly decorators?: ReactNode[];
}

const BookingOfferServiceManageContentLayout: FCC<BookingOfferServiceManageContentLayoutProps> = props => {
  const { children, decorators = [], header: Header, footer: Footer } = props;

  return (
    <Wrapper>
      <RenderDecoratorContainer decorators={decorators}>
        <ContentWrapper>
          {Header && <Header />}
          {children}
          {Footer && <Footer />}
        </ContentWrapper>
      </RenderDecoratorContainer>
    </Wrapper>
  );
};

export default BookingOfferServiceManageContentLayout;
