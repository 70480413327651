import { UserAccessPartitionProductOrders } from '../../../../domain/model/accessMatrix';
import { ProductOrder } from '../../../../domain/model/order';
import { Nullable, UUID } from '../../../../domain/model/types';
import TableCommonLayout from '../../../layouts/tableCommon/container';
import { ProductOrderLifeCycle } from '../lifecycle/types';
import { EProductOrderTableTab } from '../types';
import { ProductOrderTableBehaviorAdapter } from './adapters/behavior';
import ProductOrderTableFilterAdapter from './adapters/filters';
import ProductOrderTableHeaderAdapter from './adapters/header';
import ProductOrdersTableTableAdapter from './adapters/table';
import { ProductOrderTableTabsAdapter } from './adapters/tabs';
import { ProductOrderTableContext } from './context';
import ProductOrderTableFooterContainer from './footerContainer';
import { useProductOrderTableHandlers } from './hooks/useTable';
import { getProductOrderTableTabActions } from './utils';

export interface ProductOrderTableContainerProps {
  readonly guid: UUID;
  readonly partnerId: Nullable<UUID>;
  readonly customerId: Nullable<UUID>;
  readonly tab: EProductOrderTableTab;
  readonly lifecycle: ProductOrderLifeCycle;
  readonly access: UserAccessPartitionProductOrders;
  readonly onOrderClick: (order: ProductOrder) => void;
}

const ProductOrderTableContainer = (props: ProductOrderTableContainerProps) => {
  const { guid, partnerId, customerId, tab, lifecycle, access, onOrderClick } = props;
  const handlers = useProductOrderTableHandlers({ tab });

  const tableTabActions = getProductOrderTableTabActions(tab, access);

  return (
    <ProductOrderTableContext.Provider value={handlers}>
      <ProductOrderTableBehaviorAdapter
        guid={guid}
        tab={tab}
        partnerId={partnerId}
        customerId={customerId}
      />
      <TableCommonLayout
        header={<ProductOrderTableHeaderAdapter canCreate={access.create} />}
        filter={<ProductOrderTableFilterAdapter customerId={customerId} />}
        tabs={<ProductOrderTableTabsAdapter tab={tab} />}
        table={
          <ProductOrdersTableTableAdapter
            tab={tab}
            partnerId={partnerId}
            tabActions={tableTabActions}
            lifecycle={lifecycle}
            onOrderClick={onOrderClick}
          />
        }
        footer={ProductOrderTableFooterContainer}
      />
    </ProductOrderTableContext.Provider>
  );
};

export default ProductOrderTableContainer;
