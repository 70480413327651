import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AdCampaignDetailsStep } from '../../types';
import { adCampaignDetailsSetUiState } from '../store/slice';
import { AdCampaignDetailsUiState } from '../types';
import { getAdCampaignDetailsSteps, getAdCampaignDetailsStepText } from '../utils';

export const AdCampaignDetailsUiStepsState = () => {
  const dispatch = useDispatch();

  const value = useMemo<AdCampaignDetailsUiState['steps']>(() => {
    const steps: AdCampaignDetailsStep[] = getAdCampaignDetailsSteps().map(key => ({
      key,
      label: getAdCampaignDetailsStepText(key),
    }));

    return steps;
  }, []);

  useEffect(() => {
    dispatch(adCampaignDetailsSetUiState({ name: 'steps', value }));
  }, [dispatch, value]);

  return null;
};
