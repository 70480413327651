import { Playground } from '../../../../domain/model/playground';
import { PlaygroundCommonServiceSaveArgs } from './common';
import Api from '../../../../data/api';
import { EPlaygroundStatus, EUserRole } from '../../../../domain/model/enums';
import { v4 as uuid } from 'uuid';
import { UUID } from '../../../../domain/model/types';
import { ApiCancellable } from '../../../../data/api/types';

type PublishProps = Playground;

type UnblockProps = {
  readonly id: UUID;
};

type BlockProps = {
  readonly id: UUID;
  readonly reason: string;
};

type CreatedByRolesProps = ApiCancellable & {
  readonly playground: Playground;
};

export type PlaygroundActionService = {
  readonly save: (playground: PlaygroundCommonServiceSaveArgs) => Promise<Playground>;
  readonly publish: (props: PublishProps) => Promise<Playground>;
  readonly unblock: (props: UnblockProps) => Promise<void>;
  readonly block: (props: BlockProps) => Promise<void>;
  readonly createdByRoles: (props: CreatedByRolesProps) => Promise<EUserRole[]>;
};
export const playgroundActionService: PlaygroundActionService = {
  // Метод сохранения площадки
  save: async playground => {
    const { id } = playground;
    if (!id) {
      return (
        await Api.playground.create({
          ...playground,
          id: uuid(),
          status: EPlaygroundStatus.Available,
        })
      ).data;
    } else {
      return (await Api.playground.update(playground)).data;
    }
  },

  // Метод для разблокировки площадки
  unblock: async props => (await Api.playground.unblock(props)).data,

  // Метод для блокировки площадки
  block: async props => (await Api.playground.block(props)).data,

  // Метод для публикации площадки
  publish: async playground => {
    const data = await playgroundActionService.save(playground);
    await playgroundActionService.unblock({ id: data.id });
    return data;
  },

  // Создание по роли
  createdByRoles: async ({ playground: { createdBy }, signal }) => {
    const createdById = createdBy?.id;
    if (createdById) {
      return (await Api.user.sport.one({ id: createdById })).data.roles;
    } else {
      return [];
    }
  },
};