import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { CorpOffer } from '../../../../../domain/model/corpOffer';
import { EOfferStatus } from '../../../../../domain/model/enums';
import { EOfferTableTab } from '../../../general/offer/types';
import {
  getOffersTableTabs,
  getOffersTableTabsForCounts,
  getOffersTableTabsForCountsUnwatched,
} from '../../../general/offer/utils/table';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { getCorpOfferDetailsRoute, getCorpOfferEditRoute } from '../../entry';

export type UseCorpOffersTableConfig = {
  readonly tabs: EOfferTableTab[];
  readonly tabsForCounts: EOfferTableTab[];
  readonly tabsForCountsUnwatched: EOfferTableTab[];
  readonly defaultTab: EOfferTableTab;
  readonly multiSelectAllowed: boolean;
  readonly onCorpOfferClick: (corpOffer: CorpOffer) => void;
  readonly onCorpOfferOpenEdit: (corpOffer: CorpOffer) => void;
};

export function useCorpOffersTableConfig(): UseCorpOffersTableConfig {
  const history = useHistory();

  const {
    accessMatrix: {
      isPartnerUser,
      corpOffers: { create },
    },
  } = useCurrentUser();

  const onCorpOfferClick = useCallback(
    (corpOffer: CorpOffer) => {
      if ([EOfferStatus.Draft].includes(corpOffer.status) && create) {
        history.push(getCorpOfferEditRoute({ id: corpOffer.id }));
      } else {
        history.push(getCorpOfferDetailsRoute({ id: corpOffer.id }));
      }
    },
    [history]
  );

  const onCorpOfferOpenEdit = useCallback(
    (corpOffer: CorpOffer) => {
      history.push(getCorpOfferEditRoute({ id: corpOffer.id }));
    },
    [history]
  );

  const tabs = useMemo(() => getOffersTableTabs(isPartnerUser), [isPartnerUser]);
  const tabsForCounts = useMemo(() => getOffersTableTabsForCounts(isPartnerUser), [isPartnerUser]);
  const tabsForCountsUnwatched = useMemo(() => getOffersTableTabsForCountsUnwatched(), []);
  const multiSelectAllowed = !isPartnerUser;
  const defaultTab = isPartnerUser ? EOfferTableTab.Active : EOfferTableTab.Moderation;

  return {
    tabs,
    tabsForCounts,
    tabsForCountsUnwatched,
    multiSelectAllowed,
    defaultTab,
    onCorpOfferClick,
    onCorpOfferOpenEdit,
  };
}
