import styled from '@emotion/styled';
import { Grid, Popper } from '@mui/material';

export const ToolbarGridItem = styled(Grid)`
  flex-grow: 1;
  text-align: right;
`;

export const ActionsGridItem = styled(Grid)`
  width: 100%;

  flex-grow: 1;
`;

export const MenuPopper = styled(Popper)`
  z-index: 1;

  .MuiPaper-root {
    padding-top: ${p => p.theme.spacing()};
    padding-bottom: ${p => p.theme.spacing()};
  }
`;
