import { Grid } from '@mui/material';
import styled from '@emotion/styled';

export const HeaderWrapper = styled(Grid)`
  cursor: pointer;
`;

export const HeaderAnnotationWrapper = styled(Grid)`
  align-self: flex-end;
`;
