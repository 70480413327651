import ProductTableCell, { ProductTableCellCommonProps } from './index';

const ProductTableCellLastStatusAuthor = (props: ProductTableCellCommonProps) => {
  const {
    productOffer: { lastStatusAuthor },
  } = props;

  const value = lastStatusAuthor?.name ?? '-';

  return (
    <ProductTableCell
      {...props}
      value={value}
    />
  );
};

export default ProductTableCellLastStatusAuthor;
