import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { EOrderPartition, EOrderStatus } from '../../../domain/model/enums';
import { BookingOrder } from '../../../domain/model/order';
import { Nullable } from '../../../domain/model/types';
import { MpUser } from '../../../domain/model/user';
import ForbiddenComponent from '../../components/common/forbidden';
import BookingOrdersActionsProvider from '../../features/bookingOrder/actions/provider';
import BookingOrderTableEntry from '../../features/bookingOrder/table/entry';
import { EBookingOrderTableTab } from '../../features/bookingOrder/types';
import { getCustomerManagementOrderRoute } from '../../features/customer/management/common/entry';
import { viewShowCustomerManagementCommonMenu } from '../../features/customer/management/common/store/slice';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import { EOrderUrlParam, getOrderEditRoute, OrdersLocationState } from '../../features/order/entry';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface CustomerManagementBookingOrdersScreenProps {
  readonly customer: MpUser;
}

const CustomerManagementBookingOrdersScreen = ({ customer }: CustomerManagementBookingOrdersScreenProps) => {
  const dispatch = useDispatch();
  const location = useLocation<Nullable<OrdersLocationState>>();
  const history = useHistory();

  const {
    accessMatrix: { orders, isPartnerUser },
    mpPartnerUserProfile,
  } = useCurrentUser();

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(EOrderUrlParam.Tab) as EBookingOrderTableTab;

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowCustomerManagementCommonMenu());
  }, [dispatch]);

  const onOrderClick = (order: BookingOrder) => {
    if (order.status === EOrderStatus.New && orders[EOrderPartition.BookingOffers].edit) {
      const editRoute = getOrderEditRoute({ id: order.id });
      history.push(editRoute);
    } else {
      history.push(
        getCustomerManagementOrderRoute({
          orderId: order.id,
          customerId: customer.id,
        })
      );
    }
  };

  if (!orders[EOrderPartition.BookingOffers].view) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<OrdersLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <BookingOrdersActionsProvider>
          <BookingOrderTableEntry
            guid={state.guid}
            tab={tab}
            partnerId={isPartnerUser ? mpPartnerUserProfile.partner.id : null}
            customerId={customer.id}
            onOrderClick={onOrderClick}
          />
        </BookingOrdersActionsProvider>
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default CustomerManagementBookingOrdersScreen;
