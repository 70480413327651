import axios, { AxiosResponse } from 'axios';
import { CorpOfferActivation, TradeOfferActivation } from '../../domain/model/activation';
import {
  EOfferActivationActionDiscriminator,
  EOfferActivationDiscriminator,
  EOfferType,
} from '../../domain/model/enums';
import { Nullable, UUID } from '../../domain/model/types';
import { createCancelToken } from './index';
import {
  ApiCancellable,
  ApiQueryDsl,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
  ApiResponseMaBeCounters,
} from './types';
import { appendQueryDslParams, getComServicesEndpoint } from './utils';

type AllProps<D extends ApiRequestListDiscriminator, OAD extends EOfferActivationDiscriminator> = ApiRequestPageable &
  ApiCancellable & {
    readonly activationDiscriminator: OAD;
    readonly discriminator?: D;
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

type TradeOfferActivationResponse<D extends EOfferActivationDiscriminator> =
  D extends EOfferActivationDiscriminator.TradeOffer ? TradeOfferActivation : never;
type CorpOfferActivationResponse<D extends EOfferActivationDiscriminator> =
  D extends EOfferActivationDiscriminator.CorpOffer ? CorpOfferActivation : never;

type FullOfferActivationResponse<D extends EOfferActivationDiscriminator> =
  | TradeOfferActivationResponse<D>
  | CorpOfferActivationResponse<D>;

type GiveProps = {
  readonly id: UUID;
};

type ActivationApi = {
  readonly all: <
    OAD extends EOfferActivationDiscriminator,
    D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List
  >(
    props: AllProps<D, OAD>
  ) => Promise<AxiosResponse<ApiResponseMaBeCounters<D, FullOfferActivationResponse<OAD>[]>>>;
  readonly corp: {
    readonly give: (props: GiveProps) => Promise<AxiosResponse<CorpOfferActivation>>;
    readonly unGive: (props: GiveProps) => Promise<AxiosResponse<CorpOfferActivation>>;
  };
};

/**
 * АПИ по работе с активациями предложений
 */
const activation: ActivationApi = {
  all: props => {
    const { activationDiscriminator, page, pageSize, sort, query, querydsl, discriminator, signal } = props;

    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    if (query) {
      params.append('q', query);
    }

    switch (activationDiscriminator) {
      case EOfferActivationDiscriminator.TradeOffer:
        params.append('offerType', EOfferType.Trade);
        break;
      case EOfferActivationDiscriminator.CorpOffer:
        params.append('offerType', EOfferType.Corp);
        break;
    }

    if (querydsl) {
      appendQueryDslParams(params, querydsl);
    }

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (discriminator) {
      params.append('resultType', discriminator);
    }

    return axios.get(`${getComServicesEndpoint()}/offers/activations`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  corp: {
    give: ({ id }) => {
      return axios.post(`${getComServicesEndpoint()}/offers/activations/${id}`, {
        discriminator: EOfferActivationActionDiscriminator.CorpOfferActivationSetGivenCommand,
      });
    },
    unGive: ({ id }) => {
      return axios.post(`${getComServicesEndpoint()}/offers/activations/${id}`, {
        discriminator: EOfferActivationActionDiscriminator.CorpOfferActivationUnsetGivenCommand,
      });
    },
  },
};

export default activation;
