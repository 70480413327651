import { Grid, Link as MuiLink } from '@mui/material';
import styled from '@emotion/styled';

export const ErrorWrapper = styled.div`
  background: ${p => p.theme.palette.error.light};
  padding: ${p => p.theme.spacing(1)};
`;

export const SuccessWrapper = styled.div`
  background: ${p => p.theme.palette.success.light};
  padding: ${p => p.theme.spacing(1)};
`;

export const GridItemContent = styled(Grid)`
  flex-grow: 1;
`;

export const Link = styled(MuiLink)`
  :focus {
    outline: none;
  }
`;
