import { combineReducers } from '@reduxjs/toolkit';
import list, { ClientOrgUserListState } from './table/store/slice';
import actions, { ClientOrgUserActionsState } from './actions/store/slice';

export interface ClientOrgUserState {
  list: ClientOrgUserListState;
  actions: ClientOrgUserActionsState;
}

export default combineReducers<ClientOrgUserState>({
  list,
  actions,
});
