import { FCC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../data/store/store';
import ContentLoader from '../../components/common/loader';
import { Wrapper } from './controls';

interface TableLoaderCommonLayoutProps {
  readonly selector: (state: RootState) => boolean;
}

const TableLoaderCommonLayout: FCC<TableLoaderCommonLayoutProps> = props => {
  const { selector, children } = props;

  const isLoading = useSelector(selector);

  if (isLoading) {
    return (
      <Wrapper>
        <ContentLoader />
      </Wrapper>
    );
  }

  return <>{children}</>;
};

export default TableLoaderCommonLayout;
