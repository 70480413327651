import BookingOrderTableCell, { BookingOrderTableCellCommonProps } from './index';

const BookingOrderTableCellCancelReason = (props: BookingOrderTableCellCommonProps) => {
  const {
    bookingOrder: { cancelReason, customerCancelReason },
  } = props;

  return (
    <BookingOrderTableCell
      {...props}
      value={cancelReason?.name || customerCancelReason?.name}
    />
  );
};

export default BookingOrderTableCellCancelReason;
