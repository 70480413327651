import { ECmsContainerStatus } from '../../../../../domain/model/enums';
import { DataTableColumns } from '../../../../components/common/table';
import {
  CmsContainerActions,
  CmsContainerActionTableType,
  ECmsContainerActionType,
  GetCmsContainerActionsByPermissions,
} from '../types';
import { filterCmsContainerTableActions, getCmsContainerActionsConfigByPermissions } from '../utils/actions';

export type CmsContainerActionTableCommonType = Exclude<
  CmsContainerActionTableType,
  ECmsContainerActionType.ChangeSortIndex
>;

export interface CmsContainerTableActionProcess {
  readonly isFetching: boolean;
  readonly type: ECmsContainerActionType;
}

export enum ECmsContainerTableTab {
  Active = 'active',
  Upcoming = 'upcoming',
  Paused = 'paused',
  Draft = 'draft',
  Archived = 'archived',
}

export type CmsContainerTableTabsCounter = {
  [name in ECmsContainerTableTab]?: number;
};

export interface CmsContainerTableTab {
  readonly id: ECmsContainerTableTab;
  readonly name: string;
}

export enum ECmsContainerTableColumn {
  UpdatedAt = 'updatedAt',
  Preview = 'preview',
  SortIndex = 'sortIndex',
  Type = 'type',
  Name = 'name',
  Title = 'title',
  StartDate = 'startDate',
  EndDate = 'endDate',
  OfferType = 'filterSettings.offerType',
  LinkTo = 'linkTo',
  Target = 'targetText',
  StatusComment = 'statusComment',
}

export const cmsContainerTableColumns: DataTableColumns<ECmsContainerTableColumn> = {
  [ECmsContainerTableColumn.UpdatedAt]: {
    title: 'Время',
    width: '7rem',
  },
  [ECmsContainerTableColumn.Preview]: {
    title: 'Предпросмотр',
    width: '14rem',
  },
  [ECmsContainerTableColumn.SortIndex]: {
    title: 'Место',
    width: '4rem',
  },
  [ECmsContainerTableColumn.Type]: {
    title: 'Блок',
    width: '8rem',
    wrap: true,
  },
  [ECmsContainerTableColumn.Name]: {
    title: 'Название блока',
    width: '13rem',
    wrap: true,
  },
  [ECmsContainerTableColumn.Title]: {
    title: 'Заголовок',
    width: '13rem',
    wrap: true,
  },
  [ECmsContainerTableColumn.StartDate]: {
    width: '4.5rem',
    title: 'Начало',
  },
  [ECmsContainerTableColumn.EndDate]: {
    width: '4.5rem',
    title: 'Окончание',
  },
  [ECmsContainerTableColumn.OfferType]: {
    title: 'Вид предложения',
    width: '8rem',
    wrap: true,
  },
  [ECmsContainerTableColumn.LinkTo]: {
    title: 'Переход на',
    width: '12rem',
    wrap: true,
  },
  [ECmsContainerTableColumn.Target]: {
    title: 'Доступность',
    width: '15rem',
    wrap: true,
  },
  [ECmsContainerTableColumn.StatusComment]: {
    title: 'Причина остановки',
    width: '10rem',
    wrap: true,
  },
};

export const getCmsContainerTableColumns = (tab: ECmsContainerTableTab): DataTableColumns<ECmsContainerTableColumn> => {
  const defaultColumns = getCmsContainerTableDefaultColumns(tab);

  const allColumns: ECmsContainerTableColumn[] = [];
  switch (tab) {
    case ECmsContainerTableTab.Active:
      allColumns.push(
        ECmsContainerTableColumn.UpdatedAt,
        ECmsContainerTableColumn.Preview,
        ECmsContainerTableColumn.SortIndex,
        ECmsContainerTableColumn.Type,
        ECmsContainerTableColumn.Name,
        ECmsContainerTableColumn.Title,
        ECmsContainerTableColumn.OfferType,
        ECmsContainerTableColumn.LinkTo,
        ECmsContainerTableColumn.StartDate,
        ECmsContainerTableColumn.EndDate,
        ECmsContainerTableColumn.Target
      );
      break;
    case ECmsContainerTableTab.Paused:
      allColumns.push(
        ECmsContainerTableColumn.UpdatedAt,
        ECmsContainerTableColumn.Preview,
        ECmsContainerTableColumn.Type,
        ECmsContainerTableColumn.Name,
        ECmsContainerTableColumn.Title,
        ECmsContainerTableColumn.OfferType,
        ECmsContainerTableColumn.LinkTo,
        ECmsContainerTableColumn.StartDate,
        ECmsContainerTableColumn.EndDate,
        ECmsContainerTableColumn.StatusComment,
        ECmsContainerTableColumn.Target
      );
      break;
    default:
      allColumns.push(
        ECmsContainerTableColumn.UpdatedAt,
        ECmsContainerTableColumn.Preview,
        ECmsContainerTableColumn.Type,
        ECmsContainerTableColumn.Name,
        ECmsContainerTableColumn.Title,
        ECmsContainerTableColumn.OfferType,
        ECmsContainerTableColumn.LinkTo,
        ECmsContainerTableColumn.StartDate,
        ECmsContainerTableColumn.EndDate,
        ECmsContainerTableColumn.Target
      );
  }

  return allColumns.reduce<DataTableColumns<ECmsContainerTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...cmsContainerTableColumns[column],
        hidden: !defaultColumns.includes(column),
      },
    };
  }, {});
};

export const getCmsContainerTableStatusesByTab = (t: ECmsContainerTableTab): ECmsContainerStatus[] => {
  switch (t) {
    case ECmsContainerTableTab.Active:
      return [ECmsContainerStatus.Active];
    case ECmsContainerTableTab.Upcoming:
      return [ECmsContainerStatus.Upcoming];
    case ECmsContainerTableTab.Paused:
      return [ECmsContainerStatus.Paused];
    case ECmsContainerTableTab.Draft:
      return [ECmsContainerStatus.Draft];
    case ECmsContainerTableTab.Archived:
      return [ECmsContainerStatus.Archived];
  }
};

export const getCmsContainerTableTabActionsConfig = (
  tab: ECmsContainerTableTab,
  actions: CmsContainerActions<CmsContainerActionTableType>
) => {
  return actions;
};

const cmsContainerTableDefaultColumns = [
  ECmsContainerTableColumn.UpdatedAt,
  ECmsContainerTableColumn.Preview,
  ECmsContainerTableColumn.Title,
  ECmsContainerTableColumn.SortIndex,
  ECmsContainerTableColumn.Type,
  ECmsContainerTableColumn.Name,
  ECmsContainerTableColumn.OfferType,
  ECmsContainerTableColumn.LinkTo,
  ECmsContainerTableColumn.StartDate,
  ECmsContainerTableColumn.EndDate,
  ECmsContainerTableColumn.Target,
];

const getCmsContainerTableDefaultColumns = (tab: ECmsContainerTableTab): ECmsContainerTableColumn[] => {
  return cmsContainerTableDefaultColumns;
};

export const getCmsContainerTableTabActions = (
  permissions: GetCmsContainerActionsByPermissions,
  tab: ECmsContainerTableTab
): CmsContainerActions<CmsContainerActionTableType> => {
  const allowedActions = getCmsContainerActionsConfigByPermissions(permissions);
  const actions = filterCmsContainerTableActions(allowedActions);
  return getCmsContainerTableTabActionsConfig(tab, actions);
};

interface GetCmsContainerTableTabsProps {
  readonly canCreate?: boolean;
}

const cmsContainerTableTabs: CmsContainerTableTab[] = [
  { id: ECmsContainerTableTab.Active, name: 'Опубликованные' },
  { id: ECmsContainerTableTab.Upcoming, name: 'Предстоящие' },
  { id: ECmsContainerTableTab.Paused, name: 'Приостановленные' },
  { id: ECmsContainerTableTab.Draft, name: 'Черновики' },
  { id: ECmsContainerTableTab.Archived, name: 'Архив' },
];

export const getCmsContainerTableTabs = ({ canCreate }: GetCmsContainerTableTabsProps): CmsContainerTableTab[] => {
  const tabs = [...cmsContainerTableTabs];
  if (!canCreate) {
    tabs.splice(
      tabs.findIndex(t => t.id === ECmsContainerTableTab.Draft),
      1
    );
  }
  return tabs;
};
