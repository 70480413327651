import axios, { AxiosResponse } from 'axios';
import { EProductDeskActionDiscriminator } from '../../domain/model/enums';
import { PartnerLink } from '../../domain/model/partner';
import { ProductBrand } from '../../domain/model/product';
import { DictionaryLink, ProductDesk } from '../../domain/model/productOffer';
import { Nullable } from '../../domain/model/types';
import { createCancelToken } from './index';
import { ApiCancellable, ApiQueryDsl, ApiRequestPageable } from './types';
import { appendQueryDslParams, getComServicesEndpoint } from './utils';

export type DefaultApiProps = ApiCancellable &
  ApiRequestPageable & {
    querydsl?: Nullable<ApiQueryDsl>;
  };

type BrandApi = {
  readonly all: (props: DefaultApiProps) => Promise<AxiosResponse<ProductBrand[]>>;
};

type AloneIDProps = ApiCancellable & {
  id: string;
};

type ProductDeskRequest = ApiCancellable & {
  readonly partner: PartnerLink;
  readonly code: string;
  readonly category: DictionaryLink;
};

type ProductDeskPauseProps = {
  discriminator: EProductDeskActionDiscriminator.ProductPauseCommand;
  readonly comment: Nullable<string>;
};

export type ProductOfferAction =
  | {
      discriminator: EProductDeskActionDiscriminator;
    }
  | ProductDeskPauseProps;

type ProductDeskActionProps = AloneIDProps & ProductOfferAction;

export type ProductDeskAllProps = DefaultApiProps & {
  category?: string;
};

type ProductDeskApi = {
  readonly all: (props: ProductDeskAllProps) => Promise<AxiosResponse<ProductDesk[]>>;
  readonly one: (props: AloneIDProps) => Promise<AxiosResponse<ProductDesk>>;
  readonly create: (props: ProductDeskRequest) => Promise<AxiosResponse<ProductDesk>>;
  readonly actions: (props: ProductDeskActionProps) => Promise<AxiosResponse<ProductDesk>>;
};

type ProductApi = {
  brand: BrandApi;
  productDesk: ProductDeskApi;
};

function getDefaultParams<T extends DefaultApiProps>(props: T) {
  const { page, pageSize, sort, querydsl } = props;

  const params = new URLSearchParams();

  if (sort) {
    if (typeof sort === 'string') {
      params.append('sort', sort);
    } else {
      sort.forEach(item => params.append('sort', item));
    }
  }

  if (querydsl) appendQueryDslParams(params, querydsl);

  params.append('page', (page - 1).toString(10));
  params.append('size', pageSize.toString(10));

  return params;
}

/**
 * АПИ по работе со справочниками для товаров
 */

const product: ProductApi = {
  brand: {
    all: props => {
      const { signal } = props;

      const params = getDefaultParams(props);

      return axios.get(`${getComServicesEndpoint()}/brands`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
  },
  productDesk: {
    one: props => {
      const { signal, id } = props;

      return axios.get(`${getComServicesEndpoint()}/product-desks/${id}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    all: props => {
      const { signal, category } = props;

      const params = getDefaultParams(props);
      if (category) {
        params.append('category', category);
      }

      return axios.get(`${getComServicesEndpoint()}/product-desks`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    create: props => {
      const { signal, ...payload } = props;

      return axios.post(`${getComServicesEndpoint()}/product-desks`, payload, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    actions: props => {
      const { id, signal, ...data } = props;

      return axios.post(`${getComServicesEndpoint()}/product-desks/${id}`, data, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
  },
};

export default product;
