import * as schemas from './scheme';
import { CorpActivationLifeCycleRulesConfiguration } from './types';

/**
 * конфигурация правил админа активаций корпоративных предложений
 */
export const corpActivationAdminConfiguration: CorpActivationLifeCycleRulesConfiguration = {
  statusScheme: schemas.corpActivationAdminStatusScheme.statusScheme,
  actionsScheme: schemas.corpActivationAdminStatusScheme.actionsScheme,
  actionRules: [],
};
/**
 * конфигурация правил партнера активаций корпоративных предложений
 */
export const corpActivationPartnerConfiguration: CorpActivationLifeCycleRulesConfiguration = {
  statusScheme: schemas.corpActivationPartnerStatusScheme.statusScheme,
  actionsScheme: schemas.corpActivationPartnerStatusScheme.actionsScheme,
  actionRules: [],
};
