import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../data/store/store';
import { Nullable } from '../../../../../domain/model/types';
import { ECmsTermTag } from '../../container/types';
import { CmsTermValueSuggestion } from '../../types';
import { cmsTermSearchValuesSuggestionFetch } from './store/slice';

interface CmsTermSuggestionResultParams {
  readonly onSearch: (searchedTitle: Nullable<string>) => void;
  readonly isFetching: boolean;
  readonly values: Nullable<CmsTermValueSuggestion[]>;
}

interface CmsTermSearchContainerProps {
  readonly initialValue: Nullable<string>;
  readonly tag: ECmsTermTag;
  readonly children: (params: CmsTermSuggestionResultParams) => React.ReactNode;
}

const CmsTermSearchContainer = (props: CmsTermSearchContainerProps) => {
  const { initialValue, children, tag } = props;

  const dispatch = useAppDispatch();

  const [values, setValues] = useState<Nullable<CmsTermValueSuggestion[]>>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<Nullable<string>>(initialValue);

  const onSearch = useCallback(
    (searchedValue: Nullable<string>) => {
      setSearchValue(searchedValue);
    },
    [setSearchValue]
  );

  useEffect(() => {
    setIsFetching(true);

    const promise = dispatch(cmsTermSearchValuesSuggestionFetch({ value: searchValue, tag }));

    promise
      .unwrap()
      .then(result => {
        setIsFetching(false);
        setValues(result);
      })
      .catch((e: any) => {
        if (e.message !== 'Aborted') {
          setIsFetching(false);
        }
      });

    return () => {
      promise?.abort();
    };
  }, [dispatch, searchValue]);

  return (
    <>
      {children({
        onSearch,
        isFetching,
        values,
      })}
    </>
  );
};

export default CmsTermSearchContainer;
