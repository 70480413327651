import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ForbiddenComponent from '../../components/common/forbidden';
import ActivityTypesEditContainer from '../../features/dictionary/activityType/edit/container';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const ConfigurationSportActivityTypeScreen = () => {
  const dispatch = useDispatch();
  const { activityTypes } = useCurrentUser().accessMatrix;

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  if (!activityTypes.view) {
    return <ForbiddenComponent />;
  }

  return (
    <ActivityTypesEditContainer
      access={{
        canCreate: activityTypes.create,
        canEdit: activityTypes.edit,
        canDelete: activityTypes.delete,
        canSort: activityTypes.sort,
        canChangeVisible: activityTypes.changeVisible,
      }}
    />
  );
};

export default ConfigurationSportActivityTypeScreen;
