import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AdminMpTableCellCommonProps } from '../../components/tableCell';
import { adminMpsAdminByIndexSelector } from '../store/selectors';

export type AdminMpTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<AdminMpTableCellCommonProps>;
};

const AdminMpTableCellAdapter = (props: AdminMpTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const admin = useSelector(adminMpsAdminByIndexSelector(index));

  return useMemo(() => (admin ? <Component admin={admin} /> : null), [Component, admin]);
};

export default AdminMpTableCellAdapter;
