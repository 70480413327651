import { UUID } from '@/domain/model/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ECorpActivationTableTab } from '../../types';
import { corpActivationsGuidSelector } from '../store/selectors';
import { corpActivationsSetTab, corpActivationsStartSession } from '../store/slice';

type useCorpActivationSessionProps = {
  readonly guid: UUID;
  readonly tab: ECorpActivationTableTab;
};

export const useCorpActivationTableSession = (props: useCorpActivationSessionProps) => {
  const { guid, tab } = props;

  const currentGuid = useSelector(corpActivationsGuidSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(corpActivationsStartSession({ guid }));
  }, [dispatch, guid]);

  // начальная загрузка, изменение вкладки
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(corpActivationsSetTab({ tab }));
    }
  }, [dispatch, guid, currentGuid, tab]);
};
