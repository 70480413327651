import { CaseReducer, createSelector, createSlice, PayloadAction, Selector, SliceCaseReducers } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../../../../../../data/store/store';
import { UUID } from '../../../../../../domain/model/types';
import { ECustomerManagementMenuType } from '../enums';

interface MenuState {
  readonly guid: UUID;
  readonly opened: {
    readonly [guid: string]: boolean;
  };
}

export interface CustomerManagementMenusState {
  readonly menus: {
    readonly [ECustomerManagementMenuType.Common]: MenuState;
    readonly [ECustomerManagementMenuType.ByPartner]: MenuState;
  };
}

type Reducer<T = undefined> = CaseReducer<CustomerManagementMenusState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CustomerManagementMenusState> {
  customerManagementMenuAddToOpened: Reducer<{ menu: ECustomerManagementMenuType; itemId: UUID }>;
}

const slice = createSlice<CustomerManagementMenusState, Reducers, 'customer/management/menus'>({
  name: 'customer/management/menus',
  initialState: {
    menus: {
      [ECustomerManagementMenuType.Common]: { guid: uuidv4(), opened: {} },
      [ECustomerManagementMenuType.ByPartner]: { guid: uuidv4(), opened: {} },
    },
  },
  reducers: {
    customerManagementMenuAddToOpened(state, { payload }) {
      const { menu, itemId } = payload;
      state.menus[menu].opened[itemId] = !state.menus[menu].opened[itemId];
    },
  },
});

export const { customerManagementMenuAddToOpened } = slice.actions;

export default slice.reducer;

const menusSelector: Selector<RootState, CustomerManagementMenusState> = state => state.customer.management.menus;
const menuSelector: Selector<RootState, ECustomerManagementMenuType, [ECustomerManagementMenuType]> = (state, menu) =>
  menu;

export const createCustomerManagementMenuOpenedItemsSelector = createSelector(
  menusSelector,
  menuSelector,
  (customerManagementMenus, menu) => customerManagementMenus.menus[menu].opened
);

export const createCustomerManagementGuidSelector = createSelector(
  menusSelector,
  menuSelector,
  (customerManagementMenus, menu) => customerManagementMenus.menus[menu].guid
);
