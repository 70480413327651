import axios from 'axios';
import { useEffect, useState } from 'react';
import Api from '../../../../../data/api';
import { Nullable } from '../../../../../domain/model/types';
import { AnyFileLink } from '../link';
import { FileInfoAdapterType, FileInfoState } from './types';
import { appendFileInfoExtension } from './utils';

/**
 * адаптер для работы с файлом по любой ссылке
 * применяется в рамках FileInfo
 */
export const FileInfoRemoteAnyAdapter: FileInfoAdapterType = props => {
  const { id, originalName, loadingProgress, children, linkProps, fileExtension } = props;

  const [state, setState] = useState<FileInfoState>({
    isLoading: false,
    name: originalName ?? null,
    size: null,
    error: null,
  });

  useEffect(() => {
    setState({
      ...state,
      isLoading: !!loadingProgress,
    });
  }, [loadingProgress]);

  useEffect(() => {
    if (!id) return;

    const cancelCallback = axios.CancelToken.source();
    const config = {
      cancelToken: cancelCallback.token,
    };

    setState(currentState => ({
      ...currentState,
      isLoading: true,
    }));

    Api.files
      .getInfoByUrl(id, config)
      .then(metadata => {
        let name: Nullable<string> = '';

        if (fileExtension) {
          if (originalName) {
            name = appendFileInfoExtension(originalName, metadata.type);
          } else if (metadata.name) {
            name = appendFileInfoExtension(metadata.name, metadata.type);
          }
        } else {
          name = originalName || metadata.name;
        }

        const size: Nullable<number> = metadata.size;

        setState({
          isLoading: false,
          name,
          size,
          error: null,
        });
      })
      .catch(error => {
        if (axios.isCancel(error)) return;

        console.error('Ошибка при получении информации о файле', error);
        setState({
          isLoading: false,
          name: null,
          size: null,
          error: 'данные файла не получены',
        });
      });

    return () => {
      cancelCallback.cancel();
    };
  }, [id, originalName, fileExtension]);

  return (
    <AnyFileLink
      href={id}
      target='_blank'
      underline='none'
      {...linkProps}
    >
      {children(state)}
    </AnyFileLink>
  );
};
