import TabsPanelCounter from '../../../components/common/tabs/counter/counter';
import TabsPanelLabel from '../../../components/common/tabs/label/label';
import { BannerTableTabsCounter, EBannerTableTab, getBannerTableTabName } from './utils';

interface BannerTableTabProps {
  readonly tab: EBannerTableTab;
  readonly counts: BannerTableTabsCounter;
}

const BannerTableTab = ({ tab, counts }: BannerTableTabProps) => {
  const count = counts[tab];

  return (
    <TabsPanelLabel title={getBannerTableTabName(tab)}>
      <TabsPanelCounter count={count} />
    </TabsPanelLabel>
  );
};

export default BannerTableTab;
