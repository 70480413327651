import { CorpOffer, EOfferType, Nullable, TradeOffer, UUID } from '@/domain';
import { ButtonLink } from '@components/common/buttons/link';
import {
  getOfferCollectionColumns,
  offerCollectionCorpColumns,
  offerCollectionTradeColumns,
} from '@features/general/offer/components/collection/utils';
import { EOfferTableColumn } from '@features/general/offer/types';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { Grid, Typography } from '@mui/material';
import SectionTitle from '../../../../../components/common/sectionTitle';
import Splitter from '../../../../../components/common/splitter';
import OfferCollection from '../../../../general/offer/components/collection';

import { CollectionActionsContainer } from './controls';

const cmsOfferCollectionColumns = [EOfferTableColumn.Code, EOfferTableColumn.SortIndex, EOfferTableColumn.Name];

interface CmsOfferSingleCollectionComponentTradeProps {
  readonly offerType: EOfferType.Trade;
  readonly offer: Nullable<TradeOffer>;
}

interface CmsOfferSingleCollectionComponentCorpProps {
  readonly offerType: EOfferType.Corp;
  readonly offer: Nullable<CorpOffer>;
}

type CmsOfferSingleCollectionComponentProps = {
  readonly onShowOfferSearch?: () => void;
  readonly onRemoveOffer?: (id: UUID) => void;
} & (CmsOfferSingleCollectionComponentTradeProps | CmsOfferSingleCollectionComponentCorpProps);

const CmsOfferSingleCollectionComponent = (props: CmsOfferSingleCollectionComponentProps) => {
  const { offer, offerType, onShowOfferSearch, onRemoveOffer } = props;

  let title;
  if (offerType === EOfferType.Corp) {
    title = 'Карточка корпоративного предложения';
  } else {
    title = 'Карточка торгового предложения';
  }

  return (
    <Grid
      item
      xs={12}
    >
      <Splitter
        variant='horizontal'
        size={1}
      />
      <SectionTitle>{title}</SectionTitle>
      {offer && (
        <>
          {props.offerType === EOfferType.Corp && (
            <OfferCollection<CorpOffer>
              offers={props.offer ? [props.offer] : []}
              columns={getOfferCollectionColumns(cmsOfferCollectionColumns, offerCollectionCorpColumns)}
              onRemove={onRemoveOffer}
            />
          )}
          {props.offerType === EOfferType.Trade && (
            <OfferCollection<TradeOffer>
              offers={props.offer ? [props.offer] : []}
              columns={getOfferCollectionColumns(cmsOfferCollectionColumns, offerCollectionTradeColumns)}
              onRemove={onRemoveOffer}
            />
          )}
        </>
      )}
      {onShowOfferSearch && (
        <CollectionActionsContainer>
          <ButtonLink onClick={() => onShowOfferSearch()}>
            <AddBoxOutlinedIcon />
            <Typography>Выбрать предложение</Typography>
          </ButtonLink>
        </CollectionActionsContainer>
      )}
    </Grid>
  );
};

export default CmsOfferSingleCollectionComponent;
