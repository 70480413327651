import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { SportEnumOption, UploadItem } from '../../../../../domain/model';
import { ESportEventType } from '../../../../../domain/model/enums';
import { ActivityType, NewSportEventGeneralAttributes } from '../../../../../domain/model/event';
import { Nullable, UUID } from '../../../../../domain/model/types';

export interface EventCreateSummaryState {
  generalAttributes: NewSportEventGeneralAttributes;
  type: SportEnumOption<ESportEventType>;
  guid: Nullable<UUID>;
  uploadingAttachments: {
    files: UploadItem[];
  };
}

interface Reducers extends SliceCaseReducers<EventCreateSummaryState> {
  eventCreateSetAttribute: CaseReducer<
    EventCreateSummaryState,
    PayloadAction<{ name: keyof NewSportEventGeneralAttributes; value: any }>
  >;
  eventCreateSetAttributes: CaseReducer<EventCreateSummaryState, PayloadAction<NewSportEventGeneralAttributes>>;
  eventCreateStartSession: CaseReducer<EventCreateSummaryState, PayloadAction<{ guid: UUID }>>;
  eventCreateSetActivityTypeAttribute: CaseReducer<
    EventCreateSummaryState,
    PayloadAction<{ value: Nullable<ActivityType[]> }>
  >;
}

const defaultEventType: SportEnumOption<ESportEventType> = { code: ESportEventType.Tournament, name: null };

const attributesInitialState: NewSportEventGeneralAttributes = {
  name: '',
  description: '',
  createdDate: null,
  road: null,
  scheduledDate: null,
  endDate: null,
  playground: null,
  previewImage: null,
  appearCheckingHours: null,
  status: null,
  activityTypes: null,
  userType: null,
};

const slice = createSlice<EventCreateSummaryState, Reducers, 'event/createSummary'>({
  name: 'event/createSummary',
  initialState: {
    type: defaultEventType,
    guid: null,
    generalAttributes: attributesInitialState,
    uploadingAttachments: {
      files: [],
    },
  },
  reducers: {
    eventCreateSetActivityTypeAttribute: (state, { payload }) => {
      const { value } = payload;
      const { playground } = state.generalAttributes;
      state.generalAttributes.activityTypes = value;

      if (playground && value) {
        const isTypeExists = value.every(({ id }) => playground.activityTypes?.some(el => el.id === id));

        if (!isTypeExists) state.generalAttributes.playground = null;
      }
    },
    eventCreateSetAttribute: (state, { payload }) => {
      const { name, value } = payload;
      (state.generalAttributes[name] as keyof NewSportEventGeneralAttributes) = value;
    },
    eventCreateSetAttributes: (state, { payload }) => {
      state.generalAttributes = payload;
    },
    eventCreateStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (state.guid !== guid) {
        state.generalAttributes = attributesInitialState;
      }

      state.guid = guid;
    },
  },
});

export const {
  eventCreateSetAttribute,
  eventCreateSetAttributes,
  eventCreateStartSession,
  eventCreateSetActivityTypeAttribute,
} = slice.actions;

export default slice.reducer;
