// получение действий с оффером с учетом состояния оффера в таблице
import { EOfferTableTab } from '../../general/offer/types';
import { BookingActions, BookingActionTableType, EBookingActionType } from '../types';
import {
  filterBookingTableActions,
  GetBookingActionsByPermissionsProps,
  getBookingActionsConfigByPermissions,
} from './actions';

type BookingActionTypes = BookingActionTableType;

interface GetBookingTableTabActionsProps {
  readonly tab: EOfferTableTab;
  readonly actions: BookingActions<BookingActionTableType>;
}

// получение возможных действий с объектом для вкладки таблицы
export const getBookingTableTabActionsConfig = (
  props: GetBookingTableTabActionsProps
): BookingActions<BookingActionTableType> => {
  const { actions, tab } = props;

  switch (tab) {
    case EOfferTableTab.Draft:
      return actions.filter(item =>
        [EBookingActionType.Duplicate, EBookingActionType.Delete, EBookingActionType.Archive].includes(item.type)
      );
    case EOfferTableTab.Moderation:
      return actions.filter(item =>
        [
          EBookingActionType.Edit,
          EBookingActionType.Duplicate,
          EBookingActionType.UnPublish,
          EBookingActionType.Approve,
          EBookingActionType.Reject,
          EBookingActionType.InWork,
          EBookingActionType.ChangeModerator,
          EBookingActionType.Delete,
        ].includes(item.type)
      );

    case EOfferTableTab.Active:
      return actions.filter(item =>
        [
          EBookingActionType.Pause,
          EBookingActionType.Edit,
          EBookingActionType.Duplicate,
          EBookingActionType.Archive,
          EBookingActionType.ManageServices,
          EBookingActionType.Delete,
        ].includes(item.type)
      );

    case EOfferTableTab.Paused:
      return actions.filter(item =>
        [
          EBookingActionType.Resume,
          EBookingActionType.Edit,
          EBookingActionType.Duplicate,
          EBookingActionType.Archive,
          EBookingActionType.ManageServices,
          EBookingActionType.Delete,
        ].includes(item.type)
      );

    case EOfferTableTab.Rejected:
      return actions.filter(item =>
        [
          EBookingActionType.ReturnToVerification,
          EBookingActionType.Publish,
          EBookingActionType.Duplicate,
          EBookingActionType.Archive,
          EBookingActionType.Delete,
        ].includes(item.type)
      );

    case EOfferTableTab.Archived:
      return actions.filter(item =>
        [EBookingActionType.Duplicate, EBookingActionType.Retrieve, EBookingActionType.Delete].includes(item.type)
      );

    case EOfferTableTab.Upcoming:
      return [];
  }
};

export const getBookingTableTabActions = (
  permissions: GetBookingActionsByPermissionsProps,
  tab: EOfferTableTab
): BookingActions<BookingActionTypes> => {
  const allowedActions = getBookingActionsConfigByPermissions(permissions);
  const actions = filterBookingTableActions(allowedActions);
  return getBookingTableTabActionsConfig({ tab, actions });
};
