import { Grid, Hidden } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Nullable } from '../../../../../domain/model/types';
import { EditableCommonUserData } from '../../../../../domain/model/user';
import { MPEmailInput, MPFormInput, MPPhoneInput } from '../../../../theme/ui-kit/input';
import { OnChangeObjectAttribute } from '../../../../types';
import { ValidationResult, ValidationRules } from '../../../../utils/validation';
import { userPhonePrefix } from '../../utils';

type DataType = EditableCommonUserData;

interface UserCommonEditProfileProps<T extends DataType> {
  readonly user: T;
  readonly titleHide?: boolean;
  readonly emailHide?: boolean;
  readonly emailReadonly?: boolean;
  readonly validation: Nullable<ValidationResult<T>>;
  readonly validationRules?: ValidationRules<T>;
  readonly onChangeAttribute: OnChangeObjectAttribute<T>;
}

const UserCommonEditProfile = <T extends DataType>(props: UserCommonEditProfileProps<T>) => {
  const { user, validation, validationRules, titleHide, emailHide, emailReadonly, onChangeAttribute } = props;

  return (
    <Grid
      container
      spacing={2}
    >
      {!titleHide && (
        <Grid
          item
          xs={12}
        >
          <Typography variant={'h3'}>Профиль</Typography>
        </Grid>
      )}

      <Grid
        item
        xs={12}
      >
        <MPFormInput
          label='Фамилия'
          value={user.lastName}
          required={!!validationRules?.['lastName']?.required}
          error={!!validation?.lastName?.hasError}
          helperText={validation?.lastName?.message}
          onChange={(event: any) => onChangeAttribute('lastName', event.target.value)}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <MPFormInput
          label='Имя'
          value={user.firstName}
          required={!!validationRules?.['firstName']?.required}
          error={!!validation?.firstName?.hasError}
          helperText={validation?.firstName?.message}
          onChange={(event: any) => onChangeAttribute('firstName', event.target.value)}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <MPFormInput
          label='Отчество'
          value={user.middleName}
          required={!!validationRules?.['middleName']?.required}
          error={!!validation?.middleName?.hasError}
          helperText={validation?.middleName?.message}
          onChange={(event: any) => onChangeAttribute('middleName', event.target.value)}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <MPPhoneInput
          error={!!validation?.phone?.hasError}
          helperText={validation?.phone?.message}
          label='Телефон'
          prefix={userPhonePrefix}
          value={user.phone}
          required={!!validationRules?.['phone']?.required}
          onChange={(event: any) => onChangeAttribute('phone', event.target.value)}
        />
      </Grid>
      <Hidden xsUp={emailHide}>
        <Grid
          item
          xs={12}
        >
          <MPEmailInput
            label='Электронная почта'
            value={user.email}
            required={!!validationRules?.['email']?.required}
            disabled={emailReadonly}
            error={!!validation?.email?.hasError}
            helperText={validation?.email?.message}
            onChange={value => onChangeAttribute('email', value)}
          />
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default UserCommonEditProfile;
