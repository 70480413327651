import { Grid } from '@mui/material';
import { FileDescription } from '../../../../../../domain/model';
import PartnerDetailsInfoDocument from './document';

interface PartnerDetailsInfoIndividualDocumentsProps {
  readonly ogrnipCert: FileDescription;
  readonly innCert: FileDescription;
  readonly passportCopy: FileDescription;
  readonly usnDoc: FileDescription;
}

const PartnerDetailsInfoIndividualDocuments = (props: PartnerDetailsInfoIndividualDocumentsProps) => {
  const { ogrnipCert, innCert, passportCopy, usnDoc } = props;

  return (
    <Grid
      container
      spacing={3}
      direction='column'
    >
      <Grid item>
        <PartnerDetailsInfoDocument
          label='Свидетельство ОГРНИП'
          guid={ogrnipCert?.path}
        />
      </Grid>
      <Grid item>
        <PartnerDetailsInfoDocument
          label='Свидетельство ИНН'
          guid={innCert?.path}
        />
      </Grid>
      <Grid item>
        <PartnerDetailsInfoDocument
          label='Копия паспорта ИП'
          description='Первая страница с фото и страница с регистрацией'
          guid={passportCopy?.path}
        />
      </Grid>
      <Grid item>
        <PartnerDetailsInfoDocument
          label='Основание УСН'
          guid={usnDoc?.path}
        />
      </Grid>
    </Grid>
  );
};

export default PartnerDetailsInfoIndividualDocuments;
