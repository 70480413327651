import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TeamTableCellCommonProps } from '../../components/tableCell';
import { teamsTeamByIndexSelector } from '../store/selectors';

export type TeamsTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<TeamTableCellCommonProps>;
};

const TeamsTableCellAdapter = (props: TeamsTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const team = useSelector(teamsTeamByIndexSelector(index));

  return useMemo(() => (team ? <Component team={team} /> : null), [Component, team]);
};

export default TeamsTableCellAdapter;
