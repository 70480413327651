import mimeTypes from 'mime-types';
import { Nullable } from '../../../../../domain/model/types';

export const appendFileInfoExtension = (fileName: string, contentType: Nullable<string>) => {
  if (fileName && contentType) {
    //определяем расширение файла
    const extension = contentType && mimeTypes.extension(contentType);

    if (extension) {
      //определяем content-type по расширению из fileName
      const contentTypeByLoadedExtension = mimeTypes.contentType(fileName);
      //если есть content-type по fileName
      if (contentTypeByLoadedExtension) {
        const fileNameParts = fileName.split('.');
        //меняем расширение в fileName на фактическое исходя из content-type
        fileNameParts.splice(fileNameParts.length - 1, 1, extension);
        return fileNameParts.join('.');
      } else {
        //если нет content-type по fileName
        //добавляем расширение в fileName из content-type
        return [fileName, extension].join('.');
      }
    }
  }
  return fileName;
};
