import PartnerEmployeeTableCell, { PartnerEmployeeTableCellCommonProps } from './index';

const PartnerEmployeeTableCellEmail = (props: PartnerEmployeeTableCellCommonProps) => {
  const {
    employee: { email: value },
  } = props;

  return (
    <PartnerEmployeeTableCell
      {...props}
      value={value}
    />
  );
};

export default PartnerEmployeeTableCellEmail;