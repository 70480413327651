import { Checkbox as MuiCheckbox, Grid } from '@mui/material';
import styled from '@emotion/styled';

export const Wrapper = styled(Grid)`
  padding: ${p => p.theme.spacing(0.5)} ${p => p.theme.spacing(1.5)} ${p => p.theme.spacing(1.5)}
    ${p => p.theme.spacing(1.5)};
  background: ${p => p.theme.palette.highlight};

  border-radius: ${p => p.theme.shape.borderRadius}px;
`;

export const Checkbox = styled(MuiCheckbox)`
  padding: ${p => p.theme.spacing(0.7)};
`;
