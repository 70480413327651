import React from 'react';
import { RootState } from '../../../../data/store/store';
import { BookingOffer, BookingOfferService, BookingOfferShort } from '../../../../domain/model/booking';
import { Nullable, UUID } from '../../../../domain/model/types';
import { BookingOfferServiceView, BookingOfferView, EBookingActionType } from '../types';
import { BookingOfferActionsState } from './store/slice';

type BookingOfferTypes = BookingOffer | BookingOfferShort;

type NewVersionProps = { id: UUID; offer: BookingOfferView };

export interface BookingOfferActionsSelectors {
  readonly getIsOfferProcessingSelector: (id: Nullable<UUID>) => (store: RootState) => boolean;
  readonly getIsOfferExecutedActionsSelector: (
    id: Nullable<UUID>,
    ...actions: EBookingActionType[]
  ) => (store: RootState) => Nullable<EBookingActionType>;
  readonly isBookingOffersAnyChangedSelector: (store: RootState) => number;
}

export interface BookingOfferActionsContextType {
  readonly onChangeDialogState: (
    name: keyof BookingOfferActionsState['dialogs'],
    data: Nullable<BookingOffer | BookingOfferShort>
  ) => void;
  readonly onSave: (offer: BookingOfferView) => Promise<Nullable<{ offer: BookingOffer; errors: string[] }>>;
  readonly onSaveServices: (
    offerId: UUID,
    services: BookingOfferServiceView[]
  ) => Promise<Nullable<{ services: BookingOfferService[]; errors: string[] }>>;

  readonly onReject: (offer: BookingOfferShort, comment: string) => Promise<Nullable<BookingOffer>>;
  readonly onReturnToVerification: (id: UUID) => Promise<Nullable<BookingOffer>>;
  readonly onDuplicate: (id: UUID) => Promise<Nullable<BookingOffer>>;
  readonly onDelete: (id: UUID) => Promise<Nullable<boolean>>;
  readonly onArchive: (id: UUID) => Promise<Nullable<BookingOffer>>;
  readonly onChangeModerator: (id: UUID, userId: UUID) => Promise<Nullable<BookingOffer>>;
  readonly onPublish: (id: UUID) => Promise<Nullable<BookingOffer>>;
  readonly onUnPublish: (id: UUID) => Promise<Nullable<BookingOffer>>;
  readonly onNewVersion: (props: NewVersionProps) => Promise<Nullable<{ offer: BookingOffer; errors: string[] }>>;

  readonly onTryReject: (offer: BookingOfferTypes) => void;
  readonly onTryApprove: (offer: BookingOfferTypes) => void;
  readonly onTryInWork: (offer: BookingOfferTypes) => void;
  readonly onTryReturnToVerification: (offer: BookingOfferTypes) => void;
  readonly onTryDuplicate: (offer: BookingOfferTypes) => void;
  readonly onTryDelete: (offer: BookingOfferTypes) => void;
  readonly onTryArchive: (offer: BookingOfferTypes) => void;
  readonly onTryUnPublish: (offer: BookingOfferTypes) => void;
  readonly onTryChangeModerator: (offer: BookingOfferTypes) => void;
  readonly onTryPause: (offer: BookingOfferTypes) => void;
  readonly onTryPublish: (offer: BookingOfferTypes) => void;
  readonly onTryRetrieve: (offer: BookingOfferTypes) => void;
  readonly onTryResume: (offer: BookingOfferTypes) => void;
  readonly onTryServiceManage: (offer: BookingOfferTypes) => void;

  readonly utils: {
    readonly selectors: BookingOfferActionsSelectors;
  };
}

const BookingOfferActionsContext = React.createContext<BookingOfferActionsContextType>(
  {} as BookingOfferActionsContextType
);

export default BookingOfferActionsContext;
