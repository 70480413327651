import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { Nullable, UUID } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import { viewShowMainMenu } from '../../features/main/container/store/slice';
import TeamContainer from '../../features/team/details/container';
import { ETeamTab } from '../../features/team/details/utils';
import { ETeamUrlParam } from '../../features/team/entry';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const TeamScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { teams } = useCurrentUser().accessMatrix;

  const guid: Nullable<UUID> = (new URLSearchParams(location.search).get(ETeamUrlParam.Guid) as UUID) ?? uuidv4();
  const tab: ETeamTab = (new URLSearchParams(location.search).get(ETeamUrlParam.Tab) as ETeamTab) ?? ETeamTab.General;
  const { id } = useParams<{ id: UUID }>();

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (!teams?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <TeamContainer
      guid={guid}
      id={id}
      tab={tab}
      canEdit={!!teams.edit}
      canChangeMembers={!!teams.changeMembers}
    />
  );
};

export default TeamScreen;
