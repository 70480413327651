import { Fetchable } from '@/data/store/types';
import { AnyOffer, Nullable } from '@/domain';
import { Dispatch, SetStateAction } from 'react';
import DataTableCellSortIndex from '../../../../../../../components/common/table/cell/sortIndex';

interface OfferTableCellSortIndexProps<T extends AnyOffer> {
  readonly offer: T;
  readonly activeOffer: Nullable<T>;
  readonly editableOffer: Nullable<T>;
  readonly sortIndexInfo: Fetchable & {
    readonly fetch: () => void;
    readonly max: number;
  };
  readonly sortIndex: number;
  readonly isChangeSortIndexFetching?: (offer: T) => boolean;
  readonly onChangeSortIndex: (offer: T, sortIndex: number) => void;
  readonly setEditableOffer: Dispatch<SetStateAction<Nullable<T>>>;
}

const OfferTableCellSortIndex = <T extends AnyOffer>(props: OfferTableCellSortIndexProps<T>) => {
  const {
    offer,
    activeOffer,
    editableOffer,
    sortIndexInfo,
    sortIndex,
    isChangeSortIndexFetching,
    onChangeSortIndex,
    setEditableOffer,
  } = props;
  const { id } = offer;

  const currentEditableOffer = editableOffer?.id === id ? editableOffer : null;
  const isSortIndexFetching = isChangeSortIndexFetching?.(offer) || sortIndexInfo.isFetching;

  return (
    <>
      <DataTableCellSortIndex
        editMode={editableOffer?.id === id && sortIndexInfo.isFetched}
        value={sortIndex}
        max={sortIndexInfo.max}
        label={(min, max) => (sortIndexInfo.isFetched ? `${min}-${max}` : '')}
        hideEditIcon={activeOffer?.id !== id && editableOffer?.id !== id}
        isFetching={(!editableOffer || !!currentEditableOffer) && isSortIndexFetching}
        onChangeEditMode={isEditMode => setEditableOffer(!isEditMode ? null : offer)}
        onChange={newValue => {
          setEditableOffer({ ...offer, sortIndex: newValue });
          onChangeSortIndex(offer, newValue);
        }}
      />
    </>
  );
};

export default OfferTableCellSortIndex;
