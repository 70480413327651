import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { Nullable } from '../../../../domain/model/types';
import ErrorBoundaryContent from './content';

class ErrorBoundaryCommon extends React.Component<any, { error: Nullable<Error> }> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error);
    return {
      error,
    };
  }

  componentDidCatch(error: Error) {
    const errorName = error.name;
    if (errorName === 'ChunkLoadError') {
      window.location.href = '/';
    }
  }

  onLogout = () => {
    this.props.logout({
      redirectUri: window.location.origin,
    });
  };

  render() {
    if (this.state.error) {
      const message = this.state.error.message;
      const stacktrace = this.state.error.stack;
      return (
        <ErrorBoundaryContent
          message={message}
          stacktrace={stacktrace}
          onLogout={this.onLogout}
        />
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundaryCommon);
