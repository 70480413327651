import { UserAccessPartitionBookingOffers } from '@/domain/model/accessMatrix';
import { Nullable, UUID } from '@/domain/model/types';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import TableCommonLayout from '../../../layouts/tableCommon/container';
import TableLoaderCommonLayout from '../../../layouts/tableLoaderCommon/container';
import { EOfferTableTab } from '../../general/offer/types';
import { EBookingOfferTableFilterItem } from '../filterUtils';
import { BookingLifeCycle } from '../lifecycle/types';
import { BookingOfferTableStubAdapterType, EBookingOfferTableColumn } from '../types';
import { getBookingTableTabActions } from '../utils/table';
import { BookingOfferBehaviorAdapter } from './adapters/behavior';
import BookingOffersTableFilterAdapter from './adapters/filters';
import BookingOffersTableHeaderAdapter from './adapters/header';
import { BookingOffersTableAdapter } from './adapters/table';
import { BookingOffersTableTabsAdapter } from './adapters/tabs';
import { BookingOffersTableContext } from './context';
import BookingTableFooterContainer from './footerContainer';
import { useBookingOffersTableHandlers } from './hooks/useBookingOffersTable';
import { bookingOffersIsPreloadingSelector } from './store/selectors';

interface BookingOfferTableContainerProps {
  readonly guid: UUID;
  readonly partnerId: Nullable<UUID>;
  readonly tab: EOfferTableTab;
  readonly stubAdapter: BookingOfferTableStubAdapterType;
  readonly filterAdapter: DataFilterAdapter<EBookingOfferTableColumn, EBookingOfferTableFilterItem>;
  readonly lifecycle: BookingLifeCycle;
  readonly access: UserAccessPartitionBookingOffers;
}

const BookingOfferTableContainer = (props: BookingOfferTableContainerProps) => {
  const { guid, partnerId, tab, lifecycle, stubAdapter: StubAdapter, filterAdapter, access } = props;

  const handlers = useBookingOffersTableHandlers({ tab });

  const tableTabActions = getBookingTableTabActions(access, tab);

  return (
    <BookingOffersTableContext.Provider value={handlers}>
      <BookingOfferBehaviorAdapter
        guid={guid}
        //tab={currentTab}
        tab={tab}
        partnerId={partnerId}
      />
      <TableLoaderCommonLayout selector={bookingOffersIsPreloadingSelector}>
        <StubAdapter>
          {({ canViewTable, isCreateDisabled, createInfo, stub }) => (
            <TableCommonLayout
              canViewTable={canViewTable}
              filter={<BookingOffersTableFilterAdapter filterAdapter={filterAdapter} />}
              header={
                <BookingOffersTableHeaderAdapter
                  tab={tab}
                  partnerId={partnerId}
                  createInfo={createInfo}
                  isCreateDisabled={isCreateDisabled}
                  canCreate={access.create}
                  canDownload={access.download}
                />
              }
              tabs={<BookingOffersTableTabsAdapter tab={tab} />}
              table={
                <BookingOffersTableAdapter
                  lifecycle={lifecycle}
                  filterAdapter={filterAdapter}
                  tabActions={tableTabActions}
                />
              }
              footer={BookingTableFooterContainer}
              stub={stub}
            ></TableCommonLayout>
          )}
        </StubAdapter>
      </TableLoaderCommonLayout>
    </BookingOffersTableContext.Provider>
  );
};

export default BookingOfferTableContainer;
