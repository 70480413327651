import React, { useState } from 'react';
import { Nullable } from '../../../../domain/model/types';
import { Breadcrumb, Breadcrumbs, BreadcrumbsContextType } from './types';

export const BreadcrumbsContext = React.createContext<Nullable<BreadcrumbsContextType>>(null);

interface BreadcrumbsProviderProps {
  readonly children: any;
}

const BreadcrumbsProvider = ({ children }: BreadcrumbsProviderProps) => {
  const [headBreadcrumbs, setHeadBreadcrumbs] = useState<Breadcrumbs>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumbs>([]);

  const putHead = (newHeadBreadcrumbs: Breadcrumb[]) => {
    setHeadBreadcrumbs(newHeadBreadcrumbs);
  };

  const put = (newBreadcrumbs: Breadcrumb[]) => {
    setBreadcrumbs(newBreadcrumbs);
  };

  const state = {
    headBreadcrumbs,
    breadcrumbs,
    putHead,
    put,
  };

  return <BreadcrumbsContext.Provider value={state}>{children}</BreadcrumbsContext.Provider>;
};

export default BreadcrumbsProvider;
