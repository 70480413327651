import { DataTreeItem } from '../../../../../domain/model';
import { ActivityType } from '../../../../../domain/model/event';

export const activityTypeImageMaxSize = 5 * 1024 * 1024;

export const buildActivityTypeTree = (activityTypes: ActivityType[]): DataTreeItem<ActivityType>[] => {
  return activityTypes.filter(item => !item.parent?.id).map(activityType => buildTreeItem(activityType, activityTypes));
};

const buildTreeItem = (activityType: ActivityType, activityTypes: ActivityType[]): DataTreeItem<ActivityType> => {
  return {
    id: activityType.id,
    label: activityType.name,
    parentId: activityType.parent?.id ?? null,
    data: activityType,
    children:
      activityTypes.filter(c => c.parent?.id === activityType.id).map(c => buildTreeItem(c, activityTypes)) ?? [],
  };
};
