import Axios, { AxiosResponse } from 'axios';
import Api from '../../../../data/api';
import { AllProps as ApiAllProps } from '../../../../data/api/banner';
import { ApiCancellable, ApiRequestListDiscriminator } from '../../../../data/api/types';
import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '../../../../data/api/utils';
import { LastSortIndex, Pageable } from '../../../../domain/model';
import { Banner, BannerPlace, BannerRequest } from '../../../../domain/model/banner';
import { EBannerPartition, EBannerStatus } from '../../../../domain/model/enums';
import { DataFilterQueryDslOperator } from '../../../../domain/model/filter';
import { Nullable, UUID } from '../../../../domain/model/types';
import { PaginationSize } from '../../../types';
import { BannerTableFilterValues } from '../table/filterUtils';
import { BannerTableTabsCounter, EBannerTableTab, getBannerTableStatusesByTab } from '../table/utils';
import { getBannerPlacesByPartition } from '../utils';

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly sort: string;
    readonly statuses: EBannerStatus[];
    readonly pageSize: PaginationSize;
  };
  readonly filter: BannerTableFilterValues;
  readonly pageNumber: number;
  readonly partition: EBannerPartition;
  readonly places: BannerPlace[];
  readonly userId?: Nullable<UUID>;
};

export type CountProps = Omit<AllProps, 'pageNumber'>;
export type CommonActionProps = {
  id: UUID;
};

export type SortIndexProps = CommonActionProps & {
  sortIndex: number;
};
export type OneProps = CommonActionProps & {
  signal: AbortSignal;
};

export type UpdateProps = CommonActionProps & {
  request: BannerRequest;
};

export type CreateProps = {
  request: BannerRequest;
};

type CountsByTabsProps = CountProps & {
  readonly tabs: EBannerTableTab[];
};

export interface BannersCommonService {
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps
  ) => ApiAllProps<D>;
  readonly all: (props: AllProps) => Promise<Pageable<Banner>>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly paused: (props: CommonActionProps) => Promise<AxiosResponse<Banner>>;
  readonly archived: (props: CommonActionProps) => Promise<AxiosResponse<Banner>>;
  readonly resumed: (props: CommonActionProps) => Promise<Banner>;
  readonly lastSortIndex: () => Promise<AxiosResponse<LastSortIndex>>;
  readonly changeSortIndex: (props: SortIndexProps) => Promise<AxiosResponse<void>>;
  readonly one: (props: OneProps) => Promise<Banner>;
  readonly update: (props: UpdateProps) => Promise<Banner>;
  readonly create: (props: CreateProps) => Promise<Banner>;
  readonly publish: (props: CommonActionProps) => Promise<Banner>;
  readonly countsByTabs: (
    props: CountsByTabsProps
  ) => Promise<{ counts: BannerTableTabsCounter; errors: Array<string> }>;
}

const service: BannersCommonService = {
  buildListQueryParams: payload => {
    const { search, filter, userId, pageNumber, places, partition, signal } = payload;
    const { pageSize, sort, statuses } = search;
    const { query } = filter;

    const bannerPlaceCodes = getBannerPlacesByPartition(places, partition).map(item => item.code);

    const querydsl = [
      ...(getQueryDslByDataFilterValues(filter) ?? []),
      {
        field: 'places.code',
        operator: DataFilterQueryDslOperator.In,
        value: bannerPlaceCodes,
      },
      {
        field: 'status',
        operator: DataFilterQueryDslOperator.In,
        value: statuses,
      },
    ];

    if (userId) {
      querydsl.push({
        field: 'createdBy',
        operator: DataFilterQueryDslOperator.Equals,
        value: [userId],
      });
    }

    return {
      page: pageNumber,
      pageSize,
      sort,
      statuses,
      query: query?.value,
      querydsl,
      signal,
    };
  },
  all: async props => {
    const response = await Api.banner.all({ ...service.buildListQueryParams(props) });
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);

    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
  paused: async ({ id }) => {
    return await Api.banner.paused({ id });
  },
  archived: async ({ id }) => {
    return await Api.banner.archived({ id });
  },
  resumed: async ({ id }) => {
    const { data } = await Api.banner.resumed({ id });
    return data;
  },
  changeSortIndex: async ({ id, sortIndex }) => {
    return await Api.banner.changeSortIndex({ id, sortIndex });
  },
  lastSortIndex: async () => {
    return await Api.banner.lastSortIndex();
  },
  one: async ({ id, signal }) => {
    const { data } = await Api.banner.one({ id, signal });
    return data;
  },
  update: async ({ id, request }) => {
    const { data } = await Api.banner.update({ id, request });
    return data;
  },
  publish: async ({ id }) => {
    const { data } = await Api.banner.publish({ id });
    return data;
  },
  create: async ({ request }) => {
    const { data } = await Api.banner.create(request);
    return data;
  },
  count: async props => {
    const { data: response } = await Api.banner.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      discriminator: ApiRequestListDiscriminator.Count,
    });

    return response[0].count;
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: BannerTableTabsCounter = {};

    const requests = tabs.map(tab => {
      const params = {
        ...props,
        userId: tab === EBannerTableTab.Draft ? props.userId : null,
        search: { ...props.search, statuses: getBannerTableStatusesByTab(tab) },
      };

      return service.count({ ...params, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: typeof responses[0], tab: EBannerTableTab) => {
      if (response.status === 'fulfilled') {
        counts[tab] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество рекламных банеров '${tab}': ${response.reason}`);
        }
      }
    };

    tabs.forEach((tab, index) => parseResponse(responses[index], tab));

    return { counts, errors };
  },
};

export default service;
