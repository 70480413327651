import { FC, ReactNode, useEffect } from 'react';
import CodeConfirmationDialog from './dialog';
import { useCodeEdit } from './hooks/useCodeEdit';
import { ActionConfirmWithCode } from '@/domain';
import moment from 'moment-timezone';
import { utcToLocalTimeZone } from '@/presentation/utils/dates';
import { useDispatch } from 'react-redux';
import { confirmCodeResetData } from '@features/requiredAction/confirmCode/store/slice';

type RequiredActionConfirmCodeContainerProps = {
  readonly email: string;
  readonly source: ActionConfirmWithCode;
  readonly back?: ReactNode;
  readonly onSuccess: () => void;
  readonly onGetNewCode: () => void;
  readonly onClose?: () => void;
};

export const RequiredActionConfirmCodeContainer: FC<RequiredActionConfirmCodeContainerProps> = ({
  email,
  source,
  back,
  onSuccess,
  onGetNewCode,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { code, setCode, validation, codeTtl, codeRequestId, codeNextAttemptDate, isFetching, onSendCode } =
    useCodeEdit({
      source,
      onSuccess,
    });

  const codeNextAttemptSeconds = utcToLocalTimeZone(codeNextAttemptDate).diff(moment(), 'seconds');

  useEffect(() => {
    return () => {
      dispatch(confirmCodeResetData());
    };
  }, []);

  return (
    <CodeConfirmationDialog
      open
      email={email}
      value={code}
      codeTtl={codeTtl}
      codeRequestId={codeRequestId}
      codeNextAttemptSeconds={codeNextAttemptSeconds}
      validation={validation?.code}
      isFetching={isFetching}
      back={back}
      onGetNewCode={onGetNewCode}
      onClose={onClose}
      onChange={setCode}
      onSendCode={onSendCode}
    />
  );
};
