import { useSelector } from 'react-redux';
import { RootState } from '../../../../../data/store/store';
import { TableLoading } from './controls';

type DataTableLoaderProps = {
  readonly selector: (store: RootState) => boolean;
};

export const DataTableLoader = ({ selector }: DataTableLoaderProps) => {
  const value = useSelector(selector);

  if (!value) {
    return null;
  }

  return <TableLoading color='primary' />;
};
