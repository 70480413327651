import { EUserGender } from '../../../../domain/model/enums';
import { userGender } from '../../general/nsi';

export enum ESportsmanTab {
  General = 'general',
  Events = 'events',
  Teams = 'teams',
  Bonuses = 'bonuses',
}

export const userHeaderSize = '13.5rem';

export const userRewardImageSize = '8.75rem';

export const getSportsmanDetailsTitle = () => 'Пользователь';

export const getSportsmanGender = (gender: EUserGender) =>
  gender === EUserGender.Male ? userGender.MALE.name : userGender.FEMALE.name;
