import { Select as MuiSelect, SelectProps, SvgIconOwnProps } from '@mui/material';
import React from 'react';
import AppSvgIcon from '../../../components/common/images/icon';
import { CloseIcon } from '../../../icons';
import styled from '@emotion/styled';

interface MuiSelectCommonProps extends SelectProps {
  readonly forwardedRef?: any;
}

interface MuiSelectProps extends MuiSelectCommonProps {
  readonly dense?: boolean;
}

export const SelectCommon = styled(({ forwardedRef, ...others }: MuiSelectCommonProps) => (
  <MuiSelect
    ref={forwardedRef}
    {...others}
  />
))``;

export const SelectDense = styled((props: MuiSelectProps) => <SelectCommon {...props} />)`
  .MuiOutlinedInput-input {
    padding-top: ${p => p.theme.spacing(1.5)};
    padding-bottom: ${p => p.theme.spacing(1.5)};
  }
`;

export const SelectText = styled((props: MuiSelectProps) => <SelectCommon {...props} />)`
  fieldset {
    border-color: transparent !important;
  }

  ${props =>
    props.displayEmpty &&
    ` .MuiSelect-select {
    color ${props.theme.palette.disabled.text};
  }

  .MuiSelect-icon{
    color ${props.theme.palette.disabled.text};
  }`}

  ${props =>
    props.dense &&
    `  .MuiOutlinedInput-input {
    padding-top: ${props.theme.spacing(1.5)};
    padding-bottom: ${props.theme.spacing(1.5)};
  }`}
`;

export const Select: React.ComponentType<MuiSelectProps> = styled(({ dense, variant, ...others }: MuiSelectProps) => {
  // @ts-ignore
  if (variant === 'text')
    return (
      <SelectText
        variant={'outlined'}
        {...others}
      />
    );

  if (dense) return <SelectDense {...others} />;

  return <SelectCommon {...others} />;
})``;

export const MPSelectClearButtonWrapper = styled((props: SvgIconOwnProps & { onClick?: any }) => {
  return (
    <AppSvgIcon
      icon={CloseIcon}
      {...props}
    />
  );
})`
  pointer-events: all;
  cursor: pointer;
`;
