import React from 'react';
import { OnChangeObjectAttributeByIndex } from '../../../types';
import { BookingServicePriceItemView } from '../components/fieldsEdit/types';
import { BookingOfferServiceView, BookingOfferView } from '../types';
import { BookingOfferServiceManageContainerProps } from './container';

export type BookingOfferServiceManageParamsContextValue = BookingOfferServiceManageContainerProps;

export const BookingOfferServiceManageParamsContext = React.createContext<BookingOfferServiceManageParamsContextValue>(
  {} as BookingOfferServiceManageParamsContextValue
);

export type BookingOfferServiceManageHandlersContextValue = {
  readonly onSave: () => void;
  readonly onClose: () => void;
  readonly onAttributeValidate: (name: keyof BookingOfferView) => void;

  readonly onChangeServiceAttribute: OnChangeObjectAttributeByIndex<BookingOfferServiceView>;
  readonly onServiceAttributeValidate: (index: number, name: keyof BookingOfferServiceView) => void;
  readonly onChangeServicePriceItemAttribute: <A extends keyof BookingServicePriceItemView>(
    serviceIndex: number,
    index: number,
    name: A,
    value: BookingServicePriceItemView[A]
  ) => void;
  readonly onServicePriceItemAttributeValidate: (
    serviceIndex: number,
    index: number,
    name: keyof BookingServicePriceItemView
  ) => void;
};

export const BookingOfferServiceManageHandlersContext =
  React.createContext<BookingOfferServiceManageHandlersContextValue>(
    {} as BookingOfferServiceManageHandlersContextValue
  );
