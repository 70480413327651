import { Fade, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { EPartnerStatus } from '../../../../domain/model/enums';
import { UUID } from '../../../../domain/model/types';
import MasterActionsComponent from '../../../components/common/actions/master';
import useNavAdapter from '../../../components/common/actions/navAdapter/hooks';
import { ButtonLink } from '../../../components/common/buttons/link';
import DefaultHeader from '../../../components/common/header';
import ContentLoader from '../../../components/common/loader';
import { DefaultContentWrapper } from '../../../components/common/wrappers/content';
import { DefaultFooterWrapper } from '../../../components/common/wrappers/footer';
import useHistoryExtensions from '../../../hooks/useHistoryExtensions';
import { EPanelActionPosition, PanelAction, PanelActions } from '../../../types';
import { nsiDataSelector } from '../../general/nsi/store/selectors';
import usePartnerStepper from '../../general/partner/hooks/useStepper';
import { EPartnerStep, EPartnerTableTab, PartnerStep } from '../../general/partner/types';
import { getPartnerStatusText } from '../../general/partner/utils/common';
import StepperContainer from '../../general/stepper/container';
import { getPartnersTableRoute } from '../../partner/entry';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import { getPartnerApplicationEditRoute } from '../entry';
import { EPartnerApplicationActionType, PartnerApplicationDetailsActionType } from '../types';
import { getPartnerApplicationActionName, isPartnerApplicationEditable } from '../utils';
import PartnerApplicationDetails from './component';
import { ContainerWrapper, ContentContainer, LoaderWrapper, StatusLabel, TitleWrapper } from './controls';
import { PartnerApplicationDetailsDataSelector, PartnerApplicationDetailsFetchSelector } from './store/selectors';
import { partnerApplicationDetailsFetch } from './store/slice';

interface PartnerApplicationDetailsContainerProps {
  readonly partnerId: UUID;
  readonly step: EPartnerStep;
  readonly canEdit?: boolean;
  readonly isAdmin?: boolean;
}

const PartnerApplicationDetailsContainer = (props: PartnerApplicationDetailsContainerProps) => {
  const { partnerId, step, canEdit, isAdmin = false } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const { logOut } = useCurrentUser();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const { partnerOwnerShipTypes: ownerShipTypes, taxationSystemTypes } = useSelector(nsiDataSelector);
  const partnerApplication = useSelector(PartnerApplicationDetailsDataSelector);
  const { isFetching } = useSelector(PartnerApplicationDetailsFetchSelector);

  const orgType = partnerApplication?.regInfo?.orgType;
  const { currentStep, steps, openStep, currentStepIndex, openNextStep, openPrevStep } = usePartnerStepper({
    currentStepKey: step,
    orgType,
  });

  const canModify = partnerApplication && canEdit && isPartnerApplicationEditable(partnerApplication.status, isAdmin);

  const actions: PanelActions<PartnerApplicationDetailsActionType> = canModify
    ? [
        {
          type: EPartnerApplicationActionType.Edit,
          label: getPartnerApplicationActionName(EPartnerApplicationActionType.Edit),
          position: [EPanelActionPosition.Menu],
        },
      ]
    : [];

  const onClose = () => {
    if (isAdmin) {
      gotoPrevIndependentLocation(getPartnersTableRoute({ tab: EPartnerTableTab.Unverified }));
    } else {
      logOut();
    }
  };

  const onPanelAction = (action: PanelAction<PartnerApplicationDetailsActionType>) => {
    const { type } = action;
    switch (type) {
      case EPartnerApplicationActionType.Edit:
        onEdit();
        break;
    }
  };

  const onChangeStep = (newStep: PartnerStep) => {
    if (newStep.key !== step) {
      openStep(newStep);
    }
  };

  const onEdit = () => {
    history.push(getPartnerApplicationEditRoute({ id: partnerId, step: EPartnerStep.General }));
  };

  useEffect(() => {
    const promise = dispatch(partnerApplicationDetailsFetch({ partnerId }));
    return () => {
      promise?.abort();
    };
  }, [dispatch]);

  const { adapter: navAdapter, actions: navActions } = useNavAdapter({
    openPrevStep,
    currentStepIndex,
    openNextStep,
    stepsCount: steps.length,
  });

  const actionsPanel = (
    <MasterActionsComponent<PartnerApplicationDetailsActionType>
      show={!!actions.length || !!navActions.length}
      actions={actions}
      navAdapter={navAdapter}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );

  return (
    <Fade in>
      <ContainerWrapper>
        {partnerApplication && (
          <>
            <StepperContainer<EPartnerStep>
              flow={false}
              step={currentStep}
              steps={steps}
              onClick={onChangeStep}
            />
            <ContentContainer>
              <DefaultContentWrapper
                type='details'
                stickyHeader
                fullHeight
                footer={actionsPanel}
              >
                <DefaultHeader
                  sticky
                  headline={
                    partnerApplication.status !== EPartnerStatus.Rejected && (
                      <StatusLabel
                        variant='body2'
                        status={partnerApplication.status}
                      >
                        {getPartnerStatusText(partnerApplication.status)}
                      </StatusLabel>
                    )
                  }
                  actions={
                    canModify && onEdit ? (
                      <ButtonLink onClick={onEdit}>
                        <EditIcon fontSize='small' />
                        <Typography>Редактировать</Typography>
                      </ButtonLink>
                    ) : null
                  }
                  onClose={onClose}
                >
                  <TitleWrapper>
                    <Typography variant='h2'>Заявка на партнёрство</Typography>
                  </TitleWrapper>
                </DefaultHeader>
                <PartnerApplicationDetails
                  partnerApplication={partnerApplication}
                  step={step}
                  ownerShipTypes={ownerShipTypes}
                  taxationSystemTypes={taxationSystemTypes}
                />
              </DefaultContentWrapper>
            </ContentContainer>
          </>
        )}
        {isFetching && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
      </ContainerWrapper>
    </Fade>
  );
};

export default PartnerApplicationDetailsContainer;
