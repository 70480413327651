import TabsPanelCounter from '../../../components/common/tabs/counter/counter';
import TabsPanelLabel from '../../../components/common/tabs/label/label';
import { ESportsmanTableTab, getSportsmanTableTabName, SportsmanTableTabsCounter } from './utils';

interface SportsmanTableTabProps {
  readonly tab: ESportsmanTableTab;
  readonly counts: SportsmanTableTabsCounter;
}

const SportsmanTableTab = ({ tab, counts }: SportsmanTableTabProps) => {
  const count = counts[tab];

  return (
    <TabsPanelLabel title={getSportsmanTableTabName(tab)}>
      <TabsPanelCounter count={count} />
    </TabsPanelLabel>
  );
};

export default SportsmanTableTab;
