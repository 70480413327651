import { Moment } from 'moment';
import { DataFilterStrategyDateAsDateTimePeriod, EDateFormat, Nullable } from '@/domain';
import { MPDatePicker } from '@ui-kit/picker';
import { DataFilterEditItemProps } from '../index';

interface DataFilterEditItemDateAsDateTimePeriodProps<T extends string>
  extends DataFilterEditItemProps<T, Nullable<[string, string]>> {
  readonly strategy: DataFilterStrategyDateAsDateTimePeriod<T>;
}

function DataFilterEditItemDateAsDateTimePeriod<T extends string>(
  props: DataFilterEditItemDateAsDateTimePeriodProps<T>
) {
  const { strategy, onChange } = props;

  const { label, value, readOnly } = strategy;

  const onSubmitValue = (newValue: Nullable<Moment>) => {
    if (!newValue) {
      onChange(strategy, null);
    } else {
      const date = newValue.format(EDateFormat.Server);
      const from = `${date}T00:00:00`;
      const to = `${date}T23:59:59`;
      onChange(strategy, [from, to]);
    }
  };

  return (
    <MPDatePicker
      label={label}
      value={value ? new Date(value[0]) : null}
      disabled={readOnly}
      onChange={date => onSubmitValue(date)}
    />
  );
}

export default DataFilterEditItemDateAsDateTimePeriod;
