/*
 * сложные правила
 */

import { EBookingActionType } from '../../types';
import { disableBookingAction, removeBookingAction, renameBookingAction } from '../../utils/actions';
import {
  isArchivedCondition,
  isDraftCondition,
  isNotCanDeleteCondition,
  isNotMeLastStatusAuthor,
  isNotOnModerationByMeCondition,
  isNotSavedCondition,
  isPausedByNotPartnerCondition,
  isPausedCondition,
  isWaitingForModerationCondition,
} from '../conditions';
import { BookingLifeCycleActionsRule } from '../types';

export const disableModerateActionsRule: BookingLifeCycleActionsRule = {
  description: 'Отключение доступности модерации',
  algorithm: [...isWaitingForModerationCondition.algorithm, 'или', ...isNotOnModerationByMeCondition.algorithm],
  call: props => {
    if (isWaitingForModerationCondition.call(props) || isNotOnModerationByMeCondition.call(props)) {
      disableBookingAction(props.nextActions, EBookingActionType.Approve);
      disableBookingAction(props.nextActions, EBookingActionType.Reject);
      return true;
    }
    return false;
  },
};

export const disableArchiveActionRule: BookingLifeCycleActionsRule = {
  description: 'Отключение доступности отправки в архив',
  algorithm: [...isDraftCondition.algorithm],
  call: props => {
    if (isDraftCondition.call(props)) {
      disableBookingAction(props.nextActions, EBookingActionType.Archive);
      return true;
    }
    return false;
  },
};

export const disableRetrieveActionRule: BookingLifeCycleActionsRule = {
  description: 'Отключение доступности возврата из архива',
  algorithm: [...isArchivedCondition.algorithm, 'и', ...isNotMeLastStatusAuthor.algorithm],
  call: props => {
    if (isArchivedCondition.call(props) && isNotMeLastStatusAuthor.call(props)) {
      disableBookingAction(props.nextActions, EBookingActionType.Retrieve);
      return true;
    }
    return false;
  },
};

export const disableResumePartnerActionRule: BookingLifeCycleActionsRule = {
  description: 'Отключение доступности возобновления партнером',
  algorithm: [...isPausedCondition.algorithm, 'и', ...isPausedByNotPartnerCondition.algorithm],
  call: props => {
    if (isPausedCondition.call(props) && isPausedByNotPartnerCondition.call(props)) {
      disableBookingAction(props.nextActions, EBookingActionType.Resume);
      return true;
    }
    return false;
  },
};

export const removeEditActionRule: BookingLifeCycleActionsRule = {
  description: 'Удаление возможности редактирования',
  algorithm: [...isNotOnModerationByMeCondition.algorithm],
  call: props => {
    if (isNotOnModerationByMeCondition.call(props)) {
      removeBookingAction(props.nextActions, EBookingActionType.Edit);
      return true;
    }
    return false;
  },
};

export const disableChangeModeratorActionRule: BookingLifeCycleActionsRule = {
  description: 'Отключение доступности изменения модератора',
  algorithm: [...isWaitingForModerationCondition.algorithm],
  call: props => {
    if (isWaitingForModerationCondition.call(props)) {
      disableBookingAction(props.nextActions, EBookingActionType.ChangeModerator);
      return true;
    }
    return false;
  },
};

export const removeDeleteActionRule: BookingLifeCycleActionsRule = {
  description: 'Удаление доступности удаления',
  algorithm: [...isNotCanDeleteCondition.algorithm],
  call: props => {
    if (isNotCanDeleteCondition.call(props)) {
      removeBookingAction(props.nextActions, EBookingActionType.Delete);
      return true;
    }
    return false;
  },
};

export const removeDuplicateActionRule: BookingLifeCycleActionsRule = {
  description: 'Удаление доступности дублирования',
  algorithm: [...isNotSavedCondition.algorithm],
  call: props => {
    if (isNotSavedCondition.call(props)) {
      removeBookingAction(props.nextActions, EBookingActionType.Duplicate);
      return true;
    }
    return false;
  },
};

export const renameDuplicateActionRule: BookingLifeCycleActionsRule = {
  description: 'Смена названия действия Копировать в черновики для статуса черновик',
  algorithm: [...isDraftCondition.algorithm],
  call: props => {
    if (isDraftCondition.call(props)) {
      renameBookingAction(props.nextActions, EBookingActionType.Duplicate, 'Копировать');
      return true;
    }
    return false;
  },
};
