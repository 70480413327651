import { Tab, Tabs } from '@mui/material';
import { BonusTransaction } from '../../../../domain/model';
import { Team } from '../../../../domain/model/team';
import { UUID } from '../../../../domain/model/types';
import { SportUserProfile } from '../../../../domain/model/user';
import BackButton from '../../../components/common/buttons/back';
import ContentLoader from '../../../components/common/loader';
import { DefaultContentWrapper } from '../../../components/common/wrappers/content';
import { BonusInfo } from '../types';
import {
  Content,
  Header,
  LoaderWrapper,
  SportsmanDetailsHeaderWrapper,
  TabsWrapper,
  TabWrapper,
  Wrapper,
} from './controls';
import SportsmanDetailsHeader from './header';
import SportsmanTabBonuses from './tabs/bonuses';
import SportsmanTabEvents from './tabs/events';
import SportsmanTabGeneral from './tabs/general';
import SportsmanTabTeams from './tabs/teams';
import { ESportsmanTab } from './utils';

interface SportsmanDetailsComponentProps {
  readonly guid: UUID;
  readonly tab: ESportsmanTab;
  readonly sportsman: SportUserProfile;
  readonly teams: Team[];
  readonly bonus: BonusInfo;
  readonly bonusTransactions: {
    readonly enabled: boolean;
    readonly data: BonusTransaction[];
    readonly totalCount: number;
    readonly isFetching: boolean;
    readonly needRefresh: boolean;
    readonly loadNextPage: () => void;
  };
  readonly isFetching: boolean;
  readonly onTeamClick: (event: Team) => void;
  readonly onBonusTransactionClick: (event: BonusTransaction) => void;
  readonly onChangeTab: (tab: ESportsmanTab) => void;
}

const SportsmanDetails = (props: SportsmanDetailsComponentProps) => {
  const {
    guid,
    tab,
    sportsman,
    teams,
    bonus,
    bonusTransactions,
    onTeamClick,
    onBonusTransactionClick,
    isFetching,
    onChangeTab,
  } = props;

  return (
    <DefaultContentWrapper>
      <Wrapper>
        <Header>
          <BackButton label='Пользователь' />
        </Header>
        <Content>
          <SportsmanDetailsHeaderWrapper>
            <SportsmanDetailsHeader
              sportsman={sportsman}
              bonus={bonus}
            />
          </SportsmanDetailsHeaderWrapper>
          <TabsWrapper>
            <Tabs
              onChange={(event, newTab: ESportsmanTab) => onChangeTab(newTab)}
              value={tab}
              textColor='primary'
            >
              <Tab
                label='Профиль'
                value={ESportsmanTab.General}
              />
              <Tab
                label='Мероприятия'
                value={ESportsmanTab.Events}
              />
              <Tab
                label='Команды'
                value={ESportsmanTab.Teams}
              />
              {bonusTransactions.enabled && (
                <Tab
                  label='Бонусы'
                  value={ESportsmanTab.Bonuses}
                />
              )}
            </Tabs>
          </TabsWrapper>
          {tab === ESportsmanTab.General && (
            <TabWrapper tab={tab}>{sportsman && <SportsmanTabGeneral sportsman={sportsman} />}</TabWrapper>
          )}

          {tab === ESportsmanTab.Events && (
            <TabWrapper tab={tab}>
              <SportsmanTabEvents
                guid={guid}
                userId={sportsman.id}
              />
            </TabWrapper>
          )}

          {tab === ESportsmanTab.Teams && (
            <TabWrapper tab={tab}>
              <SportsmanTabTeams
                sportsmanId={sportsman.id}
                teams={teams}
                onTeamClick={onTeamClick}
              />
            </TabWrapper>
          )}

          {tab === ESportsmanTab.Bonuses && bonusTransactions.enabled && (
            <TabWrapper tab={tab}>
              <SportsmanTabBonuses
                bonusTransactions={bonusTransactions.data}
                totalCount={bonusTransactions.totalCount}
                isFetching={bonusTransactions.isFetching}
                needRefresh={bonusTransactions.needRefresh}
                loadNextPage={bonusTransactions.loadNextPage}
                onBonusTransactionClick={onBonusTransactionClick}
              />
            </TabWrapper>
          )}
        </Content>

        {isFetching && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
      </Wrapper>
    </DefaultContentWrapper>
  );
};

export default SportsmanDetails;
