import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ClientOrgUserTableCellCommonProps } from '../../components/tableCell';
import { clientOrgUsersClientOrgUserByIndexSelector } from '../store/selectors';

export type ClientOrgUsersTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<ClientOrgUserTableCellCommonProps>;
};

const ClientOrgUsersTableCellAdapter = (props: ClientOrgUsersTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const clientOrgUser = useSelector(clientOrgUsersClientOrgUserByIndexSelector(index));

  return clientOrgUser ? <Component clientOrgUser={clientOrgUser} /> : null;
};

export default ClientOrgUsersTableCellAdapter;
