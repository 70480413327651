export enum ChangeType {
  search = 'search',
  click = 'click',
}

export type CascadeItem<T = unknown> = {
  id: string;
  name: string;
  parentId: string | null;
} & T;

export type CascadeItemWithChildren<T> = CascadeItem<T> & {
  children?: CascadeItem<T>[];
};
