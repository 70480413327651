import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { UUID } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import { EPartnerStep } from '../../features/general/partner/types';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import PartnerApplicationEditContainer from '../../features/partnerApplication/edit/container';
import { EPartnerApplicationUrlParam } from '../../features/partnerApplication/entry';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const PartnerApplicationEditScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { accessMatrix, refreshToken } = useCurrentUser();
  const { partners } = accessMatrix;

  const { id } = useParams<{ id: UUID }>();
  const searchParams = new URLSearchParams(location.search);
  const step = (searchParams.get(EPartnerApplicationUrlParam.Step) as EPartnerStep) ?? EPartnerStep.General;

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  if (!partners?.edit) {
    return <ForbiddenComponent />;
  }

  return (
    <PartnerApplicationEditContainer
      partnerId={id}
      step={step}
      isAdmin={partners.viewAsAdmin}
      canApprove={partners.approve}
      canSendToVerification={partners.sendToVerification}
      canReturnToVerification={partners.returnToVerification}
      canChangePermissions={partners.changePermissions}
      refreshCurrentUser={refreshToken}
    />
  );
};

export default PartnerApplicationEditScreen;
