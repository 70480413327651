import { MpCustomer } from '@/domain';
import { ECustomersFilterItem } from '@features/customer/table/filterUtils';
import { CustomerActions, CustomerActionTableType, ECustomerActionType } from '@features/customer/types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import CustomerTable from '../component';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  customersActionsSelector,
  customersCustomersSelector,
  customersFilterSelector,
  customersGuidSelector,
  customersSortColumnSelector,
  customersSortDirectionSelector,
} from '../store/selectors';
import { CustomerActionTableCall, CustomerTableActionProcess, ECustomerTableColumn } from '../utils';

interface CustomerTableAdapterProps {
  readonly filterAdapter: DataFilterAdapter<ECustomerTableColumn, ECustomersFilterItem>;
  readonly tabActions: CustomerActions<CustomerActionTableType>;
  readonly onCustomerClick: (customer: MpCustomer) => void;
}

export const CustomerTableAdapter = (props: CustomerTableAdapterProps) => {
  const { filterAdapter, tabActions, onCustomerClick } = props;

  const handlers = useContextHandlers();

  const guid = useSelector(customersGuidSelector);
  const customers = useSelector(customersCustomersSelector);
  const actionProcesses = useSelector(customersActionsSelector);

  const sortColumn = useSelector(customersSortColumnSelector);
  const sortDirection = useSelector(customersSortDirectionSelector);
  const filter = useSelector(customersFilterSelector);

  const onCustomerAction = (call: CustomerActionTableCall) => {
    const { action, customer } = call;
    switch (action) {
      case ECustomerActionType.Activate:
        handlers.onCustomerActivate(customer);
        break;
      case ECustomerActionType.Deactivate:
        handlers.onCustomerDeactivate(customer);
        break;
    }
  };

  const getActionProcesses = (customer: MpCustomer): CustomerTableActionProcess[] => {
    return actionProcesses.filter(p => p.id === customer.id) ?? [];
  };

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  const getCustomerActions = useCallback(
    (customer: MpCustomer) => {
      return tabActions;
    },
    [tabActions]
  );

  return (
    <CustomerTable
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      customers={customers}
      getActions={getCustomerActions}
      onRequestSort={handlers.onChangeSort}
      getActionProcesses={getActionProcesses}
      onChangeMetadata={onChangeMetadata}
      onCustomerAction={onCustomerAction}
      onCustomerClick={onCustomerClick}
    />
  );
};
