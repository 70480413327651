import { useSelector } from 'react-redux';
import { ProductOffer } from '../../../../../domain/model/productOffer';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { EPanelActionPosition } from '../../../../types';
import { ProductLifeCycle } from '../../lifecycle/types';
import {
  EProductActionType,
  ProductActionProcess,
  ProductActions,
  ProductActionTableCommonType,
  ProductActionTableType,
} from '../../types';
import { getProductTableActions } from '../../utils/actions';
import ProductTable from '../component';
import { EProductTableFilterItem } from '../filter/filterUtils';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  productsActionsSelector,
  productsFilterSelector,
  productsGuidSelector,
  productsProductOffersSelector,
  productsSelectedSelector,
  productsSortColumnSelector,
  productsSortDirectionSelector,
} from '../store/selectors';
import { EProductTableColumn, ProductActionCall } from '../utils';

interface ProductTableAdapterProps {
  readonly lifecycle: ProductLifeCycle;
  readonly filterAdapter: DataFilterAdapter<EProductTableColumn, EProductTableFilterItem>;
  readonly tabActions: ProductActions<ProductActionTableType>;
}

const ProductTableAdapter = (props: ProductTableAdapterProps) => {
  const { lifecycle, filterAdapter, tabActions } = props;

  const handlers = useContextHandlers();
  const { multiSelectAllowed, onProductOfferClick, onProductOfferOpenEdit } = useContextConfig();

  const guid = useSelector(productsGuidSelector);
  const products = useSelector(productsProductOffersSelector);
  const actionProcesses = useSelector(productsActionsSelector);
  const selectedProducts = useSelector(productsSelectedSelector);
  const sortColumn = useSelector(productsSortColumnSelector);
  const sortDirection = useSelector(productsSortDirectionSelector);
  const filter = useSelector(productsFilterSelector);

  const onActionClick = (call: ProductActionCall) => {
    const { action, product } = call;
    const { productDesk } = product;
    switch (action) {
      case EProductActionType.Pause:
        handlers.onChangeDialogState('pause', product);
        break;
      case EProductActionType.PauseDesk:
        handlers.onChangeDialogState('pauseDesk', product);
        break;
      case EProductActionType.Archive:
        handlers.onChangeDialogState('archive', product);
        break;
      case EProductActionType.ArchiveDesk:
        if (productDesk) {
          handlers.onChangeDialogState('archiveDesk', product);
        }
        break;
      case EProductActionType.Resume:
        handlers.onProductResume(product);
        break;
      case EProductActionType.ResumeDesk:
        if (productDesk) {
          handlers.onProductDeskResume(productDesk);
        }
        break;
      case EProductActionType.Delete:
        handlers.onProductDelete(product);
        break;
      case EProductActionType.Approve:
        handlers.onProductApprove(product);
        break;
      case EProductActionType.Edit:
        onProductOfferOpenEdit(product);
        break;
      case EProductActionType.Duplicate:
        handlers.onProductDuplicate(product).then(duplicate => {
          if (duplicate) {
            onProductOfferOpenEdit(duplicate);
          }
        });
        break;
      case EProductActionType.ChangePrice:
        handlers.onChangeDialogState('changePrice', product);
        break;
      case EProductActionType.ChangeStock:
        handlers.onChangeDialogState('changeStock', product);
        break;
      case EProductActionType.ChangeModerator:
        handlers.onChangeDialogState('changeModerator', product);
        break;
      case EProductActionType.Reject:
        handlers.onChangeDialogState('reject', product);
        break;
      case EProductActionType.Publish:
        handlers.onProductPublish(product);
        break;
      case EProductActionType.UnPublish:
        handlers.onProductUnPublish(product);
        break;
      case EProductActionType.UnPublishDesk:
        handlers.onProductUnPublishDesk(product);
        break;
    }
  };

  const getProductCommonActions = (
    product: ProductOffer,
    actions: ProductActions<ProductActionTableType>
  ): ProductActions<ProductActionTableCommonType> => {
    return getProductTableActions(lifecycle, product, actions).filter(action =>
      action.position.includes(EPanelActionPosition.Menu)
    );
  };

  const getProductActionProcesses = (product: ProductOffer): ProductActionProcess[] => {
    return actionProcesses.filter(p => p.id === product.id) ?? [];
  };

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });


  return (
    <ProductTable
      metadata={metadata}
      products={products}
      sort={{ column: sortColumn, direction: sortDirection }}
      getCommonActions={product => getProductCommonActions(product, tabActions)}
      getActionProcesses={getProductActionProcesses}
      selected={selectedProducts}
      onProductAction={onActionClick}
      onRequestSort={handlers.onChangeSort}
      onClick={onProductOfferClick}
      onProductSelect={multiSelectAllowed ? handlers.onProductSelect : null}
      onChangeMetadata={onChangeMetadata}
      onAllProductsSelect={handlers.onAllProductsSelect}
    />
  );
};

export default ProductTableAdapter;
