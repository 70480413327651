import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import TabsPanel from '../../../../components/common/tabs/panel';
import { EOfferHistoryTableTab } from '../../types';
import OfferHistoryTableTab from '../components/tab';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { offerHistoryTableTabSelector } from '../store/selectors';

export const OfferHistoryTableTabsAdapter = () => {
  const { defaultTab } = useContextConfig();
  const handlers = useContextHandlers();

  const currentTab = useSelector(offerHistoryTableTabSelector);

  const { tabs } = useContextConfig();

  const tab = useMemo<EOfferHistoryTableTab>(() => currentTab ?? defaultTab, [currentTab, defaultTab]);

  const onChangeTab = useCallback(
    (newTab: EOfferHistoryTableTab) => {
      if (newTab !== tab) {
        handlers.onSetTab(newTab);
      }
    },
    [tab]
  );

  return useMemo(
    () => (
      <TabsPanel
        tab={tab}
        tabs={tabs}
        labelAdapter={OfferHistoryTableTab}
        onChangeTab={onChangeTab}
      />
    ),
    [tab, onChangeTab]
  );
};
