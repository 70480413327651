import { FCC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BookingOrderEditContainerProps } from './container';
import { bookingOrderEditGuidSelector } from './store/selectors';
import { bookingOrderEditByIdFetch, bookingOrderEditClearState, bookingOrderEditStartSession } from './store/slice';

export const BookingOrderEditInitializer: FCC<BookingOrderEditContainerProps> = ({ id, children }) => {
  const dispatch = useDispatch();
  const currentGuid = useSelector(bookingOrderEditGuidSelector);

  useEffect(() => {
    dispatch(bookingOrderEditStartSession({ guid: id }));

    return () => {
      dispatch(bookingOrderEditClearState());
    };
  }, [dispatch, id]);

  //загрузка основных данных
  useEffect(() => {
    // При первой загрузке мы инициализируем данные по умолчанию
    if (currentGuid === id) {
      const request = dispatch(bookingOrderEditByIdFetch({ id }));

      return () => {
        request.abort();
      };
    }
  }, [dispatch, id, currentGuid]);

  if (id !== currentGuid) {
    return null;
  }

  return <>{children}</>;
};
