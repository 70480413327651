import CustomerTableCell, { CustomerTableCellCommonProps } from './index';

const CustomerTableCellPernr = (props: CustomerTableCellCommonProps) => {
  const {
    customer: { pernr: value = '-' },
  } = props;

  return (
    <CustomerTableCell
      {...props}
      value={value}
    />
  );
};

export default CustomerTableCellPernr;
