import debounce from 'lodash/debounce';
import { useState } from 'react';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import {
  DataFilterStrategyAutocompleteSingle,
  DataFilterStrategyListItem,
} from '../../../../../../domain/model/filter';
import { Nullable } from '../../../../../../domain/model/types';
import { MPAutocompleteSingleSelect } from '../../../../../theme/ui-kit/autocomplete/single';
import { DataFilterEditItemProps } from '../index';

type ValueType<T extends string> = DataFilterStrategyAutocompleteSingle<T>['value'];

interface DataFilterEditItemAutocompleteSingleProps<T extends string>
  extends DataFilterEditItemProps<T, Nullable<ValueType<T>>> {
  readonly strategy: DataFilterStrategyAutocompleteSingle<T>;
}

function DataFilterEditItemAutocompleteSingle<T extends string>(props: DataFilterEditItemAutocompleteSingleProps<T>) {
  const { strategy, onChange } = props;

  const { label, value, readOnly, source } = strategy;

  const [options, setOptions] = useState<DataFilterStrategyListItem[]>([]);

  const handlerSearch = debounce(newValue => search(newValue), 500);

  const search = (searchQuery: string) => {
    source(searchQuery)
      .then(setOptions)
      .catch(e => {
        console.error(e);
        ErrorHandler.handleHttpError(e, e.response);
      });
  };

  return (
    <MPAutocompleteSingleSelect<DataFilterStrategyListItem>
      value={value}
      options={options}
      label={label}
      disabled={readOnly}
      onSearchValue={newValue => handlerSearch(newValue)}
      onChangeValue={newValue => onChange(strategy, newValue ? { id: newValue.id, name: newValue.name } : null)}
    />
  );
}

export default DataFilterEditItemAutocompleteSingle;
