import styled from '@emotion/styled';
import { Drawer as MuiDrawer, DrawerProps as MuiDrawerProps, Grid, GridProps, IconButton } from '@mui/material';
import { Property } from 'csstype';

interface DrawerProps extends MuiDrawerProps {
  readonly fullScreen?: boolean;
  readonly leftOffset: number | string;
  readonly backdropAdjust?: any;
}

interface HeaderProps extends GridProps {
  readonly sticky?: boolean;
}

interface ContentProps {
  readonly overflow?: Property.Overflow;
}

export const Drawer = styled(({ leftOffset, fullScreen, backdropAdjust, ...others }: DrawerProps) => (
  <MuiDrawer {...others} />
))`
  ${p =>
    p.backdropAdjust &&
    `
      &.MuiDrawer-root {
        left: ${typeof p.leftOffset === 'string' ? `calc(${p.leftOffset} + 1px)` : `${p.leftOffset + 1}px`} !important;
      }
    `}
  .MuiPaper-root {
    max-height: 100%;

    left: ${p => (typeof p.leftOffset === 'string' ? `calc(${p.leftOffset} + 1px)` : `${p.leftOffset + 1}px`)};

    display: flex;
    flex-direction: column;
    overflow: auto;

    border-bottom: 1px solid ${p => p.theme.palette.secondary.main};

    ${p =>
      p.fullScreen &&
      `
      height: 100%;

      border-bottom: none;
    `}
  }

  .MuiBackdrop-root {
    left: ${p => (typeof p.leftOffset === 'string' ? `calc(${p.leftOffset} + 1px)` : `${p.leftOffset + 1}px`)};

    background: ${p => p.theme.palette.background.defaultAlpha80};
  }
`;

export const CloseButton = styled(IconButton)`
  left: ${p => p.theme.spacing(4)};
  top: ${p => p.theme.spacing(3.2)};

  position: absolute;
`;

export const Header = styled(({ sticky, ...others }: HeaderProps) => <Grid {...others} />)`
  padding: ${p => p.theme.spacing(5, 5, 2, 10)};
  background-color: ${p => p.theme.palette.background.default};
  z-index: 1;

  ${p =>
    p.sticky &&
    `
  position: sticky;
  top: 0;
  `}
`;

export const Content = styled.div<ContentProps>`
  height: 100%;

  padding: ${p => p.theme.spacing(2, 5, 2, 10)};

  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: ${p => p.overflow ?? 'unset'};
`;

export const Footer = styled.div`
  border-top: 1px solid ${p => p.theme.palette.secondary.main};

  padding: ${p => p.theme.spacing(2, 5, 2, 10)};
`;
