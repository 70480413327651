import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable } from '../../../../../domain/model';
import { Complaint } from '../../../../../domain/model/complaint';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { PaginationSize } from '../../../../types';
import complaintServices from '../../services';
import { AllProps } from '../../services/common';
import { ComplaintTableFilterValues } from '../filterUtils';
import { ComplaintTableTabsCounter, EComplaintTableColumn, EComplaintTableTab } from '../utils';

const defaultSort = `${EComplaintTableColumn.CreatedDate},desc`;

export type ComplaintsFetchProps = Omit<AllProps, 'signal'>;

type ComplaintsCountsFetchProps = ComplaintsFetchProps & {
  readonly tabs: EComplaintTableTab[];
};

export const complaintsFetch = createAsyncThunk<Pageable<Complaint>, ComplaintsFetchProps, AppThunkAPIConfig>(
  'complaint/list/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await complaintServices.common.all({ ...props, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const complaintsCountsFetch = createAsyncThunk<
  ComplaintTableTabsCounter,
  ComplaintsCountsFetchProps,
  AppThunkAPIConfig
>('complaint/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await complaintServices.common.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface ComplaintListState extends Fetchable, Pageable<Complaint> {
  readonly guid: Nullable<UUID>;
  readonly tab: Nullable<EComplaintTableTab>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
  };
  readonly filter: ComplaintTableFilterValues;
  readonly tabsCounter: ComplaintTableTabsCounter;
}

type Reducer<T = undefined> = CaseReducer<ComplaintListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ComplaintListState> {
  complaintsStartSession: Reducer<{ guid: UUID }>;
  complaintsSetTab: Reducer<{ tab: EComplaintTableTab }>;
  complaintsSetSort: Reducer<{ sort: string }>;
  complaintsSetPage: Reducer<{ pageNumber: number }>;
  complaintsSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  complaintsDataReset: Reducer;
  complaintsSortReset: Reducer;
  complaintsSetFilter: Reducer<ComplaintTableFilterValues>;
  complaintsNeedRefreshWatcherReset: Reducer;
}

const slice = createSlice<ComplaintListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    ...fetchableDefault,
    guid: null,
    tab: null,
    data: [],
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    needRefreshWatcher: 0,
    search: {
      sort: defaultSort,
      pageSize: 15,
    },
    filter: {},
    tabsCounter: {},
  },
  reducers: {
    complaintsStartSession: (state, { payload }) => {
      const { guid } = payload;
      if (guid !== state.guid) {
        state.guid = guid;
        state.needRefreshWatcher = 0;
        state.tabsCounter = {};

        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;

        state.tab = null;
      }
    },
    complaintsSetTab: (state, { payload: { tab } }) => {
      // сбрасываем только пейджинг, если поменялся таб (сортировку смысла нет - набор полей одинаков)
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    complaintsSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    complaintsSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    complaintsSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    complaintsSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    complaintsDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
    },
    complaintsSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    complaintsNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(complaintsFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
      })
      .addCase(complaintsFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(complaintsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(complaintsCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(complaintsCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      });
  },
});

export const {
  complaintsStartSession,
  complaintsSetTab,
  complaintsSetSort,
  complaintsSetPage,
  complaintsSetPageSize,
  complaintsSetFilter,
  complaintsDataReset,
  complaintsSortReset,
  complaintsNeedRefreshWatcherReset,
} = slice.actions;

export default slice.reducer;
