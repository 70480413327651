import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TabsPanel from '../../../../components/common/tabs/panel';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { partnerEmployeesCounterSelector } from '../store/selectors';
import CustomersTableTab from '../tab';
import { EPartnerEmployeeTableTab } from '../utils';

interface PartnerEmployeeTableTabsAdapterProps {
  readonly tab: EPartnerEmployeeTableTab;
}

export const PartnerEmployeeTableTabsAdapter = (props: PartnerEmployeeTableTabsAdapterProps) => {
  const { tab } = props;

  const { onChangeTab } = useContextHandlers();

  const counter = useSelector(partnerEmployeesCounterSelector);

  return useMemo(() => {
    return (
      <TabsPanel
        tab={tab}
        tabs={Object.values(EPartnerEmployeeTableTab)}
        labelAdapter={CustomersTableTab}
        data={{
          counts: counter,
        }}
        onChangeTab={onChangeTab}
      />
    );
  }, [tab, counter, onChangeTab]);
};
