import { FormHelperText, useTheme } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { IAllProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { AppConfigurator } from '../../../../system/appConfigurator';
import { MPTheme } from '../../types';
import { Wrapper } from './controls';

import './controls';

interface MPRichTextEditorProps extends IAllProps {
  readonly label?: string;
  readonly error?: boolean;
  readonly helperText?: string;
}

export const MPRichTextEditor = ({ label, value, error, helperText, onEditorChange }: MPRichTextEditorProps) => {
  const theme = useTheme() as MPTheme;

  const contentStyles = [
    `html { font-size: ${theme.typography.htmlFontSize}px; }`,
    `body { font-family: ${theme.typography.fontFamily}; }`,
    `* { font-size: ${theme.typography.body1.fontSize}; line-height: ${theme.typography.body1.lineHeight}; margin: 0; }`,
  ];
  const plugins = ['autolink', 'link', 'wordcount', 'visualchars ', 'lists'];
  const toolbar =
    'undo redo | bold italic underline strikethrough | numlist bullist link | backcolor forecolor | outdent indent';

  return (
    <Wrapper error={error}>
      <Editor
        tinymceScriptSrc={`${AppConfigurator.getInstance().getTinyMCEConfig().path}/tinymce.min.js`}
        init={{
          placeholder: label,
          language: 'ru',
          branding: false,
          height: 300,
          menubar: false,
          content_style: contentStyles.join(' '),
          plugins,
          toolbar,
          paste_as_text: true,
        }}
        value={value}
        /*onKeyDown={(event, editor) => {
          /!**
           * todo задача пока отложена из-за сложности
           * ASCII коды для Control characters - 0-31,127
           * чтобы не превысить лимит по тексту, необходимо
           * 1. предотвратить нажатие на Printable characters в фазе события "0"
           * 2. исключить ситуацию когда фаза события больше "0"
           *!/
          const max = 20;
          const numChars = editor.plugins.wordcount.body.getCharacterCount();

          const isPrintableChar = event.keyCode > 31 && event.keyCode !== 127;
          const isMultiKey = event.keyCode > 31 && event.keyCode !== 127;

          if ( && numChars > max) {
            event.preventDefault();
            return false;
          }
        }}*/
        onEditorChange={onEditorChange}
      />
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </Wrapper>
  );
};
