import styled from '@emotion/styled';
import { css } from '@mui/material';

interface WrapperProps {
  readonly error?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  .MuiFormHelperText-root {
    margin-top: ${p => p.theme.spacing()};
  }

  .tox-tinymce {
    border: 1px solid ${p => p.theme.palette.secondary.main} !important;
    border-radius: ${p => p.theme.shape.borderRadius}px !important;
  }

  .tox-toolbar__primary {
    background: none !important;
    border-bottom: 1px solid ${p => p.theme.palette.secondary.main} !important;
  }

  .tox-toolbar__group {
    border-right: 1px solid ${p => p.theme.palette.secondary.main} !important;
  }

  .tox-statusbar {
    border-top: 1px solid ${p => p.theme.palette.secondary.main} !important;
  }

  .tox-dialog * {
    font-family: ${p => p.theme.typography.fontFamily};
  }

  .tox-button {
    background-color: ${p => p.theme.palette.primary.main} !important;
    border: none !important;

    :hover {
      background-color: ${p => p.theme.palette.primary.alpha40} !important;
    }
  }

  .tox-button--secondary {
    background-color: ${p => p.theme.palette.background.default} !important;
    border: 1px solid ${p => p.theme.palette.secondary.main} !important;

    :hover {
      background-color: ${p => p.theme.palette.secondary.alpha10} !important;
    }
  }

  .tox-button--icon {
    background-color: ${p => p.theme.palette.background.default} !important;
    border: none !important;

    :hover {
      background-color: ${p => p.theme.palette.secondary.alpha10} !important;
    }
  }

  ${p =>
    p.error &&
    css`
      .tox-tinymce {
        border-color: ${p.theme.palette.error.main} !important;
      }
    `}
`;
