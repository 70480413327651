import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ResetPasswordContainer from '../../features/auth/resetPassword/container';
import { viewHideMainMenu } from '../../features/main/container/store/slice';

const ResetPasswordScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  return <ResetPasswordContainer />;
};

export default ResetPasswordScreen;
