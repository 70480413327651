import styled from '@emotion/styled';
import React from 'react';
import { MPButton } from '../../../../theme/ui-kit/button';

export const StyledButtonLink = styled(
  React.forwardRef((props: any, ref) => (
    <MPButton
      ref={ref}
      {...props}
    />
  ))
)`
  min-width: auto;
  min-height: 0;
  height: auto;

  position: relative;
  display: flex;
  align-content: center;
  gap: ${p => p.theme.spacing()};

  padding: 0;
  font-weight: normal;

  &:hover {
    background: none;
    text-decoration: underline;
  }

  & > * {
    white-space: nowrap;
  }
`;
