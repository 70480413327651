import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { OfferFieldHistoryTableCellCommonProps } from '../components/tableCell/fieldHistory';
import { offerHistoryTableFieldHistoryByIndexSelector } from '../store/selectors';

export type OfferFieldHistoryTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<OfferFieldHistoryTableCellCommonProps>;
};

const OfferFieldHistoryTableCellAdapter = (props: OfferFieldHistoryTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const fieldHistory = useSelector(offerHistoryTableFieldHistoryByIndexSelector(index));

  return useMemo(() => (fieldHistory ? <Component fieldHistory={fieldHistory} /> : null), [Component, fieldHistory]);
};

export default OfferFieldHistoryTableCellAdapter;
