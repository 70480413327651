import { EPanelActionPosition } from '../../../../types';
import { BookingOrderLifeCycleRules } from '../../lifecycle/types';
import { BookingOrderActionDetailsType, BookingOrderActions, EBookingOrderActionType } from '../../types';
import {
  changeBookingOrderActionPosition,
  filterBookingOrderDetailsActions,
  renameBookingOrderAction,
} from '../../utils/actions';

type UseBookingOrderDetailsActions = BookingOrderActions<BookingOrderActionDetailsType>;

interface UseBookingOrderDetailsActionsProps {
  readonly lifeCycleRules: BookingOrderLifeCycleRules;
}

const useBookingOrderDetailsActions = (props: UseBookingOrderDetailsActionsProps): UseBookingOrderDetailsActions => {
  const { lifeCycleRules } = props;

  const { nextActions } = lifeCycleRules;
  const detailsActions = filterBookingOrderDetailsActions(nextActions);

  //переименуем кнопку Редактирования/Сохранения
  renameBookingOrderAction(detailsActions, EBookingOrderActionType.Save, 'Редактировать');
  //перемещаем кнопку Редактирования/Сохранения в меню
  changeBookingOrderActionPosition(detailsActions, EBookingOrderActionType.Save, [EPanelActionPosition.Menu]);
  //перемещаем кнопку Отменыв меню
  changeBookingOrderActionPosition(detailsActions, EBookingOrderActionType.Cancel, [EPanelActionPosition.Menu]);

  return detailsActions;
};

export default useBookingOrderDetailsActions;
