import { Nullable } from '../../../../../../domain/model/types';
import { Caption } from './controls';

interface CmsPreviewBlockCaptionProps {
  readonly text?: Nullable<string>;
}

const CmsPreviewBlockCaption = ({ text }: CmsPreviewBlockCaptionProps) => {
  return (
    <Caption
      color='textSecondary'
      noWrap
    >
      {text}
    </Caption>
  );
};

export default CmsPreviewBlockCaption;
