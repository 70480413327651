import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../data/store/store';
import { ESortDirection } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { addSearchParamToLocation } from '../../../../../routing/globalRouting';
import { PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import { ESportsmanUrlParam } from '../../entry';
import {
  ESportsmansTableFilterItem,
  SportsmansTableFilterEditStrategy,
  SportsmansTableFilterValues,
} from '../../filterUtils';
import { sportsmansGetGuid } from '../store/selectors';
import {
  sportsmanDataReset,
  sportsmanResetFilter,
  sportsmanSetFilter,
  sportsmanSetPage,
  sportsmanSetPageSize,
  sportsmanSetSort,
  sportsmanSortReset,
} from '../store/slice';
import { ESportsmanTableTab } from '../utils';

export type UseSportsmanTableHandlers = {
  readonly onChangeTab: (newTab: ESportsmanTableTab) => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
  readonly onChangePage: (num: number) => void;
  readonly onChangePageSize: (size: PaginationSize) => void;
  readonly onClearFilter: () => void;
  readonly onResetSort: () => void;
  readonly onChangeFilter: (strategies: SportsmansTableFilterEditStrategy[]) => void;
};

type UseSportsTableHandlersProps = {
  readonly tab: ESportsmanTableTab;
};

export function useSportsTableHandlers({ tab }: UseSportsTableHandlersProps): UseSportsmanTableHandlers {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const getSportsmanTableGuidFromState = useCallback((): Nullable<UUID> => {
    const state = store.getState();
    return sportsmansGetGuid(state);
  }, [store]);

  const onClearFilter = () => {
    dispatch(sportsmanResetFilter());
  };

  const onResetSort = useCallback(() => dispatch(sportsmanSortReset()), [dispatch]);

  const onChangeSort = useCallback(
    (name: string, direction: ESortDirection) => {
      dispatch(
        sportsmanSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onChangeTab = useCallback(
    (newTab: ESportsmanTableTab) => {
      if (newTab !== tab) {
        const guid = getSportsmanTableGuidFromState();
        dispatch(sportsmanDataReset());
        history.replace(
          addSearchParamToLocation({
            location,
            param: ESportsmanUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [dispatch, history, location, tab, getSportsmanTableGuidFromState]
  );

  const onChangePage = (num: number) => dispatch(sportsmanSetPage({ pageNumber: num }));

  const onChangePageSize = (size: PaginationSize) => dispatch(sportsmanSetPageSize({ pageSize: size }));

  const onChangeFilter = (strategies: SportsmansTableFilterEditStrategy[]) => {
    dispatch(
      sportsmanSetFilter(
        getDataFilterValuesByStrategies<ESportsmansTableFilterItem, SportsmansTableFilterValues>(strategies)
      )
    );
  };

  return {
    onChangeTab,
    onChangeFilter,
    onChangeSort,
    onChangePage,
    onChangePageSize,
    onClearFilter,
    onResetSort,
  };
}
