import clientOrg, { ClientOrgService, AllProps } from './clientOrg';

type ClientOrgServicesType = {
  readonly clientOrg: ClientOrgService;
};

const clientOrgServices: ClientOrgServicesType = {
  clientOrg,
};

export default clientOrgServices;
export type { AllProps };
