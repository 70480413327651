import { Typography } from '@mui/material';
import DefaultHeader from '../../../../../components/common/header';
import { TitleWrapper } from '../controls';
import { useContextHandlers } from '../hooks/useContextHandlers';

export const TradeOfferCategoriesEditHeaderAdapter = () => {
  const handlers = useContextHandlers();

  return (
    <DefaultHeader
      sticky
      onClose={handlers.onClose}
    >
      <TitleWrapper>
        <Typography variant='h2'>Категории торговых предложений</Typography>
      </TitleWrapper>
    </DefaultHeader>
  );
};
