import common, { AddressCommonService } from './common';

type AddressServices = {
  readonly common: AddressCommonService;
};

const addressServices: AddressServices = {
  common,
};

export default addressServices;
