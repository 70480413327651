import { BookingServiceCategory } from '../../../../../../domain/model/booking';
import { DictionaryDeleteDialog } from '../../../../../components/common/dictionary/dialog/delete';

interface BookingServiceCategoriesEditDialogDeleteAdapterProps {
  readonly isLoading: boolean;
  readonly data: BookingServiceCategory;
  readonly onClose: () => void;
  readonly onDelete: (bookingServiceCategory: BookingServiceCategory) => void;
}

export const BookingServiceCategoriesEditDialogDeleteAdapter = (
  props: BookingServiceCategoriesEditDialogDeleteAdapterProps
) => {
  const { isLoading, data, onDelete, onClose } = props;

  return (
    <DictionaryDeleteDialog
      isLoading={isLoading}
      onClose={onClose}
      onDelete={() => onDelete(data)}
    >
      Вы уверены, что хотите удалить <b>«{data.name}»</b>?
    </DictionaryDeleteDialog>
  );
};
