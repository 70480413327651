import CmsPreviewByContainerType from '../../preview/byContainerType';
import { CmsContainerTableCellCommonProps } from '../index';
import { Wrapper } from './controls';

type CmsContainerTableCellPreviewProps = CmsContainerTableCellCommonProps;

const CmsContainerTableCellPreview = (props: CmsContainerTableCellPreviewProps) => {
  const {
    cmsContainer: { linkedObjects, type },
  } = props;

  return (
    <Wrapper>
      <CmsPreviewByContainerType
        type={type!.code}
        cmsLinkedObjects={linkedObjects}
      />
    </Wrapper>
  );
};

export default CmsContainerTableCellPreview;
