import { combineReducers } from '@reduxjs/toolkit';
import byId, { CmsContainerByIdState } from './byId/store/slice';
import create, { CmsContainerCreateState } from './create/store/slice';
import details, { CmsContainerDetailsState } from './details/store/slice';
import table, { CmsContainerTableState } from './table/store/slice';

export interface CmsContainerState {
  byId: CmsContainerByIdState;
  create: CmsContainerCreateState;
  details: CmsContainerDetailsState;
  table: CmsContainerTableState;
}

export default combineReducers<CmsContainerState>({
  byId,
  create,
  details,
  table,
});
