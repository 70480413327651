import { Grid, Typography } from '@mui/material';
import moment from 'moment-timezone';
import { ReactNode, useState } from 'react';
import { EDateFormat } from '../../../../../domain/model/formats';
import { Nullable } from '../../../../../domain/model/types';
import Splitter from '../../../../components/common/splitter';
import { DatesRange, MPRangeCalendarPicker } from '../../../../theme/ui-kit/picker';
import BookingOrderEditItemInfoActions from './actions';
import { ItemsWrapper } from './controls';
import BookingOrderEditItemInfoQuantityItem from './item';
import BookingOrderEditItemInfoQuantityItemsHeader from './itemsHeader';

type BookingInfoByPeriod = {
  readonly period: DatesRange;
  readonly quantity: number;
};

interface BookingOrderEditItemInfoByPeriodProps {
  readonly value: BookingInfoByPeriod;
  readonly onApply: (value: BookingInfoByPeriod) => void;
  readonly onCancel: () => void;
}

const getPeriodLabel = (range: DatesRange): ReactNode => {
  const startDate = range.startDate && 'с ' + moment(range.startDate).format(EDateFormat.Human);
  const endDate = range.endDate && 'по ' + moment(range.endDate).format(EDateFormat.Human);
  return (
    <>
      {startDate && <Typography>{startDate}</Typography>}
      {endDate && <Typography>{endDate}</Typography>}
    </>
  );
};

const BookingOrderEditItemInfoByPeriod = (props: BookingOrderEditItemInfoByPeriodProps) => {
  const { value, onApply, onCancel } = props;

  const [period, setPeriod] = useState<DatesRange>(value.period);
  const [quantity, setQuantity] = useState<number>(value.quantity);

  const onInternalApply = () => {
    onApply({ period, quantity });
  };

  const onClear = () => {
    setPeriod({ startDate: null, endDate: null });
    setQuantity(1);
  };

  const onChangePeriod = (newPeriod?: Nullable<DatesRange>) => {
    setPeriod(newPeriod ?? { startDate: null, endDate: null });
  };

  const onChangeQuantity = (newQuantity: number) => {
    setQuantity(newQuantity);
  };

  const isEmpty = !period.startDate && !period.endDate;
  const isDisabled = !period.startDate || !period.endDate;

  return (
    <Grid
      container
      direction='column'
      spacing={3}
    >
      <Grid item>
        <MPRangeCalendarPicker
          minDate={new Date()}
          value={[period?.startDate ?? null, period?.endDate ?? null].filter(item => !!item) as any}
          onChange={onChangePeriod}
        />
      </Grid>

      <Grid item>
        <ItemsWrapper>
          <BookingOrderEditItemInfoQuantityItemsHeader />
          {!isEmpty && (
            <BookingOrderEditItemInfoQuantityItem
              quantity={quantity}
              label={getPeriodLabel(period)}
              onChange={onChangeQuantity}
            />
          )}
        </ItemsWrapper>
        {isEmpty && (
          <>
            <Splitter />
            <Typography
              color='error'
              variant='body2'
            >
              Выберите нужные даты, используя календарь
            </Typography>
          </>
        )}
      </Grid>
      <Grid item>
        <BookingOrderEditItemInfoActions
          isExecuting={false}
          disabled={isDisabled}
          onExecute={onInternalApply}
          onClear={onClear}
          onCancel={onCancel}
        />
      </Grid>
    </Grid>
  );
};

export default BookingOrderEditItemInfoByPeriod;
