import MasterActionsComponent from '@components/common/actions/master';
import { DefaultFooterWrapper } from '@components/common/wrappers/footer';
import { ClientOrgActionDetailsType } from '../../types';
import { useContextLifecycle } from '../hooks/useContextLifecycle';

const ClientOrgDetailsFooterAdapter = () => {
  const { actions, onAction } = useContextLifecycle();

  return (
    <MasterActionsComponent<ClientOrgActionDetailsType>
      show={!!actions.length}
      actions={actions}
      onAction={onAction}
      wrapper={DefaultFooterWrapper}
    />
  );
};

export default ClientOrgDetailsFooterAdapter;
