import { FC, useEffect, useState } from 'react';
import { AnyOffer } from '../../../../../../domain/model';
import { Nullable } from '../../../../../../domain/model/types';
import EntityMasterPreviewContentGrid from '../../../../../components/common/entityMaster/preview/contentGrid';
import { EEntityPreviewMode } from '../../../../../types';
import CmsCompilationPreviewBlock from './block';

interface CmsCompilationPreviewProps {
  readonly mode?: EEntityPreviewMode;
  readonly offers: Nullable<AnyOffer>[];
}

const calculateRatio = (mode: EEntityPreviewMode): number => {
  switch (mode) {
    case EEntityPreviewMode.Desktop:
      return 7 / 8;
    case EEntityPreviewMode.Mobile:
      return 7 / 8;
  }
};

const CmsCompilationPreview: FC<CmsCompilationPreviewProps> = props => {
  const { mode = EEntityPreviewMode.Desktop, offers } = props;

  const [[visibleCount, ratio, preserveAspectRatio, maxRowElementsCount], setViewParams] = useState<
    [number, number, boolean, number]
  >([0, 0, true, 0]);

  useEffect(() => {
    if (mode === EEntityPreviewMode.Desktop) {
      setViewParams([4, calculateRatio(mode), true, 4]);
    } else {
      setViewParams([4, calculateRatio(mode), false, 2]);
    }
  }, [offers.length, mode]);

  return (
    <EntityMasterPreviewContentGrid
      ratio={ratio}
      maxRowElementsCount={maxRowElementsCount}
      preserveAspectRatio={preserveAspectRatio}
    >
      {offers.slice(0, visibleCount).map((vi, i) => (
        <CmsCompilationPreviewBlock
          key={vi?.id ?? i}
          image={vi?.images?.[0]?.path}
        />
      ))}
    </EntityMasterPreviewContentGrid>
  );
};

export default CmsCompilationPreview;
