import { ContentTarget } from '../../../../../../domain/model';
import CheckBoxContent from '../../../../../components/common/checkBoxContent';
import { ContentTargetViewComponent } from '../../types';

const attribute: keyof ContentTarget = 'havingChildFamilyMemberOnly';

const ContentTargetViewHavingChildFamilyMemberOnly: ContentTargetViewComponent = props => {
  const { target } = props;

  const value = target[attribute] ?? false;

  return (
    <CheckBoxContent
      label='Только если есть член семьи ДО 18 лет'
      checked={value}
    />
  );
};

export default ContentTargetViewHavingChildFamilyMemberOnly;
