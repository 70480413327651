import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterValueItem,
  EDataFilterType,
} from '../../../../domain/model/filter';
import { Nullable } from '../../../../domain/model/types';
import { EPartnerEmployeeTableColumn } from './utils';

export enum EPartnerEmployeesFilterItem {
  Query = 'query',
  FirstName = 'firstName',
  LastName = 'lastName',
  MiddleName = 'middleName',
  Phone = 'phone',
  Email = 'email',
}

export type PartnerEmployeesFilterEditStrategy = DataFilterStrategyBase<EPartnerEmployeesFilterItem>;
export type PartnerEmployeesFilterValues = {
  readonly [EPartnerEmployeesFilterItem.Query]?: DataFilterValueItem<Nullable<string>>;
  readonly [EPartnerEmployeesFilterItem.FirstName]?: DataFilterValueItem<Nullable<string>>;
  readonly [EPartnerEmployeesFilterItem.LastName]?: DataFilterValueItem<Nullable<string>>;
  readonly [EPartnerEmployeesFilterItem.MiddleName]?: DataFilterValueItem<Nullable<string>>;
  readonly [EPartnerEmployeesFilterItem.Phone]?: DataFilterValueItem<Nullable<string>>;
  readonly [EPartnerEmployeesFilterItem.Email]?: DataFilterValueItem<Nullable<string>>;
};

export const partnerEmployeeFilterItems: Record<EPartnerEmployeeTableColumn, EPartnerEmployeesFilterItem[]> = {
  [EPartnerEmployeeTableColumn.Name]: [
    EPartnerEmployeesFilterItem.LastName,
    EPartnerEmployeesFilterItem.FirstName,
    EPartnerEmployeesFilterItem.MiddleName,
  ],
  [EPartnerEmployeeTableColumn.Phone]: [EPartnerEmployeesFilterItem.Phone],
  [EPartnerEmployeeTableColumn.Email]: [EPartnerEmployeesFilterItem.Email],
  [EPartnerEmployeeTableColumn.Owner]: [],
};

export const getPartnerEmployeesFilterStrategy = (
  filterItem: EPartnerEmployeesFilterItem,
  values: PartnerEmployeesFilterValues
): PartnerEmployeesFilterEditStrategy => {
  switch (filterItem) {
    case EPartnerEmployeesFilterItem.Query:
      return {
        type: EDataFilterType.String,
        key: EPartnerEmployeesFilterItem.Query,
        label: 'Поиск по ключевому слову',
        preview: values[EPartnerEmployeesFilterItem.Query]?.value || null,
        value: values[EPartnerEmployeesFilterItem.Query]?.value || null,
      };
    case EPartnerEmployeesFilterItem.LastName:
      return {
        type: EDataFilterType.String,
        key: EPartnerEmployeesFilterItem.LastName,
        label: 'Фамилия',
        preview:
          [
            values[EPartnerEmployeesFilterItem.FirstName]?.value,
            values[EPartnerEmployeesFilterItem.LastName]?.value,
            values[EPartnerEmployeesFilterItem.MiddleName]?.value,
          ]
            .filter(v => !!v)
            .join(' ')
            .trim() || null,
        value: values[EPartnerEmployeesFilterItem.LastName]?.value || null,
        group: 'fio',
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case EPartnerEmployeesFilterItem.MiddleName:
      return {
        type: EDataFilterType.String,
        key: EPartnerEmployeesFilterItem.MiddleName,
        label: 'Отчество',
        preview:
          [
            values[EPartnerEmployeesFilterItem.FirstName]?.value,
            values[EPartnerEmployeesFilterItem.LastName]?.value,
            values[EPartnerEmployeesFilterItem.MiddleName]?.value,
          ]
            .filter(v => !!v)
            .join(' ')
            .trim() || null,
        value: values[EPartnerEmployeesFilterItem.MiddleName]?.value || null,
        group: 'fio',
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case EPartnerEmployeesFilterItem.FirstName:
      return {
        type: EDataFilterType.String,
        key: EPartnerEmployeesFilterItem.FirstName,
        label: 'Имя',
        preview:
          [
            values[EPartnerEmployeesFilterItem.FirstName]?.value,
            values[EPartnerEmployeesFilterItem.LastName]?.value,
            values[EPartnerEmployeesFilterItem.MiddleName]?.value,
          ]
            .filter(v => !!v)
            .join(' ')
            .trim() || null,
        value: values[EPartnerEmployeesFilterItem.FirstName]?.value || null,
        group: 'fio',
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case EPartnerEmployeesFilterItem.Phone:
      return {
        type: EDataFilterType.String,
        key: EPartnerEmployeesFilterItem.Phone,
        label: 'Телефон',
        preview: values[EPartnerEmployeesFilterItem.Phone]?.value || null,
        value: values[EPartnerEmployeesFilterItem.Phone]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case EPartnerEmployeesFilterItem.Email:
      return {
        type: EDataFilterType.String,
        key: EPartnerEmployeesFilterItem.Email,
        label: 'Email',
        preview: values[EPartnerEmployeesFilterItem.Email]?.value || null,
        value: values[EPartnerEmployeesFilterItem.Email]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
  }
};
