import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '@/data/network/errorHandler';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable } from '@/data/store/types';
import { ClientOrg } from '@/domain/model/clientOrg';
import { Nullable, UUID } from '@/domain/model/types';
import clientOrgServices from '../../../services';

export const clientOrgManagementClientOrgByIdFetch = createAsyncThunk<ClientOrg, UUID, AppThunkAPIConfig>(
  'clientOrg/management/container/byId/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      return await clientOrgServices.clientOrg.one({ id, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export interface ClientOrgManagementContainerState {
  readonly menu: {
    readonly visible: boolean;
  };
  readonly clientOrg: Fetchable & {
    readonly data: Nullable<ClientOrg>;
    readonly needRefreshWatcher: number;
  };
}

type Reducer<T = undefined> = CaseReducer<ClientOrgManagementContainerState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ClientOrgManagementContainerState> {
  viewShowClientOrgManagementMenu: Reducer;
  viewHideClientOrgManagementMenu: Reducer;
}

const slice = createSlice<ClientOrgManagementContainerState, Reducers, 'clientOrg/management/container'>({
  name: 'clientOrg/management/container',
  initialState: {
    menu: {
      visible: true,
    },
    clientOrg: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      data: null,
      needRefreshWatcher: 0,
    },
  },
  reducers: {
    viewShowClientOrgManagementMenu(state) {
      state.menu.visible = true;
    },
    viewHideClientOrgManagementMenu(state) {
      state.menu.visible = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(clientOrgManagementClientOrgByIdFetch.pending, state => {
        state.clientOrg.isFetching = true;
        state.clientOrg.isFetched = false;
        state.clientOrg.isFailed = false;
        state.clientOrg.data = null;
      })
      .addCase(clientOrgManagementClientOrgByIdFetch.fulfilled, (state, { payload }) => {
        state.clientOrg.isFetching = false;
        state.clientOrg.isFetched = true;
        state.clientOrg.isFailed = false;

        state.clientOrg.data = payload;
      })
      .addCase(clientOrgManagementClientOrgByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.clientOrg.isFetching = false;
          state.clientOrg.isFetched = false;
          state.clientOrg.isFailed = true;
        } else {
          state.clientOrg.isFetching = false;
          state.clientOrg.isFetched = false;
          state.clientOrg.isFailed = false;
        }

        state.clientOrg.data = null;
      });
  },
});

export const { viewShowClientOrgManagementMenu, viewHideClientOrgManagementMenu } = slice.actions;

export default slice.reducer;
