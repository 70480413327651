import { Location } from 'history';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Nullable, UUID } from '../../../domain/model/types';
import rootRouting from '../../../routing';
import NotFoundScreen from '../../screens/notFound';
import PartnerEmployeeCreateScreen from '../../screens/userCreate/partnerEmployee';
import PartnerEmployeeDetailsScreen from '../../screens/userDetails/partnerEmployee';
import PartnerEmployeeEditScreen from '../../screens/userEdit/partnerEmployee';
import PartnerEmployeesScreen from '../../screens/users/partnerEmployees';
import { EPartnerEmployeeTableTab } from './table/utils';
import { EPartnerEmployeeStep } from './utils';

const routing = {
  root: `${rootRouting.employees}`,
  list: `${rootRouting.employees}`,
  create: `${rootRouting.employees}/create`,
  details: `${rootRouting.employees}/:id/details`,
  edit: `${rootRouting.employees}/:id/edit`,
};

export enum EPartnerEmployeeUrlParam {
  Tab = 'tab',
  Step = 'step',
}

export interface PartnerEmployeesLocationState {
  readonly guid: UUID;
}

interface GetPartnerEmployeesTableRouteProps {
  readonly tab?: Nullable<EPartnerEmployeeTableTab>;
  readonly guid?: UUID;
}

interface GetPartnerEmployeeCreateRouteProps {
  readonly step?: EPartnerEmployeeStep;
}

interface GetPartnerEmployeeDetailsRouteProps {
  readonly id: UUID;
  readonly step?: EPartnerEmployeeStep;
}

interface GetPartnerEmployeeEditRouteProps {
  readonly id: UUID;
  readonly step?: EPartnerEmployeeStep;
}

export const getPartnerEmployeeCreateRoute = ({
  step = EPartnerEmployeeStep.Profile,
}: GetPartnerEmployeeCreateRouteProps) => {
  const searchParams = new URLSearchParams();

  searchParams.append(EPartnerEmployeeUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.create}${search}`;
};

export const getPartnerEmployeeDetailsRoute = ({
  id,
  step = EPartnerEmployeeStep.Profile,
}: GetPartnerEmployeeDetailsRouteProps) => {
  const searchParams = new URLSearchParams();

  searchParams.append(EPartnerEmployeeUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.details.replace(':id', id)}${search}`;
};

export const getPartnerEmployeeEditRoute = ({
  id,
  step = EPartnerEmployeeStep.Profile,
}: GetPartnerEmployeeEditRouteProps) => {
  const searchParams = new URLSearchParams();

  searchParams.append(EPartnerEmployeeUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.edit.replace(':id', id)}${search}`;
};

export const getPartnerEmployeesTableRoute = ({
  tab,
  guid,
}: GetPartnerEmployeesTableRouteProps): Location<PartnerEmployeesLocationState> => {
  const searchParams = new URLSearchParams();
  if (tab) {
    searchParams.append(EPartnerEmployeeUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return {
    pathname: `${routing.list}`,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    key: '',
    hash: '',
  };
};

const PartnerEmployeeEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.list}
        component={PartnerEmployeesScreen}
      />
      <Route
        exact
        path={routing.create}
        component={PartnerEmployeeCreateScreen}
      />
      <Route
        exact
        path={routing.details}
        component={PartnerEmployeeDetailsScreen}
      />
      <Route
        exact
        path={routing.edit}
        component={PartnerEmployeeEditScreen}
      />
      <Redirect
        exact
        from={routing.root}
        to={routing.list}
      />

      <Route component={NotFoundScreen} />
    </Switch>
  );
};

export default PartnerEmployeeEntry;
