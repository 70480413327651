import ContentLoader from '../../../../components/common/loader';
import { MPButton } from '../../../../theme/ui-kit/button';
import { ActionsWrapper } from './controls';

interface BookingOrderEditItemInfoActionsProps {
  readonly disabled: boolean;
  readonly isExecuting: boolean;
  readonly onExecute: () => void;
  readonly onClear: () => void;
  readonly onCancel: () => void;
}

const BookingOrderEditItemInfoActions = (props: BookingOrderEditItemInfoActionsProps) => {
  const { disabled, isExecuting, onExecute, onClear, onCancel } = props;

  return (
    <ActionsWrapper>
      <MPButton
        variant='text'
        fullWidth={false}
        disabled={isExecuting}
        onClick={onClear}
      >
        Очистить
      </MPButton>
      <MPButton
        variant='text'
        fullWidth={false}
        disabled={isExecuting}
        onClick={onCancel}
      >
        Отменить
      </MPButton>
      <MPButton
        variant='text'
        fullWidth={false}
        disabled={isExecuting || disabled}
        onClick={onExecute}
      >
        Применить
        {isExecuting && <ContentLoader />}
      </MPButton>
    </ActionsWrapper>
  );
};

export default BookingOrderEditItemInfoActions;
