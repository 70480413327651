import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ETeamMemberStatus } from '../../../../domain/model/enums';
import { TeamMember } from '../../../../domain/model/team';
import { Nullable, UUID } from '../../../../domain/model/types';
import { SportUserProfile } from '../../../../domain/model/user';
import { getSportsmanDetailsRoute } from '../../sportsman/entry';
import { getTeamEditRoute, getTeamRoute } from '../entry';
import Team from './component';
import {
  teamGetApproveApplicationSelector,
  teamGetByIdSelector,
  teamGetInvitesSelector,
  teamGetMembersSelector,
  teamGetNeedRefreshWatcherSelector,
  teamGetRejectApplicationSelector,
  teamGetRevokeInviteSelector,
  teamGuidSelector,
} from './store/selectors';
import { teamByIdFetch, teamInvitesFetch, teamMembersFetch, teamSetInvites, teamStartSession } from './store/slice';
import { ETeamTab } from './utils';

interface TeamContainerProps {
  readonly guid: UUID;
  readonly id: UUID;
  readonly tab: ETeamTab;
  readonly canEdit: boolean;
  readonly canChangeMembers: boolean;
}

const TeamContainer = ({ guid, id, tab, canEdit, canChangeMembers }: TeamContainerProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentGuid = useSelector(teamGuidSelector);
  const needRefreshWatcher = useSelector(teamGetNeedRefreshWatcherSelector);
  const { data: team, isFetching: teamIsFetching } = useSelector(teamGetByIdSelector);
  const { data: members, isFetching: membersIsFetching } = useSelector(teamGetMembersSelector);
  const { isFetching: invitesIsFetching } = useSelector(teamGetInvitesSelector);
  const { isFetching: revokeInviteIsFetching } = useSelector(teamGetRevokeInviteSelector);
  const { isFetching: rejectApplicationIsFetching } = useSelector(teamGetRejectApplicationSelector);
  const { isFetching: approveApplicationIsFetching } = useSelector(teamGetApproveApplicationSelector);

  const isFetching =
    teamIsFetching ||
    membersIsFetching ||
    invitesIsFetching ||
    revokeInviteIsFetching ||
    rejectApplicationIsFetching ||
    approveApplicationIsFetching;

  const onTeamEdit = () => {
    history.push(getTeamEditRoute({ id }));
  };

  const onMemberClick = (member: TeamMember) => {
    const { id: userId } = member.userProfile;
    history.push(getSportsmanDetailsRoute({ id: userId }));
  };

  const onChangeTab = (newTab: ETeamTab) => {
    history.replace(getTeamRoute({ guid: currentGuid, id: team!.id, tab: newTab }));
  };

  const onAddMember = useCallback(
    (sportsmen: Nullable<SportUserProfile[]>) => {
      dispatch(teamSetInvites(sportsmen));
    },
    [dispatch]
  );

  // сброс текущих данных
  useEffect(() => {
    dispatch(teamStartSession(guid));
  }, [dispatch, guid]);

  // первоначальная загрузка
  useEffect(() => {
    if (guid !== currentGuid) {
      const requests: any = [];

      requests.push(
        dispatch(teamByIdFetch({ id })),
        dispatch(teamMembersFetch({ id, status: ETeamMemberStatus.Active })),
        dispatch(teamInvitesFetch({ id }))
      );

      return () => {
        requests.forEach((request: any) => request.abort());
      };
    }
  }, [dispatch, id, guid]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      const requests: any = [];

      requests.push(
        dispatch(teamByIdFetch({ id })),
        dispatch(teamMembersFetch({ id, status: ETeamMemberStatus.Active })),
        dispatch(teamInvitesFetch({ id }))
      );

      return () => {
        requests.forEach((request: any) => request.abort());
      };
    }
  }, [dispatch, id, needRefreshWatcher]);

  if (!team) {
    return null;
  }

  return (
    <Team
      guid={guid}
      tab={tab}
      team={team}
      members={members}
      canChangeMembers={canChangeMembers}
      isFetching={isFetching}
      onTeamEdit={canEdit ? onTeamEdit : null}
      onChangeTab={onChangeTab}
      onMemberClick={onMemberClick}
      onAddMember={onAddMember}
    />
  );
};

export default TeamContainer;
