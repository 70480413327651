import { EOfferType, EPeriod, Nullable, Partner, PartnerShort } from '@/domain';
import { ReactElement } from 'react';

export interface ReportPeriodItem {
  readonly id: EPeriod;
  readonly name: string;
}

export interface ReportOfferTypeItem {
  readonly id: EOfferType;
  readonly name: string;
}

export interface ReportSportPeriodTypeItem {
  readonly id: EReportSportPeriodType;
  readonly name: string;
}

interface ReportRequestCommon {
  readonly dateFrom: Nullable<Date>;
  readonly dateTo: Nullable<Date>;
}

export interface ReportOffersRequest extends ReportRequestCommon {
  readonly offerType: Nullable<EOfferType>;
  readonly partner: Nullable<Partner>;
}

export interface ReportPartnersRequest extends ReportRequestCommon {
  readonly offerType: Nullable<EOfferType>;
}

export interface ReportUsersRequest extends ReportRequestCommon {
  readonly offerType: Nullable<EOfferType>;
  readonly partner: Nullable<Partner>;
}

export interface ReportFilter {
  readonly partner?: Nullable<PartnerShort>;
  readonly offer: Nullable<ReportOfferTypeItem>;
  readonly startDate: Nullable<string>;
  readonly endDate: Nullable<string>;
}

export enum EReportActionType {
  OffersStatistic = 'offer_statistic',
  Offers = 'offer',
  Users = 'users',
  Partners = 'partners',
  BookingOrdersStatistic = 'booking_orders_statistic',
}

export enum EReportType {
  OffersStatistic = 'offer_statistic',
  Offers = 'offer',
  Users = 'users',
  Partners = 'partners',
  BookingOrdersStatistic = 'booking_orders_statistic',
  SportUsers = 'sport_users',
  Events = 'events',
  EventsDetailed = 'events_detailed',
}

export enum EReportSportActionType {
  Users = 'users',
  Events = 'events',
  EventsDetailed = 'events_detailed',
}

export enum EReportDownloadStatus {
  Pending = 'Pending',
  Finished = 'Finished',
}

export enum EReportSportPeriodType {
  DateEvent = 'dateEvent',
  DateUserRegistration = 'dateUserRegistration',
}

export enum EReportsTableColumn {
  Name = 'name',
  Description = 'description',
}

export type ReportsTableStubAdapterResult = {
  readonly canViewTable: boolean;
};

export type ReportsTableStubAdapterProps = {
  readonly children: (props: ReportsTableStubAdapterResult) => ReactElement<any, any>;
};

export type ReportsTableStubAdapterType = (props: ReportsTableStubAdapterProps) => ReactElement<any, any>;
