import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BookingOfferShort } from '../../../../../domain/model/booking';
import { EBookingActionType } from '../../types';
import BookingActionsConfirmDialog from '../components/confirmDialog';
import {
  bookingOfferActionsDialogResumeSelector,
  getBookingOfferActionsOfferIsProcessingSelector,
} from '../store/selectors';
import { bookingOfferActionsResume } from '../store/slice';
import { useBookingOfferActions } from '../useActions';
import { showBookingOfferActionNotification } from '../utils';

const BookingOfferActionsDialogResumeAdapter = () => {
  const dispatch = useDispatch();

  const handlers = useBookingOfferActions();

  const object = useSelector(bookingOfferActionsDialogResumeSelector);

  const isProcessing = useSelector(getBookingOfferActionsOfferIsProcessingSelector(object?.id ?? null));

  const onResume = useCallback(
    (offer: BookingOfferShort) => {
      dispatch(
        bookingOfferActionsResume({
          id: offer.id,
        })
      )
        .unwrap()
        .then(response => {
          handlers.onChangeDialogState('resume', null);
          showBookingOfferActionNotification(response, EBookingActionType.Resume, false);
        });
    },
    [dispatch, handlers.onChangeDialogState]
  );

  const onCloseDialog = useCallback(() => {
    handlers.onChangeDialogState('resume', null);
  }, [handlers.onChangeDialogState]);

  return (
    object && (
      <BookingActionsConfirmDialog
        offer={object}
        title='Возобновить публикацию объекта?'
        actionText='Возобновить'
        onAction={offer => onResume(offer)}
        isFetching={isProcessing}
        onClose={onCloseDialog}
      />
    )
  );
};

export default BookingOfferActionsDialogResumeAdapter;
