import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../data/store/store';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { EProductOrderActionType } from '../../types';
import { ProductOrderActionsState } from './slice';

export const getProductOrderActionsOfferIsProcessingSelector = (id: Nullable<UUID>) =>
  createSelector(
    (store: RootState) => store.productOrder.actions.actions,
    (store: RootState) => store.productOrder.actions.create.isFetching,
    (actions, isCreating) => {
      return id ? actions.some(action => action.id === id && action.isFetching) : isCreating;
    }
  );

export const productOrderActionsDialogByNameSelector =
  (name: keyof ProductOrderActionsState['dialogs']) => (store: RootState) =>
    store.productOrder.actions.dialogs[name];

export const isProductOrdersAnyChangedSelector = (store: RootState) =>
  store.productOrder.actions.actions.filter(action => !action.isFetching).length;

export const getIsProductOrderAnyActionByTypeExecutedSelector = (
  id: Nullable<UUID>,
  ...actions: EProductOrderActionType[]
) =>
  createSelector(
    () => id,
    () => actions,
    (store: RootState) => store.productOrder.actions.actions,
    (id, argActions, storeActions) => {
      return id
        ? storeActions.find(action => action.id === id && action.isFetched && argActions.some(a => a === action.type))
            ?.type ?? null
        : null;
    }
  );
