import React from 'react';
import { Wrapper } from './controls';

interface TabsPanelLabelProps {
  readonly title: string;
  readonly children?: React.ReactNode;
}

export const TabsPanelLabel = (props: TabsPanelLabelProps) => {
  const { title, children } = props;

  return (
    <Wrapper>
      {title}
      {children}
    </Wrapper>
  );
};

export default TabsPanelLabel;
