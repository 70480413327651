import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { CmsContainer } from '../../../../../../domain/model/cms';
import { CmsContainerTableTab, ECmsContainerTableTab, getCmsContainerTableTabs } from '../utils';

type UseCmsContainersTableConfigProps = {
  readonly canCreate?: boolean;
  readonly onCmsContainerOpenView: (cmsContainer: CmsContainer) => void;
  readonly onCmsContainerOpenEdit: (cmsContainer: CmsContainer) => void;
};

export type UseCmsContainersTableConfig = {
  readonly tabs: CmsContainerTableTab[];
  readonly defaultTab: ECmsContainerTableTab;
  readonly onCmsContainerClick: (cmsContainer: CmsContainer) => void;
  readonly onCmsContainerOpenView: (cmsContainer: CmsContainer) => void;
  readonly onCmsContainerOpenEdit: (cmsContainer: CmsContainer) => void;
};

export function useCmsContainersTableConfig(props: UseCmsContainersTableConfigProps): UseCmsContainersTableConfig {
  const { canCreate, onCmsContainerOpenView, onCmsContainerOpenEdit } = props;

  const history = useHistory();

  const onCmsContainerClick = useCallback(
    (cmsContainer: CmsContainer) => {
      onCmsContainerOpenView(cmsContainer);
    },
    [history, onCmsContainerOpenView]
  );

  const tabs = useMemo(() => getCmsContainerTableTabs({ canCreate }), [canCreate]);
  const defaultTab = ECmsContainerTableTab.Active;

  return {
    tabs,
    defaultTab,
    onCmsContainerClick,
    onCmsContainerOpenView,
    onCmsContainerOpenEdit,
  };
}
