import { AdCampaign } from '@/domain';
import React from 'react';
import { PanelAction } from '../../../types';
import { AdCampaignLifeCycle, AdCampaignLifeCycleRules } from '../lifecycle/types';
import { AdCampaignActionDetailsType, AdCampaignActions, AdCampaignDetailsStep, EAdCampaignActionType } from '../types';
import { AdCampaignDetailsContainerProps } from './container';
import { AdCampaignDetailsState } from './store/slice';

export type AdCampaignDetailsParamsContextValue = AdCampaignDetailsContainerProps;

export const AdCampaignDetailsParamsContext = React.createContext<AdCampaignDetailsParamsContextValue>(
  {} as AdCampaignDetailsParamsContextValue
);

export type AdCampaignDetailsLifecycleContextValue = {
  readonly lifecycle: AdCampaignLifeCycle;
  readonly lifeCycleRules: AdCampaignLifeCycleRules;
  readonly actions: AdCampaignActions<AdCampaignActionDetailsType>;
  readonly onAction: (action: PanelAction<AdCampaignActionDetailsType>) => void;
};

export const AdCampaignDetailsLifecycleContext = React.createContext<AdCampaignDetailsLifecycleContextValue>(
  {} as AdCampaignDetailsLifecycleContextValue
);

export type AdCampaignDetailsHandlersContextValue = {
  readonly onRefresh: () => void;
  readonly onChangeDialogState: (
    name: keyof AdCampaignDetailsState['dialogs'],
    adCampaign: Nullable<AdCampaign>
  ) => void;

  readonly onChangeStep: (step: AdCampaignDetailsStep) => void;
  readonly onAdCampaignOpenEdit: (adCampaign: AdCampaign) => void;
  readonly onAdCampaignSwitchToEdit: (adCampaign: AdCampaign) => void;
  readonly onAdCampaignClose: (action: Nullable<EAdCampaignActionType>) => void;
  readonly onAdCampaignRefetch: (adCampaign: AdCampaign) => Promise<AdCampaign>;

  readonly onAdCampaignTryArchive: () => void;
  readonly onAdCampaignTryPause: () => void;
  readonly onAdCampaignTryResume: () => void;
};

export const AdCampaignDetailsHandlersContext = React.createContext<AdCampaignDetailsHandlersContextValue>(
  {} as AdCampaignDetailsHandlersContextValue
);
