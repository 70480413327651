import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EFilterMultiSelectorValueType, EMultiSelectorValueType } from '../../../../../../domain/model/enums';
import { DataFilterStrategySportOptionsSelector } from '../../../../../../domain/model/filter';
import { Nullable } from '../../../../../../domain/model/types';
import SportOptionMultipleTypesSelector from '../../../../../features/general/contentTarget/selectors/sportOption';
import { SportOptionMultipleSelectType } from '../../../../../features/general/contentTarget/selectors/sportOption/types';
import { DataFilterEditItemProps } from '../index';

type ValueType<T extends string> = DataFilterStrategySportOptionsSelector<T>['value'];

interface DataFilterEditItemListMultipleTypesProps<T extends string>
  extends DataFilterEditItemProps<T, Nullable<ValueType<T>>> {
  readonly strategy: DataFilterStrategySportOptionsSelector<T>;
}

function DataFilterEditItemListMultipleTypes<T extends string>({
  strategy,
  onChange,
}: DataFilterEditItemListMultipleTypesProps<T>) {
  const { label, selectAllLabel, value, items, readOnly } = strategy;

  const multipleSelectPredicate = value ?? { select: EMultiSelectorValueType.In, in: null };

  const select =
    multipleSelectPredicate.select === EFilterMultiSelectorValueType.All
      ? SportOptionMultipleSelectType.All
      : multipleSelectPredicate.select === EFilterMultiSelectorValueType.None
      ? SportOptionMultipleSelectType.None
      : null;

  const onChangeValue = (newValue: string[]) => {
    if (newValue.length === items.length) {
      onChange(strategy, { select: EFilterMultiSelectorValueType.All, in: null });
    } else {
      if (newValue.length) {
        onChange(strategy, { select: EFilterMultiSelectorValueType.In, in: newValue.length > 0 ? newValue : null });
      } else {
        onChange(strategy, null);
      }
    }
  };

  const onSelectNone = () => {
    onChange(strategy, { select: EFilterMultiSelectorValueType.None, in: null });
  };

  return (
    <SportOptionMultipleTypesSelector
      selectNone
      label={label}
      selectAllLabel={selectAllLabel}
      color='primary'
      select={select}
      values={multipleSelectPredicate.in ?? []}
      allValues={items}
      limitTags={2}
      disabled={readOnly}
      popupIcon={<ExpandMoreIcon fontSize='small' />}
      onChange={onChangeValue}
      onSelectNone={onSelectNone}
    />
  );
}

export default DataFilterEditItemListMultipleTypes;
