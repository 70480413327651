import { BookingOffer } from '../../../../domain/model/booking';
import { ENoticeStatus, EOfferServiceStatus, EOfferStatus } from '../../../../domain/model/enums';
import { Nullable } from '../../../../domain/model/types';
import Notifier from '../../../../system/notifier';
import { BookingServicePriceItemView } from '../components/fieldsEdit/types';
import { BookingOfferServiceView, BookingOfferView } from '../types';

interface ValidPriceItem extends Omit<BookingServicePriceItemView, 'price'> {
  readonly price: number;
}

const getServicesMinDisplayPriceItem = (services: BookingOfferServiceView[]): Nullable<BookingServicePriceItemView> => {
  const validPriceItems: ValidPriceItem[] = services
    .flatMap(service => service.priceItems)
    .filter(item => item.price !== null) as ValidPriceItem[];

  return validPriceItems.length
    ? validPriceItems.reduce<ValidPriceItem>(
        (minPriceItem, priceItem) => (priceItem.price > minPriceItem.price ? minPriceItem : priceItem),
        validPriceItems[0]
      )
    : null;
};

export const getBookingOfferDisplayPriceItem = (
  bookingOffer: BookingOfferView
): Nullable<BookingServicePriceItemView> => {
  const enabledServices = bookingOffer.services.filter(service => service.status === EOfferServiceStatus.Enabled);

  return (
    getServicesMinDisplayPriceItem(enabledServices.filter(service => service.category?.displayPrice)) ??
    getServicesMinDisplayPriceItem(enabledServices)
  );
};

export const isBookingOfferDraftSavable = (status: EOfferStatus): boolean => {
  return [EOfferStatus.Draft, EOfferStatus.Rejected].includes(status);
};

/**
 * показать нотификатор об успешном выполнении действия с объектом ДиО
 * @param event BookingOfferView | BookingOffer
 * @param nextStatus если значение не передано, предполагается что статус не меняется относительно текущего, или пока статус не присвоен
 * */
export const showBookingOfferActionSuccessNotification = (
  bookingOffer: BookingOfferView | BookingOffer,
  nextStatus?: EOfferStatus
) => {
  let text;

  if (!bookingOffer.status && !nextStatus) {
    text = 'Черновик объекта Досуга и Отдыха сохранён';
  } else {
    switch (nextStatus) {
      case EOfferStatus.WaitingForModeration:
        text = 'Объект Досуга и Отдыха отправлен на модерацию';
        break;
      default:
        text = 'Изменения сохранены';
        break;
    }
  }

  Notifier.getInstance().addNotice(ENoticeStatus.Success, text);
};

export const showBookingOfferInvalidNotification = (bookingOffer: BookingOfferView, nextStatus?: EOfferStatus) => {
  let text;

  if (!bookingOffer.status && !nextStatus) {
    text = 'Заполните корректно поля, чтобы сохранить черновик';
  } else {
    switch (nextStatus) {
      case EOfferStatus.WaitingForModeration:
        text = 'Заполните все обязательные поля, чтобы опубликовать объект';
        break;
      default:
        text = 'Заполните все обязательные поля, чтобы сохранить изменения';
        break;
    }
  }

  Notifier.getInstance().addNotice(ENoticeStatus.Warning, text);
};

export const showBookingOfferErrorNotification = (error: string[]) => {
  Notifier.getInstance().addNotice(ENoticeStatus.Warning, error.join(';, \n'));
};
