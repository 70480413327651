import CorpActivationTableCell, { CorpActivationTableCellCommonProps } from './index';

const CorpActivationTableCellCustomerPhone = (props: CorpActivationTableCellCommonProps) => {
  const {
    corpActivation: {
      customer: { phone },
    },
  } = props;

  return (
    <CorpActivationTableCell
      {...props}
      value={phone ?? ''}
    />
  );
};

export default CorpActivationTableCellCustomerPhone;
