import TabsPanelCounter from '../../../../../../components/common/tabs/counter/counter';
import TabsPanelLabel from '../../../../../../components/common/tabs/label/label';
import { TabsCounter } from '../../../../../../components/common/tabs/types';
import { EProductTableTab } from '../../../../../products/table/utils';
import { EOfferTableTab } from '../../../types';

interface GetCountsLabel {
  readonly count?: number;
  readonly countUnwatched?: number;
}

const getCountsLabel = ({ count, countUnwatched }: GetCountsLabel) => {
  const countLabel = count !== undefined ? count.toString(10) : '';
  const countUnwatchedLabel = countUnwatched ? ` (${countUnwatched})` : '';
  return countLabel + countUnwatchedLabel;
};

interface OfferTableTabProps<T extends EOfferTableTab | EProductTableTab> {
  readonly tab: T;
  readonly counts: TabsCounter<T>;
  readonly unwatchedCounts: TabsCounter<T>;
  readonly getTabName: (tab: T) => string;
}

export const OfferTableTab = <T extends EOfferTableTab | EProductTableTab>({
  tab,
  counts,
  unwatchedCounts,
  getTabName,
}: OfferTableTabProps<T>) => {
  const count = counts[tab];
  const countUnwatched = unwatchedCounts[tab];

  return (
    <TabsPanelLabel title={getTabName(tab)}>
      <TabsPanelCounter count={getCountsLabel({ count, countUnwatched })} />
    </TabsPanelLabel>
  );
};
