import { combineReducers } from '@reduxjs/toolkit';
import editControl, { ActivityTypeEditControlState } from './editControl/store/slice';

interface ActivityTypeState {
  editControl: ActivityTypeEditControlState;
}

export default combineReducers<ActivityTypeState>({
  editControl,
});
