import { Chip } from '@mui/material';
import { Address } from '../../../../../../domain/model/address';
import { MPAutocompleteMultipleSelect } from '../../../../../theme/ui-kit/autocomplete/multiple';
import { AddressHelper } from '../../../../../utils/address';
import { AddressSelectorProps } from '../types';
import useAddressSelector from '../useAddressSelector';

export interface AddressMultipleSelectorProps extends AddressSelectorProps<Address[]> {
  readonly limitTags?: number;
  readonly color?: 'default' | 'primary' | 'secondary' | undefined;
  readonly getTagLabel?: (address: Address) => string;
}

const getOptionLabelDefault = (address: Address) => new AddressHelper(address).getFullPath();
const filterOptionsDefault = (options: Address[]) => options;

const AddressMultipleSelector = (props: AddressMultipleSelectorProps) => {
  const {
    count,
    limitTags,
    onlyValidPostalCode,
    level,
    fromLevel,
    toLevel,
    value,
    locations,
    filterBy,
    sortBy,
    groupBy,
    color,
    onChange,
    getTagLabel = getOptionLabelDefault,
    getOptionLabel = getOptionLabelDefault,
    filterOptions = filterOptionsDefault,
    ...others
  } = props;

  const { suggestions, isLoading, onSearch } = useAddressSelector(props);

  return (
    <MPAutocompleteMultipleSelect<Address>
      {...others}
      value={value ?? []}
      options={suggestions}
      isLoading={isLoading}
      filterOptions={filterOptions}
      groupBy={groupBy?.(suggestions)}
      getOptionLabel={getOptionLabel}
      onSearchValue={onSearch}
      onChangeValue={onChange}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            component={'div'}
            color={color}
            label={getTagLabel(option)}
            {...getTagProps({ index })}
            key={option.id}
          />
        ))
      }
    />
  );
};

export default AddressMultipleSelector;
