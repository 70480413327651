import { combineReducers } from '@reduxjs/toolkit';
import edit, { ActivityTypesEditState } from './edit/store/slice';

interface ActivityTypeState {
  edit: ActivityTypesEditState;
}

export default combineReducers<ActivityTypeState>({
  edit,
});
