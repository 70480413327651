import { MPFormInput } from '../../../../../theme/ui-kit/input';
import { BookingOfferEditFieldServicePriceItemProps } from '../types';

const fieldName = 'name';

const BookingOfferEditFieldServicePriceItemName = (
  props: BookingOfferEditFieldServicePriceItemProps<typeof fieldName>
) => {
  const { value, validation, disabled, onChangeAttribute, onValidateAttribute } = props;

  return (
    <MPFormInput
      label='Название тарифа'
      value={value}
      disabled={disabled}
      error={validation?.hasError}
      helperText={validation?.message}
      onChange={event => onChangeAttribute(fieldName, event.target.value)}
      onBlur={() => onValidateAttribute(fieldName)}
    />
  );
};

export default BookingOfferEditFieldServicePriceItemName;
