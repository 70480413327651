import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { OfferCategory } from '../../../../../../domain/model';
import { Nullable } from '../../../../../../domain/model/types';
import { DictionaryAddDialog } from '../../../../../components/common/dictionary/dialog/add';
import { MPFormInput } from '../../../../../theme/ui-kit/input';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingOfferCategoriesEditAddDialogStateSelector } from '../store/selectors';

interface BookingOfferCategoriesEditDialogAddAdapterInternalProps {
  readonly parent: Nullable<OfferCategory>;
}

const BookingOfferCategoriesEditDialogAddAdapterInternal = (
  props: BookingOfferCategoriesEditDialogAddAdapterInternalProps
) => {
  const { parent } = props;

  const handlers = useContextHandlers();

  const [error, setError] = useState<Nullable<string>>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [value, setValue] = useState<OfferCategory>({
    id: '',
    name: '',
    parentId: parent?.id ?? null,
  });

  const onAdd = async () => {
    if (!value.name) {
      setError('Введите название');
    } else {
      setIsLoading(true);
      await handlers
        .onCreate(value)
        .then(() => {
          handlers.onChangeDialogState('add', null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onClose = () => {
    setIsLoading(false);
    handlers.onChangeDialogState('add', null);
  };

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setError(null);
    setValue(prev => ({ ...prev, name: event.target.value }));
  };

  useEffect(() => {
    return () => {
      setError(null);
      setIsLoading(false);
    };
  }, []);

  return (
    <DictionaryAddDialog
      isLoading={isLoading}
      onClose={onClose}
      onSave={onAdd}
    >
      <MPFormInput
        disabled={isLoading}
        label='Название'
        value={value.name}
        error={error !== null}
        helperText={error}
        inputRef={input => input && input.focus()}
        onChange={onChange}
      />
    </DictionaryAddDialog>
  );
};

export const BookingOfferCategoriesEditDialogAddAdapter = () => {
  const data = useSelector(bookingOfferCategoriesEditAddDialogStateSelector);

  if (!data) {
    return null;
  }

  return <BookingOfferCategoriesEditDialogAddAdapterInternal parent={data.parent?.data ?? null} />;
};
