import { useMemo } from 'react';
import { Nullable } from '../../../../../domain/model/types';
import { CmsContainerLifeCycle } from '../lifecycle/types';
import {
  ActionCmsContainerType,
  CmsContainerActionCreateType,
  CmsContainerActions,
  ECmsContainerActionType,
} from '../types';
import {
  filterCmsContainerCreateActions,
  makeCmsContainerActionPrimary,
  removeCmsContainerAction,
  renameCmsContainerAction,
} from '../utils/actions';

type CmsContainerType = ActionCmsContainerType;

type UseCmsContainerCreateActions = CmsContainerActions<CmsContainerActionCreateType>;

interface UseCmsContainerCreateActionsProps {
  readonly allowedActions: CmsContainerActions;
  readonly lifecycle: CmsContainerLifeCycle;
  readonly cmsContainer: Nullable<CmsContainerType>;
  readonly isLastStep: boolean;
}

const useCmsContainerCreateActions = (props: UseCmsContainerCreateActionsProps): UseCmsContainerCreateActions => {
  const { allowedActions, lifecycle, cmsContainer, isLastStep } = props;

  const lifeCycleRules = useMemo(
    () =>
      lifecycle.build({
        allowedActions,
        cmsContainer,
      }),
    [allowedActions, cmsContainer]
  );

  if (!cmsContainer) {
    return [];
  }

  const { nextActions } = lifeCycleRules;
  let createActions = filterCmsContainerCreateActions(nextActions);

  // меняем текст на кнопке сохранения
  if (cmsContainer.id) {
    renameCmsContainerAction(createActions, ECmsContainerActionType.Save, 'Сохранить и закрыть');
  } else {
    renameCmsContainerAction(createActions, ECmsContainerActionType.Save, 'Сохранить как черновик');
  }

  // кнопки публикации и возобновления показываем только на последнем шаге
  if (!isLastStep) {
    removeCmsContainerAction(createActions, ECmsContainerActionType.Publish);
  }

  // помечаем праймари экшоны
  createActions = makeCmsContainerActionPrimary(createActions, [ECmsContainerActionType.Publish]);

  return createActions;
};

export default useCmsContainerCreateActions;
