import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataFilterComponent from '../../../../../components/common/dataFilter';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../../hooks/useDataFilterAdapter';
import { EAnalyticsQueriesTableFilterItem } from '../../filterUtils';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  analyticsQueriesFilterSelector,
  analyticsQueriesGuidSelector,
  analyticsQueriesSortColumnSelector,
} from '../store/selectors';
import { EAnalyticsQueriesTableColumn } from '../utils';

interface AnalyticsQueriesTableFilterAdapterProps {
  readonly filterAdapter: DataFilterAdapter<EAnalyticsQueriesTableColumn, EAnalyticsQueriesTableFilterItem>;
}

export const AnalyticsQueriesTableFilterAdapter = (props: AnalyticsQueriesTableFilterAdapterProps) => {
  const { filterAdapter } = props;

  const handlers = useContextHandlers();

  const guid = useSelector(analyticsQueriesGuidSelector);
  const sortColumn = useSelector(analyticsQueriesSortColumnSelector);
  const filter = useSelector(analyticsQueriesFilterSelector);

  const { filterStrategies } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return useMemo(
    () => (
      <DataFilterComponent
        strategies={filterStrategies}
        onChange={handlers.onChangeFilter}
      />
    ),
    [filterStrategies, handlers.onChangeFilter]
  );
};
