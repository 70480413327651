import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterStrategyView,
  DataFilterValueItem,
  EDataFilterType,
  EDateFormat,
  Nullable,
} from '@/domain';
import moment from 'moment';
import { ECustomerTableColumn } from './utils';

export enum ECustomersFilterItem {
  Query = 'query',
  LastName = 'lastName',
  FirstName = 'firstName',
  MiddleName = 'middleName',
  CreatedAt = 'createdAt',
  PersonnelNumber = 'pernr',
  Summ = 'summ',
  OrderCount = 'orderCount',
  Phone = 'phone',
  Email = 'email',
}

export const customerTableFilterItems: Record<ECustomerTableColumn, ECustomersFilterItem[]> = {
  [ECustomerTableColumn.Date]: [ECustomersFilterItem.CreatedAt],
  [ECustomerTableColumn.Name]: [
    ECustomersFilterItem.LastName,
    ECustomersFilterItem.FirstName,
    ECustomersFilterItem.MiddleName,
  ],
  [ECustomerTableColumn.Phone]: [ECustomersFilterItem.Phone],
  [ECustomerTableColumn.Email]: [ECustomersFilterItem.Email],
  [ECustomerTableColumn.PersonnelNumber]: [ECustomersFilterItem.PersonnelNumber],
};

export type CustomersFilterEditStrategy = DataFilterStrategyBase<ECustomersFilterItem>;
export type CustomersFilterViewStrategy = DataFilterStrategyView<ECustomersFilterItem>;
export type CustomersFilterValues = {
  readonly [ECustomersFilterItem.Query]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECustomersFilterItem.LastName]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECustomersFilterItem.FirstName]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECustomersFilterItem.MiddleName]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECustomersFilterItem.CreatedAt]?: DataFilterValueItem<Nullable<[string, string]>>;
  readonly [ECustomersFilterItem.PersonnelNumber]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECustomersFilterItem.Summ]?: DataFilterValueItem<Nullable<number>>;
  readonly [ECustomersFilterItem.OrderCount]?: DataFilterValueItem<Nullable<number>>;
  readonly [ECustomersFilterItem.Phone]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECustomersFilterItem.Email]?: DataFilterValueItem<Nullable<string>>;
};

export const getCustomerTableFilterStrategy = (
  filterItem: ECustomersFilterItem,
  values: CustomersFilterValues
): Nullable<CustomersFilterEditStrategy> => {
  switch (filterItem) {
    case ECustomersFilterItem.Query:
      return {
        type: EDataFilterType.String,
        key: ECustomersFilterItem.Query,
        label: 'Поиск по ключевому слову',
        preview: values[ECustomersFilterItem.Query]?.value || null,
        value: values[ECustomersFilterItem.Query]?.value || null,
      };
    case ECustomersFilterItem.LastName:
      return {
        type: EDataFilterType.String,
        key: ECustomersFilterItem.LastName,
        label: 'Фамилия',
        preview:
          [
            values[ECustomersFilterItem.LastName]?.value,
            values[ECustomersFilterItem.FirstName]?.value,
            values[ECustomersFilterItem.MiddleName]?.value,
          ]
            .filter(v => !!v)
            .join(' ')
            .trim() || null,
        value: values[ECustomersFilterItem.LastName]?.value || null,
        group: 'fio',
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ECustomersFilterItem.FirstName:
      return {
        type: EDataFilterType.String,
        key: ECustomersFilterItem.FirstName,
        label: 'Имя',
        preview:
          [
            values[ECustomersFilterItem.LastName]?.value,
            values[ECustomersFilterItem.FirstName]?.value,
            values[ECustomersFilterItem.MiddleName]?.value,
          ]
            .filter(v => !!v)
            .join(' ')
            .trim() || null,
        value: values[ECustomersFilterItem.FirstName]?.value || null,
        group: 'fio',
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ECustomersFilterItem.MiddleName:
      return {
        type: EDataFilterType.String,
        key: ECustomersFilterItem.MiddleName,
        label: 'Отчество',
        preview:
          [
            values[ECustomersFilterItem.LastName]?.value,
            values[ECustomersFilterItem.FirstName]?.value,
            values[ECustomersFilterItem.MiddleName]?.value,
          ]
            .filter(v => !!v)
            .join(' ')
            .trim() || null,
        value: values[ECustomersFilterItem.MiddleName]?.value || null,
        group: 'fio',
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ECustomersFilterItem.CreatedAt:
      return {
        type: EDataFilterType.DateAsTimePeriod,
        key: ECustomersFilterItem.CreatedAt,
        label: 'Дата',
        preview: moment(values[ECustomersFilterItem.CreatedAt]?.value?.[0]).format(EDateFormat.DisplayDefault) || null,
        value: values[ECustomersFilterItem.CreatedAt]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Between,
        },
      };
    case ECustomersFilterItem.PersonnelNumber:
      return {
        type: EDataFilterType.String,
        key: ECustomersFilterItem.PersonnelNumber,
        label: 'Табельный №',
        preview: values[ECustomersFilterItem.PersonnelNumber]?.value ?? null,
        value: values[ECustomersFilterItem.PersonnelNumber]?.value ?? null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ECustomersFilterItem.Phone:
      return {
        type: EDataFilterType.String,
        key: ECustomersFilterItem.Phone,
        label: 'Телефон',
        preview: values[ECustomersFilterItem.Phone]?.value || null,
        value: values[ECustomersFilterItem.Phone]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ECustomersFilterItem.Email:
      return {
        type: EDataFilterType.String,
        key: ECustomersFilterItem.Email,
        label: 'Email',
        preview: values[ECustomersFilterItem.Email]?.value || null,
        value: values[ECustomersFilterItem.Email]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ECustomersFilterItem.OrderCount:
      return null;
    case ECustomersFilterItem.Summ:
      return null;
  }
};
