import { Drawer as MuiDrawer, DrawerProps as MuiDrawerProps, List } from '@mui/material';
import styled from '@emotion/styled';
import { viewConfig } from '../../theme/viewConfig';

export const Wrapper = styled.div`
  width: ${viewConfig.menuPanelWidth};
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const Footer = styled(List)`
  & .MuiListItemText-primary {
    font-weight: ${p => p.theme.typography.fontWeightSemiBold};
  }

  & .MuiListItemIcon-root {
    margin-right: ${p => p.theme.spacing(2)};

    .MuiSvgIcon-root {
      font-size: 1.25rem;
    }
  }
`;

interface DrawerProps extends MuiDrawerProps {
  readonly backgroundColor?: string;
}

export const Drawer = styled(({ backgroundColor, ...others }: DrawerProps) => <MuiDrawer {...others} />)`
  width: ${viewConfig.menuPanelWidth};

  ${p =>
    p.backgroundColor &&
    `
    .MuiPaper-root {
      background-color: ${p.backgroundColor};
    }
  `}
`;
