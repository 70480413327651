import { FCC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../data/store/store';
import ContentLoader, { ContentLoaderProps } from '../../../components/common/loader';

interface CommonPreloaderDecoratorProps extends ContentLoaderProps {
  readonly isFetchingSelector: (store: RootState) => boolean;
}

export const CommonPreloaderDecorator: FCC<CommonPreloaderDecoratorProps> = ({
  isFetchingSelector,
  children,
  ...loaderProps
}) => {
  const isFetching = useSelector(isFetchingSelector);

  return isFetching ? (
    <ContentLoader
      size={75}
      position='fixed'
      {...loaderProps}
    />
  ) : (
    <>{children}</>
  );
};
