import { ContentTargetViewLocalitiesTableCell } from '../../../general/contentTarget/components/viewLocalities';
import ProductTableCell, { ProductTableCellCommonProps } from './index';

const ProductTableCellTargetLocalities = (props: ProductTableCellCommonProps) => {
  const {
    productOffer: { target },
  } = props;

  return (
    <ProductTableCell
      {...props}
      value={<ContentTargetViewLocalitiesTableCell value={target.targetLocalities} />}
    />
  );
};

export default ProductTableCellTargetLocalities;
