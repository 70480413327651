import { ApiRequestPageable } from '@/data/api/types';
import {
  appendQueryDslParams,
  getOfferActivationsDownloadEndpoint,
  getQueryDslByDataFilterValues,
} from '@/data/api/utils';
import { EOfferType } from '@/domain/model/enums';
import { DataFilterQueryDslOperator } from '@/domain/model/filter';
import { Nullable, UUID } from '@/domain/model/types';
import { DataTableColumns, MutableDataTableColumns } from '../../../components/common/table';
import { ETradeActivationTableColumn } from '../types';
import { TradeActivationsFilterValues } from './filter';

const tradeActivationTableColumns: DataTableColumns<ETradeActivationTableColumn> = {
  [ETradeActivationTableColumn.CreatedAt]: {
    title: 'Дата',
    width: '10rem',
    sortable: true,
  },
  [ETradeActivationTableColumn.OfferCode]: {
    title: 'Код ТП',
    width: '6rem',
    sortable: true,
  },
  [ETradeActivationTableColumn.OfferName]: {
    title: 'Название',
    width: '10rem',
    wrap: true,
    sortable: true,
  },
  [ETradeActivationTableColumn.PromotionType]: {
    title: 'Способ получения',
    width: '10rem',
    sortable: true,
  },
  [ETradeActivationTableColumn.PromotionCode]: {
    title: 'Присвоенный код',
    width: '10rem',
    sortable: true,
  },
  [ETradeActivationTableColumn.Partner]: {
    title: 'Партнер',
    width: '15rem',
    wrap: true,
    sortable: true,
  },
  [ETradeActivationTableColumn.CustomerName]: {
    title: 'ФИО',
    width: '20rem',
    wrap: true,
    sortable: true,
  },
  [ETradeActivationTableColumn.CustomerEmail]: {
    title: 'Эл. почта',
    width: '10rem',
    sortable: true,
  },
  [ETradeActivationTableColumn.CustomerPhone]: {
    title: 'Телефон',
    width: '10rem',
    sortable: true,
  },
};

export const getTradeActivationTableVisibleColumns = (
  partnerId: Nullable<UUID>,
  customerId: Nullable<UUID>
): DataTableColumns<ETradeActivationTableColumn> => {
  const columns: ETradeActivationTableColumn[] = [];

  columns.push(
    ETradeActivationTableColumn.CreatedAt,
    ETradeActivationTableColumn.OfferCode,
    ETradeActivationTableColumn.OfferName,
    ETradeActivationTableColumn.PromotionType,
    ETradeActivationTableColumn.PromotionCode
  );

  if (!partnerId) {
    columns.push(ETradeActivationTableColumn.Partner);
  }

  if (!customerId) {
    columns.push(
      ETradeActivationTableColumn.CustomerName,
      ETradeActivationTableColumn.CustomerEmail,
      ETradeActivationTableColumn.CustomerPhone
    );
  }

  const mutableTableColumns: MutableDataTableColumns<ETradeActivationTableColumn> = {};
  columns.forEach(column => (mutableTableColumns[column] = tradeActivationTableColumns[column]));
  return mutableTableColumns;
};

enum ETradeActivationsDownloadUrlParam {
  PartnerId = 'offer.partner.id',
  CustomerId = 'customer.id',
}

type GetTradeActivationsDownloadUrlWithSkipPaginationProps = {
  readonly skipPageable: true;
} & Partial<ApiRequestPageable>;

type GetTradeActivationsDownloadUrlWithoutSkipPaginationProps = {
  readonly skipPageable?: false;
} & ApiRequestPageable;

type MabeSkipPaginationGetTraderActivationsDownloadUrlProps =
  | GetTradeActivationsDownloadUrlWithSkipPaginationProps
  | GetTradeActivationsDownloadUrlWithoutSkipPaginationProps;

type GetTradeActivationsDownloadUrlProps = {
  readonly partnerId?: Nullable<UUID>;
  readonly customerId?: Nullable<UUID>;
  readonly filter: TradeActivationsFilterValues;
} & MabeSkipPaginationGetTraderActivationsDownloadUrlProps;

export const getTradeActivationsDownloadUrl = (props: GetTradeActivationsDownloadUrlProps) => {
  const { sort, page, pageSize, partnerId, customerId, filter, skipPageable } = props;

  const searchParams = new URLSearchParams();

  if (sort) {
    if (typeof sort === 'string') {
      searchParams.append('sort', sort);
    } else {
      sort.forEach(item => searchParams.append('sort', item));
    }
  }

  if (skipPageable) {
    searchParams.append('skipPageable', skipPageable.toString());
  } else {
    page && searchParams.append('page', (page - 1).toString(10));
    pageSize && searchParams.append('size', pageSize.toString(10));
  }

  searchParams.append('offerType', EOfferType.Trade);

  const queryDsl = getQueryDslByDataFilterValues(filter) ?? [];

  if (customerId) {
    queryDsl.push({
      field: ETradeActivationsDownloadUrlParam.CustomerId,
      operator: DataFilterQueryDslOperator.Equals,
      value: [customerId],
    });
  }

  if (partnerId) {
    queryDsl.push({
      field: ETradeActivationsDownloadUrlParam.PartnerId,
      operator: DataFilterQueryDslOperator.Equals,
      value: [partnerId],
    });
  }

  appendQueryDslParams(searchParams, queryDsl);

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return `${getOfferActivationsDownloadEndpoint()}${search}`;
};
