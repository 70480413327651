import AdminMpTableCell, { AdminMpTableCellCommonProps } from './index';

const AdminMpTableCellPhone = (props: AdminMpTableCellCommonProps) => {
  const {
    admin: { phone: value },
  } = props;

  return (
    <AdminMpTableCell
      {...props}
      value={value}
    />
  );
};

export default AdminMpTableCellPhone;