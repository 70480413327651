import { MPReport } from '@/domain';
import { DataTableColumns } from '@components/common/table';
import { EReportsTableColumn, EReportType } from '@features/report/types';
import { v4 as uuidv4 } from 'uuid';

const reportOffersStatistic: MPReport = {
  id: uuidv4(),
  name: 'Конверсия предложений',
  type: EReportType.OffersStatistic,
  description: 'Статистика по просмотрам и показам ТП и КП, подсчет конверсии и др.',
};

const reportOffers: MPReport = {
  id: uuidv4(),
  name: 'Предложения',
  type: EReportType.Offers,
  description: 'Статистика по количеству КП и ТП, активациям и переходам на сайт партнера',
};

const reportBookingOrdersStatistic: MPReport = {
  id: uuidv4(),
  name: 'Объекты Досуга и отдыха',
  type: EReportType.BookingOrdersStatistic,
  description: 'Статистика по реализации услуг на объектах Досуга и Отдыха',
};

const reportPartners: MPReport = {
  id: uuidv4(),
  name: 'Партнёры',
  type: EReportType.Partners,
  description: 'Статистика по количеству и статусам партнеров',
};

const reportUsers: MPReport = {
  id: uuidv4(),
  name: 'Пользователи',
  type: EReportType.Users,
  description: 'Статистика по количеству зарегистрированных пользователей',
};

const reportSportEvents: MPReport = {
  id: uuidv4(),
  name: 'Мероприятия, сводный',
  type: EReportType.Events,
  description: 'Сводная статистика по мероприятиям и участникам',
};

const reportSportUsers: MPReport = {
  id: uuidv4(),
  name: 'Пользователи',
  type: EReportType.SportUsers,
  description: 'Статистика по количеству зарегистрированных пользователей и их участию в мероприятиях',
};

const reportSportEventsDetailed: MPReport = {
  id: uuidv4(),
  name: 'Мероприятия, детальный',
  type: EReportType.EventsDetailed,
  description: 'Детальная статистика по мероприятиям и участникам',
};

// для админа ЦС - 5 отчётов
export const reportsForMpAdmin: MPReport[] = [
  reportOffersStatistic,
  reportOffers,
  reportBookingOrdersStatistic,
  reportPartners,
  reportUsers,
];

// для админа партнёра - 3 отчета
export const reportsForPartnerAdmin: MPReport[] = [reportOffersStatistic, reportOffers, reportBookingOrdersStatistic];

// для админа спорта - 3 отчета
export const reportsForSportAdmin: MPReport[] = [reportSportEvents, reportSportUsers, reportSportEventsDetailed];

type ReportsByUserRoleProps = {
  readonly isMpUser?: boolean;
  readonly isSportUser?: boolean;
  readonly isPartnerUser?: boolean;
  readonly isAdminMp?: boolean;
  readonly isAdminPartner?: boolean;
};

export const getReportsByUserRole = (props: ReportsByUserRoleProps): Nullable<MPReport[]> => {
  const { isSportUser, isPartnerUser, isMpUser } = props;

  if (isSportUser) {
    return reportsForSportAdmin;
  }

  if (isPartnerUser) {
    return reportsForPartnerAdmin;
  }

  if (isMpUser) {
    return reportsForMpAdmin;
  }

  return null;
};

export const reportsTableColumns: DataTableColumns<EReportsTableColumn> = {
  [EReportsTableColumn.Name]: {
    title: 'Название отчета',
    wrap: true,
  },
  [EReportsTableColumn.Description]: {
    title: 'Описание',
    wrap: true,
  },
};
