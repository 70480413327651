import { UUID } from 'domain/model/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EClientOrgUserTableTab } from '../../types';
import { clientOrgUsersGuidSelector } from '../store/selectors';
import { clientOrgUsersSetTab, clientOrgUsersStartSession } from '../store/slice';
import { ClientOrg } from '@/domain/model/clientOrg';

interface UseClientOrgUsersTableSessionProps {
  readonly guid: UUID;
  readonly clientOrg: ClientOrg;
  readonly tab: EClientOrgUserTableTab;
}

const useClientOrgUsersTableSession = (props: UseClientOrgUsersTableSessionProps): void => {
  const { guid, tab, clientOrg } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(clientOrgUsersGuidSelector);

  // инициализация сеанса
  useEffect(() => {
    dispatch(clientOrgUsersStartSession({ guid, clientOrg }));
  }, [dispatch, guid]);

  // начальная загрузка, изменение вкладки
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(clientOrgUsersSetTab({ tab }));
    }
  }, [dispatch, guid, currentGuid, tab]);
};

export default useClientOrgUsersTableSession;
