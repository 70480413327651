import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { BookingServiceCategory } from '../../../../../../domain/model/booking';
import { EBookingServiceCategoryStatus, EServiceOrderByDateType } from '../../../../../../domain/model/enums';
import { DictionaryTreeEdit } from '../../../../../components/common/dictionary/tree';
import { DictionaryEditItem } from '../../../../../components/common/dictionary/tree/item';
import { DictionaryAddItem } from '../../../../../components/common/dictionary/tree/item/add';
import { DictionaryCheckboxItem } from '../../../../../components/common/dictionary/tree/item/checkbox';
import { ContentWrapper } from '../controls';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingServiceCategoriesEditDataSelector } from '../store/selectors';
import { BookingServiceCategoriesEditAccess } from '../types';

const levelsLimit = 2;

type BookingServiceCategoriesEditDictionaryAdapterProps = {
  readonly access: BookingServiceCategoriesEditAccess;
};

export const BookingServiceCategoriesEditDictionaryAdapter = (
  props: BookingServiceCategoriesEditDictionaryAdapterProps
) => {
  const { access } = props;

  const data = useSelector(bookingServiceCategoriesEditDataSelector);

  const handlers = useContextHandlers();

  const isOrderByDateTypeIncludes = (
    bookingServiceCategory: BookingServiceCategory,
    orderByDateType: EServiceOrderByDateType
  ) => !!bookingServiceCategory.orderByDateTypes?.find(item => item === orderByDateType);

  return (
    <ContentWrapper>
      {data && (
        <DictionaryTreeEdit<BookingServiceCategory>
          data={data}
          slots={{
            item: props =>
              props.level === 0 ? (
                <DictionaryEditItem
                  {...props}
                  content={contentProps => (
                    <Typography
                      color={
                        contentProps.data.data.status === EBookingServiceCategoryStatus.Enabled
                          ? 'textPrimary'
                          : 'textSecondary'
                      }
                    >
                      {contentProps.children}
                    </Typography>
                  )}
                  onEdit={access.canEdit ? () => handlers.onChangeDialogState('modify', props.data) : undefined}
                  onDelete={access.canDelete ? () => handlers.onChangeDialogState('delete', props.data) : undefined}
                />
              ) : (
                <DictionaryCheckboxItem
                  {...props}
                  selected={false}
                  checked={isOrderByDateTypeIncludes(props.data.data, props.data.id as EServiceOrderByDateType)}
                  onChange={
                    access.canEdit
                      ? (item, selected) => {
                          if (selected) {
                            handlers.onAddOrderByDateType(item.data, item.id as EServiceOrderByDateType);
                          } else {
                            if (item.data.used) {
                              handlers.onChangeDialogState('serviceChange', item);
                            } else {
                              handlers.onRemoveOrderByDateType(item.data, item.id as EServiceOrderByDateType);
                            }
                          }
                        }
                      : undefined
                  }
                />
              ),
            levelHeader: access.canCreate
              ? props =>
                  props.level === 0 ? (
                    <DictionaryAddItem
                      {...props}
                      fixed
                      onClick={() => handlers.onChangeDialogState('add', { parent: props.parent })}
                    >
                      Добавить категорию
                    </DictionaryAddItem>
                  ) : null
              : undefined,
          }}
          hasLevel={level => level + 1 <= levelsLimit}
        />
      )}
    </ContentWrapper>
  );
};
