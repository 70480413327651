import { Grid } from '@mui/material';
import { ActivityType } from '../../../../../../domain/model/event';
import ConfirmDialog from '../../../../../components/common/dialogs/confirm';
import ContentLoader from '../../../../../components/common/loader';
import { MPButton } from '../../../../../theme/ui-kit/button';

interface ActivityTypesEditDialogHideAdapterProps {
  readonly isLoading: boolean;
  readonly data: ActivityType;
  readonly onClose: () => void;
  readonly onHide: (activityType: ActivityType) => void;
}

export const ActivityTypesEditDialogHideAdapter = (props: ActivityTypesEditDialogHideAdapterProps) => {
  const { isLoading, data, onHide, onClose } = props;

  return (
    <ConfirmDialog
      open
      dialogButtons={
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <MPButton
              disabled={isLoading}
              onClick={() => onHide(data)}
            >
              Да, скрыть
            </MPButton>
            {isLoading && <ContentLoader color='secondary' />}
          </Grid>
          <Grid item>
            <MPButton
              disabled={isLoading}
              variant='outlined'
              onClick={onClose}
            >
              Отмена
            </MPButton>
          </Grid>
        </Grid>
      }
      title='Переместить в скрытые'
    >
      Если вы скроете категорию <b>«{data.name}»</b>, её больше нельзя будет выбирать в профилях пользователей, команд и
      при создании мероприятий.
    </ConfirmDialog>
  );
};
