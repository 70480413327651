import { Link as MuiLink, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { viewConfig } from '../../../theme/viewConfig';

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;

  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div``;

export const Content = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  padding-top: ${p => p.theme.spacing(2)};
`;

export const PreviewWrapper = styled.div`
  display: grid;
  grid-gap: ${p => p.theme.spacing(3)};
  grid-template-columns: repeat(1, min-content);

  position: fixed;
  top: ${viewConfig.stepper.contentHeader.height};

  z-index: 2;
`;

export const Link = styled(MuiLink)`
  :focus {
    outline: none;
  }
`;

export const ButtonsWrapper = styled.div``;

export const TitleWrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  padding-bottom: ${p => p.theme.spacing(1.5)};

  border-bottom: 1px solid ${p => p.theme.palette.disabled.background};
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  padding-left: ${viewConfig.stepper.width};
`;

export const TextSemiBold = styled(Typography)`
  font-weight: ${p => p.theme.typography.fontWeightSemiBold};
`;
