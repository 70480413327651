import StepperValidationBadgeAdapter from '@components/common/stepper/adapters/validationBadge';
import { FCC } from 'react';
import { useSelector } from 'react-redux';
import { AdCampaignCreateStep } from '../../types';
import { adCampaignCreateValidationStepperSelector } from '../store/selectors';

interface AdCampaignCreateStepperIconAdapterProps {
  readonly step: AdCampaignCreateStep;
}

export const AdCampaignCreateStepperIconAdapter: FCC<AdCampaignCreateStepperIconAdapterProps> = props => {
  const { step, children } = props;

  const validation = useSelector(adCampaignCreateValidationStepperSelector);

  return <StepperValidationBadgeAdapter validation={validation?.[step.key]}>{children}</StepperValidationBadgeAdapter>;
};
