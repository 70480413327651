import BookingOfferTableCell, { BookingOfferTableCellCommonProps } from './index';

const BookingOfferTableCelRzdSocialPackage = (props: BookingOfferTableCellCommonProps) => {
  const {
    bookingOffer: { rzdSocialPackage },
  } = props;

  const value = rzdSocialPackage ? 'Да' : 'Нет';

  return (
    <BookingOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default BookingOfferTableCelRzdSocialPackage;
