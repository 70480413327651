import { Grid } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { Nullable } from '../../../../../domain/model/types';
import { MPButton } from '../../../../theme/ui-kit/button';
import MPImageCropActions from '../../../../theme/ui-kit/imageEditor/crop/actions';
import MPImagePixelCrop from '../../../../theme/ui-kit/imageEditor/crop/pixel';
import { MPImagePixelCropEnhancer } from '../../../../theme/ui-kit/imageEditor/crop/pixel/types';
import { imageCoverAuto } from '../../../../utils/images';
import { Wrapper } from './controls';

interface ImagePixelCropProps {
  readonly source: File;
  readonly width: number;
  readonly height: number;
  readonly onCancel: () => void;
  readonly onChange: (url: Nullable<File>) => void;
}

const ImagePixelCrop = (props: ImagePixelCropProps) => {
  const { source, width, height, onCancel, onChange } = props;

  //добавляем обработчик для подгонки картинки, чтобы пользователь руками этого не делал в редакторе
  const enhancers = useMemo<MPImagePixelCropEnhancer[]>(
    () => [props => imageCoverAuto(props.element, props.width, props.height)],
    []
  );

  const stopPropagation = useCallback((e: Event) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <Wrapper width={width}>
      <MPImagePixelCrop
        source={source}
        minWidth={width}
        maxWidth={width}
        minHeight={height}
        maxHeight={height}
        enhancers={enhancers}
        onChange={onChange}
      >
        {(cropped, size, onApplyCrop) => (
          <MPImageCropActions>
            <Grid
              container
              spacing={1}
              alignItems='center'
              justifyContent='center'
            >
              <Grid item>
                <MPButton
                  disabled={!cropped}
                  onMouseDown={stopPropagation}
                  onMouseUp={stopPropagation}
                  onMouseMove={stopPropagation}
                  onClick={onApplyCrop}
                >
                  Сохранить
                </MPButton>
              </Grid>
              <Grid item>
                <MPButton
                  variant='outlined'
                  onMouseDown={stopPropagation}
                  onMouseUp={stopPropagation}
                  onMouseMove={stopPropagation}
                  onClick={onCancel}
                >
                  Отмена
                </MPButton>
              </Grid>
            </Grid>
          </MPImageCropActions>
        )}
      </MPImagePixelCrop>
    </Wrapper>
  );
};

export default ImagePixelCrop;
