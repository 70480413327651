import { locale } from '@/locale';
import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

export const MPNaturalNumberFormat = React.forwardRef<any, NumberFormatProps>((props, ref) => {
  return (
    <NumberFormat
      {...props}
      getInputRef={ref}
      allowNegative={false}
      isNumericString
      decimalScale={0}
    />
  );
});

export const MPNumberFormat = React.forwardRef<any, NumberFormatProps>((props, ref) => {
  return (
    <NumberFormat
      getInputRef={ref}
      {...props}
    />
  );
});

export const MPCostFormat = React.forwardRef<any, NumberFormatProps>((props, ref) => {
  return (
    <NumberFormat
      getInputRef={ref}
      decimalScale={3}
      decimalSeparator={'.'}
      allowNegative={false}
      isNumericString={false}
      {...props}
    />
  );
});

const semiSpace = '\u2009' as const;

type MPRubFormatProps = {
  readonly value: Nullable<number>;
  readonly showRub?: true;
};

export const MPRubFormat = React.forwardRef<any, MPRubFormatProps>((props, ref) => {
  const { value, showRub } = props;

  return value === null ? (
    '-'
  ) : (
    <NumberFormat
      displayType='text'
      getInputRef={ref}
      thousandSeparator={semiSpace}
      decimalScale={3}
      decimalSeparator={'.'}
      allowNegative={false}
      isNumericString={false}
      suffix={showRub && ` ${locale.currency}`}
      value={value}
    />
  );
});

type MPBenefitFormatProps = {
  readonly value: Nullable<number>;
  readonly showBenefit?: true;
};

export const MPBenefitFormat = React.forwardRef<any, MPBenefitFormatProps>((props, ref) => {
  const { value, showBenefit } = props;

  return value === null ? (
    '-'
  ) : (
    <NumberFormat
      displayType='text'
      getInputRef={ref}
      thousandSeparator={semiSpace}
      decimalScale={3}
      decimalSeparator={'.'}
      allowNegative={false}
      isNumericString={false}
      suffix={showBenefit && ` ${locale.currencyBenefit}`}
      value={value}
    />
  );
});

type MPAspFormatProps = {
  readonly value: Nullable<number>;
  readonly showAsp?: true;
};

export const MPAspFormat = React.forwardRef<any, MPAspFormatProps>((props, ref) => {
  const { value, showAsp } = props;

  return value === null ? (
    '-'
  ) : (
    <NumberFormat
      displayType='text'
      getInputRef={ref}
      thousandSeparator={semiSpace}
      decimalScale={3}
      decimalSeparator={'.'}
      allowNegative={false}
      isNumericString={false}
      suffix={showAsp && ` ${locale.currencyAsp}`}
      value={value}
    />
  );
});

type MPAspRubFormatProps = {
  readonly value: Nullable<number>;
  readonly showAspRub?: true;
};

export const MPAspRubFormat = React.forwardRef<any, MPAspRubFormatProps>((props, ref) => {
  const { value, showAspRub } = props;

  return value === null ? (
    '-'
  ) : (
    <NumberFormat
      displayType='text'
      getInputRef={ref}
      thousandSeparator={semiSpace}
      decimalScale={3}
      decimalSeparator={'.'}
      allowNegative={false}
      isNumericString={false}
      suffix={showAspRub && ` ${locale.currencyAspRub}`}
      value={value}
    />
  );
});
