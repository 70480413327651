import SportsmanTableCell, { SportsmanTableCellCommonProps } from './index';

const SportsmanTableCellDepartmentName = (props: SportsmanTableCellCommonProps) => {
  const { sportsman } = props;

  return (
    <SportsmanTableCell
      sportsman={sportsman}
      value={sportsman.department?.name || '-'}
    />
  );
};

export default SportsmanTableCellDepartmentName;