import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../data/store/store';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { EBookingActionType } from '../../types';

export const bookingOfferActionsActionsSelector = (store: RootState) => store.bookingOffer.actions.actions;

export const bookingOfferActionsIsCreatingSelector = (store: RootState) => store.bookingOffer.actions.create.isFetching;

export const bookingOfferActionsDialogsSelector = (store: RootState) => store.bookingOffer.actions.dialogs;

export const bookingOfferActionsDialogPublishSelector = (store: RootState) =>
  store.bookingOffer.actions.dialogs.publish;
export const bookingOfferActionsDialogRejectSelector = (store: RootState) => store.bookingOffer.actions.dialogs.reject;
export const bookingOfferActionsDialogPauseSelector = (store: RootState) => store.bookingOffer.actions.dialogs.pause;
export const bookingOfferActionsDialogDeleteSelector = (store: RootState) => store.bookingOffer.actions.dialogs.delete;
export const bookingOfferActionsDialogArchiveSelector = (store: RootState) =>
  store.bookingOffer.actions.dialogs.archive;
export const bookingOfferActionsDialogChangeModeratorSelector = (store: RootState) =>
  store.bookingOffer.actions.dialogs.changeModerator;
export const bookingOfferActionsDialogResumeSelector = (store: RootState) => store.bookingOffer.actions.dialogs.resume;
export const bookingOfferActionsDialogRetrieveSelector = (store: RootState) =>
  store.bookingOffer.actions.dialogs.retrieve;
export const bookingOfferActionsDialogReturnToVerificationSelector = (store: RootState) =>
  store.bookingOffer.actions.dialogs.returnToVerification;
export const bookingOfferActionsDialogApproveSelector = (store: RootState) =>
  store.bookingOffer.actions.dialogs.approve;
export const bookingOfferActionsDialogUnPublishSelector = (store: RootState) =>
  store.bookingOffer.actions.dialogs.unPublish;
export const bookingOfferActionsDialogServiceManageSelector = (store: RootState) =>
  store.bookingOffer.actions.dialogs.serviceManage;

export const getBookingOfferActionsOfferIsProcessingSelector = (id: Nullable<UUID>) =>
  createSelector(
    (store: RootState) => store.bookingOffer.actions.actions,
    (store: RootState) => store.bookingOffer.actions.create.isFetching,
    (actions, isCreating) => {
      return id ? actions.some(action => action.id === id && action.isFetching) : isCreating;
    }
  );

export const isBookingOffersAnyChangedSelector = (store: RootState) =>
  store.bookingOffer.actions.actions.filter(action => !action.isFetching).length;

export const getIsBookingOfferAnyActionByTypeExecutedSelector = (
  id: Nullable<UUID>,
  ...actions: EBookingActionType[]
) =>
  createSelector(
    () => id,
    () => actions,
    (store: RootState) => store.bookingOffer.actions.actions,
    (id, argActions, storeActions) => {
      return id
        ? storeActions.find(action => action.id === id && action.isFetched && argActions.some(a => a === action.type))
            ?.type ?? null
        : null;
    }
  );

// TODO - booking придумать что делать с созданным
/*export const isBookingOffersAnyChangedSelector = (store: RootState) =>
  store.bookingOffer.actions.actions.filter(action => !action.isFetching).length +
  (!store.bookingOffer.actions.create.isFetching ? 1 : 0);*/
