import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ClientOrg } from '../../../../../domain/model/clientOrg';
import useHistoryExtensions from '../../../../hooks/useHistoryExtensions';
import MenuPanelDrawerLayout from '../../../../layouts/menuPanel';
import theme from '../../../../theme';
import { getClientOrgsTableRoute } from '../../entry';
import { ClientOrgManagementMenuHeaderAdapter } from './adapter/header';
import { ClientOrgManagementMenuItemsAdapter } from './adapter/items';
import { clientOrgManagementMenuGuidSelector } from './store/selectors';

interface ClientOrgManagementMenuPanelProps {
  readonly visible: boolean;
  readonly clientOrg: ClientOrg;
}

const ClientOrgManagementMenuPanel = ({ visible, clientOrg }: ClientOrgManagementMenuPanelProps) => {
  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const guid = useSelector(clientOrgManagementMenuGuidSelector);

  const goBack = useCallback(
    () => gotoPrevIndependentLocation(getClientOrgsTableRoute({})),
    [gotoPrevIndependentLocation]
  );

  return (
    <MenuPanelDrawerLayout
      visible={visible}
      guid={guid}
      backgroundColor={theme.palette.error.alpha90}
      header={
        <ClientOrgManagementMenuHeaderAdapter
          clientOrg={clientOrg}
          goBack={goBack}
        />
      }
    >
      <ClientOrgManagementMenuItemsAdapter clientOrg={clientOrg} />
    </MenuPanelDrawerLayout>
  );
};

export default ClientOrgManagementMenuPanel;
