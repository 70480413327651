import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../data/store/store';
import { CorpOffer } from '../../../../../domain/model/corpOffer';
import { ESortDirection } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { ECorpOfferTableColumn } from '../../types';

export const corpOffersSearchCorpOffersSelector = (store: RootState) => store.corpOffer.search.data;
export const corpOffersSearchIsFetchingSelector = (store: RootState) => store.corpOffer.search.isFetching;
export const corpOffersSearchGet = (store: RootState) => store.corpOffer.search;
export const corpOffersSearchGuidSelector = (store: RootState) => store.corpOffer.search.guid;
export const corpOffersSearchPageNumberSelector = (store: RootState) => store.corpOffer.search.pageNumber;
export const corpOffersSearchSearchSelector = (store: RootState) => store.corpOffer.search.search;
export const corpOffersSearchFilterSelector = (store: RootState) => store.corpOffer.search.filter;
export const corpOffersSearchSortColumnSelector = (store: RootState) =>
  (store.corpOffer.search.search.sort?.split(',') as [ECorpOfferTableColumn, ESortDirection])?.[0] ?? '';
export const corpOffersSearchSortDirectionSelector = (store: RootState) =>
  (store.corpOffer.search.search.sort?.split(',') as [ECorpOfferTableColumn, ESortDirection])?.[1] ?? '';
export const corpOffersSearchNeedRefreshWatcherSelector = (store: RootState) =>
  store.corpOffer.search.needRefreshWatcher;
export const corpOffersSearchTabsCounterSelector = (store: RootState) => store.corpOffer.search.tabsCounter;
export const corpOffersSearchSelectedSelector = (store: RootState) => store.corpOffer.search.selected;

export const corpOffersSearchCorpOfferIndexSelector = (store: RootState, index: number) => index;
const createCorpOffersSearchCorpOfferByIndexSelector = createSelector(
  corpOffersSearchCorpOffersSelector,
  corpOffersSearchCorpOfferIndexSelector,
  (corpOffers, index): Nullable<CorpOffer> => corpOffers?.[index] ?? null
);
export const corpOffersSearchCorpOfferByIndexSelector = (index: number) => (store: RootState) =>
  createCorpOffersSearchCorpOfferByIndexSelector(store, index);
