import AdminMpTableCell, {AdminMpTableCellCommonProps} from './index';

const AdminMpTableCellEmail = (props: AdminMpTableCellCommonProps) => {
  const {
    admin: { email: value },
  } = props;

  return (
    <AdminMpTableCell
      {...props}
      value={value}
    />
  );
};

export default AdminMpTableCellEmail;