import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { BookingServicePriceUnit } from '../../../../../../domain/model/booking';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingServicePriceUnitsEditDialogsStateSelector } from '../store/selectors';
import { BookingServicePriceUnitsEditDialogAddAdapter } from './dialogAdd';
import { BookingServicePriceUnitsEditDialogDeleteAdapter } from './dialogDelete';
import { BookingServicePriceUnitsEditDialogEditAdapter } from './dialogEdit';

export const BookingServicePriceUnitsEditDialogsAdapter = () => {
  const handlers = useContextHandlers();

  const dialogs = useSelector(bookingServicePriceUnitsEditDialogsStateSelector);

  const [isLoading, setIsLoading] = useState(false);

  const onAdd = useCallback(
    async (bookingServicePriceUnit: BookingServicePriceUnit) => {
      setIsLoading(true);
      await handlers
        .onCreate(bookingServicePriceUnit)
        .then(() => {
          handlers.onChangeDialogState('add', null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [handlers.onChangeDialogState, handlers.onCreate]
  );

  const onCloseAdd = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('add', null);
  }, [handlers.onChangeDialogState]);

  const onDelete = useCallback(
    async (bookingServicePriceUnit: BookingServicePriceUnit) => {
      setIsLoading(true);
      await handlers
        .onDelete(bookingServicePriceUnit)
        .then(() => {
          handlers.onChangeDialogState('delete', null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [handlers.onChangeDialogState]
  );

  const onCloseDelete = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('delete', null);
  }, [handlers.onChangeDialogState]);

  const onModify = useCallback(
    async (bookingServicePriceUnit: BookingServicePriceUnit) => {
      setIsLoading(true);
      await handlers
        .onEdit(bookingServicePriceUnit)
        .then(() => {
          handlers.onChangeDialogState('modify', null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [handlers.onChangeDialogState, handlers.onEdit]
  );

  const onCloseModify = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('modify', null);
  }, [handlers.onChangeDialogState]);

  return (
    <>
      {dialogs.add && (
        <BookingServicePriceUnitsEditDialogAddAdapter
          isLoading={isLoading}
          parent={dialogs.add?.parent?.data ?? null}
          onAdd={onAdd}
          onClose={onCloseAdd}
        />
      )}
      {dialogs.delete && (
        <BookingServicePriceUnitsEditDialogDeleteAdapter
          isLoading={isLoading}
          data={dialogs.delete.data}
          onDelete={onDelete}
          onClose={onCloseDelete}
        />
      )}
      {dialogs.modify && (
        <BookingServicePriceUnitsEditDialogEditAdapter
          isLoading={isLoading}
          data={dialogs.modify.data}
          onEdit={onModify}
          onClose={onCloseModify}
        />
      )}
    </>
  );
};
