import { useMemo } from 'react';
import { EOrderPartition } from '../../../../domain/model/enums';
import { ProductOrder } from '../../../../domain/model/order';
import { Nullable, UUID } from '../../../../domain/model/types';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import { ProductOrderLifeCycleFactory } from '../lifecycle';
import { EProductOrderTableTab } from '../types';
import ProductOrderTableContainer from './container';
import { ProductOrderTableConfigContext } from './context';
import { useProductOrderTableConfig } from './hooks/useTableConfig';

interface ProductOrderTableEntryProps {
  readonly guid: UUID;
  readonly tab: Nullable<EProductOrderTableTab>;
  readonly partnerId?: Nullable<UUID>;
  readonly customerId?: Nullable<UUID>;
  readonly onOrderClick: (order: ProductOrder) => void;
}

const ProductOrderTableEntry = ({
  tab,
  guid,
  partnerId = null,
  customerId = null,
  onOrderClick,
}: ProductOrderTableEntryProps) => {
  const {
    accessMatrix: { orders, isPartnerUser },
    userSpecific,
  } = useCurrentUser();

  const config = useProductOrderTableConfig();

  const { defaultTab } = config;
  const outTab = tab ?? defaultTab;

  const lifecycle = useMemo(
    () =>
      ProductOrderLifeCycleFactory().create({
        isPartnerUser,
        userId: userSpecific.id,
      }),
    [isPartnerUser, userSpecific.id]
  );

  return (
    <ProductOrderTableConfigContext.Provider value={config}>
      <ProductOrderTableContainer
        guid={guid}
        tab={outTab}
        partnerId={partnerId}
        customerId={customerId}
        lifecycle={lifecycle}
        access={orders[EOrderPartition.ProductOffers]}
        onOrderClick={onOrderClick}
      />
    </ProductOrderTableConfigContext.Provider>
  );
};

export default ProductOrderTableEntry;
