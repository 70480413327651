import { TradeOfferActivation } from '@/domain/model/activation';
import { ESortDirection } from '@/domain/model/enums';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../components/common/table';
import { DataTableLoader } from '../../../components/common/table/loader';
import TradeActivationTableCellCreateAt from '../components/tableCell/createAt';
import TradeActivationTableCellCustomerEmail from '../components/tableCell/customerEmail';
import TradeActivationTableCellCustomerName from '../components/tableCell/customerName';
import TradeActivationTableCellCustomerPhone from '../components/tableCell/customerPhone';
import TradeActivationTableCellOfferCode from '../components/tableCell/offerCode';
import TradeActivationTableCellOfferName from '../components/tableCell/offerName';
import TradeActivationTableCellPartnerName from '../components/tableCell/partnerName';
import TradeActivationTableCellPromotionCode from '../components/tableCell/promotionCode';
import TradeActivationTableCellPromotionType from '../components/tableCell/promotionType';
import { ETradeActivationTableColumn } from '../types';
import TradeActivationTableCellAdapter from './adapters/cell';
import TradeActivationTableCellPromotionTypeAdapter from './adapters/cellPromotionType';
import { tradeActivationsIsFetchingSelector } from './store/selectors';

interface TradeActivationTableProps {
  readonly metadata: DataTableMetadata<ETradeActivationTableColumn>;
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly activations: TradeOfferActivation[];
  readonly onRequestSort: (column: ETradeActivationTableColumn, direction: ESortDirection) => void;
  readonly onChangeMetadata?: (metadata: DataTableMetadata<ETradeActivationTableColumn>) => void;
}

const TradeActivationTable = (props: TradeActivationTableProps) => {
  const { metadata, activations, sort, onRequestSort, onChangeMetadata } = props;

  const rows: DataTableRow<TradeOfferActivation, ETradeActivationTableColumn>[] = activations?.map(
    (activation, index) => {
      return {
        [ETradeActivationTableColumn.CreatedAt]: (
          <TradeActivationTableCellAdapter
            index={index}
            component={TradeActivationTableCellCreateAt}
          />
        ),
        [ETradeActivationTableColumn.OfferCode]: (
          <TradeActivationTableCellAdapter
            index={index}
            component={TradeActivationTableCellOfferCode}
          />
        ),
        [ETradeActivationTableColumn.OfferName]: (
          <TradeActivationTableCellAdapter
            index={index}
            component={TradeActivationTableCellOfferName}
          />
        ),
        [ETradeActivationTableColumn.PromotionType]: (
          <TradeActivationTableCellPromotionTypeAdapter
            index={index}
            component={TradeActivationTableCellPromotionType}
          />
        ),
        [ETradeActivationTableColumn.PromotionCode]: (
          <TradeActivationTableCellAdapter
            index={index}
            component={TradeActivationTableCellPromotionCode}
          />
        ),
        [ETradeActivationTableColumn.Partner]: (
          <TradeActivationTableCellAdapter
            index={index}
            component={TradeActivationTableCellPartnerName}
          />
        ),
        [ETradeActivationTableColumn.CustomerName]: (
          <TradeActivationTableCellAdapter
            index={index}
            component={TradeActivationTableCellCustomerName}
          />
        ),
        [ETradeActivationTableColumn.CustomerEmail]: (
          <TradeActivationTableCellAdapter
            index={index}
            component={TradeActivationTableCellCustomerEmail}
          />
        ),
        [ETradeActivationTableColumn.CustomerPhone]: (
          <TradeActivationTableCellAdapter
            index={index}
            component={TradeActivationTableCellCustomerPhone}
          />
        ),
        data: activation,
      };
    }
  );

  return (
    <DataTable<TradeOfferActivation, ETradeActivationTableColumn>
      width='auto'
      overflowX='inherit'
      metadata={metadata}
      rows={rows}
      sort={{ column: sort.column, direction: sort.direction }}
      onSort={(event, column, direction) => onRequestSort(column as ETradeActivationTableColumn, direction)}
      onChangeMetadata={onChangeMetadata}
      loader={<DataTableLoader selector={tradeActivationsIsFetchingSelector} />}
    />
  );
};

export default TradeActivationTable;
