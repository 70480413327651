import ProductTableCell, { ProductTableCellCommonProps } from './index';

const ProductTableCellModerator = (props: ProductTableCellCommonProps) => {
  const {
    productOffer: { moderator },
  } = props;

  const value = moderator?.name ?? '-';

  return (
    <ProductTableCell
      {...props}
      value={value}
    />
  );
};

export default ProductTableCellModerator;
