import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { ClientOrgActionsDialogsAdapter, ClientOrgActionsOptimizerAdapter } from './adapters';
import ClientOrgActionsContext, { ClientOrgActionsContextType } from './context';
import {
  clientOrgActionsIsCreatedSelector,
  getClientOrgActionsOfferIsProcessingSelector,
  getIsClientOrgAnyActionByTypeExecutedSelector,
  isClientOrgsAnyChangedSelector,
} from './store/selectors';
import {
  clientOrgActionsBlock,
  clientOrgActionsChangeDialogState,
  clientOrgActionsSave,
  clientOrgActionsUnBlock,
} from './store/slice';
import { EClientOrgActionType } from '../types';
import { showClientOrgActionNotification } from './utils';

interface ClientOrgActionsProviderProps {
  readonly children: ReactNode;
}

type ClientOrgActionsProviderType = (props: ClientOrgActionsProviderProps) => JSX.Element;

export const ClientOrgActionsProvider: ClientOrgActionsProviderType = ({ children }) => {
  const dispatch = useDispatch();

  const onSave: ClientOrgActionsContextType['onSave'] = async clientOrg => {
    const response = await dispatch(clientOrgActionsSave(clientOrg)).unwrap();

    if (response) {
      showClientOrgActionNotification(response, EClientOrgActionType.Edit);
    }

    return response;
  };

  const onChangeDialogState: ClientOrgActionsContextType['onChangeDialogState'] = (name, data) => {
    dispatch(clientOrgActionsChangeDialogState({ name, data }));
  };

  const onUnBlock: ClientOrgActionsContextType['onUnBlock'] = async id => {
    return await dispatch(clientOrgActionsUnBlock({ id })).unwrap();
  };

  const onBlock: ClientOrgActionsContextType['onBlock'] = async id => {
    const response = await dispatch(clientOrgActionsBlock({ id })).unwrap();
    if (response) {
      showClientOrgActionNotification(response, EClientOrgActionType.Block);
    }
    return response;
  };

  const onTryUnBlock: ClientOrgActionsContextType['onTryUnBlock'] = clientOrg => {
    onChangeDialogState('unBlock', clientOrg);
  };

  const onTryBlock: ClientOrgActionsContextType['onTryBlock'] = clientOrg => {
    onChangeDialogState('block', clientOrg);
  };

  return (
    <ClientOrgActionsContext.Provider
      value={{
        onSave,
        onUnBlock,
        onBlock,

        onTryUnBlock,
        onTryBlock,

        onChangeDialogState,
        utils: {
          selectors: {
            isClientOrgCreatedActionSelector: clientOrgActionsIsCreatedSelector,
            getIsClientOrgExecutedActionsSelector: getIsClientOrgAnyActionByTypeExecutedSelector,
            getIsClientOrgProcessingSelector: getClientOrgActionsOfferIsProcessingSelector,
            isClientOrgsAnyChangedSelector: isClientOrgsAnyChangedSelector(),
          },
        },
      }}
    >
      {children}
      <ClientOrgActionsOptimizerAdapter />
      <ClientOrgActionsDialogsAdapter />
    </ClientOrgActionsContext.Provider>
  );
};
