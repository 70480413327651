import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Partner } from '../../../../../domain/model/partner';
import { MpUser } from '../../../../../domain/model/user';
import { MenuPanelItem, MenuPanelLinkLocationState } from '../../../../types';
import { getMenuItemLocation, menuPanelItemIsSelected } from '../../../general/menu/utils';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { ECustomerManagementMenuType } from './enums';
import { customerManagementMenuOpenedItemsSelector } from './store/selectors';
import { customerManagementMenuAddToOpened } from './store/slice';
import { getCustomerManagementByPartnerMenu, getCustomerManagementCommonMenu } from './utils';

interface UseCustomerManagementMenuItemsProps {
  readonly customer: MpUser;
  readonly partner?: Partner;
}

interface UseCustomerManagementMenuItems {
  readonly items: MenuPanelItem[];
  readonly isSelectedItem: (item: MenuPanelItem) => boolean;
  readonly isOpenedSubMenu: (item: MenuPanelItem) => boolean;
  readonly onItemClick: (item: MenuPanelItem) => void;
}

const useCustomerManagementMenuItems = ({
  customer,
  partner,
}: UseCustomerManagementMenuItemsProps): UseCustomerManagementMenuItems => {
  const location = useLocation();
  const history = useHistory<MenuPanelLinkLocationState>();
  const dispatch = useDispatch();

  const { accessMatrix } = useCurrentUser();

  const menuType: ECustomerManagementMenuType = useMemo(
    () => (partner ? ECustomerManagementMenuType.ByPartner : ECustomerManagementMenuType.Common),
    [partner]
  );

  const opened = useSelector(customerManagementMenuOpenedItemsSelector(menuType));

  const isSelectedItem = useCallback((item: MenuPanelItem) => menuPanelItemIsSelected(item, location), [location]);

  const isOpenedSubMenu = useCallback(
    (item: MenuPanelItem): boolean => {
      return opened[item.id] || (item.subMenu?.some(subItem => isSelectedItem(subItem)) ?? false);
    },
    [isSelectedItem, opened]
  );

  const onItemClick = useCallback(
    (item: MenuPanelItem) => {
      if (item.subMenu) {
        dispatch(customerManagementMenuAddToOpened({ menu: menuType, itemId: item.id }));
      } else {
        history.replace(getMenuItemLocation(item));
      }
    },
    [dispatch, history, menuType]
  );

  const items = useMemo<MenuPanelItem[]>(
    () =>
      partner
        ? getCustomerManagementByPartnerMenu(customer, partner, accessMatrix)
        : getCustomerManagementCommonMenu(customer, accessMatrix),
    [partner, accessMatrix, customer]
  );

  return { items, isSelectedItem, isOpenedSubMenu, onItemClick };
};

export default useCustomerManagementMenuItems;
