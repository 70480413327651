import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FileDescription } from '../../../../../../domain/model';
import { ETaxationSystemType } from '../../../../../../domain/model/enums';
import { PartnerCompanyDataDraft, PartnerDataDraft } from '../../../../../../domain/model/partner';
import { Nullable } from '../../../../../../domain/model/types';
import SectionTitle from '../../../../../components/common/sectionTitle';
import ValidationErrorPanel from '../../../../../components/common/validation/errorPanel';
import { OnChangeObjectAttribute } from '../../../../../types';
import { ValidationResult } from '../../../../../utils/validation';
import { PartnerDocumentsCompany } from '../../../../general/partner/validation';
import { PartnerCommonFileSection, PartnerCompanyFileSection } from './fileSections';

interface PartnerEditCompanyDocumentsProps {
  readonly innCert: Nullable<FileDescription>;
  readonly taxSystem: Nullable<ETaxationSystemType>;
  readonly usnDoc: Nullable<FileDescription>;
  readonly ogrnCert: Nullable<FileDescription>;
  readonly charterDoc: Nullable<FileDescription>;
  readonly ceoAppointmentProtocol: Nullable<FileDescription>;
  readonly isValid: boolean;
  readonly validation: Nullable<ValidationResult<PartnerDocumentsCompany>>;
  readonly onChangeAttribute: OnChangeObjectAttribute<PartnerDataDraft>;
  readonly onChangeCompanyAttribute: OnChangeObjectAttribute<PartnerCompanyDataDraft>;
}

const PartnerEditCompanyDocuments = (props: PartnerEditCompanyDocumentsProps) => {
  const {
    innCert,
    taxSystem,
    usnDoc,
    ogrnCert,
    charterDoc,
    ceoAppointmentProtocol,
    isValid,
    validation,
    onChangeAttribute,
    onChangeCompanyAttribute,
  } = props;

  return (
    <Grid
      container
      spacing={2}
      direction='column'
    >
      <Grid item>
        <Typography
          variant='h3'
          gutterBottom
        >
          Документы
        </Typography>
      </Grid>

      <PartnerCompanyFileSection
        title={<SectionTitle>Выписка из ЕГРЮЛ</SectionTitle>}
        name='ogrnCert'
        guid={ogrnCert?.path}
        error={!!validation?.ogrnCert?.hasError}
        helperText={validation?.ogrnCert?.message}
        onChangeFile={onChangeCompanyAttribute}
      />

      <PartnerCommonFileSection
        title={<SectionTitle>Свидетельство ИНН</SectionTitle>}
        name='innCert'
        guid={innCert?.path}
        error={!!validation?.innCert?.hasError}
        helperText={validation?.innCert?.message}
        onChangeFile={onChangeAttribute}
      />

      <PartnerCompanyFileSection
        title={
          <>
            <SectionTitle gutterBottom={false}>Копия Устава</SectionTitle>
            <Typography gutterBottom>
              Устав в полной редакции единым файлом, с отметкой о государственной регистрации
            </Typography>
          </>
        }
        name='charterDoc'
        guid={charterDoc?.path}
        error={!!validation?.charterDoc?.hasError}
        helperText={validation?.charterDoc?.message}
        onChangeFile={onChangeCompanyAttribute}
      />

      <PartnerCompanyFileSection
        title={<SectionTitle>Решение/протокол о назначении ген. директора</SectionTitle>}
        name='ceoAppointmentProtocol'
        guid={ceoAppointmentProtocol?.path}
        error={!!validation?.ceoAppointmentProtocol?.hasError}
        helperText={validation?.ceoAppointmentProtocol?.message}
        onChangeFile={onChangeCompanyAttribute}
      />

      {taxSystem === ETaxationSystemType.USN && (
        <PartnerCommonFileSection
          title={<SectionTitle>Основание УСН</SectionTitle>}
          name='usnDoc'
          guid={usnDoc?.path}
          error={!!validation?.usnDoc?.hasError}
          helperText={validation?.usnDoc?.message}
          onChangeFile={onChangeAttribute}
        />
      )}

      {!isValid && (
        <Grid
          item
          xs={12}
        >
          <ValidationErrorPanel />
        </Grid>
      )}
    </Grid>
  );
};

export default PartnerEditCompanyDocuments;
