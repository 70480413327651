import styled from '@emotion/styled';
import { teamMediaSize } from '../../utils';

export const TeamMediaWrapper = styled.div`
  height: ${teamMediaSize};
  width: ${teamMediaSize};

  position: relative;

  border-radius: ${p => p.theme.shape.borderRadius}px;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;

    object-fit: cover;
    object-position: center center;
  }
`;

export const Content = styled.div`
  padding-top: ${p => p.theme.spacing(3)};
  padding-bottom: ${p => p.theme.spacing(6)};
  flex-grow: 1;
`;

export const Footer = styled.div`
  position: sticky;
  bottom: 0;

  margin-left: -${p => p.theme.spacing(3.75)};
  margin-right: -${p => p.theme.spacing(3.75)};

  padding: ${p => p.theme.spacing(2)} ${p => p.theme.spacing(3.75)} ${p => p.theme.spacing(3)};

  background: ${p => p.theme.palette.background.default};
`;
