import { DataTreeItem } from '../../../../../domain/model';
import { Nullable } from '../../../../../domain/model/types';
import { DictionaryItemContent, DictionaryLevelItem } from '../types';

export enum DictionaryTreeChangeType {
  search = 'search',
  click = 'click',
}

export type DictionaryTreeSlots<T> = {
  readonly item: DictionaryItemContent<T>;
  readonly levelHeader?: DictionaryLevelItem<T>;
};

export type DictionaryTreeCommonProps<T> = {
  readonly initialExpanded?: Nullable<DataTreeItem<T>[]>;
  readonly selected?: Nullable<DataTreeItem<T>[]>;
  readonly slots: DictionaryTreeSlots<T>;
  readonly onChangeSort?: (parent: Nullable<DataTreeItem<T>>, data: DataTreeItem<T>[]) => void;
  readonly hasLevel?: (level: number) => boolean;
};

export type DictionaryTreeProps<T> = DictionaryTreeCommonProps<T> & {
  readonly data: DataTreeItem<T>[];
  readonly changeType: DictionaryTreeChangeType;
};
