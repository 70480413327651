import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { EOrderDiscriminator, EOrderPartition } from '../../../domain/model/enums';
import { UUID } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import BookingOrderActionsProvider from '../../features/bookingOrder/actions/provider';
import BookingOrderDetailsContainer from '../../features/bookingOrder/details/container';
import { BookingOrderLifeCycleFactory } from '../../features/bookingOrder/lifecycle';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import OrderByIdContainer from '../../features/order/byId/container';
import { getOrdersTableRoute } from '../../features/order/entry';
import { getOrderPartitionByOrderDiscriminator } from '../../features/order/utils';
import ProductOrderEditContainer from '../../features/productOrder/edit/container';
import { TradeOffersLocationState } from '../../features/tradeOffer/entry';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';
import useHistoryExtensions from '../../hooks/useHistoryExtensions';

const OrderDetailsScreen = () => {
  const dispatch = useDispatch();
  const { accessMatrix, userSpecific } = useCurrentUser();
  const { isPartnerUser, orders } = accessMatrix;

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const { id } = useParams<{ id: UUID }>();

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  const canView = (partition: EOrderPartition) => {
    return orders?.[partition].view;
  };

  return (
    <LocationWithRequiredStateContainer<TradeOffersLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <OrderByIdContainer id={id}>
          {({ order }) => {
            const partition = getOrderPartitionByOrderDiscriminator(order.discriminator);

            if (!canView(partition)) {
              return <ForbiddenComponent />;
            }

            if (order) {
              switch (order.discriminator) {
                case EOrderDiscriminator.ProductOrder:
                  return (
                    <ProductOrderEditContainer
                      id={id}
                      guid={state.guid}
                      source={order}
                      partner={order.partner}
                    />
                  );
                case EOrderDiscriminator.BookingOrder:
                  return (
                    <BookingOrderActionsProvider>
                      <BookingOrderDetailsContainer
                        id={id}
                        lifecycle={BookingOrderLifeCycleFactory().create({
                          isPartnerUser,
                          userId: userSpecific.id,
                        })}
                        access={orders[partition]}
                        onClose={() => gotoPrevIndependentLocation(getOrdersTableRoute({ partition }))}
                      />
                    </BookingOrderActionsProvider>
                  );
              }
            }
          }}
        </OrderByIdContainer>
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default OrderDetailsScreen;
