import { RootState } from '../../../../../../data/store/store';

export const bookingOfferCategoriesEditDataSelector = (store: RootState) =>
  store.dictionary.bookingOfferCategory.edit.fetch.data;

export const bookingOfferCategoriesEditIsPreloadingSelector = (store: RootState) =>
  store.dictionary.bookingOfferCategory.edit.fetch.isFetching;

export const bookingOfferCategoriesEditIsLoadingSelector = (store: RootState) =>
  store.dictionary.bookingOfferCategory.edit.fetch.isFetching;

export const bookingOfferCategoriesEditAddDialogStateSelector = (store: RootState) =>
  store.dictionary.bookingOfferCategory.edit.dialogs.add;

export const bookingOfferCategoriesEditDeleteDialogStateSelector = (store: RootState) =>
  store.dictionary.bookingOfferCategory.edit.dialogs.delete;

export const bookingOfferCategoriesEditEditDialogStateSelector = (store: RootState) =>
  store.dictionary.bookingOfferCategory.edit.dialogs.modify;

export const bookingOfferCategoriesEditIsRelationShipsCheckingSelector = (store: RootState) =>
  store.dictionary.bookingOfferCategory.edit.checkRelationShips.isFetching;
