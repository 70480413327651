import { FormControlLabel, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { AppFile, FileDescription, SportOptionTyped } from '../../../../../../../domain/model';
import { EPartnerPermission, EPartnerType } from '../../../../../../../domain/model/enums';
import { Nullable } from '../../../../../../../domain/model/types';
import { Checkbox } from '../../../../../../components/common/checkboxPanel/controls';
import FileInfo from '../../../../../../components/common/files/info';
import FileUploader from '../../../../../../components/common/files/uploader';
import Splitter from '../../../../../../components/common/splitter';
import ValidationErrorPanel from '../../../../../../components/common/validation/errorPanel';
import { fileTypes } from '../../../../../../constants';
import useTechConfig from '../../../../../../hooks/useTechConfig';
import { ContentTypeDocumentIcon } from '../../../../../../icons';
import { MPMenuItem } from '../../../../../../theme/ui-kit/menu/item';
import { MPSelect } from '../../../../../../theme/ui-kit/select';
import { EAppFeature } from '../../../../../../types';
import { ValidationResult } from '../../../../../../utils/validation';
import { PartnerDataStepPermissions } from '../../../../../general/partner/validation';

interface PartnerApplicationEditStepPermissionsProps {
  readonly agreements: Nullable<FileDescription[]>;
  readonly isValid?: boolean;
  readonly partnerType: Nullable<EPartnerType>;
  readonly partnerTypes: SportOptionTyped<EPartnerType>[];
  readonly permissions: Nullable<EPartnerPermission[]>;
  readonly validation: Nullable<ValidationResult<PartnerDataStepPermissions>>;
  readonly onChangePermissions: (value: EPartnerPermission[]) => void;
  readonly onChangeAgreements: (value: FileDescription[]) => void;
  readonly onChangeType: (value: EPartnerType) => void;
}

const PartnerApplicationEditStepPermissions = (props: PartnerApplicationEditStepPermissionsProps) => {
  const {
    agreements,
    partnerTypes,
    permissions,
    partnerType,
    validation,
    onChangePermissions,
    onChangeAgreements,
    onChangeType,
  } = props;

  const { hasFeature } = useTechConfig();
  const productOffers = hasFeature(EAppFeature.ProductOffer);
  const bookingOffers = hasFeature(EAppFeature.Booking);

  const tradeOffersAllowed: boolean = permissions?.includes(EPartnerPermission.TradeOfferPublic) ?? false;
  const corpOffersAllowed: boolean = permissions?.includes(EPartnerPermission.CorpOfferPublic) ?? false;
  const productOffersAllowed: boolean = permissions?.includes(EPartnerPermission.ProductOfferPublic) ?? false;
  const bookingOffersAllowed: boolean = permissions?.includes(EPartnerPermission.BookingOfferPublic) ?? false;

  const selectedPartnerType = partnerTypes.find(pt => pt.id === partnerType) ?? '';

  const onChangePermission = (permission: EPartnerPermission, enabled: boolean) => {
    const newPermissions: EPartnerPermission[] = [...(permissions ?? [])];

    const existedIndex = newPermissions.indexOf(permission);
    if (existedIndex !== -1) {
      newPermissions.splice(existedIndex, 1);
    }

    if (enabled) {
      newPermissions.push(permission);
    }
    onChangePermissions(newPermissions);
  };

  const onUploadAgreement = (file: AppFile) => {
    const newAgreements: FileDescription[] = [...(agreements ?? []), { path: file.path }];
    onChangeAgreements(newAgreements);
  };

  const onRemoveAgreement = (removedAgreement: FileDescription) => {
    const newAgreements: FileDescription[] = [...(agreements ?? [])];

    const existedIndex = newAgreements.findIndex(agreement => agreement.path === removedAgreement.path);
    if (existedIndex !== -1) {
      newAgreements.splice(existedIndex, 1);
    }

    onChangeAgreements(newAgreements);
  };

  return (
    <Grid
      container
      direction='column'
    >
      <Grid item>
        <Typography variant='h3'>Доступ</Typography>
        <Splitter variant='horizontal' />
      </Grid>

      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={tradeOffersAllowed}
              onChange={(event, checked) => onChangePermission(EPartnerPermission.TradeOfferPublic, checked)}
            />
          }
          label={<Typography>Торговые предложения</Typography>}
        />
      </Grid>

      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={corpOffersAllowed}
              onChange={(event, checked) => onChangePermission(EPartnerPermission.CorpOfferPublic, checked)}
            />
          }
          label={<Typography>Корпоративные предложения</Typography>}
        />
      </Grid>

      {productOffers && (
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={productOffersAllowed}
                onChange={(event, checked) => onChangePermission(EPartnerPermission.ProductOfferPublic, checked)}
              />
            }
            label={<Typography>Товарные предложения</Typography>}
          />
        </Grid>
      )}

      {bookingOffers && (
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={bookingOffersAllowed}
                onChange={(event, checked) => onChangePermission(EPartnerPermission.BookingOfferPublic, checked)}
              />
            }
            label={<Typography>Объекты досуга и отдыха</Typography>}
          />
        </Grid>
      )}

      {validation?.permissions && (
        <Grid
          item
          xs={12}
        >
          <Splitter variant='horizontal' />
          <ValidationErrorPanel text={validation.permissions.message} />
        </Grid>
      )}

      <Grid item>
        <Splitter
          variant='horizontal'
          size={3}
        />
        <Typography variant='subtitle2'>Вид партнёра</Typography>
        <Splitter variant='horizontal' />
        <MPSelect<SportOptionTyped<EPartnerType>>
          label='Вид партнёра'
          value={selectedPartnerType}
          error={validation?.type?.hasError}
          helperText={validation?.type?.message}
          onChange={newType => onChangeType(newType.id)}
        >
          {partnerTypes.map(pt => (
            <MPMenuItem
              key={pt.id}
              value={pt}
            >
              <Typography variant='body1'>{pt.name}</Typography>
            </MPMenuItem>
          ))}
        </MPSelect>
      </Grid>
      <Grid item>
        <Splitter
          variant='horizontal'
          size={3}
        />
        <Typography variant='subtitle2'>Договоры и соглашения</Typography>
        <Splitter variant='horizontal' />
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={1}
          direction='column'
        >
          {agreements?.map(agreement => (
            <Grid
              item
              key={agreement.path}
            >
              <FileInfo
                id={agreement.path}
                onRemove={() => onRemoveAgreement(agreement)}
              />
            </Grid>
          ))}
          <Grid item>
            <FileUploader
              text='Загрузите документ'
              filesMaxCount={9999}
              icon={<ContentTypeDocumentIcon />}
              fileTypes={[fileTypes.pdf.mimeType, fileTypes.doc.mimeType, fileTypes.docx.mimeType]}
              accept={[fileTypes.pdf.ext, fileTypes.docx.ext, fileTypes.docx.ext]}
              onUpload={file => onUploadAgreement(file)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PartnerApplicationEditStepPermissions;
