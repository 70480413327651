import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Nullable, UUID } from '../../../../../domain/model/types';
import DataFilterComponent from '../../../../components/common/dataFilter';
import { getProductOrdersFilterStrategies } from '../filterUtils';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { productOrdersFilterSelector } from '../store/selectors';

interface ProductOrderTableFilterAdapterProps {
  readonly customerId: Nullable<UUID>;
}

const ProductOrderTableFilterAdapter = (props: ProductOrderTableFilterAdapterProps) => {
  const { customerId } = props;

  const handlers = useContextHandlers();

  const filter = useSelector(productOrdersFilterSelector);

  const filterStrategies = getProductOrdersFilterStrategies(customerId, filter);

  return useMemo(
    () => (
      <DataFilterComponent
        strategies={filterStrategies}
        onChange={handlers.onChangeFilter}
      />
    ),
    [filterStrategies, handlers.onChangeFilter]
  );
};

export default ProductOrderTableFilterAdapter;
