import { CaseReducer, createSelector, createSlice, PayloadAction, Selector, SliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from '../../../../data/store/store';
import { UUID } from '../../../../domain/model/types';

export interface MenuPanelLayoutState {
  readonly scrollPosition: {
    readonly [type: string]: number;
  };
}

type Reducer<T> = CaseReducer<MenuPanelLayoutState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<MenuPanelLayoutState> {
  menuPanelLayoutSetScrollPosition: Reducer<{ guid: UUID; scrollPosition: number }>;
}

const slice = createSlice<MenuPanelLayoutState, Reducers, 'layout/menu'>({
  name: 'layout/menu',
  initialState: {
    scrollPosition: {},
  },
  reducers: {
    menuPanelLayoutSetScrollPosition(state, { payload }) {
      const {guid, scrollPosition} = payload
      state.scrollPosition[guid] = scrollPosition;
    },
  },
});

export const { menuPanelLayoutSetScrollPosition } = slice.actions;

export default slice.reducer;

const menuPanelsSelector: Selector<RootState, MenuPanelLayoutState> = state => state.layout.menuPanel;
const menuPanelSelector: Selector<RootState, UUID, [UUID]> = (state, guid) => guid;

export const createMenuPanelLayoutScrollPositionSelector = createSelector(
  menuPanelsSelector,
  menuPanelSelector,
  (menuPanels, guid) => menuPanels.scrollPosition[guid]
);