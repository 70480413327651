import { EDateFormat } from '@/domain';
import moment from 'moment/moment';
import DataTableCellTextFormat from '../../../../../../../components/common/table/cell/textFormat';

interface OfferTableCellEndDateProps {
  readonly endDate: Nullable<string>;
}

const OfferTableCellEndDate = ({ endDate }: OfferTableCellEndDateProps) => (
  <DataTableCellTextFormat>{moment(endDate).format(EDateFormat.DisplayDefault)}</DataTableCellTextFormat>
);

export default OfferTableCellEndDate;
