import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { ActivityEvent } from '@/domain';
import {
  CaseReducer,
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { activityEventCreatePublish, activityEventCreateSave } from '../../../create/activity/store/slice';
import eventServices from '../../../services';
import { ActivityEventViewUiState } from '../types';

export const activityEventViewByIdFetch = createAsyncThunk<ActivityEvent, UUID, AppThunkAPIConfig>(
  'activityEvent/view/byId/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      return await eventServices.activity.one({ id, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const activityEventViewCancel = createAsyncThunk<void, UUID, AppThunkAPIConfig>(
  'activityEvent/view/cancel',
  async (id, { rejectWithValue }) => {
    try {
      await eventServices.activity.cancel({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface ActivityEventViewState {
  readonly guid: Nullable<UUID>;
  readonly byId: Fetchable & {
    readonly activityEvent: Nullable<ActivityEvent>;
  };
  readonly dialogs: {
    readonly cancel: Nullable<ActivityEvent>;
  };
  readonly ui: Nullable<ActivityEventViewUiState>;
  readonly cancel: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<ActivityEventViewState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ActivityEventViewState> {
  activityEventViewStartSession: Reducer<{ guid: UUID }>;
  activityEventViewClearActionsState: Reducer;
  activityEventViewSetUiState: Reducer<{ name: keyof ActivityEventViewUiState; value: any }>;
  activityEventViewSetDialogState: Reducer<{ name: keyof ActivityEventViewState['dialogs']; data: Nullable<any> }>;
}

const slice = createSlice<ActivityEventViewState, Reducers, 'activityEvent/create'>({
  name: 'activityEvent/create',
  initialState: {
    guid: null,
    byId: {
      ...fetchableDefault,
      activityEvent: null,
    },
    dialogs: {
      cancel: null,
    },
    ui: null,
    cancel: {
      ...fetchableDefault,
    },
  },
  reducers: {
    activityEventViewStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;
        state.byId = {
          ...fetchableDefault,
          activityEvent: null,
        };
        state.dialogs = {
          cancel: null,
        };
        state.ui = null;
      }
    },
    activityEventViewClearActionsState: state => {
      state.byId = { ...fetchableDefault, activityEvent: null };
      state.cancel = {
        ...fetchableDefault,
      };
    },
    activityEventViewSetUiState: (state, { payload }) => {
      const { name, value } = payload;
      if (!state.ui) {
        state.ui = {
          steps: [],
        };
      }
      state.ui[name] = value;
    },
    activityEventViewSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(activityEventViewByIdFetch.pending, (state, { meta }) => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        if (state.byId.activityEvent?.id !== meta.arg) {
          state.byId.activityEvent = null;
        }
      })
      .addCase(activityEventViewByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;
        state.byId.activityEvent = payload;
      })
      .addCase(activityEventViewByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = false;
        } else {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        }
        state.byId.activityEvent = null;
      })
      .addCase(activityEventViewCancel.pending, state => {
        state.cancel.isFetching = true;
        state.cancel.isFetched = false;
        state.cancel.isFailed = false;
      })
      .addCase(activityEventViewCancel.fulfilled, state => {
        state.cancel.isFetching = false;
        state.cancel.isFetched = true;
        state.cancel.isFailed = false;
      })
      .addCase(activityEventViewCancel.rejected, state => {
        state.cancel.isFetching = false;
        state.cancel.isFetched = false;
        state.cancel.isFailed = true;
      })
      .addMatcher(isAnyOf(activityEventCreateSave.fulfilled, activityEventCreatePublish.fulfilled), state => {
        state.guid = null;
      });
  },
});

export const {
  activityEventViewStartSession,
  activityEventViewClearActionsState,
  activityEventViewSetUiState,
  activityEventViewSetDialogState,
} = slice.actions;

export default slice.reducer;
