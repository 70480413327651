import { getUserFullName } from '../../../../utils';
import TradeActivationTableCell, { TradeActivationTableCellCommonProps } from './index';

const TradeActivationTableCellCustomerName = (props: TradeActivationTableCellCommonProps) => {
  const {
    tradeActivation: { customer },
  } = props;

  return (
    <TradeActivationTableCell
      {...props}
      value={customer && getUserFullName({ user: customer })}
    />
  );
};

export default TradeActivationTableCellCustomerName;
