import ErrorHandler from '@/data/network/errorHandler';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { Pageable } from '@/domain/model';
import { ClientOrg } from '@/domain/model/clientOrg';
import { Nullable, UUID } from '@/domain/model/types';
import { PaginationSize } from '@/presentation/types';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { ClientOrgTableFilterValues } from '../../filterUtils';
import clientOrgServices, { AllProps } from '../../services';
import { EClientOrgTableTab } from '../../types';
import { ClientOrgTableTabsCounter, EClientOrgTableColumn } from '../utils';

const defaultSort = `${EClientOrgTableColumn.Name},asc`;

export interface ClientOrgListState extends Fetchable, Pageable<ClientOrg> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly totalCount: number;
  readonly pageCount: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
  };
  readonly tab: Nullable<EClientOrgTableTab>;
  readonly tabsCounter: ClientOrgTableTabsCounter;
  readonly filter: ClientOrgTableFilterValues;
  readonly allClientOrgs: Fetchable & {
    readonly count: Nullable<number>;
    readonly needRefreshWatcher: number;
  };
}

type Reducer<T = undefined> = CaseReducer<ClientOrgListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ClientOrgListState> {
  clientOrgsDataReset: Reducer;
  clientOrgsSetTab: Reducer<{ tab: EClientOrgTableTab }>;
  clientOrgsStartSession: Reducer<{ guid: UUID }>;
  clientOrgsSetFilter: Reducer<ClientOrgTableFilterValues>;
  clientOrgsSortReset: Reducer;
  clientOrgsSetSort: Reducer<{ sort: string }>;
  clientOrgsNeedRefreshWatcherReset: Reducer;
  clientOrgsNeedRefreshWatcherIncrement: Reducer;
  clientOrgsSetPage: Reducer<{ pageNumber: number }>;
  clientOrgsSetPageSize: Reducer<{ pageSize: PaginationSize }>;
}

export type ClientOrgsFetchProps = Omit<AllProps, 'signal'>;
export type ClientOrgsCountsFetchProps = ClientOrgsFetchProps & {
  readonly tabs: EClientOrgTableTab[];
};

export const clientOrgsFetch = createAsyncThunk<Pageable<ClientOrg>, ClientOrgsFetchProps, AppThunkAPIConfig>(
  'clientOrg/list/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await clientOrgServices.clientOrg.all({ ...props, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const clientOrgsCountFetch = createAsyncThunk<number, ClientOrgsFetchProps, AppThunkAPIConfig>(
  'clientOrg/list/count/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await clientOrgServices.clientOrg.count({ ...props, signal });
    } catch (e: any) {
      console.error(e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const clientOrgsCountsFetch = createAsyncThunk<
  ClientOrgTableTabsCounter,
  ClientOrgsCountsFetchProps,
  AppThunkAPIConfig
>('clientOrg/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await clientOrgServices.clientOrg.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      errors.map(e => {
        console.error(e);
      });
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

const slice = createSlice<ClientOrgListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    guid: null,
    needRefreshWatcher: 0,
    pageNumber: 1,
    totalCount: 0,
    pageCount: 0,
    tab: null,
    data: [],
    tabsCounter: {},
    filter: {},
    ...fetchableDefault,
    search: {
      sort: defaultSort,
      pageSize: 10,
    },
    allClientOrgs: {
      ...fetchableDefault,
      count: null,
      needRefreshWatcher: 0,
    },
  },
  reducers: {
    clientOrgsStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;

        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = false;

        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;
        state.needRefreshWatcher = 0;

        state.filter = {};
        state.search = {
          sort: defaultSort,
          pageSize: 10,
        };

        state.allClientOrgs = {
          ...fetchableDefault,
          count: null,
          needRefreshWatcher: 0,
        };

        state.tab = null;
      }
    },
    clientOrgsSetTab: (state, { payload }) => {
      const { tab } = payload;
      // сбрасываем пейджинг и сортировку, если поменялись статусы (закладки)
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    clientOrgsSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    clientOrgsDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
    },
    clientOrgsSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    clientOrgsSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    clientOrgsNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
      state.allClientOrgs.needRefreshWatcher = 0;
    },
    clientOrgsNeedRefreshWatcherIncrement: state => {
      state.needRefreshWatcher++;
    },
    clientOrgsSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    clientOrgsSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(clientOrgsFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
      })
      .addCase(clientOrgsFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(clientOrgsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(clientOrgsCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(clientOrgsCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      })
      .addCase(clientOrgsCountFetch.pending, state => {
        state.allClientOrgs.isFetching = true;
        state.allClientOrgs.isFetched = false;
        state.allClientOrgs.isFailed = false;

        if (state.allClientOrgs.count === 0) {
          //сбрасываем в null в том случае если до этого ничего не было, чтобы не моргала таблица лишний раз
          state.allClientOrgs.count = null;
        }
      })
      .addCase(clientOrgsCountFetch.fulfilled, (state, { payload }) => {
        state.allClientOrgs.isFetching = false;
        state.allClientOrgs.isFetched = true;
        state.allClientOrgs.isFailed = false;

        state.allClientOrgs.count = payload;
      })
      .addCase(clientOrgsCountFetch.rejected, state => {
        state.allClientOrgs.isFetching = false;
        state.allClientOrgs.isFetched = false;
        state.allClientOrgs.isFailed = true;

        state.allClientOrgs.count = null;
      });
  },
});
export const {
  clientOrgsStartSession,
  clientOrgsSetTab,
  clientOrgsSetFilter,
  clientOrgsDataReset,
  clientOrgsSetPage,
  clientOrgsNeedRefreshWatcherReset,
  clientOrgsSortReset,
  clientOrgsSetPageSize,
  clientOrgsSetSort,
  clientOrgsNeedRefreshWatcherIncrement,
} = slice.actions;

export default slice.reducer;
