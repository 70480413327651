import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../data/store/store';
import { UserAccessMatrix } from '../../../../../domain/model/accessMatrix';
import { ENoticeStatus, EUserRole } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { AppUser } from '../../../../../domain/model/user';
import Notifier from '../../../../../system/notifier';
import ContentLoader from '../../../../components/common/loader';
import useTechConfig from '../../../../hooks/useTechConfig';
import { buildUserAccessMatrix, buildUserAccessMatrixCommon } from '../../securityUtils';
import { userCurrentSpecificFailedSelector, userCurrentSpecificSelector } from '../store/selectors';
import {
  userCurrentMpAdminSpecificFetch,
  userCurrentMpCommonUserSpecificFetch,
  userCurrentMpPartnerAdminSpecificFetch,
  userCurrentMpPartnerManagerSpecificFetch,
  userCurrentSportSpecificFetch,
} from '../store/slice';
import CurrentUserContext, { CurrentUserContextType } from './context';

const logOutTimeoutMs = 8000 as const;

interface CurrentUserProviderProps {
  readonly user: AppUser;
  readonly roles: EUserRole[];
  readonly logOut: () => void;
  readonly refreshToken: () => void;
  readonly resetPassword: () => void;
  readonly children: JSX.Element;
}

type CurrentUserProviderType = (props: CurrentUserProviderProps) => JSX.Element;

const CurrentUserProvider: CurrentUserProviderType = props => {
  const { user, roles, logOut, refreshToken, resetPassword, children } = props;

  const dispatch = useAppDispatch();

  const userSpecific = useSelector(userCurrentSpecificSelector);
  const userSpecificFailed = useSelector(userCurrentSpecificFailedSelector);

  const hasRole = (...requestedRoles: EUserRole[]) =>
    roles?.some(role => requestedRoles.some(r => r === role)) ?? false;
  // TODO (@Protopopov Ruslan): мб зареплейсить на это? double some пугает
  // const hasRole2 = (...requestedRoles: EUserRole[]) => roles?.some(role => requestedRoles.includes(role)) ?? false;
  const hasAnyRole = (roles && roles?.length > 0) ?? false;

  const [accessMatrix, setAccessMatrix] = useState<Nullable<UserAccessMatrix>>(null);

  const { hasFeature, getFeatureOptions } = useTechConfig();

  const refreshUserSpecific = useCallback(() => {
    if (accessMatrix?.isAdminSport) {
      dispatch(userCurrentSportSpecificFetch({ roles }));
      return;
    }
    if (accessMatrix?.isAdminMp) {
      dispatch(userCurrentMpAdminSpecificFetch());
      return;
    }
    if (accessMatrix?.isAdminPartner) {
      dispatch(userCurrentMpPartnerAdminSpecificFetch());
      return;
    }
    if (accessMatrix?.isManagerPartner) {
      dispatch(userCurrentMpPartnerManagerSpecificFetch());
      return;
    }
    if (accessMatrix?.isMpUser) {
      dispatch(userCurrentMpCommonUserSpecificFetch());
      return;
    }
  }, [dispatch, accessMatrix]);

  // проверка наличия вообще ролей
  useEffect(() => {
    if (!hasAnyRole) {
      Notifier.getInstance().addNotice(ENoticeStatus.Error, 'Вы не обладаете полномочиями');
    }
  }, [dispatch, hasAnyRole]);

  // загрузка текущего специфического юзера в соответствии с ролями
  useEffect(() => {
    const accessMatrixCommon = buildUserAccessMatrixCommon(roles, hasRole);

    if (accessMatrixCommon.isAdminSport) {
      const promise = dispatch(userCurrentSportSpecificFetch({ roles }));
      return () => {
        promise?.abort();
      };
    }
    if (accessMatrixCommon.isAdminMp) {
      const promise = dispatch(userCurrentMpAdminSpecificFetch());
      return () => {
        promise?.abort();
      };
    }
    if (accessMatrixCommon.isAdminPartner) {
      const promise = dispatch(userCurrentMpPartnerAdminSpecificFetch());
      return () => {
        promise?.abort();
      };
    }
    if (accessMatrixCommon.isManagerPartner) {
      const promise = dispatch(userCurrentMpPartnerManagerSpecificFetch());
      return () => {
        promise?.abort();
      };
    }
    if (accessMatrixCommon.isMpUser) {
      const promise = dispatch(userCurrentMpCommonUserSpecificFetch());
      return () => {
        promise?.abort();
      };
    }

    setTimeout(logOut, logOutTimeoutMs);
    Notifier.getInstance().addNotice(
      ENoticeStatus.Error,
      'Не удалось определить полномочия пользователя. Сеанс будет завершен.'
    );
    console.error('Not existed specific for current user');
  }, [dispatch, roles]);

  // пересчет матрицы доступа
  useEffect(() => {
    if (userSpecific) {
      setAccessMatrix(buildUserAccessMatrix(userSpecific, roles, hasRole, hasFeature, getFeatureOptions));
    }
  }, [userSpecific, roles]);

  // ошибка загрузки текущего специфического юзера
  useEffect(() => {
    if (userSpecificFailed) {
      Notifier.getInstance().addNotice(
        ENoticeStatus.Error,
        'При получении информации о текущем пользователе произошла ошибка'
      );
      console.error('Error at request current user specific');

      const logOutTimeout = setTimeout(logOut, logOutTimeoutMs);
      return () => {
        clearTimeout(logOutTimeout);
      };
    }
  }, [userSpecificFailed]);

  if (!accessMatrix || !userSpecific) {
    return <ContentLoader />;
  }

  console.debug('Access matrix', accessMatrix);

  const value: CurrentUserContextType = {
    user,
    userSpecific,
    accessMatrix,

    sportUserProfile: userSpecific?.sport!,
    mpPartnerUserProfile: userSpecific?.mpPartner!,
    mpAdminUserProfile: userSpecific?.mpAdmin!,

    defaultRoute: accessMatrix.defaultRoute,

    logOut,
    refreshToken,
    resetPassword,
    refreshUserSpecific,
  };

  return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>;
};

export default CurrentUserProvider;
