import styled from '@emotion/styled';
import moreVertical from '../../../../icons/moreVertical.png';

export const Wrapper = styled.div`
  position: relative;

  &:before {
    width: 10px;
    height: 100%;

    left: 0;
    position: absolute;
    z-index: 1;
    content: '';
    background: url(${moreVertical}) no-repeat center center;
    display: none;
  }

  &:hover {
    :before {
      display: block;
    }
  }
`;
