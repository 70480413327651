import Axios from 'axios';
import Api from '../../../../data/api';
import { AllProps as ApiAllProps } from '../../../../data/api/complaint';
import { ApiCancellable, ApiRequestListDiscriminator } from '../../../../data/api/types';
import { Pageable } from '../../../../domain/model';
import { Complaint } from '../../../../domain/model/complaint';
import { EComplaintStatus } from '../../../../domain/model/enums';
import { Nullable } from '../../../../domain/model/types';
import { PaginationSize } from '../../../types';
import {
  ComplaintTableFilterValues,
  EComplaintTableFilterItem,
  getStatesFilterForComplaintTableTab,
} from '../table/filterUtils';
import { ComplaintCounterByStatus, ComplaintTableTabsCounter, EComplaintTableTab } from '../table/utils';

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string>;
    readonly statuses: EComplaintStatus[];
  };
  readonly filter: ComplaintTableFilterValues;
  readonly pageNumber: number;
};

type CountsByStatusesProps = Omit<AllProps, 'pageNumber'>;

export type CountProps = Omit<AllProps, 'pageNumber'>;

type CountsByTabsProps = CountsByStatusesProps & {
  readonly tabs: EComplaintTableTab[];
};

export type ComplaintCommonService = {
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps
  ) => ApiAllProps<D>;
  readonly all: (props: AllProps) => Promise<Pageable<Complaint>>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly countsByStatuses: (
    props: CountsByStatusesProps
  ) => Promise<{ counts: ComplaintCounterByStatus; errors: Array<string> }>;
  readonly countsByTabs: (
    props: CountsByTabsProps
  ) => Promise<{ counts: ComplaintTableTabsCounter; errors: Array<string> }>;
};

const service: ComplaintCommonService = {
  buildListQueryParams: ({ search, filter, pageNumber, signal }) => {
    const { pageSize, sort, statuses } = search;

    const name = filter[EComplaintTableFilterItem.ObjectName]?.value;
    const objectTypes = filter[EComplaintTableFilterItem.ObjectType]?.value;
    const causeCodes = filter[EComplaintTableFilterItem.Cause]?.value;
    const admin = filter[EComplaintTableFilterItem.ExecutedBy]?.value;

    return {
      page: pageNumber,
      pageSize,
      sort,
      name,
      statuses,
      objectTypes,
      causeCodes,
      admin,
      signal,
    };
  },
  all: async props => {
    const { data: response } = await Api.complaint.all(service.buildListQueryParams(props));

    const { content: data, totalElements: totalCount, totalPages: pageCount, number: page } = response;
    return { data, totalCount, pageCount, pageNumber: page };
  },
  count: async props => {
    const { data: response } = await Api.complaint.all({
      ...service.buildListQueryParams({
        ...props,
        pageNumber: 1,
      }),
      discriminator: ApiRequestListDiscriminator.Count,
    });

    return response.count;
  },
  countsByStatuses: async ({ search, filter, signal }) => {
    const errors: string[] = [];
    const counts: ComplaintCounterByStatus = {};
    const { statuses } = search;

    const requests = statuses.map(status =>
      service.count({
        search: { ...search, statuses: [status] },
        filter,
        signal,
      })
    );

    const responses = await Promise.allSettled<Promise<number>[]>(requests);
    const parseResponse = (response: typeof responses[0], status: EComplaintStatus) => {
      if (response.status === 'fulfilled') {
        counts[status] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество жалоб '${status}': ${response.reason}`);
        }
      }
    };
    statuses.forEach((status, index) => parseResponse(responses[index], status));

    return { counts, errors };
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: ComplaintTableTabsCounter = {};

    const tabsWithStatuses = tabs.filter(tab => {
      const statuses = getStatesFilterForComplaintTableTab(tab);
      return !!statuses.length;
    });
    const tabsWithoutStatuses = tabs.filter(tab => {
      const statuses = getStatesFilterForComplaintTableTab(tab);
      return !statuses.length;
    });

    //табы для которых есть статусы
    const requestsWithStatuses = tabsWithStatuses.map(tab => {
      const statuses = getStatesFilterForComplaintTableTab(tab);
      const params = {
        ...props,
        search: { ...props.search, statuses },
      };
      return service.countsByStatuses({ ...params, signal });
    });

    //табы для которых нет статусов
    const requestsWithoutStatuses = tabsWithoutStatuses.map(() => {
      const params = {
        ...props,
        search: { ...props.search, statuses: [] },
      };
      return service.count({ ...params, signal });
    });

    const responsesWithStatuses = await Promise.allSettled(requestsWithStatuses);
    const responsesWithoutStatuses = await Promise.allSettled(requestsWithoutStatuses);

    const parseResponseWithStatuses = (response: typeof responsesWithStatuses[0], tab: EComplaintTableTab) => {
      if (response.status === 'fulfilled') {
        const statuses = getStatesFilterForComplaintTableTab(tab);
        counts[tab] = statuses.reduce<number>((prev, current) => prev + (response.value.counts?.[current] ?? 0), 0);
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество жалоб '${tab}': ${response.reason}`);
        }
      }
    };

    const parseResponseWithoutStatuses = (response: typeof responsesWithoutStatuses[0], tab: EComplaintTableTab) => {
      if (response.status === 'fulfilled') {
        counts[tab] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество жалоб '${tab}': ${response.reason}`);
        }
      }
    };

    tabsWithStatuses.forEach((tab, index) => parseResponseWithStatuses(responsesWithStatuses[index], tab));
    tabsWithoutStatuses.forEach((tab, index) => parseResponseWithoutStatuses(responsesWithoutStatuses[index], tab));

    return { counts, errors };
  },
};

export default service;
