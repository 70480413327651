export interface Fetchable {
  isFetching: boolean;
  isFetched: boolean;
  isFailed: boolean;
}

export const fetchableDefault: Fetchable = {
  isFetching: false,
  isFetched: false,
  isFailed: false,
};

export const fetchableFetching: Fetchable = {
  ...fetchableDefault,
  isFetching: true,
};

export const fetchableFetched: Fetchable = {
  ...fetchableDefault,
  isFetched: true,
};

export const fetchableFailed: Fetchable = {
  ...fetchableDefault,
  isFailed: true,
};
