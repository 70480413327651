import TabsPanelCounter from '../../../components/common/tabs/counter/counter';
import TabsPanelLabel from '../../../components/common/tabs/label/label';
import { ECorpActivationTableTab } from '../types';
import { CorpActivationTableTabsCounter, getCorpActivationTableTabName } from '../utils/table';

interface CorpActivationTableTabProps {
  readonly tab: ECorpActivationTableTab;
  readonly counts: CorpActivationTableTabsCounter;
}

const CorpActivationTableTab = ({ tab, counts }: CorpActivationTableTabProps) => {
  const count = counts[tab];

  return (
    <TabsPanelLabel title={getCorpActivationTableTabName(tab)}>
      <TabsPanelCounter count={count} />
    </TabsPanelLabel>
  );
};

export default CorpActivationTableTab;
