import { ECurrentUserActionType } from './types';

export const getCurrentUserActionName = (type: ECurrentUserActionType): string => {
  switch (type) {
    case ECurrentUserActionType.Edit:
      return 'Редактировать';
    case ECurrentUserActionType.Logout:
      return 'Выйти из системы';
  }
};
