import { CircularProgress, FormControl, FormHelperText, InputLabel, SelectProps, useTheme } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { Children } from 'react';
import { MPTheme } from '../../types';
import { FormControlTooltip } from '../common';
import { MPSelectClearButtonWrapper, Select } from './controls';

export interface MPSelectProps<T> extends Omit<SelectProps, 'onChange' | 'variant' | 'renderValue'> {
  readonly helperText?: string;
  readonly isFetching?: boolean;
  readonly helperInTooltip?: boolean;
  readonly disableOnOneItem?: boolean;
  readonly dense?: boolean;
  readonly variant?: SelectProps['variant'] | 'text';
  readonly renderValue?: (value: T) => React.ReactNode;
  readonly onChange: (value: T, child: React.ReactNode) => void;
}

function MPSelectInternal<T>(props: MPSelectProps<T> & { forwardedRef?: any }) {
  const {
    fullWidth = true,
    dense = true,
    error,
    disableOnOneItem,
    helperText,
    helperInTooltip,
    onChange,
    renderValue,
    forwardedRef,
    variant = 'outlined',
    isFetching,
    ...selectProps
  } = props;

  const theme = useTheme() as MPTheme;

  const tooltipInvisible = !helperText || !helperInTooltip;

  const control = (
    <FormControl
      variant='outlined'
      fullWidth={fullWidth}
      margin='dense'
    >
      <InputLabel
        error={error}
        shrink={!!selectProps.value}
      >
        {selectProps.label}
      </InputLabel>
      <Select
        forwardedRef={forwardedRef}
        IconComponent={
          isFetching
            ? () => (
                <div style={{ paddingRight: theme.spacing(2) }}>
                  <CircularProgress size={20} />
                </div>
              )
            : undefined
        }
        dense={dense}
        variant={variant as SelectProps['variant']}
        error={error}
        renderValue={renderValue as any}
        onChange={(event, child) => {
          event.stopPropagation();
          onChange?.(event.target.value as any, child);
        }}
        {...selectProps}
        disabled={
          selectProps.disabled ||
          (disableOnOneItem && Children.count(selectProps.children) === 1 && !!selectProps.value)
        }
      >
        {selectProps.children}
      </Select>
      {helperText && !helperInTooltip && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );

  if (helperText && helperInTooltip) {
    return (
      <FormControlTooltip
        variant={error ? 'error' : 'default'}
        placement='right-start'
        arrow
        disableFocusListener={tooltipInvisible}
        disableHoverListener={tooltipInvisible}
        disableTouchListener={tooltipInvisible}
        title={helperText as string}
      >
        {control}
      </FormControlTooltip>
    );
  }

  return control;
}

export const MPSelectClearButton = (props: SvgIconProps) => {
  const { onClick, ...other } = props;

  const handleClick = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    onClick?.(e);
  };

  return (
    <MPSelectClearButtonWrapper
      onClick={handleClick}
      {...other}
    />
  );
};

export const MPSelect = React.forwardRef<typeof MPSelectInternal, any>(<T,>(props: MPSelectProps<T>, ref: any) => (
  <MPSelectInternal<T>
    forwardedRef={ref}
    {...props}
  />
)) as typeof MPSelectInternal;
