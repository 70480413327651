import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/data/store/store';
import { UUID } from '@/domain';
import Splitter from '../../../../components/common/splitter';
import PartnerDeskByIdContainer from '../../../partnerDesk/byId/container';
import PartnerDeskRestrictionContainer from '../../../partnerDesk/restriction/container';
import { BookingOfferTableStubAdapterProps } from '../../types';

export type BookingOffersPartnerStubAdapterProps = BookingOfferTableStubAdapterProps & {
  readonly isEmptySelector: (state: RootState) => boolean;
  readonly partnerId: UUID;
};

const BookingOffersPartnerStubAdapter = (props: BookingOffersPartnerStubAdapterProps) => {
  const { partnerId, isEmptySelector, children } = props;

  const isEmpty = useSelector(isEmptySelector);

  const getStub = useCallback(
    (hasPartnerDesk: boolean) => {
      if (!hasPartnerDesk) {
        return (
          <PartnerDeskRestrictionContainer
            partnerId={partnerId}
            text='Для того, чтобы создать объекты в разделе “Досуг и отдых”, необходимо заполнить карточку компании, которая будет отображаться на Витрине.'
            description='Карточка компании будет объединять все объекты раздела “Досуг и отдых” вашей компании.'
          />
        );
      }

      if (isEmpty) {
        return (
          <>
            <Splitter size={1} />
            <Typography color='textSecondary'>Здесь будут ваши объекты раздела “Досуг и отдых”</Typography>
          </>
        );
      }

      return null;
    },
    [partnerId, isEmpty]
  );

  const getCreateInfo = useCallback(
    (hasPartnerDesk: boolean) => {
      if (!hasPartnerDesk) {
        return 'Создание предложение в разделе “Досуг и отдых” будет доступно после заполнения карточки компании';
      }
      if (isEmpty) {
        return 'Чтобы создать первый объект в разделе “Досуг и отдых” нажмите на кнопку “Создать” и заполните форму';
      }

      return null;
    },
    [isEmpty]
  );

  return (
    <PartnerDeskByIdContainer id={partnerId}>
      {({ partnerDesk }) =>
        children({
          canViewTable: !!partnerDesk && !isEmpty,
          isCreateDisabled: !partnerDesk,
          createInfo: getCreateInfo(!!partnerDesk),
          stub: getStub(!!partnerDesk),
        })
      }
    </PartnerDeskByIdContainer>
  );
};

export default BookingOffersPartnerStubAdapter;
