import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { UUID } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import { EPartnerStep } from '../../features/general/partner/types';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import PartnerApplicationDetailsContainer from '../../features/partnerApplication/details/container';
import { EPartnerApplicationUrlParam } from '../../features/partnerApplication/entry';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const PartnerApplicationDetailsScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { partners } = useCurrentUser().accessMatrix;

  const { id } = useParams<{ id: UUID }>();
  const searchParams = new URLSearchParams(location.search);
  const step = (searchParams.get(EPartnerApplicationUrlParam.Step) as EPartnerStep) ?? EPartnerStep.General;

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  if (!partners?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <PartnerApplicationDetailsContainer
      partnerId={id}
      step={step}
      canEdit={partners?.edit}
      isAdmin={partners?.viewAsAdmin}
    />
  );
};

export default PartnerApplicationDetailsScreen;
