import { useContext } from 'react';
import BusinessConfigContext, {
  BusinessConfigContextType,
} from '../features/general/config/contexts/businessConfigContext';

type UseBusinessConfig = () => BusinessConfigContextType;

const useBusinessConfig: UseBusinessConfig = () => {
  return useContext<BusinessConfigContextType>(BusinessConfigContext);
};

export default useBusinessConfig;
