import Api from '../../../../data/api';
import { ApiCancellable } from '../../../../data/api/types';
import { CmsBanner, CmsComponentData, CmsComponentLinked } from '../../../../domain/model/cms';
import { ECmsBannerLinkObjectType, ECmsLinkObjectType } from '../../../../domain/model/enums';
import { UUID } from '../../../../domain/model/types';
import { getEmptyCmsLinkedObjectBanner } from '../container/create/utils';
import {
  CmsLinkedObjectBanner,
  CmsLinkedObjectBannerCategoryType,
  CmsLinkedObjectBannerCorpOfferType,
  CmsLinkedObjectBannerTradeOfferType,
} from '../types/banner';
import cmsServices from './index';

type ByIdProps = ApiCancellable & {
  readonly id: UUID;
};

type GetByLinkedObjectIdProps = ApiCancellable & {
  readonly id: UUID;
};

type GetByComponentIdProps = ApiCancellable & {
  readonly cmsComponent: CmsComponentLinked;
  //игнорировать прогрузку глубоких объектов
  readonly ignoreDeepObjects?: boolean;
};

export type CmsBannerService = {
  readonly byId: (props: ByIdProps) => Promise<CmsBanner>;
  readonly getLinkedObject: (props: GetByComponentIdProps) => Promise<CmsLinkedObjectBanner>;
  readonly getBannerCorpOfferByLinkObjectId: (
    props: GetByLinkedObjectIdProps
  ) => Promise<CmsLinkedObjectBannerCorpOfferType>;
  readonly getBannerTradeOfferByLinkObjectId: (
    props: GetByLinkedObjectIdProps
  ) => Promise<CmsLinkedObjectBannerTradeOfferType>;
  readonly getBannerCorpOfferCategoryByLinkObjectId: (
    props: GetByLinkedObjectIdProps
  ) => Promise<CmsLinkedObjectBannerCategoryType>;
  readonly getBannerTradeOfferCategoryByLinkObjectId: (
    props: GetByLinkedObjectIdProps
  ) => Promise<CmsLinkedObjectBannerCategoryType>;
};

const service: CmsBannerService = {
  byId: async ({ id, signal }): Promise<CmsBanner> => {
    const { data } = await Api.cms.banner.one({ id, signal });
    return data;
  },
  getLinkedObject: async ({ cmsComponent, ignoreDeepObjects, signal }): Promise<CmsLinkedObjectBanner> => {
    const { linkObjectId: id, zone, pageSize } = cmsComponent;
    const cmsComponentData: CmsComponentData = { zone, pageSize };

    const banner = await cmsServices.banner.byId({
      id,
      signal,
    });

    if (banner.linkObjectType === ECmsBannerLinkObjectType.Link) {
      return {
        type: ECmsLinkObjectType.Banner,
        banner: {
          ...banner,
          ...cmsComponentData,
          linkObjectType: ECmsBannerLinkObjectType.Link,
          linkedObject: banner.url,
        },
      };
    }

    if (!banner.linkObjectId) {
      const emptyCmsLinkedObjectBanner = getEmptyCmsLinkedObjectBanner(banner.linkObjectType);
      const { linkObjectType, ...cmsBannerCommonAttributes } = banner;
      return {
        ...emptyCmsLinkedObjectBanner,
        banner: {
          ...emptyCmsLinkedObjectBanner.banner,
          ...cmsBannerCommonAttributes,
        },
      };
    }

    switch (banner.linkObjectType) {
      case ECmsBannerLinkObjectType.CorpOffer:
        const bannerCorpOffer = await cmsServices.banner.getBannerCorpOfferByLinkObjectId({
          id: banner.linkObjectId,
          signal,
        });
        return {
          type: ECmsLinkObjectType.Banner,
          banner: {
            ...banner,
            ...bannerCorpOffer,
            ...cmsComponentData,
          },
        };

      case ECmsBannerLinkObjectType.TradeOffer:
        const bannerTradeOffer = await cmsServices.banner.getBannerTradeOfferByLinkObjectId({
          id: banner.linkObjectId,
          signal,
        });
        return {
          type: ECmsLinkObjectType.Banner,
          banner: {
            ...banner,
            ...bannerTradeOffer,
            ...cmsComponentData,
          },
        };

      case ECmsBannerLinkObjectType.CorpOfferCategory:
        const bannerCorpOfferCategory = await cmsServices.banner.getBannerCorpOfferCategoryByLinkObjectId({
          id: banner.linkObjectId,
          signal,
        });
        return {
          type: ECmsLinkObjectType.Banner,
          banner: {
            ...banner,
            ...bannerCorpOfferCategory,
            ...cmsComponentData,
          },
        };

      case ECmsBannerLinkObjectType.TradeOfferCategory:
        const bannerTradeOfferCategory = await cmsServices.banner.getBannerTradeOfferCategoryByLinkObjectId({
          id: banner.linkObjectId,
          signal,
        });
        return {
          type: ECmsLinkObjectType.Banner,
          banner: {
            ...banner,
            ...bannerTradeOfferCategory,
            ...cmsComponentData,
          },
        };

      case ECmsBannerLinkObjectType.Collection:
        const bannerCollection = await cmsServices.collection.getCollectionByLinkObjectId({
          id: banner.linkObjectId,
          ignoreData: ignoreDeepObjects,
          signal,
        });
        return {
          type: ECmsLinkObjectType.Banner,
          banner: {
            ...banner,
            linkObjectType: ECmsBannerLinkObjectType.Collection,
            linkedObject: bannerCollection,
            ...cmsComponentData,
          },
        };
      default:
        // todo
        console.warn('Not implemented');
        return {} as any;
    }
  },
  getBannerCorpOfferByLinkObjectId: async ({ id, signal }): Promise<CmsLinkedObjectBannerCorpOfferType> => {
    const { data: linkedObject } = await Api.corpOffer.one({
      id,
      signal,
    });
    return {
      linkObjectType: ECmsBannerLinkObjectType.CorpOffer,
      linkedObject,
    };
  },
  getBannerTradeOfferByLinkObjectId: async ({ id, signal }): Promise<CmsLinkedObjectBannerTradeOfferType> => {
    const { data: linkedObject } = await Api.tradeOffer.one({
      id,
      signal,
    });
    return {
      linkObjectType: ECmsBannerLinkObjectType.TradeOffer,
      linkedObject,
    };
  },
  getBannerCorpOfferCategoryByLinkObjectId: async ({ id, signal }): Promise<CmsLinkedObjectBannerCategoryType> => {
    const { data: linkedObject } = await Api.category.corpOffer.one({
      categoryId: id,
      signal,
    });
    return {
      linkObjectType: ECmsBannerLinkObjectType.CorpOfferCategory,
      linkedObject,
    };
  },
  getBannerTradeOfferCategoryByLinkObjectId: async ({ id, signal }): Promise<CmsLinkedObjectBannerCategoryType> => {
    const { data: linkedObject } = await Api.category.tradeOffer.one({
      categoryId: id,
      signal,
    });
    return {
      linkObjectType: ECmsBannerLinkObjectType.TradeOfferCategory,
      linkedObject,
    };
  },
};

export default service;
