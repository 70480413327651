import AddressApi, {
  AddressByFiasIdProps,
  AddressByQueryProps,
  BuildingTypesProps,
  ObjectTypesProps,
} from '../../../../data/api/address';
import {
  Address,
  CreateAddressRequest,
  CustomBuildingType,
  ManualAddressObjectType,
} from '../../../../domain/model/address';
import { Nullable } from '../../../../domain/model/types';

export type AddressCommonService = {
  readonly addressByQuery: (props: AddressByQueryProps) => Promise<Address[]>;
  readonly addressByFiasId: (props: AddressByFiasIdProps) => Promise<Nullable<Address>>;
  readonly getObjectTypes: (props: ObjectTypesProps) => Promise<ManualAddressObjectType[]>;
  readonly getBuildTypes: (props: BuildingTypesProps) => Promise<CustomBuildingType[]>;
  readonly createFromManual: (props: CreateAddressRequest) => Promise<Address>;
};

const service: AddressCommonService = {
  addressByQuery: async props => {
    const { data } = await AddressApi.addressByQuery(props);
    return data;
  },
  addressByFiasId: async props => {
    const { data } = await AddressApi.addressByFiasId(props);
    return data;
  },
  getObjectTypes: async props => {
    const { data } = await AddressApi.objectTypes(props);
    return data;
  },
  getBuildTypes: async props => {
    const { data } = await AddressApi.buildingTypes(props);
    return data;
  },
  createFromManual: async props => {
    const { data } = await AddressApi.create({ data: props });
    return data;
  },
};

export default service;
