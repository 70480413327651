import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getTradeOfferActionsOfferIsProcessingSelector, tradeOfferActionsGetActionSelector } from '../store/selectors';
import { useTradeOfferActions } from '../useActions';
import { TradeOffer } from '@/domain';
import { TradeOfferActionsConfirmDialog } from '@features/tradeOffer/actions/components';
import { TradeOfferStateKeyDialogs } from '../store/slice';

const nameDialog: TradeOfferStateKeyDialogs = 'approve';

const TradeOfferActionsDialogApproveAdapter = () => {
  const handlers = useTradeOfferActions();
  const { data, notification } = useSelector(tradeOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getTradeOfferActionsOfferIsProcessingSelector(data?.id ?? null));

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onApprove = useCallback(
    (offer: TradeOffer) => {
      handlers.onApprove(offer.id, notification).then(() => {
        onCloseDialog();
      });
    },
    [handlers.onApprove, notification]
  );

  return (
    data && (
      <TradeOfferActionsConfirmDialog
        offer={data}
        title='Опубликовать предложение?'
        actionText='Опубликовать'
        onAction={offer => onApprove(offer)}
        isFetching={isProcessing}
        onClose={onCloseDialog}
      />
    )
  );
};

export default TradeOfferActionsDialogApproveAdapter;
