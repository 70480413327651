import { FC } from 'react';
import { useSelector } from 'react-redux';
import { TradeActivationTableCellCommonProps } from '../../components/tableCell';
import { tradeActivationsTradeActivationByIndexSelector } from '../store/selectors';

export type TradeActivationTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<TradeActivationTableCellCommonProps>;
};

const TradeActivationTableCellAdapter = (props: TradeActivationTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const tradeActivation = useSelector(tradeActivationsTradeActivationByIndexSelector(index));

  return tradeActivation ? <Component tradeActivation={tradeActivation} /> : null;
};

export default TradeActivationTableCellAdapter;
