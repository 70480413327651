import { combineReducers } from '@reduxjs/toolkit';
import create, { TeamCreateState } from './create/store/slice';
import details, { TeamDetailsState } from './details/store/slice';
import edit, { TeamEditState } from './edit/store/slice';
import logos, { TeamLogoSelectState } from './logos/store/slice';
import search, { TeamSearchState } from './search/store/slice';
import list, { TeamListState } from './table/store/slice';

interface TeamState {
  list: TeamListState;
  details: TeamDetailsState;
  search: TeamSearchState;
  create: TeamCreateState;
  edit: TeamEditState;
  logos: TeamLogoSelectState;
}

export default combineReducers<TeamState>({
  list,
  details,
  search,
  create,
  edit,
  logos,
});
