import styled from '@emotion/styled';

export const DataTableCellImageWrapper = styled.div`
  width: 5rem;
  height: 3.375rem;
`;

export const EclipseLine = styled.div`
  width: 10rem;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
