import React from 'react';
import { UseCorpOffersSearch } from './hooks/useCorpOffersSearch';
import { UseCorpOffersSearchConfig } from './hooks/useCorpOffersSearchConfig';

export type CorpOffersSearchContextValue = UseCorpOffersSearch;

export const CorpOffersSearchContext = React.createContext<CorpOffersSearchContextValue>(
  {} as CorpOffersSearchContextValue
);

export type CorpOffersSearchConfigContextValue = UseCorpOffersSearchConfig;

export const CorpOffersSearchConfigContext = React.createContext<CorpOffersSearchConfigContextValue>(
  {} as CorpOffersSearchConfigContextValue
);
