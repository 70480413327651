import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import { SportUserProfile } from '../../../../../../domain/model/user';
import { teamGetByIdSelector, teamGetInvitesSelector, teamGetMembersSelector } from '../../store/selectors';
import { approveApplication, inviteMembers, rejectApplication, revokeInvite } from '../../store/slice';
import TeamTabInvites from './index';

interface TeamTabInvitesContainerProps {
  readonly canChangeMembers: boolean;
  readonly onAddMember: (sportsmen: Nullable<SportUserProfile[]>) => void;
}

const TeamTabInvitesContainer = ({ canChangeMembers, onAddMember }: TeamTabInvitesContainerProps) => {
  const dispatch = useDispatch();

  const { data: team } = useSelector(teamGetByIdSelector);
  const { data: invites, preInvited } = useSelector(teamGetInvitesSelector);
  const { data: members } = useSelector(teamGetMembersSelector);

  const onRevokeInvite = (id: UUID) => {
    dispatch(revokeInvite({ id }));
  };

  const onApproveApplication = (id: UUID) => {
    dispatch(approveApplication({ id }));
  };

  const onRejectApplication = (id: UUID) => {
    dispatch(rejectApplication({ id }));
  };

  useEffect(() => {
    const id = team?.id;

    if (id && preInvited?.length > 0) {
      dispatch(inviteMembers({ id, preInvited, members: members!, invites: invites! }));
    }
  }, [dispatch, preInvited, team]);

  return (
    <>
      {invites && (
        <TeamTabInvites
          activityTypes={team?.activityTypes ?? null}
          invites={invites}
          canChangeMembers={canChangeMembers}
          onRevokeInvite={onRevokeInvite}
          onApproveApplication={onApproveApplication}
          onRejectApplication={onRejectApplication}
          onAddMember={onAddMember}
        />
      )}
    </>
  );
};

export default TeamTabInvitesContainer;
