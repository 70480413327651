import { CorpOfferCategoriesEditAccess } from './types';
import MasterDetailsContainer from '../../../general/master/details/container';
import { MasterDetailsLayout } from '../../../../layouts/masterDetails/container';
import {
  CorpOfferCategoriesEditDialogsAdapter,
  CorpOfferCategoriesEditHeaderAdapter,
  CorpOfferCategoriesEditStepperAdapter,
} from './adapters';
import { CommonPreloaderDecorator } from '../../../general/decorators/preloader';
import {
  corpOfferCategoriesEditIsLoadingSelector,
  corpOfferCategoriesEditIsPreloadingSelector,
} from './store/selectors';
import { CommonUpdaterDecorator } from '../../../general/decorators/updater';
import { CorpOfferCategoriesEditInitializer } from './initializer';
import { CorpOfferCategoriesEditHandlersProvider } from './providers';
import CorpOfferCategoriesEditContainer from './container';
import { FullScreenContentWrapper } from './controls';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import ForbiddenComponent from '../../../../components/common/forbidden';

export const CorpOfferCategoriesEditFullScreenContainer = () => {
  const { corpCategories } = useCurrentUser().accessMatrix;
  const access: CorpOfferCategoriesEditAccess = {
    canCreate: corpCategories.create,
    canEdit: corpCategories.edit,
    canDelete: corpCategories.delete,
  };

  if (!corpCategories.view) {
    return <ForbiddenComponent />;
  }

  return (
    <MasterDetailsContainer
      layout={MasterDetailsLayout}
      layoutProps={{
        stepper: CorpOfferCategoriesEditStepperAdapter,
        header: CorpOfferCategoriesEditHeaderAdapter,
        decorators: [
          <CommonPreloaderDecorator
            key='preloader'
            isFetchingSelector={corpOfferCategoriesEditIsPreloadingSelector}
          />,
          <CommonUpdaterDecorator
            key='updater'
            isFetchingSelector={corpOfferCategoriesEditIsLoadingSelector}
          />,
        ],
      }}
      initializer={CorpOfferCategoriesEditInitializer}
      providers={[
        {
          name: 'handlers',
          factory: CorpOfferCategoriesEditHandlersProvider,
        },
      ]}
      commonProps={{
        access,
      }}
    >
      <FullScreenContentWrapper>
        <CorpOfferCategoriesEditContainer access={access} />
      </FullScreenContentWrapper>

      <CorpOfferCategoriesEditDialogsAdapter />
    </MasterDetailsContainer>
  );
};
