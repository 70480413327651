import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import {
  Nullable,
  Pageable,
  paginationSizeVariant,
  SportsFest,
  SportsFestCombinedTeam,
  SportsFestResultsRequest,
  UUID,
} from '@/domain';
import {
  CaseReducer,
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { PaginationSize } from '../../../../../types';
import { ValidationItemResult, ValidationResult } from '../../../../../utils/validation';
import { EventStatisticsProps } from '../../../components/statistics';
import {
  sportsFestCreatePublish,
  sportsFestCreatePublishAnnounce,
  sportsFestCreateSave,
} from '../../../create/sportsFest/store/slice';
import eventServices from '../../../services';
import { SportsFestViewUiState } from '../types';
import { getSportsFestCombinedTeamResults } from '../utils';

interface SportsFestCombinedTeamsFetchProps {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string[]>;
    readonly sportsFestId: UUID;
  };
  readonly pageNumber: number;
}

export const sportsFestViewByIdFetch = createAsyncThunk<SportsFest, UUID, AppThunkAPIConfig>(
  'sportsFest/view/byId/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      return await eventServices.sportsFest.one({ id, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const sportsFestViewCombinedTeamsFetch = createAsyncThunk<
  Pageable<SportsFestCombinedTeam>,
  SportsFestCombinedTeamsFetchProps,
  AppThunkAPIConfig
>('sportsFest/view/combinedTeams/fetch', async ({ search, pageNumber }, { rejectWithValue, signal }) => {
  try {
    const { sportsFestId, pageSize, sort } = search;
    const response = await eventServices.sportsFest.combinedTeams({
      sportsFestId,
      pageSize,
      sort,
      page: pageNumber,
      signal,
    });

    const { content: data, totalElements: totalCount, totalPages: pageCount, number: page } = response;
    return { data, totalCount, pageCount, pageNumber: page };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const sportsFestViewCancel = createAsyncThunk<void, UUID, AppThunkAPIConfig>(
  'sportsFest/view/cancel',
  async (id, { rejectWithValue }) => {
    try {
      await eventServices.sportsFest.cancel({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const sportsFestViewSaveResults = createAsyncThunk<
  SportsFest,
  { id: UUID; results: SportsFestResultsRequest },
  AppThunkAPIConfig
>('sportsFest/view/saveResults', async ({ id, results }, { rejectWithValue }) => {
  try {
    return await eventServices.sportsFest.saveResults({ id, results });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const sportsFestViewPublishResults = createAsyncThunk<
  SportsFest,
  { id: UUID; results: SportsFestResultsRequest },
  AppThunkAPIConfig
>('sportsFest/view/publishResults', async ({ id, results }, { rejectWithValue }) => {
  try {
    await eventServices.sportsFest.saveResults({ id, results });
    return await eventServices.sportsFest.publishResults({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface SportsFestViewState {
  readonly guid: Nullable<UUID>;
  readonly byId: Fetchable & {
    readonly sportsFest: Nullable<SportsFest>;
  };
  readonly dialogs: {
    readonly cancel: Nullable<SportsFest>;
  };
  readonly ui: Nullable<SportsFestViewUiState>;
  readonly combinedTeams: Fetchable &
    Pageable<SportsFestCombinedTeam> & {
      readonly pageNumber: number;
      readonly search: {
        readonly sort: string[];
        readonly pageSize: PaginationSize;
      };
    };
  readonly cancel: Fetchable;
  readonly saveResults: Fetchable;
  readonly publishResults: Fetchable;

  readonly results: {
    readonly data: SportsFestResultsRequest;
    readonly modified: boolean;
    readonly validation: ValidationResult<SportsFestResultsRequest>;
  };

  readonly statistics: Nullable<EventStatisticsProps>;
}

type Reducer<T = undefined> = CaseReducer<SportsFestViewState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<SportsFestViewState> {
  sportsFestViewStartSession: Reducer<{ guid: UUID }>;
  sportsFestViewClearActionsState: Reducer;
  sportsFestViewSetUiState: Reducer<{ name: keyof SportsFestViewUiState; value: any }>;
  sportsFestViewCombinedTeamsSetPage: Reducer<{ pageNumber: number }>;
  sportsFestViewCombinedTeamsSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  sportsFestViewSetResultsAttribute: Reducer<{ field: keyof SportsFestResultsRequest; value: any }>;
  sportsFestViewSetResultsModified: Reducer<boolean>;
  sportsFestViewSetResultsAttributeValidation: Reducer<{
    field: keyof SportsFestResultsRequest;
    results: Nullable<ValidationItemResult>;
  }>;
  sportsFestViewSetDialogState: Reducer<{ name: keyof SportsFestViewState['dialogs']; data: Nullable<any> }>;
  sportsFestViewSetStatistics: Reducer<Nullable<EventStatisticsProps>>;
}

const slice = createSlice<SportsFestViewState, Reducers, 'sportsFest/create'>({
  name: 'sportsFest/create',
  initialState: {
    guid: null,
    byId: {
      ...fetchableDefault,
      sportsFest: null,
    },
    dialogs: {
      cancel: null,
    },
    ui: null,
    combinedTeams: {
      ...fetchableDefault,
      data: [],
      pageNumber: 1,
      totalCount: 0,
      pageCount: 0,
      search: {
        sort: ['name', 'road.name'],
        pageSize: paginationSizeVariant[0],
      },
    },
    cancel: {
      ...fetchableDefault,
    },
    saveResults: {
      ...fetchableDefault,
    },
    publishResults: {
      ...fetchableDefault,
    },
    results: {
      data: {
        combinedTeamResults: null,
        resultsFile: null,
      },
      modified: false,
      validation: {},
    },
    statistics: null,
  },
  reducers: {
    sportsFestViewStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;
        state.byId = {
          ...fetchableDefault,
          sportsFest: null,
        };
        state.combinedTeams = {
          ...fetchableDefault,
          data: [],
          totalCount: 0,
          pageCount: 0,
          search: {
            sort: ['name', 'road.name'],
            pageSize: paginationSizeVariant[0],
          },
          pageNumber: 1,
        };
        state.results = {
          data: {
            combinedTeamResults: null,
            resultsFile: null,
          },
          modified: false,
          validation: {},
        };
        state.dialogs = {
          cancel: null,
        };
        state.ui = null;
      }
    },
    sportsFestViewClearActionsState: state => {
      state.byId = { ...fetchableDefault, sportsFest: null };
      state.cancel = {
        ...fetchableDefault,
      };
      state.saveResults = {
        ...fetchableDefault,
      };
      state.publishResults = {
        ...fetchableDefault,
      };
    },
    sportsFestViewSetStatistics: (state, { payload }) => {
      state.statistics = payload;
    },
    sportsFestViewSetResultsAttributeValidation: (state, { payload }) => {
      const { field, results } = payload;

      if (!results) {
        delete state.results.validation?.[field];
      } else {
        state.results.validation[field] = results;
      }
    },
    sportsFestViewSetUiState: (state, { payload }) => {
      const { name, value } = payload;
      if (!state.ui) {
        state.ui = {
          steps: [],
          options: [],
        };
      }
      state.ui[name] = value;
    },
    sportsFestViewCombinedTeamsSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.combinedTeams.pageNumber = pageNumber;
    },
    sportsFestViewCombinedTeamsSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.combinedTeams.search.pageSize = pageSize;
      state.combinedTeams.pageNumber = 1;
    },
    sportsFestViewSetResultsAttribute: (state, { payload }) => {
      const { field, value } = payload;
      state.results.data[field] = value;
    },
    sportsFestViewSetResultsModified: (state, { payload }) => {
      state.results.modified = payload;
    },
    sportsFestViewSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sportsFestViewByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.sportsFest = null;
      })
      .addCase(sportsFestViewByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;
        state.byId.sportsFest = payload;

        state.results.data.resultsFile = payload.resultsFile;
        state.results.data.combinedTeamResults = getSportsFestCombinedTeamResults(payload);
        state.results.modified = false;
      })
      .addCase(sportsFestViewByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = false;
        } else {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        }
        state.byId.sportsFest = null;
      })
      .addCase(sportsFestViewCombinedTeamsFetch.pending, state => {
        state.combinedTeams.isFetching = true;
        state.combinedTeams.isFetched = false;
        state.combinedTeams.isFailed = false;
        state.combinedTeams.data = [];
      })
      .addCase(sportsFestViewCombinedTeamsFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.combinedTeams.isFetching = false;
        state.combinedTeams.isFetched = true;
        state.combinedTeams.isFailed = false;
        state.combinedTeams.data = data;
        state.combinedTeams.totalCount = totalCount;
        state.combinedTeams.pageCount = pageCount;
      })
      .addCase(sportsFestViewCombinedTeamsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.combinedTeams.isFetching = false;
          state.combinedTeams.isFetched = false;
          state.combinedTeams.isFailed = true;
        }
      })
      .addCase(sportsFestViewCancel.pending, state => {
        state.cancel.isFetching = true;
        state.cancel.isFetched = false;
        state.cancel.isFailed = false;
      })
      .addCase(sportsFestViewCancel.fulfilled, state => {
        state.cancel.isFetching = false;
        state.cancel.isFetched = true;
        state.cancel.isFailed = false;
      })
      .addCase(sportsFestViewCancel.rejected, state => {
        state.cancel.isFetching = false;
        state.cancel.isFetched = false;
        state.cancel.isFailed = true;
      })
      .addCase(sportsFestViewSaveResults.pending, state => {
        state.saveResults.isFetching = true;
        state.saveResults.isFetched = false;
        state.saveResults.isFailed = false;
      })
      .addCase(sportsFestViewSaveResults.fulfilled, (state, { payload }) => {
        state.saveResults.isFetching = false;
        state.saveResults.isFetched = true;
        state.saveResults.isFailed = false;

        state.byId.sportsFest = payload;
        state.results.data.resultsFile = payload.resultsFile;
        state.results.data.combinedTeamResults = getSportsFestCombinedTeamResults(payload);
        state.results.modified = false;
      })
      .addCase(sportsFestViewSaveResults.rejected, state => {
        state.saveResults.isFetching = false;
        state.saveResults.isFetched = false;
        state.saveResults.isFailed = true;
      })
      .addCase(sportsFestViewPublishResults.pending, state => {
        state.publishResults.isFetching = true;
        state.publishResults.isFetched = false;
        state.publishResults.isFailed = false;
      })
      .addCase(sportsFestViewPublishResults.fulfilled, (state, { payload }) => {
        state.publishResults.isFetching = false;
        state.publishResults.isFetched = true;
        state.publishResults.isFailed = false;

        state.byId.sportsFest = payload;
        state.results.data.resultsFile = payload.resultsFile;
        state.results.data.combinedTeamResults = getSportsFestCombinedTeamResults(payload);
        state.results.modified = false;
      })
      .addCase(sportsFestViewPublishResults.rejected, state => {
        state.publishResults.isFetching = false;
        state.publishResults.isFetched = false;
        state.publishResults.isFailed = true;
      })

      .addMatcher(
        isAnyOf(
          sportsFestCreateSave.fulfilled,
          sportsFestCreatePublish.fulfilled,
          sportsFestCreatePublishAnnounce.fulfilled
        ),
        state => {
          state.guid = null;
        }
      );
  },
});

export const {
  sportsFestViewStartSession,
  sportsFestViewSetUiState,
  sportsFestViewClearActionsState,
  sportsFestViewCombinedTeamsSetPage,
  sportsFestViewCombinedTeamsSetPageSize,
  sportsFestViewSetResultsAttribute,
  sportsFestViewSetResultsModified,
  sportsFestViewSetDialogState,
  sportsFestViewSetStatistics,
  sportsFestViewSetResultsAttributeValidation,
} = slice.actions;

export default slice.reducer;
