import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TopPushDialog from '../../../../components/common/dialogs/topPush';
import { viewConfig } from '../../../../theme/viewConfig';
import { useBookingOfferActions } from '../../actions/useActions';

const BookingOfferDetailsHistoryAdapter = () => {
  const location = useLocation();

  const actionHandlers = useBookingOfferActions();
  const historyVisible = false;

  const onClose = useCallback(() => {
    // TODO поправить после коммита андрея
    // actionHandlers.onChangeDialogState('history', null);
  }, [actionHandlers.onChangeDialogState]);

  useEffect(() => {
    onClose();
  }, [location, onClose]);

  // TODO booking - OfferHistoryTableContainer сделать, чтобы работал с историей
  return (
    <TopPushDialog
      open={historyVisible}
      title='История'
      fullScreen
      stickyHeader
      leftOffset={viewConfig.menuPanelWidth}
      backdropAdjust
      onClose={onClose}
    >
      {/*      {historyOffer && (
        <OfferHistoryTableContainer
          offerId={historyOffer.id}
          typeHistory={EVariantHistory.BookingOffer}
        />
      )}*/}
    </TopPushDialog>
  );
};

export default BookingOfferDetailsHistoryAdapter;
