import PartnerTableCell, { PartnerTableCellCommonProps } from './index';

const PartnerTableCellPhone = (props: PartnerTableCellCommonProps) => {
  const {
    partner: { owner },
  } = props;

  const value = owner?.phone ?? '-';

  return (
    <PartnerTableCell
      {...props}
      value={value}
    />
  );
};

export default PartnerTableCellPhone;
