import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useBreadcrumbsActions from '../../features/general/breadcrumbs/useBreadcrumbsActions';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import AdminMpCreateContainer from '../../features/user/adminMp/create/container';
import { EAdminMpStep } from '../../features/user/adminMp/types';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const AdminMpCreateScreen = () => {
  const dispatch = useDispatch();
  const { putBreadcrumbs } = useBreadcrumbsActions();

  const { isAdminMp } = useCurrentUser().accessMatrix;

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  useEffect(() => {
    putBreadcrumbs([{ label: 'Администраторы' }]);
  }, []);

  if (isAdminMp) {
    return <AdminMpCreateContainer step={EAdminMpStep.Profile} />;
  }

  return null;
};

export default AdminMpCreateScreen;
