import styled from '@emotion/styled';
import { MPFormInput, MPNaturalNumberInput } from '..';
import { MPButton } from '../../button';

export const StyledInput = styled(MPFormInput)`
  .MuiInputBase-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const StyledNumberInput = styled(MPNaturalNumberInput)`
  .MuiInputBase-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const StyledButton = styled(MPButton)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;
