import { isArray } from 'lodash';
import { CalendarProps, DateObject } from 'react-multi-date-picker';
import { Nullable } from '../../../../../domain/model/types';
import { permanentOptions, regularOptions, StyledCalendar } from '../configuration';

export type MPMultipleCalendarPickerProps = Omit<CalendarProps<true>, 'onChange' | 'multiple' | 'radge'> & {
  readonly onChange?: (date: Nullable<Date[]>) => false | void;
};

export const MPMultipleCalendarPicker = (props: MPMultipleCalendarPickerProps) => {
  const onChangeInternal = (selectedDates: Nullable<DateObject | DateObject[]>): false | void => {
    if (selectedDates === null) {
      return props.onChange?.(null);
    }

    const result: Nullable<Date[]> = !isArray(selectedDates)
      ? [selectedDates.toDate()]
      : selectedDates.map(item => item.toDate());

    return props.onChange?.(result);
  };

  return (
    <StyledCalendar
      {...regularOptions}
      {...props}
      onChange={onChangeInternal}
      multiple
      range={false}
      {...permanentOptions}
    />
  );
};
