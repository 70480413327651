import { EMPStepperTransitionMethod } from '@components/common/stepper';
import StepperContainer from '../../../general/stepper/container';
import { AdCampaignCreateStepType } from '../../types';
import useAdCampaignCreateStepper from '../hooks/useStepper';
import { AdCampaignCreateStepperIconAdapter } from './stepperIcon';
import { AdCampaignCreateStepperLabelAdapter } from './stepperLabel';

export const AdCampaignCreateStepperAdapter = () => {
  const { currentStep, steps, openStep } = useAdCampaignCreateStepper();

  return (
    <StepperContainer<AdCampaignCreateStepType>
      step={currentStep}
      steps={steps}
      flow={false}
      forwardTransition={EMPStepperTransitionMethod.Free}
      decorators={{
        icon: AdCampaignCreateStepperIconAdapter,
        labelOptional: AdCampaignCreateStepperLabelAdapter,
      }}
      onClick={openStep}
    />
  );
};
