import { FC } from 'react';
import { useSelector } from 'react-redux';
import { AnalyticsQueriesTableCellCommonProps } from '../../components/tableCell';
import { analyticsQueriesAnalyticsQueryByIndexSelector } from '../store/selectors';

type AnalyticsQueriesTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<AnalyticsQueriesTableCellCommonProps>;
};

export const AnalyticsQueriesTableCellAdapter = (props: AnalyticsQueriesTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const query = useSelector(analyticsQueriesAnalyticsQueryByIndexSelector(index));

  return query ? <Component query={query} /> : null;
};
