import { FCC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import store from '../../../../../../data/store/store';
import useHistoryExtensions from '../../../../../hooks/useHistoryExtensions';
import { getConfigurationSportRoute } from '../../../../configuration/entry';
import { ActivityTypesEditContainerProps } from '../container';
import { ActivityTypesEditHandlersContext, ActivityTypesEditHandlersContextValue } from '../context';
import {
  activityTypesEditCreate,
  activityTypesEditDelete,
  activityTypesEditFetch,
  activityTypesEditHide,
  activityTypesEditSetDialogState,
  activityTypesEditShow,
  activityTypesEditSort,
  activityTypesEditUpdate,
} from '../store/slice';

export const ActivityTypesEditHandlersProvider: FCC<ActivityTypesEditContainerProps> = ({ children, access }) => {
  const dispatch = useDispatch();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const onChangeDialogState = useCallback<ActivityTypesEditHandlersContextValue['onChangeDialogState']>(
    (name, data) => {
      dispatch(activityTypesEditSetDialogState({ name, data }));
    },
    [dispatch]
  );

  const onRefresh = useCallback(async () => await dispatch(activityTypesEditFetch()).unwrap(), [dispatch]);

  const onCreate = useCallback<ActivityTypesEditHandlersContextValue['onCreate']>(
    async activityType => {
      await dispatch(activityTypesEditCreate(activityType)).unwrap();
      await onRefresh();
    },
    [dispatch, onRefresh]
  );

  const onEdit = useCallback<ActivityTypesEditHandlersContextValue['onEdit']>(
    async activityType => {
      await dispatch(activityTypesEditUpdate(activityType)).unwrap();
      await onRefresh();
    },
    [dispatch, onRefresh]
  );

  const onDelete = useCallback<ActivityTypesEditHandlersContextValue['onDelete']>(
    async activityType => {
      await dispatch(activityTypesEditDelete(activityType)).unwrap();
      await onRefresh();
    },
    [dispatch, onRefresh]
  );

  const onHide = useCallback<ActivityTypesEditHandlersContextValue['onHide']>(
    async activityType => {
      await dispatch(activityTypesEditHide(activityType)).unwrap();
      await onRefresh();
    },
    [dispatch, onRefresh]
  );

  const onShow = useCallback<ActivityTypesEditHandlersContextValue['onShow']>(
    async activityType => {
      await dispatch(activityTypesEditShow(activityType)).unwrap();
      await onRefresh();
    },
    [dispatch, onRefresh]
  );

  const onSort = useCallback<ActivityTypesEditHandlersContextValue['onSort']>(
    async (parent, activityTypes) => {
      await dispatch(activityTypesEditSort({ parent, activityTypes })).unwrap();
      await onRefresh();
    },
    [dispatch, onRefresh]
  );

  const onClose = useCallback<ActivityTypesEditHandlersContextValue['onClose']>(() => {
    gotoPrevIndependentLocation(getConfigurationSportRoute({}));
  }, [gotoPrevIndependentLocation, store]);

  const value: ActivityTypesEditHandlersContextValue = {
    onChangeDialogState,
    onCreate,
    onEdit,
    onDelete,
    onHide,
    onShow,
    onSort,
    onClose,
  };

  return (
    <ActivityTypesEditHandlersContext.Provider value={value}>{children}</ActivityTypesEditHandlersContext.Provider>
  );
};
