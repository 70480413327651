import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable } from '../../../../../domain/model';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { SportUserProfile } from '../../../../../domain/model/user';
import { PaginationSize } from '../../../../types';
import { SportsmansTableFilterValues } from '../../filterUtils';
import sportsmanServices from '../../services';
import { SportsmanFetchProps } from '../../table/store/slice';
import { ESportsmanTableColumn, ESportsmanTableTab } from '../../table/utils';

const defaultSort = `${ESportsmanTableColumn.LastName},asc`;

export interface SportsmansSearchState extends Fetchable, Pageable<SportUserProfile> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
  };
  readonly tab: ESportsmanTableTab;
  readonly filter: SportsmansTableFilterValues;
  readonly selected: SportUserProfile[];
}

type Reducer<T = undefined> = CaseReducer<SportsmansSearchState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<SportsmansSearchState> {
  sportsmanSearchStartSession: Reducer<{
    guid: UUID;
    selected: SportUserProfile[];
  }>;
  sportsmanSearchSetSearch: Reducer<{
    sort: string;
    pageSize: PaginationSize;
  }>;
  sportsmanSearchSetTab: Reducer<{
    tab: ESportsmanTableTab;
  }>;
  sportsmanSearchSortReset: Reducer;
  sportsmanSearchSetPage: Reducer<{
    pageNumber: number;
  }>;
  sportsmanSearchSelect: Reducer<SportUserProfile>;
  sportsmanSearchAllSelect: Reducer<Nullable<number>>;
  sportsmanSearchAllUnselect: Reducer;
  sportsmanSearchDataReset: Reducer;
  sportsmanSearchSetFilter: Reducer<SportsmansTableFilterValues>;
  sportsmanSearchSetPageSize: Reducer<{
    pageSize: PaginationSize;
  }>;
  sportsmanSearchSetSort: Reducer<{
    sort: string;
  }>;
  sportsmanSearchUnselect: Reducer<SportUserProfile>;
  sportsmanSearchNeedRefreshWatcherReset: Reducer;
}

export const sportsmansSearchFetch = createAsyncThunk<
  Pageable<SportUserProfile>,
  SportsmanFetchProps,
  AppThunkAPIConfig
>('sportsmansSearch/list/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    return await sportsmanServices.common.all({ ...props, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

const slice = createSlice<SportsmansSearchState, Reducers, 'search'>({
  name: 'search',
  initialState: {
    ...fetchableDefault,
    guid: null,
    selected: [],
    data: [],
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    needRefreshWatcher: 0,
    tab: ESportsmanTableTab.All,
    search: {
      sort: defaultSort,
      pageSize: 15,
    },
    filter: {},
  },
  reducers: {
    sportsmanSearchStartSession: (state, { payload }) => {
      const { guid, selected } = payload;
      state.guid = guid;

      state.isFetching = false;
      state.isFetched = false;
      state.isFailed = false;

      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
      state.needRefreshWatcher = 0;
      state.search = {
        sort: defaultSort,
        pageSize: 10,
      };
      state.filter = {};
      state.selected = selected;
    },
    sportsmanSearchSetSearch: (state, { payload }) => {
      const { sort, pageSize } = payload;

      state.search = {
        ...state.search,
        sort,
        pageSize,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    sportsmanSearchSetTab: (state, { payload }) => {
      const { tab } = payload;

      if (state.tab !== tab) {
        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
        state.needRefreshWatcher++;
      }
    },
    sportsmanSearchSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    sportsmanSearchSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.selected = [];
      state.needRefreshWatcher++;
    },
    sportsmanSearchSelect: (state, { payload }) => {
      const selected = [...state.selected];
      const existedIndex = selected.findIndex(s => s.id === payload.id);

      if (existedIndex !== -1) {
        selected.splice(existedIndex, 1);
      }

      state.selected = [...selected, payload];
    },
    sportsmanSearchAllSelect: (state, { payload }) => {
      if (payload) {
        state.selected = [...state.data.slice(0, payload)];
      } else {
        state.selected = state.data;
      }
    },
    sportsmanSearchAllUnselect: state => {
      state.selected = [];
    },
    sportsmanSearchDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
      state.selected = [];
    },
    sportsmanSearchSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    sportsmanSearchSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;

      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    sportsmanSearchSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    sportsmanSearchUnselect: (state, { payload }) => {
      const existedIndex = state.selected.findIndex(s => s.id === payload.id);

      if (existedIndex !== -1) {
        state.selected.splice(existedIndex, 1);
      }
    },
    sportsmanSearchNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sportsmansSearchFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
        state.needRefreshWatcher = 0;
      })
      .addCase(sportsmansSearchFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(sportsmansSearchFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      });
  },
});

export const {
  sportsmanSearchSetSearch,
  sportsmanSearchSetPage,
  sportsmanSearchSelect,
  sportsmanSearchStartSession,
  sportsmanSearchAllSelect,
  sportsmanSearchAllUnselect,
  sportsmanSearchDataReset,
  sportsmanSearchSetFilter,
  sportsmanSearchSetPageSize,
  sportsmanSearchSetSort,
  sportsmanSearchSortReset,
  sportsmanSearchUnselect,
  sportsmanSearchNeedRefreshWatcherReset,
  sportsmanSearchSetTab,
} = slice.actions;

export default slice.reducer;
