import { Typography } from '@mui/material';
import { DataTreeItem } from '../../../../../../domain/model';
import { BookingServiceCategory } from '../../../../../../domain/model/booking';
import { DictionaryServiceChangeDialog } from '../../../../../components/common/dictionary/dialog/confirm';

type BookingServiceCategoriesEditDialogChangeDateAdapterProps = {
  readonly service: DataTreeItem<BookingServiceCategory>;
  readonly onClose: () => void;
  readonly onChange: (service: DataTreeItem<BookingServiceCategory>) => void;
};

export const BookingServiceCategoriesEditDialogServiceChangeDateAdapter = (
  props: BookingServiceCategoriesEditDialogChangeDateAdapterProps
) => {
  const { service, onClose, onChange } = props;

  return (
    <DictionaryServiceChangeDialog
      onClose={onClose}
      onConfirm={() => onChange(service)}
    >
      <Typography>
        Найдены услуги в активных объектах категории “{service.data.name}” с ранее выбранным условием выбора дат.
      </Typography>
      <br />
      <Typography>Вы уверены, что хотите изменить условие ?</Typography>
    </DictionaryServiceChangeDialog>
  );
};
