import { CorpOfferActivation } from '@/domain/model/activation';
import { ReactNode, useMemo } from 'react';
import DataTableCellTextFormat from '../../../../components/common/table/cell/textFormat';

export interface CorpActivationTableCellCommonProps {
  readonly corpActivation: CorpOfferActivation;
  readonly isDisabled?: boolean;
  readonly isTextAccent?: boolean;
}

interface BookingOfferTableCellProps extends CorpActivationTableCellCommonProps {
  readonly value: ReactNode;
}

const CorpActivationTableCell = ({ value, isDisabled, isTextAccent }: BookingOfferTableCellProps) => {
  return useMemo(
    () => (
      <DataTableCellTextFormat
        disabled={isDisabled}
        bold={isTextAccent}
      >
        {value}
      </DataTableCellTextFormat>
    ),
    [isDisabled, isTextAccent, value]
  );
};

export default CorpActivationTableCell;
