import React from 'react';
import { UseTeamsTable } from './hooks/useTeamsTable';
import { UseTeamsTableConfig } from './hooks/useTeamsTableConfig';

export type TeamsTableContextValue = UseTeamsTable;

export const TeamsTableContext = React.createContext<TeamsTableContextValue>({} as TeamsTableContextValue);

export type TeamsTableConfigContextValue = UseTeamsTableConfig;

export const TeamsTableConfigContext = React.createContext<TeamsTableConfigContextValue>(
  {} as TeamsTableConfigContextValue
);
