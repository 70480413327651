import { RequiredActionConfirmCodeContainer } from '@features/requiredAction/confirmCode/container';
import { useCodeConfirm } from '../hooks/useCodeConfirm';
import { useUserProfileEmailEdit } from '../hooks/useEmailEdit';

type CodeConfirmationAdapterProps = {
  readonly onClose: () => void;
  readonly onNext: () => void;
};

export const CodeConfirmationAdapter = (props: CodeConfirmationAdapterProps) => {
  const { onClose, onNext } = props;

  const { email, source, onUpdate } = useUserProfileEmailEdit();
  const { onConfirmed } = useCodeConfirm();

  const onSuccess = () => {
    onConfirmed();
    onNext();
  };

  return (
    source && (
      <RequiredActionConfirmCodeContainer
        email={email}
        source={source}
        onSuccess={onSuccess}
        onGetNewCode={onUpdate}
        onClose={onClose}
      />
    )
  );
};
