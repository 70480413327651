import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../data/store/types';
import { AddressPosition } from '../../../../../domain/model/address';
import { EUserRole } from '../../../../../domain/model/enums';
import { Playground } from '../../../../../domain/model/playground';
import { Nullable, UUID } from '../../../../../domain/model/types';
import playgroundServices from '../../services';

const emptyPlaygroundPosition: AddressPosition = {
  lat: 0,
  lon: 0,
};

const emptyPlayground: Playground = {
  address: null,
  location: emptyPlaygroundPosition,
} as Playground;

export const playgroundByIdFetch = createAsyncThunk<
  { playground: Playground; initiatorRoles: EUserRole[] },
  { id: Nullable<UUID> },
  AppThunkAPIConfig
>('playground/details/byId/fetch', async ({ id }, { rejectWithValue, signal }) => {
  try {
    if (!id) {
      return {
        playground: emptyPlayground,
        initiatorRoles: [],
      };
    }

    const playground = await playgroundServices.common.one({ id });
    const initiatorRoles = await playgroundServices.action.createdByRoles({ playground, signal });
    return { playground, initiatorRoles };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);

    return rejectWithValue(e.response.data);
  }
});

export const playgroundUnblock = createAsyncThunk<void, { id: UUID }, AppThunkAPIConfig>(
  'playground/details/unblock',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await playgroundServices.action.unblock({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const playgroundBlock = createAsyncThunk<void, { id: UUID; reason: string }, AppThunkAPIConfig>(
  'playground/details/reject',
  async ({ id, reason }, { rejectWithValue }) => {
    try {
      return await playgroundServices.action.block({ id, reason });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const playgroundSave = createAsyncThunk<Playground, { playground: Playground }, AppThunkAPIConfig>(
  'playground/details/save',
  async ({ playground }, { rejectWithValue }) => {
    try {
      return await playgroundServices.action.save(playground);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export const playgroundPublish = createAsyncThunk<Playground, { playground: Playground }, AppThunkAPIConfig>(
  'playground/details/publish',
  async ({ playground }, { rejectWithValue }) => {
    try {
      return await playgroundServices.action.publish(playground);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export interface PlaygroundDetailsState {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly byId: Fetchable & {
    readonly data: Nullable<Playground>;
    readonly loadedData: Nullable<Playground>;
    readonly initiatorRoles: EUserRole[];
    readonly modified: boolean;
  };
  readonly reject: Fetchable;
  readonly publish: Fetchable;
  readonly save: Fetchable;
  readonly unblock: Fetchable;
  readonly addressPrepare: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<PlaygroundDetailsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PlaygroundDetailsState> {
  playgroundStartSession: Reducer<Nullable<UUID>>;
  playgroundSetAttribute: Reducer<{ name: keyof Playground; value: any }>;
  playgroundResetSave: Reducer;
  playgroundClearActionsState: Reducer;
}

const slice = createSlice<PlaygroundDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    guid: null,
    needRefreshWatcher: 0,
    byId: {
      ...fetchableDefault,
      data: null,
      loadedData: null,
      initiatorRoles: [],
      modified: false,
    },
    reject: {
      ...fetchableDefault,
    },
    publish: {
      ...fetchableDefault,
    },
    save: {
      ...fetchableDefault,
    },
    unblock: {
      ...fetchableDefault,
    },
    addressPrepare: {
      ...fetchableDefault,
    },
  },
  reducers: {
    playgroundStartSession: (state, { payload }) => {
      if (state.guid !== payload) {
        state.needRefreshWatcher = 0;

        state.byId.data = null;
        state.byId.initiatorRoles = [];
      }
      state.guid = payload;
    },
    playgroundClearActionsState: state => {
      state.reject = {
        ...fetchableDefault,
      };
      state.publish = {
        ...fetchableDefault,
      };
      state.save = {
        ...fetchableDefault,
      };
      state.unblock = {
        ...fetchableDefault,
      };
      state.addressPrepare = {
        ...fetchableDefault,
      };
    },
    playgroundSetAttribute: (state, { payload }) => {
      const { name, value } = payload;

      state.byId.modified = true;

      if (state.byId.data) {
        (state.byId.data[name] as keyof Playground) = value;

        if (name === 'address') {
          const position = state.byId.data.address?.position;
          if (position) {
            state.byId.data.location = {
              lat: position.lat,
              lon: position.lon,
            };
          } else {
            state.byId.data.location = emptyPlaygroundPosition;
          }
        }
      }
    },
    playgroundResetSave: state => {
      state.save = fetchableDefault;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(playgroundByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.data = null;
        state.byId.loadedData = null;
        state.byId.modified = false;
      })
      .addCase(playgroundByIdFetch.fulfilled, (state, { payload }) => {
        const { playground, initiatorRoles } = payload;
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;
        state.byId.data = playground;
        state.byId.loadedData = playground;
        state.byId.initiatorRoles = initiatorRoles;
        state.byId.modified = false;
      })
      .addCase(playgroundByIdFetch.rejected, state => {
        state.byId.isFetching = false;
        state.byId.isFetched = false;
        state.byId.isFailed = true;
        state.byId.data = null;
        state.byId.loadedData = null;
        state.byId.initiatorRoles = [];
        state.byId.modified = false;
      })

      .addCase(playgroundBlock.pending, state => {
        state.reject.isFetching = true;
        state.reject.isFetched = false;
        state.reject.isFailed = false;
      })
      .addCase(playgroundBlock.fulfilled, state => {
        state.reject.isFetching = false;
        state.reject.isFetched = true;
        state.reject.isFailed = false;
        state.needRefreshWatcher++;
      })
      .addCase(playgroundBlock.rejected, state => {
        state.reject.isFetching = false;
        state.reject.isFetched = false;
        state.reject.isFailed = true;
      })

      .addCase(playgroundUnblock.pending, state => {
        state.unblock.isFetching = true;
        state.unblock.isFetched = false;
        state.unblock.isFailed = false;
      })
      .addCase(playgroundUnblock.fulfilled, state => {
        state.unblock.isFetching = false;
        state.unblock.isFetched = true;
        state.unblock.isFailed = false;
        state.needRefreshWatcher++;
      })
      .addCase(playgroundUnblock.rejected, state => {
        state.unblock.isFetching = false;
        state.unblock.isFetched = false;
        state.unblock.isFailed = true;
      })

      .addCase(playgroundPublish.pending, state => {
        state.publish.isFetching = true;
        state.publish.isFetched = false;
        state.publish.isFailed = false;
      })
      .addCase(playgroundPublish.fulfilled, state => {
        state.publish.isFetching = false;
        state.publish.isFetched = true;
        state.publish.isFailed = false;
        state.needRefreshWatcher++;
      })
      .addCase(playgroundPublish.rejected, state => {
        state.publish.isFetching = false;
        state.publish.isFetched = false;
        state.publish.isFailed = true;
      })

      .addCase(playgroundSave.pending, state => {
        state.save = fetchableFetching;
      })
      .addCase(playgroundSave.fulfilled, (state, { payload }) => {
        state.save = fetchableFetched;
        state.byId.data = payload;
        state.byId.loadedData = payload;
        state.byId.modified = false;
      })
      .addCase(playgroundSave.rejected, state => {
        state.save = fetchableFailed;
      });
  },
});

export const {
    playgroundStartSession,
    playgroundSetAttribute,
    playgroundSetValidationErrors,
    playgroundClearActionsState,
} = slice.actions;

export default slice.reducer;
