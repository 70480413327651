import AppBreadcrumbs from '@components/common/breadcrumbs';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import DefaultHeader from '../../../../components/common/header';
import { TitleWrapper } from '../controls';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { clientOrgCreateClientOrgStatusSelector } from '../store/selectors';

export const ClientOrgCreateHeaderAdapter = () => {
  const clientOrgStatus = useSelector(clientOrgCreateClientOrgStatusSelector);

  const handlers = useContextHandlers();

  return (
    <DefaultHeader
      sticky
      headline={<AppBreadcrumbs />}
      onClose={handlers.onClose}
    >
      <TitleWrapper>
        <Typography variant='h2'>{!clientOrgStatus ? 'Добавление компании' : 'Компания'}</Typography>
      </TitleWrapper>
    </DefaultHeader>
  );
};
