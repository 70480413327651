import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ReactNode } from 'react';
import { Wrapper } from './controls';

type DictionaryItemElementArrowProps = {
  readonly active?: ReactNode;
};

export const DictionaryItemElementArrow = (props: DictionaryItemElementArrowProps) => {
  const { active } = props;

  return (
    <Wrapper>
      <KeyboardArrowRightIcon
        color={active ? 'primary' : 'secondary'}
        fontSize='small'
      />
    </Wrapper>
  );
};
