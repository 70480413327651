import moment from 'moment';
import { EDateFormat } from '../../../../../domain/model/formats';
import ProductTableCell, { ProductTableCellCommonProps } from './index';

const ProductTableCellCreatedAt = (props: ProductTableCellCommonProps) => {
  const {
    productOffer: { createdAt },
  } = props;

  const value = createdAt ? moment(createdAt).format(EDateFormat.DisplayDefault) : '-';

  return (
    <ProductTableCell
      {...props}
      value={value}
    />
  );
};

export default ProductTableCellCreatedAt;
