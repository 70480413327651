import React from 'react';
import { MPButton } from '@ui-kit/button';
import ConfirmDialog from '@components/common/dialogs/confirm';
import ContentLoader from '@components/common/loader';
import { ValidationItemResult } from '@/presentation/utils/validation';
import { MPFormInput } from '@ui-kit/input';

export type ChangeEmailDialogProps = {
  readonly label: string;
  readonly primaryText: string;
  readonly value: string;
  readonly isFetching: boolean;
  readonly validation?: ValidationItemResult;
  readonly onChange: (value: string) => void;
  readonly onClose: () => void;
  readonly onSave: () => void;
};

export const ChangeEmailDialog = (props: ChangeEmailDialogProps) => {
  const { label, value, validation, isFetching, primaryText, onChange, onClose, onSave } = props;

  return (
    <ConfirmDialog
      open
      title={label}
      dialogButtons={
        <>
          <MPButton
            fullWidth
            disabled={isFetching}
            onClick={onSave}
          >
            {primaryText}
            {isFetching && <ContentLoader />}
          </MPButton>
          <MPButton
            fullWidth
            variant='outlined'
            disabled={isFetching}
            onClick={onClose}
          >
            Отмена
          </MPButton>
        </>
      }
      onClose={onClose}
    >
      <MPFormInput
        label={label}
        value={value ?? ''}
        error={!!validation?.hasError}
        helperText={validation?.message}
        disabled={isFetching}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
      />
    </ConfirmDialog>
  );
};
