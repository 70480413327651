import { Fetchable } from '../../../../../data/store/types';
import { Nullable } from '../../../../../domain/model/types';
import DataTableCellSortIndex from '../../../../components/common/table/cell/sortIndex';
import { PanelAction } from '../../../../types';
import { CmsContainerTableActionProcess } from '../../container/table/utils';
import { CmsContainerActionCall, ECmsContainerActionType } from '../../container/types';
import { CmsContainerTableView } from '../../types';
import CmsContainerTableCell, { CmsContainerTableCellCommonProps } from './index';

export interface CmsContainerTableCellSortIndexProps extends CmsContainerTableCellCommonProps {
  readonly cmsContainer: CmsContainerTableView;
  readonly activeCmsContainer: Nullable<CmsContainerTableView>;
  readonly editableCmsContainer: Nullable<CmsContainerTableView>;
  readonly changeSortIndexAction: Nullable<PanelAction<ECmsContainerActionType>>;
  readonly sortIndexInfo: Fetchable & {
    readonly max: number;
  };
  readonly getActionProcesses: (cmsContainer: CmsContainerTableView) => CmsContainerTableActionProcess[];
  readonly onCmsContainerAction: (call: CmsContainerActionCall) => void;
  readonly setEditableCmsContainer: (cmsContainer: Nullable<CmsContainerTableView>) => void;
}

const CmsContainerTableCellSortIndex = (props: CmsContainerTableCellSortIndexProps) => {
  const {
    cmsContainer,
    activeCmsContainer,
    editableCmsContainer,
    changeSortIndexAction,
    sortIndexInfo,
    getActionProcesses,
    onCmsContainerAction,
    setEditableCmsContainer,
  } = props;
  const { id, sortIndex } = cmsContainer;

  const changeSortIndexProcess = getActionProcesses(cmsContainer).find(
    process => process.type === ECmsContainerActionType.ChangeSortIndex
  );
  const currentEditableCmsContainer = editableCmsContainer?.id === id ? editableCmsContainer : null;
  const isSortIndexFetching = changeSortIndexProcess?.isFetching ?? false;

  return (
    <>
      {!changeSortIndexAction || changeSortIndexAction.disabled ? (
        <CmsContainerTableCell
          {...props}
          value={sortIndex}
        />
      ) : (
        <DataTableCellSortIndex
          editMode={editableCmsContainer?.id === id}
          value={sortIndex}
          max={sortIndexInfo.max}
          label={(min, max) => `${min}-${max}`}
          hideEditIcon={activeCmsContainer?.id !== id && editableCmsContainer?.id !== id}
          isFetching={(!editableCmsContainer || !!currentEditableCmsContainer) && isSortIndexFetching}
          onChangeEditMode={isEditMode => setEditableCmsContainer(!isEditMode ? null : cmsContainer)}
          onChange={newValue => {
            setEditableCmsContainer({ ...cmsContainer, sortIndex: newValue });
            onCmsContainerAction({
              action: ECmsContainerActionType.ChangeSortIndex,
              cmsContainer,
              sortIndex: newValue,
            });
          }}
        />
      )}
    </>
  );
};

export default CmsContainerTableCellSortIndex;
