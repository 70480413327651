import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ForbiddenComponent from '../../../components/common/forbidden';
import { ConfigurationLocationState } from '../../../features/configuration/entry';
import ConfigurationSportContainer from '../../../features/configuration/sport/container';
import LocationWithRequiredStateContainer from '../../../features/general/locationExtension/withRequiredState';
import { viewShowMainMenu } from '../../../features/main/container/store/slice';
import useCurrentUser from '../../../features/user/hooks/useCurrentUser';

const ConfigurationSportScreen = () => {
  const dispatch = useDispatch();
  const { configuration, activityTypes } = useCurrentUser().accessMatrix;

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (!configuration?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<ConfigurationLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <ConfigurationSportContainer
          guid={state.guid}
          canEditActivityTypes={!!activityTypes.edit}
        />
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default ConfigurationSportScreen;
