import { combineReducers } from '@reduxjs/toolkit';
import downloads, { ReportDownloadsState } from './downloads/store/slice';
import list, { ReportListState } from './table/store/slice';

interface ReportState {
  list: ReportListState;
  downloads: ReportDownloadsState;
}

export default combineReducers<ReportState>({
  list,
  downloads,
});
