import { Nullable } from '@/domain/model/types';
import { QueryAnalyticsLocationState } from '@features/analytics/query/entry';
import AnalyticsQueriesTableEntry from '@features/analytics/query/table/entry';
import { EAnalyticsQueriesTableTab } from '@features/analytics/query/table/utils';
import { EAnalyticsQueryUrlParam } from '@features/analytics/query/types';
import LocationWithRequiredStateContainer from '@features/general/locationExtension/withRequiredState';
import { viewHideMainMenu } from '@features/main/container/store/slice';
import { viewShowPartnerManagementMenu } from '@features/partner/management/container/store/slice';
import useCurrentUser from '@features/user/hooks/useCurrentUser';
import { Partner } from 'domain/model/partner';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ForbiddenComponent from '../../components/common/forbidden';

interface PartnerManagementQueryAnalyticsScreenProps {
  readonly partner: Partner;
}

const PartnerManagementQueryAnalyticsScreen = ({ partner }: PartnerManagementQueryAnalyticsScreenProps) => {
  const dispatch = useDispatch();
  const location = useLocation<Nullable<QueryAnalyticsLocationState>>();

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(EAnalyticsQueryUrlParam.Tab) as EAnalyticsQueriesTableTab;

  const {
    accessMatrix: { analyticsQueries },
  } = useCurrentUser();

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowPartnerManagementMenu());
  }, [dispatch]);

  if (!analyticsQueries?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<QueryAnalyticsLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <AnalyticsQueriesTableEntry
          guid={state.guid}
          tab={tab}
          partnerId={partner.id}
        />
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default PartnerManagementQueryAnalyticsScreen;
