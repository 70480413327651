import { Link, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import useBreadcrumbs from '../../../features/general/breadcrumbs/useBreadcrumbs';
import { StyledBreadcrumbs } from './controls';

interface AppBreadcrumbsProps {
  readonly dense?: boolean;
  readonly children?: any;
}

const AppBreadcrumbs = ({ dense = true, children }: AppBreadcrumbsProps) => {
  const breadcrumbs = useBreadcrumbs();

  const validBreadcrumbs = breadcrumbs.filter(breadcrumb => !!breadcrumb.label);

  return (
    <StyledBreadcrumbs
      aria-label='breadcrumb'
      dense={dense}
    >
      {validBreadcrumbs.map(({ location, label }) =>
        location ? (
          <Link
            component={NavLink}
            key={label}
            color='textSecondary'
            to={location}
          >
            <Typography>{label}</Typography>
          </Link>
        ) : (
          <Typography key={Math.random()}>{label}</Typography>
        )
      )}
      {children}
    </StyledBreadcrumbs>
  );
};

export default AppBreadcrumbs;
