import { appPalette } from '@theme/palette';
import { brandbook } from '@theme/privilege/brandBook';

/**
 * импортировать напрямую только в крайних случаях, все цвета доступны из theme.palette
 */
export const privilegePalette = {
  ...appPalette,
  error: {
    ...appPalette.error,
    main: brandbook.color.red,
    dark: brandbook.color.red,
    light: brandbook.color.red,
  },
  warning: {
    main: brandbook.color.orange,
    dark: brandbook.color.orange,
    light: brandbook.color.orange,
  },
};
