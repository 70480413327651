import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from './index';

const CorpOfferTableCellTargetFamilyMemberOnly = (props: CorpOfferTableCellCommonProps) => {
  const {
    corpOffer: { target },
  } = props;

  const value = target?.targetFamilyMemberOnly ? 'Да' : '';

  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellTargetFamilyMemberOnly;
