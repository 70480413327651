import { EPanelActionPosition } from '../../../../types';
import { AdCampaignLifeCycleRules } from '../../lifecycle/types';
import { AdCampaignActionCreateType, AdCampaignActions, EAdCampaignActionType } from '../../types';
import {
  changeAdCampaignActionPosition,
  filterAdCampaignCreateActions,
  makeAdCampaignActionPrimary,
  renameAdCampaignAction,
} from '../../utils/actions';

type UseAdCampaignCreateActions = AdCampaignActions<AdCampaignActionCreateType>;

interface UseAdCampaignCreateActionsProps {
  readonly lifeCycleRules: AdCampaignLifeCycleRules;
}

const useAdCampaignCreateActions = (props: UseAdCampaignCreateActionsProps): UseAdCampaignCreateActions => {
  const { lifeCycleRules } = props;

  const { nextActions } = lifeCycleRules;

  const actions = filterAdCampaignCreateActions(nextActions);

  //переименуем кнопку Редактирования/Сохранения - здесь любое сохранение - повод к публикации
  renameAdCampaignAction(actions, EAdCampaignActionType.Edit, 'Опубликовать');

  //перемещаем кнопку Редактирования/Сохранения на основную панель
  changeAdCampaignActionPosition(actions, EAdCampaignActionType.Edit, [EPanelActionPosition.Default]);

  // помечаем праймари экшоны
  return makeAdCampaignActionPrimary(actions, [EAdCampaignActionType.Edit, EAdCampaignActionType.Resume]);
};

export default useAdCampaignCreateActions;
