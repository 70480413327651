import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../data/store/store';
import { ESortDirection } from '../../../../../domain/model/enums';
import { PartnerShortDraft } from '../../../../../domain/model/partner';
import { Nullable } from '../../../../../domain/model/types';
import { EPartnerTableColumn } from '../../../general/partner/types';

export const partnersPartnersSelector = (store: RootState) => store.partner.list.data;
export const partnersIsFetchingSelector = (store: RootState) => store.partner.list.isFetching;
export const partnersGuidSelector = (store: RootState) => store.partner.list.guid;
export const partnersFilterSelector = (store: RootState) => store.partner.list.filter;
export const partnersPageNumberSelector = (store: RootState) => store.partner.list.pageNumber;
export const partnersPageCountSelector = (store: RootState) => store.partner.list.pageCount;
export const partnersTotalCountSelector = (store: RootState) => store.partner.list.totalCount;
export const partnersSearchSelector = (store: RootState) => store.partner.list.search;
export const partnersSortColumnSelector = (store: RootState) =>
  (store.partner.list.search.sort.split(',') as [EPartnerTableColumn, ESortDirection])?.[0] ?? '';
export const partnersSortDirectionSelector = (store: RootState) =>
  (store.partner.list.search.sort.split(',') as [EPartnerTableColumn, ESortDirection])?.[1] ?? '';
export const partnersNeedRefreshWatcherSelector = (store: RootState) => store.partner.list.needRefreshWatcher;
export const partnersActionsSelector = (store: RootState) => store.partner.list.actions;

export const partnersTabsCounterSelector = (store: RootState) => store.partner.list.tabsCounter;

export const partnersPartnerIndexSelector = (store: RootState, index: number) => index;

const createPartnersTablePartnerByIndexSelector = createSelector(
  partnersPartnersSelector,
  partnersPartnerIndexSelector,
  (partners, index): Nullable<PartnerShortDraft> => partners?.[index] ?? null
);
export const partnersPartnerByIndexSelector = (index: number) => (store: RootState) =>
  createPartnersTablePartnerByIndexSelector(store, index);
