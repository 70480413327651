import { createAsyncThunk } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { Nullable } from '../../../../../../domain/model/types';
import { ECmsTermTag } from '../../../container/types';
import cmsServices from '../../../services';
import { CmsTermValueSuggestion } from '../../../types';

export type TermValuesSearchFetchProps = {
  value: Nullable<string>;
  tag: ECmsTermTag;
};

export const cmsTermSearchValuesSuggestionFetch = createAsyncThunk<
  CmsTermValueSuggestion[],
  TermValuesSearchFetchProps,
  AppThunkAPIConfig
>('cms/term/search/fetch', async ({ value, tag }, { rejectWithValue, signal }) => {
  try {
    return await cmsServices.term.search({
      value,
      tag,
      signal,
    });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});
