import { combineReducers } from '@reduxjs/toolkit';
import container, { ClientOrgManagementContainerState } from './container/store/slice';
import menu, { ClientOrgManagementMenuState } from './menu/store/slice';

export interface ClientOrgManagementState {
  container: ClientOrgManagementContainerState;
  menu: ClientOrgManagementMenuState;
}

export default combineReducers<ClientOrgManagementState>({
  container,
  menu,
});
