import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import TablePagination from '../../../components/common/table/pagination';
import { useContextHandlers } from './hooks/useContextHandlers';
import {
  tradeActivationsPageCountSelector,
  tradeActivationsPageNumberSelector,
  tradeActivationsSearchSelector,
  tradeActivationsTotalCountSelector,
} from './store/selectors';

interface TradeActivationTableFooterContainerProps {}

const TradeActivationTableFooterContainer = forwardRef((props: TradeActivationTableFooterContainerProps, ref: any) => {
  const handlers = useContextHandlers();
  const search = useSelector(tradeActivationsSearchSelector);
  const pageNumber = useSelector(tradeActivationsPageNumberSelector);
  const totalCount = useSelector(tradeActivationsTotalCountSelector);
  const pageCount = useSelector(tradeActivationsPageCountSelector);

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='активаций'
        onChangePageSize={handlers.onChangePageSize}
        onChangePage={handlers.onChangePage}
      />
    </div>
  );
});

export default TradeActivationTableFooterContainer;
