import { getOfferPausedReasonText } from '../../../general/offer/utils/common';
import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from './index';

const CorpOfferTableCellPausedReason = (props: CorpOfferTableCellCommonProps) => {
  const {
    corpOffer: { pausedReason },
  } = props;

  const value = pausedReason ? getOfferPausedReasonText(pausedReason) : '-';

  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellPausedReason;
