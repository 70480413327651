import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SportOption } from '../../../../../domain/model';
import { EChatType } from '../../../../../domain/model/enums';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { getEventViewRoute } from '../../../../../presentation/features/event/entry';
import { getSportsmanDetailsRoute } from '../../../../../presentation/features/sportsman/entry';
import { getTeamRoute } from '../../../../../presentation/features/team/entry';
import { ChatAvatar } from '../../../../components/common/avatars';
import { pluralize } from '../../../../utils/pluralize';
import useChatMembers from '../../details/hooks/useChatMembers';
import { chatGetByIdSelector } from '../../details/store/selectors';
import { HeaderAnnotationWrapper, HeaderWrapper } from './controls';

interface ChatMessageComponentProps {
  readonly guid: UUID;
}

interface HeaderProps {
  readonly counter: number;
  readonly subject: Nullable<SportOption>;
  readonly onClick: () => void;
}

const Header = ({ subject, counter, onClick }: HeaderProps) => {
  return (
    <HeaderWrapper
      container
      spacing={1}
      onClick={onClick}
    >
      <Grid item>{subject && <ChatAvatar subject={subject} />}</Grid>
      <HeaderAnnotationWrapper item>
        <Grid
          container
          alignContent='center'
          justifyContent='space-between'
          direction='column'
          spacing={1}
        >
          <Grid item>
            <Typography variant='body2'>{subject?.name}</Typography>
          </Grid>
          <Grid item>
            <Typography
              variant='body2'
              color='textSecondary'
            >
              {counter} {pluralize(counter, ['участник', 'участника', 'участников'])}
            </Typography>
          </Grid>
        </Grid>
      </HeaderAnnotationWrapper>
    </HeaderWrapper>
  );
};

const ChatHeader = ({ guid }: ChatMessageComponentProps) => {
  const history = useHistory();

  const { data: chat } = useSelector(chatGetByIdSelector(guid));

  const { members } = useChatMembers({ guid, chatId: chat?.id ?? null });

  if (!chat) return null;

  const { subject, type: chatType } = chat;

  const onClickHeader = () => {
    if (!chatType) return null;

    const subjectId = subject?.id ?? '';

    switch (chatType) {
      case EChatType.PeerToPeer:
        history.push(getSportsmanDetailsRoute({ id: subjectId }));
        break;
      case EChatType.ForTeam:
        history.push(getTeamRoute({ id: subjectId }));
        break;
      case EChatType.AboutEvent:
      case EChatType.ForCombinedTeam:
      case EChatType.AboutSportsFest:
        history.push(getEventViewRoute({ id: subjectId }));
        break;
      case EChatType.TeamToTeam:
      case EChatType.ForCommunity:
        break;
    }
  };

  return (
    <Header
      subject={subject}
      counter={members.length}
      onClick={onClickHeader}
    />
  );
};

export default ChatHeader;
