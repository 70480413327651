import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UUID } from '../../../../../domain/model/types';
import { partnerEmployeesGuidSelector } from '../store/selectors';
import { partnerEmployeesSetTab, partnerEmployeesStartSession } from '../store/slice';
import { EPartnerEmployeeTableTab } from '../utils';

interface UsePartnerEmployeesTableSessionProps {
  readonly guid: UUID;
  readonly tab: EPartnerEmployeeTableTab;
}

const usePartnerEmployeeTableSession = (props: UsePartnerEmployeesTableSessionProps): void => {
  const { guid, tab } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(partnerEmployeesGuidSelector);

  // инициализация сеанса
  useEffect(() => {
    dispatch(partnerEmployeesStartSession({ guid }));
  }, [dispatch, guid]);

  // начальная загрузка, изменение вкладки
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(partnerEmployeesSetTab({ tab }));
    }
  }, [dispatch, guid, currentGuid, tab]);
};

export default usePartnerEmployeeTableSession;
