import {MPPaletteType} from './types';

/**
 * импортировать напрямую только в крайних случаях, все цвета доступны из theme.palette
 */
export const appPalette: MPPaletteType = {
  background: '#fff',
  primary: {
    main: '#406aff',
    light: '#8298ff',
    dark: '#0040cb',
  },
  secondary: {
    main: '#d9dcf0',
    dark: '#a7aabd',
    light: '#f0f5ff',
  },
  text: {
    primary: '#000',
    secondary: '#9198a5',
    onPrimary: '#fff',
  },
  disabled: {
    text: '#b6b6b6',
    background: '#f2f2f2',
  },
  error: {
    main: '#ff9b26',
    dark: '#dd7902',
    light: '#ffe1be',
  },
  success: '#0eb500',
  border: '#9d91a5',
};

export const publicAppPalette = {
  primary: '#ff4040',
  secondary: '#d9dcf0',
  textPrimary: '#4b4f57',
  textSecondary: '#a9b1c2',
  border: '#eef1f3',
  shadow: 'rgba(0, 0, 0, 0.05)',
};
