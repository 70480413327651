import { AnyClientOrg, ClientOrg, ClientOrgCreate, UserAccessPartitionClientOrgs } from '@/domain';
import { OnChangeObjectAttribute, PanelAction, PanelActions } from '@/presentation/types';
import { ValidationItemResult, ValidationRules } from '@/presentation/utils/validation';
import { MPStepperStep } from '@components/common/stepper';
import { ReactElement } from 'react';

export enum EClientOrgTableTab {
  Active = 'active',
  Blocked = 'blocked',
}

export enum EClientOrgCreateUrlParam {
  Guid = 'guid',
  Id = 'id',
}

export enum EClientOrgStep {
  General = 'general',
}

export enum EClientOrgActionType {
  /** Сохранение/редактирование */
  Edit = 'edit',
  /** Блокировка организации */
  Block = 'block',
  /** Разблокировка организации */
  UnBlock = 'unblock',
}

export type ClientOrgCreateStepType = EClientOrgStep.General;

export type ClientOrgCreateStep = MPStepperStep<ClientOrgCreateStepType>;

export type ClientOrgActionTableType = EClientOrgActionType.Block | EClientOrgActionType.UnBlock;

export type ClientOrgTableStubAdapterProps = {
  readonly children: (props: ClientOrgTableStubAdapterResult) => ReactElement<any, any>;
};

export type ClientOrgTableStubAdapterResult = {
  readonly canViewTable: boolean;
  readonly stub?: Nullable<JSX.Element>;
};

export type ClientOrgTableStubAdapterType = (props: ClientOrgTableStubAdapterProps) => ReactElement<any, any>;

export type ActionClientOrgType = ClientOrg | ClientOrgCreate;

export type ClientOrgAction<A extends EClientOrgActionType, P = any> = PanelAction<A, P>;

export type ClientOrgActions<A extends string = EClientOrgActionType> = PanelActions<A>;

export type GetClientOrgActionsByPermissions = UserAccessPartitionClientOrgs;

export type ClientOrgActionCreateType = EClientOrgActionType.Edit;

export type ClientOrgActionDetailsType =
  | EClientOrgActionType.Edit
  | EClientOrgActionType.Block
  | EClientOrgActionType.UnBlock;

export type ClientOrgValidationRules = ValidationRules<ClientOrgForValidation>;

type ClientOrgTypesForField = ClientOrgCreate | AnyClientOrg;

export type ClientOrgForValidation = ClientOrgCreate | ClientOrg;

export interface ClientOrgFieldCommonProps<T extends ClientOrgTypesForField, Key extends keyof T = any> {
  readonly value: T[Key];
  readonly disabled?: boolean;
  readonly validation: Nullable<ValidationItemResult>;
  readonly onChangeAttribute: OnChangeObjectAttribute<T>;
  readonly onValidateAttribute: (name: keyof T) => void;
}

export interface ClientOrgFieldViewProps<T extends ClientOrgTypesForField, Key extends keyof T = any> {
  readonly value: T[Key];
}

export type ClientOrgViewFieldCommonProps<Key extends keyof ClientOrgCreate> = ClientOrgFieldViewProps<
  ClientOrgCreate,
  Key
>;

export type ClientOrgCreateFieldCommonProps<Key extends keyof ClientOrgCreate> = ClientOrgFieldCommonProps<
  ClientOrgCreate,
  Key
>;
