import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../../data/store/types';
import { DataTreeItem, DataTreeItemMutable } from '../../../../../../domain/model';
import { BookingServiceCategory } from '../../../../../../domain/model/booking';
import { EServiceOrderByDateType } from '../../../../../../domain/model/enums';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import service from '../../services';
import { buildBookingServiceCategoryTree, buildBookingServiceCategoryTreeItem } from '../utils';

export const bookingServiceCategoriesEditFetch = createAsyncThunk<BookingServiceCategory[], void, AppThunkAPIConfig>(
  'bookingServiceCategory/edit/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await service.all({ signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const bookingServiceCategoriesEditCreate = createAsyncThunk<
  BookingServiceCategory,
  BookingServiceCategory,
  AppThunkAPIConfig
>('bookingServiceCategory/edit/create', async (data, { rejectWithValue }) => {
  try {
    return await service.create(data);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingServiceCategoriesEditUpdate = createAsyncThunk<
  BookingServiceCategory,
  BookingServiceCategory,
  AppThunkAPIConfig
>('bookingServiceCategory/edit/update', async (data, { rejectWithValue }) => {
  try {
    return await service.update({ id: data.id, data });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingServiceCategoriesEditAddOrderByDateType = createAsyncThunk<
  BookingServiceCategory,
  { bookingServiceCategory: BookingServiceCategory; orderByDateType: EServiceOrderByDateType },
  AppThunkAPIConfig
>(
  'bookingServiceCategory/edit/addOrderByDateType',
  async ({ bookingServiceCategory, orderByDateType }, { rejectWithValue }) => {
    try {
      const data: BookingServiceCategory = {
        ...bookingServiceCategory,
        orderByDateTypes: [...(bookingServiceCategory.orderByDateTypes ?? []), orderByDateType],
      };

      return await service.update({
        id: bookingServiceCategory.id,
        data,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const bookingServiceCategoriesEditRemoveOrderByDateType = createAsyncThunk<
  BookingServiceCategory,
  { bookingServiceCategory: BookingServiceCategory; orderByDateType: EServiceOrderByDateType },
  AppThunkAPIConfig
>(
  'bookingServiceCategory/edit/removeOrderByDateType',
  async ({ bookingServiceCategory, orderByDateType }, { rejectWithValue }) => {
    try {
      const data: BookingServiceCategory = {
        ...bookingServiceCategory,
        orderByDateTypes: [...(bookingServiceCategory.orderByDateTypes ?? []).filter(item => item !== orderByDateType)],
      };

      return await service.update({
        id: bookingServiceCategory.id,
        data,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const bookingServiceCategoriesEditDelete = createAsyncThunk<
  BookingServiceCategory,
  BookingServiceCategory,
  AppThunkAPIConfig
>('bookingServiceCategory/edit/delete', async (data, { rejectWithValue }) => {
  try {
    await service.delete({ id: data.id });
    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface BookingServiceCategoriesEditState {
  readonly fetch: Fetchable & {
    readonly data: Nullable<DataTreeItem<BookingServiceCategory>[]>;
  };
  readonly create: Fetchable;
  readonly delete: Fetchable;
  readonly update: Fetchable;
  readonly addOrderByDateType: Fetchable;
  readonly removeOrderByDateType: Fetchable;
  readonly dialogs: {
    readonly add: Nullable<{ parent: Nullable<DataTreeItem<BookingServiceCategory>> }>;
    readonly modify: Nullable<DataTreeItem<BookingServiceCategory>>;
    readonly delete: Nullable<DataTreeItem<BookingServiceCategory>>;
    readonly serviceChange: Nullable<DataTreeItem<BookingServiceCategory>>;
  };
}

type Reducer<T = undefined> = CaseReducer<BookingServiceCategoriesEditState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<BookingServiceCategoriesEditState> {
  readonly bookingServiceCategoriesEditResetState: Reducer;
  readonly bookingServiceCategoriesEditSetDialogState: Reducer<{
    name: keyof BookingServiceCategoriesEditState['dialogs'];
    data: any;
  }>;
}

const slice = createSlice<BookingServiceCategoriesEditState, Reducers, 'bookingServiceCategory/edit'>({
  name: 'bookingServiceCategory/edit',
  initialState: {
    fetch: {
      ...fetchableDefault,
      data: null,
    },
    create: {
      ...fetchableDefault,
    },
    delete: {
      ...fetchableDefault,
    },
    update: {
      ...fetchableDefault,
    },
    addOrderByDateType: {
      ...fetchableDefault,
    },
    removeOrderByDateType: {
      ...fetchableDefault,
    },
    dialogs: {
      add: null,
      modify: null,
      delete: null,
      serviceChange: null,
    },
  },
  reducers: {
    bookingServiceCategoriesEditResetState: state => {
      state.fetch = {
        ...fetchableDefault,
        data: null,
      };
      state.create = {
        ...fetchableDefault,
      };
      state.delete = {
        ...fetchableDefault,
      };
      state.update = {
        ...fetchableDefault,
      };
      state.addOrderByDateType = {
        ...fetchableDefault,
      };
      state.removeOrderByDateType = {
        ...fetchableDefault,
      };
      state.dialogs = {
        add: null,
        modify: null,
        delete: null,
        serviceChange: null,
      };
    },
    bookingServiceCategoriesEditSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bookingServiceCategoriesEditFetch.pending, state => {
        state.fetch.isFetching = true;
        state.fetch.isFetched = false;
        state.fetch.isFailed = false;
        state.fetch.data = null;
      })
      .addCase(bookingServiceCategoriesEditFetch.fulfilled, (state, { payload }) => {
        state.fetch.isFetching = false;
        state.fetch.isFetched = true;
        state.fetch.isFailed = false;
        state.fetch.data = buildBookingServiceCategoryTree(payload);
      })
      .addCase(bookingServiceCategoriesEditFetch.rejected, state => {
        state.fetch.isFetching = false;
        state.fetch.isFetched = false;
        state.fetch.isFailed = true;
        state.fetch.data = null;
      })
      .addCase(bookingServiceCategoriesEditCreate.pending, state => {
        state.create.isFetching = true;
        state.create.isFetched = false;
        state.create.isFailed = false;
      })
      .addCase(bookingServiceCategoriesEditCreate.fulfilled, (state, { payload }) => {
        state.create.isFetching = false;
        state.create.isFetched = true;
        state.create.isFailed = false;

        if (state.fetch.data) {
          state.fetch.data.push(buildBookingServiceCategoryTreeItem(payload));
        }
      })
      .addCase(bookingServiceCategoriesEditCreate.rejected, state => {
        state.create.isFetching = false;
        state.create.isFetched = false;
        state.create.isFailed = true;
      })
      .addCase(bookingServiceCategoriesEditUpdate.pending, state => {
        state.update.isFetching = true;
        state.update.isFetched = false;
        state.update.isFailed = false;
      })
      .addCase(bookingServiceCategoriesEditUpdate.fulfilled, (state, { payload }) => {
        state.update.isFetching = false;
        state.update.isFetched = true;
        state.update.isFailed = false;

        if (state.fetch.data) {
          const item = findItem(state.fetch.data, payload.id);
          if (item) {
            item.id = payload.id;
            item.label = payload.name;
            item.parentId = null;
            item.data = payload;
          }
        }
      })
      .addCase(bookingServiceCategoriesEditUpdate.rejected, state => {
        state.update.isFetching = false;
        state.update.isFetched = false;
        state.update.isFailed = true;
      })
      .addCase(bookingServiceCategoriesEditDelete.pending, state => {
        state.delete.isFetching = true;
        state.delete.isFetched = false;
        state.delete.isFailed = false;
      })
      .addCase(bookingServiceCategoriesEditDelete.fulfilled, (state, { payload }) => {
        state.delete.isFetching = false;
        state.delete.isFetched = true;
        state.delete.isFailed = false;

        if (state.fetch.data) {
          const index = state.fetch.data.findIndex(item => item.id === payload.id);
          state.fetch.data.splice(index, 1);
        }
      })
      .addCase(bookingServiceCategoriesEditDelete.rejected, state => {
        state.delete.isFetching = false;
        state.delete.isFetched = false;
        state.delete.isFailed = true;
      })
      .addCase(bookingServiceCategoriesEditAddOrderByDateType.pending, state => {
        state.addOrderByDateType.isFetching = true;
        state.addOrderByDateType.isFetched = false;
        state.addOrderByDateType.isFailed = false;
      })
      .addCase(bookingServiceCategoriesEditAddOrderByDateType.fulfilled, (state, { payload }) => {
        state.addOrderByDateType.isFetching = false;
        state.addOrderByDateType.isFetched = true;
        state.addOrderByDateType.isFailed = false;

        if (state.fetch.data) {
          const item = state.fetch.data.find(item => item.id === payload.id);
          const treeItem = buildBookingServiceCategoryTreeItem(payload);
          if (item) {
            item.id = treeItem.id;
            item.label = treeItem.label;
            item.children = treeItem.children;
            item.parentId = treeItem.parentId;
            item.data = treeItem.data;
          }
        }
      })
      .addCase(bookingServiceCategoriesEditAddOrderByDateType.rejected, state => {
        state.addOrderByDateType.isFetching = false;
        state.addOrderByDateType.isFetched = false;
        state.addOrderByDateType.isFailed = true;
      })
      .addCase(bookingServiceCategoriesEditRemoveOrderByDateType.pending, state => {
        state.removeOrderByDateType.isFetching = true;
        state.removeOrderByDateType.isFetched = false;
        state.removeOrderByDateType.isFailed = false;
      })
      .addCase(bookingServiceCategoriesEditRemoveOrderByDateType.fulfilled, (state, { payload }) => {
        state.removeOrderByDateType.isFetching = false;
        state.removeOrderByDateType.isFetched = true;
        state.removeOrderByDateType.isFailed = false;

        if (state.fetch.data) {
          const item = state.fetch.data.find(item => item.id === payload.id);
          const treeItem = buildBookingServiceCategoryTreeItem(payload);
          if (item) {
            item.id = treeItem.id;
            item.label = treeItem.label;
            item.children = treeItem.children;
            item.parentId = treeItem.parentId;
            item.data = treeItem.data;
          }
        }
      })
      .addCase(bookingServiceCategoriesEditRemoveOrderByDateType.rejected, state => {
        state.removeOrderByDateType.isFetching = false;
        state.removeOrderByDateType.isFetched = false;
        state.removeOrderByDateType.isFailed = true;
      });
  },
});

export const { bookingServiceCategoriesEditResetState, bookingServiceCategoriesEditSetDialogState } = slice.actions;

const findChild = (
  item: DataTreeItem<BookingServiceCategory>,
  id: UUID
): Nullable<DataTreeItemMutable<BookingServiceCategory>> =>
  item.id === id
    ? item
    : item.children?.reduce<Nullable<DataTreeItem<BookingServiceCategory>>>(
        (result, n) => result || findChild(n, id),
        null
      ) ?? null;

const findItem = (
  tree: DataTreeItem<BookingServiceCategory>[],
  id: UUID
): Nullable<DataTreeItemMutable<BookingServiceCategory>> => {
  let result: Nullable<DataTreeItemMutable<BookingServiceCategory>> = null;
  for (let i = 0; i < tree.length; i++) {
    result = findChild(tree[i], id);
    if (result) {
      break;
    }
  }
  return result;
};

export default slice.reducer;
