import TabsPanelLabel from '../../../../../components/common/tabs/label/label';
import { EOfferHistoryTableTab } from '../../../types';
import { getOfferHistoryTableTabName } from '../../utils/common';

interface OfferHistoryTableTabProps {
  readonly tab: EOfferHistoryTableTab;
}

const OfferHistoryTableTab = ({ tab }: OfferHistoryTableTabProps) => {
  return <TabsPanelLabel title={getOfferHistoryTableTabName(tab)} />;
};

export default OfferHistoryTableTab;
