import { MPFormInput, MPFormInputProps } from '../../../theme/ui-kit/input';
import styled from '@emotion/styled';

export const Input = styled(({ ...props }: MPFormInputProps) => <MPFormInput {...props} />)`
  .MuiInputBase-root {
    display: flex;
    justify-content: space-between;

    min-height: 0;

    border-radius: ${p => (p.theme.shape.borderRadius as number) * 2}px;

    background-color: ${p => p.theme.palette.highlight};

    &:hover {
      background-color: ${p => p.theme.palette.secondary.main};
    }
  }

  .MuiInputBase-input {
    // обнуляем паддинги для самого input что бы большее число влезло для отображения
    padding: 0 !important;
    text-align: center;

    // При любых размерах нужен одинаковый размер шрифта
    font-size: ${p => p.theme.typography.body1.fontSize} !important;
  }

  .MuiIconButton-root {
    padding: ${p => p.theme.spacing(0.7)};
  }

  .MuiInputBase-adornedStart {
    padding: 0;
  }

  .MuiInputBase-adornedEnd {
    padding: 0;
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;
