import { UserAccessPartitionAdCampaigns } from '@/domain/model/accessMatrix';
import { Nullable, UUID } from '@/domain/model/types';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import TableCommonLayout from '../../../layouts/tableCommon/container';
import TableLoaderCommonLayout from '../../../layouts/tableLoaderCommon/container';
import { EAdCampaignTableFilterItem } from '../filterUtils';
import { AdCampaignLifeCycle } from '../lifecycle/types';
import { AdCampaignTableStubAdapterType, EAdCampaignTableColumn, EAdCampaignTableTab } from '../types';
import { AdCampaignBehaviorAdapter } from './adapters/behavior';
import AdCampaignsTableFilterAdapter from './adapters/filters';
import AdCampaignsTableHeaderAdapter from './adapters/header';
import { AdCampaignTableAdapter } from './adapters/table';
import { AdCampaignTableTabsAdapter } from './adapters/tabs';
import { AdCampaignTableContext } from './context';
import AdCampaignTableFooterContainer from './footerContainer';
import { useAdCampaignTableHandlers } from './hooks/useAdCampaignTable';
import { adCampaignIsPreloadingSelector } from './store/selectors';
import { getAdCampaignTableTabActions } from '../utils/actions';

interface AdCampaignTableContainerProps {
  readonly guid: UUID;
  readonly partnerId: Nullable<UUID>;
  readonly tab: EAdCampaignTableTab;
  readonly stubAdapter: AdCampaignTableStubAdapterType;
  readonly filterAdapter: DataFilterAdapter<EAdCampaignTableColumn, EAdCampaignTableFilterItem>;
  readonly lifecycle: AdCampaignLifeCycle;
  readonly access: UserAccessPartitionAdCampaigns;
}

const AdCampaignTableContainer = (props: AdCampaignTableContainerProps) => {
  const { guid, partnerId, tab, lifecycle, stubAdapter: StubAdapter, filterAdapter, access } = props;

  const handlers = useAdCampaignTableHandlers({ tab });

  const tableTabActions = getAdCampaignTableTabActions(access, tab);

  return (
    <AdCampaignTableContext.Provider value={handlers}>
      <AdCampaignBehaviorAdapter
        guid={guid}
        tab={tab}
        partnerId={partnerId}
      />
      <TableLoaderCommonLayout selector={adCampaignIsPreloadingSelector}>
        <StubAdapter>
          {({ canViewTable, isCreateDisabled, createInfo, stub }) => (
            <TableCommonLayout
              canViewTable={canViewTable}
              filter={<AdCampaignsTableFilterAdapter filterAdapter={filterAdapter} />}
              header={
                <AdCampaignsTableHeaderAdapter
                  createInfo={createInfo}
                  isCreateDisabled={isCreateDisabled}
                  canCreate={access.create}
                />
              }
              tabs={<AdCampaignTableTabsAdapter tab={tab} />}
              table={
                <AdCampaignTableAdapter
                  lifecycle={lifecycle}
                  filterAdapter={filterAdapter}
                  tabActions={tableTabActions}
                />
              }
              footer={AdCampaignTableFooterContainer}
              stub={stub}
            ></TableCommonLayout>
          )}
        </StubAdapter>
      </TableLoaderCommonLayout>
    </AdCampaignTableContext.Provider>
  );
};

export default AdCampaignTableContainer;
