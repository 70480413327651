import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isClientOrgUserAnyChangedSelector } from '../store/selectors';
import { clientOrgUserActionsOptimize } from '../store/slice';

export const ClientOrgUserActionsOptimizerAdapter = () => {
  const dispatch = useDispatch();
  const needOptimize = useSelector(isClientOrgUserAnyChangedSelector);

  useEffect(() => {
    if (needOptimize > 0) {
      dispatch(clientOrgUserActionsOptimize());
    }
  }, [needOptimize]);

  return null;
};
