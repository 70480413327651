import moment from 'moment/moment';
import BookingOfferTableCell, { BookingOfferTableCellCommonProps } from './index';

const BookingOfferTableCellDate = (props: BookingOfferTableCellCommonProps) => {
  const {
    bookingOffer: { createdAt },
  } = props;
  const value = createdAt ? moment(createdAt).format('DD.MM.YYYY - h:mm') : '-';

  return (
    <BookingOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default BookingOfferTableCellDate;
