import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault, fetchableFailed, fetchableFetched, fetchableFetching } from '@/data/store/types';
import {
  ESportEventLevel,
  EUserType,
  SportEnumOption,
  SportUserProfile,
  Tournament,
  TournamentRequest,
} from '@/domain';
import {
  CaseReducer,
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { ValidationItemResult, ValidationResult } from '../../../../../utils/validation';
import { EventStatisticsProps } from '../../../components/statistics';
import eventServices from '../../../services';
import { TournamentCreateStepType } from '../../../types';
import { TournamentCreateUiState } from '../types';
import { convertTournamentToTournamentRequest, createNewTournamentRequest } from '../utils';

export const tournamentCreateByIdFetch = createAsyncThunk<
  Nullable<Tournament>,
  {
    id: Nullable<UUID>;
    level: Nullable<SportEnumOption<ESportEventLevel>>;
    userType: Nullable<EUserType>;
    admin: Nullable<SportUserProfile>;
  },
  AppThunkAPIConfig
>('tournament/create/byId/fetch', async ({ id }, { rejectWithValue, signal }) => {
  try {
    if (!id) return null;
    return await eventServices.tournament.one({ id, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tournamentCreateEventAdmins = createAsyncThunk<SportUserProfile[], {}, AppThunkAPIConfig>(
  'tournament/create/eventAdmins',
  async (data, { rejectWithValue }) => {
    try {
      const response = await eventServices.users.eventAdmins();
      return response.content;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tournamentCreateSave = createAsyncThunk<Tournament, TournamentRequest, AppThunkAPIConfig>(
  'tournament/create/save',
  async (tournament, { rejectWithValue }) => {
    try {
      return await eventServices.tournament.save({ tournament });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tournamentCreatePublish = createAsyncThunk<Tournament, TournamentRequest, AppThunkAPIConfig>(
  'tournament/create/publish',
  async (tournament, { rejectWithValue }) => {
    try {
      return await eventServices.tournament.publish({ tournament });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tournamentCreatePublishAnnounce = createAsyncThunk<Tournament, TournamentRequest, AppThunkAPIConfig>(
  'tournament/create/publishAnnounce',
  async (tournament, { rejectWithValue }) => {
    try {
      return await eventServices.tournament.publishAnnounce({ tournament });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export type TournamentCreateValidationStepper = Nullable<
  Record<TournamentCreateStepType, Nullable<ValidationResult<any>>>
>;

export interface TournamentCreateState {
  readonly guid: Nullable<UUID>;
  readonly modified: boolean;
  readonly byId: Fetchable & {
    readonly id: Nullable<UUID>;
    readonly tournament: TournamentRequest;
    readonly loadedData: Nullable<Tournament>;
  };
  readonly eventAdmins: Fetchable & {
    readonly data: SportUserProfile[];
  };
  readonly publish: Fetchable;
  readonly save: Fetchable;
  readonly dialogs: {
    readonly close: Nullable<TournamentRequest>;
  };
  readonly validation: ValidationResult<TournamentRequest>;
  readonly validationStepper: TournamentCreateValidationStepper;
  readonly ui: Nullable<TournamentCreateUiState>;
  readonly statistics: Nullable<EventStatisticsProps>;
}

type Reducer<T = undefined> = CaseReducer<TournamentCreateState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<TournamentCreateState> {
  tournamentCreateStartSession: Reducer<{
    guid: Nullable<UUID>;
  }>;
  tournamentCreateSetAttribute: Reducer<{
    name: keyof TournamentRequest;
    value: any;
  }>;
  tournamentCreateSetModified: Reducer<boolean>;
  tournamentCreateSetUiState: Reducer<{
    name: keyof TournamentCreateUiState;
    value: any;
  }>;
  tournamentCreateClearAttributeValidation: Reducer<keyof TournamentRequest>;
  tournamentCreateSetAttributeValidation: Reducer<{
    name: keyof TournamentRequest;
    results: Nullable<ValidationItemResult>;
  }>;
  tournamentCreateSetValidation: Reducer<Nullable<ValidationResult<TournamentRequest>>>;
  tournamentCreateSetValidationStepper: Reducer<
    Nullable<Record<TournamentCreateStepType, Nullable<ValidationResult<any>>>>
  >;
  tournamentCreateClearAllValidations: Reducer;
  tournamentCreateClearActionsState: Reducer;
  tournamentCreateSetDialogState: Reducer<{
    name: keyof TournamentCreateState['dialogs'];
    data: Nullable<TournamentRequest>;
  }>;
}

const slice = createSlice<TournamentCreateState, Reducers, 'tournament/create'>({
  name: 'tournament/create',
  initialState: {
    guid: null,
    modified: false,
    byId: {
      ...fetchableDefault,
      id: null,
      tournament: createNewTournamentRequest(),
      loadedData: null,
    },
    eventAdmins: {
      ...fetchableDefault,
      data: [],
    },
    publish: {
      ...fetchableDefault,
    },
    save: {
      ...fetchableDefault,
    },
    dialogs: {
      close: null,
    },
    validation: {},
    validationStepper: null,
    ui: null,
    statistics: null,
  },
  reducers: {
    tournamentCreateStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;
        state.byId = {
          ...fetchableDefault,
          id: null,
          tournament: createNewTournamentRequest(),
          loadedData: null,
        };
        state.modified = false;
        state.validation = {};
        state.ui = null;
      }
    },
    tournamentCreateSetModified: (state, { payload }) => {
      state.modified = payload;
    },
    tournamentCreateSetStatistics: (state, { payload }) => {
      state.statistics = payload;
    },
    tournamentCreateClearActionsState: state => {
      state.byId.isFetching = false;
      state.byId.isFetched = false;
      state.byId.isFailed = false;
      
      state.publish = fetchableDefault;
      state.save = fetchableDefault;
      state.validation = {};
      state.validationStepper = null;
      state.ui = null;
      state.dialogs = {
        close: null,
      };
    },
    tournamentCreateSetAttribute: (state, { payload }) => {
      const { name, value } = payload;

      if (state.byId.tournament) {
        (state.byId.tournament[name] as keyof TournamentRequest) = value;
      }
    },
    tournamentCreateSetUiState: (state, { payload }) => {
      const { name, value } = payload;
      if (!state.ui) {
        state.ui = {
          steps: [],
          options: [],
          fields: {
            visible: [],
            disabled: [],
          },
        };
      }
      state.ui[name] = value;
    },
    tournamentCreateClearAttributeValidation: (state, { payload }) => {
      delete state.validation?.[payload];
    },
    tournamentCreateSetAttributeValidation: (state, { payload }) => {
      const { name, results } = payload;
      if (!results) {
        delete state.validation?.[name];
      } else {
        state.validation[name] = results;
      }
    },
    tournamentCreateSetValidation: (state, { payload }) => {
      state.validation = payload ?? {};
    },
    tournamentCreateSetValidationStepper: (state, { payload }) => {
      state.validationStepper = payload;
    },
    tournamentCreateClearAllValidations: state => {
      state.validation = {};
    },
    tournamentCreateSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(tournamentCreateByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
      })
      .addCase(tournamentCreateByIdFetch.fulfilled, (state, { meta, payload }) => {
        const { level, userType, admin } = meta.arg;
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;
        state.byId.tournament = payload
          ? convertTournamentToTournamentRequest(payload)
          : {
              ...createNewTournamentRequest(),
              level,
              userType,
              admin,
            };
        state.byId.loadedData = payload;
      })
      .addCase(tournamentCreateByIdFetch.rejected, state => {
        state.byId.isFetching = false;
        state.byId.isFetched = false;
        state.byId.isFailed = true;
      })
      .addCase(tournamentCreateSave.pending, state => {
        state.save = fetchableFetching;
      })
      .addCase(tournamentCreateSave.fulfilled, (state, { payload }) => {
        state.save = fetchableFetched;

        state.byId.tournament = convertTournamentToTournamentRequest(payload);
        state.byId.loadedData = payload;
      })
      .addCase(tournamentCreateSave.rejected, state => {
        state.save = fetchableFailed;
      })
      .addCase(tournamentCreateEventAdmins.pending, state => {
        state.eventAdmins = { data: state.eventAdmins.data, ...fetchableFetching };
      })
      .addCase(tournamentCreateEventAdmins.fulfilled, (state, { payload }) => {
        state.eventAdmins = { data: payload, ...fetchableFetched };
      })
      .addCase(tournamentCreateEventAdmins.rejected, state => {
        state.eventAdmins = { data: state.eventAdmins.data, ...fetchableFailed };
      })
      .addMatcher(isAnyOf(tournamentCreatePublish.pending, tournamentCreatePublishAnnounce.pending), state => {
        state.publish = fetchableFetching;
      })
      .addMatcher(
        isAnyOf(tournamentCreatePublish.fulfilled, tournamentCreatePublishAnnounce.fulfilled),
        (state, { payload }) => {
          state.publish = fetchableFetched;

          state.byId.tournament = convertTournamentToTournamentRequest(payload);
          state.byId.loadedData = payload;
        }
      )
      .addMatcher(isAnyOf(tournamentCreatePublish.rejected, tournamentCreatePublishAnnounce.rejected), state => {
        state.publish = fetchableFailed;
      });
  },
});

export const {
  tournamentCreateSetAttribute,
  tournamentCreateSetModified,
  tournamentCreateClearActionsState,
  tournamentCreateStartSession,
  tournamentCreateSetUiState,
  tournamentCreateClearAttributeValidation,
  tournamentCreateSetAttributeValidation,
  tournamentCreateSetValidation,
  tournamentCreateClearAllValidations,
  tournamentCreateSetDialogState,
  tournamentCreateSetStatistics,
  tournamentCreateSetValidationStepper,
} = slice.actions;

export default slice.reducer;
