import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { EPartnerTableTab } from '../../../general/partner/types';
import {
  partnersFilterSelector,
  partnersNeedRefreshWatcherSelector,
  partnersPageNumberSelector,
  partnersSearchSelector,
} from '../store/selectors';
import {
  partnersCountsFetch,
  partnersFetch,
  PartnersFetchProps,
  partnersNeedRefreshWatcherReset,
} from '../store/slice';
import { getPartnerTableStatusesByTab } from '../utils';
import { useContextConfig } from './useContextConfig';

interface UsePartnersTableSearchProps {
  readonly tab: EPartnerTableTab;
  readonly userId: UUID;
}

const usePartnersTableSearch = (props: UsePartnersTableSearchProps): void => {
  const { tab, userId } = props;

  const dispatch = useDispatch();

  const [searchPromises, setSearchPromises] = useState<Nullable<any[]>>(null);

  const { tabsForCounts } = useContextConfig();

  const needRefreshWatcher = useSelector(partnersNeedRefreshWatcherSelector);
  const filter = useSelector(partnersFilterSelector);
  const search = useSelector(partnersSearchSelector);
  const pageNumber = useSelector(partnersPageNumberSelector);

  const currentStatuses = getPartnerTableStatusesByTab(tab);

  const queryParams = useMemo<PartnersFetchProps>(() => {
    return {
      search: {
        ...search,
        statuses: currentStatuses,
        createdById: tab === EPartnerTableTab.Unverified ? userId : null,
      },
      filter,
      pageNumber,
    };
  }, [filter, tab, currentStatuses, userId, search]);

  const onSearch = useCallback(() => {
    setSearchPromises([
      dispatch(partnersFetch(queryParams)),
      dispatch(
        partnersCountsFetch({
          ...queryParams,
          search: {
            ...queryParams.search,
            createdById: userId,
          },
          tabs: tabsForCounts,
        })
      ),
    ]);
  }, [dispatch, queryParams, userId, tabsForCounts]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  // сброс вотчера после ухода
  useEffect(() => {
    return () => {
      dispatch(partnersNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};

export default usePartnersTableSearch;
