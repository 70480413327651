import { useCallback } from 'react';
import { MPStepperStep } from '../../../components/common/stepper';

export type UseStepper<T extends string | number, S extends MPStepperStep<T>> = {
  readonly steps: S[];
  readonly currentStep: S;
  readonly nextStep: S;
  readonly currentStepIndex: number;
  readonly nextStepIndex: number;
  readonly isLastStep: boolean;
  readonly isNextStepDirectionForward: (nextStep: S) => boolean;
  readonly openStep: (nextStep: S) => void;
  readonly openFirstStep: () => void;
  readonly openNextStep: () => void;
  readonly openPrevStep: () => void;
};

interface UseStepperProps<T extends string | number, S extends MPStepperStep<T>> {
  readonly currentStepKey: T;
  readonly steps: S[];
  readonly openStep: (nextStep: S) => void;
}

const useStepper = <T extends string | number, S extends MPStepperStep<T>>(
  props: UseStepperProps<T, S>
): UseStepper<T, S> => {
  const { currentStepKey, steps, openStep } = props;

  const currentStepIndex = steps.findIndex(step => step.key === currentStepKey);
  const nextStepIndex = currentStepIndex + 1;
  const currentStep = steps[currentStepIndex];
  const nextStep = steps[nextStepIndex];
  const prevStep = steps[currentStepIndex - 1];
  const isLastStep = steps.length === 0 || currentStepKey === steps[steps.length - 1].key;

  const getNextStepDirection = useCallback(
    (nextStep: S): boolean => {
      const currentStepIndex = steps.findIndex(step => step.key === currentStepKey);
      const nextStepIndex = steps.findIndex(step => step.key === nextStep.key);
      return nextStepIndex > currentStepIndex;
    },
    [steps, currentStepKey]
  );

  const openNextStep = useCallback(() => openStep(nextStep), [openStep, nextStep]);

  const openFirstStep = useCallback(() => openStep(steps[0]), [openStep, steps]);

  const openPrevStep = useCallback(() => openStep(prevStep), [openStep, prevStep]);

  return {
    currentStep,
    nextStep,
    currentStepIndex,
    steps,
    nextStepIndex,
    isLastStep,
    isNextStepDirectionForward: getNextStepDirection,
    openStep,
    openFirstStep,
    openNextStep,
    openPrevStep,
  };
};

export default useStepper;
