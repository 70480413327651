import { UseCustomersTable } from '@features/customer/table/hooks/useTable';
import { UseCustomerTableConfig } from '@features/customer/table/hooks/useTableConfig';
import React from 'react';

export type CustomersTableContextValue = UseCustomersTable;

export const CustomersTableContext = React.createContext<CustomersTableContextValue>({} as CustomersTableContextValue);

export type CustomersTableConfigContextValue = UseCustomerTableConfig;

export const CustomersTableConfigContext = React.createContext<CustomersTableConfigContextValue>(
  {} as CustomersTableConfigContextValue
);
