import { EComplaintStatus } from '../../../../domain/model/enums';
import { DataTableColumns, ETableColumnAlign } from '../../../components/common/table';

export enum EComplaintTableTab {
  All = 'all',
  UnderConsideration = 'under_consideration',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export type ComplaintTableTabsCounter = {
  [name in EComplaintTableTab]?: number;
};

export type ComplaintCounterByStatus = {
  [name in EComplaintStatus]?: number;
};

export enum EComplaintTableColumn {
  Logo = 'logo',
  ObjectName = 'objectName',
  CreatedBy = 'createdBy',
  CreatedDate = 'createdDate',
  Causes = 'causes',
  DisplayId = 'displayId',
  ExecutedBy = 'executedBy.lastName',
}

const complaintTableColumns: DataTableColumns<EComplaintTableColumn> = {
  [EComplaintTableColumn.Logo]: {
    width: '2rem',
  },
  [EComplaintTableColumn.ObjectName]: {
    title: 'Название',
    width: '25%',
    wrap: true,
    sortable: true,
  },
  [EComplaintTableColumn.CreatedBy]: {
    title: 'Отправитель',
    width: '20%',
    wrap: true,
    sortable: true,
  },
  [EComplaintTableColumn.CreatedDate]: {
    title: 'Дата',
    width: '10rem',
    sortable: true,
  },
  [EComplaintTableColumn.Causes]: {
    title: 'Причина',
  },
  [EComplaintTableColumn.DisplayId]: {
    title: 'Номер жалобы',
    width: '12rem',
    sortable: true,
  },
  [EComplaintTableColumn.ExecutedBy]: {
    title: 'Администратор',
    width: '15rem',
    sortable: true,
    align: ETableColumnAlign.Right,
  },
};

export const getComplaintTableColumns = (): DataTableColumns<EComplaintTableColumn> => {
  return complaintTableColumns;
};

interface GetComplaintTableAdapterKeyResult {
  key: string;
}

export const getComplaintTableAdapterKey = (): GetComplaintTableAdapterKeyResult => {
  return {
    key: 'complaints',
  };
};

export const getComplaintTableTabName = (tab: EComplaintTableTab): string => {
  switch (tab) {
    case EComplaintTableTab.All:
      return 'Все жалобы';
    case EComplaintTableTab.UnderConsideration:
      return 'Ожидают рассмотрения';
    case EComplaintTableTab.Accepted:
      return 'Удовлетворены';
    case EComplaintTableTab.Rejected:
      return 'Отклонены';
  }
};
