import { MenuItem } from '@mui/material';
import React, { forwardRef } from 'react';

interface CmsContainerTableActionItemProps {
  readonly disabled: boolean;
  readonly children: React.ReactNode;
  readonly onExecute: () => void;
}

const CmsContainerTableActionItem = forwardRef((props: CmsContainerTableActionItemProps, ref: any) => {
  const { disabled, children, onExecute } = props;

  return (
    <MenuItem
      ref={ref}
      disabled={disabled}
      onClick={onExecute}
    >
      {children}
    </MenuItem>
  );
});

export default CmsContainerTableActionItem;
