import { SportOptionTyped } from '../../../../../domain/model';
import { EMultiSelectorValueType, EUserGender } from '../../../../../domain/model/enums';
import CorpOfferTableCell, { CorpOfferTableCellCommonProps } from './index';

export type CorpOfferTableCellUserGenderProps = CorpOfferTableCellCommonProps & {
  readonly userGenders: SportOptionTyped<EUserGender>[];
};

const CorpOfferTableCellUserGender = (props: CorpOfferTableCellUserGenderProps) => {
  const {
    corpOffer: { target },
    userGenders,
  } = props;

  const targetGender = target?.targetGender ?? { select: EMultiSelectorValueType.In, in: null };

  const value =
    targetGender.select === EMultiSelectorValueType.All
      ? 'для всех полов'
      : userGenders
          .filter(genderType => targetGender.in?.find(item => item === genderType.id))
          .map(genderType => genderType.name)
          .join(', ');

  return (
    <CorpOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default CorpOfferTableCellUserGender;
