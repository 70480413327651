import { Nullable } from '@/domain';
import AppBreadcrumbs from '@components/common/breadcrumbs';
import DefaultHeader from '@components/common/header';
import { Grid, Typography } from '@mui/material';
import { MPButton } from '@ui-kit/button';
import { useContextHandlers } from '../hooks/useContextHandlers';

interface AdCampaignTableHeaderAdapterProps {
  readonly createInfo?: Nullable<string>;
  readonly isCreateDisabled?: boolean;
  readonly canCreate?: boolean;
}

const AdCampaignTableHeaderAdapter = (props: AdCampaignTableHeaderAdapterProps) => {
  const { isCreateDisabled, canCreate } = props;
  const { onCreate } = useContextHandlers();
  const showCreateButton = canCreate;

  return (
    <DefaultHeader
      headline={<AppBreadcrumbs />}
      actions={
        showCreateButton && (
          <Grid
            container
            spacing={1}
            alignItems='center'
          >
            {showCreateButton && (
              <Grid item>
                <MPButton
                  disabled={isCreateDisabled}
                  variant='outlined'
                  size='small'
                  onClick={onCreate}
                >
                  Создать кампанию
                </MPButton>
              </Grid>
            )}
          </Grid>
        )
      }
    >
      <Typography variant='h2'>Рекламный кабинет</Typography>
    </DefaultHeader>
  );
};

export default AdCampaignTableHeaderAdapter;
