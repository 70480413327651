import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { ButtonLink } from '../../buttons/link';

export const Wrapper = styled(Grid)``;

export const StyledButtonLink = styled(ButtonLink)`
  color: ${p => p.theme.palette.text.secondary};
`;

export const ItemsWrapper = styled.div`
  height: ${p => p.theme.spacing(3.5)};

  overflow: hidden;
  position: relative;
  padding-right: ${p => p.theme.spacing(10)};

  && > * {
    margin-right: ${p => p.theme.spacing()};
    margin-bottom: ${p => p.theme.spacing()};
  }
`;
