import {
  BannerPlace,
  CustomerOrderCancellationReasonType,
  DictionaryItem,
  EChatType,
  ECmsContainerType,
  EPlaygroundStatus,
  ESportEventLevel,
  ESportEventStatus,
  ESportEventType,
  OfferPausedReason,
  OrderCancellationReasonType,
  SportEnumOption,
  SportOption,
} from '@/domain';
import axios, { AxiosResponse } from 'axios';
import { getComServicesEndpoint, getSportBaseEndpoint } from './utils';

type NsiApi = {
  playgroundStatuses: () => Promise<AxiosResponse<DictionaryItem<EPlaygroundStatus>[]>>;
  playgroundTypes: () => Promise<AxiosResponse<SportOption[]>>;
  eventTypes: () => Promise<AxiosResponse<DictionaryItem<ESportEventType>[]>>;
  eventLevels: () => Promise<AxiosResponse<DictionaryItem<ESportEventLevel>[]>>;
  eventStatuses: () => Promise<AxiosResponse<DictionaryItem<ESportEventStatus>[]>>;
  chatTypes: () => Promise<AxiosResponse<DictionaryItem<EChatType>[]>>;
  complaintCauses: () => Promise<AxiosResponse<SportOption[]>>;
  roads: () => Promise<AxiosResponse<SportOption[]>>;
  partnerRejectReasons: () => Promise<AxiosResponse<SportOption[]>>;
  offerRejectReasons: () => Promise<AxiosResponse<SportOption[]>>;
  offerPauseReasons: () => Promise<AxiosResponse<OfferPausedReason[]>>;
  orderCancelReasons: () => Promise<AxiosResponse<OrderCancellationReasonType[]>>;
  customerOrderCancelReasons: () => Promise<AxiosResponse<CustomerOrderCancellationReasonType[]>>;
  bannerPlaces: () => Promise<AxiosResponse<BannerPlace[]>>;
  cmsContainerTypes: () => Promise<AxiosResponse<SportEnumOption<ECmsContainerType>[]>>;
  socialPackageRejectReasons: () => Promise<AxiosResponse<SportOption[]>>;
};

/**
 * АПИ по работе с НСИ
 */
const nsi: NsiApi = {
  playgroundStatuses: () => {
    return axios.get(`${getSportBaseEndpoint()}/nsi/playgroundStatuses`);
  },
  playgroundTypes: () => {
    return axios.get(`${getSportBaseEndpoint()}/nsi/playgroundTypes`);
  },
  eventTypes: () => {
    return axios.get(`${getSportBaseEndpoint()}/nsi/eventTypes`);
  },
  eventLevels: () => {
    return axios.get(`${getSportBaseEndpoint()}/nsi/eventLevels`);
  },
  eventStatuses: () => {
    return axios.get(`${getSportBaseEndpoint()}/nsi/eventStatuses`);
  },
  chatTypes: () => {
    return axios.get(`${getSportBaseEndpoint()}/nsi/chatTypes`);
  },
  complaintCauses: () => {
    return axios.get(`${getSportBaseEndpoint()}/complaints/causes`);
  },
  roads: () => {
    return axios.get(`${getSportBaseEndpoint()}/road`);
  },
  partnerRejectReasons: () => {
    return axios.get(`${getComServicesEndpoint()}/partners/rejection-reasons`);
  },
  offerRejectReasons: () => {
    return axios.get(`${getComServicesEndpoint()}/offers/rejection-reasons`);
  },
  offerPauseReasons: () => {
    return axios.get(`${getComServicesEndpoint()}/offers/paused-reasons`);
  },
  orderCancelReasons: () => {
    return axios.get(`${getComServicesEndpoint()}/orders/cancel-reason-types`);
  },
  customerOrderCancelReasons: () => {
    return axios.get(`${getComServicesEndpoint()}/customer/order/cancel-reason-types`);
  },
  bannerPlaces: () => {
    return axios.get(`${getComServicesEndpoint()}/banner-places`);
  },
  cmsContainerTypes: () => {
    return axios.get(`${getComServicesEndpoint()}/cms/container-types`);
  },
  socialPackageRejectReasons: () => {
    return axios.get(`${getComServicesEndpoint()}/social-packages/rejection-reasons`);
  },
};

export default nsi;
