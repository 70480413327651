import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { EOrderPartition, EOrderStatus } from '../../../domain/model/enums';
import { ProductOrder } from '../../../domain/model/order';
import { Nullable } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import LocationWithRequiredStateContainer from '../../features/general/locationExtension/withRequiredState';
import { viewShowMainMenu } from '../../features/main/container/store/slice';
import {
  EOrderUrlParam,
  getOrderDetailsRoute,
  getOrderEditRoute,
  OrdersLocationState,
} from '../../features/order/entry';
import ProductOrderActionsProvider from '../../features/productOrder/actions/provider';
import ProductOrderTableEntry from '../../features/productOrder/table/entry';
import { EProductOrderTableTab } from '../../features/productOrder/types';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const ProductOrdersScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation<Nullable<OrdersLocationState>>();
  const history = useHistory();
  const {
    accessMatrix: { orders, isPartnerUser },
    mpPartnerUserProfile,
  } = useCurrentUser();

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(EOrderUrlParam.Tab) as EProductOrderTableTab;

  const onOrderClick = (order: ProductOrder) => {
    if (order.status === EOrderStatus.New && orders[EOrderPartition.ProductOffers].edit) {
      history.push(getOrderEditRoute({ id: order.id }));
    } else {
      history.push(getOrderDetailsRoute({ id: order.id }));
    }
  };

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (!orders[EOrderPartition.ProductOffers].view) {
    return <ForbiddenComponent />;
  }

  return (
    <LocationWithRequiredStateContainer<OrdersLocationState>
      defaultState={{
        guid: uuidv4(),
      }}
    >
      {({ state }) => (
        <ProductOrderActionsProvider>
          <ProductOrderTableEntry
            guid={state.guid}
            tab={tab}
            partnerId={isPartnerUser ? mpPartnerUserProfile.partner.id : null}
            onOrderClick={onOrderClick}
          />
        </ProductOrderActionsProvider>
      )}
    </LocationWithRequiredStateContainer>
  );
};

export default ProductOrdersScreen;
