import ProductTableCell, { ProductTableCellCommonProps } from './index';

const ProductTableCellProducerSKU = (props: ProductTableCellCommonProps) => {
  const {
    productOffer: { producerSKU: value = '-' },
  } = props;

  return (
    <ProductTableCell
      {...props}
      value={value}
    />
  );
};

export default ProductTableCellProducerSKU;