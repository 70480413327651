import { Chip } from '@mui/material';
import React from 'react';
import { RzhdRoad } from '../../../../../../domain/model/orgStructure';
import { Nullable } from '../../../../../../domain/model/types';
import { MPAutocompleteMultipleSelect } from '../../../../../theme/ui-kit/autocomplete/multiple';
import OrgStructureRoadMultipleItem from './item';
import {
  MultipleSelectorSelectAllType,
  OrgStructureRoadMultipleItemType,
  OrgStructureRoadMultipleSelectType,
  OrgStructureRoadMultipleTypesSelectorItem,
} from './types';
import useOrgStructureRoadMultipleTypes from './useMultipleTypes';

interface OrgStructureRoadMultipleTypesSelectorCommonProps {
  readonly selectAllType: MultipleSelectorSelectAllType;
  readonly selectNone?: boolean;
  readonly select?: Nullable<OrgStructureRoadMultipleSelectType>;
  readonly roads: RzhdRoad[];
  readonly label: string;
  readonly helperText?: string;
  readonly error?: boolean;
  readonly limitTags?: number;
  readonly disabled?: boolean;
  readonly isLoading?: boolean;
  readonly color?: 'default' | 'primary' | 'secondary' | undefined;
  readonly popupIcon?: React.ReactNode;
  /* набор данных по умолчанию (в моменты когда нет поискового запроса) */
  readonly defaultSource?: Nullable<RzhdRoad[]>;
  readonly onChange: (roads: RzhdRoad[]) => void;
  readonly onSelect?: (type: OrgStructureRoadMultipleSelectType) => void;
}

interface OrgStructureRoadMultipleTypesSelectorSelectAllFlagTypeProps
  extends OrgStructureRoadMultipleTypesSelectorCommonProps {
  readonly selectAllType: MultipleSelectorSelectAllType.Flag;
  readonly select?: Nullable<OrgStructureRoadMultipleSelectType>;
  readonly onSelect: (type: OrgStructureRoadMultipleSelectType) => void;
}

interface OrgStructureRoadMultipleTypesSelectorSelectAllListTypeProps
  extends OrgStructureRoadMultipleTypesSelectorCommonProps {
  readonly selectAllType: MultipleSelectorSelectAllType.List;
  readonly select?: undefined;
  readonly onSelect?: undefined;
}

type OrgStructureRoadMultipleTypesSelectorProps =
  | OrgStructureRoadMultipleTypesSelectorSelectAllFlagTypeProps
  | OrgStructureRoadMultipleTypesSelectorSelectAllListTypeProps;

/**
 * Компонент мультисеклектора дорог
 * Выбор 'Все дороги' устанавливает значение либо список всех дорог
 * либо [] в зависимости от selectAllType
 *
 * @example с переченем дорог снаружи для всех дорог
 *       <OrgStructureRoadMultipleTypesSelector
 *         selectAllType={MultipleSelectorSelectAllType.List}
 *         roads={value}
 *         label='Железная дорога'
 *         limitTags={2}
 *         helperText={validation?.[attribute]?.message}
 *         error={!!validation?.[attribute]?.hasError}
 *         popupIcon={<ExpandMoreIcon fontSize='small' />}
 *         onChange={onChange}
 *       />
 *
 * @example с флагом снаружи для всех дорог
 *       <OrgStructureRoadMultipleTypesSelector
 *         selectAllType={MultipleSelectorSelectAllType.Flag}
 *         selectAll={selectAllRoads}
 *         roads={value}
 *         label='Железная дорога'
 *         limitTags={2}
 *         helperText={validation?.[attribute]?.message}
 *         error={!!validation?.[attribute]?.hasError}
 *         popupIcon={<ExpandMoreIcon fontSize='small' />}
 *         onChange={onChange}
 *         onSelectAll={onSelectAllRoads}
 *       />
 */

const OrgStructureRoadMultipleTypesSelector = (props: OrgStructureRoadMultipleTypesSelectorProps) => {
  const {
    selectAllType,
    select,
    selectNone,
    roads,
    defaultSource,
    label,
    helperText,
    error,
    limitTags = -1,
    disabled = false,
    color = 'default',
    popupIcon,
    isLoading: isInitialLoading,
    onSelect,
    onChange,
  } = props;

  const { options, values, isLoading, setSearchValue, onChangeValue, getOptionLabel } =
    useOrgStructureRoadMultipleTypes({
      selectAllType,
      select,
      selectNone,
      roads,
      defaultSource,
      onSelect,
      onChange,
    });

  return (
    <MPAutocompleteMultipleSelect<OrgStructureRoadMultipleTypesSelectorItem>
      value={values}
      label={label}
      helperText={helperText}
      error={error}
      options={options}
      limitTags={limitTags}
      isLoading={isLoading || isInitialLoading}
      disabled={disabled}
      disableCloseOnSelect
      onSearchValue={setSearchValue}
      getOptionLabel={getOptionLabel}
      onChangeValue={onChangeValue}
      renderOption={(elementProps, option, state) => (
        <OrgStructureRoadMultipleItem
          key={option.id}
          elementProps={elementProps}
          option={option}
          state={state}
        />
      )}
      renderTags={(tagValue, getTagProps) => {
        const allRoadsSelected = tagValue.find(option => option.type === OrgStructureRoadMultipleItemType.All);
        return tagValue
          .filter(option => !allRoadsSelected || option.type === OrgStructureRoadMultipleItemType.All)
          .map((option, index) => (
            <Chip
              component={'div' as any}
              color={color}
              selected
              label={option.name}
              {...getTagProps({ index })}
              key={option.id}
            />
          ));
      }}
      popupIcon={popupIcon}
    />
  );
};

export default OrgStructureRoadMultipleTypesSelector;
