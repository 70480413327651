import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BookingOfferTableCellCommonProps } from '../../components/tableCell';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingOfferSearchBookingOfferByIndexSelector } from '../store/selectors';

export type BookingOfferSearchCellAdapterProps = {
  readonly index: number;
  readonly component: FC<BookingOfferTableCellCommonProps>;
};

const BookingOfferSearchCellAdapter = (props: BookingOfferSearchCellAdapterProps) => {
  const { index, component: Component } = props;

  const handlers = useContextHandlers();
  const bookingOffer = useSelector(bookingOfferSearchBookingOfferByIndexSelector(index));
  const isDisabled = bookingOffer ? handlers.isBookingOfferNotModeratedByMe(bookingOffer) : false;

  return useMemo(
    () =>
      bookingOffer ? (
        <Component
          bookingOffer={bookingOffer}
          isDisabled={isDisabled}
        />
      ) : null,
    [Component, bookingOffer, isDisabled]
  );
};

export default BookingOfferSearchCellAdapter;
