import axios, { AxiosResponse } from 'axios';
import { EOfferType, EOrderActionDiscriminator, EOrderDiscriminator, EOrderStatus } from '../../domain/model/enums';
import {
  BookingOrder,
  BookingOrderRequest,
  OrderFull,
  PartnerUpdateProductOrderCommand,
  ProductOrder,
} from '../../domain/model/order';
import { Nullable, UUID } from '../../domain/model/types';
import { createCancelToken } from './index';
import {
  ApiCancellable,
  ApiQueryDsl,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
  ApiResponseMaBeCounters,
} from './types';
import { appendQueryDslParams, getComServicesEndpoint } from './utils';

type MpAllProps<D extends ApiRequestListDiscriminator, OD extends EOrderDiscriminator> = ApiRequestPageable &
  ApiCancellable & {
    readonly orderDiscriminator: OD;
    readonly query?: Nullable<string>;
    readonly statuses?: EOrderStatus[];
    readonly customerId?: Nullable<UUID>;
    readonly partnerId?: Nullable<UUID>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
    readonly discriminator?: D;
  };

type BookingOrderResponse<D extends EOrderDiscriminator> = D extends EOrderDiscriminator.BookingOrder
  ? BookingOrder
  : never;
type ProductOrderResponse<D extends EOrderDiscriminator> = D extends EOrderDiscriminator.ProductOrder
  ? ProductOrder
  : never;

type FullOrderResponse<D extends EOrderDiscriminator> = BookingOrderResponse<D> | ProductOrderResponse<D>;

type ByIdProps = ApiCancellable & {
  readonly id: UUID;
};

type OneProps<OD extends EOrderDiscriminator> = ApiCancellable & {
  readonly id: UUID;
  readonly orderDiscriminator: OD;
};

type CommandProps<OD extends EOrderDiscriminator> = {
  readonly id: UUID;
  readonly orderDiscriminator: OD;
};

type CancelProps<OD extends EOrderDiscriminator> = CommandProps<OD> & {
  readonly reasonId: UUID;
  readonly comment?: string;
};

type ChangeStatusCommand = <OD extends EOrderDiscriminator>(
  props: CommandProps<OD>
) => Promise<AxiosResponse<FullOrderResponse<OD>>>;

type CancelCommand = <OD extends EOrderDiscriminator>(
  props: CancelProps<OD>
) => Promise<AxiosResponse<FullOrderResponse<OD>>>;

export type UpdateProductOrderProps = {
  readonly id: UUID;
  readonly data: PartnerUpdateProductOrderCommand;
};

type UpdateBookingOrderProps = {
  readonly id: UUID;
  readonly data: BookingOrderRequest;
};

type OrderApi = {
  readonly mp: {
    readonly all: <
      OD extends EOrderDiscriminator,
      D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List
    >(
      props: MpAllProps<D, OD>
    ) => Promise<AxiosResponse<ApiResponseMaBeCounters<D, FullOrderResponse<OD>[]>>>;
    readonly byId: (props: ByIdProps) => Promise<AxiosResponse<OrderFull>>;
    readonly one: <OD extends EOrderDiscriminator>(
      props: OneProps<OD>
    ) => Promise<AxiosResponse<FullOrderResponse<OD>>>;
    // команды по смене статуса
    readonly given: ChangeStatusCommand;
    readonly renew: ChangeStatusCommand;
    readonly activate: ChangeStatusCommand;
    readonly cancel: CancelCommand;
    readonly customerCancel: CancelCommand;
    readonly pay: ChangeStatusCommand;
    readonly send: ChangeStatusCommand;
    readonly return: ChangeStatusCommand;
    readonly partiallyReturn: ChangeStatusCommand;
    readonly confirm: ChangeStatusCommand;

    readonly updateProductOrder: (props: UpdateProductOrderProps) => Promise<AxiosResponse<ProductOrder>>;
    readonly updateBookingOrder: (props: UpdateBookingOrderProps) => Promise<AxiosResponse<BookingOrder>>;
  };
};

/**
 * АПИ по работе с заказами
 */
const order: OrderApi = {
  mp: {
    all: props => {
      const {
        orderDiscriminator,
        page,
        pageSize,
        sort,
        query,
        customerId,
        partnerId,
        querydsl,
        statuses,
        discriminator,
        signal,
      } = props;

      const params = new URLSearchParams();

      if (sort) {
        if (typeof sort === 'string') {
          params.append('sort', sort);
        } else {
          sort.forEach(item => params.append('sort', item));
        }
      }

      if (query) {
        params.append('q', query);
      }

      if (statuses) {
        statuses.forEach(s => params.append('status', s));
      }

      switch (orderDiscriminator) {
        case EOrderDiscriminator.BookingOrder:
          params.append('offerType', EOfferType.Booking);
          break;
        case EOrderDiscriminator.ProductOrder:
          params.append('offerType', EOfferType.Product);
          break;
      }

      if (customerId) {
        params.append('customerId', customerId);
      }

      if (partnerId) {
        params.append('partnerId', partnerId);
      }

      if (querydsl) {
        appendQueryDslParams(params, querydsl);
      }

      params.append('page', (page - 1).toString(10));
      params.append('size', pageSize.toString(10));

      if (discriminator) {
        params.append('resultType', discriminator);
      }

      return axios.get(`${getComServicesEndpoint()}/orders`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    byId: ({ id, signal }) => {
      return axios.get(`${getComServicesEndpoint()}/orders/${id}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    one: ({ id, signal }) => {
      return axios.get(`${getComServicesEndpoint()}/orders/${id}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    given: ({ id }) => {
      return axios.post(`${getComServicesEndpoint()}/orders/${id}`, {
        discriminator: EOrderActionDiscriminator.OrderChangeStatusCommand,
        status: EOrderStatus.Given,
      });
    },

    renew: ({ id }) => {
      return axios.post(`${getComServicesEndpoint()}/orders/${id}`, {
        discriminator: EOrderActionDiscriminator.OrderChangeStatusCommand,
        status: EOrderStatus.New,
      });
    },

    activate: ({ id }) => {
      return axios.post(`${getComServicesEndpoint()}/orders/${id}`, {
        discriminator: EOrderActionDiscriminator.OrderChangeStatusCommand,
        status: EOrderStatus.Active,
      });
    },
    pay: ({ id }) => {
      return axios.post(`${getComServicesEndpoint()}/orders/${id}`, {
        discriminator: EOrderActionDiscriminator.OrderChangeStatusCommand,
        status: EOrderStatus.Paid,
      });
    },
    send: ({ id }) => {
      return axios.post(`${getComServicesEndpoint()}/orders/${id}`, {
        discriminator: EOrderActionDiscriminator.OrderChangeStatusCommand,
        status: EOrderStatus.Sent,
      });
    },
    return: ({ id }) => {
      return axios.post(`${getComServicesEndpoint()}/orders/${id}`, {
        discriminator: EOrderActionDiscriminator.OrderChangeStatusCommand,
        status: EOrderStatus.Returned,
      });
    },
    partiallyReturn: ({ id }) => {
      return axios.post(`${getComServicesEndpoint()}/orders/${id}`, {
        discriminator: EOrderActionDiscriminator.OrderChangeStatusCommand,
        status: EOrderStatus.PartiallyReturned,
      });
    },
    confirm: ({ id }) => {
      return axios.post(`${getComServicesEndpoint()}/orders/${id}`, {
        discriminator: EOrderActionDiscriminator.OrderChangeStatusCommand,
        status: EOrderStatus.Confirmed,
      });
    },

    cancel: ({ id, comment, reasonId }) => {
      return axios.post(`${getComServicesEndpoint()}/orders/${id}`, {
        discriminator: EOrderActionDiscriminator.OrderCancelCommand,
        reason: { id: reasonId },
        comment,
      });
    },
    customerCancel: ({ id, comment, reasonId }) => {
      return axios.post(`${getComServicesEndpoint()}/orders/${id}`, {
        discriminator: EOrderActionDiscriminator.CustomerOrderCancelCommand,
        reason: { id: reasonId },
        comment,
      });
    },
    updateProductOrder: ({ id, data }) => {
      return axios.post(`${getComServicesEndpoint()}/orders/${id}`, {
        discriminator: EOrderActionDiscriminator.PartnerUpdateProductOrderCommand,
        ...data,
      });
    },
    updateBookingOrder: ({ id, data }) => {
      return axios.post(`${getComServicesEndpoint()}/orders/${id}`, {
        discriminator: EOrderActionDiscriminator.UpdateBookingOrderCommand,
        ...data,
      });
    },
  },
};

export default order;
