import { Nullable } from '../../../../../domain/model/types';
import ImagePixelCrop from '../../../../components/common/images/pixelCrop';
import { Drawer } from './controls';

interface ImagePixelCropContainerProps {
  readonly source: Nullable<File>;
  readonly widthInPx: number;
  readonly heightInPx: number;
  readonly onCancel: () => void;
  readonly onChange: (file: Nullable<File>) => void;
}

const ImagePixelCropContainer = (props: ImagePixelCropContainerProps) => {
  const { source, widthInPx, heightInPx, onCancel, onChange } = props;

  return (
    <Drawer
      open={!!source}
      elevation={0}
      anchor='top'
    >
      {source && (
        <ImagePixelCrop
          source={source}
          width={widthInPx}
          height={heightInPx}
          onCancel={onCancel}
          onChange={onChange}
        />
      )}
    </Drawer>
  );
};

export default ImagePixelCropContainer;
