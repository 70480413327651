import { Typography } from '@mui/material';
import { useHistory } from 'react-router';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../components/common/header';
import { MPButton } from '../../../../theme/ui-kit/button';
import { getPartnerEmployeeCreateRoute } from '../../entry';

interface PartnerEmployeeTableHeaderAdapterProps {
  readonly canCreate?: boolean;
}

const PartnerEmployeeTableHeaderAdapter = (props: PartnerEmployeeTableHeaderAdapterProps) => {
  const { canCreate } = props;

  const history = useHistory();

  const onCreate = () => history.push(getPartnerEmployeeCreateRoute({}));

  return (
    <DefaultHeader
      headline={<AppBreadcrumbs />}
      actions={
        canCreate && (
          <MPButton
            variant='outlined'
            size='small'
            onClick={onCreate}
          >
            Добавить сотрудника
          </MPButton>
        )
      }
    >
      <Typography variant='h2'>Сотрудники</Typography>
    </DefaultHeader>
  );
};

export default PartnerEmployeeTableHeaderAdapter;
