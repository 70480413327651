import { Skeleton } from '@mui/material';
import styled from '@emotion/styled';
import AppImage from '../../../../components/common/images/common';

export const ImageWrapper = styled.div`
  position: relative;
`;

export const Image = styled(AppImage)`
  width: 100%;
  height: auto;
  max-height: 20rem;

  vertical-align: top;
`;

export const ImageSkeleton = styled(Skeleton)`
  width: 100%;
  height: 100%;

  position: absolute;

  transform: none;
  border-radius: 0;
`;
