import confirmCode, { RequiredActionConfirmCodeState } from './confirmCode/store/slice';
import { combineReducers } from '@reduxjs/toolkit';

interface RequiredActionState {
  confirmCode: RequiredActionConfirmCodeState;
}

export default combineReducers<RequiredActionState>({
  confirmCode,
});
