import { AdCampaignTableCell, AdCampaignTableCellCommonProps } from './index';

export const AdCampaignTableCellViewCount = (props: AdCampaignTableCellCommonProps) => {
  const { adCampaign } = props;

  const value = adCampaign.viewCount;

  return (
    <AdCampaignTableCell
      {...props}
      value={value}
    />
  );
};
