import { ESortDirection, TradeOffer } from '@/domain';
import { DataTableLoader } from '@components/common/table/loader';
import TradeOfferTableCellTargetClientOrgs from '@features/tradeOffer/components/tableCell/targetClientOrgs';
import TradeOfferTableCellTargetExternalUsers from '@features/tradeOffer/components/tableCell/targetExternalUsers';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../components/common/table';
import TradeOfferTableCellAggregatorIntegrationId from '../components/tableCell/aggregatorIntegrationId';
import TradeOfferTableCellApprovingAdmin from '../components/tableCell/approvingAdmin';
import TradeOfferTableCellCode from '../components/tableCell/code';
import TradeOfferTableCellLastStatusAuthor from '../components/tableCell/lastStatusAuthor';
import TradeOfferTableCellLastStatusDate from '../components/tableCell/lastStatusDate';
import TradeOfferTableCellName from '../components/tableCell/name';
import TradeOfferTableCellOfferCounts from '../components/tableCell/offerCounts';
import TradeOfferTableCellPartner from '../components/tableCell/partner';
import TradeOfferTableCellPausedReason from '../components/tableCell/pausedReason';
import TradeOfferTableCellPeriod from '../components/tableCell/period';
import TradeOfferTableCellPrice from '../components/tableCell/price';
import TradeOfferTableCellPromotionType from '../components/tableCell/promotionType';
import TradeOfferTableCellStatus from '../components/tableCell/status';
import TradeOfferTableCellTargetLocalities from '../components/tableCell/targetLocalities';
import { ETradeOfferTableColumn } from '../types';
import TradeOffersTableCellAdapter from './adapters/cell';
import TradeOffersTableCellPromotionTypeAdapter from './adapters/cellPromotionType';
import TradeOffersTableCellStatusAdapter from './adapters/cellStatus';
import { tradeOffersSearchIsFetchingSelector } from './store/selectors';

interface TradeOfferSearchProps {
  readonly metadata: DataTableMetadata<ETradeOfferTableColumn>;
  readonly tradeOffers: TradeOffer[];
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly selectedTradeOffers: TradeOffer[];
  readonly isSingleSelect?: boolean;
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onTradeOfferClick: (tradeOffer: TradeOffer) => void;
  readonly onChangeMetadata: (metadata: DataTableMetadata<ETradeOfferTableColumn>) => void;
  readonly onTradeOfferSelect: (tradeOffer: TradeOffer, selected: boolean) => void;
  readonly onAllTradeOffersSelect: (selected: boolean) => void;
}

const TradeOfferSearch = (props: TradeOfferSearchProps) => {
  const {
    metadata,
    tradeOffers,
    sort,
    selectedTradeOffers,
    isSingleSelect,
    onRequestSort,
    onTradeOfferClick,
    onChangeMetadata,
    onTradeOfferSelect,
    onAllTradeOffersSelect,
  } = props;

  const rows: DataTableRow<TradeOffer, ETradeOfferTableColumn>[] = tradeOffers?.map((tradeOffer, index) => {
    return {
      [ETradeOfferTableColumn.Code]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellCode}
        />
      ),
      [ETradeOfferTableColumn.LastStatusDate]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellLastStatusDate}
        />
      ),
      [ETradeOfferTableColumn.Name]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellName}
        />
      ),
      [ETradeOfferTableColumn.PromotionType]: (
        <TradeOffersTableCellPromotionTypeAdapter
          index={index}
          component={TradeOfferTableCellPromotionType}
        />
      ),
      [ETradeOfferTableColumn.Partner]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellPartner}
        />
      ),
      [ETradeOfferTableColumn.Price]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellPrice}
        />
      ),
      [ETradeOfferTableColumn.Period]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellPeriod}
        />
      ),
      [ETradeOfferTableColumn.Status]: (
        <TradeOffersTableCellStatusAdapter
          index={index}
          component={TradeOfferTableCellStatus}
        />
      ),
      [ETradeOfferTableColumn.ApprovingAdmin]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellApprovingAdmin}
        />
      ),
      [ETradeOfferTableColumn.LastStatusAuthor]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellLastStatusAuthor}
        />
      ),
      [ETradeOfferTableColumn.OfferCounts]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellOfferCounts}
        />
      ),
      [ETradeOfferTableColumn.PausedReason]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellPausedReason}
        />
      ),
      [ETradeOfferTableColumn.TargetLocalities]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellTargetLocalities}
        />
      ),
      [ETradeOfferTableColumn.TargetClientOrgs]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellTargetClientOrgs}
        />
      ),
      [ETradeOfferTableColumn.TargetExternalUsers]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellTargetExternalUsers}
        />
      ),
      [ETradeOfferTableColumn.AggregatorIntegrationId]: (
        <TradeOffersTableCellAdapter
          index={index}
          component={TradeOfferTableCellAggregatorIntegrationId}
        />
      ),
      data: tradeOffer,
    };
  });

  return (
    <DataTable<TradeOffer, ETradeOfferTableColumn>
      metadata={metadata}
      width='auto'
      overflowX='inherit'
      rows={rows}
      sort={{ column: sort.column, direction: sort.direction }}
      loader={<DataTableLoader selector={tradeOffersSearchIsFetchingSelector} />}
      selectable
      singleSelect={isSingleSelect}
      selected={selectedTradeOffers}
      onSort={(event, column, direction) => onRequestSort(column, direction)}
      onRowClick={(event, cell, { data }) => onTradeOfferClick(data)}
      onAllRowsSelect={(event, selected) => onAllTradeOffersSelect(selected)}
      onRowSelect={(event, { data }, selected) => onTradeOfferSelect(data, selected)}
      onChangeMetadata={onChangeMetadata}
    />
  );
};

export default TradeOfferSearch;
