import { FCC } from 'react';
import { InnerBorder, LeftBottomBlock, LeftTopBlock, OuterBorder, RightBlock, TopBlock } from './controls';

const MobilePreviewWrapper: FCC = ({ children }) => (
  <OuterBorder>
    <TopBlock />
    <RightBlock />
    <LeftTopBlock />
    <LeftBottomBlock />
    <InnerBorder>{children}</InnerBorder>
  </OuterBorder>
);

export default MobilePreviewWrapper;
