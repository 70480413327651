import { Grid, ImageList, ImageListItem } from '@mui/material';
import moment from 'moment';
import { EDateFormat } from '../../../../../../domain/model/formats';
import { SportUserProfile } from '../../../../../../domain/model/user';
import AppImage from '../../../../../components/common/images/common';
import { Field, FieldsGroup } from '../../../../../components/fields';
import { pluralize } from '../../../../../utils/pluralize';
import ActivityTypesFieldView from '../../../../activityType/components/fieldView';
import { SportsmanMediaWrapper, Wrapper } from './controls';

interface SportsmanTabGeneralProps {
  readonly sportsman: SportUserProfile;
}

const SportsmanTabGeneral = ({ sportsman }: SportsmanTabGeneralProps) => {
  const formattedSportsmanAge =
    sportsman.age > 0 ? ` (${sportsman.age} ${pluralize(sportsman.age, ['год', 'года', 'лет'])})` : '';

  const getSportsmanBirthDate = (date: string): string => {
    return moment(date).format(EDateFormat.Human);
  };

  return (
    <Wrapper>
      <FieldsGroup>
        <Field label='Статус'>{sportsman.statusText}</Field>
        <Field label='Описание'>{sportsman.description}</Field>
        <Field label='Дата рождения'>
          {sportsman.birthDate && (
            <>
              {getSportsmanBirthDate(sportsman.birthDate)}
              {formattedSportsmanAge}
            </>
          )}
        </Field>
        {(sportsman.phone || sportsman.email) && (
          <Grid
            container
            spacing={2}
          >
            {sportsman.phone && (
              <Grid
                item
                xs={6}
              >
                <Field label='Номер телефона'>{sportsman.phone}</Field>
              </Grid>
            )}
            {sportsman.email && (
              <Grid
                item
                xs={6}
              >
                <Field label='E-mail'>{sportsman.email}</Field>
              </Grid>
            )}
          </Grid>
        )}
        <Field label='Дорога'>{sportsman.road?.name}</Field>
        <Field label='Подразделение'>{sportsman.department?.name}</Field>
        <Field>
          {sportsman.activityTypes && sportsman.activityTypes.length > 0 && (
            <ActivityTypesFieldView value={sportsman.activityTypes} />
          )}
        </Field>
        <Field label='Медиа'>
          {sportsman.rewards && sportsman.rewards.length > 0 && (
            <ImageList
              cols={3}
              gap={6}
            >
              {sportsman.rewards.map(reward => (
                <ImageListItem key={reward.path}>
                  <SportsmanMediaWrapper>
                    <AppImage src={reward} />
                  </SportsmanMediaWrapper>
                </ImageListItem>
              ))}
            </ImageList>
          )}
        </Field>
      </FieldsGroup>
    </Wrapper>
  );
};

export default SportsmanTabGeneral;
