import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UUID } from '../../../../../../domain/model/types';
import { adminMpsGuidSelector } from '../store/selectors';
import { adminMpSetTab, adminMpStartSession } from '../store/slice';
import { EAdminMpTableTab } from '../utils';

interface UseAdminMpTableSessionProps {
  readonly guid: UUID;
  readonly tab: EAdminMpTableTab;
}

const useAdminMpTableSession = (props: UseAdminMpTableSessionProps): void => {
  const { guid, tab } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(adminMpsGuidSelector);

  // инициализация сеанса
  useEffect(() => {
    dispatch(adminMpStartSession({ guid }));
  }, [dispatch, guid]);

  // начальная загрузка, изменение вкладки
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(adminMpSetTab({ tab }));
    }
  }, [dispatch, guid, currentGuid, tab]);
};

export default useAdminMpTableSession;