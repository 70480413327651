import { getCorpOfferActivationStatusText } from '../../utils/common';
import CorpActivationTableCell, { CorpActivationTableCellCommonProps } from './index';

const CorpActivationTableCellStatus = (props: CorpActivationTableCellCommonProps) => {
  const {
    corpActivation: { status },
  } = props;

  return (
    <CorpActivationTableCell
      {...props}
      value={getCorpOfferActivationStatusText(status)}
    />
  );
};

export default CorpActivationTableCellStatus;
