import { appTypography } from '../typography';
import { brandbook } from './brandBook';

export const privilegeTypography = {
  ...appTypography,
  fontFamily: `'${brandbook.typography.fontFamily}', sans-serif`,
  body1: brandbook.typography.body1,
  body2: brandbook.typography.body2,

  subtitle1: brandbook.typography.subtitle1,
  subtitle2: brandbook.typography.p2Medium,
  caption: {
    ...brandbook.typography.p3,
    display: 'block',
  },
  h2: brandbook.typography.h2,
  h3: brandbook.typography.h3,
  overline: brandbook.typography.p4,
};

export const publicAppTypography = {
  body1: {
    fontSize: '1rem',
    lineHeight: '1.375rem',
  },
};
