import { Chip, Grid } from '@mui/material';
import styled from '@emotion/styled';
import ConfirmDialog from '../../../../components/common/dialogs/confirm';

export const CategoryChip = styled(Chip)`
  height: 100%;
  min-height: 1.625rem;

  padding: ${p => p.theme.spacing(0.5, 0, 0.5, 0)};

  .MuiChip-label {
    white-space: normal;
  }
`;

export const Header = styled(Grid)`
  padding: ${p => p.theme.spacing(3, 3, 1, 2)};
`;

export const Content = styled.div`
  height: 100%;

  padding: ${p => p.theme.spacing(0, 7, 2, 7)};

  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const StyledDialog = styled(ConfirmDialog)`
  .MuiPaper-root {
    height: 100%;
  }

  .MuiDialog-paper {
    padding: 0;
  }

  .MuiDialogTitle-root {
    border-bottom: 1px solid ${p => p.theme.palette.secondary.main};
  }
`;
