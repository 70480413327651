import { Nullable } from '@/domain/model/types';
import { MPFormInput } from '@/presentation/theme/ui-kit/input';
import { LimitLabel, TextFieldLimited } from '@/presentation/theme/ui-kit/input/controls';
import { Autocomplete, AutocompleteProps, Chip } from '@mui/material';
import { useEffect, useState } from 'react';

type ValueType = string;

export type KeywordsInputProps = Omit<
  AutocompleteProps<ValueType, true, false, true>,
  'onChange' | 'renderInput' | 'options'
> & {
  readonly label?: string;
  readonly helperText?: Nullable<string>;
  readonly error?: boolean;
  readonly maxCount?: number;
  readonly value: Nullable<string[]>;
  readonly onChange: (value: Nullable<string[]>) => void;
};

const KeywordsInput = (props: KeywordsInputProps) => {
  const { label, error, helperText, value, placeholder, maxCount, onChange } = props;

  const [localError, setLocalError] = useState<Nullable<string>>(null);

  const onChangeInternal = (value: string[]) => {
    if (maxCount && value.length > maxCount) {
      setLocalError(`Максимум ${maxCount} значений`);
    } else {
      onChange(value);
    }
  };

  useEffect(() => {
    setLocalError(null);
  }, [value, error, helperText]);

  return (
    <TextFieldLimited>
      <Autocomplete<ValueType, true, false, true>
        freeSolo
        multiple
        value={value ?? []}
        options={[]}
        onChange={(event, newValue) => onChangeInternal(newValue)}
        renderInput={params => (
          <MPFormInput
            {...params}
            error={!!localError || !!error}
            helperText={localError || helperText}
            label={label}
            placeholder={placeholder}
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              component={'div'}
              label={option}
              color='primary'
              {...getTagProps({ index })}
              key={option}
            />
          ))
        }
      />
      {maxCount && (
        <LimitLabel
          color='textSecondary'
          variant='body2'
        >
          {value?.length ?? 0} из {maxCount}
        </LimitLabel>
      )}
    </TextFieldLimited>
  );
};

export default KeywordsInput;
