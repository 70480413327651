import { DataTableMetadata } from '@components/common/table';
import useDataTableSettings from '@/presentation/hooks/useDataTableSettings';
import { EProductOrderItemsTableColumn, getProductOrderItemsColumns } from '../utils';
import { useMemo } from 'react';

type ColumnsType = EProductOrderItemsTableColumn;

type UseProductOrderItemsTableResult = {
  readonly metadata: DataTableMetadata<ColumnsType>;
  readonly onChangeMetadata: (metadata: DataTableMetadata<ColumnsType>) => void;
};

export const useProductOrderItemsTable = (): UseProductOrderItemsTableResult => {
  const sourceColumns = useMemo(() => getProductOrderItemsColumns(), []);

  const { metadata, onChangeMetadata } = useDataTableSettings<ColumnsType>({
    name: 'product-order-items',
    sourceColumns,
  });

  return {
    metadata,
    onChangeMetadata,
  };
};
