import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault, fetchableFailed, fetchableFetched, fetchableFetching } from '@/data/store/types';
import {
  ESportEventLevel,
  EUserType,
  SportEnumOption,
  SportsFest,
  SportsFestCombinedTeamRequest,
  SportsFestContestRequest,
  SportsFestRequest,
  SportUserProfile,
} from '@/domain';
import {
  CaseReducer,
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { ValidationCollectionResult, ValidationItemResult, ValidationResult } from '../../../../../utils/validation';
import { EventStatisticsProps } from '../../../components/statistics';
import eventServices from '../../../services';
import { SportsFestCreateStepType } from '../../../types';
import { SportsFestCreateUiState } from '../types';
import { convertSportsFestToSportsFestRequest, createNewSportsFestRequest } from '../utils';

export const sportsFestCreateByIdFetch = createAsyncThunk<
  Nullable<SportsFest>,
  {
    id: Nullable<UUID>;
    level: Nullable<SportEnumOption<ESportEventLevel>>;
    userType: Nullable<EUserType>;
    admin: Nullable<SportUserProfile>;
  },
  AppThunkAPIConfig
>('sportsFest/create/byId/fetch', async ({ id }, { rejectWithValue, signal }) => {
  try {
    if (!id) return null;
    return await eventServices.sportsFest.one({ id, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const sportsFestCreateSave = createAsyncThunk<SportsFest, SportsFestRequest, AppThunkAPIConfig>(
  'sportsFest/create/save',
  async (sportsFest, { rejectWithValue }) => {
    try {
      return await eventServices.sportsFest.save({ sportsFest });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const sportsFestCreatePublish = createAsyncThunk<SportsFest, SportsFestRequest, AppThunkAPIConfig>(
  'sportsFest/create/publish',
  async (sportsFest, { rejectWithValue }) => {
    try {
      return await eventServices.sportsFest.publish({ sportsFest });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const sportsFestCreatePublishAnnounce = createAsyncThunk<SportsFest, SportsFestRequest, AppThunkAPIConfig>(
  'sportsFest/create/publishAnnounce',
  async (sportsFest, { rejectWithValue }) => {
    try {
      return await eventServices.sportsFest.publishAnnounce({ sportsFest });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface SportsFestCreateState {
  readonly guid: Nullable<UUID>;
  readonly byId: Fetchable & {
    readonly id: Nullable<UUID>;
    readonly sportsFest: SportsFestRequest;
    readonly loadedData: Nullable<SportsFest>;
  };
  readonly modified: boolean;
  readonly publish: Fetchable;
  readonly save: Fetchable;
  readonly dialogs: {
    readonly close: Nullable<SportsFestRequest>;
  };
  readonly validation: ValidationResult<SportsFestRequest>;
  readonly validationStepper: Nullable<Record<SportsFestCreateStepType, Nullable<ValidationResult<any>>>>;
  readonly validationContests: ValidationCollectionResult<SportsFestContestRequest>;
  readonly validationCombinedTeams: ValidationCollectionResult<SportsFestCombinedTeamRequest>;
  readonly ui: Nullable<SportsFestCreateUiState>;
  readonly statistics: Nullable<EventStatisticsProps>;
}

type Reducer<T = undefined> = CaseReducer<SportsFestCreateState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<SportsFestCreateState> {
  sportsFestCreateStartSession: Reducer<{ guid: Nullable<UUID> }>;
  sportsFestCreateSetAttribute: Reducer<{
    name: keyof SportsFestRequest;
    value: any;
  }>;
  sportsFestCreateSetModified: Reducer<boolean>;
  sportsFestCreateSetStatistics: Reducer<Nullable<EventStatisticsProps>>;
  sportsFestCreateSetContestAttribute: Reducer<{
    index: number;
    name: keyof SportsFestContestRequest;
    value: any;
  }>;
  sportsFestCreateSetCombinedTeamAttribute: Reducer<{
    index: number;
    name: keyof SportsFestCombinedTeamRequest;
    value: any;
  }>;
  sportsFestCreateAddCombinedTeam: Reducer;
  sportsFestCreateRemoveCombinedTeam: Reducer<SportsFestCombinedTeamRequest>;
  sportsFestCreateClearCombinedTeamUser: Reducer<SportsFestCombinedTeamRequest>;
  sportsFestCreateSetUiState: Reducer<{
    name: keyof SportsFestCreateUiState;
    value: any;
  }>;
  sportsFestCreateClearAllValidations: Reducer;
  sportsFestCreateClearAttributeValidation: Reducer<keyof SportsFestRequest>;
  sportsFestCreateSetAttributeValidation: Reducer<{
    name: keyof SportsFestRequest;
    results: Nullable<ValidationItemResult>;
  }>;
  sportsFestCreateSetDialogState: Reducer<{
    name: keyof SportsFestCreateState['dialogs'];
    data: Nullable<SportsFestRequest>;
  }>;
  sportsFestCreateSetValidation: Reducer<Nullable<ValidationResult<SportsFestRequest>>>;
  sportsFestCreateSetCombinedTeamsValidation: Reducer<
    Nullable<ValidationCollectionResult<SportsFestCombinedTeamRequest>>
  >;
  sportsFestCreateClearCombinedTeamAttributeValidation: Reducer<{
    combinedTeam: SportsFestCombinedTeamRequest;
    name: keyof SportsFestCombinedTeamRequest;
  }>;
  sportsFestCreateClearContestAttributeValidation: Reducer<{
    contest: SportsFestContestRequest;
    name: keyof SportsFestContestRequest;
  }>;
  sportsFestCreateClearContestValidation: Reducer<SportsFestContestRequest>;
  sportsFestCreateClearCombinedTeamValidation: Reducer<SportsFestCombinedTeamRequest>;
  sportsFestCreateSetContestsValidation: Reducer<Nullable<ValidationResult<SportsFestContestRequest>>[]>;
  sportsFestCreateClearActionsState: Reducer;
  sportsFestCreateSetValidationStepper: Reducer<
    Nullable<Record<SportsFestCreateStepType, Nullable<ValidationResult<any>>>>
  >;
}

const slice = createSlice<SportsFestCreateState, Reducers, 'sportsFest/create'>({
  name: 'sportsFest/create',
  initialState: {
    guid: null,
    byId: {
      ...fetchableDefault,
      id: null,
      sportsFest: createNewSportsFestRequest(),
      loadedData: null,
    },
    modified: false,
    publish: {
      ...fetchableDefault,
    },
    save: {
      ...fetchableDefault,
    },
    dialogs: {
      close: null,
    },
    validation: {},
    validationStepper: null,
    validationContests: [],
    validationCombinedTeams: [],
    ui: null,
    statistics: null,
  },
  reducers: {
    sportsFestCreateStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;
        state.byId = {
          ...fetchableDefault,
          id: null,
          sportsFest: createNewSportsFestRequest(),
          loadedData: null,
        };
        state.modified = false;
        state.validation = {};
        state.validationContests = [];
        state.validationCombinedTeams = [];
        state.ui = null;
      }
    },
    sportsFestCreateClearActionsState: state => {
      state.byId.isFetching = false;
      state.byId.isFetched = false;
      state.byId.isFailed = false;
      
      state.publish = fetchableDefault;
      state.save = fetchableDefault;
      state.validation = {};
      state.validationStepper = null;
      state.validationContests = [];
      state.validationCombinedTeams = [];
      state.ui = null;
      state.dialogs = {
        close: null,
      };
    },
    sportsFestCreateSetModified: (state, { payload }) => {
      state.modified = payload;
    },
    sportsFestCreateSetStatistics: (state, { payload }) => {
      state.statistics = payload;
    },
    sportsFestCreateSetAttribute: (state, { payload }) => {
      const { name, value } = payload;

      if (state.byId.sportsFest) {
        (state.byId.sportsFest[name] as keyof SportsFestRequest) = value;
      }
    },
    sportsFestCreateSetContestAttribute: (state, { payload }) => {
      const { index, name, value } = payload;

      if (state.byId.sportsFest?.contests?.[index]) {
        (state.byId.sportsFest.contests[index][name] as keyof SportsFestContestRequest) = value;
      }
    },
    sportsFestCreateSetCombinedTeamAttribute: (state, { payload }) => {
      const { index, name, value } = payload;

      if (state.byId.sportsFest?.combinedTeams?.[index]) {
        (state.byId.sportsFest.combinedTeams[index][name] as keyof SportsFestCombinedTeamRequest) = value;
      }
    },
    sportsFestCreateAddCombinedTeam: state => {
      if (!state.byId.sportsFest.combinedTeams) {
        state.byId.sportsFest.combinedTeams = [];
      }

      state.byId.sportsFest.combinedTeams.push({
        id: uuidv4(),
        name: null,
        road: null,
        responsibleUser: null,
      });
    },
    sportsFestCreateRemoveCombinedTeam: (state, { payload }) => {
      const currentCombinedTeams = [...(state.byId.sportsFest.combinedTeams ?? [])];
      if (currentCombinedTeams.length) {
        const existedIndex = currentCombinedTeams.findIndex(c => c.id === payload.id);
        if (existedIndex > -1) {
          currentCombinedTeams.splice(existedIndex, 1);
          state.byId.sportsFest.combinedTeams = currentCombinedTeams;
        }
      }
    },
    sportsFestCreateClearCombinedTeamUser: (state, { payload }) => {
      const currentCombinedTeams = [...(state.byId.sportsFest.combinedTeams ?? [])];
      if (currentCombinedTeams.length) {
        const existed = currentCombinedTeams.find(c => c.id === payload.id);
        if (existed) {
          existed.responsibleUser = null;
        }
      }
    },
    sportsFestCreateSetUiState: (state, { payload }) => {
      const { name, value } = payload;
      if (!state.ui) {
        state.ui = {
          steps: [],
          options: [],
          fields: {
            visible: [],
            disabled: [],
          },
        };
      }
      state.ui[name] = value;
    },
    sportsFestCreateClearAllValidations: state => {
      state.validation = {};
      state.validationContests = [];
      state.validationCombinedTeams = [];
    },
    sportsFestCreateClearAttributeValidation: (state, { payload }) => {
      delete state.validation?.[payload];
    },
    sportsFestCreateSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
    sportsFestCreateSetAttributeValidation: (state, { payload }) => {
      const { name, results } = payload;
      if (!results) {
        delete state.validation?.[name];
      } else {
        state.validation[name] = results;
      }
    },
    sportsFestCreateSetValidation: (state, { payload }) => {
      state.validation = payload ?? {};
    },
    sportsFestCreateSetCombinedTeamsValidation: (state, { payload }) => {
      state.validationCombinedTeams = payload ?? [];
    },
    sportsFestCreateClearCombinedTeamAttributeValidation: (state, { payload }) => {
      const { combinedTeam, name } = payload;
      const index = state.byId.sportsFest?.combinedTeams?.findIndex(ct => ct.id === combinedTeam.id) ?? -1;
      if (index >= 0) {
        const itemValidation = state.validationCombinedTeams?.[index];
        if (itemValidation) {
          delete itemValidation[name];
        }
      }
    },
    sportsFestCreateClearCombinedTeamValidation: (state, { payload }) => {
      const combinedTeams = state.byId.sportsFest?.combinedTeams;
      const index = combinedTeams?.findIndex(ct => ct.id === payload.id) ?? -1;
      if (index >= 0) {
        const validationCombinedTeams = state.validationCombinedTeams;
        validationCombinedTeams.splice(index, 1);
      }
    },
    sportsFestCreateSetContestsValidation: (state, { payload }) => {
      state.validationContests = payload ?? [];
    },
    sportsFestCreateClearContestAttributeValidation: (state, { payload }) => {
      const { contest, name } = payload;
      const index = state.byId.sportsFest?.contests?.findIndex(c => c.id === contest.id) ?? -1;
      if (index >= 0) {
        const itemValidation = state.validationContests?.[index];
        if (itemValidation) {
          delete itemValidation[name];
        }
      }
    },
    sportsFestCreateClearContestValidation: (state, { payload }) => {
      const index = state.byId.sportsFest?.contests?.findIndex(c => c.id === payload.id) ?? -1;
      if (index >= 0) {
        state.validationContests.splice(index, 1);
      }
    },
    sportsFestCreateSetValidationStepper: (state, { payload }) => {
      state.validationStepper = payload ?? null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sportsFestCreateByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
      })
      .addCase(sportsFestCreateByIdFetch.fulfilled, (state, { meta, payload }) => {
        const { level, userType, admin } = meta.arg;
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;
        state.byId.sportsFest = payload
          ? convertSportsFestToSportsFestRequest(payload)
          : {
              ...createNewSportsFestRequest(),
              level,
              userType,
              admin,
            };
        state.byId.loadedData = payload;
      })
      .addCase(sportsFestCreateByIdFetch.rejected, state => {
        state.byId.isFetching = false;
        state.byId.isFetched = false;
        state.byId.isFailed = true;
      })
      .addCase(sportsFestCreateSave.pending, state => {
        state.save = fetchableFetching;
      })
      .addCase(sportsFestCreateSave.fulfilled, (state, { payload }) => {
        state.save = fetchableFetched;

        state.byId.sportsFest = convertSportsFestToSportsFestRequest(payload);
        state.byId.loadedData = payload;
      })
      .addCase(sportsFestCreateSave.rejected, state => {
        state.save = fetchableFailed;
      })
      .addMatcher(isAnyOf(sportsFestCreatePublish.pending, sportsFestCreatePublishAnnounce.pending), state => {
        state.publish = fetchableFetching;
      })
      .addMatcher(
        isAnyOf(sportsFestCreatePublish.fulfilled, sportsFestCreatePublishAnnounce.fulfilled),
        (state, { payload }) => {
          state.publish = fetchableFetched;

          state.byId.sportsFest = convertSportsFestToSportsFestRequest(payload);
          state.byId.loadedData = payload;
        }
      )
      .addMatcher(isAnyOf(sportsFestCreatePublish.rejected, sportsFestCreatePublishAnnounce.rejected), state => {
        state.publish = fetchableFailed;
      });
  },
});

export const {
  sportsFestCreateSetAttribute,
  sportsFestCreateSetModified,
  sportsFestCreateClearAllValidations,
  sportsFestCreateClearAttributeValidation,
  sportsFestCreateSetAttributeValidation,
  sportsFestCreateSetValidation,
  sportsFestCreateClearCombinedTeamAttributeValidation,
  sportsFestCreateClearContestAttributeValidation,
  sportsFestCreateClearCombinedTeamValidation,
  sportsFestCreateSetCombinedTeamsValidation,
  sportsFestCreateSetCombinedTeamAttribute,
  sportsFestCreateAddCombinedTeam,
  sportsFestCreateRemoveCombinedTeam,
  sportsFestCreateClearCombinedTeamUser,
  sportsFestCreateSetContestsValidation,
  sportsFestCreateClearContestValidation,
  sportsFestCreateSetContestAttribute,
  sportsFestCreateStartSession,
  sportsFestCreateClearActionsState,
  sportsFestCreateSetUiState,
  sportsFestCreateSetDialogState,
  sportsFestCreateSetStatistics,
  sportsFestCreateSetValidationStepper,
} = slice.actions;

export default slice.reducer;
