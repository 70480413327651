import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { Banner, BannerPlace, BannerRequest, EBannerPartition, ETargetType, Target } from '@/domain';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { bannerServices } from '../../services';
import { createBannerRequestFromBanner, createEmptyBannerRequest } from '../../utils';

export const bannerEditSave = createAsyncThunk<
  Banner,
  { request: BannerRequest; source: Nullable<Banner> },
  AppThunkAPIConfig
>('banner/edit/save', async ({ request, source }, { rejectWithValue }) => {
  try {
    if (source) {
      const id = source.id;
      return await bannerServices.common.update({ id, request });
    } else {
      return await bannerServices.common.create({ request });
    }
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bannerEditSaveAndPublish = createAsyncThunk<
  Banner,
  { request: BannerRequest; source: Nullable<Banner> },
  AppThunkAPIConfig
>('banner/edit/saveAndPublish', async ({ request, source }, { rejectWithValue }) => {
  try {
    if (source) {
      const id = source.id;
      await bannerServices.common.update({ id, request });
      return await bannerServices.common.publish({ id });
    } else {
      const createdBanner = await bannerServices.common.create({ request });
      return await bannerServices.common.publish({ id: createdBanner.id });
    }
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bannerEditSaveAndResume = createAsyncThunk<
  Banner,
  { request: BannerRequest; source: Nullable<Banner> },
  AppThunkAPIConfig
>('banner/edit/saveAndResume', async ({ request, source }, { rejectWithValue }) => {
  try {
    if (source) {
      const id = source.id;
      await bannerServices.common.update({ id, request });
      return bannerServices.common.resumed({ id });
    } else {
      const createdBanner = await bannerServices.common.create({ request });
      return await bannerServices.common.resumed({ id: createdBanner.id });
    }
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface BannerEditState {
  readonly data: Fetchable & {
    readonly banner: BannerRequest;
    readonly modified: boolean;
  };
  readonly save: Fetchable & {
    readonly data: Nullable<Banner>;
  };
  readonly publish: Fetchable & {
    readonly data: Nullable<Banner>;
  };
  readonly resume: Fetchable & {
    readonly data: Nullable<Banner>;
  };
}

type Reducer<T = undefined> = CaseReducer<BannerEditState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<BannerEditState> {
  bannerEditStartSession: Reducer<{ place: BannerPlace; source: Nullable<Banner>; partition: EBannerPartition }>;
  bannerEditStateReset: Reducer;
  bannerEditSetAttribute: Reducer<{ name: keyof BannerRequest; value: any }>;
  bannerEditSetTargetAttribute: Reducer<{ name: keyof Target; value: any }>;
}

const slice = createSlice<BannerEditState, Reducers, 'create'>({
  name: 'create',
  initialState: {
    data: {
      ...fetchableDefault,
      banner: createEmptyBannerRequest(EBannerPartition.TradeOffers),
      modified: false,
    },
    save: {
      ...fetchableDefault,
      data: null,
    },
    publish: {
      ...fetchableDefault,
      data: null,
    },
    resume: {
      ...fetchableDefault,
      data: null,
    },
  },
  reducers: {
    bannerEditStartSession: (state, { payload }) => {
      const { place, source, partition } = payload;

      let bannerRequest: BannerRequest;
      if (source) {
        bannerRequest = createBannerRequestFromBanner(source, place);
      } else {
        bannerRequest = createEmptyBannerRequest(partition);
      }

      state.data = {
        ...fetchableDefault,
        banner: {
          ...bannerRequest,
          placeCodes: [place.code],
        },
        modified: false,
      };

      state.save = {
        ...fetchableDefault,
        data: null,
      };
      state.publish = {
        ...fetchableDefault,
        data: null,
      };
      state.resume = {
        ...fetchableDefault,
        data: null,
      };
    },
    bannerEditStateReset: state => {
      state.data = {
        ...fetchableDefault,
        banner: createEmptyBannerRequest(EBannerPartition.TradeOffers),
        modified: false,
      };
      state.save = {
        ...fetchableDefault,
        data: null,
      };
      state.publish = {
        ...fetchableDefault,
        data: null,
      };
      state.resume = {
        ...fetchableDefault,
        data: null,
      };
    },
    bannerEditSetAttribute: (state, { payload }) => {
      const { name, value } = payload;
      (state.data.banner[name] as keyof BannerRequest) = value;

      state.data.modified = true;
    },
    bannerEditSetTargetAttribute: (state, { payload }) => {
      const { name, value } = payload;

      if (name === 'targetType') {
        state.data.banner.target[name] = value;
        switch (value) {
          case ETargetType.Geo:
            state.data.banner.target.targetGender = null;
            state.data.banner.target.targetOrgUnits = null;
            state.data.banner.target.targetRoads = null;
            state.data.banner.target.targetHavingChildFamilyMemberOnly = null;
            state.data.banner.target.targetTradeUnionMembersOnly = null;
            break;
          case ETargetType.Corp:
            state.data.banner.target.targetLocalities = null;
            state.data.banner.target.targetExternalUsers = null;
            state.data.banner.target.targetClientOrgs = null;
            break;
        }
      } else {
        state.data.banner.target[name] = value;
      }

      state.data.modified = true;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bannerEditSave.pending, state => {
        state.save.isFetching = true;
        state.save.isFetched = false;
        state.save.isFailed = false;
        state.save.data = null;
      })
      .addCase(bannerEditSave.fulfilled, (state, { payload }) => {
        state.save.isFetching = false;
        state.save.isFetched = true;
        state.save.isFailed = false;
        state.save.data = payload;
        state.data.modified = false;
      })
      .addCase(bannerEditSave.rejected, state => {
        state.save.isFetching = false;
        state.save.isFetched = false;
        state.save.isFailed = true;
        state.save.data = null;
      })
      .addCase(bannerEditSaveAndPublish.pending, state => {
        state.publish.isFetching = true;
        state.publish.isFetched = false;
        state.publish.isFailed = false;
        state.publish.data = null;
      })
      .addCase(bannerEditSaveAndPublish.fulfilled, (state, { payload }) => {
        state.publish.isFetching = false;
        state.publish.isFetched = true;
        state.publish.isFailed = false;
        state.publish.data = payload;
        state.data.modified = false;
      })
      .addCase(bannerEditSaveAndPublish.rejected, state => {
        state.publish.isFetching = false;
        state.publish.isFetched = false;
        state.publish.isFailed = true;
        state.publish.data = null;
      })
      .addCase(bannerEditSaveAndResume.pending, state => {
        state.resume.isFetching = true;
        state.resume.isFetched = false;
        state.resume.isFailed = false;
        state.resume.data = null;
      })
      .addCase(bannerEditSaveAndResume.fulfilled, (state, { payload }) => {
        state.resume.isFetching = false;
        state.resume.isFetched = true;
        state.resume.isFailed = false;
        state.resume.data = payload;
        state.data.modified = false;
      })
      .addCase(bannerEditSaveAndResume.rejected, state => {
        state.resume.isFetching = false;
        state.resume.isFetched = false;
        state.resume.isFailed = true;
        state.resume.data = null;
      });
  },
});

export const { bannerEditSetAttribute, bannerEditSetTargetAttribute, bannerEditStateReset, bannerEditStartSession } =
  slice.actions;

export default slice.reducer;
