import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { EBannerPartition } from '../../../domain/model/enums';
import ForbiddenComponent from '../../components/common/forbidden';
import BannerCreateContainer from '../../features/banner/edit/container';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const BannerCreateScreen = () => {
  const dispatch = useDispatch();
  const { banners } = useCurrentUser().accessMatrix;

  const { partition } = useParams<{ partition: EBannerPartition }>();

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  if (!banners[partition].create) {
    return <ForbiddenComponent />;
  }

  return (
    <BannerCreateContainer
      partition={partition}
      source={null}
    />
  );
};

export default BannerCreateScreen;
