import ErrorHandler from '@/data/network/errorHandler';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { Pageable } from '@/domain/model';
import { SearchQueryStat } from '@/domain/model/analytics';
import { paginationSizeVariant } from '@/domain/model/constants';
import { Nullable } from '@/domain/model/types';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { PaginationSize } from '../../../../../types';
import { analyticsServices } from '../../../services';
import { AllProps } from '../../../services/query';
import { AnalyticsQueriesTableFilterValues } from '../../filterUtils';
import { EAnalyticsQueriesTableColumn, EAnalyticsQueriesTableTab } from '../utils';

const defaultSort = `${EAnalyticsQueriesTableColumn.Count},desc`;

export interface AnalyticsQueriesListState extends Fetchable, Pageable<SearchQueryStat> {
  readonly guid: Nullable<UUID>;
  readonly tab: Nullable<EAnalyticsQueriesTableTab>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly totalCount: number;
  readonly pageCount: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
  };
  readonly filter: AnalyticsQueriesTableFilterValues;
  readonly count: Nullable<number>;
}

type Reducer<T = undefined> = CaseReducer<AnalyticsQueriesListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<AnalyticsQueriesListState> {
  analyticsQueriesDataReset: Reducer;
  analyticsQueriesStartSession: Reducer<{ guid: UUID }>;
  analyticsQueriesSetTab: Reducer<{ tab: EAnalyticsQueriesTableTab }>;
  analyticsQueriesSetFilter: Reducer<AnalyticsQueriesTableFilterValues>;
  analyticsQueriesSortReset: Reducer;
  analyticsQueriesSetSort: Reducer<{ sort: string }>;
  analyticsQueriesNeedRefreshWatcherReset: Reducer;
  analyticsQueriesNeedRefreshWatcherIncrement: Reducer;
  analyticsQueriesSetPage: Reducer<{ pageNumber: number }>;
  analyticsQueriesSetPageSize: Reducer<{ pageSize: PaginationSize }>;
}

export type AnalyticsQueriesFetchProps = Omit<AllProps, 'signal'> & {
  readonly tab: EAnalyticsQueriesTableTab;
};

export const analyticsQueriesFetch = createAsyncThunk<
  { stats: Pageable<SearchQueryStat>; count: number },
  AnalyticsQueriesFetchProps,
  AppThunkAPIConfig
>('analytics/queries/list/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { tab, ...fetchProps } = props;
    const stats =
      tab === EAnalyticsQueriesTableTab.Queries
        ? await analyticsServices.queries.allByKeywords({
            ...fetchProps,
            signal,
          })
        : await analyticsServices.queries.allByGeo({ ...fetchProps, signal });
    const count = await analyticsServices.queries.count({ ...fetchProps, signal });
    return { stats, count };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

const slice = createSlice<AnalyticsQueriesListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    guid: null,
    tab: null,
    needRefreshWatcher: 0,
    pageNumber: 1,
    totalCount: 0,
    pageCount: 0,
    data: [],
    filter: {},
    count: null,
    ...fetchableDefault,
    search: {
      sort: defaultSort,
      pageSize: paginationSizeVariant[1],
    },
  },
  reducers: {
    analyticsQueriesStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;

        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = false;

        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;
        state.needRefreshWatcher = 0;
        state.count = null;

        state.search = {
          sort: defaultSort,
          pageSize: 10,
        };
        state.tab = null;
        state.filter = {};
      }
    },
    analyticsQueriesSetTab: (state, { payload }) => {
      const { tab } = payload;
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    analyticsQueriesSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    analyticsQueriesDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
      state.count = null;
    },
    analyticsQueriesSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    analyticsQueriesSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    analyticsQueriesNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
    analyticsQueriesNeedRefreshWatcherIncrement: state => {
      state.needRefreshWatcher++;
    },
    analyticsQueriesSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    analyticsQueriesSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(analyticsQueriesFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;

        state.data = [];
        state.count = null;
      })
      .addCase(analyticsQueriesFetch.fulfilled, (state, { payload }) => {
        const { stats, count } = payload;
        const { data, totalCount, pageCount } = stats;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;

        state.count = count;
      })
      .addCase(analyticsQueriesFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;

          state.data = [];
          state.count = null;
        }
      });
  },
});
export const {
  analyticsQueriesStartSession,
  analyticsQueriesSetTab,
  analyticsQueriesSetFilter,
  analyticsQueriesDataReset,
  analyticsQueriesSetPage,
  analyticsQueriesNeedRefreshWatcherReset,
  analyticsQueriesSortReset,
  analyticsQueriesSetPageSize,
  analyticsQueriesSetSort,
  analyticsQueriesNeedRefreshWatcherIncrement,
} = slice.actions;

export default slice.reducer;
