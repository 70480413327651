import { combineReducers } from '@reduxjs/toolkit';
import actions, { BookingOfferActionsState } from './actions/store/slice';
import create, { BookingOfferCreateState } from './create/store/slice';
import details, { BookingOfferDetailsState } from './details/store/slice';
import serviceManage, { BookingOfferServiceManageState } from './serviceManage/store/slice';
import list, { BookingOfferListState } from './table/store/slice';
import search, { BookingOfferSearchState } from './search/store/slice';

interface BookingOfferState {
  actions: BookingOfferActionsState;
  create: BookingOfferCreateState;
  details: BookingOfferDetailsState;
  list: BookingOfferListState;
  serviceManage: BookingOfferServiceManageState;
  search: BookingOfferSearchState;
}

export default combineReducers<BookingOfferState>({
  actions,
  create,
  details,
  list,
  serviceManage,
  search,
});
