import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getPartnerLogo } from '../../../partner/utils';
import { useContextHandlers } from '../hooks/useContextHandlers';
import TradeOfferTableNotifier from '../notifier';
import { tradeOffersNotifierInfoGet } from '../store/selectors';

export const TradeOffersTableNotifierAdapter = () => {
  const notifierInfo = useSelector(tradeOffersNotifierInfoGet);
  const partnerLogo = notifierInfo?.tradeOffer ? getPartnerLogo(notifierInfo.tradeOffer.partner) : null;

  const handlers = useContextHandlers();

  return useMemo(
    () =>
      notifierInfo ? (
        <TradeOfferTableNotifier
          tradeOffer={notifierInfo.tradeOffer}
          partnerLogo={partnerLogo}
          type={notifierInfo.type}
          onRead={handlers.onNotificationRead}
        />
      ) : null,
    [notifierInfo?.tradeOffer, partnerLogo, notifierInfo?.type, handlers.onNotificationRead]
  );
};
