import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { EProductOrderTableTab } from '../../types';
import {
  productOrdersFilterSelector,
  productOrdersNeedRefreshWatcherSelector,
  productOrdersPageNumberSelector,
  productOrdersSearchSelector,
} from '../store/selectors';
import {
  productOrdersCountsFetch,
  productOrdersFetch,
  ProductOrdersFetchProps,
  productOrdersNeedRefreshWatcherReset,
} from '../store/slice';
import { getProductOrderStatusesByTableTab } from '../utils';
import { useContextConfig } from './useContextConfig';

type useProductOrderTableSearchProps = {
  readonly tab: EProductOrderTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly customerId: Nullable<UUID>;
};

export const useProductOrderTableSearch = (props: useProductOrderTableSearchProps) => {
  const { tab, partnerId, customerId } = props;
  const dispatch = useDispatch();

  const { tabsForCounts } = useContextConfig();

  const [searchPromise, setSearchPromise] = useState<Nullable<any[]>>(null);

  const needRefreshWatcher = useSelector(productOrdersNeedRefreshWatcherSelector);
  const pageNumber = useSelector(productOrdersPageNumberSelector);
  const search = useSelector(productOrdersSearchSelector);
  const filter = useSelector(productOrdersFilterSelector);

  const currentStatuses = getProductOrderStatusesByTableTab(tab);

  const queryParams = useMemo<ProductOrdersFetchProps>(
    () => ({
      search: {
        ...search,
        statuses: currentStatuses,
        partnerId,
        customerId,
      },
      pageNumber,
      filter,
    }),
    [search, currentStatuses, partnerId, customerId, pageNumber, filter]
  );

  const onSearch = useCallback(() => {
    setSearchPromise([
      dispatch(productOrdersFetch(queryParams)),
      dispatch(
        productOrdersCountsFetch({
          ...queryParams,
          tabs: tabsForCounts,
        })
      ),
    ]);
  }, [queryParams, tabsForCounts]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromise?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromise?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  useEffect(() => {
    return () => {
      dispatch(productOrdersNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};
