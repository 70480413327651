import { Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ContentTarget } from '../../../../../../domain/model';
import { EMultiSelectorValueType, EUserGender } from '../../../../../../domain/model/enums';
import SportOptionMultipleTypesSelector from '../../selectors/sportOption';
import { SportOptionMultipleSelectType } from '../../selectors/sportOption/types';
import { ContentTargetEditProps } from '../../types';

const attribute: keyof ContentTarget = 'gender';

interface ContentTargetEditGenderProps extends ContentTargetEditProps {
  readonly label?: string | false;
}

const ContentTargetEditGender = (props: ContentTargetEditGenderProps) => {
  const {
    target,
    label = 'Выберите категории по условиям которых предложение будет доступно',
    nsi: { genderTypes },
    validation,
    onChangeAttribute,
  } = props;

  const targetGenders = target[attribute] ?? { select: EMultiSelectorValueType.In, in: null };

  // значение gender может быть
  // 1) null
  // 2) { select: EMultiSelectorValueType.All, in: null }
  // 3) { select: EMultiSelectorValueType.In, in: newValue }, где newValue.length > 0
  const onChange = (newValue: EUserGender[]) => {
    if (newValue.length === genderTypes.length) {
      onChangeAttribute(attribute, { select: EMultiSelectorValueType.All, in: null });
    } else {
      if (newValue.length) {
        onChangeAttribute(attribute, { select: EMultiSelectorValueType.In, in: newValue.length > 0 ? newValue : null });
      } else {
        onChangeAttribute(attribute, null);
      }
    }
  };

  return (
    <>
      {label && (
        <Typography
          variant='body2'
          gutterBottom
        >
          Выберите категории по условиям которых предложение будет доступно
        </Typography>
      )}
      <SportOptionMultipleTypesSelector<EUserGender>
        label='Пол'
        selectAllLabel='Для всех'
        color='primary'
        select={targetGenders.select === EMultiSelectorValueType.All ? SportOptionMultipleSelectType.All : null}
        values={targetGenders.in ?? []}
        allValues={genderTypes}
        limitTags={2}
        helperText={validation?.[attribute]?.message}
        error={!!validation?.[attribute]?.hasError}
        popupIcon={<ExpandMoreIcon fontSize='small' />}
        onChange={onChange}
      />
    </>
  );
};

export default ContentTargetEditGender;
