import { CorpOffer, EOfferType, TradeOffer, UUID } from '@/domain';
import { ButtonLink } from '@components/common/buttons/link';
import {
  getOfferCollectionColumns,
  offerCollectionCorpColumns,
  offerCollectionTradeColumns,
} from '@features/general/offer/components/collection/utils';
import { EOfferTableColumn } from '@features/general/offer/types';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { Grid, Typography } from '@mui/material';
import SectionTitle from '../../../../../components/common/sectionTitle';
import Splitter from '../../../../../components/common/splitter';
import { RefreshIcon } from '../../../../../icons';
import OfferCollection from '../../../../general/offer/components/collection';

import { CollectionActionsContainer } from './controls';

const cmsOfferCollectionColumns = [EOfferTableColumn.Code, EOfferTableColumn.SortIndex, EOfferTableColumn.Name];

interface CmsOfferMultipleCollectionComponentTradeProps {
  readonly offerType: EOfferType.Trade;
  readonly offers: TradeOffer[];
  readonly onChangeSortIndex?: (offer: TradeOffer, sortIndex: number) => void;
}

interface CmsOfferMultipleCollectionComponentCorpProps {
  readonly offerType: EOfferType.Corp;
  readonly offers: CorpOffer[];
  readonly onChangeSortIndex?: (offer: CorpOffer, sortIndex: number) => void;
}

type CmsOfferMultipleCollectionComponentProps = {
  readonly onShowOfferSearch?: () => void;
  readonly onClearOffers?: () => void;
  readonly onRemoveOffer?: (id: UUID) => void;
} & (CmsOfferMultipleCollectionComponentTradeProps | CmsOfferMultipleCollectionComponentCorpProps);

const CmsOfferMultipleCollectionComponent = (props: CmsOfferMultipleCollectionComponentProps) => {
  const { offers, offerType, onShowOfferSearch, onClearOffers, onRemoveOffer } = props;

  let title;
  if (offerType === EOfferType.Corp) {
    title = 'Подборка корпоративных предложений';
  } else {
    title = 'Подборка торговых предложений';
  }

  return (
    <Grid
      item
      xs={12}
    >
      <Splitter
        variant='horizontal'
        size={1}
      />
      <SectionTitle>{title}</SectionTitle>
      {offers.length > 0 && (
        <>
          {props.offerType === EOfferType.Corp && (
            <OfferCollection<CorpOffer>
              offers={props.offers}
              columns={getOfferCollectionColumns(cmsOfferCollectionColumns, offerCollectionCorpColumns)}
              onRemove={onRemoveOffer}
              onChangeSortIndex={props.onChangeSortIndex}
            />
          )}
          {props.offerType === EOfferType.Trade && (
            <OfferCollection<TradeOffer>
              columns={getOfferCollectionColumns(cmsOfferCollectionColumns, offerCollectionTradeColumns)}
              offers={props.offers}
              onRemove={onRemoveOffer}
              onChangeSortIndex={props.onChangeSortIndex}
            />
          )}
        </>
      )}
      <CollectionActionsContainer>
        {onShowOfferSearch && (
          <ButtonLink onClick={() => onShowOfferSearch()}>
            <AddBoxOutlinedIcon />
            <Typography>Выбрать предложения</Typography>
          </ButtonLink>
        )}

        {onClearOffers && offers.length > 0 && (
          <ButtonLink onClick={() => onClearOffers()}>
            <RefreshIcon />
            <Typography>Очистить список</Typography>
          </ButtonLink>
        )}
      </CollectionActionsContainer>
    </Grid>
  );
};

export default CmsOfferMultipleCollectionComponent;
