import { UserAgreement } from '../../../../domain/model/legalAgreement';
import LegalAgreementsApi, { GetAgreementsProps, SignAgreementsProps } from '../../../../data/api/legalAgreements';

export type LegalAgreementSportService = {
  readonly getAgreements: (props: GetAgreementsProps) => Promise<UserAgreement[]>;
  readonly signAgreements: (props: SignAgreementsProps) => Promise<void>;
};

const service: LegalAgreementSportService = {
  getAgreements: async props => {
    const { data } = await LegalAgreementsApi.sport.getAgreements(props);
    return data;
  },
  signAgreements: async props => {
    const { data } = await LegalAgreementsApi.sport.signAgreements(props);
    return data;
  },
};

export default service;
