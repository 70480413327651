import StepperValidationLabelAdapter from '@components/common/stepper/adapters/validationLabel';
import { FCC } from 'react';
import { useSelector } from 'react-redux';
import { AdCampaignCreateStep } from '../../types';
import { adCampaignCreateValidationStepperSelector } from '../store/selectors';

interface AdCampaignCreateStepperLabelAdapterProps {
  readonly step: AdCampaignCreateStep;
}

export const AdCampaignCreateStepperLabelAdapter: FCC<AdCampaignCreateStepperLabelAdapterProps> = props => {
  const { step, children } = props;

  const validation = useSelector(adCampaignCreateValidationStepperSelector);

  return <StepperValidationLabelAdapter validation={validation?.[step.key]}>{children}</StepperValidationLabelAdapter>;
};
