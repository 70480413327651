import { AppDispatch } from '../data/store/store';
import { Notice } from '../domain/model';
import { ENoticeStatus } from '../domain/model/enums';
import { addNotice } from '../presentation/features/general/notifier/store/slice';

class Notifier {
  private static instance: Notifier;

  public static getInstance(): Notifier {
    if (!Notifier.instance) {
      Notifier.instance = new Notifier();
    }

    return Notifier.instance;
  }

  private constructor() {}

  dispatch: AppDispatch | null = null;

  private getDispatch(): AppDispatch {
    return this.dispatch!;
  }

  init(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  addNotice(status: ENoticeStatus, text: string, title?: string) {
    this.getDispatch()(addNotice({ status, text, title }));
  }

  addErrorNotice(text: string, title?: string) {
    this.getDispatch()(addNotice({ status: ENoticeStatus.Error, text, title }));
  }

  addSuccessNotice(text: string, title?: string) {
    this.getDispatch()(addNotice({ status: ENoticeStatus.Success, text, title }));
  }

  addNotices(notices: Notice[]) {
    let hasErrors = false;
    let hasWarnings = false;
    let hasSuccess = false;
    let message = '';
    const title = '';
    notices.forEach((notice, index) => {
      switch (notice.status) {
        case ENoticeStatus.Error:
          hasErrors = true;
          break;
        case ENoticeStatus.Warning:
          hasWarnings = true;
          break;
        case ENoticeStatus.Success:
          hasSuccess = true;
          break;
        default:
          hasSuccess = false;
          break;
      }
      message += `${notices.length > 1 && index > 0 ? '\n' : ''}${notices.length > 1 ? notice.status + ': ' : ''}${
        notice.text
      }`;
    });

    let summaryStatus = ENoticeStatus.Info;
    if (hasErrors) summaryStatus = ENoticeStatus.Error;
    else if (hasWarnings) summaryStatus = ENoticeStatus.Warning;
    if (hasSuccess) summaryStatus = ENoticeStatus.Success;

    this.addNotice(summaryStatus, message, title);
    return summaryStatus;
  }
}

export default Notifier;
