import { locale } from '../../../../../../locale';
import { MPNumberInput } from '../../../../../theme/ui-kit/input';
import { BookingOfferEditFieldServicePriceItemProps } from '../types';

const fieldName = 'price';

const BookingOfferEditFieldServicePriceItemPrice = (
  props: BookingOfferEditFieldServicePriceItemProps<typeof fieldName>
) => {
  const { value, validation, disabled, onChangeAttribute, onValidateAttribute } = props;

  return (
    <MPNumberInput
      label={`Стоимость, ${locale.currency}`}
      value={value}
      allowNegative={false}
      isNumericString
      decimalScale={0}
      disabled={disabled}
      error={validation?.hasError}
      helperText={validation?.message}
      onChange={(event, newValue) => onChangeAttribute(fieldName, newValue)}
      onBlur={() => onValidateAttribute(fieldName)}
    />
  );
};

export default BookingOfferEditFieldServicePriceItemPrice;
