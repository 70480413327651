import { useState } from 'react';
import { Address } from '../../../../../domain/model/address';
import { EOrderItemStatus } from '../../../../../domain/model/enums';
import { ProductOrder, ProductOrderItem } from '../../../../../domain/model/order';
import { Nullable } from '../../../../../domain/model/types';
import { InternalValidateResult } from '../../../../hooks/validation/utils';
import { AddressHelper } from '../../../../utils/address';
import { setField } from '../../../../utils/modifier';
import { EValidationType, ValidationResult } from '../../../../utils/validation';

export type ProductOrderValidationResult = Pick<ProductOrder, 'deliveryAddress' | 'deliveryCost'>;
export type ProductOrderItemsValidationResult = Pick<ProductOrderItem, 'status'>;

type UseValidProductOrder = {
  validateOrderDeliveryAddress(deliveryAddress: Nullable<Address>): InternalValidateResult<Address>;
  validateProductOrderItems(items: ProductOrderItem[]): InternalValidateResult<ProductOrderItemsValidationResult>;
};

export const useValidProductOrder = (): UseValidProductOrder => {
  const [resultDeliveryAddress, setResultDeliveryAddress] = useState<Nullable<ValidationResult<Address>>>(null);
  const [resultProductOrderItems, setResultProductOrderItems] =
    useState<Nullable<ValidationResult<ProductOrderItemsValidationResult>>>(null);

  const validateOrderDeliveryAddress = (deliveryAddress: Nullable<Address>): InternalValidateResult<Address> => {
    const keyValidation: (keyof Address)[] = ['hierarchy', 'postalCode'];

    let results;
    if (deliveryAddress) {
      results = Object.entries(deliveryAddress).reduce((acc, [keyArg, item]) => {
        const key = keyArg as keyof Address;

        if (keyValidation.includes(key) && !item) {
          setField(acc, key, {
            hasError: true,
            type: EValidationType.Error,
          });
        }

        if (key === 'hierarchy') {
          const isIncorrect = new AddressHelper(deliveryAddress).isEmptyStartingFromStead();

          if (isIncorrect) {
            setField(acc, key, {
              hasError: true,
              message: 'Укажите полный адрес',
              type: EValidationType.Error,
            });
          }
        }

        return acc;
      }, {});
    } else {
      results = keyValidation.reduce((acc, key) => {
        setField(acc, key, {
          hasError: true,
          type: EValidationType.Error,
        });

        return acc;
      }, {});
    }

    if (JSON.stringify(resultDeliveryAddress) !== JSON.stringify(results)) {
      setResultDeliveryAddress(results);
    }

    if (Object.keys(results).length > 0) {
      return {
        isValid: false,
        results,
      };
    } else {
      return {
        isValid: true,
        results: {},
      };
    }
  };

  const validateProductOrderItems = (
    items: ProductOrderItem[]
  ): InternalValidateResult<ProductOrderItemsValidationResult> => {
    const isValid = items.every(item => item.status != EOrderItemStatus.New);
    const validateItemsResult = {
      isValid,
      results: {
        status: {
          type: EValidationType.Error,
          hasError: isValid,
          message: 'Чтобы измененить статус заказа, сначала выберите статус для каждого товара',
        },
      },
    };
    if (JSON.stringify(resultProductOrderItems) !== JSON.stringify(validateItemsResult.results)) {
      setResultProductOrderItems(validateItemsResult.results);
    }
    return validateItemsResult;
  };

  return {
    validateOrderDeliveryAddress,
    validateProductOrderItems,
  };
};
