import { Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { UUID } from '../../../../domain/model/types';
import { MPButton } from '../../../theme/ui-kit/button';
import { getPartnerDeskEditRoute } from '../entry';
import { Wrapper } from './controls';

interface PartnerDeskRestrictionContainerProps {
  readonly partnerId: UUID;
  readonly text?: string;
  readonly description?: string;
}

const defaultText =
  'Для использования данного раздела необходимо заполнить карточку компании, которая будет отображаться на Витрине.';

const defaultDescription = '';

const PartnerDeskRestrictionContainer = ({
  partnerId,
  text = defaultText,
  description = defaultDescription,
}: PartnerDeskRestrictionContainerProps) => {
  const history = useHistory();

  const onCreate = () => {
    history.push(getPartnerDeskEditRoute({ partnerId }));
  };

  return (
    <Wrapper>
      <Grid
        container
        direction='column'
        spacing={4}
        justifyContent='space-between'
        wrap='nowrap'
      >
        <Grid
          item
          xs={12}
        >
          {text && <Typography gutterBottom>{text}</Typography>}
          {description && <Typography>{description}</Typography>}
        </Grid>
        <Grid
          item
          xs={12}
        >
          <MPButton
            onClick={onCreate}
            fullWidth={false}
          >
            Заполнить карточку компании
          </MPButton>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default PartnerDeskRestrictionContainer;
