import { ReactNode, useMemo } from 'react';
import { OfferFieldAudit } from '../../../../../../../domain/model';
import DataTableCellTextFormat from '../../../../../../components/common/table/cell/textFormat';

export interface OfferFieldHistoryTableCellCommonProps {
  readonly fieldHistory: OfferFieldAudit;
}

interface OfferFieldHistoryTableCellProps extends OfferFieldHistoryTableCellCommonProps {
  readonly value: ReactNode;
}

const OfferFieldHistoryTableCell = ({ value }: OfferFieldHistoryTableCellProps) => {
  return useMemo(
    () => (
      <DataTableCellTextFormat
        hintWhenOverflow
        maxLines={3}
      >
        {value}
      </DataTableCellTextFormat>
    ),
    [value]
  );
};

export default OfferFieldHistoryTableCell;
