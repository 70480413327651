import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import TablePagination from '../../../components/common/table/pagination';
import { useContextHandlers } from './hooks/useContextHandlers';
import {
  partnerEmployeesPageCountSelector,
  partnerEmployeesPageNumberSelector,
  partnerEmployeesSearchSelector,
  partnerEmployeesTotalCountSelector,
} from './store/selectors';

interface PartnerEmployeeTableFooterContainerProps {}

const PartnerEmployeeTableFooterContainer = forwardRef((props: PartnerEmployeeTableFooterContainerProps, ref: any) => {
  const handlers = useContextHandlers();

  const pageNumber = useSelector(partnerEmployeesPageNumberSelector);
  const search = useSelector(partnerEmployeesSearchSelector);
  const totalCount = useSelector(partnerEmployeesTotalCountSelector);
  const pageCount = useSelector(partnerEmployeesPageCountSelector);

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='сотрудников'
        onChangePageSize={handlers.onChangePageSize}
        onChangePage={handlers.onChangePage}
      />
    </div>
  );
});

export default PartnerEmployeeTableFooterContainer;
