import MasterActionsComponent from '../../../../components/common/actions/master';
import { DefaultFooterWrapper } from '../../../../components/common/wrappers/footer';
import { BookingOrderActionEditType } from '../../types';
import { useContextLifecycle } from '../hooks/useContextLifecycle';

export const BookingOrderEditFooterAdapter = () => {
  const { actions, onAction } = useContextLifecycle();
  return (
    <MasterActionsComponent<BookingOrderActionEditType>
      show={!!actions.length}
      actions={actions}
      onAction={onAction}
      wrapper={DefaultFooterWrapper}
    />
  );
};
