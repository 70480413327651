import styled from '@emotion/styled';
import { ContentLoaderWrapperProps } from './index';

export const Wrapper = styled(
  ({
    alpha,
    position,
    verticalPosition,
    horizontalPosition,
    ...others
  }: ContentLoaderWrapperProps & { children?: any }) => <div {...others} />
)`
  width: 100%;
  height: 100%;

  ${p => (!p.alpha ? '' : `background-color: ${p.theme.palette.background.defaultAlpha80}`)};

  display: flex;
  align-items: ${p => p.horizontalPosition};
  position: ${p => p.position};
  justify-content: ${p => p.verticalPosition};

  z-index: ${p => (p.position === 'fixed' ? p.theme.zIndex.drawer + 1 : 99)};
  top: 0;
  left: 0;
`;

export const SimpleWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  & > * {
    font-size: 0.5rem;
    color: ${p => p.theme.palette.primary.alpha40};
  }
`;
