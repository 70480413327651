import { SportOptionTyped } from '../../../../../../domain/model';
import { EPartnerOwnershipType, ETaxationSystemType } from '../../../../../../domain/model/enums';
import {
  PartnerCompanyDataDraft,
  PartnerData,
  PartnerDataDraft,
  PartnerIndividualDataDraft,
} from '../../../../../../domain/model/partner';
import { Nullable } from '../../../../../../domain/model/types';
import { OnChangeObjectAttribute } from '../../../../../types';
import { ValidationResult } from '../../../../../utils/validation';
import { PartnerDetailsCompany, PartnerDetailsIndividual } from '../../../../general/partner/validation';
import { Content } from '../../controls';
import PartnerApplicationEditCompanyDetails from './company';
import PartnerApplicationEditIndividualDetails from './individual';

interface PartnerEditStepDetailsProps {
  readonly partner: PartnerData;
  readonly taxationSystemTypes: SportOptionTyped<ETaxationSystemType>[];
  readonly isValid: boolean;
  readonly validationCompany: Nullable<ValidationResult<PartnerDetailsCompany>>;
  readonly validationIndividual: Nullable<ValidationResult<PartnerDetailsIndividual>>;
  readonly onChangeAttribute: OnChangeObjectAttribute<PartnerDataDraft>;
  readonly onChangeCompanyAttribute: OnChangeObjectAttribute<PartnerCompanyDataDraft>;
  readonly onChangeIndividualAttribute: OnChangeObjectAttribute<PartnerIndividualDataDraft>;
}

const PartnerEditStepDetails = (props: PartnerEditStepDetailsProps) => {
  const {
    partner,
    taxationSystemTypes,
    isValid,
    validationCompany,
    validationIndividual,
    onChangeAttribute,
    onChangeCompanyAttribute,
    onChangeIndividualAttribute,
  } = props;

  const { bic, checkingAccount, taxSystem } = partner;

  switch (partner.regInfo.orgType) {
    case EPartnerOwnershipType.IndividualEntrepreneur:
      return (
        <Content>
          <PartnerApplicationEditIndividualDetails
            bic={bic}
            checkingAccount={checkingAccount}
            ogrnip={partner.regInfo.ogrnip}
            taxSystem={taxSystem}
            taxationSystemTypes={taxationSystemTypes}
            validation={validationIndividual}
            isValid={isValid}
            onChangeAttribute={onChangeAttribute}
            onChangeIndividualAttribute={onChangeIndividualAttribute}
          />
        </Content>
      );
    case EPartnerOwnershipType.StockCompany:
    case EPartnerOwnershipType.PublicJoinStockCompany:
    case EPartnerOwnershipType.PK:
    case EPartnerOwnershipType.UP:
    case EPartnerOwnershipType.FGBNU:
    case EPartnerOwnershipType.NonProfitOrganization:
    case EPartnerOwnershipType.ClosedJoinStockCompany:
    case EPartnerOwnershipType.JoinStockCompany:
    case EPartnerOwnershipType.NAO:
    case EPartnerOwnershipType.AutonomousNonProfitOrganization:
    case EPartnerOwnershipType.LimitedLiabilityCompany: {
      return (
        <Content>
          <PartnerApplicationEditCompanyDetails
            bic={bic}
            checkingAccount={checkingAccount}
            kpp={partner.regInfo.kpp}
            ogrn={partner.regInfo.ogrn}
            taxSystem={taxSystem}
            taxationSystemTypes={taxationSystemTypes}
            validation={validationCompany}
            isValid={isValid}
            onChangeAttribute={onChangeAttribute}
            onChangeCompanyAttribute={onChangeCompanyAttribute}
          />
        </Content>
      );
    }
    default: {
      return <Content></Content>;
    }
  }
};

export default PartnerEditStepDetails;
