import { AdCampaignShort } from '@/domain';
import { ReactNode, useMemo } from 'react';
import DataTableCellTextFormat from '../../../../components/common/table/cell/textFormat';

export interface AdCampaignTableCellCommonProps {
  readonly adCampaign: AdCampaignShort;
  readonly isDisabled?: boolean;
  readonly isTextAccent?: boolean;
}

interface AdCampaignTableCellProps extends AdCampaignTableCellCommonProps {
  readonly value: ReactNode;
}

export const AdCampaignTableCell = ({ value, isDisabled, isTextAccent }: AdCampaignTableCellProps) => {
  return useMemo(
    () => (
      <DataTableCellTextFormat
        disabled={isDisabled}
        bold={isTextAccent}
      >
        {value}
      </DataTableCellTextFormat>
    ),
    [isDisabled, isTextAccent, value]
  );
};

export * from './budget';
export * from './name';
export * from './offerType';
export * from './offerCount';
export * from './partner';
export * from './period';
export * from './showCount';
export * from './viewCount';
export * from './type';
