import { ClientOrg } from '@/domain';
import FileInfo from '@components/common/files/info';
import AppImage from '@components/common/images/common';
import { Field, FieldsGroup } from '@components/fields';
import { Grid, Link, Typography } from '@mui/material';
import ClientOrgColorBrandWrapper from '../../components/colorWrapper';
import { FaviconWrapper } from '../controls';

interface ClientOrgDetailsContentProps {
  readonly clientOrg: ClientOrg;
}

const ClientOrgDetailsContent = (props: ClientOrgDetailsContentProps) => {
  const { clientOrg } = props;

  return (
    <Grid
      container
      spacing={3}
      direction='column'
    >
      <Grid item>
        <Typography variant={'h3'}>Основные данные</Typography>
      </Grid>

      {clientOrg.logo && (
        <Grid item>
          <FieldsGroup label='Логотип'>
            <FileInfo id={clientOrg.logo.path} />
          </FieldsGroup>
        </Grid>
      )}

      {clientOrg.favicon && (
        <Grid item>
          <FieldsGroup label='Логотип'>
            <FileInfo
              id={clientOrg.favicon.path}
              icon={
                <FaviconWrapper>
                  <AppImage src={clientOrg.favicon.path} />
                </FaviconWrapper>
              }
            />
          </FieldsGroup>
        </Grid>
      )}

      {clientOrg.name && (
        <Grid item>
          <FieldsGroup label='Название компании'>
            <Field label='Название компании'>{clientOrg.name}</Field>
          </FieldsGroup>
        </Grid>
      )}

      {clientOrg.code && (
        <Grid item>
          <FieldsGroup label='Код компании в системе Витрина'>
            <Field label='Код компании'>{clientOrg.code}</Field>
          </FieldsGroup>
        </Grid>
      )}

      {(clientOrg.colorPrimary || clientOrg.colorBrand) && (
        <Grid item>
          <FieldsGroup label='Цвета'>
            {clientOrg.colorBrand && (
              <ClientOrgColorBrandWrapper
                alignItems='flex-end'
                color={clientOrg.colorBrand}
              >
                <Field label='Первый цвет'>{clientOrg.colorBrand}</Field>
              </ClientOrgColorBrandWrapper>
            )}
            {clientOrg.colorPrimary && (
              <ClientOrgColorBrandWrapper
                alignItems='flex-end'
                color={clientOrg.colorPrimary}
              >
                <Field label='Второй цвет'>{clientOrg.colorPrimary}</Field>
              </ClientOrgColorBrandWrapper>
            )}
          </FieldsGroup>
        </Grid>
      )}

      {clientOrg.domain && (
        <Grid item>
          <FieldsGroup label='Доменное имя'>
            <Field label='Url-адрес домена'>
              <Link
                color='textPrimary'
                href={clientOrg.domain}
                target='_blank'
              >
                {clientOrg.domain}
              </Link>
            </Field>
          </FieldsGroup>
        </Grid>
      )}
    </Grid>
  );
};

export default ClientOrgDetailsContent;
