import { uniqBy } from 'lodash';
import {
  Address,
  AddressObject,
  AddressObjectValue,
  AnyAddress,
  AppAddress,
  PrivilegeAddress,
} from '../../domain/model/address';
import { EAddressLevel, EAddressOption } from '../../domain/model/enums';
import { Nullable } from '../../domain/model/types';

export const parseLocationCoordinate = (coordinate: any): number => {
  try {
    return parseFloat(coordinate);
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const createEmptyDraftAddress = (): Address => ({
  id: '',
  name: '',
  shortName: '',
  level: {
    id: EAddressLevel.City,
    name: '',
  },
  hierarchy: [],
  postalCode: null,
  position: null,
  external: true,
  manual: false,
});

export const createEmptyAddress = (): null => null;

const officeToString = (office: Nullable<string>) => (office ? `офис ${office}` : null);

const filteredAddressToString = (...address: Nullable<string>[]) => address.filter(item => !!item).join(', ');

export const privilegeAddressToString = (address: PrivilegeAddress) => {
  const { postIndex, city, street, house, region, building, office } = address;

  const addressOffice = officeToString(office);

  return filteredAddressToString(postIndex, region, city, street, house, building, addressOffice);
};

export const privilegeAddressToStringWithoutRegion = (address: PrivilegeAddress) => {
  const { fiasCode, postIndex, city, street, house, building, office, addressString } = address;

  const addressOffice = officeToString(office);

  return fiasCode ? filteredAddressToString(postIndex, city, street, house, building, addressOffice) : addressString;
};

export const addressToString = (address: AppAddress, options?: { ignoreZipCode: boolean }) => {
  const { zipCode, city, street, house, region, building, office } = address;

  const addressOffice = officeToString(office);

  return filteredAddressToString(
    options?.ignoreZipCode ? null : zipCode,
    region?.name ?? '',
    city?.name ?? '',
    street?.name ?? '',
    house?.name ?? '',
    building,
    addressOffice
  );
};

export const addressToStringWithoutRegion = (address: AppAddress) => {
  const { zipCode, city, street, house, building, office } = address;

  const addressOffice = officeToString(office);

  return filteredAddressToString(zipCode, city!.name, street!.name, house!.name, building, addressOffice);
};

export class AddressHelper {
  private address: AnyAddress;
  private separator: string | undefined;

  constructor(source: AnyAddress, separator?: string) {
    this.address = JSON.parse(JSON.stringify(source));
    this.separator = separator;

    this.optimizeLocality();
  }

  // оптимизация населенного пункта, если нет нас пункта и города то добавляется синтетический город на основе административного района или региона
  // так как в ГАР в случае городов регионального/административного значения фигурирует только район/регион
  // примеры: Орехово-Зуево, Москва, Санкт-Петербург
  private optimizeLocality() {
    const hasLocalityObjects =
      this.address.hierarchy?.some(
        item => item.level.id >= EAddressLevel.City && item.level.id <= EAddressLevel.Settlement
      ) ?? false;
    const administrativeRegionObject = this.address.hierarchy?.find(
      item => item.level.id == EAddressLevel.AdministrativeRegion
    );
    const regionObject = this.address.hierarchy?.find(item => item.level.id == EAddressLevel.Region);
    if (!hasLocalityObjects && administrativeRegionObject != null) {
      const index = this.address.hierarchy?.findIndex(item => item.level.id == EAddressLevel.AdministrativeRegion)!;
      this.address.hierarchy?.splice(index + 1, 0, {
        ...administrativeRegionObject,
        level: { id: EAddressLevel.City, name: 'Город' },
      });
    } else if (!hasLocalityObjects && regionObject != null) {
      const index = this.address.hierarchy?.findIndex(item => item.level.id == EAddressLevel.Region)!;
      this.address.hierarchy?.splice(index + 1, 0, {
        ...regionObject,
        level: { id: EAddressLevel.City, name: 'Город' },
      });
    }
  }

  // получение иерархии по уровню [С, ПО]
  private getHierarchyObjects(fromLevel: EAddressLevel, toLevel?: EAddressLevel): AddressObject[] {
    //получаем объекты иерархии адреса
    let hierarchyObjects =
      this.address.hierarchy
        ?.filter(item => item.level.id >= fromLevel && (toLevel === undefined || item.level.id <= toLevel))
        ?.filter(item => !!item) ?? [];
    //сортируем по уровню для правильного порядка
    hierarchyObjects = hierarchyObjects.sort((o1, o2) => o1.level.id - o2.level.id);
    //убираем дублирующие id, это может быть к примеру для городов федерального значения
    return uniqBy(hierarchyObjects, 'id');
  }

  // получение полного адреса по иерархии
  getFullPath(props?: { options?: EAddressOption[] }) {
    return AddressStringBuilder.full(this.address)
      .withSeparator(this.separator)
      .withoutMunicipality()
      .withOptions(...(props?.options ?? []))
      .toString();
  }

  // получение полного адреса по иерархии с почтовым индексом
  getFullPathWithPostalCode() {
    return this.getFullPath({ options: [EAddressOption.PostalCode] });
  }

  // получение наименования населенного пункта начиная с региона
  getLocalityFullPath(): Nullable<string> {
    return AddressStringBuilder.fromRegionToSettlement(this.address)
      .withSeparator(this.separator)
      .withoutMunicipality()
      .toString();
  }

  // получение наименования населенного пункта без региона и образований
  getLocalityShortPath(): Nullable<string> {
    return AddressStringBuilder.locality(this.address).withSeparator(this.separator).toString();
  }

  // получение наименования населенного пункта без региона и образований и без города/района (если это посёлок)
  // отличается от getLocalityFullPath тем, что в locality входит всё из ГОРОД+НАС_ПУНКТ, а адреса очень сложные, и вдруг будет и то и то, в данном случае учитывается последний элемент
  getLastLocalityShortPath(strict: boolean = false): Nullable<string> {
    return AddressStringBuilder.locality(this.address).withSeparator(this.separator).lastLevel().toString();
  }

  // получение наименования населенного пункта без региона и образований и без города/района (если это посёлок) и без имени типа
  getLocalitySimpleName(): Nullable<string> {
    return AddressStringBuilder.locality(this.address).withSeparator(this.separator).lastLevel().toSimpleString();
  }

  // получение наименования региона
  getLocalityRegion(): Nullable<string> {
    return AddressStringBuilder.region(this.address).withSeparator(this.separator).toString();
  }

  // получение наименования района
  getLocalityAdmRegion(): Nullable<string> {
    return AddressStringBuilder.admRegion(this.address).withOutDefaultString().withSeparator(this.separator).toString();
  }

  // получение id объекта из иерархии по уровеню
  getLocalityIdByLevel(level: EAddressLevel): Nullable<string> {
    return this.address.hierarchy?.find(item => item.level.id === level)?.id ?? null;
  }

  // получение значений у уровней начиная от здания
  getLocalityValuesByLevel(): Nullable<AddressObjectValue[]> {
    const levels = this.getHierarchyObjects(EAddressLevel.Stead);
    const data: AddressObjectValue[] = [];

    if (levels?.length > 0) {
      levels.forEach(level => {
        if (level.values) {
          data.push(...level.values);
        }
      });
    }

    return data;
  }

  // получение наименования улицы
  getStreet(): Nullable<string> {
    return AddressStringBuilder.street(this.address).withOutDefaultString().withSeparator(this.separator).toString();
  }

  // получение наименования улицы начиная с региона
  getStreetFullPath() {
    return AddressStringBuilder.fromRegionToStreet(this.address)
      .withSeparator(this.separator)
      .withoutMunicipality()
      .toString();
  }

  // факт отсутствия адресных объектов по заданным уровням иерархии
  isEmptyStartingFromStead(): boolean {
    return AddressStringBuilder.fromStead(this.address).isEmpty();
  }
}

export class AddressStringBuilder {
  private readonly defaultSeparator = ', ';
  private readonly source: AddressObject[];
  private readonly sourceOptions: Partial<Record<EAddressOption, string>>;
  private defaultString: string;
  private separator: string = this.defaultSeparator;
  private options: string[] = [];
  private objects: AddressObject[];

  // получение иерархии по уровню [С, ПО]
  private static getHierarchyObjects(
    address: AnyAddress,
    fromLevel: EAddressLevel,
    toLevel?: EAddressLevel
  ): AddressObject[] {
    //получаем объекты иерархии адреса
    let hierarchyObjects =
      address.hierarchy
        ?.filter(item => item.level.id >= fromLevel && (toLevel === undefined || item.level.id <= toLevel))
        ?.filter(item => !!item) ?? [];
    //сортируем по уровню для правильного порядка
    hierarchyObjects = hierarchyObjects.sort((o1, o2) => o1.level.id - o2.level.id);
    //убираем дублирующие id, это может быть к примеру для городов федерального значения
    return uniqBy(hierarchyObjects, 'id');
  }

  // полный адрес
  static full(address: AnyAddress): AddressStringBuilder {
    const objects = AddressStringBuilder.getHierarchyObjects(address, EAddressLevel.Region, EAddressLevel.Parking);
    return new AddressStringBuilder(address, objects);
  }

  static region(address: AnyAddress): AddressStringBuilder {
    const objects = AddressStringBuilder.getHierarchyObjects(address, EAddressLevel.Region, EAddressLevel.Region);
    return new AddressStringBuilder(address, objects);
  }

  static admRegion(address: AnyAddress): AddressStringBuilder {
    const objects = AddressStringBuilder.getHierarchyObjects(
      address,
      EAddressLevel.AdministrativeRegion,
      EAddressLevel.AdministrativeRegion
    );
    return new AddressStringBuilder(address, objects);
  }

  static street(address: AnyAddress): AddressStringBuilder {
    const objects = AddressStringBuilder.getHierarchyObjects(address, EAddressLevel.Street, EAddressLevel.Street);
    return new AddressStringBuilder(address, objects);
  }

  // от региона до посёлка
  static fromRegionToSettlement(address: AnyAddress): AddressStringBuilder {
    const objects = AddressStringBuilder.getHierarchyObjects(address, EAddressLevel.Region, EAddressLevel.Settlement);
    return new AddressStringBuilder(address, objects);
  }

  // от региона до улицы
  static fromRegionToStreet(address: AnyAddress): AddressStringBuilder {
    const objects = AddressStringBuilder.getHierarchyObjects(address, EAddressLevel.Region, EAddressLevel.Street);
    return new AddressStringBuilder(address, objects);
  }

  // от дома
  static fromStead(address: AnyAddress): AddressStringBuilder {
    const objects = AddressStringBuilder.getHierarchyObjects(address, EAddressLevel.Stead);
    return new AddressStringBuilder(address, objects);
  }

  // населенный пункт (от города до посёлка)
  static locality(address: AnyAddress): AddressStringBuilder {
    const objects = AddressStringBuilder.getHierarchyObjects(address, EAddressLevel.City, EAddressLevel.Settlement);
    return new AddressStringBuilder(address, objects);
  }

  // получение адресных "опций" - отдельные специфические элементы из всей структуры адреса
  private static parseOptions(address: AnyAddress): Partial<Record<EAddressOption, string>> {
    const options: Partial<Record<EAddressOption, string>> = {};
    if (address.postalCode) {
      options[EAddressOption.PostalCode] = address.postalCode;
    }
    return options;
  }

  constructor(address: AnyAddress, objects: AddressObject[]) {
    this.source = [...objects];
    this.sourceOptions = AddressStringBuilder.parseOptions(address);
    this.objects = objects;
    this.defaultString = address.shortName || address.name || '';
  }

  // добавление опции - почтовый индекс
  withPostalCode(): AddressStringBuilder {
    return this.withOptions(EAddressOption.PostalCode);
  }

  // изменение доп опций
  withOptions(...options: EAddressOption[]): AddressStringBuilder {
    options.map(option => {
      const value = this.sourceOptions[option];
      if (value) {
        this.options.push(value);
      }
    });
    return this;
  }

  // изменение сепаратора для вывода в строку
  withSeparator(separator?: string): AddressStringBuilder {
    this.separator = separator || this.defaultSeparator;
    return this;
  }

  // восстановление дефолтных настроек
  restore(): AddressStringBuilder {
    this.objects = [...this.source];
    this.options = [];
    this.separator = this.defaultSeparator;
    return this;
  }

  withOutDefaultString() {
    this.defaultString = '';
    return this;
  }

  // конвертация в строку
  toString(props: { shortName?: boolean } = { shortName: true }): string {
    return props.shortName ? this.toShortString() : this.toFullString();
  }

  // конвертация в строку с короткими наименованиями
  toShortString(): string {
    const strings = this.objects.map(item => item.shortName ?? item.name ?? null);
    const commonString = strings.join(this.separator) || this.defaultString;
    return [...this.options, commonString].join(this.separator);
  }

  // конвертация в строку с длинными наименованиями
  toFullString(): string {
    const strings = this.objects.map(item => item.name ?? item.shortName ?? null);
    return [...this.options, ...strings].join(this.separator) || this.defaultString;
  }

  // конвертация в строку с короткими наименованиями (без имен типов)
  toSimpleString(): string {
    const strings = this.objects.map(item => item.values?.[0]?.value ?? null);
    return [...this.options, ...strings].join(this.separator) || this.defaultString;
  }

  // исключение уровней
  withoutLevels(...levels: EAddressLevel[]): AddressStringBuilder {
    this.objects = this.objects.filter(item => item?.level && !levels.includes(item.level.id)) ?? [];
    return this;
  }

  // исключение муниципалитетов
  withoutMunicipality(): AddressStringBuilder {
    return this.withoutLevels(EAddressLevel.Municipality);
  }

  // оставляем односложное наименовании в текущей иерархии (ищем начиная с последней до первой, останавливаемся на том где есть shortName)
  lastLevel(): AddressStringBuilder {
    const lastLevel = this.objects.reverse().find(item => !!item.shortName || !!item.name);
    this.objects = lastLevel ? [lastLevel] : [];
    return this;
  }

  // нет ардесных объектов в составе
  isEmpty(): boolean {
    return this.objects.length === 0;
  }
}
