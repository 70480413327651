import ChatBubbleIcon from '@mui/icons-material/ChatBubbleOutline';
import { useSelector } from 'react-redux';
import { ButtonLink } from '../../../../components/common/buttons/link';
import { TextSemiBold } from '../../../products/create/controls';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingOrderDetailsBookingOrderSelector } from '../store/selectors';
import { StepperButtonWrapper } from './controls';

const BookingOrderDetailsStepperFooterAdapter = () => {
  const bookingOrder = useSelector(bookingOrderDetailsBookingOrderSelector);

  const handlers = useContextHandlers();

  const onShowFeedback = () => {
    handlers.onChangeDialogState('feedback', bookingOrder);
  };

  return (
    <StepperButtonWrapper>
      <ButtonLink
        variant='text'
        fullWidth={false}
        onClick={onShowFeedback}
      >
        <ChatBubbleIcon fontSize={'small'} />
        <TextSemiBold>Форма обратной связи</TextSemiBold>
      </ButtonLink>
    </StepperButtonWrapper>
  );
};

export default BookingOrderDetailsStepperFooterAdapter;
