import { ContentTargetViewLocalitiesTableCell } from '../../../general/contentTarget/components/viewLocalities';
import TradeOfferTableCell, { TradeOfferTableCellCommonProps } from './index';

const TradeOfferTableCellTargetLocalities = (props: TradeOfferTableCellCommonProps) => {
  const {
    tradeOffer: { target },
  } = props;

  return (
    <TradeOfferTableCell
      {...props}
      value={<ContentTargetViewLocalitiesTableCell value={target?.targetLocalities ?? null} />}
    />
  );
};

export default TradeOfferTableCellTargetLocalities;
