import { SportOptionTyped } from '@/domain/model';
import { pageSizeAll } from '@/domain/model/constants';
import { ETradeOfferType } from '@/domain/model/enums';
import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterValueItem,
  EDataFilterType,
} from '@/domain/model/filter';
import { EDateFormat } from '@/domain/model/formats';
import { Nullable } from '@/domain/model/types';
import { formatDateRange } from '@/presentation/utils';
import { ETradeActivationTableColumn } from '@features/tradeActivation/types';
import moment from 'moment';
import Api from '../../../../data/api';

export enum ETradeActivationsFilterItem {
  /** Строка запроса */
  Query = 'query',
  /** Фильтр даты создания */
  CreatedAt = 'createdAt',
  /** Фильтр код КП */
  OfferCode = 'offer.code',
  /** Фильтр название КП */
  OfferName = 'offer.name',
  /** Фильтр тип промоужен */
  PromotionType = 'promotionType',
  PromotionCode = 'promotion.code',
  /** Фильтр фамилия клиента */
  CustomerLastName = 'customer.lastName',
  CustomerFirstName = 'customer.firstName',
  CustomerMiddleName = 'customer.middleName',
  /* Партнер */
  Partner = 'offer.partner.id',
}

export const tradeActivationsFilterItems: Record<ETradeActivationTableColumn, ETradeActivationsFilterItem[]> = {
  [ETradeActivationTableColumn.CreatedAt]: [ETradeActivationsFilterItem.CreatedAt],
  [ETradeActivationTableColumn.OfferCode]: [ETradeActivationsFilterItem.OfferCode],
  [ETradeActivationTableColumn.OfferName]: [ETradeActivationsFilterItem.OfferName],
  [ETradeActivationTableColumn.PromotionType]: [ETradeActivationsFilterItem.PromotionType],
  [ETradeActivationTableColumn.Partner]: [ETradeActivationsFilterItem.Partner],
  [ETradeActivationTableColumn.PromotionCode]: [ETradeActivationsFilterItem.PromotionCode],
  [ETradeActivationTableColumn.CustomerName]: [
    ETradeActivationsFilterItem.CustomerLastName,
    ETradeActivationsFilterItem.CustomerFirstName,
    ETradeActivationsFilterItem.CustomerMiddleName,
  ],
  [ETradeActivationTableColumn.CustomerEmail]: [],
  [ETradeActivationTableColumn.CustomerPhone]: [],
};

export type TradeActivationsFilterEditStrategy = DataFilterStrategyBase<ETradeActivationsFilterItem>;

export type TradeActivationsFilterValues = {
  readonly [ETradeActivationsFilterItem.Query]?: DataFilterValueItem<Nullable<string>>;
  readonly [ETradeActivationsFilterItem.CreatedAt]?: DataFilterValueItem<
    Nullable<[Nullable<string>, Nullable<string>]>
  >;
  readonly [ETradeActivationsFilterItem.CustomerLastName]?: DataFilterValueItem<Nullable<string>>;
  readonly [ETradeActivationsFilterItem.OfferCode]?: DataFilterValueItem<Nullable<string>>;
  readonly [ETradeActivationsFilterItem.OfferName]?: DataFilterValueItem<Nullable<string>>;
  readonly [ETradeActivationsFilterItem.PromotionType]?: DataFilterValueItem<Nullable<string[]>>;
  readonly [ETradeActivationsFilterItem.PromotionCode]?: DataFilterValueItem<Nullable<string>>;
  readonly [ETradeActivationsFilterItem.Partner]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
  readonly [ETradeActivationsFilterItem.CustomerLastName]?: DataFilterValueItem<Nullable<string>>;
  readonly [ETradeActivationsFilterItem.CustomerFirstName]?: DataFilterValueItem<Nullable<string>>;
  readonly [ETradeActivationsFilterItem.CustomerMiddleName]?: DataFilterValueItem<Nullable<string>>;
};

const getCreatedDatePreview = (
  date: TradeActivationsFilterValues[ETradeActivationsFilterItem.CreatedAt]
): Nullable<string> => {
  const range = formatDateRange(date?.value?.[0] ?? null, date?.value?.[1] ?? null, (v: Nullable<string>) =>
    moment(v).format(EDateFormat.DisplayDefault)
  );
  return range ? 'Создан ' + range : null;
};

const getCustomerFullName = (values: TradeActivationsFilterValues): Nullable<string> =>
  [
    values[ETradeActivationsFilterItem.CustomerLastName]?.value,
    values[ETradeActivationsFilterItem.CustomerFirstName]?.value,
    values[ETradeActivationsFilterItem.CustomerMiddleName]?.value,
  ]
    .filter(v => !!v)
    .join(' ')
    .trim() || null;

export const getTradeActivationFilterStrategy = (
  filterItem: ETradeActivationsFilterItem,
  promotionTypes: SportOptionTyped<ETradeOfferType>[],
  values: TradeActivationsFilterValues
): Nullable<TradeActivationsFilterEditStrategy> => {
  switch (filterItem) {
    case ETradeActivationsFilterItem.Query:
      return {
        type: EDataFilterType.String,
        key: ETradeActivationsFilterItem.Query,
        label: 'Поиск по ключевому слову',
        preview: values[ETradeActivationsFilterItem.Query]?.value || null,
        value: values[ETradeActivationsFilterItem.Query]?.value || null,
      };
    case ETradeActivationsFilterItem.CreatedAt:
      return {
        type: EDataFilterType.DatePeriod,
        key: ETradeActivationsFilterItem.CreatedAt,
        label: '',
        labelPeriod: {
          from: 'Создан с',
          to: 'по',
        },
        preview: getCreatedDatePreview(values[ETradeActivationsFilterItem.CreatedAt]),
        value: values[ETradeActivationsFilterItem.CreatedAt]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Between,
        },
      };
    case ETradeActivationsFilterItem.OfferCode:
      return {
        type: EDataFilterType.String,
        key: ETradeActivationsFilterItem.OfferCode,
        label: 'Код ТП',
        preview: values[ETradeActivationsFilterItem.OfferCode]?.value || null,
        value: values[ETradeActivationsFilterItem.OfferCode]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ETradeActivationsFilterItem.OfferName:
      return {
        type: EDataFilterType.String,
        key: ETradeActivationsFilterItem.OfferName,
        label: 'Название',
        preview: values[ETradeActivationsFilterItem.OfferName]?.value || null,
        value: values[ETradeActivationsFilterItem.OfferName]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ETradeActivationsFilterItem.PromotionType:
      return {
        type: EDataFilterType.ListMultiple,
        key: ETradeActivationsFilterItem.PromotionType,
        label: 'Способ получения',
        preview: `Способ получения (${values[ETradeActivationsFilterItem.PromotionType]?.value?.length})`,
        previews: promotionTypes
          .filter(t => values[ETradeActivationsFilterItem.PromotionType]?.value?.some(v => v === t.id))
          .map(t => t.name),
        value: values[ETradeActivationsFilterItem.PromotionType]?.value || null,
        items: promotionTypes.map(t => ({ id: t.id, name: t.name })),
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
        },
      };
    case ETradeActivationsFilterItem.Partner:
      return {
        type: EDataFilterType.AutocompleteMultiple,
        key: ETradeActivationsFilterItem.Partner,
        label: 'Партнёр',
        value: values[ETradeActivationsFilterItem.Partner]?.value || null,
        preview: `Партнер (${values[ETradeActivationsFilterItem.Partner]?.value?.length})` || null,
        previews: values[ETradeActivationsFilterItem.Partner]?.value?.map(t => t.name) ?? [],
        source: (name: string) =>
          name
            ? Api.partner
                .all({
                  query: name,
                  sort: 'name,asc',
                  page: 1,
                  pageSize: pageSizeAll,
                })
                .then(response => response.data)
            : Promise.resolve([]),
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
          valueAttribute: 'id',
        },
      };
    case ETradeActivationsFilterItem.PromotionCode:
      return {
        type: EDataFilterType.String,
        key: ETradeActivationsFilterItem.PromotionCode,
        label: 'Присвоенный код',
        preview: values[ETradeActivationsFilterItem.PromotionCode]?.value || null,
        value: values[ETradeActivationsFilterItem.PromotionCode]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ETradeActivationsFilterItem.CustomerLastName:
      return {
        type: EDataFilterType.String,
        key: ETradeActivationsFilterItem.CustomerLastName,
        group: 'customer_name',
        label: 'Фамилия',
        preview: getCustomerFullName(values),
        value: values[ETradeActivationsFilterItem.CustomerLastName]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ETradeActivationsFilterItem.CustomerFirstName:
      return {
        type: EDataFilterType.String,
        key: ETradeActivationsFilterItem.CustomerFirstName,
        group: 'customer_name',
        label: 'Имя',
        preview: getCustomerFullName(values),
        value: values[ETradeActivationsFilterItem.CustomerFirstName]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ETradeActivationsFilterItem.CustomerMiddleName:
      return {
        type: EDataFilterType.String,
        key: ETradeActivationsFilterItem.CustomerMiddleName,
        group: 'customer_name',
        label: 'Отчество',
        preview: getCustomerFullName(values),
        value: values[ETradeActivationsFilterItem.CustomerMiddleName]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
  }
};
