import styled from '@emotion/styled';
import { viewConfig } from '../../../theme/viewConfig';

export const ContainerWrapper = styled.div``;

export const Wrapper = styled.div`
  height: 100%;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const FooterWrapper = styled.div`
  border-top: 1px solid ${p => p.theme.palette.secondary.main};

  padding: ${p => p.theme.spacing(2)};
`;

export const TooltipTitleWrapper = styled.div`
  padding: ${p => p.theme.spacing(3)};
`;

export const LoaderWrapper = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: ${viewConfig.stepper.width};
`;
