import Api from '@/data/api';
import { EPartnerStatus, PartnerView } from '@/domain';
import AdCampaignCreateFieldPartner from '@features/adCampaign/create/fields/partner';
import { AdCampaignCreateFieldCommonProps } from '@features/adCampaign/types';
import debounce from 'lodash/debounce';
import { useRef, useState } from 'react';

const fieldName = 'partner';

export const AdCampaignCreateFieldOfferPartnerAdapter = (props: AdCampaignCreateFieldCommonProps<typeof fieldName>) => {
  const [partners, setPartners] = useState<PartnerView[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const handlerSearch = useRef(debounce(value => search(value), 500)).current;

  const search = (query: string) => {
    if (!query) return;
    setIsSearching(true);
    Api.partner
      .all({ statuses: [EPartnerStatus.Enabled], page: 1, pageSize: 20, query, sort: 'name,asc' })
      .then(response => {
        setPartners(response.data);
      })
      .finally(() => {
        setIsSearching(false);
      });
  };

  return (
    <AdCampaignCreateFieldPartner
      {...props}
      partners={partners}
      isSearching={isSearching}
      onSearch={handlerSearch}
    />
  );
};
