import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../../../../../data/api';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { pageSizeAll } from '../../../../../../domain/model/constants';
import { EUserRole, EUserStatus } from '../../../../../../domain/model/enums';
import { Nullable } from '../../../../../../domain/model/types';
import { MpUser } from '../../../../../../domain/model/user';

export type UserMpSuggestionFetchProps = {
  readonly name: Nullable<string>;
  readonly roles?: EUserRole[];
  readonly statuses?: EUserStatus[];
};

export const userMpSuggestionFetch = createAsyncThunk<MpUser[], UserMpSuggestionFetchProps, AppThunkAPIConfig>(
  'userMp/search/fetch',
  async ({ name, roles = [EUserRole.DefaultMp], statuses = [EUserStatus.Enabled] }, { rejectWithValue, signal }) => {
    try {
      if (name) {
        const { data } = await Api.user.mp.all({
          page: 1,
          pageSize: pageSizeAll,
          sort: ['lastName,asc', 'firstName,asc', 'middleName,asc'],
          query: name,
          roles,
          statuses,
          signal,
        });
        return data;
      } else {
        return [];
      }
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);
