import { PartnerShort } from '@/domain';
import { FC } from 'react';
import AppImage from '../../../../../components/common/images/common';
import { EEntityPreviewMode } from '../../../../../types';
import { Card, CardTitle, CardTitleMobile, WrapperLogo } from './controls';

interface CmsPartnersPreviewBlockPartnerProps {
  readonly partner: PartnerShort;
  readonly mode?: EEntityPreviewMode;
}

const CmsPartnersPreviewBlockPartner: FC<CmsPartnersPreviewBlockPartnerProps> = ({ partner, mode = null }) => {
  const logo = partner.logo?.path;

  return (
    <Card mode={mode}>
      <WrapperLogo mode={mode}>{logo && <AppImage src={logo} />}</WrapperLogo>
      {mode !== EEntityPreviewMode.Mobile && <CardTitle>{partner.name}</CardTitle>}
      {mode === EEntityPreviewMode.Mobile && <CardTitleMobile variant='caption'>{partner.name}</CardTitleMobile>}
    </Card>
  );
};

export default CmsPartnersPreviewBlockPartner;
