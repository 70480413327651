import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataFilterComponent from '../../../../components/common/dataFilter';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { EProductTableFilterItem } from '../filter/filterUtils';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { productsFilterSelector, productsGuidSelector, productsSortColumnSelector } from '../store/selectors';
import { EProductTableColumn } from '../utils';

interface ProductTableFilterAdapterProps {
  readonly filterAdapter: DataFilterAdapter<EProductTableColumn, EProductTableFilterItem>;
}

const ProductTableFilterAdapter = (props: ProductTableFilterAdapterProps) => {
  const { filterAdapter } = props;

  const handlers = useContextHandlers();

  const guid = useSelector(productsGuidSelector);
  const sortColumn = useSelector(productsSortColumnSelector);
  const filter = useSelector(productsFilterSelector);

  const { filterStrategies } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return useMemo(
    () => (
      <DataFilterComponent
        strategies={filterStrategies}
        onChange={handlers.onChangeFilter}
      />
    ),
    [filterStrategies, handlers.onChangeFilter]
  );
};

export default ProductTableFilterAdapter;
