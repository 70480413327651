import { ClientOrg } from '@/domain';
import { FCC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import store from '../../../../../data/store/store';
import { useClientOrgActions } from '../../actions/useActions';
import { getClientOrgEditRoute } from '../../entry';
import { ClientOrgDetailsContainerProps } from '../container';
import { ClientOrgDetailsHandlersContext, ClientOrgDetailsHandlersContextValue } from '../context';
import { clientOrgDetailsClientOrgSelector } from '../store/selectors';
import { clientOrgDetailsByIdFetch } from '../store/slice';

export const ClientOrgDetailHandlersProvider: FCC<ClientOrgDetailsContainerProps> = ({ children, id }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { onTryBlock, onTryUnBlock } = useClientOrgActions();

  const getClientOrgFromState = useCallback((): Nullable<ClientOrg> => {
    const state = store.getState();
    return clientOrgDetailsClientOrgSelector(state);
  }, [store]);

  const onRefresh = useCallback(() => {
    dispatch(clientOrgDetailsByIdFetch({ id }));
  }, [dispatch]);

  const onClientOrgOpenEdit = useCallback<ClientOrgDetailsHandlersContextValue['onClientOrgOpenEdit']>(
    clientOrg => {
      history.push(getClientOrgEditRoute({ id: clientOrg.id }));
    },
    [history]
  );

  const onClientOrgRefetch = useCallback<ClientOrgDetailsHandlersContextValue['onClientOrgRefetch']>(
    async clientOrg => {
      return await dispatch(clientOrgDetailsByIdFetch({ id: clientOrg.id })).unwrap();
    },
    [dispatch]
  );

  const onClientOrgTryBlock = useCallback<ClientOrgDetailsHandlersContextValue['onClientOrgTryBlock']>(() => {
    const clientOrg = getClientOrgFromState();

    if (clientOrg) {
      onTryBlock(clientOrg);
    }
  }, [getClientOrgFromState]);

  const onClientOrgTryUnBlock = useCallback<ClientOrgDetailsHandlersContextValue['onClientOrgTryUnBlock']>(() => {
    const clientOrg = getClientOrgFromState();

    if (clientOrg) {
      onTryUnBlock(clientOrg);
    }
  }, [getClientOrgFromState]);

  const value: ClientOrgDetailsHandlersContextValue = {
    onRefresh,

    onClientOrgTryBlock,
    onClientOrgTryUnBlock,
    onClientOrgOpenEdit,
    onClientOrgRefetch,
  };

  return <ClientOrgDetailsHandlersContext.Provider value={value}>{children}</ClientOrgDetailsHandlersContext.Provider>;
};
