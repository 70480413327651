import { Fade, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../../../data/store/store';
import { NotificationOption } from '../../../../../domain/model/notification';
import { UUID } from '../../../../../domain/model/types';
import { addSearchParamToLocation } from '../../../../../routing/globalRouting';
import MasterActionsComponent from '../../../../components/common/actions/master';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../components/common/header';
import ContentLoader from '../../../../components/common/loader';
import Splitter from '../../../../components/common/splitter';
import { DefaultContentWrapper } from '../../../../components/common/wrappers/content';
import { DefaultFooterWrapper } from '../../../../components/common/wrappers/footer';
import { MPTag } from '../../../../theme/ui-kit/tag';
import { PanelAction, PanelActions } from '../../../../types';
import useBreadcrumbs from '../../../general/breadcrumbs/useBreadcrumbs';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import { EPartnerActionType, PartnerActionDetailsType } from '../../../general/partner/types';
import { getPartnerStatusText, getPartnerStatusType } from '../../../general/partner/utils/common';
import { getPartnerApplicationEditRoute } from '../../../partnerApplication/entry';
import { getPartnerDeskEditRoute } from '../../../partnerDesk/entry';
import { EPartnerUrlParam } from '../../entry';
import { ContainerWrapper, HeaderWrapper, LoaderWrapper } from '../controls';
import PartnerDetailsTabs from '../tabs';
import {
  EPartnerDetailsLegalTab,
  EPartnerDetailsTab,
  getPartnerDetailsActions,
  getPartnerDetailsLegalTabs,
  PartnerDetailsLegalTab,
} from '../utils';
import PartnerDetailsOwnerComponent from './component';
import { partnerDetailsOwnerByIdSelector } from './store/selectors';
import {
  partnerDetailsOwnerByIdFetch,
  partnerDetailsOwnerNotificationsUpdate,
  partnerDetailsOwnerStateReset,
} from './store/slice';

interface PartnerDetailsOwnerContainerProps {
  readonly id: UUID;
  readonly tab?: EPartnerDetailsTab;
  readonly legalTab?: EPartnerDetailsLegalTab;
  readonly canEdit?: boolean;
  readonly canEditDesk?: boolean;
  readonly canEditNotifications?: boolean;
}

const PartnerDetailsOwnerContainer = (props: PartnerDetailsOwnerContainerProps) => {
  const {
    id,
    tab = EPartnerDetailsTab.Legal,
    legalTab = EPartnerDetailsLegalTab.Common,
    canEdit,
    canEditDesk,
    canEditNotifications,
  } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const { partner, desk, notifications, isFetching } = useSelector(partnerDetailsOwnerByIdSelector);
  const { taxationSystemTypes, partnerTypes, partnerOwnerShipTypes } = useSelector(nsiDataSelector);

  const breadcrumbs = useBreadcrumbs();

  const onChangeTab = (newTab: EPartnerDetailsTab) => {
    history.replace(addSearchParamToLocation({ location, param: EPartnerUrlParam.Tab, value: newTab }));
  };

  const onEditDesk = () => {
    history.push(getPartnerDeskEditRoute({ partnerId: id }));
  };

  const onEditApplication = () => {
    history.push(getPartnerApplicationEditRoute({ id }));
  };

  const onChangeNotification = (option: NotificationOption, enabled: boolean) => {
    dispatch(partnerDetailsOwnerNotificationsUpdate({ id, option, enabled }));
  };

  const getOnEditDesk = () => {
    if (canEditDesk) {
      return onEditDesk;
    }
  };

  const onChangeLegalTab = (newLegalTab: PartnerDetailsLegalTab) => {
    history.replace(addSearchParamToLocation({ location, param: EPartnerUrlParam.LegalTab, value: newLegalTab.id }));
  };

  const onPanelAction = (action: PanelAction<PartnerActionDetailsType>) => {
    const { type } = action;
    switch (type) {
      case EPartnerActionType.Edit:
        onEditApplication();
        break;
      case EPartnerActionType.EditDesk:
        onEditDesk();
        break;
      case EPartnerActionType.Activate:
      case EPartnerActionType.Deactivate:
        break;
      case EPartnerActionType.ChangeSortIndex:
        break;
    }
  };

  useEffect(() => {
    const promise = dispatch(partnerDetailsOwnerByIdFetch({ id }));
    return () => {
      dispatch(partnerDetailsOwnerStateReset());
      promise?.abort();
    };
  }, [dispatch]);

  const statusType = partner && getPartnerStatusType(partner.status);

  const actions: PanelActions<PartnerActionDetailsType> = partner
    ? getPartnerDetailsActions({
        status: partner.status,
        tab,
        canEdit,
        canEditDesk: !!desk && canEditDesk,
      })
    : [];

  const actionsPanel = (
    <MasterActionsComponent<PartnerActionDetailsType>
      show={!!actions.length}
      actions={actions}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );

  return (
    <Fade in>
      <DefaultContentWrapper
        type='details'
        stickyHeader
        fullHeight
        footer={actionsPanel}
      >
        <ContainerWrapper>
          {partner && (
            <>
              <HeaderWrapper>
                <DefaultHeader
                  headline={
                    <>
                      {breadcrumbs.length > 0 && <AppBreadcrumbs />}
                      {statusType && (
                        <MPTag
                          bold
                          label={getPartnerStatusText(partner.status)}
                          color={statusType}
                        />
                      )}
                    </>
                  }
                >
                  <Splitter size={1} />
                  <Typography variant='h2'>Компания</Typography>
                </DefaultHeader>
                <Splitter size={1.5} />
                <PartnerDetailsTabs
                  tab={tab}
                  onChangeTab={onChangeTab}
                />
              </HeaderWrapper>

              <Splitter
                variant='horizontal'
                size={4}
              />

              <PartnerDetailsOwnerComponent
                tab={tab}
                partner={partner}
                partnerTypes={partnerTypes}
                notifications={notifications}
                legalTabs={getPartnerDetailsLegalTabs(partner.regInfo?.orgType ?? null)}
                legalTab={legalTab}
                desk={desk}
                taxSystems={taxationSystemTypes}
                ownerShipTypes={partnerOwnerShipTypes}
                onEditDesk={getOnEditDesk()}
                onChangeLegalTab={onChangeLegalTab}
                onChangeNotificationOption={canEditNotifications ? onChangeNotification : null}
              />
            </>
          )}
        </ContainerWrapper>
        {isFetching && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
      </DefaultContentWrapper>
    </Fade>
  );
};

export default PartnerDetailsOwnerContainer;
