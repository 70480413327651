import BookingOrderTableCell, { BookingOrderTableCellCommonProps } from './index';
import { getUserFullName } from '../../../../utils';

const BookingOrderTableCellCustomerName = (props: BookingOrderTableCellCommonProps) => {
  const {
    bookingOrder: { customer },
  } = props;

  return (
    <BookingOrderTableCell
      {...props}
      value={customer && getUserFullName({ user: customer })}
    />
  );
};

export default BookingOrderTableCellCustomerName;
