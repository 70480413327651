import { useSelector } from 'react-redux';
import { nsiDataSelector } from '../../general/nsi/store/selectors';
import { CmsFeatureContainerCommonProps } from '../container/types';
import CmsPreviewTargetComponent from './component';
import usePreviewTargetData from './usePreviewTarget';

type CmsPreviewTargetContainerProps = CmsFeatureContainerCommonProps;

const CmsPreviewTargetContainer = ({ cmsContext }: CmsPreviewTargetContainerProps) => {
  const { userGenders: genderTypes } = useSelector(nsiDataSelector);
  const { target, isTargetFilled, onPreview, onChangeAttribute, onAudienceChange } = usePreviewTargetData({
    cmsContext,
  });

  return (
    <CmsPreviewTargetComponent
      target={target}
      genderTypes={genderTypes}
      isTargetFilled={isTargetFilled}
      onChangeAttribute={onChangeAttribute}
      onAudienceChange={onAudienceChange}
      onPreview={onPreview}
    />
  );
};

export default CmsPreviewTargetContainer;
