import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { OfferCategory } from '../../../../../../domain/model';
import { Nullable } from '../../../../../../domain/model/types';
import { DictionaryEditDialog } from '../../../../../components/common/dictionary/dialog/edit';
import Splitter from '../../../../../components/common/splitter';
import { MPFormInput } from '../../../../../theme/ui-kit/input';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  bookingOfferCategoriesEditEditDialogStateSelector,
  bookingOfferCategoriesEditIsRelationShipsCheckingSelector,
} from '../store/selectors';

interface BookingOfferCategoriesEditDialogEditAdapterInternalProps {
  readonly data: OfferCategory;
}

const BookingOfferCategoriesEditDialogEditAdapterInternal = (
  props: BookingOfferCategoriesEditDialogEditAdapterInternalProps
) => {
  const { data } = props;

  const handlers = useContextHandlers();

  const isRelationShipsChecking = useSelector(bookingOfferCategoriesEditIsRelationShipsCheckingSelector);

  const [error, setError] = useState<Nullable<string>>(null);
  const [relationShipsCount, setRelationShipsCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const [value, setValue] = useState<OfferCategory>(data);

  const onEdit = async () => {
    if (!value.name) {
      setError('Введите название');
    } else {
      setIsLoading(true);
      await handlers
        .onEdit(value)
        .then(() => {
          handlers.onChangeDialogState('modify', null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onClose = () => {
    setIsLoading(false);
    handlers.onChangeDialogState('modify', null);
  };

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setError(null);
    setValue(prev => ({ ...prev, name: event.target.value }));
  };

  const isFetching = isRelationShipsChecking || isLoading;

  useEffect(() => {
    handlers.onGetRelationShipsCount(data).then(setRelationShipsCount);
  }, [data]);

  useEffect(() => {
    return () => {
      setError(null);
      setIsLoading(false);
    };
  }, []);

  return (
    <DictionaryEditDialog
      isLoading={isFetching}
      onClose={onClose}
      onSave={onEdit}
    >
      <MPFormInput
        disabled={isFetching}
        label='Название'
        value={value.name}
        error={error !== null}
        helperText={error}
        inputRef={input => input && input.focus()}
        onChange={onChange}
      />
      <Splitter size={1} />
      {relationShipsCount > 0 && (
        <Typography variant='body2'>
          В <b>«{data.name}»</b> находятся активные предложения
        </Typography>
      )}
    </DictionaryEditDialog>
  );
};

export const BookingOfferCategoriesEditDialogEditAdapter = () => {
  const data = useSelector(bookingOfferCategoriesEditEditDialogStateSelector);

  if (!data) {
    return null;
  }

  return <BookingOfferCategoriesEditDialogEditAdapterInternal data={data.data} />;
};
