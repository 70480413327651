import { EOfferActivationStatus } from '@/domain/model/enums';

export const getCorpOfferActivationStatusText = (status: EOfferActivationStatus): string => {
  switch (status) {
    case EOfferActivationStatus.Pending:
      return 'На рассмотрении';
    case EOfferActivationStatus.Approved:
      return 'Не выдан';
    case EOfferActivationStatus.Given:
      return 'Выдан';
    case EOfferActivationStatus.Rejected:
      return 'Отклонен';
  }
};
