import { EPartnerEmployeeStep } from '../utils';

export const getPartnerEmployeeEditStepText = (step: EPartnerEmployeeStep): string => {
  switch (step) {
    case EPartnerEmployeeStep.Profile:
      return 'Профиль';
    case EPartnerEmployeeStep.Grants:
      return 'Доступ';
    default:
      throw new Error(`Unknown EPartnerEmployeeStep: ${step}`);
  }
};
