import { useMemo } from 'react';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import useCurrentUser from '../../hooks/useCurrentUser';
import AdminMpTableContainer from './container';
import { AdminMpTableConfigContext } from './context';
import { adminMpFilterItems, EAdminMpFilterItem, getAdminMpFilterStrategy } from './filterUtils';
import { useAdminMpTableConfig } from './hooks/useAdminMpTableConfig';
import { EAdminMpTableColumn, EAdminMpTableTab, getAdminMpTableColumns } from './utils';

interface AdminMpTableEntryProps {
  readonly guid: UUID;
  readonly tab: Nullable<EAdminMpTableTab>;
}

const AdminMpTableEntry = ({ guid, tab }: AdminMpTableEntryProps) => {
  const {
    accessMatrix: { adminsMp },
  } = useCurrentUser();

  const config = useAdminMpTableConfig();
  const { defaultTab } = config;

  const outTab = tab ?? defaultTab;

  // получаем максимально возможный состав колонок
  const sourceColumns = useMemo(() => getAdminMpTableColumns(outTab), [outTab]);
  
  // готовим адаптер для хука
  const adapter = useMemo<DataFilterAdapter<EAdminMpTableColumn, EAdminMpFilterItem>>(
    () => ({
      key: 'adminsMp',
      keyPartitions: [outTab],
      sourceColumns,
      filterItems: adminMpFilterItems,
      requiredFilters: [EAdminMpFilterItem.Query],
      getDataFilterStrategy: getAdminMpFilterStrategy,
    }),
    [outTab, sourceColumns]
  );

  return (
    <AdminMpTableConfigContext.Provider value={config}>
      <AdminMpTableContainer
        guid={guid}
        tab={outTab}
        filterAdapter={adapter}
        canCreate={adminsMp.create}
        canChangeStatus={adminsMp.changeStatus}
      />
    </AdminMpTableConfigContext.Provider>
  );
};

export default AdminMpTableEntry;
