import {
  ClientOrg,
  ClientOrgMultiSelectorPredicate,
  ClientOrgMultiSelectorPredicateRequest,
  ETargetType,
  GenderMultiSelectorPredicate,
  GenderMultiSelectorPredicateRequest,
  LocalityMultiSelectorPredicate,
  LocalityMultiSelectorPredicateRequest,
  OrgUnitMultiSelectorPredicate,
  OrgUnitMultiSelectorPredicateRequest,
  RoadMultiSelectorPredicate,
  RoadMultiSelectorPredicateRequest,
} from '@/domain';
import { MPAutocompleteMultipleSelectItem } from '@ui-kit/autocomplete/types';

export interface CmsPreviewTarget {
  readonly type: Nullable<ETargetType>;
  readonly clientOrgs: Nullable<ClientOrgMultiSelectorPredicate>;
  readonly externalUsers: Nullable<boolean>;
  readonly localities: Nullable<LocalityMultiSelectorPredicate>;
  readonly roads: Nullable<RoadMultiSelectorPredicate>;
  readonly orgUnits: Nullable<OrgUnitMultiSelectorPredicate>;
  readonly gender: Nullable<GenderMultiSelectorPredicate>;
  readonly familyMemberOnly: Nullable<boolean>;
  readonly tradeUnionMembersOnly: Nullable<boolean>;
  readonly havingChildFamilyMemberOnly: Nullable<boolean>;
  readonly hasRzdSocialPackage: Nullable<boolean>;
}

export interface CmsPreviewTargetExportData {
  readonly targetType: Nullable<ETargetType>;
  readonly targetClientOrgs: Nullable<ClientOrgMultiSelectorPredicateRequest>;
  readonly targetExternalUsers: Nullable<boolean>;
  readonly targetLocalities: Nullable<LocalityMultiSelectorPredicateRequest>;
  readonly targetRoads: Nullable<RoadMultiSelectorPredicateRequest>;
  readonly targetOrgUnits: Nullable<OrgUnitMultiSelectorPredicateRequest>;
  readonly targetGender: Nullable<GenderMultiSelectorPredicateRequest>;
  readonly targetFamilyMemberOnly: Nullable<boolean>;
  readonly targetTradeUnionMembersOnly: Nullable<boolean>;
  readonly targetHavingChildFamilyMemberOnly: Nullable<boolean>;
  readonly targetHasRzdSocialPackage: Nullable<boolean>;
}

export enum ECmsPreviewTargetUrlParam {
  Target = 'target',
  Context = 'context',
}

export enum ECmsPreviewTargetAudienceType {
  Corporate = 'corporate',
  External = 'external',
}

export enum CmsPreviewTargetAudienceSelectorItemType {
  ExternalUsers = 'externalUsers',
  ClientOrg = 'clientOrg',
}

export type CmsPreviewTargetAudienceSelectorItem =
  | (ClientOrg & { type: CmsPreviewTargetAudienceSelectorItemType.ClientOrg })
  | (MPAutocompleteMultipleSelectItem & { readonly type: CmsPreviewTargetAudienceSelectorItemType.ExternalUsers });
