import SportsmanTableCell, { SportsmanTableCellCommonProps } from './index';

const SportsmanTableCellActivitySubTypes = (props: SportsmanTableCellCommonProps) => {
  const {
    sportsman: { activityTypes },
  } = props;

  const value =
    activityTypes
      ?.filter(item => !!item.parent)
      ?.map(item => item.name)
      .join(', ') || '-';

  return (
    <SportsmanTableCell
      {...props}
      value={value}
    />
  );
};

export default SportsmanTableCellActivitySubTypes;
