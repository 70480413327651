import { forwardRef } from 'react';
import { Wrapper } from './controls';

interface DangerouslyHtmlWrapperProps {
  readonly children?: any;
}

const DangerouslyHtmlWrapper = forwardRef(({ children }: DangerouslyHtmlWrapperProps, ref: any) => {
  return <Wrapper ref={ref}>{children}</Wrapper>;
});

export default DangerouslyHtmlWrapper;
