import { DataFilterStrategyNumberPeriod } from '@/domain';
import { Grid } from '@mui/material';
import { MPNumberInput } from '@ui-kit/input';
import { DataFilterEditItemProps } from '../index';

interface DataFilterEditItemNumberPeriodProps<T extends string>
  extends DataFilterEditItemProps<T, Nullable<[Nullable<number>, Nullable<number>]>> {
  readonly strategy: DataFilterStrategyNumberPeriod<T>;
}

function DataFilterEditItemNumberPeriod<T extends string>(props: DataFilterEditItemNumberPeriodProps<T>) {
  const { strategy, onChange } = props;

  const { value, readOnly, labelPeriod } = strategy;

  const getRangeValue = (
    from: Nullable<number>,
    to: Nullable<number>
  ): Nullable<[Nullable<number>, Nullable<number>]> => (from === null && to === null ? null : [from, to]);

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={6}
      >
        <MPNumberInput
          label={labelPeriod.from}
          value={value?.[0] ?? null}
          disabled={readOnly}
          onChange={(event, newValue) => onChange(strategy, getRangeValue(newValue, value?.[1] ?? null))}
        />
      </Grid>
      <Grid
        item
        xs={6}
      >
        <MPNumberInput
          label={labelPeriod.to}
          value={value?.[1] ?? null}
          disabled={readOnly}
          onChange={(event, newValue) => onChange(strategy, getRangeValue(value?.[0] ?? null, newValue))}
        />
      </Grid>
    </Grid>
  );
}

export default DataFilterEditItemNumberPeriod;
