import activation, { TradeActivationActivationService } from './activation';

type TradeActivationServices = {
  readonly activation: TradeActivationActivationService;
};

const tradeActivationServices: TradeActivationServices = {
  activation,
};

export default tradeActivationServices;
