import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CorpOffer } from '../../../../../domain/model/corpOffer';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { NumberRange } from '../../../../types';
import { EOfferTableTab } from '../../../general/offer/types';
import { corpOffersSearchGet, corpOffersSearchGuidSelector } from '../store/selectors';
import { corpOffersSearchSetTab, corpOffersSearchStartSession } from '../store/slice';

interface UseCorpOffersSearchSessionSingleProps {
  readonly initialSelected: Nullable<CorpOffer>;
  readonly selectedMaxCount: 1;
}

interface UseCorpOffersSearchSessionMultipleProps {
  readonly initialSelected: Nullable<CorpOffer[]>;
  readonly selectedMaxCount?: NumberRange<2, 100> | false;
}

type UseCorpOffersSearchSessionProps = {
  readonly guid: UUID;
  readonly tab: EOfferTableTab;
} & (UseCorpOffersSearchSessionSingleProps | UseCorpOffersSearchSessionMultipleProps);

const useCorpOffersSearchSession = (props: UseCorpOffersSearchSessionProps): void => {
  const { guid, tab } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(corpOffersSearchGuidSelector);
  const { tab: tableTab } = useSelector(corpOffersSearchGet);

  // инициализация сеанса
  useEffect(() => {
    const selected: CorpOffer[] = [];
    if (props.initialSelected) {
      switch (props.selectedMaxCount) {
        case 1:
          selected.push(props.initialSelected);
          break;
        default:
          selected.push(...props.initialSelected);
          break;
      }
    }
    if (guid !== currentGuid) {
      dispatch(corpOffersSearchStartSession({ guid, selected }));
    }
  }, [dispatch, guid, currentGuid, props.initialSelected, props.selectedMaxCount]);

  // изменение вкладки
  useEffect(() => {
    if (tab && tab !== tableTab) {
      dispatch(corpOffersSearchSetTab({ tab }));
    }
  }, [dispatch, tab, tableTab]);
};

export default useCorpOffersSearchSession;
