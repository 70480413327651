import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import { Nullable } from '../../domain/model/types';

interface UseInputDebounceProps {
  readonly initialValue: Nullable<string>;
  readonly delay?: number;
  readonly nullIsValidValue?: boolean;
  readonly onChange: (value: string) => void;
}

type UseInputDebounceDeps = any[];

type UseInputDebounce = (
  props: UseInputDebounceProps,
  deps?: UseInputDebounceDeps
) => [Nullable<string>, (value: string) => void];

export const useInputDebounce: UseInputDebounce = (props, deps = []) => {
  const { initialValue, onChange, nullIsValidValue = false, delay = 1000 } = props;

  const [localValue, setLocalValue] = useState<Nullable<string>>(initialValue);

  const handleChange = useCallback<any>(
    debounce(newValue => onChange(newValue), delay),
    deps
  );

  useEffect(() => {
    if (localValue !== initialValue && (localValue !== null || nullIsValidValue)) handleChange(localValue);
  }, [localValue]);

  return [localValue, setLocalValue];
};
