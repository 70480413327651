import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BookingOfferShort } from '../../../../../domain/model/booking';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { OfferActions } from '../../../general/offer/types';
import { useBookingOfferActions } from '../../actions/useActions';
import { EBookingOfferTableFilterItem } from '../../filterUtils';
import { BookingLifeCycle } from '../../lifecycle/types';
import {
  BookingActionTableCommonType,
  BookingActionTableType,
  EBookingActionType,
  EBookingOfferTableColumn,
} from '../../types';
import { getBookingOfferTableActions } from '../../utils/actions';
import { BookingOfferTable } from '../component';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  bookingOffersBookingOffersSelector,
  bookingOffersFilterSelector,
  bookingOffersGuidSelector,
  bookingOffersSortColumnSelector,
  bookingOffersSortDirectionSelector,
} from '../store/selectors';
import { BookingOfferActionCall } from '../utils';

interface BookingOffersTableAdapterProps {
  readonly filterAdapter: DataFilterAdapter<EBookingOfferTableColumn, EBookingOfferTableFilterItem>;
  readonly lifecycle: BookingLifeCycle;
  readonly tabActions: OfferActions<BookingActionTableType>;
}

export const BookingOffersTableAdapter = (props: BookingOffersTableAdapterProps) => {
  const { filterAdapter, lifecycle, tabActions } = props;

  const { onBookingOfferClick, onBookingOfferOpenEdit } = useContextConfig();
  const handlers = useContextHandlers();
  const guid = useSelector(bookingOffersGuidSelector);
  const sortColumn = useSelector(bookingOffersSortColumnSelector);
  const sortDirection = useSelector(bookingOffersSortDirectionSelector);
  const bookingOffers = useSelector(bookingOffersBookingOffersSelector);
  const filter = useSelector(bookingOffersFilterSelector);

  const {
    utils: {
      selectors: { isBookingOffersAnyChangedSelector },
    },
  } = useBookingOfferActions();

  const needRefresh = useSelector(isBookingOffersAnyChangedSelector);

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  const onActionClick = (call: BookingOfferActionCall) => {
    const { action, bookingOffer } = call;

    switch (action) {
      case EBookingActionType.ManageServices:
        handlers.onBookingOfferTryServiceManage(bookingOffer);
        break;
      case EBookingActionType.Resume:
        handlers.onBookingOfferTryResume(bookingOffer);
        break;
      case EBookingActionType.Delete:
        handlers.onBookingOfferTryDelete(bookingOffer);
        break;
      case EBookingActionType.Archive:
        handlers.onBookingOfferTryArchive(bookingOffer);
        break;
      case EBookingActionType.ChangeModerator:
        handlers.onBookingOfferTryChangeModerator(bookingOffer);
        break;
      case EBookingActionType.InWork:
        handlers.onBookingOfferTryInWork(bookingOffer);
        break;
      case EBookingActionType.Reject:
        handlers.onBookingOfferTryReject(bookingOffer);
        break;
      case EBookingActionType.Duplicate:
        handlers.onBookingOfferTryDuplicate(bookingOffer);
        break;
      case EBookingActionType.Approve:
        handlers.onBookingOfferTryApprove(bookingOffer);
        break;
      case EBookingActionType.Publish:
        handlers.onBookingOfferTryPublish(bookingOffer);
        break;
      case EBookingActionType.UnPublish:
        handlers.onBookingOfferTryUnPublish(bookingOffer);
        break;
      case EBookingActionType.ReturnToVerification:
        handlers.onBookingOfferTryReturnToVerification(bookingOffer);
        break;
      case EBookingActionType.Pause:
        handlers.onBookingOfferTryPause(bookingOffer);
        break;
      case EBookingActionType.Retrieve:
        handlers.onBookingOfferTryRetrieve(bookingOffer);
        break;
      case EBookingActionType.Edit:
        onBookingOfferOpenEdit(bookingOffer);
        break;
    }
  };

  const getBookingOfferCommonActions = (
    bookingOffer: BookingOfferShort,
    actions: OfferActions<BookingActionTableType>
  ): OfferActions<BookingActionTableCommonType> => {
    return getBookingOfferTableActions(lifecycle, bookingOffer, actions) as OfferActions<BookingActionTableCommonType>;
  };

  useEffect(() => {
    if (needRefresh > 0) {
      handlers.onRefresh();
    }
  }, [needRefresh, handlers.onRefresh]);

  return (
    <BookingOfferTable
      metadata={metadata}
      onChangeMetadata={onChangeMetadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      bookingOffers={bookingOffers}
      onRequestSort={handlers.onChangeSort}
      onBookingOfferAction={onActionClick}
      onBookingOfferClick={onBookingOfferClick}
      getCommonActions={bookingOffer => getBookingOfferCommonActions(bookingOffer, tabActions)}
      getActions={bookingOffer => getBookingOfferTableActions(lifecycle, bookingOffer, tabActions)}
    />
  );
};
