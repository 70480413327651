import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TabsPanel from '../../../../../components/common/tabs/panel';
import { ClientOrgUserTableTab } from '../../components/tab';
import { EClientOrgUserTableTab } from '../../types';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { clientOrgUsersTabsCounterSelector } from '../store/selectors';
import { getClientOrgUsersTableTabName } from '../utils';

interface ClientOrgUsersTableTabsAdapterProps {
  readonly tab: EClientOrgUserTableTab;
}

export const ClientOrgUsersTableTabsAdapter = (props: ClientOrgUsersTableTabsAdapterProps) => {
  const { tab } = props;

  const { onChangeTab } = useContextHandlers();
  const { tabs } = useContextConfig();

  const counter = useSelector(clientOrgUsersTabsCounterSelector);

  return useMemo(
    () => (
      <TabsPanel
        tab={tab}
        tabs={tabs}
        labelAdapter={ClientOrgUserTableTab}
        data={{
          counts: counter,
          getTabName: getClientOrgUsersTableTabName,
        }}
        onChangeTab={onChangeTab}
      />
    ),
    [tab, tabs, counter, onChangeTab]
  );
};
