import { SportOption } from '../../../../../domain/model';
import { ESportEventApplicationStatus, ESportEventClassificationType } from '../../../../../domain/model/enums';
import {
  TournamentClassificationResultsRequest,
  TournamentIndividualMemberListView,
  TournamentIndividualResults,
  TournamentIndividualResultsRequest,
  TournamentResultsRequest,
} from '../../../../../domain/model/event';
import { Nullable } from '../../../../../domain/model/types';
import { SportUserProfileShort } from '../../../../../domain/model/user';
import { EEventStep, TournamentIndividualViewStepType } from '../../types';
import { ETournamentIndividualViewApplicationsTabs } from '../../tournamentIndividualApplication';

export interface TournamentIndividualNominationMember extends SportOption {
  readonly user: TournamentIndividualMemberListView;
}

export const tournamentWinnersMaxCount = 3;

export const getTournamentIndividualViewStepText = (step: TournamentIndividualViewStepType): string => {
  switch (step) {
    case EEventStep.General:
      return 'Описание мероприятия';
    case EEventStep.Results:
      return 'Результаты';
    case EEventStep.Chat:
      return 'Чат';
    case EEventStep.Applications:
      return 'Участники';
  }
};

const userProfileConvertToUserSportOption = (profile: SportUserProfileShort): SportOption => {
  const { lastName, firstName, id, photo } = profile;
  return {
    id,
    name: [firstName, lastName].join(' '),
    image: photo?.path,
  };
};

export const tournamentIndividualMemberConvertToUserSportOption = (
  profiles: TournamentIndividualMemberListView[]
): SportOption[] => {
  return profiles.map(profile => {
    return {
      id: profile.id,
      name: profile.userProfile.name,
      image: profile.userProfile.image,
      code: String(profile.place),
    };
  });
};

export const convertTournamentIndividualResultsToTournamentResultsRequest = (
  results: TournamentIndividualResults
): TournamentIndividualResultsRequest => {
  const { maleResults, femaleResults, mixedResults, resultsFile } = results;

  return {
    maleResults: maleResults
      ? {
          nominations:
            maleResults?.nominations?.map(nomination => ({
              ...nomination,
              user: userProfileConvertToUserSportOption(nomination.user),
            })) ?? null,
          results:
            maleResults?.results?.map(userResult => ({
              ...userResult,
              user: userProfileConvertToUserSportOption(userResult.user),
            })) ?? null,
        }
      : null,
    femaleResults: femaleResults
      ? {
          nominations:
            femaleResults?.nominations?.map(nomination => ({
              ...nomination,
              user: userProfileConvertToUserSportOption(nomination.user),
            })) ?? null,
          results:
            femaleResults?.results?.map(userResult => ({
              ...userResult,
              user: userProfileConvertToUserSportOption(userResult.user),
            })) ?? null,
        }
      : null,
    mixedResults: mixedResults
      ? {
          nominations:
            mixedResults?.nominations?.map(nomination => ({
              ...nomination,
              user: userProfileConvertToUserSportOption(nomination.user),
            })) ?? null,
          results:
            mixedResults?.results?.map(userResult => ({
              ...userResult,
              user: userProfileConvertToUserSportOption(userResult.user),
            })) ?? null,
        }
      : null,
    resultsFile,
  };
};

export const getTournamentClassificationResults = (
  classification: ESportEventClassificationType,
  results: TournamentResultsRequest
): Nullable<TournamentClassificationResultsRequest> => {
  switch (classification) {
    case ESportEventClassificationType.Male:
      return results.maleResults;
    case ESportEventClassificationType.Female:
      return results.femaleResults;
    case ESportEventClassificationType.Mixed:
      return results.mixedResults;
  }
};

export const tournamentIndividualViewGetStatusByTab = (
  tab: ETournamentIndividualViewApplicationsTabs
): Nullable<ESportEventApplicationStatus> => {
  switch (tab) {
    case ETournamentIndividualViewApplicationsTabs.All: {
      return null;
    }
    case ETournamentIndividualViewApplicationsTabs.New: {
      return ESportEventApplicationStatus.New;
    }
    case ETournamentIndividualViewApplicationsTabs.Accepted: {
      return ESportEventApplicationStatus.Accepted;
    }
    case ETournamentIndividualViewApplicationsTabs.Rejected: {
      return ESportEventApplicationStatus.Rejected;
    }
    case ETournamentIndividualViewApplicationsTabs.Cancelled: {
      return ESportEventApplicationStatus.Cancelled;
    }
  }
};

export const tournamentIndividualViewGetApplicationLabelTabs = (
  tab: ETournamentIndividualViewApplicationsTabs
): string => {
  switch (tab) {
    case ETournamentIndividualViewApplicationsTabs.All:
      return 'Все';
    case ETournamentIndividualViewApplicationsTabs.New:
      return 'На рассмотрении';
    case ETournamentIndividualViewApplicationsTabs.Accepted:
      return 'Принято';
    case ETournamentIndividualViewApplicationsTabs.Rejected:
      return 'Отказано';
    case ETournamentIndividualViewApplicationsTabs.Cancelled:
      return 'Отозвано';
  }
};
