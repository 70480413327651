import { MPNaturalNumberInput } from '@ui-kit/input';
import { AdCampaignCreateFieldCommonProps } from '../../types';

const fieldName = 'budget';
const AdCampaignCreateFieldBudget = (props: AdCampaignCreateFieldCommonProps<typeof fieldName>) => {
  const { value, validation, disabled, onChangeAttribute, onValidateAttribute } = props;

  return (
    <MPNaturalNumberInput
      label='Бюджет кампании'
      value={value}
      disabled={disabled}
      error={validation?.hasError}
      helperText={validation?.message}
      onChange={(event, newValue) => onChangeAttribute(fieldName, newValue)}
      onBlur={() => onValidateAttribute(fieldName)}
    />
  );
};

export default AdCampaignCreateFieldBudget;
