import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrderFull } from '../../../../domain/model/order';
import { UUID } from '../../../../domain/model/types';
import ContentLoader from '../../../components/common/loader';
import { ContainerWrapper } from './controls';
import { orderByIdSelector } from './store/selectors';
import { orderByIdFetch, orderByIdStateReset } from './store/slice';

interface OrderByIdContainerResult {
  readonly order: OrderFull;
}

interface OrderByIdContainerProps {
  readonly id: UUID;
  readonly children: (result: OrderByIdContainerResult) => React.ReactNode;
}

const OrderByIdContainer = ({ id, children }: OrderByIdContainerProps) => {
  const dispatch = useDispatch();

  const { order, isFetching } = useSelector(orderByIdSelector);

  useEffect(() => {
    dispatch(orderByIdFetch(id));

    return () => {
      dispatch(orderByIdStateReset());
    };
  }, [dispatch, id]);

  if (isFetching || !order) {
    return (
      <ContainerWrapper>
        <ContentLoader />
      </ContainerWrapper>
    );
  }

  return <>{children({ order })}</>;
};

export default OrderByIdContainer;
