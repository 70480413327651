import React from 'react';
import { SupportInfoMpType, SupportInfoSportType } from '../../../../../system/appConfigurator';

export interface BusinessConfigContextType {
  readonly supportInfo: {
    readonly termsOfUseUrl: string;
    readonly personalDataAgreementUrl: string;
    readonly personalDataPolicyUrl: string;
    readonly mp: SupportInfoMpType;
    readonly sport: SupportInfoSportType;
  };
  readonly trustedDomains: string[];
}

const BusinessConfigContext = React.createContext<BusinessConfigContextType>({} as BusinessConfigContextType);

export default BusinessConfigContext;
