import { ClientOrg } from '@/domain';
import { MPAutocompleteMultipleSelectItem } from '@ui-kit/autocomplete/types';

export enum OrgStructureClientOrgMultipleItemType {
  All = 'all',
  Text = 'text',
  ClientOrg = 'clientOrg',
  None = 'none',
}

export enum OrgStructureClientOrgMultipleSelectType {
  All = 'all',
  None = 'none',
}

export enum OrgStructureClientOrgMultipleAllClientOrgsState {
  Indeterminate = 'indeterminate',
  Checked = 'checked',
  NotChecked = 'not-checked',
}

export type OrgStructureClientOrgMultipleTypesSelectorItem =
  | (ClientOrg & { type: OrgStructureClientOrgMultipleItemType.ClientOrg })
  | (MPAutocompleteMultipleSelectItem & { type: OrgStructureClientOrgMultipleItemType.Text })
  | (MPAutocompleteMultipleSelectItem & {
      readonly type: OrgStructureClientOrgMultipleItemType.All;
      readonly state: OrgStructureClientOrgMultipleAllClientOrgsState;
    })
  | (MPAutocompleteMultipleSelectItem & {
      readonly type: OrgStructureClientOrgMultipleItemType.None;
      readonly state: OrgStructureClientOrgMultipleAllClientOrgsState;
    });
