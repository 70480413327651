import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { ServerErrorResponse } from '../../../../../data/network/types';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../data/store/types';
import { SportOption } from '../../../../../domain/model';
import { CorpOffer } from '../../../../../domain/model/corpOffer';
import { ApprovalOfferRegistryRequest } from '../../../../../domain/model/offerApprovalRegistry';
import { PartnerView } from '../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { ValidationResult } from '../../../../utils/validation';
import offerServices from '../../../general/offer/services';
import { ECorpOfferStep } from '../../types';

export const corpOfferDetailsViewed = createAsyncThunk<void, UUID, AppThunkAPIConfig>(
  'corpOffer/details/viewed',
  async id => {
    try {
      await offerServices.common.makeViewed({ id });
    } catch (e: any) {
      console.error(`Error at call user event`, e);
    }
  }
);

export const corpOfferDetailsByIdFetch = createAsyncThunk<
  { corpOffer: CorpOffer; partner: PartnerView },
  { id: UUID },
  AppThunkAPIConfig
>('corpOffer/details/byId/fetch', async ({ id }, { rejectWithValue, dispatch }) => {
  try {
    const corpOffer = await offerServices.corp.one({ id });
    dispatch(corpOfferDetailsViewed(id));
    return { corpOffer, partner: corpOffer.partner };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOfferDetailsPromotionsCountFetch = createAsyncThunk<CorpOffer, { id: UUID }, AppThunkAPIConfig>(
  'corpOffer/details/promotionsCount/fetch',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await offerServices.corp.one({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOfferDetailsChangeModerator = createAsyncThunk<
  CorpOffer,
  {
    id: UUID;
    userId: UUID;
  },
  AppThunkAPIConfig
>('corpOffer/details/changeModerator', async ({ id, userId }, { rejectWithValue }) => {
  try {
    return await offerServices.corp.inWork({ id, userId });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOfferDetailsResume = createAsyncThunk<CorpOffer, { id: UUID }, AppThunkAPIConfig>(
  'corpOffer/details/resume',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await offerServices.corp.resume({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOfferDetailsArchive = createAsyncThunk<
  CorpOffer,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('corpOffer/details/archive', async ({ id }, { rejectWithValue }) => {
  try {
    return await offerServices.corp.archive({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOfferDetailsPause = createAsyncThunk<
  CorpOffer,
  { id: UUID; reason?: SportOption; comment?: string },
  AppThunkAPIConfig<ServerErrorResponse>
>('corpOffer/details/pause', async ({ id, reason, comment }, { rejectWithValue }) => {
  try {
    return await offerServices.corp.pause({ id, reason, comment });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOfferDetailsNotUsedCodesDelete = createAsyncThunk<CorpOffer, { id: UUID }, AppThunkAPIConfig>(
  'corpOffer/details/codesFile/delete',
  async ({ id }, { rejectWithValue }) => {
    try {
      await Api.personalPromotion.notUsedDelete({ offerId: id });
      return await offerServices.corp.one({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOfferDetailsApprove = createAsyncThunk<
  CorpOffer,
  { corpOffer: CorpOffer; approvalRegistry: Nullable<ApprovalOfferRegistryRequest> },
  AppThunkAPIConfig
>('corpOffer/details/approve', async ({ corpOffer, approvalRegistry }, { rejectWithValue }) => {
  try {
    const savedCorpOffer = await offerServices.corp.applyData({
      data: corpOffer,
      approvalRegistry,
    });
    return await offerServices.corp.approve({ id: savedCorpOffer.id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOfferDetailsReject = createAsyncThunk<
  CorpOffer,
  { id: UUID; reason: SportOption; comment?: string },
  AppThunkAPIConfig
>('corpOffer/details/reject', async ({ id, reason, comment }, { rejectWithValue }) => {
  try {
    return await offerServices.corp.reject({ id, reason, comment });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOfferDetailsUnPublish = createAsyncThunk<CorpOffer, { id: UUID }, AppThunkAPIConfig>(
  'corpOffer/details/unPublish',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await offerServices.corp.unPublish({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOfferDetailsDuplicate = createAsyncThunk<CorpOffer, { id: UUID }, AppThunkAPIConfig>(
  'corpOffer/details/duplicate',
  async (prop, { rejectWithValue }) => {
    try {
      return await offerServices.corp.duplicate(prop);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOfferDetailsRetrieve = createAsyncThunk<
  CorpOffer,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('corpOffer/details/retrieve', async ({ id }, { rejectWithValue }) => {
  try {
    return await offerServices.corp.retrieve({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface CorpOfferDetailsValidationStep {
  readonly results: Nullable<ValidationResult<CorpOffer & ApprovalOfferRegistryRequest>>;
  readonly validateOnChange: boolean;
}

export type CorpOfferDetailsValidation = Record<ECorpOfferStep, CorpOfferDetailsValidationStep>;

export interface CorpOfferDetailsState {
  readonly byId: Fetchable & {
    readonly corpOffer: Nullable<CorpOffer>;
    readonly loadedCorpOffer: Nullable<CorpOffer>;
    readonly partner: Nullable<PartnerView>;
  };
  readonly validation: CorpOfferDetailsValidation;
  readonly notUsedCodesDelete: Fetchable;
  readonly duplicate: Fetchable;
  readonly retrieve: Fetchable;
  readonly resume: Fetchable;
  readonly changeModerator: Fetchable;
  readonly archive: Fetchable;
  readonly assignToMeChecking: Fetchable;
  readonly approve: Fetchable;
  readonly reject: Fetchable;
  readonly pause: Fetchable;
  readonly unPublish: Fetchable;
  readonly dialogs: {
    readonly history: Nullable<CorpOffer>;
    readonly changeModerator: Nullable<CorpOffer>;
    readonly reject: Nullable<CorpOffer>;
    readonly pause: Nullable<CorpOffer>;
    readonly resume: Nullable<CorpOffer>;
    readonly unPublish: Nullable<CorpOffer>;
    readonly createCodes: Nullable<CorpOffer>;
    readonly deleteCodes: Nullable<CorpOffer>;
  };
}

type Reducer<T = undefined> = CaseReducer<CorpOfferDetailsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CorpOfferDetailsState> {
  corpOfferDetailsStateReset: Reducer;
  corpOfferDetailsNotUsedCodesDeleteReset: Reducer;
  corpOfferDetailsSetApprovalRegistryAttribute: Reducer<{ name: keyof ApprovalOfferRegistryRequest; value: any }>;
  corpOfferDetailsSetValidation: Reducer<{
    step: ECorpOfferStep;
    results: Nullable<ValidationResult<any>>;
    validateOnChange?: boolean;
  }>;
  corpOfferDetailsClearValidation: Reducer;
  corpOfferDetailsSetValidationValidateOnChange: Reducer<{ step: ECorpOfferStep; validateOnChange: boolean }>;
  corpOfferDetailsSetDialogState: Reducer<{
    name: keyof CorpOfferDetailsState['dialogs'];
    data: Nullable<CorpOffer>;
  }>;
}

const slice = createSlice<CorpOfferDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    byId: {
      ...fetchableDefault,
      corpOffer: null,
      loadedCorpOffer: null,
      partner: null,
    },
    validation: {
      [ECorpOfferStep.General]: {
        results: null,
        validateOnChange: false,
      },
      [ECorpOfferStep.Description]: {
        results: null,
        validateOnChange: false,
      },
      [ECorpOfferStep.Conditions]: {
        results: null,
        validateOnChange: false,
      },
      [ECorpOfferStep.PromoCodes]: {
        results: null,
        validateOnChange: false,
      },
    },
    duplicate: {
      ...fetchableDefault,
    },
    retrieve: {
      ...fetchableDefault,
    },
    notUsedCodesDelete: {
      ...fetchableDefault,
    },
    resume: {
      ...fetchableDefault,
    },
    changeModerator: {
      ...fetchableDefault,
    },
    archive: {
      ...fetchableDefault,
    },
    assignToMeChecking: {
      ...fetchableDefault,
    },
    approve: {
      ...fetchableDefault,
    },
    reject: {
      ...fetchableDefault,
    },
    pause: {
      ...fetchableDefault,
    },
    unPublish: {
      ...fetchableDefault,
    },
    dialogs: {
      history: null,
      changeModerator: null,
      reject: null,
      pause: null,
      resume: null,
      createCodes: null,
      deleteCodes: null,
      unPublish: null,
    },
  },
  reducers: {
    corpOfferDetailsNotUsedCodesDeleteReset: state => {
      state.notUsedCodesDelete = {
        ...fetchableDefault,
      };
    },
    corpOfferDetailsSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
    corpOfferDetailsStateReset: state => {
      state.byId = {
        ...fetchableDefault,
        corpOffer: null,
        loadedCorpOffer: null,
        partner: null,
      };
      state.notUsedCodesDelete = {
        ...fetchableDefault,
      };
      state.resume = fetchableDefault;
      state.archive = fetchableDefault;
      state.assignToMeChecking = fetchableDefault;
      state.approve = fetchableDefault;
      state.reject = fetchableDefault;
      state.pause = fetchableDefault;
      state.unPublish = fetchableDefault;
      state.duplicate = fetchableDefault;
      state.retrieve = fetchableDefault;

      state.dialogs.history = null;
      state.dialogs.changeModerator = null;
      state.dialogs.reject = null;
      state.dialogs.pause = null;
      state.dialogs.resume = null;
    },
    corpOfferDetailsSetApprovalRegistryAttribute: (state, { payload }) => {
      const { name, value } = payload;
      if (!state.byId.corpOffer!.approvalRegistry) {
        state.byId.corpOffer!.approvalRegistry = {
          id: '',
          number: null,
          date: null,
        };
      }
      state.byId.corpOffer!.approvalRegistry[name] = value;
    },
    corpOfferDetailsClearValidation: state => {
      state.validation = {
        [ECorpOfferStep.General]: {
          results: null,
          validateOnChange: false,
        },
        [ECorpOfferStep.Description]: {
          results: null,
          validateOnChange: false,
        },
        [ECorpOfferStep.Conditions]: {
          results: null,
          validateOnChange: false,
        },
        [ECorpOfferStep.PromoCodes]: {
          results: null,
          validateOnChange: false,
        },
      };
    },
    corpOfferDetailsSetValidation: (state, { payload }) => {
      const { step, results, validateOnChange } = payload;
      state.validation[step].results = results;
      if (validateOnChange !== undefined) {
        state.validation[step].validateOnChange = validateOnChange;
      }
    },
    corpOfferDetailsSetValidationValidateOnChange: (state, { payload }) => {
      const { step, validateOnChange } = payload;
      state.validation[step].validateOnChange = validateOnChange;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(corpOfferDetailsByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.corpOffer = null;
        state.byId.loadedCorpOffer = null;
        state.byId.partner = null;
      })
      .addCase(corpOfferDetailsByIdFetch.fulfilled, (state, { payload }) => {
        const { corpOffer, partner } = payload;
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.corpOffer = corpOffer;
        state.byId.loadedCorpOffer = corpOffer;
        state.byId.partner = partner;
      })
      .addCase(corpOfferDetailsByIdFetch.rejected, state => {
        state.byId.isFetching = false;
        state.byId.isFetched = false;
        state.byId.isFailed = true;
        state.byId.corpOffer = null;
        state.byId.loadedCorpOffer = null;
        state.byId.partner = null;
      })
      .addCase(corpOfferDetailsNotUsedCodesDelete.pending, state => {
        state.notUsedCodesDelete = {
          ...fetchableFetching,
        };
      })
      .addCase(corpOfferDetailsNotUsedCodesDelete.fulfilled, (state, { payload }) => {
        state.notUsedCodesDelete = {
          ...fetchableFetched,
        };
        state.byId.corpOffer = payload;
        state.byId.loadedCorpOffer = payload;
      })
      .addCase(corpOfferDetailsNotUsedCodesDelete.rejected, state => {
        state.notUsedCodesDelete = {
          ...fetchableFailed,
        };
      })
      .addCase(corpOfferDetailsPromotionsCountFetch.fulfilled, (state, { payload }) => {
        if (state.byId.corpOffer) {
          state.byId.corpOffer.offerCount = payload.offerCount;
          state.byId.corpOffer.notUsedOfferCount = payload.notUsedOfferCount;
        }
      })
      .addCase(corpOfferDetailsResume.pending, state => {
        state.resume = fetchableFetching;
      })
      .addCase(corpOfferDetailsResume.fulfilled, (state, { payload }) => {
        state.resume = fetchableFetched;
        state.byId.corpOffer = payload;
        state.byId.loadedCorpOffer = payload;
      })
      .addCase(corpOfferDetailsResume.rejected, state => {
        state.resume = fetchableFailed;
      })
      .addCase(corpOfferDetailsChangeModerator.pending, state => {
        state.changeModerator = fetchableFetching;
      })
      .addCase(corpOfferDetailsChangeModerator.fulfilled, (state, { payload }) => {
        state.changeModerator = fetchableFetched;
        state.byId.corpOffer = payload;
        state.byId.loadedCorpOffer = payload;
      })
      .addCase(corpOfferDetailsChangeModerator.rejected, state => {
        state.changeModerator = fetchableFailed;
      })
      .addCase(corpOfferDetailsArchive.pending, state => {
        state.archive = fetchableFetching;
      })
      .addCase(corpOfferDetailsArchive.fulfilled, state => {
        state.archive = fetchableFetched;
      })
      .addCase(corpOfferDetailsArchive.rejected, state => {
        state.archive = fetchableFailed;
      })
      .addCase(corpOfferDetailsApprove.pending, state => {
        state.approve = fetchableFetching;
      })
      .addCase(corpOfferDetailsApprove.fulfilled, (state, { payload }) => {
        state.approve = fetchableFetched;
        state.byId.corpOffer = payload;
        state.byId.loadedCorpOffer = payload;
      })
      .addCase(corpOfferDetailsApprove.rejected, state => {
        state.approve = fetchableFailed;
      })
      .addCase(corpOfferDetailsReject.pending, state => {
        state.reject = fetchableFetching;
      })
      .addCase(corpOfferDetailsReject.fulfilled, (state, { payload }) => {
        state.reject = fetchableFetched;
        state.byId.corpOffer = payload;
        state.byId.loadedCorpOffer = payload;
      })
      .addCase(corpOfferDetailsReject.rejected, state => {
        state.reject = fetchableFailed;
      })
      .addCase(corpOfferDetailsPause.pending, state => {
        state.pause = fetchableFetching;
      })
      .addCase(corpOfferDetailsPause.fulfilled, (state, { payload }) => {
        state.pause = fetchableFetched;
        state.byId.corpOffer = payload;
        state.byId.loadedCorpOffer = payload;
      })
      .addCase(corpOfferDetailsPause.rejected, state => {
        state.pause = fetchableFailed;
      })
      .addCase(corpOfferDetailsUnPublish.pending, state => {
        state.unPublish = fetchableFetching;
      })
      .addCase(corpOfferDetailsUnPublish.fulfilled, (state, { payload }) => {
        state.unPublish = fetchableFetched;
        state.byId.corpOffer = payload;
        state.byId.loadedCorpOffer = payload;
      })
      .addCase(corpOfferDetailsUnPublish.rejected, state => {
        state.unPublish = fetchableFailed;
      })
      .addCase(corpOfferDetailsDuplicate.pending, state => {
        state.duplicate = fetchableFetching;
      })
      .addCase(corpOfferDetailsDuplicate.fulfilled, state => {
        state.duplicate = fetchableFetched;
      })
      .addCase(corpOfferDetailsDuplicate.rejected, state => {
        state.duplicate = fetchableFailed;
      })
      .addCase(corpOfferDetailsRetrieve.pending, state => {
        state.retrieve = fetchableFetching;
      })
      .addCase(corpOfferDetailsRetrieve.fulfilled, (state, { payload }) => {
        state.retrieve = fetchableFetched;
        state.byId.corpOffer = payload;
        state.byId.loadedCorpOffer = payload;
      })
      .addCase(corpOfferDetailsRetrieve.rejected, state => {
        state.retrieve = fetchableFailed;
      });
  },
});

export const {
  corpOfferDetailsStateReset,
  corpOfferDetailsNotUsedCodesDeleteReset,
  corpOfferDetailsSetApprovalRegistryAttribute,
  corpOfferDetailsSetValidation,
  corpOfferDetailsSetValidationValidateOnChange,
  corpOfferDetailsClearValidation,
  corpOfferDetailsSetDialogState,
} = slice.actions;

export default slice.reducer;
