import ChangeEmailSuccessDialog from '../successDialog';
import { useUserProfileEmailEdit } from '../hooks/useEmailEdit';

type UserSuccessEmailUpdateAdapterProps = {
  readonly onNext: () => void;
};

export const UserSuccessEmailUpdateAdapter = (props: UserSuccessEmailUpdateAdapterProps) => {
  const { onNext } = props;

  const { email } = useUserProfileEmailEdit();

  return (
    <ChangeEmailSuccessDialog
      open
      email={email}
      onClose={onNext}
    />
  );
};
