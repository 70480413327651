import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isBookingOffersAnyChangedSelector } from '../store/selectors';
import { bookingOfferActionsOptimize } from '../store/slice';

const BookingOfferActionsOptimizerAdapter = () => {
  const dispatch = useDispatch();
  const needOptimize = useSelector(isBookingOffersAnyChangedSelector);

  useEffect(() => {
    if (needOptimize > 0) {
      dispatch(bookingOfferActionsOptimize());
    }
  }, [needOptimize]);

  return null;
};

export default BookingOfferActionsOptimizerAdapter;
