import { RootState } from '../../../../../../data/store/store';

export const bookingServicePriceUnitsEditDataSelector = (store: RootState) =>
  store.dictionary.bookingServicePriceUnit.edit.fetch.data;

export const bookingServicePriceUnitsEditIsPreloadingSelector = (store: RootState) =>
  store.dictionary.bookingServicePriceUnit.edit.fetch.isFetching;

export const bookingServicePriceUnitsEditIsLoadingSelector = (store: RootState) =>
  store.dictionary.bookingServicePriceUnit.edit.fetch.isFetching;

export const bookingServicePriceUnitsEditDialogsStateSelector = (store: RootState) =>
  store.dictionary.bookingServicePriceUnit.edit.dialogs; 
