import { ListItemText } from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { useMemo } from 'react';
import { Partner } from '../../../../../../domain/model/partner';
import { MenuPanelCommonHeader } from '../../../../../components/common/menuPanel/header';
import { HeaderItem, LogoIconWrapper, UserType } from '../controls';

interface PartnerManagementMenuHeaderAdapterProps {
  readonly partner: Partner;
  readonly goBack: () => void;
}

export const PartnerManagementMenuHeaderAdapter = (props: PartnerManagementMenuHeaderAdapterProps) => {
  const { partner, goBack } = props;

  return useMemo(() => {
    return (
      <MenuPanelCommonHeader>
        <HeaderItem onClick={goBack}>
          <LogoIconWrapper>
            <ArrowBackIosOutlinedIcon fontSize='small' />
          </LogoIconWrapper>
          <ListItemText primary={<UserType variant='subtitle2'>{partner.name}</UserType>} />
        </HeaderItem>
      </MenuPanelCommonHeader>
    );
  }, [partner, goBack]);
};
