import Api from '@/data/api';
import ErrorHandler from '@/data/network/errorHandler';
import { ServerErrorResponse } from '@/data/network/types';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault, fetchableFailed, fetchableFetched, fetchableFetching } from '@/data/store/types';
import { Nullable, SportOption, TradeOffer, UUID } from '@/domain';
import offerServices from '@features/general/offer/services';
import { EOfferActionType } from '@features/general/offer/types';
import {
  personalPromotionCreateGenerate,
  personalPromotionCreateUpload,
} from '@features/personalPromotion/create/store/slice';
import { CaseReducer, createAsyncThunk, createSlice, Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

const handleActionProcess = (
  state: Draft<TradeOffersActionsState>,
  id: UUID,
  actionType: EOfferActionType,
  fetchable: Fetchable,
  error: Nullable<ServerErrorResponse | undefined> = null
) => {
  if (id) {
    let process = state.actions.find(change => change.id === id);

    if (process) {
      process.id = id;
      process.type = actionType;
      process.error = error ?? null;
      process.isFetching = fetchable.isFetching;
      process.isFetched = fetchable.isFetched;
      process.isFailed = fetchable.isFailed;
    } else {
      process = {
        ...fetchable,
        id,
        type: actionType,
        error: null,
      };

      state.actions.push(process);
    }
  } else {
    state.create = fetchable;
  }
};

export const tradeOfferActionsSave = createAsyncThunk<TradeOffer, TradeOffer, AppThunkAPIConfig<ServerErrorResponse>>(
  'tradeOffer/actions/save',
  async (offer, { rejectWithValue }) => {
    try {
      return await offerServices.trade.save(offer);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tradeOfferActionsSaveNewVersion = createAsyncThunk<
  TradeOffer,
  TradeOffer,
  AppThunkAPIConfig<ServerErrorResponse>
>('tradeOffer/actions/saveNewVersion', async (offer, { rejectWithValue }) => {
  try {
    return await offerServices.trade.saveNewVersion(offer);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tradeOfferActionsArchive = createAsyncThunk<
  TradeOffer,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('tradeOffer/actions/archive', async ({ id }, { rejectWithValue }) => {
  try {
    return await offerServices.trade.archive({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tradeOfferActionsRetrieve = createAsyncThunk<
  TradeOffer,
  { id: UUID; endDate: string },
  AppThunkAPIConfig<ServerErrorResponse>
>('tradeOffer/actions/retrieve', async (props, { rejectWithValue }) => {
  try {
    return await offerServices.trade.retrieve(props);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tradeOfferActionsPause = createAsyncThunk<
  TradeOffer,
  { id: UUID; reason?: SportOption; comment?: string },
  AppThunkAPIConfig<ServerErrorResponse>
>('tradeOffer/actions/pause', async ({ id, reason, comment }, { rejectWithValue }) => {
  try {
    return await offerServices.trade.pause({ id, reason, comment });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tradeOfferActionsApprove = createAsyncThunk<
  TradeOffer,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('tradeOffer/actions/approve', async ({ id }, { rejectWithValue }) => {
  try {
    return await offerServices.trade.approve({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tradeOfferActionsReturnToVerification = createAsyncThunk<
  TradeOffer,
  { id: UUID; userId: UUID },
  AppThunkAPIConfig
>('tradeOffer/actions/returnToVerification', async ({ id, userId }, { rejectWithValue }) => {
  try {
    return await offerServices.trade.inWork({ id, userId });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tradeOfferActionsChangeModerator = createAsyncThunk<
  TradeOffer,
  {
    id: UUID;
    userId: UUID;
  },
  AppThunkAPIConfig
>('tradeOffer/actions/changeModerator', async ({ id, userId }, { rejectWithValue }) => {
  try {
    return await offerServices.trade.inWork({ id, userId });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tradeOfferActionsResume = createAsyncThunk<
  TradeOffer,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('tradeOffer/actions/resume', async ({ id }, { rejectWithValue }) => {
  try {
    return await offerServices.trade.resume({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tradeOfferActionsDelete = createAsyncThunk<void, { id: UUID }, AppThunkAPIConfig<ServerErrorResponse>>(
  'tradeOffer/actions/delete',
  async ({ id }, { rejectWithValue }) => {
    try {
      await offerServices.trade.delete({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tradeOfferActionsDuplicate = createAsyncThunk<TradeOffer, { id: UUID }, AppThunkAPIConfig>(
  'tradeOffer/actions/duplicate',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await offerServices.trade.duplicate({
        id,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tradeOfferActionsNotUsedCodesDelete = createAsyncThunk<TradeOffer, { id: UUID }, AppThunkAPIConfig>(
  'tradeOffer/actions/notUsedDelete',
  async ({ id }, { rejectWithValue }) => {
    try {
      await Api.personalPromotion.notUsedDelete({ offerId: id });
      return await offerServices.trade.one({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tradeOfferActionsUnPublish = createAsyncThunk<TradeOffer, { id: UUID }, AppThunkAPIConfig>(
  'tradeOffer/actions/unPublish',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await offerServices.trade.unPublish({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tradeOfferActionsPublish = createAsyncThunk<TradeOffer, { id: UUID }, AppThunkAPIConfig>(
  'tradeOffer/actions/publish',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await offerServices.trade.publish({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const tradeOfferActionsReject = createAsyncThunk<
  TradeOffer,
  { id: UUID; reason: SportOption; comment?: string },
  AppThunkAPIConfig
>('tradeOffer/actions/reject', async ({ id, reason, comment }, { rejectWithValue }) => {
  try {
    return await offerServices.trade.reject({ id, reason, comment });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const tradeOfferActionsInWork = createAsyncThunk<TradeOffer, { id: UUID; userId: UUID }, AppThunkAPIConfig>(
  'tradeOffer/actions/inWork',
  async ({ id, userId }, { rejectWithValue }) => {
    try {
      return await offerServices.trade.inWork({ id, userId });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export type DialogData<T> = {
  data: Nullable<T>;
  notification?: boolean;
};

export type TradeOffersActionsState = {
  readonly actions: (Fetchable & {
    id: UUID;
    type: EOfferActionType;
    error: Nullable<ServerErrorResponse>;
  })[];
  readonly create: Fetchable;
  readonly dialogs: {
    readonly publish: DialogData<TradeOffer>;
    readonly pause: DialogData<TradeOffer>;
    readonly reject: DialogData<TradeOffer>;
    readonly archive: DialogData<TradeOffer>;
    readonly delete: DialogData<TradeOffer>;
    readonly resume: DialogData<TradeOffer>;
    readonly approve: DialogData<TradeOffer>;
    readonly duplicate: DialogData<TradeOffer>;
    readonly retrieve: DialogData<TradeOffer>;
    readonly createCodes: DialogData<TradeOffer>;
    readonly deleteCodes: DialogData<TradeOffer>;
    readonly unPublishAndEdit: DialogData<TradeOffer>;
    readonly unPublish: DialogData<TradeOffer>;
    readonly changeModerator: DialogData<TradeOffer>;
    readonly returnToVerification: DialogData<TradeOffer>;
    readonly changeSortIndex: DialogData<TradeOffer>;
    readonly inWork: DialogData<TradeOffer>;
    readonly downloadCodes: DialogData<TradeOffer>;
  };
};

export type TradeOfferStateKeyDialogs = keyof TradeOffersActionsState['dialogs'];

interface Reducers extends SliceCaseReducers<TradeOffersActionsState> {
  tradeOfferActionsChangeDialogState: Reducer<{
    name: keyof TradeOffersActionsState['dialogs'];
    data: DialogData<TradeOffer>;
  }>;
  tradeOfferActionsOptimize: Reducer;
}

type Reducer<T = undefined> = CaseReducer<TradeOffersActionsState, PayloadAction<T>>;

const dataEmpty = { data: null };

export const initialState: TradeOffersActionsState = {
  actions: [],
  create: fetchableDefault,
  dialogs: {
    publish: dataEmpty,
    pause: dataEmpty,
    reject: dataEmpty,
    archive: dataEmpty,
    delete: dataEmpty,
    resume: dataEmpty,
    duplicate: dataEmpty,
    changeSortIndex: dataEmpty,
    approve: dataEmpty,
    retrieve: dataEmpty,
    changeModerator: dataEmpty,
    returnToVerification: dataEmpty,
    createCodes: dataEmpty,
    deleteCodes: dataEmpty,
    unPublish: dataEmpty,
    unPublishAndEdit: dataEmpty,
    inWork: dataEmpty,
    downloadCodes: dataEmpty,
  },
};

const slice = createSlice<TradeOffersActionsState, Reducers>({
  name: 'tradeOffer/actions',
  initialState,
  reducers: {
    tradeOfferActionsChangeDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
    tradeOfferActionsOptimize: state => {
      // Оставляем только выполняющиеся действия
      state.actions = state.actions.filter(action => action.isFetching);
    },
  },
  extraReducers: builder => {
    builder
      /** Save */
      .addCase(tradeOfferActionsSave.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Edit, fetchableFetching);
      })
      .addCase(tradeOfferActionsSave.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Edit, fetchableFetched);
      })
      .addCase(tradeOfferActionsSave.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Edit, fetchableFailed, payload);
      })
      /** Save New Version */
      .addCase(tradeOfferActionsSaveNewVersion.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.SaveNewVersion, fetchableFetching);
      })
      .addCase(tradeOfferActionsSaveNewVersion.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.SaveNewVersion, fetchableFetched);
      })
      .addCase(tradeOfferActionsSaveNewVersion.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.SaveNewVersion, fetchableFailed, payload);
      })
      /** Archive */
      .addCase(tradeOfferActionsArchive.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Archive, fetchableFetching);
      })
      .addCase(tradeOfferActionsArchive.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Archive, fetchableFetched);
      })
      .addCase(tradeOfferActionsArchive.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Archive, fetchableFailed, payload);
      })
      /** Retrieve */
      .addCase(tradeOfferActionsRetrieve.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Retrieve, fetchableFetching);
      })
      .addCase(tradeOfferActionsRetrieve.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Retrieve, fetchableFetched);
      })
      .addCase(tradeOfferActionsRetrieve.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Retrieve, fetchableFailed, payload);
      })
      /** Pause */
      .addCase(tradeOfferActionsPause.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Pause, fetchableFetching);
      })
      .addCase(tradeOfferActionsPause.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Pause, fetchableFetched);
      })
      .addCase(tradeOfferActionsPause.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Pause, fetchableFailed, payload);
      })
      /** Approve */
      .addCase(tradeOfferActionsApprove.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Approve, fetchableFetching);
      })
      .addCase(tradeOfferActionsApprove.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Approve, fetchableFetched);
      })
      .addCase(tradeOfferActionsApprove.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Approve, fetchableFailed, payload);
      })
      /** Return To verification */
      .addCase(tradeOfferActionsReturnToVerification.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.ReturnToVerification, fetchableFetching);
      })
      .addCase(tradeOfferActionsReturnToVerification.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.ReturnToVerification, fetchableFetched);
      })
      .addCase(tradeOfferActionsReturnToVerification.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.ReturnToVerification, fetchableFailed, payload);
      })
      /** ChangeModerator */
      .addCase(tradeOfferActionsChangeModerator.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.ChangeModerator, fetchableFetching);
      })
      .addCase(tradeOfferActionsChangeModerator.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.ChangeModerator, fetchableFetched);
      })
      .addCase(tradeOfferActionsChangeModerator.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.ChangeModerator, fetchableFailed, payload);
      })
      /** Resume */
      .addCase(tradeOfferActionsResume.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Resume, fetchableFetching);
      })
      .addCase(tradeOfferActionsResume.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Resume, fetchableFetched);
      })
      .addCase(tradeOfferActionsResume.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Resume, fetchableFailed, payload);
      })
      /** Delete */
      .addCase(tradeOfferActionsDelete.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Delete, fetchableFetching);
      })
      .addCase(tradeOfferActionsDelete.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Delete, fetchableFetched);
      })
      .addCase(tradeOfferActionsDelete.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Delete, fetchableFailed, payload);
      })
      /** Duplicate */
      .addCase(tradeOfferActionsDuplicate.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Duplicate, fetchableFetching);
      })
      .addCase(tradeOfferActionsDuplicate.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Duplicate, fetchableFetched);
      })
      .addCase(tradeOfferActionsDuplicate.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Duplicate, fetchableFailed, payload);
      })
      /** DeleteCodes */
      .addCase(tradeOfferActionsNotUsedCodesDelete.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.DeleteCodes, fetchableFetching);
      })
      .addCase(tradeOfferActionsNotUsedCodesDelete.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.DeleteCodes, fetchableFetched);
      })
      .addCase(tradeOfferActionsNotUsedCodesDelete.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.DeleteCodes, fetchableFailed, payload);
      })
      /** UnPublish */
      .addCase(tradeOfferActionsUnPublish.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.UnPublish, fetchableFetching);
      })
      .addCase(tradeOfferActionsUnPublish.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.UnPublish, fetchableFetched);
      })
      .addCase(tradeOfferActionsUnPublish.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.UnPublish, fetchableFailed, payload);
      })
      /** InWork */
      .addCase(tradeOfferActionsInWork.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.InWork, fetchableFetching);
      })
      .addCase(tradeOfferActionsInWork.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.InWork, fetchableFetched);
      })
      .addCase(tradeOfferActionsInWork.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.InWork, fetchableFailed, payload);
      })
      /** Reject */
      .addCase(tradeOfferActionsReject.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Reject, fetchableFetching);
      })
      .addCase(tradeOfferActionsReject.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Reject, fetchableFetched);
      })
      .addCase(tradeOfferActionsReject.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Reject, fetchableFailed, payload);
      })
      /** Publish */
      .addCase(tradeOfferActionsPublish.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Publish, fetchableFetching);
      })
      .addCase(tradeOfferActionsPublish.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Publish, fetchableFetched);
      })
      .addCase(tradeOfferActionsPublish.rejected, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Publish, fetchableFailed);
      })
      /** CreateCodes */
      .addCase(personalPromotionCreateGenerate.pending, (state, { meta }) => {
        const { offerId } = meta.arg;
        handleActionProcess(state, offerId, EOfferActionType.CreateCodes, fetchableFetching);
      })
      .addCase(personalPromotionCreateGenerate.fulfilled, (state, { meta }) => {
        const { offerId } = meta.arg;
        handleActionProcess(state, offerId, EOfferActionType.CreateCodes, fetchableFetched);
      })
      .addCase(personalPromotionCreateGenerate.rejected, (state, { meta }) => {
        const { offerId } = meta.arg;
        handleActionProcess(state, offerId, EOfferActionType.CreateCodes, fetchableFailed);
      })
      /** UploadCodes */
      .addCase(personalPromotionCreateUpload.pending, (state, { meta }) => {
        const { offerId } = meta.arg;
        handleActionProcess(state, offerId, EOfferActionType.CreateCodes, fetchableFetching);
      })
      .addCase(personalPromotionCreateUpload.fulfilled, (state, { meta }) => {
        const { offerId } = meta.arg;
        handleActionProcess(state, offerId, EOfferActionType.CreateCodes, fetchableFetched);
      })
      .addCase(personalPromotionCreateUpload.rejected, (state, { meta }) => {
        const { offerId } = meta.arg;
        handleActionProcess(state, offerId, EOfferActionType.CreateCodes, fetchableFailed);
      });
  },
});

export const { tradeOfferActionsChangeDialogState, tradeOfferActionsOptimize } = slice.actions;

export default slice.reducer;
