import { FCC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../data/store/store';
import { BookingOffer } from '../../../../../domain/model/booking';
import { Nullable } from '../../../../../domain/model/types';
import { addSearchParamToLocation } from '../../../../../routing/globalRouting';
import useHistoryExtensions from '../../../../hooks/useHistoryExtensions';
import { useBookingOfferActions } from '../../actions/useActions';
import { getBookingOfferTableTabByNameAction } from '../../actions/utils';
import { EBookingOfferUrlParam, getBookingOfferEditRoute, getBookingOffersTableRoute } from '../../entry';
import { EBookingActionType } from '../../types';
import { BookingOfferDetailsContainerProps } from '../container';
import { BookingOfferDetailsHandlersContext, BookingOfferDetailsHandlersContextValue } from '../context';
import { bookingOfferDetailsBookingOfferSelector } from '../store/selectors';
import { bookingOfferDetailsByIdFetch, bookingOfferDetailsSetDialogState } from '../store/slice';

export const BookingOfferDetailHandlersProvider: FCC<BookingOfferDetailsContainerProps> = ({ step, children, id }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();
  const {
    onTryPublish,
    onTryReject,
    onTryInWork,
    onTryApprove,
    onTryArchive,
    onTryDuplicate,
    onTryPause,
    onTryResume,
    onTryUnPublish,
    onTryChangeModerator,
    onTryReturnToVerification,
    onTryRetrieve,
    onTryServiceManage,
  } = useBookingOfferActions();

  /**
   * Получение объекта букинга
   */
  const getBookingOfferFromState = useCallback((): Nullable<BookingOffer> => {
    const state = store.getState();
    return bookingOfferDetailsBookingOfferSelector(state);
  }, [store]);

  /**
   * обновить данные объекта
   */
  const onRefresh = useCallback(() => {
    dispatch(bookingOfferDetailsByIdFetch({ id }));
  }, [dispatch]);

  /**
   * Изменить шаг
   */
  const onChangeStep = useCallback<BookingOfferDetailsHandlersContextValue['onChangeStep']>(
    newStep => {
      if (newStep.key !== step) {
        history.push(
          addSearchParamToLocation({
            location,
            param: EBookingOfferUrlParam.Step,
            value: newStep.key,
          })
        );
      }
    },
    [dispatch, step, history, location]
  );

  /**
   * Открываем или закрываем диалоговое окно
   */
  const onChangeDialogState = useCallback<BookingOfferDetailsHandlersContextValue['onChangeDialogState']>(
    (name, bookingOffer) => {
      dispatch(bookingOfferDetailsSetDialogState({ name, data: bookingOffer }));
    },
    [dispatch]
  );

  /**
   * Открыть объект для редактирования
   */
  const onBookingOfferOpenEdit = useCallback<BookingOfferDetailsHandlersContextValue['onBookingOfferOpenEdit']>(
    bookingOffer => {
      history.push(getBookingOfferEditRoute({ id: bookingOffer.id }));
    },
    [history]
  );

  const onBookingOfferSwitchToEdit = useCallback<BookingOfferDetailsHandlersContextValue['onBookingOfferSwitchToEdit']>(
    bookingOffer => {
      history.replace(getBookingOfferEditRoute({ id: bookingOffer.id }));
    },
    [history]
  );

  /**
   * Закрыть мастер просмотра
   */
  const onBookingOfferClose = useCallback(
    (action: Nullable<EBookingActionType>) => {
      const tab = getBookingOfferTableTabByNameAction(action);
      gotoPrevIndependentLocation(getBookingOffersTableRoute({ tab }));
    },
    [gotoPrevIndependentLocation]
  );

  /**
   * Обновить данные объекта
   */
  const onBookingOfferRefetch = useCallback<BookingOfferDetailsHandlersContextValue['onBookingOfferRefetch']>(
    async bookingOffer => {
      const { bookingOffer: newBookingOffer } = await dispatch(
        bookingOfferDetailsByIdFetch({ id: bookingOffer.id })
      ).unwrap();
      return newBookingOffer;
    },
    [dispatch]
  );

  /**
   * Добавить объект в архив
   */
  const onBookingOfferTryArchive = useCallback<
    BookingOfferDetailsHandlersContextValue['onBookingOfferTryArchive']
  >(() => {
    const bookingOffer = getBookingOfferFromState();

    if (bookingOffer) {
      onTryArchive(bookingOffer);
    }
  }, [getBookingOfferFromState]);

  /**
   * Сделать копию объекта
   */
  const onBookingOfferTryDuplicate = useCallback<
    BookingOfferDetailsHandlersContextValue['onBookingOfferTryDuplicate']
  >(() => {
    const bookingOffer = getBookingOfferFromState();

    if (bookingOffer) {
      onTryDuplicate(bookingOffer);
    }
  }, [getBookingOfferFromState]);

  /**
   * Назначить модератора объекта
   */
  const onBookingOfferTryChangeModerator = useCallback<
    BookingOfferDetailsHandlersContextValue['onBookingOfferTryChangeModerator']
  >(() => {
    const bookingOffer = getBookingOfferFromState();

    if (bookingOffer) {
      onTryChangeModerator(bookingOffer);
    }
  }, [getBookingOfferFromState]);

  /**
   * Отклонить объект
   */
  const onBookingOfferTryReject = useCallback<
    BookingOfferDetailsHandlersContextValue['onBookingOfferTryReject']
  >(() => {
    const bookingOffer = getBookingOfferFromState();

    if (bookingOffer) {
      onTryReject(bookingOffer);
    }
  }, [getBookingOfferFromState]);

  /**
   * Приостановить объект
   */
  const onBookingOfferTryPause = useCallback<BookingOfferDetailsHandlersContextValue['onBookingOfferTryPause']>(() => {
    const bookingOffer = getBookingOfferFromState();

    if (bookingOffer) {
      onTryPause(bookingOffer);
    }
  }, [getBookingOfferFromState]);

  /**
   * Вернуть на проверку объект
   */
  const onBookingOfferTryResume = useCallback<
    BookingOfferDetailsHandlersContextValue['onBookingOfferTryResume']
  >(() => {
    const bookingOffer = getBookingOfferFromState();

    if (bookingOffer) {
      onTryResume(bookingOffer);
    }
  }, [getBookingOfferFromState]);

  /**
   * Одобрить объект
   */
  const onBookingOfferTryApprove = useCallback<
    BookingOfferDetailsHandlersContextValue['onBookingOfferTryApprove']
  >(() => {
    const bookingOffer = getBookingOfferFromState();

    if (bookingOffer) {
      onTryApprove(bookingOffer);
    }
  }, [getBookingOfferFromState]);

  /**
   * Опубликовать объект
   */
  const onBookingOfferTryPublish = useCallback<
    BookingOfferDetailsHandlersContextValue['onBookingOfferTryPublish']
  >(() => {
    const bookingOffer = getBookingOfferFromState();

    if (bookingOffer) {
      onTryPublish(bookingOffer);
    }
  }, [getBookingOfferFromState]);

  /**
   * Одобрить объект
   */
  const onBookingOfferTryUnPublish = useCallback<
    BookingOfferDetailsHandlersContextValue['onBookingOfferTryUnPublish']
  >(() => {
    const bookingOffer = getBookingOfferFromState();

    if (bookingOffer) {
      onTryUnPublish(bookingOffer);
    }
  }, [getBookingOfferFromState]);

  /**
   * Вернуть на проверку объект
   */
  const onBookingOfferTryReturnToVerification = useCallback<
    BookingOfferDetailsHandlersContextValue['onBookingOfferTryReturnToVerification']
  >(() => {
    const bookingOffer = getBookingOfferFromState();

    if (bookingOffer) {
      onTryReturnToVerification(bookingOffer);
    }
  }, [getBookingOfferFromState]);

  /**
   * Вернуть в предыдущий статус
   */
  const onBookingOfferTryRetrieve = useCallback<
    BookingOfferDetailsHandlersContextValue['onBookingOfferTryRetrieve']
  >(() => {
    const bookingOffer = getBookingOfferFromState();

    if (bookingOffer) {
      onTryRetrieve(bookingOffer);
    }
  }, [getBookingOfferFromState]);

  /**
   * Взять в работу объект
   */
  const onBookingOfferTryInWork = useCallback<
    BookingOfferDetailsHandlersContextValue['onBookingOfferTryInWork']
  >(async () => {
    const bookingOffer = getBookingOfferFromState();

    if (bookingOffer?.id) {
      await onTryInWork(bookingOffer);
    }
  }, [getBookingOfferFromState]);

  /**
   * Открыть модальное окно для управления услугами
   */
  const onBookingOfferTryServiceManage = useCallback<
    BookingOfferDetailsHandlersContextValue['onBookingOfferTryServiceManage']
  >(async () => {
    const bookingOffer = getBookingOfferFromState();

    if (bookingOffer?.id) {
      await onTryServiceManage(bookingOffer);
    }
  }, [getBookingOfferFromState]);

  const value: BookingOfferDetailsHandlersContextValue = {
    onRefresh,
    onChangeDialogState,

    onChangeStep,
    onBookingOfferOpenEdit,
    onBookingOfferSwitchToEdit,
    onBookingOfferClose,
    onBookingOfferRefetch,
    onBookingOfferTryArchive,
    onBookingOfferTryDuplicate,
    onBookingOfferTryChangeModerator,
    onBookingOfferTryReject,
    onBookingOfferTryPause,
    onBookingOfferTryResume,
    onBookingOfferTryApprove,
    onBookingOfferTryUnPublish,
    onBookingOfferTryPublish,
    onBookingOfferTryInWork,
    onBookingOfferTryRetrieve,
    onBookingOfferTryReturnToVerification,
    onBookingOfferTryServiceManage,
  };

  return (
    <BookingOfferDetailsHandlersContext.Provider value={value}>{children}</BookingOfferDetailsHandlersContext.Provider>
  );
};
