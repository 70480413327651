import { Fetchable } from '@/data/store/types';
import { Banner, Nullable } from '@/domain';
import { BannerAction, BannerActionTableType, EBannerActionType } from '@features/banner/types';
import BannerTableCell, { BannerTableCellCommonProps } from '.';
import DataTableCellSortIndex from '../../../../components/common/table/cell/sortIndex';
import { BannerActionCall, BannerTableActionProcess } from '../../table/utils';

export type BannersTableCellSortIndexProps = BannerTableCellCommonProps & {
  readonly activeBanner: Nullable<Banner>;
  readonly editableBanner: Nullable<Banner>;
  readonly canChangeSortIndex: Nullable<BannerAction<BannerActionTableType>>;
  readonly sortIndexInfo: Fetchable & {
    readonly fetch: () => void;
    readonly max: number;
  };
  readonly getActionProcesses: (banner: Banner) => BannerTableActionProcess[];
  readonly onBannerAction: (call: BannerActionCall) => void;
  readonly setEditableBanner: (banner: Nullable<Banner>) => void;
};

const BannerTableCellSortIndex = (props: BannersTableCellSortIndexProps) => {
  const {
    banner,
    isDisabled,
    activeBanner,
    editableBanner,
    canChangeSortIndex,
    sortIndexInfo,
    getActionProcesses,
    onBannerAction,
    setEditableBanner,
  } = props;
  const { id, sortIndex } = banner;

  const changeSortIndexProcess = getActionProcesses(banner).find(
    process => process.type === EBannerActionType.ChangeSortIndex
  );
  const currentEditableBanner = editableBanner?.id === id ? editableBanner : null;
  const isSortIndexFetching = changeSortIndexProcess?.isFetching || sortIndexInfo.isFetching;

  return (
    <>
      {!canChangeSortIndex || canChangeSortIndex.disabled ? (
        <BannerTableCell
          banner={banner}
          value={sortIndex}
          isDisabled={isDisabled}
        />
      ) : (
        <DataTableCellSortIndex
          editMode={editableBanner?.id === id && sortIndexInfo.isFetched}
          value={sortIndex}
          max={sortIndexInfo.max}
          label={(min, max) => (sortIndexInfo.isFetched ? `${min}-${max}` : '')}
          hideEditIcon={activeBanner?.id !== id && editableBanner?.id !== id}
          isFetching={(!editableBanner || !!currentEditableBanner) && isSortIndexFetching}
          onChangeEditMode={isEditMode => setEditableBanner(!isEditMode ? null : banner)}
          onChange={newValue => {
            setEditableBanner({ ...banner, sortIndex: newValue });
            onBannerAction({
              action: EBannerActionType.ChangeSortIndex,
              banner,
              sortIndex: newValue,
            });
          }}
        />
      )}
    </>
  );
};

export default BannerTableCellSortIndex;
