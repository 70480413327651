import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 30.625rem;
  padding: ${p => p.theme.spacing(4)};
  border: solid 1px ${p => p.theme.palette.secondary.light};
  border-radius: ${p => (p.theme.shape.borderRadius as number) * 2}px;

  background: ${p => p.theme.palette.error.light};
`;
