import { Fade, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useAppDispatch } from '../../../../../data/store/store';
import { EUserRole, EUserStatus } from '../../../../../domain/model/enums';
import { NotificationOption } from '../../../../../domain/model/notification';
import { Nullable, UUID } from '../../../../../domain/model/types';
import MasterActionsComponent from '../../../../components/common/actions/master';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../components/common/header';
import ContentLoader from '../../../../components/common/loader';
import Splitter from '../../../../components/common/splitter';
import { DefaultContentWrapper } from '../../../../components/common/wrappers/content';
import { DefaultFooterWrapper } from '../../../../components/common/wrappers/footer';
import { ETagColors, MPTag } from '../../../../theme/ui-kit/tag';
import { PanelAction, PanelActions } from '../../../../types';
import { getUserShortName } from '../../../../utils';
import useBreadcrumbs from '../../../general/breadcrumbs/useBreadcrumbs';
import { getCurrentUserEditRoute } from '../../entry';
import { EUserMPActionType, UserMPDetailsActionType } from '../types';
import { getUserMPDetailsActions } from '../utils';
import UserMpDetailsComponent from './component';
import { ContainerWrapper, LoaderWrapper, TitleWrapper } from './controls';
import {
  userMpDetailsByIdSelector,
  userMpDetailsChangeStatusSelector,
  userMpDetailsNeedRefreshWatcherSelector,
} from './store/selectors';
import {
  userMpDetailsByIdFetch,
  userMpDetailsChangeUserStatus,
  userMpDetailsNotificationsUpdate,
  userMpDetailsStateReset,
} from './store/slice';

interface UserMpDetailsContainerProps {
  readonly id: UUID;
  readonly canEdit?: boolean;
  readonly canChangeStatus?: boolean;
  readonly roles?: Nullable<EUserRole[]>;
  readonly resetPassword?: () => void;
  readonly logOut?: () => void;
}

const allowedRoles = [
  EUserRole.AdminMp,
  EUserRole.AdminPartner,
  EUserRole.ManagerPartner,
  EUserRole.AdminMpReadOnly,
  EUserRole.AdminMpCorp,
];

const UserMpDetailsContainer = (props: UserMpDetailsContainerProps) => {
  const { id, canEdit = false, canChangeStatus = false, roles, resetPassword, logOut } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();

  const { user, notifications, isFetching } = useSelector(userMpDetailsByIdSelector);
  const needRefreshWatcher = useSelector(userMpDetailsNeedRefreshWatcherSelector);
  const { isFetching: changeStatusIsFetching } = useSelector(userMpDetailsChangeStatusSelector);
  const breadcrumbs = useBreadcrumbs();

  const onEdit = () => {
    history.push(getCurrentUserEditRoute());
  };

  const onChangeStatus = (status: EUserStatus) => {
    dispatch(userMpDetailsChangeUserStatus({ id, status }));
  };

  const onChangePassword = () => {
    resetPassword?.();
  };

  const onChangeNotification = (option: NotificationOption, enabled: boolean) => {
    dispatch(userMpDetailsNotificationsUpdate({ id, option, enabled }));
  };

  const onPanelAction = (action: PanelAction<UserMPDetailsActionType>) => {
    const { type } = action;
    switch (type) {
      case EUserMPActionType.Edit:
        onEdit();
        break;
      case EUserMPActionType.Activate:
        onChangeStatus(EUserStatus.Enabled);
        break;
      case EUserMPActionType.Deactivate:
        onChangeStatus(EUserStatus.Disabled);
        break;
      case EUserMPActionType.Logout:
        logOut?.();
        break;
    }
  };

  useEffect(() => {
    const promise = dispatch(
      userMpDetailsByIdFetch({
        id,
        roles: allowedRoles,
      })
    );
    return () => {
      dispatch(userMpDetailsStateReset());
      promise?.abort();
    };
  }, [dispatch, id, roles, needRefreshWatcher]);

  const actions: PanelActions<UserMPDetailsActionType> = user
    ? getUserMPDetailsActions({
        status: user.status,
        canEdit,
        canChangeStatus,
        canLogout: !!logOut,
      })
    : [];

  const actionsPanel = (
    <MasterActionsComponent<UserMPDetailsActionType>
      actions={actions}
      show={!!actions.length}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );

  return (
    <Fade in>
      <DefaultContentWrapper
        type='details'
        stickyHeader
        fullHeight
        footer={actionsPanel}
      >
        <ContainerWrapper>
          {user && (
            <>
              <DefaultHeader
                sticky
                headline={
                  <>
                    {breadcrumbs.length > 0 && <AppBreadcrumbs />}
                    {user.status === EUserStatus.Disabled && (
                      <MPTag
                        bold
                        label={'Отключён'}
                        color={ETagColors.Warning}
                      />
                    )}
                  </>
                }
              >
                <TitleWrapper>
                  <Typography variant='h2'>{getUserShortName(user)}</Typography>
                </TitleWrapper>
              </DefaultHeader>
              <Splitter
                variant='horizontal'
                size={3}
              />
            </>
          )}

          {user && notifications && (
            <UserMpDetailsComponent
              user={user}
              notifications={notifications}
              onChangeNotification={canEdit ? onChangeNotification : null}
              onChangePassword={canEdit && resetPassword ? onChangePassword : null}
            />
          )}
        </ContainerWrapper>
        {(isFetching || changeStatusIsFetching) && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
      </DefaultContentWrapper>
    </Fade>
  );
};

export default UserMpDetailsContainer;
