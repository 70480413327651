import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../data/store/store';

// agreementsList

export enum EAgreementsStatus {
  Updated = 'Updated',
  AllUnsigned = 'AllUnsigned',
}

export const agreementsListSelector = (state: RootState) => {
  return state.legalAgreements.legalAgreements.agreementsList.agreementsToSign;
};

// note: учитывает кейс когда с бэка приходит 403 и все доки подписаны
export const agreementsListToSignSelector = createSelector(agreementsListSelector, agreements => {
  const isAllSigned = agreements.every(a => a.agreementDate);
  if (isAllSigned) {
    return agreements;
  }

  return agreements.filter(a => !a.agreementDate);
});

export const agreementsListStatusSelector = createSelector(agreementsListSelector, agreements => {
  if (agreements.every(a => a.agreementDate === null)) {
    return EAgreementsStatus.AllUnsigned;
  }

  return EAgreementsStatus.Updated;
});
export const agreementsListRefreshWatcherSelector = (state: RootState) =>
  state.legalAgreements.legalAgreements.agreementsList.needRefreshWatcher;
export const agreementsListIsFetchingToSignSelector = (state: RootState) =>
  state.legalAgreements.legalAgreements.agreementsList.isFetching;
export const agreementsListIsFetchedToSignSelector = (state: RootState) =>
  state.legalAgreements.legalAgreements.agreementsList.isFetched;

// agreementsSign
export const agreementsSignIsFetchingToSignSelector = (state: RootState) =>
  state.legalAgreements.legalAgreements.agreementsSign.isFetching;
export const agreementsSignIsFetchedToSignSelector = (state: RootState) =>
  state.legalAgreements.legalAgreements.agreementsSign.isFetched;

// checking request
export const agreementsIsCheckingRequestPassed = (state: RootState) =>
  state.legalAgreements.legalAgreements.isCheckingRequestPassed;

export const agreementsIsCheckingRequestFailed = (state: RootState) =>
  state.legalAgreements.legalAgreements.isCheckingRequestFailed;

export const agreementsCheckingRequestError = (state: RootState) =>
  state.legalAgreements.legalAgreements.isCheckingRequestError;
