import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UUID } from '../../../../../domain/model/types';
import { EProductOrderTableTab } from '../../types';
import { productOrdersGuidSelector } from '../store/selectors';
import { productOrdersSetTab, productOrdersStartSession } from '../store/slice';

type UseProductOrderTableSessionProps = {
  readonly guid: UUID;
  readonly tab: EProductOrderTableTab;
};

export const useProductOrderTableSession = (props: UseProductOrderTableSessionProps) => {
  const { guid, tab } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(productOrdersGuidSelector);

  // инициализация сеанса
  useEffect(() => {
    dispatch(productOrdersStartSession({ guid }));
  }, [dispatch, guid]);

  // начальная загрузка, изменение вкладки
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(productOrdersSetTab({ tab }));
    }
  }, [dispatch, guid, currentGuid, tab]);
};
