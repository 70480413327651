import styled from '@emotion/styled';
import { Avatar, AvatarProps } from '@mui/material';
import { MPAvatarSize } from './index';

interface StyledAvatarProps extends AvatarProps {
  readonly autosize: boolean;
  readonly size: MPAvatarSize;
  readonly selected: boolean;
  readonly children: any;
}

export const StyledAvatar = styled(({ size, autosize, selected, ...others }: StyledAvatarProps) => (
  <Avatar {...others} />
))`
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.075em;

  ${p =>
    p.selected &&
    `
    border: solid 3px ${p.theme.palette.primary.main};
  `};

  ${p =>
    !p.autosize &&
    `
    & .MuiAvatar-img {
      width: auto;
      height: auto;
      max-width: 70%;
      max-height: 70%;
    }
  `};

  ${p =>
    p.size === 'micro' &&
    `
    width: ${p.theme.spacing(2.5)};
    height: ${p.theme.spacing(2.5)};
    font-size: .6em;
  `};
  ${p =>
    p.size === 'small' &&
    `
    width: ${p.theme.spacing(3)};
    height: ${p.theme.spacing(3)};
    font-size: .8em;
  `};
  ${p =>
    p.size === 'default' &&
    `
    width: ${p.theme.spacing(5)};
    height: ${p.theme.spacing(5)};
    font-size: .8125rem;
  `};
  ${p =>
    p.size === 'large' &&
    `
    width: ${p.theme.spacing(6)};
    height: ${p.theme.spacing(6)};
    font-size: 1.3em;
  `};
  ${p =>
    p.size === 'giant' &&
    `
    width: ${p.theme.spacing(8)};
    height: ${p.theme.spacing(8)};
    font-size: 1.5em;
  `};
  ${p =>
    p.size === 'largest' &&
    `
    width: ${p.theme.spacing(10)};
    height: ${p.theme.spacing(10)};
    font-size: 1.8em;
  `};
`;
