import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cmsContainersNeedRefreshWatcherSelector, cmsContainersSearchSelector } from '../store/selectors';
import { cmsContainersFetch, CmsContainersFetchProps, cmsContainersNeedRefreshWatcherReset } from '../store/slice';
import { ECmsContainerTableTab, getCmsContainerTableStatusesByTab } from '../utils';

interface UseCmsContainerTableSearchProps {
  readonly sitePageId: UUID;
  readonly tab: ECmsContainerTableTab;
}

const useCmsContainerTableSearch = (props: UseCmsContainerTableSearchProps): void => {
  const { sitePageId, tab } = props;

  const dispatch = useDispatch();

  const [searchPromise, setSearchPromise] = useState<Nullable<any>>(null);

  const { offerTypes, userGenders: genderTypes } = useSelector(nsiDataSelector);

  const search = useSelector(cmsContainersSearchSelector);
  const needRefreshWatcher = useSelector(cmsContainersNeedRefreshWatcherSelector);

  const queryParams = useMemo<CmsContainersFetchProps>(() => {
    return {
      sitePageId,
      search: {
        ...search,
        statuses: getCmsContainerTableStatusesByTab(tab),
      },
      offerTypes,
      genderTypes,
    };
  }, [sitePageId, search, tab, offerTypes, genderTypes]);

  const onSearch = useCallback(() => {
    setSearchPromise(dispatch(cmsContainersFetch(queryParams)));
  }, [dispatch, queryParams]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromise?.abort();
      onSearch();

      return () => {
        searchPromise?.abort();
      };
    }
  }, [dispatch, needRefreshWatcher, onSearch]);

  useEffect(() => {
    return () => {
      dispatch(cmsContainersNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};

export default useCmsContainerTableSearch;
