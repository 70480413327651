import { Nullable } from '@/domain/model/types';
import { useMemo } from 'react';
import { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import {
  analyticsQueriesTableFilterItems,
  EAnalyticsQueriesTableFilterItem,
  getAnalyticsQueriesTableFilterStrategy,
} from '../filterUtils';
import AnalyticsQueriesTableContainer from './container';
import { AnalyticsQueriesTableConfigContext } from './context';
import { useAnalyticsQueriesTableConfig } from './hooks/useTableConfig';
import { EAnalyticsQueriesTableColumn, EAnalyticsQueriesTableTab, getAnalyticsQueriesTableColumns } from './utils';

interface AnalyticsQueriesTableEntryProps {
  readonly guid: UUID;
  readonly tab: Nullable<EAnalyticsQueriesTableTab>;
  readonly partnerId?: Nullable<UUID>;
}

interface GetTableAdapterKeyParams {
  readonly isAdmin?: boolean;
  readonly partnerId: Nullable<string>;
  readonly tab: EAnalyticsQueriesTableTab;
}

interface GetTableAdapterKeyResult {
  readonly key: string;
  readonly keyPartitions?: (string | number | null)[];
}

// TODO - убрать вообще зависимость от партнера? хотя у каждого своя должна быть по идее
const getTableAdapterKey = (params: GetTableAdapterKeyParams): GetTableAdapterKeyResult => {
  const { isAdmin, partnerId, tab } = params;

  const key = 'analyticsQueries';
  let keyPartitions;

  if (isAdmin) {
    keyPartitions = ['admin', partnerId ? 'byPartner' : 'common', tab];
  } else {
    keyPartitions = ['partner', tab];
  }

  return {
    key,
    keyPartitions,
  };
};

const AnalyticsQueriesTableEntry = ({ guid, tab, partnerId = null }: AnalyticsQueriesTableEntryProps) => {
  const {
    accessMatrix: { analyticsQueries, isAdminMp },
  } = useCurrentUser();

  const config = useAnalyticsQueriesTableConfig();

  const { defaultTab } = config;
  const outTab = tab ?? defaultTab;

  // получаем максимально возможный состав колонок
  const sourceColumns = useMemo(() => getAnalyticsQueriesTableColumns(outTab), [outTab]);

  // готовим адаптер для хука
  const filterAdapter = useMemo<DataFilterAdapter<EAnalyticsQueriesTableColumn, EAnalyticsQueriesTableFilterItem>>(
    () => ({
      ...getTableAdapterKey({
        isAdmin: isAdminMp,
        partnerId,
        tab: outTab,
      }),
      sourceColumns,
      filterItems: analyticsQueriesTableFilterItems,
      requiredFilters: [
        EAnalyticsQueriesTableFilterItem.Query,
        EAnalyticsQueriesTableFilterItem.DateFrom,
        EAnalyticsQueriesTableFilterItem.DateTo,
        EAnalyticsQueriesTableFilterItem.Places,
      ],
      getDataFilterStrategy: (filterItem, values) => getAnalyticsQueriesTableFilterStrategy(filterItem, values),
    }),
    [sourceColumns, partnerId, isAdminMp]
  );

  return (
    <AnalyticsQueriesTableConfigContext.Provider value={config}>
      <AnalyticsQueriesTableContainer
        guid={guid}
        tab={outTab}
        filterAdapter={filterAdapter}
        access={analyticsQueries}
      />
    </AnalyticsQueriesTableConfigContext.Provider>
  );
};

export default AnalyticsQueriesTableEntry;
