import { useDispatch } from 'react-redux';
import { ECorpActivationActionType } from '../types';
import CorpActivationActionsOptimizerAdapter from './adapters/optimizer';
import CorpActivationActionsContext, { CorpActivationActionsContextType } from './context';
import {
  getCorpActivationActionsOfferIsProcessingSelector,
  getIsCorpActivationAnyActionByTypeExecutedSelector,
  isCorpActivationsAnyChangedSelector,
} from './store/selectors';
import { corpActivationActionsGive, corpActivationActionsUnGive } from './store/slice';
import { showCorpActivationActionNotification } from './utils';

type CorpActivationActionsProviderProps = {
  readonly children: JSX.Element;
};

type CorpActivationActionsProviderType = (props: CorpActivationActionsProviderProps) => JSX.Element;

const CorpActivationActionsProvider: CorpActivationActionsProviderType = ({ children }) => {
  const dispatch = useDispatch();

  const onGive: CorpActivationActionsContextType['onGive'] = async corpActivation => {
    const response = await dispatch(
      corpActivationActionsGive({
        id: corpActivation.id,
      })
    ).unwrap();

    if (response) {
      showCorpActivationActionNotification(ECorpActivationActionType.Give);
    }
  };

  const onUnGive: CorpActivationActionsContextType['onUnGive'] = async corpActivation => {
    const response = await dispatch(
      corpActivationActionsUnGive({
        id: corpActivation.id,
      })
    ).unwrap();

    if (response) {
      showCorpActivationActionNotification(ECorpActivationActionType.UnGive);
    }
  };

  const value: CorpActivationActionsContextType = {
    onGive,
    onUnGive,

    utils: {
      selectors: {
        getIsCorpActivationExecutedActionsSelector: getIsCorpActivationAnyActionByTypeExecutedSelector,
        getIsCorpActivationProcessingSelector: getCorpActivationActionsOfferIsProcessingSelector,
        isCorpActivationsAnyChangedSelector,
      },
    },
  };

  return (
    <CorpActivationActionsContext.Provider value={value}>
      {children}
      <CorpActivationActionsOptimizerAdapter />
    </CorpActivationActionsContext.Provider>
  );
};

export default CorpActivationActionsProvider;
