import { useMemo } from 'react';
import { ENavStepAction } from '..';
import { PanelAction, PanelActions } from '../../../../../types';

interface UseGetNavActionsProps {
  readonly actions: PanelActions<ENavStepAction>;
}

interface UseGetNavActionsResult {
  readonly nextStepAction?: PanelAction<ENavStepAction, any>;
  readonly prevStepAction?: PanelAction<ENavStepAction, any>;
}

const useGetNavActions = (props: UseGetNavActionsProps): UseGetNavActionsResult => {
  const { actions } = props;

  return useMemo(() => {
    const nextStepAction = actions.find(action => action.type === ENavStepAction.NextStep);
    const prevStepAction = actions.find(action => action.type === ENavStepAction.Back);

    return {
      nextStepAction,
      prevStepAction,
    };
  }, [actions]);
};

export default useGetNavActions;
