import styled from '@emotion/styled';
import { ActionButton } from '../../../../../components/common/dictionary/tree/item/controls';
import AppImage from '../../../../../components/common/images/common';

export const InvisibleIconButton = styled(ActionButton)`
  .MuiSvgIcon-root {
    color: ${p => p.theme.palette.text.secondary};
  }
`;

export const Image = styled(AppImage)`
  width: auto;
  height: auto;
  max-width: 1.5rem;
  max-height: 1.5rem;
`;
