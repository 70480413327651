import { ContentTarget } from '@/domain';
import CheckBoxContent from '../../../../../components/common/checkBoxContent';
import { ContentTargetViewComponent } from '../../types';

const attribute: keyof ContentTarget = 'hasRzdSocialPackage';

const ContentTargetViewHasRzdSocialPackage: ContentTargetViewComponent = props => {
  const { target } = props;

  const value = target[attribute] ?? false;

  return (
    <CheckBoxContent
      label='КСП'
      checked={value}
    />
  );
};

export default ContentTargetViewHasRzdSocialPackage;
