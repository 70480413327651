import { MouseEventHandler } from 'react';
import AppImage from '../common';
import { StyledBackdrop } from './controls';

type ViewImageProps = {
  src: string;
  open: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
  alt?: string;
};

export const ViewImage = (props: ViewImageProps) => {
  const { open, src, alt, onClick } = props;

  if (!src) return null;

  return (
    <StyledBackdrop
      open={open}
      onClick={onClick}
    >
      <AppImage
        src={src}
        alt={alt}
      />
    </StyledBackdrop>
  );
};
