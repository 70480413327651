import { useSelector } from 'react-redux';
import MasterActionsComponent from '../../../../components/common/actions/master';
import useNavAdapter from '../../../../components/common/actions/navAdapter/hooks';
import { DefaultFooterWrapper } from '../../../../components/common/wrappers/footer';
import { BookingActionDetailsType } from '../../types';
import { useContextLifecycle } from '../hooks/useContextLifecycle';
import useBookingOfferDetailsStepper from '../hooks/useStepper';
import { bookingOfferDetailsBookingOfferSelector } from '../store/selectors';

const BookingOfferDetailsFooterAdapter = () => {
  const { actions, onAction } = useContextLifecycle();

  const bookingOffer = useSelector(bookingOfferDetailsBookingOfferSelector);

  const { openNextStep, currentStepIndex, openPrevStep, steps } = useBookingOfferDetailsStepper();

  const onNextStep = () => {
    if (bookingOffer) {
      openNextStep();
    }
  };

  const { adapter: navAdapter, actions: navActions } = useNavAdapter({
    openPrevStep,
    currentStepIndex,
    openNextStep: onNextStep,
    stepsCount: steps.length,
  });

  return (
    <MasterActionsComponent<BookingActionDetailsType>
      show={!!actions.length || !!navActions.length}
      actions={actions}
      navAdapter={navAdapter}
      onAction={onAction}
      wrapper={DefaultFooterWrapper}
    />
  );
};

export default BookingOfferDetailsFooterAdapter;
