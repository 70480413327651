import { DataFilterStrategyLocalitySingle } from '../../../../../../domain/model/filter';
import { Nullable } from '../../../../../../domain/model/types';
import AddressLocalitySingleSelector from '../../../../../features/address/components/selector/single/locality';
import { DataFilterEditItemProps } from '../index';

type ValueType<T extends string> = DataFilterStrategyLocalitySingle<T>['value'];

interface DataFilterEditItemAddressLocalitySingleProps<T extends string>
  extends DataFilterEditItemProps<T, Nullable<ValueType<T>>> {
  readonly strategy: DataFilterStrategyLocalitySingle<T>;
}

function DataFilterEditItemAddressLocalitySingle<T extends string>({
  strategy,
  onChange,
}: DataFilterEditItemAddressLocalitySingleProps<T>) {
  const { label, value, readOnly } = strategy;

  return (
    <AddressLocalitySingleSelector
      value={value}
      label={label}
      disabled={readOnly}
      onChange={data => onChange(strategy, data)}
    />
  );
}

export default DataFilterEditItemAddressLocalitySingle;
