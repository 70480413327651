import { EAdCampaignType, SportOptionTyped } from '@/domain';
import { AdCampaignTableCell, AdCampaignTableCellCommonProps } from './index';

export type AdCampaignTableCellTypeProps = AdCampaignTableCellCommonProps & {
  readonly adTypes: SportOptionTyped<EAdCampaignType>[];
};

export const AdCampaignTableCellType = (props: AdCampaignTableCellTypeProps) => {
  const { adCampaign, adTypes } = props;

  const value = adTypes.find(adType => adType.id === adCampaign.type)?.name;

  return (
    <AdCampaignTableCell
      {...props}
      value={value}
    />
  );
};
