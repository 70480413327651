import { FCC } from 'react';
import ErrorBoundaryCommon from './common';
import ErrorBoundarySentry from './sentry';

interface ErrorBoundaryProps {
  readonly variant: 'common' | 'sentry';
  readonly onLogout?: (() => void) | false;
}

const ErrorBoundary: FCC<ErrorBoundaryProps> = ({ variant, onLogout, children }) => {
  if (variant === 'common') {
    return <ErrorBoundaryCommon onLogout={onLogout}>{children}</ErrorBoundaryCommon>;
  }

  if (variant === 'sentry') {
    return <ErrorBoundarySentry onLogout={onLogout}>{children}</ErrorBoundarySentry>;
  }

  return <>{children}</>;
};

export default ErrorBoundary;
