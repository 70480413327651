import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../data/store/store';
import { SportOption } from '../../../../../domain/model';
import { EOfferStatus, ESortDirection } from '../../../../../domain/model/enums';
import { ProductCategory, ProductDeskShort, ProductOffer } from '../../../../../domain/model/productOffer';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { MpUser } from '../../../../../domain/model/user';
import { addSearchParamToLocation } from '../../../../../routing/globalRouting';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { EProductUrlParam } from '../../entry';
import { ProductTableFilterEditStrategy } from '../filter/filterUtils';
import { productsGuidSelector } from '../store/selectors';
import {
  ProductListState,
  productsAllSelect,
  productsAllUnselect,
  productsApprove,
  productsArchive,
  productsArchiveProductDesk,
  productsChangeModerator,
  productsChangePrice,
  productsChangeStock,
  productsDataReset,
  productsDelete,
  productsDuplicate,
  productsOffersUploaded,
  productsOneSelect,
  productsOneUnselect,
  productsPauseProduct,
  productsPauseProductDesk,
  productsPublishProduct,
  productsRejectProductOffer,
  productsResume,
  productsResumeDesk,
  productsSetCategory,
  productsSetDialogState,
  productsSetFilter,
  productsSetSort,
  productsSortReset,
  productsStocksUploaded,
  productsUnPublish,
  productsUnPublishDesk,
} from '../store/slice';
import { EProductTableTab } from '../utils';

export type UseProductTable = {
  readonly onChangeTab: (newTab: EProductTableTab) => void;
  readonly isProductNotModeratedByMe: (product: ProductOffer) => boolean;
  readonly onProductsUploaded: (count: number) => void;
  readonly onStocksUploaded: (count: number) => void;
  readonly onChangeFilter: (strategies: ProductTableFilterEditStrategy[]) => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
  readonly onResetSort: () => void;
  readonly onProductSelect: (product: ProductOffer, selected: boolean) => void;
  readonly onAllProductsSelect: (selected: boolean) => void;

  readonly onChangeDialogState: (dialog: keyof ProductListState['dialogs'], data: Nullable<ProductOffer>) => void;

  readonly onProductArchive: (product: ProductOffer) => Promise<void>;
  readonly onProductResume: (product: ProductOffer) => Promise<boolean>;
  readonly onProductDelete: (product: ProductOffer) => Promise<boolean>;
  readonly onProductApprove: (product: ProductOffer) => Promise<boolean>;
  readonly onProductPublish: (product: ProductOffer) => Promise<boolean>;
  readonly onProductUnPublish: (product: ProductOffer) => Promise<boolean>;
  readonly onProductUnPublishDesk: (product: ProductOffer) => Promise<boolean>;
  readonly onProductDuplicate: (product: ProductOffer) => Promise<Nullable<ProductOffer>>;

  readonly onProductDeskArchive: (productDesk: ProductDeskShort) => Promise<boolean>;
  readonly onProductDeskResume: (productDesk: ProductDeskShort) => Promise<boolean>;

  readonly onProductReject: (productOffer: ProductOffer, reason: SportOption, comment?: string) => Promise<boolean>;
  readonly onProductPause: (productOffer: ProductOffer, comment: Nullable<string>) => Promise<boolean>;
  readonly onProductDeskPause: (productDesk: ProductDeskShort, comment: Nullable<string>) => Promise<boolean>;
  readonly onProductStockChange: (productOffer: ProductOffer, stock: number) => Promise<boolean>;
  readonly onProductPriceChange: (
    productOffer: ProductOffer,
    price: number,
    originalPrice: ProductOffer['originalPrice']
  ) => Promise<boolean>;
  readonly onProductModeratorChange: (productOffer: ProductOffer, newModerator: MpUser) => Promise<boolean>;
  readonly onChangeCategory: (
    newCategory: Nullable<ProductCategory>,
    newCategoryList: Nullable<ProductCategory[]>
  ) => void;
};

type UseProductTableHandlersProps = {
  readonly tab: EProductTableTab;
}
export function useProductTableHandlers({tab}: UseProductTableHandlersProps): UseProductTable {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  
  const {
    userSpecific: { id: userId },
  } = useCurrentUser();

  const getProductsGuidFromState = useCallback((): Nullable<UUID> => {
    const state = store.getState();
    return productsGuidSelector(state);
  }, [store]);

  const onChangeTab = useCallback(
    (newTab: EProductTableTab) => {
      if (newTab !== tab) {
        const guid = getProductsGuidFromState();
        dispatch(productsDataReset())
        history.replace(
          addSearchParamToLocation({
            location,
            param: EProductUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [dispatch, history, location, tab, getProductsGuidFromState]
  );
  
  const isProductNotModeratedByMe = useCallback(
    (product: ProductOffer) => {
      return product.status === EOfferStatus.OnModeration && product.moderator?.id !== userId;
    },
    [userId]
  );

  const onChangeDialogState = useCallback(
    (dialog: keyof ProductListState['dialogs'], data: Nullable<ProductOffer>) => {
      dispatch(productsSetDialogState({ name: dialog, data }));
    },
    [dispatch]
  );

  const onProductStockChange = useCallback(
    async (productOffer: ProductOffer, stock: number) => {
      const response = await dispatch(productsChangeStock({ id: productOffer.id, stock })).unwrap();
      return !!response.id;
    },
    [dispatch]
  );

  const onProductPriceChange = useCallback(
    async (productOffer: ProductOffer, price: number, originalPrice: ProductOffer['originalPrice']) => {
      const response = await dispatch(productsChangePrice({ id: productOffer.id, price, originalPrice })).unwrap();
      return !!response.id;
    },
    [dispatch]
  );

  const onProductModeratorChange = useCallback(
    async (productOffer: ProductOffer, newModerator: MpUser) => {
      const response = await dispatch(
        productsChangeModerator({ id: productOffer.id, userId: newModerator.id })
      ).unwrap();
      return !!response.id;
    },
    [dispatch]
  );

  const onProductReject = useCallback(
    async (productOffer: ProductOffer, reason: SportOption, comment?: string) => {
      const response = await dispatch(productsRejectProductOffer({ id: productOffer.id, reason, comment })).unwrap();
      return !!response.id;
    },
    [dispatch]
  );

  const onProductPause = useCallback(
    async (productOffer: ProductOffer, comment: Nullable<string>) => {
      const response = await dispatch(productsPauseProduct({ id: productOffer.id, comment })).unwrap();
      return !!response.id;
    },
    [dispatch]
  );

  const onProductDeskPause = useCallback(
    async (productDesk: ProductDeskShort, comment: Nullable<string>) => {
      const response = await dispatch(productsPauseProductDesk({ id: productDesk.id, comment })).unwrap();
      return !!response.id;
    },
    [dispatch]
  );

  const onProductArchive = useCallback(
    async (product: ProductOffer) => {
      await dispatch(productsArchive({ id: product.id })).unwrap();
    },
    [dispatch]
  );

  const onProductResume = useCallback(
    async (product: ProductOffer) => {
      const response = await dispatch(productsResume({ id: product.id })).unwrap();
      return !!response.id;
    },
    [dispatch]
  );

  const onProductPublish = useCallback(
    async (product: ProductOffer) => {
      const response = await dispatch(productsPublishProduct({ id: product.id })).unwrap();
      return !!response.id;
    },
    [dispatch]
  );

  const onProductDuplicate = useCallback(
    async (product: ProductOffer) => {
      const response = await dispatch(productsDuplicate({ id: product.id })).unwrap();

      return response.id ? response : null;
    },
    [dispatch]
  );

  const onProductUnPublish = useCallback(
    async (product: ProductOffer) => {
      const response = await dispatch(productsUnPublish({ id: product.id })).unwrap();
      return !!response.id;
    },
    [dispatch]
  );

  const onProductUnPublishDesk = useCallback(
    async (product: ProductOffer) => {
      if (product.productDesk?.id) {
        const response = await dispatch(productsUnPublishDesk({ id: product.productDesk?.id })).unwrap();
        return !!response.id;
      }
      return false;
    },
    [dispatch]
  );

  const onProductApprove = useCallback(
    async (product: ProductOffer) => {
      const response = await dispatch(productsApprove({ id: product.id })).unwrap();
      return !!response.id;
    },
    [dispatch]
  );

  const onProductDelete = useCallback(
    async (product: ProductOffer) => {
      await dispatch(productsDelete({ id: product.id })).unwrap();
      return true;
    },
    [dispatch]
  );

  const onProductDeskArchive = useCallback(
    async (productDesk: ProductDeskShort) => {
      const response = await dispatch(productsArchiveProductDesk({ id: productDesk.id })).unwrap();
      return !!response.id;
    },
    [dispatch]
  );

  const onProductDeskResume = useCallback(
    async (productDesk: ProductDeskShort) => {
      const response = await dispatch(productsResumeDesk({ id: productDesk.id })).unwrap();
      return !!response.id;
    },
    [dispatch]
  );

  const onProductsUploaded = useCallback((count: number) => dispatch(productsOffersUploaded(count)), [dispatch]);

  const onStocksUploaded = useCallback((count: number) => dispatch(productsStocksUploaded(count)), [dispatch]);

  const onChangeFilter = useCallback(
    (strategies: ProductTableFilterEditStrategy[]) => {
      dispatch(productsSetFilter(getDataFilterValuesByStrategies(strategies)));
    },
    [dispatch]
  );

  const onChangeSort = useCallback(
    (name: string, direction: ESortDirection) => {
      dispatch(
        productsSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onResetSort = useCallback(() => dispatch(productsSortReset()), [dispatch]);

  const onProductSelect = useCallback(
    (product: ProductOffer, selected: boolean) => {
      if (selected) {
        dispatch(productsOneSelect(product));
      } else {
        dispatch(productsOneUnselect(product));
      }
    },
    [dispatch]
  );

  const onAllProductsSelect = useCallback(
    (selected: boolean) => {
      if (selected) {
        dispatch(productsAllSelect());
      } else {
        dispatch(productsAllUnselect());
      }
    },
    [dispatch]
  );

  const onChangeCategory = useCallback(
    (newCategory: Nullable<ProductCategory>, newCategoryList: Nullable<ProductCategory[]>) => {
      dispatch(productsSetCategory({ category: newCategory, categoryList: newCategoryList }));
    },
    [dispatch]
  );

  return {
    onChangeTab,
    isProductNotModeratedByMe,
    onChangeDialogState,
    onProductsUploaded,
    onStocksUploaded,
    onChangeFilter,
    onChangeSort,
    onResetSort,
    onProductSelect,
    onAllProductsSelect,
    onProductApprove,
    onProductArchive,
    onProductDelete,
    onProductResume,
    onProductPublish,
    onProductUnPublish,
    onProductUnPublishDesk,
    onProductDeskArchive,
    onProductDeskResume,
    onProductDuplicate,
    onProductReject,
    onProductPause,
    onProductStockChange,
    onProductPriceChange,
    onProductModeratorChange,
    onProductDeskPause,
    onChangeCategory,
  };
}
