import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataFilterComponent from '../../../../components/common/dataFilter';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { ESportsmansTableFilterItem } from '../../filterUtils';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { sportsmansGetFilter, sportsmansGetGuid, sportsmansSortColumnSelector } from '../store/selectors';
import { ESportsmanTableColumn } from '../utils';

type SportsmanFilterAdapterProps = {
  readonly filterAdapter: DataFilterAdapter<ESportsmanTableColumn, ESportsmansTableFilterItem>;
};

const SportsmanFilterAdapter = ({ filterAdapter }: SportsmanFilterAdapterProps) => {
  const { onChangeFilter, onResetSort } = useContextHandlers();
  const guid = useSelector(sportsmansGetGuid);
  const sortColumn = useSelector(sportsmansSortColumnSelector);
  const filter = useSelector(sportsmansGetFilter);

  const { filterStrategies } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: onChangeFilter,
    onSortReset: onResetSort,
  });

  return useMemo(
    () => (
      <DataFilterComponent
        strategies={filterStrategies}
        onChange={onChangeFilter}
      />
    ),
    [filterStrategies, onChangeFilter]
  );
};

export default SportsmanFilterAdapter;
