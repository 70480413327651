import { MPRubFormat } from '@/presentation/theme/ui-kit/format';
import { FC } from 'react';
import { EEntityPreviewMode } from '../../../../types';
import { OfferPreviewSkeleton } from '../../../general/offer/components/preview/controls';
import { Price, PriceWrapper } from './controls';

type BookingOfferPreviewPriceProps = {
  readonly mode: EEntityPreviewMode;
  readonly price: Nullable<number>;
  readonly unit: Nullable<string>;
};

const BookingOfferPreviewPrice: FC<BookingOfferPreviewPriceProps> = props => {
  const { mode, price, unit } = props;
  return (
    <>
      {price !== null ? (
        <PriceWrapper mode={mode}>
          от
          <Price mode={mode}>
            <MPRubFormat
              showRub
              value={price}
            />
          </Price>
          {unit?.toLocaleLowerCase()}
        </PriceWrapper>
      ) : (
        <OfferPreviewSkeleton
          animation={false}
          variant='rectangular'
          width='47%'
          height={mode === EEntityPreviewMode.Mobile ? '1.5rem' : '1.625rem'}
        />
      )}
    </>
  );
};

export default BookingOfferPreviewPrice;
