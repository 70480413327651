import axios, { AxiosResponse } from 'axios';
import { SportOption } from '../../domain/model';
import { EPlaygroundModerationStatus, EPlaygroundStatus } from '../../domain/model/enums';
import { Playground } from '../../domain/model/playground';
import { Nullable, UUID } from '../../domain/model/types';
import { createCancelToken } from './index';
import {
  ApiCancellable,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
  ApiResponseWithPageable,
  ApiSportResponseMaBeCounters,
} from './types';
import { getSportBaseEndpoint } from './utils';

export type AllProps<D extends ApiRequestListDiscriminator> = ApiRequestPageable &
  ApiCancellable & {
    readonly name?: Nullable<string>;
    readonly latitude?: Nullable<number>;
    readonly longitude?: Nullable<number>;
    readonly radius?: Nullable<number>;
    readonly addressObjectId?: Nullable<UUID>;
    readonly activityTypes?: Nullable<UUID[]>;
    readonly type?: Nullable<UUID>;
    readonly paid?: Nullable<boolean>;
    readonly adminId?: Nullable<UUID>;
    readonly moderatedBy?: Nullable<UUID>;
    readonly moderationStatuses?: Nullable<EPlaygroundModerationStatus[]>;
    readonly creator?: Nullable<SportOption>;
    readonly status?: Nullable<EPlaygroundStatus>;
    readonly discriminator?: D;
  };

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type CreatorsProps = ApiCancellable & {
  readonly name: string;
};

type CreateProps = Playground;

type UpdateProps = Playground;

type BlockProps = {
  readonly id: UUID;
  readonly reason: string;
};

type UnblockProps = {
  readonly id: UUID;
};

type PlaygroundApi = {
  readonly all: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps<D>
  ) => Promise<AxiosResponse<ApiSportResponseMaBeCounters<D, ApiResponseWithPageable<Playground>>>>;
  readonly one: (props: OneProps) => Promise<AxiosResponse<Playground>>;
  readonly block: (props: BlockProps) => Promise<AxiosResponse<void>>;
  readonly unblock: (props: UnblockProps) => Promise<AxiosResponse<void>>;
  readonly create: (props: CreateProps) => Promise<AxiosResponse<Playground>>;
  readonly update: (props: UpdateProps) => Promise<AxiosResponse<Playground>>;
  readonly creators: (props: CreatorsProps) => Promise<AxiosResponse<SportOption[]>>;
};

/**
 * АПИ по работе с спортивными площадками
 */
const playground: PlaygroundApi = {
  all: ({
    page,
    pageSize,
    name,
    sort,
    addressObjectId,
    creator,
    status,
    type,
    paid,
    activityTypes,
    adminId,
    moderationStatuses,
    moderatedBy,
    discriminator,
    signal,
  }) => {
    const params = new URLSearchParams();

    if (name) params.append('name', name);

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    if (discriminator) params.append('resultType', discriminator);

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (addressObjectId) params.append('addressObjectId', addressObjectId);
    if (type) params.append('type', type);
    if (paid) params.append('paid', paid.toString());
    if (activityTypes) activityTypes.forEach(item => params.append('activityTypes', item));
    if (moderationStatuses)
      moderationStatuses.forEach(moderationStatus => params.append('moderationStatus', moderationStatus));
    if (adminId) params.append('adminId', adminId);
    if (creator) params.append('createdBy', creator.id);
    if (moderatedBy) params.append('moderatedBy', moderatedBy);

    return axios.get(`${getSportBaseEndpoint()}/admin/playgrounds`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },

  one: ({ id, signal }) => {
    return axios.get(`${getSportBaseEndpoint()}/playgrounds/${id}`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },

  block: ({ id, reason }) => {
    return axios.put(`${getSportBaseEndpoint()}/admin/playgrounds/${id}/block`, {
      reason,
    });
  },

  unblock: ({ id }) => {
    return axios.put(`${getSportBaseEndpoint()}/admin/playgrounds/${id}/unblock`);
  },

  create: data => {
    return axios.post(`${getSportBaseEndpoint()}/admin/playgrounds`, data);
  },

  update: data => {
    return axios.put(`${getSportBaseEndpoint()}/admin/playgrounds`, data);
  },

  creators: ({ name, signal }) => {
    const params = new URLSearchParams();

    params.append('name', name);

    return axios.get(`${getSportBaseEndpoint()}/playgrounds/creators`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
};

export default playground;
