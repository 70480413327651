import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TabsPanel from '../../../../components/common/tabs/panel';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { adCampaignTabsCounterSelector } from '../store/selectors';
import { EAdCampaignTableTab } from '../../types';
import { AdCampaignTableTab } from '../tab';
import { getAdCampaignTableTabName } from '../utils';

interface AdCampaignTableTabsAdapterProps {
  readonly tab: EAdCampaignTableTab;
}

export const AdCampaignTableTabsAdapter = (props: AdCampaignTableTabsAdapterProps) => {
  const { tab } = props;

  const { onChangeTab } = useContextHandlers();
  const { tabs } = useContextConfig();

  const counter = useSelector(adCampaignTabsCounterSelector);

  return useMemo(
    () => (
      <TabsPanel
        tab={tab}
        tabs={tabs}
        labelAdapter={AdCampaignTableTab}
        data={{
          counts: counter,
          getTabName: getAdCampaignTableTabName,
        }}
        onChangeTab={onChangeTab}
      />
    ),
    [tab, tabs, counter, onChangeTab]
  );
};
