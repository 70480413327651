import { FCC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PanelAction } from '../../../../types';
import { useClientOrgActions } from '../../actions/useActions';
import { ClientOrgActionCreateType, EClientOrgActionType } from '../../types';
import { getClientOrgActionsConfigByPermissions } from '../../utils/actions';
import { ClientOrgCreateContainerProps } from '../container';
import { ClientOrgCreateLifecycleContext, ClientOrgCreateLifecycleContextValue } from '../context';
import { useContextHandlers } from '../hooks/useContextHandlers';
import useClientOrgCreateActions from '../hooks/useCreateActions';
import { clientOrgCreateClientOrgFirLifeCycleSelector } from '../store/selectors';

export const ClientOrgCreateLifecycleProvider: FCC<ClientOrgCreateContainerProps> = ({
  children,
  step,
  lifecycle,
  access,
}) => {
  const handlers = useContextHandlers();

  const {
    utils: {
      selectors: { isClientOrgCreatedActionSelector, getIsClientOrgExecutedActionsSelector },
    },
  } = useClientOrgActions();

  const clientOrg = useSelector(clientOrgCreateClientOrgFirLifeCycleSelector);
  const actionsForClose = useSelector(getIsClientOrgExecutedActionsSelector(clientOrg.id, EClientOrgActionType.Edit));
  const isClose = useSelector(isClientOrgCreatedActionSelector) || !!actionsForClose;

  const allowedActions = getClientOrgActionsConfigByPermissions({
    ...access,
  });

  const lifeCycleRules = lifecycle.build({ allowedActions, obj: clientOrg });

  const actions = useClientOrgCreateActions({
    clientOrg,
    lifeCycleRules,
  });

  const onAction = async (action: PanelAction<ClientOrgActionCreateType>) => {
    const { type } = action;
    let savedClientOrg;
    switch (type) {
      case EClientOrgActionType.Edit:
        savedClientOrg = await handlers.onClientOrgSave();
        if (!savedClientOrg) {
          // Возвращаемся на шаг с ошибкой
          handlers.goToFirstIsNotValidStep();
        }
        break;
    }
  };

  useEffect(() => {
    if (isClose) {
      handlers.onClose();
    }
  }, [isClose, handlers.onClose]);

  const value: ClientOrgCreateLifecycleContextValue = {
    lifecycle,
    lifeCycleRules,
    actions,
    onAction,
  };

  return <ClientOrgCreateLifecycleContext.Provider value={value}>{children}</ClientOrgCreateLifecycleContext.Provider>;
};
