import mpAdmin, { UserMpAdminService } from './mpAdmin';
import clientOrgUser, { UserClientOrgService } from './clientOrgUser';

type UserServices = {
  readonly mpAdmin: UserMpAdminService;
  readonly clientOrgUser: UserClientOrgService;
};

const userServices: UserServices = {
  mpAdmin,
  clientOrgUser,
};

export default userServices;
