import CustomerTableCell, { CustomerTableCellCommonProps } from './index';

const CustomerTableCellEmail = (props: CustomerTableCellCommonProps) => {
  const {
    customer: { email: value },
  } = props;

  return (
    <CustomerTableCell
      {...props}
      value={value}
    />
  );
};

export default CustomerTableCellEmail;
