import common, { ChatCommonService } from './common';

type ChatServices = {
  readonly common: ChatCommonService;
};

const chatServices: ChatServices = {
  common,
};

export default chatServices;
