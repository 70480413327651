import MasterActionsComponent from '../../../../../components/common/actions/master';
import { EPanelActionPosition, PanelAction, PanelActions } from '../../../../../types';
import { useContextHandlers } from '../hooks/useContextHandlers';

enum EActionType {
  Select = 'select',
  Edit = 'edit',
}

const selectAction: PanelAction<EActionType> = {
  type: EActionType.Select,
  label: 'Выбрать',
  primary: true,
  position: [EPanelActionPosition.Default],
};

const editAction: PanelAction<EActionType> = {
  type: EActionType.Edit,
  label: 'Редактировать',
  position: [EPanelActionPosition.Menu],
};

export const CorpOfferCategoriesSelectFooterAdapter = () => {
  const handlers = useContextHandlers();

  const actions: PanelActions<EActionType> = [selectAction];
  if (handlers.onEdit) {
    actions.push(editAction);
  }

  const onAction = (action: PanelAction<EActionType>) => {
    switch (action.type) {
      case EActionType.Select:
        handlers.onClose();
        break;
      case EActionType.Edit:
        handlers.onEdit?.();
        break;
    }
  };

  return (
    <MasterActionsComponent
      actions={actions}
      onAction={onAction}
    />
  );
};
