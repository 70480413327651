//правила технической валидации (формат ввода)
import { EOrderItemStatus, EServiceOrderByDateType } from '../../../../domain/model/enums';
import { EValidationType, ValidationCollectionRules, ValidationRules } from '../../../utils/validation';
import { BookingOrderItemView, BookingOrderView } from '../types';

const orderItemsValidation = (value: BookingOrderItemView[]) => {
  // Проверяем есть ли хотя бы одна строка заказа
  if (!value?.length) {
    return {
      type: EValidationType.Error,
      hasError: true,
      message: 'Заполните таблицу строк заказа',
    };
  }

  // Проверяем что бы хотя бы одна строка была подтверждена
  for (let i = 0; i < value.length; i++) {
    if (value[i].status === EOrderItemStatus.Confirmed) {
      return null;
    }
  }

  return {
    type: EValidationType.Error,
    hasError: true,
    message: 'Хотя бы одна строка заказа должна быть подтверждена',
  };
};

const orderSlotsValidation = (object: BookingOrderItemView, value: BookingOrderItemView['slots']) => {
  const orderByType = object.priceItem.service.orderByDateType;
  if (
    (orderByType === EServiceOrderByDateType.Days || orderByType === EServiceOrderByDateType.Period) &&
    !value?.length
  ) {
    return {
      type: EValidationType.Error,
      hasError: true,
      message: 'Заполните количество',
    };
  }
  return null;
};

//правила технической валидации
const bookingOrderTechnicalValidationRules: ValidationRules<BookingOrderView> = {};

//правила бизнес валидации
export const bookingOrderBusinessValidationRules: ValidationRules<BookingOrderView> = {
  ...bookingOrderTechnicalValidationRules,
};

export const bookingOrderValidationRulesConfirm: ValidationRules<BookingOrderView> = {
  ...bookingOrderBusinessValidationRules,
  orderItems: {
    ...bookingOrderBusinessValidationRules.orderItems,
    required: true,
    validator: (object, value) => orderItemsValidation(value),
  },
};

export const bookingOrderValidationRulesGive: ValidationRules<BookingOrderView> = {
  ...bookingOrderBusinessValidationRules,
  orderItems: {
    ...bookingOrderBusinessValidationRules.orderItems,
    required: true,
    validator: (object, value) => orderItemsValidation(value),
  },
};

//правила технической валидации
const bookingOrderOrderItemTechnicalValidationRules: ValidationRules<BookingOrderItemView> = {};

//правила бизнес валидации
export const bookingOrderOrderItemBusinessValidationRules: ValidationRules<BookingOrderItemView> = {
  ...bookingOrderOrderItemTechnicalValidationRules,
  cost: {
    ...bookingOrderOrderItemTechnicalValidationRules.cost,
    required: true,
  },
  qty: {
    ...bookingOrderOrderItemTechnicalValidationRules.qty,
    required: object => object.priceItem.service.orderByDateType === EServiceOrderByDateType.None,
  },
  slots: {
    ...bookingOrderOrderItemTechnicalValidationRules.slots,
    required: object => object.priceItem.service.orderByDateType !== EServiceOrderByDateType.None,
    validator: (object, value) => orderSlotsValidation(object, value),
  },
  status: {
    ...bookingOrderOrderItemTechnicalValidationRules.status,
    required: true,
    validator: (object, value) => {
      if (![EOrderItemStatus.Confirmed, EOrderItemStatus.Cancelled].includes(value)) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Некорректный статус строки заказа!',
        };
      }
      return null;
    },
  },
};

export const bookingOrderOrderItemValidationRulesConfirm: ValidationCollectionRules<BookingOrderItemView> = {
  ...bookingOrderOrderItemBusinessValidationRules,
};

export const bookingOrderOrderItemValidationRulesGive: ValidationCollectionRules<BookingOrderItemView> = {
  ...bookingOrderOrderItemBusinessValidationRules,
};
