import { RootState } from '@/data/store/store';
import React from 'react';
import { UserCurrentActionsState } from './store/slice';

export interface UserCurrentActionsSelectors {
  readonly isCurrentUserEmailChangedSelector: (store: RootState) => boolean;
}

export interface UserCurrentActionsContext {
  readonly onChangeDialogState: (name: keyof UserCurrentActionsState['dialogs'], value: boolean) => void;
  readonly onEmailChanged: () => void;
  readonly onTryChangeEmail: () => void;

  readonly utils: {
    readonly selectors: UserCurrentActionsSelectors;
  };
}

export const UserCurrentActionsContext = React.createContext<UserCurrentActionsContext>(
  {} as UserCurrentActionsContext
);
