import { AxiosResponse } from 'axios';
import Api from '../../../../data/api';
import { ApiCancellable } from '../../../../data/api/types';
import { ESportEventStatus } from '../../../../domain/model/enums';
import { ActivityEvent, ActivityEventRequest } from '../../../../domain/model/event';
import { UUID } from '../../../../domain/model/types';
import eventServices from './';

export type ByIdProps = ApiCancellable & {
  id: UUID;
};

export type SaveProps = {
  activityEvent: ActivityEventRequest;
};

export type PublishProps = {
  activityEvent: ActivityEventRequest;
};

type ActivityEventsCancelProps = ApiCancellable & {
  readonly id: UUID;
};

export type ActivityEventService = {
  readonly one: (props: ByIdProps) => Promise<ActivityEvent>;
  readonly save: (props: SaveProps) => Promise<ActivityEvent>;
  readonly publish: (props: PublishProps) => Promise<ActivityEvent>;
  readonly cancel: (props: ActivityEventsCancelProps) => Promise<AxiosResponse<void>>;
};

export const service: ActivityEventService = {
  one: async ({ id, signal }) => {
    const { data } = await Api.event.activity.one({ id, signal });
    return data;
  },
  save: async ({ activityEvent }) => {
    const activityEventToSave = eventServices.common.beforeSave(activityEvent);

    const id = activityEventToSave.id;
    switch (activityEventToSave.status?.code ?? null) {
      case null:
        return (await Api.event.activity.create({ data: activityEventToSave })).data;
      case ESportEventStatus.Draft:
        return (await Api.event.activity.saveUnpublished({ data: activityEventToSave })).data;
      default:
        if (!id) {
          throw new Error('Не найден идентификатор объекта');
        }
        return (await Api.event.activity.save({ id, data: activityEventToSave })).data;
    }
  },
  publish: async ({ activityEvent }) => {
    const id = activityEvent.id;
    if (!id) {
      throw new Error('Не найден идентификатор объекта');
    }
    const { data } = await Api.event.activity.publish({ id });
    return data;
  },
  cancel: async ({ id }) => {
    return await Api.event.cancel({ id });
  },
};
