import { DataFilterStrategyDatePeriod } from '@/domain/model/filter';
import { EDateTimeFormat } from '@/domain/model/formats';
import { Nullable } from '@/domain/model/types';
import { MPDatePicker } from '@/presentation/theme/ui-kit/picker';
import { Grid } from '@mui/material';
import moment from 'moment-timezone';
import { DataFilterEditItemProps } from '../index';
import { MPToggleButtonsGroupSingle } from '@ui-kit/button/toggle';
import { EPeriod } from '@/domain';
import { useMemo } from 'react';

export interface DatePeriodItem {
  readonly id: EPeriod;
  readonly name: string;
}

interface DataFilterEditItemDatePeriodProps<T extends string>
  extends DataFilterEditItemProps<T, Nullable<[Nullable<string>, Nullable<string>]>> {
  readonly strategy: DataFilterStrategyDatePeriod<T>;
}

export const datePeriods: DatePeriodItem[] = [
  { id: EPeriod.Week, name: 'Неделя' },
  { id: EPeriod.Month, name: 'Месяц' },
  { id: EPeriod.Quarter, name: 'Квартал' },
  { id: EPeriod.Year, name: 'Год' },
];

export const getPastPeriodDates = (period: EPeriod): [string, string] => {
  const end = moment().endOf('day').format(EDateTimeFormat.Server);

  switch (period) {
    case EPeriod.Week:
      return [moment().add(-1, 'week').add(1, 'day').startOf('day').format(EDateTimeFormat.Server), end];
    case EPeriod.Month:
      return [moment().add(-1, 'month').add(1, 'day').startOf('day').format(EDateTimeFormat.Server), end];
    case EPeriod.Quarter:
      return [moment().add(-1, 'quarter').add(1, 'day').startOf('day').format(EDateTimeFormat.Server), end];
    case EPeriod.Year:
      return [moment().add(-1, 'year').add(1, 'day').startOf('day').format(EDateTimeFormat.Server), end];
  }
};

export const getPeriodByDates = (periods: DatePeriodItem[], from: string, to: string): Nullable<DatePeriodItem> => {
  let result: Nullable<DatePeriodItem> = null;

  Object.values(EPeriod).forEach(period => {
    const periodDates = getPastPeriodDates(period);

    if (periodDates[0].valueOf() === from.valueOf() && periodDates[1].valueOf() === to.valueOf()) {
      result = periods.find(p => p.id === period) ?? null;
    }
  });
  return result;
};

function DataFilterEditItemDatePeriod<T extends string>(props: DataFilterEditItemDatePeriodProps<T>) {
  const { strategy, onChange } = props;

  const { value, readOnly, labelPeriod } = strategy;

  const onChangeInternal = (from: Nullable<string>, to: Nullable<string>) => {
    const newValue: Nullable<[Nullable<string>, Nullable<string>]> = from === null && to === null ? null : [from, to];
    onChange(strategy, newValue);
  };

  const onChangeSpecialPeriod = (periodType: Nullable<DatePeriodItem>) => {
    if (!periodType) {
      return onChange(strategy, value);
    }

    const period = getPastPeriodDates(periodType.id);
    return onChange(strategy, period);
  };

  const selectedPeriod: Nullable<DatePeriodItem> = useMemo(
    () => (value?.[0] && value?.[1] ? getPeriodByDates(datePeriods, value[0], value[1]) : null),
    [value]
  );

  return (
    <Grid
      container
      spacing={2}
    >
      {strategy.buttonsPeriod && (
        <Grid
          item
          xs={12}
        >
          <MPToggleButtonsGroupSingle<DatePeriodItem>
            size='medium'
            items={datePeriods}
            fullWidth
            value={selectedPeriod}
            onChange={onChangeSpecialPeriod}
          />
        </Grid>
      )}

      <Grid
        item
        xs={6}
      >
        <MPDatePicker
          label={labelPeriod.from}
          value={value?.[0]}
          disabled={readOnly}
          onChange={date =>
            onChangeInternal(
              date ? moment(date).startOf('day').format(EDateTimeFormat.Server) : null,
              value?.[1] ?? null
            )
          }
        />
      </Grid>
      <Grid
        item
        xs={6}
      >
        <MPDatePicker
          label={labelPeriod.to}
          value={value?.[1]}
          disabled={readOnly}
          onChange={date =>
            onChangeInternal(value?.[0] ?? null, date ? moment(date).endOf('day').format(EDateTimeFormat.Server) : null)
          }
        />
      </Grid>
    </Grid>
  );
}

export default DataFilterEditItemDatePeriod;
