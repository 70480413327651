import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault, fetchableFailed, fetchableFetched, fetchableFetching } from '@/data/store/types';
import {
  ActivityEvent,
  ActivityEventRequest,
  ESportEventLevel,
  EUserType,
  SportEnumOption,
  SportUserProfile,
} from '@/domain';
import {
  CaseReducer,
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { ValidationItemResult, ValidationResult } from '../../../../../utils/validation';
import eventServices from '../../../services';
import { ActivityEventCreateStepType } from '../../../types';
import { ActivityEventCreateUiState } from '../types';
import { convertActivityEventToActivityEventRequest, createNewActivityEventRequest } from '../utils';

export const activityEventCreateByIdFetch = createAsyncThunk<
  Nullable<ActivityEvent>,
  {
    id: Nullable<UUID>;
    level: Nullable<SportEnumOption<ESportEventLevel>>;
    userType: Nullable<EUserType>;
    admin: Nullable<SportUserProfile>;
  },
  AppThunkAPIConfig
>('activityEvent/create/byId/fetch', async ({ id }, { rejectWithValue, signal }) => {
  try {
    if (!id) return null;
    return await eventServices.activity.one({ id, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const activityEventCreateEventAdmins = createAsyncThunk<SportUserProfile[], {}, AppThunkAPIConfig>(
  'activityEvent/create/eventAdmins',
  async (data, { rejectWithValue }) => {
    try {
      const response = await eventServices.users.eventAdmins();
      return response.content;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const activityEventCreateSave = createAsyncThunk<ActivityEvent, ActivityEventRequest, AppThunkAPIConfig>(
  'activityEvent/create/save',
  async (activityEvent, { rejectWithValue }) => {
    try {
      return await eventServices.activity.save({ activityEvent });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const activityEventCreatePublish = createAsyncThunk<ActivityEvent, ActivityEventRequest, AppThunkAPIConfig>(
  'activityEvent/create/publish',
  async (activityEvent, { rejectWithValue }) => {
    try {
      return await eventServices.activity.publish({ activityEvent });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export type ActivityEventCreateValidationStepper = Nullable<
  Record<ActivityEventCreateStepType, Nullable<ValidationResult<any>>>
>;

export interface ActivityEventCreateState {
  readonly guid: Nullable<UUID>;
  readonly modified: boolean;
  readonly byId: Fetchable & {
    readonly id: Nullable<UUID>;
    readonly event: ActivityEventRequest;
    readonly loadedData: Nullable<ActivityEvent>;
  };
  readonly eventAdmins: Fetchable & {
    readonly data: SportUserProfile[];
  };
  readonly publish: Fetchable;
  readonly save: Fetchable;
  readonly dialogs: {
    readonly close: Nullable<ActivityEventRequest>;
  };
  readonly validation: ValidationResult<ActivityEventRequest>;
  readonly validationStepper: ActivityEventCreateValidationStepper;
  readonly ui: Nullable<ActivityEventCreateUiState>;
}

type Reducer<T = undefined> = CaseReducer<ActivityEventCreateState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ActivityEventCreateState> {
  activityEventCreateStartSession: Reducer<{
    guid: Nullable<UUID>;
  }>;
  activityEventCreateSetAttribute: Reducer<{
    name: keyof ActivityEventRequest;
    value: any;
  }>;
  activityEventCreateSetModified: Reducer<boolean>;
  activityEventCreateSetUiState: Reducer<{
    name: keyof ActivityEventCreateUiState;
    value: any;
  }>;
  activityEventCreateClearAttributeValidation: Reducer<keyof ActivityEventRequest>;
  activityEventCreateSetAttributeValidation: Reducer<{
    name: keyof ActivityEventRequest;
    results: Nullable<ValidationItemResult>;
  }>;
  activityEventCreateSetValidation: Reducer<Nullable<ValidationResult<ActivityEventRequest>>>;
  activityEventCreateSetValidationStepper: Reducer<
    Nullable<Record<ActivityEventCreateStepType, Nullable<ValidationResult<any>>>>
  >;
  activityEventCreateClearAllValidations: Reducer;
  activityEventCreateClearActionsState: Reducer;
  activityEventCreateSetDialogState: Reducer<{
    name: keyof ActivityEventCreateState['dialogs'];
    data: Nullable<ActivityEventRequest>;
  }>;
}

const slice = createSlice<ActivityEventCreateState, Reducers, 'activityEvent/create'>({
  name: 'activityEvent/create',
  initialState: {
    guid: null,
    modified: false,
    byId: {
      ...fetchableDefault,
      id: null,
      event: createNewActivityEventRequest(),
      loadedData: null,
    },
    eventAdmins: {
      ...fetchableDefault,
      data: [],
    },
    publish: {
      ...fetchableDefault,
    },
    save: {
      ...fetchableDefault,
    },
    dialogs: {
      close: null,
    },
    validation: {},
    validationStepper: null,
    ui: null,
  },
  reducers: {
    activityEventCreateStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;
        state.byId = {
          ...fetchableDefault,
          id: null,
          event: createNewActivityEventRequest(),
          loadedData: null,
        };
        state.modified = false;
        state.validation = {};
        state.ui = null;
      }
    },
    activityEventCreateSetModified: (state, { payload }) => {
      state.modified = payload;
    },
    activityEventCreateClearActionsState: state => {
      state.byId.isFetching = false;
      state.byId.isFetched = false;
      state.byId.isFailed = false;
      
      state.publish = fetchableDefault;
      state.save = fetchableDefault;
      state.validation = {};
      state.validationStepper = null;
      state.ui = null;
      state.dialogs = {
        close: null,
      };
    },
    activityEventCreateSetAttribute: (state, { payload }) => {
      const { name, value } = payload;

      if (state.byId.event) {
        (state.byId.event[name] as keyof ActivityEventRequest) = value;
      }
    },
    activityEventCreateSetUiState: (state, { payload }) => {
      const { name, value } = payload;
      if (!state.ui) {
        state.ui = {
          steps: [],
          fields: {
            visible: [],
            disabled: [],
          },
        };
      }
      state.ui[name] = value;
    },
    activityEventCreateClearAttributeValidation: (state, { payload }) => {
      delete state.validation?.[payload];
    },
    activityEventCreateSetAttributeValidation: (state, { payload }) => {
      const { name, results } = payload;
      if (!results) {
        delete state.validation?.[name];
      } else {
        state.validation[name] = results;
      }
    },
    activityEventCreateSetValidation: (state, { payload }) => {
      state.validation = payload ?? {};
    },
    activityEventCreateSetValidationStepper: (state, { payload }) => {
      state.validationStepper = payload;
    },
    activityEventCreateClearAllValidations: state => {
      state.validation = {};
    },
    activityEventCreateSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(activityEventCreateByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
      })
      .addCase(activityEventCreateByIdFetch.fulfilled, (state, { meta, payload }) => {
        const { level, userType, admin } = meta.arg;
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;
        state.byId.event = payload
          ? convertActivityEventToActivityEventRequest(payload)
          : {
              ...createNewActivityEventRequest(),
              level,
              userType,
              admin,
            };
        state.byId.loadedData = payload;
      })
      .addCase(activityEventCreateByIdFetch.rejected, state => {
        state.byId.isFetching = false;
        state.byId.isFetched = false;
        state.byId.isFailed = true;
      })
      .addCase(activityEventCreateSave.pending, state => {
        state.save = fetchableFetching;
      })
      .addCase(activityEventCreateSave.fulfilled, (state, { payload }) => {
        state.save = fetchableFetched;

        state.byId.event = convertActivityEventToActivityEventRequest(payload);
        state.byId.loadedData = payload;
      })
      .addCase(activityEventCreateSave.rejected, state => {
        state.save = fetchableFailed;
      })
      .addCase(activityEventCreateEventAdmins.pending, state => {
        state.eventAdmins = { data: state.eventAdmins.data, ...fetchableFetching };
      })
      .addCase(activityEventCreateEventAdmins.fulfilled, (state, { payload }) => {
        state.eventAdmins = { data: payload, ...fetchableFetched };
      })
      .addCase(activityEventCreateEventAdmins.rejected, state => {
        state.eventAdmins = { data: state.eventAdmins.data, ...fetchableFailed };
      })
      .addMatcher(isAnyOf(activityEventCreatePublish.pending), state => {
        state.publish = fetchableFetching;
      })
      .addMatcher(isAnyOf(activityEventCreatePublish.fulfilled), (state, { payload }) => {
        state.publish = fetchableFetched;

        state.byId.event = convertActivityEventToActivityEventRequest(payload);
        state.byId.loadedData = payload;
      })
      .addMatcher(isAnyOf(activityEventCreatePublish.rejected), state => {
        state.publish = fetchableFailed;
      });
  },
});

export const {
  activityEventCreateSetAttribute,
  activityEventCreateSetModified,
  activityEventCreateClearActionsState,
  activityEventCreateStartSession,
  activityEventCreateSetUiState,
  activityEventCreateClearAttributeValidation,
  activityEventCreateSetAttributeValidation,
  activityEventCreateSetValidation,
  activityEventCreateClearAllValidations,
  activityEventCreateSetDialogState,
  activityEventCreateSetStatistics,
  activityEventCreateSetValidationStepper,
} = slice.actions;

export default slice.reducer;
