import { ApiRequestPageable } from '@/data/api/types';
import { appendQueryDslParams, getCorpOffersDownloadEndpoint, getQueryDslByDataFilterValues } from '@/data/api/utils';
import { CorpOffer, DataFilterQueryDslOperator, EOfferStatus, Nullable, UUID } from '@/domain';
import { DataTableColumns, ETableColumnAlign } from '@components/common/table';
import { EOfferTableTab, OfferActionTableCommonType } from '../../general/offer/types';
import { CorpOfferTableFilterValues } from '../filterUtils';
import { ECorpOfferTableColumn } from '../types';

enum ECorpOffersDownloadUrlParam {
  Status = 'status',
  PartnerId = 'partner.id',
}

type GetCorpOffersDownloadUrlWithSkipPaginationProps = {
  readonly skipPageable: true;
} & Partial<ApiRequestPageable>;

type GetCorpOffersDownloadUrlWithoutSkipPaginationProps = {
  readonly skipPageable?: false;
} & ApiRequestPageable;

type MabeSkipPaginationGetCorpOffersDownloadUrlProps =
  | GetCorpOffersDownloadUrlWithSkipPaginationProps
  | GetCorpOffersDownloadUrlWithoutSkipPaginationProps;

type GetCorpOffersDownloadUrlProps = {
  readonly id?: Nullable<UUID[]>;
  readonly partnerId?: Nullable<UUID>;
  readonly states?: Nullable<EOfferStatus[]>;
  readonly filter: CorpOfferTableFilterValues;
} & MabeSkipPaginationGetCorpOffersDownloadUrlProps;

export const getCorpOffersDownloadUrl = (props: GetCorpOffersDownloadUrlProps) => {
  const { sort, page, pageSize, id, partnerId, states, filter, skipPageable } = props;

  const searchParams = new URLSearchParams();

  if (sort) {
    if (typeof sort === 'string') {
      searchParams.append('sort', sort);
    } else {
      sort.forEach(item => searchParams.append('sort', item));
    }
  }

  if (skipPageable) {
    searchParams.append('skipPageable', skipPageable.toString());
  } else {
    page && searchParams.append('page', (page - 1).toString(10));
    pageSize && searchParams.append('size', pageSize.toString(10));
  }

  if (id && id.length > 0) {
    id.forEach(item => searchParams.append('id', item));
  } else {
    const queryDsl = getQueryDslByDataFilterValues(filter) ?? [];

    if (states) {
      queryDsl.push({
        field: ECorpOffersDownloadUrlParam.Status,
        operator: DataFilterQueryDslOperator.In,
        value: states,
      });
    }

    if (partnerId) {
      queryDsl.push({
        field: ECorpOffersDownloadUrlParam.PartnerId,
        operator: DataFilterQueryDslOperator.Equals,
        value: [partnerId],
      });
    }

    appendQueryDslParams(searchParams, queryDsl);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return `${getCorpOffersDownloadEndpoint()}${search}`;
};

export const corpOffersTableColumns: DataTableColumns<ECorpOfferTableColumn> = {
  [ECorpOfferTableColumn.LastStatusDate]: {
    title: 'Дата',
    sortable: true,
    width: '5.5rem',
  },
  [ECorpOfferTableColumn.Code]: {
    title: 'Код КП',
    sortable: true,
    width: '6rem',
  },
  [ECorpOfferTableColumn.Name]: {
    title: 'Название',
    width: '30rem',
    wrap: true,
    sortable: true,
  },
  [ECorpOfferTableColumn.Price]: {
    title: 'Стоимость',
    width: '10rem',
    align: ETableColumnAlign.Right,
    sortable: true,
  },
  [ECorpOfferTableColumn.Period]: {
    width: '10rem',
    title: 'Начало - окончание',
    sortable: true,
  },
  [ECorpOfferTableColumn.Partner]: {
    title: 'Партнёр',
    width: '20rem',
    wrap: true,
    sortable: true,
  },
  [ECorpOfferTableColumn.PartnerType]: {
    title: 'Вид партнёра',
    width: '15rem',
    wrap: true,
    sortable: true,
  },
  [ECorpOfferTableColumn.Ndfl]: {
    title: 'НДФЛ',
    width: '20rem',
    wrap: true,
    sortable: true,
  },
  [ECorpOfferTableColumn.Status]: {
    title: 'Статус',
    sortable: true,
  },
  [ECorpOfferTableColumn.Moderator]: {
    title: 'Модератор',
    width: '15rem',
    wrap: true,
    sortable: true,
  },
  [ECorpOfferTableColumn.LastStatusAuthor]: {
    title: 'Статус изменил',
    width: '15rem',
    wrap: true,
    sortable: true,
  },
  [ECorpOfferTableColumn.OfferCounts]: {
    title: 'Доступно/всего',
    width: '8rem',
    sortable: true,
  },
  [ECorpOfferTableColumn.PausedReason]: {
    title: 'Причина остановки',
    width: '13rem',
    wrap: true,
    sortable: true,
  },
  [ECorpOfferTableColumn.ApprovalRegistry]: {
    title: 'Приказ',
    width: '15rem',
    sortable: true,
  },
  [ECorpOfferTableColumn.PartnerResponsible]: {
    title: 'Ответственный за формирование',
    width: '15rem',
    sortable: true,
    wrap: true,
  },
  [ECorpOfferTableColumn.StoreResponsible]: {
    title: 'Ответственный за выдачу',
    width: '15rem',
    sortable: true,
    wrap: true,
  },
  [ECorpOfferTableColumn.Categories]: {
    title: 'Категория',
    width: '15rem',
    wrap: true,
  },
  [ECorpOfferTableColumn.TargetLocalities]: {
    title: 'Город',
    width: '15rem',
    wrap: true,
  },
  [ECorpOfferTableColumn.PromotionType]: {
    title: 'Способ получения',
    width: '15rem',
    sortable: true,
    wrap: true,
  },
  [ECorpOfferTableColumn.TargetRoads]: {
    title: 'Дорога',
    width: '15rem',
    wrap: true,
  },
  [ECorpOfferTableColumn.TargetOrgUnits]: {
    title: 'Филиал',
    width: '15rem',
    wrap: true,
  },
  [ECorpOfferTableColumn.TargetTradeUnionMembersOnly]: {
    title: 'Только для членов профсоюза',
    width: '8rem',
    sortable: true,
  },
  [ECorpOfferTableColumn.TargetHavingChildFamilyMemberOnly]: {
    title: 'Только если есть член семьи (ребенок до 18 лет)',
    width: '8rem',
    sortable: true,
  },
  [ECorpOfferTableColumn.TargetFamilyMemberOnly]: {
    title: 'Только если есть член семьи от 18 лет',
    width: '8rem',
    sortable: true,
  },
  [ECorpOfferTableColumn.TargetGender]: {
    title: 'Пол',
    width: '8rem',
    sortable: true,
  },
  [ECorpOfferTableColumn.Inn]: {
    title: 'ИНН',
    width: '6rem',
    sortable: true,
  },
  [ECorpOfferTableColumn.Kpp]: {
    title: 'КПП',
    width: '6rem',
    sortable: true,
  },
  [ECorpOfferTableColumn.Ogrn]: {
    title: 'ОГРН',
    width: '8rem',
    sortable: true,
  },
};

const corpOfferTableDefaultColumns = [
  ECorpOfferTableColumn.LastStatusDate,
  ECorpOfferTableColumn.Code,
  ECorpOfferTableColumn.Name,
  ECorpOfferTableColumn.Partner,
  ECorpOfferTableColumn.PartnerType,
  ECorpOfferTableColumn.Period,
  ECorpOfferTableColumn.Price,
  ECorpOfferTableColumn.OfferCounts,
  ECorpOfferTableColumn.Ndfl,
  ECorpOfferTableColumn.PartnerResponsible,
  ECorpOfferTableColumn.StoreResponsible,
  ECorpOfferTableColumn.Moderator,
];

export const getCorpOfferAdminTableColumns = (
  tab: EOfferTableTab,
  partnerId: Nullable<UUID>,
  canViewApprovalRegistry: boolean
): DataTableColumns<ECorpOfferTableColumn> => {
  const defaultColumns = getCorpOfferTableDefaultColumns(tab, partnerId, canViewApprovalRegistry);

  const allColumns: ECorpOfferTableColumn[] = [];
  switch (tab) {
    case EOfferTableTab.Moderation:
    case EOfferTableTab.Upcoming:
      allColumns.push(
        ECorpOfferTableColumn.LastStatusDate,
        ECorpOfferTableColumn.Code,
        ECorpOfferTableColumn.Name,
        ECorpOfferTableColumn.Partner,
        ECorpOfferTableColumn.PartnerType,
        ECorpOfferTableColumn.Period,
        ECorpOfferTableColumn.Price,
        ECorpOfferTableColumn.OfferCounts,
        ECorpOfferTableColumn.Ndfl,
        ECorpOfferTableColumn.PartnerResponsible,
        ECorpOfferTableColumn.StoreResponsible,
        ECorpOfferTableColumn.Moderator,
        ECorpOfferTableColumn.LastStatusAuthor,
        ECorpOfferTableColumn.Categories,
        ECorpOfferTableColumn.TargetLocalities,
        ECorpOfferTableColumn.PromotionType,
        ECorpOfferTableColumn.TargetRoads,
        ECorpOfferTableColumn.TargetOrgUnits,
        ECorpOfferTableColumn.TargetTradeUnionMembersOnly,
        ECorpOfferTableColumn.TargetHavingChildFamilyMemberOnly,
        ECorpOfferTableColumn.TargetFamilyMemberOnly,
        ECorpOfferTableColumn.TargetGender,

        ECorpOfferTableColumn.Inn,
        ECorpOfferTableColumn.Kpp,
        ECorpOfferTableColumn.Ogrn
      );
      break;
    case EOfferTableTab.Paused:
      allColumns.push(
        ECorpOfferTableColumn.LastStatusDate,
        ECorpOfferTableColumn.Code,
        ECorpOfferTableColumn.Name,
        ECorpOfferTableColumn.Partner,
        ECorpOfferTableColumn.PartnerType,
        ECorpOfferTableColumn.Period,
        ECorpOfferTableColumn.Price,
        ECorpOfferTableColumn.OfferCounts,
        ECorpOfferTableColumn.Ndfl,
        ECorpOfferTableColumn.PartnerResponsible,
        ECorpOfferTableColumn.StoreResponsible,
        ECorpOfferTableColumn.Moderator,
        ECorpOfferTableColumn.PausedReason,
        ECorpOfferTableColumn.LastStatusAuthor,
        ECorpOfferTableColumn.Categories,
        ECorpOfferTableColumn.TargetLocalities,
        ECorpOfferTableColumn.PromotionType,
        ECorpOfferTableColumn.TargetRoads,
        ECorpOfferTableColumn.TargetOrgUnits,
        ECorpOfferTableColumn.TargetTradeUnionMembersOnly,
        ECorpOfferTableColumn.TargetHavingChildFamilyMemberOnly,
        ECorpOfferTableColumn.TargetFamilyMemberOnly,
        ECorpOfferTableColumn.TargetGender,

        ECorpOfferTableColumn.Inn,
        ECorpOfferTableColumn.Kpp,
        ECorpOfferTableColumn.Ogrn
      );
      break;
    case EOfferTableTab.Active:
      allColumns.push(
        ECorpOfferTableColumn.LastStatusDate,
        ECorpOfferTableColumn.Code,
        ECorpOfferTableColumn.Name,
        ECorpOfferTableColumn.Partner,
        ECorpOfferTableColumn.PartnerType,
        ECorpOfferTableColumn.Period,
        ECorpOfferTableColumn.Price,
        ECorpOfferTableColumn.OfferCounts,
        ECorpOfferTableColumn.Ndfl,
        ECorpOfferTableColumn.PartnerResponsible,
        ECorpOfferTableColumn.StoreResponsible,
        ECorpOfferTableColumn.Moderator,

        ECorpOfferTableColumn.LastStatusAuthor,

        ECorpOfferTableColumn.Categories,
        ECorpOfferTableColumn.TargetLocalities,
        ECorpOfferTableColumn.PromotionType,
        ECorpOfferTableColumn.TargetRoads,
        ECorpOfferTableColumn.TargetOrgUnits,
        ECorpOfferTableColumn.TargetTradeUnionMembersOnly,
        ECorpOfferTableColumn.TargetHavingChildFamilyMemberOnly,
        ECorpOfferTableColumn.TargetFamilyMemberOnly,
        ECorpOfferTableColumn.TargetGender,

        ECorpOfferTableColumn.Inn,
        ECorpOfferTableColumn.Kpp,
        ECorpOfferTableColumn.Ogrn
      );
      break;
    default:
      allColumns.push(
        ECorpOfferTableColumn.LastStatusDate,
        ECorpOfferTableColumn.Code,
        ECorpOfferTableColumn.Name,
        ECorpOfferTableColumn.Partner,
        ECorpOfferTableColumn.PartnerType,
        ECorpOfferTableColumn.Period,
        ECorpOfferTableColumn.Price,
        ECorpOfferTableColumn.OfferCounts,
        ECorpOfferTableColumn.Ndfl,
        ECorpOfferTableColumn.PartnerResponsible,
        ECorpOfferTableColumn.StoreResponsible,
        ECorpOfferTableColumn.Moderator,
        ECorpOfferTableColumn.LastStatusAuthor,
        ECorpOfferTableColumn.Categories,
        ECorpOfferTableColumn.TargetLocalities,
        ECorpOfferTableColumn.PromotionType,
        ECorpOfferTableColumn.TargetRoads,
        ECorpOfferTableColumn.TargetOrgUnits,
        ECorpOfferTableColumn.TargetTradeUnionMembersOnly,
        ECorpOfferTableColumn.TargetHavingChildFamilyMemberOnly,
        ECorpOfferTableColumn.TargetFamilyMemberOnly,
        ECorpOfferTableColumn.TargetGender,

        ECorpOfferTableColumn.Inn,
        ECorpOfferTableColumn.Kpp,
        ECorpOfferTableColumn.Ogrn
      );
      break;
  }

  if (canViewApprovalRegistry) {
    allColumns.push(ECorpOfferTableColumn.ApprovalRegistry);
  }

  if (partnerId) {
    allColumns.splice(allColumns.indexOf(ECorpOfferTableColumn.Partner), 1);
  }

  return allColumns.reduce<DataTableColumns<ECorpOfferTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...corpOffersTableColumns[column],
        hidden: !defaultColumns.includes(column),
      },
    };
  }, {});
};

export type CorpOfferActionCall = {
  readonly action: OfferActionTableCommonType;
  readonly corpOffer: CorpOffer;
};

export type CorpOfferCollectionActionPayload = {
  readonly corpOffers: CorpOffer[];
};

const getCorpOfferTableDefaultColumns = (
  tab: EOfferTableTab,
  partnerId: Nullable<UUID>,
  canViewApprovalRegistry: boolean
): ECorpOfferTableColumn[] => {
  let columns: ECorpOfferTableColumn[];
  switch (tab) {
    case EOfferTableTab.Active:
      columns = [...corpOfferTableDefaultColumns];
      break;
    case EOfferTableTab.Paused:
      columns = [
        ...corpOfferTableDefaultColumns,
        ECorpOfferTableColumn.PausedReason,
        ECorpOfferTableColumn.LastStatusAuthor,
      ];
      break;
    default:
      columns = corpOfferTableDefaultColumns;
  }

  if (canViewApprovalRegistry) {
    columns.push(ECorpOfferTableColumn.ApprovalRegistry);
  }

  if (partnerId) {
    columns.splice(columns.indexOf(ECorpOfferTableColumn.Partner), 1);
  }

  return columns;
};

const corpOfferOwnerTableDefaultColumns = [
  ECorpOfferTableColumn.Code,
  ECorpOfferTableColumn.Name,
  ECorpOfferTableColumn.Period,
  ECorpOfferTableColumn.OfferCounts,
];

export const getCorpOfferOwnerTableColumns = (tab: EOfferTableTab): DataTableColumns<ECorpOfferTableColumn> => {
  const defaultColumns = getCorpOfferOwnerTableDefaultColumns(tab);

  const allColumns: ECorpOfferTableColumn[] = [];

  switch (tab) {
    case EOfferTableTab.Moderation:
      allColumns.push(
        ECorpOfferTableColumn.Code,
        ECorpOfferTableColumn.Name,
        ECorpOfferTableColumn.Period,
        ECorpOfferTableColumn.Status,
        ECorpOfferTableColumn.OfferCounts,
        ECorpOfferTableColumn.PartnerResponsible,
        ECorpOfferTableColumn.StoreResponsible,
        ECorpOfferTableColumn.Moderator,
        ECorpOfferTableColumn.TargetTradeUnionMembersOnly,
        ECorpOfferTableColumn.TargetHavingChildFamilyMemberOnly,
        ECorpOfferTableColumn.TargetFamilyMemberOnly,
        ECorpOfferTableColumn.TargetGender,
        ECorpOfferTableColumn.TargetRoads,
        ECorpOfferTableColumn.TargetLocalities,
        ECorpOfferTableColumn.TargetOrgUnits
      );
      break;
    case EOfferTableTab.Paused:
      allColumns.push(
        ECorpOfferTableColumn.Code,
        ECorpOfferTableColumn.Name,
        ECorpOfferTableColumn.Period,
        ECorpOfferTableColumn.OfferCounts,
        ECorpOfferTableColumn.PausedReason,
        ECorpOfferTableColumn.PartnerResponsible,
        ECorpOfferTableColumn.StoreResponsible,
        ECorpOfferTableColumn.Moderator,
        ECorpOfferTableColumn.TargetTradeUnionMembersOnly,
        ECorpOfferTableColumn.TargetHavingChildFamilyMemberOnly,
        ECorpOfferTableColumn.TargetFamilyMemberOnly,
        ECorpOfferTableColumn.TargetGender,
        ECorpOfferTableColumn.TargetRoads,
        ECorpOfferTableColumn.TargetLocalities,
        ECorpOfferTableColumn.TargetOrgUnits
      );
      break;
    default:
      allColumns.push(
        ECorpOfferTableColumn.Code,
        ECorpOfferTableColumn.Name,
        ECorpOfferTableColumn.Period,
        ECorpOfferTableColumn.OfferCounts,
        ECorpOfferTableColumn.PartnerResponsible,
        ECorpOfferTableColumn.StoreResponsible,
        ECorpOfferTableColumn.Moderator,
        ECorpOfferTableColumn.TargetTradeUnionMembersOnly,
        ECorpOfferTableColumn.TargetHavingChildFamilyMemberOnly,
        ECorpOfferTableColumn.TargetFamilyMemberOnly,
        ECorpOfferTableColumn.TargetGender,
        ECorpOfferTableColumn.TargetRoads,
        ECorpOfferTableColumn.TargetLocalities,
        ECorpOfferTableColumn.TargetOrgUnits
      );
  }
  return allColumns.reduce<DataTableColumns<ECorpOfferTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...corpOffersTableColumns[column],
        hidden: !defaultColumns.includes(column),
      },
    };
  }, {});
};

const getCorpOfferOwnerTableDefaultColumns = (tab: EOfferTableTab): ECorpOfferTableColumn[] => {
  return corpOfferOwnerTableDefaultColumns;
};
