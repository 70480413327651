import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '@/data/api/utils';
import { ServerErrorResponse } from '@/data/network/types';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { Nullable, Pageable, PersonalPromotion, UUID } from '@/domain';
import {
  personalPromotionCreateGenerate,
  personalPromotionCreateUpload,
} from '@features/personalPromotion/create/store/slice';
import { tradeOfferActionsNotUsedCodesDelete } from '@features/tradeOffer/actions/store/slice';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { PaginationSize } from '../../../../types';
import { corpOfferCreateNotUsedCodesDelete } from '../../../corpOffer/create/store/slice';
import { corpOfferDetailsNotUsedCodesDelete } from '../../../corpOffer/details/store/slice';
import { EPromotionActionType } from '../../types';
import { PersonalPromotionsFilterValues } from '../filterUtils';

const sortDefault = 'code,asc';
const pageSizeDefault: PaginationSize = 10;

const getActionProcess = (state: PersonalPromotionTableState, id: UUID, actionType: EPromotionActionType) => {
  let process = state.actions.find(change => change.id === id);
  if (process) return process;

  process = {
    ...fetchableDefault,
    id,
    type: actionType,
    error: null,
  };
  state.actions.push(process);

  return process;
};

export interface PersonalPromotionFetchProps {
  readonly offerId: UUID;
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string>;
  };
  readonly filter: PersonalPromotionsFilterValues;
  readonly pageNumber: number;
}

export const personalPromotionTableFetch = createAsyncThunk<
  Pageable<PersonalPromotion>,
  PersonalPromotionFetchProps,
  AppThunkAPIConfig
>('personalPromotion/table/fetch', async ({ search, filter, pageNumber, offerId }, { rejectWithValue, signal }) => {
  try {
    const { pageSize, sort } = search;
    const querydsl = getQueryDslByDataFilterValues(filter);

    const response = await Api.personalPromotion.all({
      page: pageNumber,
      pageSize,
      sort,
      querydsl,
      offerId,
      signal,
    });
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);
    return { data: response.data, totalCount, pageCount, pageNumber: page };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const personalPromotionTableEnabled = createAsyncThunk<PersonalPromotion, UUID, AppThunkAPIConfig>(
  'personalPromotion/table/enabled',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await Api.personalPromotion.enabled({ id });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const personalPromotionTableDisabled = createAsyncThunk<PersonalPromotion, UUID, AppThunkAPIConfig>(
  'personalPromotion/table/disabled',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await Api.personalPromotion.disabled({ id });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface PersonalPromotionTableState {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly changeCountWatcher: number;
  readonly promotions: Fetchable & Pageable<PersonalPromotion>;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
  };
  readonly filter: PersonalPromotionsFilterValues;
  readonly actions: (Fetchable & {
    id: UUID;
    type: EPromotionActionType;
    error: Nullable<ServerErrorResponse>;
  })[];
}

type Reducer<T = undefined> = CaseReducer<PersonalPromotionTableState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PersonalPromotionTableState> {
  personalPromotionTableStartSession: Reducer<UUID>;
  personalPromotionTableStateReset: Reducer;
  personalPromotionTableSetSearch: Reducer<{ sort: string; pageSize: PaginationSize }>;
  personalPromotionTableSetPage: Reducer<number>;
  personalPromotionTableSetPageSize: Reducer<PaginationSize>;
  personalPromotionTableSetFilter: Reducer<PersonalPromotionsFilterValues>;
}

const slice = createSlice<PersonalPromotionTableState, Reducers, 'table'>({
  name: 'table',
  initialState: {
    guid: null,
    needRefreshWatcher: 0,
    changeCountWatcher: 0,
    promotions: {
      ...fetchableDefault,
      data: [],
      totalCount: 0,
      pageCount: 0,
      pageNumber: 1,
    },
    search: {
      sort: sortDefault,
      pageSize: pageSizeDefault,
    },
    filter: {},
    actions: [],
  },
  reducers: {
    personalPromotionTableStartSession: (state, { payload }) => {
      if (payload !== state.guid) {
        state.guid = payload;
        state.needRefreshWatcher = 0;
        state.changeCountWatcher = 0;
        state.promotions.isFetching = false;
        state.promotions.isFetched = false;
        state.promotions.isFailed = false;
        state.promotions.data = [];
        state.promotions.totalCount = 0;
        state.promotions.pageCount = 0;
        state.promotions.pageNumber = 1;
        state.search = {
          sort: sortDefault,
          pageSize: pageSizeDefault,
        };
        state.filter = {};
        state.actions = [];
      }
    },
    personalPromotionTableStateReset: state => {
      state.needRefreshWatcher = 0;
      state.changeCountWatcher = 0;
      state.promotions.isFetching = false;
      state.promotions.isFetched = false;
      state.promotions.isFailed = false;
      state.promotions.data = [];
      state.promotions.totalCount = 0;
      state.promotions.pageCount = 0;
      state.promotions.pageNumber = 1;
      state.search = {
        sort: sortDefault,
        pageSize: pageSizeDefault,
      };
      state.filter = {};
      state.actions = [];
    },
    personalPromotionTableSetSearch: (state, { payload }) => {
      const { sort, pageSize } = payload;
      state.search = {
        ...state.search,
        sort,
        pageSize,
      };
      state.promotions.pageNumber = 1;
    },
    personalPromotionTableSetPage: (state, { payload }) => {
      state.promotions.pageNumber = payload;
    },
    personalPromotionTableSetPageSize: (state, { payload }) => {
      state.promotions.pageNumber = 1;
      state.search.pageSize = payload;
    },
    personalPromotionTableSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.promotions.pageNumber = 1;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(personalPromotionTableFetch.pending, state => {
        state.promotions.isFetching = true;
        state.promotions.isFetched = false;
        state.promotions.isFailed = false;
      })
      .addCase(personalPromotionTableFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.promotions.isFetching = false;
        state.promotions.isFetched = true;
        state.promotions.isFailed = false;

        state.promotions.data = data;
        state.promotions.totalCount = totalCount;
        state.promotions.pageCount = pageCount;
      })
      .addCase(personalPromotionTableFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.promotions.isFetching = false;
          state.promotions.isFetched = false;
          state.promotions.isFailed = true;
        } else {
          state.promotions.isFetching = false;
          state.promotions.isFetched = false;
          state.promotions.isFailed = false;
        }
        state.promotions.data = [];
      })
      .addCase(personalPromotionTableEnabled.pending, (state, { meta }) => {
        const id = meta.arg;

        const actionType = EPromotionActionType.Enable;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(personalPromotionTableEnabled.fulfilled, (state, { meta }) => {
        const id = meta.arg;

        const actionType = EPromotionActionType.Enable;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
        state.changeCountWatcher++;
      })
      .addCase(personalPromotionTableEnabled.rejected, (state, { meta, payload }) => {
        const id = meta.arg;

        const actionType = EPromotionActionType.Enable;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(personalPromotionTableDisabled.pending, (state, { meta }) => {
        const id = meta.arg;

        const actionType = EPromotionActionType.Enable;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(personalPromotionTableDisabled.fulfilled, (state, { meta }) => {
        const id = meta.arg;

        const actionType = EPromotionActionType.Enable;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
        state.changeCountWatcher++;
      })
      .addCase(personalPromotionTableDisabled.rejected, (state, { meta, payload }) => {
        const id = meta.arg;

        const actionType = EPromotionActionType.Enable;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(personalPromotionCreateGenerate.fulfilled, state => {
        state.promotions.pageNumber = 1;
        state.needRefreshWatcher++;
      })
      .addCase(personalPromotionCreateUpload.fulfilled, state => {
        state.promotions.pageNumber = 1;
        state.needRefreshWatcher++;
      })
      .addCase(tradeOfferActionsNotUsedCodesDelete.fulfilled, state => {
        state.promotions.pageNumber = 1;
        state.needRefreshWatcher++;
      })
      .addCase(corpOfferCreateNotUsedCodesDelete.fulfilled, state => {
        state.promotions.pageNumber = 1;
        state.needRefreshWatcher++;
      })
      .addCase(corpOfferDetailsNotUsedCodesDelete.fulfilled, state => {
        state.promotions.pageNumber = 1;
        state.needRefreshWatcher++;
      });
  },
});

export const {
  personalPromotionTableStartSession,
  personalPromotionTableStateReset,
  personalPromotionTableSetFilter,
  personalPromotionTableSetPage,
  personalPromotionTableSetPageSize,
  personalPromotionTableSetSearch,
} = slice.actions;

export default slice.reducer;
