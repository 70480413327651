import { Checkbox } from '@mui/material';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import React from 'react';
import { AddressHelper } from '../../../../../utils/address';
import { CityOption, TextOption } from './controls';
import { AddressMultipleAllItemsState, AddressMultipleItemType, AddressMultipleTypesSelectorItem } from './types';

interface AddressMultipleItemProps {
  readonly elementProps: React.HTMLAttributes<HTMLLIElement>;
  readonly option: AddressMultipleTypesSelectorItem;
  readonly state: AutocompleteRenderOptionState;
}

const AddressMultipleItem = ({ elementProps, option, state }: AddressMultipleItemProps) => {
  switch (option.type) {
    case AddressMultipleItemType.Text:
      return (
        <TextOption
          color='textSecondary'
          variant='body2'
        >
          {option.name}
        </TextOption>
      );
    case AddressMultipleItemType.All:
      return (
        <CityOption {...elementProps}>
          <Checkbox
            color='primary'
            checked={state.selected}
            indeterminate={option.state === AddressMultipleAllItemsState.Indeterminate}
          />
          {option.name}
        </CityOption>
      );
    case AddressMultipleItemType.None:
      return (
        <CityOption {...elementProps}>
          <Checkbox
            color='primary'
            checked={state.selected}
          />
          {option.name}
        </CityOption>
      );
    case AddressMultipleItemType.Address:
      return (
        <CityOption {...elementProps}>
          <Checkbox
            color='primary'
            checked={state.selected}
          />
          {new AddressHelper(option).getLastLocalityShortPath() ?? ''}
        </CityOption>
      );
  }
};

export default AddressMultipleItem;

export const getAddressMultipleItemAll = (state: AddressMultipleAllItemsState): AddressMultipleTypesSelectorItem => ({
  type: AddressMultipleItemType.All,
  id: AddressMultipleItemType.All,
  name: 'Вся Россия',
  state,
});

export const getAddressMultipleItemNone = (state: AddressMultipleAllItemsState): AddressMultipleTypesSelectorItem => ({
  type: AddressMultipleItemType.None,
  id: AddressMultipleItemType.None,
  name: 'не выбрано',
  state,
});

export const getAddressMultipleItemDefaultSourceHeader = (): AddressMultipleTypesSelectorItem => ({
  type: AddressMultipleItemType.Text,
  id: 'default_source_header',
  name: 'Самые часто используемые города или регионы',
});

export const getAddressMultipleItemDefaultSourceFooter = (): AddressMultipleTypesSelectorItem => ({
  type: AddressMultipleItemType.Text,
  id: 'default_source_footer',
  name: 'Для выбора конкретного города или региона начните вводить его название в поле поиска',
});
