import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../../data/store/types';
import { CmsSitePage } from '../../../../../../domain/model/cms';
import { Nullable } from '../../../../../../domain/model/types';
import cmsServices from '../../../services';

export const cmsSitePageForLandingFetch = createAsyncThunk<CmsSitePage, undefined, AppThunkAPIConfig>(
  'cmsSitePage/forLanding/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return await cmsServices.sitePage.getOrCreateForLanding();
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface CmsSitePageForLandingState extends Fetchable {
  readonly data: Nullable<CmsSitePage>;
}

type Reducer<T = undefined> = CaseReducer<CmsSitePageForLandingState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CmsSitePageForLandingState> {
  cmsSitePageForLandingStateReset: Reducer;
}

const slice = createSlice<CmsSitePageForLandingState, Reducers, 'forLanding'>({
  name: 'forLanding',
  initialState: {
    ...fetchableDefault,
    data: null,
  },
  reducers: {
    cmsSitePageForLandingStateReset: state => {
      state.isFetching = false;
      state.isFetched = false;
      state.isFailed = false;
      state.data = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(cmsSitePageForLandingFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
        state.data = null;
      })
      .addCase(cmsSitePageForLandingFetch.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.data = payload;
      })
      .addCase(cmsSitePageForLandingFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
        } else {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = false;
        }
      });
  },
});

export const { cmsSitePageForLandingStateReset } = slice.actions;

export default slice.reducer;
