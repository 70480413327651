import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import rootRouting from '../../../routing';
import ProtectedRoute from '../../components/common/protectedRoute';
import NotFoundScreen from '../../screens/notFound';
import UserProfileScreen from '../../screens/userProfile';
import UserProfileEditScreen from '../../screens/userProfileEdit';
import AdminMpEntry, { getAdminMpRootRouteDefinition } from './adminMp/entry';
import useCurrentUser from './hooks/useCurrentUser';

const routing = {
  root: `${rootRouting.users}`,
  current: {
    details: `${rootRouting.users}/profile`,
    edit: `${rootRouting.users}/profile/edit`,
  },
};

export const getCurrentUserDetailsRoute = () => {
  return routing.current.details;
};

export const getCurrentUserEditRoute = () => {
  return routing.current.edit;
};

const UsersEntry = () => {
  const { adminsMp } = useCurrentUser().accessMatrix;

  return (
    <Switch>
      <ProtectedRoute
        path={getAdminMpRootRouteDefinition()}
        component={AdminMpEntry}
        allowed={adminsMp?.view}
      />
      <Route
        exact
        path={routing.current.details}
        component={UserProfileScreen}
      />
      <Route
        exact
        path={routing.current.edit}
        component={UserProfileEditScreen}
      />
      <Redirect
        exact
        from={routing.root}
        to={routing.current.details}
      />

      <Route component={NotFoundScreen} />
    </Switch>
  );
};

export default UsersEntry;
