import { ClientOrgCreateDialogCloseAdapter } from './dialogClose';
import { ClientOrgCreateDialogDiscardAdapter } from './dialogDiscard';
import { ClientOrgCreateDialogFeedbackAdapter } from './dialogFeedback';

export const ClientOrgCreateDialogsAdapter = () => {
  return (
    <>
      <ClientOrgCreateDialogCloseAdapter />
      <ClientOrgCreateDialogFeedbackAdapter />
      <ClientOrgCreateDialogDiscardAdapter />
    </>
  );
};
