type ColorType = string;

type Color = {
  white: ColorType;
  whiteTransparent: ColorType;
  black: ColorType;

  red: ColorType;
  redHover: ColorType;
  redLight: ColorType;
  redHoverLight: ColorType;

  blue: ColorType;
  blueHover: ColorType;
  blueLight: ColorType;
  blueSuperLight: ColorType;
  blueHoverLight: ColorType;

  gray: ColorType;
  grayMiddle: ColorType;
  grayDark: ColorType;
  grayLight: ColorType;
  graySuperLight: ColorType;
  grayHoverSuperLight: ColorType;

  orange: ColorType;
  orangeLight: ColorType;

  green: ColorType;

  gradientStart: ColorType;
  gradientMiddle: ColorType;
  gradientEnd: ColorType;
};

const brandBookColor: Color = {
  white: '#FFFFFF',
  whiteTransparent: 'hsla(0, 0%, 100%, 0.6)',
  black: '#2E3138',

  red: '#FF133D',
  redHover: '#FA002D',
  redLight: '#FFEBEE',
  redHoverLight: '#FFE5EA',

  blue: '#0084E7',
  blueHover: '#0080E0',
  blueLight: '#EBF6FF',
  blueHoverLight: '#E5F4FF',
  blueSuperLight: 'hsla(206, 100%, 60%, 1)',

  gray: 'hsla(220, 10%, 58%, 1)',
  grayMiddle: 'hsla(220, 10%, 77%, 1)',
  grayDark: 'hsla(210, 10%, 96%, 1)',
  grayLight: 'hsla(220, 10%, 90%, 1)',
  graySuperLight: 'hsla(210, 10%, 96%, 1)',
  grayHoverSuperLight: '#F1F2F4',

  orange: 'hsla(19, 100%, 67%, 1)',
  orangeLight: 'hsla(19, 100%, 96%, 1)',

  green: '#00CA51',

  gradientStart: '#0084E7',
  gradientMiddle: '#7654FF',
  gradientEnd: '#FF133D',
};

export default brandBookColor;
