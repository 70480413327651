import { Grid, Typography } from '@mui/material';
import { ECmsContainerType } from '../../../../../../../domain/model/enums';
import Splitter from '../../../../../../components/common/splitter';
import { CmsContainerView } from '../../../../types';
import CmsContainerViewComponentBannerSize from '../../attributes/bannerSize';
import CmsContainerViewComponentCommon from '../../attributes/common';
import CmsContainerViewComponentOfferType from '../../attributes/offerType';
import CmsContainerViewComponentPeriod from '../../attributes/period';
import CmsContainerViewComponentTarget from '../../attributes/target';
import { CmsContainerDetailsComponentProps } from '../../types';
import { ColumnWrapper, Content, Wrapper } from './controls';

interface CmsContainerCreateStepGeneralProps extends CmsContainerDetailsComponentProps {
  readonly cmsContainer: CmsContainerView;
}

const CmsContainerDetailsStepGeneral = (props: CmsContainerCreateStepGeneralProps) => {
  const { cmsContainer } = props;

  const containerType = cmsContainer.type!.code;

  return (
    <Wrapper>
      <ColumnWrapper>
        <Content>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Splitter size={2} />
              <Typography variant='h3'>Данные блока</Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Splitter size={2} />
              <CmsContainerViewComponentCommon {...props} />
            </Grid>
            {(cmsContainer.type?.code === ECmsContainerType.Banner1Resizable ||
              cmsContainer.type?.code === ECmsContainerType.Banner3Resizable) && (
              <Grid item>
                <CmsContainerViewComponentBannerSize {...props} />
              </Grid>
            )}
            <Grid
              item
              xs={12}
            >
              <Splitter size={2} />
              <CmsContainerViewComponentPeriod {...props} />
            </Grid>

            {cmsContainer.type?.code !== ECmsContainerType.CompilationPartner &&
              cmsContainer.type?.code !== ECmsContainerType.Banner1LinkResizable && (
                <Grid
                  item
                  xs={12}
                >
                  <Splitter size={2} />
                  <CmsContainerViewComponentOfferType {...props} />
                </Grid>
              )}
            <Grid
              item
              xs={12}
            >
              <Splitter size={2} />
              <CmsContainerViewComponentTarget
                {...props}
                isNotRequiredOfferType={containerType === ECmsContainerType.Banner1LinkResizable}
              />
            </Grid>
          </Grid>
        </Content>
      </ColumnWrapper>
    </Wrapper>
  );
};

export default CmsContainerDetailsStepGeneral;
