import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable } from '../../../../../domain/model';
import { ProductOrder } from '../../../../../domain/model/order';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { PaginationSize } from '../../../../types';
import productOrderServices from '../../services';
import { AllProps } from '../../services/order';
import { EProductOrderTableColumn, EProductOrderTableTab } from '../../types';
import { ProductOrdersFilterValues } from '../filterUtils';
import { ProductOrderTableTabsCounter } from '../utils';

const defaultSort = `${EProductOrderTableColumn.CreateAt},desc` as const;

export type ProductOrdersFetchProps = Omit<AllProps, 'signal'>;

export type ProductOrdersCountsFetchProps = ProductOrdersFetchProps & {
  readonly tabs: EProductOrderTableTab[];
};

export const productOrdersCountsFetch = createAsyncThunk<
  ProductOrderTableTabsCounter,
  ProductOrdersCountsFetchProps,
  AppThunkAPIConfig
>('productOrder/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await productOrderServices.order.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

export const productOrdersFetch = createAsyncThunk<Pageable<ProductOrder>, ProductOrdersFetchProps, AppThunkAPIConfig>(
  'productOrder/list/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await productOrderServices.order.all({ ...props, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

interface OrderListSearchState {
  readonly sort: string;
  readonly pageSize: PaginationSize;
  readonly partnerId: Nullable<UUID>;
}

export interface ProductOrderListState {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly orders: Fetchable & Pageable<ProductOrder>;
  readonly search: OrderListSearchState;
  readonly tab: Nullable<EProductOrderTableTab>;
  readonly filter: ProductOrdersFilterValues;
  readonly tabsCounter: ProductOrderTableTabsCounter;
}

type Reducer<T = undefined> = CaseReducer<ProductOrderListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ProductOrderListState> {
  productOrdersStartSession: Reducer<{ guid: UUID }>;
  productOrdersSetSearch: Reducer<{ sort: string; pageSize: PaginationSize }>;
  productOrdersSetSort: Reducer<{ sort: string }>;
  productOrdersSortReset: Reducer;
  productOrdersSetTab: Reducer<{ tab: EProductOrderTableTab }>;
  productOrdersSetFilter: Reducer<{ filter: ProductOrdersFilterValues }>;
  productOrdersSetPage: Reducer<{ pageNumber: number }>;
  productOrdersSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  productOrdersDataReset: Reducer;
  productOrdersNeedRefreshWatcherReset: Reducer;
  productOrdersNeedRefreshWatcherIncrement: Reducer;
}

const slice = createSlice<ProductOrderListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    guid: null,
    orders: {
      ...fetchableDefault,
      data: [],
      totalCount: 0,
      pageCount: 0,
      pageNumber: 1,
    },
    needRefreshWatcher: 0,
    search: {
      sort: defaultSort,
      pageSize: 10,
      partnerId: null,
    },
    tab: null,
    filter: {},
    tabsCounter: {},
  },
  reducers: {
    productOrdersStartSession: (state, { payload }) => {
      const { guid } = payload;
      if (guid !== state.guid) {
        state.guid = guid;

        state.orders.isFetching = false;
        state.orders.isFetched = false;
        state.orders.isFailed = false;

        state.orders.data = [];
        state.orders.totalCount = 0;
        state.orders.pageCount = 0;
        state.orders.pageNumber = 1;
        state.needRefreshWatcher = 0;
        state.search = {
          sort: defaultSort,
          pageSize: 10,
          partnerId: null,
        };
        state.tab = null;
        state.filter = {};
        state.tabsCounter = {};
      }
    },
    productOrdersSetTab: (state, { payload }) => {
      const { tab } = payload;
      if (state.tab !== tab) {
        state.orders.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    productOrdersSetSearch: (state, { payload }) => {
      const { sort, pageSize } = payload;
      state.search = {
        ...state.search,
        sort,
        pageSize,
      };
      state.orders.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    productOrdersSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.orders.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    productOrdersNeedRefreshWatcherIncrement: state => {
      state.needRefreshWatcher++;
    },
    productOrdersSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.needRefreshWatcher++;
    },
    productOrdersSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.orders.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    productOrdersSetFilter: (state, { payload }) => {
      const { filter } = payload;
      state.filter = filter;
      state.orders.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    productOrdersSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.orders.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    productOrdersDataReset: state => {
      state.orders.data = [];
      state.orders.totalCount = 0;
      state.orders.pageCount = 0;
      state.orders.pageNumber = 1;
    },
    productOrdersNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(productOrdersFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;
        state.orders.isFetching = true;
        state.orders.isFetched = false;
        state.orders.isFailed = false;

        if (partnerId !== state.search.partnerId) {
          state.orders.data = [];
        }
        state.search.partnerId = partnerId;
      })
      .addCase(productOrdersFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.orders.isFetching = false;
        state.orders.isFetched = true;
        state.orders.isFailed = false;

        state.orders.data = data;
        state.orders.totalCount = totalCount;
        state.orders.pageCount = pageCount;
      })
      .addCase(productOrdersFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.orders.isFetching = false;
          state.orders.isFetched = false;
          state.orders.isFailed = true;
          state.orders.data = [];
        }
      })
      .addCase(productOrdersCountsFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        if (partnerId !== state.search.partnerId) {
          state.tabsCounter = {};
        }
      })
      .addCase(productOrdersCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(productOrdersCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      });
  },
});

export const {
  productOrdersStartSession,
  productOrdersSetTab,
  productOrdersSetSearch,
  productOrdersSortReset,
  productOrdersSetPage,
  productOrdersSetFilter,
  productOrdersSetPageSize,
  productOrdersDataReset,
  productOrdersNeedRefreshWatcherReset,
  productOrdersNeedRefreshWatcherIncrement,
  productOrdersSetSort,
} = slice.actions;

export default slice.reducer;
