import { Grid } from '@mui/material';
import { Ref } from 'react';
import { PanelAction, PanelActions } from '../../../../types';
import ActionItem from '../item';
import {
  FixedWidthBackActionItemWrapper,
  NavAdapterWrapperGridItem,
  NavGridItem,
  NextActionItemWrapper,
} from './controls';
import useGetNavActions from './hooks/useGetNavActions';

interface WithRef {
  readonly ref?: Ref<HTMLDivElement>;
}

export enum ENavStepAction {
  NextStep = 'nextStep',
  Back = 'back',
}

export interface NavAdapterProps extends WithRef {
  readonly actions: PanelActions<ENavStepAction>;
  readonly onNavAction: (action: PanelAction<ENavStepAction>) => void;
}

const NavAdapter = (props: NavAdapterProps) => {
  const { actions, onNavAction } = props;

  const { prevStepAction, nextStepAction } = useGetNavActions({ actions });

  if (!actions.length) {
    return null;
  }

  return (
    <NavAdapterWrapperGridItem item>
      <NavGridItem
        container
        item
        xs
        direction={'row'}
        wrap={'nowrap'}
        spacing={2}
      >
        <Grid item>
          <FixedWidthBackActionItemWrapper>
            {prevStepAction && (
              <ActionItem
                key={prevStepAction.type}
                disabled={prevStepAction.disabled}
                primary={prevStepAction.primary}
                onExecute={() => onNavAction(prevStepAction)}
              >
                {prevStepAction.label}
              </ActionItem>
            )}
          </FixedWidthBackActionItemWrapper>
        </Grid>

        {nextStepAction && (
          <Grid item>
            <NextActionItemWrapper>
              <ActionItem
                key={nextStepAction.type}
                disabled={nextStepAction.disabled}
                primary={nextStepAction.primary}
                onExecute={() => onNavAction(nextStepAction)}
              >
                {nextStepAction.label}
              </ActionItem>
            </NextActionItemWrapper>
          </Grid>
        )}
      </NavGridItem>
    </NavAdapterWrapperGridItem>
  );
};

export default NavAdapter;
