import { Grid } from '@mui/material';
import moment from 'moment';
import { EDateFormat } from '../../../../../domain/model/formats';
import { ApprovalOfferRegistryRequest } from '../../../../../domain/model/offerApprovalRegistry';
import { Nullable } from '../../../../../domain/model/types';
import { MPAutocompleteSingleSelect } from '../../../../theme/ui-kit/autocomplete/single';
import { MPDatePicker } from '../../../../theme/ui-kit/picker';
import { OnChangeObjectAttribute } from '../../../../types';
import { ValidationResult } from '../../../../utils/validation';
import OfferApprovalRegistrySuggestionContainer from '../../../approvalRegistry/search/container';
import { ApprovalOfferRegistryPreview } from '../../types';
import { getApprovalRegistryPreview } from '../../utils';

interface CorpOfferApprovalRegistryEditProps {
  readonly disabled?: boolean;
  readonly approvalRegistry: Nullable<ApprovalOfferRegistryRequest>;
  readonly validation: Nullable<ValidationResult<ApprovalOfferRegistryRequest>>;
  readonly onChangeAttribute: OnChangeObjectAttribute<ApprovalOfferRegistryRequest>;
}

const CorpOfferApprovalRegistryEdit = (props: CorpOfferApprovalRegistryEditProps) => {
  const { disabled, approvalRegistry, validation, onChangeAttribute } = props;

  return (
    <OfferApprovalRegistrySuggestionContainer>
      {({ onSearch, approvalRegistries, isFetching }) => (
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <MPAutocompleteSingleSelect<ApprovalOfferRegistryPreview>
              label='Номер приказа'
              disabled={disabled}
              isLoading={isFetching}
              value={
                approvalRegistry && {
                  ...approvalRegistry,
                  id: '',
                  name: approvalRegistry.number?.toString() ?? '',
                }
              }
              error={!!validation?.number?.hasError}
              helperText={validation?.number?.message}
              options={
                approvalRegistries?.map(ar => ({
                  ...ar,
                  name: getApprovalRegistryPreview(ar),
                })) ?? []
              }
              onSearchValue={value => {
                onSearch(value);
                onChangeAttribute('number', value ?? null);
              }}
              onChangeValue={value => {
                onChangeAttribute('number', value?.number ?? null);
                onChangeAttribute('date', value?.date ?? null);
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <MPDatePicker
              label='Дата приказа'
              value={approvalRegistry?.date ?? null}
              disabled={disabled}
              error={!!validation?.date?.hasError}
              helperText={validation?.date?.message}
              onChange={date => {
                const d = date === null ? null : moment(date).format(EDateFormat.Server);
                onChangeAttribute('date', d);
              }}
            />
          </Grid>
        </Grid>
      )}
    </OfferApprovalRegistrySuggestionContainer>
  );
};

export default CorpOfferApprovalRegistryEdit;
