import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import {
  Fetchable,
  fetchableDefault,
  fetchableFailed,
  fetchableFetched,
  fetchableFetching,
} from '../../../../../data/store/types';
import { SportOption } from '../../../../../domain/model';
import { EPartnerPermission, EPartnerType } from '../../../../../domain/model/enums';
import { Partner, PartnerData } from '../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { PartnerPermissionsAttributes } from '../../types';

export const partnerApplicationVerificationFetch = createAsyncThunk<Partner, { partnerId: UUID }, AppThunkAPIConfig>(
  'partner/application/verification/fetch',
  async ({ partnerId }, { rejectWithValue, signal }) => {
    try {
      const { data } = await Api.partner.one({ id: partnerId, signal });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const partnerApplicationVerificationAssignVerifier = createAsyncThunk<
  Partner,
  { partnerId: UUID; userId: UUID },
  AppThunkAPIConfig
>('partner/application/verification/inWork', async ({ partnerId, userId }, { rejectWithValue }) => {
  try {
    const { data } = await Api.partner.assignVerifier({ partnerId, userId });
    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const partnerApplicationVerificationApprove = createAsyncThunk<
  Partner,
  { partnerId: UUID; data: PartnerData; permissions: Nullable<EPartnerPermission[]>; type: EPartnerType },
  AppThunkAPIConfig
>('partner/application/verification/approve', async ({ partnerId, data, permissions, type }, { rejectWithValue }) => {
  try {
    await Api.partner.update({ id: partnerId, data });
    await Api.partner.updatePermissions({
      id: partnerId,
      permissions: permissions ?? [],
    });
    await Api.partner.updateType({ id: partnerId, type });
    const { data: approvedPartner } = await Api.partner.approve(partnerId);
    return approvedPartner;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const partnerApplicationVerificationReject = createAsyncThunk<
  Partner,
  { partnerId: UUID; reason: SportOption; comment?: string },
  AppThunkAPIConfig
>('partner/application/verification/reject', async ({ partnerId, reason, comment }, { rejectWithValue }) => {
  try {
    const { data } = await Api.partner.reject({ partnerId, rejectId: reason.id, comment });
    return data;
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface PartnerApplicationVerificationState {
  readonly data: Nullable<Partner>;
  readonly fetch: Fetchable;
  readonly assignVerifier: Fetchable;
  readonly approve: Fetchable & {
    readonly data: Nullable<Partner>;
  };
  readonly reject: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<PartnerApplicationVerificationState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PartnerApplicationVerificationState> {
  partnerApplicationVerificationStateReset: Reducer<void>;
  partnerApplicationVerificationSetPermissionsAttribute: Reducer<{
    attribute: keyof PartnerPermissionsAttributes;
    value: any;
  }>;
}

const slice = createSlice<PartnerApplicationVerificationState, Reducers, 'verification'>({
  name: 'verification',
  initialState: {
    fetch: fetchableDefault,
    assignVerifier: fetchableDefault,
    approve: {
      ...fetchableDefault,
      data: null,
    },
    reject: fetchableDefault,
    data: null,
  },
  reducers: {
    partnerApplicationVerificationStateReset: state => {
      state.fetch = fetchableDefault;
      state.assignVerifier = fetchableDefault;
      state.approve = {
        ...fetchableDefault,
        data: null,
      };
      state.reject = fetchableDefault;
      state.data = null;
    },
    partnerApplicationVerificationSetPermissionsAttribute: (state, { payload }) => {
      const { attribute, value } = payload;
      state.data![attribute] = value;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(partnerApplicationVerificationFetch.pending, state => {
        state.fetch = fetchableFetching;
        state.data = null;
      })
      .addCase(partnerApplicationVerificationFetch.fulfilled, (state, { payload }) => {
        state.fetch = fetchableFetched;
        state.data = payload;
      })
      .addCase(partnerApplicationVerificationFetch.rejected, state => {
        state.fetch = fetchableFailed;
      })

      .addCase(partnerApplicationVerificationAssignVerifier.pending, state => {
        state.assignVerifier = fetchableFetching;
      })
      .addCase(partnerApplicationVerificationAssignVerifier.fulfilled, (state, { payload }) => {
        state.assignVerifier = fetchableFetched;
        state.data = payload;
      })
      .addCase(partnerApplicationVerificationAssignVerifier.rejected, state => {
        state.assignVerifier = fetchableFailed;
      })

      .addCase(partnerApplicationVerificationApprove.pending, state => {
        state.approve = {
          ...fetchableFetching,
          data: null,
        };
      })
      .addCase(partnerApplicationVerificationApprove.fulfilled, (state, { payload }) => {
        state.approve = {
          ...fetchableFetched,
          data: payload,
        };
        state.data = payload;
      })
      .addCase(partnerApplicationVerificationApprove.rejected, state => {
        state.approve = {
          ...fetchableFailed,
          data: null,
        };
      })

      .addCase(partnerApplicationVerificationReject.pending, state => {
        state.reject = fetchableFetching;
      })
      .addCase(partnerApplicationVerificationReject.fulfilled, (state, { payload }) => {
        state.reject = fetchableFetched;
        state.data = payload;
      })
      .addCase(partnerApplicationVerificationReject.rejected, state => {
        state.reject = fetchableFailed;
      });
  },
});

export const { partnerApplicationVerificationStateReset, partnerApplicationVerificationSetPermissionsAttribute } =
  slice.actions;

export default slice.reducer;
