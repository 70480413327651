import common, { CommonOfferService } from './common';
import corp, { CorpOfferService } from './corpOffers';
import trade, { TradeOfferService } from './tradeOffers';

type OfferServices = {
  readonly common: CommonOfferService;
  readonly trade: TradeOfferService;
  readonly corp: CorpOfferService;
};

const offerServices: OfferServices = {
  common,
  trade,
  corp,
};

export default offerServices;
