import { Grid, Typography } from '@mui/material';
import { BookingActionDetailsType } from '@features/bookingOffer/types';
import { MPButton } from '@/presentation/theme/ui-kit/button';
import { PanelAction } from '@/presentation/types';
import { BookingOffer, EOfferStatus } from '@/domain';
import { ETagColors, MPTag } from '@/presentation/theme/ui-kit/tag';
import { getBookingActionName } from '@features/bookingOffer/utils/actions';
import AppBreadcrumbs from '@components/common/breadcrumbs';
import { OfferMasterTitle } from '@features/general/offer/components/title';
import DefaultHeader from '@components/common/header';

type HeaderActionsProps = {
  readonly isDisabledAll: boolean;
  readonly isModerator: boolean;
  readonly offerStatusText?: string;
  readonly bookingOffer: Nullable<BookingOffer>;
  readonly actions: PanelAction<BookingActionDetailsType, any>[];
  readonly onAction: (action: PanelAction<BookingActionDetailsType>) => void;
  readonly onClose: () => void;
};

export const BookingMasterHeader = (props: HeaderActionsProps) => {
  const { isDisabledAll, actions, offerStatusText, bookingOffer, isModerator, onAction, onClose } = props;

  return bookingOffer ? (
    <DefaultHeader
      sticky
      headline={
        <AppBreadcrumbs>
          <Typography color='textSecondary'>{offerStatusText}</Typography>
        </AppBreadcrumbs>
      }
      actions={
        <Grid
          container
          spacing={4}
          alignItems='center'
        >
          {actions?.map((action, index) => (
            <Grid
              item
              key={action.type}
            >
              <MPButton
                variant='outlined'
                size='small'
                disabled={isDisabledAll || action.disabled}
                onClick={() => onAction(action)}
              >
                <Typography noWrap>{getBookingActionName(action.type)}</Typography>
              </MPButton>
            </Grid>
          ))}
          {isModerator && bookingOffer.status === EOfferStatus.OnModeration && (
            <Grid item>
              <MPTag
                color={ETagColors.Success}
                label={'В работе'}
              />
            </Grid>
          )}
          {!isModerator && bookingOffer.status === EOfferStatus.OnModeration && (
            <Grid item>
              <MPTag
                color={ETagColors.Success}
                label={offerStatusText}
              />
            </Grid>
          )}
        </Grid>
      }
      onClose={onClose}
    >
      <OfferMasterTitle>Объект досуга и отдыха</OfferMasterTitle>
    </DefaultHeader>
  ) : null;
};
