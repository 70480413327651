import { BannersTableCellImageProps } from '@features/banner/components/tableCell/image';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bannersBannerByIndexSelector } from '../store/selectors';
import { BannerTableCellAdapterProps } from './cell';

type BannerTableCellImageAdapterProps = Omit<BannerTableCellAdapterProps, 'component'> & {
  readonly component: FC<BannersTableCellImageProps>;
};

export const BannerTableCellImageAdapter = (props: BannerTableCellImageAdapterProps) => {
  const { partition, index, component: Component } = props;

  const handlers = useContextHandlers();
  const banner = useSelector(bannersBannerByIndexSelector(partition, index));
  const isDisabled = banner ? handlers.isBannerLockedNotByMe(banner) : false;

  return banner?.mobileFormatImage ? (
    <Component
      banner={banner}
      isDisabled={isDisabled}
      image={banner.mobileFormatImage}
    />
  ) : null;
};