import { EUserNameRule, getUserShortName } from '../../../../utils';
import TeamTableCell, { TeamTableCellCommonProps } from './index';

const TeamTableCellCaptain = (props: TeamTableCellCommonProps) => {
  const {
    team: { captain },
  } = props;

  const value = captain?.userProfile ? getUserShortName(captain?.userProfile, EUserNameRule.Reverse) : '-';

  return (
    <TeamTableCell
      {...props}
      value={value}
    />
  );
};

export default TeamTableCellCaptain;
