import TeamTableCell, { TeamTableCellCommonProps } from './index';

const TeamTableCellActivitySubTypes = (props: TeamTableCellCommonProps) => {
  const {
    team: { activityTypes },
  } = props;

  const value =
    activityTypes
      ?.filter(item => !!item.parent)
      ?.map(item => item.name)
      .join(', ') || '-';

  return (
    <TeamTableCell
      {...props}
      value={value}
    />
  );
};

export default TeamTableCellActivitySubTypes;
