import Api from '@/data/api';
import moment from 'moment';
import { EDateFormat, EMPUserGroup, EOfferType, Nullable } from '@/domain';
import { AxiosResponse } from 'axios';

type ReportAllProps = {
  readonly offerType?: Nullable<EOfferType | EOfferType[]>;
  readonly startDate?: Nullable<string>;
  readonly endDate?: Nullable<string>;
  readonly partnerId?: Nullable<UUID>;
  readonly reportUserGroup?: Nullable<EMPUserGroup>;
};

export type ReportMpService = {
  readonly buildListQueryParams: (props: ReportAllProps) => ReportAllProps;
  readonly offers: (props: ReportAllProps) => Promise<AxiosResponse<string>>;
  readonly bookingOrdersStatistic: (props: ReportAllProps) => Promise<AxiosResponse<string>>;
  readonly offersStatistic: (props: ReportAllProps) => Promise<AxiosResponse<string>>;
  readonly partners: (props: ReportPartnersProps) => Promise<AxiosResponse<string>>;
  readonly users: (props: ReportAllProps) => Promise<AxiosResponse<string>>;
};

type ReportPartnersProps = ReportAllProps & {
  readonly offerType?: Nullable<EOfferType>;
};

const service: ReportMpService = {
  buildListQueryParams: props => {
    const { startDate, endDate, offerType, partnerId, reportUserGroup } = props;

    return {
      offerType,
      partnerId,
      reportUserGroup,
      startDate: startDate && moment(startDate).format(EDateFormat.Server),
      endDate: endDate && moment(endDate).format(EDateFormat.Server),
    };
  },
  offers: async props => {
    const params = service.buildListQueryParams(props);

    return await Api.report.mp.offers(params);
  },
  offersStatistic: async props => {
    const params = service.buildListQueryParams(props);

    return await Api.report.mp.offersStatistic(params);
  },
  bookingOrdersStatistic: async props => {
    const params = service.buildListQueryParams(props);

    return await Api.report.mp.bookingOrdersStatistic(params);
  },
  users: async props => {
    const params = service.buildListQueryParams(props);

    return await Api.report.mp.users(params);
  },
  partners: async props => {
    const params = service.buildListQueryParams(props) as ReportPartnersProps;

    return await Api.report.mp.partners(params);
  },
};

export default service;
