import { useEffect, useState } from 'react';
import { DataFilterStrategyListItem, DataFilterStrategyListSingle } from '../../../../../../domain/model/filter';
import { Nullable } from '../../../../../../domain/model/types';
import { MPAutocompleteSingleSelect } from '../../../../../theme/ui-kit/autocomplete/single';
import { DataFilterEditItemProps } from '../index';

interface DataFilterEditItemListSingleAutocompleteProps<T extends string>
  extends DataFilterEditItemProps<T, Nullable<string>> {
  readonly strategy: DataFilterStrategyListSingle<T>;
}

function DataFilterEditItemListSingleAutocomplete<T extends string>(
  props: DataFilterEditItemListSingleAutocompleteProps<T>
) {
  const { strategy, onChange } = props;

  const { label, value, readOnly } = strategy;

  const [searchValue, setSearchValue] = useState<Nullable<string>>(null);

  const onSubmitValue = (newValue: Nullable<DataFilterStrategyListItem>) => {
    onChange(strategy, newValue?.id ?? null);
  };

  useEffect(() => {
    setSearchValue(null);
  }, [strategy]);

  const items =
    strategy.items?.filter(item => item.name.toLowerCase().includes(searchValue?.toLowerCase() ?? '')) ?? [];

  return (
    <MPAutocompleteSingleSelect<DataFilterStrategyListItem>
      value={value ? items.find(item => item.id === value)! : null}
      label={label}
      disabled={readOnly}
      options={items}
      onSearchValue={newValue => setSearchValue(newValue)}
      onChangeValue={onSubmitValue}
    />
  );
}

export default DataFilterEditItemListSingleAutocomplete;
