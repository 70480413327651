import { useState } from 'react';
import { BookingServicePriceUnit } from '../../../../../../domain/model/booking';
import { Nullable } from '../../../../../../domain/model/types';
import { DictionaryEditDialog } from '../../../../../components/common/dictionary/dialog/edit';
import { MPFormInput } from '../../../../../theme/ui-kit/input';

interface BookingServicePriceUnitsEditDialogEditAdapterProps {
  readonly isLoading: boolean;
  readonly data: BookingServicePriceUnit;
  readonly onClose: () => void;
  readonly onEdit: (bookingServicePriceUnit: BookingServicePriceUnit) => void;
}

export const BookingServicePriceUnitsEditDialogEditAdapter = (
  props: BookingServicePriceUnitsEditDialogEditAdapterProps
) => {
  const { isLoading, data, onEdit, onClose } = props;

  const [error, setError] = useState<Nullable<string>>(null);

  const [value, setValue] = useState<BookingServicePriceUnit>(data);

  const onEditInternal = () => {
    if (!value.name) {
      setError('Введите название');
    } else {
      onEdit(value ?? '');
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setError(null);
    setValue(prev => ({ ...prev, name: event.target.value }));
  };

  return (
    <DictionaryEditDialog
      isLoading={isLoading}
      onClose={onClose}
      onSave={onEditInternal}
    >
      <MPFormInput
        disabled={isLoading}
        label='Название'
        value={value.name}
        error={error !== null}
        helperText={error}
        inputRef={input => input && input.focus()}
        onChange={onChange}
      />
    </DictionaryEditDialog>
  );
};
