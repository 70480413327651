import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { MPFormInput } from '../../../../../theme/ui-kit/input';

export const ViewWrapper = styled(Grid)`
  position: relative;

  .MuiButtonBase-root {
    margin-left: ${p => p.theme.spacing(0.5)};
  }
`;

export const EditInput = styled(MPFormInput)`
  .MuiInputBase-root {
    min-height: auto;
  }

  .MuiOutlinedInput-input {
    padding: ${p => p.theme.spacing(0.6)} ${p => p.theme.spacing()};
  }
`;
