import styled from '@emotion/styled';
import {
  Checkbox,
  CheckboxProps,
  Radio,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableBodyProps,
  TableCell as MuiTableCell,
  TableFooter as MuiTableFooter,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableSortLabel as MuiTableSortLabel,
  TableSortLabelProps,
} from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell/TableCell';
import { Property } from 'csstype';
import { TableProps, TableRowProps } from 'material-ui';
import { viewConfig } from '../../../theme/viewConfig';
import { ColumnActionsPosition } from '@/presentation/components/common/table/index';
import { forwardRef } from 'react';

interface HeadCellWrapperProps extends TableCellProps {
  readonly active?: boolean;
}

const HeadCellDefaultWrapper = styled.div<any>`
  display: inline-flex;
  align-items: flex-end;

  color: ${p => p.theme.palette.text.secondary};
`;

export const HeadCellActiveWrapper = styled(HeadCellDefaultWrapper)`
  cursor: pointer;
`;

export const HeadCellWrapper = styled(({ active, ...props }: HeadCellWrapperProps) =>
  active ? <HeadCellActiveWrapper {...props} /> : <HeadCellDefaultWrapper {...props} />
)``;

export const TableCell = styled(
  forwardRef(({ width, height, ...props }: TableCellProps, ref: any) => (
    <MuiTableCell
      ref={ref}
      {...props}
    />
  ))
)`
  width: ${p => p.width};
  min-width: ${p => p.width};
  height: ${p => p.height};

  padding: ${p => `0 ${p.theme.spacing()}`};
`;

interface TableHeadRowProps extends TableRowProps {
  readonly height?: number | string;
  readonly children?: any;
}

export const TableRow = styled(MuiTableRow)``;

export const TableHeadRow = styled(({ height, ...props }: TableHeadRowProps) => <TableRow {...props} />)`
  height: ${p => (p.height ? p.height : viewConfig.table.headRowSize)};

  .MuiTableCell-alignRight {
    flex-direction: row;
  }
`;

const TableBodyDefaultRow = styled(TableRow)``;

const TableBodyActiveRow = styled(TableBodyDefaultRow)`
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.palette.highlight};
  }
`;

interface TableBodyRowProps extends TableRowProps {
  readonly active: boolean;
  readonly children?: any;
}

export const TableBodyRow = styled(({ active, ...props }: TableBodyRowProps) =>
  active ? <TableBodyActiveRow {...props} /> : <TableBodyDefaultRow {...props} />
)`
  &:hover {
    [data-table-cell-type='actions'] {
      background-color: inherit;
    }
  }
`;

export const TableFooterRow = styled(TableRow)``;

export const TableBody = styled(
  forwardRef((props: TableBodyProps, ref: any) => (
    <MuiTableBody
      {...props}
      ref={ref}
    />
  ))
)``;

export const TableHead = styled(MuiTableHead)`
  position: relative;
`;

export const TableFooter = styled(MuiTableFooter)``;

export interface TableBodyCellDefaultProps extends TableCellProps {
  readonly wrap?: boolean;
  readonly fixed?: boolean;
  readonly position?: ColumnActionsPosition;
  readonly positionOffset?: number;
  readonly children?: any;
}

const TableBodyCellDefault = styled(
  forwardRef(({ fixed, wrap, positionOffset, ...props }: TableBodyCellDefaultProps, ref: any) => (
    <TableCell
      {...props}
      ref={ref}
    />
  ))
)`
  font-size: 0.8125rem;

  padding-top: ${p => p.theme.spacing(0.7)};
  padding-bottom: ${p => p.theme.spacing(0.7)};

  word-break: break-word;

  &:first-of-type {
    padding-left: 0;
  }
`;

const TableBodyCellDefaultNoWrap = styled(
  forwardRef((props: TableBodyCellDefaultProps, ref) => (
    <TableBodyCellDefault
      {...props}
      ref={ref}
    />
  ))
)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TableBodyCell = styled(
  forwardRef((props: TableBodyCellDefaultProps, ref: any) =>
    props.wrap ? (
      <TableBodyCellDefault
        {...props}
        ref={ref}
      />
    ) : (
      <TableBodyCellDefaultNoWrap
        {...props}
        ref={ref}
      />
    )
  )
)`
  z-index: 2; // фикс наслоения колонки
  ${p =>
    p.fixed &&
    `
    position: sticky;
    ${p.position === 'start' ? `left: ${p.positionOffset || 0}px` : `right: ${p.positionOffset || 0}px`};
    background-color: ${p.theme.palette.background.default};
  `}
`;

export const TableBodyCheckboxCell = styled(
  forwardRef((props: TableBodyCellDefaultProps, ref: any) => (
    <TableBodyCell
      {...props}
      ref={ref}
    />
  ))
)``;

export const TableCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: ${p => p.theme.spacing(0.5)};
    margin-left: ${p => p.theme.spacing(0.5)};
  }
`;

const TableRadio = styled(Radio)`
  &.MuiRadio-root {
    padding: ${p => p.theme.spacing(0.5)};
    margin-left: ${p => p.theme.spacing(0.5)};
  }
`;

interface TableSelectBoxProps extends CheckboxProps {
  readonly single?: boolean;
}

export const TableSelectBox = styled(({ single, ...props }: TableSelectBoxProps) =>
  single ? <TableRadio {...props} /> : <TableCheckbox {...props} />
)``;

interface TableHeadCellProps extends TableCellProps {
  readonly fixed?: boolean;
  readonly position?: ColumnActionsPosition;
  readonly positionOffset?: number;
}

export const TableHeadCell = styled(
  forwardRef(({ fixed, position, positionOffset, ...others }: TableHeadCellProps, ref: any) => (
    <TableCell
      ref={ref}
      {...others}
    />
  ))
)`
  padding-top: ${p => p.theme.spacing()};
  padding-bottom: ${p => p.theme.spacing()};

  &:first-of-type {
    padding-left: 0;
  }

  & .MuiTableSortLabel-icon {
    width: 0.8rem;
  }

  ${p =>
    p.fixed &&
    `
    z-index: 2;
    position: sticky;
    ${p.position === 'start' ? `left:` : 'right:'} ${p.positionOffset || 0}px;
    background-color: ${p.theme.palette.background.default};
  `}
`;

export const TableHeadProgressBarCell = styled(TableHeadCell)`
  position: relative;
  width: auto;
  padding: 0;
  border: 0;
`;

export const TableFooterCell = styled(TableCell)`
  padding: ${p => `${p.theme.spacing(2)} 0`};
`;

interface StyledTableProps extends TableProps {
  readonly width?: number | string;
  readonly children?: any;
}

export const Table = styled(
  forwardRef(({ width, ...others }: StyledTableProps, ref: any) => (
    <MuiTable
      {...others}
      ref={ref}
    />
  ))
)`
  table-layout: fixed;

  &.MuiTable-root {
    width: ${p => p.width};
    table-layout: auto;
    border-collapse: collapse;
  }
`;

export const TableHeadCheckboxCell = styled(
  forwardRef((props: TableHeadCellProps, ref: any) => (
    <TableHeadCell
      {...props}
      ref={ref}
    />
  ))
)`
  padding-right: 0;
`;

interface StyledTableSortLabelProps extends TableSortLabelProps {
  readonly visible?: boolean;
}

export const TableSortLabel = styled(({ visible, ...others }: StyledTableSortLabelProps) => (
  <MuiTableSortLabel {...others} />
))`
  & .MuiTableSortLabel-icon {
    ${p => (!p.visible ? 'visibility: hidden;' : '')};
    opacity: 1;
    margin-left: ${p => p.theme.spacing(0.5)};
  }

  &.MuiTableSortLabel-root.Mui-active * {
    color: ${p => p.theme.palette.primary.main};
  }
`;

interface WrapperProps {
  readonly overflowX?: Property.OverflowX;
  readonly children: any;
}

export const Wrapper = styled(
  forwardRef(({ overflowX, ...others }: WrapperProps, ref: any) => (
    <div
      {...others}
      ref={ref}
    />
  ))
)`
  width: 100%;
  height: 100%;

  position: relative;

  padding-bottom: ${p => p.theme.spacing(2)};

  overflow-x: ${p => p.overflowX};
`;

export const TableMultiCellContainer = styled.div<{ widthElement: number; width: number }>`
  display: grid;
  gap: ${p => p.theme.spacing(1)};
  grid-template-columns: repeat(auto-fit, minmax(${p => p.widthElement + 'em'}, 1fr));
  width: ${p => p.width + 'rem'};
`;
