import { getUserShortName } from '../../../../../../utils';
import OfferFieldHistoryTableCell, { OfferFieldHistoryTableCellCommonProps } from './index';

const OfferFieldHistoryTableCellUpdatedBy = (props: OfferFieldHistoryTableCellCommonProps) => {
  const {
    fieldHistory: { updatedBy },
  } = props;

  const value = updatedBy ? getUserShortName(updatedBy) : '-';

  return (
    <OfferFieldHistoryTableCell
      {...props}
      value={value}
    />
  );
};

export default OfferFieldHistoryTableCellUpdatedBy;
