import { MasterDetailsLayout } from '../../../../layouts/masterDetails/container';
import { CommonPreloaderDecorator } from '../../../general/decorators/preloader';
import { CommonUpdaterDecorator } from '../../../general/decorators/updater';
import MasterDetailsContainer from '../../../general/master/details/container';
import {
  BookingServiceCategoriesEditDialogsAdapter,
  BookingServiceCategoriesEditDictionaryAdapter,
  BookingServiceCategoriesEditHeaderAdapter,
  BookingServiceCategoriesEditStepperAdapter,
} from './adapters';
import { BookingServiceCategoriesEditInitializer } from './initializer';
import { BookingServiceCategoriesEditHandlersProvider } from './providers';
import {
  bookingServiceCategoriesEditIsLoadingSelector,
  bookingServiceCategoriesEditIsPreloadingSelector,
} from './store/selectors';
import { BookingServiceCategoriesEditAccess } from './types';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import ForbiddenComponent from '../../../../components/common/forbidden';

const BookingServiceCategoriesEditContainer = () => {
  const { bookingServiceCategories } = useCurrentUser().accessMatrix;
  const access: BookingServiceCategoriesEditAccess = {
    canCreate: bookingServiceCategories.create,
    canEdit: bookingServiceCategories.edit,
    canDelete: bookingServiceCategories.delete,
  };

  if (!bookingServiceCategories.view) {
    return <ForbiddenComponent />;
  }

  return (
    <MasterDetailsContainer
      layout={MasterDetailsLayout}
      layoutProps={{
        stepper: BookingServiceCategoriesEditStepperAdapter,
        header: BookingServiceCategoriesEditHeaderAdapter,
        decorators: [
          <CommonPreloaderDecorator
            key='preloader'
            isFetchingSelector={bookingServiceCategoriesEditIsPreloadingSelector}
          />,
          <CommonUpdaterDecorator
            key='updater'
            isFetchingSelector={bookingServiceCategoriesEditIsLoadingSelector}
          />,
        ],
      }}
      initializer={BookingServiceCategoriesEditInitializer}
      providers={[
        {
          name: 'handlers',
          factory: BookingServiceCategoriesEditHandlersProvider,
        },
      ]}
      commonProps={{
        access,
      }}
    >
      <BookingServiceCategoriesEditDictionaryAdapter access={access} />

      <BookingServiceCategoriesEditDialogsAdapter />
    </MasterDetailsContainer>
  );
};

export default BookingServiceCategoriesEditContainer;
