import { EOfferType, Nullable, UUID } from '@/domain';
import { DataFilterAdapter } from '@/presentation/hooks/useDataFilterAdapter';
import AdCampaignStubAdapter from '@features/adCampaign/table/adapters/stub';
import AdCampaignTableContainer from '@features/adCampaign/table/container';
import { AdCampaignTableConfigContext } from '@features/adCampaign/table/context';
import { useAdCampaignTableConfig } from '@features/adCampaign/table/hooks/useAdCampaignTableConfig';
import { getAdCampaignAdminTableColumns, getAdCampaignPartnerTableColumns } from '@features/adCampaign/table/utils';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import useCurrentUser from '@features/user/hooks/useCurrentUser';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  adCampaignTableFilterItems,
  EAdCampaignTableFilterItem,
  getAdCampaignTableFilterStrategy,
} from '../filterUtils';
import { AdCampaignLifeCycleFactory } from '../lifecycle/lifecycle';
import { EAdCampaignTableColumn, EAdCampaignTableTab } from '../types';

interface AdCampaignTableEntryProps {
  readonly guid: UUID;
  readonly tab: Nullable<EAdCampaignTableTab>;
  readonly partnerId?: Nullable<UUID>;
}

interface GetAdCampaignsTableAdapterKeyParams {
  readonly isAdmin?: boolean;
  readonly partnerId: Nullable<string>;
  readonly tab: EAdCampaignTableTab;
}

interface GetAdCampaignsTableAdapterKeyResult {
  readonly key: string;
  readonly keyPartitions?: (string | number | null)[];
}

const AdCampaignTableEntry = ({ guid, tab, partnerId = null }: AdCampaignTableEntryProps) => {
  const {
    accessMatrix: { adCampaigns, isPartnerUser },
    userSpecific,
  } = useCurrentUser();

  const { offerTypes } = useSelector(nsiDataSelector);

  const offerTypesForAds = useMemo(
    () => offerTypes.filter(offerType => offerType.id !== EOfferType.Product),
    [offerTypes]
  );

  const config = useAdCampaignTableConfig();

  const { defaultTab } = config;
  const outTab = tab ?? defaultTab;

  const lifecycle = useMemo(
    () =>
      AdCampaignLifeCycleFactory().create({
        isPartnerUser,
        userId: userSpecific.id,
      }),
    [isPartnerUser, userSpecific.id]
  );

  // получаем максимально возможный состав колонок
  const sourceColumns = useMemo(
    () => (isPartnerUser ? getAdCampaignPartnerTableColumns() : getAdCampaignAdminTableColumns(outTab, partnerId)),
    [outTab, partnerId, isPartnerUser]
  );

  const getAdCampaignsTableAdapterKey = (
    params: GetAdCampaignsTableAdapterKeyParams
  ): GetAdCampaignsTableAdapterKeyResult => {
    const { isAdmin, partnerId, tab } = params;

    const key = 'adCampaigns';
    let keyPartitions;

    if (isAdmin) {
      keyPartitions = ['admin', partnerId ? 'byPartner' : 'common', tab];
    } else {
      keyPartitions = ['partner', tab];
    }

    return {
      key,
      keyPartitions,
    };
  };

  // готовим адаптер для хука
  const filterAdapter = useMemo<DataFilterAdapter<EAdCampaignTableColumn, EAdCampaignTableFilterItem>>(
    () => ({
      ...getAdCampaignsTableAdapterKey({
        tab: outTab,
        partnerId,
      }),
      sourceColumns: sourceColumns,
      filterItems: adCampaignTableFilterItems,
      requiredFilters: [EAdCampaignTableFilterItem.Query],
      getDataFilterStrategy: (filterItem, values) =>
        getAdCampaignTableFilterStrategy(filterItem, values, offerTypesForAds),
    }),
    [sourceColumns, partnerId, outTab, offerTypesForAds]
  );

  return (
    <AdCampaignTableConfigContext.Provider value={config}>
      <AdCampaignTableContainer
        guid={guid}
        partnerId={partnerId}
        tab={outTab}
        filterAdapter={filterAdapter}
        stubAdapter={AdCampaignStubAdapter}
        lifecycle={lifecycle}
        access={adCampaigns}
      />
    </AdCampaignTableConfigContext.Provider>
  );
};

export default AdCampaignTableEntry;
