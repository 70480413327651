import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { UUID } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import BookingOfferDetailsContainer from '../../features/bookingOffer/details/container';
import { EBookingOfferUrlParam } from '../../features/bookingOffer/entry';
import { BookingLifeCycleFactory } from '../../features/bookingOffer/lifecycle/lifecycle';
import { BookingOfferDetailsStepType, EBookingOfferStep } from '../../features/bookingOffer/types';
import useBreadcrumbsActions from '../../features/general/breadcrumbs/useBreadcrumbsActions';
import { viewHideMainMenu } from '../../features/main/container/store/slice';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const BookingOfferDetailsScreen = () => {
  const dispatch = useDispatch();
  const { putBreadcrumbs } = useBreadcrumbsActions();
  const location = useLocation();
  const {
    accessMatrix: { bookingOffers, isPartnerUser },
    userSpecific,
  } = useCurrentUser();

  const { id } = useParams<{ id: UUID }>();
  const searchParams = new URLSearchParams(location.search);
  const step: BookingOfferDetailsStepType =
    (searchParams.get(EBookingOfferUrlParam.Step) as BookingOfferDetailsStepType) ?? EBookingOfferStep.General;

  useEffect(() => {
    dispatch(viewHideMainMenu());
  }, [dispatch]);

  useEffect(() => {
    putBreadcrumbs([{ label: 'Досуг и отдых' }]);
  }, []);

  if (!bookingOffers?.view) {
    return <ForbiddenComponent />;
  }

  return (
    <BookingOfferDetailsContainer
      id={id}
      step={step}
      lifecycle={BookingLifeCycleFactory().create({ isPartnerUser, userId: userSpecific.id })}
      access={bookingOffers}
    />
  );
};

export default BookingOfferDetailsScreen;
