import { Grid, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import moment from 'moment/moment';
import { EServiceOrderByDateType } from '../../../../../../domain/model/enums';
import { EDateFormat } from '../../../../../../domain/model/formats';
import { BookingDateSlot } from '../../../../../../domain/model/order';
import { Nullable } from '../../../../../../domain/model/types';
import AppSvgIcon from '../../../../../components/common/images/icon';
import { EditIcon } from '../../../../../icons';

const formatDate = (date: Nullable<string>): Nullable<string> =>
  (date && moment(date).format(EDateFormat.DisplayDefault)) ?? null;

interface BookingOrderItemTableCellQuantityProps {
  readonly orderByDateType: EServiceOrderByDateType;
  readonly quantity: Nullable<number>;
  readonly slots: BookingDateSlot[];
  readonly hasError?: boolean;
  readonly onClick: Nullable<() => void>;
}

const BookingOrderItemTableCellQuantity = (props: BookingOrderItemTableCellQuantityProps) => {
  const { quantity, slots, hasError, orderByDateType, onClick } = props;

  return (
    <Grid
      container
      alignItems='center'
    >
      <Grid
        item
        xs={5}
      >
        {orderByDateType === EServiceOrderByDateType.Period && slots[0]?.startDate && (
          <Typography>{formatDate(slots[0].startDate) + ' -'}</Typography>
        )}
        {orderByDateType === EServiceOrderByDateType.Period && slots[0]?.endDate && (
          <Typography>{formatDate(slots[0].endDate)}</Typography>
        )}
        {orderByDateType === EServiceOrderByDateType.Days &&
          slots.map((slot, index) => <Typography key={index}>{formatDate(slot.startDate)}</Typography>)}
      </Grid>
      <Grid
        item
        xs={4}
      >
        {orderByDateType === EServiceOrderByDateType.None && <Typography align='right'>{quantity}</Typography>}
        {orderByDateType === EServiceOrderByDateType.Period && <Typography align='right'>{slots[0]?.qty}</Typography>}
        {orderByDateType === EServiceOrderByDateType.Days &&
          slots.map((slot, index) => (
            <Typography
              key={index}
              align='right'
            >
              {slot.qty}
            </Typography>
          ))}
      </Grid>
      <Grid
        item
        xs={1}
      />
      <Grid
        item
        xs={2}
      >
        {onClick && (
          <IconButton
            size='small'
            onClick={onClick}
          >
            <AppSvgIcon
              color={hasError ? 'error' : 'primary'}
              fontSize='small'
              icon={EditIcon}
            />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default BookingOrderItemTableCellQuantity;
