import { Location } from 'history';
import { v4 as uuidv4 } from 'uuid';
import { MenuPanelItem } from '../../../types';

export const menuPanelItemIsSelected = (item: MenuPanelItem, location: Location): boolean => {
  const itemLink = typeof item.link === 'string' ? item.link : `${item.link.pathname}${item.link.search}`;
  if (!item.subMenu) {
    return (
      itemLink.split('?')[0] === location.pathname ||
      !!(itemLink && location.pathname.split('/')[1] === itemLink.replace('/', '')) ||
      !!(itemLink && location.pathname.indexOf(itemLink) === 0)
    );
  } else {
    return (
      itemLink.split('?')[0] === location.pathname ||
      !!(itemLink && location.pathname.split('/')[1] === itemLink.split('/')[1].replace('/', ''))
    );
  }
};

export const getMenuItemLocation = (item: MenuPanelItem) =>
  typeof item.link === 'string'
    ? {
        pathname: item.link,
        state: {
          guid: uuidv4(),
        },
      }
    : {
        ...item.link,
        state: {
          guid: uuidv4(),
        },
      };
