import Api from '../../../../data/api';
import { ApiCancellable } from '../../../../data/api/types';
import { CmsSitePage, CmsSitePageRequest } from '../../../../domain/model/cms';
import { DataFilterQueryDslOperator } from '../../../../domain/model/filter';
import { Nullable, UUID } from '../../../../domain/model/types';

type ByIdProps = ApiCancellable & {
  readonly id: UUID;
};

type ByContainerIdProps = ApiCancellable & {
  readonly containerId: UUID;
};

type SaveProps = {
  readonly id?: Nullable<UUID>;
  readonly data: CmsSitePageRequest;
};

type GetOrCreateForPartnerProps = {
  readonly partnerId: UUID;
};

export type CmsSitePageService = {
  readonly byId: (props: ByIdProps) => Promise<CmsSitePage>;
  readonly byContainerId: (props: ByContainerIdProps) => Promise<Nullable<CmsSitePage>>;
  readonly save: (props: SaveProps) => Promise<CmsSitePage>;
  readonly getOrCreateForPartner: (props: GetOrCreateForPartnerProps) => Promise<CmsSitePage>;
  readonly getOrCreateForLanding: () => Promise<CmsSitePage>;
};

const cmsSitePageService: CmsSitePageService = {
  byId: async ({ id, signal }) => {
    const { data: cmsSitePage } = await Api.cms.page.one({
      id,
      signal,
    });
    return cmsSitePage;
  },
  byContainerId: async ({ containerId, signal }) => {
    const { data: cmsSitePages } = await Api.cms.page.all({
      querydsl: [{ field: 'containers.id', operator: DataFilterQueryDslOperator.In, value: [containerId] }],
      signal,
    });
    return cmsSitePages?.[0] ?? null;
  },
  save: async ({ id, data }) => {
    const { data: sitePage } = await Api.cms.page.save({
      id,
      data: {
        ...data,
        containers: data?.containers?.map(container => ({ id: container.id })) ?? null,
      },
    });
    return sitePage;
  },
  getOrCreateForPartner: async ({ partnerId }) => {
    const { data } = await Api.cms.page.all({
      querydsl: [{ field: 'partnerId', operator: DataFilterQueryDslOperator.Equals, value: [partnerId] }],
    });
    if (data.length === 0) {
      const { data: cmsSitePage } = await Api.cms.page.save({
        data: {
          partner: { id: partnerId },
          containers: null,
          enabled: false,
        },
      });
      return cmsSitePage;
    } else {
      return data[0];
    }
  },
  getOrCreateForLanding: async () => {
    const { data } = await Api.cms.page.all({
      querydsl: [{ field: 'partnerId', operator: DataFilterQueryDslOperator.Equals, value: [null] }],
    });

    const existedSitePage = data?.[0];

    if (!existedSitePage) {
      const { data: cmsSitePage } = await Api.cms.page.save({
        data: {
          partner: null,
          containers: null,
          enabled: false,
        },
      });
      return cmsSitePage;
    } else {
      return existedSitePage;
    }
  },
};

export default cmsSitePageService;
