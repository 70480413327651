import { UUID } from '../../../../../domain/model/types';
import useSportsmansTableSearch from '../hooks/useSearch';
import useSportsmansTableSession from '../hooks/useSession';
import { ESportsmanTableTab } from '../utils';

interface SportsmansBehaviorAdapterProps {
  readonly guid: UUID;
  readonly tab: ESportsmanTableTab;
}

export const SportsmansBehaviorAdapter = ({ guid, tab }: SportsmansBehaviorAdapterProps) => {
  useSportsmansTableSession({ guid, tab });
  useSportsmansTableSearch({ guid });

  return null;
};
