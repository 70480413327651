import { UUID } from '../../../../../domain/model/types';
import useTeamsTableSearch from '../hooks/useSearch';
import useTeamsTableSession from '../hooks/useSession';
import { ETeamTableTab } from '../utils';

interface TeamsBehaviorAdapterProps {
  readonly guid: UUID;
  readonly tab: ETeamTableTab;
}

export const TeamsBehaviorAdapter = ({ guid, tab }: TeamsBehaviorAdapterProps) => {
  useTeamsTableSession({ guid, tab });
  useTeamsTableSearch({ guid });

  return null;
};
