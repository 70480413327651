import { useSelector } from 'react-redux';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { ETeamTableFilterItem } from '../../filterUtils';
import TeamsTable from '../component';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  teamsFilterSelector,
  teamsGuidSelector,
  teamsSortColumnSelector,
  teamsSortDirectionSelector,
  teamsTeamsSelector,
} from '../store/selectors';
import { ETeamTableColumn } from '../utils';

interface TeamsTableAdapterProps {
  readonly filterAdapter: DataFilterAdapter<ETeamTableColumn, ETeamTableFilterItem>;
}

const TeamsTableAdapter = (props: TeamsTableAdapterProps) => {
  const { filterAdapter } = props;

  const handlers = useContextHandlers();
  const { onTeamClick } = useContextConfig();

  const guid = useSelector(teamsGuidSelector);
  const teams = useSelector(teamsTeamsSelector);

  const sortColumn = useSelector(teamsSortColumnSelector);
  const sortDirection = useSelector(teamsSortDirectionSelector);
  const filter = useSelector(teamsFilterSelector);

  const { metadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return (
    <TeamsTable
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      teams={teams}
      onRequestSort={handlers.onChangeSort}
      onClick={onTeamClick}
    />
  );
};

export default TeamsTableAdapter;
