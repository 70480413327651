import { Skeleton } from '@mui/material';
import {
  ContentWrapper,
  ImageStub,
  ImageWrapper,
  PartnerIcon,
  SkeletonWrapper,
  StyledImage,
  Wrapper,
} from './controls';

interface CmsCompilationPreviewBlockProps {
  readonly image?: string;
}

const CmsCompilationPreviewBlock = ({ image }: CmsCompilationPreviewBlockProps) => (
  <Wrapper>
    <ContentWrapper>
      <ImageWrapper>{image ? <StyledImage src={image} /> : <ImageStub />}</ImageWrapper>
      <SkeletonWrapper>
        <Skeleton
          animation={false}
          height={4}
          variant='text'
          width='100%'
        />
        <Skeleton
          animation={false}
          height={4}
          variant='text'
          width='75%'
        />
        <Skeleton
          animation={false}
          height={6}
          variant='text'
          width='30%'
        />
      </SkeletonWrapper>
      <PartnerIcon
        animation={false}
        variant='rectangular'
        width='0.5rem'
        height='0.5rem'
      />
    </ContentWrapper>
  </Wrapper>
);

export default CmsCompilationPreviewBlock;
