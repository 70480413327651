import styled from '@emotion/styled';
import AppImage, { AppImageProps } from '../../../images/common';

interface ImageProps extends AppImageProps {
  readonly scaleType: 'fit-center' | 'crop-center';
}

export const Image = styled(({ scaleType, ...others }: ImageProps) => (
  <AppImage
    alt=''
    {...others}
  />
))`
  vertical-align: top;
  ${p =>
    p.scaleType === 'fit-center' &&
    `
    object-fit: contain;
  `}

  ${p =>
    p.scaleType === 'crop-center' &&
    `
    object-fit: cover;
  `}
`;
