import ErrorHandler from '@/data/network/errorHandler';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { AdCampaign, AdCampaignCreate } from '@/domain';
import { convertAdCampaignToAdCampaignCreate, createEmptyAdCampaignCreate } from '@features/adCampaign/create/utils';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { ValidationItemResult, ValidationResult } from '../../../../utils/validation';
import adCampaignServices from '../../services';
import { AdCampaignCreateStepType } from '../../types';
import { AdCampaignCreateUiState } from '../types';

export const adCampaignCreateByIdFetch = createAsyncThunk<Nullable<AdCampaign>, Nullable<UUID>, AppThunkAPIConfig>(
  'adCampaign/create/byId/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      if (id) {
        return await adCampaignServices.one({ id, signal });
      } else {
        return null;
      }
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export type AdCampaignCreateValidationStepper = Nullable<
  Record<AdCampaignCreateStepType, Nullable<ValidationResult<any>>>
>;

export interface AdCampaignCreateState {
  readonly guid: Nullable<UUID>;
  readonly byId: Fetchable & {
    readonly adCampaign: AdCampaignCreate;
    readonly loadedData: Nullable<AdCampaign>;
  };
  readonly modified: boolean;

  readonly validation: ValidationResult<AdCampaignCreate>;
  readonly validationStepper: AdCampaignCreateValidationStepper;

  readonly ui: Nullable<AdCampaignCreateUiState>;
}

type Reducer<T = undefined> = CaseReducer<AdCampaignCreateState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<AdCampaignCreateState> {
  adCampaignCreateStartSession: Reducer<{
    guid: Nullable<UUID>;
  }>;
  adCampaignCreateSetModified: Reducer<boolean>;
  adCampaignCreateClearActionsState: Reducer;
  adCampaignCreateSetValidationStepper: Reducer<
    Nullable<Record<AdCampaignCreateStepType, Nullable<ValidationResult<any>>>>
  >;
  adCampaignCreateClearAllValidations: Reducer;

  adCampaignCreateSetAttribute: Reducer<{
    name: keyof AdCampaignCreate;
    value: any;
  }>;
  adCampaignCreateSetUiState: Reducer<{
    name: keyof AdCampaignCreateUiState;
    value: any;
  }>;
  adCampaignCreateClearAttributeValidation: Reducer<keyof AdCampaignCreate>;
  adCampaignCreateSetAttributeValidation: Reducer<{
    name: keyof AdCampaignCreate;
    results: Nullable<ValidationItemResult>;
  }>;
  adCampaignCreateSetValidation: Reducer<Nullable<ValidationResult<AdCampaignCreate>>>;

  adCampaignCreateApplySavedAdCampaign: Reducer<AdCampaign>;
}

const slice = createSlice<AdCampaignCreateState, Reducers, 'adCampaign/create'>({
  name: 'adCampaign/create',
  initialState: {
    guid: null,
    byId: {
      ...fetchableDefault,
      adCampaign: createEmptyAdCampaignCreate(),
      loadedData: null,
    },
    modified: false,
    validation: {},
    validationStepper: null,
    ui: null,
  },
  reducers: {
    adCampaignCreateStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        state.guid = guid;
        state.byId = {
          ...fetchableDefault,
          adCampaign: createEmptyAdCampaignCreate(),
          loadedData: null,
        };
        state.modified = false;
        state.validation = {};
        state.ui = null;
      }
    },
    adCampaignCreateSetModified: (state, { payload }) => {
      state.modified = payload;
    },
    adCampaignCreateClearActionsState: state => {
      state.validation = {};
      state.validationStepper = null;
      state.ui = null;
    },
    adCampaignCreateSetUiState: (state, { payload }) => {
      const { name, value } = payload;
      if (!state.ui) {
        state.ui = {
          steps: [],
          fields: { visible: [], disabled: [] },
        };
      }
      state.ui[name] = value;
    },

    adCampaignCreateSetValidationStepper: (state, { payload }) => {
      state.validationStepper = payload;
    },

    adCampaignCreateSetAttribute: (state, { payload }) => {
      const { name, value } = payload;

      if (state.byId.adCampaign) {
        (state.byId.adCampaign[name] as keyof AdCampaignCreate) = value;
      }
    },
    adCampaignCreateClearAllValidations: state => {
      state.validation = {};
    },
    adCampaignCreateClearAttributeValidation: (state, { payload }) => {
      delete state.validation?.[payload];
    },
    adCampaignCreateSetAttributeValidation: (state, { payload }) => {
      const { name, results } = payload;
      if (!results) {
        delete state.validation?.[name];
      } else {
        state.validation[name] = results;
      }
    },
    adCampaignCreateSetValidation: (state, { payload }) => {
      state.validation = payload ?? {};
    },
    adCampaignCreateApplySavedAdCampaign: (state, { payload }) => {
      state.byId.adCampaign = convertAdCampaignToAdCampaignCreate(payload);
      state.byId.loadedData = payload;
      state.modified = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(adCampaignCreateByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
      })
      .addCase(adCampaignCreateByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.adCampaign = payload ? convertAdCampaignToAdCampaignCreate(payload) : createEmptyAdCampaignCreate();
        state.byId.loadedData = payload;
      })
      .addCase(adCampaignCreateByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        } else {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = false;
        }
      });
  },
});

export const {
  adCampaignCreateStartSession,
  adCampaignCreateSetModified,
  adCampaignCreateClearActionsState,
  adCampaignCreateSetUiState,

  adCampaignCreateClearAllValidations,
  adCampaignCreateSetValidationStepper,

  adCampaignCreateSetAttribute,
  adCampaignCreateSetValidation,
  adCampaignCreateClearAttributeValidation,
  adCampaignCreateSetAttributeValidation,

  adCampaignCreateApplySavedAdCampaign,
} = slice.actions;

export default slice.reducer;
