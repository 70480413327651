import moment from 'moment';
import Api from '../../../../../data/api';
import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterStrategyView,
  DataFilterValueItem,
  EDataFilterType,
  EDateFormat,
  EOfferStatus,
  EPrivilegeActorType,
  EUserRole,
  Nullable,
  pageSizeAll,
  SportOptionTyped,
} from '@/domain';
import { getUserFullName } from '../../../../utils';
import { DataTableColumns } from '@components/common/table';
import { EOfferHistoryTableColumn } from '@features/offerHistory/table/utils/common';

export enum EOfferHistoryFilterItem {
  EnteredAt = 'enteredAt',
  Status = 'status',
  EnteredBy = 'enteredBy.id',
  ActorType = 'actorType',
}

export type OfferHistoryFilterEditStrategy = DataFilterStrategyBase<EOfferHistoryFilterItem>;
export type OfferHistoryFilterViewStrategy = DataFilterStrategyView<EOfferHistoryFilterItem>;
export type OfferHistoryFilterValues = {
  readonly [EOfferHistoryFilterItem.EnteredAt]?: DataFilterValueItem<Nullable<[string, string]>>;
  readonly [EOfferHistoryFilterItem.Status]?: DataFilterValueItem<Nullable<string[]>>;
  readonly [EOfferHistoryFilterItem.ActorType]?: DataFilterValueItem<Nullable<string[]>>;
  readonly [EOfferHistoryFilterItem.EnteredBy]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
};

export const getOfferHistoryFilterStrategies = (
  values: OfferHistoryFilterValues,
  offerStatuses: SportOptionTyped<EOfferStatus>[],
  actorTypes: SportOptionTyped<EPrivilegeActorType>[]
): OfferHistoryFilterEditStrategy[] => {
  return [
    {
      type: EDataFilterType.DateAsTimePeriod,
      key: EOfferHistoryFilterItem.EnteredAt,
      label: 'Дата',
      preview: moment(values[EOfferHistoryFilterItem.EnteredAt]?.value?.[0]).format(EDateFormat.DisplayDefault) || null,
      value: values[EOfferHistoryFilterItem.EnteredAt]?.value || null,
      querydsl: {
        operator: DataFilterQueryDslOperator.Between,
      },
    },
    {
      type: EDataFilterType.ListMultiple,
      key: EOfferHistoryFilterItem.Status,
      label: 'Событие',
      preview: `Событие (${values[EOfferHistoryFilterItem.Status]?.value?.length})`,
      previews: offerStatuses
        .filter(t => values[EOfferHistoryFilterItem.Status]?.value?.some(v => v === t.id))
        .map(t => t.name),
      value: values[EOfferHistoryFilterItem.Status]?.value || null,
      items: offerStatuses.map(t => ({ id: t.id, name: t.name })),
      querydsl: {
        operator: DataFilterQueryDslOperator.In,
      },
    },

    {
      type: EDataFilterType.AutocompleteMultiple,
      key: EOfferHistoryFilterItem.EnteredBy,
      label: 'Пользователь',
      value: values[EOfferHistoryFilterItem.EnteredBy]?.value || null,
      preview: `Пользователь (${values[EOfferHistoryFilterItem.EnteredBy]?.value?.length})` || null,
      previews: values[EOfferHistoryFilterItem.EnteredBy]?.value?.map(t => t.name) || null,
      source: (name: string) =>
        name
          ? Api.user.mp
              .all({
                query: name,
                page: 1,
                pageSize: pageSizeAll,
                sort: ['lastName,asc', 'firstName,asc', 'middleName,asc'],
                roles: [EUserRole.AdminPartner, EUserRole.AdminMp],
              })
              .then(response => response.data.map(item => ({ ...item, name: getUserFullName({ user: item }) })))
          : Promise.resolve([]),
      querydsl: {
        operator: DataFilterQueryDslOperator.In,
        valueAttribute: 'id',
      },
    },
    {
      type: EDataFilterType.ListMultiple,
      key: EOfferHistoryFilterItem.ActorType,
      label: 'Роль',
      preview: `Роль (${values[EOfferHistoryFilterItem.ActorType]?.value?.length})`,
      previews: actorTypes
        .filter(t => values[EOfferHistoryFilterItem.ActorType]?.value?.some(v => v === t.id))
        .map(t => t.name),
      value: values[EOfferHistoryFilterItem.ActorType]?.value || null,
      items: actorTypes.map(t => ({ id: t.id, name: t.name })),
      querydsl: {
        operator: DataFilterQueryDslOperator.In,
      },
    },
  ];
};

export const getOfferHistoryColumns = (): DataTableColumns<EOfferHistoryTableColumn> => ({
  [EOfferHistoryTableColumn.EnteredAt]: {
    title: 'Дата',
    width: '5rem',
  },
  [EOfferHistoryTableColumn.EnteredAtTime]: {
    title: 'Время',
    width: '4rem',
  },
  [EOfferHistoryTableColumn.Status]: {
    title: 'Событие',
    width: '13rem',
  },
  [EOfferHistoryTableColumn.ActorType]: {
    title: 'Роль',
    width: '10rem',
  },
  [EOfferHistoryTableColumn.EnteredBy]: {
    title: 'Пользователь',
    width: '10rem',
  },
  [EOfferHistoryTableColumn.StatusComment]: {
    title: 'Комментарий',
    width: '23rem',
  },
});
