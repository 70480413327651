import { DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { CloseIconButton, StyledDialog } from './controls';

interface ConfirmDialogProps {
  readonly open: boolean;
  readonly title?: React.ReactNode;
  readonly text?: React.ReactNode | string;
  readonly className?: string;
  readonly dialogButtons?: any;
  readonly dialogProps?: DialogProps;
  readonly disableStandardClose?: any;
  readonly children?: React.ReactNode;
  readonly onClose?: () => void;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { title, text, open, className, disableStandardClose, dialogProps, dialogButtons, children, onClose } = props;

  return (
    <StyledDialog
      className={className}
      fullWidth
      keepMounted
      onClose={!disableStandardClose ? () => onClose?.() : () => null}
      {...dialogProps}
      open={open}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {text && <DialogContentText color='textPrimary'>{text}</DialogContentText>}
        {onClose && (
          <CloseIconButton onClick={onClose}>
            <CloseIcon />
          </CloseIconButton>
        )}
        {children}
      </DialogContent>
      {dialogButtons && <DialogActions>{dialogButtons}</DialogActions>}
    </StyledDialog>
  );
};

export default ConfirmDialog;
