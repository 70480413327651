import styled from '@emotion/styled';
import { Card, CardActions, CardContent, cardContentClasses, css, Skeleton, Typography } from '@mui/material';
import { CardProps } from '@mui/material/Card/Card';
import { CardActionsProps } from '@mui/material/CardActions/CardActions';
import { CardContentProps } from '@mui/material/CardContent/CardContent';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { HTMLAttributes } from 'react';
import AppImage, { AppImageProps } from '../../../../../components/common/images/common';
import { publicAppPalette } from '@theme/palette';
import { EEntityPreviewMode } from '../../../../../types';

type OfferPreviewModedProps<T> = T & {
  readonly mode: EEntityPreviewMode;
};

type OfferPreviewCardProps = CardProps & {
  readonly hasBorder?: boolean;
};

export const OfferPreviewCard = styled(({ hasBorder, children, ...props }: OfferPreviewCardProps) => (
  <Card {...props}>{children}</Card>
))(
  ({ theme, hasBorder }) => css`
    position: relative;

    user-select: none;
    cursor: pointer;

    border-radius: ${(theme.shape.borderRadius as number) * 3}px;

    ${hasBorder &&
    `
      border: 1px solid ${publicAppPalette.border};
    `}

    display: flex;
    flex-direction: column;
  `
);

type ImagePreviewWrapperProps = HTMLAttributes<HTMLDivElement> & {
  readonly ratio: number;
};

export const ImagePreviewWrapper = styled(({ ratio, ...props }: ImagePreviewWrapperProps) => <div {...props} />)(
  ({ ratio }) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: ${ratio};
    overflow-x: hidden;
    border-radius: inherit;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center center;
    }
  `
);

export const OfferPreviewSkeleton = styled(Skeleton)(
  ({ theme }) =>
    css`
      transform: none;
      background-color: #e3e5e8;
    `
);

export const CardFavoriteWrapper = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing()};
    right: ${theme.spacing()};

    & .MuiSvgIcon-root {
      color: ${theme.palette.grey['400']};
      font-size: 1.3rem;
    }
  `
);

export const OfferTagsWrapper = styled(
  ({ mode, children, ...props }: OfferPreviewModedProps<HTMLAttributes<HTMLDivElement>>) => (
    <div {...props}>{children}</div>
  )
)(
  ({ theme, mode }) => css`
    position: absolute;
    z-index: 1;
    bottom: ${theme.spacing(mode === EEntityPreviewMode.Mobile ? 1 : 2)};
    left: ${theme.spacing(mode === EEntityPreviewMode.Mobile ? 1 : 2)};

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > * + * {
      margin-top: ${theme.spacing()};
    }
  `
);

export const OfferPreviewCardDetails = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `
);

export const OfferPreviewContent = styled(({ mode, children, ...props }: OfferPreviewModedProps<CardContentProps>) => (
  <CardContent {...props}>{children}</CardContent>
))(
  ({ theme, mode }) => css`
    direction: ltr;
    flex: 1;

    padding: ${theme.spacing(1.5, mode === EEntityPreviewMode.Mobile ? 1 : 2)};

    &.${cardContentClasses.root}:last-child {
      padding-bottom: ${theme.spacing(1.5)};
    }
  `
);

export const OfferPreviewDescription = styled(
  ({ mode, children, ...props }: OfferPreviewModedProps<TypographyProps>) => (
    <Typography {...props}>{children}</Typography>
  )
)(
  ({ mode }) => css`
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: ${mode === EEntityPreviewMode.Mobile ? 3 : 4};
    -webkit-box-orient: vertical;
  `
);

export const OfferPreviewFooter = styled(({ mode, children, ...props }: OfferPreviewModedProps<CardActionsProps>) => (
  <CardActions {...props}>{children}</CardActions>
))(
  ({ theme, mode }) => css`
    direction: ltr;
    display: flex;
    align-items: center;
    padding: ${mode === EEntityPreviewMode.Mobile ? theme.spacing(0, 1, 0.75, 1) : theme.spacing(0, 2, 2, 2)};
  `
);

export const OfferPreviewHeader = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing()};
  `
);

export const OfferPreviewLogoWrapper = styled(
  ({ mode, children, ...props }: OfferPreviewModedProps<HTMLAttributes<HTMLDivElement>>) => (
    <div {...props}>{children}</div>
  )
)(
  ({ mode }) => css`
    flex: 0 0 auto;

    width: ${mode === EEntityPreviewMode.Mobile ? 1.5 : 2}rem;
    height: ${mode === EEntityPreviewMode.Mobile ? 1.5 : 2}rem;
  `
);

type LogoImageProps = AppImageProps & {
  readonly mode: EEntityPreviewMode;
};

export const OfferPreviewLogoImage = styled(({ mode, ...props }: LogoImageProps) => <AppImage {...props} />)(
  ({ mode }) => css`
    width: ${mode === EEntityPreviewMode.Mobile ? 1.5 : 2}rem;
    height: auto;
    max-height: ${mode === EEntityPreviewMode.Mobile ? 1.5 : 2}rem;

    overflow: hidden;
  `
);

export const OfferPreviewLogoSkeleton = styled(OfferPreviewSkeleton)`
  transform: none;
  border-radius: ${p => p.theme.shape.borderRadius}px;
`;

export const OfferPreviewImageSkeleton = styled(OfferPreviewSkeleton)`
  transform: none;
`;

export const OfferPreviewEllipsis = styled(Typography)(
  () => css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `
);

export const OfferPreviewCaption = styled(OfferPreviewEllipsis)(
  ({ theme }) => css`
    padding-right: ${theme.spacing(3)};
  `
);
