import { RootState } from '@/data/store/store';
import { CorpOfferActivation } from '@/domain/model/activation';
import { Nullable, UUID } from '@/domain/model/types';
import { createContext } from 'react';
import { ECorpActivationActionType } from '../types';

export interface CorpActivationActionsSelectors {
  readonly getIsCorpActivationProcessingSelector: (id: Nullable<UUID>) => (store: RootState) => boolean;
  readonly getIsCorpActivationExecutedActionsSelector: (
    id: Nullable<UUID>,
    ...actions: ECorpActivationActionType[]
  ) => (store: RootState) => Nullable<ECorpActivationActionType>;
  readonly isCorpActivationsAnyChangedSelector: (store: RootState) => number;
}

export type CorpActivationActionsContextType = {
  readonly onGive: (props: CorpOfferActivation) => void;
  readonly onUnGive: (props: CorpOfferActivation) => void;

  readonly utils: {
    readonly selectors: CorpActivationActionsSelectors;
  };
};

const CorpActivationActionsContext = createContext<CorpActivationActionsContextType>(
  {} as CorpActivationActionsContextType
);

export default CorpActivationActionsContext;
