import { combineReducers } from '@reduxjs/toolkit';
import edit, { UserSportEditState } from './edit/store/slice';

export interface UserSportState {
  edit: UserSportEditState;
}

export default combineReducers<UserSportState>({
  edit,
});
