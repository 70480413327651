import { Nullable, UUID } from '@/domain/model/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ECorpActivationTableTab } from '../../types';
import { getCorpActivationStatusesByTableTab } from '../../utils/table';
import {
  corpActivationsFilterSelector,
  corpActivationsNeedRefreshWatcherSelector,
  corpActivationsPageNumberSelector,
  corpActivationsSearchSelector,
} from '../store/selectors';
import {
  corpActivationsCountsFetch,
  corpActivationsFetch,
  CorpActivationsFetchProps,
  corpActivationsNeedRefreshWatcherReset,
} from '../store/slice';
import { useContextConfig } from './useContextConfig';

type useCorpActivationTableSearchProps = {
  readonly tab: ECorpActivationTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly customerId: Nullable<UUID>;
};

export const useCorpActivationTableSearch = (props: useCorpActivationTableSearchProps) => {
  const { tab, partnerId, customerId } = props;
  const dispatch = useDispatch();

  const { tabsForCounts } = useContextConfig();

  const [searchPromise, setSearchPromise] = useState<Nullable<any[]>>(null);

  const needRefreshWatcher = useSelector(corpActivationsNeedRefreshWatcherSelector);
  const pageNumber = useSelector(corpActivationsPageNumberSelector);
  const search = useSelector(corpActivationsSearchSelector);
  const filter = useSelector(corpActivationsFilterSelector);

  const currentStatuses = getCorpActivationStatusesByTableTab(tab);

  const queryParams = useMemo<CorpActivationsFetchProps>(
    () => ({
      search: {
        ...search,
        statuses: currentStatuses,
        partnerId,
        customerId,
      },
      pageNumber,
      filter,
    }),
    [search, currentStatuses, partnerId, customerId, pageNumber, filter]
  );

  const onSearch = useCallback(() => {
    setSearchPromise([
      dispatch(corpActivationsFetch(queryParams)),
      dispatch(
        corpActivationsCountsFetch({
          ...queryParams,
          tabs: tabsForCounts,
        })
      ),
    ]);
  }, [queryParams, tabsForCounts]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromise?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromise?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  useEffect(() => {
    return () => {
      dispatch(corpActivationsNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};
