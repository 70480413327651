import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UUID } from '../../../../../domain/model/types';
import { teamsGuidSelector } from '../store/selectors';
import { teamsSetTab, teamsStartSession } from '../store/slice';
import { ETeamTableTab } from '../utils';

interface UseTeamsTableSessionProps {
  readonly guid: UUID;
  readonly tab: ETeamTableTab;
}

const useTeamsTableSession = (props: UseTeamsTableSessionProps): void => {
  const { guid, tab } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(teamsGuidSelector);

  // инициализация сеанса
  useEffect(() => {
    dispatch(teamsStartSession({ guid }));
  }, [dispatch, guid]);

  // начальная загрузка, изменение вкладки
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(teamsSetTab({ tab }));
    }
  }, [dispatch, guid, currentGuid, tab]);
};

export default useTeamsTableSession;
