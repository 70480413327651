import { RouteProps } from 'react-router';
import { Route } from 'react-router-dom';
import { Nullable } from '../../../../domain/model/types';
import ForbiddenComponent from '../forbidden';
import { EAppFeature } from '../../../types';
import RouteByFeature from '../../../features/general/routing/routeByFeature';

interface ProtectedRouteProps extends RouteProps {
  readonly allowed?: boolean;
  readonly feature?: {
    readonly name: EAppFeature;
    readonly defaultRoute: string;
  };
}

const ProtectedRoute = ({ allowed, feature, ...others }: ProtectedRouteProps): Nullable<JSX.Element> => {
  if (!allowed) {
    return <ForbiddenComponent />;
  }

  if (feature) {
    return (
      <RouteByFeature
        defaultRoute={feature.defaultRoute}
        feature={feature.name}
        {...others}
      />
    );
  }

  return <Route {...others} />;
};

export default ProtectedRoute;
