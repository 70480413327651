import { combineReducers } from '@reduxjs/toolkit';
import list, { ProductListState } from './table/store/slice';
import create, { ProductCreateState } from './create/store/slice';
import details, { ProductOfferDetailsState } from './details/store/slice';

interface ProductState {
  list: ProductListState;
  create: ProductCreateState;
  details: ProductOfferDetailsState;
}

export default combineReducers<ProductState>({
  list,
  create,
  details,
});
