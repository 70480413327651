import React from 'react';
import { Nullable } from '../../../../../domain/model/types';
import { FeaturesOptions } from '../../../../../system/appConfigurator';
import { EAppFeature } from '../../../../types';

export interface TechConfigContextType {
  readonly isDebug: boolean;
  readonly hasFeature: (feature: EAppFeature) => boolean;
  readonly getFeatureOptions: <T extends keyof FeaturesOptions>(feature: T) => Nullable<FeaturesOptions[T]>;
}

const TechConfigContext = React.createContext<TechConfigContextType>({} as TechConfigContextType);

export default TechConfigContext;
