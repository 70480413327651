import { combineReducers } from '@reduxjs/toolkit';
import edit, { TradeOfferCategoriesEditState } from './edit/store/slice';
import select, { TradeOfferCategoriesSelectState } from './select/store/slice';

interface TradeOfferCategoriesState {
  edit: TradeOfferCategoriesEditState;
  select: TradeOfferCategoriesSelectState;
}

export default combineReducers<TradeOfferCategoriesState>({
  edit,
  select,
});
