import { Typography } from '@mui/material';
import { SportEnumOption } from '../../../../../domain/model';
import { ECmsContainerType } from '../../../../../domain/model/enums';
import { getEmptyCmsLinkedObjectsByType } from '../../container/utils';
import CmsPreviewByContainerType from '../preview/byContainerType';
import { Block, StyledDialog } from './controls';

interface CmsAddContainerDialogProps {
  readonly types: SportEnumOption<ECmsContainerType>[];
  readonly open: boolean;
  readonly onAdd: (type: ECmsContainerType) => void;
  readonly onClose: () => void;
}

const CmsAddContainerDialog = (props: CmsAddContainerDialogProps) => {
  const { types, open, onAdd, onClose } = props;

  return (
    <StyledDialog
      open={open}
      title='Добавление блока'
      text='Выберите тип блока'
      onClose={onClose}
    >
      {types.map(({ code, name }) => (
        <Block
          key={code}
          onClick={() => onAdd(code)}
        >
          <CmsPreviewByContainerType
            type={code}
            cmsLinkedObjects={getEmptyCmsLinkedObjectsByType(code)}
          />
          <Typography align='center'>{name}</Typography>
        </Block>
      ))}
    </StyledDialog>
  );
};

export default CmsAddContainerDialog;
