import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { EBookingOrderTableTab } from '../../types';
import {
  bookingOrdersFilterSelector,
  bookingOrdersNeedRefreshWatcherSelector,
  bookingOrdersPageNumberSelector,
  bookingOrdersSearchSelector,
} from '../store/selectors';
import {
  bookingOrdersCountsFetch,
  bookingOrdersFetch,
  BookingOrdersFetchProps,
  bookingOrdersNeedRefreshWatcherReset,
} from '../store/slice';
import { getBookingOrderStatusesByTableTab } from '../utils';
import { useContextConfig } from './useContextConfig';

type useBookingOrderTableSearchProps = {
  readonly tab: EBookingOrderTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly customerId: Nullable<UUID>;
};

export const useBookingOrderTableSearch = (props: useBookingOrderTableSearchProps) => {
  const { tab, partnerId, customerId } = props;
  const dispatch = useDispatch();

  const { tabsForCounts } = useContextConfig();

  const [searchPromise, setSearchPromise] = useState<Nullable<any[]>>(null);

  const needRefreshWatcher = useSelector(bookingOrdersNeedRefreshWatcherSelector);
  const pageNumber = useSelector(bookingOrdersPageNumberSelector);
  const search = useSelector(bookingOrdersSearchSelector);
  const filter = useSelector(bookingOrdersFilterSelector);

  const currentStatuses = getBookingOrderStatusesByTableTab(tab);

  const queryParams = useMemo<BookingOrdersFetchProps>(
    () => ({
      search: {
        ...search,
        statuses: currentStatuses,
        partnerId,
        customerId,
      },
      pageNumber,
      filter,
    }),
    [search, currentStatuses, partnerId, customerId, pageNumber, filter]
  );

  const onSearch = useCallback(() => {
    setSearchPromise([
      dispatch(bookingOrdersFetch(queryParams)),
      dispatch(
        bookingOrdersCountsFetch({
          ...queryParams,
          tabs: tabsForCounts,
        })
      ),
    ]);
  }, [queryParams, tabsForCounts]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromise?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromise?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  useEffect(() => {
    return () => {
      dispatch(bookingOrdersNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};
