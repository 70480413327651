import { WebAnalyticsConfigurator } from '@features/webAnalytics';
import moment from 'moment-timezone';
import 'moment/dist/locale/ru';
import { createRoot } from 'react-dom/client';
import App from './app/app';
import HttpClient from './data/network/http';
import './index.css';
import SentryClient from './integration/sentry/client';
import { locale } from './locale';
import { EAppFeature } from './presentation/types';
import AppProviders from './providers';

import reportWebVitals from './reportWebVitals';
import { AppConfigurator } from './system/appConfigurator';

const appConfigurator = AppConfigurator.getInstance();
HttpClient.getInstance().init();
SentryClient.getInstance().init(appConfigurator.getOptions().sentry);

// Если включена аналитика то вызываем конфигуратор
if (appConfigurator.hasFeature(EAppFeature.WebAnalytics)) {
  WebAnalyticsConfigurator.getInstance();
}

moment.locale(locale.short);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <AppProviders>
    <App />
  </AppProviders>
);

reportWebVitals();
export { TradeOfferCreateStepDescription } from '@features/tradeOffer/create/steps/description';
export { TradeOfferCreateStepConditions } from '@features/tradeOffer/create/steps/conditions';
export { TradeOfferCreateStepWidget } from '@features/tradeOffer/create/steps/widget';
export { TradeOfferCreateStepGeneral } from '@features/tradeOffer/create/steps/general';
