import { CorpOfferCategoriesSelectDictionaryAdapter } from './adapters';
import { CorpOfferCategoriesSelectCommonProps } from './types';

export type CorpOfferCategoriesSelectContainerProps = CorpOfferCategoriesSelectCommonProps;

const CorpOfferCategoriesSelectContainer = (props: CorpOfferCategoriesSelectContainerProps) => {
  const { access, maxCount } = props;

  return (
    <CorpOfferCategoriesSelectDictionaryAdapter
      access={access}
      maxCount={maxCount}
    />
  );
};

export default CorpOfferCategoriesSelectContainer;
