import { Redirect, Switch, useParams } from 'react-router';
import { Route } from 'react-router-dom';
import { PartnerShort } from '../../../domain/model/partner';
import { UUID } from '../../../domain/model/types';
import rootRouting from '../../../routing';
import NotFoundScreen from '../../screens/notFound';
import PartnerApplicationDetailsScreen from '../../screens/partnerApplication';
import PartnerApplicationEditScreen from '../../screens/partnerApplicationEdit';
import PartnerApplicationVerificationScreen from '../../screens/partnerApplicationVerification';
import { EPartnerStep } from '../general/partner/types';

const routing = {
  root: `${rootRouting.partnerApplication}`,
  edit: `${rootRouting.partnerApplication}/:id/edit`,
  details: `${rootRouting.partnerApplication}/:id/details`,
  verification: `${rootRouting.partnerApplication}/:id/verification`,
};

export enum EPartnerApplicationUrlParam {
  Step = 'step',
}

interface GetPartnerApplicationEditRouteProps {
  readonly id: UUID;
  readonly step?: EPartnerStep;
}

interface GetPartnerApplicationDetailsRouteProps {
  readonly id: UUID;
  readonly step?: EPartnerStep;
}

interface GetPartnerApplicationVerificationRouteProps {
  readonly id: UUID;
  readonly step?: EPartnerStep;
}

export const getPartnerApplicationEditRoute = ({
  id,
  step = EPartnerStep.General,
}: GetPartnerApplicationEditRouteProps) => {
  const searchParams = new URLSearchParams();

  searchParams.append(EPartnerApplicationUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.edit.replace(':id', id)}${search}`;
};

export const getPartnerApplicationDetailsRoute = ({
  id,
  step = EPartnerStep.General,
}: GetPartnerApplicationDetailsRouteProps) => {
  const searchParams = new URLSearchParams();

  searchParams.append(EPartnerApplicationUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.details.replace(':id', id)}${search}`;
};

export const getPartnerApplicationVerificationRoute = ({
  id,
  step = EPartnerStep.General,
}: GetPartnerApplicationVerificationRouteProps) => {
  const searchParams = new URLSearchParams();

  searchParams.append(EPartnerApplicationUrlParam.Step, step);

  const search = searchParams.toString() ? `?${searchParams}` : '';
  return `${routing.verification.replace(':id', id)}${search}`;
};

interface PartnerApplicationEntryProps {
  readonly partner?: PartnerShort;
}

const PartnerApplicationEntry = ({ partner }: PartnerApplicationEntryProps) => {
  const params = useParams<{ id: UUID }>();
  const id = partner?.id ?? params.id;

  return (
    <Switch>
      <Route
        exact
        path={routing.edit}
        component={PartnerApplicationEditScreen}
      />
      <Route
        exact
        path={routing.details}
        component={PartnerApplicationDetailsScreen}
      />
      <Route
        exact
        path={routing.verification}
        component={PartnerApplicationVerificationScreen}
      />
      <Redirect
        exact
        from={routing.root}
        to={routing.details}
      />
      <Redirect
        exact
        from=''
        to={getPartnerApplicationEditRoute({ id })}
      />

      <Route component={NotFoundScreen} />
    </Switch>
  );
};

export default PartnerApplicationEntry;
