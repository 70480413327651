import { ReactNode, useMemo } from 'react';
import { MpUserShort } from '../../../../../../domain/model/user';
import DataTableCellTextFormat from '../../../../../components/common/table/cell/textFormat';

export interface AdminMpTableCellCommonProps {
  readonly admin: MpUserShort;
}

interface AdminMpTableCellProps extends AdminMpTableCellCommonProps {
  readonly value: ReactNode;
}

const AdminMpTableCell = ({ value }: AdminMpTableCellProps) => {
  return useMemo(
    () => (
      <DataTableCellTextFormat
      >
        {value}
      </DataTableCellTextFormat>
    ),
    [value]
  );
};

export default AdminMpTableCell;