import { UUID } from '@/domain';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customersGuidSelector } from '../store/selectors';
import { customersSetTab, customersStartSession } from '../store/slice';
import { ECustomerTableTab } from '../utils';

interface UseCustomerTableSessionProps {
  readonly guid: UUID;
  readonly tab: ECustomerTableTab;
}

const useCustomerTableSession = (props: UseCustomerTableSessionProps): void => {
  const { guid, tab } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(customersGuidSelector);

  // инициализация сеанса
  useEffect(() => {
    dispatch(customersStartSession({ guid }));
  }, [dispatch, guid]);

  // начальная загрузка, изменение вкладки
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(customersSetTab({ tab }));
    }
  }, [dispatch, guid, currentGuid, tab]);
};

export default useCustomerTableSession;
