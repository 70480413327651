import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CorpOfferTableCellCommonProps } from '../../components/tableCell';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { corpOffersCorpOfferByIndexSelector, corpOffersUnwatchedByIndexSelector } from '../store/selectors';

export type CorpOffersTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<CorpOfferTableCellCommonProps>;
};

const CorpOffersTableCellAdapter = (props: CorpOffersTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const handlers = useContextHandlers();
  const corpOffer = useSelector(corpOffersCorpOfferByIndexSelector(index));
  const isUnwatched = corpOffer ? useSelector(corpOffersUnwatchedByIndexSelector(index)) : false;
  const isDisabled = corpOffer && !isUnwatched ? handlers.isCorpOfferNotModeratedByMe(corpOffer) : false;

  return useMemo(
    () =>
      corpOffer ? (
        <Component
          corpOffer={corpOffer}
          isDisabled={isDisabled}
          isTextAccent={isUnwatched}
        />
      ) : null,
    [Component, corpOffer, isDisabled, isUnwatched]
  );
};

export default CorpOffersTableCellAdapter;
