import ChatBubbleIcon from '@mui/icons-material/ChatBubbleOutline';
import { useSelector } from 'react-redux';
import { ButtonLink } from '../../../../components/common/buttons/link';
import AppSvgIcon from '../../../../components/common/images/icon';
import { TimerIcon } from '../../../../icons';
import { TextSemiBold } from '../../../products/create/controls';
import { HistoryLink } from '../controls';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { useContextParams } from '../hooks/useContextParams';
import { bookingOfferDetailsBookingOfferSelector } from '../store/selectors';
import { StepperButtonWrapper } from './controls';

const BookingOfferDetailsButtonsAdapter = () => {
  const {
    access: { viewHistory },
  } = useContextParams();
  const bookingOffer = useSelector(bookingOfferDetailsBookingOfferSelector);

  const handlers = useContextHandlers();

  const onShowHistory = () => {
    handlers.onChangeDialogState('history', bookingOffer);
  };

  const onShowFeedback = () => {
    handlers.onChangeDialogState('feedback', bookingOffer);
  };

  return (
    <StepperButtonWrapper>
      {viewHistory && (
        <ButtonLink
          variant='text'
          fullWidth={false}
          onClick={onShowHistory}
        >
          <AppSvgIcon
            fontSize='small'
            icon={TimerIcon}
          />
          <HistoryLink>История</HistoryLink>
        </ButtonLink>
      )}
      <ButtonLink
        variant='text'
        fullWidth={false}
        onClick={onShowFeedback}
      >
        <ChatBubbleIcon fontSize={'small'} />
        <TextSemiBold>Форма обратной связи</TextSemiBold>
      </ButtonLink>
    </StepperButtonWrapper>
  );
};

export default BookingOfferDetailsButtonsAdapter;
