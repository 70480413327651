import { getFileDownloadEndpoint } from '../../../../data/api/utils';
import { FileDescription, SportOption } from '../../../../domain/model';
import { Playground } from '../../../../domain/model/playground';
import { TeamShort } from '../../../../domain/model/team';
import { Nullable, UUID } from '../../../../domain/model/types';
import { AppUserSpecific, SportUserProfile, SportUserProfileShort } from '../../../../domain/model/user';
import productStub from '../../../icons/noPhoto.png';
import teamStub from '../../../icons/users.png';
import userStub from '../../../icons/users.png';
import { MPAvatar, MPAvatarSize, MPAvatarVariant } from '../../../theme/ui-kit/avatar';
import { StyledTeamAvatarGroup } from './controls';
import { getAvatarNameAbbreviation } from './utils';
import { EventShort, SportEventShort } from '../../../../domain/model/event';

interface UserAvatarProps {
  readonly user: AppUserSpecific | SportUserProfile | SportUserProfileShort;
  readonly size?: MPAvatarSize;
  readonly stub?: any;
}

export const UserAvatar = ({ user, size, stub = userStub }: UserAvatarProps) => {
  const fullName = [user.lastName, user.firstName].filter(item => !!item).join(' ');
  const url = user.photo ? getFileDownloadEndpoint({ id: user.photo?.path }) : stub;
  const content = !url && getAvatarNameAbbreviation(fullName);
  return (
    <MPAvatar
      size={size}
      description={fullName}
      url={url}
    >
      {content}
    </MPAvatar>
  );
};

interface ProductAvatarProps {
  readonly image?: Nullable<UUID>;
  readonly size?: MPAvatarSize;
  readonly variant?: MPAvatarVariant;
}

export const ProductAvatar = ({ image, size, variant }: ProductAvatarProps) => {
  const url = image ? getFileDownloadEndpoint({ id: image }) : productStub;

  return (
    <MPAvatar
      size={size}
      url={url}
      variant={variant}
    />
  );
};

interface TeamAvatarProps {
  readonly team?: Nullable<TeamShort>;
  readonly size?: MPAvatarSize;
}

export const TeamAvatar = ({ team, size }: TeamAvatarProps) => {
  return (
    <FileDescriptionAvatar
      src={team?.logo?.image}
      autosize={false}
      size={size}
      description={team?.name}
      stub={teamStub}
    />
  );
};

interface ChatAvatarProps {
  readonly subject: SportOption;
  readonly size?: MPAvatarSize;
}

export const ChatAvatar = ({ subject, size }: ChatAvatarProps) => {
  const url = subject.image ? getFileDownloadEndpoint({ id: subject.image }) : teamStub;
  return (
    <MPAvatar
      autosize={false}
      size={size}
      description={subject?.name}
      url={url}
    />
  );
};

interface OptionAvatarProps {
  readonly option?: Nullable<SportOption>;
  readonly size?: MPAvatarSize;
  readonly selected?: boolean;
  readonly autosize?: boolean;
  readonly stub?: any;
}

export const OptionAvatar = ({ option, size, selected = false, autosize = true, stub }: OptionAvatarProps) => {
  const url = option?.image ? getFileDownloadEndpoint({ id: option.image }) : stub;
  const content = !url && getAvatarNameAbbreviation(option?.name || '');
  return (
    <MPAvatar
      size={size}
      description={option?.name}
      selected={selected}
      autosize={autosize}
      url={url}
    >
      {content}
    </MPAvatar>
  );
};

interface FileDescriptionAvatarProps {
  readonly src?: Nullable<FileDescription>;
  readonly description?: Nullable<string>;
  readonly size?: MPAvatarSize;
  readonly selected?: boolean;
  readonly autosize?: boolean;
  readonly stub?: any;
}

export const FileDescriptionAvatar = (props: FileDescriptionAvatarProps) => {
  const { src, description, size, selected = false, autosize = true, stub } = props;

  const url = src ? getFileDownloadEndpoint({ id: src?.path }) : stub;
  const content = !url && getAvatarNameAbbreviation(description ?? '');
  return (
    <MPAvatar
      size={size}
      description={description ?? ''}
      selected={selected}
      autosize={autosize}
      url={url}
    >
      {content}
    </MPAvatar>
  );
};

interface OptionsAvatarGroupProps {
  readonly options: SportOption[];
  readonly size?: MPAvatarSize;
  readonly max?: number;
}

export const OptionsAvatarGroup = ({ options, size, max = 4 }: OptionsAvatarGroupProps) => {
  return (
    <StyledTeamAvatarGroup
      size={size}
      max={max}
    >
      {options.map(option => {
        const url = option.image && getFileDownloadEndpoint({ id: option.image });
        const content = !url && getAvatarNameAbbreviation(option.name || '');

        return (
          <MPAvatar
            key={option.id}
            size={size}
            url={url}
            autosize
          >
            {content}
          </MPAvatar>
        );
      })}
    </StyledTeamAvatarGroup>
  );
};

interface PlaygroundAvatarProps {
  readonly playground: Playground;
}

export const PlaygroundAvatar = ({ playground, ...others }: PlaygroundAvatarProps) => {
  return (
    <FileDescriptionAvatar
      key={playground?.id}
      src={playground.previewImage}
      description={playground?.name}
      {...others}
    />
  );
};

interface EventAvatarProps {
  readonly event: SportEventShort | EventShort;
  readonly size?: MPAvatarSize;
}

export const EventAvatar = ({ event, ...others }: EventAvatarProps) => {
  return (
    <FileDescriptionAvatar
      key={event?.id}
      src={event.previewImage}
      description={event?.name}
      {...others}
    />
  );
};
