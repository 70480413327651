import DiscardCreateWhenExitDialog from '@components/common/dialogs/discardCreateWhenExit';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { clientOrgCreateDialogDiscardSelector } from '../store/selectors';

export const ClientOrgCreateDialogDiscardAdapter = () => {
  const handlers = useContextHandlers();

  const clientOrg = useSelector(clientOrgCreateDialogDiscardSelector);

  const onClose = useCallback(() => {
    handlers.onChangeDialogState('discard', null);
  }, [handlers.onChangeDialogState]);

  if (!clientOrg) {
    return null;
  }

  return (
    <DiscardCreateWhenExitDialog
      open
      onCancel={onClose}
      onDiscard={handlers.onCloseImmediate}
    />
  );
};
