import { useCallback } from 'react';
import { ESortDirection } from '../../../../../domain/model/enums';
import { MpUserShort } from '../../../../../domain/model/user';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../../components/common/table';
import TableActions from '../../../../components/common/table/actions';
import { DataTableLoader } from '../../../../components/common/table/loader';
import AdminMpTableCellEmail from '../components/tableCell/email';
import AdminMpTableCellName from '../components/tableCell/name';
import AdminMpTableCellPhone from '../components/tableCell/phone';
import { AdminMpActions, AdminMpActionTableType } from '../types';
import AdminMpTableCellAdapter from './adapters/cell';
import { Wrapper } from './controls';
import { adminMpsIsFetchingSelector } from './store/selectors';
import { AdminMpActionTableCall, AdminMpTableActionProcess, EAdminMpTableColumn } from './utils';

interface AdminMpTableProps {
  readonly metadata: DataTableMetadata<EAdminMpTableColumn>;
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly admins: MpUserShort[];
  readonly onChangeMetadata: (metadata: DataTableMetadata<EAdminMpTableColumn>) => void;
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onClick: (admin: MpUserShort) => void;
  readonly onAdminMpAction: (call: AdminMpActionTableCall) => void;
  readonly getActions: (admin: MpUserShort) => AdminMpActions<AdminMpActionTableType>;
  readonly getActionProcesses: (admin: MpUserShort) => AdminMpTableActionProcess[];
}

const AdminMpTable = (props: AdminMpTableProps) => {
  const {
    metadata,
    sort,
    admins,
    onChangeMetadata,
    onRequestSort,
    onClick,
    onAdminMpAction,
    getActions,
    getActionProcesses,
  } = props;

  const rows: DataTableRow<MpUserShort, EAdminMpTableColumn>[] = admins?.map((admin, index) => {
    return {
      [EAdminMpTableColumn.Name]: (
        <AdminMpTableCellAdapter
          index={index}
          component={AdminMpTableCellName}
        />
      ),
      [EAdminMpTableColumn.Phone]: (
        <AdminMpTableCellAdapter
          index={index}
          component={AdminMpTableCellPhone}
        />
      ),
      [EAdminMpTableColumn.Email]: (
        <AdminMpTableCellAdapter
          index={index}
          component={AdminMpTableCellEmail}
        />
      ),
      data: admin,
    };
  });

  const onTableCommonAction = useCallback(
    (action: AdminMpActionTableType, admin: MpUserShort) => onAdminMpAction({ action, admin }),
    [onAdminMpAction]
  );

  return (
    <Wrapper>
      <DataTable<MpUserShort, EAdminMpTableColumn>
        metadata={metadata}
        width='auto'
        overflowX='inherit'
        rows={rows}
        sort={{ column: sort.column, direction: sort.direction }}
        loader={<DataTableLoader selector={adminMpsIsFetchingSelector} />}
        rowActions
        hoverModule
        onSort={(event, column, direction) => onRequestSort(column, direction)}
        onRowClick={(event, cell, { data }) => onClick(data)}
        onChangeMetadata={onChangeMetadata}
        getRowActions={admin => (
          <TableActions<MpUserShort, AdminMpActionTableType>
            entityLink={admin}
            actions={getActions(admin)}
            onExecute={onTableCommonAction}
            isExecuting={getActionProcesses(admin).some(process => process.isFetching)}
          />
        )}
      />
    </Wrapper>
  );
};

export default AdminMpTable;
