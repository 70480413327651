import { combineReducers } from '@reduxjs/toolkit';
import create, { AdminMpCreateState } from './create/store/slice';
import list, { AdminMpListState } from './table/store/slice';

export interface AdminMpState {
  list: AdminMpListState;
  create: AdminMpCreateState;
}

export default combineReducers<AdminMpState>({
  list,
  create,
});
