import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import { TradeOfferTableCellPromotionTypeProps } from '../../components/tableCell/promotionType';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { tradeOffersTradeOfferByIndexSelector, tradeOffersUnwatchedByIndexSelector } from '../store/selectors';
import { TradeOffersTableCellAdapterProps } from './cell';

type TradeOffersTableCellPromotionTypeAdapterProps = Omit<TradeOffersTableCellAdapterProps, 'component'> & {
  readonly component: FC<TradeOfferTableCellPromotionTypeProps>;
};

const TradeOffersTableCellPromotionTypeAdapter = (props: TradeOffersTableCellPromotionTypeAdapterProps) => {
  const { index, component: Component } = props;

  const handlers = useContextHandlers();
  const tradeOffer = useSelector(tradeOffersTradeOfferByIndexSelector(index));
  const isUnwatched = tradeOffer ? useSelector(tradeOffersUnwatchedByIndexSelector(index)) : false;
  const isDisabled = tradeOffer && !isUnwatched ? handlers.isTradeOfferNotModeratedByMe(tradeOffer) : false;

  const { promotionTypes } = useSelector(nsiDataSelector);

  return useMemo(
    () =>
      tradeOffer ? (
        <Component
          tradeOffer={tradeOffer}
          tradePromotionTypes={promotionTypes}
          isDisabled={isDisabled}
          isTextAccent={isUnwatched}
        />
      ) : null,
    [Component, tradeOffer, isDisabled, isUnwatched, promotionTypes]
  );
};

export default TradeOffersTableCellPromotionTypeAdapter;
