import { ENoticeStatus, MpUserData } from '@/domain';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notifier from '../../../../system/notifier';
import useValidation from '../../../hooks/validation/useValidation';
import { OnChangeObjectAttribute } from '../../../types';
import UserCreateDialog from '../../user/components/createDialog';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import PartnerSelfRegistrationActions from './actions';
import { partnerSelfRegistrationSaveSelector, partnerSelfRegistrationUserSelector } from './store/selectors';
import {
  partnerSelfRegistrationCreate,
  partnerSelfRegistrationInit,
  partnerSelfRegistrationReset,
  partnerSelfRegistrationSetAttribute,
} from './store/slice';
import { partnerSelfRegistrationValidation } from './utils';

const validationRules = partnerSelfRegistrationValidation;

const PartnerSelfRegistrationContainer = () => {
  const { logOut, refreshToken, refreshUserSpecific, userSpecific } = useCurrentUser();

  const dispatch = useDispatch();

  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);

  const user = useSelector(partnerSelfRegistrationUserSelector);
  const { isFetching: isSaving } = useSelector(partnerSelfRegistrationSaveSelector);

  const { validationResult, isValid, validate } = useValidation<MpUserData>({
    object: user,
    validateOnChange,
    rules: validationRules,
  });

  const onAfterRegister = useCallback(() => {
    refreshToken();
    refreshUserSpecific();
  }, []);

  const onRegister = () => {
    if (user) {
      const isValidObject = validate();

      if (!isValidObject) {
        setValidateOnChange(true);
      } else {
        dispatch(
          partnerSelfRegistrationCreate({
            user,
            partner: userSpecific?.mpPartner?.partner ?? null,
          })
        )
          .unwrap()
          .then(onAfterRegister);
      }
    }
  };

  const onChangeAttribute: OnChangeObjectAttribute<MpUserData> = (name, value) => {
    dispatch(partnerSelfRegistrationSetAttribute({ name, value }));
  };

  useEffect(() => {
    dispatch(partnerSelfRegistrationInit(userSpecific));
    return () => {
      dispatch(partnerSelfRegistrationReset());
    };
  }, [dispatch, userSpecific]);

  useEffect(() => {
    if (validationResult?.email?.hasError) {
      Notifier.getInstance().addNotice(
        ENoticeStatus.Error,
        validationResult.email.message ?? 'Некорректно указан email'
      );
    }
  }, [validationResult?.email]);

  if (!user) {
    return null;
  }

  return (
    <UserCreateDialog
      user={user}
      validation={validationResult}
      validationRules={validationRules}
      isValid={isValid}
      actions={
        <PartnerSelfRegistrationActions
          isFetching={isSaving}
          onSave={onRegister}
        />
      }
      onChangeAttribute={onChangeAttribute}
      onClose={logOut}
    />
  );
};

export default PartnerSelfRegistrationContainer;
