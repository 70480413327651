import { EMultiSelectorValueType } from '@/domain';
import { EValidationType, ValidationRules } from '../../../utils/validation';
import { CmsPreviewTarget, ECmsPreviewTargetAudienceType } from './types';
import { getCmsPreviewTargetAudienceType } from './utils';

type CmsTargetSelectorType =
  | CmsPreviewTarget['roads']
  | CmsPreviewTarget['orgUnits']
  | CmsPreviewTarget['localities']
  | CmsPreviewTarget['clientOrgs']
  | CmsPreviewTarget['gender'];

const isSelectorEmpty = (value: CmsTargetSelectorType) =>
  !value || (value.select === EMultiSelectorValueType.In && !value.in?.length);

export const cmsPreviewTargetValidation: ValidationRules<CmsPreviewTarget> = {
  externalUsers: {
    validator: (object, value) => {
      if (!value && isSelectorEmpty(object.clientOrgs)) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Выберите «Внешние пользователи» или компании',
        };
      }

      return null;
    },
  },
  gender: {
    validator: (object, value) => {
      if (
        getCmsPreviewTargetAudienceType(object.externalUsers, object.clientOrgs) !==
        ECmsPreviewTargetAudienceType.Corporate
      )
        return null;
      if (!value || (value.select === EMultiSelectorValueType.In && !value.in?.length)) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Выберите пол или <<Для всех>>',
        };
      }

      return null;
    },
  },
  localities: {
    validator: (object, value) => {
      if (
        getCmsPreviewTargetAudienceType(object.externalUsers, object.clientOrgs) ===
          ECmsPreviewTargetAudienceType.External &&
        isSelectorEmpty(value)
      ) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Выберите города или <<Вся Россия>>',
        };
      }

      if (
        getCmsPreviewTargetAudienceType(object.externalUsers, object.clientOrgs) ===
          ECmsPreviewTargetAudienceType.Corporate &&
        isSelectorEmpty(value) &&
        isSelectorEmpty(object.roads) &&
        isSelectorEmpty(object.orgUnits)
      ) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Выберите город, дорогу или филиал',
        };
      }

      return null;
    },
  },
  roads: {
    validator: (object, value) => {
      if (
        getCmsPreviewTargetAudienceType(object.externalUsers, object.clientOrgs) !==
        ECmsPreviewTargetAudienceType.Corporate
      )
        return null;

      if (isSelectorEmpty(value) && isSelectorEmpty(object.orgUnits) && isSelectorEmpty(object.localities)) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Выберите город, дорогу или филиал',
        };
      }

      return null;
    },
  },
  orgUnits: {
    validator: (object, value) => {
      if (
        getCmsPreviewTargetAudienceType(object.externalUsers, object.clientOrgs) !==
        ECmsPreviewTargetAudienceType.Corporate
      )
        return null;

      if (isSelectorEmpty(value) && isSelectorEmpty(object.roads) && isSelectorEmpty(object.localities)) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Выберите город, дорогу или филиал',
        };
      }

      return null;
    },
  },
};
