import { EBannerPartition, UUID } from '@/domain';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bannersGuidSelector } from '../store/selectors';
import { bannersSetTab, bannersStartSession } from '../store/slice';
import { EBannerTableTab } from '../utils';

interface UseBannerTableSessionProps {
  readonly guid: UUID;
  readonly tab: EBannerTableTab;
  readonly partition: EBannerPartition;
}

export const useBannerTableSession = (props: UseBannerTableSessionProps) => {
  const { guid, tab, partition } = props;
  const dispatch = useDispatch();

  const currentGuid = useSelector(bannersGuidSelector(partition));

  useEffect(() => {
    if (guid !== currentGuid) {
      dispatch(bannersStartSession({ guid, partition /*, statuses: currentStatuses */ }));
    }
  }, [dispatch, guid, currentGuid, partition]);

  // начальная загрузка, изменение вкладки
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(bannersSetTab({ tab, partition }));
    }
  }, [dispatch, guid, currentGuid, tab, partition]);
};
