import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { UUID } from '../../../../domain/model/types';
import { MenuPanelItem, MenuPanelLinkLocationState } from '../../../types';
import { getMenuItemLocation, menuPanelItemIsSelected } from '../../general/menu/utils';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import { viewShowMainMenu } from '../container/store/slice';
import { EMainMenuType } from './enums';
import { mainMenuGuidSelector, mainMenuOpenedItemsSelector } from './store/selectors';
import { mainMenuAddToOpened } from './store/slice';
import { getCommonMpMenu, getPartnerMenu, getSportMenu } from './utils';

interface UseMainMenu {
  readonly menuType: EMainMenuType;
  readonly guid: UUID;
  readonly items: MenuPanelItem[];
  readonly isSelectedItem: (item: MenuPanelItem) => boolean;
  readonly isOpenedSubMenu: (item: MenuPanelItem) => boolean;
  readonly onItemClick: (item: MenuPanelItem) => void;
}

const useMainMenu = (): UseMainMenu => {
  const location = useLocation();
  const history = useHistory<MenuPanelLinkLocationState>();
  const dispatch = useDispatch();
  const { mpPartnerUserProfile, defaultRoute, accessMatrix } = useCurrentUser();

  const { isPartnerUser, isMpUser, isAdminSport } = accessMatrix;

  const menuType: EMainMenuType = useMemo(() => {
    if (isAdminSport) return EMainMenuType.Sport;
    if (isPartnerUser) return EMainMenuType.Partner;
    return EMainMenuType.MpCommon;
  }, [isPartnerUser, isMpUser, isAdminSport]);

  const opened = useSelector(mainMenuOpenedItemsSelector(menuType));
  const guid = useSelector(mainMenuGuidSelector(menuType));

  const isSelectedItem = useCallback((item: MenuPanelItem) => menuPanelItemIsSelected(item, location), [location]);

  const isSomeSubItemSelected = useCallback(
    (item: MenuPanelItem): boolean => !!item.subMenu?.some(subItem => isSelectedItem(subItem)),
    [isSelectedItem]
  );

  const isOpenedSubMenu = useCallback(
    (item: MenuPanelItem): boolean => opened[item.id] || isSomeSubItemSelected(item),
    [isSomeSubItemSelected, opened]
  );

  const onItemClick = useCallback(
    (item: MenuPanelItem) => {
      if (item.subMenu) {
        dispatch(mainMenuAddToOpened({ menu: menuType, itemId: item.id }));
      } else {
        history.push(getMenuItemLocation(item));
      }
    },
    [dispatch, history, menuType]
  );

  const items = useMemo<MenuPanelItem[]>(() => {
    switch (menuType) {
      case EMainMenuType.Partner:
        return mpPartnerUserProfile.partner?.id ? getPartnerMenu(mpPartnerUserProfile.partner.id, accessMatrix) : [];
      case EMainMenuType.MpCommon:
        return getCommonMpMenu(accessMatrix);
      case EMainMenuType.Sport:
        return getSportMenu(accessMatrix);
    }
  }, [mpPartnerUserProfile?.partner?.id, accessMatrix, menuType]);

  useEffect(() => {
    if (location.pathname.replaceAll('/', '') === defaultRoute.replaceAll('/', '')) {
      dispatch(viewShowMainMenu());
    }
  }, [dispatch, location, defaultRoute]);

  return { items, menuType, guid, isSelectedItem, isOpenedSubMenu, onItemClick };
};

export default useMainMenu;
