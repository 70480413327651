import { ClientOrg } from '@/domain/model/clientOrg';
import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClientOrgActionsConfirmDialog from '../components/confirmDialog';
import { clientOrgActionsDialogBlockSelector, getClientOrgActionsOfferIsProcessingSelector } from '../store/selectors';
import { useClientOrgActions } from '../useActions';

export const ClientOrgActionsDialogBlockAdapter = () => {
  const dispatch = useDispatch();
  const handlers = useClientOrgActions();

  const object = useSelector(clientOrgActionsDialogBlockSelector);

  const isProcessing = useSelector(getClientOrgActionsOfferIsProcessingSelector(object?.id ?? null));

  const onCloseDialog = useCallback(() => handlers.onChangeDialogState('block', null), [handlers.onChangeDialogState]);

  const onBlock = useCallback(
    async (clientOrg: ClientOrg) => {
      await handlers.onBlock(clientOrg.id);
      handlers.onChangeDialogState('block', null);
    },
    [dispatch, handlers.onChangeDialogState]
  );

  return (
    object && (
      <ClientOrgActionsConfirmDialog
        clientOrg={object}
        title={'Приостановить компанию?'}
        text={
          <>
            <Typography>Вы уверены что хотите приостановить компанию «{object.name}»</Typography>
            <Typography>{object.membersCount} пользователей потеряют доступ.</Typography>
          </>
        }
        actionText={'Приостановить'}
        isFetching={isProcessing}
        onClose={onCloseDialog}
        onAction={onBlock}
      />
    )
  );
};
