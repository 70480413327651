import { useContext } from 'react';
import CorpActivationActionsContext, { CorpActivationActionsContextType } from './context';

export const useCorpActivationActions = (): CorpActivationActionsContextType => {
  return useContext<CorpActivationActionsContextType>(CorpActivationActionsContext);
};

export const useCorpActivationActionsSelectors = (): CorpActivationActionsContextType['utils']['selectors'] => {
  return useCorpActivationActions().utils.selectors;
};
