import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ClientOrgCreateStep } from '../../types';
import { clientOrgCreateSetUiState } from '../store/slice';
import { ClientOrgCreateUiState } from '../types';
import { getClientOrgCreateSteps, getClientOrgCreateStepText } from '../utils';

export const ClientOrgCreateUiStepsState = () => {
  const dispatch = useDispatch();

  const value = useMemo<ClientOrgCreateUiState['steps']>(() => {
    const steps: ClientOrgCreateStep[] = getClientOrgCreateSteps().map(key => ({
      key,
      label: getClientOrgCreateStepText(key),
    }));

    return steps;
  }, []);

  useEffect(() => {
    dispatch(clientOrgCreateSetUiState({ name: 'steps', value }));
  }, [dispatch, value]);

  return null;
};
