import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Feedback } from '../../../feedback';
import { useFeedback } from '../../../feedback/useFeedback';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { clientOrgCreateDialogFeedbackSelector } from '../store/selectors';

export const ClientOrgCreateDialogFeedbackAdapter = () => {
  const handlers = useContextHandlers();
  const { onSubmitFeedback } = useFeedback();

  const clientOrg = useSelector(clientOrgCreateDialogFeedbackSelector);

  const onClose = useCallback(() => {
    handlers.onChangeDialogState('feedback', null);
  }, [handlers.onChangeDialogState]);

  if (!clientOrg) {
    return null;
  }

  return (
    <Feedback
      open
      onClose={onClose}
      onSubmit={onSubmitFeedback}
    />
  );
};
