import React from 'react';
import { UseAdminMpTable } from './hooks/useAdminMpTable';
import { UseAdminMpTableConfig } from './hooks/useAdminMpTableConfig';

export type AdminMpTableContextValue = UseAdminMpTable;

export const AdminMpTableContext = React.createContext<AdminMpTableContextValue>({} as AdminMpTableContextValue);

export type AdminMpTableConfigContextValue = UseAdminMpTableConfig;

export const AdminMpTableConfigContext = React.createContext<AdminMpTableConfigContextValue>(
  {} as AdminMpTableConfigContextValue
);
