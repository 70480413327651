import { DataTreeItem } from '../../../../../domain/model';

export function treeFindItem<T>(id: string | null, source: DataTreeItem<T>[]): DataTreeItem<T> | null {
  if (id) {
    const item = source.find(r => r.id === id);
    if (item) {
      return item;
    } else {
      for (const sourceElement of source) {
        if (sourceElement.children) {
          const result = treeFindItem(id, sourceElement.children);
          if (result) {
            return result;
          }
        }
      }
    }
  }
  return null;
}

export function treeFindParentAndPush<T>(id: string | null, acc: DataTreeItem<T>[], source: DataTreeItem<T>[]) {
  if (id) {
    const item = source.find(r => r.id === id);
    if (item) {
      acc.push(item);
      if (item.parentId) {
        treeFindParentAndPush(item.parentId, acc, source);
      }
    }
  }
}
