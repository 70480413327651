import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UUID } from '../../../../../../domain/model/types';
import { cmsContainersGuidSelector } from '../store/selectors';
import { cmsContainersNeedRefreshWatcherReset, cmsContainersSetTab, cmsContainersStartSession } from '../store/slice';
import { ECmsContainerTableTab, getCmsContainerTableStatusesByTab } from '../utils';

interface UseCmsContainerTableSessionProps {
  readonly guid: UUID;
  readonly tab: ECmsContainerTableTab;
}

const useCmsContainerTableSession = (props: UseCmsContainerTableSessionProps): void => {
  const { guid, tab } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(cmsContainersGuidSelector);

  // начало сеанса
  useEffect(() => {
    if (guid !== currentGuid) {
      dispatch(
        cmsContainersStartSession({
          guid,
          statuses: getCmsContainerTableStatusesByTab(tab),
        })
      );
    }
  }, [dispatch, guid, currentGuid, tab]);

  // начальная загрузка, изменение вкладки
  useEffect(() => {
    if (guid === currentGuid) {
      dispatch(cmsContainersSetTab({ tab }));
    }
  }, [dispatch, guid, currentGuid, tab]);

  useEffect(() => {
    return () => {
      dispatch(cmsContainersNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};

export default useCmsContainerTableSession;
