import { EOfferPausedReason, EOfferStatus, Nullable } from '@/domain';
import { EPanelActionPosition } from '../../../../types';
import { OfferLifeCycle } from '../lifecycle/types';
import {
  ActionOfferType,
  EOfferActionType,
  OfferAction,
  OfferActionCreateType,
  OfferActionDetailsType,
  OfferActions,
  OfferActionTableType,
} from '../types';

export const getOfferActionName = (type: EOfferActionType): string => {
  switch (type) {
    case EOfferActionType.Pause:
      return 'Приостановить';
    case EOfferActionType.Archive:
      return 'В архив';
    case EOfferActionType.Delete:
      return 'Удалить';
    case EOfferActionType.Resume:
    case EOfferActionType.Publish:
    case EOfferActionType.Approve:
      return 'Опубликовать';
    case EOfferActionType.Reject:
      return 'Отклонить';
    case EOfferActionType.UnPublish:
      return 'Отозвать';
    case EOfferActionType.ChangeApprovalRegistry:
      return 'Присвоить приказ';
    case EOfferActionType.ChangeModerator:
      return 'Назначить модератора';
    case EOfferActionType.CreateCodes:
      return 'Пополнить базу кодов';
    case EOfferActionType.DeleteCodes:
      return 'Удалить неприсвоенные коды';
    case EOfferActionType.DownloadCodes:
      return 'Скачать базу кодов';
    case EOfferActionType.Edit:
      return 'Редактировать';
    case EOfferActionType.SaveNewVersion:
      return 'Отправить';
    case EOfferActionType.UnPublishAndEdit:
      return 'Отозвать и редактировать';
    case EOfferActionType.InWork:
      return 'Взять в работу';
    case EOfferActionType.ChangeResponsiblePerson:
      return 'Изменить ответственного';
    case EOfferActionType.Duplicate:
      return 'Копировать в черновики';
    case EOfferActionType.Retrieve:
      return 'Вернуть в предыдущий статус';
    case EOfferActionType.ReturnToVerification:
      return 'Вернуть на проверку';
  }
};

interface GetOfferActionsConfigProps<T extends ActionOfferType> {
  readonly offer: T;
  readonly actions: OfferActions;
}

// получение действий с офером с учетом состояния офера
export const getOfferActionsConfig = <T extends ActionOfferType>(
  props: GetOfferActionsConfigProps<T>
): OfferActions => {
  const { offer, actions } = props;

  const internalActions = [...actions];

  const { status, pausedReason } = offer;

  if (status === EOfferStatus.Paused) {
    if (
      pausedReason?.code !== EOfferPausedReason.PausedByPartner &&
      pausedReason?.code !== EOfferPausedReason.PausedAfterCodesAdding
    ) {
      const resumeActionIndex = internalActions.findIndex(action => action.type === EOfferActionType.Resume);
      if (resumeActionIndex !== -1) {
        internalActions[resumeActionIndex] = {
          ...internalActions[resumeActionIndex],
          disabled: true,
        };
      }
    }
  }

  return internalActions;
};

export interface GetOfferActionsByPermissionsProps {
  readonly canArchive?: boolean;
  readonly canResume?: boolean;
  readonly canDuplicate?: boolean;
  readonly canPause?: boolean;
  readonly canDelete?: boolean;
  readonly canPublish?: boolean;
  readonly canUnPublish?: boolean;
  readonly canReject?: boolean;
  readonly canApprove?: boolean;
  readonly canChangeApprovalRegistry?: boolean;
  readonly canChangeModerator?: boolean;
  readonly canEditPromotions?: boolean;
  readonly canEdit?: boolean;
  readonly canModerate?: boolean;
  readonly canEditResponsiblePerson?: boolean;
  readonly canRetrieve?: boolean;
  readonly canReturnToVerification?: boolean;
}

// получение разрешенных действий с оффером с учетом разрешений
export const getOfferActionsConfigByPermissions = (props: GetOfferActionsByPermissionsProps): OfferActions => {
  const {
    canArchive,
    canResume,
    canPause,
    canDelete,
    canPublish,
    canUnPublish,
    canReject,
    canApprove,
    canChangeApprovalRegistry,
    canChangeModerator,
    canEditPromotions,
    canEdit,
    canModerate,
    canEditResponsiblePerson,
    canDuplicate,
    canRetrieve,
    canReturnToVerification,
  } = props;

  const actions: OfferActions = [];

  let onArchive: Nullable<OfferAction> = null;
  let onResume: Nullable<OfferAction> = null;
  let onPause: Nullable<OfferAction> = null;
  let onDuplicate: Nullable<OfferAction> = null;
  let onDelete: Nullable<OfferAction> = null;
  let onPublish: Nullable<OfferAction> = null;
  let onUnPublish: Nullable<OfferAction> = null;
  let onReject: Nullable<OfferAction> = null;
  let onApprove: Nullable<OfferAction> = null;
  let onChangeApprovalRegistry: Nullable<OfferAction> = null;
  let onChangeModerator: Nullable<OfferAction> = null;
  let onCreateCodes: Nullable<OfferAction> = null;
  let onDeleteCodes: Nullable<OfferAction> = null;
  let onDownloadCodes: Nullable<OfferAction> = null;
  let onEdit: Nullable<OfferAction> = null;
  let onSaveNewVersion: Nullable<OfferAction> = null;
  let onUnPublishAndEdit: Nullable<OfferAction> = null;
  let onInWork: Nullable<OfferAction> = null;
  let onChangeResponsiblePerson: Nullable<OfferAction> = null;
  let onRetrieve: Nullable<OfferAction> = null;
  let onReturnToVerification: Nullable<OfferAction> = null;

  if (canChangeApprovalRegistry) {
    onChangeApprovalRegistry = {
      type: EOfferActionType.ChangeApprovalRegistry,
      disabled: false,
      label: getOfferActionName(EOfferActionType.ChangeApprovalRegistry),

      position: [EPanelActionPosition.Default],
    };
    actions.push(onChangeApprovalRegistry);
  }
  if (canPublish) {
    onPublish = {
      type: EOfferActionType.Publish,
      disabled: false,
      label: getOfferActionName(EOfferActionType.Publish),

      position: [EPanelActionPosition.Default],
    };
    actions.push(onPublish);
  }
  if (canUnPublish) {
    onUnPublish = {
      type: EOfferActionType.UnPublish,
      disabled: false,
      label: getOfferActionName(EOfferActionType.UnPublish),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onUnPublish);

    onUnPublishAndEdit = {
      type: EOfferActionType.UnPublishAndEdit,
      disabled: false,
      label: getOfferActionName(EOfferActionType.UnPublishAndEdit),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onUnPublishAndEdit);
  }
  if (canResume) {
    onResume = {
      type: EOfferActionType.Resume,
      disabled: false,
      label: getOfferActionName(EOfferActionType.Resume),

      position: [EPanelActionPosition.Default],
    };
    actions.push(onResume);
  }
  if (canApprove) {
    onApprove = {
      type: EOfferActionType.Approve,
      disabled: false,
      label: getOfferActionName(EOfferActionType.Approve),

      position: [EPanelActionPosition.Default],
    };
    actions.push(onApprove);
  }
  if (canChangeModerator) {
    onChangeModerator = {
      type: EOfferActionType.ChangeModerator,
      disabled: false,
      label: getOfferActionName(EOfferActionType.ChangeModerator),

      position: [EPanelActionPosition.Default],
    };
    actions.push(onChangeModerator);
  }
  if (canReject) {
    onReject = {
      type: EOfferActionType.Reject,
      disabled: false,
      label: getOfferActionName(EOfferActionType.Reject),

      position: [EPanelActionPosition.Default],
    };
    actions.push(onReject);
  }
  if (canPause) {
    onPause = {
      type: EOfferActionType.Pause,
      disabled: false,
      label: getOfferActionName(EOfferActionType.Pause),

      position: [EPanelActionPosition.Default],
    };
    actions.push(onPause);
  }
  if (canDuplicate) {
    onDuplicate = {
      type: EOfferActionType.Duplicate,
      disabled: false,
      label: getOfferActionName(EOfferActionType.Duplicate),

      position: [EPanelActionPosition.Default],
    };
    actions.push(onDuplicate);
  }
  if (canArchive) {
    onArchive = {
      type: EOfferActionType.Archive,
      disabled: false,
      label: getOfferActionName(EOfferActionType.Archive),

      position: [EPanelActionPosition.Default],
    };
    actions.push(onArchive);
  }
  if (canDelete) {
    onDelete = {
      type: EOfferActionType.Delete,
      disabled: false,
      label: getOfferActionName(EOfferActionType.Delete),

      position: [EPanelActionPosition.Default],
    };
    actions.push(onDelete);
  }
  if (canEdit) {
    onSaveNewVersion = {
      type: EOfferActionType.SaveNewVersion,
      disabled: false,
      label: getOfferActionName(EOfferActionType.SaveNewVersion),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onSaveNewVersion);
    onEdit = {
      type: EOfferActionType.Edit,
      disabled: false,
      label: getOfferActionName(EOfferActionType.Edit),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onEdit);
  }
  if (canEditPromotions) {
    onCreateCodes = {
      type: EOfferActionType.CreateCodes,
      disabled: false,
      label: getOfferActionName(EOfferActionType.CreateCodes),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onCreateCodes);

    onDeleteCodes = {
      type: EOfferActionType.DeleteCodes,
      disabled: false,
      label: getOfferActionName(EOfferActionType.DeleteCodes),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onDeleteCodes);
  }

  onDownloadCodes = {
    type: EOfferActionType.DownloadCodes,
    disabled: false,
    label: getOfferActionName(EOfferActionType.DownloadCodes),
    position: [EPanelActionPosition.Menu],
  };
  actions.push(onDownloadCodes);

  if (canModerate) {
    onInWork = {
      type: EOfferActionType.InWork,
      disabled: false,
      label: getOfferActionName(EOfferActionType.InWork),
      position: [EPanelActionPosition.Header],
    };
    actions.push(onInWork);
  }
  if (canEditResponsiblePerson) {
    onChangeResponsiblePerson = {
      type: EOfferActionType.ChangeResponsiblePerson,
      disabled: false,
      label: getOfferActionName(EOfferActionType.ChangeResponsiblePerson),
      position: [EPanelActionPosition.Content],
    };
    actions.push(onChangeResponsiblePerson);
  }
  if (canRetrieve) {
    onRetrieve = {
      type: EOfferActionType.Retrieve,
      disabled: false,
      label: getOfferActionName(EOfferActionType.Retrieve),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onRetrieve);
  }

  if (canReturnToVerification) {
    onReturnToVerification = {
      type: EOfferActionType.ReturnToVerification,
      disabled: false,
      label: getOfferActionName(EOfferActionType.ReturnToVerification),
      position: [EPanelActionPosition.Default],
    };
    actions.push(onReturnToVerification);
  }
  return actions;
};

export const removeOfferAction = (actions: OfferActions, type: EOfferActionType) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions.splice(index, 1);
  }
};

export const disableOfferAction = (actions: OfferActions, type: EOfferActionType) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      disabled: true,
    };
  }
};

export const renameOfferAction = (actions: OfferActions, type: EOfferActionType, label: string) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      label,
    };
  }
};

export const makeOfferActionPrimary = (actions: OfferActions<any>, types: EOfferActionType[]) => {
  return actions.map(action => ({
    ...action,
    primary: types.includes(action.type) ? true : action.primary,
  }));
};

export const changeOfferActionPosition = (
  actions: OfferActions<any>,
  type: EOfferActionType,
  position: EPanelActionPosition[]
) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions[index] = {
      ...actions[index],
      position,
    };
  }
};

export const isOfferActionEnabled = (actions: OfferActions<any>, type: EOfferActionType) => {
  return actions.some(action => action.type === type && !action.disabled);
};

// фильтрация действий для создания офферов по всем действиям
export const filterOfferCreateActions = (actions: OfferActions): OfferActions<OfferActionCreateType> => {
  const createActions: OfferActions<OfferActionCreateType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EOfferActionType.Publish:
      case EOfferActionType.Resume:
      case EOfferActionType.CreateCodes:
      case EOfferActionType.DeleteCodes:
      case EOfferActionType.DownloadCodes:
      case EOfferActionType.ChangeResponsiblePerson:
      case EOfferActionType.Edit:
      case EOfferActionType.SaveNewVersion:
      case EOfferActionType.ChangeModerator:
      case EOfferActionType.Delete:
        createActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          primary: action.primary,
          position: action.position,
        });
        break;
      case EOfferActionType.Duplicate:
      case EOfferActionType.Archive:
      case EOfferActionType.Pause:
      case EOfferActionType.Reject:
      case EOfferActionType.Approve:
      case EOfferActionType.UnPublish:
      case EOfferActionType.ChangeApprovalRegistry:
      case EOfferActionType.UnPublishAndEdit:
      case EOfferActionType.InWork:
      case EOfferActionType.ReturnToVerification:
      case EOfferActionType.Retrieve:
        break;
    }
  });

  return createActions;
};

/**
 * Фильтрация действий на странице Details
 * @param actions - Массив событий
 */
export const filterOfferDetailsActions = (actions: OfferActions): OfferActions<OfferActionDetailsType> => {
  const detailsActions: OfferActions<OfferActionDetailsType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EOfferActionType.Resume:
      case EOfferActionType.Archive:
      case EOfferActionType.Reject:
      case EOfferActionType.Approve:
      case EOfferActionType.Pause:
      case EOfferActionType.UnPublish:
      case EOfferActionType.CreateCodes:
      case EOfferActionType.DeleteCodes:
      case EOfferActionType.DownloadCodes:
      case EOfferActionType.Edit:
      case EOfferActionType.UnPublishAndEdit:
      case EOfferActionType.InWork:
      case EOfferActionType.ChangeResponsiblePerson:
      case EOfferActionType.Duplicate:
      case EOfferActionType.Retrieve:
      case EOfferActionType.ReturnToVerification:
      case EOfferActionType.ChangeModerator:
        detailsActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          primary: action.primary,
          position: action.position,
        });
        break;
      case EOfferActionType.SaveNewVersion:
      case EOfferActionType.Publish:
      case EOfferActionType.Delete:
      case EOfferActionType.ChangeApprovalRegistry:
        break;
    }
  });

  return detailsActions;
};

/**
 * Функция для получения действия в таблице трех точек
 *
 * @param {OfferLifeCycle} lifecycle - Жизненный цикл
 * @param offer - Объект офера CorpOffer или TradeOffer
 * @param allowedActions - Массив объектов событий
 */
export const getOfferTableActions = <T extends ActionOfferType>(
  lifecycle: OfferLifeCycle<T>,
  offer: T,
  allowedActions: OfferActions<OfferActionTableType>
) => {
  return lifecycle.build({ obj: offer, allowedActions }).nextActions as OfferActions<OfferActionTableType>;
};
