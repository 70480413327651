import { UUID } from '@/domain/model/types';
import { Location } from 'history';
import { Suspense } from 'react';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import rootRouting from '../../../routing';
import { ContentLoaderSimple } from '../../components/common/loader';
import CorpActivationsScreen from '../../screens/corpActivations';
import NotFoundScreen from '../../screens/notFound';
import { ECorpActivationTableTab } from './types';

export enum ECorpActivationUrlParam {
  Tab = 'tab',
  Step = 'step',
}

const routing = {
  root: `${rootRouting.corpActivations}`,
  list: `${rootRouting.corpActivations}`,
};

interface GetActivationsTableRouteProps {
  readonly tab?: ECorpActivationTableTab;
  readonly guid?: UUID;
}

export interface CorpActivationsLocationState {
  readonly guid: UUID;
}

export const getCorpActivationTableRoute = (
  props: GetActivationsTableRouteProps
): Location<CorpActivationsLocationState> => {
  const { guid, tab } = props;

  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.append(ECorpActivationUrlParam.Tab, tab);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: routing.list,
    search,
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

const CorpActivationsEntry = () => {
  return (
    <Suspense fallback={<ContentLoaderSimple />}>
      <Switch>
        <Route
          exact
          path={routing.list}
          component={CorpActivationsScreen}
        />
        <Redirect
          exact
          from={routing.root}
          to={routing.list}
        />
        <Route component={NotFoundScreen} />
      </Switch>
    </Suspense>
  );
};

export default CorpActivationsEntry;
