import { Nullable, PartnerContactData, PartnerDesk } from '@/domain';
import { OnChangeObjectAttribute, OnChangeObjectAttributeByIndex } from '../../../types';
import { ValidationResult } from '../../../utils/validation';
import { Wrapper } from './controls';
import PartnerDeskEditStepDesk from './steps/desk';
import { EPartnerDeskEditStep } from './utils';

interface PartnerDeskEditProps {
  readonly step: EPartnerDeskEditStep;
  readonly desk: PartnerDesk;
  readonly validationDesk: Nullable<ValidationResult<PartnerDesk>>;
  readonly validationContacts: Nullable<ValidationResult<Nullable<PartnerContactData>>[]>;
  readonly isValidDesk: boolean;
  readonly isValidContacts: boolean;
  readonly onChangeAttribute: OnChangeObjectAttribute<PartnerDesk>;
  readonly onChangeContactAttribute: OnChangeObjectAttributeByIndex<PartnerContactData>;
  readonly onNextStep: () => void;
  readonly onAddContact: () => void;
  readonly onRemoveContact: (index: number) => void;
}

const PartnerDeskEdit = (props: PartnerDeskEditProps) => {
  const {
    step,
    desk,
    validationDesk,
    validationContacts,
    isValidDesk,
    isValidContacts,
    onChangeAttribute,
    onChangeContactAttribute,
    onAddContact,
    onRemoveContact,
  } = props;

  return (
    <Wrapper>
      {step === EPartnerDeskEditStep.Desk && (
        <PartnerDeskEditStepDesk
          desk={desk}
          validation={validationDesk}
          validationContacts={validationContacts}
          isValidDesk={isValidDesk}
          isValidContacts={isValidContacts}
          onChangeAttribute={onChangeAttribute}
          onChangeContactAttribute={onChangeContactAttribute}
          onAddContact={onAddContact}
          onRemoveContact={onRemoveContact}
        />
      )}
    </Wrapper>
  );
};

export default PartnerDeskEdit;
