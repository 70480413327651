import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import TabsPanel from '../../../../components/common/tabs/panel';
import { OfferTableTab } from '../../../general/offer/components/table/tab';
import { EOfferTableTab } from '../../../general/offer/types';
import { getOffersTableTabName } from '../../../general/offer/utils/table';
import { useContextConfig } from '../hooks/useContextConfig';
import { bookingOfferSearchTabsCounterSelector } from '../store/selectors';

interface BookingOfferSearchTabsAdapterProps {
  readonly tab: EOfferTableTab;
}

export const BookingOfferSearchTabsAdapter = (props: BookingOfferSearchTabsAdapterProps) => {
  const { tab } = props;

  const { tabs, onChangeTab } = useContextConfig();

  const counter = useSelector(bookingOfferSearchTabsCounterSelector);

  const getTabName = useCallback((tab: EOfferTableTab) => getOffersTableTabName(tab), []);

  return useMemo(
    () => (
      <TabsPanel
        tab={tab}
        tabs={tabs}
        labelAdapter={OfferTableTab}
        data={{
          counts: counter,
          unwatchedCounts: {},
          getTabName,
        }}
        onChangeTab={onChangeTab}
      />
    ),
    [tab, counter, getTabName, onChangeTab]
  );
};
