import { FC } from 'react';
import ForbiddenComponent from '../../../../components/common/forbidden';
import { MasterTopPushLayout } from '../../../../layouts/masterTopPush/container';
import { CommonPreloaderDecorator } from '../../../general/decorators/preloader';
import { CommonUpdaterDecorator } from '../../../general/decorators/updater';
import MasterDetailsContainer from '../../../general/master/details/container';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { CorpOfferCategoriesSelectFooterAdapter } from './adapters';
import CorpOfferCategoriesSelectContainer, { CorpOfferCategoriesSelectContainerProps } from './container';
import { TopPushContentWrapper } from './controls';
import { CorpOfferCategoriesSelectInitializer } from './initializer';
import { CorpOfferCategoriesSelectHandlersProvider } from './providers';
import {
  corpOfferCategoriesSelectIsLoadingSelector,
  corpOfferCategoriesSelectIsPreloadingSelector,
} from './store/selectors';
import { CorpOfferCategoriesSelectAccess } from './types';

type CorpOfferCategoriesSelectTopPushContainerProps = Omit<CorpOfferCategoriesSelectContainerProps, 'access'> & {
  readonly open?: boolean;
};

export const CorpOfferCategoriesSelectTopPushContainer: FC<CorpOfferCategoriesSelectTopPushContainerProps> = props => {
  const { selected, open, onlyUsed, statuses, partnerId, maxCount, onClose, onEdit } = props;

  const { corpCategories } = useCurrentUser().accessMatrix;
  const access: CorpOfferCategoriesSelectAccess = {
    canCreate: corpCategories.create,
    canEdit: corpCategories.edit,
    canDelete: corpCategories.delete,
  };

  if (!corpCategories.view) {
    return <ForbiddenComponent />;
  }

  return (
    <MasterDetailsContainer
      layout={MasterTopPushLayout}
      layoutProps={{
        title: 'Выбор категории',
        open,
        onClose: onClose ? () => onClose?.() : undefined, //такая штука, чтобы не ловить первый параметр от штатного onClose диалога
        footer: CorpOfferCategoriesSelectFooterAdapter,
        decorators: [
          <CommonPreloaderDecorator
            key='preloader'
            isFetchingSelector={corpOfferCategoriesSelectIsPreloadingSelector}
          />,
          <CommonUpdaterDecorator
            key='updater'
            isFetchingSelector={corpOfferCategoriesSelectIsLoadingSelector}
          />,
        ],
      }}
      initializer={CorpOfferCategoriesSelectInitializer}
      providers={[
        {
          name: 'handlers',
          factory: CorpOfferCategoriesSelectHandlersProvider,
        },
      ]}
      commonProps={{
        open,
        selected,
        access,
        onlyUsed,
        statuses,
        partnerId,
        maxCount,
        onClose,
        onEdit,
      }}
    >
      <TopPushContentWrapper>
        <CorpOfferCategoriesSelectContainer
          {...props}
          access={access}
        />
      </TopPushContentWrapper>
    </MasterDetailsContainer>
  );
};
