import { combineReducers } from '@reduxjs/toolkit';
import byId, { EventByIdState } from './byId/store/slice';
import eventCreate, { ActivityEventCreateState } from './create/activity/store/slice';
import friendlyMatchCreate, { FriendlyMatchCreateState } from './create/friendlyMatch/store/slice';
import sportsFestCreate, { SportsFestCreateState } from './create/sportsFest/store/slice';
import createSummary, { EventCreateSummaryState } from './create/store/slice';
import tournamentCreate, { TournamentCreateState } from './create/tournament/store/slice';
import tournamentIndividualCreate, { TournamentIndividualCreateState } from './create/tournamentIndividual/store/slice';
import activityEventView, { ActivityEventViewState } from './details/activity/store/slice';
import friendlyMatchView, { FriendlyMatchViewState } from './details/friendlyMatch/store/slice';
import sportsFestView, { SportsFestViewState } from './details/sportsFest/store/slice';
import tournamentView, { TournamentViewState } from './details/tournament/store/slice';
import tournamentIndividualView, { TournamentIndividualViewState } from './details/tournamentIndividual/store/slice';
import edit, { EventEditState } from './edit/legacy/store/slice';
import list, { EventListState } from './list/store/slice';
import table, { EventTableState } from './table/store/slice';

interface EventState {
  byId: EventByIdState;
  table: EventTableState;
  list: EventListState;
  tournamentView: TournamentViewState;
  tournamentIndividualView: TournamentIndividualViewState;
  sportsFestView: SportsFestViewState;
  friendlyMatchView: FriendlyMatchViewState;
  activityEventView: ActivityEventViewState;
  createSummary: EventCreateSummaryState;
  tournamentCreate: TournamentCreateState;
  tournamentIndividualCreate: TournamentIndividualCreateState;
  sportsFestCreate: SportsFestCreateState;
  friendlyMatchCreate: FriendlyMatchCreateState;
  activityEventCreate: ActivityEventCreateState;
  edit: EventEditState;
}

export default combineReducers<EventState>({
  byId,
  table,
  list,
  tournamentView,
  tournamentIndividualView,
  sportsFestView,
  friendlyMatchView,
  activityEventView,
  createSummary,
  tournamentCreate,
  tournamentIndividualCreate,
  sportsFestCreate,
  friendlyMatchCreate,
  activityEventCreate: eventCreate,
  edit,
});
