import { ClientOrg } from '@/domain';
import { Chip } from '@mui/material';
import { MPAutocompleteMultipleSelect } from '@ui-kit/autocomplete/multiple';
import React from 'react';
import OrgStructureClientOrgMultipleItem from './item';
import {
  OrgStructureClientOrgMultipleItemType,
  OrgStructureClientOrgMultipleSelectType,
  OrgStructureClientOrgMultipleTypesSelectorItem,
} from './types';
import useOrgStructureClientOrgMultipleTypes from './useMultipleTypes';

interface OrgStructureClientOrgMultipleTypesSelectorProps {
  readonly selectNone?: boolean;
  readonly select?: Nullable<OrgStructureClientOrgMultipleSelectType>;
  readonly clientOrgs: ClientOrg[];
  readonly label: string;
  readonly helperText?: string;
  readonly error?: boolean;
  readonly limitTags?: number;
  readonly disabled?: boolean;
  readonly isLoading?: boolean;
  readonly color?: 'default' | 'primary' | 'secondary' | undefined;
  readonly popupIcon?: React.ReactNode;
  readonly onChange: (clientOrgs: ClientOrg[]) => void;
  readonly onSelect: (type: OrgStructureClientOrgMultipleSelectType) => void;
}

/**
 * Компонент мультисеклектора компаний
 *
 * @example
 *       <OrgStructureClientOrgMultipleTypesSelector
 *         selectAll={selectAllClientOrgs}
 *         clientOrgs={value}
 *         label='Компания'
 *         limitTags={2}
 *         helperText={validation?.[attribute]?.message}
 *         error={!!validation?.[attribute]?.hasError}
 *         popupIcon={<ExpandMoreIcon fontSize='small' />}
 *         onChange={onChange}
 *         onSelectAll={onSelectAllClientOrgs}
 *       />
 */

const OrgStructureClientOrgMultipleTypesSelector = (props: OrgStructureClientOrgMultipleTypesSelectorProps) => {
  const {
    select,
    selectNone,
    clientOrgs,
    label,
    helperText,
    error,
    limitTags = -1,
    disabled = false,
    color = 'default',
    popupIcon,
    isLoading: isInitialLoading,
    onSelect,
    onChange,
  } = props;

  const { options, values, isLoading, setSearchValue, onChangeValue, getOptionLabel } =
    useOrgStructureClientOrgMultipleTypes({
      select,
      selectNone,
      clientOrgs,
      onSelect,
      onChange,
    });

  return (
    <MPAutocompleteMultipleSelect<OrgStructureClientOrgMultipleTypesSelectorItem>
      value={values}
      label={label}
      helperText={helperText}
      error={error}
      options={options}
      limitTags={limitTags}
      isLoading={isLoading || isInitialLoading}
      disabled={disabled}
      disableCloseOnSelect
      onSearchValue={setSearchValue}
      getOptionLabel={getOptionLabel}
      onChangeValue={onChangeValue}
      renderOption={(elementProps, option, state) => (
        <OrgStructureClientOrgMultipleItem
          key={option.id}
          elementProps={elementProps}
          option={option}
          state={state}
        />
      )}
      renderTags={(tagValue, getTagProps) => {
        const allClientOrgsSelected = tagValue.find(
          option => option.type === OrgStructureClientOrgMultipleItemType.All
        );
        return tagValue
          .filter(option => !allClientOrgsSelected || option.type === OrgStructureClientOrgMultipleItemType.All)
          .map((option, index) => (
            <Chip
              component={'div' as any}
              color={color}
              selected
              label={option.name}
              {...getTagProps({ index })}
              key={option.id}
            />
          ));
      }}
      popupIcon={popupIcon}
    />
  );
};

export default OrgStructureClientOrgMultipleTypesSelector;
