import React from 'react';
import { UseBookingOffersTable } from './hooks/useBookingOffersTable';
import { UseBookingOffersTableConfig } from './hooks/useBookingOffersTableConfig';

export type BookingOffersTableContextValue = UseBookingOffersTable;

export const BookingOffersTableContext = React.createContext<BookingOffersTableContextValue>(
  {} as BookingOffersTableContextValue
);

export type BookingOffersTableConfigContextValue = UseBookingOffersTableConfig;

export const BookingOffersTableConfigContext = React.createContext<BookingOffersTableConfigContextValue>(
  {} as BookingOffersTableConfigContextValue
);
