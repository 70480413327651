import { Drawer as MuiDrawer } from '@mui/material';
import styled from '@emotion/styled';

export const Drawer = styled(MuiDrawer)`
  > .MuiPaper-root {
    height: 100%;
    max-height: 100%;

    display: flex;
    flex-direction: column;
    overflow: auto;

    background-color: ${p => p.theme.palette.grey['800']};
  }
`;
