import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Nullable } from '../../../../domain/model/types';
import { LocationWithRequiredStateType } from './types';

type UseLocationWithRequiredStateProps<T extends Partial<LocationWithRequiredStateType>> = T;

interface UseLocationWithRequiredState<T> {
  readonly ready: boolean;
  readonly state: Nullable<T>;
}

function useLocationWithRequiredState<T extends LocationWithRequiredStateType>(
  props: UseLocationWithRequiredStateProps<T>
): UseLocationWithRequiredState<T> {
  const history = useHistory();
  const location = useLocation<Nullable<Partial<T>>>();

  const hasNotFilledAttribute = useMemo<boolean>(() => {
    const state = location.state;
    return state ? Object.keys(state).some(key => !state[key]) : true;
  }, [location]);

  useEffect(() => {
    if (hasNotFilledAttribute) {
      history.replace({
        ...location,
        state: props,
      });
    }
  }, [hasNotFilledAttribute]);

  return {
    ready: !hasNotFilledAttribute,
    state: location.state as Nullable<T>,
  };
}

export default useLocationWithRequiredState;
