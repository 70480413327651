import { FCC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ClientOrgDetailsContainerProps } from './container';
import { clientOrgDetailsByIdFetch, clientOrgDetailsStateReset } from './store/slice';

type ClientOrgDetailsInitializerProps = ClientOrgDetailsContainerProps;

export const ClientOrgDetailsInitializer: FCC<ClientOrgDetailsInitializerProps> = ({ id, children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const promise = dispatch(clientOrgDetailsByIdFetch({ id }));

    return () => {
      dispatch(clientOrgDetailsStateReset());
      promise?.abort();
    };
  }, [dispatch, id]);

  return <>{children}</>;
};
