import { EOfferStatus, ESortDirection, Nullable, TradeOffer } from '@/domain';
import { addSearchParamToLocation } from '@/routing/globalRouting';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../data/store/store';
import { PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import { TradeOfferLifeCycle } from '../../../general/offer/lifecycle/types';
import { EOfferTableTab } from '../../../general/offer/types';
import { ETradeOfferUrlParam } from '../../entry';
import { TradeOfferTableFilterEditStrategy } from '../../filterUtils';
import { tradeOffersGuidSelector } from '../store/selectors';
import {
  tradeOffersClearNotifierInfo,
  tradeOffersDataReset,
  tradeOffersNeedRefreshWatcherIncrement,
  tradeOffersSetFilter,
  tradeOffersSetPage,
  tradeOffersSetPageSize,
  tradeOffersSetSort,
  tradeOffersSortReset,
} from '../store/slice';
import { useTradeOfferActions } from '@features/tradeOffer/actions/useActions';

export type UseTradeOffersTableProps = {
  readonly tab: EOfferTableTab;
  readonly lifecycle: TradeOfferLifeCycle;
  readonly canModerate?: boolean;
};

export type UseTradeOffersTable = {
  readonly onRefresh: () => void;
  readonly onChangeTab: (newTab: EOfferTableTab) => void;
  readonly onChangeFilter: (strategies: TradeOfferTableFilterEditStrategy[]) => void;
  readonly onNotificationRead: () => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
  readonly onResetSort: () => void;
  readonly onTradeOfferResume: (tradeOffer: TradeOffer) => Promise<Nullable<TradeOffer>>;
  readonly onTradeOfferDuplicate: (tradeOffer: TradeOffer) => Promise<Nullable<TradeOffer>>;

  readonly isTradeOfferNotModeratedByMe: (tradeOffer: TradeOffer) => boolean;
  readonly onTradeOfferTryReject: (tradeOffer: TradeOffer) => void;
  readonly onTradeOfferTryPause: (tradeOffer: TradeOffer) => void;
  readonly onTradeOfferTryArchive: (tradeOffer: TradeOffer) => void;
  readonly onTradeOfferTryDelete: (tradeOffer: TradeOffer) => void;
  readonly onTradeOfferTryApprove: (tradeOffer: TradeOffer) => void;
  readonly onTradeOfferTryRetrieve: (tradeOffer: TradeOffer) => void;
  readonly onTradeOffersChangePage: (page: number) => void;
  readonly onTradeOffersChangePageSize: (pageSize: PaginationSize) => void;
  readonly onTradeOfferTryChangeModerator: (offer: TradeOffer) => void;
  readonly onTradeOfferReturnToVerification: (offer: TradeOffer) => Promise<Nullable<TradeOffer>>;
};

export function useTradeOffersTableHandlers(props: UseTradeOffersTableProps): UseTradeOffersTable {
  const { tab, lifecycle, canModerate } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const {
    onTryPause,
    onResume,
    onDuplicate,
    onReturnToVerification,
    onTryReject,
    onTryArchive,
    onTryRetrieve,
    onTryApprove,
    onTryChangeModerator,
    onTryDelete,
  } = useTradeOfferActions();

  const onRefresh = useCallback(() => dispatch(tradeOffersNeedRefreshWatcherIncrement()), [dispatch]);

  const getTradeOffersGuidFromState = useCallback((): Nullable<UUID> => {
    const state = store.getState();
    return tradeOffersGuidSelector(state);
  }, [store]);

  const onTradeOfferTryReject = useCallback<UseTradeOffersTable['onTradeOfferTryReject']>(
    tradeOffer => {
      return onTryReject({ data: tradeOffer });
    },
    [onTryReject]
  );

  const onTradeOfferTryPause = useCallback<UseTradeOffersTable['onTradeOfferTryPause']>(
    tradeOffer => {
      return onTryPause({ data: tradeOffer });
    },
    [onTryPause]
  );

  const onTradeOfferTryArchive = useCallback<UseTradeOffersTable['onTradeOfferTryArchive']>(
    tradeOffer => {
      return onTryArchive({ data: tradeOffer });
    },
    [onTryArchive]
  );

  const onTradeOfferTryChangeModerator = useCallback<UseTradeOffersTable['onTradeOfferTryChangeModerator']>(
    tradeOffer => {
      return onTryChangeModerator({ data: tradeOffer });
    },
    [onTryChangeModerator]
  );

  const onTradeOfferTryApprove = useCallback<UseTradeOffersTable['onTradeOfferTryApprove']>(
    tradeOffer => {
      return onTryApprove({ data: tradeOffer });
    },
    [onTryApprove]
  );

  const onTradeOfferTryDelete = useCallback<UseTradeOffersTable['onTradeOfferTryDelete']>(
    tradeOffer => {
      return onTryDelete({ data: tradeOffer });
    },
    [onTryDelete]
  );

  const onTradeOfferResume = useCallback(onResume, [onResume]);

  const onTradeOfferTryRetrieve = useCallback<UseTradeOffersTable['onTradeOfferTryRetrieve']>(
    tradeOffer => {
      return onTryRetrieve({ data: tradeOffer });
    },
    [onTryRetrieve]
  );

  const onTradeOfferDuplicate = useCallback(onDuplicate, [onDuplicate]);

  const onTradeOfferReturnToVerification = useCallback(onReturnToVerification, [onReturnToVerification]);

  const onResetSort = useCallback(() => dispatch(tradeOffersSortReset()), [dispatch]);

  const onChangeFilter = useCallback(
    (strategies: TradeOfferTableFilterEditStrategy[]) => {
      dispatch(tradeOffersSetFilter(getDataFilterValuesByStrategies(strategies)));
    },
    [dispatch]
  );

  const onNotificationRead = useCallback(() => {
    dispatch(tradeOffersClearNotifierInfo());
  }, [dispatch]);

  const onChangeSort = useCallback(
    (name: string, direction: ESortDirection) => {
      dispatch(
        tradeOffersSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const isTradeOfferNotModeratedByMe = useCallback(
    (tradeOffer: TradeOffer) => {
      return (
        !!canModerate &&
        tradeOffer.status === EOfferStatus.OnModeration &&
        tradeOffer.approvingAdmin?.id !== lifecycle.userId
      );
    },
    [lifecycle.userId, canModerate]
  );

  const onChangeTab = useCallback(
    (newTab: EOfferTableTab) => {
      if (newTab !== tab) {
        const guid = getTradeOffersGuidFromState();
        dispatch(tradeOffersDataReset());
        history.replace(
          addSearchParamToLocation({
            location,
            param: ETradeOfferUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [dispatch, history, location, tab, getTradeOffersGuidFromState]
  );

  const onTradeOffersChangePage = useCallback(
    (page: number) => {
      dispatch(tradeOffersSetPage({ pageNumber: page }));
    },
    [dispatch]
  );

  const onTradeOffersChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(tradeOffersSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  return {
    onRefresh,

    onChangeTab,
    onChangeFilter,
    onNotificationRead,
    onChangeSort,
    onResetSort,
    isTradeOfferNotModeratedByMe,
    onTradeOfferTryReject,
    onTradeOfferTryPause,
    onTradeOfferTryArchive,
    onTradeOfferTryDelete,
    onTradeOfferResume,
    onTradeOfferTryApprove,
    onTradeOfferTryRetrieve,
    onTradeOffersChangePage,
    onTradeOffersChangePageSize,
    onTradeOfferDuplicate,
    onTradeOfferTryChangeModerator,
    onTradeOfferReturnToVerification,
  };
}
