import { Nullable, UUID } from '@/domain/model/types';
import { PaginationSize } from '@/presentation/types';
import { EOfferTableTab } from '@features/general/offer/types';
import { BookingOfferTableFilterValues } from '../../filterUtils';
import { OfferTableTabsCounter } from '@features/general/offer/utils/table';
import { BookingOfferShort } from '@/domain/model/booking';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { Pageable } from '@/domain/model';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { AppThunkAPIConfig } from '@/data/store/store';
import ErrorHandler from '@/data/network/errorHandler';
import { BookingAllProps } from '../../services/offer';
import bookingServices from '@features/bookingOffer/services';
import { EBookingOfferTableColumn } from '../../types';

const defaultSort = `${EBookingOfferTableColumn.Name},asc`;

export type BookingOfferSearchFetchProps = Omit<BookingAllProps, 'signal'>;

export type BookingOfferSearchCountsFetchProps = BookingOfferSearchFetchProps & {
  readonly tabs: EOfferTableTab[];
};

export const bookingOfferSearchCountsFetch = createAsyncThunk<
  OfferTableTabsCounter,
  BookingOfferSearchCountsFetchProps,
  AppThunkAPIConfig
>('bookingOffer/search/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await bookingServices.offer.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

export const bookingOfferSearchFetch = createAsyncThunk<
  Pageable<BookingOfferShort>,
  BookingOfferSearchFetchProps,
  AppThunkAPIConfig
>('bookingOffer/search/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    return await bookingServices.offer.all({ ...props, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface BookingOfferSearchState extends Fetchable, Pageable<BookingOfferShort> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
    readonly partnerId: Nullable<UUID>;
  };
  readonly tab: EOfferTableTab;
  readonly tabsCounter: OfferTableTabsCounter;
  readonly filter: BookingOfferTableFilterValues;
  readonly selected: BookingOfferShort[];
}

type Reducer<T = undefined> = CaseReducer<BookingOfferSearchState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<BookingOfferSearchState> {
  bookingOfferSearchStartSession: Reducer<{ guid: UUID; selected: BookingOfferShort[] }>;
  bookingOfferSearchSetTab: Reducer<{ tab: EOfferTableTab }>;
  bookingOfferSearchSetSearch: Reducer<{ sort: string; pageSize: PaginationSize }>;
  bookingOfferSearchSortReset: Reducer;
  bookingOfferSearchSetSort: Reducer<{ sort: string }>;
  bookingOfferSearchSetPage: Reducer<{ pageNumber: number }>;
  bookingOfferSearchSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  bookingOfferSearchSetFilter: Reducer<BookingOfferTableFilterValues>;
  bookingOfferSearchDataReset: Reducer;
  bookingOfferSearchNeedRefreshWatcherReset: Reducer;
  bookingOfferSearchSelect: Reducer<BookingOfferShort>;
  bookingOfferSearchUnselect: Reducer<BookingOfferShort>;
  bookingOfferSearchAllSelect: Reducer<Nullable<number>>;
  bookingOfferSearchAllUnselect: Reducer;
}

const slice = createSlice<BookingOfferSearchState, Reducers>({
  name: 'search',
  initialState: {
    ...fetchableDefault,
    guid: null,
    data: [],
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    needRefreshWatcher: 0,
    search: {
      sort: defaultSort,
      pageSize: 10,
      partnerId: null,
    },
    tab: EOfferTableTab.Active,
    filter: {},
    tabsCounter: {},
    selected: [],
  },
  reducers: {
    bookingOfferSearchStartSession: (state, { payload }) => {
      const { guid, selected } = payload;

      if (guid !== state.guid) {
        state.guid = guid;

        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = false;

        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;
        state.needRefreshWatcher = 0;
        state.search = {
          sort: defaultSort,
          pageSize: 10,
          partnerId: null,
        };
        state.tab = EOfferTableTab.Active;
        state.filter = {};
        state.tabsCounter = {};
        state.selected = selected;
      }
    },
    bookingOfferSearchSetTab: (state, { payload }) => {
      const { tab } = payload;

      if (state.tab !== tab) {
        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
      }

      state.needRefreshWatcher++;
    },
    bookingOfferSearchSetSearch: (state, { payload }) => {
      const { sort, pageSize } = payload;

      state.search = {
        ...state.search,
        sort,
        pageSize,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    bookingOfferSearchSortReset: (state, { payload }) => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
      state.selected = [];
    },
    bookingOfferSearchSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    bookingOfferSearchSetPage: (state, { payload }) => {
      const { pageNumber } = payload;

      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    bookingOfferSearchSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;

      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    bookingOfferSearchSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    bookingOfferSearchDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
      state.selected = [];
    },
    bookingOfferSearchNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
    bookingOfferSearchSelect: (state, { payload }) => {
      const selected = [...state.selected];
      const existedIndex = selected.findIndex(s => s.id === payload.id);

      if (existedIndex !== -1) {
        selected.splice(existedIndex, 1);
      }

      state.selected = [...selected, payload];
    },
    bookingOfferSearchUnselect: (state, { payload }) => {
      const existedIndex = state.selected.findIndex(s => s.id === payload.id);

      if (existedIndex !== -1) {
        state.selected.splice(existedIndex, 1);
      }
    },
    bookingOfferSearchAllSelect: (state, { payload }) => {
      if (payload) {
        state.selected = [...state.data.slice(0, payload)];
      } else {
        state.selected = state.data;
      }
    },
    bookingOfferSearchAllUnselect: state => {
      state.selected = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bookingOfferSearchFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;

        if (partnerId !== state.search.partnerId) {
          state.data = [];
          state.tabsCounter = {};
        }
        state.search.partnerId = partnerId;
      })
      .addCase(bookingOfferSearchFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(bookingOfferSearchFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(bookingOfferSearchCountsFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        if (partnerId !== state.search.partnerId) {
          state.tabsCounter = {};
        }
      })
      .addCase(bookingOfferSearchCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(bookingOfferSearchCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      });
  },
});

export const {
  bookingOfferSearchStartSession,
  bookingOfferSearchSetTab,
  bookingOfferSearchSetSearch,
  bookingOfferSearchSortReset,
  bookingOfferSearchSetSort,
  bookingOfferSearchSetPage,
  bookingOfferSearchSetFilter,
  bookingOfferSearchSetPageSize,
  bookingOfferSearchDataReset,
  bookingOfferSearchNeedRefreshWatcherReset,
  bookingOfferSearchSelect,
  bookingOfferSearchUnselect,
  bookingOfferSearchAllSelect,
  bookingOfferSearchAllUnselect,
} = slice.actions;

export default slice.reducer;
