import React from 'react';
import { FileDescription } from '../../../../../../domain/model';
import { Nullable } from '../../../../../../domain/model/types';
import { ImageStub, ImageWrapper, Number, StyledImage, Wrapper } from './controls';

interface CmsPreviewBlockProps {
  readonly image?: Nullable<FileDescription | string>;
  readonly width?: Nullable<string>;
  readonly height?: Nullable<string>;
  readonly number: number;
  readonly caption?: Nullable<React.ReactNode>;
  readonly isActive: boolean;
  readonly showNumber?: boolean;
  readonly stub?: React.ReactNode;
}

const CmsPreviewBlock = (props: CmsPreviewBlockProps) => {
  const { image, width, height, number, caption, isActive, stub, showNumber } = props;

  return (
    <Wrapper
      aria-checked={isActive}
      width={width}
      height={height}
    >
      <ImageWrapper>
        {image ? <StyledImage src={image} /> : stub ? stub : <ImageStub isActive={isActive} />}
      </ImageWrapper>
      {caption}
      {showNumber && (
        <Number
          align='center'
          isActive={isActive}
          variant='h2'
        >
          {number}
        </Number>
      )}
    </Wrapper>
  );
};

export default CmsPreviewBlock;
