import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BookingOrder } from '@/domain';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { useBookingOrderActionsSelectors } from '../../actions/useAction';
import { BookingOrderLifeCycle } from '../../lifecycle/types';
import {
  BookingOrderAction,
  BookingOrderActionTableType,
  EBookingOrderActionType,
  EBookingOrderTableTab,
} from '../../types';
import { getBookingOrderTableActions } from '../../utils/actions';
import BookingOrderTable from '../component';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { useBookingOrderTableMetadata } from '../hooks/useMetaData';
import {
  bookingOrdersOrdersSelector,
  bookingOrdersSortColumnSelector,
  bookingOrdersSortDirectionSelector,
} from '../store/selectors';

type BookingOrdersTableTableAdapterProps = {
  readonly tab: EBookingOrderTableTab;
  readonly partnerId: Nullable<UUID>;
  readonly lifecycle: BookingOrderLifeCycle;
  readonly tableTabActions: BookingOrderAction<BookingOrderActionTableType, any>[];
  readonly onOrderClick: (order: BookingOrder) => void;
};

const BookingOrdersTableTableAdapter = (props: BookingOrdersTableTableAdapterProps) => {
  const { tab, partnerId, lifecycle, tableTabActions, onOrderClick } = props;

  const {
    accessMatrix: { isPartnerUser },
  } = useCurrentUser();
  const handlers = useContextHandlers();

  const sortColumn = useSelector(bookingOrdersSortColumnSelector);
  const sortDirection = useSelector(bookingOrdersSortDirectionSelector);
  const orders = useSelector(bookingOrdersOrdersSelector);

  const { isBookingOrdersAnyChangedSelector } = useBookingOrderActionsSelectors();
  const needRefresh = useSelector(isBookingOrdersAnyChangedSelector);

  const { metadata, onChangeMetadata } = useBookingOrderTableMetadata({
    tab,
    partnerId,
    isPartnerUser,
    sortColumn,
    onSortReset: handlers.onResetSort,
  });

  const onExecuteAction = (action: BookingOrderActionTableType, order: BookingOrder) => {
    switch (action) {
      case EBookingOrderActionType.Give:
        handlers.onOrderGive(order);
        break;
      case EBookingOrderActionType.Renew:
        handlers.onOrderRenew(order);
        break;
      case EBookingOrderActionType.Cancel:
        handlers.onOrderTryCancel(order);
        break;
      case EBookingOrderActionType.Confirm:
        handlers.onOrderConfirm(order);
        break;
    }
  };

  useEffect(() => {
    if (needRefresh > 0) {
      handlers.onRefresh();
    }
  }, [needRefresh, handlers.onRefresh]);

  return (
    <BookingOrderTable
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      orders={orders}
      getActions={order => getBookingOrderTableActions(lifecycle, order, tableTabActions)}
      onRequestSort={handlers.onChangeSort}
      onClick={onOrderClick}
      onAction={onExecuteAction}
      onChangeMetadata={onChangeMetadata}
    />
  );
};

export default BookingOrdersTableTableAdapter;
