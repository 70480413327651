import { UUID } from '../../../../../domain/model/types';
import { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import TableCommonLayout from '../../../../layouts/tableCommon/container';
import useCurrentUser from '../../hooks/useCurrentUser';
import { AdminMpTableBehaviorAdapter } from './adapters/behavior';
import AdminMpTableFilterAdapter from './adapters/filter';
import AdminMpTableHeaderAdapter from './adapters/header';
import AdminMpTableAdapter from './adapters/table';
import { AdminMpTableTabsAdapter } from './adapters/tabs';
import { AdminMpTableContext } from './context';
import { EAdminMpFilterItem } from './filterUtils';
import AdminMpTableFooterContainer from './footerContainer';
import useAdminMpTableActions from './hooks/useActions';
import { useAdminMpTableHandlers } from './hooks/useAdminMpTable';
import { EAdminMpTableColumn, EAdminMpTableTab } from './utils';

interface AdminMpTableContainerProps {
  readonly guid: UUID;
  readonly tab: EAdminMpTableTab;
  readonly filterAdapter: DataFilterAdapter<EAdminMpTableColumn, EAdminMpFilterItem>;
  readonly canCreate?: boolean;
  readonly canChangeStatus?: boolean;
}

const AdminMpTableContainer = ({
  guid,
  tab,
  filterAdapter,
  canCreate = false,
  canChangeStatus = false,
}: AdminMpTableContainerProps) => {
  const { userSpecific } = useCurrentUser();

  const handlers = useAdminMpTableHandlers({tab});

  const { getAdminMpActions } = useAdminMpTableActions({
    canActivate: canChangeStatus,
    canDeactivate: canChangeStatus,
    tab,
    user: userSpecific,
  });

  return (
    <AdminMpTableContext.Provider value={handlers}>
      <AdminMpTableBehaviorAdapter
        tab={tab}
        guid={guid}
      />
      <TableCommonLayout
        header={<AdminMpTableHeaderAdapter canCreate={canCreate} />}
        filter={<AdminMpTableFilterAdapter filterAdapter={filterAdapter} />}
        tabs={<AdminMpTableTabsAdapter tab={tab} />}
        table={
          <AdminMpTableAdapter
            filterAdapter={filterAdapter}
            getActions={getAdminMpActions}
          />
        }
        footer={AdminMpTableFooterContainer}
      />
    </AdminMpTableContext.Provider>
  );
};

export default AdminMpTableContainer;
