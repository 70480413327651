import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Nullable, UUID } from '../../../domain/model/types';
import rootRouting from '../../../routing';
import NotFoundScreen from '../../screens/notFound';
import SportsmanScreen from '../../screens/sportsman';
import SportsmansScreen from '../../screens/sportsmans';
import { ESportsmanTab } from './details/utils';

const routing = {
  root: `${rootRouting.sportsmen}`,
  list: `${rootRouting.sportsmen}`,
  details: `${rootRouting.sportsmen}/:id`,
};

export enum ESportsmanUrlParam {
  Guid = 'guid',
  Tab = 'tab',
}

interface GetSportsmanDetailsRouteProps {
  readonly guid?: Nullable<UUID>;
  readonly id: UUID;
  readonly tab?: ESportsmanTab;
}

export interface SportsmansLocationState {
  readonly guid: UUID;
}

export const getSportsmanDetailsRoute = ({ guid, id, tab }: GetSportsmanDetailsRouteProps) => {
  const searchParams = new URLSearchParams();
  searchParams.append(ESportsmanUrlParam.Guid, guid || uuidv4());

  if (tab) {
    searchParams.append(ESportsmanUrlParam.Tab, tab);
  }

  return `${routing.details.replace(':id', id)}?${searchParams.toString()}`;
};

const SportsmanEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.list}
        component={SportsmansScreen}
      />
      <Route
        exact
        path={routing.details}
        component={SportsmanScreen}
      />
      <Redirect
        exact
        from={routing.root}
        to={routing.list}
      />
      <Route component={NotFoundScreen} />
    </Switch>
  );
};

export default SportsmanEntry;
