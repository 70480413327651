import { Fetchable, fetchableDefault, fetchableFetched, fetchableFetching } from '@/data/store/types';
import { Nullable, UUID } from '@/domain/model/types';
import { ServerErrorResponse } from '@/data/network/types';
import { EClientOrgUserActionType } from '../../types';
import { ClientOrg } from '@/domain/model/clientOrg';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { AppThunkAPIConfig } from '@/data/store/store';
import ErrorHandler from '@/data/network/errorHandler';
import userServices from '@features/user/services';

export interface ClientOrgUserActionsState {
  readonly actions: (Fetchable & {
    id: UUID;
    type: EClientOrgUserActionType;
    error: Nullable<ServerErrorResponse>;
  })[];
  readonly create: Fetchable;
  readonly dialogs: {
    readonly import: Nullable<ClientOrg>;
  };
}

type Reducer<T = undefined> = CaseReducer<ClientOrgUserActionsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ClientOrgUserActionsState> {
  clientOrgUserActionsChangeDialogState: Reducer<{
    name: keyof ClientOrgUserActionsState['dialogs'];
    data: Nullable<ClientOrg>;
  }>;
  clientOrgUserActionsOptimize: Reducer;
}

const getActionProcess = (state: ClientOrgUserActionsState, id: UUID, actionType: EClientOrgUserActionType) => {
  let process = state.actions.find(change => change.id === id);
  if (process) return process;

  process = {
    ...fetchableDefault,
    id,
    type: actionType,
    error: null,
  };
  state.actions.push(process);

  return process;
};

export const clientOrgUserActionsImport = createAsyncThunk<string, { id: UUID; file: File }, AppThunkAPIConfig>(
  'clientOrgUser/actions/import',
  async ({ id, file }, { getState, rejectWithValue }) => {
    try {
      return await userServices.clientOrgUser.import({ clientOrgId: id, file });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue({
        error: e.response.data,
      });
    }
  }
);

const slice = createSlice<ClientOrgUserActionsState, Reducers>({
  name: 'clientOrgUser/actions',
  initialState: {
    actions: [],
    create: fetchableDefault,
    dialogs: {
      import: null,
    },
  },
  reducers: {
    clientOrgUserActionsChangeDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
    clientOrgUserActionsOptimize: state => {
      // Оставляем только выполняющиеся действия
      state.actions = state.actions.filter(action => action.isFetching);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(clientOrgUserActionsImport.pending, (state, { meta }) => {
        const { id } = meta.arg;

        if (id) {
          const actionType = EClientOrgUserActionType.Import;
          const process = getActionProcess(state, id, actionType);

          process.isFetching = true;
          process.isFetched = false;
          process.isFailed = false;

          process.id = id;
          process.type = actionType;
          process.error = null;
        } else {
          state.create = fetchableFetching;
        }
      })
      .addCase(clientOrgUserActionsImport.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        if (id) {
          const actionType = EClientOrgUserActionType.Import;
          const process = getActionProcess(state, id, actionType);

          process.isFetching = false;
          process.isFetched = true;
          process.isFailed = false;

          process.id = id;
          process.type = actionType;
          process.error = null;
        } else {
          state.create = fetchableFetched;
        }
      })
      .addCase(clientOrgUserActionsImport.rejected, (state, { meta }) => {
        const { id } = meta.arg;

        if (id) {
          const actionType = EClientOrgUserActionType.Import;
          const process = getActionProcess(state, id, actionType);

          process.isFetching = false;
          process.isFetched = false;
          process.isFailed = true;

          process.id = id;
          process.type = actionType;
          process.error = null;
        } else {
          state.create = fetchableFetched;
        }
      });
  },
});

export const { clientOrgUserActionsOptimize, clientOrgUserActionsChangeDialogState } = slice.actions;

export default slice.reducer;
