import { MPFormTextArea } from '../../../../theme/ui-kit/input';
import { BookingOrderEditFieldCommonProps } from '../../types';

const fieldName = 'managerComment';

export const BookingOrderEditManagerCommentField = (props: BookingOrderEditFieldCommonProps<typeof fieldName>) => {
  const { value, onChangeAttribute, disabled } = props;

  return (
    <MPFormTextArea
      label={'Комментарий'}
      rows={3}
      value={value}
      disabled={disabled}
      onChange={event => onChangeAttribute(fieldName, event.target.value)}
    />
  );
};
