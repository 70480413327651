import axios, { AxiosResponse } from 'axios';
import { EUserStatus } from '../../domain/model/enums';
import { Nullable, UUID } from '../../domain/model/types';
import { MpCustomer } from '../../domain/model/user';
import { createCancelToken } from './index';
import {
  ApiCancellable,
  ApiQueryDsl,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
  ApiResponseMaBeCounters,
} from './types';
import { appendQueryDslParams, getComServicesEndpoint } from './utils';

export type AllProps<D extends ApiRequestListDiscriminator> = ApiRequestPageable &
  ApiCancellable & {
    readonly partnerId?: Nullable<UUID>;
    readonly clientOrgId?: Nullable<UUID>;
    readonly statuses?: Nullable<EUserStatus[]>;
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
    readonly discriminator?: D;
  };

type CustomerApi = {
  readonly all: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps<D>
  ) => Promise<AxiosResponse<ApiResponseMaBeCounters<D, MpCustomer[]>>>;
};

/**
 * АПИ по работе с пользователями клиентов
 */
const customer: CustomerApi = {
  all: props => {
    const { partnerId, clientOrgId, sort, discriminator, statuses, query, querydsl, page, pageSize, signal } = props;

    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }
    if (query) params.append('q', query);
    statuses?.forEach(status => params.append('status', status));
    if (partnerId) params.append('partnerId', partnerId);
    if (clientOrgId) params.append('clientOrgId', clientOrgId);
    if (querydsl) appendQueryDslParams(params, querydsl);

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (discriminator) {
      params.append('resultType', discriminator);
    }

    return axios.get(`${getComServicesEndpoint()}/customers`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
};

export default customer;
