import { RootState } from '../../../../../../data/store/store';

export const activityTypesEditDataSelector = (store: RootState) => store.dictionary.activityType.edit.fetch.data;

export const activityTypesEditIsPreloadingSelector = (store: RootState) =>
  !store.dictionary.activityType.edit.fetch.data && store.dictionary.activityType.edit.fetch.isFetching;

export const activityTypesEditIsLoadingSelector = (store: RootState) =>
  store.dictionary.activityType.edit.fetch.isFetching;

export const activityTypesEditDialogsStateSelector = (store: RootState) => store.dictionary.activityType.edit.dialogs;
