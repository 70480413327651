import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FCC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DataTreeItem } from '../../../../../domain/model';
import { Nullable } from '../../../../../domain/model/types';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { ContentWrapper, List, ListWrapper, Wrapper } from './controls';
import { DictionaryTreeListItem } from './item/listItem';
import { DictionaryTreeChangeType, DictionaryTreeProps } from './types';
import { treeFindItem, treeFindParentAndPush } from './utils';

type ColumnListProps = {
  readonly height: number;
  readonly windowHeight: number;
  readonly scrollToElementId?: string;
};

type ColumnListPropsWrapperProps<T> = DictionaryTreeProps<T> & {
  readonly value: DataTreeItem<T>[];
  readonly level: number;
  readonly windowHeight: number;
  readonly columnHeight: number;
  readonly parent: Nullable<DataTreeItem<T>>;
  readonly getNext: (level: number) => Nullable<DataTreeItem<T>>;
  readonly getTargetId: (level: number) => string | undefined;
  readonly onClickItem: (item: DataTreeItem<T>) => void;
  readonly isSelected: (item: DataTreeItem<T>) => boolean;
  readonly isExpanded: (item: DataTreeItem<T>) => boolean;
  readonly isInSelectedPath: (item: DataTreeItem<T>) => boolean;
};

const ColumnList: FCC<ColumnListProps> = props => {
  const { height = 0, children, scrollToElementId } = props;
  const [contentWrapperHeight, setContentWrapperHeight] = useState<number>(0);
  const scrollRef = useRef<OverlayScrollbarsComponent>(null);
  const listRef = useRef<any>(null);

  useEffect(() => {
    setContentWrapperHeight(height);
  }, [height]);

  useEffect(() => {
    if (scrollToElementId && scrollRef.current) {
      const osInstance = scrollRef.current?.osInstance();
      if (osInstance) {
        const viewport = osInstance.getElements('viewport');
        const el = viewport.querySelector('[data-id="' + scrollToElementId + '"]');

        if (el) {
          osInstance.scroll(el, 300);
        }
      }
    }
  });

  return (
    <ListWrapper height={contentWrapperHeight}>
      <OverlayScrollbarsComponent
        ref={scrollRef}
        options={{ scrollbars: { autoHide: 'leave' } }}
      >
        <List ref={listRef}>{children}</List>
      </OverlayScrollbarsComponent>
    </ListWrapper>
  );
};

const ColumnListWrapper = <T extends object>(props: ColumnListPropsWrapperProps<T>) => {
  const {
    data,
    changeType,
    parent,
    windowHeight,
    columnHeight,
    level,
    hasLevel,
    slots: { item: SlotItem, levelHeader: SlotLevelHeader },
    getNext,
    getTargetId,
    onChangeSort,
    onClickItem,
    isSelected,
    isExpanded,
    isInSelectedPath,
  } = props;

  const [itemsDisplay, setItemsDisplay] = useState<DataTreeItem<T>[]>(() => data ?? []);

  const onMove = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setItemsDisplay((prevItems: DataTreeItem<T>[]) => {
        // создаём копию
        const imageItems = Array.from(prevItems);
        // получаем текущий
        const target = imageItems[dragIndex];

        // удаляем выбранный элемент
        imageItems.splice(dragIndex, 1);

        // вставляем выбранный элемент на позицию в которой находиться курсор
        imageItems.splice(hoverIndex, 0, target);

        return imageItems;
      });
    },
    [onChangeSort]
  );

  useEffect(() => {
    setItemsDisplay(() => data);
  }, [data]);

  if (itemsDisplay.length === 0) {
    return null;
  }

  const next = getNext(level);
  const nextLvlArr = next?.children ?? [];

  const targetId = changeType === DictionaryTreeChangeType.search ? getTargetId(level) : undefined;

  return (
    <>
      <ColumnList
        key={parent?.id}
        windowHeight={windowHeight}
        height={columnHeight}
        scrollToElementId={targetId}
      >
        {SlotLevelHeader && (
          <SlotLevelHeader
            level={level}
            parent={parent}
          />
        )}
        {itemsDisplay.map((item, index) => (
          <DictionaryTreeListItem
            key={item.id}
            component={SlotItem}
            data={item}
            index={index}
            level={level}
            selected={isSelected(item)}
            expanded={isExpanded(item)}
            inSelectedPath={isInSelectedPath(item)}
            onClick={() => onClickItem(item)}
            onMove={onChangeSort ? onMove : undefined}
            onChangeSort={onChangeSort ? () => onChangeSort?.(parent, itemsDisplay) : undefined}
          />
        ))}
      </ColumnList>

      {nextLvlArr.length > 0 && (
        <ColumnListWrapper
          key={next?.id}
          {...props}
          level={level + 1}
          parent={next}
          data={nextLvlArr}
        />
      )}
      {next && nextLvlArr.length === 0 && SlotLevelHeader && (!hasLevel || hasLevel(level + 1)) && (
        <ColumnList
          windowHeight={windowHeight}
          height={columnHeight}
          scrollToElementId={targetId}
        >
          <SlotLevelHeader
            level={level + 1}
            parent={next}
          />
        </ColumnList>
      )}
    </>
  );
};

export const DictionaryTree = <T extends object>(props: DictionaryTreeProps<T>) => {
  const { data, initialExpanded, selected } = props;

  const { height: windowHeight } = useWindowResize();

  const contentRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<HTMLDivElement>(null);

  const allSelected = useMemo<Nullable<DataTreeItem<T>[]>>(() => {
    return (
      selected
        ?.map(item => {
          const cascade: DataTreeItem<T>[] = [item];
          treeFindParentAndPush(item.parentId, cascade, data);
          return cascade.reverse();
        })
        .flatMap(item => item) ?? null
    );
  }, [selected]);

  const [target, setTarget] = useState<{
    item: DataTreeItem<T> | undefined;
    all: DataTreeItem<T>[];
    type: DictionaryTreeChangeType;
  } | null>(() =>
    initialExpanded
      ? {
          item: undefined,
          all: initialExpanded
            .map(item => {
              const cascade: DataTreeItem<T>[] = [item];
              treeFindParentAndPush(item.parentId, cascade, data);
              return cascade.reverse();
            })
            .flatMap(item => item),
          type: DictionaryTreeChangeType.click,
        }
      : null
  );

  const value: DataTreeItem<T>[] = target?.all ?? [];

  const getNext = (level: number): Nullable<DataTreeItem<T>> => {
    if (Array.isArray(value) && value.length > 0) {
      const categoryTarget = value[level];
      if (categoryTarget) {
        const category = treeFindItem(categoryTarget.id, data);
        return category ?? null;
      }
    }
    return null;
  };

  const getTargetId = (level: number): string | undefined => {
    if (Array.isArray(value) && value.length > 0) {
      const item = value[level];
      return item?.id;
    }
    return undefined;
  };

  const isSelected = (item: DataTreeItem<T>): boolean => {
    return !!selected?.find(r => r.id === item.id);
  };

  const isExpanded = (item: DataTreeItem<T>): boolean => {
    return !!value?.find(r => r.id === item.id);
  };

  const isInSelectedPath = (item: DataTreeItem<T>): boolean => {
    return !!allSelected?.find(r => r.id === item.id);
  };

  const onClickItem = useCallback(
    (item: DataTreeItem<T>) => {
      if (item) {
        const cascade: DataTreeItem<T>[] = [item];
        treeFindParentAndPush(item.parentId, cascade, data);

        setTarget({
          item,
          all: cascade.reverse(),
          type: DictionaryTreeChangeType.click,
        });
      }
    },
    [treeFindParentAndPush, data]
  );

  const columnHeight = useMemo(() => {
    return (
      (contentRef.current?.parentElement?.parentElement?.offsetHeight ?? 0) - (buttonsRef.current?.offsetHeight || 0)
    );
  }, [contentRef.current, buttonsRef.current, windowHeight]);

  return (
    <Wrapper>
      <ContentWrapper ref={contentRef}>
        <DndProvider backend={HTML5Backend}>
          <ColumnListWrapper
            key='no-parent'
            parent={null}
            level={0}
            value={value}
            windowHeight={windowHeight}
            columnHeight={columnHeight}
            getNext={getNext}
            getTargetId={getTargetId}
            onClickItem={onClickItem}
            isSelected={isSelected}
            isExpanded={isExpanded}
            isInSelectedPath={isInSelectedPath}
            {...props}
          />
        </DndProvider>
      </ContentWrapper>
    </Wrapper>
  );
};
