import { Tab } from '@mui/material';
import { FC } from 'react';
import { Border, Tabs, Wrapper } from './controls';

interface TabsPanelProps<T extends string, D> {
  readonly tab: T;
  readonly tabs: T[];
  readonly labelAdapter: FC<{ tab: T } & D>;
  readonly data?: D;
  readonly onChangeTab: (tab: T) => void;
}

const TabsPanel = <T extends string, D extends object>(props: TabsPanelProps<T, D>) => {
  const { tab, tabs, data = {}, labelAdapter: LabelAdapter, onChangeTab } = props;

  return (
    <Wrapper>
      <Tabs
        textColor='primary'
        variant='scrollable'
        scrollButtons='auto'
        value={tab}
        onChange={(event, newTab) => onChangeTab(newTab)}
      >
        {tabs.map(t => (
          <Tab
            key={t}
            label={
              // @ts-ignore
              <LabelAdapter
                tab={t}
                {...data}
              />
            }
            value={t}
          />
        ))}
      </Tabs>
      <Border />
    </Wrapper>
  );
};

export default TabsPanel;
