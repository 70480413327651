import styled from '@emotion/styled';

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;

  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
`;

export const BackdropWrapper = styled(LoaderWrapper)`
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 9999;
`;
