import { RootState } from '../../../../../data/store/store';

export const productOrderEditOrderSelector = (store: RootState) => store.productOrder.edit.order;
export const productOrderEditIsFetchingSelector = (store: RootState) =>
  store.productOrder.edit.fetchable.main.isFetching;
export const productOrderEditItemsIsFetchingSelector = (store: RootState) =>
  store.productOrder.edit.fetchable.items.isFetching;
export const productOrderEditItemsSelector = (store: RootState) => store.productOrder.edit.items;
export const productOrderEditModifiedItemsSelector = (store: RootState) => store.productOrder.edit.modifiedItems;
export const productOrderEditWarningsSelector = (store: RootState) => store.productOrder.edit.warnings;
export const productOrderEditDeliveryAddressSelector = (store: RootState) =>
  store.productOrder.edit?.order?.deliveryAddress ?? null;
