import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataFilterStrategyLocalityMultiple } from '../../../../../../domain/model/filter';
import { Nullable } from '../../../../../../domain/model/types';
import { AddressLocalityMultipleSelector } from '../../../../../features/address';
import { DataFilterEditItemProps } from '../index';

type ValueType<T extends string> = DataFilterStrategyLocalityMultiple<T>['value'];

interface DataFilterEditItemAddressLocalityMultipleProps<T extends string>
  extends DataFilterEditItemProps<T, Nullable<ValueType<T>>> {
  readonly strategy: DataFilterStrategyLocalityMultiple<T>;
}

function DataFilterEditItemAddressLocalityMultiple<T extends string>({
  strategy,
  onChange,
}: DataFilterEditItemAddressLocalityMultipleProps<T>) {
  const { label, value, readOnly } = strategy;

  return (
    <AddressLocalityMultipleSelector
      value={value ?? []}
      label={label}
      color='primary'
      disabled={readOnly}
      popupIcon={<ExpandMoreIcon fontSize='small' />}
      onChange={newValue => onChange(strategy, newValue)}
    />
  );
}

export default DataFilterEditItemAddressLocalityMultiple;
