export enum EPromotionActionType {
  Enable = 'enable',
  Disable = 'disable',
}

export interface PromotionActionProcess {
  readonly isFetching: boolean;
  readonly type: EPromotionActionType;
}

export interface PromotionAction<A = EPromotionActionType> {
  readonly type: A;
  readonly disabled: boolean;
  readonly label: string;
}

export type PromotionActions<A = EPromotionActionType> = PromotionAction<A>[];
