import { AdCampaignCreate, EOfferStatus, EOfferType, TradeOffer } from '@/domain';
import useTechConfig from '@/presentation/hooks/useTechConfig';
import { EAppFeature, NumberRange } from '@/presentation/types';
import TopPushDialog from '@components/common/dialogs/topPush';
import AdCampaignsOfferCollectionComponent from '@features/adCampaign/components/offerCollection';
import useAdCampaignCollectionOffer from '@features/adCampaign/create/collection/useChangeOffers';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import { getTradeOfferDetailsRoute } from '@features/tradeOffer/entry';
import TradeOfferSearchContainer from '@features/tradeOffer/search/container';
import { isAspPromotionType } from '@features/tradeOffer/utils';
import useCurrentUser from '@features/user/hooks/useCurrentUser';
import { viewConfig } from '@theme/viewConfig';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getAdCampaignSearchTradeOfferPresetValues } from '../../utils';
import useAdCampaignCollectionTradeOfferFilterAdapter from './useFilterAdapter';

interface AdCampaignTradeOfferCollectionContainerProps {
  readonly guid: UUID;
  readonly offers: TradeOffer[];
  readonly adCampaign: AdCampaignCreate;
  readonly selectedMaxCount?: NumberRange<2, 100> | false;
  readonly onChange: (offers: TradeOffer[]) => void;
}

const AdCampaignTradeOfferCollectionContainer = (props: AdCampaignTradeOfferCollectionContainerProps) => {
  const { guid, offers, adCampaign, selectedMaxCount, onChange } = props;

  const history = useHistory();
  const {
    accessMatrix: {
      bookingOffers: { viewAsAdmin = false },
    },
  } = useCurrentUser();
  const { hasFeature } = useTechConfig();
  const isAspFeature = hasFeature(EAppFeature.Asp);

  const { promotionTypes: tradePromotionTypes, userGenders: genderTypes } = useSelector(nsiDataSelector);

  const promotionTypes = isAspFeature
    ? tradePromotionTypes
    : tradePromotionTypes.filter(promotionType => !isAspPromotionType(promotionType.id));

  const filterPresetValues = useMemo(() => getAdCampaignSearchTradeOfferPresetValues(adCampaign), [adCampaign]);

  const {
    isSearchDialogOpened,
    searchDialogParams,
    onOpenSearchDialog,
    onCloseSearchDialog,
    onChangeSearchDialogTab,
    onSelect,
    onClear,
    onUnselect,
  } = useAdCampaignCollectionOffer<TradeOffer>({
    offerType: EOfferType.Trade,
    selected: offers,
    onChange,
  });

  const filterAdapter = useAdCampaignCollectionTradeOfferFilterAdapter({
    viewAsAdmin,
    tab: searchDialogParams.dialog_tab,
    partnerId: null,
    values: filterPresetValues,
    promotionTypes,
    genderTypes,
  });

  const onOfferClick = useCallback(
    (id: UUID) => {
      history.push(getTradeOfferDetailsRoute({ id }));
    },
    [history]
  );

  return (
    <>
      <AdCampaignsOfferCollectionComponent
        offers={offers}
        offerType={EOfferType.Trade}
        onShowOfferSearch={onOpenSearchDialog}
        onClearOffers={onClear}
        onRemoveOffer={onUnselect}
      />
      <TopPushDialog
        fullScreen
        stickyHeader
        open={isSearchDialogOpened}
        title='Выбор торговых предложений'
        leftOffset={viewConfig.stepper.width}
        onClose={onCloseSearchDialog}
      >
        <TradeOfferSearchContainer
          guid={searchDialogParams?.dialog_guid ?? guid}
          partnerId={null}
          statuses={[EOfferStatus.Active]}
          filterAdapter={filterAdapter}
          initialSelected={offers}
          selectedMaxCount={selectedMaxCount}
          tab={searchDialogParams.dialog_tab}
          onTradeOfferClick={tradeOffer => onOfferClick(tradeOffer.id)}
          onSelect={onSelect}
          onChangeTableTab={onChangeSearchDialogTab}
        />
      </TopPushDialog>
    </>
  );
};

export default AdCampaignTradeOfferCollectionContainer;
