import { Chip, Grid, Typography } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { useCallback } from 'react';
import { OfferCategory } from '../../../../../domain/model';
import { EOfferCategoryType, EOfferStatus } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { ButtonLink } from '../../../../components/common/buttons/link';
import SectionTitle from '../../../../components/common/sectionTitle';
import Splitter from '../../../../components/common/splitter';
import useDialogInHistory from '../../../../hooks/useDialogInHistory';
import { ECollectionSearchDialogTag } from '../../container/create/collection/types';
import { CmsFeatureContainerCommonProps } from '../../container/types';
import { CmsLinkedObjectBannerCategoryType } from '../../types/banner';
import { ContentWrapper } from './controls';
import useCmsCategorySelector from './useCmsCategorySelector';
import { TradeOfferCategoriesSelectTopPushContainer } from '../../../dictionary/tradeOfferCategory/select/containerTopPush';
import { CorpOfferCategoriesSelectTopPushContainer } from '../../../dictionary/corpOfferCategory/select/containerTopPush';

interface CmsContainerCreateStepBannerCategory extends CmsFeatureContainerCommonProps {
  readonly category: Nullable<OfferCategory>;
  readonly statuses?: EOfferStatus[];
  readonly linkObjectType: CmsLinkedObjectBannerCategoryType['linkObjectType'];
  readonly onChangeLinkedObject: (banner: CmsLinkedObjectBannerCategoryType) => void;
}

const CmsCategorySelector = (props: CmsContainerCreateStepBannerCategory) => {
  const { cmsContext, category, statuses, linkObjectType, onChangeLinkedObject } = props;

  const {
    open: isSearchDialogOpened,
    onOpen: onOpenSearchDialog,
    onClose: onCloseSearchDialog,
  } = useDialogInHistory({ tag: ECollectionSearchDialogTag.Category, params: {} });

  const { categoryType, onSelect, onClear } = useCmsCategorySelector({
    linkObjectType,
    onChangeLinkedObject,
    onCloseSearchDialog,
  });

  const typeText = categoryType === EOfferCategoryType.Corp ? 'корпоративного' : 'торгового';

  const onSelectInternal = useCallback((categories: OfferCategory[]) => onSelect(categories?.[0] ?? null), [onSelect]);

  return (
    <Grid
      item
      xs={12}
    >
      <Splitter
        variant='horizontal'
        size={1}
      />
      <SectionTitle>Категория {typeText} предложения</SectionTitle>
      <ContentWrapper>
        {category ? (
          <Chip
            label={category?.name}
            color='primary'
            onDelete={onClear}
          />
        ) : (
          <ButtonLink onClick={() => onOpenSearchDialog()}>
            <AddBoxOutlinedIcon />
            <Typography>Выбрать категорию</Typography>
          </ButtonLink>
        )}
      </ContentWrapper>

      {categoryType === EOfferCategoryType.Corp && (
        <CorpOfferCategoriesSelectTopPushContainer
          maxCount={1}
          onlyUsed
          statuses={statuses}
          partnerId={cmsContext?.partner?.id}
          selected={category ? [category] : null}
          open={isSearchDialogOpened}
          onClose={selected => {
            if (selected) {
              onSelectInternal(selected);
            }
            onCloseSearchDialog();
          }}
        />
      )}

      {categoryType === EOfferCategoryType.Trade && (
        <TradeOfferCategoriesSelectTopPushContainer
          maxCount={1}
          onlyUsed
          statuses={statuses}
          partnerId={cmsContext?.partner?.id}
          selected={category ? [category] : null}
          open={isSearchDialogOpened}
          onClose={selected => {
            if (selected) {
              onSelectInternal(selected);
            }
            onCloseSearchDialog();
          }}
        />
      )}
    </Grid>
  );
};

export default CmsCategorySelector;
