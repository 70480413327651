import { useEffect, useState } from 'react';
import { EPartnerOwnershipType } from '../../../../../domain/model/enums';
import { PartnerDataDraft } from '../../../../../domain/model/partner';
import { Nullable } from '../../../../../domain/model/types';
import useValidation from '../../../../hooks/validation/useValidation';
import { ValidationResult } from '../../../../utils/validation';
import {
  PartnerDocumentsCompany,
  PartnerDocumentsIndividual,
  partnerEditDocumentsCompanyValidation,
  partnerEditDocumentsIndividualValidation,
} from '../validation';

interface UsePartnerDocumentsValidationProps {
  readonly object: Nullable<PartnerDataDraft>;
  readonly validateOnChange?: boolean;
}

type UsePartnerDocumentsValidation = {
  readonly isValid: boolean;
  readonly validationResultCompany: Nullable<ValidationResult<PartnerDocumentsCompany>>;
  readonly validationResultIndividual: Nullable<ValidationResult<PartnerDocumentsIndividual>>;
  readonly validate: () => boolean;
};

const getPartnerDocumentsCompanyByPartner = (partner: Nullable<PartnerDataDraft>): Nullable<PartnerDocumentsCompany> =>
  partner?.regInfo?.orgType && partner.regInfo.orgType !== EPartnerOwnershipType.IndividualEntrepreneur
    ? {
        ...partner,
        ogrnCert: partner.regInfo.ogrnCert,
        charterDoc: partner.regInfo.charterDoc,
        ceoAppointmentProtocol: partner.regInfo.ceoAppointmentProtocol,
      }
    : null;

const getPartnerDocumentsIndividualByPartner = (
  partner: Nullable<PartnerDataDraft>
): Nullable<PartnerDocumentsIndividual> =>
  partner?.regInfo?.orgType === EPartnerOwnershipType.IndividualEntrepreneur
    ? {
        ...partner,
        ogrnipCert: partner.regInfo.ogrnipCert,
        passportCopy: partner.regInfo.passportCopy,
      }
    : null;

function usePartnerDocumentsValidation(props: UsePartnerDocumentsValidationProps): UsePartnerDocumentsValidation {
  const { object, validateOnChange = false } = props;

  const [partnerDocumentsCompany, setPartnerDocumentsCompany] = useState<Nullable<PartnerDocumentsCompany>>(null);
  const [partnerDocumentsIndividual, setPartnerDocumentsIndividual] =
    useState<Nullable<PartnerDocumentsIndividual>>(null);

  const {
    validationResult: validationResultCompany,
    isValid: isValidDocumentsCompany,
    validate: validateDocumentsCompany,
  } = useValidation<PartnerDocumentsCompany>({
    object: partnerDocumentsCompany,
    validateOnChange,
    rules: partnerEditDocumentsCompanyValidation,
  });

  const {
    validationResult: validationResultIndividual,
    isValid: isValidDocumentsIndividual,
    validate: validateDocumentsIndividual,
  } = useValidation<PartnerDocumentsIndividual>({
    object: partnerDocumentsIndividual,
    validateOnChange,
    rules: partnerEditDocumentsIndividualValidation,
  });

  useEffect(() => setPartnerDocumentsCompany(getPartnerDocumentsCompanyByPartner(object)), [object]);
  useEffect(() => setPartnerDocumentsIndividual(getPartnerDocumentsIndividualByPartner(object)), [object]);

  return {
    validationResultCompany,
    validationResultIndividual,
    isValid: isValidDocumentsCompany && isValidDocumentsIndividual,
    validate: () => validateDocumentsCompany() && validateDocumentsIndividual(),
  };
}

export default usePartnerDocumentsValidation;
