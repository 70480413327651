import { Typography } from '@mui/material';
import { BookingServicePriceUnit } from '../../../../../../domain/model/booking';
import { MPMenuItem } from '../../../../../theme/ui-kit/menu/item';
import { MPSelect } from '../../../../../theme/ui-kit/select';
import { BookingOfferEditFieldServicePriceItemProps } from '../types';

const fieldName = 'unit';

interface BookingOfferEditFieldServicePriceItemUnitProps
  extends BookingOfferEditFieldServicePriceItemProps<typeof fieldName> {
  readonly priceUnits: BookingServicePriceUnit[];
}

const BookingOfferEditFieldServicePriceItemUnit = (props: BookingOfferEditFieldServicePriceItemUnitProps) => {
  const { value, priceUnits, validation, disabled, onChangeAttribute, onValidateAttribute } = props;

  const onChangeInternal = (v: typeof value) => {
    onChangeAttribute(fieldName, v);
    onValidateAttribute(fieldName);
  };

  return (
    <MPSelect<BookingServicePriceUnit>
      label='Единица измерения'
      disabled={disabled}
      value={priceUnits.find(item => item.id === value?.id) ?? ''}
      error={validation?.hasError}
      helperText={validation?.message}
      onChange={onChangeInternal}
    >
      {priceUnits.map(item => (
        <MPMenuItem
          key={item.id}
          value={item}
        >
          <Typography variant='body1'>{item.name}</Typography>
        </MPMenuItem>
      ))}
    </MPSelect>
  );
};

export default BookingOfferEditFieldServicePriceItemUnit;
