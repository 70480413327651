import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { offerHistoryTableHistoryByIndexSelector } from '../store/selectors';
import { OfferHistoryTableCellAdapterProps } from './cell';

const OfferHistoryTableCellEnteredByAdapter = (props: OfferHistoryTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const history = useSelector(offerHistoryTableHistoryByIndexSelector(index));

  return useMemo(() => (history ? <Component history={history} /> : null), [Component, history]);
};

export default OfferHistoryTableCellEnteredByAdapter;
