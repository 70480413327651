import { FieldsGroup } from '@components/fields';
import {
  AdCampaignCreateFieldAdapter,
  AdCampaignCreateFieldOfferPartnerAdapter,
  AdCampaignCreateFieldOfferTypeAdapter,
  AdCampaignCreateFieldTypeAdapter,
} from '@features/adCampaign/create/adapters';
import FormItemAdapter from '@features/adCampaign/create/adapters/formItem';
import AdCampaignCreateFieldEndDate from '@features/adCampaign/create/fields/endDate';
import AdCampaignCreateFieldName from '@features/adCampaign/create/fields/name';
import AdCampaignCreateFieldStartDate from '@features/adCampaign/create/fields/startDate';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

const AdCampaignCreateStepGeneral = () => {
  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Typography variant={'h3'}>Описание</Typography>
      </Grid>

      <FormItemAdapter>
        <AdCampaignCreateFieldAdapter
          name='name'
          component={AdCampaignCreateFieldName}
        />
      </FormItemAdapter>

      <FormItemAdapter>
        <AdCampaignCreateFieldAdapter
          name='type'
          component={AdCampaignCreateFieldTypeAdapter}
        />
      </FormItemAdapter>

      <FormItemAdapter>
        <AdCampaignCreateFieldAdapter
          name='partner'
          component={AdCampaignCreateFieldOfferPartnerAdapter}
        />
      </FormItemAdapter>
      <FormItemAdapter>
        <AdCampaignCreateFieldAdapter
          name='offerType'
          component={AdCampaignCreateFieldOfferTypeAdapter}
        />
      </FormItemAdapter>

      <FormItemAdapter>
        <FieldsGroup direction={'row'}>
          <AdCampaignCreateFieldAdapter
            name='startDate'
            component={AdCampaignCreateFieldStartDate}
          />
          <AdCampaignCreateFieldAdapter
            name='endDate'
            component={AdCampaignCreateFieldEndDate}
          />
        </FieldsGroup>
      </FormItemAdapter>
    </Grid>
  );
};

export default AdCampaignCreateStepGeneral;
