import { Grid } from '@mui/material';
import { useState } from 'react';
import { Nullable } from '../../../../domain/model/types';
import CodesGenerate from './components/generate';
import CodesUpload from './components/upload';
import { ToggleButtons, Wrapper } from './controls';

interface PersonalPromotionCreateProps {
  readonly isExecuting: boolean;
  readonly error?: string;
  readonly onUpload: (file: File) => void;
  readonly onGenerate: Nullable<(count: number) => void>;
}

enum EPersonalPromotionCreateType {
  Generate = 'generate',
  Upload = 'upload',
}

interface PromotionCreateTypeButton {
  readonly id: EPersonalPromotionCreateType;
  readonly name: string;
}

const typeButtons: PromotionCreateTypeButton[] = [
  {
    id: EPersonalPromotionCreateType.Generate,
    name: 'Генерация кодов',
  },
  {
    id: EPersonalPromotionCreateType.Upload,
    name: 'Загрузка кодов из файла',
  },
];

const PersonalPromotionCreate = (props: PersonalPromotionCreateProps) => {
  const { isExecuting, error, onUpload, onGenerate } = props;

  const [createType, setCreateType] = useState<EPersonalPromotionCreateType>(
    onGenerate ? EPersonalPromotionCreateType.Generate : EPersonalPromotionCreateType.Upload
  );

  return (
    <Wrapper>
      <Grid
        container
        spacing={5}
        wrap='nowrap'
        direction='column'
      >
        {onGenerate && (
          <Grid item>
            <ToggleButtons<PromotionCreateTypeButton>
              size='medium'
              value={typeButtons.find(item => item.id === createType) ?? null}
              items={typeButtons}
              onChange={value => {
                if (value) setCreateType(value.id);
              }}
            />
          </Grid>
        )}
        {createType === EPersonalPromotionCreateType.Generate && (
          <Grid item>
            <CodesGenerate
              isExecuting={isExecuting}
              error={error}
              onGenerate={onGenerate}
            />
          </Grid>
        )}
        {createType === EPersonalPromotionCreateType.Upload && (
          <Grid item>
            <CodesUpload
              isExecuting={isExecuting}
              error={error}
              onUpload={onUpload}
            />
          </Grid>
        )}
      </Grid>
    </Wrapper>
  );
};

export default PersonalPromotionCreate;
