import { Grid } from '@mui/material';
import { MPButton } from '../../../../../theme/ui-kit/button';
import { EConfigurationSportDictionaryType } from '../../../utils';
import { DictionaryTitle, Wrapper } from './controls';

interface ConfigurationSportDictionariesProps {
  readonly canEditActivityTypes?: boolean;
  readonly onEdit: (type: EConfigurationSportDictionaryType) => void;
}

export const ConfigurationSportDictionaries = (props: ConfigurationSportDictionariesProps) => {
  const { canEditActivityTypes, onEdit } = props;

  if (!canEditActivityTypes) {
    return null;
  }

  return (
    <Wrapper>
      <Grid
        container
        direction='column'
        spacing={3}
      >
        {canEditActivityTypes && (
          <Grid
            item
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <DictionaryTitle>Справочник интересов</DictionaryTitle>
            </Grid>
            <Grid
              item
              xs={7}
            >
              <MPButton onClick={() => onEdit(EConfigurationSportDictionaryType.ActivityType)}>Редактировать</MPButton>
            </Grid>
            <Grid
              item
              xs={5}
            >
              {/*<FileDownloadButton
                variant='outlined'
                startIcon={<IconDownload />}
                src={getTradeOfferCategoriesDownloadEndpoint()}
                accept={fileTypes.xls.mimeType}
                fileName={'категории_торговых_предложений'}
              >
                Скачать
              </FileDownloadButton>*/}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Wrapper>
  );
};
