import { ProductOffer } from '../../../../../../../domain/model/productOffer';
import DataTableCellImage from '../../../../../../components/common/table/cell/image';
import { ProductOrderItemTableCellImageWrapper } from './controls';
import { ProductOrderItemTableCellCommonProps } from '../types';

export interface ProductOrderItemTableCellImageProps extends ProductOrderItemTableCellCommonProps {
  readonly images: ProductOffer['images'];
}

const ProductOrderItemTableCellImages = (props: ProductOrderItemTableCellImageProps) => {
  const { images } = props;

  const path = images && images.length > 0 ? images[0].path : null;

  if (path) {
    return (
      <ProductOrderItemTableCellImageWrapper>
        <DataTableCellImage
          src={path}
          height='100%'
          width='100%'
        />
      </ProductOrderItemTableCellImageWrapper>
    );
  } else return null;
};

export default ProductOrderItemTableCellImages;
