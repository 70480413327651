import validator from 'validator';
import { MpUserData } from '../../../../../domain/model/user';
import { EValidationMessages, EValidationType, ValidationRules } from '../../../../utils/validation';
import { EAdminMpStep } from '../types';

export const getAdminMpCreateStepText = (step: EAdminMpStep): string => {
  switch (step) {
    case EAdminMpStep.Profile:
      return 'Профиль';
    case EAdminMpStep.Grants:
      return 'Доступ';
    default:
      throw new Error(`Unknown EAdminMpStep: ${step}`);
  }
};

export const adminMpCreateProfileStepValidation: ValidationRules<MpUserData> = {
  lastName: {
    required: true,
  },
  firstName: {
    required: true,
  },
  email: {
    required: true,
    validator: (object, value) => {
      if (!value) return null;

      const isEmail = validator.isEmail(value);
      if (!isEmail) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EValidationMessages.EmailInvalid,
        };
      } else {
        return null;
      }
    },
  },
  phone: {
    required: true,
  },
};
