import { paginationSizeVariant } from '@/domain/model/constants';
import { EClientOrgUserTableTab } from '@features/user/userClientOrg/types';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clientOrgUsersCountNeedRefreshWatcherSelector, clientOrgUsersGuidSelector } from '../store/selectors';
import { clientOrgUsersCountFetch, ClientOrgUsersFetchProps } from '../store/slice';

interface UseClientOrgUsersTableCountProps {
  readonly tab: EClientOrgUserTableTab;
}

const useClientOrgUsersTableCount = (props: UseClientOrgUsersTableCountProps): void => {
  const { tab } = props;

  const dispatch = useDispatch();

  const guid = useSelector(clientOrgUsersGuidSelector);
  const needRefreshWatcher = useSelector(clientOrgUsersCountNeedRefreshWatcherSelector);

  const queryParams = useMemo<ClientOrgUsersFetchProps>(() => {
    return {
      search: {
        onlyLoggedIn: null,
        statuses: [],
        sort: null,
        pageSize: paginationSizeVariant[0],
      },
      filter: {},
      pageNumber: 0,
    };
  }, [tab]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      const promise = dispatch(clientOrgUsersCountFetch(queryParams));
      return () => {
        promise?.abort();
      };
    }
  }, [dispatch, needRefreshWatcher, queryParams]);

  // начальный поиск и отмена запроса после ухода
  useEffect(() => {
    const promise = dispatch(clientOrgUsersCountFetch(queryParams));
    return () => {
      promise?.abort();
    };
  }, [dispatch, queryParams, guid]);
};

export default useClientOrgUsersTableCount;
