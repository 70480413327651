import { FC } from 'react';
import { useSelector } from 'react-redux';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { ESportsmansTableFilterItem } from '../../filterUtils';
import SportsmanTable from '../component';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  sportsmansGetFilter,
  sportsmansGetGuid,
  sportsmansSelector,
  sportsmansSortColumnSelector,
  sportsmansSortDirectionSelector,
} from '../store/selectors';
import { ESportsmanTableColumn } from '../utils';

interface SportsmanTableAdapterProps {
  readonly filterAdapter: DataFilterAdapter<ESportsmanTableColumn, ESportsmansTableFilterItem>;
}

const SportsmanTableAdapter: FC<SportsmanTableAdapterProps> = (props: SportsmanTableAdapterProps) => {
  const { filterAdapter } = props;
  const { onSportsmanClick } = useContextConfig();
  const { onChangeSort, onChangeFilter, onResetSort } = useContextHandlers();

  const guid = useSelector(sportsmansGetGuid);
  const sportsmen = useSelector(sportsmansSelector);

  const sortColumn = useSelector(sportsmansSortColumnSelector);
  const sortDirection = useSelector(sportsmansSortDirectionSelector);
  const filter = useSelector(sportsmansGetFilter);

  const { metadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: onChangeFilter,
    onSortReset: onResetSort,
  });

  return (
    <SportsmanTable
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      onRequestSort={onChangeSort}
      onClick={onSportsmanClick}
      sportsmen={sportsmen}
    />
  );
};

export default SportsmanTableAdapter;
