import { EUserStatus } from '@/domain/model/enums';
import { DataTableColumns } from '../../../../components/common/table';
import { EClientOrgUserTableTab } from '../types';

export enum EClientOrgUserTableColumn {
  Date = 'createdAt',
  Name = 'name',
  Phone = 'phone',
  Email = 'email',
}

export type ClientOrgUserTableTabsCounter = {
  [name in EClientOrgUserTableTab]?: number;
};

export const clientOrgUsersTableColumns: DataTableColumns<EClientOrgUserTableColumn> = {
  [EClientOrgUserTableColumn.Date]: {
    title: 'Дата',
    width: '10rem',
    sortable: true,
  },
  [EClientOrgUserTableColumn.Name]: {
    title: 'ФИО',
    width: '10rem',
    sortable: false,
  },
  [EClientOrgUserTableColumn.Phone]: {
    title: 'Телефон',
    width: '10rem',
    sortable: true,
  },
  [EClientOrgUserTableColumn.Email]: {
    title: 'Электронная почта',
    width: '10rem',
    sortable: true,
  },
};

const clientOrgUserTableDefaultColumns = [
  EClientOrgUserTableColumn.Date,
  EClientOrgUserTableColumn.Name,
  EClientOrgUserTableColumn.Phone,
  EClientOrgUserTableColumn.Email,
];

export const getClientOrgUserTableDefaultColumns = (): DataTableColumns<EClientOrgUserTableColumn> => {
  const defaultColumns = clientOrgUserTableDefaultColumns;

  const allColumns: EClientOrgUserTableColumn[] = [];

  allColumns.push(
    EClientOrgUserTableColumn.Date,
    EClientOrgUserTableColumn.Name,
    EClientOrgUserTableColumn.Phone,
    EClientOrgUserTableColumn.Email
  );

  return allColumns.reduce<DataTableColumns<EClientOrgUserTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...clientOrgUsersTableColumns[column],
        hidden: !defaultColumns.includes(column),
      },
    };
  }, {});
};

export const getStatesFilterForClientOrgUserTableTab = (
  tab: EClientOrgUserTableTab,
  defaultStatuses: EUserStatus[] = []
): EUserStatus[] => {
  switch (tab) {
    case EClientOrgUserTableTab.Enabled:
      return [EUserStatus.Enabled, EUserStatus.NotFilled];

    case EClientOrgUserTableTab.Invited:
      return [EUserStatus.Enabled, EUserStatus.NotFilled];

    case EClientOrgUserTableTab.Disabled:
      return [EUserStatus.Disabled];

    default: {
      return defaultStatuses;
    }
  }
};

export const getClientOrgUsersTableTabs = (): EClientOrgUserTableTab[] => [
  EClientOrgUserTableTab.Enabled,
  EClientOrgUserTableTab.Invited,
  EClientOrgUserTableTab.Disabled,
];

export const getClientOrgUsersTableTabsForCounts = (): EClientOrgUserTableTab[] => [
  EClientOrgUserTableTab.Enabled,
  EClientOrgUserTableTab.Invited,
  EClientOrgUserTableTab.Disabled,
];

export const getClientOrgUsersTableTabName = (tab: EClientOrgUserTableTab): string => {
  switch (tab) {
    case EClientOrgUserTableTab.Disabled:
      return 'Приостановлены';
    case EClientOrgUserTableTab.Invited:
      return 'Не заходили на витрину';
    case EClientOrgUserTableTab.Enabled:
      return 'Заходили на витрину';
  }
};
