import { Location } from 'history';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Nullable } from '../../domain/model/types';

const dialogParamName = 'dialog';
const defaultTag = 'default';

interface UseDialogInHistoryProps<P extends Record<string, any>> {
  readonly tag?: string;
  readonly params: P;
}

interface UseDialogInHistoryResult<P extends Record<string, any>> {
  readonly open: boolean;
  readonly params: P;
  readonly onOpen: (params?: P) => void;
  readonly onClose: () => void;
}

const useDialogInHistory = <P extends Record<string, any>>({
  tag = defaultTag,
  params,
}: UseDialogInHistoryProps<P>): UseDialogInHistoryResult<P> => {
  const location = useLocation();
  const history = useHistory();

  const dialogTag = useMemo<Nullable<string>>(
    () => new URLSearchParams(location.search).get(dialogParamName),
    [location]
  );

  const dialogParams = useMemo<P>(() => {
    const searchParams = new URLSearchParams(location.search);
    const result = {} as any;
    Object.keys(params).forEach(paramName => (result[paramName.toString()] = searchParams.get(paramName.toString())));
    return result;
  }, [location, params]);

  const getNewLocation = useCallback(
    (currentLocation: Location, addDialogTag: boolean, newParams?: P): Location => {
      const searchParams = new URLSearchParams(currentLocation.search);

      searchParams.delete(dialogParamName);
      if (addDialogTag) {
        searchParams.append(dialogParamName, tag);
      }

      Object.keys(params).forEach(paramName => searchParams.delete(paramName.toString()));
      if (newParams) {
        Object.entries(newParams).map(([key, value]) => {
          searchParams.append(key, value);
        });
      }

      return {
        pathname: currentLocation.pathname,
        search: `?${searchParams.toString()}`,
        hash: currentLocation.hash,
        state: currentLocation.state,
        key: currentLocation.key,
      };
    },
    [params]
  );

  const onOpen = useCallback(
    (newParams?: P) => {
      const newLocation = getNewLocation(location, true, newParams);
      history.replace(newLocation);
    },
    [history, location, getNewLocation]
  );

  const onClose = useCallback(() => {
    const newLocation = getNewLocation(location, false);
    history.replace(newLocation);
  }, [history, location, getNewLocation]);

  return {
    open: tag === dialogTag,
    params: dialogParams,
    onOpen,
    onClose,
  };
};

export default useDialogInHistory;
