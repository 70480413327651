import { Grid, Typography } from '@mui/material';
import ContentLoader from '../../../components/common/loader';
import useTechConfig from '../../../hooks/useTechConfig';
import { MPButton } from '../../../theme/ui-kit/button';
import { EAppFeature } from '../../../types';
import SupportPdaLinkContainer from '../../support/pdaLink';
import { SupportPdpLinkInstrumental } from '../../support/pdpLink';

interface PartnerSelfRegistrationActionsProps {
  readonly isFetching: boolean;
  readonly onSave: () => void;
}

const PartnerSelfRegistrationActions = (props: PartnerSelfRegistrationActionsProps) => {
  const { isFetching, onSave } = props;

  const { hasFeature } = useTechConfig();

  const hasPersonalDataAgreementFeature = hasFeature(EAppFeature.PersonalDataAgreement);
  const hasPersonalDataPolicyFeature = hasFeature(EAppFeature.PersonalDataPolicy);

  return (
    <Grid
      container
      spacing={2}
      direction='column'
    >
      <Grid item>
        <MPButton
          fullWidth={false}
          disabled={isFetching}
          onClick={onSave}
        >
          Далее
          {isFetching && <ContentLoader />}
        </MPButton>
      </Grid>
      {(hasPersonalDataPolicyFeature || hasPersonalDataAgreementFeature) && (
        <Grid item>
          <Typography>Нажимая кнопку «Далее», вы {hasPersonalDataPolicyFeature ? 'соглашаетесь' : 'даёте'}</Typography>
          {hasPersonalDataPolicyFeature && (
            <Typography noWrap>
              с <SupportPdpLinkInstrumental />
            </Typography>
          )}
          {hasPersonalDataAgreementFeature && (
            <Typography noWrap>
              {hasPersonalDataPolicyFeature ? 'и даёте ' : ''}
              <SupportPdaLinkContainer />
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default PartnerSelfRegistrationActions;
