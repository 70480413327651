import { RootState } from '../../../../../../data/store/store';

export const tradeOfferCategoriesSelectDataSelector = (store: RootState) =>
  store.dictionary.tradeOfferCategory.select.fetch.data;

export const tradeOfferCategoriesSelectIsPreloadingSelector = (store: RootState) =>
  store.dictionary.tradeOfferCategory.select.fetch.isFetching;

export const tradeOfferCategoriesSelectIsLoadingSelector = (store: RootState) =>
  store.dictionary.tradeOfferCategory.select.fetch.isFetching;

export const tradeOfferCategoriesSelectSelectedSelector = (store: RootState) =>
  store.dictionary.tradeOfferCategory.select.selected;

export const tradeOfferCategoriesSelectSelectedItemsSelector = (store: RootState) =>
  store.dictionary.tradeOfferCategory.select.selectedItems;
