import { useCallback } from 'react';
import { OfferCategory } from '../../../../../domain/model';
import { ECmsBannerLinkObjectType, EOfferCategoryType } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { CmsLinkedObjectBannerCategoryType } from '../../types/banner';

type UseCmsCategorySelector = {
  readonly categoryType: EOfferCategoryType;
  readonly onSelect: (offer: Nullable<OfferCategory>) => void;
  readonly onClear: () => void;
};

type UseCmsCategorySelectorProps = {
  readonly linkObjectType: CmsLinkedObjectBannerCategoryType['linkObjectType'];
  readonly onCloseSearchDialog: () => void;
  readonly onChangeLinkedObject: (data: CmsLinkedObjectBannerCategoryType) => void;
};

const useCmsCategorySelector = (props: UseCmsCategorySelectorProps): UseCmsCategorySelector => {
  const { linkObjectType, onCloseSearchDialog, onChangeLinkedObject } = props;

  const categoryType =
    linkObjectType === ECmsBannerLinkObjectType.CorpOfferCategory ? EOfferCategoryType.Corp : EOfferCategoryType.Trade;

  const onSelect = useCallback(
    (category: Nullable<OfferCategory>) => {
      onChangeLinkedObject({
        linkObjectType,
        linkedObject: category,
      });
      onCloseSearchDialog();
    },
    [onChangeLinkedObject, onCloseSearchDialog]
  );

  const onClear = useCallback(() => {
    onChangeLinkedObject({
      linkObjectType,
      linkedObject: null,
    });
  }, [onChangeLinkedObject]);

  return {
    categoryType,
    onSelect,
    onClear,
  };
};

export default useCmsCategorySelector;
