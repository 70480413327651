import { Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Tooltip } from '@mui/material';
import React from 'react';
import { ETeamMemberType } from '../../../../../../domain/model/enums';
import { TeamMember } from '../../../../../../domain/model/team';
import { UserAvatar } from '../../../../../components/common/avatars';
import { Captain as CaptainIcon } from '../../../../../icons';
import { getUserFullName } from '../../../../../utils';
import { List, Wrapper } from './controls';

interface TeamTabMembersProps {
  readonly members: TeamMember[];
  readonly onMemberClick: (member: TeamMember) => void;
}

const TeamTabMembers = ({ members, onMemberClick }: TeamTabMembersProps) => {
  return (
    <Wrapper>
      <List>
        {members.map((member, index, array) => (
          <React.Fragment key={member.id}>
            <ListItem
              button
              alignItems='center'
              onClick={() => onMemberClick(member)}
            >
              <ListItemAvatar>
                <UserAvatar user={member.userProfile} />
              </ListItemAvatar>
              <ListItemText
                primary={getUserFullName({ user: member.userProfile })}
                secondary={member.locality?.name}
              />
              {member.memberType === ETeamMemberType.TeamLeader && (
                <ListItemSecondaryAction>
                  <Tooltip
                    title='Капитан команды'
                    arrow
                  >
                    <CaptainIcon color='primary' />
                  </Tooltip>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            {array.length - 1 !== index && (
              <Divider
                key={`divider${member.id}`}
                variant='inset'
              />
            )}
          </React.Fragment>
        ))}
      </List>
    </Wrapper>
  );
};

export default TeamTabMembers;
