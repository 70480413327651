import { useMemo } from 'react';
import { BlankFileLink } from '../link';
import { FileInfoAdapterType, FileInfoState } from './types';

/**
 * адаптер для работы с файлом лежащим по url, без обёртки
 * применяется в рамках FileInfo
 */
export const FileInfoBlankAdapter: FileInfoAdapterType = props => {
  const { id, originalName, children } = props;

  const state = useMemo<FileInfoState>(
    () => ({
      isLoading: false,
      name: originalName ?? id ?? '',
      size: null,
      error: null,
    }),
    [originalName, id]
  );

  return <BlankFileLink>{children(state)}</BlankFileLink>;
};
