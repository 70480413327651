import { Grid } from '@mui/material';
import BookingOfferEditFieldServicePriceItemName from '../../components/fieldsEdit/priceItem/name';
import BookingOfferEditFieldServicePriceItemPrice from '../../components/fieldsEdit/priceItem/price';
import BookingOfferServiceManagePriceItemFieldUnitAdapter from '../adapters/fields/priceUntil';
import { BookingOfferServiceManageServicePriceItemFieldAdapter } from '../adapters/priceItemField';

interface BookingOfferServiceManageSectionPriceItemProps {
  readonly index: number;
  readonly serviceIndex: number;
}

const BookingOfferServiceManageSectionServicePriceItem = (props: BookingOfferServiceManageSectionPriceItemProps) => {
  const { index, serviceIndex } = props;

  return (
    <>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <BookingOfferServiceManageServicePriceItemFieldAdapter
            index={index}
            serviceIndex={serviceIndex}
            disabled
            name={'name'}
            component={BookingOfferEditFieldServicePriceItemName}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <BookingOfferServiceManageServicePriceItemFieldAdapter
            index={index}
            serviceIndex={serviceIndex}
            name={'price'}
            component={BookingOfferEditFieldServicePriceItemPrice}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <BookingOfferServiceManageServicePriceItemFieldAdapter
            index={index}
            serviceIndex={serviceIndex}
            name={'unit'}
            component={BookingOfferServiceManagePriceItemFieldUnitAdapter}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BookingOfferServiceManageSectionServicePriceItem;
