import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../data/store/store';
import { ApprovalOfferRegistryShort } from '../../../../domain/model/offerApprovalRegistry';
import { Nullable } from '../../../../domain/model/types';
import { offerApprovalRegistrySuggestionFetch } from './store/slice';

interface OfferApprovalRegistrySuggestionResultParams {
  readonly onSearch: (registryNom: Nullable<string>) => void;
  readonly isFetching: boolean;
  readonly approvalRegistries: Nullable<ApprovalOfferRegistryShort[]>;
}

interface OfferApprovalRegistrySuggestionContainerProps {
  readonly children: (params: OfferApprovalRegistrySuggestionResultParams) => React.ReactNode;
}

const OfferApprovalRegistrySuggestionContainer = ({ children }: OfferApprovalRegistrySuggestionContainerProps) => {
  const dispatch = useAppDispatch();

  const [approvalRegistries, setApprovalRegistries] = useState<Nullable<ApprovalOfferRegistryShort[]>>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [searchNumber, setSearchNumber] = useState<Nullable<string>>(null);

  const onSearch = (registryNom: Nullable<string>) => {
    setSearchNumber(registryNom);
  };

  useEffect(() => {
    setIsFetching(true);
    const promise = dispatch(offerApprovalRegistrySuggestionFetch({ number: searchNumber }));

    promise
      .unwrap()
      .then(result => {
        setIsFetching(false);
        setApprovalRegistries(result);
      })
      .catch((e: any) => {
        if (e.message !== 'Aborted') {
          setIsFetching(false);
        }
        console.error(e);
      });

    return () => {
      promise?.abort();
    };
  }, [dispatch, searchNumber]);

  return (
    <>
      {children({
        onSearch,
        isFetching,
        approvalRegistries,
      })}
    </>
  );
};

export default OfferApprovalRegistrySuggestionContainer;
