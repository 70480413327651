import { StepIconProps } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { memo } from 'react';
import { Wrapper } from './controls';

export interface CustomStepIconProps extends Partial<StepIconProps> {
  readonly flow?: boolean;
}

export const StepIcon = memo<CustomStepIconProps>(props => {
  const { active = false, completed = false, placeholder, flow = false } = props;

  return (
    <Wrapper
      active={active}
      completed={completed}
    >
      {flow && completed ? <CheckIcon fontSize={'small'} /> : placeholder}
    </Wrapper>
  );
});
