import { Typography } from '@mui/material';
import { FileDescription } from '../../../../../../domain/model';
import { Nullable } from '../../../../../../domain/model/types';
import AppImage from '../../../../../components/common/images/common';
import { Card, CardLabel, ContentWrapper, LogoSkeleton, LogoWrapper, Wrapper } from './controls';

interface PartnerLogoPreviewProps {
  readonly id: Nullable<string | FileDescription>;
  readonly stub?: Nullable<string>;
}

const PartnerLogoPreview = ({ id, stub }: PartnerLogoPreviewProps) => {
  return (
    <Wrapper>
      <Card elevation={0}>
        <CardLabel>
          <Typography
            color='textSecondary'
            variant='body2'
          >
            Предпросмотр
          </Typography>
        </CardLabel>
        <ContentWrapper>
          <LogoWrapper>
            {id ? (
              <AppImage src={id} />
            ) : stub ? (
              <img
                src={stub}
                alt=''
              />
            ) : (
              <LogoSkeleton
                animation={false}
                height='3rem'
              />
            )}
          </LogoWrapper>
        </ContentWrapper>
      </Card>
    </Wrapper>
  );
};

export default PartnerLogoPreview;
