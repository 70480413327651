import { AppConfigurator } from '../../../../system/appConfigurator';
import { LoginFrame, Wrapper } from './controls';

const ResetPasswordContainer = () => {
  const { url, realm } = AppConfigurator.getInstance().getApiAuthService();

  const resetPasswordUrl = `${url}/realms/${realm}/login-actions/reset-credentials`;

  return (
    <Wrapper>
      <LoginFrame
        src={resetPasswordUrl}
        width='100%'
        height='100%'
        frameBorder='0'
      />
    </Wrapper>
  );
};

export default ResetPasswordContainer;
