import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { EOfferStatus } from '../../../../../domain/model/enums';
import { TradeOffer } from '../../../../../domain/model/tradeOffer';
import { TradeOfferLifeCycle } from '../../../general/offer/lifecycle/types';
import { EOfferTableTab } from '../../../general/offer/types';
import {
  getOffersTableTabs,
  getOffersTableTabsForCounts,
  getOffersTableTabsForCountsUnwatched,
} from '../../../general/offer/utils/table';
import { getTradeOfferDetailsRoute, getTradeOfferEditRoute } from '../../entry';

export type UseTradeOffersTableConfigProps = {
  readonly lifecycle: TradeOfferLifeCycle;
  readonly canCreate: boolean;
};

export type UseTradeOffersTableConfig = {
  readonly tabs: EOfferTableTab[];
  readonly tabsForCounts: EOfferTableTab[];
  readonly tabsForCountsUnwatched: EOfferTableTab[];
  readonly defaultTab: EOfferTableTab;
  readonly onTradeOfferClick: (tradeOffer: TradeOffer) => void;
  readonly onTradeOfferOpenEdit: (tradeOffer: TradeOffer) => void;
};

export function useTradeOffersTableConfig(props: UseTradeOffersTableConfigProps): UseTradeOffersTableConfig {
  const { lifecycle, canCreate } = props;

  const history = useHistory();

  const onTradeOfferClick = useCallback(
    (tradeOffer: TradeOffer) => {
      if ([EOfferStatus.Draft].includes(tradeOffer.status) && canCreate) {
        history.push(getTradeOfferEditRoute({ id: tradeOffer.id }));
      } else {
        history.push(getTradeOfferDetailsRoute({ id: tradeOffer.id }));
      }
    },
    [history]
  );

  const onTradeOfferOpenEdit = useCallback(
    (tradeOffer: TradeOffer) => {
      history.push(getTradeOfferEditRoute({ id: tradeOffer.id }));
    },
    [history]
  );

  const tabs = useMemo(() => getOffersTableTabs(lifecycle.isPartnerUser), [lifecycle.isPartnerUser]);
  const tabsForCounts = useMemo(() => getOffersTableTabsForCounts(lifecycle.isPartnerUser), [lifecycle.isPartnerUser]);
  const tabsForCountsUnwatched = useMemo(() => getOffersTableTabsForCountsUnwatched(), []);
  const defaultTab = lifecycle.isPartnerUser ? EOfferTableTab.Active : EOfferTableTab.Moderation;

  return {
    tabs,
    tabsForCounts,
    tabsForCountsUnwatched,
    defaultTab,
    onTradeOfferClick,
    onTradeOfferOpenEdit,
  };
}
