import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { UUID } from '../../../domain/model/types';
import ForbiddenComponent from '../../components/common/forbidden';
import CustomerDetailsContainer from '../../features/customer/details/container';
import { viewShowMainMenu } from '../../features/main/container/store/slice';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const CustomerScreen = () => {
  const dispatch = useDispatch();
  const { customers } = useCurrentUser().accessMatrix;

  const { id } = useParams<{ id: UUID }>();

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (!customers?.view?.details) {
    return <ForbiddenComponent />;
  }

  return (
    <CustomerDetailsContainer
      id={id}
      canChangeStatus={customers?.changeStatus}
    />
  );
};

export default CustomerScreen;
