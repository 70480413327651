import { Grid } from '@mui/material';
import { MpUser } from '../../../../domain/model/user';
import UserMpDetailsGeneral from '../../user/components/detailsGeneral';
import { ColumnWrapper, Wrapper } from './controls';

interface CustomerDetailsComponentProps {
  readonly user: MpUser;
}

const CustomerDetailsComponent = (props: CustomerDetailsComponentProps) => {
  const { user } = props;

  return (
    <Wrapper>
      <ColumnWrapper>
        <Grid
          container
          spacing={4}
          direction='column'
        >
          <Grid item>
            <UserMpDetailsGeneral user={user} />
          </Grid>
        </Grid>
      </ColumnWrapper>
    </Wrapper>
  );
};

export default CustomerDetailsComponent;
