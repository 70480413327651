import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { cmsContainersTabsCounterSelector } from '../store/selectors';
import CmsContainerTableTabs from '../tabs';
import { ECmsContainerTableTab } from '../utils';

interface CmsContainersTableTabsAdapterProps {
  readonly tab: ECmsContainerTableTab;
}

export const CmsContainersTableTabsAdapter = (props: CmsContainersTableTabsAdapterProps) => {
  const { tab } = props;

  const handlers = useContextHandlers();
  const { tabs } = useContextConfig();

  const counter = useSelector(cmsContainersTabsCounterSelector);

  const onChangeTab = useCallback(
    (newTab: ECmsContainerTableTab) => handlers.onChangeTab(tab, newTab),
    [tab, handlers.onChangeTab]
  );

  return useMemo(
    () => (
      <CmsContainerTableTabs
        tab={tab}
        tabs={tabs}
        counter={counter}
        onChangeTab={onChangeTab}
      />
    ),
    [tab, tabs, counter, onChangeTab]
  );
};
