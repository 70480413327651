import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../data/store/store';
import { ESortDirection } from '../../../../../domain/model/enums';
import { ProductOffer } from '../../../../../domain/model/productOffer';
import { Nullable } from '../../../../../domain/model/types';
import { EProductTableColumn } from '../utils';

export const productsGetSelector = (store: RootState) => store.product.list;
export const productsCountNeedRefreshWatcherSelector = (store: RootState) =>
  store.product.list.allProducts.needRefreshWatcher;
export const productsProductOffersSelector = (store: RootState) => store.product.list.data;
export const productsIsEmptySelector = (store: RootState) =>
  store.product.list.allProducts.isFetched && store.product.list.allProducts.count === 0;
export const productsIsFetchingSelector = (store: RootState) =>
  store.product.list.isFetching || store.product.list.allProducts.isFetching;
export const productsIsPreloadingSelector = (store: RootState) => store.product.list.allProducts.count === null;
export const productsGuidSelector = (store: RootState) => store.product.list.guid;
export const productsSearchSelector = (store: RootState) => store.product.list.search;
export const productsPageNumberSelector = (store: RootState) => store.product.list.pageNumber;
export const productsSortSelector = (store: RootState) => store.product.list.search.sort;
export const productsSortColumnSelector = (store: RootState) =>
  (store.product.list.search.sort?.split(',') as [EProductTableColumn, ESortDirection])?.[0] ?? '';
export const productsSortDirectionSelector = (store: RootState) =>
  (store.product.list.search.sort?.split(',') as [EProductTableColumn, ESortDirection])?.[1] ?? '';
export const productsFilterSelector = (store: RootState) => store.product.list.filter;
export const productsNeedRefreshWatcherSelector = (store: RootState) => store.product.list.needRefreshWatcher;
export const productsTabsCounterSelector = (store: RootState) => store.product.list.tabsCounter;
export const productsTabsCounterUnwatchedSelector = (store: RootState) => store.product.list.tabsCounterUnwatched;
export const productsCategorySelector = (store: RootState) => store.product.list.category;
export const productsCategoryListSelector = (store: RootState) => store.product.list.categoryList;
export const productsSelectedSelector = (store: RootState) => store.product.list.selected;
export const productsActionsSelector = (store: RootState) => store.product.list.actions;
export const productsCollectionActionsSelector = (store: RootState) => store.product.list.collectionActions;
export const productsUnwatchedIdsSelector = (store: RootState) => store.product.list.unwatchedIds;
export const productsDialogsStateSelector = (store: RootState) => store.product.list.dialogs;

export const productsProductOfferIndexSelector = (store: RootState, index: number) => index;
const createProductsTableProductOfferByIndexSelector = createSelector(
  productsProductOffersSelector,
  productsProductOfferIndexSelector,
  (productOffers, index): Nullable<ProductOffer> => productOffers?.[index] ?? null
);

const createProductsTableUnwatchedByIndexSelector = createSelector(
  productsProductOffersSelector,
  productsUnwatchedIdsSelector,
  productsProductOfferIndexSelector,
  (productOffers, unwatchedIds, index): boolean => {
    const productOffer = productOffers?.[index] ?? null;
    if (!productOffer) {
      return false;
    } else {
      return unwatchedIds?.includes(productOffer.id) ?? false;
    }
  }
);

export const productsProductOfferByIndexSelector = (index: number) => (store: RootState) =>
  createProductsTableProductOfferByIndexSelector(store, index);
export const productsUnwatchedByIndexSelector = (index: number) => (store: RootState) =>
  createProductsTableUnwatchedByIndexSelector(store, index);
