import { FormHelperText, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { XLSXImportError, XLSXImportResult } from '../../../../domain/model';
import { ProductCategory } from '../../../../domain/model/productOffer';
import { Nullable } from '../../../../domain/model/types';
import FileDownloadButton from '../../../components/common/files/download/button';
import ContentLoader from '../../../components/common/loader';
import SectionTitle from '../../../components/common/sectionTitle';
import Splitter from '../../../components/common/splitter';
import { ValidationResult } from '../../../utils/validation';
import ProductUploadItems from '../components/upload';
import { productOffersImportAccept } from '../utils/common';
import ProductUploadOffersButtons from './buttons';
import ProductUploadOffersCategory from './category';
import { ProductOffersUploadObject } from './utils';

type ProductUploadOffersProps = {
  readonly offersDownloadUrl: string;
  readonly uploadFile: Nullable<{ file: File; error: Nullable<string> }>;
  readonly category: Nullable<ProductCategory>;
  readonly categoryList: Nullable<ProductCategory[]>;
  readonly isUploading: boolean;
  readonly isUploaded: boolean;
  readonly isFailed: boolean;
  readonly uploadResult: Nullable<XLSXImportResult>;
  readonly validation: Nullable<ValidationResult<ProductOffersUploadObject>>;
  readonly isValid: boolean;
  readonly getSuccessImportedCountText: (count: number) => string;
  readonly onSetFile: (file: File, error?: string) => void;
  readonly onRemoveFile: () => void;
  readonly onDownloadImportError: (errors: XLSXImportError[]) => void;
  readonly onUploadFile: () => void;
  readonly onChangeCategory: (category: Nullable<ProductCategory>, categoryList: Nullable<ProductCategory[]>) => void;
  readonly onClose: () => void;
};

const ProductUploadOffers: FC<ProductUploadOffersProps> = props => {
  const {
    offersDownloadUrl,
    uploadFile,
    category,
    categoryList,
    uploadResult,
    isUploading,
    isUploaded,
    isFailed,
    validation,
    isValid,
    getSuccessImportedCountText,
    onSetFile,
    onRemoveFile,
    onDownloadImportError,
    onUploadFile,
    onChangeCategory,
    onClose,
  } = props;

  const isUploadSuccess = isUploaded && (uploadResult?.successCount ?? 0) > 0;

  return (
    <>
      <Grid
        container
        spacing={4}
        direction='column'
      >
        <Grid item>
          <Typography noWrap>1. Выберите категорию товаров, которую хотите загрузить</Typography>
          <Typography noWrap>2. Скачайте предложенный шаблон</Typography>
          <Typography noWrap>3. Заполните шаблон товарами, которые будете продавать</Typography>
          <Typography noWrap>4. Загрузите заполненный товарами файл для импорта</Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <SectionTitle>Шаблон категории</SectionTitle>
          <Splitter size={1} />
          <ProductUploadOffersCategory
            category={category}
            categoryList={categoryList}
            onChangeCategory={onChangeCategory}
          />

          {!!validation?.category?.hasError && <FormHelperText error>{validation?.category?.message}</FormHelperText>}

          {category && (
            <>
              <Splitter size={2} />
              <FileDownloadButton
                size='small'
                fullWidth={false}
                src={offersDownloadUrl}
                accept={productOffersImportAccept}
                fileName={'загрузка_товаров'}
              >
                Скачать шаблон
              </FileDownloadButton>
            </>
          )}
        </Grid>

        <Grid item>
          <SectionTitle>Загрузка файла с товарами</SectionTitle>
          <Splitter size={1} />
          <ProductUploadItems
            uploadFile={uploadFile}
            importResult={uploadResult}
            isSaved={isUploaded}
            isFailed={isFailed}
            isValid={isValid}
            validation={validation}
            getSuccessImportedCountText={getSuccessImportedCountText}
            onSetFile={onSetFile}
            onRemoveFile={onRemoveFile}
            onDownloadImportError={onDownloadImportError}
          />
        </Grid>
        <Grid item>
          <ProductUploadOffersButtons
            isUploadSuccess={isUploadSuccess}
            onUploadFile={onUploadFile}
            onClose={onClose}
          />
        </Grid>
      </Grid>
      {isUploading && (
        <ContentLoader
          size={75}
          alpha
        />
      )}
    </>
  );
};

export default ProductUploadOffers;
