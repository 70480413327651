import { useContext } from 'react';
import ClientOrgActionsContext, { ClientOrgUserActionsContextType } from './context';

export const useClientOrgUserActions = (): ClientOrgUserActionsContextType => {
  return useContext<ClientOrgUserActionsContextType>(ClientOrgActionsContext);
};

export const useClientOrgUserActionsSelectors = (): ClientOrgUserActionsContextType['utils']['selectors'] => {
  return useClientOrgUserActions().utils.selectors;
};
