import { SportEnumOption } from '../../domain/model';
import { Nullable, UUID } from '../../domain/model/types';
import { PanelActions } from '../types';

type LifeCycleObject<S extends string> = { status: Nullable<S | SportEnumOption<S>> };

export type StatusActions<S extends string, A extends string> = { [name in S]: A[] } & { null?: A[] };

export type StatusScheme<S extends string, A extends string> = StatusActions<S, A>;

export type ActionsScheme<A extends string, S extends string> = { [name in A]: S[] };

export interface LifeCycleStatusScheme<S extends string, A extends string> {
  readonly description: string;
  readonly statusScheme: StatusScheme<S, A>;
  readonly actionsScheme: ActionsScheme<A, S>;
}

interface LifeCycleConditionCallbackProps<T, A> {
  readonly userId: UUID;
  //ключевой объект (текущее состояние)
  readonly obj: T;
  //ключевой объект (начальное состояние, опциональное применение для сложной логики)
  readonly initialObj?: Nullable<T>;
  readonly nextActions: A;
}

type LifeCycleConditionCallback<T, A> = (props: LifeCycleConditionCallbackProps<T, A>) => boolean;

export interface LifeCycleCondition<T, A> {
  readonly description: string;
  readonly algorithm: string[];
  readonly call: LifeCycleConditionCallback<T, A>;
}

interface LifeCycleActionsRuleCallbackProps<T, A extends string> {
  readonly userId: UUID;
  //ключевой объект (текущее состояние)
  readonly obj: T;
  //ключевой объект (начальное состояние, опциональное применение для сложной логики)
  readonly initialObj?: Nullable<T>;
  readonly nextActions: PanelActions<A>;
}

type LifeCycleActionsRuleCallback<T, A extends string> = (
  props: LifeCycleActionsRuleCallbackProps<T, A>
) => boolean | void;

export interface LifeCycleActionsRule<T, A extends string> {
  readonly description: string;
  readonly algorithm: string[];
  readonly call: LifeCycleActionsRuleCallback<T, A>;
}

export interface LifeCycleFactoryProps {
  readonly debug?: boolean;
}

export interface LifeCycleCommonRules<A extends string> {
  readonly nextActions: PanelActions<A>;
  readonly can: (action: A) => boolean;
  readonly debug?: string[];
}

export type LifeCycleRulesConfiguration<OT, S extends string, A extends string> = {
  readonly actionRules: LifeCycleActionsRule<OT, A>[];
  readonly statusScheme: StatusScheme<S, A>;
  readonly actionsScheme: ActionsScheme<A, S>;
};

interface BuildLifeCycleProps<OT, A extends string> {
  //набор возможных действий
  readonly allowedActions: PanelActions<A>;
  //ключевой объект (текущее состояние)
  readonly obj: Nullable<OT>;
  //ключевой объект (начальное состояние, опциональное применение для сложной логики)
  readonly initialObj?: Nullable<OT>;
}

export interface LifeCycle<
  OT,
  R extends LifeCycleCommonRules<A>,
  S extends string,
  A extends string,
  Config extends LifeCycleRulesConfiguration<OT, S, A> = LifeCycleRulesConfiguration<OT, S, A>
> {
  readonly configuration: Config;
  readonly build: (props: BuildLifeCycleProps<OT, A>) => R;
}

export interface LifeCycleProps<
  OT,
  S extends string,
  A extends string,
  Config extends LifeCycleRulesConfiguration<OT, S, A> = LifeCycleRulesConfiguration<OT, S, A>
> {
  readonly userId: UUID;
  readonly configuration: Config;
  readonly debug?: boolean;
}

export interface GetNextLifeCycleActionsProps<S extends string, A extends string> {
  readonly allowedActions: PanelActions<A>;
  readonly obj: LifeCycleObject<S>;
  readonly statusActions: StatusActions<S, A>;
}

/**
 * получение разрешённых действий по статусу объекта
 */
export const getNextLifeCycleActions = <S extends string, A extends string>(
  props: GetNextLifeCycleActionsProps<S, A>
): PanelActions<A> => {
  const { obj, allowedActions, statusActions } = props;

  const status = typeof obj.status === 'object' ? obj.status?.code ?? null : obj.status;

  // определяем доступные статусы после текущего
  const nextActions = status ? statusActions[status] : statusActions.null ?? [];

  // фильтруем действия по доступным следующим статусам
  return allowedActions.filter(action => {
    return nextActions.some(a => action.type === a);
  });
};

export const CommonLifeCycleInstance = <
  OT extends LifeCycleObject<S>,
  R extends LifeCycleCommonRules<A>,
  S extends string,
  A extends string
>({
  userId,
  configuration,
  debug,
}: LifeCycleProps<OT, S, A>): LifeCycle<OT, R, S, A> => {
  return {
    configuration,
    build: ({ allowedActions, obj, initialObj }) => {
      const result: LifeCycleCommonRules<A> = {
        nextActions: [],
        can: () => false,
      };

      if (!obj) {
        return result as R;
      }

      const debugInfo: string[] = [];

      const nextActions = getNextLifeCycleActions({
        obj,
        statusActions: configuration.statusScheme,
        allowedActions,
      });

      configuration.actionRules.map(rule => {
        const result = rule.call({ userId, obj, initialObj, nextActions });
        if (result) {
          debugInfo.push(`${rule.description}: ${rule.algorithm.join(' ')}`);
        }
      });

      const can = (action: A) => nextActions.some(a => a.type === action && !a.disabled);

      if (debug) {
        console.debug('Offer Lifecycle', debugInfo, nextActions);
      }

      return {
        nextActions,
        can,
        debug: debug ? debugInfo : undefined,
      } as R;
    },
  };
};
