import { MPFormInput } from '@ui-kit/input';
import ClientOrgColorBrandWrapper from '../../components/colorWrapper';
import { ClientOrgCreateFieldCommonProps } from '../../types';

const fieldName = 'colorBrand';

const ClientOrgCreateFieldColorBrand = (props: ClientOrgCreateFieldCommonProps<typeof fieldName>) => {
  const { value, validation, disabled, onChangeAttribute, onValidateAttribute } = props;

  return (
    <ClientOrgColorBrandWrapper color={value}>
      <MPFormInput
        label='Первый цвет'
        disabled={disabled}
        value={value}
        error={validation?.hasError}
        helperText={validation?.message}
        onChange={event => onChangeAttribute(fieldName, event.target.value)}
        onBlur={() => onValidateAttribute(fieldName)}
      />
    </ClientOrgColorBrandWrapper>
  );
};

export default ClientOrgCreateFieldColorBrand;
