import 'overlayscrollbars/css/OverlayScrollbars.css';
import { useCallback, useEffect, useState } from 'react';
import SelectCascadeComponent from './component';
import SearchCascade from './search';
import { CascadeItem, CascadeItemWithChildren, ChangeType } from './types';
import { findParentAndPush, transformListToTree } from './utils';

interface SelectCascadeProps<T> {
  readonly value?: CascadeItem<T>[];
  readonly loadOptionsMethod: (search?: string) => Promise<CascadeItem<T>[]>;
  readonly onSelect?: (item: CascadeItem<T> | undefined, all: CascadeItem<T>[]) => void;
}

function SelectCascade<T>(props: SelectCascadeProps<T>) {
  const { value, loadOptionsMethod, onSelect } = props;

  const [target, setTarget] = useState<{
    item: CascadeItem<T> | undefined;
    all: CascadeItem<T>[];
    type: ChangeType;
  } | null>(null);

  const [{ isFetching, items, source }, setData] = useState<{
    isFetching: boolean;
    items: CascadeItemWithChildren<T>[];
    source: CascadeItem<T>[];
  }>({
    isFetching: false,
    items: [],
    source: [],
  });

  useEffect(() => {
    if (value) {
      setTarget({
        item: value[value.length - 1],
        all: value,
        type: ChangeType.search,
      });
    }
  }, [value]);

  useEffect(() => {
    if (loadOptionsMethod) {
      setData({ isFetching: true, items: [], source: [] });
      loadOptionsMethod().then(data => {
        setData({
          isFetching: false,
          items: transformListToTree(data),
          source: data,
        });
      });
    }
  }, [loadOptionsMethod]);

  const onClickItem = useCallback(
    (id: string) => {
      const item = source.find(r => r.id === id);

      if (item) {
        const cascade: CascadeItem<T>[] = [item];
        findParentAndPush(item.parentId, cascade, source);

        setTarget({
          item,
          all: cascade.reverse(),
          type: ChangeType.click,
        });
      }
    },
    [findParentAndPush, source]
  );

  const onSubmit = useCallback(() => {
    if (target) onSelect?.(target.item, target.all);
  }, [target]);

  const onChangeSearch = useCallback((item: CascadeItem<T> | undefined, all: CascadeItem<T>[]) => {
    setTarget({
      item,
      all,
      type: ChangeType.search,
    });
  }, []);

  const searchValue =
    target?.item && target?.all
      ? {
          ...target.item,
          name: target.all.map(r => r.name).join(' > '),
        }
      : undefined;

  return (
    <>
      <SearchCascade
        source={source}
        onChange={onChangeSearch}
        value={searchValue}
      />

      {isFetching && 'Загрузка...'}

      <SelectCascadeComponent
        items={items}
        value={target?.all}
        changeType={target?.type ?? ChangeType.search}
        onClick={onClickItem}
        onSubmit={onSubmit}
      />
    </>
  );
}

export default SelectCascade;
