import { MenuItem, MenuItemProps } from '@mui/material';
import React from 'react';

// @ts-ignore
interface MPMenuItemProps<T> extends MenuItemProps {
  readonly value: T;
}

function MPMenuItemInternal<T>(props: MPMenuItemProps<T> & { forwardedRef?: any }) {
  const { forwardedRef, ...others } = props;

  return (
    // @ts-ignore
    <MenuItem
      ref={forwardedRef}
      {...others}
    />
  );
}

export const MPMenuItem = React.forwardRef<typeof MPMenuItemInternal, any>(
  <T,>(props: MPMenuItemProps<T>, ref: any) => (
    <MPMenuItemInternal<T>
      forwardedRef={ref}
      {...props}
    />
  )
) as typeof MPMenuItemInternal;
