import { createContext } from 'react';
import { RootState } from '../../../../data/store/store';
import { BookingOrder, BookingOrderStatus } from '../../../../domain/model/order';
import { Nullable, UUID } from '../../../../domain/model/types';

import { BookingOrderView, EBookingOrderActionType } from '../types';
import { GetBookingOrdersDownloadUrlProps } from '../utils/common';
import { ActionsChangeDialogStateType } from './store/slice';

type BookingOrderEntity = BookingOrder | BookingOrderView;

export interface BookingOrderActionsSelectors {
  readonly getIsBookingOrderProcessingSelector: (id: Nullable<UUID>) => (store: RootState) => boolean;
  readonly getIsBookingOrderExecutedActionsSelector: (
    id: Nullable<UUID>,
    ...actions: EBookingOrderActionType[]
  ) => (store: RootState) => Nullable<EBookingOrderActionType>;
  readonly isBookingOrdersAnyChangedSelector: (store: RootState) => number;
}

export type BookingOrderActionsContextType = {
  readonly onChangeDialogState: (props: ActionsChangeDialogStateType) => void;

  readonly onSaveInStatus: (status: BookingOrderStatus, order: BookingOrderView) => Promise<Nullable<BookingOrder>>;
  readonly onConfirm: (props: BookingOrderEntity) => void;
  readonly onRenew: (props: BookingOrderEntity) => void;
  readonly onGive: (props: BookingOrderEntity) => void;

  readonly onTryCancel: (data: BookingOrderEntity) => void;
  readonly onTryDownload: (data: GetBookingOrdersDownloadUrlProps) => void;

  readonly utils: {
    readonly selectors: BookingOrderActionsSelectors;
  };
};

const BookingOrderActionsContext = createContext<BookingOrderActionsContextType>({} as BookingOrderActionsContextType);

export default BookingOrderActionsContext;
