import ProductTableCell, { ProductTableCellCommonProps } from './index';
import {Nullable} from "../../../../../domain/model/types";
import {ProductCategoryAttribute} from "../../../../../domain/model/productOffer";

const ProductTableCellAttributeItem = (props: ProductTableCellCommonProps) => {
  const {
    productOffer: { attributes: values },
    column,
  } = props;

  const metaData = column?.metaData as Nullable<ProductCategoryAttribute>

  if (!metaData) {
    return null;
  }

  const value =  (values ?? []).find(val => val?.attribute.id === metaData.attribute.id)?.values?.[0]?.value || '-';

  return (
    <>
      <ProductTableCell
        {...props}
        value={value}
      />
    </>
  );
};

export default ProductTableCellAttributeItem;
