import BookingOfferActionsDialogApproveAdapter from './dialogApprove';
import BookingOfferActionsDialogArchiveAdapter from './dialogArchive';
import BookingOfferActionsDialogChangeModeratorAdapter from './dialogChangeModerator';
import BookingOfferActionsDialogDeleteAdapter from './dialogDelete';
import BookingOfferActionsDialogPauseAdapter from './dialogPause';
import BookingOfferActionsDialogPublishAdapter from './dialogPublish';
import BookingOfferActionsDialogRejectAdapter from './dialogReject';
import BookingOfferActionsDialogResumeAdapter from './dialogResume';
import BookingOfferActionsDialogRetrieveAdapter from './dialogrRetrieve';
import BookingOfferActionsDialogReturnToVerificationAdapter from './dialogrReturnToVerification';
import BookingOfferActionsDialogServiceManageAdapter from './dialogServiceManage';
import BookingOfferActionsDialogUnPublishAdapter from './dialogUnPublish';

const BookingOfferActionsDialogsAdapter = () => {
  return (
    <>
      <BookingOfferActionsDialogPublishAdapter />
      <BookingOfferActionsDialogRejectAdapter />
      <BookingOfferActionsDialogPauseAdapter />
      <BookingOfferActionsDialogDeleteAdapter />
      <BookingOfferActionsDialogChangeModeratorAdapter />
      <BookingOfferActionsDialogArchiveAdapter />
      <BookingOfferActionsDialogResumeAdapter />
      <BookingOfferActionsDialogRetrieveAdapter />
      <BookingOfferActionsDialogReturnToVerificationAdapter />
      <BookingOfferActionsDialogApproveAdapter />
      <BookingOfferActionsDialogUnPublishAdapter />
      <BookingOfferActionsDialogServiceManageAdapter />
    </>
  );
};

export default BookingOfferActionsDialogsAdapter;
