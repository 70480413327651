import BookingOrderTableCell, { BookingOrderTableCellCommonProps } from './index';

const BookingOrderTableCellObjectPartner = (props: BookingOrderTableCellCommonProps) => {
  const {
    bookingOrder: { partner },
  } = props;

  const value = partner?.name ?? '';

  return (
    <BookingOrderTableCell
      {...props}
      value={value}
    />
  );
};

export default BookingOrderTableCellObjectPartner;
