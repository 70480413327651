import { DialogContent } from '@mui/material';
import moment from 'moment-timezone';
import { EServiceOrderByDateType } from '../../../../../domain/model/enums';
import { EDateFormat } from '../../../../../domain/model/formats';
import { BookingDateSlot } from '../../../../../domain/model/order';
import { Nullable } from '../../../../../domain/model/types';
import BookingOrderEditItemInfoByDates from './byDates';
import BookingOrderEditItemInfoByNone from './byNone';
import BookingOrderEditItemInfoByPeriod from './byPeriod';
import { StyledDialog } from './controls';

type BookingInfo = {
  readonly orderByDateType: EServiceOrderByDateType;
  readonly qty?: Nullable<number>;
  readonly items?: BookingDateSlot[];
};

interface BookingOrderEditItemDialogProps {
  readonly bookingInfo: BookingInfo;
  readonly onApply: (bookingInfo: BookingInfo) => void;
  readonly onClose: () => void;
}

const BookingOrderEditItemInfoDialog = (props: BookingOrderEditItemDialogProps) => {
  const { bookingInfo, onApply, onClose } = props;

  return (
    <StyledDialog
      keepMounted
      open={true}
    >
      <DialogContent>
        {bookingInfo.orderByDateType === EServiceOrderByDateType.Days && (
          <BookingOrderEditItemInfoByDates
            value={
              bookingInfo.items?.map(item => ({
                date: moment(item.startDate).toDate(),
                quantity: item.qty ?? 0,
              })) ?? []
            }
            onApply={value =>
              onApply({
                orderByDateType: EServiceOrderByDateType.Days,
                items: value.map(({ date, quantity }) => ({
                  startDate: moment(date).format(EDateFormat.Server),
                  endDate: moment(date).format(EDateFormat.Server),
                  qty: quantity,
                })),
              })
            }
            onCancel={onClose}
          />
        )}
        {bookingInfo.orderByDateType === EServiceOrderByDateType.Period && (
          <BookingOrderEditItemInfoByPeriod
            value={{
              period: {
                startDate: bookingInfo.items?.[0]?.startDate ? moment(bookingInfo.items[0].startDate).toDate() : null,
                endDate: bookingInfo.items?.[0]?.endDate ? moment(bookingInfo.items[0].endDate).toDate() : null,
              },
              quantity: bookingInfo.items?.[0]?.qty ?? 1,
            }}
            onApply={({ period, quantity }) =>
              onApply({
                orderByDateType: EServiceOrderByDateType.Period,
                items: [
                  {
                    startDate: moment(period.startDate).format(EDateFormat.Server),
                    endDate: moment(period.endDate).format(EDateFormat.Server),
                    qty: quantity,
                  },
                ],
              })
            }
            onCancel={onClose}
          />
        )}
        {bookingInfo.orderByDateType === EServiceOrderByDateType.None && (
          <BookingOrderEditItemInfoByNone
            value={bookingInfo.qty ?? 1}
            onApply={quantity =>
              onApply({
                orderByDateType: EServiceOrderByDateType.None,
                qty: quantity,
              })
            }
            onCancel={onClose}
          />
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default BookingOrderEditItemInfoDialog;
