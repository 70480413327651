import styled from '@emotion/styled';
import { ToggleButton } from '@mui/material';

export const ErrorToggleButton = styled(ToggleButton)`
  &.MuiToggleButton-root {
    color: ${p => p.theme.palette.error.main};

    &:hover {
      background-color: ${p => p.theme.palette.error.alpha90};
    }

    &.Mui-selected {
      color: ${p => p.theme.palette.text.onPrimary};
      background-color: ${p => p.theme.palette.error.main};
      border-color: ${p => p.theme.palette.error.alpha90};
    }
  }
`;
