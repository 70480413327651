import { UserAccessPartitionCorpActivations } from '@/domain/model/accessMatrix';
import { CorpOfferActivation } from '@/domain/model/activation';
import { Nullable } from '@/domain/model/types';
import { EPanelActionPosition, PanelAction, PanelActions } from '../../../types';
import { CorpActivationLifeCycle } from '../lifecycle/types';
import { CorpActivationActionTableType, ECorpActivationActionType } from '../types';

const getCorpActivationActionName = (type: ECorpActivationActionType): string => {
  switch (type) {
    case ECorpActivationActionType.Give:
      return 'Выдано';
    case ECorpActivationActionType.UnGive:
      return 'Не выдано';
  }
};

export type CorpActivationActionsByPermissions = UserAccessPartitionCorpActivations;

export const getCorpActivationActionsConfigByPermissions = (
  props: CorpActivationActionsByPermissions
): PanelActions<ECorpActivationActionType> => {
  const { give, unGive } = props;

  const actions: PanelActions<ECorpActivationActionType> = [];

  let onGive: Nullable<PanelAction<ECorpActivationActionType>> = null;
  let onUnGive: Nullable<PanelAction<ECorpActivationActionType>> = null;

  if (give) {
    onGive = {
      type: ECorpActivationActionType.Give,
      disabled: false,
      label: getCorpActivationActionName(ECorpActivationActionType.Give),

      position: [EPanelActionPosition.Default],
    };
  }
  if (unGive) {
    onUnGive = {
      type: ECorpActivationActionType.UnGive,
      disabled: false,
      label: getCorpActivationActionName(ECorpActivationActionType.UnGive),

      position: [EPanelActionPosition.Default],
    };
  }

  if (onGive) actions.push(onGive);
  if (onUnGive) actions.push(onUnGive);

  return actions;
};

export const getCorpActivationTableActions = (
  lifecycle: CorpActivationLifeCycle,
  activation: CorpOfferActivation,
  allowedActions: PanelActions<CorpActivationActionTableType>
) => {
  return lifecycle.build({ obj: activation, allowedActions })
    .nextActions as PanelActions<CorpActivationActionTableType>;
};
