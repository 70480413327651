import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getPartnerLogo } from '../../../partner/utils';
import { useContextHandlers } from '../hooks/useContextHandlers';
import CorpOfferTableNotifier from '../notifier';
import { corpOffersNotifierInfoGet } from '../store/selectors';

export const CorpOffersTableNotifierAdapter = () => {
  const notifierInfo = useSelector(corpOffersNotifierInfoGet);
  const partnerLogo = notifierInfo?.corpOffer ? getPartnerLogo(notifierInfo.corpOffer.partner) : null;

  const handlers = useContextHandlers();

  return useMemo(
    () =>
      notifierInfo ? (
        <CorpOfferTableNotifier
          corpOffer={notifierInfo.corpOffer}
          partnerLogo={partnerLogo}
          type={notifierInfo.type}
          onRead={handlers.onNotificationRead}
        />
      ) : null,
    [notifierInfo?.corpOffer, partnerLogo, notifierInfo?.type, handlers.onNotificationRead]
  );
};
