import { useMemo, useState } from 'react';
import { Fetchable } from '../../../../../../../data/store/types';
import { PartnerShort } from '../../../../../../../domain/model/partner';
import { Nullable, UUID } from '../../../../../../../domain/model/types';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../../../../components/common/table';
import DataTableCellTextFormat from '../../../../../../components/common/table/cell/textFormat';
import TablePagination from '../../../../../../components/common/table/pagination';
import { PaginationSize } from '../../../../../../types';
import { EPartnerTableColumn, PartnerActionCall } from '../../../types';
import { PartnerCollectionColumn } from '../utils';
import PartnerTableCellName from './cell/name';
import PartnerTableCellSortIndex from './cell/sortIndex';
import { RemoveIcon, Wrapper } from './controls';

interface PartnerCollectionTableProps<T extends PartnerShort> {
  readonly metadata: DataTableMetadata<PartnerCollectionColumn>;
  readonly sortIndexInfo: Fetchable & {
    readonly fetch: () => void;
    readonly max: number;
  };
  readonly partners: T[];
  readonly onPartnerAction?: ((call: PartnerActionCall<T>) => void) | false;
  readonly onRemove?: (id: UUID) => void;
}

const minPaginationSize: Extract<PaginationSize, 5> = 5;

const PartnerCollectionTable = <T extends PartnerShort>(props: PartnerCollectionTableProps<T>) => {
  const { metadata, sortIndexInfo, partners, onPartnerAction, onRemove } = props;

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<PaginationSize>(10);

  const [activePartner, setActivePartner] = useState<Nullable<T>>(null);
  const [editablePartner, setEditablePartner] = useState<Nullable<T>>(null);

  const pageCount = useMemo<number>(() => Math.ceil(partners.length / pageSize), [partners, pageSize]);

  const partnersDisplay = useMemo<T[]>(
    () => partners.slice((page - 1) * pageSize, page * pageSize),
    [partners, page, pageSize]
  );

  const rows: DataTableRow<T, PartnerCollectionColumn>[] = partnersDisplay?.map((partner, i) => ({
    [EPartnerTableColumn.SortIndex]: onPartnerAction ? (
      <PartnerTableCellSortIndex<T>
        activePartner={activePartner}
        editablePartner={editablePartner}
        onPartnerAction={onPartnerAction}
        setEditablePartner={setEditablePartner}
        sortIndexInfo={sortIndexInfo}
        sortIndex={(page - 1) * pageSize + i + 1}
        partner={partner}
      />
    ) : (
      <DataTableCellTextFormat>{(page - 1) * pageSize + i + 1}</DataTableCellTextFormat>
    ),
    [EPartnerTableColumn.Name]: <PartnerTableCellName name={partner.name} />,
    data: partner,
  }));

  return (
    <Wrapper>
      <DataTable<T, EPartnerTableColumn>
        metadata={metadata}
        rows={rows}
        rowActions={!!onRemove}
        onRowHover={(event, cell, { data }, hover) => setActivePartner(hover ? data : null)}
        getRowActions={data => (
          <RemoveIcon
            color='disabled'
            fontSize='small'
            onClick={() => onRemove?.(data.id)}
          />
        )}
      />
      {partners.length > minPaginationSize && (
        <TablePagination
          dense
          page={page}
          pageSize={pageSize}
          pageCount={pageCount}
          objectsCount={partners.length}
          objectsLabel=''
          onChangePageSize={setPageSize}
          onChangePage={setPage}
        />
      )}
    </Wrapper>
  );
};

export default PartnerCollectionTable;
