import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ProductOffer } from '../../../../../../domain/model/productOffer';
import { Nullable } from '../../../../../../domain/model/types';
import ConfirmDialog from '../../../../../components/common/dialogs/confirm';
import ContentLoader from '../../../../../components/common/loader';
import { MPButton } from '../../../../../theme/ui-kit/button';
import { MPFormTextArea } from '../../../../../theme/ui-kit/input';
import { CardProduct } from '../../../components/cardProduct';
import productServices from '../../../services';
import { EOfferStatus } from '../../../../../../domain/model/enums';

interface ProductPauseDialogProps {
  readonly productOffer: ProductOffer;
  readonly isExecuting: boolean;
  readonly pauseWithComment: boolean;
  readonly pauseDesk: boolean;
  readonly onExecute: (comment: Nullable<string>) => void;
  readonly onClose: () => void;
}

const ProductPauseDialog = (props: ProductPauseDialogProps) => {
  const { productOffer, isExecuting, pauseWithComment, pauseDesk, onExecute, onClose } = props;

  const [variantNames, setVariantNames] = useState<string[]>([]);

  const [localValue, setLocalValue] = useState<Nullable<string>>(null);
  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);
  const [validation, setValidation] = useState<Nullable<string>>(null);

  const onSetLocalValue = (newValue: string) => {
    if (validateOnChange) {
      setValidation(newValue.length === 0 ? 'Укажите причину приостановки' : null);
    }
    setLocalValue(newValue.length === 0 ? null : newValue);
  };

  const onInternalChange = () => {
    if (!pauseWithComment || (pauseWithComment && localValue)) {
      onExecute(localValue);
    } else {
      setValidation('Укажите причину приостановки');
      setValidateOnChange(true);
    }
  };

  useEffect(() => {
    if (pauseDesk && productOffer.productDesk?.id) {
      productServices.productDesk
        .offers({ id: productOffer.productDesk.id, sort: 'variantName,asc', statuses: [EOfferStatus.Active] })
        .then(offers => {
          setVariantNames(offers.map(offer => offer.variantName ?? '-'));
        });
    } else {
      setVariantNames([productOffer.variantName ?? '-']);
    }
  }, []);

  return (
    <ConfirmDialog
      open
      onClose={onClose}
      title={pauseDesk ? 'Приостановка карточки товара' : 'Приостановка варианта товара'}
      dialogButtons={
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              disabled={isExecuting}
              onClick={onInternalChange}
            >
              Приостановить
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              disabled={isExecuting}
              variant='outlined'
              fullWidth={false}
              onClick={onClose}
            >
              Отмена
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        spacing={2}
        wrap='nowrap'
        direction='column'
      >
        {isExecuting && <ContentLoader />}
        <Grid item>
          <CardProduct
            name={productOffer.name}
            salePercent={productOffer.salePercent}
            price={productOffer.price}
            originalPrice={productOffer.originalPrice}
            image={productOffer.images?.[0]?.path}
          />
        </Grid>
        <Grid item>
          <Typography>{pauseDesk ? 'Будут приостановлены варианты:' : 'Будет приостановлен вариант:'}</Typography>
          <Typography>
            {variantNames.map((variantName, index) => (
              <li key={index}>{variantName}</li>
            ))}
          </Typography>
        </Grid>
        {pauseWithComment && (
          <Grid item>
            <MPFormTextArea
              rows={3}
              label='Комментарий'
              value={localValue}
              margin='none'
              disabled={isExecuting}
              error={!!validation}
              helperText={validation}
              onChange={event => onSetLocalValue(event.target.value)}
            />
          </Grid>
        )}
      </Grid>
    </ConfirmDialog>
  );
};

export default ProductPauseDialog;
