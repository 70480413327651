import { Nullable, UUID } from '@/domain';
import { Location } from 'history';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import rootRouting from '../../../routing';
import NotFoundScreen from '../../screens/notFound';
import CustomerScreen from '../../screens/user/customer';
import CustomersScreen from '../../screens/users/customers';
import CustomerManagementByPartnerEntry, {
  getCustomerManagementByPartnerRootRouteDefinition,
} from './management/byPartner/entry';
import CustomerManagementCommonEntry, { getCustomerManagementRootRouteDefinition } from './management/common/entry';
import { ECustomerTableTab } from './table/utils';

const routing = {
  root: `${rootRouting.customers}`,
  list: `${rootRouting.customers}`,
  details: `${rootRouting.customers}/:id`,
};

export interface CustomersLocationState {
  readonly guid: UUID;
}

export enum ECustomerUrlParam {
  Tab = 'tab',
}

interface GetCustomersTableRouteProps {
  readonly tab?: ECustomerTableTab;
  readonly guid?: Nullable<UUID>;
}

interface GetCustomerDetailsRouteProps {
  readonly id: UUID;
}

export const getCustomerDetailsRoute = ({ id }: GetCustomerDetailsRouteProps) => {
  return `${routing.details.replace(':id', id)}`;
};

export const getCustomersTableRoute = ({
  tab,
  guid,
}: GetCustomersTableRouteProps): Location<CustomersLocationState> => {
  const searchParams = new URLSearchParams();
  if (tab) searchParams.append(ECustomerUrlParam.Tab, tab);

  const newGuid = guid ?? uuidv4();

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return {
    pathname: routing.list,
    search,
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

const CustomersEntry = () => {
  return (
    <Switch>
      <Route
        path={getCustomerManagementByPartnerRootRouteDefinition()}
        component={CustomerManagementByPartnerEntry}
      />
      <Route
        path={getCustomerManagementRootRouteDefinition()}
        component={CustomerManagementCommonEntry}
      />
      <Route
        exact
        path={routing.list}
        component={CustomersScreen}
      />
      <Route
        exact
        path={routing.details}
        component={CustomerScreen}
      />
      <Redirect
        exact
        from={routing.root}
        to={routing.list}
      />

      <Route component={NotFoundScreen} />
    </Switch>
  );
};

export default CustomersEntry;
