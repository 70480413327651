import store from '@/data/store/store';
import { ESortDirection } from '@/domain/model/enums';
import { addSearchParamToLocation } from '@/routing/globalRouting';
import { EAnalyticsQueryUrlParam } from '@features/analytics/query/types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { PaginationSize } from '../../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../../utils/filtering';
import { AnalyticsQueriesTableFilterEditStrategy } from '../../filterUtils';
import { analyticsQueriesGuidSelector } from '../store/selectors';
import {
  analyticsQueriesDataReset,
  analyticsQueriesNeedRefreshWatcherIncrement,
  analyticsQueriesSetFilter,
  analyticsQueriesSetPage,
  analyticsQueriesSetPageSize,
  analyticsQueriesSetSort,
  analyticsQueriesSortReset,
} from '../store/slice';
import { EAnalyticsQueriesTableTab } from '../utils';

type UseAnalyticsQueriesTableHandlersProps = {
  readonly tab: EAnalyticsQueriesTableTab;
};

export type UseAnalyticsQueriesTable = {
  readonly onRefresh: () => void;
  readonly onResetSort: () => void;
  readonly onChangePage: (page: number) => void;
  readonly onChangePageSize: (pageSize: PaginationSize) => void;
  readonly onChangeTab: (newTab: EAnalyticsQueriesTableTab) => void;
  readonly onChangeFilter: (strategies: AnalyticsQueriesTableFilterEditStrategy[]) => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
};

export const useAnalyticsQueriesTableHandlers = ({
  tab,
}: UseAnalyticsQueriesTableHandlersProps): UseAnalyticsQueriesTable => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const getAnalyticsQueriesGuidFromState = useCallback((): Nullable<UUID> => {
    const state = store.getState();
    return analyticsQueriesGuidSelector(state);
  }, [store]);

  const onRefresh = useCallback(() => dispatch(analyticsQueriesNeedRefreshWatcherIncrement()), [dispatch]);

  const onChangeTab = useCallback(
    (newTab: EAnalyticsQueriesTableTab) => {
      if (newTab !== tab) {
        const guid = getAnalyticsQueriesGuidFromState();
        dispatch(analyticsQueriesDataReset());
        history.replace(
          addSearchParamToLocation({
            location,
            param: EAnalyticsQueryUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [dispatch, history, location, tab, getAnalyticsQueriesGuidFromState]
  );

  const onChangeFilter = useCallback(
    (strategies: AnalyticsQueriesTableFilterEditStrategy[]) => {
      dispatch(analyticsQueriesSetFilter(getDataFilterValuesByStrategies(strategies)));
    },
    [dispatch]
  );

  const onResetSort = useCallback(() => dispatch(analyticsQueriesSortReset()), [dispatch]);

  const onChangePage = useCallback(
    (page: number) => {
      dispatch(analyticsQueriesSetPage({ pageNumber: page }));
    },
    [dispatch]
  );

  const onChangePageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(analyticsQueriesSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onChangeSort = useCallback(
    (name: string, direction: ESortDirection) => {
      dispatch(
        analyticsQueriesSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  return {
    onChangeTab,
    onResetSort,
    onChangeSort,
    onChangeFilter,
    onChangePage,
    onChangePageSize,
    onRefresh,
  };
};
