import { Checkbox, Typography } from '@mui/material';
import { DataFilterStrategyBooleanFlag } from '../../../../../../domain/model/filter';
import { Nullable } from '../../../../../../domain/model/types';
import { DataFilterEditItemProps } from '../index';
import { StyledFormControlLabel } from './controls';

interface DataFilterEditItemBooleanFlagProps<T extends string> extends DataFilterEditItemProps<T, Nullable<true>> {
  readonly strategy: DataFilterStrategyBooleanFlag<T>;
}

function DataFilterEditItemBooleanFlag<T extends string>({
  strategy,
  onChange,
}: DataFilterEditItemBooleanFlagProps<T>) {
  const { label, value, readOnly } = strategy;

  return (
    <StyledFormControlLabel
      control={
        <Checkbox
          color='primary'
          checked={value ?? false}
          disabled={readOnly}
          onChange={(event, checked) => onChange(strategy, checked ? true : null)}
        />
      }
      label={<Typography color='textSecondary'>{label}</Typography>}
    />
  );
}

export default DataFilterEditItemBooleanFlag;
