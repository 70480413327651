import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TradeOfferTableCellCommonProps } from '../../components/tableCell';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { tradeOffersTradeOfferByIndexSelector, tradeOffersUnwatchedByIndexSelector } from '../store/selectors';

export type TradeOffersTableCellAdapterProps = {
  readonly index: number;
  readonly component: FC<TradeOfferTableCellCommonProps>;
};

const TradeOffersTableCellAdapter = (props: TradeOffersTableCellAdapterProps) => {
  const { index, component: Component } = props;

  const handlers = useContextHandlers();
  const tradeOffer = useSelector(tradeOffersTradeOfferByIndexSelector(index));
  const isUnwatched = tradeOffer ? useSelector(tradeOffersUnwatchedByIndexSelector(index)) : false;
  const isDisabled = tradeOffer && !isUnwatched ? handlers.isTradeOfferNotModeratedByMe(tradeOffer) : false;

  return useMemo(
    () =>
      tradeOffer ? (
        <Component
          tradeOffer={tradeOffer}
          isDisabled={isDisabled}
          isTextAccent={isUnwatched}
        />
      ) : null,
    [Component, tradeOffer, isDisabled, isUnwatched]
  );
};

export default TradeOffersTableCellAdapter;
