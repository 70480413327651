import { useCallback } from 'react';
import { CmsBanner } from '../../../../../../../domain/model/cms';
import { ECmsBannerLinkObjectType, EOfferType } from '../../../../../../../domain/model/enums';
import { OnChangeObjectAttribute } from '../../../../../../types';
import { CmsLinkedObjectBannerType, CmsLinkedObjectBannerTypes } from '../../../../types/banner';
import { getCmsCollectionLinkObjectTypeByOfferType } from '../../../utils';

type UseCmsContainerCreateBannerChange = {
  readonly onChangeBannerAttribute: OnChangeObjectAttribute<CmsBanner>;
  readonly onChangeBannerLinkedObject: (newCmsBanner: CmsLinkedObjectBannerTypes) => void;
};

interface useCmsContainerCreateBannerChangeProps {
  readonly offerType: EOfferType;
  readonly cmsBanner: CmsLinkedObjectBannerType;
  readonly onChangeBanner: (data: CmsLinkedObjectBannerType) => void;
}

const useCmsContainerCreateBannerChange = (
  props: useCmsContainerCreateBannerChangeProps
): UseCmsContainerCreateBannerChange => {
  const { offerType, cmsBanner, onChangeBanner } = props;

  const onChangeBannerAttribute: OnChangeObjectAttribute<CmsBanner> = useCallback(
    (name, value) => {
      if (name === 'linkObjectType') {
        //если меняем тип контента за баннером, то формируем пустой вложенный объект нужного типа
        switch (value) {
          case ECmsBannerLinkObjectType.Collection:
            onChangeBanner({
              ...cmsBanner,
              linkObjectId: null,
              linkObjectType: ECmsBannerLinkObjectType.Collection,
              linkedObject: {
                linkObjectType: getCmsCollectionLinkObjectTypeByOfferType(offerType),
                linkedObject: [],
              },
            });
            break;
          case ECmsBannerLinkObjectType.TradeOffer:
            onChangeBanner({
              ...cmsBanner,
              linkObjectId: null,
              linkObjectType: ECmsBannerLinkObjectType.TradeOffer,
              linkedObject: null,
            });
            break;
          case ECmsBannerLinkObjectType.CorpOffer:
            onChangeBanner({
              ...cmsBanner,
              linkObjectId: null,
              linkObjectType: ECmsBannerLinkObjectType.CorpOffer,
              linkedObject: null,
            });
            break;
          case ECmsBannerLinkObjectType.TradeOfferCategory:
            onChangeBanner({
              ...cmsBanner,
              linkObjectId: null,
              linkObjectType: ECmsBannerLinkObjectType.TradeOfferCategory,
              linkedObject: null,
            });
            break;
          case ECmsBannerLinkObjectType.CorpOfferCategory:
            onChangeBanner({
              ...cmsBanner,
              linkObjectId: null,
              linkObjectType: ECmsBannerLinkObjectType.CorpOfferCategory,
              linkedObject: null,
            });
            break;
          case ECmsBannerLinkObjectType.Link:
            onChangeBanner({
              ...cmsBanner,
              linkObjectId: null,
              linkObjectType: ECmsBannerLinkObjectType.Link,
              linkedObject: null,
            });
            break;
          case null:
            break;
          default:
            throw new Error(`Not implemented yet: ${value}`);
        }
      } else {
        onChangeBanner({ ...cmsBanner, [name]: value });
      }
    },
    [offerType, cmsBanner, onChangeBanner]
  );

  const onChangeBannerLinkedObject = useCallback(
    (newCmsBanner: CmsLinkedObjectBannerTypes) => {
      if (newCmsBanner.linkObjectType === cmsBanner.linkObjectType) {
        const temp: typeof cmsBanner = {
          ...cmsBanner,
          linkedObject: newCmsBanner.linkedObject as any,
        };
        onChangeBanner(temp);
      } else {
        console.error(
          `Inner cmsBanner linkObjectType '${cmsBanner.linkObjectType}' and '${newCmsBanner.linkObjectType}' not equal`
        );
      }
    },
    [cmsBanner]
  );

  return {
    onChangeBannerAttribute,
    onChangeBannerLinkedObject,
  };
};

export default useCmsContainerCreateBannerChange;
