import { useSelector } from 'react-redux';
import { PartnerShortDraft } from '../../../../../domain/model/partner';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import {
  EPartnerActionType,
  EPartnerTableColumn,
  PartnerActions,
  PartnerActionTableType,
} from '../../../general/partner/types';
import { EPartnerTableFilterItem } from '../../filterUtils';
import PartnerTable from '../component';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  partnersActionsSelector,
  partnersFilterSelector,
  partnersGuidSelector,
  partnersPartnersSelector,
  partnersSortColumnSelector,
  partnersSortDirectionSelector,
} from '../store/selectors';
import { PartnerActionTableCall, PartnerTableActionProcess } from '../utils';

interface PartnersTableAdapterProps {
  readonly filterAdapter: DataFilterAdapter<EPartnerTableColumn, EPartnerTableFilterItem>;
  readonly getActions: (partner: PartnerShortDraft) => PartnerActions<PartnerActionTableType>;
}

const PartnersTableAdapter = (props: PartnersTableAdapterProps) => {
  const { filterAdapter, getActions } = props;

  const handlers = useContextHandlers();
  const { onPartnerClick } = useContextConfig();

  const guid = useSelector(partnersGuidSelector);
  const partners = useSelector(partnersPartnersSelector);
  const actionProcesses = useSelector(partnersActionsSelector);

  const sortColumn = useSelector(partnersSortColumnSelector);
  const sortDirection = useSelector(partnersSortDirectionSelector);
  const filter = useSelector(partnersFilterSelector);

  const onActionClick = (call: PartnerActionTableCall) => {
    const { action, partner } = call;
    switch (action) {
      case EPartnerActionType.Activate:
        handlers.onPartnerActivate(partner);
        break;
      case EPartnerActionType.Deactivate:
        handlers.onPartnerDeactivate(partner);
        break;
      case EPartnerActionType.ReturnToVerification:
        handlers.onPartnerReturnToVerification(partner);
        break;
      case EPartnerActionType.Delete:
        handlers.onPartnerDelete(partner);
        break;
    }
  };

  const getPartnerActionProcesses = (partner: PartnerShortDraft): PartnerTableActionProcess[] => {
    return actionProcesses.filter(p => p.id === partner.id) ?? [];
  };

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  return (
    <PartnerTable
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      partners={partners}
      getActions={getActions}
      onPartnerAction={onActionClick}
      onRequestSort={handlers.onChangeSort}
      onClick={onPartnerClick}
      getActionProcesses={getPartnerActionProcesses}
      onChangeMetadata={onChangeMetadata}
    />
  );
};

export default PartnersTableAdapter;
