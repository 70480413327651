import ClientOrgUserTableCell, { ClientOrgUserTableCellCommonProps } from './index';

const ClientOrgUserTableCellPhone = (props: ClientOrgUserTableCellCommonProps) => {
  const {
    clientOrgUser: { phone },
  } = props;

  const value = phone ?? '-';

  return (
    <ClientOrgUserTableCell
      {...props}
      value={value}
    />
  );
};

export default ClientOrgUserTableCellPhone;
