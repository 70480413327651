import Api from '../../../../data/api';
import { ApiCancellable } from '../../../../data/api/types';
import { Pageable } from '../../../../domain/model';
import { Chat } from '../../../../domain/model/chat';
import { Nullable } from '../../../../domain/model/types';
import { PaginationSize } from '../../../types';
import { ChatTableFilterValues, EChatTableFilterItem } from '../table/filterUtils';

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string>;
  };
  readonly filter: ChatTableFilterValues;
  readonly pageNumber: number;
};

export type ChatCommonService = {
  readonly all: (props: AllProps) => Promise<Pageable<Chat>>;
};

const service: ChatCommonService = {
  all: async ({ search, filter, pageNumber, signal }) => {
    const { pageSize, sort } = search;

    const name = filter[EChatTableFilterItem.Name]?.value;
    const chatTypes = filter[EChatTableFilterItem.ChatTypes]?.value;

    const { data: response } = await Api.chat.all({
      pageSize,
      sort,
      page: pageNumber,
      types: chatTypes,
      signal,
      name,
    });

    const { content: data, totalElements: totalCount, totalPages: pageCount, number: page } = response;
    return { data, totalCount, pageCount, pageNumber: page };
  },
};

export default service;
