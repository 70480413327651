import { UUID } from '../../../../domain/model/types';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import TableCommonLayout from '../../../layouts/tableCommon/container';
import { PartnerEmployeeTableBehaviorAdapter } from './adapters/behavior';
import PartnerEmployeeTableFilterAdapter from './adapters/filter';
import PartnerEmployeeTableHeaderAdapter from './adapters/header';
import PartnerEmployeeTableAdapter from './adapters/table';
import { PartnerEmployeeTableTabsAdapter } from './adapters/tabs';
import { EmployeeTableContext } from './context';
import PartnerEmployeeTableDialogs from './dialogs';
import { EPartnerEmployeesFilterItem } from './filterUtils';
import PartnerEmployeeTableFooterContainer from './footerContainer';
import usePartnerEmployeeTableActions from './hooks/useActions';
import { useContextConfig } from './hooks/useContextConfig';
import { useEmployeeTableHandlers } from './hooks/useEmployeeTable';
import { EPartnerEmployeeTableColumn, EPartnerEmployeeTableTab } from './utils';

interface PartnerEmployeeTableContainerProps {
  readonly guid: UUID;
  readonly tab: EPartnerEmployeeTableTab;
  readonly filterAdapter: DataFilterAdapter<EPartnerEmployeeTableColumn, EPartnerEmployeesFilterItem>;
  readonly canCreate?: boolean;
  readonly canChangeStatus?: boolean;
  readonly canChangePartnerOwner?: boolean;
}

const PartnerEmployeeTableContainer = (props: PartnerEmployeeTableContainerProps) => {
  const { guid, tab, filterAdapter, canCreate = false, canChangeStatus = false, canChangePartnerOwner = false } = props;

  const { partnerOwner } = useContextConfig();

  const handlers = useEmployeeTableHandlers({tab});

  const { getPartnerEmployeeActions } = usePartnerEmployeeTableActions({
    tab,
    canCreate,
    canActivate: canChangeStatus,
    canDeactivate: canChangeStatus,
    canChangeResponsible: canChangePartnerOwner,
    responsibleId: partnerOwner?.id!,
  });

  return (
    <EmployeeTableContext.Provider value={handlers}>
      <PartnerEmployeeTableBehaviorAdapter
        tab={tab}
        guid={guid}
      />
      <TableCommonLayout
        header={<PartnerEmployeeTableHeaderAdapter canCreate={canCreate} />}
        filter={<PartnerEmployeeTableFilterAdapter filterAdapter={filterAdapter} />}
        tabs={<PartnerEmployeeTableTabsAdapter tab={tab} />}
        table={
          <PartnerEmployeeTableAdapter
            filterAdapter={filterAdapter}
            getActions={getPartnerEmployeeActions}
          />
        }
        footer={PartnerEmployeeTableFooterContainer}
      >
        <PartnerEmployeeTableDialogs />
      </TableCommonLayout>
    </EmployeeTableContext.Provider>
  );
};

export default PartnerEmployeeTableContainer;
