import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataTreeItem } from '../../../../../../domain/model';
import { BookingServiceCategory } from '../../../../../../domain/model/booking';
import { EServiceOrderByDateType } from '../../../../../../domain/model/enums';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingServiceCategoriesEditDialogsStateSelector } from '../store/selectors';
import { BookingServiceCategoriesEditDialogAddAdapter } from './dialogAdd';
import { BookingServiceCategoriesEditDialogServiceChangeDateAdapter } from './dialogChangeDate';
import { BookingServiceCategoriesEditDialogDeleteAdapter } from './dialogDelete';
import { BookingServiceCategoriesEditDialogEditAdapter } from './dialogEdit';

export const BookingServiceCategoriesEditDialogsAdapter = () => {
  const handlers = useContextHandlers();

  const dialogs = useSelector(bookingServiceCategoriesEditDialogsStateSelector);

  const [isLoading, setIsLoading] = useState(false);

  const onAdd = useCallback(
    async (bookingServiceCategory: BookingServiceCategory) => {
      setIsLoading(true);
      await handlers
        .onCreate(bookingServiceCategory)
        .then(() => {
          handlers.onChangeDialogState('add', null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [handlers.onChangeDialogState, handlers.onCreate]
  );

  const onCloseAdd = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('add', null);
  }, [handlers.onChangeDialogState]);

  const onDelete = useCallback(
    async (bookingServiceCategory: BookingServiceCategory) => {
      setIsLoading(true);
      await handlers
        .onDelete(bookingServiceCategory)
        .then(() => {
          handlers.onChangeDialogState('delete', null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [handlers.onChangeDialogState]
  );

  const onCloseDelete = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('delete', null);
  }, [handlers.onChangeDialogState]);

  const onModify = useCallback(
    async (bookingServiceCategory: BookingServiceCategory) => {
      setIsLoading(true);
      await handlers
        .onEdit(bookingServiceCategory)
        .then(() => {
          handlers.onChangeDialogState('modify', null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [handlers.onChangeDialogState, handlers.onEdit]
  );

  const onServiceChange = useCallback(
    async (service: DataTreeItem<BookingServiceCategory>) => {
      await handlers.onRemoveOrderByDateType(service.data, service.id as EServiceOrderByDateType).then(() => {
        handlers.onChangeDialogState('serviceChange', null);
      });
    },
    [handlers.onChangeDialogState, handlers.onRemoveOrderByDateType]
  );

  const onCloseChangeService = useCallback(() => {
    handlers.onChangeDialogState('serviceChange', null);
  }, [handlers.onChangeDialogState]);

  const onCloseModify = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('modify', null);
  }, [handlers.onChangeDialogState]);

  return (
    <>
      {dialogs.add && (
        <BookingServiceCategoriesEditDialogAddAdapter
          isLoading={isLoading}
          onAdd={onAdd}
          onClose={onCloseAdd}
        />
      )}
      {dialogs.delete && (
        <BookingServiceCategoriesEditDialogDeleteAdapter
          isLoading={isLoading}
          data={dialogs.delete.data}
          onDelete={onDelete}
          onClose={onCloseDelete}
        />
      )}
      {dialogs.modify && (
        <BookingServiceCategoriesEditDialogEditAdapter
          isLoading={isLoading}
          data={dialogs.modify.data}
          onEdit={onModify}
          onClose={onCloseModify}
        />
      )}
      {dialogs.serviceChange && (
        <BookingServiceCategoriesEditDialogServiceChangeDateAdapter
          service={dialogs.serviceChange}
          onChange={onServiceChange}
          onClose={onCloseChangeService}
        />
      )}
    </>
  );
};
