import { ButtonProps } from '@mui/material';
import React, { forwardRef } from 'react';
import { MPButton } from '../../../theme/ui-kit/button';

interface ActionItemProps {
  readonly disabled?: boolean;
  readonly primary?: boolean;
  readonly startIcon?: ButtonProps['startIcon'];
  readonly endIcon?: ButtonProps['endIcon'];
  readonly dataTestId?: string;
  readonly children: React.ReactNode;
  readonly onExecute: () => void;
}

const ActionItem = forwardRef((props: ActionItemProps, ref: any) => {
  const { disabled, primary, startIcon, endIcon, children, dataTestId, onExecute } = props;

  return (
    <MPButton
      data-testid={dataTestId}
      ref={ref}
      variant={primary ? 'contained' : 'outlined'}
      disabled={disabled}
      onClick={onExecute}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {children}
    </MPButton>
  );
});

export default ActionItem;
