import { Typography } from '@mui/material';
import { useHistory } from 'react-router';
import AppBreadcrumbs from '../../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../../components/common/header';
import { MPButton } from '../../../../../theme/ui-kit/button';
import { getAdminMpCreateRoute } from '../../entry';

interface AdminMpTableHeaderAdapterProps {
  readonly canCreate?: boolean;
}

const AdminMpTableHeaderAdapter = (props: AdminMpTableHeaderAdapterProps) => {
  const { canCreate } = props;

  const history = useHistory();

  const onCreate = () => history.push(getAdminMpCreateRoute({}));

  return (
    <DefaultHeader
      headline={<AppBreadcrumbs />}
      actions={
        canCreate && (
          <MPButton
            variant='outlined'
            size='small'
            onClick={onCreate}
          >
            Добавить администратора
          </MPButton>
        )
      }
    >
      <Typography variant='h2'>Администраторы</Typography>
    </DefaultHeader>
  );
};

export default AdminMpTableHeaderAdapter;
