import { Drawer } from '@mui/material';
import styled from '@emotion/styled';
import { viewConfig } from '../../theme/viewConfig';

export const Wrapper = styled.div`
  display: flex;

  background: ${p => p.theme.palette.background.default};
`;

export const StepperWrapper = styled(Drawer)`
  width: ${viewConfig.stepper.width};

  & .MuiDrawer-paper {
    width: ${viewConfig.stepper.width};
    box-sizing: border-box;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
