import { Grid, Typography } from '@mui/material';
import { Nullable } from '../../../../../domain/model/types';
import { SportUserProfile } from '../../../../../domain/model/user';
import { ButtonLink } from '../../../../components/common/buttons/link';
import Splitter from '../../../../components/common/splitter';
import { AdminIcon } from '../../../../icons';
import UserDetailsGeneral from '../../components/detailsGeneral';
import { ColumnWrapper, Wrapper } from './controls';

interface UserCurrentDetailsMpComponentProps {
  readonly user: SportUserProfile;
  readonly onChangePassword: Nullable<() => void>;
}

const UserCurrentDetailsMpComponent = (props: UserCurrentDetailsMpComponentProps) => {
  const { user, onChangePassword } = props;

  return (
    <Wrapper>
      <ColumnWrapper>
        <Grid
          container
          spacing={4}
          direction='column'
        >
          <Grid item>
            <Typography variant='h3'>Профиль</Typography>
            <Splitter
              variant='horizontal'
              size={3}
            />
            <UserDetailsGeneral user={user} />
          </Grid>
          {onChangePassword && (
            <Grid item>
              <ButtonLink onClick={onChangePassword}>
                <AdminIcon />
                Сменить пароль
              </ButtonLink>
            </Grid>
          )}
        </Grid>
      </ColumnWrapper>
    </Wrapper>
  );
};

export default UserCurrentDetailsMpComponent;
