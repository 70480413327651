import { StompHeaders } from '@stomp/stompjs';
import { EOfferType, EPartnerType } from '../../../domain/model/enums';
import { UUID } from '../../../domain/model/types';

export enum ESocketHeaderType {
  Message = 'Message-Type',
}

export interface UseNotificationSocketProps<T> {
  readonly onMessageReceive: (msg: T, type: ENotificationMessageType) => void;
}

type CommonNotification = {
  title: string;
  description: string;
};

export type OfferNotification = CommonNotification & {
  offerId: UUID;
  offerType: EOfferType;
  offerName: string;
};

export type PartnerNotification = CommonNotification & {
  partnerId: UUID;
  partnerType: EPartnerType;
  partnerName: string;
};

export type OfferToModerationNotification = OfferNotification & {
  type: ENotificationMessageType.OfferToModerationMessage;
};

export type OfferActivateNotification = OfferNotification & {
  type: ENotificationMessageType.OfferActivatedMessage;
};

export type OfferRejectNotification = OfferNotification & {
  type: ENotificationMessageType.OfferRejectedMessage;
};

export type OfferExpiredNotification = OfferNotification & {
  type: ENotificationMessageType.OfferExpiredMessage;
};

export type OfferPromotionFreshOutNotification = OfferNotification & {
  type: ENotificationMessageType.OfferPromotionFreshOutMessage;
};

export type OfferPromotionsSellingOutNotification = OfferNotification & {
  type: ENotificationMessageType.OfferPromotionsSellingOut;
};

export type OfferUpcomingNotification = OfferNotification & {
  type: ENotificationMessageType.OfferUpcomingMessage;
};

export type OfferPausedByAdminNotification = OfferNotification & {
  type: ENotificationMessageType.OfferPausedByAdminMessage;
};

export type PartnerAddedNotification = PartnerNotification & {
  type: ENotificationMessageType.PartnerAddedMessage;
};

export type PartnerToVerificationNotification = PartnerNotification & {
  type: ENotificationMessageType.PartnerToVerificationMessage;
};

export type NotificationTypes =
  | OfferActivateNotification
  | OfferToModerationNotification
  | OfferRejectNotification
  | OfferExpiredNotification
  | OfferPromotionFreshOutNotification
  | OfferPromotionsSellingOutNotification
  | OfferUpcomingNotification
  | OfferPausedByAdminNotification
  | PartnerAddedNotification
  | PartnerToVerificationNotification;

export type NotificationMessageCallback = (msg: NotificationTypes, header: StompHeaders) => void;
export type NotificationConnectionFailureCallback = any;

export enum ENotificationMessageType {
  OfferToModerationMessage = 'OfferToModerationMessage',
  OfferActivatedMessage = 'OfferActivatedMessage',
  OfferRejectedMessage = 'OfferRejectedMessage',
  OfferExpiredMessage = 'OfferExpiredMessage',
  OfferPromotionFreshOutMessage = 'OfferPromotionFreshOutMessage',
  OfferPromotionsSellingOut = 'OfferPromotionsSellingOut',
  OfferUpcomingMessage = 'OfferUpcomingMessage',
  OfferPausedByAdminMessage = 'OfferPausedByAdminMessage',
  PartnerAddedMessage = 'PartnerAddedMessage',
  PartnerToVerificationMessage = 'PartnerToVerificationMessage',
}
