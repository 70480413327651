import { UUID } from '../../../../domain/model/types';
import TableCommonLayout from '../../../layouts/tableCommon/container';
import { OfferHistoryTableBehaviorAdapter } from './adapters/behavior';
import OfferHistoryTableFilterAdapter from './adapters/filter';
import OfferHistoryTableFooterAdapter from './adapters/footer';
import OfferHistoryTableAdapter from './adapters/table';
import { OfferHistoryTableTabsAdapter } from './adapters/tabs';
import { OfferHistoryTableConfigContext, OfferHistoryTableContext } from './context';
import { useOfferHistoryTableHandlers } from './hooks/useOfferHistoryTable';
import { useOfferHistoryTableConfig } from './hooks/useOfferHistoryTableConfig';
import { EVariantHistory } from './utils/common';

interface OfferHistoryTableContainerProps {
  readonly offerId: UUID;
  readonly typeHistory: EVariantHistory;
}

const OfferHistoryTableContainer = (props: OfferHistoryTableContainerProps) => {
  const { offerId, typeHistory } = props;

  const config = useOfferHistoryTableConfig();
  const handlers = useOfferHistoryTableHandlers();

  return (
    <OfferHistoryTableConfigContext.Provider value={config}>
      <OfferHistoryTableContext.Provider value={handlers}>
        <OfferHistoryTableBehaviorAdapter
          offerId={offerId}
          typeHistory={typeHistory}
        />
        <TableCommonLayout
          tabs={<OfferHistoryTableTabsAdapter />}
          filter={<OfferHistoryTableFilterAdapter />}
          table={<OfferHistoryTableAdapter />}
          footer={OfferHistoryTableFooterAdapter}
        />
      </OfferHistoryTableContext.Provider>
    </OfferHistoryTableConfigContext.Provider>
  );
};

export default OfferHistoryTableContainer;
