import ErrorHandler from '@/data/network/errorHandler';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { Pageable } from '@/domain/model';
import { ClientOrg } from '@/domain/model/clientOrg';
import { Nullable, UUID } from '@/domain/model/types';
import { ClientOrgUser } from '@/domain/model/user';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { PaginationSize } from '../../../../../types';
import service from '../../../services';
import { AllProps } from '../../../services/clientOrgUser';
import { ClientOrgUserTableFilterValues } from '../../filterUtils';
import { EClientOrgUserTableTab } from '../../types';
import { ClientOrgUserTableTabsCounter } from '../utils';

const defaultSort = `lastName,asc`;

export interface ClientOrgUserListState extends Fetchable, Pageable<ClientOrgUser> {
  readonly guid: Nullable<UUID>;
  readonly clientOrg: Nullable<ClientOrg>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly totalCount: number;
  readonly pageCount: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
  };
  readonly tab: Nullable<EClientOrgUserTableTab>;
  readonly tabsCounter: ClientOrgUserTableTabsCounter;
  readonly filter: ClientOrgUserTableFilterValues;
  readonly allClientOrgUsers: Fetchable & {
    readonly count: Nullable<number>;
    readonly needRefreshWatcher: number;
  };
}

type Reducer<T = undefined> = CaseReducer<ClientOrgUserListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ClientOrgUserListState> {
  clientOrgUsersDataReset: Reducer;
  clientOrgUsersSetTab: Reducer<{ tab: EClientOrgUserTableTab }>;
  clientOrgUsersStartSession: Reducer<{ guid: UUID; clientOrg: ClientOrg }>;
  clientOrgUsersSetFilter: Reducer<ClientOrgUserTableFilterValues>;
  clientOrgUsersSortReset: Reducer;
  clientOrgUsersSetSort: Reducer<{ sort: string }>;
  clientOrgUsersNeedRefreshWatcherReset: Reducer;
  clientOrgUsersNeedRefreshWatcherIncrement: Reducer;
  clientOrgUsersSetPage: Reducer<{ pageNumber: number }>;
  clientOrgUsersSetPageSize: Reducer<{ pageSize: PaginationSize }>;
}

export type ClientOrgUsersFetchProps = Omit<AllProps, 'signal'>;
export type ClientOrgUsersCountsFetchProps = ClientOrgUsersFetchProps & {
  readonly tabs: EClientOrgUserTableTab[];
};

export const clientOrgUsersFetch = createAsyncThunk<
  Pageable<ClientOrgUser>,
  ClientOrgUsersFetchProps,
  AppThunkAPIConfig
>('clientOrgUser/list/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    return await service.clientOrgUser.all({ ...props, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const clientOrgUsersCountFetch = createAsyncThunk<number, ClientOrgUsersFetchProps, AppThunkAPIConfig>(
  'clientOrgUser/list/count/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await service.clientOrgUser.count({ ...props, signal });
    } catch (e: any) {
      console.error(e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const clientOrgUsersCountsFetch = createAsyncThunk<
  ClientOrgUserTableTabsCounter,
  ClientOrgUsersCountsFetchProps,
  AppThunkAPIConfig
>('clientOrgUser/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await service.clientOrgUser.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      errors.map(e => {
        console.error(e);
      });
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

const slice = createSlice<ClientOrgUserListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    guid: null,
    clientOrg: null,
    needRefreshWatcher: 0,
    pageNumber: 1,
    totalCount: 0,
    pageCount: 0,
    tab: null,
    data: [],
    tabsCounter: {},
    filter: {},
    ...fetchableDefault,
    search: {
      sort: defaultSort,
      pageSize: 10,
    },
    allClientOrgUsers: {
      ...fetchableDefault,
      count: null,
      needRefreshWatcher: 0,
    },
  },
  reducers: {
    clientOrgUsersStartSession: (state, { payload }) => {
      const { guid, clientOrg } = payload;

      if (guid !== state.guid) {
        state.guid = guid;
        state.clientOrg = clientOrg;

        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = false;

        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;
        state.needRefreshWatcher = 0;

        state.filter = {};
        state.search = {
          sort: defaultSort,
          pageSize: 10,
        };

        state.allClientOrgUsers = {
          ...fetchableDefault,
          count: null,
          needRefreshWatcher: 0,
        };

        state.tab = null;
      }
    },
    clientOrgUsersSetTab: (state, { payload }) => {
      const { tab } = payload;
      // сбрасываем пейджинг и сортировку, если поменялись статусы (закладки)
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    clientOrgUsersSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    clientOrgUsersDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
    },
    clientOrgUsersSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    clientOrgUsersSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    clientOrgUsersNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
      state.allClientOrgUsers.needRefreshWatcher = 0;
    },
    clientOrgUsersNeedRefreshWatcherIncrement: state => {
      state.needRefreshWatcher++;
    },
    clientOrgUsersSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    clientOrgUsersSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(clientOrgUsersFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
      })
      .addCase(clientOrgUsersFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(clientOrgUsersFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(clientOrgUsersCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(clientOrgUsersCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      })
      .addCase(clientOrgUsersCountFetch.pending, state => {
        state.allClientOrgUsers.isFetching = true;
        state.allClientOrgUsers.isFetched = false;
        state.allClientOrgUsers.isFailed = false;

        if (state.allClientOrgUsers.count === 0) {
          //сбрасываем в null в том случае если до этого ничего не было, чтобы не моргала таблица лишний раз
          state.allClientOrgUsers.count = null;
        }
      })
      .addCase(clientOrgUsersCountFetch.fulfilled, (state, { payload }) => {
        state.allClientOrgUsers.isFetching = false;
        state.allClientOrgUsers.isFetched = true;
        state.allClientOrgUsers.isFailed = false;

        state.allClientOrgUsers.count = payload;
      })
      .addCase(clientOrgUsersCountFetch.rejected, state => {
        state.allClientOrgUsers.isFetching = false;
        state.allClientOrgUsers.isFetched = false;
        state.allClientOrgUsers.isFailed = true;

        state.allClientOrgUsers.count = null;
      });
  },
});
export const {
  clientOrgUsersStartSession,
  clientOrgUsersSetTab,
  clientOrgUsersSetFilter,
  clientOrgUsersDataReset,
  clientOrgUsersSetPage,
  clientOrgUsersNeedRefreshWatcherReset,
  clientOrgUsersSortReset,
  clientOrgUsersSetPageSize,
  clientOrgUsersSetSort,
  clientOrgUsersNeedRefreshWatcherIncrement,
} = slice.actions;

export default slice.reducer;
