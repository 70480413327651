import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TabsPanel from '../../../../components/common/tabs/panel';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { sportsmansTabsCounterSelector } from '../store/selectors';
import SportsmanTableTab from '../tab';
import { ESportsmanTableTab } from '../utils';

interface SportsmanTableTabsAdapterProps {
  readonly tab: ESportsmanTableTab;
}

export const SportsmanTableTabsAdapter = (props: SportsmanTableTabsAdapterProps) => {
  const { tab } = props;
  const { onChangeTab } = useContextHandlers();
  const counter = useSelector(sportsmansTabsCounterSelector);

  return useMemo(
    () => (
      <TabsPanel
        tab={tab}
        tabs={Object.values(ESportsmanTableTab)}
        labelAdapter={SportsmanTableTab}
        data={{
          counts: counter,
        }}
        onChangeTab={onChangeTab}
      />
    ),
    [tab, counter, onChangeTab]
  );
};
