import { Grid } from '@mui/material';
import { MPButton } from '../../../../theme/ui-kit/button';

interface ProductUploadStocksButtonsProps {
  readonly isUploadSuccess: boolean;
  readonly onUploadFile: () => void;
  readonly onClose: () => void;
}

const ProductUploadStocksButtons = (props: ProductUploadStocksButtonsProps) => {
  const { isUploadSuccess, onUploadFile, onClose } = props;

  return (
    <Grid
      container
      spacing={2}
    >
      {!isUploadSuccess && (
        <Grid item>
          <MPButton
            fullWidth={false}
            onClick={onUploadFile}
          >
            Обновить остатки
          </MPButton>
        </Grid>
      )}
      {!isUploadSuccess && (
        <Grid item>
          <MPButton
            fullWidth={false}
            variant='outlined'
            onClick={onClose}
          >
            Отмена
          </MPButton>
        </Grid>
      )}
      {isUploadSuccess && (
        <Grid item>
          <MPButton
            fullWidth={false}
            onClick={onClose}
          >
            Закрыть
          </MPButton>
        </Grid>
      )}
    </Grid>
  );
};

export default ProductUploadStocksButtons;
