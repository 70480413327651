import BookingOfferTableCell, { BookingOfferTableCellCommonProps } from './index';

const BookingOfferTableCellName = (props: BookingOfferTableCellCommonProps) => {
  const {
    bookingOffer: { name: value = '-' },
  } = props;

  return (
    <BookingOfferTableCell
      {...props}
      value={value}
    />
  );
};

export default BookingOfferTableCellName;
