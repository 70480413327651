import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { MenuSvgIcon } from '../../../../../components/common/images/icon';
import { SupportIcon } from '../../../../../icons';

interface MainMenuItemSupportProps {
  readonly selected: boolean;
  readonly onClick: () => void;
}

export const MainMenuItemSupport = ({ selected, onClick }: MainMenuItemSupportProps) => {
  return (
    <ListItem
      button
      onClick={onClick}
      selected={selected}
    >
      <ListItemIcon>
        <MenuSvgIcon icon={SupportIcon} />
      </ListItemIcon>
      <ListItemText
        primary='Служба поддержки'
        primaryTypographyProps={{ variant: 'body1' }}
      />
    </ListItem>
  );
};
