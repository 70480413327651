import { FormHelperText, Grid, Typography } from '@mui/material';
import { ECmsCollectionLinkObjectType, EOfferType } from '../../../../../../../domain/model/enums';
import { Nullable, UUID } from '../../../../../../../domain/model/types';
import { ValidationResult } from '../../../../../../utils/validation';
import { CmsContainerView } from '../../../../types';
import { CmsLinkedObjectCollectionType } from '../../../../types/collection';
import { CmsFeatureContainerCommonProps } from '../../../types';
import CmsCorpOfferMultipleCollectionContainer from '../../collection/corpOffer/multiple';
import CmsPartnerMultipleCollectionContainer from '../../collection/partner/multiple';
import CmsTradeOfferMultipleCollectionContainer from '../../collection/tradeOffer/multiple';
import { ColumnWrapper, Content, Wrapper } from './controls';
import useCmsContainerCreateCollectionChange from './useCollectionChange';

interface CmsContainerCreateStepCollectionContainerProps extends CmsFeatureContainerCommonProps {
  readonly guid: UUID;
  readonly offerType: EOfferType;
  readonly cmsCollection: CmsLinkedObjectCollectionType;
  readonly cmsContainer: CmsContainerView;
  readonly validation: Nullable<ValidationResult<CmsLinkedObjectCollectionType>>;
  readonly onChangeCollection: (data: CmsLinkedObjectCollectionType) => void;
}

const CmsContainerCreateStepCollection = (props: CmsContainerCreateStepCollectionContainerProps) => {
  const { guid, cmsCollection, offerType, cmsContainer, cmsContext, validation, onChangeCollection } = props;

  const { linkObjectType } = cmsCollection;

  const { onChangeCollectionLinkedObject } = useCmsContainerCreateCollectionChange({
    offerType,
    cmsCollection,
    onChangeCollection,
  });

  return (
    <>
      <Wrapper>
        <ColumnWrapper>
          <Content>
            <Grid
              container
              direction='column'
              spacing={2}
            >
              <Grid item>
                <Typography variant='h3'>Состав блока</Typography>
              </Grid>

              <Grid item>
                {linkObjectType === ECmsCollectionLinkObjectType.CorpOffer && (
                  <CmsCorpOfferMultipleCollectionContainer
                    guid={guid}
                    cmsContainer={cmsContainer}
                    cmsContext={cmsContext}
                    offers={cmsCollection.linkedObject ?? []}
                    selectedMaxCount={20}
                    onChangeLinkedObject={onChangeCollectionLinkedObject}
                  />
                )}
                {linkObjectType === ECmsCollectionLinkObjectType.TradeOffer && (
                  <CmsTradeOfferMultipleCollectionContainer
                    guid={guid}
                    cmsContainer={cmsContainer}
                    cmsContext={cmsContext}
                    offers={cmsCollection.linkedObject ?? []}
                    selectedMaxCount={20}
                    onChangeLinkedObject={onChangeCollectionLinkedObject}
                  />
                )}
                {linkObjectType === ECmsCollectionLinkObjectType.Partner && (
                  <CmsPartnerMultipleCollectionContainer
                    guid={guid}
                    partners={cmsCollection.linkedObject ?? []}
                    selectedMaxCount={10}
                    onChangeLinkedObject={onChangeCollectionLinkedObject}
                  />
                )}
              </Grid>

              {validation?.linkedObject?.hasError && (
                <Grid item>
                  <FormHelperText error>{validation?.linkedObject.message}</FormHelperText>
                </Grid>
              )}
            </Grid>
          </Content>
        </ColumnWrapper>
      </Wrapper>
    </>
  );
};

export default CmsContainerCreateStepCollection;
