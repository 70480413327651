import { MoneyIcon } from '@/presentation/icons';
import AppSvgIcon from '@components/common/images/icon';
import { FC } from 'react';
import { EEntityPreviewMode } from '../../../../types';
import { AspLabel, AspTag } from './controls';

type TradeOfferPreviewAspTagProps = {
  readonly mode: EEntityPreviewMode;
};

const TradeOfferPreviewAspTag: FC<TradeOfferPreviewAspTagProps> = props => {
  const { mode } = props;

  return (
    <AspTag
      icon={
        <AppSvgIcon
          color={'primary'}
          fontSize={mode === EEntityPreviewMode.Mobile ? 'small' : 'medium'}
          icon={MoneyIcon}
        />
      }
      label={<AspLabel variant={mode === EEntityPreviewMode.Mobile ? 'caption' : 'body2'}>Доступно с АСП</AspLabel>}
      color='primary'
      variant='filled'
    />
  );
};

export default TradeOfferPreviewAspTag;
