import { ActivityType } from '../../../../domain/model/event';
import { DataFilterQueryDslOperator } from '../../../../domain/model/filter';
import { Nullable } from '../../../../domain/model/types';
import { DataTableColumns } from '../../../components/common/table';
import { ESportsmansTableFilterItem, SportsmansTableFilterValues } from '../filterUtils';

export enum ESportsmanTableTab {
  All = 'ALL',
}

export type SportsmanTableTabsCounter = {
  [name in ESportsmanTableTab]?: number;
};

export enum ESportsmanTableColumn {
  Logo = 'logo',
  LastName = 'lastName',
  Age = 'age',
  ActivityTypes = 'activityTypes',
  ActivitySubTypes = 'activitySubTypes',
  Road = 'road',
  DepartmentName = 'departmentName',
  Gender = 'gender',
  Locality = 'locality',
}

export const sportsmansTableColumns: DataTableColumns<ESportsmanTableColumn> = {
  [ESportsmanTableColumn.Logo]: {
    width: '2rem',
  },
  [ESportsmanTableColumn.LastName]: {
    title: 'Фамилия и Имя',
    sortable: true,
    width: '12rem',
  },
  [ESportsmanTableColumn.Age]: {
    title: 'Возраст',
    sortable: true,
    width: '5rem',
  },
  [ESportsmanTableColumn.ActivityTypes]: {
    title: 'Интересы',
    width: '16rem',
    sortable: true,
    wrap: true,
  },
  [ESportsmanTableColumn.ActivitySubTypes]: {
    title: 'Жанр',
    width: '22rem',
    wrap: true,
  },
  [ESportsmanTableColumn.Road]: {
    title: 'Дорога',
    width: '8rem',
    wrap: true,
  },
  [ESportsmanTableColumn.DepartmentName]: {
    title: 'Подразделение',
    sortable: true,
  },
  [ESportsmanTableColumn.Gender]: {
    title: 'Пол',
    width: '5rem',
  },
  [ESportsmanTableColumn.Locality]: {
    title: 'Населенный пункт',
  },
};

export const getSportsmansTableColumns = (): DataTableColumns<ESportsmanTableColumn> => {
  const { gender, locality, departmentName, ...columns } = sportsmansTableColumns;
  return columns;
};

interface GetSportsmansTableAdapterKey {
  key: string;
}

export const getSportsmansTableAdapterKey = (): GetSportsmansTableAdapterKey => {
  return {
    key: 'sportsmen',
  };
};

export const getSportsmanTableTabName = (tab: ESportsmanTableTab): string => {
  switch (tab) {
    case ESportsmanTableTab.All:
      return 'Все пользователи';
  }
};

export const getSportsmanSearchSportsmanPresetValues = (
  activityTypes: Nullable<ActivityType[]>
): SportsmansTableFilterValues => {
  let filterValues: SportsmansTableFilterValues = {};

  if (activityTypes?.length) {
    filterValues = {
      ...filterValues,
      [ESportsmansTableFilterItem.ActivityTypes]: {
        value: activityTypes,
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
        },
      },
    };
  }

  return filterValues;
};
