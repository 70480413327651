import { useMemo } from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache, WindowScroller } from 'react-virtualized';
import { Nullable } from '../../../../../domain/model/types';
import { VirtualizedList } from './controls';

interface InfiniteListProps<T> {
  readonly list: T[];
  readonly windowScroller?: boolean;
  readonly isLoading: boolean;
  readonly totalCount: number;
  readonly rowHeight?: number;
  readonly contentHeight?: number;
  readonly contentWidth?: number;
  readonly loadMore: () => void;
  readonly onRowRender: (item: Nullable<T>, prevItem: Nullable<T>) => JSX.Element | null;
}

function InfiniteList<T>(props: InfiniteListProps<T>) {
  const {
    list,
    loadMore,
    windowScroller = true,
    isLoading,
    totalCount,
    rowHeight = 0,
    contentHeight,
    contentWidth,
    onRowRender,
  } = props;

  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        minHeight: rowHeight,
        fixedWidth: true,
      }),
    [rowHeight]
  );

  const isRowLoaded = ({ index }: { index: number }) => index < list.length;

  const rowRenderer = ({ index, key, style, parent }: { index: number; key: any; style: any; parent: any }) => {
    const item: Nullable<T> = isRowLoaded({ index }) ? list[index] : null;
    const prevItem: Nullable<T> = index >= 0 && isRowLoaded({ index: index - 1 }) ? list[index - 1] : null;
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <div
            ref={registerChild as any}
            key={key}
            style={style}
          >
            {onRowRender(item, prevItem)}
          </div>
        )}
      </CellMeasurer>
    );
  };

  const onRowsRendered = ({ stopIndex }: { stopIndex: number }) => {
    if (stopIndex >= list.length && !isLoading) {
      loadMore();
    }
  };

  if (windowScroller) {
    return (
      <WindowScroller>
        {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <div ref={registerChild as any}>
                <VirtualizedList
                  autoHeight
                  deferredMeasurementCache={cache}
                  isScrolling={isScrolling}
                  scrollTop={scrollTop}
                  overscanRowCount={1000}
                  rowRenderer={rowRenderer}
                  width={contentWidth || width}
                  height={contentHeight || height}
                  rowHeight={cache.rowHeight}
                  rowCount={totalCount}
                  onRowsRendered={onRowsRendered}
                  onScroll={onChildScroll}
                />
              </div>
            )}
          </AutoSizer>
        )}
      </WindowScroller>
    );
  }

  return (
    <AutoSizer>
      {({ width, height }) => (
        <VirtualizedList
          deferredMeasurementCache={cache}
          overscanRowCount={0}
          onRowsRendered={onRowsRendered}
          rowRenderer={rowRenderer}
          width={contentWidth || width}
          height={contentHeight || height}
          rowHeight={cache.rowHeight}
          rowCount={totalCount}
        />
      )}
    </AutoSizer>
  );
}

export default InfiniteList;
