import { UserAccessPartitionProductOrders } from '../../../../domain/model/accessMatrix';
import { EOrderStatus } from '../../../../domain/model/enums';
import { ProductOrderStatus } from '../../../../domain/model/order';
import { DataTableColumns, MutableDataTableColumns } from '../../../components/common/table';
import { PanelActions } from '../../../types';
import {
  EProductOrderActionType,
  EProductOrderTableColumn,
  EProductOrderTableTab,
  ProductOrderActionTableType,
} from '../types';
import { getProductOrderActionsConfigByPermissions } from '../utils/actions';

export type ProductOrderTableTabsCounter = {
  [name in EProductOrderTableTab]?: number;
};

export const getProductOrderStatusesByTableTab = (tab: EProductOrderTableTab): ProductOrderStatus[] => {
  switch (tab) {
    case EProductOrderTableTab.Paid:
      return [EOrderStatus.Paid];

    case EProductOrderTableTab.Sent:
      return [EOrderStatus.Sent];

    case EProductOrderTableTab.Given:
      return [EOrderStatus.Given];

    case EProductOrderTableTab.New:
      return [EOrderStatus.New];

    case EProductOrderTableTab.Cancelled:
      return [EOrderStatus.Cancelled];

    case EProductOrderTableTab.Confirmed:
      return [EOrderStatus.Confirmed];

    case EProductOrderTableTab.Returned:
      return [EOrderStatus.Returned];

    case EProductOrderTableTab.PartiallyReturned:
      return [EOrderStatus.PartiallyReturned];
  }
};

const productOrderTableColumns: DataTableColumns<EProductOrderTableColumn> = {
  [EProductOrderTableColumn.CreateAt]: {
    title: 'Дата',
    width: '10rem',
    sortable: true,
  },
  [EProductOrderTableColumn.Number]: {
    title: 'Номер заказа',
    width: '10rem',
    sortable: true,
  },
  [EProductOrderTableColumn.CustomerName]: {
    title: 'Клиент',
    width: '30rem',
    wrap: true,
    sortable: true,
  },
  [EProductOrderTableColumn.Cost]: {
    title: 'Сумма',
    width: '10rem',
    sortable: true,
  },
  [EProductOrderTableColumn.CustomerCancelReason]: {
    title: 'Комментарий клиента',
    width: '15rem',
    wrap: true,
    sortable: true,
  },
  [EProductOrderTableColumn.CancelReason]: {
    title: 'Комментарий менеджера',
    width: '15rem',
    wrap: true,
    sortable: true,
  },
  [EProductOrderTableColumn.ManagerComment]: {
    title: 'Комментарий менеджера',
    width: '15rem',
    wrap: true,
  },
  [EProductOrderTableColumn.ItemsCount]: {
    title: 'Количество',
    width: '6rem',
  },
};

export const getProductOrderTableColumns = (tab: EProductOrderTableTab): DataTableColumns<EProductOrderTableColumn> => {
  const columns: EProductOrderTableColumn[] = [];
  columns.push(
    EProductOrderTableColumn.CreateAt,
    EProductOrderTableColumn.Number,
    EProductOrderTableColumn.ItemsCount,
    EProductOrderTableColumn.Cost,
    EProductOrderTableColumn.CustomerName
  );

  if (tab === EProductOrderTableTab.Cancelled) {
    columns.push(EProductOrderTableColumn.CustomerCancelReason, EProductOrderTableColumn.CancelReason);
  } else {
    columns.push(EProductOrderTableColumn.ManagerComment);
  }

  const mutableTableColumns: MutableDataTableColumns<EProductOrderTableColumn> = {};
  columns.forEach(column => (mutableTableColumns[column] = productOrderTableColumns[column]));
  return mutableTableColumns;
};

export const filterProductOrderTableActions = (
  actions: PanelActions<EProductOrderActionType>
): PanelActions<ProductOrderActionTableType> => {
  const tableActions: PanelActions<ProductOrderActionTableType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EProductOrderActionType.Pay:
      case EProductOrderActionType.Give:
      case EProductOrderActionType.Confirm:
      case EProductOrderActionType.Send:
      case EProductOrderActionType.Renew:
      case EProductOrderActionType.Return:
      case EProductOrderActionType.PartiallyReturn:
      case EProductOrderActionType.Cancel:
        tableActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          position: action.position,
        });
        break;
      case EProductOrderActionType.Save:
      case EProductOrderActionType.New:
        break;
    }
  });

  return tableActions;
};

export const getProductOrderTableTabActions = (
  /*tab выбранный таб*/
  tab: EProductOrderTableTab,
  /*высокоуровневые permissions из матрицы доступа скрина*/
  permissions: UserAccessPartitionProductOrders
): PanelActions<ProductOrderActionTableType> => {
  // сформировали экшн объекты по permissions
  const allowedActions = getProductOrderActionsConfigByPermissions(permissions);

  // дофильтровали для таблиц
  const actions = filterProductOrderTableActions(allowedActions);

  return getProductOrderTableTabActionsConfig({ tab, actions });
};

interface GetOrdersTableTabActionsProps {
  readonly tab: EProductOrderTableTab;
  readonly actions: PanelActions<ProductOrderActionTableType>;
}

const getProductOrderTableTabActionsConfig = ({
  tab,
  actions,
}: GetOrdersTableTabActionsProps): PanelActions<ProductOrderActionTableType> => {
  switch (tab) {
    case EProductOrderTableTab.New:
      return actions.map(item => ({ ...item, disabled: item.type === EProductOrderActionType.Renew || item.disabled }));
    case EProductOrderTableTab.Confirmed:
      return actions.map(item => ({ ...item, disabled: item.type === EProductOrderActionType.Confirm || item.disabled }));
    case EProductOrderTableTab.Paid:
      return actions.map(item => ({ ...item, disabled: item.type === EProductOrderActionType.Pay || item.disabled }));
    case EProductOrderTableTab.Sent:
      return actions.map(item => ({ ...item, disabled: item.type === EProductOrderActionType.Send || item.disabled }));
    case EProductOrderTableTab.Given:
      return actions.map(item => ({ ...item, disabled: item.type === EProductOrderActionType.Give || item.disabled }));
    case EProductOrderTableTab.Cancelled:
      return actions.map(item => ({ ...item, disabled: item.type === EProductOrderActionType.Cancel || item.disabled }));
    case EProductOrderTableTab.Returned:
      return actions.map(item => ({ ...item, disabled: item.type === EProductOrderActionType.Return || item.disabled }));
    case EProductOrderTableTab.PartiallyReturned:
      return actions.map(item => ({
        ...item,
        disabled: item.type === EProductOrderActionType.PartiallyReturn || item.disabled,
      }));
    default:
      return [];
  }
};

export const getProductOrderTableTabName = (tab: EProductOrderTableTab): string => {
  switch (tab) {
    case EProductOrderTableTab.New:
      return 'Создан';
    case EProductOrderTableTab.Confirmed:
      return 'Принят';
    case EProductOrderTableTab.Paid:
      return 'Оплачен';
    case EProductOrderTableTab.Sent:
      return 'Отправлен';
    case EProductOrderTableTab.Given:
      return 'Получен';
    case EProductOrderTableTab.Cancelled:
      return 'Отменен';
    case EProductOrderTableTab.PartiallyReturned:
      return 'Возврат частичный';
    case EProductOrderTableTab.Returned:
      return 'Возврат полный';
  }
};
