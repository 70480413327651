import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { SportOption } from '../../../../../../domain/model';
import { EUserRole, EUserStatus } from '../../../../../../domain/model/enums';
import { Nullable } from '../../../../../../domain/model/types';
import { MpUser } from '../../../../../../domain/model/user';
import ConfirmDialog from '../../../../../components/common/dialogs/confirm';
import ContentLoader from '../../../../../components/common/loader';
import { MPAutocompleteSingleSelect } from '../../../../../theme/ui-kit/autocomplete/single';
import { MPButton } from '../../../../../theme/ui-kit/button';
import UserMpSuggestionContainer from '../../../../user/userMp/search/container';
import { ProductOfferModeratorPreview } from '../../../types';
import { getProductOfferModeratorPreview } from '../../../utils/common';

interface ProductChangeModeratorDialogProps {
  readonly approvingAdmin: Nullable<SportOption>;
  readonly productName: Nullable<string>;
  readonly isExecuting: boolean;
  readonly onExecute: (user: MpUser) => void;
  readonly onClose: () => void;
}

const ProductChangeModeratorDialog = (props: ProductChangeModeratorDialogProps) => {
  const { productName, approvingAdmin, isExecuting, onExecute, onClose } = props;

  const [selectedModerator, setSelectedModerator] = useState<Nullable<MpUser>>(null);
  const [searchedModerator, setSearchedModerator] = useState<Nullable<MpUser>>(null);
  const [searchValue, setSearchValue] = useState<Nullable<string>>(null);
  const [error, setError] = useState<Nullable<string>>(null);

  const onModeratorChange = () => {
    if (selectedModerator) {
      onExecute(selectedModerator);
    } else {
      setError('Обязательно для заполнения');
    }
  };

  const title = approvingAdmin ? 'Переназначение модератора' : 'Назначение модератора';

  return (
    <ConfirmDialog
      open
      onClose={onClose}
      title={title}
      dialogButtons={
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              disabled={isExecuting}
              onClick={onModeratorChange}
            >
              Назначить
              {isExecuting && <ContentLoader />}
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              fullWidth={false}
              onClick={onClose}
            >
              Отмена
              {isExecuting && <ContentLoader />}
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        spacing={2}
        wrap='nowrap'
        direction='column'
      >
        {approvingAdmin && (
          <Grid item>
            <Typography>
              Это товарное предложение уже в работе у {approvingAdmin.name}. Выберите администратора на которого будет
              переназначена проверка карточки товара «{productName}».
            </Typography>
          </Grid>
        )}
        {!approvingAdmin && (
          <Grid item>
            <Typography>
              Выберите администратора на которого будет назначена проверка карточки товара «{productName}».
            </Typography>
          </Grid>
        )}

        <Grid item>
          <UserMpSuggestionContainer
            roles={[EUserRole.AdminMp]}
            statuses={[EUserStatus.Enabled]}
          >
            {({ onSearch, users, isFetching }) => (
              <MPAutocompleteSingleSelect<ProductOfferModeratorPreview>
                label='Администратор товарних предложений'
                isLoading={isFetching}
                error={!!error}
                helperText={error}
                value={
                  searchedModerator && {
                    ...searchedModerator,
                    id: '',
                    name: searchValue ?? '',
                  }
                }
                options={
                  users?.map(user => ({
                    ...user,
                    name: getProductOfferModeratorPreview(user, [EUserRole.AdminMp]),
                  })) ?? []
                }
                onSearchValue={value => {
                  onSearch(value);
                  setSearchValue(value);
                  setSelectedModerator(null);
                }}
                onChangeValue={value => {
                  setSelectedModerator(value);
                  setSearchedModerator(value);
                  setError(null);
                }}
              />
            )}
          </UserMpSuggestionContainer>
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
};

export default ProductChangeModeratorDialog;
