import React from 'react';
import { OfferCategory } from '../../../../../domain/model';

export type TradeOfferCategoriesSelectHandlersContextValue = {
  readonly onSelect: (tradeOfferCategory: OfferCategory) => void;
  readonly onUnselect: (tradeOfferCategory: OfferCategory) => void;
  readonly onClose: () => void;
};

export const TradeOfferCategoriesSelectHandlersContext =
  React.createContext<TradeOfferCategoriesSelectHandlersContextValue>(
    {} as TradeOfferCategoriesSelectHandlersContextValue
  );
