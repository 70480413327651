import { BookingOfferShort } from '@/domain/model/booking';
import { Grid } from '@mui/material';
import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TablePagination from '../../../components/common/table/pagination';
import { MPButton } from '../../../theme/ui-kit/button';
import { PaginationSize } from '../../../types';
import { FooterActionsWrapper } from './controls';
import { bookingOfferSearchGet, bookingOfferSearchSelectedSelector } from './store/selectors';
import { bookingOfferSearchSetPage, bookingOfferSearchSetPageSize } from './store/slice';

interface BookingOfferSearchFooterContainerProps {
  readonly maxCount?: number | false;
  readonly onSelect: (offers: BookingOfferShort[]) => void;
}

const BookingOfferSearchFooterContainer = forwardRef((props: BookingOfferSearchFooterContainerProps, ref: any) => {
  const { maxCount, onSelect } = props;

  const dispatch = useDispatch();

  const { totalCount, pageCount, pageNumber, search, selected: offers } = useSelector(bookingOfferSearchGet);

  const selectedBookingOffers = useSelector(bookingOfferSearchSelectedSelector);

  const onChangePage = (num: number) => {
    dispatch(bookingOfferSearchSetPage({ pageNumber: num }));
  };

  const onChangePageSize = (size: PaginationSize) => {
    dispatch(bookingOfferSearchSetPageSize({ pageSize: size }));
  };

  let buttonText = `Выбрать ${selectedBookingOffers.length}`;

  if (maxCount) {
    buttonText += ` из ${maxCount}`;
  }

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='обьектов досуга и отдыха'
        onChangePageSize={onChangePageSize}
        onChangePage={onChangePage}
      />
      <FooterActionsWrapper>
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <MPButton
              disabled={selectedBookingOffers.length === 0}
              onClick={() => onSelect(offers)}
            >
              {buttonText}
            </MPButton>
          </Grid>
        </Grid>
      </FooterActionsWrapper>
    </div>
  );
});

export default BookingOfferSearchFooterContainer;
