import { MasterDetailsLayout } from '../../../../layouts/masterDetails/container';
import { CommonPreloaderDecorator } from '../../../general/decorators/preloader';
import { CommonUpdaterDecorator } from '../../../general/decorators/updater';
import MasterDetailsContainer from '../../../general/master/details/container';
import {
  ActivityTypesEditDialogsAdapter,
  ActivityTypesEditDictionaryAdapter,
  ActivityTypesEditHeaderAdapter,
  ActivityTypesEditStepperAdapter,
} from './adapters';
import { ActivityTypesEditInitializer } from './initializer';
import { ActivityTypesEditHandlersProvider } from './providers';
import { activityTypesEditIsLoadingSelector, activityTypesEditIsPreloadingSelector } from './store/selectors';
import { ActivityTypesEditAccess } from './types';

export type ActivityTypesEditContainerProps = {
  readonly access: ActivityTypesEditAccess;
};

const ActivityTypesEditContainer = (props: ActivityTypesEditContainerProps) => {
  const { access } = props;

  return (
    <MasterDetailsContainer
      layout={MasterDetailsLayout}
      layoutProps={{
        stepper: ActivityTypesEditStepperAdapter,
        header: ActivityTypesEditHeaderAdapter,
        decorators: [
          <CommonPreloaderDecorator
            key='preloader'
            isFetchingSelector={activityTypesEditIsPreloadingSelector}
          />,
          <CommonUpdaterDecorator
            key='updater'
            isFetchingSelector={activityTypesEditIsLoadingSelector}
          />,
        ],
      }}
      initializer={ActivityTypesEditInitializer}
      providers={[
        {
          name: 'handlers',
          factory: ActivityTypesEditHandlersProvider,
        },
      ]}
      commonProps={{
        access,
      }}
    >
      <ActivityTypesEditDictionaryAdapter access={access} />

      <ActivityTypesEditDialogsAdapter />
    </MasterDetailsContainer>
  );
};

export default ActivityTypesEditContainer;
