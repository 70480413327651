import { BookingOfferEditFieldCommonProps } from '../../../components/fieldsEdit/types';
import BookingOfferServiceManageServicesField from '../../fields/services';

const fieldName = 'services';

const BookingOfferServiceManageServicesFieldAdapter = (props: BookingOfferEditFieldCommonProps<typeof fieldName>) => {
  const { value } = props;

  return value.length > 0 ? <BookingOfferServiceManageServicesField {...props} /> : null;
};

export default BookingOfferServiceManageServicesFieldAdapter;
