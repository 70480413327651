import Axios from 'axios';
import Api from '../../../../data/api';
import { AllProps as ApiAllProps } from '../../../../data/api/playground';
import { ApiCancellable, ApiRequestListDiscriminator } from '../../../../data/api/types';
import { Pageable, SportOption } from '../../../../domain/model';
import { EPlaygroundModerationStatus, EUserRole } from '../../../../domain/model/enums';
import { Playground } from '../../../../domain/model/playground';
import { Nullable, UUID } from '../../../../domain/model/types';
import { PaginationSize } from '../../../types';
import {
  EPlaygroundTableFilterItem,
  getStatesFilterForPlaygroundTableTab,
  PlaygroundTableFilterValues,
} from '../filterUtils';
import { EPlaygroundTableTab, PlaygroundCounterByModerationStatus, PlaygroundTableTabsCounter } from '../table/utils';

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string>;
    readonly moderationStatuses: Nullable<EPlaygroundModerationStatus[]>;
  };
  readonly filter: PlaygroundTableFilterValues;
  readonly pageNumber: number;
};

type CreatedByRolesProps = ApiCancellable & {
  readonly playground: Playground;
};

type CountsByStatusesProps = Omit<AllProps, 'pageNumber'>;

export type CountProps = Omit<AllProps, 'pageNumber'>;

type CountsByTabsProps = CountsByStatusesProps & {
  readonly tabs: EPlaygroundTableTab[];
};

export type PlaygroundCommonServiceOneArgs = {
  readonly id: UUID;
};

export type PlaygroundCommonServiceSaveArgs = Playground;

export type PlaygroundCommonService = {
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps
  ) => ApiAllProps<D>;
  readonly all: (props: AllProps) => Promise<Pageable<Playground>>;
  readonly one: (args: PlaygroundCommonServiceOneArgs) => Promise<Playground>;
  readonly createdByRoles: (props: CreatedByRolesProps) => Promise<EUserRole[]>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly countsByModerationStatuses: (
    props: CountsByStatusesProps
  ) => Promise<{ counts: PlaygroundCounterByModerationStatus; errors: Array<string> }>;
  readonly countsByTabs: (
    props: CountsByTabsProps
  ) => Promise<{ counts: PlaygroundTableTabsCounter; errors: Array<string> }>;
};

export const playgroundCommonService: PlaygroundCommonService = {
  buildListQueryParams: props => {
    const { search, filter, pageNumber, signal } = props;
    const { pageSize, sort, moderationStatuses } = search;

    const name = filter[EPlaygroundTableFilterItem.Name]?.value;
    const activityTypes = filter[EPlaygroundTableFilterItem.ActivityTypes]?.value?.map(item => item.id);
    const addressObjectId = filter[EPlaygroundTableFilterItem.Address]?.value?.id;
    const createdBy = filter[EPlaygroundTableFilterItem.CreatedBy]?.value;
    const moderatedBy = filter[EPlaygroundTableFilterItem.ModeratedBy]?.value;
    const type = filter[EPlaygroundTableFilterItem.TypePlayGround]?.value;

    const creator: Nullable<SportOption> = createdBy ? { id: createdBy?.id, name: '' } : null;

    return {
      page: pageNumber,
      pageSize,
      name,
      sort,
      addressObjectId,
      activityTypes,
      signal,
      creator,
      moderatedBy: moderatedBy?.id,
      type,
      moderationStatuses,
    };
  },
  // Метод для поручения списка площадок
  all: async props => {
    const { data: response } = await Api.playground.all(playgroundCommonService.buildListQueryParams(props));

    const { content: data, totalElements: totalCount, totalPages: pageCount, number: page } = response;

    return { data, totalCount, pageCount, pageNumber: page };
  },

  // Метод получения одной площадки
  one: async props => {
    const { data } = await Api.playground.one(props);

    return data;
  },

  createdByRoles: async ({ playground: { createdBy }, signal }) => {
    const createdById = createdBy?.id;
    if (createdById) {
      return (await Api.user.sport.one({ id: createdById, signal })).data.roles;
    } else {
      return [];
    }
  },

  count: async props => {
    const { data: response } = await Api.playground.all({
      ...playgroundCommonService.buildListQueryParams({
        ...props,
        pageNumber: 1,
      }),
      discriminator: ApiRequestListDiscriminator.Count,
    });

    return response.count;
  },

  countsByModerationStatuses: async ({ search, filter, signal }) => {
    const errors: string[] = [];
    const counts: PlaygroundCounterByModerationStatus = {};
    const { moderationStatuses } = search;

    const requests =
      moderationStatuses?.map(moderationStatus =>
        playgroundCommonService.count({
          search: { ...search, moderationStatuses: [moderationStatus] },
          filter,
          signal,
        })
      ) ?? [];

    const responses = await Promise.allSettled<Promise<number>[]>(requests);
    const parseResponse = (response: typeof responses[0], status: EPlaygroundModerationStatus) => {
      if (response.status === 'fulfilled') {
        counts[status] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество площадок '${status}': ${response.reason}`);
        }
      }
    };
    moderationStatuses?.forEach((status, index) => parseResponse(responses[index], status));

    return { counts, errors };
  },

  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: PlaygroundTableTabsCounter = {};

    const tabsWithStatuses = tabs.filter(tab => {
      const statuses = getStatesFilterForPlaygroundTableTab(tab);
      return !!statuses.length;
    });
    const tabsWithoutStatuses = tabs.filter(tab => {
      const statuses = getStatesFilterForPlaygroundTableTab(tab);
      return !statuses.length;
    });

    //табы для которых есть статусы
    const requestsWithStatuses = tabsWithStatuses.map(tab => {
      const moderationStatuses = getStatesFilterForPlaygroundTableTab(tab);
      const params: CountsByStatusesProps = {
        ...props,
        search: { ...props.search, moderationStatuses },
      };
      return playgroundCommonService.countsByModerationStatuses({ ...params, signal });
    });

    //табы для которых нет статусов
    const requestsWithoutStatuses = tabsWithoutStatuses.map(() => {
      const params: CountProps = {
        ...props,
        search: { ...props.search, moderationStatuses: null },
      };
      return playgroundCommonService.count({ ...params, signal });
    });

    const responsesWithStatuses = await Promise.allSettled(requestsWithStatuses);
    const responsesWithoutStatuses = await Promise.allSettled(requestsWithoutStatuses);

    const parseResponseWithStatuses = (response: typeof responsesWithStatuses[0], tab: EPlaygroundTableTab) => {
      if (response.status === 'fulfilled') {
        const statuses = getStatesFilterForPlaygroundTableTab(tab);
        counts[tab] = statuses.reduce<number>((prev, current) => prev + (response.value.counts?.[current] ?? 0), 0);
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество площадок '${tab}': ${response.reason}`);
        }
      }
    };

    const parseResponseWithoutStatuses = (response: typeof responsesWithoutStatuses[0], tab: EPlaygroundTableTab) => {
      if (response.status === 'fulfilled') {
        counts[tab] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество площадок '${tab}': ${response.reason}`);
        }
      }
    };

    tabsWithStatuses.forEach((tab, index) => parseResponseWithStatuses(responsesWithStatuses[index], tab));
    tabsWithoutStatuses.forEach((tab, index) => parseResponseWithoutStatuses(responsesWithoutStatuses[index], tab));

    return { counts, errors };
  },
};
