import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import TablePagination from '../../../../components/common/table/pagination';
import { useContextHandlers } from './hooks/useContextHandlers';
import {
  analyticsQueriesPageCountSelector,
  analyticsQueriesPageNumberSelector,
  analyticsQueriesSearchSelector,
  analyticsQueriesTotalCountSelector,
} from './store/selectors';

interface AnalyticsQueriesTableFooterContainerProps {}

const AnalyticsQueriesTableFooterContainer = forwardRef(
  (props: AnalyticsQueriesTableFooterContainerProps, ref: any) => {
    const handlers = useContextHandlers();

    const pageNumber = useSelector(analyticsQueriesPageNumberSelector);
    const search = useSelector(analyticsQueriesSearchSelector);
    const totalCount = useSelector(analyticsQueriesTotalCountSelector);
    const pageCount = useSelector(analyticsQueriesPageCountSelector);

    return (
      <div ref={ref}>
        <TablePagination
          page={pageNumber}
          pageSize={search.pageSize}
          pageCount={pageCount}
          objectsCount={totalCount}
          objectsLabel='объекты'
          onChangePageSize={handlers.onChangePageSize}
          onChangePage={handlers.onChangePage}
        />
      </div>
    );
  }
);

export default AnalyticsQueriesTableFooterContainer;
