import CmsContainerTableCell, { CmsContainerTableCellCommonProps } from './index';

type CmsContainerTableCellTypeProps = CmsContainerTableCellCommonProps;

const CmsContainerTableCellType = (props: CmsContainerTableCellTypeProps) => {
  const {
    cmsContainer: { typeName },
  } = props;

  const value = typeName ?? '-';

  return (
    <CmsContainerTableCell
      {...props}
      value={value}
    />
  );
};

export default CmsContainerTableCellType;
