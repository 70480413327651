import TabsPanelCounter from '../../../components/common/tabs/counter/counter';
import TabsPanelLabel from '../../../components/common/tabs/label/label';
import { EProductOrderTableTab } from '../types';
import { getProductOrderTableTabName, ProductOrderTableTabsCounter } from './utils';

interface ProductOrderTableTabProps {
  readonly tab: EProductOrderTableTab;
  readonly counts: ProductOrderTableTabsCounter;
}

const ProductOrderTableTab = ({ tab, counts }: ProductOrderTableTabProps) => {
  const count = counts[tab];

  return (
    <TabsPanelLabel title={getProductOrderTableTabName(tab)}>
      <TabsPanelCounter count={count} />
    </TabsPanelLabel>
  );
};

export default ProductOrderTableTab;
