import { combineReducers } from '@reduxjs/toolkit';
import details, { UserMpDetailsState } from './details/store/slice';
import edit, { UserMpEditState } from './edit/store/slice';

export interface UserMpState {
  details: UserMpDetailsState;
  edit: UserMpEditState;
}

export default combineReducers<UserMpState>({
  details,
  edit,
});
