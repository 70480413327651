import { useMemo } from 'react';
import useBreadcrumbs from '../../../general/breadcrumbs/useBreadcrumbs';
import SupportInfoDetailsMpHeader from '../header';

const SupportInfoMpHeaderAdapter = () => {
  const breadcrumbs = useBreadcrumbs();

  return useMemo(() => {
    return <SupportInfoDetailsMpHeader breadcrumbs={breadcrumbs} />;
  }, [breadcrumbs]);
};

export default SupportInfoMpHeaderAdapter;