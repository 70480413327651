import TableCommonLayout from '../../../layouts/tableCommon/container';
import TableLoaderCommonLayout from '../../../layouts/tableLoaderCommon/container';
import { ReportsBehaviorAdapter } from './adapters/behavior';
import { ReportsHeaderAdapter } from './adapters/header';
import { ReportsTableAdapter } from './adapters/table';

interface ReportsTableContainerProps {
  readonly guid: UUID;
}

const ReportsTableContainer = (props: ReportsTableContainerProps) => {
  const { guid } = props;

  return (
    <>
      <ReportsBehaviorAdapter guid={guid} />
      <TableLoaderCommonLayout selector={() => false}>
        <TableCommonLayout
          canViewTable={true}
          header={<ReportsHeaderAdapter />}
          table={<ReportsTableAdapter />}
        ></TableCommonLayout>
      </TableLoaderCommonLayout>
    </>
  );
};

export default ReportsTableContainer;
