import { Location } from 'history';
import { Redirect, Switch, useParams } from 'react-router';
import { Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { EOrderPartition } from '../../../../../domain/model/enums';
import { Partner } from '../../../../../domain/model/partner';
import { UUID } from '../../../../../domain/model/types';
import { MpUser } from '../../../../../domain/model/user';
import ForbiddenComponent from '../../../../components/common/forbidden';
import CustomerManagementBookingOrdersByPartnerScreen from '../../../../screens/customerManagement/bookingOrdersByPartner';
import CustomerManagementCorpActivationsByPartnerScreen from '../../../../screens/customerManagement/corpActivationsByPartner';
import CustomerManagementByPartnerScreen from '../../../../screens/customerManagement/customerByPartner';
import CustomerManagementOrderByPartnerScreen from '../../../../screens/customerManagement/orderByPartner';
import CustomerManagementProductOrdersByPartnerScreen from '../../../../screens/customerManagement/productOrdersByPartner';
import CustomerManagementTradeActivationsByPartnerScreen from '../../../../screens/customerManagement/tradeActivationsByPartner';
import NotFoundScreen from '../../../../screens/notFound';
import { CorpActivationsLocationState } from '../../../corpActivation/entry';
import { TradeActivationsLocationState } from '../../../tradeActivation/entry';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { CustomersLocationState } from '../../entry';
import CustomerManagementByPartnerContainer from './index';

const partitionPathParam = ':partition' as const;

const routing = {
  root: '/customers/:customerId/management/by-partner/:partnerId',
  details: '/customers/:customerId/management/by-partner/:partnerId/details',
  orders: `/customers/:customerId/management/by-partner/:partnerId/orders/${partitionPathParam}`,
  order: '/customers/:customerId/management/by-partner/:partnerId/orders/:orderId',
  tradeActivations: `/customers/:customerId/management/by-partner/:partnerId/trade-activations`,
  corpActivations: `/customers/:customerId/management/by-partner/:partnerId/corp-activations`,
};

interface GetCustomerManagementByPartnerDetailsRouteProps {
  readonly partnerId: UUID;
  readonly customerId: UUID;
}

interface GetCustomerManagementByPartnerOrdersRouteProps {
  readonly partnerId: UUID;
  readonly customerId: UUID;
  readonly partition: EOrderPartition;
  readonly guid?: UUID;
}

interface GetCustomerManagementByPartnerOrderRouteProps {
  readonly orderId: UUID;
  readonly partnerId: UUID;
  readonly customerId: UUID;
}

interface GetCustomerManagementByPartnerTradeActivationsRouteProps {
  readonly partnerId: UUID;
  readonly customerId: UUID;
  readonly guid?: UUID;
}

interface GetCustomerManagementByPartnerCorpActivationsRouteProps {
  readonly partnerId: UUID;
  readonly customerId: UUID;
  readonly guid?: UUID;
}

export const getCustomerManagementByPartnerRootRouteDefinition = () => routing.root;

export const getCustomerManagementByPartnerDetailsRoute = (props: GetCustomerManagementByPartnerDetailsRouteProps) => {
  const { partnerId, customerId } = props;
  return `${routing.details.replace(':customerId', customerId).replace(':partnerId', partnerId)}`;
};

export const getCustomerManagementByPartnerOrdersRoute = (
  props: GetCustomerManagementByPartnerOrdersRouteProps
): Location<CustomersLocationState> => {
  const { partnerId, customerId, guid, partition } = props;

  return {
    pathname: partition
      ? `${routing.orders
          .replace(':customerId', customerId)
          .replace(':partnerId', partnerId)
          .replace(partitionPathParam, partition)}`
      : `${routing.orders.replace(':customerId', customerId).replace(':partnerId', partnerId)}`,
    search: '',
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

export const getCustomerManagementByPartnerOrderRoute = (props: GetCustomerManagementByPartnerOrderRouteProps) => {
  const { partnerId, customerId, orderId } = props;
  return `${routing.order
    .replace(':customerId', customerId)
    .replace(':partnerId', partnerId)
    .replace(':orderId', orderId)}
    `;
};

export const getCustomerManagementByPartnerTradeActivationsRoute = (
  props: GetCustomerManagementByPartnerTradeActivationsRouteProps
): Location<TradeActivationsLocationState> => {
  const { partnerId, customerId, guid } = props;

  return {
    pathname: `${routing.tradeActivations.replace(':customerId', customerId).replace(':partnerId', partnerId)}`,
    search: '',
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

export const getCustomerManagementByPartnerCorpActivationsRoute = (
  props: GetCustomerManagementByPartnerCorpActivationsRouteProps
): Location<CorpActivationsLocationState> => {
  const { partnerId, customerId, guid } = props;

  return {
    pathname: `${routing.corpActivations.replace(':customerId', customerId).replace(':partnerId', partnerId)}`,
    search: '',
    state: {
      guid: guid ?? uuidv4(),
    },
    hash: '',
    key: '',
  };
};

interface CustomerManagementByPartnerEntryInternalProps {
  readonly partner: Partner;
  readonly customer: MpUser;
}

const CustomerManagementByPartnerEntryInternal = ({
  partner,
  customer,
}: CustomerManagementByPartnerEntryInternalProps) => {
  return (
    <Switch>
      <Route
        exact
        path={routing.orders.replace(partitionPathParam, EOrderPartition.ProductOffers)}
        render={() => (
          <CustomerManagementProductOrdersByPartnerScreen
            customer={customer}
            partner={partner}
          />
        )}
      />
      <Route
        exact
        path={routing.orders.replace(partitionPathParam, EOrderPartition.BookingOffers)}
        render={() => (
          <CustomerManagementBookingOrdersByPartnerScreen
            customer={customer}
            partner={partner}
          />
        )}
      />
      <Route
        exact
        path={routing.tradeActivations}
        render={() => (
          <CustomerManagementTradeActivationsByPartnerScreen
            customer={customer}
            partner={partner}
          />
        )}
      />
      <Route
        exact
        path={routing.corpActivations}
        render={() => (
          <CustomerManagementCorpActivationsByPartnerScreen
            customer={customer}
            partner={partner}
          />
        )}
      />
      <Route
        exact
        path={routing.details}
        render={() => (
          <CustomerManagementByPartnerScreen
            customer={customer}
            partner={partner}
          />
        )}
      />
      <Route
        exact
        path={routing.order}
        render={() => (
          <CustomerManagementOrderByPartnerScreen
            customer={customer}
            partner={partner}
          />
        )}
      />
      <Redirect
        from={routing.root}
        to={routing.details}
      />

      <Route component={NotFoundScreen} />
    </Switch>
  );
};

const CustomerManagementByPartnerEntry = () => {
  const { partnerId, customerId } = useParams<{ customerId: UUID; partnerId: UUID }>();
  const { customers } = useCurrentUser().accessMatrix;

  if (!customers?.view?.management) {
    return <ForbiddenComponent />;
  }

  return (
    <CustomerManagementByPartnerContainer
      partnerId={partnerId}
      customerId={customerId}
    >
      {(partner, customer) => (
        <CustomerManagementByPartnerEntryInternal
          partner={partner}
          customer={customer}
        />
      )}
    </CustomerManagementByPartnerContainer>
  );
};

export default CustomerManagementByPartnerEntry;
