import { Nullable } from '../../domain/model/types';

export enum EValidationMessages {
  Empty = 'Обязательно для заполнения',
  EmailInvalid = 'Необходимо указать корректный адрес электронной почты',
  URLInvalid = 'Необходимо указать корректный URL',
  DomainInvalid = 'Необходимо указать корректный домен (example.ru)',
  PaymentAccountInvalid = 'Необходимо указать корректный расчетный счет',
  InnInvalid = 'Необходимо указать корректный ИНН',
  OgrnInvalid = 'Необходимо указать корректный ОГРН',
  OgrnipInvalid = 'Необходимо указать корректный ОГРНИП',
  Summary = 'Заполните обязательные поля и исправьте ошибки',
  HexColorInvalid = "Необходимо указать цвет в формате '#xxx' или '#xxxxxx'",
}

export enum EValidationType {
  None = 'none',
  Error = 'error',
}

export type ValidationRule<T, V = any> = {
  readonly required?: boolean | ((object: T, value: V) => boolean);
  readonly requiredMessage?: string;
  readonly validator?: (object: T, value: V) => Nullable<ValidationItemResult>;
};

export type ValidationRules<T> = {
  readonly [K in keyof T]?: ValidationRule<T, T[K]>;
};

export type ValidationOptions = {
  readonly ignoreEmpty?: boolean;
};

export type ValidationRuleSummary<T> = {
  readonly validationSummary?: ValidationRule<Nullable<T>>;
};

export type ValidationCollectionRules<T> = ValidationRules<T> & ValidationRuleSummary<T[]>;

export type ValidationItemResult = {
  readonly type: EValidationType;
  readonly hasError: boolean;
  readonly message?: string;
};

export type ValidationResult<T> = {
  [K in keyof T]?: ValidationItemResult;
};

export type ValidationResultSummary = {
  validationSummary?: Nullable<ValidationItemResult>;
};

export type ValidationCollectionResult<T> = ValidationResult<Nullable<T>>[] & Nullable<ValidationResultSummary>;

export const validationRegexp = {
  email: /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/,
};
